import type { OrderListItemTrackerProps } from '@orthly/dentin';
import { OrderListItemTracker } from '@orthly/dentin';
import type { TypographyVariant } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface OrderListItemStatusTrackerProps extends Omit<OrderListItemTrackerProps, 'isManufacturer'> {
    variant?: TypographyVariant;
    filledWarningIcon?: boolean;
}

export const OrderListItemStatusTracker: React.FC<OrderListItemStatusTrackerProps> = props => {
    const { variant, filledWarningIcon, tracking_status, ...trackerProps } = props;
    return (
        <Grid container>
            <OrderListItemTracker
                isManufacturer={true}
                {...trackerProps}
                containerStyle={{ justifyContent: 'flex-end' }}
            />
        </Grid>
    );
};
