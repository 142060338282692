import type { ReturnedItemsInfo } from '../utils';
import { SimpleCheckbox } from '@orthly/ui';
import { Text } from '@orthly/ui-primitives';
import React from 'react';

interface RefabAcknowledgeReturnProps {
    hasAcked: boolean;
    setHasHacked: (checked: boolean) => void;
    itemsToReturn: ReturnedItemsInfo[];
}

/**
 * Controlled component used in a couple of places to make sure users acknowledge that some
 * items need to be returned before the refabrication get processed.
 */
export const RefabAcknowledgeReturn: React.VFC<RefabAcknowledgeReturnProps> = ({
    hasAcked,
    setHasHacked,
    itemsToReturn,
}) => (
    <SimpleCheckbox
        checked={hasAcked}
        setChecked={setHasHacked}
        label={
            <Text variant={'body2'} component={'span'}>
                I understand{' '}
                <Text variant={'body2'} medium component={'span'}>
                    {itemsToReturn.map(i => i.name).join(', ')} must be returned
                </Text>{' '}
                for the refabrication order to be processed.
            </Text>
        }
    />
);
