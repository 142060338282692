import { useColorRamps } from '../ColorRamp';
import { ClosestPointComputer } from '../GpuAccel';
import type { MainViewCameraControlsRef } from '../ModelViewer';
import { initializeDistanceAttribute } from './ScanReview.utils';
import { DEFAULT_UPPER_JAW_INSERTION_AXIS, DEFAULT_LOWER_JAW_INSERTION_AXIS } from './ScanReviewCompleteView.hooks';
import { ScanReviewDcmBuilder } from './ScanReviewDcm.utils';
import type { ScanReviewDepthData, ScanReviewInsertionDepthMapGeneratorFactory } from './ScanReviewMaterialTypes';
import { ScanReviewInsertionDepthMapGenerator } from './ScanReviewMaterialTypes';
import type { ScanReviewRecordFactory, ScanReviewRecordsFactory } from './ScanReviewRecordTypes';
import type { ScanReviewCompositeScene } from './ScanReviewSceneTypes';
import {
    ScanReviewPanelType,
    ScanReviewCompositeViewManager,
    type ScanReviewViewState,
    ScanReviewViewManager,
    type ScanReviewThreeViewProvider,
} from './ScanReviewViewTypes';
import { AttributeName, type VertexResult } from '@orthly/forceps';
import { Jaw } from '@orthly/shared-types';
import React from 'react';
import type * as THREE from 'three';

export function useViewManager(
    scene: ScanReviewThreeViewProvider,
    panelType: ScanReviewPanelType,
    viewState: ScanReviewViewState,
) {
    const canvasRef: React.MutableRefObject<HTMLCanvasElement | null> = React.useRef(null);
    const cameraRef: React.MutableRefObject<THREE.OrthographicCamera | null> = React.useRef(null);
    const cameraControlsRef: MainViewCameraControlsRef = React.useRef(null);

    const viewManager = React.useMemo(() => {
        return new ScanReviewViewManager(canvasRef, cameraRef, cameraControlsRef, scene, panelType, viewState);
    }, [panelType, scene, viewState]);
    return viewManager;
}

export function useCompositeViewManager(scene: ScanReviewCompositeScene, viewState: ScanReviewViewState) {
    const upperManager = useViewManager(
        scene.getPartialSceneForPanelType(ScanReviewPanelType.Upper),
        ScanReviewPanelType.Upper,
        viewState,
    );

    const lowerManager = useViewManager(
        scene.getPartialSceneForPanelType(ScanReviewPanelType.Lower),
        ScanReviewPanelType.Lower,
        viewState,
    );

    const leftManager = useViewManager(
        scene.getPartialSceneForPanelType(ScanReviewPanelType.Left),
        ScanReviewPanelType.Left,
        viewState,
    );

    const rightManager = useViewManager(
        scene.getPartialSceneForPanelType(ScanReviewPanelType.Right),
        ScanReviewPanelType.Right,
        viewState,
    );

    const frontManager = useViewManager(
        scene.getPartialSceneForPanelType(ScanReviewPanelType.Front),
        ScanReviewPanelType.Front,
        viewState,
    );

    const compositeViewManager = React.useMemo(() => {
        return new ScanReviewCompositeViewManager(upperManager, lowerManager, leftManager, rightManager, frontManager);
    }, [frontManager, leftManager, lowerManager, rightManager, upperManager]);

    return compositeViewManager;
}

export function useScanReviewRecordsFactory(
    lowerJawFactory: ScanReviewRecordFactory | null,
    upperJawFactory: ScanReviewRecordFactory | null,
): ScanReviewRecordsFactory {
    const distancesCache = React.useMemo(() => {
        return new Map<string, VertexResult[]>();
    }, []);
    return React.useCallback(() => {
        const lowerJaw = lowerJawFactory?.() ?? null;
        const upperJaw = upperJawFactory?.() ?? null;

        initializeDistanceAttribute(lowerJaw?.scanMesh?.geometry);
        initializeDistanceAttribute(upperJaw?.scanMesh?.geometry);

        if (lowerJaw && upperJaw) {
            for (const { queryMesh, referenceMesh } of [
                { queryMesh: lowerJaw.scanMesh.geometry, referenceMesh: upperJaw.scanMesh.geometry },
                { queryMesh: upperJaw.scanMesh.geometry, referenceMesh: lowerJaw.scanMesh.geometry },
            ]) {
                const cacheKey = `${queryMesh.uuid}|${referenceMesh.uuid}`;
                const cachedDistances = distancesCache.get(cacheKey);
                const distancesAttribute = queryMesh.getAttribute(AttributeName.OcclusalDistance);

                if (cachedDistances) {
                    (distancesAttribute.array as Float32Array).set(cachedDistances.map(el => el.signedDistance));
                    distancesAttribute.needsUpdate = true;
                    continue;
                }

                const computer = new ClosestPointComputer(referenceMesh, queryMesh, undefined, true);
                const distances = computer.compute();
                if (!distances) {
                    computer.dispose();
                    continue;
                }

                (distancesAttribute.array as Float32Array).set(distances.map(el => el.signedDistance));
                distancesAttribute.needsUpdate = true;
                computer.dispose();

                distancesCache.set(cacheKey, distances);
            }
        }
        return {
            lowerJaw: lowerJaw,
            upperJaw: upperJaw,
        };
    }, [distancesCache, lowerJawFactory, upperJawFactory]);
}

export function useInsertionDepthMapGeneratorFactory(): ScanReviewInsertionDepthMapGeneratorFactory {
    const depthMapCache = React.useMemo(() => new Map<string, ScanReviewDepthData>(), []);
    const factory = React.useCallback(
        (scanGeometry: THREE.BufferGeometry, insertionAxis: THREE.Vector3) =>
            new ScanReviewInsertionDepthMapGenerator(scanGeometry, insertionAxis, depthMapCache),
        [depthMapCache],
    );
    return factory;
}

export function useChairsideShadeMatchingOnImageLoadCallback() {
    const onScanImageLoadCallback = React.useCallback(
        (_jaw: Jaw, scanGeometry: THREE.BufferGeometry, scanImage: HTMLImageElement) => {
            const factory = () => {
                const scanReviewRecord = new ScanReviewDcmBuilder(scanGeometry, scanImage)
                    .buildDcmVertexColors()
                    .complete();
                return scanReviewRecord ?? null;
            };
            return factory;
        },
        [],
    );
    return onScanImageLoadCallback;
}

export function useChairsideCompleteViewOnImageLoadCallback() {
    // Color ramps are used by the undercut tool. They are rendered into a series of in-memory textures
    // via an async function wrapped in useEffect() hook.
    const colorRamps = useColorRamps();

    // The undercut tool shader requires depth maps for each jaw to be rendered into memory. Since
    // We replicate the scene for each view panel, we want to reuse this data and avoid
    // redundant computations. This hook creates a factory function that creates a depth map
    // generator with a memoized cache for rendered depth maps keyed to the geometry UUID and
    // insertion axis vector.
    const injectorDepthMapGeneratorFactory = useInsertionDepthMapGeneratorFactory();

    // After a DCM file is loaded and the texture information is extracted, we need to take that information
    // and return a factory function for actually creating the scan record for the associated arch.
    // Given a THREE.js geometry extracted from a DCM and an image constructed in memory from the texture data,
    // this callback will return a factory function that can be invoked at a later time to construct a scan
    // record for insertion into a partial scene.
    //
    // Note the ScanReviewDcmBuilder allows you to customize optional data to add to your records. This is important because
    // not all use cases require the same data, and you should only "pay for what you use". This capability is leveraged in
    // shade matching for instance to add vertex colors to the created meshes but skip GPU heatmap calculations.

    const onScanImageLoadCallback = React.useCallback(
        (jaw: Jaw, scanGeometry: THREE.BufferGeometry, scanImage: HTMLImageElement) => {
            const factory = () => {
                const insertionAxis =
                    jaw === Jaw.UPPER ? DEFAULT_UPPER_JAW_INSERTION_AXIS : DEFAULT_LOWER_JAW_INSERTION_AXIS;
                const scanReviewRecord = new ScanReviewDcmBuilder(
                    scanGeometry,
                    scanImage,
                    colorRamps,
                    injectorDepthMapGeneratorFactory,
                )
                    .buildDcmScanStoneMaterial()
                    .buildDcmScanHeatmapManager()
                    .buildDcmScanUndercutManager(insertionAxis)
                    .buildDcmScanStoneUndercutManager(insertionAxis)
                    .complete();
                return scanReviewRecord ?? null;
            };
            return factory;
        },
        [colorRamps, injectorDepthMapGeneratorFactory],
    );
    return onScanImageLoadCallback;
}
