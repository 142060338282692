import { stylesFactory } from '../../util/stylesFactory';
import { SidebarDivider } from '../Sidebar/SidebarDivider';
import type { SubNavigationCollapseEntry } from './SubNavigation.types';
import { SubNavigationItem } from './SubNavigationItem';
import { Collapse, List, FlossPalette, Icon, Grid } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useToggleStyles = stylesFactory(() => ({
    activeIcon: { color: FlossPalette.STAR_GRASS },
    closedIcon: { transform: 'rotate(270deg)' },
    iconWrap: { width: 'auto', alignItems: 'center', flexWrap: 'nowrap', marginLeft: -8, paddingRight: 8 },
}));

const SubNavigationGroupCollapseToggle: React.FC<{ open: boolean; active: boolean }> = props => {
    const classes = useToggleStyles();
    return (
        <Grid container className={classes.iconWrap}>
            <Icon
                icon={'ExpandMoreIcon'}
                color={'action'}
                className={clsx(!props.open && classes.closedIcon, props.active && classes.activeIcon)}
            />
        </Grid>
    );
};

const useEntryStyles = stylesFactory(() => ({
    subItemRoot: { paddingLeft: 40 },
}));

interface SubNavigationGroupCollapseProps<V extends string = string> extends SubNavigationCollapseEntry<V> {
    isFirstGroup: boolean;
    value: V;
    onChange: (value: V) => void;
}

export function SubNavigationGroupCollapse<V extends string = string>(props: SubNavigationGroupCollapseProps<V>) {
    const classes = useEntryStyles();
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const { isFirstGroup, onChange, value, entries, label } = props;
    const { count, isActive } = React.useMemo(() => {
        return entries.reduce<{ count: number; isActive: boolean }>(
            (result, entry) => ({
                isActive: entry.value === value ? true : result.isActive,
                count: result.count + (entry.count ?? 0),
            }),
            { count: 0, isActive: false },
        );
    }, [entries, value]);
    return (
        <>
            {!isFirstGroup && <SidebarDivider />}
            <SubNavigationItem<V>
                label={label}
                value={value}
                active={false}
                count={count}
                onClick={() => setIsOpen(o => !o)}
                StartIcon={() => <SubNavigationGroupCollapseToggle key={'collapse'} open={isOpen} active={isActive} />}
            />
            <Collapse in={isOpen}>
                <List disablePadding>
                    {entries.map(item => (
                        <SubNavigationItem<V>
                            key={item.value}
                            {...item}
                            classes={{ ...item.classes, root: clsx(item.classes?.root, classes.subItemRoot) }}
                            active={item.value === value}
                            onClick={() => onChange(item.value)}
                        />
                    ))}
                </List>
            </Collapse>
        </>
    );
}
