import { RouterUtils } from '../../../../utils/router/RouterUtils';
import { useAutomationBuilderAction } from '../../state/AutomationBuilder.actions';
import type { LabsGqlOrderAutomationFragment } from '@orthly/graphql-operations';
import React from 'react';
import { useHistory } from 'react-router-dom';

// Utility hook to both set the editor form state with the selected automation, and also update the URL bar.
export function useAutomationStartEditing() {
    const startEditingAction = useAutomationBuilderAction('ENTER_EDITING');
    const history = useHistory();

    return React.useCallback(
        (automation?: LabsGqlOrderAutomationFragment) => {
            history.replace(`${RouterUtils.pathForScreen('automations')}/${automation?.id ?? ''}`);
            startEditingAction(automation);
        },
        [history, startEditingAction],
    );
}
