import type { DesignQcState } from './DesignQc.state';
import _ from 'lodash';
import React from 'react';
import type { TypedUseSelectorHook } from 'react-redux';
import { createDispatchHook, createSelectorHook } from 'react-redux';

// @ts-ignore
export const DesignQcReduxContext = React.createContext({} as any);

export const useDesignQcDispatch = createDispatchHook(DesignQcReduxContext);
export const useDesignQcSelector: TypedUseSelectorHook<DesignQcState> = createSelectorHook(DesignQcReduxContext);

export function useDesignQcPropSelector<K extends keyof DesignQcState>(props: K[]): Pick<DesignQcState, K> {
    const state = useDesignQcSelector(s => s);

    return _.pick(state, props);
}
