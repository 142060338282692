import { LIST_ITEM_ROOT_STYLE } from './OrderListItemTracker';
import { TrackerConnector } from './TrackerConnector';
import { TrackerIcon } from './TrackerIcon';
import type { TrackerStepProps, TrackerStepDefinition } from './TrackerStep';
import { FlossPalette, stylesFactory, Grid, Text } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

const useStyles = stylesFactory(
    () => ({
        stepIconWrap: {
            justifyContent: 'center',
            maxWidth: 18,
            height: 18,
            '&:last-of-type': { justifyContent: 'flex-end' },
            '&:first-of-type': { justifyContent: 'flex-start' },
        },
        stepLabelWrap: {
            width: 'auto',
            textAlign: 'center',
            flexGrow: 2,
            flexBasis: 0,
            '&:last-of-type': { flexGrow: 1, textAlign: 'right' },
            '&:first-of-type': { flexGrow: 1, textAlign: 'left' },
        },
        stepIconLabelWrap: {
            justifyContent: 'center',
            maxWidth: 18,
            position: 'relative',
            height: 18,
            '&:last-of-type': { justifyContent: 'flex-end' },
            '&:first-of-type': { justifyContent: 'flex-start' },
        },
        icon: { color: FlossPalette.DARK_TAN, height: 16, width: 16 },
        iconActiveOrDone: { color: FlossPalette.GRAY },
        iconActiveOrDoneIssue: { color: FlossPalette.ATTENTION },
        stepIconLabel: { display: 'block', color: FlossPalette.GRAY },
        stepIconLabelIssue: { color: FlossPalette.ATTENTION },
        designTaskLabel: {
            fontWeight: 500,
            fontSize: 12,
        },
        designTaskSubtitle: {
            fontSize: 12,
        },
    }),
    { name: 'DesignTrackerStep' },
);

const DesignTaskTrackerStepIcon: React.FC<TrackerStepProps> = props => {
    const { icon, active, complete, hasIssue, isLast } = props;
    const classes = useStyles();
    const activeOrComplete = active || complete;
    return (
        <>
            <Grid container className={classes.stepIconWrap}>
                <TrackerIcon
                    variant={icon}
                    className={cx(
                        classes.icon,
                        activeOrComplete && classes.iconActiveOrDone,
                        activeOrComplete && hasIssue && classes.iconActiveOrDoneIssue,
                    )}
                    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                    // eslint-disable-next-line no-nested-ternary
                    color={activeOrComplete ? (hasIssue ? 'ATTENTION' : 'DARK_GRAY') : 'GRAY'}
                />
            </Grid>
            {!isLast && <TrackerConnector hasIssue={hasIssue} active={active} completed={complete} />}
        </>
    );
};

const DesignTaskTrackerStepText: React.FC<TrackerStepProps> = props => {
    const { title, subtitle, secondarySubtitle, hasIssue, isLast } = props;
    const classes = useStyles();
    return (
        <div className={classes.stepLabelWrap}>
            <Text
                className={cx(classes.stepIconLabel, classes.designTaskLabel, hasIssue && classes.stepIconLabelIssue)}
                variant={'caption'}
                style={{ fontWeight: isLast ? 500 : undefined }}
            >
                {title}
            </Text>
            {subtitle && (
                <Text
                    className={cx(
                        classes.stepIconLabel,
                        classes.designTaskSubtitle,
                        hasIssue && classes.stepIconLabelIssue,
                    )}
                    variant={'caption'}
                >
                    {subtitle}
                </Text>
            )}
            {secondarySubtitle && (
                <Text
                    className={cx(
                        classes.stepIconLabel,
                        classes.designTaskSubtitle,
                        hasIssue && classes.stepIconLabelIssue,
                    )}
                    variant={'caption'}
                >
                    {secondarySubtitle}
                </Text>
            )}
        </div>
    );
};

interface DesignTaskDetailTrackerWithStepsProps {
    steps: TrackerStepDefinition[];
    hasIssue: boolean;
}

export const DesignTaskDetailTrackerWithSteps: React.FC<DesignTaskDetailTrackerWithStepsProps> = ({
    steps,
    hasIssue,
}) => {
    return (
        <>
            <Grid container style={LIST_ITEM_ROOT_STYLE} wrap={'nowrap'}>
                {steps.map((step, idx) => (
                    <DesignTaskTrackerStepIcon
                        key={idx}
                        {...step}
                        idx={idx}
                        hasIssue={hasIssue}
                        isLast={idx === steps.length - 1}
                    />
                ))}
            </Grid>
            <div style={{ display: 'flex', width: '100%' }}>
                {steps.map((step, idx) => (
                    <DesignTaskTrackerStepText
                        key={idx}
                        {...step}
                        idx={idx}
                        hasIssue={hasIssue}
                        isLast={idx === steps.length - 1}
                    />
                ))}
            </div>
        </>
    );
};
