import differenceInYears from 'date-fns/differenceInYears';

/**
 * Get the age (in years) of a patient based on their birthday
 * @param birthday The patient's D.O.B
 * @returns Age of the patient in years
 */
export function getPatientAge(birthday: string): number {
    return differenceInYears(Date.now(), new Date(birthday));
}

/**
 * Gets the M/F abbreviation of the patient's sex
 * @param sex Full length sex of the patient
 * @returns 1 letter abbreviation of the patient's sex
 */
export function getPatientSexAbbrev(sex: string | null): string {
    if (sex === null) {
        return '';
    }
    return sex.slice(0, 1);
}
