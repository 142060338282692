import { useAutomationStartEditing } from './AutomationStartEditing.hook';
import type { LabsGqlOrderAutomationFragment, LabsGqlUserDtoFragment } from '@orthly/graphql-operations';
import type { UseStateObject, SimpleAutocompleteProps, SimpleAutocompleteOption } from '@orthly/ui';
import { SimpleAutocomplete, SimpleCheckbox } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    checkboxLabel: {
        marginLeft: 0,
        fontSize: 14,
        color: FlossPalette.GRAY,
    },
}));

export const NO_FOLDER_AUTOMATIONS_KEY = '___NO_FOLDER_AUTOMATION___';

export interface AutomationListItemData extends LabsGqlOrderAutomationFragment {
    creator: LabsGqlUserDtoFragment | null;
}

interface AutomationsListFilterToolbarProps
    extends UseStateObject<'folderFilter' | 'creatorFilter' | 'actionFilter', string | undefined>,
        UseStateObject<'showArchivedFilter', boolean> {
    automations: AutomationListItemData[];
}

const AutocompleteTextFieldProps: SimpleAutocompleteProps['TextFieldProps'] = {
    InputProps: { style: { background: '#fff' } },
    InputLabelProps: { style: { background: '#fff' } },
};

export const AutomationsListFilterToolbar: React.VFC<AutomationsListFilterToolbarProps> = props => {
    const {
        automations,
        folderFilter,
        setFolderFilter,
        creatorFilter,
        setCreatorFilter,
        actionFilter,
        setActionFilter,
        showArchivedFilter,
        setShowArchivedFilter,
    } = props;
    const startEditing = useAutomationStartEditing();
    const classes = useStyles();

    const userOptions = React.useMemo(() => {
        const automationsByUserId = _.groupBy(automations, a => a.created_by_user_id);
        return Object.entries(automationsByUserId).map(([userId, automationsForUser]) => {
            const creator = automationsForUser[0]?.creator;
            const creatorName = creator ? `${creator.first_name} ${creator.last_name}` : 'Unknown user';
            return { label: `${creatorName} (${automationsForUser.length})`, value: userId };
        });
    }, [automations]);

    const folderOptions = React.useMemo(() => {
        const automationsByFolder = _.groupBy(
            automations.filter(automation => showArchivedFilter || !automation.archived),
            a => a.folder ?? NO_FOLDER_AUTOMATIONS_KEY,
        );
        const options = Object.entries(automationsByFolder).map(([folder, automations]) => ({
            value: folder,
            label: `${folder === NO_FOLDER_AUTOMATIONS_KEY ? 'Uncategorized' : folder} (${automations.length})`,
        }));
        return _.sortBy(options, opt => opt.value);
    }, [automations, showArchivedFilter]);

    const actionsOptions = React.useMemo(() => {
        const uniqueActions = _.uniqBy(
            _.compact(automations.flatMap(automation => automation.actions.map(action => action.action))),
            action => action.id,
        );

        const options = uniqueActions.map<SimpleAutocompleteOption>(action => {
            const count = automations.filter(automation =>
                automation.actions.some(candidate => {
                    if (candidate.action?.name !== action.name) {
                        return false;
                    }

                    return showArchivedFilter || !automation.archived;
                }),
            ).length;
            return {
                value: action.name,
                label: `${action.name} (${count})`,
            };
        });

        return _.sortBy(options, opt => opt.label);
    }, [automations, showArchivedFilter]);

    const automationOptions = React.useMemo(() => {
        return _.sortBy(automations, i => i.name).map(automation => {
            const userLabel = automation.creator
                ? `${automation.creator.first_name} ${automation.creator.last_name}`
                : null;
            const label = _.compact([automation.name, automation.folder ?? null, userLabel]).join(' | ');
            return { label, value: automation.id };
        });
    }, [automations]);

    return (
        <Grid container wrap={'nowrap'} style={{ borderBottom: `2px solid ${FlossPalette.DARK_TAN}` }} spacing={1}>
            <Grid container item xs>
                <SimpleAutocomplete
                    AutocompleteProps={{ style: { width: '100%' } }}
                    TextFieldProps={AutocompleteTextFieldProps}
                    label={'Search'}
                    options={automationOptions}
                    onChange={automationId => {
                        if (!automationId) {
                            return;
                        }
                        const automation = automations.find(a => a.id === automationId);
                        automation && startEditing(automation);
                    }}
                />
            </Grid>
            <Grid container item xs={2}>
                <SimpleAutocomplete
                    AutocompleteProps={{
                        style: { width: '100%' },
                        value: folderFilter === NO_FOLDER_AUTOMATIONS_KEY ? 'Uncategorized' : folderFilter ?? null,
                    }}
                    TextFieldProps={AutocompleteTextFieldProps}
                    label={'Folder'}
                    options={folderOptions}
                    onChange={value => setFolderFilter(value ?? undefined)}
                />
            </Grid>
            <Grid container item xs={2}>
                <SimpleAutocomplete
                    AutocompleteProps={{
                        style: { width: '100%' },
                        value: actionFilter ?? null,
                    }}
                    TextFieldProps={AutocompleteTextFieldProps}
                    label={'Action'}
                    options={actionsOptions}
                    onChange={value => setActionFilter(value ?? undefined)}
                />
            </Grid>
            <Grid container item xs={2}>
                <SimpleAutocomplete
                    AutocompleteProps={{
                        style: { width: '100%' },
                        value: creatorFilter ? userOptions.find(u => u.value === creatorFilter) : null,
                    }}
                    TextFieldProps={AutocompleteTextFieldProps}
                    label={'Creator'}
                    options={userOptions}
                    onChange={value => setCreatorFilter(value ?? undefined)}
                />
            </Grid>
            <Grid container item xs={1}>
                <SimpleCheckbox
                    label={'Show Archived'}
                    checked={showArchivedFilter}
                    setChecked={setShowArchivedFilter}
                    FormControlLabelProps={{ classes: { label: classes.checkboxLabel } }}
                />
            </Grid>
        </Grid>
    );
};

type AutomationsListFilterState = {
    visibleAutomationIds: string[];
    folderFilterState: UseStateObject<'folderFilter', string | undefined>;
    creatorFilterState: UseStateObject<'creatorFilter', string | undefined>;
    actionFilterState: UseStateObject<'actionFilter', string | undefined>;
    showArchivedFilterState: UseStateObject<'showArchivedFilter', boolean>;
};

export function useAutomationsListFilterState(automations: AutomationListItemData[]): AutomationsListFilterState {
    const [folderFilter, setFolderFilter] = React.useState<string | undefined>();
    const [creatorFilter, setCreatorFilter] = React.useState<string | undefined>();
    const [actionFilter, setActionFilter] = React.useState<string | undefined>();
    const [showArchivedFilter, setShowArchivedFilter] = React.useState<boolean>(false);

    const visibleAutomationIds = React.useMemo<string[]>(() => {
        const visibleAutomationIds = automations.map<string | null>(automation => {
            if (
                folderFilter &&
                (folderFilter === NO_FOLDER_AUTOMATIONS_KEY ? !!automation.folder : folderFilter !== automation.folder)
            ) {
                return null;
            }

            if (actionFilter && !automation.actions.some(automation => automation.action?.name === actionFilter)) {
                return null;
            }

            if (!showArchivedFilter && automation.archived) {
                return null;
            }

            if (creatorFilter && creatorFilter !== automation.created_by_user_id) {
                return null;
            }

            return automation.id;
        });
        return _.compact(visibleAutomationIds);
    }, [automations, folderFilter, creatorFilter, actionFilter, showArchivedFilter]);

    return {
        visibleAutomationIds,
        folderFilterState: { folderFilter, setFolderFilter },
        creatorFilterState: { creatorFilter, setCreatorFilter },
        actionFilterState: { actionFilter, setActionFilter },
        showArchivedFilterState: { showArchivedFilter, setShowArchivedFilter },
    };
}
