import type { RefabInputWithCategory, RefabReasonSelectorProps } from './RefabReasons.types';
import { LabsGqlRefabFault } from '@orthly/graphql-schema';
import { SimpleSelect } from '@orthly/ui';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Text, ExpandMoreIcon } from '@orthly/ui-primitives';
import React from 'react';

interface RefabFaultSelectorProps extends Omit<RefabReasonSelectorProps, 'allowFaultOverride'> {
    selectedInCategory: RefabInputWithCategory[];
}

export const RefabFaultSelector: React.VFC<RefabFaultSelectorProps> = props => {
    const { selected, selectedInCategory, possibleReasons, setSelected } = props;
    return (
        <Accordion style={{ marginTop: '8px' }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ padding: '0 16px 0 42px' }}>
                <Text>Assign/Override Fault</Text>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={'row'}>
                    {selectedInCategory.map(s => {
                        const defaultFault = possibleReasons.find(p => p.id === s.refab_reason_id)?.default_fault;
                        return (
                            <Grid container key={s.name} direction={'row'} wrap={'nowrap'} alignItems={'center'}>
                                <Text style={{ marginLeft: '18px', minWidth: '170px' }}>{s.name}</Text>
                                <SimpleSelect
                                    placeholder={defaultFault ?? 'None'}
                                    options={Object.keys(LabsGqlRefabFault).map(k => ({
                                        value: k,
                                    }))}
                                    onChange={val => {
                                        setSelected(
                                            selected.map(curr =>
                                                curr.name === s.name
                                                    ? {
                                                          name: curr.name,
                                                          fault_override: (val as LabsGqlRefabFault) ?? undefined,
                                                          refab_reason_id: curr.refab_reason_id,
                                                      }
                                                    : curr,
                                            ),
                                        );
                                    }}
                                    label={`Fault for ${s.name}`}
                                    value={s.fault_override ?? defaultFault ?? ''}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
