import { SwitchWithSlider } from './SwitchWithSlider';
import { useTransparency } from './TransparencyControls.hooks';
import { styled } from '@mui/system';
import type { ModelAppearance } from '@orthly/dentin';
import { Card, CloseIcon, IconButton } from '@orthly/ui-primitives';
import React from 'react';

const CloseButton = styled(IconButton)({
    position: 'absolute',
    right: 0,
    top: 0,
});

export const TransparencyFormMobile: React.VFC<{
    appearanceSettings: ModelAppearance;
    onAppearanceChange: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    onClose: () => void;
}> = ({ appearanceSettings, onAppearanceChange, onClose }) => {
    const {
        updatePorcelainOpacity,
        updateOpacity,
        restorativeOpacity,
        porcelainOpacity,
        applyToAnatomyLayers,
        resetState,
    } = useTransparency(appearanceSettings, onAppearanceChange);
    const cardRef = React.useRef<HTMLDivElement | null>(null);

    const onChange = (newValue: number) => {
        const action = applyToAnatomyLayers ? updatePorcelainOpacity : updateOpacity;
        action(1 - newValue / 100);
    };

    const handleClose = () => {
        resetState();
        onClose();
    };
    const label = applyToAnatomyLayers ? 'Applied to layered porcelain' : 'Apply to all';
    const value = (applyToAnatomyLayers ? porcelainOpacity : restorativeOpacity) * 100;

    return (
        <Card variant={'outlined'} style={{ paddingTop: 8, paddingBottom: 8, position: 'relative' }} ref={cardRef}>
            <SwitchWithSlider
                checked={true}
                onClick={handleClose}
                label={label}
                value={100 - value}
                onChange={onChange}
                showSlider={true}
            />
            <CloseButton onClick={handleClose}>
                <CloseIcon fontSize={'medium'} />
            </CloseButton>
        </Card>
    );
};
