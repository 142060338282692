import {
    useGetAllNotes,
    hasCrownUnderPartial,
    getNotesCountForOrder,
} from '../../OrderDetails/NotesSummary/NotesSummary.hooks';
import type { AlertsText } from '../../OrderDetails/NotesSummary/NotesSummary.types';
import { useCreateNoteTiles } from '../../OrderDetails/NotesSummary/NotesSummary.utils';
import { useOrder } from '../OrderState.hooks';
import { OrderItemV2Utils } from '@orthly/items';
import { StackY } from '@orthly/ui';
import { FlossPalette, styled } from '@orthly/ui-primitives';
import React from 'react';

const NoteContainer = styled(StackY)(() => ({
    rowGap: 8,
    borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
    padding: '8px 0px',
}));

export const CaseNotesBlock: React.VFC = () => {
    const order = useOrder();

    const allItems = OrderItemV2Utils.parseItems(order.items_v2);
    const alertsText: AlertsText = {
        designTaskAlertText: hasCrownUnderPartial(allItems) ? 'Crown under partial: YES' : null,
    };

    const allNotes = useGetAllNotes(order);
    const numNotes = React.useMemo(() => getNotesCountForOrder(allNotes, allItems), [allNotes, allItems]);
    const tileProps = { compact: true, forceExpanded: true };
    const noteTiles = useCreateNoteTiles(order, allNotes, alertsText, undefined, tileProps);

    if (!numNotes) {
        return null;
    }

    return <NoteContainer>{noteTiles}</NoteContainer>;
};
