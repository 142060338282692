import type { LabsGqlOrderReturnFragment } from '@orthly/graphql-operations';
import { useCancelOrderReturnMutation } from '@orthly/graphql-react';
import { LabsGqlOrderReturnStatus } from '@orthly/graphql-schema';
import { QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { IconButton, Tooltip, CancelIcon } from '@orthly/ui-primitives';
import React from 'react';

interface CancelOrderReturnProps {
    orderReturn: LabsGqlOrderReturnFragment;
    onComplete: () => Promise<any>;
}

export const CancelOrderReturnAction: React.FC<CancelOrderReturnProps> = props => {
    const { orderReturn, onComplete } = props;
    const [submitMtn] = useCancelOrderReturnMutation();
    const mtnSubmitter = (reason: string) => submitMtn({ variables: { reason, returnId: orderReturn.id } });
    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [string]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Return canceled', {}],
        onSuccess: async () => {
            await onComplete();
        },
    });
    if (orderReturn.status !== LabsGqlOrderReturnStatus.NotShipped) {
        return null;
    }

    return (
        <>
            <IconButton
                size={'small'}
                onClick={e => {
                    e.stopPropagation();
                    setOpen(true);
                }}
            >
                <Tooltip title={'Cancel Return'} arrow>
                    <CancelIcon />
                </Tooltip>
            </IconButton>
            <RootActionDialog
                loading={submitting}
                open={open}
                setOpen={setOpen}
                title={'Cancel Order'}
                content={
                    <QuickForm<{ reason: string }>
                        fields={{
                            reason: {
                                type: 'text',
                                label: 'Cancellation Reason',
                                helperText: 'Please let us know why you need to cancel this return',
                                fieldProps: { multiline: true, rows: 2 },
                            },
                        }}
                        initialValues={{ reason: '' }}
                        onSubmit={async result => {
                            await submit(result.reason);
                        }}
                    />
                }
                style={{ padding: 0 }}
                buttonText={'Cancel Return'}
                buttonProps={{ style: { display: 'none' } }}
            />
        </>
    );
};
