import { useSoftSmileWebviewerLinkMutation } from '@orthly/graphql-react';
import { Dialog, DialogContent, DialogTitle, IconButton, CloseIcon } from '@orthly/ui-primitives';
import React from 'react';

/**
 * Adds rounded corners with a specified radius to arbitrary content by embedding it in a pair of divs.
 * The outer div is useful for applying padding; the inner one applies the corner mask.
 */
const RoundedCorners: React.VFC<{ radius: number; children: React.ReactNode; style?: React.CSSProperties }> = ({
    radius,
    children,
    style,
}) => (
    <div style={style}>
        <div
            style={{
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                borderRadius: radius,
            }}
        >
            {children}
        </div>
    </div>
);

/** An iframe that loads SoftSmile's WebGL-based viewer. */
const WebviewerIframe: React.VFC<{ labOrderId: string; versionId: number; accessToken?: string }> = ({
    labOrderId,
    versionId,
    accessToken,
}) => {
    // Links expire (after 5 minutes at the time of writing), so we generate a fresh link
    // every time the iframe loads.
    const [src, setSrc] = React.useState('');
    const [getWebviewerLink] = useSoftSmileWebviewerLinkMutation();
    React.useEffect(() => {
        void getWebviewerLink({ variables: { versionId, orderId: labOrderId, accessToken: accessToken || null } }).then(
            ({ data }) => {
                const link = data?.generateSoftsmileWebviewerLink;
                if (typeof link === 'string') {
                    setSrc(link);
                }
            },
        );
    }, [getWebviewerLink, labOrderId, versionId, accessToken]);

    return (
        <iframe
            title={`webviewer`}
            src={src}
            style={{ width: '100%', height: '100%', border: 'none' }}
            sandbox={'allow-scripts allow-same-origin'}
        />
    );
};

/** Dialog-based "full-screen" viewer (not actually full-screen; just most of the window). */
const SoftsmileViewerDialog: React.FC<{
    isOpen: boolean;
    close: () => void;
    labOrderId: string;
    versionId: number;
}> = props => {
    const { isOpen, close, labOrderId, versionId } = props;
    return (
        <Dialog onClose={() => close()} open={isOpen} maxWidth={'xl'} fullWidth>
            <DialogTitle>
                <IconButton onClick={() => close()}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ height: '90vh' }}>
                <WebviewerIframe labOrderId={labOrderId} versionId={versionId} />
            </DialogContent>
        </Dialog>
    );
};

/** Wraps an inline viewer and a full-screen viewer. */
export const SoftsmileViewer: React.VFC<{
    labOrderId: string;
    versionId: number;
    isFullscreen: boolean;
    close: () => void;
    accessToken?: string;
    style?: React.CSSProperties;
}> = ({ labOrderId, versionId, isFullscreen, close, accessToken, style }) => {
    return (
        <>
            <SoftsmileViewerDialog isOpen={isFullscreen} close={close} labOrderId={labOrderId} versionId={versionId} />
            <RoundedCorners radius={10} style={style}>
                <WebviewerIframe labOrderId={labOrderId} versionId={versionId} accessToken={accessToken} />
            </RoundedCorners>
        </>
    );
};
