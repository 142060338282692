import CementRetainedImg from '../../../assets/images/implants/CementRetained.svg';
import ScrewRetainedImg from '../../../assets/images/implants/ScrewRetained.svg';
import ScrewmentableImg from '../../../assets/images/implants/Screwmentable.svg';
import type { BigRadioOption } from '../CheckoutFormFields/BigRadioSelect';
import { BigRadioSelect } from '../CheckoutFormFields/BigRadioSelect';
import { OrderItemLinkRelationship } from '@orthly/items';
import { Text, FlossPalette, HelpIcon } from '@orthly/ui-primitives';
import React from 'react';

/**
 * This component was originally created in the fluoride package.
 * Full history: https://github.com/orthly/Scanner/commits/develop/packages/fluoride/src/components/case-builder/setup/items/steps/ItemLinkRelationshipSelect.tsx
 */

export const ImplantRelationshipDisplayNames: { [K in OrderItemLinkRelationship]: string } = {
    [OrderItemLinkRelationship.ScrewRetained]: 'Screw-retained',
    [OrderItemLinkRelationship.CementRetained]: 'Cement-retained',
    [OrderItemLinkRelationship.Screwmentable]: 'Screwmentable',
};

export const ImplantRelationshipDescriptions: { [K in OrderItemLinkRelationship]: string } = {
    [OrderItemLinkRelationship.ScrewRetained]:
        'Crown with access-hole for future retrievability. No cementing required.',
    [OrderItemLinkRelationship.CementRetained]: 'Crown without an access hole to be cemented intraorally.',
    [OrderItemLinkRelationship.Screwmentable]:
        'Crown with screw access hole to be cemented intraorally. Allows for future retrievability.',
};

export const ImplantRelationshipPics: { [K in OrderItemLinkRelationship]: string } = {
    [OrderItemLinkRelationship.ScrewRetained]: ScrewRetainedImg,
    [OrderItemLinkRelationship.CementRetained]: CementRetainedImg,
    [OrderItemLinkRelationship.Screwmentable]: ScrewmentableImg,
};

interface ItemLinkRelationshipSelectProps {
    value?: OrderItemLinkRelationship;
    onChange: (val: OrderItemLinkRelationship) => void;
}

export const ItemLinkRelationshipSelect: React.VFC<ItemLinkRelationshipSelectProps> = ({ value, onChange }) => {
    const options: BigRadioOption<OrderItemLinkRelationship>[] = React.useMemo(
        () =>
            Object.values(OrderItemLinkRelationship).map(rel => ({
                label: ImplantRelationshipDisplayNames[rel],
                value: rel,
                helpText: (
                    <div style={{ width: 252 }}>
                        <img
                            src={ImplantRelationshipPics[rel]}
                            style={{
                                backgroundColor: FlossPalette.WHITE,
                                borderRadius: 8,
                                width: 252,
                                height: 128,
                            }}
                        />
                        <Text variant={'body2'} color={'WHITE'}>
                            {ImplantRelationshipDescriptions[rel]}
                        </Text>
                    </div>
                ),
                endAdornment: <HelpIcon style={{ color: FlossPalette.GRAY }} />,
            })),
        [],
    );

    return (
        <BigRadioSelect<OrderItemLinkRelationship>
            options={options}
            onChange={onChange}
            value={value}
            recommendation={OrderItemLinkRelationship.ScrewRetained}
        />
    );
};
