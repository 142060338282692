import type { Theme } from '@orthly/ui-primitives';
import { createStyles, makeStyles, Grid } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        overlay: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: '#fff',
            opacity: 0,
            // this ensure it doesnt pick up button presses, etc.
            pointerEvents: 'none',
            transition: theme.transitions.create(['opacity'], {
                duration: 150,
                easing: theme.transitions.easing.sharp,
            }),
        },
        overlayDisabled: {
            opacity: 0.75,
            zIndex: 1,
            // make sure no events propagate through the overlay
            pointerEvents: 'all',
        },
    }),
);

interface DisabledFieldOverlayProps {
    disabled: boolean;
}

// Make sure this is wrapped in a relatively positioned element!!
export const DisabledFieldOverlay: React.FC<DisabledFieldOverlayProps> = props => {
    const classes = useStyles();
    return <Grid container className={cx(classes.overlay, props.disabled && classes.overlayDisabled)} />;
};
