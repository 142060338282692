import type { DandyChatInputProps, DandyChatProps } from './DandyChat';
import { DandyChatInput, DandyChatBase } from './DandyChat';
import { IOrganizationType } from '@orthly/retainer-common';
import type { ConstEnum } from '@orthly/runtime-utils';
import { useHasCapability } from '@orthly/session-client';
import { LoadBlocker } from '@orthly/ui';
import {
    FlossPalette,
    createStyles,
    makeStyles,
    Button,
    ClickAwayListener,
    Grid,
    ToggleButton,
    ToggleButtonGroup,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        loadBlockerRoot: {
            alignSelf: 'flex-start',
            flexWrap: 'nowrap',
            background: '#fff',
            borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
            cursor: 'text',
        },
        toggleButton: {
            border: 'none',
            marginLeft: 8,
            marginRight: 8,
            borderRadius: '0.25em',
            fontSize: 16,
            height: 32,
        },
    }),
);

export interface DandyChatConversationButtonProps<Convo extends IOrganizationType> {
    convoKeysEnum: ConstEnum<Convo>;
    currentConvo: Convo;
    setCurrentConvo: (convo: Convo) => void;
    onClick: () => void;
    disabled?: boolean;
    chatActions?: React.ReactNode;
    ExpandedChatAction?: React.ComponentType<{ currentConvo: Convo }>;
    expanded?: boolean;
}

const DandyChatConversationButton = <Convo extends IOrganizationType>(
    props: DandyChatConversationButtonProps<Convo>,
) => {
    const { onClick, chatActions, expanded, ExpandedChatAction, currentConvo } = props;
    return (
        <>
            {ExpandedChatAction && expanded && <ExpandedChatAction currentConvo={currentConvo} />}

            <Grid container alignItems={'center'} style={{ width: 'auto', padding: 4 }} wrap={'nowrap'}>
                <Grid item style={{ height: 'fit-content' }}>
                    {chatActions}
                </Grid>
                <Grid item>
                    <Button
                        fullWidth
                        disabled={props.disabled}
                        variant={'contained'}
                        style={{ borderRadius: 3, height: 40 }}
                        onClick={() => onClick()}
                    >
                        Send
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

interface DandyChatInputExpandedProps<Convo extends IOrganizationType> extends Omit<DandyChatInputProps, 'expanded'> {
    setCurrentConvo: (convo: Convo) => void;
    currentConvo: Convo;
    buttonProps: DandyChatWithConversationBtnProps<Convo>;
    submitting: boolean;
    onSubmit: () => void;
    onKeyDown: (event: React.KeyboardEvent<any>) => void;
    chatActions?: React.ReactNode;
    expanded: boolean;
    setExpanded: (expanded: boolean) => void;
    onFilePaste?: (files: File[]) => void;
}

const DandyChatInputExpanded = <Convo extends IOrganizationType>(props: DandyChatInputExpandedProps<Convo>) => {
    const classes = useStyles();

    const canSendToLab = useHasCapability('chat', 'chat.send_to_lab');
    const canSendToPractice = useHasCapability('chat', 'chat.send_to_practice');

    const { expanded, setExpanded } = props;
    return (
        <ClickAwayListener onClickAway={() => setExpanded(false)}>
            <Grid container item style={{ flexDirection: 'column', width: '100%', paddingLeft: 16, paddingTop: 4 }}>
                <ToggleButtonGroup
                    size={'small'}
                    value={props.currentConvo}
                    style={{ marginLeft: -8, cursor: 'pointer', display: !expanded ? 'none' : undefined }}
                    exclusive
                    onChange={(_event, value) => {
                        !!value && props.setCurrentConvo(value);
                    }}
                >
                    <ToggleButton value={`${IOrganizationType.internal}`} className={classes.toggleButton}>
                        Internal
                    </ToggleButton>
                    {canSendToLab && (
                        <ToggleButton value={`${IOrganizationType.lab}`} className={classes.toggleButton}>
                            Lab
                        </ToggleButton>
                    )}
                    {canSendToPractice && (
                        <ToggleButton value={`${IOrganizationType.practice}`} className={classes.toggleButton}>
                            Practice
                        </ToggleButton>
                    )}
                </ToggleButtonGroup>
                <Grid container wrap={expanded ? 'wrap' : 'nowrap'}>
                    <DandyChatInput
                        onFilePaste={props.onFilePaste}
                        newMessageText={props.newMessageText}
                        setNewMessageText={props.setNewMessageText}
                        onKeyDown={props.onKeyDown}
                        expanded={expanded}
                        // only show the "Chat with X" placeholder when expanded
                        currentRecipient={expanded ? props.currentConvo : undefined}
                        onFocus={() => {
                            !expanded && setExpanded(true);
                        }}
                    />
                    <Grid container justifyContent={'flex-end'} style={{ width: expanded ? '100%' : 'auto' }}>
                        <DandyChatConversationButton
                            {...props.buttonProps}
                            currentConvo={props.currentConvo}
                            setCurrentConvo={props.setCurrentConvo}
                            disabled={!props.newMessageText || props.submitting}
                            onClick={props.onSubmit}
                            chatActions={props.chatActions}
                            expanded={expanded}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </ClickAwayListener>
    );
};

type DandyChatWithConversationBtnProps<Convo extends IOrganizationType> = Omit<
    DandyChatConversationButtonProps<Convo>,
    'currentConvo' | 'setCurrentConvo' | 'onClick' | 'disabled'
>;

interface DandyChatWithConversationProps<Convo extends IOrganizationType> {
    chatProps: Omit<DandyChatProps, 'CustomButton' | 'onAddChat' | 'submitLayout'>;
    buttonProps: DandyChatWithConversationBtnProps<Convo>;
    onAddChat?: (text: string, convo: Convo) => Promise<void>;
    initialConvo: Convo;
    chatActions?: React.ReactNode;
}

export const DandyChatWithConversation = <Convo extends IOrganizationType = IOrganizationType>(
    props: DandyChatWithConversationProps<Convo>,
) => {
    const { initialConvo, chatProps } = props;
    const classes = useStyles();
    const [inputExpanded, setInputExpanded] = React.useState(false);
    const [currentConvo, setCurrentConvo] = React.useState<Convo>(initialConvo);
    const [newMessageText, setNewMessageText] = React.useState<string>('');
    const onSubmit = React.useCallback(async () => {
        if (newMessageText && !props.chatProps.submitting) {
            await props.onAddChat?.(newMessageText, currentConvo).catch(console.error);
            setNewMessageText('');
        }
    }, [currentConvo, newMessageText, props]);
    const onKeyDown = React.useCallback(
        (event: React.KeyboardEvent<any>) => {
            if (event.key === 'Enter' && !event.shiftKey && newMessageText?.length > 1) {
                event.preventDefault();
                event.stopPropagation();
                void onSubmit();
            }
        },
        [newMessageText, onSubmit],
    );
    return (
        <DandyChatBase
            {...chatProps}
            newMessageText={newMessageText}
            setNewMessageText={setNewMessageText}
            expandedInput={inputExpanded}
            enableStickyDate
        >
            <LoadBlocker blocking={props.chatProps.submitting}>
                <Grid container className={classes.loadBlockerRoot}>
                    {props.onAddChat && (
                        <DandyChatInputExpanded
                            onFilePaste={props.chatProps.onFilePaste}
                            setCurrentConvo={setCurrentConvo}
                            currentConvo={currentConvo}
                            buttonProps={props.buttonProps}
                            submitting={props.chatProps.submitting}
                            newMessageText={newMessageText}
                            setNewMessageText={setNewMessageText}
                            onSubmit={onSubmit}
                            onKeyDown={onKeyDown}
                            chatActions={props.chatActions}
                            setExpanded={setInputExpanded}
                            expanded={inputExpanded}
                        />
                    )}
                </Grid>
            </LoadBlocker>
        </DandyChatBase>
    );
};
