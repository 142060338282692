import type { AbutmentPartFilters } from './AbutmentPartSelector';
import { formatAbutmentPartsSpecsText, fromPriorityNumberToIsPreferred } from './helpers';
import type { LabsGqlAbutmentPartToImplantAndScanbodyFragment } from '@orthly/graphql-operations';
import { SimpleAutocomplete, SimpleCheckbox } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

/**
 * This function allows us to return all the values to display in the dropdown filters for the abutment parts.
 *
 * Example: if we have 5 parts from the Dess or Creodent manufacture, we only want to display Dess and Creodent once in
 * the manufacturer dropdown. Then, for the next filter (material), we only expect to display values in that dropdown
 * if the previous filter has been filled. So if manufacturer is not set we just return empty. But if the manufacturer is set,
 * we want to make sure we first filter all the parts by this manufacturer, and then get all the possible material left on
 * the remaining parts. And so on for each filter.
 *
 * @param listParts Full list of parts fetched by the top level component
 * @param filters all the filters to filter down parts
 * @param field this is the specific filter for which we want to get the values to displayed
 * @returns all the unique values to show in the dropdown
 */

export const getPossibleAbutmentPartValues = (
    listParts: LabsGqlAbutmentPartToImplantAndScanbodyFragment[],
    filters: AbutmentPartFilters,
    field: keyof AbutmentPartFilters,
): string[] => {
    // First we filter all parts that are non-preferred if the "show preferred only" checkbox is checked
    const filteredListByPreferred = listParts.filter(
        its => fromPriorityNumberToIsPreferred(its.priority) === filters?.showPreferred,
    );

    switch (field) {
        case 'manufacturer':
            return _.uniq(filteredListByPreferred.map(p => p.abutment_part.manufacturer));
        case 'material':
            if (filters.manufacturer) {
                return _.uniq(
                    filteredListByPreferred
                        .filter(p => p.abutment_part.manufacturer === filters.manufacturer)
                        .map(p => p.abutment_part.material),
                );
            }
            return [];
        case 'engagement':
            if (filters.manufacturer && filters.material) {
                return _.uniq(
                    filteredListByPreferred
                        .filter(p => p.abutment_part.manufacturer === filters.manufacturer)
                        .filter(p => p.abutment_part.material === filters.material)
                        .map(p => p.abutment_part.engagement),
                );
            }
            return [];
        case 'specs':
            if (filters.manufacturer && filters.material && filters.engagement) {
                return _.uniq(
                    filteredListByPreferred
                        .filter(p => p.abutment_part.manufacturer === filters.manufacturer)
                        .filter(p => p.abutment_part.material === filters.material)
                        .filter(p => p.abutment_part.engagement === filters.engagement)
                        // Part specs is split in 3 fields, but in the UI we show them all in 1 dropdown for easier UX
                        .map(p => formatAbutmentPartsSpecsText(p.abutment_part)),
                );
            }
            return [];
        default:
            return [];
    }
};

interface AbutmentPartInputProps {
    filters: AbutmentPartFilters;
    onFieldUpdate: <T extends keyof AbutmentPartFilters>(fieldName: T, fieldValue: AbutmentPartFilters[T]) => void;
    listParts: LabsGqlAbutmentPartToImplantAndScanbodyFragment[];
}

export const AbutmentPartInput: React.VFC<AbutmentPartInputProps> = ({ filters, onFieldUpdate, listParts }) => (
    <div>
        <SimpleCheckbox
            checked={filters.showPreferred}
            setChecked={isChecked => {
                onFieldUpdate('showPreferred', isChecked);
            }}
            label={'Show Preferred Parts?'}
        />
        <Grid container spacing={1} alignItems={'center'}>
            <Grid container item xs={3}>
                <SimpleAutocomplete
                    AutocompleteProps={{ style: { width: '100%' } }}
                    key={`manufacturer_preferred_${filters.showPreferred}`}
                    label={'Part Manufacturer'}
                    options={getPossibleAbutmentPartValues(listParts, filters, 'manufacturer')}
                    onChange={val => onFieldUpdate('manufacturer', val ?? undefined)}
                />
            </Grid>
            <Grid container item xs={3}>
                <SimpleAutocomplete
                    AutocompleteProps={{ style: { width: '100%' } }}
                    label={'Abutment'}
                    options={getPossibleAbutmentPartValues(listParts, filters, 'material').map(val => ({
                        label: _.startCase(val),
                        value: val,
                    }))}
                    // uses manufacturer field so React creates a fresh component instance when manufacturer changes
                    // otherwise, the values in this auto complete don't refresh and show obsolete values
                    key={filters.manufacturer}
                    onChange={val => onFieldUpdate('material', val ?? undefined)}
                />
            </Grid>
            <Grid container item xs={3}>
                <SimpleAutocomplete
                    AutocompleteProps={{ style: { width: '100%' } }}
                    label={'Abutment Connection'}
                    options={getPossibleAbutmentPartValues(listParts, filters, 'engagement').map(val => ({
                        label: _.startCase(val),
                        value: val,
                    }))}
                    // uses material field so React creates a fresh component instance when material changes
                    // otherwise, the values in this auto complete don't refresh and show obsolete values
                    key={filters.material}
                    onChange={val => onFieldUpdate('engagement', val ?? undefined)}
                />
            </Grid>
            <Grid container item xs={3}>
                <SimpleAutocomplete
                    AutocompleteProps={{ style: { width: '100%' } }}
                    label={'Part Specs'}
                    options={getPossibleAbutmentPartValues(listParts, filters, 'specs')}
                    // uses engagement field so React creates a fresh component instance when engagement changes
                    // otherwise, the values in this auto complete don't refresh and show obsolete values
                    key={filters.engagement}
                    onChange={val => onFieldUpdate('specs', val ?? undefined)}
                />
            </Grid>
        </Grid>
    </div>
);
