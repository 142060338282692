import type { RequireChildrenProps } from '@orthly/ui';
import { stylesFactory, FlossPalette, Grid, Popover } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    root: {
        width: '25%',
        position: 'relative',
        cursor: 'pointer',
        borderLeft: `1px solid ${FlossPalette.DARK_TAN}`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
    },
    popperPaper: {
        left: 'unset !important',
        right: 0,
        width: 350,
        top: '64px !important',
        padding: 24,
        [theme.breakpoints.down('lg')]: { top: '56px !important' },
    },
    palatePopperPaper: {
        left: '8px !important',
        top: '96px !important',
        padding: '16px 24px 64px',
        borderRadius: 8,
        height: 400,
        maxWidth: 'unset',
    },
}));

export interface OrdersToolbarPopperContentProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

type WithContentProps<ContentProps extends {} = {}> = keyof ContentProps extends never
    ? { ContentProps?: undefined }
    : { ContentProps: ContentProps };

interface OrdersToolbarPopperProps<ContentProps extends {} = {}> extends RequireChildrenProps {
    PopoverContent: React.ComponentType<OrdersToolbarPopperContentProps & ContentProps>;
    popoverWidth?: number | string;
    rootStyle?: React.CSSProperties;
    open: boolean;
    setOpen: (open: boolean, isBackgroundClick: boolean) => void;
    palate?: boolean;
}

export function OrdersToolbarPopper<ContentProps extends {} = {}>(
    props: OrdersToolbarPopperProps<ContentProps> & WithContentProps<ContentProps>,
) {
    const { open, setOpen, PopoverContent } = props;
    const classes = useStyles();
    const wrapperRef = React.useRef<HTMLDivElement | null>(null);
    const popperWidth = (wrapperRef.current ?? { clientWidth: window.innerWidth * 0.1 }).clientWidth * 2;
    const contentProps = React.useMemo<OrdersToolbarPopperContentProps & ContentProps>(() => {
        return { open, setOpen: o => setOpen(o, false), ...props.ContentProps } as OrdersToolbarPopperContentProps &
            ContentProps;
    }, [open, setOpen, props.ContentProps]);
    return (
        <>
            <Grid ref={wrapperRef} container className={classes.root} style={props.rootStyle}>
                {props.children}
            </Grid>
            <Popover
                anchorReference={'none'}
                open={open}
                onClose={() => setOpen(false, true)}
                PaperProps={{
                    className: props.palate ? classes.palatePopperPaper : classes.popperPaper,
                    style: { width: props.popoverWidth ?? popperWidth },
                }}
            >
                <PopoverContent {...contentProps} />
            </Popover>
        </>
    );
}
