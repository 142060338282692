import type {
    FormItemValues,
    FormValues,
    LedgerRow,
    PriceHierarchy,
    SchemaFromOrderArgs,
} from './AdminLabsEditInvoicingLineItems.types';
import type { GetOrderLedgerQuery, OrderLedgerQuery } from '@orthly/graphql-inline-react';
import type {
    LabsGqlLineToothItemFragment,
    LabsGqlOrder,
    LabsGqlOrderFieldSubmissionFragment,
} from '@orthly/graphql-operations';
import { CurrencyUtils, dayjsExt as dayjs } from '@orthly/runtime-utils';
import _ from 'lodash';

export const REFUND_CONFIRMATION = `You are trying to set the amount due to be lower than what the dentist has already been billed.
Moving forward will issue a refund. Is this OK?`;

export const convertGetOrderLedgerQueryToTableData = (
    data: GetOrderLedgerQuery['getOrderLedger'] | OrderLedgerQuery['getOrderLedger'],
): LedgerRow[] => {
    const ledgerItems: LedgerRow[] = [];
    for (const item of data.billedItems) {
        const dollarAmount = item.amount_cents / 100;
        ledgerItems.push({
            id: item.id,
            type: item.category === 'order_refund' ? 'refund' : 'item',
            invoice: item.invoice_id,
            invoice_id: item.invoice_id,
            paid: data.paidInvoiceIds.includes(item.invoice_id),
            amount: dollarAmount,
            description: item.description,
            date: dayjs(item.created_at).format('MM/DD/YYYY LT'),
            isDeleted: false,
        });
    }
    if ('credits' in data) {
        for (const credit of data.credits) {
            if (credit.allocations?.length) {
                for (const allocation of credit.allocations) {
                    ledgerItems.push({
                        id: credit.id,
                        type: 'credit',
                        invoice: allocation.invoiceId,
                        invoice_id: allocation.invoiceId,
                        amount: (allocation.amountCents / 100) * -1,
                        date: dayjs(credit.created_at).format('MM/DD/YYYY LT'),
                        paid: false,
                        description: `${credit.attribution?.type} - ${credit.categoryName} - ${credit.description}`,
                        isDeleted: !!credit.deleted_at,
                    });
                }
            } else {
                ledgerItems.push({
                    id: credit.id,
                    type: 'credit',
                    invoice: 'Pending',
                    invoice_id: 'Pending',
                    amount: (credit.amount_issued_cents / 100) * -1,
                    date: dayjs(credit.created_at).format('MM/DD/YYYY LT'),
                    paid: false,
                    description: `${credit.attribution?.type} - ${credit.categoryName} - ${credit.description}`,
                    isDeleted: !!credit.deleted_at,
                });
            }
        }
    }
    if (data.pendingItem) {
        ledgerItems.push({
            id: '',
            type: 'item',
            invoice: 'Pending',
            invoice_id: '',
            paid: false,
            amount: data.pendingItem.amount_cents / 100,
            description: data.pendingItem.description,
            date: dayjs(data.pendingItem.created_at).format('MM/DD/YYYY LT'),
            isDeleted: false,
        });
    }
    return _.sortBy(ledgerItems, i => dayjs(i.date).unix());
};

export const staticColumns = [
    {
        title: 'Invoice',
        field: 'invoice',
        defaultGroupOrder: 0,
    },
    {
        title: 'Type',
        field: 'type',
    },
    {
        title: 'Date',
        field: 'date',
    },
    {
        title: 'Description',
        field: 'description',
        cellStyle: {
            minWidth: '200px',
        },
    },
];

export const quickFormFieldFromTooth = (tooth: LabsGqlLineToothItemFragment, idx: number) => {
    return {
        [`tooth_${idx}`]: {
            type: 'currency',
            precision: 2,
            fieldProps: { textAlign: 'left' },
            label: `${tooth.unit_type} (Tooth ${tooth.unn}${tooth.material ? `, ${tooth.material}` : ``})`,
        },
    };
};

export const quickFormFieldFromPreference = (pref: LabsGqlOrderFieldSubmissionFragment, idx: number) => {
    return {
        [`pref_${idx}`]: {
            type: 'currency',
            precision: 2,
            fieldProps: { textAlign: 'left' },
            label: `${pref.display_name}`,
        },
    };
};

export const quickFormInitialValueFromTooth = (tooth: LabsGqlLineToothItemFragment, idx: number) => {
    return {
        [`tooth_${idx}`]: ((tooth.amount_due_cents || 0) / 100).toFixed(2),
    };
};

export const quickFormInitialValueFromPreference = (pref: LabsGqlOrderFieldSubmissionFragment, idx: number) => {
    return {
        [`pref_${idx}`]: ((pref.price_cents || 0) / 100).toFixed(2),
    };
};

export const quickFormInitialValuesFromOrder = (order: LabsGqlOrder) => {
    return Object.assign(
        {},
        ...order.line_items.map(li => {
            return {
                [`${li.id}`]: {
                    ...Object.assign({}, ...li.teeth.map(quickFormInitialValueFromTooth)),
                    ...Object.assign({}, ...li.preference_fields.map(quickFormInitialValueFromPreference)),
                },
            };
        }),
    );
};

export const itemsPricesFromOrder = (order: LabsGqlOrder): PriceHierarchy[] => {
    return order.line_items.map(item => ({
        item_id: item.id,
        teeth: item.teeth.map((tooth: LabsGqlLineToothItemFragment, idx: number) => ({
            idx,
            amount_due_cents: tooth.amount_due_cents ?? 0,
        })),
        preferences: item.preference_fields.map((pref: LabsGqlOrderFieldSubmissionFragment, idx: number) => ({
            idx,
            amount_due_cents: pref.price_cents ?? 0,
        })),
    }));
};

export const priceChangeContainsDecrease = (
    oldPrices: PriceHierarchy | undefined,
    newPrices: PriceHierarchy | undefined,
): boolean => {
    if (!oldPrices || !newPrices) {
        return true;
    }
    return (
        oldPrices.teeth.some(oldToothPrice => {
            const newToothPrice = newPrices.teeth.find(p => p.idx === oldToothPrice.idx);
            if (!newToothPrice) {
                return true;
            }
            return oldToothPrice.amount_due_cents > newToothPrice.amount_due_cents;
        }) ||
        oldPrices.preferences.some(oldPrefPrice => {
            const newPrefPrice = newPrices.preferences.find(p => p.idx === oldPrefPrice.idx);
            if (!newPrefPrice) {
                return true;
            }
            return oldPrefPrice.amount_due_cents > newPrefPrice.amount_due_cents;
        })
    );
};

export const omitOverrideReasons = (priceHierarchy: PriceHierarchy | undefined): PriceHierarchy | undefined => {
    if (!priceHierarchy) {
        return priceHierarchy;
    }
    return {
        ...priceHierarchy,
        teeth: priceHierarchy.teeth.map(tooth => _.omit(tooth, 'override_reason')),
        preferences: priceHierarchy.preferences.map(pref => _.omit(pref, 'override_reason')),
    };
};

export const priceHierarchyEqual = (a: PriceHierarchy | undefined, b: PriceHierarchy | undefined): boolean => {
    return _.isEqual(omitOverrideReasons(a), omitOverrideReasons(b));
};

export const quickFormSchemaFromOrder = (args: SchemaFromOrderArgs) => {
    const { order, showingPreferences, orderItemsPrices, formItemsPrices } = args;

    return Object.assign(
        {},
        ...order.line_items.map((li, idx) => {
            return {
                [`${li.id}`]: {
                    type: 'nested',
                    label: `Item #${idx + 1}`,
                    fields: {
                        ...Object.assign({}, ...li.teeth.map(quickFormFieldFromTooth)),
                        ...(showingPreferences
                            ? Object.assign({}, ...li.preference_fields.map(quickFormFieldFromPreference))
                            : {}),
                        reason: {
                            type: 'text',
                            label: 'Override Reason',
                            hidden: priceHierarchyEqual(orderItemsPrices[idx], formItemsPrices[idx]),
                            optional: !priceChangeContainsDecrease(orderItemsPrices[idx], formItemsPrices[idx]),
                        },
                    },
                },
            };
        }),
    );
};

export const itemsPricesFromQuickformValues = (result: FormValues): PriceHierarchy[] => {
    return _.map(result, (item, item_id) => {
        const tooth_prices = _.pickBy(item, (_val: string | undefined, key: string) => key.split('_')[0] === 'tooth');
        const pref_prices = _.pickBy(item, (_val: string | undefined, key: string) => key.split('_')[0] === 'pref');
        return {
            item_id,
            teeth: _.map(tooth_prices, (tooth, key) => ({
                idx: parseInt(key.split('_')[1] ?? ''),
                amount_due_cents: CurrencyUtils.dollarStringToCents(tooth),
                override_reason: item.reason,
            })),
            preferences: _.map(pref_prices, (pref, key) => ({
                idx: parseInt(key.split('_')[1] ?? ''),
                amount_due_cents: CurrencyUtils.dollarStringToCents(pref),
                override_reason: item.reason,
            })),
        };
    });
};

export const totalFromItemPrices = (items: FormItemValues[]) => {
    const total = _.sumBy(items, item => {
        return _.sumBy(_.values(_.omit(item, 'reason')), (toothOrPref: string) =>
            parseFloat(toothOrPref.replace(',', '')),
        );
    });
    // handle floating point precision error
    return Math.round(100 * total) / 100;
};
