import type { Point } from '../Util';

// Handles appear when an object is selected. Clicking and dragging
// a handle resizes or repositions the object.
export interface Handle {
    center: Point;
    cursor: string;
    actionForMove(point: Point): () => void;
}

export abstract class CanvasObject {
    // Draws the object in `ctx`. If `hitTestColor` is non-null, should draw
    // for hit testing using that color.
    abstract draw(ctx: CanvasRenderingContext2D, hitTestColor?: string): void;

    // An array of handles to show when the object is selected.
    abstract handles(): Handle[];

    // A special handle used when the object itself is clicked & dragged.
    abstract grabHandle(): Handle;
}
