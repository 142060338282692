import { RefabAcknowledgeReturn } from '../components/RefabAcknowledgeReturn';
import { RefabBodyContainer } from '../components/RefabBodyContainer';
import { useRefabFlowAction, useRefabFlowSelector } from '../state/refab-flow-state';
import { getItemsToReturn } from '../utils';
import { useRefabFlowContext } from './refab-flow-context';
import { LoadBlocker, SimpleInput } from '@orthly/ui';
import { Text, stylesFactory, useScreenIsMobileOrVerticalTablet, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    contentWrapper: { display: `flex`, gap: `32px` },
}));

export const RefabAdditionalNotes: React.VFC = () => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { order, submitting } = useRefabFlowContext();
    const notes = useRefabFlowSelector(s => s.supplementaryNotes);
    const itemwiseReasonCodes = useRefabFlowSelector(s => s.itemwiseReasonCodes);
    const patchState = useRefabFlowAction('PATCH_STATE');
    const setNotes = (notes: string | undefined) => {
        patchState({ supplementaryNotes: notes ?? '' });
    };
    const itemsToReturn = getItemsToReturn(order, itemwiseReasonCodes);
    const [hasAckedReturnPolicy, setHasAckedReturnPolicy] = React.useState(false);
    const hasPassedReturnValidation = itemsToReturn.length > 0 ? hasAckedReturnPolicy : true;

    return (
        <RefabBodyContainer
            title={<Text variant={isMobile ? 'h5' : 'h3'}>Optionally, what else do you want to share?</Text>}
            nextTitle={'Next'}
            disableNext={!hasPassedReturnValidation}
        >
            <LoadBlocker blocking={submitting}>
                <Grid direction={'column'} className={classes.contentWrapper}>
                    <SimpleInput
                        onChange={setNotes}
                        value={notes}
                        label={'Additional instructions'}
                        placeholder={'Comment on what our technicians should keep in mind'}
                        TextFieldProps={{
                            multiline: true,
                            rows: 5,
                            style: { width: isMobile ? '100%' : '450px' },
                        }}
                    />

                    {itemsToReturn.length > 0 && (
                        <RefabAcknowledgeReturn
                            hasAcked={hasAckedReturnPolicy}
                            setHasHacked={setHasAckedReturnPolicy}
                            itemsToReturn={itemsToReturn}
                        />
                    )}
                </Grid>
            </LoadBlocker>
        </RefabBodyContainer>
    );
};
