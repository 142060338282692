import type { Point } from '../Util';
import { HIT_TEST_MARGIN } from '../Util';
import type { Handle } from './CanvasObject';
import { RectLikeObject } from './RectLikeObject';

export type ShapeType = 'oval' | 'rect';

export interface ShapeParams {
    type: ShapeType;
    color: string;
}

export class ShapeObject extends RectLikeObject {
    params: ShapeParams;

    constructor(params: ShapeParams, firstCorner: Point, secondCorner: Point) {
        super();
        this.params = params;
        this.x = Math.min(firstCorner[0], secondCorner[0]);
        this.y = Math.min(firstCorner[1], secondCorner[1]);
        this.width = Math.abs(firstCorner[0] - secondCorner[0]);
        this.height = Math.abs(firstCorner[1] - secondCorner[1]);
    }

    draw(ctx: CanvasRenderingContext2D, hitTestColor?: string) {
        ctx.lineWidth = 5 + (hitTestColor ? 2 * HIT_TEST_MARGIN : 0);
        ctx.strokeStyle = hitTestColor ?? this.params.color;

        if (this.params.type === 'rect') {
            ctx.strokeRect(this.x, this.y, this.width, this.height);
        } else {
            ctx.beginPath();
            ctx.ellipse(
                this.x + this.width / 2,
                this.y + this.height / 2,
                this.width / 2,
                this.height / 2,
                0,
                0,
                2 * Math.PI,
            );
            ctx.stroke();
        }
    }

    private handleForResize(
        center: Point,
        opposite: Point,
        cursor: string,
        reshapeFunction: (a: Point, b: Point) => void,
    ) {
        return {
            center,
            cursor,
            actionForMove(point: Point) {
                return () => {
                    reshapeFunction(opposite, point);
                };
            },
        };
    }

    private handleForVerticalResize(center: Point, opposite: Point): Handle {
        return this.handleForResize(center, opposite, 'ns-resize', this.reshapeVertically.bind(this));
    }

    private handleForHorizontalResize(center: Point, opposite: Point): Handle {
        return this.handleForResize(center, opposite, 'ew-resize', this.reshapeHorizontally.bind(this));
    }

    handles() {
        return [
            ...this.handlesForCorners(false),
            this.handleForVerticalResize(this.point(0.5, 0), this.point(0.5, 1)),
            this.handleForVerticalResize(this.point(0.5, 1), this.point(0.5, 0)),
            this.handleForHorizontalResize(this.point(0, 0.5), this.point(1, 0.5)),
            this.handleForHorizontalResize(this.point(1, 0.5), this.point(0, 0.5)),
        ];
    }

    private reshapeHorizontally(p1: Point, p2: Point) {
        this.reshape([p1[0], this.y], [p2[0], this.y + this.height]);
    }

    private reshapeVertically(p1: Point, p2: Point) {
        this.reshape([this.x, p1[1]], [this.x + this.width, p2[1]]);
    }
}
