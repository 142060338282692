import { Grid, IconButton, Text, EditIcon, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

interface InlineEditProps {
    label: React.ReactNode;
    textStyle?: React.CSSProperties;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
}

export const InlineEditButton: React.FC<InlineEditProps> = props => {
    const { label, onClick, textStyle, disabled } = props;
    return (
        <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text style={textStyle}>{label}</Text>
            <IconButton onClick={onClick} disabled={disabled}>
                <EditIcon sx={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
            </IconButton>
        </Grid>
    );
};
