import { useCurrentOrderDetailId, useOpenOrderDetailOps } from '../../utils/router/useOpenOrderDetail';
import type { OrderListItemProps } from '../OrderListItem/OrderListItem.utils';
import { orderListItemPropsEqual } from '../OrderListItem/OrderListItem.utils';
import { OrderDetailSidebarItemLayout, OrderDetailSidebarItemLoading } from './OrderDetailSidebarItemLayout';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { OrderFormatUtils } from '@orthly/shared-types';
import {
    OrderPaperIcon,
    OverflowTextWithTooltip,
    PalmIcon,
    RefabCircleIcon,
    ToolOutlinedIcon,
    TruckIconOutlined,
    WarningIcon,
} from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    type SvgIconProps,
    Text,
    Grid,
    CancelIcon,
    CheckIcon,
} from '@orthly/ui-primitives';
import type { OrderOpsListItemContentProps } from '@orthly/veneer';
import { OrderListItemFlags } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    statusIcon: { marginRight: 4, width: 16, height: 16, color: FlossPalette.GRAY },
    secondaryText: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
    itemsIndicator: {
        backgroundColor: FlossPalette.GRAY,
        height: 16,
        width: 16,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
        marginTop: 2,
    },
}));

const StatusIconMap: { [K in LabsGqlLabOrderStatus]-?: React.ComponentType<SvgIconProps> } = {
    [LabsGqlLabOrderStatus.New]: OrderPaperIcon,
    [LabsGqlLabOrderStatus.NeedsReview]: WarningIcon,
    [LabsGqlLabOrderStatus.OnHold]: PalmIcon,
    [LabsGqlLabOrderStatus.Fabrication]: ToolOutlinedIcon,
    [LabsGqlLabOrderStatus.Shipped]: TruckIconOutlined,
    [LabsGqlLabOrderStatus.Delivered]: CheckIcon,
    [LabsGqlLabOrderStatus.Cancelled]: CancelIcon,
    [LabsGqlLabOrderStatus.NeedsRefabrication]: RefabCircleIcon,
};

const OrderDetailSidebarItemContent: React.FC<{ listItemContent: OrderOpsListItemContentProps }> = props => {
    const classes = useStyles();
    const selectedOrderId = useCurrentOrderDetailId();
    const openOrder = useOpenOrderDetailOps();
    const { flags, review_flag_reasons, order, productsText, manufacturer_name, partner_name } = props.listItemContent;
    const Icon = React.useMemo(() => StatusIconMap[order.status], [order.status]);
    return (
        <OrderDetailSidebarItemLayout selected={order.id === selectedOrderId} onClick={e => openOrder(order.id, e)}>
            <Grid container alignItems={'center'} style={{ position: 'relative' }} wrap={'nowrap'}>
                <Grid item container xs={10} alignItems={'center'} style={{ position: 'relative' }} wrap={'nowrap'}>
                    <Icon className={classes.statusIcon} />
                    <OverflowTextWithTooltip
                        medium={true}
                        text={`${order.patient.first_name} ${order.patient.last_name}`}
                        color={'BLACK'}
                        variant={'body2'}
                    />
                </Grid>
                {order?.patient_order_count > 1 && (
                    <Grid
                        item
                        container
                        xs={2}
                        alignItems={'center'}
                        style={{ position: 'relative' }}
                        justifyContent={'flex-end'}
                    >
                        <Grid container className={classes.itemsIndicator}>
                            <Text
                                variant={'caption'}
                                style={{ color: FlossPalette.TAN, fontWeight: 500, lineHeight: 0 }}
                            >
                                {order?.patient_order_count}
                            </Text>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid container wrap={'wrap'}>
                <OrderListItemFlags small flags={flags} review_flag_reasons={review_flag_reasons} />
            </Grid>
            <Grid container wrap={'nowrap'} style={{ paddingBottom: 2 }}>
                <Text variant={'caption'} color={'DARK_GRAY'} className={classes.secondaryText}>
                    {productsText}
                </Text>
            </Grid>
            <Grid container wrap={'nowrap'}>
                <Text variant={'caption'} color={'DARK_GRAY'} className={classes.secondaryText}>
                    {OrderFormatUtils.getDisplayedStaffMemberName(order.doctor_name)} • {partner_name} •{' '}
                    {manufacturer_name}
                </Text>
            </Grid>
        </OrderDetailSidebarItemLayout>
    );
};

export const OrderDetailSidebarItem = React.memo<OrderListItemProps>(props => {
    if (!props.listItemContent) {
        return <OrderDetailSidebarItemLoading />;
    }
    return <OrderDetailSidebarItemContent listItemContent={props.listItemContent} />;
}, orderListItemPropsEqual);
