import { FlossPalette, Text, Grid, makeStyles, createStyles } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        capabilityTitleText: {
            marginTop: 20,
        },
        capabilitySubtitleText: {
            color: FlossPalette.GRAY,
            marginTop: 12,
        },
    }),
);

export const CapabilityCardTitle: React.FC<{
    title: string;
    editButtonText: string;
    subtitleText: string;
    children?: React.ReactNode;
}> = props => {
    const { title, subtitleText, children } = props;
    const classes = useStyles();

    return (
        <>
            <Grid container item direction={'row'} justifyContent={'space-between'}>
                <Grid item>
                    <Text variant={'body1'} medium className={classes.capabilityTitleText}>
                        {title}
                    </Text>
                </Grid>
                <Grid item>{children}</Grid>
            </Grid>
            <Text variant={'body2'} className={classes.capabilitySubtitleText}>
                {subtitleText}
            </Text>
        </>
    );
};
