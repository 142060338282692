import {
    ProductionPlanDebuggerStep_Fragment,
    ProductionPlanDebuggerView_Fragment,
    ProductionPlanPotentialAssignment_Fragment,
} from './PlanFragments.graphql';
import { PlanRunWorkflowsAction } from './PlanRunWorkflows.graphql';
import { PlansGantt } from './PlansGantt';
import { PlansSummary } from './PlansSummary';
import { useQuery } from '@apollo/client';
import type { ProductionPlanDebuggerViewFragment, PreviewProductionPlanQuery } from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import { UuidUtils } from '@orthly/runtime-utils';
import { ActionCard, RootActionDialog } from '@orthly/ui';
import { Text, Box, Button, Tooltip, Grid, TextInput, styled, FlossPalette } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import 'gantt-task-react/dist/index.css';
import _ from 'lodash';
import React from 'react';
import { Chart } from 'react-google-charts';

const PlanPreviewSectionTitle = styled(Text)({
    variant: 'h3',
    marginBottom: 16,
});

const PlanPreviewSection = styled(Box)({
    padding: 16,
    backgroundColor: FlossPalette.TAN,
    borderRadius: 8,
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    marginBottom: 24,
});

const PreviewProductionPlan_Query = graphql(`
    query PreviewProductionPlan($orderId: String!) {
        previewProductionPlanForLabOrder(orderId: $orderId) {
            ...ProductionPlanDebuggerView
        }
        listOrganizations(filter: { type: lab }) {
            id
            name
        }
    }
`);

const GetProductionPlan_Query = graphql(`
    query GetProductionPlan($orderId: String!) {
        getProductionPlanForOrder(orderId: $orderId) {
            ...ProductionPlanDebuggerView
        }
        listOrganizations(filter: { type: lab }) {
            id
            name
        }
    }
`);

const PotentialAssignments: React.VFC<{ data: PreviewProductionPlanQuery }> = ({ data }) => {
    const result = getFragmentData(ProductionPlanDebuggerView_Fragment, data.previewProductionPlanForLabOrder);
    const orgs = Object.fromEntries(data.listOrganizations.map(o => [o.id, o.name]));
    const requirements = getFragmentData(ProductionPlanDebuggerStep_Fragment, result.requirementSchedule).filter(r =>
        r.id.startsWith('Standard Reqs-'),
    );
    const requirementTypeLookup = _.keyBy(requirements, r => r.id.substring('Standard Reqs-'.length));
    const assignments = getFragmentData(ProductionPlanPotentialAssignment_Fragment, result.potentialAssignments);
    const chartData = [
        [
            { type: 'string', id: 'Room' },
            { type: 'string', id: 'Name' },
            { type: 'string', role: 'tooltip' },
            { type: 'date', id: 'Start' },
            { type: 'date', id: 'End' },
        ],
        ...assignments.map(a => [
            orgs[a.organizationId] ?? 'unknown organization',
            requirementTypeLookup[a.requirementId]?.type,
            a.tooltipMeta.map(t => `${t.label}: ${t.value}`).join('<br />'),
            new Date(a.capacityWindowStart),
            new Date(a.capacityWindowEnd),
        ]),
    ];
    if (assignments.length === 0) {
        return null;
    }
    return (
        <Chart
            chartType={'Timeline'}
            width={'100%'}
            height={'400px'}
            data={chartData}
            options={{
                timeline: {
                    showRowLabels: true,
                    groupByRowLabel: true,
                },
            }}
        />
    );
};

const ViewExistingPlanDialog: React.VFC<{
    open: boolean;
    setOpen: (open: boolean) => void;
    loading: boolean;
    existingResult?: ProductionPlanDebuggerViewFragment | null;
    refetch: () => void;
}> = ({ open, setOpen, loading, existingResult, refetch }) => {
    const openExistingViewButton = React.useMemo(() => {
        return (
            <Tooltip title={existingResult ? 'View existing plan' : 'No plan exists for order'}>
                <span>
                    <Button variant={'primary'} onClick={() => setOpen(true)} disabled={!existingResult}>
                        View Existing Plan
                    </Button>
                </span>
            </Tooltip>
        );
    }, [setOpen, existingResult]);

    return (
        <RootActionDialog
            loading={loading}
            open={open}
            setOpen={setOpen}
            title={'Existing Plan:'}
            showCloseButton={true}
            maxWidth={'xl'}
            fullHeight={true}
            content={
                <>
                    {existingResult?.plans.length && <PlansSummary result={existingResult} />}
                    {existingResult && <PlansGantt result={existingResult} />}
                    {!existingResult?.plans.length && !loading && <Text>Production order has no plans</Text>}
                </>
            }
            CustomButton={() => openExistingViewButton}
            actions={
                <Button variant={'secondary'} onClick={() => refetch()}>
                    Reload
                </Button>
            }
        />
    );
};

const PreviewPotentialPlansDialog: React.VFC<{
    orderId: string;
    open: boolean;
    existingPlan: boolean;
    setOpen: (open: boolean) => void;
}> = ({ orderId, open, setOpen }) => {
    const {
        loading,
        data,
        error: previewError,
        refetch,
    } = useQuery(PreviewProductionPlan_Query, {
        variables: { orderId: orderId },
        skip: !UuidUtils.isUUID(orderId),
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });

    const previewResult = getFragmentData(ProductionPlanDebuggerView_Fragment, data?.previewProductionPlanForLabOrder);
    const uniqPlanCount = previewResult
        ? _.uniq(getFragmentData(ProductionPlanDebuggerStep_Fragment, previewResult?.plans).map(p => p.project))
              .length - 1
        : 0;

    const openPotentialPlansViewButton = React.useMemo(() => {
        return (
            <Tooltip title={'View potential plans'}>
                <span>
                    <Button variant={'secondary'} onClick={() => setOpen(true)}>
                        View Potential Plans
                    </Button>
                </span>
            </Tooltip>
        );
    }, [setOpen]);

    return (
        <RootActionDialog
            loading={loading}
            open={open}
            setOpen={setOpen}
            title={'Potential Plans:'}
            showCloseButton={true}
            maxWidth={'xl'}
            fullHeight={true}
            content={
                <div>
                    <PlanPreviewSectionTitle>Summaries:</PlanPreviewSectionTitle>
                    <PlanPreviewSection>{previewResult && <PlansSummary result={previewResult} />}</PlanPreviewSection>
                    <PlanPreviewSectionTitle>Potential Assignments:</PlanPreviewSectionTitle>
                    <PlanPreviewSection>{data && <PotentialAssignments data={data} />}</PlanPreviewSection>
                    <Grid container direction={'row'} justifyContent={'space-between'}>
                        <Grid item>
                            <PlanPreviewSectionTitle>Plans:</PlanPreviewSectionTitle>
                        </Grid>
                    </Grid>
                    <PlanPreviewSection>
                        {previewResult?.resultsAreFiltered && (
                            <ActionCard
                                title={'Plan list has been filtered for size'}
                                subtitle={
                                    'The highest value plan for each combination of organizations and arrival dates is chosen.'
                                }
                                variant={'info'}
                            />
                        )}
                        <PlanPreviewSectionTitle variant={'body2'} bold>
                            {`Showing ${uniqPlanCount} out of ${previewResult?.totalPlansConsidered} valid plans considered`}
                        </PlanPreviewSectionTitle>
                        {previewResult && <PlansGantt result={previewResult} />}
                    </PlanPreviewSection>
                    {previewError && (
                        <ActionCard
                            title={`Failed to load plan previews.`}
                            subtitle={String(previewError)}
                            variant={'alert'}
                        />
                    )}
                </div>
            }
            CustomButton={() => openPotentialPlansViewButton}
            actions={
                <Button variant={'secondary'} onClick={() => refetch()}>
                    Reload
                </Button>
            }
        />
    );
};

const TextInputStyled = styled(TextInput)({ width: 300 });

interface ProductionPlansRootProps {
    orderId?: string;
}

export const ProductionPlansRoot: React.FC<ProductionPlansRootProps> = ({ orderId: orderIdInput }) => {
    const [orderId, setOrderId] = React.useState(orderIdInput);
    const [openExistingView, setOpenExistingView] = React.useState<boolean>(false);
    const [openPreviewView, setOpenPreviewView] = React.useState<boolean>(false);
    const { value: showManualProductionOrderWorkflowRunButton } = useFeatureFlag(
        'showManualProductionOrderWorkflowRunButton',
    );

    const {
        loading: loadingExisting,
        data: existingPlan,
        error: existingError,
        refetch: refetchExistingPlan,
    } = useQuery(GetProductionPlan_Query, {
        variables: { orderId: orderId ?? '' },
        // prevents duplicate refetch when the preview loads
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        skip: !orderId,
    });
    const existingResult = getFragmentData(
        ProductionPlanDebuggerView_Fragment,
        existingPlan?.getProductionPlanForOrder,
    );

    return (
        <div style={{ padding: 8 }}>
            {/* If there's no order ID provided by the parent component we show the input field to enter it. */}
            {!orderIdInput && (
                <Grid container style={{ paddingBottom: 8 }}>
                    <TextInputStyled
                        placeholder={'Lab Order ID'}
                        label={'Order ID to Generate Plan'}
                        value={orderId ?? ''}
                        onChange={value => setOrderId(value ?? undefined)}
                    />
                </Grid>
            )}
            {existingResult?.errorState && (
                <ActionCard
                    title={'Production plan is in an errored state.'}
                    subtitle={'Plan may not be up to date with lab order.'}
                    variant={'alert'}
                />
            )}
            <Grid container spacing={3}>
                <Grid item>
                    <ViewExistingPlanDialog
                        open={openExistingView}
                        setOpen={setOpenExistingView}
                        loading={loadingExisting}
                        existingResult={existingResult}
                        refetch={refetchExistingPlan}
                    />
                </Grid>
                <Grid item>
                    {orderId && (
                        <PreviewPotentialPlansDialog
                            orderId={orderId}
                            open={openPreviewView}
                            setOpen={setOpenPreviewView}
                            existingPlan={!!existingResult}
                        />
                    )}
                </Grid>
                {existingResult?.id && showManualProductionOrderWorkflowRunButton && (
                    <Grid item>
                        <PlanRunWorkflowsAction productionOrderId={existingResult.id} onSuccess={refetchExistingPlan} />
                    </Grid>
                )}
            </Grid>
            <Text variant={'caption'}>
                This feature is in testing, and doesn't represent the production behavior for this order.
            </Text>
            {existingError && (
                <ActionCard
                    title={`Failed to load existing plan.`}
                    subtitle={String(existingError)}
                    variant={'alert'}
                />
            )}
        </div>
    );
};
