import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import { LabsGqlLabPriceRuleType, LabsGqlOrderItemModelType } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';

export const ImplantModelForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.ImplantModel>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.ImplantModel>({
                model_type: {
                    type: 'select',
                    label: 'Model Type',
                    options: Object.values(LabsGqlOrderItemModelType),
                    optional: false,
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          model_type:
                              price.rule.__typename === 'LabPriceImplantModelRuleDTO' ? price.rule.model_type : null,
                      }
                    : {}
            }
            onSubmit={async result => {
                await submit({
                    name: result.name,
                    rule: {
                        type: LabsGqlLabPriceRuleType.ImplantModel,
                        model_type: result.model_type,
                    },
                });
            }}
            disabled={disabled}
        />
    );
};
