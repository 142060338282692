import { useQueryParam } from '../../hooks';
import { DEV_MODAL_PARAM } from './devModalConstants';
import React from 'react';

export const useOpenDevModal = (): [boolean, (value: boolean) => void] => {
    const [devModal, setDevModal] = useQueryParam(DEV_MODAL_PARAM);

    const open = devModal === 'open';
    const setOpen = React.useCallback(
        (value: boolean) => {
            if (value) {
                setDevModal('open');
            } else {
                setDevModal(undefined);
            }
        },
        [setDevModal],
    ) as (value: boolean) => void;

    return [open, setOpen];
};
