import { AnalyticsClient } from '../../../../../../../utils/analyticsClient';
import type { AdminLabsOrderActionProps } from '../order-action-types';
import { DesignConfigDropdown } from './DesignConfigDropdown';
import { useConfigureDesignerTypeMutation } from '@orthly/graphql-react';
import type { StaffRole } from '@orthly/retainer-common';
import { useSession } from '@orthly/session-client';
import { LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import { useIsDesignActionEditable } from '@orthly/veneer';
import React from 'react';

const OPTION_YES = { label: 'Yes', value: true };
const OPTION_NO = { label: 'No', value: false };

const ROLES_ALLOWED_TO_EDIT: StaffRole[] = ['admin', 'internal__designManager', 'internal__developer'];

export const EditAutomateReviewRequired: React.FC<AdminLabsOrderActionProps> = props => {
    const { order, refetchOrder } = props;
    const { internal_design_required, internal_design_prep_required, enable_threeshape_automate_review } =
        order.fulfillment_workflow.configuration;

    const [submitMtn] = useConfigureDesignerTypeMutation();
    const { submit, submitting } = useChangeSubmissionFn<any, [boolean]>(
        newValue => {
            AnalyticsClient.track('Ops - Portal - Automate Review Task Toggled', {
                $groups: { order: order.id },
                enabled: newValue,
            });

            return submitMtn({
                variables: {
                    internal_design_required,
                    internal_design_prep_required,
                    separate_model_design_step: null,
                    automate_review_required: newValue,
                    id: order.id,
                },
            });
        },
        {
            successMessage: () => [
                `Automate review ${!enable_threeshape_automate_review ? 'enabled' : 'disabled'}`,
                {},
            ],
            onSuccess: async () => {
                await refetchOrder();
            },
        },
    );

    const isEditable = useIsDesignActionEditable(order);

    const session = useSession();
    const canEdit = ROLES_ALLOWED_TO_EDIT.some(el => session?.roles.includes(el));

    const editingDisabled = !(isEditable && canEdit);

    return (
        <LoadBlocker
            blocking={submitting}
            overlayColor={'transparent'}
            ContainerProps={{ style: { alignItems: 'center' } }}
        >
            <DesignConfigDropdown<boolean>
                options={[OPTION_YES, OPTION_NO]}
                selected={enable_threeshape_automate_review ? OPTION_YES : OPTION_NO}
                submit={async value => {
                    if (
                        value !== enable_threeshape_automate_review &&
                        window.confirm(`${value ? 'Enable' : 'Disable'} Automate review in the workflow?`)
                    ) {
                        await submit(value);
                        return true;
                    }
                    return false;
                }}
                disabled={editingDisabled}
            />
        </LoadBlocker>
    );
};
