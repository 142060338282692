import type { SplitInfo } from './state/Splits.types';
import type { LabsGqlOrganizationDtoFragment } from '@orthly/graphql-operations';
import { PencilOutlinedIcon, QuickForm, RootActionDialog } from '@orthly/ui';
import { IconButton } from '@orthly/ui-primitives';
import React from 'react';

export type ChangeSplitManufacturerProps = {
    split: SplitInfo;
    manufacturers: readonly LabsGqlOrganizationDtoFragment[];
    onSubmit: (newManufacturerId: string) => void;
};

export const ChangeSplitManufacturerAction: React.FC<ChangeSplitManufacturerProps> = props => {
    const [open, setOpen] = React.useState(false);

    const { onSubmit, split, manufacturers } = props;

    return (
        <RootActionDialog
            loading={false}
            open={open}
            setOpen={setOpen}
            title={'Edit Lab'}
            content={
                <QuickForm<{ manufacturer_id: string }>
                    onSubmit={values => {
                        if (!values.manufacturer_id) {
                            return;
                        }
                        onSubmit(values.manufacturer_id);
                        setOpen(false);
                    }}
                    fields={{
                        manufacturer_id: {
                            type: 'select',
                            label: 'Manufacturer',
                            options: manufacturers.map(m => ({ value: m.id, label: m.name })),
                        },
                    }}
                    initialValues={{ manufacturer_id: split.manufacturerId }}
                />
            }
            buttonText={''}
            CustomButton={() => (
                <IconButton onClick={() => setOpen(true)}>
                    <PencilOutlinedIcon />
                </IconButton>
            )}
            showCloseButton={true}
        />
    );
};
