import ShadeCaptureInstructionsGif from '../../../../assets/images/refab/ShadeCaptureInstructions.gif';
import { useRefabFlowContext } from '../screens/refab-flow-context';
import { useRefabFlowAction, useRefabFlowSelector } from '../state/refab-flow-state';
import type { RefabAttachment } from '../state/refab-flow-types';
import { Medium } from '@orthly/ui';
import { Text, FlossPalette, useScreenIsMobileOrVerticalTablet, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface RefabShadeAttachmentsProps {
    onUploadingChange: (isUploading: boolean) => void;
}

export const RefabShadeAttachments: React.VFC<RefabShadeAttachmentsProps> = ({ onUploadingChange }) => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { order, RefabFileUploader, useGenerateUploaderConfig } = useRefabFlowContext();
    const { qrCode, textUploadLink } = useGenerateUploaderConfig();

    const attachments = useRefabFlowSelector(s => s.attachments ?? []);
    const patchState = useRefabFlowAction('PATCH_STATE');

    const setAttachments = React.useCallback(
        (attachments: RefabAttachment[]) => {
            patchState({ attachments });
        },
        [patchState],
    );

    if (!order) {
        return null;
    }

    return (
        <Grid container direction={isMobile ? 'column' : 'row'}>
            <Grid direction={'column'}>
                <Text variant={'body2'}>
                    Capture a <Medium>single image</Medium> with <Medium>3 shade tabs</Medium>
                </Text>
                <div style={{ margin: `16px 0 0` }}>
                    <img
                        src={ShadeCaptureInstructionsGif}
                        width={328}
                        style={{ border: `1px solid ${FlossPalette.BLACK}`, borderRadius: `4px` }}
                    />
                </div>
                <Text variant={'caption'} color={'GRAY'}>
                    Shade capture guide
                </Text>
            </Grid>
            {!isMobile && (
                // Separator for desktop view
                <div
                    style={{
                        height: `100%`,
                        width: `1px`,
                        backgroundColor: FlossPalette.DARK_TAN,
                        marginLeft: 100,
                        marginRight: 100,
                    }}
                />
            )}
            <Grid style={{ flex: 1, marginTop: isMobile ? `16px` : `0px` }}>
                <RefabFileUploader
                    attachments={attachments}
                    setAttachments={setAttachments}
                    orderId={order.id}
                    qrCode={qrCode}
                    textUploadLink={textUploadLink}
                    onUploadingChange={onUploadingChange}
                />
            </Grid>
        </Grid>
    );
};
