import type { IOperationsManager } from './OperationsManager.types';
import {
    buildMeshAdjacencyAndVerticesFaces,
    getInsertionAxisFromOrientation,
    cropGeometryNearCenter,
    makeCurtainGeometry,
} from '@orthly/forceps';
import type { MeshConnectivityGraph } from '@orthly/forceps';
import type { ToothNumber } from '@orthly/items';
import { ToothUtils } from '@orthly/items';
import { Jaw } from '@orthly/shared-types';
import * as THREE from 'three';

type MinimalOperationsManager = Pick<
    IOperationsManager,
    'editToothNumber' | 'editGeometry' | 'prepScan' | 'curtainGeometry'
>;

/**
 * Generates undercut curtains geometries
 */
export class CurtainsGenerator {
    private prepSectionMap: Map<ToothNumber, THREE.BufferGeometry> = new Map();
    private prepScanGraphMap: Map<Jaw, MeshConnectivityGraph> = new Map();

    constructor(private opManager: MinimalOperationsManager) {}

    /**
     * Updates the undercut curtains geometry for the current tooth given a new insertion axis
     * @param insertionOrientation The new insertion orientation
     */
    updateCurtains(insertionOrientation: THREE.Quaternion): void {
        const prepSection = this.getPrepSection(this.opManager.editToothNumber);

        const insertionDirection = getInsertionAxisFromOrientation(insertionOrientation);

        const curtains = makeCurtainGeometry(prepSection, this.opManager.editGeometry, insertionDirection);
        curtains.computeVertexNormals();

        this.opManager.curtainGeometry?.copy(curtains);
        // Delete the BVH as it is no longer valid. It will be lazily recalculated where it is used.
        delete this.opManager.curtainGeometry?.boundsTree;
    }

    private getPrepSection(toothNumber: ToothNumber): THREE.BufferGeometry {
        const cachedPrepSection = this.prepSectionMap.get(toothNumber);
        if (cachedPrepSection) {
            return cachedPrepSection;
        }

        const prepScanGraph = this.getPrepScanGraph(ToothUtils.toothIsUpper(toothNumber) ? Jaw.UPPER : Jaw.LOWER);

        this.opManager.editGeometry.computeBoundingBox();
        const boundingBox = this.opManager.editGeometry.boundingBox;
        if (!boundingBox) {
            throw new Error('Failed to get the restorative geometry bounding box');
        }

        const center = boundingBox.getCenter(new THREE.Vector3());
        const boxRadius = center.distanceTo(boundingBox.max);
        const cropRadius = Math.max(10, boxRadius);

        const croppedPrepScan = cropGeometryNearCenter(this.opManager.prepScan, center, cropRadius, prepScanGraph);
        if (!croppedPrepScan) {
            throw new Error('Failed to crop the prep scan');
        }

        this.prepSectionMap.set(toothNumber, croppedPrepScan);
        return croppedPrepScan;
    }

    private getPrepScanGraph(jaw: Jaw): MeshConnectivityGraph {
        const cachedGraph = this.prepScanGraphMap.get(jaw);
        if (cachedGraph) {
            return cachedGraph;
        }

        const prepScanGraph = buildMeshAdjacencyAndVerticesFaces(this.opManager.prepScan);

        this.prepScanGraphMap.set(jaw, prepScanGraph);
        return prepScanGraph;
    }
}
