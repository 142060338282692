import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';

const GetPatient_Query = graphql(`
    query GetPatient($id: String!) {
        getPatient(id: $id) {
            id
            first_name
            last_name
        }
    }
`);

export const usePatient = (id: string | null) => {
    const { data, loading } = useQuery(GetPatient_Query, {
        variables: { id: id ?? '' },
        skip: !id,
    });

    return { patient: data?.getPatient, loading };
};
