import { useSendResetPasswordEmailMutation } from '@orthly/graphql-react';
import { ValidationUtils } from '@orthly/runtime-utils';
import { LoadBlocker, SimpleInput, useChangeSubmissionFn, LoginSubmitButton } from '@orthly/ui';
import { Text, Collapse, Grid } from '@orthly/ui-primitives';
import React from 'react';

export const SendResetEmail: React.VFC<{ initialEmail?: string }> = props => {
    const [email, setEmail] = React.useState<string>(props.initialEmail ?? '');
    const [showError, setShowError] = React.useState<boolean>(false);
    const [submitMtn] = useSendResetPasswordEmailMutation();
    const {
        submit,
        submitting: loading,
        called: sendAttempted,
    } = useChangeSubmissionFn<any, []>(() => submitMtn({ variables: { email } }), {
        closeOnComplete: true,
        successMessage: () => ['Email sent!', {}],
    });
    const sendDisabled = React.useMemo(() => loading || !ValidationUtils.isEmail(email), [email, loading]);
    const emailInvalid = showError && !!email && !ValidationUtils.isEmail(email);

    return (
        <form
            onSubmit={event => {
                event.preventDefault();
                !sendDisabled && submit().catch(err => window.alert(err));
            }}
            style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', width: '100%' }}
        >
            <Grid container style={{ paddingBottom: 12 }}>
                <Text variant={'h6'} color={'BLACK'}>
                    {sendAttempted ? 'An email has been sent' : 'Reset your password'}
                </Text>
            </Grid>
            <Collapse in={!sendAttempted && !loading} style={{ width: '100%' }}>
                <LoadBlocker blocking={loading} ContainerProps={{ style: { marginBottom: 20, display: 'block' } }}>
                    <SimpleInput
                        fullWidth
                        onChange={value => setEmail(value || '')}
                        value={email}
                        label={'Email'}
                        variant={'standard'}
                        TextFieldProps={{
                            margin: 'none',
                            type: 'email',
                            autoComplete: 'email',
                            autoFocus: true,
                            onBlur: () => {
                                setShowError(true);
                            },
                            error: emailInvalid,
                            helperText: emailInvalid ? 'Invalid Email' : undefined,
                            FormHelperTextProps: { style: { position: 'absolute', bottom: -16 } },
                            InputProps: {
                                autoComplete: 'email',
                                autoFocus: true,
                                inputMode: 'email',
                                type: 'email',
                            },
                        }}
                    />
                </LoadBlocker>
                <LoginSubmitButton text={'Send reset email'} disabled={sendDisabled} fullWidth />
            </Collapse>
        </form>
    );
};
