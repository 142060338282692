import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import type { IOrderItemV2DTO } from '@orthly/items';
import { CartItemV2Utils, OrderItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import { Blue } from '@orthly/ui';
import React from 'react';

export interface FeedbackAndRefabItem {
    name: string;
    index: number;
    id: string;
    labOrderItem: IOrderItemV2DTO;
    // For some SKUs, includes the patient's name e.g. "Tim's Denture".
    fullDisplayName: React.ReactNode;
}

// Full name for use in titles. This includes the patient's first name for certain SKUs.
function itemFullDisplayName(itemName: string, sku: LabOrderItemSKUType, patientName: string): React.ReactNode {
    switch (sku) {
        case LabOrderItemSKUType.Aligners:
        case LabOrderItemSKUType.Removeable:
        case LabOrderItemSKUType.Implant:
        case LabOrderItemSKUType.Partial:
        case LabOrderItemSKUType.SurgicalGuide:
        case LabOrderItemSKUType.Denture:
        case LabOrderItemSKUType.Model:
        case LabOrderItemSKUType.ImplantBridge:
        case LabOrderItemSKUType.Waxup:
            return (
                <>
                    {patientName ? `${patientName}’s` : `the`}
                    <Blue> {itemName}</Blue>
                </>
            );
        default:
            return <Blue>{itemName}</Blue>;
    }
}

export function feedbackOrRefabItemFromOrderItem(
    item: IOrderItemV2DTO,
    index: number,
    patientName: string,
): FeedbackAndRefabItem {
    const name = CartItemV2Utils.getFullDisplayName(item);
    const fullDisplayName = itemFullDisplayName(name, item.sku, patientName);
    return {
        index,
        name,
        fullDisplayName,
        id: item.id,
        labOrderItem: item,
    };
}

export const getFeedbackOrRefabItemsFromOrder = (
    labOrder: Pick<LabsGqlLabOrderFragment, 'items_v2' | 'patient'> | undefined,
): FeedbackAndRefabItem[] => {
    return (
        labOrder?.items_v2.map((item, index) =>
            feedbackOrRefabItemFromOrderItem(OrderItemV2Utils.parseItem(item), index, labOrder.patient.first_name),
        ) ?? []
    );
};
