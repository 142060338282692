import type { SetStateAction } from 'react';

export type SpreadsheetAcceptedCellTypes = string | number;

export type ValidationRuleTestAndError = { test: (...args: SpreadsheetAcceptedCellTypes[]) => boolean; error: string };

export function doesMatrixRowContainValidationError(rowIndex: number, validationErrors: Record<string, number[]>) {
    // incrementing row index by 1 because we don't want the zero-indexed value
    return Object.values(validationErrors)
        .flat()
        .includes(rowIndex + 1);
}

export function determineInvoiceItemInputValidationErrors(
    key: string,
    val: string | number,
    index: number,
    validationErrors: Record<string, number[]>,
    setValidationErrors: React.Dispatch<SetStateAction<Record<string, number[]>>>,
    validationRules: Record<string, ValidationRuleTestAndError>,
) {
    if (Object.hasOwn(validationRules, key)) {
        const validationRule = validationRules[key] as ValidationRuleTestAndError;
        // If a validation error exists, and then is corrected, remove the index of that row from the list of values
        // associated with that validation error
        if (validationErrors[validationRule.error]?.includes(index + 1) && !validationRule.test(val)) {
            setValidationErrors(prev => {
                const copyOfExistingValidationErrorArray = [...(prev[validationRule.error] ?? [])];
                const arrIndexOfExistingError = copyOfExistingValidationErrorArray.indexOf(index + 1);
                copyOfExistingValidationErrorArray.splice(arrIndexOfExistingError, 1);
                return {
                    ...prev,
                    [validationRule.error]: copyOfExistingValidationErrorArray,
                };
            });
        }
        if (validationRule.test(val)) {
            setValidationErrors(prev =>
                // Only add the row number if it hasn't already been added for that specific error already
                !prev[validationRule.error]?.includes(index + 1)
                    ? {
                          ...prev,
                          [validationRule.error]: [...(prev[validationRule.error] ?? []), index + 1],
                      }
                    : prev,
            );
        }
    }
}
