import { DeliveryAddressValidation, useValidateDeliveryAddressQuery } from './DeliveryAddressValidation';
import type { LabsGqlDeliveryAddressFragment } from '@orthly/graphql-operations';
import type { QuickFormCustomRenderProps, QuickFormCustomSubmitProps, FieldDefText } from '@orthly/ui';
import { QuickForm, US_STATES, useQFAddressAutocompleteFieldDef } from '@orthly/ui';
import { Grid, Alert } from '@orthly/ui-primitives';
import type { FormikProps } from 'formik';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { z } from 'zod';

export type DeliveryAddressFields = Pick<
    LabsGqlDeliveryAddressFragment,
    'street_one' | 'street_two' | 'postal_code' | 'city' | 'state' | 'country'
>;

const mergeAddressValues = (...values: Partial<DeliveryAddressFields>[]): DeliveryAddressFields => ({
    street_one: values.find(v => v.street_one)?.street_one ?? '',
    street_two: values.find(v => v.street_two)?.street_two ?? '',
    postal_code: values.find(v => v.postal_code)?.postal_code ?? '',
    city: values.find(v => v.city)?.city ?? '',
    state: values.find(v => v.state)?.state ?? '',
    country: 'US',
});

export interface DeliveryAddressFormProps {
    hideSubmitButton?: boolean;
    existingAddress?: DeliveryAddressFields | null;
    onSubmit: (data: DeliveryAddressFields) => void;
    onRender?: (props: QuickFormCustomRenderProps<DeliveryAddressFields>) => void;
    errorMessage?: string;
    CustomSubmit?: React.ComponentType<QuickFormCustomSubmitProps>;
    editAddressFormat?: boolean;
}

export const DeliveryAddressForm: React.FC<DeliveryAddressFormProps> = props => {
    const { hideSubmitButton, existingAddress, onSubmit, onRender, errorMessage, CustomSubmit, editAddressFormat } =
        props;
    const updateFormRef = React.useRef<((values: Partial<DeliveryAddressFields>) => void) | null>(null);
    const [initialValues, setInitialValues] = React.useState<DeliveryAddressFields>(mergeAddressValues());
    const [validationValues, setValidationValues] = React.useState<Partial<DeliveryAddressFields>>(initialValues);
    const [ignoreError, setIgnoreError] = React.useState(false);

    React.useEffect(() => {
        setInitialValues(existingAddress ? mergeAddressValues(existingAddress) : mergeAddressValues());
    }, [existingAddress]);

    const mergeInitialValues = React.useMemo(
        () => (toMerge: Partial<DeliveryAddressFields>, form: FormikProps<Partial<DeliveryAddressFields>>) => {
            const addressValues = mergeAddressValues(initialValues, toMerge);
            Object.entries(addressValues).forEach(([addrField, addrValue]) => {
                form.setFieldValue(addrField, addrValue);
            });
        },
        [initialValues],
    );

    /*
    Validation for addresses is ultimately performed through Shippo, which has length limitations on its fields. These are defined 
    at https://docs.goshippo.com/addressesapi/address_v2/operation/validate_address_addresses_validate_get/, and enforced in the BE request
    as well as here in the FE in a more user friendly manner. If those limits change or we ever use a different API to perform address
    validation, we'll need to update these limits as well.
    */
    const streetOneFieldDef = {
        ...useQFAddressAutocompleteFieldDef({
            label: editAddressFormat ? 'Address' : 'Street One',
            onSelect: mergeInitialValues,
            variant: 'standard',
            style: {
                marginBottom: 0,
                marginTop: editAddressFormat ? 0 : undefined,
            },
            layout: { md: editAddressFormat ? 8 : 12 },
        }),
        validation: z.string().max(100),
    };

    const validationQuery = useValidateDeliveryAddressQuery(validationValues);

    const hasBadAddress = !!validationQuery.error || !validationQuery.validated_address;
    const disabled = validationQuery.loading || (hasBadAddress && !ignoreError);
    const postalCodeField: FieldDefText = {
        type: 'text',
        fieldProps: { autoComplete: 'new-password' }, // disables autofill
        layout: { md: editAddressFormat ? 4 : 6 },
        validation: z.string().max(16),
    };
    const streetTwoField: FieldDefText = {
        label: 'Unit/Suite #',
        type: 'text',
        optional: true,
        fieldProps: { autoComplete: 'new-password' }, // disables autofill
        layout: { md: editAddressFormat ? 4 : 6 },
        validation: z.string().max(50),
    };
    return (
        <Grid container>
            {!!errorMessage && (
                <Alert severity={'error'} data-test={'delivery-address-form-error'}>
                    {errorMessage}
                </Alert>
            )}
            <QuickForm<DeliveryAddressFields>
                resetOnInitialValueChange={true}
                disabled={disabled}
                onChange={formikValues => {
                    if (!isEqual(formikValues, validationValues)) {
                        setValidationValues({ ...formikValues });
                        setIgnoreError(false);
                    }
                }}
                fields={{
                    street_one: streetOneFieldDef,
                    ...(editAddressFormat
                        ? {
                              street_two: streetTwoField,
                              postal_code: postalCodeField,
                          }
                        : {
                              postal_code: postalCodeField,
                              street_two: streetTwoField,
                          }),
                    city: {
                        type: 'text',
                        fieldProps: { autoComplete: 'new-password', style: { paddingBottom: 16 } },
                        layout: { md: editAddressFormat ? 4 : 6 },
                        validation: z.string().max(64),
                    },
                    state: {
                        type: 'select',
                        options: US_STATES,
                        fieldProps: {
                            variant: 'standard',
                            autoComplete: 'new-password',
                            InputLabelProps: { shrink: true },
                        },
                        layout: { md: editAddressFormat ? 4 : 6 },
                        validation: z.string().max(32),
                    },
                    country: { type: 'text', hidden: true },
                }}
                initialValues={initialValues}
                onSubmit={onSubmit}
                submitButtonProps={hideSubmitButton ? { style: { display: 'none' } } : undefined}
                CustomSubmit={CustomSubmit}
                onRender={renderProps => {
                    onRender && onRender(renderProps);
                    updateFormRef.current = renderProps.formikProps.setValues;
                }}
            >
                <DeliveryAddressValidation
                    validationQuery={validationQuery}
                    ignoreError={ignoreError}
                    setIgnoreError={checked => setIgnoreError(checked)}
                    onFix={() => {
                        const updateForm = updateFormRef.current;
                        updateForm && updateForm({ ...validationValues, ...validationQuery.fix });
                    }}
                />
            </QuickForm>
        </Grid>
    );
};
