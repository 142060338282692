import { PrintableSlipSectionTitle } from '../shared/PrintableSlipSectionTitle';
import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import { Text, Grid, makeStyles } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles({
    headerData: {
        fontSize: '1rem !important',
    },
});

interface IPrintableSlipPracticeInfoProps {
    order: LabsGqlLabOrderForLabSlipFragment;
}

/**
 * Renders the name of the practice assigned to an order
 * to be displayed in the Printable Slip
 * @param props
 * @returns Practice information to render onto the Printable Slip
 */
export const PrintableSlipPracticeInfo: React.VFC<IPrintableSlipPracticeInfoProps> = props => {
    const classes = useStyles();
    const { order } = props;
    return (
        <Grid item xs={6}>
            <PrintableSlipSectionTitle text={'Practice'} />
            <Text variant={'h6'} className={classes.headerData}>
                {order.doctor_name}
            </Text>
        </Grid>
    );
};
