import { QCColorLegend } from '../ColorRamp';
import type { CrossSectionAppState, CrossSectionHooksApp } from '../CrossSection/CrossSection.hooks';
import { CrossSectionViewPanel } from '../CrossSection/CrossSectionPanel';
import { CrossSectionPlaneTool } from '../CrossSection/CrossSectionPlaneTool';
import type { ModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import { UndercutHighlight } from '../ModelViewer';
import { HeatmapHighlight } from '../ModelViewer/HeatmapHighlight';
import type { SetValueFn, UpdateTextFnRef } from '../ModelViewer/HeatmapHighlight.hooks';
import type { MainViewCameraControlsRef } from '../ModelViewer/ModelViewerTHREETypes';
import { MouseLabel } from '../ModelViewer/MouseLabel';
import type { IOperationsManager } from './OperationsManager.types';
import { getActiveHeatMapType } from './PartialSceneAppearance.utils';
import type { IPartialSceneAppearanceManager, PartialSceneAppearance } from './SceneAppearanceManager.types';
import { HeatMapType } from '@orthly/forceps';
import { ToothUtils } from '@orthly/items';
import { Box } from '@orthly/ui-primitives';
import { compact } from 'lodash';
import React from 'react';

/**
 * Packages all FinishingApp-produced React nodes that must be placed as children of the canvas into a single node.
 */
export function useCanvasChildren(
    cameraControlsRef: MainViewCameraControlsRef,
    crossSectionState: CrossSectionAppState,
    crossSectionApp: CrossSectionHooksApp,
    modelAppearance: ModelAppearance,
    setHeatmapHighlight: SetValueFn,
    sceneAppearance: PartialSceneAppearance,
    opManager: IOperationsManager,
    partialSceneManager: IPartialSceneAppearanceManager,
): React.ReactNode {
    const activeHeatMapType = getActiveHeatMapType(sceneAppearance);

    const geometryWithConnectivity = opManager.getGeometriesWithConnectivity();

    const editToothNumber = opManager.editToothNumber;
    const insertionAxis = opManager.editInsertionAxis;

    const scans = partialSceneManager.getScanMeshes();
    const lowerJawMesh = scans?.lowerJaw;
    const upperJawMesh = scans?.upperJaw;

    const undercutScanMesh =
        sceneAppearance.scanUndercutEnabled && (ToothUtils.toothIsUpper(editToothNumber) ? upperJawMesh : lowerJawMesh);
    const undercutSelectedMesh =
        sceneAppearance.undercutHeatmapEnabled && partialSceneManager.getRestorativeMeshes().get(editToothNumber);
    const undercutHighlightTargetMesh = undercutScanMesh || undercutSelectedMesh;

    const shadowingMeshes = React.useMemo(() => {
        return compact([ToothUtils.toothIsUpper(editToothNumber) ? upperJawMesh : lowerJawMesh]);
    }, [editToothNumber, upperJawMesh, lowerJawMesh]);

    const conditionallyIncludeHeatmapHighlightComponent = () => {
        if (!!activeHeatMapType && activeHeatMapType !== HeatMapType.Undercut) {
            const geometries = Array.from(geometryWithConnectivity.entries()).map(([_, model]) => model.geometry);
            return (
                <HeatmapHighlight
                    key={`heatmap_highlight`}
                    geometries={geometries}
                    cameraControlsRef={cameraControlsRef}
                    activeHeatMap={activeHeatMapType}
                    showCurtainsHeatmap={false}
                    maxRadiusMm={0.1}
                    onHeatmapHighlighted={setHeatmapHighlight}
                />
            );
        }
    };

    return (
        <>
            {/* New tools that need to be a child of the canvas shall go here */}
            {undercutHighlightTargetMesh && (
                <UndercutHighlight
                    targetModel={{ mesh: undercutHighlightTargetMesh }}
                    shadowingMeshes={shadowingMeshes}
                    insertionAxis={insertionAxis}
                    onHighlight={setHeatmapHighlight}
                    showOnlyEscapeDistance
                />
            )}

            <CrossSectionPlaneTool
                payload={modelAppearance}
                marginLines={undefined}
                showMarginLines={modelAppearance.showMarginLines}
                csPlane={crossSectionApp.csPlane}
                setCSPlane={crossSectionApp.setCSPlane}
                minorAxis={crossSectionApp.crossSectionMinorAxis}
                cameraControlsRef={cameraControlsRef}
                newCrossSectionPlaneActive={
                    crossSectionState.appActive.open && !crossSectionApp.crossSectionPlaneActive
                }
                onUpdateCrossSection={crossSectionApp.updateCrossSectionData}
                onNewCrossSectionPlane={crossSectionApp.handleNewCrossSectionPlane}
                clippingPlane={crossSectionState.clippingPlane.open}
                reversePlane={crossSectionState.reversePlane.open}
            />
            {conditionallyIncludeHeatmapHighlightComponent()}
        </>
    );
}

/**
 * Packages all ReviewApp-produced React nodes that must be placed as siblings of the canvas into a single node.
 */
export function createCanvasSiblings(
    crossSectionState: CrossSectionAppState,
    crossSectionApp: CrossSectionHooksApp,
    sceneAppearance: PartialSceneAppearance,
    sceneManager: IPartialSceneAppearanceManager,
    updateHeatmapHighlightTextRef: UpdateTextFnRef,
): React.ReactNode {
    const activeHeatMapType = getActiveHeatMapType(sceneAppearance);
    const setHeatMapRange = sceneManager.setHeatmapRange?.bind(sceneManager);

    return (
        <>
            {/* New tools that need to be a sibling of the review panel canvas shall go here */}
            <CrossSectionViewPanel crossSectionApp={crossSectionApp} crossSectionState={crossSectionState} />
            {activeHeatMapType && setHeatMapRange && (
                <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 21 }}>
                    <QCColorLegend
                        heatMapType={activeHeatMapType}
                        dynamicHeatmaps={true}
                        heatMapRange={sceneAppearance.heatmapRange}
                        setHeatMapRange={setHeatMapRange}
                    />
                </Box>
            )}
            <MouseLabel forceUpdateRef={updateHeatmapHighlightTextRef} offsetMouse />
        </>
    );
}
