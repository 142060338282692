import { AbutmentPartSelector } from './AbutmentPartSelector';
import { AbutmentPartsCalculatorResults } from './AbutmentPartsCalculatorResults';
import { ImplantSelector } from './ImplantSelector';
import { ImplantToScanbodySelector } from './ImplantToScanbodySelector';
import type {
    LabsGqlAbutmentPartToImplantAndScanbodyFragment,
    LabsGqlImplantToScanbodyFragment,
    LabsGqlImplantTypeLiteFragment,
} from '@orthly/graphql-operations';
import { Text } from '@orthly/ui-primitives';
import React from 'react';

interface AbutmentPartsCalculatorProps {}

/**
 * The abutment parts calculator is a temporary tool meant to replace the use of a spreadsheet from Ops to figure
 * out which abutment part to use given a bunch of inputs. This will eventually be replaced by a more functional UX
 * directly in a new Edit Implant Parts modal that will be built out later.
 *
 * Currently the flow has the user:
 * 1/ Select an implant
 * 2/ Then select a scanbody that is linked to that implant
 * 3/ Then select a part that is linked to that implant to scanbody
 *
 * Given all these links we can then show:
 * all the 3 shape fields attached on the abutment part
 * the abutment part SKU
 * the analog SKU (from the implant & scanbody relationship)
 *
 * All this information is needed by designer to input in their designer software so they can use the right
 * abutment part there.
 */
export const AbutmentPartsCalculator: React.VFC<AbutmentPartsCalculatorProps> = () => {
    const [selectedImplant, setSelectedImplant] = React.useState<LabsGqlImplantTypeLiteFragment | null>(null);
    const [selectedImplantToScanbody, setSelectedImplantToScanbody] =
        React.useState<LabsGqlImplantToScanbodyFragment | null>(null);
    const [selectedAbutmentPartToImplantAndScanbody, setSelectedAbutmentPartToImplantAndScanbody] =
        React.useState<LabsGqlAbutmentPartToImplantAndScanbodyFragment | null>(null);

    React.useEffect(() => {
        setSelectedAbutmentPartToImplantAndScanbody(null);
        setSelectedImplantToScanbody(null);
    }, [selectedImplant]);

    React.useEffect(() => {
        setSelectedAbutmentPartToImplantAndScanbody(null);
    }, [selectedImplantToScanbody]);

    return (
        <div>
            <div>
                <Text variant={'h6'} style={{ marginBottom: 8 }}>
                    Implant Selection
                </Text>
                <ImplantSelector setSelectedImplant={setSelectedImplant} />
            </div>
            {selectedImplant?.id && (
                <div style={{ marginTop: 24 }}>
                    <Text variant={'h6'} style={{ marginBottom: 8 }}>
                        Scanbody Selection
                    </Text>
                    <ImplantToScanbodySelector
                        implantId={selectedImplant.id}
                        setSelectedImplantToScanbody={setSelectedImplantToScanbody}
                    />
                </div>
            )}
            {selectedImplantToScanbody?.id && (
                <div style={{ marginTop: 24 }}>
                    <AbutmentPartSelector
                        implantToScanbodyId={selectedImplantToScanbody.id}
                        setSelectedAbutmentPartToImplantAndScanbody={setSelectedAbutmentPartToImplantAndScanbody}
                    />
                </div>
            )}
            {selectedImplant && selectedAbutmentPartToImplantAndScanbody && (
                <AbutmentPartsCalculatorResults
                    abutmentPartToImplantAndScanbody={selectedAbutmentPartToImplantAndScanbody}
                />
            )}
        </div>
    );
};
