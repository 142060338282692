import { ActionBlock } from './ActionBlock';
import { useTicketStyles } from './OrderTickets.utils';
import type { LabsGqlTicketDto } from '@orthly/graphql-schema';
import { RootActionDialog } from '@orthly/ui';
import { FlossPalette, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface TicketDetailContainerProps {
    tickets: LabsGqlTicketDto[];
}
export const TicketDetailContainer: React.FC<TicketDetailContainerProps> = ({ tickets }) => {
    const styles = useTicketStyles();

    return (
        <>
            {tickets.map((ticket, index) => (
                <Grid key={index} container className={styles.ticketBlock} direction={'column'}>
                    {ticket.actions.map((action, actionIndex) => (
                        <ActionBlock
                            key={actionIndex}
                            ticket={ticket}
                            action={action}
                            active={ticket.active_action?.id === action.id}
                            showDetail={true}
                        />
                    ))}
                </Grid>
            ))}
        </>
    );
};

interface TicketDetailDialogProps extends TicketDetailContainerProps {
    onClose: () => void;
}
export const TicketDetailDialog: React.FC<TicketDetailDialogProps> = ({ onClose, ...props }) => {
    const [open, setOpen] = React.useState<boolean>(true);

    return (
        <RootActionDialog
            loading={false}
            open={open}
            setOpen={setOpen}
            onClose={() => {
                setOpen(false);
                onClose();
            }}
            title={''}
            titleProps={{ style: { display: 'none' } }}
            PaperProps={{ style: { borderRadius: 10 } }}
            contentProps={{ style: { padding: 16, backgroundColor: FlossPalette.DARK_TAN } }}
            content={!open ? null : <TicketDetailContainer {...props} />}
            CustomButton={() => null}
        />
    );
};
