import { SimpleExpansionPanel } from '../../../components/SimpleExpansionPanel';
import { GrayBooleanIcon } from '../BillingDetails/components/GrayBooleanIcon';
import { FullWidthContainer, TableBodyRow } from '../BillingDetails/components/styled-components';
import { useBillingDetailsContext } from '../BillingDetails/providers/BillingDetailsProvider.graphql';
import { PartnerContractsTable } from './PracticeContractsTable.graphql';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlFullyResolvedPracticeContractDto } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { LoadBlocker, Table } from '@orthly/ui';
import { FlossPalette, TableBody, TableCell, TableHead, TableRow, Text } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import _ from 'lodash';

dayjs.extend(objectSupport);

const GetContracts_Query = graphql(`
    query GetContracts($practiceId: String!) {
        getFullyResolvedPracticeContractsV2ByPracticeId(practice_id: $practiceId) {
            associatedPractices {
                createdAt
                isPrimary
                practiceContractId
                practiceId
                updatedAt
            }
            contract {
                activationFeeCents
                createdAt
                effectiveDateContractOriginal
                effectiveDateOffsetMonths
                effectiveDateType
                enforceContractFirstMonth
                enforceContractFirstYear
                enforceContractLastMonth
                enforceContractLastYear
                id
                introductoryCycleLengthMonths
                introductoryCycleMinimumSpendCents
                migrationNote
                ongoingCycleLengthMonths
                ongoingCycleMinimumSpendCents
                practiceId
                salesForceContractId
                updatedAt
                contractSignedDate
            }
            spendTerms {
                createdAt
                firstMonth
                firstYear
                id
                isMonthlyContract
                lastMonth
                lastYear
                practiceContractId
                spendMinimumCents
                updatedAt
            }
        }
    }
`);

export const PracticeContractV2Table: React.FC<{ practiceId: string }> = ({ practiceId }) => {
    const { selectedContractId, setSelectedContractId, activeContractId, contractsLoading } =
        useBillingDetailsContext();

    const { data: { getFullyResolvedPracticeContractsV2ByPracticeId: contracts = [] } = {} } = useQuery<{
        getFullyResolvedPracticeContractsV2ByPracticeId: LabsGqlFullyResolvedPracticeContractDto[];
    }>(GetContracts_Query, { variables: { practiceId } });

    const sortedContracts = _.orderBy(contracts, ['contract.contractSignedDate'], ['desc']);

    const rows = sortedContracts.map(contract => {
        const multiLocStr = contract.associatedPractices.length > 1 ? 'Multiple Locations' : 'Single Location';
        const effectiveDateStr =
            contract.contract.enforceContractFirstYear && contract.contract.enforceContractFirstMonth
                ? dayjs({
                      year: contract.contract.enforceContractFirstYear,
                      month: contract.contract.enforceContractFirstMonth - 1,
                  }).format('MMMM YYYY')
                : `Contract is not active`;

        const endDateStr =
            contract.contract.enforceContractLastYear && contract.contract.enforceContractLastMonth
                ? dayjs(
                      new Date(
                          contract.contract.enforceContractLastYear,
                          contract.contract.enforceContractLastMonth - 1,
                          1,
                      ),
                  ).format('MMMM YYYY')
                : `No end date`;

        const introCycleStr =
            contract.contract.introductoryCycleLengthMonths && contract.contract.introductoryCycleMinimumSpendCents
                ? `${Format.currency(contract.contract.introductoryCycleMinimumSpendCents, 'cents')} over ${contract.contract.introductoryCycleLengthMonths} months.`
                : `No introductory cycle`;

        const ongoingCycleStr = `${Format.currency(contract.contract.ongoingCycleMinimumSpendCents, 'cents')} every ${contract.contract.ongoingCycleLengthMonths} months.`;

        const signedDateString =
            contract.contract.contractSignedDate === '2024-03-27'
                ? '2024-03-27 <Probably Incorrect>'
                : contract.contract.contractSignedDate;

        return (
            <TableBodyRow
                key={`tbr-${contract.contract.id}`}
                selected={selectedContractId === contract.contract.id}
                sx={{ cursor: 'pointer', '&:hover': { background: FlossPalette.TAN } }}
                onClick={() => setSelectedContractId(contract.contract.id)}
            >
                <TableCell>
                    <GrayBooleanIcon val={activeContractId === contract.contract.id} />
                </TableCell>
                <TableCell> {signedDateString} </TableCell>
                <TableCell> {contract.contract.salesForceContractId} </TableCell>
                <TableCell> {multiLocStr} </TableCell>
                <TableCell> {effectiveDateStr} </TableCell>
                <TableCell> {endDateStr} </TableCell>
                <TableCell> {introCycleStr} </TableCell>
                <TableCell> {ongoingCycleStr} </TableCell>
            </TableBodyRow>
        );
    });

    const emptyRow = (
        <TableBodyRow key={`tbr-empt-row`}>
            <TableCell />
            <TableCell>No Contract Found</TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>$0 per month</TableCell>
        </TableBodyRow>
    );

    return (
        <SimpleExpansionPanel
            ExpansionPanelProps={{
                variant: 'outlined',
                style: {
                    margin: '10px 10px 10px 0px !important',
                    width: 'inherit',
                },
                defaultExpanded: true,
            }}
            ExpansionPanelSummaryProps={{
                style: {
                    height: '56px',
                },
            }}
            title={<Text variant={'h5'}>Practice Contracts</Text>}
        >
            <FullWidthContainer>
                <p>What is this?</p>
                <p>
                    In October 2024 the billing team migrated to pulling contracts directly from Salesforce, which now
                    serves as the source of truth for all contract information. This panel contains, first, all the
                    contracts and amendments that apply to this practice using the new Salesforce workflow. We also
                    preserve the historical contract data from EPD systems, for practices that signed contracts before
                    October 2024, in the nested "Legacy EPD Contracts" panel.
                </p>
                <p>Click on a row in this table to update the locations shown above based on the contract selected.</p>
                <LoadBlocker blocking={contractsLoading}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Active?</TableCell>
                                <TableCell>Contract Signed Date</TableCell>
                                <TableCell>Salesforce Contract Id</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Effective Start Date</TableCell>
                                <TableCell>End Date</TableCell>
                                <TableCell>Introductory Spend Term</TableCell>
                                <TableCell>Ongoing Spend Term</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{rows.length > 0 ? rows : emptyRow}</TableBody>
                    </Table>
                </LoadBlocker>
                <br />
                <PartnerContractsTable organizationId={practiceId} />
            </FullWidthContainer>
        </SimpleExpansionPanel>
    );
};
