import {
    CreateOrganizationPriceConfigAction,
    UpdateOrganizationPriceConfigAction,
    DeleteOrganizationPriceConfigAction,
} from './OrganizationPriceConfigAction';
import type { LabsGqlOrganizationPriceConfigDtoFragment } from '@orthly/graphql-operations';
import {
    useGetLabPricePartnerPricesQuery,
    useGetOrganizationPriceConfigsQuery,
    useGetOrganizationWithDescendantsQuery,
} from '@orthly/graphql-react';
import { LabsGqlOrganizationTypeForPricing } from '@orthly/graphql-schema';
import type { Column } from '@orthly/mui-table';
import MUITable from '@orthly/mui-table';
import { LoadBlocker } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';

export const OrganizationPriceConfigTable: React.FC<RouteComponentProps<{ partnerId: string }>> = props => {
    const { value: disableContractEdits = false } = useFeatureFlag('disableContractEdits');
    const partnerId = props.match.params.partnerId;

    // get organization name
    const { data: gowdData, loading: gowdLoading } = useGetOrganizationWithDescendantsQuery({
        variables: { id: partnerId },
    });

    // get organization price configs
    const {
        data: gopcData,
        loading: gopcLoading,
        refetch,
    } = useGetOrganizationPriceConfigsQuery({
        variables: {
            organization_id: partnerId,
            organization_type: LabsGqlOrganizationTypeForPricing.Practice,
            price_id: null,
        },
    });

    // get lab price definitions (aka product catalog items)
    const { data: glpdData, loading: glpdLoading } = useGetLabPricePartnerPricesQuery();
    const prices = React.useMemo(() => glpdData?.getLabPricePartnerPrices ?? [], [glpdData]);

    const CustomRightComponent = React.useCallback(
        () =>
            disableContractEdits ? null : (
                <Grid container justifyContent={'flex-end'} style={{ paddingTop: 10 }}>
                    <CreateOrganizationPriceConfigAction
                        organization_id={partnerId}
                        organization_type={LabsGqlOrganizationTypeForPricing.Practice}
                        prices={prices}
                        onComplete={refetch}
                    />
                </Grid>
            ),
        [refetch, partnerId, prices, disableContractEdits],
    );

    const configs = gopcData?.getOrganizationPriceConfigs ?? [];
    const loading = gowdLoading || gopcLoading || glpdLoading;
    const partnerName = gowdData?.getOrganizationWithDescendants.name;

    const actionsColumn: Column<LabsGqlOrganizationPriceConfigDtoFragment>[] = disableContractEdits
        ? []
        : [
              {
                  name: 'Actions',
                  sort: false,
                  render: row => <DeleteOrganizationPriceConfigAction id={row.id} onComplete={refetch} />,
              },
          ];

    return (
        <LoadBlocker blocking={loading} loader={'dandy'}>
            <MUITable<LabsGqlOrganizationPriceConfigDtoFragment>
                title={`Prices for ${partnerName}`}
                displayOptions={{ fixedSearch: true, fixedHeader: true, responsive: 'scroll' }}
                data={configs}
                columns={[
                    { name: 'id', hidden: true, render: 'id' },
                    { name: 'organization_id', hidden: true, render: 'organization_id' },
                    {
                        name: 'price_id',
                        render: r => {
                            const match = prices.find(price => price.id === r.price_id);
                            return match?.name ?? 'N/A';
                        },
                        title: 'Product Catalog Item',
                    },
                    {
                        name: 'price_cents',
                        render: r =>
                            disableContractEdits ? (
                                `$${(r.price_cents / 100).toFixed(2)}`
                            ) : (
                                <UpdateOrganizationPriceConfigAction
                                    id={r.id}
                                    price_cents={r.price_cents}
                                    onComplete={refetch}
                                />
                            ),
                        field: 'price_cents',
                        title: 'Price',
                    },
                    ...actionsColumn,
                ]}
                toolbarOptions={{ CustomRight: CustomRightComponent }}
            />
        </LoadBlocker>
    );
};
