/* eslint-disable max-lines */
import { AbutmentPartToImplantScanbodyContainer } from './AbutmentPartToImplantScanbody/AbutmentPartToImplantScanbodyContainer';
import { ArchiveAndRestore } from './ArchiveAndRestore';
import type { LabsGqlImplantTypeFragment, LabsGqlScanbodyFragment } from '@orthly/graphql-operations';
import {
    useGetImplantTypesQuery,
    useGetScanbodiesQuery,
    useUpdateImplantTypeMutation,
    useCreateImplantTypeMutation,
    useDeleteImplantTypeMutation,
    useRestoreImplantTypeMutation,
} from '@orthly/graphql-react';
import MUITable from '@orthly/mui-table';
import { selectFirstTruthyKey } from '@orthly/runtime-utils';
import type { CustomQFComponentProps } from '@orthly/ui';
import {
    ActionCard,
    QuickForm,
    LoadBlocker,
    SimpleAutocomplete,
    RootActionDialog,
    useChangeSubmissionFn,
    apolloErrorMessage,
} from '@orthly/ui';
import { Grid, TextField, Tooltip } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import constate from 'constate';
import _ from 'lodash';
import React from 'react';
import { z } from 'zod';

interface ImplantToScanbodyRowProps {
    implantTypeId: string;
    scanbodies: LabsGqlScanbodyFragment[];
    scanbodyTrie: _.Dictionary<_.Dictionary<LabsGqlScanbodyFragment[]>>;
}

const ImplantToScanbodyRow: React.FC<CustomQFComponentProps<ImplantToScanbodyRowProps>> = ({
    field,
    form,
    implantTypeId,
    scanbodies,
    scanbodyTrie,
}) => {
    const { scanbody_id, is_authentic, priority, id } = field.value ?? {};
    const { value: isAbutmentPartsManagementEnabled = false } = useFeatureFlag('enableAbutmentPartsAdminManagement');

    // Only allow abutment parts management for implant to scanbody relationship that has been saved (and therefore has an id)
    const hasAbutmentPartManager = id && isAbutmentPartsManagementEnabled;

    const [{ name, manufacturer, sku }, setState] = React.useState<{
        name?: string;
        manufacturer?: string;
        sku?: string;
    }>({});

    const onFieldChange = (key: string) => (value: string | null) => {
        setState(currentValue => {
            const nextValue = {
                ...currentValue,
                manufacturer: key === 'name' ? undefined : currentValue.manufacturer,
                sku: key === 'name' || key === 'manufacturer' ? undefined : currentValue.sku,
                [key]: value,
            };
            const scanbody = scanbodies.find(
                s => s.name === nextValue.name && s.manufacturer === nextValue.manufacturer && s.sku === nextValue.sku,
            );
            if (scanbody) {
                form.setFieldValue(field.name, { ...field.value, scanbody_id: scanbody.id });
            }
            return nextValue;
        });
    };
    React.useEffect(() => {
        const scanbody = scanbodies.find(s => s.id === scanbody_id);
        if (scanbody) {
            setState({ name: scanbody.name, manufacturer: scanbody.manufacturer, sku: scanbody.sku });
        }
    }, [scanbody_id, scanbodies]);

    // For all four inputs, always make sure that the "initialInputValue" and "key" match, otherwise
    // the async loading will cause empty inputs
    return (
        <Grid container spacing={1} alignItems={'center'}>
            <Grid container item xs={3}>
                <SimpleAutocomplete
                    AutocompleteProps={{ style: { width: '100%' } }}
                    label={'Name'}
                    options={_.keys(scanbodyTrie)}
                    key={name}
                    initialInputValue={name}
                    onChange={onFieldChange('name')}
                />
            </Grid>

            <Grid container item xs={3}>
                <SimpleAutocomplete
                    AutocompleteProps={{ style: { width: '100%' } }}
                    label={'Manufacturer'}
                    options={_.keys(scanbodyTrie[name ?? ''])}
                    key={manufacturer}
                    initialInputValue={manufacturer}
                    onChange={onFieldChange('manufacturer')}
                />
            </Grid>

            <Grid container item xs={3}>
                <SimpleAutocomplete
                    AutocompleteProps={{ style: { width: '100%' } }}
                    label={'SKU'}
                    options={scanbodyTrie[name ?? '']?.[manufacturer ?? '']?.map(s => s.sku) ?? []}
                    key={sku}
                    initialInputValue={sku}
                    onChange={onFieldChange('sku')}
                />
            </Grid>

            <Grid container item xs={3}>
                <Tooltip title={'Scan bodies with higher priority are selected first'}>
                    <TextField
                        variant={'standard'}
                        value={priority}
                        type={'number'}
                        label={'Priority'}
                        autoFocus
                        inputProps={{ min: 0, step: 1, style: { padding: 'none' } }}
                        onChange={e => form.setFieldValue(field.name, { ...field.value, priority: e.target.value })}
                    />
                </Tooltip>
            </Grid>

            <Grid container item xs={3}>
                <SimpleAutocomplete
                    AutocompleteProps={{ style: { width: '100%' } }}
                    label={'Is authentic?'}
                    options={[
                        { label: 'Authentic', value: 'yes' },
                        { label: 'Generic', value: 'no' },
                    ]}
                    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                    // eslint-disable-next-line no-nested-ternary
                    key={String(is_authentic === true ? 'Authentic' : is_authentic === false ? 'Generic' : null)}
                    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                    // eslint-disable-next-line no-nested-ternary
                    initialInputValue={is_authentic === true ? 'Authentic' : is_authentic === false ? 'Generic' : null}
                    onChange={is_authentic =>
                        form.setFieldValue(field.name, {
                            ...field.value,
                            is_authentic: is_authentic === 'yes',
                        })
                    }
                />
            </Grid>

            {hasAbutmentPartManager && (
                <Grid container item xs={6}>
                    <AbutmentPartToImplantScanbodyContainer
                        abutmentManagerData={{
                            implantMetadata: {
                                manufacturer: form.values.manufacturer,
                                system: form.values.system,
                                connection: form.values.connection,
                            },
                            scanbodyMetadata: {
                                name: name || '',
                                manufacturer: manufacturer || '',
                                sku: sku || '',
                            },
                            implantTypeId: implantTypeId,
                            implantToScanbodyId: id,
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
};

const [ImplantTypesProvider, useImplantTypes] = constate(() =>
    useGetImplantTypesQuery({
        variables: { withDeleted: true },
    }),
);

interface LabsGqlScanbodyRelation {
    id: string;
    scanbody_id: string;
    is_authentic: boolean;
    priority?: number | null;
}

interface ImplantTypeRowProps {
    data: LabsGqlImplantTypeFragment;
}

interface ScanbodyTrie {
    [name: string]: {
        [manufacturer: string]: LabsGqlScanbodyFragment[];
    };
}

function useScanbodyTrie(scanbodies?: LabsGqlScanbodyFragment[]): ScanbodyTrie {
    return React.useMemo<ScanbodyTrie>(() => {
        if (!scanbodies) {
            return {};
        }

        const groupedByName = _.groupBy(scanbodies, t => t.name);
        return _.mapValues<typeof groupedByName, typeof groupedByName>(groupedByName, scanbodies =>
            _.groupBy(scanbodies, t => t.manufacturer),
        );
    }, [scanbodies]);
}

const ImplantTypeRow: React.FC<ImplantTypeRowProps> = ({ data: implantType }) => {
    const { loading: isFetching, refetch: refetchImplantTypes } = useImplantTypes();

    const { data, error: fetchError } = useGetScanbodiesQuery({ variables: { withDeleted: false } });
    const scanbodyTrie = useScanbodyTrie(data?.getScanbodies);

    const dispatchAction = async (promise: PromiseLike<any> | void) => {
        await promise;
        await refetchImplantTypes();
    };

    const [updateImplantType, { error: updateError, loading: isUpdating }] = useUpdateImplantTypeMutation();
    const [deleteImplantType, { error: deleteError, loading: isDeleting }] = useDeleteImplantTypeMutation();
    const [restoreImplantType, { error: restoreError, loading: isRestoring }] = useRestoreImplantTypeMutation();

    const loading = isFetching || isUpdating || isDeleting || isRestoring;
    const error = updateError || deleteError || restoreError || fetchError;

    return (
        <Grid container style={{ padding: 16 }}>
            {error && (
                <ActionCard
                    variant={'alert'}
                    title={`Failed to ${
                        selectFirstTruthyKey({
                            update: updateError,
                            delete: deleteError,
                            restore: restoreError,
                            fetch: fetchError,
                        }) || 'do something with'
                    } implant type.`}
                    subtitle={apolloErrorMessage(error)}
                />
            )}
            <LoadBlocker blocking={(!data && !error) || loading}>
                {data?.getScanbodies.length === 0 ? (
                    <ActionCard
                        variant={'alert'}
                        title={'There are no scan bodies'}
                        subtitle={'Please create some scan bodies before modifying implant types.'}
                    />
                ) : (
                    <Grid container direction={'row'} alignItems={'flex-start'} spacing={4}>
                        <Grid container item xs={9}>
                            <QuickForm<{
                                manufacturer: string;
                                system: string;
                                connection: string;
                                scanbodies: LabsGqlScanbodyRelation[];
                            }>
                                disabled={!data}
                                readOnly={!data}
                                resetOnInitialValueChange={true}
                                fields={{
                                    manufacturer: { type: 'text' },
                                    system: { type: 'text' },
                                    connection: { label: 'Platform Size', type: 'text' },
                                    scanbodies: {
                                        type: 'array',
                                        of: {
                                            label: 'Scan body',
                                            type: 'custom',
                                            component: ({ field, form }) => (
                                                <ImplantToScanbodyRow
                                                    field={field}
                                                    form={form}
                                                    scanbodies={data?.getScanbodies || []}
                                                    scanbodyTrie={scanbodyTrie}
                                                    implantTypeId={implantType.id}
                                                />
                                            ),
                                            validation: z.object({
                                                scanbody_id: z.string(),
                                                is_authentic: z.boolean(),
                                            }),
                                        },
                                    },
                                }}
                                initialValues={{
                                    manufacturer: implantType.manufacturer,
                                    system: implantType.system,
                                    connection: implantType.connection,
                                    scanbodies: _.sortBy(
                                        implantType.implantToScanbodies.map(
                                            ({ is_authentic, scanbody, priority, id }) => ({
                                                is_authentic,
                                                priority,
                                                scanbody_id: scanbody.id,
                                                id,
                                            }),
                                        ),
                                        its => -(its.priority ?? 0),
                                    ),
                                }}
                                onSubmit={values => {
                                    void dispatchAction(
                                        updateImplantType({
                                            variables: {
                                                data: {
                                                    manufacturer: values.manufacturer,
                                                    system: values.system,
                                                    connection: values.connection,
                                                    implantToScanbodies: values.scanbodies.map(scanbody => ({
                                                        is_authentic: scanbody.is_authentic,
                                                        scanbody_id: scanbody.scanbody_id,
                                                        priority: scanbody.priority?.toString()
                                                            ? parseInt(scanbody.priority.toString())
                                                            : null,
                                                    })),
                                                    implant_type_id: implantType.id,
                                                },
                                            },
                                        }),
                                    );
                                }}
                            />
                        </Grid>

                        <Grid container item xs={3}>
                            <ArchiveAndRestore
                                objectName={'implant type'}
                                isArchived={implantType.is_archived}
                                disabled={loading}
                                hidePrompt={false}
                                promptText={''}
                                onRestore={() =>
                                    dispatchAction(
                                        restoreImplantType({
                                            variables: { data: { implant_type_id: implantType.id } },
                                        }),
                                    )
                                }
                                onDelete={() =>
                                    dispatchAction(
                                        deleteImplantType({
                                            variables: {
                                                data: {
                                                    implant_type_id: implantType.id,
                                                },
                                            },
                                        }),
                                    )
                                }
                            />
                        </Grid>
                    </Grid>
                )}
            </LoadBlocker>
        </Grid>
    );
};

function getImplantTypeOptions(
    implantTypes: LabsGqlImplantTypeFragment[] | undefined,
    field: (frag: LabsGqlImplantTypeFragment) => string | null,
): string[] {
    return _(implantTypes).map(field).compact().uniq().sort().value();
}

type ImplantFormRow = Omit<
    LabsGqlImplantTypeFragment,
    'is_archived' | 'updated_at' | 'id' | 'implantToScanbodies' | 'created_at' | 'scanbody_relationships'
>;

const ImplantsConfigContent: React.FC = () => {
    const { data, error, refetch: refetchImplantTypes } = useImplantTypes();
    const [isCreateImplantTypeOpen, setCreateImplantTypeOpen] = React.useState(false);
    const [rawCreateImplantType] = useCreateImplantTypeMutation();
    const { submit: createImplantType, submitting: creatingScanbody } = useChangeSubmissionFn<any, any>(
        data => rawCreateImplantType({ variables: { data } }),
        {
            closeOnComplete: true,
            successMessage: () => ['Scan body successfully created!', {}],
            onSuccess: async () => {
                setCreateImplantTypeOpen(false);
                await refetchImplantTypes();
            },
        },
    );

    const { manufacturers, systems, connections } = React.useMemo(
        () => ({
            manufacturers: getImplantTypeOptions(data?.getImplantTypes, s => s.manufacturer),
            systems: getImplantTypeOptions(data?.getImplantTypes, s => s.system),
            connections: getImplantTypeOptions(data?.getImplantTypes, s => s.connection),
        }),
        [data],
    );

    return (
        <Grid container style={{ padding: 16 }}>
            {error && (
                <ActionCard
                    variant={'alert'}
                    title={'Failed to retrieve implant systems'}
                    subtitle={'Please try again later.'}
                />
            )}
            <MUITable<LabsGqlImplantTypeFragment>
                title={'Implant Systems'}
                data={data?.getImplantTypes ?? []}
                loading={!data && !error}
                DetailPanel={ImplantTypeRow}
                rowOptions={{ rowHover: true }}
                displayOptions={{
                    elevation: 0,
                    sort: true,
                    filter: true,
                }}
                actions={{
                    global: [
                        {
                            icon: 'refresh',
                            position: 'toolbar',
                            tooltip: 'Reload implant types',
                            onClick: () => refetchImplantTypes(),
                        },
                        {
                            icon: 'add',
                            position: 'toolbar',
                            tooltip: 'Create scan body',
                            onClick: () => setCreateImplantTypeOpen(true),
                        },
                    ],
                }}
                eventHooks={{
                    onRowClick: (row, actions) => actions.toggleDetailPanel(row),
                }}
                columns={[
                    { name: 'Manufacturer', render: 'manufacturer' },
                    { name: 'System', render: 'system' },
                    { name: 'Platform Size', render: 'connection' },
                    {
                        name: 'Authentic Only',
                        type: 'boolean',
                        filter: false,
                        render: r =>
                            r.implantToScanbodies.length > 0 ? r.implantToScanbodies.every(s => s.is_authentic) : null,
                    },
                    {
                        name: 'Number of scan bodies',
                        filter: false,
                        render: r => r.implantToScanbodies.length,
                    },
                    {
                        name: 'Archived',
                        render: 'is_archived',
                        type: 'boolean',
                        filterOptions: { defaultValues: ['false'], exact: false, type: 'dropdown' },
                    },
                ]}
            />
            <RootActionDialog
                title={'Create Implant Type'}
                open={isCreateImplantTypeOpen}
                setOpen={setCreateImplantTypeOpen}
                loading={creatingScanbody}
                hideButton={true}
                content={
                    <QuickForm<ImplantFormRow>
                        fields={{
                            manufacturer: { type: 'autocomplete', options: manufacturers, freeSolo: true },
                            system: { type: 'autocomplete', options: systems, freeSolo: true },
                            connection: {
                                type: 'autocomplete',
                                label: 'Platform Size',
                                options: connections,
                                freeSolo: true,
                            },
                        }}
                        initialValues={{ manufacturer: '', system: '', connection: '' }}
                        onSubmit={createImplantType}
                    />
                }
            />
        </Grid>
    );
};

export const ImplantsConfigRoot: React.FC = () => {
    return (
        <ImplantTypesProvider>
            <ImplantsConfigContent />
        </ImplantTypesProvider>
    );
};
