import path_of_insertion_interproximal_undercut_BAD from '../../../../assets/images/design-qc/path_of_insertion/path_of_insertion_interproximal_undercut_BAD.png';
import path_of_insertion_interproximal_undercut_GOOD from '../../../../assets/images/design-qc/path_of_insertion/path_of_insertion_interproximal_undercut_GOOD.png';
import path_of_insertion_percentage_visible_BAD from '../../../../assets/images/design-qc/path_of_insertion/path_of_insertion_percentage_visible_BAD.png';
import path_of_insertion_percentage_visible_GOOD from '../../../../assets/images/design-qc/path_of_insertion/path_of_insertion_percentage_visible_GOOD.png';
import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { DEFAULT_SCAN_APPEARANCE, DEFAULT_RESTORATIVE_APPEARANCE, DEFAULT_TRANSPARENT_OPACITY } from '@orthly/dentin';

export const insertionDirectionRubric: DesignQcRubricCategoryEntry = {
    category: 'path_of_insertion',
    subcategories: [
        {
            category: 'path_of_insertion',
            name: '% of margin visible',
            grading: {
                type: 'binary',
                bad: '<100% of margin is visible',
                ideal: '100% of margin is visible',
            },
            examples: {
                bad: [path_of_insertion_percentage_visible_BAD],
                ideal: [path_of_insertion_percentage_visible_GOOD],
            },
            description: '100% of the margin line must be visible.',
            selectable_bad_reasons: ['Undercut on margin line'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                qc_settings: {
                    showMarginLines: true,
                    showDoctorMarginLines: true,
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        showInsertionAxis: true,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
        },
        {
            category: 'path_of_insertion',
            name: 'Minimized areas of interproximal undercut',
            grading: {
                type: 'tertiary',
                bad: 'Worse than 40/60 undercut distribution',
                acceptable: '40/60 undercut distribution',
                ideal: '50/50 undercut distribution',
            },
            examples: {
                bad: [path_of_insertion_interproximal_undercut_BAD],
                acceptable: [path_of_insertion_interproximal_undercut_GOOD],
                ideal: [path_of_insertion_interproximal_undercut_GOOD],
            },
            description: 'Always subservient to margin visibility<br><br>Distribution of interproximal undercut',
            edge_case:
                'Even distribution of interproximal undercut from a POI perspective.<br><br>Always subservient to margin visibility<br><br>Adjacent crowns - We should design assuming doctors place from the distal first',
            selectable_bad_reasons: ['Fix path of insertion to minimize interproximal undercut'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                qc_settings: {
                    showMarginLines: true,
                    showDoctorMarginLines: true,
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        showInsertionAxis: true,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                        colorize: false,
                        opacity: DEFAULT_TRANSPARENT_OPACITY,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
        },
        {
            category: 'path_of_insertion',
            name: 'Minimized areas of prep undercut',
            grading: {
                type: 'tertiary',
                bad: 'Anything that compromises the above',
                acceptable: 'Uneven visibility fo B/L prep surface, but does not compromise the above',
                ideal: 'Even visibility of the B/L prep surfaces',
            },
            description:
                'Minimizing the amount of excessive undercut on the prep (undercuts extending too close to margin may cause minimum thickness problems around margin)<br><br>Always subservient to even distribution of interproximal undercut',
            selectable_bad_reasons: [
                'Excessive undercuts on the prep (undercuts extending too close to margin may cause minimum thickness problems around margin)',
            ],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        showInsertionAxis: true,
                        visible: false,
                        showUndercutShadow: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        colorize: false,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
        },
        {
            category: 'path_of_insertion',
            name: 'Common POI',
            grading: {
                type: 'tertiary',
                bad: 'POI is not identical across all adjacent units, even though it was possible',
                acceptable: 'POI is not identical across all units because it was NOT possible',
                ideal: 'POI is identical across all adjacent units',
            },
            description: `A common POI is chosen <strong>whenever possible<strong>
                <br><br>Always subservient to even distribution of interproximal undercut`,
            selectable_bad_reasons: ['POI was possible but not achieved'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        showInsertionAxis: true,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        colorize: false,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasAdjacentRestorations,
        },
        {
            category: 'path_of_insertion',
            name: 'Deviation between abutments',
            grading: {
                type: 'binary',
                bad: 'More than 12 degrees of deviation',
                ideal: 'Less than 12 degrees of deviation',
            },
            description: `Insertion direction cannot deviate by more than 12 degrees
            <br><br>Individual insertion should only be used when necessary`,
            selectable_bad_reasons: ['Excessive undercuts on the prep'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        showInsertionAxis: true,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        colorize: false,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasBridge,
        },
    ],
    design_standard: {
        googleEmbedStub: `2PACX-1vQALAgFzwvCLn34w_GuLzuDV8YFE3Xr8FhT_2dEOhGIuZah8t_ypKEx3-jqMC8eCSlY50UShdueKla0`,
    },
};
