import { AnnotationList } from './AnnotationList';
import type { Annotation } from './types';
import type { RootActionDialogProps } from '@orthly/ui';
import { RootActionDialog } from '@orthly/ui';
import React from 'react';

export type AnnotationListModalProps = Pick<RootActionDialogProps, 'open' | 'setOpen' | 'dialogProps'> & {
    annotations: Annotation[];
    onRemove?: (index: number, annotation: Annotation) => void;
    canRemove?: boolean;
};

/**
 * You can use this component to show a modal showing a list of annotations.
 *
 * It only needs an array of annotations along with a `open` and `setOpen` function
 * to control when to show the modal.
 */
export const AnnotationListModal: React.VFC<AnnotationListModalProps> = ({
    annotations,
    open,
    setOpen,
    onRemove,
    canRemove,
    dialogProps,
}) => {
    if (annotations.length === 0) {
        return null;
    }

    return (
        <RootActionDialog
            showCloseButton
            loading={false}
            title={'Annotations'}
            content={<AnnotationList annotations={annotations} canRemove={canRemove} onRemove={onRemove} />}
            hideButton
            dialogProps={{
                maxWidth: 'sm',
                fullWidth: true,
                ...(dialogProps ?? {}),
            }}
            open={open}
            setOpen={setOpen}
        />
    );
};
