import type { ItemInfo, Rule, Decision } from '../orderEditMode';
import { OrderEditMode } from '@orthly/shared-types';

/**
 * not inverts the result of another rule, returning null if the child
 * rule returned OrderEdit and OrderEdit if it returned null or
 * CancelAndResubmit
 */
export function not(child: Rule): Rule {
    return function (item: ItemInfo): Decision {
        const result = child(item);
        const debugInfo = {
            rule: 'not',
            result: result,
        };

        if (result.editMode === OrderEditMode.OrderEdit) {
            return {
                editMode: null,
                debugInfo,
            };
        }

        return {
            editMode: OrderEditMode.OrderEdit,
            debugInfo,
        };
    };
}
