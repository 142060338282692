import { PRINTABLE_SLIP_NESTED_BORDER_COLOR } from '../../styles/colors';
import type { IMergedItem, IBaseItemData } from '../../types';
import { isSingleToothUnitItem } from '../../types';
import * as ItemUtils from '../../utils/item.util';
import { getHeaderForLabSlipItem } from '../../utils/shared.utils';
import { BaseItem } from './BaseItem';
import { ItemShadesTable } from './ItemShadesTable';
import { PrintableSlipItemDetail } from './PrintableSlipItemDetail';
import type { IOrderItemShade, IOrderItemV2DTO } from '@orthly/items';
import { Grid, makeStyles } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = makeStyles({
    nestedSection: {
        borderLeft: `1px solid ${PRINTABLE_SLIP_NESTED_BORDER_COLOR}`,
        marginTop: '0.5rem',
        marginLeft: '0.25rem',
    },
});

/**
 * Renders the body of a SingleToothUnitItem
 */
export const SingleToothUnitItemBody: React.VFC<{
    material: string;
    shades: IOrderItemShade[];
    occlusalStainingPreference: string | null;
    maximumModelHeight: number | undefined;
    puckHeight: number | undefined;
}> = ({ material, shades, occlusalStainingPreference, maximumModelHeight, puckHeight }) => {
    const classes = useStyles();
    const crownHeight =
        maximumModelHeight &&
        `${_.round(maximumModelHeight, 2)}mm${puckHeight ? ` (Puck Height ${_.round(puckHeight, 2)}mm)` : ''}`;

    return (
        <Grid container className={classes.nestedSection}>
            {/*Item Material*/}
            <PrintableSlipItemDetail title={'Material'} value={material} />

            {/*Item Shades*/}
            <PrintableSlipItemDetail title={'Shade'}>
                <ItemShadesTable shades={shades} />
            </PrintableSlipItemDetail>

            {/*Occlusal Staining*/}
            <PrintableSlipItemDetail title={'Occlusal Staining'} value={occlusalStainingPreference} />

            {/*Crown Height*/}
            {maximumModelHeight && <PrintableSlipItemDetail title={'Crown Height'} value={crownHeight} />}
        </Grid>
    );
};

/**
 * Renders a SingleToothUnitItem order item to display on
 * the Printable Slip
 * @param props The order item to render
 * @returns Rendered order item details for the order "Crown" item
 */
export const SingleToothUnitItem: React.VFC<{
    mergedItem: IMergedItem;
}> = ({ mergedItem }) => {
    return <BaseItem itemData={mapSingleToothItemToPrintableSlipData(mergedItem)} />;
};

/**
 * Pull data needed for printable slip display into a standard data structure
 * @param mergedItem IMergedItem of a ISingleToothUnitItem order item to display
 * @returns Structured data for Single Tooth item display
 */
function mapSingleToothItemToPrintableSlipData(mergedItem: IMergedItem): IBaseItemData | null {
    const item: IOrderItemV2DTO = mergedItem.defaultItem;
    if (!isSingleToothUnitItem(item)) {
        return null;
    }

    const maxModelHeight = _.max(mergedItem.groupItems.map(i => ItemUtils.getMaximumModelElementHeightInMm(i)));
    const maxPuckHeight = _.max(mergedItem.groupItems.map(i => ItemUtils.getPuckHeight(i)));
    const headerLeft = getHeaderForLabSlipItem(mergedItem);
    return {
        headerLeft,
        itemFields: [
            {
                customComponent: (
                    <SingleToothUnitItemBody
                        material={item.unit.material}
                        shades={item.shades}
                        occlusalStainingPreference={ItemUtils.getOcclusalStainingPreference(item)}
                        maximumModelHeight={maxModelHeight}
                        puckHeight={maxPuckHeight}
                    />
                ),
            },
        ],
    };
}
