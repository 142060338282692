import { ComparatorLabels } from '../../screens/Automations/screens/AutomationBuilder/AutomationFilterStep/criteria-fields/OrderFilterComparatorField';
import {
    AutomationMultiSelectField,
    AutomationSelectField,
} from '../../screens/Automations/screens/AutomationBuilder/components/AutomationBuilderFields';
import { useOpsInboxAction } from '../../screens/Inbox/state/Inbox.actions';
import { useInboxTasksFilter } from '../../screens/Inbox/state/Inbox.selectors';
import type { FiltersPopoverContentProps, FiltersDateProps, FiltersMultiSelectProps } from './InboxListFilters.types';
import { ClearFilterButton, DateComparators, useSelectDisplayProps } from './ListFilters';
import type { OrdersToolbarPopperContentProps } from './OrdersToolbarPopper';
import { OrdersToolbarPopper } from './OrdersToolbarPopper';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlListWorkflowTasksFilter } from '@orthly/graphql-schema';
import { LabsGqlFilterComparator, LabsGqlWorkflowTaskType, LabsGqlResponsiblePartyEnum } from '@orthly/graphql-schema';
import type { SimpleSelectOption } from '@orthly/ui';
import { SimpleDatePicker, SimpleSelect, XIcon } from '@orthly/ui';
import { Button, FlossPalette, FlossPaletteUtils, Text, Tooltip, Grid, IconButton } from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const FilterTitle: React.FC<{ title: string }> = props => {
    return (
        <Grid container xs={3} style={{ alignContent: 'center' }}>
            <Text variant={'body2'}>{props.title}</Text>
        </Grid>
    );
};

const FilterClearButton: React.FC<{ onClear: () => void; isDefined?: boolean }> = props => {
    return props.isDefined ? (
        <Grid container item xs={1} alignItems={'center'} style={{ marginLeft: 16 }}>
            <IconButton size={'small'} onClick={props.onClear}>
                <Tooltip title={'Clear'}>
                    <XIcon />
                </Tooltip>
            </IconButton>
        </Grid>
    ) : null;
};

const FiltersMultiSelect: React.FC<FiltersMultiSelectProps> = props => {
    const { options, value, onClear, onSelect, title, isDefined } = props;
    const alphabeticalOptions = _.sortBy(options, o => o.label);
    return (
        <Grid container item>
            <FilterTitle title={title} />
            <Grid xs={3}>
                <AutomationMultiSelectField
                    options={alphabeticalOptions}
                    onChange={onSelect}
                    value={value}
                    label={''}
                />
            </Grid>
            <FilterClearButton onClear={onClear} isDefined={isDefined} />
        </Grid>
    );
};

interface FiltersSingleSelectProps {
    options: SimpleSelectOption[];
    value: string | undefined;
    onClear: () => void;
    onSelect: (value?: string) => void;
    title: string;
    isDefined: boolean;
}

const FiltersSingleSelectField: React.FC<FiltersSingleSelectProps> = props => {
    const { options, value, onClear, onSelect, title, isDefined } = props;
    const alphabeticalOptions = _.sortBy(options, o => o.label);
    return (
        <Grid container item>
            <FilterTitle title={title} />
            <Grid xs={3}>
                <AutomationSelectField options={alphabeticalOptions} onChange={onSelect} value={value} label={''} />
            </Grid>
            <FilterClearButton onClear={onClear} isDefined={isDefined} />
        </Grid>
    );
};

const FiltersDatePicker: React.FC<FiltersDateProps> = props => {
    const { title, comparatorValue, onComparatorSelect, dateValue, onDateSelect, onClear, isDefined } = props;
    return (
        <Grid container item>
            <FilterTitle title={title} />
            <Grid xs={2} style={{ marginRight: 36 }}>
                <AutomationSelectField
                    label={''}
                    options={DateComparators.map(comp => ({ value: comp, label: ComparatorLabels[comp] }))}
                    value={comparatorValue}
                    onChange={onComparatorSelect}
                />
            </Grid>
            <Grid xs={3}>
                <SimpleDatePicker
                    label={''}
                    minDate={moment('2020-01-01').toDate()}
                    sx={{ paddingTop: 0 }}
                    value={dateValue}
                    onChange={onDateSelect}
                />
            </Grid>
            <FilterClearButton onClear={onClear} isDefined={isDefined} />
        </Grid>
    );
};

const UNASSIGNED = `unassigned`;

const hasOnlyDesignReviewSelected = (values: string[]): boolean => {
    const designTasks = [LabsGqlWorkflowTaskType.DesignReview, LabsGqlWorkflowTaskType.DesignReview2] as string[];
    return values.length > 0 && values.every(task => (designTasks as string[]).includes(task));
};

const hasOnlyDesignSelected = (values: string[]): boolean => {
    const designTasks = [LabsGqlWorkflowTaskType.ExternalDesign, LabsGqlWorkflowTaskType.InternalDesign] as string[];
    return values.length > 0 && values.every(task => (designTasks as string[]).includes(task));
};

interface DependentTaskFiltersProps {
    title: string;
    value: string;
    labels?: { true?: string; false?: string };
    onChange: (newVal: boolean | null) => void;
}

const BooleanOrUnsetFilter: React.VFC<DependentTaskFiltersProps> = ({ title, value, labels, onChange }) => {
    return (
        <Grid container item>
            <FilterTitle title={title} />
            <Grid xs={3}>
                <AutomationSelectField
                    label={``}
                    options={[
                        { value: ``, label: `(unset)` },
                        { value: `true`, label: labels?.true ?? 'True' },
                        { value: `false`, label: labels?.false ?? 'False' },
                    ]}
                    value={value}
                    onChange={val => {
                        const boolOrNull = val ? val === `true` : null;
                        onChange(boolOrNull);
                    }}
                />
            </Grid>
        </Grid>
    );
};

const ResponsiblePartyValues = Object.values(LabsGqlResponsiblePartyEnum);
const ResponsiblePartyOptions = ResponsiblePartyValues.map(value => ({
    value,
    label: value === LabsGqlResponsiblePartyEnum.Psr ? 'Dandy' : _.startCase(value),
}));

function isValidResponsibleParty(value?: string): value is LabsGqlResponsiblePartyEnum {
    return !!value && (ResponsiblePartyValues as string[]).includes(value);
}

const ListUsers = graphql(`
    query ListUsers_Query {
        listUsers(filter: { organization_type: internal }) {
            id
            first_name
            last_name
        }
    }
`);

const FiltersPopoverContent: React.FC<OrdersToolbarPopperContentProps & FiltersPopoverContentProps> = props => {
    const { setOpen, setChanged, changed } = props;
    const { data: { listUsers = [] } = {} } = useQuery(ListUsers, {
        fetchPolicy: 'cache-first',
    });
    const setTasksFilter = useOpsInboxAction('SET_TASK_FILTER');
    const tasksFilter = useInboxTasksFilter();
    const allTypes = Object.values(LabsGqlWorkflowTaskType);
    const [filter, setFilter] = React.useState<LabsGqlListWorkflowTasksFilter>(tasksFilter ?? {});
    const users = listUsers.map(user => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name}`,
    }));

    const taskTypes = allTypes.map(value => ({ value, label: _.startCase(value) }));
    const isUnchanged = React.useMemo(() => {
        return _.isEqual(filter, tasksFilter) || (_.isEqual(filter, {}) && !tasksFilter);
    }, [tasksFilter, filter]);
    const onApply = React.useCallback(() => {
        setTasksFilter(filter);
        setOpen(false);
    }, [setTasksFilter, setOpen, filter]);
    const changeFilter = React.useCallback(
        (newFilter: LabsGqlListWorkflowTasksFilter) => {
            setFilter(newFilter);
            !changed && setChanged(true);
        },
        [setChanged, changed],
    );
    return (
        <Grid container spacing={2}>
            <FiltersMultiSelect
                title={'Task Assignee'}
                options={[{ value: UNASSIGNED, label: `(unassigned)` }, ...users]}
                value={filter.assigned_user_ids?.map(id => id ?? UNASSIGNED) ?? []}
                onClear={() => setFilter({ ...filter, assigned_user_ids: undefined })}
                onSelect={val => {
                    const assigned_user_ids = val?.map(id => (id === UNASSIGNED ? null : id)) ?? [];
                    changeFilter({ ...filter, assigned_user_ids });
                }}
                isDefined={!!filter.assigned_user_ids && filter.assigned_user_ids.length > 0}
            />
            <FiltersMultiSelect
                title={'Completed by'}
                options={users}
                value={filter.completed_by_ids ?? []}
                onClear={() => setFilter({ ...filter, completed_by_ids: undefined })}
                onSelect={val => {
                    val && changeFilter({ ...filter, completed_by_ids: val });
                }}
                isDefined={!!filter.completed_by_ids && filter.completed_by_ids.length > 0}
            />
            <FiltersMultiSelect
                title={'Task Type'}
                options={taskTypes}
                value={filter.type ?? []}
                onClear={() => setFilter({ ...filter, type: undefined, design_review_required: undefined })}
                onSelect={values => {
                    values &&
                        changeFilter({
                            ...filter,
                            type: values.flatMap(val => {
                                const type = allTypes.find(enumVal => enumVal === val);
                                return !!type ? [type] : [];
                            }),
                            design_review_required:
                                // if Design Review is not the only type selected, clear our design_review_requested
                                hasOnlyDesignReviewSelected(values) ? filter.design_review_required : undefined,
                            is_revision:
                                // if Design task type(s) are not the only types selected, clear our design_review_requested
                                hasOnlyDesignSelected(values) ? filter.is_revision : undefined,
                        });
                }}
                isDefined={!!filter.type && filter.type.length > 0}
            />
            <>
                {!!filter.type && hasOnlyDesignReviewSelected(filter.type) ? (
                    <BooleanOrUnsetFilter
                        title={'Review Requirement'}
                        value={filter.design_review_required !== null ? `${filter.design_review_required}` : ``}
                        labels={{ true: 'Required', false: 'Optional' }}
                        onChange={design_review_required => {
                            changeFilter({ ...filter, design_review_required });
                        }}
                    />
                ) : null}
                {!!filter.type && hasOnlyDesignSelected(filter.type) ? (
                    <BooleanOrUnsetFilter
                        title={'Is Design Revision'}
                        value={filter.is_revision !== null ? `${filter.is_revision}` : ``}
                        labels={{ true: 'True (revisions only)', false: 'False (initial designs only)' }}
                        onChange={is_revision => {
                            changeFilter({ ...filter, is_revision });
                        }}
                    />
                ) : null}
            </>
            <FiltersSingleSelectField
                options={ResponsiblePartyOptions}
                value={filter.responsible_party?.role}
                onClear={() => {
                    changeFilter({ ...filter, responsible_party: null });
                }}
                onSelect={value => {
                    const responsible_party = isValidResponsibleParty(value) ? { role: value } : null;
                    changeFilter({ ...filter, responsible_party });
                }}
                title={'Assigned org'}
                isDefined={!!filter.responsible_party}
            />
            <BooleanOrUnsetFilter
                title={'Is Design Revision'}
                value={filter.completed !== null ? `${filter.completed}` : ``}
                labels={{ true: 'Complete', false: 'Not complete' }}
                onChange={completed => {
                    changeFilter({ ...filter, completed });
                }}
            />
            <FiltersDatePicker
                title={'Completed Date'}
                comparatorValue={filter.completed_at?.comparator ?? undefined}
                onComparatorSelect={val => {
                    changeFilter({
                        ...filter,
                        completed_at: {
                            ...filter.completed_at,
                            comparator: Object.values(LabsGqlFilterComparator).find(enumVal => enumVal === val),
                        },
                    });
                }}
                dateValue={
                    !!filter.completed_at?.comparison_value ? new Date(filter.completed_at.comparison_value) : null
                }
                onDateSelect={date => {
                    changeFilter({
                        ...filter,
                        completed_at: {
                            ...filter.completed_at,
                            comparison_value: date?.toDateString() ?? undefined,
                        },
                    });
                }}
                onClear={() => setFilter({ ...filter, completed_at: undefined })}
                isDefined={!!filter.completed_at?.comparison_value || !!filter.completed_at?.comparator}
            />
            <FiltersDatePicker
                title={'Created Date'}
                comparatorValue={filter.created_at?.comparator ?? undefined}
                onComparatorSelect={val => {
                    changeFilter({
                        ...filter,
                        created_at: {
                            ...filter.created_at,
                            comparator: Object.values(LabsGqlFilterComparator).find(enumVal => enumVal === val),
                        },
                    });
                }}
                dateValue={!!filter.created_at?.comparison_value ? new Date(filter.created_at.comparison_value) : null}
                onDateSelect={date => {
                    changeFilter({
                        ...filter,
                        created_at: {
                            ...filter.created_at,
                            comparison_value: date ? date?.toDateString() : undefined,
                        },
                    });
                }}
                onClear={() => setFilter({ ...filter, created_at: undefined })}
                isDefined={!!filter.created_at?.comparison_value || !!filter.created_at?.comparator}
            />
            <Grid container item justifyContent={'flex-end'}>
                <Button variant={'ghost'} onClick={() => props.setOpen(false)}>
                    Cancel
                </Button>
                <Button disabled={isUnchanged} variant={'primary'} onClick={onApply} style={{ marginLeft: 8 }}>
                    Apply
                </Button>
            </Grid>
        </Grid>
    );
};

const ClearFilterButtonInbox: React.FC<{ className?: string }> = props => {
    const clearTaskFilter = useOpsInboxAction('CLEAR_TASK_FILTER');
    const setScreen = useOpsInboxAction('SET_SCREEN');
    return (
        <ClearFilterButton
            className={props.className}
            onClear={() => {
                clearTaskFilter();
                setScreen('AssignedToMe');
            }}
        />
    );
};

export const InboxListFilters: React.FC = () => {
    const [changed, setChanged] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const { value: displayValue, options: displayOpts } = useSelectDisplayProps();

    const popperSetOpen = React.useCallback(
        (newOpen: boolean, isBackgroundClick: boolean) => {
            if (!newOpen && changed) {
                if (isBackgroundClick && !window.confirm('Abandon changes?')) {
                    return;
                }
                setChanged(false);
            }
            setOpen(newOpen);
        },
        [changed],
    );
    return (
        <OrdersToolbarPopper<FiltersPopoverContentProps>
            ContentProps={{ changed, setChanged: () => setChanged(true) }}
            PopoverContent={FiltersPopoverContent}
            open={open}
            setOpen={popperSetOpen}
            popoverWidth={'50vw'}
            rootStyle={{
                background: !!displayValue ? FlossPaletteUtils.toRgba('STAR_GRASS', 0.15) : undefined,
                width: '20%',
            }}
        >
            <SimpleSelect
                // just used for display
                value={displayValue}
                options={displayOpts}
                onChange={() => {}}
                label={'Filter by task'}
                FormControlProps={{ onClick: () => setOpen(true), variant: 'standard' }}
                InputLabelProps={{ style: { backgroundColor: 'transparent' } }}
                SelectProps={{
                    disabled: true,
                    style: { backgroundColor: 'transparent', color: FlossPalette.BLACK, cursor: 'pointer' },
                    SelectDisplayProps: { style: { cursor: 'pointer' } },
                    IconComponent: !!displayValue ? ClearFilterButtonInbox : undefined,
                    variant: 'standard',
                }}
            />
        </OrdersToolbarPopper>
    );
};
