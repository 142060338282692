import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LoadBlocker, useRootActionCommand, Icon } from '@orthly/ui';
import { Button, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

const PromoteDesignRevisionToLatest_Mutation = graphql(`
    mutation PromoteDesignRevisionToLatest($orderId: String!, $revisionId: String!) {
        promoteDesignRevisionToLatest(orderId: $orderId, revisionId: $revisionId)
    }
`);

interface PromoteRevisionButtonProps {
    orderId: string;
    selectedRevision: { is_latest_design: boolean; id: string };
    refetch: () => void;
}

export const PromoteRevisionButton: React.FC<PromoteRevisionButtonProps> = props => {
    const { orderId, selectedRevision, refetch } = props;
    const isLatest = selectedRevision.is_latest_design;

    const { submit, submitting } = useRootActionCommand(useMutation(PromoteDesignRevisionToLatest_Mutation), {
        successMessage: 'Revision promoted to latest!',
        onSuccess: () => {
            refetch();
        },
    });

    return (
        <Tooltip title={isLatest ? 'This revision is already the latest' : 'Promote this revision to latest'}>
            <div>
                <Button
                    onClick={() => {
                        void submit({
                            orderId,
                            revisionId: selectedRevision.id,
                        });
                    }}
                    variant={'text'}
                    disabled={isLatest || submitting}
                >
                    <LoadBlocker blocking={submitting}>
                        <Icon icon={'KeyboardDoubleArrowUp'} />
                    </LoadBlocker>
                </Button>
            </div>
        </Tooltip>
    );
};
