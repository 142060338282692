import { useChangeAttachmentVisibility } from './DandyChat.hooks';
import { getChatMessageVisibilityForWrite } from '@orthly/chat';
import { LabsGqlStaffRoleWithAny } from '@orthly/graphql-schema';
import { IOrganizationType } from '@orthly/retainer-common';
import { useHasCapability } from '@orthly/session-client';
import { RootActionDialog, SimpleCheckbox } from '@orthly/ui';
import { Button, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface ChangeAttachmentVisibilityDialogButtonProps {
    senderRole: IOrganizationType;
    onChangeAttachmentVisibility: (visible_to_roles: LabsGqlStaffRoleWithAny[]) => Promise<void>;
    attachmentVisibility?: string[];
    practice_id: string;
    lab_id: string;
}

export const ChangeAttachmentVisibilityDialogButton: React.FC<ChangeAttachmentVisibilityDialogButtonProps> = props => {
    const onChangeAttachmentVisibilityProps = useChangeAttachmentVisibility(props.onChangeAttachmentVisibility);
    const [visibleToManufacturer, setVisibleToManufacturer] = React.useState<boolean>(false);
    const [visibleToPractice, setVisibleToPractice] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!props.attachmentVisibility) {
            return;
        }
        setVisibleToManufacturer(props.attachmentVisibility.includes(IOrganizationType.lab));
        setVisibleToPractice(props.attachmentVisibility.includes(IOrganizationType.practice));
    }, [props.attachmentVisibility]);

    const visibilityChanged = React.useMemo(() => {
        const initiallyVisibleToPractice = props.attachmentVisibility?.includes(IOrganizationType.practice);
        const initiallyVisibleToManufacturer = props.attachmentVisibility?.includes(IOrganizationType.lab);

        return (
            initiallyVisibleToManufacturer !== visibleToManufacturer || initiallyVisibleToPractice !== visibleToPractice
        );
    }, [props.attachmentVisibility, visibleToManufacturer, visibleToPractice]);

    const submitVisibilityChange = React.useCallback(() => {
        const additionalRoles: LabsGqlStaffRoleWithAny[] = [];
        if (visibleToManufacturer) {
            additionalRoles.push(LabsGqlStaffRoleWithAny.LabAny);
        }
        if (visibleToPractice) {
            additionalRoles.push(LabsGqlStaffRoleWithAny.PracticeAny);
        }
        const visible_to_roles = getChatMessageVisibilityForWrite<LabsGqlStaffRoleWithAny>(
            props.senderRole,
            additionalRoles,
        );
        onChangeAttachmentVisibilityProps.submit(visible_to_roles).catch(console.error);
    }, [visibleToManufacturer, visibleToPractice, props.senderRole, onChangeAttachmentVisibilityProps]);

    const canSendToLab = useHasCapability('chat', 'chat.send_to_lab');
    const canSendToPractice = useHasCapability('chat', 'chat.send_to_practice');

    const userRoleTitle =
        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
        // eslint-disable-next-line no-nested-ternary
        props.senderRole === IOrganizationType.internal
            ? 'Ops'
            : // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
              // eslint-disable-next-line no-nested-ternary
              props.senderRole === IOrganizationType.lab
              ? 'The lab'
              : props.senderRole === IOrganizationType.practice
                ? 'The practice'
                : 'Not sure who';

    return (
        <RootActionDialog
            loading={onChangeAttachmentVisibilityProps.submitting}
            open={onChangeAttachmentVisibilityProps.open}
            setOpen={onChangeAttachmentVisibilityProps.setOpen}
            title={'Update Visibility'}
            style={{ padding: 0, float: 'right' }}
            buttonText={'Make Visible'}
            buttonProps={{
                variant: 'ghost',
                fullWidth: false,
                style: { paddingLeft: 0 },
                startIcon: 'VisibilityOutlinedIcon',
            }}
            showCloseButton={true}
            content={
                <div>
                    <div>
                        <b>{userRoleTitle}</b> uploaded the attachment
                    </div>
                    <br />
                    <div>{'Select who can see the attachment:'}</div>
                    <Grid container>
                        {canSendToPractice && props.senderRole !== IOrganizationType.practice && (
                            <SimpleCheckbox
                                checked={visibleToPractice}
                                setChecked={setVisibleToPractice}
                                label={'Practice'}
                            />
                        )}
                        {canSendToLab && props.senderRole !== IOrganizationType.lab && (
                            <SimpleCheckbox
                                checked={visibleToManufacturer}
                                setChecked={setVisibleToManufacturer}
                                label={'Manufacturer'}
                            />
                        )}
                    </Grid>
                    <Grid container justifyContent={'flex-end'} style={{ marginTop: 16 }}>
                        <Button variant={'primary'} disabled={!visibilityChanged} onClick={submitVisibilityChange}>
                            Save Visibility
                        </Button>
                    </Grid>
                </div>
            }
        />
    );
};
