import type { OrderDesignFileStatus } from './ThreeshapePlugin.types';
import {
    getSubdirectoryForTask,
    useRecursiveTimeout,
    logThreeshapePluginOperation,
    getAssignedDesignTasks,
} from './ThreeshapePluginUtils';
import type { ApolloClient } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import type {
    LabsGqlCompleteInternalDesignTaskMutation,
    LabsGqlCompleteInternalDesignTaskMutationVariables,
    LabsGqlCompleteDesignPrepTaskMutation,
    LabsGqlCompleteDesignPrepTaskMutationVariables,
} from '@orthly/graphql-operations';
import { CompleteInternalDesignTaskDocument, CompleteDesignPrepTaskDocument } from '@orthly/graphql-react';
import { LabsGqlThreeshapePluginOperationTypeInput } from '@orthly/graphql-schema';
import { DesignTaskType, getFullStoragePath, DesignStorageConfigs } from '@orthly/shared-types';
import { OrthlyBrowserConfig } from '@orthly/ui';
import { useFirebaseStorage } from '@orthly/veneer';
import type Firebase from 'firebase/compat';

/**
 * Uploads the given blob to firebase storage. The path is determined by the orderId
 * @returns {Promise<string>} The firebase path to the uploaded file
 */
async function uploadBlob(
    firebaseStorage: Firebase.storage.Storage,
    orderId: string,
    fileName: string,
    blob: Blob,
): Promise<string> {
    const storagePath = getFullStoragePath(
        OrthlyBrowserConfig.env,
        DesignStorageConfigs.designs,
        orderId,
        Date.now().toString(),
    );
    const validBucketStorage = firebaseStorage.app.storage(storagePath.bucketName);
    const storageRef = validBucketStorage.ref(storagePath.path).child(fileName);
    const output = await storageRef.put(blob);
    return output.metadata.fullPath;
}

/**
 * Uploads the design artifact from the given directory handle
 * The directory should only contain a single zip file for upload
 * @param {firebase.storage.Storage} firebaseStorage
 * @param {FileSystemDirectoryHandle} dirHandle The directory handle containing the design artifact
 * @param orderId The design order ID
 * @param taskType The design task type
 * @param apolloClient The apollo client instance to use to log status
 * @returns {Promise<string | null>} The path to the uploaded file or null if no design file was found
 */
async function uploadDesignArtifact(
    firebaseStorage: Firebase.storage.Storage,
    dirHandle: FileSystemDirectoryHandle,
    orderId: string,
    taskType: DesignTaskType,
    apolloClient: ApolloClient<any>,
): Promise<string | null> {
    //@ts-expect-error
    for await (const handle of dirHandle.values()) {
        if (handle.name.endsWith('.zip')) {
            try {
                await logThreeshapePluginOperation(
                    apolloClient,
                    orderId,
                    taskType,
                    LabsGqlThreeshapePluginOperationTypeInput.UploadStarting,
                );
                const filename = handle.name;
                const file = await handle.getFile();
                const filePath = await uploadBlob(firebaseStorage, orderId, filename, file);
                await logThreeshapePluginOperation(
                    apolloClient,
                    orderId,
                    taskType,
                    LabsGqlThreeshapePluginOperationTypeInput.UploadComplete,
                );
                return filePath;
            } catch (e) {
                await logThreeshapePluginOperation(
                    apolloClient,
                    orderId,
                    taskType,
                    LabsGqlThreeshapePluginOperationTypeInput.UploadFailed,
                    e as Error,
                );
                throw e;
            }
        }
    }
    // Note: This will likely get too noisy and will be turned off before wider rollout
    await logThreeshapePluginOperation(
        apolloClient,
        orderId,
        taskType,
        LabsGqlThreeshapePluginOperationTypeInput.UploadSkipped,
    );
    return null;
}

async function completeInternalDesignTask(apolloClient: ApolloClient<any>, orderId: string, uploadedFilePath: string) {
    await apolloClient.mutate<
        LabsGqlCompleteInternalDesignTaskMutation,
        LabsGqlCompleteInternalDesignTaskMutationVariables
    >({
        variables: {
            data: {
                orderId,
                design_file_path: uploadedFilePath,
                is_manual_upload: false,
                internal_notes: 'Threeshape Plugin Upload',
            },
        },
        mutation: CompleteInternalDesignTaskDocument,
    });
}

async function completeDesignPrepTask(apolloClient: ApolloClient<any>, orderId: string, uploadedFilePath: string) {
    await apolloClient.mutate<LabsGqlCompleteDesignPrepTaskMutation, LabsGqlCompleteDesignPrepTaskMutationVariables>({
        variables: {
            data: {
                order_id: orderId,
                notes: 'Threeshape Plugin Upload',
                prepped_design_file_path: uploadedFilePath,
            },
        },
        mutation: CompleteDesignPrepTaskDocument,
    });
}

/**
 * Uploads the design artifact, completes the design task and deletes all associated files from both the uploads and downloads directory
 */
async function handleSingleCompletedDesignTask(
    firebaseStorage: Firebase.storage.Storage,
    apolloClient: ApolloClient<any>,
    uploadsDir: FileSystemDirectoryHandle,
    downloadsDir: FileSystemDirectoryHandle | null,
    orderId: string,
    taskType: DesignTaskType,
) {
    const dirHandle = await uploadsDir.getDirectoryHandle(orderId);
    const uploadedFilePath = await uploadDesignArtifact(firebaseStorage, dirHandle, orderId, taskType, apolloClient);
    if (uploadedFilePath) {
        // also complete the design task
        if (taskType === DesignTaskType.InternalDesign) {
            await completeInternalDesignTask(apolloClient, orderId, uploadedFilePath);
        } else if (taskType === DesignTaskType.DesignPrep) {
            await completeDesignPrepTask(apolloClient, orderId, uploadedFilePath);
        } else {
            throw new Error(`Unknown task type ${taskType}. Skipping upload for ${orderId}`);
        }
        await uploadsDir.removeEntry(orderId, { recursive: true });
        if (downloadsDir) {
            await downloadsDir.removeEntry(orderId, { recursive: true });
        }
    }
}

/**
 * Uploads files in the uploads directory, completes the design task and deletes all associated files from
 * both the uploads and downloads directory
 */
async function handleCompletedDesignTasks(
    firebaseStorage: Firebase.storage.Storage,
    apolloClient: ApolloClient<any>,
    uploadsRootDir: FileSystemDirectoryHandle,
    downloadsRootDir: FileSystemDirectoryHandle,
    updateDesignFileStatus: (updatedOrders: Record<string, OrderDesignFileStatus>) => void,
) {
    // identify all assigned design tasks
    const assignedDesignTasks = await getAssignedDesignTasks(apolloClient);

    const latestOrderStatus: Record<string, OrderDesignFileStatus> = {};
    // we only attempt to upload artifacts for assigned tasks
    for (const task of assignedDesignTasks) {
        const orderId = task.orderId;
        const taskType = task.taskType;
        const uploadsDir = await getSubdirectoryForTask(uploadsRootDir, taskType);
        // check to see if we have a folder corresponding to the assigned task
        // the only way to check is to call the getDirectoryHandle method and verify it doesn't throw
        try {
            await uploadsDir.getDirectoryHandle(orderId);
        } catch (__e) {
            // the directory doesn't exist so there's nothing to do for this task
            continue;
        }
        // the directory exists, so we should have an artifact to upload
        try {
            const downloadsDir = await getSubdirectoryForTask(downloadsRootDir, taskType);
            await handleSingleCompletedDesignTask(
                firebaseStorage,
                apolloClient,
                uploadsDir,
                downloadsDir,
                orderId,
                taskType,
            );
            latestOrderStatus[orderId] = { taskType, currentAction: 'Upload', success: true };
        } catch (e) {
            console.error(e);
            latestOrderStatus[orderId] = { taskType, currentAction: 'Upload', success: false };
        }
    }
    updateDesignFileStatus(latestOrderStatus);
}

export const DesignFileUploadPlugin: React.FC<{
    uploadDirHandle: FileSystemDirectoryHandle;
    downloadDirHandle: FileSystemDirectoryHandle;
    updateDesignFileStatus: (updatedOrders: Record<string, OrderDesignFileStatus>) => void;
}> = ({ uploadDirHandle, downloadDirHandle, updateDesignFileStatus }) => {
    const firebaseStorage = useFirebaseStorage();
    const apolloClient = useApolloClient();
    useRecursiveTimeout(async () => {
        await handleCompletedDesignTasks(
            firebaseStorage,
            apolloClient,
            uploadDirHandle,
            downloadDirHandle,
            updateDesignFileStatus,
        );
    }, 30000);

    return null;
};
