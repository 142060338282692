import type { LabsGqlDesignEvaluationByIdOrderFragmentFragment } from '@orthly/graphql-operations';
import type React from 'react';

// A minimal set of properties needed through DesignQC to represent an order.
// Stored as a common type because many components within the system will
// TODO: replace this with inline graphql once we have a good solution for items_v2.
export type DesignQcOrder = LabsGqlDesignEvaluationByIdOrderFragmentFragment;

export type DesignQcSidebarEntry = {
    name: string;
    sidebarBody: React.ReactNode;
};

export const DESIGN_QC_TOGGLE_VIEWS_ENABLED_LOCAL_STORAGE_KEY = 'DESIGN_QC_TOGGLE_VIEWS_ENABLED';
