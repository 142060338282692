import React from 'react';

/**
 * Returns an instance of HTMLAudioElement that will have been played in response to a user event if possible with
 * volume = 0.0. After the audio has finished playing in response to user action it will have its volume set to 1.0.
 */
export function useUserInteractedAudio(audioSource: string) {
    const [interactedAudio, setInteractedAudio] = React.useState<HTMLAudioElement | null>(null);
    React.useEffect(() => {
        const audio = new Audio(audioSource);
        audio.volume = 0;
        const onFirstEnd = () => {
            audio.volume = 1;
            audio.removeEventListener('ended', onFirstEnd);
            setInteractedAudio(audio);
        };
        audio.addEventListener('ended', onFirstEnd);
        const clickHandler = () => {
            document.removeEventListener('click', clickHandler);
            void audio.play().catch(error => {
                console.error('Failed to play audio', { error, audioSource });
            });
        };
        document.addEventListener('click', clickHandler);
        return () => {
            audio.removeEventListener('ended', onFirstEnd);
            document.removeEventListener('click', clickHandler);
        };
    }, [audioSource]);
    return interactedAudio;
}
