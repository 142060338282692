import { getPresetTitle } from '../../../../GuidedWaxup.util';
import { useGuidedWaxupContext } from '../../../../state/GuidedWaxupContext';
import { useGuidedWaxupAction } from '../../../../state/GuidedWaxupState';
import type { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { LabsGqlGuidedWaxupPresetStatus } from '@orthly/graphql-schema';
import { Box, Button, Icon, Text, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

const ApprovedControl: React.VFC<{
    onClick: (status: LabsGqlGuidedWaxupPresetStatus) => any;
}> = ({ onClick }) => {
    const { selectedDesignRevisionAlreadyReviewed } = useGuidedWaxupContext();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
            <Text variant={'body2'} bold color={'GRAY'} sx={{ marginLeft: '12px' }}>
                Approved
            </Text>
            {!selectedDesignRevisionAlreadyReviewed && (
                <Button
                    variant={'ghost'}
                    disabled={selectedDesignRevisionAlreadyReviewed}
                    endIcon={'UndoIcon'}
                    onClick={onClick(LabsGqlGuidedWaxupPresetStatus.Skipped)}
                >
                    Undo
                </Button>
            )}
        </Box>
    );
};

const SkippedControl: React.VFC<{
    onClick: (status: LabsGqlGuidedWaxupPresetStatus) => any;
}> = ({ onClick }) => {
    const { selectedDesignRevisionAlreadyReviewed } = useGuidedWaxupContext();

    return (
        <Button
            variant={'ghost'}
            disabled={selectedDesignRevisionAlreadyReviewed}
            onClick={onClick(LabsGqlGuidedWaxupPresetStatus.Approved)}
        >
            {selectedDesignRevisionAlreadyReviewed ? (
                'Skipped'
            ) : (
                <>
                    Approve
                    <Icon icon={'Check'} fontSize={'small'} sx={{ marginLeft: '1ch' }} />
                </>
            )}
        </Button>
    );
};

const RejectedControl: React.VFC = () => {
    const { selectedDesignRevisionAlreadyReviewed } = useGuidedWaxupContext();

    return (
        <Text variant={'body2'} bold color={'BURGUNDY'} sx={{ marginLeft: '12px' }}>
            {selectedDesignRevisionAlreadyReviewed ? 'Rejected' : 'Rejecting'}
        </Text>
    );
};

export const PresetReviewControlsTitle: React.VFC<{
    presetStatus?: LabsGqlGuidedWaxupPresetStatus;
    presetType: LabsGqlGuidedWaxupPresetType;
}> = ({ presetStatus, presetType }) => {
    const setPresetStructuredRejectionNotes = useGuidedWaxupAction('SET_PRESET_STRUCTURED_REJECTION_NOTES');
    const setPresetStatus = useGuidedWaxupAction('SET_PRESET_STATUS');
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const resetState = React.useCallback(
        (status: LabsGqlGuidedWaxupPresetStatus) => {
            return () => {
                setPresetStatus({ presetName: presetType, status });
                setPresetStructuredRejectionNotes({ presetName: presetType, structuredRejectionNotes: [] });
            };
        },
        [presetType, setPresetStatus, setPresetStructuredRejectionNotes],
    );

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: isMobile ? 'space-between' : 'flex-start',
                gap: '1em',
                alignItems: 'center',
                minHeight: '48px',
                '&:not(:first-of-type)': { marginTop: '12px' },
            }}
        >
            <Text variant={'body1'} bold>
                {getPresetTitle(presetType)}
            </Text>
            {presetStatus === LabsGqlGuidedWaxupPresetStatus.Approved && <ApprovedControl onClick={resetState} />}
            {presetStatus === LabsGqlGuidedWaxupPresetStatus.Skipped && <SkippedControl onClick={resetState} />}
            {presetStatus === LabsGqlGuidedWaxupPresetStatus.Rejected && <RejectedControl />}
        </Box>
    );
};
