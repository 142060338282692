import type { IBaseItemData } from '../../types';
import { mapRemovableItemToLabSlipItemFields } from '../../utils/removableItem.util';
import { getHeaderForLabSlipItem } from '../../utils/shared.utils';
import { BaseItem } from './BaseItem';
import type { IRemovableItem, ITMJItem } from '@orthly/items';
import React from 'react';

/**
 * Renders a "Removable" order item to display on
 * the Printable Slip
 * @param props The order item to render
 * @returns Rendered order item details for the order "Removable" item
 */
export const RemovableItem: React.VFC<{ item: IRemovableItem | ITMJItem }> = ({ item }) => {
    return <BaseItem itemData={mapRemovableItemToPrintableSlipData(item)} />;
};

/**
 * Pull data needed for printable slip display into a standard data structure
 * @param item Removable order item to display
 * @returns Structured data for removable item display
 */
function mapRemovableItemToPrintableSlipData(item: IRemovableItem | ITMJItem): IBaseItemData {
    return {
        headerLeft: getHeaderForLabSlipItem(item),
        itemFields: mapRemovableItemToLabSlipItemFields(item),
    };
}
