import { useGetImpersonationToken } from '../../../../../../utils/useGetImpersonationToken';
import { ImpersonatePreviewBox } from '../../../../../Impersonate/GlobalImpersonate';
import { OrthlyBrowserConfig, Icon } from '@orthly/ui';
import { Button, Dialog, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

interface OrderDetailSummaryImpersonationButtonProps {
    orderId: string;
    practiceId: string;
}

export const OrderDetailSummaryImpersonationButton: React.VFC<OrderDetailSummaryImpersonationButtonProps> = ({
    orderId,
    practiceId,
}) => {
    const [impersonationUrl, setImpersonationUrl] = React.useState<string | null>(null);

    const [getImpersonateToken] = useGetImpersonationToken(token =>
        setImpersonationUrl(`${OrthlyBrowserConfig.practicePortalUrl}/lab/${orderId}?impersonateToken=${token}`),
    );

    return (
        <>
            <Tooltip title={'Impersonate'} arrow>
                <Button
                    onClick={() =>
                        getImpersonateToken({
                            variables: {
                                user_id: null,
                                organization_id: practiceId,
                                expires_in_seconds: null,
                            },
                        })
                    }
                    style={{ minWidth: 'unset', padding: 0, height: 'fit-content', marginRight: -8 }}
                    variant={'ghost'}
                >
                    <Icon icon={'IncognitoIcon'} />
                </Button>
            </Tooltip>
            <Dialog open={!!impersonationUrl} onClose={() => setImpersonationUrl(null)}>
                {impersonationUrl && (
                    <ImpersonatePreviewBox
                        impersonateLink={impersonationUrl}
                        onReset={() => setImpersonationUrl(null)}
                        // TODO: fix
                        userName={practiceId}
                    />
                )}
            </Dialog>
        </>
    );
};
