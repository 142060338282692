import type { Point } from '../Util';
import { RectLikeObject } from './RectLikeObject';
import _ from 'lodash';

export interface TextParams {
    text: string;
    color: string;
}

export class TextObject extends RectLikeObject {
    private lines: string[];

    constructor(
        private params: TextParams,
        firstCorner: Point,
        secondCorner: Point,
    ) {
        super();
        this.x = Math.min(firstCorner[0], secondCorner[0]);
        this.y = Math.min(firstCorner[1], secondCorner[1]);
        this.width = Math.abs(firstCorner[0] - secondCorner[0]);

        this.lines = TextObject.splitStringToLines(params.text);

        // Roughly 10px tall text + 6 pixels of spacing per line.
        this.height = Math.max(this.lines.length, 2) * 16;
    }

    // Given a string, returns a list of substrings, one per line.
    // Each line will have a capped maximum length of either maxLineLength or the length of the longest individual word,
    // whichever is longer.
    private static splitStringToLines(str: string, maxLineLength: number = 25): string[] {
        const words = str.split(' ');
        const longestWord = _.maxBy(words, word => word.length);
        const maxWordLength = Math.max(longestWord?.length ?? 0, maxLineLength);
        return words.reduce<string[]>((state, word) => {
            const lastIsland = _.last(state);
            const proposedJoinedString = lastIsland ? `${lastIsland} ${word}` : word;

            // Adding this word would put us over the limit, so we'll just create a new line.
            if (proposedJoinedString.length > maxWordLength) {
                return [...state, word];
            }

            // Adding this word won't put us over the limit, so we replace the island with our new candidate.
            return [...state.slice(0, -1), proposedJoinedString];
        }, []);
    }

    draw(ctx: CanvasRenderingContext2D, hitTestColor?: string) {
        // Save the context so that we can restore it after our draw is finished
        ctx.save();

        // Important: we translate the box to the new origin point, so that the scale applies from the x, y point (our element's origin).
        ctx.translate(this.x, this.y);

        // We assume a base of 16px text, in sans-serif font.
        // We will then compute the longest line, and derive a "scale factor", or how much we need to scale
        // our text to make it perfectly fit into the maximum width.
        // We apply the same scale factor to all lines so that no one line looks scrunched and smushed.
        ctx.font = '16px sans-serif';
        ctx.textBaseline = 'top';
        ctx.textAlign = 'left';
        ctx.fillStyle = hitTestColor ?? this.params.color;

        const longestLineWidth = _.maxBy(this.lines.map(l => ctx.measureText(l).width)) ?? this.width;
        // If the bounding box width is 50px and our longest line is 100px, we need a 0.5 scale factor.
        // If the bounding box width is 100px and our longest line is 50px, we need a 2.0 scale factor.
        const scaleFactor = this.width / longestLineWidth;

        // Perform the actual scale.
        ctx.scale(scaleFactor, scaleFactor);

        // Now we render each line, one at a time, adding some space between each line.
        let runningHeight = 0;
        for (const line of this.lines) {
            const metrics = ctx.measureText(line);
            const lineHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

            if (!hitTestColor) {
                ctx.fillText(line, 0, runningHeight);
            } else {
                ctx.fillRect(0, runningHeight, metrics.width, runningHeight + lineHeight + 6);
            }

            runningHeight += lineHeight + 6;
        }

        // Restore so that the next element is scaled and translated.
        ctx.restore();
    }

    handles() {
        return this.handlesForCorners(true);
    }
}
