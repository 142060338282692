import type { OrderAlertBannerVariant } from './OrderAlertBanner';
import { OrderAlertBanner } from './OrderAlertBanner';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import _ from 'lodash';
import React from 'react';

const VeneerOrderLinkedOrdersBannersLabSalesOrder_Fragment = graphql(`
    fragment VeneerOrderLinkedOrdersBannersLabSalesOrder_Fragment on LabSalesOrderDTO {
        previous_order {
            continued_order_id
            refabricated_order_id
            replaced_order_id
        }
        next_order_excluding_replaced {
            continued_by_order_ids
            refabricated_by_order_ids
            replaced_by_order_ids
        }
    }
`);

interface OrderLinkedOrderBannerProps {
    previousOrderId: string | null;
    nextOrderIds: string[] | null;
    openOrder: (orderId: string, e: React.MouseEvent) => void;
    variant: OrderAlertBannerVariant;
    copy: {
        previousAndNextTitle: string;
        previousOnlyTitle: string;
        nextOnlyTitle: string;
        previousButtonLabel: string;
        nextButtonLabel: string;
    };
}

const OrderLinkedOrderBanner: React.VFC<OrderLinkedOrderBannerProps> = ({
    previousOrderId,
    nextOrderIds,
    openOrder,
    variant,
    copy,
}) => {
    const hasPrevious = !!previousOrderId;
    const nextOrdersCount = nextOrderIds?.length ?? 0;
    const hasNext = !!nextOrdersCount;

    if (!hasPrevious && !hasNext) {
        return null;
    }

    const getTitle = () => {
        if (hasPrevious && hasNext) {
            return copy.previousAndNextTitle;
        }
        return hasPrevious ? copy.previousOnlyTitle : copy.nextOnlyTitle;
    };

    return (
        <OrderAlertBanner
            variant={variant}
            title={getTitle()}
            actions={_.compact([
                hasPrevious && {
                    label: copy.previousButtonLabel,
                    onClick: e => openOrder(previousOrderId, e),
                },
                ...(nextOrderIds ?? []).map((nextOrderId, idx) => ({
                    label: `${copy.nextButtonLabel}${nextOrdersCount > 1 ? ` (${idx + 1})` : ''}`,
                    onClick: (e: React.MouseEvent) => openOrder(nextOrderId, e),
                })),
            ])}
        />
    );
};

interface OrderLinkedOrdersBannersProps {
    salesOrder: FragmentType<typeof VeneerOrderLinkedOrdersBannersLabSalesOrder_Fragment>;
    openOrder: (orderId: string, e: React.MouseEvent) => void;
    hideReplaced?: boolean;
}

export const OrderLinkedOrdersBanners: React.VFC<OrderLinkedOrdersBannersProps> = ({
    salesOrder: salesOrderProp,
    openOrder,
    hideReplaced = false,
}) => {
    const salesOrder = getFragmentData(VeneerOrderLinkedOrdersBannersLabSalesOrder_Fragment, salesOrderProp);

    return (
        <>
            <OrderLinkedOrderBanner
                previousOrderId={salesOrder.previous_order.continued_order_id ?? null}
                nextOrderIds={salesOrder.next_order_excluding_replaced.continued_by_order_ids ?? null}
                openOrder={openOrder}
                variant={'primary'}
                copy={{
                    previousAndNextTitle: 'This case has a more recent order',
                    previousOnlyTitle: 'This case has a past order',
                    nextOnlyTitle: 'This case has a more recent order',
                    previousButtonLabel: 'View past order',
                    nextButtonLabel: 'View next order',
                }}
            />
            <OrderLinkedOrderBanner
                previousOrderId={salesOrder.previous_order.refabricated_order_id ?? null}
                nextOrderIds={salesOrder.next_order_excluding_replaced.refabricated_by_order_ids ?? null}
                openOrder={openOrder}
                variant={'alert'}
                copy={{
                    previousAndNextTitle: 'This is a refabrication order that has been refabricated',
                    previousOnlyTitle: 'This is a refabrication order',
                    nextOnlyTitle: 'This order has been refabricated',
                    previousButtonLabel: 'View original',
                    nextButtonLabel: 'View new',
                }}
            />
            {!hideReplaced && (
                <OrderLinkedOrderBanner
                    previousOrderId={salesOrder.previous_order.replaced_order_id ?? null}
                    nextOrderIds={salesOrder.next_order_excluding_replaced.replaced_by_order_ids ?? null}
                    openOrder={openOrder}
                    variant={'alert'}
                    copy={{
                        previousAndNextTitle: 'This is a resubmitted order that was canceled & resubmitted again',
                        previousOnlyTitle: 'This is a resubmitted order',
                        nextOnlyTitle: 'This order was canceled & resubmitted',
                        previousButtonLabel: 'View original',
                        nextButtonLabel: 'View new',
                    }}
                />
            )}
        </>
    );
};
