import {
    PresetStatusIndicator,
    isGuidedPreset,
    useGuidedWaxupTabs,
    usePresetTabClick,
    useShouldDisableTabs,
} from '../GuidedWaxup.util';
import {
    GUIDED_WAXUP_COMMON_STYLES,
    GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT,
    GUIDED_WAXUP_TOOLBAR_HEIGHT_DESKTOP,
    PRESET_SIDEBAR_WIDTH,
} from '../VisualConstants.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupSelector } from '../state/GuidedWaxupState';
import { GeneralReviewVisibilityControls } from './GeneralReviewVisibilityControls';
import { FlossPalette, Grid, Text, stylesFactory } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    presetTabContainer: {
        position: 'fixed',
        width: PRESET_SIDEBAR_WIDTH,
        top: GUIDED_WAXUP_TOOLBAR_HEIGHT_DESKTOP,
        overflow: 'auto',
        height: GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT,
        ...GUIDED_WAXUP_COMMON_STYLES,
    },
    presetTab: {
        padding: 30,
        cursor: 'pointer',
        ...GUIDED_WAXUP_COMMON_STYLES,
        borderRight: 'none',
        borderTop: 'none',
    },
    selectedPresetTab: {
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
    },
}));

export const PresetTabsSidebar: React.VFC = () => {
    const classes = useStyles();
    const { selectedTab, internalEvaluation, toggles } = useGuidedWaxupContext();
    const presets = useGuidedWaxupSelector(s => s.presets);
    const tabs = useGuidedWaxupTabs(presets);
    const guidedPreset = isGuidedPreset(selectedTab);
    const disableTabs = useShouldDisableTabs();
    const presetTabClick = usePresetTabClick();
    const hideTabs = internalEvaluation || tabs.length === 1;
    return (
        <Grid item className={classes.presetTabContainer}>
            {!hideTabs &&
                tabs.map(tab => (
                    <Grid
                        key={tab.value}
                        item
                        className={clsx(classes.presetTab, selectedTab === tab.value && classes.selectedPresetTab)}
                        onClick={() => presetTabClick(tab)}
                    >
                        <Grid container justifyContent={'space-between'}>
                            <Text
                                variant={'body2'}
                                medium
                                color={
                                    (disableTabs || presets[tab.value]?.status) && tab.value !== selectedTab
                                        ? 'LIGHT_GRAY'
                                        : 'BLACK'
                                }
                            >
                                {tab.label}
                            </Text>
                            <PresetStatusIndicator presetType={tab.value} />
                        </Grid>
                    </Grid>
                ))}
            {!guidedPreset && <GeneralReviewVisibilityControls toggles={toggles} />}
        </Grid>
    );
};
