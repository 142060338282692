import { InboxListBulkActions } from './InboxListBulkActions';
import { InboxListFilters } from './InboxListFilters.graphql';
import { InboxListSort } from './InboxListSort';
import { OrdersListFilters } from './OrdersListFilters';
import { OrdersListSort } from './OrdersListSort';
import { OrdersListToolbarSearch } from './OrdersListToolbarSearch';
import { ToolbarContainer } from '@orthly/ui';
import { FlossPalette, stylesFactory, LinearProgress } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    toolbarContainer: { padding: 0, alignItems: 'flex-start' },
    loader: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        opacity: 1,
        transition: theme.transitions.create('opacity', { duration: 250 }),
        zIndex: 2,
    },
    loaderHidden: { opacity: 0 },
    loaderBar: { backgroundColor: FlossPalette.STAR_GRASS },
}));

interface OrdersListToolbarProps {
    loading: boolean;
    mode: 'inbox' | 'orders';
}

export const OrdersListToolbar: React.FC<OrdersListToolbarProps> = props => {
    const { loading } = props;
    const classes = useStyles();
    return (
        <ToolbarContainer sticky className={classes.toolbarContainer}>
            <LinearProgress
                classes={{ bar: classes.loaderBar, root: clsx(classes.loader, !loading && classes.loaderHidden) }}
            />
            <OrdersListToolbarSearch mode={props.mode} />
            {props.mode === 'inbox' && <InboxListFilters />}
            <OrdersListFilters mode={props.mode} />
            {props.mode === 'inbox' ? <InboxListSort /> : <OrdersListSort />}
            {props.mode === 'inbox' && <InboxListBulkActions />}
        </ToolbarContainer>
    );
};
