import { QCColorLegendContainer } from './DesignViewerLiteButtonGroups/QCColorLegendContainer';
import { useDesignViewerButtonGroupsState } from './DesignViewerLiteButtonGroups/useDesignViewerLiteButtonGroupsState';
import { ColorToggleButton } from './GuidedWaxups/components/ColorToggleButton';
import { ComparisonToggleButton } from './GuidedWaxups/components/ComparisonToggleButton';
import { DesignViewLiteButton } from './GuidedWaxups/components/DesignViewerLiteButton';
import { MarginToggleButton } from './GuidedWaxups/components/MarginToggleButton';
import { OpposingArchToggleButton } from './GuidedWaxups/components/OpposingArchToggleButton';
import { TransparencyControls } from './GuidedWaxups/components/TransparencyControls';
import { TransparencyToggleMobile } from './GuidedWaxups/components/TransparencyToggleMobile';
import { useGuidedWaxupContext } from './GuidedWaxups/state/GuidedWaxupContext';
import { ModelViewControls } from './ModelViewControls';
import { HeatMapSelectionBar, HeatMapTypeToLabelMap } from '@orthly/dentin';
import { HeatMapType } from '@orthly/forceps';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { Box, ButtonGroup, QCHeatmapIcon, styled, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    width: '100%',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
        gap: '8px',
    },
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
        gap: '8px',
    },
}));

const MenuContainer: React.FC = ({ children }) => {
    const isMobileOrVerticalTablet = useScreenIsMobileOrVerticalTablet();

    return isMobileOrVerticalTablet ? <Box sx={{ alignSelf: 'stretch' }}>{children}</Box> : <>{children}</>;
};

export const DesignViewerLiteButtonGroups: React.VFC = () => {
    const isMobileOrTablet = useScreenIsMobileOrVerticalTablet();
    const {
        primaryAppearance,
        isComparisonModeAvailable,
        isImmediateDenture,
        isRemoveable,
        selectedTab,
        setBothAppearances,
    } = useGuidedWaxupContext();
    const {
        canHaveLayers,
        closeHeatmapMenu,
        closeTransparencyMenu,
        heatmapButtonClick,
        heatmapAnchorEl,
        isHeatmapView,
        resetHeatmapState,
        transparencyButtonClick,
        transparencyAnchorEl,
    } = useDesignViewerButtonGroupsState();

    return (
        <Container>
            <ButtonsContainer>
                <ModelViewControls />
                {/* We don't want to render the ButtonGroup unless it has content */}
                {(isComparisonModeAvailable ||
                    [
                        LabsGqlGuidedWaxupPresetType.MarginView,
                        LabsGqlGuidedWaxupPresetType.OcclusalAnatomy,
                        LabsGqlGuidedWaxupPresetType.GeneralView,
                    ].includes(selectedTab)) && (
                    <ButtonGroup>
                        {selectedTab === LabsGqlGuidedWaxupPresetType.MarginView && <ColorToggleButton />}
                        {selectedTab === LabsGqlGuidedWaxupPresetType.OcclusalAnatomy && <OpposingArchToggleButton />}
                        {selectedTab === LabsGqlGuidedWaxupPresetType.GeneralView && <ColorToggleButton />}
                        {selectedTab === LabsGqlGuidedWaxupPresetType.GeneralView && <MarginToggleButton />}
                        {selectedTab === LabsGqlGuidedWaxupPresetType.GeneralView && (
                            <TransparencyControls
                                appearanceSettings={primaryAppearance}
                                onAppearanceChange={setBothAppearances}
                                anchorEl={transparencyAnchorEl}
                                canHaveLayers={canHaveLayers}
                                onClick={transparencyButtonClick}
                                isMobile={isMobileOrTablet}
                                onClose={closeTransparencyMenu}
                            />
                        )}
                        {selectedTab === LabsGqlGuidedWaxupPresetType.GeneralView && !isImmediateDenture && (
                            <DesignViewLiteButton
                                icon={<QCHeatmapIcon />}
                                label={'Heatmap'}
                                onClick={heatmapButtonClick}
                                onClose={resetHeatmapState}
                                isActive={!!heatmapAnchorEl}
                                extraLabel={
                                    isHeatmapView
                                        ? HeatMapTypeToLabelMap[
                                              primaryAppearance.activeHeatMap || HeatMapType.Thickness
                                          ]
                                        : undefined
                                }
                            />
                        )}
                        {isComparisonModeAvailable && <ComparisonToggleButton />}
                    </ButtonGroup>
                )}
            </ButtonsContainer>
            {isMobileOrTablet && transparencyAnchorEl && (
                <TransparencyToggleMobile
                    appearanceSettings={primaryAppearance}
                    onAppearanceChange={setBothAppearances}
                    canHaveLayers={canHaveLayers}
                />
            )}
            {heatmapAnchorEl && (
                <MenuContainer>
                    <HeatMapSelectionBar
                        appearanceSettings={primaryAppearance}
                        onAppearanceChange={setBothAppearances}
                        showBasicHeatmaps
                        enableSculptReviewHeatmaps={false}
                        enableUndercutHeatmap={false}
                        enableTissuePressureHeatmap={false}
                        enableProximalHeatmap={!isRemoveable}
                        enableGuidedWaxupToolbar
                        anchorEl={heatmapAnchorEl}
                        onClose={closeHeatmapMenu}
                        dynamicHeatmaps
                    />
                </MenuContainer>
            )}
            <QCColorLegendContainer isHeatmapView={isHeatmapView} />
        </Container>
    );
};
