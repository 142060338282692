import { OrderDetailAccordionBlock } from '../OrderDetails';
import { OrderDetailBlock, OrderDetailFactRow } from '../OrderDetails/OrderDetailBlock';
import { TryInFeedbackDetailForOrder_Fragment, useTryInFeedbackForOrder } from './useTryInFeedbackForOrder.graphql';
import type { TryInFeedbackDetailForOrder_FragmentFragment } from '@orthly/graphql-inline-react';
import { getFragmentData } from '@orthly/graphql-inline-react';
import { OrderItemArch } from '@orthly/items';
import { Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface OrderDetailTryInFeedbackDetailRowProps {
    category: string;
    details: string;
}

const OrderDetailTryInFeedbackDetailRow: React.VFC<OrderDetailTryInFeedbackDetailRowProps> = ({
    category,
    details,
}) => {
    return (
        <OrderDetailFactRow
            typographyVariant={'body2'}
            title={category}
            value={details}
            flexBasisLeft={200}
            wrapperProps={{ alignItems: 'flex-start' }}
            valueStyle={{ whiteSpace: 'normal' }}
        />
    );
};

interface OrderDetailTryInFeedbackItemDetailsProps {
    details: TryInFeedbackDetailForOrder_FragmentFragment[];
}

const OrderDetailTryInFeedbackItemDetails: React.VFC<OrderDetailTryInFeedbackItemDetailsProps> = ({ details }) => {
    const archGroupedDetails = _.groupBy(details, detail => detail.arch);

    return (
        <>
            {[OrderItemArch.Dual, OrderItemArch.Upper, OrderItemArch.Lower].map(arch => {
                const archDetails = archGroupedDetails[arch];
                if (!archDetails?.length) {
                    return null;
                }

                const itemLabel = archDetails.find(detail => detail.item_display_name)?.item_display_name ?? 'Item';

                return (
                    <React.Fragment key={arch}>
                        <Text variant={'body2'} color={'GRAY'} medium style={{ marginTop: 4, marginBottom: 4 }}>
                            {itemLabel} {arch === OrderItemArch.Dual ? 'General' : `${arch} Arch`} Feedback
                        </Text>
                        {archDetails.map(detail => (
                            <OrderDetailTryInFeedbackDetailRow
                                key={detail.category}
                                category={detail.category}
                                details={detail.details}
                            />
                        ))}
                    </React.Fragment>
                );
            })}
        </>
    );
};

interface OrderDetailTryInFeedbackProps {
    orderId: string;
    isPreviousOrder: boolean;
    accordion?: boolean;
}

export const OrderDetailTryInFeedback: React.VFC<OrderDetailTryInFeedbackProps> = ({
    orderId,
    isPreviousOrder,
    accordion,
}) => {
    const tryInFeedback = useTryInFeedbackForOrder(orderId);

    if (!tryInFeedback) {
        return null;
    }

    const tryInFeedbackDetails = tryInFeedback.try_in_feedback_details.map(detail =>
        getFragmentData(TryInFeedbackDetailForOrder_Fragment, detail),
    );
    const itemGroupedDetails = _.groupBy(tryInFeedbackDetails, detail => detail.item_id);

    const title = isPreviousOrder ? 'Feedback from Previous Order' : 'Try-In Feedback on this Order';
    const OrderBlock = accordion ? OrderDetailAccordionBlock : OrderDetailBlock;

    return (
        <OrderBlock variant={'full'} title={title} updatedAt={tryInFeedback.updated_at}>
            {tryInFeedbackDetails.length ? (
                _.map(itemGroupedDetails, (details, itemId) => (
                    <OrderDetailTryInFeedbackItemDetails key={itemId} details={details} />
                ))
            ) : (
                <Text variant={'body2'} color={'GRAY'} medium>
                    Feedback Completed
                </Text>
            )}
        </OrderBlock>
    );
};
