import { RefabCircleIcon } from '@orthly/ui';
import { stylesFactory, Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    subSection: {
        marginBottom: '.5rem',
    },
    subSectionTitle: {
        fontSize: '1rem !important',
        fontWeight: 'bold',
    },
    item: {
        fontSize: '0.75rem !important',
    },
}));

/**
 * LabSlip section for displaying notes regarding refabrication
 * @param notesForRefabrication The note_for_refabrication property of an Order
 * @returns RefabNotes section
 */
export const RefabNotes: React.VFC<{ notesForRefabrication: string | null }> = ({ notesForRefabrication }) => {
    const classes = useStyles();

    if (notesForRefabrication === null) {
        return null;
    }
    return (
        <Grid container className={classes.subSection}>
            <Grid container spacing={1}>
                <Grid item>
                    <RefabCircleIcon />
                </Grid>
                <Grid item>
                    <Text variant={'h5'} className={classes.subSectionTitle}>
                        Refab Notes
                    </Text>
                </Grid>
            </Grid>
            <Grid container>
                <Text variant={'body1'} className={classes.item}>
                    {notesForRefabrication}
                </Text>
            </Grid>
        </Grid>
    );
};
