/* eslint-disable max-lines-per-function */
import Abutment from '../../../assets/images/tooth-menu/ToothMenuAbutment.png';
import Crown from '../../../assets/images/tooth-menu/ToothMenuCrown.png';
import Pontic from '../../../assets/images/tooth-menu/ToothMenuPontic.png';
import Wing from '../../../assets/images/tooth-menu/ToothMenuWing.png';
import { DandyMouthDisplay } from './DandyMouthDisplay';
import type { ICartItemSingleToothUnit, ToothNumber } from '@orthly/items';
import { LabOrderItemSKUType } from '@orthly/items';
import { TrashCanIcon } from '@orthly/ui';
import type { PopoverOrigin } from '@orthly/ui-primitives';
import {
    FlossPalette,
    Text,
    stylesFactory,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Popover,
    Tooltip,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    noMaxWidth: {
        maxWidth: 'none',
    },
    listItem: {
        gap: 8,
    },
    listItemIcon: {
        minWidth: 'auto',
    },
    listItemText: {
        flexGrow: 0,
    },
    divider: {
        margin: '0 16px',
    },
}));

interface DandyMouthToothTypeSelectorProps<UnitType extends string> {
    unit_types: UnitType[];
    selected: ICartItemSingleToothUnit<UnitType>[];
    onChange: (teeth: ICartItemSingleToothUnit<UnitType>[]) => void;
    verticalLayout?: boolean;
    animate?: boolean;
    disabled?: ToothNumber[];
    archLabels?: boolean;
    itemSku?: LabOrderItemSKUType;
}

interface ToothTypeListItemProps {
    unitType: string;
    isImplantBridge?: boolean;
    updateSelected: () => void;
}

function getToothTypeImageURL(unit_type: string): string {
    switch (unit_type) {
        case 'Abutment':
            return Abutment;
        case 'Crown':
            return Crown;
        case 'CrownPontic':
            return Pontic;
        case 'Wing':
            return Wing;
        default:
            return Crown;
    }
}

function getToothTypeDisplayName(unit_type: string): string {
    switch (unit_type) {
        case 'Wing':
            return 'Wing (Maryland)';
        case 'CrownPontic':
            return 'Pontic';
        default:
            return _.startCase(unit_type);
    }
}

const ImplantBridgeAbutmentListItem: React.VFC<Pick<ToothTypeListItemProps, 'unitType' | 'updateSelected'>> = ({
    unitType,
    updateSelected,
}) => {
    const classes = useStyles();

    return (
        <ListItem key={unitType} button onClick={updateSelected} className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
                <img src={getToothTypeImageURL(unitType)} alt={unitType} height={24} width={24} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>{getToothTypeDisplayName(unitType)}</ListItemText>
            <ListItemText className={classes.listItemText}> + </ListItemText>
            <ListItemIcon className={classes.listItemIcon}>
                <img src={getToothTypeImageURL('Crown')} alt={'Crown'} height={24} width={24} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>{getToothTypeDisplayName('Crown')}</ListItemText>
        </ListItem>
    );
};

const StandardToothTypeListItem: React.VFC<ToothTypeListItemProps> = ({
    unitType,
    updateSelected,
    isImplantBridge,
}) => {
    const classes = useStyles();

    return (
        <ListItem key={unitType} button onClick={updateSelected} className={classes.listItem}>
            <ListItemIcon className={classes.listItemIcon}>
                <img src={getToothTypeImageURL(unitType)} alt={unitType} height={24} width={24} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
                {isImplantBridge && unitType === 'Crown'
                    ? 'Crown only (over natural prep)'
                    : getToothTypeDisplayName(unitType)}
            </ListItemText>
        </ListItem>
    );
};

const DeleteToothTypeListItem: React.VFC<{ onClick: () => void }> = ({ onClick }) => {
    const classes = useStyles();
    return (
        <>
            <ListItem button onClick={onClick} className={classes.listItem}>
                <ListItemIcon className={classes.listItemIcon}>
                    <TrashCanIcon height={24} width={24} />
                </ListItemIcon>
                <ListItemText className={classes.listItemText}>Delete</ListItemText>
            </ListItem>
            <Divider className={classes.divider} />
        </>
    );
};

const ImplantBridgeToothTypeListItem: React.VFC<Pick<ToothTypeListItemProps, 'unitType' | 'updateSelected'>> = ({
    updateSelected,
    unitType,
}) => {
    const classes = useStyles();
    return (
        <>
            {unitType === 'Crown' ? <Divider className={classes.divider} /> : null}
            <Tooltip
                title={
                    unitType === 'Crown' ? (
                        <Text variant={'body2'} color={'WHITE'}>
                            For bridging between an implant and a natural prepared tooth
                        </Text>
                    ) : (
                        ''
                    )
                }
                arrow
                placement={'right'}
                classes={{ tooltip: classes.noMaxWidth }}
            >
                {unitType === 'Abutment' ? (
                    <ImplantBridgeAbutmentListItem unitType={unitType} updateSelected={updateSelected} />
                ) : (
                    <StandardToothTypeListItem
                        unitType={unitType}
                        isImplantBridge={true}
                        updateSelected={updateSelected}
                    />
                )}
            </Tooltip>
        </>
    );
};

export const DandyMouthToothTypeSelector = <UnitType extends string>({
    unit_types,
    selected,
    onChange,
    verticalLayout,
    disabled,
    animate = true,
    archLabels,
    itemSku,
}: DandyMouthToothTypeSelectorProps<UnitType>): React.ReactElement => {
    const [pendingTooth, setPendingTooth] = React.useState<ToothNumber>();
    const [anchorEl, setAnchorEl] = React.useState<SVGGElement | null>(null);

    const items = React.useMemo(
        () =>
            _.compact([
                ...selected.map(
                    ({ unn, unit_type }) =>
                        unn !== pendingTooth && {
                            number: unn,
                            highlighted: true,
                            activeColor: FlossPalette.GREEN,
                            // while crowns are not implants, this makes it visually for the bridge select
                            isImplant: unit_type === 'Abutment' || unit_type === 'Crown',
                        },
                ),
                ...(disabled ?? []).map(tooth => ({
                    number: tooth,
                    highlighted: true,
                    activeColor: FlossPalette.DARK_TAN,
                    disableClick: true,
                })),
                pendingTooth
                    ? {
                          number: pendingTooth,
                          highlighted: false,
                          activeColor: FlossPalette.GREEN,
                      }
                    : undefined,
            ]),
        [selected, pendingTooth, disabled],
    );

    const onToothClick = React.useCallback(
        (tooth: ToothNumber, event: React.MouseEvent<SVGGElement>) => {
            setPendingTooth(tooth);
            setAnchorEl(event.currentTarget);
        },
        [setPendingTooth, setAnchorEl],
    );

    const updateSelected = React.useCallback(
        (unit_type?: UnitType) => {
            if (pendingTooth) {
                const unChangedTeeth = [...selected.filter(({ unn }) => unn !== pendingTooth)];
                onChange(unit_type ? [...unChangedTeeth, { unit_type, unn: pendingTooth }] : unChangedTeeth);
            }
            setAnchorEl(null);
            setPendingTooth(undefined);
        },
        [selected, pendingTooth, onChange, setPendingTooth, setAnchorEl],
    );

    const popoverOrigin = React.useMemo<{ anchorOrigin?: PopoverOrigin; transformOrigin?: PopoverOrigin }>(() => {
        if (!pendingTooth) {
            return {};
        }
        if (pendingTooth <= 8) {
            return {
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                transformOrigin: { vertical: 'top', horizontal: 'left' },
            };
        }
        if (pendingTooth <= 16) {
            return {
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                transformOrigin: { vertical: 'top', horizontal: 'right' },
            };
        }
        if (pendingTooth <= 24) {
            return {
                anchorOrigin: { vertical: 'top', horizontal: 'left' },
                transformOrigin: { vertical: 'bottom', horizontal: 'right' },
            };
        }
        return {
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            transformOrigin: { vertical: 'bottom', horizontal: 'left' },
        };
    }, [pendingTooth]);

    const isImplantBridge = itemSku === LabOrderItemSKUType.ImplantBridge;

    return (
        <>
            <DandyMouthDisplay
                items={items}
                bridges={[]}
                fullMouth={{
                    upperVisible: true,
                    upperHighlighted: true,
                    upperActiveColor: FlossPalette.DARK_TAN,
                    lowerVisible: true,
                    lowerHighlighted: true,
                    lowerActiveColor: FlossPalette.DARK_TAN,
                }}
                animate={animate}
                verticalLayout={!!verticalLayout}
                onToothClick={onToothClick}
                archLabels={archLabels}
            />
            <Popover
                {...popoverOrigin}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(null);
                    setPendingTooth(undefined);
                }}
            >
                <Grid container>
                    <List>
                        {selected.find(t => t.unn === pendingTooth) && (
                            <DeleteToothTypeListItem onClick={() => updateSelected()} />
                        )}
                        {unit_types.map(unit_type =>
                            isImplantBridge ? (
                                <ImplantBridgeToothTypeListItem
                                    key={unit_type}
                                    unitType={unit_type}
                                    updateSelected={() => updateSelected(unit_type)}
                                />
                            ) : (
                                <StandardToothTypeListItem
                                    key={unit_type}
                                    unitType={unit_type}
                                    updateSelected={() => updateSelected(unit_type)}
                                />
                            ),
                        )}
                    </List>
                </Grid>
            </Popover>
        </>
    );
};
