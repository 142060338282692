import type { LabsGqlScanFileBaseFragment, LabsGqlScanFileForOrderFragment } from '@orthly/graphql-operations';
import { useSchedule3oxzConvertMutation } from '@orthly/graphql-react';
import { LoadBlocker, apolloErrorMessage } from '@orthly/ui';
import { FlossPalette, Button, IconButton, Tooltip, Icon } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

interface Schedule3OxzToStlProps {
    scan: LabsGqlScanFileForOrderFragment | LabsGqlScanFileBaseFragment;
    showIfStlExists?: boolean;
    useIconButton?: boolean;
}

export const Schedule3OxzToStl: React.FC<Schedule3OxzToStlProps> = props => {
    const { scan } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [submitMtn, { loading }] = useSchedule3oxzConvertMutation({ variables: { scanId: scan.id } });
    async function onSubmit() {
        const res = await submitMtn();
        const firstError = res.errors?.[0];
        if (firstError) {
            enqueueSnackbar(apolloErrorMessage(firstError), { variant: 'error' });
        } else {
            enqueueSnackbar(`3oxz conversion scheduled, check back later... (and pray)`, { variant: 'success' });
        }
    }
    if (scan.stl_url && !props.showIfStlExists) {
        return null;
    }
    return (
        <LoadBlocker blocking={loading} ContainerProps={{ style: props.useIconButton ? { width: 'auto' } : {} }}>
            {props.useIconButton ? (
                <IconButton onClick={() => onSubmit().catch(console.error)}>
                    <Tooltip title={'Run STL Convert'}>
                        <Icon icon={'QueuePlayNext'} />
                    </Tooltip>
                </IconButton>
            ) : (
                <Button
                    fullWidth
                    variant={'contained'}
                    style={{ color: FlossPalette.WHITE }}
                    onClick={() => {
                        onSubmit().catch(console.error);
                    }}
                >
                    Run STL Convert
                </Button>
            )}
        </LoadBlocker>
    );
};
