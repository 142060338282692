import { useOpenOrderDetailOps } from '../../utils/router/useOpenOrderDetail';
import type {
    LabsGqlAssignmentCandidateOrderFragment,
    LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment,
    LabsGqlDesignStaffWithUserFragment,
    LabsGqlDesignStaffDetailedStatusAssignmentFragment,
} from '@orthly/graphql-operations';
import { getWorkflowTaskName } from '@orthly/graphql-operations';
import { useDesignStaffDebugQuery } from '@orthly/graphql-react';
import { LabsGqlProductLine } from '@orthly/graphql-schema';
import { DesignPrepCapabilities, InternalDesignCapabilities, OrderDesignCapabilities } from '@orthly/shared-types';
import { ActionCard } from '@orthly/ui';
import { FlossPalette, Collapse, Link, Icon, Text, type TextProps } from '@orthly/ui-primitives';
import { DesignerStatusIndicator } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const DarkGray: React.FC<Omit<TextProps, 'variant' | 'color'>> = props => (
    <Text variant={'body2'} color={'DARK_GRAY'} {...props}>
        {props.children}
    </Text>
);

const Black: React.FC<Omit<TextProps, 'variant' | 'color'>> = props => (
    <Text variant={'body2'} color={'BLACK'} bold {...props}>
        {props.children}
    </Text>
);

/** Renders a clickable link to an order. */
const OrderLink: React.VFC<{ id: string }> = ({ id }) => {
    const openOrder = useOpenOrderDetailOps({ absolute: true });
    return (
        <Link
            component={'button'}
            onClick={e => openOrder(id, e)}
            onAuxClick={e => openOrder(id, e)}
            style={{ color: FlossPalette.HIGHLIGHT_BLUE, fontWeight: 600 }}
        >
            {`${id}`}
        </Link>
    );
};

type Order = Pick<LabsGqlAssignmentCandidateOrderFragment, 'id' | 'priority' | 'productLine' | 'taskType'> & {
    autoAssigned?: boolean;
};

/** Renders the elements in a candidate order row: Link to the order, product line, task type, and priority */
const OrderRow: React.VFC<{ order: Order; includeAssignmentType: boolean }> = ({ order, includeAssignmentType }) => (
    <>
        <OrderLink id={order.id} />
        <DarkGray>{order.productLine}</DarkGray>
        <DarkGray>{order.taskType}</DarkGray>
        <DarkGray>{order.priority}</DarkGray>
        {includeAssignmentType && (
            // eslint-disable-next-line no-nested-ternary
            <DarkGray>{order.autoAssigned === undefined ? '-' : order.autoAssigned ? 'Automatic' : 'Manual'}</DarkGray>
        )}
    </>
);

const OrdersTable: React.VFC<{ orders: Order[] }> = ({ orders }) => {
    const includeAssignmentType = orders.some(o => o.autoAssigned !== undefined);
    const numColumns = includeAssignmentType ? 5 : 4;
    return (
        <div
            style={{
                display: 'grid',
                gridTemplateColumns: `repeat(${numColumns}, auto)`,
                justifyItems: 'left',
                justifyContent: 'start',
                columnGap: 32,
                margin: `8px 16px`,
            }}
        >
            {orders.length ? (
                <>
                    <DarkGray medium>Order</DarkGray>
                    <DarkGray medium>Product line</DarkGray>
                    <DarkGray medium>Task type</DarkGray>
                    <DarkGray medium>Priority</DarkGray>
                    {includeAssignmentType && <DarkGray medium>Assignment type</DarkGray>}
                    {orders.map(order => {
                        return <OrderRow key={order.id} order={order} includeAssignmentType={includeAssignmentType} />;
                    })}
                </>
            ) : (
                <DarkGray>(none)</DarkGray>
            )}
        </div>
    );
};

const CAPABILITY_DEFINITIONS = {
    DesignPrep: DesignPrepCapabilities,
    InternalDesign: InternalDesignCapabilities,
    DesignReview: OrderDesignCapabilities,
};

function getLevelDescription(
    taskType: 'DesignPrep' | 'InternalDesign' | 'DesignReview',
    capabilityType: string,
    level: string,
) {
    const defs: Record<string, Record<string, { name: string }>> = CAPABILITY_DEFINITIONS[taskType];
    return defs[capabilityType]?.[level];
}

/** Renders the sections with the designer's capabilities. */
const CapabilitiesSections: React.VFC<{
    taskCapabilities: LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment;
}> = ({ taskCapabilities }) => {
    const capabilities = (['DesignPrep', 'InternalDesign', 'DesignReview'] as const).map(taskType => {
        return {
            taskType,
            capabilities: _.compact(
                Object.entries(taskCapabilities[taskType]).map(([capabilityType, level]) => {
                    return level && getLevelDescription(taskType, capabilityType, level);
                }),
            ),
        };
    });

    return (
        <>
            {capabilities.map(capabilities => {
                const taskName = getWorkflowTaskName(capabilities.taskType);
                return (
                    <>
                        <Black>{`${taskName} capabilities`}</Black>
                        <DarkGray style={{ margin: `8px 16px` }}>
                            {capabilities.capabilities.length
                                ? capabilities.capabilities.map(c => c.name).join(', ')
                                : `(none)`}
                        </DarkGray>
                    </>
                );
            })}
        </>
    );
};

function assignmentToOrder(
    assignment: LabsGqlDesignStaffDetailedStatusAssignmentFragment,
    isAutoAssigned: boolean,
): Order {
    return {
        id: assignment.order_id,
        productLine: assignment.product_line ?? LabsGqlProductLine.Other,
        taskType: assignment.task_type,
        priority: assignment.priority,
        autoAssigned: isAutoAssigned,
    };
}

export const DesignerEntryContent: React.VFC<{
    isOpen: boolean;
    designStaff: LabsGqlDesignStaffWithUserFragment['designStaff'];
}> = ({ isOpen, designStaff }) => {
    const {
        data: packedData,
        error,
        loading,
    } = useDesignStaffDebugQuery({
        skip: !isOpen,
        variables: {
            user_id: designStaff.id,
        },
    });
    const data = packedData?.designStaffDetailedStatus;
    const assignments = data
        ? [
              ...data.auto_assignments.map(a => assignmentToOrder(a, true)),
              ...data.manual_assignments.map(a => assignmentToOrder(a, false)),
          ]
        : [];

    return (
        <>
            {error && <ActionCard title={`Failed to load order list.`} subtitle={String(error)} variant={'alert'} />}
            {loading && <p>Loading ...</p>}
            {data && (
                <>
                    {data.task_capabilities && <CapabilitiesSections taskCapabilities={data.task_capabilities} />}

                    <Black>Assignable task types</Black>
                    <DarkGray style={{ margin: `8px 16px` }}>{designStaff.assignable_task_types.join(', ')}</DarkGray>

                    <Black>Orders currently assigned</Black>
                    <OrdersTable orders={assignments} />
                    <Black>Assignment candidate orders</Black>
                    <OrdersTable orders={data.candidate_orders} />
                </>
            )}
        </>
    );
};

export const AutoAssignmentDesignerEntry: React.FC<{
    designStaffWithUser: LabsGqlDesignStaffWithUserFragment;
}> = ({ designStaffWithUser }) => {
    const [isOpen, setOpen] = React.useState(false);

    return (
        <div style={{ padding: 10 }}>
            <Text
                variant={'body2'}
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                onClick={() => setOpen(!isOpen)}
            >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <DesignerStatusIndicator status={designStaffWithUser.designStaff.status} />
                    <span style={{ paddingBottom: 2 }}>
                        {[designStaffWithUser.first_name, designStaffWithUser.last_name]
                            .map(w => w.trim())
                            .filter(w => !!w)
                            .join(' ')}{' '}
                        ({designStaffWithUser.email})
                    </span>
                </span>
                <Icon icon={isOpen ? 'KeyboardArrowUpIcon' : 'KeyboardArrowDownIcon'} />
            </Text>
            <Collapse in={isOpen}>
                <div style={{ margin: `8px 16px` }}>
                    <DesignerEntryContent isOpen={isOpen} designStaff={designStaffWithUser.designStaff} />
                </div>
            </Collapse>
        </div>
    );
};
