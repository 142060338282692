import type { LabsGqlAttachTreatmentPlanRequestMutationVariables } from '@orthly/graphql-operations';
import { useAttachTreatmentPlanRequestMutation } from '@orthly/graphql-react';
import { LabsGqlTreatmentPlanProvider } from '@orthly/graphql-schema';
import { useChangeSubmissionFn, RootActionDialog, QuickForm } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

interface MarkAlignerPlanRequestedProps {
    orderId: string;
    refetch: () => Promise<any>;
    is_softsmile: boolean;
}

export const MarkAlignerPlanRequested: React.FC<MarkAlignerPlanRequestedProps> = ({
    orderId,
    refetch,
    is_softsmile,
}) => {
    type Vars = LabsGqlAttachTreatmentPlanRequestMutationVariables['data'];
    const [submitMtn] = useAttachTreatmentPlanRequestMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        successMessage: () => [`Aligner plan request submitted`, {}],
        onSuccess: async () => {
            await refetch();
        },
    });
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Record Treatment Plan Request'}
            buttonText={'Submit to planner'}
            content={
                <QuickForm<Omit<Vars, 'order_id'>>
                    fields={{
                        provider_case_id: { type: 'text' },
                        expected_completion_date: { type: 'date', fieldProps: { disablePast: true } },
                        provider: {
                            helperText: is_softsmile
                                ? 'This is a SoftSmile case so must be designed internally'
                                : undefined,
                            type: 'select',
                            options: Object.values(LabsGqlTreatmentPlanProvider)
                                .filter(value => !is_softsmile || value === LabsGqlTreatmentPlanProvider.InternalDesign)
                                .map(value => ({
                                    value,
                                    label: _.startCase(_.toLower(value)),
                                })),
                        },
                    }}
                    initialValues={is_softsmile ? { provider: LabsGqlTreatmentPlanProvider.InternalDesign } : {}}
                    onSubmit={async result => {
                        await submit({ ...result, order_id: orderId });
                    }}
                />
            }
        />
    );
};
