import type { AutomationBuilderState } from './AutomationBuilder.types';
import React from 'react';
import type { ReactReduxContextValue, TypedUseSelectorHook } from 'react-redux';
import { createDispatchHook, createSelectorHook } from 'react-redux';

export const AutomationBuilderReduxContext = React.createContext<ReactReduxContextValue>(
    {} as ReactReduxContextValue<any, any>,
);

export const useAutomationBuilderDispatch = createDispatchHook(AutomationBuilderReduxContext);

export const useAutomationBuilderSelector: TypedUseSelectorHook<AutomationBuilderState> =
    createSelectorHook(AutomationBuilderReduxContext);
