import type { ModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import { createJawItem, createRestorativeItem } from './Adapter.utils';
import type { IPartialSceneAppearanceManager, PartialSceneAppearance } from './SceneAppearanceManager.types';
import { ToothUtils, type ToothNumber } from '@orthly/items';
import { compact } from 'lodash';
import React from 'react';

/**
 * These hooks adapt Finishing App-specific types to other general types. They should be used only for interfacing with
 * existing components, e.g. the cross section tool.
 */

// Creates a `ModelAppearance` representing the scene state
export function useModelAppearance(
    editToothNumber: ToothNumber,
    sceneManager: IPartialSceneAppearanceManager,
    sceneAppearance: PartialSceneAppearance,
): ModelAppearance {
    return React.useMemo(() => {
        const restorativeItems = compact(
            Array.from(sceneManager.getRestorativeMeshes().values(), el => {
                return createRestorativeItem(el, { visible: true });
            }),
        );

        const scanMeshes = sceneManager.getScanMeshes();
        const isUpper = ToothUtils.toothIsUpper(editToothNumber);

        const prepScanMesh = isUpper ? scanMeshes?.upperJaw : scanMeshes?.lowerJaw;
        const antagonistScanMesh = isUpper ? scanMeshes?.lowerJaw : scanMeshes?.upperJaw;

        const prepScanItem = prepScanMesh && createJawItem(prepScanMesh.geometry, { visible: true });
        const antagonistScanItem =
            antagonistScanMesh &&
            createJawItem(antagonistScanMesh.geometry, { visible: sceneAppearance.antagonistScansVisible });

        const preprepScanItems = compact([scanMeshes?.prePrepLowerJaw, scanMeshes?.prePrepUpperJaw]).map(el => {
            return createJawItem(el.geometry, { visible: sceneAppearance.prePrepScansVisible });
        });

        return {
            restoratives: restorativeItems,
            pastRestoratives: [],
            upperJaw: compact([isUpper ? prepScanItem : antagonistScanItem]),
            lowerJaw: compact([isUpper ? antagonistScanItem : prepScanItem]),
            scans: preprepScanItems,
            preExtractionScans: [],
            printedModels: [],
            collisions: [],
            anatomy: [],
            curtains: {},
            showAnatomyLayers: false,
            showMarginLines: false,
            showDoctorMarginLines: false,
            showDoctorToothMarkings: false,
            showCollisions: false,
            showCurtainsCollisions: false,
            activeHeatMap: null,
            showCurtainsHeatmap: false,
        };
    }, [editToothNumber, sceneManager, sceneAppearance]);
}
