import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import type { CurrentDoctorPreferencesQueryHookResult } from '@orthly/graphql-react';
import { useCurrentDoctorPreferencesQuery } from '@orthly/graphql-react';

export function useDoctorsForPartner(
    partner_id: string,
    include_deleted: boolean,
    options?: Partial<Parameters<typeof useCurrentDoctorPreferencesQuery>[0]>,
): CurrentDoctorPreferencesQueryHookResult & {
    preferences: LabsGqlDoctorPreferencesFragment[];
} {
    const query = useCurrentDoctorPreferencesQuery({
        variables: { partner_id, include_deleted },
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        ...options,
    });
    const preferences = query.data?.preferences ?? [];
    return { ...query, preferences };
}
