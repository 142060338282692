import { useUsersAction } from '../../state/Users.actions';
import type { FetchResult } from '@apollo/client';
import type { LabsGqlCreateUserMutationVariables, LabsGqlCreateUserMutation } from '@orthly/graphql-operations';
import { useCreateUserMutation } from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import { useHistory } from 'react-router-dom';

type CreateUserVars = LabsGqlCreateUserMutationVariables['data'];
type CreateUserResult = FetchResult<LabsGqlCreateUserMutation>;

export const useCreateUser = () => {
    const onUserSaved = useUsersAction('USER_SAVED');
    const history = useHistory();
    const [submitMtn] = useCreateUserMutation();
    const mtnSubmitter = (data: CreateUserVars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<CreateUserResult, [CreateUserVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Successfully created user!', {}],
        onSuccess: result => {
            const user = result.data?.createUser;
            if (user) {
                onUserSaved({ user });
                history.push(`/users/${user.id}`);
            }
        },
    });

    return { submit, submitting };
};
