import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { isItemEditorV2CrownMaterialItem } from './ItemEditorV2CrownMaterialField';
import { CartItemV2Utils, ItemMaterialFieldV2Utils, LabOrderItemSKUType } from '@orthly/items';
import type { ICartItemV2DTO } from '@orthly/items';
import { SimpleSelect } from '@orthly/ui';
import React from 'react';

// some items with material fields now have custom components, so this is only used for a subset
// of items that have a material field but no custom component

export const isItemEditorV2MaterialItem = (item: ICartItemV2DTO): boolean => {
    // includes implants as well, since their abutment material field is handled in the implants section
    if (isItemEditorV2CrownMaterialItem(item)) {
        return false;
    }
    // For bleaching trays and sportsguards the arch field will also set the material.
    // This is done for legacy compatibility reasons.
    if (
        CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Removeable) &&
        (item.unit.unit_type === 'Bleaching Tray' || item.unit.unit_type === 'Sportsguard')
    ) {
        return false;
    }
    return ItemMaterialFieldV2Utils.getMaterialFieldsForItemExcludingDataFields(item).length > 0;
};

export const ItemEditorV2MaterialField: React.VFC<ItemEditorV2BaseProps> = ({ item, updateItem, showError }) => {
    // implants are the only items with multiple material fields,
    // so we just take the first since there should only be exactly one
    const materialField = ItemMaterialFieldV2Utils.getMaterialFieldsForItem(item)[0];

    if (!materialField) {
        return null;
    }

    const value = ItemMaterialFieldV2Utils.getSingleMaterial(item);

    return (
        <SimpleSelect
            label={materialField.label}
            helperText={materialField.helper_text}
            value={value}
            onChange={val => {
                updateItem({ name: 'material', payload: val });
            }}
            options={materialField.options}
            errorText={showError && !value ? 'This field is required' : undefined}
        />
    );
};
