import type { IBaseItemData } from '../../types';
import { getHeaderForLabSlipItem } from '../../utils/shared.utils';
import { BaseItem } from './BaseItem';
import type { IAlignerItem } from '@orthly/items';
import { ALIGNER_RETAINER_QUANTITY_METAFIELD_ID } from '@orthly/items';
import React from 'react';

/**
 * Renders a "Aligner" order item to display on
 * the Printable Slip
 * @param props The order item to render
 * @returns Rendered order item details for the order "Aligner" item
 */
export const AlignerItem: React.VFC<{ item: IAlignerItem }> = ({ item }) => {
    return <BaseItem itemData={mapAlignerItemToPrintableSlipData(item)} />;
};

/**
 * Pull data needed for printable slip display into a standard data structure
 * @param item IAlignerItem order item to display
 * @returns Structured data for Aligner item display
 */
function mapAlignerItemToPrintableSlipData(item: IAlignerItem): IBaseItemData {
    const itemFields: IBaseItemData['itemFields'] = [{ key: 'Arch', value: item.unit.arch }];

    if (item.unit.unit_type !== 'Aligner Retainer') {
        return { headerLeft: getHeaderForLabSlipItem(item), itemFields: itemFields };
    }

    const quantityValue =
        item.preference_fields.find(pref => pref.field_id === ALIGNER_RETAINER_QUANTITY_METAFIELD_ID)?.value ?? '1';
    return {
        headerLeft: getHeaderForLabSlipItem(item),
        itemFields: [
            ...itemFields,
            { key: 'Material', value: item.unit.material },
            { key: 'Quantity', value: `${quantityValue}` },
        ],
    };
}
