import type { ActionFormField } from './AutomationBuilder.types';
import { DateUtils } from '@orthly/shared-types';
import moment from 'moment';

type ActionFieldOfType<T extends ActionFormField['__typename']> = Extract<ActionFormField, { __typename: T }>;

function getDateFieldErrorMessage(field: ActionFieldOfType<'AutomationFieldDate'>): string | undefined {
    if (typeof field.value !== 'string') {
        return `${field.label} is required`;
    }
    if (!moment(field.value).isValid()) {
        return 'Must be a valid date';
    }
    if (field.minDate && moment(field.value).isBefore(field.minDate, 'day')) {
        return `Must be after minimum date of ${DateUtils.format(field.minDate, 'MM/DD/YY')}`;
    }
    if (field.maxDate && moment(field.value).isAfter(field.maxDate, 'day')) {
        return `Must be before max date of ${DateUtils.format(field.maxDate, 'MM/DD/YY')}`;
    }
    return undefined;
}

function getNumberFieldErrorMessage(field: ActionFieldOfType<'AutomationFieldNumber'>): string | undefined {
    if (typeof field.value !== 'number') {
        return `Must be valid number`;
    }
    if (typeof field.min === 'number' && field.value < field.min) {
        return `Must be greater than minimum value: ${field.min}`;
    }
    if (typeof field.max === 'number' && field.value > field.max) {
        return `Must be less than maximum value: ${field.max}`;
    }
    return undefined;
}

function selectFieldValueIsValid(
    field: ActionFieldOfType<'AutomationFieldMultiSelect' | 'AutomationFieldSelect'>,
    selectedValue: string,
): boolean {
    return field.options.some(o => o.value === selectedValue);
}

function getMultiSelectErrorMessage(field: ActionFieldOfType<'AutomationFieldMultiSelect'>): string | undefined {
    if (!Array.isArray(field.value)) {
        return 'Must be an array';
    }
    if (!field.value.every(v => typeof v === 'string')) {
        return 'Must be an array of strings';
    }
    if (typeof field.minSelections === 'number' && field.value.length < field.minSelections) {
        return `Must select at least ${field.minSelections} options`;
    }
    if (typeof field.maxSelections === 'number' && field.value.length > field.maxSelections) {
        return `Cannot select more than ${field.maxSelections} options`;
    }
    const invalidOpts = field.value.filter(v => !selectFieldValueIsValid(field, v));
    return invalidOpts.length > 0 ? `Invalid options selected: ${invalidOpts.join(', ')}` : undefined;
}

function getSelectFieldErrorMessage(field: ActionFieldOfType<'AutomationFieldSelect'>): string | undefined {
    if (typeof field.value !== 'string') {
        return 'Please select a value';
    }
    return !selectFieldValueIsValid(field, field.value) ? `Invalid option: ${field.value}` : undefined;
}

function getTextFieldErrorMessage(field: ActionFieldOfType<'AutomationFieldText'>): string | undefined {
    if (typeof field.value !== 'string') {
        return `Must be valid string`;
    }
    if (field.minimum_characters && field.value.length < field.minimum_characters) {
        return `Must have length of ${field.minimum_characters} or more`;
    }
    return undefined;
}

export function addConfigFieldError(field: ActionFormField): ActionFormField {
    const addError = (errorMessage?: string): ActionFormField => ({ ...field, error: errorMessage });
    if (field.value === undefined || field.value === null) {
        return { ...field, error: field.optional ? undefined : `${field.label} is required` };
    }
    switch (field.__typename) {
        case 'AutomationFieldBoolean':
            return addError(typeof field.value !== 'boolean' ? 'Must be a boolean' : undefined);
        case 'AutomationFieldCopy':
            return field;
        case 'AutomationFieldDate':
            return addError(getDateFieldErrorMessage(field));
        case 'AutomationFieldNumber':
            return addError(getNumberFieldErrorMessage(field));
        case 'AutomationFieldSelect':
            return addError(getSelectFieldErrorMessage(field));
        case 'AutomationFieldMultiSelect':
            return addError(getMultiSelectErrorMessage(field));
        case 'AutomationFieldText':
            return addError(getTextFieldErrorMessage(field));
    }
}
