import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import { LabsGqlLabPriceRuleType } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';

export const AdditionalPonticsInRemovableForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.AdditionalPonticsInRemovable>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.AdditionalPonticsInRemovable>({})}
            initialValues={
                price
                    ? {
                          name: price.name,
                      }
                    : {}
            }
            onSubmit={async result => {
                await submit({
                    name: result.name,
                    rule: {
                        type: LabsGqlLabPriceRuleType.AdditionalPonticsInRemovable,
                    },
                });
            }}
            disabled={disabled}
        />
    );
};
