import { RouterUtils } from '../../../utils/router/RouterUtils';
import { AutomationBuilderSaveButton } from '../screens/AutomationBuilder/AutomationBuilderSaveButton';
import { useAutomationBuilderAction } from '../state/AutomationBuilder.actions';
import { useAutomationBuilderSelector } from '../state/AutomationBuilder.context';
import { savedAutomationToFormState } from '../state/AutomationBuilder.state';
import { ToolbarContainer } from '@orthly/ui';
import { makeStyles, createStyles, Button, Grid, IconButton, Text, Icon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
    createStyles({
        buttonContainer: {
            width: 'auto',
            minWidth: 300,
            paddingLeft: 12,
        },
    }),
);

const NewAutomationButton: React.FC = () => {
    const startEditing = useAutomationBuilderAction('ENTER_EDITING');
    return (
        <Button
            variant={'contained'}
            endIcon={'PlusIcon'}
            style={{ whiteSpace: 'nowrap' }}
            onClick={() => startEditing()}
        >
            Create New
        </Button>
    );
};

const ExitEditingButton: React.FC = () => {
    const exitEditing = useAutomationBuilderAction('RESET');
    const editing = useAutomationBuilderSelector(s => s.editing);
    const warnUnsavedChanges = useAutomationBuilderSelector(s => {
        return !s.saved || !_.isEqual(s.form, savedAutomationToFormState(s.saved));
    });

    const history = useHistory();
    const onClick = React.useCallback(() => {
        if (!warnUnsavedChanges || window.confirm('Abandon unsaved changes?')) {
            history.replace(`${RouterUtils.pathForScreen('automations')}`);
            exitEditing();
        }
    }, [warnUnsavedChanges, exitEditing, history]);

    if (!editing) {
        return null;
    }

    return (
        <IconButton size={'small'} onClick={onClick} color={'secondary'} style={{ marginRight: 8 }}>
            <Icon icon={'ArrowBackIos'} />
        </IconButton>
    );
};

interface AutomationsToolbarProps {
    refetch: () => Promise<unknown>;
}

export const AutomationsToolbar: React.VFC<AutomationsToolbarProps> = ({ refetch }) => {
    const classes = useStyles();
    const editing = useAutomationBuilderSelector(s => s.editing);
    const automationId = useAutomationBuilderSelector(s => s.saved?.id);
    const title = useAutomationBuilderSelector(s => {
        if (!s.editing) {
            return 'Automations';
        }
        if (s.saved) {
            return `${s.form.name ?? s.saved.name}`;
        }
        return s.form.name ?? 'Create';
    });

    return (
        <ToolbarContainer sticky style={{ flexWrap: 'nowrap', width: '100%', zIndex: 3 }}>
            <Grid container alignItems={'center'}>
                <ExitEditingButton />
                <Text variant={'h5'}>{title}</Text>
            </Grid>
            <Grid container className={classes.buttonContainer} justifyContent={'flex-end'}>
                {editing ? (
                    <AutomationBuilderSaveButton automationId={automationId} refetch={refetch} />
                ) : (
                    <NewAutomationButton />
                )}
            </Grid>
        </ToolbarContainer>
    );
};
