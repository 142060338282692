import c2c_contact_placement_ACCEPTABLE from '../../../../assets/images/design-qc/c2c_contacts/c2c_contact_placement_ACCEPTABLE.jpg';
import c2c_contact_placement_BAD from '../../../../assets/images/design-qc/c2c_contacts/c2c_contact_placement_BAD.jpg';
import c2c_contact_size_ACCEPTABLE from '../../../../assets/images/design-qc/c2c_contacts/c2c_contact_size_ACCEPTABLE.jpg';
import c2c_contact_size_BAD from '../../../../assets/images/design-qc/c2c_contacts/c2c_contact_size_BAD.jpg';
import c2c_parallel_to_poi_ACCEPTABLE from '../../../../assets/images/design-qc/c2c_contacts/c2c_parallel_to_poi_ACCEPTABLE.jpg';
import c2c_parallel_to_poi_BAD from '../../../../assets/images/design-qc/c2c_contacts/c2c_parallel_to_poi_BAD.jpg';
import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { DEFAULT_RESTORATIVE_APPEARANCE, DEFAULT_SCAN_APPEARANCE } from '@orthly/dentin';
import { HeatMapType } from '@orthly/forceps';

export const crownToCrownContactsRubric: DesignQcRubricCategoryEntry = {
    category: 'crown_to_crown_contacts',
    subcategories: [
        {
            category: 'crown_to_crown_contacts',
            name: 'Crown-to-Crown Contact Pressure',
            description:
                'Crown-to-crown contact must be a flat plane cut at SOP-specified contact tightness<br><br>-0.02 intersection for standard contacts<br>-0.04 for terminal crowns',
            grading: {
                type: 'binary',
                bad: 'Contact is not flat plane<br>AND/OR<br>Contact tightness does not follow SOP',
                ideal: 'Contact is flat plane<br>AND<br>Contact tightness follows SOP',
            },
            selectable_bad_reasons: [
                'Crown-to-crown contact is not flat plane',
                'Contact tightness does not follow SOP',
            ],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                qc_settings: {
                    activeHeatMap: HeatMapType.Proximal,
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasAdjacentRestorations,
        },
        {
            category: 'crown_to_crown_contacts',
            name: 'POI matches contact cut direction',
            description:
                'Plane cut of crown-tocrown contact should match unifed POI or (If using individual POI) an average of the 2 crown POIs',
            grading: {
                type: 'binary',
                bad: 'Contact plane cut does not mimic POI of restorations',
                ideal: 'Contact plane cut mimics POI of restorations',
            },
            examples: {
                bad: [c2c_parallel_to_poi_BAD],
                ideal: [c2c_parallel_to_poi_ACCEPTABLE],
            },
            selectable_bad_reasons: ['Crown-to-crown contact plane cut does not match crown path of insertion'],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                qc_settings: {
                    activeHeatMap: HeatMapType.Proximal,
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                        showInsertionAxis: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasAdjacentRestorations,
        },
        {
            category: 'crown_to_crown_contacts',
            name: 'Contact placement (mesial to distal)',
            description:
                'Contact should be placed in a position that does not result in unnaturally proportioned teeth.<br>Position should also NOT impact cusp placement / occlusal function in a negative way<br><br>"You know it when you see it"',
            grading: {
                type: 'binary',
                bad: 'Contact location compromises occlusal function<br>AND/OR<br>Location creates unnaturally proportioned anatomy',
                ideal: "Contact location doesn't compromise occlusal function<br>AND<br>Crowns have proportionate anatomy",
            },
            examples: {
                bad: [c2c_contact_placement_BAD],
                ideal: [c2c_contact_placement_ACCEPTABLE],
            },
            selectable_bad_reasons: [
                'Location of crown-to-crown contact is compromising occlusal functions',
                'Location of crown-to-crown contact is resulting in unnaturally proportioned teeth',
            ],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                qc_settings: {
                    activeHeatMap: HeatMapType.Proximal,
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                        showInsertionAxis: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasAdjacentRestorations,
        },
        {
            category: 'crown_to_crown_contacts',
            name: 'Crown-to-Crown Contact Size',
            description:
                'Contact surface should not take up more than 50% of total surface (measured from occlusal to gingival). Any more contact area makes insertion difficult. <b>Do not forget to keep occlusal embrasures and mesial/distal embrasures natural looking</b>',
            grading: {
                type: 'binary',
                bad: 'C2C contact area take up more than 50% of the total possible contact surface',
                ideal: 'C2C contact area takes up less than 50% of the total possible contact surface',
            },
            examples: {
                bad: [c2c_contact_size_BAD],
                ideal: [c2c_contact_size_ACCEPTABLE],
            },
            selectable_bad_reasons: ['Crown-to-crown contact area is too large'],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                qc_settings: {
                    activeHeatMap: HeatMapType.Proximal,
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                        showInsertionAxis: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasAdjacentRestorations,
        },
    ],
};
