import {
    HeaderContainer,
    CloseButton,
    Label,
    Value,
    PriceConfigContainer,
    PricingLink,
    DateContaienr,
    ItemContainer,
    ItemContent,
    ItemDetailsContainer,
    AutomationLink,
    Row,
    Divider,
    Layout,
    IndentedRow,
    PricingHistoryItemsContainer,
} from './PricingHistoryComponents';
import type {
    ItemDetailsProps,
    ItemDetailRowProps,
    ItemPriceRowProps,
    PricingHistoryHeaderProps,
    PricingHistoryModalProps,
    PricingHistoryItemProps,
    ItemDateProps,
    ItemTitleProps,
    PriceConfigHistoryProps,
    ActorNameProps,
} from './types';
import { formatHistoryGroupingDate, formatHistoryItemDate, getActorInfo } from './utils';
import { dayjsExt as dayjs } from '@orthly/runtime-utils';
import { LoadBlocker } from '@orthly/ui';
import { Text, Drawer, FlossPalette, Icon, Tooltip, ArrowRightAltIcon } from '@orthly/ui-primitives';
import React from 'react';

const ItemPriceRow: React.VFC<ItemPriceRowProps> = ({ label, value }) => {
    return (
        <IndentedRow>
            <Label variant={'body2'} color={'GRAY'}>
                {label}
            </Label>
            <Value variant={'body2'} color={'GRAY'}>
                {value}
            </Value>
        </IndentedRow>
    );
};

const ItemDetailRow: React.VFC<ItemDetailRowProps> = ({ label, value, valueTooltip }) => {
    const valueText = (
        <Value variant={'body2'} color={'DARK_GRAY'} bold>
            {value}
        </Value>
    );
    return (
        <Row>
            <Label variant={'body2'} color={'DARK_GRAY'} bold>
                {label}
            </Label>
            {valueTooltip ? (
                <Tooltip title={valueTooltip} placement={'left'}>
                    {valueText}
                </Tooltip>
            ) : (
                valueText
            )}
        </Row>
    );
};

const ActorName: React.VFC<ActorNameProps> = ({ actorInfo }) => {
    const { name, automationId } = actorInfo;

    if (!automationId) {
        return <span>{name}</span>;
    }

    return (
        <AutomationLink to={`/automations/${automationId}`} target={'_blank'} rel={'noreferrer'}>
            {name}
            <ArrowRightAltIcon />
        </AutomationLink>
    );
};

const PriceConfigHistoryItem: React.VFC<PriceConfigHistoryProps> = ({ actorInfo }) => {
    const priceConfigMessage = (
        <>
            A partner price config for a unit type in this order has changed, please refer to the{' '}
            <PricingLink to={'/pricing'} target={'_blank'} rel={'noreferrer'}>
                Pricing Tab.
            </PricingLink>
        </>
    );
    return (
        <PriceConfigContainer>
            <Text variant={'caption'} color={'DARK_GRAY'}>
                {priceConfigMessage} Changed By: <ActorName actorInfo={actorInfo} />
            </Text>
        </PriceConfigContainer>
    );
};

const ItemGroupingDate: React.VFC<ItemDateProps> = ({ date }) => {
    const formattedDate = formatHistoryGroupingDate(date);
    return (
        <DateContaienr>
            <Text variant={'caption'} color={'GRAY'}>
                {formattedDate}
            </Text>
        </DateContaienr>
    );
};

const ItemTitle: React.VFC<ItemTitleProps> = ({ historyItem }) => {
    const { title, date } = historyItem;
    const formattedDate = dayjs(date).format('MMM D, h:mm a');
    return (
        <Row sx={{ alignItems: 'center' }}>
            <Text variant={'body1'} medium color={'BLACK'}>
                {title}
            </Text>
            <Text sx={{ textAlign: 'right' }} variant={'caption'} color={'GRAY'}>
                {formattedDate}
            </Text>
        </Row>
    );
};

const ItemDetails: React.VFC<ItemDetailsProps> = ({ historyItem }) => {
    const { details, item_details, actor, title, date } = historyItem;
    const historyDetails = details ?? [];
    const itemDetails = item_details ?? [];
    const actorInfo = getActorInfo(actor);
    const historyItemIsPriceConfig = title.includes('Price Config');

    if (historyItemIsPriceConfig) {
        return <PriceConfigHistoryItem actorInfo={actorInfo} />;
    }

    return (
        <ItemDetailsContainer>
            {historyDetails.map(historyDetail => (
                <ItemDetailRow
                    key={`${historyDetail.label}-${date}`}
                    label={historyDetail.label}
                    value={historyDetail.value}
                />
            ))}
            {itemDetails.map(itemDetail => (
                <ItemPriceRow key={itemDetail.item_id} label={itemDetail.name} value={itemDetail.display_price} />
            ))}
            <ItemDetailRow
                label={'Changed By'}
                value={<ActorName actorInfo={actorInfo} />}
                valueTooltip={actorInfo.email}
            />
        </ItemDetailsContainer>
    );
};

const PricingHistoryHeader: React.VFC<PricingHistoryHeaderProps> = ({ title, subtitle, setOpen }) => {
    return (
        <HeaderContainer>
            <CloseButton size={'small'} onClick={() => setOpen(false)}>
                <Icon icon={'Close'} sx={{ color: FlossPalette.BLACK }} />
            </CloseButton>
            <Text variant={'h4'} color={'BLACK'} medium>
                {title}
            </Text>
            <Text variant={'body2'} color={'GRAY'}>
                {subtitle}
            </Text>
        </HeaderContainer>
    );
};

const PricingHistoryItem: React.VFC<PricingHistoryItemProps> = ({ historyItem, previousItem, nextItem }) => {
    const currentDate = formatHistoryItemDate(historyItem.date);
    const previousDate = formatHistoryItemDate(previousItem?.date);
    const nextDate = formatHistoryItemDate(nextItem?.date);
    const showGroupingDate = !previousDate || currentDate !== previousDate;
    const lastItemInGrouping = currentDate !== nextDate;

    return (
        <>
            {showGroupingDate && <ItemGroupingDate date={historyItem.date} />}
            <ItemContainer>
                <ItemContent>
                    <ItemTitle historyItem={historyItem} />
                    <ItemDetails historyItem={historyItem} />
                </ItemContent>
                {!lastItemInGrouping && <Divider />}
            </ItemContainer>
        </>
    );
};

export const PricingHistoryModalBase: React.VFC<PricingHistoryModalProps> = ({
    historyItems,
    loadingHistoryItems,
    title,
    subtitle,
    open,
    setOpen,
    noHistoryItemsFoundMessage,
}) => {
    return (
        <Drawer
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            variant={'temporary'}
            anchor={'right'}
        >
            <LoadBlocker blocking={loadingHistoryItems}>
                <Layout>
                    <PricingHistoryHeader title={title} subtitle={subtitle} setOpen={setOpen} />
                    <PricingHistoryItemsContainer>
                        {historyItems.map((historyItem, idx) => (
                            <PricingHistoryItem
                                key={historyItem.date}
                                historyItem={historyItem}
                                previousItem={historyItems[idx - 1]}
                                nextItem={historyItems[idx + 1]}
                            />
                        ))}
                        {!loadingHistoryItems && historyItems.length === 0 && noHistoryItemsFoundMessage && (
                            <Text>
                                <i>{noHistoryItemsFoundMessage}</i>
                            </Text>
                        )}
                    </PricingHistoryItemsContainer>
                </Layout>
            </LoadBlocker>
        </Drawer>
    );
};
