import type { ToolbarActionDefinition, ToolbarActionProps } from './ToolbarActionDefinition';
import { useMarkFulfillmentDeliveredMutation } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { useChangeSubmissionFn, RootActionDialog } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const MarkDeliveredAction: React.FC<ToolbarActionProps> = props => {
    const { order, open, setOpen } = props;
    const [submitMtn] = useMarkFulfillmentDeliveredMutation({ variables: { data: { orderId: order.id } } });
    const { submit, submitting, called } = useChangeSubmissionFn<any, []>(() => submitMtn(), {
        closeOnComplete: true,
        successMessage: () => ['Order marked delivered', {}],
        onSuccess: async () => {
            await props.refetchOrder();
            setOpen(false);
        },
    });
    React.useEffect(() => {
        if (open && !submitting && !called) {
            if (window.confirm('Mark order delivered?')) {
                void submit();
            } else {
                setOpen(false);
            }
        }
    }, [open, submitting, submit, called, setOpen]);
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Mark delivered'}
            content={<Grid container style={{ minHeight: 150 }} />}
            buttonText={''}
            CustomButton={props.CustomButton}
        />
    );
};

export const MarkDeliveredToolbarAction: ToolbarActionDefinition = {
    label: 'Mark delivered',
    Component: MarkDeliveredAction,
    allowedStatuses: [LabsGqlLabOrderStatus.Shipped],
    capabilityRequirement: 'order.fulfillment.mark_delivered',
};
