import type { ItemInfo, Rule, Decision } from '../orderEditMode';
import { OrderEditMode } from '@orthly/shared-types';

/**
 * editedField checks for the edited field type matching any of its
 * specified field types.  If it finds a match it returns EditOrder as
 * the most permissible possible value, otherwise it returns null to
 * skip
 */
export function editedField(...fields: ItemInfo['editedField'][]): Rule {
    return function (item: ItemInfo): Decision {
        const debugInfo = {
            rule: 'editField',
            expected: fields,
            received: item.editedField,
        };

        if (fields.includes(item.editedField)) {
            return {
                editMode: OrderEditMode.OrderEdit,
                debugInfo,
            };
        }

        return {
            editMode: null,
            debugInfo,
        };
    };
}
