import { ProductImageWrapper } from '../ProductImageWrapper';
import { CartItemV2Utils } from '@orthly/items';
import type { ICartItemV2DTO, ICartItemV2DTOWithId } from '@orthly/items';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import { SimpleCheckbox } from '@orthly/ui';
import { FlossPalette, Text, stylesFactory, Grid, Icon } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    itemSelectContainer: {
        display: `flex`,
        flexDirection: `column`,
        gap: '6px',
        width: `400px`,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    itemSelectRow: {
        display: `flex`,
        flexFlow: `row`,
        alignItems: `center`,
        justifyContent: `flex-start`,
        padding: `10px 0px`,
        gap: `4px`,
        background: FlossPalette.TAN,
        border: `1px solid ${FlossPalette.DARK_TAN}`,
        borderRadius: 16,
    },
    disabledMessage: {
        marginLeft: `11px`,
    },
    formControlLabel: {
        // Make the <label> element full-width so you can click anywhere in the container.
        width: '100%',
        marginRight: 0,
    },
}));

type ItemOrGroup = ICartItemV2DTO | ArrayMin1<ICartItemV2DTO>;

const ItemGroupCheckbox: React.VFC<{
    itemOrGroup: ItemOrGroup;
    setSelected: (isSelected: boolean) => void;
    isSelected: boolean;
    isDisabled?: boolean;
    disabledMessage?: string;
}> = ({
    itemOrGroup,
    setSelected,
    isSelected,
    isDisabled = false,
    disabledMessage = 'Please contact customer support to edit this item',
}) => {
    const classes = useStyles();
    const firstItem = Array.isArray(itemOrGroup) ? itemOrGroup[0] : itemOrGroup;

    return (
        <Grid container>
            <SimpleCheckbox
                checked={isSelected}
                setChecked={checked => setSelected(checked)}
                FormControlLabelProps={{ className: classes.formControlLabel }}
                CheckboxProps={{
                    disabled: isDisabled,
                    color: 'secondary',
                    icon: isDisabled ? <Icon icon={'SquareSharp'} /> : undefined,
                }}
                label={
                    <Grid container direction={'row'} alignItems={'center'}>
                        <ProductImageWrapper
                            product={CartItemV2Utils.getProductUnitType(firstItem)}
                            wrapperStyle={{ marginRight: 7, height: 40, width: 40 }}
                            imgStyle={{ height: 40, width: 40 }}
                        />
                        <Text variant={'body2'} medium color={isDisabled ? 'GRAY' : 'BLACK'}>
                            {Array.isArray(itemOrGroup)
                                ? CartItemV2Utils.getItemGroupDisplayName(itemOrGroup)
                                : CartItemV2Utils.getFullDisplayName(itemOrGroup)}
                        </Text>
                    </Grid>
                }
            />
            {isDisabled && (
                <Text variant={'caption'} color={'GRAY'} className={classes.disabledMessage}>
                    {disabledMessage}
                </Text>
            )}
        </Grid>
    );
};

interface OrderEditItemSelectPageContentProps<T extends ItemOrGroup> {
    items: T[];
    selectedItems: T[];
    setItemSelected: (item: T, selected: boolean) => void;
    nonEditableItems?: ICartItemV2DTOWithId[];
}

export const ItemSelect = <T extends ItemOrGroup>({
    items,
    selectedItems,
    setItemSelected,
    nonEditableItems,
}: OrderEditItemSelectPageContentProps<T>): JSX.Element => {
    const classes = useStyles();

    return (
        <Grid container className={classes.itemSelectContainer}>
            {items.map(item => {
                const firstItem: ICartItemV2DTO = Array.isArray(item) ? item[0] : item;
                return (
                    <Grid key={firstItem.id} item className={classes.itemSelectRow}>
                        <ItemGroupCheckbox
                            itemOrGroup={item}
                            isSelected={selectedItems.includes(item)}
                            setSelected={selected => setItemSelected(item, selected)}
                        />
                    </Grid>
                );
            })}
            {(nonEditableItems ?? []).map(item => (
                <Grid key={item.id} item className={classes.itemSelectRow}>
                    <ItemGroupCheckbox
                        itemOrGroup={item}
                        isSelected={false}
                        isDisabled={true}
                        setSelected={() => null}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
