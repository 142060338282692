import { HelpIcon } from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import { Button, FlossPalette, stylesFactory, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

interface SuggestedValueWarningProps {
    currentValue?: string;
    suggestedValue?: string;
    customStyles?: React.CSSProperties;
    onAccept: () => void;
}

const useSuggestionStyles = stylesFactory((_theme: Theme) => ({
    container: {
        backgroundColor: FlossPalette.TAN,
        padding: '4px 16px',
        width: '100%',
        flexWrap: 'nowrap',
    },
    icon: {
        color: FlossPalette.BURGUNDY,
        marginRight: '8px',
    },
    tip: {
        color: FlossPalette.BURGUNDY,
        marginRight: '8px',
    },
    button: {
        display: 'inline',
        flexShrink: 0,
    },
}));

export const SuggestedValueWarning: React.FC<SuggestedValueWarningProps> = props => {
    const { currentValue, suggestedValue, customStyles, onAccept } = props;
    const [analyticsLogged, setAnalyticsLogged] = React.useState(false);
    const classes = useSuggestionStyles();
    if (!suggestedValue || currentValue === suggestedValue) {
        return null;
    }
    if (!analyticsLogged) {
        setAnalyticsLogged(true);
    }
    return (
        <Grid container alignItems={'center'} className={classes.container} style={{ ...customStyles }}>
            <HelpIcon className={classes.icon} />
            <Text className={classes.tip}>Tip: </Text>
            <Text>For items like this, we suggest {suggestedValue}.</Text>
            <Button
                onClick={() => {
                    onAccept();
                }}
                variant={'ghost'}
                className={classes.button}
            >
                {!!currentValue ? `Switch` : `Select`}
            </Button>
        </Grid>
    );
};
