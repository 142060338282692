import type { Hotkey } from '../../util/hotkeys';
import { HotkeysManager } from '../../util/hotkeys';
import { SpotlightSearchItem } from './SpotlightSearchItem';
import type { SimpleAutocompleteOption } from '@orthly/ui';
import { VirtualizedAutocomplete } from '@orthly/ui';
import {
    stylesFactory,
    FlossPalette,
    Text,
    Collapse,
    Dialog,
    DialogTitle,
    Grid,
    InputAdornment,
    SearchIcon,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import type { KeyboardEvent } from 'react';
import React from 'react';

const useRootStyles = stylesFactory(theme => ({
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        borderRadius: 8,
        borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
        zIndex: 3,
        padding: 0,
        flexWrap: 'wrap',
        background: '#fff',
        '&> *': {
            width: '100%',
        },
    },
    inputRoot: {
        height: 64,
        fontSize: 18,
        paddingTop: 0,
        paddingRight: 0,
        boxShadow: 'none !important',
        border: 'none !important',
        borderRadius: 8,
    },
    palateInput: {
        background: FlossPalette.WHITE,
        '& input': {
            background: FlossPalette.WHITE,
        },
        borderRadius: 8,
        border: `1px solid ${FlossPalette.STAR_GRASS}`,
    },
    autocompletePaper: {
        marginTop: -5,
        borderRadius: '0 0 8px 8px',
        [theme.breakpoints.down('md')]: {
            padding: '4px 0 0',
        },
    },
    autocompleteOption: { zIndex: 0 },
    paper: {
        background: 'none',
        boxShadow: 'none',
        height: 65,
        overflow: 'visible',
    },
}));

interface SpotlightSearchProps<Opt extends SimpleAutocompleteOption> {
    hotkey?: Hotkey;
    options: Opt[];
    onSelectOption: (value: string) => void;
    renderOption?: (optionProps: React.HTMLAttributes<HTMLLIElement>, option: Opt) => React.ReactNode;
    search?: string;
    setSearch: (text?: string) => void;
    noOptionsText?: string;
    open: boolean;
    onClose: () => void;
    placeholder?: string;
    loading: boolean;
    AutocompleteOverride?: React.ReactNode;
    endAdornment?: JSX.Element;
    palate?: boolean;
}

export function SpotlightSearchAutocomplete<Opt extends SimpleAutocompleteOption>(props: SpotlightSearchProps<Opt>) {
    const classes = useRootStyles();
    const { search, setSearch, loading, placeholder, options, renderOption, onClose, endAdornment, palate } = props;
    const renderOptionInternal = React.useCallback(
        (optionProps, option: Opt | string) => {
            const matchingOpt = options.find(o => o.value === (typeof option === 'string' ? option : option.value));
            if (!matchingOpt) {
                return null;
            }
            return renderOption ? (
                renderOption(optionProps, matchingOpt)
            ) : (
                <SpotlightSearchItem title={`${matchingOpt.label ?? matchingOpt.value}`} />
            );
        },
        [options, renderOption],
    );

    const onKeyDown = React.useCallback(
        (event: KeyboardEvent) => {
            const isToggleHotkey = props.hotkey && HotkeysManager.isHotKey(event, props.hotkey);
            if (isToggleHotkey || event.key === 'Escape') {
                onClose();
            }
        },
        [onClose, props.hotkey],
    );

    return (
        <VirtualizedAutocomplete
            label={''}
            options={options}
            optionHeight={palate ? 98 : 84}
            maxOptionCount={5}
            onChange={value => !!value && props.onSelectOption(value)}
            initialInputValue={search ?? ''}
            onInputChange={value => setSearch(value ?? undefined)}
            AutocompleteProps={{
                loading,
                onKeyDown,
                disablePortal: true,
                open: props.AutocompleteOverride === undefined,
                filterOptions: options => options,
                freeSolo: true,
                renderOption: renderOptionInternal,
                classes: { paper: classes.autocompletePaper, option: classes.autocompleteOption },
                style: { width: '100%' },
            }}
            TextFieldProps={{
                placeholder,
                autoFocus: true,
                InputProps: {
                    className: palate ? cx(classes.inputRoot, classes.palateInput) : classes.inputRoot,
                    disableUnderline: true,
                    endAdornment: endAdornment ?? (
                        <InputAdornment position={'end'} style={{ paddingTop: 12, paddingRight: 16 }}>
                            <SearchIcon style={{ color: FlossPalette.GRAY }} />
                        </InputAdornment>
                    ),
                },
            }}
        />
    );
}

export function SpotlightSearch<Opt extends SimpleAutocompleteOption>(props: SpotlightSearchProps<Opt>) {
    const classes = useRootStyles();
    const { open, onClose, options, noOptionsText, loading } = props;
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={'sm'}
            fullWidth
            style={{ paddingBottom: '35vh' }}
            PaperProps={{ className: classes.paper }}
        >
            <DialogTitle className={classes.title}>
                <Collapse in={!!props.AutocompleteOverride} style={{ width: '100%' }}>
                    {props.AutocompleteOverride ?? null}
                </Collapse>
                <Collapse in={!props.AutocompleteOverride} style={{ width: '100%' }} unmountOnExit>
                    <SpotlightSearchAutocomplete<Opt> {...props} />
                    {options.length === 0 && (
                        <Grid container style={{ padding: '6px 16px' }}>
                            <Text color={'DARK_GRAY'} variant={'body1'}>
                                {loading ? 'Loading...' : noOptionsText ?? 'No options found'}
                            </Text>
                        </Grid>
                    )}
                </Collapse>
            </DialogTitle>
        </Dialog>
    );
}
