import { createStyles, Grid, makeStyles, Text, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        badgeCount: {
            backgroundColor: FlossPalette.DARK_TAN,
            height: 16,
            minWidth: 16,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
            marginLeft: 8,
            paddingRight: 4,
            paddingLeft: 4,
            width: `auto`,
        },
        tabWithBadge: {
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0 8px',
        },
    }),
);

export const TabWithBadge: React.FC<{ count?: number; label: string }> = ({ count, label }) => {
    const classes = useStyles();
    return (
        <Grid container direction={'row'} className={classes.tabWithBadge}>
            {label}
            {count !== undefined && (
                <Grid container className={classes.badgeCount}>
                    <Text variant={'caption'} style={{ color: FlossPalette.GRAY, fontWeight: 500, lineHeight: 0 }}>
                        {count}
                    </Text>
                </Grid>
            )}
        </Grid>
    );
};
