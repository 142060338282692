import { RefabFlowOrderDetailsItemLayout } from './RefabFlowOrderDetailsItemLayout';
import { useRefabFlowOrderDetailsStyles } from './styles';
import type { RefabFlowOrderDetailsLayoutProps } from './types';
import { Text, Grid, Tab, Tabs } from '@orthly/ui-primitives';
import React from 'react';

export const RefabFlowOrderDetailsLayout: React.VFC<RefabFlowOrderDetailsLayoutProps> = ({
    items,
    doctorName,
    arrivedAt,
    placedAt,
}) => {
    const classes = useRefabFlowOrderDetailsStyles();

    const [activeTabItemIndex, setActiveTabItemIndex] = React.useState(0);
    const selectedItem = items[activeTabItemIndex];

    return (
        <div>
            <Tabs
                indicatorColor={'secondary'}
                textColor={'inherit'}
                value={activeTabItemIndex}
                onChange={(_, val) => setActiveTabItemIndex(val)}
            >
                {items.map((item, index) => (
                    <Tab key={index} value={index} label={item.name} />
                ))}
            </Tabs>
            <div className={classes.tabContent}>
                {selectedItem && <RefabFlowOrderDetailsItemLayout {...selectedItem} />}
                <Grid container className={classes.row}>
                    <Text variant={'body2'} color={'GRAY'} className={classes.label}>
                        Doctor
                    </Text>
                    <Text variant={'body2'}>{doctorName}</Text>
                </Grid>
                <Grid container className={classes.row}>
                    <Grid item style={{ display: `flex` }} className={classes.preferenceLabel}>
                        <Text variant={'body2'} color={'GRAY'} className={classes.label}>
                            Placed
                        </Text>
                        <Text variant={'body2'}>{placedAt}</Text>
                    </Grid>
                    <Grid item style={{ display: `flex` }}>
                        <Text variant={'body2'} color={'GRAY'} className={classes.label}>
                            Arrived
                        </Text>
                        <Text variant={'body2'}>{arrivedAt}</Text>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
