import { createStyles, makeStyles, TableRow } from '@orthly/ui-primitives';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles(
    () =>
        createStyles({
            root: {},
        }),
    { name: 'MUITableHeadRow' },
);

interface Props {
    children: React.ReactNode;
}

export const TableHeadRow = (props: Props) => {
    const classes = useStyles();
    return (
        <TableRow
            className={classNames({
                [classes.root]: true,
            })}
        >
            {props.children}
        </TableRow>
    );
};
