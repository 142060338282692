import type { Point } from '../Util';
import { projectVector, vectorDifference, vectorSum } from '../Util';
import type { Handle } from './CanvasObject';
import { CanvasObject } from './CanvasObject';

export abstract class RectLikeObject extends CanvasObject {
    protected x: number = 0;
    protected y: number = 0;
    protected width: number = 0;
    protected height: number = 0;

    protected handlesForCorners(lockScale: boolean) {
        return [
            this.handleForCorner(this.point(0, 0), this.point(1, 1), lockScale, 'nwse-resize'),
            this.handleForCorner(this.point(0, 1), this.point(1, 0), lockScale, 'nesw-resize'),
            this.handleForCorner(this.point(1, 0), this.point(0, 1), lockScale, 'nesw-resize'),
            this.handleForCorner(this.point(1, 1), this.point(0, 0), lockScale, 'nwse-resize'),
        ];
    }

    protected point(x: number, y: number): Point {
        return [this.x + x * this.width, this.y + y * this.height];
    }

    private handleForCorner(center: Point, opposite: Point, lockScale: boolean, cursor: string) {
        const constraintVector = lockScale ? vectorDifference(center, opposite) : null;
        return {
            center,
            cursor,
            actionForMove: (point: Point) => {
                let secondPoint = point;
                if (constraintVector) {
                    const proposedDiag = vectorDifference(point, center);
                    const projection = projectVector(proposedDiag, constraintVector);
                    secondPoint = vectorSum(center, projection);
                }
                return () => {
                    this.reshape(opposite, secondPoint);
                };
            },
        };
    }

    grabHandle(): Handle {
        return {
            center: [this.x, this.y],
            cursor: 'grabbing',
            actionForMove: (point: Point) => {
                return () => {
                    this.x = point[0];
                    this.y = point[1];
                };
            },
        };
    }

    reshape(origin: Point, secondCorner: Point) {
        this.x = Math.min(origin[0], secondCorner[0]);
        this.y = Math.min(origin[1], secondCorner[1]);
        this.width = Math.abs(origin[0] - secondCorner[0]);
        this.height = Math.abs(origin[1] - secondCorner[1]);
    }
}
