import { getPresetTitle } from '../../GuidedWaxup.util';
import { useGuidedWaxupAction, useGuidedWaxupSelector } from '../../state/GuidedWaxupState';
import { useComparisonToggleButton } from '../ComparisonToggleButton';
import { checkIfNotesAreRequired } from './StructuredRejectionNotes.util';
import type { OrderDesignPreviewDesign_FragmentFragmentDoc, FragmentType } from '@orthly/graphql-inline-react';
import type { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { RootActionDialog, useScreenIsMobileOrVerticalTablet } from '@orthly/ui';
import { Box, Button, FlossPalette, Icon, TextInput } from '@orthly/ui-primitives';
import React from 'react';

/**
 * This component displays the "additional notes" section of the structured rejection notes.
 * This section is required when the user has previously rejected the design for the same reason.
 *
 * It also provides a button to toggle comparison mode for the design history.
 * If you are on mobile, there is a dialog that warns you about unsaved changes when you try to on comparison mode.
 *
 * The "additional notes" section of this figma: https://www.figma.com/design/C3hnlaLEorlXlJUZZ07b7t/Feature%3A-DDP-Improvements-2024?node-id=6920-111315&node-type=frame&m=dev
 */
export const StructuredRejectionNotesAddendum: React.VFC<{
    disabled?: boolean;
    hasBorder?: boolean;
    isComparisonModeAvailable?: boolean;
    notes?: string;
    onCancel?: () => void;
    mostRecentlyRejectedDesignFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    presetType: LabsGqlGuidedWaxupPresetType;
}> = ({
    disabled,
    hasBorder,
    onCancel,
    presetType,
    notes,
    mostRecentlyRejectedDesignFragment,
    isComparisonModeAvailable,
}) => {
    const [open, setOpen] = React.useState(false);

    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { checked, onClick } = useComparisonToggleButton();

    const preset = useGuidedWaxupSelector(s => s.presets[presetType]);
    const notesAreRequired = checkIfNotesAreRequired(preset, presetType, mostRecentlyRejectedDesignFragment);
    const setPresetRejectionNotes = useGuidedWaxupAction('SET_PRESET_REJECTION_NOTES');
    const presetTitle = React.useMemo(() => getPresetTitle(presetType).toLowerCase(), [presetType]);

    const handleComparisonClick = () => {
        if (isMobile) {
            setOpen(true);
        } else {
            onClick();
        }
    };

    return (
        <Box
            sx={{
                borderTop: hasBorder ? `1px solid ${FlossPalette.DARK_TAN}` : 'none',
                marginTop: hasBorder ? '12px' : '0',
                padding: '12px 0',
                '& .MuiButton-text': {
                    padding: 0,
                    height: 'inherit',
                },
            }}
        >
            <TextInput
                disabled={disabled}
                value={notes ?? preset?.notes ?? ''}
                onChange={(rejectionNotes = '') => setPresetRejectionNotes({ presetName: presetType, rejectionNotes })}
                label={`Additional ${presetTitle} notes`}
                required={notesAreRequired}
                placeholder={`Please provide a description for any ${presetTitle} issues`}
                helpTextBottom={true}
                helpText={
                    notesAreRequired
                        ? "You've previously rejected the design for the same reason, what can we do differently?"
                        : undefined
                }
            />
            {!checked && notesAreRequired && isComparisonModeAvailable && (
                <Button variant={'ghost'} onClick={handleComparisonClick}>
                    Compare design history
                    <Icon icon={'CompareIcon'} />
                </Button>
            )}
            {!checked && open && (
                <RootActionDialog
                    open={open}
                    setOpen={setOpen}
                    loading={false}
                    disableActionsBorder={true}
                    showCloseButton={true}
                    actions={
                        <>
                            <Button
                                variant={'secondary'}
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant={'primary'}
                                onClick={() => {
                                    onCancel?.();
                                    onClick();
                                    setOpen(false);
                                }}
                            >
                                Leave & compare designs
                            </Button>
                        </>
                    }
                    title={'You have unsaved changes'}
                    content={'You have started editing feedback. Leave this page and lose changes?'}
                />
            )}
        </Box>
    );
};
