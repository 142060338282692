import type { ToolbarActionProps, ToolbarActionDefinition } from './ToolbarActionDefinition';
import type { LabsGqlOrder, LabsGqlChangeDesignSoftwareMutationVariables } from '@orthly/graphql-operations';
import { useChangeDesignSoftwareMutation } from '@orthly/graphql-react';
import { LabsGqlAlignerDesignSoftware } from '@orthly/graphql-schema';
import { useChangeSubmissionFn, RootActionDialog } from '@orthly/ui';
import { Button, FlossPalette, Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

type Vars = LabsGqlChangeDesignSoftwareMutationVariables['data'];

const ConvertToArchformAction: React.FC<ToolbarActionProps> = props => {
    const { order, refetchOrder, open, setOpen, CustomButton } = props;
    const [submitMtn] = useChangeDesignSoftwareMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Converted case to ArchForm', {}],
        onSuccess: async () => {
            await refetchOrder();
            setOpen(false);
        },
    });

    if (!!order.cancel_date || !!order.needs_refabrication_date) {
        return null;
    }
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={`Convert design software to ArchForm?`}
            content={
                <Grid container direction={`column`} alignItems={`center`} spacing={4}>
                    <Grid item>
                        <Text variant={`body2`}>
                            <b>Make sure you mean it!</b> All design progress will be lost and the order will be reset
                            to its original state.
                        </Text>
                    </Grid>
                    <Grid item>
                        <Button
                            variant={`alert`}
                            onClick={async () => {
                                void submit({
                                    order_id: order.id,
                                    new_design_software: LabsGqlAlignerDesignSoftware.ArchForm,
                                });
                            }}
                        >
                            Reset & Convert to ArchForm
                        </Button>
                    </Grid>
                </Grid>
            }
            style={{ padding: 0 }}
            buttonText={'Convert to ArchForm'}
            buttonColor={FlossPalette.ATTENTION}
            buttonTextColor={'#fff'}
            buttonProps={{
                style: { height: '100%' },
            }}
            CustomButton={CustomButton}
        />
    );
};

export const ConvertToArchformToolbarAction: ToolbarActionDefinition = {
    Component: ConvertToArchformAction,
    label: 'Convert to ArchForm',
    isVisible: (order: LabsGqlOrder) => {
        if (order.aligner_case?.design_software === LabsGqlAlignerDesignSoftware.SoftSmile) {
            return true;
        }
        return false;
    },
    capabilityRequirement: 'order.convert_to_archform',
};
