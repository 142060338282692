import { StackX } from '@orthly/ui';
import { Box, CheckboxPrimitive, FlossPalette, Paper, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const ControllerContainer = styled(Paper)(() => ({
    position: 'absolute',
    top: '8px',
    left: '8px',
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    borderRadius: '8px',
    padding: '8px',
}));

const ColorSwatch = styled(Box)(() => ({
    width: '16px',
    height: '16px',
    borderRadius: '4px',
}));

interface ControllerSplineEntry {
    name: string;
    color: string;
    visible: boolean;
}

type SetSplineVisibilityFn = (splineIndex: number, visible: boolean) => void;

interface ControllerProps {
    splines: ControllerSplineEntry[];
    setSplineVisibility: SetSplineVisibilityFn;
}

interface SplineRowProps extends ControllerSplineEntry {
    index: number;
    setSplineVisibility: SetSplineVisibilityFn;
}

export const SplineRow: React.VFC<SplineRowProps> = ({ name, color, visible, index, setSplineVisibility }) => {
    const onChange = React.useCallback(
        (evt: React.ChangeEvent<HTMLInputElement>) => {
            setSplineVisibility(index, evt.target.checked);
        },
        [index, setSplineVisibility],
    );

    return (
        <StackX style={{ alignItems: 'center' }}>
            <CheckboxPrimitive color={'secondary'} size={'small'} checked={visible} onChange={onChange} />
            <ColorSwatch bgcolor={color} sx={{ marginRight: '8px' }} />
            <Text variant={'body2'}>{name}</Text>
        </StackX>
    );
};

export const Controller: React.VFC<ControllerProps> = ({ splines, setSplineVisibility }) => {
    return (
        <ControllerContainer>
            <Text variant={'h6'}>Splines</Text>
            {splines.map((props, index) => {
                return (
                    <SplineRow key={props.name} index={index} setSplineVisibility={setSplineVisibility} {...props} />
                );
            })}
        </ControllerContainer>
    );
};
