import type { LabsGqlScanExportAssetFragment } from '@orthly/graphql-operations';
import { LabsGqlScanExportAssetKind } from '@orthly/graphql-schema';
import _ from 'lodash';

interface StlPaths {
    upper: string[];
    lower: string[];
    bitescan: string[];
}

export class ScanExportAssetUtils {
    static getStlsOfKind(assets: LabsGqlScanExportAssetFragment[], assetKind: LabsGqlScanExportAssetKind): string[] {
        return _.compact(assets.map(({ kind, stl_path }) => (kind === assetKind ? stl_path : null)));
    }

    static getStlPaths(assets: LabsGqlScanExportAssetFragment[]): StlPaths {
        return {
            upper: ScanExportAssetUtils.getStlsOfKind(assets, LabsGqlScanExportAssetKind.UpperJawScan),
            lower: ScanExportAssetUtils.getStlsOfKind(assets, LabsGqlScanExportAssetKind.LowerJawScan),
            bitescan: ScanExportAssetUtils.getStlsOfKind(assets, LabsGqlScanExportAssetKind.BiteScan),
        };
    }
}
