import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { OrderDetailFactRow, ScanExportSourceToScannerNameMap } from '@orthly/veneer';

const AdminOrderDetailSummaryScanSourceLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailSummaryScanSourceLabSalesOrder_Fragment on LabSalesOrderDTO {
        scan_export {
            scan_source
            scan_source_version
        }
    }
`);

interface OrderDetailSummaryScanSourceProps {
    salesOrder: FragmentType<typeof AdminOrderDetailSummaryScanSourceLabSalesOrder_Fragment>;
}

export const OrderDetailSummaryScanSource: React.VFC<OrderDetailSummaryScanSourceProps> = ({ salesOrder }) => {
    const { scan_export } = getFragmentData(AdminOrderDetailSummaryScanSourceLabSalesOrder_Fragment, salesOrder);

    return (
        <OrderDetailFactRow
            title={'Scan Source'}
            value={`${ScanExportSourceToScannerNameMap[scan_export.scan_source]} ${scan_export.scan_source_version ?? ''}`}
        />
    );
};
