export interface OrderEditPage {
    supertitle: string;
    title: string;
    titleDecoration?: React.ReactNode;
    Content: React.ReactNode;
    extrasButton?: React.ReactNode;
    // Indicates that the 'next' button should say "Skip" instead.
    nextIsSkip?: boolean;
    nextIsDisabled?: boolean;
    // Safe way to differentiate pages, instead of relying on page indices
    type?: 'item_select' | 'extras' | 'item_edit' | 'review_order';
}

// Since models aren't editable, we exclude them from the C/R Extras flow
export const blacklistedExtras = ['Model', 'Study Model', 'Implant Model', 'Removable Model'];
