import { DandyLightbox } from '../DandyLightbox';
import { useGuidedQcAnalytics } from './DesignQcCommon';
import type { DesignQcRubricSubCategoryEntry } from './configs/DesignQc.config.types';
import { useDesignQcPropSelector } from './state/DesignQc.context';
import { RootActionDialog } from '@orthly/ui';
import { FlossPalette, Text, Button, stylesFactory, Grid } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    subcategoryRowRoot: {
        minHeight: 48,
        padding: `12px 0px`,
    },
    subcategoryRowBottomBorder: {
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    },
    subcategoryRowPhoto: {
        maxHeight: 80,
        maxWidth: 80,
        cursor: 'pointer',
    },
}));

interface DesignQcSubcategoryRubricModalProps {
    label: string;
    value: string;
    bottomBorder: boolean;
    photoUrls?: string[];
}

const DesignQcSubcategoryModalRow: React.VFC<DesignQcSubcategoryRubricModalProps> = ({
    label,
    value,
    bottomBorder,
    photoUrls,
}) => {
    const classes = useStyles();
    const { order } = useDesignQcPropSelector(['order']);

    const [selectedPhotoUrl, setSelectedPhotoUrl] = React.useState<string | undefined>(undefined);

    return (
        <Grid
            container
            item
            direction={'row'}
            className={clsx(classes.subcategoryRowRoot, bottomBorder ? classes.subcategoryRowBottomBorder : undefined)}
        >
            <Grid item xs={3}>
                <Text variant={'body2'} medium color={'BLACK'}>
                    {label}
                </Text>
            </Grid>
            {photoUrls?.length && (
                <Grid item xs={2}>
                    <DandyLightbox
                        setSelectedAttachmentPreview={setSelectedPhotoUrl}
                        previews={photoUrls.map(p => ({ source: p, name: p }))}
                        selectedAttachmentPreview={selectedPhotoUrl}
                        onPhotoViewedAnalytics={(source, name) => {
                            return {
                                name: 'All - Portal - Photo Lightbox Viewed',
                                data: {
                                    $groups: { order: order.id },
                                    displayLocation: 'guided_design_qc',
                                    photoSource: source,
                                    photoName: name,
                                },
                            };
                        }}
                    />
                    <img
                        src={photoUrls[0]}
                        className={classes.subcategoryRowPhoto}
                        onClick={() => setSelectedPhotoUrl(photoUrls[0])}
                    />
                </Grid>
            )}
            <Grid item xs>
                <Text variant={'body2'} color={'DARK_GRAY'} style={{ textAlign: 'right' }}>
                    <span dangerouslySetInnerHTML={{ __html: value }} />
                </Text>
            </Grid>
        </Grid>
    );
};

export const DesignQcSubcategoryModal: React.VFC<{
    entry: DesignQcRubricSubCategoryEntry;
    open: boolean;
    setOpen: (open: boolean) => void;
}> = ({ open, setOpen, entry }) => {
    const trackModalOpen = useGuidedQcAnalytics('All - Portal - Guided QC - Subcategory Rubric Viewed');
    React.useEffect(() => {
        if (open) {
            trackModalOpen({});
        }
    }, [open, trackModalOpen]);

    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            title={entry.name}
            loading={false}
            CustomButton={() => null}
            showCloseButton
            content={
                <Grid container direction={'column'}>
                    <Grid item>
                        <Text variant={'body2'}>
                            <span dangerouslySetInnerHTML={{ __html: entry.description }} />
                        </Text>
                    </Grid>
                    <DesignQcSubcategoryModalRow
                        label={'Grading'}
                        value={
                            // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                            // eslint-disable-next-line no-nested-ternary
                            entry.grading.type === 'binary'
                                ? 'Binary'
                                : entry.grading.type === 'tertiary'
                                  ? 'Ternary'
                                  : 'Select All That Apply'
                        }
                        bottomBorder
                    />
                    {entry.grading.type === 'binary' || entry.grading.type === 'tertiary' ? (
                        <>
                            <DesignQcSubcategoryModalRow
                                label={'Bad'}
                                value={entry.grading.bad}
                                photoUrls={entry.examples?.bad}
                                bottomBorder
                            />
                            <DesignQcSubcategoryModalRow
                                label={'Acceptable'}
                                value={entry.grading.type === 'tertiary' ? entry.grading.acceptable : 'N/A'}
                                photoUrls={entry.examples?.acceptable}
                                bottomBorder
                            />
                            <DesignQcSubcategoryModalRow
                                label={'Ideal'}
                                value={entry.grading.ideal}
                                photoUrls={entry.examples?.ideal}
                                bottomBorder={false}
                            />
                        </>
                    ) : (
                        <>
                            {entry.grading.entries.map(e => (
                                <DesignQcSubcategoryModalRow
                                    label={e.name}
                                    value={e.description ?? 'N/A'}
                                    bottomBorder={true}
                                    key={e.name}
                                />
                            ))}
                        </>
                    )}
                    {entry.edge_case && (
                        <>
                            <Grid item>
                                <Text variant={'caption'} medium color={'GRAY'}>
                                    Edge Case - Scenarios where our narrow definitions do not apply
                                </Text>
                            </Grid>
                            <Grid item>
                                <Text variant={'caption'} color={'GRAY'}>
                                    <span dangerouslySetInnerHTML={{ __html: entry.edge_case }} />
                                </Text>
                            </Grid>
                        </>
                    )}
                    <Grid item container direction={'row'}>
                        <Grid item xs />
                        <Grid item>
                            <Button variant={'secondary'} onClick={() => setOpen(false)}>
                                Close
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
        />
    );
};
