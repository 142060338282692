import type { PricesRow } from './PricingRoot.types';
import { useUpdatedPricingQuery } from './hooks/useUpdatedPricingQuery.graphql';
import { useMutation } from '@apollo/client';
import type {
    BulkLoadAllPracticePricesQuery,
    GetPricesForPracticesQuery,
    ListOrganizationSummariesQuery,
} from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import type { MUITableColumn } from '@orthly/mui-table';
import { Format } from '@orthly/runtime-utils';
import { useRootActionCommand } from '@orthly/ui';
import { Button, Menu, MenuItem, styled } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

export const Container = styled('div')({
    width: '100%',
    '& .MuiTableCell-body': {
        paddingTop: 3,
        paddingBottom: 3,
        '& .MuiCheckbox-root': {
            paddingTop: 1,
            paddingBottom: 1,
        },
    },
});

const ApplyPricePreset_Mutation = graphql(`
    mutation ApplyPricePreset($data: ApplyPricePresetCommand!) {
        applyPricePreset(data: $data) {
            id
        }
    }
`);

const PresetPicker: React.FC<{ partnerId: string; refresh?: () => void; disabled: boolean }> = ({
    partnerId,
    refresh,
    disabled,
}) => {
    const { value: disableContractEdits = false } = useFeatureFlag('disableContractEdits');
    const { activePricePresets, pricePresetsLoading } = useUpdatedPricingQuery();
    const applyPricePreset = useMutation(ApplyPricePreset_Mutation);
    const { submit, submitting } = useRootActionCommand(applyPricePreset, { onSuccess: refresh });
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();

    const handleClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = React.useCallback(
        (presetId?: string) => {
            if (
                presetId !== undefined &&
                window.confirm(
                    'Are you sure you want to apply this preset? No existing partner prices will be overwritten, but this change cannot be undone',
                )
            ) {
                void submit({ data: { preset_id: presetId, partner_id: partnerId } });
            }
            setAnchorEl(null);
        },
        [submit, partnerId],
    );

    return (
        <>
            <Button
                variant={'contained'}
                onClick={handleClick}
                disabled={disabled || pricePresetsLoading || submitting || disableContractEdits}
            >
                Apply Preset
            </Button>
            <Menu
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => handleClose()}
            >
                {activePricePresets !== undefined &&
                    activePricePresets.map(preset => (
                        <MenuItem key={preset.id} onClick={() => handleClose(preset.id)}>
                            Code: {preset.code}
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
};

export const ActionsContainer = styled('div')({
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
});

export const buildPartnerPriceMap = (
    partnerPricesRaw: GetPricesForPracticesQuery['bulkLoadPracticePrices'],
    emptyPriceMap: Record<string, string>,
) => {
    return partnerPricesRaw.reduce<Record<string, Partial<PricesRow>>>((acc, partnerPrice) => {
        const { partnerId, prices } = partnerPrice;

        const partnerPriceMap: Partial<PricesRow> = prices.reduce((acc, price) => {
            const { name, partner_prices } = price;
            const priceCents = partner_prices[0]?.price_cents;
            const formattedPrice = typeof priceCents === 'number' ? Format.currency(priceCents, 'cents') : null;
            return { ...acc, [name]: formattedPrice };
        }, {});

        Object.keys(emptyPriceMap).forEach(priceName => {
            if (!partnerPriceMap[priceName]) {
                partnerPriceMap[priceName] = 'Not Set';
            }
        });

        return { ...acc, [partnerId]: partnerPriceMap };
    }, {});
};

export const generateRowsFromBulkLoad = (
    bulkFetchedPrices: BulkLoadAllPracticePricesQuery['listPrices'],
    practiceNames: ListOrganizationSummariesQuery['listOrganizationSummaries'],
) => {
    // For each list of prices, we want to create a map of partner_id to price_cents
    const pricesWithPartnerNamesAndPriceMap = bulkFetchedPrices.map(price => {
        const partnerMap = price.partner_prices.reduce(
            (map, partnerPrice) => {
                map[partnerPrice.partner_id] = partnerPrice?.price_cents;
                return map;
            },
            {} as { [k: string]: number | undefined },
        );
        return {
            ...price,
            partnerPriceMap: partnerMap,
        };
    });

    // for each partner, we want to create a row with the partner name and the price for each product
    const allRows = practiceNames.map(practice => {
        const rowWithAllPrices = pricesWithPartnerNamesAndPriceMap.reduce<PricesRow>(
            (row, price) => {
                const partnerOpt = price.partnerPriceMap[practice.id];
                return {
                    ...row,
                    [price.name]: typeof partnerOpt === 'number' ? Format.currency(partnerOpt, 'cents') : null,
                };
            },
            { id: practice.id, isLoaded: true, partner: practice.name },
        );
        return rowWithAllPrices;
    });

    return allRows;
};

export const useColumns = (
    fetchPrice: (id: string) => void,
    fetchPartnerId: string | undefined,
    fetchPricesForPartnersLoading: boolean,
    allLoading: boolean,
) => {
    const { prices } = useUpdatedPricingQuery();
    const [sortColumnName, setSortColumnName] = React.useState<'' | 'isLoaded'>('');

    const applyPresetCol: MUITableColumn<PricesRow> = {
        name: 'Actions',
        title: 'Actions',
        filter: false,
        sort: true,
        customSort: (a, b) => {
            if (a.isLoaded && !b.isLoaded) {
                return -1;
            }
            if (!a.isLoaded && b.isLoaded) {
                return 1;
            }
            return 0;
        },
        defaultSort: sortColumnName === 'isLoaded' ? 'desc' : undefined,
        render: r => {
            if (r.isLoaded) {
                return <PresetPicker disabled={allLoading} partnerId={r.id} refresh={() => fetchPrice(r.id)} />;
            }
            if (fetchPricesForPartnersLoading && fetchPartnerId === r.id) {
                return (
                    <Button variant={'contained'} disabled>
                        Loading...
                    </Button>
                );
            }
            return (
                <Button variant={'contained'} disabled={allLoading} onClick={() => fetchPrice(r.id)}>
                    Load practice
                </Button>
            );
        },
    };

    const priceColumns = prices.map<MUITableColumn<PricesRow>>(price => ({
        name: price.name,
        title: price.name,
        render: r => r[price.name],
        filterOptions: {
            type: 'multiselect' as 'multiselect',
            exact: true,
            defaultOpts: ['Set', 'Not Set'],
            sortFilterList: true,
        },
        customFilterFn: (filterValues, row) => {
            if (!row.isLoaded) {
                return false;
            }
            const rowHasValue = row[price.name] !== 'Not Set';
            return (rowHasValue && filterValues.includes('Set')) || (!rowHasValue && filterValues.includes('Not Set'));
        },
    }));

    const columns = [
        applyPresetCol,
        { name: 'Id', hidden: true, render: 'id' },
        { name: 'Partner', render: 'name' },
        ...priceColumns,
    ];

    return { columns, setSortColumnName };
};
