import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import type { LabOrderItemSKUType } from '@orthly/items';
import { SimpleTextField } from '@orthly/ui';
import React from 'react';

export const ItemEditorV2SurgicalGuideDesiredSizeField: React.VFC<
    Omit<ItemEditorV2BaseProps<LabOrderItemSKUType.SurgicalGuide>, 'showError'>
> = ({ item, updateItem }) => {
    return (
        <SimpleTextField
            label={'Preferred Implant Size (Optional)'}
            helperText={'Diameter x length (mm)'}
            value={item.unit.implant_metadata.desired_size ?? undefined}
            onChange={val =>
                updateItem({
                    name: 'surgical_guide_implant_metadata',
                    payload: { desired_size: val || null },
                })
            }
        />
    );
};
