import { useUsersAction } from '../../state/Users.actions';
import { useUsersSelector } from '../../state/Users.context';
import type { UserRole } from '../../state/Users.types';
import { useUpdatePreference } from '../../useUpdatePreference';
import {
    useAddStaffMember,
    useUpdateStaffMember,
    useDeleteStaffMember,
    useReactivateStaffMember,
    useReactivateDrPref,
    useCreateDrPref,
    useDeleteDrPref,
} from '../EditUserRoles.util.graphql';
import { extractGqlRoles } from '../EditUserRoles.util.graphql';
import { useGetPreferencesByStaffMemberIdsQuery } from '@orthly/graphql-react';
import type { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import React from 'react';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useEditUserRole = (role: UserRole, idx: number) => {
    const isPractice = role.type === LabsGqlOrganizationType.Practice;

    const user = useUsersSelector(s => s.user);
    const updateRole = useUsersAction('UPDATE_ROLE');
    const deleteRole = useUsersAction('DELETE_ROLE');

    const { submit: submitAddRole, submitting: submittingAdd } = useAddStaffMember();
    const { submit: submitUpdateRole, submitting: submittingUpdate } = useUpdateStaffMember();
    const { submit: submitDeleteRole, submitting: submittingDelete } = useDeleteStaffMember();
    const { submit: submitReactivateRole, submitting: submittingReactivateRole } = useReactivateStaffMember();

    const { submit: submitUpdatePrefRole, submitting: submittingPrefUpdate } = useUpdatePreference();
    const { submit: submitCreateDrPref, submitting: submittingCreateDrPref } = useCreateDrPref();
    const { submit: submitDeleteDrPref, submitting: submittingDeleteDrPref } = useDeleteDrPref();
    const { submit: submitReactivateDrPref, submitting: submittingReactivateDrPref } = useReactivateDrPref();

    const { data } = useGetPreferencesByStaffMemberIdsQuery({
        variables: { staff_member_ids: role.member_id ? [role.member_id] : [] },
        skip: !role.member_id,
    });
    const prefs = data?.preferences;
    const drPref = prefs?.[0];
    const dr_pref_id = drPref?.id;

    const organization_id = role.organization_id;

    const submitting = React.useMemo(() => {
        return (
            submittingAdd ||
            submittingUpdate ||
            submittingDelete ||
            submittingReactivateRole ||
            submittingPrefUpdate ||
            submittingReactivateDrPref ||
            submittingCreateDrPref ||
            submittingDeleteDrPref
        );
    }, [
        submittingAdd,
        submittingUpdate,
        submittingDelete,
        submittingReactivateRole,
        submittingPrefUpdate,
        submittingReactivateDrPref,
        submittingCreateDrPref,
        submittingDeleteDrPref,
    ]);

    const reactivateRoleFn = async () => {
        if (!user) {
            return;
        }
        if (!organization_id) {
            return;
        }
        if (isPractice && dr_pref_id) {
            // we have dr pref pref from good data
            await submitReactivateDrPref({
                variables: { data: { preferences_id: dr_pref_id } },
            });
        } else if (isPractice && user && role && organization_id) {
            //we have a staff and no dr pref from old bad data
            await submitCreateDrPref({
                data: {
                    name: `${user?.first_name} ${user?.last_name}`,
                    partner_id: organization_id,
                    roles: extractGqlRoles(role.roles, 'practice'),
                    contact_email: user?.email,
                    contact_phone: user?.mobile_phone_number,
                    contact_phone_call_number: user?.phone_number,
                },
            });
        } else {
            await submitReactivateRole({
                organization_id,
                user_id: user.id,
            });
        }
    };
    const saveRoleFn = async () => {
        if (role.editing && user) {
            if (role.member_id) {
                if (dr_pref_id && role.roles) {
                    await submitUpdatePrefRole({
                        preference_set_id: dr_pref_id,
                        roles: role.roles as LabsGqlStaffRolePractice[],
                    });
                } else {
                    await submitUpdateRole({ role, user_id: user.id });
                }
            } else {
                if (isPractice && user && role && organization_id) {
                    await submitCreateDrPref({
                        data: {
                            name: `${user?.first_name} ${user?.last_name}`,
                            partner_id: organization_id,
                            roles: extractGqlRoles(role.roles, 'practice'),
                            contact_email: user?.email,
                            contact_phone: user?.mobile_phone_number,
                            contact_phone_call_number: user?.phone_number,
                        },
                    });
                } else {
                    await submitAddRole({ role, user_id: user.id });
                }
            }
        }
        updateRole({ idx, property: 'editing', value: !role.editing });
    };

    const removeRoleFn = async () => {
        if (isPractice && dr_pref_id) {
            await submitDeleteDrPref({ data: { preferences_id: dr_pref_id } });
        } else if (organization_id && role.member_id) {
            await submitDeleteRole({ organization_id, member_id: role.member_id });
        }
        deleteRole({ idx });
    };
    return {
        submitting,
        removeRoleFn,
        saveRoleFn,
        reactivateRoleFn,
        isPractice,
        drPref,
        user,
    };
};
