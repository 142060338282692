import type { ViewState, ToggleFavoriteProps } from './SavedView.types';
import { UNSAVED_VIEW_ID } from './SavedViewActionConstants';
import type { LabsGqlSavedSearchVisibility } from '@orthly/graphql-schema';
import _ from 'lodash';

export class SavedViewReducerUtils {
    static toggleViewVisibility<T extends ViewState>(
        state: T,
        searchId: string,
        newVisibility: LabsGqlSavedSearchVisibility,
    ): T {
        const savedViews = state.savedViews;
        const view = savedViews[searchId];
        if (!view) {
            return state;
        }
        savedViews[searchId] = { ...view, visibility: newVisibility };
        const newState = {
            ...state,
            savedViews,
        };
        if (newState.view?.id === searchId && newState.view) {
            return { ...newState, view: { ...newState.view, visibility: newVisibility } };
        }
        return newState;
    }

    static toggleFavorite<T extends ViewState>(props: ToggleFavoriteProps<T>): T {
        const { state, searchId, setCurrentCustomView, view } = props;
        if (!view) {
            const newState = { ...state, savedViews: _.omit(state.savedViews, searchId) };
            // we are deleting the currently active view, update the current view to be unsaved
            if (state.view?.id === searchId) {
                return setCurrentCustomView(newState, {
                    id: UNSAVED_VIEW_ID,
                    title: 'Unsaved view',
                } as T['view']);
            }
            return newState;
        }
        const newState: T = {
            ...state,
            savedViews: Object.assign({ ...state.savedViews }, { [`${view.id}`]: view }),
        };
        return setCurrentCustomView(newState, view);
    }
}
