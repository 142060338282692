import { RootActionDialog } from '@orthly/ui';
import { NotificationLogsTable } from '@orthly/veneer';
import React from 'react';

export const NotificationLogsDialog: React.VFC<{ doctorPreferencesId: string; doctorPreferencesName: string }> = ({
    doctorPreferencesId,
    doctorPreferencesName,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    return (
        <RootActionDialog
            loading={false}
            title={`${doctorPreferencesName} Notification Logs`}
            buttonText={'Notification Logs'}
            setOpen={setOpen}
            open={open}
            content={<NotificationLogsTable doctorPreferencesId={doctorPreferencesId} />}
            fullScreen
            showCloseButton
            buttonProps={{
                analytics: {
                    AssetLocation: 'Admin - Notification Logs',
                    AssetName: 'Open Dialog',
                },
            }}
        />
    );
};
