import { useAutomationBuilderAction } from '../../../../state/AutomationBuilder.actions';
import { useAutomationBuilderSelector } from '../../../../state/AutomationBuilder.context';
import { AutomationSelectField } from '../../components/AutomationBuilderFields';
import { LabsGqlFilterCombinator } from '@orthly/graphql-schema';
import React from 'react';

export const OrderFilterCombinatorField: React.FC = () => {
    const setCombinator = useAutomationBuilderAction('SET_COMBINATOR');
    const combinator = useAutomationBuilderSelector(s => s.form.filter_combinator);
    return (
        <AutomationSelectField
            variant={'outlined'}
            label={''}
            FormControlProps={{ style: { width: 'auto', minWidth: 75 }, variant: 'standard' }}
            value={combinator}
            onChange={value => {
                !!value &&
                    setCombinator(
                        value === LabsGqlFilterCombinator.And
                            ? LabsGqlFilterCombinator.And
                            : LabsGqlFilterCombinator.Or,
                    );
            }}
            options={[
                { value: LabsGqlFilterCombinator.And, label: 'And' },
                { value: LabsGqlFilterCombinator.Or, label: 'Or' },
            ]}
        />
    );
};
