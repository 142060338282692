import { AnalyticsClient } from '../../../../../../utils/analyticsClient';
import { OrderItemMenuButton } from './OrderItemMenuButton';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import { useRootActionCommand } from '@orthly/ui';
import { Icon } from '@orthly/ui-primitives';
import type { OrderDetailItemsTableItemActionItem } from '@orthly/veneer';
import React from 'react';

const AdminDeleteOrderItemV2ButtonLabSalesOrderDeleteItem_Mutation = graphql(`
    mutation AdminDeleteOrderItemV2ButtonLabSalesOrderDeleteItem_Mutation($args: LabSalesOrderDeleteItemInput!) {
        labSalesOrderDeleteItem(args: $args) {
            id
        }
    }
`);

interface DeleteOrderItemV2ButtonProps {
    orderId: string;
    items: ArrayMin1<OrderDetailItemsTableItemActionItem>;
    refetch: () => Promise<unknown>;
}

export const DeleteOrderItemV2Button: React.VFC<DeleteOrderItemV2ButtonProps> = ({ orderId, items, refetch }) => {
    const deleteItemMutation = useMutation(AdminDeleteOrderItemV2ButtonLabSalesOrderDeleteItem_Mutation);
    const { submit, submitting } = useRootActionCommand(deleteItemMutation, {
        onSuccess: () => {
            refetch().catch(console.error);
        },
        successMessage: 'Order item deleted!',
    });

    const deleteItem = (itemId: string) => {
        const item = items.find(i => i.itemId === itemId);
        if (!item) {
            return;
        }
        if (window.confirm(`Delete ${item.label}?`)) {
            void submit({ args: { sales_order_id: orderId, item_id: item.itemId } });
            AnalyticsClient.track('Ops - Item Delete - Submitted', {
                $groups: { order: orderId },
            });
        }
    };

    return (
        <OrderItemMenuButton items={items} onSelect={deleteItem} disabled={submitting}>
            <Icon icon={'DeleteOutline'} />
        </OrderItemMenuButton>
    );
};
