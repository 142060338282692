import { BulkDownloadDialog } from '../../BulkDownloadDialog/BulkDownloadDialog';
import { useLazyQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import React from 'react';

const VeneerDesignOrderRevisionManufacturerFilesDownload_Query = graphql(`
    query VeneerDesignOrderRevisionManufacturerFilesDownload_Query(
        $orderIds: [String!]!
        $rotated: Boolean!
        $return_design_metadata: Boolean
    ) {
        getLatestManufacturerFilesForOrders(
            labOrderIds: $orderIds
            rotated: $rotated
            return_design_metadata: $return_design_metadata
        ) {
            file_name
            gcs_path
            order_id
        }
    }
`);

interface DesignOrderRevisionManufacturerFilesDownloadProps {
    Button: React.VFC<{ onClick: () => void }>;
    orderIds: string[];
    rotated: boolean;
    onDownloadStarted?: (orderIds: string[], rotated: boolean) => void;

    /** Whether to fetch additional design specific data, such as design acceleration data. Defaults to falsy if not provided. **/
    returnDesignMetadata?: boolean;
}

export const DesignOrderRevisionManufacturerFilesDownload: React.VFC<
    DesignOrderRevisionManufacturerFilesDownloadProps
> = ({ Button, orderIds, rotated, onDownloadStarted, returnDesignMetadata }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [fetchDesigns, { data }] = useLazyQuery(VeneerDesignOrderRevisionManufacturerFilesDownload_Query, {
        onCompleted: () => {
            setOpen(true);
        },
    });

    return (
        <>
            <Button
                onClick={() => {
                    fetchDesigns({
                        variables: {
                            orderIds,
                            rotated,
                            return_design_metadata: returnDesignMetadata,
                        },
                    });
                }}
            />
            <BulkDownloadDialog
                open={open}
                setOpen={setOpen}
                files={
                    data?.getLatestManufacturerFilesForOrders.map(rev => ({
                        source: 'firebase',
                        path: rev.gcs_path,
                        name: rev.file_name,
                    })) ?? []
                }
                onDownloadStart={() => onDownloadStarted?.(orderIds, rotated)}
            />
        </>
    );
};
