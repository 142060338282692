import { useAlignerPlansDetails } from '../Aligners/AlignerPlans';
import { AlignerTreatmentPlanReference } from './AlignerTreatmentPlanReference.graphql';
import { AlignerTreatmentPlanSidebarBox, AlignerTreatmentPlanSidebarRow } from './AlignerTreatmentPlanSidebar.graphql';
import { ApproveAlignerFabrication } from './forms/ApproveAlignerFabrication.graphql';
import { MarkAlignerPlanRequested } from './forms/MarkAlignerPlanRequested';
import { MarkExternalAlignerFulfillmentCreated } from './forms/MarkExternalAlignerFulfillmentCreated';
import { RequestAlignerRevision } from './forms/RequestAlignerRevision';
import { UploadSoftsmileArtifacts } from './forms/UploadSoftsmileArtifacts';
import type { FragmentType, VeneerAlignerTreatmentPlanStatus_FragmentFragment } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlAlignerPlan } from '@orthly/graphql-schema';
import {
    LabsGqlDesignOrderAlignerTreatmentPlanDoctorReviewStatus,
    LabsGqlDesignOrderAlignerTreatmentPlanSource,
    LabsGqlWorkflowTaskType,
} from '@orthly/graphql-schema';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { UserRoleUtil } from '@orthly/shared-types';
import { FlossPalette, FlossPaletteUtils, stylesFactory, Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    white_button: {
        [`& .MuiButton-label`]: { color: FlossPalette.WHITE },
        [`& .MuiButton-label .MuiButton-endIcon .MuiSvgIcon-root`]: { color: FlossPalette.WHITE },
        [`&:hover`]: { backgroundColor: FlossPaletteUtils.toRgba(`WHITE`, 0.1) },
    },
}));

const AlignerTreatmentPlanApprovalBox: React.VFC<{
    patientName: string;
    userRole: string;
    treatmentPlan: VeneerAlignerTreatmentPlanStatus_FragmentFragment;
    orderId: string;
    refetch: () => Promise<any>;
    isRefinement: boolean;
}> = ({ patientName, userRole, treatmentPlan, orderId, refetch, isRefinement }) => {
    const classes = useStyles();
    const is_practice = UserRoleUtil.is_practice(userRole);
    return (
        <AlignerTreatmentPlanSidebarBox
            style={{ backgroundColor: is_practice ? FlossPalette.BURGUNDY : FlossPalette.WHITE }}
        >
            <Grid container spacing={1} direction={`column`}>
                <Grid item>
                    <Text
                        variant={`h6`}
                        style={{
                            marginBottom: 4,
                            ...(is_practice ? { color: FlossPalette.SECONDARY_BACKGROUND } : {}),
                        }}
                    >
                        Awaiting approval
                    </Text>
                    <Text variant={`body2`} style={{ color: is_practice ? FlossPalette.WHITE : FlossPalette.GRAY }}>
                        This treatment plan is awaiting approval
                    </Text>
                </Grid>
                <Grid item>
                    <ApproveAlignerFabrication
                        patient_name={patientName}
                        order_id={orderId}
                        treatment_plan_fragment={treatmentPlan}
                        refetch={refetch}
                        button_props={is_practice ? { style: { backgroundColor: FlossPalette.WHITE } } : {}}
                        is_refinement={isRefinement}
                    />
                </Grid>
                <Grid item>
                    <RequestAlignerRevision
                        order_id={orderId}
                        refetch={refetch}
                        button_props={is_practice ? { variant: `ghost`, className: classes.white_button } : {}}
                    />
                </Grid>
            </Grid>
        </AlignerTreatmentPlanSidebarBox>
    );
};

const AlignerRevisingStatusBox: React.VFC<{ user_role: string }> = ({ user_role }) => (
    <AlignerTreatmentPlanSidebarBox>
        <Text variant={`h6`}>{UserRoleUtil.is_internal(user_role) ? `Revision request` : `Revision in progress`}</Text>
        <Text variant={`body2`} style={{ color: FlossPalette.GRAY }}>
            {UserRoleUtil.is_internal(user_role)
                ? `A treatment plan revision was requested`
                : `We received your request. A revised treatment plan will be available within 2 business days`}
        </Text>
    </AlignerTreatmentPlanSidebarBox>
);

const AlignerRejectedStatusBox: React.VFC = () => (
    <AlignerTreatmentPlanSidebarBox>
        <Text variant={`h6`}>Rejected revision</Text>
        <Text variant={`body2`} style={{ color: FlossPalette.GRAY }}>
            This treatment plan was not approved for fabrication
        </Text>
    </AlignerTreatmentPlanSidebarBox>
);

const AlignerDandyPlanBox: React.VFC<{
    dandyPlan: LabsGqlAlignerPlan;
    treatmentPlan: VeneerAlignerTreatmentPlanStatus_FragmentFragment;
}> = ({ dandyPlan, treatmentPlan }) => {
    const plans_details = useAlignerPlansDetails();

    return (
        <AlignerTreatmentPlanSidebarBox>
            <AlignerTreatmentPlanSidebarRow heading title={`Dandy Plan`} text={plans_details[dandyPlan].title} />
            <AlignerTreatmentPlanSidebarRow
                title={`Lab Price`}
                text={plans_details[dandyPlan].calculated_price(treatmentPlan)}
            />
            <AlignerTreatmentPlanReference treatment_plan_fragment={treatmentPlan} />
        </AlignerTreatmentPlanSidebarBox>
    );
};

const AlignerApprovedStatusBox: React.VFC<{
    patientName: string;
    dandyPlan: LabsGqlAlignerPlan | null;
    treatmentPlan: VeneerAlignerTreatmentPlanStatus_FragmentFragment;
}> = ({ treatmentPlan, dandyPlan, patientName }) =>
    dandyPlan ? (
        <AlignerDandyPlanBox dandyPlan={dandyPlan} treatmentPlan={treatmentPlan} />
    ) : (
        <AlignerTreatmentPlanSidebarBox>
            <AlignerTreatmentPlanSidebarRow heading title={`Treatment Plan`} text={patientName} />
        </AlignerTreatmentPlanSidebarBox>
    );

export const AlignerTreatmentPlanPatientStatusBox: React.VFC<{
    doctor_name: string;
    patient_name: string;
}> = ({ doctor_name, patient_name }) => (
    <AlignerTreatmentPlanSidebarBox>
        <AlignerTreatmentPlanSidebarRow heading title={`By`} text={doctor_name} />
        <AlignerTreatmentPlanSidebarRow heading title={`For`} text={patient_name} />
    </AlignerTreatmentPlanSidebarBox>
);

const VeneerAlignerTreatmentPlanStatus_Fragment = graphql(`
    fragment VeneerAlignerTreatmentPlanStatus_Fragment on DesignOrderAlignerTreatmentPlanDTO {
        source
        manufacturing_files {
            unconverted_gcs_path
            converted_gcs_path
        }
        version_number_practice
        doctor_review {
            status
        }

        steps_count {
            upper
            lower
            overall
        }

        ...VeneerAlignerTreatmentPlanReference_Fragment
        ...VeneerApproveAlignerFabrication_Fragment
    }
`);

export const AlignerTreatmentPlanStatus: React.VFC<{
    patient_name: string;
    order_id: string;
    treatment_plan_fragment: FragmentType<typeof VeneerAlignerTreatmentPlanStatus_Fragment> | null;
    user_role: string;
    refetch: () => Promise<any>;
    is_refinement: boolean;
    is_current_unfinalized: boolean;
    is_softsmile: boolean;
    lab_id: string;
    status: LabsGqlLabOrderStatus;
    active_task_type?: LabsGqlWorkflowTaskType;
    dandy_plan: LabsGqlAlignerPlan | null;
}> = ({
    user_role,
    patient_name,
    treatment_plan_fragment,
    order_id,
    refetch,
    is_refinement,
    is_current_unfinalized,
    is_softsmile,
    lab_id,
    status,
    active_task_type,
    dandy_plan,
}) => {
    const treatment_plan = getFragmentData(VeneerAlignerTreatmentPlanStatus_Fragment, treatment_plan_fragment);

    if (active_task_type === LabsGqlWorkflowTaskType.CreateTreatmentPlanRequest) {
        return UserRoleUtil.is_internal(user_role) ? (
            <AlignerTreatmentPlanSidebarBox>
                <MarkAlignerPlanRequested orderId={order_id} refetch={refetch} is_softsmile={is_softsmile} />
            </AlignerTreatmentPlanSidebarBox>
        ) : null;
    }

    if (!treatment_plan) {
        return null;
    }

    if (treatment_plan?.doctor_review?.status === LabsGqlDesignOrderAlignerTreatmentPlanDoctorReviewStatus.Approved) {
        return UserRoleUtil.is_internal(user_role) &&
            (!treatment_plan.manufacturing_files.converted_gcs_path ||
                active_task_type === LabsGqlWorkflowTaskType.CreateExternalAlignerFulfillment) ? (
            <AlignerTreatmentPlanSidebarBox>
                {treatment_plan.source === LabsGqlDesignOrderAlignerTreatmentPlanSource.SoftSmile && (
                    <Grid item style={{ marginBottom: 16 }}>
                        <UploadSoftsmileArtifacts
                            orderId={order_id}
                            treatmentPlanUnconvertedUrl={
                                treatment_plan.manufacturing_files.unconverted_gcs_path ?? undefined
                            }
                            refetch={refetch}
                        />
                    </Grid>
                )}

                <Grid item>
                    <MarkExternalAlignerFulfillmentCreated
                        orderId={order_id}
                        refetch={refetch}
                        disabled={!treatment_plan.manufacturing_files.converted_gcs_path}
                        labId={lab_id}
                    />
                </Grid>
            </AlignerTreatmentPlanSidebarBox>
        ) : (
            <AlignerApprovedStatusBox
                dandyPlan={dandy_plan}
                treatmentPlan={treatment_plan}
                patientName={patient_name}
            />
        );
    }

    // We are awaiting approval by the doctor.
    if (active_task_type === LabsGqlWorkflowTaskType.ApproveAlignerFabrication && treatment_plan) {
        // Avoid showing the acceptance box if the order is cancelled.
        return status === LabsGqlLabOrderStatus.Cancelled ? (
            <AlignerTreatmentPlanSidebarBox>
                <AlignerTreatmentPlanSidebarRow heading title={`Treatment Plan`} text={patient_name} />
            </AlignerTreatmentPlanSidebarBox>
        ) : (
            <AlignerTreatmentPlanApprovalBox
                patientName={patient_name}
                userRole={user_role}
                treatmentPlan={treatment_plan}
                refetch={refetch}
                orderId={order_id}
                isRefinement={is_refinement}
            />
        );
    }

    // This is a previously rejected revision -> show the rejection
    if (treatment_plan?.doctor_review?.status === LabsGqlDesignOrderAlignerTreatmentPlanDoctorReviewStatus.Rejected) {
        return is_current_unfinalized ? (
            <AlignerRevisingStatusBox user_role={user_role} />
        ) : (
            <AlignerRejectedStatusBox />
        );
    }

    return null;
};
