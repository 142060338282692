import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';

export const PracticeOrderCountsOverview_Query = graphql(`
    query PalatePracticeOrderCounts($practiceId: String!) {
        practiceOrderCountsOverview(practiceId: $practiceId) {
            InFlightOrders
            TotalOrders
        }
    }
`);

export type PalatePracticeOrderCounts = {
    counts: { InFlightOrders: number; TotalOrders: number } | null;
    loading: boolean;
};

export function usePracticeOrderCountsPalate(practiceId: string): PalatePracticeOrderCounts {
    const { data, loading } = useQuery(PracticeOrderCountsOverview_Query, {
        variables: { practiceId },
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });

    return { loading, counts: data?.practiceOrderCountsOverview ?? null };
}
