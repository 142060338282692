import type { RuleTableDialogProps } from './RuleTableDialog';
import { RuleTableDialog } from './RuleTableDialog';
import { LoadBlocker } from '@orthly/ui';
import { FlossPalette, IconButton, Tooltip, EditIcon, ToggleOffIcon, ToggleOnIcon } from '@orthly/ui-primitives';
import React from 'react';

interface RuleTableRowEnableProps {
    disabled: boolean;
    onClick: () => void;
    submitting: boolean;
}

interface RowActionsProps {
    RuleTableRowEnableProps: RuleTableRowEnableProps;
    RuleTableDialogProps: RuleTableDialogProps;
}

export const RuleTableRowEnable: React.VFC<RuleTableRowEnableProps> = ({ disabled, submitting, onClick }) => {
    return (
        <Tooltip title={disabled ? 'Enable' : 'Disable'}>
            <IconButton
                onClick={onClick}
                disabled={submitting}
                style={{ color: disabled ? FlossPalette.DARK_TAN : FlossPalette.STAR_GRASS }}
            >
                <LoadBlocker blocking={submitting} loader={'circular'}>
                    {disabled ? <ToggleOffIcon /> : <ToggleOnIcon />}
                </LoadBlocker>
            </IconButton>
        </Tooltip>
    );
};

export const RuleTableRowActions: React.VFC<RowActionsProps> = props => {
    const { RuleTableRowEnableProps, RuleTableDialogProps } = props;
    return (
        <>
            <RuleTableRowEnable {...RuleTableRowEnableProps} />
            <Tooltip title={'Edit'}>
                <IconButton onClick={() => RuleTableDialogProps.setOpen(true)}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <RuleTableDialog {...RuleTableDialogProps} />
        </>
    );
};
