import { AddReasonCodeRule } from './components/AddReasonCodeRule';
import { AdditionalInfoRequestAutocomplete } from './components/AdditionalInfoRequestAutocomplete';
import { CategoryAutocomplete } from './components/CategoryAutocomplete';
import { DisplayRulesTable } from './components/DisplayRulesTable';
import type {
    LabsGqlCreateReasonCodeMutationVariables,
    LabsGqlReasonCodeFragment,
    LabsGqlUpdateReasonCodeMutationVariables,
} from '@orthly/graphql-operations';
import {
    useCreateReasonCodeMutation,
    useDeleteReasonCodeRuleMutation,
    useReasonCodeAdditionalInfoRequestsQuery,
    useReasonCodeCategoriesQuery,
    useReasonCodesQuery,
    useUpdateReasonCodeMutation,
} from '@orthly/graphql-react';
import type { LabsGqlCreateReasonCodeCommand } from '@orthly/graphql-schema';
import {
    LabsGqlDisplayRuleTypeEnum,
    LabsGqlFormDisplay,
    LabsGqlReasonCodeFault,
    LabsGqlReasonCodeGroup,
} from '@orthly/graphql-schema';
import { MUITable } from '@orthly/mui-table';
import type { FieldsDefProp } from '@orthly/ui';
import { LoadBlocker, QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { Chip, Button, Grid, Tab, Tabs } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { z } from 'zod';

function useCategorySuggestions() {
    const { data, refetch } = useReasonCodeCategoriesQuery();
    return {
        refetch,
        suggestions: _.sortBy(data?.getReasonCodeCategoriesAndCounts.map(c => ({ value: c.category })) ?? [], e =>
            e.value.toLowerCase(),
        ),
    };
}

function useAdditionalInfoRequestSuggestions() {
    const { data, refetch } = useReasonCodeAdditionalInfoRequestsQuery();
    return {
        refetch,
        suggestions: _.sortBy(
            data?.getReasonCodeAdditionalInfoRequestsAndCounts.map(c => c.additional_info_request) ?? [],
        ),
    };
}

const reasonCodeConfigFormFields = (
    category_suggestions: { value: string }[],
    additional_info_request_suggestions: string[],
): FieldsDefProp<Omit<LabsGqlCreateReasonCodeCommand, 'display_rules' | 'return_rules'>> => {
    return {
        title: { type: 'text', validation: z.string().max(40) },
        group: {
            type: 'select',
            optional: true,
            options: Object.keys(LabsGqlReasonCodeGroup),
        },
        category: {
            type: 'custom',
            optional: true,
            validation: z.string().min(3).max(50),
            component: (formProps: any) => <CategoryAutocomplete suggestions={category_suggestions} {...formProps} />,
        },
        default_fault: {
            type: 'select',
            options: Object.keys(LabsGqlReasonCodeFault),
            optional: true,
        },
        form_display: {
            type: 'select',
            options: Object.keys(LabsGqlFormDisplay),
            optional: true,
        },
        additional_info_request: {
            type: 'custom',
            optional: true,
            validation: z.array(z.string()),
            component: (formProps: any) => (
                <AdditionalInfoRequestAutocomplete suggestions={additional_info_request_suggestions} {...formProps} />
            ),
        },
        internal_only: { type: 'boolean' },
    };
};

type UpdateVars = LabsGqlUpdateReasonCodeMutationVariables['data'];

interface EditReasonCodeProps {
    reasonCode: LabsGqlReasonCodeFragment;
    onSubmit?: () => void;
}

const EditReasonCode: React.FC<EditReasonCodeProps> = props => {
    const { reasonCode } = props;
    const { suggestions: category_suggestions, refetch: refetchCategories } = useCategorySuggestions();
    const { suggestions: additional_info_request_suggestions, refetch: refetchAdditionalInfoRequests } =
        useAdditionalInfoRequestSuggestions();
    const [submitMtn] = useUpdateReasonCodeMutation();
    const mtnSubmitter = (data: UpdateVars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [UpdateVars]>(mtnSubmitter, {
        closeOnComplete: true,
        onSuccess: () => {
            void refetchCategories();
            void refetchAdditionalInfoRequests();
        },
        successMessage: () => ['Reason Code updated!', {}],
    });
    return (
        <QuickForm<Partial<Omit<UpdateVars, 'id'>>>
            disabled={submitting}
            fields={reasonCodeConfigFormFields(category_suggestions, additional_info_request_suggestions)}
            initialValues={{
                title: reasonCode.title,
                group: reasonCode.group,
                category: reasonCode.category,
                default_fault: reasonCode.default_fault,
                form_display: reasonCode.form_display,
                internal_only: reasonCode.internal_only,
                additional_info_request: reasonCode.additional_info_request,
            }}
            dirtySubmitOnly={true}
            onSubmit={async result => {
                // Prevents spurious empty string submission for the fault, form display, and group enums
                const default_fault = result.default_fault || null;
                const form_display = result.form_display || null;
                const group = result.group || null;
                await submit({
                    ...result,
                    default_fault,
                    form_display,
                    group,
                    reason_id: reasonCode.id,
                    additional_info_request: result.additional_info_request ?? reasonCode.additional_info_request,
                });
            }}
        />
    );
};

interface ReasonCodeDetailPanelProps {
    reasonCode: LabsGqlReasonCodeFragment;
    existingReasonCodes: LabsGqlReasonCodeFragment[];
    refetch: () => Promise<unknown>;
}

const ReasonCodeDetailPanel: React.FC<ReasonCodeDetailPanelProps> = props => {
    const [tab, setTab] = React.useState<'edit' | 'displayRules' | 'returnRules'>('edit');
    const [submitMtn, { loading }] = useUpdateReasonCodeMutation();
    const [deleteRule, { loading: deleteLoading }] = useDeleteReasonCodeRuleMutation();
    const summaryForRules = (
        ruleType: LabsGqlDisplayRuleTypeEnum.DisplayRules | LabsGqlDisplayRuleTypeEnum.ReturnRules,
    ) => (
        <DisplayRulesTable
            elementName={props.reasonCode.title}
            rules={props.reasonCode[ruleType]}
            ruleType={ruleType}
            deleteLoading={deleteLoading}
            deleteRule={rule_id => {
                void deleteRule({
                    variables: { data: { rule_id, reason_id: props.reasonCode.id, rule_type: ruleType } },
                });
            }}
            rulesPassWhenEmpty={ruleType === LabsGqlDisplayRuleTypeEnum.DisplayRules}
        />
    );
    return (
        <Grid container style={{ padding: 0, maxWidth: 'calc(100vw - 73px)' }}>
            <Grid container item xs={10}>
                <Tabs
                    indicatorColor={'secondary'}
                    textColor={'inherit'}
                    value={tab}
                    onChange={(_, value) => setTab(value)}
                >
                    <Tab value={'edit'} label={'Edit'} />
                    <Tab value={'displayRules'} label={'Display Rules'} />
                    <Tab value={'returnRules'} label={'Return Rules'} />
                </Tabs>
                <Grid container style={{ padding: 10 }}>
                    {tab === 'edit' && <EditReasonCode reasonCode={props.reasonCode} onSubmit={props.refetch} />}
                    {tab === 'displayRules' && summaryForRules(LabsGqlDisplayRuleTypeEnum.DisplayRules)}
                    {tab === 'returnRules' && summaryForRules(LabsGqlDisplayRuleTypeEnum.ReturnRules)}
                </Grid>
            </Grid>
            <Grid container item xs={2} direction={'column'} style={{ padding: 10 }} wrap={'nowrap'}>
                <LoadBlocker blocking={loading} ContainerProps={{ style: { height: 'fit-content' } }}>
                    <Button
                        fullWidth
                        variant={'contained'}
                        onClick={() => {
                            submitMtn({
                                variables: {
                                    data: {
                                        title: props.reasonCode.title,
                                        reason_id: props.reasonCode.id,
                                        archived: !props.reasonCode.archived,
                                        internal_only: props.reasonCode.internal_only,
                                        additional_info_request: [],
                                    },
                                },
                            }).catch(console.error);
                        }}
                    >
                        {props.reasonCode.archived ? 'Unarchive Reason' : 'Archive Reason'}
                    </Button>
                </LoadBlocker>
                <Grid container>
                    <AddReasonCodeRule
                        reasonCode={props.reasonCode}
                        rule_type={LabsGqlDisplayRuleTypeEnum.DisplayRules}
                        existingReasonCodes={props.existingReasonCodes}
                    />
                </Grid>
                <Grid container>
                    <AddReasonCodeRule
                        reasonCode={props.reasonCode}
                        rule_type={LabsGqlDisplayRuleTypeEnum.ReturnRules}
                        existingReasonCodes={props.existingReasonCodes}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

interface CreateReasonCodeProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onSuccess: () => Promise<unknown>;
}

type Vars = LabsGqlCreateReasonCodeMutationVariables['data'];

const CreateReasonCode: React.FC<CreateReasonCodeProps> = props => {
    const { suggestions: category_suggestions, refetch: refetchCategories } = useCategorySuggestions();
    const { suggestions: additional_info_request_suggestions, refetch: refetchAdditionalInfoRequests } =
        useAdditionalInfoRequestSuggestions();
    const [submitMtn] = useCreateReasonCodeMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Reason Code created!', {}],
        onSuccess: async () => {
            void refetchCategories();
            void refetchAdditionalInfoRequests();
            await props.onSuccess();
        },
    });

    return (
        <RootActionDialog
            title={'Create Reason Code'}
            open={props.open}
            setOpen={props.setOpen}
            loading={submitting}
            buttonText={'Create Reason Code'}
            CustomButton={() => null}
            content={
                <QuickForm<Omit<Vars, 'display_rules' | 'return_rules'>>
                    fields={reasonCodeConfigFormFields(category_suggestions, additional_info_request_suggestions)}
                    initialValues={{
                        additional_info_request: [],
                    }}
                    onSubmit={async formResult => {
                        // Prevents spurious empty string submission for the fault enum
                        const default_fault = formResult.default_fault || undefined;
                        const command: Vars = {
                            ...formResult,
                            default_fault,
                            display_rules: [],
                            return_rules: [],
                        };
                        await submit(command);
                    }}
                />
            }
        />
    );
};

export const ReasonCodesConfigForm: React.FC = () => {
    const { data, refetch } = useReasonCodesQuery();
    const [createOpen, setCreateOpen] = React.useState<boolean>(false);
    const allReasons = data?.listReasonCodes || [];
    return (
        <Grid container>
            <MUITable<LabsGqlReasonCodeFragment>
                title={'Feedback & Refab Reason Codes'}
                data={allReasons}
                displayOptions={{
                    fixedSearch: true,
                    elevation: 0,
                    viewColumns: true,
                    filter: true,
                    sort: true,
                }}
                DetailPanel={detailProps => (
                    <ReasonCodeDetailPanel
                        reasonCode={detailProps.data}
                        existingReasonCodes={allReasons}
                        refetch={refetch}
                    />
                )}
                actions={{
                    global: [
                        { icon: 'refresh', position: 'toolbar', onClick: () => refetch().catch(console.error) },
                        { icon: 'add', position: 'toolbar', onClick: () => setCreateOpen(true), tooltip: 'Create' },
                    ],
                }}
                rowOptions={{ rowHover: true }}
                eventHooks={{ onRowClick: (row, actions) => actions.toggleDetailPanel(row) }}
                columns={[
                    { name: 'title', render: 'title' },
                    {
                        name: 'Form Display',
                        render: row => row.form_display ?? <i>(None)</i>,
                    },
                    {
                        name: 'Group',
                        render: row => row.group ?? <i>(None)</i>,
                    },
                    {
                        name: 'Category',
                        render: row => row.category ?? <i>(None)</i>,
                    },
                    {
                        name: 'Default Fault',
                        render: row => row.default_fault ?? <i>(None)</i>,
                    },
                    { name: 'Internal Only?', render: 'internal_only', type: 'boolean' },
                    {
                        name: 'Has Display Rules',
                        render: row => row.display_rules.length > 0,
                        type: 'boolean',
                    },
                    {
                        name: 'Has Return Rules',
                        render: row => row.return_rules.length > 0,
                        type: 'boolean',
                    },
                    {
                        name: 'Supplemental Info Requested',
                        render: row =>
                            row.additional_info_request.map(text => (
                                <Chip key={text} label={text} style={{ margin: 1 }} />
                            )) ?? <i>(None)</i>,
                    },
                    {
                        name: 'Archived',
                        render: 'archived',
                        type: 'boolean',
                        filterOptions: { defaultValues: ['false'], exact: false, type: 'dropdown' },
                    },
                    { name: 'Last Updated', render: 'updated_at', type: 'datetime' },
                ]}
            />
            <CreateReasonCode
                open={createOpen}
                setOpen={setCreateOpen}
                onSuccess={async () => {
                    await refetch();
                    setCreateOpen(false);
                }}
            />
        </Grid>
    );
};
