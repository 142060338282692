import { CreditAmountInput } from './CreditAmountInput';
import { CreditExpirationInput } from './CreditExpirationInput';
import { NEXT_INVOICE_VALUE } from './CreditOrRefund.types';
import { CreditOrRefundCategorySelect } from './CreditOrRefundCategorySelect';
import { CreditOrRefundNotesInput } from './CreditOrRefundNotesInput';
import { CreditOrRefundSelector } from './CreditOrRefundSelector';
import { OrderItemsSelector } from './OrderItemsSelector';
import { useCreditOrRefundFormContext } from './providers/CreditOrRefundFormProvider.graphql';
import { styled } from '@orthly/ui-primitives';
import React from 'react';

const FormContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '32px 32px',
    gap: '24px',
    overflowY: 'auto',
});

const Inline = styled('div')({
    display: 'flex',
    gap: '16px',
    width: '100%',
});

export const CreditOrRefundForm: React.FC = () => {
    const { formState, order } = useCreditOrRefundFormContext();

    const shouldShowCreditAmountInput = formState.action === 'credit' && !formState.attributed;
    const shouldShowCreditExpirationInput = formState.action === 'credit' && formState.applyOn === NEXT_INVOICE_VALUE;

    return (
        <FormContainer>
            <CreditOrRefundSelector />
            <Inline>
                {shouldShowCreditAmountInput && <CreditAmountInput />}
                <CreditOrRefundCategorySelect />
                {shouldShowCreditExpirationInput && formState.attributed && <CreditExpirationInput />}
            </Inline>
            {shouldShowCreditExpirationInput && !formState.attributed && <CreditExpirationInput />}
            <CreditOrRefundNotesInput />
            {order && <OrderItemsSelector order={order} />}
        </FormContainer>
    );
};
