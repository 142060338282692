import { useFirebasePreviewMulti } from '../../hooks/useFirebaseDownload';
import { DandyLightbox } from '../DandyLightbox/DandyLightbox';
import { FileUploaderBulk } from '../FirebaseUpload/FileUploaderBulk';
import {
    LabOrderImageTypeTitleMap,
    isImplantOrder,
    useOrderDetailImages,
    type OrderDetailImage,
    type OrderDetailImageExists,
} from './OrderDetail.util';
import { OrderDetailBlock } from './OrderDetailBlock';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useAddLabOrderPhotosMutation, useOrder } from '@orthly/graphql-react';
import type { LabsGqlLabOrderSinglePhotoInput } from '@orthly/graphql-schema';
import { LabsGqlLabOrderPhotoType } from '@orthly/graphql-schema';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import {
    LoadBlocker,
    OrthlyBrowserConfig,
    PencilOutlinedIcon,
    RootActionDialog,
    WarningIcon,
    useChangeSubmissionFn,
} from '@orthly/ui';
import type { GridProps } from '@orthly/ui-primitives';
import { Button, FlossPalette, Text, stylesFactory, Grid, IconButton, Icon } from '@orthly/ui-primitives';
import cx from 'classnames';
import _ from 'lodash';
import React from 'react';

const PREVIEW_SIZE = 120;
const useStyles = stylesFactory(() => ({
    image: {
        backgroundSize: `cover`,
        width: PREVIEW_SIZE,
        height: PREVIEW_SIZE,
        cursor: `pointer`,
        borderRadius: 18,
        boxShadow: `0 0 3px rgba(0,0,0,0.16)`,
        marginBottom: 9,
        display: `inline-flex`,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    imageContainer: {
        display: 'inline-flex',
        flexDirection: 'column',
        margin: 12,
    },
    imageDeleted: {
        opacity: 0.3,
    },
}));

interface ImgPreviewProps {
    imgSrc?: string;
    name: string;
    onSelect?: () => unknown;
    isDeleted: boolean;
    loading?: boolean;
}

export const ImgPreview: React.FC<ImgPreviewProps> = ({ imgSrc, name, isDeleted, onSelect, loading }) => {
    const classes = useStyles();
    return (
        <div className={classes.imageContainer}>
            <div
                style={
                    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                    // eslint-disable-next-line no-nested-ternary
                    imgSrc
                        ? {
                              backgroundImage: `url("${imgSrc}")`,
                          }
                        : loading
                          ? { backgroundColor: FlossPalette.TAN, pointerEvents: 'none' }
                          : { backgroundColor: FlossPalette.LIGHT_YELLOW, pointerEvents: 'none' }
                }
                className={cx(classes.image, {
                    [classes.imageDeleted]: isDeleted,
                })}
                tabIndex={0}
                aria-label={name}
                onClick={onSelect}
            >
                {!imgSrc && !loading && (
                    <>
                        <WarningIcon />
                        <span>Image missing</span>
                    </>
                )}
            </div>
            <Text variant={'body2'}>{name}</Text>
        </div>
    );
};

export function orderDetailImageExists(image: OrderDetailImage): image is OrderDetailImageExists {
    // NOTE: look for missing and optional photos
    return !image.isMissing && Boolean(image.source);
}

interface OrderDetailsImagesContentProps {
    sources: OrderDetailImage[];
    wrapperProps?: GridProps;
    disablePhotoDownload?: boolean;
    orderId: string;
}

export const OrderDetailImagesContent: React.VFC<OrderDetailsImagesContentProps> = ({
    sources,
    wrapperProps,
    disablePhotoDownload,
    orderId,
}) => {
    const [sourcesWithExistingImage, sourcesWithMissingImage] = _.partition(sources, orderDetailImageExists);
    // we set the source to be the name so that we can associate the returned source (authenticated url) with the original source (path within bucket)
    const { result, loading } = useFirebasePreviewMulti(
        sourcesWithExistingImage.map(({ source }) => ({ source, name: source })),
        true,
    );
    const [selected, setSelected] = React.useState<string | undefined>();

    if (sources.length === 0) {
        return null;
    }

    return (
        <>
            <LoadBlocker
                blocking={loading}
                ContainerProps={{
                    wrap: 'wrap',
                    direction: 'row',
                    ...wrapperProps,
                }}
            >
                {sourcesWithExistingImage.map(source => {
                    const imgSrc = result?.find(res => res.name === source.source)?.source;
                    return (
                        <ImgPreview
                            loading={loading}
                            key={source.source}
                            name={source.title}
                            isDeleted={!!source.isDeleted}
                            imgSrc={imgSrc}
                            onSelect={() => setSelected(source.source)}
                        />
                    );
                })}
                {sourcesWithMissingImage.map((source, i) => {
                    return (
                        <ImgPreview
                            loading={loading}
                            key={i}
                            name={source.title}
                            isDeleted={!!source.isDeleted}
                            imgSrc={undefined}
                        />
                    );
                })}
            </LoadBlocker>
            <DandyLightbox
                setSelectedAttachmentPreview={setSelected}
                previews={result}
                selectedAttachmentPreview={selected}
                disablePhotoDownload={disablePhotoDownload}
                onPhotoViewedAnalytics={(source, name) => {
                    return {
                        name: 'All - Portal - Photo Lightbox Viewed',
                        data: {
                            $groups: { order: orderId },
                            displayLocation: 'order_body_doctor_photos',
                            photoSource: source,
                            photoName: name,
                        },
                    };
                }}
            />
        </>
    );
};

interface OrderDetailImagesBlockProps {
    label: string;
    sources: OrderDetailImage[];
    refetchOrder?: () => Promise<any>;
    actions?: React.ReactNode;
    disablePhotoDownload?: boolean;
    orderId: string;
}

export const OrderDetailImagesBlock: React.VFC<OrderDetailImagesBlockProps> = ({
    label,
    sources,
    refetchOrder,
    actions,
    disablePhotoDownload,
    orderId,
}) => {
    if (sources.length === 0) {
        return null;
    }

    return (
        <OrderDetailBlock
            title={label}
            variant={'full'}
            innerRootStyle={{ flexDirection: 'row' }}
            contentStyle={{ width: `100%`, flexGrow: 0, overflowX: `auto`, overflowY: 'hidden' }}
            actions={
                <>
                    {actions}
                    {refetchOrder && (
                        <IconButton style={{ padding: 0 }} onClick={() => refetchOrder && refetchOrder()}>
                            <Icon icon={'RefreshIcon'} />
                        </IconButton>
                    )}
                </>
            }
        >
            <OrderDetailImagesContent
                sources={sources}
                wrapperProps={{ style: { whiteSpace: `nowrap`, paddingTop: 16, marginLeft: -11 } }}
                disablePhotoDownload={disablePhotoDownload}
                orderId={orderId}
            />
        </OrderDetailBlock>
    );
};

interface OrderDetailShadeMatchProps {
    imageUrls: string[];
    orderId: string;
    refetchOrder?: () => Promise<any>;
}

export const OrderDetailShadeMatchBlock: React.VFC<OrderDetailShadeMatchProps> = props => {
    const sources = React.useMemo<OrderDetailImage[]>(() => {
        return props.imageUrls
            .filter(s => s.includes('measure'))
            .map<OrderDetailImage>((source, idx) => ({ source, title: `Shade match #${idx + 1}` }));
    }, [props.imageUrls]);
    return (
        <OrderDetailImagesBlock
            orderId={props.orderId}
            refetchOrder={props.refetchOrder}
            sources={sources}
            label={'Shade match images'}
        />
    );
};

interface OrderDetailDoctorPhotosBlockProps {
    order: LabsGqlLabOrderFragment;
    refetchOrder?: () => Promise<any>;
    isPSR: boolean;
    disablePhotoDownload?: boolean;
    label?: string;
}

interface UploadOrderPhotosDialogProps {
    order_id: string;
    scan_export_id: string;
    editing: boolean;
    setEditing: (val: boolean) => void;
    refetchOrder?: () => Promise<any>;
}

type DoctorPhotoMap = { [k in LabsGqlLabOrderPhotoType]?: string };

export const UploadOrderPhotosDialog: React.VFC<UploadOrderPhotosDialogProps> = ({
    order_id,
    scan_export_id,
    editing,
    setEditing,
    refetchOrder,
}) => {
    const [rawSubmit] = useAddLabOrderPhotosMutation();
    const [photoMap, setPhotoMap] = React.useState<DoctorPhotoMap>({});
    const { submit, submitting } = useChangeSubmissionFn(rawSubmit, {
        closeOnComplete: true,
        successMessage: () => [`Order photos uploaded!`, {}],
        onSuccess: () => {
            setEditing(false);
            void refetchOrder?.();
        },
    });
    const implantImageStorageConfig = getFullStoragePath(
        OrthlyBrowserConfig.env,
        OrderingStorageConfigs.implants,
        scan_export_id,
    );
    return (
        <RootActionDialog
            setOpen={setEditing}
            open={editing}
            title={'Update Order Photos'}
            CustomButton={() => null}
            content={
                <Grid container style={{ justifyContent: 'center' }}>
                    {Object.values(LabsGqlLabOrderPhotoType).map(type => (
                        <div style={{ marginBottom: 16, width: '100%' }} key={type}>
                            <Text variant={'body2'} style={{ marginBottom: 8 }}>
                                {LabOrderImageTypeTitleMap[type]}
                            </Text>
                            <FileUploaderBulk
                                autoSubmit
                                deleteOnReset
                                processImages
                                storagePathConfig={implantImageStorageConfig}
                                onComplete={results => {
                                    if (!results[0]) {
                                        window.alert('Unable to upload image, try again.');
                                        return;
                                    }
                                    setPhotoMap(prevMap => ({ ...prevMap, [type]: results[0]?.uploadedPath }));
                                }}
                                prependTimestampToFilename={true}
                            />
                        </div>
                    ))}
                    <Button
                        variant={'primary'}
                        style={{ width: '100%' }}
                        onClick={() => {
                            const photos: LabsGqlLabOrderSinglePhotoInput[] = Object.values(
                                LabsGqlLabOrderPhotoType,
                            ).flatMap(type => {
                                const fullPath = photoMap[type];
                                return !!fullPath ? [{ type, fullPath }] : [];
                            });
                            void submit({ variables: { data: { order_id, photos } } });
                        }}
                    >
                        Submit
                    </Button>
                </Grid>
            }
            loading={submitting}
        />
    );
};

export const OrderDetailDoctorPhotosBlock: React.VFC<OrderDetailDoctorPhotosBlockProps> = ({
    order,
    refetchOrder,
    isPSR,
    disablePhotoDownload,
    label,
}) => {
    const [editing, setEditing] = React.useState(false);
    const sources = useOrderDetailImages(order, isPSR);

    const actions = (
        <>
            {isImplantOrder(order) && isPSR ? (
                <IconButton style={{ padding: 0 }} onClick={() => setEditing(true)}>
                    <PencilOutlinedIcon />
                </IconButton>
            ) : null}
        </>
    );

    if (sources.length === 0) {
        return null;
    }

    return (
        <Grid item style={{ display: `flex`, flexBasis: 0, flexGrow: 1 }}>
            <OrderDetailImagesBlock
                refetchOrder={refetchOrder}
                sources={sources}
                label={label ?? 'Doctor photos'}
                actions={actions}
                disablePhotoDownload={disablePhotoDownload}
                orderId={order.id}
            />
            <UploadOrderPhotosDialog
                order_id={order.id}
                scan_export_id={order.scan_export_id}
                editing={editing}
                setEditing={setEditing}
                refetchOrder={refetchOrder}
            />
        </Grid>
    );
};

// TODO: Do we even still need this? Don't we copy over all the photos when we C&R now?
// If a given order was refabbed, this block shows the photos associated with the original non-refabbed order.
export const OrderDetailOriginalPhotosBlock: React.VFC<OrderDetailDoctorPhotosBlockProps> = ({
    order,
    isPSR,
    disablePhotoDownload,
}) => {
    const { order: originalOrder, refetch: refetchOriginalOrder } = useOrder(order.original_order_id, {
        skip: !order.original_order_id,
    });
    if (!originalOrder) {
        return null;
    }
    if (originalOrder.original_order_id) {
        return (
            <OrderDetailOriginalPhotosBlock
                order={originalOrder}
                refetchOrder={refetchOriginalOrder}
                isPSR={isPSR}
                disablePhotoDownload={disablePhotoDownload}
            />
        );
    }
    return (
        <OrderDetailDoctorPhotosBlock
            order={originalOrder}
            refetchOrder={refetchOriginalOrder}
            isPSR={isPSR}
            disablePhotoDownload={disablePhotoDownload}
            label={'Original Order Photos'}
        />
    );
};
