import { RefabBodyContainer } from '../components/RefabBodyContainer';
import { RefabShadeAttachments } from '../components/RefabShadeAttachments';
import { useGetDisplayStringOfItemWithShadeUpdates } from '../utils';
import { useRefabFlowContext } from './refab-flow-context';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { Text, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

export const ItemShadeAttachments: React.VFC = () => {
    const { order, isOps } = useRefabFlowContext();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const itemNamesDisplayString = useGetDisplayStringOfItemWithShadeUpdates({ shouldAlwaysReturnAllNames: true });

    React.useEffect(() => {
        BrowserAnalyticsClientFactory.Instance?.track(
            `${isOps ? 'Ops' : 'Practice'} - Refab Flow - Shade Capture Entered`,
            {
                $groups: { order: order?.id ?? 'unknown' },
            },
        );
    }, [order, isOps]);

    const [isUploading, setIsUploading] = React.useState(false);

    return (
        <RefabBodyContainer
            title={
                <>
                    <Text variant={'body1'} color={'GRAY'} medium>
                        You noted that{' '}
                        <Text variant={'body1'} color={'SECONDARY_FOREGROUND'} medium style={{ display: 'inline ' }}>
                            {itemNamesDisplayString}'s shades are incorrect
                        </Text>
                    </Text>
                    <Text variant={isMobile ? 'h5' : 'h3'} medium style={{ marginTop: '8px' }}>
                        Upload a patient photo
                    </Text>
                </>
            }
            disableNext={isUploading}
            nextTitle={isUploading ? 'Uploading...' : 'Next'}
        >
            <RefabShadeAttachments onUploadingChange={setIsUploading} />
        </RefabBodyContainer>
    );
};
