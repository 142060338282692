import { useAbutmentPartsMap } from '../../../../context';
import type { IMergedItem, IBaseItemData } from '../../types';
import { isImplantItem } from '../../types';
import * as ItemUtils from '../../utils/item.util';
import { getHeaderForLabSlipItem } from '../../utils/shared.utils';
import { BaseItem } from './BaseItem';
import { PrintableSlipItemDetail } from './PrintableSlipItemDetail';
import { SingleToothUnitItemBody } from './SingleToothUnitItem';
import type { LabsGqlAbutmentPartDto } from '@orthly/graphql-schema';
import type { IOrderItemV2DTO } from '@orthly/items';
import {
    type IImplantItem,
    type IImplantBridgeItem,
    OrderItemV2Utils,
    LabOrderItemSKUType,
    ImplantItemQuantitiesUtils,
} from '@orthly/items';
import _ from 'lodash';
import React from 'react';

/**
 * Renders an "Implant" order item to display on
 * the Printable Slip
 * @param props The order item to render
 * @returns Rendered order item details for the order "Implant" item
 */
export const ImplantItem: React.VFC<{
    mergedItem: IMergedItem;
    allItems: IOrderItemV2DTO[];
}> = ({ mergedItem, allItems }) => {
    const abutmentPartId = isImplantItem(mergedItem.defaultItem)
        ? mergedItem.defaultItem.unit?.implant_metadata?.part_id
        : undefined;
    const abutmentPartsMap = useAbutmentPartsMap();
    const abutmentPart = abutmentPartsMap.get(abutmentPartId ?? '');
    const itemData = mapImplantItemToPrintableSlipData(mergedItem, allItems, abutmentPart);
    return <BaseItem itemData={itemData} />;
};

/**
 * Pull data needed for printable slip display into a standard data structure
 * @param mergedItem Implant order item within a merged item to display
 * @param order Design data on the order
 * @param abutmentPartData data relating to the abutment parts for this item
 * @returns Structured data for implant item display
 */
function mapImplantItemToPrintableSlipData(
    mergedItem: IMergedItem,
    allItems: IOrderItemV2DTO[],
    abutmentPartData: LabsGqlAbutmentPartDto | undefined,
): IBaseItemData | null {
    const [firstImplant, ...otherImplants]: IImplantItem[] = mergedItem.groupItems.filter(isImplantItem);

    if (!firstImplant) {
        return null;
    }

    return {
        headerLeft: getHeaderForLabSlipItem(mergedItem),
        ...getImplantDisplayBodyData([firstImplant, ...otherImplants], allItems, abutmentPartData),
    };
}

type ImplantOrBridgeItem = IImplantItem | IImplantBridgeItem;

export function getImplantDisplayBodyData(
    groupItems: [ImplantOrBridgeItem, ...ImplantOrBridgeItem[]],
    allItems: IOrderItemV2DTO[],
    abutmentPartData: LabsGqlAbutmentPartDto | undefined,
): Pick<IBaseItemData, 'headerRight' | 'itemFields'> {
    const [defaultItem] = groupItems;
    const defaultUnit = OrderItemV2Utils.itemIsType(defaultItem, LabOrderItemSKUType.Implant)
        ? defaultItem.unit
        : defaultItem.implants[0];

    // Should never happen, but performed as a precaution.
    if (!defaultUnit) {
        return {};
    }

    const abutmentPartQuantity = ImplantItemQuantitiesUtils.getAbutmentPartQuantity(groupItems);
    const extraScrewQuantity = ImplantItemQuantitiesUtils.getExtraScrewQuantity(groupItems, allItems);

    const { crown, abutment, implant_metadata } = defaultUnit;

    const headerRight = implant_metadata.relationship;

    const linkConnection = implant_metadata.connection_size;
    const isHealingCap = crown.unit_type === 'HealingCap';

    const implantManufacturerRow = { key: 'Implant Mfr.', value: implant_metadata.manufacturer };
    const implantSystemRow = { key: 'System', value: implant_metadata.system };
    const connectionRow = linkConnection && { key: 'Connection', value: linkConnection };
    const abutmentRow = abutment && { key: 'Abutment', value: abutment.material ?? '' };
    const abutmentSkuRow = abutmentPartData?.sku && {
        key: 'Abutment Sku',
        value: `# ${abutmentPartData.sku}, quantity ${abutmentPartQuantity}`,
    };
    const abutmentManufacturerRow = abutmentPartData?.manufacturer && {
        key: 'Abutment Mfr.',
        value: `${abutmentPartData.manufacturer}`,
    };
    const screwSkuRow = {
        key: 'Screw SKU',
        // This may render `0 extra`, that is fine and intentional - we want to be explicit on the quantity.
        value: `${abutmentPartData?.screw_sku ?? 'TBD'}, ${extraScrewQuantity} extra`,
    };
    const analogSkuRow = abutmentPartData?.analog_sku && {
        key: 'Analog Sku',
        value: `# ${abutmentPartData.analog_sku}, quantity ${abutmentPartQuantity}`,
    };
    const healingCapRow = isHealingCap && { key: 'Healing Cap', value: crown.material ?? '' };
    const crownRow = !isHealingCap && {
        customComponent: (
            <>
                <PrintableSlipItemDetail title={'Crown'} value={''} />
                <SingleToothUnitItemBody
                    material={crown.material ?? ''}
                    shades={defaultItem.shades}
                    occlusalStainingPreference={ItemUtils.getOcclusalStainingPreference(defaultItem)}
                    maximumModelHeight={ItemUtils.getMaximumModelElementHeightInMm(defaultItem)}
                    puckHeight={ItemUtils.getPuckHeight(defaultItem)}
                />
            </>
        ),
    };

    return {
        headerRight,
        itemFields: _.compact([
            implantManufacturerRow,
            implantSystemRow,
            connectionRow,
            abutmentRow,
            abutmentSkuRow,
            abutmentManufacturerRow,
            screwSkuRow,
            analogSkuRow,
            healingCapRow,
            crownRow,
        ]),
    };
}
