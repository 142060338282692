import { ReviewDetailCell } from '../components/ReviewDetailCell';
import { SummarySectionHeader } from '../components/SummarySectionHeader';
import { SummaryStepResultRowValue } from '../components/SummaryStepResultRowValue';
import { summaryRowsForGroup } from '../utils/summaryRowsForGroup';
import { OrderItemMultiLineRow } from './OrderItemMultiLineRow';
import { CartItemV2Utils } from '@orthly/items';
import type { ICartItemV2DTOWithId } from '@orthly/items';
import { FlossPalette, stylesFactory, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    rowWrapper: {
        backgroundColor: FlossPalette.TAN,
        borderRadius: 16,
        border: `1px solid ${FlossPalette.DARK_TAN}`,
        marginBottom: 16,
        padding: '24px 38px',
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    header: {
        fontWeight: 600,
    },
    contentsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    infoPrefWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    notesWrapper: {
        marginTop: 16,
        borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
        paddingTop: 24,
        [theme.breakpoints.down('md')]: {
            borderTop: 'none',
        },
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            paddingBottom: 24,
            paddingTop: 24,
            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
        },
    },
    itemDataFieldSection: {
        marginBottom: 8,
    },
    verticalDivider: {
        borderLeft: `1px solid ${FlossPalette.DARK_TAN}`,
        margin: '0 30px',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    summarySectionHeaderWrapper: {
        width: '20%',
        [theme.breakpoints.down('md')]: {
            marginBottom: 16,
            width: '100%',
        },
    },
}));

interface SummaryItemProps {
    items: ICartItemV2DTOWithId[][];
    originalItemMap: Record<string, ICartItemV2DTOWithId>;
}

export const OrderSummaryItems: React.VFC<SummaryItemProps> = ({ items, originalItemMap }) => {
    const classes = useStyles();
    return (
        <>
            {items.map(item => {
                const [firstItem] = item;
                if (!firstItem) {
                    return null;
                }
                const rows = summaryRowsForGroup([firstItem], originalItemMap);
                return (
                    <div key={`${rows.teeth.title}`} className={classes.rowWrapper}>
                        <div className={classes.summarySectionHeaderWrapper}>
                            <SummarySectionHeader
                                title={CartItemV2Utils.getItemGroupDisplayName(item)}
                                productUnitType={CartItemV2Utils.getProductUnitType(firstItem)}
                                hideImageBorder={true}
                            />
                        </div>
                        <div className={classes.contentsWrapper}>
                            <div className={classes.infoPrefWrapper}>
                                <div className={classes.contentWrapper}>
                                    <Text className={classes.header} variant={'body2'} color={'GRAY'}>
                                        GENERAL INFO
                                    </Text>
                                    {_.compact([rows.material, rows.shade, rows.implantInfo]).map(row => (
                                        <ReviewDetailCell
                                            key={row.title}
                                            title={row.title}
                                            value={
                                                <SummaryStepResultRowValue
                                                    value={row.value}
                                                    originalValue={row.originalValue}
                                                    multiline={row.multiline}
                                                />
                                            }
                                        />
                                    ))}
                                </div>

                                <div className={classes.verticalDivider} />
                                <div className={classes.contentWrapper}>
                                    {rows.itemDataFields && (
                                        <div className={classes.itemDataFieldSection}>
                                            <Text className={classes.header} variant={'body2'} color={'GRAY'}>
                                                ITEM DETAILS
                                            </Text>
                                            <OrderItemMultiLineRow row={rows.itemDataFields} />
                                        </div>
                                    )}
                                    <Text className={classes.header} variant={'body2'} color={'GRAY'}>
                                        PREFERENCES
                                    </Text>
                                    {rows.preferences && <OrderItemMultiLineRow row={rows.preferences} />}
                                </div>
                            </div>
                            <div className={classes.notesWrapper}>
                                <Text className={classes.header} variant={'body2'} color={'GRAY'}>
                                    {`${CartItemV2Utils.getItemGroupDisplayName(
                                        item,
                                    ).toLocaleUpperCase()} INSTRUCTIONS`}
                                </Text>
                                {firstItem.item_notes}
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};
