import type { UpdateNotifierInternalMessage } from '@orthly/shared-types';
import { OrthlyBrowserConfig } from '@orthly/ui';
import type { Channel as PusherChannel } from 'pusher-js';
import { default as Pusher } from 'pusher-js';

interface ChannelEntry {
    attached: boolean;
    pusherChan?: PusherChannel;
    subscribers: Set<(data: any) => Promise<void>>;
}

class OperationQueue {
    private readonly tasks: (() => Promise<void>)[] = [];
    private isProcessorRunning = false;

    enqueue(task: () => Promise<void>) {
        this.tasks.push(task);
        if (!this.isProcessorRunning) {
            this.isProcessorRunning = true;
            setTimeout(() => void this.processQueue(), 16);
        }
    }

    private async processQueue() {
        while (true) {
            const task = this.tasks.shift();
            if (task) {
                await task().catch(error => {
                    console.error(error);
                });
            } else {
                this.isProcessorRunning = false;
                break;
            }
        }
    }
}

/**
 * Wraps the pusher client and channel abstractions to avoid race conditions that exist
 * within the pusher client. Also performs reference counting in case the same channel in used
 * in multiple places across the app.
 *
 * Note: the key here is to perform subscribe/unsubscribe operations serially, because subscribe
 * requires a server-side acknowledgement.
 */
class PusherWrapper {
    private readonly channels = new Map<string, ChannelEntry>();
    private readonly operationQueue = new OperationQueue();
    private readonly pusher = new Pusher(OrthlyBrowserConfig.pusherKey, {
        cluster: 'us2',
    });

    getActiveChannels() {
        return [...this.channels.entries()].map(([name, { attached }]) => ({
            name,
            attached,
        }));
    }

    setLoggingEnabled(enabled: boolean) {
        Pusher.logToConsole = enabled;
    }

    /**
     * Subscribes to the 'message' event on the given channel.
     */
    subscribe(channelName: string, onMessage: (data: any) => Promise<void>) {
        const channelList = this.channels.get(channelName) ?? {
            attached: false,
            pusherChan: undefined,
            subscribers: new Set(),
        };
        this.operationQueue.enqueue(
            () =>
                new Promise<void>((resolve, reject) => {
                    const chan = this.pusher.subscribe(channelName);
                    channelList.pusherChan = chan;
                    chan.bind('message', (message: UpdateNotifierInternalMessage) => {
                        for (const subscriber of channelList.subscribers) {
                            void subscriber(message);
                        }
                    });
                    chan.bind('pusher:subscription_succeeded', () => {
                        channelList.attached = true;
                        resolve();
                    });
                    chan.bind('pusher:subscription_error', reject);
                }),
        );
        this.channels.set(channelName, channelList);
        channelList.subscribers.add(onMessage);

        return {
            unsubscribe: () => {
                channelList.subscribers.delete(onMessage);

                if (channelList.subscribers.size === 0) {
                    this.channels.delete(channelName);
                    this.operationQueue.enqueue(async () => {
                        channelList.attached = false;
                        channelList.pusherChan?.unsubscribe();
                        await new Promise(resolve => setTimeout(resolve, 100));
                    });
                }
            },
        };
    }
}

export const pusherClient = new PusherWrapper();
if (OrthlyBrowserConfig.isDevelopment && (global as any).window) {
    (window as any).PusherClient = pusherClient;
}
