import {
    createStyles,
    makeStyles,
    Grid,
    TablePrimitive as Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Text,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const BORDER_COLOR = '#e8e8ed';
const useStyles = makeStyles(() =>
    createStyles({
        printFormRow: {
            border: `1px solid ${BORDER_COLOR}`,
            // borderTop: 'none',
            padding: 10,
            width: '100%',
            '&:nth-of-type(2)': {
                borderTop: `1px solid ${BORDER_COLOR}`,
            },
            '& .MuiTableRow-root:not(.MuiTableRow-head):last-of-type .MuiTableCell-root': {
                borderBottom: `none !important`,
            },
            '@media print': {
                display: 'block',
                pageBreakInside: 'avoid',
            },
        },
        distributedCellTable: {
            '& .MuiTableCell-root': {
                textAlign: 'center',
                '&:first-of-type': {
                    textAlign: 'left',
                },
                '&:last-of-type': {
                    textAlign: 'right',
                },
            },
        },
    }),
);

interface PrintTableProps<R extends { [k: string]: React.ReactNode }> {
    rows: R[];
    title?: React.ReactNode;
    rootStyle?: React.CSSProperties;
    distributeCells?: boolean;
    headerStyle?: React.CSSProperties;
    headerCellStyle?: React.CSSProperties;
    bodyCellStyle?: React.CSSProperties;
    calculateBodyCellStyle?: (headerVal: string, cellVal: string, row: R) => React.CSSProperties | undefined;
    bodyCellStyleByHeader?: {
        [K in keyof R]?: React.CSSProperties;
    };
    children?: React.ReactNode;
    disableHeaderFormatting?: boolean;
    displayEmpty?: boolean;
}

export function PrintTable<R extends { [k: string]: any }>(props: PrintTableProps<R>) {
    const { rows, title } = props;
    const classes = useStyles();
    const firstRow = rows[0];
    if (!firstRow && !props.displayEmpty) {
        return null;
    }
    const headers = Object.keys(firstRow ?? {});
    return (
        <Grid item xs={12} className={`${classes.printFormRow} print-row`} style={props.rootStyle}>
            {title && (
                <Grid container alignItems={'center'} style={{ pageBreakInside: 'avoid' }}>
                    {typeof title === 'string' ? <Text variant={'h6'}>{title}</Text> : title}
                </Grid>
            )}
            <TableContainer>
                <Table className={props.distributeCells ? classes.distributedCellTable : undefined}>
                    <TableHead>
                        <TableRow style={props.headerStyle}>
                            {headers.map(h => (
                                <TableCell
                                    key={h}
                                    style={{ padding: 5, whiteSpace: 'pre-line', ...props.headerCellStyle }}
                                    variant={'head'}
                                >
                                    {props.disableHeaderFormatting ? h : _.startCase(h)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, idx) => (
                            <TableRow key={idx}>
                                {headers.map((key, valIdx) => {
                                    const val = row[key] || '-';
                                    const colStyle = props?.bodyCellStyleByHeader?.[key];
                                    const calculatedStyle = props.calculateBodyCellStyle?.(
                                        key,
                                        typeof val === 'string' ? val : '',
                                        row,
                                    );
                                    return (
                                        <TableCell
                                            key={`${valIdx}-${key}`}
                                            style={{
                                                padding: 5,
                                                whiteSpace: 'pre-line',
                                                ...props.bodyCellStyle,
                                                ...colStyle,
                                                ...calculatedStyle,
                                            }}
                                        >
                                            {val}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {props.children}
        </Grid>
    );
}
