import { PRINTABLE_SLIP_FADED_TEXT_COLOR } from '../../styles/colors';
import { DEFUALT_VALUE_TEXT } from '../../types';
import type { LabsGqlLabOrderItemShade } from '@orthly/graphql-schema';
import type { IOrderItemShade } from '@orthly/items';
import {
    Text,
    TablePrimitive as Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    makeStyles,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles({
    shadeTable: {
        '& th, & td': {
            border: 'none',
            paddingLeft: '0',
            paddingRight: '1rem',
            width: '25%',
        },
    },
    fadedText: {
        color: `${PRINTABLE_SLIP_FADED_TEXT_COLOR} !important`,
    },
    baseFont: {
        fontSize: `0.9rem !important`,
        lineHeight: '0.5 !important',
    },
});

/**
 * Get the actual shade value for a specific part of the item
 * (gingival, base, incisal, stump)
 * @param shades List of shades on the item
 * @param name The name of the shade whose value to retrieve (gingival, base, incisal, stump)
 * @returns The shade value (defaults to '--')
 */
function getShadeValue(shades: LabsGqlLabOrderItemShade[], name: string): string {
    return shades.find(shade => shade.name.toLowerCase() === name.toLowerCase())?.value || DEFUALT_VALUE_TEXT;
}

/**
 * Renders the table containing the shades details for an order item
 * when rendering the item information on the Printable Slip
 * @param item The order item whose shades to display
 * @returns Rendered table displaying the shade information of an order item
 */
export const ItemShadesTable: React.VFC<{ shades: IOrderItemShade[] }> = ({ shades }) => {
    const classes = useStyles();
    return (
        <TableContainer>
            <Table size={'small'} className={classes.shadeTable}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Text variant={'h6'} className={`${classes.baseFont} ${classes.fadedText}`}>
                                Gingival
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant={'h6'} className={`${classes.baseFont} ${classes.fadedText}`}>
                                Base
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant={'h6'} className={`${classes.baseFont} ${classes.fadedText}`}>
                                Incisal
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant={'h6'} className={`${classes.baseFont} ${classes.fadedText}`}>
                                Stump
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Text variant={'h5'} className={classes.baseFont}>
                                {getShadeValue(shades, 'gingival')}
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant={'h5'} className={classes.baseFont}>
                                {getShadeValue(shades, 'base')}
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant={'h5'} className={classes.baseFont}>
                                {getShadeValue(shades, 'incisal')}
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text variant={'h5'} className={classes.baseFont}>
                                {getShadeValue(shades, 'stump')}
                            </Text>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
