import { IconButton, Icon, Text } from '@orthly/ui-primitives';
import React from 'react';

export const PaginationFooter: React.FC<{ hasNextPage: boolean; page: number; setPage(page: number): void }> = ({
    hasNextPage,
    page,
    setPage,
}) => {
    return (
        <div
            style={{
                marginTop: 10,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <IconButton size={'small'} style={{ minWidth: 0 }} disabled={page === 0} onClick={() => setPage(page - 1)}>
                <Icon icon={'KeyboardArrowLeft'} />
            </IconButton>
            <Text variant={'body2'} style={{ margin: '0 16px' }}>
                Page {page + 1}
            </Text>
            <IconButton
                size={'small'}
                style={{ minWidth: 0 }}
                disabled={!hasNextPage}
                onClick={() => setPage(page + 1)}
            >
                <Icon icon={'KeyboardArrowRight'} />
            </IconButton>
        </div>
    );
};
