import { Table } from '@orthly/ui';
import { Button, FlossPalette, Input, styled } from '@orthly/ui-primitives';

export const FullWidthContainer = styled('div')({
    width: '100%',
});

export const TableCell = styled(Table.Cell)({
    padding: '4px 0px',
});

export const TableHeaderRow = styled(Table.Row)({
    borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
});

export const TableBodyRow = styled(Table.Row)({
    borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    height: '40px',
});

export const TableActionsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '24px',
});

export const StyledInput = styled(Input)({
    height: '32px',
    padding: '4px 8px',
    '& input': {
        padding: '0px 0px 0px 8px !important',
    },
});

export const ActionButtonsContainer = styled('div')({
    alignContent: 'center',
    marginLeft: 'auto',
});

export const StyledButton = styled(Button)({
    padding: '0px',
    height: '24px',
});
