import { useOrderRevisionItems } from '../../OrderDesignActions.graphql';
import { useTimelineAndImagesMenuItems } from '../GuidedWaxup.util';
import { PresetTabTopbar } from '../components';
import { ExitGuidedReviewWarningModal } from '../components/ExitGuidedReviewWarningModal';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useWaxupIsRejected } from '../state/GuidedWaxupState';
import { SimpleMenu } from '@orthly/ui';
import { Button, FlossPalette, Text, stylesFactory, IconButton, Grid, Icon } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    closeButton: {
        padding: 8,
        minWidth: 0,
        background: FlossPalette.TAN,
        border: 'none',
    },
    patientAndStatus: {
        marginLeft: 12,
    },
    tabsContainer: {
        justifyContent: 'space-between',
    },
    selectedTab: {
        borderBottom: `2px solid ${FlossPalette.PRIMARY_FOREGROUND}`,
    },
    unselectedTab: {
        borderBottom: 'none',
    },
    vertIcon: {
        color: FlossPalette.PRIMARY_FOREGROUND,
    },
}));

export const CurrentWaxupToolbarMobile: React.VFC = () => {
    const classes = useStyles();
    const {
        order,
        patientName,
        onBack,
        setSelectedDesignRevisionId,
        designRevisionFragments,
        selectedDesignRevisionId,
        enableGuidedWaxupComparison,
    } = useGuidedWaxupContext();
    const waxupRejected = useWaxupIsRejected();
    const statusText = waxupRejected ? 'Rejecting Design' : 'No Change Requests';
    const timelineAndImagesMenuItems = useTimelineAndImagesMenuItems();
    const revisionItems = useOrderRevisionItems(
        setSelectedDesignRevisionId,
        'practitioner',
        order,
        designRevisionFragments,
        selectedDesignRevisionId,
    );

    if (!order) {
        return null;
    }

    return (
        <>
            <ExitGuidedReviewWarningModal />
            <Grid item>
                <Button variant={'secondary'} className={classes.closeButton} onClick={() => onBack()}>
                    <Icon icon={'CloseIcon'} />
                </Button>
            </Grid>
            <Grid item className={classes.patientAndStatus}>
                <Grid item>
                    <Text variant={'body2'} medium>
                        {`${patientName}'s Design`}
                    </Text>
                </Grid>
                <Grid item>
                    <Text
                        variant={'caption'}
                        color={waxupRejected ? 'SECONDARY_FOREGROUND' : 'BLACK'}
                    >{`Status: ${statusText}`}</Text>
                </Grid>
            </Grid>
            <div style={{ flexGrow: 1 }} />
            <Grid item>
                <SimpleMenu
                    ButtonComponent={({ onClick }) => (
                        <IconButton onClick={onClick}>
                            <Icon icon={'MoreVertIcon'} className={classes.vertIcon} />
                        </IconButton>
                    )}
                    items={
                        !enableGuidedWaxupComparison
                            ? [...timelineAndImagesMenuItems, ...revisionItems]
                            : [...timelineAndImagesMenuItems]
                    }
                />
            </Grid>
            <PresetTabTopbar />
        </>
    );
};
