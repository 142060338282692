import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlReasonCodeOptionFragment, LabsGqlReviewTagSubmissionFragment } from '@orthly/graphql-operations';
import { useReasonCodesForOrderItemQuery, useUpdateReviewSubmissionItemTagsMutation } from '@orthly/graphql-react';
import { LabsGqlFormDisplay, LabsGqlReasonCodeFault, LabsGqlReasonCodeGroup } from '@orthly/graphql-schema';
import {
    Medium,
    RootActionDialog,
    SimpleAutocomplete,
    SimpleInput,
    SimpleSelect,
    SimpleTextField,
    useChangeSubmissionFn,
} from '@orthly/ui';
import { Button, FlossPalette, Text, Chip, Grid } from '@orthly/ui-primitives';
import constate from 'constate';
import _ from 'lodash';
import React from 'react';

const STYLES = {
    chip: { cursor: 'pointer', height: 24, marginRight: 8, marginBottom: 8, borderRadius: 2 },
    field: { marginTop: 8 },
    tagbox: { border: `1px solid ${FlossPalette.LIGHT_GRAY}`, borderRadius: 8, padding: '12px 8px' },
    clearinput: { border: 'none', background: 'white', paddingInline: 0, paddingBottom: 0 },
} as const;

const reasonCodeLabel = (reasonCode: LabsGqlReasonCodeOptionFragment) => {
    const group = reasonCode.group ?? LabsGqlReasonCodeGroup.Other;
    const category = reasonCode.category ?? 'Uncategorized';
    const title = reasonCode.title ?? 'Untitled';
    return group === LabsGqlReasonCodeGroup.Other ? `${group} > ${title}` : `${group} > ${category} > ${title}`;
};

const reasonCodeToTag = (
    reasonCode: LabsGqlReasonCodeOptionFragment,
    fault?: LabsGqlReasonCodeFault,
    otherNotes?: string,
): LabsGqlReviewTagSubmissionFragment => ({
    __typename: 'ReviewTagSubmission',
    reason_code_id: reasonCode.id,
    category: reasonCode.category,
    title: reasonCode.title,
    fault: fault ?? reasonCode.default_fault,
    internal_only: reasonCode.internal_only,
    other_notes: otherNotes ?? '',
    review_tag_id: null,
});

interface ItemRefabReasonCodesEditorChipProps {
    orderId: string;
    itemId: string;
    itemDisplayName: string;
    savedTags: LabsGqlReviewTagSubmissionFragment[];
    showRefabEditChips?: boolean;
}

// State logic, packaged using constate
const [ItemRefabContextProvider, useItemRefabContext] = constate((props: ItemRefabReasonCodesEditorChipProps) => {
    const { orderId, itemId, itemDisplayName, savedTags, showRefabEditChips } = props;
    const [open, setOpen] = React.useState(false);
    const [tags, setTags] = React.useState(savedTags);
    const updateTag = React.useCallback(
        (tagId: string, patch: Partial<LabsGqlReviewTagSubmissionFragment>) =>
            setTags(tags => tags.map(tag => (tag.reason_code_id === tagId ? { ...tag, ...patch } : tag))),
        [setTags],
    );
    const removeTag = React.useCallback(
        (tagId: string) => setTags(tags => tags.filter(tag => tag.reason_code_id !== tagId)),
        [setTags],
    );
    const { data, loading } = useReasonCodesForOrderItemQuery({
        variables: { order_id: orderId, item_id: itemId, form_display: LabsGqlFormDisplay.Refab },
    });
    const reasonCodes = _.sortBy(data?.reasonCodesForOrderItem ?? [], reasonCodeLabel);
    const addableReasonCodes = React.useMemo(() => {
        const addedIds = new Set(tags.map(tag => tag.reason_code_id));
        return reasonCodes.filter(rc => !addedIds.has(rc.id));
    }, [tags, reasonCodes]);
    const [newReasonCodeId, setNewReasonCodeId] = React.useState<string | null>(null);
    const [newFault, setNewFault] = React.useState<LabsGqlReasonCodeFault | undefined>(undefined);
    const [newOtherNotes, setNewOtherNotes] = React.useState('');
    const newReasonCode = React.useMemo(
        () => reasonCodes.find(rc => rc.id === newReasonCodeId),
        [reasonCodes, newReasonCodeId],
    );
    const addReasonCode = React.useCallback(() => {
        if (newReasonCode) {
            setTags(tags => [...tags, reasonCodeToTag(newReasonCode, newFault, newOtherNotes)]);
            setNewReasonCodeId('');
            setNewOtherNotes('');
            setNewFault(undefined);
        }
    }, [newReasonCode, setTags, newFault, newOtherNotes, setNewReasonCodeId, setNewOtherNotes, setNewFault]);
    const changeDetected = React.useMemo(() => !_.isEqual(savedTags, tags), [savedTags, tags]);
    const resetTags = React.useCallback(() => {
        setTags(savedTags);
    }, [setTags, savedTags]);
    return {
        open,
        setOpen,
        orderId,
        itemId,
        itemDisplayName,
        loading,
        tags,
        updateTag,
        resetTags,
        removeTag,
        addableReasonCodes,
        newReasonCodeId,
        setNewReasonCodeId,
        newReasonCode,
        newOtherNotes,
        setNewOtherNotes,
        newFault,
        setNewFault,
        addReasonCode,
        changeDetected,
        showRefabEditChips,
    };
});

const ItemRefabTagBox: React.VFC<{ tag: LabsGqlReviewTagSubmissionFragment }> = ({ tag }) => {
    const { removeTag, updateTag } = useItemRefabContext();
    const isOther = tag.title.toLowerCase() === 'other';
    return (
        <div style={STYLES.tagbox}>
            <Text
                variant={'caption'}
                color={'DARK_GRAY'}
                onClick={() => removeTag(tag.reason_code_id ?? '')}
                style={{ float: 'right', cursor: 'pointer', paddingRight: 8 }}
            >
                Remove
            </Text>
            <Text variant={'body2'}>
                <Medium>{tag.category}</Medium> ({tag.title})
            </Text>
            <SimpleSelect
                label={'Fault'}
                value={tag.fault ?? undefined}
                options={Object.values(LabsGqlReasonCodeFault).map(value => ({ value }))}
                onChange={fault => updateTag(tag.reason_code_id ?? '', { fault: fault as LabsGqlReasonCodeFault })}
                SelectProps={{ style: STYLES.clearinput, variant: 'standard' }}
            />
            {isOther && (
                <div style={{ marginTop: 8 }}>
                    <SimpleInput
                        label={'Notes'}
                        value={tag.other_notes ?? ''}
                        onChange={value => updateTag(tag.reason_code_id ?? '', { other_notes: value ?? '' })}
                        fullWidth={true}
                        TextFieldProps={{ multiline: true, InputProps: { style: STYLES.clearinput } }}
                    />
                </div>
            )}
        </div>
    );
};

const ItemRefabTagBoxes: React.VFC = () => {
    const { tags } = useItemRefabContext();
    return (
        <>
            <Text variant={'h5'} style={{ marginBottom: 16 }}>
                Refab Tags:
            </Text>
            <Grid container spacing={2}>
                {tags.map(tag => (
                    <Grid item xs={6} key={tag.reason_code_id}>
                        <ItemRefabTagBox tag={tag} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

const ItemRefabTagAdditionForm: React.VFC = () => {
    const {
        orderId,
        itemId,
        tags,
        setOpen,
        addableReasonCodes,
        newReasonCodeId,
        setNewReasonCodeId,
        newReasonCode,
        newOtherNotes,
        setNewOtherNotes,
        newFault,
        setNewFault,
        changeDetected,
        addReasonCode,
    } = useItemRefabContext();
    const [submitMtn] = useUpdateReviewSubmissionItemTagsMutation();
    const { submit, submitting } = useChangeSubmissionFn(
        () =>
            submitMtn({
                variables: {
                    data: {
                        lab_order_id: orderId,
                        item_id: itemId,
                        // omitting '__typename' prop, the server doesn't like it
                        tags: tags.map(tag => _.omit(tag, ['__typename'])) as LabsGqlReviewTagSubmissionFragment[],
                    },
                },
            }),
        {
            onSuccess: () => {
                setOpen(false);
                BrowserAnalyticsClientFactory.Instance?.track('Ops - Portal - Refab tags edited', {
                    $groups: { order: orderId },
                    tags: tags.map(tag => ({
                        reason_code_id: tag.reason_code_id ?? '',
                        category: tag.category ?? '',
                        title: tag.title ?? '',
                        fault: tag.fault ?? '',
                    })),
                });
            },
        },
    );
    const saveEnabled = !submitting && tags.length !== 0 && changeDetected;
    return (
        <>
            <Text variant={'h5'} style={{ marginTop: 24, marginBottom: 16 }}>
                Add refab tag:
            </Text>
            <div style={STYLES.field}>
                <SimpleAutocomplete
                    key={newReasonCodeId ?? ''}
                    freeSolo={false}
                    label={'Select Tag'}
                    options={addableReasonCodes.map(rc => ({ label: reasonCodeLabel(rc), value: rc.id }))}
                    initialInputValue={newReasonCodeId}
                    onChange={value => setNewReasonCodeId(value)}
                />
            </div>
            {newReasonCode && newReasonCode.title.toLowerCase() === 'other' && (
                <div style={STYLES.field}>
                    <SimpleTextField
                        label={'Other Notes'}
                        value={newOtherNotes}
                        onChange={value => setNewOtherNotes(value)}
                    />
                </div>
            )}
            {newReasonCode && (
                <div style={STYLES.field}>
                    <SimpleSelect
                        label={'Select Fault'}
                        value={newFault ?? newReasonCode.default_fault ?? undefined}
                        options={Object.values(LabsGqlReasonCodeFault).map(value => ({ value }))}
                        onChange={value => setNewFault(value as LabsGqlReasonCodeFault)}
                    />
                </div>
            )}
            <div style={STYLES.field}>
                <Button variant={'secondary'} onClick={addReasonCode} disabled={!newReasonCode}>
                    Add Tag
                </Button>
                <Button variant={'primary'} onClick={submit} disabled={!saveEnabled} style={{ float: 'right' }}>
                    {changeDetected ? 'Save changes' : 'Changes saved'}
                </Button>
            </div>
        </>
    );
};

const ItemRefabReasonCodesEditorChipRoot: React.VFC = () => {
    const { open, setOpen, loading, itemDisplayName, resetTags } = useItemRefabContext();
    return (
        <>
            <Chip
                style={STYLES.chip}
                variant={'outlined'}
                label={<Text variant={'caption'}>Inspect/edit tags</Text>}
                onClick={() => setOpen(true)}
            />
            <RootActionDialog
                maxWidth={'md'}
                hideButton={true}
                loading={loading}
                open={open}
                setOpen={setOpen}
                onClose={resetTags}
                title={<Text variant={'h4'}>{itemDisplayName}</Text>}
                content={
                    <>
                        <ItemRefabTagBoxes />
                        <ItemRefabTagAdditionForm />
                    </>
                }
            />
        </>
    );
};

export const ItemRefabReasonCodesEditorChip: React.VFC<ItemRefabReasonCodesEditorChipProps> = props => {
    if (!props.showRefabEditChips) {
        return null;
    }
    return (
        <ItemRefabContextProvider {...props}>
            <ItemRefabReasonCodesEditorChipRoot />
        </ItemRefabContextProvider>
    );
};
