/* eslint-disable max-lines */
import { DoctorDesignTaskAlerts } from './DoctorDesignTaskAlerts';
import type { LabsGqlOrder, LabsGqlWorkflowTaskFragment } from '@orthly/graphql-operations';
import { useCompleteExternalDesignVerificationTaskMutation, useOrderRefabRates } from '@orthly/graphql-react';
import type { LabsGqlCompleteExternalDesignVerificationTaskCommand } from '@orthly/graphql-schema';
import type { ActionCardButtonPropsBase } from '@orthly/ui';
import { RootActionDialog, useChangeSubmissionFn, WarningIcon } from '@orthly/ui';
import { Button, FlossPalette, Text, Grid } from '@orthly/ui-primitives';
import { CondensedActionCard, type CondensedActionCardVariant } from '@orthly/veneer';
import React from 'react';

interface OrderExternalDesignVerificationTaskAlertProps {
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
    variant: CondensedActionCardVariant;
}

interface OrderExternalDesignVerificationTaskContentProps extends OrderExternalDesignVerificationTaskAlertProps {
    task: LabsGqlWorkflowTaskFragment;
}

interface CompleteVerificationModalProps extends OrderExternalDesignVerificationTaskAlertProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const CompleteVerificationModal: React.FC<CompleteVerificationModalProps> = props => {
    const { order, refetchOrder, setOpen, open } = props;
    const { refabRates } = useOrderRefabRates(order.id);
    const [submitMtn] = useCompleteExternalDesignVerificationTaskMutation();
    const mtnSubmitter = (data: LabsGqlCompleteExternalDesignVerificationTaskCommand) =>
        submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [LabsGqlCompleteExternalDesignVerificationTaskCommand]>(
        mtnSubmitter,
        {
            closeOnComplete: true,
            successMessage: () => [`Design verified!`, {}],
            onSuccess: async () => {
                await refetchOrder();
                setOpen(false);
            },
        },
    );
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Complete external design verification'}
            content={
                <>
                    <Grid container alignItems={'center'} style={{ paddingBottom: 8 }} justifyContent={'space-between'}>
                        <Text variant={'h6'}>
                            <span style={{ color: FlossPalette.GRAY }}>Doctor refab rate: </span>
                            {refabRates.doctor.toFixed(2)}%
                        </Text>
                        <Text variant={'h6'}>
                            <span style={{ color: FlossPalette.GRAY }}>Partner order: </span>#
                            {order.partner_order_number}
                        </Text>
                    </Grid>
                    <Grid container>
                        <Text variant={'body2'}>
                            Please carefully verify that the design preview viewer looks correct, and is free from
                            visual defects and misalignmened scans or restoratives.
                        </Text>
                    </Grid>
                    <Button fullWidth variant={'primary'} onClick={async () => submit({ order_id: order.id })}>
                        Approve design preview
                    </Button>
                </>
            }
            buttonText={''}
            buttonProps={{ style: { display: 'none' } }}
        />
    );
};

const OrderExternalDesignVerificationTaskContent: React.FC<OrderExternalDesignVerificationTaskContentProps> = props => {
    const [designVerificationTaskOpen, setDesignVerificationTaskOpen] = React.useState<boolean>(false);
    const isSidebarElement = props.variant === 'sidebar';
    const primaryAction = React.useMemo<ActionCardButtonPropsBase>(() => {
        return {
            endIcon: 'CheckIcon',
            text: isSidebarElement ? 'Verify' : 'Submit verification',
            onClick: () => setDesignVerificationTaskOpen(true),
        };
    }, [isSidebarElement, setDesignVerificationTaskOpen]);

    return (
        <>
            <CondensedActionCard
                title={'External Waxup Verification'}
                refetch={props.refetchOrder}
                variant={props.variant}
                task={props.task}
                assignee={''}
                primaryAction={primaryAction}
                IconComponent={WarningIcon}
                subtitle={
                    <>
                        <DoctorDesignTaskAlerts lab_slip_alerts={props.order.lab_slip_alerts} />
                    </>
                }
            />
            <CompleteVerificationModal
                {...props}
                setOpen={setDesignVerificationTaskOpen}
                open={designVerificationTaskOpen}
            />
        </>
    );
};

export const OrderExternalDesignVerificationTaskAlert: React.FC<
    OrderExternalDesignVerificationTaskAlertProps
> = props => {
    const { order } = props;

    const verificationTask = React.useMemo(() => {
        return order.fulfillment_workflow.active_task?.type === 'ExternalDesignVerification'
            ? order.fulfillment_workflow.active_task
            : null;
    }, [order.fulfillment_workflow]);

    // Verify that the active task is in fact a verification task.
    if (!verificationTask) {
        return null;
    }

    return <OrderExternalDesignVerificationTaskContent {...props} task={verificationTask} />;
};
