import { useListDisplayRuleOptsQuery } from '@orthly/graphql-react';

export function useUnitMaterialOptions() {
    const { data, loading } = useListDisplayRuleOptsQuery({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });
    const unitTypes = data?.listDisplayRuleOptions.unit_types ?? [];
    const materialTypes = data?.listDisplayRuleOptions.material_types ?? [];
    return { unitTypes, materialTypes, loading };
}
