import type { LabsGqlOrder, LabsGqlResolveOrderReviewFlagMutationVariables } from '@orthly/graphql-operations';
import { useResolveOrderReviewFlagMutation } from '@orthly/graphql-react';
import type { ActionCardButtonPropsBase } from '@orthly/ui';
import { ActionCard, useChangeSubmissionFn } from '@orthly/ui';
import { Text, FlossPalette, Grid } from '@orthly/ui-primitives';
import { CondensedActionCard } from '@orthly/veneer';
import React from 'react';

interface OrderNeedsReviewAlertProps {
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
    variant: 'standard' | 'detail_block';
}

type Vars = LabsGqlResolveOrderReviewFlagMutationVariables['data'];

export const OrderNeedsReviewAlert: React.FC<OrderNeedsReviewAlertProps> = props => {
    const { order, refetchOrder, variant } = props;
    const reasons: string[] = order.review_flag_reasons ?? [];
    const [submitMtn] = useResolveOrderReviewFlagMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Order Review Flag Resolved', {}],
        onSuccess: async () => {
            await refetchOrder();
        },
    });
    const activeTask = order.fulfillment_workflow.active_task;
    const primaryAction = React.useMemo<ActionCardButtonPropsBase>(() => {
        return {
            endIcon: 'CheckIcon',
            text: 'Resolved',
            onClick: async () => {
                await submit({ orderId: order.id });
            },
            loading: submitting,
        };
    }, [submitting, submit, order]);
    if (!activeTask || activeTask.__typename !== `ReviewOrderWorkflowTask`) {
        return null;
    }
    const { review_step } = activeTask.configuration;
    const assignee_name = activeTask.assignee
        ? `${activeTask.assignee.assigned_user.first_name} ${activeTask.assignee.assigned_user.last_name}`
        : 'Unassigned';
    const { needed_review_steps } = order.fulfillment_workflow.configuration;
    const review_step_count = needed_review_steps.length;
    const review_step_index = needed_review_steps.findIndex(step => step.id === review_step.id);
    return (
        <>
            {variant === 'standard' ? (
                <ActionCard
                    title={'This order needs review'}
                    subtitle={
                        <Grid>
                            <Text variant={'body2'} color={'DARK_GRAY'}>
                                Step {review_step_index + 1} of {review_step_count}: {review_step.name}
                            </Text>
                            <Text variant={'body2'}>
                                {reasons.length === 1 ? `Reason: ` : `Reasons: `}
                                {reasons.join(`, `) || `none`}
                            </Text>
                        </Grid>
                    }
                    primaryAction={primaryAction}
                    variant={'alert'}
                />
            ) : (
                <CondensedActionCard
                    title={review_step.name}
                    assignee={assignee_name}
                    primaryAction={primaryAction}
                    subtitle={
                        <Grid>
                            <Text variant={'body2'}>
                                <span style={{ color: FlossPalette.GRAY }}>
                                    {reasons.length === 1 ? `Reason: ` : `Reasons: `}
                                </span>
                                {reasons.join(`, `) || `none`}
                            </Text>
                        </Grid>
                    }
                    refetch={refetchOrder}
                    variant={variant}
                    task={activeTask}
                />
            )}
        </>
    );
};
