import { PricingHistoryModalBase } from './PricingHistoryModalBase';
import { GetInvoiceHistoryItems_Query } from './PricingHistoryQueries.graphql';
import type { InvoiceAdjustmentHistoryBaseProps, InvoiceAdjustmentHistoryProps } from './types';
import { useQuery } from '@apollo/client';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

const InvoiceAdjustmentHistoryModal: React.VFC<InvoiceAdjustmentHistoryProps> = ({ invoiceId, open, setOpen }) => {
    const { data: { getInvoiceHistoryItems: invoiceHistoryItems = [] } = {}, loading } = useQuery(
        GetInvoiceHistoryItems_Query,
        {
            variables: { invoiceId },
        },
    );

    return (
        <PricingHistoryModalBase
            historyItems={invoiceHistoryItems}
            loadingHistoryItems={loading}
            title={'Invoice Adjustments'}
            subtitle={'Below are changes made to credit card fees and manual invoice items.'}
            open={open}
            setOpen={setOpen}
            noHistoryItemsFoundMessage={'There have been no adjustments made to this invoice.'}
        />
    );
};

export const InvoiceAdjustmentHistoryButton: React.VFC<InvoiceAdjustmentHistoryBaseProps> = ({ invoiceId }) => {
    const [open, setOpen] = React.useState<boolean>(false);

    return (
        <>
            {open && <InvoiceAdjustmentHistoryModal invoiceId={invoiceId} open={open} setOpen={setOpen} />}
            <Button onClick={() => setOpen(true)} variant={'ghost'}>
                View Adjustments
            </Button>
        </>
    );
};
