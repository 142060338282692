import { Text, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

interface ReviewDetailCellProps {
    title: string;
    value: React.ReactNode;
}

const useStyles = stylesFactory(theme => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
    valueWrapper: {
        display: 'flex',
        alignItems: 'flex-end',
    },
}));

/** Cell for showing a single item attribute's title and value.  */
export const ReviewDetailCell: React.VFC<ReviewDetailCellProps> = ({ title, value }) => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper}>
            <Text variant={'body2'} color={'GRAY'} medium>
                {title}
            </Text>
            <div className={classes.valueWrapper}>{value}</div>
        </div>
    );
};
