import type { UnsavedFilterCriteria } from './OrderFilterBuilder/OrderFilterBuilder.types';
import { isCompletedFilterCriteria, OrderFilterBuilderRoot } from './OrderFilterBuilder/OrderFilterBuilderRoot';
import type { LabsGqlOrderFilterFragment } from '@orthly/graphql-operations';
import { useListOrderFiltersQuery } from '@orthly/graphql-react';
import type { LabsGqlFilterCriteriaSubmissionInput } from '@orthly/graphql-schema';
import { Button, FlossPalette, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export type Filter = {
    criteria: UnsavedFilterCriteria[];
    completedCriteria?: LabsGqlFilterCriteriaSubmissionInput[];
    changed: boolean;
};

export function GqlFragmentToFilter(filter: LabsGqlOrderFilterFragment): Filter {
    const criteria = filter.criteria.map(c => _.omit(c, '__typename') as Omit<UnsavedFilterCriteria, '__typename'>);
    return {
        criteria,
        completedCriteria: criteria.filter(isCompletedFilterCriteria),
        changed: false,
    };
}

export interface MultiFilterBuilderProps {
    filters: Filter[];
    setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
}

export const MultiFilterBuilder: React.FC<MultiFilterBuilderProps> = props => {
    const filtersQuery = useListOrderFiltersQuery();
    // Immutably change fields of a filter by index:
    const updateFilter = (idx: number, filter: Partial<Filter>) => {
        props.setFilters(filters => filters.map((f, i) => (idx === i ? { ...f, ...filter } : f)));
    };
    const updateCriteria = (idx: number, criteria: UnsavedFilterCriteria[]) =>
        updateFilter(idx, { criteria, completedCriteria: criteria.filter(isCompletedFilterCriteria) });

    return (
        <>
            {props.filters.map((filter, idx) => {
                const { criteria, changed: filterChanged } = filter;
                return (
                    <Grid
                        style={{
                            marginTop: '12px',
                            paddingBottom: '12px',
                            borderBottom:
                                idx === props.filters.length - 1 ? undefined : `1px solid ${FlossPalette.DARK_TAN}`,
                        }}
                        key={idx}
                    >
                        <OrderFilterBuilderRoot
                            filtersQuery={filtersQuery}
                            criterias={criteria}
                            setCriterias={v => {
                                updateCriteria(idx, v);
                            }}
                            changed={filterChanged}
                            setChanged={v => {
                                updateFilter(idx, { changed: v });
                            }}
                            actionsBlock={
                                <Button
                                    variant={'secondary'}
                                    onClick={() => {
                                        props.setFilters(props.filters.filter((_, i) => i !== idx));
                                    }}
                                >
                                    {'Delete Entire Filter'}
                                </Button>
                            }
                        />
                    </Grid>
                );
            })}
            <Grid xs={2}>
                <Button
                    variant={'primary'}
                    onClick={() => {
                        props.setFilters([...props.filters, { criteria: [{}], changed: true }]);
                    }}
                >
                    {'Add Filter'}
                </Button>
            </Grid>
        </>
    );
};
