import type { IProximalSceneAppearanceManager } from './ProximalSceneAppearanceManager';
import type { Range } from './SceneAppearanceManager.types';
import type { ToothNumber } from '@orthly/items';
import type * as THREE from 'three';

/**
 * Synchronizes two ProximalSceneAppearanceManagers
 */
export class MergedProximalSceneAppearanceManager implements IProximalSceneAppearanceManager {
    scene: THREE.Scene;

    /**
     * Constructor
     * @param leader The leading ProximalSceneAppearanceManager. This manager's scene is exposed by this class.
     * @param follower The following ProximalSceneAppearanceManager. The appearance-mutating functions of this object
     * are called in order to synchronize the appearances of the two scenes.
     */
    constructor(
        private readonly leader: IProximalSceneAppearanceManager,
        private readonly follower: IProximalSceneAppearanceManager,
    ) {
        this.scene = leader.scene;
    }

    getRestorativeMeshes() {
        return this.leader.getRestorativeMeshes();
    }

    getScanMeshes() {
        return this.leader.getScanMeshes();
    }

    setHeatmapRange(range: Range): void {
        this.leader.setHeatmapRange(range);
        this.follower.setHeatmapRange(range);
    }

    getRestorativeBoundingSphere(toothNumber: ToothNumber) {
        return this.leader.getRestorativeBoundingSphere(toothNumber);
    }

    getBoundingSphereForVisible() {
        return this.leader.getBoundingSphereForVisible();
    }

    toggleCollisionsVisibility(): void {
        this.leader.toggleCollisionsVisibility();
        this.follower.toggleCollisionsVisibility();
    }

    toggleCollisionsCurtainsVisibility(): void {
        this.leader.toggleCollisionsCurtainsVisibility();
        this.follower.toggleCollisionsCurtainsVisibility();
    }

    toggleUndercutHeatmapEnabled(enabled?: boolean): void {
        this.leader.toggleUndercutHeatmapEnabled(enabled);
        this.follower.toggleUndercutHeatmapEnabled(enabled);
    }

    toggleProximalHeatmapEnabled(enabled?: boolean): void {
        this.leader.toggleProximalHeatmapEnabled(enabled);
        this.follower.toggleProximalHeatmapEnabled(enabled);
    }

    toggleCurtainsVisibility(): void {
        this.leader.toggleCurtainsVisibility();
        this.follower.toggleCurtainsVisibility();
    }
}
