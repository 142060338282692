import type { ToolbarActionProps, ToolbarActionDefinition } from './ToolbarActionDefinition';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const ToggleOrderLockStatusAction_Mutation = graphql(`
    mutation ToggleOrderLockStatusAction_Mutation($order_id: String!, $is_locked: Boolean!) {
        updateLabOrderLockStatus(is_locked: $is_locked, order_id: $order_id)
    }
`);

const ToggleOrderLockStatusAction: React.FC<ToolbarActionProps> = props => {
    const { order, refetchOrder, open, setOpen, CustomButton } = props;

    const pauseMutation = useMutation(ToggleOrderLockStatusAction_Mutation);
    const { submit, submitting, called } = useRootActionCommand(pauseMutation, {
        successMessage: 'Updated locked status of order',
        onSuccess: async () => {
            await refetchOrder();
            setOpen(false);
        },
    });

    React.useEffect(() => {
        if (open && !submitting && !called) {
            void submit({ order_id: order.id, is_locked: !order.is_order_locked_for_sales_demo });
        }
    }, [open, submitting, submit, called, order.id, order.is_order_locked_for_sales_demo]);

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Toggle Lock Status'}
            content={<Grid container style={{ minHeight: 400 }} />}
            buttonText={''}
            CustomButton={CustomButton}
        />
    );
};

export const ToggleOrderLockStatusToolbarAction: ToolbarActionDefinition = {
    Component: ToggleOrderLockStatusAction,
    label: 'Toggle Lock Status',
};
