import type { ItemEditorV2BaseProps } from '../../../types/ItemEditorV2BaseProps';
import { useAutoSetItemDataFieldDefaultValue } from './ItemEditorV2ItemDataField.util';
import { ItemDataFieldUtils } from '@orthly/items';
import type { EnumSelectMultiItemDataField, ItemDataFieldId, LabOrderItemSKUType } from '@orthly/items';
import { belongsToEnum } from '@orthly/runtime-utils';
import { SimpleMultiSelect } from '@orthly/ui';
import React from 'react';

interface ItemEditorV2EnumSelectMultiItemDataFieldProps<
    ID extends ItemDataFieldId,
    SKU extends LabOrderItemSKUType,
    Enum extends string,
> extends ItemEditorV2BaseProps<SKU> {
    field: EnumSelectMultiItemDataField<ID, SKU, Enum>;
}

export const ItemEditorV2EnumSelectMultiItemDataField = <
    ID extends ItemDataFieldId,
    SKU extends LabOrderItemSKUType,
    Enum extends string,
>({
    field,
    item,
    updateItem,
    showError,
}: ItemEditorV2EnumSelectMultiItemDataFieldProps<ID, SKU, Enum>): React.ReactElement => {
    const value = field.getValue(item);

    const applyUpdate = React.useCallback(
        (val: string[] | undefined) => {
            // belongs to enum should always be true, but we check for type reasons
            if (val === undefined || val.every((v): v is Enum => belongsToEnum(v, field.enum))) {
                updateItem(field.getUpdatePayload(val ?? [], item));
            }
        },
        [field, item, updateItem],
    );

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    // if they have no value set, we set it to an empty array
    // this is particularly necessary for cart items that allow the property to be optional
    // but order items that expect it to be required, but allow it to be empty
    React.useEffect(() => {
        if (!value && !field.getDefaultValue?.(item)) {
            applyUpdate([]);
        }
    }, [value, field, item, applyUpdate]);

    return (
        <SimpleMultiSelect
            label={field.label}
            value={value ?? []}
            options={[...field.getOptions(item)]}
            onChange={val => applyUpdate(val)}
            errorText={
                showError && ItemDataFieldUtils.isItemDataFieldRequired(field, item) && !field.isComplete(item)
                    ? 'This field is required'
                    : undefined
            }
        />
    );
};
