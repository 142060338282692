import type { AutomationFilterCriteria } from '../../../../state/AutomationBuilder.types';
import { AutomationAutocompleteField } from '../../components/AutomationBuilderFields';
import { comparatorsAvailableForFilter } from './OrderFilterComparatorField';
import type { LabsGqlOrderFilterRuleFragment } from '@orthly/graphql-operations';
import type { LabsGqlFilterCriteriaSubmissionInput } from '@orthly/graphql-schema';
import { LabsGqlFilterListCombinator } from '@orthly/graphql-schema';
import type { SimpleAutocompleteOption } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

interface OrderFilterRuleSelectProps {
    rules: LabsGqlOrderFilterRuleFragment[];
    onChange: (newValue: Partial<LabsGqlFilterCriteriaSubmissionInput>) => void;
    currentCriteria: AutomationFilterCriteria;
}

export const OrderFilterRuleField: React.FC<OrderFilterRuleSelectProps> = props => {
    const { rules, onChange, currentCriteria } = props;
    const filterOptions = React.useMemo(() => {
        const opts = rules.map(f => ({
            value: f.id,
            label: f.list_entity_name ? `${_.startCase(f.list_entity_name)} ${f.name}` : f.name,
        }));
        return _.sortBy(opts, r => r.label);
    }, [rules]);
    const foundValue = React.useMemo(() => {
        if (!currentCriteria.filter_id) {
            return null;
        }
        return filterOptions.find(o => o.value === currentCriteria.filter_id);
    }, [currentCriteria.filter_id, filterOptions]);

    // We always want to have a controlled component, which means passing a non-null value
    // even if we don't have one.
    // This can happen when we're loading, and haven't loaded rules/currentCriteria yet.
    // Using an empty string is not great, but if uncontrolled, the component would default
    // to empty string anyway.
    const value: SimpleAutocompleteOption = foundValue ?? { value: '', label: '' };

    return (
        <AutomationAutocompleteField
            options={filterOptions}
            AutocompleteProps={{ value, disableClearable: true }}
            onChange={newFilterId => {
                if (newFilterId) {
                    const matchingFilter = rules.find(f => f.id === newFilterId);
                    // set the comparator to the first valid option if the selected filter has changed
                    const comparator =
                        currentCriteria.filter_id !== newFilterId && matchingFilter
                            ? comparatorsAvailableForFilter(matchingFilter)[0]
                            : currentCriteria.comparator;
                    onChange({
                        comparator,
                        filter_id: newFilterId,
                        list_combinator: matchingFilter?.list_entity_name
                            ? currentCriteria.list_combinator ?? LabsGqlFilterListCombinator.Any
                            : undefined,
                    });
                }
            }}
            label={''}
        />
    );
};
