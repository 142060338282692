import { RuleTableDialog } from '../../../../components/RuleTable/RuleTableDialog';
import { RoutingRuleUtils } from '../../components/RoutingRule.util';
import { useCreateBiasRuleSubmitter } from './BiasRule.hooks';
import { LabFilterRuleForm } from './BiasRuleForm';
import { BiasRuleRowActions } from './BiasRuleRowActions';
import type { LabsGqlBiasRuleFragment } from '@orthly/graphql-operations';
import { useBiasRulesForDoctorQuery } from '@orthly/graphql-react';
import MUITable from '@orthly/mui-table';
import { ActionCard, LoadBlocker } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

export const LabFilterRules: React.VFC = () => {
    const { data: biasRuleData, loading: loadingRules, error: errorRules, refetch } = useBiasRulesForDoctorQuery();
    const rulesActionCard = errorRules && (
        <ActionCard title={`Error loading distribution rules`} subtitle={null} variant={'alert'} />
    );
    const biasRules = (biasRuleData?.biasRulesForDoctor ?? []).filter(rule => rule.veto);
    const [openAddDialog, setOpenAddDialog] = React.useState(false);

    const { submit: submitBiasRule, submitting: submittingBiasRule } = useCreateBiasRuleSubmitter({
        onComplete: async () => {
            await refetch();
            setOpenAddDialog(false);
        },
    });

    return (
        <Grid container>
            {rulesActionCard}
            <MUITable<LabsGqlBiasRuleFragment>
                title={`Lab Filter Rules`}
                data={biasRules}
                loading={loadingRules}
                actions={{
                    global: [
                        { icon: 'add', position: 'toolbar', onClick: () => setOpenAddDialog(true) },
                        { icon: 'refresh', position: 'toolbar', onClick: () => refetch() },
                    ],
                }}
                displayOptions={{ filter: true, sort: true, filterValues: true }}
                columns={[
                    ...RoutingRuleUtils.commonColumns,
                    {
                        title: 'Disabled?',
                        name: 'actions',
                        field: 'disabled',
                        render: row => <BiasRuleRowActions row={row} refetch={refetch} isFilterRule={true} />,
                        filter: true,
                        sort: false,
                        customFilterFn: (values, row) => values.includes(`${row.disabled}`),
                        type: 'boolean',
                        filterOptions: {
                            defaultValues: ['false'],
                        },
                    },
                ]}
            />
            <RuleTableDialog
                open={openAddDialog}
                setOpen={setOpenAddDialog}
                form={
                    <LoadBlocker blocking={submittingBiasRule}>
                        <LabFilterRuleForm
                            initialValues={{
                                name: ``,
                                condition_json: `null`,
                            }}
                            onSubmit={values => {
                                void submitBiasRule({ ...values });
                            }}
                        />
                    </LoadBlocker>
                }
                title={'Add Lab Filter Rule'}
            />
        </Grid>
    );
};
