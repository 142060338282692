import type { ToolbarActionProps, ToolbarActionDefinition } from './ToolbarActionDefinition';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useCreateDesignQaEvaluationMutation } from '@orthly/graphql-react';
import type { LabsGqlMutationCreateDesignQaEvaluationArgs } from '@orthly/graphql-schema';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { Text } from '@orthly/ui-primitives';
import type { UserPartial } from '@orthly/veneer';
import { UserSelectButton } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';
import z from 'zod';

type Vars = LabsGqlMutationCreateDesignQaEvaluationArgs['data'];

export const AdminSendOrderToQaEvaluation_Query = graphql(`
    query AdminSendOrderToQaEvaluation_Query($orderId: String!) {
        getAllDesignOrderDesignRevisions(labOrderId: $orderId) {
            label
            source_file_zip_path
        }
    }
`);

const SendOrderToQaEvaluation: React.FC<ToolbarActionProps> = props => {
    const { order, setOpen, open } = props;

    const { data: queryData } = useQuery(AdminSendOrderToQaEvaluation_Query, {
        variables: {
            orderId: order.id,
        },
    });
    const allRevisions = queryData?.getAllDesignOrderDesignRevisions ?? [];

    const [submitMtn] = useCreateDesignQaEvaluationMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Order submitted to QA Evaluation Queue', {}],
        onSuccess: async () => {
            await props.refetchOrder();
            setOpen(false);
        },
    });
    const [selectedUser, setSelectedUser] = React.useState<UserPartial | undefined>(undefined);

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Send to QA'}
            content={
                <QuickForm<Omit<Vars, 'order_id'>>
                    fields={{
                        design_file_path: {
                            type: 'select',
                            options: _.compact(allRevisions).map(revision => ({
                                label: revision.label,
                                value: revision.source_file_zip_path,
                            })),
                            label: 'Design File',
                            optional: false,
                        },
                        is_calibration: {
                            type: 'boolean',
                            label: 'Is Calibration',
                            optional: true,
                        },
                        assigned_user_id: {
                            type: 'custom',
                            component: () => (
                                <>
                                    <Text variant={'body2'} medium color={'BLACK'}>
                                        Assigned To User
                                    </Text>
                                    <UserSelectButton
                                        assignedUser={selectedUser}
                                        setAssignedUser={async user => setSelectedUser(user ?? undefined)}
                                    />
                                    {selectedUser && (
                                        <Text variant={'body2'} color={'BLACK'}>
                                            {selectedUser.first_name} {selectedUser.last_name}
                                        </Text>
                                    )}
                                </>
                            ),
                            optional: true,
                            validation: z.string().optional(),
                        },
                    }}
                    initialValues={{ design_file_path: undefined }}
                    onSubmit={async (result, actions) => {
                        await submit({
                            ...result,
                            order_id: props.order.id,
                            assigned_user_id: selectedUser?.id,
                        });
                        actions.setSubmitting(false);
                    }}
                />
            }
            style={{ padding: 0 }}
            buttonText={'Send to QA'}
            buttonProps={{
                style: { height: '100%' },
            }}
            CustomButton={props.CustomButton}
        />
    );
};

export const SendOrderToQaEvaluationToolbarAction: ToolbarActionDefinition = {
    label: 'Send to QA',
    Component: SendOrderToQaEvaluation,
    allowedStatuses: [
        LabsGqlLabOrderStatus.NeedsReview,
        LabsGqlLabOrderStatus.Fabrication,
        LabsGqlLabOrderStatus.Shipped,
        LabsGqlLabOrderStatus.Delivered,
        LabsGqlLabOrderStatus.NeedsRefabrication,
    ],
};
