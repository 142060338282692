import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { QuickForm, QuickFormValidations, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { FlossPalette, Icon, IconButton, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

type EditAccountManagementContactProps = {
    practiceId: string;
    acctManagementContactEmails: string[];
    refetch: () => void;
};

const EditAccountManagementContactEmail_Mutation = graphql(`
    mutation EditAccountManagementContacts($data: EditAccountManagementContactsCommand!) {
        editAccountManagementContacts(data: $data) {
            id
        }
    }
`);

const EmailContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    alignItems: 'center',
});

const EmailText = styled(Text)({
    maxWidth: '140px',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
});

export const EditAccountManagementContact: React.FC<EditAccountManagementContactProps> = ({
    practiceId,
    acctManagementContactEmails,
    refetch,
}) => {
    const [open, setOpen] = React.useState(false);
    const editAccountManagementContactEmail = useMutation(EditAccountManagementContactEmail_Mutation);
    const { submit, submitting } = useRootActionCommand(editAccountManagementContactEmail, {
        onSuccess: () => {
            refetch();
            setOpen(false);
        },
    });

    const CustomButton = React.useCallback(
        () => (
            <EmailContainer>
                <EmailText variant={'caption'}>{acctManagementContactEmails.join(', ')}</EmailText>
                <IconButton onClick={() => setOpen(true)}>
                    <Icon
                        icon={'PencilOutlinedIcon'}
                        fontSize={'small'}
                        sx={{ color: FlossPalette.PRIMARY_FOREGROUND, minWidth: '24px' }}
                    />
                </IconButton>
            </EmailContainer>
        ),
        [acctManagementContactEmails, setOpen],
    );

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Edit Account Management Contact Email(s)'}
            buttonText={''}
            CustomButton={CustomButton}
            content={
                <QuickForm<{ account_management_contact_emails: string[] }>
                    fields={{
                        account_management_contact_emails: {
                            type: 'array',
                            of: { type: 'text', validation: QuickFormValidations.email },
                            label: 'Contact Email',
                            min: 1,
                        },
                    }}
                    initialValues={{ account_management_contact_emails: acctManagementContactEmails }}
                    onSubmit={async result => {
                        void submit({
                            data: {
                                partner_id: practiceId,
                                account_management_contact_emails: result.account_management_contact_emails,
                            },
                        });
                    }}
                    dirtySubmitOnly={true}
                />
            }
        />
    );
};
