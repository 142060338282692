import { FileUploaderBulk } from '../FirebaseUpload/FileUploaderBulk';
import { OrderTaskSidebarOverlay } from '../OrderDetails/OrderTaskSidebarOverlay';
import type { SidebarOverlayButtonProps } from '../Sidebar/SidebarOverlay';
import type { UserPartial } from '../TaskButtons/AssignTaskButton';
import { UserSelectButton } from '../TaskButtons/AssignTaskButton';
import type { MetafieldInputs } from './DesignReviewMetafields';
import {
    DesignReviewMetafields,
    useReviewFields,
    metafieldInputsAreValid,
    metafieldInputsToMetafieldSubmissions,
    anyMetafieldCategoriesAreChecked,
} from './DesignReviewMetafields';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlOrder, LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useCompleteDesignReviewTaskMutation, useListPsrUsers } from '@orthly/graphql-react';
import type { LabsGqlCompleteDesignReviewTaskCommand } from '@orthly/graphql-schema';
import { LabsGqlDesignReviewFieldType, LabsGqlProductLine } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { getFullStoragePath, DesignStorageConfigs } from '@orthly/shared-types';
import {
    LoadBlocker,
    OrthlyBrowserConfig,
    SimpleTextField,
    useChangeSubmissionFn,
    getSentryReplayUrl,
} from '@orthly/ui';
import { Text, createStyles, makeStyles, Switch, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const DesignQcAnalyticsContext = React.createContext<{
    designTabOpenTime: number;
    designTabOpenFullstoryLink?: string;
} | null>(null);

const useStyles = makeStyles(() =>
    createStyles({
        switchButton: {
            margin: '12px 12px 0px',
        },
    }),
);

type RejectionSubformData = {
    skip_next_review: boolean;
    next_assigned_user_id?: string;
    notes: string;
    file_urls?: string[];
};

export interface CompleteDesignReviewTaskModalProps {
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const ReviewRejectionSubform: React.VFC<{
    order: LabsGqlLabOrderFragment;
    data: RejectionSubformData;
    onFormUpdated: (setter: (data: RejectionSubformData | undefined) => RejectionSubformData | undefined) => void;
}> = props => {
    const classes = useStyles();
    const { order, data, onFormUpdated } = props;
    const { data: users, loading: loadingUsers } = useListPsrUsers();
    const psrUsers = users?.listUsers ?? [];
    const canAutoAssign =
        order.product_line !== LabsGqlProductLine.Partial && order.product_line !== LabsGqlProductLine.Removable;

    // Keeps track of the user object itself, as we just use the id in the form fields.
    const [redesigner, setRedesigner] = React.useState<UserPartial | undefined>(
        psrUsers.find(user => user.id === data.next_assigned_user_id),
    );

    // Utility function, existing just to provide easier data management.
    // Sets the value of field, while maintaining old values for the other fields.
    const updateField = <T extends keyof RejectionSubformData>(field: T, value: RejectionSubformData[T]) => {
        onFormUpdated(data => (data ? { ...data, [field]: value } : undefined));
    };

    React.useEffect(() => {
        if (canAutoAssign) {
            setRedesigner(undefined);
            onFormUpdated(data => (data ? { ...data, next_assigned_user_id: undefined } : undefined));
        }
    }, [canAutoAssign, onFormUpdated]);

    const storagePath = getFullStoragePath(OrthlyBrowserConfig.env, DesignStorageConfigs.qc, order.id);

    return (
        <LoadBlocker blocking={loadingUsers}>
            <Grid container spacing={2} direction={'column'} style={{ padding: '8px 16px' }}>
                {/* Screenshots */}
                <Grid container item>
                    <Grid item>
                        <Text variant={'body2'} style={{ fontWeight: 500, paddingBottom: 8 }}>
                            Want to attach a screenshot?
                        </Text>
                    </Grid>
                    <Grid item>
                        <FileUploaderBulk
                            autoSubmit
                            elevation={0}
                            storagePathConfig={storagePath}
                            paperStyle={{ padding: 0, marginBottom: -16 }}
                            onComplete={results =>
                                updateField(
                                    'file_urls',
                                    results.map(result => result.uploadedPath),
                                )
                            }
                            onReset={() => updateField('file_urls', [])}
                            prependTimestampToFilename={true}
                        />
                    </Grid>
                </Grid>

                {/* Redesign Assignment */}
                {!canAutoAssign && (
                    <Grid
                        container
                        item
                        justifyContent={'space-between'}
                        spacing={1}
                        direction={'row'}
                        alignItems={'flex-end'}
                    >
                        <Grid item xs={8}>
                            <Text variant={'body2'} style={{ fontWeight: 500 }}>
                                Assign redesign to
                            </Text>
                        </Grid>
                        <Grid container item xs={4} alignItems={'flex-end'} spacing={1}>
                            <Grid item xs={7}>
                                <UserSelectButton
                                    setAssignedUser={async (user: UserPartial | null) => {
                                        setRedesigner(user ?? undefined);
                                        updateField('next_assigned_user_id', user?.id);
                                    }}
                                    assignedUser={redesigner}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                {psrUsers.find(user => user.id === data.next_assigned_user_id)?.first_name}
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {/* Do we want a redesign QC? */}
                <Grid
                    container
                    item
                    justifyContent={'space-between'}
                    alignItems={'flex-end'}
                    spacing={1}
                    direction={'row'}
                >
                    <Grid item xs={8} alignContent={'center'}>
                        <Text variant={'body2'} style={{ fontWeight: 500 }}>
                            QC redesign
                        </Text>
                    </Grid>
                    <Grid container item xs={4} alignItems={'flex-end'}>
                        <Grid item alignContent={'flex-end'} xs={8}>
                            <Switch
                                color={'secondary'}
                                size={'medium'}
                                checked={!data.skip_next_review}
                                onChange={() => updateField('skip_next_review', !data.skip_next_review)}
                                className={classes.switchButton}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Text variant={'body2'}>{data.skip_next_review ? 'No' : 'Yes'}</Text>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Notes */}
                <Grid container item direction={'row'}>
                    <Grid item>
                        <Text variant={'body2'} style={{ fontWeight: 500, paddingBottom: 8 }}>
                            Leave a note to the designer
                        </Text>
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleTextField
                            TextFieldProps={{ multiline: true, rows: 3, style: { fontWeight: 'normal' } }}
                            value={data.notes}
                            onChange={notes => updateField('notes', notes)}
                            label={'Note'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </LoadBlocker>
    );
};

export function useDesignReviewData(props: CompleteDesignReviewTaskModalProps) {
    const { order, refetchOrder, setOpen } = props;

    const [submitMtn] = useCompleteDesignReviewTaskMutation();
    const mtnSubmitter = (data: LabsGqlCompleteDesignReviewTaskCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [LabsGqlCompleteDesignReviewTaskCommand]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => [`Design review submitted`, {}],
        onSuccess: async () => {
            await refetchOrder();
            setOpen(false);
        },
    });

    const [metafieldInputs, setMetafieldInputs] = React.useState<MetafieldInputs>({});
    const [rejection, setRejection] = React.useState<RejectionSubformData | undefined>();

    const { reviewFields, loading } = useReviewFields(order, LabsGqlDesignReviewFieldType.DesignReview);
    const isValid = React.useMemo(
        () => metafieldInputsAreValid(metafieldInputs, reviewFields),
        [metafieldInputs, reviewFields],
    );

    const designQcAnalyticsContext = React.useContext(DesignQcAnalyticsContext);
    const submitForm = async () => {
        if (!window.confirm(`Are you sure you want to ${!!rejection ? 'reject' : 'approve'} this design?`)) {
            return;
        }

        BrowserAnalyticsClientFactory.Instance?.track(`Ops - Portal - Order Design QC Decision Made`, {
            wasRejected: !!rejection,
            rejectionNotes: rejection?.notes,
            skipNextReview: rejection?.skip_next_review,
            designTabOpenTime: designQcAnalyticsContext?.designTabOpenTime,
            $groups: {
                order: order.id,
            },
        });
        const metafieldSubmissions = metafieldInputsToMetafieldSubmissions(metafieldInputs, reviewFields);
        await submit({
            order_id: order.id,
            file_urls: rejection?.file_urls ?? [],
            metafields: metafieldSubmissions,
            rejection: rejection
                ? {
                      skip_next_review: rejection.skip_next_review,
                      next_assigned_user_id: rejection.next_assigned_user_id,
                      notes: rejection.notes,
                      // These keys are the section groups, we just want the remaining portions of the label.
                      reasons: Object.keys(metafieldInputs).map(input => _.startCase(input)),
                  }
                : null,

            // analytics
            designTabOpenTime: designQcAnalyticsContext?.designTabOpenTime,
            submitTime: Date.now(),
            designTabOpenFullstoryLink: designQcAnalyticsContext?.designTabOpenFullstoryLink,
            submitFullstoryLink: getSentryReplayUrl(),
        });
    };

    return {
        submitForm,
        reviewFields,
        rejection,
        metafieldInputs,
        setMetafieldInputs,
        setRejection,
        blocked: submitting || loading || !isValid,
    };
}

export const CompleteDesignReviewTaskModal: React.VFC<CompleteDesignReviewTaskModalProps> = props => {
    const { order, setOpen, open } = props;

    const { submitForm, blocked, reviewFields, setRejection, rejection, setMetafieldInputs, metafieldInputs } =
        useDesignReviewData(props);

    const currentUserId = useSession()?.user_id;

    if (!open) {
        return null;
    }

    const frame = (frameParams: {
        title: React.ReactNode;
        secondaryButton?: SidebarOverlayButtonProps;
        primaryButton?: SidebarOverlayButtonProps;
        contents: React.ReactNode;
    }) => {
        return (
            <OrderTaskSidebarOverlay
                open={open}
                setOpen={setOpen}
                blocked={blocked}
                title={frameParams.title}
                secondaryButton={frameParams.secondaryButton}
                primaryButton={frameParams.primaryButton}
            >
                {frameParams.contents}
            </OrderTaskSidebarOverlay>
        );
    };

    if (rejection) {
        return frame({
            title: 'Flag Design',
            secondaryButton: {
                label: 'Cancel',
                icon: undefined,
                onClick: async () => setRejection(undefined),
            },
            primaryButton: {
                label: 'Flag',
                icon: 'ReportProblemOutlinedIcon',
                onClick: submitForm,
            },
            contents: <ReviewRejectionSubform order={order} data={rejection} onFormUpdated={setRejection} />,
        });
    }

    return frame({
        title: 'Review Design',
        secondaryButton: {
            label: 'Flag',
            icon: 'ReportProblemOutlinedIcon',
            disabled: !anyMetafieldCategoriesAreChecked(metafieldInputs),
            onClick: async () =>
                setRejection({ skip_next_review: false, next_assigned_user_id: currentUserId, notes: '' }),
        },
        primaryButton: {
            label: 'Approve',
            icon: 'CheckIcon',
            onClick: submitForm,
        },
        contents: (
            <DesignReviewMetafields
                metafieldInputs={metafieldInputs}
                reviewFields={reviewFields}
                setMetafieldInputs={setMetafieldInputs}
            />
        ),
    });
};
