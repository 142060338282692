import { useAutomationBuilderDispatch } from './AutomationBuilder.context';
import type { AutomationBuilderFormState } from './AutomationBuilder.types';
import type { LabsGqlOrderAutomationFragment, LabsGqlAutomationActionFragment } from '@orthly/graphql-operations';
import type {
    Scalars,
    Maybe,
    LabsGqlFilterCriteriaSubmissionInput,
    LabsGqlFilterCombinator,
} from '@orthly/graphql-schema';
import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'automations';

type UpdateFormPayload<K extends keyof AutomationBuilderFormState = keyof AutomationBuilderFormState> = {
    property: K;
    value: AutomationBuilderFormState[K];
};

type UpdateOrderFilterPayload = {
    idx: number;
    value: Partial<LabsGqlFilterCriteriaSubmissionInput>;
};

type UpdateActionFieldPayload = {
    action_id: string;
    field_id: string;
    value?: Maybe<Scalars['AutomationFieldValue']['input']>;
};

export const AutomationBuilderActions = {
    RESET: createSyncAction<undefined, []>(`${PREFIX}/RESET`),
    ENTER_EDITING: createSyncAction<undefined | LabsGqlOrderAutomationFragment, [LabsGqlOrderAutomationFragment?]>(
        `${PREFIX}/ENTER_EDITING`,
    ),
    AUTOMATION_SAVED: createSyncAction<LabsGqlOrderAutomationFragment, [LabsGqlOrderAutomationFragment]>(
        `${PREFIX}/SAVED`,
    ),
    UPDATE_FORM: createSyncAction<UpdateFormPayload, [UpdateFormPayload]>(`${PREFIX}/UPDATE_FORM`),
    UPDATE_ORDER_FILTER: createSyncAction<UpdateOrderFilterPayload>(`${PREFIX}/UPDATE_ORDER_FILTER`),
    DELETE_ORDER_FILTER: createSyncAction<number>(`${PREFIX}/DELETE_ORDER_FILTER`),
    ADD_ORDER_FILTER: createSyncAction<undefined, []>(`${PREFIX}/ADD_ORDER_FILTER`),
    SET_COMBINATOR: createSyncAction<LabsGqlFilterCombinator, [LabsGqlFilterCombinator]>(`${PREFIX}/SET_COMBINATOR`),
    ADD_ACTION: createSyncAction<LabsGqlAutomationActionFragment, [LabsGqlAutomationActionFragment]>(
        `${PREFIX}/ADD_ACTION`,
    ),
    DELETE_ACTION: createSyncAction<{ id: string }>(`${PREFIX}/DELETE_ACTION`),
    UPDATE_ACTION_FIELD: createSyncAction<UpdateActionFieldPayload, [UpdateActionFieldPayload]>(
        `${PREFIX}/UPDATE_ACTION_FIELD`,
    ),
    UPDATE_SAVED_AUTOMATION: createSyncAction<Partial<LabsGqlOrderAutomationFragment>>(
        `${PREFIX}/UPDATE_SAVED_AUTOMATION`,
    ),
    RESET_FORM_TO_SAVED: createSyncAction<undefined>(`${PREFIX}/RESET_FORM_TO_SAVED`),
    ENTER_DUPLICATE: createSyncAction<LabsGqlOrderAutomationFragment, [LabsGqlOrderAutomationFragment?]>(
        `${PREFIX}/ENTER_DUPLICATE`,
    ),
};

export const useAutomationBuilderAction = generateUseActionHook<typeof AutomationBuilderActions>(
    AutomationBuilderActions,
    useAutomationBuilderDispatch,
);
