import type { TextVariant } from '@orthly/ui-primitives';
import { Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

export interface SpotlightSearchItemProps {
    title: string;
    titleVariant?: TextVariant;
    boldTitle?: boolean;
    lightPadding?: boolean;
    titleRight?: React.ReactNode;
    details?: React.ReactNode;
    onClick?: () => void;
}

export const SpotlightSearchItem: React.FC<SpotlightSearchItemProps> = props => {
    const { title, titleVariant, boldTitle, lightPadding, titleRight, details } = props;
    return (
        <Grid
            container
            wrap={'nowrap'}
            style={{
                cursor: !!props.onClick ? 'pointer' : undefined,
            }}
        >
            <Grid container direction={'column'}>
                <Grid container>
                    <Text variant={titleVariant ?? 'h6'} medium={boldTitle}>
                        {title}
                    </Text>
                </Grid>
                <Grid container alignItems={'center'} style={{ paddingBottom: lightPadding ? 2 : 8 }}>
                    {details ?? null}
                </Grid>
            </Grid>
            {titleRight && (
                <Grid container alignItems={'center'} justifyContent={'flex-end'}>
                    {titleRight}
                </Grid>
            )}
        </Grid>
    );
};
