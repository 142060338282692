import { default as constate } from 'constate';
import React from 'react';

type ElementRef = React.MutableRefObject<Element | null>;

const do_boxes_intersect = (box1: DOMRect, box2: DOMRect) =>
    box1.top + box1.height > box2.top &&
    box1.left + box1.width > box2.left &&
    box1.bottom - box1.height < box2.bottom &&
    box1.right - box1.width < box2.right;

export const createStickyContext = <Props extends unknown>() => {
    const [Provider, useSticky] = constate(() => React.useRef(new Map<ElementRef, Props>()).current);

    const useParent = (parent_ref: ElementRef): Props[] => {
        const sticky = useSticky();
        const [visible_props, set_visible_props] = React.useState<Props[]>([]);
        React.useEffect(() => {
            const { current: parent } = parent_ref;
            if (!parent) {
                return;
            }
            const scroll_listener = () => {
                const parent_box = parent.getBoundingClientRect();
                const visible_props = Array.from(sticky.entries())
                    .filter(([child_ref, _]) => {
                        const { current: child } = child_ref;
                        if (!child) {
                            return false;
                        }
                        const child_box = child.getBoundingClientRect();
                        return do_boxes_intersect(parent_box, child_box);
                    })
                    .map(([_, props]) => props);
                set_visible_props(visible_props);
            };
            parent.addEventListener(`scroll`, scroll_listener);
            return () => parent.removeEventListener(`scroll`, scroll_listener);
        }, [sticky, parent_ref]);
        return visible_props;
    };

    const useChild = (child_ref: ElementRef, props: Props): void => {
        const sticky = useSticky();
        React.useEffect(() => {
            sticky.set(child_ref, props);
            return () => {
                sticky.delete(child_ref);
            };
        }, [sticky, child_ref, props]);
    };

    return { Provider, useParent, useChild };
};
