import type {
    LabsGqlOrganizationDtoFragment,
    LabsGqlUpdatePracticeSettingsMutationVariables,
} from '@orthly/graphql-operations';
import { useGetOrgQuery, useUpdatePracticeSettingsMutation } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

type LabsGqlUpdatePracticeSettingsMutationArgs = LabsGqlUpdatePracticeSettingsMutationVariables['data'];

export const useUpdatePracticeSettings = (onSuccess: () => void) => {
    const [submitMtn] = useUpdatePracticeSettingsMutation();
    const mtnSubmitter = (data: LabsGqlUpdatePracticeSettingsMutationArgs) => submitMtn({ variables: { data } });
    return useChangeSubmissionFn(mtnSubmitter, {
        onSuccess: onSuccess,
        successMessage: () => ['Successfully updated practice contact information', {}],
    });
};

export const usePreloadPracticeInformation = (
    data: LabsGqlOrganizationDtoFragment | undefined,
    setName: (name: string) => void,
    setPhone: (phone: string) => void,
) => {
    React.useEffect(() => {
        if (data) {
            setName(data.name);
            setPhone(data.phone_number ?? '');
        }
    }, [data, setName, setPhone]);
};

export const useCurrentPartner = (organizationId: string | null | undefined) => {
    const session = useSession();
    const orgId = organizationId ?? session?.organization_id ?? '';

    const { data, loading } = useGetOrgQuery({
        variables: { id: orgId },
        skip: !orgId,
    });

    return {
        data: data?.getOrganization ?? undefined,
        loading,
    };
};
