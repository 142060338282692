import { ItemEditorV2SectionWrapper } from '../../components/ItemEditorV2SectionWrapper';
import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { ItemEditorV2AbutmentMaterialField } from './ItemEditorV2AbutmentMaterialField';
import { ItemEditorV2ImplantSystemFields } from './ItemEditorV2ImplantSystemFields';
import { ItemEditorV2LinkRelationshipField } from './ItemEditorV2LinkRelationshipField';
import type { LabsGqlImplantTypeLiteFragment } from '@orthly/graphql-operations';
import { CartItemV2Utils } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface ItemEditorV2ImplantInfoSectionProps extends ItemEditorV2BaseProps {
    isInternal: boolean;
    implantSystems: LabsGqlImplantTypeLiteFragment[];
}

export const ItemEditorV2ImplantInfoSection: React.VFC<ItemEditorV2ImplantInfoSectionProps> = ({
    item,
    updateItem,
    showError,
    isInternal,
    implantSystems,
}) => {
    if (!CartItemV2Utils.itemTypeHasImplant(item)) {
        return null;
    }

    return (
        <ItemEditorV2SectionWrapper title={'Implant Info'}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <ItemEditorV2LinkRelationshipField item={item} updateItem={updateItem} showError={showError} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ItemEditorV2AbutmentMaterialField
                        item={item}
                        updateItem={updateItem}
                        showError={showError}
                        isInternal={isInternal}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ItemEditorV2ImplantSystemFields
                        item={item}
                        updateItem={updateItem}
                        showError={showError}
                        implantSystems={implantSystems}
                    />
                </Grid>
            </Grid>
        </ItemEditorV2SectionWrapper>
    );
};
