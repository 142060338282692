import type { LabsGqlFinalizeTreatmentPlanRequestMutationVariables } from '@orthly/graphql-operations';
import { useFinalizeTreatmentPlanRequestMutation } from '@orthly/graphql-react';
import type { LabsGqlFinalizeTreatmentPlanRequestCommand } from '@orthly/graphql-schema';
import { LabsGqlTreatmentPlanType } from '@orthly/graphql-schema';
import { useChangeSubmissionFn } from '@orthly/ui';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, Button } from '@orthly/ui-primitives';
import React from 'react';

type FinalizePlanVars = LabsGqlFinalizeTreatmentPlanRequestMutationVariables['data'];

export const SendToDoctorConfirmationDialog: React.VFC<{
    open: boolean;
    handleClose: () => void;
    refetch: () => Promise<unknown>;
    orderId: string;
    softsmileVersion: number;
    nextVersionNum: number;
}> = ({ open, handleClose, refetch, orderId, softsmileVersion, nextVersionNum }) => {
    const [submitPlanMtn] = useFinalizeTreatmentPlanRequestMutation();
    const { submit: submitPlan } = useChangeSubmissionFn<any, [FinalizePlanVars]>(
        (data: FinalizePlanVars) => submitPlanMtn({ variables: { data } }),
        {
            closeOnComplete: true,
            successMessage: () => ['Treatment Plan Sent To Doctor', {}],
            onSuccess: () => {
                void refetch();
                handleClose();
            },
        },
    );

    const finalizeCommand: LabsGqlFinalizeTreatmentPlanRequestCommand = {
        order_id: orderId,
        softsmile_version_id: softsmileVersion,

        // These fields are unused for SoftSmile plans.
        url: '',
        duration_in_months: 0,
        plan_type: LabsGqlTreatmentPlanType.SoftSmile,
        steps: [],
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
            <DialogTitle
                id={'alert-dialog-title'}
            >{`Send Revision ${softsmileVersion} to the doctor for approval?`}</DialogTitle>
            <DialogContent>
                <DialogContentText>{`This treatment plan will be sent to the doctor as Version ${nextVersionNum}.`}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()} variant={`secondary`}>
                    Cancel
                </Button>
                <Button onClick={() => submitPlan(finalizeCommand)} variant={`primary`}>
                    Send to doctor
                </Button>
            </DialogActions>
        </Dialog>
    );
};
