import { SimpleTextField } from '@orthly/ui';
import { Text, stylesFactory, Dialog, Grid, IconButton, Button, FlossPalette, Icon } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    paper: {
        height: 640,
        width: 960,
        maxWidth: 960,
        overflowX: 'hidden',
    },
    wrapper: {
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
    },
    leftHalf: {
        display: 'grid',
        gridTemplateRows: 'auto auto auto auto 1fr auto',
        height: '100%',
        padding: '24px',
        gridRowGap: '8px',
    },
    rightHalf: {
        display: 'grid',
        gridTemplateRows: 'auto auto auto auto 1fr auto',
        height: '100%',
        borderLeft: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        backgroundColor: FlossPalette.TAN,
        padding: '24px',
        position: 'relative',
        gridRowGap: '8px',
    },
    closeIconWrapper: {
        position: 'absolute',
        top: 8,
        right: 8,
        height: 24,
        cursor: 'pointer',
        color: FlossPalette.BLACK,
    },
    dialogContainer: {
        '& .MuiDialog-container .MuiDialog-paper': {
            margin: 8,
        },
    },
    reasonsBlock: {
        overflowY: 'scroll',
        overflowX: 'hidden',
        maxHeight: 400,
    },
    reasonsBlockInner: {
        height: '100%',
    },
    reasonRowIdx: {
        width: 24,
        margin: 'auto 8px auto 0px',
    },
    reasonRowText: {
        margin: 'auto 0',
    },
    reasonRowWrapper: {
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        padding: `8px 0px`,
        minHeight: 40,
    },
    submitButtonWrapper: {
        marginLeft: 'auto',
        marginTop: 'auto',
    },
}));

interface OrderDesignTaskValidationBypassModalFailureReasonProps {
    reason: string;
    idx: number;
}

const OrderDesignTaskValidationBypassModalFailureReason: React.VFC<
    OrderDesignTaskValidationBypassModalFailureReasonProps
> = ({ reason, idx }) => {
    const classes = useStyles();

    return (
        <Grid container direction={'row'} className={classes.reasonRowWrapper}>
            <Grid item className={classes.reasonRowIdx}>
                <Text variant={'body2'} medium color={'LIGHT_GRAY'}>
                    #{idx + 1}
                </Text>
            </Grid>
            <Grid item xs className={classes.reasonRowText}>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    {reason}
                </Text>
            </Grid>
        </Grid>
    );
};

interface OrderDesignTaskValidationBypassModalProps {
    failureReasons: string[];
    onBypass: (feedback: string) => void;
    onClose: () => void;
    submitDisabled: boolean;
}

export const OrderDesignTaskValidationBypassModal: React.VFC<OrderDesignTaskValidationBypassModalProps> = ({
    failureReasons,
    onBypass,
    onClose,
    submitDisabled,
}) => {
    const classes = useStyles();
    const [feedbackText, setFeedbackText] = React.useState<string | undefined>(undefined);

    return (
        <Dialog
            className={classes.dialogContainer}
            classes={{ paper: classes.paper }}
            open={true}
            onClose={onClose}
            PaperProps={{ style: { borderRadius: 8 } }}
        >
            <div className={classes.wrapper}>
                <div className={classes.leftHalf}>
                    <Text variant={'h5'} medium color={'BLACK'}>
                        Design File Errors
                    </Text>
                    <Text variant={'body2'} color={'GRAY'}>
                        We've spotted a few potential tweaks for this design:
                    </Text>
                    <Grid item container direction={'column'} className={classes.reasonsBlock}>
                        <div className={classes.reasonsBlockInner}>
                            {failureReasons.map((reason, idx) => (
                                <OrderDesignTaskValidationBypassModalFailureReason
                                    idx={idx}
                                    reason={reason}
                                    key={`validation_error_reason_${idx}`}
                                />
                            ))}
                        </div>
                    </Grid>
                    <Text variant={'body2'} color={'GRAY'}>
                        We encourage you to double check your work before submitting.
                    </Text>
                    <div />
                    <div>
                        <Button variant={'secondary'} onClick={onClose} fullWidth={false}>
                            Go Back
                        </Button>
                    </div>
                </div>
                <div className={classes.rightHalf}>
                    <IconButton onClick={onClose} className={classes.closeIconWrapper}>
                        <Icon icon={'XIcon'} />
                    </IconButton>
                    <Text variant={'body2'} medium color={'BLACK'}>
                        Path to Bypassing Errors
                    </Text>
                    <Text variant={'caption'} color={'DARK_GRAY'}>
                        If you're sure this design is on point, please take a moment to share any unique situations or
                        decisions you made. Your insights will be shared with your lead to enhance our automated checks
                        and guarantee designs align with our SOP:
                    </Text>
                    <SimpleTextField
                        onChange={setFeedbackText}
                        value={feedbackText}
                        label={''}
                        TextFieldProps={{
                            multiline: true,
                            rows: 6,
                            maxRows: 12,
                            placeholder: 'Enter feedback here...',
                        }}
                    />
                    <div />
                    <div className={classes.submitButtonWrapper}>
                        <Button
                            variant={'primary'}
                            onClick={() => {
                                // Should never happen, but for typesafety.
                                if (!feedbackText) {
                                    return;
                                }

                                onBypass(feedbackText);
                            }}
                            disabled={!feedbackText || submitDisabled}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
