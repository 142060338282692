import type { Filter } from '../../components/MultiFilterBuilder';
import { MultiFilterBuilder, GqlFragmentToFilter } from '../../components/MultiFilterBuilder';
import type { LabsGqlQcIssueFragment, LabsGqlCreateQcIssueMutationVariables } from '@orthly/graphql-operations';
import { useCreateQcIssueMutation, useQcIssuesQuery, useUpdateQcIssueMutation } from '@orthly/graphql-react';
import { LabsGqlFilterCombinator } from '@orthly/graphql-schema';
import MUITable from '@orthly/mui-table';
import {
    SimpleCheckbox,
    LoadBlocker,
    RootActionDialog,
    SimpleInput,
    useChangeSubmissionFn,
    makeLazyDialog,
} from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import {
    Button,
    FlossPalette,
    Text,
    Grid,
    IconButton,
    Tooltip,
    createStyles,
    makeStyles,
    DeleteIcon,
    EditIcon,
    ToggleOffIcon,
    ToggleOnIcon,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface SubIssue {
    name: string;
    is_fail_reason: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subIssue: {
            borderLeft: `5px solid ${theme.palette.primary.main}`,
            paddingLeft: 20,
            marginBottom: 10,
        },
    }),
);

interface QcIssueEditDialogProps {
    loading: boolean;
    initialValues?: LabsGqlQcIssueFragment;
    onSubmit: (data: CreateQcIssueVars) => void;
}

type CreateQcIssueVars = LabsGqlCreateQcIssueMutationVariables['data'];
const useQcIssueEditDialog = makeLazyDialog<QcIssueEditDialogProps>(props => {
    const classes = useStyles();
    const { loading, open, setOpen, initialValues, onSubmit } = props;
    const mode: 'create' | 'edit' = initialValues ? 'edit' : 'create';
    const [name, setName] = React.useState<string>(initialValues?.name ?? '');
    const [prompt, setPrompt] = React.useState<string>(initialValues?.prompt ?? '');
    const [subIssues, setSubIssues] = React.useState<SubIssue[]>(initialValues?.sub_issues ?? []);

    const [filters, setFilters] = React.useState<Filter[]>(
        initialValues?.filters
            ? initialValues?.filters?.map(GqlFragmentToFilter)
            : [{ criteria: [{}], changed: false }],
    );
    const [changed, setChanged] = React.useState<boolean>(false);

    // The mutation we use depends on the mode. Reset form state on success.
    // Derived statuses:
    const allCriteriaComplete = React.useMemo(
        () => _.every(filters, f => f.criteria.length === f.completedCriteria?.length),
        [filters],
    );

    const updateSubIssue = (idx: number, sub_issue: Partial<SubIssue>) => {
        const mapIndex = (sub: SubIssue, i: number) => {
            if (idx === i) {
                return { ...sub, ...sub_issue };
            }
            return sub;
        };

        setChanged(true);
        setSubIssues((subIssues: SubIssue[]) => subIssues.map(mapIndex));
    };

    const createStringSetter = (func: (val: string) => void, defaultVal: string) => {
        return (v?: string) => {
            setChanged(true);
            func(v ?? defaultVal);
        };
    };

    const onClickAdd = () => {
        setSubIssues(subIssues => [...subIssues, { name: '', is_fail_reason: false }]);
    };

    return (
        <RootActionDialog
            title={mode === `edit` ? `Edit QC Issue` : `Create New QC Issue`}
            open={open}
            setOpen={setOpen}
            loading={loading}
            buttonText={``}
            CustomButton={() => null}
            titleAction={
                <Button
                    variant={'primary'}
                    disabled={!allCriteriaComplete || name === '' || prompt === '' || !changed}
                    onClick={() => {
                        onSubmit({
                            name,
                            prompt,
                            sub_issues: subIssues,
                            filters: filters.map(f => ({
                                criteria: f.completedCriteria ?? [],
                                combinator: LabsGqlFilterCombinator.And,
                            })),
                        });
                    }}
                >
                    {mode === 'edit' ? 'Save' : 'Create'}
                </Button>
            }
            // TODO(albertl) can we abuse QuickForm from @orthly/ui to make this simpler?
            content={
                <Grid container direction={'column'}>
                    <Text variant={'body2'} style={{ paddingBottom: '8px' }}>
                        {'Name'}
                    </Text>
                    <SimpleInput
                        label={'Name'}
                        onChange={createStringSetter(setName, ``)}
                        value={name}
                        variant={'standard'}
                    />

                    <Grid style={{ paddingBottom: '12px' }} />

                    <Text variant={'body2'} style={{ paddingBottom: '8px' }}>
                        {'Prompt'}
                    </Text>
                    <SimpleInput
                        label={'Prompt'}
                        onChange={createStringSetter(setPrompt, '')}
                        value={prompt}
                        variant={'standard'}
                    />

                    <Grid style={{ paddingBottom: '12px' }} />

                    <Text variant={'body2'}>{'Sub-Issues'}</Text>

                    {subIssues.map((sub_issue, idx) => (
                        <Grid container key={idx} xs={11} className={classes.subIssue} direction={'column'}>
                            <Grid container direction={'row'}>
                                <SimpleInput
                                    label={'Name'}
                                    onChange={v => updateSubIssue(idx, { name: v })}
                                    value={sub_issue.name}
                                    variant={'standard'}
                                />
                                <IconButton
                                    edge={'end'}
                                    aria-label={'delete'}
                                    onClick={() => setSubIssues(subIssues => subIssues.filter((_si, i) => i !== idx))}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                            <SimpleCheckbox
                                checked={sub_issue.is_fail_reason}
                                setChecked={v => updateSubIssue(idx, { is_fail_reason: v })}
                                label={'Is fail reason?'}
                            />
                        </Grid>
                    ))}

                    <Button style={{ marginTop: 10 }} variant={'primary'} type={'button'} onClick={onClickAdd}>
                        {'Add Sub-Issue'}
                    </Button>

                    <Text style={{ paddingTop: '8px' }} variant={'h6'}>
                        {'Filters'}
                    </Text>
                    <Text variant={'body2'}>
                        {'This QC Item will be added to any orders that pass at least one of the filters below.'}
                    </Text>
                    <MultiFilterBuilder
                        filters={filters}
                        setFilters={f => {
                            setChanged(true);
                            setFilters(f);
                        }}
                    />
                </Grid>
            }
        />
    );
});

interface QcIssueActionsProps {
    qcIssueFragment: LabsGqlQcIssueFragment;
    refetch: () => Promise<unknown>;
}

export const QcIssueActions: React.VFC<QcIssueActionsProps> = props => {
    const { qcIssueFragment, refetch } = props;
    const { id, archived } = qcIssueFragment;
    const [rawUpdate] = useUpdateQcIssueMutation();

    const qcIssueUpdateValues: LabsGqlQcIssueFragment = React.useMemo(() => {
        return {
            ..._.omit(qcIssueFragment, ['__typename'] as const),
            sub_issues: qcIssueFragment.sub_issues.map(subIssue => _.omit(subIssue, ['__typename'] as const)),
            filters: qcIssueFragment?.filters?.map(filter => {
                return { ..._.omit(filter, '__typename'), criteria: filter.criteria.map(c => _.omit(c, '__typename')) };
            }),
        };
    }, [qcIssueFragment]);
    const { submit: update, submitting: updating } = useChangeSubmissionFn(rawUpdate, {
        closeOnComplete: true,
        successMessage: () => [`QC issue updated!`, {}],
        onSuccess: () => refetch(),
    });
    const setArchived = (archived: boolean) =>
        update({ variables: { data: { ..._.omit(qcIssueUpdateValues, ['created_at', 'updated_at']), archived } } });
    const [setEditModalOpen, editModal] = useQcIssueEditDialog({
        loading: updating,
        initialValues: qcIssueUpdateValues,
        onSubmit: async values => {
            await update({ variables: { data: { ...values, archived, id } } });
            setEditModalOpen(false);
        },
    });

    return (
        <>
            <Tooltip title={archived ? `Unarchive` : `Archive`}>
                <IconButton
                    onClick={() => setArchived(!archived)}
                    disabled={updating}
                    style={{ color: archived ? FlossPalette.DARK_TAN : FlossPalette.STAR_GRASS }}
                >
                    <LoadBlocker blocking={updating} loader={`circular`}>
                        {archived ? <ToggleOffIcon /> : <ToggleOnIcon />}
                    </LoadBlocker>
                </IconButton>
            </Tooltip>
            <Tooltip title={`Edit`}>
                <IconButton onClick={() => setEditModalOpen(true)} disabled={updating}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            {editModal}
        </>
    );
};

export const QcIssuesConfigForm: React.VFC = () => {
    const { data: { listQcIssues: qcIssueFragments = [] } = {}, refetch } = useQcIssuesQuery();
    const [rawCreate] = useCreateQcIssueMutation();
    const { submit: save, submitting: saving } = useChangeSubmissionFn(rawCreate, {
        closeOnComplete: true,
        successMessage: () => [`QC issue created!`, {}],
        onSuccess: () => refetch(),
    });
    const [setCreating, createModal] = useQcIssueEditDialog({
        loading: saving,
        onSubmit: async values => {
            await save({ variables: { data: values } });
            setCreating(false);
        },
    });

    return (
        <Grid container>
            <MUITable<LabsGqlQcIssueFragment>
                title={`QC issues`}
                data={qcIssueFragments}
                displayOptions={{
                    fixedSearch: true,
                    elevation: 0,
                    viewColumns: true,
                    filter: true,
                    sort: true,
                }}
                actions={{
                    global: [
                        {
                            icon: `refresh`,
                            position: `toolbar`,
                            onClick: () => refetch()?.catch(console.error),
                            tooltip: `Refresh`,
                        },
                        { icon: `add`, position: `toolbar`, onClick: () => setCreating(true), tooltip: `Create` },
                    ],
                }}
                columns={[
                    { name: `Name`, render: `name` },
                    { name: `Prompt`, render: `prompt` },
                    { name: `Options`, render: qcIssueFragment => qcIssueFragment.options.join(`, `) },
                    { name: 'Filter Count', type: 'numeric', render: row => row.filters.length },
                    { name: `Last Updated`, render: `updated_at`, type: `datetime` },
                    {
                        name: `Actions`,
                        render: fragment => <QcIssueActions qcIssueFragment={fragment} refetch={refetch} />,
                    },
                ]}
            />
            {createModal}
        </Grid>
    );
};
