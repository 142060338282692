import { graphql } from '@orthly/graphql-inline-react';

export const GetOrderPriceHistoryItems_Query = graphql(`
    query GetOrderPriceHistoryItems($orderId: String!) {
        getOrderPriceHistoryItems(orderId: $orderId) {
            title
            date
            details {
                label
                value
            }
            item_details {
                item_id
                name
                display_price
            }
            actor {
                actor
                actor_id
                user_email
                user_display_text
                automation_id
            }
        }
    }
`);

export const GetInvoiceHistoryItems_Query = graphql(`
    query GetInvoiceHistoryItems($invoiceId: String!) {
        getInvoiceHistoryItems(invoiceId: $invoiceId) {
            title
            date
            details {
                label
                value
            }
            item_details {
                item_id
                name
                display_price
            }
            actor {
                actor
                actor_id
                user_email
                user_display_text
                automation_id
            }
        }
    }
`);
