import { OTHER_CREDIT_CATEGORY_ID } from './CreditOrRefund.types';
import { useCreditOrRefundFormContext } from './providers/CreditOrRefundFormProvider.graphql';
import { SimpleInput } from '@orthly/ui';
import { FlossPalette, Icon, InputAdornment, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const RequiredLabel = styled(Text)({
    '&::after': {
        color: FlossPalette.ATTENTION,
        content: '"*"',
    },
});

const Stacked = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

export const CreditOrRefundNotesInput: React.FC = () => {
    const { formState, dispatchFormStateAction, viewOnly } = useCreditOrRefundFormContext();
    return (
        <Stacked>
            {formState.category === OTHER_CREDIT_CATEGORY_ID ? (
                <RequiredLabel variant={'body2'} medium>
                    Please Indicate Why You Chose "Other"
                </RequiredLabel>
            ) : (
                <Text variant={'body2'} medium>
                    Add an Internal Note
                </Text>
            )}
            <SimpleInput
                flossInputConfig={{ backgroundColor: 'gray', bordered: true }}
                onChange={value => dispatchFormStateAction({ type: 'SET_DESCRIPTION', description: value })}
                value={formState.description}
                disabled={viewOnly}
                label={''}
                placeholder={`Add a Note ${formState.category === OTHER_CREDIT_CATEGORY_ID ? '' : '(Optional)'}`}
                TextFieldProps={{
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position={'start'}>
                                <Icon icon={'PencilOutlinedIcon'} />
                            </InputAdornment>
                        ),
                    },
                }}
                fullWidth
            />
        </Stacked>
    );
};
