import { RefabFlowProvider, useRefabFlowSelector } from '../state/refab-flow-state';
import { RefabAdditionalNotes } from './refab-flow-additional-notes';
import { AttachExistingScans } from './refab-flow-attach-existing-scans';
import { ConfirmationScreen } from './refab-flow-confirmation';
import type { RefabFlowContextShape } from './refab-flow-context';
import { RefabFlowContextProvider } from './refab-flow-context';
import { RefabInfoScreen } from './refab-flow-info';
import { ItemReasonCodesScreen } from './refab-flow-item-codes';
import { ItemSelectionScreen } from './refab-flow-item-selection';
import { ItemShadeAttachments } from './refab-flow-item-shade-attachments';
import { ItemShadeChanges } from './refab-flow-item-shade-changes';
import { SupplementalScreenStepOne } from './refab-flow-supplemental-step-one';
import { SupplementalScreenStepTwo } from './refab-flow-supplemental-step-two';
import React from 'react';

const RefabCurrentScreen: React.VFC = () => {
    const screen = useRefabFlowSelector(s => s.screen);

    switch (screen) {
        case 'selection':
            return <ItemSelectionScreen />;
        case 'reasonCodes':
            return <ItemReasonCodesScreen />;
        case 'shade_attachments':
            return <ItemShadeAttachments />;
        case 'shade_change_requests':
            return <ItemShadeChanges />;
        case 'attach_existing_scans':
            return <AttachExistingScans />;
        case 'supplemental_info_step_one':
            return <SupplementalScreenStepOne />;
        case 'supplemental_info_step_two':
            return <SupplementalScreenStepTwo />;
        case 'additional_notes':
            return <RefabAdditionalNotes />;
        case 'info':
            return <RefabInfoScreen />;
        case 'confirmation':
            return <ConfirmationScreen />;
        default:
            return null;
    }
};

export type { RefabAttachment } from '../state/refab-flow-types';
export type { RefabOrReturnSubmitData, RefabOrReturnSubmitResult } from './refab-flow-context';

export const RefabFlowWrapper: React.VFC<RefabFlowContextShape> = context => {
    return (
        <RefabFlowProvider>
            <RefabFlowContextProvider {...context}>
                <RefabCurrentScreen />
            </RefabFlowContextProvider>
        </RefabFlowProvider>
    );
};
