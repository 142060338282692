import type { ToolbarActionDefinition, ToolbarActionProps } from './ToolbarActionDefinition';
import { usePendingReturnForOrderQuery, useConfirmReturnReceivedMutation } from '@orthly/graphql-react';
import { UuidUtils } from '@orthly/runtime-utils';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

const MarkRefabReturnReceivedButton: React.VFC<ToolbarActionProps> = props => {
    const { order, open, setOpen, refetchOrder } = props;
    const { data, refetch: refetchReturn } = usePendingReturnForOrderQuery({
        skip: !UuidUtils.isUUID(order.id),
        variables: {
            orderId: order.id,
        },
    });

    const linkedReturnId = data?.pendingReturnForOrder?.id ?? '';

    const [submitMtn] = useConfirmReturnReceivedMutation({
        variables: { returnId: linkedReturnId },
    });
    const { submit, submitting, called } = useChangeSubmissionFn<any, []>(submitMtn, {
        closeOnComplete: true,
        successMessage: () => [`Successfully marked return as received`, {}],
        errorMessage: () => [`Failed to mark return as received, please try again`, {}],
        onSuccess: () => {
            void refetchOrder();
            void refetchReturn();
        },
    });

    React.useEffect(() => {
        if (open && !submitting && !called && linkedReturnId) {
            if (window.confirm('Mark order returned?')) {
                void submit();
            }
            setOpen(false);
        }
    }, [open, submitting, submit, called, setOpen, linkedReturnId]);

    return <div />;
};

export const MarkRefabReturnReceivedToolbarAction: ToolbarActionDefinition = {
    label: 'Mark Return Received',
    Component: MarkRefabReturnReceivedButton,
};
