import type { LabsGqlICustomFieldSubmissionInput, LabsGqlSetDoctorPreferencesCommand } from '@orthly/graphql-schema';
import type { ICustomFieldSubmission } from '@orthly/items';
import { PreferenceItemMetafields } from '@orthly/items';

export interface PreferenceFieldValues {
    [fieldId: string]: string | number | boolean | null | undefined;
}

export class PreferenceFieldGqlUtils {
    // used to initialize a form with existing custom field submissions
    static fieldArrayToFormValues(submissions: ICustomFieldSubmission[]): PreferenceFieldValues {
        return PreferenceItemMetafields.reduce((result, field) => {
            const submission = submissions.find(s => s.field_id === field.id);
            if (submission && ['string', 'boolean'].includes(typeof submission.value)) {
                return {
                    ...result,
                    [field.id]:
                        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                        // eslint-disable-next-line no-nested-ternary
                        typeof submission.value === 'boolean' ? (submission.value ? 'Yes' : 'No') : submission.value,
                };
            }
            if (field.default_value !== null) {
                return {
                    ...result,
                    [field.id]: field.default_value,
                };
            }
            return result;
        }, {});
    }

    // transform form field results to custom field submissions
    static preferenceFormValuesToFieldArray(fields: PreferenceFieldValues): LabsGqlICustomFieldSubmissionInput[] {
        return Object.entries(fields).reduce((submitFields, [field_id, value]) => {
            if (typeof value === 'string' || typeof value === 'boolean') {
                const fieldDef = PreferenceItemMetafields.find(c => c.id === field_id);
                if (!fieldDef) {
                    return submitFields;
                }
                const fieldVal = fieldDef.type === 'boolean' && typeof value === 'string' ? value === 'Yes' : value;
                return [...submitFields, { field_id, value: fieldVal, display_name: fieldDef.label }];
            }
            return submitFields;
        }, [] as LabsGqlICustomFieldSubmissionInput[]);
    }

    // transform form results to a mutation for setting preferences
    static preferencesFormResultToMutation(
        val: LabsGqlSetDoctorPreferencesCommand & PreferenceFieldValues,
    ): LabsGqlSetDoctorPreferencesCommand {
        const {
            preference_set_id,
            name,
            test_order_default,
            contact_tightness,
            mailing_address_id,
            contact_phone,
            contact_email,
            custom_field_preferences,
            ...values
        } = val;
        return {
            preference_set_id,
            name,
            test_order_default,
            contact_tightness,
            mailing_address_id,
            contact_email,
            contact_phone,
            custom_field_preferences: PreferenceFieldGqlUtils.preferenceFormValuesToFieldArray(values),
        };
    }
}
