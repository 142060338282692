import { useOrderDetailPreferences } from '../../../hooks';
import { ALIGNER_PREFERENCE_FIELDS } from '../utils/alignerItem.util';
import { DENTURES_PREFERENCE_FIELDS } from '../utils/dentureItem.util';
import { PARTIALS_PREFERENCE_FIELDS } from '../utils/partialsItem.util';
import { TOP_LEVEL_PREFERENCE_FIELDS, QUANTITY_FIELDS } from '../utils/removableItem.util';
import { PrintableSlipSectionDivider } from './shared/PrintableSlipSectionDivider';
import { PrintableSlipSectionTitle } from './shared/PrintableSlipSectionTitle';
import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import { Text, Grid, makeStyles } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles({
    preferenceEntry: {
        fontSize: '0.75rem !important',
    },
});

interface IPrintableSlipPreferencesProps {
    order: LabsGqlLabOrderForLabSlipFragment;
}

const PREFERENCES_TO_IGNORE = [
    // Handled by RemovableItem
    ...Object.keys(TOP_LEVEL_PREFERENCE_FIELDS),
    ...QUANTITY_FIELDS,
    // Handled by PartialsItem
    ...PARTIALS_PREFERENCE_FIELDS,
    ...DENTURES_PREFERENCE_FIELDS,
    ...ALIGNER_PREFERENCE_FIELDS,
    'diagnostic-model-teeth-selection-order-item-meta',
    'waxup-teeth-selection-order-item-meta',
];

/**
 * Preferences section of the Printable Slip.
 * Renders a section of the Printable Slip that contains any deviations
 * from the "Standard (Dandy) Preferences"
 * @param props
 * @returns Printable Slip section with order preferences
 */
export const PrintableSlipPreferences: React.VFC<IPrintableSlipPreferencesProps> = props => {
    const classes = useStyles();

    // Get order preferences using useOrderDetailPreferences hook.
    // This is the hook used on the Order Details page. Here, we pass in the same options.
    const items = (props.order?.items_v2 ?? []).map(item => ({
        preference_fields: item.preference_fields.filter(pref => !PREFERENCES_TO_IGNORE.includes(pref.field_id)),
    }));
    const preferenceEntries = useOrderDetailPreferences(items, { showNumericValues: true, isOps: true });

    if (preferenceEntries.length === 0) {
        return null;
    }

    return (
        <>
            <Grid container>
                <PrintableSlipSectionTitle text={'Nonstandard Preferences'} />
                <Grid container>
                    {preferenceEntries.map((entry, idx) => {
                        return (
                            <Grid container key={idx}>
                                <Grid item xs={5} style={{ paddingRight: 5 }}>
                                    <Text variant={'body1'} color={'DARK_GRAY'} className={classes.preferenceEntry}>
                                        {entry.name}
                                    </Text>
                                </Grid>
                                <Grid item xs={7}>
                                    <Text variant={'body1'} color={'GRAY'} className={classes.preferenceEntry}>
                                        {entry.value}
                                    </Text>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
            <PrintableSlipSectionDivider />
        </>
    );
};
