import { PrintableSlipSectionTitle } from '../../../PrintableSlip/components/shared/PrintableSlipSectionTitle';
import { Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface UnboxingSlipHeaderTextProps {
    label: string;
    value: string;
    layout: '4x6' | 'letter';
}

/**
 * Renders half width text with label in the header section of the Unboxing Slip
 */
export const UnboxingSlipHeaderTopHalfText: React.VFC<UnboxingSlipHeaderTextProps> = ({ label, layout, value }) => (
    <Grid item xs={6}>
        <PrintableSlipSectionTitle text={label} />
        <Text variant={'h6'} style={{ fontSize: layout === '4x6' ? 18 : '1rem !important' }}>
            {value}
        </Text>
    </Grid>
);
