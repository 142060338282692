/* eslint-disable no-nested-ternary */
import { CloseActionDialog } from '../../../screens/Orders/screens/OrderDetail/components/OrderTickets/CloseActionDialog';
import { useOpenOrderDetailOps } from '../../../utils/router/useOpenOrderDetail';
import type { ChoiceData } from '../../../utils/useTicketActionHooks';
import type { LabsGqlTicketDataFragment } from '@orthly/graphql-operations';
import {
    useClaimTicketActionMutation,
    useCloseTicketActionMutation,
    useFilteredTicketsQuery,
} from '@orthly/graphql-react';
import type { LabsGqlTicketActionDto } from '@orthly/graphql-schema';
import { LabsGqlActionStatus, LabsGqlTicketActionCompletionResolution } from '@orthly/graphql-schema';
import { useRootActionCommand, OverflowTextWithTooltip, CalendarIcon, LoadBlocker } from '@orthly/ui';
import {
    Text,
    FlossPalette,
    Tooltip,
    CircularProgress,
    Box,
    createStyles,
    makeStyles,
    Menu,
    MenuItem,
    CheckCircleIcon,
    MoreVertIcon,
    PlayCircleFilledIcon,
} from '@orthly/ui-primitives';
import { AssignmentAvatar, useCopyToClipboard } from '@orthly/veneer';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const ticketContainer: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row nowrap',
    padding: '16px 24px',
    alignItems: 'center',
    boxShadow: '0px 1px 0px #ECEAE6',
};

const useStyles = makeStyles(() =>
    createStyles({
        completeAction: {
            marginLeft: 4,
            marginRight: 12,
            width: 24,
            height: 24,
            borderWidth: 2,
            borderRadius: 50,
            borderStyle: 'solid',
            borderColor: FlossPalette.GRAY,
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: FlossPalette.DARK_TAN,
            },
        },
    }),
);

const MoreOptions: React.FC<{ ticket: LabsGqlTicketDataFragment; refetch: () => void }> = props => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [closeIntent, setCloseIntent] = React.useState<'complete' | null>(null);
    const copyToClipboard = useCopyToClipboard();

    return (
        <>
            <Tooltip title={'More Actions'} onClick={e => setAnchorEl(e.currentTarget)}>
                <MoreVertIcon />
            </Tooltip>
            <Menu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem
                    onClick={() => {
                        copyToClipboard({ text: props.ticket.order_id });
                        setAnchorEl(null);
                    }}
                >
                    Copy Order ID
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        copyToClipboard({ text: props.ticket.partner_id });
                        setAnchorEl(null);
                    }}
                >
                    Copy Practice ID
                </MenuItem>
                {props.ticket.active_action && (
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                            setCloseIntent('complete');
                        }}
                    >
                        Advanced Complete
                    </MenuItem>
                )}
            </Menu>
            {closeIntent && props.ticket.active_action && (
                <CloseActionDialog
                    ticket={props.ticket}
                    action={props.ticket.active_action}
                    intent={closeIntent}
                    onClose={() => {
                        props.refetch();
                        setCloseIntent(null);
                    }}
                />
            )}
        </>
    );
};

const CompleteAction: React.FC<{
    ticket: LabsGqlTicketDataFragment;
    action: LabsGqlTicketActionDto;
    refetch: () => void;
}> = props => {
    const styles = useStyles();
    const { submit, submitting } = useRootActionCommand(useCloseTicketActionMutation(), {
        successMessage: 'Action completed!',
        onSuccess: () => props.refetch(),
    });

    return (
        <div>
            {submitting ? (
                <LoadBlocker loader={'circular'} blocking={submitting} />
            ) : props.action.closed_at ? (
                <Tooltip title={'Ticket Completed'}>
                    <CheckCircleIcon color={'action'} />
                </Tooltip>
            ) : (
                <Tooltip title={'Complete action'}>
                    <div
                        className={styles.completeAction}
                        onClick={() =>
                            submit({
                                data: {
                                    ticket_id: props.ticket.id,
                                    action_id: props.action.id,
                                    status: LabsGqlActionStatus.Completed,
                                    resolution: LabsGqlTicketActionCompletionResolution.Proceed,
                                },
                            })
                        }
                    />
                </Tooltip>
            )}
        </div>
    );
};

const AssigneeButton: React.FC<{
    ticket: LabsGqlTicketDataFragment;
    refetch: () => void;
    action: Pick<LabsGqlTicketActionDto, 'assigned_user_id' | 'closed_at' | 'id'>;
    choiceData: ChoiceData;
}> = ({ ticket, action, choiceData, refetch }) => {
    const assignedUser = choiceData.users.get(action.assigned_user_id ?? '');
    const { submit, submitting } = useRootActionCommand(useClaimTicketActionMutation(), {
        successMessage: 'Action Claimed',
        onSuccess: () => refetch(),
    });

    return (
        <>
            {submitting ? (
                <CircularProgress />
            ) : (
                <>
                    {assignedUser && (
                        <Tooltip title={assignedUser.display}>
                            <Box style={{ width: '20px', height: '20px' }}>
                                <AssignmentAvatar user={assignedUser} />
                            </Box>
                        </Tooltip>
                    )}
                    {!action.assigned_user_id && !action.closed_at && (
                        <Tooltip title={'Claim'}>
                            <PlayCircleFilledIcon
                                htmlColor={FlossPalette.GRAY}
                                style={{ width: '26px', height: '26px' }}
                                className={'dnd-orderAction-actionable'}
                                onClick={() => submit({ data: { ticket_id: ticket.id, action_id: action.id } })}
                            />
                        </Tooltip>
                    )}
                </>
            )}
        </>
    );
};

const ActionDescription: React.FC<{ action: LabsGqlTicketActionDto; choiceData: ChoiceData }> = props => {
    const actionType = props.choiceData.actionTypes.get(props.action.type_id);

    return (
        <div>
            {actionType && (
                <div>
                    <Text medium variant={'body2'}>
                        {props.action.followup_parent_id ? 'Follow up: ' : ''}
                        {actionType.name}
                    </Text>
                    <Text
                        color={'DARK_GRAY'}
                        variant={'caption'}
                    >{`${actionType.category_name} > ${actionType.subcategory_name}`}</Text>
                </div>
            )}
        </div>
    );
};

const OrderInfo: React.FC<{ ticket: LabsGqlTicketDataFragment }> = props => {
    const openOrder = useOpenOrderDetailOps({ absolute: true });

    return (
        <div style={{ cursor: 'pointer' }} onClick={e => openOrder(props.ticket.order_id, e)}>
            <OverflowTextWithTooltip text={props.ticket.items_summary ?? ''} color={'BLACK'} variant={'body2'} />
            <Text color={'DARK_GRAY'} variant={'caption'}>
                {props.ticket.patient_name}
            </Text>
        </div>
    );
};

const PracticeInfo: React.FC<{ ticket: LabsGqlTicketDataFragment; choiceData: ChoiceData }> = props => {
    const practice = props.choiceData.practices.get(props.ticket.partner_id);

    return (
        <div>
            {practice && (
                <div>
                    <Text variant={'body2'}>{props.ticket.doctor_name}</Text>
                    <Text color={'DARK_GRAY'} variant={'caption'}>
                        {practice.display}
                    </Text>
                </div>
            )}
        </div>
    );
};

const ManufacturerInfo: React.FC<{ ticket: LabsGqlTicketDataFragment; choiceData: ChoiceData }> = props => {
    const manufacturer = props.choiceData.manufacturers.get(props.ticket.manufacturer_id ?? 'Invalid');

    return (
        <div>
            {manufacturer && (
                <div>
                    <Text variant={'body2'}>{manufacturer.display}</Text>
                    <Text color={'DARK_GRAY'} variant={'caption'}>
                        {_.startCase(props.ticket.order_status ?? '')}
                    </Text>
                </div>
            )}
        </div>
    );
};

const DueDateInfo: React.FC<{ action: LabsGqlTicketActionDto }> = props => {
    if (props.action.due_at === undefined || props.action.due_at === null) {
        return <div />;
    }

    const dueAt = moment(props.action.due_at);
    const isLate = dueAt.isAfter(moment.now());

    const activateAt = props.action.activate_at ? moment(props.action.activate_at) : null;

    return (
        <div style={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center' }}>
            <CalendarIcon color={!isLate ? 'error' : 'inherit'} />
            <div style={{ paddingLeft: '8px' }}>
                <Text variant={'body2'} color={!isLate ? 'REVIEW_RED' : undefined}>
                    {dueAt.format('[Due ] MM/DD [ at ] h:mmA')}
                </Text>
                {activateAt ? <Text variant={'caption'}>Start at {activateAt.format('MM/DD h:mmA')}</Text> : null}
            </div>
        </div>
    );
};

const Ticket: React.FC<{ ticket: LabsGqlTicketDataFragment; choiceData: ChoiceData }> = props => {
    const { choiceData } = props;
    const [invalidatedTicketState, setInvalidatedTicketState] = React.useState<boolean>(false);
    const { data: refetchedData, refetch: refetchData } = useFilteredTicketsQuery({
        skip: !invalidatedTicketState,
        variables: {
            input: {
                pagination: { page: 1, per_page: 1 },
                filters: {
                    ticket_ids: [props.ticket.id],
                },
            },
        },
    });

    const refetch = () => {
        setInvalidatedTicketState(true);
        void refetchData().then(_e => setInvalidatedTicketState(false));
    };

    const ticket = refetchedData ? refetchedData.filteredTickets[0] : props.ticket;
    const action: LabsGqlTicketActionDto | undefined = ticket?.active_action ?? ticket?.actions[0];

    if (!ticket) {
        return <div>Error! Ticket Lost?</div>;
    }

    return (
        <div style={{ ...ticketContainer, opacity: ticket.is_open ? '1' : '0.5' }}>
            <div
                style={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    alignItems: 'center',
                    minWidth: '180px',
                    maxWidth: '280px',
                }}
            >
                <div style={{ marginLeft: '18px', width: '24px' }}>
                    {action ? <CompleteAction ticket={ticket} action={action} refetch={refetch} /> : null}
                </div>
                <div style={{ marginLeft: '18px', minWidth: '24px', display: 'flex' }}>
                    {action ? (
                        <AssigneeButton choiceData={choiceData} ticket={ticket} action={action} refetch={refetch} />
                    ) : null}
                </div>
                <div style={{ marginLeft: '18px', minWidth: '180px' }}>
                    {action ? <ActionDescription action={action} choiceData={props.choiceData} /> : null}
                </div>
            </div>
            <div style={{ marginLeft: '18px', width: '200px' }}>
                {action && !action?.closed_at ? <DueDateInfo action={action} /> : null}
            </div>
            <div style={{ marginLeft: '18px', width: '180px' }}>
                <PracticeInfo ticket={ticket} choiceData={props.choiceData} />
            </div>
            <div style={{ marginLeft: '18px', width: '220px' }}>
                <OrderInfo ticket={ticket} />
            </div>
            <div style={{ marginLeft: '18px', width: '180px' }}>
                <ManufacturerInfo ticket={ticket} choiceData={props.choiceData} />
            </div>
            <div style={{ marginLeft: '18px', width: '24px' }}>
                <MoreOptions ticket={ticket} refetch={refetch} />
            </div>
        </div>
    );
};

export const TicketTable: React.FC<{ tickets: LabsGqlTicketDataFragment[]; choiceData: ChoiceData }> = props => {
    return (
        <table style={{ width: '100%' }}>
            {props.tickets.map(ticket => (
                <Ticket key={ticket.id} ticket={ticket} choiceData={props.choiceData} />
            ))}
        </table>
    );
};
