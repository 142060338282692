import type { Point } from '../Util';
import {
    HIT_TEST_MARGIN,
    vectorDifference,
    vectorMultiply,
    vectorNormal,
    vectorRotate,
    vectorSum,
    pointEquals,
} from '../Util';
import type { Handle } from './CanvasObject';
import { CanvasObject } from './CanvasObject';

const LINE_WIDTH = 5;
// The arrowhead length.
const HEAD_LENGTH = 10;
// Half the arrowhead angle, in radians.
const HEAD_HALF_ANGLE = (Math.PI * 25) / 180;

export type LineType = 'line' | 'arrow';

export interface LineParams {
    type: LineType;
    color: string;
}

export class LineObject extends CanvasObject {
    private params: LineParams;
    private startPoint: Point;
    private endPoint: Point;

    constructor(params: LineParams, startPoint: Point, endPoint: Point) {
        super();
        this.params = params;
        this.startPoint = startPoint;
        this.endPoint = endPoint;
    }

    draw(ctx: CanvasRenderingContext2D, hitTestColor?: string) {
        ctx.lineWidth = LINE_WIDTH + (hitTestColor ? 2 * HIT_TEST_MARGIN : 0);
        ctx.strokeStyle = hitTestColor ?? this.params.color;
        ctx.fillStyle = hitTestColor ?? this.params.color;
        ctx.lineJoin = 'miter';

        const path = LineObject.makePath(this.startPoint, this.endPoint, this.params.type);
        ctx.stroke(path);
        ctx.fill(path);
    }

    private handle(center: Point, setter: (point: Point) => void): Handle {
        return {
            center,
            cursor: 'default',
            actionForMove: (point: Point) => {
                return () => {
                    setter(point);
                };
            },
        };
    }

    handles() {
        return [
            this.handle(this.startPoint, pt => {
                this.startPoint = pt;
            }),
            this.handle(this.endPoint, pt => {
                this.endPoint = pt;
            }),
        ];
    }

    grabHandle(): Handle {
        const { startPoint, endPoint } = this;
        return {
            center: [0, 0],
            cursor: 'grabbing',
            actionForMove: (point: Point) => {
                return () => {
                    this.startPoint = vectorSum(point, startPoint);
                    this.endPoint = vectorSum(point, endPoint);
                };
            },
        };
    }

    reshape(_start: Point, _end: Point) {}

    static makePath(startPoint: Point, endPoint: Point, type: LineType): Path2D {
        const path = new Path2D();

        path.moveTo(...startPoint);
        path.lineTo(...endPoint);

        if (type === 'arrow' && !pointEquals(startPoint, endPoint)) {
            // Make a length-10 vector from the tip of the arrow toward the back. We'll rotate it
            // a bit in each direction to find the corners of the arrowhead.
            const vecToRotate = vectorMultiply(vectorNormal(vectorDifference(startPoint, endPoint)), HEAD_LENGTH);
            const pt1 = vectorSum(endPoint, vectorRotate(vecToRotate, HEAD_HALF_ANGLE));
            const pt2 = vectorSum(endPoint, vectorRotate(vecToRotate, -HEAD_HALF_ANGLE));

            path.moveTo(...endPoint);
            path.lineTo(...pt1);
            path.lineTo(...pt2);
            path.closePath();
        }
        return path;
    }
}
