/* eslint-disable max-lines */
import { useFirebaseStorage } from '../../context';
import type { Annotation } from '../Annotations';
import { INITIAL_TOOL_STATE } from '../Annotations/DrawingControls';
import { OrderDesignPreview } from '../DesignViewer';
import { useGuidedQcAnalytics, useGuidedQcPresets } from './DesignQcCommon';
import { useDesignQcAction } from './state/DesignQc.actions';
import { useDesignQcPropSelector } from './state/DesignQc.context';
import { OrderAnalyticsContext } from '@orthly/analytics/dist/browser';
import type {
    MainViewCameraControlsRef,
    MainViewModelRef,
    ModelAppearance,
    TakeSnapshotRef,
    ToolType,
    ToolState,
    UndoState,
} from '@orthly/dentin';
import { MODEL_VIEWER_INITIAL_APPEARANCE, useDrawingCanvas, useRegisterHotKeys } from '@orthly/dentin';
import { OrderDesignPreviewDesign_FragmentFragmentDoc, getFragmentData } from '@orthly/graphql-inline-react';
import { DesignStorageConfigs, getFullStoragePath } from '@orthly/shared-types';
import {
    TextIcon,
    DesignArrowIcon,
    DesignCircleIcon,
    UndoIcon,
    RedoIcon,
    OrthlyBrowserConfig,
    RootActionDialog,
    SimpleTextField,
    DrawIcon,
} from '@orthly/ui';
import {
    FlossPalette,
    Text,
    Button,
    stylesFactory,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    RemoveIcon,
} from '@orthly/ui-primitives';
import clsx from 'clsx';
import type Firebase from 'firebase/compat';
import { useSnackbar } from 'notistack';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        height: '100vh',
        backgroundColor: FlossPalette.WHITE,
        position: 'relative',
        width: '100%',
        maxWidth: `calc(100vw - 700px)`,
    },
    bottomActionButton: {
        height: 24,
        color: FlossPalette.GRAY,
        padding: 4,
    },
    bottomActionWrapper: {
        position: 'absolute',
        bottom: 8,
        left: 64,
        paddingRight: 128,
    },
    markupToolbarRoot: {
        width: '100%',
    },
    markupToolbarMainToolsRow: {
        padding: `8px 16px`,
        height: 64,
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    },
    markupToolbarToolConfigRow: {
        padding: `8px 12px`,
        height: 40,
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    },
    markupToolbarToolConfigItem: {
        width: 24,
        height: 24,
        borderRadius: 8,
        cursor: 'pointer',
        // Set to white so the sizing doesn't change when the color does
        border: `2px solid ${FlossPalette.WHITE}`,
    },
    markupToolbarToolConfigItemSelected: {
        border: `2px solid ${FlossPalette.GRAY}`,
    },
    markupToolbarToolConfigItemColor: {
        height: 12,
        width: 12,
        borderRadius: 12,
        margin: `5px auto`,
    },
    markupToolbarIcon: {
        height: 24,
        width: 24,
    },
    markupToolbarIconSelected: {
        color: FlossPalette.PRIMARY_FOREGROUND,
    },
    markupToolbarIconWrapper: {
        margin: `auto 4px`,
    },
}));

async function uploadAnnotation(
    storage: Firebase.storage.Storage,
    storagePath: string,
    image: Blob,
): Promise<Annotation> {
    return new Promise((res, rej) => {
        storage
            .ref(storagePath)
            .put(image)
            .then(
                snapshot => {
                    res({
                        imageUrl: snapshot.ref.fullPath,
                    });
                },
                error => {
                    rej(error);
                },
            );
    });
}

function useUploadCurrentAnnotation(takeSnapshot: () => Promise<Blob | null>, orderId: string, designId: string) {
    const fullStoragePath = getFullStoragePath(
        OrthlyBrowserConfig.env,
        DesignStorageConfigs.designs,
        orderId,
        'design',
        designId,
        `annotation-${new Date().toISOString()}.png`,
    );
    const { enqueueSnackbar } = useSnackbar();
    const storage = useFirebaseStorage(fullStoragePath.bucketName);

    return React.useCallback(async (): Promise<Annotation | null> => {
        try {
            const blob = await takeSnapshot();
            if (!blob) {
                return null;
            }
            const annotation = await uploadAnnotation(storage, fullStoragePath.path, blob);
            return annotation;
        } catch (error) {
            enqueueSnackbar(`Error uploading annotation: ${error}`, { variant: 'error' });
            throw error;
        }
    }, [takeSnapshot, storage, fullStoragePath.path, enqueueSnackbar]);
}

function useDesignQcMarkup(takeSnapshotRef: TakeSnapshotRef) {
    const { order, designFragment } = useDesignQcPropSelector(['order', 'designFragment']);
    const addMarkupAction = useDesignQcAction('ADD_PENDING_MARKUP_URL');

    const [backgroundImageUrl, setBackgroundImageUrl] = React.useState<string | undefined>(undefined);
    const [isUploading, setIsUploading] = React.useState<boolean>(false);
    const [canvasSize, setCanvasSize] = React.useState<{ width: number; height: number }>({
        width: 400,
        height: 400,
    });

    const backgroundImageSizeCallback = React.useCallback(
        (width, height) => {
            setCanvasSize({ width, height });
        },
        [setCanvasSize],
    );

    const { takeSnapshot: captureAnnotations, ...markupsInternals } = useDrawingCanvas({
        ...canvasSize,
        initialToolState: INITIAL_TOOL_STATE,
        backgroundImageSizeCallback,
        backgroundImageUrl: backgroundImageUrl ?? undefined,
        backgroundColor: FlossPalette.WHITE,
        canvasProps: { style: { border: `unset` } },
    });

    const design = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, designFragment);
    const upload = useUploadCurrentAnnotation(captureAnnotations, order.id, design.id);

    const trackInitiateMarkup = useGuidedQcAnalytics('All - Portal - Guided QC - Markup Photo Initiated');
    const initiateMarkup = React.useCallback(async () => {
        const blob = takeSnapshotRef.current ? await takeSnapshotRef.current() : undefined;
        const url = blob ? URL.createObjectURL(blob) : undefined;
        setBackgroundImageUrl(url);
        trackInitiateMarkup({});
    }, [takeSnapshotRef, setBackgroundImageUrl, trackInitiateMarkup]);

    const reset = React.useCallback(() => {
        setBackgroundImageUrl(undefined);
        setIsUploading(false);
        markupsInternals.clear();
        markupsInternals.setToolState(state => ({ ...state, toolType: 'pointer' }));
    }, [setBackgroundImageUrl, markupsInternals, setIsUploading]);

    const trackMarkupSaved = useGuidedQcAnalytics('All - Portal - Guided QC - Markup Photo Saved');
    const uploadAndAddMarkup = React.useCallback(async () => {
        setIsUploading(true);
        const result = await upload();
        if (result) {
            trackMarkupSaved({ photoSource: result.imageUrl });
            addMarkupAction({
                url: result.imageUrl,
            });
        }
        reset();
    }, [upload, addMarkupAction, reset, trackMarkupSaved]);

    // Sets the active tool to current tool if it's not already, or `pointer` if it is the active tool.
    // If a markup isn't yet in progress, this function will initiate one.
    const setActiveTool = React.useCallback(
        async (toolType: ToolType) => {
            if (!backgroundImageUrl) {
                await initiateMarkup();
            }

            markupsInternals.setToolState(state => ({
                ...state,
                toolType: markupsInternals.toolState.toolType === toolType ? 'pointer' : toolType,
            }));
        },
        [backgroundImageUrl, markupsInternals, initiateMarkup],
    );

    const setBrushSize = React.useCallback(
        (brushSize: number) => {
            markupsInternals.setToolState(state => ({ ...state, brushSize }));
        },
        [markupsInternals],
    );

    const setColor = React.useCallback(
        (color: string) => {
            markupsInternals.setToolState(state => ({ ...state, color }));
        },
        [markupsInternals],
    );

    const commitText = React.useCallback(
        (text: string) => {
            void setActiveTool('pointer');
            markupsInternals.commitTextObject(text);
        },
        [markupsInternals, setActiveTool],
    );

    return {
        uploadAndAddMarkup,
        reset,
        initiateMarkup,
        markupsInternals,
        setActiveTool,
        setColor,
        setBrushSize,
        commitText,
        isUploading,
        hasMarkupInProgress: !!backgroundImageUrl,
    };
}

const DesignQcMarkupTextTool: React.VFC<{
    commit: (text: string) => void;
}> = ({ commit }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState<boolean>(false);
    const [text, setText] = React.useState<string>('');

    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            title={'Add Markup Note'}
            loading={false}
            CustomButton={({ onClick }) => (
                <Tooltip title={'Add note'}>
                    <IconButton onClick={onClick} className={classes.markupToolbarIcon}>
                        <TextIcon />
                    </IconButton>
                </Tooltip>
            )}
            content={
                <Grid container direction={'column'} spacing={1}>
                    <Grid item>
                        <SimpleTextField onChange={setText} value={text} label={'Note'} />
                    </Grid>
                    <Grid item container>
                        <Button
                            variant={'primary'}
                            disabled={!text.length}
                            fullWidth
                            onClick={() => {
                                commit(text);

                                // Reset text state
                                setText('');

                                // Close the modal
                                setOpen(false);
                            }}
                        >
                            Add Markup Note
                        </Button>
                    </Grid>
                </Grid>
            }
        />
    );
};

interface DesignQcDesignMarkupToolbarProps {
    setActiveTool: (type: ToolType) => Promise<void>;
    setBrushSize: (size: number) => void;
    setColor: (color: string) => void;
    toolState: ToolState;
    reset?: () => void;
    undo: () => void;
    redo: () => void;
    undoState: UndoState;
    commit: (text: string) => void;
}

const PossibleBrushSizes = [3, 5, 10];
const PossibleColors = [
    '#EB5757',
    '#F2994A',
    '#F2C94C',
    '#219653',
    '#158915',
    '#2F80ED',
    '#56CCF2',
    '#9B51E0',
    '#000000',
];

const DesignQcDesignMarkupToolbar: React.VFC<DesignQcDesignMarkupToolbarProps> = ({
    setActiveTool,
    reset,
    setBrushSize,
    setColor,
    toolState,
    undo,
    redo,
    undoState,
    commit,
}) => {
    const classes = useStyles();

    const { pendingMarkupUrls } = useDesignQcPropSelector(['pendingMarkupUrls']);
    const toggleBadWorkflowAction = useDesignQcAction('TOGGLE_MARK_SUBCATEGORY_BAD_WORKFLOW');
    const trackCancelMarkup = useGuidedQcAnalytics('All - Portal - Guided QC - Markup Photo Cancelled');

    return (
        <Grid item container direction={'column'} className={classes.markupToolbarRoot}>
            <Grid item container direction={'row'} className={classes.markupToolbarMainToolsRow}>
                <Grid item xs>
                    {reset ? (
                        <Button
                            variant={'alert'}
                            onClick={() => {
                                trackCancelMarkup({});
                                reset();
                            }}
                        >
                            Cancel markup
                        </Button>
                    ) : (
                        <Button
                            variant={'alert'}
                            onClick={() => {
                                // TODO: add an actual confirmation modal instead of this confirm.
                                if (
                                    pendingMarkupUrls?.length &&
                                    !window.confirm(
                                        'You will lose any markups for this subcategory, are you sure you would like to cancel?',
                                    )
                                ) {
                                    return;
                                }

                                toggleBadWorkflowAction({ isEnabled: false });
                            }}
                        >
                            Cancel
                        </Button>
                    )}
                </Grid>
                <Grid item className={classes.markupToolbarIconWrapper}>
                    <Tooltip title={'Brush Tool'}>
                        <IconButton
                            onClick={() => setActiveTool('brush')}
                            className={clsx(
                                classes.markupToolbarIcon,
                                toolState.toolType === 'brush' ? classes.markupToolbarIconSelected : undefined,
                            )}
                        >
                            <DrawIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item className={classes.markupToolbarIconWrapper}>
                    <Tooltip title={'Arrow Tool'}>
                        <IconButton
                            onClick={() => setActiveTool('arrow')}
                            className={clsx(
                                classes.markupToolbarIcon,
                                toolState.toolType === 'arrow' ? classes.markupToolbarIconSelected : undefined,
                            )}
                        >
                            <DesignArrowIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item className={classes.markupToolbarIconWrapper}>
                    <Tooltip title={'Line Tool'}>
                        <IconButton
                            onClick={() => setActiveTool('line')}
                            className={clsx(
                                classes.markupToolbarIcon,
                                toolState.toolType === 'line' ? classes.markupToolbarIconSelected : undefined,
                            )}
                        >
                            <RemoveIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item className={classes.markupToolbarIconWrapper}>
                    <DesignQcMarkupTextTool commit={commit} />
                </Grid>
                <Grid item className={classes.markupToolbarIconWrapper}>
                    <Tooltip title={'Circle Tool'}>
                        <IconButton
                            onClick={() => setActiveTool('oval')}
                            className={clsx(
                                classes.markupToolbarIcon,
                                toolState.toolType === 'oval' ? classes.markupToolbarIconSelected : undefined,
                            )}
                        >
                            <DesignCircleIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item className={classes.markupToolbarIconWrapper}>
                    <Divider orientation={'vertical'} style={{ height: 24 }} />
                </Grid>
                <Grid item className={classes.markupToolbarIconWrapper}>
                    <Tooltip title={'Undo'}>
                        <IconButton onClick={undo} disabled={!undoState.canUndo} className={classes.markupToolbarIcon}>
                            <UndoIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item className={classes.markupToolbarIconWrapper}>
                    <Tooltip title={'Redo'}>
                        <IconButton onClick={redo} disabled={!undoState.canRedo} className={classes.markupToolbarIcon}>
                            <RedoIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
            {toolState.toolType !== 'pointer' && (
                <Grid item container direction={'row'} className={classes.markupToolbarToolConfigRow}>
                    {toolState.toolType !== 'brush' && (
                        <Grid item>
                            <Text variant={'caption'} medium color={'GRAY'}>
                                Hold Shift to draw multiple
                            </Text>
                        </Grid>
                    )}
                    <Grid item xs />
                    <Grid item>
                        <Text variant={'caption'} color={'GRAY'}>
                            Size:
                        </Text>
                    </Grid>
                    {PossibleBrushSizes.map(size => (
                        <Grid
                            item
                            key={`brush_size_${size}`}
                            onClick={() => setBrushSize(size)}
                            className={clsx(
                                classes.markupToolbarToolConfigItem,
                                toolState.brushSize === size ? classes.markupToolbarToolConfigItemSelected : undefined,
                            )}
                        >
                            <div
                                style={{
                                    height: size,
                                    width: 12,
                                    borderRadius: 12,
                                    backgroundColor: FlossPalette.LIGHT_GRAY,
                                    margin: `${Math.floor((22 - size) / 2)}px auto`,
                                }}
                            />
                        </Grid>
                    ))}
                    <Grid item>
                        <Text variant={'caption'} color={'GRAY'}>
                            Color:
                        </Text>
                    </Grid>
                    {PossibleColors.map(color => (
                        <Grid
                            item
                            key={`brush_color_${color}`}
                            onClick={() => setColor(color)}
                            className={clsx(
                                classes.markupToolbarToolConfigItem,
                                toolState.color === color ? classes.markupToolbarToolConfigItemSelected : undefined,
                            )}
                        >
                            <div
                                style={{ backgroundColor: color }}
                                className={classes.markupToolbarToolConfigItemColor}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
        </Grid>
    );
};

const DesignQcDesignContentDecisionActions: React.VFC = () => {
    const classes = useStyles();
    const toggleBadWorkflowAction = useDesignQcAction('TOGGLE_MARK_SUBCATEGORY_BAD_WORKFLOW');
    const toggleAcceptableWorkflowAction = useDesignQcAction('TOGGLE_MARK_SUBCATEGORY_ACCEPTABLE_WORKFLOW');

    const { isBadSubcategoryWorkflowEnabled } = useDesignQcPropSelector(['isBadSubcategoryWorkflowEnabled', 'order']);
    const trackInitBadWorkflow = useGuidedQcAnalytics('All - Portal - Guided QC - Subcategory Bad Workflow Initiated');
    const trackInitAcceptableWorkflow = useGuidedQcAnalytics(
        'All - Portal - Guided QC - Subcategory Acceptable Workflow Initiated',
    );

    const initializeBadWorkflow = React.useCallback(() => {
        trackInitBadWorkflow({});
        toggleBadWorkflowAction({ isEnabled: true });
    }, [toggleBadWorkflowAction, trackInitBadWorkflow]);

    const initializeAcceptableWorkflow = React.useCallback(() => {
        trackInitAcceptableWorkflow({});
        toggleAcceptableWorkflowAction({ isEnabled: true });
    }, [toggleAcceptableWorkflowAction, trackInitAcceptableWorkflow]);

    useRegisterHotKeys({
        key: 'Shift+I',
        description: 'Mark as acceptable',
        category: 'Guided QC',
        action: initializeAcceptableWorkflow,
        disabled: isBadSubcategoryWorkflowEnabled,
    });

    useRegisterHotKeys({
        key: 'Shift+B',
        description: 'Mark as bad',
        category: 'Guided QC',
        action: initializeBadWorkflow,
        disabled: isBadSubcategoryWorkflowEnabled,
    });

    if (isBadSubcategoryWorkflowEnabled) {
        return null;
    }

    return (
        <>
            <Grid item>
                <Button variant={'ghost'} className={classes.bottomActionButton} onClick={initializeAcceptableWorkflow}>
                    <Text variant={'caption'} medium color={'GRAY'}>
                        Mark as acceptable
                    </Text>
                    &nbsp;
                    <Text variant={'caption'} color={'GRAY'}>
                        (Shift+I)
                    </Text>
                </Button>
            </Grid>
            <Grid item>
                <Button variant={'ghost'} className={classes.bottomActionButton} onClick={initializeBadWorkflow}>
                    <Text variant={'caption'} medium color={'GRAY'}>
                        Mark as bad
                    </Text>
                    &nbsp;
                    <Text variant={'caption'} color={'GRAY'}>
                        (Shift+B)
                    </Text>
                </Button>
            </Grid>
        </>
    );
};

interface DesignQcDesignContentProps {
    arePresetViewsEnabled: boolean;
}

export const DesignQcDesignContent: React.VFC<DesignQcDesignContentProps> = ({ arePresetViewsEnabled }) => {
    const classes = useStyles();

    const { order, isBadSubcategoryWorkflowEnabled, designFragment } = useDesignQcPropSelector([
        'order',
        'isBadSubcategoryWorkflowEnabled',
        'designFragment',
    ]);

    const modelRef: MainViewModelRef = React.useRef(undefined);
    const controlRef: MainViewCameraControlsRef = React.useRef(null);
    const takeSnapshotRef: TakeSnapshotRef = React.useRef();

    const [appearance, setAppearance] = React.useState<ModelAppearance>(MODEL_VIEWER_INITIAL_APPEARANCE);
    useGuidedQcPresets({ setAppearance, appearance, controlRef, arePresetViewsEnabled });

    const {
        markupsInternals,
        uploadAndAddMarkup,
        isUploading,
        reset,
        setActiveTool,
        setColor,
        setBrushSize,
        hasMarkupInProgress,
        commitText,
    } = useDesignQcMarkup(takeSnapshotRef);

    React.useEffect(() => {
        if (!isBadSubcategoryWorkflowEnabled && hasMarkupInProgress) {
            reset();
        }
    }, [reset, isBadSubcategoryWorkflowEnabled, hasMarkupInProgress]);

    useRegisterHotKeys({
        key: 'Shift+S',
        description: 'Save markup',
        category: 'Guided QC',
        action: uploadAndAddMarkup,
        disabled: isUploading || !hasMarkupInProgress,
    });

    // Experimental effect that is attempting to fix a bug in which the model will sometimes disappear.
    // This bug is somewhat system dependent and difficult to debug, but generally resolves after restoring the camera view.
    React.useEffect(() => {
        if (modelRef.current) {
            modelRef.current.visible = true;
        }

        controlRef.current?.handleResize?.();
    }, [modelRef, controlRef, isBadSubcategoryWorkflowEnabled]);

    return (
        <Grid xs item container direction={'column'} className={classes.root}>
            <OrderAnalyticsContext.Provider value={{ orderId: order.id }}>
                {isBadSubcategoryWorkflowEnabled && (
                    <DesignQcDesignMarkupToolbar
                        setActiveTool={setActiveTool}
                        setColor={setColor}
                        setBrushSize={setBrushSize}
                        reset={hasMarkupInProgress ? reset : undefined}
                        toolState={markupsInternals.toolState}
                        undo={markupsInternals.undo}
                        redo={markupsInternals.redo}
                        undoState={markupsInternals.undoState}
                        commit={commitText}
                    />
                )}
                <Grid
                    item
                    xs
                    style={{
                        position: 'relative',
                        maxHeight: `calc(100vh - ${isBadSubcategoryWorkflowEnabled ? 88 : 24}px)`,
                        height: '100%',
                        overflow: 'hidden',
                    }}
                >
                    <Grid
                        container
                        xs
                        style={{
                            height: '100%',
                            maxHeight: '100%',
                            position: 'relative',
                            visibility: hasMarkupInProgress ? 'hidden' : 'visible',
                        }}
                    >
                        <OrderDesignPreview
                            order={order}
                            userRole={'psr'}
                            selectedDesignFragment={designFragment ?? undefined}
                            fullScreen
                            enableNewViewerWithProps={{
                                appearance,
                                setAppearance,
                                modelRef,
                                controlRef,
                                takeSnapshotRef,
                            }}
                            checkCanvasSize
                        />
                    </Grid>
                    {hasMarkupInProgress && (
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                overflow: 'hidden',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                            }}
                        >
                            {markupsInternals.canvas}
                        </div>
                    )}
                </Grid>
                <Grid container direction={'row'} className={classes.bottomActionWrapper}>
                    <DesignQcDesignContentDecisionActions />
                    <Grid item xs />
                    {hasMarkupInProgress && (
                        <Grid item>
                            <Button
                                variant={'ghost'}
                                className={classes.bottomActionButton}
                                onClick={uploadAndAddMarkup}
                                disabled={isUploading}
                            >
                                <Text variant={'caption'} color={'LIGHT_GRAY'}>
                                    Saving annotations will add it to the annotations on the right
                                </Text>
                                &nbsp;&nbsp;
                                <Text variant={'caption'} medium color={'GRAY'}>
                                    Save markup
                                </Text>
                                &nbsp;
                                <Text variant={'caption'} color={'GRAY'}>
                                    (Shift+S)
                                </Text>
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </OrderAnalyticsContext.Provider>
        </Grid>
    );
};
