import { CapacityStationRuleTable } from './CapacityStationTables/CapacityStationRuleTable';
import { CapacityStationTable } from './CapacityStationTables/CapacityStationTable';
import { EditCapacityStationDialog } from './EditCapacityStations/EditCapacityStationDialog';
import { EditCapacityStationRuleDialog } from './EditCapacityStations/EditCapacityStationRuleDialog';
import { useMutation, useQuery } from '@apollo/client';
import type { CapacityStationDtoFragment, CapacityStationRuleDtoFragment } from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import { useListLabOrgs } from '@orthly/graphql-react';
import { ActionCard, useRootActionCommand } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const CapacityStations_Fragment = graphql(`
    fragment CapacityStationDTO on CapacityStationDTO {
        id
        created_at
        updated_at
        organization_id
        name
        unit_type
    }
`);

const CapacityStationRules_Fragment = graphql(`
    fragment CapacityStationRuleDTO on CapacityStationRuleDTO {
        id
        created_at
        updated_at
        capacity_station_id
        name
        work_order_type
        default_unit_limit
        default_unit_granularity
        matching_logic_json
    }
`);

const CreateCapacityStation_Mutation = graphql(`
    mutation CreateCapacityStation_Mutation(
        $name: String!
        $organization_id: String!
        $unit_type: CapacityLimitUnitType!
    ) {
        createCapacityStation(name: $name, organization_id: $organization_id, unit_type: $unit_type) {
            ...CapacityStationDTO
        }
    }
`);

const CreateCapacityStationRule_Mutation = graphql(`
    mutation CreateCapacityStationRule_Mutation($createCapacityStationRuleArgs: CreateCapacityStationRuleArgs!) {
        createCapacityStationRule(createCapacityStationRuleArgs: $createCapacityStationRuleArgs) {
            ...CapacityStationRuleDTO
        }
    }
`);

const AllCapacityStations_Query = graphql(`
    query AllCapacityStations {
        getAllCapacityStations {
            ...CapacityStationDTO
        }
    }
`);

const AllCapacityStationRules_Query = graphql(`
    query AllCapacityStationRules {
        getAllCapacityStationRules {
            ...CapacityStationRuleDTO
        }
    }
`);

export type StationRow = CapacityStationDtoFragment & { organization_name?: string };
export type RuleRow = CapacityStationRuleDtoFragment & { station_name?: string };

export interface EditCapacityDialogProps {
    setOpen: (open: boolean) => void;
    submitting: boolean;
    open: boolean;
    submit: (args: any) => Promise<any>;
}

export const CapacityStationsRoot: React.FC = () => {
    const { data: { listOrganizations: labs = [] } = {}, loading: labsLoading } = useListLabOrgs({
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });

    const {
        loading: loadingStations,
        data: stations,
        error: stationsError,
        refetch: refetchStations,
    } = useQuery(AllCapacityStations_Query, {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const stationsResult = getFragmentData(CapacityStations_Fragment, stations?.getAllCapacityStations);

    const {
        loading: loadingRules,
        data: rules,
        error: rulesError,
        refetch: refetchRules,
    } = useQuery(AllCapacityStationRules_Query, {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const rulesResult = getFragmentData(CapacityStationRules_Fragment, rules?.getAllCapacityStationRules);

    const createCapacityStation = useMutation(CreateCapacityStation_Mutation);
    const {
        submit: submitCapacityStation,
        submitting: submittingCapacityStation,
        open: openAddStationDialog,
        setOpen: setOpenAddStationDialog,
    } = useRootActionCommand(createCapacityStation, {
        onSuccess: async () => {
            await refetchStations();
            setOpenAddStationDialog(false);
        },
        successMessage: 'Capacity Station created!',
    });

    const createCapacityStationRule = useMutation(CreateCapacityStationRule_Mutation);
    const {
        submit: submitRule,
        submitting: submittingRule,
        open: openAddRuleDialog,
        setOpen: setOpenAddRuleDialog,
    } = useRootActionCommand(createCapacityStationRule, {
        onSuccess: async () => {
            await refetchRules();
            setOpenAddRuleDialog(false);
        },
        successMessage: 'Capacity Station Rule created!',
    });

    const stationRows: StationRow[] = React.useMemo(
        () =>
            (stationsResult ?? []).map(station => ({
                ...station,
                organization_name: labs?.find(({ id }) => id === station.organization_id)?.name ?? undefined,
            })),
        [stationsResult, labs],
    );
    const ruleRows: RuleRow[] = React.useMemo(
        () =>
            (rulesResult ?? []).map(rule => ({
                ...rule,
                station_name: stationsResult?.find(({ id }) => id === rule.capacity_station_id)?.name ?? undefined,
            })),
        [rulesResult, stationsResult],
    );

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <CapacityStationTable
                        stationRows={stationRows}
                        loadingStations={loadingStations}
                        setOpenAddStationDialog={setOpenAddStationDialog}
                        refetchStations={refetchStations}
                    />
                    <EditCapacityStationDialog
                        setOpen={setOpenAddStationDialog}
                        submitting={submittingCapacityStation}
                        open={openAddStationDialog}
                        labs={labs}
                        labsLoading={labsLoading}
                        submit={submitCapacityStation}
                    />
                </Grid>
                {stationsError && (
                    <ActionCard title={'Error retrieving capacity stations'} subtitle={''} variant={'alert'} />
                )}
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <CapacityStationRuleTable
                        ruleRows={ruleRows}
                        loadingRules={loadingRules}
                        setOpenAddRuleDialog={setOpenAddRuleDialog}
                        refetchRules={refetchRules}
                    />
                    <EditCapacityStationRuleDialog
                        setOpen={setOpenAddRuleDialog}
                        submitting={submittingRule}
                        open={openAddRuleDialog}
                        stations={stationsResult ? [...stationsResult] : []}
                        submit={submitRule}
                        loadingStations={loadingStations}
                    />
                </Grid>
                {rulesError && (
                    <ActionCard title={'Error retrieving capacity station rules'} subtitle={''} variant={'alert'} />
                )}
            </Grid>
        </>
    );
};
