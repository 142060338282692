import { recomputeCollisionsObject } from '../DesignEditing/RecomputeCollisions';
import type { NamedRestorativeModel, RestorativeModel, CollisionsMap, ScanGeometries } from './FinishingApp.types';
import { InsertionDepthGenerator } from './InsertionDepthGenerator';
import { computeNormalsAndGenerateBvh, computeDistanceAttributes } from './PrepareModels.utils';
import type { NamedGeometry, NamedRestorativeGeometry } from '@orthly/forceps';
import { ScanName } from '@orthly/forceps';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import React from 'react';

interface PrepareModelsResult {
    restorativeModels: ArrayMin1<RestorativeModel>;
    collisions: CollisionsMap;
    insertionDepthGenerator: InsertionDepthGenerator;
}

function makeNamedRestorativeModel(model: RestorativeModel): NamedRestorativeModel {
    const geometry = model.geometry.clone() as NamedRestorativeGeometry;
    geometry.name = `${model.toothNumber}`;
    return {
        ...model,
        geometry,
        insertionAxis: model.insertionAxis.clone(),
        curtainGeometry: model.curtainGeometry?.clone(),
    };
}

function makeScanNamed<T extends ScanName>(geometry: THREE.BufferGeometry, name: T): NamedGeometry<T> {
    geometry.name = name;
    return geometry as NamedGeometry<T>;
}

/**
 * Prepares models to be used in the finishing app
 * @param[in] restorativeModelsInput Restorative models. These objects are not modified; instead, they are cloned and
 *   returned after preparation.
 * @param[in,out] upperJawGeometry Geometry of the upper jaw, modified in place
 * @param[in,out] lowerJawGeometry Geometry of the lower jaw, modified in place
 * @returns The cloned and prepared restorative models
 */
export function usePrepareModels(
    restorativeModelsInput: ArrayMin1<RestorativeModel>,
    scanGeometries: ScanGeometries,
): PrepareModelsResult {
    // We clone the restorative geometries before potentially modifying them so that if the app is closed and reopened,
    // the original geometries are still available.
    const restorativeModels = React.useMemo<ArrayMin1<NamedRestorativeModel>>(() => {
        const restorativeModels = restorativeModelsInput.map(
            makeNamedRestorativeModel,
        ) as ArrayMin1<NamedRestorativeModel>;

        restorativeModels.forEach(model => computeNormalsAndGenerateBvh(model.geometry));
        restorativeModels.forEach(model => model.curtainGeometry?.computeVertexNormals());

        return restorativeModels;
    }, [restorativeModelsInput]);

    const upperJawGeometry = makeScanNamed(scanGeometries.upperJaw, ScanName.UpperJaw);
    const lowerJawGeometry = makeScanNamed(scanGeometries.lowerJaw, ScanName.LowerJaw);

    if (!upperJawGeometry || !lowerJawGeometry) {
        throw new Error('Could not prepare models because one of the Jaw geometries is missing');
    }

    // The only use of the jaw scan BVHs is to snap the tessellated margin lines to the scan surface, so we actually
    // only need to calculate the BVH for the prep scan. For simplicity, we calculate it for both scans, but we can
    // revisit this if it becomes a performance issue.
    useComputeNormalsAndGenerateBvh(upperJawGeometry);
    useComputeNormalsAndGenerateBvh(lowerJawGeometry);
    useComputeNormalsAndGenerateBvh(scanGeometries.prePrepUpperJaw);
    useComputeNormalsAndGenerateBvh(scanGeometries.prePrepLowerJaw);

    const collisions = useComputeHeatmapsAndCollisions(restorativeModels, upperJawGeometry, lowerJawGeometry);
    const insertionDepthGenerator = React.useMemo(
        () => new InsertionDepthGenerator(restorativeModels, upperJawGeometry, lowerJawGeometry),
        [restorativeModels, upperJawGeometry, lowerJawGeometry],
    );

    return React.useMemo(
        () => ({ restorativeModels, collisions, insertionDepthGenerator }),
        [restorativeModels, collisions, insertionDepthGenerator],
    );
}

function useComputeNormalsAndGenerateBvh(geometry: THREE.BufferGeometry | undefined): void {
    // Although this function doesn't return anything, we use useMemo rather than useEffect so that the calculations
    // happen during the render, rather than after it.
    React.useMemo(() => geometry && computeNormalsAndGenerateBvh(geometry), [geometry]);
}

function useComputeHeatmapsAndCollisions(
    restorativeModels: ArrayMin1<NamedRestorativeModel>,
    upperJawGeometry: NamedGeometry<ScanName.UpperJaw>,
    lowerJawGeometry: NamedGeometry<ScanName.LowerJaw>,
): CollisionsMap {
    return React.useMemo(() => {
        const collisions: CollisionsMap = new Map();

        restorativeModels.forEach(el => {
            computeDistanceAttributes(el, restorativeModels, upperJawGeometry, lowerJawGeometry);
            const collisionGeometry = recomputeCollisionsObject(el.geometry).model.geometry;
            el.collisionsGeometry = collisionGeometry;
            collisions.set(el.toothNumber, collisionGeometry);
        });

        return collisions;
    }, [restorativeModels, upperJawGeometry, lowerJawGeometry]);
}
