import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';

export const usePaymentMethodStyles = stylesFactory<{ is_default?: boolean }>(() => ({
    root: {
        backgroundColor: ({ is_default }) => (is_default ? FlossPalette.PRIMARY_BACKGROUND : FlossPalette.TAN),
        borderRadius: '16px',
        marginBottom: '12px',
        padding: '16px 24px',
        border: ({ is_default }) =>
            `1px solid ${is_default ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.STROKE_LIGHT}`,
    },
    primaryTag: {
        color: FlossPalette.WHITE,
        background: FlossPalette.STAR_GRASS,
        padding: '4px 8px',
        borderRadius: '2px',
        display: 'inline-block',
        fontSize: '12px',
        fontWeight: 600,
        textAlign: 'center',
        lineHeight: '18px',
        marginLeft: '8px',
    },
    setPrimaryBtn: {
        padding: 8,
    },
    paymentMethodIcon: {
        margin: 8,
    },
    removeMethodContainer: {
        float: 'right',
        marginTop: '16px',
    },
    removeMethodBtns: {
        marginRight: '8px',
    },
    footerContainer: {
        marginTop: '0.5rem',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

enum BankCardType {
    american_express = 'american_express',
    discover = 'discover',
    jcb = 'jcb',
    mastercard = 'mastercard',
    visa = 'visa',
}

export const BankCardToNameMap: Record<BankCardType, string> = {
    american_express: 'Amex',
    discover: 'Discover',
    jcb: 'Jcb',
    mastercard: 'Mastercard',
    visa: 'Visa',
};
