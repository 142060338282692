import { SplitManagerRoot } from './SplitManagerRoot';
import { useSplitsAction } from './state/Splits.actions';
import { SplitsReduxContext, useSplitsSelector } from './state/Splits.context';
import { SplitsStore } from './state/Splits.store';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useOrders, useListLabOrgs } from '@orthly/graphql-react';
import { RootActionDialog } from '@orthly/ui';
import React from 'react';
import { Provider } from 'react-redux';

export const useSplitsManager = (order: LabsGqlLabOrderFragment) => {
    const { data: mfrQueryResult } = useListLabOrgs({
        fetchPolicy: 'cache-first',
    });
    const manufacturers = React.useMemo(() => mfrQueryResult?.listOrganizations ?? [], [mfrQueryResult]);
    const { orders: splitOrders } = useOrders({
        filter: {
            partner_order_id: order.partner_order_id,
        },
    });
    const openManager = useSplitsAction('OPEN_MANAGER');

    return React.useCallback(() => {
        openManager({
            order,
            splitOrders,
            manufacturers,
        });
    }, [openManager, manufacturers, splitOrders, order]);
};

export const ManageSplitsDialog: React.FC = () => {
    const closeManager = useSplitsAction('CLOSE_MANAGER');
    const open = useSplitsSelector(s => s.open);
    if (!open) {
        return null;
    }
    return (
        <RootActionDialog
            dialogProps={{ fullWidth: true, maxWidth: 'xl' }}
            buttonText={'Splits'}
            title={'Manage Splits'}
            loading={false}
            open={open}
            setOpen={isOpen => {
                if (!isOpen) {
                    closeManager();
                }
            }}
            buttonProps={{ variant: 'ghost', style: { padding: 0, marginLeft: 10 } }}
            content={<SplitManagerRoot />}
        />
    );
};

export const ManageSplitsProvider: React.FC = props => {
    return (
        <Provider context={SplitsReduxContext} store={SplitsStore}>
            {props.children}
        </Provider>
    );
};
