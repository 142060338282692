import type { LabsGqlTicketsFilters, LabsGqlPagination } from '@orthly/graphql-schema';
import _ from 'lodash';
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import type { ZodError } from 'zod';
import { z } from 'zod';

type QueryParamValue = number | boolean | string;

export const parseQueryStringVal = (queryStringVal: string): QueryParamValue => {
    switch (queryStringVal) {
        case 'true':
            return true;
        case 'false':
            return false;
        default:
            if (/^\d+$/.test(queryStringVal)) {
                return parseInt(queryStringVal);
            }
            return queryStringVal;
    }
};

export const parseQueryString = (queryString: string): { [key: string]: QueryParamValue | QueryParamValue[] } => {
    const params = new URLSearchParams(queryString);

    const items: [string, QueryParamValue | QueryParamValue[]][] = Array.from(params.entries(), ([k, v]) => {
        const value = /,/.test(v) ? v.split(',').map(parseQueryStringVal) : parseQueryStringVal(v);
        return [k, value];
    });

    return Object.fromEntries(items);
};

export const encodeQueryString = (params: {
    [key: string]: QueryParamValue | QueryParamValue[] | undefined | null;
}): string => {
    return _.sortBy(
        Object.entries(params)
            .filter(([_k, v]) => v !== null && v !== undefined)
            .map(([k, v]) => `${k}=${v}`),
    ).join('&');
};

// This is gross, it comes from me using `undefined` to signify "ALL"
// in GQL to the backend and `undefined` to signify not present in the
// filters. Not my best choice but breaking backwards compat now would
// be a pain.

type GqlBooleanCondition = boolean | undefined;
type UrlBooleanCondition = boolean | 'ALL';

export const convertBooleanConditionFromGqlToUrl = (cond: GqlBooleanCondition): UrlBooleanCondition =>
    cond === undefined ? 'ALL' : cond;

export const convertBooleanConditionFromUrlToGql = (cond: UrlBooleanCondition): GqlBooleanCondition =>
    cond === 'ALL' ? undefined : cond;

export const maybeWrapStringInArray = (x: string | string[]): string[] => [x].flat();

export const URLTicketFilterSchema = z.object({
    page: z.number().positive().optional(),
    per_page: z.number().positive().optional(),
    is_open: z
        .union([z.boolean(), z.literal('ALL')])
        .default(true)
        .transform(convertBooleanConditionFromUrlToGql),
    active_action_is_workable_now: z
        .union([z.boolean(), z.literal('ALL')])
        .default('ALL')
        .transform(convertBooleanConditionFromUrlToGql),
    action_type_ids: z.union([z.string(), z.string().array()]).transform(maybeWrapStringInArray).optional(),
    assigned_user_ids: z.union([z.string(), z.string().array()]).transform(maybeWrapStringInArray).optional(),
    manufacturer_ids: z.union([z.string(), z.string().array()]).transform(maybeWrapStringInArray).optional(),
    order_tag_ids: z.union([z.string(), z.string().array()]).transform(maybeWrapStringInArray).optional(),
    practice_ids: z.union([z.string(), z.string().array()]).transform(maybeWrapStringInArray).optional(),
    ticket_ids: z.union([z.string(), z.string().array()]).transform(maybeWrapStringInArray).optional(),
});

const DEFAULT_FILTERS = { is_open: true };
const DEFAULT_PAGINTATION = { page: 1, per_page: 25 };

export const useUrlTicketFilterParams = (): {
    filters: LabsGqlTicketsFilters;
    pagination: LabsGqlPagination;
    setFiltersAndPagination: (filters: LabsGqlTicketsFilters & LabsGqlPagination) => void;
    error?: ZodError;
} => {
    const { search } = useLocation();
    const history = useHistory();

    return React.useMemo(() => {
        const setFiltersAndPagination = (filters: LabsGqlTicketsFilters & LabsGqlPagination) => {
            const urlSafeFilters = {
                ...filters,
                is_open: convertBooleanConditionFromGqlToUrl(filters.is_open ?? undefined),
                active_action_is_workable_now: convertBooleanConditionFromGqlToUrl(
                    filters.active_action_is_workable_now ?? undefined,
                ),
            };
            history.push(`tickets?${encodeQueryString(urlSafeFilters)}`);
        };

        const params = parseQueryString(search);
        const result = URLTicketFilterSchema.safeParse(params);
        const filters = result.success
            ? {
                  ...DEFAULT_FILTERS,
                  ..._.omit(result.data, ['page', 'per_page']),
              }
            : DEFAULT_FILTERS;

        const pagination = result.success
            ? {
                  page: result.data.page ?? DEFAULT_PAGINTATION.page,
                  per_page: result.data.per_page ?? DEFAULT_PAGINTATION.per_page,
              }
            : DEFAULT_PAGINTATION;

        return { setFiltersAndPagination, pagination, filters, error: !result.success ? result.error : undefined };
    }, [search, history]);
};
