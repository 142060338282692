import { hasFullStructuredNotesSelection } from '../../GuidedWaxup.util';
import { useStructuredNotesOptions } from '../../hooks/useStructuredNotesOptions';
import { useGuidedWaxupContext } from '../../state/GuidedWaxupContext';
import { useGuidedWaxupAction, useGuidedWaxupSelector } from '../../state/GuidedWaxupState';
import { RejectionNotesContainer } from '../RejectionNotesContainer';
import { StructuredRejectionNotesAddendum } from './StructuredRejectionNotesAddendum';
import { nestedCheckboxListUtils, NestedCheckboxList } from '@orthly/dentin';
import type { LabsGqlDesignOrderNoteCategory } from '@orthly/graphql-schema';
import React from 'react';

// Figma for this component can be found here: https://www.figma.com/design/C3hnlaLEorlXlJUZZ07b7t/Feature%3A-DDP-Improvements-2024?node-id=7071-106881&node-type=text&m=dev

export const StructuredRejectionNotesContent: React.VFC<{ onCancel?: () => any }> = ({ onCancel }) => {
    const {
        selectedDesignRevisionAlreadyReviewed,
        selectedTab,
        mostRecentlyRejectedDesignFragment,
        isComparisonModeAvailable,
    } = useGuidedWaxupContext();
    const preset = useGuidedWaxupSelector(s => s.presets[selectedTab]);
    const options = useStructuredNotesOptions(selectedTab);
    const structuredNotes = React.useMemo(
        () => nestedCheckboxListUtils.unflattenSelections(preset?.structured_notes ?? [], options),
        [options, preset],
    );

    const showNotesAddendum = React.useMemo(
        () => hasFullStructuredNotesSelection(selectedTab, preset?.structured_notes),
        [selectedTab, preset],
    );

    const setPresetStructuredRejectionNotes = useGuidedWaxupAction('SET_PRESET_STRUCTURED_REJECTION_NOTES');

    return (
        <RejectionNotesContainer title={'Sorry about that, what should we change?'}>
            <NestedCheckboxList
                disabled={selectedDesignRevisionAlreadyReviewed}
                options={options}
                selections={structuredNotes}
                onChange={selections => {
                    const structuredRejectionNotes = nestedCheckboxListUtils.flattenSelections(
                        selections,
                    ) as LabsGqlDesignOrderNoteCategory[];
                    setPresetStructuredRejectionNotes({
                        presetName: selectedTab,
                        structuredRejectionNotes,
                    });
                }}
            />
            {showNotesAddendum && (
                <StructuredRejectionNotesAddendum
                    disabled={selectedDesignRevisionAlreadyReviewed}
                    mostRecentlyRejectedDesignFragment={mostRecentlyRejectedDesignFragment}
                    isComparisonModeAvailable={isComparisonModeAvailable}
                    presetType={selectedTab}
                    onCancel={onCancel}
                    hasBorder
                />
            )}
        </RejectionNotesContainer>
    );
};
