import { DesignSidebarSection } from './DesignDetailSidebarSection';
import type { LabsGqlDesignQaEvaluationResultsOrderFragmentFragment, LabsGqlOrder } from '@orthly/graphql-operations';
import { Text } from '@orthly/ui-primitives';
import { OrderDetailRefabBlock } from '@orthly/veneer';
import React from 'react';

export const DesignRefabNotesSection: React.FC<{ order: LabsGqlOrder }> = props => {
    if (!props.order.notes_for_refabrication || props.order.notes_for_refabrication?.length === 0) {
        return null;
    }
    return (
        <>
            <DesignSidebarSection title={'Refab Reasons'}>
                <OrderDetailRefabBlock order={props.order} hideBlockWrapper />
            </DesignSidebarSection>
            <DesignSidebarSection title={'Refab Notes'}>
                <Text variant={'caption'}>{props.order.notes_for_refabrication}</Text>
            </DesignSidebarSection>
        </>
    );
};

export const DesignDoctorNotesSection: React.FC<{
    order: LabsGqlDesignQaEvaluationResultsOrderFragmentFragment;
}> = props => {
    if (!props.order.doctor_notes || props.order.doctor_notes?.length === 0) {
        return null;
    }
    return (
        <DesignSidebarSection title={'Doctor Notes'}>
            <Text variant={'caption'} style={{ whiteSpace: 'pre-line' }}>
                {props.order.doctor_notes}
            </Text>
        </DesignSidebarSection>
    );
};

export const DesignClinicalNotesSection: React.FC<{
    order: LabsGqlDesignQaEvaluationResultsOrderFragmentFragment;
}> = props => {
    if (!props.order.clinical_support_notes?.notes || props.order.clinical_support_notes?.notes?.length === 0) {
        return null;
    }
    return (
        <DesignSidebarSection title={'Clinical Support Team Notes'}>
            <Text variant={'caption'} style={{ whiteSpace: 'pre-line' }}>
                {props.order.clinical_support_notes.notes}
            </Text>
        </DesignSidebarSection>
    );
};
