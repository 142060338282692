import React from 'react';

export const ChatItemSkeleton = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={344} height={48} {...props}>
        <circle cx={'20'} cy={'20'} r={'20'} fill={'currentColor'} />
        <rect x={'64'} width={'128'} height={'16'} fill={'currentColor'} />
        <rect x={'295'} width={'49'} height={'8'} fill={'currentColor'} />
        <rect x={'64'} y={'24'} width={'202'} height={'8'} fill={'currentColor'} />
        <rect x={'64'} y={'40'} width={'172'} height={'8'} fill={'currentColor'} />
        <circle cx={'332'} cy={'28'} r={'12'} fill={'currentColor'} />
    </svg>
);
