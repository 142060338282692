import { useTableData } from '../../contexts/TableDataContext';
import { usePaginationOptionsContext, useTranslationOptionsContext } from '../../contexts/TableOptionsContext';
import { useMuiTableSelector } from '../../state';
import { useTableStateAction } from '../../state/action-hooks';
import { MUITablePaginationActions } from './MUITablePaginationActions';
import {
    createStyles,
    makeStyles,
    TablePrimitive as Table,
    TableFooter as MuiTableFooter,
    TablePagination as MuiTablePagination,
    TableRow as MuiTableRow,
} from '@orthly/ui-primitives';
import type { ChangeEvent } from 'react';
import React from 'react';

const useStyles = makeStyles(
    () =>
        createStyles({
            root: {
                '&:last-child': {
                    padding: '0px 24px 0px 24px',
                },
            },
            toolbar: {},
            selectRoot: {
                marginRight: '4px',
                '& > :first-child': {
                    padding: '6px !important',
                    paddingLeft: '12px !important',
                },
            },
            selectIcon: { top: '6px' },

            '@media screen and (max-width: 400px)': {
                toolbar: {
                    '& span:nth-child(2)': {
                        display: 'none',
                    },
                },
                selectRoot: {
                    marginRight: '8px',
                },
            },
        }),
    { name: 'MUITablePagination' },
);

const useFooterStyles = makeStyles(
    () =>
        createStyles({
            table: {},
        }),
    { name: 'MUITableFooter' },
);

function getMaxPage(totalRows: number, rowsPerPage: number): number {
    // round up, but page is 0 based so we subtract 1 to get max page back to 0. If there are no rows (0 / perPage = 0),
    //  we return the max of 0 and lastPage to avoid a negative lastPage.
    return Math.max(0, Math.ceil(totalRows / rowsPerPage) - 1);
}

export const MUITablePagination = () => {
    const classes = useStyles();
    const footerClasses = useFooterStyles();
    const { totalRowCount } = useTableData();
    const paginationOptions = usePaginationOptionsContext();
    const pagination = useMuiTableSelector(s => s.pagination);
    const changeRowsPerPage = useTableStateAction<'changeRowsPerPage'>('changeRowsPerPage');
    const changePage = useTableStateAction<'changePage'>('changePage');
    const translations = useTranslationOptionsContext();
    const textLabels = translations.pagination;
    const { rowsPerPage, page } = pagination;
    const onRowsPerPageChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value);
        const maxPage = getMaxPage(totalRowCount, newRowsPerPage);
        if (page > maxPage) {
            changePage(maxPage);
        }
        changeRowsPerPage(newRowsPerPage);
    };
    const lastPage = getMaxPage(totalRowCount, rowsPerPage);
    const [pendingMaxPageFix, setPendingMaxPageFix] = React.useState<number | undefined>(undefined);
    React.useEffect(() => {
        if (page > lastPage && pendingMaxPageFix !== lastPage) {
            setPendingMaxPageFix(lastPage);
            return changePage(lastPage);
        }
        if (page <= lastPage && pendingMaxPageFix !== undefined) {
            setPendingMaxPageFix(undefined);
        }
    }, [page, lastPage, changePage, pendingMaxPageFix]);

    if (paginationOptions.customFooter) {
        const CustomFooter = paginationOptions.customFooter;
        const customFooterProps = { rowsPerPage, page, changeRowsPerPage, changePage, lastPage };
        return <CustomFooter {...customFooterProps} />;
    }
    return (
        <Table className={footerClasses.table}>
            <MuiTableFooter>
                <MuiTableRow>
                    <MuiTablePagination
                        className={classes.root}
                        classes={{
                            toolbar: classes.toolbar,
                            selectRoot: classes.selectRoot,
                            selectIcon: classes.selectIcon,
                        }}
                        SelectProps={{ title: 'MUITable-rowsPerPageSelect' }}
                        count={totalRowCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={textLabels.rowsPerPage}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${textLabels.displayRows} ${count}`}
                        backIconButtonProps={{ 'aria-label': textLabels.previous }}
                        nextIconButtonProps={{ 'aria-label': textLabels.next }}
                        rowsPerPageOptions={pagination.rowsPerPageOptions}
                        onPageChange={(_e: any, page: number) => changePage(page)}
                        onRowsPerPageChange={onRowsPerPageChange}
                        ActionsComponent={MUITablePaginationActions}
                    />
                </MuiTableRow>
            </MuiTableFooter>
        </Table>
    );
};
