import { RefabBodyContainer } from '../components/RefabBodyContainer';
import { FlossPalette, Text, styled, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gridGap: '35px',
    padding: '48px 16px',
    maxWidth: '600px',
});
const VerticalLine = styled('div')({
    width: '1px',
    backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
});
const ContentContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gridGap: 24,
});
const StepContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gridGap: 8,
});
const StepTitleContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
});
const StepNumber = styled('div')({
    zIndex: 2,
    borderRadius: '50%',
    border: '4px solid white',
    width: '32px',
    height: '32px',
    backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const RefabInfoScreen: React.VFC = () => {
    const isMobile = useScreenIsMobileOrVerticalTablet();

    const steps = [
        {
            number: 1,
            title: `Click "Start refabrication order" to create a placeholder order.`,
            content: `This order will be on hold until new scans are added.`,
        },
        {
            number: 2,
            title: `Add new scans`,
            content: `Go to your laptop where Chairside is installed to re-scan the patient. Alternatively, if you want to
        use existing scans, navigate to the new order's details page, locate the 'Attach existing scans'
        button, and select the scans from your original order.`,
        },
        {
            number: 3,
            title: `Once the scans are added, we'll handle the rest!`,
        },
    ];

    return (
        <RefabBodyContainer
            title={<Text variant={isMobile ? 'h5' : 'h3'}>What happens next</Text>}
            nextTitle={'Start refabrication order'}
        >
            <Container>
                <VerticalLine />
                <ContentContainer>
                    {steps.map(step => (
                        <StepContainer key={step.number}>
                            <StepTitleContainer>
                                <StepNumber style={{ position: 'absolute', left: '-51px' }}>
                                    <Text variant={'body2'} medium color={'PRIMARY_FOREGROUND'}>
                                        {step.number}
                                    </Text>
                                </StepNumber>

                                <Text variant={'body2'} medium color={'BLACK'}>
                                    {step.title}
                                </Text>
                            </StepTitleContainer>
                            {step?.content && (
                                <Text variant={'body2'} color={'GRAY'}>
                                    {step.content}
                                </Text>
                            )}
                        </StepContainer>
                    ))}
                </ContentContainer>
            </Container>
        </RefabBodyContainer>
    );
};
