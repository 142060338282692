import { ScanReviewRecord } from '../ScanReviewRecordTypes';
import type { ScanReviewViewManager } from '../ScanReviewViewTypes';
import type { ToothPlacementVisualizationPartialScene } from './ToothPlacementVisualizationView.hooks';
import type { DcmManager } from '@orthly/forceps';
import type * as THREE from 'three';

export interface ToothPlacementVisualizationRecordFactory {
    (): ToothPlacementVisualizationJawRecord | null;
}

export class ToothPlacementVisualizationJawRecord extends ScanReviewRecord {
    constructor(
        public readonly scanMesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material>,
        public readonly scanMeshTexture: THREE.Texture,
        public readonly scanMeshMaterial: THREE.Material,
        public readonly dcmManager: DcmManager,
    ) {
        super(scanMesh, scanMeshTexture, scanMeshMaterial);
    }
}

export class ToothPlacementVisualization {
    constructor(
        public scene: ToothPlacementVisualizationPartialScene,
        public viewManager: ScanReviewViewManager,
    ) {}

    setUpperJawVisibility(visible: boolean) {
        this.scene.setUpperJawVisibility(visible);
    }

    setLowerJawVisibility(visible: boolean) {
        this.scene.setLowerJawVisibility(visible);
    }
}
