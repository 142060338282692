import {
    ScanReviewViewType,
    ScanReviewDisplayType,
    useScanReviewMarginMarkingViewContext,
    useScanReviewCompleteViewAppContext,
    QCColorLegend,
    ScanReviewMode,
    type ScanReviewScene,
} from '@orthly/dentin';
import { HeatMapType } from '@orthly/forceps';
import { type ToothNumber, AllToothNumbers, AllLowerToothNumbers } from '@orthly/items';
import { StackY, styled, FlossPalette, NumberArrayFlipperInput, StackX } from '@orthly/ui';
import { Button, Box, FormControlLabel, Checkbox } from '@orthly/ui-primitives';
import React from 'react';

const ToolPanel = styled(Box)({
    borderRadius: 8,
    margin: '4px',
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    backgroundColor: FlossPalette.WHITE,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
    minWidth: 0,
});

const ToothNumberPanel = styled(Box)({
    borderRadius: 4,
    margin: '4px',
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    backgroundColor: FlossPalette.WHITE,
    overflow: 'scroll',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    flexDirection: 'column',
    maxHeight: '150px',
    minHeight: 0,
    minWidth: 0,
});

const QCToolbarContainer = styled(StackY)({
    position: 'absolute',
    zIndex: 21,
    top: 86,
    right: 16,
});

export const QCToolbar: React.VFC = () => {
    const ctx = useScanReviewCompleteViewAppContext();
    return (
        <QCToolbarContainer>
            <QCColorLegend
                heatMapType={HeatMapType.Clearance}
                heatMapRange={ctx.appearance.heatmapRange}
                setHeatMapRange={qc => ctx.appearanceManager.setHeatmapRange({ min: qc.min, max: qc.max })}
                dynamicHeatmaps={true}
                heatMapStep={0.05}
            />
        </QCToolbarContainer>
    );
};

export const ScanReviewCompleteViewToolButtonsToolbar: React.VFC = () => {
    const { appearance, appearanceManager } = useScanReviewCompleteViewAppContext();
    return (
        <StackX>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={appearance.isScanOrScanUndercutDisplay}
                        onChange={e => e.target.checked && appearanceManager.setDisplayType(ScanReviewDisplayType.Scan)}
                    />
                }
                label={'Scan'}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={appearance.isStoneModelOrStoneModelUndercutDisplay}
                        onChange={e => {
                            if (e.target.checked) {
                                appearanceManager.setDisplayType(ScanReviewDisplayType.StoneModel);
                            }
                        }}
                    />
                }
                label={'Stone Model'}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={appearance.displayType === ScanReviewDisplayType.BiteAnalysis}
                        onChange={e =>
                            e.target.checked && appearanceManager.setDisplayType(ScanReviewDisplayType.BiteAnalysis)
                        }
                    />
                }
                label={'Bite Analysis'}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        disabled={appearance.viewType !== ScanReviewViewType.Single}
                        checked={appearance.mode === ScanReviewMode.MarginMarking}
                        onChange={e =>
                            e.target.checked
                                ? appearanceManager.setMode(ScanReviewMode.MarginMarking)
                                : appearanceManager.setMode(ScanReviewMode.Review)
                        }
                    />
                }
                label={'Margin Marking'}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        disabled={
                            appearance.viewType !== ScanReviewViewType.Single ||
                            (!appearance.upperJawInScene && !appearance.lowerJawInScene)
                        }
                        checked={appearance.mode === ScanReviewMode.Undercut}
                        onChange={e =>
                            e.target.checked
                                ? appearanceManager.setMode(ScanReviewMode.Undercut)
                                : appearanceManager.setMode(ScanReviewMode.Review)
                        }
                    />
                }
                label={'Undercut'}
            />
        </StackX>
    );
};

export const ScanReviewMarginMarkingViewToolbar: React.VFC = () => {
    const { appearanceManager, sceneState, sceneStateManager } = useScanReviewCompleteViewAppContext();
    const { toolController } = useScanReviewMarginMarkingViewContext();

    React.useEffect(() => {
        if (!sceneState.toothNumber) {
            return;
        }
        const isLowerTooth = AllLowerToothNumbers.filter(n => n === sceneState.toothNumber).length > 0;
        appearanceManager.setLowerJawVisible(isLowerTooth);
    }, [appearanceManager, sceneState.toothNumber]);

    return (
        <StackY>
            <ToolPanel>
                <Button
                    variant={'text'}
                    onClick={() => {
                        toolController?.reset();
                    }}
                >
                    Reset
                </Button>
                <Button
                    variant={'text'}
                    onClick={() => {
                        toolController?.undo();
                    }}
                >
                    Undo
                </Button>
            </ToolPanel>
            <ToothNumberPanel>
                {/*The logic here and in the NumberArrayFlipperInput component below is a bit complicated, but only exists for the purposes*/}
                {/*of providing a developer interface for testing margin marking . It isn't relevant for the use case of */}
                {/*integrating the new scan review components into an external application like ChairSide.*/}
                {AllToothNumbers.map(unn => (
                    <FormControlLabel
                        key={`prepped-tooth-${unn}`}
                        control={
                            <Checkbox
                                checked={sceneState.preppedTeeth.get(unn)}
                                onChange={e => {
                                    const updatedPreppedTeeth: Map<ToothNumber, boolean> = new Map([
                                        ...sceneState.preppedTeeth,
                                    ]).set(unn, e.target.checked);
                                    const checkedTeeth: ToothNumber[] = [...updatedPreppedTeeth]
                                        .filter(([_key, value]) => value)
                                        .map(([key, _value]) => key);
                                    if (
                                        sceneState.toothNumber === undefined ||
                                        !updatedPreppedTeeth.get(sceneState.toothNumber)
                                    ) {
                                        let newActiveTooth = undefined;
                                        if (checkedTeeth) {
                                            newActiveTooth = checkedTeeth.length
                                                ? (Math.min(...checkedTeeth) as ToothNumber)
                                                : undefined;
                                        }
                                        sceneStateManager.setToothNumber(newActiveTooth);
                                    } else if (!checkedTeeth) {
                                        sceneStateManager.setToothNumber(undefined);
                                    }
                                    sceneStateManager.setPreppedTeeth(updatedPreppedTeeth);
                                }}
                            />
                        }
                        label={unn}
                    />
                ))}
            </ToothNumberPanel>
            {sceneState.toothNumber !== undefined && (
                <NumberArrayFlipperInput
                    value={sceneState.toothNumber}
                    onChange={unn => sceneStateManager.setToothNumber(unn as ToothNumber)}
                    allowedValues={[...sceneState.preppedTeeth]
                        .filter(([_key, value]) => value === true)
                        .map(([key, _value]) => key)}
                />
            )}
        </StackY>
    );
};

export const ScanReviewCompleteViewButtonsToolbar: React.FC<{ scene: ScanReviewScene }> = ({ scene, ...props }) => {
    const { appearance, appearanceManager } = useScanReviewCompleteViewAppContext();
    return (
        <StackY>
            <ToolPanel>
                <Button variant={'text'} onClick={() => appearanceManager.setViewType(ScanReviewViewType.Single)}>
                    Single View
                </Button>
                <Button variant={'text'} onClick={() => appearanceManager.setViewType(ScanReviewViewType.SideBySide)}>
                    Side By Side
                </Button>
                <Button variant={'text'} onClick={() => appearanceManager.setViewType(ScanReviewViewType.Complete)}>
                    Complete View
                </Button>
            </ToolPanel>
            <ToolPanel>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={e => appearanceManager.setUpperJawVisible(e.target.checked)}
                            checked={appearance.upperJawVisible}
                        />
                    }
                    label={'Upper'}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={e => appearanceManager.setLowerJawVisible(e.target.checked)}
                            checked={appearance.lowerJawVisible}
                        />
                    }
                    label={'Lower'}
                />
                {props.children}
            </ToolPanel>
            {appearance.displayType === ScanReviewDisplayType.BiteAnalysis && <QCToolbar />}
        </StackY>
    );
};
