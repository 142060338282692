/* eslint-disable max-lines */
import type { ToothNumber } from '@orthly/items';
import { FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

interface ToothSvgDefinition {
    Tooth: React.ComponentType<React.SVGProps<any>>;
    ToothNumber: React.ComponentType<React.SVGProps<any>>;
    Triangle: React.ComponentType<React.SVGProps<any>>;
    Circle: React.ComponentType<React.SVGProps<any>>;
    BridgeLine: React.ComponentType<React.SVGProps<any>>;
}

export const DandyTeethSVGs: { [K in ToothNumber]: ToothSvgDefinition } = {
    1: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M9.01976 143.904C8.79295 141.409 10.6074 140.049 9.92698 134.152C9.92698 133.169 11.0157 130.704 15.3703 128.708C20.8137 126.213 27.8447 130.75 30.1127 132.564C32.3808 134.378 31.4736 139.595 30.1127 141.183C28.7519 142.77 29.4323 144.812 27.8447 147.76C26.257 150.709 18.5456 150.709 14.2363 150.255C9.92699 149.801 9.24657 146.399 9.01976 143.904Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M9.59997 154V146.392H8.91597C8.69997 147.28 8.35197 147.52 7.07997 147.52H6.53997V148.288H8.55597V154H9.59997Z'
                }
                transform={'translate(-11,-12)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M18.6877 135.739L25.3564 141.265L17.2369 144.277L18.6877 135.739Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'20.1909'}
                cy={'140.191'}
                r={'3'}
                transform={'rotate(53.963 20.1909 140.191)'}
                fill={FlossPalette.WHITE}
                opacity={'0.5'}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path
                d={'M20.5 140L25.5 117.5'}
                stroke={FlossPalette.WHITE}
                strokeWidth={'1.5'}
                opacity={'0.5'}
                {...props}
            />
        ),
    },
    2: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M14.4631 110.337C14.4631 106.708 15.5972 104.44 21.9477 103.76C27.6179 103.76 33.5148 108.069 35.7829 110.337C38.051 112.605 35.5561 118.502 35.7829 121.677C36.0097 124.852 34.6489 126.667 30.5664 128.935C26.4838 131.203 21.9477 128.255 16.2776 125.987C10.6074 123.718 13.1023 118.956 13.7827 117.141C14.4631 115.327 14.4631 113.966 14.4631 110.337Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M12.4156 130.1L13.8196 128.924C14.8636 128.06 16.1116 126.98 16.1116 125.612C16.1116 124.328 15.2716 123.296 13.5076 123.296C11.8876 123.296 10.8436 124.292 10.8436 125.84C10.8436 126.068 10.8796 126.308 10.9636 126.548L11.9836 126.596C11.8996 126.38 11.8636 126.14 11.8636 125.912C11.8636 124.808 12.4636 124.172 13.5076 124.172C14.5036 124.172 15.0436 124.772 15.0436 125.66C15.0436 126.764 14.1316 127.532 12.9196 128.564L10.7716 130.4V131H16.3516V130.1H12.4156Z'
                }
                transform={'translate(-13,-12)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M23.2621 112.314L29.9307 117.839L21.8113 120.851L23.2621 112.314Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'25.1908'}
                cy={'117.191'}
                r={'3'}
                transform={'rotate(53.963 25.1908 117.191)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M25.5 117.5L31.5 91'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    3: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M17.1846 93.0995C18.7694 89.2254 18.8503 86.4451 19.4442 83.3786C19.8023 81.5293 20.3337 79.5108 21.8564 78.402C22.8954 77.6454 24.7114 77.0009 28.2981 76.7695C35.329 76.3159 41.9064 82.2128 43.7209 87.4294C45.5353 92.6459 42.8136 97.8625 40.5456 101.718C38.2775 105.574 34.4218 106.254 29.2053 105.347C23.9888 104.44 15.1433 98.0893 17.1846 93.0995Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M16.7866 98.9C16.8466 97.796 17.5066 97.136 18.5266 97.136C19.5706 97.136 20.1226 97.7 20.1226 98.516C20.1226 99.488 19.3186 99.8 18.5026 99.8C18.1546 99.8 17.9506 99.752 17.7826 99.728V100.676C17.9626 100.652 18.1906 100.604 18.5026 100.604C19.4626 100.604 20.2426 101.06 20.2426 101.924C20.2426 102.824 19.5586 103.304 18.5746 103.304C17.4586 103.304 16.6546 102.68 16.5946 101.552L15.5986 101.864C15.7666 103.292 16.9066 104.144 18.5626 104.144C20.2306 104.144 21.2866 103.256 21.2866 101.996C21.2866 101.012 20.5186 100.268 19.5586 100.184C20.3506 100.064 21.1426 99.404 21.1426 98.42C21.1426 97.16 20.2186 96.296 18.5506 96.296C16.9906 96.296 15.9706 97.244 15.8026 98.588L16.7866 98.9Z'
                }
                transform={'translate(-13,-12)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M29.8704 86.1309L35.7847 92.4571L27.3489 94.4159L29.8704 86.1309Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'31.1908'}
                cy={'91.1908'}
                r={'3'}
                transform={'rotate(53.963 31.1908 91.1908)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M31.5 91.5L37.5 68'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    4: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M25.3497 65.6556C26.578 60.5377 33.9038 58.192 38.4881 59.2842C39.4335 59.5095 40.2778 60.0204 41.0587 60.599C44.7172 63.3093 48.1411 67.0776 49.1644 69.7382C50.2984 72.6866 47.5767 75.6351 44.8551 77.2228C42.1334 78.8104 36.4632 77.2228 33.7416 76.5423C31.0199 75.8619 23.9889 71.3257 25.3497 65.6556Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M29.8861 70.44H28.5901L24.8821 74.976V75.984H28.8781V78H29.8861V75.984H31.2421V75.132H29.8861V70.44ZM28.8781 71.34V75.132H25.7701L28.8781 71.34Z'
                }
                transform={'translate(-13,-12)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M37.2932 63.3075L41.6447 70.7951L32.9845 70.8198L37.2932 63.3075Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'37.1909'}
                cy={'68.1908'}
                r={'3'}
                transform={'rotate(53.963 37.1909 68.1908)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M37.5 68L46 51'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    5: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M47.8036 41.1611C50.7521 41.6147 52.7934 45.0168 56.8759 50.9137C57.3295 51.5706 57.3295 52.0477 57.3295 54.542V54.5426C57.3295 57.0375 55.2882 59.5324 53.7006 60.8932C52.1129 62.254 45.0819 60.2128 40.9994 59.0788C36.9169 57.9447 32.6076 51.3673 34.1953 46.8312C35.7829 42.2951 44.8551 40.7075 47.8036 41.1611Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M34.5883 53.744C34.7083 55.172 35.8603 56.144 37.5283 56.144C39.1723 56.144 40.3363 55.052 40.3363 53.492C40.3363 52.1 39.4243 50.936 37.7563 50.936C36.9043 50.936 36.2323 51.308 35.8843 51.764C35.9803 51.368 36.1483 49.7 36.1723 49.244C36.9163 49.304 38.4643 49.424 39.0403 49.424C39.7603 49.424 39.9403 49.016 39.8803 48.44H35.3923C35.3563 49.592 35.1403 51.32 34.9603 52.4L35.9803 52.58C36.2683 52.124 36.8203 51.74 37.5883 51.74C38.6683 51.74 39.3163 52.496 39.3163 53.492C39.3163 54.596 38.6203 55.316 37.5523 55.316C36.3643 55.316 35.6563 54.596 35.6203 53.528L34.5883 53.744Z'
                }
                transform={'translate(-13,-12)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M46.2233 45.9159L50.0684 53.6757L41.4256 53.1257L46.2233 45.9159Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'46.1909'}
                cy={'51.1908'}
                r={'3'}
                transform={'rotate(53.963 46.1909 51.1908)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M46 51L56.5 34'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    6: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M47.3499 28.2323C48.7828 26.0829 50.3573 24.6407 55.0897 24.5762C58.0617 24.5357 61.0188 25.5416 63.0986 27.6651C63.5522 28.1281 63.9855 28.6244 64.3603 29.1398C66.1748 31.6346 65.948 36.3976 66.1748 37.7584C66.4016 39.1192 66.1748 42.5213 63.2263 44.109C60.2778 45.6966 54.3808 43.6554 49.3911 41.1605C44.4014 38.6656 45.5354 30.954 47.3499 28.2323Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M55.8634 29.132C55.5994 27.992 54.6514 27.296 53.3074 27.296C51.1834 27.296 50.1034 29 50.1034 31.364C50.1034 33.68 51.1714 35.144 53.1874 35.144C54.8674 35.144 55.9354 34.1 55.9354 32.576C55.9354 31.124 54.9154 30.08 53.3434 30.08C52.0714 30.08 51.3034 30.776 51.0514 31.664C51.0034 29.708 51.6634 28.124 53.2954 28.124C54.2434 28.124 54.7474 28.64 54.9274 29.432L55.8634 29.132ZM51.3394 32.588C51.3394 31.568 52.0114 30.896 53.1394 30.896C54.2674 30.896 54.9394 31.568 54.9394 32.588C54.9394 33.608 54.2674 34.304 53.1754 34.304C52.0594 34.304 51.3394 33.608 51.3394 32.588Z'
                }
                transform={'translate(-13,-12)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M56.9143 29.0085L59.6426 37.2278L51.1604 35.481L56.9143 29.0085Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'56.1909'}
                cy={'34.1908'}
                r={'3'}
                transform={'rotate(53.963 56.1909 34.1908)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M56.5 34L72 25'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    7: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M62.3192 23.697C62.4901 20.1079 67.0398 16.9051 71.9894 15.9327C75.0306 15.3352 77.7167 17.2959 79.1028 20.068C80.9173 23.6969 80.2368 26.8722 80.4636 29.8207C80.6904 32.7692 78.4224 34.3568 75.7007 34.8104C72.979 35.264 62.0924 28.4599 62.3192 23.697Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M76.0639 14.4C74.5519 15.984 73.5079 18.324 73.4719 21H74.6119C74.6359 18.432 75.2839 16.14 77.1799 14.172V13.44H71.8159V14.4H76.0639Z'
                }
                transform={'translate(-13,-11)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                id={'pontic_7'}
                d={'M74.16 20.3819L74.3943 29.039L66.7799 24.9134L74.16 20.3819Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'72.1908'}
                cy={'25.1908'}
                r={'3'}
                transform={'rotate(53.963 72.1908 25.1908)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M72 25L91.5 19.5'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    8: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M100.876 18.2536C100.876 19.6359 97.7816 28.5827 93.8377 31.3234C92.7561 32.075 91.3393 32.1333 90.0931 31.707C83.1543 29.3333 79.3669 16.9334 79.7833 14.8516C80.2369 12.5835 83.4122 10.0886 89.5359 9.1814C95.6597 8.27417 97.0205 10.3154 99.0618 10.9958C101.103 11.6763 100.876 16.666 100.876 18.2536Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M101.499 13.144C103.299 13.144 104.379 12.316 104.379 10.996C104.379 10.012 103.587 9.328 102.759 9.184C103.575 8.908 104.115 8.284 104.115 7.396C104.115 6.088 102.999 5.296 101.499 5.296C99.999 5.296 98.895 6.088 98.895 7.396C98.895 8.284 99.435 8.908 100.239 9.184C99.411 9.328 98.619 10.012 98.619 10.996C98.619 12.316 99.699 13.144 101.499 13.144ZM101.499 8.812C100.563 8.812 99.927 8.332 99.927 7.468C99.927 6.532 100.563 6.064 101.499 6.064C102.435 6.064 103.071 6.532 103.071 7.468C103.071 8.332 102.435 8.812 101.499 8.812ZM101.499 12.364C100.359 12.364 99.711 11.836 99.711 10.948C99.711 10.06 100.359 9.592 101.499 9.592C102.651 9.592 103.287 10.06 103.287 10.948C103.287 11.836 102.651 12.364 101.499 12.364Z'
                }
                transform={'translate(-13,-10)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M95.5041 16.6411L91.3142 24.2204L86.8454 16.8021L95.5041 16.6411Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'91.1909'}
                cy={'19.1908'}
                r={'3'}
                transform={'rotate(53.963 91.1909 19.1908)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M91.5 19.5L112 20'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    9: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M122.783 18.4805C122.783 20.9618 119.183 27.3209 114.578 30.6316C112.727 31.9625 108.608 31.9453 106.68 30.7278C102.371 28.0062 100.33 14.6246 102.371 11.6761C104.412 8.72766 108.041 9.18149 112.35 8.95468C116.66 8.72788 121.196 13.0372 121.876 13.7176C122.557 14.398 122.783 15.3053 122.783 18.4805Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M126.663 7.852C126.663 8.872 125.991 9.544 124.863 9.544C123.735 9.544 123.063 8.872 123.063 7.852C123.063 6.832 123.735 6.136 124.827 6.136C125.943 6.136 126.663 6.832 126.663 7.852ZM122.139 11.308C122.403 12.448 123.351 13.144 124.695 13.144C126.819 13.144 127.899 11.44 127.899 9.076C127.899 6.76 126.831 5.296 124.815 5.296C123.135 5.296 122.067 6.34 122.067 7.864C122.067 9.316 123.087 10.36 124.659 10.36C125.931 10.36 126.699 9.664 126.951 8.776C126.999 10.732 126.339 12.316 124.707 12.316C123.759 12.316 123.255 11.8 123.075 11.008L122.139 11.308Z'
                }
                transform={'translate(-9,-10)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M117.137 17.6505L111.752 24.4328L108.571 16.3779L117.137 17.6505Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'112.191'}
                cy={'20.1908'}
                r={'3'}
                transform={'rotate(53.963 112.191 20.1908)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M112 20L130.5 25.5'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    10: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M122.33 32.3156C121.268 30.8555 121.449 27.2204 122.373 23.5014C123.593 18.5896 128.099 15.3724 133.093 16.1919L133.217 16.2124C135.938 16.666 139.114 21.2021 139.794 23.9238C140.474 26.6455 130.949 33.9033 128.681 34.5837C126.412 35.2641 124.144 34.8105 122.33 32.3156Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M147.797 21V13.392H147.113C146.897 14.28 146.549 14.52 145.277 14.52H144.737V15.288H146.753V21H147.797ZM155.965 17.22C155.965 14.712 154.813 13.296 152.833 13.296C150.853 13.296 149.689 14.712 149.689 17.22C149.689 19.728 150.853 21.144 152.833 21.144C154.813 21.144 155.965 19.728 155.965 17.22ZM154.885 17.22C154.885 19.272 154.225 20.304 152.833 20.304C151.441 20.304 150.781 19.272 150.781 17.22C150.781 15.168 151.441 14.136 152.833 14.136C154.225 14.136 154.885 15.168 154.885 17.22Z'
                }
                transform={'translate(-9,-11)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M135.134 25.4532L127.477 29.4992L127.802 20.845L135.134 25.4532Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'130.191'}
                cy={'25.1908'}
                r={'3'}
                transform={'rotate(53.963 130.191 25.1908)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M130.5 25.5L146.5 34'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    11: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M137.072 42.7488C134.351 40.7076 134.351 32.9962 140.475 26.4188C146.145 21.6559 151.361 24.6043 154.31 26.4188C157.258 28.2332 157.712 37.5323 154.31 40.4808C150.908 43.4292 139.794 44.7901 137.072 42.7488Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M171.768 35V27.392H171.084C170.868 28.28 170.52 28.52 169.248 28.52H168.708V29.288H170.724V35H171.768ZM176.432 35V27.392H175.748C175.532 28.28 175.184 28.52 173.912 28.52H173.372V29.288H175.388V35H176.432Z'
                }
                transform={'translate(-9,-12)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M142.332 37.5085L145.232 29.3484L150.849 35.9404L142.332 37.5085Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'146.191'}
                cy={'34.1908'}
                r={'3'}
                transform={'rotate(53.963 146.191 34.1908)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M146.5 34L156 51'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    12: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M145.011 56.1299C143.65 51.8206 147.732 45.0164 155.217 41.3875C164.743 39.1195 168.145 47.5113 167.918 52.501C167.691 57.4908 158.392 60.2124 152.722 61.1197C147.052 62.0269 146.371 60.4392 145.011 56.1299Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
                strokeMiterlimit={'10'}
                strokeLinecap={'round'}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M185.784 56V48.392H185.1C184.884 49.28 184.536 49.52 183.264 49.52H182.724V50.288H184.74V56H185.784ZM189.248 55.1L190.652 53.924C191.696 53.06 192.944 51.98 192.944 50.612C192.944 49.328 192.104 48.296 190.34 48.296C188.72 48.296 187.676 49.292 187.676 50.84C187.676 51.068 187.712 51.308 187.796 51.548L188.816 51.596C188.732 51.38 188.696 51.14 188.696 50.912C188.696 49.808 189.296 49.172 190.34 49.172C191.336 49.172 191.876 49.772 191.876 50.66C191.876 51.764 190.964 52.532 189.752 53.564L187.604 55.4V56H193.184V55.1H189.248Z'
                }
                transform={'translate(-9,-12)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M151.72 53.6076L156.318 46.269L160.374 53.9206L151.72 53.6076Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'156.191'}
                cy={'51.1908'}
                r={'3'}
                transform={'rotate(53.963 156.191 51.1908)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M156 51L165 68'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    13: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M153.629 72.914C151.788 68.4138 155.297 63.7291 161.328 60.5238C162.666 59.8126 164.136 59.3532 165.644 59.2058C174.563 58.3344 176.377 63.4883 177.217 64.749C178.124 66.1098 177.444 73.5944 169.506 76.0893C161.567 78.5842 155.671 77.9037 153.629 72.914Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M196.748 78V70.392H196.064C195.848 71.28 195.5 71.52 194.228 71.52H193.688V72.288H195.704V78H196.748ZM199.613 72.9C199.673 71.796 200.333 71.136 201.353 71.136C202.397 71.136 202.949 71.7 202.949 72.516C202.949 73.488 202.145 73.8 201.329 73.8C200.981 73.8 200.777 73.752 200.609 73.728V74.676C200.789 74.652 201.017 74.604 201.329 74.604C202.289 74.604 203.069 75.06 203.069 75.924C203.069 76.824 202.385 77.304 201.401 77.304C200.285 77.304 199.481 76.68 199.421 75.552L198.425 75.864C198.593 77.292 199.733 78.144 201.389 78.144C203.057 78.144 204.113 77.256 204.113 75.996C204.113 75.012 203.345 74.268 202.385 74.184C203.177 74.064 203.969 73.404 203.969 72.42C203.969 71.16 203.045 70.296 201.377 70.296C199.817 70.296 198.797 71.244 198.629 72.588L199.613 72.9Z'
                }
                transform={'translate(-9,-12)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M160.371 69.7749L166.214 63.383L168.828 71.6394L160.371 69.7749Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'165.191'}
                cy={'68.1908'}
                r={'3'}
                transform={'rotate(53.963 165.191 68.1908)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M165 68L172 92'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    14: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M158.846 96.0485C155.589 89.3321 160.917 82.6156 167.617 78.5215C169.134 77.5946 170.84 77.0023 172.604 76.7872C177.005 76.2508 177.813 77.3244 179.939 77.904C182.434 78.5845 181.98 84.7082 183.341 87.2031C184.702 89.698 185.836 94.6877 185.382 97.1826C184.928 99.6774 176.083 104.894 169.959 105.574C163.836 106.255 162.475 103.533 158.846 96.0485Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M204.537 104V96.392H203.853C203.637 97.28 203.289 97.52 202.017 97.52H201.477V98.288H203.493V104H204.537ZM211.195 96.44H209.899L206.191 100.976V101.984H210.187V104H211.195V101.984H212.551V101.132H211.195V96.44ZM210.187 97.34V101.132H207.079L210.187 97.34Z'
                }
                transform={'translate(-9,-12)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M167.326 93.625L173.366 87.4191L175.721 95.7533L167.326 93.625Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'172.191'}
                cy={'92.1369'}
                r={'3'}
                transform={'rotate(53.963 172.191 92.1369)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path
                d={'M172 91.5L179.5 117.5'}
                stroke={FlossPalette.WHITE}
                opacity={0.5}
                strokeWidth={'1.5'}
                {...props}
            />
        ),
    },
    15: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M165.877 113.966C165.877 110.11 167.691 107.842 174.495 106.027C176.537 105.347 181.436 104.122 184.702 104.667C188.784 105.347 188.104 112.605 188.104 115.1C188.104 117.595 189.918 121.45 188.104 124.172C186.289 126.894 176.537 129.842 170.867 128.708C165.196 127.574 165.877 117.821 165.877 113.966Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M209.672 131V123.392H208.988C208.772 124.28 208.424 124.52 207.152 124.52H206.612V125.288H208.628V131H209.672ZM211.42 128.744C211.54 130.172 212.692 131.144 214.36 131.144C216.004 131.144 217.168 130.052 217.168 128.492C217.168 127.1 216.256 125.936 214.588 125.936C213.736 125.936 213.064 126.308 212.716 126.764C212.812 126.368 212.98 124.7 213.004 124.244C213.748 124.304 215.296 124.424 215.872 124.424C216.592 124.424 216.772 124.016 216.712 123.44H212.224C212.188 124.592 211.972 126.32 211.792 127.4L212.812 127.58C213.1 127.124 213.652 126.74 214.42 126.74C215.5 126.74 216.148 127.496 216.148 128.492C216.148 129.596 215.452 130.316 214.384 130.316C213.196 130.316 212.488 129.596 212.452 128.528L211.42 128.744Z'
                }
                transform={'translate(-9,-12)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M174.202 118.064L180.914 112.592L182.297 121.141L174.202 118.064Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'179.191'}
                cy={'117.191'}
                r={'3'}
                transform={'rotate(53.963 179.191 117.191)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path
                d={'M179.5 117.5L183 139.5'}
                stroke={FlossPalette.WHITE}
                opacity={0.5}
                strokeWidth={'1.5'}
                {...props}
            />
        ),
    },
    16: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M171.547 139.821C169.732 136.192 171.093 130.976 179.939 129.388C192.186 126.667 192.186 134.151 191.959 138.234C191.733 142.316 193.547 141.182 193.32 143.904C193.093 146.626 191.279 149.574 184.475 150.254C177.671 150.935 174.269 149.12 173.588 146.626C172.908 144.131 173.361 143.45 171.547 139.821Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M213.543 154V146.392H212.859C212.643 147.28 212.295 147.52 211.023 147.52H210.483V148.288H212.499V154H213.543ZM221.195 148.132C220.931 146.992 219.983 146.296 218.639 146.296C216.515 146.296 215.435 148 215.435 150.364C215.435 152.68 216.503 154.144 218.519 154.144C220.199 154.144 221.267 153.1 221.267 151.576C221.267 150.124 220.247 149.08 218.675 149.08C217.403 149.08 216.635 149.776 216.383 150.664C216.335 148.708 216.995 147.124 218.627 147.124C219.575 147.124 220.079 147.64 220.259 148.432L221.195 148.132ZM216.671 151.588C216.671 150.568 217.343 149.896 218.471 149.896C219.599 149.896 220.271 150.568 220.271 151.588C220.271 152.608 219.599 153.304 218.507 153.304C217.391 153.304 216.671 152.608 216.671 151.588Z'
                }
                transform={'translate(-9,-12)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M178.138 139.346L185.568 134.896L185.707 143.555L178.138 139.346Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'183.193'}
                cy={'139.455'}
                r={'3'}
                transform={'rotate(53.963 183.193 139.455)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M382 23L375 50'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    17: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M371.059 22.3999C369.204 27.0158 370.595 33.651 379.639 35.6704C392.16 39.1323 392.16 29.6122 391.929 24.4193C391.697 19.2265 393.552 20.669 393.32 17.2071C393.088 13.7452 391.233 9.99487 384.276 9.1294C377.32 8.26394 373.842 10.5719 373.146 13.7452C372.45 16.9186 372.914 17.7841 371.059 22.3999Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M424.483 30V22.392H423.799C423.583 23.28 423.235 23.52 421.963 23.52H421.423V24.288H423.439V30H424.483ZM430.349 23.4C428.837 24.984 427.793 27.324 427.757 30H428.897C428.921 27.432 429.569 25.14 431.465 23.172V22.44H426.101V23.4H430.349Z'
                }
                transform={'translate(-22,0)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M385.48 19.4676L384.329 28.051L377.471 22.7625L385.48 19.4676Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'382.191'}
                cy={'23.1909'}
                r={'3'}
                transform={'rotate(53.963 382.191 23.1909)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M382 23L375 50'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    18: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M365.286 42.7845C368.735 35.1827 373.794 35.1827 380.233 37.1445C390.121 40.3323 388.741 49.1603 387.361 51.6125C385.982 54.0647 385.752 54.5551 383.222 59.9499C380.693 65.3448 376.784 64.3639 372.185 63.383C367.586 62.4021 362.297 57.743 362.067 54.5551C361.837 51.3673 361.837 50.3863 365.286 42.7845Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M418.643 60V52.392H417.959C417.743 53.28 417.395 53.52 416.123 53.52H415.583V54.288H417.599V60H418.643ZM423.331 60.144C425.131 60.144 426.211 59.316 426.211 57.996C426.211 57.012 425.419 56.328 424.591 56.184C425.407 55.908 425.947 55.284 425.947 54.396C425.947 53.088 424.831 52.296 423.331 52.296C421.831 52.296 420.727 53.088 420.727 54.396C420.727 55.284 421.267 55.908 422.071 56.184C421.243 56.328 420.451 57.012 420.451 57.996C420.451 59.316 421.531 60.144 423.331 60.144ZM423.331 55.812C422.395 55.812 421.759 55.332 421.759 54.468C421.759 53.532 422.395 53.064 423.331 53.064C424.267 53.064 424.903 53.532 424.903 54.468C424.903 55.332 424.267 55.812 423.331 55.812ZM423.331 59.364C422.191 59.364 421.543 58.836 421.543 57.948C421.543 57.06 422.191 56.592 423.331 56.592C424.483 56.592 425.119 57.06 425.119 57.948C425.119 58.836 424.483 59.364 423.331 59.364Z'
                }
                transform={'translate(-20,0)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M370.853 48.4087L379.462 47.4745L375.966 55.3978L370.853 48.4087Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'375.191'}
                cy={'50.1909'}
                r={'3'}
                transform={'rotate(53.963 375.191 50.1909)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M375 50L365 78'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    19: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M357.698 66.8171C359.467 64.3007 362.414 63.3982 367.833 63.6496C369.805 63.7412 371.787 64.178 373.429 65.2745C376.989 67.6507 378.77 71.1182 378.393 73.9284C377.933 77.3615 379.543 79.8137 378.393 84.4728C377.243 89.132 370.115 94.0364 367.815 94.5268C365.516 95.0173 357.698 90.6033 354.478 88.1511C351.259 85.6989 352.409 84.2276 352.179 81.5302C351.949 78.8328 355.628 69.7597 357.698 66.8171Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M410.543 91V83.392H409.859C409.643 84.28 409.295 84.52 408.023 84.52H407.483V85.288H409.499V91H410.543ZM416.995 85.852C416.995 86.872 416.323 87.544 415.195 87.544C414.067 87.544 413.395 86.872 413.395 85.852C413.395 84.832 414.067 84.136 415.159 84.136C416.275 84.136 416.995 84.832 416.995 85.852ZM412.471 89.308C412.735 90.448 413.683 91.144 415.027 91.144C417.151 91.144 418.231 89.44 418.231 87.076C418.231 84.76 417.163 83.296 415.147 83.296C413.467 83.296 412.399 84.34 412.399 85.864C412.399 87.316 413.419 88.36 414.991 88.36C416.263 88.36 417.031 87.664 417.283 86.776C417.331 88.732 416.671 90.316 415.039 90.316C414.091 90.316 413.587 89.8 413.407 89.008L412.471 89.308Z'
                }
                transform={'translate(-20,0)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M369.598 75.6701L365.729 83.4179L360.954 76.193L369.598 75.6701Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'365.191'}
                cy={'78.1909'}
                r={'3'}
                transform={'rotate(53.963 365.191 78.1909)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M365 78L357.5 102'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    20: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M357.698 92.3192C358.696 92.3192 358.654 92.6888 361.935 94.3903C363.083 94.9859 364.226 95.6249 365.161 96.5189C367.841 99.0803 368.603 101.67 369.195 103.354C369.885 105.316 367.585 109.24 365.746 110.956C363.906 112.673 357.008 112.427 354.708 111.692C352.409 110.956 348.73 107.278 347.12 103.845C345.51 100.412 348.27 96.2427 350.799 93.7906C353.329 91.3384 356.548 92.3192 357.698 92.3192Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M397.613 113.1L399.017 111.924C400.061 111.06 401.309 109.98 401.309 108.612C401.309 107.328 400.469 106.296 398.705 106.296C397.085 106.296 396.041 107.292 396.041 108.84C396.041 109.068 396.077 109.308 396.161 109.548L397.181 109.596C397.097 109.38 397.061 109.14 397.061 108.912C397.061 107.808 397.661 107.172 398.705 107.172C399.701 107.172 400.241 107.772 400.241 108.66C400.241 109.764 399.329 110.532 398.117 111.564L395.969 113.4V114H401.549V113.1H397.613ZM408.95 110.22C408.95 107.712 407.798 106.296 405.818 106.296C403.838 106.296 402.674 107.712 402.674 110.22C402.674 112.728 403.838 114.144 405.818 114.144C407.798 114.144 408.95 112.728 408.95 110.22ZM407.87 110.22C407.87 112.272 407.21 113.304 405.818 113.304C404.426 113.304 403.766 112.272 403.766 110.22C403.766 108.168 404.426 107.136 405.818 107.136C407.21 107.136 407.87 108.168 407.87 110.22Z'
                }
                transform={'translate(-20,0)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M353.435 99.4159L362.031 100.476L356.815 107.389L353.435 99.4159Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'357.191'}
                cy={'102.191'}
                r={'3'}
                transform={'rotate(53.963 357.191 102.191)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M357.5 102L350.5 119'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    21: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M342.061 112.919C343.211 109.976 347.35 110.712 353.789 111.938C354.938 112.155 358.848 116.842 359.767 119.049C360.687 121.256 358.388 125.18 355.858 127.387C353.329 129.594 349.65 129.594 345.051 127.387C340.452 125.18 340.911 115.861 342.061 112.919Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M387.548 133.1L388.952 131.924C389.996 131.06 391.244 129.98 391.244 128.612C391.244 127.328 390.404 126.296 388.64 126.296C387.02 126.296 385.976 127.292 385.976 128.84C385.976 129.068 386.012 129.308 386.096 129.548L387.116 129.596C387.032 129.38 386.996 129.14 386.996 128.912C386.996 127.808 387.596 127.172 388.64 127.172C389.636 127.172 390.176 127.772 390.176 128.66C390.176 129.764 389.264 130.532 388.052 131.564L385.904 133.4V134H391.484V133.1H387.548ZM395.452 134V126.392H394.768C394.552 127.28 394.204 127.52 392.932 127.52H392.392V128.288H394.408V134H395.452Z'
                }
                transform={'translate(-20,0)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M346.494 116.34L355.067 117.564L349.72 124.377L346.494 116.34Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'350.191'}
                cy={'119.191'}
                r={'3'}
                transform={'rotate(53.963 350.191 119.191)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M350.5 119L337 133'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    22: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M331.713 139.157C329.891 137.797 329.513 132.894 330.236 128.599C330.596 126.461 331.603 124.409 333.553 123.463C336.082 122.237 344.821 129.839 346.2 132.291C347.58 134.743 343.441 140.629 340.452 141.855C337.462 143.081 334.013 140.874 331.713 139.157Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M370.593 153.1L371.997 151.924C373.041 151.06 374.289 149.98 374.289 148.612C374.289 147.328 373.449 146.296 371.685 146.296C370.065 146.296 369.021 147.292 369.021 148.84C369.021 149.068 369.057 149.308 369.141 149.548L370.161 149.596C370.077 149.38 370.041 149.14 370.041 148.912C370.041 147.808 370.641 147.172 371.685 147.172C372.681 147.172 373.221 147.772 373.221 148.66C373.221 149.764 372.309 150.532 371.097 151.564L368.949 153.4V154H374.529V153.1H370.593ZM377.238 153.1L378.642 151.924C379.686 151.06 380.934 149.98 380.934 148.612C380.934 147.328 380.094 146.296 378.33 146.296C376.71 146.296 375.666 147.292 375.666 148.84C375.666 149.068 375.702 149.308 375.786 149.548L376.806 149.596C376.722 149.38 376.686 149.14 376.686 148.912C376.686 147.808 377.286 147.172 378.33 147.172C379.326 147.172 379.866 147.772 379.866 148.66C379.866 149.764 378.954 150.532 377.742 151.564L375.594 153.4V154H381.174V153.1H377.238Z'
                }
                transform={'translate(-20,0)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M334.29 129.533L342.367 132.658L335.623 138.09L334.29 129.533Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'337.191'}
                cy={'133.191'}
                r={'3'}
                transform={'rotate(53.963 337.191 133.191)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M337 133L323 140'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    23: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M320.216 130.329C322.975 129.103 326.424 133.026 329.874 137.931C330.221 138.421 331.483 141.118 331.253 142.59C331.023 144.061 328.494 147.985 324.585 149.211C320.676 150.437 317.456 147.985 316.306 145.778C315.157 143.571 317.456 131.555 320.216 130.329Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M346.552 161.1L347.956 159.924C349 159.06 350.248 157.98 350.248 156.612C350.248 155.328 349.408 154.296 347.644 154.296C346.024 154.296 344.98 155.292 344.98 156.84C344.98 157.068 345.016 157.308 345.1 157.548L346.12 157.596C346.036 157.38 346 157.14 346 156.912C346 155.808 346.6 155.172 347.644 155.172C348.64 155.172 349.18 155.772 349.18 156.66C349.18 157.764 348.268 158.532 347.056 159.564L344.908 161.4V162H350.488V161.1H346.552ZM352.609 156.9C352.669 155.796 353.329 155.136 354.349 155.136C355.393 155.136 355.945 155.7 355.945 156.516C355.945 157.488 355.141 157.8 354.325 157.8C353.977 157.8 353.773 157.752 353.605 157.728V158.676C353.785 158.652 354.013 158.604 354.325 158.604C355.285 158.604 356.065 159.06 356.065 159.924C356.065 160.824 355.381 161.304 354.397 161.304C353.281 161.304 352.477 160.68 352.417 159.552L351.421 159.864C351.589 161.292 352.729 162.144 354.385 162.144C356.053 162.144 357.109 161.256 357.109 159.996C357.109 159.012 356.341 158.268 355.381 158.184C356.173 158.064 356.965 157.404 356.965 156.42C356.965 155.16 356.041 154.296 354.373 154.296C352.813 154.296 351.793 155.244 351.625 156.588L352.609 156.9Z'
                }
                transform={'translate(-20,2)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M321.253 135.925L328.413 140.795L320.615 144.561L321.253 135.925Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'323.191'}
                cy={'140.191'}
                r={'3'}
                transform={'rotate(53.963 323.191 140.191)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M323 140L308 142'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    24: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M301.59 146.759C300.845 144.574 302.061 139.978 304.015 135.964C304.895 134.157 306.316 132.563 308.258 132.045C311.018 131.31 314.697 143.571 314.927 145.778C315.157 147.985 310.328 149.946 308.488 149.946C306.649 149.946 302.51 149.456 301.59 146.759Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M326.447 163.1L327.851 161.924C328.895 161.06 330.143 159.98 330.143 158.612C330.143 157.328 329.303 156.296 327.539 156.296C325.919 156.296 324.875 157.292 324.875 158.84C324.875 159.068 324.911 159.308 324.995 159.548L326.015 159.596C325.931 159.38 325.895 159.14 325.895 158.912C325.895 157.808 326.495 157.172 327.539 157.172C328.535 157.172 329.075 157.772 329.075 158.66C329.075 159.764 328.163 160.532 326.951 161.564L324.803 163.4V164H330.383V163.1H326.447ZM336.085 156.44H334.789L331.081 160.976V161.984H335.077V164H336.085V161.984H337.441V161.132H336.085V156.44ZM335.077 157.34V161.132H331.969L335.077 157.34Z'
                }
                transform={'translate(-20,4)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M307.947 137.45L312.977 144.5L304.357 145.331L307.947 137.45Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'308.191'}
                cy={'142.191'}
                r={'3'}
                transform={'rotate(53.963 308.191 142.191)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M308 142H293.5'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    25: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M299.75 146.759C300.495 144.574 299.279 139.978 297.325 135.964C296.445 134.157 295.024 132.563 293.081 132.045C290.322 131.31 286.643 143.571 286.413 145.778C286.183 147.985 291.012 149.946 292.852 149.946C294.691 149.946 298.83 149.456 299.75 146.759Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M309.505 163.1L310.909 161.924C311.953 161.06 313.201 159.98 313.201 158.612C313.201 157.328 312.361 156.296 310.597 156.296C308.977 156.296 307.933 157.292 307.933 158.84C307.933 159.068 307.969 159.308 308.053 159.548L309.073 159.596C308.989 159.38 308.953 159.14 308.953 158.912C308.953 157.808 309.553 157.172 310.597 157.172C311.593 157.172 312.133 157.772 312.133 158.66C312.133 159.764 311.221 160.532 310.009 161.564L307.861 163.4V164H313.441V163.1H309.505ZM314.387 161.744C314.507 163.172 315.659 164.144 317.327 164.144C318.971 164.144 320.135 163.052 320.135 161.492C320.135 160.1 319.223 158.936 317.555 158.936C316.703 158.936 316.031 159.308 315.683 159.764C315.779 159.368 315.947 157.7 315.971 157.244C316.715 157.304 318.263 157.424 318.839 157.424C319.559 157.424 319.739 157.016 319.679 156.44H315.191C315.155 157.592 314.939 159.32 314.759 160.4L315.779 160.58C316.067 160.124 316.619 159.74 317.387 159.74C318.467 159.74 319.115 160.496 319.115 161.492C319.115 162.596 318.419 163.316 317.351 163.316C316.163 163.316 315.455 162.596 315.419 161.528L314.387 161.744Z'
                }
                transform={'translate(-22,4)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M293.621 137.431L297.657 145.093L289.003 144.757L293.621 137.431Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'293.191'}
                cy={'142.191'}
                r={'3'}
                transform={'rotate(53.963 293.191 142.191)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M293.5 142L278 140'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    26: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M281.124 130.329C278.365 129.103 274.916 133.026 271.466 137.931C271.119 138.421 269.857 141.118 270.087 142.59C270.317 144.061 272.846 147.985 276.755 149.211C280.664 150.437 283.884 147.985 285.033 145.778C286.183 143.571 283.884 131.555 281.124 130.329Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M290.382 161.1L291.786 159.924C292.83 159.06 294.078 157.98 294.078 156.612C294.078 155.328 293.238 154.296 291.474 154.296C289.854 154.296 288.81 155.292 288.81 156.84C288.81 157.068 288.846 157.308 288.93 157.548L289.95 157.596C289.866 157.38 289.83 157.14 289.83 156.912C289.83 155.808 290.43 155.172 291.474 155.172C292.47 155.172 293.01 155.772 293.01 156.66C293.01 157.764 292.098 158.532 290.886 159.564L288.738 161.4V162H294.318V161.1H290.382ZM301.156 156.132C300.892 154.992 299.944 154.296 298.6 154.296C296.476 154.296 295.396 156 295.396 158.364C295.396 160.68 296.464 162.144 298.48 162.144C300.16 162.144 301.228 161.1 301.228 159.576C301.228 158.124 300.208 157.08 298.636 157.08C297.364 157.08 296.596 157.776 296.344 158.664C296.296 156.708 296.956 155.124 298.588 155.124C299.536 155.124 300.04 155.64 300.22 156.432L301.156 156.132ZM296.632 159.588C296.632 158.568 297.304 157.896 298.432 157.896C299.56 157.896 300.232 158.568 300.232 159.588C300.232 160.608 299.56 161.304 298.468 161.304C297.352 161.304 296.632 160.608 296.632 159.588Z'
                }
                transform={'translate(-22,2)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M279.981 135.675L281.766 144.149L273.534 141.457L279.981 135.675Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'278.191'}
                cy={'140.191'}
                r={'3'}
                transform={'rotate(53.963 278.191 140.191)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M278 140L263.5 133'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    27: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M269.627 139.157C271.449 137.797 271.827 132.894 271.104 128.599C270.744 126.461 269.737 124.409 267.787 123.463C265.258 122.237 256.519 129.839 255.14 132.291C253.76 134.743 257.899 140.629 260.888 141.855C263.878 143.081 267.327 140.874 269.627 139.157Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M268.316 153.1L269.72 151.924C270.764 151.06 272.012 149.98 272.012 148.612C272.012 147.328 271.172 146.296 269.408 146.296C267.788 146.296 266.744 147.292 266.744 148.84C266.744 149.068 266.78 149.308 266.864 149.548L267.884 149.596C267.8 149.38 267.764 149.14 267.764 148.912C267.764 147.808 268.364 147.172 269.408 147.172C270.404 147.172 270.944 147.772 270.944 148.66C270.944 149.764 270.032 150.532 268.82 151.564L266.672 153.4V154H272.252V153.1H268.316ZM277.316 147.4C275.804 148.984 274.76 151.324 274.724 154H275.864C275.888 151.432 276.536 149.14 278.432 147.172V146.44H273.068V147.4H277.316Z'
                }
                transform={'translate(-22,0)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M258.488 132.647L266.572 129.54L265.221 138.094L258.488 132.647Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'263.191'}
                cy={'133.191'}
                r={'3'}
                transform={'rotate(53.963 263.191 133.191)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M263.5 133L251 120'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    28: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M259.279 112.919C258.129 109.976 253.99 110.712 247.551 111.938C246.402 112.155 242.492 116.842 241.573 119.049C240.653 121.256 242.952 125.18 245.482 127.387C248.011 129.594 251.69 129.594 256.289 127.387C260.888 125.18 260.429 115.861 259.279 112.919Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M250.453 133.1L251.857 131.924C252.901 131.06 254.149 129.98 254.149 128.612C254.149 127.328 253.309 126.296 251.545 126.296C249.925 126.296 248.881 127.292 248.881 128.84C248.881 129.068 248.917 129.308 249.001 129.548L250.021 129.596C249.937 129.38 249.901 129.14 249.901 128.912C249.901 127.808 250.501 127.172 251.545 127.172C252.541 127.172 253.081 127.772 253.081 128.66C253.081 129.764 252.169 130.532 250.957 131.564L248.809 133.4V134H254.389V133.1H250.453ZM258.321 134.144C260.121 134.144 261.201 133.316 261.201 131.996C261.201 131.012 260.409 130.328 259.581 130.184C260.397 129.908 260.937 129.284 260.937 128.396C260.937 127.088 259.821 126.296 258.321 126.296C256.821 126.296 255.717 127.088 255.717 128.396C255.717 129.284 256.257 129.908 257.061 130.184C256.233 130.328 255.441 131.012 255.441 131.996C255.441 133.316 256.521 134.144 258.321 134.144ZM258.321 129.812C257.385 129.812 256.749 129.332 256.749 128.468C256.749 127.532 257.385 127.064 258.321 127.064C259.257 127.064 259.893 127.532 259.893 128.468C259.893 129.332 259.257 129.812 258.321 129.812ZM258.321 133.364C257.181 133.364 256.533 132.836 256.533 131.948C256.533 131.06 257.181 130.592 258.321 130.592C259.473 130.592 260.109 131.06 260.109 131.948C260.109 132.836 259.473 133.364 258.321 133.364Z'
                }
                transform={'translate(-23,0)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M246.652 118.946L255.098 117.032L252.532 125.303L246.652 118.946Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'251.191'}
                cy={'120.191'}
                r={'3'}
                transform={'rotate(53.963 251.191 120.191)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M251 120L243 102'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    29: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M243.642 92.3192C242.644 92.3192 242.686 92.6888 239.405 94.3903C238.257 94.9859 237.114 95.6249 236.179 96.5189C233.499 99.0803 232.737 101.67 232.145 103.354C231.455 105.316 233.754 109.24 235.594 110.956C237.433 112.673 244.332 112.427 246.631 111.692C248.931 110.956 252.61 107.278 254.22 103.845C255.83 100.412 253.07 96.2427 250.541 93.7906C248.011 91.3384 244.792 92.3192 243.642 92.3192Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M238.353 113.1L239.757 111.924C240.801 111.06 242.049 109.98 242.049 108.612C242.049 107.328 241.209 106.296 239.445 106.296C237.825 106.296 236.781 107.292 236.781 108.84C236.781 109.068 236.817 109.308 236.901 109.548L237.921 109.596C237.837 109.38 237.801 109.14 237.801 108.912C237.801 107.808 238.401 107.172 239.445 107.172C240.441 107.172 240.981 107.772 240.981 108.66C240.981 109.764 240.069 110.532 238.857 111.564L236.709 113.4V114H242.289V113.1H238.353ZM247.986 108.852C247.986 109.872 247.314 110.544 246.186 110.544C245.058 110.544 244.386 109.872 244.386 108.852C244.386 107.832 245.058 107.136 246.15 107.136C247.266 107.136 247.986 107.832 247.986 108.852ZM243.462 112.308C243.726 113.448 244.674 114.144 246.018 114.144C248.142 114.144 249.222 112.44 249.222 110.076C249.222 107.76 248.154 106.296 246.138 106.296C244.458 106.296 243.39 107.34 243.39 108.864C243.39 110.316 244.41 111.36 245.982 111.36C247.254 111.36 248.022 110.664 248.274 109.776C248.322 111.732 247.662 113.316 246.03 113.316C245.082 113.316 244.578 112.8 244.398 112.008L243.462 112.308Z'
                }
                transform={'translate(-22,0)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M238.997 100.109L247.65 99.7493L243.635 107.423L238.997 100.109Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'243.191'}
                cy={'102.191'}
                r={'3'}
                transform={'rotate(53.963 243.191 102.191)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M243 102L235 79'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    30: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M243.642 66.8171C241.872 64.3007 238.925 63.3982 233.507 63.6496C231.534 63.7412 229.553 64.178 227.911 65.2745C224.351 67.6507 222.57 71.1182 222.947 73.9284C223.407 77.3615 221.797 79.8137 222.947 84.4728C224.096 89.132 231.225 94.0364 233.524 94.5268C235.824 95.0173 243.642 90.6033 246.862 88.1511C250.081 85.6989 248.931 84.2276 249.161 81.5302C249.391 78.8328 245.712 69.7597 243.642 66.8171Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M227.984 85.9C228.044 84.796 228.704 84.136 229.724 84.136C230.768 84.136 231.32 84.7 231.32 85.516C231.32 86.488 230.516 86.8 229.7 86.8C229.352 86.8 229.148 86.752 228.98 86.728V87.676C229.16 87.652 229.388 87.604 229.7 87.604C230.66 87.604 231.44 88.06 231.44 88.924C231.44 89.824 230.756 90.304 229.772 90.304C228.656 90.304 227.852 89.68 227.792 88.552L226.796 88.864C226.964 90.292 228.104 91.144 229.76 91.144C231.428 91.144 232.484 90.256 232.484 88.996C232.484 88.012 231.716 87.268 230.756 87.184C231.548 87.064 232.34 86.404 232.34 85.42C232.34 84.16 231.416 83.296 229.748 83.296C228.188 83.296 227.168 84.244 227 85.588L227.984 85.9ZM239.991 87.22C239.991 84.712 238.839 83.296 236.859 83.296C234.879 83.296 233.715 84.712 233.715 87.22C233.715 89.728 234.879 91.144 236.859 91.144C238.839 91.144 239.991 89.728 239.991 87.22ZM238.911 87.22C238.911 89.272 238.251 90.304 236.859 90.304C235.467 90.304 234.807 89.272 234.807 87.22C234.807 85.168 235.467 84.136 236.859 84.136C238.251 84.136 238.911 85.168 238.911 87.22Z'
                }
                transform={'translate(-22,0)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M231.613 76.194L240.134 77.7404L234.534 84.3467L231.613 76.194Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'235.191'}
                cy={'79.1909'}
                r={'3'}
                transform={'rotate(53.963 235.191 79.1909)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M235 79L226 50'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    31: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M236.054 42.7845C232.605 35.1827 227.546 35.1827 221.107 37.1445C211.219 40.3323 212.599 49.1603 213.979 51.6125C215.358 54.0647 215.588 54.5551 218.118 59.9499C220.647 65.3448 224.556 64.3639 229.155 63.383C233.754 62.4021 239.043 57.743 239.273 54.5551C239.503 51.3673 239.503 50.3863 236.054 42.7845Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M219.955 54.9C220.015 53.796 220.675 53.136 221.695 53.136C222.739 53.136 223.291 53.7 223.291 54.516C223.291 55.488 222.487 55.8 221.671 55.8C221.323 55.8 221.119 55.752 220.951 55.728V56.676C221.131 56.652 221.359 56.604 221.671 56.604C222.631 56.604 223.411 57.06 223.411 57.924C223.411 58.824 222.727 59.304 221.743 59.304C220.627 59.304 219.823 58.68 219.763 57.552L218.767 57.864C218.935 59.292 220.075 60.144 221.731 60.144C223.399 60.144 224.455 59.256 224.455 57.996C224.455 57.012 223.687 56.268 222.727 56.184C223.519 56.064 224.311 55.404 224.311 54.42C224.311 53.16 223.387 52.296 221.719 52.296C220.159 52.296 219.139 53.244 218.971 54.588L219.955 54.9ZM228.457 60V52.392H227.773C227.557 53.28 227.209 53.52 225.937 53.52H225.397V54.288H227.413V60H228.457Z'
                }
                transform={'translate(-22,0)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M222.823 46.9616L231.23 49.0382L225.228 55.2811L222.823 46.9616Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'226.191'}
                cy={'50.1909'}
                r={'3'}
                transform={'rotate(53.963 226.191 50.1909)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (props: React.SVGProps<any>) => (
            <path d={'M226 50L219.5 22'} stroke={FlossPalette.WHITE} opacity={0.5} strokeWidth={'1.5'} {...props} />
        ),
    },
    32: {
        Tooth: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M230.281 22.3999C232.136 27.0158 230.745 33.651 221.701 35.6704C209.18 39.1323 209.179 29.6122 209.411 24.4193C209.643 19.2265 207.788 20.669 208.02 17.2071C208.252 13.7452 210.107 9.99487 217.064 9.1294C224.02 8.26394 227.498 10.5719 228.194 13.7452C228.89 16.9186 228.426 17.7841 230.281 22.3999Z'
                }
                strokeWidth={'0'}
                fill={FlossPalette.WHITE}
                {...props}
            />
        ),
        ToothNumber: (props: React.SVGProps<any>) => (
            <path
                d={
                    'M211.97 24.9C212.03 23.796 212.69 23.136 213.71 23.136C214.754 23.136 215.306 23.7 215.306 24.516C215.306 25.488 214.502 25.8 213.686 25.8C213.338 25.8 213.134 25.752 212.966 25.728V26.676C213.146 26.652 213.374 26.604 213.686 26.604C214.646 26.604 215.426 27.06 215.426 27.924C215.426 28.824 214.742 29.304 213.758 29.304C212.642 29.304 211.838 28.68 211.778 27.552L210.782 27.864C210.95 29.292 212.09 30.144 213.746 30.144C215.414 30.144 216.47 29.256 216.47 27.996C216.47 27.012 215.702 26.268 214.742 26.184C215.534 26.064 216.326 25.404 216.326 24.42C216.326 23.16 215.402 22.296 213.734 22.296C212.174 22.296 211.154 23.244 210.986 24.588L211.97 24.9ZM219.273 29.1L220.677 27.924C221.721 27.06 222.969 25.98 222.969 24.612C222.969 23.328 222.129 22.296 220.365 22.296C218.745 22.296 217.701 23.292 217.701 24.84C217.701 25.068 217.737 25.308 217.821 25.548L218.841 25.596C218.757 25.38 218.721 25.14 218.721 24.912C218.721 23.808 219.321 23.172 220.365 23.172C221.361 23.172 221.901 23.772 221.901 24.66C221.901 25.764 220.989 26.532 219.777 27.564L217.629 29.4V30H223.209V29.1H219.273Z'
                }
                transform={'translate(-22,0)'}
                fill={FlossPalette.GRAY}
                opacity={0.5}
                {...props}
            />
        ),
        Triangle: (props: React.SVGProps<any>) => (
            <path
                d={'M216.13 18.6682L224.331 21.451L217.82 27.1619L216.13 18.6682Z'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        Circle: (props: React.SVGProps<any>) => (
            <circle
                cx={'219.191'}
                cy={'22.1909'}
                r={'3'}
                transform={'rotate(53.963 219.191 22.1909)'}
                fill={FlossPalette.WHITE}
                opacity={0.5}
                {...props}
            />
        ),
        BridgeLine: (_props: React.SVGProps<any>) => null,
    },
};
