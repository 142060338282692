import { type ScanReviewDcmFile } from '@orthly/dentin';
import { DcmManager } from '@orthly/forceps';
import { type Jaw } from '@orthly/shared-types';
import _ from 'lodash';
import React from 'react';
import type { DropEvent } from 'react-dropzone';

type SetDcmFilesFn = React.Dispatch<React.SetStateAction<ScanReviewDcmFile[]>>;
type FileDropFunction = <T extends File>(jawType: Jaw, files: T[], event: DropEvent) => void;

export function useAcceptDcmFiles(setDcmFiles: SetDcmFilesFn): { onDcmDrop: FileDropFunction } {
    const onDcmDrop = React.useCallback<FileDropFunction>(
        async <T extends File>(jawType: Jaw, files: T[]) => {
            const dcmFiles = _.compact(
                await Promise.all(
                    files.map(async (file: T) => {
                        const name = file.name;

                        const dcmManager = DcmManager.tryBuildDcmManager(await file.text());
                        if (!dcmManager) {
                            console.warn(`Failed to parse DCM file: ${name}`);
                            return undefined;
                        }

                        const dcmFile = {
                            name: file.name,
                            dcmManager: dcmManager,
                            jawType: jawType,
                        };
                        return dcmFile;
                    }),
                ),
            );
            setDcmFiles(curr => [...curr.filter(c => c.jawType !== jawType), ...dcmFiles]);
        },
        [setDcmFiles],
    );

    return { onDcmDrop };
}
