import { LabsUtilsBase } from '@orthly/dentin';
import type { LabsGqlCreateLabsManufacturerMutationVariables } from '@orthly/graphql-operations';
import { useCreateLabsManufacturerMutation } from '@orthly/graphql-react';
import { QuickForm, QuickFormValidations, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

interface CreateLabsManufacturerActionProps {
    onComplete: () => Promise<any>;
}

type Vars = LabsGqlCreateLabsManufacturerMutationVariables['fields'];

export const CreateLabsManufacturerAction: React.FC<CreateLabsManufacturerActionProps> = props => {
    const [submitMtn] = useCreateLabsManufacturerMutation();
    const mtnSubmitter = (fields: Vars) => submitMtn({ variables: { fields } });
    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Lab Manufacturer created!', {}],
        onSuccess: async () => {
            await props.onComplete();
        },
    });
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Create Lab Manufacturer'}
            content={
                <QuickForm<Vars>
                    fields={{
                        display_name: { type: 'text' },
                        phone_number: {
                            type: 'text',
                            mask: '+19999999999',
                            validation: QuickFormValidations.phone,
                            optional: true,
                            fieldProps: { variant: 'standard' },
                        },
                        email: { type: 'text', validation: QuickFormValidations.email },
                        time_zone: {
                            type: 'select',
                            options: LabsUtilsBase.notificationTimeZoneOptions,
                            helperText: 'Used to time notifications properly',
                        },
                        first_name: { type: 'text' },
                        last_name: { type: 'text' },
                        password: { type: 'text' },
                    }}
                    initialValues={{}}
                    onSubmit={async fields => {
                        await submit(fields);
                    }}
                />
            }
            buttonText={'Create Lab'}
            buttonProps={{ style: { maxWidth: 250 } }}
        />
    );
};
