import type { AdminLabsOrderActionProps } from './order-action-types';
import type {
    LabsGqlChangeLabOrderManufacturerMutationVariables,
    LabsGqlOrderSlaFragment,
    LabsGqlOrganizationDtoFragment,
    LabsGqlOverrideManufacturerNonProdMutationVariables,
} from '@orthly/graphql-operations';
import {
    useChangeLabOrderManufacturerMutation,
    useOverrideManufacturerNonProdMutation,
    useListLabOrgsWithoutRelations,
} from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { DateUtils } from '@orthly/shared-types';
import type { FieldsDefProp } from '@orthly/ui';
import {
    OrthlyBrowserConfig,
    OrthlyFrontendApps,
    PencilOutlinedIcon,
    QuickForm,
    RootActionDialog,
    useChangeSubmissionFn,
} from '@orthly/ui';
import { IconButton } from '@orthly/ui-primitives';
import React from 'react';

const IS_PROD = new OrthlyBrowserConfig(OrthlyFrontendApps.admin).isProduction;

function getManufacturerDropDownOptions(
    manufacturers: Pick<LabsGqlOrganizationDtoFragment, 'id' | 'name'>[],
    currentManufacturerId: string,
) {
    return manufacturers.map(m => ({
        label: `${m.name} ${m.id === currentManufacturerId ? '(current)' : ''}`,
        value: m.id,
    }));
}

type FormFields = { manufacturerId: string; disable_sla_reset: boolean; send_notification: boolean; reason: string };

interface ChangeManufacturerFormFieldsArgs {
    orderStatus: LabsGqlLabOrderStatus;
    manufacturer_id: string;
    sla: LabsGqlOrderSlaFragment;
    skip: boolean;
}

function useChangeManufacturerFormFields({
    orderStatus,
    manufacturer_id,
    sla,
    skip,
}: ChangeManufacturerFormFieldsArgs): FieldsDefProp<FormFields> {
    const { data } = useListLabOrgsWithoutRelations({ skip });
    const manufacturers = data?.listOrganizations ?? [];
    const resetHelperText = React.useMemo(() => {
        const shipByFmt = DateUtils.format(sla.ship_by_date, 'MM/DD/YY');
        const dueDateFmt = DateUtils.format(sla.due_date, 'MM/DD/YY');
        return `Keeps original manufacturer Ship By (${shipByFmt}), and Due Date (${dueDateFmt})`;
    }, [sla.due_date, sla.ship_by_date]);
    return {
        manufacturerId: {
            type: 'select',
            label: 'Manufacturer',
            options: getManufacturerDropDownOptions(manufacturers, manufacturer_id),
            helperText:
                orderStatus !== LabsGqlLabOrderStatus.NeedsReview
                    ? `This will set the order status back to 'New' and reset the due date SLA`
                    : undefined,
        },
        disable_sla_reset: {
            type: 'boolean',
            label: 'Keep original SLA dates',
            helperText: resetHelperText,
            hidden: sla.version === 3,
        },
        send_notification: { type: 'boolean', label: 'Notify practice if due date changes?' },
        reason: { type: 'text', label: 'Why are we changing lab?' },
    };
}

const ChangeProdManufacturer: React.FC<AdminLabsOrderActionProps> = props => {
    type Vars = LabsGqlChangeLabOrderManufacturerMutationVariables;
    const { order } = props;

    const [submitMtn] = useChangeLabOrderManufacturerMutation();
    const mtnSubmitter = (variables: Vars) => submitMtn({ variables });
    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Order manufacturer updated', {}],
        onSuccess: async () => {
            await props.refetchOrder();
        },
    });

    const formFields = useChangeManufacturerFormFields({
        orderStatus: order.status,
        manufacturer_id: order.manufacturer_id,
        sla: order.manufacturer_sla,
        skip: !open,
    });

    const openButton = (
        <IconButton onClick={() => setOpen(true)} style={{ padding: 0 }}>
            <PencilOutlinedIcon />
        </IconButton>
    );
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Change Manufacturer'}
            content={
                <QuickForm<FormFields>
                    dirtySubmitOnly={true}
                    initialValues={{ manufacturerId: props.order.manufacturer_id }}
                    fields={formFields}
                    onSubmit={async result => {
                        const { manufacturerId, disable_sla_reset, send_notification } = result;
                        const activeTask = props.order.fulfillment_workflow.active_task;

                        if (
                            activeTask &&
                            ['WaxupReview'].includes(activeTask.type) &&
                            !window.confirm('This order has a design preview currently pending review. Are you sure?')
                        ) {
                            return;
                        }

                        await submit({
                            manufacturerId,
                            disable_sla_reset,
                            disable_notification: !send_notification,
                            orderId: props.order.id,
                            reason: result.reason,
                        });
                    }}
                />
            }
            buttonText={'Change Manufacturer'}
            CustomButton={_p => openButton}
        />
    );
};

const OverrideNonProdManufacturer: React.FC<AdminLabsOrderActionProps> = props => {
    type Vars = LabsGqlOverrideManufacturerNonProdMutationVariables['data'];
    const [submitMtn] = useOverrideManufacturerNonProdMutation();
    const { order } = props;

    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Order manufacturer updated', {}],
        onSuccess: async () => {
            await props.refetchOrder();
        },
    });

    const formFields = useChangeManufacturerFormFields({
        orderStatus: order.status,
        manufacturer_id: order.manufacturer_id,
        sla: order.manufacturer_sla,
        skip: !open,
    });

    const openButton = (
        <IconButton onClick={() => setOpen(true)} style={{ padding: 0 }}>
            <PencilOutlinedIcon />
        </IconButton>
    );
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Change Staging Manufacturer'}
            content={
                <QuickForm<FormFields>
                    dirtySubmitOnly={true}
                    initialValues={{ manufacturerId: props.order.manufacturer_id }}
                    fields={formFields}
                    onSubmit={async result => {
                        const { manufacturerId, disable_sla_reset, send_notification } = result;
                        await submit({
                            manufacturerId,
                            disable_sla_reset,
                            orderId: props.order.id,
                            disable_notification: !send_notification,
                        });
                    }}
                />
            }
            buttonText={'Change Manufacturer (staging)'}
            CustomButton={_p => openButton}
        />
    );
};

export const ChangeManufacturer: React.FC<AdminLabsOrderActionProps> = props => {
    const { order } = props;
    const allowedStatuses = [
        LabsGqlLabOrderStatus.New,
        LabsGqlLabOrderStatus.Fabrication,
        LabsGqlLabOrderStatus.NeedsReview,
    ];
    if (order.status && allowedStatuses.includes(order.status)) {
        return <ChangeProdManufacturer {...props} />;
    }
    if (!IS_PROD) {
        return <OverrideNonProdManufacturer {...props} />;
    }
    return null;
};
