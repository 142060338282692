import Avatar1 from '../../../assets/images/shared-profile-avatar/1.svg';
import Avatar2 from '../../../assets/images/shared-profile-avatar/2.svg';
import Avatar3 from '../../../assets/images/shared-profile-avatar/3.svg';
import Avatar4 from '../../../assets/images/shared-profile-avatar/4.svg';
import Avatar5 from '../../../assets/images/shared-profile-avatar/5.svg';
import Avatar6 from '../../../assets/images/shared-profile-avatar/6.svg';
import { LabsGqlDoctorProfilePhotoColor } from '@orthly/graphql-schema';

export const PROFILE_PHOTO_AVATAR_OPTIONS: string[] = [Avatar1, Avatar2, Avatar3, Avatar4, Avatar5, Avatar6];

export const PROFILE_PHOTO_COLOR_TO_HEX: { [_ in LabsGqlDoctorProfilePhotoColor]: string } = {
    // these colors are not in FlossPalette, and are used for profile photo only
    [LabsGqlDoctorProfilePhotoColor.Red]: '#EB5757',
    [LabsGqlDoctorProfilePhotoColor.Orange]: '#F2994A',
    [LabsGqlDoctorProfilePhotoColor.Yellow]: '#F2C94C',
    [LabsGqlDoctorProfilePhotoColor.DarkGreen]: '#219653',
    [LabsGqlDoctorProfilePhotoColor.LightGreen]: '#6FCF97',
    [LabsGqlDoctorProfilePhotoColor.DarkBlue]: '#2F80ED',
    [LabsGqlDoctorProfilePhotoColor.LightBlue]: '#56CCF2',
    [LabsGqlDoctorProfilePhotoColor.Purple]: '#9B51E0',
    [LabsGqlDoctorProfilePhotoColor.Black]: '#545350',
};
