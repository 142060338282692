import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { CurrentWaxupGeneralStructuredNotesControls } from './CurrentWaxupGeneralStructuredNotesControls';
import { GeneralReviewRejectionControlsDesktop } from './GeneralReviewRejectionControlsDesktop';
import { GeneralViewContainer, WaxupModelViewerAndControls } from './WaxupModelViewerControls';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

const GeneralViewContent: React.VFC = () => {
    const { enableStructuredDesignReviewNotes, selectedTab } = useGuidedWaxupContext();
    const isMobile = useScreenIsMobileOrVerticalTablet();

    if (selectedTab !== LabsGqlGuidedWaxupPresetType.GeneralView) {
        return null;
    }

    if (enableStructuredDesignReviewNotes) {
        return (
            <GeneralViewContainer>
                <CurrentWaxupGeneralStructuredNotesControls />
            </GeneralViewContainer>
        );
    }

    if (!isMobile) {
        return (
            <GeneralViewContainer>
                <GeneralReviewRejectionControlsDesktop />
            </GeneralViewContainer>
        );
    }

    return null;
};

export const CurrentWaxupModelViewerAndControls: React.VFC = () => {
    return (
        <WaxupModelViewerAndControls>
            <GeneralViewContent />
        </WaxupModelViewerAndControls>
    );
};
