import { AttachmentPreview } from '../FilePreview';
import type { LabsGqlWorkflowTask_InternalDesignWorkflowTask_Fragment as LabsGqlInternalDesignTaskFragment } from '@orthly/graphql-operations';
import { LabsGqlGuidedQcResultEntryScoreVerdict } from '@orthly/graphql-schema';
import { Button, FlossPalette, Text, Grid, LaunchIcon } from '@orthly/ui-primitives';
import React from 'react';

type GuidedQcResults = {
    name: string;
    verdict: LabsGqlGuidedQcResultEntryScoreVerdict;
    affected_unns: number[];
    markup_paths?: string[] | null;
    reasons?: string[] | null;
    note?: string | null;
};

interface PreviousDesignRejectionDetailsProps {
    rejection_notes?: string;
    rejection_reasons?: string[];
    rejection_attachment_urls?: string[];
    guided_qc_results?: GuidedQcResults[];
}

export const PreviousDesignRejectionDetails: React.VFC<PreviousDesignRejectionDetailsProps> = ({
    rejection_notes,
    rejection_reasons,
    rejection_attachment_urls,
    guided_qc_results,
}) => {
    const [filesOpen, setFilesOpen] = React.useState(false);

    const guidedQcAttachments = (guided_qc_results ?? []).flatMap(r => r.markup_paths ?? []);

    if (!rejection_reasons?.length && !guided_qc_results?.length && !guidedQcAttachments.length) {
        return null;
    }

    if (rejection_reasons?.length) {
        return (
            <Grid container style={{ paddingBottom: 8 }}>
                <Grid container>
                    <Text variant={'body2'} color={'ATTENTION'}>
                        Previous design rejected
                    </Text>
                </Grid>
                <Grid container>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        Reasons: <span style={{ color: FlossPalette.BLACK }}>{rejection_reasons.join(', ')}</span>
                    </Text>
                </Grid>
                {rejection_notes && (
                    <Grid container>
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            Notes: <span style={{ color: FlossPalette.BLACK }}>{rejection_notes}</span>
                        </Text>
                    </Grid>
                )}
                {rejection_attachment_urls && (
                    <Grid container alignItems={'center'}>
                        <Grid item>
                            <Text variant={'body2'} color={'DARK_GRAY'}>
                                Attachments:
                            </Text>
                        </Grid>
                        <Grid item>
                            <AttachmentPreview
                                open={filesOpen}
                                setOpen={setFilesOpen}
                                title={'Design rejection files'}
                                buttonText={''}
                                CustomButton={props => (
                                    <Button
                                        variant={'text'}
                                        onClick={props.onClick}
                                        style={{ padding: 0, margin: 0, marginLeft: 5 }}
                                    >
                                        View &nbsp; <LaunchIcon style={{ fontSize: '1.1em' }} />
                                    </Button>
                                )}
                                sources={rejection_attachment_urls.map((source, idx) => ({
                                    source,
                                    name: `Attachment ${idx + 1}`,
                                }))}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }

    return (
        <Grid container style={{ paddingBottom: 8 }}>
            <Grid container>
                <Text variant={'body2'} color={'ATTENTION'}>
                    Previous design rejected
                </Text>
            </Grid>
            <Grid container>
                {!!guided_qc_results?.length &&
                    guided_qc_results
                        ?.filter(r => r.verdict === LabsGqlGuidedQcResultEntryScoreVerdict.Bad)
                        .map(r => {
                            const reasonsString = r.reasons?.join(', ');
                            const noteString = r.note ? `[${r.note}]` : '';
                            return (
                                <Text key={r.name} variant={'body2'} color={'DARK_GRAY'}>
                                    {r.name}:{' '}
                                    <span style={{ color: FlossPalette.BLACK }}>
                                        {`${reasonsString} ${noteString}`.trim()}
                                    </span>
                                </Text>
                            );
                        })}
            </Grid>
            {guidedQcAttachments.length > 0 && (
                <Grid container alignItems={'center'}>
                    <Grid item>
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            Attachments:
                        </Text>
                    </Grid>
                    <Grid item>
                        <AttachmentPreview
                            open={filesOpen}
                            setOpen={setFilesOpen}
                            title={'Design rejection files'}
                            buttonText={''}
                            CustomButton={props => (
                                <Button
                                    variant={'text'}
                                    onClick={props.onClick}
                                    style={{ padding: 0, margin: 0, marginLeft: 5 }}
                                >
                                    View &nbsp; <LaunchIcon style={{ fontSize: '1.1em' }} />
                                </Button>
                            )}
                            sources={guidedQcAttachments.map((source, idx) => ({
                                source,
                                name: `Attachment ${idx + 1}`,
                            }))}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export const PreviousDesignRejectionDetailsFromTask: React.VFC<{ task: LabsGqlInternalDesignTaskFragment }> = ({
    task,
}) => {
    const { rejection_notes, rejection_reasons, rejection_attachment_urls, guided_qc_results } = task.configuration;

    return (
        <PreviousDesignRejectionDetails
            rejection_reasons={rejection_reasons ?? undefined}
            rejection_notes={rejection_notes ?? undefined}
            rejection_attachment_urls={rejection_attachment_urls ?? undefined}
            guided_qc_results={guided_qc_results ?? undefined}
        />
    );
};
