import { NEXT_INVOICE_VALUE, PAST_INVOICE_PLACEHOLDER_VALUE } from './CreditOrRefund.types';
import { useCreditOrRefundFormContext } from './providers/CreditOrRefundFormProvider.graphql';
import { dayjsExt as dayjs } from '@orthly/runtime-utils';
import { useHasCapability } from '@orthly/session-client';
import { SimpleSelect } from '@orthly/ui';
import {
    FlossPalette,
    FormControl,
    FormControlLabel,
    RadioPrimitive as Radio,
    RadioGroupPrimitive as RadioGroup,
    styled,
    Text,
} from '@orthly/ui-primitives';
import React from 'react';

const RequiredLabel = styled(Text)({
    '&::after': {
        color: FlossPalette.ATTENTION,
        content: '"*"',
    },
});

const ApplyContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
});

const StyledFormControl = styled(FormControl)({
    width: '100%',
});

const StyledFormControlLabel = styled(FormControlLabel)({
    marginLeft: '-8px',
    '.MuiTypography-root': {
        marginLeft: '0px',
        '&.Mui-disabled': {
            color: FlossPalette.DARK_TAN,
        },
    },
    '.MuiButtonBase-root': {
        margin: '8px',
        '&.Mui-disabled': {
            backgroundColor: FlossPalette.DARK_TAN,
        },
    },
});

export const CreditOrRefundSelector: React.FC = () => {
    const { formState, dispatchFormStateAction, eligibleForRefund, viewOnly, existingCredit, invoiceOptionsForCredit } =
        useCreditOrRefundFormContext();

    const nextInvoiceMonthYear = dayjs.utc().format('MMM YYYY');
    const nextInvoiceText = `Next invoice (${nextInvoiceMonthYear})`;
    const existingCreditInvoiceMonthYear = dayjs.utc(existingCredit?.created_at).format('MMM YYYY');
    const existingCreditInvoiceText = `${existingCreditInvoiceMonthYear} Invoice`;

    const userCanIssueRefund = useHasCapability('billing', 'billing.issue_refunds');
    const creatingRefund = formState.action === 'refund';
    const disableCreditForNextInvoice = creatingRefund || viewOnly;
    const noEligibleInvoicesForCredit = !invoiceOptionsForCredit.length;
    const disableCreditForPreviousInvoice = creatingRefund || noEligibleInvoicesForCredit || viewOnly;
    const disableRefund = !userCanIssueRefund || !eligibleForRefund || !formState.attributed || viewOnly;

    return (
        <ApplyContainer>
            <StyledFormControl>
                {viewOnly ? (
                    <Text variant={'body2'} medium>
                        Applied On
                    </Text>
                ) : (
                    <RequiredLabel variant={'body2'} medium>
                        Apply On
                    </RequiredLabel>
                )}
                <RadioGroup aria-label={'apply-on'} value={formState.applyOn}>
                    <StyledFormControlLabel
                        value={NEXT_INVOICE_VALUE}
                        onChange={() => dispatchFormStateAction({ type: 'SET_APPLY_ON', applyOn: NEXT_INVOICE_VALUE })}
                        control={<Radio color={'secondary'} />}
                        disabled={disableCreditForNextInvoice}
                        label={
                            <Text variant={'body2'} color={disableCreditForNextInvoice ? 'GRAY' : 'BLACK'}>
                                {viewOnly ? existingCreditInvoiceText : nextInvoiceText}
                            </Text>
                        }
                    />
                    <StyledFormControlLabel
                        value={PAST_INVOICE_PLACEHOLDER_VALUE}
                        onChange={() =>
                            dispatchFormStateAction({ type: 'SET_APPLY_ON', applyOn: PAST_INVOICE_PLACEHOLDER_VALUE })
                        }
                        disabled={disableCreditForPreviousInvoice}
                        control={<Radio color={'secondary'} />}
                        label={
                            <Text variant={'body2'} color={disableCreditForPreviousInvoice ? 'GRAY' : 'BLACK'}>
                                Previous invoice
                            </Text>
                        }
                    />
                </RadioGroup>
                {formState.applyOn && formState.applyOn !== NEXT_INVOICE_VALUE && (
                    <SimpleSelect
                        flossInputConfig={{ backgroundColor: 'gray', bordered: true }}
                        options={invoiceOptionsForCredit}
                        label={''}
                        placeholder={'Select a previous invoice'}
                        value={formState.existingInvoiceId}
                        onChange={value => {
                            dispatchFormStateAction({ type: 'SET_EXISTING_INVOICE', existingInvoiceId: value });
                        }}
                        SelectProps={{
                            variant: 'standard',
                            style: { padding: '0px' },
                            inputProps: { style: { width: '100%' } },
                            MenuProps: {
                                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                                style: { height: '350px' },
                            },
                        }}
                    />
                )}
            </StyledFormControl>
            <FormControl>
                {viewOnly ? (
                    <Text variant={'body2'} medium>
                        Applied
                    </Text>
                ) : (
                    <RequiredLabel variant={'body2'} medium>
                        How to Apply
                    </RequiredLabel>
                )}
                <RadioGroup aria-label={'how-to-apply'} value={formState.action}>
                    <StyledFormControlLabel
                        value={'credit'}
                        onChange={() => dispatchFormStateAction({ type: 'SET_ACTION', actionType: 'credit' })}
                        disabled={viewOnly}
                        control={<Radio color={'secondary'} />}
                        label={<Text variant={'body2'}>As a credit</Text>}
                    />
                    <StyledFormControlLabel
                        value={'refund'}
                        control={<Radio color={'secondary'} />}
                        disabled={disableRefund}
                        onChange={() => dispatchFormStateAction({ type: 'SET_ACTION', actionType: 'refund' })}
                        label={
                            <Text variant={'body2'} color={disableRefund ? 'GRAY' : 'BLACK'}>
                                As a refund (Permission controlled + Only on paid invoices)
                            </Text>
                        }
                    />
                </RadioGroup>
            </FormControl>
        </ApplyContainer>
    );
};
