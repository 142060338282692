import { useUsersAction } from '../state/Users.actions';
import { useUsersSelector } from '../state/Users.context';
import type { UserRole } from '../state/Users.types';
import { NotificationLogsDialog } from './NotificationLogsDialog';
import { PreferredContactInfo } from './PreferredContactInfo';
import { useEditUserRole } from './hooks/useEditUserRole';
import type { LabsGqlOrganizationSummaryFragment } from '@orthly/graphql-operations';
import { useListOrgSummariesQuery } from '@orthly/graphql-react';
import { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import type { StaffRole } from '@orthly/retainer-common';
import { StaffRoleInternal, StaffRoleLab, StaffRolePractice, StaffRoleParent } from '@orthly/retainer-common';
import { SimpleSelect, SimpleAutocomplete, SimpleMultiSelect, TrashIcon, LoadBlocker } from '@orthly/ui';
import { FlossPalette, Grid, CircularProgress, IconButton, Icon, Button, Text, Tooltip } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

interface EditUserRoleProps {
    organizations: LabsGqlOrganizationSummaryFragment[];
    idx: number;
    role: UserRole;
    inactive?: boolean;
}

const valueToRole = (value: string) => {
    if (value === 'internal') {
        return LabsGqlOrganizationType.Internal;
    } else if (value === 'lab') {
        return LabsGqlOrganizationType.Lab;
    } else if (value === 'practice') {
        return LabsGqlOrganizationType.Practice;
    } else if (value === 'parent') {
        return LabsGqlOrganizationType.Parent;
    } else {
        return undefined;
    }
};
const options = [
    { value: 'internal', label: 'Internal' },
    { value: 'lab', label: 'Lab' },
    { value: 'practice', label: 'Practice' },
    { value: 'parent', label: 'Parent' },
];

const roleOptions = (role: UserRole) => {
    const enumToOptions = (e: any) =>
        Object.keys(e).map(value => ({ value, label: `${_.startCase(_.last(value.split('__')))}` }));
    switch (role.type) {
        case LabsGqlOrganizationType.Internal:
            return enumToOptions(StaffRoleInternal);
        case LabsGqlOrganizationType.Practice:
            return enumToOptions(StaffRolePractice);
        case LabsGqlOrganizationType.Lab:
            return enumToOptions(StaffRoleLab);
        case LabsGqlOrganizationType.Parent:
            return enumToOptions(StaffRoleParent);
        default:
            return [];
    }
};

const orgOptions = (
    organizations: LabsGqlOrganizationSummaryFragment[] | undefined,
    role: UserRole,
): { value: any; label: any }[] => {
    return organizations?.filter(org => org.type === role.type).map(org => ({ value: org.id, label: org.name })) ?? [];
};

const EditUserRole: React.FC<EditUserRoleProps> = props => {
    const { organizations, role, idx, inactive } = props;
    const organizationOptions = orgOptions(organizations, role);
    const { submitting, removeRoleFn, saveRoleFn, reactivateRoleFn, isPractice, drPref, user } = useEditUserRole(
        role,
        idx,
    );
    const updateRole = useUsersAction('UPDATE_ROLE');
    const color = role.activated_at ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.GRAY;
    const activatedAtHelperText = role.activated_at
        ? `Practice profile activated at ${role.activated_at}`
        : 'Practice profile has not be activated.';

    const { value: enableNotificationLogTableOps = false } = useFeatureFlag('enableNotificationLogTableOps');

    return (
        <LoadBlocker blocking={submitting}>
            <Grid
                container
                item
                direction={'row'}
                style={
                    inactive
                        ? {
                              border: `1px solid ${FlossPalette.ATTENTION_BACKGROUND}`,
                              borderRadius: '4px',
                              background: FlossPalette.ATTENTION_BACKGROUND,
                              padding: 4,
                          }
                        : {
                              border: `1px solid ${FlossPalette.DARK_TAN}`,
                              borderRadius: '4px',
                              background: FlossPalette.TAN,
                              padding: 4,
                          }
                }
            >
                <Grid xs={10} container item alignItems={'flex-start'}>
                    <Grid container spacing={1} item direction={'row'}>
                        <Grid item xs={4}>
                            <SimpleSelect
                                label={'Type'}
                                value={role.type}
                                disabled={role.deactivated || !role.editing}
                                options={options}
                                onChange={value =>
                                    updateRole({
                                        idx,
                                        property: 'type',
                                        value: valueToRole(value as string) ?? undefined,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SimpleMultiSelect
                                disabled={role.deactivated || !role.editing || !role.type}
                                label={'Roles'}
                                value={role.roles}
                                options={roleOptions(role)}
                                onChange={value =>
                                    updateRole({ idx, property: 'roles', value: (value as StaffRole[]) ?? [] })
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SimpleAutocomplete
                                // forces a rerender when orgs size changes (aka loading completes)
                                // because the value of the autocomplete can only be set on initial render
                                key={`${organizationOptions.length}`}
                                disabled={role.deactivated || !role.editing || !role.type}
                                label={_.startCase(role.type ?? 'Organization')}
                                options={organizationOptions}
                                variant={'standard'}
                                onChange={value => {
                                    updateRole({ idx, property: 'organization_id', value: value ?? undefined });
                                }}
                                AutocompleteProps={{
                                    style: { width: '100%' },
                                    value: role.organization_id
                                        ? organizationOptions.find(opt => opt.value === role.organization_id)
                                        : null,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container xs={2} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item>
                        {isPractice && (
                            <Tooltip title={activatedAtHelperText}>
                                <Icon
                                    icon={'CheckIcon'}
                                    style={{
                                        color: color,
                                        marginLeft: 10,
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Grid>
                    <Grid xs={8} container justifyContent={'flex-end'}>
                        {drPref && enableNotificationLogTableOps && (
                            <Grid item>
                                <NotificationLogsDialog
                                    doctorPreferencesId={drPref.id}
                                    doctorPreferencesName={drPref.name}
                                />
                            </Grid>
                        )}
                        {role.deactivated ? (
                            <Grid item>
                                <IconButton
                                    onClick={async () => {
                                        await reactivateRoleFn();
                                    }}
                                >
                                    <Icon icon={'Restore'} />
                                </IconButton>
                            </Grid>
                        ) : (
                            <>
                                <Grid item>
                                    <IconButton
                                        onClick={async () => {
                                            await saveRoleFn();
                                        }}
                                    >
                                        <Icon icon={role.editing ? 'Save' : 'PencilIcon'} />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        onClick={async () => {
                                            await removeRoleFn();
                                        }}
                                    >
                                        <TrashIcon style={{ color: FlossPalette.ATTENTION }} />
                                    </IconButton>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </Grid>
                {drPref && user && isPractice && !role.deactivated && (
                    <PreferredContactInfo
                        userFirstName={user.first_name}
                        userLastName={user.last_name}
                        userEmail={user.email}
                        userMobilePhone={user.mobile_phone_number ?? ''}
                        drPrefEmail={drPref.contact_email ?? ''}
                        drPrefMobilePhone={drPref.contact_phone ?? ''}
                        drPrefName={drPref.name}
                        drPrefId={drPref.id}
                    />
                )}
            </Grid>
        </LoadBlocker>
    );
};

export const EditUserRoles: React.FC = () => {
    const { roles, user, rolesLoaded } = useUsersSelector(s => ({
        roles: s.form?.roles ?? [],
        user: s.user,
        rolesLoaded: s.form?.rolesLoaded ?? false,
    }));
    const addRole = useUsersAction('ADD_ROLE');

    const { data: organizationsRaw, loading: organizationsLoading } = useListOrgSummariesQuery();

    const { activeRoles, inactiveRoles } = React.useMemo(() => {
        const [activeRoles, inactiveRoles] = _.partition(
            roles.map((role, idx) => ({ ...role, idx })),
            r => !r.deactivated,
        );
        return { activeRoles, inactiveRoles };
    }, [roles]);

    if (!user) {
        return null;
    }

    return (
        <LoadBlocker blocking={organizationsLoading}>
            {rolesLoaded && (
                <Grid container spacing={1} direction={'column'}>
                    <Grid
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '16px 0 0px 0',
                            alignItems: 'center',
                        }}
                    >
                        <Text variant={'h6'}>Roles</Text>
                        <Button startIcon={'AddIcon'} onClick={addRole} variant={'primary'} disabled={!rolesLoaded}>
                            Add New Role
                        </Button>
                    </Grid>
                    {activeRoles.length > 0 && (
                        <>
                            <Grid container spacing={3} item direction={'column'}>
                                {activeRoles.map(role => (
                                    <Grid container item key={role.idx}>
                                        <EditUserRole
                                            organizations={organizationsRaw?.listOrganizationSummaries ?? []}
                                            idx={role.idx}
                                            role={role}
                                            inactive={false}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}

                    {inactiveRoles.length > 0 && (
                        <>
                            <Grid item style={{ padding: '16px 0' }}>
                                <Text variant={'h6'}>Inactive Roles</Text>
                            </Grid>

                            <Grid container spacing={3} item direction={'column'}>
                                {inactiveRoles.map(role => (
                                    <Grid container item key={role.idx}>
                                        <EditUserRole
                                            organizations={organizationsRaw?.listOrganizationSummaries ?? []}
                                            idx={role.idx}
                                            role={role}
                                            inactive={true}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </>
                    )}
                </Grid>
            )}
            {!rolesLoaded && <CircularProgress color={'primary'} />}
        </LoadBlocker>
    );
};
