import { PricingHistoryModalBase } from './PricingHistoryModalBase';
import { GetOrderPriceHistoryItems_Query } from './PricingHistoryQueries.graphql';
import type { OrderPricingHistoryProps, OrderPricingHistoryModalProps } from './types';
import { useQuery } from '@apollo/client';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

const OrderPricingHistoryModal: React.VFC<OrderPricingHistoryModalProps> = ({ orderId, open, setOpen }) => {
    const { data: { getOrderPriceHistoryItems: orderPriceHistoryItems = [] } = {}, loading } = useQuery(
        GetOrderPriceHistoryItems_Query,
        {
            variables: { orderId },
        },
    );

    return (
        <PricingHistoryModalBase
            historyItems={orderPriceHistoryItems}
            loadingHistoryItems={loading}
            title={'Pricing History'}
            subtitle={
                'Below are changes made to practice price configs, order ledger adjustments, price overrides, and credits and refunds that might affect the pricing for this order.'
            }
            open={open}
            setOpen={setOpen}
        />
    );
};

export const OrderPricingHistoryButton: React.VFC<OrderPricingHistoryProps> = ({ orderId, shouldShowButtonIcon }) => {
    const [open, setOpen] = React.useState<boolean>(false);

    return (
        <>
            {open && <OrderPricingHistoryModal orderId={orderId} open={open} setOpen={setOpen} />}
            <Button
                onClick={() => setOpen(true)}
                variant={'ghost'}
                startIcon={shouldShowButtonIcon ? 'History' : undefined}
            >
                Pricing History
            </Button>
        </>
    );
};
