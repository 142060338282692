import { useOpsInboxAction } from '../../screens/Inbox/state/Inbox.actions';
import { useInboxState } from '../../screens/Inbox/state/Inbox.selectors';
import { useOrdersOverviewAction } from '../../screens/Orders/state/OrdersOverview.actions';
import { useOrdersOverviewState, isOrdersSearchViewActive } from '../../screens/Orders/state/OrdersOverview.selectors';
import { useCurrentOrdersSort } from '../../screens/Orders/state/OrdersStateUtils';
import { LabsGqlLabOrderSortKey } from '@orthly/graphql-schema';
import { SimpleInput, useDebouncedState } from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    FlossPaletteUtils,
    Grid,
    IconButton,
    InputAdornment,
    CloseIcon,
    SearchIcon,
} from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory<{ mode: string }>(() => ({
    root: props => ({
        width: props.mode === 'inbox' ? '30%' : '50%',
        position: 'relative',
        height: '100%',
        alignContent: 'flex-start',
    }),
    textField: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    textFieldActive: { background: FlossPaletteUtils.toRgba('STAR_GRASS', 0.15) },
    inputRoot: {
        // Need to override the default padding of the input root
        '&&&&': {
            paddingRight: 16,
        },
        background: 'transparent',
    },
}));

interface SearchViewControls {
    setSearch: (search?: string) => void;
    onSearchBlurOrClear: () => void;
    searchViewActive: boolean;
    search?: string;
}

function useInboxSearchControls() {
    const setSearch = useOpsInboxAction('SET_SEARCH');
    const onSearchBlurOrClear = useOpsInboxAction('SEARCH_ON_BLUR');
    const { searchViewActive, search } = useInboxState(s => ({
        searchViewActive: !!s.view?.search,
        search: s.view?.search,
    }));
    return { setSearch, onSearchBlurOrClear, searchViewActive, search };
}

export function useOrdersSearchControls(): SearchViewControls {
    const setSearch = useOrdersOverviewAction('SET_SEARCH');
    const onSearchBlurOrClear = useOrdersOverviewAction('SEARCH_ON_BLUR');
    const { searchViewActive, search } = useOrdersOverviewState(s => ({
        searchViewActive: isOrdersSearchViewActive(s),
        search: s.screen === 'custom' ? s.view.search : undefined,
    }));
    return { setSearch, onSearchBlurOrClear, searchViewActive, search };
}

function useSearchControls(mode: 'orders' | 'inbox') {
    const ordersControls = useOrdersSearchControls();
    const inboxControls = useInboxSearchControls();
    return mode === 'orders' ? ordersControls : inboxControls;
}

interface SearchInputAdornmentProps {
    active: boolean;
    onClear: () => void;
}

const SearchInputAdornment: React.VFC<SearchInputAdornmentProps> = ({ active, onClear }) => {
    return (
        <InputAdornment position={'end'} style={{ height: 30, paddingBottom: 4 }}>
            <Grid
                container
                style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                wrap={'nowrap'}
            >
                {active ? (
                    <IconButton onClick={onClear} size={'small'} style={{ padding: 0 }}>
                        <CloseIcon style={{ color: FlossPalette.STAR_GRASS }} />
                    </IconButton>
                ) : (
                    <SearchIcon color={'action'} />
                )}
            </Grid>
        </InputAdornment>
    );
};

export const OrdersListToolbarSearch: React.FC<{ mode: 'orders' | 'inbox' }> = props => {
    const { onSearchBlurOrClear, search, searchViewActive, setSearch } = useSearchControls(props.mode);
    const setSort = useOrdersOverviewAction('SORT_KEY_CLICKED');
    const sort = useCurrentOrdersSort();
    const classes = useStyles({ mode: props.mode });

    // We debounce by 2 seconds to avoid constantly hitting the backend while the user is typing.
    // Locally they will see their changes, and after a few seconds it'll propogate to redux and the backend.
    const [localSearch, setLocalSearch] = useDebouncedState(search, setSearch, 2000);

    const onClearSearch = React.useCallback(() => {
        // We call both to avoid breaking local state, and having the user have to wait 2 seconds
        // for their clear to take effect.
        setLocalSearch(undefined);
        setSearch(undefined);

        // Reset to Updated At Sort once search is erased
        if (!sort.key) {
            setSort(LabsGqlLabOrderSortKey.UpdatedAt);
        }
        onSearchBlurOrClear();
    }, [onSearchBlurOrClear, setSearch, setSort, sort, setLocalSearch]);
    return (
        <Grid container className={classes.root}>
            <SimpleInput
                fullWidth
                label={props.mode === 'inbox' ? 'Search orders' : 'Search'}
                onChange={value => {
                    // When search has begun, set to relevance sort (which can be changed later). if search is undefined
                    // reset to updatedAt
                    localSearch !== value && setSort(value ? undefined : LabsGqlLabOrderSortKey.UpdatedAt);
                    setLocalSearch(value);
                }}
                value={localSearch ?? ''}
                placeholder={'Type to search...'}
                TextFieldProps={{
                    className: clsx(classes.textField, searchViewActive && classes.textFieldActive),
                    onBlur: () => onSearchBlurOrClear(),
                    InputLabelProps: { style: { background: 'none' } },
                    InputProps: {
                        className: classes.inputRoot,
                        endAdornment: <SearchInputAdornment onClear={onClearSearch} active={searchViewActive} />,
                        disableUnderline: true,
                        style: { borderRadius: 0, border: 'none', boxShadow: 'none' },
                    },
                }}
            />
        </Grid>
    );
};
