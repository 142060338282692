import {
    useDesignAccelerationdata,
    useDesignMetadata,
    useDesignModelPayloadsInner,
    useDesignXmlPayload,
} from '../DesignViewer/OrderDesignPreview.hooks.graphql';
import type { FirebasePreviewFileMultiWithType } from '../DesignViewer/OrderDesignPreview.types';
import { getDesignPayloadItems } from '../DesignViewer/OrderDesignPreview.util';
import type { ScanModels } from './AppState.hooks';
import {
    generateCurtainsRecord,
    getPreferredScanType,
    parseMarginLines,
    isPrePrepAssetPath,
} from './FetchDesignData.utils';
import type { AutomateValidation, MarginLinesMap, MinimalOrder } from './Types';
import type { DesignFinishingRestorativeModel, ModelPayloadItem } from '@orthly/dentin';
import { TexturedModel, Jaw, isCurrentCadItem } from '@orthly/dentin';
import { bakeFacetMarksIntoGeometry } from '@orthly/forceps';
import type { FragmentType, VeneerDesignFinishingDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlAutomateValidationError } from '@orthly/graphql-schema';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import { ToothUtils } from '@orthly/items';
import { FileNameUtils } from '@orthly/runtime-utils';
import type { DesignAccelerationData, MorphPointsInternalData } from '@orthly/shared-types';
import { compact, groupBy } from 'lodash';
import path from 'path-browserify';
import React from 'react';
import * as THREE from 'three';

const VeneerDesignFinishingDesign_Fragment = graphql(`
    fragment VeneerDesignFinishingDesign_Fragment on DesignOrderDesignRevisionDTO {
        id
        has_morph_points

        conversion_artifacts {
            design_tree_path
            metadata_json_path
            design_acceleration_data_path
            manufacturing_acceleration_data_path
        }

        validation_data {
            automate_validation_done
            automate_validation_errors
        }

        case_file_model_elements {
            model_element_id
            model_file_name
            insertion_axis
            model_element_type
            model_job_id
        }

        case_file_tooth_elements {
            model_element_id
            tooth_element_id
            tooth_number
            tooth_type_class
        }

        assets {
            file_path
            file_type
            mesh_type
            image_path
        }
    }
`);

export interface DesignModels {
    restorativeModels: DesignFinishingRestorativeModel[];
    scanModels: Partial<ScanModels>;
    marginLines: MarginLinesMap;
}

interface DesignData {
    loading: boolean;
    error: Error | undefined;
    design: VeneerDesignFinishingDesign_FragmentFragment;
    models: DesignModels;
    automateValidation: AutomateValidation;
}

function modelPayloadToTexturedModel(payload?: ModelPayloadItem): TexturedModel | undefined {
    return payload && new TexturedModel(payload.model.geometry, payload.colorMap);
}

/**
 * Downloads the models (restoratives, jaw scans) of the design that are needed for finishing.
 */
export function useFetchDesignData(
    designFragment: FragmentType<typeof VeneerDesignFinishingDesign_Fragment>,
    order: MinimalOrder,
): DesignData {
    const design = getFragmentData(VeneerDesignFinishingDesign_Fragment, designFragment);
    const {
        payloads: { result: designPayloads, loading, error },
        designMetadata,
        designAccelerationData,
        automateValidation,
    } = useProcessDesignData(design);

    const models = React.useMemo<DesignModels>(() => {
        const modelPayloadItems = getDesignPayloadItems(order, compact(designPayloads) ?? [], design ?? undefined);

        const curtainsModelsMapByID: Record<string, ModelPayloadItem> = generateCurtainsRecord(modelPayloadItems);
        return {
            restorativeModels: compact(
                modelPayloadItems
                    .filter(isCurrentCadItem)
                    .map(m => createRestorativeModel(m, designAccelerationData, curtainsModelsMapByID)),
            ),
            scanModels: {
                upperJaw: modelPayloadToTexturedModel(modelPayloadItems.find(isUpperJawItem)),
                lowerJaw: modelPayloadToTexturedModel(modelPayloadItems.find(isLowerJawItem)),
                prePrepUpperJaw: modelPayloadToTexturedModel(
                    modelPayloadItems.find(i => isPrePrepItem(i) && isUpperJawItem(i)),
                ),
                prePrepLowerJaw: modelPayloadToTexturedModel(
                    modelPayloadItems.find(i => isPrePrepItem(i) && isLowerJawItem(i)),
                ),
            },
            marginLines: parseMarginLines(designMetadata.margin_lines ?? []),
        };
    }, [order, designPayloads, design, designMetadata, designAccelerationData]);

    return { loading, error, design, models, automateValidation };
}

/**
 * Downloads the design assets that are required for finishing.
 */
function useProcessDesignData(design: VeneerDesignFinishingDesign_FragmentFragment) {
    const { result: modellingTreePayload } = useDesignXmlPayload(
        design.conversion_artifacts?.design_tree_path ?? undefined,
    );

    const designMetadata = useDesignMetadata(design.conversion_artifacts?.metadata_json_path);

    const assetPaths = React.useMemo(() => getDesignModelPaths(design), [design]);
    const payloads = useDesignModelPayloadsInner(assetPaths, design, {
        location: 'Finishing in Browser',
    });
    const designAccelerationData = useDesignAccelerationdata(
        design?.conversion_artifacts?.design_acceleration_data_path ?? undefined,
    );

    const automateValidationDone: boolean = design.validation_data?.automate_validation_done ?? false;
    const automateValidationErrors: LabsGqlAutomateValidationError[] =
        design.validation_data?.automate_validation_errors ?? [];

    const automateValidation = { automateValidationDone, automateValidationErrors };
    return {
        modellingTreePayload,
        payloads,
        designMetadata,
        designAccelerationData,
        automateValidation,
    };
}

/**
 * Gets the paths of the design models (restoratives, jaw scans) that should be downloaded.
 */
function getDesignModelPaths(design: VeneerDesignFinishingDesign_FragmentFragment): FirebasePreviewFileMultiWithType[] {
    const assets = design.assets ?? [];

    const cadPaths = getCadModelPaths(assets);
    const upperJawPath = getJawScanModelPath(assets, 'Upper', false);
    const lowerJawPath = getJawScanModelPath(assets, 'Lower', false);
    const prePrepUpperJawPath = getJawScanModelPath(assets, 'Upper', true);
    const prePrepLowerJawPath = getJawScanModelPath(assets, 'Lower', true);
    const curtainsPaths = getCurtainsPaths(assets);

    return compact([
        ...cadPaths,
        upperJawPath,
        lowerJawPath,
        prePrepUpperJawPath,
        prePrepLowerJawPath,
        ...curtainsPaths,
    ]);
}

type DesignAssetsArray = NonNullable<VeneerDesignFinishingDesign_FragmentFragment['assets']>;

/**
 * Gets the paths of the CAD models that should be downloaded. We require PLY files in order the preserve the face
 * order.
 */
function getCadModelPaths(assets: DesignAssetsArray): FirebasePreviewFileMultiWithType[] {
    return assets
        .filter(el => {
            return el.mesh_type === LabsGqlOrderDesignScanType.Cad && el.file_type === 'PLY';
        })
        .map(el => ({
            source: el.file_path,
            name: el.file_path,
            type: el.mesh_type,
        }));
}

function getCurtainsPaths(assets: DesignAssetsArray): FirebasePreviewFileMultiWithType[] {
    return assets
        .filter(el => el.mesh_type === LabsGqlOrderDesignScanType.QcExtras && el.file_path.includes('QCBlockout'))
        .map(el => ({
            source: el.file_path,
            name: el.file_path,
            type: el.mesh_type,
        }));
}

/**
 * Gets the path of the jaw scan mesh that should be downloaded for the specified jaw.
 */
function getJawScanModelPath(
    assets: DesignAssetsArray,
    variant: 'Upper' | 'Lower',
    prePrep: boolean,
): FirebasePreviewFileMultiWithType | undefined {
    const scanAssets = assets.filter(el => {
        return el.mesh_type === LabsGqlOrderDesignScanType.Scans && el.file_path.includes(`Scans/${variant}`);
    });

    // Group the scan assets by their source, then choose the desired source.
    const groups = groupBy(scanAssets, f => FileNameUtils.removeExtension(f.file_path));

    const filePaths = Object.keys(groups);
    const preferredBasename = getPreferredScanType(filePaths, prePrep);
    if (!preferredBasename) {
        if (!prePrep) {
            console.warn(`Failed to find scan for ${variant.toLowerCase()} jaw.`);
        }
        return undefined;
    }

    const preferredEntries = groups[preferredBasename];

    // Pick the best mesh format for the scan. We should always have Draco and PLY available.
    // Of these, we prefer Draco as it is compressed.
    const bestEntry =
        preferredEntries?.find(el => el.file_type === 'DRC') ??
        preferredEntries?.find(el => el.file_type === 'PLY') ??
        preferredEntries?.[0];

    return bestEntry
        ? {
              source: bestEntry.file_path,
              name: bestEntry.file_path,
              type: bestEntry.mesh_type,
              image: bestEntry.image_path ?? undefined,
          }
        : undefined;
}

function getFileName(file_path: string): string {
    return path.basename(file_path, path.extname(file_path));
}

/**
 * Maps a `ModelPayloadItem` to the type used by the finishing app.
 */
function createRestorativeModel(
    item: ModelPayloadItem,
    designAccelerationData: DesignAccelerationData,
    curtainsModelsMapByID: Record<string, ModelPayloadItem>,
): DesignFinishingRestorativeModel | undefined {
    const toothNumber = item.unns?.[0];
    if (!(toothNumber && ToothUtils.isToothNumber(toothNumber))) {
        console.warn('Got a restorative item without a valid tooth number');
        return undefined;
    }

    const insertionAxisArray = item.insertionAxis;
    if (!insertionAxisArray) {
        console.warn('Got a restorative item without an insertion axis');
        return undefined;
    }

    // TODO: This is a temporary solution until we have a better way to get the morph points.
    // We should store an easier identifier like the tooth number
    const accelData = designAccelerationData?.find(d => getFileName(d.asset_path) === getFileName(item.path));
    const morphPoints: MorphPointsInternalData | undefined = accelData?.morph_points;
    const intaglioSettings = accelData?.intaglio_settings?.find(({ unn }) => unn === toothNumber);
    const facet_marks = accelData?.facet_marks;
    bakeFacetMarksIntoGeometry(item.model.geometry, facet_marks);

    if (!morphPoints) {
        console.warn('Got a restorative item without morph points', { item, designAccelerationData });
    }

    if (!intaglioSettings) {
        console.warn('Got a restorative item without intaglio settings', { item, designAccelerationData });
    }

    const curtainsModel = curtainsModelsMapByID[item.modelElementID ?? ''];
    return {
        geometry: item.model.geometry,
        toothNumber,
        insertionAxis: new THREE.Vector3().fromArray(insertionAxisArray),
        morphPoints,
        curtainGeometry: curtainsModel?.model.geometry,
        intaglioSettings,
        splines: accelData?.splines ?? [],
    };
}

function isPrePrepItem(pl: ModelPayloadItem): boolean {
    return isPrePrepAssetPath(pl.path);
}

function isUpperJawItem(pl: ModelPayloadItem): boolean {
    return pl.type === LabsGqlOrderDesignScanType.Scans && pl.jaw === Jaw.Upper;
}

function isLowerJawItem(pl: ModelPayloadItem): boolean {
    return pl.type === LabsGqlOrderDesignScanType.Scans && pl.jaw === Jaw.Lower;
}
