import type { MinimalOrder, MinimalDesign, AutomateValidation } from './Types';
import type { LabsGqlFinishingInBrowserPayload } from '@orthly/graphql-schema';
import constate from 'constate';
import { useSnackbar } from 'notistack';
import React from 'react';

interface ReviewDecisionArgs {
    approved: boolean;
    reviewDurationMs: number;
}

interface SubmitArgs {
    payload: LabsGqlFinishingInBrowserPayload;
}

export interface FinishingCallbacks {
    onComplete: () => void;
    onCancel: () => void;
    setWindowOpen: React.Dispatch<React.SetStateAction<boolean>>;
    submitReviewDecision: (args: ReviewDecisionArgs) => Promise<void>;
    submitFinishedDesign: (args: SubmitArgs) => Promise<void>;
}

interface OrderStateProps {
    order: MinimalOrder;
    design: MinimalDesign;
    automateValidation: AutomateValidation;
    initiallyOpen: boolean | undefined;
    callbacks: FinishingCallbacks;
    isTrainingOrder: boolean | undefined;
}

// Returns true if the UI was closed; false otherwise
type CloseWindowFn = (force?: boolean | React.MouseEvent) => boolean;

interface OrderState {
    // The order being worked on
    order: MinimalOrder;

    // The design being worked on
    design: MinimalDesign;

    // Validation data on the Automate design
    automateValidation: AutomateValidation;

    // Whether or not the UI was open on page load
    initiallyOpen: boolean;

    callbacks: {
        // Callback to submit a Dandy Verified Design (DVD) review decision.
        submitReviewDecision: (args: ReviewDecisionArgs) => Promise<void>;

        // Callback to submit the finished design.
        submitFinishedDesign: (args: SubmitArgs) => Promise<void>;

        // Callback to call when the finishing design is complete
        onComplete: () => void;

        // Callback to call when the finishing design is cancelled
        onCancel: () => void;

        // Callback to close the window
        closeWindow: CloseWindowFn;
    };

    // Whether the order is a training order.
    isTrainingOrder: boolean;
}

function useOrderState({
    order,
    design,
    automateValidation,
    initiallyOpen = false,
    callbacks,
    isTrainingOrder = false,
}: OrderStateProps): OrderState {
    const { closeSnackbar } = useSnackbar();

    const { onComplete, onCancel: onCancel_, setWindowOpen, submitReviewDecision, submitFinishedDesign } = callbacks;

    const closeWindow = React.useCallback<CloseWindowFn>(
        force => {
            let forceClose = false;
            if (typeof force === 'boolean') {
                forceClose = force;
            } else if (typeof force === 'object') {
                forceClose = force.shiftKey;
            }

            if (forceClose || confirm('Are you sure you want to exit? Your progress will be lost.')) {
                setWindowOpen(false);
                return true;
            }

            return false;
        },
        [setWindowOpen],
    );

    const onCancel = React.useCallback(() => {
        // We persist error notifications so that the user doesn't miss them if they switched to another tab. Here, we
        // clear them because the user is explicitly cancelling out of the Finishing tool.
        closeSnackbar();

        onCancel_();
    }, [onCancel_, closeSnackbar]);

    return {
        order,
        design,
        automateValidation,
        initiallyOpen,
        callbacks: {
            submitReviewDecision,
            submitFinishedDesign,
            onComplete,
            onCancel,
            closeWindow,
        },
        isTrainingOrder,
    };
}

export const [
    OrderStateProvider,
    useOrder,
    useDesign,
    useAutomateValidation,
    useInitiallyOpen,
    useFinishingCallbacks,
    useIsTrainingOrder,
] = constate(
    useOrderState,
    val => val.order,
    val => val.design,
    val => val.automateValidation,
    val => val.initiallyOpen,
    val => val.callbacks,
    val => val.isTrainingOrder,
);
