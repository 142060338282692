import { QCColorLegend } from '../ColorRamp';
import type { CrossSectionAppState, CrossSectionHooksApp } from '../CrossSection/CrossSection.hooks';
import { CrossSectionViewPanel } from '../CrossSection/CrossSectionPanel';
import { CrossSectionPlaneTool } from '../CrossSection/CrossSectionPlaneTool';
import { ProximalContactsWindow } from '../DesignEditing/ProximalContactsWindow';
import { useProximalPlanes, useProximalViewsCallback } from '../DesignEditing/ProximalView.hooks';
import type { ModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import type { SetValueFn, UpdateTextFnRef } from '../ModelViewer';
import { MouseLabel, UndercutHighlight } from '../ModelViewer';
import { HeatmapHighlight } from '../ModelViewer/HeatmapHighlight';
import type { MainViewCameraControlsRef } from '../ModelViewer/ModelViewerTHREETypes';
import type { MarginLineData } from '../PortalScanEditor';
import type { InsertionAxisState, MarginLinesMap } from './FinishingApp.types';
import type { IOperationsManager } from './OperationsManager.types';
import type { ISceneAppearanceManager, SceneAppearance, SceneAppearanceState } from './SceneAppearanceManager.types';
import { HeatMapType, isMesh } from '@orthly/forceps';
import type { ToothNumber } from '@orthly/items';
import { ToothUtils } from '@orthly/items';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import type { MarginLine } from '@orthly/shared-types';
import { Box } from '@orthly/ui-primitives';
import { compact } from 'lodash';
import React from 'react';
import * as THREE from 'three';

/**
 * Packages all FinishingApp-produced React nodes that must be placed as children of the canvas into a single node.
 */
export function useCanvasChildren(
    cameraControlsRef: MainViewCameraControlsRef,
    crossSectionState: CrossSectionAppState,
    crossSectionApp: CrossSectionHooksApp,
    modelAppearance: ModelAppearance,
    marginLines: MarginLine[],
    setHeatmapHighlight: SetValueFn,
    sceneAppearance: SceneAppearance,
    opManager: IOperationsManager,
    sceneManager: ISceneAppearanceManager,
): React.ReactNode {
    const { activeHeatMap: activeHeatMapType, curtainsHeatMapEnabled } = sceneAppearance;

    const geometryWithConnectivity = opManager.getGeometriesWithConnectivity();

    const editToothNumber = opManager.editToothNumber;
    const getInsertionAxis = React.useCallback(() => opManager.editInsertionAxis.clone(), [opManager]);

    const scans = sceneManager.getScanMeshes();
    const lowerJawMesh = scans?.lowerJaw;
    const upperJawMesh = scans?.upperJaw;

    const undercutRestorativeMesh =
        activeHeatMapType === HeatMapType.Undercut && sceneManager.getRestorativeMeshes().get(editToothNumber);
    const undercutRestorativeMeshVisible = isMesh(undercutRestorativeMesh) && undercutRestorativeMesh.visible;

    const undercutScanMesh =
        sceneAppearance.scanUndercutHeatmapEnabled &&
        (ToothUtils.toothIsUpper(editToothNumber) ? upperJawMesh : lowerJawMesh);
    const undercutScanMeshVisible = isMesh(undercutScanMesh) && undercutScanMesh.visible;

    const undercutHighlightTargetMesh =
        (undercutScanMeshVisible && undercutScanMesh) || (undercutRestorativeMeshVisible && undercutRestorativeMesh);

    const shadowingMeshes = React.useMemo(() => {
        return compact([ToothUtils.toothIsUpper(editToothNumber) ? upperJawMesh : lowerJawMesh]);
    }, [editToothNumber, upperJawMesh, lowerJawMesh]);

    const conditionallyIncludeHeatmapHighlightComponent = () => {
        if (!!activeHeatMapType && activeHeatMapType !== HeatMapType.Undercut) {
            const geometries = Array.from(geometryWithConnectivity.values()).map(model => model.geometry);
            return (
                <HeatmapHighlight
                    key={`heatmap_highlight`}
                    geometries={geometries}
                    cameraControlsRef={cameraControlsRef}
                    activeHeatMap={activeHeatMapType}
                    showCurtainsHeatmap={curtainsHeatMapEnabled}
                    maxRadiusMm={0.1}
                    onHeatmapHighlighted={setHeatmapHighlight}
                />
            );
        }
    };

    return (
        <>
            {/* New tools that need to be a child of the canvas shall go here */}
            {undercutHighlightTargetMesh && (
                <UndercutHighlight
                    targetModel={{ mesh: undercutHighlightTargetMesh }}
                    shadowingMeshes={shadowingMeshes}
                    insertionAxis={getInsertionAxis}
                    onHighlight={setHeatmapHighlight}
                    showOnlyEscapeDistance
                />
            )}
            <CrossSectionPlaneTool
                payload={modelAppearance}
                marginLines={marginLines}
                showMarginLines={modelAppearance.showMarginLines}
                csPlane={crossSectionApp.csPlane}
                setCSPlane={crossSectionApp.setCSPlane}
                minorAxis={crossSectionApp.crossSectionMinorAxis}
                cameraControlsRef={cameraControlsRef}
                newCrossSectionPlaneActive={
                    crossSectionState.appActive.open && !crossSectionApp.crossSectionPlaneActive
                }
                onUpdateCrossSection={crossSectionApp.updateCrossSectionData}
                onNewCrossSectionPlane={crossSectionApp.handleNewCrossSectionPlane}
                clippingPlane={crossSectionState.clippingPlane.open}
                reversePlane={crossSectionState.reversePlane.open}
            />
            {conditionallyIncludeHeatmapHighlightComponent()}
        </>
    );
}

/**
 * Packages all FinishingApp-produced React nodes that must be placed as siblings of the canvas into a single node.
 */
export function useCanvasSiblingsState(
    crossSectionState: CrossSectionAppState,
    crossSectionApp: CrossSectionHooksApp,
    sceneState: SceneAppearanceState,
    updateHeatmapHighlightTextRef: UpdateTextFnRef,
    opManager: IOperationsManager,
    marginLines: MarginLinesMap,
    insertionAxisState: InsertionAxisState,
): React.ReactNode {
    const { appearance, manager } = sceneState;
    const { activeHeatMap, heatmapRange, scanUndercutHeatmapEnabled } = appearance;
    const editMarginToothNumber = opManager.editToothNumber;
    const getInsertionAxis = React.useCallback(() => {
        return opManager.editInsertionOrientation
            .clone()
            .multiply(new THREE.Quaternion().setFromEuler(new THREE.Euler(0, 0, Math.PI)));
    }, [opManager]);
    const scans = sceneState.manager.getScanMeshes();
    const jawMeshes = {
        lower: scans?.lowerJaw,
        lowerPreprep: scans?.prePrepLowerJaw,
        upper: scans?.upperJaw,
        upperPreprep: scans?.prePrepUpperJaw,
    };

    const editMesh = sceneState.manager.getRestorativeMeshes().get(editMarginToothNumber);
    const getMargin = (toothNumber: ToothNumber): MarginLineData | undefined => {
        return {
            controlPoints: marginLines.get(toothNumber) || [],
            isClosed: true,
        };
    };
    const globalOcclusalPlaneAlignment = new THREE.Matrix4().makeRotationFromEuler(new THREE.Euler(Math.PI / 2, 0, 0));

    const toothNumbers = React.useMemo<ArrayMin1<ToothNumber>>(() => [editMarginToothNumber], [editMarginToothNumber]);

    const proximalPlanesState = useProximalPlanes(
        editMesh,
        toothNumbers,
        jawMeshes,
        getInsertionAxis,
        getMargin,
        globalOcclusalPlaneAlignment,
    );
    const getProximalSubviews = useProximalViewsCallback(proximalPlanesState.getProximalPlanes, jawMeshes);

    return (
        <>
            {/* New tools that need to be a sibling of the canvas shall go here */}
            <ProximalContactsWindow
                open={insertionAxisState.showMultiView}
                setOpen={insertionAxisState.setShowMultiView}
                toothNumbers={toothNumbers}
                getProximalSubviews={getProximalSubviews}
                translateMesialFrame={proximalPlanesState.translateMesialFrame}
                translateDistalFrame={proximalPlanesState.translateDistalFrame}
                rotateMesialFrame={proximalPlanesState.rotateMesialFrame}
                rotateDistalFrame={proximalPlanesState.rotateDistalFrame}
                resetDistalFrame={proximalPlanesState.resetDistalFrame}
                resetMesialFrame={proximalPlanesState.resetMesialFrame}
            />
            <CrossSectionViewPanel crossSectionApp={crossSectionApp} crossSectionState={crossSectionState} />
            {(activeHeatMap || scanUndercutHeatmapEnabled) && (
                <Box sx={{ position: 'absolute', top: 8, right: 8, zIndex: 21 }}>
                    <QCColorLegend
                        heatMapType={activeHeatMap ?? HeatMapType.Undercut}
                        dynamicHeatmaps={true}
                        heatMapRange={heatmapRange}
                        setHeatMapRange={range => manager.setHeatmapRange(range)}
                    />
                </Box>
            )}
            <MouseLabel forceUpdateRef={updateHeatmapHighlightTextRef} offsetMouse />
        </>
    );
}
