import { ChangeDoctorAction } from '../actions/ChangeDoctorAction.graphql';
import type {
    AdminOrderDetailSummaryDoctorLabSalesOrder_FragmentFragment,
    FragmentType,
} from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { OrderFormatUtils } from '@orthly/shared-types';
import { Collapse, Grid, Icon, IconButton } from '@orthly/ui-primitives';
import { NameForNotificationChannel, NameForWhoReceivesNotification, OrderDetailFactRow } from '@orthly/veneer';
import React from 'react';

const AdminOrderDetailSummaryDoctorLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailSummaryDoctorLabSalesOrder_Fragment on LabSalesOrderDTO {
        doctor_preferences {
            name
            contact_email
            contact_phone_call_number
            contact_phone
            best_call_hour
            timezone
            who_receives_dr_notifications
            grouped_notification_preferences {
                review_required_email
                status_change_email
                review_required_sms
                status_change_sms
            }
        }
        ...AdminChangeDoctorActionLabSalesOrder_Fragment
    }
`);

const getNotifyForIssuesValue = (
    notifyPref: AdminOrderDetailSummaryDoctorLabSalesOrder_FragmentFragment['doctor_preferences']['grouped_notification_preferences'],
): string => {
    const hasEmail = notifyPref.review_required_email || notifyPref.status_change_email;
    const hasSms = notifyPref.review_required_sms || notifyPref.status_change_sms;
    if (hasEmail && hasSms) {
        return `${NameForNotificationChannel.email} & ${NameForNotificationChannel.sms}`;
    }
    if (hasEmail) {
        return NameForNotificationChannel.email;
    }
    if (hasSms) {
        return NameForNotificationChannel.sms;
    }
    return 'Not set';
};

interface OrderDetailSummaryDoctorProps {
    salesOrder: FragmentType<typeof AdminOrderDetailSummaryDoctorLabSalesOrder_Fragment>;
    refetchSalesOrder: () => void;
}

export const OrderDetailSummaryDoctor: React.VFC<OrderDetailSummaryDoctorProps> = ({
    salesOrder: salesOrderProp,
    refetchSalesOrder,
}) => {
    const salesOrder = getFragmentData(AdminOrderDetailSummaryDoctorLabSalesOrder_Fragment, salesOrderProp);
    const { doctor_preferences } = salesOrder;

    const [displayContactInfo, setDisplayContactInfo] = React.useState<boolean>(false);

    const contactInfo = OrderFormatUtils.bestContactInfo({
        ...doctor_preferences,
        doctor_name: doctor_preferences.name,
    });

    return (
        <>
            <OrderDetailFactRow
                title={'Doctor'}
                value={contactInfo.doctor}
                action={
                    <Grid container style={{ width: 'auto', flexWrap: 'nowrap' }}>
                        <ChangeDoctorAction salesOrder={salesOrder} refetchSalesOrder={refetchSalesOrder} />
                        <IconButton onClick={() => setDisplayContactInfo(!displayContactInfo)} style={{ padding: 0 }}>
                            <Icon
                                icon={'ChevronLeft'}
                                style={{
                                    transition: 'all 0.2s linear',
                                    transform: displayContactInfo ? 'rotate(-90deg)' : `rotate(0)`,
                                }}
                            />
                        </IconButton>
                    </Grid>
                }
            />
            <Collapse in={displayContactInfo}>
                <Grid container style={{ padding: '8px 0px 8px 8px' }}>
                    <OrderDetailFactRow flexBasisLeft={100} title={'Email'} value={contactInfo.email} />
                    <OrderDetailFactRow flexBasisLeft={100} title={'Call #'} value={contactInfo.call_number} />
                    <OrderDetailFactRow flexBasisLeft={100} title={'Sms #'} value={contactInfo.sms_number} />
                    <OrderDetailFactRow
                        flexBasisLeft={100}
                        title={'Best Call Hour'}
                        value={contactInfo.best_call_hour}
                    />
                    <OrderDetailFactRow
                        flexBasisLeft={100}
                        title={'Best Contact'}
                        value={
                            doctor_preferences.who_receives_dr_notifications
                                ? NameForWhoReceivesNotification[doctor_preferences.who_receives_dr_notifications]
                                : 'Not set'
                        }
                    />
                    <OrderDetailFactRow
                        flexBasisLeft={100}
                        title={'Notify for Issues'}
                        value={getNotifyForIssuesValue(doctor_preferences.grouped_notification_preferences)}
                    />
                </Grid>
            </Collapse>
        </>
    );
};
