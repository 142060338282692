import { ArchiveAutomationSwitch } from '../../components/ArchiveAutomationSwitch';
import { useAutomationBuilderAction } from '../../state/AutomationBuilder.actions';
import { useAutomationBuilderSelector } from '../../state/AutomationBuilder.context';
import { useCompletedAutomationFilter } from '../../state/AutomationBuilder.selectors';
import { savedAutomationToFormState } from '../../state/AutomationBuilder.state';
import type { ActionConfigValue } from '../../state/AutomationBuilder.types';
import { AutomationsBuilderDeleteButton } from './AutomationBuilderDeleteButton';
import { useCreateOrderAutomationMutation, useUpdateOrderAutomationMutation } from '@orthly/graphql-react';
import type {
    LabsGqlCreateOrderAutomationCommand,
    LabsGqlAutomationActionConfigInput,
    LabsGqlAutomationFieldSubmissionInput,
} from '@orthly/graphql-schema';
import { useChangeSubmissionFn, LoadBlocker } from '@orthly/ui';
import { Button, Grid, Tooltip } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

function getCompletedAutomationActions(actions: ActionConfigValue[]): LabsGqlAutomationActionConfigInput[] {
    const mappedActions = actions.map<LabsGqlAutomationActionConfigInput | undefined>(actionConfig => {
        if (actionConfig.fields.filter(f => !!f.error).length > 0) {
            return undefined;
        }
        const completeFields = _.compact(
            actionConfig.fields.map<LabsGqlAutomationFieldSubmissionInput | undefined>(f =>
                f.value === undefined || f.value === null ? undefined : { value: f.value, field_id: f.id },
            ),
        );
        return { action_id: actionConfig.id, fields: completeFields };
    });
    return _.compact(mappedActions);
}

type AutomationBuilderSaveState =
    | {
          creationCommand: LabsGqlCreateOrderAutomationCommand;
          errorMessage?: undefined;
      }
    | {
          creationCommand?: undefined;
          errorMessage: string;
      };

function useAutomationBuilderSaveState(): AutomationBuilderSaveState {
    const form = useAutomationBuilderSelector(s => s.form);
    const filter = useCompletedAutomationFilter();
    return React.useMemo<AutomationBuilderSaveState>(() => {
        if (!form.name || !form.description) {
            return { errorMessage: 'Please add name and description' };
        }
        if (!form.triggered_by || form.triggered_by.length === 0) {
            return { errorMessage: 'Please select at least one event trigger' };
        }
        if (!filter) {
            return { errorMessage: 'Please configure filter' };
        }
        if (form.actions.length === 0) {
            return { errorMessage: 'Please add at least one action' };
        }
        const completedActions = getCompletedAutomationActions(form.actions);
        if (completedActions.length !== form.actions.length) {
            const completedActionsById = _.keyBy(completedActions, a => a.action_id);
            const incompleteActions = form.actions.filter(a => !completedActionsById[a.id]).map(a => a.name);
            return { errorMessage: `Please complete configuration for actions: ${incompleteActions.join(', ')}` };
        }
        return {
            creationCommand: {
                filter,
                name: form.name,
                triggered_by: form.triggered_by,
                description: form.description,
                actions: completedActions,
            },
        };
    }, [form.name, form.triggered_by, form.description, form.actions, filter]);
}

function useSaveAutomation(refetch: () => Promise<unknown>) {
    const onSaved = useAutomationBuilderAction('AUTOMATION_SAVED');
    const existing = useAutomationBuilderSelector(s => s.saved);
    const [submitCreation] = useCreateOrderAutomationMutation();
    const [submitUpdate] = useUpdateOrderAutomationMutation();
    const { creationCommand, errorMessage } = useAutomationBuilderSaveState();
    const mtnSubmitter = React.useCallback(async () => {
        if (!creationCommand) {
            return undefined;
        }

        if (existing) {
            return await submitUpdate({
                variables: { data: { ...creationCommand, id: existing.id } },
            });
        }

        return await submitCreation({ variables: { data: creationCommand } });
    }, [creationCommand, existing, submitCreation, submitUpdate]);
    const { submitting, submit } = useChangeSubmissionFn(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => [`Automation ${existing ? 'updated' : 'created'}`, {}],
        onSuccess: async result => {
            const data = result?.data?.saveAutomation;
            if (data) {
                onSaved(data);
                void refetch();
            }
        },
    });
    return { submitting, submit, creationCommand, errorMessage };
}

const ArchiveSwitch: React.FC = () => {
    const saved = useAutomationBuilderSelector(s => s.saved);
    const updateSaved = useAutomationBuilderAction('UPDATE_SAVED_AUTOMATION');
    if (!saved) {
        return null;
    }
    return (
        <Grid container style={{ paddingRight: 8, width: 'auto' }}>
            <ArchiveAutomationSwitch
                automationId={saved.id}
                archived={saved.archived}
                onComplete={archived => updateSaved({ archived })}
            />
        </Grid>
    );
};

const ResetFormButton: React.FC = () => {
    const canReset = useAutomationBuilderSelector(
        s => !!s.saved && !_.isEqual(s.form, savedAutomationToFormState(s.saved)),
    );
    const resetToSaved = useAutomationBuilderAction('RESET_FORM_TO_SAVED');
    if (!canReset) {
        return null;
    }
    return (
        <Grid container style={{ paddingRight: 8, width: 'auto' }} alignItems={'center'}>
            <Button fullWidth style={{ whiteSpace: 'nowrap' }} onClick={() => resetToSaved()} variant={'text'}>
                Reset changes
            </Button>
        </Grid>
    );
};

interface AutomationBuilderSaveButtonProps {
    automationId?: string;
    refetch: () => Promise<unknown>;
}

export const AutomationBuilderSaveButton: React.VFC<AutomationBuilderSaveButtonProps> = ({ automationId, refetch }) => {
    const { form, saved } = useAutomationBuilderSelector(s => s);
    const { submit, submitting, creationCommand, errorMessage } = useSaveAutomation(refetch);
    const saveDisabled = React.useMemo(() => {
        return !creationCommand || (!!saved && _.isEqual(form, savedAutomationToFormState(saved)));
    }, [creationCommand, form, saved]);
    return (
        <Grid container wrap={'nowrap'} alignItems={'center'} justifyContent={'flex-end'}>
            <ResetFormButton />
            <ArchiveSwitch />
            <LoadBlocker blocking={submitting} ContainerProps={{ style: { width: 'auto' } }}>
                <Tooltip title={errorMessage ?? ''} disableHoverListener={!errorMessage}>
                    <Grid container style={{ width: 'auto' }}>
                        <Button
                            style={{ borderRadius: '6px !important' }}
                            onClick={() => submit()}
                            disabled={saveDisabled}
                            variant={'contained'}
                        >
                            {saved ? 'Save' : 'Create'}
                        </Button>
                    </Grid>
                </Tooltip>
            </LoadBlocker>
            {automationId && <AutomationsBuilderDeleteButton automationId={automationId} refetch={refetch} />}
        </Grid>
    );
};
