import { AnalyticsClient } from '../../../../../../../../utils/analyticsClient';
import { AskDoctorOptionListManager } from './AskDoctorOptionListManager';
import { useAskDoctorPracticePreviewDialog } from './AskDoctorPracticePreviewDialog';
import { AskDoctorV2DialogAttachments } from './AskDoctorV2DialogAttachments';
import type { AskDoctorErrorState } from './utils';
import { validateData } from './utils';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import type { LabsGqlDoctorRequestInitiatingFlow } from '@orthly/graphql-schema';
import { apolloErrorMessage } from '@orthly/ui';
import { FlossPalette, FormHelperText, Grid, TextField, Button, Text, stylesFactory } from '@orthly/ui-primitives';
import type { AskDoctorV2ViewableOption } from '@orthly/veneer';
import { useSnackbar } from 'notistack';
import React from 'react';

const useStyles = stylesFactory(() => ({
    spacer: {
        height: `1px`,
        backgroundColor: FlossPalette.DARK_TAN,
    },
    secondButtonGroup: {
        width: 'auto',
    },
    errorWrapper: {
        paddingLeft: `2px`,
    },
}));

const CreateDoctorRequestMutation = graphql(`
    mutation CreateDoctorRequest($data: CreateDoctorRequestCommand!) {
        createDoctorRequest(data: $data) {
            id
        }
    }
`);

export type AskDoctorV2DialogContentProps = {
    order: LabsGqlLabOrderFragment;
    refetchOrder: () => Promise<any>;
    closeDialog: () => void;
    initiatingFlow: LabsGqlDoctorRequestInitiatingFlow;
    initialNotesForDoctor: string | null;
};

export const AskDoctorV2DialogContent: React.VFC<AskDoctorV2DialogContentProps> = ({
    initialNotesForDoctor,
    initiatingFlow,
    order,
    refetchOrder,
    closeDialog,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [createDoctorRequest, { loading: submitting }] = useMutation(CreateDoctorRequestMutation, {
        onError: error => {
            enqueueSnackbar(apolloErrorMessage(error), { variant: 'error' });
        },
        onCompleted: async () => {
            enqueueSnackbar(`Doctor request created!`, { variant: 'success' });
            await refetchOrder();
            closeDialog();
        },
    });
    const classes = useStyles();
    const orderId = order.id;

    const [questionForDoctor, setQuestionForDoctor] = React.useState(initialNotesForDoctor ?? '');
    const [attachments, setAttachments] = React.useState<string[]>([]);
    const [options, setOptions] = React.useState<AskDoctorV2ViewableOption[]>([]);
    const [isAttachmentLoading, setIsAttachmentLoading] = React.useState(false);
    const [errorState, setErrorState] = React.useState<AskDoctorErrorState | null>(null);
    const isSubmitDisabled = submitting || isAttachmentLoading;
    const [openPreviewDialog, previewDialog] = useAskDoctorPracticePreviewDialog({
        attachmentUrls: attachments,
        options,
        order,
        questionForDoctor,
    });

    React.useEffect(() => {
        // Clear the error message when the data changes, we only want to show the error message on submit.
        setErrorState(null);
    }, [attachments, options, orderId, initiatingFlow, questionForDoctor]);

    return (
        <>
            <Grid item container direction={'column'} spacing={3}>
                <Grid item>
                    <Text variant={'body2'} medium>
                        Question for Doctor
                    </Text>
                    <TextField
                        variant={'standard'}
                        multiline={true}
                        minRows={3}
                        fullWidth
                        label={'Describe the issue'}
                        value={questionForDoctor ?? ''}
                        onChange={event => setQuestionForDoctor(event?.target.value)}
                        required
                        error={!!errorState?.questionError}
                        helperText={errorState?.questionError}
                    />
                </Grid>

                <Grid item>
                    <div className={classes.spacer} />
                </Grid>

                <Grid item container direction={'column'} spacing={1}>
                    <Grid item>
                        <Text variant={'body2'} medium>
                            Options for Proceeding
                        </Text>
                    </Grid>
                    <AskDoctorOptionListManager
                        options={options}
                        setOptions={setOptions}
                        order={order}
                        errorMessages={errorState?.optionErrors}
                    />
                    {errorState?.noOptionsError && (
                        <FormHelperText className={classes.errorWrapper} error={true}>
                            {errorState?.noOptionsError}
                        </FormHelperText>
                    )}
                </Grid>

                <Grid item>
                    <div className={classes.spacer} />
                </Grid>

                <Grid item>
                    <AskDoctorV2DialogAttachments
                        setAttachments={setAttachments}
                        onLoadingStateChange={setIsAttachmentLoading}
                        orderId={orderId}
                    />
                </Grid>

                <Grid container item justifyContent={'space-between'} direction={'row'}>
                    <Grid item>
                        <Button variant={'ghost'} onClick={() => openPreviewDialog(true)} endIcon={'VisibilityIcon'}>
                            Preview
                        </Button>
                    </Grid>
                    <Grid item container justifyContent={'flex-end'} spacing={2} className={classes.secondButtonGroup}>
                        <Grid item>
                            <Button variant={'secondary'} onClick={closeDialog}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={'primary'}
                                onClick={async () => {
                                    const { isDataValid, errorState, validatedData } = validateData({
                                        initiating_flow: initiatingFlow,
                                        order_id: orderId,
                                        photo_attachment_urls: attachments,
                                        question_text: questionForDoctor,
                                        options,
                                    });

                                    setErrorState(errorState);

                                    if (isDataValid && validatedData) {
                                        const response = await createDoctorRequest({
                                            variables: { data: validatedData },
                                        });

                                        AnalyticsClient.track('Ops - Portal - Ask Doctor About Hold (v2)', {
                                            attachmentUrls: validatedData.photo_attachment_urls,
                                            options: validatedData.options.map(o => o.label),
                                            questionText: validatedData.question_text,
                                            doctorRequestId: response.data?.createDoctorRequest.id ?? '',
                                            $groups: { order: orderId },
                                        });
                                    }
                                }}
                                disabled={isSubmitDisabled}
                            >
                                Publish Doctor Hold Survey
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {previewDialog}
        </>
    );
};
