import type { MenuProps, ButtonVariant } from '@orthly/ui-primitives';
import { Menu, MenuItem, Button } from '@orthly/ui-primitives';
import React from 'react';

export type SimpleMenuPropsItem = {
    value?: string;
    label: string;
    onClick?: (setClosed: () => void) => void;
    disabled?: boolean;
};

interface SimpleMenuProps {
    ButtonComponent: React.ComponentType<{ onClick: React.ReactEventHandler<HTMLElement> }>;
    items: SimpleMenuPropsItem[];
    MenuProps?: Partial<Omit<MenuProps, 'anchorEl' | 'open' | 'children'>>;
}

export const SimpleMenu: React.FC<SimpleMenuProps> = props => {
    const { MenuProps, ButtonComponent, items } = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    return (
        <>
            <ButtonComponent onClick={e => setAnchorEl(e.currentTarget)} />
            <Menu
                {...MenuProps}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={(event, reason) => {
                    MenuProps?.onClose?.(event, reason);
                    setAnchorEl(null);
                }}
            >
                {items.map(i => (
                    <MenuItem
                        key={i.value ?? i.label}
                        disabled={i.disabled}
                        onClick={() => {
                            i.onClick?.(() => setAnchorEl(null));
                        }}
                    >
                        {i.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export interface SimpleDropdownMenuProps {
    buttonVariant: ButtonVariant;
    buttonLabel: React.ReactNode;
    items: (Omit<SimpleMenuPropsItem, 'onClick'> & { onClick: () => void })[];
}
export const SimpleDropdownMenu: React.FC<SimpleDropdownMenuProps> = props => {
    const { buttonVariant, buttonLabel, items } = props;
    return (
        <SimpleMenu
            ButtonComponent={({ onClick }) => (
                <Button variant={buttonVariant} onClick={onClick} endIcon={'DropdownIcon'}>
                    {buttonLabel}
                </Button>
            )}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            }}
            items={items.map(item => ({
                ...item,
                onClick: setClosed => {
                    item.onClick?.();
                    setClosed();
                },
            }))}
        />
    );
};
