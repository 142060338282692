import { SimpleMultiSelect } from '@orthly/ui';
import { FlossPalette } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export type SelectIDsProps = {
    label: string;
    options?: Map<string, { display: string }>;
    selectedIDs?: string[];
    selectIDs: (ids: string[] | undefined) => void;
};

export const SelectIDs: React.FC<SelectIDsProps> = props => {
    const options: { label: string; value: string }[] = Array.from(props.options?.entries() ?? []).map(
        ([id, data]) => ({
            label: data.display,
            value: id,
        }),
    );

    const sortedOptions = _.sortBy(options, ({ label }) => (label ?? '').toLowerCase());

    return (
        <div style={{ borderRight: `1px solid ${FlossPalette.DARK_TAN}`, minWidth: '120px', width: '100%' }}>
            <SimpleMultiSelect
                backgroundColor={'white'}
                label={props.label}
                value={props.selectedIDs ?? []}
                options={sortedOptions}
                onChange={ids => {
                    if (Array.isArray(ids)) {
                        ids.length === 0 ? props.selectIDs(undefined) : props.selectIDs(ids);
                    }
                }}
            />
        </div>
    );
};
