import type { IconName } from '@orthly/ui-primitives';
import { FlossPalette, Text, stylesFactory, Button, Drawer, Grid, CloseIcon } from '@orthly/ui-primitives';
import React from 'react';

// TODO: TEST THE SIDEBAR OVERLAY WITH A STORYBOOK STORY
const useStyles = stylesFactory(() => ({
    alertWell: {
        padding: 16,
    },
}));
export interface SidebarOverlayButtonProps {
    icon?: IconName;
    label: string;
    onClick: () => Promise<void>;
    disabled?: boolean;
}
interface SidebarOverlayProps {
    open: boolean;
    setOpen: (open: boolean) => void;

    width?: number;

    title: React.ReactNode | string;
    children: React.ReactNode;
    bodyStyle?: React.CSSProperties;
    primaryButton?: SidebarOverlayButtonProps;
    secondaryButton?: SidebarOverlayButtonProps;
    buttonsDisabled?: boolean;
    alert?: React.ReactNode;
}

// sidebar width + nav width - 1 pixel for the border to be flush
export const SIDEBAR_OVERLAY_WIDTH = 300 + 72 - 1;

export const SidebarOverlay: React.FC<SidebarOverlayProps> = props => {
    const { setOpen, open, title, primaryButton, secondaryButton, buttonsDisabled, alert } = props;

    const classes = useStyles();

    return (
        <Drawer
            hideBackdrop
            variant={'temporary'}
            style={{ width: 0 }}
            disableScrollLock
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{ elevation: 0 }}
        >
            <Grid
                style={{
                    zIndex: 1200, // 1200 is above the sidebar's z-index, but below the material paper's z-index (1300)
                    background: 'white',
                    width: SIDEBAR_OVERLAY_WIDTH,
                }}
            >
                {/* Common header */}
                <Grid container direction={'row'} style={{ borderBottom: '1px solid #ECEAE6', height: 64 }}>
                    <Grid item xs={11}>
                        <Text variant={'h5'} style={{ padding: '16px 0px 0px 16px' }}>
                            {title}
                        </Text>
                    </Grid>
                    <Grid item xs={1} style={{ margin: 'auto' }}>
                        <CloseIcon
                            fontSize={'small'}
                            style={{ color: FlossPalette.STAR_GRASS, cursor: 'pointer' }}
                            onClick={() => setOpen(false)}
                        />
                    </Grid>
                </Grid>

                {/* Alert well */}
                {alert ? (
                    <Grid container className={classes.alertWell}>
                        {alert}
                    </Grid>
                ) : null}

                {/* Body content */}
                <Grid style={props.bodyStyle ?? { padding: '8px 16px 0px 16px' }}>{props.children}</Grid>

                {/* Navigation Buttons */}
                <Grid container item direction={'row'} style={{ padding: '8px 16px' }} spacing={1}>
                    {secondaryButton && (
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                endIcon={secondaryButton.icon}
                                variant={'contained'}
                                onClick={secondaryButton.onClick}
                                disabled={(buttonsDisabled || secondaryButton.disabled) ?? false}
                            >
                                {secondaryButton.label}
                            </Button>
                        </Grid>
                    )}
                    {primaryButton && (
                        <Grid item xs={6}>
                            <Button
                                fullWidth
                                endIcon={primaryButton.icon}
                                variant={'contained'}
                                onClick={primaryButton.onClick}
                                disabled={(buttonsDisabled || primaryButton.disabled) ?? false}
                            >
                                {primaryButton.label}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Drawer>
    );
};
