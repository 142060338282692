import { formatEvents } from './FormatEvents';
import { FormatStatus } from './FormatStatus';
import type { LabsGqlNotificationLogEntityType } from '@orthly/graphql-schema';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    CircularProgress,
} from '@orthly/ui-primitives';
import React from 'react';

interface Row {
    id: string;
    name: string;
    type: string;
    category?: LabsGqlNotificationLogEntityType | null;
    status: string;
    events: Array<string>;
}

interface NotificationLogsTableContentProps {
    loading: boolean;
    data: Row[] | undefined;
    totalRows: number | undefined;
    rowsPerPage: number;
    page: number;
    setPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
}

export const NotificationLogsTableContent: React.FC<NotificationLogsTableContentProps> = ({
    loading,
    data,
    totalRows,
    rowsPerPage,
    page,
    setPage,
    setRowsPerPage,
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to the first page
    };
    if (loading) {
        return <CircularProgress />;
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Category</TableCell>
                        <TableCell>Events</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map(row => (
                        <TableRow key={row.id}>
                            <TableCell sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}>{row.name}</TableCell>
                            <TableCell>{row.type}</TableCell>
                            <TableCell sx={{ textTransform: 'capitalize' }}>{row.category}</TableCell>
                            <TableCell sx={{ textTransform: 'capitalize' }}>{formatEvents(row.events)}</TableCell>
                            <TableCell>
                                <FormatStatus status={row.status} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <TablePagination
                sx={{
                    display: 'flex',
                    '.MuiTablePagination-toolbar': {
                        width: '100%',
                    },
                }}
                rowsPerPageOptions={[5, 10, 25]}
                count={totalRows ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
};
