import type { AdminState } from '../../../redux/redux.types';
import type {
    VisibilitySavedSearchResult,
    FavoriteSavedSearchResult,
    EditOrderSavedSearchProps,
    CreateOrderSavedSearchProps,
    FavoriteSavedSearchPayload,
    EditSavedSearchPayload,
    LoadSavedSearchPayload,
    MutateSavedSearchPayload,
    SaveViewProps,
} from '../../../redux/utils/SavedViewActionUtils';
import { SavedViewActionUtils } from '../../../redux/utils/SavedViewActionUtils';
import type { OrdersOverviewCustomView, OrdersOverviewScreen } from './OrdersOverview.types';
import { OrdersOverviewDefaultSort } from './OrdersOverviewConstants';
import type {
    LabsGqlEditSavedOrderSearchMutationVariables as EditSavedOrderSearchVariables,
    LabsGqlEditSavedOrderSearchMutation as EditSavedOrderSearchMutation,
    LabsGqlCreateSavedOrderSearchMutation as CreateSavedOrderSearchMutation,
    LabsGqlCreateSavedOrderSearchMutationVariables as CreateSavedOrderSearchVariables,
    LabsGqlSavedSearchFragment,
} from '@orthly/graphql-operations';
import { EditSavedOrderSearchDocument, CreateSavedOrderSearchDocument } from '@orthly/graphql-react';
import type { LabsGqlLabOrderSortKey, LabsGqlFilterCriteriaSubmissionInput } from '@orthly/graphql-schema';
import { LabsGqlCustomSearchType, LabsGqlSavedSearchVisibility } from '@orthly/graphql-schema';
import { createSyncAction, generateUseActionHook, createAsyncAction } from '@orthly/redux-async-actions';
import { RetainerSessionManager } from '@orthly/session-client';

const PREFIX = 'orders';

function getCustomView(rawSearch: LabsGqlSavedSearchFragment): OrdersOverviewCustomView {
    const session = RetainerSessionManager.sessionFromToken(RetainerSessionManager.tokenFromLocalStorage());
    return {
        id: rawSearch.id,
        title: rawSearch.name,
        criteria: rawSearch.search.order_criteria ?? undefined,
        search: rawSearch.search.search ?? undefined,
        visibility: rawSearch.visibility,
        createdByUser: session?.user.id ? session.user.id === rawSearch.created_by : undefined,
        createdByUsername: `${rawSearch.created_by_user.first_name} ${rawSearch.created_by_user.last_name}`,
        sort:
            rawSearch.search.__typename === 'OrderCustomSearch' && rawSearch.search.order_sort
                ? { key: rawSearch.search.order_sort.key ?? undefined, asc: rawSearch.search.order_sort.asc }
                : OrdersOverviewDefaultSort,
        // + 1 for user who created
        favoritedCount: rawSearch.favorited_by.length + 1,
    };
}

async function editSavedSearch(props: EditOrderSavedSearchProps): Promise<SaveViewProps> {
    const { payload, activeSearch, ordersOverviewState, existingSavedSearch } = props;

    const result = await payload.client.mutate<EditSavedOrderSearchMutation, EditSavedOrderSearchVariables>({
        mutation: EditSavedOrderSearchDocument,
        variables: {
            data: {
                search_id: payload.searchId,
                visibility:
                    payload.visibility ??
                    (activeSearch ? ordersOverviewState?.view?.visibility : existingSavedSearch.visibility),
                search: activeSearch
                    ? {
                          type: LabsGqlCustomSearchType.Order,
                          search: ordersOverviewState?.view?.search,
                          order_criteria: SavedViewActionUtils.cleanOrderFilter(ordersOverviewState?.view?.criteria),
                          sort: ordersOverviewState?.view?.sort,
                      }
                    : undefined,
                name: payload.title,
            },
        },
    });
    if (!result.data) {
        console.log(result.errors);
        throw new Error('No data returned from mutation');
    }
    const { id, name: title, visibility } = result.data.editSavedOrderSearch;
    return { id, title, visibility };
}

async function createSavedSearch(props: CreateOrderSavedSearchProps): Promise<SaveViewProps> {
    const { payload, ordersOverviewState } = props;
    const result = await payload.client.mutate<CreateSavedOrderSearchMutation, CreateSavedOrderSearchVariables>({
        mutation: CreateSavedOrderSearchDocument,
        variables: {
            data: {
                visibility: payload.visibility ?? LabsGqlSavedSearchVisibility.Private,
                search: {
                    type: LabsGqlCustomSearchType.Order,
                    search: ordersOverviewState?.view?.search,
                    order_criteria: SavedViewActionUtils.cleanOrderFilter(ordersOverviewState?.view?.criteria),
                    sort: ordersOverviewState?.view?.sort,
                },
                name: payload.title,
            },
        },
    });
    if (!result.data) {
        console.log(result.errors);
        throw new Error('No data returned from mutation');
    }
    const { id, name: title, visibility } = result.data.createSavedOrderSearch;
    return { id, title, visibility };
}

export const OrdersOverviewActions = {
    SET_SCREEN: createSyncAction<OrdersOverviewScreen | string, [OrdersOverviewScreen | string]>(
        `${PREFIX}/SET_SCREEN`,
    ),
    SET_CRITERIA: createSyncAction<Array<LabsGqlFilterCriteriaSubmissionInput>>(`${PREFIX}/SET_CRITERIA`),
    SET_SEARCH: createSyncAction<string | undefined, [string | undefined]>(`${PREFIX}/SET_SEARCH`),
    SEARCH_FOR_PATIENT: createSyncAction<string, [string]>(`${PREFIX}/SEARCH_FOR_PATIENT`),
    SEARCH_ON_BLUR: createSyncAction<undefined, []>(`${PREFIX}/SEARCH_ON_BLUR`),
    CLEAR_CRITERIA: createSyncAction<undefined, []>(`${PREFIX}/CLEAR_CRITERIA`),
    SORT_KEY_CLICKED: createSyncAction<LabsGqlLabOrderSortKey | undefined, [LabsGqlLabOrderSortKey | undefined]>(
        `${PREFIX}/SORT_KEY_CLICKED`,
    ),
    TOGGLE_SORT_DIR: createSyncAction<undefined, []>(`${PREFIX}/TOGGLE_SORT_DIR`),
    SAVE_TIMELINE_WIDTH_PCT: createSyncAction<number, [number]>(`${PREFIX}/SAVE_TIMELINE_WIDTH_PCT`),
    SAVE_VIEW: createAsyncAction<SaveViewProps, AdminState, [EditSavedSearchPayload]>(
        `${PREFIX}/SAVE_VIEW`,
        async (thunkParams, payload) => {
            const ordersOverviewState = thunkParams.getState().ordersOverview;
            const existingSavedSearch = ordersOverviewState.savedViews[payload.searchId];
            if (existingSavedSearch) {
                const activeSearch = ordersOverviewState?.view?.id === payload.searchId;
                return {
                    payload: await editSavedSearch({ payload, activeSearch, existingSavedSearch, ordersOverviewState }),
                };
            }
            return {
                payload: await createSavedSearch({ payload, ordersOverviewState }),
            };
        },
    ),
    SET_EDITING_VIEW_ID: createSyncAction<string | undefined, [string | undefined]>(`${PREFIX}/SET_EDITING_VIEW_ID`),
    DELETE_VIEW: createAsyncAction<string, AdminState, [MutateSavedSearchPayload]>(
        `${PREFIX}/DELETE_VIEW`,
        async (_thunkParams, { client, searchId }) => {
            await SavedViewActionUtils.deleteView(client, searchId);
            return { payload: searchId };
        },
    ),
    TOGGLE_FAVORITE_VIEW: createAsyncAction<FavoriteSavedSearchResult, AdminState, [FavoriteSavedSearchPayload]>(
        `${PREFIX}/TOGGLE_FAVORITE_VIEW`,
        async (_thunkParams, { client, searchId, favorited }) => {
            const savedSearch = await SavedViewActionUtils.toggleFavoritedView(client, favorited, searchId);
            return { payload: { searchId, view: savedSearch ? getCustomView(savedSearch) : undefined } };
        },
    ),
    LOAD_VIEWS: createAsyncAction<OrdersOverviewCustomView[], AdminState, [LoadSavedSearchPayload]>(
        `${PREFIX}/LOAD_VIEWS`,
        async (_thunkParams, { client }) => {
            return {
                payload:
                    (await SavedViewActionUtils.loadViews(client, LabsGqlCustomSearchType.Order)).map(search =>
                        getCustomView(search),
                    ) ?? [],
            };
        },
    ),
    TOGGLE_VIEW_VISIBILITY: createAsyncAction<VisibilitySavedSearchResult, AdminState, [MutateSavedSearchPayload]>(
        `${PREFIX}/TOGGLE_VIEW_VISIBILITY`,
        async (thunkParams, { client, searchId }) => {
            const editedView = thunkParams.getState().ordersOverview.savedViews[searchId];

            const newVisibility = await SavedViewActionUtils.toggleViewVisibility(client, searchId, editedView);
            return { payload: { searchId, newVisibility } };
        },
    ),
    COPY_VIEW: createSyncAction<OrdersOverviewCustomView, [OrdersOverviewCustomView]>(`${PREFIX}/COPY_VIEW`),
    SAVE_ACTIVE_VIEW_AS_NEW: createSyncAction<undefined, []>(`${PREFIX}/SAVE_ACTIVE_VIEW_AS_NEW`),
};

export const useOrdersOverviewAction = generateUseActionHook<typeof OrdersOverviewActions>(OrdersOverviewActions);
