import type { FirebasePreviewFileMulti } from '../../hooks/useFirebaseDownload';
import type { AnalyticsEventWithName } from '@orthly/analytics/dist/browser';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { LoadBlocker } from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    Button,
    IconButton,
    RotateLeftIcon,
    RotateRightIcon,
    SaveAltIcon,
} from '@orthly/ui-primitives';
import clsx from 'clsx';
import path from 'path-browserify';
import React from 'react';
import ReactDOM from 'react-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export type DandyLightboxAnalyticsHandler = (photoSource: string, photoName: string) => AnalyticsEventWithName;

interface DandyLightboxProps {
    setSelectedAttachmentPreview: (attachment: string | undefined) => void;
    previews?: FirebasePreviewFileMulti[];
    loading?: boolean;
    selectedAttachmentPreview?: string;
    disablePhotoDownload?: boolean;
    // If provided, will fire the payload as an analytics event whenever a photo is viewed in the lightbox.
    // NOTE: this only works if the client has instantiated BrowserAnalytics already.
    onPhotoViewedAnalytics?: DandyLightboxAnalyticsHandler;
}

const useStyles = stylesFactory(() => ({
    lightbox: {
        '& .ril__navButtonPrev, .ril__navButtonNext': {
            backgroundColor: FlossPalette.STAR_GRASS,
            borderRadius: 2,
            marginRight: 8,
            marginLeft: 8,
        },
    },

    rotated90: {
        '& .ril-image-current': {
            rotate: '90deg',
        },
    },

    rotated180: {
        '& .ril-image-current': {
            rotate: '180deg',
        },
    },

    rotated270: {
        '& .ril-image-current': {
            rotate: '270deg',
        },
    },
    rotationButton: {
        color: FlossPalette.WHITE,
    },
}));

export function isImagePath(url: string) {
    return ['.png', '.gif', '.jpg', '.jpeg', '.svg'].includes(path.extname(url).toLowerCase());
}

type RotationAngle = 0 | 90 | 180 | 270;
const SortedRotationAngles: RotationAngle[] = [0, 90, 180, 270];

export const DandyLightbox: React.VFC<DandyLightboxProps> = props => {
    const classes = useStyles();
    const {
        previews,
        selectedAttachmentPreview,
        setSelectedAttachmentPreview,
        loading,
        disablePhotoDownload,
        onPhotoViewedAnalytics,
    } = props;

    const [rotationAngle, setRotationAngle] = React.useState<RotationAngle>(0);

    // -1 when there are no loaded images (yet), or when the selected image isn't in the list.
    // Otherwise, set to the current index of the selected image within the previews list.
    const selectedIndex = React.useMemo(() => {
        if (!previews?.length || !selectedAttachmentPreview) {
            return -1;
        }

        return previews.findIndex(img => img.name === selectedAttachmentPreview);
    }, [previews, selectedAttachmentPreview]);

    React.useEffect(() => {
        const selectedPhoto = previews?.[selectedIndex];

        // Either we've exited the lightbox, or the caller didn't want analytics
        if (!onPhotoViewedAnalytics || !selectedPhoto) {
            return;
        }

        const result = onPhotoViewedAnalytics(selectedPhoto.source, selectedPhoto.name);
        BrowserAnalyticsClientFactory.Instance?.track(result.name, result.data);
    }, [previews, selectedIndex, onPhotoViewedAnalytics]);

    if (!selectedAttachmentPreview || !previews) {
        return null;
    }

    return ReactDOM.createPortal(
        <LoadBlocker blocking={loading || selectedIndex === -1}>
            <Lightbox
                mainSrc={previews[selectedIndex]?.source ?? ''}
                nextSrc={previews[selectedIndex + 1]?.source}
                prevSrc={previews[selectedIndex - 1]?.source}
                onCloseRequest={() => setSelectedAttachmentPreview(undefined)}
                onMovePrevRequest={() => {
                    setSelectedAttachmentPreview(previews[selectedIndex - 1]?.name);
                    setRotationAngle(0);
                }}
                onMoveNextRequest={() => {
                    setSelectedAttachmentPreview(previews[selectedIndex + 1]?.name);
                    setRotationAngle(0);
                }}
                reactModalStyle={{ overlay: { zIndex: 10000 } }}
                wrapperClassName={clsx(
                    classes.lightbox,
                    rotationAngle === 90 ? classes.rotated90 : undefined,
                    rotationAngle === 180 ? classes.rotated180 : undefined,
                    rotationAngle === 270 ? classes.rotated270 : undefined,
                )}
                toolbarButtons={[
                    <IconButton
                        key={'rotate_right'}
                        onClick={() => {
                            setRotationAngle(currentAngle => {
                                const currentIdx = SortedRotationAngles.indexOf(currentAngle);
                                const nextIdxCandidate = currentIdx + 1;
                                return SortedRotationAngles[nextIdxCandidate] ?? 0;
                            });
                        }}
                        className={classes.rotationButton}
                    >
                        <RotateRightIcon />
                    </IconButton>,
                    <IconButton
                        key={'rotate_left'}
                        onClick={() => {
                            setRotationAngle(currentAngle => {
                                const currentIdx = SortedRotationAngles.indexOf(currentAngle);
                                const nextIdxCandidate = currentIdx - 1;
                                return SortedRotationAngles[nextIdxCandidate] ?? 270;
                            });
                        }}
                        className={classes.rotationButton}
                    >
                        <RotateLeftIcon />
                    </IconButton>,
                ]}
                imageLoadErrorMessage={
                    !disablePhotoDownload && (
                        <Button
                            variant={'primary'}
                            size={'large'}
                            onClick={() => window.open(previews[selectedIndex]?.source)}
                        >
                            <SaveAltIcon style={{ marginRight: 8 }} />
                            Download File
                        </Button>
                    )
                }
            />
        </LoadBlocker>,
        document.body,
    );
};
