import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction, useGuidedWaxupSelector, type PresetInfo } from '../state/GuidedWaxupState';
import { LabsGqlGuidedWaxupPresetStatus } from '@orthly/graphql-schema';
import React from 'react';

export const useUndo = (preset?: PresetInfo) => {
    const { selectedDesignRevisionId } = useGuidedWaxupContext();
    const overrideState = useGuidedWaxupAction('OVERRIDE_STATE');
    const presets = useGuidedWaxupSelector(s => s.presets);

    const snapshot = React.useRef<typeof presets | undefined>();
    const setSnapshot = React.useCallback(() => {
        snapshot.current = presets;
    }, [presets]);

    const resetToSnapshot = React.useCallback(() => {
        if (snapshot.current) {
            overrideState({ designRevisionId: selectedDesignRevisionId, presets: snapshot.current });
        }
    }, [overrideState, snapshot, selectedDesignRevisionId]);

    // Sets the initial snapshot whenever presets have been defined
    React.useEffect(() => {
        if (!snapshot.current && Object.keys(presets).length) {
            setSnapshot();
        }
    }, [presets]); // eslint-disable-line react-hooks/exhaustive-deps

    // Sets the snapshot whenever the optional preset status changes to something other than Rejected
    React.useEffect(() => {
        if (preset?.status && preset.status !== LabsGqlGuidedWaxupPresetStatus.Rejected) {
            setSnapshot();
        }
    }, [preset?.status]); // eslint-disable-line react-hooks/exhaustive-deps

    return { setSnapshot, resetToSnapshot };
};
