import { useRefabPrintReturnLabel } from '../../../hooks';
import { PracticeScreen } from '../../PracticeScreen';
import { RefabBodyContainer } from '../components/RefabBodyContainer';
import { RefabConfirmationScreen } from '../components/RefabConfirmationScreen';
import { useRefabFlowAction, useRefabFlowSelector } from '../state/refab-flow-state';
import { INITIAL_REFAB_FLOW_STATE } from '../state/refab-flow-types';
import { getItemsToReturn } from '../utils';
import { useRefabFlowContext } from './refab-flow-context';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { NavigationLogoDandy } from '@orthly/ui';
import { FlossPalette, Text, stylesFactory, useScreenIsMobileOrVerticalTablet, Grid } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = stylesFactory(theme => ({
    mainContainer: {
        justifyContent: 'flex-start',
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center',
            flexDirection: `column`,
            alignItems: `center`,
        },
    },
    titleContainer: {
        alignItems: 'flex-start',
        marginLeft: 24,
        [theme.breakpoints.down('lg')]: {
            alignItems: 'center',
            marginLeft: 0,
            marginTop: 16,
        },
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        height: 96,
        width: 96,
        background: FlossPalette.PRIMARY_BACKGROUND,
        border: '1px solid rgba(0,0,0,0.1)',
        borderRadius: 16,
    },
}));

export const ConfirmationScreen: React.VFC = () => {
    const { order, openChairsidePdf, isOps, refabricatedOrder } = useRefabFlowContext();
    const classes = useStyles();
    const itemwiseReasonCodes = useRefabFlowSelector(s => s.itemwiseReasonCodes);
    const patchState = useRefabFlowAction('PATCH_STATE');
    const history = useHistory();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const labelUrl = useRefabFlowSelector(s => s.returnLabelUrl);
    const itemsToReturn = getItemsToReturn(order, itemwiseReasonCodes);
    const requiresReturn = itemsToReturn.length > 0;
    const printLabel = useRefabPrintReturnLabel(
        labelUrl,
        openChairsidePdf ? url => openChairsidePdf({ url }) : undefined,
    );

    return (
        <RefabBodyContainer
            title={
                <Grid container className={classes.mainContainer}>
                    <div className={classes.logoContainer}>
                        <NavigationLogoDandy style={{ width: 70 }} />
                    </div>
                    <Grid item>
                        <Grid container direction={'column'} className={classes.titleContainer}>
                            <Grid item>
                                <Text
                                    variant={isMobile ? 'body1' : 'h4'}
                                    medium={!isMobile}
                                    color={'GRAY'}
                                    style={{ opacity: 0.5 }}
                                >
                                    We're on it
                                </Text>
                            </Grid>
                            <Grid item style={{ textAlign: 'center' }}>
                                <Text variant={isMobile ? 'h5' : 'h3'} medium>
                                    {requiresReturn
                                        ? 'Your return request is confirmed'
                                        : 'Your refab order is being processed'}
                                </Text>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            hideOrderDetails
            nextTitle={requiresReturn ? 'Print return slip' : 'Go back home'}
            backTitle={'View refabricated order details'}
            onNext={() => {
                if (requiresReturn) {
                    BrowserAnalyticsClientFactory.Instance?.track('Practice - Portal - Return Label Printed', {
                        $groups: {
                            order: order?.id,
                        },
                    });
                    printLabel();
                } else {
                    patchState(INITIAL_REFAB_FLOW_STATE);
                    history.replace('/');
                }
            }}
            onBack={() => {
                if (requiresReturn) {
                    // if order requires return, new order hasn't been created yet
                    // so go to original order page
                    isOps
                        ? history.replace(`/orders/${order?.id}`)
                        : history.replace(`/${PracticeScreen.orders}/${order?.id}`);
                } else {
                    // if no return required, navigate to new order created
                    // from refab
                    isOps
                        ? history.replace(`/orders/${refabricatedOrder?.id}`)
                        : history.replace(`/${PracticeScreen.orders}/${refabricatedOrder?.id}`);
                }
            }}
        >
            {
                // This screen handles 2 possible flows:
                // - We just processed a refab, in which case `refabricatedOrder` won't be null and we can show confirmation screen
                // - We just processed a return, in which case `refabricatedOrder` will remain null, but `labelUrl` will be defined.
                // When both these fields are null, we assume this is just loading and display nothing.
                refabricatedOrder || labelUrl ? (
                    <RefabConfirmationScreen
                        requiresReturn={requiresReturn}
                        estimatedDate={refabricatedOrder?.practice_dates.estimated_delivery_date ?? ''}
                    />
                ) : null
            }
        </RefabBodyContainer>
    );
};
