import type { ItemInfo, Rule, Decision } from '../orderEditMode';
import { OrderEditMode } from '@orthly/shared-types';

/**
 * editedPreference checks for the edited item pref matching the
 * specified string.  If it finds a match it returns EditOrder as
 * the most permissible possible value, otherwise it returns null to
 * skip
 */
export function editedPreferenceIncludes(substring: string): Rule {
    return function (item: ItemInfo): Decision {
        const debugInfo = {
            rule: 'editedPreferenceIncludes',
            expected: substring,
            received: item.editedPref,
        };

        if (item.editedPref && item.editedPref.includes(substring)) {
            return {
                editMode: OrderEditMode.OrderEdit,
                debugInfo,
            };
        }

        return {
            editMode: null,
            debugInfo,
        };
    };
}
