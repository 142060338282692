import { ChangeDeliveryAddressAction } from '../actions/ChangeDeliveryAddressAction.graphql';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { OrderDetailFactRow } from '@orthly/veneer';

const AdminOrderDetailSummaryDeliveryAddressLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailSummaryDeliveryAddressLabSalesOrder_Fragment on LabSalesOrderDTO {
        delivery_address {
            street_one
        }
        ...AdminChangDeliveryAddressActionLabSalesOrder_Fragment
    }
`);

interface OrderDetailSummaryDeliveryAddressProps {
    salesOrder: FragmentType<typeof AdminOrderDetailSummaryDeliveryAddressLabSalesOrder_Fragment>;
    refetchSalesOrder: () => void;
}

export const OrderDetailSummaryDeliveryAddress: React.VFC<OrderDetailSummaryDeliveryAddressProps> = ({
    salesOrder: salesOrderProp,
    refetchSalesOrder,
}) => {
    const salesOrder = getFragmentData(AdminOrderDetailSummaryDeliveryAddressLabSalesOrder_Fragment, salesOrderProp);

    return (
        <OrderDetailFactRow
            title={'Address'}
            value={salesOrder.delivery_address.street_one}
            action={<ChangeDeliveryAddressAction salesOrder={salesOrder} refetchSalesOrder={refetchSalesOrder} />}
        />
    );
};
