import { usePartnerAndLabNames } from '../../../../../../utils/usePartnerAndLabNames';
import { OrderDetailSummaryBlock } from './OrderDetailSummaryBlock.graphql';
import { OrderDetailSummaryDeliveryAddress } from './OrderDetailSummaryDeliveryAddress.graphql';
import { OrderDetailSummaryDesignCapabilityRequirements } from './OrderDetailSummaryDesignCapabilityRequirements';
import { OrderDetailSummaryDesignInfo } from './OrderDetailSummaryDesignInfo';
import { OrderDetailSummaryDoctor } from './OrderDetailSummaryDoctor.graphql';
import { OrderDetailSummaryFlags } from './OrderDetailSummaryFlags';
import { OrderDetailSummaryManufacturer } from './OrderDetailSummaryManufacturer';
import { OrderDetailSummaryPractice } from './OrderDetailSummaryPractice.graphql';
import { OrderDetailSummaryScanSource } from './OrderDetailSummaryScanSource.graphql';
import { OrderDetailSummaryShippingSpeed } from './OrderDetailSummaryShippingSpeed';
import { OrderDetailSummaryTags } from './OrderDetailSummaryTags';
import { OrderDetailSummaryTitle } from './OrderDetailSummaryTitle.graphql';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlActiveInternalDesignTaskFragment, LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { FlossPalette, styled } from '@orthly/ui-primitives';
import React from 'react';

const AdminOrderDetailSummaryLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailSummaryLabSalesOrder_Fragment on LabSalesOrderDTO {
        id
        ...AdminOrderDetailSummaryBlockLabSalesOrder_Fragment
        ...AdminOrderDetailSummaryTitleLabSalesOrder_Fragment
        ...AdminOrderDetailSummaryDoctorLabSalesOrder_Fragment
        ...AdminOrderDetailSummaryPracticeLabSalesOrder_Fragment
        ...AdminOrderDetailSummaryDeliveryAddressLabSalesOrder_Fragment
        ...AdminOrderDetailSummaryScanSourceLabSalesOrder_Fragment
    }
`);

const OrderDetailSummarySeparator = styled('div')({
    width: '100%',
    height: '1px',
    backgroundColor: FlossPalette.DARK_TAN,
    margin: '10px 0',
});

interface OrderDetailSummaryProps {
    salesOrder: FragmentType<typeof AdminOrderDetailSummaryLabSalesOrder_Fragment>;
    labOrder: LabsGqlLabOrderFragment;
    activeInternalDesignTask: LabsGqlActiveInternalDesignTaskFragment | null;
    refetch: () => Promise<any>;
}

export const OrderDetailSummary: React.VFC<OrderDetailSummaryProps> = ({
    salesOrder: salesOrderProp,
    labOrder,
    activeInternalDesignTask,
    refetch,
}) => {
    const salesOrder = getFragmentData(AdminOrderDetailSummaryLabSalesOrder_Fragment, salesOrderProp);

    const { labNamesById } = usePartnerAndLabNames();
    const manufacturerName = labNamesById[labOrder.manufacturer_id] ?? 'Unknown lab';

    return (
        <OrderDetailSummaryBlock salesOrder={salesOrder}>
            <OrderDetailSummaryTitle salesOrder={salesOrder} />
            <OrderDetailSummaryFlags labOrder={labOrder} />
            <OrderDetailSummarySeparator />
            <OrderDetailSummaryDoctor salesOrder={salesOrder} refetchSalesOrder={refetch} />
            <OrderDetailSummaryPractice salesOrder={salesOrder} />
            <OrderDetailSummaryDeliveryAddress salesOrder={salesOrder} refetchSalesOrder={refetch} />
            <OrderDetailSummaryManufacturer
                labOrder={labOrder}
                refetchLabOrder={refetch}
                manufacturerName={manufacturerName}
            />
            <OrderDetailSummaryDesignInfo
                labOrder={labOrder}
                refetchLabOrder={refetch}
                manufacturerName={manufacturerName}
            />
            <OrderDetailSummaryDesignCapabilityRequirements activeInternalDesignTask={activeInternalDesignTask} />
            <OrderDetailSummaryShippingSpeed labOrder={labOrder} />
            <OrderDetailSummaryScanSource salesOrder={salesOrder} />
            <OrderDetailSummaryTags salesOrderId={salesOrder.id} />
        </OrderDetailSummaryBlock>
    );
};
