import { DandyMouthArchItems } from './DandyMouthArchItems';
import type { DandyMouthDisplayProps } from './DandyMouthDisplay';
import { DandyTeethSVGs } from './DandyTeethSVGs';
import type { ToothNumber } from '@orthly/items';
import { AllLowerToothNumbers, AllUpperToothNumbers } from '@orthly/items';
import { FlossPalette } from '@orthly/ui-primitives';
import some from 'lodash/some';
import uniqBy from 'lodash/uniqBy';
import React from 'react';

interface SingleToothPathProps {
    number: ToothNumber;
    hasItem: boolean;
    highlight?: boolean;
    activeColor?: string;
    isImplant?: boolean;
    numberFill?: string;
    onClick?: (number: ToothNumber, event: React.MouseEvent<SVGGElement, MouseEvent>) => void;
    toothStrokeColor?: string;
    toothStrokeWidth?: number;
    unselected?: boolean;
}

const SingleToothPath: React.FC<SingleToothPathProps> = props => {
    const { onClick } = props;
    const { Circle, Tooth, ToothNumber } = React.useMemo(() => DandyTeethSVGs[props.number], [props.number]);
    // const isLower = ToothUtils.lowerTeethNumbers.includes(props.number);
    return (
        <g
            aria-label={`Tooth ${props.number}`}
            role={'button'}
            onClick={e => onClick && onClick(props.number, e)}
            style={{ cursor: onClick ? 'pointer' : undefined }}
        >
            <Tooth
                // filter={`url(#shadow-${isLower ? 'lower' : 'upper'})`}
                {...(props.hasItem && !props.unselected ? { fill: props.activeColor ?? FlossPalette.BURGUNDY } : {})}
                {...(props.hasItem && !props.unselected ? { opacity: props.highlight ? 1 : 0.5 } : {})}
                stroke={props.toothStrokeColor ?? FlossPalette.STROKE_LIGHT}
                strokeWidth={props.toothStrokeWidth ?? 1}
            />
            <ToothNumber
                // filter={`url(#shadow-${isLower ? 'lower' : 'upper'})`}
                {...(props.hasItem && !props.unselected
                    ? {
                          fill: props.numberFill ? props.numberFill : props.activeColor || FlossPalette.BURGUNDY,
                      }
                    : {})}
                {...(props.hasItem && !props.unselected ? { opacity: props.highlight ? 1 : 0.5 } : {})}
                stroke={FlossPalette.STROKE_LIGHT}
                strokeWidth={1}
            />
            {props.isImplant && <Circle />}
        </g>
    );
};

interface BridgeItemBase {
    number: ToothNumber;
    pontic?: boolean;
}
interface BridgeItemProps extends BridgeItemBase {
    // if this is the max tooth we hide the connection line
    isMaxToothInBridge: boolean;
}

const BridgeItem: React.FC<BridgeItemProps> = props => {
    const { Circle, BridgeLine, Triangle } = React.useMemo(() => DandyTeethSVGs[props.number], [props.number]);
    return (
        <g id={`${props.number}-bridge-item`}>
            {!props.isMaxToothInBridge && <BridgeLine />}
            {props.pontic ? <Triangle /> : <Circle />}
        </g>
    );
};

export interface BridgeGroupProps {
    highlighted: boolean;
    items: BridgeItemBase[];
}
const BridgeGroup: React.FC<BridgeGroupProps> = props => {
    const { items } = props;
    const uniqTeeth = uniqBy(items, item => item.number);
    const maxTooth = React.useMemo(() => Math.max(...items.map(i => i.number)) as ToothNumber, [items]);
    return (
        <g id={`${maxTooth}-bridge`}>
            {/* Teeth need to go first because the bridge svg elements sit on top */}
            {uniqTeeth.map(i => (
                <SingleToothPath key={i.number} hasItem={true} number={i.number} highlight={props.highlighted} />
            ))}
            {uniqTeeth.map(i => (
                // This can technically be an implant or a bridge, but the graphics work out the same
                <BridgeItem
                    key={i.number}
                    number={i.number}
                    pontic={i.pontic}
                    isMaxToothInBridge={i.number === maxTooth}
                />
            ))}
        </g>
    );
};

export interface SingleMouthItem {
    number: ToothNumber;
    highlighted: boolean;
    isImplant?: boolean;
    activeColor?: string;
    disableClick?: boolean;
    numberFill?: string;
    toothStrokeColor?: string;
    toothStrokeWidth?: number;
    // set to true when the tooth is not supposed to appear as selected
    // but you want to customize the appearance of the tooth, such as to set only an outline
    unselected?: boolean;
}

const TwoSidedSvgWidth = 460;
const SingleSideSvgWidth = TwoSidedSvgWidth / 2;
const OriginalExportViewport = 188;

export interface TeethLayoutProps extends DandyMouthDisplayProps {
    type: 'upper' | 'lower';
}

export const TeethLayout: React.FC<TeethLayoutProps> = props => {
    const { type, items, bridges, fullMouth: fullMouthProps, onToothClick, archLabels } = props;
    const teethNumbers = React.useMemo<readonly ToothNumber[]>(() => {
        return type === 'upper' ? AllUpperToothNumbers : AllLowerToothNumbers;
    }, [type]);
    const visibleBridges = React.useMemo(() => {
        return bridges.filter(bridgeItem => {
            const allTeeth = bridgeItem.items.map(i => i.number);
            // ensure some teeth are in the visible tooth numbers
            return some(allTeeth, t => teethNumbers.includes(t));
        });
    }, [bridges, teethNumbers]);
    const allBridgeTeeth = React.useMemo(
        () => visibleBridges.flatMap(b => b.items.map(i => i.number)),
        [visibleBridges],
    );
    const viewBoxTop = type === 'lower' ? '0' : '-10';
    return (
        <svg
            width={SingleSideSvgWidth}
            viewBox={`${type === 'lower' ? OriginalExportViewport : -10} ${viewBoxTop} ${SingleSideSvgWidth} 170`}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            style={{ width: '100%' }}
        >
            {/* TODO: Figure out how to bring these back without such a performance hit */}
            <defs>
                <filter
                    id={`shadow-${type}`}
                    x={type === 'lower' ? OriginalExportViewport : 0}
                    y={0}
                    filterUnits={'userSpaceOnUse'}
                    colorInterpolationFilters={'sRGB'}
                >
                    <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
                    <feColorMatrix
                        in={'SourceAlpha'}
                        type={'matrix'}
                        values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
                    />
                    <feOffset dy={'2'} />
                    <feGaussianBlur stdDeviation={'8'} />
                    <feColorMatrix type={'matrix'} values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0'} />
                    <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow'} />
                    <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_dropShadow'} result={'shape'} />
                </filter>
            </defs>
            <g id={`teeth-${type}`}>
                <DandyMouthArchItems
                    lowerHighlighted={type === 'upper' ? false : !!fullMouthProps?.lowerHighlighted}
                    lowerVisible={type === 'upper' ? false : !!fullMouthProps?.lowerVisible}
                    lowerActiveColor={type === 'upper' ? undefined : fullMouthProps?.lowerActiveColor}
                    upperHighlighted={type === 'lower' ? false : !!fullMouthProps?.upperHighlighted}
                    upperVisible={type === 'lower' ? false : !!fullMouthProps?.upperVisible}
                    upperActiveColor={type === 'lower' ? undefined : fullMouthProps?.upperActiveColor}
                    archLabels={archLabels}
                />
                {teethNumbers.map(toothNum => {
                    if (allBridgeTeeth.includes(toothNum)) {
                        return null;
                    }
                    const itemsForTooth = items.filter(i => i.number === toothNum);
                    const highlighted = some(itemsForTooth, i => i.highlighted);
                    return (
                        <SingleToothPath
                            key={toothNum}
                            number={toothNum}
                            hasItem={itemsForTooth.length > 0}
                            highlight={highlighted}
                            activeColor={itemsForTooth.find(i => i.activeColor)?.activeColor}
                            isImplant={!!itemsForTooth.find(i => i.isImplant)}
                            numberFill={itemsForTooth.find(i => i.numberFill)?.numberFill}
                            onClick={!itemsForTooth.find(i => i.disableClick) ? onToothClick : undefined}
                            toothStrokeColor={itemsForTooth.find(i => i.toothStrokeColor)?.toothStrokeColor}
                            toothStrokeWidth={itemsForTooth.find(i => i.toothStrokeWidth)?.toothStrokeWidth}
                            unselected={itemsForTooth.find(i => i.toothStrokeWidth)?.unselected}
                        />
                    );
                })}
                {visibleBridges.map((bridge, idx) => (
                    <BridgeGroup
                        key={idx}
                        items={bridge.items.filter(i => teethNumbers.includes(i.number))}
                        highlighted={bridge.highlighted}
                    />
                ))}
            </g>
        </svg>
    );
};
