import { LabsGqlGuidedWaxupPresetStatus, LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import type { LabsGqlDesignOrderNoteCategory } from '@orthly/graphql-schema';
import type { LocalActionWithPayload } from '@orthly/ui';
import { createLocalReducerContext, createScrollToContext } from '@orthly/ui';

export interface PresetInfo {
    status?: LabsGqlGuidedWaxupPresetStatus;
    preset_status?: LabsGqlGuidedWaxupPresetStatus;
    notes?: string;
    structured_notes?: LabsGqlDesignOrderNoteCategory[];
    annotatedImageUrls?: string[];
}

export type PresetsRecord = Partial<Record<LabsGqlGuidedWaxupPresetType, PresetInfo>>;

export interface GuidedWaxupState {
    presetsByDesignRevisionId: Record<string, PresetsRecord>;
    currentDesignRevisionId?: string;
}

export const INITIAL_GUIDED_WAXUP_STATE: GuidedWaxupState = {
    presetsByDesignRevisionId: {},
};

export const {
    Provider: ScrollToProvider,
    useContainer: useScrollToContainer,
    useControl: useScrollToControl,
} = createScrollToContext();

const { Provider, useAction, useSelector } = createLocalReducerContext(
    {
        PATCH_STATE: (
            state,
            action: LocalActionWithPayload<{ designRevisionId: string | undefined; presets: PresetsRecord }>,
        ) => {
            const { designRevisionId, presets: presetsPatch } = action.payload;

            if (!designRevisionId) {
                return state;
            }

            const designRevisionPresets = state.presetsByDesignRevisionId[designRevisionId];
            if (!designRevisionPresets) {
                return {
                    currentDesignRevisionId: designRevisionId,
                    presetsByDesignRevisionId: {
                        ...state.presetsByDesignRevisionId,
                        [designRevisionId]: presetsPatch,
                    },
                };
            }

            return {
                ...state,
                currentDesignRevisionId: designRevisionId,
            };
        },
        OVERRIDE_STATE: (
            state,
            action: LocalActionWithPayload<{ designRevisionId: string | undefined; presets: PresetsRecord }>,
        ) => {
            const { designRevisionId, presets: presetsPatch } = action.payload;

            if (!designRevisionId) {
                return state;
            }

            return {
                currentDesignRevisionId: designRevisionId,
                presetsByDesignRevisionId: {
                    ...state.presetsByDesignRevisionId,
                    [designRevisionId]: presetsPatch,
                },
            };
        },
        SET_PRESET_STATUS: (
            state,
            action: LocalActionWithPayload<{
                presetName: LabsGqlGuidedWaxupPresetType;
                status?: LabsGqlGuidedWaxupPresetStatus;
            }>,
        ) => {
            const presetInfo: Partial<PresetInfo> = { status: action.payload.status };

            if (presetInfo.status !== LabsGqlGuidedWaxupPresetStatus.Rejected) {
                presetInfo.structured_notes = undefined;
                presetInfo.annotatedImageUrls = undefined;
                presetInfo.notes = '';
            }

            return setPresetInfo(state, action.payload.presetName, presetInfo);
        },
        SET_PRESET_STRUCTURED_REJECTION_NOTES: (
            state,
            action: LocalActionWithPayload<{
                presetName: LabsGqlGuidedWaxupPresetType;
                structuredRejectionNotes?: LabsGqlDesignOrderNoteCategory[];
            }>,
        ) => {
            const presetInfo: Partial<PresetInfo> = {
                structured_notes: action.payload.structuredRejectionNotes,
            };

            if (!action.payload.structuredRejectionNotes?.length) {
                presetInfo.notes = '';
            }

            return setPresetInfo(state, action.payload.presetName, presetInfo);
        },
        SET_PRESET_REJECTION_NOTES: (
            state,
            action: LocalActionWithPayload<{ presetName: LabsGqlGuidedWaxupPresetType; rejectionNotes?: string }>,
        ) => setPresetInfo(state, action.payload.presetName, { notes: action.payload.rejectionNotes }),
        SET_PRESET_ANNOTATION: (
            state,
            action: LocalActionWithPayload<{ presetName: LabsGqlGuidedWaxupPresetType; annotatedImageUrls: string[] }>,
        ) => setPresetInfo(state, action.payload.presetName, { annotatedImageUrls: action.payload.annotatedImageUrls }),
        SKIP_TO_GENERAL_VIEW: state => {
            if (!state.currentDesignRevisionId) {
                return state;
            }

            const currentPresets = state.presetsByDesignRevisionId[state.currentDesignRevisionId];
            if (!currentPresets) {
                return state;
            }

            const presets = Object.keys(currentPresets) as LabsGqlGuidedWaxupPresetType[];
            const newPresetStatuses = {} as Record<LabsGqlGuidedWaxupPresetType, PresetInfo>;
            presets.forEach(presetType => {
                if (presetType !== LabsGqlGuidedWaxupPresetType.GeneralView && !currentPresets[presetType]?.status) {
                    newPresetStatuses[presetType] = {
                        status: LabsGqlGuidedWaxupPresetStatus.Skipped,
                    };
                } else {
                    newPresetStatuses[presetType] = currentPresets[presetType] ?? {};
                }
            });
            return {
                ...state,
                presetsByDesignRevisionId: {
                    ...state.presetsByDesignRevisionId,
                    [state.currentDesignRevisionId]: newPresetStatuses,
                },
            };
        },
    },
    INITIAL_GUIDED_WAXUP_STATE,
);

function setPresetInfo(
    state: GuidedWaxupState,
    presetName: LabsGqlGuidedWaxupPresetType,
    presetInfo: PresetInfo,
): GuidedWaxupState {
    if (!state.currentDesignRevisionId) {
        return state;
    }

    const currentPresets = state.presetsByDesignRevisionId[state.currentDesignRevisionId];
    if (!currentPresets) {
        return state;
    }

    return {
        ...state,
        presetsByDesignRevisionId: {
            ...state.presetsByDesignRevisionId,
            [state.currentDesignRevisionId]: {
                ...currentPresets,
                [presetName]: {
                    ...currentPresets[presetName],
                    ...presetInfo,
                },
            },
        },
    };
}

export const GuidedWaxupProvider = Provider;
export const useGuidedWaxupAction = useAction;

export const useGuidedWaxupSelector = <T>(selector: (state: { presets: PresetsRecord }) => T): T =>
    useSelector(state => {
        const presets = state.presetsByDesignRevisionId[state.currentDesignRevisionId ?? ''] ?? {};
        return selector({ presets });
    });

export const useWaxupIsRejected = () =>
    useGuidedWaxupSelector(state =>
        Object.values(state.presets).some(preset => preset?.status === LabsGqlGuidedWaxupPresetStatus.Rejected),
    );
