import { File3dDropLoader } from './File3dDropLoader';
import { ScanReviewLandingPageAppProvider, useScanReviewLandingPageContext } from './ScanReviewLandingPage.hooks';
import { initializeScanReviewViewStateFromDcm } from './ScanReviewLandingPage.utils';
import {
    ScanReviewCompleteViewButtonsToolbar,
    ScanReviewCompleteViewToolButtonsToolbar,
    ScanReviewMarginMarkingViewToolbar,
} from './ScanReviewLandingPageToolbars';
import {
    type ScanReviewEditedMarginLine,
    extractScanReviewDcmFileData,
    loadScanReviewDcmFileData,
    useScanReviewCompleteViewAppContext,
    ScanReviewCompleteViewAppProvider,
    ScanReviewMarginMarkingView,
    useScanReviewMarginMarkingViewContext,
    ScanReviewMarginMarkingTool,
    ScanReviewMarginMarkingViewAppProvider,
    ScanReviewPanelType,
    ScanReviewCompleteView,
    ScanReviewMode,
    useScanReviewUndercutViewContext,
    ScanReviewUndercutView,
    ScanReviewUndercutTool,
    ScanReviewUndercutViewAppProvider,
    useChairsideCompleteViewOnImageLoadCallback,
    type ScanReviewRecordFactory,
} from '@orthly/dentin';
import { Jaw } from '@orthly/shared-types';
import { StackY, StackX } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import React from 'react';
import type * as THREE from 'three';

// The purpose of this and associated files in the same folder is to illustrate how to use the components in
// fe-libs/dentin/src/components/ScanReviewModelViewer to build functionally equivalent set of scan review
// components to what is in chairside today.

const ScanReviewLandingPageUndercutView: React.VFC = () => {
    const { scene, toolController } = useScanReviewUndercutViewContext();
    return (
        <StackX sx={{ flex: 'auto', minHeight: 0 }}>
            <ScanReviewCompleteViewButtonsToolbar scene={scene}>
                <Button variant={'text'} onClick={() => toolController && toolController.updateInsertionAxisFromView()}>
                    Set Direction
                </Button>
            </ScanReviewCompleteViewButtonsToolbar>
            <StackY sx={{ flex: 'auto', minHeight: 0, minWidth: 0 }}>
                <ScanReviewUndercutView>
                    {toolController && <ScanReviewUndercutTool toolController={toolController} />}
                </ScanReviewUndercutView>
                <ScanReviewCompleteViewToolButtonsToolbar />
            </StackY>
        </StackX>
    );
};

const ScanReviewLandingPageMarginMarkingView: React.VFC = () => {
    const { scene, toolController } = useScanReviewMarginMarkingViewContext();
    return (
        <StackX sx={{ flex: 'auto', minHeight: 0 }} onContextMenu={e => e.preventDefault()}>
            <ScanReviewCompleteViewButtonsToolbar scene={scene} />
            <StackY sx={{ flex: 'auto', minHeight: 0, minWidth: 0 }}>
                <ScanReviewMarginMarkingView>
                    {toolController && <ScanReviewMarginMarkingTool toolController={toolController} />}
                </ScanReviewMarginMarkingView>
                <ScanReviewCompleteViewToolButtonsToolbar />
            </StackY>
            <ScanReviewMarginMarkingViewToolbar />
        </StackX>
    );
};

const ScanReviewLandingPageCompleteView: React.VFC = () => {
    const { appearance, activeJawData, sceneState, sceneStateManager, scene, viewManager } =
        useScanReviewCompleteViewAppContext();

    // These two callbacks are very similar to what is used in chairside today, so should be easy to adapt.
    const onMarginUpdate = (editedMarginLine: ScanReviewEditedMarginLine) => {
        // This is where chairside would copy the edited margin line to whatever slice of state it
        // had reserved for it.
        console.log(editedMarginLine);
    };

    const onInsertionAxisUpdate = (jaw: Jaw, newInsertionAxis: THREE.Vector3) => {
        // This is where chairside would copy the updated insertion axis to whatever slice of state it
        // had reserved for it.
        console.log(jaw, newInsertionAxis);
    };

    return (
        <ScanReviewMarginMarkingViewAppProvider
            scene={scene.getPartialSceneForPanelType(ScanReviewPanelType.Isolated)}
            toothNumber={sceneState.toothNumber}
            viewManager={viewManager.getViewManagerForPanelType(ScanReviewPanelType.Isolated)}
            marginLineEditor={sceneState.currentMarginLineEditor}
            onMarginUpdate={onMarginUpdate}
        >
            <ScanReviewUndercutViewAppProvider
                scene={scene.getPartialSceneForPanelType(ScanReviewPanelType.Isolated)}
                viewManager={viewManager.getViewManagerForPanelType(ScanReviewPanelType.Isolated)}
                sceneStateManager={sceneStateManager}
                activeJawData={activeJawData}
                onInsertionAxisUpdate={onInsertionAxisUpdate}
            >
                {appearance.mode === ScanReviewMode.MarginMarking && <ScanReviewLandingPageMarginMarkingView />}
                {appearance.mode === ScanReviewMode.Review && (
                    <StackX sx={{ flex: 'auto', minHeight: 0 }}>
                        <ScanReviewCompleteViewButtonsToolbar scene={scene} />
                        <StackY sx={{ flex: 'auto', minHeight: 0 }}>
                            <ScanReviewCompleteView />
                            <ScanReviewCompleteViewToolButtonsToolbar />
                        </StackY>
                    </StackX>
                )}
                {appearance.mode === ScanReviewMode.Undercut && <ScanReviewLandingPageUndercutView />}
            </ScanReviewUndercutViewAppProvider>
        </ScanReviewMarginMarkingViewAppProvider>
    );
};

//We use a different data test id for the component based on which factories have been provided.
//The reason for this is that the function that creates the factories is an async callback and
//PlayWright needs some indication that test data is ready to be loaded.
function getDataTestId(
    upperJawFactory: ScanReviewRecordFactory | null,
    lowerJawFactory: ScanReviewRecordFactory | null,
) {
    if (!upperJawFactory && !lowerJawFactory) {
        return 'no-factories';
    } else if (upperJawFactory && lowerJawFactory) {
        return 'both-factories';
    } else if (upperJawFactory) {
        return 'upper-jaw-factory';
    }
    return 'lower-jaw-factory';
}

const ScanReviewLandingPageInner: React.VFC = () => {
    const { dcms, onDcmDrop, upperJawFactory, setUpperJawFactory, lowerJawFactory, setLowerJawFactory, viewState } =
        useScanReviewLandingPageContext();

    // After the DCM is loaded the texture data is converted in memory to an HTML image element. This is an
    // async function that accepts a callback that will be invoked once the image loading is complete. The callback
    // returned by this hook returns a factory function that is customized to construct scan records/meshes with
    // the correct materials and attributes needed for most Chairside scan review purposes. Shade matching is dealt
    // with via a separate hook/callback.
    const onScanImageLoadCallback = useChairsideCompleteViewOnImageLoadCallback();

    // Actually load the DCM files. There is a caveat with this example:
    //    The computeInitialScanReviewViewStateFromDcm function should not be used in production. It assumes
    //    a DCM has been exported from 3-Shape designer. Either the existing logic from chairside for calculating
    //    camera poses should be used, or an approach similar to what is implemented here:
    //    ./fe-libs/dentin/src/components/CaptureScanSnapshots/ScanSnapshotCameraPropsConstructor.ts
    React.useEffect(() => {
        const upperJawData = extractScanReviewDcmFileData(Jaw.UPPER, dcms);
        const lowerJawData = extractScanReviewDcmFileData(Jaw.LOWER, dcms);
        initializeScanReviewViewStateFromDcm(upperJawData, lowerJawData, viewState);
        if (upperJawData) {
            loadScanReviewDcmFileData(Jaw.UPPER, upperJawData, onScanImageLoadCallback, setUpperJawFactory);
        }
        if (lowerJawData) {
            loadScanReviewDcmFileData(Jaw.LOWER, lowerJawData, onScanImageLoadCallback, setLowerJawFactory);
        }
    }, [dcms, onScanImageLoadCallback, setLowerJawFactory, setUpperJawFactory, viewState]);

    return (
        <StackY
            sx={{
                flex: 'auto',
                overflow: 'hidden',
                height: '100vh',
            }}
            data-testid={getDataTestId(upperJawFactory, lowerJawFactory)}
        >
            <File3dDropLoader scan={'Upper Jaw'} onDcmDrop={(files, event) => onDcmDrop(Jaw.UPPER, files, event)} />
            <File3dDropLoader scan={'Lower Jaw'} onDcmDrop={(files, event) => onDcmDrop(Jaw.LOWER, files, event)} />
            <ScanReviewCompleteViewAppProvider
                upperJawFactory={upperJawFactory}
                lowerJawFactory={lowerJawFactory}
                viewState={viewState}
            >
                <ScanReviewLandingPageCompleteView />
            </ScanReviewCompleteViewAppProvider>
        </StackY>
    );
};

export const ScanReviewLandingPage: React.VFC = () => {
    return (
        <ScanReviewLandingPageAppProvider>
            <ScanReviewLandingPageInner />
        </ScanReviewLandingPageAppProvider>
    );
};
