import type { UserRole } from '../../util';
import { OrderDetailBlock } from '../OrderDetails';
import { OrderDesignPreviewPlaceholder } from '../Waxups/OrderDesignPreviewPlaceholder';
import { OrderAlignerTreatmentPlanSidebar } from './AlignerTreatmentPlanSidebar.graphql';
import { AlignerTreatmentPlanStatus } from './AlignerTreatmentPlanStatus.graphql';
import {
    AlignerTreatmentPlanTitleActions,
    AlignerTreatmentPlanTitleVersionSelector,
} from './AlignerTreatmentPlanTitle.graphql';
import { AlignerTreatmentPlanViewer, AlignerTreatmentPlanViewerFullscreen } from './AlignerTreatmentPlanViewer.graphql';
import { SoftsmileViewer } from './SoftsmileViewer';
import type {
    FragmentType,
    VeneerAlignerTreatmentPlanTreatmentPlan_FragmentFragment,
} from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlAlignerCaseFragment, LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { LabsGqlDesignOrderAlignerTreatmentPlanSource } from '@orthly/graphql-schema';
import { UserRoleUtil } from '@orthly/shared-types';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const VeneerAlignerTreatmentPlanTreatmentPlan_Fragment = graphql(`
    fragment VeneerAlignerTreatmentPlanTreatmentPlan_Fragment on DesignOrderAlignerTreatmentPlanDTO {
        id
        source
        manufacturing_files {
            converted_gcs_path
        }
        is_latest_doctor_treatment_plan
        version_number_internal
        version_number_practice

        doctor_review {
            status
        }

        ...VeneerAlignerTreatmentPlanViewer_Fragment
        ...VeneerAlignerTreatmentPlanTitleActions_Fragment
        ...VeneerAlignerTreatmentPlanTitleVersionSelector_Fragment
        ...VeneerAlignerTreatmentPlanSidebar_Fragment
        ...VeneerAlignerTreatmentPlanStatus_Fragment
    }
`);

const TreatmentPlanViewer: React.VFC<{
    selectedTreatmentPlan: VeneerAlignerTreatmentPlanTreatmentPlan_FragmentFragment;
    labOrderId: string;
    isFullscreen: boolean;
    setIsFullscreen: (isFullscreen: boolean) => void;
    selectedStepIndex: number;
    setSelectedStepIndex: React.Dispatch<React.SetStateAction<number>>;
}> = ({
    selectedTreatmentPlan,
    labOrderId,
    isFullscreen,
    setIsFullscreen,
    selectedStepIndex,
    setSelectedStepIndex,
}) => {
    if (!selectedTreatmentPlan.version_number_practice) {
        return <OrderDesignPreviewPlaceholder />;
    }

    const isSoftsmile = selectedTreatmentPlan.source === LabsGqlDesignOrderAlignerTreatmentPlanSource.SoftSmile;
    if (isSoftsmile) {
        return (
            <Grid
                item
                style={{
                    alignSelf: 'stretch',
                    flexGrow: 1,
                    display: 'contents',
                }}
            >
                <SoftsmileViewer
                    labOrderId={labOrderId}
                    versionId={selectedTreatmentPlan.version_number_internal}
                    isFullscreen={isFullscreen}
                    close={() => setIsFullscreen(false)}
                    style={{ width: '100%', aspectRatio: '5 / 3', padding: 8 }}
                />
            </Grid>
        );
    } else {
        return (
            <>
                <AlignerTreatmentPlanViewerFullscreen
                    open={isFullscreen}
                    setOpen={setIsFullscreen}
                    setSelectedStepIndex={setSelectedStepIndex}
                    selectedStepIndex={selectedStepIndex}
                    treatmentPlanFragment={selectedTreatmentPlan}
                />
                <AlignerTreatmentPlanViewer
                    fullScreen={false}
                    hideToolbar={false}
                    style={{ height: 528 }}
                    setSelectedStepIndex={setSelectedStepIndex}
                    selectedStepIndex={selectedStepIndex}
                    treatmentPlanFragment={selectedTreatmentPlan}
                />
            </>
        );
    }
};

export const AlignerTreatmentPlan: React.VFC<{
    lab_order: LabsGqlLabOrderFragment;
    aligner_case: LabsGqlAlignerCaseFragment;
    refetch: () => Promise<unknown>;
    user_role: UserRole;
    selected_step_index: number;
    set_selected_step_index: React.Dispatch<React.SetStateAction<number>>;
    all_treatment_plan_fragments: FragmentType<typeof VeneerAlignerTreatmentPlanTreatmentPlan_Fragment>[];
    selected_treatment_plan_index: number;
    set_selected_treatment_plan_index: React.Dispatch<React.SetStateAction<number>>;
}> = ({
    lab_order,
    aligner_case,
    refetch,
    user_role,
    set_selected_step_index,
    selected_step_index,
    all_treatment_plan_fragments,
    selected_treatment_plan_index,
    set_selected_treatment_plan_index,
}) => {
    const { id: order_id, patient, fulfillment_workflow } = lab_order;
    const { is_refinement, dandy_plan } = aligner_case;
    const patient_name = `${patient.first_name} ${patient.last_name}`;

    const [isFullscreen, setIsFullscreen] = React.useState<boolean>(false);

    const all_treatment_plans = getFragmentData(
        VeneerAlignerTreatmentPlanTreatmentPlan_Fragment,
        all_treatment_plan_fragments,
    );

    const current_treatment_plan = all_treatment_plans.find(plan => plan.is_latest_doctor_treatment_plan);

    const is_current_unfinalized = !current_treatment_plan?.manufacturing_files.converted_gcs_path;

    const selected_treatment_plan = all_treatment_plans[selected_treatment_plan_index] ?? null;

    const is_current_plan_selected = selected_treatment_plan?.id === current_treatment_plan?.id;

    const is_shareable = !!selected_treatment_plan?.version_number_practice && is_current_plan_selected; // limitation: only the current plan can be shared

    return (
        <OrderDetailBlock
            title={
                all_treatment_plans.length <= 1 ? (
                    `Treatment Plan`
                ) : (
                    <AlignerTreatmentPlanTitleVersionSelector
                        treatment_plan_fragments={all_treatment_plans}
                        treatment_plan_index={selected_treatment_plan_index}
                        set_treatment_plan_index={set_selected_treatment_plan_index}
                    />
                )
            }
            testId={`aligner-treatment-plan`}
            variant={`full`}
            actions={
                <AlignerTreatmentPlanTitleActions
                    orderId={order_id}
                    treatmentPlanFragment={selected_treatment_plan}
                    patientName={patient_name}
                    userRole={user_role}
                    setIsFullscreen={setIsFullscreen}
                    is_shareable={is_shareable}
                />
            }
        >
            <Grid container direction={`column`} spacing={2} style={{ paddingTop: 16 }}>
                <Grid item container>
                    <OrderAlignerTreatmentPlanSidebar
                        treatmentPlanFragment={selected_treatment_plan}
                        userRole={user_role}
                        statusBox={
                            !UserRoleUtil.is_lab(user_role) && (
                                <AlignerTreatmentPlanStatus
                                    patient_name={patient_name}
                                    order_id={order_id}
                                    treatment_plan_fragment={selected_treatment_plan}
                                    user_role={user_role}
                                    refetch={refetch}
                                    is_current_unfinalized={is_current_unfinalized}
                                    is_refinement={is_refinement}
                                    is_softsmile={
                                        selected_treatment_plan?.source ===
                                        LabsGqlDesignOrderAlignerTreatmentPlanSource.SoftSmile
                                    }
                                    lab_id={lab_order.manufacturer_id}
                                    status={lab_order.status}
                                    active_task_type={fulfillment_workflow.active_task?.type}
                                    dandy_plan={dandy_plan}
                                />
                            )
                        }
                    />
                </Grid>
                <Grid item container style={{ position: `relative` }}>
                    {selected_treatment_plan && (
                        <Grid
                            item
                            container
                            alignItems={`center`}
                            direction={`column`}
                            style={{
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                display: `flex`,
                            }}
                        >
                            <TreatmentPlanViewer
                                selectedTreatmentPlan={selected_treatment_plan}
                                labOrderId={lab_order.id}
                                isFullscreen={isFullscreen}
                                setIsFullscreen={setIsFullscreen}
                                selectedStepIndex={selected_step_index}
                                setSelectedStepIndex={set_selected_step_index}
                            />
                        </Grid>
                    )}
                    {all_treatment_plans.length === 0 && <OrderDesignPreviewPlaceholder />}
                </Grid>
            </Grid>
        </OrderDetailBlock>
    );
};
