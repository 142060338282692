import { useUsersById } from '../../../../../../utils/useUsers';
import { AttachAlignerPreppedScans } from '../actions/aligners/AttachAlignerPreppedScans';
import { SendToDoctorConfirmationDialog } from '../actions/aligners/SendToDoctor';
import { useQuery } from '@apollo/client';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlUserDtoFragment } from '@orthly/graphql-operations';
import { LabsGqlAlignerDesignSoftware, LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { DateUtils } from '@orthly/shared-types';
import {
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Menu,
    MenuItem,
    Paper,
    TablePrimitive as Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    EditIcon,
    MoreVertIcon,
    SendIcon,
    Icon,
    VisibilityIcon,
    Red,
    Text,
} from '@orthly/ui-primitives';
import type { OrderDetailAlignersSoftSmilePlanVersionsBlockProps } from '@orthly/veneer';
import {
    StartDesignInVisionButton,
    useCopyTroubleshootingVisionLink,
    useCopyWebviewerLink,
    useOpenCaseInVision,
    OrderDetailBlock,
} from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

interface ColumnSpec {
    label: string;
    maxWidth?: number;
    align: 'center' | 'left' | 'right';
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 16,
    },
    container: {
        maxHeight: 440,
    },
});

const AdminSoftsmilePlanVersions_Fragment = graphql(`
    fragment AdminSoftsmilePlanVersions_Fragment on DesignOrderAlignerTreatmentPlanDTO {
        version_number_internal

        steps_count {
            overall
        }

        softsmile_metadata {
            viewer_path
        }
    }
`);

const ActionMenuButton: React.VFC<{
    refetch: () => Promise<unknown>;
    orderId: string;
    treatmentPlanFragment: FragmentType<typeof AdminSoftsmilePlanVersions_Fragment>;
    nextVersionNum: number;
    canSendToDoctor: boolean;
}> = ({ refetch, orderId, treatmentPlanFragment, nextVersionNum, canSendToDoctor }) => {
    const treatmentPlan = getFragmentData(AdminSoftsmilePlanVersions_Fragment, treatmentPlanFragment);

    const openCaseInVision = useOpenCaseInVision();
    const copyShareableLink = useCopyTroubleshootingVisionLink();
    const copyWebviewerLink = useCopyWebviewerLink();

    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const open = !!anchorEl;

    const [sendConfirmDialogOpen, setSendConfirmDialogOpen] = React.useState(false);

    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Treatment plans with 1 step or fewer, or without webviewer assets, are not complete and cannot be sent to the doctor.
    const isCompletePlan: boolean =
        treatmentPlan.steps_count.overall > 1 && !!treatmentPlan.softsmile_metadata.viewer_path;

    return (
        <div>
            <IconButton style={{ padding: 4 }} onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem
                    onClick={() => {
                        openCaseInVision(orderId, treatmentPlan.version_number_internal);
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <EditIcon fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText primary={'Open in Vision'} />
                </MenuItem>
                <MenuItem
                    disabled={!isCompletePlan || !canSendToDoctor}
                    onClick={() => {
                        setSendConfirmDialogOpen(true);
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <SendIcon fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText primary={'Send to doctor'} />
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        copyShareableLink(orderId, treatmentPlan.version_number_internal);
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <Icon icon={'Share'} fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText primary={'Share vision:// link'} />
                </MenuItem>
                <MenuItem
                    disabled={!treatmentPlan.softsmile_metadata.viewer_path}
                    onClick={async () => {
                        await copyWebviewerLink(orderId, treatmentPlan.version_number_internal);
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <VisibilityIcon fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText primary={'Share webviewer link'} />
                </MenuItem>
            </Menu>
            <SendToDoctorConfirmationDialog
                open={sendConfirmDialogOpen}
                handleClose={() => {
                    setSendConfirmDialogOpen(false);
                }}
                refetch={refetch}
                orderId={orderId}
                softsmileVersion={treatmentPlan.version_number_internal}
                nextVersionNum={nextVersionNum}
            />
        </div>
    );
};

const AdminSoftsmilePlanVersionTableRow_Fragment = graphql(`
    fragment AdminSoftsmilePlanVersionTableRow_Fragment on DesignOrderAlignerTreatmentPlanDTO {
        version_number_internal
        version_number_practice
        created_by_user_id
        created_at

        softsmile_metadata {
            viewer_path
        }

        steps_count {
            overall
        }

        ...AdminSoftsmilePlanVersions_Fragment
    }
`);

const VersionTableRow: React.VFC<{
    versionFragment: FragmentType<typeof AdminSoftsmilePlanVersionTableRow_Fragment>;
    refetch: () => Promise<unknown>;
    orderId: string;
    authors: Record<string, LabsGqlUserDtoFragment>;
    canSendToDoctor: boolean;
    nextDoctorFacingVersion: number;
}> = ({ versionFragment, refetch, orderId, authors, canSendToDoctor, nextDoctorFacingVersion }) => {
    const version = getFragmentData(AdminSoftsmilePlanVersionTableRow_Fragment, versionFragment);

    const author = version.created_by_user_id && authors[version.created_by_user_id];
    const numSteps = version.steps_count.overall ?? 0;

    return (
        <TableRow hover tabIndex={-1}>
            {/* Action menu */}
            <TableCell style={{ paddingTop: 4, paddingBottom: 4, maxWidth: 40 }}>
                <ActionMenuButton
                    refetch={refetch}
                    orderId={orderId}
                    treatmentPlanFragment={version}
                    nextVersionNum={nextDoctorFacingVersion}
                    canSendToDoctor={canSendToDoctor}
                />
            </TableCell>
            {/* Internal Version */}
            <TableCell align={'center'}>
                {`S${version.version_number_internal}`}
                {!version.softsmile_metadata.viewer_path && <Red>{`*`}</Red>}
            </TableCell>
            {/* Doctor-facing version */}
            <TableCell align={'center'}>
                {version.version_number_practice ? `V${version.version_number_practice}` : `-`}
            </TableCell>
            {/* Author */}
            <TableCell align={'left'}>{author ? `${author.first_name} ${author.last_name}` : '-'}</TableCell>
            {/* Created date */}
            <TableCell align={'left'}>{DateUtils.relativeFormat(new Date(version.created_at))}</TableCell>
            {/* Steps */}
            <TableCell align={'center'}>{numSteps >= 1 ? numSteps : '-'}</TableCell>
        </TableRow>
    );
};

const AdminSoftsmilePlanVersions_Query = graphql(`
    query AdminSoftsmilePlanVersions_Query($lab_order_id: String!) {
        getAllDesignOrderAlignerTreatmentPlans(labOrderId: $lab_order_id) {
            created_by_user_id
            created_at
            version_number_internal
            version_number_practice

            softsmile_metadata {
                viewer_path
            }

            ...AdminSoftsmilePlanVersionTableRow_Fragment
        }
    }
`);

export const SoftSmilePlanVersions: React.VFC<OrderDetailAlignersSoftSmilePlanVersionsBlockProps> = ({
    refetch,
    order,
}) => {
    const { aligner_case, fulfillment_workflow, id: orderId } = order;
    const classes = useStyles();

    const isSoftSmile = aligner_case?.design_software === LabsGqlAlignerDesignSoftware.SoftSmile;

    const { data: plansData } = useQuery(AdminSoftsmilePlanVersions_Query, {
        variables: {
            lab_order_id: order.id,
        },
        fetchPolicy: 'no-cache',
        skip: !isSoftSmile,
    });

    const versions = plansData?.getAllDesignOrderAlignerTreatmentPlans ?? [];
    const authors = useUsersById(_.compact(versions.map(v => v.created_by_user_id)));

    if (!isSoftSmile) {
        return null;
    }

    const currentTask = fulfillment_workflow.active_task?.type;
    const canSendToDoctor =
        currentTask === LabsGqlWorkflowTaskType.FinalizeTreatmentPlanRequest ||
        currentTask === LabsGqlWorkflowTaskType.SetTreatmentPlanRequestUrl;

    // We use a negative date so that we will have the newest treatment plan at the top of the list.
    const treatmentPlans = _.sortBy(versions, tp => -new Date(tp.created_at));

    const columns: ColumnSpec[] = [
        { label: '', maxWidth: 40, align: 'center' },
        { label: 'Save Version', align: 'center' },
        { label: 'Doctor-facing Version', align: 'center' },
        { label: 'Author', align: 'left' },
        { label: 'Created', align: 'left' },
        { label: 'Steps', align: 'center' },
    ];

    // The version number of the next doctor-facing version.
    const nextDoctorFacingVersion =
        (_.maxBy(treatmentPlans, plan => plan.version_number_practice)?.version_number_practice ?? 0) + 1;

    return (
        <OrderDetailBlock
            variant={'full'}
            title={'SoftSmile Plan Versions'}
            contentStyle={{ flexDirection: 'column', gap: 20, alignItems: 'center' }}
            innerRootStyle={{ overflow: 'visible' }}
            actions={
                <>
                    <Grid item style={{ marginRight: 8 }}>
                        <AttachAlignerPreppedScans onClose={() => {}} order={order} refetchOrder={refetch} />
                    </Grid>
                    <StartDesignInVisionButton order_id={orderId} />
                </>
            }
        >
            {treatmentPlans.length ? (
                <Paper className={classes.root} elevation={0}>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {columns.map((column, i) => (
                                        <TableCell
                                            key={i}
                                            align={column.align}
                                            style={{ minWidth: 0, maxWidth: column.maxWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {treatmentPlans.map(version => (
                                    <VersionTableRow
                                        key={version.version_number_internal}
                                        versionFragment={version}
                                        refetch={refetch}
                                        orderId={orderId}
                                        authors={authors}
                                        canSendToDoctor={canSendToDoctor}
                                        nextDoctorFacingVersion={nextDoctorFacingVersion}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            ) : (
                <Text variant={'body2'} color={'DARK_GRAY'} style={{ padding: 24 }}>
                    No plans saved yet
                </Text>
            )}
            {versions.some(v => !v.softsmile_metadata.viewer_path) && (
                <Text variant={'body2'} color={'ATTENTION'} style={{ alignSelf: 'start', marginBottom: 8 }}>
                    * This save version does not include webviewer assets and so may not be sent to the doctor.
                </Text>
            )}
        </OrderDetailBlock>
    );
};
