import FlossToothSad from '../../../assets/images/FlossToothSad.svg';
import { XIcon } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, Text, IconButton } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        margin: 24,
        alignItems: 'center',
    },
    closeIconContainer: {
        padding: '20px 16px',
    },
    closeIcon: {
        color: `${FlossPalette.PRIMARY_FOREGROUND} !important`,
    },
}));

interface ErrorPlaceholderProps {
    onClose: () => void;
    message: string;
}

export const ErrorPlaceholder: React.FC<ErrorPlaceholderProps> = ({ onClose, message }) => {
    const classes = useStyles();

    return (
        <Grid container direction={'row'} justifyContent={'space-between'}>
            <Grid item className={classes.closeIconContainer}>
                <IconButton onClick={onClose}>
                    <XIcon className={classes.closeIcon} />
                </IconButton>
            </Grid>
            <Grid item container className={classes.root} direction={'column'} justifyContent={'center'} spacing={3}>
                <Grid item>
                    <img src={FlossToothSad} alt={message} />
                </Grid>
                <Grid item>
                    <Text
                        style={{
                            color: FlossPalette.ATTENTION,
                        }}
                    >
                        {message}
                    </Text>
                </Grid>
            </Grid>
            <Grid item />
        </Grid>
    );
};
