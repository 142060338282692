import { useViewManager } from '../ScanReview.hooks';
import type { ScanReviewRecordFactory } from '../ScanReviewRecordTypes';
import { ScanReviewPartialScene } from '../ScanReviewSceneTypes';
import { type ScanReviewViewState, ScanReviewPanelType } from '../ScanReviewViewTypes';
import { ScanReviewShadeMatchingPicker } from './ScanReviewShadeMatchingTypes';
import constate from 'constate';
import React from 'react';

interface ScanReviewShadeMatchingViewAppProps {
    lowerJawFactory: ScanReviewRecordFactory | null;
    upperJawFactory: ScanReviewRecordFactory | null;
    viewState: ScanReviewViewState;
    onShadePicked: (color: [number, number, number]) => void;
}

function useScanReviewShadeMatchingViewApp({
    lowerJawFactory,
    upperJawFactory,
    onShadePicked,
    viewState,
}: ScanReviewShadeMatchingViewAppProps) {
    const scene = React.useMemo(() => {
        const upperJaw = upperJawFactory?.() ?? null;
        const lowerJaw = lowerJawFactory?.() ?? null;
        const scene = new ScanReviewPartialScene(upperJaw, lowerJaw);
        return scene;
    }, [upperJawFactory, lowerJawFactory]);

    const viewManager = useViewManager(scene, ScanReviewPanelType.Isolated, viewState);
    const picker = React.useMemo(() => {
        return new ScanReviewShadeMatchingPicker(scene, viewManager);
    }, [scene, viewManager]);

    return {
        picker,
        onShadePicked,
    };
}
export const [ScanReviewShadeMatchingViewAppProvider, useScanReviewShadeMatchingViewContext] = constate(
    useScanReviewShadeMatchingViewApp,
);
