import { useFirebasePreviewMulti } from '../../hooks';
import { DandyAttachmentZoomIcon } from '../DandyChat';
import { DandyLightbox, isImagePath } from '../DandyLightbox';
import type { LabsGqlDoctorRequestOptionModifyOrderMode } from '@orthly/graphql-schema';
import type { LabsGqlDoctorRequestOptionType } from '@orthly/graphql-schema';
import type { ICartItemV2DTO } from '@orthly/items';
import { LoadBlocker } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    attachmentsContainer: {
        position: 'relative',
        '&:hover': {
            background: FlossPalette.STAR_GRASS,
            cursor: 'pointer',
        },
        '&:hover img': { opacity: 0.4 },
        '&:hover svg': { visibility: 'visible' },
    },
}));

export type AskDoctorV2ViewableOption = {
    id: string;
    label: string;
    type: LabsGqlDoctorRequestOptionType | null;
    modify_order_config?: { items_v2: ICartItemV2DTO[]; mode?: LabsGqlDoctorRequestOptionModifyOrderMode };
};

export type AskDoctorAttachmentProps = {
    attachmentUrls: string[];
    orderId: string;
};

export const AskDoctorAttachments: React.VFC<AskDoctorAttachmentProps> = ({ attachmentUrls, orderId }) => {
    const classes = useStyles();

    const [selectedAttachmentPreview, setSelectedAttachmentPreview] = React.useState<string | undefined>(undefined);
    const attachments = React.useMemo(() => {
        return attachmentUrls.filter(url => isImagePath(url)).map(url => ({ source: url, name: url }));
    }, [attachmentUrls]);
    const { result: previews, loading: previewsLoading } = useFirebasePreviewMulti(attachments);

    return (
        <Grid item container>
            <LoadBlocker blocking={previewsLoading} ContainerProps={{ style: { justifyContent: 'space-evenly' } }}>
                {attachments.map(r => {
                    const imgSrc = previews?.find(img => r.name === img.name)?.source;
                    return (
                        <Grid
                            item
                            xs={5}
                            key={r.name}
                            onClick={() => setSelectedAttachmentPreview(r.name)}
                            style={{ padding: 0, margin: '8px 0px' }}
                            className={classes.attachmentsContainer}
                        >
                            {imgSrc && (
                                <>
                                    <img
                                        alt={r.name}
                                        key={r.name}
                                        src={imgSrc}
                                        style={{ height: '100%', width: '100%' }}
                                    />
                                    <DandyAttachmentZoomIcon />
                                </>
                            )}
                        </Grid>
                    );
                })}
            </LoadBlocker>
            {selectedAttachmentPreview && (
                <DandyLightbox
                    previews={previews}
                    loading={previewsLoading}
                    setSelectedAttachmentPreview={setSelectedAttachmentPreview}
                    selectedAttachmentPreview={selectedAttachmentPreview}
                    onPhotoViewedAnalytics={(source, name) => {
                        return {
                            name: 'All - Portal - Photo Lightbox Viewed',
                            data: {
                                $groups: { order: orderId },
                                displayLocation: 'hold_question_doctor_warning',
                                photoSource: source,
                                photoName: name,
                            },
                        };
                    }}
                />
            )}
        </Grid>
    );
};
