import { ORDER_COMPARE_SUBGRID_STYLES, OrderCompareSectionDivider } from './OrderCompareDisplayComponents';
import { OrderCompareOrderHeader } from './OrderCompareOrderHeader.graphql';
import type { OrderCompareOrderItemsKeyMap } from './OrderCompareOrderItems.graphql';
import { OrderCompareOrderItems } from './OrderCompareOrderItems.graphql';
import { OrderCompareOrderNotes } from './OrderCompareOrderNotes.graphql';
import { OrderCompareOrderSummary } from './OrderCompareOrderSummary.graphql';
import { ORDER_COMPARE_ORDER_CLASS_NAME } from './classNames';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { FlossPalette, styled } from '@orthly/ui-primitives';
import React from 'react';

const AdminOrderCompareOrderBlockLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderCompareOrderBlockLabSalesOrder_Fragment on LabSalesOrderDTO {
        ...AdminOrderCompareOrderHeaderLabSalesOrder_Fragment
        ...AdminOrderCompareOrderSummaryLabSalesOrder_Fragment
        ...AdminOrderCompareOrderItemsLabSalesOrder_Fragment
        ...AdminOrderCompareOrderNotesLabSalesOrder_Fragment
    }
`);

const OrderCompareOrderBlockWrapper = styled('div')({
    ...ORDER_COMPARE_SUBGRID_STYLES,
    // number must be greater than the number of rows displayed, 10000 feels like a safe bet
    gridRow: 'span 10000',
    borderRadius: 16,
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    background: FlossPalette.TAN,
    padding: '8px 0',
});

interface OrderCompareOrderBlockProps {
    salesOrder: FragmentType<typeof AdminOrderCompareOrderBlockLabSalesOrder_Fragment>;
    previousSalesOrder: FragmentType<typeof AdminOrderCompareOrderBlockLabSalesOrder_Fragment> | null;
    allSalesOrders: readonly FragmentType<typeof AdminOrderCompareOrderBlockLabSalesOrder_Fragment>[];
    itemsKeyMap: OrderCompareOrderItemsKeyMap;
    openOrder: (orderId: string, e: React.MouseEvent) => void;
}

export const OrderCompareOrderBlock: React.VFC<OrderCompareOrderBlockProps> = ({
    salesOrder: salesOrderProp,
    previousSalesOrder: previousSalesOrderProp,
    allSalesOrders: allSalesOrdersProp,
    itemsKeyMap,
    openOrder,
}) => {
    const salesOrder = getFragmentData(AdminOrderCompareOrderBlockLabSalesOrder_Fragment, salesOrderProp);
    const previousSalesOrder = getFragmentData(
        AdminOrderCompareOrderBlockLabSalesOrder_Fragment,
        previousSalesOrderProp,
    );
    const allSalesOrders = getFragmentData(AdminOrderCompareOrderBlockLabSalesOrder_Fragment, allSalesOrdersProp);

    // NOTE: in order for the comparison script and grid displays to work properly,
    // every single order must render the same number of rows, and each row index must correspond to the same data across orders
    return (
        <OrderCompareOrderBlockWrapper className={ORDER_COMPARE_ORDER_CLASS_NAME}>
            <OrderCompareOrderHeader salesOrder={salesOrder} openOrder={openOrder} />
            <OrderCompareSectionDivider />
            <OrderCompareOrderSummary salesOrder={salesOrder} previousSalesOrder={previousSalesOrder ?? null} />
            <OrderCompareSectionDivider />
            <OrderCompareOrderItems salesOrder={salesOrder} allSalesOrders={allSalesOrders} itemsKeyMap={itemsKeyMap} />
            <OrderCompareSectionDivider />
            <OrderCompareOrderNotes salesOrder={salesOrder} />
        </OrderCompareOrderBlockWrapper>
    );
};
