import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import {
    OrderDesignPreviewDesign_FragmentFragmentDoc,
    VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc,
    getFragmentData,
} from '@orthly/graphql-inline-react';
import { LabsGqlDesignOrderDoctorReviewStatus } from '@orthly/graphql-schema';
import { ChevronLeft } from '@orthly/ui';
import { stylesFactory, Text, Grid, Icon, Button } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        alignItems: 'center',
        diplay: 'flex',
    },
    patientAndButtonContainer: {
        alignItems: 'center',
    },
    patientName: {
        marginLeft: 12,
    },
    closeButton: {
        padding: 8,
        minWidth: 0,
    },
    statusContainer: {
        justifyContent: 'flex-start',
    },
}));

export const HistoricalWaxupsToolbarDesktop: React.VFC = () => {
    const classes = useStyles();
    const {
        patientName,
        onBack,
        revisionId,
        selectedDesignFragment,
        designRevisionFragments,
        enableGuidedWaxupComparison,
    } = useGuidedWaxupContext();

    const slimDesigns = getFragmentData(VeneerUseOrderRevisionItemsDesign_FragmentFragmentDoc, designRevisionFragments);
    const selectedDesign = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, selectedDesignFragment);
    const isApprovedWaxup = selectedDesign?.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved;

    const approvedWaxupIsPresentInHistory = slimDesigns.some(
        design => design.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved,
    );

    // The revisionId here indicates that the doctor is viewing previous waxups from the entrypoint in the order details page.
    // This is only accessible for the doctor when they are either waiting on a new design or they approved the most recent design
    // so we add this helper text for clarity when they are waiting on a new design.
    const showDesignUnderWayText = revisionId !== undefined && !approvedWaxupIsPresentInHistory;
    const newDesignUnderWayText = showDesignUnderWayText ? 'Your new design is under way.' : '';
    return (
        <Grid container className={classes.root}>
            <Grid item container className={classes.patientAndButtonContainer} xs={5}>
                <Grid item>
                    <Button variant={'secondary'} className={classes.closeButton} onClick={() => onBack()}>
                        {enableGuidedWaxupComparison || revisionId !== undefined ? (
                            <Icon icon={'CloseIcon'} />
                        ) : (
                            <ChevronLeft />
                        )}
                    </Button>
                </Grid>
                <Grid item className={classes.patientName}>
                    <Text variant={'h6'} medium>
                        {`Reviewing ${patientName}'s Design`}
                    </Text>
                </Grid>
            </Grid>
            <Grid item container className={classes.statusContainer} xs={7}>
                <Grid item>
                    <Text variant={'body1'} color={isApprovedWaxup ? 'PRIMARY_FOREGROUND' : 'SECONDARY_FOREGROUND'}>
                        {isApprovedWaxup
                            ? 'This is your approved design.'
                            : `This is a previously rejected design. ${newDesignUnderWayText}`}
                    </Text>
                </Grid>
            </Grid>
        </Grid>
    );
};
