import { RootActionDialog } from '@orthly/ui';
import { Button, Text } from '@orthly/ui-primitives';
import React from 'react';

interface RemoveRelationshipDialogProps {
    sku: string;
    isRemoveDialogOpen: boolean;
    onSave: () => unknown;
    onClose: () => unknown;
}

export const RemoveRelationshipDialog: React.FC<RemoveRelationshipDialogProps> = ({
    sku,
    isRemoveDialogOpen,
    onSave,
    onClose,
}) => {
    return (
        <RootActionDialog
            title={`Are you sure?`}
            open={isRemoveDialogOpen}
            loading={false}
            setOpen={isOpen => !isOpen && onClose()}
            hideButton={true}
            content={
                <div>
                    <Text variant={'h6'} style={{ marginBottom: `16px` }}>
                        This will remove the abutment part ({sku}) from this implant/scanbody, are you sure?
                    </Text>
                    <Text variant={'body2'} style={{ marginBottom: `32px` }}>
                        Hint: If you want to skip this confirmation next time you're removing an abutment part, press
                        shift while you click on the remove button!
                    </Text>
                    <Button variant={'alert'} onClick={onSave}>
                        Remove
                    </Button>
                    <Button variant={'ghost-warning'} onClick={onClose}>
                        Cancel
                    </Button>
                </div>
            }
        />
    );
};
