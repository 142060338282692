import { OrderDetailPageFrame } from '../../OrderDetailPageFrame.graphql';
import { useOrderDetailContext } from '../../state/OrderDetailProvider.graphql';
import { OrderCompareContent } from './OrderCompareContent.graphql';
import React from 'react';

export const OrderDetailComparePage: React.VFC = () => {
    const { id: orderId } = useOrderDetailContext();

    return (
        <OrderDetailPageFrame body={<OrderCompareContent orderId={orderId} />} sidebar={null} toolbarActions={null} />
    );
};
