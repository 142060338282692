import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useResolveRejectedScanMutation } from '@orthly/graphql-react';
import { LabsGqlRejectedScanResolution } from '@orthly/graphql-schema';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import type { SimpleSelectOption } from '@orthly/ui';
import { ActionCard, QuickForm, OrthlyBrowserConfig, useRootActionCommand } from '@orthly/ui';
import { Button, Collapse, FormControlLabel, Switch } from '@orthly/ui-primitives';
import { useFirebasePreview, getQFUploadFieldDef } from '@orthly/veneer';
import React from 'react';
import { z } from 'zod';

export const ResolutionOptionLabels: Record<LabsGqlRejectedScanResolution, string> = {
    [LabsGqlRejectedScanResolution.NotShared]: 'Not shared with practice',
    [LabsGqlRejectedScanResolution.SharedNoHold]: 'Shared with practice, no hold',
    [LabsGqlRejectedScanResolution.ProceedWithoutChange]: 'Proceeded with no changes',
    [LabsGqlRejectedScanResolution.ProceedWithChange]: 'Proceeded with changes',
    [LabsGqlRejectedScanResolution.NewScan]: 'Uploaded new scans',
    [LabsGqlRejectedScanResolution.Other]: 'Other',
};

const ResolutionOptions = Object.values(LabsGqlRejectedScanResolution).map<SimpleSelectOption>(value => ({
    value,
    label: ResolutionOptionLabels[value],
}));

export const ScanPane: React.FC<{
    order: LabsGqlLabOrderFragment;
    afterSubmit: () => void;
    uploadNewScanUnchecked?: boolean;
}> = props => {
    const { order, afterSubmit, uploadNewScanUnchecked } = props;
    const [uploadNewScan, setUploadNewScan] = React.useState<boolean>(!uploadNewScanUnchecked);
    const { result: old_3oxz_preview_link, error } = useFirebasePreview(order.scan_export.file_url ?? '');

    const { submit, submitting } = useRootActionCommand(useResolveRejectedScanMutation(), {
        successMessage: 'Scan issue resolved!',
        onSuccess: async () => {
            afterSubmit();
        },
    });

    const CustomSubmit = React.useCallback(
        ({ triggerSubmit, disabled }) => (
            <Button
                style={{ marginTop: 16 }}
                fullWidth
                variant={'primary'}
                disabled={disabled}
                onClick={() => {
                    triggerSubmit();
                }}
            >
                Resolve Scan Review
            </Button>
        ),
        [],
    );

    const newScanPath = getFullStoragePath(OrthlyBrowserConfig.env, OrderingStorageConfigs.scans, order.id);

    return (
        <>
            <FormControlLabel
                control={
                    <Switch
                        color={'secondary'}
                        checked={uploadNewScan}
                        onChange={event => setUploadNewScan(event.target.checked)}
                    />
                }
                label={'Upload new scan?'}
            />
            <Collapse in={!uploadNewScan} style={{ width: '100%' }}>
                {!uploadNewScan && (
                    <QuickForm<{
                        resolution: LabsGqlRejectedScanResolution;
                        notes: string;
                    }>
                        fields={{
                            resolution: {
                                type: 'select',
                                disableSortOptions: true,
                                options: ResolutionOptions,
                            },
                            notes: {
                                type: 'text',
                                helperText: 'Please explain why we are not replacing scan',
                                fieldProps: { multiline: true, minRows: 3, maxRows: 100 },
                            },
                        }}
                        disabled={submitting}
                        CustomSubmit={CustomSubmit}
                        initialValues={{}}
                        onSubmit={async result =>
                            await submit({
                                data: {
                                    order_id: order.id,
                                    notes: result.notes,
                                    resolution: result.resolution,
                                },
                            })
                        }
                    />
                )}
            </Collapse>
            <Collapse in={uploadNewScan} style={{ width: '100%' }}>
                {error && (
                    <ActionCard title={`Failed to fetch scan file.`} subtitle={String(error)} variant={'alert'} />
                )}
                {uploadNewScan && (
                    <QuickForm<{
                        resolution: LabsGqlRejectedScanResolution;
                        notes: string;
                        new_3oxz_gcs_path: string;
                        skip_new_scan_review: boolean;
                    }>
                        disabled={!!error || submitting}
                        fields={{
                            resolution: {
                                type: 'select',
                                disableSortOptions: true,
                                options: ResolutionOptions,
                            },
                            notes: {
                                type: 'text',
                                fieldProps: { multiline: true, minRows: 3, maxRows: 100 },
                                validation: z.string(),
                            },
                            skip_new_scan_review: {
                                type: 'boolean',
                                helperText: 'If checked, a scan review task will not be created for the new scan',
                            },
                            new_3oxz_gcs_path: getQFUploadFieldDef({
                                fileField: { fieldKey: '.30xzScan' },
                                label: 'New scan',
                                storagePathConfig: newScanPath,
                            }),
                        }}
                        CustomSubmit={CustomSubmit}
                        initialValues={{ skip_new_scan_review: false }}
                        onSubmit={async result =>
                            await submit({
                                data: {
                                    order_id: order.id,
                                    notes: result.notes,
                                    new_scan: {
                                        old_3oxz_preview_link: old_3oxz_preview_link ?? '',
                                        new_3oxz_gcs_path: result.new_3oxz_gcs_path,
                                        skip_new_scan_review: result.skip_new_scan_review,
                                    },
                                    resolution: result.resolution,
                                },
                            })
                        }
                    />
                )}
            </Collapse>
        </>
    );
};
