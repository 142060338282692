import { stylesFactory } from '../util/stylesFactory';
import type { SvgIconProps, ButtonVariant } from '@orthly/ui-primitives';
import { FlossPalette, Icon, Text, Button } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    wrapper: {
        display: 'flex',
        padding: 4,
        borderRadius: 8,
    },
    numberContainer: {
        flexGrow: 1,
        minWidth: 80,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // often when users click the disabled buttons, it'll highlight the
        // number text, creating a poor looking experience, so we disable highlighting
        userSelect: 'none',
    },
    buttonWrapper: {
        backgroundColor: FlossPalette.WHITE,
        // matches the border radius of buttons
        borderRadius: 8,
    },
    button: {
        // overrides the default min width
        minWidth: 48,
        width: 48,
        height: 48,
    },
}));

type FlipperVariant = 'open' | 'contained';

interface NumberFlipperInputBaseProps {
    value: number;
    onChange: (val: number) => void;
    min?: number;
    max?: number;
    stepSize: number;
    label: string;
    variant?: FlipperVariant;
    leftPaddleIcon: React.ComponentType<SvgIconProps>;
    rightPaddleIcon: React.ComponentType<SvgIconProps>;
}

export const NumberFlipperInputBase: React.VFC<NumberFlipperInputBaseProps> = ({
    value,
    onChange,
    max,
    min,
    stepSize,
    variant = 'contained',
    label,
    leftPaddleIcon: LeftPaddleIcon,
    rightPaddleIcon: RightPaddleIcon,
}) => {
    const classes = useStyles();
    const isContained = variant === 'contained';
    const buttonVariant: ButtonVariant = isContained ? 'ghost' : 'secondary';

    const isValidValue = (val: number): boolean => {
        return (max === undefined || val <= max) && (min === undefined || val >= min);
    };

    return (
        <div
            className={classes.wrapper}
            style={{
                backgroundColor: isContained ? FlossPalette.TAN : undefined,
                border: isContained ? `1px solid ${FlossPalette.STROKE_LIGHT}` : undefined,
            }}
        >
            <div className={classes.buttonWrapper}>
                <Button
                    className={classes.button}
                    variant={buttonVariant}
                    onClick={() => onChange(value - stepSize)}
                    disabled={!isValidValue(value - stepSize)}
                    data-test={'number-flipper-input-decrement'}
                >
                    <LeftPaddleIcon />
                </Button>
            </div>
            <div className={classes.numberContainer}>
                <Text
                    variant={'body2'}
                    color={'BLACK'}
                    style={{ fontWeight: 400 }}
                    bold
                    data-test={'number-flipper-input-value'}
                >
                    {label}
                </Text>
            </div>
            <div className={classes.buttonWrapper}>
                <Button
                    className={classes.button}
                    variant={buttonVariant}
                    onClick={() => onChange(value + stepSize)}
                    disabled={!isValidValue(value + stepSize)}
                    data-test={'number-flipper-input-increment'}
                >
                    <RightPaddleIcon />
                </Button>
            </div>
        </div>
    );
};

interface NumberFlipperInputProps {
    value: number;
    onChange: (val: number) => void;
    min?: number;
    max?: number;
    stepSize: number;
    unitLabel?: string;
    variant?: FlipperVariant;
}

export const NumberFlipperInput: React.VFC<NumberFlipperInputProps> = props => {
    return (
        <NumberFlipperInputBase
            {...props}
            label={props.unitLabel ? `${props.value} ${props.unitLabel}` : `${props.value}`}
            leftPaddleIcon={props => <Icon icon={'RemoveIcon'} {...props} />}
            rightPaddleIcon={props => <Icon icon={'AddIcon'} {...props} />}
        />
    );
};

interface NumberArrayFlipperInputProps {
    value: number;
    onChange: (val: number) => void;
    allowedValues: number[];
    unitLabel?: string;
    variant?: FlipperVariant;
}

// Given a list of allowed values, the prev/next buttons will cycle through the list.
// The value and onChange properties are values from the list, not their indices.
// The index math will be handled internally within this component.
export const NumberArrayFlipperInput: React.VFC<NumberArrayFlipperInputProps> = ({
    value,
    onChange,
    allowedValues,
    unitLabel,
    variant,
}) => {
    const valueIndex = allowedValues.findIndex(v => v === value);

    return (
        <NumberFlipperInputBase
            value={valueIndex}
            onChange={newValueIndex => {
                const newValue = allowedValues[newValueIndex];
                if (newValue !== undefined) {
                    onChange(newValue);
                }
            }}
            min={0}
            max={allowedValues.length - 1}
            label={unitLabel ? `${value} ${unitLabel}` : `${value}`}
            variant={variant}
            stepSize={1}
            leftPaddleIcon={props => <Icon icon={'ChevronLeft'} {...props} />}
            rightPaddleIcon={props => <Icon icon={'ChevronRight'} {...props} />}
        />
    );
};
