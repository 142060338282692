import { useMutation, useQuery } from '@apollo/client';
import type { AdminRequestRushDialogLabSalesOrder_FragmentFragment, FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import {
    LabsGqlLabOrderStatus,
    LabsGqlLabSalesOrderServiceSpeed,
    LabsGqlOrderItemSkuType,
    LabsGqlPortalShipShippingSpeed,
} from '@orthly/graphql-schema';
import { LEHI_MANUFACTURER_ID } from '@orthly/shared-types';
import { RootActionDialog, SimpleToggle, useRootActionCommand } from '@orthly/ui';
import { Text, Button, ArrowRightAltIcon, type ButtonProps, styled, FlossPalette } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import moment from 'moment';
import React from 'react';

const AdminRequestRushDialogLabSalesOrder_Fragment = graphql(`
    fragment AdminRequestRushDialogLabSalesOrder_Fragment on LabSalesOrderDTO {
        id
        service_level {
            speed
        }
        item_groups_display_info {
            sku
        }
    }
`);

const AdminRequestRushDialogSlaPreview_Query = graphql(`
    query AdminRequestRushDialogSlaPreview_Query($payload: UpdateOrderRushConfigurationPayload!) {
        previewUpdateRushConfiguration(payload: $payload) {
            estimated_delivery_date
        }
    }
`);

const AdminRequestRushDialogSlaSubmit_Mutation = graphql(`
    mutation AdminRequestRushDialogSlaSubmit_Mutation($payload: UpdateOrderRushConfigurationPayload!) {
        updateRushConfiguration(payload: $payload) {
            estimated_delivery_date
        }
    }
`);

const REQUEST_RUSH_DISALLOWED_STATUSES: LabsGqlLabOrderStatus[] = [
    LabsGqlLabOrderStatus.Cancelled,
    LabsGqlLabOrderStatus.Delivered,
    LabsGqlLabOrderStatus.Shipped,
];

type RequestRushLabOrder = Pick<LabsGqlOrder, 'practice_dates' | 'manufacturer_id' | 'status' | 'manufacturer_sla'>;

interface RequestRushDialogProps {
    labOrder: RequestRushLabOrder;
    salesOrder: FragmentType<typeof AdminRequestRushDialogLabSalesOrder_Fragment>;
    refetch: () => Promise<any>;
}

const InputGrid = styled('div')({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(4, auto)',
    gridGap: 16,
    alignItems: 'center',
});

const CaptionRow = styled(Text)({
    gridColumn: 'span 4',
});

const FullWidthRow = styled('div')({
    gridColumn: 'span 3',
});

const RushButton = styled(Button)({
    marginRight: 8,
});

const RushDisabledWrapper = styled('div')({
    width: '100%',
    background: FlossPalette.ATTENTION_BACKGROUND,
    border: `1px solid ${FlossPalette.ATTENTION}`,
    borderRadius: `16px`,
    padding: `16px`,
    margin: `8px 0px 16px`,
});

const RequestRushCustomButton: React.VFC<ButtonProps> = ({ onClick }) => {
    return (
        <RushButton onClick={onClick} variant={`ghost`} startIcon={'TruckIconOutlined'}>
            Rush
        </RushButton>
    );
};

function formatDate(date: Date | string) {
    return moment(date).format('MM/DD/YYYY');
}

function useRushRequestQueries(
    salesOrder: AdminRequestRushDialogLabSalesOrder_FragmentFragment,
    labOrder: RequestRushLabOrder,
    open: boolean,
    onComplete: () => Promise<void>,
) {
    const orderIsRushed = salesOrder.service_level.speed === LabsGqlLabSalesOrderServiceSpeed.Rush;

    const [overnightingOrder, setOvernightingOrder] = React.useState<boolean>(orderIsRushed);

    const shippingSpeed = overnightingOrder ? LabsGqlPortalShipShippingSpeed.Overnight : null;

    const { data: previewUpdateData } = useQuery(AdminRequestRushDialogSlaPreview_Query, {
        variables: {
            payload: {
                order_id: salesOrder.id,
                shipping_speed: shippingSpeed,
            },
        },
        skip: !open,
    });
    const updatedSla = previewUpdateData?.previewUpdateRushConfiguration;

    const submitMtn = useMutation(AdminRequestRushDialogSlaSubmit_Mutation);
    const { submit, submitting } = useRootActionCommand(submitMtn, {
        successMessage: 'Rush configuration updated.',
        onSuccess: onComplete,
    });

    return {
        overnightingOrder,
        setOvernightingOrder,
        submitting,
        currentEta: labOrder.practice_dates.estimated_delivery_date,
        newEta: updatedSla?.estimated_delivery_date ?? labOrder.practice_dates.estimated_delivery_date,
        submit: () =>
            submit({
                payload: {
                    order_id: salesOrder.id,
                    shipping_speed: shippingSpeed,
                },
            }),
    };
}

/**
 * A list of SKUs that cannot be rushed. See: https://meetdandy.atlassian.net/browse/DECP-419
 */
const BANNED_RUSH_SKUS: LabsGqlOrderItemSkuType[] = [
    LabsGqlOrderItemSkuType.Aligners,
    LabsGqlOrderItemSkuType.SleepApnea,
];

export const RequestRushDialog: React.VFC<RequestRushDialogProps> = ({
    salesOrder: salesOrderProp,
    labOrder,
    refetch,
}) => {
    const salesOrder = getFragmentData(AdminRequestRushDialogLabSalesOrder_Fragment, salesOrderProp);

    const [open, setOpen] = React.useState<boolean>(false);
    const { currentEta, newEta, submit, submitting, overnightingOrder, setOvernightingOrder } = useRushRequestQueries(
        salesOrder,
        labOrder,
        open,
        async () => {
            await refetch();
            setOpen(false);
        },
    );
    const { value: enableRushRequests } = useFeatureFlag('rushRequests');

    if (REQUEST_RUSH_DISALLOWED_STATUSES.includes(labOrder.status) || !enableRushRequests) {
        return null;
    }

    // We allow changing it if it's not already overnight, and is before the lehi cutoff.
    const currentDate = moment();
    const isTooLateToChangeLehiShipping =
        labOrder.manufacturer_id === LEHI_MANUFACTURER_ID &&
        moment(labOrder.manufacturer_sla.ship_by_date).isSameOrBefore(currentDate, 'day') &&
        currentDate.tz('America/Denver').hour() >= 10;
    const isOrderAlreadyOvernight = salesOrder.service_level.speed === LabsGqlLabSalesOrderServiceSpeed.Rush;
    const isShippingOptionNoLongerChangeable = isOrderAlreadyOvernight || isTooLateToChangeLehiShipping;

    const orderContainsBannedSKUs = salesOrder.item_groups_display_info.some(({ sku }) =>
        BANNED_RUSH_SKUS.includes(sku),
    );

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={`Updating Rush Configuration`}
            content={
                <div>
                    {orderContainsBannedSKUs && (
                        <RushDisabledWrapper>
                            <Text variant={'body2'} medium color={'ATTENTION'}>
                                Aligners, Aligner Retainers, and Sleep Apnea orders cannot be rushed.
                            </Text>
                        </RushDisabledWrapper>
                    )}
                    <InputGrid>
                        {/* Shipping speed */}
                        {!orderContainsBannedSKUs && (
                            <>
                                <Text variant={'body2'} medium>
                                    Overnight Shipping
                                </Text>
                                <FullWidthRow>
                                    <SimpleToggle
                                        label={''}
                                        checked={overnightingOrder}
                                        onChange={setOvernightingOrder}
                                        FormControlLabelProps={{
                                            disabled: isShippingOptionNoLongerChangeable,
                                        }}
                                        SwitchProps={{
                                            style: {
                                                padding: 0,
                                                margin: 0,
                                            },
                                        }}
                                    />
                                </FullWidthRow>

                                {isShippingOptionNoLongerChangeable && (
                                    <CaptionRow variant={'caption'}>
                                        {isTooLateToChangeLehiShipping
                                            ? 'The shipping speed can no longer be changed for this order, as it is due to ship today and it is after 10AM MST.'
                                            : 'The shipping speed can no longer be changed for this order, as it is already set to overnight.'}
                                    </CaptionRow>
                                )}

                                {/* Overview */}

                                <Text variant={'body2'} medium>
                                    Estimated Delivery Date
                                </Text>
                                <Text variant={'body2'}>{formatDate(currentEta)}</Text>
                                <ArrowRightAltIcon />
                                <Text
                                    variant={'body2'}
                                    color={moment(newEta).isBefore(currentEta, 'day') ? 'PRIMARY_FOREGROUND' : 'BLACK'}
                                >
                                    {formatDate(newEta)}
                                </Text>
                            </>
                        )}

                        {/* Save Buttons */}
                        <Button variant={'secondary'} onClick={() => setOpen(false)} disabled={submitting}>
                            Close
                        </Button>
                        <span />
                        <span />
                        <Button
                            variant={'primary'}
                            onClick={submit}
                            disabled={submitting || isShippingOptionNoLongerChangeable || orderContainsBannedSKUs}
                        >
                            Update Rush
                        </Button>
                    </InputGrid>
                </div>
            }
            CustomButton={RequestRushCustomButton}
        />
    );
};
