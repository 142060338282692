import type { SplitManagerState } from './Splits.types';
import React from 'react';
import type { ReactReduxContextValue, TypedUseSelectorHook } from 'react-redux';
import { createDispatchHook, createSelectorHook } from 'react-redux';

export const SplitsReduxContext = React.createContext<ReactReduxContextValue>({} as ReactReduxContextValue<any, any>);

export const useSplitsDispatch = createDispatchHook(SplitsReduxContext);

export const useSplitsSelector: TypedUseSelectorHook<SplitManagerState> = createSelectorHook(SplitsReduxContext);
