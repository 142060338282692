import type { MainViewCameraControlsRef } from '../ModelViewer/ModelViewerTHREETypes';
import { useInsertionAxisIncrement } from '../PortalScanEditor';
import type { CaseMetadata } from './CaseMetadata';
import type { InsertionAxisState, MarginLinesMap } from './FinishingApp.types';
import { EditingMode } from './FinishingApp.types';
import { InsertionAxisManager } from './InsertionAxisManager';
import type { IOperationsManager } from './OperationsManager.types';
import React from 'react';

export function useInsertionAxis(
    mode: EditingMode | undefined,
    setMode: React.Dispatch<React.SetStateAction<EditingMode | undefined>>,
    cameraControlsRef: MainViewCameraControlsRef,
    manager: IOperationsManager,
    caseMetadata: CaseMetadata,
    marginLines: MarginLinesMap,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    allowInvalidIntaglio: boolean,
): InsertionAxisState {
    const [showMultiView, setShowMultiView] = React.useState(false);

    const insertionAxisManager = React.useMemo(
        () =>
            new InsertionAxisManager(
                cameraControlsRef,
                manager,
                caseMetadata,
                marginLines.get(manager.editToothNumber),
                setLoading,
                allowInvalidIntaglio,
            ),
        [cameraControlsRef, manager, caseMetadata, marginLines, setLoading, allowInvalidIntaglio],
    );

    const insertionAxisAdjustingEnabled = mode === EditingMode.InsertionAxis;

    const { insertionAxisIncrementDeg, setInsertionAxisIncrementDeg, insertionAxisIncrementRad } =
        useInsertionAxisIncrement();
    const toggleInsertionAxisAdjustingEnabled = React.useCallback(() => {
        setMode(curr => {
            return curr === EditingMode.InsertionAxis ? EditingMode.Sculpt : EditingMode.InsertionAxis;
        });
    }, [setMode]);

    return {
        manager: insertionAxisManager,
        insertionAxisAdjustingEnabled,
        toggleInsertionAxisAdjustingEnabled,
        insertionAxisIncrementDeg: insertionAxisIncrementDeg || 2,
        setInsertionAxisIncrementDeg,
        insertionAxisIncrementRad,
        showMultiView,
        setShowMultiView,
    };
}
