import { BulkSetWillChargeCCFeeControls } from './BulkSetWillChargeCCFeeControls.graphql';
import { useBulkSetWillChargeCCFeeContext } from './providers/BulkSetWillChargeCCFeeProvider.graphql';
import type { SetWillChargeCCFeeCsvRow } from './types';
import { MUITable, type Column } from '@orthly/mui-table';
import type { FlossColor } from '@orthly/ui-primitives';
import { Button, CheckboxPrimitive, FlossPalette, Text, boldTitleFont, styled } from '@orthly/ui-primitives';
import { toUpper, values } from 'lodash';
import React from 'react';

const ErrorContainer = styled('div')({
    maxHeight: '64px',
    textOverflow: 'ellipsis',
    overflowY: 'scroll',
});

const customSortBoolean = (a: boolean, b: boolean, direction: 'asc' | 'desc') => {
    if (a && !b) {
        return direction === 'asc' ? 1 : -1;
    } else if (!a && b) {
        return direction === 'asc' ? -1 : 1;
    } else {
        return 0;
    }
};

export const BulkSetWillChargeCCFeeRowDisplay: React.FC = () => {
    const { options, loading, inputRows, onPracticeSelected, onEditRow, sortColumnName } =
        useBulkSetWillChargeCCFeeContext();

    const columns: Column<SetWillChargeCCFeeCsvRow>[] = [
        {
            name: ' ',
            render: row => (
                <CheckboxPrimitive
                    color={'secondary'}
                    size={'small'}
                    checked={!!inputRows[row.id]}
                    onChange={v => onPracticeSelected(row.id, v.target.checked, row.will_be_charged_cc_fee)}
                />
            ),
            sort: true,
            customSort: (a, b, direction) => {
                const isASelected = !!inputRows[a.id];
                const isBSelected = !!inputRows[b.id];
                return customSortBoolean(isASelected, isBSelected, direction);
            },
            filter: false,
            style: { width: '16px', padding: '0' },
            defaultSort: sortColumnName === 'selected' ? 'desc' : undefined,
        },
        {
            name: 'Name',
            render: 'name',
            bodyCellWrapStyle: {
                fontFamily: boldTitleFont,
            },
            style: { width: '20%', padding: '0' },
            defaultSort: sortColumnName === 'name' ? 'desc' : undefined,
        },
        {
            name: 'Practice ID',
            render: 'id',
            bodyCellWrapStyle: {
                color: FlossPalette.GRAY,
            },
            style: { width: '25%', padding: '0' },
        },
        {
            name: 'Error',
            render: row => {
                const color: FlossColor = row.error === '-' ? 'GRAY' : 'ATTENTION_FOREGROUND';
                return (
                    <ErrorContainer>
                        <Text variant={'body2'} color={color}>
                            {row.error}
                        </Text>
                    </ErrorContainer>
                );
            },
            customSort: (a, b, direction) => {
                if (direction === 'asc') {
                    return (a.error ?? '-').localeCompare(b.error ?? '-');
                } else {
                    return (b.error ?? '-').localeCompare(a.error ?? '-');
                }
            },
            customFilterFn: (errorSearchTerms, row) => {
                const errSearchTerm = errorSearchTerms[0];
                if (!errSearchTerm) {
                    return true;
                }
                const searchTerms = errSearchTerm
                    .toLowerCase()
                    .split(',')
                    .filter(term => !!term);

                return searchTerms.some(searchTerm => row?.error?.toLowerCase().includes(searchTerm));
            },
            style: { width: '25%', maxWidth: '25%', padding: '0', whiteSpace: 'unset' },
        },
        {
            name: 'Processing fee enabled',
            render: row => <Text variant={'body2'}>{toUpper(row.will_be_charged_cc_fee.toString())}</Text>,
            style: {
                padding: '0',
            },
            customSort: (a, b, direction) =>
                customSortBoolean(a.will_be_charged_cc_fee, b.will_be_charged_cc_fee, direction),
            customFilterFn: (enabledSearchTerms, row) => {
                const enabledSearchTerm = enabledSearchTerms[0];
                if (!enabledSearchTerm) {
                    return true;
                }
                const isTrue = ['yes', 'true', 't'].includes(enabledSearchTerm.toLowerCase());
                const isFalse = ['no', 'false', 'f'].includes(enabledSearchTerm.toLowerCase());
                if (isTrue) {
                    return row?.will_be_charged_cc_fee === true;
                } else if (isFalse) {
                    return row?.will_be_charged_cc_fee === false;
                } else {
                    return true;
                }
            },
        },
        {
            name: 'Options',
            render: row => (
                <Button variant={'ghost'} onClick={() => onEditRow(row.id, !row.will_be_charged_cc_fee)}>
                    {row.will_be_charged_cc_fee ? 'Disable' : 'Enable'}
                </Button>
            ),
            sort: false,
            filter: false,
            style: {
                padding: '0',
            },
        },
    ];

    return (
        <>
            <MUITable<SetWillChargeCCFeeCsvRow>
                loading={loading}
                columns={columns}
                displayOptions={{ filter: true, sort: true, elevation: 0 }}
                data={values(options)}
                title={'Card Processing Fees'}
                rowOptions={{
                    setRowStyle: row => {
                        const isErrored = row?.error !== '-';
                        const isSelected = !!inputRows[row.id];
                        let color = FlossPalette.WHITE;
                        if (isErrored) {
                            color = FlossPalette.WARNING_BACKGROUND;
                        } else if (isSelected) {
                            color = FlossPalette.PRIMARY_BACKGROUND;
                        }

                        return {
                            backgroundColor: color,
                            height: '64px',
                        };
                    },
                }}
            />
            <BulkSetWillChargeCCFeeControls />
        </>
    );
};
