import type { ItemEditorV2BaseProps } from '../../../types/ItemEditorV2BaseProps';
import { useAutoSetItemDataFieldDefaultValue } from './ItemEditorV2ItemDataField.util';
import type { ItemDataFieldId, LabOrderItemSKUType, StringSelectItemDataField } from '@orthly/items';
import { ItemDataFieldUtils } from '@orthly/items';
import { SimpleSelect } from '@orthly/ui';
import React from 'react';

interface ItemEditorV2StringSelectItemDataFieldProps<ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>
    extends ItemEditorV2BaseProps<SKU> {
    field: StringSelectItemDataField<ID, SKU>;
}

export const ItemEditorV2StringSelectItemDataField = <ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>({
    field,
    item,
    updateItem,
    showError,
}: ItemEditorV2StringSelectItemDataFieldProps<ID, SKU>): React.ReactElement => {
    const value = field.getValue(item) ?? undefined;

    const applyUpdate = (val: string | undefined) => {
        updateItem(field.getUpdatePayload(val ?? null, item));
    };

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    return (
        <SimpleSelect
            label={field.label}
            value={value}
            options={[...field.getOptions(item)]}
            onChange={val => applyUpdate(val)}
            errorText={
                showError && ItemDataFieldUtils.isItemDataFieldRequired(field, item) && !field.isComplete(item)
                    ? 'This field is required'
                    : undefined
            }
        />
    );
};
