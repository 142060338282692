import { PracticeFullScreenDialog } from '@orthly/dentin';
import { RootActionDialog } from '@orthly/ui';
import { Button, Divider, FlossPalette, Icon, Text, styled, Box } from '@orthly/ui-primitives';
import React from 'react';

const NewPillWrapper = styled('span')({
    backgroundColor: FlossPalette.SECONDARY_FOREGROUND,
    borderRadius: '8px',
    border: `1px solid ${FlossPalette.SECONDARY_FOREGROUND}`,
    height: '32px',
    width: '51px',
    marginLeft: '8px',
    padding: '4px 8px',
});

const StyledRootActionDialog = styled(RootActionDialog)({
    '.MuiPaper-root': {
        maxWidth: '796px',
    },
});

const BottomButtons = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
    display: 'grid',
    gridTemplateColumns: isMobile ? '0 auto auto' : '1fr min-content min-content',
    gridColumnGap: '8px',
    padding: '16px',
}));

const ContentWrapper = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
    margin: isMobile ? '24px 16px 0px' : '24px 0px',
    display: 'grid',
    gridTemplateRows: `repeat(3, min-content)`,
    gridRowGap: `16px`,
    backgroundColor: FlossPalette.TAN,
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    borderRadius: `8px`,
    padding: `24px`,
}));

const BottomBannerWrapper = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridGap: '8px',
    position: isMobile ? 'relative' : 'absolute',
    bottom: isMobile ? undefined : '0px',
    left: isMobile ? undefined : '0px',
    height: isMobile ? undefined : '40px',
    backgroundColor: FlossPalette.SECONDARY_BACKGROUND,
    padding: '8px 16px',
    width: '100%',
}));

const ExclamationIcon = styled(Icon)({
    height: '12px',
    width: '12px',
    color: FlossPalette.SECONDARY_FOREGROUND,
    margin: 'auto 0px',
});

const InnerContentWrapper = styled('div')({
    display: 'grid',
    height: '100%',
    gridTemplateRows: '1fr min-content min-content',
});

const MobileWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

const MobileHeader = styled(Box)({
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    alignItems: 'center',
    minHeight: '70px',
    backgroundColor: FlossPalette.TAN,
    borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    position: 'relative',
    padding: '12px 16px',
});

const MobileCloseButton = styled(Button)({
    background: FlossPalette.TAN,
    height: '48px',
    width: '48px',
});

const BottomBanner: React.VFC<{ numberOfDaysUntilAutoApproveDdp: number; isMobile: boolean }> = ({
    numberOfDaysUntilAutoApproveDdp,
    isMobile,
}) => {
    return (
        <BottomBannerWrapper isMobile={isMobile}>
            <ExclamationIcon icon={'ExclamationIcon'} />
            <Text variant={'body2'} medium color={'SECONDARY_FOREGROUND'}>
                Missed appointments will result in the design being auto approved{' '}
                {numberOfDaysUntilAutoApproveDdp > 0
                    ? `after ${numberOfDaysUntilAutoApproveDdp} days of the meeting`
                    : 'the same day of the meeting'}
                .
            </Text>
        </BottomBannerWrapper>
    );
};

interface GuidedWaxupScheduleLiveCallInitialModal {
    open: boolean;
    openAbandomentModal: () => void;
    openModal: () => void;
    openCalendlyModal: () => void;
    numberOfDaysUntilAutoApproveDdp: number;
    /**
     * While this field could be ascertained within the component, we pass it in
     * to make it easier to test variants in Storybook and other areas.
     */
    isMobile: boolean;
}

const InnerContent: React.VFC<
    Pick<
        GuidedWaxupScheduleLiveCallInitialModal,
        'openAbandomentModal' | 'openCalendlyModal' | 'numberOfDaysUntilAutoApproveDdp' | 'isMobile'
    >
> = ({ openAbandomentModal, openCalendlyModal, numberOfDaysUntilAutoApproveDdp, isMobile }) => {
    return (
        <InnerContentWrapper>
            <div>
                <ContentWrapper isMobile={isMobile}>
                    <div>
                        <Text variant={'body2'} color={'GRAY'}>
                            What is a Live Design Review?
                        </Text>
                        <Text variant={'body2'}>
                            Live Design Review is a scheduled video call with Dandy. Collaborate with a specialist to
                            update designs in real time, and confirm final results before moving to fabrication.
                        </Text>
                    </div>
                    <Divider />
                    <div>
                        <Text variant={'body2'} color={'GRAY'}>
                            What happens when I schedule a Live Design Review?
                        </Text>
                        <Text variant={'body2'}>Your order will be paused until your scheduled design review.</Text>
                    </div>
                </ContentWrapper>
            </div>
            {isMobile && (
                <BottomBanner numberOfDaysUntilAutoApproveDdp={numberOfDaysUntilAutoApproveDdp} isMobile={isMobile} />
            )}
            <BottomButtons isMobile={isMobile}>
                <span />
                <Button variant={'secondary'} onClick={openAbandomentModal} fullWidth={false}>
                    Cancel
                </Button>
                <Button variant={'primary'} onClick={openCalendlyModal} fullWidth={false}>
                    {isMobile ? 'Pause Order' : 'Schedule Appointment & Pause Order'}
                </Button>
            </BottomButtons>
            {!isMobile && (
                <BottomBanner numberOfDaysUntilAutoApproveDdp={numberOfDaysUntilAutoApproveDdp} isMobile={isMobile} />
            )}
        </InnerContentWrapper>
    );
};

const OpenButton: React.VFC<{ openModal: () => void }> = ({ openModal }) => {
    return (
        <Button
            variant={'ghost'}
            startIcon={'CalendarAppointmentsIcon'}
            onClick={() => openModal()}
            id={'guided-ddp-schedule-live-call-button'}
        >
            Schedule Live Design Review{' '}
            <NewPillWrapper>
                <Text variant={'body2'} medium color={'WHITE'}>
                    New!
                </Text>
            </NewPillWrapper>
        </Button>
    );
};

export const GuidedWaxupScheduleLiveCallInitialModal: React.VFC<GuidedWaxupScheduleLiveCallInitialModal> = ({
    open,
    openAbandomentModal,
    openModal,
    openCalendlyModal,
    numberOfDaysUntilAutoApproveDdp,
    isMobile,
}) => {
    if (isMobile) {
        return (
            <>
                <OpenButton openModal={openModal} />
                <PracticeFullScreenDialog open={open}>
                    <MobileWrapper>
                        <MobileHeader>
                            <Text variant={'h6'} medium>
                                Would you like to schedule a Live Design Review?
                            </Text>
                            <MobileCloseButton variant={'secondary'} onClick={openAbandomentModal}>
                                <Icon icon={'XIcon'} />
                            </MobileCloseButton>
                        </MobileHeader>
                        <InnerContent
                            openAbandomentModal={openAbandomentModal}
                            openCalendlyModal={openCalendlyModal}
                            numberOfDaysUntilAutoApproveDdp={numberOfDaysUntilAutoApproveDdp}
                            isMobile={isMobile}
                        />
                    </MobileWrapper>
                </PracticeFullScreenDialog>
            </>
        );
    }
    return (
        <StyledRootActionDialog
            open={open}
            setOpen={open => {
                // If the user attempts to close, we open the abandonment modal
                if (!open) {
                    openAbandomentModal();
                } else {
                    // Otherwise, we re-open the modal.
                    openModal();
                }
            }}
            loading={false}
            buttonText={''}
            maxWidth={'md'}
            title={`Would you like to schedule a Live Design Review?`}
            showCloseButton
            CustomButton={() => <OpenButton openModal={openModal} />}
            content={
                <InnerContent
                    openAbandomentModal={openAbandomentModal}
                    openCalendlyModal={openCalendlyModal}
                    numberOfDaysUntilAutoApproveDdp={numberOfDaysUntilAutoApproveDdp}
                    isMobile={isMobile}
                />
            }
        />
    );
};
