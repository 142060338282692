import { LabsGqlDesignOrderNoteCategory } from '@orthly/graphql-schema';

export const isAnteriorContourOptionDisabled = (
    option: LabsGqlDesignOrderNoteCategory,
    selectionsContain: (options: LabsGqlDesignOrderNoteCategory[]) => boolean,
): boolean => {
    switch (option) {
        case LabsGqlDesignOrderNoteCategory.FacialReduceOvercontourCervical:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.FacialFillOutContourCervical]);

        case LabsGqlDesignOrderNoteCategory.FacialReduceOvercontourIncisal:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.FacialFillOutContourIncisal]);

        case LabsGqlDesignOrderNoteCategory.FacialFillOutContourCervical:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.FacialReduceOvercontourCervical]);

        case LabsGqlDesignOrderNoteCategory.FacialFillOutContourIncisal:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.FacialReduceOvercontourIncisal]);

        case LabsGqlDesignOrderNoteCategory.LingualReduceOvercontourCingulum:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.LingualFillOutContourCingulum]);

        case LabsGqlDesignOrderNoteCategory.LingualReduceOvercontourIncisal:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.LingualFillOutContourIncisal]);

        case LabsGqlDesignOrderNoteCategory.LingualFillOutContourCingulum:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.LingualReduceOvercontourCingulum]);

        case LabsGqlDesignOrderNoteCategory.LingualFillOutContourIncisal:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.LingualReduceOvercontourIncisal]);

        default:
            return false;
    }
};
