import { stylesFactory } from '../../util/stylesFactory';
import type { TextProps } from '@orthly/ui-primitives';
import { Tooltip, FlossPaletteUtils, FlossPalette, Icon, Text } from '@orthly/ui-primitives';
import React from 'react';

const useOverflowTextStyles = stylesFactory(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
    },
    span: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
    patientText: {
        fontWeight: 500,
        width: 'auto',
        cursor: 'pointer',
        padding: '4px 8px',
        marginLeft: -8,
        '&:hover': { background: FlossPaletteUtils.toRgba('STAR_GRASS', 0.15) },
    },
}));

export interface OverflowTextWithTooltipProps {
    color?: TextProps['color'];
    text: string | string[];
    tooltipText?: string;
    onClick?: TextProps['onClick'];
    variant?: TextProps['variant'];
    medium?: boolean;
}

export const OverflowTextWithTooltip: React.FC<OverflowTextWithTooltipProps> = props => {
    const classes = useOverflowTextStyles();
    const { text, tooltipText, onClick } = props;
    const [isOverflowed, setIsOverflowed] = React.useState<boolean>(false);
    const textElementRef = React.useRef<HTMLSpanElement | null>(null);
    const checkIsOverflowed = () => {
        const currentEl = textElementRef.current;
        if (currentEl) {
            const compare = currentEl.scrollWidth > currentEl.clientWidth;
            setIsOverflowed(compare);
        }
    };
    React.useEffect(() => checkIsOverflowed(), [text]);
    // check if overflowed on screen resize
    React.useEffect(() => {
        window.addEventListener('resize', checkIsOverflowed);
        return () => {
            window.removeEventListener('resize', checkIsOverflowed);
        };
    }, []);

    return (
        <Text
            medium={props.medium}
            onClick={onClick}
            variant={props.variant ?? 'body2'}
            color={props.color}
            className={classes.root}
            style={{ cursor: !!onClick ? 'pointer' : undefined }}
        >
            <span className={classes.span} ref={textElementRef}>
                {props.text}
            </span>
            {isOverflowed && (
                <Tooltip
                    title={<span style={{ whiteSpace: 'pre-line' }}>{tooltipText ?? text}</span>}
                    disableHoverListener={!isOverflowed}
                >
                    <Icon icon={'InfoIcon'} style={{ fontSize: 16, color: FlossPalette.GRAY, marginLeft: 4 }} />
                </Tooltip>
            )}
        </Text>
    );
};
