import type { ScanModels } from './FinishingApp.types';
import { PartialSceneAppearanceManagerBase } from './PartialSceneAppearanceManagerBase';
import type { IPartialSceneAppearanceManager, PartialSceneAppearanceSetter } from './SceneAppearanceManager.types';
import type { ISceneMaterialManager, ScanMaterialOptions } from './SceneMaterialManager.types';
import type { ToothNumber } from '@orthly/items';
import { ToothUtils } from '@orthly/items';
import type * as THREE from 'three';

// Manages the scene of the insertion direction review panel
export class InsertionSceneAppearanceManager
    extends PartialSceneAppearanceManagerBase
    implements IPartialSceneAppearanceManager
{
    constructor(
        setAppearance: PartialSceneAppearanceSetter,
        restoratives: Map<ToothNumber, THREE.BufferGeometry>,
        curtains: Map<ToothNumber, THREE.BufferGeometry>,
        scanModels: ScanModels,
        collisions: THREE.Mesh[],
        materialManager: ISceneMaterialManager,
        insertionPaths: Map<ToothNumber, THREE.ArrowHelper>,
    ) {
        const scanMaterialOptions: ScanMaterialOptions = { useColors: true, transparent: false };
        super(
            setAppearance,
            materialManager,
            restoratives,
            scanModels,
            collisions,
            insertionPaths,
            scanMaterialOptions,
            curtains,
        );

        if (this.scans) {
            this.scans.upperJaw.visible = Array.from(restoratives.keys()).some(ToothUtils.toothIsUpper);
            this.scans.lowerJaw.visible = Array.from(restoratives.keys()).some(ToothUtils.toothIsLower);
        }

        this.toggleScanUndercutEnabled(true);
        this.toggleInsertionPathsVisibility(true);
        this.toggleRestorativeTransparency(true);
    }

    toggleInsertionPathsVisibility(visible?: boolean): void {
        this.toggleInsertionPathsVisibilityImpl(visible);
    }

    toggleScanUndercutEnabled(enabled?: boolean): void {
        this.toggleScanUndercut(enabled);
    }

    toggleCurtainsVisibility(): void {
        const nextVisible = !this.areCurtainsVisible;

        this.curtains.forEach(el => {
            el.visible = nextVisible;
        });

        this.updateAppearanceState();
    }

    toggleRestorativesVisibility(): void {
        this.toggleRestorativesVisibilityImpl();
    }
}
