import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import {
    LabsGqlLabPriceAbutmentType,
    LabsGqlLabPriceRuleType,
    LabsGqlOrderItemLinkGenericManufacturer,
} from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';

export const ImplantForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.Implant>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.Implant>({
                abutment_type: {
                    type: 'select',
                    label: 'Abutment Type',
                    options: Object.values(LabsGqlLabPriceAbutmentType),
                    optional: false,
                },
                authentic: {
                    type: 'select',
                    label: 'OEM',
                    options: ['Yes', 'No'],
                    optional: false,
                },
                generic_manufacturer: {
                    type: 'select',
                    label: 'Generic Manufacturer',
                    options: Object.values(LabsGqlOrderItemLinkGenericManufacturer),
                    optional: true,
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          abutment_type:
                              price.rule.__typename === 'LabPriceImplantRuleDTO' ? price.rule.abutment_type : null,
                          authentic:
                              // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                              // eslint-disable-next-line no-nested-ternary
                              price.rule.__typename === 'LabPriceImplantRuleDTO'
                                  ? price.rule.authentic
                                      ? 'Yes'
                                      : 'No'
                                  : null,
                          generic_manufacturer:
                              price.rule.__typename === 'LabPriceImplantRuleDTO'
                                  ? price.rule.generic_manufacturer
                                  : null,
                      }
                    : {}
            }
            onSubmit={async result => {
                await submit({
                    name: result.name,
                    rule: {
                        type: LabsGqlLabPriceRuleType.Implant,
                        abutment_type: result.abutment_type,
                        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                        // eslint-disable-next-line no-nested-ternary
                        authentic: result.authentic === 'Yes' ? true : result.authentic === 'No' ? false : null,
                        generic_manufacturer: result.generic_manufacturer || null,
                    },
                });
            }}
            disabled={disabled}
        />
    );
};
