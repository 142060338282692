import type { ToolbarActionDefinition, ToolbarActionProps } from './ToolbarActionDefinition';
import { useFlagOrderForReviewMutation } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

const FlagForReviewAction: React.FC<ToolbarActionProps> = props => {
    const { order, setOpen, open } = props;
    const [submitMtn] = useFlagOrderForReviewMutation();
    const mtnSubmitter = (reason: string) => submitMtn({ variables: { reason, order_id: order.id } });
    const { submit, submitting } = useChangeSubmissionFn<any, [string]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Order Flagged For Review', {}],
        onSuccess: async () => {
            await props.refetchOrder();
            setOpen(false);
        },
    });
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Flag for Review'}
            content={
                <QuickForm<{ reason: string }>
                    fields={{ reason: { type: 'text', helperText: 'Why are you flagging for review' } }}
                    initialValues={{}}
                    onSubmit={async result => {
                        await submit(result.reason);
                    }}
                />
            }
            style={{ padding: 0 }}
            buttonText={'Flag for Review'}
            buttonProps={{
                style: { height: '100%' },
            }}
            CustomButton={props.CustomButton}
        />
    );
};

export const FlagForReviewToolbarAction: ToolbarActionDefinition = {
    label: 'Mark as Needs Review',
    Component: FlagForReviewAction,
    allowedStatuses: [LabsGqlLabOrderStatus.New],
    capabilityRequirement: 'order.flag_for_review',
};
