import { getDesignQcReducer } from './DesignQc.reducers';
import type { DesignQcState } from './DesignQc.state';
import { compose, applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

const isDev = process.env.NODE_ENV === 'development';

function getComposeEnhancers() {
    if (isDev && typeof window !== 'undefined' && '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' in window) {
        return (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: false,
            traceLimit: 5,
            name: 'DesignQc',
        });
    }

    return compose;
}

const composeEnhancers = getComposeEnhancers();

export const DesignQcStore = (
    props: Pick<
        DesignQcState,
        | 'config'
        | 'order'
        | 'designFragment'
        | 'rubricNavigation'
        | 'currentNavigationStartTime'
        | 'designQaEvaluationId'
        | 'current_user_id'
        | 'has_design_previously_been_rejected'
    >,
) =>
    createStore<DesignQcState, any, any, any>(
        getDesignQcReducer(props),
        undefined,
        composeEnhancers(applyMiddleware(thunk)),
    );
