import { BigRadioSelect } from '@orthly/dentin';
import { RootActionDialog, styled } from '@orthly/ui';
import { Button, Text } from '@orthly/ui-primitives';
import React from 'react';

const ContentWrapper = styled('div')({ marginTop: '24px' });

const ButtonRow = styled('div')({
    marginTop: '48px',
    display: 'grid',
    gridTemplateColumns: 'auto min-content min-content',
    gridColumnGap: '8px',
});

const allReasons = [
    `I'd prefer to be able to review my design with a specialist instantly`,
    `I'm not available to attend the open appointment slots`,
    `I'd rather jump on a phone call`,
    `Other`,
];

export const GuidedWaxupScheduleLiveCallAbandonmentModal: React.VFC<{
    closeRootModal: (reason: string | null) => void;
}> = ({ closeRootModal }) => {
    const [reason, setReason] = React.useState<string | undefined>(undefined);
    return (
        <RootActionDialog
            loading={false}
            open={true}
            setOpen={() => closeRootModal(null)}
            showCloseButton
            title={'Not interested in trying out Live Design Reviews?'}
            CustomButton={() => null}
            content={
                <ContentWrapper>
                    <Text variant={'body2'} medium>
                        What's your reason for changing your mind?
                    </Text>
                    <BigRadioSelect
                        options={allReasons.map(reason => ({ label: reason, value: reason }))}
                        direction={'column'}
                        value={reason}
                        onChange={value => setReason(value)}
                    />
                    <ButtonRow>
                        <span />
                        <Button variant={'secondary'} onClick={() => closeRootModal(null)} fullWidth={false}>
                            Cancel
                        </Button>
                        <Button
                            variant={'primary'}
                            onClick={() => {
                                closeRootModal(reason ?? null);
                            }}
                            fullWidth={false}
                            disabled={!reason}
                        >
                            Submit
                        </Button>
                    </ButtonRow>
                </ContentWrapper>
            }
        />
    );
};
