import { DesignQcRubricCategoryToLabelMap } from './DesignQcCommon';
import { DesignStandardModalWithButton } from './DesignQcDesignStandardModal';
import { DesignQcFinalReviewModal } from './DesignQcReviewModal';
import { DesignQcSubcategoryModal } from './DesignQcSubcategoryRubricModal';
import type { DesignQcRubricSubCategoryEntry } from './configs/DesignQc.config.types';
import { useDesignQcAction } from './state/DesignQc.actions';
import { useDesignQcPropSelector } from './state/DesignQc.context';
import { useCanSubmit, getQcRubricEntryResult, getRelativeEntry } from './state/DesignQc.selectors';
import { XIcon, CheckIcon } from '@orthly/ui';
import { FlossPalette, Text, stylesFactory, Grid, Switch, InfoIcon, Button } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    categoryNameContainer: {
        padding: `6px 16px`,
        marginTop: 16,
        marginBottom: 8,
        borderBottom: `1px solid ${FlossPalette.STAR_GRASS_HIGHLIGHTED}`,
    },
    entryRoot: {
        borderLeft: `4px solid ${FlossPalette.TAN}`,
        backgroundColor: FlossPalette.TAN,
        cursor: 'pointer',
        padding: `8px 8px 8px 0px`,
    },
    entryRootSelected: {
        borderLeft: `4px solid ${FlossPalette.PRIMARY_FOREGROUND}`,
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
    },
    entryName: {
        flexGrow: 1,
        marginRight: 8,
    },
    entryIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px 8px',
    },
    rubricSidebarRoot: {
        width: 364,
        height: '100%',
        borderRight: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        backgroundColor: FlossPalette.TAN,
    },
    rubricSidebarHeader: {
        height: 64,
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        padding: `20px 16px`,
    },
    rubricSidebarBottomWrapper: {
        borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        padding: 16,
    },
    rubricSidebarBottomDesignStandard: {
        paddingBottom: 16,
    },
    rubricSidebarBottomNavigationButtons: {
        paddingTop: 16,
    },
    switchRoot: {
        margin: 0,
        height: 16,
        width: 36,
        borderRadius: 12,
    },
    switchThumb: {
        borderRadius: 12,
        height: 12,
        width: 12,
    },
    switchBase: {
        width: 36,
        transform: 'translateX(-10px)',
        '&.Mui-checked': {
            transform: 'translateX(10px)',
        },
    },
}));

const DesignQcRubricSidebarSubCategoryEntry = React.forwardRef<
    HTMLDivElement,
    {
        entry: DesignQcRubricSubCategoryEntry;
        isSelected: boolean;
    }
>(({ entry, isSelected }, ref) => {
    const classes = useStyles();
    const state = useDesignQcPropSelector(['results']);
    const result = getQcRubricEntryResult(state, entry);
    const setNavigationAction = useDesignQcAction('SET_RUBRIC_NAVIGATION');

    const [modalOpen, setModalOpen] = React.useState<boolean>(false);

    const [IconElement, iconColor] =
        result?.result.type === 'bad'
            ? [XIcon, FlossPalette.ATTENTION_FOREGROUND]
            : [CheckIcon, FlossPalette.PRIMARY_FOREGROUND];

    return (
        <Grid
            item
            container
            direction={'row'}
            wrap={'nowrap'}
            alignItems={'center'}
            className={clsx(classes.entryRoot, isSelected ? classes.entryRootSelected : undefined)}
            onClick={() => {
                if (!isSelected) {
                    setNavigationAction({
                        entry,
                        category: entry.category,
                        currentTime: Date.now(),
                        autoScroll: false,
                    });
                }
            }}
            ref={ref}
        >
            <Grid
                item
                className={classes.entryIconWrapper}
                style={{ visibility: result ? undefined : 'hidden' }}
                onClick={() => setModalOpen(true)}
            >
                <IconElement style={{ color: iconColor }} />
            </Grid>
            <Grid item className={classes.entryName}>
                <Text variant={'body2'} medium>
                    {entry.name}
                </Text>
            </Grid>
            <DesignQcSubcategoryModal entry={entry} open={modalOpen} setOpen={setModalOpen} />
            <Grid item className={classes.entryIconWrapper} onClick={() => setModalOpen(true)}>
                <InfoIcon style={{ color: FlossPalette.BLACK, cursor: 'pointer' }} />
            </Grid>
        </Grid>
    );
});

interface DesignQcRubricSidebarProps {
    onClose: () => void;
    closeGuidedQcAndRefetch: () => Promise<void>;
    arePresetViewsEnabled: boolean;
    handlePresetViewsEnabledChanged: (enabled: boolean) => void;
}

export const DesignQcRubricSidebar: React.VFC<DesignQcRubricSidebarProps> = ({
    onClose,
    closeGuidedQcAndRefetch,
    arePresetViewsEnabled,
    handlePresetViewsEnabledChanged,
}) => {
    const classes = useStyles();
    const { rubricNavigation, config } = useDesignQcPropSelector(['rubricNavigation', 'config']);
    const setNavigationAction = useDesignQcAction('SET_RUBRIC_NAVIGATION');

    const [isSubmissionFlowOpen, setIsSubmissionFlowOpen] = React.useState<boolean>(false);
    const canSubmit = useCanSubmit();

    const { previousEntry, nextEntry } = React.useMemo(() => {
        return {
            previousEntry: getRelativeEntry({ config, rubricNavigation }, -1),
            nextEntry: getRelativeEntry({ config, rubricNavigation }, 1),
        };
    }, [config, rubricNavigation]);

    // entryElements keeps a reference to the <div> for each rubric criterion so
    // we can automatically scroll through them as the user progresses through.
    const entryElements = React.useRef<Record<string, HTMLDivElement>>({});
    React.useEffect(() => {
        if (entryElements.current && rubricNavigation && rubricNavigation.entry && rubricNavigation.autoScroll) {
            const { name, category } = rubricNavigation.entry;
            entryElements.current[`${name}:${category}`]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [rubricNavigation]);

    return (
        <Grid item container direction={'column'} wrap={'nowrap'} className={classes.rubricSidebarRoot}>
            <Grid item container className={classes.rubricSidebarHeader}>
                <Grid item style={{ marginTop: 1, cursor: 'pointer' }} onClick={onClose}>
                    <XIcon style={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
                </Grid>
                <Grid item style={{ marginLeft: 8 }}>
                    <Text variant={'h6'} medium>
                        Reviewing Design
                    </Text>
                </Grid>
                <Grid item xs />
                {canSubmit && (
                    <Grid item>
                        <DesignQcFinalReviewModal
                            open={isSubmissionFlowOpen}
                            setOpen={setIsSubmissionFlowOpen}
                            closeGuidedQcAndRefetch={closeGuidedQcAndRefetch}
                        />
                        <Button
                            variant={'primary'}
                            onClick={() => setIsSubmissionFlowOpen(true)}
                            style={{ height: 40, marginTop: -8 }}
                        >
                            Complete
                        </Button>
                    </Grid>
                )}
            </Grid>
            {/* Scrollable column with rubric */}
            <Grid item container direction={'column'} wrap={'nowrap'} style={{ overflowY: 'scroll' }}>
                {config.map(c => {
                    return (
                        <>
                            <div className={classes.categoryNameContainer}>
                                <Text variant={'body1'}>{DesignQcRubricCategoryToLabelMap[c.category]}</Text>{' '}
                            </div>
                            {c.subcategories.map(entry => (
                                <DesignQcRubricSidebarSubCategoryEntry
                                    entry={entry}
                                    key={entry.name}
                                    isSelected={
                                        rubricNavigation?.entry?.name === entry.name &&
                                        rubricNavigation?.entry?.category === entry.category
                                    }
                                    ref={el => {
                                        if (el) {
                                            entryElements.current[`${entry.name}:${entry.category}`] = el;
                                        }
                                    }}
                                />
                            ))}
                        </>
                    );
                })}
            </Grid>
            <Grid item xs />
            <Grid item className={classes.rubricSidebarBottomWrapper} container direction={'column'}>
                <Grid item className={classes.rubricSidebarBottomDesignStandard}>
                    <DesignStandardModalWithButton />
                </Grid>
                <Grid item container direction={'row'}>
                    <Grid item>
                        <Text variant={'body2'} medium>
                            Toggle views
                        </Text>
                    </Grid>
                    <Grid item xs />
                    <Grid item>
                        <Switch
                            color={'secondary'}
                            size={'medium'}
                            checked={arePresetViewsEnabled}
                            onChange={() => {
                                handlePresetViewsEnabledChanged(!arePresetViewsEnabled);
                            }}
                            classes={{
                                root: classes.switchRoot,
                                thumb: classes.switchThumb,
                                switchBase: classes.switchBase,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Text variant={'caption'} color={'GRAY'}>
                        This will cause the model viewer to change its position as you switch between categories &
                        subcategories.
                    </Text>
                </Grid>
                <Grid
                    item
                    container
                    direction={'row'}
                    spacing={2}
                    className={classes.rubricSidebarBottomNavigationButtons}
                >
                    <Grid item xs>
                        <Button
                            variant={'secondary'}
                            startIcon={'ChevronLeft'}
                            fullWidth
                            disabled={!previousEntry}
                            onClick={() => {
                                if (previousEntry) {
                                    setNavigationAction({
                                        category: previousEntry.category,
                                        entry: previousEntry,
                                        currentTime: Date.now(),
                                        autoScroll: true,
                                    });
                                }
                            }}
                        >
                            Previous
                        </Button>
                    </Grid>
                    <Grid item xs>
                        <Button
                            variant={'secondary'}
                            endIcon={'ChevronRight'}
                            fullWidth
                            disabled={!nextEntry}
                            onClick={() => {
                                if (nextEntry) {
                                    setNavigationAction({
                                        category: nextEntry.category,
                                        entry: nextEntry,
                                        currentTime: Date.now(),
                                        autoScroll: true,
                                    });
                                }
                            }}
                        >
                            Next
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
