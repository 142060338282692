import { YAxis } from '../../utils';
import { ScanReviewEditedMarginLine, ScanReviewMarginLineEditor } from './MarginMarking';
import { useScanReviewRecordsFactory, useCompositeViewManager } from './ScanReview.hooks';
import type { ScanReviewRecordFactory } from './ScanReviewRecordTypes';
import {
    ScanReviewCompositeScene,
    type ScanReviewSceneState,
    ScanReviewSceneStateManager,
} from './ScanReviewSceneTypes';
import {
    type ScanReviewViewState,
    ScanReviewAppearanceManager,
    ScanReviewDisplayType,
    INITIAL_SCAN_REVIEW_BITE_ANALYSIS_HEATMAP_RANGE,
    INITIAL_SCAN_REVIEW_UNDERCUT_HEATMAP_RANGE,
    ScanReviewMode,
    ScanReviewViewType,
    type ScanReviewSceneAppearance,
    ScanReviewPanelType,
} from './ScanReviewViewTypes';
import { AllToothNumbers, type ToothNumber } from '@orthly/items';
import { Jaw } from '@orthly/shared-types';
import constate from 'constate';
import React from 'react';
import type * as THREE from 'three';

interface ScanReviewCompleteViewAppProps {
    lowerJawFactory: ScanReviewRecordFactory | null;
    upperJawFactory: ScanReviewRecordFactory | null;
    viewState: ScanReviewViewState;
    upperJawInsertionAxis?: THREE.Vector3;
    lowerJawInsertionAxis?: THREE.Vector3;
    initialToothNumber?: ToothNumber;
    marginLines?: ScanReviewEditedMarginLine[];
}

export const DEFAULT_UPPER_JAW_INSERTION_AXIS = YAxis.clone();
export const DEFAULT_LOWER_JAW_INSERTION_AXIS = YAxis.clone().negate();

function useScanReviewCompleteViewApp({
    lowerJawFactory,
    upperJawFactory,
    viewState,
    upperJawInsertionAxis,
    lowerJawInsertionAxis,
    initialToothNumber,
    marginLines,
}: ScanReviewCompleteViewAppProps) {
    const scanRecordsFactory = useScanReviewRecordsFactory(lowerJawFactory, upperJawFactory);
    const scene = React.useMemo(() => {
        return new ScanReviewCompositeScene(scanRecordsFactory);
    }, [scanRecordsFactory]);

    const preppedTeeth = React.useMemo(() => {
        const defaultPrep: [ToothNumber, boolean][] = AllToothNumbers.map(unn => [unn as ToothNumber, false]);
        const existingPrep: [ToothNumber, boolean][] = marginLines ? marginLines.map(m => [m.toothNumber, true]) : [];
        return initialToothNumber
            ? new Map(defaultPrep.concat(existingPrep)).set(initialToothNumber, true)
            : new Map(defaultPrep.concat(existingPrep));
    }, [initialToothNumber, marginLines]);

    const marginLineEditors = React.useMemo(() => {
        const defaultEditors: [ToothNumber, ScanReviewMarginLineEditor][] = AllToothNumbers.map(unn => [
            unn,
            new ScanReviewMarginLineEditor(new ScanReviewEditedMarginLine(unn, [], false)),
        ]);
        const existingEditors: [ToothNumber, ScanReviewMarginLineEditor][] = marginLines
            ? marginLines.map(m => [m.toothNumber, new ScanReviewMarginLineEditor(m)])
            : [];
        return new Map(defaultEditors.concat(existingEditors));
    }, [marginLines]);

    const defaultSceneState = React.useMemo(() => {
        return {
            toothNumber: initialToothNumber,
            upperJawInsertionAxis: upperJawInsertionAxis ?? DEFAULT_UPPER_JAW_INSERTION_AXIS,
            lowerJawInsertionAxis: lowerJawInsertionAxis ?? DEFAULT_LOWER_JAW_INSERTION_AXIS,
            preppedTeeth,
            currentMarginLine: initialToothNumber
                ? marginLineEditors.get(initialToothNumber)?.currentEditedMarginLine
                : undefined,
            currentMarginLineEditor: initialToothNumber ? marginLineEditors.get(initialToothNumber) : undefined,
        };
    }, [initialToothNumber, lowerJawInsertionAxis, marginLineEditors, preppedTeeth, upperJawInsertionAxis]);

    // The scene state manager is responsible for tracking all scene state related to different tools/modes/etc.
    const [sceneState, setSceneState] = React.useState<ScanReviewSceneState>(defaultSceneState);
    const sceneStateManager = React.useMemo(
        () => new ScanReviewSceneStateManager(defaultSceneState, setSceneState, marginLineEditors),
        [defaultSceneState, marginLineEditors],
    );

    // The appearance manager is responsible for synchronizing and managing the following aspects of a managed scene:
    // 1. The current mode of the scene (scan review, margin marking, or undercut)
    // 2. The current view type (complete, side by side, single)
    // 3. The current display type (scan review, stone model, bite analysis, etc.).
    // 4. Upper/Lower jaw visibility
    // 5. Current heatmap settings.
    // Managing this is not simply a matter of aggregating a bunch of unrelated state variables since each of these
    // interacts in subtle ways. For instance upper/lower jaw visibility may be exclusive based on mode, and setting
    // display type may implicitly change the mode.
    //
    // useScanReviewCompleteViewAppContext contains reactive state and related callbacks that binds to the appearance manager.
    const defaultAppearance = React.useMemo(() => {
        return {
            mode: ScanReviewMode.Review,
            displayType: ScanReviewDisplayType.Scan,
            viewType: ScanReviewViewType.Complete,
            heatmapRange: INITIAL_SCAN_REVIEW_BITE_ANALYSIS_HEATMAP_RANGE,
            undercutHeatmapRange: INITIAL_SCAN_REVIEW_UNDERCUT_HEATMAP_RANGE,
            upperJawVisible: true,
            lowerJawVisible: true,
            isScanOrScanUndercutDisplay: true,
            isStoneModelOrStoneModelUndercutDisplay: false,
            upperJawInScene: scene.upperJawInScene,
            lowerJawInScene: scene.lowerJawInScene,
        };
    }, [scene.lowerJawInScene, scene.upperJawInScene]);
    const [appearance, setAppearance] = React.useState<ScanReviewSceneAppearance>(defaultAppearance);
    const appearanceManager = React.useMemo(
        () => new ScanReviewAppearanceManager(scene, setAppearance, defaultAppearance),
        [defaultAppearance, scene],
    );

    // The view manager is responsible for the following:
    // 1. Tracking references to HTML canvas elements for all view panels
    // 2. Tracking references to THREE.JS camera objects for all view panels
    // 3. Tracking references to React Three Fiber camera controls for all view panels
    // 4. Adding/removing event listeners to the camera controls associated with a view panel to persist/restore view-state
    //    between component mount/unmount
    const viewManager = useCompositeViewManager(scene, viewState);

    const activeJawData = React.useMemo(() => {
        const isolatedScene = scene.getPartialSceneForPanelType(ScanReviewPanelType.Isolated);
        if (appearance.upperJawInScene && appearance.upperJawVisible && isolatedScene.upperJaw?.scanMesh) {
            return {
                jaw: Jaw.UPPER,
                scanMesh: isolatedScene.upperJaw.scanMesh,
                insertionAxis: sceneState.upperJawInsertionAxis,
            };
        }
        if (appearance.lowerJawInScene && appearance.lowerJawVisible && isolatedScene.lowerJaw?.scanMesh.visible) {
            return {
                jaw: Jaw.LOWER,
                scanMesh: isolatedScene.lowerJaw.scanMesh,
                insertionAxis: sceneState.lowerJawInsertionAxis,
            };
        }
    }, [
        appearance.lowerJawInScene,
        appearance.lowerJawVisible,
        appearance.upperJawInScene,
        appearance.upperJawVisible,
        scene,
        sceneState.lowerJawInsertionAxis,
        sceneState.upperJawInsertionAxis,
    ]);

    return {
        scene,
        sceneState,
        sceneStateManager,
        viewState,
        viewManager,
        appearance,
        appearanceManager,
        activeJawData,
    };
}

export const [ScanReviewCompleteViewAppProvider, useScanReviewCompleteViewAppContext] =
    constate(useScanReviewCompleteViewApp);
