import { ScanReviewPanel } from '../ScanReviewPanel';
import { type ScanReviewViewJawDisplayState } from '../ScanReviewViewTypes';
import { useToothPlacementVisualizationViewContext } from './ToothPlacementVisualizationView.hooks';
import React from 'react';

export const ToothPlacementVisualizationView: React.VFC<ScanReviewViewJawDisplayState> = ({ upper, lower }) => {
    const ctx = useToothPlacementVisualizationViewContext();
    const viewManager = ctx.picker.viewManager;
    React.useEffect(() => {
        ctx.picker.setUpperJawVisibility(upper);
        ctx.picker.setLowerJawVisibility(lower);
    }, [ctx.picker, upper, lower]);

    return <ScanReviewPanel viewManager={viewManager} />;
};
