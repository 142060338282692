import { LabsGqlDesignOrderNoteCategory } from '@orthly/graphql-schema';

export const isFacialAnatomyOptionDisabled = (
    option: LabsGqlDesignOrderNoteCategory,
    selectionsContain: (options: LabsGqlDesignOrderNoteCategory[]) => boolean,
): boolean => {
    switch (option) {
        case LabsGqlDesignOrderNoteCategory.FacialMakeMorePronounced:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.FacialMakeLessPronounced]);

        case LabsGqlDesignOrderNoteCategory.FacialMakeLessPronounced:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.FacialMakeMorePronounced]);

        default:
            return false;
    }
};
