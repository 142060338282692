import { OrderListItem } from '../../../../components/OrderListItem/OrderListItem';
import { ORDER_LIST_ITEM_HEIGHT } from '../../../../components/OrderListItem/OrderListItemLayout';
import { OrdersListToolbar } from '../../../../components/OrdersListToolbar/OrdersListToolbar';
import { UNSAVED_VIEW_ID } from '../../../../redux/utils/SavedViewActionConstants';
import { usePartnerAndLabNames } from '../../../../utils/usePartnerAndLabNames';
import { useOrdersListViewIds } from '../../state/OrdersListViewIdsProvider';
import { useOrdersSearchQueryDisabled, useOrdersOverviewState } from '../../state/OrdersOverview.selectors';
import type { ApolloError } from '@apollo/client';
import type { RequireChildrenProps } from '@orthly/ui';
import { LoadBlocker } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import {
    useVirtualizedListOrders,
    OrdersVirtualList,
    useOrderToOpsListItemContent,
    OrderListNoResultsItem,
} from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

interface OrdersListLayoutProps extends RequireChildrenProps {
    idsLoading: boolean;
    idsCount: number;
    error?: ApolloError;
}

const OrdersListLayout: React.FC<OrdersListLayoutProps> = props => {
    const { idsCount, idsLoading, error } = props;
    const searchQueryDisabled = useOrdersSearchQueryDisabled();
    const showToolbarLoader = idsLoading || searchQueryDisabled;
    // show overlay only when loading new ids list
    const loadBlockerEnabled = showToolbarLoader && idsCount === 0;
    const noOrdersMessage = useOrdersOverviewState(s => {
        if (s.screen === 'custom' && !!s.view) {
            return s.view.id === UNSAVED_VIEW_ID
                ? `No orders found for unsaved query`
                : `No orders found for saved view ${s.view.title}`;
        }
        return `No orders with status ${_.startCase(s.screen)}`;
    });
    return (
        <Grid container style={{ height: '100vh', overflow: 'auto', alignContent: 'flex-start' }}>
            <OrdersListToolbar loading={showToolbarLoader} mode={'orders'} />
            <LoadBlocker loader={'dandy'} blocking={loadBlockerEnabled}>
                {idsCount === 0 && !idsLoading && !error && <OrderListNoResultsItem message={noOrdersMessage} />}
                {error && <OrderListNoResultsItem message={`Error: ${error.message}`} variant={'error'} />}
                {props.children}
            </LoadBlocker>
        </Grid>
    );
};

export const OrdersListView: React.FC = () => {
    const { ids: orderIds, loading, error } = useOrdersListViewIds();
    const { labNamesById, partnerNamesById } = usePartnerAndLabNames();
    const toListItemContent = useOrderToOpsListItemContent(labNamesById, partnerNamesById);
    const { idsLoading, onItemsRendered, orders, listHeight, ids, startIndex } = useVirtualizedListOrders({
        orderIds,
        itemHeight: ORDER_LIST_ITEM_HEIGHT,
        idsLoading: loading,
        orderToListItemContent: toListItemContent,
    });
    return (
        <OrdersListLayout idsCount={ids.length} idsLoading={idsLoading} error={error}>
            <OrdersVirtualList
                listItemHeight={ORDER_LIST_ITEM_HEIGHT}
                ListItem={OrderListItem}
                idsLoading={idsLoading}
                onItemsRendered={onItemsRendered}
                orders={orders}
                listHeight={listHeight}
                ids={ids}
                startIndex={startIndex}
                orderToListItemContent={toListItemContent}
            />
        </OrdersListLayout>
    );
};
