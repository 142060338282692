import { ItemEditorV2SectionWrapper } from '../../components/ItemEditorV2SectionWrapper';
import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { CartItemV2Utils } from '@orthly/items';
import { SimpleInput } from '@orthly/ui';
import { FlossPalette, Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface ItemEditorV2NotesSectionProps extends Omit<ItemEditorV2BaseProps, 'showError'> {
    autoFocus?: boolean;
}

export const ItemEditorV2NotesSection: React.VFC<ItemEditorV2NotesSectionProps> = ({ item, updateItem, autoFocus }) => {
    const itemDisplayName = CartItemV2Utils.getFullDisplayName(item);
    const textInputRef = React.useRef<HTMLInputElement>(null);
    React.useLayoutEffect(() => {
        if (autoFocus) {
            setTimeout(() => {
                textInputRef.current?.scrollIntoView();
                textInputRef.current?.focus();
            }, 500);
        }
    }, [autoFocus, item.id]);

    return (
        <ItemEditorV2SectionWrapper title={`${itemDisplayName} instructions`}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SimpleInput
                        flossInputConfig={{ backgroundColor: 'gray' }}
                        TextFieldProps={{
                            inputProps: {
                                maxLength: 250,
                                ref: textInputRef,
                                autoFocus: autoFocus,
                            },
                            InputProps: {
                                endAdornment: (
                                    <Text variant={'body2'} style={{ marginRight: 8, color: FlossPalette.GRAY }}>
                                        {item.item_notes?.length ?? 0}/250
                                    </Text>
                                ),
                            },
                        }}
                        label={''}
                        fullWidth
                        placeholder={`${itemDisplayName} instructions will appear directly on the Rx. Please feel free to include additional instructions.`}
                        onChange={value => updateItem({ name: 'item_notes', payload: value })}
                        value={item.item_notes ?? undefined}
                    />
                </Grid>
            </Grid>
        </ItemEditorV2SectionWrapper>
    );
};
