import { useNavItemRootStyles } from '../../../components/CustomViewListItem';
import { TicketSavedSearchEndAction } from './ticket_saved_searches/TicketSavedSearchEndAction';
import { TicketSavedSearchStartAction } from './ticket_saved_searches/TicketSavedSearchStartAction';
import { TicketSavedSearchAutocomplete } from './ticket_saved_searches/TicketSavedSearchesAutoComplete';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { useGetTicketSavedSearchesQuery, useFavoriteTicketSavedSearchMutation } from '@orthly/graphql-react';
import type { LabsGqlTicketsFilters } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import type { SubNavigationEntry } from '@orthly/ui';
import { SubNavigation } from '@orthly/ui';
import { stylesFactory, IconButton, RefreshIcon } from '@orthly/ui-primitives';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        height: '100vh',
        position: 'sticky',
        top: 0,
    },
}));

const isDefaultSearch = (search: LabsGqlTicketsFilters): boolean =>
    _.isEqual(
        Object.entries(search).map(([k, _v]) => k),
        ['is_open'],
    );

function trackSavedSearchSelected(searchId: string) {
    BrowserAnalyticsClientFactory.Instance?.track('Ops - Portal - Saved Search Loaded', {
        search_id: searchId,
        search_type: 'tickets',
    });
}

export const TicketSavedSearches: React.VFC<{
    refetch: () => Promise<void>;
    isLoading: boolean;
    activeSearch: LabsGqlTicketsFilters;
    setSearch: (filters: LabsGqlTicketsFilters) => void;
}> = props => {
    const classes = useNavItemRootStyles();
    const navClasses = useStyles();
    const session = useSession();
    const { data, refetch } = useGetTicketSavedSearchesQuery();
    const [favorite] = useFavoriteTicketSavedSearchMutation();
    const [filterValue, setFilterValue] = React.useState<string>('open');

    const combinedRefetch = async (): Promise<void> => {
        await Promise.all([refetch(), props.refetch()]);
    };

    const savedSearches = data?.getTicketSavedSearches ?? [];

    const mainScreenSections: SubNavigationEntry<'open' | 'closed' | 'all'>[][] = [
        [
            {
                value: 'open',
                label: 'Open Tickets',
            },
            {
                value: 'closed',
                label: 'Closed Tickets',
            },
            {
                value: 'all',
                label: 'All Tickets',
            },
        ],
    ];

    const setFavorite = async (search_id: string): Promise<void> => {
        await favorite({
            variables: {
                data: {
                    search_id,
                    user_id: session?.user.id ?? 'we hit a race condition 🤷',
                },
            },
        }).then(() => combinedRefetch());
    };

    const savedSearchesEntries: SubNavigationEntry<string>[] = savedSearches
        .filter(search => search.favorited_by.includes(session?.user.id ?? ''))
        .map(search => {
            const isInactive = search.id !== filterValue;

            return {
                value: search.id,
                label: search.name,
                classes: {
                    root: clsx(classes.root, isInactive && classes.inactiveFilterRoot),
                    textWrapper: classes.textWrapper,
                },
                StartIcon: () => <TicketSavedSearchStartAction isFavorited={true} />,
                EndAction: () => (
                    <TicketSavedSearchEndAction
                        search={{ ...search, __type: 'existing' }}
                        refetch={combinedRefetch}
                        activeSearch={props.activeSearch}
                    />
                ),
            };
        });

    if (!isDefaultSearch(props.activeSearch)) {
        savedSearchesEntries.push({
            value: 'unsaved',
            label: 'Unsaved Search',
            classes: {
                text: classes.unsavedFilterItemText,
                root: clsx(classes.root, classes.inactiveFilterRoot),
                textWrapper: classes.textWrapper,
            },
            StartIcon: () => <TicketSavedSearchStartAction isFavorited={false} />,
            EndAction: () => (
                <TicketSavedSearchEndAction
                    refetch={combinedRefetch}
                    search={{ __type: 'unsaved' }}
                    activeSearch={props.activeSearch}
                />
            ),
        });
    }

    return (
        <SubNavigation<any>
            title={'Tickets'}
            classes={{ root: navClasses.root }}
            titleAction={
                <IconButton
                    onClick={_ => {
                        void props.refetch();
                    }}
                    disabled={props.isLoading}
                    size={'small'}
                >
                    <RefreshIcon />
                </IconButton>
            }
            value={filterValue}
            onChange={change => {
                switch (change) {
                    case 'open':
                        props.setSearch({ is_open: true });
                        break;
                    case 'closed':
                        props.setSearch({ is_open: false });
                        break;
                    case 'all':
                        props.setSearch({ is_open: undefined });
                        break;
                    default:
                        const search = savedSearches.find(search => search.id === change);

                        if (search) {
                            trackSavedSearchSelected(search.id);
                            props.setSearch(search.search);
                        }
                        break;
                }
                setFilterValue(change);
            }}
            sections={[...mainScreenSections, savedSearchesEntries]}
            bottomContent={
                <TicketSavedSearchAutocomplete savedSearches={savedSearches} onSelect={id => setFavorite(id)} />
            }
        />
    );
};
