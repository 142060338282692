import type { ScanModels } from './FinishingApp.types';
import { PartialSceneAppearanceManagerBase } from './PartialSceneAppearanceManagerBase';
import type { IPartialSceneAppearanceManager, PartialSceneAppearanceSetter } from './SceneAppearanceManager.types';
import type { ISceneMaterialManager } from './SceneMaterialManager.types';
import { HeatMapType } from '@orthly/forceps';
import type { ToothNumber } from '@orthly/items';
import { ToothUtils } from '@orthly/items';
import type * as THREE from 'three';

export interface IProximalSceneAppearanceManager extends IPartialSceneAppearanceManager {
    toggleUndercutHeatmapEnabled(enabled?: boolean): void;
    toggleProximalHeatmapEnabled(enabled?: boolean): void;
    toggleCurtainsVisibility(): void;
}

// Manages the scene of a proximal contact (mesial or distal) review panel
export class ProximalSceneAppearanceManager
    extends PartialSceneAppearanceManagerBase
    implements IProximalSceneAppearanceManager
{
    constructor(
        setAppearance: PartialSceneAppearanceSetter,
        restoratives: Map<ToothNumber, THREE.BufferGeometry>,
        curtains: Map<ToothNumber, THREE.BufferGeometry>,
        scanModels: ScanModels,
        collisions: THREE.Mesh[],
        materialManager: ISceneMaterialManager,
    ) {
        super(setAppearance, materialManager, restoratives, scanModels, collisions, new Map(), {}, curtains);

        if (this.scans) {
            this.scans.upperJaw.visible = Array.from(restoratives.keys()).some(ToothUtils.toothIsUpper);
            this.scans.lowerJaw.visible = Array.from(restoratives.keys()).some(ToothUtils.toothIsLower);
        }

        this.toggleProximalHeatmapEnabled(true);
    }

    toggleUndercutHeatmapEnabled(enabled?: boolean): void {
        this.toggleHeatMap(HeatMapType.Undercut, enabled);
    }

    toggleProximalHeatmapEnabled(enabled?: boolean): void {
        this.toggleHeatMap(HeatMapType.Proximal, enabled);
    }

    toggleCurtainsVisibility(): void {
        const nextVisible = !this.areCurtainsVisible;

        this.curtains.forEach(el => {
            el.visible = nextVisible;
        });

        this.updateAppearanceState();
    }
}
