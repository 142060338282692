import type { ToolbarActionProps } from '../OrderDetailToolbar/OrderDetailToolbarActions/ToolbarActionDefinition';
import { ScanPane } from './ResolveScanRejectionScanPane';
import type { LabsGqlWorkflowTask_ResolveScanRejectionWorkflowTask_Fragment as ResolveScanRejectionWorkflowTask } from '@orthly/graphql-operations';
import { RootActionDialog, makeLazyDialog } from '@orthly/ui';
import { Grid, Text } from '@orthly/ui-primitives';

interface ResolveScanRejectionDialogProps extends ToolbarActionProps {
    task: ResolveScanRejectionWorkflowTask;
    uploadNewScanUnchecked?: boolean;
}

export const useResolveScanRejectionDialog = makeLazyDialog<Omit<ResolveScanRejectionDialogProps, 'open' | 'setOpen'>>(
    props => {
        const { order, open, setOpen } = props;

        return (
            <RootActionDialog
                title={<Text variant={'h6'}>Fix Flagged Scan</Text>}
                loading={false}
                open={open}
                setOpen={setOpen}
                content={
                    <Grid container>
                        <ScanPane
                            order={order}
                            afterSubmit={() => {
                                void props.refetchOrder();
                                setOpen(false);
                            }}
                        />
                    </Grid>
                }
                buttonText={'Fix flagged scan'}
                CustomButton={props.CustomButton}
            />
        );
    },
);
