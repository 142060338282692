import type { SimpleAutocompleteOption, SimpleAutocompleteProps } from '../SimpleForm';
import { SimpleAutocomplete } from '../SimpleForm';
import type { FieldDefCustom, FieldLayout } from './QuickForm.types';
import { DefaultValidationMap } from './QuickForm.utils';
import type { FieldProps as FormikFieldCompProps, FormikProps } from 'formik';
import _ from 'lodash';
import React from 'react';
import type { PropTypes as GPlacesAutocompleteProps } from 'react-places-autocomplete';
import GPlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';

export type QFAutocompleteAddress = {
    street_one: string;
    street_two?: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;
};

async function geocodeToAddress(placeId: string): Promise<Partial<QFAutocompleteAddress> | undefined> {
    const result: google.maps.GeocoderResult | undefined = (await geocodeByPlaceId(placeId))[0];
    if (!result) {
        return undefined;
    }
    const elements = result.address_components;
    const number = elements.find(el => el.types.indexOf('street_number') >= 0);
    const street = elements.find(el => el.types.indexOf('route') >= 0);
    const streetOne = `${number ? number.long_name : ''} ${street ? street.long_name : ''}`;
    const city = elements.find(el => el.types.indexOf('locality') >= 0);
    const country = elements.find(el => el.types.indexOf('country') >= 0);
    const state = elements.find(
        el => el.types.indexOf('administrative_area_level_1') >= 0 && el.types.indexOf('political') >= 0,
    );
    const zip = elements.find(el => el.types.indexOf('postal_code') >= 0);
    return {
        street_one: streetOne,
        city: city ? city.long_name : '',
        state: state ? state.short_name : '',
        postal_code: zip ? zip.short_name : '',
        country: country ? country.short_name : '',
    };
}

type GPlacesRenderProps = Parameters<GPlacesAutocompleteProps['children']>[0];

type QFAddressAutocompleteFieldChildProps = { suggestOpts: GPlacesRenderProps } & FormikFieldCompProps &
    QFAutoAddressProps;

const QFAddressAutocompleteFieldChild: React.FC<QFAddressAutocompleteFieldChildProps> = props => {
    const { suggestOpts, onSelect } = props;
    const fieldValue = typeof props.field.value === 'string' ? props.field.value : undefined;
    const { getInputProps, suggestions, loading } = suggestOpts;
    const inputProps = getInputProps();
    const autocompleteOptions = suggestions.map<SimpleAutocompleteOption>(suggest => {
        const main: string = suggest.formattedSuggestion.mainText;
        const secondary: string = suggest.formattedSuggestion.secondaryText.replace(', USA', '');
        return { label: `${main}, ${secondary}`, value: suggest.placeId };
    });

    const errorText = React.useMemo(() => {
        const error = _.get<{ [k: string]: string | undefined | object }, string>(props.form.errors, props.field.name);
        const touched = props.form.touched[props.field.name] === true;
        return touched && typeof error === 'string' ? error : undefined;
    }, [props.field.name, props.form.errors, props.form.touched]);

    const onSuggestionSelect = React.useCallback(
        async (placeId: string) => {
            const result = await geocodeToAddress(placeId);
            if (result) {
                result.street_one && props.form.setFieldValue(props.field.name, result.street_one);
                onSelect(result, props.form);
            }
        },
        [onSelect, props.field.name, props.form],
    );
    return (
        <SimpleAutocomplete
            label={props.label ?? _.startCase(props.field.name)}
            options={autocompleteOptions}
            onChange={value => {
                const matchingSuggestion = suggestions.find(s => s.placeId === value);
                if (matchingSuggestion) {
                    onSuggestionSelect(matchingSuggestion.placeId).catch(console.error);
                }
            }}
            error={errorText}
            helperText={props.helperText}
            variant={props.variant ?? 'standard'}
            AutocompleteProps={{
                loading,
                loadingText: 'Loading suggestions',
                freeSolo: true,
                style: { width: '100%' },
                blurOnSelect: true,
                disableClearable: true,
            }}
            TextFieldProps={{
                fullWidth: true,
                placeholder: props.placeholder ?? 'Type to search for address',
                autoFocus: true,
                inputProps: { value: fieldValue ?? '', autoComplete: 'new-password' }, // Because fuck chrome autocomplete
                InputProps: { disableUnderline: true },
                style: props.style,
                onChange: event => {
                    inputProps.onChange(event);
                    props.form.setFieldValue(props.field.name, event.target.value);
                },
                onBlur: event => {
                    props.form.setFieldTouched(props.field.name);
                    inputProps.onBlur(event);
                    props.field.onBlur(event);
                },
                'data-test': `quick-form-field-${props.field.name}`,
            }}
        />
    );
};

export interface QFAutoAddressProps {
    label: string;
    onSelect: (addr: Partial<QFAutocompleteAddress>, form: FormikProps<Partial<QFAutocompleteAddress>>) => void;
    placeholder?: string;
    helperText?: string;
    style?: React.CSSProperties;
    variant?: SimpleAutocompleteProps['variant'];
    layout?: FieldLayout;
}

export const QFAddressAutocompleteField: React.FC<FormikFieldCompProps & QFAutoAddressProps> = props => {
    const fieldValue = typeof props.field.value === 'string' ? props.field.value : undefined;
    return (
        <GPlacesAutocomplete
            value={fieldValue}
            onChange={() => {}}
            onSelect={() => {}}
            searchOptions={{ types: ['address'] }}
            shouldFetchSuggestions={!!fieldValue && fieldValue.length > 3}
            // eslint-disable-next-line react/no-children-prop
            children={opts => <QFAddressAutocompleteFieldChild {...props} suggestOpts={opts} />}
        />
    );
};

export function useQFAddressAutocompleteFieldDef(fieldProps: QFAutoAddressProps) {
    return React.useMemo<FieldDefCustom<QFAutoAddressProps>>(() => {
        return {
            fieldProps,
            type: 'custom',
            component: QFAddressAutocompleteField,
            validation: DefaultValidationMap.text.min(4),
            hideErrorMessage: true,
            layout: fieldProps.layout,
        };
    }, [fieldProps]);
}
