import { LoadBlocker } from '@orthly/ui';
import type { ButtonProps } from '@orthly/ui-primitives';
import { Button } from '@orthly/ui-primitives';
import copy from 'clipboard-copy';
import { useSnackbar } from 'notistack';
import React from 'react';

export enum CopyButtonState {
    Ready = `Ready`,
    Copying = `Copying`,
    Copied = `Copied`,
}

interface CopyToClipboardButtonProps {
    state: CopyButtonState;
    onClick: () => void;
}

type CopyButtonLabelMap = Record<CopyButtonState, string>;
const defaultCopyButtonLabelMap: CopyButtonLabelMap = {
    [CopyButtonState.Copying]: `Copying`,
    [CopyButtonState.Copied]: `Copied`,
    [CopyButtonState.Ready]: `Copy`,
};

type CopyButtonIconMap = Partial<Record<CopyButtonState, React.ComponentType>>;
interface DefaultCopyButtonProps {
    ButtonProps?: Partial<Omit<ButtonProps, 'onClick' | 'children' | 'startIcon'>>;
    labels?: Partial<Record<CopyButtonState, string>>;
    startIcons?: CopyButtonIconMap;
}

const CopyToClipboardDefaultButton: React.VFC<DefaultCopyButtonProps & CopyToClipboardButtonProps> = props => {
    const { onClick, state, ButtonProps, labels } = props;
    const labelMap: CopyButtonLabelMap = { ...defaultCopyButtonLabelMap, ...labels };
    return (
        <LoadBlocker blocking={state === CopyButtonState.Copying}>
            <Button disabled={state !== CopyButtonState.Ready} variant={`ghost`} {...ButtonProps} onClick={onClick}>
                {labelMap[state]}
            </Button>
        </LoadBlocker>
    );
};

type MaybePromise<T> = Promise<T> | T;

type CopyToClipboardButtonProp = { Button: React.ComponentType<CopyToClipboardButtonProps> } | DefaultCopyButtonProps;

type CopyToClipboardProps = CopyToClipboardButtonProp & {
    // Give multiple options for source, in most cases the user
    source: (() => MaybePromise<string | null | undefined>) | string;
    // Delay before the copy button can be clicked again (many users may just want you to be able to copy as much as possible)
    debounceRecopyMs?: number;
};

export const CopyToClipboard: React.VFC<CopyToClipboardProps> = props => {
    const { source, debounceRecopyMs, ...defaultButtonProps } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [copyingState, setCopyingState] = React.useState<CopyButtonState>(CopyButtonState.Ready);
    const onClickCopy = React.useCallback(async () => {
        try {
            if (copyingState !== CopyButtonState.Ready) {
                return;
            }
            setCopyingState(CopyButtonState.Copying);
            const text = typeof source === `function` ? await source() : source;
            if (typeof text !== `string`) {
                throw new Error(`No result from source `);
            }
            await copy(text);
            setCopyingState(CopyButtonState.Copied);
            enqueueSnackbar(`Copied to clipboard!`, { variant: `success` });
            setTimeout(() => setCopyingState(CopyButtonState.Ready), debounceRecopyMs ?? 0);
        } catch (error: any) {
            console.error(error);
            setCopyingState(CopyButtonState.Ready);
            enqueueSnackbar(`Failed to copy :(`, { variant: `error` });
        }
    }, [copyingState, debounceRecopyMs, enqueueSnackbar, source]);

    if (`Button` in props) {
        const { Button } = props;
        return <Button state={copyingState} onClick={onClickCopy} />;
    }

    return <CopyToClipboardDefaultButton {...defaultButtonProps} state={copyingState} onClick={onClickCopy} />;
};
