import type { FileDrop } from '../../../../Pricing/useCsvImport';
import { useRowsFromCsv } from '../../../../Pricing/useCsvImport';
import { ListOrgSummaries_Query } from '../../../AdminBillingRoot.graphql';
import type { SetWillChargeCCFeeCsvRow, SetWillChargeCCFeeCsvRowRaw } from '../types';
import { useQuery } from '@apollo/client';
import type {
    BulkToggleWillBeChargedCcFeeMutation,
    ListPartnerBillingAccountsQuery,
} from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import type { Dictionary } from 'lodash';
import { keyBy, keys, omit } from 'lodash';
import React from 'react';

interface BulkSetWillChargeCCFeeContextType {
    inputRows: Record<string, SetWillChargeCCFeeCsvRow>;
    onDropAccepted: FileDrop;
    loading: boolean;
    options: Dictionary<SetWillChargeCCFeeCsvRow>;
    onEditRow: (partnerId: string, val: boolean) => void;
    bulkEditRows: (val: boolean) => void;
    onPracticeSelected: (partnerId: string, selected: boolean, willChargeFee: boolean) => void;
    sortColumnName: 'name' | 'selected';
    handleSubmit: (res: BulkToggleWillBeChargedCcFeeMutation) => void;
}

export const BulkSetWillChargeCCFeeContext = React.createContext<BulkSetWillChargeCCFeeContextType | null>(null);

const ListPartnerBillingAccounts_Query = graphql(`
    query listPartnerBillingAccounts($limit: Int, $offset: Int, $partnerIds: [String!]) {
        listPartnerBillingAccounts(limit: $limit, offset: $offset, partner_ids: $partnerIds) {
            id
            will_be_charged_cc_fee
        }
    }
`);

export const BulkSetWillChargeCCFeeCtxProvider: React.FC<{}> = ({ children }) => {
    // user input overrides
    const [inputRows, setInputRows] = React.useState<Record<string, SetWillChargeCCFeeCsvRow>>({});
    // the raw account data
    const [accounts, setAccounts] = React.useState<ListPartnerBillingAccountsQuery['listPartnerBillingAccounts']>([]);
    const [accountsLoading, setAccountsLoading] = React.useState(true);
    const limit = 500;
    const [offset, setOffset] = React.useState(0);
    const { data: { listOrganizationSummaries: practiceNamesRaw } = {}, loading: practiceNamesLoading } = useQuery(
        ListOrgSummaries_Query,
        { variables: { filter: { type: LabsGqlOrganizationType.Practice } } },
    );

    const {
        data: { listPartnerBillingAccounts: partnerBillingAccountsRaw } = {},
        called: accountsCalled,
        refetch: refetchAccounts,
        fetchMore: fetchMoreAccounts,
        previousData: { listPartnerBillingAccounts: previousAccounts } = {},
    } = useQuery(ListPartnerBillingAccounts_Query, { variables: { limit, offset }, fetchPolicy: 'no-cache' });

    const practiceNamesById = keyBy(practiceNamesRaw ?? [], p => p.id);
    const getPracticeNameById = React.useMemo(
        () => (id: string) => practiceNamesById[id]?.name ?? 'Failed to load practice name',
        [practiceNamesById],
    );

    React.useEffect(() => {
        if (!partnerBillingAccountsRaw || !accountsCalled || !accountsLoading) {
            return;
        }

        setAccounts(prevState => [...prevState, ...partnerBillingAccountsRaw]);

        // the first time we fetch, previousAccounts will be undefined
        // we only want to retrieve more if we've only executed the query once, or if the queries continue to yield results
        if (fetchMoreAccounts && (!previousAccounts || previousAccounts?.length > 0)) {
            setOffset(offset + limit);
            // apollo's fetchMore automatically fetches with the same variables as the initial call
            // so we only need to adjust the offset
            void fetchMoreAccounts({ variables: { offset: offset + limit } });
        } else {
            setAccountsLoading(false);
        }
    }, [
        partnerBillingAccountsRaw,
        getPracticeNameById,
        accountsCalled,
        accountsLoading,
        fetchMoreAccounts,
        offset,
        previousAccounts,
    ]);

    // options are the accounts with the user input overrides - what we display in the table
    const options = React.useMemo(() => {
        if (accountsLoading) {
            return {};
        }
        return keyBy(
            (accounts ?? []).map<SetWillChargeCCFeeCsvRow>(p => {
                const name = getPracticeNameById(p.id);
                const valueOverride = inputRows[p.id]?.will_be_charged_cc_fee ?? p.will_be_charged_cc_fee;
                const error = inputRows[p.id]?.error ?? '-';
                return { ...p, name, error, will_be_charged_cc_fee: valueOverride };
            }),
            p => p.id,
        );
    }, [accounts, accountsLoading, getPracticeNameById, inputRows]);

    const [sortColumnName, setSortColumnName] = React.useState<'name' | 'selected'>('name');

    const onDropAccepted = useRowsFromCsv<SetWillChargeCCFeeCsvRowRaw>({
        checkColumns: columns => {
            const invalid = !columns.includes('partner_id') || !columns.includes('will_be_charged_cc_fee');
            invalid && window.alert('Columns must be named partner_id and will_be_charged_cc_fee!');
            return !invalid;
        },
        onValid: (rows: SetWillChargeCCFeeCsvRowRaw[]) => {
            const validRows = rows.flatMap<SetWillChargeCCFeeCsvRow>(row => {
                if (!row.partner_id) {
                    return [];
                }

                return {
                    will_be_charged_cc_fee: row.will_be_charged_cc_fee.toLocaleLowerCase() === 'true',
                    id: row.partner_id,
                };
            });
            setInputRows(keyBy(validRows, r => r.id));
            setSortColumnName('selected');
        },
    });

    const onPracticeSelected = (partnerId: string, selected: boolean, willChargeFee: boolean) => {
        setInputRows(prevState => {
            if (selected) {
                return { ...prevState, [partnerId]: { id: partnerId, will_be_charged_cc_fee: willChargeFee } };
            } else {
                return omit(prevState, partnerId);
            }
        });
    };

    const onEditRow = (partnerId: string, val: boolean) => {
        setInputRows(prevState => {
            const currentRow = prevState[partnerId] ?? options[partnerId];

            return currentRow
                ? { ...prevState, [partnerId]: { ...currentRow, will_be_charged_cc_fee: val } }
                : prevState;
        });
    };

    const bulkEditRows = (val: boolean) => {
        setInputRows(prevState => {
            return keys(prevState).reduce((acc, key) => {
                return { ...acc, [key]: { ...prevState[key], will_be_charged_cc_fee: val } };
            }, {});
        });
    };

    const setErrorRows = (errors: { partner_id: string; error: string }[]) => {
        const errorsKeyedById = keyBy(errors, e => e.partner_id);
        setInputRows(prevState => {
            return keys(prevState).reduce((acc, key) => {
                if (errorsKeyedById[key]) {
                    return { ...acc, [key]: { ...prevState[key], error: errorsKeyedById[key]?.error } };
                } else {
                    return { ...acc };
                }
            }, {});
        });
    };

    // refetch updates from the server (only for the successful updates)
    // the callback ensures we reset the valid input rows (errors stay so we can see the error messages)
    const refetch = async (ids: string[], cb: () => void) => {
        const {
            data: { listPartnerBillingAccounts: updates },
        } = await refetchAccounts({ partnerIds: ids });
        setAccounts(prevState => [...prevState, ...updates]);
        cb();
    };

    const handleSubmit = (res: BulkToggleWillBeChargedCcFeeMutation) => {
        if (res.bulkToggleWillBeChargedCCFee.results.length) {
            void refetch(
                res.bulkToggleWillBeChargedCCFee.results.map(r => r.id),
                () => setErrorRows(res.bulkToggleWillBeChargedCCFee.errors ?? []),
            );
        } else {
            setErrorRows(res.bulkToggleWillBeChargedCCFee.errors ?? []);
        }
    };

    return (
        <BulkSetWillChargeCCFeeContext.Provider
            value={{
                inputRows,
                onDropAccepted,
                loading: practiceNamesLoading || accountsLoading,
                options,
                onEditRow,
                bulkEditRows,
                onPracticeSelected,
                sortColumnName,
                handleSubmit,
            }}
        >
            {children}
        </BulkSetWillChargeCCFeeContext.Provider>
    );
};

export function useBulkSetWillChargeCCFeeContext() {
    const ctx = React.useContext(BulkSetWillChargeCCFeeContext);
    if (!ctx) {
        throw new Error('useBulkSetWillChargeCCFeeContext must be used within a BulkSetWillChargeCCFeeCtxProvider');
    }

    return ctx;
}
