import { SimpleDialog } from '@orthly/ui';
import type { DialogProps } from '@orthly/ui-primitives';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

/** Standard dialog used throughout the order edit UI */
export const OrderEditDialog: React.VFC<{
    children: React.ReactNode;
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    cancelButtonTitle?: string;
    confirmButtonTitle?: string;
    disableConfirm?: boolean;
    // Dialog width. The default is `md`.
    width?: DialogProps['maxWidth'];
}> = ({ children, open, onClose, onConfirm, title, cancelButtonTitle, confirmButtonTitle, disableConfirm, width }) => {
    return (
        <SimpleDialog
            open={open}
            onClose={onClose}
            title={title}
            DialogProps={{ maxWidth: width ?? 'md', fullWidth: !!width }}
            actions={
                <div>
                    <Button variant={'secondary'} onClick={onClose} style={{ marginRight: 8 }}>
                        {cancelButtonTitle ?? `Cancel`}
                    </Button>
                    <Button variant={'primary'} onClick={onConfirm} disabled={!!disableConfirm}>
                        {confirmButtonTitle ?? `Save`}
                    </Button>
                </div>
            }
        >
            {children}
        </SimpleDialog>
    );
};
