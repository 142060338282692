import type { GetAllBillingCreditCategoriesQuery } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';

export const GetAllBillingCreditCategories_Query = graphql(`
    query GetAllBillingCreditCategories($include_archived: Boolean) {
        getAllBillingCreditCategories(include_archived: $include_archived) {
            id
            updated_at
            created_at
            name
            archived
        }
    }
`);

export type BillingCreditCategories = GetAllBillingCreditCategoriesQuery['getAllBillingCreditCategories'];
