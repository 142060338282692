import { ReviewDetailCell } from '../components/ReviewDetailCell';
import { SummaryStepResultRowValue } from '../components/SummaryStepResultRowValue';
import type { SummaryItemRow } from './types';
import { stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

interface OrderItemMultiLineRowProps {
    row: SummaryItemRow;
}

const useStyles = stylesFactory(theme => ({
    wrapper: {
        width: '100%',
    },
    stepResultRow: {
        marginBottom: 4,
        textAlign: 'right',
        width: 165,
        [theme.breakpoints.down('md')]: {
            textAlign: 'left',
            width: '100%',
        },
    },
}));

export const OrderItemMultiLineRow: React.VFC<OrderItemMultiLineRowProps> = ({ row }) => {
    const classes = useStyles();

    const { value, originalValue } = row;

    if (!Array.isArray(value) || !Array.isArray(originalValue)) {
        return null;
    }

    const keys = Array.from(new Set([...originalValue?.map(({ key }) => key), ...value.map(({ key }) => key)]));
    return (
        <div className={classes.wrapper}>
            {keys.map(title => {
                const orgValue = originalValue.find(({ key }) => key === title);
                const val = value.find(({ key }) => key === title);
                return (
                    <ReviewDetailCell
                        key={title}
                        title={title}
                        value={
                            <SummaryStepResultRowValue
                                value={val?.value ?? ''}
                                originalValue={orgValue?.value ?? ''}
                                multiline={row.multiline}
                                stepResultRowClass={classes.stepResultRow}
                            />
                        }
                    />
                );
            })}
        </div>
    );
};
