import { JsonDevTools } from '../../../../components/JsonDevTools';
import { DeleteUnusedScan } from './components/DeleteUnusedScan';
import { OpenScanSubmission } from './components/OpenScanSubmission';
import { RefreshScanImages } from './components/RefreshScanImages';
import { ScanFileDownloadLink } from './components/ScanFileDownloadLink';
import { Schedule3OxzToStl } from './components/Schedule3oxzToStl';
import { LabsUtilsBase } from '@orthly/dentin';
import type { LabsGqlScanFileFragment } from '@orthly/graphql-operations';
import { useScanFilesQuery } from '@orthly/graphql-react';
import { MUITable } from '@orthly/mui-table';
import { Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

type TouchedScan = LabsGqlScanFileFragment & { partner?: string };

const ProductCellDisplay: React.FC<TouchedScan> = row => {
    const uniqByLabel = _.uniqBy(row.restorations, r => LabsUtilsBase.labelForScanItem(r));
    return (
        <Grid container style={{ maxHeight: 50, overflowY: 'scroll' }}>
            {uniqByLabel.map(scanItem => {
                const label = LabsUtilsBase.labelForScanItem(scanItem);
                const count = row.restorations.filter(o => LabsUtilsBase.labelForScanItem(o) === label).length;
                return (
                    <div key={label}>
                        {label}
                        {count > 1 && ` (x${count})`}
                    </div>
                );
            })}
        </Grid>
    );
};

const ActionsCell: React.FC<{ scan: TouchedScan; refetch: () => Promise<any> }> = props => {
    const { scan } = props;
    return (
        <Grid container style={{ width: 'auto', minWidth: 150 }} wrap={'nowrap'} alignItems={'center'}>
            <OpenScanSubmission scan={scan} />
            <DeleteUnusedScan scan={scan} refetchScans={props.refetch} />
            <RefreshScanImages scan={scan} useIconButton={true} onComplete={props.refetch} />
            <Schedule3OxzToStl scan={scan} useIconButton={true} />
        </Grid>
    );
};

const ScanDetailPanel: React.FC<{ data: TouchedScan }> = props => {
    return (
        <JsonDevTools
            defaultExpanded
            src={props.data}
            dataType={'ScanExport'}
            title={'Scan JSON'}
            containerStyle={{ maxWidth: 'calc(100vw - 64px)', padding: 16 }}
        />
    );
};

export const UnsubmittedScansRoot: React.FC = () => {
    const { data: scansData, refetch: refetchScans, loading: scansLoading } = useScanFilesQuery();
    const touchedScans = React.useMemo(() => {
        return (scansData?.scan_files ?? []).map(sd => ({
            ...sd,
            partner: sd.scanner.owner?.name,
        }));
    }, [scansData]);
    return (
        <MUITable<TouchedScan>
            title={`Unsubmitted Scans`}
            loading={scansLoading}
            columns={[
                { name: 'Actions', render: r => <ActionsCell refetch={() => refetchScans()} scan={r} /> },
                { name: 'Partner', render: 'partner' },
                {
                    name: 'Uploaded At',
                    type: 'datetime' as 'datetime',
                    field: 'created_at',
                    render: (row: TouchedScan) => <span>{moment(row.created_at).fromNow()}</span>,
                    defaultSort: 'desc',
                },
                {
                    name: 'Patient Name',
                    field: 'patient_name',
                    render: (data: TouchedScan) =>
                        `${data.patient_first_name} ${data.patient_last_name} ${
                            data.patient_birthday ? moment(data.patient_birthday).format('(MM/DD/YYYY)') : ''
                        }`,
                },
                {
                    name: 'Quantity',
                    render: 'restorations.length',
                    type: 'numeric' as 'numeric',
                },
                {
                    name: 'Product',
                    render: (row: TouchedScan) => <ProductCellDisplay {...row} />,
                    field: row => _.uniqBy(row.restorations, (r: any) => `${r.Type}.${r.UNN}.${r.Material}`).join(' '),
                },
                { name: 'ID', render: 'id', hidden: true },
                {
                    name: 'Order Files',
                    field: 'scan_export.file_url',
                    render: (row: TouchedScan) => <ScanFileDownloadLink scan_export={row} />,
                    filter: false,
                },
            ]}
            data={touchedScans}
            DetailPanel={ScanDetailPanel}
            actions={{
                global: [
                    {
                        icon: 'refresh',
                        position: 'toolbar',
                        onClick: () => {
                            refetchScans().catch(console.error);
                        },
                        tooltip: 'Reload',
                    },
                ],
            }}
            displayOptions={{ viewColumns: true, fixedSearch: true }}
            paginationOptions={{ initialRowsPerPage: 25, rowsPerPageOptions: [25] }}
        />
    );
};
