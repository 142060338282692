import { UploadInjectionMoldFilesDialog } from '../../OrderTickets/UploadInjectionMoldFilesDialog.graphql';
import { DoctorDesignTaskAlerts } from '../DoctorDesignTaskAlerts';
import type { OrderDesignSubmissionCoreProps } from './OrderDesignSubmissionCommon.graphql';
import { CondensedActionCard } from '@orthly/veneer';
import React from 'react';

export const OrderInjectionMoldDesignTaskAlert: React.FC<OrderDesignSubmissionCoreProps> = ({
    order,
    variant,
    refetchOrder,
}) => {
    const [open, setOpen] = React.useState(false);

    const CardSubtitle = React.useCallback(() => {
        return <DoctorDesignTaskAlerts lab_slip_alerts={order.lab_slip_alerts} />;
    }, [order.lab_slip_alerts]);

    const task = order.fulfillment_workflow.active_task;
    if (!task) {
        return null;
    }

    const assignee = task?.assignee;
    const assignedUser = assignee
        ? `${assignee.assigned_user.first_name} ${assignee.assigned_user.last_name}`
        : 'Unassigned';

    return (
        <>
            <CondensedActionCard
                title={'Injection Mold Design'}
                assignee={assignedUser}
                primaryAction={{
                    endIcon: 'CopyFileIcon',
                    text: 'Upload files',
                    onClick: () => setOpen(true),
                }}
                task={task}
                subtitle={CardSubtitle}
                refetch={refetchOrder}
                variant={variant}
            />
            <UploadInjectionMoldFilesDialog orderId={order.id} open={open} setOpen={setOpen} refetch={refetchOrder} />
        </>
    );
};
