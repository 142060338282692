import type { LabsGqlPracticeDetailsFragment } from '@orthly/graphql-operations';

export enum PracticeTab {
    Info = 'Info',
    Orders = 'Orders',
    Timeline = 'Timeline',
}

export interface PalatePracticeRootProps {
    practiceData: PracticeData;
    onReset: () => void;
    loading: boolean;
}

export interface PracticeLinkProps {
    url: string;
    label: string;
    practiceId: string;
}

export interface PracticeTabsProps {
    tab: PracticeTab;
    setTab: (tab: PracticeTab) => void;
    practiceId: string;
}

export interface PracticeDetailsProps {
    practiceDetails: LabsGqlPracticeDetailsFragment;
    practiceId: string;
}

export interface PracticeLinksFooterProps extends PracticeDetailsProps {
    practiceUrl: string;
}

export interface PracticeTabsContainerProps extends PracticeDetailsProps {
    tab: PracticeTab;
    practiceUrl: string;
}

export interface PracticeTabDisplayProps extends PracticeTabsContainerProps {
    tabContainerHeight: number;
}

export type PracticeData = {
    data?: LabsGqlPracticeDetailsFragment;
    organization_id: string;
};
