import type { ActorInfoResult } from './types';
import type { LabsGqlPricingHistoryItemActorDto } from '@orthly/graphql-schema';
import { dayjsExt as dayjs } from '@orthly/runtime-utils';

export function getActorInfo(actor: LabsGqlPricingHistoryItemActorDto): ActorInfoResult {
    const { actor: actorType, actor_id, automation_id, user_display_text, user_email } = actor;

    if (actorType === 'service') {
        if (automation_id) {
            return {
                name: 'Automation',
                automationId: automation_id,
            };
        }
        return {
            name: actor_id,
        };
    }
    return {
        name: user_display_text,
        email: user_email,
    };
}

export function formatHistoryItemDate(dateString?: string) {
    return dateString ? dayjs(dateString).format('MM/DD/YY') : undefined;
}

export function formatHistoryGroupingDate(dateString: string): string {
    const providedDate = dayjs(dateString);
    const today = dayjs();
    const yesterday = dayjs().subtract(1, 'day');

    if (providedDate.isSame(today, 'day')) {
        return 'Today';
    }

    if (providedDate.isSame(yesterday, 'day')) {
        return 'Yesterday';
    }

    return providedDate.format(`ddd, MMM Do YYYY`);
}
