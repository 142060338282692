import type { BillingCreditCategories } from '../../CreditCategories/BillingCreditCategoriesQuery.graphql';
import { GetAllBillingCreditCategories_Query } from '../../CreditCategories/BillingCreditCategoriesQuery.graphql';
import type { Credit, Invoice } from '../../InvoicesTable/InvoiceTable.types';
import type { RefundCategories } from '../../RefundCategories/RefundCategoriesQuery.graphql';
import { ListRefundCategories_Query } from '../../RefundCategories/RefundCategoriesQuery.graphql';
import type { PartnerBillingOverviewData } from '../../types';
import {
    ContractOrgNeedsBillingSummaryEmail_Query,
    GetActiveContract_Query,
    GetInvoicesForOrganization_Query,
    GetNextInvoiceStatusForContractOrgs_Query,
    GetPartnerBillingOverviewData_Query,
    GetPartnerBillingOverviewDataForContract_Query,
    ListInvoiceCreditsForOrganization_Query,
} from '../BillingOverviewQueries.graphql';
import { useQuery } from '@apollo/client';
import type { GetActiveContractDataQuery } from '@orthly/graphql-inline-react';
import { LabsGqlExternalAccountRelationshipType, type LabsGqlNextInvoiceStatusSummary } from '@orthly/graphql-schema';
import { compact } from 'lodash';
import React from 'react';

interface BillingDetailsContextType {
    practice: PartnerBillingOverviewData | undefined;
    practiceLoading: boolean;
    refetchPractice: () => Promise<unknown>;
    doesNotHaveActiveContract: boolean;
    invoices: Invoice[];
    invoicesLoading: boolean;
    refetchInvoices: () => Promise<unknown>;
    credits: Credit[];
    creditsLoading: boolean;
    refetchCredits: () => Promise<unknown>;
    refetchBillingDetails: () => Promise<unknown>;
    // Multi-location
    associatedOrganizations: PartnerBillingOverviewData[];
    primaryPracticeForContract: string | undefined;
    getOrganizationFromId: (id: string) => PartnerBillingOverviewData | undefined;
    getNextInvoiceStatusForOrg: (organizationId: string) => LabsGqlNextInvoiceStatusSummary;
    getOrgNeedsBillingSummaryEmail: (organizationId: string) => boolean;
    creditCategories: BillingCreditCategories;
    refundCategories: RefundCategories;
    selectedContractId: string | null;
    setSelectedContractId: (selectedContractId: string | null) => void;
    activeContractId?: string;
    contractsLoading: boolean;
    hasAtLeastOneContract: boolean;
}

const BillingDetailsContext = React.createContext<BillingDetailsContextType | null>(null);

interface BillingDetailsCtxProviderProps {
    practiceId: string;
    contractAndAssociates?: GetActiveContractDataQuery['contractAndAssociates'];
    contractAndAssociatesLoading: boolean;
    selectedContractId: string | null;
    setSelectedContractId: (selectedContractId: string | null) => void;
}

export const BillingDetailsCtxProvider: React.FC<BillingDetailsCtxProviderProps> = ({
    practiceId,
    contractAndAssociates,
    contractAndAssociatesLoading,
    selectedContractId,
    setSelectedContractId,
    children,
}) => {
    const [practicesLoaded, setPracticesLoaded] = React.useState<boolean>(false);

    // Still used for practices which don't have contracts.
    const {
        data: { getPartnerBillingAccount, getOrganization } = {},
        refetch: refetchPractice,
        loading: practiceLoading,
    } = useQuery(GetPartnerBillingOverviewData_Query, {
        variables: { partnerId: practiceId },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        refetchWritePolicy: 'overwrite',
    });

    const {
        data: { contractAccounts } = {},
        refetch: refetchPractices,
        loading: practicesLoading,
    } = useQuery(GetPartnerBillingOverviewDataForContract_Query, {
        variables: { partnerId: practiceId, contractId: selectedContractId },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        refetchWritePolicy: 'overwrite',
        onCompleted: () => setPracticesLoaded(true),
        onError: () => setPracticesLoaded(true),
    });

    const { data: { activeContract } = {}, loading: activeContractLoading } = useQuery(GetActiveContract_Query, {
        variables: { organizationId: practiceId },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        refetchWritePolicy: 'overwrite',
    });

    let practice: PartnerBillingOverviewData | undefined;
    let associatedOrganizations: PartnerBillingOverviewData[] = [];

    associatedOrganizations = compact(
        contractAccounts?.map(account => {
            const practice = contractAndAssociates?.activeContractPractices.find(
                practice => practice.id === account.id,
            );
            if (!practice) {
                return;
            }
            return {
                ...account,
                name: practice.name,
                salesforceId: practice.salesforceId || null,
            };
        }),
    );
    practice = associatedOrganizations?.find(org => org.id === practiceId);

    if (!practice) {
        practice = getPartnerBillingAccount
            ? {
                  ...getPartnerBillingAccount,
                  name: getOrganization?.name ?? '',
                  salesforceId:
                      getOrganization?.external_accounts?.find(
                          a => a.type === LabsGqlExternalAccountRelationshipType.SalesforceAccount,
                      )?.id || null,
              }
            : undefined;

        associatedOrganizations = compact([practice]);
    }

    const getOrganizationFromId = (id: string): PartnerBillingOverviewData | undefined =>
        associatedOrganizations?.find(org => org.id === id);

    const {
        data: { invoices = [] } = {},
        loading: invoicesLoading,
        refetch: refetchRawInvoices,
    } = useQuery<{
        invoices: Invoice[];
    }>(GetInvoicesForOrganization_Query, {
        variables: { organizationId: practiceId },
    });

    const {
        data: { credits = [] } = {},
        loading: creditsLoading,
        refetch: refetchCredits,
    } = useQuery<{ credits: Credit[] }>(ListInvoiceCreditsForOrganization_Query, {
        variables: { organizationId: practiceId, includeDeleted: true },
    });

    const { data: { getAllBillingCreditCategories: creditCategories = [] } = {}, loading: creditCategoriesLoading } =
        useQuery<{
            getAllBillingCreditCategories: BillingCreditCategories;
        }>(GetAllBillingCreditCategories_Query, {
            variables: { include_archived: false },
        });

    const { data: { listRefundCategories: refundCategories = [] } = {} } = useQuery<{
        listRefundCategories: RefundCategories;
    }>(ListRefundCategories_Query, {
        variables: { includeArchived: false },
    });

    const {
        data: { nextInvoiceStatusList = [] } = {},
        loading: getNextInvoiceStatusLoading,
        refetch: refetchNextInvoiceStatus,
    } = useQuery(GetNextInvoiceStatusForContractOrgs_Query, {
        variables: { organizationId: practiceId },
    });

    const getNextInvoiceStatusForOrg = (organizationId: string): LabsGqlNextInvoiceStatusSummary => {
        const nextInvoiceStatus = nextInvoiceStatusList.find(status => status.organization_id === organizationId);
        return (
            nextInvoiceStatus?.next_invoice_status_summary ?? {
                pending_item_count: 0,
                previous_invoice_amount_cents: 0,
                will_be_invoiced: false,
            }
        );
    };

    const partnerBillingLoading = practiceLoading || getNextInvoiceStatusLoading;

    const { data: { needsBillingSummaryEmailList = [] } = {}, refetch: refetchInvoiceEmailStatus } = useQuery(
        ContractOrgNeedsBillingSummaryEmail_Query,
        {
            variables: { organizationId: practiceId },
        },
    );

    const getOrgNeedsBillingSummaryEmail = (organizationId: string): boolean => {
        const orgNeedsSummaryEmail = needsBillingSummaryEmailList.find(v => v.organizationId === organizationId);
        return orgNeedsSummaryEmail?.needsSummaryEmail ?? false;
    };

    const refetchInvoices = React.useCallback(async () => {
        await Promise.all([refetchRawInvoices(), refetchInvoiceEmailStatus(), refetchNextInvoiceStatus()]);
    }, [refetchRawInvoices, refetchInvoiceEmailStatus, refetchNextInvoiceStatus]);

    const refetchBillingDetails = React.useCallback(async () => {
        await Promise.all([refetchPractice(), refetchPractices(), refetchInvoices()]);
    }, [refetchPractice, refetchInvoices, refetchPractices]);

    const contractsLoading = activeContractLoading || contractAndAssociatesLoading;
    const loading = practicesLoading || partnerBillingLoading || contractsLoading;
    const refetch = React.useCallback(async () => {
        await Promise.all([refetchPractice(), refetchPractices()]);
    }, [refetchPractice, refetchPractices]);

    return (
        <BillingDetailsContext.Provider
            value={{
                practice,
                practiceLoading: !practicesLoaded && loading,
                refetchPractice: refetch,
                doesNotHaveActiveContract: practicesLoaded && !practicesLoading && !practice,
                invoices,
                invoicesLoading,
                refetchInvoices,
                credits,
                creditsLoading: creditsLoading || creditCategoriesLoading,
                refetchCredits,
                refetchBillingDetails,
                primaryPracticeForContract: contractAndAssociates?.primaryPracticeId,
                associatedOrganizations,
                getOrganizationFromId,
                getNextInvoiceStatusForOrg,
                getOrgNeedsBillingSummaryEmail,
                creditCategories,
                refundCategories,
                selectedContractId: selectedContractId ?? contractAndAssociates?.contract.id ?? null,
                setSelectedContractId,
                contractsLoading,
                activeContractId: activeContract?.contract.id,
                hasAtLeastOneContract: !!contractAndAssociates?.contract,
            }}
        >
            {children}
        </BillingDetailsContext.Provider>
    );
};

export function useBillingDetailsContext() {
    const ctx = React.useContext(BillingDetailsContext);
    if (!ctx) {
        throw new Error('useBillingDetailsContext must be used within a BillingDetailsCtxProvider');
    }

    return ctx;
}
