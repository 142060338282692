import { ExitGuidedReviewWarningModal } from '../components/ExitGuidedReviewWarningModal';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useWaxupIsRejected } from '../state/GuidedWaxupState';
import { ExclamationIcon } from '@orthly/ui';
import { Button, FlossPalette, Text, stylesFactory, Grid, Icon } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        alignItems: 'center',
        display: 'flex',
    },
    closeButton: {
        padding: 8,
        minWidth: 0,
    },
    iconContainer: {
        marginRight: 10,
    },
    patientName: {
        marginLeft: 12,
    },
    exclamationIcon: {
        color: FlossPalette.SECONDARY_FOREGROUND,
    },
    statusAndRevisionsContainer: {
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
    },
    patientNameAndExitContainer: {
        alignItems: 'center',
    },
    statusContainer: {
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
}));

export const CurrentWaxupToolbarDesktop: React.VFC = () => {
    const { order, patientName, onBack } = useGuidedWaxupContext();
    const classes = useStyles();
    const waxupRejected = useWaxupIsRejected();
    const statusText = waxupRejected ? 'Rejecting Design' : 'No Change Requests';

    if (!order) {
        return null;
    }

    return (
        <>
            <ExitGuidedReviewWarningModal />
            <Grid container className={classes.root}>
                <Grid item container wrap={'nowrap'} className={classes.patientNameAndExitContainer} xs={5}>
                    <Grid item>
                        <Button variant={'secondary'} className={classes.closeButton} onClick={() => onBack()}>
                            {<Icon icon={'CloseIcon'} />}
                        </Button>
                    </Grid>
                    <Grid item className={classes.patientName}>
                        <Text variant={'h6'} medium>
                            {`Reviewing ${patientName}'s Design`}
                        </Text>
                    </Grid>
                </Grid>
                <Grid item container className={classes.statusAndRevisionsContainer} xs={7}>
                    <Grid item container className={classes.statusContainer}>
                        {waxupRejected && (
                            <Grid item className={classes.iconContainer}>
                                <ExclamationIcon className={classes.exclamationIcon} />
                            </Grid>
                        )}
                        <Grid item>
                            <Text
                                variant={'body1'}
                                color={waxupRejected ? 'SECONDARY_FOREGROUND' : 'BLACK'}
                            >{`Status: ${statusText}`}</Text>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
