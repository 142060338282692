import type { DesignQcOrder, DesignQcSidebarEntry } from './DesignQC.types';
import { useGuidedQcPresetViewsEnabled } from './DesignQcCommon';
import { DesignQcDesignContent } from './DesignQcContentArea';
import { DesignQcRightSidebar } from './DesignQcRightSidebar';
import { DesignQcRubricSidebar } from './DesignQcRubricSidebar';
import type { DesignQcConfig, FilterFlags, DesignQcRubricCategoryEntry } from './configs/DesignQc.config.types';
import { generateDefaultGuidedQCRubric } from './configs/DesignQc.config.utils';
import { DesignQcReduxContext } from './state/DesignQc.context';
import { DesignQcStore } from './state/DesignQc.store';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { FragmentType, OrderDesignPreviewDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import {
    CartItemV2Utils,
    OrderItemV2Utils,
    ToothUtils,
    LabOrderItemSKUType,
    ExtraCartItemV2Utils,
} from '@orthly/items';
import { Grid, Popover, Text } from '@orthly/ui-primitives';
import React from 'react';
import { Provider } from 'react-redux';

interface DesignQcInternal {
    order: DesignQcOrder;
    designFragment: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    config: DesignQcConfig;
    sidebarEntries: DesignQcSidebarEntry[];
    refetch: () => Promise<unknown>;
    currentUserId?: string;
    hasDesignBeenRejectedPreviously?: boolean;
    open: boolean;
    setOpen: (open: boolean) => void;
    designQaEvaluationId?: string;
}

const DesignQcInternal: React.VFC<DesignQcInternal> = ({
    order,
    designFragment,
    config,
    sidebarEntries,
    refetch,
    currentUserId,
    hasDesignBeenRejectedPreviously,
    open,
    setOpen,
    designQaEvaluationId,
}) => {
    const [store] = React.useState(
        DesignQcStore({
            order,
            designFragment,
            config,
            designQaEvaluationId,
            current_user_id: currentUserId,
            has_design_previously_been_rejected: !!hasDesignBeenRejectedPreviously,
            currentNavigationStartTime: Date.now(),
        }),
    );

    React.useEffect(() => {
        if (open) {
            BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Guided QC - Opened', {
                $groups: {
                    order: order.id,
                },
            });
        } else {
            BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Guided QC - Closed', {
                $groups: {
                    order: order.id,
                },
            });
        }
    }, [order, open]);

    const [arePresetViewsEnabled, handlePresetViewsEnabledChanged] = useGuidedQcPresetViewsEnabled();

    return (
        <Provider store={store} context={DesignQcReduxContext}>
            <Popover
                anchorReference={'none'}
                open={open}
                PaperProps={{
                    style: { width: '100vw', height: '100vh', maxWidth: '100vw', maxHeight: '100vh' },
                }}
            >
                <Grid container direction={'row'} style={{ height: '100vh', width: '100vw' }}>
                    <DesignQcRubricSidebar
                        onClose={() => setOpen(false)}
                        closeGuidedQcAndRefetch={async () => {
                            await refetch();
                            setOpen(false);
                        }}
                        arePresetViewsEnabled={arePresetViewsEnabled}
                        handlePresetViewsEnabledChanged={handlePresetViewsEnabledChanged}
                    />
                    <DesignQcDesignContent arePresetViewsEnabled={arePresetViewsEnabled} />
                    <DesignQcRightSidebar sidebarEntries={sidebarEntries} />
                </Grid>
            </Popover>
        </Provider>
    );
};

interface DesignQcRootProps {
    order: DesignQcOrder;
    designFragment: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    sidebarEntries: DesignQcSidebarEntry[];
    refetch: () => Promise<unknown>;
    currentUserId?: string;
    hasDesignBeenRejectedPreviously?: boolean;
    open: boolean;
    setOpen: (open: boolean) => void;
    designQaEvaluationId?: string;
    rubricOverride?: DesignQcRubricCategoryEntry[] | undefined;
}

const GUIDED_QC_ALLOWED_SKUS = [
    LabOrderItemSKUType.Crown,
    LabOrderItemSKUType.Bridge,
    LabOrderItemSKUType.Implant,
    LabOrderItemSKUType.ImplantBridge,
    LabOrderItemSKUType.Model,
    LabOrderItemSKUType.Other,
] as const;

type ConfigOrError = { config: DesignQcConfig } | { error: string };

export const DesignQcRoot: React.VFC<DesignQcRootProps> = ({
    order,
    designFragment,
    sidebarEntries,
    refetch,
    currentUserId,
    hasDesignBeenRejectedPreviously,
    open,
    setOpen,
    designQaEvaluationId,
    rubricOverride,
}) => {
    const configOrError = React.useMemo<ConfigOrError>(() => {
        const convertedItems = OrderItemV2Utils.parseItems(order.items_v2);

        // All SKUs in the order must be allowed for guided QC.
        const disallowedSkuItems = convertedItems.filter(item =>
            CartItemV2Utils.itemIsNotType(item, GUIDED_QC_ALLOWED_SKUS),
        );
        if (disallowedSkuItems.length) {
            return { error: `Order includes disallowed SKUs: ${disallowedSkuItems.map(i => i.sku).join(', ')}` };
        }

        // While we generally allow models, we do not allow Diagnostic Models, as they are a variant of waxups.
        if (
            convertedItems.some(
                item =>
                    CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Model) &&
                    item.unit_type === 'Diagnostic Model',
            )
        ) {
            return { error: `Order includes disallowed diagnostic model` };
        }

        const hasAnteriors = CartItemV2Utils.getItemGroupUniqueUNNs(convertedItems).some(unn =>
            ToothUtils.toothIsAnterior(unn),
        );

        const hasCrown = convertedItems.some(item => item.sku === LabOrderItemSKUType.Crown);
        const hasBridge = convertedItems.some(item => CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Bridge));
        const hasModel = convertedItems.some(item => {
            if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Model)) {
                return true;
            }

            const unitType = ExtraCartItemV2Utils.getExtraItemUnitType(item)?.toLowerCase();
            return unitType && (unitType.includes('model') || unitType === 'die only');
        });

        const hasAdjacentRestorations = convertedItems.some(item1 => {
            const unns1 = CartItemV2Utils.getUniqueUNNs(item1);
            return convertedItems.some(item2 => {
                if (item1 === item2) {
                    return false;
                }
                const unns2 = CartItemV2Utils.getUniqueUNNs(item2);
                return unns1.some(tooth1 =>
                    unns2.some(
                        tooth2 =>
                            // Teeth are adjacent if one apart and on the same arch.
                            Math.abs(tooth1 - tooth2) === 1 &&
                            ToothUtils.toothIsUpper(tooth1) === ToothUtils.toothIsUpper(tooth2),
                    ),
                );
            });
        });

        // If we're generating a rubric, we use only the 5 most important categories for anterior QC.
        const rubric = rubricOverride ?? generateDefaultGuidedQCRubric(!designQaEvaluationId && hasAnteriors);

        // Apply filter rules and remove categories that are empty after filtering.
        const flags: FilterFlags = {
            hasAdjacentRestorations,
            hasModel,
            hasBridge,
            hasCrown,
            hasAnteriors,
        };
        const config = rubric
            .map(category => {
                return {
                    ...category,
                    subcategories: category.subcategories.filter(subcat => !subcat.when || subcat.when(flags)),
                };
            })
            .filter(cat => cat.subcategories.length);
        return { config };
    }, [order, designQaEvaluationId, rubricOverride]);

    if ('error' in configOrError) {
        return <Text variant={'body1'}>{configOrError.error}</Text>;
    }

    return (
        <DesignQcInternal
            // Ensures that React uses a distinct component for different evaluation IDs.
            key={designQaEvaluationId}
            open={open}
            setOpen={setOpen}
            order={order}
            designFragment={designFragment}
            config={configOrError.config}
            sidebarEntries={sidebarEntries}
            refetch={refetch}
            currentUserId={currentUserId}
            hasDesignBeenRejectedPreviously={hasDesignBeenRejectedPreviously}
            designQaEvaluationId={designQaEvaluationId}
        />
    );
};
