import type { IMergedItem } from '../../types';
import { isMergedItem } from '../../types';
import { getMergedItems } from '../../utils/mergedItem.util';
import type { IPrintableSlipItemProps } from './PrintableSlipItem';
import type { IOrderItemV2DTO } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface IPrintableSlipItemListProps {
    items: IOrderItemV2DTO[];
    // the component to render for each item
    ItemComponent: React.VFC<IPrintableSlipItemProps>;
}

/**
 * Renders the items section of the Printable Slip.
 * This component iterates through the order items and renders
 * the details needed for each item.
 * @param props
 * @returns Rendered list of items for the Printable Slip
 */
export const PrintableSlipItemList: React.VFC<IPrintableSlipItemListProps> = ({ items, ItemComponent }) => {
    const itemsToRender = getMergedItems(items);
    return (
        <Grid container>
            {itemsToRender.map((item: IMergedItem | IOrderItemV2DTO, idx: number) => (
                <ItemComponent
                    key={`${isMergedItem(item) ? item.defaultItem.id : item.id}-${idx}`}
                    item={item}
                    allOrderItems={items}
                />
            ))}
        </Grid>
    );
};
