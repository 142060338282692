import { useFirebaseStorage } from '../../context';
import { DesignViewerLiteWrapper } from '../Waxups/DesignViewerLiteWrapper';
import {
    uploadAnnotatedImage,
    useGetAnnotationCanvasFunctions,
    useGetCommonAnnotationState,
} from '../Waxups/Waxup.util.graphql';
import { AnnotationListModal } from './AnnotationListModal';
import { DrawingControls, INITIAL_TOOL_STATE } from './DrawingControls';
import type { Annotation } from './types';
import type { DandyAnalyticsEventSchemaType } from '@orthly/analytics/dist/browser';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { DesignCaseParserOrder } from '@orthly/dentin';
import { useDrawingCanvas } from '@orthly/dentin';
import type { FragmentType, OrderDesignPreviewDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import { Format } from '@orthly/runtime-utils';
import { getFullStoragePath, DesignStorageConfigs } from '@orthly/shared-types';
import { StatefulSimpleInput, OrthlyBrowserConfig } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    Text,
    stylesFactory,
    useScreenIsMobile,
    CircularProgress,
    Chip,
} from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

const useStyles = stylesFactory(() => ({
    container: {
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
        '@media screen and (max-width: 600px)': {
            height: 'auto',
            minHeight: '100%',
        },
    },
    canvasContainer: {
        flexGrow: 1,
        justifyContent: 'center',
    },
    controlContainer: {
        display: `flex`,
        flexFlow: `row wrap`,
        justifyContent: `space-between`,
        alignItems: `center`,
        padding: `24px`,
        gap: `8px`,
    },
    buttonContainer: {
        display: 'flex',
        gap: `16px`,
        flexGrow: 1,
        justifyContent: 'right',
    },
    buttonProgress: {
        color: FlossPalette.GREEN,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

function useUploadCurrentAnnotation(
    takeSnapshot: () => Promise<Blob | null>,
    trackingSource: DandyAnalyticsEventSchemaType['Order Annotation - Upload completed']['source'],
    orderId: string,
) {
    const storagePathConfig = getFullStoragePath(
        OrthlyBrowserConfig.env,
        DesignStorageConfigs.designAnnotations,
        orderId,
    );
    const { enqueueSnackbar } = useSnackbar();
    const storage = useFirebaseStorage(storagePathConfig.bucketName);

    return React.useCallback(async (): Promise<Annotation | null> => {
        try {
            const blob = await takeSnapshot();
            if (!blob) {
                return null;
            }
            const imageUrl = await uploadAnnotatedImage(storage, storagePathConfig.path, blob);
            const annotation: Annotation = { imageUrl };
            BrowserAnalyticsClientFactory.Instance?.track('Order Annotation - Upload completed', {
                $groups: { order: orderId },
                source: trackingSource,
            });
            return annotation;
        } catch (error) {
            enqueueSnackbar(`Error uploading annotation: ${error}`, { variant: 'error' });
            throw error;
        }
    }, [takeSnapshot, storage, storagePathConfig.path, orderId, trackingSource, enqueueSnackbar]);
}

export type OrderDesignAnnotatorOnSaveData = {
    notes: string;
    annotations: Annotation[];
};

export const OrderDesignAnnotator: React.VFC<{
    order: DesignCaseParserOrder & { id: string };
    onSave: (savedData: OrderDesignAnnotatorOnSaveData) => Promise<void>;
    onCancel: () => void;
    isSaveDisabled?: boolean;
    trackingSource: DandyAnalyticsEventSchemaType['Order Annotation - Capture Screenshot Clicked']['source'];
    refetchDesign?: () => Promise<unknown> | void;
    designFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    notesRef: React.MutableRefObject<string>;
    hideTextInput?: boolean;
    // eslint-disable-next-line max-lines-per-function
}> = ({
    order,
    onSave,
    onCancel,
    trackingSource,
    isSaveDisabled = false,
    refetchDesign,
    designFragment,
    notesRef,
    hideTextInput,
}) => {
    const classes = useStyles();

    const isMobile = useScreenIsMobile();

    const [hasNotes, setHasNotes] = React.useState(false);
    const [annotations, setAnnotations] = React.useState<Annotation[]>([]);

    // Tracks whether we are uploading an annotation to Storage while submitting the form.
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    // We show the annotation canvas *basically* when `screenshotToAnnotate` is set, but
    // using a separate var allows us to delay revealing the canvas until it's had a chance
    // to render the new image.
    const [showAnnotationCanvas, setShowAnnotationCanvas] = React.useState(false);

    const {
        takeSnapshotRef,
        isAnnotationListModalOpen,
        setIsAnnotationListModalOpen,
        screenshotToAnnotate,
        setScreenshotToAnnotate,
        isUploading,
        setIsUploading,
        canvasSize,
        setCanvasSize,
        backgroundImageUrl,
    } = useGetCommonAnnotationState();

    const numAnnotations = annotations.length + (isUploading ? 1 : 0);

    const backgroundImageSizeCallback = React.useCallback(
        (width, height) => {
            setCanvasSize({ width, height });
            setShowAnnotationCanvas(true);
        },
        [setCanvasSize],
    );

    const { canvas, toolState, setToolState, takeSnapshot, undoState, undo, redo, clear } = useDrawingCanvas({
        ...canvasSize,
        initialToolState: INITIAL_TOOL_STATE,
        backgroundImageSizeCallback,
        backgroundImageUrl: backgroundImageUrl ?? undefined,
        backgroundColor: FlossPalette.DARK_TAN,
        canvasProps: { style: { border: 'unset' } },
    });

    const { setToolStateWrapped, closeDrawingCanvas } = useGetAnnotationCanvasFunctions(
        order.id,
        takeSnapshotRef,
        screenshotToAnnotate,
        setScreenshotToAnnotate,
        clear,
        setToolState,
        trackingSource,
        setShowAnnotationCanvas,
    );

    const uploadCurrentAnnotation = useUploadCurrentAnnotation(takeSnapshot, trackingSource, order.id);

    // Handler for the "Save and markup new screen capture" button.
    // Uploads the current annotation and adds it to `dataToSubmit`.
    const saveAnnotation = React.useCallback(async () => {
        try {
            closeDrawingCanvas();
            setIsUploading(true);
            const annotation = await uploadCurrentAnnotation();
            if (annotation) {
                setAnnotations(a => [...a, annotation]);
            }
        } finally {
            setIsUploading(false);
        }
    }, [uploadCurrentAnnotation, closeDrawingCanvas, setIsUploading, setAnnotations]);

    // Handler for the "Request changes" button.
    // Uploads the current annotation if there is one, and submits it along with the existing `dataToSubmit`.
    const submit = React.useCallback(async () => {
        try {
            setIsSubmitting(true);
            const newAnnotation = screenshotToAnnotate ? await uploadCurrentAnnotation() : null;
            const dataToSubmit = { notes: notesRef.current, annotations };
            if (newAnnotation) {
                await onSave({ ...dataToSubmit, annotations: [...dataToSubmit.annotations, newAnnotation] });
            } else {
                await onSave(dataToSubmit);
            }
        } finally {
            setIsSubmitting(false);
        }
    }, [screenshotToAnnotate, uploadCurrentAnnotation, onSave, notesRef, annotations, setIsSubmitting]);

    // On mobile, there's not enough space for the separate progress indicator
    // on the chip, so we show upload on the submit button instead.
    const showChipProgress = !isMobile && isUploading;
    const showSubmitProgress = isSubmitting || (isMobile && isUploading);

    return (
        <>
            <div className={classes.container}>
                <DrawingControls
                    toolState={toolState}
                    setToolState={setToolStateWrapped}
                    canUndo={undoState.canUndo}
                    canRedo={undoState.canRedo}
                    undo={undo}
                    redo={redo}
                    deleteButtonVisible={!isMobile && !!showAnnotationCanvas}
                    deleteSnapshot={() => {
                        closeDrawingCanvas();
                    }}
                    trackingSource={trackingSource}
                />

                <DesignViewerLiteWrapper
                    order={order}
                    refetchDesign={refetchDesign}
                    designFragment={designFragment}
                    takeSnapshotRef={takeSnapshotRef}
                    style={{
                        // Ideally we'd use `display: none`, but on iOS, that can cause the model
                        // to disappear (https://www.loom.com/share/8ad7ce409a9143418077f8792d6d0829)
                        visibility: showAnnotationCanvas ? 'hidden' : undefined,
                        position: showAnnotationCanvas ? 'absolute' : undefined,
                        flexGrow: 1,
                    }}
                />
                <div
                    className={classes.canvasContainer}
                    style={{
                        display: showAnnotationCanvas ? 'flex' : 'none',
                    }}
                >
                    {canvas}
                </div>
                {isMobile && showAnnotationCanvas && (
                    <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'center', gap: 24 }}>
                        <Button variant={'ghost-warning'} onClick={() => closeDrawingCanvas()}>
                            Discard screenshot
                        </Button>
                        <Button variant={'secondary'} onClick={saveAnnotation}>
                            New screenshot
                        </Button>
                    </div>
                )}
                <div className={classes.controlContainer}>
                    {!hideTextInput && (
                        <>
                            <Text variant={'body2'} medium>
                                Rejection reason
                            </Text>
                            <Text variant={'body2'} color={'GRAY'}>
                                Required
                            </Text>
                            <StatefulSimpleInput
                                label={'Describe what you would like done differently'}
                                TextFieldProps={{
                                    multiline: true,
                                    rows: 4,
                                    style: { flexBasis: '100%', marginBottom: 12 },
                                    'data-test': 'annotation-simple-input',
                                }}
                                value={notesRef.current}
                                onChange={text => {
                                    notesRef.current = text || '';
                                    setHasNotes(!!text);
                                }}
                            />
                        </>
                    )}
                    {numAnnotations > 0 && (
                        <Chip
                            label={
                                isMobile
                                    ? Format.pluralize('Screenshot', numAnnotations)
                                    : `${Format.pluralize('Screen Capture', numAnnotations)} Saved`
                            }
                            clickable={true}
                            onClick={() => setIsAnnotationListModalOpen(true)}
                            size={'small'}
                            style={{
                                backgroundColor: FlossPalette.SECONDARY_BACKGROUND,
                                color: FlossPalette.SECONDARY_FOREGROUND,
                                marginRight: 16,
                            }}
                        />
                    )}
                    {showChipProgress && (
                        <CircularProgress style={{ color: FlossPalette.SECONDARY_FOREGROUND }} size={20} />
                    )}
                    <div className={classes.buttonContainer}>
                        {!isMobile && screenshotToAnnotate ? (
                            <Button variant={'secondary'} onClick={saveAnnotation} disabled={isSubmitting}>
                                Save and markup new screen capture
                            </Button>
                        ) : (
                            <Button variant={'secondary'} onClick={onCancel} disabled={isSubmitting}>
                                Cancel
                            </Button>
                        )}
                        <div
                            style={{
                                position: 'relative',
                            }}
                        >
                            <Button
                                variant={'primary'}
                                onClick={submit}
                                disabled={
                                    isSaveDisabled || isUploading || isSubmitting || (!hideTextInput && !hasNotes)
                                }
                                data-test={'annotator-request-changes'}
                            >
                                Request changes
                            </Button>
                            {showSubmitProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </div>
                </div>
            </div>
            <AnnotationListModal
                annotations={annotations}
                open={isAnnotationListModalOpen}
                setOpen={setIsAnnotationListModalOpen}
            />
        </>
    );
};
