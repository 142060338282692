import { formatAbutmentPartOptions } from './helpers';
import type { AbutmentPartsByManufacturer } from './types';
import { useAddAbutmentPartToImplantScanbodyMutation } from '@orthly/graphql-react';
import { ActionCard, QuickForm } from '@orthly/ui';
import { fromIsPreferredToPriorityNumber } from '@orthly/veneer';
import React from 'react';
import { z } from 'zod';

interface AbutmentPartRelationshipFormRow {
    manufacturer: string;
    abutmentPartId: string;
    isPreferred: boolean;
}

interface CreateAbutmentPartRelationshipProps {
    implantTypeId: string;
    implantToScanbodyId: string;
    abutmentPartsByManufacturer: AbutmentPartsByManufacturer;
    afterSaved: () => void;
}

export const CreateAbutmentPartRelationship: React.FC<CreateAbutmentPartRelationshipProps> = ({
    implantToScanbodyId,
    implantTypeId,
    abutmentPartsByManufacturer,
    afterSaved,
}) => {
    const [createAbutmentPartRelationship, { error, loading }] = useAddAbutmentPartToImplantScanbodyMutation();
    const [selectedManufacturer, setSelectedManufacturer] = React.useState('');

    return (
        <div>
            {error?.message && <ActionCard variant={'alert'} title={'Error'} subtitle={error?.message} />}
            <QuickForm<AbutmentPartRelationshipFormRow>
                fields={{
                    manufacturer: {
                        type: 'autocomplete',
                        options: Object.keys(abutmentPartsByManufacturer),
                        freeSolo: false,
                        label: 'Abutment Part Manufacturer',
                        validation: z.string().min(1),
                    },
                    abutmentPartId: {
                        type: 'autocomplete',
                        options: formatAbutmentPartOptions(abutmentPartsByManufacturer[selectedManufacturer] ?? []),
                        // uses manufacturer so React creates a fresh component instance when manufacturer changes, and the values in the dropdown refresh as expected
                        key: selectedManufacturer,
                        label: 'Abutment Part',
                        validation: z.string().min(1),
                    },
                    isPreferred: { type: 'boolean', label: 'Is Preferred?' },
                }}
                initialValues={{
                    manufacturer: '',
                    abutmentPartId: '',
                    isPreferred: false,
                }}
                onChange={(result, form) => {
                    // Dynamically update the list of SKU to select from the list based on the manufacturer selected
                    if (result.manufacturer && result.manufacturer !== selectedManufacturer) {
                        setSelectedManufacturer(result.manufacturer);
                        form.setFieldValue('abutmentPartId', '');
                    }
                }}
                onSubmit={async row => {
                    await createAbutmentPartRelationship({
                        variables: {
                            data: {
                                abutment_part_id: row.abutmentPartId,
                                implant_to_scanbody_id: implantToScanbodyId,
                                implant_type_id: implantTypeId,
                                priority: fromIsPreferredToPriorityNumber(row.isPreferred),
                            },
                        },
                    });
                    afterSaved();
                }}
                submitButtonProps={{
                    disabled: loading,
                    children: `Add Abutment Part Relationship`,
                }}
            />
        </div>
    );
};
