import { Box, Text, Button } from '@orthly/ui-primitives';
import React from 'react';

interface ShareScansWithPatientModalFooterProps {
    error: string;
    setShared: (shared: boolean) => void;
    setOpen: (open: boolean) => void;
    linkOrPrintout: string;
    shareButtonDisabled: boolean;
    shareType: string;
}

export const ShareScansWithPatientModalFooter: React.FC<ShareScansWithPatientModalFooterProps> = ({
    error,
    setShared,
    setOpen,
    linkOrPrintout,
    shareButtonDisabled,
    shareType,
}) => {
    return (
        <Box sx={{ display: 'grid', gridTemplateColumns: '2fr 1fr', marginTop: '40px', paddingRight: '24px' }}>
            <Box>
                <Text variant={'caption'} color={'GRAY'}>
                    By proceeding, you confirm that the patient understands the requested information is Protected
                    Health Information (PHI) under HIPAA, acknowledges the risks of sending it via phone or email, and
                    consents to proceed.
                </Text>
            </Box>
            <Box sx={{ paddingLeft: '24px' }}>
                {error && (
                    <Text color={'ATTENTION'} variant={'caption'}>
                        {error}
                    </Text>
                )}
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gridColumnGap: '8px',
                    }}
                >
                    <Button
                        variant={'secondary'}
                        onClick={() => {
                            setOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={'primary'}
                        onClick={() => {
                            setShared(true);
                        }}
                        disabled={shareButtonDisabled}
                        analytics={{
                            AssetName: `Share Scans - ${shareType}`,
                            AssetLocation: 'Share Scans Modal',
                        }}
                    >
                        {linkOrPrintout === 'printout' ? 'Generate PDF' : 'Share'}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};
