import { AbutmentPartRow } from './AbutmentPartRow';
import type { EditableAbutmentPartFields } from './utils';
import { abutmentPartsFormFields, getDataToAutoComplete, useAbutmentParts } from './utils';
import type { LabsGqlAbutmentPartFragment } from '@orthly/graphql-operations';
import { useCreateAbutmentPartMutation } from '@orthly/graphql-react';
import MUITable from '@orthly/mui-table';
import { ActionCard, QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const AbutmentPartTable: React.FC = () => {
    const { error: abutmentPartsError, data, refetch: refetchAbutmentParts } = useAbutmentParts();
    const [isCreateAbutmentPartOpen, setCreateAbutmentPartOpen] = React.useState(false);
    const [rawCreateAbutmentPart] = useCreateAbutmentPartMutation();
    const { submit: createAbutmentPart, submitting: creatingAbutmentPart } = useChangeSubmissionFn<any, any>(
        data => rawCreateAbutmentPart({ variables: { data } }),
        {
            closeOnComplete: true,
            successMessage: () => ['Abutment Part successfully created!', {}],
            onSuccess: async () => {
                setCreateAbutmentPartOpen(false);
                await refetchAbutmentParts();
            },
        },
    );

    const dataToAutoComplete = React.useMemo(() => getDataToAutoComplete(data?.listAbutmentParts ?? []), [data]);

    return (
        <Grid container style={{ padding: 16 }}>
            {abutmentPartsError && (
                <ActionCard
                    variant={'alert'}
                    title={'Failed to retrieve abutment parts'}
                    subtitle={'Please try again later'}
                />
            )}
            <MUITable<LabsGqlAbutmentPartFragment>
                title={'Abutment Parts'}
                data={data?.listAbutmentParts ?? []}
                loading={!data && !abutmentPartsError}
                DetailPanel={props => <AbutmentPartRow {...props} dataToAutoComplete={dataToAutoComplete} />}
                rowOptions={{ rowHover: true }}
                displayOptions={{
                    elevation: 0,
                    sort: true,
                    filter: true,
                }}
                actions={{
                    global: [
                        {
                            icon: 'refresh',
                            position: 'toolbar',
                            tooltip: 'Reload abutment parts',
                            onClick: () => refetchAbutmentParts(),
                        },
                        {
                            icon: 'add',
                            position: 'toolbar',
                            tooltip: 'Create abutment part',
                            onClick: () => setCreateAbutmentPartOpen(true),
                        },
                    ],
                }}
                eventHooks={{
                    onRowClick: (row, actions) => actions.toggleDetailPanel(row),
                }}
                columns={[
                    { name: 'SKU', render: 'sku' },
                    { name: 'Manufacturer', render: 'manufacturer' },
                    {
                        name: 'Material',
                        render: row => _.startCase(row.material),
                        filterOptions: { type: 'multiselect', exact: true },
                    },
                    {
                        name: 'Engagement',
                        render: row => _.startCase(row.engagement),
                        filterOptions: { type: 'multiselect', exact: true },
                    },
                    {
                        name: 'Archived',
                        render: 'is_archived',
                        type: 'boolean',
                        filterOptions: { defaultValues: ['false'] },
                    },
                ]}
            />
            <RootActionDialog
                title={'Create Abutment Part'}
                open={isCreateAbutmentPartOpen}
                setOpen={setCreateAbutmentPartOpen}
                loading={creatingAbutmentPart}
                hideButton={true}
                content={
                    <QuickForm<EditableAbutmentPartFields>
                        fields={abutmentPartsFormFields(dataToAutoComplete)}
                        initialValues={{}}
                        onSubmit={createAbutmentPart}
                    />
                }
            />
        </Grid>
    );
};
