import { usePracticeInfoRender } from '../../PalateMetrics';
import { usePracticeOrderCountsPalate } from '../../state/usePracticeOrderCountsPalate.graphql';
import { PracticeInfoRow } from './PracticeInfoRow';
import type { LabsGqlPracticeDetailsFragment } from '@orthly/graphql-operations';
import { Format, PhoneNumberUtils } from '@orthly/runtime-utils';
import { Text, FlossPalette, Grid } from '@orthly/ui-primitives';
import React from 'react';

const TRIOS_STAGES = ['Does not have Intune', 'Ready for Chairside Install', 'Install Failed'];
const CHAIRSIDE_STAGES = ['Installed - Not Adopted', 'Adopted', 'Ramped'];

export const PracticeInfo: React.FC<{ practiceDetails: LabsGqlPracticeDetailsFragment; practiceId: string }> = ({
    practiceDetails,
    practiceId,
}) => {
    const { counts } = usePracticeOrderCountsPalate(practiceId);

    const inFlightOrdersCount = counts?.InFlightOrders;
    const allOrdersCount = counts?.TotalOrders;

    const dashOrSpace = practiceDetails.chairside_stage?.includes('-') ? ' ' : ' - ';
    const scanningSoftware = () => {
        if (!practiceDetails.chairside_stage) {
            return 'Trios';
        }
        if (CHAIRSIDE_STAGES.includes(practiceDetails.chairside_stage)) {
            return `Chairside${dashOrSpace}${practiceDetails.chairside_stage}`;
        }
        if (TRIOS_STAGES.includes(practiceDetails.chairside_stage)) {
            return `Trios - ${practiceDetails.chairside_stage}`;
        }
        return practiceDetails.chairside_stage;
    };

    const phoneNumber = () => {
        if (practiceDetails.phone_number) {
            const cleaned = PhoneNumberUtils.cleanPhoneNumber(practiceDetails.phone_number);
            if (cleaned) {
                return PhoneNumberUtils.prettyPhoneNumber(cleaned);
            }
        }
    };
    usePracticeInfoRender(practiceId);

    return (
        <Grid container>
            <Grid
                container
                style={{ paddingTop: 8, paddingBottom: 16, borderBottom: `1px solid ${FlossPalette.DARK_TAN}` }}
            >
                <Text variant={'body2'} medium>
                    Practice information
                </Text>
                <PracticeInfoRow title={'Splashtop IDs'} value={practiceDetails?.device_id_list || ''} />
                <PracticeInfoRow title={'Shipping address'} value={practiceDetails?.address || ''} />
                <PracticeInfoRow title={'Primary contact'} value={practiceDetails?.practice_email || ''} />
                <PracticeInfoRow title={'AM'} value={practiceDetails?.AM || ''} />
                <PracticeInfoRow title={'Phone number(s)'} value={phoneNumber() || ''} />
            </Grid>
            <Grid container style={{ paddingBottom: 16, borderBottom: `1px solid ${FlossPalette.DARK_TAN}` }}>
                <PracticeInfoRow
                    title={'In-flight orders'}
                    value={`${inFlightOrdersCount} (${allOrdersCount} total)`}
                />
                <PracticeInfoRow
                    title={'Average monthly spend'}
                    value={
                        (practiceDetails?.avg_monthly_spend &&
                            Format.currency(practiceDetails.avg_monthly_spend, 'dollars', false)) ||
                        ''
                    }
                />
            </Grid>
            <Grid container style={{ paddingBottom: 16, borderBottom: `1px solid ${FlossPalette.DARK_TAN}` }}>
                <PracticeInfoRow title={'Scanning software'} value={scanningSoftware() || ''} />
            </Grid>
        </Grid>
    );
};
