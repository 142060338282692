import { AnalyticsClient } from '../../../../../../../utils/analyticsClient';
import { SubmitOrderScanReview } from '../../actions/SubmitOrderScanReview';
import { type FragmentType, graphql, getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder, LabsGqlWorkflowTaskFragment } from '@orthly/graphql-operations';
import type { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { WarningIcon } from '@orthly/ui';
import type { CondensedActionCardVariant, OrderDesignTaskPaneSubmissionData } from '@orthly/veneer';
import {
    CondensedActionCard,
    CompleteDesignTaskPane,
    OrderDesignPreppedBanner,
    useValidateDesign,
    OrderDesignTaskValidationBypassModal,
    useFeatureFlag,
} from '@orthly/veneer';
import React from 'react';

export const AdminOrderDesignSubmissionCommonInternalDesignPath_Fragment = graphql(`
    fragment AdminOrderDesignSubmissionCommonInternalDesignPath_Fragment on DesignOrderDesignRevisionDTO {
        source_file_zip_path
    }
`);

export interface OrderDesignSubmissionCoreProps {
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
    variant: CondensedActionCardVariant;
    designRevision: FragmentType<typeof AdminOrderDesignSubmissionCommonInternalDesignPath_Fragment> | undefined;
}

interface OrderDesignSubmissionCommonProps extends OrderDesignSubmissionCoreProps {
    taskType: LabsGqlWorkflowTaskType;
    onSubmit: (data: OrderDesignTaskPaneSubmissionData & { overrodeValidation: boolean }) => Promise<void>;
    submitting: boolean;
    CardTopAction?: React.VFC<{ task: LabsGqlWorkflowTaskFragment }>;
    CardSubtitle?: React.VFC<{ task: LabsGqlWorkflowTaskFragment }>;
    CardTitle: string | React.VFC<{ task: LabsGqlWorkflowTaskFragment }>;
}

export interface OrderDesignSubmissionCommonInternalProps extends OrderDesignSubmissionCommonProps {
    task: LabsGqlWorkflowTaskFragment;
}

const OrderDesignSubmissionCommonInternal: React.FC<OrderDesignSubmissionCommonInternalProps> = props => {
    const {
        task,
        refetchOrder,
        variant,
        order,
        onSubmit,
        submitting,
        designRevision,
        CardTopAction,
        CardSubtitle,
        CardTitle,
    } = props;
    const { assignee } = task;
    const { source_file_zip_path: latestDesignFilePath } =
        getFragmentData(AdminOrderDesignSubmissionCommonInternalDesignPath_Fragment, designRevision) ?? {};

    const [scanReviewOpen, setScanReviewOpen] = React.useState<boolean>(false);
    const [designTaskOpen, setDesignTaskOpen] = React.useState<boolean>(false);

    const {
        checkDesignValid,
        result: validationResult,
        reset: resetValidation,
        validationLoading,
    } = useValidateDesign(order.id, task);

    const assignedUser = assignee
        ? `${assignee.assigned_user.first_name} ${assignee.assigned_user.last_name}`
        : 'Unassigned';

    const [bypassedData, setBypassedData] = React.useState<OrderDesignTaskPaneSubmissionData | undefined>(undefined);
    const { value: shouldUseNewBypassFlow } = useFeatureFlag('automatedChecksNewBypassFlowEnabled');

    return (
        <>
            {variant !== 'sidebar' && (
                <OrderDesignPreppedBanner
                    order={order}
                    latestDesignFilePath={latestDesignFilePath}
                    variant={'rounded'}
                />
            )}
            <CondensedActionCard
                title={typeof CardTitle === 'string' ? CardTitle : <CardTitle task={task} />}
                assignee={assignedUser}
                topAction={CardTopAction ? <CardTopAction task={task} /> : undefined}
                primaryAction={{
                    endIcon: 'CopyFileIcon',
                    text: 'Upload design',
                    onClick: () => setDesignTaskOpen(true),
                }}
                IconComponent={WarningIcon}
                task={task}
                subtitle={CardSubtitle ? <CardSubtitle task={task} /> : undefined}
                refetch={refetchOrder}
                variant={variant}
            />
            {validationResult?.status === 'failed' && bypassedData && (
                <OrderDesignTaskValidationBypassModal
                    failureReasons={validationResult.errorLines}
                    onBypass={feedback => {
                        const { design_file_path } = bypassedData;

                        // Should never happen, as we don't allow submitting without this set, but we check anyway for type safety.
                        if (!design_file_path) {
                            return;
                        }

                        AnalyticsClient.track('Ops - Portal - Design Validation Bypassed', {
                            $groups: { order: order.id },
                            feedback,
                        });

                        void onSubmit({
                            ...bypassedData,
                            overrodeValidation: true,
                        });
                    }}
                    onClose={() => {
                        setBypassedData(undefined);
                    }}
                    submitDisabled={submitting}
                />
            )}
            <CompleteDesignTaskPane
                {...props}
                setOpen={setDesignTaskOpen}
                open={designTaskOpen}
                submitting={submitting || validationLoading}
                onReset={() => {
                    resetValidation();
                    setBypassedData(undefined);
                }}
                designSubmissionValidationResult={validationResult}
                submit={async data => {
                    try {
                        const { design_file_path } = data;

                        // This design already failed validation -- we are going to open the bypass modal if they're allowed to.
                        if (
                            validationResult?.status === 'failed' &&
                            validationResult.canBypass &&
                            shouldUseNewBypassFlow
                        ) {
                            setBypassedData(data);
                            return;
                        }

                        if (design_file_path && !(await checkDesignValid(design_file_path))) {
                            return;
                        }

                        await onSubmit({
                            ...data,
                            // We explicitly set validation as overridden because we are handling validation ourselves upfront.
                            overrodeValidation: true,
                        });
                        setDesignTaskOpen(false);
                    } catch (err) {
                        console.warn(err);
                    }
                }}
            />
            <SubmitOrderScanReview
                initialResponse={'flag'}
                open={scanReviewOpen}
                reasons={[]}
                setOpen={setScanReviewOpen}
                {...props}
            />
        </>
    );
};

export const OrderDesignSubmissionCommon: React.FC<OrderDesignSubmissionCommonProps> = props => {
    const { order, taskType } = props;
    const activeTask = order.fulfillment_workflow.active_task;
    if (!activeTask || activeTask.type !== taskType) {
        return null;
    }

    return <OrderDesignSubmissionCommonInternal {...props} task={activeTask} />;
};
