import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import type { ButtonVariant, IconName } from '@orthly/ui-primitives';
import { Button, Icon, IconButton } from '@orthly/ui-primitives';
import React from 'react';

export const OrderEditActionButton: React.VFC<{
    order: LabsGqlOrder;
    isInternal?: boolean;
    hideButtonIcon?: boolean;
    buttonStyle?: React.CSSProperties;
    buttonLabel?: React.ReactNode;
    isFromQuickOrderActions?: boolean;
    isProvideInstructionsFlow?: boolean;
    setEditOpen: (isOpen: boolean) => void;
    isIconOnly?: boolean;
    buttonVariant?: ButtonVariant;
    leftAlign?: boolean;
    disableInsteadOfHide?: boolean;
    hasEndIcon?: boolean;
}> = props => {
    const {
        order,
        isInternal,
        hideButtonIcon,
        buttonStyle,
        buttonLabel,
        isFromQuickOrderActions,
        isProvideInstructionsFlow,
        setEditOpen,
        buttonVariant = 'ghost',
        isIconOnly = false,
        leftAlign,
        disableInsteadOfHide,
        hasEndIcon = false,
    } = props;

    const canEdit = order.can_edit;
    const location = isInternal ? 'admin' : 'practice';

    const icon: IconName = isProvideInstructionsFlow ? 'LabIcon' : 'OrderPaperIcon';

    if (!disableInsteadOfHide && !canEdit) {
        return null;
    }

    return (
        <>
            {isIconOnly ? (
                <IconButton
                    size={'large'}
                    disabled={disableInsteadOfHide && !canEdit}
                    data-tracker-id={
                        buttonLabel === 'Provide instructions'
                            ? 'provide-instructions-tracker-id'
                            : 'modify-rx-tracker-id'
                    }
                    color={'primary'}
                    onClick={() => {
                        BrowserAnalyticsClientFactory.Instance?.track('All - Edit Order - Opened', {
                            $groups: { order: order.id },
                            location,
                            mode: canEdit ? 'order_edit' : undefined,
                            orderStatus: order.status,
                            activeTaskType: order.fulfillment_workflow.active_task?.type,
                            isFromQuickOrderActions: isFromQuickOrderActions,
                            isProvideInstructions: isProvideInstructionsFlow,
                            version: 'v1',
                        });
                        setEditOpen(true);
                    }}
                    style={{ ...buttonStyle }}
                    data-test={'modify-rx-action-button'}
                >
                    <Icon icon={icon} />
                </IconButton>
            ) : (
                <Button
                    disabled={disableInsteadOfHide && !canEdit}
                    data-tracker-id={
                        buttonLabel === 'Provide instructions'
                            ? 'provide-instructions-tracker-id'
                            : 'modify-rx-tracker-id'
                    }
                    variant={buttonVariant}
                    onClick={() => {
                        BrowserAnalyticsClientFactory.Instance?.track('All - Edit Order - Opened', {
                            $groups: { order: order.id },
                            location,
                            mode: canEdit ? 'order_edit' : undefined,
                            orderStatus: order.status,
                            activeTaskType: order.fulfillment_workflow.active_task?.type,
                            isFromQuickOrderActions: isFromQuickOrderActions,
                            isProvideInstructions: isProvideInstructionsFlow,
                            version: 'v1',
                        });
                        setEditOpen(true);
                    }}
                    startIcon={hideButtonIcon || isIconOnly || hasEndIcon ? undefined : icon}
                    endIcon={!hasEndIcon || hideButtonIcon || isIconOnly ? undefined : icon}
                    style={{ ...buttonStyle }}
                    data-test={'modify-rx-action-button'}
                    leftAlign={leftAlign}
                >
                    {buttonLabel ?? 'Modify Rx & instructions'}
                </Button>
            )}
        </>
    );
};
