import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import { OrthlyBrowserConfig } from '@orthly/ui';
import { CheckboxPrimitive as Checkbox, FormControlLabel, Grid } from '@orthly/ui-primitives';
import { FileUploaderBulk } from '@orthly/veneer';
import React from 'react';

export const AskDoctorV2DialogAttachments: React.FC<{
    orderId: string;
    setAttachments: (attachments: string[]) => void;
    onLoadingStateChange: (isLoading: boolean) => void;
}> = ({ setAttachments, onLoadingStateChange, orderId }) => {
    const [isAddingAttachments, setIsAddingAttachments] = React.useState<boolean>(false);
    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, OrderingStorageConfigs.attachments, orderId);
    return (
        <Grid container>
            <FormControlLabel
                control={
                    <Checkbox
                        color={'secondary'}
                        checked={isAddingAttachments}
                        onChange={e => {
                            setIsAddingAttachments(e.target.checked);
                            !e.target.checked && setAttachments([]);
                        }}
                        value={'skip'}
                    />
                }
                label={`Upload files for additional attachments?`}
            />
            {isAddingAttachments && (
                <Grid container>
                    <FileUploaderBulk
                        autoSubmit
                        storagePathConfig={storagePathConfig}
                        title={'Additional attachments'}
                        onReset={() => {
                            setAttachments([]);
                        }}
                        deleteOnReset={true}
                        onLoadingStateChange={onLoadingStateChange}
                        onComplete={results => setAttachments(results.map(r => r.uploadedPath))}
                        elevation={0}
                    />
                </Grid>
            )}
        </Grid>
    );
};
