import type { AdminLabsOrderActionProps } from '../actions/order-action-types';
import { ActionBlock } from './ActionBlock';
import { useActiveTickets, useTicketStyles } from './OrderTickets.utils';
import { TicketDetailDialog } from './TicketDetailDialog';
import { UploadInjectionMoldFilesAction } from './UploadInjectionMoldFilesAction.graphql';
import { useGetOrderTicketsQuery } from '@orthly/graphql-react';
import type { LabsGqlTicketDto } from '@orthly/graphql-schema';
import { LabsGqlActionStatus } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { LoadBlocker } from '@orthly/ui';
import { FlossPalette, Grid, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const TicketBlock: React.FC<{ ticket: LabsGqlTicketDto }> = ({ ticket }) => {
    const styles = useTicketStyles();
    const [showClosedActions, setShowClosedActions] = React.useState(false);

    const activeAction = ticket.active_action;
    if (!activeAction) {
        return null;
    }

    const activeActionIndex = ticket.actions.findIndex(action => action.id === activeAction.id);
    const pendingActionCount = ticket.actions
        .slice(activeActionIndex + 1)
        .filter(action => action.status === LabsGqlActionStatus.Pending && !action.followup_parent_id).length;

    return (
        <Grid container style={{ marginTop: 8 }} className={styles.ticketBlock}>
            <ActionBlock
                ticket={ticket}
                action={activeAction}
                active={true}
                subtitle={_.compact([
                    pendingActionCount && Format.pluralize('pending action', pendingActionCount),
                    activeActionIndex && Format.pluralize('closed action', activeActionIndex),
                ]).join(' • ')}
                onClick={() => setShowClosedActions(true)}
            />
            {showClosedActions && <TicketDetailDialog tickets={[ticket]} onClose={() => setShowClosedActions(false)} />}
        </Grid>
    );
};

const TicketsList: React.FC<{ tickets: LabsGqlTicketDto[] }> = ({ tickets }) => {
    const [showClosedTickets, setShowClosedTickets] = React.useState(false);
    const openTickets = tickets.filter(ticket => ticket.is_open);
    const closedTickets = tickets.filter(ticket => !ticket.is_open);

    if (!openTickets.length) {
        return (
            <Grid container justifyContent={'center'} alignItems={'center'} direction={'column'}>
                <Text style={{ color: FlossPalette.GRAY }}>Order has {tickets.length ? 'no open' : 'no'} tickets.</Text>
                {tickets.length ? (
                    <Text
                        style={{ color: FlossPalette.STAR_GRASS, cursor: 'pointer' }}
                        onClick={() => setShowClosedTickets(true)}
                    >
                        Show closed tickets
                    </Text>
                ) : null}
                {showClosedTickets && (
                    <TicketDetailDialog tickets={closedTickets} onClose={() => setShowClosedTickets(false)} />
                )}
            </Grid>
        );
    }

    return (
        <Grid container direction={'column'}>
            {openTickets.map((ticket, index) => (
                <TicketBlock key={index} ticket={ticket} />
            ))}
            {closedTickets.length ? (
                <Text
                    style={{ color: FlossPalette.STAR_GRASS, cursor: 'pointer', marginTop: 8 }}
                    onClick={() => setShowClosedTickets(true)}
                >
                    Show {Format.pluralize('closed ticket', closedTickets.length)}
                </Text>
            ) : null}
            {showClosedTickets && (
                <TicketDetailDialog tickets={closedTickets} onClose={() => setShowClosedTickets(false)} />
            )}
        </Grid>
    );
};

export const TicketsTrackingBlock: React.FC<AdminLabsOrderActionProps> = props => {
    const { data, loading } = useGetOrderTicketsQuery({ variables: { order_id: props.order.id } });
    const { canUploadInjectionMoldFiles } = useActiveTickets(data?.getOrderTickets);
    return (
        <LoadBlocker blocking={loading} ContainerProps={{ style: { flex: 1, flexDirection: 'column' } }}>
            <Grid container direction={'column'} justifyContent={'space-between'} style={{ height: '100%' }}>
                <Grid item>{data?.getOrderTickets && <TicketsList tickets={data.getOrderTickets} />}</Grid>
                {canUploadInjectionMoldFiles && (
                    <Grid item>
                        <UploadInjectionMoldFilesAction orderId={props.order.id} />
                    </Grid>
                )}
            </Grid>
        </LoadBlocker>
    );
};
