import type { ScanImageUrls } from './PatientScansPdfGenerator';
import { PatientScansPdfGenerator } from './PatientScansPdfGenerator';
import { UserUtil } from '@orthly/retainer-common';
import dayjs from 'dayjs';

interface GetPatientPdfProps {
    practiceName: string | null;
    practiceContactPhone: string | null;
    patientName: string;
    annotationImages: Array<{ source: string }> | undefined;
    snapshots: ScanImageUrls;
    message: string;
    doctorName: string;
    portalLink: string;
    appointmentDate: string;
}

export const getPatientPdf = async (props: GetPatientPdfProps) => {
    const {
        practiceName,
        practiceContactPhone,
        patientName,
        annotationImages,
        snapshots,
        message,
        doctorName,
        portalLink,
        appointmentDate,
    } = props;

    const annotations = annotationImages?.reduce(
        (acc, { source }, i) => {
            acc[`annotation_${i}`] = source;
            return acc;
        },
        {} as Record<string, string>,
    );

    const generator = new PatientScansPdfGenerator({
        practice_name: practiceName ?? '',
        practice_phone: UserUtil.prettyPhoneNumber(practiceContactPhone) ?? '',
        patient_name: patientName ?? '',
        doctor_name: doctorName,
        appointment_date: dayjs(appointmentDate ?? '').format('MMMM D'),
        portal_link: portalLink,
        scan_image_urls: snapshots,
        scan_annotations: annotations,
        doctor_message: message,
    });

    return await generator.generatePdf();
};
