import type { LabsGqlTaskDateFilter, LabsGqlListWorkflowTasksFilter } from '@orthly/graphql-schema';

export function cleanTasksFilter(filter?: LabsGqlListWorkflowTasksFilter): LabsGqlListWorkflowTasksFilter | null {
    if (!filter) {
        return null;
    }
    const cleanTaskDateFilter = (dateFilter?: LabsGqlTaskDateFilter | null): LabsGqlTaskDateFilter | null => {
        return dateFilter ? { comparator: dateFilter.comparator, comparison_value: dateFilter.comparison_value } : null;
    };
    return {
        type: filter.type,
        completed_at: cleanTaskDateFilter(filter.completed_at),
        created_at: cleanTaskDateFilter(filter.created_at),
        completed: filter.completed,
        assigned: filter.assigned,
        design_review_required: filter.design_review_required,
        assigned_user_ids: filter.assigned_user_ids,
        exclude_assigned_user_ids: filter.exclude_assigned_user_ids,
        completed_by_ids: filter.completed_by_ids,
        is_revision: filter.is_revision,
        responsible_party: filter.responsible_party,
    };
}
