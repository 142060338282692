import { useIsRejectionNoteRequired } from '../GuidedWaxup.util';
import { GUIDED_WAXUP_MOBILE_NAV_HEIGHT, MOBILE_DIALOG_HEIGHT, NAV_BUTTON_STYLES } from '../VisualConstants.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction, useGuidedWaxupSelector } from '../state/GuidedWaxupState';
import { PracticeFullScreenDialog } from '@orthly/dentin';
import { LabsGqlGuidedWaxupPresetStatus, LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { ChevronLeft, SimpleInput } from '@orthly/ui';
import { Button, FlossPalette, Text, stylesFactory, Grid, Slide } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    navArea: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%',
        height: GUIDED_WAXUP_MOBILE_NAV_HEIGHT,
        borderTop: `2px solid ${FlossPalette.STROKE_LIGHT}`,
        backgroundColor: FlossPalette.WHITE,
    },
    dialog: {
        height: MOBILE_DIALOG_HEIGHT,
    },
    dialogContentContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'center',
        height: 70,
        backgroundColor: FlossPalette.TAN,
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    },
    goBackButton: {
        padding: 8,
        minWidth: 0,
        background: FlossPalette.TAN,
        border: 'none',
    },
    rejectionNoteContainer: {
        padding: 15,
    },
    title: {
        alignSelf: 'center',
    },
    navButtonContainer: {
        width: '50%',
        padding: '0px 5px',
    },
    navButtons: {
        ...NAV_BUTTON_STYLES,
    },
    textInput: {
        marginTop: 8,
    },
}));

export const GeneralRejectionNoteMobileDialog: React.VFC<{
    selectedRevisionNotes?: string;
}> = ({ selectedRevisionNotes }) => {
    const classes = useStyles();
    const { selectedDesignRevisionAlreadyReviewed, selectedTab, revisionId, setIsMobileRejectionNoteOpen } =
        useGuidedWaxupContext();
    const setPresetRejectionNotes = useGuidedWaxupAction('SET_PRESET_REJECTION_NOTES');
    const setPresetStatus = useGuidedWaxupAction('SET_PRESET_STATUS');
    const generalRejectionNote = useGuidedWaxupSelector(s => s.presets[selectedTab]?.notes);
    const [rejectionNotes, setRejectionNotes] = React.useState<string | undefined>(
        !generalRejectionNote ? '' : generalRejectionNote,
    );
    const viewingHistoricalDesign = selectedDesignRevisionAlreadyReviewed || revisionId !== undefined;
    const isRejectionNoteRequired = useIsRejectionNoteRequired();

    return (
        <PracticeFullScreenDialog>
            <Slide in={true} direction={'left'}>
                <Grid container direction={'column'}>
                    <Grid item className={classes.dialog}>
                        <Grid item container className={classes.dialogContentContainer}>
                            <Grid item xs={4}>
                                <Button
                                    variant={'secondary'}
                                    className={classes.goBackButton}
                                    onClick={() => {
                                        setIsMobileRejectionNoteOpen(false);
                                    }}
                                >
                                    {<ChevronLeft />}
                                </Button>
                            </Grid>
                            <Grid item xs={8} className={classes.title}>
                                <Text variant={'body2'} medium>
                                    {viewingHistoricalDesign ? 'Previous rejection note' : 'Add a rejection note'}
                                </Text>
                            </Grid>
                        </Grid>
                        {viewingHistoricalDesign ? (
                            <Grid item container direction={'column'} className={classes.rejectionNoteContainer}>
                                <Grid item>
                                    <Text variant={'body2'} medium>
                                        Rejection Note
                                    </Text>
                                </Grid>
                                <Grid item>
                                    <Text variant={'body2'}>{selectedRevisionNotes}</Text>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid item className={classes.rejectionNoteContainer}>
                                <Grid item container direction={'column'}>
                                    <Grid item container justifyContent={'space-between'}>
                                        <Grid item>
                                            <Text variant={'body2'} medium>
                                                Overall Rejection Note
                                            </Text>
                                        </Grid>
                                        <Grid item>
                                            <Text
                                                variant={'body2'}
                                                color={isRejectionNoteRequired ? 'ATTENTION_FOREGROUND' : 'BLACK'}
                                            >
                                                {isRejectionNoteRequired ? 'Required field' : 'Optional'}
                                            </Text>
                                        </Grid>
                                    </Grid>
                                    <Grid item className={classes.textInput}>
                                        <SimpleInput
                                            onChange={value => {
                                                setRejectionNotes(value);
                                            }}
                                            value={rejectionNotes}
                                            label={'Tell your designer what could be done differently'}
                                            fullWidth
                                            TextFieldProps={{
                                                multiline: true,
                                                rows: 5,
                                                autoFocus: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item container className={classes.navArea}>
                        {!viewingHistoricalDesign && (
                            <Grid item className={classes.navButtonContainer}>
                                <Button
                                    variant={'secondary-gray'}
                                    onClick={() => {
                                        setIsMobileRejectionNoteOpen(false);
                                    }}
                                    className={classes.navButtons}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        )}
                        <Grid
                            item
                            className={classes.navButtonContainer}
                            style={{
                                width: viewingHistoricalDesign ? '100%' : '50%',
                            }}
                        >
                            <Button
                                variant={'primary'}
                                onClick={() => {
                                    if (!viewingHistoricalDesign) {
                                        setPresetRejectionNotes({
                                            presetName: LabsGqlGuidedWaxupPresetType.GeneralView,
                                            rejectionNotes,
                                        });
                                        setPresetStatus({
                                            presetName: LabsGqlGuidedWaxupPresetType.GeneralView,
                                            status: LabsGqlGuidedWaxupPresetStatus.Rejected,
                                        });
                                    }
                                    setIsMobileRejectionNoteOpen(false);
                                }}
                                className={classes.navButtons}
                            >
                                {viewingHistoricalDesign ? 'Back' : 'Save'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Slide>
        </PracticeFullScreenDialog>
    );
};
