import type { ColorRampData } from '../ColorRamp';
import type { CaseMetadata } from './CaseMetadata';
import type { CollisionsMap, MarginLinesMap, RestorativeModel, ScanModels } from './FinishingApp.types';
import type { InsertionDepthGenerator } from './InsertionDepthGenerator';
import { SceneAppearanceManager } from './SceneAppearanceManager';
import type { SceneAppearanceState, SceneAppearance, MinimalOperationsManager } from './SceneAppearanceManager.types';
import { createDefaultSceneAppearance } from './SceneAppearanceManager.utils';
import { SceneMaterialManager } from './SceneMaterialManager';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import React from 'react';

// Creates a `SceneAppearanceManager` to manage the scene and returns the scene appearance, stored as state
export function useSceneAppearanceManager(
    scanModels: ScanModels,
    restorativeModels: ArrayMin1<RestorativeModel>,
    marginLines: MarginLinesMap,
    collisions: CollisionsMap,
    insertionDepthGenerator: InsertionDepthGenerator,
    caseMetadata: CaseMetadata,
    colorRamps: ColorRampData | undefined,
    operationsManager: MinimalOperationsManager,
    enableTubeMarginLine: boolean,
): SceneAppearanceState {
    const [appearance, setAppearance] = React.useState<SceneAppearance>(createDefaultSceneAppearance);

    const manager = React.useMemo(() => {
        const toothNumbers = restorativeModels.map(el => el.toothNumber);
        const materialManagerOptions = { adaptInsertionAxis: true } as const;
        const materialManager = new SceneMaterialManager(
            toothNumbers,
            operationsManager,
            insertionDepthGenerator,
            colorRamps,
            materialManagerOptions,
        );

        return new SceneAppearanceManager(
            setAppearance,
            materialManager,
            operationsManager,
            scanModels,
            restorativeModels,
            marginLines,
            collisions,
            caseMetadata.restorationJaw,
            enableTubeMarginLine,
        );
    }, [
        scanModels,
        restorativeModels,
        marginLines,
        collisions,
        insertionDepthGenerator,
        colorRamps,
        caseMetadata,
        operationsManager,
        enableTubeMarginLine,
    ]);

    return React.useMemo(
        () => ({
            appearance,
            manager,
        }),
        [appearance, manager],
    );
}
