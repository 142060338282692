import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { FlossPalette, Grid, Text, styled } from '@orthly/ui-primitives';
import dayjs from 'dayjs';

const AdminOrderDetailSummaryTitleLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailSummaryTitleLabSalesOrder_Fragment on LabSalesOrderDTO {
        patient {
            first_name
            last_name
            birthday
            gender
            order_count
        }
    }
`);

const GENDER_INITIALS: Record<string, string> = { Male: 'M', Female: 'F' };

const PatientOrderCountWrapper = styled(Grid)({
    backgroundColor: FlossPalette.DARK_TAN,
    height: 16,
    width: 16,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
    marginTop: 2,
});

interface OrderDetailSummaryTitleProps {
    salesOrder: FragmentType<typeof AdminOrderDetailSummaryTitleLabSalesOrder_Fragment>;
}

export const OrderDetailSummaryTitle: React.FC<OrderDetailSummaryTitleProps> = ({ salesOrder }) => {
    const { patient } = getFragmentData(AdminOrderDetailSummaryTitleLabSalesOrder_Fragment, salesOrder);

    return (
        <Grid container item alignItems={'center'} spacing={1} wrap={'nowrap'}>
            <Grid item>
                <Text variant={'h5'}>
                    {patient.first_name} {patient.last_name}
                </Text>
            </Grid>
            <Grid item>
                <PatientOrderCountWrapper item container>
                    <Text color={'GRAY'} variant={'caption'} medium>
                        {patient.order_count}
                    </Text>
                </PatientOrderCountWrapper>
            </Grid>
            <Grid item>
                <Text variant={'body1'} color={'GRAY'} style={{ whiteSpace: 'nowrap' }}>
                    {patient.birthday && dayjs().diff(dayjs(patient.birthday), 'years')}{' '}
                    {patient.gender && GENDER_INITIALS[patient.gender]}
                </Text>
            </Grid>
        </Grid>
    );
};
