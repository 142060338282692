import type { LabsGqlDesignFileValidationError } from '@orthly/graphql-schema';
import { ActionCard } from '@orthly/ui';
import { getFormattedDesignValidationMessage } from '@orthly/veneer';
import React from 'react';

export const DesignFileValidationErrorsCard: React.FC<{
    validationErrors: LabsGqlDesignFileValidationError[];
}> = props => {
    const [errorAcked, setErrorAcked] = React.useState(false);
    if (errorAcked) {
        return null;
    }
    return (
        <ActionCard
            style={{ marginTop: 10 }}
            title={`Design File Validation Errors`}
            subtitle={getFormattedDesignValidationMessage(
                props.validationErrors,
                'The uploaded design file failed validation for the following reasons:',
                'Please verify the design file is valid and for the right patient as part of your review',
            )}
            variant={'alert'}
            secondaryAction={{ onClick: () => setErrorAcked(true), text: 'Dismiss' }}
        />
    );
};
