import type { AllNotes, NotesSummaryOrder, AlertsText } from './NotesSummary.types';
import { useCreateNoteTiles } from './NotesSummary.utils';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface NotesSummaryGridProps {
    order: NotesSummaryOrder;
    refetchOrder: () => Promise<void>;
    allNotes: AllNotes;
    alertsText: AlertsText;
    showLabSlipAlerts?: boolean;
}

export const NotesSummaryGrid: React.FC<NotesSummaryGridProps> = props => {
    const { order, refetchOrder, allNotes, alertsText, showLabSlipAlerts = false } = props;

    const notesTiles = useCreateNoteTiles(order, allNotes, alertsText, refetchOrder, undefined, showLabSlipAlerts);

    return (
        <Grid container direction={'row'} spacing={3}>
            {notesTiles.map(el => {
                return (
                    <Grid key={el.key} item xs={6}>
                        {el}
                    </Grid>
                );
            })}
        </Grid>
    );
};
