import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { Button } from '@orthly/ui-primitives';
import { useCopyToClipboard } from '@orthly/veneer';
import React from 'react';

const AdminOrderDetailToolbarCopyIdLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailToolbarCopyIdLabSalesOrder_Fragment on LabSalesOrderDTO {
        id
    }
`);

interface OrderDetailToolbarCopyIdProps {
    salesOrder: FragmentType<typeof AdminOrderDetailToolbarCopyIdLabSalesOrder_Fragment>;
}

export const OrderDetailToolbarCopyId: React.VFC<OrderDetailToolbarCopyIdProps> = ({ salesOrder: salesOrderProp }) => {
    const salesOrder = getFragmentData(AdminOrderDetailToolbarCopyIdLabSalesOrder_Fragment, salesOrderProp);
    const copyToClipboard = useCopyToClipboard();

    const copyId = () => {
        copyToClipboard({
            text: salesOrder.id,
            successMessage: `Full order id copied to clipboard!`,
            errorMessage: `Failed to copy order id :(`,
        });
    };

    return (
        <Button onClick={copyId} variant={`ghost`} startIcon={'CopyLinkIcon'} style={{ marginRight: 8 }}>
            Order ID
        </Button>
    );
};
