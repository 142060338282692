import { OrderDetailBlock } from '../OrderDetailBlock';
import type { OrderDetailItemsTableItemActionProps } from './OrderDetailItemsTable.types';
import type {
    FragmentType,
    VeneerOrderDetailItemsTableLabSalesOrderItemFullDisplayInfo_FragmentFragment,
} from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import type { GridProps } from '@orthly/ui-primitives';
import { FlossPalette, Grid, styled, Text, useScreenIsXs } from '@orthly/ui-primitives';
import React from 'react';

const VeneerOrderDetailItemsTableLabSalesOrderItemFullDisplayInfo_Fragment = graphql(`
    fragment VeneerOrderDetailItemsTableLabSalesOrderItemFullDisplayInfo_Fragment on LabSalesOrderItemFullDisplayInfoDTO {
        title
        individual_items {
            item_id
            label
        }
        major_details {
            label
            value
        }
        minor_details {
            label
            value
        }
        shade_details {
            label
            value
        }
        notes
    }
`);

interface OrderDetailItemsTableEntryFactRowProps {
    label: string;
    value: string | React.ReactNode;
    variant: 'major' | 'minor' | 'notes';
}

const OrderDetailItemsTableEntryFactRow: React.VFC<OrderDetailItemsTableEntryFactRowProps> = ({
    label,
    value,
    variant,
}) => {
    const isMajor = variant === 'major';
    const isXs = useScreenIsXs();

    return (
        <Grid container>
            <Grid item xs={12} sm={5} md={4} lg={3} style={{ maxWidth: isMajor ? 260 : 264 }}>
                <Text
                    variant={'body2'}
                    color={isMajor ? 'BLACK' : 'GRAY'}
                    medium={(isMajor && isXs) || variant === 'notes'}
                >
                    {label}
                </Text>
            </Grid>
            <Grid item xs={12} sm style={{ paddingLeft: !isMajor && !isXs ? 10 : undefined }}>
                <Text variant={'body2'} color={'BLACK'} style={{ whiteSpace: 'pre-line' }}>
                    {value}
                </Text>
            </Grid>
        </Grid>
    );
};

interface OrderDetailItemsTableShadesFactValueProps {
    shadeDetails: VeneerOrderDetailItemsTableLabSalesOrderItemFullDisplayInfo_FragmentFragment['shade_details'];
}

const OrderDetailItemsTableShadesFactValue: React.VFC<OrderDetailItemsTableShadesFactValueProps> = ({
    shadeDetails,
}) => {
    return (
        <>
            {shadeDetails.map((shade, idx) => {
                const hasValue = shade.value.toLowerCase() !== 'n/a';

                return (
                    <React.Fragment key={shade.label}>
                        <Text
                            component={'span'}
                            variant={'body2'}
                            color={hasValue ? 'BLACK' : 'GRAY'}
                            style={{ marginRight: 4 }}
                        >
                            {shade.label}
                        </Text>
                        <Text
                            component={'span'}
                            variant={'body2'}
                            color={hasValue ? 'BLACK' : 'GRAY'}
                            medium={hasValue}
                            style={{ marginRight: idx !== shadeDetails.length ? 8 : undefined }}
                        >
                            {shade.value}
                        </Text>
                    </React.Fragment>
                );
            })}
        </>
    );
};

const EntryContainer = styled((props: Omit<GridProps, 'item'>) => <Grid item {...props} />)({
    '&:not(:first-child)': {
        borderTop: `1px solid ${FlossPalette.STROKE_DARK}`,
        paddingTop: 16,
    },
    '&:not(:last-child)': {
        paddingBottom: 16,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
});

const EntryTitleContainer = styled((props: Omit<GridProps, 'item'>) => <Grid item {...props} />)({
    display: 'flex',
    flexDirection: 'row',
});

const EntryItemActionContainer = styled(Grid)({
    marginLeft: 12,
});

const EntryMajorDetailsContainer = styled((props: Omit<GridProps, 'item'>) => <Grid item {...props} />)({
    borderLeft: `2px solid ${FlossPalette.STROKE_DARK}`,
    paddingLeft: 12,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
});

const EntryMinorDetailsContainer = styled((props: Omit<GridProps, 'item'>) => <Grid item {...props} />)({
    display: 'flex',
    flexDirection: 'column',
});

interface OrderDetailItemsTableEntryProps {
    fullDisplayInfo: VeneerOrderDetailItemsTableLabSalesOrderItemFullDisplayInfo_FragmentFragment;
    ItemAction?: React.ComponentType<OrderDetailItemsTableItemActionProps>;
}

const OrderDetailItemsTableEntry: React.VFC<OrderDetailItemsTableEntryProps> = ({ fullDisplayInfo, ItemAction }) => {
    return (
        <EntryContainer>
            <EntryTitleContainer>
                <Text variant={'body1'} color={'BLACK'} medium>
                    {fullDisplayInfo.title}
                </Text>
                {ItemAction && (
                    <EntryItemActionContainer>
                        <ItemAction
                            items={fullDisplayInfo.individual_items.map(i => ({ itemId: i.item_id, label: i.label }))}
                        />
                    </EntryItemActionContainer>
                )}
            </EntryTitleContainer>
            <EntryMajorDetailsContainer>
                {fullDisplayInfo.major_details.map(detail => (
                    <OrderDetailItemsTableEntryFactRow
                        key={`${detail.label}-${detail.value}`}
                        label={detail.label}
                        value={detail.value}
                        variant={'major'}
                    />
                ))}
                {fullDisplayInfo.shade_details.length > 0 && (
                    <OrderDetailItemsTableEntryFactRow
                        label={'Shades'}
                        value={<OrderDetailItemsTableShadesFactValue shadeDetails={fullDisplayInfo.shade_details} />}
                        variant={'major'}
                    />
                )}
            </EntryMajorDetailsContainer>
            {fullDisplayInfo.notes && (
                <Grid item>
                    <OrderDetailItemsTableEntryFactRow
                        label={'NOTES'}
                        value={fullDisplayInfo.notes}
                        variant={'notes'}
                    />
                </Grid>
            )}
            <EntryMinorDetailsContainer>
                {fullDisplayInfo.minor_details.map(detail => (
                    <OrderDetailItemsTableEntryFactRow
                        key={`${detail.label}-${detail.value}`}
                        label={detail.label}
                        value={detail.value}
                        variant={'minor'}
                    />
                ))}
            </EntryMinorDetailsContainer>
        </EntryContainer>
    );
};

interface OrderDetailItemsTableProps {
    fullDisplayInfo: FragmentType<typeof VeneerOrderDetailItemsTableLabSalesOrderItemFullDisplayInfo_Fragment>[];
    variant: 'full' | 'right';
    blockActions?: React.ReactNode;
    ItemAction?: React.ComponentType<OrderDetailItemsTableItemActionProps>;
}

export const OrderDetailItemsTable: React.VFC<OrderDetailItemsTableProps> = ({
    fullDisplayInfo: fullDisplayInfoProp,
    variant,
    blockActions,
    ItemAction,
}) => {
    const fullDisplayInfo = getFragmentData(
        VeneerOrderDetailItemsTableLabSalesOrderItemFullDisplayInfo_Fragment,
        fullDisplayInfoProp,
    );

    return (
        <OrderDetailBlock title={'Items'} variant={variant} actions={blockActions}>
            <div style={{ width: '100%' }}>
                {fullDisplayInfo.map(info => (
                    <OrderDetailItemsTableEntry
                        key={`${info.individual_items.map(i => i.item_id).join('-')}`}
                        fullDisplayInfo={info}
                        ItemAction={ItemAction}
                    />
                ))}
            </div>
        </OrderDetailBlock>
    );
};
