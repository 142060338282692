import { useGetFilteredScanbodies } from '../../../AbutmentPartsCalculator/ImplantToScanbodySelector';
import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import type { LabsGqlImplantTypeLiteFragment } from '@orthly/graphql-operations';
import { useGetImplantByIdQuery } from '@orthly/graphql-react';
import { CartItemV2Utils } from '@orthly/items';
import type { LabOrderItemSKUType, ICartItemImplantMetadata } from '@orthly/items';
import { SimpleAutocomplete } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const implantSystemMetaFields = ['manufacturer', 'system', 'connection_size', 'authentic'] as const;

const useScanbodyInfo = (
    implantSystems: LabsGqlImplantTypeLiteFragment[],
    implantMetadata?: ICartItemImplantMetadata,
) => {
    const selectedImplant = implantSystems.find(
        implant =>
            implant.manufacturer === implantMetadata?.manufacturer &&
            implant.system === implantMetadata?.system &&
            implant.connection === implantMetadata?.connection_size,
    );

    // This is the full data for the specific implant the user has requested, from the list of implantSystems above.
    // This differs from selectedImplant because it will be the non-lite data model.
    const { data: implantData } = useGetImplantByIdQuery({
        variables: {
            id: selectedImplant?.id ?? '',
        },
        skip: !selectedImplant?.id,
    });

    const scanbodyTrie = useGetFilteredScanbodies(
        implantData?.getImplantById?.scanbody_relationships ?? [],
        implantMetadata?.authentic ?? undefined,
    );

    return { scanbodyTrie, implantData: implantData?.getImplantById };
};

interface ItemEditorV2ScanbodyFieldsProps
    extends Omit<ItemEditorV2BaseProps<LabOrderItemSKUType.Implant | LabOrderItemSKUType.ImplantBridge>, 'showError'> {
    implantSystems: LabsGqlImplantTypeLiteFragment[];
    implantToScanbodyId: string | null;
    setImplantToScanbodyId: (id: string | null) => void;
}

export const ItemEditorV2ScanbodyFields: React.VFC<ItemEditorV2ScanbodyFieldsProps> = ({
    item,
    updateItem,
    implantSystems,
    implantToScanbodyId,
    setImplantToScanbodyId,
}) => {
    const implantMetadata = CartItemV2Utils.getImplantMetadata(item);

    const { scanbodyTrie, implantData } = useScanbodyInfo(implantSystems, implantMetadata);

    const [scanbodyManufacturer, setScanbodyManufacturer] = React.useState<string | null>(null);
    const [scanbodyName, setScanbodyName] = React.useState<string | null>(null);
    const [scanbodySku, setScanbodySku] = React.useState<string | null>(null);

    const isInitialized = React.useRef<boolean>(false);

    // if we have a scanbody id already set, look for the scanbody in the implant data and set the fields
    React.useEffect(() => {
        if (!isInitialized.current) {
            if (implantMetadata?.scanbody_id) {
                const scanbody = implantData?.scanbody_relationships?.find(
                    scanbody => scanbody.scanbody.id === implantMetadata?.scanbody_id,
                )?.scanbody;
                if (scanbody) {
                    setScanbodyManufacturer(scanbody.manufacturer);
                    setScanbodyName(scanbody.name);
                    setScanbodySku(scanbody.sku);
                    isInitialized.current = true;
                } else if ((implantData?.scanbody_relationships?.length ?? 0) > 0) {
                    // if we have a scanbody id but can't find it in the implant data (after loading), clear it
                    updateItem({ name: 'implant_metadata', payload: { scanbody_id: null } });
                    isInitialized.current = true;
                }
                // in this case, with no scanbody and the implant hasn't loaded, we intentionally do not
                // set isInitialized to true so that we run through this hook again once the implant loads
            } else {
                isInitialized.current = true;
            }
        }
    }, [
        implantMetadata?.scanbody_id,
        implantData?.scanbody_relationships,
        scanbodyManufacturer,
        scanbodyName,
        scanbodySku,
        updateItem,
    ]);

    // if they change authentic selection or implant system, clear the scanbody fields
    const previousImplantSystemMetadata = React.useRef(
        implantMetadata ? _.pick(implantMetadata, implantSystemMetaFields) : null,
    );
    const currentImplantSystemMetadata = _.pick(implantMetadata ?? {}, implantSystemMetaFields);
    React.useEffect(() => {
        if (!_.isEqual(currentImplantSystemMetadata, previousImplantSystemMetadata.current)) {
            setScanbodyManufacturer(null);
            setScanbodyName(null);
            setScanbodySku(null);
            previousImplantSystemMetadata.current = currentImplantSystemMetadata;
        }
    }, [currentImplantSystemMetadata]);

    const selectedImplantToScanbody = React.useMemo(
        () =>
            implantData?.scanbody_relationships?.find(
                implantToScanbody =>
                    implantToScanbody.is_authentic === implantMetadata?.authentic &&
                    implantToScanbody.scanbody.manufacturer === scanbodyManufacturer &&
                    implantToScanbody.scanbody.name === scanbodyName &&
                    implantToScanbody.scanbody.sku === scanbodySku,
            ),
        [
            implantData?.scanbody_relationships,
            implantMetadata?.authentic,
            scanbodyManufacturer,
            scanbodyName,
            scanbodySku,
        ],
    );

    // if we have a selected scanbody, set that on the item and set the relationship id
    // otherwise, if either id is set, clear them
    React.useEffect(() => {
        if (selectedImplantToScanbody) {
            if (selectedImplantToScanbody.scanbody.id !== implantMetadata?.scanbody_id) {
                updateItem({
                    name: 'implant_metadata',
                    payload: { scanbody_id: selectedImplantToScanbody.scanbody.id },
                });
            }
            if (selectedImplantToScanbody.id !== implantToScanbodyId) {
                setImplantToScanbodyId(selectedImplantToScanbody.id);
            }
        } else if (isInitialized.current) {
            if (implantMetadata?.scanbody_id) {
                updateItem({ name: 'implant_metadata', payload: { scanbody_id: null } });
            }
            if (implantToScanbodyId) {
                setImplantToScanbodyId(null);
            }
        }
    }, [
        selectedImplantToScanbody,
        implantMetadata?.scanbody_id,
        implantToScanbodyId,
        updateItem,
        setImplantToScanbodyId,
    ]);

    return (
        <>
            <Grid item xs={12} md={4}>
                <SimpleAutocomplete
                    label={'Scan Body Manufacturer'}
                    key={`${implantMetadata?.authentic}_${scanbodyManufacturer}`}
                    variant={'standard'}
                    options={Object.keys(scanbodyTrie)}
                    AutocompleteProps={{ value: scanbodyManufacturer }}
                    onChange={value => {
                        setScanbodyManufacturer(value);
                        setScanbodyName(null);
                        setScanbodySku(null);
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <SimpleAutocomplete
                    label={'Scan Body Name'}
                    key={`${scanbodyManufacturer}_${scanbodyName}`}
                    variant={'standard'}
                    options={Object.keys(scanbodyTrie[scanbodyManufacturer ?? ''] ?? [])}
                    AutocompleteProps={{ value: scanbodyName }}
                    onChange={value => {
                        setScanbodyName(value);
                        setScanbodySku(null);
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <SimpleAutocomplete
                    label={'Scan Body'}
                    key={`${scanbodyManufacturer}_${scanbodyName}_${scanbodySku}`}
                    variant={'standard'}
                    options={scanbodyTrie[scanbodyManufacturer ?? '']?.[scanbodyName ?? '']?.map(s => s.sku) ?? []}
                    AutocompleteProps={{ value: scanbodySku }}
                    onChange={value => {
                        setScanbodySku(value);
                    }}
                />
            </Grid>
        </>
    );
};
