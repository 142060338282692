import type { AdminLabsOrderActionProps } from '../actions/order-action-types';
import { CreateActionForm } from './CreateActionForm';
import { useOrderTicketsRefetch } from './OrderTickets.utils';
import { useCreateTicketMutation } from '@orthly/graphql-react';
import { RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

export const CreateTicketDialog: React.FC<AdminLabsOrderActionProps> = props => {
    const [open, setOpen] = React.useState<boolean>(false);
    const refetchTickets = useOrderTicketsRefetch(props.order.id);

    const { submit, submitting } = useRootActionCommand(useCreateTicketMutation(), {
        successMessage: `Ticket created!`,
        onSuccess: () => {
            refetchTickets();
            setOpen(false);
        },
    });

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            onClose={() => setOpen(false)}
            title={`Create Ticket`}
            content={
                !open ? null : (
                    <CreateActionForm
                        orderId={props.order.id}
                        onSubmit={result => {
                            void submit({
                                data: {
                                    order_id: props.order.id,
                                    action: {
                                        type_id: result.type_id,
                                        notes: result.notes,
                                        activate_at: result.activate_at,
                                        due_at: result.due_at,
                                        enable_followup_action: result.enable_followup_action,
                                    },
                                },
                            });
                        }}
                    />
                )
            }
            CustomButton={() => (
                <Button variant={'ghost'} startIcon={'AddIcon'} onClick={() => setOpen(true)}>
                    Add
                </Button>
            )}
        />
    );
};
