import { useFeatureFlag } from '../Providers/LaunchDarkly';
import { LabsGqlAlignerPlan } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { LoadBlocker, Green, Medium } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

// It's pretty annoying to use inline graphql in this file,
// since most users of the types are simple nested functions.
// Thus, this data represents all we actually care about, and type checks will handle making sure callers provide it sufficiently.
interface AlignerPlansStepsData {
    steps_count: {
        overall: number;
        lower: number;
        upper: number;
    };
}

interface PriceConfigInterface {
    max_dual_arch: string | number;
    max_single_arch: string | number;
    on_demand_base: string | number;
    on_demand_per_aligner: string | number;
    twenty_dual_arch: string | number;
    twenty_single_arch: string | number;
}

export interface AlignerPlanDetails {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    steps: React.ReactNode;
    description: React.ReactNode;
    price: React.ReactNode;
    calculated_price: (treatment_plan: AlignerPlansStepsData) => React.ReactNode;
    unavailability: (treatment_plan: AlignerPlansStepsData) => false | React.ReactNode;
    refinements: React.ReactNode;
    retainers: React.ReactNode;
    expiry: React.ReactNode;
}

interface PlanDetailsWithoutConfig extends Omit<AlignerPlanDetails, 'calculated_price'> {
    price: (price_config: PriceConfigInterface) => React.ReactNode;
    calculated_price: (price_config: PriceConfigInterface, treatment_plan: AlignerPlansStepsData) => React.ReactNode;
}

// prettier-ignore
const total_aligners_for_plan = (treatment_plan: AlignerPlansStepsData) =>
    treatment_plan.steps_count.upper +
    treatment_plan.steps_count.lower;

const is_dual_arch_plan = (treatment_plan: AlignerPlansStepsData) =>
    treatment_plan.steps_count.upper > 1 && treatment_plan.steps_count.lower > 1;

// the dollars > 0 check avoids nonsense prices and NaN from failed Number conversion
const format_price = (dollars: number) => (dollars > 0 ? Format.currency(dollars, `dollars`, false) : `Pricing error`);

/* eslint-disable max-len */
// prettier would make an absolute mess out of this beautiful configuration
// prettier-ignore
const ALIGNER_PLANS_DETAILS: Record<LabsGqlAlignerPlan, PlanDetailsWithoutConfig> = {
    [LabsGqlAlignerPlan.OnDemand]: {
        title: <>Dandy <Green>On Demand</Green></>,
        subtitle: <>Very Mild Cases</>,
        steps: <>A la carte</>,
        description: <>Pay for exactly what you<br />need and nothing more</>,
        price: config => <><Medium>${config.on_demand_base}</Medium> base price<br /><Medium>+ ${config.on_demand_per_aligner}</Medium> / aligner</>,
        calculated_price: (config, plan) => format_price(Number(config.on_demand_base) + Number(config.on_demand_per_aligner) * total_aligners_for_plan(plan)),
        unavailability: plan => plan.steps_count.overall > 10 && `This treatment plan exceeds the maximum steps supported by this plan`,
        refinements: <Medium>+ $100</Medium>,
        retainers: <>Single retainer <Medium>+ $50</Medium><br />Set of retainers <Medium>+ $85</Medium></>,
        expiry: <><Medium>1</Medium> year</>,
    },
    [LabsGqlAlignerPlan.Dandy20]: {
        title: <>Dandy <Green>20</Green></>,
        subtitle: <>Mild to Moderate Cases</>,
        steps: <>Up to <Medium>20</Medium> steps</>,
        description: <>The perfect option for less<br />than 1 year treatment</>,
        price: config => <>Dual arch <Medium>${config.twenty_dual_arch}</Medium><br />Single arch <Medium>${config.twenty_single_arch}</Medium></>,
        calculated_price: (config, plan) => format_price(is_dual_arch_plan(plan) ? Number(config.twenty_dual_arch) : Number(config.twenty_single_arch)),
        unavailability: plan => plan.steps_count.overall > 20 && `This treatment plan exceeds the maximum steps supported by this plan`,
        refinements: <><Medium>1</Medium> included</>,
        retainers: <><Medium>1</Medium> set included</>,
        expiry: <><Medium>2</Medium> years</>,
    },
    [LabsGqlAlignerPlan.DandyMax]: {
        title: <>Dandy <Green>Max</Green></>,
        subtitle: <>Mild to Complex Cases</>,
        steps: <><Medium>Unlimited</Medium> steps</>,
        description: <>An all-inclusive option<br />for all treatment types</>,
        price: config => <>Dual arch <Medium>${config.max_dual_arch}</Medium><br />Single arch <Medium>${config.max_single_arch}</Medium></>,
        calculated_price: (config, plan) => format_price(is_dual_arch_plan(plan) ? Number(config.max_dual_arch) : Number(config.max_single_arch)),
        unavailability: () => false,
        refinements: <Medium>Unlimited</Medium>,
        retainers: <><Medium>2</Medium> sets included</>,
        expiry: <><Medium>3</Medium> years</>,
    }
}
/* eslint-enable max-len */

const AlignerPriceDetails: React.VFC<{
    price: (price_config: PriceConfigInterface) => React.ReactNode;
}> = ({ price }) => {
    const price_config = useFeatureFlag(`alignerCustomPricing`);
    return (
        <LoadBlocker blocking={price_config.loading} ContainerProps={{ style: { display: `block` } }}>
            {price_config.value && price(price_config.value)}
        </LoadBlocker>
    );
};

const AlignerCalculatedPrice: React.VFC<{
    calculated_price: PlanDetailsWithoutConfig['calculated_price'];
    treatment_plan: AlignerPlansStepsData;
}> = ({ calculated_price, treatment_plan }) => {
    const price_config = useFeatureFlag(`alignerCustomPricing`);
    return (
        <LoadBlocker blocking={price_config.loading} ContainerProps={{ style: { display: `block` } }}>
            {price_config.value && calculated_price(price_config.value, treatment_plan)}
        </LoadBlocker>
    );
};

export const useAlignerPlansDetails = (): Record<LabsGqlAlignerPlan, AlignerPlanDetails> =>
    _.mapValues(
        ALIGNER_PLANS_DETAILS,
        ({ price, calculated_price, ...plan }): AlignerPlanDetails => ({
            ...plan,
            price: <AlignerPriceDetails price={price} />,
            calculated_price: plan => (
                <AlignerCalculatedPrice calculated_price={calculated_price} treatment_plan={plan} />
            ),
        }),
    );
