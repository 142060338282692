import type { OrderDetailAlignersIprBlockIprStepRow_FragmentFragment } from '@orthly/graphql-inline-react';
import { ToothUtils } from '@orthly/items';
import _ from 'lodash';

// map of tooth number to max step
export type ToothToLastStepMap = { [K: string]: number };

export class AlignersIPRUtils {
    // Converts international tooth (11-48) stored in treatment plan to conventional tooth
    // number (1-32). The international tooth is stored as '(11-48)(m/d)', for example '11m'
    // The conversion chart can be seen here: https://www.123dentist.com/understanding-dental-lingo/
    // For display, the ipr is converted to the international with the smaller standard number
    // (because they are between two teeth)
    static getGridNumberFromIPRTooth(tooth: string): string | undefined {
        try {
            const isMesial = tooth[2] === 'm';
            const intlToothNumber = tooth.substring(0, 2);
            // first digit is 1 or 2 for upper teeth
            const isUpper = ['1', '2'].includes(intlToothNumber[0] ?? '');
            // between front two teeth on either upper or lower, default to the one with smaller standard number for rendering
            if (isMesial && tooth[1] === '1') {
                return ToothUtils.INTL_TO_TOOTH_NUM_MAP[isUpper ? '11' : '41'];
            }
            // for 1 and 4 quadrants, the smaller standard number is on the mesial side
            const defaultToMesial = ['1', '4'].includes(intlToothNumber[0] ?? '');
            const toothNum = ToothUtils.INTL_TO_TOOTH_NUM_MAP[intlToothNumber];
            if (!toothNum) {
                // should not happen, but guard for malformed input
                return undefined;
            }
            // if in the correct d/m configuration in the appropriate quadrant, return
            if ((defaultToMesial && isMesial) || (!defaultToMesial && !isMesial)) {
                return toothNum;
            }
            // if needs modification, upper requires 1 subtracted from standard tooth number
            return `${isUpper ? _.toNumber(toothNum) - 1 : _.toNumber(toothNum) + 1}`;
        } catch (e: any) {
            console.error(e);
            return undefined;
        }
    }

    static getToothToLastStepMap(
        steps: readonly OrderDetailAlignersIprBlockIprStepRow_FragmentFragment[],
    ): ToothToLastStepMap {
        return steps.reduce<ToothToLastStepMap>((prevMap, step) => {
            step.interproximal_reductions.forEach(ipr => {
                const gridNumber = AlignersIPRUtils.getGridNumberFromIPRTooth(ipr.tooth);
                if (!gridNumber) {
                    return;
                }
                const previousMax = prevMap[gridNumber];
                prevMap[gridNumber] = !!previousMax && previousMax > step.step ? previousMax : step.step;
            });
            return prevMap;
        }, {});
    }
}
