import { usePartnersData } from '../../../utils/usePartners';
import type { PartnerPricesV2CsvRow } from './PartnerPricesV2.types';
import { PartnerPricesV2BulkUploader } from './PartnerPricesV2BulkUploader';
import { PartnerPricesV2DialogSubtitle } from './PartnerPricesV2DialogSubtitle';
import { useGetLabPricePartnerPricesQuery } from '@orthly/graphql-react';
import { RootActionDialog } from '@orthly/ui';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    dialogButton: {
        width: 'fit-content',
        padding: '8px 8px 8px 16px',
    },
    dialogContent: {
        padding: '0px 40px 40px 40px',
        borderTop: 'none',
        maxHeight: 500,
        overflow: 'hidden',
    },
}));

export const PartnerPricesV2BulkUploadModal: React.VFC = () => {
    const classes = useStyles();
    const { data: partnerNames } = usePartnersData();
    const partnerData = partnerNames?.listPracticeNames ?? [];
    const { data: partnerPrices } = useGetLabPricePartnerPricesQuery();
    const partnerPricesData = partnerPrices?.getLabPricePartnerPrices ?? [];
    const [openBulkImport, setOpenBulkImport] = React.useState<boolean>(false);
    const [inputRows, setInputRows] = React.useState<PartnerPricesV2CsvRow[]>([]);
    const partnerPricesHaveBeenUploaded = inputRows.length > 0;
    return (
        <Grid container justifyContent={'flex-end'}>
            <RootActionDialog
                title={partnerPricesHaveBeenUploaded ? 'Confirm partner prices' : 'Upload partner prices'}
                subtitle={
                    <PartnerPricesV2DialogSubtitle partnerPricesHaveBeenUploaded={partnerPricesHaveBeenUploaded} />
                }
                showCloseButton
                onClose={() => {
                    setInputRows([]);
                    setOpenBulkImport(false);
                }}
                buttonText={'Bulk upload partner prices'}
                buttonProps={{
                    endIcon: 'AddIcon',
                    className: classes.dialogButton,
                }}
                loading={false}
                open={openBulkImport}
                maxWidth={partnerPricesHaveBeenUploaded ? 'md' : 'sm'}
                contentProps={{
                    className: classes.dialogContent,
                }}
                setOpen={setOpenBulkImport}
                content={
                    <PartnerPricesV2BulkUploader
                        partnerData={partnerData}
                        partnerPricesData={partnerPricesData}
                        partnerPricesHaveBeenUploaded={partnerPricesHaveBeenUploaded}
                        inputRows={inputRows}
                        setInputRows={setInputRows}
                        setOpenBulkImport={setOpenBulkImport}
                    />
                }
            />
        </Grid>
    );
};
