import { ConfigureLabPriceForm } from './ConfigureLabPriceForm';
import type { LabsGqlLabPriceDefinitionResultFragment } from '@orthly/graphql-operations';
import { useUpdateLabPriceMutation } from '@orthly/graphql-react';
import type { LabsGqlUpdateLabPriceCommand } from '@orthly/graphql-schema';
import { LabsGqlLabPriceRuleType } from '@orthly/graphql-schema';
import { LoadBlocker, SimpleSelect, useChangeSubmissionFn } from '@orthly/ui';
import { Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

interface EditProductCatalogEntryFormProps {
    price: LabsGqlLabPriceDefinitionResultFragment;
    disabled: boolean;
    onSuccess?: () => Promise<void>;
}

export const EditProductCatalogEntryForm: React.FC<EditProductCatalogEntryFormProps> = ({
    price,
    onSuccess,
    disabled,
}) => {
    const [submitMtn] = useUpdateLabPriceMutation();
    const mtnSubmitter = (data: LabsGqlUpdateLabPriceCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [LabsGqlUpdateLabPriceCommand]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Catalog entry updated!', {}],
        onSuccess: async () => {
            if (onSuccess) {
                await onSuccess();
            }
        },
    });
    const submitUpdate = (args: Omit<LabsGqlUpdateLabPriceCommand, 'lab_price_id'>) => {
        return submit({ lab_price_id: price.id, ...args });
    };
    const [ruleType, setRuleType] = React.useState<LabsGqlLabPriceRuleType>(price.rule.type);
    return (
        <LoadBlocker blocking={submitting}>
            <Grid container style={{ padding: '10px 0' }}>
                <Text variant={'h6'}>Edit Lab Price</Text>
            </Grid>
            <Grid container style={{ padding: '12px 0' }}>
                <SimpleSelect
                    value={ruleType}
                    onChange={value => {
                        value && setRuleType(value as LabsGqlLabPriceRuleType);
                    }}
                    label={'Rule Type'}
                    options={Object.values(LabsGqlLabPriceRuleType)
                        .filter(o => o !== LabsGqlLabPriceRuleType.Unknown)
                        .map(o => ({ value: o, label: o }))}
                    disabled={disabled}
                />
            </Grid>
            <ConfigureLabPriceForm ruleType={ruleType} price={price} submit={submitUpdate} disabled={disabled} />
        </LoadBlocker>
    );
};
