import { useDisplayOptionsContext } from '../contexts/TableOptionsContext';
import type { Column } from '../types';
import { MUITableBody } from './Body/MUITableBody';
import { MUITableFooter } from './Footer/MUITableFooter';
import { MUITableHead } from './Head/MUITableHead';
import { MUITableLoader } from './Head/MUITableLoader';
import { MUITableFilterList } from './Toolbars/Filters/MUITableFilterList';
import { MUITableToolbar } from './Toolbars/MUITableToolbar';
import { Grid, createStyles, makeStyles, Paper, TablePrimitive as Table, ThemeProvider } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(
    () =>
        createStyles({
            root: {
                position: 'relative',
                width: '100%',
            },
            tableRoot: {
                outline: 'none',
                position: 'relative',
                width: '100%',
            },
            responsiveScroll: {
                overflowX: 'auto',
                overflow: 'auto',
                height: 'auto',
            },
            responsiveStack: {},
            toolbarGrid: {
                position: 'relative',
            },
        }),
    { name: 'MUITableWrapper' },
);

interface Props {
    loading: boolean;
    columns: Column<any>[];
    style?: React.CSSProperties;
}

const MuiTableLayoutStylesWrap: React.FC = props => {
    if (process.env.NODE_ENV === 'production') {
        return <>{props.children}</>;
    }
    // use the outer theme of whatever app we're being rendered in
    return <ThemeProvider theme={outerTheme => outerTheme}>{props.children}</ThemeProvider>;
};

export const MUITableLayout = (props: Props) => {
    const { loading } = props;
    const classes = useStyles();
    const displayOpts = useDisplayOptionsContext();
    const wrapperClass = displayOpts.responsive === 'stacked' ? classes.responsiveStack : classes.responsiveScroll;
    return (
        <MuiTableLayoutStylesWrap>
            <Paper elevation={displayOpts.elevation ?? 2} className={classes.root} style={{ ...props.style }}>
                <MUITableLoader loading={loading} />
                <Grid container spacing={0} className={classes.toolbarGrid}>
                    <MUITableToolbar />
                </Grid>
                <MUITableFilterList />
                <div data-testid={'responsive-style-div'} className={wrapperClass}>
                    <Table tabIndex={0} role={'grid'} className={classes.tableRoot}>
                        <MUITableHead columns={props.columns} />
                        <MUITableBody />
                    </Table>
                </div>
                <MUITableFooter />
            </Paper>
        </MuiTableLayoutStylesWrap>
    );
};
