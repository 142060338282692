import type {
    LabsGqlOrganizationDtoFragment,
    LabsGqlUpdateOrganizationSettingsMutationVariables,
    LabsGqlUpdateStripeLinkingMutationVariables as UpdateStripeLinkingVars,
} from '@orthly/graphql-operations';
import {
    useGetOrgQuery,
    useUpdateOrganizationSettingsMutation,
    useListOrgsWithoutRelationsQuery,
    useUpdateStripeLinkingMutation,
} from '@orthly/graphql-react';
import { LabsGqlExternalAccountRelationshipType, LabsGqlOrganizationType } from '@orthly/graphql-schema';
import { LoadBlocker, QuickForm, useChangeSubmissionFn } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface OrganizationSettingsProps {
    organizationId: string;
}

interface OrganizationSettingsFormVars {
    name: string;
    parent_id?: string;
    salesforce_account: string;
    pandadoc_contract_id: string;
    stripe_customer: string;
}

function getOrganizationSettings(organization: LabsGqlOrganizationDtoFragment): OrganizationSettingsFormVars {
    const externalAccount = (type: LabsGqlExternalAccountRelationshipType): string | undefined => {
        const entry = organization.external_accounts.find(account => account.type === type);
        return entry?.id;
    };
    return {
        name: organization.legal_name,
        parent_id: organization.parent_id || undefined,
        salesforce_account: externalAccount(LabsGqlExternalAccountRelationshipType.SalesforceAccount) ?? '',
        pandadoc_contract_id: externalAccount(LabsGqlExternalAccountRelationshipType.PandadocContractId) ?? '',
        stripe_customer: externalAccount(LabsGqlExternalAccountRelationshipType.StripeCustomer) ?? '',
    };
}

type UpdateOrganizationSettingsVars = LabsGqlUpdateOrganizationSettingsMutationVariables['data'];

export const OrganizationSettings: React.FC<OrganizationSettingsProps> = props => {
    const {
        data: organizationData,
        loading: organizationLoading,
        refetch: refetchOrganization,
    } = useGetOrgQuery({
        variables: { id: props.organizationId },
    });
    const { data: parentOrgsData, loading: parentOrgsLoading } = useListOrgsWithoutRelationsQuery({
        variables: { filter: { type: LabsGqlOrganizationType.Parent } },
    });

    const [submitMtn] = useUpdateOrganizationSettingsMutation();
    const mtnSubmitter = (data: UpdateOrganizationSettingsVars) => submitMtn({ variables: { data } });
    const { submit } = useChangeSubmissionFn<any, [UpdateOrganizationSettingsVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Organization settings updated!', {}],
    });

    const [submitStripeIdMtn] = useUpdateStripeLinkingMutation();
    const stripeIdMtnSubmitter = (variables: UpdateStripeLinkingVars) => submitStripeIdMtn({ variables });
    const { submit: submitStripeId } = useChangeSubmissionFn<any, [UpdateStripeLinkingVars]>(stripeIdMtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Organization Stripe ID updated!', {}],
    });

    const organization = organizationData?.getOrganization;
    if (!organization) {
        return null;
    }
    const organizationSettings = getOrganizationSettings(organization);

    return (
        <Grid container>
            <Grid item xs={6}>
                <LoadBlocker blocking={organizationLoading || parentOrgsLoading}>
                    <QuickForm<OrganizationSettingsFormVars>
                        disabled={!organization}
                        readOnly={!organization}
                        fields={{
                            name: { type: 'text', label: 'Organization name' },
                            parent_id: {
                                type: 'select',
                                label: 'Parent organization',
                                options: (parentOrgsData?.listOrganizations ?? []).map(org => ({
                                    value: org.id,
                                    label: org.legal_name,
                                })),
                                optional: true,
                            },
                            salesforce_account: { type: 'text', label: 'Salesforce ID', optional: true },
                            pandadoc_contract_id: { type: 'text', label: 'Pandadoc ID', optional: true },
                            stripe_customer: { type: 'text', label: 'Stripe ID', optional: true },
                        }}
                        initialValues={organizationSettings}
                        dirtySubmitOnly={true}
                        onSubmit={async result => {
                            if (
                                result.stripe_customer &&
                                organizationSettings.stripe_customer !== result.stripe_customer
                            ) {
                                /** Stripe ID update needs to be handled separately because both labs-server and retainer apps
                                 * need to react to this update */
                                await submitStripeId({
                                    partner_id: organization.id,
                                    stripe_id: result.stripe_customer,
                                });
                                return;
                            }
                            await submit({
                                id: organization.id,
                                name: result.name,
                                parentId: result.parent_id || null,
                                // The mutation will figure out which of these are actual changes
                                externalAccountUpdates: [
                                    {
                                        type: LabsGqlExternalAccountRelationshipType.SalesforceAccount,
                                        id: result.salesforce_account,
                                    },
                                    {
                                        type: LabsGqlExternalAccountRelationshipType.PandadocContractId,
                                        id: result.pandadoc_contract_id,
                                    },
                                ],
                            });
                            await refetchOrganization();
                        }}
                    />
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};
