import { PrintableSlipSectionTitle } from '../shared/PrintableSlipSectionTitle';
import type { IPrintableSlipItemProps } from './PrintableSlipItem';
import { PrintableSlipItemList } from './PrintableSlipItemList';
import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import type { IOrderItemV2DTO } from '@orthly/items';
import { OrderItemV2Utils } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface IPrintableSlipItemsSection {
    order: LabsGqlLabOrderForLabSlipFragment;
    ItemComponent: React.VFC<IPrintableSlipItemProps>;
}

/**
 * Renders items section of the Printable Slip
 * @param props
 * @returns Rendered section of the Printable Slip containing the order items details
 */
export const PrintableSlipItemsSection: React.VFC<IPrintableSlipItemsSection> = props => {
    const { order } = props;
    const items = OrderItemV2Utils.parseItems(order.items_v2);
    forceConsistentPreferenceFieldCents(items);
    return (
        <Grid>
            <PrintableSlipSectionTitle text={'Item(s)'} />
            <PrintableSlipItemList items={items} ItemComponent={props.ItemComponent} />
        </Grid>
    );
};

// This function is used to make the price_cents field on preferences consistent
// because the item comparer compares the price_cents field on preferences despite
// not being relevant at all for lab slip display.  This is a hack because we should
// just not be loading them but that's a can of worms and this is quickest resolution.
// for context: https://teamorthly.slack.com/archives/C01J1TC7J7M/p1701877464763439
// this will be removed when we remove the price_cents field from the preference field
function forceConsistentPreferenceFieldCents(items: IOrderItemV2DTO[]): void {
    items.forEach(item => {
        item.preference_fields.forEach(preferenceField => {
            if (preferenceField) {
                preferenceField.price_cents = 0;
            }
        });
    });
}
