import { AskDoctorV2DialogContent } from './AskDoctorV2DialogContent.graphql';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import type { LabsGqlDoctorRequestInitiatingFlow } from '@orthly/graphql-schema';
import { makeLazyDialog, RootActionDialog } from '@orthly/ui';
import React from 'react';

export interface AskDoctorV2DialogProps {
    order: LabsGqlLabOrderFragment;
    refetchOrder: () => Promise<any>;
    initiatingFlow: LabsGqlDoctorRequestInitiatingFlow;
    issues: string[];
    notesForDoctor?: string | null;
}

export const useAskDoctorAboutHoldDialogV2 = makeLazyDialog<AskDoctorV2DialogProps>(
    ({ open, setOpen, order, refetchOrder, initiatingFlow, issues, notesForDoctor = null }) => (
        <RootActionDialog
            title={`Configure Hold Resolution Survey`}
            open={open}
            setOpen={setOpen}
            loading={false}
            subtitle={`Issues: ${issues.join(', ')}`}
            hideButton
            showCloseButton
            content={
                <AskDoctorV2DialogContent
                    initialNotesForDoctor={notesForDoctor}
                    order={order}
                    refetchOrder={refetchOrder}
                    initiatingFlow={initiatingFlow}
                    closeDialog={() => {
                        setOpen(false);
                    }}
                />
            }
        />
    ),
);
