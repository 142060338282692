import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import { LabsGqlDenturesArchType, LabsGqlLabPriceRuleType } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';

export const DentureTryInForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.DentureTryIn>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.DentureTryIn>({
                denture_arch: {
                    type: 'select',
                    label: 'Arch',
                    options: Object.values(LabsGqlDenturesArchType),
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          denture_arch:
                              price.rule.__typename === 'LabPriceDentureTryInRuleDTO' ? price.rule.denture_arch : null,
                      }
                    : {}
            }
            onSubmit={async result => {
                await submit({
                    name: result.name,
                    rule: {
                        type: LabsGqlLabPriceRuleType.DentureTryIn,
                        denture_arch: result.denture_arch || null,
                    },
                });
            }}
            disabled={disabled}
        />
    );
};
