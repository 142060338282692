import { AnalyticsClient } from '../../../../../../utils/analyticsClient';
import { OrderItemMenuButton } from './OrderItemMenuButton';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { OrderItemV2InputUtils } from '@orthly/graphql-schema';
import type { ICartItemV2DTO, IOrderItemV2DTO } from '@orthly/items';
import { CartItemV2Utils, CartItemV2UpdateUtils } from '@orthly/items';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import { PencilOutlinedIcon, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import type { OrderDetailItemsTableItemActionItem } from '@orthly/veneer';
import { ItemEditorV2, ItemEditorV2Utils } from '@orthly/veneer';
import React from 'react';

const AdminEditOrderItemV2ButtonLabSalesOrderUpdateItems_Mutation = graphql(`
    mutation AdminEditOrderItemV2ButtonLabSalesOrderUpdateItems_Mutation($args: LabSalesOrderUpdateItemsInput!) {
        labSalesOrderUpdateItems(args: $args) {
            id
        }
    }
`);

interface EditOrderItemV2ButtonProps {
    orderId: string;
    doctorPreferencesId: string;
    itemOptions: ArrayMin1<OrderDetailItemsTableItemActionItem>;
    allItems: IOrderItemV2DTO[];
    refetch: () => Promise<unknown>;
}

export const EditOrderItemV2Button: React.VFC<EditOrderItemV2ButtonProps> = ({
    orderId,
    doctorPreferencesId,
    itemOptions,
    allItems,
    refetch,
}) => {
    const [item, setItem] = React.useState<ICartItemV2DTO | null>(null);
    const [showError, setShowError] = React.useState<boolean>(false);

    const openModal = (itemId: string) => {
        const item = allItems.find(i => i.id === itemId);
        if (!item) {
            return;
        }
        AnalyticsClient.track('Ops - Item Edit - Opened', {
            $groups: { order: orderId },
            version: 'item_v2',
        });
        setItem(item);
    };

    const updateItemsMutation = useMutation(AdminEditOrderItemV2ButtonLabSalesOrderUpdateItems_Mutation);

    const { submit, submitting } = useRootActionCommand(updateItemsMutation, {
        onSuccess: () => {
            setItem(null);
            setShowError(false);
            refetch().catch(console.error);
        },
        successMessage: 'Order item updated!',
    });

    return (
        <>
            <OrderItemMenuButton
                items={itemOptions}
                onSelect={openModal}
                onMenuOpen={() => {
                    AnalyticsClient.track('Ops - Item Edit - Menu Opened', {
                        $groups: { order: orderId },
                        version: 'item_v2',
                    });
                }}
            >
                <PencilOutlinedIcon />
            </OrderItemMenuButton>
            <RootActionDialog
                open={!!item}
                setOpen={open => !open && setItem(null)}
                // reset changes on close
                onClose={() => {
                    setItem(null);
                    setShowError(false);
                }}
                loading={submitting}
                hideButton={true}
                maxWidth={'lg'}
                title={item && `Edit ${CartItemV2Utils.getFullDisplayName(item)}`}
                content={
                    item && (
                        <ItemEditorV2
                            item={item}
                            updateItem={update => setItem(i => i && CartItemV2UpdateUtils.updateItem(i, update))}
                            isInternal={true}
                            doctorPreferencesId={doctorPreferencesId}
                            showError={showError}
                        />
                    )
                }
                actions={
                    <Button
                        variant={'primary'}
                        disabled={submitting}
                        onClick={() => {
                            if (!item) {
                                return;
                            }
                            if (!ItemEditorV2Utils.isItemComplete(item, true)) {
                                setShowError(true);
                                return;
                            }
                            const cleanedItem = ItemEditorV2Utils.cleanItem(item);
                            const items_v2_by_sku = OrderItemV2InputUtils.getOrderItemV2InputBySKU([cleanedItem]);
                            if (!items_v2_by_sku) {
                                return;
                            }
                            AnalyticsClient.track('Ops - Item Edit - Submitted', {
                                $groups: { order: orderId },
                                version: 'item_v2',
                            });
                            void submit({ args: { items_v2_by_sku, sales_order_id: orderId } });
                        }}
                    >
                        Save
                    </Button>
                }
            />
        </>
    );
};
