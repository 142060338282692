import { useOpenOrderDetailOps } from '../../../../utils/router/useOpenOrderDetail';
import { usePartnersData } from '../../../../utils/usePartners';
import { CancelOrderReturnAction } from './CancelOrderReturn';
import { LabsUtilsBase } from '@orthly/dentin';
import type { LabsGqlOrderReturnFragment, LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import { useListOrderReturnsQuery } from '@orthly/graphql-react';
import { LabsGqlOrderReturnStatus } from '@orthly/graphql-schema';
import { OrderItemV2Utils } from '@orthly/items';
import { MUITable } from '@orthly/mui-table';
import { ShippingUtils } from '@orthly/shared-types';
import { FlossPalette, Button, Grid, IconButton, Link, Tooltip, PrintIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface ReturnsTableRow extends LabsGqlOrderReturnFragment {
    order: LabsGqlSingleLabOrderFragment;
    patient: string;
    product: string;
    partner?: string;
}

function useReturnsTableRows(returns: LabsGqlOrderReturnFragment[]) {
    const { data: partnersData } = usePartnersData();
    return React.useMemo(() => {
        return returns.flatMap<ReturnsTableRow>(orderReturn => {
            const order = orderReturn.order;
            const partner = partnersData?.listPracticeNames.find(p => p.id === order.partner_id)?.name;
            return [
                {
                    ...orderReturn,
                    order,
                    partner,
                    patient: `${order.patient.first_name} ${order.patient.last_name}`,
                    product: OrderItemV2Utils.parseItems(order.items_v2)
                        .map(item => LabsUtilsBase.productsForOrderV2([item]).join(', '))
                        .join('\n'),
                },
            ];
        });
    }, [partnersData, returns]);
}

const ActionsCell: React.FC<{ row: ReturnsTableRow; onComplete: () => Promise<any> }> = props => {
    const { status } = props.row;
    const openOrder = useOpenOrderDetailOps();
    const isUnshipped = status === LabsGqlOrderReturnStatus.NotShipped;
    return (
        <Grid container style={{ width: 'auto' }} wrap={'nowrap'}>
            <Button variant={'ghost'} onClick={e => openOrder(props.row.order.id, e)}>
                View Order
            </Button>
            {isUnshipped && (
                <>
                    <IconButton href={props.row.label_url} target={'_blank'} size={'small'} style={{ marginRight: 4 }}>
                        <Tooltip title={'Print Return Label'} arrow>
                            <PrintIcon style={{ color: FlossPalette.STAR_GRASS }} />
                        </Tooltip>
                    </IconButton>
                    <CancelOrderReturnAction orderReturn={props.row} onComplete={props.onComplete} />
                </>
            )}
        </Grid>
    );
};

const TrackingCell: React.FC<{ row: ReturnsTableRow }> = props => {
    return (
        <Link
            style={{ maxWidth: 64, whiteSpace: 'normal' }}
            href={ShippingUtils.getTrackingLinkForCarrier(
                props.row.shipment.carrier,
                props.row.shipment.tracking_number,
            )}
            target={'_blank'}
        >
            <span style={{ color: FlossPalette.STAR_GRASS }}>{_.startCase(props.row.shipment.status)}:</span>{' '}
            {props.row.shipment.status_description}
        </Link>
    );
};

export const ReturnsTable: React.FC = () => {
    const listReturnsQuery = useListOrderReturnsQuery();
    const rows = useReturnsTableRows(listReturnsQuery.data?.listOrderReturns ?? []);
    return (
        <MUITable<ReturnsTableRow>
            title={`Returns`}
            loading={listReturnsQuery.loading}
            columns={[
                { name: 'Actions', render: r => <ActionsCell row={r} onComplete={() => listReturnsQuery.refetch()} /> },
                { name: 'Status', render: r => _.startCase(`${r.status}`), field: 'status' },
                { name: 'Patient', render: 'patient' },
                { name: 'Created', render: 'created_at', type: 'date' },
                {
                    name: 'Refabrication Reason',
                    render: r => <span style={{ maxWidth: 300, whiteSpace: 'pre-line' }}>{r.created_by.notes}</span>,
                },
                { name: 'Latest Tracking', render: r => <TrackingCell row={r} />, field: 'shipment.status' },
                { name: 'Product', render: 'product' },
                {
                    name: 'Doctor',
                    render: r => (
                        <>
                            {r.order.doctor_name}
                            {r.partner && (
                                <>
                                    <br />
                                    {r.partner}
                                </>
                            )}
                        </>
                    ),
                },
            ]}
            data={rows}
            rowOptions={{
                rowHover: false,
                setRowProps: row => ({ TableRowProps: { 'data-status': `${row.status}` } }),
            }}
            displayOptions={{ fixedSearch: true, download: true }}
            actions={{
                global: [
                    {
                        disabled: listReturnsQuery.loading,
                        icon: 'refresh',
                        position: 'toolbar',
                        onClick: () => listReturnsQuery.refetch().catch(console.error),
                    },
                ],
            }}
        />
    );
};
