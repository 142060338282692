import { PrintableSlipSectionTitle } from '../../../../../PrintableSlip/components/shared/PrintableSlipSectionTitle';
import { getOrderEntryQRCode } from '../../../../util/magicTouchQREntry';
import type { LabsGqlLabOrderForLabSlipFragment, LabsGqlManufacturerProfileFragment } from '@orthly/graphql-operations';
import { Grid, makeStyles } from '@orthly/ui-primitives';
import React from 'react';
import QRCode from 'react-qr-code';

const useStyles = makeStyles({
    footerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderTop: '1pt solid black',
        pageBreakInside: 'avoid',
    },
    footerTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
});

interface ILabSlipFooterProps {
    order: LabsGqlLabOrderForLabSlipFragment;
    manufacturerProfileData?: LabsGqlManufacturerProfileFragment;
    stackCode?: string;
}

/**
 * Renders the bottom most section of the Order Lab Slip.
 * Contains the QR code for order enter
 * @param props
 * @returns Bottom most section of the Order Lab Slip
 */
export const LabSlipFooter: React.VFC<ILabSlipFooterProps> = ({ order, manufacturerProfileData, stackCode }) => {
    const classes = useStyles();
    const orderEntryQrSrc = React.useMemo(
        () => getOrderEntryQRCode(order, manufacturerProfileData),
        [order, manufacturerProfileData],
    );

    if (!orderEntryQrSrc) {
        return null;
    }
    return (
        <Grid container className={classes.footerContainer} spacing={1}>
            {/* Stack Code Column */}
            {stackCode && (
                <Grid item xs={4} container justifyContent={'center'}>
                    <Grid container direction={'column'} alignItems={'center'}>
                        <QRCode value={stackCode} style={{ height: '2.5rem', width: '2.5rem' }} />
                        <PrintableSlipSectionTitle text={'Stack#'} />
                    </Grid>
                </Grid>
            )}
            {/* Order Entry QR Code Column */}
            <Grid item xs={4} container justifyContent={'center'}>
                <Grid container direction={'column'} alignItems={'center'}>
                    <QRCode value={orderEntryQrSrc} style={{ height: '2.5rem', width: '2.5rem' }} />
                    <PrintableSlipSectionTitle text={'Scan for Order Entry'} />
                </Grid>
            </Grid>
        </Grid>
    );
};
