import type { TicketSavedSearch } from '../TicketSavedSearches.types';
import {
    useFavoriteTicketSavedSearchMutation,
    useUnfavoriteTicketSavedSearchMutation,
    useCreateTicketSavedSearchMutation,
    useEditTicketSavedSearchMutation,
    useDeleteTicketSavedSearchMutation,
} from '@orthly/graphql-react';
import type { LabsGqlTicketsFilters } from '@orthly/graphql-schema';
import { LabsGqlSavedSearchVisibility } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { SaveCustomViewModal } from '@orthly/ui';
import {
    stylesFactory,
    FlossPalette,
    Dialog,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    MoreVertIcon,
    Icon,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';

const SaveModal: React.FC<{
    search: TicketSavedSearch;
    save: (props: { name: string; visibility: LabsGqlSavedSearchVisibility }) => void;
    onClose: () => void;
}> = props => {
    const originalName = props.search.__type === 'existing' ? props.search.name : 'Unsaved Search';
    const originalVisibility =
        props.search.__type === 'existing' ? props.search.visibility : LabsGqlSavedSearchVisibility.Public;
    const [editedTitle, setEditedTitle] = React.useState<string>(originalName);
    const [newPublicValue, setNewPublicValue] = React.useState(originalVisibility);

    return (
        <SaveCustomViewModal
            isSaved={false}
            onClose={props.onClose}
            onSave={() =>
                props.save({
                    name: editedTitle,
                    visibility: newPublicValue,
                })
            }
            viewTitle={originalName}
            editedTitle={editedTitle}
            setEditedTitle={setEditedTitle}
            viewIsPublic={newPublicValue === LabsGqlSavedSearchVisibility.Public}
            newPublicValue={newPublicValue === LabsGqlSavedSearchVisibility.Public}
            setNewPublicValue={isPublic =>
                setNewPublicValue(isPublic ? LabsGqlSavedSearchVisibility.Public : LabsGqlSavedSearchVisibility.Private)
            }
        />
    );
};

const useStartIconStyles = stylesFactory(() => ({
    root: { width: 'auto', paddingRight: 8, alignItems: 'center' },
    icon: { color: FlossPalette.STAR_GRASS, fontSize: 24 },
    grayIcon: { color: FlossPalette.GRAY, fontSize: 24 },
}));

const EndIcons: React.FC<{ setAnchorEl: (elem: HTMLElement | null) => void; search: TicketSavedSearch }> = props => {
    const { setAnchorEl } = props;
    const classes = useStartIconStyles();
    return (
        <>
            {props.search.__type === 'existing' && props.search.visibility === LabsGqlSavedSearchVisibility.Public && (
                <Icon icon={'Public'} className={classes.grayIcon} />
            )}
            <IconButton
                style={{ padding: 0 }}
                onClick={e => {
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                }}
            >
                <MoreVertIcon className={classes.icon} />
            </IconButton>
        </>
    );
};

const SavedSearchEndActionMenu: React.FC<{
    refetch: () => Promise<void>;
    search: TicketSavedSearch;
    activeSearch: LabsGqlTicketsFilters;
    anchorEl: HTMLElement | null;
    setAnchorEl: (el: HTMLElement | null) => void;
}> = props => {
    const { search } = props;
    const [createSavedSearch] = useCreateTicketSavedSearchMutation();
    const [editSavedSearch] = useEditTicketSavedSearchMutation();
    const [deleteSavedSearch] = useDeleteTicketSavedSearchMutation();
    const [favorite] = useFavoriteTicketSavedSearchMutation();
    const [unfavorite] = useUnfavoriteTicketSavedSearchMutation();
    const session = useSession();

    const { enqueueSnackbar } = useSnackbar();
    const { anchorEl, setAnchorEl } = props;
    const [saveDialogOpen, setSaveDialogOpen] = React.useState(false);

    const saveUnsavedSearch = async (saveProps: { name: string; visibility: LabsGqlSavedSearchVisibility }) => {
        const { name, visibility } = saveProps;

        await createSavedSearch({
            variables: {
                data: {
                    name,
                    visibility,
                    search: props.activeSearch,
                },
            },
        });
        await props.refetch();
        enqueueSnackbar('Success!', { variant: 'success' });
        setSaveDialogOpen(false);
    };

    const menuItems: { label: string; onClick: () => void }[] = [];

    if (search.__type === 'unsaved') {
        menuItems.push({
            label: 'Save',
            onClick: () => {
                setSaveDialogOpen(true);
            },
        });
    } else {
        menuItems.push({
            label: `Set visibility to ${
                search.visibility === LabsGqlSavedSearchVisibility.Public ? 'Private' : 'Public'
            }`,
            onClick: async () => {
                await editSavedSearch({
                    variables: {
                        data: {
                            search_id: search.id,
                            name: search.name,
                            search: _.omit(search.search, '__typename'),
                            visibility:
                                search.visibility === LabsGqlSavedSearchVisibility.Public
                                    ? LabsGqlSavedSearchVisibility.Private
                                    : LabsGqlSavedSearchVisibility.Public,
                        },
                    },
                });
                await props.refetch();
                enqueueSnackbar('Success!', { variant: 'success' });
            },
        });
        menuItems.push({
            label: 'Replace with current search',
            onClick: async () => {
                await editSavedSearch({
                    variables: {
                        data: {
                            search_id: search.id,
                            name: search.name,
                            search: _.omit(props.activeSearch, '__typename'),
                            visibility: search.visibility,
                        },
                    },
                });
                await props.refetch();
                enqueueSnackbar('Success!', { variant: 'success' });

                setSaveDialogOpen(true);
            },
        });

        const user_id = session?.user_id;
        const isFavorited = search.favorited_by.includes(session?.user_id ?? '');

        if (user_id) {
            menuItems.push({
                label: isFavorited ? 'Unfavorite' : 'Favorite',
                onClick: async () => {
                    const params = { variables: { data: { user_id, search_id: search.id } } };
                    await (isFavorited ? unfavorite(params) : favorite(params));
                    await props.refetch();
                    enqueueSnackbar('Success!', { variant: 'success' });
                },
            });
        }

        menuItems.push({
            label: 'Delete',
            onClick: async () => {
                window.confirm(`Are you sure you want to delete '${search.name}'`) &&
                    (await deleteSavedSearch({ variables: { data: { search_id: search.id } } }));
                await props.refetch();
                enqueueSnackbar('Success!', { variant: 'success' });
            },
        });
    }

    return (
        <>
            <Menu
                onClose={() => setAnchorEl(null)}
                onBackdropClick={e => {
                    e.stopPropagation();
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                keepMounted
            >
                {menuItems.map(val => (
                    <MenuItem
                        key={val.label}
                        onClick={e => {
                            val.onClick();
                            e.stopPropagation();
                            setAnchorEl(null);
                        }}
                    >
                        {val.label}
                    </MenuItem>
                ))}
            </Menu>

            <Dialog open={saveDialogOpen} onClose={() => setSaveDialogOpen(false)}>
                <SaveModal search={props.search} save={saveUnsavedSearch} onClose={() => setSaveDialogOpen(false)} />
            </Dialog>
        </>
    );
};

export const TicketSavedSearchEndAction: React.FC<{
    refetch: () => Promise<void>;
    search: TicketSavedSearch;
    activeSearch: LabsGqlTicketsFilters;
}> = props => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    return (
        <Grid container style={{ width: 'auto', alignItems: 'center', flexWrap: 'nowrap' }}>
            <EndIcons setAnchorEl={setAnchorEl} search={props.search} />
            <SavedSearchEndActionMenu
                refetch={props.refetch}
                search={props.search}
                activeSearch={props.activeSearch}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
            />
        </Grid>
    );
};
