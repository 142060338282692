import type { LabsGqlLabOrderWithEvents } from '@orthly/graphql-schema';
import { OrderAlertBanner } from '@orthly/veneer';
import React from 'react';

interface OrderDetialLockedOrderBannerProps {
    order: Pick<LabsGqlLabOrderWithEvents, 'is_order_locked_for_sales_demo'>;
}

export const OrderDetialLockedOrderBanner: React.VFC<OrderDetialLockedOrderBannerProps> = ({ order }) => {
    const { is_order_locked_for_sales_demo } = order;

    if (!is_order_locked_for_sales_demo) {
        return null;
    }

    return <OrderAlertBanner variant={'alert'} title={'This order is locked and cannot be modified.'} actions={[]} />;
};
