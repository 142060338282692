import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { LabsGqlOrderItemSkuType } from '@orthly/graphql-schema';
import { AbutmentPartsCalculator, OrderDetailAccordionBlock } from '@orthly/veneer';
import React from 'react';

const AdminOrderDetailAbutmentPartsCalculatorBlockLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailAbutmentPartsCalculatorBlockLabSalesOrder_Fragment on LabSalesOrderDTO {
        item_groups_display_info {
            sku
        }
    }
`);

interface OrderDetailAbutmentPartsCalculatorBlockProps {
    salesOrder: FragmentType<typeof AdminOrderDetailAbutmentPartsCalculatorBlockLabSalesOrder_Fragment>;
}

/**
 * The abutment parts calculator is a temporary tool meant to replace the use of a spreadsheet from Ops to figure
 * out which abutment part to use given a bunch of inputs. For now it uses the same feature flag as the ones to
 * gate abutment parts management because we only want to use this tool as a demo for Ops.
 *
 * We may change the flag if we find it's ok for designers to use this tool rather than the spreadsheet while they wait for the
 * fully productize solution we'll build in the Edit Implant Parts modal.
 */
export const OrderDetailAbutmentPartsCalculatorBlock: React.VFC<OrderDetailAbutmentPartsCalculatorBlockProps> = ({
    salesOrder,
}) => {
    const { item_groups_display_info } = getFragmentData(
        AdminOrderDetailAbutmentPartsCalculatorBlockLabSalesOrder_Fragment,
        salesOrder,
    );

    // We check if we have an implant or implant bridge item on the order, otherwise we hide this block
    const isImplantOrder = item_groups_display_info.some(
        ({ sku }) => sku === LabsGqlOrderItemSkuType.Implant || sku === LabsGqlOrderItemSkuType.ImplantBridge,
    );

    if (!isImplantOrder) {
        return null;
    }

    return (
        <OrderDetailAccordionBlock title={`Abutment parts calculator`} variant={'full'}>
            <AbutmentPartsCalculator />
        </OrderDetailAccordionBlock>
    );
};
