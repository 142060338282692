import type { AlignerPlanDetails } from '../../Aligners/AlignerPlans';
import {
    AlignerTreatmentPlanDetailsBox,
    AlignerTreatmentPlanDetailsRow,
    AlignerTreatmentPlanLearnMore,
} from '../AlignerTreatmentPlanReference.graphql';
import type { FragmentType, VeneerAlignerFabricationScreens_FragmentFragment } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { LabsGqlAlignerPlan } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { Button, FlossPalette, Typography } from '@orthly/ui';
import { Grid, Link, Tooltip, Icon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const treatment_plan_pretty_duration = (duration_in_months: number) =>
    `${Format.pluralize(`month`, duration_in_months, `months`)}`;

const treatment_plan_pretty_steps = (treatment_plan: VeneerAlignerFabricationScreens_FragmentFragment) =>
    `${treatment_plan.steps_count.overall} (${treatment_plan.steps_count.upper} upper, ${treatment_plan.steps_count.lower} lower)`;

const treatment_plan_pretty_extras = (treatment_plan: VeneerAlignerFabricationScreens_FragmentFragment) =>
    _.compact([
        (treatment_plan.needs_attachments.lower || treatment_plan.needs_attachments.upper) && `Attachments`,
        treatment_plan.needs_interproximal_reductions && `IPR`,
    ]).join(`, `) || `None`;

const PLANS_IN_ORDER: LabsGqlAlignerPlan[] = [
    LabsGqlAlignerPlan.OnDemand,
    LabsGqlAlignerPlan.Dandy20,
    LabsGqlAlignerPlan.DandyMax,
];

const DetailsColumn: React.VFC<{
    title: React.ReactNode;
    content: React.ReactNode;
}> = ({ title, content }) => (
    <div style={{ padding: 8, flexGrow: 1 }}>
        <Typography variant={`body2`} style={{ color: FlossPalette.GRAY }}>
            {title}
        </Typography>
        <Typography variant={`body2`} medium>
            {content}
        </Typography>
    </div>
);

const SelectableDetailsBox: React.VFC<{
    children: React.ReactNode;
    selected: boolean;
    select: () => unknown;
    disabled?: boolean;
}> = ({ children, selected, select, disabled = false }) => (
    <div style={{ padding: 8, flexGrow: 1, flexBasis: 0, display: `flex` }}>
        <div
            style={{
                padding: 6,
                backgroundColor: FlossPalette.TAN,
                borderRadius: 16,
                border: `2px solid`,
                borderColor: FlossPalette.TAN,
                width: `100%`,
                ...(selected && {
                    borderColor: FlossPalette.PRIMARY_FOREGROUND,
                    backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
                }),
                display: `flex`,
                opacity: disabled ? 0.5 : 1,
                cursor: disabled ? `auto` : `pointer`,
            }}
            onClick={disabled ? undefined : select}
        >
            <div style={{ padding: `12px 8px` }}>
                {selected ? (
                    <Icon icon={'RadioButtonCheckedIcon'} style={{ color: FlossPalette.STAR_GRASS }} />
                ) : (
                    <Icon icon={'RadioButtonUnCheckedIcon'} style={{ color: FlossPalette.STAR_GRASS }} />
                )}
            </div>
            <div style={{ flexGrow: 1, display: `flex`, flexDirection: `column` }}>{children}</div>
        </div>
    </div>
);

const VeneerAlignerFabricationScreens_Fragment = graphql(`
    fragment VeneerAlignerFabricationScreens_Fragment on DesignOrderAlignerTreatmentPlanDTO {
        steps_count {
            overall
            lower
            upper
        }

        needs_attachments {
            upper
            lower
        }

        needs_interproximal_reductions

        duration_in_months
    }
`);

export const ApproveAlignerFabricationApproving: React.VFC<{
    patient_name: string;
    treatment_plan_fragment: FragmentType<typeof VeneerAlignerFabricationScreens_Fragment>;
    plans_details: Record<LabsGqlAlignerPlan, AlignerPlanDetails>;
    version?: string;
    selected_plan?: LabsGqlAlignerPlan;
    select_plan: (plan: LabsGqlAlignerPlan) => unknown;
    approve: () => unknown;
    close: () => unknown;
}> = ({
    patient_name,
    treatment_plan_fragment,
    plans_details,
    version,
    selected_plan,
    select_plan,
    approve,
    close,
}) => {
    const treatment_plan = getFragmentData(VeneerAlignerFabricationScreens_Fragment, treatment_plan_fragment);

    return (
        <Grid item container direction={`column`} spacing={4}>
            <Grid item>
                <Typography variant={`h4`}>Aligner treatment plan approval</Typography>
                <Typography variant={`body2`}>
                    Select a pricing plan, and approve for manufacturing when you're ready.
                </Typography>
            </Grid>
            <Grid item container>
                <div style={{ margin: -8, flexGrow: 1, display: `flex`, flexDirection: `column` }}>
                    <div style={{ display: `flex` }}>
                        <div style={{ padding: `32px 16px 0 8px` }}>
                            <AlignerTreatmentPlanDetailsRow
                                content={
                                    <Typography variant={`body2`} medium style={{ color: FlossPalette.GRAY }}>
                                        Plan
                                    </Typography>
                                }
                            />
                            <AlignerTreatmentPlanDetailsRow
                                border
                                content={
                                    <Typography variant={`body2`} medium>
                                        Price
                                    </Typography>
                                }
                            />
                            <AlignerTreatmentPlanDetailsRow
                                border
                                content={
                                    <Typography variant={`body2`} medium>
                                        Refinements
                                    </Typography>
                                }
                            />
                            <AlignerTreatmentPlanDetailsRow
                                content={
                                    <Typography variant={`body2`} medium>
                                        Retainers
                                    </Typography>
                                }
                            />
                        </div>
                        {PLANS_IN_ORDER.map(plan => (
                            <SelectableDetailsBox
                                key={plan}
                                selected={selected_plan === plan}
                                select={() => select_plan(plan)}
                                disabled={!!plans_details[plan].unavailability(treatment_plan)}
                            >
                                <AlignerTreatmentPlanDetailsRow
                                    content={<Typography variant={`h6`}>{plans_details[plan].title}</Typography>}
                                />
                                <AlignerTreatmentPlanDetailsRow
                                    border
                                    content={
                                        plans_details[plan].unavailability(treatment_plan) ? (
                                            <Tooltip
                                                title={
                                                    plans_details[plan].unavailability(treatment_plan) ||
                                                    'Not available'
                                                }
                                            >
                                                <div style={{ display: `flex` }}>
                                                    <Typography variant={`body1`} medium style={{ paddingRight: 8 }}>
                                                        Not available
                                                    </Typography>
                                                    <Icon icon={'InfoOutlinedIcon'} />
                                                </div>
                                            </Tooltip>
                                        ) : (
                                            <Typography variant={`body1`} medium>
                                                {plans_details[plan].calculated_price(treatment_plan)}
                                            </Typography>
                                        )
                                    }
                                />
                                <AlignerTreatmentPlanDetailsRow
                                    border
                                    content={
                                        <Typography variant={`body2`}>{plans_details[plan].refinements}</Typography>
                                    }
                                />
                                <AlignerTreatmentPlanDetailsRow
                                    grow
                                    content={<Typography variant={`body2`}>{plans_details[plan].retainers}</Typography>}
                                />
                                <AlignerTreatmentPlanLearnMore plan_details={plans_details[plan]} />
                            </SelectableDetailsBox>
                        ))}
                    </div>
                    <AlignerTreatmentPlanDetailsBox>
                        <Typography variant={`h5`} style={{ padding: 8 }}>
                            Treatment plan
                        </Typography>
                        <div style={{ display: `flex`, flexDirection: `row` }}>
                            <DetailsColumn title={`For patient`} content={patient_name} />
                            <DetailsColumn title={`Steps`} content={treatment_plan_pretty_steps(treatment_plan)} />
                            <DetailsColumn
                                title={`Duration`}
                                content={treatment_plan_pretty_duration(treatment_plan.duration_in_months)}
                            />
                            <DetailsColumn title={`Extras`} content={treatment_plan_pretty_extras(treatment_plan)} />
                            {version && <DetailsColumn title={`Version`} content={version} />}
                        </div>
                    </AlignerTreatmentPlanDetailsBox>
                </div>
            </Grid>
            <Grid item container justifyContent={`flex-end`} spacing={2}>
                <Grid item>
                    <Button onClick={close} variant={`secondary`}>
                        Return
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={approve} variant={`primary`} disabled={!selected_plan}>
                        Next
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export const ApproveAlignerFabricationConfirming: React.VFC<{
    plan_details: AlignerPlanDetails;
    patient_name: string;
    treatment_plan_fragment: FragmentType<typeof VeneerAlignerFabricationScreens_Fragment>;
    version?: string;
    back: () => unknown;
    confirm: () => unknown;
}> = ({ plan_details, treatment_plan_fragment, patient_name, version, back, confirm }) => {
    const treatment_plan = getFragmentData(VeneerAlignerFabricationScreens_Fragment, treatment_plan_fragment);

    return (
        <Grid item container direction={`column`} spacing={4}>
            <Grid item>
                <Typography variant={`h4`}>Confirm treatment plan approval</Typography>
                <Typography variant={`body2`}>
                    Please confirm you'd like to continue with the following treatment plan:
                </Typography>
            </Grid>
            <Grid item container spacing={4}>
                <AlignerTreatmentPlanDetailsBox>
                    <div style={{ padding: 8 }}>
                        <Typography variant={`h5`}>{plan_details.title}</Typography>
                        <Typography variant={`body2`} color={`DARK_GRAY`}>
                            {plan_details.subtitle}
                        </Typography>
                        <br />
                        <Typography variant={`body2`} medium>
                            Price
                        </Typography>
                        <Typography variant={`body2`}>{plan_details.calculated_price(treatment_plan)}</Typography>
                        <br />
                        <Typography variant={`body2`} medium>
                            Refinement aligners
                        </Typography>
                        <Typography variant={`body2`}>{plan_details.refinements}</Typography>
                        <br />
                        <Typography variant={`body2`} medium>
                            Retainers
                        </Typography>
                        <Typography variant={`body2`}>{plan_details.retainers}</Typography>
                        <br />
                        <Typography variant={`body2`} medium>
                            Treatment expiry
                        </Typography>
                        <Typography variant={`body2`}>{plan_details.expiry}</Typography>
                    </div>
                </AlignerTreatmentPlanDetailsBox>
                <AlignerTreatmentPlanDetailsBox>
                    <Typography variant={`h5`} style={{ padding: 8 }}>
                        Treatment plan
                    </Typography>
                    <DetailsColumn title={`For patient`} content={patient_name} />
                    <DetailsColumn title={`Steps`} content={treatment_plan_pretty_steps(treatment_plan)} />
                    <DetailsColumn
                        title={`Duration`}
                        content={treatment_plan_pretty_duration(treatment_plan.duration_in_months)}
                    />
                    <DetailsColumn title={`Extras`} content={treatment_plan_pretty_extras(treatment_plan)} />
                    {version && <DetailsColumn title={`Version`} content={version} />}
                </AlignerTreatmentPlanDetailsBox>
            </Grid>
            <Grid item container justifyContent={`flex-end`} spacing={2}>
                <Grid item>
                    <Button onClick={back} variant={`secondary`}>
                        Back
                    </Button>
                </Grid>
                <Grid item>
                    <Button onClick={confirm} variant={`primary`}>
                        Confirm
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export const ApproveAlignerFabricationAcknowledging: React.VFC<{
    close: () => unknown;
}> = ({ close }) => (
    <Grid item container direction={`column`} spacing={4}>
        <Grid item>
            <Typography variant={`h4`}>Approved for manufacturing!</Typography>
        </Grid>
        <Grid item container direction={`column`} spacing={2}>
            <Grid item>
                <Typography variant={`body2`}>
                    We'll be in touch as soon as there are updates to this aligner order!
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant={`body2`}>
                    In the meantime, set up your{' '}
                    <Link
                        href={`/profile`}
                        style={{
                            color: FlossPalette.BURGUNDY,
                            fontWeight: `bold`,
                            cursor: `pointer`,
                        }}
                    >
                        notification preferences
                    </Link>{' '}
                    to be notified about updates on this aligner order.
                </Typography>
            </Grid>
        </Grid>
        <Grid item>
            <Button fullWidth onClick={close} variant={`primary`}>
                Close
            </Button>
        </Grid>
    </Grid>
);
