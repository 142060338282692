import { cancelAndResubmitSubtitleText } from '../utils/orderStatusUtils';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { ActionCard } from '@orthly/ui';
import { Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface CancelAndResubmitBannerProps {
    orderStatus: LabsGqlOrder['status'];
    orderActiveTaskType: string;
}

export const CancelAndResubmitBanner: React.VFC<CancelAndResubmitBannerProps> = props => {
    const { orderStatus, orderActiveTaskType } = props;

    return (
        <Grid container data-pendo-id={'cancel-and-resubmit-august-2023'}>
            <ActionCard
                variant={'alert'}
                title={'Your edits will be processed and shipped as a new order.'}
                subtitle={
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        {cancelAndResubmitSubtitleText(orderStatus, orderActiveTaskType)}
                    </Text>
                }
            />
        </Grid>
    );
};
