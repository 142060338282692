import type { Column, EventHookOptions, PaginationOptions } from '../types';
import { TableStateActions } from './TableStateActions';
import { TableStateHooksMiddleware } from './TableStateMiddleware';
import _ from 'lodash';
import type { Reducer } from 'react';
import React from 'react';
import { handleActions } from 'redux-actions';

export type ColumnFilterMod = '>' | '<' | '=';
export const ColumnFilterModOptions = ['=', '>', '<'].map(value => ({ value, label: value }));

export type ColumnFilterState = {
    columnName: string;
    filterValues: string[];
    mod: ColumnFilterMod;
};

export type ColumnSortState = {
    columnName: string;
    asc: boolean;
};

export interface MUITableState {
    selectedRows: string[];
    // These are ids of the rows that have had their detail panel toggled to the opposite of the default state
    detailPanelRows: string[];
    searchOpen: boolean;
    searchText: string | null;
    columnFilters: ColumnFilterState[];
    // whether or not a column is visible
    hiddenColumnNames: string[];
    sortColumn?: ColumnSortState;
    providedHooks: (keyof EventHookOptions<any>)[];
    pagination: {
        page: number;
        rowsPerPage: number;
        rowsPerPageOptions: number[];
    };
}

export const INITIAL_TABLE_STATE: MUITableState = {
    columnFilters: [],
    selectedRows: [],
    detailPanelRows: [],
    searchOpen: false,
    searchText: null,
    hiddenColumnNames: [],
    sortColumn: undefined,
    pagination: {
        page: 0,
        rowsPerPage: 25,
        rowsPerPageOptions: [10, 25, 50],
    },
    providedHooks: [],
};

const {
    toggleViewColumn,
    searchTextUpdate,
    toggleSearchVisible,
    updateFilterValues,
    onFilterReset,
    toggleRowSelected,
    toggleDetailPanel,
    handleAllSelect,
    changePage,
    changeRowsPerPage,
    toggleSort,
    setHiddenColumns,
    setSortColumn,
} = TableStateActions;

type S = MUITableState;

export const tableStateReducer = handleActions<MUITableState, any>(
    {
        [toggleViewColumn.SUCCEEDED]: toggleViewColumn.successReducer<S>((state, action) => {
            const columnName = action.payload;
            return {
                ...state,
                hiddenColumnNames: state.hiddenColumnNames.includes(columnName)
                    ? state.hiddenColumnNames.filter(c => c !== columnName)
                    : [...state.hiddenColumnNames, columnName],
            };
        }),
        [setHiddenColumns.SUCCEEDED]: setHiddenColumns.successReducer<S>((state, action) => {
            return {
                ...state,
                hiddenColumnNames: action.payload,
            };
        }),
        [searchTextUpdate.SUCCEEDED]: searchTextUpdate.successReducer<S>((state, action) => {
            return { ...state, searchText: action.payload };
        }),
        [toggleSearchVisible.SUCCEEDED]: toggleSearchVisible.successReducer<S>(state => {
            const searchOpen = !state.searchOpen;
            return { ...state, searchOpen, searchText: !searchOpen ? null : state.searchText };
        }),
        [updateFilterValues.SUCCEEDED]: updateFilterValues.successReducer<S>((state, action) => {
            const newFilter = action.payload;
            const otherFilters = state.columnFilters.filter(c => c.columnName !== newFilter.columnName);
            return {
                ...state,
                columnFilters: [...otherFilters, newFilter],
            };
        }),
        [onFilterReset.SUCCEEDED]: onFilterReset.successReducer<S>(state => {
            return { ...state, columnFilters: [] };
        }),
        [handleAllSelect.SUCCEEDED]: handleAllSelect.successReducer<S>((state, action) => {
            return { ...state, selectedRows: action.payload };
        }),
        [toggleRowSelected.SUCCEEDED]: toggleRowSelected.successReducer<S>((state, action): S => {
            const { selectedRows } = state;
            const row = action.payload;
            const selectedRowIds = !selectedRows.includes(row.id)
                ? [...selectedRows, row.id]
                : selectedRows.filter(r => r !== row.id);
            return { ...state, selectedRows: selectedRowIds };
        }),
        [toggleDetailPanel.SUCCEEDED]: toggleDetailPanel.successReducer<S>((state, action): S => {
            const { detailPanelRows } = state;
            const row = action.payload;
            const rowId = row.id;
            const currentlyToggled = detailPanelRows.includes(rowId);
            const selectedRowIds = currentlyToggled
                ? detailPanelRows.filter(id => id !== rowId)
                : [...detailPanelRows, rowId];
            return { ...state, detailPanelRows: selectedRowIds };
        }),
        [changePage.SUCCEEDED]: changePage.successReducer<S>((state, action): S => {
            return { ...state, pagination: { ...state.pagination, page: action.payload } };
        }),
        [changeRowsPerPage.SUCCEEDED]: changeRowsPerPage.successReducer<S>((state, action): S => {
            return { ...state, pagination: { ...state.pagination, rowsPerPage: action.payload } };
        }),
        [toggleSort.SUCCEEDED]: toggleSort.successReducer<S>((state, action): S => {
            const columnName = action.payload;
            const isCurrentSortCol = state.sortColumn?.columnName === columnName;
            const needsHide = isCurrentSortCol && !state.sortColumn?.asc;
            return {
                ...state,
                sortColumn: needsHide
                    ? undefined
                    : {
                          columnName,
                          asc: !(isCurrentSortCol && state.sortColumn?.asc),
                      },
            };
        }),
        [setSortColumn.SUCCEEDED]: setSortColumn.successReducer<S>((state, action): S => {
            return {
                ...state,
                sortColumn: action.payload,
            };
        }),
    },
    INITIAL_TABLE_STATE,
);

type ActionType = { type: keyof Omit<typeof TableStateActions, 'prototype'>; payload: any };

const TableStateContext = React.createContext<{ state: MUITableState; dispatch: React.Dispatch<ActionType> }>({
    dispatch: _v => {},
    state: INITIAL_TABLE_STATE,
});

export type MUITableStateInitialProp = Partial<
    Omit<MUITableState, 'providedHooks' | 'hiddenColumnNames' | 'sortColumn' | 'columnFilters' | 'pagination'>
>;

interface TableStateProviderProps {
    paginationOptions: PaginationOptions;
    hookOptions: EventHookOptions<any>;
    hiddenColumnNames: string[];
    sortColumn?: ColumnSortState;
    columns: Column<any>[];
    initialState?: MUITableStateInitialProp;
}

export const TableStateProvider: React.FC<TableStateProviderProps> = props => {
    const middleware = React.useMemo(() => {
        return TableStateHooksMiddleware(props.hookOptions);
    }, [props.hookOptions]);
    const defaultFilterValues = props.columns
        .flatMap(c => {
            return !!c.filter && c.filterOptions?.defaultValues ? c.filterOptions?.defaultValues : [];
        })
        .join(' ');
    const [initialSortCol, setInitialSortCol] = React.useState<ColumnSortState | undefined>(props.sortColumn);
    const [state, dispatch] = React.useReducer<Reducer<MUITableState, ActionType>>(
        (prevState, action) => {
            let result = tableStateReducer(prevState, action);
            const fakeDispatch = (otherAction: ActionType) => {
                result = tableStateReducer(result, otherAction);
            };
            middleware(action as any, result, new TableStateActions(fakeDispatch));
            return result;
        },
        {
            ...INITIAL_TABLE_STATE,
            ..._.omitBy(props.initialState ?? {}, value => typeof value === 'undefined'),
            providedHooks: (Object.keys(props.hookOptions) as (keyof EventHookOptions<any>)[]).filter(
                k => !!props.hookOptions[k],
            ),
            hiddenColumnNames: props.hiddenColumnNames,
            sortColumn: props.sortColumn,
            columnFilters: props.columns.map<ColumnFilterState>(c => {
                return { filterValues: c.filterOptions?.defaultValues ?? [], columnName: c.name, mod: '=' };
            }),
            pagination: {
                page: 0,
                rowsPerPage: props.paginationOptions.initialRowsPerPage || INITIAL_TABLE_STATE.pagination.rowsPerPage,
                rowsPerPageOptions:
                    props.paginationOptions.rowsPerPageOptions || INITIAL_TABLE_STATE.pagination.rowsPerPageOptions,
            },
        },
    );
    React.useEffect(() => {
        props.columns.forEach(c => {
            const defaults = c.filterOptions?.defaultValues;
            if (!defaults) {
                return;
            }
            const existingValues =
                state.columnFilters.find(existing => existing.columnName === c.name)?.filterValues || [];
            if (_.sortBy(existingValues).join(' ') !== _.sortBy(defaults).join(' ')) {
                dispatch(updateFilterValues(c.name, _.uniq(defaults)) as any);
            }
        });
        // eslint-disable-next-line
    }, [defaultFilterValues]);
    const [originalHiddenCols, setOriginalHiddenCols] = React.useState<string[]>(props.hiddenColumnNames);
    React.useEffect(() => {
        if (!_.isEqual(props.hiddenColumnNames, originalHiddenCols)) {
            // anything added to the original hidden columns list
            const hiddenColAdditions = _.difference(state.hiddenColumnNames, originalHiddenCols);
            setOriginalHiddenCols(props.hiddenColumnNames);
            dispatch(TableStateActions.setHiddenColumns([...hiddenColAdditions, ...props.hiddenColumnNames]) as any);
        }
    }, [originalHiddenCols, props.hiddenColumnNames, state.hiddenColumnNames]);

    React.useEffect(() => {
        if (props.sortColumn?.columnName !== initialSortCol?.columnName) {
            setInitialSortCol(props.sortColumn);
            dispatch(TableStateActions.setSortColumn(props.sortColumn) as any);
        }
    }, [initialSortCol, props.sortColumn]);

    return <TableStateContext.Provider value={{ state, dispatch }}>{props.children}</TableStateContext.Provider>;
};

export function useTableStateContext() {
    return React.useContext(TableStateContext);
}
