import type { LabsGqlLabPriceDefinitionResultFragment } from '@orthly/graphql-operations';
import {
    useListSalesforcePriceNameMappingsForLabPricesQuery,
    useSetSalesforcePriceMappingMutation,
} from '@orthly/graphql-react';
import { LoadBlocker, SimpleSelect, useChangeSubmissionFn } from '@orthly/ui';
import { Button, Grid, Icon, IconButton, Input, Text } from '@orthly/ui-primitives';
import { useCopyToClipboard } from '@orthly/veneer';
import React from 'react';

interface EditProductCatalogSalesforceMappingProps {
    price: LabsGqlLabPriceDefinitionResultFragment;
}

export const EditProductCatalogSalesforceMappingForm: React.FC<EditProductCatalogSalesforceMappingProps> = props => {
    const { price } = props;
    const copyToClipboard = useCopyToClipboard();
    const sfPriceMappingsQuery = useListSalesforcePriceNameMappingsForLabPricesQuery();
    const [selectedMapping, setMapping] = React.useState<string | null>(price.salesforce_price_mapping);
    const [submitMtn] = useSetSalesforcePriceMappingMutation({
        variables: { data: { salesforce_code: selectedMapping, price_id: price.id } },
    });
    const { submit, submitting } = useChangeSubmissionFn<any, []>(() => submitMtn(), {
        successMessage: () => ['Price salesforce mapping changed', {}],
    });
    const selectOpts = React.useMemo(() => {
        const sfPriceNames: string[] = sfPriceMappingsQuery.data?.listSalesforcePriceNameMappingsForLabPrices ?? [];
        const options = sfPriceNames.map(value => ({ value, label: value.replace('__c', '') }));
        if (price.salesforce_price_mapping) {
            options.push({
                value: price.salesforce_price_mapping,
                label: price.salesforce_price_mapping.replace('__c', ''),
            });
        }
        return options;
    }, [price.salesforce_price_mapping, sfPriceMappingsQuery.data]);

    const onClickCopy = React.useCallback(() => {
        copyToClipboard({
            text: `[LabsContractPreset__r.${price.salesforce_price_mapping}]`,
            successMessage: 'Copied!',
        });
    }, [copyToClipboard, price.salesforce_price_mapping]);

    return (
        <LoadBlocker blocking={submitting || sfPriceMappingsQuery.loading}>
            <Grid container style={{ padding: '10px 0' }}>
                <Text variant={'h6'}>Salesforce Price Mapping</Text>
            </Grid>
            <Grid container style={{ padding: '12px 0' }}>
                <SimpleSelect
                    placeholder={'Clear selection'}
                    options={selectOpts}
                    onChange={value => setMapping(value || null)}
                    label={'Salesforce Contract Preset Property'}
                    value={selectedMapping ?? ''}
                />
                <Button
                    variant={'contained'}
                    fullWidth
                    startIcon={'Save'}
                    disabled={selectedMapping === price.salesforce_price_mapping}
                    onClick={() => submit()}
                >
                    Save
                </Button>
                <Text variant={'h6'}>
                    PandaDoc Token
                    <IconButton onClick={onClickCopy}>
                        <Icon icon={'FileCopy'} />
                    </IconButton>
                </Text>
                <Input
                    fullWidth
                    readOnly
                    id={`pandadoc_token_input_${price.id}`}
                    value={`[LabsContractPreset__r.${price.salesforce_price_mapping}]`}
                    style={{ padding: 10 }}
                />
            </Grid>
        </LoadBlocker>
    );
};
