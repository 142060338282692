import type { ScanReviewDcmFileData } from '../ScanReviewDcm.utils';
import type { ToothPlacementVisualizationRecordFactory } from './ToothPlacementVisualizationTypes';
import type { DcmManager } from '@orthly/forceps';
import type * as THREE from 'three';

export function loadJawScanDcmForToothPlacementVisualization(
    jawData: ScanReviewDcmFileData,
    onLoadCallback: (
        geometry: THREE.BufferGeometry,
        dcmManager: DcmManager,
        image: HTMLImageElement,
    ) => ToothPlacementVisualizationRecordFactory,
    factorySetter: (value: React.SetStateAction<ToothPlacementVisualizationRecordFactory | null>) => void,
) {
    if (!jawData.geometry || !jawData.textureData) {
        return;
    }
    const scanImage = new Image();
    const scanGeometry = jawData.geometry.clone();
    const dcmManager = jawData.dcmFile.dcmManager;
    scanImage.onload = () => {
        factorySetter(() => onLoadCallback(scanGeometry, dcmManager, scanImage));
    };
    scanImage.src = `data:image/jpeg;base64,${jawData.textureData}`;
}
