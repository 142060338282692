import { DesignSidebarSection } from './DesignDetailSidebarSection';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrderDetailPreferences, OrderPreferencesSidebarBlock } from '@orthly/veneer';
import React from 'react';

export const DesignPreferencesSection: React.FC<{ order: Pick<LabsGqlOrder, 'items_v2'> }> = props => {
    const preferenceEntries = useOrderDetailPreferences(props.order.items_v2);
    if (preferenceEntries.length === 0) {
        return null;
    }
    return (
        <DesignSidebarSection title={'Preferences'}>
            <OrderPreferencesSidebarBlock order={props.order} />
        </DesignSidebarSection>
    );
};
