import type { IAbutmentRelationshipRow } from './types';
import { useUpdateAbutmentPartToImplantScanbodyMutation } from '@orthly/graphql-react';
import { ActionCard, QuickForm } from '@orthly/ui';
import { fromIsPreferredToPriorityNumber } from '@orthly/veneer';
import React from 'react';

interface UpdateAbutmentPartRelationshipRow {
    isPreferred: boolean;
}
interface AbutmentPartRelationshipRowProps {
    data: IAbutmentRelationshipRow;
    afterSaved: () => void;
}
export const AbutmentPartRelationshipRow: React.FC<AbutmentPartRelationshipRowProps> = ({
    data: abutmentPartRelationship,
    afterSaved,
}) => {
    const { abutmentPartId, implantToScanbodyId, implantTypeId, isPreferred } = abutmentPartRelationship;
    const [updateAbutmentPartRelationship, { error: updateError, loading }] =
        useUpdateAbutmentPartToImplantScanbodyMutation();

    return (
        <div style={{ paddingTop: 16, paddingBottom: 16, maxWidth: `400px` }}>
            {updateError && <ActionCard variant={'alert'} title={'Error'} subtitle={updateError.message} />}
            <QuickForm<UpdateAbutmentPartRelationshipRow>
                fields={{
                    isPreferred: { type: 'boolean', label: 'Is Preferred?' },
                }}
                initialValues={{
                    isPreferred,
                }}
                onSubmit={async row => {
                    await updateAbutmentPartRelationship({
                        variables: {
                            data: {
                                abutment_part_id: abutmentPartId,
                                implant_to_scanbody_id: implantToScanbodyId,
                                implant_type_id: implantTypeId,
                                priority: fromIsPreferredToPriorityNumber(row.isPreferred),
                            },
                        },
                    });
                    afterSaved();
                }}
                submitButtonProps={{
                    disabled: loading,
                    children: `Update abutment part relationship`,
                }}
            />
        </div>
    );
};
