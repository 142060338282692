import { useActiveInboxViewIsEdited } from '../components/InboxCustomViewNavItem';
import { useOpsInboxAction } from './Inbox.actions';
import { useInboxTasksQueryVars, useInboxState, useCurrentInboxSort } from './Inbox.selectors';
import type { ApolloError } from '@apollo/client';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlWorkflowTaskFragment, LabsGqlActiveInternalDesignTaskFragment } from '@orthly/graphql-operations';
import type { WorkflowTasksByListViewQueryHookResult } from '@orthly/graphql-react';
import { useWorkflowTasksByListViewQuery, useGetActiveInternalDesignTasksQuery } from '@orthly/graphql-react';
import constate from 'constate';
import React from 'react';

export interface UseInboxTasksResult {
    orderIds: string[];
    tasks: LabsGqlWorkflowTaskFragment[];
    loading: boolean;
    refetch: WorkflowTasksByListViewQueryHookResult['refetch'];
    invalidFiltersMessage?: string;
    designTaskLookup: Record<string, LabsGqlActiveInternalDesignTaskFragment>;
    error?: ApolloError;
}

// Utility for tracking usages of saved searches for Tasks
function useTrackTaskSavedSearchLoaded() {
    const savedSearchId = useInboxState(s => s.view?.id);
    const activeViewEdited = useActiveInboxViewIsEdited();
    return React.useCallback(() => {
        if (savedSearchId && !activeViewEdited) {
            BrowserAnalyticsClientFactory.Instance?.track('Ops - Portal - Saved Search Loaded', {
                search_id: savedSearchId,
                search_type: 'tasks',
            });
        }
    }, [savedSearchId, activeViewEdited]);
}

function _useInboxTasks(): UseInboxTasksResult {
    const variables = useInboxTasksQueryVars();
    const sort = useCurrentInboxSort();
    const selectedTaskIds = useInboxState(s => s.selectedTaskIds);
    const setSelectedTaskIds = useOpsInboxAction('SET_SELECTED_TASK_IDS');
    const filtersInvalid = React.useMemo(() => {
        const searchValid = !!variables.search && variables.search.length >= 3;
        const orderFilterValid = (variables.order_filter ?? []).length > 0;
        const taskFilterValid = Object.keys(variables.task_filter ?? {}).length > 0;
        return !orderFilterValid && !taskFilterValid && !searchValid;
    }, [variables.order_filter, variables.search, variables.task_filter]);
    let loadActiveInternalDesignTasks = false;
    const trackTaskSavedSearchLoaded = useTrackTaskSavedSearchLoaded();
    const { loading, data, refetch, error } = useWorkflowTasksByListViewQuery({
        skip: filtersInvalid,
        variables: { ...variables, sort: { field: sort.key, isAsc: sort.asc } },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        onCompleted: data => {
            const inViewTaskIds = data.orderWorkflowTasksByListView?.map(t => t.id) ?? [];
            const selectedTaskIdsInQuery = selectedTaskIds.filter(id => inViewTaskIds.includes(id));
            // if we have selected tasks that are no longer in the current view we clear them out
            if (selectedTaskIdsInQuery.length !== selectedTaskIds.length) {
                setSelectedTaskIds(selectedTaskIdsInQuery);
            }
            trackTaskSavedSearchLoaded();
            loadActiveInternalDesignTasks = true;
        },
    });
    const tasks = React.useMemo(() => data?.orderWorkflowTasksByListView ?? [], [data?.orderWorkflowTasksByListView]);
    const orderIds = React.useMemo(() => tasks.map(t => t.lab_order_id), [tasks]);
    const { data: activeDesignTaskResults } = useGetActiveInternalDesignTasksQuery({
        variables: {
            orderIds: orderIds,
        },
        skip: loadActiveInternalDesignTasks,
        onCompleted: () => {
            loadActiveInternalDesignTasks = false;
        },
    });

    const designTaskLookup = React.useMemo(
        () =>
            activeDesignTaskResults?.getActiveInternalDesignTasks.reduce(
                (map, designTask) => {
                    map[designTask.labOrderId] = designTask;
                    return map;
                },
                {} as Record<string, LabsGqlActiveInternalDesignTaskFragment>,
            ) ?? {},
        [activeDesignTaskResults],
    );
    const invalidFiltersMessage = React.useMemo(() => {
        const suffix = variables.search ? 'minimum search length (3 letters) not met' : 'add filters or search';
        return filtersInvalid ? `Loading skipped, ${suffix}` : undefined;
    }, [filtersInvalid, variables.search]);
    return { tasks, orderIds, refetch, loading, invalidFiltersMessage, designTaskLookup, error };
}

const [_InboxTasksProvider, _useInboxTasksWrapped] = constate(_useInboxTasks);

export const useInboxTasks: () => UseInboxTasksResult = _useInboxTasksWrapped;
export const InboxTasksProvider = _InboxTasksProvider;
