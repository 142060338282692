import { useAbutmentPartsMap } from '../../../../context';
import type { IBaseItemData } from '../../types';
import { getHeaderForLabSlipItem } from '../../utils/shared.utils';
import { BaseItem } from './BaseItem';
import { getImplantDisplayBodyData } from './ImplantItem';
import type { LabsGqlAbutmentPartDto } from '@orthly/graphql-schema';
import type { IImplantBridgeItem, IOrderItemV2DTO } from '@orthly/items';
import React from 'react';

interface ImplantBridgeItemProps {
    item: IImplantBridgeItem;
    allItems: IOrderItemV2DTO[];
}

/**
 * Renders a "ImplantBridge" order item to display on
 * the Printable Slip
 * @param props The order item to render
 * @returns Rendered order item details for the order "ImplantBridge" item
 */
export const ImplantBridgeItem: React.VFC<ImplantBridgeItemProps> = ({ item, allItems }) => {
    const abutmentPartId = item.implants[0]?.implant_metadata?.part_id;
    const abutmentPartMap = useAbutmentPartsMap();
    const abutmentPart = abutmentPartMap.get(abutmentPartId ?? '');
    return <BaseItem itemData={mapImplantBridgeItemToPrintableSlipData(item, allItems, abutmentPart)} />;
};

/**
 * Pull data needed for printable slip display into a standard data structure
 * @param item IImplantBridgeItem order item to display
 * @param order Design data on the order
 * @param abutmentPartData data relating to the abutment parts for this item
 * @returns Structured data for ImplantBridge item display
 */
function mapImplantBridgeItemToPrintableSlipData(
    item: IImplantBridgeItem,
    allItems: IOrderItemV2DTO[],
    abutmentPartData: LabsGqlAbutmentPartDto | undefined,
): IBaseItemData {
    const headerLeft = getHeaderForLabSlipItem(item);
    const implants = item.implants;

    if (!implants[0]) {
        return { headerLeft };
    }

    // For now, we can make the assumption that all implants in the order will share the same metadata
    // The ordering team is working on a change to allow different implants in the order to have different metadata
    // We need to update this system to allow for this variability
    // Tracked here: https://www.notion.so/orthly/Lab-Slips-v2-9f452847a2bf44baa9aabc8abc99f5fc#b3718768df9f4049a620dab56981e38c
    return {
        headerLeft,
        ...getImplantDisplayBodyData([item], allItems, abutmentPartData),
    };
}
