import { TOOLBAR_ITEM_HEIGHT_PX } from './Constants';
import { FlossPalette, IconToggleButton, styled, stylesFactory } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const StyledButton = styled(IconToggleButton)(() => ({
    color: FlossPalette.BLACK,
    backgroundColor: FlossPalette.TAN,
    height: TOOLBAR_ITEM_HEIGHT_PX,
    border: 'unset',
    borderRadius: 'unset',
    '&:hover': {
        // Disable the hover border effect
        border: 'unset',
    },
    '&:disabled': {
        color: FlossPalette.LIGHT_GRAY,
        backgroundColor: FlossPalette.TAN,
    },
}));

const useStyles = stylesFactory(() => ({
    active: {
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
    },
}));

export interface ToolbarButtonProps {
    className?: string;
    disabled?: boolean;
    active?: boolean;
    tooltip: string;
    onClick: () => void;
}

export const ToolbarButton: React.FC<ToolbarButtonProps> = ({
    className,
    disabled,
    active = false,
    onClick,
    tooltip,
    children,
}) => {
    const classes = useStyles();

    return (
        <StyledButton
            className={clsx(className, active && classes.active)}
            active={active}
            disabled={disabled}
            onClick={onClick}
            tooltip={tooltip}
            forceDisabledTooltip={true}
        >
            {children}
        </StyledButton>
    );
};
