import type { FileUploadAnalyticsMetadata } from '../FirebaseUpload';
import { FileUpload, UploadedFiles, useOrderFileUpload } from '../FirebaseUpload';
import type { ImpersonatedQRCodeLinkResult } from '../QRCode';
import type { RefabAttachment, TextUploadLinkResult } from '@orthly/dentin';
import { FlossPalette, stylesFactory, useScreenIsMobileOrVerticalTablet, Grid } from '@orthly/ui-primitives';
import React from 'react';

const UPLOADER = {
    backgroundColor: FlossPalette.TAN,
    borderColor: FlossPalette.DARK_TAN,
    borderStyle: 'solid',
    borderRadius: 8,
    borderWidth: 2,
    overflow: 'hidden',
    flexDirection: 'column',
} as const;

const useStyles = stylesFactory(theme => ({
    fileUploader: {
        padding: 4,
        height: '235px',
        display: 'flex',
        alignItems: 'center',
        '& > *': {
            width: `auto`,
        },
        ...UPLOADER,
        [theme.breakpoints.down('lg')]: {
            height: 'auto',
            paddingBottom: '16px',
        },
    },
    uploadedFiles: {
        height: '100%',
        marginTop: 8,
        padding: 16,
        ...UPLOADER,
    },
}));

export const RefabFileUploader: React.VFC<{
    orderId: string;
    itemId?: string;
    setAttachments: (attachments: RefabAttachment[]) => void;
    qrCode?: ImpersonatedQRCodeLinkResult;
    textUploadLink?: TextUploadLinkResult;
    onUploadingChange?: (isUploading: boolean) => void;
}> = ({ orderId, itemId, setAttachments, qrCode, textUploadLink, onUploadingChange }) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const analyticsMetadata: FileUploadAnalyticsMetadata = {
        productName: 'order refab files',
        device: 'web',
    };
    const { onDropAccepted, onRemoveFile, files, isUploading } = useOrderFileUpload({
        orderId,
        itemId,
        analyticsMetadata,
        forRefab: true,
        setAttachments,
    });

    React.useEffect(() => {
        onUploadingChange?.(isUploading);
    }, [isUploading, onUploadingChange]);

    if (!textUploadLink) {
        return null;
    }

    return (
        <>
            <Grid container direction={'column'} wrap={'nowrap'}>
                <Grid container className={classes.fileUploader}>
                    <FileUpload
                        title={''}
                        onDropAccepted={onDropAccepted}
                        qrCode={qrCode ?? {}}
                        textUploadLink={textUploadLink}
                        allowMultipleFiles
                        acceptAllFileTypes
                        isMobile={isMobile}
                    />
                </Grid>
                <Grid container direction={'column'} className={classes.uploadedFiles}>
                    <UploadedFiles files={files} onRemoveFile={onRemoveFile} maxHeight={385} maxThumbnailSize={60} />
                </Grid>
            </Grid>
        </>
    );
};
