import { useAutomationBuilderAction } from '../../../state/AutomationBuilder.actions';
import type { ActionConfigValue, ActionFormField } from '../../../state/AutomationBuilder.types';
import { AutomationBuilderFieldLayout } from '../components/AutomationBuilderFieldLayout';
import {
    AutomationTextField,
    AutomationMultiSelectField,
    AutomationSelectField,
} from '../components/AutomationBuilderFields';
import type {
    LabsGqlAutomationFieldFragment,
    LabsGqlAutomationField_AutomationFieldBoolean_Fragment as BooleanFieldFragment,
    LabsGqlAutomationField_AutomationFieldCopy_Fragment as CopyFieldFragment,
    LabsGqlAutomationField_AutomationFieldDate_Fragment as DateFieldFragment,
    LabsGqlAutomationField_AutomationFieldMultiSelect_Fragment as MultiSelectFieldFragment,
    LabsGqlAutomationField_AutomationFieldNumber_Fragment as NumberFieldFragment,
    LabsGqlAutomationField_AutomationFieldSelect_Fragment as SelectFieldFragment,
    LabsGqlAutomationField_AutomationFieldText_Fragment as TextFieldFragment,
} from '@orthly/graphql-operations';
import type { Maybe, Scalars } from '@orthly/graphql-schema';
import { SimpleCheckbox, SimpleDatePicker, SimpleSelect } from '@orthly/ui';
import { FormHelperText, Grid, MenuItem, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

type FieldValue = Maybe<Scalars['AutomationFieldValue']['input']>;
type IFieldWithValue<F extends LabsGqlAutomationFieldFragment> = F & {
    value?: FieldValue;
    touched: boolean;
    error?: string;
};

interface FieldContainerProps {
    field: LabsGqlAutomationFieldFragment;
    children: React.ReactNode;
}

const FieldContainer: React.FC<FieldContainerProps> = props => {
    const { field } = props;
    return (
        <AutomationBuilderFieldLayout title={field.label}>
            <Grid container>{props.children}</Grid>
        </AutomationBuilderFieldLayout>
    );
};

type PropsForFieldType<F extends LabsGqlAutomationFieldFragment> = {
    field: IFieldWithValue<F>;
    onChange: (value?: FieldValue) => void;
} & (F extends SelectFieldFragment | MultiSelectFieldFragment ? { action: ActionConfigValue } : { action?: undefined });

const AutomationConfigFieldCopy: React.FC<PropsForFieldType<CopyFieldFragment>> = props => {
    const { field } = props;
    return (
        <FieldContainer field={field}>
            <Text>{field.display_text}</Text>
        </FieldContainer>
    );
};

const AutomationConfigFieldBoolean: React.FC<PropsForFieldType<BooleanFieldFragment>> = props => {
    const { field, onChange } = props;
    const errorMessage = field.error && field.touched ? field.error : undefined;
    const helperText = errorMessage ?? field.helper_text;

    let selectValue;
    if (field.value === true) {
        selectValue = 'Yes';
    } else if (field.value === false) {
        selectValue = 'No';
    } else {
        selectValue = '--';
    }

    return (
        <FieldContainer field={field}>
            <Grid container>
                {field.optional ? (
                    <SimpleSelect
                        value={selectValue}
                        options={[{ value: '--' }, { value: 'Yes' }, { value: 'No' }]}
                        label={''}
                        onChange={value => {
                            switch (value) {
                                case 'Yes':
                                    onChange(true);
                                    break;
                                case 'No':
                                    onChange(false);
                                    break;
                                default:
                                    onChange(undefined);
                            }
                        }}
                    >
                        <MenuItem value={'--'}>--</MenuItem>
                        <MenuItem value={'yes'}>Yes</MenuItem>
                        <MenuItem value={'no'}>No</MenuItem>
                    </SimpleSelect>
                ) : (
                    <SimpleCheckbox
                        checked={field.value === true}
                        setChecked={checked => onChange(checked)}
                        label={field.label}
                    />
                )}
            </Grid>
            {helperText && (
                <FormHelperText style={{ marginTop: 0 }} error={!!errorMessage}>
                    {helperText}
                </FormHelperText>
            )}
        </FieldContainer>
    );
};

const AutomationConfigFieldDate: React.FC<PropsForFieldType<DateFieldFragment>> = props => {
    const { field, onChange } = props;
    const fieldValue = React.useMemo(() => {
        if (typeof field.value === 'string' && moment(field.value).isValid()) {
            return moment(field.value).toDate();
        }
        return null;
    }, [field.value]);
    return (
        <FieldContainer field={field}>
            <SimpleDatePicker
                label={''}
                value={fieldValue}
                onChange={newDate => onChange(newDate ? moment(newDate).toJSON() : undefined)}
                minDate={field.minDate ? moment(field.minDate).toDate() : undefined}
                maxDate={field.maxDate ? moment(field.maxDate).toDate() : undefined}
                helperText={field.error && field.touched ? field.error : field.helper_text ?? ''}
            />
        </FieldContainer>
    );
};

const AutomationConfigFieldNumber: React.FC<PropsForFieldType<NumberFieldFragment>> = props => {
    const { field, onChange } = props;
    return (
        <FieldContainer field={field}>
            <AutomationTextField
                TextFieldProps={{
                    helperText: field.error && field.touched ? field.error : field.helper_text,
                    error: !!field.error && field.touched,
                    type: 'number',
                }}
                onChange={value => {
                    if (value && !isNaN(parseInt(value))) {
                        return onChange(parseInt(value));
                    }
                    !value && onChange(undefined);
                }}
                value={typeof field.value === 'number' ? `${field.value}` : ''}
            />
        </FieldContainer>
    );
};

const AutomationConfigFieldText: React.FC<PropsForFieldType<TextFieldFragment>> = props => {
    const { field, onChange } = props;
    return (
        <FieldContainer field={field}>
            <AutomationTextField
                TextFieldProps={{
                    helperText: field.error && field.touched ? field.error : field.helper_text,
                    error: !!field.error && field.touched,
                    multiline: !!field.multiline,
                    rows: field.multiline ? 3 : 1,
                }}
                onChange={onChange}
                enableLocalValue={typeof field.value === 'string' ? true : false}
                value={typeof field.value === 'string' ? field.value : ''}
            />
        </FieldContainer>
    );
};

// Checks if the action fields match the current option
function selectionOptionAppliesToAction(action: ActionConfigValue, criteria?: { field: string; values: string[] }[]) {
    if (!criteria || !criteria.length) {
        return true;
    }

    return _.every(
        criteria.map(crit =>
            action.fields.find(
                field => field.id === crit.field && _.intersection(crit.values, [field.value]).length > 0,
            ),
        ),
    );
}

function useSelectFieldOptions(
    field: IFieldWithValue<SelectFieldFragment | MultiSelectFieldFragment>,
    action: ActionConfigValue,
) {
    return React.useMemo(() => {
        const opts = field.options
            .filter(opt => selectionOptionAppliesToAction(action, _.compact(opt.displayIfMatches)))
            .map(o => ({ value: o.value, label: o.label ?? o.value }));

        // In multi-select, we propogate any values which are missing so they can deselect if needed.
        const deletedOpts = (Array.isArray(field.value) ? field.value : [])
            .filter(val => !opts.find(opt => opt.value === val))
            .map(value => ({ value, label: `${value} (DELETED)` }));
        return _.sortBy([...opts, ...deletedOpts], o => o.label);
    }, [field.options, action, field.value]);
}

const AutomationConfigFieldSelect: React.FC<PropsForFieldType<SelectFieldFragment>> = props => {
    const { field, onChange, action } = props;
    const options = useSelectFieldOptions(field, action);
    return (
        <FieldContainer field={field}>
            <AutomationSelectField
                options={options}
                onChange={onChange}
                disabled={options.length === 0}
                label={''}
                value={typeof field.value === 'string' ? field.value : ''}
                helperText={field.helper_text}
                errorText={field.error && field.touched ? field.error : undefined}
            />
        </FieldContainer>
    );
};

const AutomationConfigFieldMultiSelect: React.FC<PropsForFieldType<MultiSelectFieldFragment>> = props => {
    const { field, onChange, action } = props;
    const options = useSelectFieldOptions(field, action);
    return (
        <FieldContainer field={field}>
            <AutomationMultiSelectField
                value={Array.isArray(field.value) ? field.value : []}
                options={options}
                disabled={options.length === 0}
                onChange={value => onChange(value)}
                label={''}
                helperText={field.helper_text}
                errorText={field.error && field.touched ? field.error : undefined}
            />
        </FieldContainer>
    );
};

export const AutomationActionConfigField: React.FC<{ field: ActionFormField; action: ActionConfigValue }> = props => {
    const { field, action } = props;

    const updateField = useAutomationBuilderAction('UPDATE_ACTION_FIELD');
    const onChange = React.useCallback(
        (value?: FieldValue) => {
            updateField({ value, action_id: action.id, field_id: field.id });
        },
        [action.id, field.id, updateField],
    );

    switch (field.__typename) {
        case 'AutomationFieldBoolean':
            return <AutomationConfigFieldBoolean onChange={onChange} field={field} />;
        case 'AutomationFieldCopy':
            return <AutomationConfigFieldCopy onChange={onChange} field={field} />;
        case 'AutomationFieldDate':
            return <AutomationConfigFieldDate onChange={onChange} field={field} />;
        case 'AutomationFieldNumber':
            return <AutomationConfigFieldNumber onChange={onChange} field={field} />;
        case 'AutomationFieldText':
            return <AutomationConfigFieldText onChange={onChange} field={field} />;
        case 'AutomationFieldSelect':
            return <AutomationConfigFieldSelect onChange={onChange} field={field} action={action} />;
        case 'AutomationFieldMultiSelect':
            return <AutomationConfigFieldMultiSelect onChange={onChange} field={field} action={action} />;
    }
};
