import type { OverflowTextWithTooltipProps } from '@orthly/ui';
import { OverflowTextWithTooltip } from '@orthly/ui';
import React from 'react';

interface OrderListItemCellTextProps extends Omit<OverflowTextWithTooltipProps, 'color' | 'variant'> {
    variant: 'title' | 'subtitle';
}

export const OrderListItemCellText: React.FC<OrderListItemCellTextProps> = props => {
    const { variant, ...overflowProps } = props;
    return <OverflowTextWithTooltip {...overflowProps} color={variant === 'title' ? 'BLACK' : 'DARK_GRAY'} />;
};
