import {
    OrderDetailSidebarItemLayout,
    LOADING_STYLE,
} from '../../../../components/OrderDetailSidebar/OrderDetailSidebarItemLayout';
import { OrderDetailSidebarItemPatient } from '../../../../components/OrderDetailSidebar/OrderDetailSidebarItemPatient';
import { useOpenOrderDetailOps, useCurrentOrderDetailId } from '../../../../utils/router/useOpenOrderDetail';
import type { InboxListItemProps } from '../../state/Inbox.selectors';
import { workflowTaskItemLabel, useInboxItemDateMessage, taskListItemPropsAreEqual } from '../../state/Inbox.selectors';
import { InboxListItemActions } from '../InboxListView/InboxListItemActions';
import { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { OverflowTextWithTooltip } from '@orthly/ui';
import { Text, stylesFactory, FlossPalette, Grid, Tooltip, Icon } from '@orthly/ui-primitives';
import { useDesignTaskTimeRemainingText, OrderListItemFlags } from '@orthly/veneer';
import moment from 'moment';
import React from 'react';

const useStyles = stylesFactory(() => ({
    row: {
        '& $button': { visibility: `hidden` },
        '&:hover $button': { visibility: `visible` },
    },
    leftPane: {
        marginTop: -4,
        position: 'relative',
    },
    button: {},
}));

export const InboxDetailSidebarItemLoading: React.FC = () => {
    return (
        <OrderDetailSidebarItemLayout selected={false}>
            <div style={{ ...LOADING_STYLE, width: '50%', marginRight: 4, height: 24 }} />
            <div style={{ ...LOADING_STYLE, height: 12 }} />
            <div style={{ ...LOADING_STYLE, height: 12 }} />
        </OrderDetailSidebarItemLayout>
    );
};

const InboxDetailSidebarItemContent: React.FC<Required<InboxListItemProps>> = props => {
    const classes = useStyles();
    const selectedOrderId = useCurrentOrderDetailId();
    const openOrder = useOpenOrderDetailOps();
    const { listItemContent, task } = props;
    const {
        flags,
        review_flag_reasons,
        order: { id, patient, status, design_due_date, order_number },
    } = listItemContent;
    const dateMessage = useInboxItemDateMessage(task, design_due_date);
    const expirationMessage = useDesignTaskTimeRemainingText(task);

    return (
        <OrderDetailSidebarItemLayout
            selected={id === selectedOrderId}
            onClick={e => {
                // When opening orders for prep tasks in a new tab, enable automatic pre-download.
                const openInNewWindow = e?.metaKey || e?.ctrlKey || e?.button === 1;
                const preDownload = task.type === LabsGqlWorkflowTaskType.DesignPrep && openInNewWindow;
                openOrder(id, e, { params: preDownload ? { preDownload } : undefined });
            }}
            className={classes.row}
        >
            <Grid container alignItems={'center'} style={{ position: 'relative' }} wrap={'nowrap'}>
                <Grid
                    item
                    container
                    xs={'auto'}
                    alignItems={'flex-start'}
                    wrap={'nowrap'}
                    className={classes.leftPane}
                    direction={'column'}
                >
                    {task.started_at && !task.closeout && (
                        <Tooltip title={`Task started at ${moment(task.started_at).format(`MM/DD/YY, h:mm a`)}`}>
                            <Icon
                                icon={'PlayCircleFilledIcon'}
                                style={{ color: FlossPalette.GRAY, width: 16, height: 16, marginRight: 4 }}
                            />
                        </Tooltip>
                    )}
                    <OverflowTextWithTooltip
                        medium
                        text={workflowTaskItemLabel(task)}
                        color={'BLACK'}
                        variant={'body2'}
                    />
                    <OrderDetailSidebarItemPatient
                        disableBold
                        // hide the count for task sidebar item
                        patient_order_count={0}
                        status={status}
                        patient_name={`${patient.first_name} ${patient.last_name}`}
                    />
                </Grid>
                <Grid
                    item
                    container
                    xs={'auto'}
                    alignItems={'flex-end'}
                    style={{ position: 'relative' }}
                    justifyContent={'flex-end'}
                    direction={'column'}
                >
                    <Text variant={'caption'} color={'BLACK'}>
                        #{order_number}
                    </Text>
                    <Text variant={'caption'} color={'DARK_GRAY'}>
                        {dateMessage}
                    </Text>
                    {expirationMessage && (
                        <Text variant={'caption'} color={'DARK_GRAY'}>
                            {expirationMessage}
                        </Text>
                    )}
                </Grid>
            </Grid>
            <Grid container wrap={'wrap'} style={{ height: 20 }}>
                <OrderListItemFlags small flags={flags} review_flag_reasons={review_flag_reasons} />
            </Grid>
            <InboxListItemActions buttonClass={classes.button} task={task} isSidebarElement={true} />
        </OrderDetailSidebarItemLayout>
    );
};

export const InboxDetailSidebarItem = React.memo<InboxListItemProps>(props => {
    if (!props.listItemContent) {
        return <InboxDetailSidebarItemLoading />;
    }
    return <InboxDetailSidebarItemContent listItemContent={props.listItemContent} task={props.task} />;
}, taskListItemPropsAreEqual);
