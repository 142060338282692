import type { DesignQcRubricSubCategoryEntry } from '../configs/DesignQc.config.types';
import { useDesignQcPropSelector } from './DesignQc.context';
import type { DesignQcState } from './DesignQc.state';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

export function useCurrentRubricSubCategoryEntries() {
    const { rubricNavigation, config } = useDesignQcPropSelector(['rubricNavigation', 'config']);

    return config.find(c => c.category === rubricNavigation?.category)?.subcategories ?? [];
}

export function isQcRubricEntryCompleted(state: Pick<DesignQcState, 'results'>, entry: DesignQcRubricSubCategoryEntry) {
    return state.results.some(r => r.category === entry.category && r.name === entry.name);
}

export function getQcRubricEntryResult(state: Pick<DesignQcState, 'results'>, entry: DesignQcRubricSubCategoryEntry) {
    return state.results.find(r => r.category === entry.category && r.name === entry.name);
}

export function useCanSubmit() {
    const { config, results } = useDesignQcPropSelector(['config', 'results']);

    return React.useMemo(() => {
        const isGeneralCategoryMarkedBad = results.some(
            result => result.category === 'general' && result.result.type === 'bad',
        );

        // The general categories are thought of as "early exit" steps,
        // meaning that a bad result in one of its subcategories is sufficient to complete the review and exit.
        if (isGeneralCategoryMarkedBad) {
            return true;
        }

        return _.flatten(config.map(c => c.subcategories)).every(c => isQcRubricEntryCompleted({ results }, c));
    }, [config, results]);
}

export function getAllUnns(state: Pick<DesignQcState, 'order'>): number[] {
    const convertedItems = OrderItemV2Utils.parseItems(state.order.items_v2);
    return CartItemV2Utils.getItemGroupUniqueUNNs(convertedItems);
}

export function getRelativeEntry(state: Pick<DesignQcState, 'rubricNavigation' | 'config'>, offset: -1 | 1) {
    const allSubCategories = state.config.flatMap(c => c.subcategories);
    const currentIndex = allSubCategories.findIndex(
        c => state.rubricNavigation?.category === c.category && state.rubricNavigation?.entry?.name === c.name,
    );

    return allSubCategories[currentIndex + offset];
}
