import {
    AccordionDetails,
    Accordion,
    AccordionSummary,
    makeStyles,
    createStyles,
    Grid,
    Text,
    Typography,
    ExpandMoreIcon,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            padding: '12px 24px',
        },
        panelRoot: {
            width: '100%',
            borderRadius: '6px !important',
            display: 'flex',
            flexDirection: 'column',
            '& .MuiExpansionPanelSummary-content': { margin: 0 },
            '& .MuiExpansionPanelSummary-root': { minHeight: 48 },
        },
        summary: {
            width: '100%',
            padding: '10px 24px',
            alignItems: 'center',
        },
        heading: {
            width: '100%',
        },
        subtitle: {},
        details: {
            flexWrap: 'wrap',
        },
    }),
);

interface AutomationStepContainerProps {
    title: string;
    subtitle?: string;
    startIcon?: React.ReactNode;
    children: React.ReactNode;
    disableDefaultExpanded?: boolean;
}

export const AutomationStepContainer: React.FC<AutomationStepContainerProps> = props => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root}>
            <Accordion
                variant={'elevation'}
                defaultExpanded={!props.disableDefaultExpanded}
                classes={{ root: classes.panelRoot }}
                elevation={1}
            >
                <AccordionSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
                    {props.startIcon}
                    <Grid container alignItems={'center'}>
                        <Text variant={'h6'} className={classes.heading}>
                            {props.title}
                        </Text>
                        {props.subtitle && (
                            <Typography variant={'body1'} color={'textSecondary'} className={classes.subtitle}>
                                {props.subtitle}
                            </Typography>
                        )}
                    </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>{props.children}</AccordionDetails>
            </Accordion>
        </Grid>
    );
};
