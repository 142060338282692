import { DesignQcRubricCategoryToLabelMap, useGuidedQcAnalytics } from './DesignQcCommon';
import { useDesignQcPropSelector } from './state/DesignQc.context';
import { RootActionDialog } from '@orthly/ui';
import { stylesFactory, Button } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    iframe: {
        height: '80vh',
        width: '100%',
        border: 0,
    },
    wrapper: {
        padding: `0px !important`,
    },
}));

export const DesignStandardModalWithButton: React.VFC = () => {
    const classes = useStyles();

    const { rubricNavigation, config } = useDesignQcPropSelector(['rubricNavigation', 'config']);
    const designStandard = config.find(c => c.category === rubricNavigation?.category)?.design_standard;
    const [open, setOpen] = React.useState<boolean>(false);

    const trackModalOpen = useGuidedQcAnalytics('All - Portal - Guided QC - Category Design SOP Viewed');
    React.useEffect(() => {
        if (open) {
            trackModalOpen({});
        }
    }, [open, trackModalOpen]);

    if (!designStandard || !rubricNavigation) {
        return null;
    }

    const categoryName = DesignQcRubricCategoryToLabelMap[rubricNavigation.category];

    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            title={`Design Standard: ${categoryName}`}
            loading={false}
            CustomButton={({ onClick }) => (
                <Button variant={'secondary'} fullWidth onClick={onClick}>
                    View Design Standard for{' '}
                    {categoryName.length > 16 ? `${categoryName.slice(0, 14)}...` : categoryName}
                </Button>
            )}
            contentProps={{
                className: classes.wrapper,
            }}
            showCloseButton
            maxWidth={'lg'}
            content={
                <iframe
                    src={`https://docs.google.com/document/d/e/${designStandard.googleEmbedStub}/pub?embedded=true`}
                    className={classes.iframe}
                />
            }
        />
    );
};
