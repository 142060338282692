import type { PayloadModelAppearance, ItemAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import type { RestorativeModel } from './FinishingApp.types';
import { OPAQUE_OPACITY } from './Materials.utils';
import type { SceneModelAppearance } from './SceneAppearanceManager.types';

export function createRestorativeItem(
    restorativeModel: Pick<RestorativeModel, 'geometry'>,
    modelAppearance: Pick<SceneModelAppearance, 'visible'>,
): PayloadModelAppearance {
    return {
        payloadModel: {
            name: '',
            path: '',
            model: {
                modelType: 'stl',
                geometry: restorativeModel.geometry,
            },
            isRestorative: true,
            isPrintedModel: false,
        },
        appearance: createItemAppearance(modelAppearance.visible),
    };
}

export function createJawItem(
    geometry: THREE.BufferGeometry,
    modelAppearance: Pick<SceneModelAppearance, 'visible'>,
): PayloadModelAppearance {
    return {
        payloadModel: {
            name: '',
            path: '',
            model: {
                modelType: 'stl',
                geometry,
            },
            isRestorative: false,
            isPrintedModel: false,
        },
        appearance: createItemAppearance(modelAppearance.visible),
    };
}

function createItemAppearance(visible: boolean): ItemAppearance {
    return {
        colorize: false,
        showInsertionAxis: false,
        showUndercutShadow: false,
        showUndercutCurtains: false,
        visible,
        opacity: OPAQUE_OPACITY,
    };
}
