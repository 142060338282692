import { DesignFileDownloadPlugin } from './DesignFileDownloadPlugin.graphql';
import { DesignFileUploadPlugin } from './DesignFileUploadPlugin';
import type { OrderDesignFileStatus } from './ThreeshapePlugin.types';
import { MUITable } from '@orthly/mui-table';
import { OrthlyBrowserConfig } from '@orthly/ui';
import { Button, Grid, Link } from '@orthly/ui-primitives';
import React from 'react';
import { Switch, Route } from 'react-router-dom';

type TableRowElement = OrderDesignFileStatus & {
    id: string;
};

async function getDirectoryHandles(
    setDownloadDirHandle: (
        value:
            | ((prevState: FileSystemDirectoryHandle | null) => FileSystemDirectoryHandle | null)
            | FileSystemDirectoryHandle
            | null,
    ) => void,
    setUploadDirHandle: (
        value:
            | ((prevState: FileSystemDirectoryHandle | null) => FileSystemDirectoryHandle | null)
            | FileSystemDirectoryHandle
            | null,
    ) => void,
) {
    //@ts-ignore
    const dandyPluginDir: FileSystemDirectoryHandle = await window.showDirectoryPicker({
        id: 'plugin-files-dir',
        mode: 'readwrite',
        startIn: 'downloads',
    });
    const downloadsDir = await dandyPluginDir.getDirectoryHandle('downloads', { create: true });
    setDownloadDirHandle(downloadsDir);
    const uploadsDir = await dandyPluginDir.getDirectoryHandle('uploads', { create: true });
    setUploadDirHandle(uploadsDir);
}

const ThreeshapePluginWrapper: React.FC = () => {
    const [downloadDirHandler, setDownloadDirHandler] = React.useState<FileSystemDirectoryHandle | null>(null);
    const [uploadDirHandler, setUploadDirHandler] = React.useState<FileSystemDirectoryHandle | null>(null);
    const [orderDictionary, setOrderDictionary] = React.useState<Record<string, OrderDesignFileStatus>>({});

    function updateDesignFileStatusIfNew(updatedOrders: Record<string, OrderDesignFileStatus>) {
        // See https://react.dev/learn/queueing-a-series-of-state-updates
        setOrderDictionary(orderDictionary => {
            // either create or override order status
            return { ...orderDictionary, ...updatedOrders };
        });
    }

    if (!downloadDirHandler || !uploadDirHandler) {
        return (
            <Button
                variant={'contained'}
                onClick={() => getDirectoryHandles(setDownloadDirHandler, setUploadDirHandler)}
            >
                Allow Threeshape Plugin Root Dir Access
            </Button>
        );
    }
    const displayRows = Object.entries(orderDictionary).map(([orderId, orderDesignFileStatus]) => ({
        id: orderId,
        ...orderDesignFileStatus,
    }));
    return (
        <div style={{ width: '50%' }}>
            <DesignFileDownloadPlugin
                directoryHandle={downloadDirHandler}
                updateDesignFileStatus={updateDesignFileStatusIfNew}
            />
            <DesignFileUploadPlugin
                uploadDirHandle={uploadDirHandler}
                downloadDirHandle={downloadDirHandler}
                updateDesignFileStatus={updateDesignFileStatusIfNew}
            />
            <Grid container style={{ padding: 10 }}>
                <MUITable<TableRowElement>
                    paginationOptions={{ disable: true }}
                    columns={[
                        {
                            name: 'id',
                            title: 'Order Link',
                            render: row => (
                                <Link href={`${OrthlyBrowserConfig.opsPortalUrl}/orders/${row.id}`} color={'primary'}>
                                    {row.id}
                                </Link>
                            ),
                        },
                        { name: 'taskType', title: 'Active Task', render: row => row.taskType, defaultSort: 'desc' },
                        {
                            name: 'currentAction',
                            title: 'Current Action',
                            render: row => row.currentAction,
                            defaultSort: 'desc',
                        },
                        {
                            name: 'success',
                            title: 'Status',
                            render: row => (row.success ? 'Success' : `Failed - Please Manually ${row.currentAction}`),
                            defaultSort: 'desc',
                        },
                    ]}
                    data={displayRows}
                    title={'Download/Upload Status (Reloads every 30s)'}
                />
            </Grid>
        </div>
    );
};

export const ThreeshapePluginRoot: React.VFC = () => {
    return (
        <Switch>
            <Route path={'/designer_3shape_plugin'} exact component={ThreeshapePluginWrapper} />
        </Switch>
    );
};
