import type { CustomQFComponentProps } from '@orthly/ui';
import { TextField, Autocomplete } from '@orthly/ui-primitives';
import React from 'react';

interface AdditionalInfoRequestAutocompleteProps extends CustomQFComponentProps {
    suggestions: string[];
}

export const AdditionalInfoRequestAutocomplete: React.FC<AdditionalInfoRequestAutocompleteProps> = props => {
    const { field, form, suggestions } = props;
    return (
        <Autocomplete
            style={{ minWidth: '100%' }}
            freeSolo={field.value?.length < 2}
            multiple
            options={suggestions}
            defaultValue={field.value}
            getOptionDisabled={() => field.value?.length >= 2}
            onChange={(_ev, value) => {
                form.setFieldValue(field.name, value);
            }}
            value={field.value}
            renderInput={params => (
                <TextField
                    {...params}
                    variant={'standard'}
                    label={'Select Supplemental Info or Create New'}
                    fullWidth
                    helperText={field.value?.length >= 2 ? 'A max of two values is allowed for this field' : ''}
                />
            )}
        />
    );
};
