import { Box, CelebrationDandyLine, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const Layout = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '32px',
    marginTop: '16px',
});

const FixedWidthBox = styled(Box)({
    width: '383px',
});

export const ShareScansWithModalSuccessContent: React.VFC = () => {
    return (
        <Layout data-testid={'share-scans-success'}>
            <FixedWidthBox>
                <Text variant={'body2'} color={'GRAY'} align={'center'}>
                    The patient should receive the link shortly. If it doesn’t arrive immediately, please allow a few
                    minutes.
                </Text>
            </FixedWidthBox>

            <CelebrationDandyLine />

            <FixedWidthBox>
                <Text variant={'body2'} color={'GRAY'} align={'center'} fontStyle={'italic'}>
                    Note: The link will expire in 2 weeks and is sent as a view-only message, so patients won’t be able
                    to reply.
                </Text>
            </FixedWidthBox>
        </Layout>
    );
};
