import type { AppearanceToggles, ReviewApp, ReviewType } from '@orthly/dentin';
import { DEFAULT_FINISHING_HOT_KEY_CONFIG, useRegisterHotKeys } from '@orthly/dentin';

function useRegisterReviewViewHotKey(
    apps: Record<ReviewType, ReviewApp>,
    key: string,
    description: string,
    tool: keyof AppearanceToggles,
) {
    useRegisterHotKeys({
        key,
        description,
        category: 'Finishing Review',
        action: () => {
            Object.entries(apps).forEach(([type, app]) => {
                // The distal view is synchronized with the mesial view, so we don't want to toggle the same tool twice
                // otherwise they will cancel each other
                if (type === 'distal') {
                    return;
                }
                app.sceneState.manager[tool]?.();
            });
        },
    });
}

export function useSetupReviewKeyboardControls(
    apps: Record<ReviewType, ReviewApp>,
    expandedReviewType: ReviewType | null,
) {
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleCrossSectionActive,
        description: `Toggle cross section`,
        category: 'Finishing Review',
        action: () => {
            if (expandedReviewType === null) {
                return;
            }
            const app = apps[expandedReviewType];
            app.crossSection?.appActive.toggle(false);
        },
        disabled: expandedReviewType === null,
    });
    useRegisterReviewViewHotKey(
        apps,
        DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleCollisions,
        `Toggle collisions`,
        'toggleCollisionsVisibility',
    );
    useRegisterReviewViewHotKey(
        apps,
        DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleCollisionsWithCurtains,
        `Toggle collisions curtains`,
        'toggleCollisionsCurtainsVisibility',
    );
    useRegisterReviewViewHotKey(
        apps,
        DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleCurtains,
        `Toggle curtains`,
        'toggleCurtainsVisibility',
    );
    useRegisterReviewViewHotKey(
        apps,
        DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleInsertionAxis,
        `Toggle insertion axis`,
        'toggleInsertionPathsVisibility',
    );
    useRegisterReviewViewHotKey(
        apps,
        DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleThicknessHeatmap,
        `Toggle thickness heatmap`,
        'toggleThicknessHeatmapEnabled',
    );
    useRegisterReviewViewHotKey(
        apps,
        DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleOcclusalHeatmap,
        `Toggle occlusal heatmap`,
        'toggleOcclusalHeatmapEnabled',
    );
    useRegisterReviewViewHotKey(
        apps,
        DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleProximalHeatmap,
        `Toggle proximal heatmap`,
        'toggleProximalHeatmapEnabled',
    );
    useRegisterReviewViewHotKey(
        apps,
        DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleRestorativeUndercutHeatmap,
        `Toggle restorative undercut heatmap`,
        'toggleUndercutHeatmapEnabled',
    );
    useRegisterReviewViewHotKey(
        apps,
        DEFAULT_FINISHING_HOT_KEY_CONFIG.togglePreprep,
        `Toggle pre prep scans`,
        'togglePrePrepScanVisibility',
    );
    useRegisterReviewViewHotKey(
        apps,
        DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleAntagonist,
        `Toggle antagonist scans`,
        'toggleAntagonistScanVisibility',
    );
    useRegisterReviewViewHotKey(
        apps,
        DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleScanUndercutHeatmap,
        `Toggle scan undercut`,
        'toggleScanUndercutEnabled',
    );
    useRegisterReviewViewHotKey(
        apps,
        DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleRestorative,
        `Toggle restoratives`,
        'toggleRestorativesVisibility',
    );
}
