import { useMountingMatrix } from '../../../../hooks/useMountingMatrix';
import type { VeneerUseOrderRevisionItemsDesign_Fragment } from '../../OrderDesignActions.graphql';
import { useAppearance } from '../hooks/useAppearance';
import { useCameraSync } from '../hooks/useCameraSync';
import { useComparisonMode } from '../hooks/useComparisonMode';
import { useResetView } from '../hooks/useResetView';
import { useStructuredNotesExperiment } from '../hooks/useStructuredNotesExperiment';
import { useUnfinishedPresets } from '../hooks/useUnfinishedPresets';
import { useGuidedWaxupAction } from './GuidedWaxupState';
import type {
    LimitedAppearanceFilterBools,
    MainViewCameraControlsRef,
    MainViewModelRef,
    ModelAppearance,
} from '@orthly/dentin';
import { AGGREGATE_CONTROL_LABELS, useAggregateToggles, usePresetViewSetter } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, OrderDesignPreviewDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import type { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import constate from 'constate';
import React from 'react';

export interface GuidedWaxupContextShape {
    order: LabsGqlSingleLabOrderFragment;
    patientName: string;
    refetch: () => Promise<unknown>;
    viewingPreviousDesign: boolean;
    /**
     * Given an ID, updates the `selectedDesignFragment`.
     */
    setSelectedDesignRevisionId: (id?: string) => void;
    /**
     * Given an ID, updates the `comparisonDesignFragment`.
     */
    setComparisonDesignRevisionId: (id?: string) => void;
    selectedTab: LabsGqlGuidedWaxupPresetType;
    selectedTabInitialized: boolean;
    setSelectedTab: (tab: LabsGqlGuidedWaxupPresetType) => void;
    onBack: () => void;
    userRole: string;
    /**
     * This is the design fragment that the user is currently reviewing.
     */
    selectedDesignFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    selectedDesignRevisionAlreadyReviewed: boolean;
    /**
     * This is the design fragment that the user most recently rejected.
     * We use this for, among other things, pulling the data on what their recent rejection reasons are.
     */
    mostRecentlyRejectedDesignFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    /**
     * This is the design fragment that the user is using to compare with `selectedDesignFragment`.
     * It could be the most recently rejected version, or perhaps even an earlier version.
     */
    comparisonDesignFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    designRevisionFragments: FragmentType<typeof VeneerUseOrderRevisionItemsDesign_Fragment>[];
    selectedDesignRevisionIdx?: number;
    selectedDesignRevisionId?: string;
    comparisonDesignRevisionId?: string;
    isWarningDialogModalOpen: boolean;
    setIsWarningDialogModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isSkipToGeneralViewModalOpen: boolean;
    setIsSkipToGeneralViewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isIncompletedWorkReminderModalOpen: boolean;
    setIsIncompletedWorkReminderModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isMobileRejectionNoteOpen: boolean;
    setIsMobileRejectionNoteOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isMobileTimelineDialogOpen: boolean;
    setIsMobileTimelineDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isMobileDoctorImagesDialogOpen: boolean;
    setIsMobileDoctorImagesDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isAnnotatingScreenshot: boolean;
    setIsAnnotatingScreenshot: React.Dispatch<React.SetStateAction<boolean>>;
    teeth: number[];
    revisionId?: number;
    enableGuidedWaxupComparison?: boolean;
    isSidebarExpanded: boolean;
    setIsSidebarExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    OrderChatWrapper?: React.FC<{
        order: LabsGqlSingleLabOrderFragment;
        hideFooter?: boolean;
    }>;
    internalEvaluation?: boolean;
    isImmediateDenture?: boolean;
    isRemoveable?: boolean;
}

const useModelViewerRefs = () => {
    const modelRef: MainViewModelRef = React.useRef(undefined);
    const cameraControlRef: MainViewCameraControlsRef = React.useRef(null);

    return { modelRef, cameraControlRef };
};

export const [GuidedWaxupContextProvider, useGuidedWaxupContext] = constate((context: GuidedWaxupContextShape) => {
    const enableStructuredDesignReviewNotes = useStructuredNotesExperiment(context.order);
    const unfinishedPresets = useUnfinishedPresets(enableStructuredDesignReviewNotes);
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const {
        appearance: primaryAppearance,
        setAppearance: setPrimaryAppearance,
        appearanceFilters: primaryAppearanceFilters,
        setAppearanceFilters: setPrimaryAppearanceFilters,
        toggleAppearanceFilters: togglePrimaryAppearanceFilters,
    } = useAppearance();
    const {
        appearance: secondaryAppearance,
        setAppearance: setSecondaryAppearance,
        setAppearanceFilters: setSecondaryAppearanceFilters,
        toggleAppearanceFilters: toggleSecondaryAppearanceFilters,
    } = useAppearance();

    const toggleAppearanceFilters = React.useCallback(
        (keys: (keyof LimitedAppearanceFilterBools)[]) => {
            togglePrimaryAppearanceFilters(keys);
            toggleSecondaryAppearanceFilters(keys);
        },
        [togglePrimaryAppearanceFilters, toggleSecondaryAppearanceFilters],
    );

    const { modelRef: primaryModelRef, cameraControlRef: primaryCameraControlsRef } = useModelViewerRefs();
    const { modelRef: secondaryModelRef, cameraControlRef: secondaryCameraControlsRef } = useModelViewerRefs();
    const [zoom, setZoom] = React.useState<number>(isMobile ? 4 : 6);
    const { isComparisonModeActive, isComparisonModeAvailable, isComparisonSelectorAvailable, toggleComparisonMode } =
        useComparisonMode({
            designRevisionFragments: context.designRevisionFragments,
            items: context.order.items_v2,
        });

    React.useEffect(() => {
        if (isComparisonModeAvailable) {
            context.setIsSidebarExpanded(true);
        }
    }, [isComparisonModeAvailable]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (isComparisonModeActive) {
            context.setIsSidebarExpanded(false);
        }
    }, [isComparisonModeActive]); // eslint-disable-line react-hooks/exhaustive-deps

    const design = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, context.selectedDesignFragment);
    const mountingMatrix = useMountingMatrix(design);
    const presetViewControls = usePresetViewSetter(
        mountingMatrix,
        { primaryCameraControlsRef, secondaryCameraControlsRef },
        primaryAppearance,
    );

    const setBothAppearances = (value: ModelAppearance | ((prevState: ModelAppearance) => ModelAppearance)) => {
        setPrimaryAppearance(value);
        setSecondaryAppearance(value);
    };

    // to avoid having to change existing logic within the useAggregateToggles hook being used here, just exclude Scans and Restorations
    // toggles
    const unfilteredToggles = useAggregateToggles(primaryAppearance, setBothAppearances, setPrimaryAppearanceFilters);
    const toggles = unfilteredToggles.filter(
        toggle =>
            toggle.label !== AGGREGATE_CONTROL_LABELS.SCANS && toggle.label !== AGGREGATE_CONTROL_LABELS.RESTORATIONS,
    );

    const setPresetAnnotation = useGuidedWaxupAction('SET_PRESET_ANNOTATION');
    const setAnnotation = React.useCallback(
        (annotatedImageUrls: string[]) => {
            setPresetAnnotation({ presetName: context.selectedTab, annotatedImageUrls });
        },
        [context.selectedTab, setPresetAnnotation],
    );
    const { setPrimaryControls, setSecondaryControls, setControlsActive } = useCameraSync();

    const { resetView } = useResetView({
        primaryAppearance,
        secondaryAppearance,
        primaryCameraControlsRef,
        secondaryCameraControlsRef,
        presetViewControls,
        setPrimaryAppearance,
        setSecondaryAppearance,
        setPrimaryAppearanceFilters,
        setSecondaryAppearanceFilters,
        setZoom,
        selectedTab: context.selectedTab,
        isImmediateDenture: context.isImmediateDenture ?? false,
    });

    return {
        ...context,
        primaryAppearance,
        setPrimaryAppearance,
        setSecondaryAppearance,
        setBothAppearances,
        secondaryAppearance,
        appearanceFilters: primaryAppearanceFilters,
        toggleAppearanceFilters,
        resetView,
        enableStructuredDesignReviewNotes,
        design,
        primaryModelRef,
        primaryCameraControlsRef,
        secondaryModelRef,
        secondaryCameraControlsRef,
        setPrimaryControls,
        setSecondaryControls,
        setControlsActive,
        zoom,
        setZoom,
        presetViewControls,
        toggles,
        setAnnotation,
        unfinishedPresets,
        isComparisonModeAvailable,
        isComparisonModeActive,
        isComparisonSelectorAvailable,
        toggleComparisonMode,
    };
});
