import { AnnotationListModal } from '../Annotations';
import { INITIAL_TOOL_STATE } from '../Annotations/DrawingControls';
import { useFeatureFlag } from '../Providers/LaunchDarkly';
import type { ViewerState } from './DesignViewerLiteDetachedContainer.styles';
import {
    Root,
    DesignStatusContainer,
    CanvasAndWrapperContainer,
    DrawControlsAndAnnotationContainer,
    CanvasContainer,
    BlockerContainer,
    Blocker,
} from './DesignViewerLiteDetachedContainer.styles';
import type { GuidedWaxupViewerWrapperProps } from './DesignViewerLiteDetachedWrapper';
import { DesignViewerLiteDetachedWrapper } from './DesignViewerLiteDetachedWrapper';
import { GuidedWaxupAnnotationButtons } from './GuidedWaxups/components/GuidedWaxupAnnotationButtons';
import { GuidedWaxupDrawingControls } from './GuidedWaxups/components/GuidedWaxupDrawingControls';
import { GuidedWaxupModelCanvasButtons } from './GuidedWaxups/components/GuidedWaxupModelCanvasButtons';
import { ModelViewerFooterBanner } from './GuidedWaxups/components/ModelViewFooterBanner/ModelViewerFooterBanner';
import { useGuidedWaxupContext } from './GuidedWaxups/state/GuidedWaxupContext';
import { useGuidedWaxupSelector, useWaxupIsRejected } from './GuidedWaxups/state/GuidedWaxupState';
import {
    useGetAnnotationCanvasFunctions,
    useGetCommonAnnotationState,
    useUploadCurrentAnnotatedImage,
} from './Waxup.util.graphql';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { useDrawingCanvas } from '@orthly/dentin';
import type { OrderDesignPreviewDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import { getFragmentData, OrderDesignPreviewDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import {
    LabsGqlDesignOrderDoctorReviewStatus,
    LabsGqlGuidedWaxupPresetStatus,
    LabsGqlGuidedWaxupPresetType,
} from '@orthly/graphql-schema';
import { LoadBlocker } from '@orthly/ui';
import { FlossPalette, Text, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

export const trackGuidedWaxupButtonClick = (buttonText: string) => {
    BrowserAnalyticsClientFactory.Instance?.track('Button Clicked', {
        AssetName: `Guided Waxup Button - ${buttonText}`,
        AssetType: 'button',
        AssetVersion: '',
        AssetCTAText: buttonText,
    });
};

interface DesignViewerLiteDetachedContainerProps extends GuidedWaxupViewerWrapperProps {
    /**
     * Whether this DesignViewerLiteDetachedContainer instance is meant to be the "primary" display or not.
     * The primary display will render the `selectedDesignFragment`,
     * whereas the non-primary display renders the other design fragment (which will be added later this week).
     */
    isPrimaryDisplay: boolean;
}

// eslint-disable-next-line  max-lines-per-function
export const DesignViewerLiteDetachedContainer: React.FC<DesignViewerLiteDetachedContainerProps> = props => {
    const { value: enableDDPComparison } = useFeatureFlag('enableDDPComparison');
    const { setPresetAnnotation, children, isPrimaryDisplay } = props;
    const {
        order,
        selectedTab,
        selectedDesignRevisionAlreadyReviewed: selectedDesignRevisionAlreadyReviewedRaw,
        selectedDesignFragment: selectedRaw,
        comparisonDesignFragment: comparisonRaw,
        setIsAnnotatingScreenshot,
        enableGuidedWaxupComparison,
        isImmediateDenture,
        isComparisonModeActive,
    } = useGuidedWaxupContext();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const selectedPresetStatus = useGuidedWaxupSelector(s => s.presets[selectedTab]?.status);
    const presetRejected = selectedPresetStatus === LabsGqlGuidedWaxupPresetStatus.Rejected;
    const waxupRejected = useWaxupIsRejected();
    const generalViewRejected = selectedTab === LabsGqlGuidedWaxupPresetType.GeneralView && waxupRejected;
    const annotatedImageUrls = useGuidedWaxupSelector(s => s.presets[selectedTab]?.annotatedImageUrls);
    const annotationModalUrls =
        annotatedImageUrls?.map(url => ({
            imageUrl: url,
        })) ?? [];

    const resolvedDesignFragment = isPrimaryDisplay ? selectedRaw : comparisonRaw;

    /**
     * The selected design fragment _for this frame_.
     * This is controlled by the value of `isPrimaryDisplay`.
     */
    const selectedDesign: OrderDesignPreviewDesign_FragmentFragment | null | undefined = getFragmentData(
        OrderDesignPreviewDesign_FragmentFragmentDoc,
        resolvedDesignFragment,
    );
    const isApprovedWaxup = selectedDesign?.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved;
    const {
        takeSnapshotRef,
        isAnnotationListModalOpen,
        setIsAnnotationListModalOpen,
        screenshotToAnnotate,
        setScreenshotToAnnotate,
        isUploading,
        setIsUploading,
        canvasSize,
        setCanvasSize,
        backgroundImageUrl,
    } = useGetCommonAnnotationState();

    const backgroundImageSizeCallback = React.useCallback(
        (width, height) => {
            setCanvasSize({ width, height });
        },
        [setCanvasSize],
    );

    const { canvas, toolState, setToolState, takeSnapshot, undoState, undo, redo, clear } = useDrawingCanvas({
        ...canvasSize,
        initialToolState: INITIAL_TOOL_STATE,
        backgroundImageSizeCallback,
        backgroundImageUrl: backgroundImageUrl ?? undefined,
        backgroundColor: FlossPalette.DARK_TAN,
        canvasProps: { style: { border: 'unset' } },
    });

    const { handleTakeScreenshot, setToolStateWrapped, closeDrawingCanvas } = useGetAnnotationCanvasFunctions(
        order.id,
        takeSnapshotRef,
        screenshotToAnnotate,
        setScreenshotToAnnotate,
        clear,
        setToolState,
    );

    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const viewerState: ViewerState = isUploading
        ? 'uploading'
        : screenshotToAnnotate
          ? 'annotating-screenshot'
          : 'viewing-model';

    const uploadCurrentAnnotatedImage = useUploadCurrentAnnotatedImage(takeSnapshot, props.order.id);

    // Handler for the "Save and markup new screen capture" button.
    // Uploads the current annotation and adds it to `dataToSubmit`.
    const saveAnnotatedImage = React.useCallback(async () => {
        try {
            closeDrawingCanvas();
            setIsUploading(true);
            const annotatedImageUrl = await uploadCurrentAnnotatedImage();
            if (annotatedImageUrl) {
                setPresetAnnotation?.([...(annotatedImageUrls ?? []), annotatedImageUrl]);
            }
        } finally {
            setIsUploading(false);
        }
    }, [uploadCurrentAnnotatedImage, closeDrawingCanvas, setIsUploading, annotatedImageUrls, setPresetAnnotation]);

    /**
     * For now, we always set this to true for the secondary display (implying that they're old versions).
     * In the near future, we will instead reference the actually selected secondary design.
     * TODO: https://meetdandy.atlassian.net/browse/EPDPOR-2207
     */
    const selectedDesignRevisionAlreadyReviewed = isPrimaryDisplay ? selectedDesignRevisionAlreadyReviewedRaw : true;

    /*
     * This conditional determines whether to show the Markup tool button within the model viewer for mobile
     * We check to make sure we are on mobile, that the doctor isn't already currently marking up a screenshot or uploading one,
     * that the doctor isn't viewing a historical design that has already been approved/rejected, and that the current preset they
     * are viewing has been rejected since we only allow doctors to provide annotations on rejection
     */
    const displayMobileMarkupButton =
        isMobile &&
        viewerState === 'viewing-model' &&
        !selectedDesignRevisionAlreadyReviewed &&
        (presetRejected || generalViewRejected) &&
        !isComparisonModeActive;

    // This conditional determines to show the annotation tools, which is only shown if the preset is rejected and we're viewing a current waxup
    // This is also visible deterministically in the mobile view by the viewer state, but that is checked below where it is rendered
    const showDrawingControls =
        (presetRejected || generalViewRejected) && !selectedDesignRevisionAlreadyReviewed && !isComparisonModeActive;

    const showDesignStatus = enableGuidedWaxupComparison && viewerState === 'viewing-model' && !isApprovedWaxup;

    React.useEffect(() => {
        if (selectedPresetStatus === LabsGqlGuidedWaxupPresetStatus.Approved) {
            closeDrawingCanvas();
        }
    }, [selectedPresetStatus, closeDrawingCanvas]);

    React.useEffect(() => {
        if (viewerState !== 'viewing-model') {
            setIsAnnotatingScreenshot(true);
        } else {
            setIsAnnotatingScreenshot(false);
        }
    }, [viewerState, setIsAnnotatingScreenshot]);

    return (
        <Root
            id={'design-viewer-lite-detached-container'}
            showDrawingControls={showDrawingControls}
            isComparisonModeActive={isComparisonModeActive}
            selectedDesignRevisionAlreadyReviewed={selectedDesignRevisionAlreadyReviewed}
        >
            {showDesignStatus && !enableDDPComparison && (
                <DesignStatusContainer selectedDesignRevisionAlreadyReviewed={selectedDesignRevisionAlreadyReviewed}>
                    <Text variant={'body2'} color={selectedDesignRevisionAlreadyReviewed ? 'GRAY' : 'WHITE'} medium>
                        {selectedDesignRevisionAlreadyReviewed ? 'Archived design' : 'Needs Review'}
                    </Text>
                </DesignStatusContainer>
            )}
            <CanvasAndWrapperContainer viewerState={viewerState}>
                <GuidedWaxupModelCanvasButtons
                    setIsAnnotationListModalOpen={setIsAnnotationListModalOpen}
                    markupButtonClick={() => {
                        trackGuidedWaxupButtonClick('Markup');
                        clear();
                        void handleTakeScreenshot();
                    }}
                    displayMobileMarkupButton={displayMobileMarkupButton}
                    annotatedImageUrls={annotatedImageUrls}
                />
                <DesignViewerLiteDetachedWrapper
                    takeSnapshotRef={takeSnapshotRef}
                    designFragment={resolvedDesignFragment}
                    isImmediateDenture={isImmediateDenture}
                    {...props}
                    showDrawingControls={showDrawingControls}
                    isFragmentLoading={!resolvedDesignFragment}
                />
            </CanvasAndWrapperContainer>
            <DrawControlsAndAnnotationContainer id={'draw-controls-and-annotation-container'} viewerState={viewerState}>
                <CanvasContainer>{canvas}</CanvasContainer>
                {isMobile && showDrawingControls && (
                    <GuidedWaxupDrawingControls
                        toolState={toolState}
                        setToolState={setToolStateWrapped}
                        canUndo={undoState.canUndo}
                        canRedo={undoState.canRedo}
                        undo={undo}
                        redo={redo}
                        capturedScreenshotCount={annotatedImageUrls?.length ?? 0}
                        setIsAnnotationListModalOpen={setIsAnnotationListModalOpen}
                    />
                )}
                {(presetRejected || generalViewRejected) && (
                    <GuidedWaxupAnnotationButtons
                        closeDrawingCanvas={closeDrawingCanvas}
                        saveAnnotatedImage={saveAnnotatedImage}
                        trackGuidedWaxupButtonClick={trackGuidedWaxupButtonClick}
                    />
                )}
            </DrawControlsAndAnnotationContainer>
            {!isMobile && showDrawingControls && (
                <GuidedWaxupDrawingControls
                    toolState={toolState}
                    setToolState={setToolStateWrapped}
                    canUndo={undoState.canUndo}
                    canRedo={undoState.canRedo}
                    undo={undo}
                    redo={redo}
                    capturedScreenshotCount={annotatedImageUrls?.length ?? 0}
                    setIsAnnotationListModalOpen={setIsAnnotationListModalOpen}
                />
            )}
            {enableDDPComparison && (
                <ModelViewerFooterBanner
                    isComparisonModeActive={isComparisonModeActive}
                    selectedDesignRevisionAlreadyReviewed={selectedDesignRevisionAlreadyReviewed}
                    selectedWaxup={selectedDesign?.doctor_review}
                    selectedPreset={selectedTab}
                    isPrimaryDisplay={isPrimaryDisplay}
                />
            )}

            <BlockerContainer viewerState={viewerState}>
                <LoadBlocker blocking={true}>
                    <Blocker canvasSize={canvasSize} />
                </LoadBlocker>
            </BlockerContainer>
            <AnnotationListModal
                annotations={annotationModalUrls}
                open={isAnnotationListModalOpen}
                setOpen={setIsAnnotationListModalOpen}
                canRemove={true}
                onRemove={indexToRemove => {
                    const newAnnotationsList = annotationModalUrls.filter((_, i) => i !== indexToRemove);
                    if (newAnnotationsList.length === 0) {
                        setIsAnnotationListModalOpen(false);
                    }
                    setPresetAnnotation?.(newAnnotationsList.map(url => url.imageUrl));
                }}
                dialogProps={{
                    maxWidth: 'md',
                }}
            />
            {children}
        </Root>
    );
};
