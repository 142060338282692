import { PracticeInfoRow } from './PracticeInfoRow';
import { useGetRefabRateBySkuForPartnerQuery } from '@orthly/graphql-react';
import { LabsGqlOrderItemSkuType } from '@orthly/graphql-schema';
import { LoadBlocker } from '@orthly/ui';
import { Text, FlossPalette, Grid, ChevronRight, Icon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const RefabRates: React.FC<{ practiceId: string }> = props => {
    const [hidden, setHidden] = React.useState(true);
    const { practiceId } = props;
    const { data, loading } = useGetRefabRateBySkuForPartnerQuery({
        variables: { partner_id: practiceId },
    });
    const skus = data?.getRefabRateBySkuForPartner?.skus;

    const onHideClick = () => {
        setHidden(current => !current);
    };

    return (
        <LoadBlocker blocking={loading}>
            <Grid container direction={'column'} style={{ paddingTop: 16 }}>
                <Grid
                    container
                    onClick={onHideClick}
                    style={{ alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}
                >
                    <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
                        {hidden ? <ChevronRight /> : <Icon icon={'ExpandMoreIcon'} />}
                        <Text variant={'body2'} medium>
                            Refabs Rates
                        </Text>
                    </Grid>
                    <Grid item>
                        <Text variant={'caption'} medium style={{ color: FlossPalette.LIGHT_GRAY }}>
                            Last 4 weeks & in-flight orders
                        </Text>
                    </Grid>
                </Grid>
                {!hidden && (
                    <Grid container style={{ marginLeft: 24 }}>
                        {skus?.length ? (
                            <Grid container item direction={'row'}>
                                {_.orderBy(skus, 'refab', 'desc').map(({ name, total, refab }) => (
                                    <PracticeInfoRow
                                        key={name}
                                        title={name === LabsGqlOrderItemSkuType.ImplantBridge ? 'Implant bridge' : name}
                                        value={`${refab}/${total} (${Math.round((refab / total) * 100)}%)`}
                                        halfRow
                                    />
                                ))}
                            </Grid>
                        ) : (
                            <Grid item>
                                <Text variant={'caption'}>No recent orders</Text>
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </LoadBlocker>
    );
};
