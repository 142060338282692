import { usePalateState } from '../../../state/Palate.reducer';
import { useOrderIdsByListView, useScans } from '@orthly/graphql-react';
import {
    LabsGqlLabOrderStatus,
    LabsGqlFilterComparator as Comparator,
    LabsGqlLabOrderSortKey,
} from '@orthly/graphql-schema';
import { OrderFilterIdEnum } from '@orthly/shared-types';

type InFlightOrdersProps = { practiceId: string };

export const useInFlightOrders = (props: InFlightOrdersProps) => {
    const orderFilters = usePalateState(s => s.orderFilters);
    return useOrderIdsByListView(
        {
            sort: { key: LabsGqlLabOrderSortKey.DueDate, asc: true },
            filter: {
                excluded_statuses: [
                    LabsGqlLabOrderStatus.Cancelled,
                    LabsGqlLabOrderStatus.NeedsRefabrication,
                    LabsGqlLabOrderStatus.Delivered,
                ],
            },
            criteria: [
                {
                    filter_id: OrderFilterIdEnum.by_partner,
                    comparison_value: props.practiceId,
                    comparator: Comparator.Equals,
                },
                ...(orderFilters ?? []),
            ],
        },
        {
            fetchPolicy: 'cache-first',
        },
    );
};

type CompleteOrdersProps = { practiceId: string };

export const useCompleteOrders = (props: CompleteOrdersProps) => {
    const { practiceId } = props;
    const orderFilters = usePalateState(s => s.orderFilters)?.filter(f => f.filter_id !== 'status');
    return useOrderIdsByListView(
        {
            sort: { key: LabsGqlLabOrderSortKey.DeliveryDate, asc: false },
            filter: {
                status: LabsGqlLabOrderStatus.Delivered,
            },
            criteria: [
                {
                    filter_id: OrderFilterIdEnum.by_partner,
                    comparison_value: practiceId,
                    comparator: Comparator.Equals,
                },
                ...(orderFilters ?? []),
            ],
        },
        {
            fetchPolicy: 'cache-first',
        },
    );
};

type IncompleteProps = { practiceId: string };

export const useIncompleteOrders = (props: IncompleteProps) => {
    const { practiceId } = props;
    const orderFilters = usePalateState(s => s.orderFilters);
    const patientFilter = orderFilters?.find(filter => filter.filter_id === 'by_patient')?.comparison_value;
    return useScans({
        fetchPolicy: 'cache-first',
        variables: {
            practice_id: practiceId,
            patient_names: patientFilter ?? [],
        },
    });
};
