import { ToolButton } from './ToolButton';
import type { ToolsToolbarProps } from './Types';
import { StackY } from '@orthly/ui';
import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        height: '100%',
        flex: 'none',
        padding: '8px 0px',
        backgroundColor: FlossPalette.TAN,
        borderLeft: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    },
}));

export const ToolsToolbar: React.VFC<ToolsToolbarProps> = ({ tools }) => {
    const classes = useStyles();

    const {
        collisions,
        collisionsCurtains,
        curtains,
        margin,
        insertionPaths,
        crossSection,
        thicknessHeatmap,
        occlusalHeatmap,
        proximalHeatmap,
        undercutHeatmap,
        curtainsHeatmap,
        cementGapHeatmap,
        prePrepScansVisibility,
        antagonistScansVisibility,
        scanUndercut,
        restorativesVisibility,
        sculptMask,
    } = tools;

    return (
        <StackY className={classes.root}>
            {collisions && <ToolButton iconName={'CollisionsIcon'} tooltip={'Collisions'} {...collisions} />}
            {collisionsCurtains && (
                <ToolButton
                    iconName={'CollisionsUndercutIcon'}
                    tooltip={'Curtains Collisions'}
                    {...collisionsCurtains}
                />
            )}
            {curtains && <ToolButton iconName={'FinishingCurtainsIcon'} tooltip={'Curtains'} {...curtains} />}
            {margin && <ToolButton iconName={'ModelMarginIcon'} tooltip={'Margin lines'} {...margin} />}
            {insertionPaths && (
                <ToolButton iconName={'InsertionDirectionIcon'} tooltip={'Insertion directions'} {...insertionPaths} />
            )}
            {crossSection && (
                <ToolButton iconName={'ShowCrossSectionIcon'} tooltip={'Cross section'} {...crossSection} />
            )}
            {proximalHeatmap && (
                <ToolButton iconName={'FinishingProximalMapIcon'} tooltip={'Proximal heatmap'} {...proximalHeatmap} />
            )}
            {curtainsHeatmap && (
                <ToolButton iconName={'ProximalUndercutMapIcon'} tooltip={'Curtains heatmap'} {...curtainsHeatmap} />
            )}
            {occlusalHeatmap && (
                <ToolButton iconName={'FinishingOcclusalMapIcon'} tooltip={'Occlusal heatmap'} {...occlusalHeatmap} />
            )}
            {thicknessHeatmap && (
                <ToolButton
                    iconName={'FinishingThicknessMapIcon'}
                    tooltip={'Thickness heatmap'}
                    {...thicknessHeatmap}
                />
            )}
            {undercutHeatmap && (
                <ToolButton
                    iconName={'FinishingRestorativeUndercutIcon'}
                    tooltip={'Undercut heatmap'}
                    {...undercutHeatmap}
                />
            )}
            {cementGapHeatmap && <ToolButton iconName={'Star'} tooltip={'Cement gap heatmap'} {...cementGapHeatmap} />}
            {prePrepScansVisibility && (
                <ToolButton iconName={'ScanIcon'} tooltip={'Toggle Pre Prep Scans'} {...prePrepScansVisibility} />
            )}
            {antagonistScansVisibility && (
                <ToolButton
                    iconName={'LowerJawIcon'}
                    tooltip={'Toggle Antagonist Jaw'}
                    {...antagonistScansVisibility}
                />
            )}
            {scanUndercut && (
                <ToolButton iconName={'FinishingScanUndercutIcon'} tooltip={'Scan undercut'} {...scanUndercut} />
            )}
            {restorativesVisibility && (
                <ToolButton
                    iconName={'EyeStrikethroughIconBig'}
                    tooltip={'Toggle restoratives'}
                    {...restorativesVisibility}
                />
            )}
            {sculptMask && <ToolButton iconName={'LockIcon'} tooltip={'Sculpt mask'} {...sculptMask} />}
        </StackY>
    );
};
