import { DandyMouthToothSelector } from './DandyMouthToothSelector';
import { DandyMouthToothTypeSelector } from './DandyMouthToothTypeSelector';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import type {
    ICartBridgeItem,
    ICartImplantBridgeItem,
    ICartItemImplantMetadata,
    ICartItemSingleToothUnit,
    ICartItemV2DTO,
    ICartItemV2Update,
    ICartPartialDentureItem,
    ICartSurgicalGuideItem,
    ToothNumber,
} from '@orthly/items';
import React from 'react';

export function getMultiToothSelectValidationError(item: ICartItemV2DTO): string | undefined {
    if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Bridge) && item.units.length < 2) {
        return 'Please select at least 2 teeth before proceeding.';
    }
    if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.ImplantBridge)) {
        if (item.implants.length === 0) {
            return 'Please select an abutment before proceeding.';
        }
        if (item.implants.length + item.restoratives.length < 2) {
            return 'Please select at least 2 teeth before proceeding.';
        }
    }
}

export function getImplantBridgeMultiToothSelectWarning(item: ICartItemV2DTO): string | null {
    if (!CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.ImplantBridge)) {
        return null;
    }

    const islands = CartItemV2Utils.getAdjacentTeethGroups([item]);
    for (const island of islands) {
        const endsWithCrown =
            !!item.restoratives.find(i => i.unn === island[0] && i.unit_type === 'Crown') ||
            !!item.restoratives.find(i => i.unn === island[island.length - 1] && i.unit_type === 'Crown');

        if (endsWithCrown) {
            return 'Combining an implant with a natural tooth as bridge anchors might compromise the longevity and performance. A full implant support might be a safer choice.';
        }
    }

    return null;
}

// NOTE: bridge tooth select does not currently allow inlays as units, although they can exist on bridges
interface BridgeMultiToothSelectProps {
    item: ICartBridgeItem;
    updateItem: (update: ICartItemV2Update) => void;
    disabledTeeth?: ToothNumber[];
    archLabels?: boolean;
    verticalLayout?: boolean;
}

// The user may have already selected materials, and then came back to this screen to edit the teeth after.
// If they add new teeth, we can easily infer what the material of the other teeth should be -- it'll be the same as the others.
// If you remove teeth, though, we have an issue -- all traces of the material are stored in the unit, and will be missing,
// thus the user would have to re-select the material.
// When the item is first set, we cache the material of the first unit, and then use it for all subsequent change operations.
function useInitialBridgeItemMaterial(item: ICartBridgeItem) {
    const [initialMaterial, setInitialMaterial] = React.useState<string | undefined>(undefined);
    React.useEffect(() => {
        const firstUnitMaterial = item.units.find(u => !!u.material)?.material;

        if (!initialMaterial && firstUnitMaterial) {
            setInitialMaterial(firstUnitMaterial);
        }
    }, [item, setInitialMaterial, initialMaterial]);

    return initialMaterial;
}

const BridgeMultiToothSelect: React.VFC<BridgeMultiToothSelectProps> = ({
    item,
    updateItem,
    disabledTeeth,
    archLabels,
    verticalLayout = true,
}) => {
    const initialMaterial = useInitialBridgeItemMaterial(item);
    const onChange = React.useCallback(
        (units: ICartItemSingleToothUnit<'Crown' | 'CrownPontic' | 'Wing'>[]) => {
            updateItem({
                name: 'bridge_units',
                payload: units.map(unit => ({ ...unit, material: unit.material ?? initialMaterial })),
            });
        },
        [updateItem, initialMaterial],
    );

    return (
        <DandyMouthToothTypeSelector<'Crown' | 'CrownPontic' | 'Wing'>
            unit_types={['Crown', 'CrownPontic', 'Wing']}
            selected={item.units.filter(
                (u): u is ICartItemSingleToothUnit<'Crown' | 'CrownPontic' | 'Wing'> =>
                    u.unit_type === 'Crown' || u.unit_type === 'CrownPontic' || u.unit_type === 'Wing',
            )}
            onChange={onChange}
            verticalLayout={verticalLayout}
            animate={false}
            disabled={disabledTeeth}
            archLabels={archLabels}
        />
    );
};

interface ImplantBridgeMultiToothSelectProps {
    item: ICartImplantBridgeItem;
    updateItem: (update: ICartItemV2Update) => void;
    disabledTeeth?: ToothNumber[];
    archLabels?: boolean;
    verticalLayout?: boolean;
}

// The user may have already selected materials, and then came back to this screen to edit the teeth after.
// If they add new teeth, we can easily infer what the material/metadata of the other teeth should be -- it'll be the same as the others.
// If you remove teeth, though, we have an issue -- all traces of the material are stored in the unit, and will be missing,
// thus the user would have to re-select the material.
// When the item is first set, we cache the material of the first unit, and then use it for all subsequent change operations.
function useInitialImplantBridgeItemProperties(item: ICartImplantBridgeItem) {
    // We must use state and effects to ensure we only set on the initial invocation of this modal.
    // If the item units all are deleted, we'd end up with an undefined variable as the item itself updates,
    // so we use the state as a cache that's only updated while the unit's properties are defined and no previous cache exists.
    const [initialImplantMetadata, setInitialImplantMetadata] = React.useState<ICartItemImplantMetadata | undefined>(
        undefined,
    );
    const [initialRestorativeMaterial, setInitialRestorativeMaterial] = React.useState<string | undefined>(undefined);
    const [initialAbutmentMaterial, setInitialAbutmentMaterial] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        const firstRestorativeMaterial =
            item.restoratives.find(r => !!r.material)?.material ??
            item.implants.find(r => !!r.crown.material)?.crown.material;
        const firstImplantMetadata = item.implants.find(r => !!r.implant_metadata)?.implant_metadata;
        const firstImplantAbutmentMaterial = item.implants.find(r => !!r.abutment?.material)?.abutment?.material;

        if (!initialRestorativeMaterial && firstRestorativeMaterial) {
            setInitialRestorativeMaterial(firstRestorativeMaterial);
        }

        if (!initialImplantMetadata && firstImplantMetadata) {
            setInitialImplantMetadata(firstImplantMetadata);
        }

        if (!initialAbutmentMaterial && firstImplantAbutmentMaterial) {
            setInitialAbutmentMaterial(firstImplantAbutmentMaterial);
        }
    }, [
        item,
        initialRestorativeMaterial,
        setInitialRestorativeMaterial,
        initialImplantMetadata,
        setInitialImplantMetadata,
        initialAbutmentMaterial,
        setInitialAbutmentMaterial,
    ]);

    return { initialImplantMetadata, initialRestorativeMaterial, initialAbutmentMaterial };
}

const ImplantBridgeMultiToothSelect: React.VFC<ImplantBridgeMultiToothSelectProps> = ({
    item,
    updateItem,
    disabledTeeth,
    archLabels,
    verticalLayout = true,
}) => {
    const { initialAbutmentMaterial, initialImplantMetadata, initialRestorativeMaterial } =
        useInitialImplantBridgeItemProperties(item);
    const onChange = React.useCallback(
        (units: ICartItemSingleToothUnit<'Abutment' | 'CrownPontic' | 'Crown'>[]) => {
            updateItem({
                name: 'implant_bridge_units',
                payload: units.map(u => ({
                    ...u,
                    material:
                        u.material ??
                        (u.unit_type === 'Abutment' ? initialAbutmentMaterial : initialRestorativeMaterial),
                })),
            });

            if (initialImplantMetadata) {
                updateItem({
                    name: 'implant_metadata',
                    payload: initialImplantMetadata,
                });
            }

            if (initialRestorativeMaterial) {
                updateItem({
                    name: 'material',
                    payload: initialRestorativeMaterial,
                });
            }
        },
        [updateItem, initialAbutmentMaterial, initialImplantMetadata, initialRestorativeMaterial],
    );

    return (
        <DandyMouthToothTypeSelector<'Abutment' | 'CrownPontic' | 'Crown'>
            unit_types={['Abutment', 'CrownPontic', 'Crown']}
            selected={[
                ...item.restoratives.filter((u): u is ICartItemSingleToothUnit<'CrownPontic' | 'Crown'> =>
                    ['CrownPontic', 'Crown'].includes(u.unit_type),
                ),
                ...item.implants.map(i => ({ ...i.abutment, unn: i.unn })),
            ]}
            onChange={onChange}
            verticalLayout={verticalLayout}
            animate={false}
            disabled={disabledTeeth}
            archLabels={archLabels}
            itemSku={LabOrderItemSKUType.ImplantBridge}
        />
    );
};

// used for items with multi-tooth units of the same type
interface SimpleMultiToothSelectProps {
    item: ICartPartialDentureItem | ICartSurgicalGuideItem;
    updateItem: (update: ICartItemV2Update) => void;
    disabledTeeth?: ToothNumber[];
    archLabels?: boolean;
    verticalLayout?: boolean;
}

const SimpleMultiToothSelect: React.VFC<SimpleMultiToothSelectProps> = ({
    item,
    updateItem,
    disabledTeeth,
    archLabels,
    verticalLayout = true,
}) => (
    <DandyMouthToothSelector
        selected={item.unit.unns}
        onChange={(unns: ToothNumber[]) => updateItem({ name: 'multi_tooth_unit_item_unns', payload: unns })}
        verticalLayout={verticalLayout}
        animate={false}
        disabled={disabledTeeth}
        archLabels={archLabels}
    />
);

interface DandyItemMultiToothSelectProps {
    item: ICartImplantBridgeItem | ICartBridgeItem | ICartPartialDentureItem | ICartSurgicalGuideItem;
    updateItem: (update: ICartItemV2Update) => void;
    disabledTeeth?: ToothNumber[];
    archLabels?: boolean;
    verticalLayout?: boolean;
}

export const DandyItemMultiToothSelect: React.VFC<DandyItemMultiToothSelectProps> = ({
    item,
    updateItem,
    disabledTeeth,
    archLabels,
    verticalLayout = true,
}) => {
    if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Bridge)) {
        return (
            <BridgeMultiToothSelect
                item={item}
                updateItem={updateItem}
                disabledTeeth={disabledTeeth}
                archLabels={archLabels}
                verticalLayout={verticalLayout}
            />
        );
    }
    if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.ImplantBridge)) {
        return (
            <ImplantBridgeMultiToothSelect
                item={item}
                updateItem={updateItem}
                disabledTeeth={disabledTeeth}
                archLabels={archLabels}
                verticalLayout={verticalLayout}
            />
        );
    }
    return (
        <SimpleMultiToothSelect
            item={item}
            updateItem={updateItem}
            disabledTeeth={disabledTeeth}
            archLabels={archLabels}
            verticalLayout={verticalLayout}
        />
    );
};
