import { isHoliday } from './dandyHolidays';
import { toDate, isValid, differenceInCalendarDays, addDays, isSameDay, isWeekend } from 'date-fns';

/**
 * @name differenceInBusinessDays
 * @category Day Helpers
 * @summary Get the number of business days between the given dates.
 *
 * @description
 * Get the number of business day periods between the given dates.
 * Business days being days that arent in the weekend.
 * Like `differenceInCalendarDays`, the function removes the times from
 * the dates before calculating the difference.
 *
 * @remarks
 *
 * Modified https://github.com/date-fns/date-fns/blob/main/src/differenceInBusinessDays/index.ts
 *
 * Replacing the moment-business-days businessDiff function:
 *      now.businessDiff(designDueDate)
 * becomes
 *      differenceInBusinessDays(new Date(designDueDate), now)
 *
 *
 * @param laterDate - the later date
 * @param earlierDate - the earlier date
 * @returns the number of business days
 *
 * @example
 * // How many business days are between
 * // 10 January 2014 and 20 July 2014?
 * const result = differenceInBusinessDays(
 *   new Date(2014, 6, 20),
 *   new Date(2014, 0, 10)
 * )
 * //=> 136
 *
 * // How many business days are between
 * // 30 November 2021 and 1 November 2021?
 * const result = differenceInBusinessDays(
 *   new Date(2021, 10, 30),
 *   new Date(2021, 10, 1)
 * )
 * //=> 21
 *
 * // How many business days are between
 * // 1 November 2021 and 1 December 2021?
 * const result = differenceInBusinessDays(
 *   new Date(2021, 10, 1),
 *   new Date(2021, 11, 1)
 * )
 * //=> -22
 *
 * // How many business days are between
 * // 1 November 2021 and 1 November 2021?
 * const result = differenceInBusinessDays(
 *   new Date(2021, 10, 1),
 *   new Date(2021, 10, 1)
 * )
 * //=> 0
 */
export function differenceInBusinessDays<DateType extends Date>(
    dirtyLaterDate: DateType | number,
    dirtyEarlierDate: DateType | number,
): number {
    const laterDate = toDate(dirtyLaterDate);
    let earlierDate = toDate(dirtyEarlierDate);

    if (!isValid(laterDate) || !isValid(earlierDate)) {
        throw new Error('Invalid time value');
    }

    const calendarDifference = differenceInCalendarDays(laterDate, earlierDate);
    const sign = calendarDifference < 0 ? -1 : 1;

    let result = 0;

    while (!isSameDay(laterDate, earlierDate)) {
        result += isWeekend(earlierDate) || isHoliday(earlierDate) ? 0 : sign;
        earlierDate = addDays(earlierDate, sign);
    }

    return result === 0 ? 0 : result;
}
