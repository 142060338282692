import { useQcItemConfigsQuery } from '@orthly/graphql-react';
import React from 'react';

export function useQCItemConfigs({ includeArchived, skip }: { includeArchived: boolean; skip: boolean }) {
    const { data, loading, refetch } = useQcItemConfigsQuery({ skip });
    const configs = React.useMemo(() => {
        const configs = data?.listQCItemConfigs ?? [];
        return configs.filter(c => c.archived_at === null || includeArchived);
    }, [data, includeArchived]);
    return { refetch, loading, configs };
}
