import { useOrderDetailContext } from '../../state/OrderDetailProvider.graphql';
import { Button } from '@orthly/ui-primitives';
import { UploadDesignFileAction } from '@orthly/veneer';
import React from 'react';

export const OrderDesignToolbarFileUpload: React.FC = () => {
    const { order, refetch } = useOrderDetailContext();

    if (!order) {
        return null;
    }

    return (
        <UploadDesignFileAction
            onClose={() => {}}
            refetchOrder={refetch}
            order={order}
            CustomButton={btnProps => (
                <Button
                    onClick={btnProps.onClick}
                    variant={'ghost'}
                    startIcon={'AttachFileIcon'}
                    style={{ marginRight: 8 }}
                >
                    {!!order.design_file_path ? 'Replace Design' : 'Upload Design'}
                </Button>
            )}
        />
    );
};
