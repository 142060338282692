import { RoutingRuleUtils } from '../../components/RoutingRule.util';
import type {
    LabsGqlBiasRuleFragment,
    LabsGqlDesignRuleFragment,
    LabsGqlCapacityRuleFragment,
    LabsGqlTargetDistributionRuleFragment,
} from '@orthly/graphql-operations';
import { useGetRoutingRulesForOrderQuery, useListLabOrgs } from '@orthly/graphql-react';
import MUITable from '@orthly/mui-table';
import { SimpleInput, SimpleSelect } from '@orthly/ui';
import { FlossPalette, Grid, Tooltip, InfoIcon } from '@orthly/ui-primitives';
import React from 'react';

export const RoutingRulesByOrder = () => {
    const [orderId, setOrderId] = React.useState('');
    const [manufacturerId, setManufacturerId] = React.useState('');
    const [internalDesign, setInternalDesign] = React.useState('');

    const { data: labOrgs, loading: labOrgsLoading } = useListLabOrgs();
    const labsToOrganization: Record<string, string> = (labOrgs?.listOrganizations ?? []).reduce((mapping, labOrg) => {
        return { ...mapping, [labOrg.id]: labOrg.name };
    }, {});

    const { data, loading } = useGetRoutingRulesForOrderQuery({
        skip: !orderId,
        variables: {
            orderId: orderId,
            manufacturerId: manufacturerId ?? null,
            internalDesign: internalDesign ? JSON.parse(internalDesign) : null,
        },
    });
    return (
        <Grid container>
            <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                <SimpleInput
                    onChange={value => setOrderId(value ?? '')}
                    value={orderId}
                    label={'Enter an Order ID'}
                    TextFieldProps={{ style: { width: '700px' } }}
                />
                <SimpleInput
                    onChange={value => setManufacturerId(value ?? '')}
                    value={manufacturerId}
                    label={'Enter a Manufacturer ID (for lab filter rules)'}
                    TextFieldProps={{ style: { width: '700px' } }}
                />
                <SimpleSelect
                    style={{ width: '500px' }}
                    value={internalDesign}
                    options={[
                        { label: 'true', value: 'true' },
                        { label: 'false', value: 'false' },
                    ]}
                    onChange={value => setInternalDesign(value ?? '')}
                    label={'Select an internal design value (for lab filter rules)'}
                />
            </Grid>
            {!loading && !labOrgsLoading && orderId && (
                <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
                    <Grid item xs={12}>
                        <MUITable<LabsGqlDesignRuleFragment>
                            title={`Design Rules`}
                            data={data?.getRoutingRulesForOrder.designRules || []}
                            loading={loading}
                            displayOptions={{ download: true, filter: true, sort: true, filterValues: true }}
                            columns={RoutingRuleUtils.getDesignListColumns()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MUITable<LabsGqlBiasRuleFragment>
                            title={`Lab Filter Rules`}
                            data={data?.getRoutingRulesForOrder.labFilterRules || []}
                            loading={loading}
                            displayOptions={{ filter: true, sort: true, filterValues: true }}
                            columns={[...RoutingRuleUtils.commonColumns]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MUITable<LabsGqlCapacityRuleFragment & { manufacturer_name?: string }>
                            title={`Capacity Rules`}
                            data={data?.getRoutingRulesForOrder.capacityRules || []}
                            loading={loading}
                            displayOptions={{ download: true, filter: true, sort: true, filterValues: true }}
                            columns={RoutingRuleUtils.getCapacityListColumns()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MUITable<LabsGqlTargetDistributionRuleFragment>
                            title={'Lab Target Distribution Rules'}
                            data={data?.getRoutingRulesForOrder.targetDistributionRules ?? []}
                            loading={loading}
                            displayOptions={{ filter: true, sort: true, filterValues: true }}
                            columns={[
                                {
                                    title: (
                                        <Grid
                                            container
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <p>Priority</p>
                                            <Tooltip
                                                title={`If an order matches with multiple Target Distribution Rules, the rule with the highest Priority value will be used for routing`}
                                            >
                                                <InfoIcon
                                                    style={{
                                                        color: FlossPalette.GRAY,
                                                        width: 16,
                                                        height: 16,
                                                        marginRight: 4,
                                                    }}
                                                />
                                            </Tooltip>
                                        </Grid>
                                    ),
                                    name: 'priority',
                                    field: 'priority',
                                    render: row => <>{row.priority === -1 ? 'Global' : row.priority}</>,
                                    type: 'numeric',
                                },
                                ...RoutingRuleUtils.commonColumns,
                                {
                                    title: 'Target Distribution',
                                    name: 'targetDistribution',
                                    field: 'target_distribution_items',
                                    render: row => {
                                        return row.routing_target_distribution_items.map((item, key) => {
                                            return (
                                                <p key={`${item.manufacturer_id}_${key}`}>
                                                    {labsToOrganization[item.manufacturer_id] ?? item.manufacturer_id}:{' '}
                                                    {item.weight}
                                                </p>
                                            );
                                        });
                                    },
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
