import { formatDataTestTitle } from '../../util/format-data-test-title';
import { useMergedMuiClasses } from '../../util/mergeMuiClasses';
import { stylesFactory } from '../../util/stylesFactory';
import type { SubNavigationEntry, SubNavigationItemClassKey } from './SubNavigation.types';
import { Format } from '@orthly/runtime-utils';
import {
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Grid,
    FlossPalette,
    FlossPaletteUtils,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory<
    { error: boolean; active: boolean; showBottomAction?: boolean },
    SubNavigationItemClassKey
>(() => ({
    root: props => ({
        backgroundColor: props.active ? FlossPaletteUtils.toRgba('STAR_GRASS', 0.15) : undefined,
        height: props.showBottomAction ? 64 : 40,
        paddingLeft: 24,
    }),
    text: props => ({
        color: props.error ? FlossPalette.ATTENTION : undefined,
        fontWeight: 500,
        fontSize: 14,
    }),
    count: props => ({
        color: props.error ? FlossPalette.ATTENTION : undefined,
        fontSize: 14,
        right: 24,
    }),
    badge: {
        width: 20,
        height: 20,
        backgroundColor: FlossPalette.ATTENTION,
        color: FlossPalette.WHITE,
        borderRadius: 4,
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textWrapper: { paddingRight: 4 },
    tooltip: { padding: 0, background: 'none' },
}));

interface SubNavigationItemProps<V extends string = string> extends SubNavigationEntry<V> {
    onClick: () => void;
    active: boolean;
}

export function SubNavigationItem<V extends string = string>(props: SubNavigationItemProps<V>): React.ReactElement {
    const {
        value,
        label,
        onClick,
        count,
        alertCount,
        active,
        variant,
        disabled,
        StartIcon,
        EndAction,
        showBottomAction,
        BottomAction,
        showLabelTooltip,
        Container,
    } = props;
    const classes = useMergedMuiClasses<SubNavigationItemClassKey>(
        useStyles({ active, showBottomAction, error: variant === 'error' }),
        props.classes,
    );
    const item = (
        <ListItem onClick={() => onClick()} button disabled={disabled} className={classes.root}>
            <Grid container>
                {StartIcon && <StartIcon value={value} active={active} disabled={!!disabled} />}
                <Tooltip title={label ?? value} disableHoverListener={!showLabelTooltip} className={classes.tooltip}>
                    <ListItemText
                        primary={label ?? value}
                        primaryTypographyProps={{ className: classes.text }}
                        className={classes.textWrapper}
                        data-test={`sub-navigation-item-${formatDataTestTitle(label ?? value)}`}
                    />
                </Tooltip>
                {typeof count === 'number' && count > 0 && (
                    <ListItemSecondaryAction className={classes.count}>
                        {Format.appendMagnitudeAndTruncate(count, true)}
                    </ListItemSecondaryAction>
                )}
                {typeof alertCount === 'number' && alertCount > 0 && (
                    <ListItemSecondaryAction className={classes.badge}>{alertCount}</ListItemSecondaryAction>
                )}
                {EndAction && <EndAction value={value} active={active} disabled={!!disabled} />}
            </Grid>
            {showBottomAction && BottomAction && <BottomAction value={value} active={active} disabled={!!disabled} />}
        </ListItem>
    );
    if (Container) {
        return <Container {...props}>{item}</Container>;
    }
    return item;
}
