import type { LabsGqlDoctorDeliveryAddressFragment } from '@orthly/graphql-operations';
import React from 'react';

export const mapStyles: google.maps.MapTypeStyle[] = [
    {
        featureType: 'all',
        elementType: 'all',
        stylers: [
            {
                saturation: 32,
            },
            {
                lightness: -3,
            },
            {
                visibility: 'on',
            },
            {
                weight: 1.18,
            },
        ],
    },
    {
        featureType: 'administrative',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'all',
        stylers: [
            {
                saturation: -70,
            },
            {
                lightness: 14,
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                saturation: 100,
            },
            {
                lightness: -14,
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'off',
            },
            {
                lightness: 12,
            },
        ],
    },
];

function formatAddressForGoogleMaps(address: LabsGqlDoctorDeliveryAddressFragment): string {
    const streetTwoText = address.street_two ? ` - ${address.street_two}` : '';
    return `${address.street_one}${streetTwoText} ${address.city}, ${address.state} ${address.postal_code}`;
}

const latLongsEqual = (latLongObject: { lat: number; lng: number }, addressLatLong: { lat: number; lng: number }) => {
    return latLongObject.lat === addressLatLong.lat && latLongObject.lng === addressLatLong.lng;
};

export function useAddressLatLng(address: LabsGqlDoctorDeliveryAddressFragment) {
    const { latitude, longitude } = address;
    const [latLong, setLatLong] = React.useState<{ lat: number; lng: number } | undefined>(
        latitude && longitude ? { lat: latitude, lng: longitude } : undefined,
    );
    const [latLongLoadAttempted, setLatLongLoadAttempted] = React.useState<boolean>(!!(latitude && longitude));
    React.useEffect(() => {
        // if we don't have lat and long set in state attempt to use the Google Maps geocoder to get them from address
        if (!latLong && !latLongLoadAttempted) {
            setLatLongLoadAttempted(true);
            const geocoder = new google.maps.Geocoder();
            void geocoder.geocode({ address: formatAddressForGoogleMaps(address) }, results => {
                if (results && results.length > 0 && results[0]) {
                    setLatLong(results[0].geometry.location.toJSON());
                }
            });
        } else if (latitude && longitude && latLong && !latLongsEqual(latLong, { lat: latitude, lng: longitude })) {
            // if the address is edited/updated we need to reset the lat/lon to update the google map
            setLatLong({ lat: latitude, lng: longitude });
        }
    }, [address, latLong, latLongLoadAttempted, latitude, longitude, setLatLong]);

    return latLong;
}
