import { AppStateProvider, isCompleteScanModels, useLoading } from './AppState.hooks';
import { CentralArea } from './CentralArea/CentralArea';
import { ErrorPlaceholder } from './ErrorPlaceholder';
import { checkForError } from './ErrorPlaceholder.utils';
import { useFetchDesignData } from './FetchDesignData.hooks.graphql';
import type { DesignModels } from './FetchDesignData.hooks.graphql';
import { LeftSidebar } from './LeftSidebar/LeftSidebar';
import type { FinishingCallbacks } from './OrderState.hooks';
import { OrderStateProvider, useOrder, useFinishingCallbacks, useIsTrainingOrder } from './OrderState.hooks';
import { RightSidebar } from './RightSidebar/RightSidebar';
import type { MinimalOrder } from './Types';
import { LabeledWindowBorder } from '@orthly/dentin';
import type { VeneerDesignFinishingDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { isArrayMin1 } from '@orthly/runtime-utils';
import { LoadBlocker, StackX, useEnableSentrySessionReplay } from '@orthly/ui';
import { Popover, Text, styled } from '@orthly/ui-primitives';
import React from 'react';
import { useHotkeysContext } from 'react-hotkeys-hook';

const Container = styled(StackX)(({ padded }: { padded: boolean }) => ({
    height: '100vh',
    width: '100vw',
    padding: padded ? 8 : undefined,
}));

const PaperContentsInner: React.VFC = () => {
    const isTraining = useIsTrainingOrder();

    const loading = useLoading();

    return (
        <>
            <LoadBlocker blocking={loading} overlayColor={'transparent'}>
                <Container padded={isTraining}>
                    <LeftSidebar />
                    <CentralArea />
                    <RightSidebar />
                </Container>
            </LoadBlocker>
            {isTraining && (
                <LabeledWindowBorder placement={'bottom'} blockClicks={false}>
                    <Text color={'WHITE'} variant={'body2'} medium>
                        This is a training order
                    </Text>
                </LabeledWindowBorder>
            )}
        </>
    );
};

interface PaperContentsProps {
    loading: boolean;
    loadingError: boolean;
    models: DesignModels;
}

const PaperContents: React.VFC<PaperContentsProps> = ({ loading, loadingError, models }) => {
    const { closeWindow } = useFinishingCallbacks();
    const order = useOrder();

    if (loading) {
        // Return a placeholder so that the loading spinner is centered.
        return <Container padded={false} />;
    }

    const { restorativeModels, scanModels, marginLines } = models;

    const hasRestorativeModels = isArrayMin1(restorativeModels);

    const errorMessage = checkForError({
        hasDownloadError: loadingError,
        hasUpperJawScan: !!scanModels.upperJaw,
        hasLowerJawScan: !!scanModels.lowerJaw,
        hasRestorativeModel: hasRestorativeModels,
    });

    if (errorMessage) {
        return <ErrorPlaceholder onClose={() => closeWindow(true)} message={errorMessage} />;
    }

    if (!hasRestorativeModels || !isCompleteScanModels(scanModels)) {
        // This should not be possible because of the logic in `checkForError`, but TS doesn't realize that so manually
        // narrow the types here.
        throw new Error('Unexpected state: missing restorative items, upper jaw, or lower jaw.');
    }

    return (
        <AppStateProvider
            order={order}
            restorativeModels={restorativeModels}
            scanModels={scanModels}
            marginLines={marginLines}
        >
            <PaperContentsInner />
        </AppStateProvider>
    );
};

export interface DesignFinishingRootProps {
    order: MinimalOrder;
    design: FragmentType<typeof VeneerDesignFinishingDesign_FragmentFragmentDoc>;
    open: boolean;
    initiallyOpen: boolean | undefined;
    callbacks: FinishingCallbacks;
    isTrainingOrder?: boolean;
}

export const DesignFinishingRoot: React.VFC<DesignFinishingRootProps> = ({
    order,
    design: designFragment,
    open,
    initiallyOpen,
    callbacks,
    isTrainingOrder,
}) => {
    const {
        loading: designPayloadsLoading,
        error: designPayloadsError,
        design,
        models,
        automateValidation,
    } = useFetchDesignData(designFragment, order);

    useDisableOrderDetailTabHotkeys(open);
    useEnableSentrySessionReplay(open);

    return (
        <OrderStateProvider
            order={order}
            design={design}
            automateValidation={automateValidation}
            initiallyOpen={initiallyOpen}
            callbacks={callbacks}
            isTrainingOrder={isTrainingOrder}
        >
            <Popover
                anchorReference={'none'}
                open={open}
                PaperProps={{
                    style: { width: '100vw', height: '100vh', maxWidth: '100vw', maxHeight: '100vh' },
                }}
            >
                <LoadBlocker blocking={designPayloadsLoading} overlayColor={'transparent'}>
                    <PaperContents
                        loading={designPayloadsLoading}
                        loadingError={!!designPayloadsError}
                        models={models}
                    />
                </LoadBlocker>
            </Popover>
        </OrderStateProvider>
    );
};

function useDisableOrderDetailTabHotkeys(open: boolean) {
    const { enableScope, disableScope } = useHotkeysContext();

    React.useEffect(() => {
        if (!open) {
            return;
        }

        disableScope('tabs');

        return () => enableScope('tabs');
    }, [enableScope, disableScope, open]);
}
