import { ItemEditorV2DialogField } from '../../components/ItemEditorV2DialogField';
import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { ItemLinkRelationshipSelect } from '@orthly/dentin';
import { CartItemV2Utils } from '@orthly/items';
import type { LabOrderItemSKUType } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

export const ItemEditorV2LinkRelationshipField: React.VFC<
    ItemEditorV2BaseProps<LabOrderItemSKUType.Implant | LabOrderItemSKUType.ImplantBridge>
> = ({ item, updateItem, showError }) => {
    const value = CartItemV2Utils.getImplantMetadata(item)?.relationship ?? undefined;

    return (
        <ItemEditorV2DialogField
            fieldLabel={'Relationship'}
            fieldValue={value && _.startCase(value)}
            modalTitle={'Select Relationship'}
            modalContent={
                <ItemLinkRelationshipSelect
                    value={value}
                    onChange={relationship => updateItem({ name: 'implant_metadata', payload: { relationship } })}
                />
            }
            // the only way to get the tooltips to display properly after mount is to force a deep re-render,
            // which apparently only happens when the provided value changes, so we just set it to itself again on open
            onOpen={() => updateItem({ name: 'implant_metadata', payload: { relationship: value } })}
            errorText={showError && !value ? 'This field is required' : undefined}
        />
    );
};
