import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import type { LabOrderItemSKUType } from '@orthly/items';
import { CartItemV2Utils } from '@orthly/items';
import { SimpleSelect } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

export const ItemEditorV2ImplantAuthenticField: React.VFC<
    Omit<ItemEditorV2BaseProps<LabOrderItemSKUType.Implant | LabOrderItemSKUType.ImplantBridge>, 'showError'>
> = ({ item, updateItem }) => {
    const authentic = CartItemV2Utils.getImplantMetadata(item)?.authentic ?? undefined;

    return (
        <SimpleSelect
            label={'Authentic (OEM)'}
            // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
            // eslint-disable-next-line no-nested-ternary
            value={_.isBoolean(authentic) ? (authentic ? 'Yes' : 'No') : undefined}
            options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
            ]}
            onChange={val =>
                updateItem({
                    name: 'implant_metadata',
                    payload: {
                        authentic: val === 'Yes',
                        // generic manufacturer is not used in the new system so we clear it if set and edits are made
                        generic_manufacturer: null,
                    },
                })
            }
        />
    );
};
