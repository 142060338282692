import { BigRadioSelect } from '../CheckoutFormFields/BigRadioSelect';
import type { BigRadioOption } from '../CheckoutFormFields/BigRadioSelect';
import { OrderItemLinkRelationship, CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import type { ICartImplantItem, ICartImplantBridgeItem } from '@orthly/items';
import { FlossPalette, HelpIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

/**
 * This component was originally created in the fluoride package.
 * Full history: https://github.com/orthly/Scanner/commits/develop/packages/fluoride/src/components/case-builder/setup/items/steps/ItemLinkRelationshipSelect.tsx
 */

const tiBaseBannedRelationships: (OrderItemLinkRelationship | undefined)[] = [
    OrderItemLinkRelationship.CementRetained,
    OrderItemLinkRelationship.Screwmentable,
];

enum AbutmentMaterialValue {
    DANDY_RECOMMENDED = 'Dandy Recommended',
    CUSTOM_TITANIUM = 'Custom Titanium Abutment',
    CUSTOM_ZIRCONIA_HYBRID = 'Custom Zirconia Hybrid Abutment',
    TI_BASE = 'Ti-Base',
}

const ABUTMENT_MATERIAL_DESCRIPTIONS: { [K in AbutmentMaterialValue]: string } = {
    [AbutmentMaterialValue.DANDY_RECOMMENDED]: '',
    [AbutmentMaterialValue.CUSTOM_TITANIUM]: 'Allows us to create an abutment with crown and tissue support.',
    [AbutmentMaterialValue.CUSTOM_ZIRCONIA_HYBRID]:
        'Zirconia and titanium custom milled abutment. Eliminates any gray or gold tones from the titanium. ',
    [AbutmentMaterialValue.TI_BASE]:
        'A stock titanium component. Creates an interface between the crown and the implant.',
};

// implant bridges with screw retained relationships can only use ti-base, with the exception of IB w/ exactly one implant and one restorative
const shouldDisableNonTiBase = (item: ICartImplantItem | ICartImplantBridgeItem) =>
    CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.ImplantBridge) &&
    CartItemV2Utils.getImplantMetadata(item)?.relationship === OrderItemLinkRelationship.ScrewRetained &&
    (item.implants.length !== 1 || item.restoratives.length !== 1);

interface UseAutoResetAbutmentMaterialProps {
    item: ICartImplantItem | ICartImplantBridgeItem;
    onChange: (val?: string) => void;
    value?: string;
    useDefaultValue?: boolean;
}

export const useAutoResetAbutmentMaterial = ({
    item,
    onChange,
    value,
    useDefaultValue,
}: UseAutoResetAbutmentMaterialProps) => {
    const relationship = CartItemV2Utils.getImplantMetadata(item)?.relationship ?? undefined;
    const nonTiBaseDisabled = shouldDisableNonTiBase(item);

    React.useEffect(() => {
        // if Ti-Base is selected and the relationship changes to CR/Screwmentable, then we reset the material selection since they're not compatible
        if (tiBaseBannedRelationships.includes(relationship) && value === AbutmentMaterialValue.TI_BASE) {
            onChange(useDefaultValue ? AbutmentMaterialValue.DANDY_RECOMMENDED : undefined);
        }
        // if the only thing showing is ti-base, because of the screw retained IB restriction, then we force the selection or reset any selection
        if (nonTiBaseDisabled && value !== AbutmentMaterialValue.TI_BASE) {
            if (useDefaultValue) {
                onChange(AbutmentMaterialValue.TI_BASE);
            } else if (value) {
                // only reset when they have something set, because undefined will also not equal ti-base and that's ok if we're not using default value
                onChange(undefined);
            }
        }
    }, [relationship, value, nonTiBaseDisabled, onChange, useDefaultValue]);
};

interface ItemAbutmentMaterialSelectProps {
    item: ICartImplantItem | ICartImplantBridgeItem;
    value?: string;
    onChange: (val?: string) => void;
}

export const ItemAbutmentMaterialSelect: React.VFC<ItemAbutmentMaterialSelectProps> = ({ item, value, onChange }) => {
    const relationship = CartItemV2Utils.getImplantMetadata(item)?.relationship ?? undefined;
    // implant bridges with screw retained relationships can only use ti-base, with the exception of IB w/ exactly one implant and one restorative
    const disableNonTiBase = shouldDisableNonTiBase(item);

    const options: BigRadioOption<AbutmentMaterialValue>[] = React.useMemo(() => {
        return _.compact([
            !disableNonTiBase && {
                label: "Lab's choice",
                value: AbutmentMaterialValue.DANDY_RECOMMENDED,
            },
            !disableNonTiBase && {
                label: 'Custom Titanium',
                value: AbutmentMaterialValue.CUSTOM_TITANIUM,
                helpText: ABUTMENT_MATERIAL_DESCRIPTIONS[AbutmentMaterialValue.CUSTOM_TITANIUM],
                endAdornment: <HelpIcon style={{ color: FlossPalette.GRAY }} />,
            },
            !disableNonTiBase && {
                label: 'Custom Zirconia Hybrid',
                value: AbutmentMaterialValue.CUSTOM_ZIRCONIA_HYBRID,
                helpText: ABUTMENT_MATERIAL_DESCRIPTIONS[AbutmentMaterialValue.CUSTOM_ZIRCONIA_HYBRID],
                endAdornment: <HelpIcon style={{ color: FlossPalette.GRAY }} />,
            },
            !tiBaseBannedRelationships.includes(relationship)
                ? {
                      label: 'Ti-Base',
                      value: AbutmentMaterialValue.TI_BASE,
                      helpText: ABUTMENT_MATERIAL_DESCRIPTIONS[AbutmentMaterialValue.TI_BASE],
                      endAdornment: <HelpIcon style={{ color: FlossPalette.GRAY }} />,
                  }
                : undefined,
        ]);
    }, [relationship, disableNonTiBase]);

    useAutoResetAbutmentMaterial({ item, onChange, value, useDefaultValue: true });

    return (
        <BigRadioSelect
            onChange={onChange}
            options={options}
            recommendation={AbutmentMaterialValue.DANDY_RECOMMENDED}
            value={value}
        />
    );
};
