import { PausedPracticePauseButton } from './PausedPracticePauseButton';
import { PausedPracticeUnpauseButton } from './PausedPracticeUnpauseButton.graphql';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlOrderingPausedPracticeReason } from '@orthly/graphql-schema';
import { FlossPalette, Grid, Text, stylesFactory } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

const useStyles = stylesFactory(() => ({
    bannerRoot: {
        backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
        width: '100%',
        padding: 8,
    },
    bannerButton: {
        height: 24,
    },
}));

const AdminPausedPracticeBanner_Query = graphql(`
    query AdminPausedPracticeBanner_Query($practice_id: String!) {
        getPracticeOrderingPauseInformation(practice_id: $practice_id) {
            is_archived
            reasons
            updated_at
        }
    }
`);

function getPauseReason(reasons: LabsGqlOrderingPausedPracticeReason[]): string {
    return reasons
        .map(reason => {
            if (reason === LabsGqlOrderingPausedPracticeReason.TooManyUnpaidInvoices) {
                return 'excessive delinquent invoices';
            }

            // Will never happen yet, but if this changes in the future, you know where to edit!
            return '';
        })
        .join(', ');
}

export const PausedPracticeBanner: React.VFC<{ practiceId: string }> = ({ practiceId }) => {
    const classes = useStyles();

    const { data, refetch } = useQuery(AdminPausedPracticeBanner_Query, {
        variables: {
            practice_id: practiceId,
        },
    });
    const pausedData = data?.getPracticeOrderingPauseInformation;

    if (pausedData && !pausedData.is_archived) {
        return (
            <Grid container direction={'row'} className={classes.bannerRoot}>
                <Grid item xs>
                    <Text variant={'body2'} color={'ATTENTION_FOREGROUND'} medium>
                        All new orders from this practice will be placed on hold as of{' '}
                        {moment(pausedData.updated_at).format('MM/DD/YY')} due to {getPauseReason(pausedData.reasons)}.
                    </Text>
                </Grid>
                <Grid item>
                    <PausedPracticeUnpauseButton
                        practiceId={practiceId}
                        refetch={refetch}
                        reasons={pausedData.reasons}
                    />
                </Grid>
            </Grid>
        );
    }

    return <PausedPracticePauseButton practiceId={practiceId} refetch={refetch} />;
};
