import { ItemEditorV2SectionWrapper } from '../../components/ItemEditorV2SectionWrapper';
import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { ItemEditorV2Utils } from '../../utils/ItemEditorV2.util';
import { ItemEditorV2PreferenceField } from './ItemEditorV2PreferenceField';
import { useGetDoctorPreferencesByIdQuery } from '@orthly/graphql-react';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface ItemEditorV2PreferencesSectionProps extends ItemEditorV2BaseProps {
    isInternal: boolean;
    doctorPreferencesId: string;
}

export const ItemEditorV2PreferencesSection: React.VFC<ItemEditorV2PreferencesSectionProps> = ({
    item,
    updateItem,
    showError,
    isInternal,
    doctorPreferencesId,
}) => {
    const { data: doctorPreferences } = useGetDoctorPreferencesByIdQuery({
        variables: { doctor_id: doctorPreferencesId },
    });

    const metafields = ItemEditorV2Utils.getMetafieldsForItem(item);

    if (metafields.length === 0) {
        return null;
    }

    return (
        <ItemEditorV2SectionWrapper title={'Preferences'}>
            <Grid container spacing={2}>
                {metafields.map(field => (
                    <Grid item xs={12} md={6} key={field.id}>
                        <ItemEditorV2PreferenceField
                            item={item}
                            updateItem={updateItem}
                            showError={showError}
                            field={field}
                            isInternal={isInternal}
                            doctorPreferences={doctorPreferences?.preferences.custom_field_preferences ?? null}
                        />
                    </Grid>
                ))}
            </Grid>
        </ItemEditorV2SectionWrapper>
    );
};
