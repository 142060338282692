import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { RootActionDialog } from '@orthly/ui';
import { Button, FlossPalette, Text, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const IncompletedWorkReminderModal: React.VFC = () => {
    const {
        enableStructuredDesignReviewNotes,
        isAnnotatingScreenshot,
        isIncompletedWorkReminderModalOpen,
        selectedTab,
        setIsIncompletedWorkReminderModalOpen,
    } = useGuidedWaxupContext();
    const formattedTabTitle =
        selectedTab === LabsGqlGuidedWaxupPresetType.GeneralView ? 'design' : _.startCase(selectedTab).toLowerCase();

    const titles = React.useMemo(() => {
        if (isAnnotatingScreenshot) {
            return {
                title: `You have unsaved changes`,
                subtitle: `You have an unsaved markup in progress. Please save the markup or delete the markup first.`,
            };
        }

        if (enableStructuredDesignReviewNotes) {
            return {
                title: `Please select all required fields for the ${formattedTabTitle}`,
                subtitle: `You have stated that you want to reject the ${formattedTabTitle}. Please specify the precise reason by selecting from the subcategories.`,
            };
        }

        return {
            title: `Please supply a rejection note first for the ${formattedTabTitle}`,
            subtitle: `You have stated you want to reject the ${formattedTabTitle}. A rejection note is required to submit your review.`,
        };
    }, [enableStructuredDesignReviewNotes, formattedTabTitle, isAnnotatingScreenshot]);

    return (
        <RootActionDialog
            loading={false}
            open={isIncompletedWorkReminderModalOpen}
            setOpen={setIsIncompletedWorkReminderModalOpen}
            hideButton={true}
            title={titles.title}
            content={
                <Grid container>
                    <Grid item>
                        <Text variant={'body1'} color={'GRAY'}>
                            {titles.subtitle}
                        </Text>
                    </Grid>
                    <Grid item container sx={{ flexDirection: 'row', justifyContent: 'flex-end', padding: '8px' }}>
                        <Grid item>
                            <Button
                                variant={'primary'}
                                onClick={() => {
                                    setIsIncompletedWorkReminderModalOpen(false);
                                }}
                                sx={{ border: `1px solid ${FlossPalette.STROKE_LIGHT}`, margin: '15px 5px 5px 5px' }}
                            >
                                Done
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
        />
    );
};
