import { useTrackImpersonateOpen } from '../../../PalateMetrics';
import { OpenIncognitoDialog } from '../../../components/OpenIncognitoDialog';
import { usePalateStyles } from '../../../usePalateStyles';
import { useIncompleteOrders } from './queryHooks';
import { calculateSKU } from '@orthly/dentin';
import { MultiProductImageWrapper } from '@orthly/dentin';
import type { ProductUnitType } from '@orthly/items';
import { isValidProductUnitType } from '@orthly/items';
import { LoadBlocker } from '@orthly/ui';
import { Text, Grid } from '@orthly/ui-primitives';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

interface IncompleteOrdersProps {
    practiceUrl: string;
    practiceId: string;
}

interface IncompleteOrderDetailsProps {
    id: string;
    patientName: string;
    date: string;
    products: ProductUnitType[];
    lastOrder: boolean;
    practiceUrl: string;
    practiceId: string;
}

const IncompleteOrderDetails: React.FC<IncompleteOrderDetailsProps> = props => {
    const classes = usePalateStyles();
    const { id, patientName, date, products, lastOrder, practiceUrl, practiceId } = props;
    const [open, setOpen] = React.useState(false);
    const impersonateUrl = practiceUrl.replace('?', `/lab/submit?scanId=${id}&`);
    const trackImpersonateOpen = useTrackImpersonateOpen(practiceId, impersonateUrl);

    return (
        <>
            <OpenIncognitoDialog
                open={open}
                setOpen={setOpen}
                practiceUrl={impersonateUrl}
                title={'Open this incomplete order'}
            />
            <Grid
                container
                wrap={'nowrap'}
                className={clsx(classes.order, !lastOrder && classes.orderBorder)}
                alignItems={'center'}
                onClick={() => {
                    trackImpersonateOpen();
                    setOpen(true);
                }}
            >
                <Grid item style={{ paddingRight: 8 }}>
                    <MultiProductImageWrapper products={products} direction={'column'} maxNumOrbs={3} />
                </Grid>
                <Grid item style={{ marginTop: -4 }}>
                    <Text variant={'caption'} medium>{`${patientName}'s ${(
                        products[0] ?? 'Other'
                    ).toLowerCase()}`}</Text>
                    <Grid container>
                        <Text variant={'caption'} color={'DARK_GRAY'}>
                            Since {moment(date).format('DD MMM YYYY')}
                        </Text>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export const IncompleteOrdersView: React.FC<IncompleteOrdersProps> = props => {
    const classes = usePalateStyles();
    const { practiceUrl, practiceId } = props;
    const { scans, loading: loadingScans } = useIncompleteOrders({ practiceId });

    return (
        <LoadBlocker blocking={loadingScans} ContainerProps={{ style: { overflow: 'auto', height: '100%' } }}>
            <Grid container>
                {scans.length > 0 ? (
                    <Grid container>
                        {_.orderBy(scans, s => new Date(s.created_at).valueOf(), 'desc').map((scan, index) => (
                            <IncompleteOrderDetails
                                key={index}
                                id={scan.id}
                                patientName={`${scan.patient_first_name} ${scan.patient_last_name}`}
                                date={scan.created_at}
                                products={_.uniq(
                                    scan.restorations.map(r => {
                                        const sku = calculateSKU(r);
                                        return isValidProductUnitType(sku) ? sku : 'Unknown';
                                    }),
                                )}
                                lastOrder={index === scans.length - 1}
                                practiceUrl={practiceUrl}
                                practiceId={practiceId}
                            />
                        ))}
                    </Grid>
                ) : (
                    <Text className={classes.sectionHeader} variant={'body2'} medium>
                        No incomplete orders
                    </Text>
                )}
            </Grid>
        </LoadBlocker>
    );
};
