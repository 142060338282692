import { usePaginationOptionsContext } from '../../contexts/TableOptionsContext';
import { MUITablePagination } from './MUITablePagination';
import { createStyles, makeStyles } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(
    () =>
        createStyles({
            root: {
                position: 'relative',
            },
            table: {},
        }),
    { name: 'MUITableFooter' },
);

export const MUITableFooter: React.VFC = () => {
    const classes = useStyles();
    const paginationOptions = usePaginationOptionsContext();
    if (paginationOptions.disable) {
        return null;
    }
    return (
        <div className={classes.root}>
            <MUITablePagination />
        </div>
    );
};
