import { Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface ItemEditorV2SectionWrapperProps {
    title: string;
}

export const ItemEditorV2SectionWrapper: React.FC<ItemEditorV2SectionWrapperProps> = ({ title, children }) => {
    return (
        <Grid item>
            <Grid container direction={'column'} spacing={1}>
                <Grid item>
                    <Text variant={'body1'} medium>
                        {title}
                    </Text>
                </Grid>
                <Grid item>{children}</Grid>
            </Grid>
        </Grid>
    );
};
