import { useTranslationOptionsContext } from '../../contexts/TableOptionsContext';
import { useTableStateAction } from '../../state/action-hooks';
import type { Theme } from '@orthly/ui-primitives';
import { createStyles, makeStyles, Grow, IconButton, TextField, ClearIcon, SearchIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import type { ChangeEvent } from 'react';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            display: 'flex',
            flex: '1 0 auto',
        },
        searchIcon: {
            color: theme.palette.text.secondary,
            marginTop: '10px',
            marginRight: '8px',
        },
        searchText: {
            flex: '0.8 0',
        },
        clearIcon: {
            '&:hover': {
                color: theme.palette.error.main,
            },
        },
    }),
);

export const MUITableSearch: React.FC = () => {
    const classes = useStyles();
    const toggleSearchVisible = useTableStateAction('toggleSearchVisible');
    const updateSearchText = useTableStateAction('searchTextUpdate');
    const translations = useTranslationOptionsContext();
    React.useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Esc' || event.key === 'Escape') {
                toggleSearchVisible();
            }
        };
        document.addEventListener<'keydown'>('keydown', onKeyDown, false);
        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
        };
    }, [toggleSearchVisible]);

    const handleTextChange = _.debounce((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        updateSearchText(event.target.value);
    });
    return (
        <Grow appear in={true} timeout={300}>
            <div className={classes.main}>
                <SearchIcon className={classes.searchIcon} />
                <TextField
                    variant={'standard'}
                    className={classes.searchText}
                    autoFocus={true}
                    InputProps={{
                        'aria-label': translations.toolbar.search,
                    }}
                    onChange={e => {
                        e.persist();
                        handleTextChange(e);
                    }}
                    fullWidth={true}
                />
                <IconButton
                    className={classes.clearIcon}
                    data-testid={'toggleSearchVisible'}
                    onClick={toggleSearchVisible}
                >
                    <ClearIcon />
                </IconButton>
            </div>
        </Grow>
    );
};
