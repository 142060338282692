import type { ColorRampData } from '../ColorRamp/ColorRamp.util';
import { getHeatmapRange } from '../ModelAppearance';
import { HIGHLIGHT_TOOTH_COLOR } from '../ModelViewer/defaultModelColors';
import type { UndercutMaterialUniforms } from '../ModelViewer/materials/UndercutDepthShader';
import type { DesignMeshShaderMaterialUniforms } from '../ModelViewer/materials/designMeshShaderMaterial.utils';
import type { InsertionDepthData, InsertionDepthDataMap } from './FinishingApp.types';
import type { InsertionDepthGenerator } from './InsertionDepthGenerator';
import {
    createHeatmapRestorativeMaterial,
    createScanMaterial,
    createScanStoneMaterial,
    createUndercutRestorativeMaterial,
    createUndercutScanMaterial,
    createUndercutScanStoneMaterial,
    OPAQUE_OPACITY,
    TRANSPARENT_OPACITY,
} from './Materials.utils';
import type { MinimalOperationsManager } from './SceneAppearanceManager.types';
import { type ScanKey, scanKeys, type Range, type ScanData } from './SceneAppearanceManager.types';
import type { ISceneMaterialManager, ScanMaterialOptions } from './SceneMaterialManager.types';
import { HeatMapType } from '@orthly/forceps';
import type { ToothNumber } from '@orthly/items';
import * as THREE from 'three';

const DEFAULT_HEATMAP_RANGE: Range = { min: 0.0, max: 1.0 };

interface RestorativeMaterialsAndUniforms {
    heatmapMaterial: THREE.Material;
    undercutMaterial: THREE.Material;
    undercutMaterialUniforms: UndercutMaterialUniforms;
}

function makeUndercutUniforms(
    depthData: InsertionDepthData,
    colorRamps: ColorRampData | undefined,
): UndercutMaterialUniforms {
    return {
        defaultColor: new THREE.Uniform(new THREE.Color(HIGHLIGHT_TOOTH_COLOR)),
        axisSpaceMatrix: new THREE.Uniform(depthData.axisSpaceMatrix),
        lateralScale: new THREE.Uniform(depthData.lateralScale),
        lateralBuffer: new THREE.Uniform(0.25),
        vMin: new THREE.Uniform(0.0),
        vMax: new THREE.Uniform(0.1),
        depthScale: new THREE.Uniform(depthData.depthScale),
        depthFadeDistance: new THREE.Uniform(0.01),
        depthMapSampleSize: new THREE.Uniform(1),
        disableGating: new THREE.Uniform(false),
        overlayOpacity: new THREE.Uniform(1.0),
        depthMapSize: new THREE.Uniform(depthData.texSize),
        depthMap: new THREE.Uniform(depthData.texture),
        colorRamp: new THREE.Uniform(colorRamps?.greenToRed.texture),
    };
}

function updateUndercutUniforms(depthData: InsertionDepthData, uniforms: UndercutMaterialUniforms): void {
    uniforms.axisSpaceMatrix.value = depthData.axisSpaceMatrix;
    uniforms.lateralScale.value = depthData.lateralScale;
    uniforms.depthScale.value = depthData.depthScale;
    uniforms.depthMapSize.value = depthData.texSize;
    const prevTexture = uniforms.depthMap.value;
    uniforms.depthMap.value = depthData.texture;
    prevTexture.dispose();
}

function createUndercutDepthUniformsAndMaterial(
    toothNumber: ToothNumber,
    depths: InsertionDepthDataMap,
    colorRamps: ColorRampData | undefined,
) {
    const depthData = depths.get(toothNumber);
    if (!depthData) {
        console.warn(`Missing depth information for tooth number ${toothNumber}.`);
        return;
    }

    const uniforms = makeUndercutUniforms(depthData, colorRamps);
    const material = createUndercutRestorativeMaterial(uniforms);
    return { material, uniforms };
}

interface SceneMaterialManagerOptions {
    // If true, update the undercut depth materials when the insertion axis changes.
    readonly adaptInsertionAxis?: boolean;
}

const DEFAULT_SCENE_MATERIAL_MANAGER_OPTIONS: Required<SceneMaterialManagerOptions> = {
    adaptInsertionAxis: false,
};

export class SceneMaterialManager implements ISceneMaterialManager {
    private restorativeMaterialUniforms: DesignMeshShaderMaterialUniforms = {
        activeHeatMap: new THREE.Uniform(-1),
        vMin: new THREE.Uniform(0),
        vMax: new THREE.Uniform(1),
        includeCurtains: new THREE.Uniform(false),
        defaultColor: new THREE.Uniform(new THREE.Color(HIGHLIGHT_TOOTH_COLOR)),
        showSculptMask: new THREE.Uniform(false),
    };

    private activeHeatMapInternal: HeatMapType | undefined;

    private showCurtainsDistanceInternal: boolean = false;

    private restorativeMaterialsAndUniforms: Map<ToothNumber, RestorativeMaterialsAndUniforms> = new Map();

    private heatmapRanges: Map<HeatMapType, Range> = new Map();

    private scanUndercutMaterialsAndUniforms: ScanData<{
        uniforms: UndercutMaterialUniforms;
        stone: THREE.MeshPhongMaterial;
    }> = {};

    private scanHeatmapRangeInternal: Range = { min: 0, max: 0.1 };

    public showSculptMask: boolean = false;

    public showScanUndercut: boolean = false;

    constructor(
        toothNumbers: ToothNumber[],
        private operationsManager: MinimalOperationsManager,
        private insertionDepthGenerator: InsertionDepthGenerator,
        private colorRamps: ColorRampData | undefined,
        options: SceneMaterialManagerOptions = {},
    ) {
        const { adaptInsertionAxis } = {
            ...DEFAULT_SCENE_MATERIAL_MANAGER_OPTIONS,
            ...options,
        };

        const insertionDepths = insertionDepthGenerator.getDepthDataMap();

        toothNumbers.forEach(toothNumber => {
            const matsAndUniforms = createUndercutDepthUniformsAndMaterial(toothNumber, insertionDepths, colorRamps);
            if (matsAndUniforms) {
                this.restorativeMaterialsAndUniforms.set(toothNumber, {
                    heatmapMaterial: createHeatmapRestorativeMaterial(this.restorativeMaterialUniforms),
                    undercutMaterial: matsAndUniforms.material,
                    undercutMaterialUniforms: matsAndUniforms.uniforms,
                });
            }
        });

        scanKeys.forEach(key => {
            const scanDepth = insertionDepths.get(`${key}Jaw`);
            if (!scanDepth) {
                return;
            }
            const uniforms = makeUndercutUniforms(scanDepth, colorRamps);
            const stone = createUndercutScanStoneMaterial(uniforms);
            uniforms.overlayOpacity.value = 0.4;
            uniforms.defaultColor.value = new THREE.Color(0xffffff);
            this.scanUndercutMaterialsAndUniforms[key] = {
                uniforms,
                stone,
            };
        });

        if (adaptInsertionAxis) {
            this.operationsManager.registerInsertionAxisChangedCallback(this.handleInsertionAxisChange.bind(this));
        }
    }

    get activeHeatMap() {
        return this.activeHeatMapInternal;
    }

    set activeHeatMap(value: HeatMapType | undefined) {
        this.activeHeatMapInternal = value;
        this._updateUniforms();
    }

    get heatmapRange(): Range {
        if (this.showScanUndercut) {
            return { ...this.scanHeatmapRangeInternal };
        }

        if (this.activeHeatMap) {
            const range =
                this.heatmapRanges.get(this.activeHeatMap) ?? getHeatmapRange({ activeHeatMap: this.activeHeatMap });
            return { ...range };
        }

        return { ...DEFAULT_HEATMAP_RANGE };
    }

    set heatmapRange(range: Range) {
        let needsUpdate = false;

        if (this.showScanUndercut) {
            this.scanHeatmapRangeInternal = range;
            needsUpdate = true;
        } else if (this.activeHeatMap) {
            this.heatmapRanges.set(this.activeHeatMap, range);
            needsUpdate = true;
        }

        if (needsUpdate) {
            this._updateUniforms();
        }
    }

    get showCurtainsDistance(): boolean {
        return this.showCurtainsDistanceInternal;
    }

    set showCurtainsDistance(value: boolean) {
        this.showCurtainsDistanceInternal = value;
        this._updateUniforms();
    }

    get curtainsHeatMapEnabled(): boolean {
        return this.showCurtainsDistance && this.activeHeatMap === HeatMapType.Proximal;
    }

    getRestorativeMaterial(toothNumber: ToothNumber) {
        const materials = this.restorativeMaterialsAndUniforms.get(toothNumber);
        if (!materials) {
            return;
        }

        return this.activeHeatMap === HeatMapType.Undercut ? materials.undercutMaterial : materials.heatmapMaterial;
    }

    getScanMaterial(key: ScanKey, options: ScanMaterialOptions) {
        const { useColors, transparent, colorMap } = options;

        if (!this.showScanUndercut) {
            return useColors
                ? createScanMaterial(transparent, colorMap)
                : createScanStoneMaterial({
                      transparent,
                      opacity: transparent ? TRANSPARENT_OPACITY : OPAQUE_OPACITY,
                  });
        }

        const materialsAndUniforms = this.scanUndercutMaterialsAndUniforms[key];
        if (!materialsAndUniforms) {
            return;
        }

        return useColors
            ? createUndercutScanMaterial(materialsAndUniforms.uniforms, colorMap)
            : materialsAndUniforms.stone;
    }

    clone(options: SceneMaterialManagerOptions = {}) {
        const models = Array.from(this.restorativeMaterialsAndUniforms.keys());
        return new SceneMaterialManager(
            models,
            this.operationsManager,
            this.insertionDepthGenerator,
            this.colorRamps,
            options,
        );
    }

    setShowSculptMask(showSculptMask: boolean) {
        this.showSculptMask = showSculptMask;
        this._updateUniforms();
    }

    setRestorativeTransparency(toothNumber: ToothNumber, transparent?: boolean): void {
        const material = this.restorativeMaterialsAndUniforms.get(toothNumber)?.heatmapMaterial;
        if (!material) {
            return;
        }

        const nextTransparent = transparent ?? !material.transparent;
        material.transparent = nextTransparent;
        material.opacity = nextTransparent ? TRANSPARENT_OPACITY : OPAQUE_OPACITY;
    }

    setAllRestorativesTransparency(transparent?: boolean): void {
        const nextTransparent =
            transparent ??
            !Array.from(this.restorativeMaterialsAndUniforms.values()).every(el => el.heatmapMaterial.transparent);

        for (const toothNumber of this.restorativeMaterialsAndUniforms.keys()) {
            this.setRestorativeTransparency(toothNumber, nextTransparent);
        }
    }

    private _updateUniforms() {
        if (this.activeHeatMap === HeatMapType.Undercut) {
            const range = this.heatmapRange;
            this.restorativeMaterialsAndUniforms.forEach(({ undercutMaterialUniforms }) => {
                undercutMaterialUniforms.vMin.value = range.min;
                undercutMaterialUniforms.vMax.value = range.max;
            });
        } else {
            const range = this.heatmapRange;
            this.restorativeMaterialUniforms.vMin.value = range.min;
            this.restorativeMaterialUniforms.vMax.value = range.max;
            this.restorativeMaterialUniforms.showSculptMask.value = this.showSculptMask;
            this.restorativeMaterialUniforms.activeHeatMap.value =
                this.activeHeatMap === undefined ? -1 : this.activeHeatMap;
            this.restorativeMaterialUniforms.includeCurtains.value = this.showCurtainsDistance;
        }

        for (const key of scanKeys) {
            const uniforms = this.scanUndercutMaterialsAndUniforms[key]?.uniforms;
            if (uniforms) {
                uniforms.vMin.value = this.scanHeatmapRangeInternal.min;
                uniforms.vMax.value = this.scanHeatmapRangeInternal.max;
            }
        }
    }

    private handleInsertionAxisChange(orientation: THREE.Quaternion): void {
        const insertionDepths = this.insertionDepthGenerator.generate(orientation);

        for (const [key, depthData] of insertionDepths.entries()) {
            if (key === 'upperJaw' || key === 'lowerJaw') {
                const currDepthData =
                    key === 'upperJaw'
                        ? this.scanUndercutMaterialsAndUniforms.upper
                        : this.scanUndercutMaterialsAndUniforms.lower;
                if (!currDepthData) {
                    continue;
                }
                updateUndercutUniforms(depthData, currDepthData.uniforms);
            } else {
                const currDepthData = this.restorativeMaterialsAndUniforms.get(key);
                if (!currDepthData) {
                    continue;
                }
                updateUndercutUniforms(depthData, currDepthData.undercutMaterialUniforms);
            }
        }
    }
}
