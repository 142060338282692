import { ItemEditorV2, ItemEditorV2Utils } from '../../ItemEditorV2';
import type { OrderEditPage } from './types';
import type { ICartItemV2DTOWithId, ICartItemV2Update } from '@orthly/items';
import { CartItemV2Utils, CartItemV2UpdateUtils } from '@orthly/items';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface OrderEditItemPageContentProps {
    itemGroup: ArrayMin1<ICartItemV2DTOWithId>;
    // Callback to update the entire array of editable items.
    setEditableItems: React.Dispatch<React.SetStateAction<ICartItemV2DTOWithId[]>>;
    // whether item being used has passed validation
    hasPassedItemValidation: boolean;
    setHasPassedItemValidation: (hasPassedItemValidation: boolean) => void;
    // doctor preferences id, used by the item editor v2
    doctorPreferencesId: string;
    autoFocusOnInstructions?: boolean;
}

const OrderEditItemPageContent: React.VFC<OrderEditItemPageContentProps> = ({
    itemGroup,
    setEditableItems,
    hasPassedItemValidation,
    setHasPassedItemValidation,
    doctorPreferencesId,
    autoFocusOnInstructions,
}) => {
    const [firstItem] = itemGroup;

    // We run this in an effect and not just on update item to make sure that we also validate an item
    // right after we land on its edit page (prior to any update). This way the validation will be set
    // to false if the item was invalid to start with.
    React.useEffect(() => {
        const isComplete = ItemEditorV2Utils.isItemComplete(firstItem, false);

        // Doing this check to avoid updating state too often if the value actually hasn't changed.
        if (isComplete !== hasPassedItemValidation) {
            setHasPassedItemValidation(isComplete);
        }
    }, [firstItem, hasPassedItemValidation, setHasPassedItemValidation]);

    const updateItem = (update: ICartItemV2Update) => {
        setEditableItems(items => {
            // Important to take the item from `items` instead of using `firstItem` directly as this always holds the latest state
            const editedItem = items.find(i => firstItem.id === i.id);

            if (editedItem) {
                const updatedItem = CartItemV2UpdateUtils.updateItem(editedItem, update);
                const cleanedItem = ItemEditorV2Utils.cleanItem(updatedItem);

                return items.map(item => {
                    if (item.id === cleanedItem.id) {
                        return { ...cleanedItem, id: item.id };
                    } else {
                        return item;
                    }
                });
            } else {
                return items;
            }
        });
    };

    // We set `isInternal` to false all the time as this tool is mostly made to be used by dentists, and
    // for support users using this tool from the POV of a dentist, so we don't want to show the few fields
    // that are allowed when the tool is used "internally"
    return (
        <div data-testid={'order-edit-item-page'}>
            <Grid container>
                <ItemEditorV2
                    key={`editor-item-${firstItem.id}`}
                    item={firstItem}
                    updateItem={updateItem}
                    isInternal={false}
                    showError={true}
                    doctorPreferencesId={doctorPreferencesId}
                    autoFocusOnInstructions={autoFocusOnInstructions}
                />
            </Grid>
        </div>
    );
};

export function getItemPage(props: OrderEditItemPageContentProps): OrderEditPage {
    const { itemGroup } = props;
    const canProceed = ItemEditorV2Utils.isItemComplete(itemGroup[0], false);

    return {
        supertitle: `You are editing this item`,
        title: CartItemV2Utils.getItemGroupDisplayName(itemGroup),
        Content: <OrderEditItemPageContent {...props} />,
        nextIsSkip: false,
        nextIsDisabled: !canProceed,
        type: 'item_edit',
    };
}
