import { MUITableUtils } from '../contexts/MUITableUtils';
import type { TableStateActions } from '../state/TableStateActions';
import type { ColumnFilterState } from '../state/TableStateContext';
import type React from 'react';

export type MUIDataObj = { id: string } & { [k: string]: any };

/////////////////////////// CELLS ROWS ///////////////////////////

export class Cell {
    constructor(
        private row: Row<any>,
        public readonly column: Column<any>,
    ) {
        if (!this.row) {
            this.row = row;
        }
        if (!this.column) {
            this.column = column;
        }
    }
    private _stringValue?: { result?: string };
    public get stringValue(): string | undefined {
        if (this._stringValue) {
            return this._stringValue.result;
        }
        this._stringValue = { result: MUITableUtils.getCellAsString(this.row, this.column) };
        return this._stringValue.result;
    }
    public get exportValue(): string | undefined {
        return MUITableUtils.getCellAsString(this.row, this.column, true);
    }
    public get columnName(): string {
        return this.column.name;
    }
    get renderValue(): React.ReactNode {
        return MUITableUtils.getCellRenderValue(this.row, this.column);
    }
}

// used for filtering and sorting
export class MUITableMetaRow<R extends MUIDataObj = MUIDataObj> {
    constructor(
        public readonly row: Row<R>,
        private columns: Column<R>[],
    ) {
        if (!this.row) {
            this.row = row;
        }
        if (!this.columns) {
            this.columns = columns;
        }
    }
    private cellsForColumn: Record<string, Cell> = {};

    public cellForColumn(column: Column<R>): Cell {
        const existing = this.cellsForColumn[column.name];
        if (existing) {
            return existing;
        }
        const cell = new Cell(this.row, column);
        this.cellsForColumn[column.name] = cell;
        return cell;
    }

    public get cells(): Cell[] {
        return this.columns.map(c => this.cellForColumn(c));
    }
}

export type Row<R extends MUIDataObj = MUIDataObj> = R;

/////////////////////////// COLUMNS ///////////////////////////

export type SummaryFormats = 'float' | 'integer' | 'seconds' | 'hours' | 'minutes';

export interface SummaryRowCell {
    visible: Cell;
    total: Cell;
    columnName: string;
}

export type MUITableFilterType = 'checkbox' | 'dropdown' | 'multiselect' | 'search' | 'date' | 'number';

export interface ColumnFilterOpts {
    exact?: boolean;
    type?: MUITableFilterType;
    defaultOpts?: string[];
    defaultValues?: string[];
    sortFilterList?: boolean;
}

export interface Column<R extends MUIDataObj> {
    // must be unique
    name: string;
    // custom title render
    title?: React.ReactNode;
    type?: 'string' | 'boolean' | 'numeric' | 'date' | 'datetime' | 'time' | 'currency';
    // if string, accessible via lodash.get.
    render: string | ((data: R) => React.ReactNode);
    // accessible via lodash.get
    field?: string | ((data: R) => string | undefined);
    bodyCellWrapStyle?: React.CSSProperties | ((data: R) => React.CSSProperties | undefined);
    customFilterFn?: (filterValues: string[], row: Row<R>) => boolean;
    customSort?: (a: Row<R>, b: Row<R>, direction: 'asc' | 'desc') => number;
    defaultSort?: 'asc' | 'desc';
    getFilterOptions?: (rows: Row<R>[]) => string[];
    hidden?: boolean;
    filter?: boolean;
    filterOptions?: ColumnFilterOpts;
    viewColumns?: boolean;
    sort?: boolean;
    download?: boolean;
    dateFormat?: string;
    disableOnCellClick?: boolean;
    width?: string;
    style?: React.CSSProperties;
}

export interface MUITableCustomBodyProps<R extends MUIDataObj> {
    visibleRows: MUITableMetaRow<R>[];
    visibleColumns: number;
    allRows: MUITableMetaRow<R>[];
}

/////////////////////////// OPTIONS ///////////////////////////
export interface RowOptions<R extends MUIDataObj> {
    rowHover?: boolean;
    showSummaryRow?: boolean;
    selectable?: boolean;
    summaryTop?: boolean;
    setRowProps?: (row: Row<R>, rowIndex: number) => { [k: string]: any };
    setRowStyle?: (row: Row<R>, rowIndex: number) => React.CSSProperties | undefined;
    BodyComponent?: React.ComponentType<MUITableCustomBodyProps<R>>;
}

export interface ToolbarOptions {
    showDates?: boolean;
    startDate?: Date;
    endDate?: Date;
    handleDateChange?: (isStart: boolean) => (value: any) => void;
    startLabel?: string;
    endLabel?: string;
    CustomRight?: React.ComponentType;
    CustomLeft?: React.ComponentType;
    CustomBottom?: React.ComponentType;
    CustomFull?: React.ComponentType;
    hidden?: boolean;
}

export interface EventHookOptions<R extends MUIDataObj> {
    onSearchChange?: (searchText: string) => void;
    onRowSelectionChange?: (currentSelectionIds: string[]) => void;
    onOpenDetailPanelsChange?: (openedRowIds: string[]) => void;
    onFilterChange?: (newState: ColumnFilterState) => void;
    onRowClick?: (row: R, actions: TableStateActions) => void;
    onCellClick?: (row: R, column: Column<R>, actions: TableStateActions) => void;
    onColumnSortChange?: (changedColumnName: string, direction: 'asc' | 'desc' | null) => void;
    onColumnViewChange?: (changedColumnName: string, visible: boolean) => void;
    onChangePage?: (currentPage: number) => void;
    onRowsPerPageChange?: (numberOfRows: number) => void;
}

export interface PaginationOptions {
    disable?: boolean;
    initialRowsPerPage?: number;
    rowsPerPageOptions?: number[];
    customFooter?: React.ComponentType<{
        rowsPerPage: number;
        page: number;
        changeRowsPerPage: (rows: number) => void;
        changePage: (page: number) => void;
        lastPage: number;
    }>;
}

export interface TranslationOptions {
    body: {
        noMatch?: string;
        toolTip?: string;
        summary?: string;
    };
    pagination: {
        next: string;
        previous: string;
        first: string;
        last: string;
        rowsPerPage: string;
        displayRows: string;
    };
    toolbar: {
        search: string;
        downloadCsv: string;
        print: string;
        viewColumns: string;
        filterTable: string;
    };
    filter: {
        all: string;
        title: string;
        reset: string;
    };
    viewColumns: {
        title: string;
        titleAria: string;
    };
    selectedRows: {
        text: string;
        delete: string;
        deleteAria: string;
    };
}

export interface DisplayOptions {
    sort?: boolean;
    filter?: boolean;
    fixedSearch?: boolean;
    fixedSearchPosition?: 'right' | 'left';
    search?: boolean;
    download?: boolean;
    fixedHeader?: boolean;
    viewColumns?: boolean;
    elevation?: number;
    responsive?: 'stacked' | 'scroll';
    filterValues?: boolean;
    // If set to true, all detail panels will be open when table is first rendered.
    detailPanelDefaultOpen?: boolean;
}
