import { useOrderDetailPreferences } from '../../../hooks';
import { OrderDetailRow } from '../../OrderDetails';
import { DOCTOR_PREFERENCE_FIELD_NAMES } from '../Constants';
import { useOrder } from '../OrderState.hooks';
import type { IOrderItemV2DTO } from '@orthly/items';
import { CartItemV2Utils, ItemMaterialFieldV2Utils, OrderItemV2Utils } from '@orthly/items';
import { getMaterialDetails } from '@orthly/shared-types';
import { Box, FlossPalette, Text, Tooltip, InfoIcon, styled } from '@orthly/ui-primitives';
import React from 'react';

const OrderSidebarItemContent: React.VFC<{ item: IOrderItemV2DTO; divider?: boolean }> = props => {
    const { item } = props;
    const firstTooth = CartItemV2Utils.getUniqueUNNs(item)[0];
    const materialDetails = firstTooth
        ? getMaterialDetails({ material: ItemMaterialFieldV2Utils.getSingleMaterial(item) ?? '', unn: firstTooth })
        : undefined;

    return materialDetails ? (
        <OrderDetailRow
            name={'Min thickness'}
            value={`${materialDetails.thickness} mm`}
            variant={'sidebar'}
            wrapperProps={{ alignItems: 'center' }}
            tooltip={
                <Tooltip title={materialDetails.description}>
                    <InfoIcon style={{ fontSize: 16, marginLeft: 2 }} color={'action'} />
                </Tooltip>
            }
        />
    ) : null;
};

interface PreferencesBlockProps {
    showMinimumThickness?: boolean;
    borderTop?: boolean;
}

const PaddedBox = styled(Box)({
    padding: '8px 0',
});

const BorderedBox = styled(PaddedBox)({
    borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
});

export const PreferencesBlock: React.VFC<PreferencesBlockProps> = ({ showMinimumThickness, borderTop }) => {
    const order = useOrder();
    const items = OrderItemV2Utils.parseItems(order.items_v2);
    const preferenceEntries = useOrderDetailPreferences(order.items_v2);

    const Container = borderTop ? BorderedBox : PaddedBox;

    return (
        <Container>
            <Text variant={'body2'} medium={true}>
                Doctor Preferences
            </Text>
            {showMinimumThickness &&
                items.map((item, idx) => (
                    <OrderSidebarItemContent item={item} divider={idx < items.length - 1} key={item.id} />
                ))}
            {preferenceEntries.map(
                e =>
                    DOCTOR_PREFERENCE_FIELD_NAMES.includes(e.name) && (
                        <OrderDetailRow key={e.name} value={e.value} name={e.name} variant={'sidebar'} />
                    ),
            )}
        </Container>
    );
};
