import { useCreditOrRefundFormContext } from './providers/CreditOrRefundFormProvider.graphql';
import { styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const FormContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    padding: '32px 32px',
});

export const CreditOrRefundSubmitted: React.FC = () => {
    const { formState } = useCreditOrRefundFormContext();
    return (
        <FormContainer>
            <Text variant={'body1'} color={'PRIMARY_FOREGROUND'} medium>
                {formState.action === 'credit' ? 'Credit' : 'Refund'} has been applied successfully
            </Text>
            <Text variant={'caption'}>This panel will automatically close in 2 seconds</Text>
        </FormContainer>
    );
};
