import { PracticeInfoRow } from './PracticeInfoRow';
import { useGetSkusForPartnerQuery } from '@orthly/graphql-react';
import { LabsGqlOrderItemSkuType } from '@orthly/graphql-schema';
import { LoadBlocker } from '@orthly/ui';
import { Text, Grid, Icon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const PreviouslyOrderedSKUs: React.FC<{ practiceId: string }> = props => {
    const [hidden, setHidden] = React.useState(true);
    const { practiceId } = props;
    const { data, loading } = useGetSkusForPartnerQuery({
        variables: { partner_id: practiceId },
    });
    const skus = data?.getSkusForPartner?.skus;
    const totalSkus = skus?.length;
    const skusToCount = _.countBy(skus);
    if (!totalSkus || _.isEmpty(skusToCount)) {
        return null;
    }

    const onHideClick = () => {
        setHidden(current => !current);
    };

    return (
        <LoadBlocker blocking={loading}>
            <Grid container style={{ paddingTop: 16 }}>
                <Grid container onClick={onHideClick} style={{ cursor: 'pointer' }}>
                    <Icon icon={hidden ? 'ChevronRight' : 'ExpandMoreIcon'} />
                    <Text variant={'body2'} medium>
                        Previously ordered SKU’s
                    </Text>
                </Grid>
                {!hidden && (
                    <Grid container style={{ marginLeft: 24 }}>
                        {Object.entries(skusToCount).map(([sku, count], idx) => (
                            <PracticeInfoRow
                                key={idx}
                                title={sku === LabsGqlOrderItemSkuType.ImplantBridge ? 'Implant bridge' : sku}
                                value={`${count} (${Math.round((count / totalSkus) * 100)}%)`}
                                halfRow
                            />
                        ))}
                    </Grid>
                )}
            </Grid>
        </LoadBlocker>
    );
};
