import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { DEFAULT_SCAN_APPEARANCE, DEFAULT_RESTORATIVE_APPEARANCE } from '@orthly/dentin';

export const finishingLineRubric: DesignQcRubricCategoryEntry = {
    category: 'finishing_line',
    subcategories: [
        {
            category: 'finishing_line',
            name: 'Placement %',
            grading: {
                type: 'binary',
                bad: '<100%',
                ideal: '100%',
            },
            description: '% of margin line that is correctly placed.',
            edge_case: 'Only acceptable scenario - 90% is ok if 10% is slight overextention.',
            selectable_bad_reasons: ['Margin line has incorrectly-marked sections'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                        colorize: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: false,
                    },
                },
                qc_settings: {
                    showMarginLines: true,
                    showDoctorMarginLines: true,
                },
            },
        },
        {
            category: 'finishing_line',
            name: 'Severity of misplacement',
            grading: {
                type: 'binary',
                bad: 'Severe overextension (more then .05mm) and/or ANY shyness',
                ideal: 'Slight overextension (<.05mm)',
            },
            description: 'Of the misplaced margin line, how much is it off by?',
            edge_case:
                'Worth noting that some margin lines are just not clear, either from poor doctor prep or impressioning. Unclear how we should think about scoring here.',
            selectable_bad_reasons: ['Margin is overextended', 'Margin is shy'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                        colorize: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: false,
                    },
                },
                qc_settings: {
                    showMarginLines: true,
                    showDoctorMarginLines: true,
                },
            },
        },
    ],
    design_standard: {
        googleEmbedStub: `2PACX-1vTQdiaTn3vJuBgLRXXPYr9V75_8_5Tdg77u88amSLPPPA91Eyu4AKvXEL8MWonJSz-GmOGQu6rG16kQ`,
    },
};
