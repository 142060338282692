import { useOrdersListViewIds } from '../../screens/Orders/state/OrdersListViewIdsProvider';
import { useOrdersOverviewState } from '../../screens/Orders/state/OrdersOverview.selectors';
import { RouterUtils } from '../../utils/router/RouterUtils';
import { useCurrentOrderDetailId } from '../../utils/router/useOpenOrderDetail';
import { usePartnerAndLabNames } from '../../utils/usePartnerAndLabNames';
import { ORDER_LIST_ITEM_HEIGHT } from '../OrderListItem/OrderListItemLayout';
import { OrderDetailSidebarItem } from './OrderDetailSidebarItem';
import { ORDER_DETAIL_LIST_ITEM_HEIGHT } from './OrderDetailSidebarItemLayout';
import { OrderDetailSidebarLayout } from './OrderDetailSidebarLayout';
import { IconButton, ChevronLeft, Text } from '@orthly/ui-primitives';
import { OrdersVirtualList, useOrderToOpsListItemContent, useVirtualizedListOrders } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

const OrderDetailSidebarTitle: React.VFC = () => {
    const screenTitle = useOrdersOverviewState(s => s.view?.title ?? _.startCase(s.screen));
    const history = useHistory();
    const ellipsisStyles = { overflow: `hidden`, textOverflow: `ellipsis`, whiteSpace: `nowrap` } as const;
    return (
        <>
            <IconButton
                role={'button'}
                aria-label={'Return to orders'}
                size={'small'}
                onClick={() => history.push(RouterUtils.pathForScreen('orders'))}
            >
                <ChevronLeft />
            </IconButton>
            <Text style={{ marginLeft: 8, flexBasis: 0, flexGrow: 1, ...ellipsisStyles }} variant={'h5'}>
                {screenTitle}
            </Text>
        </>
    );
};

export const OrderDetailSidebar: React.FC = () => {
    const selectedOrderId = useCurrentOrderDetailId();
    const { ids: orderIds, loading } = useOrdersListViewIds();
    const { labNamesById, partnerNamesById } = usePartnerAndLabNames();
    const toListItemContent = useOrderToOpsListItemContent(labNamesById, partnerNamesById);
    const { idsLoading, onItemsRendered, orders, listHeight, ids, startIndex } = useVirtualizedListOrders({
        orderIds,
        itemHeight: ORDER_LIST_ITEM_HEIGHT,
        idsLoading: loading,
        initialFocusedId: selectedOrderId,
        orderToListItemContent: toListItemContent,
    });
    return (
        <OrderDetailSidebarLayout toolbarContent={<OrderDetailSidebarTitle />} position={'left'} loading={idsLoading}>
            <OrdersVirtualList
                listItemHeight={ORDER_DETAIL_LIST_ITEM_HEIGHT}
                ListItem={OrderDetailSidebarItem}
                idsLoading={idsLoading}
                onItemsRendered={onItemsRendered}
                orders={orders}
                listHeight={listHeight}
                ids={ids}
                startIndex={startIndex}
                orderToListItemContent={toListItemContent}
            />
        </OrderDetailSidebarLayout>
    );
};
