import * as PatientUtil from '../../utils/patient.util';
import { PrintableSlipSectionTitle } from '../shared/PrintableSlipSectionTitle';
import type { LabsGqlPatientFragment } from '@orthly/graphql-operations';
import { Text, Grid, makeStyles } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles({
    headerData: {
        fontSize: '1rem !important',
    },
});

interface IPrintableSlipPatientInfoProps {
    patient: Pick<LabsGqlPatientFragment, 'first_name' | 'last_name' | 'birthday' | 'gender'>;
}

/**
 * Renders the name of the patient assigned to an order
 * to be displayed in the Printable Slip
 * @param props
 * @returns Patient information to render onto the Printable Slip
 */
export const PrintableSlipPatientInfo: React.VFC<IPrintableSlipPatientInfoProps> = ({ patient }) => {
    const classes = useStyles();
    const patientName: string = `${patient.first_name} ${patient.last_name}`;
    const patientAgeAndSex: string = `(${PatientUtil.getPatientAge(patient.birthday)} ${PatientUtil.getPatientSexAbbrev(
        patient.gender,
    )})`;
    return (
        <Grid item xs={6}>
            <PrintableSlipSectionTitle text={'Patient'} />
            <Text variant={'h6'} className={classes.headerData}>{`${patientName} ${patientAgeAndSex}`}</Text>
        </Grid>
    );
};
