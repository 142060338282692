import bite_alignment_contact_depth_BAD from '../../../../assets/images/design-qc/bite/bite_alignment_contact_depth_BAD.png';
import bite_alignment_contact_depth_GOOD from '../../../../assets/images/design-qc/bite/bite_alignment_contact_depth_GOOD.png';
import bite_distribution_contact_points_BAD from '../../../../assets/images/design-qc/bite/bite_distribution_contact_points_BAD.png';
import bite_distribution_contact_points_BAD_2 from '../../../../assets/images/design-qc/bite/bite_distribution_contact_points_BAD_2.png';
import bite_distribution_contact_points_GOOD from '../../../../assets/images/design-qc/bite/bite_distribution_contact_points_GOOD.png';
import bite_distribution_contact_points_quad_BAD from '../../../../assets/images/design-qc/bite/bite_distribution_contact_points_quad_BAD.png';
import bite_distribution_contact_points_quad_GOOD from '../../../../assets/images/design-qc/bite/bite_distribution_contact_points_quad_GOOD.png';
import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import {
    DEFAULT_SCAN_APPEARANCE,
    DEFAULT_SCAN_EXPORT_APPEARANCE,
    DEFAULT_TRANSPARENT_OPACITY,
    DEFAULT_RESTORATIVE_APPEARANCE,
} from '@orthly/dentin';

export const biteRubric: DesignQcRubricCategoryEntry = {
    category: 'bite',
    subcategories: [
        {
            category: 'bite',
            name: 'Even distribution of contact points',
            grading: {
                type: 'binary',
                bad: 'Uneven Ant<>Post<br>OR<br>Uneven Quad Contacts',
                ideal: 'Even Ant<>Post<br>AND<br>Even Quad Contacts',
            },
            examples: {
                bad: [
                    bite_distribution_contact_points_BAD,
                    bite_distribution_contact_points_BAD_2,
                    bite_distribution_contact_points_quad_BAD,
                ],
                ideal: [bite_distribution_contact_points_GOOD, bite_distribution_contact_points_quad_GOOD],
            },
            description:
                '<strong>For full arch</strong> - Number of contact points between quadrants should be within +/- 2 points. (also watch for anterior/posterior points)<br><strong>For quads</strong> - Depth and location of contacts should be even ant<>post',
            edge_case:
                'Class 2 or Class 3 malocclusion - Harder, but scorable<br><br>More of a crapshoot:<br>Free floating molars is tough (No opposing)<br>Opposing partial dentures',
            selectable_bad_reasons: [
                'Contact points are uneven between anterior & posterior',
                'Contact points are uneven between left and right Quadrants of the arch',
                'No contact points present',
            ],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'opposing_from_prep',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                        colorize: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    order_scans_bite: {
                        ...DEFAULT_SCAN_EXPORT_APPEARANCE,
                        visible: true,
                        opacity: DEFAULT_TRANSPARENT_OPACITY,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        opacity: DEFAULT_TRANSPARENT_OPACITY,
                    },
                },
                qc_settings: {
                    showCollisions: true,
                },
            },
        },
        {
            category: 'bite',
            name: 'Intersection between scans',
            grading: {
                type: 'binary',
                bad: '> .05mm of intersection',
                ideal: '< .05mm of intersection',
            },
            examples: {
                bad: [bite_alignment_contact_depth_BAD],
                ideal: [bite_alignment_contact_depth_GOOD],
            },
            description: `Depth of intersection must also be consistent across the arch<br><br><strong>Depth of Intersection should be less than .05mm</strong>`,
            edge_case: `Patient's oral health can lead to greater perio flex`,
            selectable_bad_reasons: ['Scans are intersecting by more than .05mm'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'opposing_from_prep',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                        colorize: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    order_scans_bite: {
                        ...DEFAULT_SCAN_EXPORT_APPEARANCE,
                        visible: true,
                        opacity: DEFAULT_TRANSPARENT_OPACITY,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        opacity: DEFAULT_TRANSPARENT_OPACITY,
                    },
                },
                qc_settings: {
                    showCollisions: true,
                },
            },
        },
        {
            category: 'bite',
            name: 'Placement of contact points',
            grading: {
                type: 'tertiary',
                bad: 'Less than 80% of possible working cusps are in the correct placement',
                acceptable: '80% of possible working cusps are in the correct placement',
                ideal: '95% of possible working cusps are in the correct placement',
            },
            description: `Are the working cusps <> fossas placed correctly?`,
            selectable_bad_reasons: ['Less than 80% of possible working cusps are placed correctly'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'opposing_from_prep',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                        colorize: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    order_scans_bite: {
                        ...DEFAULT_SCAN_EXPORT_APPEARANCE,
                        visible: true,
                        opacity: DEFAULT_TRANSPARENT_OPACITY,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        opacity: DEFAULT_TRANSPARENT_OPACITY,
                    },
                },
                qc_settings: {
                    showCollisions: true,
                },
            },
        },
    ],
    design_standard: {
        googleEmbedStub: `2PACX-1vRCbk4ZuzY2uNStY26R7KGXZ9ViTKY10JfGiw-7j32XYhR0XPjuxXbM1SS1a1XIm5MvKL0CXorl70ow`,
    },
};
