import { RouterUtils } from '../../../utils/router/RouterUtils';
import { Grid, Link, Icon, FlossPalette, styled } from '@orthly/ui-primitives';
import React from 'react';

const OrderLinkGrid = styled(Grid)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: FlossPalette.STAR_GRASS,
    '&:hover': { cursor: 'pointer' },
});

const OrderLinkIcon = styled(Icon)({
    maxHeight: '16px',
});

export const OrderLink: React.VFC<{ orderId: string; orderNumber: string }> = ({ orderId, orderNumber }) => {
    if (!orderId || !orderNumber) {
        return null;
    }

    return (
        <Link href={`${RouterUtils.pathForScreen('orders')}/${orderId}`} target={'_blank'}>
            <OrderLinkGrid>
                <Grid>#{orderNumber}</Grid>
                <OrderLinkIcon icon={'OpenInNew'} />
            </OrderLinkGrid>
        </Link>
    );
};
