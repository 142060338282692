import { ErrorToast } from './ErrorToast';
import { FlossPalette, styled } from '@orthly/ui-primitives';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import React from 'react';

export interface StyledSnackbarProviderProps {
    maxSnack?: number;
    autoHideDuration?: number;
    style?: React.CSSProperties;
    anchorOrigin?: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'center' | 'right' };
}

export const StyledMaterialDesignContent = styled(MaterialDesignContent)({
    '&.notistack-MuiContent': {
        borderRadius: '8px !important',
        boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.15) !important',
        padding: '16px 12px !important',
    },
    '&.notistack-MuiContent-success': {
        backgroundColor: `${FlossPalette.WHITE} !important`,
        borderLeft: `4px solid ${FlossPalette.PRIMARY_FOREGROUND} !important`,
        color: `${FlossPalette.BLACK} !important`,
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: `${FlossPalette.ATTENTION} !important`,
        color: `${FlossPalette.WHITE} !important`,
        whiteSpace: 'pre',
    },
    '&.notistack-MuiContent-warning': {
        backgroundColor: `${FlossPalette.WHITE} !important`,
        borderLeft: `4px solid ${FlossPalette.ATTENTION} !important`,
        color: `${FlossPalette.BLACK} !important`,
    },
    '&.notistack-MuiContent-info': {
        backgroundColor: `${FlossPalette.WHITE} !important`,
        color: `${FlossPalette.BLACK} !important`,
    },
});

export const StyledSnackbarProvider: React.FC<StyledSnackbarProviderProps> = ({
    children,
    maxSnack,
    style,
    anchorOrigin,
    autoHideDuration,
}) => {
    return (
        <SnackbarProvider
            Components={{
                success: StyledMaterialDesignContent,
                error: ErrorToast,
                warning: StyledMaterialDesignContent,
                info: StyledMaterialDesignContent,
            }}
            style={style}
            maxSnack={maxSnack}
            autoHideDuration={autoHideDuration}
            anchorOrigin={anchorOrigin}
        >
            {children}
        </SnackbarProvider>
    );
};
