import { DesignTaskDetailTrackerWithSteps } from './DesignTrackerStep';
import { LIST_ITEM_ROOT_STYLE } from './OrderListItemTracker';
import { OrderListItemTrackerUtils } from './OrderListItemTracker.util';
import type { TrackerIconVariant } from './TrackerIcon';
import type { TrackerStepDefinition } from './TrackerStep';
import type {
    LabsGqlOrder,
    LabsGqlWorkflowTask_DesignReviewWorkflowTask_Fragment as DesignReviewWorkflowTask,
    LabsGqlWorkflowTask_ExternalDesignWorkflowTask_Fragment as ExternalDesignWorkflowTask,
    LabsGqlWorkflowTask_InternalDesignWorkflowTask_Fragment as InternalDesignWorkflowTask,
    LabsGqlWorkflowTask_ResolveHoldWorkflowTask_Fragment as ResolveHoldWorkflowTask,
    LabsGqlWorkflowTask_DrReviewHoldWorkflowTask_Fragment as DrReviewHoldWorkflowTask,
    LabsGqlWorkflowTask_ResolveScanRejectionWorkflowTask_Fragment as ResolveScanRejectionWorkflowTask,
    LabsGqlWorkflowTask_ReviewOrderWorkflowTask_Fragment as ReviewOrderWorkflowTask,
    LabsGqlWorkflowTask_ScanReviewWorkflowTask_Fragment as ScanReviewWorkflowTask,
    LabsGqlWorkflowTask_WaxupReviewWorkflowTask_Fragment as WaxupReviewWorkflowTask,
    LabsGqlWorkflowTask_WorkflowTaskGeneric_Fragment as GenericWorkflowTask,
    LabsGqlWorkflowTaskFragment,
    LabsGqlOrderFulfillmentWorkflowFragment,
} from '@orthly/graphql-operations';
import {
    LabsGqlWorkflowTaskType,
    LabsGqlWorkflowStateEnum,
    LabsGqlWorkflowTaskCloseReason,
} from '@orthly/graphql-schema';
import { Text, Grid, Icon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type MinimalOrder = Pick<
    LabsGqlOrder,
    'fulfillment_workflow' | 'created_at' | 'status' | 'delivery_date' | 'workflow_state' | 'ship_status'
>;

type WorkflowDesignTask =
    | DesignReviewWorkflowTask
    | ExternalDesignWorkflowTask
    | InternalDesignWorkflowTask
    | ResolveHoldWorkflowTask
    | DrReviewHoldWorkflowTask
    | ResolveScanRejectionWorkflowTask
    | ReviewOrderWorkflowTask
    | ScanReviewWorkflowTask
    | WaxupReviewWorkflowTask
    | GenericWorkflowTask;

function getLatestTaskOfType(
    workflow: LabsGqlOrderFulfillmentWorkflowFragment,
    types: LabsGqlWorkflowTaskType[],
): WorkflowDesignTask | undefined {
    // We put the active task last as it is, technically speaking, the latest task in the order.
    const tasks: WorkflowDesignTask[] = _.compact([...workflow.closed_tasks, workflow.active_task]);

    return _.findLast(tasks, task => !!task?.type && types.includes(task.type));
}

type GetStepDefinition = (
    order: MinimalOrder,
    activeTask: WorkflowDesignTask,
    // mayBeComplete indicates that the task is allowed to be 'complete' in the UI.
    // This weirdness is due to the way that tasks get "cancelled" when the configuration changes.
    // The latest instance of a task could be "cancelled", even though the timeline shows
    // the task as coming up later because it is still in the workflow.
    mayBeComplete: boolean,
) => TrackerStepDefinition;

// For most design state kinds, we determine the content in the same way:
// Given some task types that are associated with the design state, we find the latest task in the order that is of that type,
// and then find the assignee, completion status, and other information from it.
// This function standardizes that computation across all of those states.
function standardTaskConfig(
    title: string,
    icon: TrackerIconVariant,
    taskTypes: LabsGqlWorkflowTaskType[],
): GetStepDefinition {
    return (order, activeTask, mayBeComplete) => {
        const latestTask = getLatestTaskOfType(order.fulfillment_workflow, taskTypes);

        const closed = mayBeComplete && !!latestTask && !taskTypes.includes(activeTask.type) && !!latestTask.closeout;
        const cancelled = closed && latestTask?.closeout?.reason === LabsGqlWorkflowTaskCloseReason.Cancelled;

        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
        // eslint-disable-next-line no-nested-ternary
        const subtitle = latestTask?.assignee
            ? OrderListItemTrackerUtils.formatAssignee(latestTask.assignee)
            : latestTask && latestTask.closeout && closed
              ? OrderListItemTrackerUtils.formatCloseout(latestTask.closeout)
              : ``;

        return {
            title: cancelled ? `${title} (canceled)` : title,
            icon,
            subtitle,
            complete: closed,
            active: taskTypes.includes(activeTask.type),
        };
    };
}

// Configurations of each potential design state, in order of how they'd appear if all were needed.
const DesignStateConfigs: {
    isVisible: (workflow: LabsGqlOrderFulfillmentWorkflowFragment) => boolean;
    getStepDefinition: GetStepDefinition;
}[] = [
    // Order Placed
    {
        isVisible: () => true,
        getStepDefinition: order => {
            return {
                active: false,
                complete: true,
                icon: 'Order',
                title: 'Order Placed',
                subtitle: OrderListItemTrackerUtils.formatDate(order.created_at),
            };
        },
    },
    // Prep
    {
        isVisible: workflow =>
            workflow.configuration.internal_design_prep_required ||
            workflow.closed_tasks.some(t => t.type === 'DesignPrep'),
        getStepDefinition: standardTaskConfig('Prep', 'Tool', [LabsGqlWorkflowTaskType.DesignPrep]),
    },
    // Automate Design
    {
        isVisible: workflow =>
            workflow.configuration.enable_threeshape_automate ||
            workflow.closed_tasks.some(t => t.type === 'AutomateDesign'),
        getStepDefinition: standardTaskConfig('Automate', 'Cube', [LabsGqlWorkflowTaskType.AutomateDesign]),
    },
    // Internal Design
    {
        isVisible: workflow => !workflow.configuration.skip_finishing_design,
        getStepDefinition: standardTaskConfig('Design', 'Cube', [
            LabsGqlWorkflowTaskType.InternalDesign,
            LabsGqlWorkflowTaskType.AutomateReview,
            LabsGqlWorkflowTaskType.ExternalDesign,
        ]),
    },
    // QC
    {
        isVisible: workflow =>
            workflow.configuration.design_review2_required || workflow.configuration.design_review_required,
        getStepDefinition: standardTaskConfig('QC', 'Badge', [
            LabsGqlWorkflowTaskType.DesignReview,
            LabsGqlWorkflowTaskType.ExternalDesignVerification,
            LabsGqlWorkflowTaskType.DesignReview2,
        ]),
    },
    // Waxup Review
    {
        isVisible: workflow => workflow.configuration.waxup_required,
        getStepDefinition: standardTaskConfig('Design Preview', 'Person', [LabsGqlWorkflowTaskType.WaxupReview]),
    },
    // Models
    {
        isVisible: workflow => workflow.configuration.separate_model_design_step,
        getStepDefinition: standardTaskConfig('Model', 'Check', [LabsGqlWorkflowTaskType.ModelDesign]),
    },
    // Injection Mold
    {
        isVisible: worklow => worklow.configuration.injection_mold_required,
        getStepDefinition: standardTaskConfig('Injection Mold', 'Cube', [LabsGqlWorkflowTaskType.InjectionMoldDesign]),
    },
];

function useDesignTrackerSteps(
    order: MinimalOrder,
    activeTask: WorkflowDesignTask,
    currentTaskSecondaryOverride?: string,
): TrackerStepDefinition[] {
    return React.useMemo<TrackerStepDefinition[]>(() => {
        const visibleEntries = DesignStateConfigs.filter(stage => stage.isVisible(order.fulfillment_workflow));

        // Only allow entries to show as complete if all previous entries are complete.
        let mayBeComplete = true;
        return visibleEntries.map(stage => {
            const result = stage.getStepDefinition(order, activeTask, mayBeComplete);
            mayBeComplete = mayBeComplete && result.complete;
            return {
                ...result,
                secondarySubtitle: result.active ? currentTaskSecondaryOverride : result.secondarySubtitle,
            };
        });
    }, [order, activeTask, currentTaskSecondaryOverride]);
}

interface OrderDetailTrackerProps {
    order: MinimalOrder;
    activeTask: WorkflowDesignTask;
    remainingTimeText?: string;
}

export const DesignTaskDetailTracker: React.FC<OrderDetailTrackerProps> = ({
    order,
    activeTask,
    remainingTimeText,
}) => {
    const steps = useDesignTrackerSteps(order, activeTask, remainingTimeText);
    return <DesignTaskDetailTrackerWithSteps steps={steps} hasIssue={false} />;
};

export interface DesignTaskListItemTrackerProps {
    state: LabsGqlWorkflowStateEnum;
    order: MinimalOrder;
    task: LabsGqlWorkflowTaskFragment;
    assignmentInfo?: string;
    hasIssue: boolean;
    containerStyle?: React.CSSProperties;
}

export const DesignTaskListItemTracker: React.FC<DesignTaskListItemTrackerProps> = ({
    hasIssue,
    state,
    order,
    task,
    containerStyle,
    assignmentInfo,
}) => {
    const steps = useDesignTrackerSteps(order, task, assignmentInfo);

    if (state === LabsGqlWorkflowStateEnum.Cancelled) {
        return (
            <Grid
                container
                style={{ ...LIST_ITEM_ROOT_STYLE, ...containerStyle }}
                wrap={'nowrap'}
                justifyContent={'flex-end'}
            >
                <Icon icon={'BlockIcon'} fontSize={'small'} color={'action'} />
                <Text variant={'body2'} color={'SECONDARY_FOREGROUND'} style={{ fontWeight: 500, marginLeft: 4 }}>
                    Cancelled
                </Text>
            </Grid>
        );
    }
    return <DesignTaskDetailTrackerWithSteps steps={steps} hasIssue={hasIssue} />;
};
