import { useDesignOrderRevisionsLoader } from '../DesignViewer/OrderDesign.hooks.graphql';
import { OrderDesignAnalytics } from '../OrderDesign/OrderDesignAnalytics.graphql';
import { OrderDetailBlock } from '../OrderDetails/OrderDetailBlock';
import { ClickToLoadDesignViewerLiteWrapper } from './ClickToLoadDesignViewerLiteWrapper';
import type { OrderAdminActionDefinition } from './OrderDesignActions.graphql';
import { OrderDesignDownloadAction, OrderDesignTopBar } from './OrderDesignActions.graphql';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { FlossPalette, Button, Grid, Text } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

interface OrderDesignProps {
    order: LabsGqlOrder;
    userRole: 'psr' | 'practitioner' | 'lab';
    clickToLoad?: boolean;
    fullScreen?: boolean;
}

const topbarActions: OrderAdminActionDefinition[] = [
    {
        label: 'Download',
        isVisible: true,
        Component: OrderDesignDownloadAction,
    },
];

export const OrderDesign: React.FC<OrderDesignProps> = props => {
    const { order } = props;

    const { refetch, loadAndSelectDesign, selectedDesignFragment, slimDesignFragments } = useDesignOrderRevisionsLoader(
        order.id,
    );

    const isCurrentDesignLatest = selectedDesignFragment?.source_file_zip_path === order.design_file_path;

    return (
        <>
            <OrderDetailBlock
                key={props.order.id}
                title={'Designs'}
                variant={'full'}
                actions={
                    <OrderDesignTopBar
                        {...props}
                        refetch={refetch}
                        designRevisionFragments={slimDesignFragments}
                        selectedDesignRevisionId={selectedDesignFragment?.id}
                        setSelectedDesignRevisionId={id => {
                            void loadAndSelectDesign(id);
                        }}
                        selectedDesignFragment={selectedDesignFragment ?? undefined}
                        topbarActions={topbarActions}
                    />
                }
            >
                <Grid
                    container
                    direction={'column'}
                    key={`order_design_body_${selectedDesignFragment?.id}`}
                    style={{ minHeight: 325 }}
                >
                    <Grid item>
                        {selectedDesignFragment && (
                            <>
                                <Text
                                    variant={'h6'}
                                    style={{
                                        color: isCurrentDesignLatest ? FlossPalette.BLACK : FlossPalette.ATTENTION,
                                    }}
                                >
                                    {isCurrentDesignLatest ? `Current design` : `Past version`}, uploaded{' '}
                                    {moment(selectedDesignFragment.created_at).fromNow()}
                                </Text>
                                {!isCurrentDesignLatest && (
                                    <Button
                                        variant={'contained'}
                                        style={{ padding: 0, marginTop: 16 }}
                                        onClick={async () => {
                                            await loadAndSelectDesign(undefined);
                                        }}
                                    >
                                        Back to current version
                                    </Button>
                                )}
                            </>
                        )}
                    </Grid>
                    <Grid item container xs style={{ flex: 1, justifyContent: 'center' }}>
                        <ClickToLoadDesignViewerLiteWrapper
                            designFragment={selectedDesignFragment ?? undefined}
                            order={order}
                            refetchDesign={refetch}
                            clickToLoad={props.clickToLoad}
                            clickToLoadType={`design`}
                            wrapperStyle={{ height: '500px', width: '100%' }}
                        />
                    </Grid>
                </Grid>
            </OrderDetailBlock>
            {selectedDesignFragment && (
                <OrderDesignAnalytics orderId={order.id} designFragment={selectedDesignFragment} variant={'standard'} />
            )}
        </>
    );
};
