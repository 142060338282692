import { Icon } from '@orthly/ui';
import { stylesFactory, FlossPalette, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useClasses = stylesFactory(() => ({
    root: { width: 'auto', paddingRight: 8, alignItems: 'center', display: 'flex', justifyContent: 'center' },
    icon: { color: FlossPalette.STAR_GRASS, fontSize: 24 },
}));

export const TicketSavedSearchStartAction: React.VFC<{ isFavorited: boolean }> = ({ isFavorited }) => {
    const classes = useClasses();
    return (
        <Grid className={classes.root}>
            <Icon icon={isFavorited ? 'StarRounded' : 'StarBorderRounded'} className={classes.icon} />
        </Grid>
    );
};
