import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';

const FIRST_COLUMN_WIDTH = `260px`;

export const useRefabFlowOrderDetailsStyles = stylesFactory(theme => ({
    tabContent: {
        backgroundColor: FlossPalette.TAN,
        padding: `16px`,
        marginTop: `16px`,
    },
    preferenceLabel: {
        [theme.breakpoints.up('sm')]: {
            minWidth: FIRST_COLUMN_WIDTH,
            width: FIRST_COLUMN_WIDTH,
        },
        marginRight: `16px`,
    },
    label: {
        marginRight: `16px`,
    },
    orderDetailsSeparator: {
        height: `1px`,
        backgroundColor: FlossPalette.DARK_TAN,
        margin: `8px 0`,
    },
    row: {
        alignItems: `center`,
        minHeight: `30px`,
        marginTop: `12px`,
    },
}));
