import { LabsGqlDesignOrderNoteCategory } from '@orthly/graphql-schema';

export const isPosteriorContourOptionDisabled = (
    option: LabsGqlDesignOrderNoteCategory,
    selectionsContain: (options: LabsGqlDesignOrderNoteCategory[]) => boolean,
): boolean => {
    switch (option) {
        case LabsGqlDesignOrderNoteCategory.BuccalReduceOvercontour:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.BuccalFillOutContour]);

        case LabsGqlDesignOrderNoteCategory.BuccalFillOutContour:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.BuccalReduceOvercontour]);

        case LabsGqlDesignOrderNoteCategory.LingualReduceOvercontour:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.LingualFillOutContour]);

        case LabsGqlDesignOrderNoteCategory.LingualFillOutContour:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.LingualReduceOvercontour]);

        default:
            return false;
    }
};
