import type { BusinessTimeInterval } from './businessTime';
import { getBusinessTimeInterval } from './businessTime';
import { addBusinessDays } from '@orthly/date-fns-business-days';
import type { TatProgressTrackingBlock_FragmentFragment } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { LEHI_TIMEZONE } from '@orthly/shared-types';
import moment from 'moment-timezone';

export type StageStatus = 'complete' | 'ongoing' | 'upcoming';

export function getRowData(
    order: LabsGqlLabOrderFragment,
    data: readonly TatProgressTrackingBlock_FragmentFragment[],
    userTimeZone: string,
) {
    // Do all the business-day computations in Lehi's time zone, but display the dates in the user's time zone.
    let nextStageProjectedStart = moment.tz(order.created_at, LEHI_TIMEZONE);
    const rowData = data.map(
        ({ stage_name, sla_days, actual_start_time, actual_end_time, overridden_projected_end_time }) => {
            const hasStarted = !!actual_start_time;
            const isOngoing = hasStarted && !actual_end_time;
            const start = actual_start_time
                ? moment.tz(actual_start_time, LEHI_TIMEZONE)
                : nextStageProjectedStart.clone();
            const stageDeadlineFromSlaDays = moment.tz(addBusinessDays(start.toDate(), sla_days), LEHI_TIMEZONE);
            const stageDeadline = overridden_projected_end_time
                ? moment.tz(overridden_projected_end_time, LEHI_TIMEZONE)
                : stageDeadlineFromSlaDays.clone();
            const end = actual_end_time ? moment.tz(actual_end_time, LEHI_TIMEZONE) : stageDeadline.clone();
            nextStageProjectedStart = end.clone();
            let totalTime: BusinessTimeInterval | null = null;
            let breachTime: BusinessTimeInterval | null = null;
            let stageStatus: StageStatus = 'upcoming';
            if (hasStarted) {
                stageStatus = isOngoing ? 'ongoing' : 'complete';
                const endForTotalTime = isOngoing ? moment.tz(LEHI_TIMEZONE) : end.clone();
                totalTime = getBusinessTimeInterval(endForTotalTime, start);
                if (endForTotalTime.isAfter(stageDeadline)) {
                    breachTime = getBusinessTimeInterval(endForTotalTime, stageDeadline);
                }
            }

            // If we overrode the projected end time, does what the user sees differ from what we would have
            // shown without the override?  For simplicity, only consider the displayed time, not the duration.
            // We only show times to the nearest hour.  If the stage has ended, don't bother if neither version
            // of the stage deadline would have been breached.
            const isEndTimeVisiblyOverridden =
                !stageDeadline.isSame(stageDeadlineFromSlaDays, 'hour') &&
                (stageStatus !== 'complete' || end.isAfter(moment.min(stageDeadlineFromSlaDays, stageDeadline)));

            return {
                stageStatus,
                stage: stage_name.replace(/[A-Z]+/g, ' $&').trim(),
                tat: sla_days,
                start: start.tz(userTimeZone),
                end: end.tz(userTimeZone),
                totalTime,
                breachTime,
                isEndTimeVisiblyOverridden,
                // Give us a semi-stable key for each stage for use as a React key.  Stages can, and eventually will, appear multiple
                // times in the data.
                reactKey: `${stage_name}-${start.valueOf()}`,
            };
        },
    );
    return rowData;
}

export type RowDatum = ReturnType<typeof getRowData>[number] & { orderIsInProgress: boolean };
