import { ItemEditorV2SectionWrapper } from '../../components/ItemEditorV2SectionWrapper';
import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { ItemEditorV2AbutmentPartFields } from './ItemEditorV2AbutmentPartFields';
import { ItemEditorV2ImplantAuthenticField } from './ItemEditorV2ImplantAuthenticField';
import { ItemEditorV2ScanbodyFields } from './ItemEditorV2ScanbodyFields';
import type { LabsGqlImplantTypeLiteFragment } from '@orthly/graphql-operations';
import { CartItemV2Utils } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface ItemEditorV2ImplantPartsSectionProps extends Omit<ItemEditorV2BaseProps, 'showError'> {
    implantSystems: LabsGqlImplantTypeLiteFragment[];
}

export const ItemEditorV2ImplantPartsSection: React.VFC<ItemEditorV2ImplantPartsSectionProps> = ({
    item,
    updateItem,
    implantSystems,
}) => {
    const [implantToScanbodyId, setImplantToScanbodyId] = React.useState<string | null>(null);

    if (!CartItemV2Utils.itemTypeHasImplant(item)) {
        return null;
    }

    return (
        <ItemEditorV2SectionWrapper title={'Implant Part Tiering'}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <ItemEditorV2ImplantAuthenticField item={item} updateItem={updateItem} />
                </Grid>
                <ItemEditorV2ScanbodyFields
                    item={item}
                    updateItem={updateItem}
                    implantSystems={implantSystems}
                    implantToScanbodyId={implantToScanbodyId}
                    setImplantToScanbodyId={setImplantToScanbodyId}
                />
                <ItemEditorV2AbutmentPartFields
                    item={item}
                    updateItem={updateItem}
                    implantToScanbodyId={implantToScanbodyId}
                />
            </Grid>
        </ItemEditorV2SectionWrapper>
    );
};
