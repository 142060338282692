import type { BrushSettings } from '../DesignEditing/SculptingBrush';
import React from 'react';

/**
 * Creates a callback to set one of the BrushSetting properties
 */
export function useSetBrushSetting<T extends keyof BrushSettings>(
    setting: T,
    setBrushSettings: React.Dispatch<React.SetStateAction<BrushSettings>>,
) {
    return React.useCallback(
        (value: BrushSettings[T]) => {
            setBrushSettings(curr => {
                return {
                    ...curr,
                    [setting]: value,
                };
            });
        },
        [setBrushSettings, setting],
    );
}
