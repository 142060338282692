import type { LabsGqlDesignStaffDtoFragment } from '@orthly/graphql-operations';
import { usePatchDesignStaffRosterInfoMutation } from '@orthly/graphql-react';
import { omitDeep } from '@orthly/runtime-utils';
import { useRootActionCommand } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

type FormState = Pick<
    LabsGqlDesignStaffDtoFragment,
    | 'country'
    | 'design_staff_qc_percentage_required'
    | 'design_staff_region'
    | 'design_staff_role'
    | 'ordered_main_capabilities'
    | 'employment_type'
    | 'schedule'
> & {
    user_id: string;
    design_staff_team_id: string | null;
};

const EMPTY_SCHEDULE = {
    timezone: null,
    mon: { start: null, end: null },
    tue: { start: null, end: null },
    wed: { start: null, end: null },
    thu: { start: null, end: null },
    fri: { start: null, end: null },
    sat: { start: null, end: null },
    sun: { start: null, end: null },
};

export function useRosterInfoFormState(
    designerStaff: LabsGqlDesignStaffDtoFragment | undefined,
    refetch: () => Promise<any>,
): {
    formState: FormState;
    setFormState: (formState: FormState) => void;
    hasEdited: boolean;
    submit: (args: FormState) => Promise<void>;
    submitting: boolean;
} {
    const {
        id,
        country = null,
        design_staff_qc_percentage_required,
        design_staff_region = null,
        design_staff_role = null,
        design_staff_team = null,
        ordered_main_capabilities = null,
        employment_type = null,
        schedule = EMPTY_SCHEDULE,
    } = designerStaff ?? {};

    const { submit, submitting } = useRootActionCommand(usePatchDesignStaffRosterInfoMutation(), {
        successMessage: 'Design Staff Updated!',
        onSuccess: () => {
            void refetch();
        },
    });

    const state = React.useMemo<FormState>(
        () => ({
            user_id: id ?? '',
            country,
            design_staff_qc_percentage_required: design_staff_qc_percentage_required ?? 100,
            design_staff_region,
            design_staff_role,
            design_staff_team_id: design_staff_team?.id ?? null,
            ordered_main_capabilities,
            employment_type,
            schedule: omitDeep(schedule, '__typename'),
        }),
        [
            id,
            country,
            design_staff_qc_percentage_required,
            design_staff_region,
            design_staff_role,
            design_staff_team,
            ordered_main_capabilities,
            employment_type,
            schedule,
        ],
    );

    const starterState = React.useRef(state);
    const [formState, setFormStateInternal] = React.useState<FormState>(state);
    const [hasEdited, setHasEdited] = React.useState(false);

    React.useEffect(() => {
        setFormStateInternal(state);
    }, [state]);

    const setFormState = (changingFormState: FormState) => {
        setFormStateInternal(changingFormState);
        setHasEdited(!_.isEqual(changingFormState, starterState.current));
    };

    return {
        formState,
        setFormState,
        hasEdited,
        submit: async (args: FormState) => {
            await submit({ data: args });
        },
        submitting,
    };
}
