import { useQuery } from '@apollo/client';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';

export const TryInFeedbackDetailForOrder_Fragment = graphql(`
    fragment TryInFeedbackDetailForOrder_Fragment on TryInFeedbackDetailDTO {
        item_id
        arch
        category
        details
        item_display_name
    }
`);

export const TryInFeedbackForOrder_Fragment = graphql(`
    fragment TryInFeedbackForOrder_Fragment on TryInFeedbackDTO {
        order_id
        updated_at
        try_in_feedback_details {
            ...TryInFeedbackDetailForOrder_Fragment
        }
    }
`);

const TryInFeedbackForOrder_Query = graphql(`
    query TryInFeedbackForOrder_Query($orderId: String!) {
        getTryInFeedbackForOrder(order_id: $orderId) {
            ...TryInFeedbackForOrder_Fragment
        }
    }
`);

export function useTryInFeedbackForOrder(orderId: string) {
    const { data } = useQuery(TryInFeedbackForOrder_Query, {
        variables: { orderId },
    });
    return data?.getTryInFeedbackForOrder
        ? getFragmentData(TryInFeedbackForOrder_Fragment, data.getTryInFeedbackForOrder)
        : null;
}
