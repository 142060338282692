import { FlossPalette, HelpIcon } from '@orthly/ui-primitives';
import React from 'react';

export const useHelpIconWithAnchorEl = () => {
    const [helpAnchorEl, setHelpAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleRef = (node: HTMLElement | null) => {
        if (node && !helpAnchorEl) {
            setHelpAnchorEl(node);
        }
    };

    const helpIconComponent = (
        <span ref={handleRef}>
            <HelpIcon data-test={'help-icon'} style={{ color: FlossPalette.GRAY }} />
        </span>
    );

    return { helpIconComponent, helpAnchorEl };
};
