import type { LabsGqlOrderAutomationFragment } from '@orthly/graphql-operations';
import { useSetOrderAutomationFolderMutation } from '@orthly/graphql-react';
import { useChangeSubmissionFn, SimpleAutocomplete, RootActionDialog } from '@orthly/ui';
import { Button, Grid, IconButton, Tooltip, FolderIcon } from '@orthly/ui-primitives';
import React from 'react';

interface AutomationFolderPickerProps {
    currentFolders: string[];
    automation: LabsGqlOrderAutomationFragment;
    refetch: () => Promise<void>;
}

export const AutomationFolderPicker: React.FC<AutomationFolderPickerProps> = props => {
    const { currentFolders, automation } = props;
    const [submitMtn] = useSetOrderAutomationFolderMutation();
    const [folder, setFolder] = React.useState<string | null>(automation.folder);
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<any, []>(
        () => submitMtn({ variables: { folder, id: automation.id } }),
        {
            closeOnComplete: true,
            successMessage: () => ['Folder set!', {}],
            onSuccess: async () => {
                await props.refetch();
            },
        },
    );
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Change Folder'}
            content={
                <Grid container>
                    <SimpleAutocomplete
                        freeSolo
                        AutocompleteProps={{ style: { width: '100%' } }}
                        initialInputValue={folder}
                        // account for empty string
                        onInputChange={value => setFolder(value || null)}
                        onChange={setFolder}
                        label={'Folder'}
                        options={currentFolders.map(value => ({ value }))}
                    />
                    <Grid container justifyContent={'flex-end'} style={{ paddingTop: 8 }}>
                        <Button disabled={folder === automation.folder} onClick={() => submit()} variant={'primary'}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            }
            buttonText={'Change Folder'}
            CustomButton={() => (
                <Tooltip title={'Change Folder'}>
                    <IconButton onClick={() => setOpen(true)}>
                        <FolderIcon />
                    </IconButton>
                </Tooltip>
            )}
        />
    );
};
