import { LabsGqlTaggableEntityType } from '@orthly/graphql-schema';
import { OrderDetailFactRow, TagsAutocomplete } from '@orthly/veneer';
import React from 'react';

interface OrderDetailSummaryTagsProps {
    salesOrderId: string;
}

export const OrderDetailSummaryTags: React.VFC<OrderDetailSummaryTagsProps> = ({ salesOrderId }) => {
    return (
        <OrderDetailFactRow
            title={'Tags'}
            value={
                <TagsAutocomplete
                    rootStyle={{ height: '12px', padding: 'none', margin: 'none', display: 'inline' }}
                    entityId={salesOrderId}
                    entityType={LabsGqlTaggableEntityType.Order}
                />
            }
            valueStyle={{ width: '100%' }}
            wrapperProps={{ style: { alignItems: 'center' } }}
        />
    );
};
