import { useActionTypes } from '../../../../../../utils/useTicketActionHooks';
import { GetOrderTicketsDocument, useApolloRefetch } from '@orthly/graphql-react';
import type { LabsGqlTicketDto } from '@orthly/graphql-schema';
import { createStyles, makeStyles } from '@orthly/ui-primitives';

export function useOrderTicketsRefetch(order_id: string) {
    const refetch = useApolloRefetch();
    return () => refetch.query(GetOrderTicketsDocument, { order_id });
}

export const useTicketStyles = makeStyles(() =>
    createStyles({
        ticketBlock: {
            backgroundColor: 'white',
            padding: 8,
            borderRadius: 6,

            '&:not(:first-child)': {
                marginTop: 16,
            },

            '& .dnd-orderAction:not(:first-child)': {
                marginTop: 8,
            },
        },
    }),
);

export const useActiveTickets = (tickets: LabsGqlTicketDto[] | undefined) => {
    const { resolveActionTypeName } = useActionTypes();
    if (!tickets) {
        return { canUploadInjectionMoldFiles: false };
    }
    const openTickets = tickets.filter(ticket => ticket.is_open);
    const activeActionNames = openTickets.map(
        ticket => ticket.active_action?.type_id && resolveActionTypeName(ticket.active_action?.type_id),
    );
    return {
        canUploadInjectionMoldFiles: activeActionNames.includes('Mold Design Needed'),
    };
};
