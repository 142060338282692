import { FileUploaderSingle } from '../FirebaseUpload/FileUploader';
import type { SetUrlVars, FinalizePlanVars, TreatmentPlanFormData } from './AlignerViewer.types';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useFinalizeTreatmentPlanRequestMutation, useSetTreatmentPlanRequestUrlMutation } from '@orthly/graphql-react';
import { LabsGqlDesignOrderAlignerTreatmentPlanSource } from '@orthly/graphql-schema';
import { getFullStoragePath, DesignStorageConfigs } from '@orthly/shared-types';
import { SimpleTextField, useChangeSubmissionFn, useInterval, OrthlyBrowserConfig } from '@orthly/ui';
import { Text, Grid, Button } from '@orthly/ui-primitives';
import React from 'react';

const useTreatmentPlanSubmitters = () => {
    const [submitUrlMtn] = useSetTreatmentPlanRequestUrlMutation();
    const { submit: submitPlanURL } = useChangeSubmissionFn<any, [SetUrlVars]>(
        (variables: SetUrlVars) => submitUrlMtn({ variables }),
        {
            closeOnComplete: true,
            successMessage: () => ['Treatment Plan URL Submitted', {}],
        },
    );

    const [submitPlanMtn] = useFinalizeTreatmentPlanRequestMutation();
    const { submit: submitPlan } = useChangeSubmissionFn<any, [FinalizePlanVars]>(
        (data: FinalizePlanVars) => submitPlanMtn({ variables: { data } }),
        {
            closeOnComplete: true,
            successMessage: () => ['Treatment Plan Submitted', {}],
        },
    );

    return { submitPlanURL, submitPlan };
};

export type UrlState = 'editing' | 'processing' | 'processed';

export const AlignerTreatmentPlanFileUpload: React.FC<{
    orderId: string;
    onFileUploaded: (path: string) => Promise<void>;
}> = props => {
    const { orderId, onFileUploaded } = props;

    const storagePathConfig = getFullStoragePath(
        OrthlyBrowserConfig.env,
        DesignStorageConfigs.alignerTreatmentPlans,
        orderId,
        'treatment-plans',
        'uploads',
    );

    return (
        <FileUploaderSingle
            storagePathConfig={storagePathConfig}
            fileFields={[{ fieldKey: 'treatmentPlan' }]}
            elevation={0}
            submitButtonText={'Upload Treatment Plan'}
            onComplete={results => {
                // Shouldn't ever happen, but added for safety
                if (!results[0]) {
                    window.alert('No treatment plan files selected');
                    return;
                }

                void onFileUploaded(results[0].uploadedPath);
            }}
        />
    );
};

const ConversionComplete: React.VFC<{ setOpen?: (open: boolean) => void }> = props => {
    const { setOpen } = props;

    return (
        <Grid container direction={'column'} alignItems={'center'} spacing={4} style={{ padding: 8 }}>
            <Grid item>
                <Text variant={`body1`}>Conversion complete!</Text>
            </Grid>
            <Grid item>
                <Button variant={`primary`} onClick={() => setOpen && setOpen(false)}>
                    Close
                </Button>
            </Grid>
        </Grid>
    );
};

export interface AlignerTreatmentPlanFileSubmissionProps {
    urlState: UrlState;
    setUrlState: (state: UrlState) => void;
    data: TreatmentPlanFormData;
    orderId: string;
    updateField: <T extends keyof TreatmentPlanFormData>(field: T, value: TreatmentPlanFormData[T]) => void;
    setOpen?: (open: boolean) => void;
}

const VeneerAlignerTreatmentPlanSubmission_Query = graphql(`
    query VeneerAlignerTreatmentPlanSubmission_Query($lab_order_id: String!) {
        getLatestDoctorVisibleDesignOrderAlignerTreatmentPlan(labOrderId: $lab_order_id) {
            manufacturing_files {
                converted_gcs_path
            }
            source
        }
    }
`);

export const AlignerTreatmentPlanFileSubmission: React.FC<AlignerTreatmentPlanFileSubmissionProps> = props => {
    const { urlState, setUrlState, data, orderId, updateField, setOpen } = props;

    const { submitPlanURL } = useTreatmentPlanSubmitters();

    // This input type controls whether we are in a file selector, url selector, or neither (selection screen).
    const [inputType, setInputType] = React.useState<'file' | 'url' | undefined>(data.url ? 'url' : undefined);

    const { data: treatmentPlanData, refetch } = useQuery(VeneerAlignerTreatmentPlanSubmission_Query, {
        variables: {
            lab_order_id: orderId,
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const treatmentPlan = treatmentPlanData?.getLatestDoctorVisibleDesignOrderAlignerTreatmentPlan;

    // The processing of the manufacturing files happens in a background job within root canal.
    // We cannot await the submission because this process takes ~3-5 minutes.
    // Thus, we have to re-poll and wait until we see the manufacturing files existing, to know that we're finished.
    // It's sad, but we're stuck here because I made bad decisions 3 years ago.
    useInterval(
        React.useCallback(async () => {
            // We weren't waiting for a processing event to happen, or we got nothing back.
            if (urlState !== 'processing') {
                return;
            }

            // Repoll the treatment plan
            await refetch();

            // We refetched and the treatment plan still isn't defined
            if (!treatmentPlan) {
                return;
            }

            // Manufacturing files exist -> conversion must be complete.
            if (treatmentPlan.manufacturing_files.converted_gcs_path) {
                setUrlState('processed');
            }
        }, [treatmentPlan, urlState, setUrlState, refetch]),
        5000,
    );

    // Resets the state of the url editor back to its undefined state.
    const reset = () => {
        setUrlState('editing');
        updateField('url', undefined);
    };

    if (!treatmentPlan) {
        return null;
    }

    // Should never happen, but for safety.
    if (treatmentPlan.source !== LabsGqlDesignOrderAlignerTreatmentPlanSource.SoftSmile) {
        return <strong>ArchForm treatment plan uploads have been deprecated.</strong>;
    }

    // Show file | url selector
    if (!inputType) {
        return (
            <Grid item container direction={'row'} spacing={1}>
                <Grid item xs={6}>
                    <Button
                        fullWidth={true}
                        variant={'primary'}
                        onClick={() => {
                            reset();
                            setInputType('file');
                        }}
                    >
                        Upload a File
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        fullWidth={true}
                        variant={'primary'}
                        onClick={() => {
                            reset();
                            setInputType('url');
                        }}
                    >
                        Enter a URL
                    </Button>
                </Grid>
            </Grid>
        );
    }

    return (
        <>
            {/* URL */}
            <Grid item>
                <Button
                    fullWidth={false}
                    variant={'primary'}
                    onClick={() => {
                        reset();
                        setInputType(undefined);
                    }}
                    startIcon={'BackIcon'}
                >
                    Upload a different way
                </Button>
            </Grid>
            {inputType === 'file' ? (
                <AlignerTreatmentPlanFileUpload
                    orderId={orderId}
                    onFileUploaded={async path => {
                        updateField('url', path);
                        updateField('steps', []);

                        setUrlState('processing');
                        setInputType('url');

                        await submitPlanURL({ order_id: orderId, url: path });
                    }}
                />
            ) : (
                <Grid container item direction={'row'} spacing={1}>
                    <Grid item xs={9}>
                        <SimpleTextField
                            TextFieldProps={{ multiline: false, style: { fontWeight: 'normal' } }}
                            value={data.url}
                            onChange={url => {
                                updateField('url', url);
                                updateField('steps', []);
                            }}
                            disabled={urlState !== 'editing'}
                            label={'Treatment Plan URL'}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            fullWidth
                            variant={'secondary'}
                            style={{ height: '100%' }}
                            onClick={async () => {
                                if (urlState === 'editing') {
                                    if (!data.url) {
                                        return;
                                    }
                                    // Trigger the URL processing action
                                    await submitPlanURL({ order_id: orderId, url: data.url });
                                }
                                setUrlState(urlState === 'editing' ? 'processing' : 'editing');
                            }}
                        >
                            {urlState === 'editing' ? 'Upload' : 'Edit'}
                        </Button>
                    </Grid>
                </Grid>
            )}

            {/* Steps box */}
            {urlState === 'processing' && (
                <div style={{ margin: 'auto' }}>
                    <Text variant={'body2'}>Processing...</Text>
                </div>
            )}
            {urlState === 'processed' && <ConversionComplete setOpen={setOpen} />}
        </>
    );
};
