import { useViewManager } from '../../AppState.hooks';
import { useSetupCameraViewKeyboardControls } from '../../MainViewControls.hooks';
import { LabeledButton } from './LabeledButton';
import { ToolbarContainer } from './ToolbarContainer';
import { ToolbarLabel } from './ToolbarLabel';
import type { IViewManager } from '@orthly/dentin';
import type { BoxProps } from '@orthly/ui-primitives';
import React from 'react';

interface PatientViewsToolbarProps extends BoxProps {
    viewManager?: IViewManager;
}

export const PatientViewsToolbar: React.VFC<PatientViewsToolbarProps> = ({
    viewManager: viewManagerIn,
    ...boxProps
}) => {
    const defaultViewManager = useViewManager();
    const viewManager = viewManagerIn ?? defaultViewManager;

    useSetupCameraViewKeyboardControls(viewManager);

    return (
        <ToolbarContainer {...boxProps}>
            <ToolbarLabel text={'Patient-views:'} />
            <LabeledButton
                onClick={() => viewManager.setFrontCameraView()}
                tooltip={'Patient front view'}
                label={'Front'}
            />
            <LabeledButton
                onClick={() => viewManager.setLeftCameraView()}
                tooltip={'Patient left view'}
                label={'Left'}
            />
            <LabeledButton
                onClick={() => viewManager.setRightCameraView()}
                tooltip={'Patient right view'}
                label={'Right'}
            />
            <LabeledButton onClick={() => viewManager.setTopCameraView()} tooltip={'Patient top view'} label={'Top'} />
            <LabeledButton
                onClick={() => viewManager.setBottomCameraView()}
                tooltip={'Patient bottom view'}
                label={'Bottom'}
            />
        </ToolbarContainer>
    );
};
