import { usePartnerAndLabNames } from '../../../../../../utils/usePartnerAndLabNames';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { OrderDetailFactRow } from '@orthly/veneer';
import React from 'react';

const AdminOrderDetailSummaryPracticeLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailSummaryPracticeLabSalesOrder_Fragment on LabSalesOrderDTO {
        practice_id
    }
`);

interface OrderDetailSummaryPracticeProps {
    salesOrder: FragmentType<typeof AdminOrderDetailSummaryPracticeLabSalesOrder_Fragment>;
}

export const OrderDetailSummaryPractice: React.VFC<OrderDetailSummaryPracticeProps> = ({ salesOrder }) => {
    const { practice_id } = getFragmentData(AdminOrderDetailSummaryPracticeLabSalesOrder_Fragment, salesOrder);

    const { partnerNamesById } = usePartnerAndLabNames();

    const practiceName = partnerNamesById[practice_id] ?? 'Loading...';

    return <OrderDetailFactRow title={'Practice'} value={practiceName} />;
};
