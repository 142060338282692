import { RuleTableRowActions } from '../../../../components/RuleTable/RuleTableRowActions';
import { useDisableBiasRuleSubmitter, useEnableBiasRuleSubmitter, useUpdateBiasRuleSubmitter } from './BiasRule.hooks';
import { BiasRuleForm, LabFilterRuleForm } from './BiasRuleForm';
import type { LabsGqlBiasRuleFragment } from '@orthly/graphql-operations';
import { LoadBlocker } from '@orthly/ui';
import React from 'react';

type BiasRuleRowActionsProps = { row: LabsGqlBiasRuleFragment; refetch: () => Promise<any>; isFilterRule: boolean };

export const BiasRuleRowActions = ({ row, refetch, isFilterRule }: BiasRuleRowActionsProps) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const { submit: disableRule, submitting: submittingDisable } = useDisableBiasRuleSubmitter({
        onComplete: () => refetch().catch(console.error),
    });
    const { submit: enableRule, submitting: submittingEnable } = useEnableBiasRuleSubmitter({
        onComplete: () => refetch().catch(console.error),
    });
    const { submit: submitUpdate, submitting: submittingUpdate } = useUpdateBiasRuleSubmitter({
        onComplete: async () => {
            await refetch();
            setDialogOpen(false);
        },
    });
    let form;
    if (isFilterRule) {
        form = (
            <LabFilterRuleForm
                initialValues={row}
                onSubmit={({ name, condition_json, veto, score }) =>
                    submitUpdate({ name, condition_json, veto, score, rule_id: row.id })
                }
            />
        );
    } else {
        form = (
            <BiasRuleForm
                initialValues={row}
                onSubmit={({ name, condition_json, veto, score }) =>
                    submitUpdate({ name, condition_json, veto, score, rule_id: row.id })
                }
            />
        );
    }
    return (
        <RuleTableRowActions
            RuleTableRowEnableProps={{
                disabled: row.disabled,
                onClick: () => (row.disabled ? enableRule({ rule_id: row.id }) : disableRule({ rule_id: row.id })),
                submitting: submittingEnable || submittingDisable,
            }}
            RuleTableDialogProps={{
                form: <LoadBlocker blocking={submittingUpdate}>{form}</LoadBlocker>,
                open: dialogOpen,
                setOpen: setDialogOpen,
                title: isFilterRule ? 'Edit Filter Rule' : 'Edit Bias Rule',
            }}
        />
    );
};
