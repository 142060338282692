import type { Point } from '../Util';
import { CANVAS_SCALE, HIT_TEST_MARGIN } from '../Util';
import { RectLikeObject } from './RectLikeObject';
import { getSvgPathFromStroke } from './svgPathFromStroke';
import { getStroke } from 'perfect-freehand';

let boundingBoxCalculator: SVGPathElement | undefined;

function getBoundingBox(svgPath: string): DOMRect {
    // Use SVGGraphicsElement.getBBox() to calculate the bounding box for a path.
    // This requires the path to be part of an SVG element that is in the DOM,
    // so we put a 0x0 hidden <svg> in the DOM and use it as our calculator.

    if (!boundingBoxCalculator) {
        const svgEl = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        boundingBoxCalculator = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        svgEl.style.height = '0px';
        svgEl.style.width = '0px';
        svgEl.style.visibility = 'hidden';
        svgEl.style.position = 'fixed';
        svgEl.appendChild(boundingBoxCalculator);
        document.body.appendChild(svgEl);
    }

    boundingBoxCalculator.setAttribute('d', svgPath);
    return boundingBoxCalculator.getBBox();
}

export interface BrushParams {
    readonly color: string;
    readonly size: number;
}

export class BrushPathObject extends RectLikeObject {
    path: Path2D;
    params: BrushParams;
    boundingBox: DOMRect;

    constructor(params: BrushParams, points: Point[]) {
        super();

        const stroke = getStroke(points, {
            size: params.size,
            thinning: 0,
        }) as Point[];

        const svgPath = getSvgPathFromStroke(stroke);
        this.path = new Path2D(svgPath);
        this.params = params;

        this.boundingBox = getBoundingBox(svgPath);
        this.x = this.boundingBox.x;
        this.y = this.boundingBox.y;
        this.width = this.boundingBox.width;
        this.height = this.boundingBox.height;
    }

    handles() {
        return this.handlesForCorners(true);
    }

    draw(ctx: CanvasRenderingContext2D, hitTestColor?: string) {
        ctx.save();

        // `scale` is the scale at which to draw the path, as compared to its original scale.
        const scale = this.width / this.boundingBox.width;
        // Translation is applied to correct the path's position.
        const xTrans = CANVAS_SCALE * (this.x - this.boundingBox.x * scale);
        const yTrans = CANVAS_SCALE * (this.y - this.boundingBox.y * scale);

        ctx.setTransform(CANVAS_SCALE * scale, 0, 0, CANVAS_SCALE * scale, xTrans, yTrans);
        ctx.fillStyle = hitTestColor ?? this.params.color;
        ctx.fill(this.path);

        if (hitTestColor) {
            // Use a wide stroke to expand the area for hit testing. Use a consistent
            // lineWidth that compensates for scale.
            ctx.strokeStyle = hitTestColor;
            ctx.lineWidth = (CANVAS_SCALE * HIT_TEST_MARGIN) / scale;
            ctx.stroke(this.path);
        }
        ctx.restore();
    }

    reshape(origin: Point, secondCorner: Point) {
        this.x = Math.min(origin[0], secondCorner[0]);
        this.y = Math.min(origin[1], secondCorner[1]);
        this.width = Math.abs(origin[0] - secondCorner[0]);
        this.height = Math.abs(origin[1] - secondCorner[1]);
    }
}
