import abutment_insertion_guide_32 from '../../assets/images/new-skus/abutment_insertion_guide_32_2x.png';
import abutment_insertion_guide_128 from '../../assets/images/new-skus/abutment_insertion_guide_128_2x.png';
import aligners_32 from '../../assets/images/new-skus/aligners_32_2x.png';
import aligners_128 from '../../assets/images/new-skus/aligners_128_2x.png';
import bleaching_tray_32 from '../../assets/images/new-skus/bleaching_tray_32_2x.png';
import bleaching_tray_128 from '../../assets/images/new-skus/bleaching_tray_128_2x.png';
import bridge_32 from '../../assets/images/new-skus/bridge_32_2x.png';
import bridge_128 from '../../assets/images/new-skus/bridge_128_2x.png';
import crown_32 from '../../assets/images/new-skus/crown_32_2x.png';
import crown_128 from '../../assets/images/new-skus/crown_128_2x.png';
import dentures_32 from '../../assets/images/new-skus/dentures_32_2x.png';
import dentures_128 from '../../assets/images/new-skus/dentures_128_2x.png';
import healing_cap_32 from '../../assets/images/new-skus/healing_cap_32_2x.png';
import healing_cap_128 from '../../assets/images/new-skus/healing_cap_128_2x.png';
import implant_32 from '../../assets/images/new-skus/implant_32_2x.png';
import implant_128 from '../../assets/images/new-skus/implant_128_2x.png';
import implant_bridge_32 from '../../assets/images/new-skus/implant_bridge_32_2x.png';
import implant_bridge_128 from '../../assets/images/new-skus/implant_bridge_128_2x.png';
import inlay_32 from '../../assets/images/new-skus/inlay_32_2x.png';
import inlay_128 from '../../assets/images/new-skus/inlay_128_2x.png';
import model_32 from '../../assets/images/new-skus/model_32_2x.png';
import model_128 from '../../assets/images/new-skus/model_128_2x.png';
import nightguard_32 from '../../assets/images/new-skus/nightguard_32_2x.png';
import nightguard_128 from '../../assets/images/new-skus/nightguard_128_2x.png';
import onlay_32 from '../../assets/images/new-skus/onlay_32_2x.png';
import onlay_128 from '../../assets/images/new-skus/onlay_128_2x.png';
import partial_32 from '../../assets/images/new-skus/partial_32_2x.png';
import partial_128 from '../../assets/images/new-skus/partial_128_2x.png';
import physical_waxup_32 from '../../assets/images/new-skus/physical_waxup_32_2x.png';
import physical_waxup_128 from '../../assets/images/new-skus/physical_waxup_128_2x.png';
import retainer_32 from '../../assets/images/new-skus/retainer_32_2x.png';
import retainer_128 from '../../assets/images/new-skus/retainer_128_2x.png';
import sleep_apnea_32 from '../../assets/images/new-skus/sleep_apnea_32_2x.png';
import sleep_apnea_128 from '../../assets/images/new-skus/sleep_apnea_128_2x.png';
import sportsguard_32 from '../../assets/images/new-skus/sportsguard_32_2x.png';
import sportsguard_128 from '../../assets/images/new-skus/sportsguard_128_2x.png';
import surgical_guide_32 from '../../assets/images/new-skus/surgical_guide_tissue_borne_32_2x.png';
import surgical_guide_128 from '../../assets/images/new-skus/surgical_guide_tissue_borne_128_2x.png';
import tmj_32 from '../../assets/images/new-skus/tmj_32_2x.png';
import tmj_128 from '../../assets/images/new-skus/tmj_128_2x.png';
import veneer_32 from '../../assets/images/new-skus/veneer_32_2x.png';
import veneer_128 from '../../assets/images/new-skus/veneer_128_2x.png';
import waxup_32 from '../../assets/images/new-skus/waxup_32_2x.png';
import waxup_128 from '../../assets/images/new-skus/waxup_128_2x.png';
import wellness_scan_32 from '../../assets/images/new-skus/wellness_scan_32_2x.png';
import wellness_scan_128 from '../../assets/images/new-skus/wellness_scan_128_2x.png';
import type { ProductUnitType } from '@orthly/items';

type ProductUnitTypeImageMap = { [K in ProductUnitType]: string };

/**
 * You can add assets here in the proper format by
 * following the instructions in this Loom: https://www.loom.com/share/0cc8abe70c0b44288dff6e1c142fbf06.
 */
const smallAssets: ProductUnitTypeImageMap = {
    'Abutment Insertion Guide': abutment_insertion_guide_32,
    Aligners: aligners_32,
    'Aligner Retainer': aligners_32,
    Bridge: bridge_32,
    'Bleaching Tray': bleaching_tray_32,
    Crown: crown_32,
    Denture: dentures_32,
    HealingCap: healing_cap_32,
    Implant: implant_32,
    ImplantBridge: implant_bridge_32,
    'Implant Model': model_32,
    Inlay: inlay_32,
    Model: model_32,
    'Night Guard': nightguard_32,
    Onlay: onlay_32,
    Partial: partial_32,
    // Not a typo to use the model image here, we want to use the model image for physical wax-ups
    'Physical Waxup': physical_waxup_32,
    'Removable Model': model_32,
    Retainer: retainer_32,
    SleepApnea: sleep_apnea_32,
    Sportsguard: sportsguard_32,
    'Study Model': model_32,
    SurgicalGuide: surgical_guide_32,
    TMJ: tmj_32,
    Unknown: crown_32,
    Veneer: veneer_32,
    Waxup: waxup_32,
    WellnessScan: wellness_scan_32,
};

const largeAssets: ProductUnitTypeImageMap = {
    'Abutment Insertion Guide': abutment_insertion_guide_128,
    Aligners: aligners_128,
    'Aligner Retainer': aligners_128,
    Bridge: bridge_128,
    'Bleaching Tray': bleaching_tray_128,
    Crown: crown_128,
    Denture: dentures_128,
    HealingCap: healing_cap_128,
    Implant: implant_128,
    ImplantBridge: implant_bridge_128,
    'Implant Model': model_128,
    Inlay: inlay_128,
    Model: model_128,
    'Night Guard': nightguard_128,
    Onlay: onlay_128,
    Partial: partial_128,
    // Not a typo to use the model image here, we want to use the model image for physical wax-ups
    'Physical Waxup': physical_waxup_128,
    'Removable Model': model_128,
    Retainer: retainer_128,
    SleepApnea: sleep_apnea_128,
    Sportsguard: sportsguard_128,
    'Study Model': model_128,
    SurgicalGuide: surgical_guide_128,
    TMJ: tmj_128,
    Unknown: crown_128,
    Veneer: veneer_128,
    Waxup: waxup_128,
    WellnessScan: wellness_scan_128,
};

export const ProductUnitTypeImages = {
    Small: smallAssets,
    Large: largeAssets,
};
