import { useFirebasePreviewMulti } from '../../hooks';
import { DandyAttachmentZoomIcon } from '../DandyChat';
import { DandyLightbox } from '../DandyLightbox';
import type { Annotation } from './types';
import { TrashIcon } from '@orthly/ui';
import { Text, FlossPalette, Grid, IconButton, makeStyles } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        rowGap: '24px',
        gap: '48px',
    },
    annotationBlock: {
        display: 'flex',
        flexDirection: 'column',
    },
    imageWrapper: {
        position: 'relative',
        '@media (hover: hover)': {
            '&:hover': {
                background: FlossPalette.STAR_GRASS,
                cursor: 'pointer',
            },
        },
        '&:hover img': { opacity: 0.4 },
        '&:hover svg': { visibility: 'visible' },
        borderRadius: 8,
        border: `1px solid ${FlossPalette.DARK_TAN}`,
        height: 191,
        width: '100%',
        minWidth: '100%',
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: 8,
        objectFit: `cover`,
    },
    caption: {
        marginTop: `8px`,
    },
});

interface AnnotationListProps {
    annotations: Annotation[];
    onRemove?: (index: number, annotation: Annotation) => void;
    canRemove?: boolean;
}

/**
 * This component is used to display a list of annotations.
 *
 * Annotations include an image URL and a comment to display underneath the image.
 *
 * We intend for this AnnotationList to be agnostic of the context in which they're used,
 * so we should be able to use the AnnotationList to display annotations regarding the
 * Waxup Review stage, as well as for Refabs later on or whatever use case comes up.
 */
export const AnnotationList: React.VFC<AnnotationListProps> = ({ annotations, canRemove = false, onRemove }) => {
    const classes = useStyles();
    const [selectedAttachmentPreview, setSelectedAttachmentPreview] = React.useState<string | undefined>(undefined);

    const imagesToFetchFromFirebase = React.useMemo(
        () =>
            annotations.map(({ imageUrl }) => ({
                source: imageUrl,
                // We should not use the annotation comment as the name, as it could be empty
                // and it would lead to Lightbox not working as expected
                name: imageUrl,
            })),
        [annotations],
    );

    const { result, loading } = useFirebasePreviewMulti(imagesToFetchFromFirebase, true);

    return (
        <>
            <Grid container spacing={2} justifyContent={annotations.length > 1 ? 'flex-start' : 'center'}>
                {annotations?.map((annotation, i) => (
                    <Grid
                        item
                        xs={12}
                        md={6}
                        key={`annotation-${annotation.imageUrl}`}
                        className={classes.annotationBlock}
                    >
                        <div
                            className={classes.imageWrapper}
                            onClick={() => setSelectedAttachmentPreview(annotation.imageUrl)}
                        >
                            {result?.[i]?.source && (
                                <>
                                    <img alt={annotation.comment} src={result[i]?.source} className={classes.image} />
                                    <DandyAttachmentZoomIcon />
                                </>
                            )}
                        </div>
                        {canRemove ? (
                            <div style={{ display: `flex`, justifyContent: `space-between` }}>
                                <Text variant={'caption'} className={classes.caption}>
                                    {annotation.comment}
                                </Text>
                                <IconButton size={'small'} onClick={() => onRemove?.(i, annotation)}>
                                    <TrashIcon style={{ color: FlossPalette.ATTENTION }} />
                                </IconButton>
                            </div>
                        ) : (
                            annotation.comment && (
                                <Text variant={'caption'} className={classes.caption}>
                                    {annotation.comment}
                                </Text>
                            )
                        )}
                    </Grid>
                ))}
            </Grid>
            {selectedAttachmentPreview && (
                <DandyLightbox
                    previews={result}
                    loading={loading}
                    setSelectedAttachmentPreview={setSelectedAttachmentPreview}
                    selectedAttachmentPreview={selectedAttachmentPreview}
                />
            )}
        </>
    );
};
