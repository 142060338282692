const eventSort = {
    queued: 1,
    sent: 2,
    processed: 3,
    delivered: 4,
    failed: 5,
    undelivered: 6,
    open: 7,
    dropped: 7,
    deferred: 8,
    bounce: 9,
} as Record<string, number>;

const makeEventString = (events: Array<string>) => {
    if (events.length === 1 && events[0] === 'processed') {
        return 'Processed > (Delivered || Bounced || Deferred) • Opened';
    }
    return events.sort((a, b) => (eventSort[a] ?? Infinity) - (eventSort[b] ?? Infinity)).join(' > ');
};

export const formatEvents = (events: Array<string>) => {
    return makeEventString(events);
};
