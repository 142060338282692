import type { MutationTuple } from '@apollo/client';
import { MUITable } from '@orthly/mui-table';
import type { SessionTestFn } from '@orthly/session-client';
import { SessionGuard, useSession } from '@orthly/session-client';
import { QuickForm, RootActionDialog, LoadBlocker, useRootActionCommand } from '@orthly/ui';
import { FlossPalette, IconButton, Tooltip, EditIcon, ToggleOffIcon, ToggleOnIcon } from '@orthly/ui-primitives';
import type { ReactElement } from 'react';
import React from 'react';

interface Category {
    id: string;
    updated_at: string;
    created_at: string;
    name: string;
    archived: boolean;
}

type NullablePartial<T> = { [P in keyof T]?: T[P] | null };

type UpdateCategoryMutation = MutationTuple<
    any,
    { data: Pick<Category, 'id'> & NullablePartial<Pick<Category, 'archived' | 'name'>> }
>;

interface EditCategoryProps {
    categoryName: string;
    category: Category;
    update: UpdateCategoryMutation;
}

const EditCategoryName: React.VFC<EditCategoryProps> = props => {
    const { submit, submitting, open, setOpen } = useRootActionCommand(props.update, {
        successMessage: () => [`${props.categoryName} category renamed!`, {}],
    });
    return (
        <RootActionDialog
            title={`Edit category name`}
            open={open}
            setOpen={setOpen}
            loading={submitting}
            content={
                <QuickForm<{ name: string }>
                    fields={{
                        name: {
                            type: 'text',
                            label: 'Category Name',
                            helperText: `Note: Historical ${props.categoryName}s are mapped to this category by ID. Do not change the name such that it would change the meaning of those ${props.categoryName}s`,
                        },
                    }}
                    initialValues={{ name: props.category.name }}
                    onSubmit={async result => {
                        void submit({
                            data: {
                                id: props.category.id,
                                name: result.name.trim(),
                            },
                        });
                    }}
                    dirtySubmitOnly={true}
                />
            }
            CustomButton={props => (
                <IconButton onClick={props.onClick} disabled={submitting}>
                    <Tooltip title={`Edit name`}>
                        <EditIcon />
                    </Tooltip>
                </IconButton>
            )}
        />
    );
};

const ToggleArchivedAction: React.VFC<EditCategoryProps> = ({ categoryName, category, update }) => {
    const { submit: submitArchiveMtn, submitting: toggleArchiveLoading } = useRootActionCommand(update, {});
    const toggleArchived = React.useCallback(() => {
        const suffix = category.archived
            ? ''
            : ` This will not change historical ${categoryName}s, but it will prevent new ${categoryName}s from being created with this category.`;
        const prefix = category.archived ? 'Unarchive' : 'Archive';
        const confirmationMsg = `${prefix} ${categoryName} category: ${category.name}?${suffix}`;
        if (window.confirm(confirmationMsg)) {
            void submitArchiveMtn({ data: { archived: !category.archived, id: category.id } });
        }
    }, [categoryName, category, submitArchiveMtn]);
    return (
        <IconButton
            onClick={toggleArchived}
            disabled={toggleArchiveLoading}
            style={{ color: category.archived ? FlossPalette.DARK_TAN : FlossPalette.STAR_GRASS }}
        >
            <LoadBlocker blocking={toggleArchiveLoading} loader={`circular`}>
                <Tooltip title={`${category.archived ? 'Unarchive' : 'Archive'} (click for details / confirmation)`}>
                    {category.archived ? <ToggleOnIcon /> : <ToggleOffIcon />}
                </Tooltip>
            </LoadBlocker>
        </IconButton>
    );
};

const EditCategoryActionsColumn: ({
    categoryName,
    canEdit,
    update,
}: {
    categoryName: string;
    canEdit: SessionTestFn;
    update: UpdateCategoryMutation;
}) => React.VFC<Category> =
    ({ categoryName, canEdit, update }) =>
    category => (
        <SessionGuard test={canEdit}>
            <EditCategoryName categoryName={categoryName} category={category} update={update} />
            <ToggleArchivedAction categoryName={categoryName} category={category} update={update} />
        </SessionGuard>
    );

function useCreateCategory<TCreateResult, TCreateCommand>(
    mutate: MutationTuple<TCreateResult, TCreateCommand>,
    refetch: () => Promise<any>,
) {
    return useRootActionCommand(mutate, {
        onSuccess: () => {
            void refetch();
        },
        successMessage: () => [`Category created!`, {}],
    });
}

interface CategoryConfigProps<TCreateResult> {
    categoryName: string;
    canEdit: SessionTestFn;
    data: Category[];
    loading: boolean;
    refetch: () => Promise<unknown>;
    create: MutationTuple<TCreateResult, { name: string }>;
    update: UpdateCategoryMutation;
}

export const CategoryConfig = <TCreateResult,>({
    categoryName,
    canEdit,
    data,
    loading,
    refetch,
    create,
    update,
}: CategoryConfigProps<TCreateResult>): ReactElement => {
    const {
        open: createOpen,
        setOpen: setCreateOpen,
        submit: submitCreate,
        submitting: createSubmitting,
    } = useCreateCategory(create, refetch);

    const session = useSession();
    const enableEditing = canEdit(session);

    return (
        <>
            <MUITable<Category>
                loading={loading}
                title={`${categoryName} Categories`}
                displayOptions={{ fixedSearch: true, download: true, elevation: 0 }}
                columns={[
                    { name: 'id', hidden: true, render: 'id', filter: false },
                    { name: 'Name', render: 'name' },
                    { name: 'Created', render: 'created_at', type: 'date', filter: false },
                    { name: 'Updated', render: 'updated_at', type: 'date', filter: false },
                    { name: 'Archived', render: 'archived', type: 'boolean' },
                    ...(enableEditing
                        ? [
                              {
                                  name: 'Actions',
                                  render: EditCategoryActionsColumn({
                                      categoryName,
                                      canEdit,
                                      update,
                                  }),
                                  filter: false,
                              },
                          ]
                        : []),
                ]}
                actions={{
                    global: [
                        {
                            icon: 'add',
                            tooltip: 'Create category',
                            position: 'toolbar',
                            onClick: () => setCreateOpen(true),
                            disabled: createSubmitting,
                            hidden: !enableEditing,
                        },
                        {
                            icon: 'refresh',
                            position: 'toolbar',
                            onClick: () => void refetch(),
                            disabled: enableEditing,
                            tooltip: 'Reload',
                        },
                    ],
                }}
                data={data}
                paginationOptions={{ rowsPerPageOptions: [25], initialRowsPerPage: 25 }}
            />
            <SessionGuard test={canEdit}>
                <RootActionDialog
                    loading={loading || createSubmitting}
                    setOpen={setCreateOpen}
                    open={createOpen}
                    hideButton
                    title={`Create ${categoryName} Category`}
                    content={
                        <QuickForm<{ name: string }>
                            fields={{ name: { type: 'text', label: 'Category Name' } }}
                            initialValues={{}}
                            onSubmit={result => submitCreate({ name: result.name.trim() })}
                        />
                    }
                />
            </SessionGuard>
        </>
    );
};
