import type React from 'react';

export type Hotkey = 'impersonate' | 'search' | 'searchStackCode';

function isWindows(): boolean {
    try {
        return window.navigator.platform.indexOf('Win') > -1;
    } catch (_e: any) {
        return false;
    }
}

export class HotkeysManager {
    static keyLetters: { [K in Hotkey]-?: string } = {
        search: 'k',
        searchStackCode: 'j',
        impersonate: 'i',
    };

    static keyCombo(hotkey: Hotkey) {
        const prefix = isWindows() ? 'alt' : 'meta';
        return `${prefix}+${this.keyLetters[hotkey]}`;
    }

    static isHotKey(event: React.KeyboardEvent, hotkey: Hotkey) {
        const letter = this.keyLetters[hotkey];
        return event.key === letter && (isWindows() ? event.altKey : event.metaKey);
    }

    static tooltipText(hotkey: Hotkey) {
        const prefix = isWindows() ? 'alt' : '⌘';
        return `${prefix}+${this.keyLetters[hotkey]}`;
    }
}
