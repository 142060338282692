/* eslint-disable max-lines */
import { useActionCategories, useActionCapabilities } from '../../../../utils/useTicketActionHooks';
import { useChangeEffect } from '@orthly/dentin';
import type { LabsGqlActionTypeDtoFragment } from '@orthly/graphql-operations';
import {
    useCreateActionTypeMutation,
    useGetActionCapabilitiesQuery,
    useGetActionTypesQuery,
    useUpdateActionTypeMutation,
} from '@orthly/graphql-react';
import type {
    LabsGqlActionTimeConstraintConfig,
    LabsGqlActionTimeConstraintCustomConfig,
    LabsGqlActionTimeConstraintCustomDateConfig,
    LabsGqlActionTimeConstraintCustomDateNextEncounterConfig,
    LabsGqlCreateActionTypeCommand,
} from '@orthly/graphql-schema';
import { LabsGqlActionTimeConstraintConfigType, LabsGqlTimeConstraintCustomConfigType } from '@orthly/graphql-schema';
import MUITable from '@orthly/mui-table';
import type { CustomQFComponentProps } from '@orthly/ui';
import { LoadBlocker, QuickForm, RootActionDialog, SimpleCheckbox, useRootActionCommand } from '@orthly/ui';
import {
    createStyles,
    FormControlLabel,
    Grid,
    IconButton,
    makeStyles,
    RadioPrimitive as Radio,
    RadioGroupPrimitive as RadioGroup,
    TextField,
    Tooltip,
    Text,
    EditIcon,
    Icon,
} from '@orthly/ui-primitives';
import React from 'react';
import { z } from 'zod';

const useStyles = makeStyles(() =>
    createStyles({
        embeddedField: {
            '& > div': {
                paddingTop: 0,
            },
        },
    }),
);

function timeToMinutes(time: string): number {
    const [strHours, strMins] = time.split(':');
    const hours = parseInt(strHours ?? '0');
    const mins = parseInt(strMins ?? '0');
    return hours * 60 + mins;
}

function minutesToTime(inMinutes: number): string {
    const hours = Math.floor(inMinutes / 60);
    const minutes = inMinutes - hours * 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}

function isNextEncounterConfig(
    config: LabsGqlActionTimeConstraintCustomDateConfig | null,
): config is LabsGqlActionTimeConstraintCustomDateNextEncounterConfig {
    return config?.type === LabsGqlTimeConstraintCustomConfigType.NextEncounter;
}

function configToState(initialConfig: LabsGqlActionTimeConstraintCustomDateConfig | null) {
    return {
        type: initialConfig?.type ?? '',
        time:
            initialConfig?.type === LabsGqlTimeConstraintCustomConfigType.NextEncounter
                ? minutesToTime(initialConfig.value)
                : '',
        shouldPush: isNextEncounterConfig(initialConfig) && initialConfig.minimum_duration > 0,
        pushMinutes: isNextEncounterConfig(initialConfig) ? initialConfig.minimum_duration : 0,
        duration: initialConfig?.type === LabsGqlTimeConstraintCustomConfigType.Duration ? initialConfig.value : 0,
    };
}

interface TimeConstraintCustomDateConfigProps {
    title: string;
    afterText: string;
    initialConfig: LabsGqlActionTimeConstraintCustomDateConfig | null;
    onChange: (config: LabsGqlActionTimeConstraintCustomDateConfig | null | false) => void;
}

const TimeConstraintCustomDateConfig: React.VFC<TimeConstraintCustomDateConfigProps> = props => {
    const { title, afterText, initialConfig, onChange } = props;
    const styles = useStyles();
    const initialState = React.useMemo(() => configToState(initialConfig), [initialConfig]);
    const [type, setType] = React.useState(initialState.type);
    const [time, setTime] = React.useState(initialState.time);
    const [shouldPush, setShouldPush] = React.useState(initialState.shouldPush);
    const [pushMinutes, setPushMinutes] = React.useState(initialState.pushMinutes);
    const [duration, setDuration] = React.useState(initialState.duration);

    useChangeEffect(() => {
        if (type === 'NextEncounter') {
            const value = timeToMinutes(time);
            if (isNaN(value) || (shouldPush && pushMinutes < 0)) {
                return onChange(false);
            }
            return onChange({
                value,
                type: LabsGqlTimeConstraintCustomConfigType.NextEncounter,
                minimum_duration: shouldPush ? pushMinutes : 0,
            });
        }
        if (type === 'Duration') {
            if (duration < 0) {
                return onChange(false);
            }
            return onChange({
                type: LabsGqlTimeConstraintCustomConfigType.Duration,
                value: duration,
            });
        }
        onChange(null);
    }, [type, time, shouldPush, pushMinutes, duration]);

    return (
        <>
            <Text style={{ fontWeight: 'bold', marginLeft: 4, marginTop: 16 }}>{title}</Text>
            <RadioGroup name={'type'} row={true} value={type} onChange={changed => setType(changed.target.value)}>
                <Grid container>
                    <FormControlLabel value={''} control={<Radio color={'secondary'} />} label={`None`} />
                </Grid>
                <Grid container>
                    <FormControlLabel
                        value={'NextEncounter'}
                        control={<Radio color={'secondary'} />}
                        label={`A specific time on a business day${type === 'NextEncounter' ? ': ' : ''}`}
                    />
                    {type === 'NextEncounter' && (
                        <TextField
                            variant={'standard'}
                            className={styles.embeddedField}
                            type={'time'}
                            value={time}
                            onChange={e => setTime(e.target.value)}
                        />
                    )}
                </Grid>
                {type === 'NextEncounter' && (
                    <Grid container style={{ paddingLeft: 42 }}>
                        <Grid container>
                            <SimpleCheckbox
                                checked={shouldPush}
                                setChecked={setShouldPush}
                                label={'Push to next business day if duration is less than:'}
                            />
                        </Grid>
                        <Grid container alignItems={'center'} style={{ paddingLeft: 42 }}>
                            <TextField
                                variant={'standard'}
                                className={styles.embeddedField}
                                type={'number'}
                                style={{ width: 85 }}
                                value={pushMinutes}
                                onChange={e => setPushMinutes(parseInt(e.target.value || '0'))}
                            />
                            <Text style={{ marginLeft: 8 }}>minutes</Text>
                        </Grid>
                    </Grid>
                )}
                <Grid container>
                    <FormControlLabel
                        value={'Duration'}
                        control={<Radio color={'secondary'} />}
                        label={`A specific duration after ${afterText}`}
                    />
                </Grid>
                {type === 'Duration' && (
                    <Grid container alignItems={'center'} style={{ paddingLeft: 52 }}>
                        <TextField
                            variant={'standard'}
                            className={styles.embeddedField}
                            type={'number'}
                            style={{ width: 85 }}
                            value={duration}
                            onChange={e => setDuration(parseInt(e.target.value))}
                        />
                        <Text style={{ marginLeft: 8 }}>minutes</Text>
                    </Grid>
                )}
            </RadioGroup>
        </>
    );
};

function isCustomConstraintConfig(
    config: LabsGqlActionTimeConstraintConfig | null,
): config is LabsGqlActionTimeConstraintCustomConfig {
    return config?.type === LabsGqlActionTimeConstraintConfigType.Custom;
}

type TimeConstraintConfigState = LabsGqlActionTimeConstraintCustomDateConfig | null | false;

const TimeConstraintConfig: React.VFC<CustomQFComponentProps<{}>> = props => {
    const { form, field } = props;
    const [type, setType] = React.useState(field.value?.type ?? '');
    const [activateConfig, setActivateConfig] = React.useState<TimeConstraintConfigState>(
        field.value?.activate_at ?? null,
    );
    const [dueConfig, setDueConfig] = React.useState<TimeConstraintConfigState>(field.value?.due_at ?? null);

    useChangeEffect(() => {
        if (type === 'DoctorsHours') {
            return form.setFieldValue(field.name, {
                type: 'DoctorsHours',
            });
        }
        if (type === 'Custom') {
            if (activateConfig === false || dueConfig === false) {
                return form.setFieldValue(field.name, false);
            }
            return form.setFieldValue(field.name, {
                type: 'Custom',
                activate_at: activateConfig,
                due_at: dueConfig,
            });
        }
        form.setFieldValue(field.name, null);
    }, [field.name, type, activateConfig, dueConfig]);

    return (
        <>
            <Grid container spacing={1}>
                <RadioGroup
                    name={'timeConstraintType'}
                    row={true}
                    value={type}
                    onChange={e => {
                        setType(e.target.value);
                    }}
                >
                    <FormControlLabel
                        value={''}
                        control={<Radio color={'secondary'} />}
                        label={`Don't enable task activation or due dates`}
                    />
                    <FormControlLabel
                        value={'DoctorsHours'}
                        control={<Radio color={'secondary'} />}
                        label={`Set task activation and due dates to doctor's notification window`}
                    />
                    <FormControlLabel
                        value={'Custom'}
                        control={<Radio color={'secondary'} />}
                        label={`Customize task activation and due dates`}
                    />
                </RadioGroup>

                {type === 'Custom' && (
                    <>
                        <TimeConstraintCustomDateConfig
                            title={'Activate At'}
                            afterText={'action is created'}
                            initialConfig={activateConfig || null}
                            onChange={value => {
                                setActivateConfig(value);
                            }}
                        />
                        <TimeConstraintCustomDateConfig
                            title={'Due At'}
                            afterText={`action is ${activateConfig === null ? 'created' : 'activated'}`}
                            initialConfig={dueConfig || null}
                            onChange={value => {
                                setDueConfig(value);
                            }}
                        />
                    </>
                )}
            </Grid>
        </>
    );
};

interface ActionTypeEditDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    refetch: () => void;
    type: LabsGqlActionTypeDtoFragment | null;
}

interface ActionTypeEditDialogFormData {
    category_id: string;
    subcategory_id: string;
    name: string;
    description: string | null;
    capability_ids: string[];
    time_constraint_config: LabsGqlActionTimeConstraintConfig | null;
}

const ActionTypeEditDialog: React.FC<ActionTypeEditDialogProps> = props => {
    const { type, refetch } = props;
    const [parentId, setParentId] = React.useState('');
    const { categories, subcategories, resolveSubcategoryParentId } = useActionCategories(parentId);
    const { data: capabilitiesData } = useGetActionCapabilitiesQuery();
    const capabilities = React.useMemo(
        () =>
            capabilitiesData?.getActionCapabilities.map(capability => ({
                value: capability.id,
                label: capability.name,
            })) ?? [],
        [capabilitiesData],
    );

    // if we load with a type, we need to set the root category ID to get the subcategories to populate
    React.useEffect(() => {
        type?.category_id && setParentId(resolveSubcategoryParentId(type.category_id ?? '') ?? '');
    }, [type, resolveSubcategoryParentId]);

    const closeAndRefetch = () => {
        refetch();
        props.setOpen(false);
    };

    const { submit: submitCreate, submitting: submittingCreate } = useRootActionCommand(useCreateActionTypeMutation(), {
        successMessage: `Action type created!`,
        onSuccess: closeAndRefetch,
    });
    const { submit: submitUpdate, submitting: submittingUpdate } = useRootActionCommand(useUpdateActionTypeMutation(), {
        successMessage: `Action type updated!`,
        onSuccess: closeAndRefetch,
    });

    return (
        <RootActionDialog
            title={`${type ? 'Edit' : 'Create'} Action Type`}
            open={props.open}
            setOpen={props.setOpen}
            loading={submittingCreate || submittingUpdate}
            CustomButton={() => null}
            content={
                <QuickForm<ActionTypeEditDialogFormData>
                    fields={{
                        category_id: {
                            label: 'Category',
                            type: 'autocomplete',
                            options: categories,
                        },
                        subcategory_id: {
                            label: 'Subcategory',
                            type: 'autocomplete',
                            options: subcategories,
                        },
                        name: { type: 'text' },
                        description: {
                            type: 'text',
                            fieldProps: { multiline: true, minRows: 2, maxRows: 5 },
                            optional: true,
                        },
                        capability_ids: {
                            type: 'multiselect',
                            options: capabilities,
                            label: 'Capabilities',
                            optional: true,
                        },
                        time_constraint_config: {
                            type: 'custom',
                            optional: true,
                            validation: z.any().nullable(),
                            component: TimeConstraintConfig,
                        },
                    }}
                    initialValues={{
                        name: '',
                        description: '',
                        capability_ids: [],
                        time_constraint_config: null,
                        ...type,
                        category_id: parentId,
                        subcategory_id: (type && type.category_id) ?? '',
                    }}
                    onChange={(values, form) => {
                        if (parentId !== values.category_id) {
                            setParentId(values.category_id ?? '');
                            form.setFieldValue('subcategory_id', '');
                        }
                    }}
                    submitButtonTitle={type ? 'Save' : 'Create'}
                    onSubmit={async formResult => {
                        const { subcategory_id, name, description, capability_ids, time_constraint_config } =
                            formResult;
                        const payload: LabsGqlCreateActionTypeCommand = {
                            name,
                            capability_ids,
                            description: description ?? null,
                            category_id: subcategory_id,
                            time_constraint_config_json: JSON.stringify(time_constraint_config),
                        };
                        if (type) {
                            await submitUpdate({
                                data: { ...payload, type_id: type.id, is_archived: false },
                            });
                        } else {
                            await submitCreate({
                                data: payload,
                            });
                        }
                    }}
                />
            }
        />
    );
};

interface ActionTypesListRowActionProps {
    type: LabsGqlActionTypeDtoFragment;
    onEdit: () => void;
    onArchiveChange: () => void;
}

export const ActionTypesListRowAction: React.VFC<ActionTypesListRowActionProps> = props => {
    const { type, onEdit, onArchiveChange } = props;

    const { submit: submitArchive, submitting: submittingArchive } = useRootActionCommand(
        useUpdateActionTypeMutation(),
        {
            successMessage: `Action type ${type.is_archived ? 'restored' : 'archived'}!`,
            onSuccess: () => onArchiveChange(),
        },
    );

    return (
        <>
            <div style={{ display: 'flex' }}>
                <Tooltip title={`Edit`}>
                    <IconButton onClick={onEdit}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={`Delete`}>
                    <LoadBlocker blocking={submittingArchive} loader={`circular`}>
                        <IconButton
                            onClick={() => {
                                const { name, category_id, capability_ids, time_constraint_config } = type;
                                void submitArchive({
                                    data: {
                                        name,
                                        capability_ids,
                                        category_id,
                                        time_constraint_config_json: JSON.stringify(time_constraint_config),
                                        type_id: type.id,
                                        is_archived: !type.is_archived,
                                    },
                                });
                            }}
                        >
                            <Icon icon={type.is_archived ? 'Restore' : 'TrashIcon'} />
                        </IconButton>
                    </LoadBlocker>
                </Tooltip>
            </div>
        </>
    );
};

export const ActionTypesList: React.FC = () => {
    const { data, refetch } = useGetActionTypesQuery();
    const actionTypes = React.useMemo(() => data?.getActionTypes ?? [], [data]);
    const [isEditing, setIsEditing] = React.useState(false);
    const [editTarget, setEditTarget] = React.useState<LabsGqlActionTypeDtoFragment | null>(null);
    const { resolveCategoryDisplayName } = useActionCategories();
    const { capabilityNamesById } = useActionCapabilities();

    const editType = (type: LabsGqlActionTypeDtoFragment | null) => {
        setEditTarget(type);
        setIsEditing(true);
    };

    return (
        <Grid container>
            <MUITable<LabsGqlActionTypeDtoFragment>
                title={''}
                data={actionTypes}
                displayOptions={{
                    fixedSearch: true,
                    elevation: 0,
                    viewColumns: true,
                    filter: true,
                    sort: true,
                }}
                actions={{
                    global: [
                        { icon: `refresh`, position: `toolbar`, onClick: () => refetch()?.catch(console.error) },
                        { icon: `add`, position: `toolbar`, onClick: () => editType(null), tooltip: `Create` },
                    ],
                }}
                columns={[
                    {
                        name: `Category`,
                        render: actionType => resolveCategoryDisplayName(actionType.category_id),
                        filter: false,
                    },
                    { name: `Name`, render: `name`, filter: false },
                    {
                        name: `Description`,
                        render: actionType => <div>{actionType.description ?? '-'}</div>,
                        filter: false,
                        bodyCellWrapStyle: {
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                        },
                    },
                    {
                        name: `Capabilities`,
                        render: actionType => {
                            if (actionType.capability_ids.length === 0) {
                                return '-';
                            }
                            return actionType.capability_ids.map(id => capabilityNamesById[id]).join(', ');
                        },
                        filter: false,
                    },
                    {
                        name: `Time Constraints`,
                        render: actionType => {
                            const config = actionType.time_constraint_config;
                            if (!config) {
                                return '-';
                            }
                            if (config.type === LabsGqlActionTimeConstraintConfigType.DoctorsHours) {
                                return `Doctor's notification hours`;
                            }
                            if (isCustomConstraintConfig(config)) {
                                const dueAfterVerb = config.activate_at ? 'activated' : 'created';
                                return `Activate At: ${explainCustomTimeConfig(
                                    config.activate_at,
                                    'created',
                                )}\nDue At: ${explainCustomTimeConfig(config.due_at, dueAfterVerb)}`;
                            }
                            return '-';
                        },
                        filter: false,
                    },
                    {
                        name: 'Archived',
                        hidden: true,
                        render: 'is_archived',
                        type: 'boolean',
                        filterOptions: { defaultValues: ['false'], exact: false, type: 'dropdown' },
                    },
                    {
                        name: '',
                        render: type => (
                            <ActionTypesListRowAction
                                type={type}
                                onEdit={() => editType(type)}
                                onArchiveChange={refetch}
                            />
                        ),
                        filter: false,
                    },
                ]}
            />
            <ActionTypeEditDialog open={isEditing} setOpen={setIsEditing} type={editTarget} refetch={refetch} />
        </Grid>
    );
};

function explainCustomTimeConfig(
    config: LabsGqlActionTimeConstraintCustomDateConfig | null,
    afterVerb: string,
): string {
    if (config === null) {
        return '-';
    }
    if (config.type === LabsGqlTimeConstraintCustomConfigType.Duration) {
        return `${config.value} minutes after action ${afterVerb}`;
    }
    if (isNextEncounterConfig(config)) {
        const minTimeStatement = config.minimum_duration > 0 ? `, minimum ${config.minimum_duration} mins` : '';
        return `next business ${minutesToTime(config.value)}${minTimeStatement}`;
    }
    return '-';
}
