import { ItemEditorV2DialogField } from '../../../components/ItemEditorV2DialogField';
import type { ItemEditorV2BaseProps } from '../../../types/ItemEditorV2BaseProps';
import { useAutoSetItemDataFieldDefaultValue } from './ItemEditorV2ItemDataField.util';
import { DandyMouthToothSelector } from '@orthly/dentin';
import { ItemDataFieldUtils } from '@orthly/items';
import type { ItemDataFieldId, LabOrderItemSKUType, ToothNumber, ToothSelectMultiItemDataField } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface ItemEditorV2ToothSelectMultiItemDataFieldProps<ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>
    extends ItemEditorV2BaseProps<SKU> {
    field: ToothSelectMultiItemDataField<ID, SKU>;
}

export const ItemEditorV2ToothSelectMultiItemDataField = <ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>({
    field,
    item,
    updateItem,
    showError,
}: ItemEditorV2ToothSelectMultiItemDataFieldProps<ID, SKU>): React.ReactElement => {
    const value = field.getValue(item);

    const applyUpdate = React.useCallback(
        (val: ToothNumber[]) => {
            updateItem(field.getUpdatePayload(_.sortBy(val), item));
        },
        [field, item, updateItem],
    );

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    // if they have no value set, we set it to an empty array
    // this is particularly necessary for cart items that allow the property to be optional
    // but order items that expect it to be required, but allow it to be empty
    React.useEffect(() => {
        if (!value && !field.getDefaultValue?.(item)) {
            applyUpdate([]);
        }
    }, [value, field, item, applyUpdate]);

    return (
        <ItemEditorV2DialogField
            fieldLabel={field.label}
            fieldValue={field.getHumanReadableValue(item) ?? ''}
            modalTitle={field.label}
            modalContent={
                <Grid container justifyContent={'center'}>
                    <Grid item xs={6}>
                        <DandyMouthToothSelector
                            selected={value ?? []}
                            onChange={val => applyUpdate(val)}
                            verticalLayout={true}
                            animate={false}
                            archLabels={true}
                        />
                    </Grid>
                </Grid>
            }
            errorText={
                showError && ItemDataFieldUtils.isItemDataFieldRequired(field, item) && !field.isComplete(item)
                    ? 'This field is required'
                    : undefined
            }
        />
    );
};
