import { usePartnersData } from '../../../../utils/usePartners';
import { downloadAsCsv } from '@orthly/mui-table';
import { SimpleMultiSelect } from '@orthly/ui';
import { Collapse, Grid, Button, Text } from '@orthly/ui-primitives';
import { SimpleDropzone } from '@orthly/veneer';
import { sortBy } from 'lodash';
import React from 'react';

export interface AddSourceImportRowsProps {
    visible: boolean;
    onDropAccepted: (files: File[]) => void;
    onPracticesSelected: (partnerIds: string[]) => void;
}

export const AddSourceImportRows: React.FC<AddSourceImportRowsProps> = ({
    onDropAccepted,
    onPracticesSelected,
    visible,
}) => {
    const { data: { listPracticeNames: practices } = {} } = usePartnersData();
    const [selectedPracticeIds, setSelectedPracticeIds] = React.useState<string[]>([]);

    const options = React.useMemo(() => {
        return sortBy(practices ?? [], p => p.name).map(p => ({ value: p.id, label: p.name }));
    }, [practices]);

    return (
        <Grid container>
            <Grid container justifyContent={'space-between'}>
                <Text variant={'h6'}>Import credits</Text>
                <Button
                    variant={'secondary'}
                    onClick={() =>
                        downloadAsCsv(
                            options.map(({ value: practice_id, label: name }) => ({
                                practice_id,
                                name,
                                order_id: '',
                                invoice_to_apply_date: null,
                                amount_cents: 0,
                                description: '',
                                expiration_date: '',
                                credit_category: '',
                            })),
                            `Credits_Import`,
                            { eol: '\n' },
                        )
                    }
                    startIcon={'DownloadIcon'}
                >
                    Download Template
                </Button>
            </Grid>
            <Collapse in={visible} style={{ width: '100%' }}>
                <Grid container>
                    <Grid container style={{ paddingTop: 8 }}>
                        <SimpleMultiSelect
                            options={options}
                            value={selectedPracticeIds}
                            onChange={value => setSelectedPracticeIds(value ?? [])}
                            label={'Manually select practices'}
                            SelectProps={{
                                onClose: () => {
                                    const practiceIds = [...selectedPracticeIds];
                                    setSelectedPracticeIds([]);
                                    onPracticesSelected(practiceIds);
                                },
                                variant: 'standard',
                            }}
                        />
                    </Grid>
                    <Grid container style={{ maxHeight: 60, paddingTop: 8 }}>
                        <SimpleDropzone
                            preUploadText={'Import Credits From CSV (click or drop file)'}
                            wrapperStyle={{ minHeight: 40, padding: 0 }}
                            options={{ onDropAccepted, multiple: false }}
                        />
                    </Grid>
                </Grid>
            </Collapse>
        </Grid>
    );
};

export default AddSourceImportRows;
