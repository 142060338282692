import { ScanReviewPanel } from '../ScanReviewPanel';
import { ScanReviewShadeMatchingTool } from './ScanReviewShadeMatchingTool';
import { useScanReviewShadeMatchingViewContext } from './ScanReviewShadeMatchingView.hooks';
import type { Jaw } from '@orthly/shared-types';
import React from 'react';

export interface ScanReviewShadeMatchingPanelProps {
    jawType: Jaw | null;
    maxRadiusMm: number;
}

export const ScanReviewShadeMatchingView: React.VFC<ScanReviewShadeMatchingPanelProps> = ({ jawType, maxRadiusMm }) => {
    const ctx = useScanReviewShadeMatchingViewContext();

    if (jawType) {
        ctx.picker.setCurrentJawType(jawType);
    }

    const shadeMatchingTool = jawType ? (
        <ScanReviewShadeMatchingTool
            key={`shade-match_${jawType}`}
            picker={ctx.picker}
            maxRadiusMm={maxRadiusMm}
            onShadePicked={ctx.onShadePicked}
        />
    ) : null;

    return (
        <ScanReviewPanel viewManager={ctx.picker.viewManager} key={`shade-match_${jawType}`}>
            {shadeMatchingTool}
        </ScanReviewPanel>
    );
};
