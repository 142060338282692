import { ORDER_COMPARE_FACT_CLASS_NAME, ORDER_COMPARE_FACT_DIFF_CLASS_NAME } from './classNames';
import { FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

// styles that ensure that we cascade the grid throughout the entire component tree
// the sub-grid automatically aligns the heights of rows across orders
// so that the same content visually appears on the same lines
export const ORDER_COMPARE_SUBGRID_STYLES: React.CSSProperties = {
    display: 'grid',
    gridTemplateRows: 'subgrid',
};

export const OrderCompareSectionDivider = styled('div')({
    width: '100%',
    height: 1,
    backgroundColor: FlossPalette.DARK_TAN,
    margin: '8px 0',
});

/**
 * `rows` must be the exact number of child elements
 */
export const OrderCompareSectionWrapper = styled('div')(({ rows }: { rows: number }) => ({
    ...ORDER_COMPARE_SUBGRID_STYLES,
    gridRow: `span ${rows}`,
    padding: '8px 0',
}));

export const OrderCompareSectionTitle: React.VFC<{ title: string }> = ({ title }) => (
    <Text variant={'h6'} style={{ padding: '4px 16px ' }}>
        {title}
    </Text>
);

// describes whether the layout should be biased towards a larger title or a larger value
type OrderCompareFactRowDisplayBias = 'title' | 'value';

const OrderCompareFactRowWrapper = styled('div')(
    ({ displayBias }: { displayBias: OrderCompareFactRowDisplayBias }) => ({
        display: 'grid',
        gridTemplateColumns: displayBias === 'title' ? '2fr 5fr' : '1fr 1fr',
        columnGap: 4,
        padding: '0 16px',
        '& .MuiInputBase-root': {
            backgroundColor: 'transparent',
        },
        '&:last-child': {
            paddingBottom: 8,
        },
        // used to highlight differences, applied by comparison script
        [`&.${ORDER_COMPARE_FACT_DIFF_CLASS_NAME}`]: {
            backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
        },
    }),
);

interface OrderCompareFactRowProps {
    title: string | null;
    value: React.ReactNode;
    displayBias?: OrderCompareFactRowDisplayBias;
    boldTitle?: boolean;
}

export const OrderCompareFactRow: React.VFC<OrderCompareFactRowProps> = ({
    title,
    value,
    displayBias = 'title',
    boldTitle,
}) => (
    <OrderCompareFactRowWrapper displayBias={displayBias} className={ORDER_COMPARE_FACT_CLASS_NAME}>
        <Text variant={'body2'} component={'span'} color={'GRAY'} medium={boldTitle}>
            {title}
        </Text>
        <Text
            variant={'body2'}
            component={'span'}
            color={'BLACK'}
            style={{ overflow: 'hidden', whiteSpace: 'pre-line' }}
        >
            {value}
        </Text>
    </OrderCompareFactRowWrapper>
);

const OrderCompareFactTitleWrapper = styled('div')({
    padding: '4px 16px 4px',
    // used to highlight differences, applied by comparison script
    [`&.${ORDER_COMPARE_FACT_DIFF_CLASS_NAME}`]: {
        backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
    },
});

interface OrderCompareFactTitleProps {
    title: string | null;
    bold?: boolean;
}

export const OrderCompareFactTitle: React.VFC<OrderCompareFactTitleProps> = ({ title, bold }) => (
    <OrderCompareFactTitleWrapper className={ORDER_COMPARE_FACT_CLASS_NAME}>
        <Text variant={'body2'} color={'BLACK'} style={{ overflow: 'hidden', whiteSpace: 'pre-line' }} medium={bold}>
            {title}
        </Text>
    </OrderCompareFactTitleWrapper>
);
