import type { ICameraControls } from '@orthly/dentin';
import React from 'react';

export type ActiveCamera = 'primary' | 'secondary';

export const useCameraSync = (): {
    setPrimaryControls: (controls: ICameraControls | null) => void;
    setSecondaryControls: (controls: ICameraControls | null) => void;
    setControlsActive: (which: ActiveCamera) => void;
} => {
    const [primaryControls, setPrimaryControls] = React.useState<ICameraControls | null>(null);
    const [secondaryControls, setSecondaryControls] = React.useState<ICameraControls | null>(null);

    const activeCamera = React.useRef<ActiveCamera>('primary');
    const setControlsActive = React.useCallback((which: ActiveCamera) => {
        activeCamera.current = which;
    }, []);

    React.useEffect(() => {
        if (!primaryControls || !secondaryControls) {
            return;
        }

        let frameId: number;
        const animate = () => {
            const sourceControls = activeCamera.current === 'primary' ? primaryControls : secondaryControls;
            const targetControls = activeCamera.current === 'primary' ? secondaryControls : primaryControls;

            if (
                !targetControls.object.matrix.equals(sourceControls.object.matrix) ||
                targetControls.object.zoom !== sourceControls.object.zoom
            ) {
                targetControls.target.copy(sourceControls.target);

                targetControls.object.position.copy(sourceControls.object.position);
                targetControls.object.quaternion.copy(sourceControls.object.quaternion);
                targetControls.object.up.copy(sourceControls.object.up);
                targetControls.object.zoom = sourceControls.object.zoom;

                targetControls.object.updateProjectionMatrix();
            }

            frameId = requestAnimationFrame(animate);
        };

        animate();

        return () => {
            cancelAnimationFrame(frameId);
        };
    }, [primaryControls, secondaryControls]);

    return {
        setPrimaryControls,
        setSecondaryControls,
        setControlsActive,
    };
};
