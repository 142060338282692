import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';

export const generalRubric: DesignQcRubricCategoryEntry = {
    category: 'general',
    subcategories: [
        {
            category: 'general',
            name: 'General Rx/design checks',
            grading: {
                type: 'multi_if_present',
                entries: [
                    { name: `Wrong ID`, description: `Incorrect file name` },
                    { name: `Missing screenshots`, description: `Screenshots required by SOP were not added` },
                    { name: `Missing model`, description: `Model wasn't designed` },
                    {
                        name: `Not adhering to design task alerts or note priority`,
                        description: `Did not read and/or follow design related instructions`,
                    },
                    {
                        name: `Not following wax-up rejection instructions or doctor's feedback`,
                        description: `Did not do what doctor requested`,
                    },
                    { name: `Outdated DMEs`, description: `Did not install latest DMEs` },
                    { name: `Can fall into wrong model`, description: `` },
                    { name: `Failure to flag issues`, description: `` },
                    {
                        name: `Using wrong material, articulator, or design file`,
                        description: `Filled out 3Shape form incorrectly, or used wrong items/scans`,
                    },
                    {
                        name: `Not exporting or uploading the design file correctly`,
                        description: `Designer didn't save the design properly`,
                    },
                    { name: `Double CAD files`, description: `` },
                    { name: `Case was not actually designed`, description: `` },
                ],
            },
            selectable_bad_reasons: [
                `Wrong ID`,
                `Missing screenshots`,
                `Missing model`,
                `Not adhering to design task alerts or note priority`,
                `Not following wax-up rejection instructions or doctor's feedback`,
                `Outdated DMEs`,
                `Can fall into wrong model`,
                `Failure to flag issues`,
                `Using wrong material, articulator, or design file`,
                `Not exporting or uploading the design file correctly`,
                `Double CAD files`,
                `Case was not actually designed`,
            ],
            description: 'Confirm that the design and RX match, and that we are clear of common errors.',
        },
    ],
};
