import type { AlignerTrackerProps, OrderListItemTrackerProps } from './OrderListItemTracker.types';
import type { TrackerStepDefinition } from './TrackerStep';
import { TrackerStep } from './TrackerStep';
import { LabsGqlShipmentSubStatus, LabsGqlWorkflowStateEnum } from '@orthly/graphql-schema';
import { ShippingUtils } from '@orthly/shared-types';
import { Grid } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

// utility to ensure consistency
function formatDate(date: string | Date): string {
    return moment(date).format('MM/DD');
}

function stepIndexForState(state: LabsGqlWorkflowStateEnum): 0 | 1 | 2 | 3 | 4 | 5 {
    switch (state) {
        case LabsGqlWorkflowStateEnum.AwaitingLabAcceptance:
        case LabsGqlWorkflowStateEnum.NeedsReview:
            return 0;
        case LabsGqlWorkflowStateEnum.Design:
        case LabsGqlWorkflowStateEnum.DesignReview:
        case LabsGqlWorkflowStateEnum.OnFabricationFloor:
        case LabsGqlWorkflowStateEnum.OnHold:
            return 1;
        case LabsGqlWorkflowStateEnum.Shipped:
            return 2;
        case LabsGqlWorkflowStateEnum.Cancelled:
        case LabsGqlWorkflowStateEnum.Delivered:
        case LabsGqlWorkflowStateEnum.NeedsRefabrication:
            return 3;
    }
}

type TrackerStepMinusState = Omit<TrackerStepDefinition, 'active' | 'complete'> & {
    state: LabsGqlWorkflowStateEnum;
};

function genFabricationStep(state: LabsGqlWorkflowStateEnum, hold_in_review: boolean = false): TrackerStepMinusState {
    if (state === LabsGqlWorkflowStateEnum.OnHold) {
        return {
            icon: 'Stop',
            title: hold_in_review ? 'Hold in review' : 'On hold',
            state: LabsGqlWorkflowStateEnum.OnHold,
        };
    }
    const fabTitle = state === LabsGqlWorkflowStateEnum.OnFabricationFloor ? 'Fabrication' : '';
    return { icon: 'Tool', title: fabTitle, state: LabsGqlWorkflowStateEnum.OnFabricationFloor };
}

function genLastStep(props: OrderListItemTrackerProps): TrackerStepMinusState {
    const { isManufacturer, delivery_date, due_date, state, tracking_substatus } = props;
    if (state === LabsGqlWorkflowStateEnum.NeedsRefabrication) {
        return { icon: 'Refab', title: 'Refabricated', state: LabsGqlWorkflowStateEnum.NeedsRefabrication };
    }

    // Lost in transit
    if (tracking_substatus === LabsGqlShipmentSubStatus.PackageLost) {
        return { icon: 'Cancel', title: 'Lost in transit', state: LabsGqlWorkflowStateEnum.Delivered };
    }
    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const title = delivery_date
        ? `Delivered ${formatDate(delivery_date)}`
        : isManufacturer
          ? `Due ${formatDate(due_date)}`
          : 'Delivery';
    return { title, icon: 'Check', state: LabsGqlWorkflowStateEnum.Delivered };
}

interface AlignerStepsArgs {
    aligner_props: AlignerTrackerProps;
    firstStep: TrackerStepMinusState;
    fabricationStep: TrackerStepMinusState;
    shippedStep: TrackerStepMinusState;
    state: LabsGqlWorkflowStateEnum;
}

function genAlignerSteps(args: AlignerStepsArgs): TrackerStepDefinition[] {
    const { aligner_props, fabricationStep, firstStep, state, shippedStep } = args;
    return [
        {
            icon: firstStep.icon,
            title: firstStep.title,
            active: aligner_props.new_aligner_plan,
            complete: !aligner_props.new_aligner_plan,
        },
        {
            icon: 'Scan',
            title: aligner_props.treatment_plan_active ? 'Treatment Plan' : '',
            active: aligner_props.treatment_plan_active,
            complete: aligner_props.treatment_plan_complete,
        },
        {
            icon: fabricationStep.icon,
            title: fabricationStep.title,
            active: stepIndexForState(state) === stepIndexForState(LabsGqlWorkflowStateEnum.OnFabricationFloor),
            complete: stepIndexForState(state) > stepIndexForState(LabsGqlWorkflowStateEnum.OnFabricationFloor),
        },
        {
            icon: shippedStep.icon,
            title: shippedStep.title,
            active: stepIndexForState(state) === stepIndexForState(LabsGqlWorkflowStateEnum.Shipped),
            complete: stepIndexForState(state) > stepIndexForState(LabsGqlWorkflowStateEnum.Shipped),
        },
        {
            icon: 'Person',
            title:
                stepIndexForState(state) === stepIndexForState(LabsGqlWorkflowStateEnum.Delivered)
                    ? 'In Treatment'
                    : '',
            active: stepIndexForState(state) === stepIndexForState(LabsGqlWorkflowStateEnum.Delivered),
            complete: false,
        },
        {
            icon: 'Check',
            title: '',
            active: false, // todo: fill in once we determine data model for completed aligner orders
            complete: false,
        },
    ];
}

function useTrackerSteps(props: OrderListItemTrackerProps): TrackerStepDefinition[] {
    const {
        ship_date,
        sla_ship_by_date,
        ship_status,
        created_at,
        created_from_refab,
        state,
        aligner_props,
        tracking_status,
        tracking_substatus,
        package_accepted_date,
        hold_in_review,
    } = props;
    const lastStep = genLastStep(props);
    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    return React.useMemo<TrackerStepDefinition[]>(() => {
        if (ShippingUtils.isReturned(ship_status ?? tracking_status)) {
            const steps: TrackerStepDefinition[] = [
                { title: 'Shipped', active: false, complete: true, icon: 'Truck' },
                { title: 'Unable to deliver', active: false, complete: true, icon: 'Truck' },
                { title: 'Returned', active: false, complete: true, icon: 'Cancel' },
            ];

            return steps;
        }

        // if lost in transit - present workflow as delivered
        const currentActiveIndex =
            tracking_substatus === LabsGqlShipmentSubStatus.PackageLost ? 3 : stepIndexForState(state);

        const needsReview = state === LabsGqlWorkflowStateEnum.NeedsReview;
        const firstStep: TrackerStepMinusState = needsReview
            ? { icon: 'Warning', title: formatDate(created_at), state: LabsGqlWorkflowStateEnum.NeedsReview }
            : {
                  icon: created_from_refab ? 'Refab' : 'Order',
                  title: formatDate(created_at),
                  state: LabsGqlWorkflowStateEnum.AwaitingLabAcceptance,
              };
        const fabricationStep = genFabricationStep(state, hold_in_review);
        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
        // eslint-disable-next-line no-nested-ternary
        const shippedMessage = ShippingUtils.getIsShippedButTrackingUnknown(state, tracking_status)
            ? 'Shipping label created'
            : // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
              // eslint-disable-next-line no-nested-ternary
              package_accepted_date
              ? formatDate(package_accepted_date)
              : // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                // eslint-disable-next-line no-nested-ternary
                sla_ship_by_date
                ? formatDate(sla_ship_by_date)
                : ship_date
                  ? formatDate(ship_date)
                  : '';
        const isOnHold = state === LabsGqlWorkflowStateEnum.NeedsReview || state === LabsGqlWorkflowStateEnum.OnHold;
        const shippedStep: TrackerStepMinusState = {
            icon: 'Truck',
            title: isOnHold ? '' : shippedMessage,
            state: LabsGqlWorkflowStateEnum.Shipped,
        };
        if (!!aligner_props) {
            return genAlignerSteps({ aligner_props, firstStep, fabricationStep, shippedStep, state });
        }
        const rawSteps: TrackerStepMinusState[] = [firstStep, fabricationStep, shippedStep, lastStep];
        return rawSteps.map(({ state: stepState, ...step }, idx) => ({
            ...step,
            active: stepIndexForState(stepState) === currentActiveIndex,
            complete: idx === 0 || stepIndexForState(stepState) <= currentActiveIndex,
        }));
    }, [
        created_at,
        created_from_refab,
        lastStep,
        ship_date,
        ship_status,
        sla_ship_by_date,
        state,
        aligner_props,
        tracking_status,
        tracking_substatus,
        package_accepted_date,
        hold_in_review,
    ]);
}

// done to avoid injecting additional stylesheets

export const LIST_ITEM_ROOT_STYLE: React.CSSProperties = { zIndex: 1, paddingBottom: 12, alignItems: 'center' };

// merging the OrderDetailTracker component into this would be nice
export const OrderListItemTracker: React.FC<OrderListItemTrackerProps> = props => {
    const { hasIssue, state } = props;
    const steps = useTrackerSteps(props);
    return (
        <Grid
            container
            style={{ ...LIST_ITEM_ROOT_STYLE, ...props.containerStyle }}
            wrap={'nowrap'}
            data-test={'tracker-steps'}
        >
            {state !== LabsGqlWorkflowStateEnum.Cancelled &&
                steps.map((step, idx) => (
                    <TrackerStep key={idx} {...step} idx={idx} hasIssue={hasIssue} isLast={idx === steps.length - 1} />
                ))}
        </Grid>
    );
};
