import type { MainViewCameraControlsRef } from '../ModelViewer/ModelViewerTHREETypes';
import {
    setFrontCameraView,
    setLeftCameraView,
    setRightCameraView,
    setTopCameraView,
    setBottomCameraView,
} from '../ModelViewer/utils3d/OcclusalAlignment.util';
import { zoomToSphere } from '../ModelViewer/utils3d/camera-controls.util';
import type { IOperationsManager } from './OperationsManager.types';
import type { ISceneAppearanceInspector } from './SceneAppearanceManager.types';
import type { IViewManager } from './ViewManager.types';
import { setToothView } from './ViewManager.utils';
import type * as THREE from 'three';

/**
 * Exposes methods to manage the camera view.
 *
 * NB: This class assumes that the scene contents (jaw scans, restorative models, etc.) are globally oriented by the
 * occlusal frame, i.e. the global +x, +y, and +z axes are aligned with patient left, up, and front directions,
 * respectively. This will be true for cases prepped by Dandy Prep, but not true for other cases.
 */
export class ViewManager implements IViewManager {
    constructor(
        private controlsRef: MainViewCameraControlsRef,
        private opManager: IOperationsManager,
        private sceneInspector: ISceneAppearanceInspector,
    ) {}

    /**
     * Sets the camera to look from the front of the mouth.
     */
    setFrontCameraView(): void {
        setFrontCameraView(this.controlsRef);
        this.zoomToVisible();
    }

    /**
     * Sets the camera to look from the left of the mouth.
     */
    setLeftCameraView(): void {
        setLeftCameraView(this.controlsRef);
        this.zoomToVisible();
    }

    /**
     * Sets the camera to look from the right of the mouth.
     */
    setRightCameraView(): void {
        setRightCameraView(this.controlsRef);
        this.zoomToVisible();
    }

    /**
     * Sets the camera to look from the top of the mouth.
     */
    setTopCameraView(): void {
        setTopCameraView(this.controlsRef);
        this.zoomToVisible();
    }

    /**
     * Sets the camera to look from the bottom of the mouth.
     */
    setBottomCameraView(): void {
        setBottomCameraView(this.controlsRef);
        this.zoomToVisible();
    }

    /**
     * Sets the camera to look at the mesial side of the tooth being edited.
     */
    setMesialCameraView(): void {
        const sphere = this.getEditGeometryBoundingSphere();
        const insertionAxis = this.getEditGeometryInsertionAxis();
        setToothView(this.controlsRef, this.opManager.editToothNumber, sphere.center, insertionAxis, 'M');
        zoomToSphere(this.controlsRef, sphere);
    }

    /**
     * Sets the camera to look at the distal side of the tooth being edited.
     */
    setDistalCameraView(): void {
        const sphere = this.getEditGeometryBoundingSphere();
        const insertionAxis = this.getEditGeometryInsertionAxis();
        setToothView(this.controlsRef, this.opManager.editToothNumber, sphere.center, insertionAxis, 'D');
        zoomToSphere(this.controlsRef, sphere);
    }

    /**
     * Sets the camera to look at the lingual side of the tooth being edited.
     */
    setLingualCameraView(): void {
        const sphere = this.getEditGeometryBoundingSphere();
        const insertionAxis = this.getEditGeometryInsertionAxis();
        setToothView(this.controlsRef, this.opManager.editToothNumber, sphere.center, insertionAxis, 'L');
        zoomToSphere(this.controlsRef, sphere);
    }

    /**
     * Sets the camera to look at the facial side of the tooth being edited.
     */
    setFacialCameraView(): void {
        const sphere = this.getEditGeometryBoundingSphere();
        const insertionAxis = this.getEditGeometryInsertionAxis();
        setToothView(this.controlsRef, this.opManager.editToothNumber, sphere.center, insertionAxis, 'F');
        zoomToSphere(this.controlsRef, sphere);
    }

    /**
     * Sets the camera to look along the insertion axis at the occlusal surface of the tooth being edited.
     */
    setInsertionCameraView(): void {
        const sphere = this.getEditGeometryBoundingSphere();
        const insertionAxis = this.getEditGeometryInsertionAxis();
        setToothView(this.controlsRef, this.opManager.editToothNumber, sphere.center, insertionAxis, 'O');
        zoomToSphere(this.controlsRef, sphere);
    }

    private zoomToVisible(): void {
        const sphere = this.sceneInspector.getBoundingSphereForVisible();
        zoomToSphere(this.controlsRef, sphere);
    }

    private getEditGeometryBoundingSphere(): THREE.Sphere {
        return this.sceneInspector.getRestorativeBoundingSphere(this.opManager.editToothNumber);
    }

    private getEditGeometryInsertionAxis(): THREE.Vector3 {
        return this.opManager.editInsertionAxis;
    }
}
