import type { RefabFlowLabOrder } from '../RefabFlow/state/refab-flow-types';
import { RefabFlowOrderDetailsLayout } from './RefabFlowOrderDetailsLayout';
import { formatOrderDetailsData } from './helpers';
import type { RefabFlowOrderDetailsLayoutProps } from './types';
import type { LabsGqlCustomFieldPrefsFragment } from '@orthly/graphql-operations';
import React from 'react';

export interface RefabFlowOrderDetailsProps {
    order: RefabFlowLabOrder;
    doctorCustomFieldPreferences: Omit<LabsGqlCustomFieldPrefsFragment, '__typename'>[];
}

/**
 * This component is used to show information about an order in the refab flow created
 * for both Chairside and Practice. This component is helpful in the refab flow to remind
 * doctor what the order they're refabing is.
 *
 * It shows details abouch each item in its own tab, and includes a mix of
 * - generic fields (eg: name, shades)
 * - SKU specific fields (eg: denture: arch, implant: crown material, etc.).
 *
 * It also includes high level details about the order (eg: doctor name, created/delivered dates)
 */
export const RefabFlowOrderDetails: React.VFC<RefabFlowOrderDetailsProps> = ({
    order,
    doctorCustomFieldPreferences,
}) => {
    const [formattedData, setFormattedData] = React.useState<RefabFlowOrderDetailsLayoutProps | null>(null);

    React.useEffect(() => {
        setFormattedData(formatOrderDetailsData(order, doctorCustomFieldPreferences));
    }, [order, doctorCustomFieldPreferences]);

    if (!formattedData) {
        return null;
    }

    return <RefabFlowOrderDetailsLayout {...formattedData} />;
};
