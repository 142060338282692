import {
    usePreloadPracticeInformation,
    useCurrentPartner,
    useUpdatePracticeSettings,
} from './EditPracticeInformationModal.hooks';
import type { IconName } from '@orthly/ui';
import { QuickFormValidations, RootActionDialog, SimpleInput, SimplePhoneInput, styled } from '@orthly/ui';
import { Button, Text } from '@orthly/ui-primitives';
import React from 'react';
import z from 'zod';

const MIN_DISPLAY_NAME_LEN = 5;
const MAX_DISPLAY_NAME_LEN = 75;

const practiceNameValidator = z.string().min(MIN_DISPLAY_NAME_LEN).max(MAX_DISPLAY_NAME_LEN);
const practicePhoneValidator = QuickFormValidations.phone.or(z.literal('+1')).or(z.literal(''));

const ContentLayout = styled('div')(({ theme }) => ({
    display: 'grid',
    gap: theme.spacing(4),
}));

const TextLayout = styled('div')(({ theme }) => ({
    display: 'grid',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1.5),
}));

const FieldsLayout = styled('div')(({ theme }) => ({
    display: 'grid',
    gap: theme.spacing(2),
}));

const ActionsLayout = styled('div')(({ theme }) => ({
    display: 'grid',
    gap: theme.spacing(1),
    gridAutoFlow: 'column',
}));

type EditPracticeInfomationModalConfig = {
    title: string;
    subtitle: React.ReactNode;
    submitText: string;
    submitIcon?: IconName;
};

const getEditPracticeInfomationModalConfig = (isEditing?: boolean): EditPracticeInfomationModalConfig => {
    if (isEditing) {
        return {
            title: 'Edit practice contact information',
            subtitle: (
                <TextLayout>
                    <Text variant={'body2'}>
                        This is what patients will see on the link or printout they receive, ensuring they have the
                        details they need to ask questions or schedule a follow-up appointment.
                    </Text>
                </TextLayout>
            ),
            submitText: 'Save',
        };
    }

    return {
        title: 'Share scans with patient',
        subtitle: (
            <TextLayout>
                <Text variant={'body2'} medium>
                    But first, let's confirm your practice's front desk contact information
                </Text>

                <Text variant={'body2'}>
                    This is what patients will see on the link or printout they receive, ensuring they have the details
                    they need to ask questions or schedule a follow-up appointment. Don’t worry — this is a one-time
                    setup.
                </Text>
            </TextLayout>
        ),
        submitText: 'Next',
        submitIcon: 'ChevronRight',
    };
};

interface EditPracticeInformationModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    isEditing?: boolean;
    setOrganizationName?: (organizationName: string) => void;
    setOrganizationPhone?: (organizationPhone: string) => void;
    setShareModalOpen?: (open: boolean) => void;
    organizationName?: string | null | undefined;
    organizationPhone?: string | null | undefined;
    organizationId?: string;
}

export const EditPracticeInformationModal: React.VFC<EditPracticeInformationModalProps> = ({
    open,
    setOpen,
    isEditing,
    setOrganizationName,
    setOrganizationPhone,
    setShareModalOpen,
    organizationName,
    organizationPhone,
    organizationId,
}) => {
    const [name, setName] = React.useState('');
    const [phone, setPhone] = React.useState('');

    const config = getEditPracticeInfomationModalConfig(isEditing);
    const { data: currentPartner, loading: partnerLoading } = useCurrentPartner(organizationId);

    const nameIsValid = practiceNameValidator.safeParse(name).success;
    const phoneIsValid = practicePhoneValidator.safeParse(phone).success;
    const canContinue = nameIsValid && phoneIsValid;

    usePreloadPracticeInformation(currentPartner, setName, setPhone);

    const { submit, submitting } = useUpdatePracticeSettings(() => setOpen(false));

    const loading = partnerLoading || submitting;

    React.useEffect(() => {
        if (currentPartner && setOrganizationName && setOrganizationPhone) {
            setOrganizationName(currentPartner.name);
            if (currentPartner.phone_number) {
                setOrganizationPhone(currentPartner.phone_number);
            }
        }
        if (organizationName || organizationPhone) {
            if (organizationName) {
                setName(organizationName);
            }
            if (organizationPhone) {
                setPhone(organizationPhone);
            }
        }
    }, [currentPartner, organizationName, organizationPhone, setOrganizationName, setOrganizationPhone]);

    return (
        <RootActionDialog
            title={config.title}
            open={open}
            setOpen={setOpen}
            loading={loading}
            CustomButton={() => null}
            content={
                <ContentLayout>
                    {config.subtitle}

                    <FieldsLayout>
                        <SimpleInput
                            onChange={v => setName(v ?? '')}
                            data-test={'practice-name-input'}
                            value={name}
                            label={"Your practice's name"}
                            TextFieldProps={{
                                error: !nameIsValid,
                                helperText: !nameIsValid
                                    ? 'Practice name must be between 5 and 75 characters'
                                    : undefined,
                            }}
                        />
                        <SimplePhoneInput
                            onChange={v => setPhone(v ?? '')}
                            value={phone}
                            label={'Front desk phone number'}
                            data-test={'practice-phone-input'}
                            TextFieldProps={{
                                error: !phoneIsValid,
                                helperText: !phoneIsValid ? 'Please enter a valid phone number' : undefined,
                            }}
                        />
                    </FieldsLayout>

                    <ActionsLayout>
                        <Button
                            variant={'secondary-gray'}
                            onClick={() => {
                                if (setShareModalOpen && (!organizationPhone || !organizationName)) {
                                    setShareModalOpen(false);
                                }
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant={'primary'}
                            endIcon={config.submitIcon}
                            disabled={!canContinue || !name || !phone}
                            onClick={() => {
                                if (setOrganizationName && setOrganizationPhone) {
                                    setOrganizationName(name);
                                    setOrganizationPhone(phone);
                                }
                                void submit({ name, phone_number: phone });
                                setOpen(false);
                            }}
                        >
                            {config.submitText}
                        </Button>
                    </ActionsLayout>
                </ContentLayout>
            }
        />
    );
};
