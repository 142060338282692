import { BulkDownloadDialog } from '../../BulkDownloadDialog/BulkDownloadDialog';
import { useLazyQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { basename } from 'path-browserify';
import React from 'react';

const VeneerDesignOrderLatestRawRevisionsDownload_Query = graphql(`
    query VeneerDesignOrderLatestRawRevisionsDownload_Query($orderIds: [String!]!) {
        getLatestDesignOrderDesignRevisionsForOrders(labOrderIds: $orderIds) {
            source_file_zip_path
        }
    }
`);

interface DesignOrderLatestRawRevisionsDownloadProps {
    Button: React.VFC<{ onClick: () => void }>;
    orderIds: string[];
    onDownloadStarted?: (orderIds: string[]) => void;
}

export const DesignOrderLatestRawRevisionsDownload: React.VFC<DesignOrderLatestRawRevisionsDownloadProps> = ({
    Button,
    orderIds,
    onDownloadStarted,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);

    const [fetchDesigns, { data }] = useLazyQuery(VeneerDesignOrderLatestRawRevisionsDownload_Query, {
        onCompleted: () => {
            setOpen(true);
        },
    });

    return (
        <>
            <Button
                onClick={() => {
                    fetchDesigns({
                        variables: {
                            orderIds,
                        },
                    });
                }}
            />
            <BulkDownloadDialog
                open={open}
                setOpen={setOpen}
                files={
                    data?.getLatestDesignOrderDesignRevisionsForOrders.map(rev => ({
                        source: 'firebase',
                        path: rev.source_file_zip_path,
                        name: basename(rev.source_file_zip_path),
                    })) ?? []
                }
                onDownloadStart={() => onDownloadStarted?.(orderIds)}
            />
        </>
    );
};
