import type { UrlState } from '../AlignerTreatmentPlanSubmission.graphql';
import { AlignerTreatmentPlanFileSubmission } from '../AlignerTreatmentPlanSubmission.graphql';
import type { TreatmentPlanFormData } from '../AlignerViewer.types';
import { RootActionDialog } from '@orthly/ui';
import React from 'react';

interface UploadSoftsmileArtifactsProps {
    orderId: string;
    refetch: () => Promise<any>;
    treatmentPlanUnconvertedUrl?: string;
}

export const UploadSoftsmileArtifacts: React.FC<UploadSoftsmileArtifactsProps> = ({
    orderId,
    treatmentPlanUnconvertedUrl,
    refetch,
}) => {
    const [open, setOpen] = React.useState(false);

    const [formData, setFormData] = React.useState({
        url: treatmentPlanUnconvertedUrl,
        steps: [],
        duration: 0,
        planType: undefined,
    });
    const [urlState, setUrlState] = React.useState<UrlState>('editing');
    const updateField = React.useCallback(
        <T extends keyof TreatmentPlanFormData>(field: T, value: TreatmentPlanFormData[T]) => {
            setFormData(data => ({ ...data, [field]: value }));
        },
        [setFormData],
    );

    return (
        <RootActionDialog
            loading={false}
            open={open}
            setOpen={setOpen}
            title={'Upload SoftSmile export for conversion'}
            buttonText={'Convert SoftSmile export'}
            content={
                <>
                    <AlignerTreatmentPlanFileSubmission
                        {...{
                            orderId,
                            setOpen,
                            urlState,
                            updateField,
                            setUrlState: state => {
                                if (state === 'processed') {
                                    void refetch();
                                }
                                setUrlState(state);
                            },
                            data: formData,
                        }}
                    />
                </>
            }
        />
    );
};
