import { SimpleDropzone } from '../FirebaseUpload';
import React from 'react';
import type { DropEvent } from 'react-dropzone';

interface DcmDropLoadProps {
    onDcmDrop: <T extends File>(files: T[], event: DropEvent) => void;
}

export const File3dDropLoader: React.FC<DcmDropLoadProps> = (props: DcmDropLoadProps) => {
    const { onDcmDrop } = props;
    return (
        <SimpleDropzone
            preUploadText={'Upload *.DCM File (click or drop file) to check it in the 3D Viewer'}
            wrapperStyle={{ minHeight: 40, marginTop: 0, padding: 0 }}
            options={{ onDropAccepted: onDcmDrop, multiple: false }}
        />
    );
};
