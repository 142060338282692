import type { LoginRootScreen } from '@orthly/veneer';
import { LoginRoot } from '@orthly/veneer';
import type { Location } from 'history';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { useLocation, Redirect, useHistory } from 'react-router-dom';

export const AdminLoginRoute = '/login';

const UpdatePasswordRoute = 'new-password';

type PreviousLocationState = { previous?: Location };

export const AdminLoginRedirect: React.VFC = () => {
    const location = useLocation();
    const state: PreviousLocationState = { previous: location };
    return <Redirect to={{ state, pathname: AdminLoginRoute }} />;
};

export const AdminLogin: React.FC<RouteComponentProps<any, any, PreviousLocationState | undefined>> = ({
    location,
}) => {
    const history = useHistory();
    const onLoggedIn = React.useCallback(() => {
        history.replace(location.state?.previous ?? { pathname: '/orders' });
    }, [history, location.state?.previous]);

    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const initialScreen: LoginRootScreen = location.pathname.includes('/reset')
        ? 'reset'
        : location.pathname.includes(UpdatePasswordRoute)
          ? 'change_password'
          : 'login';
    return <LoginRoot initialScreen={initialScreen} onLoggedIn={onLoggedIn} />;
};
