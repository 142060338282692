import React from 'react';

function useActivityCounterImpl() {
    const [isActive, setIsActive] = React.useState(false);
    const countRef = React.useRef(0);

    const increment = () => {
        countRef.current += 1;
        if (countRef.current === 1) {
            setIsActive(true);
        }
    };

    const decrement = () => {
        if (countRef.current === 0) {
            return;
        }
        countRef.current -= 1;
        if (countRef.current === 0) {
            setIsActive(false);
        }
    };

    return { isActive, activityCounter: { increment, decrement } };
}

// Not using constate here, since I want `useActivityCounter()` to work outside of a provider context.
type State = ReturnType<typeof useActivityCounterImpl>;
const context = React.createContext<State | undefined>(undefined);

/**
 * Provides an ActivityCounter context.
 *
 * This makes it simple for a parent component to keep track of activity (such as ongoing
 * file uploads) among any of its children.
 *
 * The parent component should be wrapped in an <ActivityCounterProvider>. It can use
 *   const { isActive } = useActivityCounter();
 * to track whether any child components have ongoing activity.
 *
 * Child components should use
 *   const { activityCounter } = useActivityCounter();
 *
 * When activity begins, call `activityCounter?.increment()` and when it ends call
 * `activityCounter?.decrement()`. Calls must be balanced. When not inside a context,
 * `useActivityCounter()` returns an empty object.
 */
export const ActivityCounterProvider: React.VFC<{ children?: React.ReactNode }> = ({ children }) => {
    const value = useActivityCounterImpl();
    return <context.Provider value={value}>{children}</context.Provider>;
};

export function useActivityCounter(): Partial<State> {
    return React.useContext(context) ?? {};
}
