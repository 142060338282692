import { stylesFactory } from '../../../util/stylesFactory';
import type { NavigationItemProps, NavigationEntry } from './NavigationItem';
import { NavigationItem } from './NavigationItem';
import { Collapse, List, FlossPalette, Icon } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory<{ collapsed: boolean; active: boolean }>(theme => ({
    icon: props => ({
        opacity: props.collapsed ? 0 : 1,
        transition: theme.transitions.create('opacity', {
            easing: theme.transitions.easing.easeInOut,
            delay: !props.collapsed ? 100 : 0,
            duration: !props.collapsed ? 200 : 0,
        }),
        color: props.active ? FlossPalette.STAR_GRASS : FlossPalette.BLACK,
        marginLeft: 'auto',
    }),
}));

export interface NavigationItemCollapseProps extends Omit<NavigationItemProps, 'onClick' | 'secondary'> {
    items: NavigationEntry[];
}

export const NavigationItemCollapse: React.FC<NavigationItemCollapseProps> = props => {
    const { items, ...rootItemProps } = props;
    const [open, setOpen] = React.useState<boolean>(false);
    const active = React.useMemo(() => {
        return open ? false : !!items.find(i => i.active);
    }, [open, items]);
    const classes = useStyles({ active, collapsed: props.collapsed });
    return (
        <>
            <NavigationItem {...rootItemProps} active={active} onClick={() => setOpen(o => !o)}>
                <Icon icon={open ? 'ExpandLessIcon' : 'ExpandMoreIcon'} className={classes.icon} />
            </NavigationItem>
            <Collapse in={open} timeout={'auto'} unmountOnExit>
                <List disablePadding>
                    {items.map((item, idx) => (
                        <NavigationItem key={idx} {...item} secondary collapsed={props.collapsed} />
                    ))}
                </List>
            </Collapse>
        </>
    );
};
