/* eslint-disable max-lines */
import { AnalyticsClient } from '../../utils/analyticsClient';
import { RouterUtils } from '../../utils/router/RouterUtils';
import { MultiProductImageWrapper } from '@orthly/dentin';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useOrdersByScanIdQuery } from '@orthly/graphql-react';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { Blue } from '@orthly/ui';
import { Text, FlossPalette, Button, Collapse, Grid } from '@orthly/ui-primitives';
import {
    useFirebasePreview,
    useFirebaseFilesInBucket,
    getFirebaseDownloadUrl,
    useFirebaseStorage,
} from '@orthly/veneer';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import ReactPlayer from 'react-player';
import type { RouteComponentProps } from 'react-router-dom';
import { Switch, Route, useHistory } from 'react-router-dom';

type ChairsideScanRecordingMetadata = {
    orgId: string;
    caseId: string;
    scanId: string;
    time: string;
};

type ScanRecordingSession = [ChairsideScanRecordingMetadata, ...ChairsideScanRecordingMetadata[]];

// TODO: GCS Cleanup. Does this need to be a harcoded path because of something in scanner?
function getCasePath(orgId: string, caseId: string): string {
    return `chairside-scans/${orgId}/${caseId}`;
}

// Paths will be stored in firebase in the format of:
// /chairside-scans/orgid/caseid/scanid_timestamp.webm
// The timestamp will be stored in the format of: MM_DD_YYYY_HH_mm_ss.
function parsePath(path: string): ChairsideScanRecordingMetadata | undefined {
    const prefix = 'chairside-scans/';
    // Trim off the left hand side until we reach to the right position of the prefix.
    const leftTrimmed = path.substr(path.indexOf(prefix) + prefix.length);
    const [orgId, caseId, scanAndTime] = leftTrimmed.split('/');
    const [scanId, ...timeSplits] = scanAndTime?.split('_') ?? [];
    const time = timeSplits.join('_').replace('.webm', '');

    if (!orgId || !caseId || !scanId || !time) {
        return undefined;
    }

    return { orgId, caseId, scanId, time };
}

export function useListChairsideScanRecordingsSessions(orgId: string, caseId: string): ScanRecordingSession[] {
    const [paths, setPaths] = React.useState<ChairsideScanRecordingMetadata[]>([]);
    // TODO: GCS Cleanup - this is relying on a hardcoded path in the GCS bucket
    const { execute } = useFirebaseFilesInBucket();

    React.useEffect(() => {
        void execute(getCasePath(orgId, caseId)).then(result => {
            const newPaths = result.items.map(i => i.toString());
            const parsedMetadata = _.compact(newPaths.map(path => parsePath(path)));
            setPaths(parsedMetadata);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, caseId]);

    return React.useMemo<ScanRecordingSession[]>(() => {
        const groupedByScanId = _.groupBy<ChairsideScanRecordingMetadata>(paths, path => path.scanId);

        return Object.values(groupedByScanId).flatMap(group => getSessionGroupedChairsideRecordings(group));
    }, [paths]);
}

function getSessionGroupedChairsideRecordings(paths: ChairsideScanRecordingMetadata[]): ScanRecordingSession[] {
    const sortedScans: ChairsideScanRecordingMetadata[] = _.sortBy(paths, path => path.time);
    return sortedScans.reduce<ScanRecordingSession[]>((state, current) => {
        const lastIsland = _.last(state);

        // There are no last islands, so we'll create one -- base case.
        if (!lastIsland?.length) {
            return [...state, [current]];
        }

        // Determine if we can add this current scan to the lastIsland.
        // We enjoin two islands if the current path is within an acceptable time bound of the last path of the island.
        // Since we time splice at 30 second intervals, all clips will be AT MOST 30 seconds apart in a given scan session.
        // They can, however, be as few as one second, since a 61 second clip will yield 3 paths -- two of 30s, and one of 1s.
        // We will never have a situation where there is a 30s clip, 5s clip, and then a 30s clip, all in one session, by nature of how they're spliced.
        // Thus, if we see that, we know the island is ready to be broken.
        // We use hasShortEnd to signify that the island has already seen a < 30 second clip, and that any other < 30 second clips should break the island.
        // Otherwise, we continue appending to the island as long as the distance from the last path to current is <= 30 seconds.
        const [secondToLastIslandScan, lastIslandScan] = lastIsland.slice(lastIsland.length - 2);
        const hasShortEnd =
            secondToLastIslandScan &&
            lastIslandScan &&
            Math.abs(timeSecondDiff(secondToLastIslandScan.time, lastIslandScan.time)) <= 28;
        const timeFromLastToCurrent = timeSecondDiff(
            (lastIslandScan ?? secondToLastIslandScan)?.time ?? '',
            current.time,
        );

        if (
            // We haven't seen a "short" path, so if we encounter one, we can add it
            (!hasShortEnd && timeFromLastToCurrent <= 28) ||
            // This is a 30 second path, so no need to worry about if it's at the end of a short path.
            (timeFromLastToCurrent >= 29 && timeFromLastToCurrent <= 31)
        ) {
            return [...state.slice(0, state.length - 1), [...lastIsland, current]];
        }

        return [...state, [current]];
    }, []);
}

function parseTime(time: string) {
    return moment(time, 'MM_DD_YYYY_HH_mm_ss');
}

function timeSecondDiff(timeA: string, timeB: string): number {
    return parseTime(timeB).diff(parseTime(timeA), 'seconds');
}

function parseAndFormatTime(time: string): string {
    return parseTime(time).format('MM/DD/YYYY HH:mm:ss');
}

const ChairsideCaseHeader: React.VFC<{ orgId: string; caseId: string; scanId?: string; time?: string }> = ({
    orgId,
    caseId,
    scanId,
    time,
}) => {
    const history = useHistory();
    const orders = useOrdersForCase(caseId);
    const firstOrder = orders[0];

    const products = React.useMemo(() => {
        const allProducts = orders.flatMap(order =>
            OrderItemV2Utils.parseItems(order.items_v2).flatMap(i => CartItemV2Utils.getProductUnitType(i)),
        );
        return _.uniq(allProducts);
    }, [orders]);

    return (
        <Grid item style={{ padding: '8px 16px' }} container direction={'column'}>
            <Grid item>
                <Text
                    variant={'caption'}
                    color={'BLACK'}
                    style={{ cursor: 'pointer' }}
                    onClick={() => history.push(`${RouterUtils.pathForScreen('chairside_scans')}/${orgId}/${caseId}`)}
                >
                    Case {caseId}
                </Text>
                {scanId && (
                    <>
                        {' '}
                        <Text variant={'caption'} color={'DARK_GRAY'} style={{ margin: '0px 8px' }}>
                            {'>'}
                        </Text>
                        <Text
                            style={{ cursor: 'pointer' }}
                            variant={'caption'}
                            color={'BLACK'}
                            onClick={() =>
                                history.push(
                                    `${RouterUtils.pathForScreen('chairside_scans')}/${orgId}/${caseId}/${scanId}`,
                                )
                            }
                        >
                            Scan {scanId}
                        </Text>
                        {time && (
                            <>
                                <Text variant={'caption'} color={'DARK_GRAY'} style={{ margin: '0px 8px' }}>
                                    {'>'}
                                </Text>
                                <Text variant={'caption'} color={'BLACK'}>
                                    Time {parseAndFormatTime(time)}
                                </Text>
                            </>
                        )}
                    </>
                )}
            </Grid>
            <Grid item container alignItems={'center'}>
                {firstOrder ? (
                    <>
                        <Grid item style={{ marginRight: 8 }}>
                            <MultiProductImageWrapper products={products} />
                        </Grid>
                        <Grid item>
                            <Text variant={'body2'}>
                                <Blue>Dr. {firstOrder.doctor_name}</Blue> submitted <Blue>{orders.length} orders</Blue>{' '}
                                for{' '}
                                <Blue>
                                    {firstOrder.patient.first_name} {firstOrder.patient.last_name}
                                </Blue>
                            </Text>
                        </Grid>
                    </>
                ) : (
                    <Text variant={'body2'}>This case has not yet been submitted.</Text>
                )}
            </Grid>
        </Grid>
    );
};

const ChairsideCaseScanCard: React.VFC<{
    scanId: string;
    sessions: ScanRecordingSession[];
    openByDefault: boolean;
}> = ({ scanId, sessions, openByDefault }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(openByDefault);
    const history = useHistory();

    return (
        <Grid
            container
            direction={'column'}
            style={{ width: '100%', borderRadius: 8, border: `1px solid ${FlossPalette.BLACK}`, marginBottom: 16 }}
        >
            <Grid
                container
                direction={'row'}
                item
                style={{
                    padding: 16,
                    borderBottom: isOpen ? `1px solid ${FlossPalette.GRAY}` : undefined,
                    cursor: 'pointer',
                }}
                onClick={() => setIsOpen(open => !open)}
            >
                <Grid item>
                    <Text variant={'h6'} color={'BLACK'}>
                        {scanId}
                    </Text>
                </Grid>
                <Grid item xs />
                <Grid item>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        {sessions.length} recordings
                    </Text>
                </Grid>
            </Grid>
            <Grid item>
                <Collapse in={isOpen} style={{ padding: isOpen ? 16 : undefined }}>
                    <ul>
                        {sessions.map(session => (
                            <li key={session[0].time}>
                                <Button
                                    variant={'ghost'}
                                    style={{ height: 32 }}
                                    onClick={() =>
                                        history.push(
                                            `${RouterUtils.pathForScreen('chairside_scans')}/${session[0].orgId}/${
                                                session[0].caseId
                                            }/${session[0].scanId}/${session[0].time}`,
                                        )
                                    }
                                >
                                    {parseAndFormatTime(session[0].time)}
                                </Button>
                            </li>
                        ))}
                    </ul>
                </Collapse>
            </Grid>
        </Grid>
    );
};

const ChairsideCaseViewer: React.VFC<
    RouteComponentProps<{ orgId: string; caseId: string; scanId?: string }>
> = props => {
    const { params } = props.match;
    const paths = useListChairsideScanRecordingsSessions(params.orgId, params.caseId);

    const pathsByScan = _.groupBy(paths, path => path[0]?.scanId);

    return (
        <Grid container direction={'column'}>
            <ChairsideCaseHeader {...params} />
            <Grid item style={{ padding: '0px 16px' }}>
                {Object.entries(pathsByScan).map(([scanId, sessions]) => (
                    <ChairsideCaseScanCard
                        key={scanId}
                        scanId={scanId}
                        sessions={sessions}
                        openByDefault={scanId === params.scanId}
                    />
                ))}
            </Grid>
        </Grid>
    );
};

interface ChairsideScanTimeThumbnailProps {
    session: ScanRecordingSession;
    isCurrentlyPlayingVideo: boolean;
    style?: React.CSSProperties;
}

export const ChairsideScanTimeThumbnail: React.VFC<ChairsideScanTimeThumbnailProps> = ({
    session,
    isCurrentlyPlayingVideo,
    style,
}) => {
    const preview = useFirebasePreview(
        `${getCasePath(session[0].orgId, session[0].caseId)}/${session[0].scanId}_${session[0].time}.webm`,
    );
    const history = useHistory();
    // A rough approximation of how long a session lasts.
    // This time is 30 seconds per full path, plus 15 seconds to assume the last path isn't fully used up.
    const sessionDuration = (session.length - 1) * 30 + 15;
    const shortenedScanId = session[0].scanId?.split('-')[0];

    if (!preview?.result) {
        return null;
    }

    return (
        <Grid
            item
            style={{
                marginRight: 8,
                marginBottom: 8,
                padding: 4,
                cursor: 'pointer',
                textAlign: 'center',
                borderRadius: 8,
                border: `1px solid ${isCurrentlyPlayingVideo ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.BLACK}`,
                backgroundColor: isCurrentlyPlayingVideo ? FlossPalette.PRIMARY_BACKGROUND : FlossPalette.TAN,
                width: 200,
                ...style,
            }}
            direction={'column'}
            onClick={() => {
                history.push(
                    `${RouterUtils.pathForScreen('chairside_scans')}/${session[0].orgId}/${session[0].caseId}/${
                        session[0].scanId
                    }/${session[0].time}`,
                );
            }}
        >
            <Grid item style={{ borderBottom: `1px solid ${FlossPalette.GRAY}`, paddingBottom: 4 }}>
                <Text variant={'caption'}>{shortenedScanId}</Text>
            </Grid>
            <Grid item style={{ paddingTop: 4 }}>
                <Text variant={'body2'}>
                    {parseAndFormatTime(session[0].time)} -{' '}
                    {parseTime(session[0].time).add(sessionDuration, 'seconds').format('HH:mm:ss')} (~{sessionDuration}
                    s)
                </Text>
            </Grid>
        </Grid>
    );
};

function useSessionBlobUrl(allSessions: ScanRecordingSession[], selectedPath: ChairsideScanRecordingMetadata) {
    const firebase = useFirebaseStorage();
    const [blobUrl, setBlobUrl] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        const session = allSessions.find(
            session => session[0].scanId === selectedPath.scanId && session.find(c => c.time === selectedPath.time),
        );

        if (!session) {
            setBlobUrl(undefined);
            return;
        }

        // We create firebase URLs out of each path in the session, and then group them into a larger blob
        // This works because of how webm's are built -- we can simply concat all of the blob sources together.
        void Promise.all(
            session.map(async item => {
                const source = await getFirebaseDownloadUrl(
                    firebase,
                    `${getCasePath(item.orgId, item.caseId)}/${item.scanId}_${item.time}.webm`,
                );
                const res = await axios.get<Blob>(source, { responseType: 'blob' });
                return res.data;
            }),
        ).then(sources => {
            const blob = new Blob(sources);
            setBlobUrl(URL.createObjectURL(blob));
        });
    }, [allSessions, selectedPath, firebase]);

    return blobUrl;
}

const ChairsideScanTimeViewer: React.VFC<
    RouteComponentProps<{ orgId: string; caseId: string; scanId: string; time: string }>
> = props => {
    const { params } = props.match;
    const sessions = useListChairsideScanRecordingsSessions(params.orgId, params.caseId);
    const blobUrl = useSessionBlobUrl(sessions, params);

    const scanSessions = sessions.filter(session => session[0].scanId === params.scanId);

    React.useEffect(() => {
        AnalyticsClient.track('Ops - Chairside - Scan Recording Viewed', {
            scanId: params.scanId,
            time: params.time,
            organizationId: params.orgId,
            $groups: {
                case: params.caseId,
            },
        });
    }, [params]);

    if (!blobUrl) {
        return null;
    }

    return (
        <Grid key={blobUrl} container direction={'column'}>
            <ChairsideCaseHeader {...params} />
            <Grid item>
                <ReactPlayer
                    stopOnUnmount
                    controls
                    playing
                    style={{ height: '100%', width: '100%', margin: '0 auto' }}
                    url={blobUrl}
                    muted
                />
            </Grid>
            <Grid item style={{ padding: `16px 0px 16px 16px` }}>
                <Text variant={'h4'}>Sessions in this scan</Text>
            </Grid>
            <Grid item container direction={'row'} style={{ padding: 16 }}>
                {scanSessions.map((session, idx) => (
                    <ChairsideScanTimeThumbnail
                        key={idx}
                        session={session}
                        isCurrentlyPlayingVideo={session.some(c => c.time === params.time)}
                    />
                ))}
            </Grid>
        </Grid>
    );
};

function useOrdersForCase(caseId: string): LabsGqlLabOrderFragment[] {
    const { data } = useOrdersByScanIdQuery({ variables: { scan_id: caseId } });
    return data?.ordersByScanId ?? [];
}

export const ChairsideScansRoot: React.VFC = () => {
    return (
        <Switch>
            <Route
                path={`${RouterUtils.pathForScreen('chairside_scans')}/:orgId/:caseId/:scanId/:time`}
                component={ChairsideScanTimeViewer}
            />

            <Route
                path={`${RouterUtils.pathForScreen('chairside_scans')}/:orgId/:caseId/:scanId`}
                component={ChairsideCaseViewer}
            />

            <Route
                path={`${RouterUtils.pathForScreen('chairside_scans')}/:orgId/:caseId`}
                component={ChairsideCaseViewer}
            />
        </Switch>
    );
};
