import { AutomationActionsStep } from './AutomationActionStep/AutomationActionsStep';
import { AutomationDetailsStep } from './AutomationDetailsStep';
import { AutomationFilterStepRoot } from './AutomationFilterStep/AutomationFilterStepRoot';
import { FlossPalette, makeStyles, createStyles, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            background: FlossPalette.DARK_TAN,
            padding: 24,
            flexDirection: 'column',
            position: 'relative',
            flexWrap: 'nowrap',
        },
    }),
);

export const AutomationBuilderRoot: React.FC = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root}>
            <AutomationDetailsStep />
            <AutomationFilterStepRoot />
            <AutomationActionsStep />
        </Grid>
    );
};
