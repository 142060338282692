import { PencilOutlinedIcon } from '@orthly/ui';
import { FlossPalette, IconButton } from '@orthly/ui-primitives';
import type { MouseEventHandler } from 'react';
import React from 'react';

interface EditIconButtonProps {
    onClick: MouseEventHandler;
    styles?: React.CSSProperties;
    variant?: 'regular' | 'small'; // defaults to regular
}

/**
 * An icon button used throughout customer-facing checkout and edit experiences.
 *
 * Full history: https://github.com/orthly/Scanner/commits/develop/packages/fluoride/src/components/common/EditIconButton.tsx
 */
export const EditIconButton: React.VFC<EditIconButtonProps> = props => {
    const { onClick, variant, styles } = props;

    return (
        <IconButton
            style={{
                height: 32,
                width: 32,
                zIndex: 1000,
                borderRadius: 0,
                backgroundColor: FlossPalette.TAN,
                border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
                verticalAlign: 'middle',
                ...styles,
            }}
            onClick={onClick}
        >
            <PencilOutlinedIcon
                style={{
                    height: variant === 'small' ? 16 : 24,
                    width: variant === 'small' ? 16 : 24,
                    color: FlossPalette.STAR_GRASS,
                }}
            />
        </IconButton>
    );
};
