import { useUpdateNotifier, UpdateNotifierSignal } from '../../update-notifier';
import { DesignerSetStatusDialog } from './DesignerStatusDialog';
import { DesignerStatusIcon } from './DesignerStatusIcon';
import { DesignerStatusTimeoutProvider } from './DesignerStatusTimeoutProvider';
import { DesignerTaskAssignmentNotifications } from './DesignerTaskAssignmentNotifications';
import { useDesignStaffDetailedStatusQuery, DesignStaffDetailedStatusDocument } from '@orthly/graphql-react';
import { useGetUserQuery } from '@orthly/graphql-react';
import { LabsGqlDesignStaffStatus } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import type { NavigationEntry } from '@orthly/ui';
import {
    FlossPalette,
    type SvgIconProps,
    Grid,
    createStyles,
    makeStyles,
    Tooltip,
    CircleIcon,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';

export const DesignerStatusIndicatorConfig: Record<
    LabsGqlDesignStaffStatus,
    { title: string; color: string; tip: string; tagline: string }
> = {
    Inactive: {
        title: 'Not accepting new cases',
        color: FlossPalette.GRAY,
        tip: 'You are inactive. Designs will not automatically be assigned to you.',
        tagline: 'Stop & re-assign cases',
    },
    Offline: {
        title: 'Not accepting new cases',
        color: FlossPalette.GRAY,
        tip: 'You are offline. Designs will not automatically be assigned to you.',
        tagline: 'Stop & re-assign cases',
    },
    Active: {
        title: 'Accepting new cases',
        color: FlossPalette.PRIMARY_FOREGROUND,
        tip: 'You are active. New designs will be automatically assigned to you.',
        tagline: 'Resume case assignment',
    },
    Draining: {
        title: 'Winding Down - case assignment paused',
        color: FlossPalette.SECONDARY_FOREGROUND,
        tip: 'You are winding down. Designs already assigned to you will remain in your queue, but no new designs will be automatically assigned to you.',
        tagline: 'Pause case assignment to prepare for logging out',
    },
    Paused: {
        title: 'Winding Down - case assignment paused',
        color: FlossPalette.SECONDARY_FOREGROUND,
        tip: 'You are winding down. Designs already assigned to you will remain in your queue, but no new designs will be automatically assigned to you.',
        tagline: 'Pause case assignment to prepare for logging out',
    },
    Away: {
        title: 'case assignment paused',
        color: FlossPalette.WARNING,
        tip: "You are away. Designs will remain in your queue but no new designs will be assigned while you're out.",
        tagline: 'Pause case assignment to take a break',
    },
    Offboarded: {
        title: 'Offboarded',
        color: FlossPalette.REVIEW_RED,
        tip: 'Offboarded',
        tagline: 'Offboarded',
    },
};

export type DesignStatusIndicatorProps = { status: LabsGqlDesignStaffStatus } & SvgIconProps;
export const DesignerStatusIndicator: React.FC<DesignStatusIndicatorProps> = ({ status, ...props }) => {
    const viewConfig = DesignerStatusIndicatorConfig[status];
    return (
        <Tooltip title={viewConfig.title}>
            <CircleIcon htmlColor={viewConfig.color} {...props} />
        </Tooltip>
    );
};

function getUserInitials(firstName?: string, lastName?: string): string {
    return _.compact([firstName, lastName])
        .map(n => n[0])
        .join('');
}

const useStyles = makeStyles(() =>
    createStyles({
        iconContainer: {
            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
            padding: `10px 0px 10px 15px`,
        },
        root: {
            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
            justifyContent: 'center',
            alignItems: 'center',
            padding: 4,
            cursor: 'pointer',
            '&:hover': { background: FlossPalette.DARK_TAN },
        },
    }),
);

const DesignerStatusNavContainer: React.FC = () => {
    const session = useSession();
    const user_id = session?.user_id ?? '';
    const { data: userData } = useGetUserQuery({
        variables: { id: user_id },
    });

    const user = userData?.getUser;
    const initials = getUserInitials(user?.first_name, user?.last_name);

    const styles = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const { data: statusQueryData, refetch: refetchStatusQuery } = useDesignStaffDetailedStatusQuery({
        variables: { user_id },
        onError: error => {
            enqueueSnackbar(String(error), {
                variant: 'error',
            });
        },
    });

    useUpdateNotifier(
        UpdateNotifierSignal.onUpdate('DesignStaff', user_id).refetch([DesignStaffDetailedStatusDocument]),
    );

    React.useEffect(() => {
        if (statusQueryData?.designStaffDetailedStatus.status) {
            const interval = setInterval(() => refetchStatusQuery(), 20 * 60_000);
            return () => clearInterval(interval);
        }
    }, [statusQueryData, refetchStatusQuery]);

    const {
        status: designerStatus,
        assignable_task_types,
        task_capabilities,
        focus_areas,
    } = React.useMemo(() => ({ ...statusQueryData?.designStaffDetailedStatus }), [statusQueryData]);

    const viewConfig = React.useMemo(() => {
        if (!designerStatus) {
            return null;
        }
        return DesignerStatusIndicatorConfig[designerStatus];
    }, [designerStatus]);

    const [isDialogOpen, setDialogOpen] = React.useState(false);

    if (!viewConfig || !assignable_task_types || !task_capabilities) {
        return null;
    }

    return (
        <>
            <Tooltip title={viewConfig.tip}>
                <Grid onClick={() => setDialogOpen(true)} className={styles.iconContainer}>
                    <DesignerStatusIcon initials={initials} badgeColor={viewConfig.color} />
                </Grid>
            </Tooltip>
            {designerStatus && (
                <DesignerSetStatusDialog
                    open={isDialogOpen}
                    setOpen={setDialogOpen}
                    onClose={() => refetchStatusQuery()}
                    user_id={user_id}
                    currentStatus={designerStatus}
                    assignableTaskTypes={assignable_task_types}
                    designerCapabilities={task_capabilities}
                    initials={initials}
                    badgeColor={viewConfig.color}
                    focusAreas={focus_areas ?? []}
                />
            )}
            <DesignerStatusTimeoutProvider
                userId={user_id}
                assignments={statusQueryData?.designStaffDetailedStatus.auto_assignments}
                refetch={refetchStatusQuery}
            />
            {designerStatus === LabsGqlDesignStaffStatus.Active && (
                <DesignerTaskAssignmentNotifications
                    autoAssignments={statusQueryData?.designStaffDetailedStatus.auto_assignments}
                />
            )}
        </>
    );
};

export function useDesignerStatusNavItem(): NavigationEntry {
    return {
        onClick: () => {},
        title: '',
        active: false,
        Container: DesignerStatusNavContainer,
    };
}
