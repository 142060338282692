import type { LabsGqlSetDoctorPreferencesMutationVariables } from '@orthly/graphql-operations';
import { useSetDoctorPreferencesMutation } from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';

type PreferenceVars = LabsGqlSetDoctorPreferencesMutationVariables['data'];

export const useUpdatePreference = () => {
    const [mtn] = useSetDoctorPreferencesMutation();
    const mtnSubmitter = (data: PreferenceVars) => mtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [PreferenceVars]>(mtnSubmitter, {
        closeOnComplete: true,
    });

    return { submit, submitting };
};
