import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { CheckoutTextField } from '@orthly/dentin';
import type { ICustomFieldSubmission, ItemMetafieldV2 } from '@orthly/items';
import { ItemMetafieldV2Utils } from '@orthly/items';
import { SimpleSelect } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

interface ItemEditorV2PreferenceFieldProps extends ItemEditorV2BaseProps {
    field: ItemMetafieldV2;
    isInternal: boolean;
    // pass null while still loading (prevents field default from being set until loaded)
    doctorPreferences: ICustomFieldSubmission[] | null;
}

export const ItemEditorV2PreferenceField: React.VFC<ItemEditorV2PreferenceFieldProps> = ({
    item,
    updateItem,
    showError,
    field,
    isInternal,
    doctorPreferences,
    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const value = item.preference_fields.find(pref => pref.field_id === field.id)?.value;

    const setValue = React.useCallback(
        (val: string | boolean | number) => {
            updateItem({
                name: 'preference_field',
                payload: { field_id: field.id, value: val, display_name: field.label },
            });
        },
        [updateItem, field.id, field.label],
    );

    React.useEffect(() => {
        // if we have a value then there's no reason to set a default
        // if dr prefs is null, that means they haven't loaded, so we'll wait until they are
        if (value === undefined && doctorPreferences) {
            // first, try to use the value from dr preferences when it exists
            // otherwise, fall back to the field default value if that exists
            const drPref = doctorPreferences.find(pref => pref.field_id === field.id);
            if (drPref && !ItemMetafieldV2Utils.isDeprecatedSelectField(field, drPref.value)) {
                setValue(drPref.value);
            } else if (field.default_value !== undefined) {
                setValue(field.default_value);
            }
        }
    }, [value, doctorPreferences, field, setValue]);

    const label = field.optional ? `${field.label} (Optional)` : field.label;

    if (field.type === 'select') {
        const rawOptions = field.options ?? [];
        const options = isInternal
            ? rawOptions.map(o =>
                  o.internal_display_label ? { ...o, label: `${o.label} (${o.internal_display_label})` } : o,
              )
            : rawOptions;

        return (
            <SimpleSelect
                label={label}
                helperText={field.helper_text}
                options={options}
                value={_.isString(value) ? value : undefined}
                onChange={val => val && setValue(val)}
                errorText={showError && !field.optional && !_.isString(value) ? 'This field is required' : undefined}
            />
        );
    }

    if (field.type === 'boolean') {
        return (
            <SimpleSelect
                label={label}
                helperText={field.helper_text}
                options={[
                    { value: 'Yes', label: 'Yes' },
                    { value: 'No', label: 'No' },
                ]}
                // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                // eslint-disable-next-line no-nested-ternary
                value={_.isBoolean(value) ? (value ? 'Yes' : 'No') : undefined}
                onChange={val => val && setValue(val === 'Yes')}
                errorText={showError && !field.optional && !_.isBoolean(value) ? 'This field is required' : undefined}
            />
        );
    }

    if (field.type === 'text') {
        const emptyRequiredField = !field.optional && !_.isString(value);
        const canValidateField = _.isString(value) && field.validator;
        const validationError = showError && canValidateField ? field.validator?.(value)?.error : undefined;

        return (
            <CheckoutTextField
                label={label}
                helperText={field.helper_text}
                value={_.isString(value) ? value : undefined}
                // we already specify whether it's optional or not so we don't need the additional required treatment
                required={false}
                onChange={setValue}
                error={showError && emptyRequiredField ? 'This field is required' : validationError}
            />
        );
    }

    // unreachable
    return null;
};
