import { ProductImageWrapper } from '@orthly/dentin';
import type { ProductUnitType } from '@orthly/items';
import { FlossPalette, Text, stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    summarySectionHeaderItem: {
        paddingRight: 16,
    },
}));

interface SummarySectionHeaderProps {
    title: string;
    productUnitType?: ProductUnitType;
    hideImageBorder?: boolean;
}

/**
 * Header for an item or item group's summary, with an icon representing the item and the item's title.
 *
 * This is cribbed from flouride's `SummarySectionHeader` with some modifications.
 **/
export const SummarySectionHeader: React.VFC<SummarySectionHeaderProps> = ({
    title,
    productUnitType,
    hideImageBorder,
}) => {
    const classes = useStyles();

    return (
        <Grid container item direction={'row'} alignItems={'center'}>
            {productUnitType && (
                <Grid item className={classes.summarySectionHeaderItem}>
                    <ProductImageWrapper
                        backgroundColor={FlossPalette.WHITE}
                        product={productUnitType}
                        size={32}
                        wrapperStyle={
                            !hideImageBorder
                                ? {
                                      outlineColor: FlossPalette.DARK_TAN,
                                      outlineStyle: 'solid',
                                      outlineWidth: 1,
                                  }
                                : {}
                        }
                    />
                </Grid>
            )}
            <Grid item className={classes.summarySectionHeaderItem}>
                <Text variant={'h6'}>{title}</Text>
            </Grid>
        </Grid>
    );
};
