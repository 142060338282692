import type { IBaseItemData } from '../../types';
import { getHeaderForLabSlipItem } from '../../utils/shared.utils';
import { BaseItem } from './BaseItem';
import type { ISurgicalGuideItem } from '@orthly/items';
import { Text } from '@orthly/ui-primitives';
import React from 'react';

/**
 * Renders a "Surgical Guide" order item to display on
 * the Printable Slip
 * @param props The order item to render
 * @returns Rendered order item details for the order "Surgical Guide" item
 */
export const SurgicalGuideItem: React.VFC<{ item: ISurgicalGuideItem }> = ({ item }) => {
    return <BaseItem itemData={mapSurgicalGuideItemToPrintableSlipData(item)} />;
};

/**
 * Pull data needed for printable slip display into a standard data structure
 * @param item ISurgicalGuideItem order item to display
 * @returns Structured data for Surgical Guide item display
 */
function mapSurgicalGuideItemToPrintableSlipData(item: ISurgicalGuideItem): IBaseItemData {
    const headerLeft = getHeaderForLabSlipItem(item);
    return {
        headerLeft,
        itemFields: [
            {
                customComponent: (
                    <Text variant={'h6'}>See preferences block below for Surgical Guide configuration</Text>
                ),
            },
        ],
    };
}
