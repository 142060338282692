import { ReRunManufacturingFileProcessingButton } from './ReRunManufacturingFileProcessingButton.graphql';
import { CopyLinkIcon, OrthlyBrowserConfig, RootActionDialog } from '@orthly/ui';
import { Box, Button, Grid, IconButton, Tooltip, styled } from '@orthly/ui-primitives';
import { useCopyToClipboard } from '@orthly/veneer';
import type { SetStateAction } from 'react';
import React from 'react';

const GenerateLinkButtonContainer = styled(Grid)({
    justifyContent: 'flex-end',
    gap: 8,
    marginTop: 20,
});
const StyledLink = styled('a')(({ theme }) => ({
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover': {
        color: theme.palette.primary.dark,
        textDecoration: 'underline',
    },
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
}));

interface ActionsProps {
    setOpen: (open: boolean) => void;
    setLogLink: React.Dispatch<SetStateAction<string | undefined>>;
    orderId: string;
}

const Actions: React.VFC<ActionsProps> = ({ setOpen, setLogLink, orderId }) => {
    const handleSetLogLink = (orderId: string): void => {
        setLogLink(
            `https://console.cloud.google.com/logs/query;query=jsonPayload.namespace%3D%22ManufacturingFileProcessor%22%0AjsonPayload.data.jobData.labOrderId%3D%22${orderId}%22?referrer=search&project=${OrthlyBrowserConfig.isProduction ? 'dandy-production-1' : 'dandy-qa-1'}`,
        );
    };

    return (
        <GenerateLinkButtonContainer>
            <Button
                variant={'secondary'}
                onClick={() => {
                    setOpen(false);
                }}
                sx={{ marginRight: '10px' }}
            >
                Cancel
            </Button>
            <ReRunManufacturingFileProcessingButton orderId={orderId} onSubmit={handleSetLogLink} />
        </GenerateLinkButtonContainer>
    );
};

interface ReRunManufacturingFileProcessingModalProps {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    orderId: string;
}

export const ReRunManufacturingFileProcessingModal: React.FC<ReRunManufacturingFileProcessingModalProps> = ({
    open,
    setOpen,
    orderId,
}) => {
    const [logLink, setLogLink] = React.useState<string>();

    const copyToClipboard = useCopyToClipboard();

    const onClickCopy = React.useCallback(() => {
        copyToClipboard({
            text: logLink ?? '',
            successMessage: 'Google Logs Link Copied to Clipboard',
            errorMessage: 'Failed to copy link :(',
        });
    }, [copyToClipboard, logLink]);

    return (
        <RootActionDialog
            title={'Rerun Cam File Pipeline'}
            content={
                logLink && (
                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', width: '100%' }}>
                        <Tooltip title={'Open Google Logs'}>
                            <StyledLink href={logLink} target={'_blank'} rel={'noopener noreferrer'}>
                                Google Logs
                            </StyledLink>
                        </Tooltip>
                        <Tooltip title={'Copy Link'}>
                            <IconButton onClick={onClickCopy} size={'small'}>
                                <CopyLinkIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )
            }
            actions={<Actions setOpen={setOpen} setLogLink={setLogLink} orderId={orderId} />}
            open={open}
            setOpen={setOpen}
            loading={false}
            hideButton={true}
            showCloseButton
        />
    );
};
