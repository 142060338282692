import { DesignPrepBannerCommon } from '../DesignPrep/DesignPrepBannerCommon';
import { DesignFinishingRoot } from './DesignFinishingRoot';
import type { FinishingCallbacks } from './OrderState.hooks';
import type { MinimalOrder } from './Types';
import type { FragmentType } from '@orthly/graphql-inline-react';
import type { VeneerDesignFinishingDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import React from 'react';

interface StartBannerInternalProps {
    order: MinimalOrder;
    design: FragmentType<typeof VeneerDesignFinishingDesign_FragmentFragmentDoc>;
    disabled?: boolean;
    initiallyOpen?: boolean;
    callbacks: Omit<FinishingCallbacks, 'setWindowOpen'>;
}

export const DesignFinishingStartBannerInternal: React.VFC<StartBannerInternalProps> = props => {
    const { disabled, initiallyOpen, callbacks } = props;

    const [open, setOpen] = React.useState<boolean>(!!(initiallyOpen && !disabled));

    const onClick = () => {
        setOpen(true);
    };

    return (
        <>
            <DesignPrepBannerCommon
                leftLabel={'Dandy Finishing'}
                rightLabel={'Start'}
                variant={'primary'}
                onClick={onClick}
                disabled={disabled}
            />
            <DesignFinishingRoot
                {...props}
                open={open}
                callbacks={{
                    ...callbacks,
                    setWindowOpen: setOpen,
                }}
                initiallyOpen={initiallyOpen}
            />
        </>
    );
};
