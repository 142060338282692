import { CreditOrRefundFooter } from './CreditOrRefundFooter';
import { CreditOrRefundForm } from './CreditOrRefundForm';
import { CreditOrRefundHeader } from './CreditOrRefundHeader';
import { CreditOrRefundSubmitted } from './CreditOrRefundSubmitted';
import { useCreditOrRefundFormContext } from './providers/CreditOrRefundFormProvider.graphql';
import { FlossPalette, styled } from '@orthly/ui-primitives';
import React from 'react';

const Layout = styled('div')({
    display: 'flex',
    height: '100%',
    width: '520px',
    flexDirection: 'column',
    backgroundColor: FlossPalette.WHITE,
    position: 'relative',
});

export const CreditOrRefundSidebar: React.FC = () => {
    const { formState } = useCreditOrRefundFormContext();
    return (
        <Layout>
            <CreditOrRefundHeader />
            {formState.submitted ? <CreditOrRefundSubmitted /> : <CreditOrRefundForm />}
            <CreditOrRefundFooter />
        </Layout>
    );
};
