import { FlossPalette, Checkbox, Text, FormControlLabel, styled } from '@orthly/ui-primitives';
import React from 'react';

const Wrapper = styled('div')({
    marginTop: '16px',
    backgroundColor: FlossPalette.TAN,
    borderColor: FlossPalette.DARK_TAN,
    borderStyle: 'solid',
    borderRadius: 8,
    borderWidth: 2,
    overflow: 'hidden',
    flexDirection: 'column',
    padding: '16px',
});

const CaptionBlock = styled('p')({
    margin: '16px 0 0 0',
});

type Props = {
    requestWaxup: boolean;
    onChange: (requestWaxup: boolean) => void;
};

export const RefabDDPSelection: React.VFC<Props> = props => {
    const { requestWaxup, onChange } = props;

    const checkbox = <Checkbox sx={{ margin: '0' }} checked={requestWaxup} onChange={() => onChange(!requestWaxup)} />;
    const label = (
        <Text color={'BLACK'} variant={'body2'} medium>
            Review & approve design before fabrication?
        </Text>
    );

    return (
        <Wrapper>
            <FormControlLabel control={checkbox} label={label} />
            <CaptionBlock>
                <Text color={'DARK_GRAY'} variant={'caption'}>
                    Digital Design Previews let you review and approve 3D designs of your restorations before
                    fabrication to ensure they meet your standards. Use this tool to review proposed margin lines,
                    assess fit and function, and share feedback with your designer.
                </Text>
            </CaptionBlock>
        </Wrapper>
    );
};
