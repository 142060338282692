import { useFirebasePreview } from '../../hooks/useFirebaseDownload';
import { useFirebaseFileDownload } from '../../hooks/useFirebaseFileDownload.graphql';
import { isImagePath } from '../DandyLightbox/DandyLightbox';
import { LoadBlocker } from '@orthly/ui';
import {
    stylesFactory,
    FlossPalette,
    useScreenIsMobile,
    Button,
    Grid,
    ZoomInIcon,
    Typography,
} from '@orthly/ui-primitives';
import React from 'react';

const useImageStyles = stylesFactory(() => ({
    root: {
        '&:hover': {
            background: FlossPalette.STAR_GRASS,
            cursor: 'pointer',
        },
        '&:hover img': { opacity: 0.4 },
        '&:hover svg': { visibility: 'visible' },
        borderRadius: 16,
    },
    img: {
        width: '100%',
        height: '100%',
        borderRadius: 16,
    },
    zoom: {
        position: 'absolute',
        right: 8,
        bottom: 8,
    },
}));

export interface DandyChatAttachmentProps {
    attachment_url: string;
    description?: string | null;
    fromCurrentUser: boolean;
    setSelectedAttachmentPreview?: (attachment: string | undefined) => void;
}

export const DandyAttachmentZoomIcon: React.FC = () => {
    const imageStyles = useImageStyles();
    return (
        <ZoomInIcon
            preserveAspectRatio={'xMidYMin'}
            color={'primary'}
            style={{
                color: FlossPalette.STAR_GRASS,
                background: 'white',
                borderRadius: 36,
                padding: 4,
                height: 36,
                width: 36,
            }}
            className={imageStyles.zoom}
        />
    );
};

export const DandyChatAttachment: React.FC<DandyChatAttachmentProps> = props => {
    const imageStyles = useImageStyles();
    const fileName = props.attachment_url.replace(/\?.*$/, '').split('/').slice(-1)[0];
    const downloadAttachmentFromFirebase = useFirebaseFileDownload(props.attachment_url || '', fileName);
    const downloadAttachment = React.useCallback(() => {
        if (props.attachment_url.startsWith('https://') || props.attachment_url.startsWith('http://')) {
            window.open(props.attachment_url, '_blank', 'noreferrer noopener');
        } else {
            void downloadAttachmentFromFirebase.execute();
        }
    }, [downloadAttachmentFromFirebase, props.attachment_url]);
    const isImage = fileName && isImagePath(fileName);
    const { result: imgPreviewSrc, loading: srcLoading } = useFirebasePreview(
        isImage ? props.attachment_url : undefined,
    );
    const isMobile = useScreenIsMobile();
    const [imgLoading, setImgLoading] = React.useState<boolean>(true);
    const loading = imgLoading || srcLoading;
    const { description } = props;
    return (
        <Grid container direction={'column'} alignItems={'flex-start'}>
            {description && (
                <Typography component={'div'} variant={'caption'} color={'inherit'}>
                    <br />
                    {description}
                </Typography>
            )}
            {isImage ? (
                <LoadBlocker
                    blocking={loading}
                    ContainerProps={{ style: { ...(loading ? { minHeight: 200, minWidth: 200 } : {}), paddingTop: 8 } }}
                >
                    {imgPreviewSrc && (
                        <div
                            className={imageStyles.root}
                            onClick={() => {
                                props.setSelectedAttachmentPreview?.(props.attachment_url);
                            }}
                        >
                            <img
                                className={imageStyles.img}
                                alt={fileName ?? 'preview'}
                                src={imgPreviewSrc}
                                onLoadStart={() => setImgLoading(true)}
                                onLoad={() => {
                                    setImgLoading(false);
                                }}
                            />
                            <DandyAttachmentZoomIcon />
                        </div>
                    )}
                </LoadBlocker>
            ) : (
                <Typography component={'div'} variant={'overline'} color={'inherit'}>
                    {fileName}
                </Typography>
            )}
            {!isMobile && (
                <Button
                    variant={'text'}
                    startIcon={'DownloadIcon'}
                    style={{
                        color: props.fromCurrentUser ? '#fff' : FlossPalette.BLACK,
                        float: 'left',
                        paddingLeft: 0,
                    }}
                    onClick={() => downloadAttachment()}
                />
            )}
        </Grid>
    );
};
