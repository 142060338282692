import { useOpenOrderDetailOps } from '../../../../../../utils/router/useOpenOrderDetail';
import { EditRefabricationAction } from '../actions/EditRefabricationAction';
import { UnlinkRefabricationAction } from '../actions/UnlinkRefabricationAction';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrder } from '@orthly/graphql-react';
import { OrderDetailRefabBlock } from '@orthly/veneer';
import React from 'react';

interface AdminOrderDetailRefabBlockProps {
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
}

export const AdminOrderDetailRefabBlock: React.VFC<AdminOrderDetailRefabBlockProps> = ({ order, refetchOrder }) => {
    const openOrder = useOpenOrderDetailOps();

    const { order: originalOrder, refetch: refetchOriginalOrder } = useOrder(order.original_order_id);

    const [refabEditOpen, setRefabEditOpen] = React.useState<boolean>(false);
    const [refabUnlinkOpen, setRefabUnlinkOpen] = React.useState<boolean>(false);
    const originalOrderHasOldRefab = !!originalOrder?.refab_reasons?.length;

    return (
        <>
            {order.original_order_id && originalOrder && originalOrderHasOldRefab && (
                <EditRefabricationAction
                    refetchOrder={() => {
                        void refetchOriginalOrder();
                        return refetchOrder();
                    }}
                    originalOrderId={originalOrder.id}
                    existingReasons={originalOrder.refab_reasons}
                    currentOrderId={order.id}
                    existingNotes={order.notes_for_refabrication}
                    controls={{ open: refabEditOpen, setOpen: setRefabEditOpen }}
                    hideButton
                />
            )}
            {order.original_order_id && (
                <UnlinkRefabricationAction
                    order={order}
                    refetchOrder={refetchOrder}
                    originalOrderId={order.original_order_id}
                    controls={{ open: refabUnlinkOpen, setOpen: setRefabUnlinkOpen }}
                    hideButton
                />
            )}
            <OrderDetailRefabBlock
                order={order}
                openOrder={openOrder}
                extraActions={
                    order.original_order_id
                        ? [
                              {
                                  label: 'Unlink refab',
                                  onClick: () => setRefabUnlinkOpen(true),
                              },
                              ...(originalOrderHasOldRefab
                                  ? [
                                        {
                                            label: 'Edit refab',
                                            onClick: () => setRefabEditOpen(true),
                                        },
                                    ]
                                  : []),
                          ]
                        : []
                }
                refetchOrder={refetchOrder}
                showRefabEditChips={true}
            />
        </>
    );
};
