import { useCreditOrRefundFormContext } from './providers/CreditOrRefundFormProvider.graphql';
import { SimpleDatePicker } from '@orthly/ui';
import { FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const Stacked = styled('div')<{ isInline: boolean }>(({ isInline }) => ({
    width: '100%',
    display: 'flex',
    flex: isInline ? 1 : undefined,
    flexDirection: 'column',
}));

const StyledDatePicker = styled(SimpleDatePicker)({
    marginTop: '11px',
    '.MuiFormLabel-root': {
        '&.Mui-disabled': {
            backgroundColor: FlossPalette.DARK_TAN,
        },
    },
    '.MuiInputBase-root': {
        // It seems like in flossInputTheme, border colors are #E4E4E4 which
        // doesn't have a corresponding value in our FlossPalette object.
        // Using the hardcoded hex value to match the other input borders in the form
        border: '1px solid #E4E4E4',
        paddingBottom: '5px',
        '&.Mui-disabled': {
            '&:hover': {
                border: '1px solid #E4E4E4',
            },
            color: FlossPalette.STROKE_LIGHT,
            backgroundColor: FlossPalette.DARK_TAN,
        },
    },
});

export const CreditExpirationInput: React.FC = () => {
    const { formState, dispatchFormStateAction, viewOnly } = useCreditOrRefundFormContext();
    return (
        <Stacked isInline={formState.attributed}>
            <Text variant={'body2'} medium>
                Select an Expiration for Credit
            </Text>
            <StyledDatePicker
                fullWidth={true}
                label={'Select a date'}
                value={formState.expires ? new Date(formState.expires) : null}
                disablePast={true}
                disabled={viewOnly}
                onChange={date => {
                    dispatchFormStateAction({ type: 'SET_EXPIRATION_DATE', expires: date });
                }}
            />
        </Stacked>
    );
};
