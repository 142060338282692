import { PRINTABLE_SLIP_DARK_COLOR } from '../../styles/colors';
import { Box } from '@orthly/ui-primitives';
import React from 'react';

/**
 * Renders the divider to distinguish different section of the Printable Slip
 * @returns Styled divider to use to separate sections of the Printable Slip
 */
export const PrintableSlipSectionDivider: React.VFC<any> = () => {
    return (
        <Box
            height={'.15rem'}
            maxHeight={'.15rem'}
            bgcolor={PRINTABLE_SLIP_DARK_COLOR}
            marginTop={'.5rem'}
            marginBottom={'.1rem'}
        />
    );
};
