import type { ScanReviewPartialScene } from '../ScanReviewSceneTypes';
import type { ScanReviewViewManager } from '../ScanReviewViewTypes';
import { ScanReviewMarginMarkingToolController } from './ScanReviewMarginMarkingToolController';
import type { ScanReviewMarginLineEditor } from './ScanReviewMarginMarkingToolServices';
import { ScanReviewMarginMarkingToolLiveObjectsProvider } from './ScanReviewMarginMarkingToolServices';
import type { ScanReviewEditedMarginLine } from './ScanReviewMarginMarkingToolTypes';
import { ensureMeshIndex } from '@orthly/forceps';
import { type ToothNumber, ToothUtils } from '@orthly/items';
import constate from 'constate';
import React from 'react';

interface ScanReviewMarginMarkingViewProps {
    scene: ScanReviewPartialScene;
    viewManager: ScanReviewViewManager;
    toothNumber?: ToothNumber;
    marginLineEditor?: ScanReviewMarginLineEditor;
    onMarginUpdate?: (editedMarginLine: ScanReviewEditedMarginLine) => void;
}

function useScanReviewMarginMarkingViewApp({
    scene,
    viewManager,
    marginLineEditor,
    toothNumber,
    onMarginUpdate,
}: ScanReviewMarginMarkingViewProps) {
    const geometry = React.useMemo(() => {
        const isLowerTooth = toothNumber && ToothUtils.toothIsLower(toothNumber);
        if (isLowerTooth) {
            return scene.lowerJaw?.scanMesh.geometry;
        } else {
            return scene.upperJaw?.scanMesh.geometry;
        }
    }, [toothNumber, scene.lowerJaw?.scanMesh.geometry, scene.upperJaw?.scanMesh.geometry]);

    const indexBVH = React.useMemo(() => {
        if (geometry) {
            return ensureMeshIndex(geometry);
        }
    }, [geometry]);

    const liveObjectsProvider = React.useMemo(
        () =>
            indexBVH &&
            marginLineEditor &&
            new ScanReviewMarginMarkingToolLiveObjectsProvider(indexBVH, marginLineEditor),
        [marginLineEditor, indexBVH],
    );

    const toolController = React.useMemo(
        () =>
            ScanReviewMarginMarkingToolController.makeController(
                viewManager,
                marginLineEditor,
                toothNumber,
                liveObjectsProvider,
                onMarginUpdate,
            ),
        [toothNumber, marginLineEditor, liveObjectsProvider, viewManager, onMarginUpdate],
    );

    return {
        scene,
        viewManager,
        toolController,
    };
}

export const [ScanReviewMarginMarkingViewAppProvider, useScanReviewMarginMarkingViewContext] = constate(
    useScanReviewMarginMarkingViewApp,
);
