import type { CanvasObject } from './Objects/CanvasObject';
import { HandleObject } from './Objects/HandleObject';
import type { LineType } from './Objects/LineObject';
import type { ShapeType } from './Objects/ShapeObject';
import { TextObject } from './Objects/TextObject';
import { BrushCreationTool } from './Tools/BrushCreationTool';
import type { ActionEntry, CanvasTool } from './Tools/CanvasTool';
import { LineCreationTool } from './Tools/LineCreationTool';
import { PointerTool } from './Tools/PointerTool';
import { ShapeCreationTool } from './Tools/ShapeCreationTool';
import type { Point } from './Util';
import { canvasToBlob, CANVAS_SCALE } from './Util';

type SetStateAction<S> = S | ((prevState: S) => S);
function newState<S>(prevState: S, action: SetStateAction<S>): S {
    return typeof action === 'function' ? (action as Function)(prevState) : action;
}

export type ToolType = LineType | ShapeType | 'brush' | 'pointer';

export interface ToolState {
    readonly color: string;
    readonly toolType: ToolType;
    readonly brushSize: number;
}

export interface UndoState {
    canUndo: boolean;
    canRedo: boolean;
}

export class CanvasController {
    private canvas?: HTMLCanvasElement;
    private context?: CanvasRenderingContext2D;
    private hitTestCanvas: HTMLCanvasElement;
    private hitTestContext?: CanvasRenderingContext2D;

    // Objects are shapes, lines, etc. that have been drawn and may be manipulated.
    private objects: CanvasObject[] = [];
    private selectedObject: CanvasObject | null = null;

    // The handles rendered for the selected object.
    private handles: CanvasObject[] = [];

    // Keeps track of whether the user is holding down the mouse button.
    private isDragging = false;

    private undoStack: ActionEntry[] = [];
    private redoStack: ActionEntry[] = [];

    static readonly initialToolState: ToolState = {
        color: 'black',
        toolType: 'pointer',
        brushSize: 3,
    };

    private toolState: ToolState;
    private currentTool: CanvasTool = new PointerTool(this.hitTest.bind(this));

    onToolStateChange?: (toolState: ToolState) => void;
    onUndoStateChange?: (undoState: UndoState) => void;

    private backgroundImage?: HTMLImageElement;
    private backgroundColor?: string;

    // Watches for changes to the canvas' width or height, in order to reset scale and update
    // the hit-testing canvas accordingly.
    private mutationObserver: MutationObserver;

    constructor(toolState: ToolState = CanvasController.initialToolState) {
        this.toolState = toolState;
        this.setToolState(toolState);

        const hitTestCanvas = document.createElement('canvas');
        hitTestCanvas.style.position = 'absolute';
        this.hitTestContext = hitTestCanvas.getContext('2d', { willReadFrequently: true }) ?? undefined;
        this.hitTestCanvas = hitTestCanvas;

        this.mutationObserver = new MutationObserver(list => {
            const changedSize = list.some(
                r => r.type === 'attributes' && (r.attributeName === 'width' || r.attributeName === 'height'),
            );
            if (changedSize) {
                this.setUpSizeAndScale();
            }
        });
    }

    setCanvas(canvas: HTMLCanvasElement) {
        if (this.canvas === canvas) {
            return;
        }
        this.canvas = canvas;
        this.context = canvas.getContext('2d') ?? undefined;
        canvas.onpointerdown = this.handlePointerDown.bind(this);
        canvas.onpointermove = this.handlePointerMove.bind(this);
        canvas.onpointerup = this.handlePointerUp.bind(this);
        canvas.onkeydown = this.handleKeyDown.bind(this);
        canvas.onblur = this.handleFocusOut.bind(this);

        this.setUpSizeAndScale();
        this.mutationObserver.disconnect();
        this.mutationObserver.observe(canvas, { attributes: true });
    }

    private setUpSizeAndScale() {
        if (this.canvas && this.hitTestCanvas) {
            this.hitTestCanvas.width = this.canvas.width;
            this.hitTestCanvas.height = this.canvas.height;
        }
        this.context?.setTransform(CANVAS_SCALE, 0, 0, CANVAS_SCALE, 0, 0);
        this.hitTestContext?.setTransform(CANVAS_SCALE, 0, 0, CANVAS_SCALE, 0, 0);

        this.render();
    }

    undoState(): UndoState {
        return { canUndo: this.undoStack.length > 0, canRedo: this.redoStack.length > 0 };
    }

    undo() {
        const entry = this.undoStack.pop();

        if (!entry) {
            return;
        }
        this.redoStack.push(entry);
        entry.undo(this.objects);
        if (this.selectedObject && !this.objects.includes(this.selectedObject)) {
            this.selectedObject = null;
        }
        this.render();
        this.onUndoStateChange?.(this.undoState());
    }

    redo() {
        const entry = this.redoStack.pop();
        if (!entry) {
            return;
        }
        this.undoStack.push(entry);
        entry.do(this.objects);
        this.render();
        this.onUndoStateChange?.(this.undoState());
    }

    clear() {
        this.objects = [];
        this.redoStack = [];
        this.undoStack = [];
        this.render();
        this.onUndoStateChange?.(this.undoState());
    }

    getToolState(): ToolState {
        return this.toolState;
    }

    setToolState(action: SetStateAction<ToolState>) {
        const toolState = newState(this.toolState, action);
        this.toolState = toolState;

        const type = toolState.toolType;
        const color = toolState.color;

        this.currentTool = (() => {
            switch (type) {
                case 'brush':
                    return new BrushCreationTool({ color, size: toolState.brushSize });
                case 'rect':
                case 'oval':
                    return new ShapeCreationTool({ type, color });
                case 'pointer':
                    return new PointerTool(this.hitTest.bind(this));
                case 'line':
                case 'arrow':
                    return new LineCreationTool({ type, color });
            }
        })();
        this.onToolStateChange?.(toolState);
        this.render();
    }

    setBackgroundImage(imageUrl: string, callback: (width: number, height: number) => void) {
        const imageElement = new Image();
        imageElement.crossOrigin = 'anonymous';
        imageElement.onload = () => {
            callback(imageElement.width, imageElement.height);
            this.render();
        };
        this.backgroundImage = imageElement;
        imageElement.src = imageUrl;
    }

    setBackgroundColor(color: string) {
        this.backgroundColor = color;
    }

    toBlob(): Promise<Blob | null> {
        return canvasToBlob(this.canvas);
    }

    private handlePointerDown(e: PointerEvent) {
        if (!this.canvas) {
            return;
        }
        // iOS Safari does not automatically focus elements on click. Focusing the element
        // now allows it to receive a `blur` event when the user clicks elsewhere.
        this.canvas.focus();
        this.isDragging = true;

        this.canvas.setPointerCapture(e.pointerId);

        const pt: Point = [e.offsetX, e.offsetY];
        if (this.currentTool) {
            const { cursor, selectedObject } = this.currentTool.handlePointerDown(pt);
            if (cursor) {
                this.canvas.style.cursor = cursor;
            }
            if (selectedObject !== undefined) {
                this.selectedObject = selectedObject;
            }
            this.render();
        }
    }

    private handlePointerMove(e: PointerEvent) {
        if (!this.canvas) {
            return;
        }
        const pt: Point = [e.offsetX, e.offsetY];

        this.canvas.style.cursor = this.currentTool.cursorAtPoint?.(pt) ?? 'default';

        if (this.isDragging && this.currentTool) {
            this.currentTool.handlePointerMoved(pt);
            this.render();
        }
    }

    private handlePointerUp(e: PointerEvent) {
        if (!this.canvas) {
            return;
        }
        this.isDragging = false;
        this.canvas.releasePointerCapture(e.pointerId);
        const pt: Point = [e.offsetX, e.offsetY];

        if (this.currentTool) {
            const action = this.currentTool.handlePointerUp(pt, e);
            if (action) {
                this.commit(action);

                if (action.resetToPointer) {
                    this.setToolState({ ...this.toolState, toolType: 'pointer' });
                    this.selectedObject = this.objects[this.objects.length - 1] ?? null;
                }
            }

            // Make sure hit-testing canvas is up to date.
            this.render();
            this.canvas.style.cursor = this.currentTool.cursorAtPoint?.(pt) ?? 'default';
        }
    }

    private handleKeyDown(e: KeyboardEvent) {
        if ((e.code === 'Backspace' || e.code === 'Delete') && this.currentTool instanceof PointerTool) {
            const selectedObject = this.selectedObject;
            const selectedIndex = this.objects.findIndex(o => o === selectedObject);
            if (selectedObject && selectedIndex >= 0) {
                this.commit({
                    do: () => {
                        this.objects.splice(selectedIndex, 1);
                    },
                    undo: () => {
                        this.objects.splice(selectedIndex, 0, selectedObject);
                    },
                });
                this.selectedObject = null;
                this.render();
            }
        }
    }

    private handleFocusOut(_e: FocusEvent) {
        if (this.currentTool instanceof PointerTool) {
            this.selectedObject = null;
            this.render();
        }
    }

    private hitTest(mousePt: Point): CanvasObject | null {
        const hitTestCtx = this.hitTestCanvas?.getContext('2d');
        if (hitTestCtx) {
            const pixel = hitTestCtx.getImageData(mousePt[0] * CANVAS_SCALE, mousePt[1] * CANVAS_SCALE, 1, 1);

            // Red channel contains the index for objects. Valid if alpha == 255.
            const index = pixel.data[0] as number;
            const alpha = pixel.data[3] as number;
            if (alpha === 255) {
                return [...this.objects, ...this.handles][index] ?? null;
            }
        }
        return null;
    }

    private render() {
        const { canvas, context: ctx } = this;
        if (!ctx || !canvas) {
            return;
        }
        ctx.fillStyle = this.backgroundColor ?? 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        if (this.backgroundImage) {
            ctx.drawImage(this.backgroundImage, 0, 0);
        }

        this.handles = this.selectedObject ? this.selectedObject.handles().map(h => new HandleObject(h)) : [];
        this.objects.concat(this.handles).forEach(o => o.draw(ctx));
        this.currentTool.draw(ctx);

        if (!this.isDragging) {
            this.renderHitTest();
        }
    }

    private renderHitTest() {
        const { hitTestCanvas: canvas, hitTestContext: ctx } = this;

        if (!canvas || !ctx) {
            return;
        }
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        this.objects.concat(this.handles).forEach((o, i) => o.draw(ctx, `rgb(${i}, 0, 0)`));
    }

    commitTextObject(text: string) {
        this.commit({
            resetToPointer: true,
            do: stack => stack.push(new TextObject({ text, color: this.toolState.color }, [64, 64], [192, 128])),
            undo: stack => stack.pop(),
        });
        this.selectedObject = this.objects[this.objects.length - 1] ?? null;
        this.render();
    }

    private commit(entry: ActionEntry) {
        const { canRedo } = this.undoState();
        entry.do(this.objects);
        this.undoStack.push(entry);
        this.redoStack = [];

        if (this.undoStack.length === 1 || canRedo) {
            this.onUndoStateChange?.(this.undoState());
        }
    }
}
