import { useCreditOrRefundFormContext } from './providers/CreditOrRefundFormProvider.graphql';
import { SimpleInput } from '@orthly/ui';
import { FlossPalette, Icon, InputAdornment, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const RequiredLabel = styled(Text)({
    '&::after': {
        color: FlossPalette.ATTENTION,
        content: '"*"',
    },
});

const Stacked = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
});

const StyledInput = styled(SimpleInput)({
    // Get rid of the up and down arrows for numeric input field
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        display: 'none',
    },
    // Get rid of the up and down arrows for numeric input field
    '& input[type=number]': {
        MozAppearance: 'textfield',
    },
});

export const CreditAmountInput: React.FC = () => {
    const { formState, dispatchFormStateAction } = useCreditOrRefundFormContext();

    if (formState.action === 'refund' || formState.attributed) {
        return null;
    }

    return (
        <Stacked>
            <RequiredLabel variant={'body2'} medium>
                Enter Amount
            </RequiredLabel>
            <StyledInput
                flossInputConfig={{ backgroundColor: 'gray', bordered: true }}
                onChange={value => {
                    dispatchFormStateAction({ type: 'SET_AMOUNT_DOLLARS', amountDollars: value ?? '' });
                }}
                value={`${formState.amountDollars}`}
                label={''}
                placeholder={'Enter Amount'}
                TextFieldProps={{
                    InputProps: {
                        type: 'number',
                        startAdornment: (
                            <InputAdornment position={'start'}>
                                <Icon icon={'CoinIcon'} />
                            </InputAdornment>
                        ),
                    },
                }}
            />
        </Stacked>
    );
};
