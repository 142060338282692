import type { LimitedAppearanceFilterBools, ModelAppearance } from '@orthly/dentin';
import { applyAppearanceFilter, INITIAL_APPEARANCE_FILTER, MODEL_VIEWER_INITIAL_APPEARANCE } from '@orthly/dentin';
import React from 'react';

export const useAppearance = () => {
    const [appearance, setAppearance] = React.useState<ModelAppearance>(MODEL_VIEWER_INITIAL_APPEARANCE);
    const [appearanceFilters, setAppearanceFilters] =
        React.useState<LimitedAppearanceFilterBools>(INITIAL_APPEARANCE_FILTER);

    const toggleAppearanceFilters = React.useCallback(
        (keys: (keyof LimitedAppearanceFilterBools)[]) =>
            setAppearanceFilters(currentFilters => {
                const nextFilters = { ...currentFilters };
                for (const key of keys) {
                    nextFilters[key] = !currentFilters[key];
                }
                setAppearance(currentAppearance => applyAppearanceFilter(currentAppearance, nextFilters));
                return nextFilters;
            }),
        [],
    );

    return {
        appearance,
        setAppearance,
        appearanceFilters,
        setAppearanceFilters,
        toggleAppearanceFilters,
    };
};
