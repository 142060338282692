import { SidebarDivider } from '../Sidebar/SidebarDivider';
import type { SubNavigationEntry } from './SubNavigation.types';
import { SubNavigationItem } from './SubNavigationItem';
import { Collapse, IconButton, Grid, useScreenIsMobile, ChevronDown, ChevronUp, Text } from '@orthly/ui-primitives';
import React from 'react';

interface SubNavigationGroupProps<V extends string = string> {
    isFirstGroup: boolean;
    value: V;
    onChange: (value: V) => void;
    items: SubNavigationEntry<V>[];
    subtitle?: string;
    collapseOnMobile?: boolean;
}

export function SubNavigationGroup<V extends string = string>(props: SubNavigationGroupProps<V>) {
    const { isFirstGroup, items, onChange, value, subtitle, collapseOnMobile } = props;
    const [isOpen, setIsOpen] = React.useState(true);
    const isMobile = useScreenIsMobile();

    return (
        <>
            {!isFirstGroup && <SidebarDivider />}
            {subtitle && (
                <Grid
                    style={{ padding: `8px 24px`, display: 'flex' }}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    onClick={() => (isMobile && collapseOnMobile ? setIsOpen(!isOpen) : {})}
                >
                    <Text variant={'caption'} color={'DARK_GRAY'} style={{ fontWeight: 600 }}>
                        {subtitle}
                    </Text>
                    {collapseOnMobile && isMobile && (
                        <IconButton
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}
                            size={'small'}
                        >
                            {isOpen ? <ChevronUp /> : <ChevronDown />}
                        </IconButton>
                    )}
                </Grid>
            )}
            <Collapse in={isOpen} data-testid={'sub-navigation-items'}>
                {items.map(item => (
                    <SubNavigationItem<V>
                        key={`${value}-${item.value}`}
                        {...item}
                        active={item.value === value}
                        onClick={() => onChange(item.value)}
                    />
                ))}
            </Collapse>
        </>
    );
}
