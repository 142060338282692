import { useReviewHasStructuredNotes } from '../GuidedWaxup.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { GeneralReviewStructuredRejectionNotes } from './GeneralReviewStructuredRejectionNotes';
import { GeneralViewContainer, WaxupModelViewerAndControls } from './WaxupModelViewerControls';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { Box, Text, styled, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

const RejectionNoteContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
});

const GeneralViewContent: React.FC<{ selectedDesignRevisionNotes?: string }> = ({ selectedDesignRevisionNotes }) => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const {
        selectedTab,
        isMobileRejectionNoteOpen,
        setIsMobileRejectionNoteOpen,
        selectedDesignRevisionAlreadyReviewed,
    } = useGuidedWaxupContext();
    const reviewHasStructuredNotes = useReviewHasStructuredNotes();

    if (selectedTab !== LabsGqlGuidedWaxupPresetType.GeneralView) {
        return null;
    }

    if (reviewHasStructuredNotes) {
        return (
            <GeneralViewContainer>
                <GeneralReviewStructuredRejectionNotes
                    title={'View rejection note'}
                    closeLabel={'Close'}
                    isOpen={isMobileRejectionNoteOpen}
                    onBack={() => setIsMobileRejectionNoteOpen(false)}
                    onClose={() => setIsMobileRejectionNoteOpen(false)}
                    disabled={selectedDesignRevisionAlreadyReviewed}
                />
            </GeneralViewContainer>
        );
    }

    if (!isMobile && selectedDesignRevisionNotes) {
        return (
            <GeneralViewContainer>
                <RejectionNoteContainer>
                    <Text variant={'body1'} medium>
                        Rejection Note
                    </Text>
                    <Text variant={'body1'}>{selectedDesignRevisionNotes}</Text>
                </RejectionNoteContainer>
            </GeneralViewContainer>
        );
    }

    return null;
};

export const HistoricalWaxupModelViewerAndControls: React.VFC<React.ComponentProps<typeof GeneralViewContent>> = ({
    selectedDesignRevisionNotes,
}) => {
    return (
        <WaxupModelViewerAndControls>
            <GeneralViewContent selectedDesignRevisionNotes={selectedDesignRevisionNotes} />
        </WaxupModelViewerAndControls>
    );
};
