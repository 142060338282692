import { useGuidedWaxupAction } from '../state/GuidedWaxupState';
import { RejectionNotesContainer } from './RejectionNotesContainer/RejectionNotesContainer';
import type { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { SimpleInput } from '@orthly/ui';
import React from 'react';

export const RejectionNoteInput: React.VFC<{
    presetName: LabsGqlGuidedWaxupPresetType;
    rejectionNote: string | undefined;
    tabTitle: string;
}> = ({ presetName, rejectionNote, tabTitle }) => {
    const setPresetRejectionNotes = useGuidedWaxupAction('SET_PRESET_REJECTION_NOTES');

    return (
        <RejectionNotesContainer title={`${tabTitle} Rejection Note`}>
            <SimpleInput
                onChange={rejectionNotes => {
                    setPresetRejectionNotes({ presetName, rejectionNotes });
                }}
                value={rejectionNote}
                label={'Tell your designer what could be done differently'}
                fullWidth
                TextFieldProps={{
                    multiline: true,
                    rows: 5,
                    autoFocus: true,
                }}
            />
        </RejectionNotesContainer>
    );
};
