import type { LabsGqlLabOrderItemV2DtoFragment } from '@orthly/graphql-operations';
import { LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';
import type { IPartialDentureItem } from '@orthly/items';
import { SKUPartialIcon } from '@orthly/ui';
import { stylesFactory, Text, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    subSection: {
        marginBottom: '0.5rem',
    },
    subSectionTitle: {
        fontSize: '1rem !important',
        fontWeight: 'bold',
    },
    item: {
        fontSize: '0.75rem !important',
    },
}));

/**
 * Get clasp preferences for a list of partials orders, using the preference field
 * partial-metal-clasp-preference-order-item-meta
 *
 * @param partialsItems a filtered list of partials items (in items v2 form)
 * @returns an array of clasp notes
 *
 */
const getClaspPreferences = (partialsItems: IPartialDentureItem[]) =>
    _.uniq(
        _.compact(
            partialsItems.map(
                partial =>
                    partial.preference_fields.find(
                        field => field.field_id === 'partial-metal-clasp-preference-order-item-meta',
                    )?.value,
            ),
        ),
    );

/**
 * LabSlip section for displaying notes from the doctor
 * @param items The items_v2 property of an order
 * @returns ClaspPlacementNotes section
 */
export const ClaspPlacementNotes: React.VFC<{ items: LabsGqlLabOrderItemV2DtoFragment[] }> = ({ items }) => {
    const classes = useStyles();
    const partialsItems = OrderItemV2Utils.parseItems(items).filter(
        OrderItemV2Utils.itemIsType(LabOrderItemSKUType.Partial),
    );
    if (partialsItems.length === 0) {
        return null;
    }

    const claspPreferences = getClaspPreferences(partialsItems);
    if (claspPreferences.length === 0) {
        return null;
    }

    return (
        <Grid container className={classes.subSection}>
            <Grid container spacing={1}>
                <Grid item>
                    <SKUPartialIcon />
                </Grid>
                <Grid item>
                    <Text variant={'h5'} className={classes.subSectionTitle}>
                        Clasp Placement
                    </Text>
                </Grid>
            </Grid>
            <Grid container>
                <Text variant={'body1'} className={classes.item}>
                    {claspPreferences.map(pref => `${pref}`).join('\n')}
                </Text>
            </Grid>
        </Grid>
    );
};
