import BleachingTray from '../../assets/images/skus/Bleaching.svg';
import Crown from '../../assets/images/skus/Crown.svg';
import Implant from '../../assets/images/skus/Implant.svg';
import Model from '../../assets/images/skus/Model.svg';
import ImplantModel from '../../assets/images/skus/Model_Dual_Quadrant.svg';
import NightGuard from '../../assets/images/skus/Nightguard.svg';
import Other from '../../assets/images/skus/Other.svg';
import Retainer from '../../assets/images/skus/Retainer.svg';
import type { ExtraCartItemV2UnitType } from '@orthly/items';

export class ExtraCartItemV2DisplayUtils {
    static getImageForUnitType(unit_type: ExtraCartItemV2UnitType): string {
        // all units used in checkout should have a specific image here
        switch (unit_type) {
            case 'Abutment Insertion Guide':
            case 'Model':
            case 'Removable Model':
            case 'Study Model':
                return Model;
            case 'Extra Screw':
                return Implant;
            case 'Implant Model':
                return ImplantModel;
            case 'Night Guard':
            case 'Sportsguard':
                return NightGuard;
            case 'Bleaching Tray':
                return BleachingTray;
            case 'Retainer':
                return Retainer;
            case 'Other':
                return Other;
        }
        return Crown;
    }
}
