import { PRINTABLE_SLIP_FADED_TEXT_COLOR } from '../../styles/colors';
import { Text, makeStyles } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles({
    fadedText: {
        color: `${PRINTABLE_SLIP_FADED_TEXT_COLOR} !important`,
        fontSize: '0.75rem !important',
    },
});

interface IPrintableSlipSectionHeaderProps {
    text: string;
}

/**
 * Renders the "shaded" section name for a section of the Printable Slip
 * @param props
 * @returns The shaded text to label a section of the Printable Slip
 */
export const PrintableSlipSectionTitle: React.VFC<IPrintableSlipSectionHeaderProps> = props => {
    const classes = useStyles();
    return (
        <Text variant={'body2'} className={classes.fadedText}>
            {props.text}
        </Text>
    );
};
