import { DesignTaskDetailTrackerWithSteps } from './DesignTrackerStep';
import { OrderListItemTrackerUtils } from './OrderListItemTracker.util';
import type { TrackerStepDefinition } from './TrackerStep';
import type {
    LabsGqlLabOrderFragment,
    LabsGqlWorkflowTaskFragment,
    LabsGqlOrderFulfillmentWorkflowFragment,
    LabsGqlWorkflowTask_ReviewOrderWorkflowTask_Fragment,
} from '@orthly/graphql-operations';
import { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import React from 'react';

const taskIsReviewOrder = (
    task: LabsGqlWorkflowTaskFragment | null,
): task is LabsGqlWorkflowTask_ReviewOrderWorkflowTask_Fragment => {
    return task?.type === LabsGqlWorkflowTaskType.ReviewOrder;
};

const reviewOrderTaskStepId = (task: LabsGqlWorkflowTaskFragment | null) => {
    if (taskIsReviewOrder(task)) {
        return task.configuration.review_step.id;
    }
};

const reviewOrderTasks = (
    workflow: LabsGqlOrderFulfillmentWorkflowFragment,
): LabsGqlWorkflowTask_ReviewOrderWorkflowTask_Fragment[] => {
    const { active_task, closed_tasks } = workflow;
    return [...(active_task ? [active_task] : []), ...closed_tasks].filter(taskIsReviewOrder);
};

const designTrackerSteps = (order: LabsGqlLabOrderFragment): TrackerStepDefinition[] => {
    const { fulfillment_workflow } = order;
    const review_order_tasks = reviewOrderTasks(fulfillment_workflow);
    const steps_with_tasks = fulfillment_workflow.configuration.needed_review_steps.map(step => {
        const task = review_order_tasks.find(task => reviewOrderTaskStepId(task) === step.id);
        return { step, task, active: !!task && !task.closeout, complete: !!task && !!task.closeout };
    });
    const started = steps_with_tasks.some(({ active, complete }) => active || complete);
    return [
        {
            title: `Placed`,
            icon: `Order` as const,
            subtitle: OrderListItemTrackerUtils.formatDate(order.created_at),
            active: !started,
            complete: started,
        },
        ...steps_with_tasks.map(({ step, task, active, complete }) => ({
            active,
            complete,
            title: step.name,
            icon: `Badge` as const,
            subtitle:
                // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                // eslint-disable-next-line no-nested-ternary
                task && active
                    ? OrderListItemTrackerUtils.formatAssignee(task.assignee)
                    : task && task.closeout && complete
                      ? OrderListItemTrackerUtils.formatCloseout(task.closeout)
                      : ``,
        })),
    ];
};

interface ReviewTaskDetailTrackerProps {
    order: LabsGqlLabOrderFragment;
}

export const ReviewTaskDetailTracker: React.FC<ReviewTaskDetailTrackerProps> = ({ order }) => {
    const steps = designTrackerSteps(order);
    return <DesignTaskDetailTrackerWithSteps steps={steps} hasIssue={false} />;
};
