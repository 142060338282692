import unknownScanbodyImageUrl from '../../../assets/images/UnknownScanbody.png';
import { Format } from '@orthly/runtime-utils';
import { ScanbodyUtils } from '@orthly/shared-types';
import { OverflowTextWithTooltip } from '@orthly/ui';
import { Text, stylesFactory, FlossPalette, Grid } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    root: {
        position: 'relative',
        // since this is a card-like component, set the size explicitly
        width: 272,
        height: 256,
        justifyContent: 'flex-end',
        backgroundColor: FlossPalette.TAN,
        borderRadius: 16,
        boxSizing: 'border-box',
        border: `1px solid ${FlossPalette.DARK_TAN}`,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    selected: {
        border: `3px solid ${FlossPalette.STAR_GRASS}`,
    },
    imageContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        userSelect: 'none',
        '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
        },
    },
    textContainer: {
        padding: 13, // 16px desired padding - 3px of border-box border
        background: `linear-gradient(180deg, rgba(247, 247, 249, 0) 0%, ${FlossPalette.TAN} 53.65%);`,
        minHeight: 112, // for the gradient
        zIndex: 1,
        borderRadius: 16,
        justifyContent: 'flex-end',
    },
    scanbodyManufacturer: {
        textTransform: 'capitalize',
    },
    scanbodyName: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

export interface ScanbodyProps {
    implantManufacturer: string;
    implantSystem: string;
    implantConnection: string;
    scanbodyManufacturer: string;
    scanbodyName: string;
    requestedDate: Date;
    selected?: boolean;
}

export const UnknownScanbodyImage: React.VFC = () => {
    // empty alt because the image is decorative, information is conveyed in labels
    return <img src={unknownScanbodyImageUrl} alt={''} />;
};

export const ScanbodyImage: React.VFC<{ scanbodyName: string; scanbodyManufacturer: string }> = ({
    scanbodyName,
    scanbodyManufacturer,
}) => {
    const src = ScanbodyUtils.getScanbodyImageURL(scanbodyName, scanbodyManufacturer);

    const [erroredUrl, setErroredUrl] = React.useState<string | null>(null);

    // empty alt because the image is decorative, information is conveyed in labels
    return <img src={erroredUrl === src ? unknownScanbodyImageUrl : src} onError={() => setErroredUrl(src)} alt={''} />;
};

export const ScanbodyLayout: React.VFC<{ image: React.ReactNode; text: React.ReactNode; selected: boolean }> = ({
    image,
    text,
    selected,
}) => {
    const classes = useStyles();

    return (
        <Grid container direction={'column'} className={clsx(classes.root, selected && classes.selected)}>
            <Grid container className={classes.imageContainer}>
                {image}
            </Grid>
            <Grid container direction={'column'} className={classes.textContainer}>
                {text}
            </Grid>
        </Grid>
    );
};

export const ScanbodyDisplay: React.VFC<ScanbodyProps> = ({
    implantManufacturer,
    implantSystem,
    implantConnection,
    scanbodyManufacturer,
    scanbodyName,
    requestedDate,
    selected,
}) => {
    const classes = useStyles();
    return (
        <ScanbodyLayout
            selected={selected ?? false}
            image={<ScanbodyImage scanbodyName={scanbodyName} scanbodyManufacturer={scanbodyManufacturer} />}
            text={
                <>
                    <Text variant={'body2'} medium className={classes.scanbodyManufacturer}>
                        {scanbodyManufacturer}
                    </Text>
                    <OverflowTextWithTooltip variant={'body2'} color={'DARK_GRAY'} text={scanbodyName} />
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        {implantManufacturer} → {implantSystem} → {implantConnection}
                    </Text>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        Requested {Format.fromNow(requestedDate, { max: 1, suffix: true })}
                    </Text>
                </>
            }
        />
    );
};

export const NonDandyScanbodyDisplay: React.VFC<{ selected?: boolean }> = ({ selected }) => {
    return (
        <ScanbodyLayout
            selected={selected ?? false}
            image={<UnknownScanbodyImage />}
            text={
                <>
                    <Text variant={'body2'} medium>
                        Non-Dandy / Other
                    </Text>
                </>
            }
        />
    );
};
