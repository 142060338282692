import type { AdminLabsOrderActionProps } from './order-action-types';
import type { LabsGqlSaveLabSlipAlertsMutationVariables } from '@orthly/graphql-operations';
import { useSaveLabSlipAlertsMutation } from '@orthly/graphql-react';
import { LabsGqlDoctorAlertLocation, LabsGqlLabSlipAlertType, LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { QuickForm, useChangeSubmissionFn, RootActionDialog, WarningIcon } from '@orthly/ui';
import { IconButton, Tooltip } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type Vars = LabsGqlSaveLabSlipAlertsMutationVariables;

const hiddenStatuses = [
    LabsGqlLabOrderStatus.Shipped,
    LabsGqlLabOrderStatus.NeedsRefabrication,
    LabsGqlLabOrderStatus.Delivered,
    LabsGqlLabOrderStatus.Cancelled,
];

export const EditLabSlipAlerts: React.FC<AdminLabsOrderActionProps> = props => {
    const { order, refetchOrder } = props;
    const [submitMtn] = useSaveLabSlipAlertsMutation();
    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [Vars]>(
        (variables: Vars) => submitMtn({ variables }),
        {
            closeOnComplete: true,
            successMessage: () => ['Lab slip alerts updated!', {}],
            onSuccess: async () => {
                await refetchOrder();
            },
        },
    );
    const openButton = React.useMemo(() => {
        return (
            <Tooltip title={'Edit Lab Slip Alerts'}>
                <IconButton onClick={() => setOpen(true)} style={{ padding: 0, marginRight: 8 }}>
                    <WarningIcon />
                </IconButton>
            </Tooltip>
        );
    }, [setOpen]);
    if (hiddenStatuses.includes(order.status)) {
        return null;
    }
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Edit Lab Slip Alerts'}
            dialogProps={{ maxWidth: 'md' }}
            content={
                <QuickForm<{ alerts: { text: string; isTask?: boolean; visibility: LabsGqlDoctorAlertLocation[] }[] }>
                    dirtySubmitOnly
                    fields={{
                        alerts: {
                            type: 'array',
                            of: {
                                type: 'nested',
                                fields: {
                                    text: { type: 'text', layout: { xs: 8 } },
                                    isTask: { type: 'boolean', label: 'Show as checkbox?', layout: { xs: 4 } },
                                    visibility: {
                                        type: 'multiselect',
                                        label: 'Visibility',
                                        options: Object.values(LabsGqlDoctorAlertLocation).map(value => ({
                                            value,
                                            label: _.startCase(value),
                                        })),
                                    },
                                },
                            },
                        },
                    }}
                    initialValues={{
                        alerts: order.lab_slip_alerts.map(({ text, type, visibility }) => ({
                            text,
                            isTask: type === LabsGqlLabSlipAlertType.Checkbox,
                            visibility:
                                visibility && visibility.length > 0 ? visibility : [LabsGqlDoctorAlertLocation.LabSlip],
                        })),
                    }}
                    onSubmit={async result => {
                        await submit({
                            orderId: order.id,
                            saved_alerts: result.alerts.map(a => ({
                                text: a.text,
                                type: a.isTask ? LabsGqlLabSlipAlertType.Checkbox : LabsGqlLabSlipAlertType.Warning,
                                visibility: a.visibility,
                            })),
                        });
                    }}
                />
            }
            buttonText={'Edit Lab Slip Alerts'}
            CustomButton={() => openButton}
        />
    );
};
