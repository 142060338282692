import type { LabsGqlAbutmentPartFragment } from '@orthly/graphql-operations';

/**
 * The back-end was built using a priority number (integer) so we can easily extend the
 * priorities in the future, but we only want to show the "preferred" or "non-preferred"
 * value to the users of the UI as of today. Therefore we created the next 2 helpers to
 * easily switch from a priority number (back-end value) to a "is preferred" boolean.
 *
 * If value is 10, it means it's preferred, if value is 0, it's non-preferred.
 */
const PRIORITIES = {
    PREFERRED: 10,
    NON_PREFERRED: 0,
};

export const fromPriorityNumberToIsPreferred = (priority: number): boolean => {
    return priority === PRIORITIES.PREFERRED;
};

export const fromIsPreferredToPriorityNumber = (isPreferred: boolean): number => {
    return isPreferred ? PRIORITIES.PREFERRED : PRIORITIES.NON_PREFERRED;
};

// We use this helper to summarize the parts specs text in a string, useful to display all the parts specs in a dropdown
export const formatAbutmentPartsSpecsText = (part: LabsGqlAbutmentPartFragment): string => {
    return `[${part.sku}] H: ${part.top_cap_height_mm || 'N/A'}, GH: ${part.gingival_height_mm || 'N/A'}, D: ${
        part.diameter_mm || 'N/A'
    }`;
};
