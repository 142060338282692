import { AttributeName } from '@orthly/forceps';
import * as THREE from 'three';

export function initializeDistanceAttribute(geometry: THREE.BufferGeometry | undefined) {
    if (!geometry) {
        return;
    }
    const positionAttribute = geometry.getAttribute(AttributeName.Position);
    let distancesAttribute = geometry.getAttribute(AttributeName.OcclusalDistance);
    if (!distancesAttribute) {
        distancesAttribute = new THREE.BufferAttribute(new Float32Array(positionAttribute.count), 1);
    }
    (distancesAttribute.array as Float32Array).fill(-101.0);
    geometry.setAttribute(AttributeName.OcclusalDistance, distancesAttribute);
    distancesAttribute.needsUpdate = true;
}

export function createScanMeshStoneMaterial() {
    const scanMeshStoneMaterial = new THREE.MeshPhongMaterial({
        color: new THREE.Color(220 / 255, 200 / 255, 178 / 255),
        shininess: 90,
        specular: new THREE.Color(17 / 255, 17 / 255, 17 / 255),
        side: THREE.DoubleSide,
    });
    return scanMeshStoneMaterial;
}
