import { FlossPalette, Icon, styled } from '@orthly/ui-primitives';
import React from 'react';

const GrayIcon = styled(Icon)({
    marginRight: '8px',
    color: FlossPalette.GRAY,
});

export const GrayBooleanIcon: React.FC<{ val: boolean }> = ({ val }) => (
    <GrayIcon icon={val ? 'CheckIcon' : 'CloseIcon'} color={'disabled'} />
);
