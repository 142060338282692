import type { ItemInfo, Rule, Decision } from '../orderEditMode';
import { OrderEditMode } from '@orthly/shared-types';

/**
 * orderAssigned checks for the current order task matching the
 * specified assignment.  If it finds a match it returns EditOrder as
 * the most permissible possible value, otherwise it returns null to
 * skip
 */
export function orderAssigned(assigned: boolean): Rule {
    return function (item: ItemInfo): Decision {
        const assignee = item.order.fulfillment_workflow.active_task?.assignee;
        const debugInfo = {
            rule: 'orderAssigned',
            expected: assigned,
            received: !!assignee,
        };

        if (!!assignee === assigned) {
            return {
                editMode: OrderEditMode.OrderEdit,
                debugInfo,
            };
        }

        return {
            editMode: null,
            debugInfo,
        };
    };
}
