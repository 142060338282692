export type Point = [number, number];

export const CANVAS_SCALE = 2;
export const HIT_TEST_MARGIN = 5;

export function pointEquals(a: Point, b: Point, epsilon: number = 0.0001): boolean {
    return Math.abs(a[0] - b[0]) < epsilon && Math.abs(a[1] - b[1]) < epsilon;
}

export function vectorDifference(v1: Point, v2: Point): Point {
    return [v1[0] - v2[0], v1[1] - v2[1]];
}

export function vectorMagnitude(v: Point): number {
    return Math.sqrt(Math.pow(v[0], 2) + Math.pow(v[1], 2));
}

export function vectorNormal(v: Point): Point {
    const mag = vectorMagnitude(v);
    return [v[0] / mag, v[1] / mag];
}

export function vectorMultiply(v: Point, m: number): Point {
    return [v[0] * m, v[1] * m];
}

export function vectorRotate(v: Point, t: number): Point {
    const sinT = Math.sin(t);
    const cosT = Math.cos(t);
    return [cosT * v[0] - sinT * v[1], sinT * v[0] + cosT * v[1]];
}

export function vectorSum(v1: Point, v2: Point): Point {
    return [v1[0] + v2[0], v1[1] + v2[1]];
}

/** Returns the projection of a vector `a` onto a vector `b`. */
export function projectVector(a: Point, b: Point): Point {
    // Projection of `a` onto `b` is:
    //        normalize(b) * (a ⋅ b) / ||b||
    // i.e.   (b / ||b||) * (a ⋅ b) / ||b||
    // i.e.   b * (a ⋅ b) / ||b||^2

    // ||b||^2
    const diagMagSquared = Math.pow(b[0], 2) + Math.pow(b[1], 2);

    // (a ⋅ b) / ||b||^2
    const dotProductOverMagSquared = (a[0] * b[0] + a[1] * b[1]) / diagMagSquared;

    return vectorMultiply(b, dotProductOverMagSquared);
}

/** We use this function to allow usage of await/async instead of dealing with callbacks for toBlob */
export async function canvasToBlob(canvas: HTMLCanvasElement | undefined): Promise<Blob | null> {
    return new Promise(resolve => {
        if (canvas) {
            canvas.toBlob(resolve, `image/png`);
        } else {
            resolve(null);
        }
    });
}
