import type { IMergedItem } from '../../../PrintableSlip/types';
import { isMergedItem } from '../../../PrintableSlip/types';
import { getMergedItems } from '../../../PrintableSlip/utils/mergedItem.util';
import { getHeaderForLabSlipItem } from '../../../PrintableSlip/utils/shared.utils';
import type { IStructuredDoctorNote } from '../../LabSlip.types';
import type { IOrderItemV2DTO } from '@orthly/items';
import _ from 'lodash';

function notesAreEqual(itemA: Omit<IOrderItemV2DTO, 'id'>, itemB: Omit<IOrderItemV2DTO, 'id'>): boolean {
    return itemA.item_notes === itemB.item_notes;
}

/**
 * Return item level notes, grouped together if they should be rendered
 * under one heading on the Lab Slip
 * @param {IOrderItemV2DTO[]} items
 * @returns {IStructuredDoctorNote[]}
 */
export function getGroupedItemNotes(items: IOrderItemV2DTO[]): IStructuredDoctorNote[] {
    return _.compact(
        getMergedItems(items, notesAreEqual).map((possiblyMergedItem: IOrderItemV2DTO | IMergedItem) => {
            const note = isMergedItem(possiblyMergedItem)
                ? possiblyMergedItem.defaultItem.item_notes
                : possiblyMergedItem.item_notes;
            if (!note) {
                return;
            }
            return {
                note,
                header: getHeaderForLabSlipItem(possiblyMergedItem),
            };
        }),
    );
}
