import { useRefabFlowContext } from '../screens/refab-flow-context';
import {
    useSelectedReasonCodesForCurrentItem,
    useAssignFaultByReasonCodeForCurrentItem,
} from '../state/refab-flow-state';
import type { RefabFlowItemReasonCode } from '../state/refab-flow-types';
import type { Maybe } from '@orthly/graphql-schema';
import { LabsGqlReasonCodeFault } from '@orthly/graphql-schema';
import { RootActionDialog, SimpleSelect } from '@orthly/ui';
import { Text, Button, FlossPalette } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const ReasonCodeFaultOverrideContent: React.VFC<{ selectedReasonCodes: RefabFlowItemReasonCode[] }> = ({
    selectedReasonCodes,
}) => {
    const groupedReasonCodes = _.groupBy(selectedReasonCodes, rc => `${rc.group} > ${rc.category}`);
    const assignFault = useAssignFaultByReasonCodeForCurrentItem();
    return (
        <>
            {Object.entries(groupedReasonCodes).map(([groupAndCategory, reasonCodes]) => (
                <div
                    key={groupAndCategory}
                    style={{
                        paddingLeft: 12,
                        marginBottom: 32,
                        borderLeft: `2px solid ${FlossPalette.DARK_TAN}`,
                    }}
                >
                    <Text variant={'h6'}>{groupAndCategory}</Text>
                    {reasonCodes.map(reasonCode => (
                        <div key={reasonCode.codeId} style={{ paddingLeft: 24, marginBlock: 16 }}>
                            <Text variant={'body1'}>{reasonCode.title}</Text>
                            {reasonCode.title.toLowerCase() === 'other' && (
                                <Text variant={'caption'} color={'GRAY'}>
                                    {reasonCode.otherNotes}
                                </Text>
                            )}
                            <SimpleSelect
                                label={'Fault'}
                                placeholder={'Select fault'}
                                options={Object.keys(LabsGqlReasonCodeFault).map(k => ({ value: k }))}
                                value={reasonCode.defaultFault ?? undefined}
                                onChange={val =>
                                    assignFault(reasonCode, (val ?? null) as Maybe<LabsGqlReasonCodeFault>)
                                }
                            />
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};

export const ReasonCodeFaultOverrideButton: React.VFC = () => {
    const [open, setOpen] = React.useState(false);
    const selectedReasonCodes = Object.values(useSelectedReasonCodesForCurrentItem() ?? {});
    const { isOps } = useRefabFlowContext();
    if (!isOps || !selectedReasonCodes.length) {
        return null;
    }
    return (
        <RootActionDialog
            CustomButton={() => (
                <Button variant={'secondary'} onClick={() => setOpen(true)} fullWidth={false}>
                    View/override faults
                </Button>
            )}
            loading={false}
            open={open}
            setOpen={setOpen}
            showCloseButton={true}
            title={'View/override faults'}
            content={
                <>
                    <ReasonCodeFaultOverrideContent selectedReasonCodes={selectedReasonCodes} />
                    <Button variant={'secondary'} onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </>
            }
        />
    );
};
