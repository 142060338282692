import { HoldRemovalSlaUpdateForm } from './HoldRemovalSlaUpdateForm.graphql';
import type { ToolbarActionDefinition, ToolbarActionProps } from './ToolbarActionDefinition';
import type { LabsGqlOrderSlaFragment } from '@orthly/graphql-operations';
import { useRemoveOrderHoldMutation } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { useRootActionCommand, RootActionDialog } from '@orthly/ui';
import { styled } from '@orthly/ui-primitives';
import { Button } from '@orthly/ui-primitives';
import { useDelayDateSlaPreview } from '@orthly/veneer';
import React from 'react';

const SubmitButton = styled(Button)({
    marginTop: 16,
    float: 'right',
});

type SlaStageKey = keyof Omit<NonNullable<LabsGqlOrderSlaFragment['stages']>, '__typename'>;

export const RemoveHoldFromOrder: React.FC<ToolbarActionProps> = props => {
    const { order, open, setOpen } = props;

    const { submit, submitting } = useRootActionCommand(useRemoveOrderHoldMutation(), {
        successMessage: 'Order hold removed!',
        onSuccess: async () => {
            await props.refetchOrder();
            setOpen(false);
        },
    });

    const currentStage = order.manufacturer_sla.current_stage
        ? order.manufacturer_sla.stages?.[order.manufacturer_sla.current_stage as SlaStageKey]
        : null;
    const startingDate = currentStage?.eta ?? order.manufacturer_sla.due_date;

    const [reason, setReason] = React.useState<string | undefined>(undefined);
    const delayDateHookData = useDelayDateSlaPreview(order, startingDate);

    if (order.status !== LabsGqlLabOrderStatus.OnHold) {
        return null;
    }

    return (
        <RootActionDialog
            title={'Remove order hold'}
            showCloseButton
            loading={submitting}
            open={open}
            setOpen={setOpen}
            content={
                <>
                    <HoldRemovalSlaUpdateForm
                        order={order}
                        delayDateHookData={delayDateHookData}
                        reason={reason}
                        setReason={setReason}
                    />

                    <br />

                    <SubmitButton
                        variant={'primary'}
                        disabled={!reason}
                        onClick={async () => {
                            await submit({
                                data: {
                                    reason,
                                    orderId: order.id,
                                    stage_due_date:
                                        (order.manufacturer_sla.version ?? 0) >= 4
                                            ? delayDateHookData.selectedDate.toISOString()
                                            : null,
                                },
                            });
                        }}
                    >
                        Remove hold
                    </SubmitButton>
                </>
            }
            buttonText={'Remove Order Hold'}
            CustomButton={props.CustomButton}
        />
    );
};

export const RemoveOrderOnHoldToolbarActionDef: ToolbarActionDefinition = {
    label: 'Remove hold',
    allowedStatuses: [LabsGqlLabOrderStatus.OnHold],
    Component: RemoveHoldFromOrder,
    capabilityRequirement: 'order.hold.remove',
};
