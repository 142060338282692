import { AskDoctorChangeRxConfigurationDialog } from './AskDoctorChangeRxConfigurationDialog';
import { getDefaultDoctorLabel, getOpsFieldLabel, getOptionTypeLabel } from './utils';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { LabsGqlDoctorRequestOptionType } from '@orthly/graphql-schema';
import { belongsToEnum } from '@orthly/runtime-utils';
import { SimpleInput, SortableList } from '@orthly/ui';
import {
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    CloseIcon,
    Button,
    DandyRecommendedIcon,
    FlossPalette,
    Text,
    stylesFactory,
} from '@orthly/ui-primitives';
import type { AskDoctorV2ViewableOption } from '@orthly/veneer';
import type { PropsWithChildren } from 'react';
import React from 'react';

const useStyles = stylesFactory(() => ({
    optionContentContainer: {
        padding: `12px`,
        backgroundColor: FlossPalette.TAN,
        borderRadius: '8px',
        border: `1px solid ${FlossPalette.DARK_TAN}`,
    },
    recommendedOptionContentContainer: {
        padding: `12px`,
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        borderRadius: '0 0 8px 8px',
        border: `1px solid ${FlossPalette.STAR_GRASS}`,
    },
    recommendedHeader: {
        flexGrow: 1,
        backgroundColor: FlossPalette.STAR_GRASS,
        color: FlossPalette.WHITE,
        fontSize: 12,
        padding: '2px 16px 2px 24px',
        width: '100%',
        borderRadius: '16px 16px 0 0',
        alignItems: 'center',
    },
    recommendedIconWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    recommendedText: {
        paddingLeft: 8,
    },
    optionTypeSelectWrapper: {
        flexGrow: 1,
    },
    errorWrapper: {
        paddingLeft: `2px`,
    },
}));

export type AskDoctorContainerProps = {
    isRecommended: boolean;
};

export const AskDoctorContainer: React.VFC<PropsWithChildren<AskDoctorContainerProps>> = ({
    isRecommended,
    children,
}) => {
    const classes = useStyles();

    return (
        <Grid container direction={'column'}>
            {isRecommended ? (
                <Grid container item direction={'row'} className={classes.recommendedHeader}>
                    <Grid item className={classes.recommendedIconWrapper}>
                        <DandyRecommendedIcon />
                    </Grid>
                    <Grid item>
                        <Text variant={'caption'} color={'WHITE'} medium className={classes.recommendedText}>
                            Recommended
                        </Text>
                    </Grid>
                </Grid>
            ) : null}
            <Grid item>
                <Grid
                    className={
                        isRecommended ? classes.recommendedOptionContentContainer : classes.optionContentContainer
                    }
                >
                    {children}
                </Grid>
            </Grid>
        </Grid>
    );
};

export type AskDoctorOptionProps = {
    option: AskDoctorV2ViewableOption;
    optionIndex: number;
    updateOption: (updatedOption: AskDoctorV2ViewableOption) => void;
    handleDelete: () => void;
    order: LabsGqlLabOrderFragment;
    errorText: string | null;
};

export const AskDoctorOption: React.VFC<AskDoctorOptionProps> = ({
    option,
    optionIndex,
    updateOption,
    handleDelete,
    order,
    errorText,
}) => {
    const classes = useStyles();
    // We always want to display the first option in the list as recommended
    const isRecommended = optionIndex === 0;
    const optionNumberDisplayed = optionIndex + 1;
    const isSelectedOptionConfigurable = option.type === LabsGqlDoctorRequestOptionType.ModifyOrder;
    const hasModifyOrderConfigurationSet =
        isSelectedOptionConfigurable && option.modify_order_config?.mode !== undefined;
    const [isChangeRxConfigurationOpen, setIsChangeRxConfigurationOpen] = React.useState(false);
    const selectHtmlId = `option-type-select-${optionNumberDisplayed}`;

    return (
        <AskDoctorContainer isRecommended={isRecommended}>
            <Grid container direction={'row'} spacing={2} wrap={'nowrap'}>
                <Grid item>
                    <SortableList.DragHandle />
                </Grid>

                <Grid container item direction={'column'} spacing={1}>
                    <Grid item container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Text variant={'body2'} medium>{`Option ${optionNumberDisplayed}`}</Text>
                        <IconButton size={'small'} color={'primary'} onClick={handleDelete}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>

                    <Grid container item direction={'row'} alignItems={'center'} spacing={1}>
                        <Grid item className={classes.optionTypeSelectWrapper}>
                            <FormControl variant={'standard'} fullWidth>
                                <InputLabel htmlFor={selectHtmlId}>Select the type of option</InputLabel>
                                <Select
                                    defaultValue={''}
                                    id={selectHtmlId}
                                    data-test={selectHtmlId}
                                    onChange={event => {
                                        const optionType = event.target.value as string;

                                        if (belongsToEnum(optionType, LabsGqlDoctorRequestOptionType)) {
                                            updateOption({
                                                ...option,
                                                type: optionType,
                                                label: getDefaultDoctorLabel(optionType),
                                            });
                                        }
                                    }}
                                    variant={'standard'}
                                >
                                    <ListSubheader>RESOLVES HOLDS</ListSubheader>
                                    <MenuItem value={LabsGqlDoctorRequestOptionType.ModifyOrder}>
                                        {getOptionTypeLabel(LabsGqlDoctorRequestOptionType.ModifyOrder)}
                                    </MenuItem>
                                    <MenuItem value={LabsGqlDoctorRequestOptionType.AddReductionCoping}>
                                        {getOptionTypeLabel(LabsGqlDoctorRequestOptionType.AddReductionCoping)}
                                    </MenuItem>
                                    <MenuItem value={LabsGqlDoctorRequestOptionType.ProceedAsIs}>
                                        {getOptionTypeLabel(LabsGqlDoctorRequestOptionType.ProceedAsIs)}
                                    </MenuItem>

                                    <ListSubheader>RETAINS HOLD</ListSubheader>
                                    <MenuItem value={LabsGqlDoctorRequestOptionType.NeedsAdditionalScans}>
                                        {getOptionTypeLabel(LabsGqlDoctorRequestOptionType.NeedsAdditionalScans)}
                                    </MenuItem>
                                    <MenuItem value={LabsGqlDoctorRequestOptionType.ManualResolution}>
                                        {getOptionTypeLabel(LabsGqlDoctorRequestOptionType.ManualResolution)}
                                    </MenuItem>
                                    <MenuItem value={LabsGqlDoctorRequestOptionType.Other}>
                                        {getOptionTypeLabel(LabsGqlDoctorRequestOptionType.Other)}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {isSelectedOptionConfigurable && (
                            <>
                                <Grid item>
                                    <Button
                                        data-test={`ask-doctor-change-rx-button-${optionNumberDisplayed}`}
                                        variant={'secondary'}
                                        onClick={() => setIsChangeRxConfigurationOpen(true)}
                                    >
                                        {hasModifyOrderConfigurationSet ? 'Update Configuration' : 'Configure'}
                                    </Button>
                                </Grid>
                                <AskDoctorChangeRxConfigurationDialog
                                    order={order}
                                    isOpen={isChangeRxConfigurationOpen}
                                    setOpen={setIsChangeRxConfigurationOpen}
                                    onSubmit={(items_v2, mode) =>
                                        updateOption({
                                            ...option,
                                            modify_order_config: {
                                                mode,
                                                items_v2,
                                            },
                                        })
                                    }
                                />
                            </>
                        )}
                    </Grid>

                    {option.type && (
                        <Grid item>
                            <SimpleInput
                                onChange={value => updateOption({ ...option, label: value ?? '' })}
                                value={option.label}
                                fullWidth
                                label={getOpsFieldLabel(option.type)}
                                data-test={`input-label-option-${optionNumberDisplayed}`}
                            />
                        </Grid>
                    )}

                    {errorText && (
                        <FormHelperText className={classes.errorWrapper} error={true}>
                            {errorText}
                        </FormHelperText>
                    )}
                </Grid>
            </Grid>
        </AskDoctorContainer>
    );
};
