import { RouterUtils } from '../../../utils/router/RouterUtils';
import { OrganizationPriceConfigTable } from '../OrganizationPriceConfig/OrganizationPriceConfigTable';
import { PartnerPricesV2BulkUploadModal } from './PartnerPricesV2BulkUploadModal';
import { useListPracticeNamesQuery } from '@orthly/graphql-react';
import type { LabsGqlPracticeNameAndPrimaryContactInfo } from '@orthly/graphql-schema';
import MUITable from '@orthly/mui-table';
import { Grid } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

const basePath = `${RouterUtils.pathForScreen('pricing')}/partner-prices`;

const ListPartnerPricesV2Table: React.VFC = () => {
    const { value: disableContractEdits = false } = useFeatureFlag('disableContractEdits');
    const { data, loading, refetch } = useListPracticeNamesQuery({ fetchPolicy: 'cache-first' });

    const history = useHistory();

    return (
        <Grid container>
            <MUITable<LabsGqlPracticeNameAndPrimaryContactInfo>
                loading={loading}
                title={'Partner Prices'}
                data={data?.listPracticeNames || []}
                rowOptions={{ rowHover: true }}
                displayOptions={{ fixedSearch: true, fixedHeader: true, responsive: 'scroll' }}
                eventHooks={{ onRowClick: row => history.push(`${basePath}/${row.id}`) }}
                toolbarOptions={{
                    CustomRight: disableContractEdits ? undefined : PartnerPricesV2BulkUploadModal,
                }}
                columns={[
                    { name: 'id', hidden: true, render: 'id' },
                    { name: 'Partner', render: 'name', bodyCellWrapStyle: { fontWeight: 'bold' } },
                ]}
                actions={{
                    global: [{ position: 'toolbar', onClick: refetch, icon: 'refresh', tooltip: 'Refresh' }],
                }}
            />
        </Grid>
    );
};

export const PartnerPricesV2Table: React.VFC = () => {
    return (
        <Switch>
            <Route path={`${basePath}/:partnerId`} component={OrganizationPriceConfigTable} />
            <Route path={`${basePath}`} component={ListPartnerPricesV2Table} />
        </Switch>
    );
};
