import { OrthlyApolloProvider } from '@orthly/graphql-react';
import { OrthlyEnvironment } from '@orthly/shared-types';
import { LoadBlocker, OrthlyBrowserConfig, OrthlyFrontendApps } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const config = new OrthlyBrowserConfig(OrthlyFrontendApps.admin);

export const AdminGqlProvider: React.FC = props => {
    return (
        <OrthlyApolloProvider
            disablePersistedCache
            clientName={process.env.REACT_APP_LOCAL_PROD ? `${config.appName}-LOCAL_PROD` : config.appName}
            version={config.version}
            env={process.env.REACT_APP_LOCAL_PROD ? OrthlyEnvironment.production : config.env}
            routerEndpoint={process.env.REACT_APP_GATEWAY_URL ?? '/graphql'}
            loadingFallback={
                <LoadBlocker blocking={true} loader={'dandy'}>
                    <Grid container style={{ minHeight: '100vh' }} />
                </LoadBlocker>
            }
        >
            {props.children}
        </OrthlyApolloProvider>
    );
};
