import { CategoryConfig } from '../CategoryConfig/CategoryConfig';
import { ListRefundCategories_Query } from './RefundCategoriesQuery.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { SessionGuardUtils } from '@orthly/session-client';
import React from 'react';

const CreateRefundCategory_Mutation = graphql(`
    mutation CreateRefundCategory($name: String!) {
        createRefundCategory(name: $name) {
            name
        }
    }
`);

const UpdateRefundCategory_Mutation = graphql(`
    mutation UpdateRefundCategory($data: UpdateRefundCategoryInput!) {
        updateRefundCategory(data: $data) {
            id
            archived
            name
        }
    }
`);

const canEditCategories = SessionGuardUtils.hasCapability('billing', 'billing.edit_refund_categories');

export const RefundCreditCategoriesRoot: React.VFC = () => {
    const {
        data: { listRefundCategories } = {},
        loading,
        refetch,
    } = useQuery(ListRefundCategories_Query, {
        variables: { includeArchived: true },
    });
    const create = useMutation(CreateRefundCategory_Mutation);
    const update = useMutation(UpdateRefundCategory_Mutation);

    return (
        <CategoryConfig
            categoryName={'Refund'}
            canEdit={canEditCategories}
            data={listRefundCategories || []}
            loading={loading}
            refetch={refetch}
            create={create}
            update={update}
        />
    );
};
