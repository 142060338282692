import { ItemEditorV2DataFieldsSection } from './sections/data-fields/ItemEditorV2DataFieldsSection';
import { ItemEditorV2GeneralSection } from './sections/general/ItemEditorV2GeneralSection';
import { ItemEditorV2ImplantInfoSection } from './sections/implant-info/ItemEditorV2ImplantInfoSection';
import { ItemEditorV2ImplantPartsSection } from './sections/implant-parts/ItemEditorV2ImplantPartsSection';
import { ItemEditorV2NotesSection } from './sections/notes/ItemEditorV2NotesSection';
import { ItemEditorV2PreferencesSection } from './sections/preferences/ItemEditorV2PreferencesSection';
import { ItemEditorV2SurgicalGuideImplantMetadataSection } from './sections/surgical-guide-implant-metadata/ItemEditorV2SurgicalGuideImplantMetadataSection';
import { useGetImplantTypesLiteQuery } from '@orthly/graphql-react';
import type { ICartItemV2DTO, ICartItemV2Update } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

/**
 * Consumers of this component should be aware of the following two utils that must
 * be used wherever this component is consumed:
 * - ItemEditorV2Utils.isItemComplete - returns true if all required fields are complete
 * - ItemEditorV2Utils.cleanItem - cleans out any old values (prefs, data fields) that no longer apply
 * Use those utils before submitting items to the backend to ensure that they're validly formed
 * and don't contain any data that's no longer relevant.
 */

export interface ItemEditorV2Props {
    item: ICartItemV2DTO;
    updateItem: (update: ICartItemV2Update) => void;
    isInternal: boolean;
    doctorPreferencesId: string;
    // when true, error messages will appear for fields that are not complete
    showError: boolean;
    autoFocusOnInstructions?: boolean;
}

export const ItemEditorV2: React.VFC<ItemEditorV2Props> = ({
    item,
    updateItem,
    isInternal,
    doctorPreferencesId,
    showError,
    autoFocusOnInstructions,
}) => {
    const { data: implantTypes } = useGetImplantTypesLiteQuery({ variables: { withDeleted: false } });

    return (
        <Grid container direction={'column'} spacing={3}>
            <ItemEditorV2GeneralSection
                item={item}
                updateItem={updateItem}
                showError={showError}
                isInternal={isInternal}
            />
            <ItemEditorV2ImplantInfoSection
                item={item}
                updateItem={updateItem}
                showError={showError}
                isInternal={isInternal}
                implantSystems={implantTypes?.getImplantTypesLite ?? []}
            />
            {isInternal && (
                <ItemEditorV2ImplantPartsSection
                    item={item}
                    updateItem={updateItem}
                    implantSystems={implantTypes?.getImplantTypesLite ?? []}
                />
            )}
            <ItemEditorV2SurgicalGuideImplantMetadataSection
                item={item}
                updateItem={updateItem}
                showError={showError}
                implantSystems={implantTypes?.getImplantTypesLite ?? []}
            />
            <ItemEditorV2DataFieldsSection item={item} updateItem={updateItem} showError={showError} />
            <ItemEditorV2PreferencesSection
                item={item}
                updateItem={updateItem}
                showError={showError}
                doctorPreferencesId={doctorPreferencesId}
                isInternal={isInternal}
            />
            <ItemEditorV2NotesSection item={item} updateItem={updateItem} autoFocus={autoFocusOnInstructions} />
        </Grid>
    );
};
