import copy from 'clipboard-copy';
import { useSnackbar } from 'notistack';
import React from 'react';

interface CopyToClipboard {
    text: string;
    successMessage?: string;
    errorMessage?: string;
}

export const useCopyToClipboard = () => {
    const { enqueueSnackbar } = useSnackbar();

    return React.useCallback(
        ({ text, successMessage, errorMessage }: CopyToClipboard) => {
            copy(text).then(
                () => enqueueSnackbar(successMessage || 'Copied to clipboard!', { variant: 'success' }),
                () => enqueueSnackbar(errorMessage || 'Failed to copy :(', { variant: 'error' }),
            );
        },
        [enqueueSnackbar],
    );
};
