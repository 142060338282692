import type { IStructuredDoctorNote } from '../../../../LabSlip.types';
import { PracticeIcon } from '@orthly/ui';
import { stylesFactory, Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    subSection: {
        marginBottom: '0.25rem',
    },
    subSectionTitle: {
        fontSize: '1rem !important',
        fontWeight: 'bold',
    },
    note: {
        fontSize: '0.75rem !important',
        whiteSpace: 'pre-line',
    },
    noteHeader: {
        fontSize: '0.9rem !important',
        fontWeight: 'bold',
    },
}));

/**
 * Renders an individual doctored note from an IStructuredDoctorNote
 * @param {IStructuredDoctorNote} note Structured note containing the notes content and optional heading
 * @returns {JSX.Element}
 */
const DoctorNote: React.VFC<{ note: IStructuredDoctorNote }> = ({ note }) => {
    const classes = useStyles();
    return (
        <Grid container>
            {note.header && (
                <Grid container>
                    <Text variant={'h5'} className={classes.noteHeader}>
                        {note.header}
                    </Text>
                </Grid>
            )}
            <Grid container>
                <Text variant={'body1'} className={classes.note}>
                    {note.note}
                </Text>
            </Grid>
        </Grid>
    );
};

/**
 * LabSlip section for displaying notes from the doctor
 * @param doctorNotes The doctor_notes property of an Order
 * @returns DoctorNotes section
 */
export const DoctorNotes: React.VFC<{ doctorNotes: IStructuredDoctorNote[] }> = ({ doctorNotes }) => {
    const classes = useStyles();

    if (!(doctorNotes && doctorNotes.length)) {
        return null;
    }

    return (
        <Grid container className={classes.subSection}>
            <Grid container spacing={1}>
                <Grid item>
                    <PracticeIcon />
                </Grid>
                <Grid item>
                    <Text variant={'h5'} className={classes.subSectionTitle}>
                        Doctor Notes
                    </Text>
                </Grid>
            </Grid>
            <Grid container>
                {doctorNotes.map((note, idx) => {
                    return <DoctorNote key={idx} note={note} />;
                })}
            </Grid>
        </Grid>
    );
};
