import type { CaseMetadata } from './CaseMetadata';
import type { ScansRecord, ScanMeshes } from './FinishingApp.types';
import type { IOperationsManager } from './OperationsManager.types';
import type { HeatMapType } from '@orthly/forceps';
import type { ToothNumber } from '@orthly/items';
import type * as THREE from 'three';

export const scanKeys = ['lower', 'upper'] as const;
export type ScanKey = (typeof scanKeys)[number];

export type ScanData<T> = { [K in ScanKey]?: T };

export interface SceneModelAppearance {
    visible: boolean;
    transparent: boolean;
    colorize?: boolean;
}

export interface Range {
    min: number;
    max: number;
}

export type SceneAppearance = {
    scans: ScansRecord<SceneModelAppearance>;
    restoratives: Map<ToothNumber, SceneModelAppearance>;

    marginLinesVisible: boolean;
    collisionsVisible: boolean;
    collisionsCurtainsVisible: boolean;
    curtainsVisible: boolean;
    insertionPathsVisible: boolean;

    activeHeatMap: HeatMapType | undefined;
    curtainsHeatMapEnabled: boolean;
    heatmapRange: Range;
    isMaskVisible: boolean;
    scanUndercutHeatmapEnabled: boolean;
};

export interface ISceneAppearanceInspector {
    getBoundingSphereForVisible: () => THREE.Sphere;
    getRestorativeBoundingSphere: (toothNumber: ToothNumber) => THREE.Sphere;
}

// Interface for an object that manages the scene
export interface ISceneAppearanceManager extends ISceneAppearanceInspector {
    scene: THREE.Scene;

    setRestorativeVisibility: (toothNumber: ToothNumber, visible?: boolean) => void;
    setRestorativeTransparency: (toothNumber: ToothNumber, transparent?: boolean) => void;

    setJawVisibility: (name: keyof ScansRecord<any>, visible?: boolean) => void;
    setJawTransparency: (name: keyof ScansRecord<any>, transparent?: boolean) => void;
    setJawColorize: (name: keyof ScansRecord<any>, colorize?: boolean) => void;

    setMarginLinesVisibility: (visible?: boolean) => void;
    setCollisionsVisibility: (visible?: boolean) => void;
    setCollisionsCurtainsVisibility: (visible?: boolean) => void;
    setCurtainsVisibility: (visible?: boolean) => void;
    setInsertionPathsVisibility: (visible?: boolean) => void;
    togglePrePrepScanVisibility: (visible?: boolean) => void;
    togglePrepScanVisibility: (visible?: boolean) => void;
    toggleAntagonistScanVisibility: (visible?: boolean) => void;
    toggleRestorativesVisibility: () => void;
    toggleScansColorize: (colorize?: boolean) => void;
    toggleScanUndercutEnabled: (scanUnderCutEnabled?: boolean) => void;

    onInsertionAxisAdjusterHover: (rotationAxis: THREE.Vector3 | undefined) => void;

    setActiveHeatMap: (heatMap?: HeatMapType) => void;
    setCurtainsHeatMapEnabled: (enabled: boolean) => void;
    setHeatmapRange: (range: Range) => void;

    getSculptingGroup: () => THREE.Group;
    getCurtains: (toothNumber: ToothNumber) => THREE.BufferGeometry | undefined;
    getRestorativeMeshes: () => ReadonlyMap<ToothNumber, THREE.Mesh<THREE.BufferGeometry, THREE.Material>>;

    getScanMeshes(): ScanMeshes | null;

    createOcclusalSceneManager: (
        setAppearance: PartialSceneAppearanceSetter,
        restorationJaw: CaseMetadata['restorationJaw'],
    ) => IPartialSceneAppearanceManager;
    createInsertionSceneManager: (setAppearance: PartialSceneAppearanceSetter) => IPartialSceneAppearanceManager;
    createProximalSceneManager: (setAppearance: PartialSceneAppearanceSetter) => IPartialSceneAppearanceManager;

    toggleIsMaskVisible: () => void;
    disableMaskVisible: () => void;
}

export interface SceneAppearanceState {
    appearance: SceneAppearance;
    manager: ISceneAppearanceManager;
}

// Appearance state of a scene with limited functionality. Null values indicate the state is not applicable to current
// scene because there is no corresponding mesh.
export interface PartialSceneAppearance {
    collisionsVisible: boolean;
    collisionsCurtainsVisible: boolean;
    curtainsVisible: boolean;
    insertionPathsVisible: boolean;

    thicknessHeatmapEnabled: boolean;
    proximalHeatmapEnabled: boolean;
    occlusalHeatmapEnabled: boolean;
    undercutHeatmapEnabled: boolean;

    scanUndercutEnabled: boolean;

    heatmapRange: Range;

    prePrepScansVisible: boolean;
    antagonistScansVisible: boolean;
    restorativesVisible: boolean;
}

export type PartialSceneAppearanceSetter = (appearance: PartialSceneAppearance) => void;

export interface AppearanceToggles {
    toggleCollisionsVisibility: () => void;
    toggleCollisionsCurtainsVisibility: () => void;
    toggleCurtainsVisibility?: () => void;
    toggleInsertionPathsVisibility?: () => void;

    togglePrepScanVisibility?: () => void;
    togglePrePrepScanVisibility?: () => void;
    toggleAntagonistScanVisibility?: () => void;

    toggleThicknessHeatmapEnabled?: (enabled?: boolean) => void;
    toggleProximalHeatmapEnabled?: (enabled?: boolean) => void;
    toggleOcclusalHeatmapEnabled?: (enabled?: boolean) => void;
    toggleUndercutHeatmapEnabled?: (enabled?: boolean) => void;
    toggleCementGapHeatmapEnabled?: (enabled?: boolean) => void;

    toggleScanUndercutEnabled?: (enabled?: boolean) => void;

    toggleRestorativesVisibility?: () => void;
}

type AppearanceManagerSuper = ISceneAppearanceInspector & AppearanceToggles;

// Interface for an object with partial functionality for managing the scene
export interface IPartialSceneAppearanceManager extends AppearanceManagerSuper {
    scene: THREE.Scene;

    getRestorativeMeshes: () => ReadonlyMap<ToothNumber, THREE.Mesh<THREE.BufferGeometry, THREE.Material>>;
    getScanMeshes: () => ScanMeshes | null;

    setHeatmapRange: (range: Range) => void;
}

export interface PartialSceneAppearanceState {
    appearance: PartialSceneAppearance;
    manager: IPartialSceneAppearanceManager;
}

export type MinimalOperationsManager = Pick<
    IOperationsManager,
    'editToothNumber' | 'registerInsertionAxisChangedCallback' | 'editInsertionOrientation'
>;
