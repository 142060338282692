/* eslint-disable max-lines */

/* eslint-disable @typescript-eslint/no-use-before-define */
import { useUnitMaterialOptions } from '../hooks/useUnitMaterialOptions';
import type { LabsGqlListAbutmentPartsQuery } from '@orthly/graphql-operations';
import { useListAbutmentPartsQuery, useGetScanbodiesQuery } from '@orthly/graphql-react';
import type { LabsGqlLabOrderItemCondition } from '@orthly/graphql-schema';
import { LabsGqlLabOrderItemConditionType } from '@orthly/graphql-schema';
import type { ToothGroup } from '@orthly/items';
import { AllTeethShades, AllItemMetafields, LabOrderItemSKUType, AllToothGroups } from '@orthly/items';
import type { CustomQFComponentProps } from '@orthly/ui';
import {
    LoadBlocker,
    SimpleMultiSelect,
    SimpleSelect,
    SimpleTextField,
    SimpleToggle,
    SimpleCheckbox,
    SimpleInput,
} from '@orthly/ui';
import { FlossPalette, Text, Grid, IconButton, AddIcon, DeleteIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export type LabOrderItemConditionEditorValue =
    | {
          type: LabsGqlLabOrderItemConditionType.Or | LabsGqlLabOrderItemConditionType.And;
          list: LabOrderItemConditionEditorValue[];
      }
    | {
          type: LabsGqlLabOrderItemConditionType.Not;
          condition: LabOrderItemConditionEditorValue;
      }
    | {
          type:
              | LabsGqlLabOrderItemConditionType.ToothGroups
              | LabsGqlLabOrderItemConditionType.Sku
              | LabsGqlLabOrderItemConditionType.UnitType
              | LabsGqlLabOrderItemConditionType.MaterialType
              | LabsGqlLabOrderItemConditionType.Shade
              | LabsGqlLabOrderItemConditionType.AbutmentPartManufacturer
              | LabsGqlLabOrderItemConditionType.ScanbodyManufacturer;
          values: string[];
      }
    | { type: LabsGqlLabOrderItemConditionType.MaxUnitsPerItem; units: number }
    | { type: LabsGqlLabOrderItemConditionType.FieldValue; field_id: string; values: string[] }
    | { type: LabsGqlLabOrderItemConditionType.AbutmentPartIsOem; is_oem: boolean }
    | { type: '' };

export const labOrderItemConditionToEditorValue = (
    cond: LabsGqlLabOrderItemCondition,
): LabOrderItemConditionEditorValue => {
    if (cond.Or) {
        return {
            type: LabsGqlLabOrderItemConditionType.Or,
            list: cond.Or.map(labOrderItemConditionToEditorValue),
        };
    }
    if (cond.And) {
        return {
            type: LabsGqlLabOrderItemConditionType.And,
            list: cond.And.map(labOrderItemConditionToEditorValue),
        };
    }
    if (cond.Not) {
        return { type: LabsGqlLabOrderItemConditionType.Not, condition: labOrderItemConditionToEditorValue(cond.Not) };
    }
    if (cond.ToothGroups) {
        return { type: LabsGqlLabOrderItemConditionType.ToothGroups, values: cond.ToothGroups.values };
    }
    if (cond.Sku) {
        return { type: LabsGqlLabOrderItemConditionType.Sku, values: cond.Sku.values };
    }
    if (cond.UnitType) {
        return { type: LabsGqlLabOrderItemConditionType.UnitType, values: cond.UnitType.values };
    }
    if (cond.MaterialType) {
        return { type: LabsGqlLabOrderItemConditionType.MaterialType, values: cond.MaterialType.values };
    }
    if (cond.Shade) {
        return { type: LabsGqlLabOrderItemConditionType.Shade, values: cond.Shade.values };
    }
    if (cond.MaxUnitsPerItem) {
        return { type: LabsGqlLabOrderItemConditionType.MaxUnitsPerItem, units: cond.MaxUnitsPerItem };
    }
    if (cond.FieldValue) {
        return {
            type: LabsGqlLabOrderItemConditionType.FieldValue,
            field_id: cond.FieldValue.field_id,
            values: cond.FieldValue.values,
        };
    }
    if (cond.AbutmentPartManufacturer) {
        return {
            type: LabsGqlLabOrderItemConditionType.AbutmentPartManufacturer,
            values: cond.AbutmentPartManufacturer.values,
        };
    }
    if (typeof cond.AbutmentPartIsOEM === 'boolean') {
        return { type: LabsGqlLabOrderItemConditionType.AbutmentPartIsOem, is_oem: cond.AbutmentPartIsOEM };
    }
    if (cond.ScanbodyManufacturer) {
        return {
            type: LabsGqlLabOrderItemConditionType.ScanbodyManufacturer,
            values: cond.ScanbodyManufacturer.values,
        };
    }
    return { type: '' };
};

export const editorValueToLabOrderItemCondition = (
    val: LabOrderItemConditionEditorValue,
): LabsGqlLabOrderItemCondition => {
    switch (val.type) {
        case LabsGqlLabOrderItemConditionType.Or:
        case LabsGqlLabOrderItemConditionType.And:
            return { [val.type]: val.list.map(editorValueToLabOrderItemCondition) };
        case LabsGqlLabOrderItemConditionType.Not:
            return { [val.type]: editorValueToLabOrderItemCondition(val.condition) };
        case LabsGqlLabOrderItemConditionType.ToothGroups:
        case LabsGqlLabOrderItemConditionType.Sku:
        case LabsGqlLabOrderItemConditionType.UnitType:
        case LabsGqlLabOrderItemConditionType.MaterialType:
        case LabsGqlLabOrderItemConditionType.Shade:
        case LabsGqlLabOrderItemConditionType.AbutmentPartManufacturer:
        case LabsGqlLabOrderItemConditionType.ScanbodyManufacturer:
            return { [val.type]: { values: val.values } };
        case LabsGqlLabOrderItemConditionType.MaxUnitsPerItem:
            return { [val.type]: val.units };
        case LabsGqlLabOrderItemConditionType.FieldValue:
            return { [val.type]: { field_id: val.field_id, values: val.values } };
        case LabsGqlLabOrderItemConditionType.AbutmentPartIsOem:
            return { [val.type]: val.is_oem };
        default:
            return {};
    }
};

const emptyEditorValueForConditionType = (
    typ: LabsGqlLabOrderItemConditionType | string | undefined,
): LabOrderItemConditionEditorValue => {
    switch (typ) {
        case LabsGqlLabOrderItemConditionType.Or:
        case LabsGqlLabOrderItemConditionType.And:
            return { type: typ, list: [] };
        case LabsGqlLabOrderItemConditionType.Not:
            return { type: typ, condition: { type: '' } };
        case LabsGqlLabOrderItemConditionType.ToothGroups:
        case LabsGqlLabOrderItemConditionType.Sku:
        case LabsGqlLabOrderItemConditionType.UnitType:
        case LabsGqlLabOrderItemConditionType.MaterialType:
        case LabsGqlLabOrderItemConditionType.Shade:
        case LabsGqlLabOrderItemConditionType.AbutmentPartManufacturer:
        case LabsGqlLabOrderItemConditionType.ScanbodyManufacturer:
            return { type: typ, values: [] };
        case LabsGqlLabOrderItemConditionType.MaxUnitsPerItem:
            return { type: typ, units: 1 };
        case LabsGqlLabOrderItemConditionType.FieldValue:
            return { type: typ, field_id: '', values: [] };
        case LabsGqlLabOrderItemConditionType.AbutmentPartIsOem:
            return { type: typ, is_oem: false };
        default:
            return { type: '' };
    }
};

type LabOrderItemConditionEditorProps<V extends LabOrderItemConditionEditorValue = LabOrderItemConditionEditorValue> = {
    onChange: (value: V) => void;
    value: V;
};

type LabOrderItemConditionEditorCombinatorProps = LabOrderItemConditionEditorProps<
    Extract<
        LabOrderItemConditionEditorValue,
        {
            type: LabsGqlLabOrderItemConditionType.Or | LabsGqlLabOrderItemConditionType.And;
        }
    >
>;

const LabOrderItemConditionEditorCombinator = (props: LabOrderItemConditionEditorCombinatorProps) => {
    const setAtIndex = (index: number, val: LabOrderItemConditionEditorValue) =>
        props.onChange({
            type: props.value.type,
            list: [...props.value.list.slice(0, index), val, ...props.value.list.slice(index + 1)],
        });
    const deleteAtIndex = (index: number) =>
        props.onChange({
            type: props.value.type,
            list: [...props.value.list.slice(0, index), ...props.value.list.slice(index + 1)],
        });
    const addItem = () => {
        props.onChange({
            type: props.value.type,
            list: [...props.value.list, { type: '' }],
        });
    };
    return (
        <Grid container style={{ paddingLeft: '1em', paddingTop: '0.5em' }} spacing={1}>
            {props.value.list.map((alt, idx) => (
                <Grid key={`${idx}-${alt.type}`} item xs={12} container alignItems={'center'}>
                    <Grid item xs={1}>
                        <IconButton onClick={() => deleteAtIndex(idx)}>
                            <DeleteIcon style={{ color: FlossPalette.ATTENTION }} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <LabOrderItemConditionEditor value={alt} onChange={val => setAtIndex(idx, val)} />
                    </Grid>
                </Grid>
            ))}
            <Grid item xs={12} container alignItems={'center'}>
                <Grid item xs={1}>
                    <IconButton onClick={() => addItem()}>
                        <AddIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={11}>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        Add a new condition
                    </Text>
                </Grid>
            </Grid>
        </Grid>
    );
};

type LabOrderItemConditionEditorNegatedProps = LabOrderItemConditionEditorProps<
    Extract<LabOrderItemConditionEditorValue, { type: LabsGqlLabOrderItemConditionType.Not }>
>;

const LabOrderItemConditionEditorNegated = (props: LabOrderItemConditionEditorNegatedProps) => {
    return (
        <Grid container style={{ paddingLeft: '1em' }}>
            <Grid item xs={12}>
                <LabOrderItemConditionEditor
                    value={props.value.condition}
                    onChange={condition => props.onChange({ condition, type: props.value.type })}
                />
            </Grid>
        </Grid>
    );
};

type LabOrderItemConditionEditorValuesProps = LabOrderItemConditionEditorProps<
    Extract<
        LabOrderItemConditionEditorValue,
        {
            type:
                | LabsGqlLabOrderItemConditionType.ToothGroups
                | LabsGqlLabOrderItemConditionType.Sku
                | LabsGqlLabOrderItemConditionType.UnitType
                | LabsGqlLabOrderItemConditionType.MaterialType
                | LabsGqlLabOrderItemConditionType.Shade
                | LabsGqlLabOrderItemConditionType.AbutmentPartManufacturer
                | LabsGqlLabOrderItemConditionType.ScanbodyManufacturer;
        }
    >
>;

const getEnabledGroupSelections = (selected: ToothGroup[]) => {
    const mutuallyExclusive: ToothGroup[][] = [
        ['Anterior', 'Posterior'],
        ['Upper', 'Lower', 'Dual'],
    ];
    return [
        ...AllToothGroups.filter(group => {
            // Anything already selected is enabled (so it can be deselected)
            if (selected.includes(group)) {
                return true;
            }
            const opposite = mutuallyExclusive.find(p => p.includes(group))?.filter(g => g !== group)[0];
            return !opposite || !selected.includes(opposite);
        }),
    ];
};

const getAbutmentPartManufacturers = (abutmentParts: LabsGqlListAbutmentPartsQuery | undefined) => {
    if (abutmentParts === undefined) {
        return [];
    }
    const values = abutmentParts.listAbutmentParts.map(abutmentPart => abutmentPart.manufacturer);
    // this manufacturer is used to enable the planning engine to make an assignment at order creation time
    // the vast majority (~96%) of implants are currently insourced and all go to Lehi, so we just need
    // to be able to set up a rule _that wont affect the existing routing engine_ to handle the case for
    // the planning engine, to avoid creating a constant in some random package, this value is duplicated
    // both here and in the planning engine itself
    values.push('Unknown Manufacturer for Planning Engine');
    return _.uniq(values);
};

const LabOrderItemConditionEditorValues = (props: LabOrderItemConditionEditorValuesProps) => {
    const { values: currentValues, type: currentType } = props.value;
    const { unitTypes, materialTypes, loading: loadingUnitMaterialOpts } = useUnitMaterialOptions();
    const { data: abutmentParts, loading: abutmentPartsLoading } = useListAbutmentPartsQuery({
        variables: { withArchived: false },
        fetchPolicy: 'cache-first',
    });
    const abutmentPartManufacturers = getAbutmentPartManufacturers(abutmentParts);

    const { data: scanbodies, loading: scanbodiesLoading } = useGetScanbodiesQuery({
        variables: { withDeleted: false },
    });
    const scanbodyManufacturers = _.uniq(scanbodies?.getScanbodies?.map(scanbody => scanbody.manufacturer) ?? []);

    const isToothGroup = currentType === 'ToothGroups';

    const enabledGroupSelections = isToothGroup ? getEnabledGroupSelections(currentValues as ToothGroup[]) : [];
    const rawOptions = React.useMemo(
        () =>
            ({
                ToothGroups: [...AllToothGroups],
                Sku: Object.values(LabOrderItemSKUType),
                UnitType: unitTypes,
                MaterialType: materialTypes,
                Shade: [...AllTeethShades],
                AbutmentPartManufacturer: abutmentPartManufacturers,
                ScanbodyManufacturer: scanbodyManufacturers,
            })[currentType],
        [unitTypes, materialTypes, abutmentPartManufacturers, scanbodyManufacturers, currentType],
    );
    return (
        <Grid container>
            <Grid item xs={12}>
                <LoadBlocker blocking={loadingUnitMaterialOpts || abutmentPartsLoading || scanbodiesLoading}>
                    <SimpleMultiSelect
                        label={'Values'}
                        SelectProps={{ fullWidth: true, variant: 'standard' }}
                        options={_.sortBy(rawOptions, m => m.trim().toLowerCase()).map(value => ({
                            value,
                            disabled: isToothGroup ? !enabledGroupSelections.includes(value as ToothGroup) : false,
                        }))}
                        value={currentValues}
                        onChange={values => props.onChange({ values: values ?? [], type: currentType })}
                    />
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};
const LabOrderItemConditionEditorFieldValues: React.VFC<
    LabOrderItemConditionEditorProps<
        Extract<LabOrderItemConditionEditorValue, { type: LabsGqlLabOrderItemConditionType.FieldValue }>
    >
> = props => {
    const availableFields = AllItemMetafields.filter(({ type }) => type === 'select' || type === 'boolean');
    const fieldById = (id: string) => availableFields.find(f => f.id === id);
    const selectedField = fieldById(props.value.field_id);
    return (
        <Grid container>
            <Grid item xs={12}>
                <SimpleSelect
                    label={'Field'}
                    SelectProps={{ fullWidth: true, variant: 'standard' }}
                    value={props.value.field_id}
                    options={_.sortBy(availableFields, m => m.name.trim().toLowerCase()).map(f => ({
                        value: f.id,
                        label: f.name,
                    }))}
                    onChange={value =>
                        props.onChange({
                            type: props.value.type,
                            field_id: value ?? '',
                            values: fieldById(value ?? ``)?.type === 'boolean' ? [`true`] : [],
                        })
                    }
                />
            </Grid>
            <Grid item xs={12}>
                {selectedField && selectedField.type === 'select' && (
                    <SimpleMultiSelect
                        SelectProps={{ fullWidth: true, variant: 'standard' }}
                        label={'Values'}
                        value={props.value.values}
                        options={(selectedField.options ?? []).map(v => ({
                            value: v.value,
                            label: v.label ?? v.value,
                        }))}
                        onChange={values =>
                            props.onChange({
                                type: props.value.type,
                                field_id: selectedField.id,
                                values: values ?? [],
                            })
                        }
                    />
                )}
                {selectedField && selectedField.type === 'boolean' && (
                    <SimpleCheckbox
                        label={selectedField.label}
                        checked={props.value.values[0] === `true`}
                        setChecked={checked =>
                            props.onChange({
                                type: props.value.type,
                                field_id: selectedField.id,
                                values: [checked ? `true` : `false`],
                            })
                        }
                    />
                )}
            </Grid>
        </Grid>
    );
};

const LabOrderItemConditionEditorMaxUnits: React.VFC<
    LabOrderItemConditionEditorProps<
        Extract<LabOrderItemConditionEditorValue, { type: LabsGqlLabOrderItemConditionType.MaxUnitsPerItem }>
    >
> = ({ value, onChange }) => (
    <Grid container>
        <Grid item xs={12}>
            <SimpleInput
                TextFieldProps={{ type: `number`, fullWidth: true, inputProps: { min: 1 } }}
                label={`Max Units Per Item`}
                value={`${value.units}`}
                onChange={value =>
                    onChange({
                        type: LabsGqlLabOrderItemConditionType.MaxUnitsPerItem,
                        units: parseInt(value ?? `1`),
                    })
                }
            />
        </Grid>
    </Grid>
);

const LabOrderConditionEditorIsOEM: React.VFC<
    LabOrderItemConditionEditorProps<
        Extract<LabOrderItemConditionEditorValue, { type: LabsGqlLabOrderItemConditionType.AbutmentPartIsOem }>
    >
> = ({ value, onChange }) => (
    <Grid container>
        <Grid item xs={12}>
            <SimpleCheckbox
                label={`OEM?`}
                checked={value.is_oem}
                setChecked={value =>
                    onChange({
                        type: LabsGqlLabOrderItemConditionType.AbutmentPartIsOem,
                        is_oem: value,
                    })
                }
            />
        </Grid>
    </Grid>
);

const LabOrderItemConditionEditorFields = (props: LabOrderItemConditionEditorProps) => {
    switch (props.value.type) {
        case LabsGqlLabOrderItemConditionType.Or:
        case LabsGqlLabOrderItemConditionType.And:
            return <LabOrderItemConditionEditorCombinator value={props.value} onChange={props.onChange} />;
        case LabsGqlLabOrderItemConditionType.Not:
            return <LabOrderItemConditionEditorNegated value={props.value} onChange={props.onChange} />;
        case LabsGqlLabOrderItemConditionType.ToothGroups:
        case LabsGqlLabOrderItemConditionType.Sku:
        case LabsGqlLabOrderItemConditionType.UnitType:
        case LabsGqlLabOrderItemConditionType.MaterialType:
        case LabsGqlLabOrderItemConditionType.Shade:
        case LabsGqlLabOrderItemConditionType.AbutmentPartManufacturer:
        case LabsGqlLabOrderItemConditionType.ScanbodyManufacturer:
            return <LabOrderItemConditionEditorValues onChange={props.onChange} value={props.value} />;
        case LabsGqlLabOrderItemConditionType.MaxUnitsPerItem:
            return <LabOrderItemConditionEditorMaxUnits onChange={props.onChange} value={props.value} />;
        case LabsGqlLabOrderItemConditionType.FieldValue:
            return <LabOrderItemConditionEditorFieldValues onChange={props.onChange} value={props.value} />;
        case LabsGqlLabOrderItemConditionType.AbutmentPartIsOem:
            return <LabOrderConditionEditorIsOEM onChange={props.onChange} value={props.value} />;
        default:
            return null;
    }
};

export const LabOrderItemConditionEditor = (props: LabOrderItemConditionEditorProps) => {
    const setType = (typ: LabsGqlLabOrderItemConditionType | string | undefined) =>
        props.onChange(emptyEditorValueForConditionType(typ));
    return (
        <Grid container>
            <Grid item xs={12}>
                <SimpleSelect
                    SelectProps={{ fullWidth: true, variant: 'standard' }}
                    label={'Item Condition'}
                    onChange={setType}
                    value={props.value.type}
                    options={[
                        { value: '', label: 'Choose...' },
                        ...Object.values(LabsGqlLabOrderItemConditionType).map(value => ({ value })),
                    ]}
                />
            </Grid>
            <Grid item xs={12}>
                <LabOrderItemConditionEditorFields {...props} />
            </Grid>
        </Grid>
    );
};

const labOrderItemConditionJSONToEditorValue = (conditionJSON: string): LabOrderItemConditionEditorValue => {
    try {
        const parsed = JSON.parse(conditionJSON);
        return labOrderItemConditionToEditorValue(parsed);
    } catch (e: any) {
        console.error(e);
        return { type: '' };
    }
};

export const LabOrderItemConditionField = (props: CustomQFComponentProps<{}>) => {
    const { field, form } = props;
    const rawFieldValue: string = field.value || '';
    const fieldValue: LabOrderItemConditionEditorValue =
        !field.value || field.value === `null` ? { type: '' } : labOrderItemConditionJSONToEditorValue(field.value);
    const [useTextEditor, setUseTextEditor] = React.useState(false);
    return (
        <Grid container>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
                <SimpleToggle label={'Edit as JSON'} checked={useTextEditor} onChange={setUseTextEditor} />
            </Grid>
            {useTextEditor ? (
                <Grid item xs={12}>
                    <SimpleTextField
                        label={'Condition JSON'}
                        TextFieldProps={{ multiline: true, rows: 10 }}
                        onChange={val => form.setFieldValue(field.name, val ?? '', true)}
                        value={rawFieldValue}
                    />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <LabOrderItemConditionEditor
                        value={fieldValue}
                        onChange={val => {
                            form.setFieldTouched(field.name, true);
                            form.setFieldValue(
                                field.name,
                                JSON.stringify(editorValueToLabOrderItemCondition(val)),
                                true,
                            );
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
    /* eslint-disable max-lines */
};
