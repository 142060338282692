import { PartnerBillingChangelogViewerDialog } from '../PartnerBillingChangelogViewer';
import { CreateBillingCreditAction } from '../actions/CreateBillingCredit.graphql';
import { CreateOrPreviewUsageInvoice } from '../actions/CreateOrPreviewUsageInvoice.graphql';
import { EditAccountManagementContact } from '../actions/EditAccountManagementContact.graphql';
import { EditBillingFlag } from '../actions/EditBillingFlag.graphql';
import { EditPaymentSplitConfig } from '../actions/EditPaymentSplitConfig/EditPaymentSplitConfig';
import { UpdatePrimaryBillingContactUser } from '../actions/UpdatePrimaryBilliingContactUser.graphql';
import { EditBillingContact } from '../actions/edit-billing-contact/EditBillingContact.graphql';
import type { PartnerBillingOverviewData } from '../types';
import { activationDate, nextInvoiceDate } from './BillingDetails.utils';
import { GrayBooleanIcon } from './components/GrayBooleanIcon';
import { OrganizationOverviewImpersonationButton } from './components/OrganizationOverviewImpersonateButton';
import { useBillingDetailsContext } from './providers/BillingDetailsProvider.graphql';
import { LabsGqlPartnerBillingBoolean, LabsGqlStripeInvoiceStatus } from '@orthly/graphql-schema';
import { LoadBlocker } from '@orthly/ui';
import { Button, FlossPalette, Icon, LaunchIcon, Link, PopOutIcon, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

export const OVERVIEW_CONTAINER_WIDTH = 440;
export const OVERVIEW_CONTAINER_GAP = 16;

const Container = styled('div')({
    width: `${OVERVIEW_CONTAINER_WIDTH}px`,
    flexShrink: 0,
    padding: '24px',
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    borderRadius: '16px',
    backgroundColor: FlossPalette.TAN,
    '&+&': {
        marginLeft: `${OVERVIEW_CONTAINER_GAP}px`,
    },
});

const OrganizationOverviewContainer = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const ActivationContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: '16px',
    height: '32px',
});

const NameContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '12px',
});

const ContractDesignationContainer = styled('div')({
    borderRadius: '8px',
    backgroundColor: FlossPalette.BLACK,
    color: FlossPalette.WHITE,
    padding: '4px 8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const ContractDesignationText = styled(Text)({
    marginLeft: '8px',
});

const ActivationText = styled(Text)({
    marginLeft: 'auto',
});

const RowContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    height: '40px',
    flexShrink: 0,
    alignItems: 'center',
    flexWrap: 'nowrap',
    '&+&': {
        borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    },
    '&:last-of-type': {
        height: 'unset',
    },
});

const RowLabelText = styled(Text)({
    flexShrink: 0,
    marginRight: '16px',
});

const RowValueContainer = styled('div')({
    marginLeft: 'auto',
    flexShrink: 0,
});

const OverviewRows: (organization: PartnerBillingOverviewData) => {
    label: string;
    field?: keyof PartnerBillingOverviewData;
    render?: (organization: PartnerBillingOverviewData) => JSX.Element | string | number;
}[] = organization => {
    const { refetchPractice, invoices, refetchInvoices, getNextInvoiceStatusForOrg, getOrgNeedsBillingSummaryEmail } =
        useBillingDetailsContext();
    const organizationInvoices = invoices.filter(i => i.organization_id === organization.id);
    const nextInvoiceStatus = getNextInvoiceStatusForOrg(organization.id);

    return [
        {
            label: 'Primary Billing Contact',
            render: r => (
                <UpdatePrimaryBillingContactUser
                    practiceId={r.id}
                    primaryBillingContactUserId={r.primary_billing_contact_user_id}
                    refetch={refetchPractice}
                />
            ),
        },
        {
            label: 'Secondary Contact',
            render: organization => (
                <EditBillingContact
                    practiceId={organization.id}
                    contactEmails={organization.contact_emails}
                    refetch={refetchPractice}
                />
            ),
        },
        {
            label: 'Account Management Contacts',
            render: organization => (
                <EditAccountManagementContact
                    practiceId={organization.id}
                    acctManagementContactEmails={organization.account_management_contact_emails}
                    refetch={refetchPractice}
                />
            ),
        },
        {
            label: 'Salesforce Account',
            render: r =>
                !!r.salesforceId ? (
                    <Link
                        href={`https://meetdandy.lightning.force.com/lightning/r/Account/${r.salesforceId}/view`}
                        sx={{ marginRight: '8px', verticalAlign: 'text-top' }}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                    >
                        <PopOutIcon />
                    </Link>
                ) : (
                    'not found'
                ),
        },
        {
            label: 'Next Invoice Date',
            render: () => (
                <Text variant={'caption'} color={'DARK_GRAY'} sx={{ marginRight: '12px' }}>
                    {nextInvoiceDate(nextInvoiceStatus?.will_be_invoiced, organizationInvoices ?? [])}
                </Text>
            ),
        },
        {
            label: 'Credit Card Processing Fees',
            render: organization => <GrayBooleanIcon val={organization.will_be_charged_cc_fee} />,
        },
        {
            label: 'Autocharge',
            render: organization => (
                <EditBillingFlag
                    practiceId={organization.id}
                    currentValue={organization.autocharge_enabled}
                    property={LabsGqlPartnerBillingBoolean.AutochargeEnabled}
                    refetch={refetchPractice}
                />
            ),
        },
        {
            label: 'Is on Payment Plan?',
            render: r => (
                <EditBillingFlag
                    practiceId={r.id}
                    currentValue={r.is_on_payment_plan}
                    property={LabsGqlPartnerBillingBoolean.IsOnPaymentPlan}
                    refetch={refetchPractice}
                />
            ),
        },
        {
            label: 'Upcoming Usage Invoice',
            render: organization => (
                <CreateOrPreviewUsageInvoice
                    organizationId={organization.id}
                    nextInvoiceStatus={nextInvoiceStatus}
                    mode={'preview'}
                    refetch={refetchInvoices}
                />
            ),
        },
        {
            label: 'Usage Invoice Split Config',
            render: r => <EditPaymentSplitConfig practiceId={r.id} config={r.usage_payment_source_config} />,
        },
        {
            label: 'Needs Summary Email?',
            render: r => <GrayBooleanIcon val={getOrgNeedsBillingSummaryEmail(r.id)} />,
        },
        {
            label: 'Unpaid Invoices',
            render: () => (
                <Text sx={{ marginRight: '15px' }}>
                    {(organizationInvoices ?? []).filter(i => i.status === LabsGqlStripeInvoiceStatus.Open).length}
                </Text>
            ),
        },
        {
            label: 'View Configuration History',
            render: r => <PartnerBillingChangelogViewerDialog partner_id={r.id} />,
        },
    ];
};

const OrganizationOverviewRow: React.FC<{
    label: string;
    organization: PartnerBillingOverviewData;
    field?: keyof PartnerBillingOverviewData;
    render?: (organization: PartnerBillingOverviewData) => JSX.Element | string | number;
}> = ({ label, field, organization, render }) => {
    let value;
    if (field) {
        value = organization[field];
        if (typeof value === 'boolean') {
            value = value ? 'Enabled' : 'Disabled';
        }
    } else if (render) {
        value = render(organization);
    }

    return (
        <RowContainer>
            <RowLabelText variant={'caption'} medium color={'DARK_GRAY'}>
                {label}
            </RowLabelText>
            <RowValueContainer>
                <Text variant={'caption'} color={'DARK_GRAY'}>
                    {value}
                </Text>
            </RowValueContainer>
        </RowContainer>
    );
};

const RowsContainer = styled('div')({
    marginBottom: 'auto',
    flexGrow: 1,
});

const ActionsContainer = styled('div')({
    borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    marginTop: 'auto',
    height: '64px',
    paddingTop: '16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

export const OrganizationOverview: React.FC<{ organization: PartnerBillingOverviewData }> = ({ organization }) => {
    const {
        practiceLoading,
        primaryPracticeForContract,
        associatedOrganizations,
        getNextInvoiceStatusForOrg,
        refetchCredits,
        refetchBillingDetails,
        hasAtLeastOneContract,
        contractsLoading,
    } = useBillingDetailsContext();
    const rows = OverviewRows(organization);
    const nextInvoiceStatus = getNextInvoiceStatusForOrg(organization.id);
    const practiceOrContractsLoading = practiceLoading || contractsLoading;

    return (
        <Container>
            <LoadBlocker blocking={practiceOrContractsLoading} ContainerProps={{ style: { height: '100%' } }}>
                <OrganizationOverviewContainer>
                    <NameContainer>
                        <Text variant={'body1'} medium>
                            {organization.id === primaryPracticeForContract || associatedOrganizations.length === 1 ? (
                                organization?.name
                            ) : (
                                <Link
                                    href={`/billing/overview/${organization.id}`}
                                    sx={{
                                        color: FlossPalette.BLACK,
                                        textDecoration: 'underline',
                                        display: 'flex',
                                        gap: '10px',
                                    }}
                                    target={'_blank'}
                                    rel={'noopener noreferrer'}
                                >
                                    {organization?.name}
                                    <LaunchIcon />
                                </Link>
                            )}
                        </Text>
                        <OrganizationOverviewImpersonationButton
                            practiceId={organization.id}
                            practiceName={organization.name}
                        />
                    </NameContainer>

                    <ActivationContainer>
                        {organization.id === primaryPracticeForContract && (
                            <ContractDesignationContainer>
                                <Icon icon={'CheckIcon'} />
                                <ContractDesignationText color={'WHITE'} variant={'caption'} medium>
                                    Primary Contract Holder
                                </ContractDesignationText>
                            </ContractDesignationContainer>
                        )}
                        {organization.id !== primaryPracticeForContract &&
                            associatedOrganizations.length === 1 &&
                            hasAtLeastOneContract &&
                            !practiceLoading && (
                                <Link
                                    href={`/billing/overview/${primaryPracticeForContract}`}
                                    sx={{ fontSize: '80%', marginLeft: '1em', textDecoration: 'underline' }}
                                    target={'_blank'}
                                    rel={'noopener noreferrer'}
                                >
                                    ⮑ link to parent org
                                </Link>
                            )}
                        <ActivationText variant={'caption'} color={'GRAY'}>
                            Activation date: {activationDate(organization?.activation_date)}
                        </ActivationText>
                    </ActivationContainer>

                    <RowsContainer>
                        {rows.map(({ label, field, render }) => (
                            <OrganizationOverviewRow
                                key={label}
                                label={label}
                                organization={organization}
                                field={field}
                                render={render}
                            />
                        ))}
                    </RowsContainer>

                    <ActionsContainer>
                        <CreateOrPreviewUsageInvoice
                            organizationId={organization.id}
                            nextInvoiceStatus={nextInvoiceStatus}
                            mode={'create'}
                            refetch={refetchBillingDetails}
                            CustomButton={({ onClick }) => (
                                <Button variant={'primary'} onClick={onClick}>
                                    Generate Invoice
                                </Button>
                            )}
                        />
                        <CreateBillingCreditAction
                            practiceId={organization.id}
                            refetchCredits={refetchCredits}
                            useTextButton={true}
                        />
                    </ActionsContainer>
                </OrganizationOverviewContainer>
            </LoadBlocker>
        </Container>
    );
};
