import type { MenuItemProps } from '../OrderDetailToolbarFiles.types';
import { useMutation, useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlProductLine, LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import type { IOrderItemV2DTO } from '@orthly/items';
import { OrderItemV2Utils } from '@orthly/items';
import { DesignStorageConfigs, getFullStoragePath } from '@orthly/shared-types';
import { OrthlyBrowserConfig, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Button, MenuItem, Text } from '@orthly/ui-primitives';
import {
    ActivityCounterProvider,
    type RemovableFileFields,
    RemovableFilesUploadFields,
    useActivityCounter,
} from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const GetManufacturingFiles_Query = graphql(`
    query GetRemovableManufacturingFiles($designOrderId: String!) {
        getRemovableManufacturingFiles(designOrderId: $designOrderId) {
            device_upper_stl
            device_lower_stl
            model_upper_stl
            model_lower_stl
            trimline_upper_pts
            trimline_lower_pts
        }
    }
`);

const ReplaceManufacturingFiles_Mutation = graphql(`
    mutation ReplaceRemovableManufacturingFiles($data: ReplaceRemovableManufacturingFilesCommand!) {
        replaceRemovableManufacturingFiles(data: $data) {
            id
        }
    }
`);

const Dialog: React.VFC<{
    open: boolean;
    setOpen: (open: boolean) => void;
    orderId: string;
    orderItems: IOrderItemV2DTO[];
}> = props => {
    const { open, setOpen, orderId, orderItems } = props;
    const [filePaths, setFilePaths] = React.useState<RemovableFileFields>({});
    // Keeps track of whether any files are currently being uploaded.
    const { isActive: uploadInProgress } = useActivityCounter();

    const { loading, data, refetch } = useQuery(GetManufacturingFiles_Query, {
        variables: { designOrderId: orderId },
        onCompleted: result => {
            setFilePaths(result.getRemovableManufacturingFiles);
        },
    });
    const submitMutation = useMutation(ReplaceManufacturingFiles_Mutation);
    const { submit, submitting } = useRootActionCommand(submitMutation, {
        successMessage: 'Updated manufacturing files.',
        onSuccess: async () => {
            await refetch();
            setOpen(false);
        },
    });
    data?.getRemovableManufacturingFiles;

    const [currentTime] = React.useState<number>(Date.now());
    const storagePathConfig = getFullStoragePath(
        OrthlyBrowserConfig.env,
        DesignStorageConfigs.designs,
        orderId,
        `${currentTime}`,
    );

    return (
        <RootActionDialog
            loading={loading}
            open={open}
            setOpen={setOpen}
            hideButton={true}
            title={'Replace Manufacturing Files'}
            content={
                <RemovableFilesUploadFields
                    orderItems={orderItems}
                    storagePathConfig={storagePathConfig}
                    updateField={(field, value) => {
                        setFilePaths(paths => ({ ...paths, [field]: value }));
                    }}
                    initialFields={data?.getRemovableManufacturingFiles ?? {}}
                />
            }
            showCloseButton={true}
            actions={
                <Button
                    style={{ margin: 16 }}
                    variant={'primary'}
                    onClick={() => {
                        void submit({ data: { order_id: orderId, files: _.omit(filePaths, '__typename') } });
                    }}
                    disabled={loading || submitting || uploadInProgress}
                >
                    Submit
                </Button>
            }
        />
    );
};

const APPLICABLE_PRODUCT_LINES = [LabsGqlProductLine.Removable, LabsGqlProductLine.Denture, LabsGqlProductLine.Partial];

export const ReplaceManufacturingFilesMenuItem: React.VFC<MenuItemProps> = props => {
    const { order } = props;
    const [open, setOpen] = React.useState(false);

    // Only show the menu item after initial design and for relevant product lines.
    if (
        !order.fulfillment_workflow.closed_tasks.some(t => t.type === LabsGqlWorkflowTaskType.InternalDesign) ||
        !order.product_line ||
        !APPLICABLE_PRODUCT_LINES.includes(order.product_line)
    ) {
        return null;
    }

    const orderItems = OrderItemV2Utils.parseItems(order.items_v2);

    return (
        <>
            <MenuItem onClick={() => setOpen(true)}>{`Replace Manufacturer Files`}</MenuItem>
            <ActivityCounterProvider>
                <Dialog open={open} setOpen={setOpen} orderId={order.id} orderItems={orderItems} />
            </ActivityCounterProvider>
        </>
    );
};
