import { AnalyticsClient } from '../../../../../../../utils/analyticsClient';
import { AdminOrderDesignToolbarFileDownloadDesign_Fragment } from '../../OrderDesignToolbar/OrderDesignToolbarFileDownload.graphql';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useRerunDesignConversionMutation } from '@orthly/graphql-react';
import { LoadBlocker, useRootActionCommand, Icon } from '@orthly/ui';
import { Button, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

interface ReRunDesignConversionMenuProps {
    order: LabsGqlLabOrderFragment;
    selectedDesignFragment: FragmentType<typeof AdminOrderDesignToolbarFileDownloadDesign_Fragment>;
}

export const ReRunDesignConversionMenu: React.FC<ReRunDesignConversionMenuProps> = props => {
    const { order, selectedDesignFragment } = props;

    const selectedDesign = getFragmentData(AdminOrderDesignToolbarFileDownloadDesign_Fragment, selectedDesignFragment);

    const { submit, submitting } = useRootActionCommand(useRerunDesignConversionMutation(), {
        successMessage: 'Design conversion scheduled:  Check back soon',
    });

    if (!order.design_file_path) {
        return null;
    }

    return (
        <Tooltip title={'Rerun Design Conversion'}>
            <Button
                onClick={() => {
                    AnalyticsClient.track('All - Portal - Order Files Menu Used', {
                        $groups: { order: order.id },
                        action: 'rerun_design_conversion',
                    });
                    // Intentional so you can find the temporal revision ID which is not
                    // easily accessible from any front end page.
                    console.log(`Scheduling full-design-conversion-${selectedDesign.id}`);
                    void submit({
                        order_id: order.id,
                        force_temporal_workflow: null,
                        revision_id: selectedDesign.id,
                    });
                }}
                variant={'text'}
                disabled={submitting}
            >
                <LoadBlocker blocking={submitting}>
                    <Icon icon={submitting ? 'Whatshot' : 'QueuePlayNext'} />
                </LoadBlocker>
            </Button>
        </Tooltip>
    );
};
