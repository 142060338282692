import { useFirebaseStorage } from '../context';
import { getFirebaseDownloadUrl } from './useFirebaseDownload';
import type { MountingMatrix } from '@orthly/forceps';
import { tryParseMountingMatrix, DENTURE_TOOTH_TYPES } from '@orthly/forceps';
import type { OrderDesignPreviewDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import axios from 'axios';
import _ from 'lodash';
import React from 'react';
import * as THREE from 'three';
import { create as createXML } from 'xmlbuilder2';

/**
 * Hook to asynchronously obtain the mounting matrix from the manufacturing_acceleration_data JSON or, if unavailable,
 * from the design_tree xml
 */
export function useMountingMatrix(design: OrderDesignPreviewDesign_FragmentFragment | undefined | null) {
    const manufacturingAccelerationDataPath = design?.conversion_artifacts?.manufacturing_acceleration_data_path;
    const modellingTreePath = design?.conversion_artifacts?.design_tree_path;
    const toothElements = design?.case_file_tooth_elements;

    const firebaseStorage = useFirebaseStorage();
    const [mountingMatrix, setMountingMatrix] = React.useState<MountingMatrix | null>(null);

    React.useEffect(() => {
        const abortController = new AbortController();
        if ((!manufacturingAccelerationDataPath && !modellingTreePath) || toothElements === undefined) {
            setMountingMatrix(null);
            return;
        }

        if (!manufacturingAccelerationDataPath) {
            if (!modellingTreePath) {
                throw new Error('Design contains no artifacts to parse mounting matrix from');
            }
            getFirebaseDownloadUrl(firebaseStorage, modellingTreePath)
                .then(url => axios.get(url, { responseType: 'text' }))
                .then(res => {
                    if (abortController.signal.aborted) {
                        return;
                    }
                    const isDenture = _.map(toothElements, 'tooth_type_class').some(
                        _.partial(_.includes, DENTURE_TOOTH_TYPES),
                    );
                    setMountingMatrix(tryParseMountingMatrix(createXML(res.data), isDenture) ?? null);
                })
                .catch(e => console.error(`Failed to get mounting matrix from modeling tree XML:${e}`));
        } else {
            getFirebaseDownloadUrl(firebaseStorage, manufacturingAccelerationDataPath)
                .then(url => axios.get(url, { responseType: 'text' }))
                .then(res => {
                    if (abortController.signal.aborted) {
                        return;
                    }
                    const matrix = res.data.mounting_matrix;
                    setMountingMatrix({
                        x: new THREE.Vector3().copy(matrix.x),
                        y: new THREE.Vector3().copy(matrix.y),
                        z: new THREE.Vector3().copy(matrix.z),
                        origin: new THREE.Vector3().copy(matrix.origin),
                    });
                })
                .catch(e =>
                    console.error(`Failed to get mounting matrix from manufacturing_acceleration_data.json:${e}`),
                );
        }
        return () => abortController.abort('Request parameters changed');
    }, [manufacturingAccelerationDataPath, modellingTreePath, toothElements, firebaseStorage]);

    return mountingMatrix;
}
