import { useUsersAction } from '../../state/Users.actions';
import type { LabsGqlUpdateUserMutationVariables } from '@orthly/graphql-operations';
import { useUpdateUserMutation } from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';

type UpdateUserVars = LabsGqlUpdateUserMutationVariables['data'];

export const useUpdateUser = () => {
    const onUserSaved = useUsersAction('USER_SAVED');
    const [submitMtn] = useUpdateUserMutation();
    const mtnSubmitter = (data: UpdateUserVars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [UpdateUserVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Successfully updated user!', {}],
        onSuccess: result => {
            const user = result.data?.updateUser;
            if (user) {
                onUserSaved({ user });
            }
        },
    });

    return { submit, submitting };
};
