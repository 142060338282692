import { FlossPalette, Text, createStyles, makeStyles, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            alignItems: 'flex-end',
            cursor: 'pointer',
        },
        title: {
            fontSize: 12,
        },
        initialsBox: {
            background: FlossPalette.DARK_TAN,
            borderRadius: 4,
            height: 40,
            width: 40,
            justifyContent: 'center',
            alignItems: 'center',
        },
        divBadge: {
            position: 'relative',
            right: 10,
            width: 10,
            height: 10,
            borderRadius: 3,
        },
    }),
);

export const DesignerStatusIcon: React.FC<{
    initials: string;
    badgeColor: string;
}> = props => {
    const { initials, badgeColor } = props;
    const styles = useStyles();

    return (
        <Grid container className={styles.root}>
            <Grid container className={styles.initialsBox}>
                <Text variant={'body2'}>{initials}</Text>
            </Grid>
            <div className={styles.divBadge} style={{ backgroundColor: badgeColor }} />
        </Grid>
    );
};
