import { getLabelForTaskType, getLabelForCapability, levelToDisplayString } from '../../utils/Capabilities';
import type { LabsGqlDesignStaffFocusAreaDtoFragment } from '@orthly/graphql-operations';
import { useDesignStaffDetailedStatusQuery } from '@orthly/graphql-react';
import { LabsGqlDesignTaskType } from '@orthly/graphql-schema';
import { ToolbarContainer } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, Text, Chip, Tooltip } from '@orthly/ui-primitives';
import { DesignerStatusIndicatorConfig } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    toolbarContainer: {
        padding: 0,
        backgroundColor: FlossPalette.TAN,
        border: `1px solid ${FlossPalette.DARK_TAN}`,
    },
    statusBadge: {
        height: 12,
        width: 12,
        borderRadius: 3,
        marginLeft: 24,
    },
    statusText: {
        paddingLeft: 8,
        paddingRight: 8,
        marginRight: 40,
    },
    focusAreaChip: {
        marginLeft: 4,
        marginRight: 4,
    },
}));

function getFocusAreaString(focusArea: LabsGqlDesignStaffFocusAreaDtoFragment) {
    const taskType = getLabelForTaskType(focusArea.task_type);
    const capability = getLabelForCapability(
        focusArea.capability,
        focusArea.task_type === LabsGqlDesignTaskType.ModelDesign,
    );
    const level = levelToDisplayString(focusArea.level);

    return `${taskType} ${capability} ${level}`;
}

export const DesignerStatusToolbar: React.VFC<{
    userId: string;
}> = props => {
    const styles = useStyles();
    const { userId } = props;

    const { data: statusQueryData } = useDesignStaffDetailedStatusQuery({
        variables: { user_id: userId },
    });

    const status = statusQueryData?.designStaffDetailedStatus.status;
    const color = status ? DesignerStatusIndicatorConfig[status].color : null;
    const title = status ? DesignerStatusIndicatorConfig[status].title : null;
    const focusAreas = statusQueryData?.designStaffDetailedStatus.focus_areas ?? [];

    if (!status || !color || !title) {
        return null;
    }
    return (
        <ToolbarContainer sticky className={styles.toolbarContainer}>
            <Grid item className={styles.statusBadge} style={{ backgroundColor: color }} />
            <Grid item>
                <Text variant={'body2'} bold className={styles.statusText}>
                    {status}: {title}
                </Text>
            </Grid>
            {focusAreas.length > 0 && (
                <>
                    <Text variant={'body2'} bold>
                        Focusing On:
                    </Text>
                    {focusAreas.map(fa => {
                        return (
                            <Tooltip title={getFocusAreaString(fa)} key={fa.id}>
                                <Chip className={styles.focusAreaChip} size={'small'} label={getFocusAreaString(fa)} />
                            </Tooltip>
                        );
                    })}
                </>
            )}
        </ToolbarContainer>
    );
};
