import type { LabsGqlDesignReviewFieldFragment, LabsGqlOrder } from '@orthly/graphql-operations';
import { useListDesignReviewFieldsQuery } from '@orthly/graphql-react';
import type { LabsGqlDesignMetafieldSubmission, LabsGqlDesignReviewFieldType, Scalars } from '@orthly/graphql-schema';
import { LabsGqlDesignReviewFieldFilterType } from '@orthly/graphql-schema';
import type { IOrderItemV2DTO } from '@orthly/items';
import { ToothUtils, CartItemV2Utils, OrderItemV2Utils, LabOrderItemSKUType, ItemCheckerUtils } from '@orthly/items';
import { mergeDicts, compactDict } from '@orthly/runtime-utils';
import type { FieldsDefProp } from '@orthly/ui';
import { QuickForm, buildQFInitialValues, buildQFValidation } from '@orthly/ui';
import { Text, CheckboxPrimitive, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

class DesignReviewFieldsUtils {
    static typeMatchesItem(type: LabsGqlDesignReviewFieldFilterType, item: IOrderItemV2DTO) {
        const uniqueUnns = CartItemV2Utils.getUniqueUNNs(item);
        const anterior = ToothUtils.checkGroupMembership('Anterior', uniqueUnns);
        const posterior = ToothUtils.checkGroupMembership('Posterior', uniqueUnns);
        const upper = ToothUtils.checkGroupMembership('Upper', uniqueUnns);
        const lower = ToothUtils.checkGroupMembership('Lower', uniqueUnns);
        const implant = [LabOrderItemSKUType.Implant, LabOrderItemSKUType.ImplantBridge].includes(item.sku);

        switch (type) {
            case LabsGqlDesignReviewFieldFilterType.Posterior:
                return posterior;
            case LabsGqlDesignReviewFieldFilterType.Anterior:
                return anterior;
            case LabsGqlDesignReviewFieldFilterType.Upper:
                return upper;
            case LabsGqlDesignReviewFieldFilterType.Lower:
                return lower;

            // SKUs
            case LabsGqlDesignReviewFieldFilterType.Implant:
                return implant;
            case LabsGqlDesignReviewFieldFilterType.Model:
                return item.sku === LabOrderItemSKUType.Model;
            case LabsGqlDesignReviewFieldFilterType.Aligner:
                return item.sku === LabOrderItemSKUType.Aligners;
            case LabsGqlDesignReviewFieldFilterType.FullDenture:
                return item.sku === LabOrderItemSKUType.Denture;
            case LabsGqlDesignReviewFieldFilterType.NightGuard:
                return ItemCheckerUtils.isNightGuard(item);

            // non-implant bridges
            case LabsGqlDesignReviewFieldFilterType.PosteriorBridge:
                // Intentionally excludes implant bridges. If you want a field to exist for both implants
                // and implant bridges, select both PosteriorBridge and PosteriorImplantBridge
                return posterior && item.sku === LabOrderItemSKUType.Bridge;
            case LabsGqlDesignReviewFieldFilterType.AnteriorBridge:
                // Intentionally excludes implant bridges. If you want a field to exist for both implants
                // and implant bridges, select both PosteriorBridge and PosteriorImplantBridge
                return anterior && item.sku === LabOrderItemSKUType.Bridge;

            // implants, including implant bridges
            case LabsGqlDesignReviewFieldFilterType.PosteriorImplant:
                return posterior && implant;
            case LabsGqlDesignReviewFieldFilterType.AnteriorImplant:
                return anterior && implant;

            // implant bridges
            case LabsGqlDesignReviewFieldFilterType.PosteriorImplantBridge:
                return posterior && item.sku === LabOrderItemSKUType.ImplantBridge;
            case LabsGqlDesignReviewFieldFilterType.AnteriorImplantBridge:
                return anterior && item.sku === LabOrderItemSKUType.ImplantBridge;
        }
    }

    static typeMatchesOrder(type: LabsGqlDesignReviewFieldFilterType, order: LabsGqlOrder) {
        return OrderItemV2Utils.parseItems(order.items_v2).some(item =>
            DesignReviewFieldsUtils.typeMatchesItem(type, item),
        );
    }

    static fieldMatchesOrder(field: LabsGqlDesignReviewFieldFragment, order: LabsGqlOrder) {
        // if the field is archived, ignore it by just pretending it never matches
        if (field.archived) {
            return false;
        }

        // If no filters are selected, we match on all orders.
        if (!field.applicable_order_types.length) {
            return true;
        }

        const tests = field.applicable_order_types.map(type => DesignReviewFieldsUtils.typeMatchesOrder(type, order));

        return _.some(tests);
    }
}

export type MetafieldValuesOfFieldsInCategory = Record<string, Scalars['MetafieldJSON']['output']>;
export type MetafieldInputs = Record<string, MetafieldValuesOfFieldsInCategory>;

export type ReviewFieldData = {
    fields: FieldsDefProp<any>;
    initialValues: MetafieldValuesOfFieldsInCategory;
    category: string;
};

export const metafieldInputsToMetafieldSubmissions = (
    metafieldInputs: MetafieldInputs,
    reviewFields: ReviewFieldData[],
) => {
    // Here we grab the defaults that are set when the sections aren't set, and then overwrite with whatever the user supplied.
    const allKeys = _.uniq([...reviewFields.map(field => field.category), ...Object.keys(metafieldInputs)]);
    const allMetafields = allKeys.reduce(
        (state, key) => ({
            ...state,
            [key]: { ...reviewFields.find(field => field.category === key)?.initialValues, ...metafieldInputs[key] },
        }),
        {},
    );

    // First layer here will just be the section groups
    return Object.entries(allMetafields).flatMap<LabsGqlDesignMetafieldSubmission>(([_rootKey, value]) => {
        if (!value || typeof value !== 'object') {
            return [];
        }

        // Second layer is the actual fields of interest, all of the editable fields within the section
        return Object.entries(value ?? {}).flatMap<LabsGqlDesignMetafieldSubmission>(([nestedKey, nestedValue]) => {
            if (typeof nestedValue !== 'string' && typeof nestedValue !== 'number') {
                return [];
            }
            return [{ value: nestedValue, name: _.startCase(nestedKey) }];
        });
    });
};

function genField(field: LabsGqlDesignReviewFieldFragment): FieldsDefProp<any> | undefined {
    switch (field.field.type) {
        case 'text':
            return {
                [field.name]: {
                    type: 'text',
                    label: field.name,
                    fieldProps: {
                        multiline: true,
                        minRows: 3,
                        maxRows: 100,
                    },
                    optional: !field.required,
                },
            };

        case 'number':
            return {
                [field.name]: {
                    type: 'number',
                    label: field.name,
                    optional: !field.required,
                },
            };

        case 'boolean':
            return {
                [field.name]: {
                    type: 'boolean',
                    label: field.name,
                    optional: !field.required,
                },
            };

        case 'select':
            return {
                [field.name]: {
                    type: 'select',
                    label: field.name,
                    options: field.field.options ?? [],
                    optional: !field.required,
                },
            };

        default:
            return undefined;
    }
}

export const useReviewFields = (order: LabsGqlOrder, type: LabsGqlDesignReviewFieldType) => {
    const { data: reviewFieldsData, loading } = useListDesignReviewFieldsQuery({ variables: { type } });

    const reviewFields = React.useMemo<ReviewFieldData[]>(() => {
        const fields = (reviewFieldsData?.listDesignReviewFields ?? []).filter(field =>
            DesignReviewFieldsUtils.fieldMatchesOrder(field, order),
        );
        // Group all by category so we can render them in the same pane.
        const groups = _.groupBy(fields, field => field.category);
        return Object.entries(groups).map(([category, fields]) => {
            return {
                category,
                fields: mergeDicts(_.compact(fields.map(f => genField(f)))),
                initialValues: compactDict(Object.fromEntries(fields.map(f => [f.name, f.field.default_value]))),
            };
        });
    }, [reviewFieldsData, order]);

    return { reviewFields, loading };
};

export const metafieldInputsAreValid = (metafieldInputs: MetafieldInputs, reviewFields: ReviewFieldData[]) => {
    for (const { fields, category } of reviewFields) {
        const values = metafieldInputs[category];
        if (values && !buildQFValidation(fields).safeParse(values).success) {
            return false;
        }
    }

    return true;
};

export function anyMetafieldCategoriesAreChecked(metafieldInputs: MetafieldInputs) {
    return Object.keys(metafieldInputs).length > 0;
}

export interface DesignReviewMetafieldProps {
    metafieldInputs: MetafieldInputs;
    reviewFields: ReviewFieldData[];
    setMetafieldInputs: (metafieldInputs: MetafieldInputs) => void;
    boldedCategories?: boolean;
}

export const DesignReviewMetafields: React.VFC<DesignReviewMetafieldProps> = ({
    metafieldInputs,
    reviewFields,
    setMetafieldInputs,
    boldedCategories = true,
}) => {
    // Memoized to avoid repeatedly resorting on rerender.
    const sortedReviewFields = React.useMemo(() => {
        return _.sortBy(reviewFields, r => r.category);
    }, [reviewFields]);

    return (
        <>
            {sortedReviewFields.map(({ category, fields, initialValues }) => {
                const setFormValues = (result: any): void =>
                    setMetafieldInputs({ ...metafieldInputs, [category]: result as any });
                const values = metafieldInputs[category];
                const isChecked = !!values;

                return (
                    <Grid
                        key={category}
                        container
                        direction={'column'}
                        style={{ minHeight: 48, borderBottom: '1px solid #ECEAE6', padding: '0px 16px 8px' }}
                    >
                        <Grid container item direction={'row'}>
                            <Grid item xs={11}>
                                <Text
                                    variant={'body2'}
                                    style={{
                                        fontWeight: boldedCategories ? 'bold' : undefined,
                                        padding: '8px 0px 0px 4px',
                                    }}
                                >
                                    {category}
                                </Text>
                            </Grid>
                            <Grid item xs={1}>
                                <CheckboxPrimitive
                                    color={'secondary'}
                                    style={{ margin: '8px 4px', padding: 0 }}
                                    checked={isChecked}
                                    onChange={evt => {
                                        if (evt.target.checked) {
                                            setFormValues(buildQFInitialValues(fields, initialValues ?? {}));
                                        } else {
                                            setMetafieldInputs(_.omit(metafieldInputs, category));
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container item>
                            {isChecked && (
                                <QuickForm<any>
                                    fields={fields}
                                    initialValues={values ?? {}}
                                    submitButtonProps={{ style: { display: 'none' } }}
                                    onChange={result => setFormValues(result)}
                                    onSubmit={() => {}}
                                />
                            )}
                        </Grid>
                    </Grid>
                );
            })}
        </>
    );
};
