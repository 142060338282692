import { TOOLBAR_ITEM_HEIGHT_PX } from './Constants';
import { FlossPalette, Text, Grid, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        height: TOOLBAR_ITEM_HEIGHT_PX,
        padding: '0px 12px',
    },
}));

export const ToolbarLabel: React.VFC<{ text: string }> = ({ text }) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} alignContent={'center'} justifyContent={'center'}>
            <Text variant={'body2'} style={{ color: FlossPalette.LIGHT_GRAY }}>
                {text}
            </Text>
        </Grid>
    );
};
