import { OrderDetailBlock, OrderDetailRow } from '../OrderDetails';
import { useFeatureFlag } from '../Providers/LaunchDarkly';
import type { EventLogMetadataKeys } from './OrderDesignAnalytics.config';
import {
    DesignEventLogMetadataKeyLabelMap,
    MetadataKeysFormattedAsCount,
    DesignEventLogAggregateConfigMap,
} from './OrderDesignAnalytics.config';
import { OrderDesignAnalyticsCaseModelElementRowGraphql } from './OrderDesignAnalyticsCaseModelElementRow.graphql';
import type { FragmentType, VeneerOrderDesignAnalyticsDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import { useGetDesignFileDmeListQuery } from '@orthly/graphql-react';
import type { LabsGqlDesignFileDmeListResult } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { Grid, Text, Divider } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const VeneerOrderDesignAnalyticsDesign_Fragment = graphql(`
    fragment VeneerOrderDesignAnalyticsDesign_Fragment on DesignOrderDesignRevisionDTO {
        event_log_event_aggregates {
            name
            count
            cumulative_duration
        }

        case_file_model_elements {
            ...VeneerOrderDesignAnalyticsCaseModelElementRowCaseModelElement_Fragment
        }

        event_log_metadata {
            number_sessions
            cumulative_session_time
            cumulative_gap_between_sessions
            prepare_step_duration
            segmentation_step_duration
            interfaces_step_duration
            directions_step_duration
            anatomy_pre_design_step_duration
            frame_design_step_duration
            abutments_post_and_core_step_duration
            anatomy_design_step_duration
            idle_duration
        }
    }
`);

// Given a design, returns the metadata and aggregate "rows", which are [label, value] arrays.
function useDesignEventLogRows(
    design?: Pick<
        VeneerOrderDesignAnalyticsDesign_FragmentFragment,
        'event_log_event_aggregates' | 'event_log_metadata'
    >,
) {
    const event_log_aggregates = design?.event_log_event_aggregates;
    const event_log_metadata = design?.event_log_metadata;

    const metadataRows = React.useMemo(() => {
        if (!event_log_metadata) {
            return [];
        }

        const rows = Object.entries(DesignEventLogMetadataKeyLabelMap).map<[string, string | number] | null>(
            ([key, label]) => {
                const value = event_log_metadata[key as EventLogMetadataKeys];
                if (!value || !label) {
                    return null;
                }

                if (MetadataKeysFormattedAsCount.includes(key as EventLogMetadataKeys) || typeof value !== 'number') {
                    return [label, value];
                }

                return [label, Format.duration(value)];
            },
        );
        return _.compact(rows);
    }, [event_log_metadata]);

    const aggregateRows = React.useMemo(() => {
        if (!event_log_aggregates) {
            return [];
        }

        const rows = Object.entries(DesignEventLogAggregateConfigMap).map<[string, string] | null>(entry => {
            const agg = event_log_aggregates.find(row => row.name === entry[0]);
            if (!agg || !agg.count) {
                return null;
            }

            return [entry[1].label, entry[1].format(agg)];
        });
        return _.compact(rows);
    }, [event_log_aggregates]);

    return { metadataRows, aggregateRows };
}

function useDesignDmeAnalytics(order_id: string): LabsGqlDesignFileDmeListResult | undefined {
    const { value: areDmeAnalyticsEnabled, loading } = useFeatureFlag('enableThreeshapeDmeAnalytics');

    const { data } = useGetDesignFileDmeListQuery({
        variables: {
            order_id,
        },
        skip: loading || !areDmeAnalyticsEnabled,
    });

    return data?.getDesignFileDmeList;
}

interface DmeVersionViewerProps {
    dmeAnalytics: LabsGqlDesignFileDmeListResult;
    variant?: 'sidebar' | 'standard';
}

const DmeVersionViewer: React.VFC<DmeVersionViewerProps> = ({ dmeAnalytics, variant }) => {
    return (
        <>
            {dmeAnalytics.found_dmes.map((dme, idx) => (
                <OrderDetailRow
                    key={idx}
                    variant={variant ?? 'standard'}
                    name={`${dme.dme_name} v${dme.dme_version}${
                        dme.dme_version !== dme.latest_version ? ' (OUTDATED)' : ''
                    }`}
                    value={`${dme.provides_materials.length} materials and ${dme.provides_smile_styles.length} smile libraries`}
                />
            ))}
            {dmeAnalytics.number_unmatched_entries && (
                <OrderDetailRow
                    key={'unknown-dme'}
                    variant={variant ?? 'standard'}
                    name={`Unknown DME`}
                    value={`${dmeAnalytics.number_unmatched_entries} definitions`}
                />
            )}
        </>
    );
};

interface OrderDesignAnalyticsProps {
    orderId: string;
    designFragment: FragmentType<typeof VeneerOrderDesignAnalyticsDesign_Fragment>;
    variant: 'standard' | 'sidebar';
}

const OrderDesignAnalyticsInternal: React.VFC<OrderDesignAnalyticsProps> = ({ orderId, designFragment, variant }) => {
    const design = getFragmentData(VeneerOrderDesignAnalyticsDesign_Fragment, designFragment);
    const { value: isEventLogAnalyticsEnabled = false } = useFeatureFlag('enableDesignAnalyticsInPortal');

    const dmeAnalytics = useDesignDmeAnalytics(orderId);
    const { metadataRows, aggregateRows } = useDesignEventLogRows(design);

    // Nothing to render, so we exit.
    if (!metadataRows.length && !aggregateRows.length && !design.case_file_model_elements.length) {
        return null;
    }

    return (
        <>
            {isEventLogAnalyticsEnabled && (
                <Grid container direction={'row'}>
                    <Grid item xs={6}>
                        {metadataRows.map((entry, idx) => (
                            <OrderDetailRow key={idx} variant={variant} name={entry[0]} value={`${entry[1]}`} />
                        ))}
                    </Grid>
                    <Grid item xs>
                        {aggregateRows.map((entry, idx) => (
                            <OrderDetailRow key={idx} variant={variant} name={entry[0]} value={`${entry[1]}`} />
                        ))}
                    </Grid>
                </Grid>
            )}
            {design.case_file_model_elements.length && (
                <Grid>
                    {isEventLogAnalyticsEnabled && <Divider style={{ margin: '8px 0px' }} />}
                    <Text variant={'body2'}>Models and Items</Text>
                    {design.case_file_model_elements.map((element, idx) => (
                        <OrderDesignAnalyticsCaseModelElementRowGraphql
                            elementFragment={element}
                            dmeAnalytics={dmeAnalytics}
                            key={idx}
                            variant={variant}
                        />
                    ))}
                </Grid>
            )}
            {dmeAnalytics && (
                <Grid>
                    <Divider style={{ margin: '8px 0px' }} />
                    <Text variant={'body2'}>DMEs</Text>
                    <DmeVersionViewer dmeAnalytics={dmeAnalytics} variant={variant} />
                </Grid>
            )}
        </>
    );
};

export const OrderDesignAnalytics: React.VFC<OrderDesignAnalyticsProps> = props => {
    const { variant } = props;

    if (variant === 'standard') {
        return (
            <OrderDetailBlock
                key={`design_analytics_wrapper_${props.orderId}`}
                title={'Design Analytics'}
                variant={'full'}
            >
                <OrderDesignAnalyticsInternal {...props} />
            </OrderDetailBlock>
        );
    }

    return <OrderDesignAnalyticsInternal key={`design_analytics_sidebar_wrapper_${props.orderId}`} {...props} />;
};
