import type { LabsGqlAttachAlignerPreppedScansMutationVariables, LabsGqlOrder } from '@orthly/graphql-operations';
import { useAttachAlignerPreppedScansMutation } from '@orthly/graphql-react';
import { getFullStoragePath, DesignStorageConfigs } from '@orthly/shared-types';
import { useChangeSubmissionFn, RootActionDialog, OrthlyBrowserConfig } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import type { FileUploaderFieldResult, FileUploaderProps } from '@orthly/veneer';
import { FileUploader } from '@orthly/veneer';
import React from 'react';

type STLFileKeys = 'Upper' | 'Lower';

function getUploadedPathForKey(files: FileUploaderFieldResult<STLFileKeys>[], key: STLFileKeys): string | undefined {
    return files.find(f => key === f.fieldKey)?.uploadedPath;
}

interface MenuItemProps {
    onClose: () => void;
    order: Pick<LabsGqlOrder, 'id' | 'scan_export'>;
    refetchOrder: () => Promise<any>;
}

export const AttachAlignerPreppedScans: React.FC<MenuItemProps> = props => {
    type Vars = LabsGqlAttachAlignerPreppedScansMutationVariables['data'];
    const scan = props.order.scan_export;
    const [submitMtn] = useAttachAlignerPreppedScansMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Scan Stls attached!', {}],
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSuccess: async () => {
            await props.refetchOrder();
            props.onClose();
        },
    });

    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, DesignStorageConfigs.stls, scan.id);
    const fileUploaderProps = React.useMemo<FileUploaderProps<STLFileKeys>>(() => {
        return {
            submitting,
            fileFields: [
                { fieldKey: 'Upper', uploadName: 'UpperAlignerPreppedScan', displayName: 'Upper Scan' },
                { fieldKey: 'Lower', uploadName: 'LowerAlignerPreppedScan', displayName: 'Lower Scan' },
            ],
            storagePathConfig: storagePathConfig,
            prependTimestampToFilename: false, // Should keep the design filenames as they are when exported
            dropzoneOptions: {
                accept: {
                    'model/stl': ['.stl'],
                    'application/stl': ['.stl'],
                    'application/zip': ['.zip'],
                },
            },
            onComplete: files => {
                const upperPath = getUploadedPathForKey(files, 'Upper');
                const lowerPath = getUploadedPathForKey(files, 'Lower');
                if (!upperPath || !lowerPath) {
                    window.alert('Zip file not found in uploads, exiting!');
                    return;
                }

                submit({ upperPath, lowerPath, scanExportId: scan.id }).catch(console.error);
            },
        };
    }, [storagePathConfig, scan.id, submit, submitting]);

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={newOpen => {
                setOpen(newOpen);
                !newOpen && props.onClose();
            }}
            title={`Attach prepped STLs for ${scan.patient_first_name} ${scan.patient_last_name}`}
            content={
                <Grid container>
                    <FileUploader {...(fileUploaderProps as FileUploaderProps)} />
                </Grid>
            }
            buttonText={'Attach Aligner Prepped STLs'}
            buttonProps={{ variant: 'secondary' }}
        />
    );
};
