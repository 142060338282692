import { usePalateState } from '../../state/Palate.reducer';
import { InFlightOrders } from './InFlightOrders';
import { IncompleteOrders } from './IncompleteOrders';
import { PalateRecentReviews } from './PalateRecentReviews';
import { useScans } from '@orthly/graphql-react';
import { LoadBlocker } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface PalatePracticeOrdersProps {
    practiceId: string;
    practiceUrl: string;
}

export const PalatePracticeOrders: React.FC<PalatePracticeOrdersProps> = props => {
    const { practiceId, practiceUrl } = props;
    const orderFilters = usePalateState(s => s.orderFilters);
    const patientFilter = orderFilters?.find(filter => filter.filter_id === 'by_patient')?.comparison_value;

    const { scans, loading: loadingScans } = useScans({
        fetchPolicy: 'cache-first',
        variables: {
            practice_id: practiceId,
            patient_names: patientFilter ?? [],
        },
    });

    return (
        <LoadBlocker blocking={loadingScans}>
            <Grid container style={{ paddingBottom: 32 }}>
                <PalateRecentReviews practiceId={practiceId} />
                <InFlightOrders practiceId={practiceId} />
                <IncompleteOrders scans={scans} practiceUrl={practiceUrl} practiceId={practiceId} />
            </Grid>
        </LoadBlocker>
    );
};
