import type {
    EditOperation,
    GeometryEditingOperation,
    InsertionAxisOperation,
    DeformOperation,
} from './EditingHistory.types';

export function isDeformOperation(op: EditOperation): op is DeformOperation {
    return op.type === 'deform';
}

export function isGeometryEditingOperation(op: EditOperation): op is GeometryEditingOperation {
    return 'geometry' in op;
}

export function isInsertionAxisOperation(op: EditOperation): op is InsertionAxisOperation {
    return 'insertionOrientation' in op;
}
