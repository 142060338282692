import { Format } from '@orthly/runtime-utils';
import { FlossPalette, Grid, Tooltip, Text, InfoIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import Papa from 'papaparse';
import React from 'react';

/**
 * Returns the amount in cents, given a string.
 * Returns `undefined` if the value is not a string or an invalid format.
 */

export function cellValToPriceCents(value: any): number | undefined {
    if (typeof value !== 'string') {
        return undefined;
    }
    const dollars = Number(value.replace(/[$,]/g, ''));
    if (isNaN(dollars)) {
        return undefined;
    }
    return Math.round(dollars * 100);
}

/*
  Returns a lambda to provide as SimpleDropzone.onDropAccepted,
  that processes the first file in the upload, or does nothing
  if no files were uploaded.

  `checkColumns`: called with the raw rows of the csv. Should return true if
     the first row is a valid title row and the data can be parsed into 
     a list of Record<string, string>. `checkColumns` is responsible for any
     side-effects like displaying an alert.

  `onValid`: called with the rows converted to objects using the title row's
     values as property names. Only called if `checkColumns` returns true.
*/
export type FileDrop = (files: File[]) => void;
type UseRowsFromCsvArgs<T> = {
    // called with each item in the csv's first row, column headers
    // return the column formatted as desired (to match type)
    // default value makes no modification
    normalizeColumn?: (column: string) => string;

    // called with the csv's first row of column headers after normalization
    // return true if they check out, false if not
    checkColumns: (columns: string[]) => boolean;

    // called with the data rows in the csv as an array of T
    // only called if checkColumns returned true,
    // but any further validation should occur in `onValid`.
    onValid: (rows: Array<T>) => void;
};

export function useRowsFromCsv<T extends Record<string, string | undefined>>({
    checkColumns,
    onValid,
    normalizeColumn = col => col,
}: UseRowsFromCsvArgs<T>): FileDrop {
    return (files: File[]) => {
        if (!files[0]) {
            return;
        }

        Papa.parse(files[0], {
            skipEmptyLines: true,
            complete: results => {
                const rawRows = results.data as string[][];

                if (_.isEmpty(rawRows)) {
                    window.alert("The uploaded file doesn't contain any rows.");
                    return;
                }

                const [rawTitles, ...dataRows] = rawRows;
                if (!rawTitles) {
                    return;
                }
                const titleRow = rawTitles.map(normalizeColumn);
                if (!checkColumns(titleRow)) {
                    return;
                }

                onValid(dataRows.map(row => _.zipObject(titleRow, row) as T));
            },
        });
    };
}

interface PriceItemProps {
    // the new price from the csv
    importPriceCents?: number;
    currentPriceCents?: number;
}

type PriceImportChangeType = 'DELETE' | 'UPDATE' | 'CREATE' | 'UNCHANGED' | 'NO_OP';
type ChangeTypeMap<Val> = { [K in PriceImportChangeType]-?: Val };

function usePriceImportChangeType(props: PriceItemProps) {
    return React.useMemo<PriceImportChangeType>((): PriceImportChangeType => {
        if (props.currentPriceCents === props.importPriceCents) {
            return 'UNCHANGED';
        }
        if (typeof props.importPriceCents !== 'number') {
            return props.currentPriceCents ? 'DELETE' : 'NO_OP';
        }
        return props.currentPriceCents !== undefined ? `UPDATE` : 'CREATE';
    }, [props.currentPriceCents, props.importPriceCents]);
}

const textColorByChange: ChangeTypeMap<string> = {
    CREATE: 'green',
    DELETE: 'red',
    NO_OP: FlossPalette.BLACK,
    UPDATE: FlossPalette.MATERIAL_YELLOW,
    UNCHANGED: FlossPalette.BLACK,
};
const tooltipTitleByChange: ChangeTypeMap<string | undefined> = {
    CREATE: 'New Price',
    DELETE: 'Price Removed',
    NO_OP: undefined,
    UPDATE: 'Price Changed',
    UNCHANGED: 'Price Unchanged',
};

export const PriceImportItem: React.FC<PriceItemProps> = props => {
    const changeType = usePriceImportChangeType(props);
    const tooltipTitle = React.useMemo(() => {
        if (changeType === 'UPDATE' && props.currentPriceCents) {
            return `Option Price Changed from ${Format.currency(props.currentPriceCents, 'cents')}`;
        }
        return tooltipTitleByChange[changeType];
    }, [changeType, props.currentPriceCents]);
    const title = React.useMemo(() => {
        if (typeof props.importPriceCents === 'number') {
            return Format.currency(props.importPriceCents, 'cents');
        }
        return props.currentPriceCents ? 'Deleted' : '-';
    }, [props.currentPriceCents, props.importPriceCents]);
    return (
        <Grid container alignItems={'center'} wrap={'nowrap'}>
            <Text variant={'body1'} style={{ color: textColorByChange[changeType] }}>
                {title}
            </Text>
            {!!tooltipTitle && (
                <Tooltip title={tooltipTitle} arrow>
                    <InfoIcon style={{ width: 18, height: 18, marginLeft: 3 }} color={'action'} />
                </Tooltip>
            )}
        </Grid>
    );
};
