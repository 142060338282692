import { ListOrgSummaries_Query } from '../../Billing/AdminBillingRoot.graphql';
import type {
    RefetchPracticeNames,
    RefetchPricesWithPracticeCounts,
    RefetchSinglePriceWithPracticeCounts,
} from '../PricingRoot.types';
import { useLazyQuery, useQuery } from '@apollo/client';
import type {
    ActivePricePresetsQuery,
    ListOrganizationSummariesQuery,
    ListPricesWithPracticeCountsQuery,
} from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import constate from 'constate';
import _ from 'lodash';
import React from 'react';

const ActivePricePresets_Query = graphql(`
    query activePricePresets {
        active_price_presets {
            cart_field_price_defaults {
                amount_cents
                field_id
                field_price_rule_id
            }
            code
            created_at
            deleted_at
            id
            price_defaults {
                amount_cents
                price_id
            }
            updated_at
        }
    }
`);

const ListPricesWithPracticeCounts_Query = graphql(`
    query listPricesWithPracticeCounts {
        listPricesWithPracticeCounts {
            id
            name
            salesforce_price_mapping
            partner_prices_count
            created_at
            updated_at
            application_rules {
                material_type
                unit_type
            }
        }
    }
`);

const GetPriceWithPracticeCounts_Query = graphql(`
    query getPriceWithPracticeCounts($priceId: String) {
        listPricesWithPracticeCounts(priceId: $priceId) {
            id
            name
            salesforce_price_mapping
            partner_prices_count
            created_at
            updated_at
            application_rules {
                material_type
                unit_type
            }
        }
    }
`);

function useUpdatedPricingQ() {
    const {
        data: { listPricesWithPracticeCounts = [] } = {},
        loading: pricesLoading,
        refetch: refetchPrices,
    } = useQuery(ListPricesWithPracticeCounts_Query, { fetchPolicy: 'no-cache' });

    // used to refetch a single price after update, rather than refetching
    // the entire list. using lazy query since we only want to exeute this query when a
    // refetch is desired (rather than executing on component mount)
    const [refetchSinglePrice, { data: { listPricesWithPracticeCounts: getPriceWithPracticeCounts = [] } = {} }] =
        useLazyQuery(GetPriceWithPracticeCounts_Query);

    // reconcile the bulk fetch with any singleton refetches
    const prices = React.useMemo(() => {
        const refetchedPrice = getPriceWithPracticeCounts[0];
        if (!refetchedPrice) {
            return listPricesWithPracticeCounts;
        }
        return listPricesWithPracticeCounts.map(p => (p.id === refetchedPrice.id ? refetchedPrice : p));
    }, [listPricesWithPracticeCounts, getPriceWithPracticeCounts]);

    const {
        data: { listOrganizationSummaries: practiceNamesRaw = [] } = {},
        loading: practiceNamesLoading,
        refetch: refetchPracticeNames,
    } = useQuery(ListOrgSummaries_Query, { variables: { filter: { type: LabsGqlOrganizationType.Practice } } });

    const practiceNamesById = React.useMemo(
        () => _.fromPairs(practiceNamesRaw.map(p => [p.id, p.name])),
        [practiceNamesRaw],
    );

    const getPracticeNameById = React.useMemo(
        () => (id: string) => practiceNamesById[id] ?? 'Failed to load practice name',
        [practiceNamesById],
    );

    const practiceOpts = React.useMemo(
        () => practiceNamesRaw.map(p => ({ value: p.id, label: p.name })),
        [practiceNamesRaw],
    );

    const { data: { active_price_presets: activePricePresets = [] } = {}, loading: pricePresetsLoading } =
        useQuery(ActivePricePresets_Query);

    return {
        prices,
        pricesLoading,
        refetchPrices,
        refetchSinglePrice,
        activePricePresets,
        pricePresetsLoading,
        practiceNames: practiceNamesRaw,
        practiceOpts,
        practiceNamesById,
        getPracticeNameById,
        practiceNamesLoading,
        refetchPracticeNames,
    };
}

const [UpdatedPricingQueryProv, useUpdatedPricingQueryRaw] = constate(useUpdatedPricingQ);

export const UpdatedPricingQueryProvider = UpdatedPricingQueryProv;

export const useUpdatedPricingQuery: () => {
    // price data -----
    prices: ListPricesWithPracticeCountsQuery['listPricesWithPracticeCounts'];
    pricesLoading: boolean;
    refetchPrices: RefetchPricesWithPracticeCounts;
    refetchSinglePrice: RefetchSinglePriceWithPracticeCounts;
    activePricePresets: ActivePricePresetsQuery['active_price_presets'];
    pricePresetsLoading: boolean;
    // practice data -----
    practiceNames: ListOrganizationSummariesQuery['listOrganizationSummaries'];
    practiceOpts: { value: string; label: string }[];
    practiceNamesById: Record<string, string>;
    getPracticeNameById: (id: string) => string;
    practiceNamesLoading: boolean;
    refetchPracticeNames: RefetchPracticeNames;
} = useUpdatedPricingQueryRaw;
