import { EditUserDesignCapabilities } from './DesignCapabilities/EditUserDesignCapabilities';
import { EditUserDesignStaffRosterInfo } from './EditUserDesignStaffRosterInfo';
import { useGetDesignStaffQuery, useUpsertDesignStaffMutation } from '@orthly/graphql-react';
import { LabsGqlDesignStaffTaskType } from '@orthly/graphql-schema';
import { ActionCard, LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import { Grid, Divider, CircularProgress, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

export const DesignStaffSection: React.VFC<{ userId: string }> = ({ userId }) => {
    const { data, error, loading, refetch } = useGetDesignStaffQuery({
        variables: {
            user_id: userId,
        },
    });
    const designStaff = data?.getDesignStaff || undefined;

    const [upsertDesignStaff] = useUpsertDesignStaffMutation();
    const { submit: createDesignStaff, submitting: submittingCreateDesignStaff } = useChangeSubmissionFn(
        () =>
            upsertDesignStaff({
                variables: { user_id: userId, task_type: LabsGqlDesignStaffTaskType.InternalDesign, capabilities: {} },
            }),
        {
            successMessage: () => ['User converted to design staff!'],
            onSuccess: () => {
                void refetch();
            },
        },
    );

    return (
        <>
            <Grid item style={{ paddingTop: 8 }}>
                <Divider />
            </Grid>
            <Grid item style={{ padding: '16px 0px' }}>
                <Text variant={'h6'}>Design staff</Text>
            </Grid>
            <LoadBlocker blocking={loading} />
            {!loading && (
                <Grid item container style={{ paddingLeft: 32, rowGap: 24 }}>
                    {designStaff ? (
                        <>
                            <Grid container item>
                                <EditUserDesignStaffRosterInfo
                                    designerStaff={designStaff}
                                    refetchStaffCallback={refetch}
                                />
                            </Grid>
                            <Grid container item>
                                <EditUserDesignCapabilities designerStaff={designStaff} />
                            </Grid>
                        </>
                    ) : (
                        <Grid item container style={{ paddingTop: 8 }} alignItems={'center'} spacing={2}>
                            <Grid item>
                                <Button
                                    variant={'secondary'}
                                    onClick={() => {
                                        void createDesignStaff();
                                    }}
                                >
                                    Convert to design staff
                                </Button>
                            </Grid>
                            {submittingCreateDesignStaff && (
                                <Grid item>
                                    <CircularProgress size={24} />
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
            )}
            {error && (
                <Grid container direction={'column'}>
                    <ActionCard title={`Failed to load design staff data.`} subtitle={`${error}`} variant={'alert'} />
                </Grid>
            )}
        </>
    );
};
