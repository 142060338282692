import { orderListItemPropsEqual } from '../../../components/OrderListItem/OrderListItem.utils';
import type { AdminState } from '../../../redux/redux.types';
import { SavedViewActionUtils } from '../../../redux/utils/SavedViewActionUtils';
import type { OpsInboxScreen, InboxSortState } from './Inbox.types';
import { cleanTasksFilter } from './InboxStateUtils';
import type {
    LabsGqlWorkflowTaskFragment,
    LabsGqlWorkflowTasksByListViewQueryVariables,
} from '@orthly/graphql-operations';
import type { LabsGqlListWorkflowTasksFilter } from '@orthly/graphql-schema';
import {
    LabsGqlResponsiblePartyEnum,
    LabsGqlWorkflowTaskSortField,
    LabsGqlWorkflowTaskType,
} from '@orthly/graphql-schema';
import { generateUseSubstateSelector } from '@orthly/redux-async-actions';
import { Format } from '@orthly/runtime-utils';
import type { ConstEnum } from '@orthly/runtime-utils';
import { useSession } from '@orthly/session-client';
import { useDebouncedValue } from '@orthly/ui';
import type { OrderOpsListItemContentProps } from '@orthly/veneer';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

export const useInboxState = generateUseSubstateSelector<AdminState, 'inbox'>('inbox');

export const OpsInboxTaskEnabledMap: { [K in LabsGqlWorkflowTaskType]: K extends OpsInboxScreen ? true : false } = {
    [LabsGqlWorkflowTaskType.ResolveHold]: true,
    [LabsGqlWorkflowTaskType.ResolveScanRejection]: true,
    [LabsGqlWorkflowTaskType.ReviewOrder]: true,
    [LabsGqlWorkflowTaskType.ScanReview]: true,
    [LabsGqlWorkflowTaskType.InternalDesign]: true,
    [LabsGqlWorkflowTaskType.DesignReview]: true,
    [LabsGqlWorkflowTaskType.DesignReview2]: true,
    [LabsGqlWorkflowTaskType.DesignPrep]: true,
    [LabsGqlWorkflowTaskType.ModelDesign]: true,
    [LabsGqlWorkflowTaskType.InjectionMoldDesign]: true,
    [LabsGqlWorkflowTaskType.DrReviewHold]: false,
    [LabsGqlWorkflowTaskType.AcknowledgeDelivery]: false,
    [LabsGqlWorkflowTaskType.WaxupReview]: false,
    [LabsGqlWorkflowTaskType.ExternalDesign]: false,
    [LabsGqlWorkflowTaskType.PullFromFloor]: false,
    [LabsGqlWorkflowTaskType.ReturnOrder]: false,
    [LabsGqlWorkflowTaskType.ShipOrder]: false,
    [LabsGqlWorkflowTaskType.LabAcceptOrder]: false,
    [LabsGqlWorkflowTaskType.SendToFloor]: false,
    [LabsGqlWorkflowTaskType.AcknowledgeReturn]: false,
    [LabsGqlWorkflowTaskType.ExternalDesignVerification]: true,
    [LabsGqlWorkflowTaskType.AutomateDesign]: false,
    [LabsGqlWorkflowTaskType.AutomateReview]: true,
    // aligner stuff
    [LabsGqlWorkflowTaskType.CreateTreatmentPlanRequest]: true,
    [LabsGqlWorkflowTaskType.ApproveAlignerFabrication]: true,
    [LabsGqlWorkflowTaskType.ConvertTreatmentPlanRequest]: true,
    [LabsGqlWorkflowTaskType.CreateExternalAlignerFulfillment]: true,
    [LabsGqlWorkflowTaskType.SetTreatmentPlanRequestUrl]: true,
    [LabsGqlWorkflowTaskType.FinalizeTreatmentPlanRequest]: true,
};
const OpsInboxTaskTypes: LabsGqlWorkflowTaskType[] = Object.values(LabsGqlWorkflowTaskType).filter(
    taskType => OpsInboxTaskEnabledMap[taskType],
);

const PracticeInboxTaskTypes: LabsGqlWorkflowTaskType[] = Object.values(LabsGqlWorkflowTaskType).filter(
    taskType => taskType !== LabsGqlWorkflowTaskType.AcknowledgeDelivery,
);

function filterForTaskType(type: LabsGqlWorkflowTaskType): LabsGqlListWorkflowTasksFilter {
    if (type === LabsGqlWorkflowTaskType.ApproveAlignerFabrication) {
        return { completed: false, type: [type], responsible_party: { role: LabsGqlResponsiblePartyEnum.Practice } };
    }
    return { completed: false, type: [type] };
}

export function useInboxTasksFilter(): LabsGqlListWorkflowTasksFilter | null {
    const session = useSession();
    const userId = session?.user_id;
    const staffId = session?.id;

    const screen = useInboxState(s => s.screen);
    const tasksFilter = useInboxState(s => s.view?.tasksFilter);
    return React.useMemo<LabsGqlListWorkflowTasksFilter | null>(() => {
        switch (screen) {
            case 'AllUnassigned':
                return { assigned: false, completed: false, type: OpsInboxTaskTypes };
            case 'AssignedToOthers':
                return {
                    completed: false,
                    assigned: true,
                    exclude_assigned_user_ids: userId ? [userId] : [],
                    type: OpsInboxTaskTypes,
                };
            case 'AssignedToMe':
                return { assigned_user_ids: userId ? [userId] : [], type: OpsInboxTaskTypes, completed: false };
            case 'CompletedByMe':
                return { type: OpsInboxTaskTypes, completed: true, completed_by_ids: staffId ? [staffId] : [] };
            case 'WaitingOnPractice':
                return {
                    completed: false,
                    type: PracticeInboxTaskTypes,
                    responsible_party: { role: LabsGqlResponsiblePartyEnum.Practice },
                };
            case 'Custom':
                return cleanTasksFilter(tasksFilter) ?? null;
            default:
                return filterForTaskType(screen);
        }
    }, [screen, userId, tasksFilter, staffId]);
}

// For incomplete tasks, we go by highest priority task first, since it needs to be completed more urgently.
const defaultIncompleteTasksSort = { key: LabsGqlWorkflowTaskSortField.LabDesignPriority, asc: true };

// For completed tasks, we want to show most recent first, as that's almost certainly what they're actually looking for.
const defaultCompletedTasksSort = { key: LabsGqlWorkflowTaskSortField.TaskClosedAt, asc: false };

export function useCurrentInboxSort(): InboxSortState {
    const screen = useInboxState(s => s.screen);
    const sort = useInboxState(s => s.view?.sort);

    if (screen === 'Custom') {
        return sort ?? defaultIncompleteTasksSort;
    }

    if (screen === 'CompletedByMe') {
        return defaultCompletedTasksSort;
    }

    return defaultIncompleteTasksSort;
}

export function useInboxTasksQueryVars(): LabsGqlWorkflowTasksByListViewQueryVariables {
    const task_filter = useInboxTasksFilter();
    const view = useInboxState(s => s.view);
    const sort = useCurrentInboxSort();
    const activeSearchValue = useDebouncedValue(view?.search ?? null, 50);
    return {
        task_filter,
        sort: { field: sort.key, isAsc: sort.asc },
        order_filter: SavedViewActionUtils.cleanOrderFilter(view?.ordersCriteria) ?? null,
        search: activeSearchValue,
    };
}

export const OpsInboxScreenEnum: ConstEnum<OpsInboxScreen> = {
    ResolveScanRejection: LabsGqlWorkflowTaskType.ResolveScanRejection,
    ScanReview: LabsGqlWorkflowTaskType.ScanReview,
    ReviewOrder: LabsGqlWorkflowTaskType.ReviewOrder,
    ResolveHold: LabsGqlWorkflowTaskType.ResolveHold,
    InternalDesign: LabsGqlWorkflowTaskType.InternalDesign,
    CreateTreatmentPlanRequest: LabsGqlWorkflowTaskType.CreateTreatmentPlanRequest,
    SetTreatmentPlanRequestUrl: LabsGqlWorkflowTaskType.SetTreatmentPlanRequestUrl,
    ConvertTreatmentPlanRequest: LabsGqlWorkflowTaskType.ConvertTreatmentPlanRequest,
    FinalizeTreatmentPlanRequest: LabsGqlWorkflowTaskType.FinalizeTreatmentPlanRequest,
    ApproveAlignerFabrication: LabsGqlWorkflowTaskType.ApproveAlignerFabrication,
    CreateExternalAlignerFulfillment: LabsGqlWorkflowTaskType.CreateExternalAlignerFulfillment,
    AllUnassigned: 'AllUnassigned',
    AssignedToOthers: 'AssignedToOthers',
    AssignedToMe: 'AssignedToMe',
    CompletedByMe: 'CompletedByMe',
    WaitingOnPractice: 'WaitingOnPractice',
    DesignReview: LabsGqlWorkflowTaskType.DesignReview,
    DesignReview2: LabsGqlWorkflowTaskType.DesignReview2,
    DesignPrep: LabsGqlWorkflowTaskType.DesignPrep,
    AutomateReview: LabsGqlWorkflowTaskType.AutomateReview,
    ModelDesign: LabsGqlWorkflowTaskType.ModelDesign,
    InjectionMoldDesign: LabsGqlWorkflowTaskType.InjectionMoldDesign,
    ExternalDesignVerification: LabsGqlWorkflowTaskType.ExternalDesignVerification,
};

export function isInboxScreen(screen: OpsInboxScreen | string): screen is OpsInboxScreen {
    return OpsInboxScreenEnum.hasOwnProperty(screen);
}

export const OpsInboxScreenLabels: { [K in OpsInboxScreen]-?: string } = {
    ResolveScanRejection: 'Resolve flagged scan',
    ScanReview: 'Scan review',
    ReviewOrder: 'Order review',
    ResolveHold: 'Resolve hold',
    InternalDesign: 'Dandy design',
    CreateTreatmentPlanRequest: 'Send to planner',
    SetTreatmentPlanRequestUrl: 'Attach plan',
    ConvertTreatmentPlanRequest: 'Convert plan',
    FinalizeTreatmentPlanRequest: 'Finalize plan',
    ApproveAlignerFabrication: 'Approve aligners',
    CreateExternalAlignerFulfillment: 'Send to manufacturer',
    AllUnassigned: 'All unassigned',
    AssignedToOthers: 'Assigned to others',
    AssignedToMe: 'Your tasks',
    CompletedByMe: 'Completed tasks',
    DesignReview: 'Design review',
    DesignReview2: 'Double Design QC',
    DesignPrep: 'Design prep',
    AutomateReview: 'Automate review',
    ModelDesign: 'Model design',
    InjectionMoldDesign: 'Injection mold design',
    WaitingOnPractice: 'Waiting on Practice',
    ExternalDesignVerification: 'Design Preview verification',
};

export const WorkflowTaskItemLabel: { [K in LabsGqlWorkflowTaskType]-?: string } = {
    [LabsGqlWorkflowTaskType.ResolveScanRejection]: OpsInboxScreenLabels.ResolveScanRejection,
    [LabsGqlWorkflowTaskType.ScanReview]: OpsInboxScreenLabels.ScanReview,
    [LabsGqlWorkflowTaskType.ReviewOrder]: OpsInboxScreenLabels.ReviewOrder,
    [LabsGqlWorkflowTaskType.ResolveHold]: OpsInboxScreenLabels.ResolveHold,
    [LabsGqlWorkflowTaskType.DrReviewHold]: 'Dr Review Hold',
    [LabsGqlWorkflowTaskType.InternalDesign]: OpsInboxScreenLabels.InternalDesign,
    [LabsGqlWorkflowTaskType.DesignReview]: OpsInboxScreenLabels.DesignReview,
    [LabsGqlWorkflowTaskType.DesignReview2]: OpsInboxScreenLabels.DesignReview2,
    [LabsGqlWorkflowTaskType.ExternalDesignVerification]: OpsInboxScreenLabels.ExternalDesignVerification,
    [LabsGqlWorkflowTaskType.ExternalDesign]: 'External Design',
    [LabsGqlWorkflowTaskType.WaxupReview]: 'Review Design Preview',
    [LabsGqlWorkflowTaskType.DesignPrep]: 'Design prep',
    [LabsGqlWorkflowTaskType.AutomateReview]: 'Automate review',
    [LabsGqlWorkflowTaskType.ModelDesign]: 'Model Design',
    [LabsGqlWorkflowTaskType.InjectionMoldDesign]: 'Injection Mold Design',
    [LabsGqlWorkflowTaskType.AcknowledgeDelivery]: 'Acknowledge delivery',
    [LabsGqlWorkflowTaskType.LabAcceptOrder]: 'Accept order',
    [LabsGqlWorkflowTaskType.SendToFloor]: 'Send to floor',
    [LabsGqlWorkflowTaskType.AcknowledgeReturn]: 'Acknowledge return',
    [LabsGqlWorkflowTaskType.ShipOrder]: 'Ship order',
    [LabsGqlWorkflowTaskType.PullFromFloor]: 'Pull from floor',
    [LabsGqlWorkflowTaskType.ReturnOrder]: 'Return order',
    [LabsGqlWorkflowTaskType.AutomateDesign]: 'AutomateDesign',
    CreateTreatmentPlanRequest: OpsInboxScreenLabels.CreateTreatmentPlanRequest,
    SetTreatmentPlanRequestUrl: OpsInboxScreenLabels.SetTreatmentPlanRequestUrl,
    ConvertTreatmentPlanRequest: OpsInboxScreenLabels.ConvertTreatmentPlanRequest,
    FinalizeTreatmentPlanRequest: OpsInboxScreenLabels.FinalizeTreatmentPlanRequest,
    ApproveAlignerFabrication: OpsInboxScreenLabels.ApproveAlignerFabrication,
    CreateExternalAlignerFulfillment: OpsInboxScreenLabels.CreateExternalAlignerFulfillment,
};

export function workflowTaskItemLabel(task: LabsGqlWorkflowTaskFragment) {
    if (task.__typename === 'DesignReviewWorkflowTask') {
        const taskName = WorkflowTaskItemLabel[task.type];
        const taskIsOptional = task.configuration.blocks_fabrication ? '' : ' (optional)';
        return `${taskName}${taskIsOptional}`;
    }
    if (task.__typename === 'InternalDesignWorkflowTask') {
        const taskName = WorkflowTaskItemLabel[task.type];
        const taskIsRevision = task.configuration.is_revision ? ' (revision)' : '';
        return `${taskName}${taskIsRevision}`;
    }
    return WorkflowTaskItemLabel[task.type];
}

const showLabDueDateTaskTypes: LabsGqlWorkflowTaskType[] = [
    LabsGqlWorkflowTaskType.ReviewOrder,
    LabsGqlWorkflowTaskType.DesignPrep,
    LabsGqlWorkflowTaskType.ScanReview,
    LabsGqlWorkflowTaskType.AutomateReview,
    LabsGqlWorkflowTaskType.InternalDesign,
    LabsGqlWorkflowTaskType.DesignReview,
    LabsGqlWorkflowTaskType.WaxupReview,
    LabsGqlWorkflowTaskType.ExternalDesignVerification,
];

export function useInboxItemDateMessage(task: LabsGqlWorkflowTaskFragment, designDueDate: string | null): string {
    return React.useMemo(() => {
        if (task.closeout) {
            return `Completed ${Format.fromNow(task.closeout.closed_at, { max: 1 })}`;
        }
        const isDueToLabDate = showLabDueDateTaskTypes.includes(task.type);
        const dueDate = isDueToLabDate ? designDueDate : task.due_at;
        if (dueDate) {
            const duePrefix = isDueToLabDate ? 'Due to lab:' : 'Due:';
            if (new Date(dueDate) > new Date()) {
                return `${duePrefix} ${moment(dueDate).format(`MM/DD ha`)}`;
            }
            return `${Format.fromNow(dueDate, { max: 1 })} ${isDueToLabDate ? 'late for lab' : 'late'}`;
        }
        return `${Format.fromNow(task.created_at, { max: 1 })} old`;
    }, [designDueDate, task.closeout, task.created_at, task.due_at, task.type]);
}

export interface InboxListItemProps {
    listItemContent?: OrderOpsListItemContentProps;
    task: LabsGqlWorkflowTaskFragment;
}

export function taskListItemPropsAreEqual(prev: InboxListItemProps, next: InboxListItemProps): boolean {
    const ordersMatch = orderListItemPropsEqual(prev, next);
    return ordersMatch && _.isEqual(prev.task, next.task);
}
