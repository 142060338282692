import { Text, IconButton, KeyboardArrowLeft, KeyboardArrowRight } from '@orthly/ui-primitives';
import React from 'react';

const style: React.CSSProperties = {
    padding: '16px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

export const Pagination: React.FC<{ page: number; setPage: (page: number) => void }> = ({ page, setPage }) => (
    <div style={style}>
        <IconButton onClick={_ => setPage(page - 1)} disabled={page <= 1}>
            <KeyboardArrowLeft />
        </IconButton>
        <Text medium variant={'body1'}>
            {page}
        </Text>
        <IconButton onClick={_ => setPage(page + 1)}>
            <KeyboardArrowRight />
        </IconButton>
    </div>
);
