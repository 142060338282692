import { LoadBlocker } from '../Loaders';
import { ValidationUtils } from '@orthly/runtime-utils';
import type { IconName } from '@orthly/ui-primitives';
import { TextField, FormHelperText, Grid, Button } from '@orthly/ui-primitives';
import React from 'react';

interface EmailPasswordLoginFieldsProps {
    loading: boolean;
    error?: string;
    onSubmit: (args: { email: string; password: string }) => void;
    containerStyle?: React.CSSProperties;
    email?: string;
}

type LoginInfo = { email: string; password: string };

export function useLoginValidation(fields: LoginInfo) {
    const { email, password } = fields;
    return React.useMemo(() => {
        const emailValid = !!email && ValidationUtils.isEmail(email);
        const passwordValid = !!password;
        let emailHelper;
        if (!emailValid) {
            if (!email) {
                emailHelper = 'Please enter email';
            } else {
                emailHelper = 'Email is invalid';
            }
        }
        const passwordHelper = !password ? 'Please enter password' : '';
        const helperText = emailHelper || passwordHelper;
        return {
            helperText,
            errors: !emailValid || !passwordValid,
            passwordError: !!email && ValidationUtils.isEmail(email) && !password,
            emailError: !!email && !emailValid,
        };
    }, [email, password]);
}

interface LoginFieldProps {
    label: string;
    value: string;
    autoFocus?: boolean;
    padInputBottom?: boolean;
    helperText?: string;
    errorMessage?: string;
    fullWidth?: boolean;
    placeholder?: string;
    onBlur?: () => void;
    onChange: (value: string) => void;
    style?: React.CSSProperties;
}

export const PasswordLoginField: React.FC<LoginFieldProps> = props => {
    const { padInputBottom, errorMessage, onBlur, onChange, ...textFieldProps } = props;

    return (
        <TextField
            variant={'standard'}
            InputProps={{
                autoComplete: 'current-password',
                type: 'password',
                style: { marginBottom: padInputBottom ? 4 : 0 },
            }}
            {...textFieldProps}
            onBlur={() => onBlur?.()}
            onChange={e => onChange(e.target.value ?? '')}
            type={'password'}
            margin={'normal'}
            error={!!errorMessage}
            helperText={errorMessage}
            inputProps={{ 'data-test': 'password-login-input' }}
        />
    );
};

export const EmailLoginField: React.FC<LoginFieldProps> = props => {
    const { autoFocus, helperText, padInputBottom, errorMessage, onBlur, onChange, ...textFieldProps } = props;

    return (
        <TextField
            variant={'standard'}
            InputProps={{
                autoComplete: 'email',
                autoFocus: !!autoFocus,
                inputMode: 'email',
                type: 'email',
                style: { marginBottom: padInputBottom ? 4 : 0 },
            }}
            {...textFieldProps}
            onBlur={() => onBlur?.()}
            onChange={e => onChange(e.target.value ?? '')}
            margin={'none'}
            error={!!errorMessage}
            helperText={errorMessage || helperText}
            inputProps={{ 'data-test': 'email-login-input' }}
        />
    );
};

interface LoginSubmitButtonProps {
    text: string;
    fullWidth?: boolean;
    disabled?: boolean;
    endIcon?: IconName;
    style?: React.CSSProperties;
}

export const LoginSubmitButton: React.FC<LoginSubmitButtonProps> = props => {
    const { text, ...buttonProps } = props;

    return (
        <Button {...buttonProps} type={'submit'} variant={'primary'} data-test={'login-button'}>
            {text}
        </Button>
    );
};

export const EmailPasswordLoginFields: React.FC<EmailPasswordLoginFieldsProps> = props => {
    const { loading, error } = props;
    const [email, setEmail] = React.useState(props.email ?? '');
    const [password, setPassword] = React.useState('');
    const submit = async () => {
        if (!loading && !!email && !!password) {
            props.onSubmit({ email, password });
        }
    };
    const validation = useLoginValidation({ email, password });
    return (
        <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            style={{ padding: 20, ...props.containerStyle }}
        >
            <LoadBlocker blocking={loading} loader={'dandy'}>
                <form
                    style={{ width: '100%' }}
                    onSubmit={evt => {
                        evt.preventDefault();
                        void submit();
                    }}
                >
                    <Grid container justifyContent={'center'}>
                        <EmailLoginField
                            autoFocus
                            label={'Email'}
                            value={email}
                            fullWidth
                            onChange={value => setEmail(value)}
                            errorMessage={validation.emailError ? 'Invalid Email' : undefined}
                        />
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        <PasswordLoginField
                            label={'Password'}
                            value={password}
                            fullWidth
                            onChange={value => setPassword(value)}
                            errorMessage={validation.passwordError ? 'Please enter a password' : undefined}
                        />
                    </Grid>
                    <Grid container justifyContent={'center'} style={{ marginTop: 10 }}>
                        <LoginSubmitButton
                            fullWidth
                            text={loading ? 'Logging in...' : 'Login'}
                            disabled={validation.errors || loading}
                        />
                        {error && <FormHelperText error={true}>{error}</FormHelperText>}
                    </Grid>
                    {props.children && (
                        <Grid container justifyContent={'center'} style={{ marginTop: 10 }}>
                            {props.children}
                        </Grid>
                    )}
                </form>
            </LoadBlocker>
        </Grid>
    );
};
