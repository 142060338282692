import { CreateActionForm } from './CreateActionForm';
import { useOrderTicketsRefetch } from './OrderTickets.utils';
import { useAppendTicketActionMutation } from '@orthly/graphql-react';
import type { LabsGqlTicketDto } from '@orthly/graphql-schema';
import { LabsGqlActionStatus } from '@orthly/graphql-schema';
import { RootActionDialog, useRootActionCommand } from '@orthly/ui';
import React from 'react';

export const AppendActionDialog: React.FC<{ ticket: LabsGqlTicketDto; onClose: () => void }> = ({
    ticket,
    onClose,
}) => {
    const [open, setOpen] = React.useState<boolean>(true);
    const refetchTickets = useOrderTicketsRefetch(ticket.order_id);

    const { submit, submitting } = useRootActionCommand(useAppendTicketActionMutation(), {
        successMessage: `Action added!`,
        onSuccess: () => {
            refetchTickets();
            setOpen(false);
            onClose();
        },
    });

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            onClose={() => {
                setOpen(false);
                onClose();
            }}
            title={`Add Action to Ticket`}
            content={
                !open ? null : (
                    <CreateActionForm
                        orderId={ticket.order_id}
                        showCancelPendingOption={true}
                        onSubmit={result => {
                            const afterAction =
                                [...ticket.actions]
                                    .reverse()
                                    .find(
                                        action =>
                                            action.status === LabsGqlActionStatus.Pending && !action.followup_parent_id,
                                    ) ?? ticket.active_action;
                            void submit({
                                data: {
                                    ticket_id: ticket.id,
                                    after_action_id: afterAction?.id ?? '',
                                    action: {
                                        type_id: result.type_id,
                                        notes: result.notes,
                                        activate_at: result.activate_at,
                                        due_at: result.due_at,
                                        enable_followup_action: result.enable_followup_action,
                                    },
                                    cancel_pending: result.cancel_pending,
                                },
                            });
                        }}
                    />
                )
            }
            CustomButton={() => null}
        />
    );
};
