import type { AutomationListItemData } from './AutomationsListFilterToolbar';
import type { LabsGqlOrderAutomationListElementFragment } from '@orthly/graphql-operations';
import {
    useListOrderAutomationsQuery,
    useListRetainerUsersQuery,
    useListAutomationActionsQuery,
} from '@orthly/graphql-react';
import _ from 'lodash';
import React from 'react';

export function useAutomationListItems() {
    const {
        loading: automationsLoading,
        data,
        refetch,
    } = useListOrderAutomationsQuery({
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });
    const creatingUserIds = React.useMemo(() => {
        return _.uniq(data?.listOrderAutomations.map(a => a.created_by_user_id) ?? []);
    }, [data]);
    const { data: usersData, loading: usersLoading } = useListRetainerUsersQuery({
        nextFetchPolicy: 'cache-first',
        fetchPolicy: 'cache-first',
        skip: creatingUserIds.length === 0,
        variables: { filter: { ids: creatingUserIds, includeDeactivated: true } },
    });

    const { data: actionData, loading: actionsLoading } = useListAutomationActionsQuery({
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });

    const actionById = React.useMemo(() => {
        return _.keyBy(actionData?.actions, action => action.id);
    }, [actionData?.actions]);

    const automations = React.useMemo<AutomationListItemData[]>(() => {
        const usersById = _.keyBy(usersData?.listUsers ?? [], u => u.id);
        const automationsRaw: LabsGqlOrderAutomationListElementFragment[] = data?.listOrderAutomations ?? [];

        // We avoid returning our list until everything is done loading.
        // This helps us avoid issues where we allow users to click into automations that can't actually be saved yet.
        if (actionsLoading || automationsLoading || usersLoading) {
            return [];
        }

        return automationsRaw.map(a => ({
            __typename: a.__typename,
            id: a.id,
            name: a.name,
            description: a.description,
            created_at: a.created_at,
            updated_at: a.updated_at,
            triggered_by: a.triggered_by,
            created_by_user_id: a.created_by_user_id,
            folder: a.folder,
            archived: a.archived,
            filter: a.filter,

            actions: _.compact(
                a.actions.map(({ action_id, fields }) => {
                    const action = actionById[action_id];

                    if (!action) {
                        return null;
                    }

                    return {
                        action,
                        fields,
                        action_id: action.id,
                        name: action.name,
                        description: action.description,
                        test_available: action.test_available,
                    };
                }),
            ),
            creator: usersById[a.created_by_user_id] ?? null,
        }));
    }, [data, usersData, actionById, automationsLoading, actionsLoading, usersLoading]);
    return { automations, refetch, loading: automationsLoading || actionsLoading || usersLoading };
}
