import type { ItemInfo, Rule, Decision } from '../orderEditMode';
import { OrderEditMode } from '@orthly/shared-types';

/**
 * editedShade checks for the edited item shade matching any of its
 * specified names.  If it finds a match it returns EditOrder as
 * the most permissible possible value, otherwise it returns null to
 * skip
 */
export function editedShade(...names: string[]): Rule {
    return function (item: ItemInfo): Decision {
        const debugInfo = {
            rule: 'editedShade',
            expected: names,
            received: item.editedShade,
        };

        if (item.editedShade && names.includes(item.editedShade)) {
            return {
                editMode: OrderEditMode.OrderEdit,
                debugInfo,
            };
        }

        return {
            editMode: null,
            debugInfo,
        };
    };
}
