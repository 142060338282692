import type { ToolbarActionDefinition, ToolbarActionProps } from './ToolbarActionDefinition';
import {
    useCreateFulfillmentMutation,
    useMarkFulfillmentShippedMutation,
    useMarkFulfillmentDeliveredMutation,
} from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus, LabsGqlShippingCarriersEnum } from '@orthly/graphql-schema';
import { OrthlyBrowserConfig, RootActionDialog } from '@orthly/ui';
import { Text } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const NonProdQuickMarkDeliveredAction: React.FC<ToolbarActionProps> = props => {
    const { order, open, setOpen, refetchOrder } = props;
    const [submitMtn] = useCreateFulfillmentMutation({ variables: { data: { orderId: order.id } } });
    const submitMarkInFabrication = submitMtn;
    const [submitMarkShipped] = useMarkFulfillmentShippedMutation();
    const [submitMarkDelivered] = useMarkFulfillmentDeliveredMutation({ variables: { data: { orderId: order.id } } });
    const startedRef = React.useRef(false);
    const [status, setStatus] = React.useState('About to get started ...');
    React.useEffect(() => {
        if (startedRef.current || !confirm('Quick mark delivered?')) {
            setOpen(false);
            return;
        }
        startedRef.current = true;
        void submitMarkInFabrication()
            .then(() => {
                setStatus('Marking shipped ...');
                return submitMarkShipped({
                    variables: {
                        data: {
                            carrier: LabsGqlShippingCarriersEnum.Ups,
                            orderId: order.id,
                            ship_date: moment().toJSON(),
                            tracking_number: uuidv4(),
                        },
                    },
                });
            })
            .then(() => {
                setStatus('Marking delivered ...');
                return submitMarkDelivered();
            })
            .then(() => {
                setStatus('Refetching ...');
                return refetchOrder();
            })
            .then(() => {
                setStatus('Done.');
                setOpen(false);
            });
    }, [order.id, refetchOrder, setOpen, submitMarkDelivered, submitMarkInFabrication, submitMarkShipped]);
    return (
        <RootActionDialog
            loading={true}
            open={open}
            setOpen={setOpen}
            title={'Quick mark delivered'}
            content={
                <div style={{ minHeight: 150 }}>
                    <Text variant={'body1'}>{status}</Text>
                </div>
            }
            buttonText={''}
            CustomButton={props.CustomButton}
        />
    );
};

export const NonProdQuickMarkDeliveredActionToolbarAction: ToolbarActionDefinition = {
    label: 'Quick deliver [nonProd]',
    Component: NonProdQuickMarkDeliveredAction,
    allowedStatuses: [LabsGqlLabOrderStatus.New],
    isVisible: () => !OrthlyBrowserConfig.isProduction,
};
