import { AlignerTreatmentPlanStepDot } from './AlignerTreatmentPlanSidebar.graphql';
import { useTreatmentPlanViews } from './useTreatmentPlanViews.graphql';
import { ModelViewer } from '@orthly/dentin';
import { type FragmentType, getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { LoadBlocker, useInterval } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    Text,
    useScreenIsMd,
    ButtonGroup,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    ChevronLeft,
    ChevronRight,
    CloseIcon,
    PauseIcon,
    PlayArrowIcon,
} from '@orthly/ui-primitives';
import React from 'react';

const VeneerAlignerTreatmentPlanViewer_Fragment = graphql(`
    fragment VeneerAlignerTreatmentPlanViewer_Fragment on DesignOrderAlignerTreatmentPlanDTO {
        steps {
            interproximal_reductions {
                reduction_in_mm
                tooth
            }
        }
        source
        ...VeneerUseTreatmentPlanViews_Fragment
    }
`);

export const AlignerTreatmentPlanViewer: React.FC<{
    treatmentPlanFragment: FragmentType<typeof VeneerAlignerTreatmentPlanViewer_Fragment>;
    fullScreen: boolean;
    style?: React.CSSProperties;
    selectedStepIndex: number;
    setSelectedStepIndex: (value: ((prevState: number) => number) | number) => void;
    hideToolbar?: boolean;
}> = props => {
    const { treatmentPlanFragment, fullScreen, selectedStepIndex, setSelectedStepIndex, style, hideToolbar } = props;

    const treatmentPlan = getFragmentData(VeneerAlignerTreatmentPlanViewer_Fragment, treatmentPlanFragment);

    const { result: treatmentPlanViews, loading: treatingPlanViewsLoading } = useTreatmentPlanViews(treatmentPlan);

    const [isAutoPlaying, setIsAutoPlaying] = React.useState<boolean>(false);

    const isMobile = useScreenIsMd();

    useInterval(() => {
        if (!isAutoPlaying) {
            return;
        }

        if (treatmentPlanViews && selectedStepIndex + 1 < treatmentPlanViews.length) {
            setSelectedStepIndex(idx => idx + 1);
        } else {
            setIsAutoPlaying(false);
        }
    }, 300);

    // The currently selected view!
    const selectedView = React.useMemo(() => {
        return treatmentPlanViews?.[selectedStepIndex];
    }, [selectedStepIndex, treatmentPlanViews]);

    // step index => the button corresponding to this step, if any
    const buttonRefArray = React.useRef<Array<HTMLElement | null>>([]);

    React.useEffect(() => {
        buttonRefArray.current.length = treatmentPlanViews?.length ?? 0;
    }, [treatmentPlanViews]);

    React.useEffect(() => {
        // the currently selected button
        const selectedButton = buttonRefArray.current[selectedStepIndex];
        // scroll the currently selected button to center of stepper
        selectedButton?.scrollIntoView({ block: 'nearest', inline: 'center' });
    }, [selectedStepIndex]);

    const buttonColor = fullScreen ? FlossPalette.TAN : FlossPalette.WHITE;

    return (
        <LoadBlocker ContainerProps={{ style }} blocking={treatingPlanViewsLoading} overlayColor={'transparent'}>
            <Grid container style={{ flexDirection: 'column' }}>
                {!!selectedView && (
                    <Grid container item style={{ flexGrow: 1 }}>
                        <ModelViewer
                            aligner_plan_type={treatmentPlan.source}
                            orderMaterialsHaveLayers={false}
                            full_screen={fullScreen}
                            model_payload={selectedView}
                            enable_textures={false}
                            enable_jaws={true}
                            disable_restoratives={true}
                            enable_rotation_tools={true}
                            use_pretty_buttons={true}
                            hide_toolbar={hideToolbar}
                            style={{ height: 'auto' }}
                        />
                    </Grid>
                )}
                {!treatingPlanViewsLoading && (
                    <Grid
                        item
                        container
                        style={{
                            height: fullScreen ? 'fit-content' : undefined,
                            paddingTop: fullScreen ? 16 : undefined,
                            paddingRight: fullScreen ? 16 : undefined,
                            justifyContent: fullScreen ? 'center' : 'flex-end',
                            flexWrap: 'nowrap',
                        }}
                    >
                        <Button
                            variant={'primary'}
                            onClick={() => {
                                if (isAutoPlaying) {
                                    setIsAutoPlaying(false);
                                } else {
                                    setSelectedStepIndex(0);
                                    setIsAutoPlaying(true);
                                }
                            }}
                            style={{ backgroundColor: FlossPalette.STAR_GRASS, marginRight: 8 }}
                        >
                            {isAutoPlaying ? (
                                <PauseIcon style={{ color: buttonColor }} />
                            ) : (
                                <PlayArrowIcon style={{ color: buttonColor }} />
                            )}
                        </Button>
                        <ButtonGroup
                            style={{
                                flexGrow: 10,
                                flexShrink: 10,
                                overflowX: 'hidden',
                                maxWidth: 64 * (treatmentPlanViews?.length ?? 0),
                            }}
                        >
                            <Button
                                variant={`secondary`}
                                onClick={() => setSelectedStepIndex(idx => idx - 1)}
                                disabled={selectedStepIndex <= 0}
                                style={{ backgroundColor: FlossPalette.WHITE }}
                            >
                                <ChevronLeft />
                            </Button>
                            {isMobile ? (
                                <Text
                                    variant={`body2`}
                                    style={{
                                        margin: `0 8px`,
                                        backgroundColor: FlossPalette.WHITE,
                                        borderRadius: 2,
                                        padding: 8,
                                        minWidth: 140,
                                        textAlign: `center`,
                                    }}
                                >
                                    Step <strong>{selectedStepIndex + 1}</strong> of {treatmentPlanViews?.length}
                                </Text>
                            ) : (
                                <Grid
                                    container
                                    style={{
                                        flexGrow: 10,
                                        overflowX: 'hidden',
                                        flexWrap: 'nowrap',
                                    }}
                                >
                                    {(treatmentPlanViews ?? []).map((_scan, idx) => {
                                        const step = treatmentPlan.steps[idx];
                                        return (
                                            <Button
                                                key={`step_${idx}`}
                                                variant={idx === selectedStepIndex ? 'primary' : 'secondary'}
                                                onClick={() => setSelectedStepIndex(idx)}
                                                style={{
                                                    backgroundColor:
                                                        idx !== selectedStepIndex ? buttonColor : undefined,
                                                    flexGrow: 1,
                                                    paddingLeft: 0,
                                                    paddingRight: 0,
                                                    minWidth: 'calc(2ch + 5px)',
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    style={{
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        position: 'relative',
                                                    }}
                                                    ref={ref => (buttonRefArray.current[idx] = ref)}
                                                >
                                                    {idx + 1}
                                                    {!!step && step.interproximal_reductions.length > 0 && (
                                                        <AlignerTreatmentPlanStepDot
                                                            color={'ATTENTION'}
                                                            style={{ position: 'absolute', bottom: -2 }}
                                                        />
                                                    )}
                                                </Grid>
                                            </Button>
                                        );
                                    })}
                                </Grid>
                            )}
                            <Button
                                variant={`secondary`}
                                onClick={() => setSelectedStepIndex(idx => idx + 1)}
                                disabled={selectedStepIndex >= (treatmentPlanViews?.length ?? 0) - 1}
                                style={{ backgroundColor: FlossPalette.WHITE }}
                            >
                                <ChevronRight />
                            </Button>
                        </ButtonGroup>
                    </Grid>
                )}
            </Grid>
        </LoadBlocker>
    );
};

export const AlignerTreatmentPlanViewerFullscreen: React.FC<{
    open: boolean;
    setOpen: (open: boolean) => void;
    treatmentPlanFragment: FragmentType<typeof VeneerAlignerTreatmentPlanViewer_Fragment> | null;
    selectedStepIndex: number;
    setSelectedStepIndex: (value: ((prevState: number) => number) | number) => void;
}> = props => {
    const { open, setOpen, treatmentPlanFragment, selectedStepIndex, setSelectedStepIndex } = props;
    if (!treatmentPlanFragment) {
        return null;
    }
    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'xl'} fullWidth>
            <DialogTitle>
                <IconButton onClick={() => setOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ height: '90vh' }}>
                <AlignerTreatmentPlanViewer
                    treatmentPlanFragment={treatmentPlanFragment}
                    selectedStepIndex={selectedStepIndex}
                    setSelectedStepIndex={setSelectedStepIndex}
                    fullScreen={true}
                    style={{ height: '100%', width: '100%' }}
                />
            </DialogContent>
        </Dialog>
    );
};
