import { useFeatureFlag } from '../Providers/LaunchDarkly';
import { FlossToothHappy } from '../Waxups';
import { stylesFactory, Text, Grid, Button } from '@orthly/ui-primitives';
import React from 'react';
import { isChrome, isChromium } from 'react-device-detect';

const useStyles = stylesFactory(() => ({
    root: {
        margin: `auto`,
        width: 656,
        height: '100vh',
        maxHeight: '100vh',
    },
    toothyWrapper: {
        margin: `0px auto`,
    },
    toothyImage: {
        height: 256,
        width: 256,
    },
    headerText: {
        textAlign: 'center',
    },
    bodyText: {
        fontSize: `18px !important`,
        textAlign: 'center',
    },
    buttonWrapper: {
        margin: `24px auto`,
    },
}));

export const ChromeGuard: React.VFC<{ children: React.ReactNode }> = ({ children }) => {
    const classes = useStyles();
    const { value: isEnabled } = useFeatureFlag('chromeRequiredToUsePortal');

    if (!isEnabled) {
        return <>{children}</>;
    }

    if (isChrome || isChromium) {
        return <>{children}</>;
    }

    return (
        <Grid container direction={'column'} className={classes.root} spacing={3}>
            <Grid item xs />
            <Grid item className={classes.toothyWrapper}>
                <img src={FlossToothHappy} className={classes.toothyImage} />
            </Grid>
            <Grid item>
                <Text variant={'h3'} color={'BLACK'} medium className={classes.headerText}>
                    The portal now requires the Chrome browser
                </Text>
            </Grid>
            <Grid item>
                <Text variant={'body2'} color={'BLACK'} className={classes.bodyText}>
                    We develop all of our experiences for Chrome, so you will have the most consistent experience by
                    using it.
                </Text>
            </Grid>
            <Grid item className={classes.buttonWrapper}>
                <Button href={'https://www.google.com/chrome/'} variant={'primary'} startIcon={'DownloadIcon'}>
                    Download Chrome
                </Button>
            </Grid>
            <Grid item xs />
        </Grid>
    );
};
