import { PricingPartnerImportUpload } from './PricingPartnerImport/PricingPartnerImportUpload.graphql';
import {
    ActionsContainer,
    buildPartnerPriceMap,
    Container,
    generateRowsFromBulkLoad,
    useColumns,
} from './PricingPartnerImportHelpers.graphql';
import type { PartnerImportCsvRow, PricesRow } from './PricingRoot.types';
import { useUpdatedPricingQuery } from './hooks/useUpdatedPricingQuery.graphql';
import { useLazyQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { MUITable, downloadAsCsv } from '@orthly/mui-table';
import { Button, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const GetPricesForPractices_Query = graphql(`
    query GetPricesForPractices($partnerIds: [String!]!) {
        bulkLoadPracticePrices(partnerIds: $partnerIds) {
            partnerId
            prices {
                id
                name
                partner_prices {
                    partner_id
                    price_cents
                    from_preset
                }
            }
        }
    }
`);

// this query is very slow! use at your own risk
const BulkLoadAllPracticePrices_Query = graphql(`
    query BulkLoadAllPracticePrices {
        listPrices {
            id
            name
            partner_prices {
                partner_id
                price_cents
                from_preset
            }
        }
    }
`);

export const PricingPartnerImport: React.VFC = () => {
    const { value: disableContractEdits = false } = useFeatureFlag('disableContractEdits');
    const {
        prices,
        pricesLoading,
        refetchPrices,
        practiceNames,
        refetchPracticeNames,
        practiceNamesLoading,
        getPracticeNameById,
    } = useUpdatedPricingQuery();
    const [fetchedPartnerPrices, setFetchedPartnerPrices] = React.useState<Record<string, Partial<PricesRow>>>({});
    const [fetchPartnerId, setFetchPartnerId] = React.useState<string | undefined>();
    const [
        fetchPricesForPartners,
        { data: { bulkLoadPracticePrices: partnerPricesRaw = [] } = {}, loading: fetchPricesForPartnersLoading },
    ] = useLazyQuery(GetPricesForPractices_Query);
    const [
        bulkLoadPracticePrices,
        { data: { listPrices: bulkFetchedPrices = [] } = {}, loading: bulkLoadPricesLoading = false },
    ] = useLazyQuery(BulkLoadAllPracticePrices_Query);

    const emptyPriceMap = React.useMemo(
        () => prices.reduce((acc, price) => ({ ...acc, [price.name]: '-' }), {}),
        [prices],
    );

    React.useEffect(() => {
        if (partnerPricesRaw.length === 0) {
            return;
        }

        const partnerPriceMap = buildPartnerPriceMap(partnerPricesRaw, emptyPriceMap);

        // store array of retrieved partner prices in a map keyed by partnerId
        // make sure to preserve prev state since that will contain previously loaded partners
        setFetchedPartnerPrices(prevState => {
            return { ...prevState, ...partnerPriceMap };
        });
    }, [partnerPricesRaw, setFetchedPartnerPrices, emptyPriceMap]);

    // our initial data poll is just the list of active price names, and the list of partner names
    // rows will be empty of price data until the practice is individually loaded - either
    // reconcile loaded data from `fetchedPartnerPrices` or fall back to the empty price map
    const practiceData = React.useMemo(() => {
        if (bulkFetchedPrices.length) {
            return generateRowsFromBulkLoad(bulkFetchedPrices, practiceNames);
        }

        return practiceNames.map<PricesRow>(p => {
            const partnerPriceConfig: Partial<PricesRow> | null = fetchedPartnerPrices[p.id] ?? null;
            return {
                ...p,
                isLoaded: !!partnerPriceConfig,
                ...(partnerPriceConfig ?? emptyPriceMap),
            };
        });
    }, [practiceNames, fetchedPartnerPrices, emptyPriceMap, bulkFetchedPrices]);

    const fetchPrice = React.useCallback(
        (partnerId: string) => {
            setFetchPartnerId(partnerId);
            fetchPricesForPartners({ variables: { partnerIds: [partnerId] } });
        },
        [fetchPricesForPartners],
    );

    const { columns, setSortColumnName } = useColumns(
        fetchPrice,
        fetchPartnerId,
        fetchPricesForPartnersLoading,
        fetchPricesForPartnersLoading || practiceNamesLoading || pricesLoading,
    );

    // refetches all of the data. if the user has specified practices to load, refetch those practice prices
    // else, refetch price and practice names
    const handleGlobalRefetch = React.useCallback(async () => {
        const fetchedPracticeIds = Object.keys(fetchedPartnerPrices);
        if (fetchedPracticeIds.length) {
            void fetchPricesForPartners({ variables: { partnerIds: fetchedPracticeIds } });
            return;
        }

        // if we haven't loaded custom data, refetch the prices and practices
        if (!fetchedPracticeIds && !bulkFetchedPrices.length) {
            void refetchPracticeNames();
            void refetchPrices();
        }
    }, [fetchedPartnerPrices, bulkFetchedPrices, fetchPricesForPartners, refetchPracticeNames, refetchPrices]);

    // "fake" refetch - takes the upserts and applies them to the current state of fetched prices
    const handleRefetchAfterImport = (rows: PartnerImportCsvRow[]) => {
        const updates = rows.reduce<Record<string, Partial<PricesRow>>>((acc, row) => {
            const { partner_id, _isLoaded, ...priceUpdates } = row;
            const currentState = fetchedPartnerPrices[partner_id] ?? {};
            return { ...acc, [partner_id]: { ...currentState, ...priceUpdates } };
        }, {});

        setFetchedPartnerPrices(prevState => {
            return { ...prevState, ...updates };
        });
    };

    return (
        <Container>
            <ActionsContainer>
                <Text variant={'h6'}>Prices By Partner</Text>
                <Button
                    variant={'contained'}
                    onClick={() => {
                        const isBulkLoaded = !!bulkFetchedPrices.length;
                        let loadedRows;
                        if (isBulkLoaded) {
                            loadedRows = practiceData.map(row => {
                                const { id, isLoaded, ...prices } = row;
                                return { partner_id: id, ...prices };
                            });
                        } else {
                            loadedRows = Object.entries(fetchedPartnerPrices).reduce<Partial<PricesRow>[]>(
                                (acc, [partnerId, partnerPrices]) => {
                                    const { id, isLoaded, ...prices } = partnerPrices;
                                    const partnerName = getPracticeNameById(partnerId);
                                    const partnerPricesRow = { partner_id: partnerId, name: partnerName, ...prices };
                                    return acc ? [...acc, partnerPricesRow] : [partnerPricesRow];
                                },
                                [],
                            );
                        }
                        // if the user has loaded prices for any (or all) practices, use that data as a starting point
                        // else, default to a single example row with no partner id and zeroed out prices
                        const rows = loadedRows.length
                            ? loadedRows
                            : [{ partner_id: '', ...prices.reduce((acc, p) => ({ ...acc, [p.name]: '$0.00' }), {}) }];
                        downloadAsCsv(rows, `Prices_Export`);
                    }}
                    startIcon={'DownloadIcon'}
                >
                    Download Template
                </Button>
            </ActionsContainer>
            {!disableContractEdits && (
                <PricingPartnerImportUpload
                    refetchAfterImport={handleRefetchAfterImport}
                    fetchedPrices={fetchedPartnerPrices}
                    setSortColumnName={setSortColumnName}
                />
            )}
            <MUITable<PricesRow>
                title={'Current Prices'}
                columns={columns}
                data={practiceData}
                loading={practiceNamesLoading || pricesLoading || bulkLoadPricesLoading}
                rowOptions={{ rowHover: true }}
                displayOptions={{
                    fixedSearch: true,
                    elevation: 0,
                    filter: false,
                    sort: true,
                    fixedSearchPosition: 'right',
                }}
                actions={{
                    global: [
                        {
                            icon: 'refresh',
                            tooltip: 'Refresh selected practices, or reload price and practice data if none selected.',
                            onClick: handleGlobalRefetch,
                            position: 'toolbar',
                            disabled: practiceNamesLoading || pricesLoading || bulkLoadPricesLoading,
                        },
                        {
                            icon: () => <Button variant={'alert-secondary'}>Bulk Load Practice Prices</Button>,
                            onClick: async () => {
                                if (window.confirm('Loading prices for all practices is a slow operation. Continue?')) {
                                    await bulkLoadPracticePrices();
                                }
                            },
                            position: 'toolbar',
                            disabled: practiceNamesLoading || pricesLoading || bulkLoadPricesLoading,
                        },
                    ],
                }}
            />
        </Container>
    );
};
