import { DesignAnalyticsSection } from '../../components/DesignDetailSidebar/DesignDetailSidebarAnalytics';
import { DesignItemsSection } from '../../components/DesignDetailSidebar/DesignDetailSidebarItems';
import { DesignPreferencesSection } from '../../components/DesignDetailSidebar/DesignDetailSidebarPreferences';
import { DesignSidebarSection } from '../../components/DesignDetailSidebar/DesignDetailSidebarSection';
import { DesignDetailSidebarTags } from '../../components/DesignDetailSidebar/DesignDetailSidebarTags';
import {
    DesignDoctorNotesSection,
    DesignClinicalNotesSection,
} from '../../components/DesignDetailSidebar/DesignNotesSection';
import { useChatMessages } from '../Orders/screens/OrderDetail/components/OrderDetailChat/OrderDetailChat.hooks';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { SimpleCopyToClipboard } from '@orthly/dentin';
import type { FragmentType, VeneerOrderDesignAnalyticsDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import type {
    LabsGqlWorkflowTask_InternalDesignWorkflowTask_Fragment as LabsGqlInternalDesignTaskFragment,
    LabsGqlDesignQaEvaluationResultsOrderFragmentFragment,
} from '@orthly/graphql-operations';
import { IOrganizationType } from '@orthly/retainer-common';
import { useSession } from '@orthly/session-client';
import { Text, Grid } from '@orthly/ui-primitives';
import type { DesignQcSidebarEntry } from '@orthly/veneer';
import { DandyChatWithConversation, PreviousDesignRejectionDetailsFromTask } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const DesignQaChat: React.VFC<{ order: LabsGqlDesignQaEvaluationResultsOrderFragmentFragment }> = ({ order }) => {
    const session = useSession();
    const staffId = session?.id ?? '';
    const {
        data: messages,
        addChatFn: { submitting },
    } = useChatMessages({ order, staffId, orderId: order.id });

    return (
        <DandyChatWithConversation<IOrganizationType>
            chatProps={{
                messages,
                events: [],
                submitting,
                currentStaffId: staffId,
                highlight_since: null,
                lab_id: order.manufacturer_id,
                practice_id: order.partner_id,
                orderId: order.id,
            }}
            buttonProps={{
                convoKeysEnum: IOrganizationType,
            }}
            initialConvo={IOrganizationType.internal}
        />
    );
};

export function useDesignQaSidebar(
    order: LabsGqlDesignQaEvaluationResultsOrderFragmentFragment | undefined,
    designFragment: FragmentType<typeof VeneerOrderDesignAnalyticsDesign_FragmentFragmentDoc> | undefined,
): DesignQcSidebarEntry[] {
    // We find the last Internal Design task, as it will have the last rejection info stored in its configuration.
    const previousDesignTask: LabsGqlInternalDesignTaskFragment | undefined = _.findLast(
        order?.fulfillment_workflow.closed_tasks,
        (task): task is LabsGqlInternalDesignTaskFragment => task.__typename === 'InternalDesignWorkflowTask',
    );

    const elements: (DesignQcSidebarEntry | undefined)[] = order
        ? [
              {
                  name: 'Items',
                  sidebarBody: (
                      <>
                          {/* Order ID */}
                          <DesignSidebarSection>
                              <Grid container direction={'column'}>
                                  <Grid item>
                                      <SimpleCopyToClipboard
                                          onClick={() =>
                                              BrowserAnalyticsClientFactory.Instance?.track('Order Number Copied', {
                                                  $groups: { order: order.id },
                                              })
                                          }
                                          tooltip={'Copy order ID'}
                                          size={'small'}
                                          value={order.order_number}
                                      >{`Order ID: #${order.order_number}`}</SimpleCopyToClipboard>
                                  </Grid>
                                  <Grid item>
                                      <Text variant={'body2'} color={'DARK_GRAY'}>
                                          Patient: {order.patient.first_name} {order.patient.last_name}
                                      </Text>
                                  </Grid>
                              </Grid>
                          </DesignSidebarSection>
                          {/* Items */}
                          <DesignItemsSection order={order} />
                          {/* Preferences */}
                          <DesignPreferencesSection order={order} />
                          {/* Notes */}
                          <DesignDoctorNotesSection order={order} />
                          {/* Clinical Notes */}
                          <DesignClinicalNotesSection order={order} />
                          {/* Tags */}
                          <DesignDetailSidebarTags orderId={order.id} />
                      </>
                  ),
              },
              {
                  name: 'Chat',
                  sidebarBody: <DesignQaChat order={order} />,
              },
              designFragment
                  ? {
                        name: 'Analytics',
                        sidebarBody: <DesignAnalyticsSection orderId={order.id} designFragment={designFragment} />,
                    }
                  : undefined,
              !!previousDesignTask
                  ? {
                        name: 'Previous Reviews',
                        sidebarBody: <PreviousDesignRejectionDetailsFromTask task={previousDesignTask} />,
                    }
                  : undefined,
          ]
        : [];

    return _.compact(elements);
}
