/* eslint-disable max-lines */
import { recomputeCollisionsObject } from '../DesignEditing/RecomputeCollisions';
import { cloneMorphPointsData } from './Deform.utils';
import { EditingHistory } from './EditingHistory';
import { isGeometryEditingOperation, isDeformOperation, isInsertionAxisOperation } from './EditingHistory.utils';
import type { RestorativeModel, ScanGeometries } from './FinishingApp.types';
import type {
    OperationsStateMinusManager,
    IOperationsManager,
    GeometryWithConnectivityMap,
    OnInsertionAxisChangedFn,
    OnGeometryChangedFn,
} from './OperationsManager.types';
import type { ExpandedUpdatedSpline, MeshConnectivityGraph } from '@orthly/forceps';
import {
    AttributeName,
    buildMeshAdjacencyAndVerticesFaces,
    getInsertionAxisFromOrientation,
    getInsertionOrientationFromAxis,
    isBufferAttribute,
    SplineUpdater,
    toSerializedSubmission,
} from '@orthly/forceps';
import { ToothUtils, type ToothNumber } from '@orthly/items';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import type { MorphPointsInternalData } from '@orthly/shared-types';
import _ from 'lodash';
import * as THREE from 'three';

interface ModelData {
    geometry: THREE.BufferGeometry;
    insertionAxis: THREE.Vector3;
    insertionOrientation: THREE.Quaternion;
    morphPoints: MorphPointsInternalData;
    splineUpdater: SplineUpdater;
    connectivityGraph: MeshConnectivityGraph;
    originalGeometry: THREE.BufferGeometry;
    originalMorphPoints: MorphPointsInternalData;
    originalInsertionOrientation: THREE.Quaternion;
    originalCurtainGeometry: THREE.BufferGeometry | undefined;
    history: EditingHistory;
    occlusalGeometries: Array<THREE.BufferGeometry>;
    proximalGeometries: Array<THREE.BufferGeometry>;
    prepScan: THREE.BufferGeometry;
    curtainGeometry: THREE.BufferGeometry | undefined;
    collisionsGeometry: THREE.BufferGeometry | undefined;
}

/**
 * Manages the operations that can be performed on the models in the FinishingApp
 */
export class OperationsManager implements IOperationsManager {
    private modelsData: Map<ToothNumber, ModelData> = new Map();
    private editToothNumber_: ToothNumber;
    private onInsertionAxisChangedCallbacks: OnInsertionAxisChangedFn[] = [];
    private onGeometryChangedCallbacks: OnGeometryChangedFn[] = [];
    private editModelData: ModelData;

    /**
     * Constructor
     * @param restorativeModels Models of the restorative items in the design
     * @param setOperationsState Setter to report the relevant state to the parent
     */
    constructor(
        restorativeModels: ArrayMin1<RestorativeModel>,
        private setOperationsState: (state: OperationsStateMinusManager) => void,
        scanGeometries: ScanGeometries,
    ) {
        const [firstModel, ...restModels] = restorativeModels;

        this.editToothNumber_ = firstModel.toothNumber;
        this.editModelData = createModelData(firstModel, restorativeModels, scanGeometries);

        this.modelsData.set(this.editToothNumber_, this.editModelData);

        for (const model of restModels) {
            this.modelsData.set(model.toothNumber, createModelData(model, restorativeModels, scanGeometries));
        }

        this.updateOperationsState();
    }

    /**
     * The geometry of the model currently being edited
     */
    get editGeometry(): THREE.BufferGeometry {
        return this.editModelData.geometry;
    }

    /**
     * The insertion axis of the model currently being edited
     */
    get editInsertionAxis(): THREE.Vector3 {
        return this.editModelData.insertionAxis;
    }

    /**
     * The insertion orientation of the model currently being edited
     */
    get editInsertionOrientation(): THREE.Quaternion {
        return this.editModelData.insertionOrientation;
    }

    /**
     * The morph points of the model currently being edited
     */
    get editMorphPoints(): MorphPointsInternalData {
        return this.editModelData.morphPoints;
    }

    /**
     * The non-morph-point splines of the model currently being edited
     */
    get editSplines(): ExpandedUpdatedSpline[] {
        const dcmSplines = this.editModelData.splineUpdater.getUpdatedSplines();
        return dcmSplines.map(el => ({
            name: el.name,
            pointsHash: el.points_hash,
            points: el.points.map(p => new THREE.Vector3(p.x, p.y, p.z)),
        }));
    }

    /**
     * The connectivity graph of the model currently being edited
     */
    get connectivityGraph(): MeshConnectivityGraph {
        return this.editModelData.connectivityGraph;
    }

    /**
     * The neighboring geometries of the model currently being edited
     */
    get proximalModels(): Array<THREE.BufferGeometry> {
        return this.editModelData.proximalGeometries;
    }

    /**
     * The neighboring scan geometry of the model currently being edited
     */
    get prepScan(): THREE.BufferGeometry {
        return this.editModelData.prepScan;
    }

    /**
     * The occlusal geometries of the model currently being edited
     */
    get occlusalModels(): Array<THREE.BufferGeometry> {
        return this.editModelData.occlusalGeometries;
    }

    /**
     * The collisions geometry of the model currently being edited
     */
    get collisionsGeometry(): THREE.BufferGeometry | undefined {
        return this.editModelData.collisionsGeometry;
    }

    /**
     * The curtains geometry of the model currently being edited
     */
    get curtainGeometry(): THREE.BufferGeometry | undefined {
        return this.editModelData.curtainGeometry;
    }

    /**
     * The tooth number of the model currently being edited
     */
    get editToothNumber(): ToothNumber {
        return this.editToothNumber_;
    }

    /**
     * Selects a restorative model for editing
     * @param toothNumber The tooth number of the model to edit
     * @throws If there is no model data for the given tooth number
     */
    selectForEditing(toothNumber: ToothNumber): void {
        const nextModelData = this.modelsData.get(toothNumber);
        if (!nextModelData) {
            throw new Error(`No model data for tooth number: ${toothNumber}`);
        }

        this.editToothNumber_ = toothNumber;
        this.editModelData = nextModelData;

        this.updateOperationsState();
    }

    /**
     * Appends a sculpt operation to the editing history
     * @param geometry The geometry resulting from the sculpt operation
     */
    applySculpt(geometry: THREE.BufferGeometry): void {
        this.editModelData.history.push({ type: 'sculpt', geometry: geometry.clone() });
        this.updateOperationsState();
    }

    /**
     * Appends a deform operation to the editing history
     * @param geometry The geometry resulting from the deform operation
     */
    applyDeform(geometry: THREE.BufferGeometry, updatedMorphPoints: MorphPointsInternalData): void {
        this.editModelData.history.push({
            type: 'deform',
            geometry: geometry.clone(),
            morphPoints: cloneMorphPointsData(updatedMorphPoints),
        });
        this.updateOperationsState();
    }

    /**
     * Appends an uncommitted insertion axis operation to the editing history
     * @param orientation The new insertion direction
     */
    applyInsertionAxis(orientation: THREE.Quaternion): void {
        const curtainsDistances = this.editGeometry.getAttribute(AttributeName.CurtainsDistance);
        if (!isBufferAttribute(curtainsDistances)) {
            throw new Error('Failed to get curtains distance buffer attribute.');
        }

        this.editModelData.history.push({
            type: 'insertionAxisTemp',
            insertionOrientation: orientation.clone(),
            curtainsDistances: curtainsDistances.clone(),
            curtainsGeometry: this.curtainGeometry?.clone(),
        });
        this.setInsertionDirection(orientation);
        this.updateOperationsState();
    }

    /**
     * Commits the tailing insertion axis operations in the editing history
     */
    commitInsertionAxis(): void {
        const geometry = this.editModelData.geometry.clone();

        this.editModelData.history.commitInsertionAxis(geometry);

        this.onGeometryChangedCallbacks.forEach(cb => cb());

        this.updateOperationsState();
    }

    /**
     * Cancels the tailing uncommitted insertion axis operations
     */
    cancelInsertionAxis(): void {
        this.editModelData.history.cancelInsertionAxis();

        const insertionOrientation =
            this.editModelData.history.currentInsertionOrientation ?? this.editModelData.originalInsertionOrientation;
        this.setInsertionDirection(insertionOrientation);

        const curtainGeometry =
            this.editModelData.history.currentCurtainsGeometry ?? this.editModelData.originalCurtainGeometry;
        this.updateCurtainGeometry(curtainGeometry);

        const curtainDistances =
            this.editModelData.history.currentCurtainsDistance ??
            (this.editModelData.originalGeometry.getAttribute(AttributeName.CurtainsDistance) as THREE.BufferAttribute);
        this.updateCurtainsDistancesAndCollisions(curtainDistances);

        this.updateOperationsState();
    }

    /**
     * Registers a callback to be called when the insertion axis changes
     */
    registerInsertionAxisChangedCallback(callback: OnInsertionAxisChangedFn): void {
        this.onInsertionAxisChangedCallbacks.push(callback);
    }

    /**
     * Registers a callback to be called when the geometry changes
     */
    registerGeometryChangedCallback(callback: OnGeometryChangedFn): void {
        this.onGeometryChangedCallbacks.push(callback);
    }

    /**
     * Undoes the last operation
     * @throws If there are no operations to undo
     */
    undoOperation(): void {
        const undoneOp = this.editModelData.history.undo();

        if (isGeometryEditingOperation(undoneOp)) {
            const geometry = this.editModelData.history.currentGeometry ?? this.editModelData.originalGeometry;
            this.updateEditGeometryAndCollisions(geometry);
        }

        if (isDeformOperation(undoneOp)) {
            const morphPoints = this.editModelData.history.currentMorphPoints ?? this.editModelData.originalMorphPoints;
            this.editModelData.morphPoints = cloneMorphPointsData(morphPoints);
        } else if (undoneOp.type === 'sculpt') {
            // Making a new copy of the morph points data causes the morph point handles to re-render, which may be
            // necessary to snap the morph points back to the model surface.
            this.editModelData.morphPoints = cloneMorphPointsData(this.editModelData.morphPoints);
        }

        if (isInsertionAxisOperation(undoneOp)) {
            const insertionOrientation =
                this.editModelData.history.currentInsertionOrientation ??
                this.editModelData.originalInsertionOrientation;
            this.setInsertionDirection(insertionOrientation);

            const curtainGeometry =
                this.editModelData.history.currentCurtainsGeometry ?? this.editModelData.originalCurtainGeometry;
            this.updateCurtainGeometry(curtainGeometry);

            // If the undone operation is a geometry editing operation (i.e. a committed insertion axis operation), then
            // we'll already have gotten the updated curtains distances from the cached geometry.
            if (!isGeometryEditingOperation(undoneOp)) {
                const curtainDistances =
                    this.editModelData.history.currentCurtainsDistance ??
                    (this.editModelData.originalGeometry.getAttribute(
                        AttributeName.CurtainsDistance,
                    ) as THREE.BufferAttribute);
                this.updateCurtainsDistancesAndCollisions(curtainDistances);
            }
        }

        this.updateOperationsState();
    }

    /**
     * Redoes the last undone operation
     * @throws If there are no operations to redo
     */
    redoOperation(): void {
        const redoneOp = this.editModelData.history.redo();

        if (isGeometryEditingOperation(redoneOp)) {
            this.updateEditGeometryAndCollisions(redoneOp.geometry);
        }

        if (redoneOp.type === 'deform') {
            this.editModelData.morphPoints = cloneMorphPointsData(redoneOp.morphPoints);
        } else if (redoneOp.type === 'sculpt') {
            // Making a new copy of the morph points data causes the morph point handles to re-render, which may be
            // necessary to snap the morph points back to the model surface.
            this.editModelData.morphPoints = cloneMorphPointsData(this.editModelData.morphPoints);
        }

        if (isInsertionAxisOperation(redoneOp)) {
            this.setInsertionDirection(redoneOp.insertionOrientation);
            this.updateCurtainGeometry(redoneOp.curtainsGeometry);

            // If the redone operation is a geometry editing operation (i.e. a committed insertion axis operation), then
            // we'll already have gotten the updated curtains distances from the cached geometry.
            if (!isGeometryEditingOperation(redoneOp)) {
                this.updateCurtainsDistancesAndCollisions(redoneOp.curtainsDistances);
            }
        }

        this.updateOperationsState();
    }

    getGeometriesWithConnectivity(): GeometryWithConnectivityMap {
        return new Map(
            Array.from(this.modelsData.entries()).map(([toothNumber, modelData]) => {
                return [toothNumber, _.pick(modelData, ['geometry', 'connectivityGraph'])];
            }),
        );
    }

    resetEditGeometry(): void {
        const committedGeometry = this.editModelData.history.currentGeometry ?? this.editModelData.originalGeometry;
        this.updateEditGeometryAndCollisions(committedGeometry);
    }

    /**
     * Returns the serialized Dandy Finishing output
     * @param modelElementId Model element ID to put in the submission
     */
    getSubmission(modelElementId: string): string {
        const vertexPositions = _.chunk(this.editGeometry.getAttribute(AttributeName.Position).array, 3).map(
            el => new THREE.Vector3(...el),
        );

        const editMorphPoints = this.editMorphPoints;
        if (!editMorphPoints) {
            throw new Error('Morph points are missing.');
        }

        const morphPoints = editMorphPoints.map(el => ({
            ...el,
            points: el.points.map(p => new THREE.Vector3(p.x, p.y, p.z)),
        }));

        return JSON.stringify(
            toSerializedSubmission([
                {
                    modelElementId,
                    vertexPositions,
                    morphPoints,
                    updatedSplines: this.editSplines,
                    insertionAxis: this.editInsertionAxis,
                },
            ]),
        );
    }

    private updateOperationsState(): void {
        this.setOperationsState({
            hasUndo: this.editModelData.history.hasUndo,
            hasRedo: this.editModelData.history.hasRedo,
            editingToothNumber: this.editToothNumber_,
            itemToothNumbers: Array.from(this.modelsData.keys()),
        });
    }

    private updateEditGeometryAndCollisions(geometry: THREE.BufferGeometry): void {
        this.editGeometry.copy(geometry);
        this.editGeometry.boundsTree?.refit();

        const collisionGeometry = recomputeCollisionsObject(this.editGeometry).model.geometry;
        this.collisionsGeometry?.copy(collisionGeometry);

        this.onGeometryChangedCallbacks.forEach(cb => cb());
    }

    private setInsertionDirection(orientation: THREE.Quaternion): void {
        this.editModelData.insertionOrientation.copy(orientation);
        this.editModelData.insertionAxis.copy(getInsertionAxisFromOrientation(orientation));

        this.onInsertionAxisChangedCallbacks.forEach(cb => cb(orientation));
    }

    private updateCurtainGeometry(geometry: THREE.BufferGeometry | undefined): void {
        if (!geometry) {
            return;
        }

        this.curtainGeometry?.copy(geometry);
        // Delete the BVH, as it is now potentially out of sync with the curtains geometry index and vertex positions.
        // The BVH will get lazily recalculated elsewhere.
        delete this.curtainGeometry?.boundsTree;
    }

    private updateCurtainsDistancesAndCollisions(distances: THREE.BufferAttribute): void {
        const curtainsDistances = this.editGeometry.getAttribute(AttributeName.CurtainsDistance);
        if (!isBufferAttribute(curtainsDistances)) {
            throw new Error('Failed to get curtains distance buffer attribute.');
        }

        curtainsDistances.copy(distances);
        curtainsDistances.needsUpdate = true;

        // Curtains collisions are derived from the curtains distances, so this must come last.
        this.collisionsGeometry?.copy(recomputeCollisionsObject(this.editGeometry).model.geometry);
    }
}

/**
 * Creates the model data for a given restorative item
 */
function createModelData(
    model: RestorativeModel,
    allRestorativeModels: RestorativeModel[],
    scanGeometries: ScanGeometries,
): ModelData {
    const { geometry, morphPoints, toothNumber, insertionAxis, splines, collisionsGeometry, curtainGeometry } = model;

    if (!geometry.boundsTree) {
        // We require that the BVH be generated before calling `buildMeshAdjacencyAndVerticesFaces` because subsequent
        // generation of the BVH, which may reorder the geometry faces, would invalidate the adjacency graph.
        throw new Error('Geometry BVH is not initialized.');
    }

    const connectivityGraph = buildMeshAdjacencyAndVerticesFaces(geometry);

    const originalGeometry = geometry.clone();
    const history = new EditingHistory();
    const originalMorphPoints = cloneMorphPointsData(morphPoints);

    const adjacentRestorationGeometries = allRestorativeModels
        .filter(el => {
            return ToothUtils.areAdjacent(toothNumber, el.toothNumber);
        })
        .map(el => el.geometry);

    const isUpper = ToothUtils.toothIsUpper(toothNumber);
    const upperJawGeometry = scanGeometries.upperJaw;
    const lowerJawGeometry = scanGeometries.lowerJaw;
    const proximalGeometries = [isUpper ? upperJawGeometry : lowerJawGeometry, ...adjacentRestorationGeometries];
    const prepScan = isUpper ? upperJawGeometry : lowerJawGeometry;
    const occlusalGeometries = [isUpper ? lowerJawGeometry : upperJawGeometry];

    const splineUpdater = new SplineUpdater(geometry, splines);

    const insertionOrientation = getInsertionOrientationFromAxis(insertionAxis);

    return {
        geometry,
        insertionAxis: insertionAxis.clone(),
        insertionOrientation,
        connectivityGraph,
        originalGeometry,
        history,
        morphPoints,
        originalMorphPoints,
        originalInsertionOrientation: insertionOrientation.clone(),
        originalCurtainGeometry: curtainGeometry?.clone(),
        proximalGeometries,
        prepScan,
        occlusalGeometries,
        curtainGeometry,
        collisionsGeometry,
        splineUpdater,
    };
}
