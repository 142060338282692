import { orderSplitMutationsDisabled } from '../state/Splits.state';
import type { SplitInfo } from '../state/Splits.types';
import type { RootActionDialogProps } from '@orthly/ui';
import { QuickForm, RootActionDialog } from '@orthly/ui';
import { Button, Icon } from '@orthly/ui-primitives';
import React from 'react';

interface MoveItemParams {
    destSplitId: string;
}

interface MoveItemActionProps {
    sourceSplitId: string;
    splits: ReadonlyArray<SplitInfo>;
    onSubmit: (args: MoveItemParams) => void;
    dialogProps?: Partial<RootActionDialogProps>;
    disabled?: boolean;
}

export const MoveItemAction: React.VFC<MoveItemActionProps> = props => {
    const { sourceSplitId, splits, onSubmit, dialogProps, disabled } = props;
    const [isOpen, setIsOpen] = React.useState(false);
    const availableSplits = React.useMemo(() => {
        return splits.filter(s => s.id !== sourceSplitId && !orderSplitMutationsDisabled(s));
    }, [splits, sourceSplitId]);
    const onClickMove = React.useCallback(() => {
        const firstSplit = availableSplits[0];
        if (availableSplits.length === 1 && firstSplit) {
            return onSubmit({ destSplitId: firstSplit.id });
        }
        return setIsOpen(true);
    }, [availableSplits, onSubmit]);
    if (availableSplits.length === 0 || disabled) {
        return null;
    }
    return (
        <RootActionDialog
            loading={false}
            title={'Move Item'}
            open={isOpen}
            setOpen={setIsOpen}
            content={
                <QuickForm<MoveItemParams>
                    fields={{
                        destSplitId: {
                            type: 'select',
                            label: 'Which split should this item be moved to?',
                            options: availableSplits.map(s => ({
                                value: s.id,
                                label: `${s.manufacturerDisplay} (${s.id})`,
                            })),
                        },
                    }}
                    initialValues={{}}
                    onSubmit={data => {
                        onSubmit(data);
                        setIsOpen(false);
                    }}
                    submitButtonProps={{ children: 'Move Item' }}
                />
            }
            buttonText={''}
            CustomButton={() => (
                <Button onClick={onClickMove} variant={'ghost'}>
                    <Icon icon={'SwapHorizontalIcon'} />
                </Button>
            )}
            {...dialogProps}
        />
    );
};
