import { ChangeManufacturer } from '../actions/ChangeManufacturer';
import { EditLabSlipAlerts } from '../actions/EditLabSlipAlerts';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useHasCapability } from '@orthly/session-client';
import { Grid } from '@orthly/ui-primitives';
import { OrderDetailFactRow } from '@orthly/veneer';

interface OrderDetailSummaryManufacturerProps {
    labOrder: LabsGqlLabOrderFragment;
    refetchLabOrder: () => Promise<void>;
    manufacturerName: string;
}

export const OrderDetailSummaryManufacturer: React.VFC<OrderDetailSummaryManufacturerProps> = ({
    labOrder,
    refetchLabOrder,
    manufacturerName,
}) => {
    const canChangeManufacturer = useHasCapability('order', 'order.configuration.manufacturer.edit');

    return (
        <OrderDetailFactRow
            title={'Lab'}
            value={manufacturerName}
            action={
                <Grid container style={{ width: 'auto', flexWrap: 'nowrap' }}>
                    <EditLabSlipAlerts order={labOrder} refetchOrder={refetchLabOrder} />
                    {!!canChangeManufacturer && <ChangeManufacturer order={labOrder} refetchOrder={refetchLabOrder} />}
                </Grid>
            }
        />
    );
};
