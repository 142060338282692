import { getFilteredExtraOptions } from '../utils/changedItemsUtils';
import type { OrderEditPage } from './types';
import { ItemSelect } from '@orthly/dentin';
import type { ICartItemV2DTOWithId } from '@orthly/items';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import { Button, styled, useScreenIsSm } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const ContentContainer = styled('div')<{ isSm: boolean }>(({ isSm }) => ({
    width: isSm ? '100%' : 'fit-content',
}));
const ButtonContainer = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
});

interface OrderEditItemSelectPageContentProps {
    itemGroups: ArrayMin1<ICartItemV2DTOWithId>[];
    selectedItemGroups: ArrayMin1<ICartItemV2DTOWithId>[];
    setGroupSelected: (group: ICartItemV2DTOWithId[], selected: boolean) => void;
    nonEditableItems: ICartItemV2DTOWithId[];
    addedExtraIds: string[];
    title?: string;
    goPageForward: () => void;
    setShowExtrasPage: (showExtrasPage: boolean) => void;
}

export function useGetItemSelectPage(props: OrderEditItemSelectPageContentProps): OrderEditPage {
    const {
        itemGroups,
        selectedItemGroups,
        setGroupSelected,
        nonEditableItems,
        addedExtraIds,
        title,
        goPageForward,
        setShowExtrasPage,
    } = props;
    const isSm = useScreenIsSm();

    // We don't show any newly added C/R extras on the item select page
    // We only show the original items from the order
    const orderItemGroups = itemGroups.filter(item => {
        const firstItem = Array.isArray(item) ? item[0] : item;
        return !addedExtraIds.includes(firstItem.id);
    });
    // Extras are based on the original items from the order & exclude models (since they're not editable)
    const availableExtras = getFilteredExtraOptions(_.flatten(orderItemGroups));

    return {
        supertitle: `What would you like to change?`,
        title: title ?? `Select products to edit`,
        Content: (
            <ContentContainer isSm={isSm}>
                <ItemSelect
                    items={orderItemGroups}
                    selectedItems={selectedItemGroups}
                    setItemSelected={setGroupSelected}
                    nonEditableItems={nonEditableItems}
                />
                {availableExtras.length > 0 && (
                    <ButtonContainer>
                        <Button
                            onClick={() => {
                                goPageForward();
                                setShowExtrasPage(true);
                            }}
                            variant={'ghost'}
                            startIcon={'PlusIcon'}
                        >
                            Add Extra
                        </Button>
                    </ButtonContainer>
                )}
            </ContentContainer>
        ),
        nextIsSkip: false,
        type: 'item_select',
    };
}
