import { useOperationsState } from '../AppState.hooks';
import { ToothUtils } from '@orthly/items';
import type { SimpleSelectOption } from '@orthly/ui';
import { SimpleSelect } from '@orthly/ui';
import React from 'react';

export const EditItemSelect: React.VFC = () => {
    const { editingToothNumber, itemToothNumbers, manager } = useOperationsState();

    const options = React.useMemo<SimpleSelectOption[]>(() => {
        return itemToothNumbers.map(unn => ({
            value: unn.toString(),
        }));
    }, [itemToothNumbers]);

    const onChange = React.useCallback(
        (newValue: string | undefined) => {
            const toothNumber = parseInt(newValue ?? 'undefined');
            if (!ToothUtils.isToothNumber(toothNumber)) {
                throw new Error(`Invalid tooth number: ${newValue}`);
            }
            manager.selectForEditing(toothNumber);
        },
        [manager],
    );

    return (
        <SimpleSelect
            label={'Select tooth for editing'}
            options={options}
            value={editingToothNumber.toString()}
            onChange={onChange}
        />
    );
};
