import { useOpsInboxAction } from '../../screens/Inbox/state/Inbox.actions';
import { useInboxState } from '../../screens/Inbox/state/Inbox.selectors';
import { useOrdersOverviewAction } from '../../screens/Orders/state/OrdersOverview.actions';
import { useOrdersOverviewState, useOrdersListQueryVars } from '../../screens/Orders/state/OrdersOverview.selectors';
import type { UnsavedFilterCriteria } from '../OrderFilterBuilder/OrderFilterBuilder.types';
import { OrderFilterBuilderRoot, isCompletedFilterCriteria } from '../OrderFilterBuilder/OrderFilterBuilderRoot';
import { ClearFilterButton } from './ListFilters';
import type { OrdersToolbarPopperContentProps } from './OrdersToolbarPopper';
import { OrdersToolbarPopper } from './OrdersToolbarPopper';
import { useListOrderFiltersQuery } from '@orthly/graphql-react';
import type { SimpleSelectProps } from '@orthly/ui';
import { SimpleSelect } from '@orthly/ui';
import { FlossPalette, Button, FlossPaletteUtils, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface FiltersPopoverContentProps {
    setChanged: () => void;
    changed: boolean;
    mode: 'inbox' | 'orders';
}

function useExistingCriteria(mode: FiltersPopoverContentProps['mode']) {
    const orderCriteria = useOrdersListQueryVars()?.criteria;
    const inboxCriteria = useInboxState(s => s.view?.ordersCriteria);
    // man i hate handling conditional hooks
    return mode === 'inbox' ? inboxCriteria : orderCriteria;
}

function useSetCriteria(mode: FiltersPopoverContentProps['mode']) {
    const setInbox = useOpsInboxAction('SET_ORDER_CRITERIA');
    const setOrders = useOrdersOverviewAction('SET_CRITERIA');
    // man i hate handling conditional hooks
    return mode === 'inbox' ? setInbox : setOrders;
}

const FiltersPopoverContent: React.FC<OrdersToolbarPopperContentProps & FiltersPopoverContentProps> = props => {
    const { setOpen, setChanged, changed } = props;
    const filtersQuery = useListOrderFiltersQuery();
    const setCriteria = useSetCriteria(props.mode);
    const existingCriteria = useExistingCriteria(props.mode);
    const [criterias, setCriterias] = React.useState<UnsavedFilterCriteria[]>(existingCriteria ?? [{}]);
    const completedCriterias = criterias.filter(isCompletedFilterCriteria);
    const onApply = React.useCallback(() => {
        if (completedCriterias.length === criterias.length) {
            setCriteria(completedCriterias);
            setOpen(false);
        }
    }, [completedCriterias, criterias.length, setCriteria, setOpen]);
    const isUnchanged = React.useMemo(() => {
        return _.isEqual(criterias, existingCriteria);
    }, [existingCriteria, criterias]);
    return (
        <OrderFilterBuilderRoot
            changed={changed}
            setChanged={setChanged}
            criterias={criterias}
            filtersQuery={filtersQuery}
            setCriterias={setCriterias}
            actionsBlock={
                <>
                    <Grid container item xs={6} justifyContent={'flex-end'}>
                        <Button disabled={!props.changed} variant={'ghost'} onClick={() => props.setOpen(false)}>
                            Cancel
                        </Button>
                        <Button
                            disabled={completedCriterias.length !== criterias.length || isUnchanged}
                            variant={'primary'}
                            onClick={onApply}
                            style={{ marginLeft: 8 }}
                        >
                            Apply
                        </Button>
                    </Grid>
                    <Grid container item xs={1} />
                </>
            }
        />
    );
};

// Calculates display value and the associated option for the in-toolbar select, which is only responsible for display
function useSelectDisplayProps(mode: 'inbox' | 'orders', skip: boolean): Pick<SimpleSelectProps, 'value' | 'options'> {
    const ordersCriteria = useOrdersOverviewState(s => s.view?.criteria ?? []);
    const inboxCriteria = useInboxState(s => s.view?.ordersCriteria ?? []);
    const filtersQuery = useListOrderFiltersQuery({ skip, fetchPolicy: 'cache-first' });
    const title = React.useMemo(() => {
        const filterCriteria = mode === 'inbox' ? inboxCriteria : ordersCriteria;
        const filterRules = filtersQuery.data?.rules ?? [];
        if (filterCriteria.length === 0) {
            return undefined;
        }
        const filterTitles = _.uniq(
            filterCriteria.map(c => {
                const matchingRule = filterRules.find(r => r.id === c.filter_id);
                if (matchingRule) {
                    return matchingRule.name;
                }
                return _.startCase(c.filter_id).replace('By ', '');
            }),
        );
        if (filterTitles.length === 0) {
            return 'Unsaved filter';
        }
        return filterTitles.join(', ');
    }, [inboxCriteria, ordersCriteria, filtersQuery.data, mode]);
    if (!title) {
        return { options: [], value: undefined };
    }
    return { options: [{ value: title }], value: title };
}

const ClearFilterButtonOrders: React.FC<{ className?: string }> = props => {
    const clearOrdersCriteria = useOrdersOverviewAction('CLEAR_CRITERIA');
    return <ClearFilterButton className={props.className} onClear={clearOrdersCriteria} />;
};

const ClearInboxOrdersFilterButton: React.FC<{ className?: string }> = props => {
    const clearInboxCriteria = useOpsInboxAction('CLEAR_ORDER_CRITERIA');
    return <ClearFilterButton className={props.className} onClear={clearInboxCriteria} />;
};

export const OrdersListFilters: React.FC<{ mode: 'inbox' | 'orders' }> = props => {
    const [changed, setChanged] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const { value: displayValue, options: displayOpts } = useSelectDisplayProps(props.mode, !open);
    const popperSetOpen = React.useCallback(
        (newOpen: boolean, isBackgroundClick: boolean) => {
            if (!newOpen && changed) {
                if (isBackgroundClick && !window.confirm('Abandon changes?')) {
                    return;
                }
                setChanged(false);
            }
            setOpen(newOpen);
        },
        [changed],
    );
    return (
        <OrdersToolbarPopper<FiltersPopoverContentProps>
            ContentProps={{ changed, setChanged: () => setChanged(true), mode: props.mode }}
            PopoverContent={FiltersPopoverContent}
            open={open}
            setOpen={popperSetOpen}
            popoverWidth={'50vw'}
            rootStyle={{
                background: !!displayValue ? FlossPaletteUtils.toRgba('STAR_GRASS', 0.15) : undefined,
                width: '20%',
            }}
        >
            <SimpleSelect
                // just used for display
                value={displayValue}
                options={displayOpts}
                onChange={() => {}}
                label={props.mode === 'inbox' ? 'Filter by order' : 'Filter by'}
                FormControlProps={{ onClick: _e => setOpen(true), variant: 'standard' }}
                InputLabelProps={{ style: { backgroundColor: 'transparent' } }}
                SelectProps={{
                    disabled: true,
                    style: {
                        backgroundColor: 'transparent',
                        color: FlossPalette.BLACK,
                        cursor: 'pointer',
                        borderRadius: 0,
                        border: 'none',
                        boxShadow: 'none',
                    },
                    SelectDisplayProps: { style: { cursor: 'pointer' } },
                    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                    // eslint-disable-next-line no-nested-ternary
                    IconComponent: !!displayValue
                        ? props.mode === 'inbox'
                            ? ClearInboxOrdersFilterButton
                            : ClearFilterButtonOrders
                        : undefined,
                    variant: 'standard',
                }}
            />
        </OrdersToolbarPopper>
    );
};
