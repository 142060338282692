import type { NestedCheckboxListOption, NestedCheckboxListSelections } from './NestedCheckboxList';

export const nestedCheckboxListUtils = {
    /** Flattens a nested selection structure into an array of category strings.  */
    flattenSelections: (selections: NestedCheckboxListSelections = {}): string[] => {
        const result: string[] = [];

        for (const [category, { children }] of Object.entries(selections)) {
            result.push(category);
            result.push(...nestedCheckboxListUtils.flattenSelections(children));
        }

        return result;
    },

    /** Unflattens a flat array of category strings into a nested selection structure based on a given scaffold.  */
    unflattenSelections: (selections: string[], scaffold: NestedCheckboxListOption[]): NestedCheckboxListSelections => {
        const buildTree = (options: NestedCheckboxListOption[] = []): NestedCheckboxListSelections => {
            const levelMap: NestedCheckboxListSelections = {};

            for (const option of options) {
                if (selections.includes(option.category)) {
                    levelMap[option.category] = { children: buildTree(option.children) };
                }
            }

            return levelMap;
        };

        return buildTree(scaffold);
    },
};
