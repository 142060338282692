import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { Format } from '@orthly/runtime-utils';
import { RootActionDialog, SmallTrashCanIcon, useRootActionCommand } from '@orthly/ui';
import { Button, IconButton, styled, Text, Tooltip, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface DeleteInvoiceCreditProps {
    initial_credit_balance: number;
    credit_id: string;
    partner_id: string;
    refetch: () => void;
    deleted_at: string | null;
    description?: string | null;
}

const DeleteInvoiceCredit_Mutation = graphql(`
    mutation DeleteInvoiceCredit($data: DeleteInvoiceCreditInput!) {
        deleteInvoiceCredit(data: $data) {
            id
        }
    }
`);

const FlexContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const StyledIconButton = styled(IconButton)({
    marginLeft: '8px',
    padding: '0 12px 0 12px',
});

export const DeleteInvoiceCredit: React.FC<DeleteInvoiceCreditProps> = ({
    initial_credit_balance,
    deleted_at,
    credit_id,
    description,
    refetch,
}) => {
    const [open, setOpen] = React.useState(false);

    const deleteInvoiceCreditMtn = useMutation(DeleteInvoiceCredit_Mutation);
    const { submit, submitting } = useRootActionCommand(deleteInvoiceCreditMtn, {
        successMessage: 'Credit has been deleted',
        onSuccess: () => refetch(),
    });

    const handleSubmit = async () => {
        await submit({
            data: {
                credit_id,
            },
        });
    };

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Delete Credit'}
            subtitle={'This action cannot be reversed. Are you sure you want to proceed?'}
            buttonText={'Delete credit'}
            CustomButton={({ onClick }) => (
                <FlexContainer>
                    <Text variant={'body2'} color={deleted_at ? 'GRAY' : 'BLACK'}>
                        {Format.currency(initial_credit_balance)}
                    </Text>
                    <StyledIconButton onClick={onClick} size={'small'}>
                        <Tooltip title={'Delete Credit'}>
                            <SmallTrashCanIcon />
                        </Tooltip>
                    </StyledIconButton>
                </FlexContainer>
            )}
            content={
                <Grid alignItems={'stretch'}>
                    {description && (
                        <Grid item>
                            <Text variant={'body2'}>Credit to be deleted: "{description}"</Text>
                        </Grid>
                    )}
                    <Grid item container justifyContent={'flex-end'}>
                        <Grid item>
                            <Button
                                variant={'secondary'}
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <Button variant={'alert-secondary'} onClick={async () => await handleSubmit()}>
                                Delete credit
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
        />
    );
};
