import type { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';

export const WorkflowTaskTypeToName: Partial<Record<LabsGqlWorkflowTaskType, string>> = {
    ResolveScanRejection: 'Resolve flagged scan',
    ScanReview: 'Scan review',
    ReviewOrder: 'Order review',
    ResolveHold: 'Resolve hold',
    InternalDesign: 'Dandy Design',
    InjectionMoldDesign: 'Injection Mold Design',
    CreateTreatmentPlanRequest: 'Send to planner',
    SetTreatmentPlanRequestUrl: 'Attach plan',
    ConvertTreatmentPlanRequest: 'Convert plan',
    FinalizeTreatmentPlanRequest: 'Finalize plan',
    ApproveAlignerFabrication: 'Approve aligners',
    CreateExternalAlignerFulfillment: 'Send to manufacturer',
    DesignReview: 'Design Review',
    DesignReview2: 'Design Double QC',
    DesignPrep: 'Design prep',
    ExternalDesignVerification: 'Waxup verification',
    ModelDesign: 'Model Design',
};

export function getWorkflowTaskName(taskType: string) {
    return WorkflowTaskTypeToName[taskType as LabsGqlWorkflowTaskType] ?? taskType;
}
