import type { Theme } from '@orthly/ui-primitives';
import { createStyles, makeStyles } from '@orthly/ui-primitives';

export const useFilterClasses = makeStyles(
    (theme: Theme) =>
        createStyles({
            __docgenInfo: {},
            displayName: {},
            root: {
                backgroundColor: theme.palette.background.default,
                padding: '16px 24px 16px 24px',
                fontFamily: 'Roboto',
            },
            header: {
                flex: '0 0 auto',
                marginBottom: '16px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
            },
            title: {
                display: 'inline-block',
                color: theme.palette.text.primary,
                fontSize: '14px',
                fontWeight: 500,
            },
            noMargin: {
                marginLeft: '0px',
            },
            reset: {
                alignSelf: 'left',
                alignItems: 'center',
                display: 'flex',
            },
            resetLink: {
                marginLeft: '16px',
                fontSize: '12px',
                cursor: 'pointer',
            },
            filtersSelected: {
                alignSelf: 'right' as 'right',
            },
            /* checkbox */
            checkboxList: {
                flex: '1 1 100%',
                display: 'inline-flex',
                marginRight: '24px',
            },
            checkboxListTitle: {
                marginLeft: '7px',
                marginBottom: '8px',
                fontSize: '14px',
                color: theme.palette.text.secondary,
                textAlign: 'left' as 'left',
                fontWeight: 500,
            },
            checkboxFormGroup: {
                marginTop: '8px',
            },
            checkboxFormControl: {
                margin: '0px',
            },
            checkboxFormControlLabel: {
                fontSize: '15px',
                marginLeft: '8px',
                color: theme.palette.text.primary,
            },
            checkboxIcon: {
                width: '32px',
                height: '32px',
            },
            checkbox: {
                '&.Mui-checked': {
                    color: theme.palette.primary.main,
                },
            },
            checked: {},
            /* selects */
            selectRoot: {
                display: 'flex',
                marginTop: '16px',
                flexDirection: 'row' as 'row',
                flexWrap: 'wrap' as 'wrap',
                width: '100%',
                height: '80%',
                justifyContent: 'space-between',
                '& .MuiSelect-selectMenu': {
                    height: '1.1875em',
                },
            },
            selectFormControl: {
                flex: '1 1 calc(50% - 24px)',
            },
            /* textField */
            textFieldRoot: {
                display: 'flex',
                marginTop: '16px',
                flexDirection: 'row' as 'row',
                flexWrap: 'wrap' as 'wrap',
                width: '100%',
            },
            textFieldFormControl: {
                flex: '1 1 calc(50% - 24px)',
                marginRight: '24px',
                marginBottom: '24px',
            },
        }),
    { name: 'MUIDataTableFilter' },
) as () => Record<string, string> & { title: string };
