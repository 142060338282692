import type { MainViewCameraControlsRef } from '../ModelViewer';
import type { IOperationsManager } from './OperationsManager.types';
import type { ISceneAppearanceManager } from './SceneAppearanceManager.types';
import { ViewManager } from './ViewManager';
import type { IViewManager } from './ViewManager.types';
import React from 'react';

export function useViewManager(
    controlsRef: MainViewCameraControlsRef,
    opManager: IOperationsManager,
    sceneManager: ISceneAppearanceManager,
): IViewManager {
    return React.useMemo(
        () => new ViewManager(controlsRef, opManager, sceneManager),
        [controlsRef, opManager, sceneManager],
    );
}
