import type { IBaseItemData } from '../../types';
import * as BridgeItemUtils from '../../utils/bridgeItem.util';
import * as ItemUtils from '../../utils/item.util';
import { getHeaderForLabSlipItem } from '../../utils/shared.utils';
import { BaseItem } from './BaseItem';
import { ItemShadesTable } from './ItemShadesTable';
import { PrintableSlipItemDetail } from './PrintableSlipItemDetail';
import type { IBridgeItem } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

/**
 * Renders a "Bridge" order item to display on
 * the Printable Slip
 * @param props The order item to render
 * @returns Rendered order item details for the order "Bridge" item
 */
export const BridgeItem: React.VFC<{
    item: IBridgeItem;
}> = ({ item }) => {
    return <BaseItem itemData={mapBridgeItemToPrintableSlipData(item)} />;
};

/**
 * Pull data needed for printable slip display into a standard data structure
 * @param item IBridgeItem order item to display
 * @returns Structured data for Bridge item display
 */
function mapBridgeItemToPrintableSlipData(item: IBridgeItem): IBaseItemData {
    const maximumModelHeight = ItemUtils.getMaximumModelElementHeightInMm(item);
    const puckHeight = ItemUtils.getPuckHeight(item);
    const crownHeight =
        maximumModelHeight &&
        `${_.round(maximumModelHeight, 2)}mm${puckHeight ? ` (Puck Height ${_.round(puckHeight, 2)}mm)` : ''}`;

    return {
        headerLeft: getHeaderForLabSlipItem(item),
        itemFields: _.compact([
            { key: 'Material', value: BridgeItemUtils.getBridgeMaterial(item) },
            {
                customComponent: (
                    <PrintableSlipItemDetail title={'Shade'}>
                        <ItemShadesTable shades={item.shades} />
                    </PrintableSlipItemDetail>
                ),
            },
            {
                key: "Occlusal Staining'",
                value: ItemUtils.getOcclusalStainingPreference(item),
            },
            maximumModelHeight
                ? {
                      customComponent: <PrintableSlipItemDetail title={'Crown Height'} value={crownHeight} />,
                  }
                : undefined,
        ]),
    };
}
