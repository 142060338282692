import type { LabsGqlFilterCriteriaSubmissionInput } from '@orthly/graphql-schema';
import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'palate';

export interface PalateFilterCriteria extends LabsGqlFilterCriteriaSubmissionInput {
    comparison_value?: string[];
}

export const PalateActions = {
    SET_ORDER_FILTERS: createSyncAction<Array<PalateFilterCriteria>>(`${PREFIX}/SET_ORDER_FILTERS`),
    CLEAR_ORDER_FILTERS: createSyncAction<undefined, []>(`${PREFIX}/CLEAR_ORDER_FILTERS`),
    SET_TIMELINE_FILTERS: createSyncAction<Array<PalateFilterCriteria>>(`${PREFIX}/SET_TIMELINE_FILTERS`),
    CLEAR_TIMELINE_FILTERS: createSyncAction<undefined, []>(`${PREFIX}/CLEAR_TIMELINE_FILTERS`),
};

export const usePalateAction = generateUseActionHook<typeof PalateActions>(PalateActions);
