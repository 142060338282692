import type { ButtonProps, CheckboxPrimitiveProps } from '@orthly/ui-primitives';
import { Button, CheckboxPrimitive as Checkbox, FormControlLabel, Grid, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

export interface ConfirmationButtonProps {
    confirmLabel: string;
    confirmationOnChange?: (confirmed: boolean) => void;

    initialText: React.ReactNode;
    initialOnClick?: () => void;
    initialProps?: ButtonProps;

    submitText: React.ReactNode;
    submitOnClick: () => void;
    submitProps?: ButtonProps;
    needsConfirmation?: boolean;

    allButtonProps?: ButtonProps;
}

export const ConfirmationButton: React.FC<ConfirmationButtonProps> = props => {
    const [initialPressed, setInitialPressed] = React.useState(false);
    const [confirmed, setConfirmed] = React.useState(false);
    const [confirmError, setConfirmError] = React.useState(false);

    const onCheckboxClick: CheckboxPrimitiveProps['onChange'] = (_e, checked) => {
        setConfirmed(checked);
        setConfirmError(false);
        props.confirmationOnChange && props.confirmationOnChange(checked);
    };
    const onInitialClick = () => {
        if (props.needsConfirmation) {
            setInitialPressed(true);
            props.initialOnClick && props.initialOnClick();
        } else {
            props.submitOnClick();
        }
    };
    const onSubmitClick = () => {
        if (!confirmed) {
            return setConfirmError(true);
        }
        props.submitOnClick();
    };

    const submitDisabled = !confirmed ? true : props.submitProps && props.submitProps.disabled;
    return (
        <Grid container justifyContent={'flex-end'}>
            {initialPressed && (
                <FormControlLabel
                    control={
                        <Checkbox
                            color={'secondary'}
                            checked={confirmed}
                            onChange={onCheckboxClick}
                            value={'confirmed'}
                        />
                    }
                    label={props.confirmLabel}
                    style={{ width: '100%', color: confirmError ? FlossPalette.ATTENTION : undefined }}
                />
            )}
            {!initialPressed && (
                <Button
                    onClick={onInitialClick}
                    variant={'contained'}
                    {...props.initialProps}
                    {...props.allButtonProps}
                >
                    {props.initialText}
                </Button>
            )}
            {initialPressed && (
                <Button
                    onClick={onSubmitClick}
                    variant={'contained'}
                    {...props.submitProps}
                    {...props.allButtonProps}
                    disabled={submitDisabled}
                >
                    {props.submitText}
                </Button>
            )}
        </Grid>
    );
};
