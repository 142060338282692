import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { DEFAULT_SCAN_APPEARANCE, DEFAULT_RESTORATIVE_APPEARANCE } from '@orthly/dentin';

/**
 * Based on {@linkplain https://docs.google.com/spreadsheets/d/1GhL--Z1XvfvOHsBhwDX-3xsxwJ3inURvMDvU3-GZYPo/edit?gid=642241560#gid=642241560|P1-3; A1-A4 Combined QC Rubrics} Google Sheet
 */
export const aestheticsRubric: DesignQcRubricCategoryEntry = {
    category: 'aesthetics',
    subcategories: [
        {
            category: 'aesthetics',
            name: 'Line Angles',
            grading: {
                type: 'binary',
                bad: 'Line angle contour does not mimic contralateral<br>AND/OR<br>Distance between distal and mesial line angle does not mimic contralateral',
                ideal: 'Line angle contour mimics contralateral<br>AND/OR<br>Distance between distal and mesial line angle mimics contralateral',
            },
            description:
                "The junction of the labial surface with the proximal surface.<br><br>The contour and distance between mesial and distal line angles should mimic it's contralateral counterpart. However, a designer has some level of freedom to" +
                ' shift the angles closer to the mesial or distal interpxomal contact.',
            selectable_bad_reasons: [
                'Line angle contour does not mimic contralateral',
                'Distance between the line angles does not mimic contralateral',
            ],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                },
                qc_settings: {
                    activeHeatMap: null,
                },
            },
            when: flags => flags.hasAnteriors,
        },
        {
            category: 'aesthetics',
            name: 'Facial Anatomy',
            grading: {
                type: 'binary',
                bad: 'Does not match/mirror adjacent or Contra side',
                ideal: 'Match adjacent or contra side',
            },
            description:
                'Developmental grooves, developmental lobes and other facial surface textures should match adjacent or contralateral teeth',
            selectable_bad_reasons: ["Doesn't match", 'Too aggressive', 'Not enough'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                },
                qc_settings: {
                    activeHeatMap: null,
                },
            },
            when: flags => flags.hasAnteriors,
        },
        {
            category: 'aesthetics',
            name: 'Mirroring / Symmetry',
            grading: {
                type: 'binary',
                bad: 'Does not match/mirror adjacent or Contra side',
                ideal: 'Match adjacent or contra side',
            },
            description:
                'When there is a viable contralateral tooth available, that tooth should be mirrored to the restoration to achieve symmetry in the aesthetic zone',
            selectable_bad_reasons: ['Does not Mirror adjacent or contra side'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                },
                qc_settings: {
                    activeHeatMap: null,
                },
            },
            when: flags => flags.hasAnteriors,
        },
        {
            category: 'aesthetics',
            name: 'Incisal Edge',
            grading: {
                type: 'binary',
                bad: 'Incisal Too Long, Too Short, Thick, Thin,  Incisal Embrasure Too open Incisal Embrasure Too close ',
                ideal: 'Incisal has correct position, thickness and Incisal Embrasure is not too open/close',
            },
            description: [
                'Is the incisal edge placed in the correct position where possible?',
                "The position of the incisal edge should be nearly identical to it's contralateral without compromising occlusion",
                '"The incisal edge thickness should match the thickness of the contralateral.',
                'If contralateral is not present, incisal edge thickness should be proportional. ',
                'Upper Centrals should be 0-0.5mm thicker than laterals.',
                'Lower incisors mantain the same size."',
                'Looking at incisal embrasures from the facial aspect. Slightly convex contour from the IP contact to incisal edge.',
                'Should never be concave.',
                'Embrasures are not exclusively dictated by their contralateral or adjacents. However, general contour should remain consistent across the anteriors. IE: Do not design a soft and youthful embrasure when the other teeth all sharp.',
                '"You know it when you see it"',
            ].join('<br><br>'),
            selectable_bad_reasons: [
                'Incisal too long',
                'Incisal too short',
                'Incisal too Thick',
                'Incisal too Thin',
                'Incisal embrasure too open',
                'Incisal embrasure too close',
            ],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                },
                qc_settings: {
                    activeHeatMap: null,
                },
            },
            when: flags => flags.hasAnteriors,
        },
        {
            category: 'aesthetics',
            name: 'Arch Form',
            grading: {
                type: 'binary',
                bad: 'Not in alignment with adjacent',
                ideal: 'In alignment with adjacent',
            },
            description: `The tooth or teeth should follow the natural arch form of the mouth.`,
            selectable_bad_reasons: ['Too Buccal', 'Too Lingual'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'TOP',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                },
                qc_settings: {
                    activeHeatMap: null,
                },
            },
            when: flags => flags.hasAnteriors,
        },
        {
            category: 'aesthetics',
            name: 'Relative Test',
            grading: {
                type: 'binary',
                bad: 'Would not give to a family member',
                ideal: 'Would give to a family member',
            },
            description: `This is the overall score for the design, "would you place this restoration in your relative's mouth"`,
            selectable_bad_reasons: ['Would not give to a family member'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                },
                qc_settings: {
                    activeHeatMap: null,
                },
            },
            when: flags => flags.hasAnteriors,
        },
    ],
};
