import { getLabelForCapability, levelToDisplayString } from '../../utils/Capabilities';
import type { LabsGqlDesignStaffWithUserFragment } from '@orthly/graphql-operations';
import { getWorkflowTaskName } from '@orthly/graphql-operations';
import { useDebugDesignerAutoAssignmentQuery } from '@orthly/graphql-react';
import { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { LabsGqlDesignStaffStatus, LabsGqlDesignStaffTaskType } from '@orthly/graphql-schema';
import { DesignStaffSupportedTaskTypes, getDefaultOrderDesignCapability } from '@orthly/shared-types';
import { ActionCard } from '@orthly/ui';
import { Tooltip, Text, makeStyles, createStyles } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        debuggerSection: {
            paddingBottom: 10,
        },
        iconSpan: {
            paddingRight: 5,
        },
    }),
);

export const OrderAutoAssignmentDebuggerInfoDisplay: React.FC<{
    designStaffWithUser: LabsGqlDesignStaffWithUserFragment;
    orderId: string;
    isOpen: boolean;
    orderActiveTask?: LabsGqlWorkflowTaskType;
}> = ({ designStaffWithUser, orderId, isOpen, orderActiveTask }) => {
    const { data, error, loading } = useDebugDesignerAutoAssignmentQuery({
        variables: {
            orderId,
            designerId: designStaffWithUser.designStaff.id,
        },
        skip: !isOpen,
    });
    const classes = useStyles();

    if (error) {
        return (
            <ActionCard
                style={{ marginTop: 10 }}
                title={`Failed to load designer info.`}
                subtitle={String(error)}
                variant={'alert'}
            />
        );
    }
    if (!data) {
        return <p>Loading ...</p>;
    }

    const { debugInfo } = data;

    return (
        <>
            {loading && <p>Loading ...</p>}

            <div className={classes.debuggerSection}>
                <Text variant={'body2'} color={'BLACK'} bold>
                    Designer is active{' '}
                    {designStaffWithUser.designStaff.status === LabsGqlDesignStaffStatus.Active ? '' : '(not met)'}
                </Text>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    {designStaffWithUser.designStaff.status === LabsGqlDesignStaffStatus.Active ? '✅ ' : '❌ '}
                    Current status: {designStaffWithUser.designStaff.status}
                </Text>
            </div>

            <div className={classes.debuggerSection}>
                <Text variant={'body2'} color={'BLACK'} bold>
                    Number of tasks in progress
                    {debugInfo.numTasksAssigned < debugInfo.maxTasksAssigned ? '' : '(not met)'}
                </Text>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    {debugInfo.numTasksAssigned < debugInfo.maxTasksAssigned ? '✅ ' : '❌ '}
                    {debugInfo.numTasksAssigned} / {debugInfo.maxTasksAssigned}
                </Text>
            </div>

            <div className={classes.debuggerSection}>
                <Text variant={'body2'} color={'BLACK'} bold>
                    Available for task types {debugInfo.availableForActiveTask ? '' : '(not met)'}
                </Text>
                {DesignStaffSupportedTaskTypes.map(taskType => {
                    return (
                        <Text key={taskType} variant={'body2'} color={'DARK_GRAY'}>
                            {designStaffWithUser.designStaff.assignable_task_types.includes(
                                LabsGqlDesignStaffTaskType[taskType],
                            )
                                ? '✅ '
                                : '❌ '}
                            {getWorkflowTaskName(taskType)}
                            {orderActiveTask === taskType ? ' (active task)' : ''}
                        </Text>
                    );
                })}
            </div>

            {debugInfo.focusAreas && (
                <div className={classes.debuggerSection}>
                    <Text variant={'body2'} bold>
                        Focus Areas
                    </Text>
                    {debugInfo.focusAreas.map(fa => (
                        <Text variant={'body2'} key={fa.type}>
                            <span className={classes.iconSpan}>{fa.passes ? '✅' : '❌'}</span>
                            <span>{`${orderActiveTask} ${getLabelForCapability(
                                fa.type,
                                orderActiveTask === LabsGqlWorkflowTaskType.ModelDesign,
                            )} ${levelToDisplayString(fa.level)}`}</span>
                        </Text>
                    ))}
                </div>
            )}

            {debugInfo.taskTypeCapabilityMatch.map(({ taskType, matches, details }) => (
                <div key={taskType} className={classes.debuggerSection}>
                    <Text variant={'body2'} color={'BLACK'} bold>
                        {getWorkflowTaskName(taskType)} Capabilities {matches ? '' : '(not met)'}
                    </Text>
                    <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
                        {details.map(({ type, level, designerLevel, passes }) => {
                            const capability = getDefaultOrderDesignCapability(type, level);
                            const capabilityName = capability?.name ?? `${type} ${level}`;
                            return (
                                <Tooltip key={type + level} title={capability?.description ?? 'Not recognized'}>
                                    <Text variant={'body2'} color={'DARK_GRAY'}>
                                        <span className={classes.iconSpan}>{passes ? '✅' : '❌'}</span>
                                        <span>{`${capabilityName} (${designerLevel ?? 'N/A'})`}</span>
                                    </Text>
                                </Tooltip>
                            );
                        })}
                    </div>
                </div>
            ))}
        </>
    );
};
