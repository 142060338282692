import type { ModelAppearance } from '@orthly/dentin';
import React from 'react';

export function useTransparency(
    appearanceSettings: ModelAppearance,
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>,
) {
    const updateRestorativeOpacity = React.useCallback(
        (newValue: number) => {
            setAppearance(appearance => ({
                ...appearance,
                restoratives: appearance.restoratives.map(pma => ({
                    ...pma,
                    appearance: {
                        ...pma.appearance,
                        opacity: newValue,
                    },
                })),
            }));
        },
        [setAppearance],
    );

    const updateApplyToAnatomyLayers = React.useCallback(
        (newValue: boolean) => {
            setAppearance(current => ({ ...current, applyTransparencyToAnatomyLayers: newValue }));
        },
        [setAppearance],
    );

    const updateApplyToRestoratives = React.useCallback(
        (newValue: boolean) => {
            setAppearance(current => ({ ...current, applyTransparencyToRestoratives: newValue }));
        },
        [setAppearance],
    );

    const updatePorcelainOpacity = React.useCallback(
        (newValue: number) => {
            setAppearance(current => ({ ...current, porcelainOpacity: newValue }));
        },
        [setAppearance],
    );

    const toggleAnatomyLayers = React.useCallback(
        (newValue: boolean) => {
            setAppearance(current => ({ ...current, showAnatomyLayers: newValue }));
        },
        [setAppearance],
    );

    const resetPorcelainOpacity = React.useCallback(() => {
        updatePorcelainOpacity(0);
        updateApplyToAnatomyLayers(false);
        toggleAnatomyLayers(true);
    }, [updatePorcelainOpacity, toggleAnatomyLayers, updateApplyToAnatomyLayers]);

    const resetRestorativeOpacity = React.useCallback(() => {
        updateApplyToRestoratives(false);
        updateRestorativeOpacity(1);
    }, [updateApplyToRestoratives, updateRestorativeOpacity]);

    const restorativeOpacity = appearanceSettings.restoratives[0]
        ? appearanceSettings.restoratives[0].appearance.opacity
        : 1;

    const porcelainOpacity = appearanceSettings?.porcelainOpacity ?? 0;

    const applyToAnatomyLayers = !!appearanceSettings.applyTransparencyToAnatomyLayers;
    const applyToRestorative = !!appearanceSettings.applyTransparencyToRestoratives;

    const resetState = React.useCallback(() => {
        resetPorcelainOpacity();
        resetRestorativeOpacity();
    }, [resetRestorativeOpacity, resetPorcelainOpacity]);

    const updateOpacity = React.useCallback(
        (newValue: number) => {
            updateRestorativeOpacity(newValue);
            updatePorcelainOpacity(newValue);
        },
        [updateRestorativeOpacity, updatePorcelainOpacity],
    );

    const onToggleRestorative = React.useCallback(() => {
        if (applyToRestorative) {
            resetState();
        } else {
            toggleAnatomyLayers(true);
            updateApplyToAnatomyLayers(false);
            updateApplyToRestoratives(true);
            updateOpacity(0.5);
        }
    }, [
        resetState,
        applyToRestorative,
        updateApplyToRestoratives,
        updateApplyToAnatomyLayers,
        toggleAnatomyLayers,
        updateOpacity,
    ]);

    const onToggleAnatomyLayers = React.useCallback(() => {
        if (applyToAnatomyLayers) {
            updateApplyToAnatomyLayers(false);
            toggleAnatomyLayers(false);
            resetPorcelainOpacity();
        } else {
            updateApplyToAnatomyLayers(true);
            updateApplyToRestoratives(false);
            toggleAnatomyLayers(true);
            updatePorcelainOpacity(0);
        }
    }, [
        applyToAnatomyLayers,
        updateApplyToAnatomyLayers,
        updatePorcelainOpacity,
        updateApplyToRestoratives,
        resetPorcelainOpacity,
        toggleAnatomyLayers,
    ]);

    return {
        updateOpacity,
        updatePorcelainOpacity,
        restorativeOpacity,
        porcelainOpacity,
        onToggleRestorative,
        onToggleAnatomyLayers,
        applyToAnatomyLayers,
        resetState,
        applyToRestorative,
    };
}
