import { NavigationTitleBoundary } from '../../components/NavigationTitleBoundary';
import { RouterUtils } from '../../utils/router/RouterUtils';
import { AutomationsToolbar } from './components/AutomationsToolbar';
import { AutomationBuilderRoot } from './screens/AutomationBuilder/AutomationBuilderRoot';
import { useAutomationListItems } from './screens/AutomationsList/AutomationsList.hook';
import { AutomationsListRoot } from './screens/AutomationsList/AutomationsListRoot';
import { AutomationBuilderReduxContext, useAutomationBuilderSelector } from './state/AutomationBuilder.context';
import { AutomationBuilderStore } from './state/AutomationBuilder.store';
import { makeStyles, createStyles, Grid, Slide } from '@orthly/ui-primitives';
import React from 'react';
import { Provider } from 'react-redux';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: '100vh',
            overflowY: 'scroll',
            flexWrap: 'nowrap',
            position: 'relative',
        },
    }),
);

const AutomationsContentRoot: React.FC<RouteComponentProps<{ automationId?: string }>> = props => {
    // If provided on load, we will automatically render this automation.
    const automationId = props.match.params.automationId;

    const classes = useStyles();
    const editing = useAutomationBuilderSelector(s => s.editing);
    const navTitle = useAutomationBuilderSelector(s =>
        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
        // eslint-disable-next-line no-nested-ternary
        s.editing ? (s.form.name ? `${s.form.name} | Automation` : 'New Automation') : 'Automations',
    );

    const { loading, automations, refetch } = useAutomationListItems();

    return (
        <NavigationTitleBoundary title={navTitle}>
            <Grid container direction={'column'} className={classes.root}>
                <AutomationsToolbar refetch={refetch} />
                <Slide direction={'right'} in={!editing}>
                    <div style={editing ? { position: 'absolute', top: 128 } : undefined}>
                        <AutomationsListRoot
                            loadedAutomationId={automationId}
                            loading={loading}
                            automations={automations}
                            refetch={refetch}
                        />
                    </div>
                </Slide>
                <Slide direction={'left'} in={editing} unmountOnExit>
                    <div style={!editing ? { position: 'absolute', top: 128 } : undefined}>
                        <AutomationBuilderRoot />
                    </div>
                </Slide>
            </Grid>
        </NavigationTitleBoundary>
    );
};

export const AutomationsRoot: React.FC = () => {
    return (
        <Provider context={AutomationBuilderReduxContext} store={AutomationBuilderStore}>
            <Switch>
                <Route
                    path={`${RouterUtils.pathForScreen('automations')}/:automationId?`}
                    component={AutomationsContentRoot}
                />
            </Switch>
        </Provider>
    );
};
