import type { Theme, AccordionProps, AccordionSummaryProps, AccordionDetailsProps } from '@orthly/ui-primitives';
import {
    createStyles,
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Text,
    ExpandMoreIcon,
} from '@orthly/ui-primitives';
import cx from 'clsx';
import React from 'react';

export const useSimpleExpansionPanelStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiExpansionPanelSummary-content': { margin: 0 },
            '& .MuiExpansionPanelSummary-root': { minHeight: 48 },
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
        },
    }),
);

interface SimpleExpansionPanelProps {
    title: React.ReactNode | string;
    children: React.ReactNode;
    ExpansionPanelProps?: Partial<AccordionProps>;
    ExpansionPanelSummaryProps?: AccordionSummaryProps;
    ExpansionPanelDetailsProps?: AccordionDetailsProps;
    hideChildrenOnClose?: boolean;
}

export const SimpleExpansionPanel: React.FC<SimpleExpansionPanelProps> = props => {
    const classes = useSimpleExpansionPanelStyles();

    const [open, setOpen] = React.useState<boolean>(props.ExpansionPanelProps?.defaultExpanded ?? false);

    return (
        <Grid container>
            <Accordion
                {...props.ExpansionPanelProps}
                expanded={open}
                onChange={(_e, expanded) => {
                    setOpen(expanded);
                }}
                style={{ width: '100%', ...props.ExpansionPanelProps?.style }}
                className={cx(classes.root, props.ExpansionPanelProps?.className)}
            >
                <AccordionSummary
                    {...props.ExpansionPanelSummaryProps}
                    expandIcon={<ExpandMoreIcon />}
                    style={{ width: '100%', padding: '0 24px', ...props.ExpansionPanelSummaryProps?.style }}
                >
                    {typeof props.title === 'string' ? (
                        <Text variant={'h6'} className={classes.heading}>
                            {props.title}
                        </Text>
                    ) : (
                        props.title
                    )}
                </AccordionSummary>
                <AccordionDetails
                    {...props.ExpansionPanelDetailsProps}
                    style={{ width: '100%', ...props.ExpansionPanelDetailsProps?.style }}
                >
                    {(!props.hideChildrenOnClose || open) && props.children}
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};
