import { recomputeCollisionsObject } from '../DesignEditing/RecomputeCollisions';
import type { BrushSettings } from '../DesignEditing/SculptingBrush';
import { BrushType, brushTypeActions } from '../DesignEditing/SculptingBrush';
import type { HandleSculptFn } from '../DesignEditing/SculptingTool.types';
import type { MainViewCameraControlsRef } from '../ModelViewer/ModelViewerTHREETypes';
import { EditingMode } from './FinishingApp.types';
import type { IOperationsManager } from './OperationsManager.types';
import { useSetBrushSetting } from './Sculpting.hooks.utils';
import type { HandleSculptCompleteFn, SculptingState } from './Sculpting.types';
import { createDefaultBrushSettings } from './Sculpting.utils';
import { SculptTool } from './SculptingTool';
import {
    ComputeVertexNormalsByAngleForSubset,
    getConnectedFaces,
    HeatMapType,
    recomputeActiveDistanceForASubset,
    type MeshConnectivityGraph,
    ALL_SCULPT_HEATMAPS,
} from '@orthly/forceps';
import React from 'react';
import type * as THREE from 'three';
import { v4 as uuidv4 } from 'uuid';

/**
 * Implements sculpting functionality
 */
export function useSculpting(
    orderId: string,
    mode: EditingMode | undefined,
    setMode: React.Dispatch<React.SetStateAction<EditingMode | undefined>>,
    operationsManager: IOperationsManager,
    cameraControlsRef: MainViewCameraControlsRef,
    canvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
    sculptingGroup: THREE.Group,
    updateAppearance: (geometry: THREE.BufferGeometry) => void,
    curtains: THREE.BufferGeometry | undefined,
): SculptingState {
    const sculptingState = useSculptingState(mode, setMode);

    const { onSculptIter, onSculptComplete } = useSculptCallbacks(
        operationsManager,
        sculptingState,
        updateAppearance,
        curtains,
    );

    useSculptingTool(
        sculptingState.sculptingEnabled,
        orderId,
        sculptingState.brushSettings,
        operationsManager.editGeometry,
        onSculptIter,
        onSculptComplete,
        cameraControlsRef,
        canvasRef,
        operationsManager.connectivityGraph,
        sculptingGroup,
    );

    return sculptingState;
}

function useSculptingTool(
    sculptingEnabled: boolean,
    orderId: string,
    brushSettings: BrushSettings,
    editGeometry: THREE.BufferGeometry | undefined,
    onSculptIter: HandleSculptFn,
    onSculptComplete: HandleSculptCompleteFn,
    cameraControlsRef: MainViewCameraControlsRef,
    canvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
    meshConnectivityGraph: MeshConnectivityGraph,
    sculptingGroup: THREE.Group,
) {
    React.useEffect(() => {
        const sculptTool = new SculptTool(
            sculptingEnabled,
            canvasRef.current,
            cameraControlsRef.current,
            brushSettings,
            onSculptIter,
            onSculptComplete,
            meshConnectivityGraph,
            sculptingGroup,
            editGeometry,
        );
        const pointerMove = (evt: MouseEvent) => sculptTool.pointerMove(evt);
        const pointerDown = (evt: MouseEvent) => sculptTool.pointerDown(evt);
        const pointerUp = (evt: MouseEvent) => sculptTool.pointerUp(evt);

        const canvas = canvasRef.current;
        canvas?.addEventListener('pointermove', pointerMove);
        canvas?.addEventListener('pointerdown', pointerDown);
        canvas?.addEventListener('pointerup', pointerUp);
        canvas?.addEventListener('pointerleave', pointerUp);

        return () => {
            canvas?.removeEventListener('pointerdown', pointerDown);
            canvas?.removeEventListener('pointermove', pointerMove);
            canvas?.removeEventListener('pointerup', pointerUp);
            canvas?.removeEventListener('pointerleave', pointerUp);
            sculptTool.removeEffectCircle();
        };
    }, [
        cameraControlsRef,
        orderId,
        sculptingEnabled,
        canvasRef,
        brushSettings,
        onSculptIter,
        onSculptComplete,
        meshConnectivityGraph,
        editGeometry,
        sculptingGroup,
    ]);
}

/**
 * Generates the state and callbacks for the sculpting tool
 */
function useSculptingState(
    mode: EditingMode | undefined,
    setMode: React.Dispatch<React.SetStateAction<EditingMode | undefined>>,
): SculptingState {
    const [brushSettings, setBrushSettings] = React.useState<BrushSettings>(createDefaultBrushSettings());

    const [isAddBrushEnabled, enableAddBrush] = useEnabledBrush(
        BrushType.add,
        brushSettings.brushType,
        setBrushSettings,
    );
    const [isSubtractBrushEnabled, enableSubtractBrush] = useEnabledBrush(
        BrushType.subtract,
        brushSettings.brushType,
        setBrushSettings,
    );
    const [isSmoothBrushEnabled, enableSmoothBrush] = useEnabledBrush(
        BrushType.smooth,
        brushSettings.brushType,
        setBrushSettings,
    );
    const [isOpposingBrushEnabled, enableOpposingBrush] = useEnabledBrush(
        BrushType.opposing,
        brushSettings.brushType,
        setBrushSettings,
    );
    const [isProximalBrushEnabled, enableProximalBrush] = useEnabledBrush(
        BrushType.limit,
        brushSettings.brushType,
        setBrushSettings,
    );
    const [isCurtainsBrushEnabled, enableCurtainsBrush] = useEnabledBrush(
        BrushType.curtains,
        brushSettings.brushType,
        setBrushSettings,
    );

    const setBrushRadius = useSetBrushSetting('radius', setBrushSettings);
    const setBrushIntensity = useSetBrushSetting('intensity', setBrushSettings);
    const setBrushOpposingIntensity = useSetBrushSetting('occlusalLimitMm', setBrushSettings);
    const setBrushProximalIntensity = useSetBrushSetting('proximalLimitMm', setBrushSettings);
    const setBrushCurtainsIntensity = useSetBrushSetting('curtainsLimitMm', setBrushSettings);

    const toggleProximalRaiseVerticesEnabled = React.useCallback(
        () =>
            setBrushSettings(curr => ({
                ...curr,
                isProximalRaiseVerticesEnabled: !curr.isProximalRaiseVerticesEnabled,
            })),
        [],
    );

    const sculptingEnabled = mode === EditingMode.Sculpt;

    const toggleSculptingEnabled = React.useCallback(() => {
        setMode(curr => {
            return curr === EditingMode.Sculpt ? undefined : EditingMode.Sculpt;
        });
    }, [setMode]);

    const setSculptingEnabled = React.useCallback(() => {
        setMode(EditingMode.Sculpt);
    }, [setMode]);

    return React.useMemo(
        () => ({
            sculptingEnabled,
            toggleSculptingEnabled,
            setSculptingEnabled,
            brushSettings,
            isAddBrushEnabled,
            enableAddBrush,
            isSubtractBrushEnabled,
            enableSubtractBrush,
            isSmoothBrushEnabled,
            enableSmoothBrush,
            setBrushRadius,
            setBrushIntensity,
            isOpposingBrushEnabled,
            enableOpposingBrush,
            setBrushOpposingIntensity,
            isProximalBrushEnabled,
            enableProximalBrush,
            setBrushProximalIntensity,
            toggleProximalRaiseVerticesEnabled,
            isCurtainsBrushEnabled,
            enableCurtainsBrush,
            setBrushCurtainsIntensity,
        }),
        [
            sculptingEnabled,
            toggleSculptingEnabled,
            setSculptingEnabled,
            brushSettings,
            isAddBrushEnabled,
            enableAddBrush,
            isSubtractBrushEnabled,
            enableSubtractBrush,
            isSmoothBrushEnabled,
            enableSmoothBrush,
            setBrushRadius,
            setBrushIntensity,
            isOpposingBrushEnabled,
            enableOpposingBrush,
            setBrushOpposingIntensity,
            isProximalBrushEnabled,
            enableProximalBrush,
            setBrushProximalIntensity,
            toggleProximalRaiseVerticesEnabled,
            isCurtainsBrushEnabled,
            enableCurtainsBrush,
            setBrushCurtainsIntensity,
        ],
    );
}

/**
 * Creates the callbacks for the SculptingTool
 */
export function useSculptCallbacks(
    operationsManager: IOperationsManager,
    sculptingState: SculptingState,
    updateAppearance: (geometry: THREE.BufferGeometry) => void,
    curtains: THREE.BufferGeometry | undefined,
): {
    onSculptIter: HandleSculptFn;
    onSculptComplete: HandleSculptCompleteFn;
} {
    const onSculptIter = React.useCallback(
        (geometry: THREE.BufferGeometry | undefined, neighbors: number[]) => {
            if (!geometry) {
                return;
            }

            const neighborFaces = getConnectedFaces(
                neighbors,
                operationsManager.connectivityGraph.adjacencyFacesToVertex,
            );
            ComputeVertexNormalsByAngleForSubset(geometry, neighbors, neighborFaces);
            if (sculptingState.brushSettings.brushType === BrushType.opposing) {
                recomputeActiveDistanceForASubset(
                    [HeatMapType.Occlusal],
                    geometry,
                    neighbors,
                    operationsManager.proximalModels,
                    operationsManager.occlusalModels,
                );
            } else if (
                sculptingState.brushSettings.brushType === BrushType.limit ||
                sculptingState.brushSettings.brushType === BrushType.curtains
            ) {
                recomputeActiveDistanceForASubset(
                    [HeatMapType.Proximal],
                    geometry,
                    neighbors,
                    operationsManager.proximalModels,
                    operationsManager.occlusalModels,
                    undefined,
                    sculptingState.brushSettings.brushType === BrushType.curtains ? curtains : undefined,
                    true,
                );
            }
        },
        [operationsManager, sculptingState, curtains],
    );

    const onSculptComplete = React.useCallback(
        (geometry: THREE.BufferGeometry | undefined, neighbors: number[]) => {
            if (!geometry) {
                return;
            }

            if (neighbors.length > 0) {
                recomputeActiveDistanceForASubset(
                    ALL_SCULPT_HEATMAPS,
                    geometry,
                    neighbors,
                    operationsManager.proximalModels,
                    operationsManager.occlusalModels,
                    undefined,
                    curtains,
                    true,
                );
            }
            const collisionGeometry = recomputeCollisionsObject(operationsManager.editGeometry).model.geometry;
            operationsManager.collisionsGeometry?.copy(collisionGeometry);

            // We change the geometry's UUID to signal that it has been modified.
            geometry.uuid = uuidv4();

            operationsManager.applySculpt(geometry);

            updateAppearance(geometry);
        },
        [operationsManager, updateAppearance, curtains],
    );

    return { onSculptIter, onSculptComplete };
}

/**
 * Determines whether the given brush type is enabled and provides a callback to enable it
 */
function useEnabledBrush(
    brushType: BrushType,
    currentBrush: BrushType,
    setBrushSettings: React.Dispatch<React.SetStateAction<BrushSettings>>,
): [boolean, () => void] {
    const isEnabled = brushType === currentBrush;

    const setEnabled = React.useCallback(() => {
        setBrushSettings(curr => {
            return {
                ...curr,
                brushType,
                brushAction: brushTypeActions[brushType],
            };
        });
    }, [setBrushSettings, brushType]);

    return [isEnabled, setEnabled];
}
