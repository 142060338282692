import { SimpleExpansionPanel } from '../../components/SimpleExpansionPanel';
import { useBillingDetailsContext } from './BillingDetails/providers/BillingDetailsProvider.graphql';
import type { BillingCreditCategories } from './CreditCategories/BillingCreditCategoriesQuery.graphql';
import type { Credit } from './InvoicesTable/InvoiceTable.types';
import { ListCreditCreateUsers_Query } from './PartnerCreditsTable.graphql';
import { CreateBillingCreditAction } from './actions/CreateBillingCredit.graphql';
import { DeleteInvoiceCredit } from './actions/EditBillingCredit.graphql';
import { useQuery } from '@apollo/client';
import type { Column } from '@orthly/mui-table';
import { MUITable } from '@orthly/mui-table';
import { Format } from '@orthly/runtime-utils';
import { FlossPalette, Text } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';

interface PartnerCreditsTableRow extends Credit {
    // can be user name or 'prepayment invoice'
    created_by: string;
    category_name: string;
}

function useBillingCreditRows(credits: Credit[], creditCategories: BillingCreditCategories) {
    const opsUserIds = React.useMemo(() => _.uniq(_.compact(credits.map(c => c.created_by_user_id))), [credits]);
    const { data: { listUsers: users = [] } = {} } = useQuery(ListCreditCreateUsers_Query, {
        variables: { filter: { ids: opsUserIds } },
        skip: opsUserIds.length === 0,
    });

    return React.useMemo<PartnerCreditsTableRow[]>(() => {
        const usersById = _.keyBy(users, u => u.id);
        const creditCategoriesById = _.keyBy(creditCategories, c => c.id);
        return credits.map<PartnerCreditsTableRow>(credit => {
            const user = credit.created_by_user_id ? usersById[credit.created_by_user_id] : undefined;
            const created_by = user ? `${user.first_name} ${user.last_name}` : 'Manual (Unknown User)';

            const category = credit.credit_category_id ? creditCategoriesById[credit.credit_category_id] : undefined;
            const category_name = category ? category.name : 'Unknown';
            return { ...credit, created_by, category_name };
        });
    }, [credits, users, creditCategories]);
}

interface PartnerCreditsTableProps {
    practiceId: string;
    credits?: Credit[];
    onRefresh?: () => Promise<unknown>;
}

export const LegacyPartnerCreditsTable: React.VFC<PartnerCreditsTableProps> = ({ practiceId, onRefresh }) => {
    const { refetchCredits, credits } = useBillingDetailsContext();
    const refetch = onRefresh ?? refetchCredits;

    const { getOrganizationFromId, creditCategories } = useBillingDetailsContext();
    const tableRows = useBillingCreditRows(credits, creditCategories);

    const disabledCellStyle: (data: PartnerCreditsTableRow) => React.CSSProperties = r => {
        if (r.balance_cents === 0) {
            return { color: FlossPalette.LIGHT_GRAY };
        } else if (r.deleted_at) {
            return { color: FlossPalette.LIGHT_GRAY, textDecoration: 'line-through' };
        } else {
            return { color: FlossPalette.BLACK };
        }
    };

    const locationColumn: Column<PartnerCreditsTableRow>[] = [
        {
            name: 'Location',
            render: r => getOrganizationFromId(r.organization_id)?.name,
            bodyCellWrapStyle: disabledCellStyle,
        },
    ];
    return (
        <SimpleExpansionPanel
            ExpansionPanelProps={{
                variant: 'outlined',
                style: {
                    margin: '10px 10px 10px 0px !important',
                    width: 'inherit',
                },
            }}
            ExpansionPanelSummaryProps={{
                style: {
                    height: '56px',
                },
            }}
            title={<Text variant={'h5'}>Credits</Text>}
        >
            <MUITable<PartnerCreditsTableRow>
                title={' '}
                data={tableRows}
                displayOptions={{ elevation: 0, download: true, sort: true, search: true, filterValues: true }}
                columns={[
                    {
                        name: 'Created',
                        type: 'date',
                        render: 'created_at',
                        defaultSort: 'asc',
                        bodyCellWrapStyle: disabledCellStyle,
                    },
                    ...locationColumn,
                    { name: 'Created By', render: 'created_by', bodyCellWrapStyle: disabledCellStyle },
                    { name: 'Category', render: 'category_name', bodyCellWrapStyle: disabledCellStyle },
                    { name: 'Description', render: 'description', bodyCellWrapStyle: disabledCellStyle },
                    {
                        name: 'Balance',
                        type: 'currency',
                        field: 'balance_cents',
                        render: r => {
                            if (r.balance_cents === 0 || !!r.deleted_at) {
                                return Format.currency(r.balance_cents);
                            }
                            return (
                                <DeleteInvoiceCredit
                                    initial_credit_balance={r.balance_cents}
                                    credit_id={r.id}
                                    partner_id={practiceId}
                                    refetch={refetch}
                                    deleted_at={r.deleted_at ?? null}
                                    description={r.description}
                                />
                            );
                        },
                        bodyCellWrapStyle: disabledCellStyle,
                    },
                    {
                        name: 'Expiration',
                        type: 'date',
                        render: r => {
                            const expires = r.deleted_at ?? r.expires;
                            if (!!expires) {
                                return dayjs(r.deleted_at ?? r.expires).format(`MM/DD/YY`);
                            }
                            return null;
                        },
                        bodyCellWrapStyle: disabledCellStyle,
                    },
                ]}
                actions={{
                    global: [
                        { icon: 'refresh', position: 'toolbar', onClick: refetch, tooltip: 'Refresh' },
                        {
                            onClick: () => {},
                            icon: () => <CreateBillingCreditAction practiceId={practiceId} refetchCredits={refetch} />,
                            position: 'toolbar',
                        },
                    ],
                }}
            />
        </SimpleExpansionPanel>
    );
};
