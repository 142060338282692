import { FlossPalette, styled, IconButton } from '@orthly/ui-primitives';

/**
 * An icon button styled for the 'ghost' variant.
 * Figma: https://www.figma.com/design/HuCVKGaat3yxkP33weyVnz/Design-System?node-id=9140-38787&t=tePjZHPMqUrbe77U-0
 **/
export const GhostIconButton = styled(IconButton)({
    border: 'none',
    padding: 0,
    color: FlossPalette.PRIMARY_FOREGROUND,
    '&:hover': {
        border: 'none',
        color: FlossPalette.PRIMARY_PRESSED,
    },
    '&:active': {
        border: 'none',
        background: `${FlossPalette.PRIMARY_BACKGROUND} !important`,
    },
});
