import type { StepResultValue } from './types';
import { FlossPalette, Text, stylesFactory } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    stepResultRowValue: {
        maxHeight: 124,
        overflowY: 'auto',
        whiteSpace: 'pre-line',
    },
}));

interface SummaryStepResultRowValueProps {
    value: StepResultValue;
    originalValue?: StepResultValue;
    multiline?: boolean;
    stepResultRowClass?: string;
}

/**
 * If newValue != originalValue, returns `originalValue` in a styled span with
 * a strikethrough and a trailing right arrow. Otherwise returns null.
 */
function styledOriginalValue(newValue: string, originalValue?: string): React.ReactNode {
    if (newValue === originalValue) {
        return null;
    }
    return (
        <>
            <span style={{ textDecoration: 'line-through', opacity: 0.6, color: FlossPalette.GRAY }}>
                {originalValue || '(None)'}
            </span>
            <span style={{ fontWeight: 'bold', opacity: 0.6 }}>{` → `}</span>
        </>
    );
}

/**
 * Shows the current value of an item property (e.g. "Zirconia Monolithic" for the material row).
 * If `originalValue` is provided and differs from `value`, shows the original value with a strikethrough.
 *
 * Cribbed from flouride's `SummaryStepResultRowValue`.
 */
export const SummaryStepResultRowValue: React.VFC<SummaryStepResultRowValueProps> = ({
    value,
    originalValue,
    multiline,
    stepResultRowClass,
}) => {
    const classes = useStyles();

    if (typeof value === 'string') {
        return (
            <Text variant={'body2'} color={'BLACK'} className={stepResultRowClass ?? classes.stepResultRowValue}>
                {typeof originalValue === 'string' && styledOriginalValue(value, originalValue)}
                {value}
            </Text>
        );
    }

    if (!Array.isArray(value)) {
        return value;
    }

    const originalValueMap = Array.isArray(originalValue)
        ? _.fromPairs(originalValue.map(({ key, value }) => [key, value]))
        : {};

    return (
        <Text variant={'body2'} color={'GRAY'} className={stepResultRowClass ?? classes.stepResultRowValue}>
            {value.map((entry, idx) => {
                return (
                    <div key={entry.key}>
                        {entry.key}:{multiline ? <br /> : ' '}
                        <Text component={'span'} variant={'body2'} color={'BLACK'}>
                            {styledOriginalValue(entry.value, originalValueMap[entry.key])}
                            {entry.value}
                        </Text>
                        {idx < value.length - 1 ? '; ' : ''}
                    </div>
                );
            })}
        </Text>
    );
};
