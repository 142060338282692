import { useFirebaseFileDownload } from '../../hooks';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { FlossPalette, Text, stylesFactory, Chip, Grid, CheckIcon, ChevronRight } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    bannerRoot: {
        backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
        color: FlossPalette.WHITE,
        cursor: 'pointer',
        height: 40,
        padding: '8px 16px',
        width: '100%',
    },
    bannedRootRounded: {
        borderRadius: 8,
    },
    pillIcon: {
        color: FlossPalette.PRIMARY_FOREGROUND,
        height: 20,
        width: 20,
    },
    pillRoot: {
        background: FlossPalette.TAN,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        borderRadius: 8,
        margin: 'auto 0',
    },
}));

function hasOrderBeenPrepped(order?: Pick<LabsGqlLabOrderFragment, 'fulfillment_workflow'>): boolean {
    const activeTask = order?.fulfillment_workflow.active_task;
    return activeTask?.__typename === 'InternalDesignWorkflowTask'
        ? !!activeTask.configuration.prepped_design_file_path
        : false;
}

export const OrderDesignPreppedPill: React.VFC<{ order?: Pick<LabsGqlLabOrderFragment, 'fulfillment_workflow'> }> = ({
    order,
}) => {
    const classes = useStyles();

    if (!hasOrderBeenPrepped(order)) {
        return null;
    }

    return (
        <Chip
            icon={<CheckIcon className={classes.pillIcon} />}
            label={
                <Text variant={'body2'} medium color={'PRIMARY_FOREGROUND'}>
                    Case Prepped
                </Text>
            }
            className={classes.pillRoot}
        />
    );
};

interface OrderDesignPreppedBannerProps {
    order?: Pick<LabsGqlLabOrderFragment, 'fulfillment_workflow'>;
    variant?: 'rounded' | 'square';
    latestDesignFilePath?: string;
}

export const OrderDesignPreppedBanner: React.VFC<OrderDesignPreppedBannerProps> = ({
    order,
    variant,
    latestDesignFilePath,
}) => {
    const classes = useStyles();

    const designPathBasename = latestDesignFilePath?.split('/').pop();
    const { execute: downloadFile } = useFirebaseFileDownload(latestDesignFilePath, designPathBasename);

    if (!hasOrderBeenPrepped(order) || !latestDesignFilePath) {
        return null;
    }

    return (
        <Grid
            container
            direction={'row'}
            className={clsx(classes.bannerRoot, variant === 'rounded' ? classes.bannedRootRounded : undefined)}
            onClick={downloadFile}
        >
            <Grid item>
                <Text variant={'body2'} medium color={'WHITE'}>
                    This case has already been prepped
                </Text>
            </Grid>
            <Grid item xs />
            <Grid item>
                <Text variant={'body2'} medium color={'WHITE'}>
                    Download Case
                </Text>
            </Grid>
            <Grid item>
                <ChevronRight />
            </Grid>
        </Grid>
    );
};
