import type { StepResultValue, SummaryItemRow } from '../components/types';
import { getGroupPreferences } from './getGroupPreferences';
import type { IOrderItemShade, ICartItemV2OfType, ICartImplantItem, ICartItemV2DTOWithId } from '@orthly/items';
import { CartItemV2Utils, ItemDataFieldUtils, LabOrderItemSKUType, shadeToMap } from '@orthly/items';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import { Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

// A collection of SummaryItemRows for various item attributes.
export interface SummaryRows {
    teeth: SummaryItemRow;
    material: SummaryItemRow;
    // Not all items require shade, e.g. when the material is a metal.
    shade?: SummaryItemRow;
    itemDataFields?: SummaryItemRow;
    // We don't include preferences if there are no differences from
    // the doctor's defaults.
    preferences?: SummaryItemRow;
    implantInfo?: SummaryItemRow;
}

function shadeDisplayValue(shades: IOrderItemShade[]): SummaryItemRow['value'] {
    if (shades.length === 0) {
        return (
            <Text variant={'body2'} color={'ATTENTION'}>
                Please make a shade selection
            </Text>
        );
    }
    const shadeMap = shadeToMap(shades);
    return _.compact([
        shadeMap.base && { key: 'Base', value: shadeMap.base },
        shadeMap.gingival && { key: 'Gingival', value: shadeMap.gingival },
        shadeMap.incisal && { key: 'Incisal', value: shadeMap.incisal },
        shadeMap.stump && { key: 'Stump', value: shadeMap.stump },
    ]);
}

const implantInfoDisplayValue = (item: ICartImplantItem): SummaryItemRow['value'] => {
    return _.compact([
        item.unit.implant_metadata.relationship && {
            key: 'Relationship',
            value: item.unit.implant_metadata.relationship,
        },
        item.unit.abutment.material && {
            key: 'Abutment Material',
            value: item.unit.abutment.material,
        },
        item.unit.implant_metadata.manufacturer && {
            key: 'Manufacturer',
            value: item.unit.implant_metadata.manufacturer,
        },
        item.unit.implant_metadata.system && {
            key: 'System',
            value: item.unit.implant_metadata.system,
        },
        item.unit.implant_metadata.connection_size && {
            key: 'Platform Size',
            value: item.unit.implant_metadata.connection_size,
        },
    ]);
};

const getImplantInfoSummaryRowItem = (
    item: ICartItemV2DTOWithId,
    originalItem: ICartItemV2DTOWithId,
): SummaryItemRow | undefined => {
    if (
        !(
            CartItemV2Utils.itemIsType(item, [LabOrderItemSKUType.Implant]) &&
            CartItemV2Utils.itemIsType(originalItem, [LabOrderItemSKUType.Implant])
        )
    ) {
        return undefined;
    }
    return {
        title: 'Implant Info',
        value: implantInfoDisplayValue(item),
        originalValue: implantInfoDisplayValue(originalItem),
        multiline: true,
    };
};

const getItemDataFieldsSummaryRowItem = <SKU extends LabOrderItemSKUType>(
    item: ICartItemV2OfType<SKU>,
    originalItem: ICartItemV2OfType<SKU>,
): SummaryItemRow | undefined => {
    const allDataFields = _.uniqBy(
        [...ItemDataFieldUtils.getItemDataFields(item), ...ItemDataFieldUtils.getItemDataFields(originalItem)],
        field => field.id,
    );
    if (!allDataFields.length) {
        return undefined;
    }

    const getValue = (i: ICartItemV2OfType<SKU>): StepResultValue =>
        allDataFields.map(field => ({ key: field.label, value: field.getHumanReadableValue(i) ?? '' }));

    return {
        title: 'Item Details',
        value: getValue(item),
        originalValue: getValue(originalItem),
        multiline: true,
    };
};

export function summaryRowsForGroup(
    group: ArrayMin1<ICartItemV2DTOWithId>,
    originalItemMap: Record<string, ICartItemV2DTOWithId>,
    opts: {
        editCallbacks?: { [k in keyof SummaryRows]?: () => void };
    } = {},
): SummaryRows {
    const { editCallbacks } = opts;
    const originalGroup = _.compact(group.map(item => originalItemMap[item.id]));

    const [firstItem] = group;
    const [originalFirstItem] = originalGroup;

    const groupUnns = CartItemV2Utils.getItemGroupUniqueUNNs(group);

    const preferences = getGroupPreferences(group);
    const originalPreferences = getGroupPreferences(originalGroup);

    const implantInfo = originalFirstItem && getImplantInfoSummaryRowItem(firstItem, originalFirstItem);
    const itemDataFields =
        originalFirstItem && firstItem.sku === originalFirstItem.sku
            ? getItemDataFieldsSummaryRowItem(firstItem, originalFirstItem)
            : undefined;

    return {
        teeth: {
            title: groupUnns.length === 1 ? 'Tooth' : 'Teeth',
            value: CartItemV2Utils.getUnnsDisplay(groupUnns),
            originalValue: CartItemV2Utils.getUnnsDisplay(CartItemV2Utils.getItemGroupUniqueUNNs(originalGroup)),
            onEditClick: editCallbacks?.teeth,
        },
        material: {
            title: 'Material',
            value: CartItemV2Utils.getAllMaterials(firstItem)[0] ?? '',
            originalValue: originalFirstItem && CartItemV2Utils.getAllMaterials(originalFirstItem)[0],
            onEditClick: editCallbacks?.material,
        },
        shade: CartItemV2Utils.itemRequiresShade(firstItem)
            ? {
                  title: 'Shade',
                  value: shadeDisplayValue(firstItem.shades),
                  originalValue: originalFirstItem ? shadeDisplayValue(originalFirstItem.shades) : '',
                  onEditClick: editCallbacks?.shade,
              }
            : undefined,
        itemDataFields: itemDataFields && { ...itemDataFields, onEditClick: editCallbacks?.itemDataFields },
        preferences:
            preferences.length || originalPreferences.length
                ? {
                      title: 'Preferences',
                      value: preferences,
                      originalValue: originalPreferences,
                      onEditClick: editCallbacks?.preferences,
                      multiline: true,
                  }
                : undefined,
        implantInfo: implantInfo && { ...implantInfo, onEditClick: editCallbacks?.implantInfo },
    };
}
