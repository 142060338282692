import type { ManualInvoiceItemEditProps } from './ManualInvoiceItem.utils';
import { useManualInvoiceStyles } from './ManualInvoiceItem.utils';
import { ManualInvoiceItemDelete } from './ManualInvoiceItemDeleteModal';
import { useUpdateRegisteredInvoiceItemMutation } from '@orthly/graphql-react';
import { LabsGqlOneTimeChargeCategory } from '@orthly/graphql-schema';
import type { SimpleSelectOption } from '@orthly/ui';
import { LoadBlocker, RootActionDialog, SimpleInput, SimpleSelect, useChangeSubmissionFn } from '@orthly/ui';
import { Text, FormControl, Grid, InputAdornment, Button } from '@orthly/ui-primitives';
import React from 'react';

export const ManualInvoiceItemEdit: React.VFC<ManualInvoiceItemEditProps> = ({
    invoiceItem,
    setOpenManualChargeEdit,
    refetch,
}) => {
    const classes = useManualInvoiceStyles();
    const [openManualChargeDelete, setOpenManualChargeDelete] = React.useState<boolean>(false);
    const [invoiceItemCategory, setInvoiceItemCategory] = React.useState<string>(
        invoiceItem?.subcategory as LabsGqlOneTimeChargeCategory,
    );
    const [invoiceItemAmount, setInvoiceItemAmount] = React.useState<string>(
        `${(invoiceItem?.amount_cents ?? 0) / 100}`,
    );
    const [invoiceItemDescription, setInvoiceItemDescription] = React.useState<string>(invoiceItem?.description ?? '');
    const [mtnSubmitter] = useUpdateRegisteredInvoiceItemMutation();
    const { submit: updateItem, submitting: updatingItem } = useChangeSubmissionFn(mtnSubmitter, {
        onSuccess: async () => {
            await refetch();
            setOpenManualChargeDelete(false);
            setOpenManualChargeEdit(false);
        },
        successMessage: () => ['Successfully updated invoice item', {}],
    });
    const categoryOptions: SimpleSelectOption[] = Object.keys(LabsGqlOneTimeChargeCategory).map(category => ({
        value: category,
    }));

    return (
        <>
            <RootActionDialog
                open={openManualChargeDelete}
                setOpen={setOpenManualChargeDelete}
                loading={false}
                title={'Delete invoice item'}
                subtitle={'This action cannot be reversed. Are you sure you want to proceed?'}
                content={
                    <ManualInvoiceItemDelete
                        invoiceItem={invoiceItem}
                        setOpenManualChargeEdit={setOpenManualChargeEdit}
                        setOpenManualChargeDelete={setOpenManualChargeDelete}
                        refetch={refetch}
                    />
                }
                hideButton
                showCloseButton
                onClose={() => {
                    setOpenManualChargeDelete(false);
                    setOpenManualChargeEdit(false);
                }}
            />
            <LoadBlocker blocking={updatingItem}>
                <Grid container spacing={2} className={classes.contentBody}>
                    <Grid item xs={6}>
                        <Text variant={'body2'} medium>
                            Category
                        </Text>
                        <FormControl variant={'standard'} fullWidth>
                            <SimpleSelect
                                label={''}
                                options={categoryOptions}
                                value={invoiceItemCategory}
                                onChange={value => value && setInvoiceItemCategory(value)}
                                SelectProps={{ className: classes.textInput, variant: 'standard' }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <Text variant={'body2'} medium>
                            Amount
                        </Text>
                        <FormControl variant={'standard'} fullWidth>
                            <SimpleInput
                                TextFieldProps={{
                                    InputProps: {
                                        className: classes.textInput,
                                        startAdornment: (
                                            <InputAdornment position={'start'}>
                                                <Text variant={'body2'} color={'BLACK'}>
                                                    $
                                                </Text>
                                            </InputAdornment>
                                        ),
                                        inputProps: { className: classes.amountInput },
                                    },
                                }}
                                value={`${invoiceItemAmount}`}
                                label={''}
                                onChange={value => setInvoiceItemAmount(value ?? '')}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item container>
                        <Text variant={'body2'} medium>
                            Description
                        </Text>
                        <FormControl variant={'standard'} fullWidth>
                            <SimpleInput
                                TextFieldProps={{ InputProps: { className: classes.textInput } }}
                                label={''}
                                onChange={value => setInvoiceItemDescription(value ?? '')}
                                value={invoiceItemDescription}
                            />
                        </FormControl>
                        <Text variant={'body2'} color={'GRAY'}>
                            This is visible on the practice's invoice
                        </Text>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'space-between'}>
                    <Grid item>
                        <Button
                            variant={'ghost-warning'}
                            startIcon={'TrashIcon'}
                            onClick={() => {
                                setOpenManualChargeDelete(true);
                            }}
                        >
                            Delete
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant={'secondary'}
                            className={classes.cancelButton}
                            onClick={() => setOpenManualChargeEdit(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant={'primary'}
                            onClick={() => {
                                void updateItem({
                                    variables: {
                                        data: {
                                            id: invoiceItem?.recurring_item_id ?? '',
                                            category: invoiceItemCategory as LabsGqlOneTimeChargeCategory,
                                            description: invoiceItemDescription,
                                            price_cents: Math.round(parseFloat(invoiceItemAmount) * 100),
                                        },
                                    },
                                });
                            }}
                        >
                            Update
                        </Button>
                    </Grid>
                </Grid>
            </LoadBlocker>
        </>
    );
};
