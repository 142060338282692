import type { EditOperation } from './EditingHistory.types';
import { isGeometryEditingOperation, isInsertionAxisOperation, isDeformOperation } from './EditingHistory.utils';
import { AttributeName } from '@orthly/forceps';
import type { MorphPointsInternalData } from '@orthly/shared-types';
import { last } from 'lodash';

/**
 * Records and manipulates the history of editing operations on a model
 */
export class EditingHistory {
    // Sequence of operations that have been applied to the model
    private operations: EditOperation[] = [];
    // Points to the current operation, i.e. the current state of the model
    private index: number = -1;

    /**
     * Whether there are any operations to undo
     */
    get hasUndo(): boolean {
        return this.index >= 0;
    }

    /**
     * Whether there are any operations to redo
     */
    get hasRedo(): boolean {
        return this.index < this.operations.length - 1;
    }

    /**
     * The current geometry of the model, i.e. the geometry most recently applied and not undone
     */
    get currentGeometry(): THREE.BufferGeometry | undefined {
        return this.currentOperations.findLast(isGeometryEditingOperation)?.geometry;
    }

    /**
     * The current morph points of the model, i.e. the morph points most recently applied and not undone
     */
    get currentMorphPoints(): MorphPointsInternalData | undefined {
        return this.currentOperations.findLast(isDeformOperation)?.morphPoints;
    }

    /**
     * The current insertion axis orientation of the model, i.e. the insertion axis most recently applied and not undone
     */
    get currentInsertionOrientation(): THREE.Quaternion | undefined {
        return this.currentOperations.findLast(isInsertionAxisOperation)?.insertionOrientation;
    }

    /**
     * The curtains geometry reflecting the current insertion axis
     */
    get currentCurtainsGeometry(): THREE.BufferGeometry | undefined {
        return this.currentOperations.findLast(isInsertionAxisOperation)?.curtainsGeometry;
    }

    /**
     * The curtains distance attribute reflecting the current curtains geometry
     */
    get currentCurtainsDistance(): THREE.BufferAttribute | undefined {
        const op = this.currentOperations.findLast(isInsertionAxisOperation);
        if (!op) {
            return;
        }

        return op.type === 'insertionAxisTemp'
            ? op.curtainsDistances
            : (op.geometry.getAttribute(AttributeName.CurtainsDistance) as THREE.BufferAttribute);
    }

    /**
     * Whether there are any uncommitted insertion axis operations in the editing history
     */
    get hasUncommittedInsertionAxisOperations(): boolean {
        return this.currentOperations.some(op => op.type === 'insertionAxisTemp');
    }

    /**
     * Pushes a new operation onto the history, discarding any redo operations
     * @param op The operation to append to the history
     */
    push(op: EditOperation): void {
        this.index++;
        this.operations.splice(this.index, Infinity, op);
    }

    /**
     * Undoes the current operation, if available, and returns the undone operation
     * @throws If there are no operations to undo
     * @returns The undone operation
     */
    undo(): EditOperation {
        if (!this.hasUndo) {
            throw new Error('No undo operations');
        }

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const undone = this.operations[this.index]!;

        this.index--;
        return undone;
    }

    /**
     * Redoes the next operation, if available, and returns the redone operation
     * @throws If there are no operations to redo
     * @returns The redone operation
     */
    redo(): EditOperation {
        if (!this.hasRedo) {
            throw new Error('No redo operations');
        }

        this.index++;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.operations[this.index]!;
    }

    /**
     * Converts the tailing uncommitted insertion axis operations into a single committed insertion axis operation
     * @param geometry The geometry resulting from the committed insertion axis adjustment
     */
    commitInsertionAxis(geometry: THREE.BufferGeometry): void {
        const currentOps = this.currentOperations;
        const lastOp = last(currentOps);
        if (lastOp?.type !== 'insertionAxisTemp') {
            return;
        }

        // Roll back to before the sequence of uncommitted insertion axis operations and push a single committed
        // insertion axis operation.
        this.index = currentOps.findLastIndex(op => op.type !== 'insertionAxisTemp');
        this.push({
            type: 'insertionAxisCommitted',
            geometry,
            insertionOrientation: lastOp.insertionOrientation,
            curtainsGeometry: lastOp.curtainsGeometry,
        });
    }

    /**
     * Cancels the tailing uncommitted insertion axis operations
     */
    cancelInsertionAxis(): void {
        const lastOp = last(this.operations);
        if (lastOp?.type === 'insertionAxisTemp') {
            // Roll back to before the sequence of uncommitted insertion axis operations
            this.index = this.operations.findLastIndex(op => op.type !== 'insertionAxisTemp');
            this.operations.splice(this.index + 1, Infinity);
        }
    }

    /**
     * The history of edit operations reflected in the current model state, i.e. excluding undone operations
     */
    private get currentOperations(): EditOperation[] {
        return this.operations.slice(0, this.index + 1);
    }
}
