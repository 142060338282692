import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import { LabsGqlOrderItemArch } from '@orthly/graphql-schema';
import { FrontBiteIcon } from '@orthly/ui';
import { stylesFactory, Text, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    subSection: {
        marginBottom: '0.1rem',
    },
    subSectionTitle: {
        fontSize: '1rem !important',
        fontWeight: 'bold',
    },
    section: {
        flexDirection: 'column',
        padding: '0.25rem 0 0.75rem',
    },
    sectionTitle: {
        fontSize: '0.9rem !important',
        fontWeight: 'bold',
    },
    itemWrapper: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '0.25rem',
        fontSize: '0.3rem !important',
    },
    mediumFont: {
        fontSize: '0.75rem !important',
    },
}));

export const TryInFeedbackNotes: React.VFC<{
    feedbackDetails: LabsGqlLabOrderForLabSlipFragment['try_in_feedback_details'];
}> = ({ feedbackDetails }) => {
    const classes = useStyles();

    if (!feedbackDetails?.length) {
        return null;
    }

    const archGroupedDetails = _.groupBy(feedbackDetails, detail => detail.arch);

    return (
        <Grid container className={classes.subSection}>
            <Grid container spacing={1}>
                <Grid item>
                    <FrontBiteIcon />
                </Grid>
                <Grid item>
                    <Text variant={'h5'} className={classes.subSectionTitle}>
                        Feedback from Previous Order
                    </Text>
                </Grid>
            </Grid>
            <Grid container>
                {[LabsGqlOrderItemArch.Dual, LabsGqlOrderItemArch.Upper, LabsGqlOrderItemArch.Lower].map(arch => {
                    const archDetails = archGroupedDetails[arch];
                    if (!archDetails?.length) {
                        return null;
                    }

                    return (
                        <Grid container key={arch} className={classes.section}>
                            <Text variant={'body2'} className={classes.sectionTitle}>
                                {arch === LabsGqlOrderItemArch.Dual ? 'General' : `${arch} Arch`}
                            </Text>
                            {archDetails.map(({ category, details }) => (
                                <Grid container key={category} className={classes.itemWrapper}>
                                    <Text variant={'body2'} medium className={classes.mediumFont}>
                                        {category}
                                    </Text>
                                    <Text variant={'body2'} className={classes.mediumFont}>
                                        {details}
                                    </Text>
                                </Grid>
                            ))}
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};
