import type { Filter } from '../../components/MultiFilterBuilder';
import { MultiFilterBuilder, GqlFragmentToFilter } from '../../components/MultiFilterBuilder';
import { ExistingImagesList } from '../Orders/screens/OrderDetail/components/OrderQcSidebar/OrderQcSidebar';
import { useQCItemConfigs } from './useQCItemConfigs';
import type {
    LabsGqlArchiveQcItemConfigMutationVariables,
    LabsGqlCreateQcItemConfigMutationVariables,
    LabsGqlEditQcItemConfigMutationVariables,
    LabsGqlQcItemConfigFragment,
} from '@orthly/graphql-operations';
import {
    useArchiveQcItemConfigMutation,
    useCreateQcItemConfigMutation,
    useEditQcItemConfigMutation,
} from '@orthly/graphql-react';
import { LabsGqlFilterCombinator } from '@orthly/graphql-schema';
import { MUITable } from '@orthly/mui-table';
import { getFullStoragePath, MfgStorageConfigs } from '@orthly/shared-types';
import type { ButtonProps } from '@orthly/ui';
import { LoadBlocker, OrthlyBrowserConfig, RootActionDialog, SimpleInput, useChangeSubmissionFn } from '@orthly/ui';
import { Button, Text, Grid } from '@orthly/ui-primitives';
import { FileUploaderBulk } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

type CreateQCItemConfigVars = LabsGqlCreateQcItemConfigMutationVariables['data'];

export function useCreateQCItemConfig(onSuccess?: () => void) {
    const [submitMtn] = useCreateQcItemConfigMutation();
    const mtnSubmitter = (data: CreateQCItemConfigVars) => submitMtn({ variables: { data } });
    return useChangeSubmissionFn<any, [CreateQCItemConfigVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['QC Item Config Created!', {}],
        onSuccess: async () => {
            await onSuccess?.();
        },
    });
}

type EditQCItemConfigVars = LabsGqlEditQcItemConfigMutationVariables['data'];

export function useEditQCItemConfig(onSuccess?: () => void) {
    const [submitMtn] = useEditQcItemConfigMutation();
    const mtnSubmitter = (data: EditQCItemConfigVars) => submitMtn({ variables: { data } });
    return useChangeSubmissionFn<any, [EditQCItemConfigVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['QC Item Config Edited!', {}],
        onSuccess: async () => {
            await onSuccess?.();
        },
    });
}

// useCreateOrEditQCItemConfig uses the edit mutation if `id` is provided, otherwise the create mutation.
function useCreateOrEditQCItemConfig(id?: string, onSuccess?: () => void) {
    const create = useCreateQCItemConfig(onSuccess);
    const edit = useEditQCItemConfig(onSuccess);
    if (!id) {
        return create;
    }
    return {
        ...edit,
        submit: (data: CreateQCItemConfigVars) =>
            edit.submit({
                id,
                ...data,
            }),
    };
}

interface QCItemConfigBuilderProps {
    initialConfig?: LabsGqlQcItemConfigFragment;
    open: boolean;
    setOpen: (open: boolean) => void;
    OpenButton: React.FC<ButtonProps>;
    onSuccess?: () => void;
}

const QCItemConfigBuilder: React.FC<QCItemConfigBuilderProps> = props => {
    const mode: 'create' | 'edit' = props.initialConfig ? 'edit' : 'create';
    // Editable state:
    const [title, setTitle] = React.useState<string>(props.initialConfig?.title ?? ``);
    const [description, setDescription] = React.useState<string>(props.initialConfig?.description ?? ``);
    const [existingAttachments, setExistingAttachments] = React.useState<string[]>(
        props.initialConfig?.attachments ?? [],
    );
    const [newAttachments, setNewAttachments] = React.useState<string[]>([]);
    const [filters, setFilters] = React.useState<Filter[]>(
        props.initialConfig?.filters
            ? props.initialConfig?.filters.map(GqlFragmentToFilter)
            : [{ criteria: [{}], changed: false }],
    );
    // The mutation we use depends on the mode. Reset form state on success.
    const { submit, submitting } = useCreateOrEditQCItemConfig(props.initialConfig?.id, () => {
        props.onSuccess?.();
    });
    // Derived statuses:
    const allCriteriaComplete = React.useMemo(
        () => _.every(filters, f => f.criteria.length === f.completedCriteria?.length),
        [filters],
    );
    const changed = React.useMemo(
        () =>
            _.some(filters, f => f.changed) ||
            (!!title && title !== props.initialConfig?.title) ||
            (!!description && description !== props.initialConfig?.description) ||
            existingAttachments.length !== props.initialConfig?.attachments?.length ||
            newAttachments.length > 0,
        [props.initialConfig, filters, title, description, existingAttachments, newAttachments],
    );
    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, MfgStorageConfigs.qcItemsConfig);

    return (
        <RootActionDialog
            loading={submitting}
            onClose={() => {
                props.setOpen(!changed ? false : !window.confirm('Changes have not been saved. Continue?'));
            }}
            open={props.open}
            dialogProps={{ maxWidth: 'md' }}
            setOpen={props.setOpen}
            title={`${mode === 'create' ? 'Create' : 'Edit'} Config`}
            titleAction={
                <Button
                    variant={'primary'}
                    disabled={!allCriteriaComplete || title === '' || description === '' || !changed}
                    onClick={() => {
                        void submit({
                            title,
                            description,
                            attachments: [...existingAttachments, ...newAttachments],
                            filters: filters.map(f => ({
                                criteria: f.completedCriteria ?? [],
                                combinator: LabsGqlFilterCombinator.And,
                            })),
                        });
                    }}
                >
                    {mode === 'create' ? 'Create' : 'Submit'}
                </Button>
            }
            content={
                <Grid container direction={'column'}>
                    <Text variant={'h6'}>{'Details'}</Text>
                    <Text variant={'body2'} style={{ paddingBottom: '8px' }}>
                        {'Title & Description are displayed in the lab quality control checklist.'}
                    </Text>
                    <SimpleInput label={'Title'} onChange={v => setTitle(v ?? '')} value={title} variant={'standard'} />
                    <Grid style={{ paddingBottom: '12px' }} />
                    <SimpleInput
                        label={'Description'}
                        onChange={v => setDescription(v ?? '')}
                        value={description}
                        variant={'standard'}
                    />
                    <Grid style={{ paddingBottom: '12px' }} />
                    <ExistingImagesList
                        images={existingAttachments}
                        remove={index => setExistingAttachments(existingAttachments.filter((_, i) => i !== index))}
                    />
                    <FileUploaderBulk
                        autoSubmit
                        elevation={0}
                        storagePathConfig={storagePathConfig}
                        paperStyle={{ padding: '0 0 8px' }}
                        onComplete={results => results && setNewAttachments(results.map(r => r.uploadedPath))}
                        onReset={() => setNewAttachments([])}
                        prependTimestampToFilename={true}
                    />
                    <Text style={{ paddingTop: '8px' }} variant={'h6'}>
                        {'Filters'}
                    </Text>
                    <Text variant={'body2'}>
                        {'This QC Item will be added to any orders that pass at least one of the filters below.'}
                    </Text>
                    <MultiFilterBuilder filters={filters} setFilters={setFilters} />
                </Grid>
            }
            buttonText={''}
            CustomButton={props.OpenButton}
        />
    );
};

type ArchiveQCItemConfigVars = LabsGqlArchiveQcItemConfigMutationVariables['data'];

export function useArchiveQCItemConfig(onSuccess?: () => void) {
    const [submitMtn] = useArchiveQcItemConfigMutation();
    const mtnSubmitter = (data: ArchiveQCItemConfigVars) => submitMtn({ variables: { data } });
    return useChangeSubmissionFn<any, [ArchiveQCItemConfigVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['QC Item Config Updated!', {}],
        onSuccess: async () => {
            await onSuccess?.();
        },
    });
}

interface QCItemConfigDetailPanelProps {
    itemConfig: LabsGqlQcItemConfigFragment;
    onConfigEditSuccess: () => void;
}

const QCItemConfigDetailPanel: React.FC<QCItemConfigDetailPanelProps> = props => {
    const archived = props.itemConfig.archived_at !== null;
    const [open, setOpen] = React.useState<boolean>(false);
    const { submit: submitArchive, submitting: submittingArchive } = useArchiveQCItemConfig(() => {
        props.onConfigEditSuccess();
    });

    return (
        <LoadBlocker blocking={submittingArchive}>
            <Grid container style={{ padding: 0, maxWidth: 'calc(100vw - 73px)' }}>
                <Grid container item xs={10} style={{ padding: '10px' }} direction={'column'}>
                    <Text variant={'h6'}>{'Preview'}</Text>
                    <Text variant={'body2'} style={{ fontWeight: 'bold', paddingRight: '8px' }}>
                        {props.itemConfig.title}
                    </Text>
                    <Text variant={'body2'}>{props.itemConfig.description}</Text>
                </Grid>
                <Grid container item xs={2} direction={'column'} style={{ padding: 10 }} wrap={'nowrap'}>
                    <Button
                        style={{ marginBottom: '8px' }}
                        variant={'primary'}
                        onClick={() => {
                            void submitArchive({ id: props.itemConfig.id, archived: !archived });
                        }}
                    >
                        {archived ? 'Unarchive' : 'Archive'}
                    </Button>
                    <QCItemConfigBuilder
                        open={open}
                        setOpen={setOpen}
                        OpenButton={() => (
                            <Button variant={'primary'} onClick={() => setOpen(true)}>
                                {'Edit'}
                            </Button>
                        )}
                        initialConfig={props.itemConfig}
                        onSuccess={props.onConfigEditSuccess}
                    />
                </Grid>
            </Grid>
        </LoadBlocker>
    );
};

export const QCItemConfigForm: React.FC = () => {
    // TODO(albertl) `true` should NOT be hardcoded. There's a thingy at the top of the
    // UI that says whether archived items should be filtered, that should be passed in here.
    const { configs, refetch } = useQCItemConfigs({ includeArchived: true, skip: false });
    const [createOpen, setCreateOpen] = React.useState<boolean>(false);
    // Change the key for the config create dialog on success to force the component to remount, refreshing its state.
    const [createKey, setCreateKey] = React.useState<string>(Date.now().toString());
    return (
        <Grid container>
            <MUITable<LabsGqlQcItemConfigFragment>
                title={'Quality Control Item Configurations'}
                data={configs}
                displayOptions={{
                    fixedSearch: true,
                    elevation: 0,
                    viewColumns: true,
                    filter: true,
                    sort: true,
                }}
                actions={{
                    global: [
                        { icon: 'refresh', position: 'toolbar', onClick: () => refetch?.().catch(console.error) },
                        {
                            icon: 'add',
                            position: 'toolbar',
                            tooltip: 'Create',
                            onClick: () => {
                                setCreateOpen(true);
                            },
                        },
                    ],
                }}
                rowOptions={{ rowHover: true }}
                eventHooks={{ onRowClick: (row, actions) => actions.toggleDetailPanel(row) }}
                DetailPanel={detailProps => (
                    <QCItemConfigDetailPanel
                        itemConfig={detailProps.data}
                        onConfigEditSuccess={async () => {
                            await refetch?.();
                        }}
                    />
                )}
                columns={[
                    { name: 'Title', render: 'title' },
                    { name: 'Filter Count', type: 'numeric', render: row => row.filters.length },
                    { name: 'Attachments', type: 'string', render: row => row.attachments?.length },
                    {
                        name: 'Archived',
                        type: 'boolean',
                        render: item => item.archived_at !== null,
                        filterOptions: { defaultValues: ['false'], exact: true, type: 'dropdown' },
                    },
                    { name: 'Last Updated', type: 'datetime', render: 'updated_at' },
                ]}
            />
            <QCItemConfigBuilder
                key={createKey}
                open={createOpen}
                setOpen={setCreateOpen}
                OpenButton={() => null}
                onSuccess={async () => {
                    await refetch?.();
                    setCreateKey(Date.now().toString());
                    setCreateOpen(false);
                }}
            />
        </Grid>
    );
};
