import { LabsGqlDesignOrderNoteCategory } from '@orthly/graphql-schema';

export const isOcclusalAnatomyOptionDisabled = (
    option: LabsGqlDesignOrderNoteCategory,
    selectionsContain: (options: LabsGqlDesignOrderNoteCategory[]) => boolean,
): boolean => {
    switch (option) {
        case LabsGqlDesignOrderNoteCategory.CorrectCuspPlacementBuccal:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.CorrectCuspPlacementLingual]);

        case LabsGqlDesignOrderNoteCategory.BringCuspsIntoOcclusionBuccal:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.BringCuspsIntoOcclusionLingual]);

        case LabsGqlDesignOrderNoteCategory.CorrectCuspPlacementLingual:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.CorrectCuspPlacementBuccal]);

        case LabsGqlDesignOrderNoteCategory.BringCuspsIntoOcclusionLingual:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.BringCuspsIntoOcclusionBuccal]);

        default:
            return false;
    }
};
