import { QC_MESHES_SHININESS } from '../ModelViewer/ModelMeshes';
import { MODEL_GRAY_COLOR, STANDARD_MESH_SHININESS } from '../ModelViewer/defaultModelColors';
import type { UndercutMaterialUniforms } from '../ModelViewer/materials/UndercutDepthShader';
import { applyShader, createDesignMeshUndercutMaterial } from '../ModelViewer/materials/UndercutDepthShader';
import { createDesignMeshShaderMaterial } from '../ModelViewer/materials/designMeshShaderMaterial.utils';
import type { DesignMeshShaderMaterialUniforms } from '../ModelViewer/materials/designMeshShaderMaterial.utils';
import { createDandyMeshPhysicalShader } from '../PrepMaterials/DandyMeshPhysicalShader';
import { createOcclusalHeatmapShader } from '../PrepMaterials/OcclusalShader';
import { DandyShaderMaterial } from '../PrepMaterials/dandyShaderMaterial';
import { CollisionsMeshMaterial } from './CollisionsMeshMaterial';
import * as THREE from 'three';

export const TRANSPARENT_OPACITY = 0.5;
export const OPAQUE_OPACITY = 1.0;

const DEFAULT_STONE_MATERIAL_PARAMS: THREE.MeshPhongMaterialParameters = {
    color: MODEL_GRAY_COLOR,
    specular: 0x111111,
    shininess: STANDARD_MESH_SHININESS,
    side: THREE.DoubleSide,
};

// Creates a material suitable for use on a jaw scan mesh
export function createScanMaterial(transparent: boolean, colorMap?: THREE.Texture): THREE.ShaderMaterial {
    const shaderParams = createOcclusalHeatmapShader({
        showHeatmap: false,
        opacity: transparent ? TRANSPARENT_OPACITY : OPAQUE_OPACITY,
        map: colorMap,
    });
    return new DandyShaderMaterial({
        ...shaderParams,
        side: THREE.DoubleSide,
        transparent,
        vertexColors: !colorMap,
    });
}

// Creates a monochromatic material suitable for use on a jaw scan mesh
export function createScanStoneMaterial(
    paramsIn: Omit<THREE.MeshPhongMaterialParameters, 'vertexColors'> = {},
): THREE.MeshPhongMaterial {
    const params: THREE.MeshPhongMaterialParameters = {
        ...DEFAULT_STONE_MATERIAL_PARAMS,
        ...paramsIn,
    };
    return new THREE.MeshPhongMaterial(params);
}

// Creates a material to use on the collisions mesh
export function createCollisionsMaterial(): CollisionsMeshMaterial {
    return new CollisionsMeshMaterial('black');
}

export function createCollisionsCurtainsMaterial(): THREE.MeshBasicMaterial {
    return new CollisionsMeshMaterial('purple');
}

const DEFAULT_RESTORATIVE_MATERIAL_PROPERTIES: THREE.MeshPhongMaterialParameters = {
    vertexColors: false,
    dithering: true,
    side: THREE.DoubleSide,
    wireframe: false,
    depthTest: true,
    depthWrite: true,
    transparent: false,
    opacity: OPAQUE_OPACITY,
    shininess: QC_MESHES_SHININESS,
};

// Creates a material for use on restorative meshes when displaying distance heatmaps
export function createHeatmapRestorativeMaterial(uniforms: DesignMeshShaderMaterialUniforms): THREE.MeshPhongMaterial {
    return createDesignMeshShaderMaterial(DEFAULT_RESTORATIVE_MATERIAL_PROPERTIES, uniforms);
}

export function createUndercutRestorativeMaterial(uniforms: UndercutMaterialUniforms): THREE.MeshPhongMaterial {
    return createDesignMeshUndercutMaterial(DEFAULT_RESTORATIVE_MATERIAL_PROPERTIES, uniforms);
}

export function createUndercutScanMaterial(
    uniforms: UndercutMaterialUniforms,
    colorMap?: THREE.Texture,
): THREE.ShaderMaterial {
    const shaderParams = createDandyMeshPhysicalShader({ map: colorMap });
    const material = new DandyShaderMaterial({ ...shaderParams, side: THREE.DoubleSide, vertexColors: !colorMap });
    return applyShader(material, uniforms);
}

export function createUndercutScanStoneMaterial(uniforms: UndercutMaterialUniforms): THREE.MeshPhongMaterial {
    return createDesignMeshUndercutMaterial(DEFAULT_STONE_MATERIAL_PARAMS, uniforms);
}

export function isColoredMaterial(material: THREE.Material): boolean {
    return material instanceof DandyShaderMaterial && material.map !== null;
}
