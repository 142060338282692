import moment from 'moment/moment';

export const format_timeline_date = (date: Date): string => {
    const moment_date = moment(date);
    const today = moment();
    const yesterday = moment().subtract(1, 'day');

    if (moment_date.isSame(today, 'day')) {
        return 'Today';
    }

    if (moment_date.isSame(yesterday, 'day')) {
        return 'Yesterday';
    }

    return moment_date.format(`ddd, MMM Do YYYY`); // e.g. Thu, Jul 22nd 2021
};
