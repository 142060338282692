import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlLabOrderStatus, LabsGqlCancellationReasonCode, LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';

export const getLabelForCancellationReasonCode = (code: LabsGqlCancellationReasonCode | null) => {
    switch (code) {
        case LabsGqlCancellationReasonCode.PatientCancelled:
            return 'Patient no longer wishes to proceed';
        case LabsGqlCancellationReasonCode.PatientPostponed:
            return 'Patient decided to postpone';
        case LabsGqlCancellationReasonCode.OrderTooLate:
            return 'Order arriving too late';
        case LabsGqlCancellationReasonCode.DuplicateOrder:
            return 'Duplicate order';
        case LabsGqlCancellationReasonCode.AnotherLab:
            return 'Doctor decided to order with another lab';
        case LabsGqlCancellationReasonCode.AnotherLabProductQuality:
            return 'Product Quality';
        case LabsGqlCancellationReasonCode.AnotherLabServiceQuality:
            return 'Service Quality';
        case LabsGqlCancellationReasonCode.AnotherLabOther:
        case LabsGqlCancellationReasonCode.Other:
        case null:
            return 'Other';
        // systemic cancellation reason codes
        case LabsGqlCancellationReasonCode.CancelAndResubmit:
            return 'Cancel & re-submit';
        case LabsGqlCancellationReasonCode.AutoCancelationAgingCase:
            return 'Auto cancelation: aging case';
        default:
            // Exhaustive check guard against future new cancellation reason codes
            code satisfies never;
    }
};

export const isReasonAnotherLabCancellation = (code?: LabsGqlCancellationReasonCode | null) =>
    !!code &&
    [
        LabsGqlCancellationReasonCode.AnotherLab,
        LabsGqlCancellationReasonCode.AnotherLabProductQuality,
        LabsGqlCancellationReasonCode.AnotherLabServiceQuality,
        LabsGqlCancellationReasonCode.AnotherLabOther,
    ].includes(code);

// @ts-ignore This unused method guards against future modifications to Lab Order Statuses.
export const getOrderStatusOrNullForWorkflowTaskType = (
    workflowTaskType: LabsGqlWorkflowTaskType,
): LabsGqlLabOrderStatus | null => {
    switch (workflowTaskType) {
        // needs review
        case LabsGqlWorkflowTaskType.ScanReview:
        case LabsGqlWorkflowTaskType.ReviewOrder:
        case LabsGqlWorkflowTaskType.InternalDesign:
        case LabsGqlWorkflowTaskType.DesignReview:
        case LabsGqlWorkflowTaskType.DesignReview2:
        case LabsGqlWorkflowTaskType.WaxupReview:
        case LabsGqlWorkflowTaskType.DesignPrep:
        case LabsGqlWorkflowTaskType.AutomateDesign:
        case LabsGqlWorkflowTaskType.AutomateReview:
        case LabsGqlWorkflowTaskType.ModelDesign:
        case LabsGqlWorkflowTaskType.InjectionMoldDesign:
        case LabsGqlWorkflowTaskType.ExternalDesignVerification:
        // Aligner planning tasks
        case LabsGqlWorkflowTaskType.ApproveAlignerFabrication:
        case LabsGqlWorkflowTaskType.FinalizeTreatmentPlanRequest:
        case LabsGqlWorkflowTaskType.SetTreatmentPlanRequestUrl:
        case LabsGqlWorkflowTaskType.ConvertTreatmentPlanRequest:
        case LabsGqlWorkflowTaskType.CreateTreatmentPlanRequest:
        case LabsGqlWorkflowTaskType.CreateExternalAlignerFulfillment:
            return LabsGqlLabOrderStatus.NeedsReview;
        // new
        case LabsGqlWorkflowTaskType.LabAcceptOrder:
            return LabsGqlLabOrderStatus.New;
        // fabrication
        case LabsGqlWorkflowTaskType.ExternalDesign:
        case LabsGqlWorkflowTaskType.ShipOrder:
        case LabsGqlWorkflowTaskType.SendToFloor:
            return LabsGqlLabOrderStatus.Fabrication;
        // on hold
        case LabsGqlWorkflowTaskType.ResolveHold:
        case LabsGqlWorkflowTaskType.DrReviewHold:
        case LabsGqlWorkflowTaskType.ResolveScanRejection:
            return LabsGqlLabOrderStatus.OnHold;
        // shipped
        case LabsGqlWorkflowTaskType.AcknowledgeDelivery:
            return LabsGqlLabOrderStatus.Shipped;
        // Needs Refabrication
        case LabsGqlWorkflowTaskType.ReturnOrder:
        case LabsGqlWorkflowTaskType.AcknowledgeReturn:
            return LabsGqlLabOrderStatus.NeedsRefabrication;
        // non-applicable
        case LabsGqlWorkflowTaskType.PullFromFloor:
            return null;
        default:
            workflowTaskType satisfies never;
            return null;
    }
};

// This method guards against future modifications to Lab Order Statuses.
export const getWorkflowTaskTypesForOrderStatus = (
    orderStatus: LabsGqlLabOrderStatus,
): Array<LabsGqlWorkflowTaskType> => {
    switch (orderStatus) {
        case LabsGqlLabOrderStatus.NeedsReview:
            return [
                LabsGqlWorkflowTaskType.ScanReview,
                LabsGqlWorkflowTaskType.ReviewOrder,
                LabsGqlWorkflowTaskType.InternalDesign,
                LabsGqlWorkflowTaskType.DesignReview,
                LabsGqlWorkflowTaskType.DesignReview2,
                LabsGqlWorkflowTaskType.WaxupReview,
                LabsGqlWorkflowTaskType.DesignPrep,
                LabsGqlWorkflowTaskType.AutomateDesign,
                LabsGqlWorkflowTaskType.AutomateReview,
                LabsGqlWorkflowTaskType.ModelDesign,
                LabsGqlWorkflowTaskType.InjectionMoldDesign,
                LabsGqlWorkflowTaskType.ExternalDesignVerification,
                // Aligner planning tasks
                LabsGqlWorkflowTaskType.ApproveAlignerFabrication,
                LabsGqlWorkflowTaskType.FinalizeTreatmentPlanRequest,
                LabsGqlWorkflowTaskType.SetTreatmentPlanRequestUrl,
                LabsGqlWorkflowTaskType.ConvertTreatmentPlanRequest,
                LabsGqlWorkflowTaskType.CreateTreatmentPlanRequest,
                LabsGqlWorkflowTaskType.CreateExternalAlignerFulfillment,
            ];
        case LabsGqlLabOrderStatus.New:
            return [LabsGqlWorkflowTaskType.LabAcceptOrder];
        case LabsGqlLabOrderStatus.Fabrication:
            return [
                LabsGqlWorkflowTaskType.ExternalDesign,
                LabsGqlWorkflowTaskType.ShipOrder,
                LabsGqlWorkflowTaskType.SendToFloor,
            ];
        case LabsGqlLabOrderStatus.OnHold:
            return [
                LabsGqlWorkflowTaskType.ResolveHold,
                LabsGqlWorkflowTaskType.DrReviewHold,
                LabsGqlWorkflowTaskType.ResolveScanRejection,
            ];
        case LabsGqlLabOrderStatus.Shipped:
            return [LabsGqlWorkflowTaskType.AcknowledgeDelivery];
        case LabsGqlLabOrderStatus.NeedsRefabrication:
            return [LabsGqlWorkflowTaskType.ReturnOrder, LabsGqlWorkflowTaskType.AcknowledgeReturn];
        case LabsGqlLabOrderStatus.Delivered:
        case LabsGqlLabOrderStatus.Cancelled:
            return [];
        default:
            orderStatus satisfies never;
            return [];
    }
};

export const isOrderPlacedOnHoldDuringStatus = (order: LabsGqlOrder, status: LabsGqlLabOrderStatus) => {
    if (order.status !== LabsGqlLabOrderStatus.OnHold || order.fulfillment_workflow.closed_tasks.length === 0) {
        return false;
    }
    const lastClosedTask = order.fulfillment_workflow.closed_tasks[order.fulfillment_workflow.closed_tasks.length - 1];
    return lastClosedTask && getWorkflowTaskTypesForOrderStatus(status).includes(lastClosedTask.type);
};
