import { Box, Text, TextField, FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

interface ShareScansWithPatientModalMessageProps {
    message: string | null | undefined;
    setMessage: (message: string) => void;
}

const useStyles = stylesFactory(() => ({
    textInput: {
        '&&&&': {
            padding: '4px 16px',
        },
    },
}));

export const ShareScansWithPatientModalMessage: React.FC<ShareScansWithPatientModalMessageProps> = ({
    message,
    setMessage,
}) => {
    const classes = useStyles();

    return (
        <Box sx={{ borderRight: `1px solid ${FlossPalette.DARK_TAN}`, paddingRight: '24px' }}>
            <Text variant={'body2'} medium color={'BLACK'} sx={{ marginBottom: '16px' }}>
                Message
            </Text>
            <TextField
                multiline
                minRows={11}
                placeholder={"What should the patient look for on the scans and think about when they're home?"}
                label={''}
                value={message}
                onChange={e => setMessage(e.target.value)}
                fullWidth={true}
                InputProps={{ className: classes.textInput }}
            />
        </Box>
    );
};
