import { AdditionalPonticsInRemovableForm } from '../AdditionalPonticsInRemovableForm';
import { AlignerKittingForm } from '../AlignerKittingForm';
import { AlignerRushFeeForm } from '../AlignerRushFeeForm';
import { AlignerUnitForm } from '../AlignerUnitForm';
import { DentureAddOnCuSilGasketForm } from '../DentureAddOnCuSilGasketForm';
import { DentureAddOnMetalFrameworkForm } from '../DentureAddOnMetalFrameworkForm';
import { DentureAddOnMetalMeshForm } from '../DentureAddOnMetalMeshForm';
import { DentureAddOnSoftlinerForm } from '../DentureAddOnSoftlinerForm';
import { DentureArchForm } from '../DentureArchForm';
import { DentureTryInForm } from '../DentureTryInForm';
import { DentureWaxRimForm } from '../DentureWaxRimForm';
import { ExternalDesignForm } from '../ExternalDesignForm';
import { FirstPonticInRemovableForm } from '../FirstPonticInRemovableForm';
import { ImplantAbutmentForm } from '../ImplantAbutementForm';
import { ImplantDigitalAnalogForm } from '../ImplantDigitalAnalogForm';
import { ImplantForm } from '../ImplantForm';
import { ImplantModelForm } from '../ImplantModelForm';
import { ModelForm } from '../ModelForm';
import { NightGuardColorCustomizationForm } from '../NightGuardColorCustomizationForm';
import { OtherAddOnForm } from '../OtherAddOnForm';
import { PackagingForm } from '../PackagingForm';
import { PartialDentureArchForm } from '../PartialDentureArchForm';
import { PartialDentureClaspForm } from '../PartialDentureClaspForm';
import { PartialDenturePracticeFinalForm } from '../PartialDenturePracticeFinalForm';
import { PartialDenturePracticeForm } from '../PartialDenturePracticeForm';
import { PartialDenturePracticeTryInForm } from '../PartialDenturePracticeTryInForm';
import { PartialDenturePracticeWaxRimForm } from '../PartialDenturePracticeWaxRimForm';
import { PartialDentureWaxRimForm } from '../PartialDentureWaxRimForm';
import { PracticeUnitForm } from '../PracticeUnitForm';
import { RemovableClaspForm } from '../RemovableClaspForm';
import { RemovableForm } from '../RemovableForm';
import { SleepApneaForm } from '../SleepApneaForm';
import { SurgicalGuideForm } from '../SurgicalGuideForm';
import { SurgicalGuideSleeveForm } from '../SurgicalGuideSleeveForm';
import { TMJForm } from '../TMJForm';
import { UnitForm } from '../UnitForm';
import type { LabPriceFormProps } from '../Utils/ProductCatalogForms.types';
import { WaxupForm } from '../WaxupForm';
import { LabsGqlLabPriceRuleType } from '@orthly/graphql-schema';
import React from 'react';

interface ConfigureLabPriceFormProps extends LabPriceFormProps {
    ruleType: LabsGqlLabPriceRuleType;
}

export const ConfigureLabPriceForm: React.FC<ConfigureLabPriceFormProps> = ({ ruleType, ...props }) => {
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (ruleType) {
        case LabsGqlLabPriceRuleType.PracticeUnit:
            return <PracticeUnitForm {...props} />;
        case LabsGqlLabPriceRuleType.Model:
            return <ModelForm {...props} />;
        case LabsGqlLabPriceRuleType.Implant:
            return <ImplantForm {...props} />;
        case LabsGqlLabPriceRuleType.ImplantAbutment:
            return <ImplantAbutmentForm {...props} />;
        case LabsGqlLabPriceRuleType.ImplantModel:
            return <ImplantModelForm {...props} />;
        case LabsGqlLabPriceRuleType.ImplantDigitalAnalog:
            return <ImplantDigitalAnalogForm {...props} />;
        case LabsGqlLabPriceRuleType.Unit:
            return <UnitForm {...props} />;
        case LabsGqlLabPriceRuleType.Removable:
            return <RemovableForm {...props} />;
        case LabsGqlLabPriceRuleType.FirstPonticInRemovable:
            return <FirstPonticInRemovableForm {...props} />;
        case LabsGqlLabPriceRuleType.AdditionalPonticsInRemovable:
            return <AdditionalPonticsInRemovableForm {...props} />;
        case LabsGqlLabPriceRuleType.RemovableClasp:
            return <RemovableClaspForm {...props} />;
        case LabsGqlLabPriceRuleType.NightGuardColorCustomization:
            return <NightGuardColorCustomizationForm {...props} />;
        case LabsGqlLabPriceRuleType.Waxup:
            return <WaxupForm {...props} />;
        case LabsGqlLabPriceRuleType.AlignerUnit:
            return <AlignerUnitForm {...props} />;
        case LabsGqlLabPriceRuleType.AlignerKitting:
            return <AlignerKittingForm {...props} />;
        case LabsGqlLabPriceRuleType.AlignerRushFee:
            return <AlignerRushFeeForm {...props} />;
        case LabsGqlLabPriceRuleType.DentureArch:
            return <DentureArchForm {...props} />;
        case LabsGqlLabPriceRuleType.DentureWaxRim:
            return <DentureWaxRimForm {...props} />;
        case LabsGqlLabPriceRuleType.DentureTryIn:
            return <DentureTryInForm {...props} />;
        case LabsGqlLabPriceRuleType.DentureAddOnSoftliner:
            return <DentureAddOnSoftlinerForm {...props} />;
        case LabsGqlLabPriceRuleType.DentureAddOnMetalMesh:
            return <DentureAddOnMetalMeshForm {...props} />;
        case LabsGqlLabPriceRuleType.DentureAddOnMetalFramework:
            return <DentureAddOnMetalFrameworkForm {...props} />;
        case LabsGqlLabPriceRuleType.DentureAddOnCuSilGasket:
            return <DentureAddOnCuSilGasketForm {...props} />;
        case LabsGqlLabPriceRuleType.PartialDentureWaxRim:
            return <PartialDentureWaxRimForm {...props} />;
        case LabsGqlLabPriceRuleType.PartialDentureArch:
            return <PartialDentureArchForm {...props} />;
        case LabsGqlLabPriceRuleType.PracticePartialDenture:
            return <PartialDenturePracticeForm {...props} />;
        case LabsGqlLabPriceRuleType.PracticePartialDentureWaxRim:
            return <PartialDenturePracticeWaxRimForm {...props} />;
        case LabsGqlLabPriceRuleType.PracticePartialDentureTryIn:
            return <PartialDenturePracticeTryInForm {...props} />;
        case LabsGqlLabPriceRuleType.PracticePartialDentureFinal:
            return <PartialDenturePracticeFinalForm {...props} />;
        case LabsGqlLabPriceRuleType.Packaging:
            return <PackagingForm {...props} />;
        case LabsGqlLabPriceRuleType.PartialDentureClasp:
            return <PartialDentureClaspForm {...props} />;
        case LabsGqlLabPriceRuleType.SurgicalGuide:
            return <SurgicalGuideForm {...props} />;
        case LabsGqlLabPriceRuleType.SurgicalGuideSleeve:
            return <SurgicalGuideSleeveForm {...props} />;
        case LabsGqlLabPriceRuleType.OtherAddOn:
            return <OtherAddOnForm {...props} />;
        case LabsGqlLabPriceRuleType.ExternalDesign:
            return <ExternalDesignForm {...props} />;
        case LabsGqlLabPriceRuleType.Tmj:
            return <TMJForm {...props} />;
        case LabsGqlLabPriceRuleType.SleepApnea:
            return <SleepApneaForm {...props} />;
        case LabsGqlLabPriceRuleType.PreciousMetal:
        case LabsGqlLabPriceRuleType.Order:
        case LabsGqlLabPriceRuleType.Unknown:
        default:
            return null;
    }
};
