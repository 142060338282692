import { Button, Menu, MenuItem } from '@orthly/ui-primitives';
import React from 'react';

export interface ActionProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export interface ActionDefinition {
    Component: React.ComponentType<ActionProps>;
    label: string;
}

export interface ActionButtonProps {
    actionDefinition: ActionDefinition;
    onClick: (label: string) => void;
}

const MenuActionButton: React.VFC<ActionButtonProps> = ({ actionDefinition, onClick }) => {
    const { label } = actionDefinition;
    return (
        <MenuItem
            onClick={() => {
                onClick(label);
            }}
        >
            {label}
        </MenuItem>
    );
};

interface MenuWithActionsProps {
    actions: ActionDefinition[];
    buttonTitle: string;
}

export const MenuWithActions: React.VFC<MenuWithActionsProps> = props => {
    const { actions, buttonTitle } = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const [openAction, setOpenAction] = React.useState<string | undefined>();

    const OpenAction = React.useMemo(() => {
        return !openAction ? undefined : actions.find(a => a.label === openAction);
    }, [openAction, actions]);

    if (actions.length === 0) {
        return null;
    }

    return (
        <>
            <Button
                disabled={actions.length === 0}
                variant={'ghost'}
                startIcon={'AddIcon'}
                onClick={e => setAnchorEl(e.currentTarget)}
            >
                {buttonTitle}
            </Button>
            <Menu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                {actions.map(actionDef => (
                    <MenuActionButton
                        key={actionDef.label}
                        actionDefinition={actionDef}
                        onClick={(label: string) => {
                            setOpenAction(label);
                            setAnchorEl(null);
                        }}
                    />
                ))}
            </Menu>
            {!!OpenAction?.Component && <OpenAction.Component open={true} setOpen={() => setOpenAction(undefined)} />}
        </>
    );
};
