import { MODEL_VIEWER_INITIAL_APPEARANCE } from '../ModelAppearance';
import type { ModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import { createRestorativeItem, createJawItem } from './Adapter.utils';
import type { MarginLinesMap, RestorativeModel, ScanGeometries } from './FinishingApp.types';
import type { SceneAppearance } from './SceneAppearanceManager.types';
import type { MarginLine } from '@orthly/shared-types';
import { compact } from 'lodash';
import React from 'react';

/**
 * These hooks adapt Finishing App-specific types to other general types. They should be used only for interfacing with
 * existing components, e.g. the cross section tool.
 */

// Creates a `ModelAppearance` representing the scene state
export function useModelAppearance(
    restorativeModels: RestorativeModel[],
    scanGeometries: Partial<ScanGeometries>,
    sceneAppearance: SceneAppearance,
): { appearance: ModelAppearance; setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>> } {
    // useState is used to store the appearance object, so we can update the cross section tool when the geometry changes
    const [appearance, setAppearance] = React.useState<ModelAppearance>(MODEL_VIEWER_INITIAL_APPEARANCE);

    React.useEffect(() => {
        const restorativeItems = compact(
            restorativeModels.map(el => {
                const modelAppearance = sceneAppearance.restoratives.get(el.toothNumber);
                return modelAppearance ? createRestorativeItem(el, modelAppearance) : undefined;
            }),
        );
        const { upperJaw: upperJawGeometry, lowerJaw: lowerJawGeometry } = scanGeometries;
        const { upperJaw, lowerJaw } = sceneAppearance.scans;
        const upperJawItem = upperJawGeometry && upperJaw && createJawItem(upperJawGeometry, upperJaw);
        const lowerJawItem = lowerJawGeometry && lowerJaw && createJawItem(lowerJawGeometry, lowerJaw);

        const showMarginLines = sceneAppearance.marginLinesVisible;

        const updatedAppearance = {
            restoratives: restorativeItems,
            pastRestoratives: [],
            upperJaw: compact([upperJawItem]),
            lowerJaw: compact([lowerJawItem]),
            scans: [],
            preExtractionScans: [],
            printedModels: [],
            collisions: [],
            anatomy: [],
            curtains: {},
            showAnatomyLayers: false,
            showMarginLines,
            showDoctorMarginLines: false,
            showDoctorToothMarkings: false,
            showCollisions: false,
            showCurtainsCollisions: false,
            activeHeatMap: null,
            showCurtainsHeatmap: false,
        };
        setAppearance(updatedAppearance);
    }, [restorativeModels, scanGeometries, sceneAppearance]);

    return { appearance, setAppearance };
}

// Creates an array of `MarginLine` objects
export function useMarginLines(marginLinesMap: MarginLinesMap): MarginLine[] {
    return React.useMemo(() => {
        const lines: MarginLine[] = [];
        const transformationMatrix: MarginLine['transformationMatrix'] = [
            0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        ];

        for (const [toothNumber, points] of marginLinesMap.entries()) {
            lines.push({
                tooth: toothNumber,
                mb_coords: points,
                coords: [],
                transformationMatrix,
            });
        }

        return lines;
    }, [marginLinesMap]);
}
