import { useAcceptDcmFiles } from './AcceptFilesFromBrowser.hooks';
import {
    type ScanReviewDcmFile,
    type ScanReviewRecordFactory,
    type ScanReviewViewState,
    ScanReviewPanelType,
} from '@orthly/dentin';
import constate from 'constate';
import React from 'react';

function useScanReviewLandingPageApp() {
    const [dcms, setDcms] = React.useState<ScanReviewDcmFile[]>([]);
    const { onDcmDrop } = useAcceptDcmFiles(setDcms);

    const [upperJawFactory, setUpperJawFactory] = React.useState<ScanReviewRecordFactory | null>(null);
    const [lowerJawFactory, setLowerJawFactory] = React.useState<ScanReviewRecordFactory | null>(null);

    const viewState = React.useMemo<ScanReviewViewState>(() => {
        return {
            [ScanReviewPanelType.Upper]: null,
            [ScanReviewPanelType.Lower]: null,
            [ScanReviewPanelType.Left]: null,
            [ScanReviewPanelType.Right]: null,
            [ScanReviewPanelType.Front]: null,
        };
    }, []);

    return {
        dcms,
        setDcms,
        onDcmDrop,
        upperJawFactory,
        setUpperJawFactory,
        lowerJawFactory,
        setLowerJawFactory,
        viewState,
    };
}

export const [ScanReviewLandingPageAppProvider, useScanReviewLandingPageContext] =
    constate(useScanReviewLandingPageApp);
