import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { OrderItemV2Utils } from '@orthly/items';
import { stylesFactory, Divider, Grid, Text } from '@orthly/ui-primitives';
import { OrderDesignSidebarItemsSection } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    sectionRoot: {
        padding: '8px 16px',
        width: '100%',
    },
    sectionHeaderText: {
        fontWeight: 500,
    },
}));

export const DesignItemsSection: React.FC<{ order: Pick<LabsGqlOrder, 'items_v2'> }> = props => {
    const items = OrderItemV2Utils.parseItems(props.order.items_v2);
    const classes = useStyles();
    return (
        <Grid style={{ width: '100%' }}>
            <Grid className={classes.sectionRoot} container>
                <Grid item xs={6} container alignItems={'center'}>
                    <Text variant={'body2'} color={'DARK_GRAY'} className={classes.sectionHeaderText}>
                        Items
                    </Text>
                </Grid>
                <Grid item xs={6} justifyContent={'flex-end'} container alignItems={'center'}>
                    <Text variant={'caption'} color={'DARK_GRAY'} className={classes.sectionHeaderText}>
                        Material
                    </Text>
                </Grid>
            </Grid>
            <Grid style={{ paddingTop: 0 }} className={classes.sectionRoot}>
                <OrderDesignSidebarItemsSection items={items} />
            </Grid>
            <Divider style={{ width: '100%' }} />
        </Grid>
    );
};
