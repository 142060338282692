import { useUsersSelector } from '../../state/Users.context';
import { isUserCommonFieldsUpdated } from '../../state/Users.state';
import { useUpdatePreference } from '../../useUpdatePreference';
import { useCreateUser } from './useCreateUser';
import { useUpdateUser } from './useUpdateUser';
import { useUpdateUserPassword } from './useUpdateUserPassword';
import { useGetPreferencesByStaffMemberIdsQuery } from '@orthly/graphql-react';
import { useSnackbar } from 'notistack';
import React from 'react';

export const useUpsertUser = () => {
    const { submit: createUser, submitting: submitCreating } = useCreateUser();
    const { submit: updateUser, submitting: submitUpdating } = useUpdateUser();
    const { submit: updateUserPassword, submitting: submitUpdatingPassword } = useUpdateUserPassword();
    const { submit: updatePref, submitting: submitUpdatingPref } = useUpdatePreference();

    const { enqueueSnackbar } = useSnackbar();

    const { form, user, roles } = useUsersSelector(s => ({ form: s.form, user: s.user, roles: s.form?.roles ?? [] }));
    const activeRoles = roles.filter(role => !role.deactivated && role.member_id);
    const staff_member_ids = activeRoles.map(role => role.member_id ?? '');
    const { data } = useGetPreferencesByStaffMemberIdsQuery({
        variables: { staff_member_ids },
        skip: staff_member_ids.length === 0,
    });
    const preferences = data?.preferences;

    const submitting = React.useMemo(() => {
        return submitCreating || submitUpdating || submitUpdatingPassword || submitUpdatingPref;
    }, [submitCreating, submitUpdating, submitUpdatingPassword, submitUpdatingPref]);

    const submit = React.useCallback(async () => {
        if (!form) {
            enqueueSnackbar('No form submitted', { variant: 'error' });
            return;
        }

        // If a user, we must first create the user, and then we can do anything else we need.
        const { first_name, last_name, email, phone_number, mobile_phone_number, password } = form;
        if (!user) {
            await createUser({
                first_name,
                last_name,
                email,
                phone_number,
                mobile_phone_number,
                password: password.value,
            });
        } else {
            // when updating a user or pref we want to send null instead of undefined for mobile_phone_number to clear it out
            const mobilePhoneNumber = mobile_phone_number ?? null;
            if (isUserCommonFieldsUpdated(user, form)) {
                await updateUser({
                    first_name,
                    last_name,
                    email,
                    phone_number,
                    mobile_phone_number: mobilePhoneNumber,
                    user_id: user.id,
                });
                if (preferences) {
                    await Promise.all(
                        preferences.map(async preference => {
                            await updatePref({
                                preference_set_id: preference.id,
                                name: `${first_name} ${last_name}`,
                                contact_email: email,
                                contact_phone: mobilePhoneNumber,
                                contact_phone_call_number: phone_number,
                            });
                        }),
                    );
                }
            }

            if (password.visible && password.value) {
                await updateUserPassword({ user_id: user.id, password_raw: password.value });
            }
        }
    }, [form, user, enqueueSnackbar, createUser, updateUser, preferences, updatePref, updateUserPassword]);

    return { submit, submitting };
};
