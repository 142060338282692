import { ReRunManufacturingFileProcessingModal } from './ReRunManufacturingFileProcessingModal';
import { Button, Icon, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

interface ReRunManufacturingFileProcessingProps {
    orderId: string;
    designFilePath: string | null;
}

export const ReRunManufacturingFileProcessing: React.FC<ReRunManufacturingFileProcessingProps> = props => {
    const { orderId, designFilePath } = props;
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);

    return (
        <>
            <ReRunManufacturingFileProcessingModal open={modalOpen} setOpen={setModalOpen} orderId={orderId} />
            <Tooltip title={'Rerun Manufacturing File Processing'}>
                <span>
                    <Button variant={'text'} onClick={() => setModalOpen(true)} disabled={!designFilePath || !orderId}>
                        <Icon icon={'CreateIntegrationOrderIcon'} />
                    </Button>
                </span>
            </Tooltip>
        </>
    );
};
