import type { PresetInfo } from '../../state/GuidedWaxupState';
import { OrderDesignPreviewDesign_FragmentFragmentDoc, getFragmentData } from '@orthly/graphql-inline-react';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { DESIGN_ORDER_PRESET_CATEGORIES, type LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';

const matchesAnyLeafNote = (
    selectedTab: LabsGqlGuidedWaxupPresetType,
    currentNodes: string[] = [],
    previousNodes: string[] = [],
) => {
    const allLeafNodes = DESIGN_ORDER_PRESET_CATEGORIES[selectedTab] ?? [];

    const currentNodeSet = new Set(currentNodes);
    const previousNodeSet = new Set(previousNodes);

    return allLeafNodes.some(leaf => currentNodeSet.has(leaf) && previousNodeSet.has(leaf));
};

export const checkIfNotesAreRequired = (
    preset: PresetInfo | undefined,
    selectedTab: LabsGqlGuidedWaxupPresetType,
    mostRecentlyRejectedDesignFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>,
) => {
    const previousPresetTypeData = getFragmentData(
        OrderDesignPreviewDesign_FragmentFragmentDoc,
        mostRecentlyRejectedDesignFragment,
    );
    const previousPresetType = previousPresetTypeData?.doctor_review?.annotations?.find(
        annotation => annotation.preset_type === selectedTab,
    );

    const previousStructuredNotes = (previousPresetType?.structured_notes ?? []).map(note => note.category);

    const notesAreRequired = matchesAnyLeafNote(selectedTab, preset?.structured_notes, previousStructuredNotes);

    return notesAreRequired;
};
