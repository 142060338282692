import type { BasicInvoice, Credit, InvoiceItemRow } from '../../InvoicesTable/InvoiceTable.types';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import type { IconName } from '@orthly/ui-primitives';

export const NEXT_INVOICE_VALUE = 'credit-next-invoice';
export const PAST_INVOICE_PLACEHOLDER_VALUE = 'credit-past-invoice';
export const OTHER_CREDIT_CATEGORY_ID = '1aa26b80-1d38-4f3b-8bdc-9456ede5b984';
export const OTHER_REFUND_CATEGORY_ID = 'dc8f8568-1cb6-45f9-ae1c-988922750031';
export const ZENDESK_CREDIT_OR_REFUND_SOP_EXTERNAL_LINK =
    'https://dandyinternal.zendesk.com/hc/en-us/articles/30768234995981-Credit-Refund-Monthly-Minimum-Guide';

export interface GeneratedInvoiceItem extends InvoiceItemRow {
    invoice_id: string;
}

export type PartialBasicInvoice = Pick<
    BasicInvoice,
    'id' | 'period_start' | 'status' | 'amount_remaining' | 'hasPendingPayment'
>;

export interface IssueCreditOrRefundButtonProps {
    organizationId: string;
    order?: LabsGqlOrder;
    invoiceItem?: GeneratedInvoiceItem;
    startIcon?: IconName;
    existingCreditId?: string | null;
    refetchItems?: () => Promise<unknown>;
    refetchCredits?: () => Promise<unknown>;
}

export interface CreditOrRefundWrapperProps extends IssueCreditOrRefundButtonProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export interface CategoryOption {
    value: string;
    label: string;
}

export interface InvoiceDropdownOption {
    value: string;
    label: string;
    disabled: boolean;
}

export interface ItemDisplayAndPriceInfo {
    value: string;
    label: string;
    price: number;
}

export interface CheckboxItem {
    label: string;
    value: string;
    price: number;
    checked: boolean;
    disabled: boolean;
}

export interface CreditWithUser extends Credit {
    created_by: string;
}

export interface CreditOrRefundFormContextProps {
    formState: FormState;
    dispatchFormStateAction: React.Dispatch<FormStateAction>;
    setOpen: (open: boolean) => void;
    creditCategoryOptions: CategoryOption[];
    refundCategoryOptions: CategoryOption[];
    order?: LabsGqlOrder;
    existingCredit?: CreditWithUser;
    invoiceItem?: GeneratedInvoiceItem;
    viewOnly: boolean;
    eligibleForRefund: boolean;
    invoiceOptionsForCredit: InvoiceDropdownOption[];
    submit: () => void;
    disableSubmit: boolean;
}

export interface CreditOrRefundFormCtxProviderProps {
    setOpen: (open: boolean) => void;
    organizationId: string;
    order?: LabsGqlOrder;
    invoiceItem?: GeneratedInvoiceItem;
    existingCreditId?: string | null;
    refetchItems?: () => Promise<unknown>;
    refetchCredits?: () => Promise<unknown>;
    children: React.ReactNode;
}

type CreditOrRefundAction = 'credit' | 'refund';

export interface FormState {
    applyOn: string | undefined;
    action: CreditOrRefundAction;
    attributed: boolean;
    existingInvoiceId: string | undefined;
    selectedItems: CheckboxItem[];
    description: string | undefined;
    category: string;
    amountDollars: string;
    expires: Date | null;
    submitted: boolean;
}

export type FormStateAction =
    | { type: `SET_AMOUNT_DOLLARS`; amountDollars: string }
    | { type: `SET_EXPIRATION_DATE`; expires: Date | null }
    | { type: `SET_CATEGORY`; category: string }
    | { type: `SET_DESCRIPTION`; description: string | undefined }
    | { type: `SET_APPLY_ON`; applyOn: string }
    | { type: `SET_EXISTING_INVOICE`; existingInvoiceId: string | undefined }
    | { type: `SET_SELECTED_ITEMS`; amountDollars: string; selectedItems: CheckboxItem[] }
    | { type: `SET_ACTION`; actionType: CreditOrRefundAction }
    | { type: `SET_VIEW_STATE`; payload: Pick<FormState, 'description' | 'category' | 'amountDollars' | 'expires'> }
    | { type: `SUBMIT` };
