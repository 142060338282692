import { useQuery } from '@apollo/client';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { Button, Grid } from '@orthly/ui-primitives';
import { OrderDetailBlock } from '@orthly/veneer';
import React from 'react';

const AdminOrderDetailSplitInfoBlockLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailSplitInfoBlockLabSalesOrder_Fragment on LabSalesOrderDTO {
        id
        split_id
    }
`);

const AdminOrderDetailSplitInfoBlockGetLabSalesOrdersBySplitId_Query = graphql(`
    query AdminOrderDetailSplitInfoBlockGetLabSalesOrdersBySplitId_Query($split_id: String!) {
        getLabSalesOrdersBySplitId(split_id: $split_id) {
            id
            item_groups_display_info {
                full_display_name
            }
        }
    }
`);

interface OrderDetailSplitInfoBlockProps {
    salesOrder: FragmentType<typeof AdminOrderDetailSplitInfoBlockLabSalesOrder_Fragment>;
    openOrder: (orderId: string, e: React.MouseEvent) => void;
}

export const OrderDetailSplitInfoBlock: React.FC<OrderDetailSplitInfoBlockProps> = ({
    salesOrder: salesOrderProp,
    openOrder,
}) => {
    const salesOrder = getFragmentData(AdminOrderDetailSplitInfoBlockLabSalesOrder_Fragment, salesOrderProp);

    const { data } = useQuery(AdminOrderDetailSplitInfoBlockGetLabSalesOrdersBySplitId_Query, {
        variables: { split_id: salesOrder.split_id },
    });

    const splitOrders = data?.getLabSalesOrdersBySplitId.filter(o => o.id !== salesOrder.id);

    if (!splitOrders?.length) {
        return null;
    }

    return (
        <OrderDetailBlock title={'Split Orders'} variant={'full'}>
            <Grid container direction={'column'}>
                {splitOrders.map(order => (
                    <Grid item key={order.id}>
                        <Button key={order.id} variant={'ghost'} onClick={e => openOrder(order.id, e)}>
                            {`Split Order: ${order.item_groups_display_info.map(info => info.full_display_name).join(', ')}`}
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </OrderDetailBlock>
    );
};
