import { recomputeCollisionsObject } from '../DesignEditing/RecomputeCollisions';
import type { MainViewCameraControlsRef } from '../ModelViewer/ModelViewerTHREETypes';
import type { CaseMetadata } from './CaseMetadata';
import { CurtainsGenerator } from './CurtainsGenerator';
import type { IOperationsManager } from './OperationsManager.types';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import {
    ComputeVertexNormalsByAngle,
    getInsertionDirectionFromCameraView,
    directionToCameraRelativeAxis,
    ensureMeshIndex,
    buildMeshAdjacencyAndVerticesFaces,
    extractPrepSiteFromScan,
    DEFAULT_CEMENT_GAP_SETTINGS,
    recomputeActiveDistanceForASubset,
    runIntaglioChecks,
    HeatMapType,
    AttributeName,
    adjustForUndercut,
    getFaceVertexIndices,
    getMillAdjusted,
    conformIntaglioToIdealizedPrepSite,
} from '@orthly/forceps';
import type { InsertionAxisAdjustmentDirection, IntaglioCheckResult } from '@orthly/forceps';
import _ from 'lodash';
import * as THREE from 'three';

type MinimalOperationsManager = Pick<
    IOperationsManager,
    | 'applyInsertionAxis'
    | 'commitInsertionAxis'
    | 'editGeometry'
    | 'proximalModels'
    | 'prepScan'
    | 'occlusalModels'
    | 'collisionsGeometry'
    | 'curtainGeometry'
    | 'editToothNumber'
    | 'editInsertionOrientation'
    | 'editInsertionAxis'
    | 'connectivityGraph'
    | 'resetEditGeometry'
>;

/**
 * Exposes methods for modifying the restorative insertion axis.
 */
export class InsertionAxisManager {
    // Our insertion axis utils follow the convention that the insertion direction points along the path that the crown
    // would be seated (i.e. the insertion axis points "down"). However, in Finishing, as well as in some other Dandy
    // tools e.g. design preview, the insertion axis indicates the path along which the crown would be removed (i.e.
    // the insertion axis points "up"). To account for this, we need to flip the insertion axis about the y-axis.
    // TODO: Revisit the convention used in Dandy Finishing - should we be consistent with Dandy Prep?
    private static readonly FLIP_ABOUT_Y = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI);

    private curtainsGenerator: CurtainsGenerator;

    public insertionAxisIncrementRad: number = 0;
    constructor(
        private controlsRef: MainViewCameraControlsRef,
        private opManager: MinimalOperationsManager,
        private readonly caseMetadata: CaseMetadata,
        private marginLine: THREE.Vector3[] | undefined,
        private setLoading: React.Dispatch<React.SetStateAction<boolean>>,
        private readonly allowInvalidIntaglio: boolean,
    ) {
        this.curtainsGenerator = new CurtainsGenerator(opManager);
    }

    /**
     * Sets the insertion direction to be along the camera view.
     */
    setInsertionAxisFromCamera(): void {
        const orientation = getInsertionDirectionFromCameraView(this.controlsRef);
        orientation.multiply(InsertionAxisManager.FLIP_ABOUT_Y);
        this.setInsertionAxis(orientation);
    }

    /**
     * Adjusts the insertion axis in the specified direction.
     */
    adjustInsertionAxis(direction: InsertionAxisAdjustmentDirection): void {
        const rotationAxis = this.computeInsertionAxisAdjustAxis(direction);
        if (!rotationAxis) {
            return;
        }
        const insertionOrientation = this.opManager.editInsertionOrientation
            .clone()
            .multiply(InsertionAxisManager.FLIP_ABOUT_Y);

        // Compound the rotation increment with the current rotation of the insertion axis to get the new rotation.
        const rotationIncrement = new THREE.Quaternion().setFromAxisAngle(rotationAxis, this.insertionAxisIncrementRad);
        insertionOrientation.premultiply(rotationIncrement).multiply(InsertionAxisManager.FLIP_ABOUT_Y);

        this.setInsertionAxis(insertionOrientation);
    }

    computeInsertionAxisAdjustAxis(direction: InsertionAxisAdjustmentDirection | undefined): THREE.Vector3 | undefined {
        if (direction === undefined) {
            return undefined;
        }
        const camera = this.controlsRef.current?.object;

        if (!camera) {
            return undefined;
        }

        const insertionOrientation = this.opManager.editInsertionOrientation
            .clone()
            .multiply(InsertionAxisManager.FLIP_ABOUT_Y);

        const rotationAxis = directionToCameraRelativeAxis(direction, insertionOrientation, camera.quaternion);
        return rotationAxis;
    }

    /**
     * Commits the insertion direction. This is done asynchronously as intaglio recalculation can be time-consuming.
     * @param orderId The ID of the order, for tracking purposes
     * @param onComplete Callback to be called when the operation is complete
     */
    commitInsertionAxis(orderId: string, onComplete: (errorMessage: string | null) => void): void {
        this.setLoading(true);

        const handleFailure = (errors: string[]): void => {
            // Wipe out any intermediate changes that were potentially made to the geometry.
            this.opManager.resetEditGeometry();

            BrowserAnalyticsClientFactory.Instance?.track('Ops - Portal - Design Finishing - Intaglio Error', {
                $groups: { order: orderId },
                errors,
            });
        };

        setTimeout(() => {
            try {
                const result = this.applyIntaglioCorrection();

                if (result.passed || this.allowInvalidIntaglio) {
                    this.opManager.commitInsertionAxis();
                } else {
                    handleFailure(result.errorsArray);
                }

                onComplete(
                    result.passed ? null : `Failed the intaglio test because of ${JSON.stringify(result.errorsArray)}`,
                );
            } catch (error) {
                const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
                handleFailure([errorMessage]);
                return errorMessage;
            } finally {
                this.setLoading(false);
            }
        });
    }

    private applyIntaglioCorrection(): IntaglioCheckResult {
        const geometry = this.opManager.editGeometry;
        const scan = this.opManager.prepScan;

        const marginLine = this.marginLine;
        if (!marginLine) {
            throw new Error('Margin line is not defined');
        }

        const scanBvh = ensureMeshIndex(scan);
        const scanGraph = buildMeshAdjacencyAndVerticesFaces(scan);
        console.log('Extracting prep site from scan');
        const prepSite = extractPrepSiteFromScan(scan, marginLine, scanBvh, scanGraph);
        if (!prepSite) {
            throw new Error('Failed to extract prep site');
        }
        prepSite.computeVertexNormals();

        const intaglioSettings = this.caseMetadata.intaglioSettings[this.opManager.editToothNumber];
        if (!intaglioSettings) {
            throw new Error('Missing intaglio settings');
        }

        const cementGapSettings = {
            ...DEFAULT_CEMENT_GAP_SETTINGS,
            ...intaglioSettings,
        };

        const meshAdj = this.opManager.connectivityGraph.adjacencyVertexToVertex;

        console.log('Projecting onto ideal intaglio surface');
        conformIntaglioToIdealizedPrepSite(geometry, meshAdj, prepSite, marginLine, cementGapSettings);

        const index = geometry.getIndex();
        const isIntaglioAttr = geometry.getAttribute(AttributeName.IsIntaglio);
        if (!(index && isIntaglioAttr)) {
            throw new Error('Missing index or intaglio attribute in geometry');
        }

        const intaglioFacetSelector = (faceIndex: number): boolean => {
            const vertexInds = getFaceVertexIndices(index.array, faceIndex);
            if (!vertexInds) {
                return false;
            }

            return vertexInds.every(i => isIntaglioAttr.getX(i) !== 0);
        };
        const conflicts = adjustForUndercut(
            geometry,
            [prepSite],
            this.opManager.editInsertionAxis,
            intaglioFacetSelector,
        );
        if (conflicts.length > 0) {
            console.warn('Intaglio correction for undercut had conflicts', { conflicts });
        }

        const millAdjustedResult = getMillAdjusted(
            geometry,
            intaglioSettings.drillRadius,
            this.opManager.connectivityGraph.adjacencyVertexToVertex,
            { facetSelector: intaglioFacetSelector },
        );

        if (!millAdjustedResult.millable) {
            console.warn('Intaglio correction for millability failed', { numIters: millAdjustedResult.iterations });
        }

        (geometry.getAttribute(AttributeName.Position) as THREE.BufferAttribute).copyArray(
            millAdjustedResult.geometry.getAttribute(AttributeName.Position).array,
        );

        ComputeVertexNormalsByAngle(geometry);

        // Thickness calculated on the cameo will be affected changes to the intaglio, so we must recompute this
        // heatmap over all vertices.
        const numVertices = geometry.getAttribute(AttributeName.Position).count;
        const allVertexInds = _.range(numVertices);
        recomputeActiveDistanceForASubset(
            [HeatMapType.Thickness],
            geometry,
            allVertexInds,
            this.opManager.proximalModels,
            this.opManager.occlusalModels,
            undefined,
            this.opManager.curtainGeometry,
            true,
        );

        const intaglioVertices = allVertexInds.filter(i => !!isIntaglioAttr.getX(i));
        recomputeActiveDistanceForASubset(
            [HeatMapType.SurfaceDisplacement, HeatMapType.CementGap],
            geometry,
            intaglioVertices,
            this.opManager.proximalModels,
            this.opManager.occlusalModels,
            undefined,
            this.opManager.curtainGeometry,
            true,
        );

        return runIntaglioChecks(
            geometry,
            this.opManager.connectivityGraph,
            prepSite,
            this.opManager.editInsertionAxis,
            intaglioSettings,
        );
    }

    /**
     * Updates insertion axis-dependent artifacts (e.g. curtains, curtain distance) and records the insertion axis
     * operation.
     */
    private setInsertionAxis(insertionOrientation: THREE.Quaternion): void {
        this.setLoading(true);

        // Recalculating the curtains, curtains distance, and collisions can take some time. We do this asynchronously
        // to allow the load blocker to be displayed.
        setTimeout(() => {
            this.curtainsGenerator.updateCurtains(insertionOrientation);

            // Of all the attribute-backed heatmaps, only the curtains distance is affected by an insertion axis change.
            recomputeActiveDistanceForASubset(
                [HeatMapType.Proximal],
                this.opManager.editGeometry,
                _.range(this.opManager.editGeometry.getAttribute(AttributeName.Position).count),
                this.opManager.proximalModels,
                this.opManager.occlusalModels,
                undefined,
                this.opManager.curtainGeometry,
                true,
            );

            const collisions = recomputeCollisionsObject(this.opManager.editGeometry).model.geometry;
            this.opManager.collisionsGeometry?.copy(collisions);

            this.opManager.applyInsertionAxis(insertionOrientation);

            this.setLoading(false);
        });
    }
}
