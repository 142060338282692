import type { IOrganizationType, StaffRole, OrgRoles } from '@orthly/retainer-common';
import {
    StaffRoleParent,
    StaffRoleExternal,
    StaffRoleLab,
    StaffRolePractice,
    StaffRoleInternal,
    isRoleOfType,
} from '@orthly/retainer-common';
import type { ConstEnum } from '@orthly/runtime-utils';
import { Assert, constEnumFromArray } from '@orthly/runtime-utils';

type PrefixAny<TPrefix extends IOrganizationType> = `${TPrefix}__any`;
type WithAny<T extends string, TPrefix extends IOrganizationType> = T | PrefixAny<TPrefix>;

function constEnumWithAny<T extends string, TPrefix extends IOrganizationType>(
    withoutAny: ConstEnum<T>,
    prefix: TPrefix,
): ConstEnum<WithAny<T, TPrefix>> {
    const withoutAnyKeys = Object.freeze(Object.keys(withoutAny));
    const anyKey: PrefixAny<TPrefix> = `${prefix}__any`;
    return constEnumFromArray([...withoutAnyKeys, anyKey]) as ConstEnum<WithAny<T, TPrefix>>;
}

export type StaffRolePracticeWithAny = WithAny<StaffRolePractice, 'practice'>;
export const StaffRolePracticeWithAny: ConstEnum<StaffRolePracticeWithAny> = constEnumWithAny(
    StaffRolePractice,
    'practice',
);
export type StaffRoleParentWithAny = WithAny<StaffRoleParent, 'parent'>;
export const StaffRoleParentWithAny: ConstEnum<StaffRoleParentWithAny> = constEnumWithAny(StaffRoleParent, 'parent');
export type StaffRoleLabWithAny = WithAny<StaffRoleLab, 'lab'>;
export const StaffRoleLabWithAny: ConstEnum<StaffRoleLabWithAny> = constEnumWithAny(StaffRoleLab, 'lab');
export type StaffRoleExternalWithAny = WithAny<StaffRoleExternal, 'external'>;
export const StaffRoleExternalWithAny: ConstEnum<StaffRoleExternalWithAny> = constEnumWithAny(
    StaffRoleExternal,
    'external',
);
export type StaffRoleInternalWithAny = WithAny<StaffRoleInternal, 'internal'>;
export const StaffRoleInternalWithAny: ConstEnum<StaffRoleInternalWithAny> = constEnumWithAny(
    StaffRoleInternal,
    'internal',
);
/**
 * No one should ever be assigned a role ending in `__any`.  They're part of this type as a way to
 * specify chat message visibility, and whatever other myriad uses we imagine down the line.  Unless
 * you know that your use case needs the `__any` roles, you probably want {@link StaffRole} instead.
 */
export type StaffRoleWithAny = WithAny<StaffRole, IOrganizationType>;
export const StaffRoleWithAny: ConstEnum<StaffRoleWithAny> = {
    ...StaffRolePracticeWithAny,
    ...StaffRoleParentWithAny,
    ...StaffRoleLabWithAny,
    ...StaffRoleExternalWithAny,
    ...StaffRoleInternalWithAny,
};
export type OrgRolesWithAny<T extends IOrganizationType> = WithAny<OrgRoles<T>, T>;

function isAnyRoleForOrgType<T extends IOrganizationType>(
    staffRole: StaffRoleWithAny,
    orgType: T,
): staffRole is `${T}__any` {
    return staffRole === `${orgType}__any`;
}

function isNotAnyRoleForOrgType<T extends IOrganizationType>(staffRole: OrgRolesWithAny<T>): staffRole is OrgRoles<T> {
    // Seems redundant, but isAnyRoleForOrgType being true does not automatically imply that
    // `staffRole is OrgRoles<T>` in TypeScript land.
    return !staffRole.endsWith('__any');
}

function isRoleOfTypeWithAny<T extends IOrganizationType>(
    staffRole: StaffRoleWithAny,
    orgType: T,
): staffRole is OrgRolesWithAny<T> {
    if (isNotAnyRoleForOrgType<IOrganizationType>(staffRole)) {
        return isRoleOfType<T>(staffRole, orgType);
    }
    return isAnyRoleForOrgType<T>(staffRole, orgType);
}

/**
 * Given a non-admin {@link StaffRoleWithAny}, return the organization type.  NOTE: Please do not import
 * for non-chat purposes.  If you need new functionality in `@orthly/retainer`, implement it there instead.
 * Also, this function does not accept `'admin'` as the role because it's ambiguous as to organization type,
 * it _shouldn't_ arise in chat (as of this writing -- that could change!), and I don't know what the caller
 * wants to do with that information.
 *
 * @param staffRole The {@link StaffRoleWithAny} value
 * @returns The corresponding {@link IOrganizationType} value
 */
export function getOrganizationTypeFromStaffRoleWithAny(
    staffRole: Exclude<StaffRoleWithAny, 'admin'>,
): IOrganizationType {
    // Not looping so that unreachable assertion works correctly
    if (isRoleOfTypeWithAny(staffRole, 'practice')) {
        return 'practice';
    }
    if (isRoleOfTypeWithAny(staffRole, 'lab')) {
        return 'lab';
    }
    if (isRoleOfTypeWithAny(staffRole, 'external')) {
        return 'external';
    }
    if (isRoleOfTypeWithAny(staffRole, 'parent')) {
        return 'parent';
    }
    if (isRoleOfTypeWithAny(staffRole, 'internal')) {
        return 'internal';
        /* c8 ignore start */
    }
    Assert.unreachable(staffRole);
}
/* c8 ignore stop */
