import bl_anatomy_depression_ACCEPTABLE from '../../../../assets/images/design-qc/bl_anatomy/bl_anatomy_depression_ACCEPTABLE.jpg';
import bl_anatomy_depression_BAD from '../../../../assets/images/design-qc/bl_anatomy/bl_anatomy_depression_BAD.jpg';
import bl_anatomy_line_angles_ACCEPTABLE from '../../../../assets/images/design-qc/bl_anatomy/bl_anatomy_line_angles_ACCEPTABLE.jpg';
import bl_anatomy_line_angles_BAD from '../../../../assets/images/design-qc/bl_anatomy/bl_anatomy_line_angles_BAD.jpg';
import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { DEFAULT_SCAN_APPEARANCE, DEFAULT_RESTORATIVE_APPEARANCE } from '@orthly/dentin';

export const buccalLingualAnatomyRubric: DesignQcRubricCategoryEntry = {
    category: 'bl_anatomy',
    subcategories: [
        {
            category: 'bl_anatomy',
            name: 'Surface Depressions',
            description:
                'Buccal and lingual surface depressions should mimic adjacent and/or contralateral anatomy<br><br>"You know it when you see it"',
            grading: {
                type: 'tertiary',
                bad: 'Surface depressions do not mimic adjacent dentition',
                acceptable: '"Know it when you see it"<br>OR<br>Escalate to Senior',
                ideal: 'Surface depressions mimic adjacent dentition',
            },
            examples: {
                bad: [bl_anatomy_depression_BAD],
                ideal: [bl_anatomy_depression_ACCEPTABLE],
            },
            selectable_bad_reasons: ['Buccal anatomy needs to be improved', 'Lingual anatomy needs to be improved'],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
        },
        {
            category: 'bl_anatomy',
            name: 'Line angles',
            description:
                'Ridges on the buccal and lingual surface should naturally follow adjacent and/or contralateral anatomy',
            grading: {
                type: 'tertiary',
                bad: 'Line angles do not naturally follow adjacent dentition',
                acceptable: '"Know it when you see it"<br>OR<br>Escalate to Senior',
                ideal: 'Line angles naturally follow adjacent dentition',
            },
            examples: {
                bad: [bl_anatomy_line_angles_BAD],
                ideal: [bl_anatomy_line_angles_ACCEPTABLE],
            },
            selectable_bad_reasons: ['Line angles are incorrect and do not follow natural dentition'],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
        },
    ],
};
