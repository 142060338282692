import type { ToolStateSetter } from '../../../Annotations/DrawingControls';
import {
    ColorAndSizeButton,
    DrawingControlsColorAndSizePopover,
    DrawingControlsUndoAndRedoButtons,
    DrawingToolButton,
    PossibleToolTypes,
} from '../../../Annotations/DrawingControls';
import type { ToolState } from '@orthly/dentin';
import { Format } from '@orthly/runtime-utils';
import { FlossPalette, Text, styled, useScreenIsMobile, Chip, Grid } from '@orthly/ui-primitives';
import React from 'react';

const VerticalDivider = styled('span')({
    width: 0,
    alignSelf: 'stretch',
    margin: '8px 8px',
    borderRight: `1px solid ${FlossPalette.STROKE_LIGHT}`,
});
const ControlTopbar = styled(Grid)(({ theme }) => ({
    backgroundColor: FlossPalette.TAN,
    borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    marginBottom: 0,
    paddingLeft: 3,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
        marginLeft: 0,
        width: 'auto',
    },
}));
const DesktopTitle = styled(Text)({
    marginLeft: '12px',
    marginRight: '12px',
});
const SavedAnnotationChip = styled(Chip)({
    backgroundColor: FlossPalette.SECONDARY_BACKGROUND,
});

export const GuidedWaxupDrawingControls: React.VFC<{
    toolState: ToolState;
    setToolState: ToolStateSetter;
    canUndo: boolean;
    canRedo: boolean;
    undo: () => void;
    redo: () => void;
    capturedScreenshotCount: number;
    setIsAnnotationListModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
    toolState,
    setToolState,
    canUndo,
    canRedo,
    undo,
    redo,
    capturedScreenshotCount,
    setIsAnnotationListModalOpen,
}) => {
    const isMobile = useScreenIsMobile();

    // Controls whether the brush selector popover is visible and which element it is anchored to.
    const [brushSelectAnchor, setBrushSelectAnchor] = React.useState<HTMLButtonElement | null>(null);

    return (
        <ControlTopbar container>
            <Grid item data-pendo-id={'guided-waxup-annotations'}>
                {!isMobile && (
                    <DesktopTitle variant={'caption'} medium color={'GRAY'}>
                        DRAWING TOOLS
                    </DesktopTitle>
                )}
                {PossibleToolTypes.map(({ type, icon, tooltip }) => (
                    <DrawingToolButton
                        key={type}
                        iconName={icon}
                        toolType={type}
                        isSelected={type === toolState.toolType}
                        setToolState={setToolState}
                        tooltip={tooltip}
                    />
                ))}
                <VerticalDivider />
                <ColorAndSizeButton
                    color={toolState.color}
                    size={toolState.brushSize}
                    isSelected={!!brushSelectAnchor}
                    onClick={e => {
                        setBrushSelectAnchor(e.currentTarget);
                    }}
                />
                <DrawingControlsColorAndSizePopover
                    brushSelectAnchor={brushSelectAnchor}
                    setBrushSelectAnchor={setBrushSelectAnchor}
                    toolState={toolState}
                    setToolState={setToolState}
                />
            </Grid>
            {!isMobile && (
                <Grid item>
                    {!!capturedScreenshotCount && (
                        <SavedAnnotationChip
                            label={
                                <Text variant={'caption'} medium color={'SECONDARY_FOREGROUND'}>
                                    {Format.pluralize('Screen Capture', capturedScreenshotCount)} Saved
                                </Text>
                            }
                            onClick={() => setIsAnnotationListModalOpen(true)}
                        />
                    )}
                </Grid>
            )}
            <Grid item>
                <DrawingControlsUndoAndRedoButtons canUndo={canUndo} undo={undo} canRedo={canRedo} redo={redo} />
            </Grid>
        </ControlTopbar>
    );
};
