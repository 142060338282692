import type { PaperProps, Theme } from '@orthly/ui-primitives';
import {
    TableCell,
    createStyles,
    makeStyles,
    Paper,
    TablePrimitive as Table,
    TableBody,
    TableRow,
    Tooltip,
    Grid,
    Text,
    Icon,
} from '@orthly/ui-primitives';
import * as _ from 'lodash';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            padding: theme.spacing(1),
        },
    }),
);

export interface SimpleTableRowProps {
    name: string;
    value: string | React.ReactNode;
    cellStyle?: React.CSSProperties;
    titleCellStyle?: React.CSSProperties;
    valueCellStyle?: React.CSSProperties;
    toolTipNote?: string;
}

const SimpleTableRow: React.FC<SimpleTableRowProps> = props => {
    const { name, value, cellStyle, titleCellStyle, valueCellStyle, toolTipNote } = props;
    return (
        <TableRow style={{ display: 'flex', alignItems: 'flex-end' }}>
            <TableCell
                component={'th'}
                scope={'row'}
                style={{ width: '30%', display: 'flex', ...cellStyle, ...titleCellStyle }}
            >
                {name}
                {toolTipNote ? (
                    <Tooltip title={toolTipNote}>
                        <div>
                            <Icon icon={'InfoOutlinedIcon'} style={{ fontSize: 16, marginLeft: 4 }} />
                        </div>
                    </Tooltip>
                ) : null}
            </TableCell>
            <TableCell
                align={'right'}
                style={{ width: '70%', wordBreak: 'break-all', display: 'flex', ...cellStyle, ...valueCellStyle }}
            >
                {value}
            </TableCell>
        </TableRow>
    );
};

interface SimpleTableProps {
    title?: string | React.ReactNode;
    titleInPaper?: boolean;
    rows: SimpleTableRowProps[];
    elevation?: number;
    PaperProps?: PaperProps;
}

const SimpleTableTitle: React.FC<{ title?: string | React.ReactNode }> = ({ title }) => {
    const classes = useStyles();
    if (!title) {
        return null;
    }
    if (typeof title === 'string') {
        return (
            <Text variant={'body1'} gutterBottom className={classes.title}>
                {title}
            </Text>
        );
    }
    return <Grid container>{title}</Grid>;
};

export const SimpleTable: React.FC<SimpleTableProps> = props => {
    const { title, rows, elevation, titleInPaper } = props;
    return (
        <React.Fragment>
            {!titleInPaper && <SimpleTableTitle title={title} />}
            <Paper elevation={elevation} {...props.PaperProps}>
                {titleInPaper && <SimpleTableTitle title={title} />}
                <Table size={'small'} style={{ display: 'block' }}>
                    <TableBody style={{ display: 'block' }}>
                        {rows.map((row, idx) => (
                            <SimpleTableRow key={`${_.camelCase(row.name)}-${idx}`} {...row} />
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </React.Fragment>
    );
};
