import { formatBusinessTimeInterval } from './utils/businessTime';
import type { StageStatus, RowDatum } from './utils/getRowData';
import { Assert } from '@orthly/runtime-utils';
import { FlossPalette, TableCell, TableRow, Tooltip, makeStyles, Icon, InfoIcon, Text } from '@orthly/ui-primitives';
import React from 'react';

const { ATTENTION_BACKGROUND, ATTENTION_FOREGROUND, BURGUNDY, GRAY, PRIMARY_BACKGROUND, PRIMARY_FOREGROUND } =
    FlossPalette;

interface RowStyleProps {
    stageIsLate: boolean;
    stageStatus: StageStatus;
    orderIsInProgress: boolean;
}

const useStyles = makeStyles({
    stageRow: ({ stageIsLate, stageStatus, orderIsInProgress }: RowStyleProps) => {
        const cell1: Record<string, unknown> = {};
        const cell3: Record<string, unknown> = {
            whiteSpace: 'nowrap' as const,
        };
        const cell4: Record<string, unknown> = {
            whiteSpace: 'nowrap' as const,
        };
        let backgroundColor: string | undefined = undefined;
        switch (stageStatus) {
            case 'complete':
                if (stageIsLate) {
                    backgroundColor = ATTENTION_BACKGROUND;
                    cell1['color'] = ATTENTION_FOREGROUND;
                } else {
                    cell1['color'] = PRIMARY_FOREGROUND;
                }
                break;
            case 'ongoing':
                cell4['color'] = BURGUNDY;
                cell4['& svg'] = {
                    transform: 'rotateY(180deg)',
                    verticalAlign: 'bottom',
                };
                cell4['& span'] = {
                    fontWeight: 'bold',
                };
                if (orderIsInProgress) {
                    backgroundColor = stageIsLate ? ATTENTION_BACKGROUND : PRIMARY_BACKGROUND;
                    cell1['color'] = stageIsLate ? ATTENTION_FOREGROUND : PRIMARY_FOREGROUND;
                    cell1['borderLeft'] = '4px solid currentcolor';
                    cell1['& span'] = {
                        position: 'relative',
                        left: '-4px',
                    };
                } else {
                    cell1['color'] = GRAY;
                    cell3['color'] = GRAY;
                }
                break;
            case 'upcoming':
                cell3['color'] = GRAY;
                if (!orderIsInProgress) {
                    cell1['color'] = GRAY;
                }
                break;
            default:
                Assert.unreachable(stageStatus);
        }
        return {
            backgroundColor,
            '& td:first-of-type': cell1,
            '& td:nth-of-type(2)': {
                color: GRAY,
                whiteSpace: 'nowrap' as const,
                '& svg': {
                    verticalAlign: 'bottom',
                },
            },
            '& td:nth-of-type(3)': cell3,
            '& td:nth-of-type(4)': cell4,
            '& td:last-of-type': {
                textAlign: 'right' as const,
                ...(stageIsLate && {
                    '& span:last-of-type': {
                        color: ATTENTION_FOREGROUND,
                    },
                }),
            },
        };
    },
});

export const StageRow: React.VFC<RowDatum> = ({
    stage,
    tat,
    start,
    end,
    totalTime,
    breachTime,
    orderIsInProgress,
    stageStatus,
    isEndTimeVisiblyOverridden,
}) => {
    const classes = useStyles({ stageIsLate: !!breachTime, stageStatus, orderIsInProgress });
    const completeOrInProgressOngoing = stageStatus === 'complete' || (stageStatus === 'ongoing' && orderIsInProgress);
    const stageDeadlineInfoIcon = isEndTimeVisiblyOverridden ? (
        <Tooltip
            title={
                <Text variant={'caption'} color={'WHITE'}>
                    The stage deadline has been set to a time that may not match the TAT days value.
                </Text>
            }
        >
            <InfoIcon fontSize={'small'} />
        </Tooltip>
    ) : null;
    return (
        <TableRow className={classes.stageRow}>
            <TableCell>
                <span>{stage}</span>
            </TableCell>
            <TableCell>
                {tat}d {stageDeadlineInfoIcon}
            </TableCell>
            {stageStatus === 'upcoming' ? (
                <TableCell>o/b {start.format(stage.startsWith('Ship') ? 'MM/DD' : 'MM/DD ha')}</TableCell>
            ) : (
                <TableCell>{start.format('MM/DD ha')}</TableCell>
            )}
            {completeOrInProgressOngoing ? (
                <>
                    <TableCell>
                        {stageStatus === 'ongoing' ? (
                            <>
                                <Icon icon={'CachedIcon'} /> <span>Ongoing</span>
                            </>
                        ) : (
                            end.format('MM/DD ha')
                        )}
                    </TableCell>
                    <TableCell>
                        {totalTime && formatBusinessTimeInterval(totalTime)}
                        {breachTime && <span> ({formatBusinessTimeInterval(breachTime)})</span>}
                    </TableCell>
                </>
            ) : (
                <>
                    <TableCell />
                    <TableCell />
                </>
            )}
        </TableRow>
    );
};
