import { getPresetTitle } from '../../../GuidedWaxup.util';
import type { PresetData } from '../ModelViewerFooterBannerHelper';
import { getLeafNodePaths, statusToStringMap } from '../ModelViewerFooterBannerHelper';
import type { RenderedCategoryTree } from '@orthly/graphql-schema';
import { getDesignOrderNoteCategoryTrees, LabsGqlGuidedWaxupPresetStatus } from '@orthly/graphql-schema';
import { FlossPalette, Icon, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const AnnotationLine = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: FlossPalette.ATTENTION_FOREGROUND,
});

export const ModelViewerFooterBannerAnnotations: React.VFC<{ presetData: PresetData }> = ({ presetData }) => {
    const presetTitle = getPresetTitle(presetData.preset_type);

    const leafNodePaths: string[] = React.useMemo(() => {
        const categoryTrees: RenderedCategoryTree[] = presetData?.structured_notes
            ? getDesignOrderNoteCategoryTrees(presetData.structured_notes)
            : [];
        return getLeafNodePaths(categoryTrees);
    }, [presetData?.structured_notes]);

    return (
        <>
            <Text
                variant={'caption'}
                color={
                    presetData?.preset_status === LabsGqlGuidedWaxupPresetStatus.Rejected
                        ? 'ATTENTION_FOREGROUND'
                        : 'DARK_GRAY'
                }
            >
                <strong>{presetTitle}:</strong>{' '}
                {presetData?.preset_status !== LabsGqlGuidedWaxupPresetStatus.Rejected &&
                    statusToStringMap[presetData?.preset_status]}
            </Text>
            {presetData?.preset_status === LabsGqlGuidedWaxupPresetStatus.Rejected && (
                <>
                    {leafNodePaths.map((path: string) => (
                        <AnnotationLine key={path}>
                            <Icon style={{ marginTop: '-5px ' }} icon={'SubdirectoryIcon'} fontSize={'small'} />
                            <Text variant={'caption'} color={'ATTENTION_FOREGROUND'}>
                                {path}
                            </Text>
                        </AnnotationLine>
                    ))}

                    {presetData.notes && (
                        <AnnotationLine>
                            <Icon style={{ marginTop: '-5px ' }} icon={'SubdirectoryIcon'} fontSize={'small'} />
                            <Text variant={'caption'} color={'ATTENTION_FOREGROUND'}>
                                Notes: "{presetData.notes}"
                            </Text>
                        </AnnotationLine>
                    )}
                </>
            )}
        </>
    );
};
