import { LabsGqlDesignOrderNoteCategory } from '@orthly/graphql-schema';

export const isContactDesignOptionDisabled = (
    option: LabsGqlDesignOrderNoteCategory,
    selectionsContain: (options: LabsGqlDesignOrderNoteCategory[]) => boolean,
): boolean => {
    switch (option) {
        case LabsGqlDesignOrderNoteCategory.MesialMakeContactBroader:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.MesialMakeContactLessBroad]);

        case LabsGqlDesignOrderNoteCategory.MesialMakeContactLessBroad:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.MesialMakeContactBroader]);

        case LabsGqlDesignOrderNoteCategory.MesialMakeContactTaller:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.MesialMakeContactLessTall]);

        case LabsGqlDesignOrderNoteCategory.MesialMakeContactLessTall:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.MesialMakeContactTaller]);

        case LabsGqlDesignOrderNoteCategory.DistalMakeContactBroader:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.DistalMakeContactLessBroad]);

        case LabsGqlDesignOrderNoteCategory.DistalMakeContactLessBroad:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.DistalMakeContactBroader]);

        case LabsGqlDesignOrderNoteCategory.DistalMakeContactTaller:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.DistalMakeContactLessTall]);

        case LabsGqlDesignOrderNoteCategory.DistalMakeContactLessTall:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.DistalMakeContactTaller]);

        default:
            return false;
    }
};
