import { useStageState } from '../AppState.hooks';
import { EditSidebarContents } from './EditSidebarContents';
import { ReviewSidebarContents } from './ReviewSidebar';
import { Box, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

export const LeftSidebar: React.VFC = () => {
    const { isReviewComplete } = useStageState();

    return (
        <Box
            sx={{
                width: 248,
                height: '100%',
                borderRight: `1px solid ${FlossPalette.STROKE_LIGHT}`,
                flex: 'none',
            }}
            bgcolor={FlossPalette.TAN}
        >
            {isReviewComplete ? <EditSidebarContents /> : <ReviewSidebarContents />}
        </Box>
    );
};
