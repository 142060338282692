import { AbutmentPartRelationshipRow } from './AbutmentPartRelationshipRow';
import { CreateAbutmentPartRelationship } from './CreateAbutmentPartRelationship';
import { RemoveRelationshipDialog } from './RemoveRelationshipDialog';
import type { AbutmentPartManagementData, AbutmentPartsByManufacturer, IAbutmentRelationshipRow } from './types';
import type { LabsGqlAbutmentPartFragment } from '@orthly/graphql-operations';
import {
    useListAbutmentPartByImplantAndScanbodyQuery,
    useListAbutmentPartsQuery,
    useRemoveAbutmentPartToImplantScanbodyMutation,
} from '@orthly/graphql-react';
import MUITable from '@orthly/mui-table';
import { RootActionDialog, TrashIcon } from '@orthly/ui';
import { Text, IconButton } from '@orthly/ui-primitives';
import { fromPriorityNumberToIsPreferred } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

interface ListAbutmentPartsProps {
    abutmentManagerData: AbutmentPartManagementData;
}

function useAbutmentPartsByManufacturer(abutmentParts?: LabsGqlAbutmentPartFragment[]): AbutmentPartsByManufacturer {
    return React.useMemo<AbutmentPartsByManufacturer>(() => {
        if (!abutmentParts) {
            return {};
        }

        return _.groupBy(abutmentParts, t => t.manufacturer);
    }, [abutmentParts]);
}

const FieldMetadata: React.FC<{ label: string; value: string }> = ({ label, value }) => (
    <div style={{ display: `flex`, flexDirection: `row`, alignItems: `center` }}>
        <Text variant={'body2'}>{label}:</Text>
        <Text medium variant={'body2'} style={{ marginLeft: `8px`, marginRight: `24px` }}>
            {value}
        </Text>
    </div>
);

export const ListAbutmentParts: React.FC<ListAbutmentPartsProps> = ({
    abutmentManagerData: { implantToScanbodyId, implantTypeId, implantMetadata, scanbodyMetadata },
}) => {
    const {
        data,
        refetch,
        error,
        loading: isListAbutmentsRelationshipsLoading,
    } = useListAbutmentPartByImplantAndScanbodyQuery({
        variables: { implantToScanbodyId },
    });
    const { data: fullListOfAbutmentParts, loading: isListAbutmentsLoading } = useListAbutmentPartsQuery({
        variables: { withArchived: true },
    });
    const [removeAbutmentPartRelationship] = useRemoveAbutmentPartToImplantScanbodyMutation();
    const abutmentPartsByManufacturer = useAbutmentPartsByManufacturer(
        fullListOfAbutmentParts?.listAbutmentParts ?? [],
    );
    const [isCreateRelationshipOpen, setIsCreateRelationshipOpen] = React.useState(false);
    const [rowToDelete, setRowToDelete] = React.useState<IAbutmentRelationshipRow | null>(null);

    const abutmentToImpantAndScanbodyRows: IAbutmentRelationshipRow[] =
        data?.listAbutmentPartByImplantAndScanbody.map(relationship => ({
            id: relationship.abutment_part_id,
            implantTypeId,
            implantToScanbodyId,
            abutmentPartId: relationship.abutment_part_id,
            abutmentManufacturer: relationship.abutment_part.manufacturer,
            abutmentSku: relationship.abutment_part.sku,
            isPreferred: fromPriorityNumberToIsPreferred(relationship.priority),
            abutmentDiameterMm: relationship.abutment_part.diameter_mm,
            abutmentGingivalHeightMm: relationship.abutment_part.gingival_height_mm,
            abutmentTopCapHeightMm: relationship.abutment_part.top_cap_height_mm,
        })) ?? [];

    const handleRemoveRow = async ({
        implantTypeId,
        implantToScanbodyId,
        abutmentPartId,
    }: IAbutmentRelationshipRow) => {
        await removeAbutmentPartRelationship({
            variables: {
                data: {
                    abutment_part_id: abutmentPartId,
                    implant_to_scanbody_id: implantToScanbodyId,
                    implant_type_id: implantTypeId,
                },
            },
        });

        await refetch();
    };

    return (
        <div>
            <div>
                <div style={{ display: 'flex', alignItems: `center`, marginBottom: `12px` }}>
                    <Text variant={'body1'} bold style={{ width: `160px` }}>
                        Implant System
                    </Text>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 36 }}>
                        <FieldMetadata label={'Manufacturer'} value={implantMetadata.manufacturer} />
                        <FieldMetadata label={'System'} value={implantMetadata.system} />
                        <FieldMetadata label={'Connection'} value={implantMetadata.connection} />
                    </div>
                </div>

                <div style={{ display: 'flex', alignItems: `center`, marginBottom: `12px` }}>
                    <Text variant={'body1'} bold style={{ width: `160px` }}>
                        Scan body
                    </Text>
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 36 }}>
                        <FieldMetadata label={'Name'} value={scanbodyMetadata.name} />
                        <FieldMetadata label={'Manufacturer'} value={scanbodyMetadata.manufacturer} />
                        <FieldMetadata label={'SKU'} value={scanbodyMetadata.sku} />
                    </div>
                </div>
            </div>
            <MUITable<IAbutmentRelationshipRow>
                title={''}
                data={abutmentToImpantAndScanbodyRows}
                loading={!data && !error}
                DetailPanel={props => (
                    <AbutmentPartRelationshipRow
                        {...props}
                        afterSaved={async () => {
                            await refetch();
                        }}
                    />
                )}
                rowOptions={{ rowHover: true }}
                displayOptions={{
                    elevation: 0,
                    sort: true,
                    filter: true,
                }}
                paginationOptions={{ disable: true }}
                actions={{
                    global: [
                        {
                            icon: 'refresh',
                            position: 'toolbar',
                            tooltip: 'Reload abutment parts to implant & scanbody',
                            onClick: () => refetch(),
                        },
                        {
                            icon: 'add',
                            position: 'toolbar',
                            tooltip: 'Link abutment part to implant & scanbody',
                            onClick: () => setIsCreateRelationshipOpen(true),
                        },
                    ],
                }}
                eventHooks={{
                    onRowClick: (row, actions) => actions.toggleDetailPanel(row),
                }}
                columns={[
                    { name: 'Manufacturer', render: 'abutmentManufacturer' },
                    { name: 'SKU', render: 'abutmentSku' },
                    { name: 'Top Cap Height (mm)', render: 'abutmentTopCapHeightMm', type: 'numeric' },
                    { name: 'Gingival Height (mm)', render: 'abutmentGingivalHeightMm', type: 'numeric' },
                    { name: 'Diameter (mm)', render: 'abutmentDiameterMm', type: 'numeric' },
                    { name: 'Is Preferred?', render: 'isPreferred', type: 'boolean' },
                    {
                        name: 'Remove',
                        filter: false,
                        render: row => (
                            <IconButton
                                onClick={async e => {
                                    // Prevent expanding row
                                    e.stopPropagation();

                                    // For users who know what they're doing, they can bypass the modal by pressing shift
                                    if (e.shiftKey) {
                                        await handleRemoveRow(row);
                                    } else {
                                        setRowToDelete(row);
                                    }
                                }}
                            >
                                <TrashIcon />
                            </IconButton>
                        ),
                    },
                ]}
            />
            <RootActionDialog
                title={'Add abutment part to scanbody'}
                open={isCreateRelationshipOpen}
                setOpen={setIsCreateRelationshipOpen}
                loading={isListAbutmentsRelationshipsLoading || isListAbutmentsLoading}
                hideButton={true}
                content={
                    <CreateAbutmentPartRelationship
                        implantTypeId={implantTypeId}
                        implantToScanbodyId={implantToScanbodyId}
                        abutmentPartsByManufacturer={abutmentPartsByManufacturer}
                        afterSaved={async () => {
                            await refetch();

                            setIsCreateRelationshipOpen(false);
                        }}
                    />
                }
            />
            <RemoveRelationshipDialog
                isRemoveDialogOpen={!!rowToDelete}
                sku={rowToDelete?.abutmentSku ?? ''}
                onClose={() => setRowToDelete(null)}
                onSave={async () => {
                    if (rowToDelete) {
                        await handleRemoveRow(rowToDelete);
                    }

                    setRowToDelete(null);
                }}
            />
        </div>
    );
};
