import type { LabsGqlTicketingSavedSearch } from '@orthly/graphql-schema';
import { LabsGqlSavedSearchVisibility } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { SimpleAutocomplete, PersonIcon } from '@orthly/ui';
import { Text, stylesFactory, FlossPalette, Grid, SearchIcon } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    popupIndicatorOpen: {
        transform: 'none',
    },
    endAdornment: {
        backgroundColor: FlossPalette.WHITE,
        top: 'unset',
        bottom: 16,
        right: 4,
    },
    formControl: {
        transform: 'translate(6px, 21px) scale(1)',
    },
    inputRoot: {
        '&[class*="MuiFilledInput-root"]': {
            backgroundColor: FlossPalette.WHITE,
        },
    },
    inputFocused: {
        // overriding another "important" css
        padding: '8px 4px !important',
    },
    option: {
        padding: '8px 0',
    },
}));

interface SavedViewsAutocompleteProps {
    onSelect: (value: string) => void;
    savedSearches: LabsGqlTicketingSavedSearch[];
}

export const TicketSavedSearchAutocomplete: React.FC<SavedViewsAutocompleteProps> = props => {
    const { onSelect, savedSearches } = props;
    const classes = useStyles();
    const session = useSession();

    return (
        <SimpleAutocomplete
            label={'Find saved searches'}
            variant={'filled'}
            options={
                savedSearches
                    .filter(
                        v =>
                            !session?.user.id ||
                            !v.favorited_by.includes(session.user.id) ||
                            v.visibility === LabsGqlSavedSearchVisibility.Public,
                    )
                    .map(v => {
                        return {
                            value: v.id,
                            label: v.name,
                        };
                    }) ?? []
            }
            TextFieldProps={{ InputLabelProps: { classes: { formControl: classes.formControl } } }}
            AutocompleteProps={{
                style: { width: '100%', height: 48 },
                classes: {
                    popupIndicatorOpen: classes.popupIndicatorOpen,
                    endAdornment: classes.endAdornment,
                    inputRoot: classes.inputRoot,
                    inputFocused: classes.inputFocused,
                    option: classes.option,
                },
                popupIcon: <SearchIcon />,
                renderOption: (props, option) => {
                    const matchingSearch = savedSearches.find(v => v.id === option.value);

                    if (!matchingSearch) {
                        return null;
                    }

                    const renderedOption = (
                        <Grid container style={{ alignItems: 'center', flexWrap: 'nowrap', paddingLeft: 8 }}>
                            <Grid item xs={5}>
                                <Text variant={'body2'}>{matchingSearch.name}</Text>
                            </Grid>
                            <Grid
                                container
                                item
                                style={{
                                    padding: '0 12px',
                                    width: 'fit-content',
                                    alignItems: 'center',
                                    flexWrap: 'nowrap',
                                }}
                            >
                                <PersonIcon style={{ color: FlossPalette.GRAY }} />
                                <Text variant={'caption'} color={'DARK_GRAY'}>
                                    {matchingSearch.favorited_by.length}
                                </Text>
                            </Grid>
                        </Grid>
                    );
                    return <li {...props}>{renderedOption}</li>;
                },
            }}
            initialInputValue={null}
            onChange={value => {
                value && onSelect(value);
            }}
        />
    );
};
