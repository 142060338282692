import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { QuickForm, QuickFormValidations, RootActionDialog, styled, useRootActionCommand } from '@orthly/ui';
import { FlossPalette, Icon, IconButton, Text } from '@orthly/ui-primitives';
import React from 'react';

const EditBillingContactEmail_Mutation = graphql(`
    mutation EditBillingContacts($data: EditBillingContactsCommand!) {
        editBillingContacts(data: $data) {
            id
        }
    }
`);

type EditBillingContactEmailProps = {
    practiceId: string;
    contactEmails: string[];
    refetch: () => void;
};

const FlexContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const EmailText = styled(Text)({
    maxWidth: '140px',
    textWrap: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
});

export const EditBillingContact: React.FC<EditBillingContactEmailProps> = ({
    practiceId,
    contactEmails = [],
    refetch,
}) => {
    const [open, setOpen] = React.useState(false);
    const editBillingContacts = useMutation(EditBillingContactEmail_Mutation);
    const { submit, submitting } = useRootActionCommand(editBillingContacts, {
        onSuccess: () => {
            refetch();
            setOpen(false);
        },
    });

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Edit Billing Contact Email(s)'}
            buttonText={''}
            CustomButton={({ onClick }) => (
                <FlexContainer>
                    <EmailText variant={'caption'}>{contactEmails?.join(', ')}</EmailText>
                    <IconButton onClick={onClick} size={'small'}>
                        <Icon
                            icon={'PencilOutlinedIcon'}
                            fontSize={'small'}
                            sx={{ color: FlossPalette.PRIMARY_FOREGROUND, minWidth: '24px' }}
                        />
                    </IconButton>
                </FlexContainer>
            )}
            content={
                <QuickForm<{ contact_emails: string[] }>
                    fields={{
                        contact_emails: {
                            type: 'array',
                            of: { type: 'text', validation: QuickFormValidations.email },
                            label: 'Contact Email',
                            min: 1,
                        },
                    }}
                    initialValues={{ contact_emails: contactEmails }}
                    onSubmit={async result => {
                        void submit({
                            data: { partner_id: practiceId, billing_contact_emails: result.contact_emails },
                        });
                    }}
                    dirtySubmitOnly={true}
                />
            }
        />
    );
};
