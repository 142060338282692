import {
    getShorthandLabelForCapability,
    getCapabilitiesForTask,
    getLabelForCapability,
} from '../../../../utils/Capabilities';
import { focusAreaExistsInList, type FocusArea } from '../../../../utils/FocusAreas';
import type { LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment } from '@orthly/graphql-operations';
import type { LabsGqlOrderDesignCapabilityLevel, LabsGqlOrderDesignCapabilityType } from '@orthly/graphql-schema';
import { LabsGqlDesignTaskType } from '@orthly/graphql-schema';
import {
    DesignPrepCapabilities,
    InternalDesignCapabilities,
    DesignReviewCapabilities,
    ModelDesignCapabilities,
    OrderDesignCapabilityType,
    OrderDesignCapabilityLevel,
} from '@orthly/shared-types';
import type { IOrderDesignCapabilityDTO } from '@orthly/shared-types';
import {
    Text,
    FormControlLabel,
    CheckboxPrimitive,
    Tooltip,
    FormGroup,
    makeStyles,
    createStyles,
} from '@orthly/ui-primitives';
import { pick } from 'lodash';
import React from 'react';

const focusAreaCheckboxDisabled = (
    focusAreaForCheckbox: FocusArea,
    designersCapabilities: LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment,
): boolean => {
    const designersCapabilitiesForTask = Object.entries(
        getCapabilitiesForTask(designersCapabilities, focusAreaForCheckbox.task_type),
    );
    return !designersCapabilitiesForTask.some(
        ([k, l]) => k === focusAreaForCheckbox.capability && l && focusAreaForCheckbox.level <= l,
    );
};

// Currently, AutomateReview tasks will only have a posterior C&B P1 requirement (they are restricted by what cases we
// can send to 3Shape Automate), so we only need to show that option when selecting the AutomateReview focus area.
const AutomateReviewCapabilities = {
    [OrderDesignCapabilityType.posterior_crown_and_bridge]: pick(
        InternalDesignCapabilities[OrderDesignCapabilityType.posterior_crown_and_bridge],
        OrderDesignCapabilityLevel.level1,
    ),
};

function getFocusAreasFromCapabilities(): FocusArea[] {
    const capabilityTaskPairs = {
        [LabsGqlDesignTaskType.DesignPrep]: DesignPrepCapabilities,
        [LabsGqlDesignTaskType.InternalDesign]: InternalDesignCapabilities,
        [LabsGqlDesignTaskType.DesignReview]: DesignReviewCapabilities,
        [LabsGqlDesignTaskType.ModelDesign]: ModelDesignCapabilities,
        [LabsGqlDesignTaskType.AutomateReview]: AutomateReviewCapabilities,
    };

    return Object.entries(capabilityTaskPairs)
        .map(entry => {
            return flattenCapabilities(entry[1], entry[0] as LabsGqlDesignTaskType);
        })
        .flat();
}

function flattenCapabilities(
    capabilities: Record<string, Record<string, IOrderDesignCapabilityDTO>>,
    taskType: LabsGqlDesignTaskType,
): FocusArea[] {
    const flattenedCapabilities = Object.entries(capabilities)
        .map(([_, v]) => Object.entries(v).map(([_, b]) => b))
        .flat();

    return flattenedCapabilities.map<FocusArea>(c => {
        return {
            capability: c.type as unknown as LabsGqlOrderDesignCapabilityType,
            level: c.level as unknown as LabsGqlOrderDesignCapabilityLevel,
            task_type: taskType,
        };
    });
}

const useStyles = makeStyles(() =>
    createStyles({
        capabilitySectionTitle: {
            marginTop: 12,
        },
        checkboxDisplayed: {
            display: 'none',
        },
    }),
);

export const FocusAreaCheckboxes: React.FC<{
    capability: LabsGqlOrderDesignCapabilityType;
    allCapabilitiesAndLevels: LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment;
    statefulFocusAreas: FocusArea[];
    currentFocusTask: LabsGqlDesignTaskType | null;
    handleCheckboxChange: (fa: FocusArea) => void;
}> = props => {
    const { capability, allCapabilitiesAndLevels, statefulFocusAreas, currentFocusTask, handleCheckboxChange } = props;
    const classes = useStyles();
    const focusAreasToDisplay = getFocusAreasFromCapabilities().filter(
        focusArea =>
            focusArea.capability === capability &&
            focusArea.task_type === currentFocusTask &&
            focusArea.level !== 'other',
    );
    const shouldUseSimplifiedLabels =
        !!currentFocusTask &&
        [LabsGqlDesignTaskType.ModelDesign, LabsGqlDesignTaskType.AutomateReview].includes(currentFocusTask);

    if (focusAreasToDisplay.length === 0) {
        return null;
    }

    return (
        <>
            <Text variant={'body2'} className={classes.capabilitySectionTitle}>
                {getLabelForCapability(capability, shouldUseSimplifiedLabels)}
            </Text>
            <FormGroup row key={capability}>
                {focusAreasToDisplay.map(focusArea => {
                    const label = `${getShorthandLabelForCapability(
                        focusArea.capability,
                        focusArea.level,
                        shouldUseSimplifiedLabels,
                    )}`;
                    const disabled = focusAreaCheckboxDisabled(focusArea, allCapabilitiesAndLevels);
                    const checked = focusAreaExistsInList(focusArea, statefulFocusAreas) ? true : false;
                    return (
                        <Tooltip
                            title={
                                disabled
                                    ? `Capability ${focusArea.capability}: ${focusArea.level} must be enabled to be added as a focus area.`
                                    : `Enable as focus area.`
                            }
                            arrow
                            placement={'top'}
                            key={`${focusArea.task_type}_${focusArea.capability}_${focusArea.level}`}
                        >
                            <FormControlLabel
                                control={
                                    <CheckboxPrimitive
                                        color={'secondary'}
                                        checked={checked}
                                        onChange={() => handleCheckboxChange(focusArea)}
                                    />
                                }
                                value={focusArea}
                                label={label}
                                aria-label={label}
                                disabled={disabled}
                            />
                        </Tooltip>
                    );
                })}
            </FormGroup>
        </>
    );
};
