import type {
    ScanReviewPartialScene,
    ScanReviewActiveJawData,
    ScanReviewSceneStateManager,
} from '../ScanReviewSceneTypes';
import type { ScanReviewViewManager } from '../ScanReviewViewTypes';
import type { ScanReviewUndercutToolLiveObjectsProvider } from './ScanReviewUndercutToolServices';
import { Jaw } from '@orthly/shared-types';
import * as THREE from 'three';

export class ScanReviewUndercutToolController {
    get group() {
        return this.liveObjectsProvider.group;
    }

    static makeController(
        viewManager: ScanReviewViewManager,
        scene: ScanReviewPartialScene,
        sceneStateManager: ScanReviewSceneStateManager,
        activeJawData?: ScanReviewActiveJawData,
        liveObjectsProvider?: ScanReviewUndercutToolLiveObjectsProvider,
        onInsertionAxisUpdate?: (jaw: Jaw, newInsertionAxis: THREE.Vector3) => void,
    ) {
        return (
            activeJawData &&
            liveObjectsProvider &&
            new ScanReviewUndercutToolController(
                activeJawData,
                viewManager,
                scene,
                sceneStateManager,
                liveObjectsProvider,
                onInsertionAxisUpdate,
            )
        );
    }

    constructor(
        public readonly activeJawData: ScanReviewActiveJawData,
        private readonly viewManager: ScanReviewViewManager,
        private readonly scene: ScanReviewPartialScene,
        private readonly sceneStateManager: ScanReviewSceneStateManager,
        private readonly liveObjectsProvider: ScanReviewUndercutToolLiveObjectsProvider,
        private readonly onInsertionAxisUpdate?: (jaw: Jaw, newInsertionAxis: THREE.Vector3) => void,
    ) {
        this.scene.updateUndercutDisplay(this.activeJawData.jaw, this.activeJawData.insertionAxis);
    }

    updateInsertionAxisFromView() {
        if (!this.viewManager.cameraRef.current) {
            return;
        }
        const cameraDirection = new THREE.Vector3();
        this.viewManager.cameraRef.current.getWorldDirection(cameraDirection);
        this.liveObjectsProvider.setInsertionAxisDirection(cameraDirection);
        this.scene.updateUndercutDisplay(this.activeJawData.jaw, cameraDirection);
        this.activeJawData.jaw === Jaw.UPPER
            ? this.sceneStateManager.setUpperJawInsertionAxis(cameraDirection)
            : this.sceneStateManager.setLowerJawInsertionAxis(cameraDirection);
        this.onInsertionAxisUpdate?.(this.activeJawData.jaw, cameraDirection);
        return cameraDirection;
    }
}
