import { useSubmitWaxupReview } from './OrderDesignActions.graphql';
import { OrderWaxupRejectionWithAnnotations } from './OrderWaxupRejectionWithAnnotations';
import type { WaxupSidebarProps, CurrentWaxup } from './Waxups.types';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { OrderDesignPreviewDesign_FragmentFragmentDoc, getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlLabOrderWaxupReviewStatus, type LabsGqlWaxupReviewRejectionInput } from '@orthly/graphql-schema';
import { TOOLBAR_TABLET_DOWN_HEIGHT } from '@orthly/ui';
import { QuickForm, RootActionDialog } from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import type { ButtonProps } from '@orthly/ui-primitives';
import {
    createStyles,
    makeStyles,
    FlossPalette,
    useScreenIsMobileOrVerticalTablet,
    Dialog,
    Grid,
    Button,
    Text,
    ChevronLeft,
} from '@orthly/ui-primitives';
import React from 'react';
import safeAreaInsets from 'safe-area-insets';

interface MobilePageLayoutProps {
    title: string;
    onBack: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backIcon: {
            color: FlossPalette.STAR_GRASS,
            display: 'flex',
        },
        pageWrapper: {
            height: '100%',
            paddingBottom: 80 - safeAreaInsets.bottom,
            flexWrap: 'nowrap',
            overflowY: 'auto',
        },
        toolbar: {
            alignItems: 'center',
        },
        toolbarContainer: {
            minHeight: TOOLBAR_TABLET_DOWN_HEIGHT,
            height: TOOLBAR_TABLET_DOWN_HEIGHT,
            borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
            background: FlossPalette.TAN,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: theme.zIndex.appBar,
            '@media print': {
                display: 'none',
            },
        },
    }),
);

const Mobile: React.FC<MobilePageLayoutProps> = ({ title, children, onBack }) => {
    const classes = useStyles();
    return (
        <Grid
            container
            direction={'column'}
            className={classes.pageWrapper}
            style={{
                paddingTop: TOOLBAR_TABLET_DOWN_HEIGHT,
            }}
        >
            <Grid container className={classes.toolbarContainer}>
                <Grid container className={classes.toolbar}>
                    <Grid
                        item
                        xs={1}
                        style={{ marginLeft: 16 }}
                        data-test={'mobile-header-back-button'}
                        onClick={() => onBack()}
                    >
                        <ChevronLeft className={classes.backIcon} />
                    </Grid>
                    <Grid
                        item
                        style={{
                            flexGrow: 1,
                            padding: '12px 16px',
                        }}
                    >
                        <Text variant={'body1'}>{title}</Text>
                    </Grid>
                </Grid>
            </Grid>
            {children}
        </Grid>
    );
};

interface WaxupResponseProps {
    order: Pick<LabsGqlOrder, 'id' | 'items_v2' | 'patient'>;
    open: boolean;
    setOpen: (open: boolean) => void;
    refetch: () => Promise<void>;
    userRole: string;
    approval: boolean;
    CustomButton: React.FC<ButtonProps>;
    currentWaxup?: CurrentWaxup;
}

const OrderWaxupResponseAction: React.FC<WaxupResponseProps> = props => {
    const { open, setOpen, order, refetch, approval, CustomButton, currentWaxup } = props;
    const isMobile = useScreenIsMobileOrVerticalTablet();

    const { submit, submitting } = useSubmitWaxupReview(async () => {
        await refetch();
        setOpen(false);
    });

    // For the new rejection flow with annotations only:
    // Hang on to rejection notes at this level, so if the doctor closes & re-opens the dialog we
    // can preserve what they typed.
    const rejectionNotesRef = React.useRef<string>('');

    React.useEffect(() => {
        if (open) {
            BrowserAnalyticsClientFactory.Instance?.track('Waxup Review - Started', {
                $groups: { order: order.id },
                isApproving: approval,
            });
        }
    }, [approval, order.id, open]);

    const handleRejectionWithAnnotations = React.useCallback(
        async (data: LabsGqlWaxupReviewRejectionInput) => {
            const dataToSave = {
                order_id: order.id,
                rejection: {
                    notes: data.notes ?? ``,
                    annotations: data.annotations ?? [],
                },
            };

            await submit(dataToSave);

            BrowserAnalyticsClientFactory.Instance?.track('Waxup Review - Submitted', {
                $groups: { order: order.id },
                annotationCount: data.annotations?.length ?? 0,
                isApproved: false,
            });

            setOpen(false);
        },
        [setOpen, order.id, submit],
    );

    if (currentWaxup && !approval) {
        const title = `What do you need changed?`;
        const AnnotationsUI = (
            <OrderWaxupRejectionWithAnnotations
                order={order}
                currentWaxup={currentWaxup}
                onSubmit={handleRejectionWithAnnotations}
                onCancel={() => setOpen(false)}
                isSaveDisabled={submitting}
                refetch={refetch}
                notesRef={rejectionNotesRef}
            />
        );

        if (isMobile) {
            return (
                <>
                    <CustomButton variant={'ghost'} onClick={() => setOpen(true)} />
                    <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
                        <Mobile title={title} onBack={() => setOpen(false)}>
                            {AnnotationsUI}
                        </Mobile>
                    </Dialog>
                </>
            );
        }

        return (
            <RootActionDialog
                loading={false}
                open={open}
                setOpen={setOpen}
                title={<Text variant={`h4`}>{title}</Text>}
                titleProps={{
                    style: {
                        alignItems: 'center',
                    },
                }}
                content={AnnotationsUI}
                CustomButton={CustomButton}
                dialogProps={{ maxWidth: 'md' }}
                PaperProps={{ style: { height: 800, minHeight: 600 } }}
                contentProps={{ style: { padding: 0 }, dividers: false }}
                showCloseButton
                fullHeight
            />
        );
    }

    return (
        <RootActionDialog
            loading={false}
            open={open}
            setOpen={setOpen}
            title={approval ? 'Approve waxup' : 'Request changes'}
            content={
                <Grid container>
                    <QuickForm<{ reason?: string }>
                        fields={{
                            reason: {
                                type: 'text',
                                hidden: !!approval,
                                optional: !!approval,
                                label: 'Design feedback',
                                fieldProps: { multiline: true, minRows: 3, maxRows: 100 },
                            },
                        }}
                        submitButtonProps={{ children: approval ? 'Approve waxup' : 'Send request' }}
                        initialValues={{ reason: undefined }}
                        disabled={submitting}
                        onSubmit={async data => {
                            await submit({
                                order_id: order.id,
                                rejection: data.reason ? { notes: data.reason } : null,
                            });

                            BrowserAnalyticsClientFactory.Instance?.track('Waxup Review - Submitted', {
                                $groups: { order: order.id },
                                annotationCount: 0,
                                isApproved: approval,
                            });
                        }}
                    />
                </Grid>
            }
            style={{ padding: 0 }}
            buttonText={'Submit Response'}
            CustomButton={CustomButton}
        />
    );
};

// Determines if a given action in the topbar is actually visible to the current user.
function getIsApproveRejectVisible(
    order: Pick<LabsGqlOrder, 'waxup_status'>,
    currentWaxup: CurrentWaxup | undefined,
    userRole: WaxupSidebarProps['userRole'],
) {
    // Manufacturers cannot approve/reject
    if (userRole === 'manufacturer' || userRole === 'external') {
        return false;
    }

    // Cannot take additional actions if waxup is not ready for review
    if (order.waxup_status !== LabsGqlLabOrderWaxupReviewStatus.ReadyForReview) {
        return false;
    }

    const design = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, currentWaxup?.currentDesignFragment);
    // cannot approve or reject if viewing a previous rejection
    if (!design?.is_latest_design || design?.doctor_review) {
        return false;
    }

    // cannot approve or reject if there is no current design (or it has not been loaded)
    // ...unless you are a psr, in which case go for it
    return userRole === 'psr' || !!design?.assets?.length;
}

export const WaxupSidebarControls: React.FC<WaxupSidebarProps & { rootStyle?: React.CSSProperties }> = props => {
    const { currentWaxup, refetchOrder, userRole, order } = props;
    const [approvalOpen, setApprovalOpen] = React.useState(false);
    const [rejectionOpen, setRejectionOpen] = React.useState(false);

    const isApproveRejectVisible = getIsApproveRejectVisible(order, currentWaxup, userRole);
    // This ensures currentWaxup is defined
    if (!isApproveRejectVisible) {
        return null;
    }

    return (
        <Grid container direction={'column'} style={props.rootStyle}>
            <OrderWaxupResponseAction
                {...props}
                open={approvalOpen}
                setOpen={setApprovalOpen}
                approval={true}
                CustomButton={({ onClick }) => (
                    <Button variant={'primary'} onClick={onClick} data-test={'waxup-accept'}>
                        Accept &amp; fabricate
                    </Button>
                )}
                refetch={refetchOrder}
            />
            <OrderWaxupResponseAction
                {...props}
                open={rejectionOpen}
                setOpen={setRejectionOpen}
                approval={false}
                CustomButton={({ onClick }) => (
                    <Button
                        variant={'secondary'}
                        style={{ backgroundColor: FlossPalette.WHITE, marginTop: '8px' }}
                        onClick={onClick}
                        data-test={'waxup-request-changes'}
                    >
                        Request changes…
                    </Button>
                )}
                refetch={refetchOrder}
            />
        </Grid>
    );
};
