import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useHasCapability } from '@orthly/session-client';
import { useRootActionCommand } from '@orthly/ui';
import { Icon, IconButton } from '@orthly/ui-primitives';
import React from 'react';

const AdminManufacturerTruckTimeDeleteButton_Mutation = graphql(`
    mutation AdminManufacturerTruckTimeDeleteButton_Mutation($id: String!) {
        deleteManufacturerTruckTime(payload: { id: $id }) {
            id
        }
    }
`);

interface ManufacturerTruckTimeDeleteButtonProps {
    truckTimeId: string;
    onSuccess: () => Promise<void>;
}

export const ManufacturerTruckTimeDeleteButton: React.VFC<ManufacturerTruckTimeDeleteButtonProps> = ({
    truckTimeId,
    onSuccess,
}) => {
    const deleteMtn = useMutation(AdminManufacturerTruckTimeDeleteButton_Mutation);
    const { submit: submitDelete, submitting: submittingDelete } = useRootActionCommand(deleteMtn, {
        successMessage: 'Deleted new truck time.',
        onSuccess,
    });

    const hasCapability = useHasCapability('lab_management', 'lab_management.truck_times.delete');
    if (!hasCapability) {
        return null;
    }

    return (
        <IconButton onClick={() => submitDelete({ id: truckTimeId })} disabled={submittingDelete}>
            <Icon icon={'TrashCanIcon'} />
        </IconButton>
    );
};
