import { AbutmentPartInput } from './AbutmentPartInput';
import { formatAbutmentPartsSpecsText, fromPriorityNumberToIsPreferred } from './helpers';
import type { LabsGqlAbutmentPartToImplantAndScanbodyFragment } from '@orthly/graphql-operations';
import { useListAbutmentPartByImplantAndScanbodyQuery } from '@orthly/graphql-react';
import { Text } from '@orthly/ui-primitives';
import React from 'react';

export interface AbutmentPartFilters {
    manufacturer?: string;
    engagement?: string;
    material?: string;
    specs?: string;
    showPreferred: boolean;
}

interface AbutmentPartSelectorProps {
    setSelectedAbutmentPartToImplantAndScanbody: (
        abutmentPartToImplantAndScanbody: LabsGqlAbutmentPartToImplantAndScanbodyFragment | null,
    ) => void;
    implantToScanbodyId: string;
}

/**
 * Component used to select an abutment part with dropdowns for manufacturer, material, connection, part specs
 *
 * The setSelectedAbutmentPartToImplantAndScanbody callback will be called with the part from the db if one exists for
 * the values selected in the dropdowns.
 */
export const AbutmentPartSelector: React.VFC<AbutmentPartSelectorProps> = ({
    setSelectedAbutmentPartToImplantAndScanbody,
    implantToScanbodyId,
}) => {
    const { data } = useListAbutmentPartByImplantAndScanbodyQuery({
        variables: { implantToScanbodyId },
    });

    const [abutmentPartFilters, setAbutmentPartFilters] = React.useState<AbutmentPartFilters>({
        showPreferred: true,
    });

    const handleUpdateField = React.useCallback(
        <T extends keyof AbutmentPartFilters>(fieldName: T, fieldValue: AbutmentPartFilters[T]) => {
            setSelectedAbutmentPartToImplantAndScanbody(null);

            // Filters are meant to be used in order: Is Authentic? -> Name -> Manufacturer -> SKU
            // Therefore we reset any filter that is impacted by a change of an earlier filter.
            switch (fieldName) {
                case 'showPreferred':
                    setAbutmentPartFilters(prevValue => ({
                        ...prevValue,
                        [fieldName]: fieldValue,
                        manufacturer: undefined,
                        material: undefined,
                        engagement: undefined,
                        specs: undefined,
                    }));
                    return;
                case 'manufacturer':
                    setAbutmentPartFilters(prevValue => ({
                        ...prevValue,
                        [fieldName]: fieldValue,
                        material: undefined,
                        engagement: undefined,
                        specs: undefined,
                    }));
                    return;
                case 'material':
                    setAbutmentPartFilters(prevValue => ({
                        ...prevValue,
                        [fieldName]: fieldValue,
                        engagement: undefined,
                        specs: undefined,
                    }));
                    return;
                case 'engagement':
                    setAbutmentPartFilters(prevValue => ({
                        ...prevValue,
                        [fieldName]: fieldValue,
                        specs: undefined,
                    }));
                    return;
                case 'specs':
                    setAbutmentPartFilters(prevValue => ({
                        ...prevValue,
                        [fieldName]: fieldValue,
                    }));
                    return;
            }
        },
        [setSelectedAbutmentPartToImplantAndScanbody],
    );

    React.useEffect(() => {
        if (
            abutmentPartFilters.manufacturer &&
            abutmentPartFilters.material &&
            abutmentPartFilters.engagement &&
            abutmentPartFilters.specs
        ) {
            const existingAbutmentPartToImplantAndScanbody = data?.listAbutmentPartByImplantAndScanbody?.find(
                part =>
                    fromPriorityNumberToIsPreferred(part.priority) === abutmentPartFilters.showPreferred &&
                    part.abutment_part.manufacturer === abutmentPartFilters.manufacturer &&
                    part.abutment_part.material === abutmentPartFilters.material &&
                    part.abutment_part.engagement === abutmentPartFilters.engagement &&
                    // Part specs is split in 3 fields, but in the UI we show them all in 1 dropdown for easier UX
                    formatAbutmentPartsSpecsText(part.abutment_part) === abutmentPartFilters.specs,
            );

            existingAbutmentPartToImplantAndScanbody &&
                setSelectedAbutmentPartToImplantAndScanbody(existingAbutmentPartToImplantAndScanbody);
        }
    }, [abutmentPartFilters, data, setSelectedAbutmentPartToImplantAndScanbody]);

    return (
        <>
            <Text variant={'h6'} style={{ marginBottom: 8 }}>
                Part Selection
            </Text>
            <AbutmentPartInput
                onFieldUpdate={handleUpdateField}
                filters={abutmentPartFilters}
                listParts={data?.listAbutmentPartByImplantAndScanbody ?? []}
            />
        </>
    );
};
