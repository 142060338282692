import type { OrderDesignAnnotatorOnSaveData } from '../Annotations/OrderDesignAnnotator';
import { OrderDesignAnnotator } from '../Annotations/OrderDesignAnnotator';
import type { CurrentWaxup } from './Waxups.types';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import type { LabsGqlWaxupReviewRejectionInput } from '@orthly/graphql-schema';
import React from 'react';

export const OrderWaxupRejectionWithAnnotations: React.VFC<{
    order: Pick<LabsGqlOrder, 'id' | 'items_v2' | 'patient'>;
    currentWaxup: CurrentWaxup;
    onSubmit: (data: LabsGqlWaxupReviewRejectionInput) => Promise<void>;
    onCancel: () => void;
    isSaveDisabled: boolean;
    refetch: () => Promise<void>;
    notesRef: React.MutableRefObject<string>;
}> = ({ order, onCancel, onSubmit, isSaveDisabled, refetch, currentWaxup, notesRef }) => {
    const handleOnSave = React.useCallback(
        async (annotatorData: OrderDesignAnnotatorOnSaveData) => {
            const formattedData: LabsGqlWaxupReviewRejectionInput = {
                notes: annotatorData.notes,
                annotations: annotatorData.annotations.map(annotation => ({
                    image_url: annotation.imageUrl,
                    comment: annotation.comment,
                })),
            };

            await onSubmit(formattedData);
        },
        [onSubmit],
    );

    return (
        <OrderDesignAnnotator
            order={order}
            designFragment={currentWaxup.currentDesignFragment}
            onSave={handleOnSave}
            onCancel={onCancel}
            isSaveDisabled={isSaveDisabled}
            trackingSource={'waxup_rejection'}
            refetchDesign={refetch}
            notesRef={notesRef}
        />
    );
};
