/* eslint-disable max-lines */
import type { OrthlyBrowserConfig } from '../util';
import React from 'react';

const OrthlyConfigContext = React.createContext<{ config?: OrthlyBrowserConfig }>({
    config: undefined,
});

export function OrthlyConfigProvider({ children, config }: { children: React.ReactNode; config: OrthlyBrowserConfig }) {
    return <OrthlyConfigContext.Provider value={{ config }}>{children}</OrthlyConfigContext.Provider>;
}

/**
 * Provides the current config to the app
 * @returns {OrthlyBrowserConfig}
 */
export function useConfig(): OrthlyBrowserConfig {
    const context = React.useContext(OrthlyConfigContext);
    if (!context || !context.config) {
        throw new Error('useConfig must be used within a ConfigProvider');
    }
    return context.config;
}
