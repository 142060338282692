import * as THREE from 'three';

export class CollisionsMeshMaterial extends THREE.MeshBasicMaterial {
    constructor(color?: string | number | THREE.Color | undefined) {
        super({
            color: color ?? 0x000000,
            wireframe: true,
        });
    }
    // customize MeshBasicMaterial to apply a small z-offset to make the collisions lines stand out more (see EPDCAD-950)
    onBeforeCompile(shader: THREE.Shader, renderer: THREE.WebGLRenderer): void {
        super.onBeforeCompile(shader, renderer);
        shader.vertexShader = shader.vertexShader.replace(
            '#include <project_vertex>',
            `#include <project_vertex>
             gl_Position.z -= 0.00008;`,
        );
    }
}
