import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';

type OrderFields = 'fulfillment_workflow' | 'status';

const BeforeLabAcceptStatuses = [LabsGqlLabOrderStatus.New, LabsGqlLabOrderStatus.NeedsReview];

const AllAllowedStatuses = [
    LabsGqlLabOrderStatus.New,
    LabsGqlLabOrderStatus.NeedsReview,
    LabsGqlLabOrderStatus.Fabrication,
    LabsGqlLabOrderStatus.OnHold,
];

export const useIsDesignActionEditable = (
    order: Pick<LabsGqlOrder, OrderFields>,
    options?: { allowAfterLabAccept?: boolean },
): boolean => {
    const { internal_design_required } = order.fulfillment_workflow.configuration;
    if (!AllAllowedStatuses.includes(order.status)) {
        return false;
    }

    if (!options?.allowAfterLabAccept && !BeforeLabAcceptStatuses.includes(order.status)) {
        return false;
    }
    // we can only change the review type for fabrication orders if they were external design
    if (order.status === LabsGqlLabOrderStatus.Fabrication && internal_design_required) {
        return false;
    }
    return true;
};
