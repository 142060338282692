import { useOrder } from '../OrderState.hooks';
import { CaseNotesBlock } from '../SharedUi/CaseNotesBlock';
import { PreferencesBlock } from '../SharedUi/PreferencesBlock';
import { TagsBlock } from '../SharedUi/TagsBlock';
import { ItemsSection } from './ItemsSection';
import { SidebarFooter } from './SidebarFooter';
import { OrderItemV2Utils } from '@orthly/items';
import { styled, StackY } from '@orthly/ui';
import { FlossPalette, Text, Grid, Box } from '@orthly/ui-primitives';
import React from 'react';

const ItemsBlock: React.VFC = () => {
    const order = useOrder();
    const items = OrderItemV2Utils.parseItems(order.items_v2);
    return (
        <Grid style={{ width: '100%' }}>
            <Grid>
                <Text variant={'body2'} medium={true}>
                    Case Information
                </Text>
            </Grid>
            <Grid container>
                <Grid item xs={4} container alignItems={'center'}>
                    <Text variant={'body2'} color={'DARK_GRAY'} style={{ fontWeight: 500 }}>
                        Items
                    </Text>
                </Grid>
                <Grid item xs={4} container alignItems={'center'}>
                    <Text variant={'caption'} color={'DARK_GRAY'} style={{ fontWeight: 500 }}>
                        Material
                    </Text>
                </Grid>
                <Grid item xs={4} container alignItems={'center'}>
                    <Text variant={'caption'} color={'DARK_GRAY'} style={{ fontWeight: 500 }}>
                        Min Thickness
                    </Text>
                </Grid>
            </Grid>
            <Grid style={{ paddingTop: 0, marginBottom: 0 }}>
                <ItemsSection items={items} />
            </Grid>
        </Grid>
    );
};

const SidebarHeader: React.VFC = () => {
    return (
        <Box sx={{ padding: '8px 16px 0px 16px', flex: 'none', borderBottom: `1px solid ${FlossPalette.DARK_TAN}` }}>
            <ItemsBlock />
        </Box>
    );
};

const SidebarBody: React.VFC = () => {
    return (
        <Box sx={{ padding: '0px 16px', flex: 'auto', overflow: 'hidden auto' }}>
            <PreferencesBlock />
            <CaseNotesBlock />
            <TagsBlock />
        </Box>
    );
};

const Root = styled(StackY)({
    width: 248,
    height: '100%',
    borderLeft: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    backgroundColor: FlossPalette.TAN,
});

export const RightSidebarContent: React.VFC = () => {
    return (
        <Root>
            <SidebarHeader />
            <SidebarBody />
            <SidebarFooter />
        </Root>
    );
};
