import { useDesignQaSidebar } from '../DesignQaSidebar.graphql';
import type { FragmentType, VeneerOrderDesignAnalyticsDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { SidebarContainer, ToolbarContainer } from '@orthly/ui';
import { stylesFactory, Grid, Tab, Tabs } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        padding: 0,
        flexWrap: 'nowrap',
        maxHeight: '100vh',
        '&::-webkit-scrollbar': { display: 'none' },
        width: 336,
    },
    toolbar: { alignItems: 'center', alignContent: 'center' },
    contentRoot: {
        padding: 16,
        height: '100vh',
        overflow: 'auto',
        alignContent: 'flex-start',
        position: 'relative',
    },
}));

interface DesignQaResultsSidebarProps {
    order: LabsGqlLabOrderFragment;
    designFragment: FragmentType<typeof VeneerOrderDesignAnalyticsDesign_FragmentFragmentDoc> | undefined;
}

export const DesignQaResultsSidebar: React.VFC<DesignQaResultsSidebarProps> = ({ order, designFragment }) => {
    const classes = useStyles();
    const sidebarEntries = useDesignQaSidebar(order, designFragment);
    const [tab, setTab] = React.useState<number>(0);
    const tabBody = sidebarEntries[tab]?.sidebarBody;

    return (
        <SidebarContainer position={'right'} className={classes.root}>
            <ToolbarContainer className={classes.toolbar}>
                <Tabs
                    indicatorColor={'secondary'}
                    textColor={'inherit'}
                    value={tab}
                    onChange={(_, value) => setTab(value)}
                >
                    {sidebarEntries.map((entry, idx) => (
                        <Tab key={entry.name} value={idx} label={entry.name} />
                    ))}
                </Tabs>
            </ToolbarContainer>
            <Grid container className={classes.contentRoot}>
                {tabBody}
            </Grid>
        </SidebarContainer>
    );
};
