import { RootActionDialog } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

export const PricingGuideLink: React.FC<{ InjectedPricingGuideComponent?: React.ReactElement }> = ({
    InjectedPricingGuideComponent,
}) => {
    const [open, setOpen] = React.useState(false);
    if (!InjectedPricingGuideComponent) {
        return null;
    }
    return (
        <>
            <Button inline variant={'ghost'} onClick={() => setOpen(true)} endIcon={'MonetizationOnOutlinedIcon'}>
                View pricing guide
            </Button>
            <RootActionDialog
                loading={false}
                open={open}
                setOpen={setOpen}
                title={null}
                content={InjectedPricingGuideComponent}
                hideButton={true}
                maxWidth={'lg'}
                onClose={() => setOpen(false)}
                showCloseButton={true}
                closeIconButtonProps={{ style: { margin: 16 } }}
            />
        </>
    );
};
