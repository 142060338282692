import type { SimpleAutocompleteOption } from './SimpleAutocomplete';
import type { TextFieldProps, AutocompleteProps } from '@orthly/ui-primitives';
import { TextField, Autocomplete } from '@orthly/ui-primitives';
import React from 'react';
import type { ListChildComponentProps } from 'react-window';
import { FixedSizeList } from 'react-window';

// this part helpfully borrowed verbatim from the MUI docs,
// https://v4.mui.com/components/autocomplete/#virtualization
//
// it is required to have the hover effect over individual items
const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;

    const Component = data[index];

    if (!Component) {
        return null;
    }

    return (
        <Component.type
            {...Component.props}
            style={{
                ...style,
                top: style.top,
            }}
        />
    );
}

interface ListboxProps {
    children: React.ReactChildren;
    optionHeight: number;
    maxOptionCount: number;
}

const ListboxComponent = React.forwardRef((props: ListboxProps, ref: React.Ref<HTMLDivElement>) => {
    const { children, optionHeight, maxOptionCount, ...outerElProps } = props;

    const itemCount = React.Children.count(children);

    const totalHeight = React.useMemo(() => {
        // if there are more than `maxOptionCount` items, truncate the size to `maxOptionCount`
        if (itemCount > maxOptionCount) {
            return maxOptionCount * optionHeight;
        }

        return itemCount * optionHeight;
    }, [itemCount, optionHeight, maxOptionCount]);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={outerElProps}>
                <FixedSizeList
                    itemData={children}
                    height={totalHeight}
                    width={'100%'}
                    outerElementType={OuterElementType}
                    innerElementType={'ul'}
                    itemSize={optionHeight}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </FixedSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

export interface VirtualizedAutocompleteProps {
    label: string;
    style?: React.CSSProperties;
    onChange?: (value: string | null) => void;

    /**
     * The height, in pixels, that an option row takes up
     */
    optionHeight: number;

    /**
     * The maximum number of options to display on screen at once
     */
    maxOptionCount: number;

    onInputChange?: (value: string | null) => void;
    disabled?: boolean;
    error?: string | boolean;
    helperText?: string;
    variant?: 'standard' | 'filled' | 'outlined';
    AutocompleteProps?: Partial<AutocompleteProps<any, any, any, any>>;
    TextFieldProps?: Partial<TextFieldProps>;
    initialInputValue?: string | null;
    autoFocus?: boolean;
    options: SimpleAutocompleteOption[];
}

export function VirtualizedAutocomplete(props: VirtualizedAutocompleteProps) {
    const [inputValue, setInputValue] = React.useState<string>(props.initialInputValue ?? '');

    // TODO: Resolve the 'as any'
    return (
        <Autocomplete
            style={props.style}
            disableListWrap
            inputValue={inputValue}
            ListboxComponent={
                // we must do this chain of casts in order for typescript to accept
                // that this is typesafe
                ListboxComponent as React.ComponentType<
                    React.HTMLAttributes<HTMLElement> & ListboxProps
                > as React.ComponentType<React.HTMLAttributes<HTMLElement>>
            }
            ListboxProps={{ optionHeight: props.optionHeight, maxOptionCount: props.maxOptionCount } as any}
            options={props.options}
            onChange={(_event, value) => {
                props.onChange?.(value ? value.value : null);
            }}
            onInputChange={(_event, value) => {
                props.onInputChange?.(value);
                setInputValue(value);
            }}
            getOptionLabel={option => (typeof option === 'string' ? option : option.label || option.value)}
            renderInput={params => (
                <TextField
                    {...params}
                    fullWidth
                    autoFocus={props.autoFocus}
                    style={{ margin: 0 }}
                    label={props.label}
                    margin={'normal'}
                    error={!!props.error}
                    variant={props.variant || 'standard'}
                    helperText={(typeof props.error === 'string' ? props.error : undefined) || props.helperText}
                    {...props.TextFieldProps}
                    InputProps={{
                        ...params.InputProps,
                        ...props.TextFieldProps?.InputProps,
                        startAdornment: props.TextFieldProps?.InputProps?.startAdornment,
                    }}
                    inputProps={{
                        ...params.inputProps,
                        ...props.TextFieldProps?.inputProps,
                    }}
                />
            )}
            {...props.AutocompleteProps}
        />
    );
}
