import type { VeneerOrderDesignAnalyticsDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import type { LabsGqlDesignEventLogMetadataFragment } from '@orthly/graphql-operations';
import { Format } from '@orthly/runtime-utils';
import type { UnpackArray } from '@orthly/runtime-utils';

export type EventLogMetadataKeys = keyof LabsGqlDesignEventLogMetadataFragment;

// Maps the metadata key to a human-readable label
// If a value is `null`, it will not be rendered.
// The [label, key] pairs are rendered in the order that the keys are ordered in this map.
export const DesignEventLogMetadataKeyLabelMap: { [K in EventLogMetadataKeys]: string | null } = {
    __typename: null,
    cumulative_gap_between_sessions: null,
    // Top-level statistics that aren't part of the core workflow.
    number_sessions: 'Number of Sessions',
    cumulative_session_time: 'Total Duration',
    idle_duration: 'Idle Duration',

    // These are sorted in the order they appear in Dental Designer.
    prepare_step_duration: 'Prepare Duration',
    segmentation_step_duration: 'Segmentation Duration',
    directions_step_duration: 'Directions Duration',
    interfaces_step_duration: 'Interfaces Duration',
    anatomy_pre_design_step_duration: 'Anatomy Pre-Design Duration',
    anatomy_design_step_duration: 'Anatomy Design Duration',
    frame_design_step_duration: 'Frame Design Duration',
    abutments_post_and_core_step_duration: 'Post & Core Duration',
};

// All the metadata keys which should be rendered as a simple count instead of as a duration.
export const MetadataKeysFormattedAsCount: EventLogMetadataKeys[] = ['number_sessions'];

type EventAggregateRow = UnpackArray<VeneerOrderDesignAnalyticsDesign_FragmentFragment['event_log_event_aggregates']>;

type DesignEventLogAggregateConfig = {
    label: string;

    format: (agg: EventAggregateRow) => string;
};

const standardUsagesWithTime = (agg: EventAggregateRow) =>
    `${Format.pluralize('usage', agg.count)}, ${Format.duration(agg.cumulative_duration)}`;

const standardClicksWithTime = (agg: EventAggregateRow) =>
    `${Format.pluralize('click', agg.count)}, ${Format.duration(agg.cumulative_duration)}`;

// A map of aggregate names to a configuration to render them.
// Any aggregate name not in this map will not be rendered.
export const DesignEventLogAggregateConfigMap: Record<string, DesignEventLogAggregateConfig> = {
    IAlignToBite: {
        label: 'Align to Bite',
        format: agg => Format.pluralize('usage', agg.count),
    },
    TODentalScanTrimmingFeature: {
        label: 'Scan Trimming',
        format: standardUsagesWithTime,
    },
    TSculpt_AddRemoveSmooth: {
        label: 'Scan Sculpting: Add/Remove/Smooth',
        format: standardClicksWithTime,
    },
    TSculpt_RemoveArtifacts: {
        label: 'Sculpting: Remove Artifacts',
        format: standardUsagesWithTime,
    },
    TOPreparationLineFeature: {
        label: 'Margin Line',
        format: agg => Format.duration(agg.cumulative_duration),
    },
    TSDrillDirectionFeature: {
        label: 'Insertion Direction',
        format: standardUsagesWithTime,
    },
    IDentalVirtualArticulation: {
        label: 'Virtual Articulator Opened',
        format: agg => Format.pluralize('usage', agg.count),
    },
    TSculpt_DentalSplineCutAndCloseHole: {
        label: 'Spline Cut & Close Hole',
        format: standardUsagesWithTime,
    },
    TSculpt_DentalPlaneCut: {
        label: 'Plane Cut',
        format: standardUsagesWithTime,
    },
    TSculpt_DentalAttachableComponents: {
        label: 'Attachable Components',
        format: standardUsagesWithTime,
    },
    TSculpt_DentalGroupsTransformations: {
        label: 'Group Transformation',
        format: standardClicksWithTime,
    },
    TSculpt_DentalIndividualTransformations: {
        label: 'Individual Transformations',
        format: standardClicksWithTime,
    },
    TSculpt_DentalPointSilhouetteMorph: {
        label: 'Individual Morphing',
        format: standardClicksWithTime,
    },
    TSculpt_DentalAddRemoveSmoothRefModel: {
        label: 'Wax Knife : Add/Remove/Smooth',
        format: standardClicksWithTime,
    },
    TSculpt_DentalOpsAndParams: {
        label: 'Ops & Params',
        format: standardUsagesWithTime,
    },
    TSculpt_DentalMirrorTooth: {
        label: 'Mirror Tooth',
        format: standardUsagesWithTime,
    },
    TSculpt_DentalArcTransformation: {
        label: 'Arch Transformation',
        format: standardUsagesWithTime,
    },
    TSculpt_DentalResetDesign: {
        label: 'Reset Design',
        format: agg => Format.pluralize('usage', agg.count),
    },
    TSculpt_NonUserControlledOperation: {
        label: 'Non-User Controlled Operation',
        format: standardUsagesWithTime,
    },
    TSculpt_DentalMorphToAdditionalScan: {
        label: 'Morph to Additional Scan',
        format: standardUsagesWithTime,
    },
    TSculpt_SmileAutoPlacement: {
        label: 'Smile Auto Placement',
        format: standardUsagesWithTime,
    },
};
