import type { InvoiceItemRow } from './InvoiceTable.types';
import { stylesFactory } from '@orthly/ui-primitives';

export interface ManualInvoiceItemEditProps {
    invoiceItem?: InvoiceItemRow;
    setOpenManualChargeEdit: (openManualChargeEdit: boolean) => void;
    refetch: () => Promise<any>;
}

export const useManualInvoiceStyles = stylesFactory(() => ({
    contentBody: {
        marginBottom: 20,
    },
    cancelButton: {
        marginRight: 8,
    },
    textInput: {
        // using !important here because MUI is stubborn
        padding: `5px 0px 10px 12px !important`,
        alignItems: 'baseline',
    },
    amountInput: {
        // using !important here because MUI is stubborn
        padding: `12px 0px 3px !important`,
    },
}));
