import type { ItemEditorV2BaseProps } from '../../../types/ItemEditorV2BaseProps';
import { useAutoSetItemDataFieldDefaultValue } from './ItemEditorV2ItemDataField.util';
import type { BooleanItemDataField, ItemDataFieldId, LabOrderItemSKUType } from '@orthly/items';
import { ItemDataFieldUtils } from '@orthly/items';
import { SimpleSelect } from '@orthly/ui';
import React from 'react';

const getStringFromBoolean = (val: boolean | null | undefined): string | undefined => {
    if (val === true) {
        return 'Yes';
    }
    if (val === false) {
        return 'No';
    }
    return undefined;
};

const getBooleanFromString = (val: string | undefined): boolean | null => {
    if (val === 'Yes') {
        return true;
    }
    if (val === 'No') {
        return false;
    }
    return null;
};

interface ItemEditorV2ItemDataFieldProps<ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>
    extends ItemEditorV2BaseProps<SKU> {
    field: BooleanItemDataField<ID, SKU>;
}

export const ItemEditorV2BooleanItemDataField = <ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>({
    field,
    item,
    updateItem,
    showError,
}: ItemEditorV2ItemDataFieldProps<ID, SKU>): React.ReactElement => {
    const value = field.getValue(item);

    const applyUpdate = (val: string | undefined) => {
        updateItem(field.getUpdatePayload(getBooleanFromString(val), item));
    };

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    return (
        <SimpleSelect
            label={field.label}
            value={getStringFromBoolean(value)}
            options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
            ]}
            onChange={val => applyUpdate(val)}
            errorText={
                showError && ItemDataFieldUtils.isItemDataFieldRequired(field, item) && !field.isComplete(item)
                    ? 'This field is required'
                    : undefined
            }
        />
    );
};
