import { VisibilityToggleIcon } from '../GuidedWaxup.util';
import { FlossPalette, Text, stylesFactory, Grid } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    modelVisibility: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'nowrap',
        padding: '3px 5px',
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        borderRadius: '8px',
    },
    iconTextContainer: {
        display: 'inline-flex',
        flexWrap: 'nowrap',
    },
    toggleText: {
        marginLeft: 10,
        textWrap: 'nowrap',
    },
    archButtonPadding: {
        paddingBottom: '5px',
    },
}));

export const VisibilityToggleMobile: React.VFC<{
    toggleLabel: string;
    toggleAction: (checked: boolean) => void;
}> = ({ toggleLabel, toggleAction }) => {
    const classes = useStyles();
    const [toggled, setToggled] = React.useState(true);
    const addBottomPadding = toggleLabel === 'Upper' || toggleLabel === 'Lower';
    return (
        <Grid
            item
            className={clsx(classes.modelVisibility, addBottomPadding && classes.archButtonPadding)}
            onClick={() => {
                toggleAction(!toggled);
                setToggled(!toggled);
            }}
        >
            <Grid item className={classes.iconTextContainer}>
                <VisibilityToggleIcon toggleName={toggleLabel} toggled={toggled} />
                <Text
                    variant={'body2'}
                    medium
                    color={toggled ? 'PRIMARY_FOREGROUND' : 'GRAY'}
                    className={classes.toggleText}
                >
                    {toggleLabel}
                </Text>
            </Grid>
        </Grid>
    );
};
