import { ProductionPlanDebuggerStep_Fragment } from './PlanFragments.graphql';
import type {
    ProductionPlanDebuggerViewFragment,
    ProductionPlanDebuggerStepFragment,
} from '@orthly/graphql-inline-react';
import { getFragmentData } from '@orthly/graphql-inline-react';
import { Text, Box } from '@orthly/ui-primitives';
import type { Task } from 'gantt-task-react';
import { ViewMode, Gantt } from 'gantt-task-react';
import 'gantt-task-react/dist/index.css';
import _ from 'lodash';
import React from 'react';

// If updating the styling for the gant chart, be sure to check the index.css for the app.
//  See https://github.com/MaTeMaTuK/gantt-task-react/issues/204

function resultToTasks(result: ProductionPlanDebuggerViewFragment): {
    tasks: Task[];
    steps: Record<string, ProductionPlanDebuggerStepFragment>;
} {
    const requirements = getFragmentData(ProductionPlanDebuggerStep_Fragment, result.requirementSchedule);
    const plans = getFragmentData(ProductionPlanDebuggerStep_Fragment, result.plans);
    const tasks = [...requirements, ...plans].map<Task>(plan => ({
        id: plan.id,
        type: plan.type === 'project' || plan.type === 'milestone' ? plan.type : 'task',
        name: plan.name,
        start: new Date(plan.plannedStart),
        end: new Date(plan.plannedEnd),
        progress: 0,
        dependencies: plan.dependencies,
        isDisabled: false,
        project: plan.project ?? undefined,
        styles: {
            backgroundColor: plan.backgroundColor ?? undefined,
            backgroundSelectedColor: plan.backgroundColor ?? undefined,
        },
        hideChildren: plan.type === 'project' ? false : undefined,
        displayOrder: 0,
    }));
    return {
        tasks,
        steps: _.keyBy([...requirements, ...plans], t => t.id),
    };
}

const TooltipContent: React.VFC<{ task: Task; step?: ProductionPlanDebuggerStepFragment }> = props => {
    const { task, step } = props;
    return (
        <Box sx={{ padding: '8px', bgcolor: '#fff', gridRowGap: '8px', display: 'grid', gridTemplateColumns: 'auto' }}>
            <Text variant={'body1'} bold>
                {task.name}
            </Text>
            <Text variant={'body2'}>
                Start: <b>{task.start.toLocaleString()}</b>
            </Text>
            <Text variant={'body2'}>
                Due: <b>{task.end.toLocaleString()}</b>
            </Text>
            {(step?.tooltipMeta ?? []).map(meta => (
                <Text key={meta.label} variant={'body2'}>
                    {meta.label}: <b>{meta.isDate ? new Date(meta.value).toLocaleString() : meta.value}</b>
                </Text>
            ))}
        </Box>
    );
};

const RenderNull: React.VFC = () => null;

export const PlansGantt: React.VFC<{ result: ProductionPlanDebuggerViewFragment }> = ({ result }) => {
    const { tasks, steps } = React.useMemo(() => resultToTasks(result), [result]);
    const [hiddenTaskIds, setHiddenTaskIds] = React.useState<string[]>([]);
    return (
        <Gantt
            rowHeight={30}
            TooltipContent={p => <TooltipContent step={steps[p.task.id]} task={p.task} />}
            preStepsCount={0}
            viewMode={ViewMode.Day}
            barFill={75}
            // Hides the left hand sidebar that just repeats task name + dates
            TaskListHeader={RenderNull}
            TaskListTable={RenderNull}
            todayColor={'rgba(255, 229, 73, 0.6)'}
            onClick={task => {
                // toggle collapse state on project bar click
                if (task.type === 'project') {
                    setHiddenTaskIds(
                        hiddenTaskIds.includes(task.id)
                            ? hiddenTaskIds.filter(id => id !== task.id)
                            : [...hiddenTaskIds, task.id],
                    );
                }
            }}
            tasks={tasks.filter(t => !hiddenTaskIds.includes(t.project ?? ''))}
        />
    );
};
