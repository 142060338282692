import {
    LabsGqlPortalShipShippingSpeed,
    LabsGqlDoctorNotificationCadence,
    LabsGqlDoctorNotificationChannel,
    LabsGqlWhoReceivesDoctorNotifications,
    LabsGqlScanExportSource,
} from '@orthly/graphql-schema';

export const ShippingSpeedToText: { [key in LabsGqlPortalShipShippingSpeed]: string } = {
    [LabsGqlPortalShipShippingSpeed.Overnight]: 'Overnight',
    [LabsGqlPortalShipShippingSpeed.TwoDay]: 'Two-day',
    [LabsGqlPortalShipShippingSpeed.ThreeDay]: 'Three-day',
    [LabsGqlPortalShipShippingSpeed.Ground]: 'Ground',
};

export const NameForNotificationCadence: { [key in LabsGqlDoctorNotificationCadence]: string } = {
    [LabsGqlDoctorNotificationCadence.Never]: 'Never',
    [LabsGqlDoctorNotificationCadence.Immediately]: 'Immediately',
    [LabsGqlDoctorNotificationCadence.Daily]: 'Once per day',
};

export const NameForWhoReceivesNotification: { [key in LabsGqlWhoReceivesDoctorNotifications]: string } = {
    [LabsGqlWhoReceivesDoctorNotifications.Dr]: 'Doctor',
    [LabsGqlWhoReceivesDoctorNotifications.Assistant]: 'My assistant',
    [LabsGqlWhoReceivesDoctorNotifications.Admin]: 'Office administrator',
    [LabsGqlWhoReceivesDoctorNotifications.Other]: 'Other',
};

export const NameForNotificationChannel: { [key in LabsGqlDoctorNotificationChannel]: string } = {
    [LabsGqlDoctorNotificationChannel.Sms]: 'SMS',
    [LabsGqlDoctorNotificationChannel.Email]: 'Email',
};

/**
 * A mapping of Scan Export sources to their human-readable names.
 */
export const ScanExportSourceToScannerNameMap: Record<LabsGqlScanExportSource, string> = {
    [LabsGqlScanExportSource.Carestream]: 'Carestream',
    [LabsGqlScanExportSource.ScannerOs]: 'Chairside',
    [LabsGqlScanExportSource.Trios]: 'TRIOS',
    [LabsGqlScanExportSource.ITero]: 'iTero',
    [LabsGqlScanExportSource.Medit]: 'Medit',
};
