import { LabeledWindowBorder } from '@orthly/dentin';
import { makeLazyDialog, RootActionDialog } from '@orthly/ui';
import { Button, CloseIcon, FlossPalette, stylesFactory, Text } from '@orthly/ui-primitives';
import type { AskDoctorV2ViewableOption, OrderForAskDoctorPracticeDialog } from '@orthly/veneer';
import { AskDoctorPracticeDialogContent } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    previewContent: {
        display: `flex`,
        alignItems: `center`,
        gap: `16px`,
    },
    dialogContent: {
        position: `relative`,
    },
    closeButton: {
        height: `initial`,
        minWidth: `initial`,
        padding: 0,
        margin: `-5px`, // compensating for internal padding of the close icon
    },
}));

export interface AskDoctorV2DialogProps {
    order: OrderForAskDoctorPracticeDialog;
    attachmentUrls: string[];
    options: AskDoctorV2ViewableOption[];
    questionForDoctor: string;
}

export const useAskDoctorPracticePreviewDialog = makeLazyDialog<AskDoctorV2DialogProps>(
    ({ open, setOpen, order, options, attachmentUrls, questionForDoctor }) => {
        const classes = useStyles();
        return (
            <RootActionDialog
                title={`Review order issue`}
                open={open}
                setOpen={setOpen}
                loading={false}
                hideButton
                showCloseButton
                PaperProps={{ style: { width: 700, maxWidth: 700 } }}
                content={
                    <>
                        <LabeledWindowBorder>
                            <div className={classes.previewContent}>
                                <Text color={'WHITE'} variant={'body2'} medium>
                                    Previewing Doctor Survey
                                </Text>
                                <Button variant={'text'} className={classes.closeButton} onClick={() => setOpen(false)}>
                                    <CloseIcon style={{ color: FlossPalette.WHITE }} />
                                </Button>
                            </div>
                        </LabeledWindowBorder>
                        <div className={classes.dialogContent}>
                            <AskDoctorPracticeDialogContent
                                order={order}
                                ChatButton={() => (
                                    <Button variant={'ghost'} onClick={() => null} startIcon={'SidebarChatIcon'}>
                                        Chat with a Dandy representative
                                    </Button>
                                )}
                                attachmentUrls={attachmentUrls}
                                onCancel={() => setOpen(false)}
                                onSubmit={() => null}
                                options={options}
                                questionForDoctor={questionForDoctor}
                                isSubmitting={false}
                            />
                        </div>
                    </>
                }
            />
        );
    },
);
