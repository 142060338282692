import { useRefabFlowOrderDetailsStyles } from './styles';
import type { RefabFlowOrderDetailsItem } from './types';
import { Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

export const RefabFlowOrderDetailsItemLayout: React.VFC<RefabFlowOrderDetailsItem> = ({
    preferences,
    shades,
    skuSpecificFields,
}) => {
    const classes = useRefabFlowOrderDetailsStyles();

    return (
        <div>
            <div>
                {shades.length > 0 && (
                    <>
                        <Grid container className={classes.row} wrap={'nowrap'}>
                            <Grid item>
                                <Text
                                    variant={'body2'}
                                    color={'GRAY'}
                                    className={classes.label}
                                    style={{ width: `52px` }}
                                >
                                    Shade
                                </Text>
                            </Grid>
                            <Grid container>
                                {shades.map(({ name, value }) => (
                                    <Grid key={name} item style={{ marginRight: `16px`, display: `flex` }}>
                                        <Text variant={'body2'} color={'GRAY'} className={classes.label}>
                                            {name}
                                        </Text>
                                        <Text variant={'body2'}>{value}</Text>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </>
                )}
                {skuSpecificFields.map(({ label, value }) => (
                    <Grid key={label} container className={classes.row}>
                        <Text variant={'body2'} color={'GRAY'} className={classes.label}>
                            {label}
                        </Text>
                        <Text variant={'body2'}>{value}</Text>
                    </Grid>
                ))}
                <div className={classes.orderDetailsSeparator} />
            </div>
            <div>
                {preferences.length === 0 ? (
                    <Text variant={'body2'}>Default preferences used for this order.</Text>
                ) : (
                    <div>
                        {preferences.map(({ name, value }) => (
                            <Grid key={name} container alignItems={'center'} className={classes.row} wrap={'nowrap'}>
                                <Text variant={'body2'} color={'GRAY'} className={classes.preferenceLabel}>
                                    {name}
                                </Text>
                                <Text variant={'body2'}>{value}</Text>
                            </Grid>
                        ))}
                    </div>
                )}
                <div className={classes.orderDetailsSeparator} />
            </div>
        </div>
    );
};
