import type { DesignQcRubricSubCategoryEntry } from '../configs/DesignQc.config.types';
import { DesignQcActions } from './DesignQc.actions';
import { isQcRubricEntryCompleted, getAllUnns } from './DesignQc.selectors';
import type { DesignQcState, RubricScoreResult } from './DesignQc.state';
import _ from 'lodash';
import { handleActions } from 'redux-actions';

const baseInitialState: Omit<DesignQcState, 'order' | 'designFragment'> = {
    config: [],
    results: [],
    isBadSubcategoryWorkflowEnabled: false,
    isAcceptableSubcategoryWorkflowEnabled: false,
    currentNavigationStartTime: 0,
    pendingMarkupUrls: [],
    timings: [],
    current_user_id: undefined,
    has_design_previously_been_rejected: false,
};

function findFirstIncompleteCategoryEntry(
    state: DesignQcState,
    category: string,
): DesignQcRubricSubCategoryEntry | undefined {
    return state.config
        .find(c => c.category === category)
        ?.subcategories.find(c => !isQcRubricEntryCompleted(state, c));
}

export function updateSubcategoryResultAndAdvanceNavigation(
    state: DesignQcState,
    result: RubricScoreResult,
    currentTime: number,
): DesignQcState {
    const currentEntry = state.rubricNavigation?.entry;

    if (!currentEntry) {
        return state;
    }

    const currentTiming = state.timings.find(
        timing => timing.name === currentEntry.name && timing.category === currentEntry.category,
    );
    const stateWithoutNavigation: Omit<DesignQcState, 'rubricNavigation'> = {
        ...state,
        isBadSubcategoryWorkflowEnabled: false,
        isAcceptableSubcategoryWorkflowEnabled: false,
        pendingMarkupUrls: [],
        results: [
            // Filter out any that were already for this entry
            ...state.results.filter(r => r.category !== currentEntry.category || r.name !== currentEntry.name),
            // And now we append our new score
            {
                result,
                name: currentEntry.name,
                category: currentEntry.category,
            },
        ],
    };

    const firstIncompleteEntry =
        state.config.flatMap(c => c.subcategories).find(c => !isQcRubricEntryCompleted(stateWithoutNavigation, c)) ??
        currentEntry;

    const didChangeEntry =
        firstIncompleteEntry.category !== state.rubricNavigation?.category ||
        firstIncompleteEntry.name !== state.rubricNavigation?.entry?.name;

    return {
        ...stateWithoutNavigation,
        rubricNavigation: {
            category: firstIncompleteEntry.category,
            entry: firstIncompleteEntry,
            autoScroll: true,
        },
        currentNavigationStartTime: didChangeEntry ? currentTime : stateWithoutNavigation.currentNavigationStartTime,
        timings: [
            // Remove any timing objects that already exist for this entry
            ...stateWithoutNavigation.timings.filter(
                timing => timing.name !== currentEntry.name || timing.category !== currentEntry.category,
            ),
            // And now we insert a new entry with the delta milliseconds from when this navigation was first navigated to and now.
            {
                name: currentEntry.name,
                category: currentEntry.category,
                durationMs:
                    (currentTiming?.durationMs ?? 0) +
                    (currentTime - stateWithoutNavigation.currentNavigationStartTime),
            },
        ],
    };
}

export function getDesignQcReducer(
    props: Pick<
        DesignQcState,
        | 'config'
        | 'order'
        | 'designFragment'
        | 'rubricNavigation'
        | 'currentNavigationStartTime'
        | 'designQaEvaluationId'
        | 'current_user_id'
        | 'has_design_previously_been_rejected'
    >,
) {
    const initialState: DesignQcState = {
        ...baseInitialState,
        ...props,
    };

    return handleActions<DesignQcState, any>(
        {
            ...DesignQcActions.SET_RUBRIC_NAVIGATION.reducer<DesignQcState>((state, action) => {
                const newEntry =
                    (action.payload.entry
                        ? action.payload.entry
                        : findFirstIncompleteCategoryEntry(state, action.payload.category)) ??
                    _.last(state.config.find(c => c.category === action.payload.category)?.subcategories);

                const didChangeEntry =
                    newEntry?.category !== state.rubricNavigation?.category ||
                    newEntry?.name !== state.rubricNavigation?.entry?.name;

                const existingResult = state.results.find(
                    r => r.name === newEntry?.name && r.category === newEntry?.category,
                );

                return {
                    ...state,
                    rubricNavigation: {
                        category: action.payload.category,
                        entry: newEntry ?? undefined,
                        autoScroll: action.payload.autoScroll,
                    },
                    // If the result was bad in a subcategory that's being navigated to,
                    // we will put the user back into the bad workflow so that they can touch up what they were doing.
                    isBadSubcategoryWorkflowEnabled:
                        state.results.find(r => r.category === newEntry?.category && r?.name === newEntry?.name)?.result
                            ?.type === 'bad',
                    pendingMarkupUrls: existingResult?.result?.type === 'bad' ? existingResult.result.markupUrls : [],
                    currentNavigationStartTime: didChangeEntry
                        ? action.payload.currentTime
                        : state.currentNavigationStartTime,
                };
            }),
            ...DesignQcActions.MARK_SUBCATEGORY_ACCEPTABLE.reducer<DesignQcState>((state, action) =>
                updateSubcategoryResultAndAdvanceNavigation(
                    state,
                    {
                        type: action.payload.which,
                        note: action.payload.note,
                        affectedUnns: getAllUnns(state),
                    },
                    action.payload.currentTime,
                ),
            ),
            ...DesignQcActions.TOGGLE_MARK_SUBCATEGORY_BAD_WORKFLOW.reducer<DesignQcState>((state, action) => ({
                ...state,
                isBadSubcategoryWorkflowEnabled: action.payload.isEnabled,
                isAcceptableSubcategoryWorkflowEnabled: false,
            })),
            ...DesignQcActions.TOGGLE_MARK_SUBCATEGORY_ACCEPTABLE_WORKFLOW.reducer<DesignQcState>((state, action) => ({
                ...state,
                isBadSubcategoryWorkflowEnabled: false,
                isAcceptableSubcategoryWorkflowEnabled: action.payload.isEnabled,
            })),
            ...DesignQcActions.MARK_SUBCATEGORY_BAD.reducer<DesignQcState>((state, action) =>
                updateSubcategoryResultAndAdvanceNavigation(
                    state,
                    {
                        type: 'bad',
                        reasons: action.payload.reasons,
                        note: action.payload.note,
                        markupUrls: state.pendingMarkupUrls,
                        affectedUnns: action.payload.affectedUnns,
                    },
                    action.payload.currentTime,
                ),
            ),
            ...DesignQcActions.ADD_PENDING_MARKUP_URL.reducer<DesignQcState>((state, action) => ({
                ...state,
                pendingMarkupUrls: [...state.pendingMarkupUrls, action.payload.url],
            })),
            ...DesignQcActions.DELETE_PENDING_MARKUP_URL.reducer<DesignQcState>((state, action) => ({
                ...state,
                pendingMarkupUrls: state.pendingMarkupUrls.filter(url => url !== action.payload.url),
            })),
        },
        initialState,
    );
}
