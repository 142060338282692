import { ToothUtils } from '@orthly/items';
import type { CustomQFComponentProps } from '@orthly/ui';
import { SimpleInput } from '@orthly/ui';
import { FormHelperText, Grid } from '@orthly/ui-primitives';
import React from 'react';
import { z } from 'zod';

export const validateTeethSelectionValidation = z.array(
    z
        .number({ invalid_type_error: 'please specify a UNN' })
        // this is how yup works, ${value} inside normal string
        // eslint-disable-next-line no-template-curly-in-string
        .refine(ToothUtils.isToothNumber, val => ({
            message: `${val} is not a valid UNN`,
        })),
);

export const UpdateTeethSelection: React.FC<CustomQFComponentProps<{ label: string }>> = props => {
    const { field, form, label } = props;
    const { name, value }: { name: string; value: number[] } = field;
    const [inputValue, setInputValue] = React.useState<string>(value.join(','));
    const fieldError = form.errors[name];
    return (
        <Grid container>
            <SimpleInput
                onChange={(inputValue = '') => {
                    const newInputValue = inputValue.replace(/[^\d,]/g, '');
                    setInputValue(newInputValue);
                    form.setFieldValue(name, newInputValue ? newInputValue.split(',').map(s => parseInt(s)) : []);
                }}
                fullWidth
                value={inputValue}
                label={label}
            />
            {fieldError && <FormHelperText error={true}>{fieldError}</FormHelperText>}
        </Grid>
    );
};
