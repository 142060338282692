import React from 'react';

const DEFAULT_OPEN_PDF_ACTION = (labelUrl: string) => window.open(labelUrl, '_blank', 'noreferrer noopener');

export const useRefabPrintReturnLabel = (
    labelUrl?: string,
    openPdfAction: (labelUrl: string) => void = DEFAULT_OPEN_PDF_ACTION,
) => {
    return React.useCallback(() => {
        if (!labelUrl) {
            console.error({
                err: `No return label generated from the refabrication request`,
            });
            return;
        }
        openPdfAction(labelUrl);
    }, [labelUrl, openPdfAction]);
};
