import type { ItemEditorV2BaseProps } from '../../../types/ItemEditorV2BaseProps';
import { useAutoSetItemDataFieldDefaultValue } from './ItemEditorV2ItemDataField.util';
import type { DateItemDataField, ItemDataFieldId, LabOrderItemSKUType } from '@orthly/items';
import { ItemDataFieldUtils } from '@orthly/items';
import type { MaterialUiPickersDate } from '@orthly/ui';
import { SimpleDatePopupPicker } from '@orthly/ui';
import moment from 'moment';
import React from 'react';

const getValidMoment = (val: string | null | undefined | Date): moment.Moment | null => {
    if (!val) {
        return null;
    }
    const date = moment(val);
    return date.isValid() ? date : null;
};

interface ItemEditorV2DateItemDataFieldProps<ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>
    extends ItemEditorV2BaseProps<SKU> {
    field: DateItemDataField<ID, SKU>;
}

export const ItemEditorV2DateItemDataField = <ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>({
    field,
    item,
    updateItem,
    showError,
}: ItemEditorV2DateItemDataFieldProps<ID, SKU>): React.ReactElement => {
    const value = getValidMoment(field.getValue(item));
    const minDate = getValidMoment(field.getMinValue?.(item));
    const maxDate = getValidMoment(field.getMaxValue?.(item));

    const applyUpdate = (val: MaterialUiPickersDate) => {
        const date = getValidMoment(val);
        updateItem(field.getUpdatePayload(date ? date.toISOString() : null, item));
    };

    const hasError = showError && ItemDataFieldUtils.isItemDataFieldRequired(field, item) && !field.isComplete(item);

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    return (
        <SimpleDatePopupPicker
            label={field.label}
            value={value ? value.toDate() : null}
            onChange={val => applyUpdate(val)}
            closeOnSelect={true}
            minDate={minDate ? minDate.toDate() : undefined}
            maxDate={maxDate ? maxDate.toDate() : undefined}
            fullWidth={true}
            errorText={hasError ? 'This field is required' : undefined}
        />
    );
};
