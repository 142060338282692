import type { MarginLinesMap, RestorativeModel, ScanGeometries } from './FinishingApp.types';
import { checkRestorativesAlignment, type RestorativeAlignmentCheckResult } from './RestorativeValidity.utils';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import React from 'react';

/**
 * Idempotently checks the validity of the restorative models
 */
export function useCheckRestorativesValidity(
    restorativeModels: ArrayMin1<RestorativeModel>,
    scanGeometries: ScanGeometries,
    marginLines: MarginLinesMap,
): RestorativeAlignmentCheckResult[] | undefined {
    return React.useMemo(
        () => checkRestorativesAlignment(restorativeModels, scanGeometries, marginLines),
        [restorativeModels, scanGeometries, marginLines],
    );
}
