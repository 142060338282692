import { useOpsInboxAction } from '../../screens/Inbox/state/Inbox.actions';
import { useCurrentInboxSort } from '../../screens/Inbox/state/Inbox.selectors';
import type { OpsInboxSortKey } from '../../screens/Inbox/state/Inbox.types';
import type { OrdersToolbarPopperContentProps } from './OrdersToolbarPopper';
import { OrdersToolbarPopper } from './OrdersToolbarPopper';
import { LabsGqlWorkflowTaskSortField } from '@orthly/graphql-schema';
import type { RequireChildrenProps } from '@orthly/ui';
import { SimpleSelect, SortIcon } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, List, ListItem, ListItemText, ListSubheader } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useItemStyles = stylesFactory(() => ({
    root: {
        width: '100%',
        margin: '8px 0',
        padding: 4,
        justifyContent: 'space-between',
        display: 'flex',
    },
    text: { padding: 0 },
}));

const SortKeyTitles: { [K in OpsInboxSortKey]?: string } = {
    [LabsGqlWorkflowTaskSortField.TaskCreatedAt]: 'Created at',
    [LabsGqlWorkflowTaskSortField.TaskClosedAt]: 'Completion date',
    [LabsGqlWorkflowTaskSortField.OrderDueDate]: 'Due date',
    [LabsGqlWorkflowTaskSortField.OrderCreatedAt]: 'Placed On',
    [LabsGqlWorkflowTaskSortField.LabDesignPriority]: 'Design Due Date',
};

interface InboxListSortItemProps {
    sortKey: OpsInboxSortKey;
    onClick: () => void;
}

const InboxListSortItem: React.FC<InboxListSortItemProps> = props => {
    const classes = useItemStyles();
    const { sortKey, onClick } = props;
    const currentSort = useCurrentInboxSort();
    return (
        <ListItem button onClick={onClick} className={classes.root}>
            <ListItemText primary={SortKeyTitles[sortKey] ?? _.startCase(sortKey)} />
            {currentSort.key === sortKey && (
                <SortIcon
                    onClick={onClick}
                    style={{ cursor: 'pointer' }}
                    highlightedArrow={currentSort.asc ? 'upper' : 'lower'}
                />
            )}
        </ListItem>
    );
};

interface SortKeysListProps extends RequireChildrenProps {
    subheader: string;
}

const SortKeysList: React.FC<SortKeysListProps> = props => {
    return (
        <Grid container style={{ maxWidth: '49%' }}>
            <List style={{ width: '100%' }}>
                <ListSubheader style={{ lineHeight: 'normal', padding: '0 4px' }}>{props.subheader}</ListSubheader>
                {props.children}
            </List>
        </Grid>
    );
};

const TaskKeys: OpsInboxSortKey[] = [
    LabsGqlWorkflowTaskSortField.TaskType,
    LabsGqlWorkflowTaskSortField.TaskCreatedAt,
    LabsGqlWorkflowTaskSortField.TaskClosedAt,
    LabsGqlWorkflowTaskSortField.LabDesignPriority,
];
const OrderKeys: OpsInboxSortKey[] = [
    LabsGqlWorkflowTaskSortField.OrderDueDate,
    LabsGqlWorkflowTaskSortField.OrderCreatedAt,
];

const SortPopoverContent: React.FC<OrdersToolbarPopperContentProps> = props => {
    const { setOpen } = props;
    const onSortKeyClicked = useOpsInboxAction('SORT_KEY_CLICKED');
    const onSelect = (key: OpsInboxSortKey) => () => {
        onSortKeyClicked(key);
        setOpen(false);
    };
    return (
        <Grid container wrap={'nowrap'} justifyContent={'space-between'}>
            <SortKeysList subheader={'Task'}>
                {TaskKeys.map(key => (
                    <InboxListSortItem key={key} sortKey={key} onClick={onSelect(key)} />
                ))}
            </SortKeysList>
            <SortKeysList subheader={'Order'}>
                {OrderKeys.map(key => (
                    <InboxListSortItem key={key} sortKey={key} onClick={onSelect(key)} />
                ))}
            </SortKeysList>
        </Grid>
    );
};

export const InboxListSort: React.FC = () => {
    const toggleDir = useOpsInboxAction('TOGGLE_SORT_DIR');
    const sort = useCurrentInboxSort();
    const [open, setOpen] = React.useState<boolean>(false);
    const currentSortLabel = SortKeyTitles[sort.key] ?? _.startCase(sort.key);
    return (
        <OrdersToolbarPopper
            PopoverContent={SortPopoverContent}
            open={open}
            setOpen={setOpen}
            rootStyle={{ width: '20%' }}
        >
            <SimpleSelect
                // just used for display
                value={currentSortLabel}
                options={[{ value: currentSortLabel }]}
                onChange={() => {}}
                label={'Sort by task'}
                FormControlProps={{ onClick: _e => setOpen(true), variant: 'standard' }}
                InputLabelProps={{ style: { backgroundColor: 'transparent' } }}
                SelectProps={{
                    disabled: true,
                    style: { backgroundColor: 'transparent', color: FlossPalette.BLACK, cursor: 'pointer' },
                    IconComponent: () => null,
                    SelectDisplayProps: { style: { cursor: 'pointer' } },
                    variant: 'standard',
                }}
            />
            <SortIcon
                onClick={() => toggleDir()}
                className={`MuiSelect-icon`}
                style={{ cursor: 'pointer', zIndex: 2, background: '#fff', pointerEvents: 'auto' }}
                highlightedArrow={sort.asc ? 'upper' : 'lower'}
            />
        </OrdersToolbarPopper>
    );
};
