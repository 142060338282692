import { LoginForm } from './LoginForm';
import { SendResetEmail } from './SendResetEmail';
import { SetNewPassword } from './SetNewPassword';
import { useSession } from '@orthly/session-client';
import { FlossPalette, Button, Grid } from '@orthly/ui-primitives';
import logo from '@orthly/ui/assets/logos/DandyLogoWhite.svg';
import React from 'react';

// little utility for the link below the form
const BottomLink: React.VFC<{ onClick: () => void; text: string }> = props => {
    return (
        <Grid container justifyContent={'center'} style={{ padding: 20 }}>
            <Button onClick={props.onClick} variant={'text'}>
                {props.text}
            </Button>
        </Grid>
    );
};

export type LoginRootScreen =
    | 'login' // email/password login screen
    | 'reset' // screen to send the user a "reset password" email
    | 'change_password'; // screen to enter the new password, linked from the reset email

interface LoginRootProps {
    /**
     * @property {'login' | 'reset' | 'change_password' = 'login'} initialScreen - Defaults to "login", useful if you
     *  are using path-based routing to enter the change password screen
     */
    initialScreen?: LoginRootScreen;
    /**
     * @property onLoggedIn - Called once the user is logged in, you probably want to open your app at that point
     */
    onLoggedIn: () => void;
}

/**
 * Shared component handling 3 Authentication screens: login, send reset email, and set new password.
 *
 * Reference implementation can be found in {@link /apps/admin/src/screens/Auth/AdminLogin.tsx}
 * Demo here: {@link https://www.loom.com/share/7a37c0f6b31d4153ba6030faa76d0c38}
 */
export const LoginRoot: React.VFC<LoginRootProps> = ({ initialScreen, onLoggedIn }) => {
    const session = useSession();
    const [screen, setScreen] = React.useState<LoginRootScreen>(initialScreen ?? 'login');
    const [emailForReset, setEmailForReset] = React.useState<string | undefined>();

    React.useEffect(() => {
        if (session && screen !== 'change_password') {
            onLoggedIn();
        }
    }, [session, screen, onLoggedIn]);
    /* eslint-disable no-nested-ternary */
    return (
        <Grid container justifyContent={'center'}>
            <Grid container justifyContent={'center'} style={{ padding: 20, background: FlossPalette.BLACK }}>
                <img alt={'Dandy logo'} src={logo} style={{ width: 200 }} />
            </Grid>
            <Grid container style={{ width: 400, padding: 20 }}>
                {screen === 'reset' ? (
                    <>
                        <SendResetEmail initialEmail={emailForReset} />
                        <BottomLink onClick={() => setScreen('login')} text={'Back to login'} />
                    </>
                ) : screen === 'change_password' ? (
                    <>
                        <SetNewPassword onPasswordReset={() => setScreen('login')} />
                        <BottomLink onClick={() => setScreen('login')} text={'Back to login'} />
                    </>
                ) : (
                    <>
                        <LoginForm setEmail={setEmailForReset} />
                        <BottomLink onClick={() => setScreen('reset')} text={'Forgot password?'} />
                    </>
                )}
            </Grid>
        </Grid>
    );
};
