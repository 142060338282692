import { abutmentEmergenceProfileAndEmbrasuresRubric } from './AbutmentEmergenceProfileAndEmbrasures.config';
import { aestheticsRubric } from './Aesthetics.config';
import { biteRubric } from './Bite.config';
import { buccalLingualAnatomyRubric } from './BuccalLingualAnatomy.config';
import { buccalLingualContourRubric } from './BuccalLingualContour.config';
import { connectorsRubric } from './Connectors.config';
import { contactsRubric } from './Contacts.config';
import { crownToCrownContactsRubric } from './CrownToCrownContacts.config';
import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { emergenceProfileAndEmbrasuresRubric } from './EmergenceProfileAndEmbrasures.config';
import { finishingLineRubric } from './FinishingLine.config';
import { generalRubric } from './General.config';
import { insertionDirectionRubric } from './InsertionDirection.config';
import { marginalRidgesRubric } from './MarginalRidges.config';
import { ModelConfig } from './Model.config';
import { occlusalAnatomyRubric } from './OcclusalAnatomy.config';
import { occlusionRubric } from './Occlusion.config';
import { ponticEmergenceProfileAndEmbrasuresRubric } from './PonticEmergenceProfileAndEmbrasures.config';
import { ponticsRubric } from './Pontics.config';
import { tomSculptingRubric } from './TomSculpting.config';

export type FilteredRubric = {
    categoryEntry: DesignQcRubricCategoryEntry;
    filteredSubcategoryNames: string[];
};

export function generateFilteredGuidedQCRubric(filteredRubrics: FilteredRubric[]): DesignQcRubricCategoryEntry[] {
    return filteredRubrics.map(filteredRubric => {
        return {
            category: filteredRubric.categoryEntry.category,
            subcategories: filteredRubric.categoryEntry.subcategories.filter(subCategory => {
                return filteredRubric.filteredSubcategoryNames.includes(subCategory.name);
            }),
        };
    });
}

export function generateDefaultGuidedQCRubric(onlyCore: boolean): DesignQcRubricCategoryEntry[] {
    const coreRubric = [
        biteRubric,
        tomSculptingRubric,
        finishingLineRubric,
        insertionDirectionRubric,
        contactsRubric,
        crownToCrownContactsRubric,
    ];

    if (onlyCore) {
        return coreRubric;
    }

    return [
        generalRubric,
        ...coreRubric,
        occlusionRubric,
        marginalRidgesRubric,
        occlusalAnatomyRubric,
        emergenceProfileAndEmbrasuresRubric,
        abutmentEmergenceProfileAndEmbrasuresRubric,
        ponticEmergenceProfileAndEmbrasuresRubric,
        buccalLingualContourRubric,
        buccalLingualAnatomyRubric,
        connectorsRubric,
        aestheticsRubric,
        ponticsRubric,
        ...ModelConfig,
    ];
}
