import { styled } from '@orthly/ui-primitives';
import { AbutmentPartsCalculator } from '@orthly/veneer';

const Wrapper = styled('div')({
    padding: 16,
    width: '100%',
    maxWidth: '100%',
});

export const StandaloneImplantPartCalculator: React.VFC = () => {
    return (
        <Wrapper>
            <AbutmentPartsCalculator />
        </Wrapper>
    );
};
