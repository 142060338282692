import { Box, Button, FlossPalette, styled, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

const MarkupButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    bottom: '16px',
    left: '16px',
    gap: '16px',
    zIndex: 1,
    [theme.breakpoints.down('lg')]: {
        marginTop: '16px',
        textAlign: 'center',
        position: 'static',
    },
}));

interface GuidedWaxupAnnotationButtonProps {
    closeDrawingCanvas: () => void;
    saveAnnotatedImage: () => Promise<void>;
    trackGuidedWaxupButtonClick: (buttonText: string) => void;
}

export const GuidedWaxupAnnotationButtons: React.VFC<GuidedWaxupAnnotationButtonProps> = ({
    closeDrawingCanvas,
    saveAnnotatedImage,
    trackGuidedWaxupButtonClick,
}) => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    return (
        <MarkupButtonContainer>
            <Button
                variant={'secondary'}
                onClick={() => {
                    trackGuidedWaxupButtonClick('Delete Screen Capture');
                    closeDrawingCanvas();
                }}
                size={'small'}
                sx={{ color: FlossPalette.ATTENTION }}
            >
                Delete Screen Capture
            </Button>
            <Button variant={isMobile ? 'primary' : 'secondary'} onClick={saveAnnotatedImage} size={'small'}>
                {isMobile ? 'Save Markup' : 'Save and Markup New Screen Capture'}
            </Button>
        </MarkupButtonContainer>
    );
};
