import type { AlignerPlanDetails } from '../Aligners/AlignerPlans';
import { useAlignerPlansDetails } from '../Aligners/AlignerPlans';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { LabsGqlAlignerPlan } from '@orthly/graphql-schema';
import { RootActionDialog } from '@orthly/ui';
import { Button, FlossPalette, Text, Tooltip, withStyles, Icon } from '@orthly/ui-primitives';
import React from 'react';

const PLANS_IN_ORDER: LabsGqlAlignerPlan[] = [
    LabsGqlAlignerPlan.OnDemand,
    LabsGqlAlignerPlan.Dandy20,
    LabsGqlAlignerPlan.DandyMax,
];

export const AlignerTreatmentPlanDetailsBox: React.VFC<{
    children: React.ReactNode;
    disabled?: boolean;
}> = ({ children, disabled = false }) => (
    <div style={{ padding: 8, flexGrow: 1, flexBasis: 0, display: `flex` }}>
        <div
            style={{
                padding: 16,
                backgroundColor: FlossPalette.TAN,
                borderRadius: 16,
                flexGrow: 1,
                opacity: disabled ? 0.5 : 1,
            }}
        >
            {children}
        </div>
    </div>
);

export const AlignerTreatmentPlanDetailsRow: React.VFC<{
    content: React.ReactNode;
    border?: boolean;
    grow?: boolean;
}> = ({ content, border = false, grow = false }) => (
    <div
        style={{
            minHeight: 40,
            borderBottom: border ? `1px solid ${FlossPalette.DARK_TAN}` : undefined,
            flexGrow: grow ? 1 : undefined,
            padding: `8px 0`,
        }}
    >
        {content}
    </div>
);

const InfoTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: FlossPalette.WHITE,
        borderRadius: 16,
        width: `40rem`,
        boxShadow: theme.shadows[12],
        padding: 16,
    },
}))(Tooltip);

export const AlignerTreatmentPlanLearnMore: React.VFC<{
    plan_details: AlignerPlanDetails;
}> = ({ plan_details }) => (
    <InfoTooltip
        title={
            <>
                <Text variant={`h6`}>{plan_details.title}</Text>
                <Text variant={`body2`}>{plan_details.subtitle}</Text>
                <br />
                <Text variant={`body2`} medium>
                    Price
                </Text>
                <Text variant={`body2`}>{plan_details.price}</Text>
                <br />
                <Text variant={`body2`} medium>
                    Refinement aligners
                </Text>
                <Text variant={`body2`}>{plan_details.refinements}</Text>
                <br />
                <Text variant={`body2`} medium>
                    Retainers
                </Text>
                <Text variant={`body2`}>{plan_details.retainers}</Text>
                <br />
                <Text variant={`body2`} medium>
                    Treatment expiry
                </Text>
                <Text variant={`body2`}>{plan_details.expiry}</Text>
            </>
        }
        placement={`right`}
    >
        <div style={{ color: FlossPalette.STAR_GRASS, display: `flex`, padding: `16px 0 8px` }}>
            <Icon icon={'InfoOutlinedIcon'} />
            <Text variant={`body2`} medium style={{ color: FlossPalette.STAR_GRASS, paddingLeft: 8 }}>
                Learn more
            </Text>
        </div>
    </InfoTooltip>
);

const VeneerAlignerTreatmentPlanReference_Fragment = graphql(`
    fragment VeneerAlignerTreatmentPlanReference_Fragment on DesignOrderAlignerTreatmentPlanDTO {
        steps_count {
            overall
            lower
            upper
        }
    }
`);

export const AlignerTreatmentPlanReference: React.VFC<{
    treatment_plan_fragment: FragmentType<typeof VeneerAlignerTreatmentPlanReference_Fragment>;
}> = ({ treatment_plan_fragment }) => {
    const treatment_plan = getFragmentData(VeneerAlignerTreatmentPlanReference_Fragment, treatment_plan_fragment);
    const [open, set_open] = React.useState(false);
    const plans_details = useAlignerPlansDetails();
    return (
        <RootActionDialog
            loading={false}
            open={open}
            setOpen={set_open}
            title={<></>}
            titleProps={{ style: { position: `absolute`, border: 0, background: `none` } }}
            showCloseButton={true}
            buttonText={`View aligner pricing`}
            buttonProps={{
                fullWidth: true,
                variant: `ghost`,
            }}
            contentProps={{ style: { padding: 32, border: 0 } }}
            dialogProps={{ maxWidth: `md` }}
            content={
                <div>
                    <Text variant={`h4`} style={{ padding: 8 }}>
                        Aligner treatment plan pricing
                    </Text>
                    <div style={{ display: `flex`, padding: 8 }}>
                        <div style={{ padding: `32px 16px 0 8px` }}>
                            <AlignerTreatmentPlanDetailsRow
                                content={
                                    <Text variant={`body2`} medium style={{ color: FlossPalette.GRAY }}>
                                        Plan
                                    </Text>
                                }
                            />
                            <AlignerTreatmentPlanDetailsRow
                                border
                                content={
                                    <Text variant={`body2`} medium>
                                        Price
                                    </Text>
                                }
                            />
                            <AlignerTreatmentPlanDetailsRow
                                border
                                content={
                                    <Text variant={`body2`} medium>
                                        Refinements
                                    </Text>
                                }
                            />
                            <AlignerTreatmentPlanDetailsRow
                                content={
                                    <Text variant={`body2`} medium>
                                        Retainers
                                    </Text>
                                }
                            />
                        </div>
                        {PLANS_IN_ORDER.map(plan => (
                            <AlignerTreatmentPlanDetailsBox
                                key={plan}
                                disabled={!!plans_details[plan].unavailability(treatment_plan)}
                            >
                                <div style={{ flexGrow: 1, display: `flex`, flexDirection: `column`, height: `100%` }}>
                                    <AlignerTreatmentPlanDetailsRow
                                        content={<Text variant={`h6`}>{plans_details[plan].title}</Text>}
                                    />
                                    <AlignerTreatmentPlanDetailsRow
                                        border
                                        content={
                                            plans_details[plan].unavailability(treatment_plan) ? (
                                                <Tooltip
                                                    title={
                                                        plans_details[plan].unavailability(treatment_plan) ||
                                                        'Not available'
                                                    }
                                                >
                                                    <div style={{ display: `flex` }}>
                                                        <Text variant={`body1`} medium style={{ paddingRight: 8 }}>
                                                            Not available
                                                        </Text>
                                                        <Icon icon={'InfoOutlinedIcon'} />
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                <Text variant={`body1`} medium>
                                                    {plans_details[plan].calculated_price(treatment_plan)}
                                                </Text>
                                            )
                                        }
                                    />
                                    <AlignerTreatmentPlanDetailsRow
                                        border
                                        content={<Text variant={`body2`}>{plans_details[plan].refinements}</Text>}
                                    />
                                    <AlignerTreatmentPlanDetailsRow
                                        grow
                                        content={<Text variant={`body2`}>{plans_details[plan].retainers}</Text>}
                                    />
                                    <AlignerTreatmentPlanLearnMore plan_details={plans_details[plan]} />
                                </div>
                            </AlignerTreatmentPlanDetailsBox>
                        ))}
                    </div>
                    <div style={{ display: `flex`, justifyContent: `flex-end`, padding: 8 }}>
                        <div style={{ padding: 8 }}>
                            <Button onClick={() => set_open(false)} variant={`secondary`}>
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            }
        />
    );
};
