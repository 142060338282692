import _ from 'lodash';

const IN_DESIGN = [
    'ResolveHold',
    'ScanReview',
    'ResolveScanRejection',
    'DesignReview',
    'DesignReview2',
    'DesignPrep',
    'AutomateDesign',
    'ModelDesign',
    'ExternalDesignVerification',
];

export const friendlyCancelAndResubmitOrderStatus = (orderStatus: string, orderActiveTaskType: string) => {
    if (IN_DESIGN.includes(orderActiveTaskType)) {
        return 'Design';
    }
    return _.startCase(orderStatus);
};

export const cancelAndResubmitSubtitleText = (orderStatus: string, orderActiveTaskType: string) => {
    if (orderStatus === 'Fabrication') {
        return 'Your order is already in fabrication. Therefore, your edits will be processed as a new order and your original order will be canceled.';
    }
    if (IN_DESIGN.includes(orderActiveTaskType)) {
        return 'Your order is already in design. Therefore, your edits will be processed as a new order and your original order will be canceled.';
    }
    return 'Your edits will be processed as a new order and your original order will be canceled.';
};
