import type { RestorativeModel, ScanGeometries } from './FinishingApp.types';
import { OperationsManager } from './OperationsManager';
import type { OperationsStateMinusManager, OperationsState } from './OperationsManager.types';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import React from 'react';

export function useOperations(
    restorativeModels: ArrayMin1<RestorativeModel>,
    scanGeometries: ScanGeometries,
): OperationsState {
    const [operationsState, setOperationsState] = React.useState<OperationsStateMinusManager>({
        hasUndo: false,
        hasRedo: false,
        editingToothNumber: restorativeModels[0].toothNumber,
        itemToothNumbers: restorativeModels.map(el => el.toothNumber),
    });

    const manager = React.useMemo(
        () => new OperationsManager(restorativeModels, setOperationsState, scanGeometries),
        [restorativeModels, scanGeometries],
    );

    return React.useMemo(() => ({ ...operationsState, manager }), [operationsState, manager]);
}
