import { EditDoctorLabSlipAlerts } from './EditDoctorLabSlipAlerts';
import { EditDoctorPreferences } from './EditDoctorPreferences';
import type {
    LabsGqlStaffMemberDtoFragment,
    LabsGqlUserDtoFragment,
    LabsGqlDoctorPreferencesFragment,
} from '@orthly/graphql-operations';
import { useGetOrgQuery, useListRetainerUsersQuery, useCurrentDoctorPreferencesQuery } from '@orthly/graphql-react';
import type { LabsGqlStaffRole } from '@orthly/graphql-schema';
import { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import { MUITable, downloadAsCsv } from '@orthly/mui-table';
import { PhoneNumberUtils } from '@orthly/runtime-utils';
import { Button, Grid, Link, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface OrganizationUsersTableProps {
    organizationId: string;
}

interface OrganizationUsersTableRow {
    id: string;
    name: string;
    roles: string;
    email: string;
    phone_number: string;
    staff_id?: string;
}

interface OrganizationUserAndRolesProps {
    name: string;
    roles: string;
    userId: string;
}

const OrganizationUserAndRoles: React.FC<OrganizationUserAndRolesProps> = props => {
    const { name, roles, userId } = props;
    return (
        <>
            <strong>
                <Link href={`/users/${userId}`}>{name}</Link>
            </strong>
            {roles && (
                <>
                    <br />
                    <Text variant={'caption'}>{roles}</Text>
                </>
            )}
        </>
    );
};

function prettifyRoles(roles: LabsGqlStaffRole[]) {
    return _.compact(
        roles.map(role => {
            const parts = role.split('__');
            const name = _.last(parts);
            if (!name) {
                // Appeasing the type system
                return null;
            }
            return _.capitalize(name);
        }),
    );
}

function organizationUsersTableRows(
    staff: LabsGqlStaffMemberDtoFragment[],
    users: LabsGqlUserDtoFragment[],
): OrganizationUsersTableRow[] {
    const staffByUserId = _.keyBy(staff, staff => staff.user_id);
    return users.map(user => {
        const member = staffByUserId[user.id];
        const roles = prettifyRoles(member?.roles ?? []);
        return {
            id: user.id,
            staff_id: member?.id,
            name: `${user.first_name} ${user.last_name}`,
            roles: roles.sort().join(', '),
            email: user.email,
            phone_number: user.phone_number ? PhoneNumberUtils.prettyPhoneNumber(user.phone_number) : '',
        };
    });
}

interface OrganizationUserActionProps {
    row: Pick<OrganizationUsersTableRow, 'roles' | 'id'>;
    refetch: () => Promise<any>;
    doctorPreferences?: LabsGqlDoctorPreferencesFragment;
}

const OrganizationUserAction: React.VFC<OrganizationUserActionProps> = ({ row, refetch, doctorPreferences }) => {
    // We currently only have custom actions for Doctors
    if (!row.roles.includes('Doctor') || !doctorPreferences) {
        return null;
    }

    return (
        <>
            <EditDoctorPreferences
                refetchPreferences={refetch}
                prefs={doctorPreferences}
                customButton={({ onClick }) => (
                    <Button variant={'ghost'} style={{ height: 24 }} onClick={onClick}>
                        Preferences
                    </Button>
                )}
            />
            <EditDoctorLabSlipAlerts
                refetchPreferences={refetch}
                doctor={doctorPreferences}
                customButton={({ onClick }) => (
                    <Button variant={'ghost'} style={{ height: 24 }} onClick={onClick}>
                        Lab Slip Alerts
                    </Button>
                )}
            />
        </>
    );
};

export const OrganizationUsersTable: React.FC<OrganizationUsersTableProps> = props => {
    const { data: organizationData, loading: organizationLoading } = useGetOrgQuery({
        variables: { id: props.organizationId },
    });
    const organization = organizationData?.getOrganization;
    const organizationStaff = (organization?.staff ?? []).filter(member => !member.deactivated_at);
    const organizationStaffUserIds = organizationStaff.map(member => member.user_id);
    const { data: organizationUsersData, loading: organizationUsersLoading } = useListRetainerUsersQuery({
        skip: organizationStaffUserIds.length === 0,
        variables: { filter: { ids: organizationStaffUserIds } },
    });

    // TODO: this is a bit awkward to be stored here, but it is the MUI-table friendly way.
    const { refetch: refetchPreferences, data: doctorPreferencesData } = useCurrentDoctorPreferencesQuery({
        variables: { partner_id: props.organizationId },
        // Only practices have doctor prefs, so we skip for all other types.
        skip: organization?.type !== LabsGqlOrganizationType.Practice,
    });

    const rows = organizationUsersTableRows(organizationStaff, organizationUsersData?.listUsers ?? []);
    return (
        <Grid container style={{ width: '100%' }}>
            <Grid item xs={12}>
                <MUITable<OrganizationUsersTableRow>
                    title={organization ? `${organization.legal_name} - Users` : 'Users'}
                    loading={organizationLoading || organizationUsersLoading}
                    displayOptions={{ filter: true, fixedSearch: true }}
                    columns={[
                        { name: 'id', render: row => row.id, hidden: true },
                        {
                            name: 'Name',
                            render: row => (
                                <OrganizationUserAndRoles name={row.name} roles={row.roles} userId={row.id} />
                            ),
                        },
                        { name: 'Email', render: row => row.email },
                        { name: 'Phone', render: row => row.phone_number },
                        {
                            name: '',
                            render: row => (
                                <OrganizationUserAction
                                    row={row}
                                    refetch={refetchPreferences}
                                    doctorPreferences={
                                        doctorPreferencesData?.preferences?.find(
                                            prefs => prefs.staff_member_id === row.staff_id,
                                        ) ?? doctorPreferencesData?.preferences?.[0]
                                    }
                                />
                            ),
                        },
                    ]}
                    actions={{
                        global: [
                            {
                                tooltip: 'Export',
                                icon: 'save_alt',
                                position: 'toolbar',
                                onClick: () => {
                                    downloadAsCsv(rows, 'Organization Users Export');
                                },
                            },
                        ],
                    }}
                    data={rows}
                />
            </Grid>
        </Grid>
    );
};
