import type { MenuItemProps } from '../OrderDetailToolbarFiles.types';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { OrthlyBrowserConfig, useRootActionCommand } from '@orthly/ui';
import { MenuItem } from '@orthly/ui-primitives';
import React from 'react';

const AdminNonProdQuickAddDesignAction_Mutation = graphql(`
    mutation AdminNonProdQuickAddDesignAction_Mutation($design_order_id: String!) {
        nonProductionAutoSubmitDesign(design_order_id: $design_order_id)
    }
`);

export const NonProdQuickAddDesignAction: React.FC<MenuItemProps> = props => {
    const { order, refetchOrder } = props;
    const submitMtn = useMutation(AdminNonProdQuickAddDesignAction_Mutation);
    const { submit, submitting } = useRootActionCommand(submitMtn, {
        successMessage: 'Submitted testing design!',
        onSuccess: async () => {
            await refetchOrder();
        },
    });

    if (OrthlyBrowserConfig.isProduction) {
        return null;
    }

    return (
        <MenuItem
            disabled={submitting}
            onClick={async () => {
                await submit({
                    design_order_id: order.id,
                });
            }}
        >
            Quick Add Design [NonProd]
        </MenuItem>
    );
};
