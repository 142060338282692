import type { RefabFlowOrderDetailsItem, RefabFlowOrderDetailsLayoutProps, SkuSpecificField } from './types';
import type { LabsGqlCustomFieldPrefsFragment, LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import type { ICustomFieldSubmission, IOrderItemV2DTO } from '@orthly/items';
import { AllItemMetafields, CartItemV2Utils, OrderItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import _ from 'lodash';
import moment from 'moment';

/**
 * Function used to return a friendly name in the order details eg: Crown 1, Bridge 10,11,12,13,14.
 */
const getItemDisplayName = (item: IOrderItemV2DTO): string => {
    const unns = CartItemV2Utils.getUniqueUNNs(item);

    if (unns.length > 0) {
        const firstUnn = unns[0];

        if (unns.length > 1) {
            const lastUnn = unns.slice(-1);
            return `${CartItemV2Utils.getDisplayName(item)} ${firstUnn}-${lastUnn}`;
        }

        return `${CartItemV2Utils.getDisplayName(item)} ${firstUnn}`;
    }

    return `${CartItemV2Utils.getDisplayName(item)}`;
};

/**
 * In this function we want to remove all the preferences that are:
 * - set to the same value as the one set in the doctor preferences
 * - or set to the same value as the default value defined in the metafield definition if the doctor preferences are not set
 *
 * This way in the order details, we'll only show the preferences that have been customized
 * by the doctor compared to their default settings.
 */
const shouldShowPreferenceValue = (
    itemPreference: ICustomFieldSubmission,
    doctorCustomFieldPreferences: Omit<LabsGqlCustomFieldPrefsFragment, '__typename'>[],
) => {
    const doctorPreference = doctorCustomFieldPreferences.find(
        doctorPref => doctorPref.field_id === itemPreference.field_id,
    );
    if (doctorPreference) {
        return doctorPreference.value !== itemPreference.value;
    }

    const preferenceDefinition = AllItemMetafields.find(metaPref => metaPref.id === itemPreference.field_id);
    if (preferenceDefinition) {
        return preferenceDefinition.default_value !== itemPreference.value;
    }

    return true;
};

const getPreferencesToShowInOrderDetails = (
    itemPreferences: ICustomFieldSubmission[],
    doctorPreferences: Omit<LabsGqlCustomFieldPrefsFragment, '__typename'>[],
): ICustomFieldSubmission[] => {
    return itemPreferences.filter(pref => shouldShowPreferenceValue(pref, doctorPreferences));
};

const formatSkuSpecificFields = (item: IOrderItemV2DTO): SkuSpecificField[] => {
    const additionalFields: SkuSpecificField[] = [];

    if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Denture)) {
        additionalFields.push({ label: 'Type', value: _.startCase(item.denture_type) });

        // we only include the arch for denture items because it appears as either a preference field or material for all other arch items
        additionalFields.push({ label: 'Arch', value: item.unit.arch });
    } else if (CartItemV2Utils.itemTypeHasImplant(item)) {
        const implantsMetadata = CartItemV2Utils.getImplantMetadata(item);

        if (implantsMetadata?.relationship) {
            additionalFields.push({ label: 'Relationship', value: _.startCase(implantsMetadata.relationship) });
        }

        if (implantsMetadata?.system) {
            additionalFields.push({ label: 'System', value: implantsMetadata.system });
        }

        if (implantsMetadata?.manufacturer) {
            additionalFields.push({ label: 'Manufacturer', value: implantsMetadata.manufacturer });
        }

        if (implantsMetadata?.connection_size) {
            additionalFields.push({ label: 'Connection', value: implantsMetadata.connection_size });
        }

        const implantToothGroup = CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Implant)
            ? item.unit
            : item.implants[0];

        if (implantToothGroup?.crown?.material) {
            additionalFields.push({ label: 'Crown Material', value: implantToothGroup.crown.material });
        }

        if (implantToothGroup?.abutment?.material) {
            additionalFields.push({ label: 'Abutment Material', value: implantToothGroup.abutment.material });
        }
    } else {
        const material = CartItemV2Utils.getItemDisplayMaterial(item);

        if (material) {
            additionalFields.push({ label: 'Material', value: material });
        }
    }

    return additionalFields;
};

const formatOrderItem = (
    item: IOrderItemV2DTO,
    doctorPreferences: Omit<LabsGqlCustomFieldPrefsFragment, '__typename'>[],
): RefabFlowOrderDetailsItem => {
    const filteredPreferences = getPreferencesToShowInOrderDetails(item.preference_fields, doctorPreferences);

    return {
        name: getItemDisplayName(item),
        shades: item.shades.map(shade => ({
            name: _.startCase(shade.name),
            value: shade.value,
        })),
        skuSpecificFields: formatSkuSpecificFields(item),
        preferences: filteredPreferences.map(pref => ({
            name: pref.display_name,
            value: _.startCase(`${pref.value}`),
        })),
    };
};

/**
 * Function used to take data from the API from an order and the doctor preferences and
 * format its fields so it's easily digestable by the UI components later. We want to
 * remove as much business logic from the UI elements as possible.
 */
export const formatOrderDetailsData = (
    order: Pick<LabsGqlLabOrderFragment, 'items_v2' | 'doctor_name' | 'created_at' | 'delivery_date'>,
    doctorPreferences: Omit<LabsGqlCustomFieldPrefsFragment, '__typename'>[],
): RefabFlowOrderDetailsLayoutProps => {
    const convertedItems = OrderItemV2Utils.parseItems(order.items_v2);

    // Sort items by tooth number, so lower tooth number items show first
    const sortedItems = _.sortBy(convertedItems, item => {
        return Math.min(...CartItemV2Utils.getUniqueUNNs(item));
    });

    return {
        items: sortedItems.map(item => formatOrderItem(item, doctorPreferences)),
        doctorName: `Dr. ${order.doctor_name}`,
        placedAt: moment(order.created_at).format('MMMM DD, YYYY'),
        arrivedAt: moment(order.delivery_date).format('MMMM DD, YYYY'),
    };
};
