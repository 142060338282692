import { DandyRecommendedIcon } from '@orthly/ui';
import { Text, stylesFactory, FlossPalette, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    recommendedHeader: {
        backgroundColor: FlossPalette.STAR_GRASS,
        color: FlossPalette.WHITE,
        fontSize: 12,
        padding: '4px 0 4px 24px',
        width: '100%',
        borderRadius: '4px 4px 0 0',
        marginLeft: 2,
        alignItems: 'center',
        whiteSpace: `normal`,
    },
    iconColumn: {
        display: 'flex',
    },
    textColumn: {
        display: `flex`,
        flex: 1,
        paddingLeft: 8,
        textAlign: `left`,
    },
}));

interface RecommendationHeaderProps {
    styleOverrides?: React.CSSProperties;
    customText?: string;
}

export const RecommendationHeader: React.VFC<RecommendationHeaderProps> = ({ styleOverrides, customText }) => {
    const classes = useStyles();

    return (
        <Grid container direction={'row'} className={classes.recommendedHeader} style={styleOverrides}>
            <Grid item className={classes.iconColumn}>
                <DandyRecommendedIcon />
            </Grid>
            <Grid item className={classes.textColumn}>
                <Text variant={'caption'} color={'WHITE'} medium>
                    {customText ?? 'Recommended'}
                </Text>
            </Grid>
        </Grid>
    );
};
