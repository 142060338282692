import { useMountingMatrix } from '../../hooks/useMountingMatrix';
import { DESIGN_QC_TOGGLE_VIEWS_ENABLED_LOCAL_STORAGE_KEY } from './DesignQC.types';
import type {
    DesignQcRubricCategory,
    DesignQcRubricSubCategoryEntry,
    ModelAppearancePayloadPreset,
} from './configs/DesignQc.config.types';
import { useDesignQcPropSelector } from './state/DesignQc.context';
import { getAllUnns } from './state/DesignQc.selectors';
import type { DandyAnalyticsEventSchemaType } from '@orthly/analytics/dist/browser';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { QCSettings, ItemAppearance, ModelAppearance, MainViewCameraControlsRef } from '@orthly/dentin';
import {
    isDesignBiteScanItem,
    DEFAULT_RESTORATIVE_APPEARANCE,
    DEFAULT_SCAN_EXPORT_APPEARANCE,
    DEFAULT_SCAN_APPEARANCE,
    usePresetViewSetter,
    isRawPrepScanItem,
} from '@orthly/dentin';
import { OrderDesignPreviewDesign_FragmentFragmentDoc, getFragmentData } from '@orthly/graphql-inline-react';
import { ToothUtils } from '@orthly/items';
import { FlossPalette, Text, Grid, makeStyles, createStyles } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

// Maps category names to a human readable name
export const DesignQcRubricCategoryToLabelMap: { [K in DesignQcRubricCategory]: string } = {
    bl_contour: 'Height of Contour',
    contacts: 'Interproximal Contacts',
    connectors: 'Connectors',
    emergence_profile_and_embrasures: 'Emergence Profile & Embrasures',
    abutment_emergence_profile_and_embrasures: 'Abutment Emergence Profile & Embrasures',
    pontic_emergence_profile_and_embrasures: 'Pontic Emergence Profile & Embrasures',
    pontics: 'Pontics',
    marginal_ridges: 'Marginal Ridges',
    occlusal_anatomy: 'Occlusal Anatomy',
    occlusion: 'Occlusion',
    path_of_insertion: 'Insertion Direction',
    bite: 'Bite',
    finishing_line: 'Margin Line',
    general: 'General',
    model_design: 'Model Design',
    follow_rx: 'Model Follows Rx',
    model_articulation: 'Model Articulation',
    tom_sculpting: 'TOM Sculpting',
    bl_anatomy: 'B/L Anatomy',
    crown_to_crown_contacts: 'Crown-to-Crown Contacts',
    aesthetics: 'Aesthetics',
};

const useStyles = makeStyles(() =>
    createStyles({
        badgeCount: {
            backgroundColor: FlossPalette.DARK_TAN,
            height: 16,
            minWidth: 16,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
            marginLeft: 8,
            paddingRight: 6,
            paddingLeft: 6,
            width: `auto`,
        },
        tabWithBadge: {
            justifyContent: 'center',
            alignItems: 'center',
        },
    }),
);

export const BadgeWithCounts: React.VFC<{ label: string; total: number; progress?: number }> = ({
    label,
    total,
    progress,
}) => {
    const classes = useStyles();
    return (
        <Grid container direction={'row'} className={classes.tabWithBadge}>
            <Grid item>
                <Text variant={'body2'} medium>
                    {label}
                </Text>
            </Grid>
            <Grid item container className={classes.badgeCount}>
                <Text variant={'caption'} medium color={'GRAY'}>
                    {progress !== undefined ? `${progress}/${total}` : total}
                </Text>
            </Grid>
        </Grid>
    );
};

export function useGuidedQcAnalytics<T extends keyof DandyAnalyticsEventSchemaType>(type: T) {
    const { order, rubricNavigation } = useDesignQcPropSelector(['order', 'rubricNavigation']);

    return React.useCallback(
        (data: Omit<DandyAnalyticsEventSchemaType[T], 'category' | 'subcategory' | '$groups'>) => {
            if (!rubricNavigation?.entry) {
                return;
            }

            BrowserAnalyticsClientFactory.Instance?.track(type, {
                $groups: {
                    order: order.id,
                },
                category: rubricNavigation.category,
                subcategory: rubricNavigation.entry.name,
                ...data,
            } as unknown as DandyAnalyticsEventSchemaType[T]);
        },
        [type, order, rubricNavigation],
    );
}

const DefaultModelAppearanceMap: Record<keyof ModelAppearancePayloadPreset, ItemAppearance> = {
    restoratives_cad: DEFAULT_RESTORATIVE_APPEARANCE,
    order_scans_bite: DEFAULT_SCAN_EXPORT_APPEARANCE,
    order_scans_raw_prep: DEFAULT_SCAN_EXPORT_APPEARANCE,
    printed_models: DEFAULT_SCAN_APPEARANCE,
    opposing_jaw: DEFAULT_SCAN_APPEARANCE,
    preparation_jaw: DEFAULT_SCAN_APPEARANCE,
};

const DefaultQcSettings: QCSettings = {
    showDoctorToothMarkings: false,
    showCollisions: false,
    showCurtainsHeatmap: false,
    showDoctorMarginLines: false,
    showMarginLines: false,
    showCurtainsCollisions: false,
    showAnatomyLayers: false,
    heatMapRange: undefined,
    activeHeatMap: null,
};

interface UseGuidedQcPresetsProps {
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    appearance: ModelAppearance;
    controlRef: MainViewCameraControlsRef;
    arePresetViewsEnabled: boolean;
}

// Updates the appearance settings on the fly as the user navigates through the guided qc.

export function useGuidedQcPresets({
    setAppearance,
    appearance,
    controlRef,
    arePresetViewsEnabled,
}: UseGuidedQcPresetsProps) {
    const { designFragment, rubricNavigation, order } = useDesignQcPropSelector([
        'designFragment',
        'rubricNavigation',
        'order',
    ]);

    const [previousEntry, setPreviousEntry] = React.useState<DesignQcRubricSubCategoryEntry | undefined>(undefined);

    const design = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, designFragment);

    const mountingMatrix = useMountingMatrix(design);
    const { generalToothViewSetter, axisAlignedViewSetter } = usePresetViewSetter(
        mountingMatrix,
        controlRef,
        appearance,
    );

    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    React.useEffect(() => {
        if (
            previousEntry?.name === rubricNavigation?.entry?.name &&
            previousEntry?.category === rubricNavigation?.entry?.category
        ) {
            return;
        }

        const toothNumbers = getAllUnns({ order });
        const presets = rubricNavigation?.entry?.presets;

        const applySetting = (
            currentValue: ItemAppearance,
            key: keyof ModelAppearancePayloadPreset,
        ): ItemAppearance => {
            const newGuidedValue = presets?.payload_presets?.[key];
            // Since this step has a new guided value, we will go with it.
            if (newGuidedValue) {
                return newGuidedValue;
            }

            const previousGuidedValue = previousEntry?.presets?.payload_presets?.[key];
            const defaultValue = DefaultModelAppearanceMap[key];

            // If the value was tweaked from the previous guided value, we will leave it alone.
            // Odds are, the user did that intentionally.
            if (!_.isEqual(previousGuidedValue, currentValue)) {
                return currentValue;
            }

            // The user hasn't changed it, and we didn't override it for this entry, so we'll go with the default.
            return defaultValue;
        };

        // Don't be fooled: you can have two non-opposing jaws!
        const isLowerOpposing = !toothNumbers.some(unn => ToothUtils.toothIsLower(unn));
        const isUpperOpposing = !toothNumbers.some(unn => ToothUtils.toothIsUpper(unn));

        setAppearance(appearance => ({
            ...appearance,
            ...DefaultQcSettings,
            ...presets?.qc_settings,
            lowerJaw: appearance.lowerJaw.map(baseScan => ({
                ...baseScan,
                appearance: applySetting(baseScan.appearance, isLowerOpposing ? 'opposing_jaw' : 'preparation_jaw'),
            })),
            upperJaw: appearance.upperJaw.map(baseScan => ({
                ...baseScan,
                appearance: applySetting(baseScan.appearance, isUpperOpposing ? 'opposing_jaw' : 'preparation_jaw'),
            })),
            printedModels: appearance.printedModels.map(baseScan => ({
                ...baseScan,
                appearance: applySetting(baseScan.appearance, 'printed_models'),
            })),
            scans: appearance.scans.map(baseScan => ({
                ...baseScan,
                // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                // eslint-disable-next-line no-nested-ternary
                appearance: isDesignBiteScanItem(baseScan.payloadModel)
                    ? applySetting(baseScan.appearance, 'order_scans_bite')
                    : isRawPrepScanItem(baseScan.payloadModel)
                      ? applySetting(baseScan.appearance, 'order_scans_raw_prep')
                      : baseScan.appearance,
            })),
            restoratives: appearance.restoratives.map(baseScan => ({
                ...baseScan,
                appearance: applySetting(baseScan.appearance, 'restoratives_cad'),
            })),
        }));

        // We will only display the preset views (camera angles) if the user has them turned on.
        if (arePresetViewsEnabled) {
            const firstTooth = toothNumbers[0];
            if (presets?.view_direction_preset?.type === 'tooth_view' && firstTooth) {
                generalToothViewSetter(firstTooth, 'F');
            }

            if (presets?.view_direction_preset?.type === 'axis_view') {
                switch (presets.view_direction_preset.view) {
                    case 'BACK':
                    case 'FRONT':
                    case 'LEFT':
                    case 'RIGHT':
                    case 'TOP':
                    case 'BOTTOM':
                        axisAlignedViewSetter(presets.view_direction_preset.view);
                        break;
                    case 'opposing_from_prep':
                        axisAlignedViewSetter(isLowerOpposing ? 'TOP' : 'BOTTOM');
                        break;
                    case 'prep_from_opposing':
                        axisAlignedViewSetter(isLowerOpposing ? 'BOTTOM' : 'TOP');
                        break;
                }
            }
        }

        if (
            previousEntry?.name !== rubricNavigation?.entry?.name ||
            previousEntry?.category !== rubricNavigation?.entry?.category
        ) {
            setPreviousEntry(rubricNavigation?.entry);
        }
    }, [
        setAppearance,
        rubricNavigation,
        previousEntry,
        setPreviousEntry,
        order,
        design,
        arePresetViewsEnabled,
        generalToothViewSetter,
        axisAlignedViewSetter,
    ]);
}

export function useGuidedQcPresetViewsEnabled() {
    const [isEnabled, setIsEnabled] = React.useState<boolean>(
        !!JSON.parse(localStorage.getItem(DESIGN_QC_TOGGLE_VIEWS_ENABLED_LOCAL_STORAGE_KEY) ?? 'true'),
    );

    const handleChange = (enabled: boolean) => {
        setIsEnabled(enabled);
        localStorage.setItem(DESIGN_QC_TOGGLE_VIEWS_ENABLED_LOCAL_STORAGE_KEY, JSON.stringify(enabled));
    };

    return [isEnabled, handleChange] as const;
}
