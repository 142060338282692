import { type IndexedSphere, marginsEqual } from '../../ModelViewer/Margin.util';
import type { ScanReviewViewManager } from '../ScanReviewViewTypes';
import {
    type ScanReviewMarginLineEditor,
    type ScanReviewMarginMarkingToolLiveObjectsProvider,
    MARGIN_LINE_CONTROL_POINT_EPSILON,
} from './ScanReviewMarginMarkingToolServices';
import {
    type ScanReviewMarginMarkingToolState,
    ScanReviewMarginMarkingDrawingState,
    ScanReviewMarginMarkingTransformingState,
    ScanReviewMarginMarkingOrbitingState,
} from './ScanReviewMarginMarkingToolStates';
import type { ScanReviewEditedMarginLine } from './ScanReviewMarginMarkingToolTypes';
import {
    ScanReviewMarginLineConnection,
    ScanReviewMarginMarkingToolActiveState,
} from './ScanReviewMarginMarkingToolTypes';
import type { ToothNumber } from '@orthly/items';

export enum ScanReviewMarginMarkingTransformStatus {
    Success = 0,
    Failure = 1,
}

export class ScanReviewMarginMarkingToolController {
    private state: ScanReviewMarginMarkingToolState | null = null;
    private affectNeighbors: boolean = false;
    private numberOfNeighbors = 10;
    private effectDistance = 2.0;

    static makeController(
        viewManager: ScanReviewViewManager,
        editor?: ScanReviewMarginLineEditor,
        activeTooth?: ToothNumber,
        liveObjectsProvider?: ScanReviewMarginMarkingToolLiveObjectsProvider,
        onMarginUpdate?: (editedMarginLine: ScanReviewEditedMarginLine) => void,
    ) {
        return (
            editor &&
            activeTooth &&
            liveObjectsProvider &&
            new ScanReviewMarginMarkingToolController(viewManager, editor, liveObjectsProvider, onMarginUpdate)
        );
    }

    constructor(
        private readonly viewManager: ScanReviewViewManager,
        private readonly editor: ScanReviewMarginLineEditor,
        private readonly liveObjectsProvider: ScanReviewMarginMarkingToolLiveObjectsProvider,
        private readonly onMarginUpdate?: (editedMarginLine: ScanReviewEditedMarginLine) => void,
    ) {
        this.switchState(ScanReviewMarginMarkingToolActiveState.Drawing);
    }

    get group() {
        return this.liveObjectsProvider.group;
    }

    updatePreviewCircle() {
        if (!this.viewManager.cameraRef.current) {
            return;
        }

        const { activeHighlight, marginLineIntersection, geometryIntersection } = this.liveObjectsProvider;
        const isMarginClosed = this.editor.currentEditedMarginLine.marginClosed;

        if (activeHighlight) {
            this.liveObjectsProvider.setPreviewCircleVisibility(true);
            this.liveObjectsProvider.updateActiveHighlightPreviewCircle(
                activeHighlight.position,
                this.viewManager.cameraRef.current.rotation,
            );
        } else if (marginLineIntersection) {
            this.liveObjectsProvider.setPreviewCircleVisibility(true);
            this.liveObjectsProvider.updateInsertMarginPointPreviewCircle(
                marginLineIntersection,
                this.viewManager.cameraRef.current.rotation,
            );
        } else if (!isMarginClosed && geometryIntersection) {
            this.liveObjectsProvider.setPreviewCircleVisibility(true);
            this.liveObjectsProvider.updatePreviewCircle(
                geometryIntersection,
                this.viewManager.cameraRef.current.rotation,
            );
        } else {
            this.liveObjectsProvider.setPreviewCircleVisibility(false);
        }
    }

    setMarginLineVisibility(visible: boolean) {
        this.liveObjectsProvider.setMarginLineVisibility(visible);
    }

    updateMarginLine() {
        this.liveObjectsProvider.updateMarginLine(this.editor.currentEditedMarginLine);
    }

    updateCompletionLine() {
        if (this.editor.currentEditedMarginLine.controlPoints.length < 3) {
            return;
        }

        const activeHighlight = this.liveObjectsProvider.activeHighlight;
        if (!activeHighlight) {
            return;
        }

        const controlPointIndex = activeHighlight.index;
        const isEndPoint =
            controlPointIndex === 0 ||
            controlPointIndex === this.editor.currentEditedMarginLine.controlPoints.length - 1;

        if (isEndPoint && this.editor.canCloseLine()) {
            this.liveObjectsProvider.setPreviewLineVisibility(true);
            this.liveObjectsProvider.updateCompletionLine();
        } else {
            this.liveObjectsProvider.setPreviewLineVisibility(false);
        }
    }

    updatePreviewLine() {
        const intersection = this.liveObjectsProvider.geometryIntersection;
        if (!intersection) {
            return;
        }

        const activeHighlight = this.liveObjectsProvider.activeHighlight;
        if (activeHighlight) {
            return;
        }

        const bestConnection = this.editor.findBestConnection(intersection);
        if (bestConnection === ScanReviewMarginLineConnection.None) {
            this.liveObjectsProvider.setPreviewLineVisibility(false);
            return;
        }
        this.liveObjectsProvider.setPreviewLineVisibility(true);
        this.liveObjectsProvider.updatePreviewLine(intersection, bestConnection === ScanReviewMarginLineConnection.End);
    }

    isPointerOverMesh() {
        return this.liveObjectsProvider.geometryIntersection !== undefined;
    }

    isPointerOverMarginLine() {
        return this.liveObjectsProvider.marginLineIntersection !== undefined;
    }

    isPointerOverControlPoint() {
        return this.liveObjectsProvider.activeHighlight !== undefined;
    }

    isMarginLineClosed() {
        return this.editor.currentEditedMarginLine.marginClosed;
    }

    getActiveHighlight() {
        return this.liveObjectsProvider.activeHighlight;
    }

    insertControlPoint() {
        const insertionPoint = this.liveObjectsProvider.marginLineIntersection;
        if (!insertionPoint) {
            return;
        }
        this.editor.insertControlPoint(insertionPoint);
        this.updateMarginLine();
        this.invokeOnUpdateCallback();
    }

    deleteControlPoint() {
        const activeHighlight = this.liveObjectsProvider.activeHighlight;
        if (!activeHighlight) {
            return;
        }
        this.editor.deleteControlPoint(activeHighlight.index);
        this.updateMarginLine();
        this.invokeOnUpdateCallback();
    }

    addControlPointOrCloseLine() {
        const activeHighlight = this.liveObjectsProvider.activeHighlight;
        if (activeHighlight) {
            const controlPointIndex = activeHighlight.index;
            const isEndPoint =
                controlPointIndex === 0 ||
                controlPointIndex === this.editor.currentEditedMarginLine.controlPoints.length - 1;

            if (isEndPoint && this.editor.canCloseLine()) {
                this.editor.closeLine();
            }
        } else {
            const intersection = this.liveObjectsProvider.geometryIntersection;
            if (intersection === undefined) {
                return;
            }
            this.editor.addControlPoint(intersection);
        }

        this.setMarginLineVisibility(true);
        this.updateMarginLine();
        this.invokeOnUpdateCallback();
    }

    private invokeOnUpdateCallback() {
        this.onMarginUpdate?.(this.editor.currentEditedMarginLine);
    }

    setCameraControlsEnabled(enabled: boolean): void {
        if (!this.viewManager.cameraControlsRef.current) {
            return;
        }
        this.viewManager.cameraControlsRef.current.enabled = enabled;
    }

    switchState(nextActiveToolState: ScanReviewMarginMarkingToolActiveState): void {
        if (this.state) {
            this.state.exit();
        }

        switch (nextActiveToolState) {
            case ScanReviewMarginMarkingToolActiveState.Drawing: {
                this.state = new ScanReviewMarginMarkingDrawingState(this);
                break;
            }
            case ScanReviewMarginMarkingToolActiveState.Transforming: {
                this.state = new ScanReviewMarginMarkingTransformingState(this);
                break;
            }
            case ScanReviewMarginMarkingToolActiveState.Orbiting: {
                this.state = new ScanReviewMarginMarkingOrbitingState(this);
                break;
            }
        }
        this.state.enter();
    }

    beginTransformingControlPoint() {
        const preTransformedMarginLine = this.editor.currentEditedMarginLine;
        this.editor.pushHistory();
        return preTransformedMarginLine;
    }

    finishTransformingControlPoint(preTransformedMarginLine: ScanReviewEditedMarginLine) {
        const controlPointsChanged = !marginsEqual(
            preTransformedMarginLine.controlPoints,
            this.editor.currentEditedMarginLine.controlPoints,
            MARGIN_LINE_CONTROL_POINT_EPSILON,
        );
        if (!controlPointsChanged) {
            this.editor.undo();
        } else {
            this.invokeOnUpdateCallback();
        }
    }

    updateFalloffMap(activeControlPoint: IndexedSphere | undefined) {
        if (!activeControlPoint) {
            return;
        }
        if (!this.affectNeighbors) {
            return;
        }
        this.editor.updateFalloffMap(activeControlPoint.index, this.numberOfNeighbors, this.effectDistance);
    }

    updateControlPointTransform(activeControlPoint: IndexedSphere) {
        if (!this.viewManager.cameraRef.current) {
            return;
        }

        const intersection = this.liveObjectsProvider.geometryIntersection;
        if (!intersection) {
            return ScanReviewMarginMarkingTransformStatus.Failure;
        }

        const marginPoint = this.editor.currentEditedMarginLine.controlPoints[activeControlPoint.index];
        if (!marginPoint) {
            return ScanReviewMarginMarkingTransformStatus.Failure;
        }

        // remember how far we travelled
        const diffVec = intersection.clone().sub(marginPoint);
        marginPoint.copy(intersection);
        if (this.affectNeighbors) {
            this.liveObjectsProvider.setEffectCircleVisibility(true);
            this.liveObjectsProvider.updateEffectCircle(
                this.effectDistance,
                intersection,
                this.viewManager.cameraRef.current.rotation,
            );

            for (const { fallOffValue, neighborIndex } of this.editor.affectedNeighbors()) {
                const currentPosition = this.editor.currentEditedMarginLine.controlPoints[neighborIndex];
                if (!currentPosition) {
                    continue;
                }
                const effectOnPreviousPoint = diffVec.clone().multiplyScalar(fallOffValue);
                currentPosition?.add(effectOnPreviousPoint);
                // snap to Scan Mesh
                const snappedPosition = this.liveObjectsProvider.snapToGeometry(currentPosition);
                if (snappedPosition) {
                    currentPosition.copy(snappedPosition);
                }
            }
        }
        this.updateMarginLine();
        return ScanReviewMarginMarkingTransformStatus.Success;
    }

    handlePointerMove(evt: MouseEvent) {
        if (this.viewManager.cameraRef.current && this.viewManager.canvasRef.current) {
            this.liveObjectsProvider.update(
                this.viewManager.canvasRef.current,
                this.viewManager.cameraRef.current,
                evt,
            );
        }
        this.state?.handlePointerMove(evt);
    }

    handlePointerDown(evt: MouseEvent) {
        if (evt.button !== 0) {
            return;
        }
        if (this.viewManager.cameraRef.current && this.viewManager.canvasRef.current) {
            this.liveObjectsProvider.update(
                this.viewManager.canvasRef.current,
                this.viewManager.cameraRef.current,
                evt,
            );
        }
        this.state?.handlePointerDown(evt);
    }

    undo() {
        this.editor.undo();
        this.updateMarginLine();
        this.invokeOnUpdateCallback();
    }

    reset() {
        this.editor.resetHistory();
        this.updateMarginLine();
        this.invokeOnUpdateCallback();
    }

    clear() {
        this.editor.clearCurrentLine();
        this.updateMarginLine();
        this.invokeOnUpdateCallback();
    }

    handlePointerUp(evt: MouseEvent) {
        if (evt.button !== 0) {
            return;
        }
        this.liveObjectsProvider.setEffectCircleVisibility(false);
        this.state?.handlePointerUp(evt);
    }

    handleKeyDown(evt: KeyboardEvent) {
        if (evt.key === 'z') {
            this.undo();
        } else if (evt.key === 'r') {
            this.reset();
        } else if (evt.key === 'c') {
            this.clear();
        }

        if (evt.shiftKey) {
            this.affectNeighbors = true;
        }
        this.state?.handleKeyDown(evt);
    }

    updateActiveHighlight() {
        this.liveObjectsProvider.updateActiveHighlight();
    }

    handleKeyUp(evt: KeyboardEvent) {
        this.affectNeighbors = false;
        if (evt.key === '+' || evt.key === '=') {
            this.effectDistance += 0.2;
            this.liveObjectsProvider.setEffectCircleVisibility(true);
            this.liveObjectsProvider.updateEffectCircle(this.effectDistance);
        } else if (evt.key === '-') {
            this.effectDistance -= 0.2;
            this.liveObjectsProvider.setEffectCircleVisibility(true);
            this.liveObjectsProvider.updateEffectCircle(this.effectDistance);
        }
        this.state?.handleKeyUp(evt);
    }
}
