import { BillingDetailsTable } from './BillingDetails/components/BillingDetailsTable';
import { useBillingDetailsContext } from './BillingDetails/providers/BillingDetailsProvider.graphql';
import type { BillingCreditCategories } from './CreditCategories/BillingCreditCategoriesQuery.graphql';
import type { Credit } from './InvoicesTable/InvoiceTable.types';
import { CreateBillingCreditAction } from './actions/CreateBillingCredit.graphql';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { MUITableColumn } from '@orthly/mui-table';
import { Text, styled } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import { uniq, compact, keyBy } from 'lodash';
import React from 'react';

export const ListCreditCreateUsers_Query = graphql(`
    query ListUsers($filter: ListUsersFilter) {
        listUsers(filter: $filter) {
            id
            first_name
            last_name
        }
    }
`);

interface PartnerCreditsTableProps {
    practiceId: string;
    practiceName: string;
    credits: Credit[];
    onRefresh: () => Promise<unknown>;
    refetchBillingDetails: () => Promise<unknown>;
}

interface PartnerCreditsTableRow extends Credit {
    created_by: string;
    category_name: string;
}

export function useBillingCreditRows(credits: Credit[], creditCategories: BillingCreditCategories) {
    const opsUserIds = React.useMemo(() => uniq(compact(credits.map(c => c.created_by_user_id))), [credits]);
    const { data: { listUsers: users = [] } = {} } = useQuery(ListCreditCreateUsers_Query, {
        variables: { filter: { ids: opsUserIds } },
        skip: opsUserIds.length === 0,
    });

    return React.useMemo<PartnerCreditsTableRow[]>(() => {
        const usersById = keyBy(users, u => u.id);
        const creditCategoriesById = keyBy(creditCategories, c => c.id);
        return credits.map<PartnerCreditsTableRow>(credit => {
            const user = credit.created_by_user_id ? usersById[credit.created_by_user_id] : undefined;
            const created_by = user ? `${user.first_name} ${user.last_name}` : 'Manual (Unknown User)';

            const category = credit.credit_category_id ? creditCategoriesById[credit.credit_category_id] : undefined;
            const category_name = category ? category.name : 'Unknown';
            return { ...credit, created_by, category_name };
        });
    }, [credits, users, creditCategories]);
}

const DescriptionText = styled(Text)({
    textAlign: 'right',
});

export const PartnerCreditsTable: React.FC<PartnerCreditsTableProps> = () => {
    const { practice, credits, refetchCredits, creditCategories } = useBillingDetailsContext();

    const unsortedRows = useBillingCreditRows(credits, creditCategories);

    const columns: MUITableColumn<PartnerCreditsTableRow>[] = [
        {
            name: 'Month',
            sort: true,
            width: '20%',
            render: c => (
                <Text variant={'caption'} medium color={'DARK_GRAY'}>
                    {dayjs(c.created_at).format('MMM YYYY')}
                </Text>
            ),
        },
        {
            name: 'Created By',
            sort: false,
            render: c => (
                <Text variant={'caption'} medium color={'DARK_GRAY'}>
                    {c.created_by}
                </Text>
            ),
        },
        {
            name: 'Category',
            render: c => (
                <Text variant={'caption'} medium color={'DARK_GRAY'}>
                    {c.category_name}
                </Text>
            ),
        },
        {
            name: 'Notes',
            type: 'date',
            width: '55%',
            style: { textAlign: 'right' },
            render: c => (
                <DescriptionText variant={'caption'} color={'DARK_GRAY'}>
                    {c.description}
                </DescriptionText>
            ),
        },
    ];

    if (!practice?.id || !practice?.name) {
        return null;
    }

    return (
        <BillingDetailsTable<PartnerCreditsTableRow>
            title={'Credits'}
            rows={unsortedRows}
            columns={columns}
            search={true}
            searchPlaceholder={'Find a credit'}
            handleSort={(a, b, sortDirection) => {
                if (sortDirection === 'asc') {
                    return dayjs(a.created_at).valueOf() - dayjs(b.created_at).valueOf();
                }

                return dayjs(b.created_at).valueOf() - dayjs(a.created_at).valueOf();
            }}
            handleFilter={(r, searchTerm) => r.description?.includes(searchTerm) ?? false}
            actions={[
                {
                    name: 'refresh',
                    label: 'Refresh',
                    onClick: refetchCredits,
                },
                {
                    name: 'issue-credit',
                    label: 'Issue Credit',
                    CustomRender: () => (
                        <CreateBillingCreditAction practiceId={practice.id} refetchCredits={refetchCredits} />
                    ),
                },
            ]}
        />
    );
};
