import {
    useActionCategories,
    useActionTypeDefaultDates,
    useActionTypes,
} from '../../../../../../utils/useTicketActionHooks';
import { QuickForm, SimpleDateTimePicker } from '@orthly/ui';
import { Button, Grid } from '@orthly/ui-primitives';
import type { FormikProps } from 'formik';
import React from 'react';
import { z } from 'zod';

interface CreateActionFormData {
    category_id: string;
    subcategory_id: string;
    type_id: string;
    notes: string;
    enable_followup_action: boolean;
    cancel_pending: boolean;
    activate_at?: string | null;
    due_at?: string | null;
}

// this is some hacky stuff to enabled the custom component wrapping
// the actual date fields, arranged in columns
interface CreateActionCustomFormData extends CreateActionFormData {
    _dates: any;
}

interface CreateActionFormProps {
    orderId: string;
    onBack?: () => void;
    onSubmit: (data: Omit<CreateActionFormData, 'category_id' | 'subcategory_id'>) => void;
    showCancelPendingOption?: boolean;
}

export const CreateActionForm: React.VFC<CreateActionFormProps> = props => {
    const { orderId, onBack, onSubmit, showCancelPendingOption } = props;
    const formRef = React.useRef<FormikProps<Partial<CreateActionCustomFormData>> | undefined>();
    const [categoryId, setCategoryId] = React.useState('');
    const [subcategoryId, setSubcategoryId] = React.useState<string | undefined>(undefined);
    const [actionTypeId, setActionTypeId] = React.useState('');
    const { categories, subcategories } = useActionCategories(categoryId);
    const { actionTypeSelectList, actionTypesById } = useActionTypes(subcategoryId);
    const actionType = React.useMemo(() => actionTypesById[actionTypeId], [actionTypesById, actionTypeId]);
    const defaultDates = useActionTypeDefaultDates(orderId, actionTypeId);
    const [activateAt, setActivateAt] = React.useState<Date | null>(null);
    const [dueAt, setDueAt] = React.useState<Date | null>(null);

    React.useEffect(() => {
        setActivateAt(defaultDates.activate_at);
        setDueAt(defaultDates.due_at);
    }, [defaultDates]);

    return (
        <QuickForm<CreateActionCustomFormData>
            onRender={props => (formRef.current = props.formikProps)}
            fields={{
                category_id: {
                    label: 'Category',
                    type: 'autocomplete',
                    options: categories,
                    optional: true,
                },
                subcategory_id: {
                    label: 'Subcategory',
                    type: 'autocomplete',
                    options: subcategories,
                    optional: true,
                },
                type_id: {
                    label: 'Action',
                    type: 'autocomplete',
                    options: actionTypeSelectList,
                },
                notes: {
                    type: 'text',
                    label: 'Notes',
                    optional: true,
                    fieldProps: { multiline: true, minRows: 3, maxRows: 100 },
                },
                _dates: {
                    type: 'custom',
                    validation: z.any(),
                    component: () => (
                        <Grid container>
                            <Grid item style={{ flex: 1, marginRight: 4 }}>
                                <SimpleDateTimePicker
                                    label={'Activate At'}
                                    value={activateAt}
                                    onChange={setActivateAt}
                                    fullWidth={true}
                                />
                            </Grid>
                            <Grid item style={{ flex: 1, marginLeft: 4 }}>
                                <SimpleDateTimePicker
                                    label={'Due At'}
                                    value={dueAt}
                                    onChange={setDueAt}
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                    ),
                    hidden: !actionType?.time_constraint_config,
                },
                enable_followup_action: {
                    type: 'boolean',
                    label: 'Assign follow-up action to me when this action is completed',
                },
                cancel_pending: {
                    type: 'boolean',
                    label: 'Cancel existing pending action items',
                    hidden: !showCancelPendingOption,
                },
            }}
            initialValues={{
                category_id: '',
                subcategory_id: '',
                type_id: '',
                notes: '',
                activate_at: null,
                due_at: null,
                enable_followup_action: false,
                cancel_pending: false,
            }}
            onChange={(values, form) => {
                if (categoryId !== values.category_id) {
                    setCategoryId(values.category_id ?? '');
                    // if cat is empty, we'll set subcat to undefined so it
                    // propagates to useActionTypes to return a full list prefixed w/ cat names
                    setSubcategoryId(values.category_id ? '' : undefined);
                    form.setFieldValue('subcategory_id', '');
                    form.setFieldValue('type_id', '');
                }
                if ((subcategoryId ?? '') !== values.subcategory_id) {
                    setSubcategoryId(values.subcategory_id ?? '');
                    form.setFieldValue('type_id', '');
                }
                if (actionTypeId !== values.type_id) {
                    setActionTypeId(values.type_id ?? '');
                }
            }}
            onSubmit={result =>
                onSubmit({
                    type_id: result.type_id,
                    notes: result.notes,
                    enable_followup_action: result.enable_followup_action,
                    cancel_pending: result.cancel_pending,
                    activate_at: activateAt?.toISOString(),
                    due_at: dueAt?.toISOString(),
                })
            }
            CustomSubmit={submitProps => {
                return (
                    <Grid container style={{ marginTop: 16 }}>
                        {onBack && (
                            <Button style={{ flex: 1, marginRight: 8 }} variant={'secondary'} onClick={onBack}>
                                Back
                            </Button>
                        )}
                        <Button
                            style={{ flex: 1 }}
                            variant={'primary'}
                            onClick={submitProps.triggerSubmit}
                            disabled={submitProps.disabled}
                        >
                            Create
                        </Button>
                    </Grid>
                );
            }}
        />
    );
};
