import { TOOLBAR_HEIGHT_PX } from './Constants';
import { FlossPalette, IconToggleButton, styled } from '@orthly/ui-primitives';
import React from 'react';

const StyledButton = styled(IconToggleButton)({
    color: FlossPalette.BLACK,
    backgroundColor: FlossPalette.TAN,
    height: TOOLBAR_HEIGHT_PX,
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    borderRadius: 0.5 * TOOLBAR_HEIGHT_PX,
    '&:hover': {
        background: `${FlossPalette.TAN} !important`,
    },
});

interface IndividualButtonProps {
    className?: string;
    tooltip: string;
    onClick: () => void;
}

export const IndividualButton: React.FC<IndividualButtonProps> = ({ className, onClick, tooltip, children }) => {
    return (
        <StyledButton className={className} active={false} onClick={onClick} tooltip={tooltip}>
            {children}
        </StyledButton>
    );
};
