import { EditIconButton } from './EditIconButton';
import { FlossPalette, stylesFactory, TextField } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory<{ noLabel: boolean; hasError: boolean; hasWarning: boolean }>(() => ({
    inputRoot: ({ noLabel }) => ({
        paddingTop: noLabel ? 6.5 : undefined,
        paddingBottom: noLabel ? 17.5 : undefined,
        '& input': {
            '-webkit-text-fill-color': `${FlossPalette.BLACK} !important`,
        },
    }),
    inputLabelRoot: ({ hasError }) => ({
        color: !hasError ? `${FlossPalette.GRAY} !important` : undefined,
    }),
    helperTextRoot: ({ hasWarning }) => ({
        color: hasWarning ? `${FlossPalette.ATTENTION_FOREGROUND} !important` : undefined,
    }),
}));

interface EditIconButtonTextFieldProps {
    value: string;
    onClick: () => void;
    label?: string;
    errorText?: string;
    hideButton?: boolean;
    warningText?: string;
}

export const EditIconButtonTextField: React.VFC<EditIconButtonTextFieldProps> = ({
    value,
    onClick,
    label,
    errorText,
    hideButton = false,
    warningText,
}) => {
    const classes = useStyles({ noLabel: !label, hasError: !!errorText, hasWarning: !!warningText });

    return (
        <TextField
            variant={'standard'}
            fullWidth
            disabled
            label={label}
            hiddenLabel={!label}
            value={value}
            error={!!errorText}
            helperText={errorText ?? warningText}
            InputProps={{
                classes: { root: classes.inputRoot },
                endAdornment: !hideButton ? (
                    <EditIconButton
                        variant={'small'}
                        onClick={() => onClick()}
                        styles={{ backgroundColor: FlossPalette.WHITE, marginRight: 12 }}
                    />
                ) : undefined,
            }}
            InputLabelProps={{
                classes: { root: classes.inputLabelRoot },
            }}
            FormHelperTextProps={{
                classes: { root: classes.helperTextRoot },
            }}
        />
    );
};
