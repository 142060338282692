/* eslint-disable max-lines, max-lines-per-function */
import { GetInvoicesForOrganization_Query } from '../../../BillingDetails/BillingOverviewQueries.graphql';
import type { BillingCreditCategories } from '../../../CreditCategories/BillingCreditCategoriesQuery.graphql';
import { GetAllBillingCreditCategories_Query } from '../../../CreditCategories/BillingCreditCategoriesQuery.graphql';
import type { Credit, Invoice } from '../../../InvoicesTable/InvoiceTable.types';
import { useBillingCreditRows } from '../../../PartnerCreditsTable.graphql';
import type { RefundCategories } from '../../../RefundCategories/RefundCategoriesQuery.graphql';
import { ListRefundCategories_Query } from '../../../RefundCategories/RefundCategoriesQuery.graphql';
import type { LedgerRow } from '../../AdminLabsEditInvoicingLineItems/AdminLabsEditInvoicingLineItems.types';
import { convertGetOrderLedgerQueryToTableData } from '../../AdminLabsEditInvoicingLineItems/AdminLabsEditInvoicingLineItems.utils';
import type {
    CreditOrRefundFormContextProps,
    CreditOrRefundFormCtxProviderProps,
    CreditWithUser,
    FormState,
    FormStateAction,
    GeneratedInvoiceItem,
    InvoiceDropdownOption,
    PartialBasicInvoice,
} from '../CreditOrRefund.types';
import { NEXT_INVOICE_VALUE, OTHER_CREDIT_CATEGORY_ID, OTHER_REFUND_CATEGORY_ID } from '../CreditOrRefund.types';
import { useMutation, useQuery } from '@apollo/client';
import type {
    CreateAttributedInvoiceCreditsMutationVariables,
    CreateInvoiceCreditMutationVariables,
} from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrder } from '@orthly/graphql-react';
import type {
    LabsGqlMutationIssueAttributedRefundsArgs,
    LabsGqlOrderItemAdjustmentAttributionInput,
    LabsGqlRefundOrderItemAttributionInput,
} from '@orthly/graphql-schema';
import { LabsGqlInvoiceAdjustmentAttributionType, LabsGqlStripeInvoiceStatus } from '@orthly/graphql-schema';
import { dayjsExt as dayjs, Format } from '@orthly/runtime-utils';
import { LoadBlocker, useRootActionCommand } from '@orthly/ui';
import { sortBy } from 'lodash';
import React from 'react';

const OrderLedger_Query = graphql(`
    query OrderLedger($orderId: String!) {
        getOrderLedger(orderId: $orderId) {
            billedItems {
                id
                category
                amount_cents
                invoice_id
                description
                created_at
            }
            pendingItem {
                amount_cents
                description
                created_at
            }
            paidInvoiceIds
        }
    }
`);

const CreateInvoiceCredit_Mutation = graphql(`
    mutation CreateInvoiceCredit($data: CreateInvoiceCreditInput!) {
        createInvoiceCredit(data: $data) {
            id
        }
    }
`);

const CreateAttributedInvoiceCredits_Mutation = graphql(`
    mutation CreateAttributedInvoiceCredits($data: CreateAttributedCreditsInput!) {
        createAttributedCredits(data: $data) {
            id
        }
    }
`);

const IssueAttributedRefunds_Mutation = graphql(`
    mutation IssueAttributedRefunds($args: IssueAttributedRefundsInput!) {
        issueAttributedRefunds(args: $args) {
            id
        }
    }
`);

const GetCreditById_Query = graphql(`
    query GetCreditById($creditId: String!) {
        getCreditById(creditId: $creditId) {
            balance_cents
            amount_issued_cents
            created_at
            created_by_user_id
            credit_category_id
            description
            expires
            id
            organization_id
            deleted_at
            attribution {
                ... on InvoiceAdjustmentAttributionBase {
                    type
                }
                ... on OrderAdjustmentAttribution {
                    order_id
                }
                ... on OrderItemAdjustmentAttribution {
                    order_id
                    order_item_id
                }
                ... on InvoiceItemAdjustmentAttribution {
                    invoice_id
                    invoice_item_id
                }
            }
        }
    }
`);

/**
 * There are two different types of ways to create credits, either a regular ("un-attributed") credit,
 * or an "attributed" credit. They are both used/applied in the same way -- they are credits with a reason tied
 * to them about _why_ something was credited, and they eventually get applied to an invoice, and the credit reduces
 * the invoice amount due. The difference is that attributed credits give visibility into _what_ was credited.
 */

const submitCredit = async (
    formState: FormState,
    organizationId: string,
    submitInvoiceCredit: (data: CreateInvoiceCreditMutationVariables) => Promise<unknown>,
) => {
    const amountCents = Math.round((parseFloat(formState.amountDollars) || 0) * 100);
    const expirationTime = formState.expires ? formState.expires.toJSON() : null;

    await submitInvoiceCredit({
        data: {
            amount_issued_cents: amountCents,
            credit_category_id: formState.category,
            description: formState.description,
            expires: expirationTime,
            organization_id: organizationId,
            existing_invoice_id: formState.existingInvoiceId,
        },
    });
};

/**
 * An attributed credit can have three different flavors --
 * 1. The credit is tied to an entire order, so we associate the credit with an orderId.
 * 2. The credit is tied to a particular item in an order, so we associate the credit with an orderId and an orderItemId.
 * 3. The credit is tied to a particular invoice item, so we associate the credit with an invoiceId and an invoiceItemId.
 */
const submitAttributedCredit = async (
    formState: FormState,
    organizationId: string,
    submitInvoiceAttributedCredits: (data: CreateAttributedInvoiceCreditsMutationVariables) => Promise<unknown>,
    invoiceItem?: GeneratedInvoiceItem,
    order?: LabsGqlOrder,
) => {
    const expirationTime = formState.expires ? formState.expires.toJSON() : null;
    const numberOfItemsWithAmountDue = order?.items_v2.filter(item => {
        const itemPrice = item.pricing.override_amount_due_cents ?? item.pricing.amount_due_cents ?? 0;
        return itemPrice > 0;
    }).length;

    await submitInvoiceAttributedCredits({
        data: {
            description: formState.description,
            organization_id: organizationId,
            existing_invoice_id: formState.existingInvoiceId,
            credit_category_id: formState.category,
            expires: expirationTime,
            order_attributions:
                order && formState.selectedItems.length === numberOfItemsWithAmountDue
                    ? [
                          {
                              type: LabsGqlInvoiceAdjustmentAttributionType.Order,
                              order_id: order.id,
                              amount_cents: order.dentist_amount_due_cents ?? 0,
                          },
                      ]
                    : [],
            order_item_attributions:
                order && formState.selectedItems.length !== numberOfItemsWithAmountDue
                    ? formState.selectedItems.map<LabsGqlOrderItemAdjustmentAttributionInput>(item => ({
                          type: LabsGqlInvoiceAdjustmentAttributionType.OrderItem,
                          order_id: order.id,
                          order_item_id: item.value,
                          amount_cents: item.price,
                      }))
                    : [],
            invoice_item_attributions: invoiceItem
                ? [
                      {
                          type: LabsGqlInvoiceAdjustmentAttributionType.InvoiceItem,
                          invoice_id: invoiceItem.invoice_id,
                          invoice_item_id: invoiceItem.id,
                          amount_cents: invoiceItem.amount_cents,
                      },
                  ]
                : [],
        },
    });
};

/**
 * Refunds are similar, except from this credits/refunds modal you can only create "attributed" refunds.
 * "Attributed" refunds have the same three flavors as "attributed" credits, except refunds always tie back
 * to an invoice item, so in addition to the metadata needed for each attribution for credits, refunds include
 * the invoiceId and invoiceItemId as well. "Un-attributed" refunds are made by refunding an entire payment
 *  towards an invoice, which is performed elsewhere.
 */
const submitAttributedInvoiceItemRefund = async (
    formState: FormState,
    paidLedgerItems: LedgerRow[],
    submitAttributedItemRefund: (data: LabsGqlMutationIssueAttributedRefundsArgs) => Promise<unknown>,
    invoiceItem?: GeneratedInvoiceItem,
    order?: LabsGqlOrder,
) => {
    const numberOfItemsWithAmountDue = order?.items_v2.filter(item => {
        const itemPrice = item.pricing.override_amount_due_cents ?? item.pricing.amount_due_cents ?? 0;
        return itemPrice > 0;
    }).length;

    // Currently only allowing refunds for an order invoice item if that order only appears on a single paid invoice
    const orderLedger = paidLedgerItems[0];

    await submitAttributedItemRefund({
        args: {
            refund_reason: formState.description,
            refund_category_id: formState.category,
            order_attributions:
                order && orderLedger && formState.selectedItems.length === numberOfItemsWithAmountDue
                    ? [
                          {
                              type: LabsGqlInvoiceAdjustmentAttributionType.Order,
                              order_id: order.id,
                              amount_cents: order.dentist_amount_due_cents ?? 0,
                              invoice_id: orderLedger.invoice_id,
                              invoice_item_id: orderLedger.id,
                          },
                      ]
                    : [],
            order_item_attributions:
                order && orderLedger && formState.selectedItems.length !== numberOfItemsWithAmountDue
                    ? formState.selectedItems.map<LabsGqlRefundOrderItemAttributionInput>(item => ({
                          type: LabsGqlInvoiceAdjustmentAttributionType.OrderItem,
                          order_id: order.id,
                          order_item_id: item.value,
                          amount_cents: item.price,
                          invoice_id: orderLedger.invoice_id,
                          invoice_item_id: orderLedger.id,
                      }))
                    : [],
            invoice_item_attributions: invoiceItem
                ? [
                      {
                          type: LabsGqlInvoiceAdjustmentAttributionType.InvoiceItem,
                          invoice_id: invoiceItem.invoice_id,
                          invoice_item_id: invoiceItem.id,
                          amount_cents: invoiceItem.amount_cents,
                      },
                  ]
                : [],
        },
    });
};

const CreditOrRefundFormContext = React.createContext<CreditOrRefundFormContextProps | undefined>(undefined);

export const useCreditOrRefundFormContext = () => {
    const context = React.useContext(CreditOrRefundFormContext);
    if (!context) {
        throw new Error('useFormContext must be used within a FormProvider');
    }
    return context;
};

export const CreditOrRefundFormCtxProvider: React.FC<CreditOrRefundFormCtxProviderProps> = ({
    setOpen,
    organizationId,
    children,
    order,
    invoiceItem,
    existingCreditId,
    refetchItems,
    refetchCredits,
}) => {
    const [currOrder, setCurrOrder] = React.useState<LabsGqlOrder | undefined>(order);
    const [existingCredit, setExistingCredit] = React.useState<Credit | undefined>();
    const { data: { getAllBillingCreditCategories: creditCategories = [] } = {}, loading: loadingCreditCategories } =
        useQuery<{
            getAllBillingCreditCategories: BillingCreditCategories;
        }>(GetAllBillingCreditCategories_Query, {
            variables: { include_archived: false },
        });

    const { data: { listRefundCategories: refundCategories = [] } = {}, loading: loadingRefundCategories } = useQuery<{
        listRefundCategories: RefundCategories;
    }>(ListRefundCategories_Query, {
        variables: { includeArchived: false },
    });

    const {
        data: { invoices = [] } = {},
        refetch: refetchInvoices,
        loading: loadingInvoices,
    } = useQuery<{
        invoices: Invoice[];
    }>(GetInvoicesForOrganization_Query, {
        variables: { organizationId },
    });

    const existingCreditOrderId =
        existingCredit?.attribution?.__typename === 'OrderAdjustmentAttribution' ||
        existingCredit?.attribution?.__typename === 'OrderItemAdjustmentAttribution'
            ? existingCredit.attribution.order_id
            : '';

    const { loading: loadingExistingCreditOrder } = useOrder(existingCreditOrderId, {
        skip: !existingCreditOrderId,
        onCompleted: ({ lab_order }) => {
            setCurrOrder(lab_order);
        },
    });

    const { data: rawLedgerData, loading: loadingRawLedgerData } = useQuery(OrderLedger_Query, {
        variables: { orderId: currOrder?.id ?? '' },
        skip: !currOrder,
    });

    const ledgerData = React.useMemo(() => {
        return rawLedgerData ? convertGetOrderLedgerQueryToTableData(rawLedgerData.getOrderLedger) : [];
    }, [rawLedgerData]);

    const paidLedgerItems = ledgerData.filter(ledger => ledger.type === 'item' && ledger.paid);

    const existingCreditWithUserName: CreditWithUser | undefined = useBillingCreditRows(
        existingCredit ? [existingCredit] : [],
        creditCategories,
    )[0];

    const initialAmountDollars = invoiceItem ? `${invoiceItem.amount_cents / 100}` : '';

    const initialFormState: FormState = {
        applyOn: NEXT_INVOICE_VALUE,
        existingInvoiceId: undefined,
        action: 'credit',
        attributed: order || invoiceItem ? true : false,
        selectedItems: [],
        category: '',
        description: undefined,
        amountDollars: initialAmountDollars,
        expires: null,
        submitted: false,
    };

    const [formState, dispatch] = React.useReducer((state: FormState, action: FormStateAction): FormState => {
        switch (action.type) {
            case 'SET_AMOUNT_DOLLARS': {
                const { amountDollars } = action;
                return { ...state, amountDollars };
            }
            case 'SET_EXPIRATION_DATE': {
                const { expires } = action;
                return { ...state, expires };
            }
            case 'SET_CATEGORY': {
                const { category } = action;
                return { ...state, category };
            }
            case 'SET_DESCRIPTION': {
                const { description } = action;
                return { ...state, description };
            }
            case 'SET_APPLY_ON': {
                const { applyOn } = action;
                return {
                    ...state,
                    existingInvoiceId: applyOn === NEXT_INVOICE_VALUE ? undefined : state.existingInvoiceId,
                    applyOn,
                };
            }
            case 'SET_EXISTING_INVOICE': {
                const { existingInvoiceId } = action;
                return { ...state, existingInvoiceId };
            }
            case 'SET_SELECTED_ITEMS': {
                const { amountDollars, selectedItems } = action;
                return { ...state, amountDollars, selectedItems };
            }
            case 'SET_ACTION': {
                const { actionType } = action;
                return actionType === 'credit'
                    ? { ...state, action: 'credit', applyOn: NEXT_INVOICE_VALUE }
                    : { ...state, action: 'refund', applyOn: undefined, category: '' };
            }
            case 'SET_VIEW_STATE': {
                const { description, category, amountDollars, expires } = action.payload;
                return { ...state, description, category, amountDollars, expires };
            }
            case 'SUBMIT': {
                return { ...state, submitted: true };
            }
        }
    }, initialFormState);

    const { loading: loadingCredit } = useQuery(GetCreditById_Query, {
        variables: { creditId: existingCreditId ?? '' },
        skip: !existingCreditId,
        onCompleted: data => {
            const { getCreditById: retrievedCredit } = data;
            setExistingCredit(retrievedCredit);
            dispatch({
                type: 'SET_VIEW_STATE',
                payload: {
                    category: retrievedCredit.credit_category_id ?? '',
                    description: retrievedCredit.description ?? undefined,
                    amountDollars: `${retrievedCredit.amount_issued_cents / 100}`,
                    expires: retrievedCredit.expires ? new Date(retrievedCredit.expires) : null,
                },
            });
        },
    });

    const creditCategoryOptions = React.useMemo(
        () =>
            sortBy(
                creditCategories.map(category => ({
                    label: category.name,
                    value: category.id,
                })) ?? [],
                category => category.label,
            ),
        [creditCategories],
    );

    const refundCategoryOptions = React.useMemo(
        () =>
            sortBy(
                refundCategories?.map(category => ({
                    label: category.name,
                    value: category.id,
                })) ?? [],
                category => category.label,
            ),
        [refundCategories],
    );

    const convertToBasicInvoice = (invoice: Invoice): PartialBasicInvoice => ({
        amount_remaining: invoice.amount_remaining,
        id: invoice.id,
        period_start: invoice.period_start,
        status: invoice.status,
        hasPendingPayment: invoice.payments.filter(p => p.status === 'pending').length > 0,
    });

    const isInvoiceEligibleForCredit = (invoice: PartialBasicInvoice): boolean =>
        invoice.status === LabsGqlStripeInvoiceStatus.Open;

    const convertInvoiceToOption = (invoice: PartialBasicInvoice) => ({
        value: invoice.id,
        label: `Orders ${dayjs.utc(invoice.period_start).format('MMM YYYY')}. ${Format.currency(
            invoice.amount_remaining,
        )} remaining${invoice.hasPendingPayment ? ' - has pending payment' : ''}`,
        start: dayjs.utc(invoice.period_start),
        disabled: invoice.hasPendingPayment,
    });

    const invoiceOptionsForCredit: InvoiceDropdownOption[] = invoices
        ?.map(convertToBasicInvoice)
        ?.filter(isInvoiceEligibleForCredit)
        .map(convertInvoiceToOption);

    /**
     * An invoice item (unmet min, cc fee, etc.) is eligible for a refund as long as the invoice it lives on is paid.
     * However, for order items, it's a bit tricker since orders can be modified after they have been originally invoiced,
     * meaning that a single order can appear on multiple invoices. As a simplest approach for now, orders are only
     * eligible to be refunded if they only appear on a single invoice and that invoice is paid.
     */
    const eligibleForRefund = React.useMemo(() => {
        if (invoiceItem) {
            return invoices.find(i => i.id === invoiceItem.invoice_id)?.status === LabsGqlStripeInvoiceStatus.Paid;
        } else if (currOrder) {
            const ledgerItems = ledgerData.filter(
                ledgerItem => ledgerItem.invoice !== 'Pending' && ledgerItem.type === 'item',
            );
            return ledgerItems.length === 1 && ledgerItems.every(item => item.paid);
        } else {
            return false;
        }
    }, [invoices, invoiceItem, currOrder, ledgerData]);

    const createInvoiceCreditMtn = useMutation(CreateInvoiceCredit_Mutation);
    const createAttributedCreditsMtn = useMutation(CreateAttributedInvoiceCredits_Mutation);
    const issueAttributedItemRefund = useMutation(IssueAttributedRefunds_Mutation);

    const { submit: submitInvoiceCredit, submitting: submittingInvoiceCredit } = useRootActionCommand(
        createInvoiceCreditMtn,
        {
            onSuccess: async () => {
                await refetchCredits?.();
                await refetchInvoices();
                await refetchItems?.();
                setTimeout(() => {
                    setOpen(false);
                }, 2000);
            },
        },
    );
    const { submit: submitInvoiceAttributedCredits, submitting: submittingInvoiceAttributedCredits } =
        useRootActionCommand(createAttributedCreditsMtn, {
            onSuccess: async () => {
                await refetchCredits?.();
                await refetchInvoices();
                await refetchItems?.();
                setTimeout(() => {
                    setOpen(false);
                }, 2000);
            },
        });
    const { submit: submitAttributedItemRefund, submitting: submittingAttributedItemRefund } = useRootActionCommand(
        issueAttributedItemRefund,
        {
            onSuccess: async () => {
                await refetchInvoices();
                await refetchItems?.();
                setTimeout(() => {
                    setOpen(false);
                }, 2000);
            },
        },
    );

    const onSubmitInvoiceCredit = async (formState: FormState) =>
        submitCredit(formState, organizationId, submitInvoiceCredit);
    const onSubmitInvoiceAttributedCredit = async (
        formState: FormState,
        invoiceItem?: GeneratedInvoiceItem,
        order?: LabsGqlOrder,
    ) => submitAttributedCredit(formState, organizationId, submitInvoiceAttributedCredits, invoiceItem, order);
    const onSubmitAttributedItemRefund = async (
        formState: FormState,
        paidLedgerItems: LedgerRow[],
        invoiceItem?: GeneratedInvoiceItem,
        order?: LabsGqlOrder,
    ) => submitAttributedInvoiceItemRefund(formState, paidLedgerItems, submitAttributedItemRefund, invoiceItem, order);

    const onSubmitCredit = async () => {
        if (invoiceItem || currOrder) {
            await onSubmitInvoiceAttributedCredit(formState, invoiceItem, currOrder);
            dispatch({ type: 'SUBMIT' });
        } else {
            await onSubmitInvoiceCredit(formState);
            dispatch({ type: 'SUBMIT' });
        }
    };

    const onSubmitRefund = async () => {
        await onSubmitAttributedItemRefund(formState, paidLedgerItems, invoiceItem, currOrder);
        dispatch({ type: 'SUBMIT' });
    };

    const loading =
        loadingCreditCategories ||
        loadingRefundCategories ||
        loadingCredit ||
        loadingInvoices ||
        loadingExistingCreditOrder ||
        loadingRawLedgerData;

    const submitting = submittingInvoiceCredit || submittingInvoiceAttributedCredits || submittingAttributedItemRefund;

    const requiredInternalNoteMissing =
        (formState.category === OTHER_CREDIT_CATEGORY_ID || formState.category === OTHER_REFUND_CATEGORY_ID) &&
        !formState.description;
    const disableSubmit = !formState.amountDollars || !formState.category || requiredInternalNoteMissing || submitting;

    return (
        <CreditOrRefundFormContext.Provider
            value={{
                formState,
                dispatchFormStateAction: dispatch,
                setOpen,
                invoiceOptionsForCredit,
                creditCategoryOptions,
                refundCategoryOptions,
                existingCredit: existingCreditWithUserName,
                viewOnly: !!existingCreditWithUserName,
                order: currOrder,
                invoiceItem,
                submit: formState.action === 'credit' ? onSubmitCredit : onSubmitRefund,
                disableSubmit,
                eligibleForRefund,
            }}
        >
            <LoadBlocker blocking={loading || submitting} ContainerProps={{ height: '100%' }}>
                {children}
            </LoadBlocker>
        </CreditOrRefundFormContext.Provider>
    );
};
