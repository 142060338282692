import { useScrollToControl } from '../state/GuidedWaxupState';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import _ from 'lodash';
import React from 'react';

const trackPresetViewDuration = (orderId: string, preset: LabsGqlGuidedWaxupPresetType, duration: number) => {
    BrowserAnalyticsClientFactory.Instance?.track('Practice - Guided Waxup - Preset Viewed', {
        $groups: { order: orderId },
        preset: _.startCase(preset),
        duration: duration,
    });
};

export const useTabSelection = ({ orderId }: { orderId: string }) => {
    const { go_to_top } = useScrollToControl();
    const viewDurationStartRef = React.useRef(Date.now());
    const [selectedTab, setRawSelectedTab] = React.useState<LabsGqlGuidedWaxupPresetType>();
    const setSelectedTab = React.useMemo(
        () => (newTab: LabsGqlGuidedWaxupPresetType) => {
            go_to_top();
            setRawSelectedTab(newTab);
        },
        [go_to_top, setRawSelectedTab],
    );

    React.useEffect(() => {
        return () => {
            if (selectedTab) {
                // track duration the user was viewing the previous preset
                const duration = Date.now() - viewDurationStartRef.current;
                viewDurationStartRef.current = Date.now();
                trackPresetViewDuration(orderId, selectedTab, duration);
            }
        };
    }, [orderId, selectedTab]);

    return [selectedTab, setSelectedTab] as const;
};
