import { Text } from '@orthly/ui-primitives';
import React from 'react';

export const OrderPriceCalculatedPrice: React.FC<{ price: number }> = ({ price }) => {
    return (
        <Text variant={'body2'} style={{ margin: '8px 0px' }}>
            Item calculated price: {`$${(price as any) / 100}`}
        </Text>
    );
};
