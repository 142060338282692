import type { FutureOrExisting } from './CancelOrderToolbarAction.graphql';
import type { PreviewCreditAndRefundOptionsForOrderCancellationQuery } from '@orthly/graphql-inline-react';
import { Format } from '@orthly/runtime-utils';
import { Text } from '@orthly/ui-primitives';
import React from 'react';

interface FooterCaptionProps {
    hasOrderBeenPaid: boolean;
    hasOrderBeenInvoiced: boolean;
    creditFutureOrExistingInvoice: FutureOrExisting;
    cancellationOptions:
        | PreviewCreditAndRefundOptionsForOrderCancellationQuery['previewCreditAndRefundOptionsForOrderCancellation']
        | undefined;
}

export const FooterCaption: React.FC<FooterCaptionProps> = ({
    hasOrderBeenPaid,
    hasOrderBeenInvoiced,
    cancellationOptions,
}: FooterCaptionProps) => {
    if (hasOrderBeenPaid) {
        return (
            <>
                <Text variant={'caption'} color={'LIGHT_GRAY'}>
                    {'Since this order has been paid, you can choose to apply a credit or a refund for '}
                </Text>
                <Text variant={'caption'} color={'GREEN'}>
                    {Format.currency(cancellationOptions?.refundExistingInvoiceOption?.amountCents || 0)}
                </Text>
            </>
        );
    }

    if (hasOrderBeenInvoiced) {
        return (
            <>
                <Text variant={'caption'} color={'LIGHT_GRAY'}>
                    {'Since this order has been invoiced, you can choose to apply a credit for '}
                </Text>
                <Text variant={'caption'} color={'GREEN'}>
                    {Format.currency(cancellationOptions?.creditExistingInvoiceOption?.amountCents || 0)}
                </Text>
            </>
        );
    }

    return (
        <>
            <Text variant={'caption'} color={'LIGHT_GRAY'}>
                This order has not been invoiced.
            </Text>
        </>
    );
};
