import { styled } from '@mui/material';
import type { SwitchProps } from '@mui/material/Switch';
import Switch from '@mui/material/Switch';
import React from 'react';

export const IosSwitch: React.FC<SwitchProps> = styled(props => (
    <Switch focusVisibleClassName={'.Mui-focusVisible'} disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: '0 0 0 1px',

        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(4px) !important',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? 'white' : 'black',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: 'black !important',
            border: '6px solid #fff !important',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: '22px !important',
        height: '22px !important',
        borderRadius: '13px !important',
    },
    '& .MuiSwitch-track': {
        borderRadius: '13px !important',
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));
