import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import type { LabsGqlScanToothShadeInput } from '@orthly/graphql-schema';
import {
    systemForShade,
    ToothUtils,
    TeethShades,
    skuRequiresStumpShade,
    materialHasNoStumpShade,
    CartItemV2Utils,
    OrderItemV2Utils,
} from '@orthly/items';
import type { ToothNumber, TeethShade } from '@orthly/items';
import { SimpleSelect } from '@orthly/ui';
import { Text, Grid, makeStyles, createStyles } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const useDesignTaskStyles = makeStyles(() =>
    createStyles({
        switchButton: {
            margin: '12px 12px 0px 12px',
        },
        fieldLabel: {
            fontWeight: 500,
            paddingBottom: 8,
        },
    }),
);

// We require internal designers to label orders with the shade from the scan file so that we can fix
// them up automatically, and save the Ops folks some time

export const useTeethNeedingStumpShade = (order: LabsGqlLabOrderFragment): ToothNumber[] => {
    return React.useMemo(() => {
        if (!order.fulfillment_workflow.configuration.internal_design_required) {
            return [];
        }

        return _.chain(OrderItemV2Utils.parseItems(order.items_v2))
            .flatMap((item): ToothNumber[] => {
                if (!skuRequiresStumpShade(item.sku)) {
                    return [];
                }

                // already has a valid shade
                const stumpShade = item.shades?.find(shade => shade.name === 'stump');
                if (stumpShade && systemForShade(stumpShade.value) === 'IPS Natural Die') {
                    return [];
                }
                const materials = CartItemV2Utils.getAllMaterials(item);
                if (materials.some(material => material && materialHasNoStumpShade(material))) {
                    return [];
                }
                return CartItemV2Utils.getUniqueUNNs(item).filter(ToothUtils.toothIsAnterior);
            })
            .uniq()
            .sortBy()
            .value();
    }, [order]);
};

const ScanShadeKinds = TeethShades.nd.map(value => ({
    value,
    label: `${value} (IPS Natural Die)`,
}));

export const DesignTaskStumpShadeField: React.VFC<{
    shadeTeeth: ToothNumber[];
    stumpShades?: LabsGqlScanToothShadeInput[];
    updateScanToothField: (tooth: ToothNumber, shade: TeethShade) => void;
}> = props => {
    const { shadeTeeth, stumpShades, updateScanToothField } = props;

    const classes = useDesignTaskStyles();

    if (shadeTeeth.length === 0) {
        return null;
    }

    return (
        <Grid container item direction={'column'}>
            <Text variant={'body2'} className={classes.fieldLabel}>
                Add missing stump shades *
            </Text>
            {shadeTeeth.map(tooth => {
                const shade = stumpShades?.find(shade => shade.tooth === tooth);

                return (
                    <Grid
                        key={`${tooth}`}
                        container
                        item
                        direction={'row'}
                        wrap={'nowrap'}
                        alignItems={'center'}
                        style={{ marginBottom: 8 }}
                    >
                        <Text variant={'body2'} style={{ whiteSpace: 'nowrap', marginRight: 8 }}>
                            Tooth {tooth}
                        </Text>
                        <SimpleSelect
                            options={ScanShadeKinds}
                            onChange={value => updateScanToothField(tooth, value as TeethShade)}
                            value={shade?.shade}
                            label={'Stump shade'}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};
