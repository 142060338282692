import { RouterTabs } from '../../components/RouterTabs';
import { DesignAutoAssignmentRoot } from '../DesignAutoAssignment/DesignAutoAssignmentRoot';
import { ThreeshapeDmesRoot } from '../ThreeshapeDmes/ThreeshapeDmesRoot';
import { DesignReviewFieldsTable } from './DesignReviewFields/DesignReviewFieldsTable';
import { AbutmentPartRoot } from './ImplantScanbody/AbutmentPart';
import { ImplantsConfigRoot } from './ImplantScanbody/ImplantSystemsRoot';
import { ScanbodiesRoot } from './ImplantScanbody/ScanbodiesRoot';
import { QCItemConfigForm } from './QCItemConfigForm';
import { QcIssuesConfigForm } from './QcIssuesConfigForm';
import { ReasonCodesConfigForm } from './ReasonCodesConfigForm';
import { RefabReasonsConfigForm } from './RefabReasonsConfigForm';
import { TatRoot } from './Tat/TatRoot';
import { WorkflowStepDefinitionsForm } from './WorkflowStepDefinitionsForm';
import { ActionCapabilitiesList } from './components/OrderTickets/ActionCapabilities';
import { ActionCategoriesList } from './components/OrderTickets/ActionCategories';
import { ActionTypesList } from './components/OrderTickets/ActionTypes';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const TaskConfigTabs: React.VFC = () => (
    <RouterTabs
        defaultPath={'review-steps'}
        items={[
            { path: 'review-steps', label: 'Review Steps', Component: WorkflowStepDefinitionsForm },
            { path: 'design-review', label: 'Design Review Fields', Component: DesignReviewFieldsTable },
        ]}
    />
);

const OrderTicketsTabs: React.VFC = () => (
    <RouterTabs
        defaultPath={'action-types'}
        items={[
            { path: 'action-types', label: 'Action Types', Component: ActionTypesList },
            { path: 'action-categories', label: 'Action Categories', Component: ActionCategoriesList },
            { path: 'action-capabilities', label: 'Action Capabilities', Component: ActionCapabilitiesList },
        ]}
    />
);

const QCAppTabs: React.VFC = () => (
    <RouterTabs
        defaultPath={'items'}
        items={[
            { path: 'items', label: 'Items', Component: QCItemConfigForm },
            { path: 'issues', label: 'Issues', Component: QcIssuesConfigForm },
        ]}
    />
);

const ImplantConfigTab: React.VFC = () => {
    const { value: isAbutmentPartsManagementEnabled = false } = useFeatureFlag('enableAbutmentPartsAdminManagement');
    const featureFlaggedAbutmentSection = isAbutmentPartsManagementEnabled
        ? [{ path: 'abutment-parts', label: 'Abutment Parts', Component: AbutmentPartRoot }]
        : [];

    return (
        <RouterTabs
            defaultPath={'implant-systems'}
            items={[
                { path: 'implant-systems', label: 'Implant Systems', Component: ImplantsConfigRoot },
                { path: 'scanbodies', label: 'Scan Bodies', Component: ScanbodiesRoot },
                ...featureFlaggedAbutmentSection,
            ]}
        />
    );
};

export const ConfigScreenRoot: React.VFC = () => {
    const { value: tatLeadTimeConfigPageAccessEnabled = false } = useFeatureFlag('tatLeadTimeConfigPageAccess');

    return (
        <RouterTabs
            defaultPath={'tasks'}
            items={[
                { path: 'tasks', label: 'Tasks', Component: TaskConfigTabs },
                { path: 'order-tickets', label: 'Order Tickets', Component: OrderTicketsTabs },
                { path: 'implant', label: `Implant Systems & Parts`, Component: ImplantConfigTab },
                { path: 'reason-codes', label: 'Feedback & Refab Reasons', Component: ReasonCodesConfigForm },
                { path: 'refab-reasons', label: 'Refab Reasons', Component: RefabReasonsConfigForm },
                { path: 'qc', label: 'QC Station', Component: QCAppTabs },
                { path: 'design-assignment', label: 'Design Assignment', Component: DesignAutoAssignmentRoot },
                { path: 'threeshape-dmes', label: 'Threeshape DMEs', Component: ThreeshapeDmesRoot },
                ...(tatLeadTimeConfigPageAccessEnabled
                    ? [{ path: 'tat', label: 'TAT Lead Times', Component: TatRoot }]
                    : []),
            ]}
        />
    );
};
