import { getPresetTitle } from '../GuidedWaxup.util';
import { GUIDED_WAXUP_COMMON_STYLES } from '../VisualConstants.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction, useGuidedWaxupSelector, useWaxupIsRejected } from '../state/GuidedWaxupState';
import { RejectionNoteInput } from './RejectionNoteInput';
import { LabsGqlGuidedWaxupPresetStatus } from '@orthly/graphql-schema';
import { PlusIcon } from '@orthly/ui';
import { Button, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    generalReviewRejectionBtn: {
        width: '100%',
        ...GUIDED_WAXUP_COMMON_STYLES,
    },
    plusIcon: {
        marginLeft: 5,
    },
}));
export const GeneralReviewRejectionControlsDesktop: React.VFC = () => {
    const classes = useStyles();
    const { selectedTab } = useGuidedWaxupContext();
    const setPresetRejectionNotes = useGuidedWaxupAction('SET_PRESET_REJECTION_NOTES');
    const setPresetStatus = useGuidedWaxupAction('SET_PRESET_STATUS');
    const waxupRejected = useWaxupIsRejected();
    const generalRejectionNote = useGuidedWaxupSelector(s => s.presets[selectedTab]?.notes);
    const setGeneralRejectionNotes = (rejectionNotes?: string) =>
        setPresetRejectionNotes({ presetName: selectedTab, rejectionNotes });
    const generalViewRejected =
        useGuidedWaxupSelector(s => s.presets[selectedTab]?.status) === LabsGqlGuidedWaxupPresetStatus.Rejected;

    if (waxupRejected) {
        return (
            <>
                <RejectionNoteInput
                    presetName={selectedTab}
                    tabTitle={getPresetTitle(selectedTab)}
                    rejectionNote={generalRejectionNote}
                />
                {generalViewRejected && (
                    <Button
                        variant={'ghost'}
                        onClick={() => {
                            setGeneralRejectionNotes('');
                            setPresetStatus({
                                presetName: selectedTab,
                                status: LabsGqlGuidedWaxupPresetStatus.Approved,
                            });
                        }}
                    >
                        Remove rejection note
                    </Button>
                )}
            </>
        );
    }

    return (
        <Button
            variant={'alert-secondary'}
            className={classes.generalReviewRejectionBtn}
            onClick={() => {
                setPresetStatus({ presetName: selectedTab, status: LabsGqlGuidedWaxupPresetStatus.Rejected });
            }}
        >
            {'Add a rejection note '}
            <PlusIcon className={classes.plusIcon} />
        </Button>
    );
};
