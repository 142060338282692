import type { PresetInfo } from '../../state/GuidedWaxupState';
import type { VeneerUseOrderRevisionItemsDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import type { LabsGqlDesignViewPreset, RenderedCategoryTree } from '@orthly/graphql-schema';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { LabsGqlGuidedWaxupPresetStatus } from '@orthly/graphql-schema';

export type DesignRevision = Pick<
    VeneerUseOrderRevisionItemsDesign_FragmentFragment,
    'created_at' | 'doctor_review' | 'id' | 'is_latest_design'
>;
export type PresetData = Omit<LabsGqlDesignViewPreset, 'annotated_image_urls'>;

export const statusToStringMap: Record<LabsGqlGuidedWaxupPresetStatus, string> = {
    [LabsGqlGuidedWaxupPresetStatus.Approved]: 'Approved',
    [LabsGqlGuidedWaxupPresetStatus.Skipped]: 'Skipped',
    [LabsGqlGuidedWaxupPresetStatus.Rejected]: 'Rejected',
};

export const getLeafNodePaths = (categoryTree: RenderedCategoryTree[]): string[] => {
    const allPaths: string[] = [];

    const buildPaths = (categoryTree: RenderedCategoryTree[], currentPath: string[] = []) => {
        categoryTree.forEach(category => {
            const path = [...currentPath, category.label];
            if (category?.children?.length) {
                buildPaths(category?.children, path);
            } else {
                allPaths.push(path.join(' / '));
            }
        });
    };

    buildPaths(categoryTree);

    return allPaths;
};

export const formatPresets = ({
    presets,
    selectedPreset,
}: {
    presets: Partial<Record<LabsGqlGuidedWaxupPresetType, PresetInfo>>;
    selectedPreset: LabsGqlGuidedWaxupPresetType;
}) => {
    const formattedPresets: PresetData[] = [];

    for (const presetType in presets) {
        const presetData = presets[presetType as LabsGqlGuidedWaxupPresetType];
        // status is used for current the current design revision
        // and preset_status is used for already reviewed revisions
        const preset_status = presetData?.preset_status ?? presetData?.status;
        const isSelectedTab = selectedPreset === presetType;
        const isGeneralView = selectedPreset === LabsGqlGuidedWaxupPresetType.GeneralView;
        if (preset_status && (isSelectedTab || isGeneralView)) {
            formattedPresets.push({
                preset_type: presetType as LabsGqlGuidedWaxupPresetType,
                preset_status,
                structured_notes: presetData?.structured_notes?.map(category => ({
                    category,
                })),
                notes: presetData?.notes ?? '',
            });
        }
    }

    return formattedPresets;
};
