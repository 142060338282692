/* eslint-disable max-lines */
import type { InterproximalSpace, ToothNumber } from '@orthly/items';
import { AllInterproximalSpaces } from '@orthly/items';
import { Assert } from '@orthly/runtime-utils';
import { FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

interface LinearInterproximalSpaceSvgDefinition {
    Line: React.ComponentType<React.SVGProps<any>>;
    ActionWrapper: React.ComponentType<React.SVGProps<any>>;
}

const LinearInterproximalSpaceSVGs: LinearInterproximalSpaceSvgDefinition[] = [
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M76.5 48v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={60} y={12} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M116.5 48v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={100} y={12} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M156.5 48v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={140} y={12} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M196.5 48v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={180} y={12} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M236.5 48v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={220} y={12} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M276.5 48v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={260} y={12} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M316.5 48v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={300} y={12} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M356.5 48v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={340} y={12} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M396.5 48v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={380} y={12} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M436.5 48v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={420} y={12} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M476.5 48v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={460} y={12} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M516.5 48v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={500} y={12} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M556.5 48v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={540} y={12} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M596.5 48v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={580} y={12} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M636.5 48v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={620} y={12} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => (
            <path stroke={FlossPalette.DARK_TAN} d={'M636.5 118.952v60'} {...props} />
        ),
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={620} y={182} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => (
            <path stroke={FlossPalette.DARK_TAN} d={'M596.5 118.952v60'} {...props} />
        ),
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={580} y={182} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => (
            <path stroke={FlossPalette.DARK_TAN} d={'M556.5 118.952v60'} {...props} />
        ),
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={540} y={182} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => (
            <path stroke={FlossPalette.DARK_TAN} d={'M516.5 118.952v60'} {...props} />
        ),
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={500} y={182} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => (
            <path stroke={FlossPalette.DARK_TAN} d={'M476.5 118.952v60'} {...props} />
        ),
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={460} y={182} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => (
            <path stroke={FlossPalette.DARK_TAN} d={'M436.5 118.952v60'} {...props} />
        ),
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={420} y={182} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => (
            <path stroke={FlossPalette.DARK_TAN} d={'M396.5 118.952v60'} {...props} />
        ),
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={380} y={182} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => (
            <path stroke={FlossPalette.DARK_TAN} d={'M356.5 118.952v60'} {...props} />
        ),
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={340} y={182} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => (
            <path stroke={FlossPalette.DARK_TAN} d={'M316.5 118.952v60'} {...props} />
        ),
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={300} y={182} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => (
            <path stroke={FlossPalette.DARK_TAN} d={'M276.5 118.952v60'} {...props} />
        ),
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={260} y={182} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => (
            <path stroke={FlossPalette.DARK_TAN} d={'M236.5 118.952v60'} {...props} />
        ),
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={220} y={182} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => (
            <path stroke={FlossPalette.DARK_TAN} d={'M196.5 118.952v60'} {...props} />
        ),
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={180} y={182} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => (
            <path stroke={FlossPalette.DARK_TAN} d={'M156.5 118.952v60'} {...props} />
        ),
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={140} y={182} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => (
            <path stroke={FlossPalette.DARK_TAN} d={'M116.5 118.952v60'} {...props} />
        ),
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={100} y={182} {...props} />
        ),
    },
    {
        Line: (props: React.SVGProps<any>) => <path stroke={FlossPalette.DARK_TAN} d={'M76.5 118.952v60'} {...props} />,
        ActionWrapper: (props: React.SVGProps<any>) => (
            <foreignObject height={32} width={32} x={60} y={182} {...props} />
        ),
    },
];

export const getDandyLinearInterproximalSpaceSVG = (
    space: InterproximalSpace,
): LinearInterproximalSpaceSvgDefinition => {
    const ret = LinearInterproximalSpaceSVGs[AllInterproximalSpaces.indexOf(space)];
    Assert.assert(ret !== undefined, `Could not find SVG for interproximal space ${space}`);
    return ret;
};

interface LinearToothSvgDefinition {
    Tooth: React.ComponentType<React.SVGProps<any>>;
}

export const DandyLinearTeethSVGs: { [K in ToothNumber]: LinearToothSvgDefinition } = {
    1: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M51.57 102.474c2.495.227 3.856-1.587 9.753-.907.983 0 3.447-1.089 5.443-5.443 2.495-5.444-2.041-12.474-3.856-14.743-1.814-2.268-7.03-1.36-8.618 0-1.588 1.361-3.63.68-6.578 2.269-2.948 1.587-2.948 9.298-2.494 13.608.453 4.309 3.855 4.99 6.35 5.216z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={'M56.6 69v-7.608h-.684c-.216.888-.564 1.128-1.836 1.128h-.54v.768h2.016V69H56.6z'}
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    2: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M100.835 102.644c3.604.423 5.989-.439 7.404-6.667.661-5.632-2.933-11.99-4.921-14.507-1.989-2.517-8.136-.726-11.263-1.321-3.128-.595-5.088.545-7.816 4.335-2.728 3.791-.328 8.64 1.264 14.535 1.592 5.896 6.613 3.973 8.495 3.509 1.881-.465 3.233-.306 6.837.116z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M94.416 68.1l1.404-1.176c1.044-.864 2.292-1.944 2.292-3.312 0-1.284-.84-2.316-2.604-2.316-1.62 0-2.664.996-2.664 2.544 0 .228.036.468.12.708l1.02.048a1.911 1.911 0 01-.12-.684c0-1.104.6-1.74 1.644-1.74.996 0 1.536.6 1.536 1.488 0 1.104-.912 1.872-2.124 2.904L92.772 68.4v.6h5.58v-.9h-3.936z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    3: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M130.292 105.909c4.168-.387 6.851.345 9.958.671 1.873.196 3.959.275 5.463-.858 1.027-.774 2.172-2.323 3.439-5.687 2.482-6.594-1.243-14.604-5.705-17.86-4.461-3.255-10.245-2.17-14.594-1.124-4.349 1.046-6.123 4.536-6.775 9.79-.652 5.255 2.846 15.567 8.214 15.068z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M133.787 63.9c.06-1.104.72-1.764 1.74-1.764 1.044 0 1.596.564 1.596 1.38 0 .972-.804 1.284-1.62 1.284-.348 0-.552-.048-.72-.072v.948c.18-.024.408-.072.72-.072.96 0 1.74.456 1.74 1.32 0 .9-.684 1.38-1.668 1.38-1.116 0-1.92-.624-1.98-1.752l-.996.312c.168 1.428 1.308 2.28 2.964 2.28 1.668 0 2.724-.888 2.724-2.148 0-.984-.768-1.728-1.728-1.812.792-.12 1.584-.78 1.584-1.764 0-1.26-.924-2.124-2.592-2.124-1.56 0-2.58.948-2.748 2.292l.984.312z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    4: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M173.028 104.409c5.113 1.248 10.549-4.194 11.674-8.77.232-.944.164-1.929.007-2.888-.738-4.493-2.523-9.26-4.421-11.388-2.103-2.356-5.97-1.285-8.627.41-2.656 1.694-3.838 7.463-4.477 10.194-.64 2.732.179 11.059 5.844 12.442z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M179.122 61.44h-1.296l-3.708 4.536v1.008h3.996V69h1.008v-2.016h1.356v-.852h-1.356V61.44zm-1.008.9v3.792h-3.108l3.108-3.792z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    5: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M225 96.291c1.246-2.71-.468-6.288-3.139-12.944-.298-.741-.697-1.004-2.778-2.379-2.082-1.375-5.288-1.046-7.299-.472-2.01.575-4.182 7.567-5.486 11.599-1.303 4.031 1.81 11.252 6.471 12.427 4.66 1.175 10.984-5.52 12.231-8.23z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M213.972 66.744c.12 1.428 1.272 2.4 2.94 2.4 1.644 0 2.808-1.092 2.808-2.652 0-1.392-.912-2.556-2.58-2.556-.852 0-1.524.372-1.872.828.096-.396.264-2.064.288-2.52.744.06 2.292.18 2.868.18.72 0 .9-.408.84-.984h-4.488c-.036 1.152-.252 2.88-.432 3.96l1.02.18c.288-.456.84-.84 1.608-.84 1.08 0 1.728.756 1.728 1.752 0 1.104-.696 1.824-1.764 1.824-1.188 0-1.896-.72-1.932-1.788l-1.032.216z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    6: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M253.279 102.498c2.483.714 4.617.797 8.269-2.213 2.294-1.89 3.901-4.569 4.115-7.533.047-.646.057-1.305.01-1.94-.228-3.077-3.48-6.565-4.186-7.75-.707-1.185-3.079-3.635-6.355-2.94-3.276.694-6.456 6.063-8.651 11.192-2.195 5.129 3.655 10.28 6.798 11.184z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M258.833 63.132c-.264-1.14-1.212-1.836-2.556-1.836-2.124 0-3.204 1.704-3.204 4.068 0 2.316 1.068 3.78 3.084 3.78 1.68 0 2.748-1.044 2.748-2.568 0-1.452-1.02-2.496-2.592-2.496-1.272 0-2.04.696-2.292 1.584-.048-1.956.612-3.54 2.244-3.54.948 0 1.452.516 1.632 1.308l.936-.3zm-4.524 3.456c0-1.02.672-1.692 1.8-1.692 1.128 0 1.8.672 1.8 1.692s-.672 1.716-1.764 1.716c-1.116 0-1.836-.696-1.836-1.716z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    7: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M287.552 96.565c1.849 3.08 7.373 3.749 12.193 2.263 2.962-.913 4.4-3.911 4.309-7.01-.119-4.055-2.221-6.53-3.416-9.234-1.196-2.705-3.945-3.03-6.558-2.142-2.612.889-8.982 12.035-6.528 16.123z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M297.064 62.4c-1.512 1.584-2.556 3.924-2.592 6.6h1.14c.024-2.568.672-4.86 2.568-6.828v-.732h-5.364v.96h4.248z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    8: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M346.124 93.835c0-1.382-3.095-10.329-7.039-13.07-1.081-.751-2.498-.81-3.744-.383-6.939 2.374-10.726 14.773-10.31 16.855.454 2.268 3.629 4.763 9.753 5.67 6.123.908 7.484-1.134 9.526-1.814 2.041-.68 1.814-5.67 1.814-7.258z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M335.706 69.144c1.8 0 2.88-.828 2.88-2.148 0-.984-.792-1.668-1.62-1.812.816-.276 1.356-.9 1.356-1.788 0-1.308-1.116-2.1-2.616-2.1s-2.604.792-2.604 2.1c0 .888.54 1.512 1.344 1.788-.828.144-1.62.828-1.62 1.812 0 1.32 1.08 2.148 2.88 2.148zm0-4.332c-.936 0-1.572-.48-1.572-1.344 0-.936.636-1.404 1.572-1.404s1.572.468 1.572 1.404c0 .864-.636 1.344-1.572 1.344zm0 3.552c-1.14 0-1.788-.528-1.788-1.416 0-.888.648-1.356 1.788-1.356 1.152 0 1.788.468 1.788 1.356 0 .888-.636 1.416-1.788 1.416z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    9: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M386.598 95.347c.456-2.439-1.916-9.351-5.834-13.451-1.575-1.648-5.627-2.388-7.746-1.546-4.736 1.885-9.2 14.663-7.735 17.937 1.465 3.273 5.116 3.493 9.31 4.508 4.194 1.014 9.445-2.389 10.239-2.933.794-.544 1.183-1.394 1.766-4.515z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M377.663 63.852c0 1.02-.672 1.692-1.8 1.692-1.128 0-1.8-.672-1.8-1.692s.672-1.716 1.764-1.716c1.116 0 1.836.696 1.836 1.716zm-4.524 3.456c.264 1.14 1.212 1.836 2.556 1.836 2.124 0 3.204-1.704 3.204-4.068 0-2.316-1.068-3.78-3.084-3.78-1.68 0-2.748 1.044-2.748 2.568 0 1.452 1.02 2.496 2.592 2.496 1.272 0 2.04-.696 2.292-1.584.048 1.956-.612 3.54-2.244 3.54-.948 0-1.452-.516-1.632-1.308l-.936.3z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    10: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M412.364 80.485c-1.687.642-3.524 3.784-4.785 7.402-1.666 4.78.346 9.937 4.975 11.983l.115.05c2.526 1.11 7.666-.95 9.724-2.856 2.059-1.907-1.945-13.193-3.472-15.003-1.526-1.81-3.673-2.672-6.557-1.576z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M412.797 69v-7.608h-.684c-.216.888-.564 1.128-1.836 1.128h-.54v.768h2.016V69h1.044zm8.168-3.78c0-2.508-1.152-3.924-3.132-3.924-1.98 0-3.144 1.416-3.144 3.924s1.164 3.924 3.144 3.924c1.98 0 3.132-1.416 3.132-3.924zm-1.08 0c0 2.052-.66 3.084-2.052 3.084s-2.052-1.032-2.052-3.084.66-3.084 2.052-3.084 2.052 1.032 2.052 3.084z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    11: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M456.807 80.107c-3.282-.894-9.368 3.842-10.797 12.715-.276 7.4 5.255 9.705 8.497 10.918 3.243 1.212 10.86-4.141 11.097-8.637.238-4.496-5.514-14.102-8.797-14.996z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M454.768 69v-7.608h-.684c-.216.888-.564 1.128-1.836 1.128h-.54v.768h2.016V69h1.044zm4.664 0v-7.608h-.684c-.216.888-.564 1.128-1.836 1.128h-.54v.768h2.016V69h1.044z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    12: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M493.95 80.743c-3.964 1.11-7.19 7.13-6.936 14.43.051 1.451.371 2.903 1.07 4.177 3.804 6.931 11.628 5.241 15.358 2.555 4.053-2.92 1.245-12.193-1.097-17.436s-4.043-4.944-8.395-3.726z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M493.784 69v-7.608h-.684c-.216.888-.564 1.128-1.836 1.128h-.54v.768h2.016V69h1.044zm3.464-.9l1.404-1.176c1.044-.864 2.292-1.944 2.292-3.312 0-1.284-.84-2.316-2.604-2.316-1.62 0-2.664.996-2.664 2.544 0 .228.036.468.12.708l1.02.048a1.911 1.911 0 01-.12-.684c0-1.104.6-1.74 1.644-1.74.996 0 1.536.6 1.536 1.488 0 1.104-.912 1.872-2.124 2.904l-2.148 1.836v.6h5.58v-.9h-3.936z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    13: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M535.756 80c-4.861-.053-7.924 4.936-8.682 11.723a11.635 11.635 0 00.366 4.498c2.475 8.612 7.935 8.399 9.417 8.716 1.599.342 8.306-3.048 7.701-11.347-.606-8.299-3.411-13.53-8.802-13.59z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M533.748 69v-7.608h-.684c-.216.888-.564 1.128-1.836 1.128h-.54v.768h2.016V69h1.044zm2.865-5.1c.06-1.104.72-1.764 1.74-1.764 1.044 0 1.596.564 1.596 1.38 0 .972-.804 1.284-1.62 1.284-.348 0-.552-.048-.72-.072v.948c.18-.024.408-.072.72-.072.96 0 1.74.456 1.74 1.32 0 .9-.684 1.38-1.668 1.38-1.116 0-1.92-.624-1.98-1.752l-.996.312c.168 1.428 1.308 2.28 2.964 2.28 1.668 0 2.724-.888 2.724-2.148 0-.984-.768-1.728-1.728-1.812.792-.12 1.584-.78 1.584-1.764 0-1.26-.924-2.124-2.592-2.124-1.56 0-2.58.948-2.748 2.292l.984.312z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    14: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M575.21 80c-7.463-.092-11.278 7.585-12.136 15.39a12.482 12.482 0 00.55 5.252c1.385 4.211 2.7 4.486 4.129 6.164 1.676 1.969 7.026-1.045 9.863-.873 2.837.171 7.835-.924 9.901-2.395 2.065-1.471 3.027-11.695 1.04-17.527-1.987-5.832-5.029-5.907-13.347-6.01z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M573.537 69v-7.608h-.684c-.216.888-.564 1.128-1.836 1.128h-.54v.768h2.016V69h1.044zm6.658-7.56h-1.296l-3.708 4.536v1.008h3.996V69h1.008v-2.016h1.356v-.852h-1.356V61.44zm-1.008.9v3.792h-3.108l3.108-3.792z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    15: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M612.568 80.239c-3.851.198-6.022 2.127-7.484 9.016-.574 2.073-1.545 7.029-.833 10.263.89 4.042 8.103 2.988 10.594 2.86 2.492-.129 6.436 1.485 9.061-.468 2.624-1.952 5.066-11.843 3.642-17.448-1.425-5.604-11.13-4.422-14.98-4.223z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M613.672 69v-7.608h-.684c-.216.888-.564 1.128-1.836 1.128h-.54v.768h2.016V69h1.044zm1.748-2.256c.12 1.428 1.272 2.4 2.94 2.4 1.644 0 2.808-1.092 2.808-2.652 0-1.392-.912-2.556-2.58-2.556-.852 0-1.524.372-1.872.828.096-.396.264-2.064.288-2.52.744.06 2.292.18 2.868.18.72 0 .9-.408.84-.984h-4.488c-.036 1.152-.252 2.88-.432 3.96l1.02.18c.288-.456.84-.84 1.608-.84 1.08 0 1.728.756 1.728 1.752 0 1.104-.696 1.824-1.764 1.824-1.188 0-1.896-.72-1.932-1.788l-1.032.216z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    16: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M656.015 80.698c-3.629-1.814-8.845-.453-10.433 8.392-2.722 12.248 4.763 12.248 8.845 12.021 4.083-.227 2.949 1.588 5.67 1.361 2.722-.227 5.671-2.041 6.351-8.846.68-6.804-1.134-10.206-3.629-10.886-2.495-.68-3.175-.227-6.804-2.042z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M653.543 69v-7.608h-.684c-.216.888-.564 1.128-1.836 1.128h-.54v.768h2.016V69h1.044zm7.652-5.868c-.264-1.14-1.212-1.836-2.556-1.836-2.124 0-3.204 1.704-3.204 4.068 0 2.316 1.068 3.78 3.084 3.78 1.68 0 2.748-1.044 2.748-2.568 0-1.452-1.02-2.496-2.592-2.496-1.272 0-2.04.696-2.292 1.584-.048-1.956.612-3.54 2.244-3.54.948 0 1.452.516 1.632 1.308l.936-.3zm-4.524 3.456c0-1.02.672-1.692 1.8-1.692 1.128 0 1.8.672 1.8 1.692s-.672 1.716-1.764 1.716c-1.116 0-1.836-.696-1.836-1.716z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    17: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M652.616 148.23c-4.939-.597-10.988 2.464-10.598 11.722-.103 12.991 9.093 10.527 14.048 8.959 4.956-1.568 4.043.597 7.327-.523s6.426-3.882 5.462-10.826c-.965-6.943-4.094-9.706-7.339-9.556-3.246.149-3.962.821-8.9.224z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M654.483 136.476v-7.608h-.684c-.216.888-.564 1.128-1.836 1.128h-.54v.768h2.016v5.712h1.044zm5.866-6.6c-1.512 1.584-2.556 3.924-2.592 6.6h1.14c.024-2.568.672-4.86 2.568-6.828v-.732h-5.364v.96h4.248z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    18: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M618.891 147.215c8.333.497 10.145 5.221 10.62 11.935.565 10.373-8.171 12.247-10.955 11.837-2.784-.409-3.324-.448-9.267-.878-5.943-.429-6.427-4.43-7.159-9.075-.731-4.645 1.725-11.252 4.619-12.609 2.893-1.356 3.809-1.708 12.142-1.21z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M613.643 136.476v-7.608h-.684c-.216.888-.564 1.128-1.836 1.128h-.54v.768h2.016v5.712h1.044zm4.688.144c1.8 0 2.88-.828 2.88-2.148 0-.984-.792-1.668-1.62-1.812.816-.276 1.356-.9 1.356-1.788 0-1.308-1.116-2.1-2.616-2.1s-2.604.792-2.604 2.1c0 .888.54 1.512 1.344 1.788-.828.144-1.62.828-1.62 1.812 0 1.32 1.08 2.148 2.88 2.148zm0-4.332c-.936 0-1.572-.48-1.572-1.344 0-.936.636-1.404 1.572-1.404s1.572.468 1.572 1.404c0 .864-.636 1.344-1.572 1.344zm0 3.552c-1.14 0-1.788-.528-1.788-1.416 0-.888.648-1.356 1.788-1.356 1.152 0 1.788.468 1.788 1.356 0 .888-.636 1.416-1.788 1.416z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    19: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M586.071 150.415c2.84 1.183 4.36 3.864 5.289 9.208.338 1.946.341 3.975-.374 5.816-1.548 3.99-4.547 6.48-7.372 6.721-3.452.295-5.497 2.398-10.294 2.285-4.798-.113-11.13-6.009-12.108-8.148-.977-2.138 1.638-10.727 3.334-14.401 1.697-3.674 3.382-2.871 5.966-3.68 2.583-.809 12.238.817 15.559 2.199z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M573.543 136.476v-7.608h-.684c-.216.888-.564 1.128-1.836 1.128h-.54v.768h2.016v5.712h1.044zm6.452-5.148c0 1.02-.672 1.692-1.8 1.692-1.128 0-1.8-.672-1.8-1.692s.672-1.716 1.764-1.716c1.116 0 1.836.696 1.836 1.716zm-4.524 3.456c.264 1.14 1.212 1.836 2.556 1.836 2.124 0 3.204-1.704 3.204-4.068 0-2.316-1.068-3.78-3.084-3.78-1.68 0-2.748 1.044-2.748 2.568 0 1.452 1.02 2.496 2.592 2.496 1.272 0 2.04-.696 2.292-1.584.048 1.956-.612 3.54-2.244 3.54-.948 0-1.452-.516-1.632-1.308l-.936.3z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    20: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M543.562 153.803c.604.794.285.985.917 4.626.221 1.275.404 2.571.259 3.856-.417 3.684-2.016 5.858-2.999 7.349-1.143 1.737-5.658 2.282-8.138 1.857-2.48-.425-6.461-6.064-7.268-8.34-.807-2.275-.106-7.43 1.652-10.79 1.758-3.359 6.746-3.687 10.229-3.158 3.483.528 4.652 3.684 5.348 4.6z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M531.613 135.576l1.404-1.176c1.044-.864 2.292-1.944 2.292-3.312 0-1.284-.84-2.316-2.604-2.316-1.62 0-2.664.996-2.664 2.544 0 .228.036.468.12.708l1.02.048a1.911 1.911 0 01-.12-.684c0-1.104.6-1.74 1.644-1.74.996 0 1.536.6 1.536 1.488 0 1.104-.912 1.872-2.124 2.904l-2.148 1.836v.6h5.58v-.9h-3.936zm11.337-2.88c0-2.508-1.152-3.924-3.132-3.924-1.98 0-3.144 1.416-3.144 3.924s1.164 3.924 3.144 3.924c1.98 0 3.132-1.416 3.132-3.924zm-1.08 0c0 2.052-.66 3.084-2.052 3.084s-2.052-1.032-2.052-3.084.66-3.084 2.052-3.084 2.052 1.032 2.052 3.084z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    21: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M495.278 149.274c2.882-1.295 5.321 2.129 9.057 7.514.668.961.176 7.044-.714 9.264-.889 2.219-5.278 3.409-8.63 3.212-3.351-.197-5.977-2.774-7.713-7.57-1.737-4.797 5.119-11.125 8-12.42z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M492.548 135.576l1.404-1.176c1.044-.864 2.292-1.944 2.292-3.312 0-1.284-.84-2.316-2.604-2.316-1.62 0-2.664.996-2.664 2.544 0 .228.036.468.12.708l1.02.048a1.911 1.911 0 01-.12-.684c0-1.104.6-1.74 1.644-1.74.996 0 1.536.6 1.536 1.488 0 1.104-.912 1.872-2.124 2.904l-2.148 1.836v.6h5.58v-.9h-3.936zm7.904.9v-7.608h-.684c-.216.888-.564 1.128-1.836 1.128h-.54v.768h2.016v5.712h1.044z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    22: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M448.205 162.653c-.891-2.092 1.248-6.52 4.033-9.869 1.386-1.666 3.289-2.934 5.45-2.77 2.803.213 6.532 11.178 6.491 13.991-.041 2.814-6.578 5.819-9.779 5.375-3.2-.444-5.071-4.086-6.195-6.727z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M451.593 135.576l1.404-1.176c1.044-.864 2.292-1.944 2.292-3.312 0-1.284-.84-2.316-2.604-2.316-1.62 0-2.664.996-2.664 2.544 0 .228.036.468.12.708l1.02.048a1.911 1.911 0 01-.12-.684c0-1.104.6-1.74 1.644-1.74.996 0 1.536.6 1.536 1.488 0 1.104-.912 1.872-2.124 2.904l-2.148 1.836v.6h5.58v-.9h-3.936zm6.645 0l1.404-1.176c1.044-.864 2.292-1.944 2.292-3.312 0-1.284-.84-2.316-2.604-2.316-1.62 0-2.664.996-2.664 2.544 0 .228.036.468.12.708l1.02.048a1.911 1.911 0 01-.12-.684c0-1.104.6-1.74 1.644-1.74.996 0 1.536.6 1.536 1.488 0 1.104-.912 1.872-2.124 2.904l-2.148 1.836v.6h5.58v-.9h-3.936z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    23: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M413.985 148.062c2.949-.65 5.545 3.884 7.944 9.378.243.55.941 3.446.421 4.841-.519 1.396-3.781 4.735-7.857 5.155-4.075.421-6.739-2.625-7.425-5.018-.686-2.392 3.968-13.706 6.917-14.356z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M411.552 135.576l1.404-1.176c1.044-.864 2.292-1.944 2.292-3.312 0-1.284-.84-2.316-2.604-2.316-1.62 0-2.664.996-2.664 2.544 0 .228.036.468.12.708l1.02.048a1.911 1.911 0 01-.12-.684c0-1.104.6-1.74 1.644-1.74.996 0 1.536.6 1.536 1.488 0 1.104-.912 1.872-2.124 2.904l-2.148 1.836v.6h5.58v-.9h-3.936zm6.057-4.2c.06-1.104.72-1.764 1.74-1.764 1.044 0 1.596.564 1.596 1.38 0 .972-.804 1.284-1.62 1.284-.348 0-.552-.048-.72-.072v.948c.18-.024.408-.072.72-.072.96 0 1.74.456 1.74 1.32 0 .9-.684 1.38-1.668 1.38-1.116 0-1.92-.624-1.98-1.752l-.996.312c.168 1.428 1.308 2.28 2.964 2.28 1.668 0 2.724-.888 2.724-2.148 0-.984-.768-1.728-1.728-1.812.792-.12 1.584-.78 1.584-1.764 0-1.26-.924-2.124-2.592-2.124-1.56 0-2.58.948-2.748 2.292l.984.312z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    24: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M369.221 164.745c-.744-2.184.471-6.781 2.426-10.795.88-1.807 2.301-3.4 4.243-3.918 2.76-.736 6.439 11.525 6.669 13.732.23 2.207-4.599 4.169-6.439 4.169-1.84 0-5.979-.491-6.899-3.188z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M371.447 135.576l1.404-1.176c1.044-.864 2.292-1.944 2.292-3.312 0-1.284-.84-2.316-2.604-2.316-1.62 0-2.664.996-2.664 2.544 0 .228.036.468.12.708l1.02.048a1.911 1.911 0 01-.12-.684c0-1.104.6-1.74 1.644-1.74.996 0 1.536.6 1.536 1.488 0 1.104-.912 1.872-2.124 2.904l-2.148 1.836v.6h5.58v-.9h-3.936zm9.638-6.66h-1.296l-3.708 4.536v1.008h3.996v2.016h1.008v-2.016h1.356v-.852h-1.356v-4.692zm-1.008.9v3.792h-3.108l3.108-3.792z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    25: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M342.345 164.745c.745-2.184-.471-6.781-2.425-10.795-.88-1.807-2.301-3.4-4.243-3.918-2.76-.736-6.439 11.525-6.669 13.732-.23 2.207 4.599 4.169 6.439 4.169 1.839 0 5.978-.491 6.898-3.188z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M331.505 135.576l1.404-1.176c1.044-.864 2.292-1.944 2.292-3.312 0-1.284-.84-2.316-2.604-2.316-1.62 0-2.664.996-2.664 2.544 0 .228.036.468.12.708l1.02.048a1.911 1.911 0 01-.12-.684c0-1.104.6-1.74 1.644-1.74.996 0 1.536.6 1.536 1.488 0 1.104-.912 1.872-2.124 2.904l-2.148 1.836v.6h5.58v-.9h-3.936zm4.882-1.356c.12 1.428 1.272 2.4 2.94 2.4 1.644 0 2.808-1.092 2.808-2.652 0-1.392-.912-2.556-2.58-2.556-.852 0-1.524.372-1.872.828.096-.396.264-2.064.288-2.52.744.06 2.292.18 2.868.18.72 0 .9-.408.84-.984h-4.488c-.036 1.152-.252 2.88-.432 3.96l1.02.18c.288-.456.84-.84 1.608-.84 1.08 0 1.728.756 1.728 1.752 0 1.104-.696 1.824-1.764 1.824-1.188 0-1.896-.72-1.932-1.788l-1.032.216z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    26: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M296.454 148.058c-2.954-.627-5.515 3.926-7.872 9.439-.238.552-.914 3.452-.384 4.844.53 1.392 3.818 4.705 7.896 5.094 4.079.389 6.72-2.677 7.387-5.074.667-2.398-4.074-13.676-7.027-14.303z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M291.382 135.576l1.404-1.176c1.044-.864 2.292-1.944 2.292-3.312 0-1.284-.84-2.316-2.604-2.316-1.62 0-2.664.996-2.664 2.544 0 .228.036.468.12.708l1.02.048a1.911 1.911 0 01-.12-.684c0-1.104.6-1.74 1.644-1.74.996 0 1.536.6 1.536 1.488 0 1.104-.912 1.872-2.124 2.904l-2.148 1.836v.6h5.58v-.9h-3.936zm10.774-4.968c-.264-1.14-1.212-1.836-2.556-1.836-2.124 0-3.204 1.704-3.204 4.068 0 2.316 1.068 3.78 3.084 3.78 1.68 0 2.748-1.044 2.748-2.568 0-1.452-1.02-2.496-2.592-2.496-1.272 0-2.04.696-2.292 1.584-.048-1.956.612-3.54 2.244-3.54.948 0 1.452.516 1.632 1.308l.936-.3zm-4.524 3.456c0-1.02.672-1.692 1.8-1.692 1.128 0 1.8.672 1.8 1.692s-.672 1.716-1.764 1.716c-1.116 0-1.836-.696-1.836-1.716z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    27: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M264.027 162.777c1.157-1.958-.382-6.628-2.703-10.314-1.155-1.834-2.875-3.34-5.039-3.461-2.807-.158-7.943 10.223-8.272 13.017-.329 2.795 5.757 6.632 8.988 6.612 3.231-.019 5.564-3.385 7.026-5.854z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M251.316 135.576l1.404-1.176c1.044-.864 2.292-1.944 2.292-3.312 0-1.284-.84-2.316-2.604-2.316-1.62 0-2.664.996-2.664 2.544 0 .228.036.468.12.708l1.02.048a1.911 1.911 0 01-.12-.684c0-1.104.6-1.74 1.644-1.74.996 0 1.536.6 1.536 1.488 0 1.104-.912 1.872-2.124 2.904l-2.148 1.836v.6h5.58v-.9h-3.936zm9-5.7c-1.512 1.584-2.556 3.924-2.592 6.6h1.14c.024-2.568.672-4.86 2.568-6.828v-.732h-5.364v.96h4.248z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    28: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M217.357 150.401c-2.745-1.563-5.499 1.613-9.731 6.618-.756.893-.845 6.995-.17 9.289.674 2.294 4.93 3.896 8.285 4.019 3.354.124 6.213-2.192 8.398-6.801 2.186-4.61-4.036-11.562-6.782-13.125z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M211.453 135.576l1.404-1.176c1.044-.864 2.292-1.944 2.292-3.312 0-1.284-.84-2.316-2.604-2.316-1.62 0-2.664.996-2.664 2.544 0 .228.036.468.12.708l1.02.048a1.911 1.911 0 01-.12-.684c0-1.104.6-1.74 1.644-1.74.996 0 1.536.6 1.536 1.488 0 1.104-.912 1.872-2.124 2.904l-2.148 1.836v.6h5.58v-.9h-3.936zm7.868 1.044c1.8 0 2.88-.828 2.88-2.148 0-.984-.792-1.668-1.62-1.812.816-.276 1.356-.9 1.356-1.788 0-1.308-1.116-2.1-2.616-2.1s-2.604.792-2.604 2.1c0 .888.54 1.512 1.344 1.788-.828.144-1.62.828-1.62 1.812 0 1.32 1.08 2.148 2.88 2.148zm0-4.332c-.936 0-1.572-.48-1.572-1.344 0-.936.636-1.404 1.572-1.404s1.572.468 1.572 1.404c0 .864-.636 1.344-1.572 1.344zm0 3.552c-1.14 0-1.788-.528-1.788-1.416 0-.888.648-1.356 1.788-1.356 1.152 0 1.788.468 1.788 1.356 0 .888-.636 1.416-1.788 1.416z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    29: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M168.322 152.704c-.614.787-.298.982-.974 4.615-.237 1.272-.437 2.566-.307 3.853.371 3.688 1.943 5.882 2.907 7.385 1.122 1.751 5.63 2.352 8.115 1.958 2.485-.395 6.536-5.984 7.37-8.249.835-2.266.199-7.429-1.518-10.81-1.716-3.381-6.7-3.771-10.19-3.285-3.489.485-4.696 3.627-5.403 4.533z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M171.353 135.576l1.404-1.176c1.044-.864 2.292-1.944 2.292-3.312 0-1.284-.84-2.316-2.604-2.316-1.62 0-2.664.996-2.664 2.544 0 .228.036.468.12.708l1.02.048a1.911 1.911 0 01-.12-.684c0-1.104.6-1.74 1.644-1.74.996 0 1.536.6 1.536 1.488 0 1.104-.912 1.872-2.124 2.904l-2.148 1.836v.6h5.58v-.9h-3.936zm9.633-4.248c0 1.02-.672 1.692-1.8 1.692-1.128 0-1.8-.672-1.8-1.692s.672-1.716 1.764-1.716c1.116 0 1.836.696 1.836 1.716zm-4.524 3.456c.264 1.14 1.212 1.836 2.556 1.836 2.124 0 3.204-1.704 3.204-4.068 0-2.316-1.068-3.78-3.084-3.78-1.68 0-2.748 1.044-2.748 2.568 0 1.452 1.02 2.496 2.592 2.496 1.272 0 2.04-.696 2.292-1.584.048 1.956-.612 3.54-2.244 3.54-.948 0-1.452-.516-1.632-1.308l-.936.3z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    30: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M126.081 149.433C123.242 150.62 121.727 153.303 120.807 158.649C120.472 160.595 120.472 162.624 121.189 164.464C122.744 168.452 125.747 170.937 128.573 171.174C132.024 171.463 134.073 173.563 138.87 173.442C143.668 173.322 149.991 167.416 150.965 165.276C151.938 163.135 149.31 154.551 147.608 150.879C145.906 147.208 144.221 148.014 141.637 147.209C139.052 146.404 129.4 148.045 126.081 149.433Z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M129.984 131.376C130.044 130.272 130.704 129.612 131.724 129.612C132.768 129.612 133.32 130.176 133.32 130.992C133.32 131.964 132.516 132.276 131.7 132.276C131.352 132.276 131.148 132.228 130.98 132.204V133.152C131.16 133.128 131.388 133.08 131.7 133.08C132.66 133.08 133.44 133.536 133.44 134.4C133.44 135.3 132.756 135.78 131.772 135.78C130.656 135.78 129.852 135.156 129.792 134.028L128.796 134.34C128.964 135.768 130.104 136.62 131.76 136.62C133.428 136.62 134.484 135.732 134.484 134.472C134.484 133.488 133.716 132.744 132.756 132.66C133.548 132.54 134.34 131.88 134.34 130.896C134.34 129.636 133.416 128.772 131.748 128.772C130.188 128.772 129.168 129.72 129 131.064L129.984 131.376ZM141.991 132.696C141.991 130.188 140.839 128.772 138.859 128.772C136.879 128.772 135.715 130.188 135.715 132.696C135.715 135.204 136.879 136.62 138.859 136.62C140.839 136.62 141.991 135.204 141.991 132.696ZM140.911 132.696C140.911 134.748 140.251 135.78 138.859 135.78C137.467 135.78 136.807 134.748 136.807 132.696C136.807 130.644 137.467 129.612 138.859 129.612C140.251 129.612 140.911 130.644 140.911 132.696Z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    31: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M93.4542 145.996C85.1065 145.976 83.0052 150.578 82.1154 157.25C80.9081 167.569 89.5117 169.98 92.3154 169.744C95.1191 169.507 95.6608 169.502 101.619 169.442C107.577 169.382 108.308 165.418 109.326 160.827C110.344 156.236 108.303 149.49 105.499 147.957C102.694 146.423 101.802 146.016 93.4542 145.996Z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M91.9545 131.376C92.0145 130.272 92.6745 129.612 93.6945 129.612C94.7385 129.612 95.2905 130.176 95.2905 130.992C95.2905 131.964 94.4865 132.276 93.6705 132.276C93.3225 132.276 93.1185 132.228 92.9505 132.204V133.152C93.1305 133.128 93.3585 133.08 93.6705 133.08C94.6305 133.08 95.4105 133.536 95.4105 134.4C95.4105 135.3 94.7265 135.78 93.7425 135.78C92.6265 135.78 91.8225 135.156 91.7625 134.028L90.7665 134.34C90.9345 135.768 92.0745 136.62 93.7305 136.62C95.3985 136.62 96.4545 135.732 96.4545 134.472C96.4545 133.488 95.6865 132.744 94.7265 132.66C95.5185 132.54 96.3105 131.88 96.3105 130.896C96.3105 129.636 95.3865 128.772 93.7185 128.772C92.1585 128.772 91.1385 129.72 90.9705 131.064L91.9545 131.376ZM100.457 136.476V128.868H99.7734C99.5574 129.756 99.2094 129.996 97.9374 129.996H97.3974V130.764H99.4134V136.476H100.457Z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
    32: {
        Tooth: (props: React.SVGProps<any>) => (
            <>
                <path
                    d={
                        'M56.454 147.714c4.616-1.855 11.251-.464 13.27 8.58 3.462 12.521-6.058 12.522-11.25 12.29-5.193-.232-3.751 1.623-7.213 1.391-3.462-.232-7.212-2.087-8.078-9.044-.865-6.956 1.443-10.434 4.616-11.13 3.174-.696 4.04-.232 8.655-2.087z'
                    }
                    fill={FlossPalette.DARK_TAN}
                    {...props}
                />
                <path
                    d={
                        'M49.97 131.376c.06-1.104.72-1.764 1.74-1.764 1.044 0 1.596.564 1.596 1.38 0 .972-.804 1.284-1.62 1.284-.348 0-.552-.048-.72-.072v.948c.18-.024.408-.072.72-.072.96 0 1.74.456 1.74 1.32 0 .9-.684 1.38-1.668 1.38-1.116 0-1.92-.624-1.98-1.752l-.996.312c.168 1.428 1.308 2.28 2.964 2.28 1.668 0 2.724-.888 2.724-2.148 0-.984-.768-1.728-1.728-1.812.792-.12 1.584-.78 1.584-1.764 0-1.26-.924-2.124-2.592-2.124-1.56 0-2.58.948-2.748 2.292l.984.312zm7.303 4.2l1.404-1.176c1.044-.864 2.292-1.944 2.292-3.312 0-1.284-.84-2.316-2.604-2.316-1.62 0-2.664.996-2.664 2.544 0 .228.036.468.12.708l1.02.048a1.911 1.911 0 01-.12-.684c0-1.104.6-1.74 1.644-1.74.996 0 1.536.6 1.536 1.488 0 1.104-.912 1.872-2.124 2.904l-2.148 1.836v.6h5.58v-.9h-3.936z'
                    }
                    fill={FlossPalette.GRAY}
                    {...props}
                />
            </>
        ),
    },
};
