import { getLabelForCancellationReasonCode, isReasonAnotherLabCancellation } from './OrderCancel.util';
import type {
    LabsGqlExternalUserCancelOrderMutationVariables,
    LabsGqlPsrCancelOrderMutationVariables,
} from '@orthly/graphql-operations';
import { LabsGqlCancellationReasonCode } from '@orthly/graphql-schema';
import type { Theme, FormControlLabelProps, RadioProps, TextFieldProps } from '@orthly/ui-primitives';
import {
    FormControl,
    FormControlLabel,
    RadioPrimitive as Radio,
    RadioGroupPrimitive as RadioGroup,
    createStyles,
    makeStyles,
    TextField,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        radioButtonLabel: {
            marginLeft: 0,
        },
        otherText: {
            display: 'flex',
            marginLeft: theme.spacing(7),
        },
        anotherLabSubGroup: {
            marginLeft: theme.spacing(5),
            flexDirection: 'row',
            flexWrap: 'nowrap',
            [`& $radioButtonLabel`]: {
                [theme.breakpoints.up('sm')]: {
                    fontSize: '0.875rem',
                },
            },
        },
    }),
);

const ReasonRadioButton: React.FC<
    Omit<Partial<FormControlLabelProps & RadioProps>, 'value'> & {
        value: LabsGqlCancellationReasonCode;
    }
> = props => {
    const { value, ...otherProps } = props;
    const label = getLabelForCancellationReasonCode(value);
    const { radioButtonLabel } = useStyles();
    return (
        <FormControlLabel
            control={<Radio color={'secondary'} />}
            value={value}
            label={label}
            aria-label={label}
            classes={{
                label: radioButtonLabel,
            }}
            data-test={`cancellation-reason-code-${value}`}
            {...otherProps}
        />
    );
};

const ReasonTextField: React.FC<Omit<TextFieldProps, 'onChange'> & { onChange?: (value: string) => void }> = props => {
    const { value, onChange, ...otherProps } = props;
    const { otherText } = useStyles();
    return (
        <TextField
            variant={'standard'}
            required={false}
            multiline={true}
            minRows={2}
            type={'text'}
            name={'cancellation_reason'}
            value={value ?? ''}
            label={'Cancellation Reason'}
            aria-label={'Cancellation Reason'}
            helperText={'Please let us know why you need to cancel'}
            classes={{
                root: otherText,
            }}
            onChange={event => onChange?.(event.target.value)}
            data-test={`cancellation-reason`}
            {...otherProps}
        />
    );
};

type Vars = Pick<
    LabsGqlExternalUserCancelOrderMutationVariables['data'] | LabsGqlPsrCancelOrderMutationVariables['data'],
    'cancellation_reason_code' | 'cancellation_reason'
>;
interface OrderCancelReasonFormComponentProps {
    cancellationReasonCode?: Vars['cancellation_reason_code'] | null;
    cancellationReason?: Vars['cancellation_reason'] | null;
    setCancellationReasonCode: (reasonCode: Vars['cancellation_reason_code']) => void;
    setCancellationReason: (reason: Vars['cancellation_reason']) => void;
}

export const OrderCancelReasonFormComponent: React.VFC<OrderCancelReasonFormComponentProps> = ({
    cancellationReasonCode,
    cancellationReason,
    setCancellationReasonCode,
    setCancellationReason,
}) => {
    const { anotherLabSubGroup } = useStyles();
    const isReasonAnotherLabCancellationSelected = React.useMemo(
        () => isReasonAnotherLabCancellation(cancellationReasonCode),
        [cancellationReasonCode],
    );

    return (
        <FormControl variant={'standard'} component={'fieldset'} fullWidth>
            <RadioGroup
                aria-label={'Cancellation reasons'}
                name={'cancellation_reason_code'}
                value={cancellationReasonCode}
                onChange={event => {
                    setCancellationReasonCode(event.target.value as LabsGqlCancellationReasonCode);
                }}
            >
                <ReasonRadioButton value={LabsGqlCancellationReasonCode.PatientCancelled} />
                <ReasonRadioButton value={LabsGqlCancellationReasonCode.PatientPostponed} />
                <ReasonRadioButton value={LabsGqlCancellationReasonCode.OrderTooLate} />
                <ReasonRadioButton value={LabsGqlCancellationReasonCode.DuplicateOrder} />
                <ReasonRadioButton
                    value={LabsGqlCancellationReasonCode.AnotherLab}
                    checked={isReasonAnotherLabCancellationSelected}
                />
                {isReasonAnotherLabCancellationSelected && (
                    <>
                        <RadioGroup
                            aria-label={'Cancellation reasons for going with another Lab'}
                            name={'cancellation_reason_code'}
                            value={cancellationReasonCode}
                            onChange={event => {
                                setCancellationReasonCode(event.target.value as LabsGqlCancellationReasonCode);
                            }}
                            className={anotherLabSubGroup}
                        >
                            <ReasonRadioButton value={LabsGqlCancellationReasonCode.AnotherLabProductQuality} />
                            <ReasonRadioButton value={LabsGqlCancellationReasonCode.AnotherLabServiceQuality} />
                            <ReasonRadioButton value={LabsGqlCancellationReasonCode.AnotherLabOther} />
                        </RadioGroup>

                        {cancellationReasonCode === LabsGqlCancellationReasonCode.AnotherLabOther && (
                            <ReasonTextField value={cancellationReason} onChange={setCancellationReason} />
                        )}
                    </>
                )}
                <ReasonRadioButton value={LabsGqlCancellationReasonCode.Other} />
                {cancellationReasonCode === LabsGqlCancellationReasonCode.Other && (
                    <ReasonTextField value={cancellationReason} onChange={setCancellationReason} />
                )}
            </RadioGroup>
        </FormControl>
    );
};
