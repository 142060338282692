import type { MarginLinesMap, RestorativeModel, ScanGeometries } from './FinishingApp.types';
import {
    checkMarginCloseToRestorative,
    checkMarginCloseToPrepScan,
    checkIntaglioIntersectsScan,
} from '@orthly/forceps';
import { ToothUtils, type ToothNumber } from '@orthly/items';

export interface RestorativeAlignmentCheckResult {
    // Tooth number of the restorative
    toothNumber: ToothNumber;
    // True if the margin line is close enough to the restorative geometry, false otherwise
    marginDistanceToRestorativeOkay: boolean;
    // True if the margin line is close enough to the prep scan geometry, false otherwise
    marginDistanceToPrepScanOkay: boolean;
    // True if the intaglio of the restorative does not intersect the prep scan geometry, false otherwise
    intaglioIntersectionOkay: boolean;
}

export function checkRestorativesAlignment(
    restorativeModels: RestorativeModel[],
    scanGeometries: ScanGeometries,
    marginLines: MarginLinesMap,
): RestorativeAlignmentCheckResult[] | undefined {
    const results: RestorativeAlignmentCheckResult[] = [];

    for (const restorativeModel of restorativeModels) {
        const marginLine = marginLines.get(restorativeModel.toothNumber);
        if (!marginLine) {
            console.warn(`Margin line missing for tooth ${restorativeModel.toothNumber}`);
            return undefined;
        }

        const scanGeometry = ToothUtils.toothIsUpper(restorativeModel.toothNumber)
            ? scanGeometries.upperJaw
            : scanGeometries.lowerJaw;

        const marginDistanceToRestorativeOkay = checkMarginCloseToRestorative(restorativeModel.geometry, marginLine);
        const marginDistanceToPrepScanOkay = checkMarginCloseToPrepScan(scanGeometry, marginLine);

        const intaglioIntersectionOkay = !checkIntaglioIntersectsScan(restorativeModel.geometry, scanGeometry);

        results.push({
            toothNumber: restorativeModel.toothNumber,
            marginDistanceToRestorativeOkay,
            marginDistanceToPrepScanOkay,
            intaglioIntersectionOkay,
        });
    }

    return results;
}
