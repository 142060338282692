import type { FragmentType, VeneerAlignerTreatmentPlanSidebar_FragmentFragment } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { LabsGqlDesignOrderAlignerTreatmentPlanDoctorReviewStatus } from '@orthly/graphql-schema';
import { UserRoleUtil } from '@orthly/shared-types';
import type { FlossColor } from '@orthly/ui';
import { DarkGray } from '@orthly/ui';
import { FlossPalette, Text, useScreenIsMobile, FlossPaletteUtils, Grid } from '@orthly/ui-primitives';
import React from 'react';

export const AlignerTreatmentPlanSidebarBox: React.VFC<{
    children: React.ReactNode;
    style?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
}> = ({ children, style = {}, containerStyle = {} }) => (
    <Grid item container style={{ flexGrow: 1, flexBasis: 0, ...containerStyle }}>
        <Grid
            item
            container
            style={{
                padding: 16,
                backgroundColor: FlossPalette.WHITE,
                borderRadius: 16,
                border: `1px solid ${FlossPaletteUtils.toRgba('BLACK', 0.24)}`,
                ...style,
            }}
            direction={`column`}
        >
            {children}
        </Grid>
    </Grid>
);

export const AlignerTreatmentPlanSidebarRow: React.VFC<{
    title: React.ReactNode;
    text?: React.ReactNode;
    body?: React.ReactNode;
    heading?: boolean;
}> = ({ title, text, body, heading = false }) => (
    <Grid item container style={{ justifyContent: `space-between`, alignItems: `start` }}>
        <Text variant={heading ? `h6` : `body2`} style={{ color: FlossPalette.GRAY, fontWeight: `normal` }}>
            {title}
        </Text>
        {text && (
            <Text variant={heading ? `h6` : `body2`} style={{ color: FlossPalette.BLACK }}>
                {text}
            </Text>
        )}
        {body && <Grid item>{body}</Grid>}
    </Grid>
);

export const AlignerTreatmentPlanStepDot: React.FC<{
    color: FlossColor;
    style?: React.CSSProperties;
}> = ({ color, style }) => (
    <div style={{ backgroundColor: FlossPalette[color], height: 6, width: 6, borderRadius: 6, ...style }} />
);

const AlignerTreatmentPlanSidebarExtrasBlurb: React.FC<{
    treatmentPlan: VeneerAlignerTreatmentPlanSidebar_FragmentFragment;
}> = ({ treatmentPlan }) => {
    const isIPR = treatmentPlan.steps.some(s => s.interproximal_reductions.length > 0);
    const needsAttachmentsUpper = treatmentPlan.needs_attachments.upper;
    const needsAttachmentsLower = treatmentPlan.needs_attachments.lower;
    const attachmentArches =
        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
        // eslint-disable-next-line no-nested-ternary
        needsAttachmentsUpper && needsAttachmentsLower
            ? '(both arches)'
            : // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
              // eslint-disable-next-line no-nested-ternary
              needsAttachmentsUpper
              ? '(upper arch)'
              : needsAttachmentsLower
                ? '(lower arch)'
                : null;

    return (
        <>
            {attachmentArches && (
                <Grid container style={{ alignItems: 'center' }}>
                    <Text variant={'body2'} style={{ marginLeft: 4 }}>
                        <DarkGray>●</DarkGray> Attachments{` `}
                        <DarkGray>{attachmentArches}</DarkGray>
                    </Text>
                </Grid>
            )}
            {isIPR && (
                <Grid container style={{ alignItems: 'center' }}>
                    <Text variant={'body2'} style={{ marginLeft: 4 }}>
                        <span style={{ color: FlossPalette.ATTENTION }}>●</span> IPR{` `}
                        <DarkGray>(see below)</DarkGray>
                    </Text>
                </Grid>
            )}
            {!attachmentArches && !isIPR && `None`}
        </>
    );
};

const AlignerTreatmentPlanStepsBox: React.VFC<{
    stepsCount: number;
}> = ({ stepsCount }) => (
    <AlignerTreatmentPlanSidebarBox>
        <Text variant={'h6'} style={{ fontWeight: `normal`, color: FlossPalette.DARK_GRAY }}>
            Total Steps
        </Text>
        <Text variant={'h6'}>{stepsCount > 0 ? `${stepsCount} steps` : `N/A`}</Text>
    </AlignerTreatmentPlanSidebarBox>
);

const AlignerTreatmentPlanDurationBox: React.VFC<{
    treatmentPlan: VeneerAlignerTreatmentPlanSidebar_FragmentFragment;
    userRole: string;
}> = ({ treatmentPlan, userRole }) => {
    const num_steps = treatmentPlan.steps_count.overall;

    return (
        <AlignerTreatmentPlanSidebarBox>
            <Text variant={'h6'} style={{ fontWeight: `normal`, color: FlossPalette.DARK_GRAY }}>
                Duration
            </Text>
            <Text variant={'h6'}>{num_steps > 0 ? `${treatmentPlan.duration_in_months} months` : `N/A`}</Text>
            {!UserRoleUtil.is_unknown(userRole) && (
                <>
                    <Text
                        variant={'body2'}
                        style={{ fontWeight: `normal`, color: FlossPalette.DARK_GRAY, marginTop: 16 }}
                    >
                        Extras
                    </Text>
                    <AlignerTreatmentPlanSidebarExtrasBlurb treatmentPlan={treatmentPlan} />
                </>
            )}
        </AlignerTreatmentPlanSidebarBox>
    );
};

export const AlignerTreatmentPlanBoxContainer: React.VFC<{ children: React.ReactNode }> = ({ children }) => (
    <Grid item container spacing={2} direction={useScreenIsMobile() ? `column` : `row`}>
        {children}
    </Grid>
);

const VeneerAlignerTreatmentPlanSidebar_Fragment = graphql(`
    fragment VeneerAlignerTreatmentPlanSidebar_Fragment on DesignOrderAlignerTreatmentPlanDTO {
        version_number_practice
        duration_in_months
        steps {
            interproximal_reductions {
                __typename
            }
        }
        needs_attachments {
            upper
            lower
        }
        steps_count {
            overall
        }
        doctor_review {
            notes
            status
        }
    }
`);

export const OrderAlignerTreatmentPlanSidebar: React.VFC<{
    treatmentPlanFragment: FragmentType<typeof VeneerAlignerTreatmentPlanSidebar_Fragment> | null;
    userRole: string;
    statusBox?: React.ReactNode;
}> = ({ treatmentPlanFragment, userRole, statusBox }) => {
    const treatmentPlan = getFragmentData(VeneerAlignerTreatmentPlanSidebar_Fragment, treatmentPlanFragment);

    return (
        <Grid item container spacing={2}>
            <AlignerTreatmentPlanBoxContainer>
                {statusBox}
                {!!treatmentPlan?.version_number_practice && (
                    <>
                        <AlignerTreatmentPlanStepsBox stepsCount={treatmentPlan.steps_count.overall} />
                        <AlignerTreatmentPlanDurationBox treatmentPlan={treatmentPlan} userRole={userRole} />
                    </>
                )}
            </AlignerTreatmentPlanBoxContainer>
            {!UserRoleUtil.is_unknown(userRole) &&
                treatmentPlan?.doctor_review?.status ===
                    LabsGqlDesignOrderAlignerTreatmentPlanDoctorReviewStatus.Rejected &&
                treatmentPlan.doctor_review.notes && (
                    <AlignerTreatmentPlanSidebarBox>
                        <Text variant={`body2`} style={{ fontWeight: `bold` }}>
                            {UserRoleUtil.is_internal(userRole)
                                ? `Revision request provided by doctor:`
                                : `Revision requested:`}
                        </Text>
                        <Text variant={`body2`} style={{ color: FlossPalette.GRAY }}>
                            {treatmentPlan.doctor_review.notes}
                        </Text>
                    </AlignerTreatmentPlanSidebarBox>
                )}
        </Grid>
    );
};
