import type {
    ICartItemV2OfType,
    ICartItemV2Update,
    ItemDataField,
    ItemDataFieldId,
    LabOrderItemSKUType,
} from '@orthly/items';
import _ from 'lodash';
import React from 'react';

export function useAutoSetItemDataFieldDefaultValue<
    ID extends ItemDataFieldId,
    SKU extends LabOrderItemSKUType,
    Enum extends string,
>(
    field: ItemDataField<ID, SKU, Enum>,
    item: ICartItemV2OfType<SKU>,
    updateItem: (update: ICartItemV2Update) => void,
): void {
    React.useEffect(() => {
        const value = field.getValue(item);
        const defaultValue = field.getDefaultValue?.(item);
        if (_.isNil(value) && !_.isNil(defaultValue)) {
            // types will be correct but ts doesn't understand
            updateItem(field.getUpdatePayload(defaultValue as any, item));
        }
    }, [field, item, updateItem]);
}
