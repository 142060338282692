import type { ScanSnapshotGeometries, ScanSnapshotViewingVolumePlanes } from './ScanSnapshot.types';
import { CameraPoseType } from './ScanSnapshot.types';
import * as THREE from 'three';

/**
 * This class is responsible for constructing the camera properties for a scan snapshot.
 * It is meant to be used in-tandem with the ScanSnapshotComputer class.
 */
export class ScanSnapshotCameraPropsConstructor {
    private readonly _xAxis = new THREE.Vector3(1, 0, 0);
    private readonly _yAxis = new THREE.Vector3(0, 1, 0);
    private readonly _zAxis = new THREE.Vector3(0, 0, 1);
    private readonly _poseType: CameraPoseType;
    private readonly _upperArch: THREE.BufferGeometry | undefined;
    private readonly _lowerArch: THREE.BufferGeometry | undefined;

    constructor(poseType: CameraPoseType, geometries: ScanSnapshotGeometries) {
        this._poseType = poseType;
        this._upperArch = geometries.upperArch;
        this._lowerArch = geometries.lowerArch;
    }

    construct(): ScanSnapshotViewingVolumePlanes {
        const boundingBox = this.getBoundingBox();
        const viewData = this.getCameraViewData(boundingBox);

        const cameraUpAxis = viewData.camY;
        const cameraMatrix = new THREE.Matrix4();
        const cameraOrientation = cameraMatrix.makeBasis(viewData.camX, viewData.camY, viewData.camZ);
        const rotation = new THREE.Euler();

        rotation.setFromRotationMatrix(cameraOrientation);

        return {
            ...viewData,
            rotation,
            up: cameraUpAxis,
            zoom: 4,
            near: 0.1,
        };
    }

    private getBoundingBox() {
        const boundingBox = new THREE.Box3();

        const arches = [];
        if (this._poseType !== CameraPoseType.Upper && this._lowerArch) {
            arches.push(this._lowerArch);
        }
        if (this._poseType !== CameraPoseType.Lower && this._upperArch) {
            arches.push(this._upperArch);
        }

        for (const geometry of arches) {
            geometry.computeBoundingBox();

            if (!geometry.boundingBox) {
                continue;
            }

            boundingBox.union(geometry.boundingBox);
        }

        return boundingBox;
    }

    private getCameraViewData(boundingBox: THREE.Box3) {
        const boundingBoxCenter = new THREE.Vector3();
        boundingBox.getCenter(boundingBoxCenter);

        const xExtents = boundingBox.max.x - boundingBox.min.x;
        const yExtents = boundingBox.max.y - boundingBox.min.y;
        const zExtents = boundingBox.max.z - boundingBox.min.z;

        switch (this._poseType) {
            case CameraPoseType.Upper:
                return {
                    camX: this._xAxis.clone(),
                    camY: this._zAxis.clone(),
                    camZ: this._yAxis.clone().negate(),
                    far: yExtents,
                    width: xExtents,
                    height: zExtents,
                    position: boundingBoxCenter.clone().sub(new THREE.Vector3(0.0, yExtents / 2.0, 0.0)),
                };
            case CameraPoseType.Lower:
                return {
                    camX: this._xAxis.clone(),
                    camY: this._zAxis.clone().negate(),
                    camZ: this._yAxis.clone(),
                    far: yExtents,
                    width: xExtents,
                    height: zExtents,
                    position: boundingBoxCenter.clone().add(new THREE.Vector3(0.0, yExtents / 2.0, 0.0)),
                };
            case CameraPoseType.Front:
                return {
                    camX: this._xAxis.clone(),
                    camY: this._yAxis.clone(),
                    camZ: this._zAxis.clone(),
                    far: zExtents,
                    width: xExtents,
                    height: yExtents,
                    position: boundingBoxCenter.clone().add(new THREE.Vector3(0.0, 0.0, zExtents / 2.0)),
                };
            case CameraPoseType.Left:
                return {
                    camX: this._zAxis.negate().clone(),
                    camY: this._yAxis.clone(),
                    camZ: this._xAxis.clone(),
                    far: xExtents,
                    width: zExtents,
                    height: yExtents,
                    position: boundingBoxCenter.clone().add(new THREE.Vector3(xExtents / 2.0, 0.0, 0.0)),
                };
            case CameraPoseType.Right:
                return {
                    camX: this._zAxis.clone(),
                    camY: this._yAxis.clone(),
                    camZ: this._xAxis.clone().negate(),
                    far: xExtents,
                    width: zExtents,
                    height: yExtents,
                    position: boundingBoxCenter.clone().sub(new THREE.Vector3(xExtents / 2.0, 0.0, 0.0)),
                };
        }
    }
}
