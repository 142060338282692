import { useStepperStyles } from '../util/OrderDetailTrackerV2View.util';
import { styled } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

const TrackerAnimationContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
});

export const OrderDetailTrackerAnimation: React.VFC<{ animated: boolean | undefined; clientWidth: number }> = ({
    animated,
    clientWidth,
}) => {
    const baseClasses = useStepperStyles();
    const dotWidth = 16;

    const animatedSteps = React.useMemo(() => {
        const s = [];
        for (let i = 0; i < clientWidth; i += dotWidth) {
            s.push(
                <div
                    style={{ animationDelay: `${(clientWidth / dotWidth - i) / dotWidth / 10}s` }}
                    className={cx(baseClasses.step, baseClasses.inactiveStep, baseClasses.animatedStep)}
                />,
            );
        }
        return s;
    }, [clientWidth, baseClasses.step, baseClasses.inactiveStep, baseClasses.animatedStep]);

    if (!animated) {
        return null;
    }

    return <TrackerAnimationContainer>{animatedSteps}</TrackerAnimationContainer>;
};
