import { AutoAssignmentDesignerEntry } from './AutoAssignmentDesignerEntry';
import { useListFilteredDesignersQuery } from '@orthly/graphql-react';
import type { LabsGqlDesignerFilters } from '@orthly/graphql-schema';
import { LabsGqlDesignStaffStatus } from '@orthly/graphql-schema';
import { ActionCard, SimpleSelect, PaginationFooter } from '@orthly/ui';
import { FlossPalette, TextField, Text, Button } from '@orthly/ui-primitives';
import React from 'react';

const DesignerSearchBar: React.FC<{
    value: LabsGqlDesignerFilters;
    onChange(value: LabsGqlDesignerFilters): void;
    isLoading: boolean;
}> = ({ value: defaultValue, onChange: updateValue, isLoading }) => {
    const [searchFilters, setSearchFilters] = React.useState(defaultValue);
    React.useEffect(() => {
        setSearchFilters(defaultValue);
    }, [defaultValue]);

    return (
        <form
            onSubmit={evt => {
                evt.preventDefault();
                if (isLoading) {
                    return;
                }
                updateValue(searchFilters);
            }}
            style={{
                width: '100%',
                minWidth: 200,
                background: FlossPalette.DARK_TAN,
                display: 'flex',
                alignItems: 'center',
                padding: 10,
                margin: 10,
                borderRadius: 10,
                marginTop: 10,
            }}
        >
            <div style={{ width: 270 }}>
                <TextField
                    variant={'standard'}
                    onChange={evt => setSearchFilters({ ...searchFilters, search: evt.target.value ?? '', page: 0 })}
                    value={searchFilters.search}
                    fullWidth
                    label={'Search for designer'}
                    disabled={isLoading}
                />
            </div>
            <div style={{ width: 180 }}>
                <SimpleSelect
                    value={searchFilters.status ?? 'no-status'}
                    options={[
                        { label: '(any status)', value: 'no-status' },
                        ...Object.values(LabsGqlDesignStaffStatus)
                            .filter(status => status !== LabsGqlDesignStaffStatus.Offboarded)
                            .map(status => ({ label: status, value: status })),
                    ]}
                    onChange={status =>
                        setSearchFilters({
                            ...searchFilters,
                            status: (LabsGqlDesignStaffStatus as any)[String(status)] ?? undefined,
                            page: 0,
                        })
                    }
                    label={'Filter by status'}
                />
            </div>
            <Button
                variant={'primary'}
                size={'small'}
                style={{ marginLeft: 10 }}
                onClick={() => updateValue(searchFilters)}
                disabled={isLoading || searchFilters === defaultValue}
            >
                Search
            </Button>
        </form>
    );
};

export const DesignAutoAssignmentDesignersView = () => {
    const [searchFilter, setSearchFilter] = React.useState<LabsGqlDesignerFilters>({
        search: '',
        status: LabsGqlDesignStaffStatus.Active,
        page: 0,
        per_page: 10,
    });
    const { data, error, loading } = useListFilteredDesignersQuery({ variables: { data: searchFilter } });

    return (
        <div>
            <DesignerSearchBar value={searchFilter} onChange={setSearchFilter} isLoading={loading} />
            {error && (
                <ActionCard
                    style={{ marginTop: 10 }}
                    title={`Failed to load designers.`}
                    subtitle={String(error)}
                    variant={'alert'}
                />
            )}
            {data && (
                <div style={{ marginTop: 20, background: '#fff', border: 'solid 1px', borderRadius: 10 }}>
                    {data.listFilteredDesigners.designers.length === 0 && (
                        <Text variant={'body2'} style={{ padding: 10, width: '100%', textAlign: 'center' }}>
                            Your search returned zero results.
                        </Text>
                    )}
                    {data.listFilteredDesigners.designers.map(designer => (
                        <AutoAssignmentDesignerEntry key={designer.email} designStaffWithUser={designer} />
                    ))}
                </div>
            )}

            <PaginationFooter
                hasNextPage={!!data?.listFilteredDesigners.hasNextPage}
                page={searchFilter.page}
                setPage={page => setSearchFilter(filters => ({ ...filters, page }))}
            />
        </div>
    );
};
