import { SortableItemContext } from './SortableItemContext';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import type { CSSProperties, PropsWithChildren } from 'react';

interface Props {
    id: UniqueIdentifier;
}

export function SortableItem({ children, id }: PropsWithChildren<Props>) {
    const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
        id,
    });
    const context = React.useMemo(
        () => ({
            attributes,
            listeners,
            ref: setActivatorNodeRef,
        }),
        [attributes, listeners, setActivatorNodeRef],
    );
    const style: CSSProperties = {
        display: 'flex',
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS.Translate.toString(transform),
        transition,
    };

    return (
        <SortableItemContext.Provider value={context}>
            <li style={style} ref={setNodeRef}>
                {children}
            </li>
        </SortableItemContext.Provider>
    );
}
