import type { LabsGqlLabOrderCondition, LabsGqlLabOrderItemCondition } from '@orthly/graphql-schema';
import { ToothUtils } from '@orthly/items';
import _ from 'lodash';

export type SubmitterParams = {
    onComplete?: () => any;
};

export async function handleSuccess(params: SubmitterParams) {
    if (params.onComplete) {
        const output = params.onComplete();
        if (output instanceof Promise) {
            await output;
        }
    }
}

export const tryParseJson = (json: string): unknown => {
    try {
        return JSON.parse(json);
    } catch (e: any) {
        console.error(e);
    }
};

export const labOrderItemConditionIsValid = (src: any): src is LabsGqlLabOrderItemCondition => {
    if (!_.isPlainObject(src)) {
        return false;
    }
    if (Object.keys(src).length !== 1) {
        return false;
    }
    if (src.Or) {
        return src.Or.length > 1 && src.Or.every(labOrderItemConditionIsValid);
    }
    if (src.And) {
        return src.And.length > 1 && src.And.every(labOrderItemConditionIsValid);
    }
    if (src.Not) {
        return labOrderItemConditionIsValid(src.Not);
    }
    if (src.FieldValue) {
        return src.FieldValue.field_id !== '' && src.FieldValue.values.length > 0;
    }
    if (src.Sku) {
        return src.Sku.values.length > 0;
    }
    if (src.UnitType) {
        return src.UnitType.values.length > 0;
    }
    if (src.MaterialType) {
        return src.MaterialType.values.length > 0;
    }
    if (src.ToothGroups) {
        return (
            src.ToothGroups.values.length > 0 &&
            src.ToothGroups.values.every((group: any) => ToothUtils.isValidToothGroup(group))
        );
    }
    if (src.Shade) {
        return src.Shade.values.length > 0;
    }
    if (src.MaxUnitsPerItem) {
        return src.MaxUnitsPerItem > 0;
    }
    if (src.AbutmentPartManufacturer) {
        return src.AbutmentPartManufacturer.values.length > 0;
    }
    if (typeof src.AbutmentPartIsOEM === `boolean`) {
        return true;
    }
    if (src.ScanbodyManufacturer) {
        return src.ScanbodyManufacturer.values.length > 0;
    }
    return false;
};

export const labOrderConditionIsValid = (src: any): src is LabsGqlLabOrderCondition => {
    if (!_.isPlainObject(src)) {
        return false;
    }
    if (Object.keys(src).length !== 1) {
        return false;
    }
    if (src.Or) {
        return src.Or.length > 1 && src.Or.every(labOrderConditionIsValid);
    }
    if (src.And) {
        return src.And.length > 1 && src.And.every(labOrderConditionIsValid);
    }
    if (src.Not) {
        return labOrderConditionIsValid(src.Not);
    }
    if (src.Manufacturer) {
        return true;
    }
    if (src.Doctor) {
        return true;
    }
    if (src.AllItems) {
        return labOrderItemConditionIsValid(src.AllItems);
    }
    if (src.AnyItem) {
        return labOrderItemConditionIsValid(src.AnyItem);
    }
    if (src.PracticeTags) {
        return true;
    }
    if (typeof src.InternalDesign === `boolean`) {
        return true;
    }
    if (typeof src.MinUnitCount === `number`) {
        return true;
    }
    if (typeof src.MaxUnitCount === `number`) {
        return true;
    }
    if (typeof src.MinRefabCount === `number`) {
        return true;
    }
    return false;
};

const labOrderItemConditionMatchesSearchTerm = (rule: LabsGqlLabOrderItemCondition, searchTerm: string): boolean => {
    if (rule.Or) {
        return rule.Or.some(child => labOrderItemConditionMatchesSearchTerm(child, searchTerm));
    }
    if (rule.And) {
        return rule.And.some(child => labOrderItemConditionMatchesSearchTerm(child, searchTerm));
    }
    if (rule.Not) {
        // This returns false because we don't want to match something that a lab won't make
        return false;
    }
    if (rule.FieldValue) {
        return rule.FieldValue.values.some(value => value.toLowerCase().includes(searchTerm));
    }
    if (rule.MaterialType) {
        return rule.MaterialType.values.some(value => value.toLowerCase().includes(searchTerm));
    }
    if (rule.ToothGroups) {
        return rule.ToothGroups.values.some(value => value.toLowerCase().includes(searchTerm));
    }
    if (rule.Sku) {
        return rule.Sku.values.some(value => value.toLowerCase().includes(searchTerm));
    }
    if (rule.UnitType) {
        return rule.UnitType.values.some(value => value.toLowerCase().includes(searchTerm));
    }
    if (rule.AbutmentPartManufacturer) {
        return rule.AbutmentPartManufacturer.values.some(value => value.toLowerCase().includes(searchTerm));
    }
    if (rule.ScanbodyManufacturer) {
        return rule.ScanbodyManufacturer.values.some(value => value.toLowerCase().includes(searchTerm));
    }
    return false;
};

export const filterOrderItemConditionsBySearchTerms = (
    filterValues: string[],
    rule: { item_condition_json: string },
) => {
    if (!filterValues[0]) {
        return true;
    }
    const searchTerms = filterValues[0]
        .toLowerCase()
        .split(/\s+/)
        .filter(term => !!term);

    return searchTerms.some(searchTerm =>
        labOrderItemConditionMatchesSearchTerm(
            JSON.parse(rule.item_condition_json ?? '{}') as LabsGqlLabOrderItemCondition,
            searchTerm,
        ),
    );
};

const labOrderConditionMatchesSearchTerm = (ruleJsonString: string, searchTerm: string): boolean => {
    return ruleJsonString.toLowerCase().includes(searchTerm);
};

export const filterOrderConditionsBySearchTerms = (
    filterValues: string[],
    rule: { order_condition_json?: string; condition_json?: string },
) => {
    if (!filterValues[0]) {
        return true;
    }
    const searchTerms = filterValues[0]
        .toLowerCase()
        .split(/\s+/)
        .filter(term => !!term);

    return searchTerms.some(searchTerm =>
        labOrderConditionMatchesSearchTerm(rule.order_condition_json ?? rule.condition_json ?? '', searchTerm),
    );
};
