import type { ToolbarActionDefinition, ToolbarActionProps } from './ToolbarActionDefinition';
import type { LabsGqlCreateOrderHoldMutationVariables } from '@orthly/graphql-operations';
import { useCreateOrderHoldMutation } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { HoldUtils } from '@orthly/shared-types';
import { useChangeSubmissionFn, RootActionDialog } from '@orthly/ui';
import { EditHoldForm } from '@orthly/veneer';
import React from 'react';

const AddHoldToOrder: React.FC<ToolbarActionProps> = props => {
    type Vars = LabsGqlCreateOrderHoldMutationVariables['data'];
    const { order, open, setOpen } = props;
    const [submitMtn] = useCreateOrderHoldMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Order marked on hold!', {}],
        onSuccess: async () => {
            await props.refetchOrder();
            setOpen(false);
        },
    });
    if (order.status === LabsGqlLabOrderStatus.OnHold) {
        return null;
    }
    return (
        <RootActionDialog
            title={'Put Order On Hold'}
            loading={submitting}
            open={open}
            setOpen={setOpen}
            content={
                <EditHoldForm
                    can_show_practice={true}
                    show_only_lab_visible_categories={false}
                    onSubmit={async hold => {
                        await submit({
                            hold_details: HoldUtils.deconstructHold(hold),
                            holdReason: await HoldUtils.describeHold(hold),
                            show_hold_status_to_practice: hold.show_to_practice,
                            orderId: order.id,
                        });
                    }}
                    labOrder={order}
                />
            }
            buttonText={'Mark On Hold'}
            CustomButton={props.CustomButton}
        />
    );
};

export const PlaceOrderOnHoldToolbarActionDef: ToolbarActionDefinition = {
    label: 'Place on hold',
    disallowedStatuses: [
        LabsGqlLabOrderStatus.Delivered,
        LabsGqlLabOrderStatus.Cancelled,
        LabsGqlLabOrderStatus.NeedsRefabrication,
        LabsGqlLabOrderStatus.OnHold,
    ],
    Component: AddHoldToOrder,
    capabilityRequirement: 'order.hold.create',
};
