import { downloadAsCsv } from '@orthly/mui-table';
import { Text, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    downloadTemplate: {
        display: 'inline',
        cursor: 'pointer',
    },
}));

export const PartnerPricesV2DialogSubtitle: React.FC<{
    partnerPricesHaveBeenUploaded: boolean;
}> = ({ partnerPricesHaveBeenUploaded }) => {
    const classes = useStyles();
    if (partnerPricesHaveBeenUploaded) {
        return <>Confirm the list below before proceeding</>;
    } else {
        return (
            <Text variant={'body2'}>
                Upload{' '}
                <Text
                    variant={'body2'}
                    color={'PRIMARY_FOREGROUND'}
                    medium
                    onClick={() =>
                        downloadAsCsv(
                            [
                                {
                                    price_id: '',
                                    price_name: '',
                                    partner_id: '',
                                    partner_name: '',
                                    price_dollars: 0,
                                },
                            ],
                            `Partner Prices Bulk Upload Template`,
                        )
                    }
                    className={classes.downloadTemplate}
                >
                    this template
                </Text>{' '}
                to add partner prices in bulk.
            </Text>
        );
    }
};
