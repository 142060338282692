import type { ValidShadeType, IOrderItemV2DTO } from '@orthly/items';
import { AllValidShadeTypes } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import _ from 'lodash';

type RawRefabShadeUpdate = {
    shadeName: ValidShadeType;
    oldValue: string | null;
    newValue: string | null;
};

const getRawShadeUpdates = (
    itemId: string | undefined,
    oldItems: IOrderItemV2DTO[],
    newItems: IOrderItemV2DTO[],
): RawRefabShadeUpdate[] => {
    if (!itemId) {
        return [];
    }

    const oldItem = oldItems.find(item => item.id === itemId);
    const newItem = newItems.find(item => item.original_item_id === itemId);

    // If we couldn't find the old or new item, we return an empty list as we can't compare items as expected
    // This is expected to happen for orders that don't have the `original_item_id` set on their items
    if (!oldItem || !newItem) {
        return [];
    }

    return _.compact(
        AllValidShadeTypes.map(shadeNameToCheck => {
            const oldItemShade = oldItem?.shades.find(oldShade => oldShade.name === shadeNameToCheck);
            const newItemShade = newItem?.shades.find(newShade => newShade.name === shadeNameToCheck);

            // If the shade was not defined in either the old or new item we don't want to return it as an "update"
            if (!oldItemShade && !newItemShade) {
                return null;
            }

            return {
                shadeName: shadeNameToCheck,
                newValue: newItemShade?.value ?? null,
                oldValue: oldItemShade?.value ?? null,
            };
        }),
    );
};

export type FormattedShadeUpdate = {
    changeDescription: string;
    shadeName: string;
};

export const getFormattedShadeUpdates = (
    itemId: string | undefined,
    oldItems: IOrderItemV2DTO[],
    newItems: IOrderItemV2DTO[],
): FormattedShadeUpdate[] => {
    const rawShadeUpdates = getRawShadeUpdates(itemId, oldItems, newItems);

    return rawShadeUpdates.map(shadeUpdate => {
        if (shadeUpdate.oldValue === shadeUpdate.newValue) {
            return {
                changeDescription: `Keep as ${shadeUpdate.newValue}`,
                shadeName: `${Format.titleCase(shadeUpdate.shadeName)} shade`,
            };
        }

        return {
            changeDescription: `Modify shade for refab: ${shadeUpdate.newValue ?? 'undefined'}`,
            shadeName: `${Format.titleCase(shadeUpdate.shadeName)} shade`,
        };
    });
};
