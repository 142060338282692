import type { IBaseItemData } from '../../types';
import { getHeaderForLabSlipItem } from '../../utils/shared.utils';
import { BaseItem } from './BaseItem';
import type { IOtherItem } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

/**
 * Renders an "Other" order item to display on
 * the Printable Slip
 * @param props The order item to render
 * @returns Rendered order item details for the order "Other" item
 */
export const OtherItem: React.VFC<{ item: IOtherItem }> = ({ item }) => {
    return <BaseItem itemData={mapOtherItemToPrintableSlipData(item)} />;
};

/**
 * Pull data needed for printable slip display into a standard data structure
 * @param item IOtherItem order item to display
 * @returns Structured data for Other item display
 */
function mapOtherItemToPrintableSlipData(item: IOtherItem): IBaseItemData {
    const materialRow = item.unit.material !== 'N/A' && { key: 'Material', value: item.unit.material };
    return {
        headerLeft: getHeaderForLabSlipItem(item),
        itemFields: _.compact([materialRow]),
    };
}
