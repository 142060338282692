import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { OrderDetailTryInFeedback } from '@orthly/veneer';
import React from 'react';

const AdminOrderDetailPreviousTryInFeedbackLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailPreviousTryInFeedbackLabSalesOrder_Fragment on LabSalesOrderDTO {
        previous_order {
            continued_order_id
        }
    }
`);

interface OrderDetailPreviousTryInFeedbackProps {
    salesOrder: FragmentType<typeof AdminOrderDetailPreviousTryInFeedbackLabSalesOrder_Fragment>;
}

export const OrderDetailPreviousTryInFeedback: React.VFC<OrderDetailPreviousTryInFeedbackProps> = ({
    salesOrder: salesOrderProp,
}) => {
    const salesOrder = getFragmentData(AdminOrderDetailPreviousTryInFeedbackLabSalesOrder_Fragment, salesOrderProp);

    if (!salesOrder.previous_order.continued_order_id) {
        return null;
    }

    return <OrderDetailTryInFeedback orderId={salesOrder.previous_order.continued_order_id} isPreviousOrder={true} />;
};
