import type { CreditOrRefundWrapperProps } from './CreditOrRefund.types';
import { CreditOrRefundSidebar } from './CreditOrRefundSidebar';
import { CreditOrRefundFormCtxProvider } from './providers/CreditOrRefundFormProvider.graphql';
import { Drawer } from '@orthly/ui-primitives';
import React from 'react';

export const CreditOrRefundWrapper: React.VFC<CreditOrRefundWrapperProps> = ({
    open,
    setOpen,
    organizationId,
    order,
    invoiceItem,
    existingCreditId,
    refetchItems,
    refetchCredits,
}) => {
    return (
        <Drawer
            open={open}
            onClose={() => {
                setOpen(false);
            }}
            variant={'temporary'}
            anchor={'right'}
        >
            <CreditOrRefundFormCtxProvider
                setOpen={setOpen}
                organizationId={organizationId}
                order={order}
                invoiceItem={invoiceItem}
                existingCreditId={existingCreditId}
                refetchItems={refetchItems}
                refetchCredits={refetchCredits}
            >
                <CreditOrRefundSidebar />
            </CreditOrRefundFormCtxProvider>
        </Drawer>
    );
};
