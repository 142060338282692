import { MUITableUtils } from '../contexts/MUITableUtils';
import { useTableStateContext } from '../state/TableStateContext';
import type { Column, MUIDataObj, Row } from '../types';
import { MUITableMetaRow } from '../types';
import _ from 'lodash';
import React from 'react';

interface ITableDataContext<R extends MUIDataObj> {
    columns: Column<R>[];
    displayRows: MUITableMetaRow<R>[];
    allRows: MUITableMetaRow<R>[];
    loading: boolean;
    totalRowCount: number;
    getFilterOptions: (column: Column<R>) => string[];
}

const TableDataContext = React.createContext<ITableDataContext<any>>({
    columns: [],
    displayRows: [],
    allRows: [],
    loading: true,
    totalRowCount: 0,
    getFilterOptions: _column => {
        return [];
    },
});

interface TableDataContextProviderProps<R extends MUIDataObj> {
    data: R[];
    columns: Column<R>[];
    loading: boolean;
    children: React.ReactNode;
}

function getFilterValue<R extends MUIDataObj>(row: Row<R>, column: Column<R>): string | undefined {
    const cellValue = MUITableUtils.cellToPrimitive(row, column);
    switch (typeof cellValue) {
        case 'string':
            return cellValue;
        case 'bigint':
        case 'boolean':
        case 'number':
        case 'symbol':
            return cellValue.toString();
        case 'undefined':
            return undefined;
        default:
            return cellValue;
    }
}

const getFilterOptions = <R extends MUIDataObj>(column: Column<R>, data: Row<R>[]): string[] => {
    const filterType = MUITableUtils.filterTypeForColumn(column);
    if (!filterType) {
        return [];
    }
    if (filterType === 'search' || filterType === 'date') {
        return [];
    }
    if (column.filterOptions?.defaultOpts) {
        return column.filterOptions.defaultOpts;
    }
    if (column.getFilterOptions) {
        return column.getFilterOptions(data);
    }
    if (column.type === 'boolean') {
        return ['true', 'false'];
    }
    return _.compact(data.map(row => getFilterValue(row, column)));
};

const getDisplayRows = (data: any[], rowsPerPage: number, page: number) => {
    const start = rowsPerPage * page;
    const end = start + rowsPerPage;
    return [...data].slice(start, end);
};

export const TableDataContextProvider = <R extends MUIDataObj>(props: TableDataContextProviderProps<R>) => {
    const filterOptsByColumnName = React.useMemo<Record<string, string[]>>(() => {
        return _.fromPairs(
            props.columns.map<[string, string[]]>(column => [
                column.name,
                _.sortBy(_.uniq(getFilterOptions<R>(column, props.data))),
            ]),
        );
    }, [props.columns, props.data]);
    const tableState = useTableStateContext().state;
    const pagination = tableState.pagination;
    const allMetaRows = React.useMemo<MUITableMetaRow<R>[]>(() => {
        return props.data.map(row => new MUITableMetaRow<R>(row, props.columns || []));
    }, [props.columns, props.data]);
    const filteredRows = React.useMemo(() => {
        return MUITableUtils.filterRows(
            allMetaRows as MUITableMetaRow<any>[],
            {
                searchText: tableState.searchText,
                searchOpen: tableState.searchOpen,
                columnFilters: tableState.columnFilters,
            },
            props.columns,
        );
    }, [allMetaRows, props.columns, tableState.columnFilters, tableState.searchOpen, tableState.searchText]);
    const sortedFilteredRows = React.useMemo(() => {
        return MUITableUtils.sortRows(filteredRows, props.columns, tableState.sortColumn);
    }, [filteredRows, props.columns, tableState.sortColumn]);
    const displayRows = React.useMemo(() => {
        return getDisplayRows(sortedFilteredRows, pagination.rowsPerPage, pagination.page);
    }, [pagination.page, pagination.rowsPerPage, sortedFilteredRows]);
    return (
        <TableDataContext.Provider
            value={{
                displayRows,
                allRows: allMetaRows,
                columns: props.columns,
                loading: props.loading,
                totalRowCount: sortedFilteredRows.length,
                getFilterOptions: (column: Column<R>) => filterOptsByColumnName[column.name] ?? [],
            }}
        >
            {props.children}
        </TableDataContext.Provider>
    );
};
TableDataContextProvider.displayName = 'TableDataContextProvider';

export function useTableData() {
    return React.useContext(TableDataContext);
}
