import { OrderNeedsReviewAlert } from '../OrderDetailAlerts/OrderNeedsReviewAlert';
import type { AdminLabsOrderActionProps } from '../actions/order-action-types';
import { ReviewTaskDetailTracker } from '@orthly/dentin';
import { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { Divider } from '@orthly/ui-primitives';
import React from 'react';

export const ReviewTrackingBlock: React.VFC<AdminLabsOrderActionProps> = props => {
    const { order, refetchOrder } = props;
    const { active_task } = order.fulfillment_workflow;

    if (!active_task) {
        return null;
    }

    return (
        <>
            <div style={{ padding: `12px 0 0` }}>
                <ReviewTaskDetailTracker order={order} />
            </div>

            <Divider style={{ margin: `8px 0`, width: `100%` }} />
            {active_task.type === LabsGqlWorkflowTaskType.ReviewOrder && (
                <OrderNeedsReviewAlert order={order} refetchOrder={refetchOrder} variant={`detail_block`} />
            )}
        </>
    );
};
