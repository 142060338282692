import type { LabsGqlLabPricePartnerPricesResultFragment } from '@orthly/graphql-operations';
import type { LabsGqlPracticeNameAndPrimaryContactInfo } from '@orthly/graphql-schema';

export interface PartnerPricesV2CsvRow {
    price_id: string;
    price_name: string;
    partner_id: string;
    partner_name: string;
    price_dollars: number;
}

export enum PartnerPricesV2UploadValidationError {
    UNKNOWN_PRICE_ID = 'Unknown `price_id`',
    UNKNOWN_PARTNER_ID = 'Unknown `partner_id`',
    INVALID_PRICE_AMOUNT = 'Invalid `price_dollars`',
}

export interface PartnerPricesV2BulkUploaderProps {
    partnerData: LabsGqlPracticeNameAndPrimaryContactInfo[];
    partnerPricesData: LabsGqlLabPricePartnerPricesResultFragment[];
    partnerPricesHaveBeenUploaded: boolean;
    inputRows: PartnerPricesV2CsvRow[];
    setInputRows: (inputRows: PartnerPricesV2CsvRow[]) => void;
    setOpenBulkImport: (openBulkImport: boolean) => void;
}
