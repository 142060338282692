import { getChatVisibilityOrgTypes } from '@orthly/chat';
import type {
    LabsGqlOrder,
    LabsGqlOrderTimelineItemFragment,
    LabsGqlCreateChatMessageMutationVariables,
    LabsGqlSetChatMessageVisibilityMutationVariables,
    LabsGqlDeleteChatMessageMutationVariables,
    LabsGqlListChatMessagesQuery,
    LabsGqlChatMessageDtoFragment,
} from '@orthly/graphql-operations';
import {
    useCreateChatMessageMutation,
    useDeleteChatMessageMutation,
    useListChatMessagesQuery,
    useListLabOrgs,
    useSetChatMessageVisibilityMutation,
    useTimelineItemsQuery,
} from '@orthly/graphql-react';
import type { LabsGqlStaffRoleWithAny } from '@orthly/graphql-schema';
import { IOrganizationType } from '@orthly/retainer-common';
import { UuidUtils } from '@orthly/runtime-utils';
import { useChangeSubmissionFn } from '@orthly/ui';
import type { DandyChatMessage, TimelineEventProps } from '@orthly/veneer';
import { TimelineIcon, generateTimelineActorText } from '@orthly/veneer';
import moment from 'moment';
import React from 'react';

type DeleteChatMessageVar = LabsGqlDeleteChatMessageMutationVariables['data'];
type ChangeChatMessageVisibilityVars = Omit<
    LabsGqlSetChatMessageVisibilityMutationVariables['data'],
    'visible_to_org_ids'
>;
type CreateChatMessageVars = Omit<LabsGqlCreateChatMessageMutationVariables['data'], 'visible_to_org_ids'>;

export interface UseTimelineChatItemsRes {
    chatTimelineItems: TimelineEventProps[];
    refetch: () => Promise<unknown>;
    loading: boolean;
}

export function useTimelineChatItems(orderId: string, orderUpdatedAt?: string): UseTimelineChatItemsRes {
    const [lastFetchTime, setLastFetchTime] = React.useState<number>(new Date().valueOf());
    const {
        data: timelineData,
        loading,
        refetch,
    } = useTimelineItemsQuery({
        variables: { entityId: orderId, entityType: 'order' },
        skip: !UuidUtils.isUUID(orderId),
    });

    React.useEffect(() => {
        if (orderUpdatedAt && moment(orderUpdatedAt).isAfter(moment(lastFetchTime)) && !loading) {
            // account for the case where order updated date is in the future compared to local date
            setLastFetchTime(Math.max(new Date().valueOf(), new Date(orderUpdatedAt).valueOf()));
            if (new Date().valueOf() < new Date(orderUpdatedAt).valueOf()) {
                console.error(`orderUpdatedAt is in the future according to local time`);
            }
            refetch()?.catch(console.error);
        }
    }, [lastFetchTime, loading, orderUpdatedAt, refetch]);
    const chatTimelineItems = React.useMemo<TimelineEventProps[]>(
        // will be sorted in `useMixedTimeline`
        () => [
            ...(timelineData?.timelineItems ?? [])
                .filter((i): i is LabsGqlOrderTimelineItemFragment => i.__typename === 'OrderTimelineItem')
                .map<TimelineEventProps>(item => ({
                    date: new Date(item.date),
                    Icon: iconProps => {
                        return <TimelineIcon IconProps={iconProps} type={item.orderEvent} />;
                    },
                    title: item.title,
                    subtitle: item.subtitle.length > 0 ? item.subtitle.join('\n') : undefined,
                    action: !item.title_link
                        ? undefined
                        : { href: item.title_link.href, text: item.title_link.text, type: 'link' },
                    actor: generateTimelineActorText(item.actor),
                    attachments: item.attachments,
                })),
        ],
        [timelineData],
    );
    return { chatTimelineItems, loading, refetch };
}

type MinimalOrder = Pick<LabsGqlOrder, 'doctor_name' | 'manufacturer_id' | 'partner_id'>;

export type GetChatMessagesArgs = {
    orderId: string;
    order?: MinimalOrder;
    staffId: string;
};

interface VisibleNamesAndOrgTypes {
    visibleNames: string[];
    orgTypes: ReadonlySet<IOrganizationType>;
}

export function useChatMessages(args: GetChatMessagesArgs) {
    const { order, orderId, staffId } = args;

    const { data: cachedManufacturers } = useListLabOrgs({
        fetchPolicy: 'cache-first',
    });
    const getVisibleNamesAndOrgTypes = React.useCallback(
        (order: MinimalOrder | undefined, message: LabsGqlChatMessageDtoFragment): VisibleNamesAndOrgTypes => {
            if (!order) {
                return { visibleNames: [], orgTypes: new Set<IOrganizationType>() };
            }
            const orgTypesWithVisibility = getChatVisibilityOrgTypes(message, order);
            const visibleNames: string[] = [];
            if (orgTypesWithVisibility.has('practice')) {
                // Per TypeScript, order.doctor_name should always be defined, but I'm not 100% sure I trust that
                // assessment and will keep the prior behavior that uses "Doctor" when we don't have doctor_name.
                visibleNames.push(order.doctor_name ?? 'Doctor');
            }
            if (orgTypesWithVisibility.has('lab')) {
                const labName = cachedManufacturers?.listOrganizations.find(
                    org => org.id === order.manufacturer_id,
                )?.name;
                if (typeof labName === 'string') {
                    visibleNames.push(labName);
                }
            }
            // If there are more than one receiver, we will not render the INTERNAL
            if (visibleNames.length === 0 && orgTypesWithVisibility.has('internal')) {
                visibleNames.push('INTERNAL');
            }

            // We don't render anything for 'external' or 'parent'
            return { visibleNames, orgTypes: orgTypesWithVisibility };
        },
        [cachedManufacturers],
    );

    const { data: chatMessages, refetch } = useListChatMessagesQuery({
        variables: { entity_ids: [orderId] },
        skip: !UuidUtils.isUUID(orderId),
    });
    const messages = React.useMemo<LabsGqlListChatMessagesQuery['listChatMessages']>(
        () => chatMessages?.listChatMessages ?? [],
        [chatMessages],
    );

    const addChatFn = useSubmitOrderChatMessage(refetch);
    const deleteChatFn = useDeleteChatMessage(refetch);
    const changeVisibilityFn = useChangeChatMessageVisibility(refetch);

    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const data = React.useMemo<DandyChatMessage[]>(() => {
        return messages.map(message => {
            const senderRole = message.sender.organization_type;
            const { visibleNames: sentTo, orgTypes } = getVisibleNamesAndOrgTypes(order, message);
            const recipientTag =
                // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                // eslint-disable-next-line no-nested-ternary
                senderRole === IOrganizationType.external
                    ? '(from EXTERNAL)'
                    : message.created_by_staff_id === staffId || senderRole === IOrganizationType.internal
                      ? `(to ${sentTo})`
                      : undefined;
            const hideDeleteOption = message.deleted_at || senderRole !== IOrganizationType.internal;
            const onDeleteChat = hideDeleteOption
                ? undefined
                : async () => {
                      await deleteChatFn({ chat_id: message.id }).catch(console.error);
                  };

            // From the prior version of this logic:
            // "note must be either invisible to, or read by, each possible recipient"
            // ...but that really means lab and practice, since external orgs were not consistently added to
            // visible_to_org_ids, and we ignored DANDY_INTERNAL_ID anyway.
            const { manufacturer_id, partner_id } = order ?? {};
            const readByAllRecipients =
                (!orgTypes.has(IOrganizationType.lab) ||
                    (!!manufacturer_id && message.seen_by_org_ids.includes(manufacturer_id))) &&
                (!orgTypes.has(IOrganizationType.practice) ||
                    (!!partner_id && message.seen_by_org_ids.includes(partner_id)));

            // External orgs don't "count" here either
            const isInternal =
                orgTypes.size <= 2 && orgTypes.has('internal') && (orgTypes.size === 1 || orgTypes.has('external'));
            const hideChangeAttachmentVisibilityOption = !message.attachments || message.attachments.length < 1;
            const onChangeAttachmentVisibility = hideChangeAttachmentVisibilityOption
                ? undefined
                : async (visible_to_roles: LabsGqlStaffRoleWithAny[]) => {
                      await changeVisibilityFn({
                          visible_to_roles,
                          chat_id: message.id,
                      });
                  };
            const attachmentVisibility: IOrganizationType[] = Array.from(orgTypes).filter(
                orgType => orgType === IOrganizationType.lab || orgType === IOrganizationType.practice,
            );
            return {
                onDeleteChat,
                onChangeAttachmentVisibility,
                attachmentVisibility,
                readByAllRecipients,
                senderRole,
                username: message.sender
                    ? `${message.sender.user.first_name} ${message.sender.user.last_name}`
                    : `Deactivated`,
                recipient_tag: recipientTag,
                staffId: message.sender.id,
                date: new Date(message.created_at),
                text: message.text,
                attachments: message.attachments,
                deleted_at: message.deleted_at ? new Date(message.deleted_at) : null,
                isInternal,
            };
        });
    }, [messages, getVisibleNamesAndOrgTypes, staffId, deleteChatFn, changeVisibilityFn, order]);

    return { data, refetch, addChatFn };
}

export function useSubmitOrderChatMessage(refetchChat: () => Promise<any>) {
    const [submitMtn] = useCreateChatMessageMutation();
    const mtnSubmitter = (data: CreateChatMessageVars) => submitMtn({ variables: { data } });
    return useChangeSubmissionFn<any, [CreateChatMessageVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Chat message added!', {}],
        onSuccess: async () => {
            await refetchChat();
        },
    });
}

export function useDeleteChatMessage(refetchChat: () => Promise<any>) {
    const [deleteMtn] = useDeleteChatMessageMutation();
    const { submit } = useChangeSubmissionFn<any, [DeleteChatMessageVar]>(
        (data: DeleteChatMessageVar) => deleteMtn({ variables: { data } }),
        {
            onSuccess: async () => {
                await refetchChat();
            },
        },
    );
    return submit;
}

export function useChangeChatMessageVisibility(refetchChat: () => Promise<any>) {
    const [changeVisibilityMtn] = useSetChatMessageVisibilityMutation();
    return async (data: ChangeChatMessageVisibilityVars) => {
        await changeVisibilityMtn({ variables: { data } });
        await refetchChat();
    };
}
