import { useFeatureFlag } from '../Providers/LaunchDarkly';
import { useStaffMemberLoginProps } from '@orthly/session-client';
import { EmailPasswordLoginFields } from '@orthly/ui';
import React from 'react';

interface LoginFormProps {
    // used to pass the email along to the reset form in the case of failed login
    setEmail: (email: string) => void;
}

export const LoginForm: React.VFC<LoginFormProps> = ({ setEmail }) => {
    const { onSubmitLogin, loading, loginError, onPostSubmitLogin } = useStaffMemberLoginProps();
    const { value: enableAdminPostLogin } = useFeatureFlag('enableAdminPostLogin');
    const onSubmit = React.useCallback(
        (args: { email: string; password: string }) => {
            setEmail(args.email);
            enableAdminPostLogin ? onPostSubmitLogin(args).catch(() => {}) : onSubmitLogin(args);
        },
        [onPostSubmitLogin, onSubmitLogin, enableAdminPostLogin, setEmail],
    );
    return (
        <EmailPasswordLoginFields
            containerStyle={{ padding: 0 }}
            loading={loading}
            onSubmit={onSubmit}
            error={loginError}
        />
    );
};
