import { useArchiveOrderAutomationMutation } from '@orthly/graphql-react';
import { LoadBlocker } from '@orthly/ui';
import { makeStyles, createStyles, Switch } from '@orthly/ui-primitives';
import React from 'react';

const commonTrackAfter: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    height: '100%',
    fontWeight: 500,
    color: '#fff',
};

const useStyles = makeStyles(() =>
    createStyles({
        trackOff: {
            borderRadius: 25,
            '&:after': {
                ...commonTrackAfter,
                content: '"OFF"',
                justifyContent: 'flex-end',
                paddingRight: 8,
            },
        },
        trackOn: {
            borderRadius: 25,
            '&:after': {
                ...commonTrackAfter,
                content: '"ON"',
                paddingLeft: 8,
            },
        },
        root: {
            padding: 4,
            width: 68,
            height: 38,
        },
        switchBase: {
            transform: 'translateX(-6px)',
            '&.Mui-checked': {
                transform: 'translateX(24px)',
            },
        },
        thumb: {
            width: 20,
            height: 20,
            borderRadius: 25,
        },
    }),
);

interface ArchiveAutomationSwitchProps {
    automationId: string;
    archived: boolean;
    onComplete: (archived: boolean) => void;
}

export const ArchiveAutomationSwitch: React.FC<ArchiveAutomationSwitchProps> = props => {
    const { automationId, archived, onComplete } = props;
    const classes = useStyles();
    const [submit, { loading }] = useArchiveOrderAutomationMutation({
        variables: { id: automationId, archived: !archived },
        onCompleted: data => {
            if (data) {
                onComplete(data.archiveOrderAutomation.archived);
            }
        },
    });

    return (
        <LoadBlocker blocking={loading} ContainerProps={{ style: { width: 'auto' } }}>
            <Switch
                size={'medium'}
                color={'secondary'}
                checked={!archived}
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    track: !archived ? classes.trackOn : classes.trackOff,
                    thumb: classes.thumb,
                }}
                disabled={loading}
                onChange={() => {
                    const newState = archived ? 'ON' : 'OFF';
                    const firstMessage = `Turn automation ${newState}?`;
                    const secondMessage = `Are you 100% certain automation should be turned ${newState}?`;
                    if (window.confirm(firstMessage) && window.confirm(secondMessage)) {
                        void submit();
                    }
                }}
            />
        </LoadBlocker>
    );
};
