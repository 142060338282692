import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { LabsGqlLabSalesOrderServiceSpeed } from '@orthly/graphql-schema';
import { FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const AdminOrderDetailAdditionalInfoSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailAdditionalInfoSalesOrder_Fragment on LabSalesOrderDTO {
        service_level {
            speed
        }
    }
`);

const Wrapper = styled('div')<{ speed: string }>(({ speed }) => ({
    marginLeft: '-24px',
    width: 'calc(100% + 48px)',
    padding: '8px 16px',
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 80,
    containerType: 'inline-size',
    backgroundColor:
        speed === LabsGqlLabSalesOrderServiceSpeed.Rush ? FlossPalette.SECONDARY_BACKGROUND : FlossPalette.GRAY,
}));

const StyledText = styled(Text)({
    margin: 'auto 0px',
});

interface OrderDetailStatusShippingSpeedBannerContentProps {
    speed: LabsGqlLabSalesOrderServiceSpeed | null | undefined;
}

export const OrderDetailStatusSpeedShippingBannerContent: React.VFC<
    OrderDetailStatusShippingSpeedBannerContentProps
> = ({ speed }) => {
    if (speed === LabsGqlLabSalesOrderServiceSpeed.Slow) {
        // TODO: EPDPOR-2182 update this days amount with a real value that is stored in the backend.
        // We don't presently store this, but will once the value stops being static.
        const delayDays = 4;
        return (
            <Wrapper speed={speed}>
                <StyledText variant={'body2'} color={'WHITE'}>
                    This doctor has opted into <strong>no-rush shipping</strong>
                </StyledText>
                <StyledText variant={'caption'} color={'WHITE'}>
                    This will result in a {delayDays}-day delay from the regular ETA
                </StyledText>
            </Wrapper>
        );
    }
    if (speed === LabsGqlLabSalesOrderServiceSpeed.Rush) {
        return (
            <Wrapper speed={speed}>
                <StyledText variant={'body2'} color={'SECONDARY_FOREGROUND'} medium>
                    This is a rush order that needs to ship with next day air
                </StyledText>
            </Wrapper>
        );
    }
    return null;
};

interface OrderDetailStatusShippingSpeedBannerProps {
    salesOrderFragment: FragmentType<typeof AdminOrderDetailAdditionalInfoSalesOrder_Fragment>;
}

export const OrderDetailStatusShippingSpeedBanner: React.VFC<OrderDetailStatusShippingSpeedBannerProps> = ({
    salesOrderFragment,
}) => {
    const salesOrder = getFragmentData(AdminOrderDetailAdditionalInfoSalesOrder_Fragment, salesOrderFragment);
    const speed = salesOrder?.service_level.speed;
    return <OrderDetailStatusSpeedShippingBannerContent speed={speed} />;
};
