import { DesignOrderLatestRawRevisionsDownload } from '../DesignOrder/DesignOrderRevisionDownloads/DesignOrderLatestRawRevisionsDownload.graphql';
import { DesignOrderRevisionManufacturerFilesDownload } from '../DesignOrder/DesignOrderRevisionDownloads/DesignOrderRevisionManufacturerFilesDownload.graphql';
import { LabSlipV2 } from '../LabSlip';
import { usePrintableState } from '../PrintableSlip';
import { DownloadItem, ScanDownloadItems } from './DownloadItem';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrder } from '@orthly/graphql-react';
import { LabsGqlProductLine } from '@orthly/graphql-schema';
import { CircularProgress, Divider, Menu, MenuItem } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const getDesignFilesDownloadButtons = (order?: LabsGqlOrder): React.ReactNode[] => {
    if (!order) {
        return [];
    }

    return [
        <DesignOrderLatestRawRevisionsDownload
            Button={({ onClick }) => (
                <MenuItem disabled={!order.design_file_path} key={'download_design_original'} onClick={onClick}>
                    Design file
                </MenuItem>
            )}
            orderIds={[order.id]}
            key={'download_design_original'}
        />,

        <DesignOrderRevisionManufacturerFilesDownload
            orderIds={[order.id]}
            rotated={false}
            key={'download_manufacturer_files'}
            Button={({ onClick }) => (
                <MenuItem
                    onClick={() => {
                        BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Order Files Menu Used', {
                            $groups: { order: order.id },
                            action: 'download_manufacturer_files',
                        });

                        onClick();
                    }}
                >
                    Manufacturer Files
                </MenuItem>
            )}
        />,

        <DesignOrderRevisionManufacturerFilesDownload
            orderIds={[order.id]}
            rotated={true}
            key={'download_manufacturer_files_rotated'}
            Button={({ onClick }) => (
                <MenuItem
                    onClick={() => {
                        BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Order Files Menu Used', {
                            $groups: { order: order.id },
                            action: 'download_manufacturer_files_rotated',
                        });

                        onClick();
                    }}
                >
                    Manufacturer Files (Rotated)
                </MenuItem>
            )}
        />,

        <DesignOrderRevisionManufacturerFilesDownload
            orderIds={[order.id]}
            rotated={false}
            key={'download_injection_mold_files'}
            Button={({ onClick }) => (
                <MenuItem
                    disabled={!order.design_file_path || order?.product_line !== LabsGqlProductLine.Partial}
                    onClick={() => {
                        BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Order Files Menu Used', {
                            $groups: { order: order.id },
                            action: 'download_manufacturer_files',
                        });

                        onClick();
                    }}
                >
                    Injection Mold Files
                </MenuItem>
            )}
            returnDesignMetadata={true}
        />,
    ];
};

interface OrderFilesMenuProps {
    orderId: string;
    order?: LabsGqlOrder;
    anchorEl: HTMLElement | null;
    onClose: () => void;
}

export const OrderFilesMenu: React.FC<OrderFilesMenuProps> = props => {
    const { anchorEl: anchor, onClose, children } = props;
    const { order: orderFromQuery } = useOrder(props.orderId, {
        skip: !anchor || !!props.order,
        fetchPolicy: 'cache-first',
    });
    const order = props.order ?? orderFromQuery;
    const scan_export = order?.scan_export;

    const [replacedScansMenuAnchor, setReplacedScansMenuAnchor] = React.useState<HTMLElement | null>();
    const openReplacedScansMenu = React.useCallback(() => {
        setReplacedScansMenuAnchor(anchor);
        onClose();
    }, [anchor, onClose]);
    const closeReplacedScansMenu = React.useCallback(() => setReplacedScansMenuAnchor(null), []);

    const task = order?.fulfillment_workflow?.active_task;
    const preppedDesignFilePath =
        task && task.__typename === 'InternalDesignWorkflowTask' ? task.configuration.prepped_design_file_path : null;
    const { printableIsOpen, openPrintable, closePrintable } = usePrintableState();

    return (
        <>
            <Menu
                open={!!anchor}
                anchorEl={anchor}
                onClose={() => onClose()}
                MenuListProps={{ style: { position: 'relative' } }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                <MenuItem
                    onClick={() => {
                        BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Order Files Menu Used', {
                            $groups: { order: order?.id ?? '' },
                            action: 'download_order_form',
                        });
                        openPrintable();
                    }}
                >
                    Lab Slip (aka Order form)
                </MenuItem>
                {scan_export && order && (
                    <ScanDownloadItems order={order} hasOrderBeenPrepped={!!preppedDesignFilePath} scan={scan_export} />
                )}
                <DownloadItem
                    url={order?.cbct_url}
                    filename={`CBCT-${order?.patient.first_name}-${order?.patient.last_name}.zip`}
                    title={<>CBCT Scan</>}
                    orderId={order?.id ?? ''}
                    actionName={'download_cbct'}
                />

                <Divider />
                {getDesignFilesDownloadButtons(order)}

                {(order?.replaced_scan_exports.length ?? 0) > 0 && [
                    <Divider key={0} />,
                    <MenuItem
                        onClick={() => {
                            BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Order Files Menu Used', {
                                $groups: { order: order?.id ?? '' },
                                action: 'download_old_scans',
                            });

                            return openReplacedScansMenu();
                        }}
                        key={1}
                    >
                        Old scans
                    </MenuItem>,
                ]}
                {children}
            </Menu>
            <Menu
                open={!anchor && !!replacedScansMenuAnchor}
                anchorEl={replacedScansMenuAnchor}
                onClose={closeReplacedScansMenu}
                MenuListProps={{ style: { position: 'relative' } }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                {order?.replaced_scan_exports.map((scan_export, index) =>
                    _.compact([
                        index > 0 && <Divider key={0} />,
                        <ScanDownloadItems
                            order={order}
                            scan={scan_export}
                            replacement={true}
                            hasOrderBeenPrepped={!!preppedDesignFilePath}
                            key={1}
                        />,
                    ]),
                )}
            </Menu>
            {printableIsOpen && (
                <LabSlipV2
                    customLoader={<CircularProgress size={20} color={'primary'} variant={'indeterminate'} />} // hide the big "Loading..." text
                    orderIds={[props.orderId]}
                    autoPrintEnabled={true}
                    onPrintComplete={closePrintable}
                />
            )}
        </>
    );
};
