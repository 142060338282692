import type { TaskButtonProps } from './TaskButton';
import { TaskButtonBase } from './TaskButton';
import { useStartFulfillmentTaskMutation } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { useChangeSubmissionFn, LoadBlocker } from '@orthly/ui';
import { Icon } from '@orthly/ui-primitives';
import React from 'react';

export const StartTaskButton: React.FC<TaskButtonProps> = props => {
    const { refetch, task, buttonProps } = props;
    const { id: task_id, assignee, lab_order_id: order_id } = task;
    const last_user_id = assignee?.assigned_user.id ?? null;
    const myself_id = useSession()?.user.id;
    const [rawStartTask] = useStartFulfillmentTaskMutation();
    const { submit: startTask, submitting: startingTask } = useChangeSubmissionFn(rawStartTask, {
        successMessage: () => [`Task started`, {}],
        onError: () => refetch(),
        onSuccess: () => refetch(),
    });

    const maybeConfirmReassignment = () => {
        if (!assignee || last_user_id === myself_id) {
            return true;
        }
        const { first_name, last_name } = assignee.assigned_user;
        return window.confirm(
            `This task is assigned to ${first_name} ${last_name}, are you sure you want to reassign it to yourself?`,
        );
    };

    return (
        <TaskButtonBase
            tooltip={last_user_id === myself_id ? `Start task` : `Assign to me and start`}
            disabled={!!task.started_at || !!task.closeout}
            onClick={() => maybeConfirmReassignment() && startTask({ variables: { last_user_id, order_id, task_id } })}
            {...buttonProps}
        >
            <LoadBlocker blocking={startingTask}>
                <Icon icon={'PlayCircleFilledIcon'} />
            </LoadBlocker>
        </TaskButtonBase>
    );
};
