import type { ToolButtonProps } from './Types';
import { Icon } from '@orthly/ui';
import { IconToggleButton, FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        height: 40,
        width: 40,
        borderRadius: 'unset',
        '&.active': {
            backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
            color: FlossPalette.PRIMARY_FOREGROUND,
        },
    },
}));

export const ToolButton: React.VFC<ToolButtonProps> = ({ iconName, tooltip, onClick, active, disabled }) => {
    const classes = useStyles();

    return (
        <IconToggleButton
            className={classes.root}
            tooltip={tooltip}
            onClick={onClick}
            active={active ?? false}
            disabled={disabled}
        >
            <Icon icon={iconName} />
        </IconToggleButton>
    );
};
