import { getFilteredExtraOptions } from '../utils/changedItemsUtils';
import type { OrderEditPage } from './types';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { GalleryMultiSelect, type GallerySelectOption, ProductUnitTypeImages } from '@orthly/dentin';
import type { ProductUnitType, ICartItemV2DTOWithId } from '@orthly/items';
import { CartItemV2Utils, CartItemV2CreateUtils } from '@orthly/items';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import _ from 'lodash';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

interface OrderEditExtrasPageProps {
    itemGroups: ArrayMin1<ICartItemV2DTOWithId>[];
    addedExtraIds: string[];
    setGroupSelected: (group: ICartItemV2DTOWithId[], selected: boolean) => void;
    setEditableItems: React.Dispatch<React.SetStateAction<ICartItemV2DTOWithId[]>>;
    setAddedExtraIds: React.Dispatch<React.SetStateAction<string[]>>;
    orderId: string;
}

const ExtrasPageContent: React.VFC<OrderEditExtrasPageProps> = props => {
    const { itemGroups, addedExtraIds, setEditableItems, setGroupSelected, setAddedExtraIds, orderId } = props;
    const orderItems = _.flatten(itemGroups);

    // Separate the original items from the order, and the extras added during C/R
    const [originalItems, existingExtras] = _.partition(orderItems, item => !addedExtraIds.includes(item.id));

    // Maintain the user's extra selections for when they navigate back to this page
    const [selectedExtras, setSelectedExtras] = React.useState<ProductUnitType[]>(
        existingExtras.map(CartItemV2Utils.getProductUnitType),
    );
    const [removedExtras, setRemovedExtras] = React.useState<string[]>([]);

    // Extras are based on the original items from the order & exclude models (since they're not editable)
    const baseOptions = getFilteredExtraOptions(originalItems);
    const allOptions: GallerySelectOption<ProductUnitType>[] = baseOptions.map(option => ({
        image: ProductUnitTypeImages.Large[option.unit_type],
        value: option.unit_type,
        ...option,
    }));

    const addItems = React.useCallback(
        (selectedUnits: ProductUnitType[]) => {
            // Filter selections if we already added them
            const existingUnitTypes = orderItems.map(item => CartItemV2Utils.getPrimaryUnitType(item));
            const filteredSelection = selectedUnits.filter(unitType => !existingUnitTypes.includes(unitType));

            // Build the newly selected units into items
            const selectedExtraItems = filteredSelection.flatMap(unit => {
                const item = CartItemV2CreateUtils.buildNewItem(unit, null);
                return item ? { ...item, id: uuidv4() } : [];
            });

            // Keep track of which extras should be removed
            const removedIds = orderItems
                .filter(item => removedExtras.includes(CartItemV2Utils.getPrimaryUnitType(item)))
                .map(item => item.id);

            // Finally update the order item list
            setEditableItems(items => {
                const newCart = [...items.filter(item => !removedIds.includes(item.id)), ...selectedExtraItems];

                // If nothing changed, don't update state
                if (newCart.length === items.length) {
                    return items;
                }
                // Select the items for edits, update the list of extras, and return the new cart
                setGroupSelected(selectedExtraItems, true);
                setAddedExtraIds(extraIds => [
                    ...extraIds.filter(id => !removedIds.includes(id)),
                    ...selectedExtraItems.map(item => item.id),
                ]);
                return newCart;
            });
        },
        [orderItems, removedExtras, setEditableItems, setGroupSelected, setAddedExtraIds],
    );

    // Since the addItems function depends on the local state of selected extras / removed extras,
    // We ensure that we call addItems only after both local states have updated, by using this effect
    React.useEffect(() => {
        addItems(selectedExtras);
    }, [selectedExtras, addItems]);

    const trackAddition = (newExtras: ProductUnitType[]) => {
        const oldExtras = selectedExtras;
        const addedExtraItemType = _.difference(newExtras, oldExtras)[0];
        if (addedExtraItemType) {
            BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Cancel & Resubmit - Extra item added', {
                $groups: { order: orderId },
                addedExtraItemType,
            });
        }
    };
    React.useEffect(() => {
        BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Cancel & Resubmit - Extra screen viewed', {
            $groups: { order: orderId },
        });
    }, [orderId]);

    return (
        <div data-testid={'order-edit-extras-page'}>
            <GalleryMultiSelect
                options={allOptions}
                selectedValues={selectedExtras}
                updateSelectedValues={selectedUnits => {
                    // Store all options that were selected & not selected
                    trackAddition(selectedUnits);
                    setSelectedExtras(selectedUnits);
                    setRemovedExtras(
                        allOptions.map(option => option.value).filter(unit => !selectedUnits.includes(unit)),
                    );
                }}
            />
        </div>
    );
};

export function getExtrasPage(props: OrderEditExtrasPageProps): OrderEditPage {
    return {
        supertitle: 'Add an item',
        title: 'Need anything else?',
        Content: <ExtrasPageContent {...props} />,
        nextIsSkip: false,
        type: 'extras',
    };
}
