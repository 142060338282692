import { useAskDoctorAboutHoldDialogV2 } from '../OrderDetailToolbar/OrderDetailToolbarActions/AskDoctorV2/AskDoctorV2Dialog';
import { OpsCancelOrderAction } from '../OrderDetailToolbar/OrderDetailToolbarActions/CancelOrderToolbarAction.graphql';
import { useResolveScanRejectionDialog } from './ResolveScanRejectionDialog';
import { useQuery } from '@apollo/client';
import { ScanReviewStatusEnumToLabelMap } from '@orthly/dentin';
import type { AdminResolveScanRejectionActionCard_QueryQuery } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import type {
    LabsGqlOrder,
    LabsGqlWorkflowTask_ResolveScanRejectionWorkflowTask_Fragment as ResolveScanRejectionWorkflowTask,
} from '@orthly/graphql-operations';
import { LabsGqlDoctorRequestInitiatingFlow, LabsGqlScanReviewWorkOrderStatus } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import type { ActionCardButtonPropsBase } from '@orthly/ui';
import { ActionCard, Typography } from '@orthly/ui';
import { Grid, Menu, MenuItem, Button } from '@orthly/ui-primitives';
import { AttachmentPreview } from '@orthly/veneer';
import React from 'react';

interface ScanRejectionImagesProps {
    scanReview: AdminResolveScanRejectionActionCard_QueryQuery['getActiveScanReviewByLabOrderId'];
    open: boolean;
    setOpen: (open: boolean) => void;
}

const ScanRejectionImages: React.FC<ScanRejectionImagesProps> = props => {
    const { scanReview, open, setOpen } = props;

    if (!scanReview?.rejection_metadata.attachment_gcs_file_paths) {
        return null;
    }

    return (
        <AttachmentPreview
            open={open}
            setOpen={setOpen}
            title={'Scan issue files'}
            sources={scanReview.rejection_metadata.attachment_gcs_file_paths.map((source, idx) => ({
                source,
                name: `Scan issue #${idx + 1}`,
            }))}
            buttonText={''}
            CustomButton={() => null}
        />
    );
};

interface SecondaryMenuProps {
    scanReview: AdminResolveScanRejectionActionCard_QueryQuery['getActiveScanReviewByLabOrderId'];
    setFilesOpen: (open: boolean) => void;
    setShareDialogV2Open: (open: boolean) => void;
}

const SecondaryMenu: React.VFC<SecondaryMenuProps> = ({ scanReview, setFilesOpen, setShareDialogV2Open }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const hasAttachments = !!scanReview?.rejection_metadata.attachment_gcs_file_paths?.length;
    const canShareWithDoctor = scanReview?.status === LabsGqlScanReviewWorkOrderStatus.InReview;

    return (
        <div>
            <Button
                variant={'ghost'}
                onClick={event => {
                    setAnchorEl(event.currentTarget ?? null);
                }}
                endIcon={'KeyboardArrowDownIcon'}
            >
                Other actions
            </Button>
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                elevation={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        setFilesOpen(true);
                        handleClose();
                    }}
                    disabled={!hasAttachments}
                >
                    {hasAttachments ? 'View files' : 'No files'}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setShareDialogV2Open(true);

                        handleClose();
                    }}
                    disabled={!canShareWithDoctor}
                >
                    Ask doctor
                </MenuItem>
            </Menu>
        </div>
    );
};

interface ResolveScanRejectionActionCardProps {
    task: ResolveScanRejectionWorkflowTask;
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
}

const ScanRejectionStatusToTitleMap: Record<LabsGqlScanReviewWorkOrderStatus, string | null> = {
    [LabsGqlScanReviewWorkOrderStatus.AwaitingDoctorResponse]: `This order is on hold pending doctor's response to flagged scan`,
    [LabsGqlScanReviewWorkOrderStatus.InReview]: 'This order is on hold due to scan being flagged',
    [LabsGqlScanReviewWorkOrderStatus.Cancelled]: null,
    [LabsGqlScanReviewWorkOrderStatus.Resolved]: null,
    [LabsGqlScanReviewWorkOrderStatus.DoctorResponseReceived]: `This order is on hold pending the doctor providing more information after responding to our doctor request survey.`,
};

const ResolveScanRejectionActionCardSubtitleSection: React.FC<{
    title: string;
}> = ({ title, children }) => {
    if (!children) {
        return null;
    }

    return (
        <Typography variant={'body2'} style={{ width: '100%' }}>
            <span style={{ fontWeight: 'bold' }}>{title}:</span> {children}
        </Typography>
    );
};

const ResolveScanRejectionActionCardSubtitle: React.FC<{
    scanReview: AdminResolveScanRejectionActionCard_QueryQuery['getActiveScanReviewByLabOrderId'];
}> = ({ scanReview }) => {
    const doctorResponse = scanReview?.current_doctor_request?.doctor_request_response;
    const doctorChoice = scanReview?.current_doctor_request?.options?.find(
        opt => opt.id === doctorResponse?.selected_doctor_request_option_id,
    );
    const categories =
        scanReview?.rejection_metadata.categories.map(
            category => ScanReviewStatusEnumToLabelMap[category] ?? category,
        ) ?? [];

    return (
        <Grid container>
            {scanReview?.status !== LabsGqlScanReviewWorkOrderStatus.DoctorResponseReceived && (
                <>
                    <Typography variant={'body2'} medium style={{ width: '100%' }}>
                        {Format.pluralize('Category', categories.length, 'Categories')}: {categories.join(', ')}
                    </Typography>

                    <ResolveScanRejectionActionCardSubtitleSection title={'Internal Notes'}>
                        {scanReview?.rejection_metadata.internal_notes}
                    </ResolveScanRejectionActionCardSubtitleSection>

                    {!!scanReview?.current_doctor_request?.options.length && (
                        <ResolveScanRejectionActionCardSubtitleSection title={'Doctor Options'}>
                            {(scanReview?.current_doctor_request?.options ?? []).map((opt, idx) => (
                                <Typography variant={'body2'} key={idx}>
                                    - {opt.label}
                                </Typography>
                            ))}
                        </ResolveScanRejectionActionCardSubtitleSection>
                    )}

                    <ResolveScanRejectionActionCardSubtitleSection title={'Notes for Doctor'}>
                        {scanReview?.current_doctor_request?.question_text ??
                            scanReview?.rejection_metadata.notes_for_doctor}
                    </ResolveScanRejectionActionCardSubtitleSection>
                </>
            )}
            {!!doctorChoice && (
                <Typography
                    variant={'body2'}
                    style={{ width: '100%' }}
                >{`Doctor choice: ${doctorChoice.label}`}</Typography>
            )}
            {doctorResponse?.freeform_notes && (
                <Typography
                    variant={'body2'}
                    style={{ width: '100%' }}
                >{`Notes from doctor: ${doctorResponse.freeform_notes}`}</Typography>
            )}
        </Grid>
    );
};

const AdminResolveScanRejectionActionCard_Query = graphql(`
    query AdminResolveScanRejectionActionCard_Query($lab_order_id: String!) {
        getActiveScanReviewByLabOrderId(lab_order_id: $lab_order_id) {
            rejection_metadata {
                categories
                internal_notes
                notes_for_doctor
                severity_score
                attachment_gcs_file_paths
            }
            current_doctor_request {
                question_text
                options {
                    id
                    label
                }
                doctor_request_response {
                    selected_doctor_request_option_id
                    freeform_notes
                }
            }
            status
        }
    }
`);

export const ResolveScanRejectionActionCard: React.FC<ResolveScanRejectionActionCardProps> = props => {
    const { order, refetchOrder, task } = props;

    const { data: scanRejectionData, refetch } = useQuery(AdminResolveScanRejectionActionCard_Query, {
        variables: {
            lab_order_id: order.id,
        },
    });
    const scanRejection = scanRejectionData?.getActiveScanReviewByLabOrderId;

    const issues =
        scanRejection?.rejection_metadata.categories.map(
            category => ScanReviewStatusEnumToLabelMap[category] ?? category,
        ) ?? [];

    const [setAskDoctorDialogV2Open, askDoctorDialogV2] = useAskDoctorAboutHoldDialogV2({
        order,
        refetchOrder: async () => {
            await refetch();
            await refetchOrder();
        },
        initiatingFlow: LabsGqlDoctorRequestInitiatingFlow.ScanRejection,
        issues: issues,
        notesForDoctor: scanRejection?.rejection_metadata.notes_for_doctor ?? null,
    });
    const [cancelDialogOpen, setCancelDialogOpen] = React.useState<boolean>(false);
    const [filesOpen, setFilesOpen] = React.useState<boolean>(false);
    const [setResolveDialogOpen, resolveDialog] = useResolveScanRejectionDialog({
        task,
        order,
        refetchOrder,
        CustomButton: () => null,
        uploadNewScanUnchecked: false,
    });
    const primaryAction = React.useMemo<ActionCardButtonPropsBase>(() => {
        return {
            onClick: () => setResolveDialogOpen(true),
            text: 'Resolve',
            endIcon: 'CheckIcon',
        };
    }, [setResolveDialogOpen]);
    const secondaryAction = (
        <SecondaryMenu
            scanReview={scanRejection}
            setFilesOpen={setFilesOpen}
            setShareDialogV2Open={setAskDoctorDialogV2Open}
        />
    );
    const title = scanRejection?.status ? ScanRejectionStatusToTitleMap[scanRejection.status] : null;

    if (!scanRejection) {
        return null;
    }

    return (
        <>
            <ActionCard
                variant={'alert'}
                title={title}
                subtitle={<ResolveScanRejectionActionCardSubtitle scanReview={scanRejection} />}
                primaryAction={primaryAction}
                secondaryAction={secondaryAction}
            />
            <ScanRejectionImages scanReview={scanRejection} open={filesOpen} setOpen={setFilesOpen} />
            {resolveDialog}
            {askDoctorDialogV2}
            <OpsCancelOrderAction
                open={cancelDialogOpen}
                setOpen={setCancelDialogOpen}
                order={order}
                refetchOrder={refetchOrder}
                CustomButton={() => null}
            />
        </>
    );
};
