import { ListEntityCombinatorField } from '../../screens/Automations/screens/AutomationBuilder/AutomationFilterStep/criteria-fields/ListEntityCombinatorField';
import { OrderFilterComparatorField } from '../../screens/Automations/screens/AutomationBuilder/AutomationFilterStep/criteria-fields/OrderFilterComparatorField';
import { OrderFilterComparisonValueField } from '../../screens/Automations/screens/AutomationBuilder/AutomationFilterStep/criteria-fields/OrderFilterComparisonValueField';
import { OrderFilterRuleField } from '../../screens/Automations/screens/AutomationBuilder/AutomationFilterStep/criteria-fields/OrderFilterRuleField';
import type { UnsavedFilterCriteria } from './OrderFilterBuilder.types';
import type { LabsGqlOrderFilterRuleFragment } from '@orthly/graphql-operations';
import { LabsGqlFilterComparator } from '@orthly/graphql-schema';
import { TrashIcon } from '@orthly/ui';
import { Grid, IconButton } from '@orthly/ui-primitives';
import React from 'react';

interface OrderFilterCriteriaRowProps {
    filterDefinitions: LabsGqlOrderFilterRuleFragment[];
    criteria: UnsavedFilterCriteria;
    onChange: (partial: UnsavedFilterCriteria) => void;
    onRuleFieldChange: (partial: UnsavedFilterCriteria) => void;
    onRemove: () => void;
}

export const OrderFilterCriteriaRow: React.FC<OrderFilterCriteriaRowProps> = props => {
    const { filterDefinitions, criteria, onChange, onRuleFieldChange, onRemove } = props;
    const selectedFilter = React.useMemo(
        () => filterDefinitions.find(f => f.id === criteria.filter_id),
        [criteria.filter_id, filterDefinitions],
    );
    const isDaysComparator =
        criteria.comparator === LabsGqlFilterComparator.DaysBefore ||
        criteria.comparator === LabsGqlFilterComparator.DaysAfter;

    return (
        <Grid container spacing={2} alignItems={'center'} wrap={'nowrap'} style={{ paddingBottom: 16 }}>
            <ListEntityCombinatorField
                criteria={criteria}
                onChange={onChange}
                filterListEntityName={selectedFilter?.list_entity_name ?? undefined}
            />
            <Grid container item xs={3} alignItems={'center'}>
                <OrderFilterRuleField
                    currentCriteria={criteria}
                    onChange={onRuleFieldChange}
                    rules={filterDefinitions}
                />
            </Grid>
            <Grid container item xs={isDaysComparator ? 4 : 2} alignItems={'center'}>
                <OrderFilterComparatorField
                    comparator={criteria.comparator}
                    onChange={onChange}
                    selectedFilter={selectedFilter}
                />
            </Grid>
            <Grid
                container
                item
                xs={!selectedFilter?.list_entity_name && !isDaysComparator ? 6 : 4}
                alignItems={'center'}
            >
                <OrderFilterComparisonValueField
                    comparator={criteria.comparator ?? LabsGqlFilterComparator.Disabled}
                    value={criteria.comparison_value}
                    selectedFilter={selectedFilter}
                    onChange={comparison_value => onChange({ comparison_value })}
                />
            </Grid>
            <Grid container item xs={1} alignItems={'center'}>
                <IconButton size={'small'} onClick={() => onRemove()}>
                    <TrashIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};
