import type { ActiveCamera } from '../../../hooks/useCameraSync';
import { ComparisonModeEvent } from '../../../hooks/useComparisonModeTracking';
import type { DesignRevision } from '../ModelViewerFooterBannerHelper';
import { DesignVersionTitle } from './DesignVersionTitle';
import { MenuItem, TextField } from '@orthly/ui-primitives';
import React from 'react';

export const DesignVersionDropdown: React.VFC<{
    designRevisions: readonly DesignRevision[];
    selectedDesignRevisionId?: string;
    comparisonDesignRevisionId?: string;
    setSelectedDesignRevisionId: (id: string) => void;
    trackComparisonMode: (event: ComparisonModeEvent) => void;
    setControlsActive(which: ActiveCamera): void;
}> = ({
    designRevisions,
    selectedDesignRevisionId,
    comparisonDesignRevisionId,
    setSelectedDesignRevisionId,
    trackComparisonMode,
    setControlsActive,
}) => {
    return (
        <TextField
            id={'design-version-dropdown'}
            fullWidth
            variant={'standard'}
            select
            value={selectedDesignRevisionId}
            onChange={event => {
                setControlsActive('primary');
                setSelectedDesignRevisionId(event.target.value);
                trackComparisonMode(ComparisonModeEvent.selectDesignVersion);
            }}
            SelectProps={{
                sx: {
                    height: '32px',
                    '&&': {
                        paddingRight: '32px',
                    },
                },
            }}
        >
            {designRevisions.map(designRevision => {
                return (
                    <MenuItem
                        key={designRevision.id}
                        value={designRevision.id}
                        disabled={[comparisonDesignRevisionId, selectedDesignRevisionId].includes(designRevision.id)}
                    >
                        <DesignVersionTitle
                            selectedDesignRevisionAlreadyReviewed={!!designRevision.doctor_review}
                            selectedDesignRevisionStatus={designRevision?.doctor_review?.status}
                            selectedDesignRevisionCreatedAtDate={designRevision?.doctor_review?.created_at}
                            color={'BLACK'}
                        />
                    </MenuItem>
                );
            })}
        </TextField>
    );
};
