export enum AlignerAdditionalConcern {
    MissingTooth = 'MissingTooth',
    Crown = 'Crown',
    DentalBridge = 'DentalBridge',
    DentalImplant = 'DentalImplant',
}

export enum AlignerPrimaryConcern {
    Crowded = 'Crowded',
    Protruding = 'Protruding',
    Spacing = 'Spacing',
    Overbite = 'Overbite',
    Underbite = 'Underbite',
    OpenBite = 'OpenBite',
}

export const TITLE_BY_ALIGNER_PRIMARY_CONCERN: { [K in AlignerPrimaryConcern]: string } = {
    Crowded: 'Crowding',
    Protruding: 'Protruding',
    Spacing: 'Spacing',
    Overbite: 'Overbite',
    Underbite: 'Underbite',
    OpenBite: 'Open bite',
};
