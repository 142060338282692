import type { PlayWrightWorldPointerEventDetails } from '@orthly/shared-types';
import type React from 'react';
import * as THREE from 'three';

// The purpose of this class is to respond to mouse events dispatched from PlayWright where the coordinates are expressed
// In world-space. In an ideal world this would not be needed; we would be able to record mouse events recorded by the user
// and play them back during a test and get the results we want. However, there are several challenges associated with this.
//
// 1. It assumes pixel perfect rendering across different platforms that PlayWright runs on
// 2. It assumes pixel perfect rendering across different GPUs
// 3. It assumes that for a given browser target, that headless rendering is identical to headed mode.
//
// While developing PlayWright tests for margin marking it quickly became clear that the largest hurdle was in fact point
// number three. In Chromium there are significant differences in layout/rendering between headless and headed mode that cause
// any recorded mouse events to be useless for playback and input into a ray-picking algorithm. There are several open issues on
// the PlayWright GitHub issue tracker for this. The main one seems to be:
//
// https://github.com/microsoft/playwright/issues/12683
//
// Since we are at the mercy of the browser vendors here, two alternatives were considered for testing interactive 3D tools
// that require precise user input into a canvas element:
//
// 1. Forget about emulating user input entirely and send data from PlayWright to some backdoor API in our components.
// 2. Extend Canvas elements with some custom events that could be dispatched from PlayWright. These custom events would
//    be intercepted by special handlers that would be able to take the provided data and figure out within the context
//    of the headless browser what the correct built-in events need to be dispatched on the Canvas element. In this way
//    we are actually testing our user input handling, albeit via a method that calculates the correct input "just-in-time".
//
// The first option didn't seem to be in the spirit of what automated UI tests are trying to accomplish - you aren't
// actually testing the code path that is run in production. The second option suffers from this problem as well, but to
// a much lesser degree and is probably the best we can do for now.
export class PlayWrightWorldPointerEventManager {
    constructor(
        private readonly canvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
        private readonly cameraRef: React.MutableRefObject<THREE.OrthographicCamera | null>,
    ) {}

    private worldPositionToClientPosition(worldX: number, worldY: number, worldZ: number) {
        const worldPosition = new THREE.Vector3(worldX, worldY, worldZ);
        const clientRect = this.canvasRef.current?.getBoundingClientRect();
        if (!clientRect) {
            return;
        }
        if (!this.cameraRef.current) {
            return;
        }
        worldPosition?.project(this.cameraRef.current);
        const clientX = 0.5 * (worldPosition.x * clientRect.width + 2 * clientRect.x + clientRect.width);
        const clientY = 0.5 * (-worldPosition.y * clientRect.height + 2 * clientRect.y + clientRect.height);
        return { clientX, clientY };
    }

    handleWorldPointerEvent(eventType: string, details: PlayWrightWorldPointerEventDetails) {
        const { worldX, worldY, worldZ, button } = details;
        const clientCoordinates = this.worldPositionToClientPosition(worldX, worldY, worldZ);
        if (clientCoordinates === undefined) {
            return;
        }
        const { clientX, clientY } = clientCoordinates;
        this.canvasRef.current?.dispatchEvent(new PointerEvent(eventType, { clientX, clientY, button }));
    }

    handleWorldPointerMove(e: CustomEvent<PlayWrightWorldPointerEventDetails>) {
        this.handleWorldPointerEvent('pointermove', e.detail);
    }

    handleWorldPointerDown(e: CustomEvent<PlayWrightWorldPointerEventDetails>) {
        this.handleWorldPointerEvent('pointerdown', e.detail);
    }

    handleWorldPointerUp(e: CustomEvent<PlayWrightWorldPointerEventDetails>) {
        this.handleWorldPointerEvent('pointerup', e.detail);
    }
}
