import { useViewManager } from '../ScanReview.hooks';
import type { ScanReviewRecord } from '../ScanReviewRecordTypes';
import {
    type ScanReviewViewState,
    ScanReviewPanelType,
    type ScanReviewThreeViewProvider,
} from '../ScanReviewViewTypes';
import {
    type ToothPlacementVisualizationRecordFactory,
    ToothPlacementVisualization,
} from './ToothPlacementVisualizationTypes';
import { toothPlacementFunctionFromDcm } from '@orthly/forceps';
import type { ToothNumber } from '@orthly/items';
import constate from 'constate';
import React from 'react';
import * as THREE from 'three';

interface ToothPlacementVisualizationViewAppProps {
    lowerJawFactory: ToothPlacementVisualizationRecordFactory | null;
    upperJawFactory: ToothPlacementVisualizationRecordFactory | null;
    crownFactory: ToothPlacementVisualizationRecordFactory | null;
    toothNumber: ToothNumber;
    viewState: ScanReviewViewState;
}

export class ToothPlacementVisualizationPartialScene implements ScanReviewThreeViewProvider {
    scene: THREE.Scene;

    constructor(
        public upperJaw: ScanReviewRecord | null,
        public lowerJaw: ScanReviewRecord | null,
        public crown: ScanReviewRecord | null,
    ) {
        this.scene = new THREE.Scene();
        if (upperJaw) {
            this.scene.add(upperJaw.scanMesh);
        }
        if (lowerJaw) {
            this.scene.add(lowerJaw.scanMesh);
        }
        if (crown) {
            this.scene.add(crown.scanMesh);
        }
    }

    setUpperJawVisibility(visible: boolean) {
        this.upperJaw?.setVisible(visible);
    }

    setLowerJawVisibility(visible: boolean) {
        this.lowerJaw?.setVisible(visible);
    }

    // Helper used to visualize debugging planes returned from the tooth placement function
    // Uses position, normal instead of THREE.Plane for tighter control over where it appears
    addPlaneToScene(position: THREE.Vector3, normal: THREE.Vector3) {
        const width = 8;
        const height = 8;
        const planeGeometry = new THREE.PlaneGeometry(width, height);
        const planeMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00, side: THREE.DoubleSide });
        const planeMesh = new THREE.Mesh(planeGeometry, planeMaterial);

        planeMesh.position.copy(position);

        const upVector = new THREE.Vector3(0, 0, 1);
        const quaternion = new THREE.Quaternion().setFromUnitVectors(upVector, normal);
        planeMesh.quaternion.copy(quaternion);
        this.scene.add(planeMesh);
    }

    // Helper used to visualize debugging vectors returned from the tooth placement function
    addArrowToScene(position: THREE.Vector3, direction: THREE.Vector3, length: number = 10, color: number = 0xffff00) {
        // Default arrow parameters
        const shaftRadius = 0.05 * length;
        const shaftLength = 0.8 * length;
        const headRadius = 0.1 * length;
        const headLength = 0.2 * length;

        const shaftGeometry = new THREE.CylinderGeometry(shaftRadius, shaftRadius, shaftLength, 8, 1);
        const shaftMaterial = new THREE.MeshBasicMaterial({ color: color });
        const shaftMesh = new THREE.Mesh(shaftGeometry, shaftMaterial);
        shaftMesh.position.set(0, shaftLength / 2, 0);
        const headGeometry = new THREE.ConeGeometry(headRadius, headLength, 8, 1);
        const headMaterial = new THREE.MeshBasicMaterial({ color: color });
        const headMesh = new THREE.Mesh(headGeometry, headMaterial);
        headMesh.position.set(0, shaftLength + headLength / 2, 0);

        // Create a group to hold both the shaft and the head
        const arrowGroup = new THREE.Group();
        arrowGroup.add(shaftMesh);
        arrowGroup.add(headMesh);

        const defaultDirection = new THREE.Vector3(0, 1, 0); // Default arrow points up along Y-axis
        const normalizedDirection = direction.clone().normalize();
        const quaternion = new THREE.Quaternion().setFromUnitVectors(defaultDirection, normalizedDirection);
        arrowGroup.applyQuaternion(quaternion);
        arrowGroup.position.copy(position);
        this.scene.add(arrowGroup);
    }

    // Helper used to visualize debugging splines returned from the tooth placement function
    addSplineToScene(spline: THREE.CatmullRomCurve3, handleLabels: string[]) {
        // Create the geometry for the spline
        const splinePoints = spline.getPoints(150);
        const splineGeometry = new THREE.BufferGeometry().setFromPoints(splinePoints);

        // Create material and line
        const splineMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
        const splineLine = new THREE.Line(splineGeometry, splineMaterial);
        this.scene.add(splineLine);

        // Add spheres at the control points, along with labels
        spline.points.forEach((point, index) => {
            const handleGeometry = new THREE.SphereGeometry(1, 16, 16);
            const handleMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
            const handleMesh = new THREE.Mesh(handleGeometry, handleMaterial);
            handleMesh.position.copy(point);
            this.scene.add(handleMesh);

            // Create label as a sprite
            if (handleLabels[index]) {
                const label = this.createTextSprite(handleLabels[index]);
                label.position.copy(point).add(new THREE.Vector3(0, 0.1, 0)); // Offset slightly above the handle
                this.scene.add(label);
            }
        });
    }

    createTextSprite(text: string): THREE.Sprite {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const fontSize = 64;

        // Set canvas size
        canvas.width = 256;
        canvas.height = 128;

        if (context) {
            // Clear the canvas (ensures transparent background)
            context.clearRect(0, 0, canvas.width, canvas.height);

            context.font = `${fontSize}px Arial`;
            context.fillStyle = 'rgba(0, 0, 0, 1)';
            context.textAlign = 'center';
            context.textBaseline = 'middle';

            context.fillText(text, canvas.width / 2, canvas.height / 2);
        }
        const texture = new THREE.CanvasTexture(canvas);
        const material = new THREE.SpriteMaterial({
            map: texture,
            transparent: true,
            depthTest: false, // Disable depth test to always render on top
        });
        const sprite = new THREE.Sprite(material);
        sprite.scale.set(8, 4, 4); // Adjust size of the sprite
        sprite.renderOrder = 999; // Ensure this renders last, on top of everything
        return sprite;
    }
}

function useToothPlacementVisualizationViewApp({
    lowerJawFactory,
    upperJawFactory,
    crownFactory,
    toothNumber,
    viewState,
}: ToothPlacementVisualizationViewAppProps) {
    const scene = React.useMemo(() => {
        const upperJaw = upperJawFactory?.() ?? null;
        const lowerJaw = lowerJawFactory?.() ?? null;
        const crown = crownFactory?.() ?? null;

        const scene = new ToothPlacementVisualizationPartialScene(upperJaw, lowerJaw, crown);

        const preparationJaw = toothNumber <= 16 ? upperJaw : lowerJaw;

        if (scene.crown && crown && preparationJaw) {
            const { transform, splines, arrows, planes } = toothPlacementFunctionFromDcm(
                preparationJaw.dcmManager,
                crown.dcmManager,
                toothNumber,
                undefined,
            );

            const translation = new THREE.Vector3();
            const rotation = new THREE.Quaternion();
            const scale = new THREE.Vector3();
            transform.decompose(translation, rotation, scale);

            scene.crown.scanMesh.position.copy(translation);
            scene.crown.scanMesh.quaternion.copy(rotation);
            scene.crown.scanMesh.scale.copy(scale);

            for (const { spline, splineLabels } of splines) {
                scene.addSplineToScene(spline, splineLabels);
            }

            for (const { position, direction } of arrows) {
                scene.addArrowToScene(position, direction);
            }

            for (const { position, normal } of planes) {
                scene.addPlaneToScene(position, normal);
            }
        }
        return scene;
    }, [crownFactory, lowerJawFactory, toothNumber, upperJawFactory]);

    const viewManager = useViewManager(scene, ScanReviewPanelType.Isolated, viewState);

    const picker = React.useMemo(() => {
        return new ToothPlacementVisualization(scene, viewManager);
    }, [scene, viewManager]);

    return {
        picker,
    };
}

export const [ToothPlacementVisualizationViewAppProvider, useToothPlacementVisualizationViewContext] = constate(
    useToothPlacementVisualizationViewApp,
);
