import { getFeedbackOrRefabItemsFromOrder } from '../FeedbackAndRefab';
import { useRefabFlowContext } from './screens/refab-flow-context';
import { useItemWithShadeReasonsSelector, useRefabFlowAction, useRefabFlowSelector } from './state/refab-flow-state';
import type { RefabFlowItemReasonCode, RefabFlowLabOrder } from './state/refab-flow-types';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type {
    LabsGqlCreateReturnForRefabricationCommand,
    LabsGqlMarkNeedsRefabricationCommand,
} from '@orthly/graphql-schema';
import { LabsGqlValidShadeTypeEnum } from '@orthly/graphql-schema';
import { CartItemV2Utils, OrderItemV2Utils, ExtraCartItems } from '@orthly/items';
import type { ICartItemV2DTO, IOrderItemShade, ValidShadeType } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

export interface ReturnedItemsInfo {
    itemId: string;
    name: string;
}

export function getItemsToReturn(
    order: RefabFlowLabOrder | undefined,
    reasonCodesByItem: Record<string, Record<string, RefabFlowItemReasonCode>>,
): ReturnedItemsInfo[] {
    if (!order) {
        return [];
    }

    const refabItems = OrderItemV2Utils.parseItems(order.items_v2);
    const returnInfo: ReturnedItemsInfo[] = [];

    for (const item of refabItems) {
        let hasReturn = false;
        for (const reasonCodeData of Object.values(reasonCodesByItem[item.id] ?? {})) {
            // if at least one reason code that's selected for an item requires a return,
            // then we mark the item as needing to be returned
            if (reasonCodeData.requiresReturn && !hasReturn) {
                returnInfo.push({
                    itemId: item.id,
                    name: `${CartItemV2Utils.getFullDisplayName(item)}`,
                });
                hasReturn = true;
            }
        }
    }
    return returnInfo;
}

/**
 * Certain extra cart items that can be added to orders are either not physically sent to
 * the doctor or are not expected to have reason codes associated with them, so we want to
 * exclude them from the refab flow
 */
export function isUnitTypeEligibleForRefab(item: ICartItemV2DTO) {
    const unitType = CartItemV2Utils.getPrimaryUnitType(item);
    switch (unitType) {
        case ExtraCartItems['Authentic Surcharge'].unit_type:
        case ExtraCartItems['Mini Model'].unit_type:
        case ExtraCartItems['Other'].unit_type:
            return false;
        default:
            return true;
    }
}

export function getTrackingMetricsFromData(
    mtnData:
        | Pick<LabsGqlCreateReturnForRefabricationCommand, 'attachments' | 'refab_item_reason_codes'>
        | Pick<LabsGqlMarkNeedsRefabricationCommand, 'attachments' | 'refab_item_reason_codes'>,
) {
    const orderAttachmentsCount = (mtnData.attachments ?? []).length;

    const shadeUpdatesCount =
        mtnData.refab_item_reason_codes?.reduce(
            (count, reasonCode) => count + (reasonCode.change_requests?.shade_changes ?? []).length,
            0,
        ) ?? 0;

    return {
        orderAttachmentsCount,
        // Item attachments are no longer a thing in the last shade refab version.
        itemAttachmentsCount: 0,
        shadeUpdatesCount,
    };
}

/**
 * In this function we take all the "additional info request" values and concatenate them in one list
 * that we can then display in the UI as help text under the attachment form
 */
export function getUniqueAdditionalInfoRequest(
    itemwiseReasonCodes: Record<string, Record<string, RefabFlowItemReasonCode>>,
): string[] {
    const allInfoRequests = [];

    for (const reasons of Object.values(itemwiseReasonCodes)) {
        for (const reason of Object.values(reasons)) {
            allInfoRequests.push(...reason.additionalInfoRequest);
        }
    }

    return _.uniq(allInfoRequests);
}

const convertShadeNameToBackEndValue = (value: ValidShadeType): LabsGqlValidShadeTypeEnum => {
    switch (value) {
        case 'base':
            return LabsGqlValidShadeTypeEnum.Base;
        case 'gingival':
            return LabsGqlValidShadeTypeEnum.Gingival;
        case 'incisal':
            return LabsGqlValidShadeTypeEnum.Incisal;
        case 'stump':
            return LabsGqlValidShadeTypeEnum.Stump;
        case 'tissue':
            return LabsGqlValidShadeTypeEnum.Tissue;
    }
};

export const useSubmitRefab = () => {
    const patchState = useRefabFlowAction('PATCH_STATE');

    const selectedItemIds = useRefabFlowSelector(s => s.selectedItemIds);
    const itemwiseReasonCodes = useRefabFlowSelector(s => s.itemwiseReasonCodes);
    const attachments = useRefabFlowSelector(s => s.attachments);
    const supplementaryNotes = useRefabFlowSelector(s => s.supplementaryNotes);
    const annotations = useRefabFlowSelector(s => s.annotations);
    const shadeUpdatesByItemId = useRefabFlowSelector(s => s.shadeUpdatesByItemId);
    const requestWaxup = useRefabFlowSelector(s => s.requestWaxup);

    // hooks can't be called conditionally, so this is the first place we can exit early
    const { order, refetch: refetchOrder, submit, isOps } = useRefabFlowContext();
    if (!order) {
        return null;
    }

    const itemsToReturn = getItemsToReturn(order, itemwiseReasonCodes);

    const mtnData: LabsGqlCreateReturnForRefabricationCommand | LabsGqlMarkNeedsRefabricationCommand = {
        itemIdsToRefab: selectedItemIds,
        orderId: order.id,
        practitioner_notes: supplementaryNotes,
        attachments: attachments.map(attachment => ({ url: attachment.firebaseUrl })),
        annotations: annotations.map(annotation => ({
            image_url: annotation.imageUrl,
            comment: annotation.comment ?? '',
        })),
        refab_item_reason_codes: Object.entries(itemwiseReasonCodes).map(([itemId, reasonCodes]) => ({
            item_id: itemId,
            change_requests: {
                shade_changes:
                    shadeUpdatesByItemId[itemId]?.changeRequests.map(changeRequest => ({
                        shade_name: convertShadeNameToBackEndValue(changeRequest.shadeName),
                        new_value: changeRequest.newValue,
                        previous_value: changeRequest.previousValue,
                    })) ?? [],
            },
            item_attachments: [],
            refab_reason_codes: Object.values(reasonCodes).map(reasonCode => {
                const otherNotes = reasonCode.otherNotes === '' ? null : reasonCode.otherNotes;
                return {
                    title: reasonCode.title,
                    category: reasonCode.category,
                    reason_code_id: reasonCode.codeId,
                    // In the practice flow, our query to retrieve reason codes per item filters out all internalOnly === true
                    // since we don't want to display these to doctors, so this field will always be false
                    internal_only: false,
                    fault: reasonCode.defaultFault,
                    other_notes: otherNotes,
                };
            }),
        })),
        waxup_requested: requestWaxup,
    };

    const submitAndFinish = async () => {
        const result = await submit({
            needsReturn: itemsToReturn.length > 0,
            command: mtnData,
        });

        const trackingMetrics = getTrackingMetricsFromData(mtnData);

        if (isOps) {
            BrowserAnalyticsClientFactory.Instance?.track('Ops - Portal - Refab initiated', {
                $groups: { order: order?.id },
                itemAttachmentsCount: trackingMetrics.itemAttachmentsCount,
                orderAttachmentsCount: trackingMetrics.orderAttachmentsCount,
                shadeUpdatesCount: trackingMetrics.shadeUpdatesCount,
            });
        } else {
            BrowserAnalyticsClientFactory.Instance?.track('Practice - Portal - Refabrication Order Submitted', {
                $groups: { order: order?.id },
                submitMethod: 'standardized',
                itemAttachmentsCount: trackingMetrics.itemAttachmentsCount,
                orderAttachmentsCount: trackingMetrics.orderAttachmentsCount,
                shadeUpdatesCount: trackingMetrics.shadeUpdatesCount,
            });
        }

        if (result.data && 'createReturnForRefabrication' in result.data) {
            patchState({ returnLabelUrl: result.data.createReturnForRefabrication.label_url });
        }
        patchState({ screen: 'confirmation' });
        await refetchOrder();
    };

    return submitAndFinish;
};

export const useRefabOnBack = () => {
    const screen = useRefabFlowSelector(s => s.screen);
    const { enableAttachExistingScans } = useRefabFlowContext();
    const patchState = useRefabFlowAction('PATCH_STATE');
    const selectedItemIds = useRefabFlowSelector(s => s.selectedItemIds);
    const itemId = useRefabFlowSelector(s => s.screenItemId);
    const itemIndex = selectedItemIds.findIndex(id => id === itemId);
    const shadeItemSelector = useItemWithShadeReasonsSelector();

    const reasonCodeScreenOnBack = React.useCallback(() => {
        patchState(
            itemIndex !== 0
                ? {
                      screenItemId: selectedItemIds[itemIndex - 1],
                  }
                : { screen: 'selection', screenItemId: '' },
        );
    }, [patchState, itemIndex, selectedItemIds]);

    const shadeChangesOnBack = React.useCallback(() => {
        const indexCurrentItem = shadeItemSelector.itemIdsWithShadeReasons.findIndex(i => i === itemId);

        patchState(
            indexCurrentItem === 0
                ? { screen: 'shade_attachments' }
                : {
                      screen: 'shade_change_requests',
                      screenItemId: shadeItemSelector.itemIdsWithShadeReasons[indexCurrentItem - 1],
                  },
        );
    }, [patchState, itemId, shadeItemSelector]);

    const supplementaryOrAttachExistingOnBack = React.useCallback(() => {
        const hasShadeUpdates = shadeItemSelector.itemIdsWithShadeReasons.length > 0;

        patchState(
            hasShadeUpdates
                ? { screen: 'shade_change_requests' }
                : {
                      screen: 'reasonCodes',
                  },
        );
    }, [patchState, shadeItemSelector]);

    const onBack = () => {
        switch (screen) {
            case 'reasonCodes':
                reasonCodeScreenOnBack();
                break;
            case 'shade_attachments':
                patchState({ screen: 'reasonCodes', screenItemId: selectedItemIds[selectedItemIds.length - 1] });
                break;
            case 'shade_change_requests':
                shadeChangesOnBack();
                break;
            case 'additional_notes':
                patchState({
                    screen: 'shade_change_requests',
                    screenItemId:
                        shadeItemSelector.itemIdsWithShadeReasons[shadeItemSelector.itemIdsWithShadeReasons.length - 1],
                });
                break;
            case 'attach_existing_scans':
                supplementaryOrAttachExistingOnBack();
                break;
            case 'supplemental_info_step_one':
                if (enableAttachExistingScans) {
                    patchState({ screen: 'attach_existing_scans' });
                } else {
                    supplementaryOrAttachExistingOnBack();
                }
                break;
            case 'supplemental_info_step_two':
                patchState({
                    screen: 'supplemental_info_step_one',
                });
                break;
            case 'info':
                patchState({
                    screen: 'supplemental_info_step_two',
                });
                break;
        }
    };

    return onBack;
};

export const useRefabOnNext = () => {
    const { enableAttachExistingScans } = useRefabFlowContext();
    const screen = useRefabFlowSelector(s => s.screen);
    const patchState = useRefabFlowAction('PATCH_STATE');
    const selectedItemIds = useRefabFlowSelector(s => s.selectedItemIds);
    const itemId = useRefabFlowSelector(s => s.screenItemId);
    const itemIndex = selectedItemIds.findIndex(id => id === itemId);
    const shadeItemSelector = useItemWithShadeReasonsSelector();
    const submitRefab = useSubmitRefab();
    const itemwiseReasonCodes = useRefabFlowSelector(s => s.itemwiseReasonCodes);
    const isSettingShadesForAllItems = useRefabFlowSelector(s => s.isSettingShadesForAllItems);

    const selectionScreenOnNext = React.useCallback(() => {
        const savedItemIds = Object.keys(itemwiseReasonCodes);
        const removedItemIds = savedItemIds.filter(itemId => !selectedItemIds.includes(itemId));
        const newItemwiseReasonCodes = _.omit(itemwiseReasonCodes, removedItemIds);
        if (removedItemIds.length > 0) {
            patchState({ itemwiseReasonCodes: newItemwiseReasonCodes });
        }
        patchState({ screen: 'reasonCodes', screenItemId: selectedItemIds[0] });
    }, [itemwiseReasonCodes, selectedItemIds, patchState]);

    const supplementalOrAttachExisting = enableAttachExistingScans
        ? 'attach_existing_scans'
        : 'supplemental_info_step_one';

    const reasonCodesOnNext = React.useCallback(() => {
        const isLastItem = itemIndex === selectedItemIds.length - 1;
        const hasShadeReasons = shadeItemSelector.itemIdsWithShadeReasons.length > 0;

        if (isLastItem) {
            hasShadeReasons
                ? patchState({ screen: 'shade_attachments' })
                : patchState({ screen: supplementalOrAttachExisting });
        } else {
            patchState({ screen: 'reasonCodes', screenItemId: selectedItemIds[itemIndex + 1] });
        }
    }, [selectedItemIds, shadeItemSelector, itemIndex, patchState, supplementalOrAttachExisting]);

    const shadeChangeRequestsOnNext = React.useCallback(() => {
        const indexOfCurrentItem = shadeItemSelector.itemIdsWithShadeReasons.findIndex(i => i === itemId);
        const hasOtherUpdates = shadeItemSelector.itemIdsWithNonShadeReasons.length > 0;
        const isLastShadeUpdate =
            isSettingShadesForAllItems || indexOfCurrentItem === shadeItemSelector.itemIdsWithShadeReasons.length - 1;

        if (isLastShadeUpdate && !hasOtherUpdates) {
            patchState({ screen: 'additional_notes' });
        } else {
            patchState(
                isLastShadeUpdate
                    ? { screen: supplementalOrAttachExisting }
                    : {
                          screen: 'shade_change_requests',
                          screenItemId: shadeItemSelector.itemIdsWithShadeReasons[indexOfCurrentItem + 1],
                      },
            );
        }
    }, [shadeItemSelector, itemId, patchState, isSettingShadesForAllItems, supplementalOrAttachExisting]);

    const onNext = () => {
        switch (screen) {
            case 'selection':
                selectionScreenOnNext();
                break;
            case 'reasonCodes':
                reasonCodesOnNext();
                break;
            case 'shade_attachments':
                patchState({
                    screen: 'shade_change_requests',
                    screenItemId: shadeItemSelector.itemIdsWithShadeReasons[0],
                });
                break;
            case 'shade_change_requests':
                shadeChangeRequestsOnNext();
                break;
            case 'attach_existing_scans':
                patchState({ screen: 'supplemental_info_step_one' });
                break;
            case 'supplemental_info_step_one':
                patchState({ screen: 'supplemental_info_step_two' });
                break;
            case 'supplemental_info_step_two':
            case 'additional_notes':
                patchState({ screen: 'info' });
                break;
            case 'info':
                submitRefab && void submitRefab();
                break;
        }
    };

    return onNext;
};

export const useGetShadesOfSelectedItems = () => {
    const { order } = useRefabFlowContext();
    const shadeItemSelector = useItemWithShadeReasonsSelector();

    const shadesOfSelectedItems = shadeItemSelector.itemIdsWithShadeReasons.map(itemId => {
        return order?.items_v2.find(orderItem => orderItem.id === itemId)?.shades ?? [];
    });

    return shadesOfSelectedItems;
};

// Here we check if all items have the same shade value for all shades, if that's the case we return `true`
// as we'll want to automatically set all shades for all items. The user can override that by toggling
// the switch
export const areShadesOfAllItemsTheSame = (shadesOfSelectedItems: IOrderItemShade[][]) => {
    if (shadesOfSelectedItems.length <= 1) {
        return true;
    }

    return shadesOfSelectedItems.every(shadeArray => {
        return shadeArray.every(shade => {
            const defaultShade = shadesOfSelectedItems[0]?.find(defaultShade => defaultShade.name === shade.name);
            return defaultShade?.value === shade.value;
        });
    });
};

export const useGetDisplayStringOfItemWithShadeUpdates = (props?: { shouldAlwaysReturnAllNames: boolean }) => {
    const { order } = useRefabFlowContext();
    const items = getFeedbackOrRefabItemsFromOrder(order);
    const shadeReasonSelectorResult = useItemWithShadeReasonsSelector();
    const isSettingShadesForAllItems = useRefabFlowSelector(s => s.isSettingShadesForAllItems);
    const screenItemId = useRefabFlowSelector(s => s.screenItemId);
    const shouldReturnAllNames = props?.shouldAlwaysReturnAllNames ?? false;

    const itemNames = shadeReasonSelectorResult.itemIdsWithShadeReasons.map(
        shadeItemId => items.find(item => item.id === shadeItemId)?.name,
    );

    const currentItemName = items.find(item => item.id === screenItemId)?.name;

    if (isSettingShadesForAllItems || shouldReturnAllNames) {
        return `${itemNames.join(', ')}`;
    } else {
        return `${currentItemName}`;
    }
};
