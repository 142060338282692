import { Box, Checkbox, FormControlLabel } from '@orthly/ui-primitives';
import React from 'react';

export type NestedCheckboxListOption = {
    category: string;
    label: string;
    disabled?: boolean;
    children?: NestedCheckboxListOption[];
};
export type NestedCheckboxListSelections = Record<string, NestedCheckboxListSelection>;
type NestedCheckboxListSelection = { children: NestedCheckboxListSelections };

type NestedCheckboxListProps = {
    disabled?: boolean;
    options: NestedCheckboxListOption[];
    selections: NestedCheckboxListSelections | undefined;
    onChange: (selections: NestedCheckboxListSelections) => void;
};

export const NestedCheckboxList: React.VFC<NestedCheckboxListProps> = ({
    disabled,
    onChange,
    options,
    selections = {},
}) => {
    const handleChange = React.useCallback(
        (category: string): React.ChangeEventHandler<HTMLInputElement> =>
            event => {
                if (event.target.checked) {
                    onChange({ ...selections, [category]: { children: {} } });
                } else {
                    const { [category]: _discarded, ...rest } = selections;
                    onChange(rest);
                }
            },
        [onChange, selections],
    );

    if (options.length === 0) {
        return null;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
            {options.map(option => (
                <Box
                    key={option.category}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        '& &': { marginLeft: '2rem' },
                    }}
                >
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={option.disabled || disabled}
                                checked={option.category in selections}
                                onChange={handleChange(option.category)}
                                value={option.category}
                                sx={{ alignSelf: 'flex-start', marginTop: 0, marginBottom: 0 }}
                            />
                        }
                        label={option.label}
                        slotProps={{ typography: { sx: { '&&': { margin: 0 } } } }}
                    />
                    {option.category in selections && option.children && (
                        <NestedCheckboxList
                            disabled={disabled}
                            options={option.children}
                            onChange={next => onChange({ ...selections, [option.category]: { children: next } })}
                            selections={selections[option.category]?.children}
                        />
                    )}
                </Box>
            ))}
        </Box>
    );
};
