import type { PartialSceneAppearance } from './SceneAppearanceManager.types';
import { HeatMapType } from '@orthly/forceps';

export function getActiveHeatMapType(sceneAppearance: PartialSceneAppearance): HeatMapType | null {
    if (sceneAppearance.thicknessHeatmapEnabled) {
        return HeatMapType.Thickness;
    }

    if (sceneAppearance.occlusalHeatmapEnabled) {
        return HeatMapType.Occlusal;
    }

    if (sceneAppearance.proximalHeatmapEnabled) {
        return HeatMapType.Proximal;
    }

    if (sceneAppearance.undercutHeatmapEnabled || sceneAppearance.scanUndercutEnabled) {
        return HeatMapType.Undercut;
    }

    return null;
}
