import { all } from './rules/all';
import { editedField } from './rules/editedField';
import { editedPreference } from './rules/editedPreference';
import { editedPreferenceIncludes } from './rules/editedPreferenceIncludes';
import { editedShade } from './rules/editedShade';
import { labDoesDesign } from './rules/labDoesDesign';
import { newScan } from './rules/newScan';
import { not } from './rules/not';
import { orderAssigned } from './rules/orderAssigned';
import { orderStatus } from './rules/orderStatus';
import { orderTask } from './rules/orderTask';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlLabOrderStatus, LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import type { OrderKey, OrderFieldKey } from '@orthly/shared-types';
import { OrderEditMode } from '@orthly/shared-types';

export type ItemInfo = {
    order: LabsGqlOrder;
    editedField?: OrderKey | OrderFieldKey;
    editedPref?: string;
    editedShade?: string;
    newScan?: boolean;
};

export type Decision = {
    /**
     * editMode tells us what edit mode is allowed by this item within
     * the rule's purview.  If the rule detects no changes
     * relevant to its logic, it will set this to null indicating that
     * its decision can be ignored.
     */
    editMode: OrderEditMode | null;

    /**
     * debugInfo contains free-form data about the rule's
     * decision that may be useful in subsequent debugging
     */
    debugInfo: { [key: string]: any };
};

export type Rule = (item: ItemInfo) => Decision;

// Pre-shipment order statuses
const PRE_SHIPMENT_STATUSES = [
    LabsGqlLabOrderStatus.New,
    LabsGqlLabOrderStatus.OnHold,
    LabsGqlLabOrderStatus.NeedsReview,
    LabsGqlLabOrderStatus.Fabrication,
];
const relevantStatus: Rule = orderStatus(...PRE_SHIPMENT_STATUSES);

const toothFields = editedField('unn', 'unns');
const designOrPrep = orderTask(LabsGqlWorkflowTaskType.InternalDesign, LabsGqlWorkflowTaskType.DesignPrep);
const implantFields = editedField('abutment', 'implant_metadata');

const RULES: Rule[] = [
    all(editedField('arch'), relevantStatus, orderTask(LabsGqlWorkflowTaskType.ReviewOrder)),
    all(editedField('arch'), relevantStatus, designOrPrep, orderAssigned(false)),

    all(toothFields, relevantStatus, orderTask(LabsGqlWorkflowTaskType.ReviewOrder)),
    all(toothFields, relevantStatus, designOrPrep, orderAssigned(false)),

    all(
        editedField('shades'),
        relevantStatus,
        orderTask(
            LabsGqlWorkflowTaskType.LabAcceptOrder,
            LabsGqlWorkflowTaskType.ResolveHold,
            LabsGqlWorkflowTaskType.ReviewOrder,
            LabsGqlWorkflowTaskType.ScanReview,
            LabsGqlWorkflowTaskType.ResolveScanRejection,
            LabsGqlWorkflowTaskType.InternalDesign,
            LabsGqlWorkflowTaskType.DesignReview,
            LabsGqlWorkflowTaskType.DesignReview2,
            LabsGqlWorkflowTaskType.DesignPrep,
            LabsGqlWorkflowTaskType.AutomateDesign,
            LabsGqlWorkflowTaskType.ModelDesign,
            LabsGqlWorkflowTaskType.ExternalDesignVerification,
            LabsGqlWorkflowTaskType.WaxupReview,
            LabsGqlWorkflowTaskType.DrReviewHold,
        ),
    ),

    all(editedField('material'), relevantStatus, orderTask(LabsGqlWorkflowTaskType.ReviewOrder)),
    all(editedField('material'), relevantStatus, designOrPrep, orderAssigned(false)),

    all(editedField('preference_fields'), relevantStatus, orderTask(LabsGqlWorkflowTaskType.ReviewOrder)),
    all(editedField('preference_fields'), relevantStatus, designOrPrep, orderAssigned(false)),

    all(editedField('item_notes'), relevantStatus, orderTask(LabsGqlWorkflowTaskType.ReviewOrder)),
    all(editedField('item_notes'), relevantStatus, designOrPrep, orderAssigned(false)),

    all(implantFields, relevantStatus, orderTask(LabsGqlWorkflowTaskType.ReviewOrder)),
    all(implantFields, relevantStatus, designOrPrep, orderAssigned(false)),

    // New scan does not require C/R when an order is in needs review
    all(newScan, relevantStatus, orderTask(LabsGqlWorkflowTaskType.ReviewOrder)),
    // New scan does not require C/R when an order is being designed by the lab and they haven't accepted it yet
    all(newScan, relevantStatus, labDoesDesign, orderTask(LabsGqlWorkflowTaskType.LabAcceptOrder)),
    // New scan does not require C/R when an order is being designed internally and hasn't been assigned yet
    all(newScan, relevantStatus, designOrPrep, orderAssigned(false)),

    all(
        editedShade('tissue'),
        relevantStatus,
        orderTask(
            LabsGqlWorkflowTaskType.ResolveHold,
            LabsGqlWorkflowTaskType.ReviewOrder,
            LabsGqlWorkflowTaskType.ScanReview,
            LabsGqlWorkflowTaskType.ResolveScanRejection,
            LabsGqlWorkflowTaskType.InternalDesign,
            LabsGqlWorkflowTaskType.DesignReview,
            LabsGqlWorkflowTaskType.DesignReview2,
            LabsGqlWorkflowTaskType.DesignPrep,
            LabsGqlWorkflowTaskType.AutomateDesign,
            LabsGqlWorkflowTaskType.ModelDesign,
            LabsGqlWorkflowTaskType.WaxupReview,
            LabsGqlWorkflowTaskType.DrReviewHold,
        ),
    ),

    all(
        editedPreference('denture_type'),
        relevantStatus,
        orderTask(LabsGqlWorkflowTaskType.InternalDesign, LabsGqlWorkflowTaskType.DesignPrep),
        orderAssigned(false),
    ),

    all(editedPreference('denture_type'), relevantStatus, orderTask(LabsGqlWorkflowTaskType.ReviewOrder)),

    all(
        editedPreference('denture-quantity-order-item-meta', 'denture-add-stippling-order-item-meta'),
        relevantStatus,
        orderTask(
            LabsGqlWorkflowTaskType.ResolveHold,
            LabsGqlWorkflowTaskType.ReviewOrder,
            LabsGqlWorkflowTaskType.ScanReview,
            LabsGqlWorkflowTaskType.ResolveScanRejection,
            LabsGqlWorkflowTaskType.InternalDesign,
            LabsGqlWorkflowTaskType.DesignReview,
            LabsGqlWorkflowTaskType.DesignReview2,
            LabsGqlWorkflowTaskType.DesignPrep,
            LabsGqlWorkflowTaskType.AutomateDesign,
            LabsGqlWorkflowTaskType.ModelDesign,
            LabsGqlWorkflowTaskType.WaxupReview,
            LabsGqlWorkflowTaskType.DrReviewHold,
        ),
    ),

    all(
        editedPreference('denture_add_softliner'),
        relevantStatus,
        orderTask(
            LabsGqlWorkflowTaskType.ResolveHold,
            LabsGqlWorkflowTaskType.ReviewOrder,
            LabsGqlWorkflowTaskType.ScanReview,
            LabsGqlWorkflowTaskType.ResolveScanRejection,
            LabsGqlWorkflowTaskType.InternalDesign,
            LabsGqlWorkflowTaskType.DesignReview,
            LabsGqlWorkflowTaskType.DesignReview2,
            LabsGqlWorkflowTaskType.DesignPrep,
            LabsGqlWorkflowTaskType.AutomateDesign,
            LabsGqlWorkflowTaskType.ModelDesign,
            LabsGqlWorkflowTaskType.WaxupReview,
            LabsGqlWorkflowTaskType.DrReviewHold,
        ),
    ),

    all(
        relevantStatus,
        editedPreference('full-dentures-scan-issues'),
        orderTask(
            LabsGqlWorkflowTaskType.LabAcceptOrder,
            LabsGqlWorkflowTaskType.ExternalDesign,
            LabsGqlWorkflowTaskType.PullFromFloor,
            LabsGqlWorkflowTaskType.SendToFloor,
            LabsGqlWorkflowTaskType.ResolveHold,
            LabsGqlWorkflowTaskType.ReviewOrder,
            LabsGqlWorkflowTaskType.ScanReview,
            LabsGqlWorkflowTaskType.ResolveScanRejection,
            LabsGqlWorkflowTaskType.InternalDesign,
            LabsGqlWorkflowTaskType.DesignReview,
            LabsGqlWorkflowTaskType.DesignReview2,
            LabsGqlWorkflowTaskType.DesignPrep,
            LabsGqlWorkflowTaskType.AutomateDesign,
            LabsGqlWorkflowTaskType.ModelDesign,
            LabsGqlWorkflowTaskType.ExternalDesignVerification,
            LabsGqlWorkflowTaskType.WaxupReview,
            LabsGqlWorkflowTaskType.DrReviewHold,
        ),
    ),

    // These two rules should match all denture settings other than
    // the ones we've already considered explicitly
    all(
        relevantStatus,
        not(
            editedPreference(
                'full-dentures-scan-issues',
                'denture_add_softliner',
                'denture-quantity-order-item-meta',
                'denture-add-stippling-order-item-meta',
                'denture_type',
            ),
        ),
        editedPreferenceIncludes('denture'),
        orderTask(LabsGqlWorkflowTaskType.InternalDesign, LabsGqlWorkflowTaskType.DesignPrep),
        orderAssigned(false),
    ),

    all(
        relevantStatus,
        not(
            editedPreference(
                'full-dentures-scan-issues',
                'denture_add_softliner',
                'denture-quantity-order-item-meta',
                'denture-add-stippling-order-item-meta',
                'denture_type',
            ),
        ),
        editedPreferenceIncludes('denture'),
        orderTask(LabsGqlWorkflowTaskType.ReviewOrder),
    ),
];

// evaluateRules abstracts out evaluation logic from the actual
// concrete set of rules for testing purposes
export function evaluateRules(rules: Rule[], item: ItemInfo): Decision {
    const decisions = rules.map(r => r(item));
    for (const decision of decisions) {
        if (decision.editMode !== null) {
            return decision;
        }
    }

    // Default in case none of the listed rules returns a usable
    // decision
    return {
        editMode: null,
        debugInfo: { rule: 'evaluateRules' },
    };
}

export function getOrderEditMode(item: ItemInfo): Decision {
    const decision = evaluateRules(RULES, item);

    // If none of the rules produces a result, we'll default to
    // CancelAndResubmit
    return { editMode: decision.editMode || OrderEditMode.CancelAndResubmit, debugInfo: decision.debugInfo };
}
