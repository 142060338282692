import type { DesignSubmissionValidationResult } from './OrderDesignTaskValidation.hooks.graphql';
import { FlossPalette, Text, stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        padding: 12,
        border: `1px solid ${FlossPalette.ATTENTION_FOREGROUND}`,
        backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
        borderRadius: 12,
    },
    list: {
        paddingInlineStart: 24,
    },
}));

export const CompleteDesignTaskPaneAlert: React.VFC<{ result?: DesignSubmissionValidationResult }> = ({ result }) => {
    const classes = useStyles();

    if (result?.status !== 'failed') {
        return null;
    }

    return (
        <Grid container direction={'column'} className={classes.root}>
            <Grid item>
                <Text variant={'body2'} medium color={'BLACK'}>
                    File Failed Validation. Please Verify Contents.
                </Text>
            </Grid>
            <Grid item>
                <ul className={classes.list}>
                    {result.errorLines.map((line, idx) => (
                        <li key={`validation_line_${idx}`}>
                            <Text variant={'caption'} color={'BLACK'}>
                                {line}
                            </Text>
                        </li>
                    ))}
                </ul>
            </Grid>
            {result.canBypass && (
                <Grid item>
                    <Text variant={'caption'} color={'BLACK'}>
                        Please verify the design file is valid and for the right patient.
                    </Text>
                </Grid>
            )}
        </Grid>
    );
};
