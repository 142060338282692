import type { LabsGqlActiveInternalDesignTaskFragment } from '@orthly/graphql-operations';
import { LabsGqlDesignStaffMatchingLogic } from '@orthly/graphql-schema';
import dayjs from 'dayjs';
import React from 'react';

export function useDesignTaskMatchPendingText(
    designTaskSummary?: LabsGqlActiveInternalDesignTaskFragment,
): string | undefined {
    return React.useMemo(() => {
        if (
            designTaskSummary?.designStaffMatchingLogic === LabsGqlDesignStaffMatchingLogic.ReassignPreviousDesignStaff
        ) {
            const timeRemainingMs = dayjs(designTaskSummary.matchStaleTime).diff();
            const timeRemainingMins = Math.round(timeRemainingMs / 60000);
            return `Prev designer hold: ${timeRemainingMins} mins remaining`;
        }
        if (designTaskSummary?.taskType) {
            return 'Assignment Pending';
        }
    }, [designTaskSummary]);
}
