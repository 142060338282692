import { useActionCategories } from '../../../../utils/useTicketActionHooks';
import type { LabsGqlActionCategoryDtoFragment } from '@orthly/graphql-operations';
import {
    useCreateActionCategoryMutation,
    useGetActionCategoriesQuery,
    useUpdateActionCategoryMutation,
} from '@orthly/graphql-react';
import MUITable from '@orthly/mui-table';
import { LoadBlocker, QuickForm, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Grid, IconButton, Icon, Tooltip, EditIcon } from '@orthly/ui-primitives';
import React from 'react';

interface ActionCategoryEditDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    refetch: () => void;
    category: LabsGqlActionCategoryDtoFragment | null;
}

interface ActionCategoryEditDialogFormData {
    parent_id: string;
    name: string;
}

const ActionCategoryEditDialog: React.FC<ActionCategoryEditDialogProps> = props => {
    const { category, refetch } = props;
    const { categories } = useActionCategories();
    const categoriesWithNone = React.useMemo(() => [{ value: '*none', label: '(None)' }, ...categories], [categories]);

    const closeAndRefetch = () => {
        refetch();
        props.setOpen(false);
    };

    const { submit: submitCreate, submitting: submittingCreate } = useRootActionCommand(
        useCreateActionCategoryMutation(),
        {
            successMessage: `Action category created!`,
            onSuccess: closeAndRefetch,
        },
    );
    const { submit: submitUpdate, submitting: submittingUpdate } = useRootActionCommand(
        useUpdateActionCategoryMutation(),
        {
            successMessage: `Action category updated!`,
            onSuccess: closeAndRefetch,
        },
    );

    return (
        <RootActionDialog
            title={`${category ? 'Edit' : 'Create'} Action Category`}
            open={props.open}
            setOpen={props.setOpen}
            loading={submittingCreate || submittingUpdate}
            CustomButton={() => null}
            content={
                <QuickForm<ActionCategoryEditDialogFormData>
                    fields={{
                        parent_id: {
                            label: 'Parent Category',
                            type: 'autocomplete',
                            options: categoriesWithNone,
                        },
                        name: { type: 'text' },
                    }}
                    initialValues={{ name: '', ...category, parent_id: category?.parent_id ?? '*none' }}
                    submitButtonTitle={category ? 'Save' : 'Create'}
                    onSubmit={async formResult => {
                        const { name } = formResult;
                        const parent_id = formResult.parent_id === '*none' ? null : formResult.parent_id;
                        if (category) {
                            await submitUpdate({
                                data: { name, parent_id, category_id: category.id, is_archived: false },
                            });
                        } else {
                            await submitCreate({
                                data: { name, parent_id },
                            });
                        }
                    }}
                />
            }
        />
    );
};

interface ActionCategoriesListRowActionProps {
    category: LabsGqlActionCategoryDtoFragment;
    onEdit: () => void;
    onArchiveChange: () => void;
}

export const ActionCategoriesListRowAction: React.VFC<ActionCategoriesListRowActionProps> = props => {
    const { category, onEdit, onArchiveChange } = props;

    const { submit: submitArchive, submitting: submittingArchive } = useRootActionCommand(
        useUpdateActionCategoryMutation(),
        {
            successMessage: `Action category ${category.is_archived ? 'restored' : 'archived'}!`,
            onSuccess: () => onArchiveChange(),
        },
    );

    return (
        <>
            <div style={{ display: 'flex' }}>
                <Tooltip title={`Edit`}>
                    <IconButton onClick={onEdit}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={`Delete`}>
                    <LoadBlocker blocking={submittingArchive} loader={`circular`}>
                        <IconButton
                            onClick={() => {
                                void submitArchive({
                                    data: {
                                        category_id: category.id,
                                        name: category.name,
                                        parent_id: category.parent_id,
                                        is_archived: !category.is_archived,
                                    },
                                });
                            }}
                        >
                            <Icon icon={category.is_archived ? 'Restore' : 'TrashIcon'} />
                        </IconButton>
                    </LoadBlocker>
                </Tooltip>
            </div>
        </>
    );
};

export const ActionCategoriesList: React.FC = () => {
    const { data, refetch } = useGetActionCategoriesQuery();
    const actionCategories = React.useMemo(() => data?.getActionCategories ?? [], [data]);
    const categoryNameById = React.useMemo(
        () => Object.fromEntries(actionCategories.map(category => [category.id, category.name])),
        [actionCategories],
    );
    const [isEditing, setIsEditing] = React.useState(false);
    const [editTarget, setEditTarget] = React.useState<LabsGqlActionCategoryDtoFragment | null>(null);

    const editCategory = (action: LabsGqlActionCategoryDtoFragment | null) => {
        setEditTarget(action);
        setIsEditing(true);
    };

    return (
        <Grid container>
            <MUITable<LabsGqlActionCategoryDtoFragment>
                title={''}
                data={actionCategories}
                displayOptions={{
                    fixedSearch: true,
                    elevation: 0,
                    viewColumns: true,
                    filter: true,
                    sort: true,
                }}
                actions={{
                    global: [
                        { icon: `refresh`, position: `toolbar`, onClick: () => refetch()?.catch(console.error) },
                        { icon: `add`, position: `toolbar`, onClick: () => editCategory(null), tooltip: `Create` },
                    ],
                }}
                columns={[
                    {
                        name: `Category`,
                        render: category => (category.parent_id ? categoryNameById[category.parent_id] : category.name),
                        filter: false,
                    },
                    {
                        name: `Subcategory`,
                        render: category => (category.parent_id ? category.name : '-'),
                        filter: false,
                    },
                    {
                        name: 'Archived',
                        hidden: true,
                        render: 'is_archived',
                        type: 'boolean',
                        filterOptions: { defaultValues: ['false'], exact: false, type: 'dropdown' },
                    },
                    {
                        name: '',
                        render: category => (
                            <ActionCategoriesListRowAction
                                category={category}
                                onEdit={() => editCategory(category)}
                                onArchiveChange={refetch}
                            />
                        ),
                    },
                ]}
            />
            <ActionCategoryEditDialog open={isEditing} setOpen={setIsEditing} category={editTarget} refetch={refetch} />
        </Grid>
    );
};
