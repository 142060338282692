import type { SceneAppearance, SceneModelAppearance } from './SceneAppearanceManager.types';
import { HeatMapType } from '@orthly/forceps';
import * as THREE from 'three';

export function createDefaultSceneAppearance(): SceneAppearance {
    return {
        scans: {
            upperJaw: createDefaultScanAppearance(),
            lowerJaw: createDefaultScanAppearance(),
            prePrepLowerJaw: createDefaultScanAppearance(),
            prePrepUpperJaw: createDefaultScanAppearance(),
        },
        restoratives: new Map(),
        marginLinesVisible: false,
        curtainsVisible: false,
        collisionsVisible: false,
        collisionsCurtainsVisible: false,
        insertionPathsVisible: false,
        activeHeatMap: undefined,
        curtainsHeatMapEnabled: false,
        heatmapRange: { min: 0, max: 1 },
        isMaskVisible: false,
        scanUndercutHeatmapEnabled: false,
    };
}

function createDefaultScanAppearance(): SceneModelAppearance {
    return {
        visible: false,
        transparent: false,
        colorize: true,
    };
}

export const heatmapName: { [K in HeatMapType]: string } = {
    [HeatMapType.Thickness]: 'thickness',
    [HeatMapType.Proximal]: 'proximal',
    [HeatMapType.Occlusal]: 'occlusal',
    [HeatMapType.Clearance]: 'clearance',
    [HeatMapType.VertexDisplacement]: 'vertex displacement',
    [HeatMapType.SurfaceDisplacement]: 'surface displacement',
    [HeatMapType.TissuePressure]: 'tissue pressure',
    [HeatMapType.AlignmentDistance]: 'alignment distance',
    [HeatMapType.Undercut]: 'undercut',
    [HeatMapType.CementGap]: 'cement gap',
};

export function createCurtainsNominalMaterial() {
    return new THREE.MeshPhongMaterial({
        color: 0xff0000,
        specular: new THREE.Color(0x111111),
        shininess: 90,
        side: THREE.DoubleSide,
    });
}

// Creates a mesh of the collision outlines
export function createCollisionsMesh(
    geometry: THREE.BufferGeometry,
    visibleMaterial: THREE.Material,
    collisionsCurtainMaterial: THREE.Material,
): THREE.Mesh {
    /**
     * The collisions geometry is expected to be divided into three groups:
     *   1. Proximal collision lines
     *   2. Proximal curtain collision lines
     *   3. Occlusal collision lines
     */

    // Here, we give the geometry a single group if it did not have any already.
    if (geometry.groups.length === 0) {
        console.warn('Collisions geometry unexpectedly has no groups.');
        geometry.addGroup(0, Infinity, 0);
    }

    const mesh = new THREE.Mesh(geometry, [visibleMaterial, collisionsCurtainMaterial, visibleMaterial]);
    mesh.renderOrder = 10000;

    return mesh;
}
