import { isItemEditorV2MultiToothItem } from '../sections/general/ItemEditorV2MultiToothField';
import { getMultiToothSelectValidationError } from '@orthly/dentin';
import type { ICartItemV2DTO, IOrderItemShade, ItemMetafieldV2 } from '@orthly/items';
import {
    ALIGNER_ARCH_CHOICE_METAFIELD_ID,
    CartItemV2Utils,
    DENTURES_ARCH_CHOICE_METAFIELD_ID,
    ItemDataFieldUtils,
    ItemMetafieldV2Utils,
    LabOrderItemSKUType,
} from '@orthly/items';
import _ from 'lodash';

export class ItemEditorV2Utils {
    static IGNORED_METAFIELD_IDS = [
        // covered by arch select
        ALIGNER_ARCH_CHOICE_METAFIELD_ID,
        DENTURES_ARCH_CHOICE_METAFIELD_ID,
        'night-guard-arch-choice-order-item-meta',
        'retainer-arch-choice-order-item-meta',
        'tmj-arch-order-item-meta',
        // covered by tooth select
        'partial-teeth-selection-order-item-meta',
        'surgical-guide-tooth-numbers-order-item-meta',
        // covered by custom surgical guide implant metadata input
        'surgical-guide-implant-manufacturer-order-item-meta',
        'surgical-guide-implant-system-order-item-meta',
        'surgical-guide-kit-order-item-meta',
        'surgical-guide-implant-length-order-item-meta',
        // never actually used
        'sleep-apnea-should-have-posterior-support-pads-order-item-meta',
    ];

    static getMetafieldsForItem(item: ICartItemV2DTO): ItemMetafieldV2[] {
        const metafields = ItemMetafieldV2Utils.getMetafieldsForItemExcludingDataFields(item);
        return metafields.filter(field => !ItemEditorV2Utils.IGNORED_METAFIELD_IDS.includes(field.id));
    }

    // verifies that an item in the editor has all required fields filled out
    static isItemComplete(item: ICartItemV2DTO, isInternal: boolean): boolean {
        // covers most of the basic fields like material, implant metadata and more
        if (!CartItemV2Utils.isItemComplete(item)) {
            return false;
        }
        // for multi teeth items, make sure that they have selected teeth
        if (isItemEditorV2MultiToothItem(item)) {
            // we only check validation error when external, see ItemEditorV2MultiToothField for reasoning
            if (!isInternal && getMultiToothSelectValidationError(item)) {
                return false;
            }
            // internal users are able to skip teeth select for partials, see ItemEditorV2MultiToothField for reasoning
            const isInternalPartial = isInternal && CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Partial);
            if (!isInternalPartial && CartItemV2Utils.getUniqueUNNs(item).length === 0) {
                return false;
            }
        }
        // check that base shade exists for applicable items
        if (CartItemV2Utils.itemRequiresShade(item) && !item.shades.some(shade => shade.name === 'base')) {
            return false;
        }
        // check that tissue shade exists for applicable items
        if (CartItemV2Utils.itemRequiresTissueShade(item) && !item.shades.some(shade => shade.name === 'tissue')) {
            return false;
        }
        // check that required metafields are complete
        const itemMetaFields = ItemEditorV2Utils.getMetafieldsForItem(item);
        const requiredMetafields = itemMetaFields.filter(metafield => metafield?.optional !== true);
        if (!requiredMetafields.every(field => item.preference_fields.some(pref => pref.field_id === field.id))) {
            return false;
        }
        // check that datafields are complete
        if (!ItemDataFieldUtils.areAllRequiredDataFieldsComplete(item)) {
            return false;
        }
        // check text field validators
        const hasTextValidationError = itemMetaFields.some(metafield => {
            if (metafield.type === 'text' && metafield.validator) {
                const value = item.preference_fields.find(pref => pref.field_id === metafield.id)?.value;
                if (_.isNil(value)) {
                    return !metafield.optional;
                }
                return metafield.validator(value) !== null;
            }
        });
        if (hasTextValidationError) {
            return false;
        }

        // if every check has passed then we're valid
        return true;
    }

    // cleans preference fields or values set from data fields that are no longer relevant
    static cleanItem(item: ICartItemV2DTO): ICartItemV2DTO {
        const cleanedDataFieldsItem = ItemDataFieldUtils.cleanInapplicableValues(item);
        const metafields = ItemEditorV2Utils.getMetafieldsForItem(item);
        return {
            ...cleanedDataFieldsItem,
            shades: ItemEditorV2Utils.cleanItemShades(cleanedDataFieldsItem),
            preference_fields: cleanedDataFieldsItem.preference_fields.filter(pref =>
                metafields.some(field => field.id === pref.field_id),
            ),
        };
    }

    private static cleanItemShades(item: ICartItemV2DTO): IOrderItemShade[] {
        return _.chain(item.shades)
            .thru(shades =>
                !CartItemV2Utils.itemRequiresTissueShade(item)
                    ? shades.filter(shade => shade.name !== 'tissue')
                    : shades,
            )
            .thru(shades =>
                !CartItemV2Utils.itemRequiresShade(item)
                    ? shades.filter(shade => !['base', 'incisal', 'gingival', 'stump'].includes(shade.name))
                    : shades,
            )
            .value();
    }
}
