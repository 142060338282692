import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { SimpleSelect } from '@orthly/ui';
import React from 'react';

export const ItemEditorV2TissueShadeField: React.VFC<ItemEditorV2BaseProps> = ({ item, updateItem, showError }) => {
    const value = item.shades.find(shade => shade.name === 'tissue')?.value;

    return (
        <SimpleSelect
            label={'Tissue Shade'}
            value={value}
            onChange={val => {
                updateItem({
                    name: 'shades',
                    payload: item.shades
                        .filter(shade => shade.name !== 'tissue')
                        .concat(val ? [{ value: val, name: 'tissue' }] : []),
                });
            }}
            // these options are only relevant for full dentures - if updated to be used for partial dentures, shade values must be dynamic by item type
            options={['Original', 'Light Pink', 'Dark Pink'].map(value => ({ value, label: value }))}
            errorText={showError && !value ? 'This field is required' : undefined}
        />
    );
};
