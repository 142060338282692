import { DesignerStatusTimeoutDialog } from './DesignerStatusTimeoutDialog.graphql';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { useDesignStaffDetailedStatusLazyQuery } from '@orthly/graphql-react';
import type { LabsGqlDesignStaffDetailedStatusAssignment } from '@orthly/graphql-schema';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';

type Assignment = Pick<
    LabsGqlDesignStaffDetailedStatusAssignment,
    'auto_assignment_expiration' | 'order_id' | 'task_id'
>;

const WARNING_MINUTES = 30;

/** Returns the assignment whose expiration is the soonest after the current date. */
function earliestExpiringAssignment(
    assignments: Assignment[],
): { assignment: Assignment; expiration: Date } | undefined {
    const now = new Date();
    const assignmentsAndExpirations = _(assignments)
        .map(a => ({
            assignment: a,
            expiration: a.auto_assignment_expiration ? new Date(a.auto_assignment_expiration) : now,
        }))
        .filter(i => i.expiration > now)
        .sortBy(i => i.expiration)
        .value();
    return assignmentsAndExpirations[0];
}

interface DesignerStatusTimeoutProviderProps {
    userId: string;
    assignments?: Assignment[];
    refetch?: () => any;
}

export const DesignerStatusTimeoutProvider: React.FC<DesignerStatusTimeoutProviderProps> = props => {
    const { userId, assignments, refetch } = props;

    const [expiringAssignment, setExpiringAssignment] = React.useState<Assignment | undefined>();
    const [dialogOpen, setDialogOpen] = React.useState(false);

    // This lazy query runs only when we think a task is about to expire. Double-check that there really
    // is a task within `warningMinutes` of its expiration date, and then show the dialog for that task.
    const [getLatestStatus, { loading: fetchingLatestStatus }] = useDesignStaffDetailedStatusLazyQuery({
        variables: { user_id: userId },
        onCompleted: data => {
            const earliestExpiring = earliestExpiringAssignment(data.designStaffDetailedStatus.auto_assignments);
            if (earliestExpiring && dayjs(earliestExpiring.expiration).diff(dayjs(), 'minutes') <= WARNING_MINUTES) {
                const assignment = earliestExpiring.assignment;

                BrowserAnalyticsClientFactory.Instance?.track('Design - Task Timeout Warning', {
                    $groups: {
                        order: assignment.order_id,
                    },
                    taskId: assignment.task_id,
                    expiresAt: assignment.auto_assignment_expiration ?? undefined,
                });

                setExpiringAssignment(assignment);
                setDialogOpen(true);
            }
        },
    });

    // Maintain a timer for `warningMinutes` previous to the next task's expiration date.
    React.useEffect(() => {
        const earliestExpiring = assignments && earliestExpiringAssignment(assignments);
        if (!dialogOpen && !fetchingLatestStatus && earliestExpiring) {
            const timeoutDurationMillis = dayjs(earliestExpiring.expiration)
                .subtract(WARNING_MINUTES, 'minutes')
                .diff(dayjs());
            const timer = setTimeout(() => {
                // Note: This may not work as expected when the component is hot-reloaded.
                // If you're debugging and it seems not to be working, try refreshing the page.
                getLatestStatus();
            }, timeoutDurationMillis);
            return () => clearTimeout(timer);
        }
    }, [dialogOpen, assignments, fetchingLatestStatus, getLatestStatus]);

    if (!expiringAssignment) {
        return null;
    }
    return (
        <DesignerStatusTimeoutDialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            assignment={expiringAssignment}
            refetch={refetch}
        />
    );
};
