import { LabsGqlDesignOrderNoteCategory } from '@orthly/graphql-schema';

export const isMarginOptionDisabled = (
    option: LabsGqlDesignOrderNoteCategory,
    selectionsContain: (options: LabsGqlDesignOrderNoteCategory[]) => boolean,
): boolean => {
    switch (option) {
        case LabsGqlDesignOrderNoteCategory.MarginBuccalExtend:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.MarginBuccalAdjustOverextended]);

        case LabsGqlDesignOrderNoteCategory.MarginBuccalAdjustOverextended:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.MarginBuccalExtend]);

        case LabsGqlDesignOrderNoteCategory.MarginLingualExtend:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.MarginLingualAdjustOverextended]);

        case LabsGqlDesignOrderNoteCategory.MarginLingualAdjustOverextended:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.MarginLingualExtend]);

        case LabsGqlDesignOrderNoteCategory.MarginMesialExtend:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.MarginMesialAdjustOverextended]);

        case LabsGqlDesignOrderNoteCategory.MarginMesialAdjustOverextended:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.MarginMesialExtend]);

        case LabsGqlDesignOrderNoteCategory.MarginDistalExtend:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.MarginDistalAdjustOverextended]);

        case LabsGqlDesignOrderNoteCategory.MarginDistalAdjustOverextended:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.MarginDistalExtend]);

        default:
            return false;
    }
};
