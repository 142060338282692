import { LabsUtilsBase } from '@orthly/dentin';
import type {
    LabsGqlUpdateManufacturerLabsNotificationPrefsMutationVariables,
    LabsGqlOrganizationDtoFragment,
} from '@orthly/graphql-operations';
import {
    useGetManufacturerPreferencesPreferencesQuery,
    useUpdateManufacturerLabsNotificationPrefsMutation,
} from '@orthly/graphql-react';
import { LabsGqlManufacturerNotificationType } from '@orthly/graphql-schema';
import { QuickForm, QuickFormValidations, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

interface EditManufacturerNotificationPrefsProps {
    manufacturer: LabsGqlOrganizationDtoFragment;
}

type Vars = LabsGqlUpdateManufacturerLabsNotificationPrefsMutationVariables['data'];

export const EditManufacturerNotificationPrefsAction: React.FC<EditManufacturerNotificationPrefsProps> = props => {
    const currentMfe = props.manufacturer;
    const [submitMtn] = useUpdateManufacturerLabsNotificationPrefsMutation();
    const manufacturerId = currentMfe.id;
    const { data, refetch } = useGetManufacturerPreferencesPreferencesQuery({
        variables: { manufacturer_id: manufacturerId },
    });
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data, manufacturer_id: manufacturerId || '' } });
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Preferences updated!', {}],
        onSuccess: () => refetch(),
    });

    const currentPrefs = data?.getManufacturerPreferencesPreferences;
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            showCloseButton={true}
            title={'Edit Notification Preferences'}
            content={
                <QuickForm<Vars>
                    fields={{
                        emails: {
                            type: 'array',
                            label: 'Emails',
                            of: {
                                type: 'text',
                                label: 'Email',
                                optional: false,
                                validation: QuickFormValidations.email,
                            },
                            min: 0,
                            max: 10,
                            optional: false,
                        },
                        phone_number: {
                            type: 'text',
                            mask: '+19999999999',
                            validation: QuickFormValidations.phone,
                            optional: true,
                            fieldProps: { style: { marginBottom: 20 }, variant: 'standard' },
                        },
                        time_zone: {
                            type: 'select',
                            options: LabsUtilsBase.notificationTimeZoneOptions,
                            helperText: 'Used to time notifications properly',
                        },
                        new_order_notifications: {
                            type: 'multiselect',
                            options: Object.values(LabsGqlManufacturerNotificationType),
                        },
                    }}
                    initialValues={{
                        emails: currentPrefs?.emails || [],
                        phone_number: currentPrefs?.phone_number || null,
                        time_zone: currentPrefs?.time_zone || null,
                        new_order_notifications: (currentPrefs?.new_order_notifications as any) ?? [],
                    }}
                    onSubmit={async val => {
                        await submit({
                            ...val,
                            emails: val.emails || [],
                            new_order_notifications: val.new_order_notifications,
                        });
                    }}
                    dirtySubmitOnly={true}
                    submitButtonProps={{
                        children: 'Save Notification Preferences',
                    }}
                />
            }
            buttonText={'Edit Notification Prefs'}
        />
    );
};
