import { useFirebasePreviewMulti } from '../../hooks/useFirebaseDownload';
import { DandyLightbox } from '../DandyLightbox/DandyLightbox';
import type {
    LabsGqlCompleteQualityControlItemMutationVariables,
    LabsGqlIgnoreQualityControlItemMutationVariables,
} from '@orthly/graphql-operations';
import {
    useCompleteQualityControlItemMutation,
    useIgnoreQualityControlItemMutation,
    useQualityControlItemsForOrderQuery,
} from '@orthly/graphql-react';
import type { LabsGqlQualityControlItemResult } from '@orthly/graphql-schema';
import { UuidUtils } from '@orthly/runtime-utils';
import { useSession } from '@orthly/session-client';
import { LoadBlocker, SmallTrashCanIcon, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, Text, IconButton, CheckboxPrimitive, Grid } from '@orthly/ui-primitives';
import React from 'react';

type CompleteQCItemVars = LabsGqlCompleteQualityControlItemMutationVariables['data'];

export function useCompleteQualityControlItem(onSuccess?: () => void) {
    const [submitMtn] = useCompleteQualityControlItemMutation();
    const mtnSubmitter = (data: CompleteQCItemVars) => submitMtn({ variables: { data } });
    return useChangeSubmissionFn<any, [CompleteQCItemVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['QC Item Updated!', {}],
        onSuccess: () => onSuccess?.(),
    });
}

type IgnoreQCItemVars = LabsGqlIgnoreQualityControlItemMutationVariables['data'];

export function useIgnoreQualityControlItem(onSuccess?: () => void) {
    const [submitMtn] = useIgnoreQualityControlItemMutation();
    const mtnSubmitter = (data: IgnoreQCItemVars) => submitMtn({ variables: { data } });
    return useChangeSubmissionFn<any, [IgnoreQCItemVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['QC Item Updated!', {}],
        onSuccess: () => onSuccess?.(),
    });
}

const IMAGE_SIZE = 60;

const QcChecklistItemImages: React.VFC<{ orderId: string; images: string[] }> = ({ orderId, images }) => {
    const [selected, setSelected] = React.useState<string | undefined>();
    const sources = React.useMemo(
        () => images.map((source, i) => ({ source, name: `Checklist image #${i + 1}` })),
        [images],
    );
    const { result } = useFirebasePreviewMulti(sources);
    return (
        <>
            {result?.map(preview => (
                <div
                    key={preview.name}
                    style={{
                        backgroundImage: `url("${preview.source}")`,
                        backgroundSize: `cover`,
                        width: IMAGE_SIZE,
                        height: IMAGE_SIZE,
                        cursor: `pointer`,
                        flexGrow: 0,
                        flexShrink: 0,
                    }}
                    onClick={() => setSelected(preview.name)}
                />
            )) ?? null}
            <DandyLightbox
                setSelectedAttachmentPreview={setSelected}
                previews={result ?? []}
                selectedAttachmentPreview={selected}
                onPhotoViewedAnalytics={(source, name) => {
                    return {
                        name: 'All - Portal - Photo Lightbox Viewed',
                        data: {
                            $groups: { order: orderId },
                            displayLocation: 'qc_station',
                            photoSource: source,
                            photoName: name,
                        },
                    };
                }}
            />
        </>
    );
};

interface QCChecklistItemProps {
    item: LabsGqlQualityControlItemResult;
    orderId: string;
    disabled: boolean;
    checked: boolean;
    onChange(checked: boolean): void;
    onDelete(): void;
}

const QCChecklistItem: React.FC<QCChecklistItemProps> = ({ orderId, item, disabled, checked, onChange, onDelete }) => {
    const { id: itemId, title, description, attachments } = item;
    const isPsr = useSession()?.organization_type === 'internal';
    const { submit: submitIgnore, submitting: submittingIgnore } = useIgnoreQualityControlItem(() => onDelete());

    return (
        <LoadBlocker blocking={submittingIgnore}>
            <Grid
                container
                direction={'row'}
                wrap={'nowrap'}
                style={
                    disabled
                        ? {
                              pointerEvents: 'none',
                              opacity: 0.5,
                          }
                        : {}
                }
            >
                <Grid container direction={'column'} style={{ flexBasis: 0 }}>
                    <CheckboxPrimitive
                        color={'secondary'}
                        style={{ padding: 1, margin: `0 16px` }}
                        checked={checked}
                        onChange={event => onChange(event.target.checked)}
                    />
                    {isPsr && (
                        <IconButton
                            style={{ width: 16, height: 16, margin: `0 16px` }}
                            onClick={() => submitIgnore({ orderId, item_id: itemId, ignored: true })}
                        >
                            <SmallTrashCanIcon style={{ fontSize: `0.75em` }} color={'inherit'} />
                        </IconButton>
                    )}
                </Grid>
                <Grid style={{ flexGrow: 1 }}>
                    <Text variant={'body2'}>{title}</Text>
                    <Text variant={'caption'}>{description}</Text>
                </Grid>
                <QcChecklistItemImages orderId={orderId} images={attachments ?? []} />
            </Grid>
        </LoadBlocker>
    );
};

interface QCItemChecklistViewProps {
    items: LabsGqlQualityControlItemResult[];
    orderId: string;
    disabled: boolean;
    checkedItems: Record<string, boolean>;
    onChange(itemId: string, checked: boolean): void;
    onDelete(itemId: string): void;
}

export const QCItemChecklistView: React.FC<QCItemChecklistViewProps> = ({
    items,
    orderId,
    disabled,
    checkedItems,
    onChange,
    onDelete,
}) => {
    return (
        <>
            {items.map((item, idx) => (
                <Grid
                    key={item.id}
                    style={{
                        borderBottom: idx === items.length - 1 ? undefined : `1px solid ${FlossPalette.DARK_TAN}`,
                        padding: '8px 0',
                    }}
                >
                    <QCChecklistItem
                        orderId={orderId}
                        item={item}
                        disabled={disabled}
                        checked={!!checkedItems[item.id]}
                        onChange={checked => onChange(item.id, checked)}
                        onDelete={() => onDelete(item.id)}
                    />
                </Grid>
            ))}
        </>
    );
};

export interface QCItemChecklistProps {
    orderId: string;
    items: LabsGqlQualityControlItemResult[];
    loading: boolean;
    refetch: () => Promise<any>;
}

export function useQCItemChecklistProps(
    orderId: string,
    skip: boolean = false,
    includeIgnored: boolean = false,
): QCItemChecklistProps {
    const { data, loading, refetch } = useQualityControlItemsForOrderQuery({
        skip: skip || !UuidUtils.isUUID(orderId),
        variables: { order_id: orderId },
    });
    const items = React.useMemo(() => {
        const items = data?.getQCItemsForOrder ?? [];
        return items.filter(i => !i.ignore || includeIgnored);
    }, [data, includeIgnored]);
    return { refetch, loading, items, orderId };
}

export const QCItemChecklist: React.FC<QCItemChecklistProps> = ({ orderId, items, refetch }) => {
    const { submit: submitChecked, submitting } = useCompleteQualityControlItem(() => refetch());
    const onItemChange = React.useCallback(
        (itemId: string, checked: boolean) => {
            void submitChecked({
                orderId,
                item_id: itemId,
                complete: checked,
            });
        },
        [orderId, submitChecked],
    );

    return (
        <QCItemChecklistView
            items={items}
            orderId={orderId}
            disabled={submitting}
            checkedItems={items.reduce((checkedItems, item) => ({ ...checkedItems, [item.id]: item.complete }), {})}
            onChange={onItemChange}
            onDelete={() => refetch()}
        />
    );
};
