import { PrintableSlipSectionTitle } from '../../../../PrintableSlip/components/shared/PrintableSlipSectionTitle';
import { getNotesByCategory } from '../../../../PrintableSlip/utils/notes.util';
import type { IStructuredDoctorNote } from '../../../LabSlip.types';
import { getGroupedItemNotes } from '../../utils/labSlip.util';
import { CstNotes } from './notes/CSTNotes';
import { ClaspPlacementNotes } from './notes/ClaspNotes';
import { DoctorNotes } from './notes/DoctorNotes';
import { LabNotes } from './notes/LabNotes';
import { RefabNotes } from './notes/RefabNotes';
import { TryInFeedbackNotes } from './notes/TryInFeedbackNotes';
import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import { OrderItemV2Utils } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface ILabSlipNotesProps {
    order: LabsGqlLabOrderForLabSlipFragment;
}

/**
 * Notes section of the Order Lab Slip.
 * Renders the various notes from the order (Dr. notes, Lab Notes, etc.)
 * @param props
 * @returns Order Lab Slip section with notes from the order
 */
export const LabSlipNotes: React.VFC<ILabSlipNotesProps> = props => {
    const { order } = props;

    const groupedItemNotes = React.useMemo(() => {
        const items = OrderItemV2Utils.parseItems(order.items_v2);
        const groupedItemNotes: IStructuredDoctorNote[] = getGroupedItemNotes(items);
        if (!!order.doctor_notes) {
            groupedItemNotes.unshift({ note: order.doctor_notes });
        }
        return groupedItemNotes;
    }, [order]);

    const { labNotes } = React.useMemo(() => getNotesByCategory(order.lab_slip_alerts), [order.lab_slip_alerts]);

    return (
        <Grid container>
            <PrintableSlipSectionTitle text={'Notes'} />
            <LabNotes labNotes={labNotes} />
            <DoctorNotes doctorNotes={groupedItemNotes} />
            <CstNotes clincialSupportNotes={order.clinical_support_notes} />
            <ClaspPlacementNotes items={order.items_v2} />
            <TryInFeedbackNotes feedbackDetails={order.try_in_feedback_details} />
            <RefabNotes notesForRefabrication={order.notes_for_refabrication} />
        </Grid>
    );
};
