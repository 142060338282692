import { UploadInjectionMoldFilesDialog } from '../../OrderTickets/UploadInjectionMoldFilesDialog.graphql';
import type { MenuItemProps } from '../OrderDetailToolbarFiles.types';
import { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { MenuItem } from '@orthly/ui-primitives';
import React from 'react';

export const ReplaceInjectionMoldFilesMenuItem: React.VFC<MenuItemProps> = props => {
    const { order, refetchOrder } = props;
    const [open, setOpen] = React.useState(false);

    // Only allow replacement if injection mold files have previously been attached.
    if (!order.fulfillment_workflow.closed_tasks.some(t => t.type === LabsGqlWorkflowTaskType.InjectionMoldDesign)) {
        return null;
    }

    return (
        <>
            <MenuItem onClick={() => setOpen(true)}>{`Replace Injection Mold Files`}</MenuItem>
            <UploadInjectionMoldFilesDialog orderId={order.id} open={open} setOpen={setOpen} refetch={refetchOrder} />
        </>
    );
};
