import type { OrderDetailContext } from '../../state/OrderDetailProvider.graphql';
import { CreateTicketDialog } from '../OrderTickets/CreateTicketDialog';
import { TicketsTrackingBlock } from '../OrderTickets/TicketsTrackingBlock';
import type { AdminLabsOrderActionProps } from '../actions/order-action-types';
import { DesignTrackingBlock } from './DesignTrackingBlock';
import { ReviewTrackingBlock } from './ReviewTrackingBlock';
import { StatusTrackingBlock } from './StatusTrackingBlock';
import { OrderListItemTrackerUtils } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { AddOrderDelayAction } from '@orthly/veneer';
import {
    FlagScanAction,
    OrderDetailBlock,
    OrderDetailBlockTabs,
    TatProgressTrackingBlock,
    useFeatureFlag,
} from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const AdminOrderDetailTrackingBlockSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailTrackingBlockSalesOrder_Fragment on LabSalesOrderDTO {
        ...AdminOrderDetailAdditionalInfoSalesOrder_Fragment
    }
`);

export interface OrderDetailTrackingBlockProps extends AdminLabsOrderActionProps {
    orderTrackerEntries: OrderDetailContext['orderTrackerEntries'];
    salesOrderFragment: FragmentType<typeof AdminOrderDetailTrackingBlockSalesOrder_Fragment>;
}

enum TrackingTab {
    TatProgress = 'TAT',
    Status = 'Status',
    Tickets = 'Tickets',
    Review = 'Review',
    Design = 'Design',
}

const ActionByTrackingTab: Record<TrackingTab, React.VFC<OrderDetailTrackingBlockProps>> = {
    [TrackingTab.TatProgress]: () => null,
    [TrackingTab.Status]: ({ order, refetchOrder }) =>
        order && (
            // TS doesn't agree that the Pick'd version of the LabOrder is acceptable in this record,
            // so we have to do this.
            <AddOrderDelayAction order={order} refetchOrder={refetchOrder} />
        ),
    [TrackingTab.Tickets]: CreateTicketDialog,
    [TrackingTab.Design]: ({ order, refetchOrder }) =>
        order.fulfillment_workflow.active_task?.type === LabsGqlWorkflowTaskType.ResolveScanRejection ? null : (
            <FlagScanAction order={order} refetchOrder={refetchOrder} />
        ),
    [TrackingTab.Review]: () => null,
};

const TrackingBlockByTrackingTab: Record<TrackingTab, React.VFC<OrderDetailTrackingBlockProps>> = {
    [TrackingTab.TatProgress]: TatProgressTrackingBlock,
    [TrackingTab.Status]: StatusTrackingBlock,
    [TrackingTab.Tickets]: TicketsTrackingBlock,
    [TrackingTab.Design]: DesignTrackingBlock,
    [TrackingTab.Review]: ReviewTrackingBlock,
};

const initialTrackingTabForOrder = (order: LabsGqlLabOrderFragment) => {
    const type = order.fulfillment_workflow.active_task?.type;
    if (type === LabsGqlWorkflowTaskType.ReviewOrder) {
        return TrackingTab.Review;
    }
    if (OrderListItemTrackerUtils.isDesignTaskForInternal(type)) {
        return TrackingTab.Design;
    }
    return TrackingTab.Status;
};

export const OrderDetailTrackingBlock: React.VFC<OrderDetailTrackingBlockProps> = props => {
    const [active, setActive] = React.useState(initialTrackingTabForOrder(props.order));
    // Not renaming the feature flag to use Tat instead of Sla
    const { value: shouldEnableTatProgress } = useFeatureFlag('enableSlaProgressTab');

    const ActiveAction = ActionByTrackingTab[active];
    const ActiveTrackingBlock = TrackingBlockByTrackingTab[active];

    const titles = _.compact([
        shouldEnableTatProgress && TrackingTab.TatProgress,
        TrackingTab.Status,
        TrackingTab.Tickets,
        TrackingTab.Review,
        TrackingTab.Design,
    ]);

    return (
        <OrderDetailBlock
            title={<OrderDetailBlockTabs<TrackingTab> titles={titles} active={active} onClick={setActive} />}
            variant={`full`}
            actions={<ActiveAction {...props} />}
        >
            <ActiveTrackingBlock {...props} />
        </OrderDetailBlock>
    );
};
