import { LabsGqlScanReviewWorkOrderRejectionCategory } from '@orthly/graphql-schema';

export const ScanReviewStatusEnumToLabelMap: Record<LabsGqlScanReviewWorkOrderRejectionCategory, string> = {
    [LabsGqlScanReviewWorkOrderRejectionCategory.BiteScanConfirmation]: 'Bite scan confirmation',
    [LabsGqlScanReviewWorkOrderRejectionCategory.DrApprovalNeededOnMargin]: 'Dr approval needed on margin',
    [LabsGqlScanReviewWorkOrderRejectionCategory.FileDownloadError]: 'File download error',
    [LabsGqlScanReviewWorkOrderRejectionCategory.IncorrectImplantScanBody]: 'Implant: Incorrect scan body',
    [LabsGqlScanReviewWorkOrderRejectionCategory.ImplantSpecsConfirmation]: 'Implant specs confirmation',
    [LabsGqlScanReviewWorkOrderRejectionCategory.InsufficientClearance]: 'Insufficient Clearance',
    [LabsGqlScanReviewWorkOrderRejectionCategory.OpposingArchInsufficient]: 'Opposing arch missing or insufficient',
    [LabsGqlScanReviewWorkOrderRejectionCategory.OrderSpecificationConfirmation]:
        'Order specification confirmation (shade, material or tooth number)',
    [LabsGqlScanReviewWorkOrderRejectionCategory.PathOfInsertionIssue]: 'Path of insertion issue',
    [LabsGqlScanReviewWorkOrderRejectionCategory.ScanError]: 'Scan error (insufficient images/quality)',
    [LabsGqlScanReviewWorkOrderRejectionCategory.TissueOverMargin]: 'Tissue over margin',
    [LabsGqlScanReviewWorkOrderRejectionCategory.Other]: 'Other',
};
