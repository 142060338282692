import type { ButtonVariant, IconName } from '@orthly/ui-primitives';

export type OrderEditCustomStrings = {
    reviewTitle?: string;
    submitText?: string;
};

type GetNextTitleProps = {
    isLastPage: boolean;
    isLoading: boolean;
    nextIsSkip: boolean | undefined;
    defaultTitle?: string;
    customStrings?: OrderEditCustomStrings;
};
export const getNextTitle = ({ isLastPage, isLoading, nextIsSkip, defaultTitle, customStrings }: GetNextTitleProps) => {
    if (isLoading) {
        return 'Loading...';
    } else if (isLastPage) {
        if (customStrings?.submitText) {
            return customStrings?.submitText;
        }
        return 'Submit Changes';
    } else if (nextIsSkip) {
        return 'Skip';
    } else {
        return defaultTitle ?? 'Next';
    }
};

type GetNextButtonProps = {
    isLastPage: boolean;
    nextIsSkip: boolean | undefined;
};

export const getNextButtonProps = ({ isLastPage, nextIsSkip }: GetNextButtonProps) => {
    const variant: ButtonVariant = nextIsSkip ? 'secondary-gray' : 'primary';

    return {
        endIcon: isLastPage ? undefined : ('ChevronRight' as IconName | undefined),
        variant,
    };
};
