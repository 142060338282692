import type { ActionCardVariant } from '@orthly/ui';
import { ActionCardIcon } from '@orthly/ui';
import type { SvgIconProps } from '@orthly/ui-primitives';
import { FlossPalette, stylesFactory, Text, Grid, Button } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    actionCardIcon: {
        marginRight: 16,
        width: 36,
        height: 36,
        '& img, & svg': { width: 24, height: 24 },
    },
}));

interface InboxCardIconProps {
    variant: ActionCardVariant;
    IconComponent?: React.ComponentType<SvgIconProps>;
    style?: React.CSSProperties;
}

export const InboxCardIcon: React.VFC<InboxCardIconProps> = ({ variant, IconComponent, style }) => {
    const classes = useStyles();

    return (
        <ActionCardIcon
            variant={variant}
            IconComponent={IconComponent}
            className={classes.actionCardIcon}
            style={style}
        />
    );
};

interface InboxCardIcon {
    variant: ActionCardVariant;
    IconComponent?: React.ComponentType<SvgIconProps>;
    iconStyle?: React.CSSProperties;
    body: React.ReactNode;
    buttonText?: string;
    onClick?: () => void;
    imageStyles: React.CSSProperties;
}

export type InboxCardProps = {
    height?: number | string;
    title?: string;
    titleAction?: {
        label: string;
        onClick: () => void;
    };
    component?: React.ReactNode;
    backgroundColor?: 'transparent' | keyof FlossPalette;
    onClick?: () => void;
    allowOverflow?: boolean;
    /**
     * So we can hide borders for welcome cards.
     */
    isWelcomeCard?: boolean;
};

type InboxCardTitleProps = Pick<InboxCardProps, 'titleAction'> & { title: string };

export const InboxCardTitle: React.VFC<InboxCardTitleProps> = props => {
    return (
        <Grid
            item
            style={{ padding: '24px 12px', paddingBottom: props.titleAction ? 6 : 12, minHeight: 32 }}
            container
            direction={'row'}
        >
            <Grid item style={{ paddingLeft: 12 }}>
                <Text variant={'h6'} medium>
                    {props.title}
                </Text>
            </Grid>
            {props.titleAction && (
                <Grid item style={{ marginLeft: 'auto' }}>
                    <Button
                        style={{ height: 32 }}
                        variant={'ghost'}
                        endIcon={'ChevronRight'}
                        onClick={props.titleAction.onClick}
                        analytics={{
                            AssetLocation: 'Chairside - Inbox - Card',
                            AssetName: props.title,
                        }}
                    >
                        {props.titleAction.label}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export const InboxCard: React.FC<InboxCardProps> = props => {
    return (
        <Grid
            item
            xs={props.height ? undefined : true}
            onClick={props.onClick}
            style={{
                border: props.isWelcomeCard ? 0 : '1px solid',
                borderColor: FlossPalette.STROKE_LIGHT,
                borderRadius: 16,
                maxWidth: '100%',
                maxHeight: props.height ?? '100%',
                height: props.height ?? '100%',
                width: '100%',
                margin: '8px 0px',
            }}
        >
            <Grid
                item
                xs
                style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: 16,
                    backgroundColor:
                        props.backgroundColor === 'transparent'
                            ? undefined
                            : FlossPalette[props.backgroundColor ?? 'WHITE'],
                    overflow: props.allowOverflow ? undefined : 'hidden',
                }}
            >
                {props.title && <InboxCardTitle title={props.title} titleAction={props.titleAction} />}
                {props.component}
                {props.children}
            </Grid>
        </Grid>
    );
};
