import { useMutation } from '@apollo/client';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlOrderingPausedPracticeReason } from '@orthly/graphql-schema';
import { RootActionDialog, SimpleMultiSelect, useRootActionCommand } from '@orthly/ui';
import { Grid, Button, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const AdminPausedPracticeEditorModalPausePractice_Mutation = graphql(`
    mutation AdminPausedPracticeEditorModalPausePractice_Mutation($data: UpdatePracticeOrderingPauseReasons!) {
        updatePracticeOrderingPauseReasons(data: $data) {
            id
        }
    }
`);

interface PausePracticeModalProps {
    open: boolean;
    practiceId: string;
    setOpen: (open: boolean) => void;
    currentReasons: LabsGqlOrderingPausedPracticeReason[];
    refetch: () => Promise<unknown>;
}

export const PausedPracticeEditorModal: React.VFC<PausePracticeModalProps> = ({
    open,
    setOpen,
    practiceId,
    currentReasons,
    refetch,
}) => {
    const { value: enableOutstandingBalanceCommunications } = useFeatureFlag('enableOutstandingBalanceCommunications');
    const [reasons, setReasons] = React.useState<LabsGqlOrderingPausedPracticeReason[]>(currentReasons);

    const isPracticeCurrentlyPaused = currentReasons.length > 0;
    const isPausedBeingRemoved = isPracticeCurrentlyPaused && reasons.length === 0;

    const pauseMutation = useMutation(AdminPausedPracticeEditorModalPausePractice_Mutation);
    const { submit, submitting } = useRootActionCommand(pauseMutation, {
        successMessage: 'Updated practice ordering pause.',
        onSuccess: async () => {
            BrowserAnalyticsClientFactory.Instance?.track(
                isPausedBeingRemoved
                    ? 'Ops - Portal - Billing - Practice Resumed'
                    : 'Ops - Portal - Billing - Practice Paused',
                { practiceId },
            );

            await refetch();
            setOpen(false);
        },
    });

    // eslint-disable-next-line no-nested-ternary
    const submitText = isPausedBeingRemoved
        ? 'Remove Pause'
        : isPracticeCurrentlyPaused
          ? 'Update Pause'
          : 'Create Pause';

    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            title={`Update Practice Ordering Pause`}
            loading={false}
            content={
                <Grid container direction={'column'} spacing={2}>
                    <Grid item>
                        <SimpleMultiSelect
                            label={'Reasons'}
                            options={[
                                {
                                    value: LabsGqlOrderingPausedPracticeReason.TooManyUnpaidInvoices,
                                    label: 'Too many unpaid invoices',
                                },
                            ]}
                            onChange={reasons => setReasons((reasons ?? []) as LabsGqlOrderingPausedPracticeReason[])}
                            value={reasons}
                        />
                    </Grid>
                    <Grid item>
                        <Text variant={'body2'} medium color={'ATTENTION_FOREGROUND'}>
                            {isPracticeCurrentlyPaused && reasons.length === 0 ? (
                                <>
                                    Are you sure you want to unpause this practice's ability to place orders?
                                    <br />
                                    <br />
                                    {enableOutstandingBalanceCommunications
                                        ? 'IMPORTANT UPDATE: This action now automatically removes holds from delinquent orders.'
                                        : 'Please note that this action will not remove holds from existing orders.'}
                                </>
                            ) : (
                                <>
                                    Pausing a practice's ability to place orders will result in all new orders from this
                                    practice being placed on hold.
                                    <br />
                                    <br />
                                    This is a very serious action and should only be done with appropriate sign-offs
                                    from the Account Manager and Accounts Receivable.
                                </>
                            )}
                        </Text>
                    </Grid>
                    <Grid item container direction={'row'}>
                        <Grid item>
                            <Button variant={'secondary'} onClick={() => setOpen(false)} disabled={submitting}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                            <Button
                                variant={'primary'}
                                onClick={() => submit({ data: { practice_id: practiceId, reasons } })}
                                disabled={_.isEqual(currentReasons, reasons) || submitting}
                            >
                                {submitText}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
            hideButton={true}
        />
    );
};
