import { default as constate } from 'constate';
import React from 'react';

type ScrollTo = { container: Element | null };

const is_at_top = ({ container }: ScrollTo) => !container || container.scrollTop === 0;
const is_at_bottom = ({ container }: ScrollTo) =>
    !container || container.scrollTop >= container.scrollHeight - container.clientHeight;

const scroll_absolute = ({ container }: ScrollTo, position: number) => {
    if (container) {
        container.scrollTop = position * (container.scrollHeight - container.clientHeight);
    }
};
const scroll_relative = ({ container }: ScrollTo, delta: number) => {
    if (container) {
        container.scrollTop += delta * container.scrollHeight;
    }
};

/**
 * this utility is a react context singleton constructor that gives a convenient way to observe and control a
 * container's scroll position from another part of the react component tree
 *
 * @returns useContainer stores a ref to the scrollable container<br/>
 * @returns useControl returns the state of, and a set of callbacks for controlling, container's scroll position<br/>
 * @returns Provider constate wrapper to avoid prop drilling refs
 *
 * @example
 * // define a context
 * export const {
 *     Provider: AcmeScrollToProvider,
 *     useContainer: useAcmeScrollToContainer,
 *     useControl: useAcmeScrollToControl
 * } = createScrollToContext();
 *
 * // wrap the parent
 * const AcmeRoot = () => {
 *     return (
 *         <AcmeScrollToProvider>
 *             <AcmeScrollable/>
 *             <AcmeToolbar/>
 *         </AcmeScrollToProvider>
 *     )
 * }
 *
 * // store the scroll container ref
 * const AcmeScrollable = () => {
 *     const scrollable_ref = React.useRef<HTMLDivElement | null>(null)
 *     useAcmeScrollToContainer(scrollable_ref)
 *     return (
 *         <div style={{ overflowY: `scroll` }} ref={scrollable_ref}>
 *             {...lots_of_children_probably}
 *         </div>
 *     )
 * }
 *
 * // control the scroll container
 * const AcmeToolbar = () => {
 *     const { at_top, at_bottom, scroll_up, scroll_down } = useAcmeScrollToControl()
 *     return (
 *         <>
 *             <button onClick={scroll_up} disabled={at_top}>Page up</button>
 *             <button onClick={scroll_down} disabled={at_bottom}>Page down</button>
 *         </>
 *     )
 * }
 */
export const createScrollToContext = () => {
    const [Provider, useScrollTo] = constate(() => React.useRef<ScrollTo>({ container: null }).current);

    const useContainer = (container_ref: React.MutableRefObject<Element | null>): void => {
        const scroll_to = useScrollTo();
        React.useLayoutEffect(() => {
            scroll_to.container = container_ref.current;
            return () => {
                scroll_to.container = null;
            };
        }, [scroll_to, container_ref]);
    };

    const useControl = () => {
        const scroll_to = useScrollTo();
        const [at_top, set_at_top] = React.useState(is_at_top(scroll_to));
        const [at_bottom, set_at_bottom] = React.useState(is_at_bottom(scroll_to));
        React.useLayoutEffect(() => {
            const { container } = scroll_to;
            if (container) {
                const recalculate_scroll = () => {
                    set_at_top(is_at_top(scroll_to));
                    set_at_bottom(is_at_bottom(scroll_to));
                };
                const observer = new MutationObserver(recalculate_scroll);
                container.addEventListener(`scroll`, recalculate_scroll);
                observer.observe(container, { childList: true, subtree: true });
                recalculate_scroll();
                return () => {
                    container.removeEventListener(`scroll`, recalculate_scroll);
                    observer.disconnect();
                };
            }
        }, [scroll_to]);
        const go_down = React.useCallback(() => scroll_relative(scroll_to, 1), [scroll_to]);
        const go_up = React.useCallback(() => scroll_relative(scroll_to, -1), [scroll_to]);
        const go_to_top = React.useCallback(() => scroll_absolute(scroll_to, 0), [scroll_to]);
        const go_to_bottom = React.useCallback(() => scroll_absolute(scroll_to, 1), [scroll_to]);
        return { at_top, at_bottom, go_down, go_up, go_to_top, go_to_bottom };
    };

    return { Provider, useContainer, useControl };
};
