/* eslint-disable no-nested-ternary */
import { CreateActionForm } from './CreateActionForm';
import { useOrderTicketsRefetch } from './OrderTickets.utils';
import { useCloseTicketActionMutation } from '@orthly/graphql-react';
import type { LabsGqlCloseTicketActionCommand, LabsGqlTicketActionDto, LabsGqlTicketDto } from '@orthly/graphql-schema';
import { LabsGqlActionStatus, LabsGqlTicketActionCompletionResolution } from '@orthly/graphql-schema';
import { RootActionDialog, useRootActionCommand } from '@orthly/ui';
import {
    Button,
    FormControlLabel,
    Grid,
    RadioPrimitive as Radio,
    RadioGroupPrimitive as RadioGroup,
    TextField,
} from '@orthly/ui-primitives';
import React from 'react';

interface CloseActionFormPropsBase {
    intent: 'complete' | 'cancel';
    ticket: Pick<LabsGqlTicketDto, 'order_id' | 'id' | 'actions'>;
    action: LabsGqlTicketActionDto;
}

interface ResolutionFormProps extends CloseActionFormPropsBase {
    onSubmit: (result: Pick<LabsGqlCloseTicketActionCommand, 'notes' | 'resolution'>) => void;
}
const ResolutionForm: React.FC<ResolutionFormProps> = ({ intent, ticket, action, onSubmit }) => {
    const [notes, setNotes] = React.useState('');
    const [resolution, setResolution] = React.useState<LabsGqlTicketActionCompletionResolution>(
        LabsGqlTicketActionCompletionResolution.Proceed,
    );

    const { hasMorePendingActions, hasFollowUps } = React.useMemo(() => {
        const actionIndex = ticket.actions.findIndex(anAction => anAction.id === action.id);
        const pendingActions = ticket.actions
            .slice(actionIndex + 1)
            .filter(anAction => anAction.status === LabsGqlActionStatus.Pending);
        const hasMorePendingActions = pendingActions.some(anAction => !anAction.followup_parent_id);
        const hasFollowUps = pendingActions.some(anAction => anAction.followup_parent_id);
        return { hasMorePendingActions, hasFollowUps };
    }, [ticket, action]);

    return (
        <Grid container spacing={1}>
            <TextField
                variant={'standard'}
                style={{ width: '100%' }}
                label={'Notes'}
                multiline={true}
                minRows={1}
                maxRows={5}
                value={notes}
                onChange={e => setNotes(e.target.value)}
            />

            <RadioGroup
                style={{ marginTop: 12 }}
                name={'actionResolution'}
                row={true}
                value={resolution}
                onChange={e => {
                    setResolution(e.target.value as LabsGqlTicketActionCompletionResolution);
                }}
            >
                <FormControlLabel
                    style={{ width: '100%' }}
                    value={LabsGqlTicketActionCompletionResolution.Proceed}
                    control={<Radio color={'secondary'} />}
                    label={
                        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                        // eslint-disable-next-line no-nested-ternary
                        hasMorePendingActions
                            ? `Proceed to next action`
                            : hasFollowUps
                              ? `Complete action and send follow-ups`
                              : `Complete action`
                    }
                />
                <FormControlLabel
                    style={{ width: '100%' }}
                    value={LabsGqlTicketActionCompletionResolution.Insert}
                    control={<Radio color={'secondary'} />}
                    label={`Add new immediate action to ticket`}
                />
                {hasMorePendingActions && (
                    <FormControlLabel
                        style={{ width: '100%' }}
                        value={LabsGqlTicketActionCompletionResolution.Abort}
                        control={<Radio color={'secondary'} />}
                        label={`Cancel all remaining actions in ticket`}
                    />
                )}
            </RadioGroup>

            <Button
                variant={'primary'}
                style={{ marginTop: 24, width: '100%' }}
                onClick={() => onSubmit({ notes, resolution })}
            >
                {resolution === LabsGqlTicketActionCompletionResolution.Insert
                    ? 'Next'
                    : intent === 'cancel'
                      ? 'Submit'
                      : 'Complete'}
            </Button>
        </Grid>
    );
};

interface CloseActionFormProps extends CloseActionFormPropsBase {
    onSubmit: (data: Pick<LabsGqlCloseTicketActionCommand, 'notes' | 'resolution' | 'next_action'>) => void;
}
const CloseActionForm: React.FC<CloseActionFormProps> = ({ onSubmit, ...props }) => {
    const [stage, setState] = React.useState<'resolution' | 'create'>('resolution');
    const [resolutionValues, setResolutionValues] = React.useState<Pick<
        LabsGqlCloseTicketActionCommand,
        'notes' | 'resolution'
    > | null>(null);

    return stage === 'resolution' ? (
        <ResolutionForm
            {...props}
            onSubmit={result => {
                if (result.resolution === LabsGqlTicketActionCompletionResolution.Insert) {
                    setResolutionValues(result);
                    setState('create');
                } else {
                    onSubmit(result);
                }
            }}
        />
    ) : (
        <>
            <CreateActionForm
                orderId={props.ticket.order_id}
                showCancelPendingOption={true}
                onBack={() => setState('resolution')}
                onSubmit={result => {
                    resolutionValues &&
                        onSubmit({
                            resolution: result.cancel_pending
                                ? LabsGqlTicketActionCompletionResolution.Abort
                                : LabsGqlTicketActionCompletionResolution.Insert,
                            notes: resolutionValues.notes,
                            next_action: {
                                type_id: result.type_id,
                                notes: result.notes,
                                activate_at: result.activate_at,
                                due_at: result.due_at,
                                enable_followup_action: result.enable_followup_action,
                            },
                        });
                }}
            />
        </>
    );
};

interface CloseActionDialogProps extends CloseActionFormPropsBase {
    onClose: () => void;
}
export const CloseActionDialog: React.FC<CloseActionDialogProps> = ({ onClose, ...props }) => {
    const [open, setOpen] = React.useState<boolean>(true);
    const refetchTickets = useOrderTicketsRefetch(props.ticket.order_id);

    const { submit, submitting } = useRootActionCommand(useCloseTicketActionMutation(), {
        successMessage: `Action ${props.intent === 'cancel' ? 'canceled' : 'completed'}!`,
        onSuccess: () => {
            refetchTickets();
            setOpen(false);
            onClose();
        },
    });

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            onClose={() => {
                setOpen(false);
                onClose();
            }}
            title={props.intent === 'cancel' ? `Cancel Action` : 'Complete Action'}
            content={
                !open ? null : (
                    <CloseActionForm
                        {...props}
                        onSubmit={result => {
                            void submit({
                                data: {
                                    ticket_id: props.ticket.id,
                                    action_id: props.action.id,
                                    status:
                                        props.intent === 'cancel'
                                            ? LabsGqlActionStatus.Canceled
                                            : LabsGqlActionStatus.Completed,
                                    notes: result.notes,
                                    resolution: result.resolution,
                                    next_action: result.next_action,
                                },
                            });
                        }}
                    />
                )
            }
            CustomButton={() => null}
        />
    );
};
