import marginal_ridges_groove_placement_BAD from '../../../../assets/images/design-qc/marginal_ridges/marginal_ridges_groove_placement_BAD.png';
import marginal_ridges_groove_placement_GOOD from '../../../../assets/images/design-qc/marginal_ridges/marginal_ridges_groove_placement_GOOD.png';
import marginal_ridges_vertical_positioning_BAD from '../../../../assets/images/design-qc/marginal_ridges/marginal_ridges_vertical_positioning_BAD.jpg';
import marginal_ridges_vertical_positioning_BAD2 from '../../../../assets/images/design-qc/marginal_ridges/marginal_ridges_vertical_positioning_BAD_2.jpg';
import marginal_ridges_vertical_positioning_GOOD from '../../../../assets/images/design-qc/marginal_ridges/marginal_ridges_vertical_positioning_GOOD.jpg';
import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { DEFAULT_SCAN_APPEARANCE, DEFAULT_RESTORATIVE_APPEARANCE } from '@orthly/dentin';

export const marginalRidgesRubric: DesignQcRubricCategoryEntry = {
    category: 'marginal_ridges',
    subcategories: [
        {
            category: 'marginal_ridges',
            name: 'Vertical Positioning',
            grading: {
                type: 'tertiary',
                bad: 'Beyond +/- 10%',
                acceptable: 'Within +/- 10%',
                ideal: 'As close to perfect as situation permits (100%)',
            },
            examples: {
                bad: [marginal_ridges_vertical_positioning_BAD, marginal_ridges_vertical_positioning_BAD2],
                acceptable: [marginal_ridges_vertical_positioning_GOOD],
                ideal: [marginal_ridges_vertical_positioning_GOOD],
            },
            description:
                'Ridges should be even with adjacent ridges, or in proper occlusion if no adjacent<br><br>Assume 100% is the distance between ideal height and gingiva',
            edge_case:
                'Opposing dentition does not permit idealized design<br><br>If adjacent marginal ridge is broken<br>If adjacent marginal ridge is orthodontically challenged to the point where it compromises occlusal design (TODO - How to handle this?)',
            selectable_bad_reasons: [
                'Marginal ridges are not aligned with adjacent ridges',
                'Marginal ridges are not in proper occlusion',
            ],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
        },
        {
            category: 'marginal_ridges',
            name: 'Shape / Contour',
            grading: {
                type: 'binary',
                bad: 'Not smooth',
                ideal: 'Smooth',
            },
            description:
                'Square, sharply angled ridges should be avoided. Ridge should not "chase" adjacent contact surface. Smooth to avoid food impaction.',
            selectable_bad_reasons: ['Contour of marginal ridge is not smooth'],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
        },
        {
            category: 'marginal_ridges',
            name: 'Groove placement',
            grading: {
                type: 'binary',
                bad: 'Secondary grooves break occusal table',
                ideal: 'Secondary grooves do not break occusal table',
            },
            examples: {
                bad: [marginal_ridges_groove_placement_BAD],
                ideal: [marginal_ridges_groove_placement_GOOD],
            },
            description:
                'The only grooves that can break the occlusal table are dissectional grooves.<br><br>Poor design can introduce secondary grooves to break the outline and it results in a hilly looking marginal ridge',
            selectable_bad_reasons: ['Secondary grooves are breaking through the occlusal table'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
        },
    ],
    design_standard: {
        googleEmbedStub: `2PACX-1vSZ_6QpfUOKsk1286tBCy5IaKWeM7douRzX8p_UXD6HHO75XXPpY-pbODCFCVsBNnM_hw_ECxfKg_q_`,
    },
};
