import { LabeledButton } from '../BottomToolbar/LabeledButton';
import { ToolbarContainer } from '../BottomToolbar/ToolbarContainer';
import { TYPE_TO_TITLE } from './ReviewPanel.utils';
import type { DesignFinishingReviewType } from '@orthly/dentin';
import type { BoxProps } from '@orthly/ui-primitives';
import React from 'react';

interface ButtonProps {
    variant: DesignFinishingReviewType;
    currentType: DesignFinishingReviewType;
    setCurrentType: React.Dispatch<React.SetStateAction<DesignFinishingReviewType | null>>;
}

const ReviewTypeButton: React.VFC<ButtonProps> = ({ variant, currentType, setCurrentType }) => {
    const active = currentType === variant;
    const makeActive = () => setCurrentType(variant);

    const label = TYPE_TO_TITLE[variant];

    return <LabeledButton active={active} onClick={makeActive} tooltip={label} label={label} />;
};

type ReviewTypeToolbarProps = {
    currentType: DesignFinishingReviewType;
    setCurrentType: React.Dispatch<React.SetStateAction<DesignFinishingReviewType | null>>;
} & BoxProps;

export const ReviewTypeToolbar: React.VFC<ReviewTypeToolbarProps> = ({ currentType, setCurrentType, ...props }) => {
    return (
        <ToolbarContainer {...props}>
            <ReviewTypeButton variant={'occlusion'} currentType={currentType} setCurrentType={setCurrentType} />
            <ReviewTypeButton variant={'insertion'} currentType={currentType} setCurrentType={setCurrentType} />
            <ReviewTypeButton variant={'mesial'} currentType={currentType} setCurrentType={setCurrentType} />
            <ReviewTypeButton variant={'distal'} currentType={currentType} setCurrentType={setCurrentType} />
        </ToolbarContainer>
    );
};
