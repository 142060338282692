import { isBusinessDay, differenceInBusinessDays } from '@orthly/date-fns-business-days';
import moment from 'moment-timezone';

export interface BusinessTimeInterval {
    days: number;
    hours: number;
}

export function getBusinessTimeInterval(end: moment.Moment, start: moment.Moment): BusinessTimeInterval {
    // TODO: move some version of this to one of our business-day packages.  That I can't say which one is not ideal.
    // differenceInBusinessDays increments its return value based on whether the interval crosses midnight.  But,
    // we need to consider the start time, not just the date, if the start time is on a business day.
    if (start.isSameOrAfter(end)) {
        return { days: 0, hours: 0 };
    }
    const startIsBusinessDay = isBusinessDay(start.toDate());
    if (start.isSame(end, 'day')) {
        return {
            days: 0,
            hours: startIsBusinessDay ? end.diff(start, 'hours') : 0,
        };
    }
    let days = 0;
    if (startIsBusinessDay) {
        const marker = start.clone().add(1, 'day');
        while (marker.isSameOrBefore(end)) {
            if (isBusinessDay(marker.toDate())) {
                days++;
            }
            marker.add(1, 'day');
        }
    } else {
        days = differenceInBusinessDays(end.toDate(), start.toDate());
    }

    // We'll consider all 24 hours of a business day to be business hours for this computation.  For U.S. time zones,
    // DST transitions always happen on non-business days, so we don't need to worry about that transition *within* a
    // day.  But, that's why we can't just use the difference in hours mod 24 here.
    const firstDayDuration = startIsBusinessDay
        ? moment.duration(start.clone().add(1, 'day').startOf('day').diff(start))
        : moment.duration(0);
    const lastDayDuration = isBusinessDay(end.toDate())
        ? moment.duration(end.diff(end.clone().startOf('day')))
        : moment.duration(0);
    return {
        days,
        hours: firstDayDuration.add(lastDayDuration).get('hours'),
    };
}

export function formatBusinessTimeInterval({ days, hours }: BusinessTimeInterval): string {
    const parts: string[] = [];
    if (days > 0) {
        parts.push(`${days}d`);
    }
    if (hours > 0) {
        parts.push(`${hours}h`);
    }
    return parts.length > 0 ? parts.join(', ') : '0h';
}
