import type { LabsGqlSetUsagePaymentSplitPercentMutationVariables as PercentMtnVars } from '@orthly/graphql-operations';
import {
    useRemoveUsagePaymentSplitConfigMutation,
    useSetUsagePaymentSplitLocationMutation,
    useSetUsagePaymentSplitDoctorsMutation,
    useSetUsagePaymentSplitPercentMutation,
} from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

export type PaymentSplitConfigType = 'doctor' | 'location' | 'percent';

// For submitting splits by doctor id or address id
type GenericEntitySplit = { entity_id: string; source_id: string };
export type PaymentSplitEntityVars = { type: 'location' | 'doctor'; splits: GenericEntitySplit[] };

type SubmitPaymentSplitVars =
    | PaymentSplitEntityVars
    | { type: 'percent'; splits: PercentMtnVars['splits'] }
    | { type: 'remove' };

function submissionSuccessMessage(splitType: PaymentSplitConfigType | 'remove') {
    if (splitType === 'remove') {
        return 'Invoice payment split removed';
    }
    return `Invoice payment split by ${splitType} set!`;
}

export function useSubmitPaymentSplitConfigBase(partner_id: string, onComplete: () => void) {
    const [removeUsageSplitMtn] = useRemoveUsagePaymentSplitConfigMutation();
    const [setUsagePercentSplitMtn] = useSetUsagePaymentSplitPercentMutation();
    const [setUsageDoctorSplitMtn] = useSetUsagePaymentSplitDoctorsMutation();
    const [setUsageLocationMtn] = useSetUsagePaymentSplitLocationMutation();
    const mtnSubmitter = (toCall: () => Promise<string>) => toCall();
    const { submit: submitRaw, submitting } = useChangeSubmissionFn<string, [() => Promise<string>]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: message => [message, {}],
        onSuccess: async () => {
            onComplete();
        },
    });
    const submit = React.useCallback(
        (vars: SubmitPaymentSplitVars) => {
            const submitter = async () => {
                switch (vars.type) {
                    case 'doctor':
                        const doctorSplits = vars.splits.map(({ source_id, entity_id }) => ({
                            source_id,
                            doctor_preferences_id: entity_id,
                        }));
                        await setUsageDoctorSplitMtn({ variables: { partner_id, splits: doctorSplits } });
                        return submissionSuccessMessage(vars.type);
                    case 'location':
                        const locationSplits = vars.splits.map(({ source_id, entity_id }) => ({
                            source_id,
                            delivery_address_id: entity_id,
                        }));
                        await setUsageLocationMtn({ variables: { partner_id, splits: locationSplits } });
                        return submissionSuccessMessage(vars.type);
                    case 'percent':
                        await setUsagePercentSplitMtn({ variables: { partner_id, splits: vars.splits } });
                        return submissionSuccessMessage(vars.type);
                    case 'remove':
                        await removeUsageSplitMtn({ variables: { partner_id } });
                        return submissionSuccessMessage(vars.type);
                }
            };
            return submitRaw(submitter);
        },
        [
            partner_id,
            removeUsageSplitMtn,
            setUsageDoctorSplitMtn,
            setUsageLocationMtn,
            setUsagePercentSplitMtn,
            submitRaw,
        ],
    );
    return { submit, submitting };
}

export function useSubmitPaymentSplitConfig(partner_id: string, onComplete: () => void) {
    const { submit, submitting } = useSubmitPaymentSplitConfigBase(partner_id, onComplete);
    // Helper method to make sure the removal call for prepay is done correctly
    const removeConfig = React.useCallback(() => {
        void submit({ type: 'remove' });
    }, [submit]);
    return { submit, submitting, removeConfig };
}
