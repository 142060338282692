import { Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface PracticeInfoRowProps {
    title: string;
    value: string;
    halfRow?: boolean;
}

export const PracticeInfoRow: React.FC<PracticeInfoRowProps> = props => {
    const { title, value, halfRow } = props;

    return (
        <Grid container style={{ paddingTop: 8, width: halfRow ? '50%' : 'inherit' }}>
            <Text variant={'caption'} color={'DARK_GRAY'} style={{ width: '50%' }}>
                {title}
            </Text>
            <Text variant={'caption'} style={{ width: '50%' }}>
                {value}
            </Text>
        </Grid>
    );
};
