import { useOrder, useFinishingCallbacks } from '../../OrderState.hooks';
import { CaseNotesBlock } from '../../SharedUi/CaseNotesBlock';
import { PreferencesBlock } from '../../SharedUi/PreferencesBlock';
import { TagsBlock } from '../../SharedUi/TagsBlock';
import { ActionBlock } from './ActionBlock';
import { EligibilityBlock } from './EligibilityBlock';
import { StackY } from '@orthly/ui';
import { FlossPalette, Button, Text, Box, styled } from '@orthly/ui-primitives';
import React from 'react';

const SidebarHeader: React.VFC = () => {
    const { first_name, last_name } = useOrder().patient;

    const { closeWindow, onCancel } = useFinishingCallbacks();

    const onCancelClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
        evt => {
            if (closeWindow(evt)) {
                onCancel();
            }
        },
        [closeWindow, onCancel],
    );

    return (
        <Box
            sx={{
                height: 48,
                padding: `8px 16px`,
                borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flex: 'none',
            }}
            bgcolor={FlossPalette.WHITE}
        >
            <Text variant={'body2'} bold>
                {first_name && last_name ? `${first_name[0]}. ${last_name}` : first_name || last_name}
            </Text>
            <Button variant={'ghost'} onClick={onCancelClick}>
                Cancel
            </Button>
        </Box>
    );
};

const SidebarBodyTitle = styled(Text)(() => ({
    padding: '8px 0',
}));

const SidebarBody: React.VFC = () => {
    return (
        <Box sx={{ padding: '8px 16px', flex: 'auto', overflow: 'hidden auto' }}>
            <SidebarBodyTitle variant={'h4'}>Review</SidebarBodyTitle>
            <EligibilityBlock />
            <PreferencesBlock showMinimumThickness borderTop />
            <CaseNotesBlock />
            <TagsBlock />
        </Box>
    );
};

const SidebarFooter: React.VFC = () => {
    return (
        <Box sx={{ padding: '8px 16px 0px 16px', flex: 'none' }}>
            <ActionBlock />
        </Box>
    );
};

const ReviewSidebarContentsContainer = styled(StackY)(() => ({
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
}));

export const ReviewSidebarContents: React.VFC = () => {
    return (
        <ReviewSidebarContentsContainer>
            <SidebarHeader />
            <SidebarBody />
            <SidebarFooter />
        </ReviewSidebarContentsContainer>
    );
};
