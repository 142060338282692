import { useCopyToClipboard } from '../../hooks';
import { usePatientPortalLinkMutation, useSoftSmileVisionLinkMutation } from '@orthly/graphql-react';
import { LabsGqlVisionLinkType } from '@orthly/graphql-schema';
import { Grid, Button } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

// Returns a fetcher for a vision link which shows a snackbar message on error.
function useFetchVisionLink(
    linkType: LabsGqlVisionLinkType,
): (orderId: string, versionId: number | null, handler: (link: string) => void) => void {
    const [createVisionLink] = useSoftSmileVisionLinkMutation();
    const { enqueueSnackbar } = useSnackbar();

    return React.useMemo(() => {
        const showError = (errorMessage = 'Failed to create Vision link') =>
            enqueueSnackbar(errorMessage, { variant: 'error' });

        return (orderId: string, versionId: number | null, handler: (link: string) => void) => {
            createVisionLink({ variables: { order_id: orderId, version_id: versionId, link_type: linkType } })
                .then(({ data }) => {
                    const link = data?.generate_softsmile_vision_link;

                    if (link) {
                        handler(link);
                    } else {
                        showError();
                    }
                })
                .catch(error => {
                    showError(error?.message);
                });
        };
    }, [createVisionLink, enqueueSnackbar, linkType]);
}

// Passing `null` for versionId opens a new plan starting at the segmentation phase.

export function useOpenCaseInVision(): (orderId: string, versionId: number | null) => void {
    const fetchVisionLink = useFetchVisionLink(LabsGqlVisionLinkType.Edit);

    return React.useMemo(() => {
        return (orderId: string, versionId: number | null) => {
            fetchVisionLink(orderId, versionId, link => {
                window.open(link, '_blank');
            });
        };
    }, [fetchVisionLink]);
}

// Passing `null` for versionId opens a new plan starting at the segmentation phase.

export function useCopyTroubleshootingVisionLink(): (orderId: string, versionId: number | null) => void {
    const copyToClipboard = useCopyToClipboard();
    const fetchVisionLink = useFetchVisionLink(LabsGqlVisionLinkType.Share);

    return React.useMemo(() => {
        return (orderId: string, versionId: number | null) => {
            fetchVisionLink(orderId, versionId, link => {
                copyToClipboard({
                    text: link,
                    successMessage: `Link copied to clipboard!`,
                    errorMessage: `Failed to copy link`,
                });
            });
        };
    }, [fetchVisionLink, copyToClipboard]);
}

export function useCopyWebviewerLink(): (orderId: string, versionId: number | null) => Promise<void> {
    const copyToClipboard = useCopyToClipboard();
    const [createPatientPortalLink] = usePatientPortalLinkMutation();

    return React.useCallback(
        async (orderId: string, versionId: number | null) => {
            const { data } = await createPatientPortalLink({ variables: { order_id: orderId, version_id: versionId } });
            if (data?.patient_portal_link) {
                copyToClipboard({
                    text: data.patient_portal_link,
                    successMessage: `Link copied to clipboard!`,
                    errorMessage: `Failed to copy link`,
                });
            }
        },
        [createPatientPortalLink, copyToClipboard],
    );
}

export const StartDesignInVisionButton: React.VFC<{
    order_id: string;
}> = ({ order_id }) => {
    const openCaseInVision = useOpenCaseInVision();

    return (
        <Grid item>
            <Button variant={`secondary`} onClick={() => openCaseInVision(order_id, null)} startIcon={'AddIcon'}>
                Start new design in Vision
            </Button>
        </Grid>
    );
};
