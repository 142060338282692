import { SculptingMenu } from '../SculptingMenu';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

export const TouchUpStage: React.VFC = () => {
    return (
        <Grid item container spacing={1} style={{ padding: '0px 12px' }}>
            <Grid item>
                <SculptingMenu />
            </Grid>
        </Grid>
    );
};
