import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import { LabsGqlLabPriceRuleType } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';

export const AlignerUnitForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    const initialRetainersValue =
        price?.rule.__typename === 'LabPriceAlignerUnitRuleDTO' ? price.rule.aligner_retainers_only : false;
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.AlignerUnit>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.AlignerUnit>({
                aligner_retainers_only: {
                    type: 'boolean',
                    label: 'For Aligner Retainers Only?',
                    helperText:
                        'If true, then this price will only match Aligner Retainers. If false, it only matches normal Aligners',
                },
            })}
            initialValues={
                price
                    ? { name: price.name, aligner_retainers_only: initialRetainersValue }
                    : { aligner_retainers_only: initialRetainersValue }
            }
            onSubmit={async result => {
                await submit({
                    name: result.name,
                    rule: {
                        type: LabsGqlLabPriceRuleType.AlignerUnit,
                        aligner_retainers_only: result.aligner_retainers_only,
                    },
                });
            }}
            disabled={disabled}
        />
    );
};
