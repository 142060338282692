import { LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';
import type {
    IBridgeItem,
    ICrownItem,
    IDentureItem,
    IImplantItem,
    IInlayItem,
    IOrderItemV2DTO,
    IVeneerItem,
    IOrderItemV2OfType,
} from '@orthly/items';
import type { ReactNode } from 'react';

export const DEFUALT_VALUE_TEXT = '--';

export type IOrderItemsBySku = { [K in LabOrderItemSKUType]?: IOrderItemV2OfType<K>[] };

export interface IMergedDentureData {
    items: IDentureItem[];
}

export interface IMergedItemData {
    items: IMergedItem[];
}

export interface IMergedItem {
    groupedUnns: number[];
    defaultItem: IMergeableItem;
    // All of the items in the group
    groupItems: IMergeableItem[];
}

export interface IBaseItemData {
    headerLeft: string;
    headerRight?: string;
    itemFields?: {
        key?: string;
        value?: string | boolean | number | null;
        customComponent?: ReactNode;
    }[];
    allowPageBreaks?: boolean;
}

export type ISingleToothUnitItem = ICrownItem | IVeneerItem | IInlayItem;
export type IMergeableItem = ISingleToothUnitItem | IImplantItem;

/* ---------------- Type Guards ---------------- */

export function isMergedItem(item: IOrderItemV2DTO | IMergedItem): item is IMergedItem {
    return item.hasOwnProperty('groupedUnns');
}

// Crown type guards
export function isCrownItem(item: IOrderItemV2DTO | IMergedItem): item is ICrownItem {
    return !isMergedItem(item) && OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Crown);
}

export function isCrownItemList(items: IOrderItemV2DTO[]): items is ICrownItem[] {
    return items.every(item => isCrownItem(item));
}

// Implant type guards
export function isImplantItem(item: IOrderItemV2DTO | IMergedItem): item is IImplantItem {
    return !isMergedItem(item) && OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Implant);
}

export function isImplantItemList(items: IOrderItemV2DTO[]): items is IImplantItem[] {
    return items.every(item => isImplantItem(item));
}

// Inlay type guards
export function isInlayItem(item: IOrderItemV2DTO | IMergedItem): item is IInlayItem {
    return !isMergedItem(item) && OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Inlay);
}

export function isInlayItemList(items: IOrderItemV2DTO[]): items is IInlayItem[] {
    return items.every(item => isInlayItem(item));
}

// Veneer type guards
export function isVeneerItem(item: IOrderItemV2DTO | IMergedItem): item is IVeneerItem {
    return !isMergedItem(item) && OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Veneer);
}

export function isVeneerItemList(items: IOrderItemV2DTO[]): items is IVeneerItem[] {
    return items.every(item => isVeneerItem(item));
}

// Bridge type guards
export function isBridgeItem(item: IOrderItemV2DTO | IMergedItem): item is IBridgeItem {
    return !isMergedItem(item) && OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Bridge);
}

export function isBridgeItemList(items: IOrderItemV2DTO[]): items is IBridgeItem[] {
    return items.every(item => isBridgeItem(item));
}

// SingleToothUnitItem type guards
export function isSingleToothUnitItem(item: IOrderItemV2DTO): item is ISingleToothUnitItem {
    return OrderItemV2Utils.itemIsType(item, [
        LabOrderItemSKUType.Crown,
        LabOrderItemSKUType.Inlay,
        LabOrderItemSKUType.Veneer,
    ]);
}

export function isDistinctSingleToothUnitItemList(items: IOrderItemV2DTO[]): items is ISingleToothUnitItem[] {
    return isCrownItemList(items) || isInlayItemList(items) || isVeneerItemList(items);
}

// Denture Type Guards
export function isDentureItem(item: IOrderItemV2DTO | IMergedItem): item is IDentureItem {
    return !isMergedItem(item) && OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Denture);
}

export function isDentureItemList(items: IOrderItemV2DTO[]): items is IDentureItem[] {
    return items.every(item => isDentureItem(item));
}
