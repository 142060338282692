import { useFetchAllPracticeEmployeesQuery } from '@orthly/graphql-react';
import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { OrderFormatUtils } from '@orthly/shared-types';
import { LoadBlocker } from '@orthly/ui';
import { Text, Grid, ChevronRight, Icon } from '@orthly/ui-primitives';
import { DoctorProfilePhotoContent, PersonCardOccupationOptionsDisplay } from '@orthly/veneer';
import React from 'react';

export const PracticeProfiles: React.FC<{ practiceId: string }> = props => {
    const [hidden, setHidden] = React.useState(true);
    const { practiceId } = props;
    const { data, loading } = useFetchAllPracticeEmployeesQuery({
        variables: { practice_id: practiceId },
    });
    const profiles = data?.fetchAllPracticeEmployees;

    if (!profiles || profiles.length === 0) {
        return null;
    }

    const onHideClick = () => {
        setHidden(current => !current);
    };

    return (
        <LoadBlocker blocking={loading}>
            <Grid container style={{ paddingTop: 16 }}>
                <Grid container onClick={onHideClick} style={{ cursor: 'pointer' }}>
                    {hidden ? <ChevronRight /> : <Icon icon={'ExpandMoreIcon'} />}
                    <Text variant={'body2'} medium>
                        Profiles
                    </Text>
                </Grid>
                {!hidden &&
                    profiles.map((profile, idx) => {
                        const contactInfo = OrderFormatUtils.bestContactInfo({
                            doctor_name: profile.name,
                            contact_email: profile.contact_email,
                            contact_phone_call_number: profile.contact_phone_call_number,
                            contact_phone: profile.contact_phone,
                            best_call_hour: profile.best_call_hour,
                            timezone: profile.timezone,
                        });

                        return (
                            <Grid
                                container
                                key={idx}
                                style={{ paddingTop: 8, width: '100%', marginLeft: 24, flexWrap: 'nowrap' }}
                                alignItems={'center'}
                            >
                                <DoctorProfilePhotoContent
                                    type={profile.profile_photo.type}
                                    source={profile.profile_photo.source ?? profile.name}
                                    color={profile.profile_photo.color}
                                    style={{
                                        width: 32,
                                        height: 32,
                                        borderRadius: 38,
                                    }}
                                />
                                <Grid item style={{ paddingLeft: 8 }}>
                                    <Grid item style={{ height: 16 }}>
                                        <Text variant={'caption'}>{`${profile.formatted_name} | ${
                                            profile.roles?.includes(LabsGqlStaffRolePractice.PracticeDoctor)
                                                ? 'Dentist'
                                                : (profile.roles &&
                                                      profile.roles
                                                          .map(role => PersonCardOccupationOptionsDisplay[role])
                                                          .join(', ')) ??
                                                  'Staff'
                                        }`}</Text>
                                    </Grid>
                                    {!!profile.contact_email && (
                                        <Grid item style={{ height: 16 }}>
                                            <Text variant={'caption'} color={'DARK_GRAY'}>
                                                {profile.contact_email}
                                            </Text>
                                        </Grid>
                                    )}
                                    {contactInfo.call_number !== 'Not set' && (
                                        <Grid item style={{ height: 16 }}>
                                            <Text variant={'caption'} color={'DARK_GRAY'}>
                                                {`Best contact number: ${contactInfo.call_number}`}
                                            </Text>
                                        </Grid>
                                    )}
                                    {contactInfo.best_call_hour !== 'Not set' && (
                                        <Grid item style={{ height: 16 }}>
                                            <Text variant={'caption'} color={'DARK_GRAY'}>
                                                {contactInfo.best_call_hour}
                                            </Text>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        );
                    })}
            </Grid>
        </LoadBlocker>
    );
};
