import { DesignViewLiteButton } from './DesignViewerLiteButton';
import { SwitchWithSlider } from './SwitchWithSlider';
import { useTransparency } from './TransparencyControls.hooks';
import type { ModelAppearance } from '@orthly/dentin';
import { Divider, Menu, RestorativesTransparentIcon } from '@orthly/ui-primitives';
import React from 'react';

interface TransparencyControlsProps {
    appearanceSettings: ModelAppearance;
    onAppearanceChange: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    anchorEl: HTMLElement | null;
    canHaveLayers: boolean;
    isMobile: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    onClose: () => void;
}

export const TransparencyControls: React.VFC<TransparencyControlsProps> = ({
    appearanceSettings,
    onAppearanceChange,
    anchorEl,
    canHaveLayers,
    isMobile,
    onClick,
    onClose,
}) => {
    const {
        updatePorcelainOpacity,
        updateOpacity,
        onToggleRestorative,
        onToggleAnatomyLayers,
        restorativeOpacity,
        porcelainOpacity,
        applyToAnatomyLayers,
        resetState,
        applyToRestorative,
    } = useTransparency(appearanceSettings, onAppearanceChange);

    const handleAllOpacityChanged = (newValue: number) => {
        updateOpacity(1 - newValue / 100);
    };

    const handleLayeredOpacityChanged = (newValue: number) => {
        updatePorcelainOpacity(1 - newValue / 100);
    };

    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (anchorEl) {
            resetState();
        } else {
            // On desktop, turn on the selection “Apply to all” by default to eliminate one click
            const turnOnAllByDefault = !isMobile && !applyToRestorative && !applyToAnatomyLayers;
            turnOnAllByDefault && onToggleRestorative();
        }

        onClick(e);
    };

    const handleClose = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.stopPropagation();
        resetState();
        onClose();
    };

    let extraLabel = undefined;
    if (applyToRestorative) {
        extraLabel = 'All';
    }
    if (applyToAnatomyLayers) {
        extraLabel = 'Layer';
    }

    return (
        <>
            <DesignViewLiteButton
                id={canHaveLayers ? 'anatomy/transparency' : 'transparency'}
                icon={<RestorativesTransparentIcon />}
                onClick={handleButtonClick}
                label={'Transparency'}
                extraLabel={extraLabel}
                isActive={Boolean(anchorEl)}
                onClose={handleClose}
            />
            <Menu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && !isMobile}
                onClose={onClose}
            >
                <SwitchWithSlider
                    checked={applyToRestorative}
                    onClick={onToggleRestorative}
                    label={'Apply to all'}
                    value={100 - restorativeOpacity * 100}
                    onChange={handleAllOpacityChanged}
                />
                {canHaveLayers && (
                    <div>
                        <Divider style={{ margin: '8px 0' }} />
                        <SwitchWithSlider
                            checked={applyToAnatomyLayers}
                            onClick={onToggleAnatomyLayers}
                            label={'Apply to layered porcelain'}
                            hint={'This is the target tooth shape and the porcelain will be laid by hand'}
                            value={100 - porcelainOpacity * 100}
                            onChange={handleLayeredOpacityChanged}
                        />
                    </div>
                )}
            </Menu>
        </>
    );
};
