import { ArchiveAndRestore } from '../ArchiveAndRestore';
import type { FieldsWithAutoCompleteDataSet, EditableAbutmentPartFields } from './utils';
import { abutmentPartsFormFields, useAbutmentParts } from './utils';
import type { LabsGqlAbutmentPartFragment } from '@orthly/graphql-operations';
import {
    useUpdateAbutmentPartMutation,
    useArchiveAbutmentPartMutation,
    useUnarchiveAbutmentPartMutation,
} from '@orthly/graphql-react';
import { QuickForm, LoadBlocker } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

interface AbutmentPartRowProps {
    data: LabsGqlAbutmentPartFragment;
    dataToAutoComplete: FieldsWithAutoCompleteDataSet;
}

// Created a quick helper to return a number > 0 or null. If the field is left empty
// in QuickForm it will return null. If it's set to 0, we'll also set to null, otherwise
// we'll set it to the number that was in the field.
const getSanitizedValue = (field: number | null): number | null => {
    return field || null;
};

export const AbutmentPartRow: React.FC<AbutmentPartRowProps> = ({ data: abutmentPart, dataToAutoComplete }) => {
    const { refetch: refetchAbutmentParts, loading: isFetching, error: fetchError } = useAbutmentParts();

    const [updateAbutmentPart, { error: updateError, loading: isUpdating }] = useUpdateAbutmentPartMutation();
    const [archiveAbutmentPart, { error: archiveError, loading: isDeleting }] = useArchiveAbutmentPartMutation();
    const [unarchiveAbutmentPart, { error: unarchiveError, loading: isRestoring }] = useUnarchiveAbutmentPartMutation();

    const loading = isFetching || isUpdating || isDeleting || isRestoring;
    const error = updateError || archiveError || unarchiveError || fetchError;
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        error &&
            enqueueSnackbar(`Error: ${error.message}`, {
                variant: 'error',
            });
    }, [error, enqueueSnackbar]);

    return (
        <Grid container style={{ padding: 16 }}>
            <Grid container direction={'row'} alignItems={'flex-start'} spacing={4}>
                <Grid container item xs={9}>
                    <LoadBlocker blocking={(!abutmentPart && !error) || isUpdating}>
                        <QuickForm<EditableAbutmentPartFields>
                            disabled={false}
                            readOnly={false}
                            fields={abutmentPartsFormFields(dataToAutoComplete)}
                            initialValues={abutmentPart}
                            onSubmit={async ({
                                manufacturer,
                                sku,
                                material,
                                engagement,
                                diameter_mm,
                                gingival_height_mm,
                                top_cap_height_mm,
                                three_shape_category,
                                three_shape_manufacturer,
                                three_shape_manufacturing_process,
                                three_shape_material,
                                three_shape_kit,
                                three_shape_system,
                                analog_sku,
                                screw_sku,
                            }) => {
                                await updateAbutmentPart({
                                    variables: {
                                        data: {
                                            abutment_part_id: abutmentPart.id,
                                            manufacturer,
                                            sku,
                                            material,
                                            engagement,
                                            diameter_mm: getSanitizedValue(diameter_mm),
                                            gingival_height_mm: getSanitizedValue(gingival_height_mm),
                                            top_cap_height_mm: getSanitizedValue(top_cap_height_mm),
                                            three_shape_category,
                                            three_shape_manufacturer,
                                            three_shape_manufacturing_process,
                                            three_shape_material,
                                            three_shape_kit: three_shape_kit ?? '',
                                            three_shape_system: three_shape_system ?? '',
                                            analog_sku: analog_sku ?? null,
                                            screw_sku: screw_sku ?? null,
                                        },
                                    },
                                });

                                await refetchAbutmentParts();
                            }}
                        />
                    </LoadBlocker>
                </Grid>

                <Grid container item xs={3}>
                    <Grid container direction={'column'} spacing={1}>
                        <Grid item>
                            <ArchiveAndRestore
                                objectName={'abutment part'}
                                isArchived={abutmentPart.is_archived}
                                disabled={loading}
                                hidePrompt={true}
                                promptText={``}
                                onRestore={async () => {
                                    await unarchiveAbutmentPart({
                                        variables: {
                                            data: {
                                                abutment_part_id: abutmentPart.id,
                                            },
                                        },
                                    });

                                    await refetchAbutmentParts();
                                }}
                                onDelete={async () => {
                                    await archiveAbutmentPart({
                                        variables: {
                                            data: {
                                                abutment_part_id: abutmentPart.id,
                                            },
                                        },
                                    });

                                    await refetchAbutmentParts();
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
