import type { LabPriceFormState } from './ProductCatalogForms.types';
import type { LabsGqlLabPriceRuleType } from '@orthly/graphql-schema';
import type { FieldDefBasic, FieldsDefProp } from '@orthly/ui';

export function intoBasePriceFieldDefs<T extends LabsGqlLabPriceRuleType>(
    defs: FieldsDefProp<Omit<LabPriceFormState<T>, 'name'>>,
): FieldsDefProp<any> {
    return {
        name: {
            type: 'text',
            label: 'Name',
            optional: false,
        } as FieldDefBasic<any, LabPriceFormState<T>['name']>,
        ...defs,
    };
}
