import { usePartnersData } from './usePartners';
import { useListLabOrgs } from '@orthly/graphql-react';
import _ from 'lodash';
import React from 'react';

export function usePartnerAndLabNames() {
    const { data: labsData } = useListLabOrgs({
        fetchPolicy: 'cache-first',
    });
    const { data: partnersQuery } = usePartnersData();
    const partnerNamesById = React.useMemo(() => {
        const partners = partnersQuery?.listPracticeNames ?? [];
        return _.fromPairs<string | null>(partners.map(p => [p.id, p.name]));
    }, [partnersQuery]);
    const labNamesById = React.useMemo(() => {
        const labs = labsData?.listOrganizations ?? [];
        return _.fromPairs<string | null | undefined>(labs.map(l => [l.id, l.name]));
    }, [labsData]);
    return { labNamesById, partnerNamesById };
}
