import { recomputeCollisionsObject } from '../DesignEditing/RecomputeCollisions';
import type { BrushSettings } from '../DesignEditing/SculptingBrush';
import type { HandleSculptFn } from '../DesignEditing/SculptingTool.types';
import type { MainViewCameraControlsRef } from '../ModelViewer';
import type { DeformState } from './Deform.types';
import { createDefaultDeformBrushSettings } from './Deform.utils';
import { DeformTool } from './DeformTool';
import { EditingMode } from './FinishingApp.types';
import type { IOperationsManager } from './OperationsManager.types';
import type { SceneAppearance } from './SceneAppearanceManager.types';
import { useSetBrushSetting } from './Sculpting.hooks.utils';
import type { FinishingTrackingInfo, HandleSculptCompleteFn } from './Sculpting.types';
import {
    ALL_SCULPT_HEATMAPS,
    ComputeVertexNormalsByAngleForSubset,
    getConnectedFaces,
    recomputeActiveDistanceForASubset,
} from '@orthly/forceps';
import type { MorphPointsInternalData } from '@orthly/shared-types';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

/**
 * Implements deform functionality
 */
export function useDeform(
    orderId: string,
    mode: EditingMode | undefined,
    setMode: React.Dispatch<React.SetStateAction<EditingMode | undefined>>,
    operationsManager: IOperationsManager,
    cameraControlsRef: MainViewCameraControlsRef,
    canvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
    sculptingGroup: THREE.Group,
    sceneAppearance: SceneAppearance,
    updateAppearance: (geometry: THREE.BufferGeometry) => void,
    curtains: THREE.BufferGeometry | undefined,
): DeformState {
    const deformState = useDeformState(mode, setMode);

    const { onSculptIter, onSculptComplete } = useDeformCallbacks(operationsManager, updateAppearance, curtains);

    const restorativeVisible = sceneAppearance.restoratives.get(operationsManager.editToothNumber)?.visible ?? false;

    useDeformTool(
        deformState.deformEnabled && restorativeVisible,
        orderId,
        deformState.brushSettings,
        onSculptIter,
        onSculptComplete,
        cameraControlsRef,
        canvasRef,
        sculptingGroup,
        deformState.cameraControlsEnabled,
        operationsManager,
    );

    return deformState;
}

export function useDeformTool(
    deformEnabled: boolean,
    orderId: string,
    brushSettings: BrushSettings,
    onSculptIter: HandleSculptFn,
    onSculptComplete: HandleSculptCompleteFn,
    cameraControlsRef: MainViewCameraControlsRef,
    canvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
    sculptingGroup: THREE.Group,
    cameraControlsEnabled: boolean,
    operationsManager: IOperationsManager,
) {
    const editGeometry = operationsManager.editGeometry;
    const meshConnectivityGraph = operationsManager.connectivityGraph;
    const morphPoints = operationsManager.editMorphPoints;

    React.useEffect(() => {
        const deformTool = new DeformTool(
            deformEnabled,
            canvasRef.current,
            cameraControlsEnabled ? cameraControlsRef.current : null,
            brushSettings,
            onSculptIter,
            onSculptComplete,
            meshConnectivityGraph,
            sculptingGroup,
            editGeometry,
            morphPoints,
            operationsManager,
        );
        const pointerMove = (evt: MouseEvent) => deformTool.pointerMove(evt);
        const pointerDown = (evt: MouseEvent) => deformTool.pointerDown(evt);
        const pointerUp = (evt: MouseEvent) => deformTool.pointerUp(evt);
        const keyDown = (evt: KeyboardEvent) => deformTool.keyDown(evt);
        const keyUp = (evt: KeyboardEvent) => deformTool.keyUp(evt);

        // This stops the right click menu from coming up on right click
        const onContextMenu = (evt: MouseEvent) => {
            evt.preventDefault();
        };

        const canvas = canvasRef.current;
        canvas?.addEventListener('pointermove', pointerMove);
        canvas?.addEventListener('pointerdown', pointerDown);
        canvas?.addEventListener('pointerup', pointerUp);
        canvas?.addEventListener('pointerleave', pointerUp);
        window.addEventListener('keydown', keyDown);
        window.addEventListener('keyup', keyUp);
        canvas?.addEventListener('contextmenu', onContextMenu);

        return () => {
            canvas?.removeEventListener('pointerdown', pointerDown);
            canvas?.removeEventListener('pointermove', pointerMove);
            canvas?.removeEventListener('pointerup', pointerUp);
            canvas?.removeEventListener('pointerleave', pointerUp);
            window.removeEventListener('keydown', keyDown);
            window.removeEventListener('keyup', keyUp);
            canvas?.removeEventListener('contextmenu', onContextMenu);

            deformTool.removeVisualEffects();
        };
    }, [
        cameraControlsRef,
        orderId,
        deformEnabled,
        canvasRef,
        brushSettings,
        onSculptIter,
        onSculptComplete,
        meshConnectivityGraph,
        editGeometry,
        sculptingGroup,
        morphPoints,
        cameraControlsEnabled,
        operationsManager,
    ]);
}

/**
 * Generates the state and callbacks for the deform tool
 */
export function useDeformState(
    mode: EditingMode | undefined,
    setMode: React.Dispatch<React.SetStateAction<EditingMode | undefined>>,
): DeformState {
    const [brushSettings, setBrushSettings] = React.useState<BrushSettings>(createDefaultDeformBrushSettings());

    const setBrushRadius = useSetBrushSetting('radius', setBrushSettings);
    const setBrushIntensity = useSetBrushSetting('intensity', setBrushSettings);

    const deformEnabled = mode === EditingMode.Deform;

    const toggleDeformEnabled = React.useCallback(() => {
        setMode(curr => {
            return curr === EditingMode.Deform ? undefined : EditingMode.Deform;
        });
    }, [setMode]);

    const setDeformEnabled = React.useCallback(() => {
        setMode(EditingMode.Deform);
    }, [setMode]);

    const [cameraControlsEnabled, setCameraControlsEnabled] = React.useState<boolean>(false);

    return React.useMemo(
        () => ({
            deformEnabled,
            toggleDeformEnabled,
            setDeformEnabled,
            brushSettings,
            setBrushRadius,
            setBrushIntensity,
            cameraControlsEnabled,
            setCameraControlsEnabled,
        }),
        [
            deformEnabled,
            toggleDeformEnabled,
            brushSettings,
            setBrushRadius,
            setBrushIntensity,
            setDeformEnabled,
            cameraControlsEnabled,
            setCameraControlsEnabled,
        ],
    );
}

/**
 * Creates the callbacks for the SculptingTool
 */
export function useDeformCallbacks(
    operationsManager: IOperationsManager,
    updateAppearance: (geometry: THREE.BufferGeometry) => void,
    curtains: THREE.BufferGeometry | undefined,
): {
    onSculptIter: HandleSculptFn;
    onSculptComplete: HandleSculptCompleteFn;
} {
    const onSculptIter = React.useCallback(
        (geometry: THREE.BufferGeometry | undefined, neighbors: number[]) => {
            if (!geometry) {
                return;
            }

            const neighborFaces = getConnectedFaces(
                neighbors,
                operationsManager.connectivityGraph.adjacencyFacesToVertex,
            );
            ComputeVertexNormalsByAngleForSubset(geometry, neighbors, neighborFaces);
        },
        [operationsManager],
    );

    const onSculptComplete = React.useCallback(
        (
            geometry: THREE.BufferGeometry | undefined,
            neighbors: number[],
            _trackingInfo: FinishingTrackingInfo,
            morphPoints?: MorphPointsInternalData,
        ) => {
            if (!geometry) {
                return;
            }

            if (neighbors.length > 0) {
                recomputeActiveDistanceForASubset(
                    ALL_SCULPT_HEATMAPS,
                    geometry,
                    neighbors,
                    operationsManager.proximalModels,
                    operationsManager.occlusalModels,
                    undefined,
                    curtains,
                    true,
                );
            }

            const collisionGeometry = recomputeCollisionsObject(operationsManager.editGeometry).model.geometry;
            operationsManager.collisionsGeometry?.copy(collisionGeometry);

            operationsManager.applyDeform(geometry, morphPoints);

            // We change the geometry's UUID to signal that it has been modified.
            geometry.uuid = uuidv4();

            updateAppearance(geometry);
        },
        [operationsManager, updateAppearance, curtains],
    );

    return { onSculptIter, onSculptComplete };
}
