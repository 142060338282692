import type { NamedRestorativeModel } from './FinishingApp.types';
import type { NamedGeometry, ScanName } from '@orthly/forceps';
import { ComputeVertexNormalsByAngle, ensureMeshIndex, ensureDistanceAttributesInitialized } from '@orthly/forceps';
import { ToothUtils } from '@orthly/items';
import type * as THREE from 'three';

// Computes the vertex normals and generates the bounding volume hierarchy for the geometry
export function computeNormalsAndGenerateBvh(geometry: THREE.BufferGeometry): void {
    // The models come in without normals, which are required for our shaders to work.
    ComputeVertexNormalsByAngle(geometry);
    ensureMeshIndex(geometry);
}

/**
 * Computes the thickness, proximal distance, and occlusal distance attributes for the restorative model.
 * @param restorativeModel The model whose distance attributes to populate
 * @param allRestorativeModels All restorative models in the design
 * @param upperJawGeometry Geometry of the upper jaw scan
 * @param lowerJawGeometry Geometry of the lower jaw scan
 */
export function computeDistanceAttributes(
    restorativeModel: NamedRestorativeModel,
    allRestorativeModels: NamedRestorativeModel[],
    upperJawGeometry: NamedGeometry<ScanName.UpperJaw>,
    lowerJawGeometry: NamedGeometry<ScanName.LowerJaw>,
): void {
    /**
     * NB: It is not uncommon for a tooth to be missing, causing nominally non-adjacent teeth to be adjacent, e.g the
     * first right premolar, 5, causing 4 and 6 to be adjacent. This is a common orthodontic treatment modality.
     * We should be more explicit in checking which restorative models are adjacent in this specific case. We may be able
     * to leverage scan segmentation information to determine which restorations are adjacent.
     */
    const adjacentRestorationGeometries = allRestorativeModels
        .filter(el => {
            return ToothUtils.areAdjacent(restorativeModel.toothNumber, el.toothNumber);
        })
        .map(el => el.geometry);

    const isUpper = ToothUtils.toothIsUpper(restorativeModel.toothNumber);
    const proximalGeometries = [isUpper ? upperJawGeometry : lowerJawGeometry, ...adjacentRestorationGeometries];
    const opposingGeometries = [isUpper ? lowerJawGeometry : upperJawGeometry];
    const curtainGeometry = restorativeModel.curtainGeometry;
    ensureDistanceAttributesInitialized(
        restorativeModel.geometry,
        proximalGeometries,
        opposingGeometries,
        curtainGeometry,
        true,
    );
}
