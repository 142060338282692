import { AutomationSelectField } from '../../components/AutomationBuilderFields';
import type { LabsGqlOrderFilterRuleFragment } from '@orthly/graphql-operations';
import type { LabsGqlFilterCriteriaSubmissionInput } from '@orthly/graphql-schema';
import { LabsGqlFilterComparator, LabsGqlFilterComparisonValueType } from '@orthly/graphql-schema';
import type { SimpleSelectOption } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

export const ComparatorLabels: { [Key in LabsGqlFilterComparator]: string } = {
    disabled: 'disabled',
    equals: 'is',
    notEquals: 'is not',
    set: 'exists',
    notSet: `doesn't exist`,
    gt: '>',
    gte: '>=',
    lt: '<',
    lte: '<=',
    oneOf: 'is one of',
    notOneOf: 'is none of',
    daysBefore: 'days before today',
    daysAfter: 'days after today',
    beforeNow: 'before now',
    afterNow: 'after now',
};

function getComparatorLabel(comparator: LabsGqlFilterComparator, selectedFilter?: LabsGqlOrderFilterRuleFragment) {
    const isPlural = !!selectedFilter && selectedFilter.name.slice(-1)[0] === 's';

    if (selectedFilter?.comparison_value_type === LabsGqlFilterComparisonValueType.Number) {
        if (comparator === LabsGqlFilterComparator.Equals) {
            return '=';
        }
        if (comparator === LabsGqlFilterComparator.NotEquals) {
            return '!=';
        }
    }
    if (isPlural) {
        if (comparator === LabsGqlFilterComparator.Set) {
            return 'exist';
        }
        if (comparator === LabsGqlFilterComparator.NotSet) {
            return `don't exist`;
        }
    }
    return ComparatorLabels[comparator];
}

export function comparatorsAvailableForFilter(
    selectedFilter?: LabsGqlOrderFilterRuleFragment,
): LabsGqlFilterComparator[] {
    if (!selectedFilter) {
        return [];
    }
    const getDefaultComparators = (): LabsGqlFilterComparator[] => {
        switch (selectedFilter.comparison_value_type) {
            case LabsGqlFilterComparisonValueType.Boolean:
                return [
                    LabsGqlFilterComparator.Set,
                    LabsGqlFilterComparator.NotSet,
                    LabsGqlFilterComparator.Equals,
                    LabsGqlFilterComparator.NotEquals,
                ];
            case LabsGqlFilterComparisonValueType.String:
            case LabsGqlFilterComparisonValueType.Tag:
            case LabsGqlFilterComparisonValueType.Pod:
                return [LabsGqlFilterComparator.Equals, LabsGqlFilterComparator.NotEquals];
            case LabsGqlFilterComparisonValueType.Date:
                return [
                    LabsGqlFilterComparator.Gt,
                    LabsGqlFilterComparator.Gte,
                    LabsGqlFilterComparator.Lt,
                    LabsGqlFilterComparator.Lte,
                    LabsGqlFilterComparator.DaysAfter,
                    LabsGqlFilterComparator.DaysBefore,
                    LabsGqlFilterComparator.BeforeNow,
                    LabsGqlFilterComparator.AfterNow,
                ];
            case LabsGqlFilterComparisonValueType.Number:
                return [
                    LabsGqlFilterComparator.Equals,
                    LabsGqlFilterComparator.NotEquals,
                    LabsGqlFilterComparator.Gt,
                    LabsGqlFilterComparator.Gte,
                    LabsGqlFilterComparator.Lt,
                    LabsGqlFilterComparator.Lte,
                ];
        }
    };
    const defaults = getDefaultComparators();
    if (selectedFilter.hasOptions) {
        defaults.push(LabsGqlFilterComparator.OneOf, LabsGqlFilterComparator.NotOneOf);
    }
    if (selectedFilter.comparison_value_nullable) {
        defaults.push(LabsGqlFilterComparator.Set, LabsGqlFilterComparator.NotSet);
    }
    return _.uniq(defaults.filter(d => !(selectedFilter.excluded_comparators ?? []).includes(d)));
}

interface OrderFilterComparatorFieldProps {
    onChange: (newValue: Partial<LabsGqlFilterCriteriaSubmissionInput>) => void;
    comparator?: LabsGqlFilterComparator;
    selectedFilter?: LabsGqlOrderFilterRuleFragment;
}

export const OrderFilterComparatorField: React.FC<OrderFilterComparatorFieldProps> = props => {
    const { onChange, selectedFilter, comparator } = props;
    const options = React.useMemo<SimpleSelectOption[]>(() => {
        return comparatorsAvailableForFilter(selectedFilter).map(value => ({
            value,
            label: getComparatorLabel(value, selectedFilter),
        }));
    }, [selectedFilter]);
    return (
        <AutomationSelectField
            disabled={!selectedFilter}
            value={comparator}
            options={options}
            onChange={value => {
                !!value && onChange({ comparator: value as LabsGqlFilterComparator });
            }}
            label={''}
        />
    );
};
