import {
    type ScanReviewPartialScene,
    type ScanReviewActiveJawData,
    type ScanReviewSceneStateManager,
} from '../ScanReviewSceneTypes';
import { type ScanReviewViewManager } from '../ScanReviewViewTypes';
import { ScanReviewUndercutToolController } from './ScanReviewUndercutToolController';
import { ScanReviewUndercutToolLiveObjectsProvider } from './ScanReviewUndercutToolServices';
import type { Jaw } from '@orthly/shared-types';
import constate from 'constate';
import React from 'react';
import type * as THREE from 'three';

interface ScanReviewUndercutViewProps {
    scene: ScanReviewPartialScene;
    sceneStateManager: ScanReviewSceneStateManager;
    viewManager: ScanReviewViewManager;
    activeJawData?: ScanReviewActiveJawData;
    onInsertionAxisUpdate?: (jaw: Jaw, newInsertionAxis: THREE.Vector3) => void;
}

function useScanReviewUndercutViewApp({
    scene,
    sceneStateManager,
    viewManager,
    activeJawData,
    onInsertionAxisUpdate,
}: ScanReviewUndercutViewProps) {
    const liveObjectsProvider = React.useMemo(
        () => activeJawData && new ScanReviewUndercutToolLiveObjectsProvider(activeJawData.insertionAxis),
        [activeJawData],
    );

    const toolController = React.useMemo(
        () =>
            ScanReviewUndercutToolController.makeController(
                viewManager,
                scene,
                sceneStateManager,
                activeJawData,
                liveObjectsProvider,
                onInsertionAxisUpdate,
            ),
        [activeJawData, liveObjectsProvider, onInsertionAxisUpdate, scene, sceneStateManager, viewManager],
    );

    return {
        viewManager,
        activeJawData,
        scene,
        toolController,
    };
}

export const [ScanReviewUndercutViewAppProvider, useScanReviewUndercutViewContext] =
    constate(useScanReviewUndercutViewApp);
