import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import { LabsGqlLabPriceRuleType } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';

export const ExternalDesignForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.ExternalDesign>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.ExternalDesign>({
                unit_type: {
                    type: 'select',
                    label: 'Unit Type',
                    // This can actually be any unit type, but I think these are the relevant ones in practice
                    options: [
                        'Crown',
                        'CrownPontic',
                        { label: 'Implant', value: 'Abutment' },
                        'Veneer',
                        'Inlay',
                        'Onlay',
                        'Model',
                        'Solid Model',
                        'Implant Model',
                        'Removable Model',
                        'Bleaching Tray',
                        'Night Guard',
                        'Retainer',
                    ],
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          unit_type:
                              price.rule.__typename === 'LabPriceExternalDesignRuleDTO' ? price.rule.unit_type : null,
                      }
                    : {}
            }
            onSubmit={async result => {
                await submit({
                    name: result.name,
                    rule: {
                        type: LabsGqlLabPriceRuleType.ExternalDesign,
                        unit_type: result.unit_type || null,
                    },
                });
            }}
            disabled={disabled}
        />
    );
};
