import type { IBaseItemData } from '../../types';
import { getHeaderForLabSlipItem } from '../../utils/shared.utils';
import { BaseItem } from './BaseItem';
import type { IModelItem } from '@orthly/items';
import React from 'react';

/**
 * Renders a "Model" order item to display on
 * the printable Slip
 * @param props The order item to render
 * @returns Rendered order item details for the order "Model" item
 */
export const ModelItem: React.VFC<{ item: IModelItem }> = ({ item }) => {
    return <BaseItem itemData={mapModelItemToPrintableSlipData(item)} />;
};

/**
 * Pull data needed for printable slip display into a standard data structure
 * @param item IModelItem order item to display
 * @returns Structured data for Model item display
 */
function mapModelItemToPrintableSlipData(item: IModelItem): IBaseItemData {
    const optionalFields: { key: string; value: string }[] = [];

    if (item.unit_type === 'Diagnostic Model' && item.selected_teeth) {
        optionalFields.push({ key: 'Teeth', value: item.selected_teeth.join(', ') });
    }

    return {
        headerLeft: getHeaderForLabSlipItem(item),
        itemFields: [{ key: 'Type', value: item.model_type }, ...optionalFields],
    };
}
