import { AddOrderItemV2Dialog } from '../../ManageOrderItemsV2/AddOrderItemV2Dialog';
import type { ICartItemV2DTO } from '@orthly/items';
import { Button, AddIcon } from '@orthly/ui-primitives';
import React from 'react';

interface AddItemActionProps {
    onSubmit: (item: ICartItemV2DTO) => void;
    doctorPreferencesId: string;
    disabled?: boolean;
}

export const AddItemAction: React.VFC<AddItemActionProps> = ({ onSubmit, doctorPreferencesId }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <AddOrderItemV2Dialog
                doctorPreferencesId={doctorPreferencesId}
                open={open}
                setOpen={setOpen}
                onSubmit={async item => onSubmit(item)}
            />
            <Button fullWidth variant={'ghost'} onClick={() => setOpen(true)}>
                <AddIcon /> Add Item
            </Button>
        </>
    );
};
