import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import {
    OrderPaperIcon,
    WarningIcon,
    PalmIcon,
    ToolOutlinedIcon,
    TruckIconOutlined,
    RefabCircleIcon,
    OverflowTextWithTooltip,
} from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    type SvgIconProps,
    Text,
    Grid,
    CancelIcon,
    CheckIcon,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    statusIcon: { marginRight: 4, width: 16, height: 16, color: FlossPalette.GRAY },
    secondaryText: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
    itemsIndicator: {
        backgroundColor: FlossPalette.GRAY,
        height: 16,
        width: 16,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
        marginTop: 2,
    },
    countText: { color: FlossPalette.TAN, fontWeight: 500, lineHeight: 0 },
}));

const StatusIconMap: { [K in LabsGqlLabOrderStatus]-?: React.ComponentType<SvgIconProps> } = {
    [LabsGqlLabOrderStatus.New]: OrderPaperIcon,
    [LabsGqlLabOrderStatus.NeedsReview]: WarningIcon,
    [LabsGqlLabOrderStatus.OnHold]: PalmIcon,
    [LabsGqlLabOrderStatus.Fabrication]: ToolOutlinedIcon,
    [LabsGqlLabOrderStatus.Shipped]: TruckIconOutlined,
    [LabsGqlLabOrderStatus.Delivered]: CheckIcon,
    [LabsGqlLabOrderStatus.Cancelled]: CancelIcon,
    [LabsGqlLabOrderStatus.NeedsRefabrication]: RefabCircleIcon,
};

interface OrderDetailSidebarItemPatientTitleProps {
    patient_name: string;
    patient_order_count: number;
    status: LabsGqlLabOrderStatus;
    disableBold?: boolean;
}

export const OrderDetailSidebarItemPatient: React.FC<OrderDetailSidebarItemPatientTitleProps> = props => {
    const { patient_order_count, patient_name, status } = props;
    const classes = useStyles();
    const Icon = React.useMemo(() => StatusIconMap[status], [status]);
    return (
        <Grid container alignItems={'center'} style={{ position: 'relative' }} wrap={'nowrap'}>
            <Grid item container xs={10} alignItems={'center'} style={{ position: 'relative' }} wrap={'nowrap'}>
                <Icon className={classes.statusIcon} />
                <OverflowTextWithTooltip
                    medium={!props.disableBold}
                    text={patient_name}
                    color={'BLACK'}
                    variant={'body2'}
                />
            </Grid>
            {patient_order_count > 1 && (
                <Grid
                    item
                    container
                    xs={2}
                    alignItems={'center'}
                    style={{ position: 'relative' }}
                    justifyContent={'flex-end'}
                >
                    <Grid container className={classes.itemsIndicator}>
                        <Text variant={'caption'} className={classes.countText}>
                            {patient_order_count}
                        </Text>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
