import { FlossPalette, styled } from '@orthly/ui-primitives';

export type ViewerState = 'uploading' | 'annotating-screenshot' | 'viewing-model';

export const Root = styled('div')<{
    showDrawingControls: boolean;
    isComparisonModeActive: boolean;
    selectedDesignRevisionAlreadyReviewed: boolean;
}>(({ isComparisonModeActive, selectedDesignRevisionAlreadyReviewed }) => ({
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '16px',
    border:
        isComparisonModeActive && !selectedDesignRevisionAlreadyReviewed
            ? `2px solid ${FlossPalette.BLACK}`
            : `1px solid ${FlossPalette.STROKE_LIGHT}`,
}));
export const DesignStatusContainer = styled('div')<{
    selectedDesignRevisionAlreadyReviewed: boolean;
}>(({ selectedDesignRevisionAlreadyReviewed }) => ({
    position: 'absolute',
    top: '10px',
    left: '10px',
    padding: '0px 10px',
    background: selectedDesignRevisionAlreadyReviewed ? 'transparent' : FlossPalette.BLACK,
    zIndex: 2,
    borderRadius: '4px',
}));
export const CanvasAndWrapperContainer = styled('div')<{
    viewerState: ViewerState;
}>(({ viewerState }) => ({
    position: 'relative',
    display: viewerState === 'viewing-model' ? 'block' : 'none',
}));

export const DrawControlsAndAnnotationContainer = styled('div')<{
    viewerState: ViewerState;
}>(({ viewerState }) => ({
    position: 'relative',
    display: viewerState === 'annotating-screenshot' ? 'block' : 'none',
}));
export const CanvasContainer = styled('div')({
    overflow: 'hidden',
    backgroundColor: FlossPalette.DARK_TAN,
});
export const BlockerContainer = styled('div')<{
    viewerState: ViewerState;
}>(({ viewerState }) => ({
    display: viewerState === 'uploading' ? 'block' : 'none',
    backgroundColor: FlossPalette.DARK_TAN,
}));
export const Blocker = styled('div')<{
    canvasSize: { width: number; height: number };
}>(({ canvasSize }) => ({
    minWidth: canvasSize.width,
    minHeight: canvasSize.height,
    width: '100%',
}));
