import { PatientViewsToolbar } from './PatientViewsToolbar';
import { ToothViewsToolbar } from './ToothViewsToolbar';
import { UndoRedoToolbar } from './UndoRedoToolbar';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    toolbarItem: {
        padding: '0px 8px',
    },
}));

export const BottomToolbar: React.VFC<{ className?: string }> = ({ className }) => {
    const classes = useStyles();

    return (
        <Grid container className={className} alignItems={'flex-start'} justifyContent={'center'}>
            <Grid item className={classes.toolbarItem}>
                <PatientViewsToolbar />
            </Grid>
            <Grid item className={classes.toolbarItem}>
                <ToothViewsToolbar />
            </Grid>
            <Grid item className={classes.toolbarItem}>
                <UndoRedoToolbar />
            </Grid>
        </Grid>
    );
};
