import { type Invoice, type InvoiceRow } from '../InvoicesTable/InvoiceTable.types';
import { usePartnerPaymentSplitSourceOptions } from './EditPaymentSplitConfig/usePartnerPaymentSplitSourceOptions.graphql';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { Format } from '@orthly/runtime-utils';
import { DefaultValidationMap, QuickForm, RootActionDialog, SimpleSelect, useRootActionCommand } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import { compact } from 'lodash';
import React from 'react';

interface RefundInvoiceChargeActionProps {
    invoice: InvoiceRow<Invoice>;
    refetch: () => Promise<unknown>;
}

type FormFields = {
    refund_reason: string;
    refund_amount_dollars: number;
};

type Charge = Omit<Invoice['payments'][number], 'charge_id'> & { charge_id: string };

function useChargesFromInvoice(invoice: InvoiceRow<Invoice>, open: boolean) {
    const charges = React.useMemo(() => {
        return compact(
            invoice.payments.map<Charge | undefined>(payment => {
                if (payment.status === 'failed' || payment.status === 'pending') {
                    return undefined;
                }

                const { stripe_charge_id, stripe_payment_intent_id } = payment;
                const stripe_charge_or_payment_intent_id = stripe_charge_id ?? stripe_payment_intent_id;
                return stripe_charge_or_payment_intent_id
                    ? { ...payment, charge_id: stripe_charge_or_payment_intent_id }
                    : undefined;
            }),
        );
    }, [invoice.payments]);
    const { sourceOptions, sourcesLoading } = usePartnerPaymentSplitSourceOptions(invoice.partner_id, !open);
    const chargeOptions = React.useMemo(() => {
        return charges.map(charge => {
            const chargeSourceId = charge.stripe_payment_source_id;
            const source = sourceOptions.find(o => o.id === chargeSourceId)?.label ?? 'Unknown';
            const amount = Format.currency(charge.amount_cents);
            return { value: charge.charge_id, label: `${amount} - ${source}` };
        });
    }, [charges, sourceOptions]);
    return { charges, chargeOptions, sourcesLoading };
}

const RefundInvoicePayment_Mutation = graphql(`
    mutation RefundInvoicePayment($args: RefundInvoicePaymentInput!) {
        refundInvoicePayment(args: $args) {
            id
        }
    }
`);

export const RefundInvoiceChargeAction: React.FC<RefundInvoiceChargeActionProps> = ({ invoice, refetch }) => {
    const [open, setOpen] = React.useState(false);
    const refundInvoicePaymentMtn = useMutation(RefundInvoicePayment_Mutation);
    const { submit, submitting } = useRootActionCommand(refundInvoicePaymentMtn, {
        onSuccess: async () => {
            setOpen(false);
            await refetch();
        },
        successMessage: 'Invoice charge refunded!',
    });
    const { sourcesLoading, chargeOptions, charges } = useChargesFromInvoice(invoice, open);
    const [selectedCharge, setSelectedCharge] = React.useState<Charge | undefined>(
        charges.length === 1 ? charges[0] : undefined,
    );
    if (charges.length === 0) {
        return null;
    }
    return (
        <RootActionDialog
            loading={sourcesLoading || submitting}
            open={open}
            setOpen={setOpen}
            title={'Refund Charge'}
            content={
                <Grid container>
                    <Grid container style={{ paddingBottom: 10 }}>
                        <SimpleSelect
                            options={chargeOptions}
                            onChange={chargeId => setSelectedCharge(charges.find(c => c.charge_id === chargeId))}
                            label={'Select Charge To Refund'}
                            value={selectedCharge?.charge_id ?? ''}
                        />
                    </Grid>
                    {selectedCharge && (
                        <QuickForm<FormFields>
                            fields={{
                                refund_amount_dollars: {
                                    type: 'number',
                                    validation: DefaultValidationMap.number
                                        .min(1)
                                        .max(selectedCharge.amount_cents / 100),
                                    fieldProps: { InputProps: { startAdornment: '$' } },
                                },
                                refund_reason: {
                                    type: 'text',
                                    fieldProps: { rows: 3, multiline: true },
                                },
                            }}
                            initialValues={{}}
                            onSubmit={async result => {
                                if (!selectedCharge) {
                                    return;
                                }
                                const refund_amount_cents = Math.round(result.refund_amount_dollars * 100);
                                if (refund_amount_cents > selectedCharge.amount_cents) {
                                    return;
                                }
                                await submit({
                                    args: {
                                        invoiceId: invoice.id,
                                        stripeChargeOrPaymentIntentId: selectedCharge.charge_id,
                                        amountCents: refund_amount_cents,
                                        refundReason: result.refund_reason,
                                    },
                                });
                            }}
                        />
                    )}
                </Grid>
            }
            buttonText={'Refund Invoice Payment'}
        />
    );
};
