import { TOOLBAR_HEIGHT_PX } from './Constants';
import { StackX } from '@orthly/ui';
import type { BoxProps } from '@orthly/ui-primitives';
import { FlossPalette, stylesFactory, Divider } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    toolbarContainer: {
        overflow: 'hidden',
        height: TOOLBAR_HEIGHT_PX,
        backgroundColor: FlossPalette.TAN,
        border: `1px solid ${FlossPalette.LIGHT_GRAY}`,
        borderRadius: 16,
    },
    divider: {
        backgroundColor: FlossPalette.LIGHT_GRAY,
    },
}));

export const ToolbarContainer: React.FC<BoxProps> = ({ children, className, ...props }) => {
    const classes = useStyles();

    const childrenArray = React.Children.toArray(children);
    const numChildren = childrenArray.length;

    return (
        // @ts-expect-error: StackX has an incompatible "ref" prop.
        <StackX className={clsx(classes.toolbarContainer, className)} {...props}>
            {childrenArray.map((child, i) => {
                return (
                    <>
                        {child}
                        {i < numChildren - 1 && <Divider className={classes.divider} orientation={'vertical'} />}
                    </>
                );
            })}
        </StackX>
    );
};
