import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { ImplantSystemInput } from '@orthly/dentin';
import type { LabsGqlImplantTypeLiteFragment } from '@orthly/graphql-operations';
import type { LabOrderItemSKUType } from '@orthly/items';
import React from 'react';

interface ItemEditorV2SurgicalGuideImplantSystemFieldsProps
    extends ItemEditorV2BaseProps<LabOrderItemSKUType.SurgicalGuide> {
    implantSystems: LabsGqlImplantTypeLiteFragment[];
}

export const ItemEditorV2SurgicalGuideImplantSystemFields: React.VFC<
    ItemEditorV2SurgicalGuideImplantSystemFieldsProps
> = ({ item, updateItem, showError, implantSystems }) => {
    const implantMetadata = item.unit.implant_metadata;

    return (
        <ImplantSystemInput
            hideConnection={true}
            implantSystems={implantSystems}
            linkMeta={{
                manufacturer: implantMetadata?.manufacturer ?? null,
                system: implantMetadata?.system ?? null,
                connection: null,
            }}
            updateField={(fieldName, value) => {
                if (fieldName !== 'manufacturer' && fieldName !== 'system') {
                    return;
                }
                updateItem({ name: 'surgical_guide_implant_metadata', payload: { [fieldName]: value } });
            }}
            showIncompleteError={showError}
        />
    );
};
