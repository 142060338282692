import { EditCapabilitiesDialog } from './EditCapabilitiesDialog';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment } from '@orthly/graphql-operations';
import { useApolloRefetch, GetDesignStaffDocument } from '@orthly/graphql-react';
import { LabsGqlDesignStaffTaskType } from '@orthly/graphql-schema';
import { DesignReviewCapabilities, DesignReviewCapabilityTypeToName } from '@orthly/shared-types';
import { useRootActionCommand } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

const UpsertDesignStaffMutation = graphql(`
    mutation UpsertDesignStaff(
        $user_id: String!
        $task_type: DesignStaffTaskType!
        $capabilities: DesignStaffCapabilityInput!
    ) {
        upsertDesignStaff(user_id: $user_id, capabilities: $capabilities, task_type: $task_type) {
            id
        }
    }
`);

export const DesignReviewCapabilitiesDialog: React.FC<{
    user_id: string;
    capabilities: LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment['DesignReview'];
}> = props => {
    const { user_id, capabilities: existingCapabilities } = props;
    const refetch = useApolloRefetch();
    const mutation = useMutation(UpsertDesignStaffMutation);

    const { submit, submitting } = useRootActionCommand(mutation, {
        successMessage: 'Design Staff Updated!',
        onSuccess: () => {
            refetch.query(GetDesignStaffDocument);
        },
    });

    return (
        <EditCapabilitiesDialog
            title={`Edit Design Review Capabilities`}
            existingCapabilities={_.omit(existingCapabilities, '__typename')}
            typeToName={DesignReviewCapabilityTypeToName}
            capabilitiesDef={DesignReviewCapabilities}
            onSubmit={capabilities => {
                void submit({
                    user_id,
                    task_type: LabsGqlDesignStaffTaskType.DesignReview,
                    capabilities,
                });
            }}
            submitting={submitting}
        />
    );
};
