import { usePalateState } from '../../state/Palate.reducer';
import { CONTAINER_WIDTH } from '../../usePalateStyles';
import type { LabsGqlReviewSubmissionFragment } from '@orthly/graphql-operations';
import { useGetRecentReviewsQuery } from '@orthly/graphql-react';
import { LabsGqlReviewRating } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { stylesFactory, FlossPalette, Text, Grid, LinearProgress } from '@orthly/ui-primitives';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    reviewDot: {
        margin: '0 2px',
        width: 8,
        height: 8,
        borderRadius: 8,
        flexShrink: 0,
    },
    unFocused: {
        opacity: 0.24,
    },
    progressBar: {
        borderRadius: 4,
        backgroundColor: FlossPalette.STAR_GRASS,
        '& > .MuiLinearProgress-barColorPrimary': {
            backgroundColor: FlossPalette.ATTENTION,
        },
    },
    reviewGraphContainer: {
        paddingTop: 4,
    },
    reviewGraph: {
        width: CONTAINER_WIDTH,
    },
}));

interface ReviewTagLegendProps {
    tag: string;
    tagCount: number;
    hovered: boolean;
}

interface RecentReviewDotProps {
    isNegative?: boolean;
    hovered: boolean;
    reviewId: string;
    setHoveredReviewId: (hoveredReviewId: string) => void;
}

interface RecentReviewsDetailsProps {
    reviews: LabsGqlReviewSubmissionFragment[];
    hoveredReviewId: string;
    setHoveredReviewId: (hoveredReviewId: string) => void;
}

interface RecentReviewsProps {
    practiceId: string;
}

const ReviewTagLegend: React.FC<ReviewTagLegendProps> = props => {
    const classes = useStyles();
    const { tag, tagCount, hovered } = props;
    return (
        <Grid container className={!hovered ? classes.unFocused : undefined} style={{ width: 'fit-content' }}>
            <Text
                variant={'caption'}
                color={['Perfect', 'Good'].includes(tag) ? 'STAR_GRASS' : 'ATTENTION'}
                style={{ marginRight: 4, fontWeight: 500 }}
            >
                {tagCount}
            </Text>
            <Text variant={'caption'} style={{ marginRight: 8 }}>
                {tag}
            </Text>
        </Grid>
    );
};

const RecentReviewDot: React.FC<RecentReviewDotProps> = props => {
    const { isNegative, hovered, reviewId, setHoveredReviewId } = props;
    const classes = useStyles();
    return (
        <div
            className={clsx(classes.reviewDot, !hovered && classes.unFocused)}
            style={{ backgroundColor: isNegative ? FlossPalette.ATTENTION : FlossPalette.STAR_GRASS }}
            onMouseOver={() => setHoveredReviewId(reviewId)}
            onMouseLeave={() => setHoveredReviewId('')}
        />
    );
};

const RecentReviewsDetails: React.FC<RecentReviewsDetailsProps> = props => {
    const { reviews, hoveredReviewId, setHoveredReviewId } = props;
    return (
        <Grid container style={{ alignItems: 'center' }} wrap={`nowrap`}>
            <Text variant={'caption'} color={'DARK_GRAY'} style={{ marginRight: 8, fontWeight: 500 }}>{`Last ${
                reviews.length === 1 ? 'review' : `${reviews.length} reviews`
            } within last 30 days`}</Text>
            {reviews.map((review, idx) => (
                <RecentReviewDot
                    key={idx}
                    isNegative={review.rating === LabsGqlReviewRating.Bad}
                    reviewId={review.id}
                    hovered={!hoveredReviewId || hoveredReviewId === review.id}
                    setHoveredReviewId={setHoveredReviewId}
                />
            ))}
        </Grid>
    );
};

export const PalateRecentReviews: React.FC<RecentReviewsProps> = props => {
    const classes = useStyles();
    const { practiceId } = props;
    const [hoveredReviewId, setHoveredReviewId] = React.useState('');
    const orderFilters = usePalateState(s => s.orderFilters);
    const doctorFilter = orderFilters?.find(filter => filter.filter_id === 'by_doctor')?.comparison_value;
    const { data } = useGetRecentReviewsQuery({
        variables: {
            partner_id: practiceId,
            doctor_ids: doctorFilter ?? [],
        },
    });
    const reviews = data?.getRecentReviews;
    if (!reviews || reviews.length === 0) {
        return null;
    }

    const negativeReviews = reviews.filter(review => review.rating === LabsGqlReviewRating.Bad);
    const reviewToTags: { [key: string]: string[] } = reviews.reduce(
        (obj, review) =>
            Object.assign(obj, {
                [review.id]: review.items.flatMap(item =>
                    item.tags.length > 0 ? item.tags.map(tag => tag.title) : Format.titleCase(item.rating),
                ),
            }),
        {},
    );
    const reviewTags = Object.values(reviewToTags).flat();
    const reviewTagsCount = Object.entries(_.countBy(reviewTags)).map(([tag, tagCount]) => ({
        tag,
        tagCount,
    }));
    return (
        <Grid container style={{ padding: '16px 32px 0' }}>
            <RecentReviewsDetails
                reviews={reviews}
                hoveredReviewId={hoveredReviewId}
                setHoveredReviewId={setHoveredReviewId}
            />
            <Grid container className={classes.reviewGraphContainer}>
                <LinearProgress
                    value={((negativeReviews?.length ?? 0) / reviews.length) * 100}
                    variant={'determinate'}
                    className={clsx(classes.progressBar, classes.reviewGraph)}
                />
            </Grid>
            <Grid container className={clsx(classes.reviewGraphContainer, classes.reviewGraph)}>
                {reviewTagsCount.map((reviewTagCount, idx) => (
                    <ReviewTagLegend
                        key={idx}
                        tag={reviewTagCount.tag}
                        tagCount={reviewTagCount.tagCount}
                        hovered={
                            !!hoveredReviewId && reviewToTags
                                ? reviewToTags[hoveredReviewId]?.includes(reviewTagCount.tag) ?? false
                                : true
                        }
                    />
                ))}
            </Grid>
        </Grid>
    );
};
