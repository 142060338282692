import { useFirebasePreviewMulti } from '../../hooks';
import { ShareScansWithPatientModalContent } from './ShareScanModalComponents/ShareScansWithPatientModalContent';
import { ShareScansWithPatientTitle } from './ShareScanModalComponents/ShareScansWithPatientTitle';
import type { ScanSnapshotGeometries } from '@orthly/dentin';
import { RootActionDialog } from '@orthly/ui';
import React from 'react';

interface ShareWithPatientModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    scanExportId: string | null;
    patientId: string | null;
    annotationUrls: Array<string>;
    geometries?: ScanSnapshotGeometries; // only required for share with pdf
    message?: string | null; // only required when re-sharing
    organizationId?: string; // only required when re-sharing
    staffMemberId?: string; // only required when re-sharing
    patientEmail?: string | null; // only required when re-sharing
    patientPhoneNumber?: string | null; // only required when re-sharing
    onShareComplete?: () => void;
}

export const ShareScansWithPatientModal: React.FC<ShareWithPatientModalProps> = ({
    open,
    setOpen,
    scanExportId,
    annotationUrls,
    patientId,
    geometries,
    message,
    organizationId,
    staffMemberId,
    patientEmail,
    patientPhoneNumber,
    onShareComplete,
}) => {
    const [organizationName, setOrganizationName] = React.useState<string | null>(null);
    const [organizationPhone, setOrganizationPhone] = React.useState<string | null>(null);

    const [onSuccessScreen, setOnSuccessScreen] = React.useState(false);

    const { result: annotationImages } = useFirebasePreviewMulti(
        annotationUrls.map((fullPath: string) => ({
            source: fullPath,
            name: fullPath,
        })),
        true,
    );

    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            onClose={() => setOnSuccessScreen(false)}
            content={
                <ShareScansWithPatientModalContent
                    setOpen={setOpen}
                    scanExportId={scanExportId}
                    annotationImages={annotationImages}
                    annotationUrls={annotationUrls}
                    patientId={patientId}
                    organizationName={organizationName}
                    organizationPhone={organizationPhone}
                    geometries={geometries}
                    message={message}
                    organizationId={organizationId}
                    staffMemberId={staffMemberId}
                    patientEmail={patientEmail}
                    patientPhoneNumber={patientPhoneNumber}
                    onShareComplete={onShareComplete}
                    onSuccessScreen={onSuccessScreen}
                    setOnSuccessScreen={setOnSuccessScreen}
                />
            }
            title={
                <ShareScansWithPatientTitle
                    organizationName={organizationName}
                    setOrganizationName={setOrganizationName}
                    organizationPhone={organizationPhone}
                    setOrganizationPhone={setOrganizationPhone}
                    setShareModalOpen={setOpen}
                    onSuccessScreen={onSuccessScreen}
                    organizationId={organizationId}
                />
            }
            showCloseButton={onSuccessScreen}
            loading={false}
            hideButton={true}
            maxWidth={'lg'}
        />
    );
};
