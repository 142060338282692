import { showBrowserTabAlert } from './BrowserTabAlert';
import type { LabsGqlDesignStaffDetailedStatusAssignmentFragment } from '@orthly/graphql-operations';
import type { LabsGqlDesignStaffDetailedStatusAssignment } from '@orthly/graphql-schema';
import { useUserInteractedAudio } from '@orthly/ui';
import FaviconBell from '@orthly/ui/assets/logos/bell.png';
import FaviconDandy from '@orthly/ui/assets/logos/favicon.png';
import NotificationSound from '@orthly/ui/assets/sounds/alert_high-intensity.wav';
import dayjs from 'dayjs';
import React from 'react';
import { useLocation } from 'react-router-dom';

const LOCAL_STORAGE_RECENTLY_NOTIFIED_ASSIGNMENTS = 'LOCAL_STORAGE_RECENTLY_NOTIFIED_ASSIGNMENTS';

function createFaviconLink(id: string, rel: string, href: string): HTMLLinkElement {
    const link = document.createElement('link');
    link.id = id;
    link.rel = rel;
    link.href = href;

    return link;
}

function swapFavicons(faviconToRemove: string, faviconToAdd: HTMLLinkElement) {
    const oldLink = document.getElementById(faviconToRemove);
    if (oldLink) {
        document.head.removeChild(oldLink);
        document.head.appendChild(faviconToAdd);
    }
}

function taskAssignmentIsNewAndUnnotified(taskId: string, assignedAt: string) {
    return (
        dayjs().diff(dayjs(assignedAt), 'milliseconds') <= 60 * 1000 &&
        !JSON.parse(localStorage.getItem(LOCAL_STORAGE_RECENTLY_NOTIFIED_ASSIGNMENTS) ?? '[]')?.includes(taskId)
    );
}

export const DesignerTaskAssignmentNotifications: React.VFC<{
    autoAssignments: LabsGqlDesignStaffDetailedStatusAssignmentFragment[] | undefined;
}> = props => {
    const { autoAssignments } = props;
    const { pathname } = useLocation();
    const mappedAutoassignments = autoAssignments?.map(aa => aa as LabsGqlDesignStaffDetailedStatusAssignment);

    React.useEffect(() => {
        swapFavicons('bell-favicon', createFaviconLink('dandy-favicon', 'shortcut icon', FaviconDandy));
    }, [pathname]);

    const notificationAudio = useUserInteractedAudio(NotificationSound);

    React.useEffect(() => {
        const newTaskAssignment = mappedAutoassignments?.filter(aa =>
            taskAssignmentIsNewAndUnnotified(aa.task_id, aa.assigned_at),
        );
        if (newTaskAssignment?.length) {
            showBrowserTabAlert('New Task Assigned!', notificationAudio, FaviconBell);
            localStorage.setItem(
                LOCAL_STORAGE_RECENTLY_NOTIFIED_ASSIGNMENTS,
                JSON.stringify(mappedAutoassignments?.map(a => a.task_id)),
            );
        }
    }, [mappedAutoassignments, notificationAudio]);

    return <></>;
};
