/* eslint-disable max-lines */
import { RecommendationHeader } from './RecommendationHeader';
import { useHelpIconWithAnchorEl } from './useHelpIconWithAnchorEl';
import type { TooltipProps } from '@orthly/ui-primitives';
import {
    stylesFactory,
    Text,
    FlossPalette,
    Icon,
    Grid,
    List,
    ListItem,
    ListItemText,
    Popover,
    Tooltip,
    ChevronDown,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import _ from 'lodash';
import React from 'react';

/**
 * NOTE: this file was originally created in the `Scanner` repo. The extended history can be found here:
 * https://github.com/orthly/Scanner/commits/79709f2614a39815a05e38a0d30af8c8ddd7e87d/packages/fluoride/src/components/case-builder/setup/components/BigRadioSelect.tsx
 */
const useStyles = stylesFactory(() => ({
    option: {
        borderRadius: 4,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        width: '100%',
        cursor: 'pointer',
        backgroundColor: FlossPalette.TAN,
        '&:hover': {
            borderColor: FlossPalette.STROKE_DARK,
        },
    },
    optionWhiteBackground: {
        backgroundColor: FlossPalette.WHITE,
    },
    optionDisabled: {
        cursor: 'default',
    },
    optionBody: {
        padding: 16,
        paddingLeft: 20,
    },
    optionSelected: {
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        borderColor: FlossPalette.PRIMARY_FOREGROUND,
        '&:hover': {
            borderColor: FlossPalette.PRIMARY_FOREGROUND,
        },
    },
    popoverButtonWrapper: {
        color: FlossPalette.STAR_GRASS,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 12,
        '&:hover': {
            color: FlossPalette.STAR_GRASS_HOVER,
        },
    },
    popoverPaper: {
        borderRadius: 4,
        marginTop: 4,
    },
    popoverOptionWrapper: {
        color: FlossPalette.STAR_GRASS,
        '&:hover': { color: FlossPalette.STAR_GRASS_HOVER },
    },
    // used to preserve the same amount of space that the RecommendationOptionHeader
    // would take up so that when the direction is row all of the options are still aligneed
    addHeaderSpace: {
        marginTop: 26,
    },
}));

export interface BigRadioOption<T> {
    label: string;
    value: T;
    body?: string | JSX.Element;
    helpText?: string | JSX.Element;
    toolTipPlacement?: TooltipProps['placement'];
    disabled?: boolean;
}

interface BigRadioSelectOptionProps<T> extends BigRadioOption<T> {
    selected: boolean;
    hideRadioButton?: boolean;
    recommended: boolean;
    onClick: () => void;
    direction?: 'row' | 'column';
    alignVariant?: 'left' | 'center';
    endAdornment?: React.ReactNode;
    endAdornmentDirection?: 'row' | 'column';
    style?: React.CSSProperties;
    backgroundColor?: Extract<keyof FlossPalette, 'TAN' | 'WHITE'>;
}

const BigRadioSelectOption = <T extends {}>(props: BigRadioSelectOptionProps<T>): React.ReactElement => {
    const {
        label,
        value,
        selected,
        recommended,
        body,
        onClick,
        helpText,
        endAdornment,
        endAdornmentDirection,
        alignVariant,
        hideRadioButton,
        toolTipPlacement,
        disabled,
        backgroundColor,
    } = props;
    const direction = props.direction ?? 'column';
    const classes = useStyles();
    const { helpAnchorEl, helpIconComponent } = useHelpIconWithAnchorEl();

    const option = (
        <Grid item xs>
            <Grid
                item
                xs={direction === 'column' ? 12 : undefined}
                className={cx(classes.option, {
                    [classes.optionWhiteBackground]: backgroundColor === 'WHITE',
                    [classes.optionDisabled]: disabled,
                    [classes.optionSelected]: selected,
                    [classes.addHeaderSpace]: !recommended && direction === 'row',
                })}
                onClick={() => !disabled && onClick()}
                aria-label={label}
                data-test={'big-radio-select-option'}
                data-test-value={_.isObjectLike(value) ? JSON.stringify(value) : `${value}`}
                data-test-recommended={`${recommended}`}
                style={props.style}
            >
                <Grid container direction={'column'} wrap={'nowrap'} alignContent={'center'}>
                    {recommended && (
                        <Grid item>
                            <RecommendationHeader styleOverrides={{ marginLeft: -1, width: 'calc(100% + 2px)' }} />
                        </Grid>
                    )}
                    <Grid container wrap={'nowrap'} className={classes.optionBody}>
                        {!hideRadioButton &&
                            (selected ? (
                                <Icon
                                    icon={'RadioButtonCheckedIcon'}
                                    style={{ color: FlossPalette.STAR_GRASS }}
                                    data-test={'big-radio-select-option-radio-checked'}
                                />
                            ) : (
                                <Icon
                                    icon={'RadioButtonUnCheckedIcon'}
                                    style={{ color: disabled ? FlossPalette.LIGHT_GRAY : FlossPalette.GRAY }}
                                    data-test={'big-radio-select-option-radio-unchecked'}
                                />
                            ))}
                        <Grid container direction={'column'} style={{ marginLeft: !hideRadioButton ? 16 : undefined }}>
                            <Grid item style={{ display: 'flex' }} direction={endAdornmentDirection ?? 'row'}>
                                <div style={{ flexGrow: 1, textAlign: alignVariant }}>
                                    <Text
                                        variant={'body2'}
                                        medium
                                        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                                        // eslint-disable-next-line no-nested-ternary
                                        color={selected ? 'GREEN' : disabled ? 'LIGHT_GRAY' : 'BLACK'}
                                    >
                                        {label}
                                    </Text>
                                </div>
                                <div>
                                    {helpText && helpIconComponent}
                                    {endAdornment}
                                </div>
                            </Grid>
                            {body && (
                                <Grid item>
                                    {_.isString(body) ? (
                                        <Text variant={'body2'} color={'GRAY'} style={{ textAlign: alignVariant }}>
                                            {body}
                                        </Text>
                                    ) : (
                                        body
                                    )}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

    return helpText ? (
        <Tooltip
            title={helpText}
            disableInteractive
            arrow
            placement={toolTipPlacement ?? 'bottom-end'}
            PopperProps={{ anchorEl: helpAnchorEl }}
        >
            {option}
        </Tooltip>
    ) : (
        option
    );
};

type BigRadioSelectOptionData<T> = { label: string; value: T; helpText?: string | JSX.Element };
type BigRadioSelectOptionDataWithOnClick<T> = BigRadioSelectOptionData<T> & { onClick: (val: T) => void };

export interface BigRadioSelectPopoverSectionProps<T> extends BigRadioSelectRowProps<T> {
    displayTitle: boolean;
    options: BigRadioSelectOptionDataWithOnClick<T>[];
}

/**
 * This component was split out of `BigRadioSelectPopoverSection` so that we can conditionally render the tooltip.
 */
const BigRadioSelectPopoverSectionOptionWithTooltip = <T,>({
    helpText,
    label,
    onClick,
    value,
    disabled,
}: BigRadioSelectOptionDataWithOnClick<T> & { disabled?: boolean }): React.ReactElement => {
    const classes = useStyles();

    const option = (
        <ListItem
            key={`${value}`}
            button
            disabled={disabled}
            onClick={onClick && (() => onClick(value))}
            className={classes.popoverOptionWrapper}
            data-test={'big-radio-select-extra-option'}
            data-test-value={`${value}`}
        >
            <ListItemText
                style={{ color: disabled ? FlossPalette.LIGHT_GRAY : undefined }}
                primaryTypographyProps={{ variant: 'body2' }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {label}
                    {helpText && (
                        <Icon
                            icon={'InfoIcon'}
                            style={{ height: 16, width: 16, marginLeft: '8px', color: FlossPalette.GRAY }}
                        />
                    )}
                </div>
            </ListItemText>
        </ListItem>
    );

    if (helpText) {
        return (
            <Tooltip title={helpText} disableInteractive arrow={false}>
                {option}
            </Tooltip>
        );
    }

    return option;
};

const BigRadioSelectPopoverSection = <T,>({
    title,
    displayTitle,
    helpText,
    disabled,
    options,
}: BigRadioSelectPopoverSectionProps<T>): React.ReactElement => {
    const { helpAnchorEl, helpIconComponent } = useHelpIconWithAnchorEl();

    return (
        <Grid xs={12} sm={'auto'} item>
            <List
                subheader={
                    displayTitle ? (
                        <ListItem>
                            <ListItemText disableTypography>
                                {helpText ? (
                                    <Tooltip
                                        title={helpText}
                                        arrow
                                        disableInteractive
                                        placement={'top-end'}
                                        PopperProps={{ anchorEl: helpAnchorEl }}
                                    >
                                        <Grid item style={{ display: 'flex' }}>
                                            <div style={{ flexGrow: 1 }}>
                                                <Text variant={'body2'} color={disabled ? 'LIGHT_GRAY' : 'DARK_GRAY'}>
                                                    {title ?? <>&nbsp;</>}
                                                </Text>
                                            </div>
                                            <div>{helpIconComponent}</div>
                                        </Grid>
                                    </Tooltip>
                                ) : (
                                    <Text variant={'body2'} color={disabled ? 'LIGHT_GRAY' : 'DARK_GRAY'}>
                                        {title ?? <>&nbsp;</>}
                                    </Text>
                                )}
                            </ListItemText>
                        </ListItem>
                    ) : undefined
                }
            >
                {options.map((option, idx) => (
                    <BigRadioSelectPopoverSectionOptionWithTooltip
                        key={`${title}_${idx}`}
                        {...option}
                        disabled={disabled}
                    />
                ))}
            </List>
        </Grid>
    );
};

export interface BigRadioSelectRowProps<T> {
    title?: string;
    disabled?: boolean;
    helpText?: string;
    options: BigRadioSelectOptionData<T>[];
}

interface BigRadioSelectPopoverProps<T> {
    rows: BigRadioSelectRowProps<T>[];
    onClick: (val: T) => void;
    label?: string;
}

const BigRadioSelectPopover = <T extends {}>({
    rows,
    onClick,
    label,
}: BigRadioSelectPopoverProps<T>): React.ReactElement => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLSpanElement | null>(null);
    const displayTitle = React.useMemo(() => rows.some(r => !!r.title), [rows]);

    return (
        <Grid item>
            <Grid container direction={'row'}>
                <Grid
                    item
                    onClick={event => setAnchorEl(event.currentTarget)}
                    className={cx(classes.option, classes.popoverButtonWrapper)}
                    data-test={'big-radio-select-show-more'}
                >
                    <Text variant={'body2'} medium style={{ color: 'inherit' }}>
                        {label ?? 'Show more options'}
                    </Text>
                    <ChevronDown style={{ color: 'inherit', marginLeft: 2 }} />
                </Grid>
            </Grid>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{ className: classes.popoverPaper }}
            >
                <Grid container>
                    {rows.map((row, i) => {
                        const popoverSectionProps = {
                            ...row,
                            displayTitle,
                            options: row.options.map(option => ({
                                ...option,
                                onClick: (value: T) => {
                                    onClick(value);
                                    setAnchorEl(null);
                                },
                            })),
                        };

                        return <BigRadioSelectPopoverSection<T> key={i} {...popoverSectionProps} />;
                    })}
                </Grid>
            </Popover>
        </Grid>
    );
};

export interface BigRadioSelectProps<T = string> {
    options: BigRadioOption<T>[];
    value?: T;
    onChange: (val: T) => void;
    moreOptions?: BigRadioSelectRowProps<T>[];
    moreOptionsLabel?: string;
    hideRadioButtons?: boolean;
    direction?: 'row' | 'column';
    alignVariant?: 'left' | 'center';
    selectedEndAdornment?: React.ReactNode;
    endAdornmentDirection?: 'row' | 'column';
    optionStyle?: React.CSSProperties;
    optionBackgroundColor?: Extract<keyof FlossPalette, 'TAN' | 'WHITE'>;
    recommendation?: T;
}

export const BigRadioSelect = <T extends {} = string>(props: BigRadioSelectProps<T>): React.ReactElement => {
    const {
        options,
        value,
        onChange,
        moreOptions,
        moreOptionsLabel,
        recommendation,
        hideRadioButtons,
        selectedEndAdornment,
        endAdornmentDirection,
        optionStyle,
        optionBackgroundColor,
    } = props;
    const direction = props.direction ?? 'column';
    const alignVariant = props.alignVariant ?? 'left';
    const [extraOption, setExtraOption] = React.useState<{ label: string; value: T }>();

    React.useEffect(() => {
        if (value !== undefined && moreOptions && !options.some(opt => opt.value === value)) {
            for (const row of moreOptions) {
                const option = row.options.find(opt => opt.value === value);
                if (option) {
                    setExtraOption(option);
                    return;
                }
            }
        }
    }, [moreOptions, setExtraOption, options, value]);

    // auto-select recommendation, but only if nothing is already selected
    React.useEffect(() => {
        if (value === undefined && recommendation !== undefined) {
            onChange(recommendation);
        }
    }, [value, recommendation, onChange]);

    return (
        <Grid
            container
            direction={direction}
            spacing={direction === 'column' ? 1 : 2}
            wrap={'nowrap'}
            data-test={'big-radio-select'}
        >
            {options.map(option => (
                <BigRadioSelectOption
                    {...option}
                    key={JSON.stringify(option.value)}
                    selected={_.isEqual(option.value, value)}
                    recommended={_.isEqual(option.value, recommendation)}
                    onClick={() => onChange(option.value)}
                    direction={direction}
                    alignVariant={alignVariant}
                    hideRadioButton={hideRadioButtons}
                    endAdornment={_.isEqual(option.value, value) ? selectedEndAdornment : undefined}
                    endAdornmentDirection={endAdornmentDirection}
                    style={optionStyle}
                    backgroundColor={optionBackgroundColor}
                />
            ))}
            {extraOption && (
                <BigRadioSelectOption
                    {...extraOption}
                    selected={_.isEqual(extraOption.value, value)}
                    recommended={false}
                    onClick={() => onChange(extraOption.value)}
                    direction={direction}
                    alignVariant={alignVariant}
                    hideRadioButton={hideRadioButtons}
                    endAdornment={_.isEqual(extraOption.value, value) ? selectedEndAdornment : undefined}
                    endAdornmentDirection={endAdornmentDirection}
                    style={optionStyle}
                    backgroundColor={optionBackgroundColor}
                />
            )}
            {moreOptions && (
                <BigRadioSelectPopover rows={moreOptions} onClick={value => onChange(value)} label={moreOptionsLabel} />
            )}
        </Grid>
    );
};
