import { BaseItem } from '../../../PrintableSlip/components/item/BaseItem';
import type { IPrintableSlipItemProps } from '../../../PrintableSlip/components/item/PrintableSlipItem';
import { isMergedItem } from '../../../PrintableSlip/types';
import { getHeaderForLabSlipItem } from '../../../PrintableSlip/utils/shared.utils';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import React from 'react';

/**
 * Custom item display component for the Unboxing Slip when rendered in 4x6 mode (for sticking onto bag packaging).
 */
export const UnboxingSlipMiniItem: React.VFC<IPrintableSlipItemProps> = ({ item }) => {
    const singleItem = isMergedItem(item) ? item.defaultItem : item;
    const material = CartItemV2Utils.getItemDisplayMaterial(singleItem);
    return (
        <BaseItem
            itemData={{
                headerLeft: getHeaderForLabSlipItem(item),
                // Only show material for non-Other items
                itemFields: singleItem.sku === LabOrderItemSKUType.Other ? [] : [{ key: 'Material', value: material }],
            }}
        />
    );
};
