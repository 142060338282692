import { compact } from 'lodash';

interface CheckForErrorParams {
    hasDownloadError: boolean;
    hasUpperJawScan: boolean;
    hasLowerJawScan: boolean;
    hasRestorativeModel: boolean;
}

export function checkForError({
    hasDownloadError,
    hasUpperJawScan,
    hasLowerJawScan,
    hasRestorativeModel,
}: CheckForErrorParams): string | null {
    if (hasDownloadError) {
        return 'There was an error downloading the design data. Please refresh the page and try again.';
    }

    if (!hasRestorativeModel) {
        return 'There are no restorative models in the design.';
    }

    if (!(hasUpperJawScan && hasLowerJawScan)) {
        const missingScanList = compact([
            hasUpperJawScan ? undefined : 'upper jaw',
            hasLowerJawScan ? undefined : 'lower jaw',
        ]).join(', ');
        return `Missing the following arch scans: ${missingScanList}`;
    }

    return null;
}
