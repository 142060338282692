import { ItemEditorV2SectionWrapper } from '../../components/ItemEditorV2SectionWrapper';
import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { ItemEditorV2ArchField, isItemEditorV2ArchItem } from './ItemEditorV2ArchField';
import { ItemEditorV2CrownMaterialField, isItemEditorV2CrownMaterialItem } from './ItemEditorV2CrownMaterialField';
import { ItemEditorV2MaterialField, isItemEditorV2MaterialItem } from './ItemEditorV2MaterialField';
import { ItemEditorV2MultiToothField, isItemEditorV2MultiToothItem } from './ItemEditorV2MultiToothField';
import { ItemEditorV2ShadeField } from './ItemEditorV2ShadeField';
import { ItemEditorV2SingleToothField, isItemEditorV2SingleToothItem } from './ItemEditorV2SingleToothField';
import { ItemEditorV2TissueShadeField } from './ItemEditorV2TissueShadeField';
import { CartItemV2Utils } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface ItemEditorV2GeneralSectionProps extends ItemEditorV2BaseProps {
    isInternal: boolean;
}

export const ItemEditorV2GeneralSection: React.VFC<ItemEditorV2GeneralSectionProps> = ({
    item,
    updateItem,
    showError,
    isInternal,
}) => {
    // if none of the individual conditions below are applicable, don't show the entire section at all
    const showGeneralSection =
        isItemEditorV2SingleToothItem(item) ||
        isItemEditorV2MultiToothItem(item) ||
        isItemEditorV2ArchItem(item) ||
        isItemEditorV2CrownMaterialItem(item) ||
        isItemEditorV2MaterialItem(item) ||
        CartItemV2Utils.itemRequiresShade(item) ||
        CartItemV2Utils.itemRequiresTissueShade(item);
    if (!showGeneralSection) {
        return null;
    }

    return (
        <ItemEditorV2SectionWrapper title={'General'}>
            <Grid container spacing={2}>
                {isItemEditorV2SingleToothItem(item) ? (
                    <Grid item xs={12} md={6}>
                        <ItemEditorV2SingleToothField item={item} updateItem={updateItem} />
                    </Grid>
                ) : undefined}
                {isItemEditorV2MultiToothItem(item) ? (
                    <Grid item xs={12} md={6}>
                        <ItemEditorV2MultiToothField
                            item={item}
                            updateItem={updateItem}
                            showError={showError}
                            isInternal={isInternal}
                        />
                    </Grid>
                ) : undefined}
                {isItemEditorV2ArchItem(item) ? (
                    <Grid item xs={12} md={6}>
                        <ItemEditorV2ArchField item={item} updateItem={updateItem} showError={showError} />
                    </Grid>
                ) : undefined}
                {isItemEditorV2CrownMaterialItem(item) ? (
                    <Grid item xs={12} md={6}>
                        <ItemEditorV2CrownMaterialField item={item} updateItem={updateItem} showError={showError} />
                    </Grid>
                ) : undefined}
                {isItemEditorV2MaterialItem(item) ? (
                    <Grid item xs={12} md={6}>
                        <ItemEditorV2MaterialField item={item} updateItem={updateItem} showError={showError} />
                    </Grid>
                ) : undefined}
                {CartItemV2Utils.itemRequiresShade(item) ? (
                    <Grid item xs={12} md={6}>
                        <ItemEditorV2ShadeField item={item} updateItem={updateItem} showError={showError} />
                    </Grid>
                ) : undefined}
                {CartItemV2Utils.itemRequiresTissueShade(item) ? (
                    <Grid item xs={12} md={6}>
                        <ItemEditorV2TissueShadeField item={item} updateItem={updateItem} showError={showError} />
                    </Grid>
                ) : undefined}
            </Grid>
        </ItemEditorV2SectionWrapper>
    );
};
