import { MODEL_VIEWER_INITIAL_APPEARANCE } from '@orthly/dentin';
import type { MainViewModelRef, MainViewCameraControlsRef, ModelAppearance } from '@orthly/dentin';
import type {
    AdminDesignQaResultsDesignDisplayEvaluation_FragmentFragment,
    FragmentType,
    OrderDesignPreviewDesign_FragmentFragmentDoc,
} from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { type LabsGqlUserDtoFragment } from '@orthly/graphql-operations';
import { LabsGqlDesignQaEvaluationStatus, LabsGqlGuidedQcResultEntryScoreVerdict } from '@orthly/graphql-schema';
import type { UnpackArray } from '@orthly/runtime-utils';
import {
    Text,
    FlossPalette,
    stylesFactory,
    Button,
    Chip,
    Grid,
    Popover,
    TablePrimitive as Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@orthly/ui-primitives';
import type { DesignQcRubricCategory } from '@orthly/veneer';
import {
    OrderDesignPreview,
    DesignQcRubricCategoryToLabelMap,
    AttachmentPreview,
    BadgeWithCounts,
    useFeatureFlag,
} from '@orthly/veneer';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    entryCellBad: {
        color: FlossPalette.ATTENTION_FOREGROUND,
    },
    entryCellClickable: {
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    popoverWrapper: {
        padding: 16,
    },
    designWrapper: {
        height: 425,
    },
    divergedRow: {
        backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
    },
    categoryColumn: {
        maxWidth: 40,
    },
    subcategoryColumn: {
        maxWidth: 150,
    },
    badgesWrapper: {
        padding: `0px 8px`,
    },
    badgePassing: {
        marginRight: 8,
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        color: FlossPalette.PRIMARY_FOREGROUND,
    },
    badgeFailing: {
        marginRight: 8,
        backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
        color: FlossPalette.ATTENTION_FOREGROUND,
    },
    badgePending: {
        marginRight: 8,
        backgroundColor: FlossPalette.LIGHT_YELLOW,
        color: FlossPalette.BLACK,
    },
}));

const AdminDesignQaResultsDesignDisplayEvaluation_Fragment = graphql(`
    fragment AdminDesignQaResultsDesignDisplayEvaluation_Fragment on DesignQaEvaluationDTO {
        status
        created_at
        order_id
        design_file_path
        design_type
        assigned_user_id
        created_at
        updated_at
        review_result_entries {
            category
            name
            verdict
            duration_ms
            affected_unns
            markup_paths
            reasons
            note
        }
    }
`);

interface DesignQaResultsEntryCellProps {
    entry?: UnpackArray<AdminDesignQaResultsDesignDisplayEvaluation_FragmentFragment['review_result_entries']>;
}

const DesignQaResultsEntryCell: React.VFC<DesignQaResultsEntryCellProps> = ({ entry }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState<boolean>(false);
    const ref = React.useRef<HTMLDivElement>(null);
    const [attachmentsOpen, setAttachmentsOpen] = React.useState(false);

    const attachments = entry?.markup_paths ?? [];
    const isBad = entry?.verdict === LabsGqlGuidedQcResultEntryScoreVerdict.Bad;
    const isClickable = isBad || !!entry?.note;

    return (
        <>
            <TableCell
                className={clsx(
                    isClickable ? classes.entryCellClickable : undefined,
                    isBad ? classes.entryCellBad : undefined,
                )}
                ref={ref}
                onClick={() => {
                    if (isClickable) {
                        setOpen(true);
                    }
                }}
            >
                {_.upperFirst(entry?.verdict ?? '')}
            </TableCell>
            <Popover
                open={open}
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Grid container direction={'column'} className={classes.popoverWrapper}>
                    {!!entry?.affected_unns?.length && (
                        <Grid item>
                            <Text variant={'body2'}>Affected UNNs: {entry.affected_unns.join(', ')}</Text>
                        </Grid>
                    )}
                    {!!entry?.reasons?.length && (
                        <Grid item>
                            <Text variant={'body2'}>Reasons: {entry.reasons.join(', ')}</Text>
                        </Grid>
                    )}
                    {entry?.note && (
                        <Grid item>
                            <Text variant={'body2'}>Note: {entry.note}</Text>
                        </Grid>
                    )}
                    {!!attachments.length && (
                        <Grid item>
                            <AttachmentPreview
                                open={attachmentsOpen}
                                setOpen={setAttachmentsOpen}
                                title={'Markups'}
                                buttonText={''}
                                CustomButton={props => (
                                    <Button variant={'text'} onClick={props.onClick}>
                                        View Markups
                                    </Button>
                                )}
                                sources={attachments.map((source, idx) => ({
                                    source,
                                    name: `Attachment ${idx + 1}`,
                                }))}
                            />
                        </Grid>
                    )}
                </Grid>
            </Popover>
        </>
    );
};

interface DesignQaResultsDesignDisplayProps {
    order: LabsGqlLabOrderFragment;
    designFragment: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    evaluationFragments: FragmentType<typeof AdminDesignQaResultsDesignDisplayEvaluation_Fragment>[];
    users: LabsGqlUserDtoFragment[];
}

export const DesignQaResultsDesignDisplay: React.VFC<DesignQaResultsDesignDisplayProps> = ({
    order,
    designFragment,
    evaluationFragments,
    users,
}) => {
    const classes = useStyles();

    const evaluations = getFragmentData(AdminDesignQaResultsDesignDisplayEvaluation_Fragment, evaluationFragments);

    const [appearance, setAppearance] = React.useState<ModelAppearance>(MODEL_VIEWER_INITIAL_APPEARANCE);
    const modelRef: MainViewModelRef = React.useRef(undefined);
    const controlRef: MainViewCameraControlsRef = React.useRef(null);

    const { value: areNamesVisible } = useFeatureFlag('guidedAuditResultsNameVisible');

    const usersById = React.useMemo(() => {
        return _.keyBy(users, user => user.id);
    }, [users]);

    const formatName = (userId: string) => {
        const user = usersById[userId];

        if (user && areNamesVisible) {
            return `${user.first_name} ${user.last_name[0]}`;
        }

        return userId?.substring(0, 8);
    };

    const submittedEvaluations = evaluations.filter(
        e =>
            e.status !== LabsGqlDesignQaEvaluationStatus.PendingReview &&
            e.status !== LabsGqlDesignQaEvaluationStatus.Cancelled,
    );

    const rows = React.useMemo(() => {
        const uniqueCategories = _(submittedEvaluations)
            .map('review_result_entries')
            .compact()
            .flatten()
            .uniqBy(el => el.category.concat(el.name))
            .value();

        return uniqueCategories.map(entry => {
            const results = submittedEvaluations.map(e =>
                e.review_result_entries?.find(
                    candidate => candidate.category === entry.category && candidate.name === entry.name,
                ),
            );
            const uniqueResults = _.uniqBy(_.compact(results), result => result?.verdict);

            return {
                results,
                category: entry.category,
                subcategory: entry.name,
                hasDivergence: uniqueResults.length !== 1,
            };
        });
    }, [submittedEvaluations]);

    const { numPasses, numFailures, numPending } = React.useMemo(() => {
        const evalsByStatus = _.groupBy(evaluations, e => e.status);
        return {
            numPasses: evalsByStatus[LabsGqlDesignQaEvaluationStatus.Passed]?.length ?? 0,
            numFailures: evalsByStatus[LabsGqlDesignQaEvaluationStatus.Failed]?.length ?? 0,
            numPending: evalsByStatus[LabsGqlDesignQaEvaluationStatus.PendingReview]?.length ?? 0,
        };
    }, [evaluations]);

    const shouldShowTable = numPasses > 0 || numFailures > 0;

    return (
        <Grid container direction={'column'}>
            <Grid item className={classes.badgesWrapper}>
                <Chip
                    size={'small'}
                    label={<BadgeWithCounts label={'Pass'} total={numPasses} />}
                    className={classes.badgePassing}
                />
                <Chip
                    size={'small'}
                    label={<BadgeWithCounts label={'Fail'} total={numFailures} />}
                    className={classes.badgeFailing}
                />
                <Chip
                    size={'small'}
                    label={<BadgeWithCounts label={'Pending'} total={numPending} />}
                    className={classes.badgePending}
                />
            </Grid>
            <Grid item container className={classes.designWrapper}>
                <OrderDesignPreview
                    fullScreen={true}
                    order={order}
                    userRole={'psr'}
                    selectedDesignFragment={designFragment}
                    enableNewViewerWithProps={{
                        appearance,
                        setAppearance,
                        modelRef,
                        controlRef,
                    }}
                />
            </Grid>
            {shouldShowTable ? (
                <Grid item>
                    <TableContainer>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={'left'}>
                                        <Text variant={'body2'} medium color={'BLACK'}>
                                            Category
                                        </Text>
                                    </TableCell>
                                    <TableCell align={'left'}>
                                        <Text variant={'body2'} medium color={'BLACK'}>
                                            Subcategory
                                        </Text>
                                    </TableCell>
                                    {submittedEvaluations.map((evaluation, idx) => (
                                        <TableCell key={`header_column_${idx}`} align={'left'}>
                                            <Text variant={'body2'} medium color={'BLACK'}>
                                                {formatName(evaluation.assigned_user_id ?? '')}
                                            </Text>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, idx) => (
                                    <TableRow
                                        key={`row_${idx}`}
                                        className={row.hasDivergence ? classes.divergedRow : undefined}
                                    >
                                        <TableCell className={classes.categoryColumn}>
                                            <Text variant={'body2'} medium color={'BLACK'}>
                                                {
                                                    DesignQcRubricCategoryToLabelMap[
                                                        row.category as DesignQcRubricCategory
                                                    ]
                                                }
                                            </Text>
                                        </TableCell>
                                        <TableCell className={classes.subcategoryColumn}>
                                            <Text variant={'body2'} medium color={'BLACK'}>
                                                {row.subcategory}
                                            </Text>
                                        </TableCell>
                                        {row.results.map((result, resultIdx) => (
                                            <DesignQaResultsEntryCell key={`result_${resultIdx}`} entry={result} />
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            ) : (
                <Grid item>
                    <Text variant={'body2'} medium>
                        There have been no results submitted yet...
                    </Text>
                </Grid>
            )}
        </Grid>
    );
};
