import { Text } from '@orthly/ui-primitives';
import React from 'react';

export const NativeDesignViewer: React.VFC = () => {
    return (
        <div>
            <Text variant={'h5'}>DESIGN VIEWER GOES HERE</Text>
        </div>
    );
};
