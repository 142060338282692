import { PrintableSlipPatientInfo } from '../../../../../PrintableSlip/components/header/PrintableSlipPatientInfo';
import { PrintableSlipPracticeInfo } from '../../../../../PrintableSlip/components/header/PrintableSlipPracticeInfo';
import { PrintableSlipSectionTitle } from '../../../../../PrintableSlip/components/shared/PrintableSlipSectionTitle';
import { useFeatureFlag } from '../../../../../Providers/LaunchDarkly';
import { getOrderQRCodeSrcUrl } from '../../../../util/useLabSlipQrCodes';
import { LabSlipOrderInfo } from './LabSlipOrderInfo';
import { LabSlipOrderStickers } from './LabSlipOrderStickers';
import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import { LabsGqlLabSalesOrderServiceSpeed } from '@orthly/graphql-schema';
import { Grid, makeStyles, Text } from '@orthly/ui-primitives';
import format from 'date-fns/format';
import React from 'react';
import QRCode from 'react-qr-code';

const useStyles = makeStyles({
    slipHeaderSection: {
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    practicePatientSection: {
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        fontSize: '0.9rem !important',
        fontWeight: 'bold',
    },
    divider: {
        marginTop: '3px',
        marginBottom: '3px',
        width: '100%',
        borderBottom: '1px solid green',
    },
    inDateText: {
        fontSize: '12px !important',
    },
    shipByText: {
        fontSize: '26px !important', // Larger text for dates
        fontWeight: 'bold',
    },
});

interface ILabSlipHeaderProps {
    order: Exclude<LabsGqlLabOrderForLabSlipFragment, 'stackAndPanCodes'>;
    panCode?: string;
    batchIds?: string[] | null;
}

/**
 * Renders the topmost section of the Order Lab Slip.
 * Contains the basic order information & QR code as well as the practice and patient names
 * @param props
 * @returns Topmost section of the Order Lab Slip
 */
export const LabSlipHeader: React.VFC<ILabSlipHeaderProps> = props => {
    const classes = useStyles();
    const { order, panCode, batchIds } = props;
    const qrCodeSrcUrl = React.useMemo(() => getOrderQRCodeSrcUrl(order.id), [order.id]);
    const { value: hidePracticeDetails = false } = useFeatureFlag('hidePracticeDetailsWherePossible');

    const { ready_for_fabrication_date } = order.manufacturer_sla;
    // Get the start date in EST
    const startDateLocaleString = ready_for_fabrication_date
        ? new Date(ready_for_fabrication_date).toLocaleString('en-US', { timeZone: 'America/New_York' })
        : null;
    const startDate = startDateLocaleString ? format(new Date(startDateLocaleString), 'MM/dd') : 'N/A';
    // Get the ship date in EST
    const shipByDateLocaleString = new Date(order.manufacturer_sla.manufacturer_committed_ship_by_date).toLocaleString(
        'en-US',
        {
            timeZone: 'America/New_York',
        },
    );
    const shipByDate = format(new Date(shipByDateLocaleString), 'MM/dd');

    return (
        <Grid container>
            <LabSlipOrderStickers order={order} />
            {/* Row 1 - LabSlipOrderInfo only */}
            <Grid container spacing={1} className={classes.slipHeaderSection}>
                <Grid item xs={12}>
                    <LabSlipOrderInfo order={order} panCode={panCode} batchIds={batchIds} />
                </Grid>
                <div className={classes.divider} />
            </Grid>
            {/* Row 2 - Labtrac QR, Ship By, and Portal QR */}
            <Grid container spacing={1} className={classes.slipHeaderSection} alignItems={'center'}>
                {/* Labtrac QR Code Section */}
                <Grid item xs={3} container justifyContent={'center'}>
                    {order.labtrac_id && (
                        <Grid container direction={'column'} alignItems={'center'}>
                            <QRCode value={`CN${order.labtrac_id}`} style={{ height: '2.5rem', width: '2.5rem' }} />
                            <PrintableSlipSectionTitle text={`CN${order.labtrac_id}`} />
                        </Grid>
                    )}
                </Grid>
                {/* Ship By */}
                <Grid item xs container direction={'column'} alignItems={'center'}>
                    <Text variant={'body2'} className={classes.inDateText}>
                        {`In: ${startDate}`}
                    </Text>
                    <Text variant={'body1'} medium className={classes.shipByText}>
                        {order.service_level_speed === LabsGqlLabSalesOrderServiceSpeed.Rush
                            ? `${shipByDate} - Rush`
                            : shipByDate}
                    </Text>
                </Grid>
                {/* Portal QR Code Section */}
                {order.delivery_address_alias && (
                    <Grid item xs={3} container justifyContent={'center'}>
                        <Grid container direction={'column'} alignItems={'center'}>
                            <QRCode value={qrCodeSrcUrl} style={{ height: '2.5rem', width: '2.5rem' }} />
                            <PrintableSlipSectionTitle text={'Portal'} />
                        </Grid>
                    </Grid>
                )}
                <div className={classes.divider} />
            </Grid>
            {/* Row 3 */}
            <Grid container>
                <Grid container spacing={3} className={classes.slipHeaderSection}>
                    {!hidePracticeDetails && <PrintableSlipPracticeInfo order={order} />}
                    <PrintableSlipPatientInfo patient={order.patient} />
                </Grid>
            </Grid>
        </Grid>
    );
};
