import { DesignPrepBannerCommon } from '../DesignPrep/DesignPrepBannerCommon';
import { useFeatureFlag } from '../Providers/LaunchDarkly';
import { isEligibleForDandyFinishing } from '@orthly/forceps';
import type { LabsGqlDesignRevisionSource } from '@orthly/graphql-schema';
import { Tooltip } from '@orthly/ui-primitives';
import React from 'react';
import { Link } from 'react-router-dom';

interface StartBannerProps {
    orderId: string;
    variant: 'review' | 'finishing';
    designSource?: LabsGqlDesignRevisionSource;
    className?: string;
}

export const DesignFinishingStartBanner: React.VFC<StartBannerProps> = ({
    orderId,
    variant,
    designSource,
    className,
}) => {
    // If the user has access to the finishing tab, show this banner that takes them to that tab.
    const { value: enableOrderDetailFinishingTab } = useFeatureFlag('enableOrderDetailFinishingTab');
    if (!enableOrderDetailFinishingTab) {
        return null;
    }

    // We don't need to check the design source when the variant is 'review' because an AutomateReview task will only
    // be active if the latest design was produced by 3Shape Automate.
    const disabled = variant === 'finishing' && !isEligibleForDandyFinishing(designSource);
    const label = variant === 'review' ? 'Dandy Verified Design Review' : 'Dandy Finishing';

    const bannerElement = (
        <DesignPrepBannerCommon
            className={className}
            leftLabel={label}
            rightLabel={'Start'}
            variant={'primary'}
            disabled={disabled}
        />
    );

    if (disabled) {
        const tooltip = 'This order is not eligible for Dandy Finishing.';
        return (
            <Tooltip title={tooltip}>
                <span>{bannerElement}</span>
            </Tooltip>
        );
    }

    return (
        <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/tasks/${orderId}/?tab=finishing&open=true`}>
            {bannerElement}
        </Link>
    );
};
