import { useDeformState, useSculptingState } from '../../AppState.hooks';
import { DeformMenu } from '../DeformMenu';
import { SculptToLimitMenu } from '../SculptToLimitMenu';
import { SculptingMenu } from '../SculptingMenu';
import { Button, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface ToolSelectButtonProps {
    label: string;
    active: boolean;
    onClick: () => void;
}

const ToolSelectButton: React.VFC<ToolSelectButtonProps> = ({ label, active, onClick: _onClick }) => {
    const onClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        _onClick();

        // If the target retains focus, our hotkeys will be inoperable until the user clicks away because the input
        // element will be capturing all key events. We need to do this in the `onClick` handler rather than the
        // `onChange` handler because the element is given focus on a click even if the value does not change (i.e.
        // clicking the currently selected radio button).
        (evt.target as any)?.blur?.();
    };

    return (
        <Button variant={active ? 'primary' : 'secondary'} onClick={onClick} fullWidth>
            {label}
        </Button>
    );
};

export const OcclusionStage: React.VFC = () => {
    const { sculptingEnabled, toggleSculptingEnabled } = useSculptingState();
    const { deformEnabled, toggleDeformEnabled } = useDeformState();

    return (
        <Grid item container spacing={1} style={{ padding: '0px 12px' }}>
            <Grid item xs={6}>
                <ToolSelectButton label={'Morph'} active={deformEnabled} onClick={toggleDeformEnabled} />
            </Grid>
            <Grid item xs={6}>
                <ToolSelectButton label={'Sculpt'} active={sculptingEnabled} onClick={toggleSculptingEnabled} />
            </Grid>
            <Grid item>
                <SculptingMenu showSculptToOpposing />
                <SculptToLimitMenu />
            </Grid>
            <Grid item>
                <DeformMenu />
            </Grid>
        </Grid>
    );
};
