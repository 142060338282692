import type { ConstEnum } from '@orthly/runtime-utils';
import type { OrderOpsListItemContentProps } from '@orthly/veneer';

export interface OrderListItemProps {
    listItemContent?: OrderOpsListItemContentProps;
}

const listItemContentKeysEnum: ConstEnum<keyof OrderOpsListItemContentProps> = {
    trackerProps: 'trackerProps',
    productsText: 'productsText',
    manufacturer_name: 'manufacturer_name',
    partner_name: 'partner_name',
    order: 'order',
    flags: 'flags',
    review_flag_reasons: 'review_flag_reasons',
    orderDesignFragments: 'orderDesignFragments',
};
const listItemContentKeys: (keyof OrderOpsListItemContentProps)[] = Object.values(listItemContentKeysEnum);

function listItemContentIsEqual(prev: OrderOpsListItemContentProps, next: OrderOpsListItemContentProps): boolean {
    const propMatches = (key: keyof OrderOpsListItemContentProps): boolean => {
        switch (key) {
            case 'order':
                return prev.order.id === next.order.id && prev.order.updated_at === next.order.updated_at;
            case 'manufacturer_name':
            case 'partner_name':
            case 'productsText':
                return prev[key] === next[key];
            // these are calculated based off the order only, so they shouldn't change unless updated_at has changed
            case 'flags':
            case 'review_flag_reasons':
            case 'trackerProps':
            case 'orderDesignFragments':
                return true;
        }
    };
    // if we cant find a key that differs, the props are the same
    return !listItemContentKeys.find(key => !propMatches(key));
}

export function orderListItemPropsEqual(prev: OrderListItemProps, next: OrderListItemProps): boolean {
    if (!prev.listItemContent || !next.listItemContent) {
        // if one is undefined then props are equal if both are undefined
        return !!prev.listItemContent === !!next.listItemContent;
    }
    return listItemContentIsEqual(prev.listItemContent, next.listItemContent);
}
