import type { PricingOverviewTableRow } from './PricingRoot.types';
import { useUpdatedPricingQuery } from './hooks/useUpdatedPricingQuery.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LoadBlocker, SimpleInput, SimpleSelect, useRootActionCommand } from '@orthly/ui';
import { Button, Grid, Icon, IconButton, Input, Text } from '@orthly/ui-primitives';
import { useCopyToClipboard, useFeatureFlag } from '@orthly/veneer';
import React from 'react';

interface EditPriceNameProps {
    price: PricingOverviewTableRow;
}

const ChangePriceName_Mutation = graphql(`
    mutation ChangePriceName($data: ChangePriceNameCommand!) {
        changePriceName(data: $data) {
            id
        }
    }
`);

export const EditPriceName: React.FC<EditPriceNameProps> = ({ price }) => {
    const { value: disableContractEdits = false } = useFeatureFlag('disableContractEdits');
    const { refetchSinglePrice } = useUpdatedPricingQuery();
    const [name, setName] = React.useState<string>(price.name);
    const changePriceName = useMutation(ChangePriceName_Mutation);
    const { submit, submitting } = useRootActionCommand(changePriceName, {
        successMessage: 'Price name changed',
        onSuccess: () => refetchSinglePrice({ variables: { priceId: price.id } }),
    });

    return (
        <LoadBlocker blocking={submitting}>
            <Grid container>
                <SimpleInput
                    fullWidth
                    onChange={value => setName(value ?? '')}
                    value={name}
                    label={'Name'}
                    disabled={disableContractEdits}
                />
            </Grid>
            <Grid container style={{ padding: '10px 0' }}>
                <Button
                    variant={'contained'}
                    startIcon={'Save'}
                    disabled={name === price.name}
                    onClick={() => submit({ data: { price_id: price.id, name } })}
                >
                    Save
                </Button>
            </Grid>
        </LoadBlocker>
    );
};

export const ListSalesforcePriceMappingOpts_Query = graphql(`
    query ListSalesforcePriceMappingOpts {
        listSalesforcePriceNameMappings
    }
`);

const SetPriceSalesforceMapping_Mutation = graphql(`
    mutation SetPriceSalesforceMapping($data: SetPriceSalesforceMappingCommand!) {
        setPriceSalesforceMapping(data: $data) {
            id
        }
    }
`);

export const EditPriceSalesforceMapping: React.FC<EditPriceNameProps> = ({ price }) => {
    const { value: disableContractEdits = false } = useFeatureFlag('disableContractEdits');

    const { refetchSinglePrice } = useUpdatedPricingQuery();
    const copyToClipboard = useCopyToClipboard();
    const onClickCopy = React.useCallback(() => {
        copyToClipboard({
            text: `[LabsContractPreset__r.${price.salesforce_price_mapping}]`,
            successMessage: 'Copied!',
        });
    }, [copyToClipboard, price.salesforce_price_mapping]);
    const { data: { listSalesforcePriceNameMappings: sfPriceNames = [] } = {}, loading } = useQuery(
        ListSalesforcePriceMappingOpts_Query,
    );
    const [selectedMapping, setMapping] = React.useState<string | null | undefined>(price.salesforce_price_mapping);

    const setPriceSalesforceMapping = useMutation(SetPriceSalesforceMapping_Mutation);
    const { submit, submitting } = useRootActionCommand(setPriceSalesforceMapping, {
        successMessage: 'Price salesforce mapping changed',
        onSuccess: () => refetchSinglePrice({ variables: { priceId: price.id } }),
    });

    const selectOpts = React.useMemo(() => {
        const options = sfPriceNames.map(value => ({ value, label: value.replace('__c', '') }));
        if (price.salesforce_price_mapping) {
            options.push({
                value: price.salesforce_price_mapping,
                label: price.salesforce_price_mapping.replace('__c', ''),
            });
        }
        return options;
    }, [price.salesforce_price_mapping, sfPriceNames]);
    return (
        <LoadBlocker blocking={submitting || loading}>
            <Grid container>
                <SimpleSelect
                    placeholder={'Clear selection'}
                    options={selectOpts}
                    onChange={value => setMapping(value || null)}
                    label={'Salesforce Contract Preset Property'}
                    value={selectedMapping ?? ''}
                    disabled={disableContractEdits}
                />
            </Grid>
            <Grid container justifyContent={'flex-end'} style={{ padding: '10px 0' }}>
                <Button
                    variant={'contained'}
                    startIcon={'Save'}
                    disabled={selectedMapping === price.salesforce_price_mapping || disableContractEdits}
                    onClick={() =>
                        submit({ data: { price_id: price.id, salesforce_price_mapping: selectedMapping ?? null } })
                    }
                >
                    Save
                </Button>
            </Grid>
            <Grid container xs={12} style={{ padding: 20 }}>
                <Text variant={'caption'}>{'PandaDoc Token'.toLocaleUpperCase()}</Text>
                <IconButton onClick={onClickCopy}>
                    <Icon icon={'FileCopy'} />
                </IconButton>
                <Input
                    fullWidth
                    readOnly
                    id={`pandadoc_token_input_${price.id}`}
                    value={`[LabsContractPreset__r.${price.salesforce_price_mapping}]`}
                    style={{ padding: 10 }}
                    disabled={disableContractEdits}
                />
            </Grid>
        </LoadBlocker>
    );
};
