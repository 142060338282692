import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { CartItemV2Utils, LabOrderItemSKUType, OrderItemArch } from '@orthly/items';
import type { ICartItemV2DTO } from '@orthly/items';
import { belongsToEnum } from '@orthly/runtime-utils';
import { SimpleSelect } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

export const isItemEditorV2ArchItem = CartItemV2Utils.itemIsType([
    LabOrderItemSKUType.Aligners,
    LabOrderItemSKUType.Denture,
    LabOrderItemSKUType.Removeable,
    LabOrderItemSKUType.TMJ,
]);

const getPossibleArchesForItem = (item: ICartItemV2DTO): OrderItemArch[] => {
    const allArches = Object.values(OrderItemArch);
    // dual arch is not offered for night guards
    if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Removeable) && item.unit.unit_type === 'Night Guard') {
        return allArches.filter(a => a !== OrderItemArch.Dual);
    }
    return allArches;
};

export const ItemEditorV2ArchField: React.VFC<
    ItemEditorV2BaseProps<
        | LabOrderItemSKUType.Aligners
        | LabOrderItemSKUType.Denture
        | LabOrderItemSKUType.Removeable
        | LabOrderItemSKUType.TMJ
    >
> = ({ item, updateItem, showError }) => {
    return (
        <SimpleSelect
            label={'Arch'}
            value={item.unit.arch ?? undefined}
            onChange={val => {
                if (val && belongsToEnum(val, OrderItemArch)) {
                    updateItem({ name: 'arch', payload: val });

                    // bleaching tray and sportsguard still sets arch as material for v1 compatibility
                    if (
                        CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Removeable) &&
                        (item.unit.unit_type === 'Bleaching Tray' || item.unit.unit_type === 'Sportsguard')
                    ) {
                        updateItem({ name: 'material', payload: val === OrderItemArch.Dual ? 'Both' : val });
                    }
                }
            }}
            options={getPossibleArchesForItem(item).map(arch => ({
                value: arch,
                label: _.startCase(arch),
            }))}
            errorText={showError && !item.unit.arch ? 'This field is required' : undefined}
        />
    );
};
