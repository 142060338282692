import { FakePracticeBanner } from '../../../components/FakePracticeBanner.graphql';
import { PausedPracticeBanner } from '../../../components/PausedPractices/PausedPracticeBanner.graphql';
import { BillingDetailPanel } from './BillingDetailPanel.graphql';
import { BillingDetailsCtxProvider } from './providers/BillingDetailsProvider.graphql';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { styled } from '@orthly/ui-primitives';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';

const TABS_HEIGHT = 64;
const BANNER_HEIGHT = 40;

const Container = styled('div')({
    padding: '16px',
    width: '100%',
    height: `calc(100vh - ${TABS_HEIGHT + BANNER_HEIGHT}px)`,
});

const GetActiveContractData_Query = graphql(`
    query GetActiveContractData($organizationId: String!, $contractId: String) {
        contractAndAssociates: getContractAndAssociatedOrgsByOrganizationId(
            organization_id: $organizationId
            contract_id: $contractId
        ) {
            contract {
                id
            }
            activeContractPractices {
                id
                name
                salesforceId
            }
            primaryPracticeId
        }
        getOrganization(id: $organizationId) {
            ...AdminFakePracticeBannerOrganization_Fragment
        }
    }
`);

export const BillingDetailsRoot: React.FC<RouteComponentProps<{ practiceId: string }>> = props => {
    const practiceId = props.match.params.practiceId;
    const [selectedContractId, setSelectedContractId] = React.useState<string | null>(null);

    const { data: { contractAndAssociates, getOrganization } = {}, loading: contractAndAssociatesLoading } = useQuery(
        GetActiveContractData_Query,
        {
            variables: { organizationId: practiceId, contractId: selectedContractId },
        },
    );

    return (
        <>
            <FakePracticeBanner organizationFragment={getOrganization} />
            <PausedPracticeBanner practiceId={practiceId} />
            <BillingDetailsCtxProvider
                practiceId={practiceId}
                contractAndAssociates={contractAndAssociates}
                contractAndAssociatesLoading={contractAndAssociatesLoading}
                selectedContractId={selectedContractId}
                setSelectedContractId={setSelectedContractId}
            >
                <Container>
                    <BillingDetailPanel practiceId={practiceId} />
                </Container>
            </BillingDetailsCtxProvider>
        </>
    );
};
