import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import { LabsGqlLabPriceRuleType } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';

export const PartialDenturePracticeWaxRimForm: React.FC<LabPriceFormProps> = ({ price, submit, disabled }) => {
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.PracticePartialDentureWaxRim>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.PracticePartialDentureWaxRim>({
                default_practice_price: {
                    type: 'number',
                    label: 'Default Price',
                    optional: true,
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          default_practice_price:
                              typeof price.default_practice_price_cents === 'number'
                                  ? price.default_practice_price_cents / 100
                                  : null,
                      }
                    : {}
            }
            onSubmit={async result => {
                const { name, default_practice_price } = result;
                const default_practice_price_cents =
                    typeof default_practice_price === 'number' ? default_practice_price * 100 : null;

                await submit({
                    name,
                    default_practice_price_cents,
                    rule: { type: LabsGqlLabPriceRuleType.PracticePartialDentureWaxRim },
                });
            }}
            disabled={disabled}
        />
    );
};
