import type { AdminLabsOrderActionProps } from './order-action-types';
import { useEditRefabricationMutation, useEditRefabricationReasonsMutation } from '@orthly/graphql-react';
import type { LabsGqlOrderRefabReasonDto, LabsGqlOrderRefabReasonInput } from '@orthly/graphql-schema';
import { QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { Text, Grid } from '@orthly/ui-primitives';
import { RefabReasonSelector, useRefabReasonOptions } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';
import { z } from 'zod';

interface EditRefabricationActionProps extends Omit<AdminLabsOrderActionProps, 'order'> {
    originalOrderId: string;
    currentOrderId: string;
    existingReasons: LabsGqlOrderRefabReasonDto[] | null;
    existingNotes: string | null;
    controls?: { open: boolean; setOpen: (open: boolean) => void };
    hideButton?: boolean;
}

interface EditRefabricationForm {
    reasons: LabsGqlOrderRefabReasonInput[];
    refabricationNotes: string;
}

type SubmitRes = { changed: boolean };

function reasonIsDeprecated(reason: LabsGqlOrderRefabReasonDto): boolean {
    return !reason.refab_reason_id;
}

export const EditRefabricationAction: React.FC<EditRefabricationActionProps> = ({
    originalOrderId,
    currentOrderId,
    existingReasons,
    existingNotes,
    controls,
    refetchOrder,
    hideButton,
}) => {
    const [submitMtnNotes] = useEditRefabricationMutation();
    const [submitMtnReasons] = useEditRefabricationReasonsMutation();

    const mtnSubmitter = async ({ reasons, refabricationNotes }: EditRefabricationForm): Promise<SubmitRes> => {
        let changed = false;
        if (refabricationNotes.trim() !== (existingNotes ?? ``).trim()) {
            await submitMtnNotes({ variables: { refabricationNotes, orderId: currentOrderId } });
            changed = true;
        }
        if (
            !_.isEqual(
                _.sortBy(existingReasons ?? [], r => r.name),
                _.sortBy(reasons, r => r.name),
            )
        ) {
            await submitMtnReasons({ variables: { reasons, originalOrderId } });
            changed = true;
        }
        return { changed };
    };

    const { submit, submitting, ...changeFn } = useChangeSubmissionFn<SubmitRes, [EditRefabricationForm]>(
        mtnSubmitter,
        {
            closeOnComplete: true,
            successMessage: () => [`Refabrication updated`, {}],
            onSuccess: async res => {
                res.changed && (await refetchOrder());
                controls?.setOpen(false);
            },
        },
    );

    const { reasons, loading: reasonsLoading } = useRefabReasonOptions(originalOrderId, existingReasons ?? []);
    const { open, setOpen } = controls ?? changeFn;

    const deprecatedReasons = existingReasons?.filter(reasonIsDeprecated).map(reason => reason.name);

    return (
        <RootActionDialog
            loading={submitting || reasonsLoading}
            open={open}
            setOpen={setOpen}
            title={`Edit Refabricated Order`}
            content={
                <Grid container>
                    {deprecatedReasons && deprecatedReasons.length > 0 && (
                        <Text variant={'body1'} color={'ATTENTION'}>
                            The following reasons are not mapped to configured refab reasons and will be REMOVED on
                            save. Please replace them with a similar one from the current options
                            {deprecatedReasons.map(r => (
                                <Text key={r} variant={'h6'} color={'DARK_GRAY'}>
                                    - {r}
                                </Text>
                            ))}
                        </Text>
                    )}
                    <QuickForm<EditRefabricationForm>
                        dirtySubmitOnly
                        fields={{
                            reasons: {
                                type: 'custom',
                                optional: true,
                                validation: z.array(
                                    z.object({
                                        name: z.string(),
                                        refab_reason_id: z.string(),
                                        fault_override: z.string().nullable().optional(),
                                    }),
                                ),
                                component: ({ form, field }) => (
                                    <RefabReasonSelector
                                        loading={reasonsLoading}
                                        possibleReasons={reasons}
                                        selected={field.value ?? []}
                                        setSelected={selected => form.setFieldValue(field.name, selected)}
                                        allowFaultOverride={true}
                                    />
                                ),
                            },
                            refabricationNotes: {
                                type: 'text',
                                label: 'Notes',
                                fieldProps: { multiline: true, maxRows: 1000 },
                            },
                        }}
                        initialValues={{
                            reasons:
                                existingReasons
                                    ?.filter(reason => !reasonIsDeprecated(reason))
                                    .map(({ name, refab_reason_id, fault_override }) => ({
                                        name,
                                        refab_reason_id,
                                        fault_override,
                                    })) ?? [],
                            refabricationNotes: existingNotes ?? ``,
                        }}
                        onSubmit={async formResult => {
                            await submit(formResult);
                        }}
                    />
                </Grid>
            }
            buttonText={`Edit refab notes/reasons`}
            buttonProps={{ style: controls ? { display: 'none' } : {} }}
            CustomButton={hideButton ? () => null : undefined}
        />
    );
};
