import { AnalyticsClient } from '../../../../../../../utils/analyticsClient';
import type { ToolbarActionProps, ToolbarActionDefinition } from './ToolbarActionDefinition';
import { useRecomputeOrderMutation } from '@orthly/graphql-react';
import { useHasCapability } from '@orthly/session-client';
import { useChangeSubmissionFn, RootActionDialog } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const RecomputeOrderAction: React.FC<ToolbarActionProps> = props => {
    const { order, refetchOrder, open, setOpen, CustomButton } = props;
    const canRecomputeOrder = useHasCapability('order', 'order.recompute');
    const [submitMtn] = useRecomputeOrderMutation();
    const { submit, submitting, called } = useChangeSubmissionFn(
        () => submitMtn({ variables: { order_id: order.id } }),
        {
            closeOnComplete: true,
            successMessage: () => ['Order synced', {}],
            onSuccess: async () => {
                await refetchOrder();
                setOpen(false);
            },
        },
    );
    React.useEffect(() => {
        if (open && !submitting && !called) {
            AnalyticsClient.track('Ops - Order State Resync Requested', { $groups: { order: order.id } });
            void submit();
        }
    }, [open, submitting, submit, called, order.id]);
    if (!canRecomputeOrder) {
        return null;
    }
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Re-Sync Order'}
            content={<Grid container style={{ minHeight: 400 }} />}
            buttonText={''}
            CustomButton={CustomButton}
        />
    );
};

export const RecomputeOrderToolbarAction: ToolbarActionDefinition = {
    Component: RecomputeOrderAction,
    label: 'Re-Sync order',
};
