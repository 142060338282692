import { NEXT_INVOICE_VALUE } from './CreditOrRefund.types';
import { useCreditOrRefundFormContext } from './providers/CreditOrRefundFormProvider.graphql';
import { SimpleSelect } from '@orthly/ui';
import { styled, Text, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

const RequiredLabel = styled(Text)({
    '&::after': {
        color: FlossPalette.ATTENTION,
        content: '"*"',
    },
});

const Stacked = styled('div')<{ restrictWidth: boolean }>(({ restrictWidth }) => ({
    display: 'flex',
    flex: 1,
    maxWidth: restrictWidth ? '50%' : '100%',
    flexDirection: 'column',
}));

export const CreditOrRefundCategorySelect: React.FC = () => {
    const { formState, dispatchFormStateAction, creditCategoryOptions, refundCategoryOptions, viewOnly } =
        useCreditOrRefundFormContext();

    const creditAmountInputShown = formState.action === 'credit' && !formState.attributed;
    const creditExpirationInputShown = formState.action === 'credit' && formState.applyOn === NEXT_INVOICE_VALUE;

    return (
        <Stacked restrictWidth={creditAmountInputShown || creditExpirationInputShown}>
            <RequiredLabel variant={'body2'} medium>
                Select Reason for {formState.action === 'credit' ? 'Credit' : 'Refund'}
            </RequiredLabel>
            <SimpleSelect
                flossInputConfig={{ backgroundColor: 'gray', bordered: true }}
                disabled={viewOnly}
                label={''}
                options={formState.action === 'credit' ? creditCategoryOptions : refundCategoryOptions}
                value={formState.category}
                onChange={value => {
                    dispatchFormStateAction({ type: 'SET_CATEGORY', category: value ?? '' });
                }}
                SelectProps={{
                    variant: 'standard',
                    style: { padding: '0px' },
                    MenuProps: {
                        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                        style: { height: '350px' },
                    },
                }}
            />
        </Stacked>
    );
};
