import { useTableData } from '../../contexts/TableDataContext';
import {
    useDisplayOptionsContext,
    useRowOptionsContext,
    useTranslationOptionsContext,
} from '../../contexts/TableOptionsContext';
import { useMuiTableSelector } from '../../state';
import type { MUITableMetaRow } from '../../types';
import { useDetailPanelOpen } from '../../utils/useDetailPanelOpen';
import { BodyCellBase, MUITableBodyCell } from './MUITableBodyCell';
import { MUITableBodyRow } from './MUITableBodyRow';
import { MUITableDetailCell } from './MUITableDetailCell';
import { MUITableSelectCell } from './MUITableSelectCell';
import { createStyles, makeStyles, TableBody as MuiTableBody, Typography } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(
    () =>
        createStyles({
            root: {},
            emptyTitle: {
                textAlign: 'center' as 'center',
            },
        }),
    { name: 'MUITableBody' },
);

interface MUITableRenderRowProps {
    rowIndex: number;
    metaRow: MUITableMetaRow<any>;
    className?: string;
}

export const MUITableRenderRow: React.FC<MUITableRenderRowProps> = props => {
    const { rowIndex, metaRow } = props;
    const { DetailPanel } = useDisplayOptionsContext();
    const rowOptions = useRowOptionsContext();
    const { setRowProps } = rowOptions;
    const { columns } = useTableData();
    const selectedRows = useMuiTableSelector(s => s.selectedRows);
    const hiddenColumnNames = useMuiTableSelector(s => s.hiddenColumnNames);
    const detailPanelOpen = useDetailPanelOpen(metaRow.row.id);
    const totalColSpan = columns.length + (rowOptions.selectable ? 1 : 0) + (DetailPanel ? 1 : 0);
    return (
        <React.Fragment>
            <MUITableBodyRow
                className={props.className}
                index={rowIndex}
                key={rowIndex}
                row={metaRow.row}
                {...(setRowProps ? setRowProps(metaRow.row, rowIndex) : {})}
            >
                {!!DetailPanel && <MUITableDetailCell row={metaRow.row} />}
                <MUITableSelectCell
                    row={metaRow.row}
                    checked={selectedRows.includes(metaRow.row.id)}
                    isHeaderCell={false}
                />
                {columns.map(column => {
                    if (hiddenColumnNames.includes(column.name)) {
                        return null;
                    }
                    return (
                        <MUITableBodyCell
                            key={`${column.name}-${metaRow.row.id}`}
                            row={metaRow}
                            colSpan={1}
                            column={column}
                            rowIndex={rowIndex}
                        />
                    );
                })}
            </MUITableBodyRow>
            {DetailPanel && detailPanelOpen && (
                <MUITableBodyRow index={rowIndex} key={`${metaRow.row.id}-detail`} disableHover={true}>
                    <BodyCellBase colSpan={totalColSpan} style={{ padding: 0 }}>
                        <DetailPanel data={metaRow.row} />
                    </BodyCellBase>
                </MUITableBodyRow>
            )}
        </React.Fragment>
    );
};

export const MUITableBody = () => {
    const classes = useStyles();
    const { DetailPanel } = useDisplayOptionsContext();
    const rowOptions = useRowOptionsContext();
    const { columns, displayRows, loading, totalRowCount, allRows } = useTableData();
    const translations = useTranslationOptionsContext();
    const totalColSpan = columns.length + (rowOptions.selectable ? 1 : 0) + (DetailPanel ? 1 : 0);
    if (totalRowCount === 0) {
        return (
            <MuiTableBody className={classes.root} key={'no-rows-table'}>
                <MUITableBodyRow index={0}>
                    <BodyCellBase colSpan={totalColSpan}>
                        <Typography variant={'subtitle1'} className={classes.emptyTitle}>
                            {!loading && translations.body.noMatch}
                        </Typography>
                    </BodyCellBase>
                </MUITableBodyRow>
            </MuiTableBody>
        );
    }
    const BodyReplacement = rowOptions.BodyComponent;
    if (BodyReplacement) {
        return <BodyReplacement allRows={allRows} visibleRows={displayRows} visibleColumns={totalColSpan} />;
    }
    return (
        <MuiTableBody className={classes.root}>
            {displayRows.map((metaRow, rowIndex) => (
                <MUITableRenderRow key={`${metaRow.row.id}-${rowIndex}`} rowIndex={rowIndex} metaRow={metaRow} />
            ))}
        </MuiTableBody>
    );
};
