import { OrderDownloadFilesUtils } from '../../util/OrderDownloadFiles.utils';
import { BulkDownloadDialog } from '../BulkDownloadDialog/BulkDownloadDialog';
import { useFeatureFlag } from '../Providers/LaunchDarkly';
import type { MinimalOrder } from './DesignPrep.types';
import { DesignPrepBannerCommon } from './DesignPrepBannerCommon';
import { DesignPrepStartPrepInBrowserBanner } from './DesignPrepStartPrepInBrowserBanner';
import { useGetDesignStaffQuery } from '@orthly/graphql-react';
import type { IOrderItemV2DTO } from '@orthly/items';
import { CartItemV2Utils, LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';
import { useRetainerToken, useSession } from '@orthly/session-client';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface DesignPrepStartPrepInThreeshapeBannerProps {
    order: Pick<MinimalOrder, 'scan_export' | 'patient' | 'order_number' | 'id'>;
}

const DesignPrepStartPrepInThreeshapeBanner: React.VFC<DesignPrepStartPrepInThreeshapeBannerProps> = ({ order }) => {
    const { retainerToken } = useRetainerToken();
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);

    const fileName = `Order${order.order_number}.3oxz`;

    if (!order.scan_export) {
        return null;
    }

    return (
        <Grid container direction={'row'} wrap={'nowrap'}>
            <DesignPrepBannerCommon
                leftLabel={'Download files to prep in 3Shape'}
                rightLabel={'Download'}
                variant={'secondary'}
                onClick={() => setDialogOpen(true)}
            />
            <BulkDownloadDialog
                open={dialogOpen}
                setOpen={setDialogOpen}
                files={
                    retainerToken
                        ? [
                              {
                                  source: 'external',
                                  path: OrderDownloadFilesUtils.getSanitizedScanUrl({
                                      retainerToken,
                                      orderId: order.id,
                                      scanExportId: order.scan_export.id,
                                  }),
                                  name: fileName,
                              },
                          ]
                        : []
                }
            />
        </Grid>
    );
};

function isSingleCrown(items: IOrderItemV2DTO[]) {
    return (
        items.some(item => OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Crown)) &&
        CartItemV2Utils.getItemGroupUniqueUNNs(items).length === 1
    );
}

interface DesignPrepStartPrepBannerProps {
    order: MinimalOrder;
    refetch: () => Promise<void>;
}

export const DesignPrepStartPrepBanner: React.VFC<DesignPrepStartPrepBannerProps> = ({ order, refetch }) => {
    const session = useSession();
    const userIdForQuery = session?.user_id ?? '';
    const { data } = useGetDesignStaffQuery({
        variables: {
            user_id: userIdForQuery,
        },
        skip: !userIdForQuery,
    });

    const isPrepInBrowserEnabledForDesignStaff = !!(
        data?.getDesignStaff?.task_capabilities.DesignPrep['posterior_crown_and_bridge'] ||
        data?.getDesignStaff?.task_capabilities.DesignPrep['anterior_crown_and_bridge']
    );

    const { value: isPrepInBrowserEnabledByFeatureFlag } = useFeatureFlag('prepInBrowserGuidedUiEnabled');
    const { value: enablePrepInBrowserMultiUnit } = useFeatureFlag('enablePrepInBrowserMultiUnit');
    const { value: enablePrepInBrowserLayeredMaterial } = useFeatureFlag('enablePrepInBrowserLayeredMaterial');

    const isPrepInBrowserEnabled = isPrepInBrowserEnabledForDesignStaff || isPrepInBrowserEnabledByFeatureFlag;
    const parsedItems = OrderItemV2Utils.parseItems(order.items_v2);
    const isCaseLayered = parsedItems.some(CartItemV2Utils.hasLayeredMaterial);
    const canPrepBeDoneInBrowser =
        (enablePrepInBrowserMultiUnit || isSingleCrown(parsedItems)) &&
        (enablePrepInBrowserLayeredMaterial || !isCaseLayered);

    if (order.fulfillment_workflow.active_task?.type !== 'DesignPrep') {
        return null;
    }

    if (isPrepInBrowserEnabled && canPrepBeDoneInBrowser) {
        return <DesignPrepStartPrepInBrowserBanner order={order} refetch={refetch} />;
    }

    return <DesignPrepStartPrepInThreeshapeBanner order={order} />;
};
