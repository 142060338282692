import { PRINTABLE_SLIP_DARK_COLOR } from '../../styles/colors';
import { FlossPalette, Text, Box, Grid, makeStyles } from '@orthly/ui-primitives';
import React from 'react';

export interface IPrintableSlipStickerProps {
    icon: React.ReactNode;
    solid?: boolean;
    text: string;
}

const useStickerStyles = makeStyles({
    stickerRoot: {
        alignItems: 'flex-start',
        border: `.12rem solid ${PRINTABLE_SLIP_DARK_COLOR}`,
        borderRadius: '6px',
        height: '2.5rem',
        justifyContent: 'flex-start',
        marginTop: '.2rem',
        marginBottom: '.2rem',
        padding: '.5rem',
    },
    stickerSolid: {
        backgroundColor: PRINTABLE_SLIP_DARK_COLOR,
    },
    stickerOutlined: {
        backgroundColor: FlossPalette.WHITE,
    },
    stickerIcon: {
        color: PRINTABLE_SLIP_DARK_COLOR,
        marginRight: '1rem',
    },
    stickerSolidIcon: {
        color: FlossPalette.WHITE,
        marginRight: '1rem',
    },
    stickerText: {
        color: `${PRINTABLE_SLIP_DARK_COLOR} !important`,
    },
    stickerSolidText: {
        color: `${FlossPalette.WHITE} !important`,
    },
});

/**
 * Renders a configured alert/tag "sticker" on Printable Slip. This sticker contains an Icon
 * and the text associated with the alert/tag on the order.
 * @param props
 * @returns Alert/Tag "sticker" fo the Printable Slip
 */
export const PrintableSlipSticker: React.VFC<IPrintableSlipStickerProps> = props => {
    const classes = useStickerStyles();
    const { icon, solid, text } = props;
    return (
        <Grid container className={`${classes.stickerRoot} ${solid ? classes.stickerSolid : classes.stickerOutlined}`}>
            <Box className={solid ? classes.stickerSolidIcon : classes.stickerIcon}>{icon}</Box>
            <Text variant={'body1'} medium className={solid ? classes.stickerSolidText : classes.stickerText}>
                {text}
            </Text>
        </Grid>
    );
};
