import { ManufacturerTruckTimeDeleteButton } from './ManufacturerTruckTimeDeleteButton.graphql';
import { ManufacturerTruckTimeModal } from './ManufacturerTruckTimeModal.graphql';
import { ManufacturerTruckTimeRestoreButton } from './ManufacturerTruckTimeRestoreButton.graphql';
import { useQuery } from '@apollo/client';
import type { AdminManufacturerTruckTimeEditor_QueryQuery } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import MUITable from '@orthly/mui-table';
import { Grid, Icon, Text } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';

const AdminManufacturerTruckTimeEditor_Query = graphql(`
    query AdminManufacturerTruckTimeEditor_Query($organization_id: String!) {
        listManufacturerTruckTimes(organization_id: $organization_id) {
            ...AdminManufacturerTruckTimeModal_Fragment

            id
            carrier
            days_of_week
            deleted_at
            label_print_cutoff_time {
                hour
                minute
            }
            pickup_time {
                hour
                minute
            }
            supports_next_day
        }
    }
`);

type ManufacturerTruckTime = AdminManufacturerTruckTimeEditor_QueryQuery['listManufacturerTruckTimes'][number];

function formatTime(time: { hour: number; minute: number }): string {
    return dayjs().hour(time.hour).minute(time.minute).format('hh:mmA');
}

interface ManufacturerTruckTimesEditorProps {
    manufacturerId: string;
    timeZone: string | null;
}

export const ManufacturerTruckTimesEditor: React.VFC<ManufacturerTruckTimesEditorProps> = ({
    manufacturerId,
    timeZone,
}) => {
    const {
        data: itemsData,
        loading,
        refetch,
    } = useQuery(AdminManufacturerTruckTimeEditor_Query, {
        variables: {
            organization_id: manufacturerId,
        },
    });
    const items = _.sortBy(itemsData?.listManufacturerTruckTimes ?? [], r => r.id);

    if (!timeZone) {
        return (
            <Text variant={'body2'} medium>
                Truck times cannot be configured for this lab because there is no set time zone. Please set one and then
                refresh the page.
            </Text>
        );
    }

    return (
        <Grid container>
            <MUITable<ManufacturerTruckTime>
                title={'Truck Times'}
                loading={loading}
                data={items}
                displayOptions={{
                    fixedSearch: true,
                    elevation: 0,
                    viewColumns: true,
                    filter: true,
                    sort: true,
                }}
                actions={{
                    global: [
                        { icon: 'refresh', position: 'toolbar', onClick: refetch },

                        {
                            icon: () => (
                                <ManufacturerTruckTimeModal
                                    manufacturerId={manufacturerId}
                                    existingTruckTimeFragment={null}
                                    timeZone={timeZone}
                                    refetch={async () => {
                                        await refetch();
                                    }}
                                />
                            ),
                            position: 'toolbar',
                            onClick: () => {
                                /* no-op */
                            },
                        },
                    ],
                }}
                rowOptions={{ rowHover: true }}
                eventHooks={{ onRowClick: (row, actions) => actions.toggleDetailPanel(row) }}
                columns={[
                    {
                        name: 'Carrier',
                        render: r => r.carrier,
                    },
                    {
                        name: 'Days of Week',
                        render: r => r.days_of_week.map(d => d.substring(0, 3)).join(', '),
                    },
                    {
                        name: 'Supports Next Date',
                        render: r => (r.supports_next_day ? 'Yes' : 'No'),
                    },
                    {
                        name: `Label Print Cutoff Time`,
                        title: `Label Print Cutoff Time (${timeZone})`,
                        render: r => formatTime(r.label_print_cutoff_time),
                    },
                    {
                        name: `Pickup Time`,
                        title: `Pickup Time (${timeZone})`,
                        render: r => formatTime(r.pickup_time),
                    },
                    {
                        name: 'Archived',
                        render: r => <Icon icon={r.deleted_at ? 'Check' : 'XIcon'} />,
                    },
                    {
                        name: 'Actions',
                        render: r =>
                            !r.deleted_at ? (
                                <>
                                    <ManufacturerTruckTimeModal
                                        manufacturerId={manufacturerId}
                                        existingTruckTimeFragment={r}
                                        timeZone={timeZone}
                                        refetch={async () => {
                                            await refetch();
                                        }}
                                    />
                                    <ManufacturerTruckTimeDeleteButton
                                        truckTimeId={r.id}
                                        onSuccess={async () => {
                                            await refetch();
                                        }}
                                    />
                                </>
                            ) : (
                                <ManufacturerTruckTimeRestoreButton
                                    truckTimeId={r.id}
                                    onSuccess={async () => {
                                        await refetch();
                                    }}
                                />
                            ),
                    },
                ]}
            />
        </Grid>
    );
};
