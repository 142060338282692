import type { ActionDefinition } from '../../MenuWithActions';
import { MenuWithActions } from '../../MenuWithActions';
import { useFeatureFlag } from '../../Providers/LaunchDarkly';
import { RefabGeneralNotesEditorChip } from '../../Refabs/RefabGeneralNotesEditorChip';
import { OrderDetailAccordionBlock } from '../OrderDetailAccordionBlock';
import { EditClinicalSupportNotesAction } from '../OrderDetailClinicalSupportNotesBlock';
import { EditDoctorNotesAction } from '../OrderDetailDoctorNotesBlock';
import { useGetAllNotes, getNotesCountForOrder, hasCrownUnderPartial } from './NotesSummary.hooks';
import type { NotesSummaryOrder, AlertsText } from './NotesSummary.types';
import { NotesSummaryGrid } from './NotesSummaryGrid';
import { OrderItemV2Utils } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

interface NotesSummaryBlockProps {
    order: NotesSummaryOrder;
    refetchOrder: () => Promise<any>;
    readOnly?: boolean;
    showLabSlipAlerts?: boolean;
}

export const NotesSummaryBlock: React.FC<NotesSummaryBlockProps> = props => {
    const { order, refetchOrder, readOnly, showLabSlipAlerts } = props;
    const includeActions = !readOnly;

    const allItems = OrderItemV2Utils.parseItems(order.items_v2);
    const alertsText: AlertsText = {
        designTaskAlertText: hasCrownUnderPartial(allItems) ? 'Crown under partial: YES' : null,
    };

    const { isLoading, ...allNotes } = useGetAllNotes(order);
    const numNotes = React.useMemo(
        () => getNotesCountForOrder(allNotes, allItems, showLabSlipAlerts),
        [allNotes, allItems, showLabSlipAlerts],
    );

    const { value: canCreateNewCSTNotes } = useFeatureFlag('creatingNewCstNotesEnabled');
    const hasExistingCSTNotes = !!allNotes.clinicalSupportNotes?.noteText?.length;

    const actions: ActionDefinition[] = _.compact([
        hasExistingCSTNotes || canCreateNewCSTNotes
            ? {
                  Component: ({ open, setOpen }) => {
                      return (
                          <EditClinicalSupportNotesAction
                              refetch={refetchOrder}
                              childProps={{ order: order, deleteNoteContent: false }}
                              open={open}
                              setOpen={setOpen}
                          />
                      );
                  },
                  label: `${hasExistingCSTNotes ? 'Edit' : 'Add'} CST Notes`,
              }
            : undefined,
        {
            Component: ({ open, setOpen }) => {
                return (
                    <EditDoctorNotesAction
                        refetch={refetchOrder}
                        childProps={{ order: order, deleteNoteContent: false }}
                        open={open}
                        setOpen={setOpen}
                    />
                );
            },
            label: `${allNotes.doctorNotes?.noteText?.length ? 'Edit' : 'Add'} Dr. notes`,
        },
        {
            Component: ({ open, setOpen }) => {
                return (
                    <RefabGeneralNotesEditorChip
                        refetch={refetchOrder}
                        childProps={{
                            order: order,
                            deleteNoteContent: false,
                            savedNotesForRefab: order.notes_for_refabrication,
                            showRefabEditChips: true,
                        }}
                        open={open}
                        setOpen={setOpen}
                    />
                );
            },
            label: `${allNotes.refabNotes?.noteText?.length ? 'Edit' : 'Add'} refab note`,
        },
    ]);

    return (
        <OrderDetailAccordionBlock
            variant={'full'}
            title={`Case note summary (${numNotes})`}
            actions={includeActions && <MenuWithActions actions={actions} buttonTitle={'Add or edit note'} />}
            defaultOpen={numNotes > 0}
            isLoading={isLoading}
        >
            <NotesSummaryGrid
                order={order}
                refetchOrder={refetchOrder}
                allNotes={allNotes}
                alertsText={alertsText}
                showLabSlipAlerts={showLabSlipAlerts}
            />
        </OrderDetailAccordionBlock>
    );
};
