import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlWorkflowTaskFragment } from '@orthly/graphql-operations';
import { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import React from 'react';

const AdminUseCompletedInboxDesignListItemDesignData_Query = graphql(`
    query AdminUseCompletedInboxDesignListItemDesignData_Query($taskIds: [String!]!) {
        getDesignOrderDesignRevisionsByTaskIds(taskIds: $taskIds) {
            design_order_id
            ...AdminCompletedInboxDesignListItemFeedbackDesign_Fragment
        }
    }
`);

export function useCompletedInboxDesignListItemDesignData(
    tasks: Pick<LabsGqlWorkflowTaskFragment, 'id' | 'closeout' | 'type'>[],
) {
    const session = useSession();

    const fetchableTaskIds = React.useMemo(() => {
        // These are the only task types where we potentially review or provide feedback on a design revision.
        const legalTaskTypes: LabsGqlWorkflowTaskType[] = [
            LabsGqlWorkflowTaskType.DesignPrep,
            LabsGqlWorkflowTaskType.InternalDesign,
        ];

        const legalTasks = tasks.filter(
            task => legalTaskTypes.includes(task.type) && task.closeout && task.closeout?.actor_id === session?.id,
        );
        return legalTasks.map(task => task.id);
    }, [tasks, session?.id]);

    const { data } = useQuery(AdminUseCompletedInboxDesignListItemDesignData_Query, {
        variables: {
            taskIds: fetchableTaskIds,
        },
        skip: !fetchableTaskIds.length,
    });

    return data?.getDesignOrderDesignRevisionsByTaskIds;
}
