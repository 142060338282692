import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import { Text, Grid, makeStyles } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles({
    panCodeSection: {
        textAlign: 'right',
        alignSelf: 'center',
    },
    orderHeaderMajor: {
        fontSize: '56px !important',
    },
    orderHeaderMinor: {
        fontSize: '0.9rem !important',
    },
});

export interface LabSlipCopyInfo {
    copyNumber: number;
    totalCopies: number;
}

interface ILabSlipOrderInfoProps {
    order: Exclude<LabsGqlLabOrderForLabSlipFragment, 'stackAndPanCodes'>;
    panCode?: string;
    batchIds?: string[] | null;
}

const getBatchText = (batchIds: string[] | null | undefined): string | null => {
    if (!batchIds || !batchIds?.[0]) {
        return null;
    }

    if (batchIds.length === 1) {
        return batchIds[0];
    }

    return 'See lab portal';
};

/**
 * Renders the Dandy Id and shipping dates
 * to be displayed on the Order Lab Slip
 * @param props
 * @returns Order Lab Slip Section with information about the order
 */
export const LabSlipOrderInfo: React.VFC<ILabSlipOrderInfoProps> = props => {
    const classes = useStyles();

    const { order, panCode, batchIds } = props;

    const batchText = getBatchText(batchIds);

    return (
        <Grid container justifyContent={'space-between'} height={'48px'}>
            <Grid item xs={4} container direction={'column'}>
                <Text variant={'h6'} style={{ fontSize: '1.1rem' }}>{`#${order.order_number}`}</Text>
                {/* TODO: we are assuming 1 batch per order for now, we need to figure out how to display multiple batches */}
                {batchText && (
                    <Grid container direction={'row'} spacing={0.5} alignItems={'center'}>
                        <Grid item>
                            <Text variant={'body2'} style={{ fontSize: '10px' }}>
                                {'Batch'}
                            </Text>
                        </Grid>
                        <Grid item>
                            <Text variant={'h6'} className={classes.orderHeaderMinor}>
                                {batchText}
                            </Text>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid item xs={8} className={classes.panCodeSection}>
                {panCode && (
                    <Text variant={'h6'} className={classes.orderHeaderMajor}>
                        {panCode}
                    </Text>
                )}
            </Grid>
        </Grid>
    );
};
