import { useDisplayOptionsContext, useTranslationOptionsContext } from '../../contexts/TableOptionsContext';
import { useMuiTableSelector } from '../../state';
import { useTableStateAction } from '../../state/action-hooks';
import { MUITableFilter } from './Filters/MUITableFilter';
import { MUITablePopover } from './MUITablePopover';
import type { Theme } from '@orthly/ui-primitives';
import {
    createStyles,
    makeStyles,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Text,
    CloseIcon,
    FilterIcon,
    SearchIcon,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const usePopStyles = makeStyles((theme: Theme) =>
    createStyles({
        icon: {
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        iconActive: {
            color: theme.palette.primary.main,
        },
        iconButton: {
            padding: 7,
        },
        pop: {
            maxHeight: 400,
        },
    }),
);

export const FixedSearchPopover = () => {
    const classes = usePopStyles();
    const translations = useTranslationOptionsContext();
    const [active, setActive] = React.useState<boolean>(false);

    return (
        <MUITablePopover
            open={active}
            refExit={() => setActive(!active)}
            className={classes.pop}
            Trigger={triggerProps => (
                <IconButton
                    data-testid={'fixed-search-filter'}
                    aria-label={translations.filter.title}
                    className={classes.iconButton}
                    classes={{ root: active ? classes.iconActive : classes.icon }}
                    onClick={() => {
                        setActive(!active);
                        triggerProps.openPopup();
                    }}
                >
                    <Tooltip title={translations.filter.title}>
                        <FilterIcon />
                    </Tooltip>
                </IconButton>
            )}
            content={<MUITableFilter />}
        />
    );
};

const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            root: {
                padding: '20px 0',
            },
            textField: {
                margin: 0,
                paddingTop: 10,
            },
            input: {
                padding: '0 !important',
                height: 'auto',
            },
            notchedOutline: {
                borderColor: `${theme.palette.grey['100']} !important`,
            },
            cssFocused: {},
            inputRoot: {
                background: theme.palette.grey['100'],
                boxSizing: 'border-box',
                borderRadius: 4,
                padding: '12px 14px',
                '&.Mui-focused': {
                    borderColor: `white !important`,
                    boxShadow: theme.shadows[1],
                },
            },
            inputFocused: {
                background: theme.palette.common.white,
                borderColor: `${theme.palette.divider} !important`,
            },
            icon: {
                color: theme.palette.text.secondary,
            },
            iconButton: {
                color: theme.palette.text.secondary,
                padding: 7,
            },
            wrapper: {
                padding: 5,
                justifyItems: 'flex-start',
                alignItems: 'center',
                display: 'flex',
            },
            adornment: {
                marginLeft: 0,
            },
            tabWrapper: {
                borderTop: `1px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.divider}`,
                marginTop: 20,
            },
            tab: {
                '&:hover': {
                    background: theme.palette.grey['100'],
                },
            },
            customSelectWrap: {
                position: 'absolute' as 'absolute',
                width: '100%',
                left: 0,
                top: 0,
            },
        }),
    { name: 'MUITableFixedSearch' },
);

type CancelableRef = ((newVal?: string) => void) & { cancel: () => void };

export const MUITableFixedSearch = (props: { hideTitle?: boolean; placeholder?: string }) => {
    const inputRef = React.useRef<HTMLInputElement>();
    const classes = useStyles();
    const searchTextUpdate = useTableStateAction('searchTextUpdate');
    const toggleSearchVisible = useTableStateAction('toggleSearchVisible');
    const { searchOpen, searchText } = useMuiTableSelector(({ searchOpen, searchText }) => ({
        searchOpen,
        searchText,
    }));
    const { title } = useDisplayOptionsContext();
    // Used by close button
    const changeText = React.useCallback(
        (text?: string) => {
            searchTextUpdate(text ? text : '');
        },
        [searchTextUpdate],
    );
    React.useEffect(() => {
        if (!searchOpen && !!searchText) {
            toggleSearchVisible();
        }
    }, [searchOpen, searchText, toggleSearchVisible]);
    // Event handler for text field
    const debouncedOnChange = React.useRef<CancelableRef>(_.debounce(changeText, 50));

    return (
        <Grid spacing={0} container className={classes.root} alignItems={'center'} data-testid={'FixedSearch'}>
            <Grid item xs={12}>
                {!props.hideTitle && (
                    <Text variant={'h5'} gutterBottom align={'left'}>
                        {title}
                    </Text>
                )}
                <TextField
                    className={classes.textField}
                    onChange={e => {
                        const newValue = (e.target?.value ?? '').trim();
                        if (newValue.length === 0 && !!searchText) {
                            return searchTextUpdate('');
                        }
                        debouncedOnChange.current.cancel();
                        debouncedOnChange.current(newValue);
                    }}
                    variant={'outlined'}
                    fullWidth
                    defaultValue={searchText ?? undefined}
                    placeholder={props.placeholder}
                    InputProps={{
                        inputRef,
                        inputProps: {
                            'data-testid': 'fixed-search-input',
                        },
                        classes: {
                            root: classes.inputRoot,
                            focused: classes.inputFocused,
                            notchedOutline: classes.notchedOutline,
                            input: classes.input,
                        },
                        startAdornment: (
                            <InputAdornment position={'start'}>
                                <SearchIcon className={classes.icon} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <React.Fragment>
                                {searchText && searchText.length > 0 ? (
                                    <InputAdornment position={'end'} className={classes.adornment}>
                                        <IconButton
                                            data-testid={'clear-fixed-search'}
                                            id={'clear-fixed-search'}
                                            className={classes.iconButton}
                                            onClick={() => {
                                                changeText();
                                                const currentInput = inputRef.current;
                                                if (currentInput) {
                                                    currentInput.value = '';
                                                }
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ) : null}
                                <InputAdornment position={'end'} className={classes.adornment}>
                                    <FixedSearchPopover />
                                </InputAdornment>
                            </React.Fragment>
                        ),
                    }}
                />
            </Grid>
        </Grid>
    );
};
