import { useActiveOrdersViewIsEdited } from '../components/OrdersSubnav/OrdersCustomViewNavItem';
import {
    useOrdersListQueryVars,
    useOrdersSearchQueryDisabled,
    useOrdersOverviewState,
} from './OrdersOverview.selectors';
import type { ApolloError } from '@apollo/client';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { OrderEntriesByListViewQueryHookResult } from '@orthly/graphql-react';
import { useOrderIdsByListView } from '@orthly/graphql-react';
import constate from 'constate';
import React from 'react';

export interface UseOrdersListViewIdsResult {
    ids: string[];
    loading: boolean;
    refetch: OrderEntriesByListViewQueryHookResult['refetch'];
    error?: ApolloError;
}

// Utility for tracking usages of saved searches for Orders
function useTrackOrderSavedSearchLoaded() {
    const savedSearchId = useOrdersOverviewState(s => s.view?.id);
    const activeViewEdited = useActiveOrdersViewIsEdited();
    return React.useCallback(() => {
        if (savedSearchId && !activeViewEdited) {
            BrowserAnalyticsClientFactory.Instance?.track('Ops - Portal - Saved Search Loaded', {
                search_id: savedSearchId,
                search_type: 'order',
            });
        }
    }, [savedSearchId, activeViewEdited]);
}

function _useOrdersListViewIds(): UseOrdersListViewIdsResult {
    const queryVars = useOrdersListQueryVars();
    const searchQueryDisabled = useOrdersSearchQueryDisabled();
    const trackOrderSavedSearchLoaded = useTrackOrderSavedSearchLoaded();
    return useOrderIdsByListView(queryVars, {
        skip: searchQueryDisabled,
        onCompleted: () => {
            trackOrderSavedSearchLoaded();
        },
    });
}

const [_OrdersListViewIdsProvider, _useOrderEntriesWrapped] = constate(_useOrdersListViewIds);

export const useOrdersListViewIds: () => UseOrdersListViewIdsResult = _useOrderEntriesWrapped;
export const OrdersListViewIdsProvider = _OrdersListViewIdsProvider;
