import type { ItemInfo, Rule, Decision } from '../orderEditMode';
import type { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { OrderEditMode } from '@orthly/shared-types';

/**
 * orderStatus checks for the order status matching any of its
 * specified statuses.  If it finds a match it returns EditOrder as
 * the most permissible possible value, otherwise it returns null to
 * skip
 */
export function orderStatus(...statuses: LabsGqlLabOrderStatus[]): Rule {
    return function (item: ItemInfo): Decision {
        const debugInfo = {
            rule: 'orderStatus',
            expected: statuses,
            received: item.order.status,
        };

        if (statuses.includes(item.order.status)) {
            return {
                editMode: OrderEditMode.OrderEdit,
                debugInfo,
            };
        }

        return {
            editMode: null,
            debugInfo,
        };
    };
}
