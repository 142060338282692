import type { TeethShade } from '@orthly/items';
import { TeethShades } from '@orthly/items';
import type { SimpleAutocompleteOption } from '@orthly/ui';
import { SimpleAutocomplete } from '@orthly/ui';
import { createStyles, makeStyles, SearchIcon } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            '& .MuiAutocomplete-popupIndicatorOpen': { transform: 'none' },
            '&&& .MuiInputBase-root.MuiInputBase-adornedEnd': { paddingRight: '8px' },
        },
    }),
);

export enum ShadeName {
    classical = 'classical',
    master = 'master',
    bleached = 'bleached',
    nd = 'nd',
    tissue = 'tissue',
}

const shadeAutoCompleteOpts: { label: string; value: TeethShade; name: ShadeName }[] = [
    ...TeethShades.classical.map(value => ({ value, label: `${value} (Vita Classical)`, name: ShadeName.classical })),
    ...TeethShades.master.map(value => ({ value, label: `${value} (Vita 3D Master)`, name: ShadeName.master })),
    ...TeethShades.bleached.map(value => ({ value, label: `${value} (Ivoclar Bleached)`, name: ShadeName.bleached })),
    ...TeethShades.nd.map(value => ({ value, label: `${value} (IPS Natural Die)`, name: ShadeName.nd })),
    ...TeethShades.tissue.map(value => ({ value, label: `${value} (Tissue)`, name: ShadeName.tissue })),
];

function getShadeAutocompleteInputValue(inputValue?: string | null): TeethShade | undefined {
    if (!inputValue) {
        return undefined;
    }
    return shadeAutoCompleteOpts.find(o => o.value === inputValue)?.value;
}

export interface ShadeFieldAutocompleteCustomPopoverProps {
    autocompleteProps: React.PropsWithChildren<{}>;
}

export interface ShadeFieldAutocompleteProps {
    // this is used to determine the timeout on auto-focusing the component. Base shade needs a longer delay b/c it's
    // the only shade field that can need auto-focus as the item screen slides into view (see longer explanation below)
    isBaseShadeType: boolean;
    setShade: (shade?: TeethShade) => void;
    currentShade: TeethShade | undefined;
    shadesToExclude: ShadeName[];
    CustomPopover?: React.ComponentType<ShadeFieldAutocompleteCustomPopoverProps>;
    endAdornment?: React.ReactNode;
    disableAutoFocusShade?: boolean;
    // If true, the UI will indicate whether and how the selection has changed from `originalShade`.
    showShadeChange?: boolean;
    // The original value for the shade, used with `showShadeChange`.
    originalShade?: TeethShade;
    // When true, will display a red label at right side of the input's title, indicating it's required
    isRequired?: boolean;
}

/**
 * If the shade field needs to be auto-focused (there's no value set yet) we can't do it immediately due to the slide
 * animations, which causes the popup to mount at the bottom of the DOM instead of under the shade input.
 *
 * This hook solves for that issue by delaying the auto-focus. If its the base shade, which is the only shade input
 * that should focus when the item screen slides into view, we need to delay longer. All other shade types mount after
 * a collapse in transition, which is faster.
 *
 * We also have the ability to disable the auto-focus when needed, though its rare.
 */
function useAutoOpenShadeField(isBaseShadeType: boolean, disableAutoFocusShade?: boolean) {
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    // this is ref so its stable for the useEffect
    const timeoutMs = React.useRef<number>(isBaseShadeType ? 350 : 200);
    React.useEffect(() => {
        const timeoutHandle = setTimeout(() => {
            const inputEl = inputRef.current;
            // if there's no value and we're mounted, then focus the input (and show the popup)
            if (inputEl && !inputEl.value) {
                inputEl.focus();
            }
        }, timeoutMs.current);
        return () => clearTimeout(timeoutHandle);
    }, []);
    return !!disableAutoFocusShade ? { inputRef: null } : { inputRef };
}

export const ShadeFieldAutocomplete: React.FC<ShadeFieldAutocompleteProps> = props => {
    const {
        setShade,
        currentShade,
        isBaseShadeType,
        CustomPopover,
        endAdornment,
        disableAutoFocusShade,
        showShadeChange,
        originalShade,
    } = props;
    const { inputRef } = useAutoOpenShadeField(isBaseShadeType, disableAutoFocusShade);
    const classes = useStyles();
    const CustomPaperComponent = React.useCallback(
        autocompleteProps => (CustomPopover ? <CustomPopover autocompleteProps={autocompleteProps} /> : null),
        [CustomPopover],
    );

    return (
        <SimpleAutocomplete
            label={''}
            key={currentShade}
            options={shadeAutoCompleteOpts.filter(shade => !props.shadesToExclude.includes(shade.name))}
            onChange={shadeValue => {
                setShade(getShadeAutocompleteInputValue(shadeValue));
            }}
            variant={'standard'}
            freeSolo={false}
            TextFieldProps={{
                inputRef,
                placeholder: 'Type to search',
                InputLabelProps: { shrink: true },
                InputProps: {
                    style: { fontWeight: 500, paddingTop: 2 },
                    multiline: false,
                    endAdornment: endAdornment ?? null,
                },
            }}
            initialInputValue={showShadeChange ? currentShade ?? originalShade : currentShade}
            AutocompleteProps={{
                className: classes.root,
                autoHighlight: false,
                // disable immediate dropdown if there's already a shade
                forcePopupIcon: !!currentShade,
                popupIcon: <SearchIcon />,
                style: { width: '100%' },
                // this is only used for setting the input value b/c we provide renderOption prop as well.
                getOptionLabel: (option: SimpleAutocompleteOption | string) => {
                    const value = typeof option === 'string' ? option : `${option.value}`;
                    if (!showShadeChange) {
                        return value;
                    } else if (value === originalShade) {
                        return `${value} (original shade)`;
                    }
                    return `${originalShade ?? '(None)'}  →  ${value}`;
                },
                // this is what shows in dropdown list
                renderOption: (props, option: SimpleAutocompleteOption | string) => {
                    const label = typeof option === 'string' ? option : option.label ?? option.value;
                    return <li {...props}>{label}</li>;
                },
                PaperComponent: CustomPopover ? CustomPaperComponent : undefined,
            }}
        />
    );
};
