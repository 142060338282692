import type { UnsavedPalateFilterCriteria } from '../PalateFilter.types';
import type { LabsGqlOrderFilterRuleFragment, LabsGqlFilterRuleOptionFragment } from '@orthly/graphql-operations';
import { useListOrderFilterOptionsQuery } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { SimpleCheckbox } from '@orthly/ui';
import { FlossPalette, Text, stylesFactory, Icon, Grid, Accordion, AccordionSummary } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    expansionPanel: {
        width: '100%',
        boxShadow: 'none',
        '&:before': {
            background: 'none',
        },
        '&.MuiExpansionPanel-root.Mui-expanded': {
            margin: 0,
        },
        '& .MuiExpansionPanelSummary-expandIcon.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
    },
    expansionPanelSummary: {
        padding: 'unset',
        '& .MuiExpansionPanelSummary-content': {
            margin: 0,
            padding: '16px 0px',
        },
        '&.MuiExpansionPanelSummary-root.Mui-expanded': {
            minHeight: 'unset',
        },
    },
}));

// Workaround to account for the fact that the options for the timeline filters are loaded separately from normal filters
type PalateTimelineFilter = Omit<LabsGqlOrderFilterRuleFragment, 'hasOptions' | '__typename'> & {
    __typename: 'PalateTimelineFilter';
    options: LabsGqlFilterRuleOptionFragment[];
};

export type PalateFilterDefinition =
    | (Omit<LabsGqlOrderFilterRuleFragment, '__typename'> & { __typename: 'FilterDefinition' })
    | PalateTimelineFilter;

interface FilterOptionsProps {
    filter: PalateFilterDefinition;
    criteriaIdx: number;
    criterias: UnsavedPalateFilterCriteria[];
    setCriterias: (criteriaValues: UnsavedPalateFilterCriteria[]) => void;
    setChanged: (changed: boolean) => void;
    changed: boolean;
    search: string;
    practiceId: string;
}

export enum ChannelFilterOption {
    salesforce = 'salesforce',
    practice = 'practice',
    reviews = 'reviews',
    automations = 'automations',
}

function usePalateFilterOptions(filter: PalateFilterDefinition, practiceId: string, search: string) {
    const { data } = useListOrderFilterOptionsQuery({
        variables: { filterId: filter.id, partnerId: practiceId },
        skip: filter.__typename === 'PalateTimelineFilter' || !filter.hasOptions,
    });
    return React.useMemo(() => {
        const baseOptions =
            filter.__typename === 'PalateTimelineFilter' ? filter.options : data?.loadOrderFilterOptions ?? [];
        // need to filter out Cancelled, Delivered, and NeedsRefabrication statuses in options since the palate orders tab only shows in flight orders
        // also filter options down by the search value if it's set
        const excludedStatuses = [
            `${LabsGqlLabOrderStatus.Cancelled}`,
            `${LabsGqlLabOrderStatus.Delivered}`,
            `${LabsGqlLabOrderStatus.NeedsRefabrication}`,
        ];
        const options =
            filter.id === 'status' ? baseOptions.filter(opt => !excludedStatuses.includes(opt.value)) : baseOptions;
        if (search.length > 0) {
            return options.filter(option =>
                (option.label ?? option.value).toLowerCase().includes(search.trim().toLowerCase()),
            );
        }
        return options;
    }, [filter, search, data?.loadOrderFilterOptions]);
}

export const FilterOptions: React.FC<FilterOptionsProps> = props => {
    const classes = useStyles();
    const { filter, criteriaIdx, criterias, setCriterias, changed, setChanged, search } = props;
    const filterOptions = usePalateFilterOptions(filter, props.practiceId, search);
    const [showDetails, setShowDetails] = React.useState(search.length > 0 && filterOptions.length > 0);
    const criteria = criterias[criteriaIdx];
    const [selectedOptions, setSelectedOptions] = React.useState(new Set<string>(criteria?.comparison_value ?? []));
    const onChange = React.useCallback(() => {
        !changed && setChanged(true);
        setCriterias(
            criterias.map((c, currentIdx) =>
                currentIdx !== criteriaIdx ? c : { ...c, comparison_value: Array.from(selectedOptions) },
            ),
        );
    }, [changed, setChanged, setCriterias, criterias, criteriaIdx, selectedOptions]);

    React.useEffect(() => {
        if (search.length > 0 && filterOptions.length > 0 && !showDetails) {
            setShowDetails(true);
        }
    }, [search, filterOptions, showDetails]);

    if (filterOptions.length === 0) {
        return null;
    }

    return (
        <Accordion
            className={classes.expansionPanel}
            onChange={(_event, expanded) => {
                setShowDetails(expanded);
            }}
            expanded={showDetails}
        >
            <AccordionSummary
                className={classes.expansionPanelSummary}
                expandIcon={<Icon icon={'ChevronRight'} />}
                style={{ borderBottom: showDetails ? 'none' : `1px solid ${FlossPalette.DARK_TAN}` }}
            >
                <Text variant={'body2'} style={{ paddingRight: 16 }} medium>
                    {filter.name}
                </Text>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    {selectedOptions.size === 0
                        ? `All ${Format.pluralizeNoun(
                              filter.name.toLowerCase(),
                              0,
                              // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                              // eslint-disable-next-line no-nested-ternary
                              filter.name.endsWith('s')
                                  ? `${filter.name.toLowerCase()}es`
                                  : filter.name === 'Staff'
                                    ? 'staff'
                                    : undefined,
                          )}`
                        : Format.pluralize(
                              filter.name.toLowerCase(),
                              selectedOptions.size,
                              // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                              // eslint-disable-next-line no-nested-ternary
                              filter.name.endsWith('s')
                                  ? `${filter.name.toLowerCase()}es`
                                  : filter.name === 'Staff'
                                    ? 'staff'
                                    : undefined,
                          )}
                </Text>
            </AccordionSummary>
            <Grid container style={{ borderBottom: `1px solid ${FlossPalette.DARK_TAN}`, paddingBottom: 24 }}>
                {filterOptions.map((option, idx) => (
                    <Grid container key={idx}>
                        <SimpleCheckbox
                            checked={selectedOptions.has(option.value)}
                            setChecked={checked => {
                                if (checked) {
                                    selectedOptions.add(option.value);
                                } else {
                                    selectedOptions.delete(option.value);
                                }
                                setSelectedOptions(new Set(selectedOptions));
                                onChange();
                            }}
                            label={option.label}
                            CheckboxProps={{ style: { marginLeft: 0 }, color: 'secondary' }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Accordion>
    );
};
