import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';

export const ModelConfig: DesignQcRubricCategoryEntry[] = [
    {
        category: 'follow_rx',
        subcategories: [
            {
                category: 'follow_rx',
                name: 'Model Size',
                grading: {
                    type: 'binary',
                    bad: 'Model size does not match Dr request',
                    ideal: 'Model size matches Dr request',
                },
                description: 'Does the designed model match doctor requested size?',
                edge_case: 'Doctor asks for impossible configuration - IE: full arch on a quad scan',
                selectable_bad_reasons: ['Model size does not match Dr request'],
                when: flags => flags.hasModel,
            },
            {
                category: 'follow_rx',
                name: 'Arches',
                grading: {
                    type: 'binary',
                    bad: 'Designed arches does not match Dr request',
                    ideal: 'Designed arches matches Dr request',
                },
                description: 'Does the designed model match doctor requested arches?',
                selectable_bad_reasons: ['Designed arches does not match Dr request'],
                when: flags => flags.hasModel,
            },
            {
                category: 'follow_rx',
                name: 'Label Placement',
                grading: {
                    type: 'binary',
                    bad: `Label isn't fully legible`,
                    ideal: 'Label is fully legible',
                },
                description: 'Label must be fully legible. Placed in the correct location, no obstructions',
                selectable_bad_reasons: [`Label isn't fully legible`],
                when: flags => flags.hasModel,
            },
            {
                category: 'follow_rx',
                name: 'Label Content',
                grading: {
                    type: 'binary',
                    bad: 'Label contains something other than order number',
                    ideal: 'Label only contains the order number',
                },
                description: 'Label should only contain order number. No names.',
                selectable_bad_reasons: ['Label contains something other than order number'],
                when: flags => flags.hasModel,
            },
        ],
    },
    {
        category: 'model_articulation',
        subcategories: [
            {
                category: 'model_articulation',
                name: 'Articulator Pin Placement',
                grading: {
                    type: 'binary',
                    bad: 'Pins are interfering with anatomy',
                    ideal: 'Pins are not interfering with anatomy',
                },
                description:
                    'Make sure that pins do not intersect/interfere with anatomy.<br><br>(Full arch) Anterior pin should be aligned as close to midline as reasonably possible',
                selectable_bad_reasons: ['Pins are interfering with anatomy'],
                when: flags => flags.hasModel,
            },
            {
                category: 'model_articulation',
                name: 'Articulator Base Placement',
                grading: {
                    type: 'binary',
                    bad: 'Base is NOT in correct position<br>OR<br>Base is NOT in correct orientation',
                    ideal: 'Base is in correct position and orientation',
                },
                description:
                    'Articulator base must connect to arches without excess distance and be oriented in the correct direction.<br><br>For single arch models - Answer should always be "ideal"',
                selectable_bad_reasons: ['Base is NOT in correct position', 'Base is NOT in correct orientation'],
                when: flags => flags.hasModel,
            },
        ],
    },
    {
        category: 'model_design',
        subcategories: [
            {
                category: 'model_design',
                name: 'General trimming',
                grading: {
                    type: 'binary',
                    bad: 'Model is significantly overtrimmed<br>OR<br>Model is significantly undertrimmed',
                    ideal: 'Model is correctly trimmed',
                },
                description:
                    'Is the scan correctly trimmed for the model? Overtrimming is critical to avoid, but undertrimming should be avoided<br><br>Overtrimming - mostly relevant for terminal molars. ',
                selectable_bad_reasons: ['Model is significantly overtrimmed', 'Model is significantly undertrimmed'],
                when: flags => flags.hasModel,
            },
            {
                category: 'model_design',
                name: 'Gingival cutting',
                grading: {
                    type: 'binary',
                    bad: 'Gingival cutting impacts IP contacts<br>OR<br>Insufficient level of gingival cutting',
                    ideal: 'Does not impact IP Contacts<br>AND<br>Tissue is sufficiently eliminated',
                },
                description:
                    'Verify that gingival cutting is not impacting IP contact, but eliminates most/all of the tissue above the margin line<br><br>This is NOT ditching',
                selectable_bad_reasons: [
                    'Gingival cutting impacts IP contacts',
                    'Insufficient level of gingival cutting',
                ],
                when: flags => flags.hasModel,
            },
            {
                category: 'model_design',
                name: 'Section Line',
                grading: {
                    type: 'binary',
                    bad: `Section line is NOT correctly set`,
                    ideal: 'Section line is correctly set',
                },
                description:
                    '<strong>For crowns</strong>: Section line should match margin line.<br><br><strong>For supra-g crowns and other restorations (ie: inlays)</strong>: Section line should be placed where the tooth structure meets the gingiva.',
                selectable_bad_reasons: [`Section line is NOT correctly set`],
                when: flags => flags.hasModel,
            },
            {
                category: 'model_design',
                name: 'Artifacts & Overhangs',
                grading: {
                    type: 'binary',
                    bad: 'Artifacts and overhangs are present',
                    ideal: 'No artifacts and overhangs present',
                },
                description:
                    'Verify that there are no scan artifacts or overhangs present in the model that could impact printing.<br><br>Common examples include minor overhangs from scan sculpting or jagged scan edges',
                selectable_bad_reasons: ['Artifacts and overhangs are present'],
                when: flags => flags.hasModel,
            },
        ],
    },
];
