import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import {
    LabsGqlLabPriceRuleType,
    LabsGqlLabPriceSurgicalGuideOwnership,
    LabsGqlLabPriceSurgicalGuideType,
} from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';

export const SurgicalGuideForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.SurgicalGuide>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.SurgicalGuide>({
                surgical_guide_type: {
                    type: 'select',
                    label: 'Guide Type',
                    options: Object.values(LabsGqlLabPriceSurgicalGuideType),
                },
                surgical_guide_ownership: {
                    type: 'select',
                    label: 'Ownership',
                    options: Object.values(LabsGqlLabPriceSurgicalGuideOwnership),
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          surgical_guide_type:
                              price.rule.__typename === 'LabPriceSurgicalGuideRuleDTO' ? price.rule.guide_type : null,
                          surgical_guide_ownership:
                              price.rule.__typename === 'LabPriceSurgicalGuideRuleDTO' ? price.rule.ownership : null,
                      }
                    : {}
            }
            onSubmit={async result => {
                await submit({
                    name: result.name,
                    rule: {
                        type: LabsGqlLabPriceRuleType.SurgicalGuide,
                        surgical_guide_type: result.surgical_guide_type || null,
                        surgical_guide_ownership: result.surgical_guide_ownership || null,
                    },
                });
            }}
            disabled={disabled}
        />
    );
};
