import { LabOrderConditionField } from '../../components/LabOrderConditionEditor';
import { RoutingRuleUtils } from '../../components/RoutingRule.util';
import type { LabsGqlBiasRuleFragment } from '@orthly/graphql-operations';
import { QuickForm } from '@orthly/ui';
import React from 'react';

export type SubmitRule = Pick<LabsGqlBiasRuleFragment, 'name' | 'score' | 'veto' | 'condition_json'>;
export type LabFilterRuleFormFields = Omit<SubmitRule, 'veto' | 'score'>;
export type BiasRuleFormFields = Omit<SubmitRule, 'veto'>;

const INITIAL_VALUES = { name: ``, condition_json: `null` };

export const LabFilterRuleForm: React.VFC<{
    onSubmit: (result: SubmitRule) => void;
    initialValues?: LabFilterRuleFormFields;
}> = ({ onSubmit, initialValues }) => {
    return (
        <QuickForm<LabFilterRuleFormFields>
            initialValues={initialValues ?? INITIAL_VALUES}
            fields={{
                name: {
                    label: 'Name',
                    type: 'text',
                },
                condition_json: {
                    type: 'custom',
                    label: 'Criteria',
                    component: LabOrderConditionField,
                    validation: RoutingRuleUtils.OrderConditionValidation,
                },
            }}
            onSubmit={({ name, condition_json }) => onSubmit({ name, condition_json, score: 0, veto: true })}
        />
    );
};

export const BiasRuleForm: React.VFC<{
    onSubmit: (result: SubmitRule) => void;
    initialValues?: BiasRuleFormFields;
}> = ({ onSubmit, initialValues }) => {
    const defaults: BiasRuleFormFields = { ...INITIAL_VALUES, score: 0 };
    return (
        <QuickForm<BiasRuleFormFields>
            initialValues={initialValues ?? defaults}
            fields={{
                name: {
                    label: 'Name',
                    type: 'text',
                },
                condition_json: {
                    type: 'custom',
                    label: 'Criteria',
                    component: LabOrderConditionField,
                    validation: RoutingRuleUtils.OrderConditionValidation,
                },
                score: {
                    label: 'Score',
                    type: 'number',
                },
            }}
            onSubmit={({ name, condition_json, score }) => onSubmit({ name, condition_json, score, veto: false })}
        />
    );
};
