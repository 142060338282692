import { ComparisonModeEvent, useComparisonModeTracking } from '../hooks/useComparisonModeTracking';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { ToggleButton } from './ToggleButton';
import React from 'react';

export const useComparisonToggleButton = () => {
    const {
        isComparisonModeActive,
        toggleComparisonMode,
        isAnnotatingScreenshot,
        setIsIncompletedWorkReminderModalOpen,
    } = useGuidedWaxupContext();

    const trackComparisonMode = useComparisonModeTracking();

    const onClick = React.useCallback(() => {
        if (isAnnotatingScreenshot) {
            setIsIncompletedWorkReminderModalOpen(true);
        } else {
            trackComparisonMode(ComparisonModeEvent.toggle);
            toggleComparisonMode();
        }
    }, [isAnnotatingScreenshot, setIsIncompletedWorkReminderModalOpen, trackComparisonMode, toggleComparisonMode]);

    return {
        checked: isComparisonModeActive,
        onClick,
    };
};

export const ComparisonToggleButton: React.VFC = () => {
    const { checked, onClick } = useComparisonToggleButton();

    return (
        <ToggleButton
            checked={checked}
            icon={'CompareIcon'}
            onClick={onClick}
            label={'Compare design history'}
            data-pendo-id={'guided-waxup-compare'}
        />
    );
};
