import { useAutomationBuilderAction } from '../../state/AutomationBuilder.actions';
import { useAutomationBuilderSelector } from '../../state/AutomationBuilder.context';
import { AutomationBuilderFieldLayout } from './components/AutomationBuilderFieldLayout';
import { AutomationTextField, AutomationMultiSelectChipsField } from './components/AutomationBuilderFields';
import { AutomationStepContainer } from './components/AutomationStepContainer';
import { LabsGqlAutomationTriggerEventNamesEnum as AutomationTriggeringEventsEnum } from '@orthly/graphql-schema';
import type { SimpleSelectOption } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

function labelForEventTrigger(name: AutomationTriggeringEventsEnum) {
    if (name === AutomationTriggeringEventsEnum.TaggedEntity) {
        return 'Tag Added to Order';
    }
    if (name === AutomationTriggeringEventsEnum.UntaggedEntity) {
        return 'Tag Removed from Order';
    }
    return _.startCase(name);
}

function getTriggerOptions(): SimpleSelectOption[] {
    const ExcludedEvents: AutomationTriggeringEventsEnum[] = [
        AutomationTriggeringEventsEnum.OrderMerged,
        AutomationTriggeringEventsEnum.DoctorPreferencesIdSet,
        AutomationTriggeringEventsEnum.DueDateChanged,
        AutomationTriggeringEventsEnum.OrderAddOnsUpdated,
    ];
    const validEvents = Object.values(AutomationTriggeringEventsEnum).filter(v => !ExcludedEvents.includes(v));
    const options = validEvents.map(value => ({ value, label: labelForEventTrigger(value) }));
    return _.sortBy(options, o => o.label);
}

const DescriptionField: React.FC = () => {
    const description = useAutomationBuilderSelector(s => s.form.description);
    const updateForm = useAutomationBuilderAction('UPDATE_FORM');
    const [touched, setTouched] = React.useState<boolean>(!!description);
    return (
        <AutomationBuilderFieldLayout title={'Description'}>
            <AutomationTextField
                enableLocalValue={true}
                value={description}
                TextFieldProps={{
                    multiline: true,
                    rows: 3,
                    error: touched && !description,
                    helperText: touched && !description ? 'Description is required' : undefined,
                }}
                onChange={value => {
                    !touched && setTouched(true);
                    updateForm({ value, property: 'description' });
                }}
                label={'Description'}
            />
        </AutomationBuilderFieldLayout>
    );
};

export const AutomationDetailsStep: React.FC = () => {
    const form = useAutomationBuilderSelector(s => s.form);
    const updateForm = useAutomationBuilderAction('UPDATE_FORM');
    return (
        <AutomationStepContainer title={'Details'}>
            <AutomationBuilderFieldLayout title={'Name'}>
                <AutomationTextField
                    enableLocalValue={true}
                    value={form.name}
                    onChange={value => {
                        updateForm({ value, property: 'name' });
                    }}
                    label={'Name'}
                />
            </AutomationBuilderFieldLayout>
            <DescriptionField />
            <AutomationBuilderFieldLayout title={'When'}>
                <AutomationMultiSelectChipsField
                    options={getTriggerOptions()}
                    errorText={
                        !form.triggered_by || form.triggered_by.length === 0
                            ? 'Please select at least one triggering event'
                            : undefined
                    }
                    onChange={value => {
                        updateForm({
                            property: 'triggered_by',
                            value: (value as AutomationTriggeringEventsEnum[]) ?? [],
                        });
                    }}
                    value={form.triggered_by ?? []}
                    label={''}
                    ChipProps={{ color: 'secondary' }}
                />
            </AutomationBuilderFieldLayout>
        </AutomationStepContainer>
    );
};
