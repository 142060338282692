import { ShippingUtils } from '@orthly/shared-types';
import { FlossPalette, Link, stylesFactory, Icon } from '@orthly/ui-primitives';
import moment from 'moment-timezone';
import React from 'react';

const { BURGUNDY, GRAY, STAR_GRASS_ACTIVE } = FlossPalette;

interface StatusHeadlineStyleProps {
    colorful: boolean;
}

const useStyles = stylesFactory(theme => ({
    statusHeadline: ({ colorful }: StatusHeadlineStyleProps) => ({
        ...theme.typography.body1,
        fontWeight: 'bold' as const,
        display: 'flex',
        flexDirection: 'row' as const,
        flexWrap: 'wrap' as const,
        columnGap: '0.5ch',
        '& a': {
            display: 'inline',
            flexGrow: 0,
            flexShrink: 0,
            textDecoration: 'none',
            color: colorful ? STAR_GRASS_ACTIVE : GRAY,
            '&:hover': {
                textDecoration: 'none',
            },
            '& svg': {
                verticalAlign: 'bottom',
            },
        },
        '& span': {
            whiteSpace: 'nowrap' as const,
            flexGrow: 0,
            flexShrink: 0,
        },
        '& span:first-of-type': {
            color: colorful ? STAR_GRASS_ACTIVE : GRAY,
        },
        '& span:nth-of-type(2)': {
            color: colorful ? BURGUNDY : GRAY,
        },
        // See comment below apologizing for the third <span>.
        '& span:nth-of-type(3)': {
            flexGrow: 1,
            flexShrink: 1,
        },
    }),
}));

const dateTimeFormats = {
    full: 'MMMM D [at] h:mma',
    hour: 'MMMM D [at] ha',
    none: 'MMMM D',
} as const;

interface StatusHeadlineProps {
    textPart: string;
    datePart: moment.Moment | string | null;
    colorful?: boolean;
    useTime?: keyof typeof dateTimeFormats;
    trackingNumber?: string | null;
    carrier?: string | null;
}

export const StatusHeadline: React.VFC<StatusHeadlineProps> = ({
    textPart,
    datePart,
    colorful = true,
    useTime = 'hour',
    trackingNumber,
    carrier,
}) => {
    const classes = useStyles({ colorful });
    const dateMoment = typeof datePart === 'string' ? moment.tz(datePart, moment.tz.guess()) : datePart;
    const timeString = dateMoment === null ? '(unknown)' : dateMoment.format(dateTimeFormats[useTime]);

    const trackingLinkElement =
        carrier && trackingNumber ? (
            <Link
                href={ShippingUtils.getTrackingLinkForCarrier(carrier, trackingNumber)}
                target={'_blank'}
                rel={'noopener noreferrer'}
            >
                {trackingNumber} <Icon icon={'CallMadeIcon'} />
            </Link>
        ) : undefined;
    // The third <span> is a flexbox spacing hack, sorry.  If you have a better idea, go right ahead.
    // The intent is for the tracking link to appear right-aligned when it's on the same line as the rest,
    // but if the content wraps, just leave the tracking link left-aligned.
    return (
        <div className={classes.statusHeadline}>
            <span>{textPart}:</span> <span>{timeString}</span> <span /> {trackingLinkElement}
        </div>
    );
};
