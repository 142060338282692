import { BooleanIcon } from '../../../components/BooleanIcon';
import { GrayBooleanIcon } from '../BillingDetails/components/GrayBooleanIcon';
import { InvoiceItemsTable } from '../InvoicesTable/InvoiceItemsTable';
import type { InvoiceItem } from '../InvoicesTable/InvoiceTable.types';
import { useMutation, useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type {
    Exact,
    LabsGqlCreateInvoiceInput,
    LabsGqlNextInvoiceStatusSummary,
    LabsGqlPendingInvoiceItemDto,
} from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import type { RootActionDialogProps } from '@orthly/ui';
import { RootActionDialog, SimpleDatePicker, useRootActionCommand } from '@orthly/ui';
import { Button, FlossPalette, Grid, Icon, IconButton, InfoIcon, Text, Tooltip } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';

const PreviewPendingInvoiceItems_Query = graphql(`
    query PreviewPracticePendingInvoiceItems($targetCreationDate: DateTime!, $organizationId: String) {
        previewPracticePendingInvoiceItems(targetCreationDate: $targetCreationDate, organizationId: $organizationId) {
            amount_cents
            category
            description
            id
            order_id
            subcategory
            used_credit_id
            created_at
            recurring_item_id
        }
    }
`);

function usePreviewUsageItems(organizationId: string, targetCreationDate: string) {
    const {
        data: { previewPracticePendingInvoiceItems: rawItems } = {},
        loading,
        refetch: refetchItems,
    } = useQuery<{ previewPracticePendingInvoiceItems: LabsGqlPendingInvoiceItemDto[] }>(
        PreviewPendingInvoiceItems_Query,
        {
            variables: { organizationId, targetCreationDate },
        },
    );

    const items = React.useMemo(() => {
        return (rawItems ?? []).map<InvoiceItem>(item => ({
            ...item,
            id: _.uniqueId('ii'),
            created_at: item.created_at,
            recurring_item_id: item.recurring_item_id,
            __typename: 'InvoiceItem',
        }));
    }, [rawItems]);

    const monthFormatted = React.useMemo(() => {
        const period_start = dayjs(targetCreationDate).subtract(1, 'month').startOf('month');
        return period_start.format('MMM YYYY');
    }, [targetCreationDate]);
    const itemsTotal = React.useMemo(() => _.sumBy(items, i => i.amount_cents), [items]);
    return { items, itemsTotal, loading, monthFormatted, refetchItems };
}

interface GenerateInvoiceTableToolbarProps {
    itemsTotal: number;
    onSubmit: () => any;
    itemsCount: number;
    isCreateMode: boolean;
}

const ZERO_DOLLAR_INVOICE_MESSAGE =
    'Invoices with $0 total will automatically be marked as paid (and you will not be able to void it)';

const GenerateInvoiceTableToolbar: React.FC<GenerateInvoiceTableToolbarProps> = props => {
    const { itemsTotal, itemsCount, isCreateMode } = props;
    const totalFmt = Format.currency(itemsTotal);
    return (
        <Grid container justifyContent={'space-between'} alignItems={'center'} style={{ paddingTop: 10 }}>
            <Grid container item xs={6}>
                <Grid container alignItems={'center'}>
                    <Text variant={'h6'} style={{ lineHeight: 1.2 }}>
                        Total: {totalFmt}
                    </Text>
                    <Tooltip title={'Does not include Stripe balance from refunds (if any)'}>
                        <InfoIcon style={{ marginLeft: 4, width: 16, height: 16 }} color={'action'} />
                    </Tooltip>
                </Grid>
                {itemsTotal === 0 && isCreateMode && (
                    <Text style={{ color: FlossPalette.ATTENTION, lineHeight: 1 }} variant={'caption'}>
                        {ZERO_DOLLAR_INVOICE_MESSAGE}
                    </Text>
                )}
                <Grid container>{props.children}</Grid>
            </Grid>
            <Grid container item xs={6} justifyContent={'flex-end'} style={{ height: 'fit-content' }}>
                {itemsCount > 0 && isCreateMode && (
                    <Button
                        variant={'contained'}
                        onClick={() => {
                            const zeroDollarWarning = itemsTotal > 0 ? '' : ZERO_DOLLAR_INVOICE_MESSAGE;
                            if (window.confirm(`Generate invoice for total: ${totalFmt}? ${zeroDollarWarning}`)) {
                                props.onSubmit();
                            }
                        }}
                    >
                        Generate Invoice...
                    </Button>
                )}
            </Grid>
        </Grid>
    );
};

const PreviewUsageConditions: React.FC<{
    pending_item_count: number;
    previous_usage_invoice_cents: number;
}> = props => {
    const { pending_item_count, previous_usage_invoice_cents } = props;
    return (
        <Grid>
            <Text>
                Partner will be invoiced if <b>either</b> are true:
            </Text>
            <Text variant={'caption'} style={{ display: 'flex', alignItems: 'center' }}>
                Has at least 1 order or other charge this month: <BooleanIcon val={pending_item_count > 0} />
            </Text>
            <Text variant={'caption'} style={{ display: 'flex', alignItems: 'center' }}>
                Last invoice was &gt; $0: <BooleanIcon val={previous_usage_invoice_cents > 0} />
            </Text>
        </Grid>
    );
};

export interface CreateUsageInvoiceProps {
    organizationId: string;
    nextInvoiceStatus: LabsGqlNextInvoiceStatusSummary;
    mode: 'create' | 'preview';
    refetch: () => Promise<unknown>;
}

interface CreateOrPreviewUsageInvoiceContentProps extends Omit<CreateUsageInvoiceProps, 'refetch'> {
    submit: CreateInvoice;
}

function parseTargetCreationDate(date?: Date): string {
    if (!date) {
        const startOfNextMonth = dayjs().add(1, 'month').startOf('month');
        return `${startOfNextMonth.get('year')}-${startOfNextMonth.get('month') + 1}-${startOfNextMonth.get('date')}`;
    } else {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }
}

const CreateOrPreviewUsageInvoiceContent: React.FC<CreateOrPreviewUsageInvoiceContentProps> = ({
    organizationId,
    nextInvoiceStatus,
    mode,
    submit,
}) => {
    const isCreateMode = mode === 'create';
    const [targetCreationDate, setTargetCreationDate] = React.useState(parseTargetCreationDate());
    const { monthFormatted, items, loading, itemsTotal, refetchItems } = usePreviewUsageItems(
        organizationId,
        targetCreationDate,
    );
    const { pending_item_count, previous_invoice_amount_cents } = nextInvoiceStatus;
    const CustomBottom = React.useCallback(() => {
        return (
            <GenerateInvoiceTableToolbar
                isCreateMode={isCreateMode}
                onSubmit={async () => {
                    await submit({
                        data: { targetCreationDate, organizationId },
                    });
                }}
                itemsTotal={itemsTotal}
                itemsCount={items.length ?? 0}
            >
                {!isCreateMode && (
                    <PreviewUsageConditions
                        pending_item_count={pending_item_count}
                        previous_usage_invoice_cents={previous_invoice_amount_cents}
                    />
                )}
            </GenerateInvoiceTableToolbar>
        );
    }, [
        isCreateMode,
        items.length,
        itemsTotal,
        organizationId,
        pending_item_count,
        previous_invoice_amount_cents,
        submit,
        targetCreationDate,
    ]);
    const onClickRefresh = () => {
        refetchItems().catch(console.error);
    };

    return (
        <Grid container>
            {isCreateMode && (
                <Grid container style={{ padding: '0 24px 10px' }}>
                    <SimpleDatePicker
                        fullWidth={true}
                        helperText={`Base date for calculating orders to include in invoice. Example: Pick July 1 to invoice for June's orders.`}
                        label={'Invoice Creation Date'}
                        value={targetCreationDate ? new Date(targetCreationDate) : null}
                        onChange={date => {
                            !!date && setTargetCreationDate(parseTargetCreationDate(date));
                        }}
                    />
                </Grid>
            )}
            <InvoiceItemsTable
                loading={loading}
                items={items ?? []}
                title={`Preview Usage Invoice - ${monthFormatted}`}
                tableProps={{
                    displayOptions: { search: true, fixedSearch: false },
                    toolbarOptions: { CustomBottom },
                    actions: { global: [{ position: 'toolbar', onClick: onClickRefresh, icon: 'refresh' }] },
                }}
                refetchItems={refetchItems}
                hasPendingPaymentOnInvoice={null}
            />
        </Grid>
    );
};

const CreateInvoice_Mutation = graphql(`
    mutation CreateInvoice($data: CreateInvoiceInput!) {
        createInvoice(data: $data) {
            id
        }
    }
`);

type CreateInvoice = (
    args_0: Exact<{
        data: LabsGqlCreateInvoiceInput;
    }>,
) => Promise<unknown>;

export const CreateOrPreviewUsageInvoice: React.FC<
    CreateUsageInvoiceProps & Pick<RootActionDialogProps, 'CustomButton'>
> = ({ organizationId: practiceId, nextInvoiceStatus, mode, refetch, CustomButton }) => {
    const isCreateMode = mode === 'create';
    const [open, setOpen] = React.useState(false);
    const createInvoice = useMutation(CreateInvoice_Mutation);

    const { submit, submitting } = useRootActionCommand(createInvoice, {
        onSuccess: async () => {
            await refetch();
            setOpen(false);
        },
        successMessage: 'Invoice created',
    });

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={`${isCreateMode ? 'Create ' : ''}Usage Invoice`}
            dialogProps={{ maxWidth: 'lg' }}
            showCloseButton
            content={
                !open ? null : (
                    <CreateOrPreviewUsageInvoiceContent
                        submit={submit}
                        organizationId={practiceId}
                        nextInvoiceStatus={nextInvoiceStatus}
                        mode={mode}
                    />
                )
            }
            buttonText={''}
            CustomButton={
                CustomButton
                    ? CustomButton
                    : () => (
                          <Text style={{ display: 'flex', alignItems: 'center' }}>
                              {!isCreateMode && <GrayBooleanIcon val={nextInvoiceStatus.will_be_invoiced} />}
                              <IconButton onClick={() => setOpen(true)} size={'small'}>
                                  <Tooltip title={isCreateMode ? 'Generate Invoice' : 'Preview Invoice'}>
                                      <Icon
                                          icon={isCreateMode ? 'AddNoteIcon' : 'FindInPage'}
                                          sx={{ color: FlossPalette.PRIMARY_FOREGROUND, minWidth: '24px' }}
                                      />
                                  </Tooltip>
                              </IconButton>
                          </Text>
                      )
            }
        />
    );
};
