import type { IBridgeItem } from '@orthly/items';

/**
 * Generates the list of tooth #s (unns) to display
 * on the Printable Slip for a bridge item that spans
 * more than 1 tooth
 * @param item Bridge item whose unns to format
 * @returns Formatted list of unns
 */
export function getFormattedUnnList(item: IBridgeItem): string {
    return item.units
        .map(unit => {
            if (unit.unit_type === 'CrownPontic') {
                return `${unit.unn} (Pontic)`;
            }
            return `${unit.unn}`;
        })
        .join(', ')
        .trim();
}

/**
 * Get the material specified for the Bridge order item
 * The bridge item has the same material for all the "units"
 * and so the 1st unit's material is defined as the Bridge material
 * @param item Bridge item whose material to retrieve
 * @returns The material for the Bridge item
 */
export function getBridgeMaterial(item: IBridgeItem): string | null {
    return item.units && item.units[0] ? item.units[0].material : null;
}
