import type { ConfigRow, RefetchPrice } from '../PricingRoot.types';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { Format } from '@orthly/runtime-utils';
import type { ColumnLabel } from '@orthly/ui';
import { LoadBlocker, Table, useRootActionCommand, useSortedRows } from '@orthly/ui';
import { Button, styled } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

export interface PartnerConfigTableProps {
    rows: ConfigRow[];
    refetch: RefetchPrice;
    priceId: string;
    priceName: string;
}

interface DeletePartnerPriceBtnProps extends Omit<PartnerConfigTableProps, 'rows'> {
    partnerId: string;
    partnerName: string;
}

const DeletePartnerPriceConfig_Mutation = graphql(`
    mutation DeletePartnerPriceConfig($data: DeletePartnerPriceConfigCommand!) {
        deletePartnerPriceConfig(data: $data) {
            id
        }
    }
`);

const DeletePartnerPriceBtn: React.FC<DeletePartnerPriceBtnProps> = ({
    partnerName,
    partnerId,
    priceId,
    priceName,
    refetch,
}) => {
    const deletePartnerPriceConfig = useMutation(DeletePartnerPriceConfig_Mutation);
    const { submit, submitting } = useRootActionCommand(deletePartnerPriceConfig, {
        onSuccess: () => refetch(),
    });
    const onClick = React.useCallback(async () => {
        if (!submitting && window.confirm(`Delete ${priceName} price for ${partnerName}?`)) {
            await submit({ data: { partner_id: partnerId, price_id: priceId } });
        }
    }, [submitting, partnerId, priceId, partnerName, priceName, submit]);

    return (
        <LoadBlocker blocking={submitting}>
            <Button startIcon={'DeleteIcon'} onClick={onClick} variant={'text'} style={{ color: 'red' }}>
                Delete
            </Button>
        </LoadBlocker>
    );
};

const columns: ColumnLabel[] = [
    {
        id: 'name',
        numeric: false,
        label: 'Partner',
    },
    {
        id: 'price_cents',
        numeric: true,
        label: 'Price',
    },
];

const Container = styled('div')({
    width: '100%',
});

export const PartnerConfigTable: React.FC<PartnerConfigTableProps> = ({ rows, refetch, priceId, priceName }) => {
    const { value: disableContractEdits = false } = useFeatureFlag('disableContractEdits');
    const deleteColumn: ColumnLabel[] = disableContractEdits
        ? []
        : [
              {
                  id: 'delete',
                  sortable: false,
                  numeric: false,
                  label: 'Remove',
              },
          ];
    const pricingColumns: ColumnLabel[] = [...columns, ...deleteColumn];
    const { sortedRows, paginationProps, sortableHeadersProps } = useSortedRows({
        unsortedRows: rows,
        columns: pricingColumns,
    });

    return (
        <Container>
            <Table>
                <Table.SortableHeaders {...sortableHeadersProps} />
                <Table.Body>
                    {sortedRows.map(row => (
                        <Table.Row key={row.partner_id}>
                            <Table.Cell>{row.name || '-'}</Table.Cell>
                            <Table.Cell align={'right'}>{Format.currency(row.price_cents, 'cents')}</Table.Cell>
                            {!disableContractEdits && (
                                <Table.Cell>
                                    <DeletePartnerPriceBtn
                                        partnerId={row.partner_id}
                                        partnerName={row.name ?? ''}
                                        priceId={priceId}
                                        priceName={priceName}
                                        refetch={refetch}
                                    />
                                </Table.Cell>
                            )}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <Table.Pagination {...paginationProps} />
        </Container>
    );
};
