import { AnalyticsClient } from '../../../../../../../utils/analyticsClient';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useBulkCreateDesignOrdersMutation } from '@orthly/graphql-react';
import { LoadBlocker, useRootActionCommand, Icon } from '@orthly/ui';
import { Button, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

interface ForceCreateDesignOrderMenuProps {
    order: LabsGqlLabOrderFragment;
}

export const ForceCreateDesignOrderMenu: React.FC<ForceCreateDesignOrderMenuProps> = props => {
    const { order } = props;

    const { submit, submitting } = useRootActionCommand(useBulkCreateDesignOrdersMutation(), {
        successMessage: 'Design order created',
    });

    return (
        <Tooltip title={'Force Create Design Order'}>
            <Button
                onClick={() => {
                    AnalyticsClient.track('All - Portal - Order Files Menu Used', {
                        $groups: { order: order.id },
                        action: 'force_create_design_order',
                    });
                    void submit({
                        lab_order_ids: [order.id],
                    });
                }}
                variant={'text'}
                disabled={submitting}
            >
                <LoadBlocker blocking={submitting}>
                    <Icon icon={submitting ? 'Whatshot' : 'SyncAlt'} />
                </LoadBlocker>
            </Button>
        </Tooltip>
    );
};
