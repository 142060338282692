import { DANDY_SEARCH_WIDTH } from './DandySearchPresenter.constants';
import { FlossPalette, useScreenIsMd, InputAdornment, TextField, CloseIcon, SearchIcon } from '@orthly/ui-primitives';
import React from 'react';

interface DandySearchInputProps {
    search: string;
    onInputChange: (value: string) => void;
    onInputFocus: (value: string) => void;
    onInputClear: () => void;
    placeholder?: string;
    inputStyle?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
}

export const DandySearchInput = React.forwardRef<HTMLDivElement, DandySearchInputProps>(
    ({ search, onInputChange, onInputFocus, onInputClear, placeholder, inputStyle = {}, containerStyle = {} }, ref) => {
        const isMobile = useScreenIsMd();

        return (
            <TextField
                variant={'standard'}
                ref={ref}
                data-test={'search-input'}
                placeholder={placeholder ?? 'Search'}
                margin={'none'}
                value={search}
                onFocus={e => onInputFocus(e.target.value ?? '')}
                onChange={e => onInputChange(e.target.value ?? '')}
                style={{ width: isMobile ? '100%' : undefined, ...containerStyle }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position={'start'} style={{ padding: 0, margin: 0 }}>
                            <SearchIcon style={{ fontSize: 20, color: FlossPalette.GRAY, marginLeft: 4 }} />
                        </InputAdornment>
                    ),
                    endAdornment:
                        search.length > 0 ? (
                            <InputAdornment
                                position={'end'}
                                style={{ padding: 0, margin: 0, cursor: 'pointer' }}
                                onClick={onInputClear}
                            >
                                <CloseIcon style={{ fontSize: 20, color: FlossPalette.GRAY, marginLeft: 4 }} />
                            </InputAdornment>
                        ) : undefined,
                    style: {
                        marginRight: 8,
                        backgroundColor: FlossPalette.WHITE,
                        width: isMobile ? '100%' : DANDY_SEARCH_WIDTH,
                        ...inputStyle,
                    },
                    sx: {
                        '&&& input.MuiInputBase-input': {
                            padding: '8px',
                        },
                    },
                }}
            />
        );
    },
);
