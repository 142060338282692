import type { AdminLabsOrderActionProps } from './order-action-types';
import { useUnlinkRefabricationMutation } from '@orthly/graphql-react';
import { QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface UnlinkRefabricationActionProps extends AdminLabsOrderActionProps {
    originalOrderId: string;
    controls?: { open: boolean; setOpen: (open: boolean) => void };
    hideButton?: boolean;
}

export const UnlinkRefabricationAction: React.FC<UnlinkRefabricationActionProps> = props => {
    const { order, originalOrderId, hideButton } = props;
    const isOriginal = order.id === originalOrderId;
    const [submitMtn] = useUnlinkRefabricationMutation();
    const mtnSubmitter = (reason: string) =>
        submitMtn({ variables: { reason, originalOrderId: originalOrderId ?? '' } });
    const { submit, submitting, ...changeFn } = useChangeSubmissionFn<any, [string]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => [`Order unlinked from ${isOriginal ? 'refabrication' : 'original'}`, {}],
        onSuccess: async () => {
            await props.refetchOrder();
            props.controls?.setOpen(false);
        },
    });
    const { open, setOpen } = props.controls ?? changeFn;
    if (order.id !== originalOrderId && order.original_order_id !== originalOrderId) {
        return null;
    }
    const title = isOriginal ? 'Remove linked refabrication' : 'Mark as not refabrication';
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={title}
            content={
                <Grid container>
                    <QuickForm<{ reason: string }>
                        fields={{ reason: { type: 'text', label: 'Reason for unlinking' } }}
                        initialValues={{}}
                        onSubmit={async result => await submit(result.reason)}
                    />
                </Grid>
            }
            buttonText={title}
            buttonProps={{ style: props.controls ? { display: 'none' } : {} }}
            CustomButton={hideButton ? () => null : undefined}
        />
    );
};
