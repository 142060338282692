import { GrayBooleanIcon } from '../BillingDetails/components/GrayBooleanIcon';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlPartnerBillingFragment } from '@orthly/graphql-operations';
import { LabsGqlPartnerBillingBoolean } from '@orthly/graphql-schema';
import { QuickForm, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { FlossPalette, Icon, IconButton, Text } from '@orthly/ui-primitives';
import React from 'react';

interface EditBillingFlagProps {
    practiceId: string;
    currentValue:
        | LabsGqlPartnerBillingFragment['autocharge_enabled']
        | LabsGqlPartnerBillingFragment['is_on_payment_plan'];
    property: LabsGqlPartnerBillingBoolean.AutochargeEnabled | LabsGqlPartnerBillingBoolean.IsOnPaymentPlan;
    refetch: () => void;
}

const flagPropertyToLabel: Record<EditBillingFlagProps['property'], string> = {
    [LabsGqlPartnerBillingBoolean.AutochargeEnabled]: 'Auto Charge Enabled',
    [LabsGqlPartnerBillingBoolean.IsOnPaymentPlan]: 'Is on Payment Plan',
};

const BulkEditBillingFlag_Mutation = graphql(`
    mutation BulkUpdatePartnerBillingConfigFlag($update: PartnerBillingBulkUpdateFlagCommand!) {
        bulkUpdatePartnerBillingConfigFlag(update: $update) {
            id
        }
    }
`);

export const EditBillingFlag: React.FC<EditBillingFlagProps> = ({ practiceId, currentValue, property, refetch }) => {
    const [open, setOpen] = React.useState(false);
    const editBillingFlag = useMutation(BulkEditBillingFlag_Mutation);
    const { submit, submitting } = useRootActionCommand(editBillingFlag, {
        onSuccess: () => {
            refetch();
            setOpen(false);
        },
    });

    const label = flagPropertyToLabel[property];

    const CustomButton = React.useCallback(
        () => (
            <Text sx={{ display: 'flex', alignItems: 'center' }}>
                <GrayBooleanIcon val={currentValue} />
                <IconButton onClick={() => setOpen(true)}>
                    <Icon
                        icon={'PencilOutlinedIcon'}
                        fontSize={'small'}
                        sx={{ color: FlossPalette.PRIMARY_FOREGROUND, minWidth: '24px' }}
                    />
                </IconButton>
            </Text>
        ),
        [currentValue, setOpen],
    );

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={`Edit Billing Account`}
            buttonText={''}
            CustomButton={CustomButton}
            content={
                <QuickForm<{ value: boolean }>
                    // TODO: update fe-libs to allow passing `defaultChecked`.
                    // @ts-expect-error: ts(2353)
                    fields={{ value: { label, type: 'boolean', fieldProps: { defaultChecked: currentValue } } }}
                    initialValues={{ value: currentValue }}
                    onSubmit={async result => {
                        await submit({ update: { property, partner_ids: [practiceId], new_value: result.value } });
                    }}
                    dirtySubmitOnly={true}
                />
            }
        />
    );
};
