import { Grid, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

export interface SidebarDividerProps {
    borderColor?: string;
}

export const SidebarDivider: React.FC<SidebarDividerProps> = props => {
    return (
        <Grid container style={{ padding: '8px 24px', height: 0 }}>
            <Grid container style={{ borderBottom: `1px solid ${props.borderColor ?? FlossPalette.DARK_TAN}` }} />
        </Grid>
    );
};
SidebarDivider.displayName = 'SidebarDivider';
