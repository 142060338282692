import { useFeatureFlag } from '../../../../Providers/LaunchDarkly';
import { useGuidedWaxupContext } from '../../state/GuidedWaxupContext';
import { LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';

/**
 * Utility hook to determine if an order is eligible to have a live design review performed.
 * This is currently defined by the PRD here:
 *     https://docs.google.com/document/d/10M0iZFLrTE5MQJj7ErAJpX61rceLGw-IWmdwQxCvlyY/edit?disco=AAABYYG9WOE
 */
export function useIsOrderEligibleForLiveDesignReview() {
    const { order } = useGuidedWaxupContext();

    const { value: isEnabled } = useFeatureFlag('liveDdpReviewsEntrypointEnabled');

    // This user isn't enabled for the pilot AT ALL.
    if (!isEnabled) {
        return false;
    }

    // Only DDC-designed orders are eligible for this service.
    if (!order.fulfillment_workflow.configuration.internal_design_required) {
        return false;
    }

    const parsedItems = OrderItemV2Utils.parseItems(order.items_v2);
    const allowedSkus: LabOrderItemSKUType[] = [
        LabOrderItemSKUType.Crown,
        LabOrderItemSKUType.Bridge,
        LabOrderItemSKUType.Veneer,
        LabOrderItemSKUType.Model,
        LabOrderItemSKUType.Waxup,
        LabOrderItemSKUType.Other,
    ];
    return parsedItems.every(item => {
        // The item isn't one of the allowed SKUs, so clearly fails our test.
        if (!OrderItemV2Utils.itemIsType(item, allowedSkus)) {
            return false;
        }

        // Other items are allowed _IF_ they are Reduction Copings, Pink Porcelain, or Die Only.
        // Everything else fails our test.
        if (
            OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Other) &&
            item.unit.unit_type !== 'Reduction Coping' &&
            item.unit.unit_type !== 'Pink Porcelain' &&
            item.unit.unit_type !== 'Die Only'
        ) {
            return false;
        }

        // IF you haven't failed by now, you succeed.
        return true;
    });
}
