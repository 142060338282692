import type { MUITableState } from './TableStateContext';
import { useTableStateContext } from './TableStateContext';

export interface TypedUseSelectorHook<TState> {
    <TSelected>(
        selector: (state: TState) => TSelected,
        equalityFn?: (left: TSelected, right: TSelected) => boolean,
    ): TSelected;
}

export const useMuiTableSelector: TypedUseSelectorHook<MUITableState> = selector => {
    const context = useTableStateContext();
    return selector(context.state);
};

export function useMuiTablePropSelector<K extends keyof MUITableState>(props: K[]): Pick<MUITableState, K> {
    return useMuiTableSelector(state => {
        return props.reduce(
            (result, prop) => {
                result[prop] = state[prop];
                return result;
            },
            {} as Pick<MUITableState, K>,
        );
    });
}

export * from './action-hooks';
