/**
 * NOTE: this file was originally created in the `Scanner` repo.
 * The extended history can be found here:
 * https://github.com/orthly/Scanner/commits/477bb65b8709136bbcfed64770ef66eb52307d8a/packages/fluoride/src/components/common/Gallery
 * Moved for https://meetdandy.atlassian.net/browse/EPDCHAIR-3938
 */
import { RecommendationHeader, useHelpIconWithAnchorEl } from '.';
import type { GridSize, GridDirection, TooltipProps } from '@orthly/ui-primitives';
import {
    FlossPalette,
    Text,
    stylesFactory,
    ButtonBase,
    CheckboxPrimitive as Checkbox,
    Grid,
    RadioPrimitive as Radio,
    Tooltip,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

export interface GalleryCardStyleFields {
    gridWidth: GridSize;
    buttonGridDirection: GridDirection;
    imageGridSize?: GridSize;
    textGridSize?: GridSize;
}

export enum GalleryCardSizeVariant {
    XLargeCard = 'XLargeCard',
    LargeCard = 'LargeCard',
    MediumCard = 'MediumCard',
    SmallCard = 'SmallCard',
    XSmallCard = 'XSmallCard',
    XXSmallCard = 'XXSmallCard',
    WideCard = 'WideCard',
    MobileCard = 'MobileCard',
}

const GalleryCardVariantFields: { [key in GalleryCardSizeVariant]: GalleryCardStyleFields } = {
    XLargeCard: {
        gridWidth: 6,
        buttonGridDirection: 'column',
    },
    LargeCard: {
        gridWidth: 4,
        buttonGridDirection: 'column',
    },
    MediumCard: {
        gridWidth: 6,
        buttonGridDirection: 'row',
        imageGridSize: 6,
        textGridSize: 6,
    },
    SmallCard: {
        gridWidth: 4,
        buttonGridDirection: 'row',
        imageGridSize: 6,
        textGridSize: 6,
    },
    XSmallCard: {
        gridWidth: 3,
        buttonGridDirection: 'column',
    },
    XXSmallCard: {
        gridWidth: 2,
        buttonGridDirection: 'column',
    },
    WideCard: {
        gridWidth: 6,
        buttonGridDirection: 'row',
    },
    MobileCard: {
        gridWidth: 12,
        buttonGridDirection: 'row',
    },
};

const useStyles = stylesFactory(() => ({
    card: {
        backgroundColor: FlossPalette.TAN,
        borderRadius: 16,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        '&:hover': {
            borderColor: FlossPalette.STROKE_DARK,
        },
        textAlign: 'left',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
    },
    XLargeCard: {
        height: 376,
        '& $textContentWrapper': {
            flexBasis: 56,
        },
        '& $helpIcon': {
            alignSelf: 'flex-start',
        },
    },
    LargeCard: {
        height: 376,
        '& $helpIcon': {
            alignSelf: 'flex-start',
        },
    },
    MediumCard: {
        height: 176,
        '& $textContentWrapper': {
            alignSelf: 'center',
        },
        '& $imageWrapper': {
            paddingRight: 0,
        },
    },
    SmallCard: {
        height: 176,
        '& $textContentWrapper': {
            alignSelf: 'center',
        },
        '& $imageWrapper': {
            paddingRight: 0,
        },
    },
    XSmallCard: {
        height: 176,
        '& $textContentWrapper': {
            alignItems: 'center',
        },
    },
    XXSmallCard: {
        height: 176,
        '& $textContentWrapper': {
            flexBasis: 70,
            flexGrow: 0,
        },
    },
    WideCard: {
        height: 104,
        '& $textContentWrapper': {
            justifyContent: 'center',
        },
        '& $selectIcon': {
            top: 20,
        },
        '& $imageWrapper': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 60,
            paddingRight: 0,
        },
    },
    MobileCard: {
        height: 104,
        justifyContent: 'flex-start',
        '& $textContentWrapper': {
            justifyContent: 'center',
        },
        '& $selectIcon': {
            top: 20,
        },
        '& $imageWrapper': {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 60,
            paddingRight: 0,
            flexGrow: 0,
        },
        '& $textWrapper': {
            width: 'auto',
            flexGrow: 0,
        },
        '& $buttonContent': {
            justifyContent: 'flex-start',
        },
    },
    disabledCard: {
        opacity: 0.6,
    },
    selectedCard: {
        borderColor: FlossPalette.PRIMARY_FOREGROUND,
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        '&:hover': {
            borderColor: FlossPalette.PRIMARY_FOREGROUND,
        },
    },
    badge: {
        width: 24,
        height: 24,
        position: 'absolute',
        top: 8,
        right: 8,
        borderRadius: 8,
        backgroundColor: FlossPalette.SECONDARY_FOREGROUND,
        textAlign: 'center',
    },
    selectIcon: {
        width: 24,
        height: 24,
        position: 'absolute',
        top: 8,
        left: 8,
        borderRadius: 8,
    },
    muiRadioRoot: {
        '&.MuiRadio-root svg': {
            border: '1px solid',
        },
    },
    helpIcon: {
        color: FlossPalette.GRAY,
        alignSelf: 'center',
        paddingLeft: 8,
    },
    buttonContent: {
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
    },
    imageWrapper: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // The minHeight and minWidth fields are needed so the image will scale down appropriately.
        minHeight: 0,
        minWidth: 0,
        padding: 24,
    },
    image: {
        width: '100%',
        height: '100%',
        // We don't want to stretch the image if the box is bigger than image source, but we do want to scale it down.
        objectFit: 'scale-down',
    },
    textWrapper: {
        padding: 24,
    },
    textContentWrapper: {
        flexGrow: 1,
    },
}));

interface GalleryCardProps {
    image?: string;
    imageBackgroundColor?: string;
    title: string | React.ReactNode;
    sizeVariant: GalleryCardSizeVariant;
    selectVariant?: 'none' | 'single' | 'multi';
    description?: string | React.ReactElement;
    recommended?: boolean;
    recommendedCustomText?: string;
    onClick?: () => void;
    selected?: boolean;
    disabled?: boolean;
    hoverImage?: string;
    badge?: number;
    helpIcon?: boolean;
    TooltipProps?: Partial<TooltipProps> & Pick<TooltipProps, 'title'>;
    styleOverrides?: React.CSSProperties;
    variantStyleOverrides?: GalleryCardStyleFields;
}

export type GalleryCardOptions = Omit<GalleryCardProps, 'sizeVariant' | 'styleOverrides'>;

/**
 * This component is designed for consumption by the `Gallery` component,
 * and shouldn't be used outside of that component.
 **/
export const GalleryCard: React.VFC<GalleryCardProps> = ({
    image,
    imageBackgroundColor,
    title,
    sizeVariant,
    selectVariant,
    description,
    recommended,
    recommendedCustomText,
    onClick,
    selected,
    disabled,
    hoverImage,
    badge,
    helpIcon,
    TooltipProps,
    styleOverrides,
    variantStyleOverrides,
}) => {
    const sizingFields = variantStyleOverrides ?? GalleryCardVariantFields[sizeVariant];

    const classes = useStyles(sizingFields);
    const [hovering, setHovering] = React.useState(false);
    const { helpIconComponent, helpAnchorEl } = useHelpIconWithAnchorEl();

    const button = (
        <Grid item xs={sizingFields.gridWidth}>
            <ButtonBase
                className={cx(classes.card, classes[sizeVariant], { [classes.selectedCard]: selected })}
                classes={{ disabled: classes.disabledCard }}
                style={styleOverrides}
                onClick={onClick}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => setHovering(false)}
                disabled={disabled}
                data-test={'gallery-card'}
                data-test-recommended={`${recommended}`}
            >
                {recommended && (
                    <RecommendationHeader
                        styleOverrides={{ width: 'calc(100% + 4px)' }}
                        customText={recommendedCustomText}
                    />
                )}
                <Grid
                    container
                    direction={sizingFields.buttonGridDirection}
                    className={classes.buttonContent}
                    style={{ height: recommended ? 'calc(100% - 19px)' : '100%', width: '100%' }}
                >
                    <Grid
                        item
                        className={classes.imageWrapper}
                        xs={sizingFields.imageGridSize}
                        style={{ background: imageBackgroundColor }}
                    >
                        {image && <img className={classes.image} src={hovering && hoverImage ? hoverImage : image} />}
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent={sizeVariant === GalleryCardSizeVariant.XSmallCard ? 'center' : 'space-between'}
                        direction={'row'}
                        wrap={'nowrap'}
                        xs={sizingFields.textGridSize}
                        className={classes.textWrapper}
                    >
                        <Grid
                            item
                            container
                            direction={'column'}
                            className={classes.textContentWrapper}
                            wrap={'nowrap'}
                            justifyContent={'flex-end'}
                        >
                            <Grid item data-test={'gallery-card-title'}>
                                {typeof title === 'string' ? (
                                    <Text variant={'body2'} medium color={selected ? 'STAR_GRASS' : 'BLACK'}>
                                        {title}
                                    </Text>
                                ) : (
                                    title
                                )}
                            </Grid>
                            {sizeVariant !== GalleryCardSizeVariant.XSmallCard && (
                                <Grid item data-test={'gallery-card-description'}>
                                    {typeof description === 'string' ? (
                                        <Text variant={'body2'} color={'GRAY'}>
                                            {description}
                                        </Text>
                                    ) : (
                                        description
                                    )}
                                </Grid>
                            )}
                        </Grid>
                        {helpIcon && (
                            <Grid item className={classes.helpIcon}>
                                {helpIconComponent}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {badge !== undefined && (
                    <div className={classes.badge} data-test={'select-card-badge'}>
                        <Text variant={'body2'} medium color={'WHITE'}>
                            {badge}
                        </Text>
                    </div>
                )}
                {selectVariant !== 'none' && (
                    <div className={classes.selectIcon} style={{ marginTop: recommended ? 24 : 'initial' }}>
                        {selectVariant === 'multi' ? (
                            <Checkbox
                                checked={!!selected}
                                style={{
                                    backgroundColor: !selected ? FlossPalette.WHITE : FlossPalette.PRIMARY_BACKGROUND,
                                }}
                                color={'secondary'}
                            />
                        ) : (
                            <Radio
                                color={'secondary'}
                                className={classes.muiRadioRoot}
                                checked={!!selected}
                                style={{
                                    backgroundColor: !selected ? FlossPalette.WHITE : FlossPalette.PRIMARY_BACKGROUND,
                                }}
                            />
                        )}
                    </div>
                )}
            </ButtonBase>
        </Grid>
    );

    const popperProps = helpIcon ? { anchorEl: helpAnchorEl } : {};
    return TooltipProps ? (
        <Tooltip arrow placement={'bottom'} PopperProps={popperProps} {...TooltipProps}>
            {button}
        </Tooltip>
    ) : (
        button
    );
};
