import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import { useListDisplayRuleOptsQuery } from '@orthly/graphql-react';
import { LabsGqlLabPriceRuleType, LabsGqlLabPriceToothPositionClass } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';

export const UnitForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    const { data: displayOpts } = useListDisplayRuleOptsQuery({ fetchPolicy: 'cache-first' });
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.Unit>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.Unit>({
                unit_type: {
                    type: 'select',
                    label: 'Unit Type',
                    options: ['Crown', 'CrownPontic', 'Veneer', 'Inlay', 'Wing'],
                },
                material: {
                    type: 'select',
                    label: 'Material',
                    options: displayOpts?.listDisplayRuleOptions.material_types ?? [],
                },
                tooth_position_class: {
                    type: 'select',
                    label: 'Tooth Position Class',
                    options: Object.values(LabsGqlLabPriceToothPositionClass),
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          unit_type: price.rule.__typename === 'LabPriceUnitRuleDTO' ? price.rule.unit_type : null,
                          material: price.rule.__typename === 'LabPriceUnitRuleDTO' ? price.rule.material : null,
                          tooth_position_class:
                              price.rule.__typename === 'LabPriceUnitRuleDTO' ? price.rule.tooth_position_class : null,
                      }
                    : {}
            }
            onSubmit={async result => {
                await submit({
                    name: result.name,
                    rule: {
                        type: LabsGqlLabPriceRuleType.Unit,
                        unit_type: result.unit_type || null,
                        material: result.material || null,
                        tooth_position_class: result.tooth_position_class || null,
                    },
                });
            }}
            disabled={disabled}
        />
    );
};
