import type { ColumnSortState, MUITableState, ColumnFilterState, ColumnFilterMod } from '../state/TableStateContext';
import type { Column, MUIDataObj, MUITableMetaRow, Row, MUITableFilterType, Cell } from '../types';
import { Icon } from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

type FilterStateWithColumn = ColumnFilterState & { column: Column<any> };

export class MUITableUtils {
    private static getDateRenderValue(result: React.ReactNode | Date | object, column: Column<any>): string | null {
        if (typeof column.render === 'function') {
            return null;
        }
        if (column.type === 'date' || column.type === 'datetime' || result instanceof Date) {
            const dateVal = result instanceof Date || typeof result === 'string' ? moment(result) : null;
            if (dateVal) {
                const format = column.dateFormat ?? (column.type === 'datetime' ? 'MM/DD/YY h:mm a' : 'MM/DD/YY');
                return dateVal.format(format);
            }
        }
        return null;
    }
    private static cleanRenderValue(result: React.ReactNode | Date | object, column: Column<any>) {
        const dateRenderValue = MUITableUtils.getDateRenderValue(result, column);
        if (dateRenderValue) {
            return dateRenderValue;
        }
        if (column.type === 'boolean' && typeof result === 'boolean') {
            return !result ? (
                <Icon icon={'CloseIcon'} color={'error'} />
            ) : (
                <Icon icon={'CheckIcon'} color={'inherit'} style={{ color: '#08C28B' }} />
            );
        }
        return result ?? null;
    }
    static getCellRenderValue<R extends MUIDataObj>(row: Row<R>, column: Column<R>): React.ReactNode {
        if (typeof column.render === 'function') {
            return MUITableUtils.cleanRenderValue(column.render(row), column);
        }
        const value = _.get(row, column.render);
        if (column.type === 'boolean') {
            return !value ? (
                <Icon icon={'CloseIcon'} color={'error'} />
            ) : (
                <Icon icon={'CheckIcon'} color={'inherit'} style={{ color: '#08C28B' }} />
            );
        }
        if (column.type === 'currency' && (typeof value === 'string' || typeof value === 'number')) {
            const dollars = typeof value === 'string' ? parseInt(value) / 100 : value / 100;
            return dollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }
        return MUITableUtils.cleanRenderValue(value, column);
    }
    static getCellAsString(row: Row<any>, column: Column<any>, forExport: boolean = false): string | undefined {
        if (column.field) {
            const fieldRes = typeof column.field === 'string' ? _.get(row, column.field) : column.field(row);
            return MUITableUtils.renderValueToString(fieldRes, forExport);
        }
        return MUITableUtils.renderValueToString(MUITableUtils.getCellRenderValue(row, column), forExport);
    }
    static getCellFieldValue(row: Row<any>, column: Column<any>) {
        if (column.field) {
            return typeof column.field === 'string' ? _.get(row, column.field) : column.field(row);
        }
        if (typeof column.render === 'string') {
            return _.get(row, column.render);
        }
        return undefined;
    }
    static cellToPrimitive(row: Row<any>, column: Column<any>): string | number | boolean | undefined {
        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
        // eslint-disable-next-line no-nested-ternary
        const renderValue = column.field
            ? typeof column.field === 'string'
                ? _.get(row, column.field)
                : column.field(row)
            : typeof column.render === 'function'
              ? column.render(row)
              : _.get(row, column.render);
        if (renderValue instanceof Date) {
            return renderValue.toJSON();
        }
        switch (typeof renderValue) {
            case 'string':
            case 'number':
            case 'boolean':
                return renderValue;
            case 'bigint':
            case 'function':
            case 'object':
                return renderValue?.toString();
            case 'symbol':
                return renderValue.description;
            case 'undefined':
                return undefined;
        }
    }
    static renderValueToString(renderValue: React.ReactNode, forExport: boolean = false): string | undefined {
        if (renderValue === null || renderValue === undefined) {
            return '';
        }
        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
        // eslint-disable-next-line no-nested-ternary
        return renderValue instanceof Date
            ? forExport
                ? renderValue.toLocaleString()
                : renderValue.toJSON()
            : typeof renderValue === 'string'
              ? renderValue
              : String(renderValue);
    }
    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    static sortRows = (rows: MUITableMetaRow<any>[], columns: Column<any>[], sortColumn?: ColumnSortState) => {
        if (!sortColumn) {
            return rows;
        }
        const direction = sortColumn.asc ? 'asc' : 'desc';
        const column = columns.find(c => c.name === sortColumn?.columnName);
        if (!column) {
            return rows;
        }
        const customSort = column.customSort;
        if (customSort) {
            return [...rows].sort((a, b) => {
                return customSort(a.row, b.row, direction);
            });
        }
        return [...rows].sort((a, b) => {
            const dir = direction === 'desc' ? -1 : 1;
            const aCell = a.cells.find(cell => cell.columnName === column.name);
            const bCell = b.cells.find(cell => cell.columnName === column.name);
            const aPrimitive = MUITableUtils.cellToPrimitive(a.row, column);
            const bPrimitive = MUITableUtils.cellToPrimitive(b.row, column);
            const aSortVal = aCell?.stringValue || '';
            const bSortVal = bCell?.stringValue || '';
            switch (column.type) {
                case 'boolean':
                    return (aPrimitive === bPrimitive ? 1 : -1) * dir;
                case 'currency':
                case 'numeric':
                    return ((aPrimitive || 0) > (bPrimitive || 0) ? 1 : -1) * dir;
                case 'date':
                case 'datetime':
                    return (moment(aSortVal).valueOf() - moment(bSortVal).valueOf()) * dir;
                case 'time':
                case 'string':
                default:
                    if (moment(aSortVal).isValid() || moment(bSortVal).isValid()) {
                        return (moment(aSortVal).valueOf() - moment(bSortVal).valueOf()) * dir;
                    }
                    if (!isNaN(parseFloat(aSortVal)) || !isNaN(parseFloat(bSortVal))) {
                        return (parseFloat(aSortVal) > parseFloat(bSortVal) ? -1 : 1) * dir;
                    }
                    return (aSortVal.toLowerCase() > bSortVal.toLowerCase() ? -1 : 1) * dir;
            }
        });
    };
    static searchFilterOk = (
        row: MUITableMetaRow<any>,
        columns: Column<any>[],
        tableState: { searchText: string | null; searchOpen: boolean },
    ) => {
        if (!tableState.searchText || !tableState.searchOpen) {
            return true;
        }
        const searchText = tableState.searchText.trim().toLowerCase();
        const rowText = columns
            .map(c => row.cells.find(cell => cell.columnName === c.name)?.stringValue || '')
            .join(' ')
            .toLowerCase();
        return rowText.toLowerCase().indexOf(searchText) >= 0;
    };
    static filterRows = (
        rows: MUITableMetaRow[],
        tableState: Pick<MUITableState, 'columnFilters' | 'searchOpen' | 'searchText'>,
        columns: Column<any>[],
    ) => {
        const filterStates = tableState.columnFilters.flatMap<FilterStateWithColumn>(filterState => {
            if (filterState.filterValues.length === 0) {
                return [];
            }
            const column = columns.find(c => c.name === filterState.columnName);
            return column ? { ...filterState, column } : [];
        });
        if (
            filterStates.length <= 0 &&
            (!tableState.searchText || tableState.searchText.trim().length === 0 || !tableState.searchOpen)
        ) {
            return rows;
        }
        return rows.filter(metaRow => {
            if (!MUITableUtils.searchFilterOk(metaRow, columns, tableState)) {
                return false;
            }
            const failingFilterForRow = filterStates.find(f => !MUITableUtils.cellPassesFilter(metaRow, f));
            return !failingFilterForRow;
        });
    };

    static cellPassesFilter(metaRow: MUITableMetaRow, filterState: FilterStateWithColumn) {
        const { column, filterValues, mod } = filterState;
        if (filterValues.length <= 0) {
            return true;
        }
        if (column.customFilterFn) {
            try {
                const customFilterOk = column.customFilterFn(filterValues, metaRow.row);
                return customFilterOk === true;
            } catch (e: any) {
                console.error(e);
            }
        }
        const cell = metaRow.cellForColumn(column);
        if (!cell) {
            return true;
        }
        const filterType = MUITableUtils.filterTypeForColumn(column);
        if (!filterType) {
            return true;
        }
        if (filterType === 'date') {
            const dateValue = filterValues[0];
            if (!dateValue || !moment(dateValue).isValid()) {
                return true;
            }
            return MUITableUtils.dateFilterValuePasses(cell, moment(dateValue), mod);
        }
        const cellPrimitive = MUITableUtils.cellToPrimitive(metaRow.row, column)?.toString() ?? '';
        if (column.filterOptions?.exact) {
            return filterValues.includes(cell.stringValue ?? '') || filterValues.includes(cellPrimitive);
        }
        // Find one of the selected filter values that the cell passes for
        const passingFilterVal = filterValues.find(filterVal => {
            const booleanPasses =
                column.type === 'boolean' && (!!this.getCellFieldValue(metaRow.row, column)).toString() === filterVal;
            const cellIncludesFilterVal = cell.stringValue?.toLowerCase().includes(filterVal.toLowerCase());
            const primitiveMatches = cellPrimitive === filterVal;
            return cellIncludesFilterVal || primitiveMatches || booleanPasses;
        });
        return !!passingFilterVal;
    }

    private static dateFilterValuePasses(cell: Cell, dateFilterValue: moment.Moment, mod: ColumnFilterMod): boolean {
        const cellStringValue = cell.stringValue;
        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
        // eslint-disable-next-line no-nested-ternary
        const cellDateValue = !cellStringValue
            ? undefined
            : moment(cellStringValue).isValid()
              ? moment(cellStringValue)
              : undefined;
        if (!cellDateValue) {
            return false;
        }
        switch (mod) {
            case '<':
                return cellDateValue.isBefore(dateFilterValue, 'day');
            case '>':
                return cellDateValue.isAfter(dateFilterValue, 'day');
            case '=':
                return cellDateValue.isSame(dateFilterValue, 'day');
        }
    }

    static filterTypeForColumn(column: Column<any>): MUITableFilterType | undefined {
        if (column.filter === false) {
            return undefined;
        }
        if (column.filterOptions?.type) {
            return column.filterOptions.type;
        }
        switch (column.type) {
            case 'date':
            case 'datetime':
                return 'date';
            case 'boolean':
                return 'checkbox';
            case 'numeric':
            case 'currency':
                return 'number';
            case 'time':
            case 'string':
            default:
                return 'search';
        }
    }
}
