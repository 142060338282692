import { PracticeIcon } from '@orthly/ui';
import { stylesFactory, Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    subSection: {
        marginBottom: '0.25rem',
    },
    subSectionTitle: {
        fontSize: '1rem !important',
        fontWeight: 'bold',
    },
    item: {
        fontSize: '0.75rem !important',
        whiteSpace: 'pre-line',
    },
}));

/**
 * LabSlip section for displaying notes regarding CST
 * @param clincialSupportNotes The clinical_support_notes property of an Order
 * @returns CstNotes section
 */
export const CstNotes: React.VFC<{ clincialSupportNotes: string | null }> = ({ clincialSupportNotes }) => {
    const classes = useStyles();

    if (clincialSupportNotes === null) {
        return null;
    }

    return (
        <Grid container className={classes.subSection}>
            <Grid container spacing={1}>
                <Grid item>
                    <PracticeIcon />
                </Grid>
                <Grid item>
                    <Text variant={'h5'} className={classes.subSectionTitle}>
                        Clinical Support Notes
                    </Text>
                </Grid>
            </Grid>
            <Grid container>
                <Text variant={'body1'} className={classes.item}>
                    {clincialSupportNotes}
                </Text>
            </Grid>
        </Grid>
    );
};
