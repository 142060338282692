import { useFirebaseStorage } from '../../context';
import { validateStlFile } from '../../util/StlValidator';
import { FirebaseFileListItem, SimpleDropzone } from '../FirebaseUpload';
import { useActivityCounter } from './ActivityCounter';
import type { IOrderItemV2DTO } from '@orthly/items';
import { DenturesProductionType, ItemCheckerUtils, OrderItemArch, OrderItemModelType } from '@orthly/items';
import { FileNameUtils } from '@orthly/runtime-utils';
import type { BucketStoragePathConfig } from '@orthly/shared-types';
import { StackY } from '@orthly/ui';
import { List, styled, Text } from '@orthly/ui-primitives';
import Firebase from 'firebase/compat/app';
import { useSnackbar } from 'notistack';
import React from 'react';

export interface RemovableFileFields {
    device_upper_stl?: string | null;
    device_lower_stl?: string | null;
    model_upper_stl?: string | null;
    model_lower_stl?: string | null;
    trimline_upper_pts?: string | null;
    trimline_lower_pts?: string | null;
}

/**
 * Returns the contents of the file as an ArrayBuffer, gzipped if the
 * file extension is .stl, .ply, or .json.
 */
async function maybeGzipFile(file: File): Promise<{ content: ArrayBuffer; gzipped: boolean }> {
    let content: ArrayBuffer;
    const gzipped = !!file.name.match(/\.(stl|ply|json)/i);
    if (gzipped) {
        const compressedStream = file.stream().pipeThrough(new CompressionStream('gzip'));
        content = await new Response(compressedStream).arrayBuffer();
    } else {
        content = await file.arrayBuffer();
    }
    return {
        content,
        gzipped,
    };
}

/**
 * Uploads a single file to storage, reporting progress.
 * Compresses the .stl, .ply, and .json files with gzip.
 */
function useSingleFileUpload(args: {
    storagePathConfig: BucketStoragePathConfig;
    onComplete: (uploadedPath: string | undefined) => void;
    onError: (error: unknown) => void;
}) {
    const {
        storagePathConfig: { bucketName, path },
        onComplete,
        onError,
    } = args;

    const snackbar = useSnackbar();
    const firebaseStorage = useFirebaseStorage(bucketName);
    const { activityCounter } = useActivityCounter();
    const [progress, setProgress] = React.useState<number | undefined>();
    const uploadTaskRef = React.useRef<Firebase.storage.UploadTask | undefined>();

    const cancelUpload = React.useCallback(() => {
        uploadTaskRef.current?.cancel();
        setProgress(undefined);
    }, [setProgress, uploadTaskRef]);

    const startUpload: (file: File) => unknown = async (file: File) => {
        try {
            activityCounter?.increment();
            setProgress(0);

            const { content, gzipped } = await maybeGzipFile(file);
            const fileRef = firebaseStorage.ref(`${path}/${file.name}`);
            const uploadTask = fileRef.put(content, {
                contentType: FileNameUtils.getContentType(file.name),
                contentEncoding: gzipped ? 'gzip' : undefined,
            });
            uploadTaskRef.current = uploadTask;
            uploadTask.on(Firebase.storage.TaskEvent.STATE_CHANGED, snapshot => {
                setProgress((100 * snapshot.bytesTransferred) / snapshot.totalBytes);
            });
            await uploadTask;

            setProgress(100);
            onComplete(fileRef.fullPath);
        } catch (error) {
            setProgress(undefined);
            console.error('Error uploading file', { file: file.name, error });
            snackbar.enqueueSnackbar(`Error uploading ${file.name}. Check console for details.`, {
                variant: 'error',
            });
            onError(error);
        } finally {
            activityCounter?.decrement();
        }
    };

    return {
        uploading: progress !== undefined && progress < 100,
        progress,
        startUpload,
        cancelUpload,
    };
}

const ACCEPT_STL = { 'model/stl': ['.stl'], 'application/stl': ['.stl'] };
const ACCEPT_PTS = { 'application/octet-stream': ['.pts'] };

const FieldLabel = styled(Text)({
    fontWeight: 500,
    paddingBottom: 8,
});
const UploaderContainer = styled(StackY)({ marginBottom: 16 });
const FileList = styled(List)({ padding: 0, borderBottom: '1px solid #eee' });

const Uploader: React.VFC<{
    label: string;
    fileType: 'STL' | 'PTS';
    storagePathConfig: BucketStoragePathConfig;
    onComplete: (uploadedPath: string | undefined) => void;
    initialPath?: string | null;
}> = ({ label, fileType, storagePathConfig, onComplete, initialPath }) => {
    const snackbar = useSnackbar();
    const [file, setFile] = React.useState<File | undefined | null>(undefined);
    const { progress, startUpload, cancelUpload } = useSingleFileUpload({
        storagePathConfig,
        onComplete,
        onError: () => setFile(null),
    });

    const initialFileName = initialPath?.split('/').pop();

    let fileOrMetadata: File | { name: string } | null | undefined;
    if (file !== undefined) {
        fileOrMetadata = file;
    } else if (initialFileName) {
        fileOrMetadata = { name: initialFileName };
    }

    const accept = fileType === 'STL' ? ACCEPT_STL : ACCEPT_PTS;
    const validateFile = fileType === 'STL' ? validateStlFile : undefined;

    return (
        <UploaderContainer>
            <FieldLabel variant={'body2'}>{label}</FieldLabel>
            {fileOrMetadata ? (
                <FileList dense>
                    <FirebaseFileListItem
                        key={`${fileOrMetadata.name}`}
                        file={fileOrMetadata}
                        completed={false}
                        onRemove={() => {
                            cancelUpload();
                            setFile(null);
                            onComplete(undefined);
                        }}
                        progress={progress}
                    />
                </FileList>
            ) : (
                <SimpleDropzone
                    options={{
                        accept,
                        multiple: false,
                        onDropRejected: rejections => {
                            if (rejections[0]?.errors[0]?.code === 'file-invalid-type') {
                                snackbar.enqueueSnackbar(`The file must be a .${fileType}`, {
                                    variant: 'error',
                                });
                            }
                        },
                        onDropAccepted: async ([file]) => {
                            if (!file) {
                                return;
                            }
                            if (validateFile) {
                                const validationResult = await validateFile(file);
                                if (!validationResult.isValid) {
                                    snackbar.enqueueSnackbar(validationResult.errorMessage, {
                                        variant: 'error',
                                    });
                                    return;
                                }
                            }
                            setFile(file);
                            startUpload(file);
                        },
                    }}
                />
            )}
        </UploaderContainer>
    );
};

function isDentureTryInWithArch(item: IOrderItemV2DTO, arch: OrderItemArch): boolean {
    return (
        ItemCheckerUtils.isDentureItem(item) &&
        item.denture_production_type === DenturesProductionType.TryIn &&
        (item.unit.arch === OrderItemArch.Dual || item.unit.arch === arch)
    );
}

function is3DPrintedNightguardWithArch(item: IOrderItemV2DTO, arch: OrderItemArch): boolean {
    return (
        ItemCheckerUtils.isNightGuard(item) &&
        item.unit.material === 'Hard / Soft 3D Printed' &&
        (item.unit.arch === OrderItemArch.Dual || item.unit.arch === arch)
    );
}

function isThermoformItemWithArch(item: IOrderItemV2DTO, arch: OrderItemArch): boolean {
    return (
        ItemCheckerUtils.isThermoformItem(item) && (item.unit.arch === OrderItemArch.Dual || item.unit.arch === arch)
    );
}

// Returns true if there is a dual-arch model OR a single-arch model and an item on `arch`.
function hasModelOnArch(items: IOrderItemV2DTO[], arch: OrderItemArch) {
    const hasItemOnArch = items.some(item => 'unit' in item && 'arch' in item.unit && item.unit.arch === arch);
    return items.some(
        item =>
            ItemCheckerUtils.isModelItem(item) &&
            (item.model_type === OrderItemModelType.DualFullArch ||
                item.model_type === OrderItemModelType.DualQuadrant ||
                hasItemOnArch),
    );
}

interface FieldDefinition {
    label: string;
    fileType: 'STL' | 'PTS';
    fieldName: keyof RemovableFileFields;
    showWhen: (orderItems: IOrderItemV2DTO[]) => boolean;
}

const UPLOAD_FIELDS: FieldDefinition[] = [
    // Accept device STLs for full dentures and 3D-printed night guards.
    {
        label: 'Device upper STL',
        fileType: 'STL',
        fieldName: 'device_upper_stl',
        showWhen: items =>
            items.some(
                item =>
                    isDentureTryInWithArch(item, OrderItemArch.Upper) ||
                    is3DPrintedNightguardWithArch(item, OrderItemArch.Upper),
            ),
    },
    {
        label: 'Device lower STL',
        fileType: 'STL',
        fieldName: 'device_lower_stl',
        showWhen: items =>
            items.some(
                item =>
                    isDentureTryInWithArch(item, OrderItemArch.Lower) ||
                    is3DPrintedNightguardWithArch(item, OrderItemArch.Lower),
            ),
    },
    // Accept model STLs for partial dentures, thermoform items, and model items.
    // For thermoform night guards, the lab always needs models for both arches
    // in order to check occlusion.
    {
        label: 'Model upper STL',
        fileType: 'STL',
        fieldName: 'model_upper_stl',
        showWhen: items =>
            hasModelOnArch(items, OrderItemArch.Upper) ||
            items.some(
                item =>
                    ItemCheckerUtils.isPartialDenture(item) ||
                    ItemCheckerUtils.isThermoformNightGuard(item) ||
                    isThermoformItemWithArch(item, OrderItemArch.Upper),
            ),
    },
    {
        label: 'Model lower STL',
        fileType: 'STL',
        fieldName: 'model_lower_stl',
        showWhen: items =>
            hasModelOnArch(items, OrderItemArch.Lower) ||
            items.some(
                item =>
                    ItemCheckerUtils.isPartialDenture(item) ||
                    ItemCheckerUtils.isThermoformNightGuard(item) ||
                    isThermoformItemWithArch(item, OrderItemArch.Lower),
            ),
    },
    // Accept trim line files for thermoform items.
    {
        label: 'Trimline upper PTS',
        fileType: 'PTS',
        fieldName: 'trimline_upper_pts',
        showWhen: items => items.some(item => isThermoformItemWithArch(item, OrderItemArch.Upper)),
    },
    {
        label: 'Trimline lower PTS',
        fileType: 'PTS',
        fieldName: 'trimline_lower_pts',
        showWhen: items => items.some(item => isThermoformItemWithArch(item, OrderItemArch.Lower)),
    },
];

/**
 * Conditionally allows for upload of various types of files for
 * manufacturing night guards, full dentures, and partial dentures.
 */
export const RemovableFilesUploadFields: React.VFC<{
    orderItems: IOrderItemV2DTO[];
    storagePathConfig: BucketStoragePathConfig;
    updateField: <T extends keyof RemovableFileFields>(field: T, value: string | undefined) => void;
    initialFields?: RemovableFileFields;
}> = ({ orderItems, storagePathConfig, initialFields, updateField }) => {
    if (
        !orderItems.some(
            item =>
                ItemCheckerUtils.isRemoveableItem(item) ||
                ItemCheckerUtils.isDentureItem(item) ||
                ItemCheckerUtils.isPartialDenture(item),
        )
    ) {
        return null;
    }

    return (
        <>
            {UPLOAD_FIELDS.map(
                fieldDef =>
                    fieldDef.showWhen(orderItems) && (
                        <Uploader
                            key={fieldDef.fieldName}
                            label={fieldDef.label}
                            fileType={fieldDef.fileType}
                            storagePathConfig={storagePathConfig}
                            onComplete={uploadedPath => updateField(fieldDef.fieldName, uploadedPath)}
                            initialPath={initialFields?.[fieldDef.fieldName]}
                        />
                    ),
            )}
        </>
    );
};
