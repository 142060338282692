import { UpdateSpacing, validateSpacing } from './UpdateSpacing';
import { UpdateTeethSelection, validateTeethSelectionValidation } from './UpdateTeethSelection';
import type { LabsGqlOrder, LabsGqlEditAlignerCaseMutationVariables } from '@orthly/graphql-operations';
import { useEditAlignerCaseMutation } from '@orthly/graphql-react';
import {
    LabsGqlAlignerArch,
    LabsGqlAlignerTreatmentArea,
    LabsGqlCrowdingResolutionOption,
    LabsGqlBiteConcernOptions,
} from '@orthly/graphql-schema';
import { omitDeep } from '@orthly/runtime-utils';
import type { SimpleSelectOption } from '@orthly/ui';
import { QuickForm, RootActionDialog, useChangeSubmissionFn, PencilOutlinedIcon } from '@orthly/ui';
import { IconButton } from '@orthly/ui-primitives';
import React from 'react';

type Vars = Omit<LabsGqlEditAlignerCaseMutationVariables['data'], 'order_id'>;

interface EditAlignerCaseActionProps {
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
}

const crowdingResolutionOptions: SimpleSelectOption[] = [
    { value: LabsGqlCrowdingResolutionOption.OnlyIfNeeded, label: 'Only if needed' },
    { value: LabsGqlCrowdingResolutionOption.No, label: 'No' },
];

const biteConcernsOptions: SimpleSelectOption[] = [
    { value: LabsGqlBiteConcernOptions.ShowOutcome, label: 'Show outcome' },
    { value: LabsGqlBiteConcernOptions.Improve, label: 'Improve' },
];

export const EditAlignerCaseAction: React.FC<EditAlignerCaseActionProps> = props => {
    const { order, refetchOrder } = props;
    const alignerCase = order.aligner_case && omitDeep(order.aligner_case, '__typename');
    const [submitMtn] = useEditAlignerCaseMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data: { ...data, order_id: order.id } } });
    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Aligner case updated!', {}],
        onSuccess: async () => {
            await refetchOrder();
        },
    });
    const openButton = React.useMemo(() => {
        return (
            <IconButton onClick={() => setOpen(true)} style={{ padding: 8 }}>
                <PencilOutlinedIcon />
            </IconButton>
        );
    }, [setOpen]);
    if (!alignerCase) {
        return null;
    }

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Edit Aligner Case Details'}
            showCloseButton={true}
            content={
                <QuickForm<Vars>
                    fields={{
                        aligner_arch: {
                            type: 'select',
                            label: 'Aligner Arch',
                            options: [LabsGqlAlignerArch.Dual, LabsGqlAlignerArch.Upper, LabsGqlAlignerArch.Lower],
                        },
                        aligner_treatment_area: {
                            type: 'select',
                            label: 'Aligner Treatment Area',
                            options: [LabsGqlAlignerTreatmentArea.FullArch, LabsGqlAlignerTreatmentArea.AnteriorOnly],
                        },
                        movement_restricted_teeth: {
                            type: 'custom',
                            fieldProps: { label: 'Movement Restricted Teeth (comma separated)' },
                            component: UpdateTeethSelection,
                            validation: validateTeethSelectionValidation,
                        },
                        attachment_restricted_teeth: {
                            type: 'custom',
                            fieldProps: { label: 'Attachment Restricted Teeth (comma separated)' },
                            component: UpdateTeethSelection,
                            validation: validateTeethSelectionValidation,
                        },
                        extraction_teeth: {
                            type: 'custom',
                            fieldProps: {
                                label: 'Extractions (comma separated)',
                            },
                            component: UpdateTeethSelection,
                            validation: validateTeethSelectionValidation,
                        },
                        interproximal_space_sizes: {
                            type: 'custom',
                            fieldProps: {
                                label: 'Spacing',
                            },
                            component: UpdateSpacing,
                            validation: validateSpacing,
                        },
                        crowding_resolution: {
                            type: 'nested',
                            label: 'Crowding Resolution',
                            fields: {
                                expansion: {
                                    type: 'radioGroup',
                                    label: 'Expansion',
                                    options: crowdingResolutionOptions,
                                },
                                proclination: {
                                    type: 'radioGroup',
                                    label: 'Proclination',
                                    options: crowdingResolutionOptions,
                                },
                                ipr: {
                                    type: 'radioGroup',
                                    label: 'IPR',
                                    options: crowdingResolutionOptions,
                                },
                            },
                        },
                        bite_concerns: {
                            type: 'nested',
                            label: 'Bite Concerns',
                            fields: {
                                improve_overjet: {
                                    type: 'radioGroup',
                                    label: 'Improve Overjet',
                                    options: biteConcernsOptions,
                                },
                                improve_overbite: {
                                    type: 'radioGroup',
                                    label: 'Improve Overbite',
                                    options: biteConcernsOptions,
                                },
                                improve_midline: {
                                    type: 'radioGroup',
                                    label: 'Improve Midline',
                                    options: biteConcernsOptions,
                                },
                            },
                        },
                        aligner_notes: {
                            type: 'text',
                            label: 'Notes',
                            optional: true,
                            fieldProps: { multiline: true },
                        },
                    }}
                    initialValues={{
                        aligner_arch: alignerCase.aligner_arch,
                        aligner_treatment_area: alignerCase.aligner_treatment_area,
                        movement_restricted_teeth: alignerCase.movement_restricted_teeth,
                        attachment_restricted_teeth: alignerCase.attachment_restricted_teeth,
                        interproximal_space_sizes: alignerCase.interproximal_space_sizes,
                        crowding_resolution: alignerCase.crowding_resolution,
                        extraction_teeth: alignerCase.extraction_teeth,
                        bite_concerns: alignerCase.bite_concerns,
                        aligner_notes: alignerCase.aligner_notes,
                    }}
                    onSubmit={async (result, actions) => {
                        await submit({ ...result });
                        actions.setSubmitting(false);
                    }}
                />
            }
            style={{ padding: 0 }}
            buttonText={'Edit Aligner Case'}
            buttonProps={{ style: { height: '100%' } }}
            CustomButton={() => openButton}
        />
    );
};
