import { useViewManager } from '../../AppState.hooks';
import { LabeledButton } from './LabeledButton';
import { ToolbarContainer } from './ToolbarContainer';
import { ToolbarLabel } from './ToolbarLabel';
import type { IViewManager } from '@orthly/dentin';
import type { BoxProps } from '@orthly/ui-primitives';
import React from 'react';

interface ToothViewsToolbarProps extends BoxProps {
    viewManager?: IViewManager;
}

export const ToothViewsToolbar: React.VFC<ToothViewsToolbarProps> = ({ viewManager: viewManagerIn, ...boxProps }) => {
    const defaultViewManager = useViewManager();
    const viewManager = viewManagerIn ?? defaultViewManager;

    return (
        <ToolbarContainer {...boxProps}>
            <ToolbarLabel text={'Tooth-views:'} />
            <LabeledButton onClick={() => viewManager.setMesialCameraView()} tooltip={'Mesial view'} label={'Mesial'} />
            <LabeledButton onClick={() => viewManager.setDistalCameraView()} tooltip={'Distal view'} label={'Distal'} />
            <LabeledButton onClick={() => viewManager.setFacialCameraView()} tooltip={'Facial view'} label={'Facial'} />
            <LabeledButton
                onClick={() => viewManager.setLingualCameraView()}
                tooltip={'Lingual view'}
                label={'Lingual'}
            />
            <LabeledButton
                onClick={() => viewManager.setInsertionCameraView()}
                tooltip={'Insertion direction view'}
                label={'POI'}
            />
        </ToolbarContainer>
    );
};
