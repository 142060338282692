import type { MUIDataObj } from '../types';
import type { IconProps } from '@orthly/ui-primitives';
import React from 'react';

interface BaseAction {
    disabled?: boolean;
    icon: string | React.ComponentType;
    position: 'toolbar' | 'toolbarOnSelect' | 'row';
    tooltip?: string;
    iconProps?: IconProps;
    hidden?: boolean;
}
export interface GlobalAction extends BaseAction {
    position: 'toolbar';
    onClick: () => void;
}
export interface BulkSelectedAction<R extends MUIDataObj> extends BaseAction {
    position: 'toolbarOnSelect';
    onClick: (selectedRows: R[]) => void;
}
export interface RowAction<R extends MUIDataObj> extends BaseAction {
    position: 'row';
    onClick: (row: R) => void;
}

export interface ActionsProp<R extends MUIDataObj> {
    row?: RowAction<R>[];
    selectedRows?: BulkSelectedAction<R>[];
    global?: GlobalAction[];
}

const CustomActionsContext = React.createContext<ActionsProp<any>>({});

interface Props<R extends MUIDataObj> {
    children: React.ReactNode;
    actions?: ActionsProp<R>;
}

export function CustomActionsContextProvider<R extends MUIDataObj>(props: Props<R>) {
    return <CustomActionsContext.Provider value={{ ...props.actions }}>{props.children}</CustomActionsContext.Provider>;
}

export function useCustomActionsContext() {
    return React.useContext(CustomActionsContext);
}
