import { PrintTable } from '../../../components/PrintTable';
import type { LabsGqlDisplayRuleFragment } from '@orthly/graphql-operations';
import type { LabsGqlCustomFieldDisplayRuleInput } from '@orthly/graphql-schema';
import { LabsGqlDisplayRuleTypeEnum } from '@orthly/graphql-schema';
import { ConfirmationButton, LoadBlocker } from '@orthly/ui';
import { FlossPalette, Chip, Grid, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const TitleForRuleType = (ruleType?: LabsGqlDisplayRuleTypeEnum): string => {
    switch (ruleType) {
        case LabsGqlDisplayRuleTypeEnum.ReturnRules:
            return 'Return';
        case LabsGqlDisplayRuleTypeEnum.DisplayRules:
        default:
            return 'Display';
    }
};

const DecisionVerbForRuleType = (ruleType?: LabsGqlDisplayRuleTypeEnum, customVerb?: string): [string, string] => {
    const capVerb = customVerb?.toUpperCase();
    if (!!capVerb) {
        return [`DON'T ${capVerb}`, capVerb];
    }
    switch (ruleType) {
        case LabsGqlDisplayRuleTypeEnum.ReturnRules:
            return ["DON'T RETURN", 'RETURN'];
        case LabsGqlDisplayRuleTypeEnum.DisplayRules:
        default:
            return ['HIDE', 'SHOW'];
    }
};

interface DisplayRuleSummaryTextProps {
    rule: Partial<LabsGqlCustomFieldDisplayRuleInput>;
    ruleType?: LabsGqlDisplayRuleTypeEnum;
    customVerb?: string;
}

export const DisplayRuleSummaryText: React.FC<DisplayRuleSummaryTextProps> = props => {
    const verbs = DecisionVerbForRuleType(props.ruleType, props.customVerb);
    const color = props.rule.display_if_passes ? FlossPalette.GREEN : FlossPalette.ATTENTION;
    const outcome = (
        <span style={{ color, fontWeight: 'bold' }}>{props.rule.display_if_passes ? verbs[1] : verbs[0]}</span>
    );
    const values = props.rule.applicable_values || [];
    return (
        <Grid container>
            <Text variant={'body1'}>
                {outcome} IF scan item's <b>{_.startCase(props.rule.property)}</b> is one of:
            </Text>
            <Grid container style={{ background: FlossPalette.TAN, padding: 3 }}>
                {values.map(val => (
                    <Chip label={val} key={val} style={{ margin: 2 }} />
                ))}
            </Grid>
        </Grid>
    );
};

interface DisplayRulesTableProps {
    // The name of the element whose visibility is determined by this rule.
    elementName: string;
    deleteRule: (rule_id: string) => void;
    deleteLoading: boolean;
    rules: LabsGqlDisplayRuleFragment[];
    ruleType: LabsGqlDisplayRuleTypeEnum;
    rulesPassWhenEmpty: boolean;
    showTestRules?: boolean;
}

export const DisplayRulesTable: React.FC<DisplayRulesTableProps> = props => {
    const title = TitleForRuleType(props.ruleType);
    return (
        <LoadBlocker blocking={props.deleteLoading}>
            <PrintTable
                title={`${title} Rules`}
                rows={props.rules.map(rule => {
                    return {
                        name: rule.name,
                        summary: <DisplayRuleSummaryText rule={rule} ruleType={props.ruleType} />,
                        delete: (
                            <ConfirmationButton
                                needsConfirmation={true}
                                confirmLabel={'Delete Rule'}
                                initialText={'Delete Rule'}
                                submitText={'Delete Rule'}
                                submitOnClick={() => {
                                    props.deleteRule(rule.id);
                                }}
                            />
                        ),
                    };
                })}
            />
        </LoadBlocker>
    );
};
