import { GUIDED_WAXUP_TOOLBAR_HEIGHT_DESKTOP, GUIDED_WAXUP_TOOLBAR_HEIGHT_MOBILE } from './VisualConstants.util';
import { useGuidedWaxupContext } from './state/GuidedWaxupContext';
import { CurrentWaxupToolbarDesktop } from './toolbars/CurrentWaxupToolbarDesktop';
import { CurrentWaxupToolbarMobile } from './toolbars/CurrentWaxupToolbarMobile';
import { HistoricalWaxupsToolbarDesktop } from './toolbars/HistoricalWaxupToolbarDesktop';
import { HistoricalWaxupsToolbarMobile } from './toolbars/HistoricalWaxupToolbarMobile';
import { ToolbarContainer } from '@orthly/ui';
import { FlossPalette, stylesFactory, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

const useToolbarStyles = stylesFactory(theme => ({
    toolbarContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        padding: '12px 24px 0px 24px',
        alignItems: 'center',
        height: GUIDED_WAXUP_TOOLBAR_HEIGHT_DESKTOP,
        [theme.breakpoints.down('lg')]: {
            height: GUIDED_WAXUP_TOOLBAR_HEIGHT_MOBILE,
            background: FlossPalette.TAN,
            alignContent: 'space-between',
        },
    },
}));

const CurrentWaxupToolbarWrapper: React.VFC = () => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    return isMobile ? <CurrentWaxupToolbarMobile /> : <CurrentWaxupToolbarDesktop />;
};

const HistoricalWaxupsToolbarWrapper: React.VFC = () => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    return isMobile ? <HistoricalWaxupsToolbarMobile /> : <HistoricalWaxupsToolbarDesktop />;
};

export const GuidedWaxupToolbarWrapper: React.VFC = () => {
    const classes = useToolbarStyles();
    const { selectedDesignRevisionAlreadyReviewed } = useGuidedWaxupContext();

    return (
        <ToolbarContainer sticky className={classes.toolbarContainer}>
            {selectedDesignRevisionAlreadyReviewed ? (
                <HistoricalWaxupsToolbarWrapper />
            ) : (
                <CurrentWaxupToolbarWrapper />
            )}
        </ToolbarContainer>
    );
};
