import {
    ORDER_DETAIL_LIST_ITEM_HEIGHT,
    OrderDetailSidebarItemLoading,
} from '../../../../components/OrderDetailSidebar/OrderDetailSidebarItemLayout';
import { OrderListItemStatusTracker } from '../../../../components/OrderListItem/OrderListItemStatusTracker';
import { useTrackLinkClick } from '../../PalateMetrics';
import { usePalateState } from '../../state/Palate.reducer';
import { usePalateStyles } from '../../usePalateStyles';
import { MultiProductImageWrapper } from '@orthly/dentin';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrderIdsByListView } from '@orthly/graphql-react';
import {
    LabsGqlLabOrderStatus,
    LabsGqlFilterComparator as Comparator,
    LabsGqlLabOrderSortKey,
} from '@orthly/graphql-schema';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { OrderFilterIdEnum } from '@orthly/shared-types';
import { LoadBlocker } from '@orthly/ui';
import { Text, Grid } from '@orthly/ui-primitives';
import { getOrderTrackerProps, OrdersVirtualList, useVirtualizedListOrders } from '@orthly/veneer';
import clsx from 'clsx';
import React from 'react';

interface InFlightOrderDetailsProps {
    listItemContent?: LabsGqlOrder;
}

interface InFlightOrdersProps {
    practiceId: string;
}

const InFlightOrderDetails: React.VFC<InFlightOrderDetailsProps> = props => {
    const classes = usePalateStyles();
    const { listItemContent: order } = props;
    const trackLinkClick = useTrackLinkClick(order?.partner_id ?? '');
    if (!order) {
        return <OrderDetailSidebarItemLoading />;
    }
    const items = OrderItemV2Utils.parseItems(order.items_v2);
    const products = items.map(item => CartItemV2Utils.getProductUnitType(item));
    const firstItemLabel = items[0] ? CartItemV2Utils.getDisplayName(items[0]) : 'Other';
    return (
        <Grid
            container
            // required for virtualized list to lay out correctly
            style={{ height: ORDER_DETAIL_LIST_ITEM_HEIGHT }}
            wrap={'nowrap'}
            // put the border on all of them
            className={clsx(classes.order, classes.orderBorder)}
            alignItems={'center'}
            onClick={() => {
                const orderLink = `orders/${order.id}`;
                trackLinkClick(orderLink);
                window.open(orderLink);
            }}
        >
            <Grid item style={{ paddingRight: 8 }}>
                <MultiProductImageWrapper products={products} direction={'column'} maxNumOrbs={3} />
            </Grid>
            <Grid container style={{ paddingBottom: 4 }}>
                <Text variant={'caption'} medium style={{ paddingBottom: 8 }}>{`${order.patient.first_name} ${
                    order.patient.last_name
                }'s ${firstItemLabel.toLowerCase()}`}</Text>
                <OrderListItemStatusTracker {...getOrderTrackerProps(order)} variant={'caption'} filledWarningIcon />
            </Grid>
        </Grid>
    );
};

function useOffsetTop() {
    // store this in state so that it doesn't start changing when the list is scrolled
    const [top, setTop] = React.useState<number>();
    const topRef = React.useRef<HTMLDivElement>(null);
    const refTop = topRef.current?.getBoundingClientRect().top;
    React.useEffect(() => {
        if (!top && refTop) {
            setTop(refTop);
        }
    }, [top, refTop]);

    // account for the top section of Palate
    return { offsetTop: top ?? 188, topRef };
}

export const InFlightOrders: React.VFC<InFlightOrdersProps> = props => {
    const classes = usePalateStyles();
    const orderFilters = usePalateState(s => s.orderFilters);
    const { ids: orderIds, loading } = useOrderIdsByListView(
        {
            sort: { key: LabsGqlLabOrderSortKey.DueDate, asc: true },
            filter: {
                excluded_statuses: [
                    LabsGqlLabOrderStatus.Cancelled,
                    LabsGqlLabOrderStatus.NeedsRefabrication,
                    LabsGqlLabOrderStatus.Delivered,
                ],
            },
            criteria: [
                {
                    filter_id: OrderFilterIdEnum.by_partner,
                    comparison_value: props.practiceId,
                    comparator: Comparator.Equals,
                },
                ...(orderFilters ?? []),
            ],
        },
        {
            fetchPolicy: 'cache-first',
        },
    );
    const { topRef, offsetTop } = useOffsetTop();
    const { idsLoading, onItemsRendered, orders, listHeight, ids, startIndex } = useVirtualizedListOrders({
        orderIds,
        itemHeight: ORDER_DETAIL_LIST_ITEM_HEIGHT,
        idsLoading: loading,
        orderToListItemContent: o => o,
        toolbarHeight: offsetTop,
    });

    return (
        <LoadBlocker blocking={loading}>
            <Text className={classes.sectionHeader} variant={'body2'} medium style={{ height: 'fit-content' }}>
                In-flight orders
            </Text>
            <Grid container ref={topRef}>
                <OrdersVirtualList<LabsGqlOrder>
                    listItemHeight={ORDER_DETAIL_LIST_ITEM_HEIGHT}
                    ListItem={InFlightOrderDetails}
                    idsLoading={idsLoading}
                    onItemsRendered={onItemsRendered}
                    orders={orders}
                    listHeight={listHeight}
                    ids={ids}
                    startIndex={startIndex}
                    orderToListItemContent={o => o}
                />
            </Grid>
        </LoadBlocker>
    );
};
