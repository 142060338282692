import { JsonDevTools } from '../../components/JsonDevTools';
import { CreateProductCatalogEntryForm } from './ProductCatalogForms/Actions/CreateProductCatalogEntryForm';
import { EditProductCatalogEntryForm } from './ProductCatalogForms/Actions/EditProductCatalogEntryForm';
import { EditProductCatalogSalesforceMappingForm } from './ProductCatalogForms/Actions/EditProductCatalogSalesforceMappingForm';
import type { LabsGqlLabPriceDefinitionResultFragment } from '@orthly/graphql-operations';
import { useGetLabPriceDefinitionsQuery, useToggleLabPriceDeletionMutation } from '@orthly/graphql-react';
import { MUITable } from '@orthly/mui-table';
import { LoadBlocker } from '@orthly/ui';
import { Button, Grid } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

interface ProductCatalogDetailPanelProps {
    price: LabsGqlLabPriceDefinitionResultFragment;
    disabled: boolean;
    onSuccess?: () => Promise<void>;
}

const DeleteProductCatalogEntryBtn: React.FC<ProductCatalogDetailPanelProps> = props => {
    const { refetch: refetchPrices } = useGetLabPriceDefinitionsQuery();
    const { price } = props;
    const [submitMtn, mtnState] = useToggleLabPriceDeletionMutation({
        variables: { data: { lab_price_id: price.id } },
    });
    const onClick = React.useCallback(async () => {
        const labCount = `There are currently ${price.labs_configured} lab(s) with a configured price`;
        if (!mtnState.loading && window.confirm(`Delete product catalog entry ${price.name} (${labCount})?`)) {
            await submitMtn();
            await refetchPrices();
        }
    }, [mtnState.loading, price, submitMtn, refetchPrices]);
    return (
        <LoadBlocker blocking={mtnState.loading}>
            <Button startIcon={'DeleteIcon'} onClick={onClick} variant={'alert'} fullWidth>
                Delete Product Catalog Entry
            </Button>
        </LoadBlocker>
    );
};

const ProductCatalogDetailPanel: React.FC<ProductCatalogDetailPanelProps> = props => {
    const { price, onSuccess, disabled } = props;
    return (
        <Grid container alignItems={'flex-start'} style={{ maxWidth: 'calc(100vw - 64px)' }}>
            <Grid container item xs={8} style={{ padding: 20, gap: 8 }}>
                <EditProductCatalogEntryForm price={price} onSuccess={onSuccess} disabled={disabled} />
                {!disabled && <DeleteProductCatalogEntryBtn {...props} />}
            </Grid>
            <Grid container item xs={4} style={{ padding: 20 }}>
                {!disabled && <EditProductCatalogSalesforceMappingForm price={price} />}
                <Grid container item>
                    <JsonDevTools src={price} dataType={'LabPriceDTO'} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export const ProductCatalogTable: React.VFC = () => {
    const { value: disableContractEdits = false } = useFeatureFlag('disableContractEdits');
    const { data, loading, refetch } = useGetLabPriceDefinitionsQuery();
    const [createOpen, setCreateOpen] = React.useState<boolean>(false);
    const prices = data?.getLabPriceDefinitions ?? [];
    return (
        <Grid container>
            <MUITable<LabsGqlLabPriceDefinitionResultFragment>
                title={'Product Catalog'}
                data={prices}
                loading={loading}
                displayOptions={{ fixedSearch: true, elevation: 0, viewColumns: true, filter: true, sort: true }}
                actions={{
                    global: [
                        { icon: 'refresh', position: 'toolbar', onClick: () => refetch().catch(console.error) },
                        { icon: 'add', position: 'toolbar', onClick: () => setCreateOpen(true), tooltip: 'Create' },
                    ],
                }}
                rowOptions={{ rowHover: true }}
                eventHooks={{ onRowClick: (row, actions) => actions.toggleDetailPanel(row) }}
                columns={[
                    { name: 'Name', field: 'name', render: row => row.name, defaultSort: 'asc' },
                    { name: 'Type', field: 'type', render: row => row.rule.type },
                    {
                        name: 'Labs Configured',
                        field: 'labs_configured',
                        render: row => (row.enabled_for_labs ? row.labs_configured : '-'),
                    },
                    {
                        name: 'Partners Configured',
                        field: 'partners_configured',
                        render: row => (row.enabled_for_practices ? row.partners_configured : '-'),
                    },
                ]}
                DetailPanel={detailProps => {
                    const disabled = disableContractEdits && Boolean(detailProps.data.enabled_for_practices);

                    return (
                        <ProductCatalogDetailPanel
                            price={detailProps.data}
                            onSuccess={async () => {
                                await refetch();
                            }}
                            disabled={disabled}
                        />
                    );
                }}
            />
            <CreateProductCatalogEntryForm
                setOpen={setCreateOpen}
                open={createOpen}
                onSuccess={async () => {
                    await refetch();
                    setCreateOpen(false);
                }}
                disabled={disableContractEdits}
            />
        </Grid>
    );
};
