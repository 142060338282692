import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import type { ICartItemV2DTO } from '@orthly/items';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import { isEqualOnProps } from '@orthly/runtime-utils';
import _ from 'lodash';

/** Returns true if the preference fields of the two items are equivalent. */
export function preferencesMatch(first: ICartItemV2DTO, second: ICartItemV2DTO): boolean {
    return isEqualOnProps(first.preference_fields, second.preference_fields, ['field_id', 'value']);
}

/** Returns true if the shades of two items are equivalent. */
export function shadesMatch(first: ICartItemV2DTO, second: ICartItemV2DTO): boolean {
    return isEqualOnProps(first.shades, second.shades, ['name', 'value']);
}

/** Returns true if the units of two items are equivalent. */
export function unitsMatch(first: ICartItemV2DTO, second: ICartItemV2DTO): boolean {
    return isEqualOnProps(CartItemV2Utils.getSingleToothUnits(first), CartItemV2Utils.getSingleToothUnits(second), [
        'unn',
        'unit_type',
        'material',
    ]);
}

/** Returns true if the preferences, shades, and units of the two items are equivalent. */
export function itemsAreEqual(first: ICartItemV2DTO, second: ICartItemV2DTO): boolean {
    // With the item v2 editor, there are more changes possible than just prefs, shades or units, so we use to _.isEqual to compare the 2 objects.
    // It's not perfect as it could say 2 items with the same properties in a different array order would be different, but it's good enough for our use case.
    return _.isEqual(first, second);
}

/**
 * Returns true if both items are single-unit items with equivalent material, shade, and preferences.
 * Currently implemented for Crown, Inlay, and Veneer SKUs.
 */
function itemsAreMergeable(first: ICartItemV2DTO, second: ICartItemV2DTO): boolean {
    if (first.sku !== second.sku) {
        return false;
    }
    const singleToothUnitItemSkus = [
        LabOrderItemSKUType.Crown,
        LabOrderItemSKUType.Inlay,
        LabOrderItemSKUType.Veneer,
    ] as const;

    if (
        CartItemV2Utils.itemIsType(first, singleToothUnitItemSkus) &&
        CartItemV2Utils.itemIsType(second, singleToothUnitItemSkus)
    ) {
        return (
            first.unit.material === second.unit.material &&
            first.unit.unit_type === second.unit.unit_type &&
            preferencesMatch(first, second) &&
            shadesMatch(first, second)
        );
    }
    // TODO: Add more mergeable unit types as needed.
    return false;
}

/**
 * Combines similar single-unit items into groups. Items are considered mergeable
 * when they have equivalent materials, shades, and preferences.
 *
 * `mayMerge` is an optional function that can be provided to selectively disable
 * grouping for each item. If the function returns `false` for an item, that item
 * will not be grouped.
 */
export function mergeItems<T extends ICartItemV2DTO>(
    items: T[],
    options: { mayMerge?: (item: T) => boolean } = {},
): ArrayMin1<T>[] {
    const { mayMerge = () => true } = options;

    const merged: ArrayMin1<T>[] = [];
    for (const candidateItem of items) {
        if (!mayMerge(candidateItem)) {
            merged.push([candidateItem]);
            continue;
        }
        const group = merged.find(([groupItem]) => mayMerge(groupItem) && itemsAreMergeable(groupItem, candidateItem));
        if (group) {
            group.push(candidateItem);
        } else {
            merged.push([candidateItem]);
        }
    }
    return merged;
}
