import type { OrderFilterBuilderRootProps } from '../../components/OrderFilterBuilder/OrderFilterBuilderRoot';
import type { OrdersToolbarPopperContentProps } from '../../components/OrdersListToolbar/OrdersToolbarPopper';
import { OrdersToolbarPopper } from '../../components/OrdersListToolbar/OrdersToolbarPopper';
import type { UnsavedPalateFilterCriteria } from './PalateFilter.types';
import { PracticeTab } from './PalatePracticeRoot.types';
import type { PalateFilterDefinition } from './components/FilterComponents';
import { FilterOptions } from './components/FilterComponents';
import type { PalateFilterCriteria } from './state/Palate.actions';
import { usePalateAction } from './state/Palate.actions';
import { usePalateState } from './state/Palate.reducer';
import { useListPalateOrderFiltersQuery } from '@orthly/graphql-react';
import { LabsGqlFilterComparator } from '@orthly/graphql-schema';
import { TrashIcon, LoadBlocker, SimpleInput } from '@orthly/ui';
import {
    FlossPalette,
    Button,
    Text,
    stylesFactory,
    Grid,
    IconButton,
    CheckIcon,
    CloseIcon,
    Icon,
    SearchIcon,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    filtersFooterContainer: {
        top: 432,
        left: 8,
        height: 64,
        position: 'fixed',
        zIndex: 1000,
        background: FlossPalette.WHITE,
        width: 424,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
        padding: '8px 24px',
        borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
    },
    inputRoot: {
        padding: '0 0 6px',
        borderRadius: 8,
        background: 'transparent',
        border: `1px solid ${FlossPalette.GRAY}`,
        '&.MuiInputBase-root': {
            '&:hover': {
                '&::before': {
                    border: 'none',
                },
            },
            '&.Mui-focused': {
                boxShadow: `0px 0px 0px 4px ${FlossPalette.STAR_GRASS_HOVER}`,
                border: `1px solid ${FlossPalette.STAR_GRASS}`,
                '&::after': {
                    borderBottom: 'none',
                },
            },
            '&.MuiInput-underline': {
                '&::after': {
                    transition: 'none',
                },
            },
        },
    },
}));

interface FiltersPopoverContentProps {
    setChanged: () => void;
    changed: boolean;
    tab: PracticeTab;
    practiceId: string;
}

interface PalateOrderFilterBuilderRootProps extends Omit<OrderFilterBuilderRootProps, 'setCriterias'> {
    criterias: UnsavedPalateFilterCriteria[];
    setCriterias: (criteriaValues: UnsavedPalateFilterCriteria[]) => void;
    tab: PracticeTab;
    practiceId: string;
}

interface PalateFilterProps {
    practiceId: string;
    tab: PracticeTab;
}

function isCompletedPalateFilterCriteria(criteria: UnsavedPalateFilterCriteria): criteria is PalateFilterCriteria {
    return !!criteria.comparator && !!criteria.filter_id;
}

const PalateOrderFilterBuilderRoot: React.FC<PalateOrderFilterBuilderRootProps> = props => {
    const classes = useStyles();
    const { tab, filtersQuery, criterias, setCriterias, changed, setChanged } = props;
    const [openSearch, setOpenSearch] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const filterDefinitions = React.useMemo<PalateFilterDefinition[]>(() => {
        const filters =
            filtersQuery.data?.rules?.map<PalateFilterDefinition>(r => ({ ...r, __typename: 'FilterDefinition' })) ??
            [];
        if (tab === PracticeTab.Timeline) {
            return filters.filter(filter => filter.id !== 'status' && filter.id !== 'by_doctor');
        }
        return filters;
    }, [tab, filtersQuery.data?.rules]);

    React.useEffect(() => {
        if (criterias.length === 0 && filterDefinitions.length > 0) {
            setCriterias(
                filterDefinitions.map(filterDef => ({
                    filter_id: filterDef.id,
                    comparator: LabsGqlFilterComparator.OneOf,
                    comparison_value: [],
                })),
            );
        }
    }, [criterias.length, filterDefinitions, setCriterias]);
    return (
        <LoadBlocker blocking={filtersQuery.loading && filterDefinitions.length === 0}>
            <Grid container style={{ justifyContent: 'space-between', height: 40 }}>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    Filters
                </Text>
                {!openSearch ? (
                    <IconButton onClick={() => setOpenSearch(true)} style={{ padding: 0, height: 24 }}>
                        <SearchIcon style={{ color: FlossPalette.GRAY }} />
                    </IconButton>
                ) : (
                    <Grid item alignItems={'center'} style={{ display: 'flex', marginTop: -12 }}>
                        <SimpleInput
                            label={''}
                            value={search}
                            onChange={value => setSearch(value ?? '')}
                            placeholder={'Search'}
                            TextFieldProps={{
                                style: { width: 160 },
                                InputProps: {
                                    className: classes.inputRoot,
                                },
                            }}
                        />
                        <IconButton
                            onClick={() => {
                                setSearch('');
                                setOpenSearch(false);
                            }}
                            style={{ padding: 4, marginLeft: 4 }}
                        >
                            <CloseIcon style={{ color: FlossPalette.GRAY, height: 20, width: 20 }} />
                        </IconButton>
                    </Grid>
                )}
            </Grid>
            {/* set autoExpand to true if a search value has been set and it is in the list of options */}
            {filterDefinitions.map((filterDef, idx) => (
                <FilterOptions
                    key={filterDef.id}
                    filter={filterDef}
                    criteriaIdx={idx}
                    criterias={criterias}
                    setCriterias={setCriterias}
                    changed={changed}
                    setChanged={setChanged}
                    search={search}
                    practiceId={props.practiceId}
                />
            ))}
            <Grid item className={classes.filtersFooterContainer}>
                {props.actionsBlock ?? null}
            </Grid>
        </LoadBlocker>
    );
};

const FiltersPopoverContent: React.FC<OrdersToolbarPopperContentProps & FiltersPopoverContentProps> = props => {
    const { tab, setOpen, setChanged, changed } = props;
    const filtersQuery = useListPalateOrderFiltersQuery({ variables: { partnerId: props.practiceId } });
    const setCriteria = usePalateAction(tab === PracticeTab.Timeline ? 'SET_TIMELINE_FILTERS' : 'SET_ORDER_FILTERS');
    const resetCriteria = usePalateAction(
        tab === PracticeTab.Timeline ? 'CLEAR_TIMELINE_FILTERS' : 'CLEAR_ORDER_FILTERS',
    );
    const existingCriteria = usePalateState(s => (tab === PracticeTab.Timeline ? s.timelineFilters : s.orderFilters));
    const [criterias, setCriterias] = React.useState<UnsavedPalateFilterCriteria[]>(existingCriteria ?? [{}]);
    const completedCriterias = React.useMemo(() => criterias.filter(isCompletedPalateFilterCriteria), [criterias]);
    const onApply = React.useCallback(() => {
        setCriteria(completedCriterias);
        setOpen(false);
    }, [completedCriterias, setCriteria, setOpen]);
    const onReset = React.useCallback(() => {
        resetCriteria();
        setOpen(false);
    }, [resetCriteria, setOpen]);
    return (
        <PalateOrderFilterBuilderRoot
            changed={changed}
            setChanged={setChanged}
            criterias={criterias}
            filtersQuery={filtersQuery}
            setCriterias={setCriterias}
            tab={tab}
            practiceId={props.practiceId}
            actionsBlock={
                <>
                    <Grid container justifyContent={'space-between'}>
                        <Button
                            disabled={!existingCriteria || existingCriteria.length === 0}
                            variant={'ghost'}
                            onClick={onReset}
                        >
                            <TrashIcon style={{ paddingRight: 4 }} />
                            Reset
                        </Button>
                        <Grid item>
                            <Button disabled={!props.changed} variant={'ghost'} onClick={() => props.setOpen(false)}>
                                Cancel
                            </Button>
                            <Button
                                disabled={!props.changed}
                                variant={'primary'}
                                onClick={onApply}
                                style={{ marginLeft: 8 }}
                            >
                                Apply <CheckIcon style={{ height: 20 }} />
                            </Button>
                        </Grid>
                    </Grid>
                </>
            }
        />
    );
};

export const PalateFilter: React.FC<PalateFilterProps> = props => {
    const { practiceId, tab } = props;
    const [changed, setChanged] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const existingCriteria = usePalateState(s => (tab === PracticeTab.Timeline ? s.timelineFilters : s.orderFilters));
    const filterCount = existingCriteria?.flatMap(criteria => criteria.comparison_value).length;
    const popperSetOpen = React.useCallback(
        (newOpen: boolean, isBackgroundClick: boolean) => {
            if (!newOpen && changed) {
                if (isBackgroundClick && !window.confirm('Abandon changes?')) {
                    return;
                }
                setChanged(false);
            }
            setOpen(newOpen);
        },
        [changed],
    );

    return (
        <OrdersToolbarPopper<FiltersPopoverContentProps>
            ContentProps={{ tab, practiceId, changed, setChanged: () => setChanged(true) }}
            PopoverContent={FiltersPopoverContent}
            open={open}
            setOpen={popperSetOpen}
            popoverWidth={424}
            rootStyle={{ border: 'none', width: '100%' }}
            palate
        >
            <Grid container>
                <IconButton edge={'end'} onClick={() => setOpen(!open)} style={{ padding: 4 }}>
                    <Icon icon={'FilterList'} style={{ color: FlossPalette.STAR_GRASS }} />
                </IconButton>
                <Grid item style={{ paddingLeft: 12, marginTop: 4 }}>
                    <Text variant={'caption'} color={filterCount && filterCount > 0 ? 'STAR_GRASS' : 'DARK_GRAY'}>
                        {filterCount}
                    </Text>
                </Grid>
            </Grid>
        </OrdersToolbarPopper>
    );
};
