import type { IMergedItem } from '../../types';
import { isBridgeItem, isMergedItem } from '../../types';
import { AlignerItem } from './AlignerItem';
import { BridgeItem } from './BridgeItem';
import { DentureItem } from './DentureItem';
import { ImplantBridgeItem } from './ImplantBridgeItem';
import { ImplantItem } from './ImplantItem';
import { ModelItem } from './ModelItem';
import { OtherItem } from './OtherItem';
import { PartialsItem } from './PartialsItem';
import { RemovableItem } from './RemovableItem';
import { SingleToothUnitItem } from './SingleToothUnitItem';
import { SleepApneaItem } from './SleepApneaItem';
import { SurgicalGuideItem } from './SurgicalGuideItem';
import { WaxupItem } from './WaxupItem';
import type { IOrderItemV2DTO } from '@orthly/items';
import { LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

export interface IPrintableSlipItemProps {
    item: IMergedItem | IOrderItemV2DTO;
    allOrderItems: IOrderItemV2DTO[];
}

/**
 * Function component that serves to render the item details
 * in a format specific to what kind of item it is (Crown, Bridge, etc.)
 * @param item The order item for which to render details
 * @returns The rendered details section specific to the kind of item
 */
// EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
// eslint-disable-next-line sonarjs/cognitive-complexity
const PrintableSlipItemDetails: React.VFC<IPrintableSlipItemProps> = ({ item, allOrderItems }) => {
    if (!isMergedItem(item)) {
        if (isBridgeItem(item)) {
            return <BridgeItem item={item} />;
        }
        if (OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Model)) {
            return <ModelItem item={item} />;
        }
        if (OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Other)) {
            return <OtherItem item={item} />;
        }
        if (OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.SurgicalGuide)) {
            return <SurgicalGuideItem item={item} />;
        }
        if (OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Waxup)) {
            return <WaxupItem item={item} />;
        }
        if (OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Denture)) {
            return <DentureItem item={item} />;
        }
        if (OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Partial)) {
            return <PartialsItem item={item} />;
        }
        if (
            OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Removeable) ||
            OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.TMJ)
        ) {
            return <RemovableItem item={item} />;
        }
        if (OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.ImplantBridge)) {
            return <ImplantBridgeItem item={item} allItems={allOrderItems} />;
        }
        if (OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.SleepApnea)) {
            return <SleepApneaItem item={item} />;
        }
        if (OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Aligners)) {
            return <AlignerItem item={item} />;
        }
        return <></>;
    }
    switch (item.defaultItem.sku) {
        case LabOrderItemSKUType.Crown:
        case LabOrderItemSKUType.Inlay:
        case LabOrderItemSKUType.Veneer:
            return <SingleToothUnitItem mergedItem={item} />;
        case LabOrderItemSKUType.Implant:
            return <ImplantItem mergedItem={item} allItems={allOrderItems} />;
        default:
            return <></>;
    }
};

/**
 * Renders the details for a single order item on the Printable Slip
 * @param props
 * @returns Detailed section about an individual order item to be displayed on the Printable Slip
 */
export const PrintableSlipItem: React.VFC<IPrintableSlipItemProps> = ({ item, allOrderItems }) => {
    return (
        <Grid container style={{ marginTop: '0.5rem' }}>
            <Grid container style={{ marginTop: '.25rem' }}>
                <PrintableSlipItemDetails item={item} allOrderItems={allOrderItems} />
            </Grid>
        </Grid>
    );
};
