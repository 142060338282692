import type { LabsGqlPracticeTimelineEventNameEnum } from '@orthly/graphql-schema';
import { LabsGqlOrderTimelineEventNameEnum } from '@orthly/graphql-schema';
import {
    CalendarTimelineIcon,
    DandyDIcon,
    LabIcon,
    OrderPaperIcon,
    ScanIcon,
    StopIcon,
    ThumbsUpIcon,
    ToolOutlinedIcon,
    TruckIconOutlined,
    WarningIcon,
    AsteriskIcon,
} from '@orthly/ui';
import type { SvgIconProps } from '@orthly/ui-primitives';
import { createStyles, makeStyles, XIcon, RefreshIcon } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        timelineIcon: {
            height: '.75em',
            width: '.75em',
        },
    }),
);

export const TimelineIcon: React.FC<{
    type: LabsGqlOrderTimelineEventNameEnum | LabsGqlPracticeTimelineEventNameEnum | 'practiceNote';
    IconProps?: SvgIconProps;
}> = props => {
    const classes = useStyles();
    const { type, IconProps } = props;
    switch (type) {
        case LabsGqlOrderTimelineEventNameEnum.NewScanAdded:
        case LabsGqlOrderTimelineEventNameEnum.ScanIssuesReported:
            return <ScanIcon {...IconProps} viewBox={'0 0 16 16'} className={classes.timelineIcon} />;
        case LabsGqlOrderTimelineEventNameEnum.DueDateChanged:
        case LabsGqlOrderTimelineEventNameEnum.OrderSlaEdited:
        case LabsGqlOrderTimelineEventNameEnum.OrderSlaStartDateChanged:
            return <CalendarTimelineIcon viewBox={'0 0 16 16'} {...IconProps} className={classes.timelineIcon} />;
        case LabsGqlOrderTimelineEventNameEnum.OrderCreated:
        case LabsGqlOrderTimelineEventNameEnum.OrderDoctorNoteEdited:
        case LabsGqlOrderTimelineEventNameEnum.OrderItemAdded:
        case LabsGqlOrderTimelineEventNameEnum.OrderItemsAddedV2:
        case LabsGqlOrderTimelineEventNameEnum.OrderItemDeleted:
        case LabsGqlOrderTimelineEventNameEnum.OrderItemUpdated:
        case LabsGqlOrderTimelineEventNameEnum.OrderItemsUpdatedV2:
            return <OrderPaperIcon {...IconProps} className={classes.timelineIcon} />;
        case LabsGqlOrderTimelineEventNameEnum.OrderCancelled:
        case LabsGqlOrderTimelineEventNameEnum.OrderReturnCancelled:
            return <XIcon {...IconProps} className={classes.timelineIcon} />;
        case LabsGqlOrderTimelineEventNameEnum.FulfillmentCreated:
        case LabsGqlOrderTimelineEventNameEnum.FulfillmentShipped:
        case LabsGqlOrderTimelineEventNameEnum.ShipmentRemoved:
        case LabsGqlOrderTimelineEventNameEnum.ShippingStatusChanged:
        case LabsGqlOrderTimelineEventNameEnum.FulfillmentDelivered:
        case LabsGqlOrderTimelineEventNameEnum.ShippingEstimateUpdated:
        case LabsGqlOrderTimelineEventNameEnum.DeliveryAddressChanged:
        case LabsGqlOrderTimelineEventNameEnum.ShippingInfoUpdated:
            return <TruckIconOutlined {...IconProps} className={classes.timelineIcon} />;
        case LabsGqlOrderTimelineEventNameEnum.OrderSentToFloor:
            return <ToolOutlinedIcon {...IconProps} className={classes.timelineIcon} />;
        case LabsGqlOrderTimelineEventNameEnum.OrderHoldCreated:
        case LabsGqlOrderTimelineEventNameEnum.OrderHoldRemoved:
            return <StopIcon {...IconProps} className={classes.timelineIcon} />;
        case LabsGqlOrderTimelineEventNameEnum.ManufacturerAssignmentChanged:
            return <LabIcon {...IconProps} className={classes.timelineIcon} />;
        case LabsGqlOrderTimelineEventNameEnum.OrderReturnCreated:
        case LabsGqlOrderTimelineEventNameEnum.OrderReturnReceived:
        case LabsGqlOrderTimelineEventNameEnum.OrderRefabricationUnlinked:
        case LabsGqlOrderTimelineEventNameEnum.NeedsRefabrication:
            return <RefreshIcon {...IconProps} className={classes.timelineIcon} />;
        case LabsGqlOrderTimelineEventNameEnum.OrderFlaggedForReview:
            return <WarningIcon {...IconProps} className={classes.timelineIcon} />;
        case LabsGqlOrderTimelineEventNameEnum.OrderReviewFlagResolved:
            return <ThumbsUpIcon {...IconProps} className={classes.timelineIcon} />;
        case 'practiceNote':
            return <AsteriskIcon {...IconProps} className={classes.timelineIcon} />;
        default:
            return <DandyDIcon {...IconProps} className={classes.timelineIcon} />;
    }
};
