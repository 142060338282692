import { WarningIcon, SplitIcon, RefabCircleIcon } from '@orthly/ui';
import type { SvgIconProps, TextColor } from '@orthly/ui-primitives';
import { stylesFactory, Text, FlossPalette, Grid, Tooltip, CloseIcon } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    flagRoot: { width: 'auto', paddingRight: 8, flexWrap: 'nowrap', alignItems: 'center', overflow: 'hidden' },
    flagRootSmall: { paddingBottom: 2 },
    flagText: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
    flagIcon: { marginRight: 4 },
    flagIconSmall: { width: 16, height: 16 },
}));

interface FlagItemLayoutProps {
    Icon: React.ComponentType<SvgIconProps>;
    tooltipText?: string;
    text: string;
    colorKey: TextColor;
    small?: boolean;
}

const FlagItemLayout: React.FC<FlagItemLayoutProps> = props => {
    const classes = useStyles();
    const { Icon, tooltipText, text, colorKey } = props;
    const color = FlossPalette[colorKey];
    return (
        <Tooltip title={tooltipText ?? ''} disableHoverListener={typeof tooltipText !== 'string'}>
            <Grid container className={clsx(classes.flagRoot, props.small && classes.flagRootSmall)}>
                <Icon
                    className={clsx(classes.flagIcon, props.small && classes.flagIconSmall)}
                    style={{ color }}
                    fontSize={'small'}
                    color={'inherit'}
                />
                <Text variant={'caption'} style={{ color }} className={classes.flagText}>
                    {text}
                </Text>
            </Grid>
        </Tooltip>
    );
};

type FlagIcon = 'MissingIcon' | 'RefabCircleIcon' | 'WarningIcon' | 'SplitIcon';

const IconComponentMap: { [K in FlagIcon]-?: React.ComponentType<SvgIconProps> } = {
    MissingIcon: CloseIcon,
    RefabCircleIcon,
    WarningIcon,
    SplitIcon,
};

function isFlagIcon(key: string): key is FlagIcon {
    return Object.keys(IconComponentMap).includes(key);
}

export type OrderListItemTitleFlag = 'Split' | 'Refab' | 'NewPractice' | 'MissingPrice';

const FlagIconMap: { [K in OrderListItemTitleFlag]-?: FlagIcon } = {
    Refab: 'RefabCircleIcon',
    MissingPrice: 'MissingIcon',
    NewPractice: 'WarningIcon',
    Split: 'SplitIcon',
};

const FlagTitleMap: { [K in OrderListItemTitleFlag]-?: string } = {
    Split: 'Split',
    NewPractice: 'New practice',
    MissingPrice: 'Missing price',
    Refab: 'From refab',
};

const NeedsReviewFlag: React.FC<{ flags: string[]; small?: boolean }> = props => {
    const { flags } = props;
    const firstFlag = flags[0];
    const additionalFlags = flags.slice(1);
    if (!firstFlag) {
        return null;
    }
    return (
        <FlagItemLayout
            small={props.small}
            Icon={WarningIcon}
            text={firstFlag}
            colorKey={'ATTENTION'}
            tooltipText={additionalFlags.length === 0 ? undefined : `Additional flags: ${additionalFlags.join(', ')}`}
        />
    );
};

export interface OrderListItemFlagsProps {
    flags: OrderListItemTitleFlag[];
    review_flag_reasons?: string[];
}

function getColorFromIcon(iconKey: FlagIcon): TextColor {
    switch (iconKey) {
        case 'WarningIcon':
            return 'BURGUNDY';
        case 'SplitIcon':
            return 'DARK_GRAY';
        default:
            return 'ATTENTION';
    }
}

export const OrderListItemFlags: React.FC<OrderListItemFlagsProps & { small?: boolean }> = props => {
    const { flags, review_flag_reasons } = props;
    const iconToFlagTitlesMap = flags.reduce<{ [K in FlagIcon]?: OrderListItemTitleFlag[] }>((acc, flag) => {
        const flagIcon = FlagIconMap[flag];
        const existing = acc[flagIcon] ?? [];
        return { ...acc, [flagIcon]: [...existing, FlagTitleMap[flag]] };
    }, {});

    return (
        <>
            {review_flag_reasons && <NeedsReviewFlag flags={review_flag_reasons} small={props.small} />}

            {Object.entries(iconToFlagTitlesMap).map(([iconKey, textArr]) => {
                if (!isFlagIcon(iconKey) || !textArr) {
                    return null;
                }
                const icon = IconComponentMap[iconKey];
                const text = textArr.join(', ');
                return (
                    <FlagItemLayout
                        key={iconKey}
                        small={props.small}
                        Icon={icon}
                        text={text}
                        colorKey={getColorFromIcon(iconKey)}
                    />
                );
            })}
        </>
    );
};
