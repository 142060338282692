import type { IOrderItemV2DTO } from '@orthly/items';
import { CartItemV2Utils } from '@orthly/items';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import { IconButton, Menu, MenuItem } from '@orthly/ui-primitives';
import type { OrderDetailItemsTableItemActionItem } from '@orthly/veneer';
import React from 'react';

// TEMP - for old buttons
// TODO: clean when old buttons are removed
export const getOrderItemMenuButtonPropFromItems = (
    items: ArrayMin1<IOrderItemV2DTO>,
): ArrayMin1<OrderDetailItemsTableItemActionItem> => {
    return items.map(item => {
        const uniqueUnns = CartItemV2Utils.getUniqueUNNs(item);
        const teethDisplay = uniqueUnns.length > 0 ? `(${CartItemV2Utils.getUnnsDisplay(uniqueUnns)})` : '';
        const label = `${CartItemV2Utils.getItemDisplayMaterial(item)} ${teethDisplay}`;
        return {
            label,
            itemId: item.id,
        };
    }) as ArrayMin1<OrderDetailItemsTableItemActionItem>;
};

interface OrderItemMenuButtonProps {
    items: ArrayMin1<OrderDetailItemsTableItemActionItem>;
    onSelect: (itemId: string) => void;
    onMenuOpen?: () => void;
    disabled?: boolean;
}

export const OrderItemMenuButton: React.FC<OrderItemMenuButtonProps> = ({
    children,
    items,
    onSelect,
    onMenuOpen,
    disabled,
}) => {
    const [menuEl, setMenuEl] = React.useState<HTMLElement | null>(null);

    return (
        <>
            <IconButton
                style={{ padding: 0 }}
                onClick={e => {
                    if (items.length > 1) {
                        setMenuEl(e.currentTarget);
                        onMenuOpen?.();
                    } else {
                        onSelect(items[0].itemId);
                    }
                }}
                color={'primary'}
                disabled={disabled}
            >
                {children}
            </IconButton>
            <Menu
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorEl={menuEl}
                open={!!menuEl}
                onClose={() => setMenuEl(null)}
            >
                {items.map(({ itemId, label }) => (
                    <MenuItem
                        key={itemId}
                        onClick={() => {
                            onSelect(itemId);
                            setMenuEl(null);
                        }}
                    >
                        {label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
