import { LabsGqlDesignOrderNoteCategory } from '@orthly/graphql-schema';

export const isToothDesignOptionDisabled = (
    option: LabsGqlDesignOrderNoteCategory,
    selectionsContain: (options: LabsGqlDesignOrderNoteCategory[]) => boolean,
): boolean => {
    switch (option) {
        case LabsGqlDesignOrderNoteCategory.GingivalEmbrasureOpenMesial:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.GingivalEmbrasureCloseMesial]);

        case LabsGqlDesignOrderNoteCategory.GingivalEmbrasureOpenDistal:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.GingivalEmbrasureCloseDistal]);

        case LabsGqlDesignOrderNoteCategory.GingivalEmbrasureCloseMesial:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.GingivalEmbrasureOpenMesial]);

        case LabsGqlDesignOrderNoteCategory.GingivalEmbrasureCloseDistal:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.GingivalEmbrasureOpenDistal]);

        case LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapeRounded:
            return selectionsContain([
                LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapePointy,
                LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapeSquared,
            ]);

        case LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapePointy:
            return selectionsContain([
                LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapeRounded,
                LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapeSquared,
            ]);

        case LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapeSquared:
            return selectionsContain([
                LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapePointy,
                LabsGqlDesignOrderNoteCategory.IncisalEdgeDesignAdjustShapeRounded,
            ]);

        case LabsGqlDesignOrderNoteCategory.EstheticsEnhanceLineAngles:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.EstheticsSoftenLineAngles]);

        case LabsGqlDesignOrderNoteCategory.EstheticsSoftenLineAngles:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.EstheticsEnhanceLineAngles]);

        default:
            return false;
    }
};
