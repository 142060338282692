import type { ItemInfo, Rule, Decision } from '../orderEditMode';
import type { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { OrderEditMode } from '@orthly/shared-types';

/**
 * orderTask checks for the current order task matching any of its
 * specified tasks.  If it finds a match it returns EditOrder as
 * the most permissible possible value, otherwise it returns null to
 * skip
 */
export function orderTask(...tasks: LabsGqlWorkflowTaskType[]): Rule {
    return function (item: ItemInfo): Decision {
        const debugInfo = {
            rule: 'orderTask',
            expected: tasks,
            received: item.order.fulfillment_workflow.active_task?.type,
        };

        const active = item.order.fulfillment_workflow.active_task;
        if (active && tasks.includes(active.type)) {
            return {
                editMode: OrderEditMode.OrderEdit,
                debugInfo,
            };
        }

        return {
            editMode: null,
            debugInfo,
        };
    };
}
