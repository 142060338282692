import { useDisplayOptionsContext } from '../../../contexts/TableOptionsContext';
import { useMuiTableSelector } from '../../../state';
import type { ColumnFilterState } from '../../../state/TableStateContext';
import { useTableStateAction } from '../../../state/action-hooks';
import { createStyles, makeStyles, Chip } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(
    () =>
        createStyles({
            root: {
                display: 'flex',
                justifyContent: 'left',
                flexWrap: 'wrap' as 'wrap',
                margin: '0px 16px 0px 16px',
            },
            chip: {
                margin: '8px 8px 0px 0px',
            },
            chipLabel: {},
        }),
    { name: 'MUITableFilterList' },
);

export const MUITableFilterList = () => {
    const columnFilters = useMuiTableSelector(s => s.columnFilters);
    const onFilterUpdate = useTableStateAction('updateFilterValues');
    const displayOpts = useDisplayOptionsContext();
    const classes = useStyles();
    if (displayOpts.filterValues === false) {
        return null;
    }
    const removeFilterValue = (columnFilterState: ColumnFilterState, value: string) => () => {
        onFilterUpdate(
            columnFilterState.columnName,
            columnFilterState.filterValues.filter(v => v !== value),
        );
    };
    return (
        <div className={classes.root} id={'MUITable-FilterList'}>
            {columnFilters.map(col =>
                col.filterValues
                    .filter(f => !!f.trim())
                    .map(data => (
                        <Chip
                            label={`${col.columnName}: ${col.mod !== '=' ? col.mod : ''}${data}`}
                            size={'small'}
                            color={'primary'}
                            key={`${col.columnName}-${data}`}
                            onDelete={removeFilterValue(col, data)}
                            className={classes.chip}
                            classes={{
                                label: classes.chipLabel,
                            }}
                        />
                    )),
            )}
        </div>
    );
};
