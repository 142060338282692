import { useUsersAction } from '../../state/Users.actions';
import type { LabsGqlUpdateUserPasswordMutationVariables } from '@orthly/graphql-operations';
import { useUpdateUserPasswordMutation } from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';

type UpdateUserPasswordVars = LabsGqlUpdateUserPasswordMutationVariables['data'];

export const useUpdateUserPassword = () => {
    const onUserSaved = useUsersAction('USER_SAVED');
    const [submitMtn] = useUpdateUserPasswordMutation();
    const mtnSubmitter = (data: UpdateUserPasswordVars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [UpdateUserPasswordVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Successfully updated user password!', {}],
        onSuccess: result => {
            const user = result.data?.updateUserPassword;
            if (user) {
                onUserSaved({ user });
            }
        },
    });

    return { submit, submitting };
};
