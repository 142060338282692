import { usePartnerPaymentSplitSourceOptions } from '../actions/EditPaymentSplitConfig/usePartnerPaymentSplitSourceOptions.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { Format } from '@orthly/runtime-utils';
import { DefaultValidationMap, QuickForm, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Icon, IconButton, Text, Tooltip, styled } from '@orthly/ui-primitives';
import React from 'react';

const GetOutstandingBalanceCents_Query = graphql(`
    query Query($organizationId: String!) {
        getOutstandingBalanceCents(organizationId: $organizationId)
    }
`);

const CollectPartialPayment_Mutation = graphql(`
    mutation CollectPartialPayment($data: PartialPaymentInput!) {
        collectPartialPayment(data: $data) {
            id
            status
            amount_cents
        }
    }
`);

const PartialPaymentDialog: React.FC<{
    organizationId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    outstandingBalanceCents: number;
    refetch: () => void;
}> = ({ organizationId, open, setOpen, outstandingBalanceCents, refetch }) => {
    const { sourceOptions, sourcesLoading } = usePartnerPaymentSplitSourceOptions(organizationId, !open);
    const [sourceId, setSourceId] = React.useState<string | undefined>(undefined);

    const collectPartialPaymentMtn = useMutation(CollectPartialPayment_Mutation);
    const { submit, submitting } = useRootActionCommand(collectPartialPaymentMtn, {
        onSuccess: async () => {
            await refetch();
            setOpen(false);
        },
        successMessage: 'Partial payment collected successfully',
    });

    const buttonDisabled = !sourceId || submitting;

    return (
        <RootActionDialog
            loading={submitting || sourcesLoading}
            open={open}
            title={'Collect Partial Payment'}
            content={
                <div>
                    <QuickForm<{ sourceId: string; amountDollars: number }>
                        onSubmit={async ({ sourceId, amountDollars }) => {
                            await submit({
                                data: { organizationId, sourceId, amountCents: Math.round(amountDollars * 100) },
                            });
                        }}
                        // controlling this component since we reference the sourceId in the "pay" button
                        onChange={({ sourceId }) => setSourceId(sourceId)}
                        initialValues={{ sourceId }}
                        fields={{
                            sourceId: {
                                type: 'select',
                                label: 'Select Partial Payment Source',
                                optional: false,
                                options: sourceOptions.map(o => ({ value: o.id, label: o.label })),
                                fieldProps: {
                                    variant: 'standard',
                                },
                            },
                            amountDollars: {
                                type: 'number',
                                label: 'Amount',
                                validation: DefaultValidationMap.number.min(1).max(outstandingBalanceCents / 100),
                                fieldProps: {
                                    InputProps: { startAdornment: '$', style: { paddingLeft: '14px' } },
                                },
                            },
                        }}
                        submitButtonTitle={`Pay using ${sourceOptions.find(s => s.id === sourceId)?.label}`}
                        submitButtonProps={{ disabled: buttonDisabled }}
                    />
                </div>
            }
            setOpen={setOpen}
            CustomButton={({ onClick }) => (
                <IconButton onClick={onClick}>
                    <Tooltip title={'Collect partial payment'}>
                        <Icon icon={'Money'} />
                    </Tooltip>
                </IconButton>
            )}
        />
    );
};

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
});

const OutstandingBalanceText = styled(Text)({
    marginRight: '8px',
});

export const PartialPaymentAction: React.FC<{ organizationId: string; refetch: () => void }> = ({
    organizationId,
    refetch,
}) => {
    const [open, setOpen] = React.useState(false);
    const { data: { getOutstandingBalanceCents: outstandingBalanceCents = 0 } = {} } = useQuery(
        GetOutstandingBalanceCents_Query,
        {
            variables: { organizationId },
        },
    );

    if (!outstandingBalanceCents) {
        return null;
    }

    return (
        <Container>
            <OutstandingBalanceText
                variant={'body2'}
                color={'ATTENTION'}
            >{`Outstanding balance: ${Format.currency(outstandingBalanceCents)}`}</OutstandingBalanceText>
            <PartialPaymentDialog
                open={open}
                setOpen={setOpen}
                organizationId={organizationId}
                outstandingBalanceCents={outstandingBalanceCents}
                refetch={refetch}
            />
        </Container>
    );
};
