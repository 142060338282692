import { TagsAutocomplete } from '../../TagsAutocomplete';
import { useIsTrainingOrder, useOrder } from '../OrderState.hooks';
import { LabsGqlTaggableEntityType } from '@orthly/graphql-schema';
import { Box, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

export const TagsBlock: React.VFC = () => {
    const { id: orderId } = useOrder();
    const isTraining = useIsTrainingOrder();

    return (
        <Box
            sx={{
                padding: '8px 0',
                borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
            }}
        >
            <TagsAutocomplete
                rootStyle={{ width: '100%' }}
                entityId={orderId}
                entityType={LabsGqlTaggableEntityType.Order}
                disabled={isTraining}
            />
        </Box>
    );
};
