import type { IOrderItemShade, ICustomFieldSubmission, IOrderItemV2DTO } from '@orthly/items';
import { isEqualOnProps } from '@orthly/runtime-utils';
import _ from 'lodash';

/**
 * Determine if an array is a superset of another array
 * in that they are equal, or 1 is a superset of the other
 * @example
 * const x = [1, 2]
 * const y = [1]
 * x is a superset of y, so return true
 * @param superset the candidate superset array
 * @param subset the candidate subset
 * @returns Whether or not superset is actually a superset of subset
 */
export function isSuperset<T extends (Partial<IOrderItemShade> | Partial<ICustomFieldSubmission>)[]>(
    superset: T,
    subset: T,
): boolean {
    const intersection = _.intersectionWith(superset, subset, _.isEqual);
    return _.isEqual(intersection, subset);
}

/**
 * Determine if an array of preference fields is a superset of another array
 * in that they are equal, or 1 is a superset of the other
 * @param superset the candidate superset array
 * @param subset the candidate subset
 * @returns Whether or not superset is actually a superset of subset
 */
export function isSupersetFields<T extends Partial<ICustomFieldSubmission>[]>(superset: T, subset: T): boolean {
    const intersection = _.intersectionWith(superset, subset, _.isEqual);
    return isEqualOnProps(intersection, subset, ['field_id', 'value']);
}

/**
 * Determine if two arrays are similar
 * in that they are equal, or 1 is a superset of the other
 * @param itemA first array to compare
 * @param itemB array to compare to the first array
 * @returns Whether or not the two arrays are identical or one is a superset of the other
 */
export function areEqualOrSuperset<T extends (Partial<IOrderItemShade> | Partial<ICustomFieldSubmission>)[]>(
    itemA: T,
    itemB: T,
): boolean {
    return isSuperset(itemA, itemB) || isSuperset(itemB, itemA);
}

/**
 * Determine if two arrays of preference fields are similar
 * in that they are equal, or 1 is a superset of the other
 * @param itemA first array to compare
 * @param itemB array to compare to the first array
 * @returns Whether or not the two arrays are identical or one is a superset of the other
 */
export function areEqualOrSupersetFields<T extends Partial<ICustomFieldSubmission>[]>(itemA: T, itemB: T): boolean {
    return isSupersetFields(itemA, itemB) || isSupersetFields(itemB, itemA);
}

/**
 * Get the "Occlusal Staining" information from an order item
 * @param item The item whose "Occlusal Staining" to retrieve
 * @returns Occlusal Staining data for the order item
 */
export function getOcclusalStainingPreference(item: IOrderItemV2DTO): string | null {
    const stainingPref = item.preference_fields.find(
        field => field.field_id === 'occlusal-staining-preference-order-item-meta',
    )?.value;
    return stainingPref ? `${stainingPref}` : null;
}

/**
 * Get the maximum model element height in mm from an order item
 * @param item The item whose model height to retrieve
 * @returns height as a number or undefined if not found
 */
export function getMaximumModelElementHeightInMm(item: IOrderItemV2DTO): number | undefined {
    const field = item.preference_fields.find(
        field => field.field_id === 'lab-slip-maximum-model-element-height-in-mm',
    );
    return field?.value ? Number(field.value) : undefined;
}

/**
 * Get the puck height from an order item
 * @param item The item whose puck height to retrieve
 * @returns height as a number or undefined if not found
 */
export function getPuckHeight(item: IOrderItemV2DTO): number | undefined {
    const field = item.preference_fields.find(field => field.field_id === 'lab-slip-puck-height');
    return field?.value ? Number(field.value) : undefined;
}
