import { OrderDetailTrackerV2 } from '../../OrderDetailTrackerV2';
import { OrderSummaryItems } from '../components/OrderSummaryItems';
import type { OrderEditCustomStrings } from '../utils/navigationButtonUtils';
import { friendlyCancelAndResubmitOrderStatus } from '../utils/orderStatusUtils';
import { summaryRowsForGroup } from '../utils/summaryRowsForGroup';
import type { OrderEditPage } from './types';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlWorkflowStateEnum } from '@orthly/graphql-schema';
import type { ICartItemV2DTOWithId } from '@orthly/items';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import { OrderEditMode } from '@orthly/shared-types';
import { styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const SummaryGrid = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const TrackerWrapper = styled('div')(({ theme }) => ({
    padding: '28px 0 20px',
    width: '80%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

interface OrderEditReviewPagePageContentProps {
    order: LabsGqlOrder;
    itemGroups: ArrayMin1<ICartItemV2DTOWithId>[];
    originalItemMap: Record<string, ICartItemV2DTOWithId>;
    nonEditableItems: ICartItemV2DTOWithId[];
    orderEditMode: OrderEditMode;
    customStrings?: OrderEditCustomStrings;
}

const VeneerOrderEditReviewPage_Query = graphql(`
    query VeneerOrderEditReviewPage_Query($order_id: String!, $timezone_offset_minutes: Int!) {
        getOrderTrackerEntries(order_id: $order_id, timezone_offset_minutes: $timezone_offset_minutes) {
            active
            alert
            subtitle
            subtitleAttachment
            title
        }
    }
`);

// See  https://meetdandy.atlassian.net/browse/EPDPLT-3100 for more details

const OrderEditReviewPagePageContent: React.VFC<OrderEditReviewPagePageContentProps> = ({
    order,
    itemGroups,
    originalItemMap,
    nonEditableItems,
    orderEditMode,
}) => {
    const materialChanged = itemGroups.some(group => {
        const rows = summaryRowsForGroup(group, originalItemMap);
        return rows.material.value !== rows.material.originalValue;
    });

    const { data: additionalData } = useQuery(VeneerOrderEditReviewPage_Query, {
        variables: {
            order_id: order.id,
            timezone_offset_minutes: new Date().getTimezoneOffset(),
        },
    });

    return (
        <>
            {orderEditMode === OrderEditMode.CancelAndResubmit && (
                <TrackerWrapper>
                    <OrderDetailTrackerV2
                        simple
                        animated
                        padded
                        orderTrackerEntries={additionalData?.getOrderTrackerEntries ?? []}
                        stepOverrides={[
                            'Placed',
                            friendlyCancelAndResubmitOrderStatus(
                                order.status,
                                order.fulfillment_workflow?.active_task?.type || '',
                            ),
                        ]}
                        isPractice
                        isLab={false}
                        isOnHold={
                            order?.workflow_state.state === LabsGqlWorkflowStateEnum.OnHold && !order?.hold_in_review
                        }
                    />
                </TrackerWrapper>
            )}
            <SummaryGrid>
                <OrderSummaryItems items={itemGroups} originalItemMap={originalItemMap} />
                {nonEditableItems.length > 0 && (
                    //TODO: when we add actual data here confirm rendering correctly
                    <OrderSummaryItems items={[nonEditableItems]} originalItemMap={originalItemMap} />
                )}
            </SummaryGrid>
            {materialChanged && (
                <Text variant={'body2'} color={'ATTENTION_FOREGROUND'} style={{ paddingTop: 64 }}>
                    Edits to the material selection may result in price adjustments to your order. Please refer to your
                    pricing guide for more information.
                </Text>
            )}
        </>
    );
};

export function getReviewPage(props: OrderEditReviewPagePageContentProps): OrderEditPage {
    return {
        supertitle: `Final step`,
        title: props.customStrings?.reviewTitle ?? `Review & submit modifications`,
        Content: <OrderEditReviewPagePageContent {...props} />,
        nextIsSkip: false,
        type: 'review_order',
    };
}
