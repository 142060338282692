import { RouterUtils } from '../../../../utils/router/RouterUtils';
import { useAutomationBuilderAction } from '../../state/AutomationBuilder.actions';
import { useDeleteOrderAutomationMutation } from '@orthly/graphql-react';
import { LoadBlocker, TrashIcon, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, makeStyles, createStyles, IconButton, Tooltip } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
    createStyles({
        deleteButton: { '&:hover': { backgroundColor: FlossPalette.ATTENTION_BG }, color: FlossPalette.ATTENTION },
    }),
);

interface AutomationsBuilderDeleteButtonProps {
    automationId: string;
    refetch: () => Promise<unknown>;
}

export const AutomationsBuilderDeleteButton: React.VFC<AutomationsBuilderDeleteButtonProps> = ({
    automationId,
    refetch,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const exitEditing = useAutomationBuilderAction('RESET');
    const [submitDelete] = useDeleteOrderAutomationMutation();
    const { submitting, submit } = useChangeSubmissionFn<any, []>(
        () => submitDelete({ variables: { id: automationId } }),
        {
            successMessage: () => [`Automation deleted`, {}],
            onSuccess: async () => {
                history.replace(`${RouterUtils.pathForScreen('automations')}`);
                exitEditing();
                await refetch();
            },
        },
    );

    return (
        <LoadBlocker blocking={submitting}>
            <Tooltip title={'Delete'}>
                <IconButton
                    onClick={() => {
                        if (window.confirm('Are you sure you want to delete this automation? This cannot be undone.')) {
                            void submit();
                        }
                    }}
                    className={classes.deleteButton}
                >
                    <TrashIcon />
                </IconButton>
            </Tooltip>
        </LoadBlocker>
    );
};
