import {
    useListChairsideScanRecordingsSessions,
    ChairsideScanTimeThumbnail,
} from '../../../../../ChairsideScans/ChairsideScansRoot';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { Grid } from '@orthly/ui-primitives';
import { OrderDetailAccordionBlock, useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const AdminOrderDetailChairsideRecordingsBlockLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailChairsideRecordingsBlockLabSalesOrder_Fragment on LabSalesOrderDTO {
        practice_id
        scan_export {
            threeshape_order_id
        }
    }
`);

interface OrderDetailChairsideRecordingsBlockProps {
    salesOrder: FragmentType<typeof AdminOrderDetailChairsideRecordingsBlockLabSalesOrder_Fragment>;
}

export const OrderDetailChairsideRecordingsBlock: React.VFC<OrderDetailChairsideRecordingsBlockProps> = ({
    salesOrder,
}) => {
    const { practice_id, scan_export } = getFragmentData(
        AdminOrderDetailChairsideRecordingsBlockLabSalesOrder_Fragment,
        salesOrder,
    );

    const { value: isEnabled } = useFeatureFlag('enableChairsideScanRecordingsInOrderPages');

    const sessions = useListChairsideScanRecordingsSessions(practice_id, scan_export.threeshape_order_id);

    if (!isEnabled || !sessions.length) {
        return null;
    }

    return (
        <OrderDetailAccordionBlock title={'Chairside Scan Recordings'} variant={'full'}>
            <Grid container justifyContent={'center'}>
                {sessions.map(session => (
                    <ChairsideScanTimeThumbnail
                        key={session[0].time}
                        session={session}
                        isCurrentlyPlayingVideo={false}
                        style={{
                            marginRight: 16,
                            marginBottom: 16,
                        }}
                    />
                ))}
            </Grid>
        </OrderDetailAccordionBlock>
    );
};
