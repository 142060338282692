import { LabsGqlSavedSearchVisibility } from '@orthly/graphql-schema';
import { stylesFactory, FlossPalette, IconButton, Tooltip, MoreVertIcon, Icon } from '@orthly/ui-primitives';
import React from 'react';

interface NavItemEndIconsProps {
    visibility?: LabsGqlSavedSearchVisibility;
    createdByUser?: boolean;
    createdByUsername?: string;
    favoritedCount?: number;
    isSaved: boolean;
    setAnchorEl: (elem: HTMLElement | null) => void;
}

const useStartIconStyles = stylesFactory(() => ({
    root: { width: 'auto', paddingRight: 8, alignItems: 'center' },
    icon: { color: FlossPalette.STAR_GRASS, fontSize: 24 },
    grayIcon: { color: FlossPalette.GRAY, fontSize: 24 },
}));

export const CustomViewNavItemEndIcons: React.FC<NavItemEndIconsProps> = props => {
    const { visibility, createdByUsername, createdByUser, isSaved, favoritedCount, setAnchorEl } = props;
    const classes = useStartIconStyles();
    return (
        <>
            {visibility === LabsGqlSavedSearchVisibility.Public && isSaved && (
                <Tooltip title={`Created by ${createdByUser ? 'you' : createdByUsername}, used by ${favoritedCount}`}>
                    <Icon icon={'Public'} className={classes.grayIcon} />
                </Tooltip>
            )}
            {
                <IconButton
                    style={{ padding: 0 }}
                    onClick={e => {
                        e.stopPropagation();
                        setAnchorEl(e.currentTarget);
                    }}
                >
                    <MoreVertIcon className={classes.icon} />
                </IconButton>
            }
        </>
    );
};
