import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';

const GetSavedSearchById_Query = graphql(`
    query GetSavedSearchById($search_id: String!) {
        getSavedSearchById(search_id: $search_id) {
            id
            visibility
            created_by
        }
    }
`);

export const useGetSavedSearchById = (search_id: string | undefined) => {
    const { data, loading, error, refetch } = useQuery(GetSavedSearchById_Query, {
        variables: { search_id: search_id ?? '' },
        skip: !search_id,
    });

    return { data: data?.getSavedSearchById, loading, error: error?.message, refetch };
};
