import { ImplantToScanbodyInput } from './ImplantToScanbodyInput';
import type { LabsGqlImplantToScanbodyFragment, LabsGqlScanbodyFragment } from '@orthly/graphql-operations';
import { useGetImplantByIdQuery } from '@orthly/graphql-react';
import _ from 'lodash';
import React from 'react';

export interface ScanbodyTrie {
    [manufacturer: string]: {
        [name: string]: LabsGqlScanbodyFragment[];
    };
}

export function useGetFilteredScanbodies(
    implantToScanbodies: LabsGqlImplantToScanbodyFragment[],
    isAuthentic?: boolean,
): ScanbodyTrie {
    return React.useMemo<ScanbodyTrie>(() => {
        if (!implantToScanbodies || isAuthentic === undefined) {
            return {};
        }

        const filteredScanbodies = implantToScanbodies
            .filter(its => its.is_authentic === isAuthentic)
            .map(its => its.scanbody);

        const groupedByManufacturer = _.groupBy(filteredScanbodies, t => t.manufacturer);
        return _.mapValues<typeof groupedByManufacturer, typeof groupedByManufacturer>(
            groupedByManufacturer,
            scanbodies => _.groupBy(scanbodies, t => t.name),
        );
    }, [implantToScanbodies, isAuthentic]);
}

export interface ImplantToScanbodyFilters {
    isAuthentic?: boolean;
    name?: string;
    manufacturer?: string;
    sku?: string;
}

interface ImplantToScanbodySelectorProps {
    setSelectedImplantToScanbody: (implantToScanbody: LabsGqlImplantToScanbodyFragment | null) => void;
    implantId: string;
}

/**
 * Component used to select a scan body with dropdowns for the name, manufacturer and sku.
 *
 * The setSelectedScanbdoy callback will be called with the scan body from the db if one exists for
 * the values selected in the dropdowns.
 */
export const ImplantToScanbodySelector: React.VFC<ImplantToScanbodySelectorProps> = ({
    setSelectedImplantToScanbody,
    implantId,
}) => {
    const { data } = useGetImplantByIdQuery({
        variables: {
            id: implantId,
        },
    });

    const [ImplantToScanbodyFilters, setImplantToScanbodyFilters] = React.useState<ImplantToScanbodyFilters>({});
    const scanbodyTrie = useGetFilteredScanbodies(
        data?.getImplantById?.scanbody_relationships ?? [],
        ImplantToScanbodyFilters.isAuthentic,
    );

    const handleUpdateField = React.useCallback(
        (fieldName: keyof ImplantToScanbodyFilters, fieldValue: string | null) => {
            setSelectedImplantToScanbody(null);

            // Filters are meant to be used in order: Is Authentic? -> Manufacturer -> Name -> SKU
            // Therefore we reset any filter that is impacted by a change of an earlier filter.
            switch (fieldName) {
                case 'isAuthentic':
                    setImplantToScanbodyFilters({
                        // Note it's important to have either true, false, or undefined, since when we clear the field
                        // we want to reset all the other fields as well
                        isAuthentic: (fieldValue ?? '').length > 0 ? fieldValue === 'Authentic' : undefined,
                        manufacturer: undefined,
                        name: undefined,
                        sku: undefined,
                    });
                    return;
                case 'manufacturer':
                    setImplantToScanbodyFilters(prevValue => ({
                        ...prevValue,
                        manufacturer: fieldValue ?? undefined,
                        name: undefined,
                        sku: undefined,
                    }));
                    return;
                case 'name':
                    setImplantToScanbodyFilters(prevValue => ({
                        ...prevValue,
                        name: fieldValue ?? undefined,
                        sku: undefined,
                    }));
                    return;
                case 'sku':
                    setImplantToScanbodyFilters(prevValue => ({
                        ...prevValue,
                        sku: fieldValue ?? undefined,
                    }));
                    return;
            }
        },
        [setSelectedImplantToScanbody],
    );

    React.useEffect(() => {
        if (ImplantToScanbodyFilters?.name && ImplantToScanbodyFilters?.manufacturer && ImplantToScanbodyFilters?.sku) {
            const existingImplantToScanbody = data?.getImplantById?.scanbody_relationships?.find(
                implantToScanbody =>
                    implantToScanbody.scanbody.manufacturer === ImplantToScanbodyFilters.manufacturer &&
                    implantToScanbody.scanbody.name === ImplantToScanbodyFilters.name &&
                    implantToScanbody.scanbody.sku === ImplantToScanbodyFilters.sku &&
                    implantToScanbody.is_authentic === ImplantToScanbodyFilters.isAuthentic,
            );

            existingImplantToScanbody && setSelectedImplantToScanbody(existingImplantToScanbody);
        }
    }, [ImplantToScanbodyFilters, data, setSelectedImplantToScanbody]);

    return (
        <ImplantToScanbodyInput
            onFieldUpdate={handleUpdateField}
            value={ImplantToScanbodyFilters}
            scanbodyTrie={scanbodyTrie}
        />
    );
};
