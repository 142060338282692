import { ItemEditorV2Utils } from '../../components/ItemEditorV2';
import type { OrderEditPage } from './pages/types';
import { itemsAreEqual, mergeItems } from './utils/orderEditItemMergeUtils';
import type { ToastProps } from '@orthly/dentin';
import { useCancelAndResubmitMutation, useUpdateOrderItemsV2PracticeMutation } from '@orthly/graphql-react';
import { OrderItemV2InputUtils } from '@orthly/graphql-schema';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import type { ICartItemV2DTOWithId, IOrderItemV2DTO } from '@orthly/items';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import type { OnCloseOrderEditProps } from '@orthly/shared-types';
import { OrderEditMode, canOtherItemBeAddedToCart, isEditableItem } from '@orthly/shared-types';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';

export function itemsToMap(items: ICartItemV2DTOWithId[]): Record<string, ICartItemV2DTOWithId> {
    return _.keyBy(items, item => item.id);
}

export function getChangedItems(
    originalItemMap: Record<string, ICartItemV2DTOWithId>,
    newItems: ICartItemV2DTOWithId[],
): ICartItemV2DTOWithId[] {
    return newItems.filter(item => {
        const original = originalItemMap[item.id];
        return !original || !itemsAreEqual(item, original);
    });
}

type IdSet = Record<string, true>;

function getIdSet(items: ICartItemV2DTOWithId[]): IdSet {
    return _.mapValues(
        _.keyBy(items, item => item.id),
        () => true as const,
    );
}

/** Vends data about the original items in the order and state for the item and item groups being edited. */
export function useItems(allOrderItems: IOrderItemV2DTO[]) {
    // Data derived from the original order items.
    const { originalEditableItems, originalNonEditableItems, originalItemMap } = React.useMemo(() => {
        const [originalEditableItems, originalNonEditableItems] = _.partition(allOrderItems, item =>
            isEditableItem(item),
        );
        const originalItemMap = itemsToMap(originalEditableItems);
        return {
            // Sort by tooth number so items are listed from top to bottom starting with lowest tooth number first.
            originalEditableItems: _.sortBy(originalEditableItems, item =>
                Math.min(...CartItemV2Utils.getUniqueUNNs(item)),
            ),
            originalNonEditableItems,
            originalItemMap,
        };
    }, [allOrderItems]);

    // Keeps track of extras added during the C/R flow
    const [addedExtraIds, setAddedExtraIds] = React.useState<string[]>([]);

    // This is the big one: Keeps track of the current state of all the editable items.
    const [editableItems, setEditableItems] = React.useState<ICartItemV2DTOWithId[]>(originalEditableItems);

    // Join current unns of editable items with unns of non-editable items to get all unns involved in the order.
    const allOrderUnns = CartItemV2Utils.getItemGroupUniqueUNNs([...editableItems, ...originalNonEditableItems]);

    // We are disabling grouping with the order edit v2 experience for now as out of the box it doesn't support
    // editing UNNs of groups of items.
    const itemGroups = mergeItems(editableItems, {
        mayMerge: () => false,
    });

    const changedItems = getChangedItems(originalItemMap, editableItems);

    return {
        // Complete set of unns involved in the order. Used to prevent moving items
        // on to a unn taken by another item.
        allOrderUnns,
        // Map from ids to original versions of each editable item. Used to compare
        // the original items to the current edits.
        originalItemMap,
        setEditableItems,
        itemGroups,
        changedItems,
        // The full list of items, will all the updated items along with the non-updated items
        allItems: [...editableItems, ...originalNonEditableItems],
        nonEditableItems: originalNonEditableItems,
        addedExtraIds,
        setAddedExtraIds,
    };
}

/** Encapsulates state for picking which item groups to edit. */
export function useGroupSelection(itemGroups: ArrayMin1<ICartItemV2DTOWithId>[]) {
    // Set of item IDs that are selected for editing.
    const [selectedItemIds, setSelectedItemIds] = React.useState<IdSet>({});
    // Items in a group are always selected all or none, so if one item in a group is selected
    // consider the group selected.
    const selectedItemGroups = itemGroups.filter(group => group.some(item => selectedItemIds[item.id]));

    const setGroupSelected = (group: ICartItemV2DTOWithId[], selected: boolean) => {
        if (selected) {
            setSelectedItemIds(oldValue => ({ ...oldValue, ...getIdSet(group) }));
        } else {
            setSelectedItemIds(oldValue =>
                _.omit(
                    oldValue,
                    group.map(item => item.id),
                ),
            );
        }
    };

    return { setGroupSelected, selectedItemGroups };
}

export type ToastSetter = (toastProps: Pick<ToastProps, 'headline' | 'description' | 'icon' | 'variant'>) => void;

/** Vends a function to submit all of the edits made so far. */
export function useSubmitUpdates(args: { orderId: string; onClose: (props: OnCloseOrderEditProps) => void }) {
    const { orderId, onClose } = args;
    const [submitMtn, { loading }] = useUpdateOrderItemsV2PracticeMutation();
    const { enqueueSnackbar } = useSnackbar();

    const submit = async (changedItems: ICartItemV2DTOWithId[]) => {
        const items_v2_by_sku =
            OrderItemV2InputUtils.getOrderItemV2InputBySKU(changedItems.map(ItemEditorV2Utils.cleanItem)) ?? {};

        await submitMtn({
            variables: {
                data: {
                    orderId,
                    items_v2_by_sku,
                },
            },
        })
            .then(() => {
                enqueueSnackbar(`Order edits submitted, we're on it!`, { variant: 'success' });

                onClose({ shouldRefetch: true, shouldTrackSubmission: true });
            })
            .catch(error => {
                enqueueSnackbar(`Failed to submit edits, error: ${error}`, { variant: 'error' });
            });
    };

    return {
        submit,
        loading,
    };
}

export function useCancelAndResubmit(args: {
    orderId: string;
    onClose: (props: OnCloseOrderEditProps) => void;
    openOrder?: (orderId: string, e?: React.MouseEvent) => void;
}) {
    const { orderId, onClose, openOrder } = args;
    const [submitMtn, { loading }] = useCancelAndResubmitMutation();
    const { enqueueSnackbar } = useSnackbar();

    const submit = async (items: ICartItemV2DTOWithId[]) => {
        const items_v2_by_sku =
            OrderItemV2InputUtils.getOrderItemV2InputBySKU(items.map(ItemEditorV2Utils.cleanItem)) ?? {};

        await submitMtn({
            variables: {
                data: {
                    order_id: orderId,
                    // Field will be deprecated soon
                    updates: [],
                    items_v2_by_sku,
                },
            },
        })
            .then(response => {
                enqueueSnackbar(`Edits to your item have been applied successfully as a new order.`, {
                    variant: 'success',
                });

                onClose({ shouldRefetch: false, shouldTrackSubmission: true });

                // We redirect users to the new order if they submit a cancel & resubmit
                if (response.data?.cancelAndResubmit.replaced_by_ids?.[0] && openOrder) {
                    openOrder(response.data?.cancelAndResubmit.replaced_by_ids?.[0]);
                }
            })
            .catch(error => {
                enqueueSnackbar(`Failed to submit edits, error: ${error}`, { variant: 'error' });
            });
    };

    return {
        submit,
        loading,
    };
}

// In this filter we want to filter out items that can not get added at checkout, like Die Only, Pink Porcelain, Reduction Coping...
// These items will get added via ops or automation after the order is placed. If we keep them in when re-submiting the order, it will
// lead to split orders with all items in one order except for these "Other" items which will live in a 2nd order. This created a poor UX
// for doctors and that's why we're filtering them out here.
export const filterItemsForCancelAndResubmit = (item: ICartItemV2DTOWithId) => {
    if (CartItemV2Utils.itemIsType(item, [LabOrderItemSKUType.Other])) {
        return canOtherItemBeAddedToCart(item);
    }

    return true;
};

// Order edits can do 2 different things: if the tool is in the "OrderEdit" mode, we'll update items directly and leave order as is.
// If order mode is "CancelAndResubmit", we'll instead use a different endpoint that will cancel the current order and place a new one.
export function useOrderEditMutation(
    changedItems: ICartItemV2DTOWithId[],
    allItems: ICartItemV2DTOWithId[],
    orderEditMode: OrderEditMode,
    onSubmit: (items: ICartItemV2DTOWithId[], orderEditMode: OrderEditMode) => void,
) {
    return React.useCallback(() => {
        if (orderEditMode === OrderEditMode.OrderEdit) {
            return onSubmit(changedItems, OrderEditMode.OrderEdit);
        } else if (orderEditMode === OrderEditMode.CancelAndResubmit) {
            const filteredItems = allItems.filter(filterItemsForCancelAndResubmit);
            return onSubmit(filteredItems, OrderEditMode.CancelAndResubmit);
        }

        return null;
    }, [onSubmit, allItems, changedItems, orderEditMode]);
}

// Helpers for making pages.
export interface PageFactory {
    useMakeItemSelectPage: (goPageForwardArg: () => void) => OrderEditPage;
    makeExtrasPage: () => OrderEditPage;
    makeItemPage: (itemGroup: ArrayMin1<ICartItemV2DTOWithId>) => OrderEditPage;
    makeReviewPage: () => OrderEditPage;
}

/** Vends the current page, functions to go forward and back through pages, etc. */
export function usePageState(args: {
    showExtrasPage: boolean;
    selectedItemGroups: ArrayMin1<ICartItemV2DTOWithId>[];
    pageFactory: PageFactory;
}) {
    const { showExtrasPage, selectedItemGroups, pageFactory } = args;

    // Keep track of the current page index in state.
    const [currentPageIndex, setCurrentPageIndex] = React.useState(0);

    const goPageForward = () => setCurrentPageIndex(currentPageIndex + 1);
    const goPageBack = () => setCurrentPageIndex(currentPageIndex - 1);

    // Generate page array.
    // There is an item-group-select page, one page per selected group, and a final review page.
    // We ALWAYS want show the item select page, for the updated C/R flow which gives the ability to add/delete items
    const basePages = [
        pageFactory.useMakeItemSelectPage(goPageForward),
        pageFactory.makeExtrasPage(),
        ...selectedItemGroups.map(itemGroup => pageFactory.makeItemPage(itemGroup)),
        pageFactory.makeReviewPage(),
    ];
    const pages = basePages.flatMap(page => (!showExtrasPage && page.type === 'extras' ? [] : page));

    return {
        currentPage: pages[currentPageIndex],
        isFirstPage: currentPageIndex === 0,
        isLastPage: currentPageIndex + 1 === pages.length,
        goPageForward,
        goPageBack,
    };
}
