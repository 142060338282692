import { getPresetTitle } from '../../GuidedWaxup.util';
import { useGuidedWaxupContext } from '../../state/GuidedWaxupContext';
import { MobileRejectionNotesContainer } from '../MobileRejectionNotesContainer';
import { UnfinishedPresetsMessage } from '../UnfinishedPresetsMessage';
import { StructuredRejectionNotesContent } from './StructuredRejectionNotesContent';
import { useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

/**
 * The container for our structured notes.
 *
 * You can find the figma here: https://www.figma.com/design/C3hnlaLEorlXlJUZZ07b7t/Feature%3A-DDP-Improvements-2024?node-id=6920-111315&node-type=frame&m=dev
 */
export const StructuredRejectionNotes: React.VFC<{
    onClose: () => any;
    onBack: () => any;
    onCancel?: () => any;
    isOpen: boolean;
    isRejected: boolean;
    closeLabel: string;
}> = ({ onBack, onCancel, onClose, isOpen, isRejected, closeLabel }) => {
    const { selectedTab, unfinishedPresets } = useGuidedWaxupContext();
    const presetTitle = React.useMemo(() => getPresetTitle(selectedTab), [selectedTab]);
    const isMobile = useScreenIsMobileOrVerticalTablet();

    if (!isRejected) {
        return null;
    }

    if (isMobile) {
        return (
            <MobileRejectionNotesContainer
                isOpen={isOpen}
                closeLabel={closeLabel}
                onBack={onBack}
                onCancel={onCancel}
                onClose={onClose}
                closeDisabled={unfinishedPresets.includes(selectedTab)}
                title={`${presetTitle} Rejection Notes`}
                footerPreface={<UnfinishedPresetsMessage />}
            >
                <StructuredRejectionNotesContent onCancel={onCancel} />
            </MobileRejectionNotesContainer>
        );
    }

    return <StructuredRejectionNotesContent onCancel={onCancel} />;
};
