import { useOpenOrderDetailOps } from '../../../../../../utils/router/useOpenOrderDetail';
import { OrderCompareOrderBlock } from './OrderCompareOrderBlock.graphql';
import { getOrderCompareOrderItemsKeyMap } from './OrderCompareOrderItems.graphql';
import { applyComparison } from './applyComparison';
import { useQuery } from '@apollo/client';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { LoadBlocker } from '@orthly/ui';
import { Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const AdminOrderCompareContentLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderCompareContentLabSalesOrder_Fragment on LabSalesOrderDTO {
        id
        ...AdminOrderCompareOrderBlockLabSalesOrder_Fragment
        ...AdminOrderCompareOrderItemsLabSalesOrder_Fragment
    }
`);

const AdminOrderCompareContentGetLabSalesOrdersInRefabChain_Query = graphql(`
    query AdminOrderCompareContentGetLabSalesOrdersInRefabChain_Query($id: String!) {
        getLabSalesOrdersInRefabChain(id: $id) {
            ...AdminOrderCompareContentLabSalesOrder_Fragment
        }
    }
`);

const OrderCompareRootWrapper = styled('div')({
    width: '100%',
    padding: '24px 12px',
});

const OrderCompareOrdersWrapper = styled('div')(({ orderCount }: { orderCount: number }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${orderCount}, 420px)`,
    gridAutoRows: 'min-content',
    columnGap: 24,
    rowGap: 0,
    marginTop: 24,
}));

interface OrderCompareContentProps {
    orderId: string;
}

export const OrderCompareContent: React.VFC<OrderCompareContentProps> = ({ orderId }) => {
    const openOrder = useOpenOrderDetailOps();

    const { data: salesOrderData, loading } = useQuery(AdminOrderCompareContentGetLabSalesOrdersInRefabChain_Query, {
        variables: { id: orderId },
    });

    const salesOrders = getFragmentData(
        AdminOrderCompareContentLabSalesOrder_Fragment,
        salesOrderData?.getLabSalesOrdersInRefabChain,
    );

    const containerRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        const containerEl = containerRef.current;
        if (containerEl) {
            // applyComparison will directly modify the dom so react is not aware of the changes
            // this is okay because we do not re-render the component after loading
            requestAnimationFrame(() => applyComparison(containerEl));
        }
    }, [salesOrders]);

    const itemsKeyMap = getOrderCompareOrderItemsKeyMap(salesOrders ?? []);

    return (
        <LoadBlocker blocking={loading}>
            <OrderCompareRootWrapper>
                <Text variant={'h5'}>Compare Orders</Text>
                <OrderCompareOrdersWrapper ref={containerRef} orderCount={salesOrders?.length ?? 0}>
                    {salesOrders?.map((salesOrder, index) => (
                        <OrderCompareOrderBlock
                            key={salesOrder.id}
                            salesOrder={salesOrder}
                            previousSalesOrder={salesOrders[index - 1] ?? null}
                            allSalesOrders={salesOrders}
                            itemsKeyMap={itemsKeyMap}
                            openOrder={openOrder}
                        />
                    ))}
                </OrderCompareOrdersWrapper>
            </OrderCompareRootWrapper>
        </LoadBlocker>
    );
};
