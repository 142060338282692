import { OpsScreenEnum } from '../../../../../../../redux/ui/ui.types';
import type { ToolbarActionDefinition, ToolbarActionProps } from './ToolbarActionDefinition';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import React from 'react';
import { useHistory } from 'react-router-dom';

const MarkNeedsRefabricationAction: React.VFC<ToolbarActionProps> = ({ order }) => {
    const history = useHistory();
    React.useEffect(() => {
        history.push(`/${OpsScreenEnum.refab_flowops}/${order.id}`);
    }, [history, order.id]);
    return null;
};

export const MarkNeedsRefabricationToolbarActionDef: ToolbarActionDefinition = {
    Component: MarkNeedsRefabricationAction,
    label: 'Create refabrication',
    capabilityRequirement: 'order.refabrication.create',
    isVisible: (order: LabsGqlOrder) => {
        return Boolean(order.can_refabricate);
    },
};
