import { LabsGqlAlignerPlan } from '@orthly/graphql-schema';
import { AlignerPlan } from '@orthly/items';

const ALIGNER_PLAN_GQL_REVERSE_MAP: { [_ in LabsGqlAlignerPlan]: AlignerPlan } = {
    [LabsGqlAlignerPlan.DandyMax]: AlignerPlan.DandyMax,
    [LabsGqlAlignerPlan.Dandy20]: AlignerPlan.Dandy20,
    [LabsGqlAlignerPlan.OnDemand]: AlignerPlan.OnDemand,
};

export function getAlignerPlanFromGql(treatment_plan: { dandy_plan: LabsGqlAlignerPlan }): AlignerPlan;
export function getAlignerPlanFromGql(
    treatment_plan?: { dandy_plan?: LabsGqlAlignerPlan | null } | {} | null,
): AlignerPlan | undefined;
export function getAlignerPlanFromGql(
    treatment_plan?: { dandy_plan?: LabsGqlAlignerPlan | null } | {} | null,
): AlignerPlan | undefined {
    if (treatment_plan && 'dandy_plan' in treatment_plan && treatment_plan.dandy_plan) {
        return ALIGNER_PLAN_GQL_REVERSE_MAP[treatment_plan.dandy_plan];
    }
    return undefined;
}
