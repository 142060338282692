import { ListSalesforcePriceMappingOpts_Query } from './EditPriceName.graphql';
import type { PricingDetailTableRow, RefetchPrice } from './PricingRoot.types';
import { useUpdatedPricingQuery } from './hooks/useUpdatedPricingQuery.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type {
    LabsGqlAddPriceApplicationRuleCommand,
    LabsGqlCreatePriceCommand,
    LabsGqlRemovePriceApplicationRuleCommand,
    LabsGqlUpsertPartnerPriceConfigCommand,
} from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { LoadBlocker, QuickForm, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Button, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

interface CreatePriceProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

type CreateFormFields = Omit<LabsGqlCreatePriceCommand, 'partner_prices'> & {
    partner_prices: {
        price_dollars: number;
        partner_id: string;
    }[];
};

const ListDisplayRuleOpts_Query = graphql(`
    query ListDisplayRuleOptions {
        listDisplayRuleOptions {
            material_types
            unit_types
        }
    }
`);

const CreatePrice_Mutation = graphql(`
    mutation CreatePrice($data: CreatePriceCommand!) {
        createPrice(data: $data) {
            id
        }
    }
`);

export const CreatePrice: React.FC<CreatePriceProps> = ({ open, setOpen }) => {
    const { refetchPrices, practiceOpts } = useUpdatedPricingQuery();
    const { data: { listSalesforcePriceNameMappings: sfPriceNames = [] } = {}, loading: sfPriceNamesLoading } =
        useQuery(ListSalesforcePriceMappingOpts_Query);
    const { data: { listDisplayRuleOptions: displayOpts } = {}, loading } = useQuery(ListDisplayRuleOpts_Query);
    const createPrice = useMutation(CreatePrice_Mutation);
    const { submit, submitting } = useRootActionCommand(createPrice, {
        successMessage: 'Custom Field created!',
        onSuccess: async () => {
            await refetchPrices();
            setOpen(false);
        },
    });

    return (
        <RootActionDialog
            open={open}
            loading={submitting || loading || sfPriceNamesLoading}
            title={'Create Pricing Config'}
            setOpen={setOpen}
            buttonText={'Create Custom Field'}
            CustomButton={() => null}
            content={
                <QuickForm<CreateFormFields>
                    fields={{
                        name: { type: 'text', helperText: 'Unique name for this price config' },
                        salesforce_price_mapping: {
                            type: 'select',
                            optional: true,
                            helperText: 'Mapping to Salesforce price preset property name',
                            options: sfPriceNames.map(value => ({ value, label: value.replace('__c', '') })),
                        },
                        partner_prices: {
                            type: 'array',
                            of: {
                                type: 'nested',
                                fields: {
                                    partner_id: {
                                        type: 'select',
                                        label: 'Partner',
                                        options: practiceOpts,
                                        layout: { xs: 6 },
                                    },
                                    price_dollars: {
                                        type: 'number',
                                        label: 'Price Dollars',
                                        fieldProps: { InputProps: { startAdornment: '$ ' } },
                                        layout: { xs: 6 },
                                    },
                                },
                            },
                        },
                        application_rules: {
                            type: 'array',
                            label: 'Applies For',
                            of: {
                                type: 'nested',
                                fields: {
                                    unit_type: {
                                        type: 'select',
                                        label: 'Unit Type',
                                        options: displayOpts?.unit_types.map(value => ({ value })) || [],
                                        layout: { xs: 6 },
                                    },
                                    material_type: {
                                        type: 'select',
                                        label: 'Material Type',
                                        optional: true,
                                        helperText: 'Leave blank to apply to any material type with this unit type',
                                        options:
                                            displayOpts?.material_types.map(value => ({
                                                value,
                                            })) || [],
                                        layout: { xs: 6 },
                                    },
                                },
                            },
                        },
                    }}
                    initialValues={{}}
                    onSubmit={async formResult => {
                        const { partner_prices, ...vars } = formResult;
                        const salesforce_price_mapping =
                            (formResult.salesforce_price_mapping ?? '').length > 0
                                ? formResult.salesforce_price_mapping
                                : undefined;
                        const command: LabsGqlCreatePriceCommand = {
                            ...vars,
                            salesforce_price_mapping,
                            partner_prices: partner_prices.map(p => ({
                                price_cents: p.price_dollars * 100,
                                partner_id: p.partner_id,
                            })),
                            application_rules: vars.application_rules.map(v => ({
                                ...v,
                                material_type: v.material_type || null,
                            })),
                        };
                        await submit({ data: command });
                    }}
                />
            }
        />
    );
};

interface AddPartnerPriceConfigProps {
    price: PricingDetailTableRow;
    refetch: RefetchPrice;
}

const UpsertPartnerPriceConfig_Mutation = graphql(`
    mutation UpsertPartnerPriceConfig($data: UpsertPartnerPriceConfigCommand!) {
        upsertPartnerPriceConfig(data: $data) {
            id
        }
    }
`);

export const UpsertPartnerPriceConfig: React.FC<AddPartnerPriceConfigProps> = ({ price, refetch }) => {
    const [open, setOpen] = React.useState(false);
    const upsertPartnerPrice = useMutation(UpsertPartnerPriceConfig_Mutation);
    const { submit, submitting } = useRootActionCommand(upsertPartnerPrice, {
        successMessage: 'Partner Rule updated',
        onSuccess: async () => {
            await refetch();
            setOpen(false);
        },
    });

    const { practiceOpts } = useUpdatedPricingQuery();
    const [labeledPartnerOpts, setLabeledPartnerOpts] = React.useState<{ value: string; label: string }[]>([]);

    React.useEffect(() => {
        const existingRuleMap = price.partner_prices.reduce(
            (acc, p) => {
                acc[p.partner_id] = p;
                return acc;
            },
            {} as Record<string, { partner_id: string; price_cents: number }>,
        );
        const localPartnerOpts = practiceOpts.map(opt => {
            const existingRule = existingRuleMap[opt.value];
            if (existingRule) {
                return { ...opt, label: `${opt.label} (${Format.currency(existingRule.price_cents, 'cents')})` };
            }
            return opt;
        });
        setLabeledPartnerOpts(localPartnerOpts);
    }, [practiceOpts, price.partner_prices]);

    const [partnerPriceDollars, setPartnerPriceDollars] = React.useState<number | undefined>();
    const [partner_id, setPartnerId] = React.useState<string | undefined>();
    if (!labeledPartnerOpts.length) {
        return (
            <Button fullWidth variant={'ghost'} disabled>
                Update / Add Partner Price
            </Button>
        );
    }

    return (
        <RootActionDialog
            open={open}
            loading={submitting}
            title={'Update / Add Partner Price'}
            setOpen={setOpen}
            buttonText={'Add / Update Partner Prices'}
            content={
                <>
                    {labeledPartnerOpts && (
                        <QuickForm<
                            Omit<LabsGqlUpsertPartnerPriceConfigCommand, 'price_id' | 'price_cents'> & {
                                price_dollars: number;
                            }
                        >
                            fields={{
                                partner_id: {
                                    type: 'select',
                                    label: 'Partner',
                                    options: labeledPartnerOpts,
                                    layout: { xs: 6 },
                                },
                                price_dollars: {
                                    type: 'number',
                                    label: 'Price Dollars',
                                    fieldProps: { InputProps: { startAdornment: '$ ' } },
                                    layout: { xs: 6 },
                                },
                            }}
                            initialValues={{ partner_id, price_dollars: partnerPriceDollars }}
                            resetOnInitialValueChange={true}
                            dirtySubmitOnly={true}
                            onChange={formValues => {
                                if (formValues.partner_id) {
                                    const idChanged = formValues.partner_id !== partner_id;
                                    const existingRule = price.partner_prices.find(
                                        p => p.partner_id === formValues.partner_id,
                                    );
                                    const existingPriceDollars = existingRule
                                        ? existingRule.price_cents / 100
                                        : undefined;
                                    if (
                                        partnerPriceDollars !== existingPriceDollars &&
                                        (typeof formValues.price_dollars !== 'number' || idChanged)
                                    ) {
                                        setPartnerPriceDollars(existingPriceDollars);
                                    }
                                    idChanged && setPartnerId(formValues.partner_id);
                                }
                            }}
                            onSubmit={async formResult => {
                                const command: LabsGqlUpsertPartnerPriceConfigCommand = {
                                    price_id: price.id,
                                    price_cents: formResult.price_dollars * 100,
                                    partner_id: formResult.partner_id,
                                };
                                await submit({ data: command });
                            }}
                        />
                    )}
                </>
            }
        />
    );
};

interface RemovePriceApplicationRuleProps {
    data: LabsGqlRemovePriceApplicationRuleCommand;
}

const RemovePriceApplicationRule_Mutation = graphql(`
    mutation RemovePriceApplicationRule($data: RemovePriceApplicationRuleCommand!) {
        removePriceApplicationRule(data: $data) {
            application_rules {
                material_type
                unit_type
            }
        }
    }
`);

export const RemovePriceApplicationRule: React.FC<RemovePriceApplicationRuleProps> = ({ data }) => {
    const { refetchSinglePrice } = useUpdatedPricingQuery();
    const removePriceApplicationRule = useMutation(RemovePriceApplicationRule_Mutation);
    const { submit, submitting } = useRootActionCommand(removePriceApplicationRule, {
        onSuccess: async () => {
            await refetchSinglePrice({ variables: { priceId: data.price_id } });
        },
    });

    return (
        <LoadBlocker blocking={submitting}>
            <Button
                startIcon={'DeleteIcon'}
                onClick={async () => {
                    await submit({ data });
                }}
                variant={'text'}
                style={{ color: FlossPalette.ATTENTION }}
            >
                Remove
            </Button>
        </LoadBlocker>
    );
};

interface AddPriceApplicationRuleProps {
    priceId: string;
}

type AddConfigVars = Omit<LabsGqlAddPriceApplicationRuleCommand, 'price_id'>;

const AddPriceApplicationRule_Mutation = graphql(`
    mutation AddPriceApplicationRule($data: AddPriceApplicationRuleCommand!) {
        addPriceApplicationRule(data: $data) {
            id
        }
    }
`);

export const AddPriceApplicationRule: React.FC<AddPriceApplicationRuleProps> = ({ priceId }) => {
    const { refetchSinglePrice } = useUpdatedPricingQuery();
    const [open, setOpen] = React.useState(false);

    const addPriceApplication = useMutation(AddPriceApplicationRule_Mutation);
    const { submit, submitting } = useRootActionCommand(addPriceApplication, {
        successMessage: 'Price application config added!',
        onSuccess: () => {
            refetchSinglePrice({ variables: { priceId } });
            setOpen(false);
        },
    });
    const { data: { listDisplayRuleOptions: displayOpts } = {}, loading } = useQuery(ListDisplayRuleOpts_Query);

    return (
        <RootActionDialog
            open={open}
            loading={submitting || loading}
            title={'Add Pricing Unit/Material Config'}
            setOpen={setOpen}
            buttonText={'Add Unit/Material Config'}
            content={
                <QuickForm<AddConfigVars>
                    fields={{
                        unit_type: {
                            type: 'select',
                            label: 'Unit Type',
                            options: displayOpts?.unit_types.map(value => ({ value })) || [],
                            layout: { xs: 6 },
                        },
                        material_type: {
                            type: 'select',
                            label: 'Material Type',
                            optional: true,
                            helperText: 'Leave blank to apply to any material type with this unit type',
                            options:
                                displayOpts?.material_types.map(value => ({
                                    value,
                                })) || [],
                            layout: { xs: 6 },
                        },
                    }}
                    initialValues={{}}
                    onSubmit={async (formResult, actions) => {
                        await submit({
                            data: { price_id: priceId, ...formResult, material_type: formResult.material_type || null },
                        });
                        actions.resetForm();
                    }}
                />
            }
        />
    );
};
