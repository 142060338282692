import { useRowsFromCsv } from '../useCsvImport';
import type { PartnerPricesV2BulkUploaderProps, PartnerPricesV2CsvRow } from './PartnerPricesV2.types';
import { PartnerPricesV2UploadValidationError } from './PartnerPricesV2.types';
import type { LabsGqlBulkUpsertOrganizationPricesMutationVariables } from '@orthly/graphql-operations';
import { useBulkUpsertOrganizationPricesMutation } from '@orthly/graphql-react';
import type { LabsGqlUpsertOrganizationPrices } from '@orthly/graphql-schema';
import type { SpreadsheetAcceptedCellTypes } from '@orthly/ui';
import { CsvUploadSpreadsheetDisplay, LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import { Button, stylesFactory, Collapse, Grid } from '@orthly/ui-primitives';
import { SimpleDropzone } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory<{ inputRows: PartnerPricesV2CsvRow[] }>(() => ({
    dropzoneContainer: {
        padding: '10px 30px',
        display: ({ inputRows }) => (inputRows.length > 0 ? 'none' : undefined),
    },
    collapseContainer: {
        padding: 20,
    },
    collapse: {
        width: '100%',
    },
}));

export const PartnerPricesV2BulkUploader: React.FC<PartnerPricesV2BulkUploaderProps> = ({
    partnerData,
    partnerPricesData,
    partnerPricesHaveBeenUploaded,
    inputRows,
    setInputRows,
    setOpenBulkImport,
}) => {
    const classes = useStyles({ inputRows });
    const columns = ['Price ID', 'Price Name', 'Partner ID', 'Partner Name', 'Price Dollars'];
    const keyedPartnerIds = _.keyBy(partnerData, 'id');
    const keyedPriceIds = _.keyBy(partnerPricesData, 'id');
    const [uploadHasValidationErrors, setUploadHasValidationErrors] = React.useState<boolean>(false);

    const [submitMtn] = useBulkUpsertOrganizationPricesMutation();
    const mtnSubmitter = (variables: LabsGqlBulkUpsertOrganizationPricesMutationVariables) => submitMtn({ variables });
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        onSuccess: () => {
            setInputRows([]);
            setOpenBulkImport(false);
        },
        closeOnComplete: true,
        successMessage: () => [
            `Successfully added ${inputRows.length} partner price${inputRows.length > 1 ? 's' : ''}!`,
            {},
        ],
    });

    async function onSubmit() {
        const upserts: LabsGqlUpsertOrganizationPrices[] = inputRows
            ? inputRows.map(r => {
                  return {
                      organization_id: r.partner_id,
                      price_id: r.price_id,
                      price_cents: Math.round(r.price_dollars * 100),
                  };
              })
            : [];
        await submit({ data: { upserts } });
    }

    const onDropAccepted = useRowsFromCsv({
        checkColumns: columns => {
            const requiredColumns = ['price_id', 'price_name', 'partner_id', 'partner_name', 'price_dollars'];
            if (!requiredColumns.every(c => columns.includes(c))) {
                window.alert('Required columns missing');
                return false;
            }
            return true;
        },
        onValid: (rows: Record<string, string>[]) => {
            setInputRows(
                rows
                    .filter(row => typeof row.partner_id === 'string' && row.price_dollars !== '')
                    .map(row => ({
                        price_id: row.price_id ?? '',
                        price_name: row.price_name ?? '',
                        partner_id: row.partner_id ?? '',
                        partner_name: row.partner_name ?? '',
                        price_dollars: parseFloat(row.price_dollars ?? ''),
                    })),
            );
        },
    });

    return (
        <LoadBlocker blocking={submitting}>
            <Grid container className={classes.dropzoneContainer}>
                <SimpleDropzone
                    wrapperStyle={{ minHeight: 40, padding: '24px 0px' }}
                    options={{ onDropAccepted, multiple: false }}
                />
            </Grid>
            <Grid container alignItems={'center'} wrap={'nowrap'} className={classes.collapseContainer}>
                <Grid container>
                    <Collapse in={partnerPricesHaveBeenUploaded} className={classes.collapse}>
                        <CsvUploadSpreadsheetDisplay
                            columnLabels={columns}
                            inputRows={inputRows}
                            validationRules={{
                                price_id: {
                                    test: (val: SpreadsheetAcceptedCellTypes) => !Object.hasOwn(keyedPriceIds, val),
                                    error: PartnerPricesV2UploadValidationError.UNKNOWN_PRICE_ID,
                                },
                                partner_id: {
                                    test: (val: SpreadsheetAcceptedCellTypes) => !Object.hasOwn(keyedPartnerIds, val),
                                    error: PartnerPricesV2UploadValidationError.UNKNOWN_PARTNER_ID,
                                },
                                price_dollars: {
                                    test: (val: SpreadsheetAcceptedCellTypes) =>
                                        typeof val !== 'number' || Number.isNaN(val) || val < 0,
                                    error: PartnerPricesV2UploadValidationError.INVALID_PRICE_AMOUNT,
                                },
                            }}
                            uploadHasValidationErrors={uploadHasValidationErrors}
                            setUploadHasValidationErrors={setUploadHasValidationErrors}
                        />
                    </Collapse>
                </Grid>
            </Grid>
            <Grid container justifyContent={'flex-end'} spacing={2}>
                <Grid item>
                    <Button
                        variant={'secondary'}
                        onClick={() => {
                            setOpenBulkImport(false);
                            setInputRows([]);
                        }}
                    >
                        Cancel
                    </Button>
                </Grid>
                {partnerPricesHaveBeenUploaded && (
                    <Grid item>
                        <Button variant={'primary'} onClick={onSubmit} disabled={uploadHasValidationErrors}>
                            Upload & add partner prices
                        </Button>
                    </Grid>
                )}
            </Grid>
        </LoadBlocker>
    );
};
