import { JsonDevTools } from '../../../../../../components/JsonDevTools';
import type { LabsGqlLabOrderFragment, LabsGqlGetSlaChangesForOrderQuery } from '@orthly/graphql-operations';
import { useGetSlaChangesForOrderQuery } from '@orthly/graphql-react';
import { LabsGqlFieldType } from '@orthly/graphql-schema';
import { dayjsExt } from '@orthly/runtime-utils';
import { ActionCard, PlusIcon, LoadBlocker, makeLazyDialog, RootActionDialog } from '@orthly/ui';
import { FlossPalette, stylesFactory, Text, Button, Collapse, Grid, BlockIcon } from '@orthly/ui-primitives';
import { OrderDetailAccordionBlock, SlaPreviewTable } from '@orthly/veneer';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    listItem: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        background: 'none',
        border: 'none',
        textAlign: 'left',
        padding: '5px 0',
        cursor: 'pointer',
    },
    listItemDisabled: {
        cursor: 'not-allowed',
    },
    badge: {
        padding: 5,
        borderRadius: 10,
        marginLeft: 16,
    },
    dateBadge: {
        background: FlossPalette.STAR_GRASS,
        color: FlossPalette.WHITE,
    },
    noModBadge: {
        background: FlossPalette.GRAY,
        color: FlossPalette.WHITE,
    },
}));

interface OrderSlaDebuggerModalProps {
    update: LabsGqlGetSlaChangesForOrderQuery['getOrderSlaChanges'][0];
    order: LabsGqlLabOrderFragment;
}

const useOrderSlaDebuggerModal = makeLazyDialog<OrderSlaDebuggerModalProps>(({ update, open, setOpen }) => {
    const { event, sla } = update;
    return (
        <RootActionDialog
            title={event.name}
            loading={false}
            open={open}
            setOpen={setOpen}
            hideButton={true}
            dialogProps={{ maxWidth: 'md', fullWidth: true }}
            content={<JsonDevTools title={'Full SLA Object'} dataType={'sla'} src={sla} defaultExpanded={true} />}
        />
    );
});

const OrderSlaDebuggerModalButton: React.FC<OrderSlaDebuggerModalProps> = props => {
    const [setOpen, modal] = useOrderSlaDebuggerModal(props);
    return (
        <>
            <Button size={'small'} variant={'primary'} onClick={() => setOpen(true)} style={{ margin: '16px 0' }}>
                More info
            </Button>
            {modal}
        </>
    );
};

interface OrderSlaDebuggerBlockProps {
    order: LabsGqlLabOrderFragment;
}

const OrderSlaDebuggerBlockInner: React.FC<OrderSlaDebuggerBlockProps> = ({ order }) => {
    const [showAllEvents, setShowAllEvents] = React.useState(false);
    const { data, refetch, error, loading } = useGetSlaChangesForOrderQuery({
        variables: {
            showAllEvents,
            order_id: order.id,
        },
    });
    const [selectedRow, setSelectedRow] = React.useState(-1);
    const classes = useStyles();
    React.useEffect(() => {
        void refetch();
    }, [order.updated_at, refetch]);

    return (
        <LoadBlocker blocking={loading}>
            {!data && !error && <p>Loading ...</p>}
            {error && (
                <ActionCard
                    style={{ marginTop: 16 }}
                    variant={'alert'}
                    title={'Failed to load SLA changes.'}
                    subtitle={String(error)}
                />
            )}
            <Button
                size={'small'}
                variant={'primary'}
                disabled={loading}
                onClick={() => refetch()}
                style={{ margin: '16px 0' }}
            >
                Reload changes
            </Button>
            <Button
                size={'small'}
                variant={'secondary'}
                disabled={loading}
                onClick={() => setShowAllEvents(!showAllEvents)}
                style={{ margin: '16px 0 16px 16px' }}
            >
                {showAllEvents ? 'Hide non-modifying events' : 'Show all events'}
            </Button>
            {data && (
                <Grid container direction={'column'}>
                    {data.getOrderSlaChanges.map((row, index) => (
                        <div key={index}>
                            <button
                                className={clsx(classes.listItem, {
                                    [classes.listItemDisabled]: row.updates.length === 0,
                                })}
                                disabled={row.updates.length === 0}
                                onClick={() => setSelectedRow(current => (current === index ? -1 : index))}
                            >
                                {row.updates.length === 0 ? (
                                    <BlockIcon style={{ marginRight: 16 }} />
                                ) : (
                                    <PlusIcon style={{ marginRight: 16 }} />
                                )}
                                <Text variant={'body2'}>
                                    {row.event.name} [{row.event.id}]
                                </Text>
                                <span className={clsx(classes.badge, classes.dateBadge)}>
                                    {dayjsExt(row.event.created_at).format('ddd M/D/YY, h:mm A')}
                                </span>
                                {row.updates.length === 0 && (
                                    <span className={clsx(classes.badge, classes.noModBadge)}>
                                        (No SLA modifications)
                                    </span>
                                )}
                            </button>
                            <Collapse in={selectedRow === index}>
                                <div style={{ padding: 16 }}>
                                    <SlaPreviewTable
                                        preview={row.updates.reduce(
                                            (updates, update) => ({
                                                ...updates,
                                                [update.field]: {
                                                    existing:
                                                        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                                                        // eslint-disable-next-line no-nested-ternary
                                                        update.fieldType === LabsGqlFieldType.Object
                                                            ? '(omitted)'
                                                            : update.previous === 'null'
                                                              ? '(not set)'
                                                              : update.previous,
                                                    updated:
                                                        update.fieldType === LabsGqlFieldType.Object
                                                            ? '(omitted)'
                                                            : update.updated,
                                                    skipFormatting: update.fieldType !== 'date',
                                                },
                                            }),
                                            {},
                                        )}
                                    />

                                    <OrderSlaDebuggerModalButton order={order} update={row} />
                                </div>
                            </Collapse>
                        </div>
                    ))}
                </Grid>
            )}
        </LoadBlocker>
    );
};

export const OrderSlaDebuggerBlock: React.FC<OrderSlaDebuggerBlockProps> = ({ order }) => {
    return (
        <OrderDetailAccordionBlock title={`SLA Debugger (v${order.manufacturer_sla.version ?? 2})`} variant={'full'}>
            <OrderSlaDebuggerBlockInner order={order} />
        </OrderDetailAccordionBlock>
    );
};
