import type { SidebarOverlayButtonProps } from '../../Sidebar/SidebarOverlay';
import { SIDEBAR_OVERLAY_WIDTH } from '../../Sidebar/SidebarOverlay';
import { Text, FlossPalette, stylesFactory, Button, Drawer, Grid, CloseIcon } from '@orthly/ui-primitives';
import React from 'react';

// TODO: Make a storybook for this component

interface OrderTaskSidebarOverlayProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    title: React.ReactNode;
    children: React.ReactNode;
    header?: React.ReactNode;
    primaryButton?: SidebarOverlayButtonProps;
    secondaryButton?: SidebarOverlayButtonProps;
    tertiaryButton?: SidebarOverlayButtonProps;
    blocked?: boolean;
}

const useStyles = stylesFactory(() => ({
    scrollBody: { minHeight: 'min-content' },
    scrollWrapper: { flexGrow: 1, maxHeight: 'inherit', overflowY: 'auto' },
    drawerWrapper: {
        zIndex: 1200, // 1200 is above the sidebar's z-index, but below the material paper's z-index (1300)
        background: 'white',
        width: SIDEBAR_OVERLAY_WIDTH,
        height: '100%',
    },
    buttonContainer: {
        padding: '4px',
    },
    tertiaryButton: {
        backgroundColor: FlossPalette.WHITE,
        color: FlossPalette.GRAY,
        '&:hover': { background: FlossPalette.REVIEW_RED, color: FlossPalette.WHITE },
    },
}));

export const OrderTaskSidebarOverlay: React.VFC<OrderTaskSidebarOverlayProps> = props => {
    const { setOpen, open, title, primaryButton, secondaryButton, tertiaryButton, blocked } = props;

    const styles = useStyles();

    return (
        <Drawer
            hideBackdrop
            variant={'temporary'}
            style={{ width: 0 }}
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{ elevation: 0 }}
        >
            <Grid container direction={'column'} wrap={'nowrap'} className={styles.drawerWrapper}>
                {/* Common header */}
                <Grid
                    container
                    direction={'row'}
                    style={{ borderBottom: '1px solid #ECEAE6', height: 64, flexShrink: 0 }}
                >
                    <Grid item xs={11}>
                        <Text variant={'h5'} style={{ padding: '16px 0px 0px 16px' }}>
                            {title}
                        </Text>
                    </Grid>
                    <Grid item xs={1} style={{ margin: 'auto' }}>
                        <CloseIcon
                            fontSize={'small'}
                            style={{ color: FlossPalette.STAR_GRASS, cursor: 'pointer' }}
                            onClick={() => setOpen(false)}
                        />
                    </Grid>
                </Grid>

                <Grid container item direction={'column'}>
                    {props.header}
                </Grid>

                {/* Body content */}
                <Grid container item direction={'column'} wrap={'nowrap'} className={styles.scrollWrapper}>
                    <Grid container item direction={'column'} wrap={'nowrap'} className={styles.scrollBody}>
                        {props.children}
                    </Grid>
                </Grid>

                {/* Navigation Buttons */}
                <Grid container item direction={'row'} style={{ padding: '8px 16px' }}>
                    {secondaryButton && (
                        <Grid item xs={6} className={styles.buttonContainer}>
                            <Button
                                fullWidth
                                endIcon={secondaryButton.icon}
                                variant={'contained'}
                                onClick={secondaryButton.onClick}
                                disabled={(blocked || secondaryButton.disabled) ?? false}
                            >
                                {secondaryButton.label}
                            </Button>
                        </Grid>
                    )}
                    {primaryButton && (
                        <Grid item xs={secondaryButton ? 6 : 12} className={styles.buttonContainer}>
                            <Button
                                fullWidth
                                endIcon={primaryButton.icon}
                                variant={'contained'}
                                onClick={primaryButton.onClick}
                                disabled={(blocked || primaryButton.disabled) ?? false}
                            >
                                {primaryButton.label}
                            </Button>
                        </Grid>
                    )}
                    {tertiaryButton && (
                        <Grid item xs={12} className={styles.buttonContainer}>
                            <Button
                                fullWidth
                                endIcon={tertiaryButton.icon}
                                variant={'contained'}
                                onClick={tertiaryButton.onClick}
                                disabled={(blocked || tertiaryButton.disabled) ?? false}
                                className={styles.tertiaryButton}
                            >
                                {tertiaryButton.label}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Drawer>
    );
};
