import { OrderEditRoot } from '../OrderEditRoot';
import { useCancelAndResubmit, useSubmitUpdates } from '../orderEditState';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { OrderToolbar } from '@orthly/dentin';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrderRefetch } from '@orthly/graphql-react';
import type { OnCloseOrderEditProps } from '@orthly/shared-types';
import { OrderEditMode } from '@orthly/shared-types';
import { Dialog } from '@orthly/ui-primitives';
import React from 'react';

export const OrderEditActionDialog: React.VFC<{
    order: LabsGqlOrder;
    isInternal?: boolean;
    isFromQuickOrderActions?: boolean;
    isProvideInstructionsFlow?: boolean;
    InjectedPricingGuideComponent?: React.ReactElement;
    openOrder: (orderId: string, e?: React.MouseEvent) => void;
    isEditOpen: boolean;
    setEditOpen: (isOpen: boolean) => void;
}> = props => {
    const {
        order,
        isInternal,
        isFromQuickOrderActions,
        isProvideInstructionsFlow,
        InjectedPricingGuideComponent,
        openOrder,
        isEditOpen,
        setEditOpen,
    } = props;
    const refetchOrder = useOrderRefetch();

    const canEdit = order.can_edit;
    const location = isInternal ? 'admin' : 'practice';

    const onClose = (props: OnCloseOrderEditProps): void => {
        setEditOpen(false);

        if (props.shouldTrackSubmission) {
            BrowserAnalyticsClientFactory.Instance?.track('All - Edit Order - Submitted', {
                $groups: { order: order.id },
                location,
                mode: canEdit ? 'order_edit' : undefined,
                orderStatus: order.status,
                activeTaskType: order.fulfillment_workflow.active_task?.type,
                isFromQuickOrderActions,
                isProvideInstructions: isProvideInstructionsFlow,
                version: 'v1',
            });
        }

        if (props.shouldRefetch) {
            // `shouldRefetch` is true if and only if edits were submitted.
            refetchOrder(order.id);
        }
    };

    const { loading: isLoadingOrderEdit, submit: submitUpdates } = useSubmitUpdates({ onClose, orderId: order.id });
    const { loading: isLoadingCancelAndResubmit, submit: submitCancelAndResubmit } = useCancelAndResubmit({
        onClose,
        openOrder,
        orderId: order.id,
    });
    const isLoading = isLoadingOrderEdit || isLoadingCancelAndResubmit;

    if (!isEditOpen) {
        return null;
    }

    return (
        <Dialog open={true} fullScreen>
            <OrderToolbar order={order} onClose={() => setEditOpen(false)} />
            <OrderEditRoot
                order={order}
                onClose={onClose}
                InjectedPricingGuideComponent={InjectedPricingGuideComponent}
                isProvideInstructionsFlow={isProvideInstructionsFlow}
                isFromQuickOrderActions={isFromQuickOrderActions}
                onSubmit={async (items, mode) => {
                    if (mode === OrderEditMode.CancelAndResubmit) {
                        await submitCancelAndResubmit(items);
                    } else if (mode === OrderEditMode.OrderEdit) {
                        await submitUpdates(items);
                    }
                }}
                isLoading={isLoading}
            />
        </Dialog>
    );
};
