import { useRowsFromCsv } from '../../../Pricing/useCsvImport';
import type { ManualInvoiceItemCsvRow } from './utils';
import { ManualInvoiceItemValidationError } from './utils';
import type { LabsGqlBulkRegisterInvoiceItemsMutationVariables } from '@orthly/graphql-operations';
import { useBulkRegisterInvoiceItemsMutation } from '@orthly/graphql-react';
import type {
    LabsGqlBulkRegisterInvoiceItemRow,
    LabsGqlPracticeNameAndPrimaryContactInfo,
} from '@orthly/graphql-schema';
import { LabsGqlOneTimeChargeCategory } from '@orthly/graphql-schema';
import type { SpreadsheetAcceptedCellTypes } from '@orthly/ui';
import { CsvUploadSpreadsheetDisplay, LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import { Button, stylesFactory, Collapse, Grid } from '@orthly/ui-primitives';
import { SimpleDropzone } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory<{ inputRows: ManualInvoiceItemCsvRow[] }>(() => ({
    dropzoneContainer: {
        padding: '10px 30px',
        display: ({ inputRows }) => (inputRows.length > 0 ? 'none' : undefined),
    },
    collapseContainer: {
        padding: 20,
    },
    collapse: {
        width: '100%',
    },
}));

function isValidCategory(s: string): s is keyof typeof LabsGqlOneTimeChargeCategory {
    const categories: string[] = Object.values(LabsGqlOneTimeChargeCategory);
    return categories.includes(s);
}

export const ManualInvoiceBulkUploader: React.FC<{
    partnerData: LabsGqlPracticeNameAndPrimaryContactInfo[];
    invoiceItemsHaveBeenUploaded: boolean;
    inputRows: ManualInvoiceItemCsvRow[];
    setInputRows: (inputRows: ManualInvoiceItemCsvRow[]) => void;
    setOpenBulkImport: (openBulkImport: boolean) => void;
}> = ({ partnerData, invoiceItemsHaveBeenUploaded, inputRows, setInputRows, setOpenBulkImport }) => {
    const classes = useStyles({ inputRows });
    const columns = ['Practice ID', 'Category', 'Description', 'Amount'];
    const keyedPartnerIds = _.keyBy(partnerData, 'id');
    const [uploadHasValidationErrors, setUploadHasValidationErrors] = React.useState<boolean>(false);

    const [submitMtn] = useBulkRegisterInvoiceItemsMutation();
    const mtnSubmitter = (variables: LabsGqlBulkRegisterInvoiceItemsMutationVariables) => submitMtn({ variables });
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        onSuccess: () => {
            setInputRows([]);
            setOpenBulkImport(false);
        },
        closeOnComplete: true,
        successMessage: () => [
            `Successfully added ${inputRows.length} manual invoice item${inputRows.length > 1 ? 's' : ''}!`,
            {},
        ],
    });

    async function onSubmit() {
        const rows: LabsGqlBulkRegisterInvoiceItemRow[] = inputRows
            ? inputRows.map(r => {
                  if (!isValidCategory(r.item_category)) {
                      throw new Error('Uncaught validation error in item category');
                  }
                  return {
                      category: LabsGqlOneTimeChargeCategory[r.item_category],
                      practice_id: r.practice_id,
                      description: r.item_description,
                      price_cents: Math.round(r.price_dollars * 100),
                  };
              })
            : [];
        await submit({ data: { rows } });
    }

    const onDropAccepted = useRowsFromCsv({
        checkColumns: columns => {
            const requiredColumns = ['practice_id', 'item_category', 'item_description', 'price_dollars'];
            if (!requiredColumns.every(c => columns.includes(c))) {
                window.alert('Required columns missing');
                return false;
            }
            return true;
        },
        onValid: (rows: Record<string, string>[]) => {
            setInputRows(
                rows
                    .filter(row => typeof row.practice_id === 'string' && row.price_dollars !== '')
                    .map(row => ({
                        practice_id: row.practice_id ?? '',
                        item_category: row.item_category ?? '',
                        item_description: row.item_description ?? '',
                        price_dollars: parseFloat(row.price_dollars?.replace(',', '') ?? ''),
                    })),
            );
        },
    });

    return (
        <LoadBlocker blocking={submitting}>
            <Grid container className={classes.dropzoneContainer}>
                <SimpleDropzone
                    wrapperStyle={{ minHeight: 40, padding: '24px 0px' }}
                    options={{ onDropAccepted, multiple: false }}
                />
            </Grid>
            <Grid container alignItems={'center'} wrap={'nowrap'} className={classes.collapseContainer}>
                <Grid container>
                    <Collapse in={invoiceItemsHaveBeenUploaded} className={classes.collapse}>
                        <CsvUploadSpreadsheetDisplay
                            columnLabels={columns}
                            inputRows={inputRows}
                            validationRules={{
                                practice_id: {
                                    test: (val: SpreadsheetAcceptedCellTypes) => !Object.hasOwn(keyedPartnerIds, val),
                                    error: ManualInvoiceItemValidationError.UNKNOWN_PRACTICE_ID,
                                },
                                item_category: {
                                    test: (val: SpreadsheetAcceptedCellTypes) =>
                                        typeof val === 'string' && !isValidCategory(val),
                                    error: ManualInvoiceItemValidationError.INVALID_ITEM_CATEGORY,
                                },
                                item_description: {
                                    test: (val: SpreadsheetAcceptedCellTypes) => val === '',
                                    error: ManualInvoiceItemValidationError.INVALID_ITEM_DESCRIPTION,
                                },
                                price_dollars: {
                                    test: (val: SpreadsheetAcceptedCellTypes) =>
                                        typeof val !== 'number' || Number.isNaN(val) || val < 0,
                                    error: ManualInvoiceItemValidationError.INVALID_ITEM_AMOUNT,
                                },
                            }}
                            uploadHasValidationErrors={uploadHasValidationErrors}
                            setUploadHasValidationErrors={setUploadHasValidationErrors}
                        />
                    </Collapse>
                </Grid>
            </Grid>
            <Grid container justifyContent={'flex-end'} spacing={2}>
                <Grid item>
                    <Button
                        variant={'secondary'}
                        onClick={() => {
                            setOpenBulkImport(false);
                            setInputRows([]);
                        }}
                    >
                        Cancel
                    </Button>
                </Grid>
                {invoiceItemsHaveBeenUploaded && (
                    <Grid item>
                        <Button variant={'primary'} onClick={onSubmit} disabled={uploadHasValidationErrors}>
                            Upload & add items
                        </Button>
                    </Grid>
                )}
            </Grid>
        </LoadBlocker>
    );
};
