import type { LabsGqlLabSlipAlertFragment } from '@orthly/graphql-operations';
import { SquareIcon, ToolOutlinedIcon, WarningIcon } from '@orthly/ui';
import { stylesFactory, Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    subSection: {
        marginBottom: '0.5rem',
    },
    subSectionTitle: {
        fontSize: '1rem !important',
        fontWeight: 'bold',
    },
    item: {
        fontSize: '0.75rem !important',
    },
}));

/**
 * LabSlip section for displaying notes and alerts to the lab
 * @param labNotes The lab notes information stored as alerts on an Order
 * @returns LabNotes section
 */
export const LabNotes: React.VFC<{ labNotes: LabsGqlLabSlipAlertFragment[] }> = ({ labNotes }) => {
    const classes = useStyles();
    if (labNotes.length === 0) {
        return null;
    }
    return (
        <Grid container className={classes.subSection}>
            <Grid container spacing={1}>
                <Grid item>
                    <ToolOutlinedIcon />
                </Grid>
                <Grid item>
                    <Text variant={'h5'} className={classes.subSectionTitle}>
                        Lab Notes
                    </Text>
                </Grid>
            </Grid>
            <Grid container>
                {labNotes.map((alert, idx) => {
                    return (
                        <Grid container key={idx} spacing={1} wrap={'nowrap'}>
                            <Grid item>{alert.type === 'warning' ? <WarningIcon /> : <SquareIcon />}</Grid>
                            <Grid item>
                                <Text variant={'body1'} className={classes.item}>
                                    {alert.text}
                                </Text>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};
