import type { IMergeableItem, IMergedDentureData, IMergedItem, ISingleToothUnitItem, IOrderItemsBySku } from '../types';
import { isDentureItemList, isSingleToothUnitItem, isImplantItem } from '../types';
import { dentureItemsAreSimilar } from './dentureItem.util';
import * as ItemUtils from './item.util';
import { mergeSimilarRemovableItems } from './removableItem.util';
import type {
    IDentureItem,
    IImplantItem,
    IOrderItemV2DTO,
    IPartialDentureItem,
    IRemovableItem,
    IOrderItemImplantMetadata,
} from '@orthly/items';
import _ from 'lodash';

/**
 * Group Denture order items together
 * when the following conditions are met:
 * Shade data is the same
 * Denture Type is the same
 * Preferences are the same
 * Unit data is the same
 * @param items The list of items to reduce into smaller groups based on similarity
 * @param comparator Function that compares two denture items to determine if they can be merged.
 * @returns List of Merged DentureItems
 */
export function mergeDentureItems(
    items: IOrderItemV2DTO[] | undefined,
    comparator: (itemA: IDentureItem, itemB: IDentureItem) => boolean = dentureItemsAreSimilar,
): IDentureItem[] {
    if (!items || !isDentureItemList(items)) {
        return [];
    }
    const mergedDentures = items.reduce<IMergedDentureData>(
        (merged: IMergedDentureData, item: IDentureItem) => {
            for (const itemGroup of merged.items) {
                if (comparator(itemGroup, item)) {
                    // Use the item with the most amount of detail
                    if (ItemUtils.isSuperset(item.shades, itemGroup.shades)) {
                        itemGroup.shades = item.shades;
                    }
                    if (ItemUtils.isSupersetFields(item.preference_fields, itemGroup.preference_fields)) {
                        itemGroup.preference_fields = item.preference_fields;
                    }
                    return merged;
                }
            }
            merged.items.push({ ...item });
            return merged;
        },
        { items: [] },
    );
    return mergedDentures.items;
}

/**
 * Determine if two Implant order items are similar enough that they can
 * be "merged" into a single item with both of their Unns
 * @param itemA Implant order item to attempt to merge
 * @param itemB 2nd Implant item to attempt to merge with itemA
 * @returns Whether or not the two items are similar enough to be merged
 */
export function implantItemsAreSimilar(itemA: IMergeableItem, itemB: IImplantItem): boolean {
    if (!isImplantItem(itemA)) {
        return false;
    }
    const unitPropsToCompare: (keyof IOrderItemImplantMetadata)[] = [
        'relationship',
        'manufacturer',
        'system',
        'connection_size',
        'part_id',
    ];
    const implantMetadataIsSimilar = _.isEqual(
        _.pick(itemA.unit.implant_metadata, unitPropsToCompare),
        _.pick(itemB.unit.implant_metadata, unitPropsToCompare),
    );
    return (
        implantMetadataIsSimilar &&
        itemA.unit.crown.material === itemB.unit.crown.material &&
        itemA.unit.abutment.material === itemB.unit.abutment.material &&
        ItemUtils.getOcclusalStainingPreference(itemA) === ItemUtils.getOcclusalStainingPreference(itemB) &&
        _.isEqual(itemA.shades, itemB.shades)
    );
}

/**
 * Determine if two SingleToothUnitItem order items are similar enough that they can
 * be "merged" into a single item with both of their Unns.
 * An item can be merged when the following conditions are met:
 * Skus are the same
 * Materials are the same
 * Shade data is the same
 * Occlusal Staining is the same
 * @param itemA SingleToothUnitItem order item to attempt to merge
 * @param itemB 2nd SingleToothUnitItem item to attempt to merge with itemA
 * @returns Whether or not the two items are similar enough to be merged
 */
export function itemsAreSimilar(itemA: IMergeableItem, itemB: ISingleToothUnitItem): boolean {
    if (!isSingleToothUnitItem(itemA)) {
        return false;
    }

    // Check for identical sku
    if (itemA.sku !== itemB.sku) {
        return false;
    }

    // Check for identical material
    if (itemA.unit.material !== itemB.unit.material) {
        return false;
    }

    // Check for identical occlusalStaining
    if (ItemUtils.getOcclusalStainingPreference(itemA) !== ItemUtils.getOcclusalStainingPreference(itemB)) {
        return false;
    }
    // Check for identical shade data
    return _.isEqual(itemA.shades, itemB.shades);
}

/**
 * Group Implant order items together, combining their Unns into `groupedUnns`.
 * The default comparator checks that the following properties of each item are the same:
 * Sku, Material, Shade, Occlusal Staining
 * @param items The list of items to reduce into smaller groups based on similarity
 * @param comparator Function that compares two items to determine if they can be merged.
 * @returns List of merged Implant Items
 */
export function mergeSimilarSingleToothItems(
    items: ISingleToothUnitItem[] | undefined,
    comparator: (itemA: IMergeableItem, itemB: ISingleToothUnitItem) => boolean = itemsAreSimilar,
): IMergedItem[] {
    return mergeSimilarItemsHelper(items, comparator);
}

/**
 * Group Implant order items together, combining their Unns into `groupedUnns`.
 * The default comparator checks that the following conditions are met:
 * Materials are the same
 * Shade data is the same
 * Occlusal Staining is the same
 * @param items The list of items to reduce into smaller groups based on similarity
 * @param comparator Function that compares two items to determine if they can be merged.
 * @returns List of merged Implant Items
 */
export function mergeSimilarImplantItems(
    items: IImplantItem[] | undefined,
    comparator: (itemA: IMergeableItem, itemB: IImplantItem) => boolean = implantItemsAreSimilar,
): IMergedItem[] {
    return mergeSimilarItemsHelper(items, comparator);
}

/**
 * Group SingleToothUnitItem or Implant order items together, combining their Unns into `groupedUnns`
 * when the following conditions are met:
 * Materials are the same
 * Shade data is the same
 * Occlusal Staining is the same
 * @param items The list of items to reduce into smaller groups based on similarity
 * @param comparator Function that compares an item with a merged item to determine if the item can be merged into it.
 * @returns List of Merged SingleToothUnitItems
 */
function mergeSimilarItemsHelper<MergeableItemSubtype extends IMergeableItem>(
    items: MergeableItemSubtype[] | undefined,
    comparator: (itemA: IMergeableItem, itemB: MergeableItemSubtype) => boolean,
): IMergedItem[] {
    if (!items) {
        return [];
    }
    const mergedItemData: IMergedItem[] = items.reduce<IMergedItem[]>(
        (mergedData: IMergedItem[], item: MergeableItemSubtype) => {
            for (const itemGroup of mergedData) {
                if (comparator(itemGroup.defaultItem, item)) {
                    // Merge the item into "itemGroup"
                    const toothNumber = Number.parseInt(`${item.unit.unn}`, 10);
                    itemGroup.groupedUnns = _.sortBy([...itemGroup.groupedUnns, toothNumber]);
                    itemGroup.groupItems.push(item);
                    return mergedData;
                }
            }
            // If item can NOT be combined with any other item in "mergedData.items"
            mergedData.push({
                groupedUnns: [Number.parseInt(`${item.unit.unn}`, 10)],
                defaultItem: item,
                groupItems: [item],
            });
            return mergedData;
        },
        [],
    );
    // Sort all items by unn
    return _.sortBy(mergedItemData, item => item.groupedUnns[0]);
}

/**
 * Skips duplicate partials items by deep-comparing them
 * @param items partials items on the order
 * @param comparator A function that takes two IPartialDentureItems and returns true
 * if they can be merged.
 * @returns A deduplicated array of partials items
 */
export function mergePartialsItems(
    items: IPartialDentureItem[] = [],
    comparator: (itemA: Omit<IPartialDentureItem, 'id'>, itemB: Omit<IPartialDentureItem, 'id'>) => boolean = _.isEqual,
): IPartialDentureItem[] {
    if (items.length === 0) {
        return items;
    }

    const merged: IPartialDentureItem[] = [];
    for (const item of items) {
        // Ignore the ID of the items when doing equality checks
        const itemMissingId = _.omit(item, ['id']);
        if (merged.some(mergedItem => comparator(itemMissingId, _.omit(mergedItem, ['id'])))) {
            continue;
        }

        merged.push(item);
    }

    return merged;
}

/**
 * Merges items that can be rendered as one item on the lab slip. We currently merge
 * Single Tooth Items (Crowns, Inlays, Veneers), Implants,
 * and Removables that contain similar data and preferences.
 * @param allOrderItems a full list of items from a single order
 * @param comparator optional parameter: When merging a group items, use this to compare
 * two items. The comparator tells the merging function if those two items can be merged.
 * @returns The same listed items with any mergeable groups items merged into single items.
 */
export function getMergedItems(
    allOrderItems: IOrderItemV2DTO[],
    comparator?: (itemA: Omit<IOrderItemV2DTO, 'id'>, itemB: Omit<IOrderItemV2DTO, 'id'>) => boolean,
) {
    const itemsBySku: IOrderItemsBySku = _.groupBy(allOrderItems, item => item.sku);
    const mergedCrownItems: IMergedItem[] = mergeSimilarSingleToothItems(itemsBySku.Crown, comparator);
    const mergedInlayItems: IMergedItem[] = mergeSimilarSingleToothItems(itemsBySku.Inlay, comparator);
    const mergedVeneerItems: IMergedItem[] = mergeSimilarSingleToothItems(itemsBySku.Veneer, comparator);

    const mergedImplantItems: IMergedItem[] = mergeSimilarImplantItems(itemsBySku.Implant, comparator);
    const mergedDentureItems: IDentureItem[] = mergeDentureItems(itemsBySku.Denture, comparator);
    const mergedRemovableItems: IRemovableItem[] = mergeSimilarRemovableItems(itemsBySku.Removeable, comparator);
    const mergedPartialsItems: IPartialDentureItem[] = mergePartialsItems(itemsBySku.Partial, comparator);

    return _.compact(
        _.concat<IMergedItem | IOrderItemV2DTO>(
            mergedCrownItems,
            mergedDentureItems,
            mergedInlayItems,
            mergedVeneerItems,
            mergedImplantItems,
            mergedRemovableItems,
            mergedPartialsItems,
            itemsBySku.SurgicalGuide || [],
            itemsBySku.Bridge || [],
            itemsBySku.ImplantBridge || [],
            itemsBySku.TMJ || [],
            itemsBySku.Model || [],
            itemsBySku.Waxup || [],
            itemsBySku.SleepApnea || [],
            itemsBySku.Aligners || [],
            itemsBySku.Other || [],
        ),
    );
}
