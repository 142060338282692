import {
    useOnClickSave,
    CustomViewBottomAction,
    useNavItemRootStyles,
} from '../../../../components/CustomViewListItem';
import { CustomViewNavItemEndIcons } from '../../../../components/CustomViewNavItem';
import { UNSAVED_VIEW_ID } from '../../../../redux/utils/SavedViewActionConstants';
import { useOrdersOverviewAction } from '../../state/OrdersOverview.actions';
import { useOrdersOverviewState } from '../../state/OrdersOverview.selectors';
import type { OrdersOverviewCustomView } from '../../state/OrdersOverview.types';
import { useApolloClient } from '@apollo/client';
import { LabsGqlSavedSearchVisibility } from '@orthly/graphql-schema';
import type { SubNavigationEntry, SubNavigationEntryContainerProps, SubNavigationEntryElementProps } from '@orthly/ui';
import { CustomViewMenu } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, IconButton, Icon } from '@orthly/ui-primitives';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';

function viewIsEdited(savedView: OrdersOverviewCustomView, activeView?: OrdersOverviewCustomView | null): boolean {
    if (!activeView || activeView.id !== savedView.id) {
        return false;
    }
    return (
        !_.isEqual(activeView.criteria, savedView.criteria) ||
        activeView.search !== savedView.search ||
        !_.isEqual(activeView.sort, savedView.sort)
    );
}

function useViewState(viewId: string) {
    return useOrdersOverviewState(s => {
        const activeView = s.view?.id === viewId ? s.view : undefined;
        const saved = s.savedViews[viewId];
        const currentView = activeView ?? saved;
        return {
            view: currentView,
            isEdited: !!saved && !!activeView ? viewIsEdited(saved, activeView) : false,
            isSaved: !!saved || !activeView,
            isActive: !!activeView,
            visibility: currentView?.visibility,
            createdByUser: currentView?.createdByUser,
            favoritedCount: currentView?.favoritedCount,
            createdByUsername: currentView?.createdByUsername,
        };
    });
}

const useContainerStyles = stylesFactory(() => ({
    rootInactive: { '&:hover': { backgroundColor: FlossPalette.DARK_TAN } },
}));

const CustomViewItemContainer: React.FC<SubNavigationEntryContainerProps> = props => {
    const classes = useContainerStyles();
    const { value: viewId } = props;
    const { view, isActive } = useViewState(viewId);
    if (!view) {
        return <>{props.children}</>;
    }
    return <div className={isActive ? undefined : classes.rootInactive}>{props.children}</div>;
};

const useStartIconStyles = stylesFactory(() => ({
    root: { width: 'auto', paddingRight: 8, alignItems: 'center' },
    icon: { color: FlossPalette.STAR_GRASS, fontSize: 24 },
}));

const CustomViewStartIcon: React.FC<SubNavigationEntryElementProps> = props => {
    const classes = useStartIconStyles();
    const client = useApolloClient();
    const saveView = useOrdersOverviewAction('SAVE_VIEW');
    const setEditingView = useOrdersOverviewAction('SET_EDITING_VIEW_ID');
    const { value: viewId } = props;
    const { isEdited, isSaved, visibility, view } = useViewState(viewId);
    const onClickSave = useOnClickSave<OrdersOverviewCustomView>({
        client,
        view,
        viewId,
        visibility,
        setEditingView,
        saveView,
        isEdited,
    });
    return (
        <Grid container className={classes.root}>
            {isSaved && !isEdited ? (
                <Icon icon={'StarRounded'} className={classes.icon} />
            ) : (
                <IconButton style={{ padding: 0 }} onClick={onClickSave}>
                    <Icon icon={'StarBorderRounded'} className={classes.icon} />
                </IconButton>
            )}
        </Grid>
    );
};

const CustomViewEndAction: React.FC<SubNavigationEntryElementProps> = props => {
    const client = useApolloClient();
    const { value: viewId } = props;
    const { visibility, createdByUser, isEdited, isSaved, view, favoritedCount, createdByUsername } =
        useViewState(viewId);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const deleteView = useOrdersOverviewAction('DELETE_VIEW');
    const saveView = useOrdersOverviewAction('SAVE_VIEW');
    const setEditingView = useOrdersOverviewAction('SET_EDITING_VIEW_ID');
    const toggleFavoriteForView = useOrdersOverviewAction('TOGGLE_FAVORITE_VIEW');
    const toggleVisibilityForView = useOrdersOverviewAction('TOGGLE_VIEW_VISIBILITY');
    const copyView = useOrdersOverviewAction('COPY_VIEW');
    const setView = useOrdersOverviewAction('SET_SCREEN');
    const onClickSave = useOnClickSave<OrdersOverviewCustomView>({
        client,
        view,
        viewId,
        visibility,
        setEditingView,
        saveView,
        isEdited,
    });
    if (!view) {
        return null;
    }

    return (
        <Grid container style={{ width: 'auto', alignItems: 'center', flexWrap: 'nowrap' }}>
            <CustomViewNavItemEndIcons
                visibility={visibility}
                isSaved={isSaved}
                createdByUser={createdByUser}
                createdByUsername={createdByUsername}
                favoritedCount={favoritedCount}
                setAnchorEl={setAnchorEl}
            />
            <CustomViewMenu
                isSaved={isSaved}
                isEdited={isEdited}
                isPublic={visibility === LabsGqlSavedSearchVisibility.Public}
                onSave={onClickSave}
                onEdit={async () => await setEditingView(view.id)}
                onCopy={() => copyView(view)}
                onDelete={async () =>
                    window.confirm(`Delete view "${view.title}"?`) && (await deleteView({ client, searchId: view.id }))
                }
                onToggleFavorite={async () =>
                    await toggleFavoriteForView({
                        client,
                        searchId: view.id,
                        favorited: false,
                    })
                }
                onToggleVisibility={async () => await toggleVisibilityForView({ client, searchId: view.id })}
                onReset={() => setView(view.id)}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                isCreatedByUser={createdByUser ?? false}
            />
        </Grid>
    );
};

const OrderCustomViewBottomAction: React.FC<SubNavigationEntryElementProps> = props => {
    const client = useApolloClient();
    const { value: viewId } = props;
    const { visibility, isEdited, view, createdByUser } = useViewState(viewId);
    const saveView = useOrdersOverviewAction('SAVE_VIEW');
    const setEditingView = useOrdersOverviewAction('SET_EDITING_VIEW_ID');
    const setView = useOrdersOverviewAction('SET_SCREEN');
    const copyView = useOrdersOverviewAction('COPY_VIEW');
    const onClickSave = useOnClickSave<OrdersOverviewCustomView>({
        client,
        view,
        viewId,
        visibility,
        setEditingView,
        saveView,
        isEdited,
    });
    return (
        <CustomViewBottomAction
            setView={setView}
            viewId={viewId}
            copyView={view ? () => copyView(view) : undefined}
            onClickSave={onClickSave}
            createdByUser={createdByUser}
        />
    );
};
export function useOrdersCustomViewSidebarEntries(): SubNavigationEntry[] {
    const classes = useNavItemRootStyles();
    const activeView = useOrdersOverviewState(s => s.view);
    const savedViews = useOrdersOverviewState(s => s.savedViews);
    const viewToSubNavEntry = React.useCallback(
        (view: OrdersOverviewCustomView) => {
            const isInactive = view.id !== activeView?.id;
            return {
                label: view.title,
                value: view.id,
                classes: {
                    root: clsx(classes.root, isInactive && classes.inactiveFilterRoot),
                    text: view.id === UNSAVED_VIEW_ID ? classes.unsavedFilterItemText : undefined,
                    textWrapper: classes.textWrapper,
                },
                StartIcon: CustomViewStartIcon,
                EndAction: CustomViewEndAction,
                Container: CustomViewItemContainer,
                BottomAction: OrderCustomViewBottomAction,
                showBottomAction: !isInactive && !!savedViews[view.id] && viewIsEdited(view, activeView),
                showLabelTooltip: true,
            };
        },
        [activeView, classes, savedViews],
    );
    return React.useMemo(() => {
        const unsavedFilterView = activeView?.id === UNSAVED_VIEW_ID ? viewToSubNavEntry(activeView) : undefined;
        const savedViewOptions = _.sortBy(savedViews, v => v.title.toLowerCase()).map<SubNavigationEntry>(
            viewToSubNavEntry,
        );
        return unsavedFilterView ? [unsavedFilterView, ...savedViewOptions] : savedViewOptions;
    }, [savedViews, activeView, viewToSubNavEntry]);
}

export function useActiveOrdersViewIsEdited() {
    return useOrdersOverviewState(s => {
        const activeView = s.view;
        const savedView = activeView ? s.savedViews[activeView.id] : undefined;
        return !!activeView && !!savedView && viewIsEdited(savedView, s.view);
    });
}
