import { LabsUtilsBase } from '@orthly/dentin';
import type { LabsGqlOrderLabSlipPreviewFragment, LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import moment from 'moment';

interface LabSlipFields {
    isRush: boolean;
    dateIn: moment.Moment;
    dueDate: moment.Moment;
    shipDate: moment.Moment;
}

export const labSlipFields = (
    order: LabsGqlOrderLabSlipPreviewFragment | LabsGqlLabOrderForLabSlipFragment,
): LabSlipFields => {
    const { manufacturer_sla: sla } = order;
    const refabDate = LabsUtilsBase.refabricationDate(order);
    return {
        isRush: sla.rush_request?.status === `Accepted`,
        dateIn: moment(refabDate ? refabDate : order.created_at),
        dueDate: moment(sla.manufacturer_committed_due_date),
        shipDate: moment(sla.manufacturer_committed_ship_by_date),
    };
};
