import { OrderDetailBlock } from './OrderDetailBlock';
import { OrderDetailImagesContent } from './OrderDetailImagesBlock';
import { useQuery } from '@apollo/client';
import type {
    FragmentType,
    VeneerOrderDetailLegacyMultiFulfillmentInfoGetLegacyMultiFulfillmentInfoByOrderId_QueryQuery,
} from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { LabsGqlOrderItemSkuType } from '@orthly/graphql-schema';
import { Grid, Text } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';

type LegacyMultiFulfillmentInfo = NonNullable<
    VeneerOrderDetailLegacyMultiFulfillmentInfoGetLegacyMultiFulfillmentInfoByOrderId_QueryQuery['getLegacyMultiFulfillmentInfoByOrderId']
>;
type LegacyMultiFulfillmentInfoFulfillment = LegacyMultiFulfillmentInfo['fulfillments'][number];
type LegacyMultiFulfillmentInfoDetail = LegacyMultiFulfillmentInfoFulfillment['details'][number];

// all multi-fulfillment orders were created before this date so we don't bother querying
// in the case that the order was created afterwards
const MULTI_FULFILLMENT_END = dayjs('2024-03-06T00:00:00.000Z');

const VeneerOrderDetailLegacyMultiFulfillmentInfoLabSalesOrder_Fragment = graphql(`
    fragment VeneerOrderDetailLegacyMultiFulfillmentInfoLabSalesOrder_Fragment on LabSalesOrderDTO {
        id
        created_at
        item_groups_display_info {
            sku
        }
    }
`);

const VeneerOrderDetailLegacyMultiFulfillmentInfoGetLegacyMultiFulfillmentInfoByOrderId_Query = graphql(`
    query VeneerOrderDetailLegacyMultiFulfillmentInfoGetLegacyMultiFulfillmentInfoByOrderId_Query($order_id: String!) {
        getLegacyMultiFulfillmentInfoByOrderId(order_id: $order_id) {
            fulfillments {
                title
                details {
                    title
                    value
                }
                photo_paths
            }
        }
    }
`);

interface OrderDetailLegacyMultiFulfillmentInfoPhotosProps {
    orderId: string;
    photoPaths: string[];
}

const OrderDetailLegacyMultiFulfillmentInfoPhotos: React.VFC<OrderDetailLegacyMultiFulfillmentInfoPhotosProps> = ({
    orderId,
    photoPaths,
}) => {
    const photoSources = photoPaths.map((source, idx) => ({ source, title: `Photo #${idx + 1}` }));

    if (photoSources.length === 0) {
        return null;
    }

    return (
        <OrderDetailImagesContent
            orderId={orderId}
            sources={photoSources}
            wrapperProps={{ style: { paddingTop: 4 } }}
        />
    );
};

interface OrderDetailLegacyMultiFulfillmentInfoDetailProps {
    detail: LegacyMultiFulfillmentInfoDetail;
}

const OrderDetailLegacyMultiFulfillmentInfoDetail: React.VFC<OrderDetailLegacyMultiFulfillmentInfoDetailProps> = ({
    detail,
}) => {
    const hasValue = !!detail.value;

    return (
        <Grid container>
            <Grid item xs={12} sm={5} md={4} lg={3} style={{ maxWidth: 264, paddingTop: !hasValue ? 4 : undefined }}>
                <Text variant={'body2'} color={hasValue ? 'GRAY' : 'BLACK'} medium={!hasValue}>
                    {detail.title}
                </Text>
            </Grid>
            {hasValue && (
                <Grid item xs={12} sm>
                    <Text variant={'body2'} color={'BLACK'} style={{ whiteSpace: 'pre-line' }}>
                        {detail.value}
                    </Text>
                </Grid>
            )}
        </Grid>
    );
};

interface OrderDetailLegacyMultiFulfillmentInfoFulfillmentProps {
    orderId: string;
    fulfillment: LegacyMultiFulfillmentInfoFulfillment;
}

const OrderDetailLegacyMultiFulfillmentInfoFulfillment: React.VFC<
    OrderDetailLegacyMultiFulfillmentInfoFulfillmentProps
> = ({ orderId, fulfillment }) => {
    return (
        <OrderDetailBlock title={fulfillment.title} variant={'full'}>
            {fulfillment.details.map((detail, idx) => (
                <OrderDetailLegacyMultiFulfillmentInfoDetail key={idx} detail={detail} />
            ))}
            <OrderDetailLegacyMultiFulfillmentInfoPhotos orderId={orderId} photoPaths={fulfillment.photo_paths} />
        </OrderDetailBlock>
    );
};

interface OrderDetailLegacyMultiFulfillmentInfoContentProps {
    orderId: string;
    info: LegacyMultiFulfillmentInfo;
}

const OrderDetailLegacyMultiFulfillmentInfoContent: React.VFC<OrderDetailLegacyMultiFulfillmentInfoContentProps> = ({
    orderId,
    info,
}) => {
    return (
        <>
            {info.fulfillments.map((fulfillment, idx) => (
                <OrderDetailLegacyMultiFulfillmentInfoFulfillment
                    key={idx}
                    orderId={orderId}
                    fulfillment={fulfillment}
                />
            ))}
        </>
    );
};

interface OrderDetailLegacyMultiFulfillmentInfoProps {
    salesOrder: FragmentType<typeof VeneerOrderDetailLegacyMultiFulfillmentInfoLabSalesOrder_Fragment>;
}

export const OrderDetailLegacyMultiFulfillmentInfo: React.VFC<OrderDetailLegacyMultiFulfillmentInfoProps> = ({
    salesOrder: salesOrderProp,
}) => {
    const salesOrder = getFragmentData(
        VeneerOrderDetailLegacyMultiFulfillmentInfoLabSalesOrder_Fragment,
        salesOrderProp,
    );

    const hasPartialOrDenture = salesOrder.item_groups_display_info.some(
        info => info.sku === LabsGqlOrderItemSkuType.Partial || info.sku === LabsGqlOrderItemSkuType.Denture,
    );
    const createdBeforeEnd = dayjs(salesOrder.created_at).isBefore(MULTI_FULFILLMENT_END);

    const { data } = useQuery(VeneerOrderDetailLegacyMultiFulfillmentInfoGetLegacyMultiFulfillmentInfoByOrderId_Query, {
        variables: {
            order_id: salesOrder.id,
        },
        skip: !hasPartialOrDenture || !createdBeforeEnd,
    });

    if (!data?.getLegacyMultiFulfillmentInfoByOrderId) {
        return null;
    }

    return (
        <OrderDetailLegacyMultiFulfillmentInfoContent
            orderId={salesOrder.id}
            info={data.getLegacyMultiFulfillmentInfoByOrderId}
        />
    );
};
