import { getFileNameWithExtension } from '../util/file-utils';
import { useMutation } from '@apollo/client';
import { DownloadUtils } from '@orthly/dentin';
import { graphql } from '@orthly/graphql-inline-react';
import { apolloErrorMessage, useConfig } from '@orthly/ui';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useAsyncCallback } from 'react-async-hook';

const GetDownloadUrlMutation = graphql(`
    mutation GetDownloadUrl($bucket: String!, $path: String!, $fileName: String) {
        getDownloadUrl(bucket: $bucket, path: $path, fileName: $fileName)
    }
`);

interface DownloadArgs {
    path: string;
    bucket?: string;
    fileName?: string;
}

function useFirebaseDownloadFromUrl(): (args: DownloadArgs) => Promise<void> {
    const config = useConfig();
    const defaultBucket = config.firebase.config.storageBucket;
    const { enqueueSnackbar } = useSnackbar();
    const [getDownloadUrl] = useMutation(GetDownloadUrlMutation, {
        onCompleted: res => {
            DownloadUtils.downloadUrl(res.getDownloadUrl);
        },
        onError: err => {
            enqueueSnackbar(apolloErrorMessage(err), { variant: 'error' });
        },
    });

    return React.useCallback(
        async (args: DownloadArgs) => {
            const { path, bucket = defaultBucket, fileName } = args;

            const fileNameWithExt = fileName && getFileNameWithExtension(fileName, path);
            await getDownloadUrl({
                variables: { bucket, path, fileName: fileNameWithExt },
            });
        },
        [getDownloadUrl, defaultBucket],
    );
}

export function useFirebaseFileDownload(recordPath?: string, fileName?: string) {
    // TODO: GCS Cleanup: accept bucket name as input for downloads
    const downloadFile = useFirebaseDownloadFromUrl();

    const downloadFn = React.useCallback(async () => {
        if (recordPath) {
            return downloadFile({ path: recordPath, fileName });
        }
        throw new Error(`Cannot download file from undefined path`);
    }, [recordPath, fileName, downloadFile]);
    return useAsyncCallback(downloadFn);
}
