import type { CanvasObject } from '../Objects/CanvasObject';
import type { ShapeParams } from '../Objects/ShapeObject';
import { ShapeObject } from '../Objects/ShapeObject';
import type { Point } from '../Util';
import { CreationTool } from './CreationTool';

export class ShapeCreationTool extends CreationTool {
    private params: ShapeParams;

    constructor(shapeTool: ShapeParams) {
        super('crosshair');
        this.params = shapeTool;
    }

    objectForPoints(start: Point, end: Point, _all: Point[]): CanvasObject | null {
        return new ShapeObject(this.params, start, end);
    }
}
