import { BulkSetWillChargeCCFeeActions } from './BulkSetWillChargeCCFeeActions.graphql';
import { BulkSetWillChargeCCFeeRowDisplay } from './BulkSetWillChargeCCFeeRowDisplay';
import { BulkSetWillChargeCCFeeCtxProvider } from './providers/BulkSetWillChargeCCFeeProvider.graphql';
import { styled } from '@orthly/ui-primitives';

const StyledContainer = styled('div')({
    width: '100%',
    padding: '24px',
});

export const BulkSetWillChargeCCFeeRoot: React.FC = () => {
    return (
        <BulkSetWillChargeCCFeeCtxProvider>
            <StyledContainer>
                <BulkSetWillChargeCCFeeActions />
                <BulkSetWillChargeCCFeeRowDisplay />
            </StyledContainer>
        </BulkSetWillChargeCCFeeCtxProvider>
    );
};
