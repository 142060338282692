import { LabOrderConditionField } from '../../components/LabOrderConditionEditor';
import { RoutingRuleUtils } from '../../components/RoutingRule.util';
import type { LabsGqlTargetDistributionRuleFragment, LabsGqlListOrgsQuery } from '@orthly/graphql-operations';
import { useListLabOrgs } from '@orthly/graphql-react';
import type { CustomQFComponentProps, FieldsDefProp, FieldDefBasic, FieldDefArray } from '@orthly/ui';
import { ActionCard, QuickForm, LoadBlocker } from '@orthly/ui';
import { Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { z } from 'zod';

export type TargetDistributionFormFields = Pick<
    LabsGqlTargetDistributionRuleFragment,
    'name' | 'condition_json' | 'priority' | 'routing_target_distribution_items'
>;

function getTargetDistributionRuleFormFields(
    labOrgs?: LabsGqlListOrgsQuery,
): FieldsDefProp<TargetDistributionFormFields> {
    const name: FieldDefBasic = {
        label: 'Name',
        type: 'text',
        optional: false,
    };
    const condition_json: FieldDefBasic = {
        type: 'custom',
        label: 'Criteria',
        optional: false,
        component: (formProps: CustomQFComponentProps) => (
            <LabOrderConditionField {...formProps} omitManufacturerOption={true} />
        ),
        validation: RoutingRuleUtils.OrderConditionValidation,
    };
    const priority: FieldDefBasic = {
        type: 'number',
        label: 'Priority',
        optional: false,
        validation: z
            .number()
            .int()
            .refine(value => value === -1 || value >= 1, {
                message: 'Use -1 for global rule, otherwise assign to priority group with min value of 1',
            }),
    };
    const routing_target_distribution_items: FieldDefArray = {
        label: 'Target Distribution Items',
        type: 'array',
        min: 1,
        of: {
            type: 'nested',
            optional: false,
            fields: {
                manufacturer_id: {
                    label: 'Lab',
                    type: 'select',
                    options: (labOrgs?.listOrganizations ?? []).map(({ id, name }) => ({
                        value: id,
                        label: name,
                    })),
                    optional: false,
                },
                weight: {
                    type: 'number',
                    label: 'Weight',
                    optional: false,
                },
            },
        },
        validation: z.array(z.object({ manufacturer_id: z.string(), weight: z.number().min(0).max(1) })).refine(
            values_list => {
                const weightsAddToOne =
                    values_list.reduce((accum, value) => {
                        return accum + value.weight;
                    }, 0) === 1;
                const allManufacturers = values_list.map(value => value.manufacturer_id);
                return weightsAddToOne && allManufacturers.length === new Set(allManufacturers).size;
            },
            {
                message: 'Each manufacturer may only appear once in the target distribution; all weights must add to 1',
            },
        ),
    };
    return {
        name,
        condition_json,
        priority,
        routing_target_distribution_items,
    };
}

/**
 * Form for creating and editing target distribution rules.
 */
export const TargetDistributionRuleForm: React.VFC<{
    onSubmit: (result: TargetDistributionFormFields) => void;
    initialValues?: TargetDistributionFormFields;
}> = ({ onSubmit, initialValues }) => {
    const defaults: TargetDistributionFormFields = {
        name: ``,
        condition_json: ``,
        routing_target_distribution_items: [],
        priority: -1,
    };
    const { data: labOrgs, loading: labOrgsLoading, error: labOrgsError } = useListLabOrgs();

    // When we read in target distribution rules with GraphQL, we get a field called __typename on the distribution
    // items. If we don't get rid of it here, __typename is sent with the rest of the data on submission. This field
    // isn't recognized by the mutation, so that would cause an error.
    const correctedInitialValues = initialValues
        ? {
              ...initialValues,
              routing_target_distribution_items: initialValues.routing_target_distribution_items.map(item => {
                  return _.omit(item, '__typename');
              }),
          }
        : undefined;

    return (
        <div>
            {labOrgsError && (
                <ActionCard
                    title={`Error loading lab names`}
                    variant={'alert'}
                    subtitle={
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            {labOrgsError.message}
                        </Text>
                    }
                />
            )}
            {labOrgsLoading && (
                <ActionCard
                    title={`Loading lab names`}
                    variant={'message'}
                    subtitle={'This form relies on lab names'}
                />
            )}
            <LoadBlocker blocking={labOrgsLoading}>
                <QuickForm<TargetDistributionFormFields>
                    disabled={!!labOrgsError}
                    initialValues={correctedInitialValues ?? defaults}
                    fields={getTargetDistributionRuleFormFields(labOrgs)}
                    onSubmit={({ name, condition_json, routing_target_distribution_items, priority }) =>
                        onSubmit({ name, condition_json, routing_target_distribution_items, priority })
                    }
                />
            </LoadBlocker>
        </div>
    );
};
