import type { WithClassesProp } from '../../util';
import { stylesFactory, useMergedMuiClasses } from '../../util';
import type { SpreadsheetAcceptedCellTypes, ValidationRuleTestAndError } from './CsvUploadSpreadsheetDisplay.utils';
import {
    determineInvoiceItemInputValidationErrors,
    doesMatrixRowContainValidationError,
} from './CsvUploadSpreadsheetDisplay.utils';
import { Grid, FlossPalette, Text } from '@orthly/ui-primitives';
import React from 'react';
import type { CellBase, Matrix } from 'react-spreadsheet';
import Spreadsheet, { createEmptyMatrix } from 'react-spreadsheet';

/** For reference of the package being used, visit https://github.com/iddan/react-spreadsheet */

type CsvUploadSpreadsheetDisplayClassKey = 'spreadsheet' | 'activeError' | 'noError';

const useStyles = stylesFactory(() => ({
    spreadsheet: {
        height: 240,
        width: '100%',
        overflow: 'scroll',
        // Overriding CSS variables of Spreadsheet component from react-spreadsheet
        '--readonly-text-color': FlossPalette.BLACK,
        '--header-background-color': FlossPalette.TAN,
    },
    activeError: {
        backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
    },
    noError: {
        backgroundColor: FlossPalette.WHITE,
    },
}));

interface CsvUploadSpreadsheetDisplayProps<T = any> extends WithClassesProp<CsvUploadSpreadsheetDisplayClassKey> {
    /** A list where each element is a row with column values provided from a CSV upload */
    inputRows: Record<string, T>[];
    /** The labels of the columns in the spreadsheet as a list of strings */
    columnLabels: string[];
    /** A record of the column name mapped to an object containing the validation function to test against
     *  and the error label for that validation */
    validationRules?: Record<string, ValidationRuleTestAndError>;
    /** An optional stateful variable boolean passed from the parent component that will be updated
     * if the spreadsheet contains validation errors. If you pass this variable, ensure you also pass
     * the optional state setting function for it
     */
    uploadHasValidationErrors?: boolean;
    /** An optional utility function that can be used to inform the parent component if the upload
     * contained validation errors or not
     */
    setUploadHasValidationErrors?: (uploadHasValidationErrors: boolean) => void;
}

export const CsvUploadSpreadsheetDisplay: React.FC<CsvUploadSpreadsheetDisplayProps> = props => {
    const { inputRows, columnLabels, validationRules, uploadHasValidationErrors, setUploadHasValidationErrors } = props;
    const classes = useMergedMuiClasses<CsvUploadSpreadsheetDisplayClassKey>(useStyles(), props.classes);
    // Create the base matrix using built-in function from react-spreadsheet package. The number of
    // rows and columns here are only added to create the base matrix, and since we are using this as a display
    // data from CSV upload, it will fill the number of rows and columns based on inputRows.
    const baseMatrix: Matrix<CellBase<SpreadsheetAcceptedCellTypes>> = createEmptyMatrix(0, 0);
    const [matrixData, setMatrixData] = React.useState<Matrix<CellBase<SpreadsheetAcceptedCellTypes>>>(baseMatrix);

    // The validationErrors object stores validation error types as keys
    // and maps the indexes of all rows (stored in array) that are affected by that validation
    const [validationErrors, setValidationErrors] = React.useState<Record<string, number[]>>({});
    const spreadsheetHasValidationErrors = Object.values(validationErrors).flat().length > 0;

    // This effect deals with formatting the data coming in from the CSV upload into
    // the appropriate shape to be displayed in the matrix of the spreadsheet, as well
    // as dealing with adding validation errors to our validationErrors object to be highlighted in the
    // following effect below
    React.useEffect(() => {
        const formattedMatrixData: Matrix<CellBase<SpreadsheetAcceptedCellTypes>> = inputRows.map((row, i) => {
            return Object.entries(row).map(([key, val]) => {
                if (validationRules) {
                    determineInvoiceItemInputValidationErrors(
                        key,
                        val,
                        i,
                        validationErrors,
                        setValidationErrors,
                        validationRules,
                    );
                }
                return {
                    value: val,
                    readOnly: true,
                };
            });
        });
        setMatrixData(formattedMatrixData);
    }, [inputRows, setMatrixData, validationRules, validationErrors, uploadHasValidationErrors]);

    // Because there is no input validation built into the spreadsheet, we have
    // to do it manually. React-spreadsheet only supports styling at the cell level rather than
    // the row level, so we have to add the styling to each cell in a row that has a validation error
    // after the data has been uploaded and formatted
    React.useEffect(() => {
        setMatrixData(matrixData =>
            matrixData.map((row, i) => {
                return row.map(cell => {
                    if (cell) {
                        return {
                            ...cell,
                            className: doesMatrixRowContainValidationError(i, validationErrors)
                                ? classes.activeError
                                : classes.noError,
                        };
                    }
                });
            }),
        );
        setUploadHasValidationErrors?.(spreadsheetHasValidationErrors);
    }, [
        validationErrors,
        inputRows,
        classes.activeError,
        classes.noError,
        uploadHasValidationErrors,
        setUploadHasValidationErrors,
        spreadsheetHasValidationErrors,
    ]);

    return (
        <>
            <Spreadsheet data={matrixData} columnLabels={columnLabels} className={classes.spreadsheet} />
            {spreadsheetHasValidationErrors && (
                <Grid>
                    <Text variant={'body2'} color={'GRAY'}>
                        Resolve issues before proceeding:
                    </Text>
                    {Object.entries(validationErrors).map(
                        ([key, value]) =>
                            value.length > 0 && (
                                <Text variant={'body2'} key={key} color={'ATTENTION'}>
                                    Row {value.sort((a, b) => a - b).join(', ')}: {key}
                                </Text>
                            ),
                    )}
                </Grid>
            )}
        </>
    );
};
