import type { RefabFlowItemReasonCode, RefabFlowState, RefabShadeChangeRequest, ShadeUpdate } from './refab-flow-types';
import { INITIAL_REFAB_FLOW_STATE } from './refab-flow-types';
import type { LabsGqlReasonCodeOptionFragment } from '@orthly/graphql-operations';
import type { Maybe } from '@orthly/graphql-schema';
import { LabsGqlReasonCodeGroup } from '@orthly/graphql-schema';
import type { LocalActionWithPayload } from '@orthly/ui';
import { createLocalReducerContext } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

type ShadeReasonSelectorResult = {
    itemIdsWithShadeReasons: string[];
    itemIdsWithNonShadeReasons: string[];
};

// This isn't ideal, but right now we don't have a system way to know a reason code is related to shade other
// than by looking at its name. In production the names used are "Tooth Shade" and "Tissue Shade", so that's why
// they're hardcoded here. These names are configured in the Config section of the Ops portal ("Feedback & Refab Reasons" tab)
export const isShadeReasonByCategoryName = (categoryName: string | null): boolean => {
    if (!categoryName) {
        return false;
    }

    return ['Tooth Shade', 'Tissue Shade'].includes(categoryName);
};

const getItemByShadeReasons = (itemwiseReasonCodes: Record<string, Record<string, RefabFlowItemReasonCode>>) => {
    const result: ShadeReasonSelectorResult = {
        itemIdsWithShadeReasons: [],
        itemIdsWithNonShadeReasons: [],
    };

    for (const [itemId, reasons] of Object.entries(itemwiseReasonCodes)) {
        const hasShadeRefabReason = Object.values(reasons).some(r => isShadeReasonByCategoryName(r.category));
        const hasNonShadeRefabReason = Object.values(reasons).some(r => !isShadeReasonByCategoryName(r.category));

        if (hasShadeRefabReason) {
            result.itemIdsWithShadeReasons.push(itemId);
        }

        // We also keep track of items that may have updates that are non shade related
        // as for that use case we still want to show the supplementary info screen at the end of
        // the refab flow
        if (hasNonShadeRefabReason) {
            result.itemIdsWithNonShadeReasons.push(itemId);
        }
    }

    return result;
};

export const {
    Provider: RefabFlowProvider,
    useAction: useRefabFlowAction,
    useSelector: useRefabFlowSelector,
    useListener: useRefabFlowListener,
} = createLocalReducerContext(
    {
        PATCH_STATE: (state, action: LocalActionWithPayload<Partial<RefabFlowState>>) => ({
            ...state,
            ...action.payload,
        }),
        SET_SINGLE_REASON_CODE: (
            state,
            action: LocalActionWithPayload<{ itemId: string; reasonCode: RefabFlowItemReasonCode }>,
        ) => {
            const { itemId, reasonCode } = action.payload;
            return {
                ...state,
                itemwiseReasonCodes: {
                    ...state.itemwiseReasonCodes,
                    [itemId]: {
                        ...state.itemwiseReasonCodes[itemId],
                        [reasonCode.codeId]: reasonCode,
                    },
                },
            };
        },
        UNSET_REASON_CODES: (state, action: LocalActionWithPayload<{ itemId: string; reasonCodeIds: string[] }>) => {
            const { itemId, reasonCodeIds } = action.payload;

            const newItemReasonCodes = {
                ...state.itemwiseReasonCodes,
                [itemId]: _.omit(state.itemwiseReasonCodes[itemId] ?? {}, reasonCodeIds),
            };
            const stillHasShadeReasons =
                getItemByShadeReasons(newItemReasonCodes).itemIdsWithShadeReasons.includes(itemId);

            return {
                ...state,
                itemwiseReasonCodes: newItemReasonCodes,
                // If we removed all the "shade reasons" for a given item, we make sure to remove the item from the shade updates map
                shadeUpdatesByItemId: stillHasShadeReasons
                    ? state.shadeUpdatesByItemId
                    : _.omit(state.shadeUpdatesByItemId, itemId),
            };
        },
        SET_OTHER_NOTES: (
            state,
            action: LocalActionWithPayload<{ itemId: string; reasonCode: RefabFlowItemReasonCode; otherNotes: string }>,
        ) => ({
            ...state,
            itemwiseReasonCodes: {
                ...state.itemwiseReasonCodes,
                [action.payload.itemId]: {
                    ...state.itemwiseReasonCodes[action.payload.itemId],
                    [action.payload.reasonCode.codeId]: {
                        ...action.payload.reasonCode,
                        otherNotes: action.payload.otherNotes,
                    },
                },
            },
        }),
        SET_CHANGE_REQUESTS: (
            state,
            action: LocalActionWithPayload<{
                shadeChangeRequests: RefabShadeChangeRequest[];
            }>,
        ) => {
            const updatedChangeRequestOfCurrentItem = {
                changeRequests: [...action.payload.shadeChangeRequests],
            };

            const itemsByShadeReasons = getItemByShadeReasons(state.itemwiseReasonCodes);

            return {
                ...state,
                shadeUpdatesByItemId: state.isSettingShadesForAllItems
                    ? itemsByShadeReasons.itemIdsWithShadeReasons.reduce(
                          (prev, itemId) => ({
                              ...prev,
                              [itemId]: updatedChangeRequestOfCurrentItem,
                          }),
                          {},
                      )
                    : { ...state.shadeUpdatesByItemId, [state.screenItemId]: updatedChangeRequestOfCurrentItem },
            };
        },
        SET_IS_SETTING_SHADES_FOR_ALL_ITEMS: (
            state,
            action: LocalActionWithPayload<{
                isSettingShadesForAllItems: boolean;
            }>,
        ) => {
            const { isSettingShadesForAllItems } = action.payload;
            const itemsByShadeReasons = getItemByShadeReasons(state.itemwiseReasonCodes);
            const firstItemIdWithChanges = itemsByShadeReasons.itemIdsWithShadeReasons[0];

            // If we don't see any item qualifying for a shade update, we exit, this shouldn't happen but helps TS types.
            if (!firstItemIdWithChanges) {
                return state;
            }

            const isFirstItemWithShadeUpdates = firstItemIdWithChanges === state.screenItemId;

            const emptyShadeUpdates: ShadeUpdate = {
                changeRequests: [],
            };
            let shadeUpdatesByItemId: Record<string, ShadeUpdate> = {};

            // If we're switching whether to set shades on all items NOT on the first item, then we just
            // override all the shade changes and reset them to have no changes and let the user make their selection again
            if (!isFirstItemWithShadeUpdates) {
                shadeUpdatesByItemId = itemsByShadeReasons.itemIdsWithShadeReasons.reduce(
                    (prev, itemId) => ({
                        ...prev,
                        [itemId]: emptyShadeUpdates,
                    }),
                    {},
                );
            } else {
                // If we are on the first item, and we are enabling the switch, then we set all shade updates to the value
                // set on the current item
                if (isSettingShadesForAllItems) {
                    shadeUpdatesByItemId = itemsByShadeReasons.itemIdsWithShadeReasons.reduce(
                        (prev, itemId) => ({
                            ...prev,
                            [itemId]: state.shadeUpdatesByItemId[state.screenItemId] ?? emptyShadeUpdates,
                        }),
                        {},
                    );
                } else {
                    // If we are disabling the switch but are on the first item, then we leave the current value selected on the
                    // first item, but erase all the values set on further items so the user can make their selection from scratch
                    shadeUpdatesByItemId = itemsByShadeReasons.itemIdsWithShadeReasons.reduce(
                        (prev, itemId) => ({
                            ...prev,
                            [itemId]:
                                itemId !== state.screenItemId
                                    ? emptyShadeUpdates
                                    : state.shadeUpdatesByItemId[state.screenItemId] ?? emptyShadeUpdates,
                        }),
                        {},
                    );
                }
            }

            // When we toggle the setting to set all shades at once, we want to either:
            // Set all the shades of all items to the current item being viewed, or if
            // it's being toggled off, we want to reset the next changes so the user can
            // customize all settings of the next teeth without seeing the initial changes
            // that were made
            return {
                ...state,
                isSettingShadesForAllItems,
                shadeUpdatesByItemId,
                // No matter what, if they switch the toggle, we send them on the screen with first item changes
                screenItemId: firstItemIdWithChanges,
            };
        },
    },
    INITIAL_REFAB_FLOW_STATE,
);

// Custom Selectors:
// ----------------

export const useSelectedReasonCodesForCurrentItem = () => {
    const itemId = useRefabFlowSelector(s => s.screenItemId);
    return useRefabFlowSelector(state => state.itemwiseReasonCodes[itemId]) ?? {};
};
export const useSelectedReasonCodeIdsForCurrentItem = () => {
    return Object.keys(useSelectedReasonCodesForCurrentItem());
};

export const useSelectOtherNotesByReasonCodeIdForCurrentItem = (reasonCodeId?: string) => {
    const itemId = useRefabFlowSelector(s => s.screenItemId);
    return useRefabFlowSelector(s => s.itemwiseReasonCodes[itemId]?.[reasonCodeId ?? '']?.otherNotes ?? '');
};

export const useFilterSelectedReasonCodeIdsByCategoryForCurrentItem = (
    reasonCodes?: LabsGqlReasonCodeOptionFragment[],
    category?: Maybe<string>,
) => {
    const selectedReasonCodeIds = useSelectedReasonCodeIdsForCurrentItem();
    const reasonCodesIdsByCategory = reasonCodes?.filter(rc => rc.category === category).map(rc => rc.id) ?? [];
    return _.intersection(selectedReasonCodeIds, reasonCodesIdsByCategory);
};

/**
 * This selector is helpful to give us the group of items that needs to go through the new shade screens and
 * the ones that don't.
 */
export const useItemWithShadeReasonsSelector = () => {
    const itemwiseReasonCodes = useRefabFlowSelector(s => s.itemwiseReasonCodes);

    return getItemByShadeReasons(itemwiseReasonCodes);
};

// Custom Actions:
// --------------

export const useUnsetReasonCodesForCurrentItem = () => {
    const itemId = useRefabFlowSelector(s => s.screenItemId);
    const unsetReasonCodes = useRefabFlowAction('UNSET_REASON_CODES');
    return React.useCallback(
        (reasonCodeIds: string[]) => {
            unsetReasonCodes({ itemId, reasonCodeIds });
        },
        [itemId, unsetReasonCodes],
    );
};

const convertReasonCodeToLocalItemAwareShape = (
    reasonCode: LabsGqlReasonCodeOptionFragment,
    itemId: string,
): RefabFlowItemReasonCode => ({
    additionalInfoRequest: reasonCode.additional_info_request,
    itemId,
    group: reasonCode.group ?? LabsGqlReasonCodeGroup.Other,
    category: reasonCode.category ?? 'Unknown',
    title: reasonCode.title,
    codeId: reasonCode.id,
    requiresReturn: reasonCode.requires_return,
    otherNotes: '',
    defaultFault: reasonCode.default_fault,
});

export const useSetReasonCodeForCurrentItem = () => {
    const itemId = useRefabFlowSelector(s => s.screenItemId);
    const setSingleReasonCode = useRefabFlowAction('SET_SINGLE_REASON_CODE');
    return React.useCallback(
        (reasonCode: LabsGqlReasonCodeOptionFragment) =>
            setSingleReasonCode({ itemId, reasonCode: convertReasonCodeToLocalItemAwareShape(reasonCode, itemId) }),
        [itemId, setSingleReasonCode],
    );
};

export const useSetOtherNotesByReasonCodeIdForCurrentItem = (codeId: string) => {
    const itemId = useRefabFlowSelector(s => s.screenItemId);
    const reasonCode = useRefabFlowSelector(s => s.itemwiseReasonCodes[itemId]?.[codeId]);
    const setOtherNotes = useRefabFlowAction('SET_OTHER_NOTES');
    return React.useCallback(
        (otherNotes: string) => {
            if (reasonCode) {
                setOtherNotes({
                    itemId,
                    reasonCode: reasonCode,
                    otherNotes: otherNotes,
                });
            }
        },
        [itemId, reasonCode, setOtherNotes],
    );
};

export const useAssignFaultByReasonCodeForCurrentItem = () => {
    const itemId = useRefabFlowSelector(s => s.screenItemId);
    const setSingleReasonCode = useRefabFlowAction('SET_SINGLE_REASON_CODE');
    return React.useCallback(
        (reasonCode: RefabFlowItemReasonCode, newFault: RefabFlowItemReasonCode['defaultFault']) =>
            setSingleReasonCode({ itemId, reasonCode: { ...reasonCode, defaultFault: newFault } }),
        [itemId, setSingleReasonCode],
    );
};
