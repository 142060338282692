import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { usePreviewUpdateOrderSlaLazyQuery } from '@orthly/graphql-react';
import { LabsGqlOrderSlaModificationType } from '@orthly/graphql-schema';
import moment from 'moment';
import React from 'react';

function getDateInEST(date: Date | string) {
    return moment(date).startOf('day').tz('America/New_York');
}

export function useDelayDateSlaPreview(
    labOrderFragment: Pick<LabsGqlLabOrderFragment, 'id' | 'manufacturer_sla'>,
    currentValue: string | Date,
) {
    const [selectedDate, rawSetSelectedDate] = React.useState<Date>(new Date(currentValue));

    const [submit, { data: previewUpdateData }] = usePreviewUpdateOrderSlaLazyQuery({
        fetchPolicy: 'no-cache',
    });
    const updatedSla = previewUpdateData?.previewUpdateOrderSla;

    const setSelectedDate = React.useCallback(
        async (date: Date) => {
            rawSetSelectedDate(date);
            await submit({
                variables: {
                    command: {
                        order_id: labOrderFragment.id,
                        modification_type: LabsGqlOrderSlaModificationType.InternalDelay,
                        notify_practice: false,
                        stage_due_date: date.toISOString(),
                        reason: {
                            category_id: '',
                        },
                    },
                },
            });
        },
        [rawSetSelectedDate, submit, labOrderFragment.id],
    );

    const updatedETAInEST = updatedSla ? getDateInEST(updatedSla.estimated_delivery_date) : null;
    const originalDeadlineInEST = labOrderFragment.manufacturer_sla.stages?.Buffer.original_deadline
        ? getDateInEST(labOrderFragment.manufacturer_sla.stages.Buffer.original_deadline)
        : undefined;

    return {
        setSelectedDate,
        selectedDate,
        updatedSLA: updatedSla,
        isInvalidDate:
            updatedETAInEST && originalDeadlineInEST ? updatedETAInEST.isBefore(originalDeadlineInEST, 'day') : false,
    };
}

export function isDelayedOrderCustomerNotificationNeeded(
    committedDate: Date | string,
    currentDueDate: Date | string,
    newDueDate: Date | string | null,
): boolean {
    const committedDueDateInEST = getDateInEST(committedDate);
    const currentDueDateInEST = getDateInEST(currentDueDate);
    const newDueDateInEST = newDueDate ? getDateInEST(newDueDate) : null;

    if (!newDueDateInEST) {
        return false;
    }

    // We only send customer notifications if the due date is both after the committed date _and_ the latest due date.
    // EG if we go from 3/14 -> 3/16 (notify) -> 3/15 (don't notify) -> 3/17 (notify)
    return newDueDateInEST.isAfter(committedDueDateInEST) && newDueDateInEST.isAfter(currentDueDateInEST);
}
