import { OrderDetailBlock } from '../OrderDetailBlock';
import { OrderDetailItemsTable } from './OrderDetailItemsTable.graphql';
import type { OrderDetailItemsTableItemActionProps } from './OrderDetailItemsTable.types';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

const VeneerOrderDetailItemsAndPreferencesLabSalesOrderItemFullDisplayInfoWithCommon_Fragment = graphql(`
    fragment VeneerOrderDetailItemsAndPreferencesLabSalesOrderItemFullDisplayInfoWithCommon_Fragment on LabSalesOrderItemFullDisplayInfoWithCommonDTO {
        items_full_display_info {
            ...VeneerOrderDetailItemsTableLabSalesOrderItemFullDisplayInfo_Fragment
        }
        common_minor_details {
            label
            value
        }
    }
`);

interface OrderDetailPreferencesBlockProps {
    commonMinorDetails: { label: string; value: string }[];
}

const OrderDetailPreferencesBlock: React.VFC<OrderDetailPreferencesBlockProps> = ({ commonMinorDetails }) => {
    return (
        <OrderDetailBlock variant={'left'} title={'Preferences'}>
            {commonMinorDetails.map(detail => (
                <Grid item key={detail.label}>
                    <Text variant={'body2'} color={'GRAY'} component={'span'}>
                        {`${detail.label}: `}
                    </Text>
                    <Text variant={'body2'} color={'BLACK'} component={'span'}>
                        {detail.value}
                    </Text>
                </Grid>
            ))}
        </OrderDetailBlock>
    );
};

interface OrderDetailItemsAndPreferencesSectionProps {
    fullDisplayInfoWithCommon: FragmentType<
        typeof VeneerOrderDetailItemsAndPreferencesLabSalesOrderItemFullDisplayInfoWithCommon_Fragment
    >;
    itemsBlockActions?: React.ReactNode;
    ItemAction?: React.ComponentType<OrderDetailItemsTableItemActionProps>;
}

export const OrderDetailItemsAndPreferencesSection: React.VFC<OrderDetailItemsAndPreferencesSectionProps> = ({
    fullDisplayInfoWithCommon,
    itemsBlockActions,
    ItemAction,
}) => {
    const displayInfoWithCommon = getFragmentData(
        VeneerOrderDetailItemsAndPreferencesLabSalesOrderItemFullDisplayInfoWithCommon_Fragment,
        fullDisplayInfoWithCommon,
    );

    const hasCommonMinorDetails = displayInfoWithCommon.common_minor_details.length > 0;

    return (
        <Grid container direction={'row'}>
            {hasCommonMinorDetails && (
                <OrderDetailPreferencesBlock commonMinorDetails={displayInfoWithCommon.common_minor_details} />
            )}
            <OrderDetailItemsTable
                fullDisplayInfo={displayInfoWithCommon.items_full_display_info}
                variant={hasCommonMinorDetails ? 'right' : 'full'}
                blockActions={itemsBlockActions}
                ItemAction={ItemAction}
            />
        </Grid>
    );
};
