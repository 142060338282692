import { getFirebaseDownloadUrl } from '../../hooks/useFirebaseDownload';
import { downloadBlob } from '../../util/file-utils';
import type { FileRow } from './BulkDownloadDialog.types';
import axios from 'axios';
import type Firebase from 'firebase/compat';

export class BulkDownloaderUtils {
    static async downloadFileAsBlob(
        firebaseStorage: Firebase.storage.Storage,
        file: FileRow,
        onDownloadProgress: (percentComplete: number) => void,
    ) {
        const downloadUrl =
            file.source === 'firebase' ? await getFirebaseDownloadUrl(firebaseStorage, file.path) : file.path;
        const res = await axios({
            url: downloadUrl,
            responseType: 'blob',
            onDownloadProgress(progressEvent: { loaded: number; total: number; target: XMLHttpRequest }) {
                const totalExpected =
                    progressEvent.total > 0
                        ? progressEvent.total
                        : Number(progressEvent.target.getResponseHeader('X-Estimated-Content-Length'));
                if (isNaN(totalExpected) || totalExpected === 0) {
                    onDownloadProgress(0);
                    return;
                }

                const percentCompleted = Math.round((progressEvent.loaded * 100) / (totalExpected + 1));
                onDownloadProgress(percentCompleted);
            },
        });
        return res.data;
    }

    static saveBlobToDisk(blob: Blob, filename: string) {
        downloadBlob(blob, filename);
    }
}
