import { CreditOrRefundWrapper } from './CreateCreditOrRefund/CreditOrRefundWrapper';
import { useHasCapability } from '@orthly/session-client';
import { Button, Icon, IconButton, Tooltip, type ButtonProps } from '@orthly/ui-primitives';
import React from 'react';

interface CreateBillingCreditActionProps {
    practiceId: string;
    refetchCredits: () => Promise<unknown>;
    useTextButton?: boolean;
}

const IssueCreditButton: React.FC<ButtonProps> = props => {
    return (
        <Tooltip title={'Issue Credit'}>
            <IconButton size={'small'} onClick={props.onClick}>
                <Icon icon={'Redeem'} />
            </IconButton>
        </Tooltip>
    );
};

// eslint-disable-next-line max-lines-per-function
export const CreateBillingCreditAction: React.FC<CreateBillingCreditActionProps> = ({
    practiceId,
    refetchCredits,
    useTextButton,
}) => {
    const [open, setOpen] = React.useState(false);

    const canIssueCredits = useHasCapability('billing', 'billing.issue_credits');

    if (!canIssueCredits) {
        return null;
    }

    return (
        <>
            <CreditOrRefundWrapper
                open={open}
                setOpen={setOpen}
                organizationId={practiceId}
                refetchCredits={refetchCredits}
            />
            {useTextButton ? (
                <Button variant={'secondary'} onClick={() => setOpen(true)}>
                    Issue Credit
                </Button>
            ) : (
                <IssueCreditButton variant={'primary'} onClick={() => setOpen(true)} />
            )}
        </>
    );
};
