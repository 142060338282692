import { AnalyticsClient } from '../../../../../../utils/analyticsClient';
import { AddOrderItemV2Dialog } from './AddOrderItemV2Dialog';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { OrderItemV2InputUtils } from '@orthly/graphql-schema';
import { useRootActionCommand } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

const AdminAddOrderItemV2ButtonLabSalesOrderAddItems_Mutation = graphql(`
    mutation AdminAddOrderItemV2ButtonLabSalesOrderAddItems_Mutation($args: LabSalesOrderAddItemsInput!) {
        labSalesOrderAddItems(args: $args) {
            id
        }
    }
`);

interface AddOrderItemV2ButtonProps {
    orderId: string;
    doctorPreferencesId: string;
    refetchOrder: () => Promise<unknown>;
}

export const AddOrderItemV2Button: React.VFC<AddOrderItemV2ButtonProps> = ({
    orderId,
    doctorPreferencesId,
    refetchOrder,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);

    const addItemsMutation = useMutation(AdminAddOrderItemV2ButtonLabSalesOrderAddItems_Mutation);
    const { submit, submitting } = useRootActionCommand(addItemsMutation, {
        onSuccess: () => {
            refetchOrder().catch(console.error);
        },
        successMessage: 'Order item added!',
    });

    return (
        <>
            <AddOrderItemV2Dialog
                doctorPreferencesId={doctorPreferencesId}
                open={open}
                setOpen={setOpen}
                onSubmit={item => {
                    AnalyticsClient.track('Ops - Item Add - Submitted', {
                        $groups: { order: orderId },
                    });
                    return submit({
                        args: {
                            sales_order_id: orderId,
                            items_v2_by_sku: OrderItemV2InputUtils.getOrderItemV2InputBySKUOrFail([item]),
                        },
                    });
                }}
                submitting={submitting}
            />
            <Button onClick={() => setOpen(true)} variant={'ghost'} startIcon={'AddIcon'}>
                New Item
            </Button>
        </>
    );
};
