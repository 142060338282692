import {
    ORDER_COMPARE_ORDER_CLASS_NAME,
    ORDER_COMPARE_FACT_CLASS_NAME,
    ORDER_COMPARE_FACT_DIFF_CLASS_NAME,
} from './classNames';
import _ from 'lodash';

function findOrders(container: HTMLDivElement): Element[] {
    const nodeList = container.querySelectorAll(`.${ORDER_COMPARE_ORDER_CLASS_NAME}`);
    return Array.from(nodeList);
}

function findOrderFacts(orders: Element[]): Element[][] {
    return orders.map(order => {
        const nodeList = order.querySelectorAll(`.${ORDER_COMPARE_FACT_CLASS_NAME}`);
        return Array.from(nodeList);
    });
}

function factsAreSame(facts: Element[]): boolean {
    return _.uniqBy(facts, fact => fact.textContent).length === 1;
}

function applyDiffClassToFacts(facts: Element[]): void {
    facts.forEach(fact => fact.classList.add(ORDER_COMPARE_FACT_DIFF_CLASS_NAME));
}

function compareOrderFacts(orderFacts: Element[][]): void {
    const firstOrderFacts = orderFacts[0];
    if (!firstOrderFacts || orderFacts.length === 1) {
        return;
    }
    for (let i = 0; i < firstOrderFacts.length; i++) {
        const facts = _.compact(orderFacts.map(orderFact => orderFact[i]));
        if (!factsAreSame(facts)) {
            applyDiffClassToFacts(facts);
        }
    }
}

export function applyComparison(container: HTMLDivElement): void {
    const orders = findOrders(container);
    const orderFacts = findOrderFacts(orders);
    compareOrderFacts(orderFacts);
}
