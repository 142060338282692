import { AddOrderDelayForm } from './AddOrderDelayForm.graphql';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlOrderSlaModificationType } from '@orthly/graphql-schema';
import { useHasCapability, useSession } from '@orthly/session-client';
import { RootActionDialog } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

interface AddOrderDelayActionProps {
    order: Pick<LabsGqlOrder, 'id' | 'manufacturer_sla' | 'practice_dates' | 'status'>;
    refetchOrder: () => Promise<any>;
}

export const AddOrderDelayAction: React.FC<AddOrderDelayActionProps> = ({ order, refetchOrder }) => {
    const [open, setOpen] = React.useState<boolean>(false);

    const session = useSession();
    const hasCapability = useHasCapability('order', 'order.fulfillment.sla.edit');

    const openButton = (
        <Button variant={'ghost'} startIcon={'CalendarIcon'} onClick={() => setOpen(true)}>
            Add Delay
        </Button>
    );

    const onSubmit = React.useCallback(() => {
        refetchOrder().catch(() => {});
        setOpen(false);
    }, [refetchOrder, setOpen]);

    if (!hasCapability) {
        return null;
    }

    return (
        <RootActionDialog
            loading={false}
            open={open}
            setOpen={setOpen}
            onClose={() => setOpen(false)}
            title={``}
            titleProps={{ style: { display: 'none' } }}
            contentProps={{ style: { padding: 40 } }}
            content={
                !open ? null : (
                    <AddOrderDelayForm
                        labOrderFragment={order}
                        onSubmit={onSubmit}
                        onBack={() => setOpen(false)}
                        modificationType={
                            session?.organization_type === 'internal'
                                ? LabsGqlOrderSlaModificationType.InternalDelay
                                : LabsGqlOrderSlaModificationType.ManufacturerDelay
                        }
                    />
                )
            }
            buttonText={''}
            CustomButton={() => openButton}
        />
    );
};
