import { EditIconButtonTextField } from '@orthly/dentin';
import { RootActionDialog } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

interface ItemEditorV2DialogFieldProps {
    fieldLabel: string;
    fieldValue?: string;
    modalTitle: string;
    modalContent: React.ReactNode;
    onOpen?: () => void;
    errorText?: string;
    isOkButtonDisabled?: boolean;
    warningText?: string;
}

export const ItemEditorV2DialogField: React.VFC<ItemEditorV2DialogFieldProps> = ({
    fieldLabel,
    fieldValue,
    modalTitle,
    modalContent,
    onOpen,
    errorText,
    isOkButtonDisabled,
    warningText,
}) => {
    const [modalOpen, setModalOpen] = React.useState<boolean>(false);

    return (
        <>
            <EditIconButtonTextField
                label={fieldLabel}
                value={fieldValue ?? '(none)'}
                onClick={() => setModalOpen(true)}
                errorText={errorText}
                warningText={warningText}
            />
            <RootActionDialog
                open={modalOpen}
                setOpen={() => {}}
                loading={false}
                title={modalTitle}
                hideButton={true}
                content={modalContent}
                actions={
                    <Button
                        disabled={isOkButtonDisabled ?? false}
                        variant={'primary'}
                        onClick={() => setModalOpen(false)}
                    >
                        Ok
                    </Button>
                }
                dialogProps={{
                    TransitionProps: {
                        onEnter: () => onOpen?.(),
                    },
                }}
            />
        </>
    );
};
