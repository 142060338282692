import { LinearProgress } from '@orthly/ui-primitives';
import React from 'react';

const styles = {
    progressWrap: {
        position: 'absolute' as 'absolute',
        display: 'flex',
        width: '100%',
        zIndex: 200,
        height: 10,
        overflow: 'hidden',
        top: 0,
        borderRadius: 0,
    },
    overlay: {
        position: 'absolute' as 'absolute',
        display: 'flex',
        width: '100%',
        zIndex: 199,
        overflow: 'hidden',
        top: 0,
        bottom: 0,
        backgroundColor: 'white',
        opacity: 0.5,
    },
    progress: {
        position: 'absolute' as 'absolute',
        display: 'block',
        width: '100%',
    },
};

interface Props {
    loading: boolean;
}

export const MUITableLoader = (props: Props) => {
    const { loading } = props;
    if (!loading) {
        return null;
    }
    return (
        <>
            <div style={styles.progressWrap}>
                <LinearProgress style={styles.progress} />
            </div>
            <div style={styles.overlay} />
        </>
    );
};
