import type { Point } from '../Util';

export const average = (a: number, b: number) => (a + b) / 2;

// From https://github.com/steveruizok/perfect-freehand.
export function getSvgPathFromStroke(points: Point[], closed: boolean = true): string {
    const len = points.length;

    if (len < 4) {
        return ``;
    }

    let a = points[0] as Point;
    let b = points[1] as Point;
    const c = points[2] as Point;

    let result = `M${a[0].toFixed(2)},${a[1].toFixed(2)} Q${b[0].toFixed(2)},${b[1].toFixed(2)} ${average(
        b[0],
        c[0],
    ).toFixed(2)},${average(b[1], c[1]).toFixed(2)} T`;

    for (let i = 2, max = len - 1; i < max; i++) {
        a = points[i] as Point;
        b = points[i + 1] as Point;
        result += `${average(a[0], b[0]).toFixed(2)},${average(a[1], b[1]).toFixed(2)} `;
    }

    if (closed) {
        result += 'Z';
    }

    return result;
}
