import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Button, Grid } from '@orthly/ui-primitives';
import React from 'react';

const RunRescheduleAndReassignWorkflow_Mutation = graphql(`
    mutation RunRescheduleAndReassignWorkflow_Mutation($orderId: String!) {
        runRescheduleAndReassign(orderId: $orderId) {
            id
        }
    }
`);

interface PlanRunWorkflowsActionProps {
    productionOrderId: string;
    onSuccess: () => Promise<unknown>;
}

export const PlanRunWorkflowsAction: React.FC<PlanRunWorkflowsActionProps> = ({ productionOrderId, onSuccess }) => {
    const runRescheduleAndReassignMutation = useMutation(RunRescheduleAndReassignWorkflow_Mutation);
    const { submit, submitting, open, setOpen } = useRootActionCommand(runRescheduleAndReassignMutation, {
        onSuccess: onSuccess,
        successMessage: 'Running rescheduling workflow!',
    });

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={(value: boolean) => {
                setOpen(value);
            }}
            title={'Run Workflow'}
            content={
                <Grid container direction={'column'} spacing={2}>
                    <Grid item style={{ display: 'flex', justifyContent: 'left' }}>
                        <Button
                            variant={'primary'}
                            onClick={async () => {
                                await submit({
                                    orderId: productionOrderId,
                                });
                            }}
                        >
                            Reschedule and Reassign
                        </Button>
                    </Grid>
                </Grid>
            }
            buttonText={'Run workflows'}
            buttonProps={{ variant: 'secondary' }}
        />
    );
};
