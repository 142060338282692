import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import { LabsGqlLabPriceRuleType, LabsGqlOrderItemLinkGenericManufacturer } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';

export const ImplantDigitalAnalogForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.ImplantDigitalAnalog>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.ImplantDigitalAnalog>({
                authentic: {
                    type: 'boolean',
                    label: 'OEM',
                },
                generic_manufacturer: {
                    type: 'select',
                    label: 'Generic Manufacturer',
                    options: Object.values(LabsGqlOrderItemLinkGenericManufacturer),
                    optional: true,
                },
                manufacturer: {
                    type: 'text',
                    label: 'Manufacturer',
                    optional: true,
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          ...(price.rule.__typename === 'LabPriceImplantDigitalAnalogRuleDTO'
                              ? {
                                    authentic: price.rule.authentic,
                                    generic_manufacturer: price.rule.generic_manufacturer,
                                    manufacturer: price.rule.manufacturer,
                                }
                              : {}),
                      }
                    : {}
            }
            onSubmit={async result => {
                await submit({
                    name: result.name,
                    rule: {
                        type: LabsGqlLabPriceRuleType.ImplantDigitalAnalog,
                        authentic: result.authentic,
                        generic_manufacturer: result.generic_manufacturer || null,
                        manufacturer: result.manufacturer || null,
                    },
                });
            }}
            disabled={disabled}
        />
    );
};
