import type {
    LabsGqlCreateWorkflowStepDefinitionMutationVariables,
    LabsGqlWorkflowStepDefinitionFragment,
    LabsGqlUpdateWorkflowStepDefinitionMutationVariables,
} from '@orthly/graphql-operations';
import {
    useCreateWorkflowStepDefinitionMutation,
    useWorkflowStepDefinitionsQuery,
    useUpdateWorkflowStepDefinitionMutation,
} from '@orthly/graphql-react';
import MUITable from '@orthly/mui-table';
import { LoadBlocker, QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, Grid, IconButton, Tooltip, EditIcon, ToggleOffIcon, ToggleOnIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const WorkflowStepDefinitionCreator: React.VFC<{
    open: boolean;
    setOpen: (creating: boolean) => unknown;
    refetch: () => Promise<unknown>;
}> = ({ open, setOpen, refetch }) => {
    const [rawCreate] = useCreateWorkflowStepDefinitionMutation();
    const { submit: create, submitting: creating } = useChangeSubmissionFn(
        (data: LabsGqlCreateWorkflowStepDefinitionMutationVariables['data']) => rawCreate({ variables: { data } }),
        {
            closeOnComplete: true,
            successMessage: () => [`Workflow step definition created!`, {}],
            onSuccess: async () => {
                await refetch();
                setOpen(false);
            },
        },
    );
    return (
        <RootActionDialog
            title={`Create workflow step definition`}
            open={open}
            setOpen={setOpen}
            loading={creating}
            buttonText={``}
            CustomButton={() => null}
            content={
                <QuickForm<LabsGqlCreateWorkflowStepDefinitionMutationVariables['data']>
                    fields={{
                        name: { type: 'text' },
                        priority: { type: 'number' },
                    }}
                    initialValues={{}}
                    onSubmit={create}
                />
            }
        />
    );
};

const WorkflowStepDefinitionEditor: React.VFC<{
    open: boolean;
    setOpen: (creating: boolean) => unknown;
    workflowStepDefinitionUpdateData: LabsGqlUpdateWorkflowStepDefinitionMutationVariables['data'];
    refetch: () => Promise<unknown>;
}> = ({ open, setOpen, workflowStepDefinitionUpdateData, refetch }) => {
    const workflowStepDefinitionFields = _.omit(workflowStepDefinitionUpdateData, [`id`, `archived`]);
    const [rawUpdate] = useUpdateWorkflowStepDefinitionMutation();
    const { submit: update, submitting: updating } = useChangeSubmissionFn(
        (data: typeof workflowStepDefinitionFields) =>
            rawUpdate({ variables: { data: { ...workflowStepDefinitionUpdateData, ...data } } }),
        {
            closeOnComplete: true,
            successMessage: () => [`Workflow step definition updated!`, {}],
            onSuccess: async () => {
                await refetch();
                setOpen(false);
            },
        },
    );
    return (
        <RootActionDialog
            title={`Update workflow step definition`}
            open={open}
            setOpen={setOpen}
            loading={updating}
            buttonText={``}
            CustomButton={() => null}
            content={
                <QuickForm<typeof workflowStepDefinitionFields>
                    fields={{
                        name: { type: 'text' },
                        priority: { type: 'number' },
                    }}
                    initialValues={workflowStepDefinitionFields}
                    onSubmit={update}
                />
            }
        />
    );
};

export const WorkflowStepDefinitionActions: React.VFC<{
    workflowStepDefinitionFragment: LabsGqlWorkflowStepDefinitionFragment;
    refetch: () => Promise<unknown>;
}> = ({ workflowStepDefinitionFragment, refetch }) => {
    const workflowStepDefinitionUpdateData: LabsGqlUpdateWorkflowStepDefinitionMutationVariables['data'] = _.omit(
        workflowStepDefinitionFragment,
        [`__typename`, `updated_at`, `created_at`],
    );
    const [editing, setEditing] = React.useState(false);
    const [rawUpdate] = useUpdateWorkflowStepDefinitionMutation();
    const { submit: update, submitting: updating } = useChangeSubmissionFn(
        (data: LabsGqlUpdateWorkflowStepDefinitionMutationVariables['data']) => rawUpdate({ variables: { data } }),
        {
            closeOnComplete: true,
            successMessage: () => [`Workflow step definition updated!`, {}],
            onSuccess: () => void refetch(),
        },
    );
    const archive = React.useCallback(
        () => update({ ...workflowStepDefinitionUpdateData, archived: true }),
        [update, workflowStepDefinitionUpdateData],
    );
    const unarchive = React.useCallback(
        () => update({ ...workflowStepDefinitionUpdateData, archived: false }),
        [update, workflowStepDefinitionUpdateData],
    );
    return (
        <>
            {workflowStepDefinitionFragment.archived ? (
                <Tooltip title={`Unarchive`}>
                    <IconButton onClick={unarchive} disabled={updating} style={{ color: FlossPalette.DARK_TAN }}>
                        <LoadBlocker blocking={updating} loader={`circular`}>
                            <ToggleOffIcon />
                        </LoadBlocker>
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title={`Archive`}>
                    <IconButton onClick={archive} disabled={updating} style={{ color: FlossPalette.STAR_GRASS }}>
                        <LoadBlocker blocking={updating} loader={`circular`}>
                            <ToggleOnIcon />
                        </LoadBlocker>
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title={`Edit`}>
                <IconButton onClick={() => setEditing(true)} disabled={updating}>
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <WorkflowStepDefinitionEditor
                open={editing}
                setOpen={setEditing}
                workflowStepDefinitionUpdateData={workflowStepDefinitionUpdateData}
                refetch={refetch}
            />
        </>
    );
};

export const WorkflowStepDefinitionsForm: React.VFC = () => {
    const { data: { listWorkflowStepDefinitions: workflowStepDefinitionFragments = [] } = {}, refetch } =
        useWorkflowStepDefinitionsQuery();
    const [creating, setCreating] = React.useState(false);
    return (
        <Grid container>
            <MUITable<LabsGqlWorkflowStepDefinitionFragment>
                title={`Workflow step definitions`}
                data={workflowStepDefinitionFragments}
                displayOptions={{
                    fixedSearch: true,
                    elevation: 0,
                    viewColumns: true,
                    filter: true,
                    sort: true,
                }}
                actions={{
                    global: [
                        {
                            icon: `refresh`,
                            position: `toolbar`,
                            onClick: () => refetch()?.catch(console.error),
                            tooltip: `Refresh`,
                        },
                        { icon: `add`, position: `toolbar`, onClick: () => setCreating(true), tooltip: `Create` },
                    ],
                }}
                columns={[
                    { name: `Name`, render: `name` },
                    { name: `Priority`, render: `priority` },
                    { name: `Last Updated`, render: `updated_at`, type: `datetime` },
                    {
                        name: `Actions`,
                        render: fragment => (
                            <WorkflowStepDefinitionActions
                                workflowStepDefinitionFragment={fragment}
                                refetch={refetch}
                            />
                        ),
                    },
                ]}
            />
            <WorkflowStepDefinitionCreator open={creating} setOpen={setCreating} refetch={refetch} />
        </Grid>
    );
};
