import { ZENDESK_CREDIT_OR_REFUND_SOP_EXTERNAL_LINK } from './CreditOrRefund.types';
import { useCreditOrRefundFormContext } from './providers/CreditOrRefundFormProvider.graphql';
import { dayjsExt as dayjs } from '@orthly/runtime-utils';
import { Button, FlossPalette, Icon, IconButton, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const HeaderContainer = styled('div')({
    display: 'flex',
    padding: '32px 32px 0px 32px',
    flexDirection: 'column',
    gap: '8px',
});

const FlexContainer = styled('div')({
    display: 'flex',
});

const ViewSopButton = styled(Button)({
    height: '24px',
    padding: '0px !important',
});

const StickyCloseButton = styled(IconButton)({
    position: 'absolute',
    top: 15,
    right: 15,
});

export const CreditOrRefundHeader: React.FC = () => {
    const { formState, setOpen, viewOnly, existingCredit } = useCreditOrRefundFormContext();
    const title = `Issue${viewOnly ? 'd' : ''} ${formState.action === 'credit' ? 'Credit' : 'Refund'}`;
    const subHeader =
        formState.action === 'credit'
            ? 'You may issue a credit, or assign the credit to an entire order or any number of order items within an order.'
            : 'You may issue a refund for an entire order or any number of order items within an order.';

    const formatDate = (dateString: string) => {
        return dayjs(dateString).format('MMMM D, YYYY [at] h:mma');
    };

    return (
        <HeaderContainer>
            <StickyCloseButton size={'small'} onClick={() => setOpen(false)}>
                <Icon icon={'Close'} style={{ color: FlossPalette.BLACK }} />
            </StickyCloseButton>
            <Text variant={'h4'} medium>
                {title}
            </Text>
            <Text variant={'body2'} color={'GRAY'}>
                {existingCredit
                    ? `This credit was issued on ${formatDate(existingCredit.created_at)} by ${existingCredit.created_by}.`
                    : subHeader}
            </Text>
            <FlexContainer>
                <ViewSopButton
                    variant={'ghost'}
                    onClick={() =>
                        window.open(ZENDESK_CREDIT_OR_REFUND_SOP_EXTERNAL_LINK, '_blank', 'noreferrer noopener')
                    }
                >
                    View SOP
                </ViewSopButton>
            </FlexContainer>
        </HeaderContainer>
    );
};
