import type { FetchResult } from '@apollo/client';
import type {
    LabsGqlCreateDeliveryAddressMutation,
    LabsGqlCreateDeliveryAddressMutationVariables,
    LabsGqlEditDeliveryAddressMutation,
    LabsGqlToggleDeliveryAddressDeletedMutationVariables,
} from '@orthly/graphql-operations';
import {
    useCreateDeliveryAddressMutation,
    useEditDeliveryAddressMutation,
    useLabsAddresses,
    useToggleDeliveryAddressDeletedMutation,
} from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

type Vars = LabsGqlCreateDeliveryAddressMutationVariables['data'];
type DeleteVars = LabsGqlToggleDeliveryAddressDeletedMutationVariables;
type CreateRes = FetchResult<LabsGqlCreateDeliveryAddressMutation>;
type UpdateRes = FetchResult<LabsGqlEditDeliveryAddressMutation>;
type UpsertVars = Vars & { id?: string };
type UpsertRes = CreateRes | UpdateRes;

export function useDeliveryAddressControls(opts: { onSuccess?: () => void; onError?: (e: Error) => void } = {}) {
    const { onSuccess, onError } = opts;

    const { refetch: refetchAddresses } = useLabsAddresses();

    const [createMtn] = useCreateDeliveryAddressMutation();
    const [updateMtn] = useEditDeliveryAddressMutation();
    const [deleteMtn] = useToggleDeliveryAddressDeletedMutation();

    const upsertSubmitter = React.useCallback(
        (address: Vars & { id?: string }) => {
            if (address.id === undefined) {
                return createMtn({ variables: { data: address } });
            }
            const unwantedUpdateFields = ['__typename', 'id', 'deleted_at'];
            const filteredVars = Object.assign(
                {},
                ...Object.keys(address)
                    .filter((k): k is keyof Vars => !unwantedUpdateFields.includes(k))
                    .map(k => ({ [k]: address[k] })),
            );
            return updateMtn({ variables: { address_id: address.id, data: filteredVars } });
        },
        [createMtn, updateMtn],
    );
    const { submit: upsertAddress, submitting: upsertSubmitting } = useChangeSubmissionFn<UpsertRes, [UpsertVars]>(
        upsertSubmitter,
        {
            closeOnComplete: true,
            onError: e => onError?.(e),
            onSuccess: () => {
                void refetchAddresses();
                onSuccess?.();
            },
            disableDefaultErrorMessage: true,
        },
    );

    const deleteSubmitter = (variables: DeleteVars) => deleteMtn({ variables });
    const { submit: submitDelete, submitting: deleteSubmitting } = useChangeSubmissionFn<any, [DeleteVars]>(
        deleteSubmitter,
        {
            closeOnComplete: true,
            onError: e => onError?.(e),
            onSuccess: () => {
                void refetchAddresses();
                onSuccess?.();
            },
            disableDefaultErrorMessage: true,
        },
    );

    const deleteAddress = async (vars: DeleteVars) => {
        if (!window.confirm("Are you sure you'd like to delete this address?")) {
            return;
        }
        void submitDelete(vars);
    };

    return { upsertAddress, upsertSubmitting, deleteAddress, deleteSubmitting, submitDelete };
}
