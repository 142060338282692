import { useGetImpersonationTokenLazyQuery } from '@orthly/graphql-react';
import { CopyLinkIcon, RootActionDialog } from '@orthly/ui';
import {
    Button,
    Text,
    stylesFactory,
    CheckboxPrimitive as Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
} from '@orthly/ui-primitives';
import { useCopyToClipboard } from '@orthly/veneer';
import type { SetStateAction } from 'react';
import React from 'react';

const useStyles = stylesFactory(() => ({
    generateLinkBtnContainer: {
        justifyContent: 'flex-end',
        gap: 8,
        marginTop: 20,
    },
    endIcon: {
        margin: 0,
        paddingRight: 8,
    },
}));

interface GenerateLinkProps {
    setOpen: (open: boolean) => void;
    checked: boolean;
    setChecked: React.Dispatch<SetStateAction<boolean>>;
    practiceName?: string;
    setLinkHasBeenGenerated: React.Dispatch<SetStateAction<boolean>>;
    triggerQuery: () => void;
}

const GenerateLink: React.VFC<GenerateLinkProps> = ({
    setOpen,
    checked,
    setChecked,
    practiceName,
    setLinkHasBeenGenerated,
    triggerQuery,
}) => {
    const classes = useStyles();

    return (
        <>
            <FormControlLabel
                control={
                    <Checkbox color={'secondary'} checked={checked} onChange={() => setChecked(checked => !checked)} />
                }
                label={<Text variant={'body2'}>I confirm I am sending this to someone at {practiceName}</Text>}
            />
            <Grid container className={classes.generateLinkBtnContainer}>
                <Button
                    variant={'secondary'}
                    onClick={() => {
                        setChecked(false);
                        setOpen(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant={'primary'}
                    onClick={() => {
                        setLinkHasBeenGenerated(true);
                        triggerQuery();
                    }}
                    disabled={!checked}
                >
                    Generate link
                </Button>
            </Grid>
        </>
    );
};

interface CollectPaymentMethodModalProps {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    practiceName?: string;
    practiceId: string;
}

export const CollectPaymentMethodModal: React.FC<CollectPaymentMethodModalProps> = ({
    open,
    setOpen,
    practiceName,
    practiceId,
}) => {
    const classes = useStyles();

    const [paymentMethodUrl, setPaymentMethodUrl] = React.useState<string>('');
    const [checked, setChecked] = React.useState<boolean>(false);
    const [linkHasBeenGenerated, setLinkHasBeenGenerated] = React.useState<boolean>(false);

    const copyToClipboard = useCopyToClipboard();

    const onClickCopy = React.useCallback(() => {
        copyToClipboard({
            text: paymentMethodUrl,
            successMessage: 'Impersonate link copied to clipboard!',
            errorMessage: 'Failed to copy link :(',
        });
    }, [copyToClipboard, paymentMethodUrl]);

    const [triggerQuery] = useGetImpersonationTokenLazyQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        onCompleted: data => {
            const url = new URL(data.getImpersonationToken.practiceUrl);
            url.pathname = 'payment_methods_and_addresses';
            url.searchParams.set('addPaymentType', 'bankAccount');
            setPaymentMethodUrl(url.toString());
        },
    });

    return (
        <RootActionDialog
            title={'Generate Payment Method Entry Link'}
            subtitle={
                <Text variant={'body2'}>
                    You are about to generate a link that allows someone at {''}
                    <Text variant={'body2'} medium style={{ display: 'inline' }}>
                        {practiceName}
                    </Text>{' '}
                    to enter in a new payment method.
                </Text>
            }
            content={
                !linkHasBeenGenerated ? (
                    <GenerateLink
                        setOpen={setOpen}
                        checked={checked}
                        setChecked={setChecked}
                        practiceName={practiceName}
                        setLinkHasBeenGenerated={setLinkHasBeenGenerated}
                        triggerQuery={() =>
                            void triggerQuery({
                                variables: {
                                    organization_id: practiceId,
                                    user_id: null,
                                    expires_in_seconds: 3600,
                                },
                            })
                        }
                    />
                ) : (
                    <TextField
                        variant={'standard'}
                        fullWidth
                        label={'Impersonate Practice Portal Link'}
                        value={paymentMethodUrl}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position={'end'} className={classes.endIcon}>
                                    <Tooltip title={'Copy Link'}>
                                        <IconButton onClick={onClickCopy} size={'small'}>
                                            <CopyLinkIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                    />
                )
            }
            open={open}
            setOpen={setOpen}
            loading={false}
            hideButton={true}
            onClose={() => {
                setChecked(false);
            }}
            showCloseButton
        />
    );
};
