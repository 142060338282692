import { FlossPaletteUtils, Text, stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';
import { useDropzone } from 'react-dropzone';

const useStyles = stylesFactory(() => ({
    root: { height: '100%', overflow: 'hidden', position: 'relative' },
    overlayGrid: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: FlossPaletteUtils.toRgba('WHITE', 0.8),
        backdropFilter: 'blur(4px)',
    },
}));

interface DandyChatFileDropProps {
    children: React.ReactNode;
    onFilesDropped: (files: File[]) => void;
    disabled?: boolean;
}

/**
 * Wrap DandyChat in this component to enable drag and drop file functionality
 */
export const DandyChatFileDrop: React.VFC<DandyChatFileDropProps> = props => {
    const classes = useStyles();
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        noClick: true,
        onDrop: props.onFilesDropped,
        disabled: props.disabled,
    });
    const { ref, ...rootProps } = React.useMemo(() => getRootProps(), [getRootProps]);
    return (
        <Grid {...rootProps} container wrap={'nowrap'} direction={'column'} className={classes.root} ref={ref}>
            <input {...getInputProps()} />
            {props.children}
            {isDragActive && (
                // overlay when dragging files onto this
                <Grid alignContent={'center'} container justifyContent={'center'} className={classes.overlayGrid}>
                    <Text variant={'h6'}>Drop files here</Text>
                </Grid>
            )}
        </Grid>
    );
};
