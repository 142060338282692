import { useGuidedWaxupSelector } from '../state/GuidedWaxupState';
import { isOptionDisabled } from '../utils/structuredNotesRestrictions';
import type { NestedCheckboxListOption } from '@orthly/dentin';
import type { LabsGqlGuidedWaxupPresetType, RenderedCategoryTree } from '@orthly/graphql-schema';
import { getDesignOrderPresetCategoryTrees } from '@orthly/graphql-schema';
import React from 'react';

export const useStructuredNotesOptions = (presetType: LabsGqlGuidedWaxupPresetType) => {
    const preset = useGuidedWaxupSelector(s => s.presets[presetType]);

    return React.useMemo(() => {
        const decorateTree = (options: RenderedCategoryTree[] = []): NestedCheckboxListOption[] =>
            options.map(option => ({
                ...option,
                children: decorateTree(option.children),
                disabled: isOptionDisabled(presetType, option.category, preset?.structured_notes ?? []),
            }));

        const categoryTrees = getDesignOrderPresetCategoryTrees(presetType);
        return decorateTree(categoryTrees);
    }, [preset?.structured_notes, presetType]);
};
