import type { ScanbodyRequestRow } from './ScanbodyOrdersTable.types';
import _ from 'lodash';

// We use uniq here because we can have the same scan body in multiple relationships (can be both linked as authentic and generic)
// but in this function we're just interested in getting the distinct scan bodies available
export const getUniquePrioritySortedScanbodyList = (request: ScanbodyRequestRow) => {
    const isAuthentic = request.abutment_type === 'Authentic';
    return _.uniqBy(
        _.sortBy(request.implant_type?.scanbody_relationships ?? [], r => r.priority)
            .filter(
                r => !request.abutment_type || request.abutment_type === 'LabChoice' || r.is_authentic === isAuthentic,
            )
            .map(s => s.scanbody),
        r => r.id,
    );
};
