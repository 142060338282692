import { IndividualButton } from '../BottomToolbar/IndividualButton';
import { LabeledButton } from '../BottomToolbar/LabeledButton';
import { ToolbarContainer } from '../BottomToolbar/ToolbarContainer';
import { stylesFactory, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    button: {
        margin: '0px 4px',
    },
}));

export interface ViewControlButtonsProps {
    resetCamera: () => void;
    viewExpanded?: boolean;
    expandView: () => void;
    minimizeView: () => void;
    connected?: boolean;
    connectedClassName?: string;
}

export const ViewControlButtons: React.VFC<ViewControlButtonsProps> = ({
    resetCamera,
    viewExpanded,
    expandView,
    minimizeView,
    connected,
    connectedClassName,
}) => {
    const classes = useStyles();

    const viewSizeAction = viewExpanded ? minimizeView : expandView;
    const viewSizeText = viewExpanded ? 'Minimize view' : 'Expand view';

    return connected ? (
        <ToolbarContainer className={connectedClassName}>
            <LabeledButton onClick={resetCamera} tooltip={'Reset camera'} label={'Reset camera'} />
            <LabeledButton onClick={viewSizeAction} tooltip={viewSizeText} label={viewSizeText} />
        </ToolbarContainer>
    ) : (
        <>
            <IndividualButton className={classes.button} onClick={resetCamera} tooltip={'Reset camera'}>
                <Text variant={'caption'}>Reset camera</Text>
            </IndividualButton>
            <IndividualButton className={classes.button} onClick={viewSizeAction} tooltip={viewSizeText}>
                <Text variant={'caption'}>{viewSizeText}</Text>
            </IndividualButton>
        </>
    );
};
