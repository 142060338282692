/* eslint-disable max-lines */
import { FileUploaderBulk } from '../FirebaseUpload/FileUploaderBulk';
import { SidebarOverlay } from '../Sidebar/SidebarOverlay';
import { ActivityCounterProvider, useActivityCounter } from './ActivityCounter';
import type { MetafieldInputs } from './DesignReviewMetafields';
import {
    DesignReviewMetafields,
    metafieldInputsAreValid,
    metafieldInputsToMetafieldSubmissions,
    useReviewFields,
} from './DesignReviewMetafields';
import { useDesignTaskStyles, useTeethNeedingStumpShade, DesignTaskStumpShadeField } from './OrderDesignTaskPane.util';
import { CompleteDesignTaskPaneAlert } from './OrderDesignTaskPaneAlert';
import { OrderDesignTaskPanePrepFeedback, useDoesTaskRequirePrepFeedback } from './OrderDesignTaskPanePrepFeedback';
import type { DesignSubmissionValidationResult } from './OrderDesignTaskValidation.hooks.graphql';
import type { RemovableFileFields } from './RemovableFilesUploadFields';
import { RemovableFilesUploadFields } from './RemovableFilesUploadFields';
import type { LabsGqlOrder, LabsGqlWorkflowTaskFragment, LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import type { LabsGqlDesignMetafieldSubmissionInput, LabsGqlScanToothShadeInput } from '@orthly/graphql-schema';
import {
    LabsGqlWorkflowTaskType,
    LabsGqlDesignReviewFieldType,
    LabsGqlScanArchKind,
    LabsGqlOrderItemSkuType,
} from '@orthly/graphql-schema';
import type { IOrderItemV2DTO, TeethShade, ToothNumber } from '@orthly/items';
import { ItemCheckerUtils, OrderItemV2Utils, ToothUtils } from '@orthly/items';
import { getFullStoragePath, DesignStorageConfigs } from '@orthly/shared-types';
import { OrthlyBrowserConfig, SimpleSelect, SimpleTextField } from '@orthly/ui';
import { Text, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export type OrderDesignTaskPaneSubmissionData = Omit<DesignTaskFields, 'metafields'> & {
    metafields: LabsGqlDesignMetafieldSubmissionInput[];
};

// We require internal designers to label scans with an associated model so that we can fix them up automatically,
// and save the Ops folks some time
const needsScanArchKind = (order: LabsGqlLabOrderFragment): boolean => {
    return (
        order.fulfillment_workflow.configuration.internal_design_required &&
        order.items_v2.some(v => v.sku === LabsGqlOrderItemSkuType.Model)
    );
};

const ScanArchKinds = [
    { label: 'Both arches are full arch scans', value: LabsGqlScanArchKind.BothFull },
    { label: 'both arches are quad scans', value: LabsGqlScanArchKind.BothQuad },
    { label: '1 arch is quad and other is full arch', value: LabsGqlScanArchKind.OneQuad },
];

interface CompleteDesignTaskPaneProps {
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
    task: LabsGqlWorkflowTaskFragment;
    // Whether or not to display this pane
    open: boolean;
    setOpen: (open: boolean) => void;
    // Callback for data submission when submit button is clicked.
    submit: (data: OrderDesignTaskPaneSubmissionData) => Promise<void>;
    submitting?: boolean;
    designSubmissionValidationResult?: DesignSubmissionValidationResult;
    automaticUpload?: boolean;
    onReset?: () => void;
}

interface DesignTaskFields extends RemovableFileFields {
    design_file_path?: string;
    notes?: string;
    scan_arch_kind?: LabsGqlScanArchKind;
    scan_stump_shades?: LabsGqlScanToothShadeInput[];
    design_prep_feedback?: string[];
}

interface CompleteDesignTaskContentProps {
    order: LabsGqlOrder;
    orderItems: IOrderItemV2DTO[];
    data: DesignTaskFields;
    onFormUpdated: (data: (previous: DesignTaskFields) => DesignTaskFields) => void;
    automaticUpload: boolean;
    shadeTeeth: ToothNumber[];
    onReset?: () => void;
    isPrepFeedbackRequired: boolean;
}

// eslint-disable-next-line max-lines-per-function
const CompleteDesignTaskContent: React.FC<CompleteDesignTaskContentProps> = ({
    order,
    orderItems,
    data,
    onFormUpdated,
    automaticUpload,
    shadeTeeth,
    onReset,
    isPrepFeedbackRequired,
}) => {
    const classes = useDesignTaskStyles();

    // Utility functions, existing just to provide easier data management.
    // Sets the value of field, while maintaining old values for the other fields.
    const updateField = <T extends keyof DesignTaskFields>(field: T, value: DesignTaskFields[T]) => {
        onFormUpdated(previous => ({ ...previous, [field]: value }));
    };

    const updateScanToothField = (tooth: ToothNumber, shade: TeethShade) => {
        onFormUpdated(previous => {
            const scan_stump_shades = data.scan_stump_shades?.filter(shade => shade.tooth !== tooth) ?? [];
            return { ...previous, scan_stump_shades: [...scan_stump_shades, { tooth, shade }] };
        });
    };

    const [currentTime] = React.useState<number>(Date.now());
    const storagePathConfig = getFullStoragePath(
        OrthlyBrowserConfig.env,
        DesignStorageConfigs.designs,
        order.id,
        `${currentTime}`,
    );

    return (
        <>
            {!automaticUpload && (
                <Grid container item direction={'column'}>
                    <Text variant={'body2'} className={classes.fieldLabel}>
                        Completed design file
                    </Text>
                    <FileUploaderBulk
                        autoSubmit
                        elevation={0}
                        storagePathConfig={storagePathConfig}
                        paperStyle={{ padding: 0 }}
                        dropzoneOptions={{ accept: { 'application/zip': ['.zip'] }, multiple: false }}
                        onComplete={results => {
                            onReset?.();
                            updateField(
                                'design_file_path',
                                results.find(r => r.uploadedPath.toLowerCase().endsWith('.zip'))?.uploadedPath,
                            );
                        }}
                        onReset={() => {
                            updateField('design_file_path', undefined);
                            onReset?.();
                        }}
                        prependTimestampToFilename={false} // Should keep the design filenames as they are when exported
                    />
                    <RemovableFilesUploadFields
                        orderItems={orderItems}
                        storagePathConfig={storagePathConfig}
                        updateField={updateField}
                    />
                </Grid>
            )}
            {isPrepFeedbackRequired && (
                <OrderDesignTaskPanePrepFeedback
                    order={order}
                    feedback={data.design_prep_feedback ?? []}
                    setFeedback={feedback => updateField('design_prep_feedback', feedback)}
                />
            )}
            <Grid container item direction={'column'}>
                <Text variant={'body2'} className={classes.fieldLabel}>
                    Want to leave a note?
                </Text>
                <SimpleTextField
                    TextFieldProps={{ multiline: true, rows: 3, style: { fontWeight: 'normal' } }}
                    value={data.notes}
                    onChange={notes => updateField('notes', notes)}
                    label={'Note'}
                />
            </Grid>
            {needsScanArchKind(order) && (
                <Grid container item direction={'column'}>
                    <Text variant={'body2'} className={classes.fieldLabel}>
                        Select arch scans *
                    </Text>
                    <SimpleSelect
                        FormControlProps={{ style: { width: '100%' }, variant: 'standard' }}
                        options={ScanArchKinds}
                        onChange={value => updateField('scan_arch_kind', value as LabsGqlScanArchKind)}
                        value={data.scan_arch_kind}
                        label={'Scan arch kind'}
                    />
                </Grid>
            )}
            <DesignTaskStumpShadeField
                shadeTeeth={shadeTeeth}
                stumpShades={data.scan_stump_shades}
                updateScanToothField={updateScanToothField}
            />
        </>
    );
};

function hasNecessaryFiles(data: DesignTaskFields, items: IOrderItemV2DTO[]): boolean {
    // Thermoform items may be designed in CADflow, so we require the model .stl files
    // that we use for manufacturing, but not a 3Shape design file.
    if (items.some(ItemCheckerUtils.isThermoformItem)) {
        if (items.some(ItemCheckerUtils.isThermoformNightGuard)) {
            // For Thermoform night guards, we need both upper and lower model files
            // so that the lab can check occlusion.
            return !!(data.model_upper_stl && data.model_lower_stl);
        }
        // Otherwise require only the model file that is used to manufacture the item.
        return !!(data.model_upper_stl || data.model_lower_stl);
    }

    return !!data.design_file_path;
}

const CompleteDesignTaskPaneImpl: React.VFC<CompleteDesignTaskPaneProps> = props => {
    const {
        order,
        setOpen,
        submitting,
        open,
        submit,
        task,
        automaticUpload,
        onReset,
        designSubmissionValidationResult,
    } = props;
    const [data, setData] = React.useState<DesignTaskFields>({});

    const [metafieldInputs, setMetafieldInputs] = React.useState<MetafieldInputs>({});

    const isDesignPrep = task.type === LabsGqlWorkflowTaskType.DesignPrep;
    const fieldType = isDesignPrep
        ? LabsGqlDesignReviewFieldType.DesignPrep
        : LabsGqlDesignReviewFieldType.InternalDesign;
    const { reviewFields, loading } = useReviewFields(order, fieldType);
    const isValid = React.useMemo(
        () => metafieldInputsAreValid(metafieldInputs, reviewFields),
        [metafieldInputs, reviewFields],
    );

    const buttonsDisabled = submitting || loading || !isValid;

    const teethNeedingStumpShade = useTeethNeedingStumpShade(order);
    const isPrepFeedbackRequired = useDoesTaskRequirePrepFeedback(order);
    const orderItems = OrderItemV2Utils.parseItems(order.items_v2);
    const { isActive: isUploadActive } = useActivityCounter();

    const needDesignFile = !automaticUpload && !hasNecessaryFiles(data, orderItems);

    React.useEffect(() => {
        setData(previous => {
            if (!previous.scan_stump_shades) {
                return previous;
            }

            const scan_stump_shades = previous.scan_stump_shades.filter(
                shade => ToothUtils.isToothNumber(shade.tooth) && teethNeedingStumpShade.includes(shade.tooth),
            );

            if (previous.scan_stump_shades.length === scan_stump_shades.length) {
                return previous;
            }

            return { ...previous, scan_stump_shades };
        });
    }, [teethNeedingStumpShade, setData]);

    return (
        <SidebarOverlay
            bodyStyle={{}}
            open={open}
            setOpen={setOpen}
            title={task.type === LabsGqlWorkflowTaskType.DesignPrep ? 'Complete prep' : 'Complete design'}
            secondaryButton={{
                label: 'Cancel',
                disabled: buttonsDisabled,
                onClick: async () => {
                    setOpen(false);
                },
            }}
            primaryButton={{
                label: 'Complete',
                disabled:
                    buttonsDisabled ||
                    needDesignFile ||
                    isUploadActive ||
                    (teethNeedingStumpShade.length > 0 &&
                        (data.scan_stump_shades?.length ?? 0) < teethNeedingStumpShade.length) ||
                    (needsScanArchKind(order) && !data.scan_arch_kind) ||
                    (isPrepFeedbackRequired && !data.design_prep_feedback?.length) ||
                    (designSubmissionValidationResult?.status === 'failed' &&
                        !designSubmissionValidationResult.canBypass),
                icon: 'CheckIcon',
                onClick: async () => {
                    const metafieldSubmissions = _.compact([
                        ...metafieldInputsToMetafieldSubmissions(metafieldInputs, reviewFields),
                    ]);
                    await submit({ ...data, metafields: metafieldSubmissions });
                },
            }}
            alert={<CompleteDesignTaskPaneAlert result={designSubmissionValidationResult} />}
        >
            <Grid container spacing={2} direction={'column'} style={{ padding: '8px 16px' }}>
                <DesignReviewMetafields {...{ metafieldInputs, reviewFields, setMetafieldInputs }} />

                <CompleteDesignTaskContent
                    order={order}
                    orderItems={orderItems}
                    data={data}
                    automaticUpload={automaticUpload ?? false}
                    onFormUpdated={setData}
                    shadeTeeth={teethNeedingStumpShade}
                    onReset={onReset}
                    isPrepFeedbackRequired={isPrepFeedbackRequired}
                />
            </Grid>
        </SidebarOverlay>
    );
};

export const CompleteDesignTaskPane: React.VFC<CompleteDesignTaskPaneProps> = props => {
    return (
        <ActivityCounterProvider>
            <CompleteDesignTaskPaneImpl {...props} />
        </ActivityCounterProvider>
    );
};
