import { RouterUtils } from '../../utils/router/RouterUtils';
import { LandingPage3D } from './LandingPage3D';
import { NativeDesignViewer } from './NativeDesignViewer';
import { OrthlyErrorBoundary } from '@orthly/ui';
import {
    GuidedWaxupRoot,
    DcmViewer,
    ScanReviewLandingPage,
    ScanReviewShadeMatchingLandingPage,
    ToothPlacementVisualizationLandingPage,
} from '@orthly/veneer';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

export const Team3dRoot: React.VFC = () => {
    return (
        <OrthlyErrorBoundary level={'warning'}>
            <Switch>
                <Route
                    path={`${RouterUtils.pathForScreen('team_3d')}/design_viewer`}
                    component={NativeDesignViewer}
                    exact
                />
                <Route path={`${RouterUtils.pathForScreen('team_3d')}/dcm_viewer`} component={DcmViewer} exact />
                <Route
                    path={`${RouterUtils.pathForScreen('team_3d')}/scan_review`}
                    component={ScanReviewLandingPage}
                    exact
                />
                <Route
                    path={`${RouterUtils.pathForScreen('team_3d')}/scan_review_shade_matching`}
                    component={ScanReviewShadeMatchingLandingPage}
                    exact
                />
                <Route
                    path={`${RouterUtils.pathForScreen('team_3d')}/tooth_placement_viewer`}
                    component={ToothPlacementVisualizationLandingPage}
                    exact
                />
                <Route path={`${RouterUtils.pathForScreen('team_3d')}/guided/:orderId/:revisionId?`} exact>
                    <GuidedWaxupRoot internalEvaluation />
                </Route>
                <Route path={`${RouterUtils.pathForScreen('team_3d')}`} component={LandingPage3D} exact />
            </Switch>
        </OrthlyErrorBoundary>
    );
};
