import { isGuidedPreset } from '../GuidedWaxup.util';
import type { PresetInfo } from '../state/GuidedWaxupState';
import { useGuidedWaxupSelector } from '../state/GuidedWaxupState';
import type { NestedCheckboxListSelections } from '@orthly/dentin';
import { nestedCheckboxListUtils } from '@orthly/dentin';
import type { LabsGqlGuidedWaxupPresetType, RenderedCategoryTree } from '@orthly/graphql-schema';
import { getDesignOrderPresetCategoryTrees, LabsGqlGuidedWaxupPresetStatus } from '@orthly/graphql-schema';
import React from 'react';

const isSelectionValid = (
    scaffoldNode: RenderedCategoryTree,
    selection: { children: NestedCheckboxListSelections },
): boolean => {
    // A selection is only valid if it can be traced all the way to a leaf node
    if (scaffoldNode.children && scaffoldNode.children.length > 0) {
        const selectionChildren = selection.children;
        const selectedChildren = scaffoldNode.children.filter(child =>
            selectionChildren.hasOwnProperty(child.category),
        );

        if (selectedChildren.length === 0) {
            return false;
        }

        return selectedChildren.every(child =>
            // Using a non-null assertion here because selectedChildren was constructed
            // using a filter that only includes children that are present in the selection
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            isSelectionValid(child, selectionChildren[child.category]!),
        );
    } else {
        return true;
    }
};

export const presetHasValidSelections = (presetType: LabsGqlGuidedWaxupPresetType, preset: PresetInfo): boolean => {
    if (!preset.structured_notes?.length) {
        return false;
    }

    const scaffold = getDesignOrderPresetCategoryTrees(presetType);
    const selections = nestedCheckboxListUtils.unflattenSelections(preset.structured_notes, scaffold);

    for (const node of scaffold) {
        const selection = selections[node.category];
        if (selection && !isSelectionValid(node, selection)) {
            return false;
        }
    }

    return true;
};

export const isUnfinishedPreset = (presetType: LabsGqlGuidedWaxupPresetType, preset: PresetInfo): boolean => {
    if (preset.status !== LabsGqlGuidedWaxupPresetStatus.Rejected) {
        return false;
    }

    if (isGuidedPreset(presetType)) {
        return !presetHasValidSelections(presetType, preset);
    }

    return !preset.notes?.trim().length;
};

export const useUnfinishedPresets = (enabled: boolean) => {
    const presets = useGuidedWaxupSelector(s => s.presets);

    return React.useMemo(
        () =>
            enabled
                ? Object.entries(presets)
                      .filter(([key, preset]) => isUnfinishedPreset(key as LabsGqlGuidedWaxupPresetType, preset))
                      .map(([key]) => key as LabsGqlGuidedWaxupPresetType)
                : [],
        [enabled, presets],
    );
};
