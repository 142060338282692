import type { OrderDetailTrackerProps } from '../OrderDetailTracker/OrderDetailTracker.types';
import type { OrderStepDefinition } from './OrderDetailTrackerV2.types';
import { OrderDetailTrackerAnimation } from './components/OrderDetailTrackerAnimation';
import {
    getStepperBackgroundColor,
    getStepperBarColor,
    getStepperBarWidth,
    getStepperMargin,
    getStepSubtitleColor,
    getStepTitleColor,
    getSubtitleAlignment,
    useStepperStyles,
} from './util/OrderDetailTrackerV2View.util';
import { LabsGqlOrderTrackerStepDefinitionStyle } from '@orthly/graphql-schema';
import { FlossPalette, Grid, Icon, Text, Tooltip, styled, useScreenIsMobile } from '@orthly/ui-primitives';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';

const TrackerContainer = styled('div')({
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
});

const SubtitleContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const MainSubtitle = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const DeliveryStepContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: 120,
});

interface OrderDetailStepTitleProps extends Omit<OrderStepDefinition, 'active'> {
    isOnHold: boolean;
    idx: number;
    activeStepIndex: number;
    classes: ReturnType<typeof useStepperStyles>;
    isPractice: boolean;
    stepCount: number;
    simple?: boolean;
}

const COMMON_STEP_TITLE_SEPARATOR = '\n';

const OrderDetailStepTitle: React.VFC<OrderDetailStepTitleProps> = props => {
    const {
        isOnHold,
        title,
        subtitle,
        subtitleAttachment,
        alert,
        idx,
        activeStepIndex,
        classes,
        isPractice,
        stepCount,
        simple,
        infoTooltip,
        style,
    } = props;
    const isMobile = useScreenIsMobile();

    return (
        <>
            <Text
                variant={simple && !isMobile ? 'body2' : 'caption'}
                medium
                className={classes.stepText}
                color={getStepTitleColor(idx, activeStepIndex, isMobile, stepCount, alert, props.simple)}
            >
                {title}
            </Text>
            {subtitle && !simple && (
                <SubtitleContainer style={{ alignItems: getSubtitleAlignment(idx, stepCount) }}>
                    <MainSubtitle
                        style={{
                            justifyContent: idx === stepCount - 1 ? 'end' : undefined,
                        }}
                    >
                        <Text
                            variant={idx === stepCount - 1 ? 'body2' : 'caption'}
                            className={classes.stepText}
                            medium={idx === stepCount - 1}
                            color={getStepSubtitleColor(style, idx, stepCount, isPractice)}
                            style={
                                isPractice && isOnHold && idx === stepCount - 1
                                    ? { textDecoration: 'line-through', textAlign: 'center' }
                                    : { textAlign: 'center' }
                            }
                        >
                            {subtitle}
                        </Text>
                        {!!infoTooltip?.length && (
                            <Tooltip
                                title={
                                    infoTooltip.length === 1 ? (
                                        infoTooltip[0]
                                    ) : (
                                        <ul>
                                            {infoTooltip.map(datum => (
                                                <li key={datum}>{datum}</li>
                                            ))}
                                        </ul>
                                    )
                                }
                                arrow
                            >
                                <Icon
                                    icon={'InfoIcon'}
                                    style={{ color: FlossPalette.GRAY, fontSize: 16, marginLeft: 4 }}
                                />
                            </Tooltip>
                        )}
                    </MainSubtitle>

                    {!isOnHold && subtitleAttachment && (
                        <Text
                            component={'div'}
                            variant={'caption'}
                            className={classes.stepText}
                            color={'BLACK'}
                            style={{ lineHeight: '10px' }}
                        >
                            ({subtitleAttachment})
                        </Text>
                    )}
                </SubtitleContainer>
            )}
        </>
    );
};

const getDisplayStep = (
    currentStep: OrderStepDefinition,
    activeStep: OrderStepDefinition | undefined,
    isMobile: boolean,
    idx: number,
) => {
    if (isMobile && idx !== 0) {
        return undefined;
    }

    if (isMobile && activeStep) {
        return { ...activeStep, subtitleAttachment: undefined };
    }

    return currentStep;
};

export const OrderDetailTrackerV2: React.FC<OrderDetailTrackerProps> = props => {
    const { padded, isPractice, orderTrackerEntries, simple, animated, stepOverrides, isOnHold } = props;
    const isMobile = useScreenIsMobile();

    const [clientWidth, setClientWidth] = React.useState(0);
    // TODO: fix the frontent this is sad
    const steps = React.useMemo<OrderStepDefinition[]>(() => {
        const stepDefinitions = (orderTrackerEntries ?? []).map<OrderStepDefinition>(step => ({
            active: step.active,
            title: step.title,
            alert: step.alert ?? undefined,
            subtitle: simple ? undefined : step.subtitle ?? undefined,
            subtitleAttachment: step.subtitleAttachment ?? undefined,
            infoTooltip: step.infoTooltip ?? undefined,
            style: step.style ?? undefined,
        }));

        if (stepOverrides) {
            stepOverrides.forEach((override, idx) => {
                if (stepDefinitions[idx]) {
                    stepDefinitions[idx] = { title: override, active: idx === stepOverrides.length - 1 };
                }
            });
        }

        return stepDefinitions;
    }, [orderTrackerEntries, stepOverrides, simple]);

    const activeStepIndex = _.findLastIndex(steps, step => step.active);
    const activeStep = steps[activeStepIndex];
    const deliveryStep = steps[steps.length - 1];
    const baseClasses = useStepperStyles();

    const needsAttention = steps.some(step => step.style === LabsGqlOrderTrackerStepDefinitionStyle.Attention);
    const isWarningStyle = steps.some(step => step.style === LabsGqlOrderTrackerStepDefinitionStyle.Warning);

    return (
        <TrackerContainer style={{ margin: getStepperMargin(padded) }}>
            <Grid
                container
                className={baseClasses.stepper}
                style={{
                    background: getStepperBackgroundColor(needsAttention),
                }}
                direction={'row'}
                wrap={'nowrap'}
                justifyContent={'space-between'}
            >
                <div
                    ref={(el: HTMLDivElement) => {
                        setClientWidth(el?.clientWidth ?? 0);
                    }}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: getStepperBarWidth(activeStepIndex, steps.length, 0),
                        height: 16,
                        background: getStepperBarColor(needsAttention, isWarningStyle, simple),
                        borderRadius: 16,
                        zIndex: 0,
                    }}
                >
                    <OrderDetailTrackerAnimation animated={animated} clientWidth={clientWidth} />
                </div>

                {steps.map((step, idx) => {
                    const displayStep = getDisplayStep(step, activeStep, isMobile, idx);

                    return (
                        <Grid item className={baseClasses.stepWrapper} key={idx}>
                            {(!animated || (idx !== 0 && idx !== (stepOverrides?.length ?? 0) - 1)) && (
                                <div
                                    className={clsx(
                                        baseClasses.step,
                                        idx === activeStepIndex ? baseClasses.activeStep : baseClasses.inactiveStep,
                                    )}
                                />
                            )}
                            <Grid
                                container
                                className={clsx(
                                    baseClasses.stepTextWrapper,
                                    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                                    // eslint-disable-next-line no-nested-ternary
                                    idx === 0
                                        ? baseClasses.firstStepTextWrapper
                                        : idx === steps.length - 1
                                          ? baseClasses.lastStepTextWrapper
                                          : baseClasses.middleStepTextWrapper,
                                )}
                                wrap={'nowrap'}
                                direction={'column'}
                            >
                                {_.zip(
                                    displayStep?.title.split(COMMON_STEP_TITLE_SEPARATOR),
                                    displayStep?.subtitle?.split(COMMON_STEP_TITLE_SEPARATOR),
                                    displayStep?.subtitleAttachment?.split(COMMON_STEP_TITLE_SEPARATOR),
                                ).map(([title, subtitle, subtitleAttachment]) => (
                                    <OrderDetailStepTitle
                                        key={idx}
                                        idx={idx}
                                        activeStepIndex={activeStepIndex}
                                        classes={baseClasses}
                                        stepCount={steps.length}
                                        isPractice={isPractice}
                                        title={title ?? ''}
                                        subtitle={subtitle}
                                        subtitleAttachment={subtitleAttachment}
                                        alert={displayStep?.alert}
                                        simple={simple}
                                        infoTooltip={step.infoTooltip}
                                        style={step.style}
                                        isOnHold={isOnHold}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>

            {isMobile && deliveryStep && (
                <DeliveryStepContainer>
                    <OrderDetailStepTitle
                        idx={steps.length - 1}
                        activeStepIndex={activeStepIndex}
                        classes={baseClasses}
                        isPractice={isPractice}
                        stepCount={steps.length}
                        title={deliveryStep.title}
                        subtitle={deliveryStep.subtitle}
                        subtitleAttachment={deliveryStep.subtitleAttachment}
                        simple={simple}
                        isOnHold={isOnHold}
                    />
                </DeliveryStepContainer>
            )}
        </TrackerContainer>
    );
};
