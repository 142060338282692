import { LabsGqlOneTimeChargeCategory } from '@orthly/graphql-schema';
import { downloadAsCsv } from '@orthly/mui-table';
import { Text, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    downloadTemplate: {
        display: 'inline',
        cursor: 'pointer',
    },
}));

export const ManualInvoiceDialogSubtitle: React.VFC<{
    invoiceItemsHaveBeenUploaded: boolean;
}> = ({ invoiceItemsHaveBeenUploaded }) => {
    const classes = useStyles();
    if (invoiceItemsHaveBeenUploaded) {
        return <>Confirm the list below before proceeding</>;
    } else {
        return (
            <Text variant={'body2'}>
                <p>
                    Upload{' '}
                    <Text
                        variant={'body2'}
                        color={'PRIMARY_FOREGROUND'}
                        medium
                        onClick={() =>
                            downloadAsCsv(
                                Object.keys(LabsGqlOneTimeChargeCategory).map(c => ({
                                    practice_id: '',
                                    item_category: c,
                                    item_description: '',
                                    price_dollars: 0,
                                })),
                                `Manual Invoice Item Bulk Upload Template`,
                            )
                        }
                        className={classes.downloadTemplate}
                    >
                        this template
                    </Text>{' '}
                    to add invoice items in bulk.
                </p>
                For unreturned or damaged equipment, click{' '}
                <Text
                    variant={'body2'}
                    color={'PRIMARY_FOREGROUND'}
                    medium
                    onClick={() =>
                        window.open(
                            'https://docs.google.com/spreadsheets/d/1YU28His7vPg4vMAVJG65xKq_-vq0oqzgUu7wVl_Oe4M/edit?usp=sharing',
                            '_blank',
                            'noopener,noreferrer',
                        )
                    }
                    className={classes.downloadTemplate}
                >
                    this customized template
                </Text>{' '}
                to open a Google Sheet in a new tab and follow the instructions.
            </Text>
        );
    }
};
