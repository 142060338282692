import { AnalyticsClient } from '../../../../../../../utils/analyticsClient';
import { DoctorDesignTaskAlerts } from '../DoctorDesignTaskAlerts';
import type { OrderDesignSubmissionCoreProps } from './OrderDesignSubmissionCommon.graphql';
import { OrderDesignSubmissionCommon } from './OrderDesignSubmissionCommon.graphql';
import type { LabsGqlCompleteModelDesignTaskMutationVariables } from '@orthly/graphql-operations';
import { useCompleteModelDesignTaskMutation } from '@orthly/graphql-react';
import { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

type Vars = LabsGqlCompleteModelDesignTaskMutationVariables['data'];

export const OrderModelDesignTaskAlert: React.FC<OrderDesignSubmissionCoreProps> = ({
    order,
    variant,
    refetchOrder,
    designRevision,
}) => {
    const [submitMtn] = useCompleteModelDesignTaskMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => [`Design task completed!`, {}],
        onSuccess: async () => {
            await refetchOrder();
            AnalyticsClient.track(`Ops - Portal - Model Design Task Completed`, {
                $groups: {
                    order: order.id,
                },
            });
        },
    });

    const CardSubtitle = React.useCallback(() => {
        return <DoctorDesignTaskAlerts lab_slip_alerts={order.lab_slip_alerts} />;
    }, [order.lab_slip_alerts]);

    return (
        <OrderDesignSubmissionCommon
            taskType={LabsGqlWorkflowTaskType.ModelDesign}
            onSubmit={async ({ design_file_path, notes, overrodeValidation }) => {
                if (!design_file_path) {
                    return;
                }

                await submit({
                    design_file_path,
                    notes,
                    order_id: order.id,
                    override_validation: overrodeValidation,
                });
            }}
            submitting={submitting}
            CardTitle={'Model Design'}
            CardSubtitle={CardSubtitle}
            order={order}
            refetchOrder={refetchOrder}
            variant={variant}
            designRevision={designRevision}
        />
    );
};
