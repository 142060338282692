import { ChangeSplitManufacturerAction } from './ChangeSplitManufacturerAction';
import { AddItemAction } from './actions/AddItemAction';
import { AddSplitAction } from './actions/AddSplitAction';
import { MoveItemAction } from './actions/MoveItemAction';
import { useSplitsAction } from './state/Splits.actions';
import { useSplitsSelector } from './state/Splits.context';
import { orderSplitMutationsDisabled } from './state/Splits.state';
import type { SplitInfo, SplitItemInfo, SplitsDelta } from './state/Splits.types';
import type { LabsGqlLabOrderFragment, LabsGqlOrganizationDtoFragment } from '@orthly/graphql-operations';
import { useOrders, useApplySplitDeltasMutation } from '@orthly/graphql-react';
import { OrderItemV2InputUtils } from '@orthly/graphql-schema';
import type { LabsGqlLabOrderSplitDeltaInput, LabsGqlLabOrderUpdateSplitsInput } from '@orthly/graphql-schema';
import { CartItemV2Utils } from '@orthly/items';
import { DateUtils } from '@orthly/shared-types';
import { LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    stylesFactory,
    Text,
    Box,
    Grid,
    IconButton,
    Tooltip,
    Icon,
    UndoIcon,
} from '@orthly/ui-primitives';
import { OrderDetailBlock } from '@orthly/veneer';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const useStyles = stylesFactory(() => ({
    statusIndicator: {
        backgroundColor: FlossPalette.STAR_GRASS,
        color: FlossPalette.TAN,
        fontWeight: 800,
        padding: 5,
        borderRadius: 3,
        display: 'block',
        position: 'absolute',
        top: 0,
        right: 0,
        fontSize: '12px !important',
    },
    lineItemSummary: {
        width: '100%',
        position: 'relative',
        margin: 5,
        padding: 10,
        borderRadius: 5,
        backgroundColor: FlossPalette.WHITE,
        boxShadow: `0px 2px 2px ${FlossPalette.DARK_TAN}`,
    },
    footnote: {
        fontSize: '0.7em',
        color: FlossPalette.GRAY,
    },
    detailBlockTitle: {
        height: 'auto',
        margin: '16px 0 32px 8px',
    },
}));

export type SplitManagerProps = {
    currentOrderId: string;
    partnerOrderId: string;
};

const NamedField: React.FC<{ name: string; value: string }> = props => {
    return (
        <>
            <b>{props.name}: </b> {props.value}
        </>
    );
};

type LineItemSummaryProps = {
    parentSplit: SplitInfo;
    itemInfo: SplitItemInfo;
};

const LineItemSummary: React.VFC<LineItemSummaryProps> = ({ parentSplit, itemInfo }) => {
    const { item } = itemInfo;
    const classes = useStyles();
    const splits = useSplitsSelector(s => (s.open ? s.splits : []));
    const applyDelta = useSplitsAction('APPLY_DELTA');

    return (
        <Box display={'flex'} alignItems={'center'} className={classes.lineItemSummary}>
            {itemInfo.isNew && (
                <Text variant={'body1'} className={classes.statusIndicator}>
                    New item
                </Text>
            )}
            <Box flexGrow={1}>
                <Text variant={'caption'}>
                    <NamedField name={'Name'} value={CartItemV2Utils.getFullDisplayName(item)} />
                    <br />
                    <NamedField name={'Material'} value={CartItemV2Utils.getItemDisplayMaterial(item) ?? 'N/A'} />
                </Text>
                <br />
                <small className={classes.footnote}>
                    <NamedField name={'ID'} value={itemInfo.id} />
                </small>
            </Box>
            <Box>
                <MoveItemAction
                    disabled={orderSplitMutationsDisabled(parentSplit)}
                    sourceSplitId={parentSplit.id}
                    splits={splits}
                    onSubmit={params => {
                        applyDelta({
                            action: 'move_item',
                            destOrderId: params.destSplitId,
                            itemId: itemInfo.id,
                            sourceOrderId: parentSplit.id,
                        });
                    }}
                />
            </Box>
        </Box>
    );
};

export type SplitTitleProps = {
    split: SplitInfo;
    manufacturers: readonly LabsGqlOrganizationDtoFragment[];
    applyDelta: (delta: SplitsDelta) => void;
};

const SplitTitle: React.FC<SplitTitleProps> = props => {
    const classes = useStyles();
    const { split, manufacturers, applyDelta } = props;
    return (
        <>
            <NamedField
                name={'Lab'}
                value={manufacturers.find(m => m.id === split.manufacturerId)?.name ?? 'Unknown Lab'}
            />
            <ChangeSplitManufacturerAction
                onSubmit={manufacturer_id => {
                    if (split.manufacturerId !== manufacturer_id) {
                        applyDelta({
                            action: 'change_manufacturer',
                            orderId: split.id,
                            manufacturerId: manufacturer_id,
                        });
                    }
                }}
                split={split}
                manufacturers={manufacturers}
            />
            <small className={classes.footnote}>
                <br />
                <NamedField name={'ID'} value={split.id} />
            </small>
        </>
    );
};

const doSubmitHelper = (
    applyDeltas: (arg: LabsGqlLabOrderUpdateSplitsInput) => Promise<void>,
    order: LabsGqlLabOrderFragment,
    deltas: readonly SplitsDelta[],
) => {
    const inputDeltas: LabsGqlLabOrderSplitDeltaInput[] = deltas.map<LabsGqlLabOrderSplitDeltaInput>(delta => {
        switch (delta.action) {
            case 'add_split':
                return {
                    add_split: {
                        order_id: delta.orderId,
                        manufacturer_id: delta.manufacturerId,
                    },
                };
            case 'move_item':
                return {
                    move_item: {
                        destination_order_id: delta.destOrderId,
                        source_order_id: delta.sourceOrderId,
                        item_id: delta.itemId,
                    },
                };
            case 'add_item': {
                const items_v2_by_sku = OrderItemV2InputUtils.getOrderItemV2InputBySKU([delta.itemInput]);
                return {
                    add_item: {
                        items_v2_by_sku,
                        item_id: delta.itemId,
                        order_id: delta.orderId,
                    },
                };
            }
            case 'change_manufacturer':
                return {
                    change_manufacturer: {
                        order_id: delta.orderId,
                        manufacturer_id: delta.manufacturerId,
                    },
                };
        }
    });

    return applyDeltas({ partner_order_id: order.partner_order_id, deltas: inputDeltas });
};

export const SplitManagerRoot: React.VFC = () => {
    const classes = useStyles();
    const closeManager = useSplitsAction('CLOSE_MANAGER');
    const applyDelta = useSplitsAction('APPLY_DELTA');
    const undoDelta = useSplitsAction('UNDO_DELTA');
    const resetDeltas = useSplitsAction('RESET_DELTAS');
    const { order, splits, deltas, manufacturers } = useSplitsSelector(s => s);
    const { refetch: refetchOrders } = useOrders(
        { filter: { partner_order_id: order?.partner_order_id } },
        { fetchPolicy: 'network-only', nextFetchPolicy: 'network-only' },
    );
    const [submitMtn] = useApplySplitDeltasMutation();
    const mtnSubmitter = (data: LabsGqlLabOrderUpdateSplitsInput) => {
        return submitMtn({ variables: { data } });
    };

    const changeSubFn = useChangeSubmissionFn<any, [LabsGqlLabOrderUpdateSplitsInput]>(mtnSubmitter, {
        successMessage: () => ['Splits updated!', {}],
        onSuccess: () => {
            void refetchOrders();
            closeManager();
        },
    });
    const { submitting: applyingDeltas, submit: applyDeltas } = changeSubFn;

    if (!order || !splits || !deltas || !manufacturers) {
        // This should be impossible.
        return null;
    }

    const doSubmit = () => doSubmitHelper(applyDeltas, order, deltas);

    const canSubmit = deltas.length > 0 && splits.every(s => s.items.length > 0);

    const colWidth = Math.min(Math.max(Math.floor(12 / splits.length), 3), 6) as 3 | 4 | 6;

    return (
        <LoadBlocker blocking={applyingDeltas}>
            <Grid container spacing={3} direction={'column'}>
                <Grid item>
                    <Box display={'flex'} alignItems={'center'}>
                        <Box flexShrink={1} flexBasis={'100%'}>
                            <Text variant={'body1'}>
                                Order for{' '}
                                <b>
                                    {order.patient.first_name} {order.patient.last_name}
                                </b>
                                , placed by <b>{order.doctor_name}</b> on {DateUtils.format(order.created_at)}
                            </Text>
                        </Box>
                        <Box>
                            <IconButton onClick={() => undoDelta()} disabled={deltas.length === 0}>
                                <Tooltip title={'Undo'}>
                                    <UndoIcon />
                                </Tooltip>
                            </IconButton>
                        </Box>
                        <Box>
                            <IconButton onClick={() => resetDeltas()} disabled={deltas.length === 0}>
                                <Tooltip title={'Reset'}>
                                    <Icon icon={'Restore'} />
                                </Tooltip>
                            </IconButton>
                        </Box>
                        <Box flexBasis={'10em'}>
                            <AddSplitAction
                                manufacturers={manufacturers}
                                onSubmit={data => {
                                    applyDelta({ action: 'add_split', orderId: uuidv4(), ...data });
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item container justifyContent={'center'} alignContent={'stretch'}>
                    {splits.map(s => (
                        <Grid item xs={colWidth} key={s.id} style={{ position: 'relative' }}>
                            <Text
                                variant={'body1'}
                                className={classes.statusIndicator}
                                style={{
                                    backgroundColor: s.isNew ? FlossPalette.STAR_GRASS : FlossPalette.DARK_TAN,
                                    color: s.isNew ? FlossPalette.TAN : FlossPalette.BLACK,
                                }}
                            >
                                {s.status}
                            </Text>
                            <OrderDetailBlock
                                classes={{ titleRow: classes.detailBlockTitle }}
                                rootStyle={{ height: '100%' }}
                                title={
                                    <SplitTitle split={s} manufacturers={manufacturers ?? []} applyDelta={applyDelta} />
                                }
                                variant={'full'}
                            >
                                <Grid item container>
                                    {s.items.map(itemInfo => (
                                        <LineItemSummary key={itemInfo.id} itemInfo={itemInfo} parentSplit={s} />
                                    ))}

                                    <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        className={classes.lineItemSummary}
                                    >
                                        <AddItemAction
                                            disabled={orderSplitMutationsDisabled(s)}
                                            doctorPreferencesId={order.doctor_preferences_id}
                                            onSubmit={item => {
                                                applyDelta({
                                                    action: 'add_item',
                                                    orderId: s.id,
                                                    itemId: uuidv4(),
                                                    itemInput: item,
                                                });
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </OrderDetailBlock>
                        </Grid>
                    ))}
                </Grid>
                <Grid item container justifyContent={'flex-end'} alignItems={'center'} spacing={2}>
                    {!canSubmit && (
                        <Grid item>
                            <Text variant={'caption'} style={{ color: FlossPalette.ATTENTION }}>
                                {deltas.length === 0
                                    ? 'No changes to submit'
                                    : 'All splits must have at least one item'}
                            </Text>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            variant={'primary'}
                            onClick={() => void doSubmit()}
                            disabled={!canSubmit}
                            title={'Submit changes'}
                        >
                            Apply Changes
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </LoadBlocker>
    );
};
