import { useBulkSetWillChargeCCFeeContext } from './providers/BulkSetWillChargeCCFeeProvider.graphql';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useRootActionCommand } from '@orthly/ui';
import { Button, FlossPalette, Icon, Text, styled } from '@orthly/ui-primitives';
import { values } from 'lodash';
import React from 'react';

const BulkToggleWillBeChargedCCFee_Mutation = graphql(`
    mutation BulkToggleWillBeChargedCCFee($data: BulkToggleWillBeChargedCCFeeCommand!) {
        bulkToggleWillBeChargedCCFee(data: $data) {
            errors {
                partner_id
                error
            }
            results {
                id
            }
        }
    }
`);

const FixedContainer = styled('div')({
    position: 'fixed',
    bottom: '48px',
    height: '40px',
    backgroundColor: 'white',
    borderRadius: '8px',
    outline: `1px solid ${FlossPalette.STROKE_LIGHT}`,
});

const CountText = styled(Text)({
    display: 'inline-block',
    height: '40px',
    padding: '8px 16px',
    lineHeight: '20px',
});

const BorderedButton = styled('div')({
    display: 'inline-block',
    borderLeft: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    height: '40px',
});

const StyledButton = styled(Button)({
    height: '40px',
    padding: '8px 16px',
    borderRadius: 0,
});
const StyledLastButton = styled(Button)({
    height: '40px',
    padding: '8px 16px',
    borderRadius: '0px 8px 8px 0px',
});

const StyledIcon = styled(Icon)({
    marginLeft: '8px',
});

export const BulkSetWillChargeCCFeeControls: React.FC = () => {
    const { inputRows, bulkEditRows, handleSubmit } = useBulkSetWillChargeCCFeeContext();

    const toggleWillBeChargedCCFee = useMutation(BulkToggleWillBeChargedCCFee_Mutation);
    const { submit, submitting } = useRootActionCommand(toggleWillBeChargedCCFee, {
        successMessage: result => {
            if (result.data.bulkToggleWillBeChargedCCFee.results.length) {
                return ['Practice settings successfully updated', {}];
            } else {
                return [undefined, {}];
            }
        },
        onSuccess: result => {
            handleSubmit(result.data);
        },
    });

    const mapInputToSubmissionRow = () => {
        return values(inputRows).map(row => ({
            partner_id: row.id,
            will_be_charged_cc_fee: row.will_be_charged_cc_fee,
        }));
    };

    if (!values(inputRows).length) {
        return null;
    }
    return (
        <FixedContainer>
            <CountText variant={'body2'} color={'PRIMARY_FOREGROUND'} medium>
                {values(inputRows).length} selected
            </CountText>
            <BorderedButton>
                <StyledButton
                    variant={'ghost'}
                    disabled={submitting}
                    onClick={() => submit({ data: { rows: mapInputToSubmissionRow() } })}
                >
                    Submit
                </StyledButton>
            </BorderedButton>
            <BorderedButton>
                <StyledButton variant={'ghost'} disabled={submitting} onClick={() => bulkEditRows(true)}>
                    Enable card processing for selected <StyledIcon icon={'CheckIcon'} />
                </StyledButton>
            </BorderedButton>
            <BorderedButton>
                <StyledLastButton variant={'ghost'} disabled={submitting} onClick={() => bulkEditRows(false)}>
                    Disable card processing for selected <StyledIcon icon={'RemoveIcon'} />
                </StyledLastButton>
            </BorderedButton>
        </FixedContainer>
    );
};
