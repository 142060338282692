import type { TextFieldProps } from '@orthly/ui-primitives';
import { TextField } from '@orthly/ui-primitives';
import React from 'react';

type FieldChangeType = 'blur' | 'focus';

interface CheckoutTextFieldProps {
    label: string;
    value: string | undefined;
    onChange: (value: string, type?: FieldChangeType) => void;
    onBlurOrFocus?: (type: FieldChangeType) => void;
    required: boolean;
    disableErrorMessage?: boolean;
    size?: TextFieldProps['size'];
    helperText?: string | null;
    TextFieldProps?: Partial<
        Omit<TextFieldProps, 'value' | 'label' | 'required' | 'size' | 'onChange' | 'onBlur' | 'onFocus' | 'error'>
    >;
    error?: string | null;
}

// Simple wrapper around text field that prevents dispatching an event on every change
export const CheckoutTextField: React.FC<CheckoutTextFieldProps> = props => {
    const onBlurOrFocus = (type: FieldChangeType) => (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = (e.target?.value || '').trim();
        props.onBlurOrFocus && props.onBlurOrFocus(type);
        if (newValue !== props.value) {
            props.onChange(newValue, type);
        }
    };
    const onErrorStateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = (e.target?.value || '').trim();
        if (!!newValue !== !!props.value) {
            props.onChange(newValue);
        }
    };
    const showError = !!props.error || (!props.disableErrorMessage && !props.value && props.required);

    return (
        <TextField
            fullWidth
            required={props.required}
            label={props.label}
            variant={'standard'}
            defaultValue={props.value}
            error={showError}
            helperText={props.error ?? (showError ? `Please enter ${props.label}` : props.helperText)}
            size={props.size}
            {...props.TextFieldProps}
            FormHelperTextProps={{
                ...props.TextFieldProps?.FormHelperTextProps,
                style: { textTransform: 'none', ...props.TextFieldProps?.FormHelperTextProps?.style },
            }}
            style={{ fontWeight: 500, ...props.TextFieldProps?.style }}
            onBlur={onBlurOrFocus('blur')}
            onFocus={onBlurOrFocus('focus')}
            onChange={onErrorStateChange}
        />
    );
};
