import { useFirebasePreviewMulti } from '../../hooks';
import { DandyLightbox } from '../DandyLightbox';
import type { DesignQcSidebarEntry } from './DesignQC.types';
import { useGuidedQcAnalytics } from './DesignQcCommon';
import { DesignQcMarkSubcategoryGoodSidebar } from './DesignQcMarkSubcategoryGoodSidebar';
import { useDesignQcAction } from './state/DesignQc.actions';
import { useDesignQcPropSelector } from './state/DesignQc.context';
import { getAllUnns, getQcRubricEntryResult } from './state/DesignQc.selectors';
import { useRegisterHotKeys } from '@orthly/dentin';
import { SidebarContainer, ToolbarContainer, TrashIcon, SimpleMultiSelect, SimpleTextField } from '@orthly/ui';
import { FlossPalette, stylesFactory, Text, Button, Grid, Tab, Tabs } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    root: {
        padding: 0,
        flexWrap: 'nowrap',
        maxHeight: '100vh',
        '&::-webkit-scrollbar': { display: 'none' },
        width: 336,
    },
    toolbar: { alignItems: 'center', alignContent: 'center' },
    contentRoot: {
        padding: 16,
        height: '100vh',
        overflow: 'auto',
        alignContent: 'flex-start',
        position: 'relative',
    },
    icon: {
        color: FlossPalette.DARK_TAN,
        position: 'absolute',
        top: 20,
        left: 4,
        cursor: 'grab',
        [theme.breakpoints.down('lg')]: { top: 16 },
    },
    barLeft: { position: 'absolute', left: -4, width: 10, bottom: 0, top: 0, cursor: 'ew-resize', zIndex: 1100 },
    overlay: { width: '100vw', position: 'fixed', left: 0, bottom: 0, top: 0, right: 0, zIndex: 1200 },
    container: { display: 'flex', position: 'relative', flex: 'none' },
    photo: {
        width: 272,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        borderRadius: 8,
    },
    photoWrapper: {
        marginTop: 8,
        cursor: 'pointer',
        position: 'relative',
    },
    deleteIcon: {
        height: 24,
        width: 24,
        margin: 4,
    },
    deleteIconWrapper: {
        position: 'absolute',
        top: 8,
        left: 8,
        height: 32,
        width: 32,
        cursor: 'pointer',
        backgroundColor: `rgba(0, 0, 0, 0.5)`,
        color: FlossPalette.ATTENTION_BACKGROUND,
        borderRadius: 32,
    },
}));

const DesignQcMarkSubcategoryBadSidebar: React.VFC = () => {
    const classes = useStyles();

    const { rubricNavigation, pendingMarkupUrls, order, results } = useDesignQcPropSelector([
        'rubricNavigation',
        'pendingMarkupUrls',
        'order',
        'results',
    ]);

    const [selectedUnns, setSelectedUnns] = React.useState<number[]>(getAllUnns({ order }));
    const [selectedReasons, setSelectedReasons] = React.useState<string[]>([]);
    const [note, setNote] = React.useState('');

    // We do this in a useEffect rather than in the initial state to avoid potentially
    // not setting the state correctly if the user navigates between different entries.
    React.useEffect(() => {
        const storedResult = rubricNavigation?.entry
            ? getQcRubricEntryResult({ results }, rubricNavigation.entry)?.result
            : undefined;
        setSelectedReasons(storedResult?.type === 'bad' ? storedResult.reasons : []);
    }, [rubricNavigation, results]);

    const markBadAction = useDesignQcAction('MARK_SUBCATEGORY_BAD');
    const deleteMarkupAction = useDesignQcAction('DELETE_PENDING_MARKUP_URL');

    const entry = rubricNavigation?.entry;

    // Loads firebase URLs since we currently just have Firebase paths in the attachments list.
    const { result: previews, loading } = useFirebasePreviewMulti(
        pendingMarkupUrls.map(url => ({ source: url, name: url })),
        true,
    );
    const [selectedAttachmentPreview, setSelectedAttachmentPreview] = React.useState<string | undefined>(undefined);

    const trackSubcategoryScored = useGuidedQcAnalytics('All - Portal - Guided QC - Subcategory Scored');
    const trackMarkupDeleted = useGuidedQcAnalytics('All - Portal - Guided QC - Markup Photo Deleted');

    const markBad = React.useCallback(() => {
        if (!selectedReasons) {
            return;
        }

        trackSubcategoryScored({
            unns: selectedUnns,
            verdict: 'bad',
            reasons: selectedReasons,
            markupPaths: pendingMarkupUrls,
        });
        markBadAction({ reasons: selectedReasons, affectedUnns: selectedUnns, note, currentTime: Date.now() });
    }, [selectedReasons, markBadAction, selectedUnns, note, trackSubcategoryScored, pendingMarkupUrls]);

    useRegisterHotKeys({
        key: 'Shift+Enter',
        description: 'Submit Reason',
        category: 'Guided QC',
        action: markBad,
        disabled: !selectedReasons?.length,
    });

    // Should never happen, but for type safety
    if (!entry) {
        return null;
    }

    return (
        <Grid
            item
            container
            direction={'column'}
            style={{
                width: 336,
                overflow: 'auto',
                borderLeft: `1px solid ${FlossPalette.STROKE_LIGHT}`,
                padding: `48px 24px`,
            }}
        >
            <Grid item spacing={1}>
                <Text variant={'h4'} medium>
                    Mark subcategory as bad
                </Text>
            </Grid>
            <Grid item style={{ marginTop: 12 }}>
                <Text variant={'body2'} color={'GRAY'}>
                    Select a reason why this subcategory is bad: {entry.name}
                </Text>
            </Grid>
            <Grid item style={{ marginTop: 24, width: 288, maxWidth: 288 }}>
                <Text variant={'body2'} color={'GRAY'}>
                    Select reason(s)
                </Text>
                <SimpleMultiSelect
                    options={entry.selectable_bad_reasons.map(r => ({ label: r, value: r }))}
                    label={'Select reason(s)'}
                    value={selectedReasons}
                    onChange={value => setSelectedReasons(value ?? [])}
                />
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
                <Text variant={'body2'} color={'GRAY'}>
                    Select tooth numbers
                </Text>
                <SimpleMultiSelect
                    SelectProps={{
                        SelectDisplayProps: { style: { whiteSpace: 'break-spaces' } },
                    }}
                    options={_.range(1, 33).map(unn => ({ label: `Tooth\u00A0${unn}`, value: `${unn}` }))}
                    label={'Select tooth numbers'}
                    onChange={values => setSelectedUnns(values?.map(unn => parseInt(unn, 10)) ?? [])}
                    value={selectedUnns.map(unn => `${unn}`)}
                />
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
                <Text variant={'body2'} color={'GRAY'}>
                    Note (optional)
                </Text>
                <SimpleTextField
                    TextFieldProps={{ multiline: true }}
                    label={'Add a note'}
                    value={note}
                    onChange={value => setNote(value)}
                />
            </Grid>
            <Grid item style={{ marginTop: 16 }}>
                <Text variant={'body1'} medium color={'BLACK'}>
                    Markups ({pendingMarkupUrls.length})
                </Text>
            </Grid>
            <Grid item xs style={{ overflowY: 'auto' }}>
                {selectedAttachmentPreview && (
                    <DandyLightbox
                        setSelectedAttachmentPreview={setSelectedAttachmentPreview}
                        loading={loading}
                        previews={previews}
                        selectedAttachmentPreview={selectedAttachmentPreview}
                        onPhotoViewedAnalytics={(source, name) => {
                            return {
                                name: 'All - Portal - Photo Lightbox Viewed',
                                data: {
                                    $groups: { order: order.id },
                                    displayLocation: 'guided_design_qc',
                                    photoSource: source,
                                    photoName: name,
                                },
                            };
                        }}
                    />
                )}
                {previews?.map((preview, idx) => (
                    <div className={classes.photoWrapper} key={preview.name}>
                        <img
                            className={classes.photo}
                            src={preview.source}
                            onClick={() => setSelectedAttachmentPreview(preview.name)}
                        />
                        <div className={classes.deleteIconWrapper}>
                            <TrashIcon
                                className={classes.deleteIcon}
                                onClick={() => {
                                    trackMarkupDeleted({
                                        photoSource: pendingMarkupUrls[idx] ?? '',
                                    });
                                    deleteMarkupAction({ url: pendingMarkupUrls[idx] ?? '' });
                                }}
                            />
                        </div>
                    </div>
                ))}
            </Grid>
            <Grid item style={{ marginTop: 8 }}>
                <Button
                    variant={'primary'}
                    disabled={!selectedReasons.length || !selectedUnns.length}
                    onClick={markBad}
                    fullWidth
                >
                    Submit Reason (Shift+Enter)
                </Button>
            </Grid>
        </Grid>
    );
};

interface DesignQcRightSidebarProps {
    sidebarEntries: DesignQcSidebarEntry[];
}

export const DesignQcRightSidebar: React.VFC<DesignQcRightSidebarProps> = ({ sidebarEntries }) => {
    const { isBadSubcategoryWorkflowEnabled, isAcceptableSubcategoryWorkflowEnabled } = useDesignQcPropSelector([
        'isBadSubcategoryWorkflowEnabled',
        'isAcceptableSubcategoryWorkflowEnabled',
    ]);
    const classes = useStyles();
    const [tab, setTab] = React.useState<number>(0);
    const tabBody = sidebarEntries[tab]?.sidebarBody;

    if (isBadSubcategoryWorkflowEnabled) {
        return <DesignQcMarkSubcategoryBadSidebar />;
    } else if (isAcceptableSubcategoryWorkflowEnabled) {
        return <DesignQcMarkSubcategoryGoodSidebar />;
    }

    return (
        <>
            <SidebarContainer position={'right'} className={classes.root}>
                <ToolbarContainer className={classes.toolbar}>
                    <Tabs
                        indicatorColor={'secondary'}
                        textColor={'inherit'}
                        value={tab}
                        onChange={(_, value) => setTab(value)}
                    >
                        {sidebarEntries.map((entry, idx) => (
                            <Tab key={entry.name} value={idx} label={entry.name} />
                        ))}
                    </Tabs>
                </ToolbarContainer>
                <Grid container className={classes.contentRoot}>
                    {tabBody}
                </Grid>
            </SidebarContainer>
        </>
    );
};
