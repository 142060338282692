import type { ListRefundCategoriesQuery } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';

export const ListRefundCategories_Query = graphql(`
    query ListRefundCategories($includeArchived: Boolean!) {
        listRefundCategories(includeArchived: $includeArchived) {
            id
            updated_at
            created_at
            name
            archived
        }
    }
`);

export type RefundCategories = ListRefundCategoriesQuery['listRefundCategories'];
