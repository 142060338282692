import { LabsUtilsBase } from '@orthly/dentin';
import type {
    LabsGqlSplitPaymentConfig_PaymentSplitConfigDoctor_Fragment as DoctorSplitConfig,
    LabsGqlPartnerBillingChangelogEntryFragment,
    LabsGqlSplitPaymentConfig_PaymentSplitConfigLocation_Fragment as LocationSplitConfig,
    LabsGqlPrepaySplitConfigFragment as PercentSplitConfig,
} from '@orthly/graphql-operations';
import { useFetchPartnerBillingChangelogQuery } from '@orthly/graphql-react';
import { MUITable } from '@orthly/mui-table';
import { LoadBlocker, RootActionDialog } from '@orthly/ui';
import { FlossPalette, Icon, IconButton } from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

type PartnerBillingChangelogViewerProps = {
    partner_id: string;
    close?: () => void;
};

type ChangelogTableElement = LabsGqlPartnerBillingChangelogEntryFragment & { id: string };

function renderEventTimestamp(change: ChangelogTableElement) {
    return moment(change.event_timestamp).format(`MM/DD/YY, h:mm a`);
}

function stringify(value: any) {
    if (value === null || value === undefined) {
        return '(None)';
    }
    if (typeof value === 'string') {
        return value;
    }
    if (typeof value === 'number') {
        return LabsUtilsBase.amountCentsToDollarInput(value);
    }
    try {
        return JSON.stringify(value, null, 2);
    } catch (e: any) {
        console.error(e);
        return '(Unknown)';
    }
}

type SplitConfig = PercentSplitConfig | DoctorSplitConfig | LocationSplitConfig;

function unTypenameSplitConfig<E extends SplitConfig>(splitConfig: E | null): Omit<E, '__typename'> | null {
    if (!splitConfig) {
        return splitConfig;
    }
    return _.omit({ ...splitConfig, splits: unTypenameObjects<E['splits'][number]>(splitConfig.splits) }, '__typename');
}

function unTypenameObjects<T extends { __typename?: string }>(objects: T[] | null): Omit<T, '__typename'>[] | null {
    if (!objects || objects.length === 0) {
        return objects;
    }
    return objects.map(o => _.omit(o, '__typename'));
}

function renderOldValue(change: ChangelogTableElement): string {
    switch (change.__typename) {
        case 'BillingUsageSplitChangelogEntry':
            return stringify(unTypenameSplitConfig(change.old_usage_split_value));
        case 'PartnerBillingContactsChangelogEntry':
            return stringify(change.old_contacts_value);
        case 'PartnerBillingAutochargeChangelogEntry':
            return stringify(`${change.old_autocharge_value}`);
        case 'PartnerBillingPrimaryBillingContactUserIdChangeLogEntry':
            return stringify(change.old_primary_billing_contact_user_id_value);
        case 'PartnerBillingPrimaryBillingContactUserTypeChangeLogEntry':
            return stringify(change.old_primary_billing_contact_user_type_value);
        case 'PartnerStripeLinkingChangelogEntry':
            return stringify(change.old_stripe_id);
        case 'AgreeToCreditCardFeesChangelogEntry':
            return stringify(change.old_agreed_to_cc_fees);
        case 'PartnerBillingWillBeChargedCCFeeChangelogEntry':
            return stringify(change.old_will_be_charged_cc_fee);
        default:
            return 'Unknown';
    }
}

function renderNewValue(change: ChangelogTableElement): string {
    switch (change.__typename) {
        case 'BillingUsageSplitChangelogEntry':
            return stringify(unTypenameSplitConfig(change.new_usage_split_value));
        case 'PartnerBillingContactsChangelogEntry':
            return stringify(change.new_contacts_value);
        case 'PartnerBillingAutochargeChangelogEntry':
            return stringify(`${change.new_autocharge_value}`);
        case 'PartnerBillingPrimaryBillingContactUserIdChangeLogEntry':
            return stringify(change.new_primary_billing_contact_user_id_value);
        case 'PartnerBillingPrimaryBillingContactUserTypeChangeLogEntry':
            return stringify(change.new_primary_billing_contact_user_type_value);
        case 'PartnerStripeLinkingChangelogEntry':
            return stringify(change.new_stripe_id);
        case 'AgreeToCreditCardFeesChangelogEntry':
            return stringify(change.new_agreed_to_cc_fees);
        case 'PartnerBillingWillBeChargedCCFeeChangelogEntry':
            return stringify(change.new_will_be_charged_cc_fee);
        default:
            return 'Unknown';
    }
}

function renderActor(change: ChangelogTableElement) {
    if (['user', 'staff'].includes(change.actor_role)) {
        return change.actor_name ?? change.actor_id;
    }
    return change.actor_id;
}

const PartnerBillingChangelogViewer: React.FC<PartnerBillingChangelogViewerProps> = props => {
    const { partner_id } = props;
    const { data, loading } = useFetchPartnerBillingChangelogQuery({ variables: { partner_id } });
    const changelog = data?.fetchPartnerBillingChangelog ?? [];
    return (
        <LoadBlocker blocking={loading}>
            <MUITable<ChangelogTableElement>
                title={'Billing Configuration Changelog'}
                columns={[
                    { name: 'Timestamp', render: renderEventTimestamp },
                    { name: 'Event', render: 'event_name' },
                    { name: 'Actor Type', render: 'actor_role' },
                    { name: 'Actor', render: renderActor },
                    { name: 'Old Value', render: renderOldValue },
                    { name: 'New Value', render: renderNewValue },
                ]}
                data={(changelog ?? []).map((entry, index) => ({ ...entry, id: `${partner_id}-${index}` }))}
                paginationOptions={{ rowsPerPageOptions: [50], initialRowsPerPage: 50 }}
                displayOptions={{ fixedSearch: true, viewColumns: true }}
            />
        </LoadBlocker>
    );
};

export const PartnerBillingChangelogViewerDialog: React.FC<{ partner_id: string }> = ({ partner_id }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const CustomButton = React.useCallback(
        () => (
            <IconButton onClick={() => setOpen(true)}>
                <Icon icon={'History'} sx={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
            </IconButton>
        ),
        [],
    );
    return (
        <RootActionDialog
            showCloseButton
            loading={false}
            open={open}
            setOpen={setOpen}
            title={''}
            dialogProps={{ maxWidth: 'xl' }}
            titleProps={{ style: { display: 'none' } }}
            content={
                !open ? null : <PartnerBillingChangelogViewer partner_id={partner_id} close={() => setOpen(false)} />
            }
            buttonText={''}
            CustomButton={CustomButton}
        />
    );
};
