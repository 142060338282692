import { objectEntries, dayjsExt as dayjs } from '@orthly/runtime-utils';
import { Text, FlossPalette, Icon } from '@orthly/ui-primitives';
import startCase from 'lodash/startCase';
import moment from 'moment';
import React from 'react';

interface SlaPreviewRowProps {
    loading: boolean;
    label: string;
    existingDate?: string;
    updatedDate?: string;
    formatValue: (value: string) => string;
}

interface SlaFactRowProps {
    loading: boolean;
    label: string;
    date: string;
}

const SlaFactRow: React.FC<SlaFactRowProps> = ({ loading, label, date }) => {
    const value = moment(date).format('MMM D, YYYY');
    return (
        <tr>
            <th>
                <Text variant={'body2'} style={{ fontWeight: 'bold' }}>
                    {label}:
                </Text>
            </th>
            <td>
                <Text
                    variant={'body2'}
                    style={{
                        color: loading ? FlossPalette.GRAY : FlossPalette.BLACK,
                    }}
                >
                    {loading ? 'Loading ...' : value}
                </Text>
            </td>
        </tr>
    );
};

const SlaPreviewRow: React.FC<SlaPreviewRowProps> = ({ loading, label, existingDate, updatedDate, formatValue }) => {
    const existingValue = existingDate ? formatValue(existingDate) : null;
    const updatedValue = updatedDate ? formatValue(updatedDate) : '';
    const isValueChanged = !loading && updatedValue !== existingValue;

    return (
        <tr>
            <th>
                <Text variant={'body2'} style={{ fontWeight: 'bold' }}>
                    {label}:
                </Text>
            </th>
            {updatedValue || loading ? (
                <>
                    <td>
                        <Text
                            variant={'body2'}
                            style={
                                isValueChanged
                                    ? {
                                          color: FlossPalette.GRAY,
                                          textDecoration: 'line-through',
                                      }
                                    : {
                                          color: loading ? FlossPalette.GRAY : FlossPalette.BLACK,
                                      }
                            }
                        >
                            {loading ? 'Loading ...' : existingValue ?? '(no value)'}
                        </Text>
                    </td>
                    <td>
                        <Icon icon={'ArrowRightAltIcon'} />
                    </td>
                    <td>
                        <Text
                            variant={'body2'}
                            style={{
                                color: isValueChanged ? FlossPalette.BLACK : FlossPalette.GRAY,
                            }}
                        >
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {loading ? 'Loading...' : isValueChanged ? updatedValue : 'No changes'}
                        </Text>
                    </td>
                </>
            ) : (
                <td>Error: no value found</td>
            )}
        </tr>
    );
};

export type SlaPreviewLabelOverrides = Partial<{
    [K: string]: string;
}>;

export type SlaPreviewTableData = Partial<{
    [K: string]:
        | string
        | { existing?: string | null; updated?: string | null; skipFormatting?: boolean; hide?: boolean };
}>;
export interface SlaPreviewTableProps {
    loading?: boolean;
    preview: SlaPreviewTableData;
    labelOverrides?: SlaPreviewLabelOverrides;
}

const labelsForSlaPreviewProperties: {
    [K: string]: string;
} = {
    manufacturer_committed_ship_by_date: 'Manufacturer committed to ship',
    manufacturer_committed_due_date: 'Manufacturer committed delivery',
    internal_committed_due_date: 'Internally committed delivery',
    start_date: 'Fabrication start date',
    ready_for_fabrication_date: 'Ready for fabrication date',
    ship_by_date: 'Ship by date',
    due_date: 'Due date',
    shipping_days: 'Shipping days',
    fabrication_days: 'Fabrication days',
    due_date_buffered: 'Due Date Buffered',
};

function dateFormatter(dateStr: string): string {
    const date = dayjs(dateStr);
    if (!date.isValid()) {
        return dateStr;
    }
    if (date.minute() === 0) {
        // standard date
        return date.format('ddd M/D/YY, hA');
    }
    return date.format('ddd M/D/YY, h:mm A');
}

export const SlaPreviewTable: React.FC<SlaPreviewTableProps> = ({ loading, preview, labelOverrides }) => {
    return (
        <div style={{ width: '100%' }}>
            <table style={{ width: '100%', textAlign: 'left' }}>
                <tbody>
                    {objectEntries(preview).map(([property, value]) => {
                        const label =
                            labelOverrides?.[property] ??
                            labelsForSlaPreviewProperties[property] ??
                            startCase(property);
                        if (typeof value === 'object' && value?.hide) {
                            return null;
                        }
                        return typeof value !== 'string' ? (
                            <SlaPreviewRow
                                loading={!!loading}
                                key={property}
                                label={label}
                                existingDate={value?.existing ?? undefined}
                                updatedDate={value?.updated ?? undefined}
                                formatValue={!value || value.skipFormatting ? (val: string) => val : dateFormatter}
                            />
                        ) : (
                            <SlaFactRow key={property} loading={!!loading} label={label} date={value} />
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
