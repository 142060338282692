import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { GetPatientSharesByOrgIdQueryQuery } from '@orthly/graphql-inline-react';
import MUITable from '@orthly/mui-table';
import { PhoneNumberUtils } from '@orthly/runtime-utils';
import { Button } from '@orthly/ui-primitives';
import { ShareScansWithPatientModal } from '@orthly/veneer';
import dayjs from 'dayjs';
import React from 'react';

const GetPatientSharesByOrgIdQuery = graphql(`
    query GetPatientSharesByOrgIdQuery($organization_id: String!) {
        getPatientSharesByOrgId(organization_id: $organization_id) {
            id
            share_date
            share_type
            patient_first_name
            patient_last_name
            patient_id
            staff_member_id
            scan_export_id
            message
            annotation_urls
            patient_email
            patient_mobile_phone
        }
    }
`);

interface OrganizationPatientSharesTableProps {
    organizationId: string;
}

interface OrganizationPatientSharesTableRow {
    id: string;
    share_date: string;
    share_type: string;
    patient_first_name: string;
    patient_last_name: string;
    patient_email: string;
    patient_mobile_phone: string;
    patient_id: string;
    staff_member_id: string;
    scan_export_id: string;
    annotation_urls: string[] | null;
    message: string | null;
}

function organizationPatientSharesTableRows(data: any): OrganizationPatientSharesTableRow[] {
    return data.map((d: OrganizationPatientSharesTableRow) => {
        return {
            share_date: d.share_date,
            share_type: d.share_type,
            patient_first_name: d.patient_first_name,
            patient_last_name: d.patient_last_name,
            patient_email: d.patient_email,
            patient_mobile_phone: d.patient_mobile_phone,
            patient_id: d.patient_id,
            staff_member_id: d.staff_member_id,
            scan_export_id: d.scan_export_id,
            annotation_urls: d.annotation_urls,
            message: d.message,
        };
    });
}

export const OrganizationPatientSharesTable: React.FC<OrganizationPatientSharesTableProps> = ({ organizationId }) => {
    const [open, setOpen] = React.useState(false);
    const [patientId, setPatientId] = React.useState<string | null>(null);
    const [staffMemberId, setStaffMemberId] = React.useState<string | null>(null);
    const [patientEmail, setPatientEmail] = React.useState<string | null>(null);
    const [patientPhoneNumber, setPatientPhoneNumber] = React.useState<string | null>(null);
    const [scanExportId, setScanExportId] = React.useState<string | null>(null);
    const [annotationUrls, setAnnotationUrls] = React.useState<string[]>([]);
    const [message, setMessage] = React.useState<string | null>(null);

    const { data, loading, refetch } = useQuery<GetPatientSharesByOrgIdQueryQuery>(GetPatientSharesByOrgIdQuery, {
        variables: {
            organization_id: organizationId,
        },
    });
    return (
        <>
            <MUITable<OrganizationPatientSharesTableRow>
                title={'Patient Shares'}
                loading={loading}
                columns={[
                    { name: 'id', render: row => row.id, hidden: true },
                    {
                        name: 'share_date',
                        render: row => dayjs(row.share_date).format('MMMM D, YYYY'),
                        hidden: false,
                        defaultSort: 'desc',
                    },
                    { name: 'share_type', render: row => row.share_type, hidden: false },
                    { name: 'patient_email', render: row => row.patient_email, hidden: false },
                    {
                        name: 'patient_mobile_phone',
                        render: row =>
                            row.patient_mobile_phone && PhoneNumberUtils.prettyPhoneNumber(row.patient_mobile_phone),
                        hidden: false,
                    },
                    { name: 'patient_first_name', render: row => row.patient_first_name, hidden: false },
                    { name: 'patient_last_name', render: row => row.patient_last_name, hidden: false },
                    {
                        name: 'Share',
                        render: row => {
                            return (
                                <Button
                                    variant={'secondary'}
                                    fullWidth
                                    onClick={() => {
                                        setPatientId(row.patient_id);
                                        setStaffMemberId(row.staff_member_id);
                                        setScanExportId(row.scan_export_id);
                                        setAnnotationUrls(row.annotation_urls ?? []);
                                        setPatientEmail(row.patient_email);
                                        setPatientPhoneNumber(row.patient_mobile_phone);
                                        setMessage(row.message);
                                        setOpen(true);
                                    }}
                                >
                                    Re-share
                                </Button>
                            );
                        },
                    },
                ]}
                data={organizationPatientSharesTableRows(data?.getPatientSharesByOrgId ?? [])}
            />
            {patientId && scanExportId && annotationUrls && staffMemberId && (
                <ShareScansWithPatientModal
                    open={open}
                    setOpen={setOpen}
                    scanExportId={scanExportId}
                    patientId={patientId}
                    staffMemberId={staffMemberId}
                    annotationUrls={annotationUrls}
                    organizationId={organizationId}
                    patientEmail={patientEmail}
                    patientPhoneNumber={patientPhoneNumber}
                    message={message}
                    onShareComplete={refetch}
                />
            )}
        </>
    );
};
