/* eslint-disable no-nested-ternary */
import { useUrlTicketFilterParams } from '../../utils/router/useUrlTicketFilterParams';
import { useTicketFilterDropdownData } from '../../utils/useTicketActionHooks';
import { NoTickets } from './components/NoTickets';
import { Pagination } from './components/Pagination';
import { TicketFiltersInput } from './components/TicketFiltersInput';
import { TicketSavedSearches } from './components/TicketSavedSearches';
import { TicketTable } from './components/TicketTable';
import { useFilteredTicketsQuery } from '@orthly/graphql-react';
import { LoadBlocker, useDebouncedValue } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

const loadingScreenContainer: React.CSSProperties = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
};

export const TicketsRoot: React.FC = () => {
    const { filters, pagination, setFiltersAndPagination, error } = useUrlTicketFilterParams();
    const dropdownData = useTicketFilterDropdownData();

    const debouncedFilters = useDebouncedValue(filters, 500);
    const filteredTickets = useFilteredTicketsQuery({
        variables: { input: { pagination, filters: _.omit(debouncedFilters, ['page', 'per_page']) } },
    });

    if (error) {
        return <div>Params Error: {JSON.stringify(error)}</div>;
    }

    const errors = _.compact([filteredTickets, dropdownData].map(x => x.error));
    const isLoading = [filteredTickets, dropdownData].some(x => x.loading);

    if (errors.length > 0) {
        return (
            <div>
                {errors.map((error, i) => (
                    <div key={i}>Error: {error.message}</div>
                ))}
            </div>
        );
    }
    return (
        <div style={{ display: 'flex', width: '100%' }}>
            <TicketSavedSearches
                isLoading={isLoading}
                refetch={async () => {
                    void filteredTickets.refetch();
                }}
                activeSearch={filters}
                setSearch={filters => setFiltersAndPagination({ ...pagination, ...filters, page: 1 })}
            />
            <div style={{ width: '100%', display: 'flex', flexFlow: 'column nowrap' }}>
                <TicketFiltersInput
                    choiceData={dropdownData.data}
                    filters={filters}
                    setFilters={filters => setFiltersAndPagination({ ...pagination, ...filters, page: 1 })}
                />
                {isLoading || !filteredTickets.data || !dropdownData.data ? (
                    <div style={loadingScreenContainer}>
                        <LoadBlocker loader={'dandy'} blocking={isLoading} />
                    </div>
                ) : filteredTickets.data.filteredTickets.length === 0 ? (
                    <NoTickets />
                ) : (
                    <TicketTable tickets={filteredTickets.data.filteredTickets} choiceData={dropdownData.data} />
                )}
                <Pagination
                    page={pagination.page}
                    setPage={page => setFiltersAndPagination({ ...filters, ...pagination, page })}
                />
            </div>
        </div>
    );
};
