import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { OrderDetailFactRow, ShippingSpeedToText } from '@orthly/veneer';
import React from 'react';

interface OrderDetailSummaryShippingSpeedProps {
    labOrder: LabsGqlLabOrderFragment;
}

export const OrderDetailSummaryShippingSpeed: React.VFC<OrderDetailSummaryShippingSpeedProps> = ({ labOrder }) => {
    const methodOverride = labOrder.fulfillment.current.shipping_method_override;
    const methodDefault = labOrder.shipping_method;

    const displayValue = methodOverride
        ? ShippingSpeedToText[methodOverride]
        : `Not set (Defaults to ${ShippingSpeedToText[methodDefault]})`;

    return <OrderDetailFactRow title={'Shipping'} value={displayValue} />;
};
