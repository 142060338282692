import { Text, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

const style = {
    background: FlossPalette.TAN,
    width: '100%',
    padding: '64px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export const NoTickets: React.FC = () => (
    <div style={style}>
        <Text variant={'body2'} color={'DARK_GRAY'} medium>
            No Tickets Match Filters
        </Text>
    </div>
);
