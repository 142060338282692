import { PRINTABLE_SLIP_NESTED_BORDER_COLOR } from '../../styles/colors';
import type { IBaseItemData } from '../../types';
import { PrintableSlipItemDetail } from './PrintableSlipItemDetail';
import { Text, Grid, makeStyles } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles({
    item: {
        pageBreakInside: 'avoid',
    },
    header: {
        fontSize: '1.2rem !important',
        fontWeight: 'bold',
    },
    nestedSection: {
        borderLeft: `1px solid ${PRINTABLE_SLIP_NESTED_BORDER_COLOR}`,
        marginTop: '0.5rem',
        marginLeft: '0.25rem',
    },
});

/**
 * Renders a "Base" order item to display on
 * the Printable Slip
 * @param props The order item to render
 * @returns Rendered order item details for the order "Base" item
 */
export const BaseItem: React.VFC<{ itemData: IBaseItemData | null }> = ({ itemData }) => {
    const classes = useStyles();
    if (!itemData) {
        return null;
    }
    return (
        <Grid container className={!itemData.allowPageBreaks ? classes.item : undefined}>
            <Grid container>
                <Grid item xs={itemData.headerRight ? 3 : 12}>
                    <Text variant={'h6'} className={classes.header}>
                        {itemData.headerLeft}
                    </Text>
                </Grid>
                {itemData.headerRight && (
                    <Grid item xs={9}>
                        <Text variant={'h6'} className={classes.header}>
                            {itemData.headerRight}
                        </Text>
                    </Grid>
                )}
            </Grid>
            {itemData.itemFields && (
                <Grid container className={classes.nestedSection}>
                    {itemData.itemFields.map(
                        field =>
                            field.customComponent ?? (
                                <PrintableSlipItemDetail
                                    key={field.key}
                                    title={field.key ?? 'Other'}
                                    value={field.value}
                                />
                            ),
                    )}
                </Grid>
            )}
        </Grid>
    );
};
