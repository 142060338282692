import { useMutation, useQuery } from '@apollo/client';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlCreateManufacturerTruckTimePayload } from '@orthly/graphql-schema';
import { LabsGqlDayOfWeek } from '@orthly/graphql-schema';
import { useHasCapability } from '@orthly/session-client';
import { QuickForm, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Icon, IconButton } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';

const AdminManufacturerTruckTimeModalCreate_Mutation = graphql(`
    mutation AdminManufacturerTruckTimeModalCreate_Mutation($payload: CreateManufacturerTruckTimePayload!) {
        createManufacturerTruckTime(payload: $payload) {
            id
        }
    }
`);

const AdminManufacturerTruckTimeModalUpdate_Mutation = graphql(`
    mutation AdminManufacturerTruckTimeModalUpdate_Mutation($payload: UpdateManufacturerTruckTimePayload!) {
        updateManufacturerTruckTime(payload: $payload) {
            id
        }
    }
`);

const AdminManufacturerTruckTimeModal_Fragment = graphql(`
    fragment AdminManufacturerTruckTimeModal_Fragment on ManufacturerTruckTimeEntity {
        id
        carrier
        days_of_week
        label_print_cutoff_time {
            hour
            minute
        }
        pickup_time {
            hour
            minute
        }
        supports_next_day
    }
`);

const AdminManufacturerTruckTimeModal_Query = graphql(`
    query AdminManufacturerTruckTimeModal_Query {
        listManufacturerTruckTimeSupportedCarriers {
            label
            value
        }
    }
`);

type QuickFormVars = Omit<LabsGqlCreateManufacturerTruckTimePayload, 'label_print_cutoff_time' | 'pickup_time'> & {
    // These two properties are stored as dates so quickform can store the time within them.
    // We convert them back to the proper data structure after the user presses submit.
    label_print_cutoff_time: Date;
    pickup_time: Date;
};

function useUpsertManufacturerTruckTime(existingTruckTimeId: string | null, onSuccess: () => Promise<void>) {
    const createMutation = useMutation(AdminManufacturerTruckTimeModalCreate_Mutation);
    const { submit: submitCreate, submitting: submittingCreate } = useRootActionCommand(createMutation, {
        successMessage: 'Created new truck time.',
        onSuccess,
    });

    const updateMutation = useMutation(AdminManufacturerTruckTimeModalUpdate_Mutation);
    const { submit: submitUpdate, submitting: submittingUpdate } = useRootActionCommand(updateMutation, {
        successMessage: 'Updated new truck time.',
        onSuccess,
    });

    return {
        submitting: submittingCreate || submittingUpdate,
        submit: async (data: QuickFormVars) => {
            if (existingTruckTimeId) {
                return submitUpdate({
                    payload: {
                        id: existingTruckTimeId,
                        carrier: data.carrier,
                        days_of_week: data.days_of_week,
                        manufacturer_id: data.manufacturer_id,
                        supports_next_day: data.supports_next_day,
                        label_print_cutoff_time: {
                            hour: data.label_print_cutoff_time.getHours(),
                            minute: data.label_print_cutoff_time.getMinutes(),
                        },
                        pickup_time: {
                            hour: data.pickup_time.getHours(),
                            minute: data.pickup_time.getMinutes(),
                        },
                    },
                });
            }

            return submitCreate({
                payload: {
                    carrier: data.carrier,
                    days_of_week: data.days_of_week,
                    manufacturer_id: data.manufacturer_id,
                    supports_next_day: data.supports_next_day,
                    label_print_cutoff_time: {
                        hour: data.label_print_cutoff_time.getHours(),
                        minute: data.label_print_cutoff_time.getMinutes(),
                    },
                    pickup_time: {
                        hour: data.pickup_time.getHours(),
                        minute: data.pickup_time.getMinutes(),
                    },
                },
            });
        },
    };
}

interface ManufacturerTruckTimeModalProps {
    manufacturerId: string;
    existingTruckTimeFragment: FragmentType<typeof AdminManufacturerTruckTimeModal_Fragment> | null;
    refetch: () => Promise<void>;
    timeZone: string;
}

export const ManufacturerTruckTimeModal: React.VFC<ManufacturerTruckTimeModalProps> = ({
    manufacturerId,
    existingTruckTimeFragment,
    refetch,
    timeZone,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);

    const { data } = useQuery(AdminManufacturerTruckTimeModal_Query, {
        skip: !open,
    });
    const availableCarriers = data?.listManufacturerTruckTimeSupportedCarriers ?? [];

    const existingTruckTime = existingTruckTimeFragment
        ? getFragmentData(AdminManufacturerTruckTimeModal_Fragment, existingTruckTimeFragment)
        : null;

    const { submit, submitting } = useUpsertManufacturerTruckTime(existingTruckTime?.id ?? null, async () => {
        await refetch();
        setOpen(false);
    });

    const hasCreateCapability = useHasCapability('lab_management', 'lab_management.truck_times.create');
    const hasUpdateCapability = useHasCapability('lab_management', 'lab_management.truck_times.edit');

    if ((!hasCreateCapability && !existingTruckTime) || (!hasUpdateCapability && existingTruckTime)) {
        return null;
    }

    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            title={`${existingTruckTimeFragment ? 'Update' : 'Create'} Manufacturer Truck Time`}
            loading={submitting}
            content={
                <QuickForm<QuickFormVars>
                    initialValues={
                        existingTruckTime
                            ? {
                                  ...existingTruckTime,
                                  manufacturer_id: manufacturerId,
                                  label_print_cutoff_time: dayjs()
                                      .hour(existingTruckTime.label_print_cutoff_time.hour)
                                      .minute(existingTruckTime.label_print_cutoff_time.minute)
                                      .toDate(),
                                  pickup_time: dayjs()
                                      .hour(existingTruckTime.pickup_time.hour)
                                      .minute(existingTruckTime.pickup_time.minute)
                                      .toDate(),
                              }
                            : { manufacturer_id: manufacturerId }
                    }
                    fields={{
                        manufacturer_id: {
                            type: 'text',
                            hidden: true,
                        },
                        carrier: {
                            label: 'Carrier',
                            type: 'select',
                            options: availableCarriers,
                        },
                        days_of_week: {
                            label: 'Days of Week',
                            type: 'multiselect',
                            options: [
                                { label: 'Monday', value: LabsGqlDayOfWeek.Monday },
                                { label: 'Tuesday', value: LabsGqlDayOfWeek.Tuesday },
                                { label: 'Wednesday', value: LabsGqlDayOfWeek.Wednesday },
                                { label: 'Thursday', value: LabsGqlDayOfWeek.Thursday },
                                { label: 'Friday', value: LabsGqlDayOfWeek.Friday },
                                { label: 'Saturday', value: LabsGqlDayOfWeek.Saturday },
                                { label: 'Sunday', value: LabsGqlDayOfWeek.Sunday },
                            ],
                        },
                        supports_next_day: {
                            label: 'Supports Next Day',
                            type: 'boolean',
                        },
                        label_print_cutoff_time: {
                            type: 'time',
                            label: `Print cutoff time (${timeZone})`,
                            fieldProps: {
                                slotProps: {
                                    digitalClockSectionItem: {
                                        // @ts-expect-error - this is a valid prop
                                        minutesStep: 15,
                                    },
                                },
                            },
                        },
                        pickup_time: {
                            type: 'time',
                            label: `Pickup time (${timeZone})`,
                            fieldProps: {
                                slotProps: {
                                    digitalClockSectionItem: {
                                        // @ts-expect-error - this is a valid prop
                                        minutesStep: 15,
                                    },
                                },
                            },
                        },
                    }}
                    onSubmit={submit}
                />
            }
            CustomButton={() => (
                <IconButton onClick={() => setOpen(true)}>
                    <Icon icon={existingTruckTimeFragment ? 'Edit' : 'AddIcon'} />
                </IconButton>
            )}
        />
    );
};
