// We import a CSS file because we need to set a specific class that the react-calendly modal uses,
// while the react-calendly package doesn't expose any way to provide custom styles.
import '../../../../../../assets/styles/GuidedWaxupScheduleLiveCall.css';
import { CalendlyModal } from '../../../../Calendly';
import { useGuidedWaxupSelector } from '../../state/GuidedWaxupState';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlCreateLiveDdpReviewRequestArgs } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import _ from 'lodash';
import React from 'react';

type Prefill = React.ComponentProps<typeof CalendlyModal>['prefill'];

/**
 * Fetches data from the active state to form prefilled data for the Calendly block.
 * These are things like the user's name, email, and notes.
 */
function useGetPrefillData(): Prefill {
    const session = useSession();
    const presets = useGuidedWaxupSelector(s => s.presets);

    const prefilledNotes = Object.entries(presets).reduce<string>((state, preset) => {
        const [type, info] = preset;

        if (!info.status) {
            return state;
        }

        const block = `- ${_.startCase(type)} (${_.startCase(info.status)})${info.notes ? `: "${info.notes}"` : ``}`;

        return `${state}${block}`;
    }, `Notes from current review:\r\n`);

    return {
        name: session ? `${session.user.first_name} ${session.user.last_name}` : undefined,
        email: session?.user.email,
        firstName: session?.user.first_name,
        lastName: session?.user.last_name,
        customAnswers: {
            a1: prefilledNotes,
        },
    };
}

interface GuidedWaxupScheduleLiveCallCalendlyModalProps {
    variant: 'production' | 'development';
    onModalClose: () => void;
    orderId: string;
    revisionId: string;
    submit: (args: LabsGqlCreateLiveDdpReviewRequestArgs) => Promise<void>;
}

export const GuidedWaxupScheduleLiveCallCalendlyModal: React.VFC<GuidedWaxupScheduleLiveCallCalendlyModalProps> = ({
    variant,
    onModalClose,
    orderId,
    revisionId,
    submit,
}) => {
    const calendlyUrl =
        variant === 'production'
            ? 'https://calendly.com/boyle-meetdandy/schedule-a-live-design-review'
            : 'https://calendly.com/engineering-meetdandy/live-design-review';

    const session = useSession();
    const prefillData = useGetPrefillData();

    if (!session) {
        return null;
    }

    return (
        <CalendlyModal
            url={calendlyUrl}
            onModalClose={onModalClose}
            prefill={prefillData}
            onEventScheduled={async event => {
                const eventId = event.data.payload.event.uri.split('/').at(-1) ?? '';
                await submit({
                    order_id: orderId,
                    design_order_revision_id: revisionId,
                    calendly_event_id: eventId,
                    practice_id: session.organization_id,
                });

                BrowserAnalyticsClientFactory.Instance?.track(
                    'Practice - Portal - Live Design Review - Scheduled Call',
                    {
                        $groups: { order: orderId },
                        eventId,
                    },
                );
            }}
        />
    );
};
