import type { IBaseItemData } from '../../types';
import { getHeaderForLabSlipItem } from '../../utils/shared.utils';
import { BaseItem } from './BaseItem';
import type { IWaxupItem } from '@orthly/items';
import React from 'react';

/**
 * Renders a "Waxup" order item to display on
 * the Printable Slip
 * @param props The order item to render
 * @returns Rendered order item details for the order "Waxup" item
 */
export const WaxupItem: React.VFC<{ item: IWaxupItem }> = ({ item }) => {
    return <BaseItem itemData={mapWaxupItemToPrintableSlipData(item)} />;
};

/**
 * Pull data needed for printable slip display into a standard data structure
 * @param item IWaxupItem order item to display
 * @returns Structured data for Waxup item display
 */
function mapWaxupItemToPrintableSlipData(item: IWaxupItem): IBaseItemData {
    const optionalFields: { key: string; value: string }[] = [];

    if (item.selected_teeth) {
        optionalFields.push({ key: 'Teeth', value: item.selected_teeth.join(', ') });
    }

    return {
        headerLeft: getHeaderForLabSlipItem(item),
        itemFields: [...optionalFields],
    };
}
