import { ProductImageWrapper } from '../ProductImageWrapper';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { ToolbarContainer } from '@orthly/ui';
import { stylesFactory, useMediaQuery, Grid, Icon, Button, Text } from '@orthly/ui-primitives';
import type { Theme } from '@orthly/ui-primitives';
import React from 'react';

export const ORDER_TOOLBAR_HEIGHT = 72;

const useStyles = stylesFactory(() => ({
    root: {
        justifyContent: 'start',
        alignItems: 'center',
        height: ORDER_TOOLBAR_HEIGHT,
        flexWrap: 'nowrap',
        flexShrink: 0,
    },
}));

export interface OrderToolbarProps {
    order?: Pick<LabsGqlLabOrderFragment, 'patient' | 'order_number' | 'items_v2'>;
    onClose: () => void;
    fullscreenSidebarShown?: boolean;
    toggleFullscreenSidebar?: () => void;
    showFeedbackOrderDetails?: boolean;
    customTitle?: string;
    hideProductImage?: boolean;
}

export const OrderToolbar: React.VFC<OrderToolbarProps> = ({
    order,
    onClose,
    fullscreenSidebarShown,
    toggleFullscreenSidebar,
    showFeedbackOrderDetails,
    hideProductImage = false,
    customTitle,
}) => {
    const classes = useStyles();
    const { order_number, items_v2 } = order ?? {};
    const items = items_v2 ? OrderItemV2Utils.parseItems(items_v2) : [];
    const productUnitType = items[0] ? CartItemV2Utils.getProductUnitType(items[0]) : 'Unknown';
    const hiddenUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));
    const hiddenDown = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const showOrderDetails =
        !hiddenUp && !fullscreenSidebarShown && toggleFullscreenSidebar && showFeedbackOrderDetails;
    const defaultTitle = order?.patient ? `${order.patient.first_name} ${order.patient.last_name}` : '';

    return (
        <ToolbarContainer sticky className={classes.root}>
            {!hideProductImage && <ProductImageWrapper product={productUnitType} />}
            <Grid item style={{ marginLeft: 12 }}>
                <Text variant={'h6'}>{customTitle ?? defaultTitle}</Text>
                {showOrderDetails ? (
                    <Text
                        variant={'body2'}
                        medium
                        color={'PRIMARY_FOREGROUND'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => toggleFullscreenSidebar?.()}
                    >
                        View order details <Icon icon={'ChevronRight'} style={{ marginBottom: -6 }} />
                    </Text>
                ) : null}
            </Grid>
            {hiddenDown ? null : (
                <Grid item style={{ marginLeft: 16 }}>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        Order #{order_number}
                    </Text>
                </Grid>
            )}
            <div style={{ flexGrow: 1 }} />
            <Grid item>
                <Button variant={'secondary'} style={{ padding: 8, minWidth: `0` }} onClick={() => onClose()}>
                    <Icon icon={'CloseIcon'} />
                </Button>
            </Grid>
        </ToolbarContainer>
    );
};
