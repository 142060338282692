import type { SlideProps, ButtonProps } from '@orthly/ui-primitives';
import { FlossPalette, stylesFactory, Button, Typography } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    additionalContentWrapper: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    delayedWrapper: {
        backgroundColor: FlossPalette.ATTENTION_FOREGROUND,
        width: '100%',
    },
    navAreaWrapper: {
        position: 'sticky',
        display: 'flex',
        width: '100%',
        borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        background: 'white',
        alignItems: 'center',
        zIndex: 10000,
        flexDirection: 'column',
    },
    navAreaInnerWrapper: {
        alignItems: 'center',
        display: 'flex',
        padding: 40,
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            padding: 18,
        },
        gridGap: 16,
    },
    buttonsWrapper: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            justifyContent: 'space-between',
        },
    },
    leftButton: {
        width: 264,
        marginRight: 32,
        [theme.breakpoints.down('md')]: {
            width: '45%',
            marginRight: 0,
        },
    },
    rightButton: {
        width: 264,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    rightButtonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            width: '45%',
        },
    },
}));

export interface NavAreaProps {
    onBack?: () => void;
    onNext?: () => void;
    nextTitle?: React.ReactNode;
    backTitle?: React.ReactNode;
    disableNext?: boolean;
    nextButtonProps?: Partial<ButtonProps>;
    backButtonProps?: Partial<ButtonProps>;
    NextButtonAdditionalContent?: React.ReactNode;
    AdditionalContent?: React.ReactNode;
    onNavigateClick?: (direction: SlideProps['direction']) => void;
    wrapperWidth?: string;
    navAreaWrapperStyle?: React.CSSProperties;
    disableNextForNoChanges?: boolean;
    BannerComponent?: React.ReactNode;
}

export const NavArea: React.VFC<NavAreaProps> = ({
    onBack,
    onNext,
    nextTitle,
    backTitle,
    disableNext,
    backButtonProps,
    nextButtonProps,
    NextButtonAdditionalContent,
    AdditionalContent,
    onNavigateClick,
    wrapperWidth,
    navAreaWrapperStyle,
    disableNextForNoChanges,
    BannerComponent,
}) => {
    const classes = useStyles();
    const isNextButton = nextTitle === 'Next';
    return (
        <div className={classes.navAreaWrapper}>
            <div className={classes.navAreaInnerWrapper} style={{ width: wrapperWidth, ...navAreaWrapperStyle }}>
                <div className={classes.additionalContentWrapper}>{AdditionalContent}</div>
                <div className={classes.buttonsWrapper}>
                    {onBack && (
                        <Button
                            className={classes.leftButton}
                            variant={'secondary-gray'}
                            onClick={() => {
                                onNavigateClick && onNavigateClick('up');
                                onBack();
                            }}
                            {...backButtonProps}
                        >
                            {backTitle ?? 'Back'}
                        </Button>
                    )}
                    {onNext && (
                        <div className={classes.rightButtonWrapper}>
                            <Button
                                className={classes.rightButton}
                                variant={'primary'}
                                onClick={() => {
                                    //Only animate if we are not on the confirmation screen
                                    if (onNavigateClick && isNextButton) {
                                        onNavigateClick('down');
                                    }
                                    onNext();
                                }}
                                disabled={disableNext}
                                {...nextButtonProps}
                            >
                                {nextTitle ?? 'Next'}
                            </Button>
                            {NextButtonAdditionalContent}
                            {isNextButton && disableNextForNoChanges && (
                                <Typography variant={'body2'} color={'textSecondary'}>
                                    Edit item to continue
                                </Typography>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {BannerComponent}
        </div>
    );
};
