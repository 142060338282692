/* eslint-disable no-nested-ternary */
import type { WithClassesProp } from '../../../util/floss.types';
import { useMergedMuiClasses } from '../../../util/mergeMuiClasses';
import { stylesFactory } from '../../../util/stylesFactory';
import type { IconName, SvgIconProps } from '@orthly/ui-primitives';
import {
    Badge,
    Divider,
    ListItemButton,
    ListItemIcon,
    Icon as IconPrimitive,
    ListItemText,
    Grid,
    useScreenIsMobile,
    FlossPalette,
    FlossPaletteUtils,
} from '@orthly/ui-primitives';
import type { ComponentType } from 'react';
import React from 'react';

export type NavigationItemClassKey =
    | 'root'
    | 'iconRoot'
    | 'icon'
    | 'badge'
    | 'textRoot'
    | 'textTypography'
    | 'itemCountTypography'
    | 'itemCountRoot';

export interface NavigationItemProps extends WithClassesProp<NavigationItemClassKey> {
    collapsed: boolean;
    active: boolean;
    // denotes in a collapsible sub-section
    secondary?: boolean;
    onClick: () => void;
    title: React.ReactNode;
    Icon?: React.ComponentType<SvgIconProps>;
    iconName?: IconName;
    disabled?: boolean;
    alertCount?: number;
    itemCount?: number;
    children?: React.ReactNode;
    Container?: React.ComponentType;
    isFirstSectionItem?: boolean;
}

const ActiveBackground = FlossPaletteUtils.toRgba('STAR_GRASS', 0.15);

const useStyles = stylesFactory<NavigationItemProps & { isMobile: boolean }, NavigationItemClassKey>(theme => ({
    root: props => ({
        flexWrap: props.collapsed ? 'wrap' : 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        // this has to account for the 4px border on the left
        padding: !props.collapsed ? '0 16px 0 20px' : '8px 4px 8px 0',
        height: !props.collapsed ? 40 : !props.Icon ? 32 : 56,
        color: props.active ? FlossPalette.STAR_GRASS : props.secondary ? FlossPalette.GRAY : FlossPalette.BLACK,
        opacity: props.active || props.secondary ? 1 : 0.7,
        borderLeftColor: props.active ? FlossPalette.STAR_GRASS : 'transparent',
        borderLeft: '4px solid',
        background: props.active ? ActiveBackground : undefined,
        transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
        }),
        width: '100%',
        '&:hover': { background: props.active ? ActiveBackground : FlossPalette.DARK_TAN },
    }),
    iconRoot: ({ collapsed, Icon, isMobile }) => ({
        justifyContent: 'center',
        alignItems: 'center',
        width: isMobile ? 16 : !collapsed ? 24 : !Icon ? 0 : 60,
        minWidth: !collapsed ? 24 : undefined,
    }),
    icon: { width: 'auto', minWidth: 'unset', color: 'inherit' },
    badge: props => ({
        borderRadius: 4,
        backgroundColor: FlossPalette.ATTENTION,
        height: 16,
        padding: '0 2px',
        right: props.collapsed ? 0 : -126,
        top: props.collapsed ? 0 : 3,
        transform: props.collapsed ? undefined : 'unset',
        transition: theme.transitions.create(['top', 'right', 'transform'], {
            easing: theme.transitions.easing.easeInOut,
        }),
    }),
    textRoot: props => ({
        width: '100%',
        height: !props.Icon && props.collapsed ? '100%' : undefined,
        margin: !props.Icon && props.collapsed ? 0 : undefined,
    }),
    itemCountRoot: props => ({
        width: '100%',
        height: !props.Icon && props.collapsed ? '100%' : undefined,
        right: !props.Icon && props.collapsed ? 0 : -126,
    }),
    textTypography: props => ({
        color: 'inherit',
        width: '100%',
        fontWeight: 500,
        fontSize: props.isMobile ? 14 : props.collapsed ? 11 : '1rem',
        textAlign: props.collapsed ? 'center' : undefined,
        marginLeft: props.collapsed ? 0 : 8,
        transition: theme.transitions.create(['font-size', 'margin'], {
            easing: theme.transitions.easing.easeInOut,
            duration: 250,
            delay: 0,
        }),
        whiteSpace: 'nowrap',
    }),
    itemCountTypography: props => ({
        color: FlossPalette.GRAY,
        width: '100%',
        fontSize: props.collapsed ? 11 : 12,
        textAlign: props.collapsed ? 'center' : 'right',
        transition: theme.transitions.create(['font-size', 'margin'], {
            easing: theme.transitions.easing.easeInOut,
            duration: 250,
            delay: 0,
        }),
    }),
}));

const NavIcon = ({ Icon, iconName }: { Icon?: React.ComponentType<SvgIconProps>; iconName?: IconName }) => {
    if (Icon) {
        return <Icon />;
    }
    if (iconName) {
        return <IconPrimitive icon={iconName} />;
    }
    return <div />;
};

export const NavigationItem: React.FC<NavigationItemProps> = props => {
    const isMobile = useScreenIsMobile();
    const classes = useMergedMuiClasses<NavigationItemClassKey>(
        useStyles({
            ...props,
            isMobile,
            Icon: (props.Icon || props.iconName) as unknown as ComponentType<SvgIconProps> | undefined,
        }),
        props.classes,
    );
    const { Icon, iconName, onClick, title, disabled, isFirstSectionItem, itemCount, alertCount = 0 } = props;
    const Container = props.Container ?? React.Fragment;
    return (
        <Container>
            {isFirstSectionItem && <Divider style={{ width: 'auto', margin: isMobile ? '8px 12px' : '12px' }} />}
            {/*@ts-expect-error - MUI types are missing aria-label */}
            <ListItemButton
                component={'button'}
                onClick={onClick}
                aria-label={title}
                className={classes.root}
                selected={false}
                disabled={disabled}
            >
                <Grid container className={classes.iconRoot}>
                    <Badge
                        classes={{ badge: classes.badge }}
                        badgeContent={alertCount}
                        color={'error'}
                        invisible={props.alertCount === undefined}
                        overlap={'rectangular'}
                    >
                        <ListItemIcon className={classes.icon}>
                            <NavIcon Icon={Icon} iconName={iconName} />
                        </ListItemIcon>
                    </Badge>
                </Grid>
                <ListItemText
                    secondary={title}
                    className={classes.textRoot}
                    secondaryTypographyProps={{ className: classes.textTypography }}
                />
                {props.children}
                {itemCount !== null && !props.collapsed && (
                    <ListItemText
                        secondary={itemCount}
                        className={classes.itemCountRoot}
                        secondaryTypographyProps={{ className: classes.itemCountTypography }}
                    />
                )}
            </ListItemButton>
        </Container>
    );
};

export type NavigationEntry = Omit<NavigationItemProps, 'collapsed' | 'secondary'>;
