import { useAdminUiAction } from '../redux/ui/ui.actions';
import { useAdminUiSelector } from '../redux/ui/ui.state';
import { useOpenOrderDetailOps } from '../utils/router/useOpenOrderDetail';
import type { NavigationEntryBottom } from '@orthly/ui';
import { OrdersSpotlightSearch, useOrdersSpotlightSearchNavItem } from '@orthly/veneer';
import React from 'react';

export const AdminOrdersSpotlightSearch: React.FC = () => {
    const open = useAdminUiSelector(s => s.activeSpotlight === 'search');
    const setOpen = useAdminUiAction('SET_SPOTLIGHT_OPEN');
    const openOrder = useOpenOrderDetailOps();
    return <OrdersSpotlightSearch open={open} setOpen={setOpen} openOrder={openOrder} />;
};

export function useAdminOrdersSpotlightSearchNavItem(): NavigationEntryBottom {
    const open = useAdminUiSelector(s => s.activeSpotlight === 'search');
    const setOpen = useAdminUiAction('SET_SPOTLIGHT_OPEN');
    return useOrdersSpotlightSearchNavItem(open, setOpen);
}
