import { useGetImpersonationToken } from '../../../../utils/useGetImpersonationToken';
import { ImpersonatePreviewBox } from '../../../Impersonate/GlobalImpersonate';
import { OrthlyBrowserConfig, Icon } from '@orthly/ui';
import { Button, Dialog, FlossPalette, styled, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

const ImpersonateButton = styled(Button)({
    minWidth: 'unset',
    height: 'fit-content',
    color: FlossPalette.PRIMARY_FOREGROUND,
});

interface OrderDetailSummaryImpersonationButtonProps {
    practiceId: string;
    practiceName: string;
}

export const OrganizationOverviewImpersonationButton: React.VFC<OrderDetailSummaryImpersonationButtonProps> = ({
    practiceId,
    practiceName,
}) => {
    const [impersonationUrl, setImpersonationUrl] = React.useState<string | null>(null);

    const [getImpersonateToken] = useGetImpersonationToken(token =>
        setImpersonationUrl(`${OrthlyBrowserConfig.practicePortalUrl}?impersonateToken=${token}`),
    );

    return (
        <>
            <Tooltip title={'Impersonate'} arrow>
                <ImpersonateButton
                    onClick={() =>
                        getImpersonateToken({
                            variables: {
                                user_id: null,
                                organization_id: practiceId,
                                expires_in_seconds: null,
                            },
                        })
                    }
                    style={{ padding: '0 8px 0 0' }}
                    variant={'ghost'}
                >
                    <Icon icon={'IncognitoIcon'} />
                </ImpersonateButton>
            </Tooltip>
            <Dialog open={!!impersonationUrl} onClose={() => setImpersonationUrl(null)}>
                {impersonationUrl && (
                    <ImpersonatePreviewBox
                        impersonateLink={impersonationUrl}
                        onReset={() => setImpersonationUrl(null)}
                        userName={practiceName}
                    />
                )}
            </Dialog>
        </>
    );
};
