import { FlossPalette, Menu, MenuItem, ChevronDown } from '@orthly/ui-primitives';
import React from 'react';

type Option<T> = { label: string; value: T };

interface DesignConfigDropdownProps<T> {
    options: Option<T>[];
    submit: (option: T) => Promise<boolean>;
    selected: Option<T>;
    disabled?: boolean;
}

export function DesignConfigDropdown<T>({ options, selected, submit, disabled = false }: DesignConfigDropdownProps<T>) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleClose = (option: Option<T>) => async () => {
        setAnchorEl(null);
        await submit(option.value);
    };

    return (
        <>
            <div
                onClick={e => setAnchorEl(e.currentTarget)}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    cursor: disabled ? undefined : 'pointer',
                    width: '100%',
                    height: '24px',
                }}
            >
                {selected.label}
                {!disabled && (
                    <ChevronDown
                        style={{
                            justifySelf: 'right',
                            color: FlossPalette.GRAY,
                            transform: !!anchorEl ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform .3s',
                        }}
                    />
                )}
            </div>
            {!disabled && (
                <Menu
                    onClose={() => setAnchorEl(null)}
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    keepMounted
                    PaperProps={{ style: { width: anchorEl?.clientWidth ?? undefined } }}
                >
                    {options.map(option => {
                        return (
                            <MenuItem
                                onClick={handleClose(option)}
                                key={option.label}
                                selected={selected.value === option.value}
                            >
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </Menu>
            )}
        </>
    );
}
