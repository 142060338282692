import { JsonDevTools } from '../../../components/JsonDevTools';
import { CreateDesignReviewFieldForm, EditDesignReviewFieldForm } from './DesignReviewFieldsForms';
import type { LabsGqlDesignReviewFieldFragment } from '@orthly/graphql-operations';
import { useListDesignReviewFieldsQuery } from '@orthly/graphql-react';
import MUITable from '@orthly/mui-table';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface DesignReviewFieldDetailPanelProps {
    field: LabsGqlDesignReviewFieldFragment;
    onSuccess: () => Promise<void>;
}

const DesignReviewFieldDetailPanel: React.FC<DesignReviewFieldDetailPanelProps> = props => {
    const { field } = props;
    return (
        <Grid container style={{ maxWidth: 'calc(100vw - 64px)', padding: 20 }}>
            <Grid container item xs={8}>
                <EditDesignReviewFieldForm field={field} onSuccess={props.onSuccess} />
            </Grid>
            <Grid container item xs={4}>
                <JsonDevTools src={field} dataType={'IDesignReviewField'} />
            </Grid>
        </Grid>
    );
};

export const DesignReviewFieldsTable: React.FC = () => {
    const { data, loading, refetch } = useListDesignReviewFieldsQuery();
    const [createOpen, setCreateOpen] = React.useState<boolean>(false);
    return (
        <Grid container>
            <MUITable<LabsGqlDesignReviewFieldFragment>
                title={'Design Review Fields'}
                data={data?.listDesignReviewFields ?? []}
                loading={loading}
                displayOptions={{ fixedSearch: true, elevation: 0, viewColumns: true, filter: true, sort: true }}
                actions={{
                    global: [
                        { icon: 'refresh', position: 'toolbar', onClick: () => refetch().catch(console.error) },
                        { icon: 'add', position: 'toolbar', onClick: () => setCreateOpen(true), tooltip: 'Create' },
                    ],
                }}
                rowOptions={{ rowHover: true }}
                eventHooks={{ onRowClick: (row, actions) => actions.toggleDetailPanel(row) }}
                columns={[
                    { name: 'Types', render: row => row.types.join(`, `) },
                    { name: 'Name', render: 'name' },
                    { name: 'Category', render: 'category' },
                    {
                        name: 'Applicable Order Types',
                        render: row => (
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                {row.applicable_order_types.length ? row.applicable_order_types.join(' OR ') : 'All'}
                            </div>
                        ),
                    },
                    { name: 'Archived', render: 'archived', type: 'boolean' },
                    { name: 'Updated', render: 'updated_at', type: 'date' },
                    { name: 'Created', render: 'created_at', type: 'date' },
                ]}
                DetailPanel={detailProps => (
                    <DesignReviewFieldDetailPanel
                        field={detailProps.data}
                        onSuccess={async () => {
                            await refetch();
                        }}
                    />
                )}
            />
            <CreateDesignReviewFieldForm
                setOpen={setCreateOpen}
                open={createOpen}
                onSuccess={async () => {
                    await refetch();
                    setCreateOpen(false);
                }}
            />
        </Grid>
    );
};
