import type { LabsGqlAbutmentPartFragment } from '@orthly/graphql-operations';
import { formatAbutmentPartsSpecsText } from '@orthly/veneer';
import _ from 'lodash';

/**
 * This function's goal is to format the option in the dropdown, so that we show the SKU to represent
 * the abutment part. However, if a SKU is present multiple times, it means it has variables parts spec
 * (happens for ~1% of abutment parts), and therefore we also display the parts spec in the label
 * so the user can differentiate which abutment part to select.
 */
export const formatAbutmentPartOptions = (
    abutmentParts: LabsGqlAbutmentPartFragment[],
): { value: string; label: string }[] => {
    const options: { value: string; label: string }[] = [];

    const groupedBySku = _.groupBy(abutmentParts, 'sku');

    for (const [sku, partsWithSameSkus] of Object.entries(groupedBySku)) {
        if (partsWithSameSkus.length === 1) {
            // If only one abutment part for this SKU, we push directly to the list with the SKU as label
            partsWithSameSkus[0] && options.push({ value: partsWithSameSkus[0].id, label: sku });
        } else if (partsWithSameSkus.length > 1) {
            // If there are more than 1 part for the same SKU, we create an option with the parts specs text along side the SKU
            for (const part of partsWithSameSkus) {
                options.push({ value: part.id, label: `${sku} (${formatAbutmentPartsSpecsText(part)})` });
            }
        }
    }
    return options;
};
