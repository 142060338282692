import { useApolloClient, useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import { apolloErrorMessage, OrthlyBrowserConfig, QuickForm, RootActionDialog } from '@orthly/ui';
import { Text } from '@orthly/ui-primitives';
import { validateStlFile } from '@orthly/veneer';
import { getQFUploadMultiFileFieldDef } from '@orthly/veneer';
import { useSnackbar } from 'notistack';
import path from 'path';
import React from 'react';
import { useAsyncCallback } from 'react-async-hook';

const InjectionMoldFiles_Query = graphql(`
    query InjectionMoldFiles_Query($orderId: String!) {
        getInjectionMoldFiles(orderId: $orderId)
    }
`);

const ManuallyAttachInjectionMoldFiles_Mutation = graphql(`
    mutation manuallyAttachInjectionMoldFiles($filePaths: [String!]!, $orderId: String!) {
        manuallyAttachInjectionMoldFiles(filePaths: $filePaths, orderId: $orderId)
    }
`);

interface Props {
    orderId: LabsGqlOrder['id'];
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
}

export const UploadInjectionMoldFilesDialog: React.FC<Props> = props => {
    const { orderId, open, setOpen, refetch } = props;
    const { enqueueSnackbar } = useSnackbar();
    const {
        loading,
        data: injectionMoldFiles,
        refetch: refetchInjectionMoldFiles,
    } = useQuery(InjectionMoldFiles_Query, {
        variables: {
            orderId,
        },
        skip: !open,
    });
    const client = useApolloClient();
    const { loading: mutationSubmitting, execute: submitInjectionMoldFiles } = useAsyncCallback(
        ({ filePaths, orderId }: { filePaths: string[]; orderId: string }) =>
            client.mutate({
                mutation: ManuallyAttachInjectionMoldFiles_Mutation,
                variables: {
                    filePaths,
                    orderId,
                },
            }),
        {
            onSuccess: async () => {
                enqueueSnackbar('Files Uploaded!');
                await refetchInjectionMoldFiles();
                setOpen(false);
                refetch();
            },
            onError: error => {
                enqueueSnackbar(apolloErrorMessage(error), { variant: 'error' });
            },
        },
    );

    const storagePathConfig = getFullStoragePath(
        OrthlyBrowserConfig.env,
        OrderingStorageConfigs.moldInjections,
        orderId,
    );
    return (
        <RootActionDialog
            loading={loading || mutationSubmitting}
            open={open}
            setOpen={setOpen}
            hideButton={true}
            title={'Upload Injection Mold Files!'}
            subtitle={
                injectionMoldFiles?.getInjectionMoldFiles.length ? (
                    <>
                        <Text variant={'body2'}>
                            {`Already uploaded: ${injectionMoldFiles?.getInjectionMoldFiles
                                .map((filePath: string) => path.basename(filePath))
                                .join(', ')}`}
                        </Text>
                        <Text variant={'body2'}>Existing files with the same name will be overwritten.</Text>
                    </>
                ) : null
            }
            content={
                <QuickForm<{ files: string[] }>
                    fields={{
                        files: getQFUploadMultiFileFieldDef({
                            optional: false,
                            storagePathConfig: storagePathConfig,
                            label: 'Injection Mold Files',
                            accept: { 'model/stl': ['.stl'], 'application/stl': ['.stl'] },
                            customFileValidation: validateStlFile,
                        }),
                    }}
                    initialValues={{}}
                    onSubmit={result => {
                        void submitInjectionMoldFiles({
                            orderId: orderId,
                            filePaths: result.files,
                        });
                    }}
                />
            }
        />
    );
};
