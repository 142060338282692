import { DoctorProfilePhotoContent } from '../DoctorProfilePhoto';
import type { LabsGqlDoctorProfilePhotoDto } from '@orthly/graphql-schema';
import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import type { StaffRolePractice } from '@orthly/retainer-common';
import { PhoneNumberUtils } from '@orthly/runtime-utils';
import { NavigationLogoD } from '@orthly/ui';
import { FlossPalette, stylesFactory, Text, Card, Grid, Skeleton } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    card: {
        width: 328,
        borderRadius: 16,
        padding: 24,
        background: `linear-gradient(330deg, rgba(232,243,232,1) 15%, ${FlossPalette.WHITE} 45%)`,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        height: 'fit-content',
        '& .MuiTypography-body1, & .MuiTypography-body2': {
            fontWeight: 400,
            whiteSpace: 'nowrap',
        },
        '& .MuiTypography-body1': {
            fontSize: 14,
        },
        '& .MuiTypography-body2': {
            fontSize: 12,
        },
        '& .MuiSkeleton-root': {
            borderRadius: 16,
        },
    },
}));

export const PersonCardOccupationOptionsDisplay: { [k in LabsGqlStaffRolePractice]?: string } = {
    [LabsGqlStaffRolePractice.Admin]: 'Admin',
    [LabsGqlStaffRolePractice.PracticeDoctor]: 'Dentist',
    [LabsGqlStaffRolePractice.PracticeAssistant]: 'Assistant',
    [LabsGqlStaffRolePractice.PracticeReceptionist]: 'Receptionist',
    [LabsGqlStaffRolePractice.PracticeOfficeManager]: 'Office Manager',
    [LabsGqlStaffRolePractice.PracticeBilling]: 'Billing Manager',
} as const;

function getPersonCardOccupationOptionsDisplay(occupation?: string[] | string | null): string | undefined {
    if (Array.isArray(occupation)) {
        return occupation
            .map(o =>
                o in PersonCardOccupationOptionsDisplay
                    ? PersonCardOccupationOptionsDisplay[o as keyof typeof PersonCardOccupationOptionsDisplay]
                    : 'Other',
            )
            .join(', ');
    }
    return occupation && occupation in PersonCardOccupationOptionsDisplay
        ? PersonCardOccupationOptionsDisplay[occupation as keyof typeof PersonCardOccupationOptionsDisplay]
        : 'Other';
}

type PersonCardProps = React.ComponentProps<typeof Card> & {
    person: {
        name?: string;
        occupation?: StaffRolePractice[] | StaffRolePractice | null;
        contact_email?: string;
        contact_phone?: string;
        profile_photo?: LabsGqlDoctorProfilePhotoDto;
    };
    profilePhotoSourceIsBlob?: boolean;
    fullWidth?: boolean;
    hideEmail?: boolean;
    hidePhone?: boolean;
};

export const PersonCard: React.VFC<PersonCardProps> = props => {
    const { fullWidth, hideEmail, hidePhone, ...cardProps } = props;
    const { name, occupation, contact_email, contact_phone, profile_photo } = props.person;
    const classes = useStyles();

    return (
        <Card className={classes.card} {...cardProps} style={fullWidth ? { width: '100%' } : {}}>
            <Grid container direction={'row'} wrap={'nowrap'} alignItems={'center'} style={{ gap: 16 }}>
                {profile_photo && (
                    <DoctorProfilePhotoContent
                        type={profile_photo.type}
                        source={profile_photo.source ?? name ?? 'T'}
                        color={profile_photo.color}
                        sourceCanbeBlob={props.profilePhotoSourceIsBlob}
                        style={{ borderRadius: '50%', width: 58 }}
                    />
                )}
                <Grid container direction={'column'} wrap={'nowrap'} style={{ flexBasis: 1, flexGrow: 1 }}>
                    <Text variant={'body1'}>
                        {name || <Skeleton variant={'text'} animation={false} style={{ width: 128 }} />}
                    </Text>
                    <Text variant={'body2'} color={'DARK_GRAY'} style={{ marginTop: -4 }}>
                        {getPersonCardOccupationOptionsDisplay(occupation) || (
                            <Skeleton variant={'text'} animation={false} style={{ width: 40 }} />
                        )}
                    </Text>
                </Grid>
            </Grid>
            {!hideEmail && (
                <>
                    <Text variant={'body2'} color={'DARK_GRAY'} style={{ marginTop: 8 }}>
                        Email address
                    </Text>
                    <Text variant={'body1'} style={{ marginTop: -4 }}>
                        {contact_email || <Skeleton variant={'text'} animation={false} style={{ width: 160 }} />}
                    </Text>
                </>
            )}
            {!hidePhone && (
                <>
                    <Text variant={'body2'} color={'DARK_GRAY'} style={{ marginTop: 8 }}>
                        Phone number
                    </Text>
                    <Text variant={'body1'} style={{ marginTop: -4 }}>
                        {(contact_phone && PhoneNumberUtils.prettyPhoneNumber(contact_phone)) || (
                            <Skeleton variant={'text'} animation={false} style={{ width: 92 }} />
                        )}
                    </Text>
                </>
            )}
            <div style={{ position: 'relative' }}>
                <NavigationLogoD style={{ position: 'absolute', bottom: 0, right: 0, width: 24, height: 24 }} />
            </div>
        </Card>
    );
};
