import { LabOrderItemConditionField } from '../../../components/LabOrderItemConditionEditor';
import { RoutingRuleUtils } from '../../../components/RoutingRule.util';
import type { CapacityStationDtoFragment } from '@orthly/graphql-inline-react';
import type { LabsGqlCapacityStationRuleDto } from '@orthly/graphql-schema';
import { LabsGqlWorkOrderTypeEnum } from '@orthly/graphql-schema';
import type { FieldsDefProp } from '@orthly/ui';
import { LoadBlocker, QuickForm } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

type CapacityStationRuleFormFields = Pick<
    LabsGqlCapacityStationRuleDto,
    'name' | 'capacity_station_id' | 'work_order_type' | 'matching_logic_json' | 'default_unit_limit'
>;

const INITIAL_VALUES: CapacityStationRuleFormFields = {
    name: ``,
    capacity_station_id: ``,
    work_order_type: LabsGqlWorkOrderTypeEnum.Design,
    matching_logic_json: ``,
    default_unit_limit: 0,
};

export const CapacityStationRuleForm: React.VFC<{
    loading: boolean;
    stations: CapacityStationDtoFragment[];
    stationsLoading: boolean;
    onSubmit: (result: CapacityStationRuleFormFields) => void;
    initialValues?: CapacityStationRuleFormFields;
}> = ({ loading, stations, stationsLoading, onSubmit, initialValues }) => {
    const formFields: FieldsDefProp<CapacityStationRuleFormFields> = {
        name: {
            label: 'Name',
            type: 'text',
        },
        capacity_station_id: {
            label: 'Station',
            type: 'select',
            options: _.sortBy(stations, m => m.name.trim().toLowerCase()).map(({ id, name }) => ({
                value: id,
                label: name,
            })),
        },
        work_order_type: {
            label: 'Work order type',
            type: 'select',
            options: Object.values(LabsGqlWorkOrderTypeEnum),
        },
        matching_logic_json: {
            type: 'custom',
            label: 'Condition',
            component: LabOrderItemConditionField,
            validation: RoutingRuleUtils.ItemConditionValidation,
        },
        default_unit_limit: {
            label: 'Default Unit Limit',
            type: 'number',
        },
    };

    return (
        <LoadBlocker blocking={loading || stationsLoading}>
            <QuickForm<CapacityStationRuleFormFields>
                initialValues={initialValues ?? INITIAL_VALUES}
                fields={formFields}
                onSubmit={onSubmit}
            />
        </LoadBlocker>
    );
};
