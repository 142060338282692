import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { ToggleButton } from './ToggleButton/ToggleButton';
import { ToothUtils } from '@orthly/items';
import React from 'react';

export const useOpposingArchToggleButton = () => {
    const { appearanceFilters, teeth, toggleAppearanceFilters } = useGuidedWaxupContext();
    const isUpper = teeth.every(tooth => ToothUtils.toothIsUpper(tooth));
    const showOpposing = appearanceFilters.showUpper !== isUpper;
    const onClick = React.useCallback(() => {
        toggleAppearanceFilters(['showUpper', 'showLower']);
    }, [toggleAppearanceFilters]);

    return { isUpper, onClick, showOpposing };
};

export const OpposingArchToggleButton: React.VFC = () => {
    const { isUpper, onClick, showOpposing } = useOpposingArchToggleButton();

    return (
        <ToggleButton
            checked={showOpposing}
            icon={isUpper ? 'LowerJawIcon' : 'UpperJawIcon'}
            onClick={onClick}
            label={showOpposing ? 'Opposing arch on' : 'Opposing arch off'}
            compactLabel={'Opposing arch'}
        />
    );
};
