import { useFeatureFlag } from '../../../Providers/LaunchDarkly';
import { useInsertionAxisState, useOperationsState, useSceneState } from '../../AppState.hooks';
import { useOrder } from '../../OrderState.hooks';
import { InsertionAxisAdjusterMenu } from '@orthly/dentin';
import { SimpleToggle } from '@orthly/ui';
import { Grid, Button, stylesFactory, Text } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

const useStyles = stylesFactory(() => ({
    divider: {
        borderTop: '1px solid #e0e0e0',
    },
    switchRoot: {
        margin: '0px 0px 0px 2px',
    },
    switchLabel: {
        margin: '0px 0px 0px 8px',
    },
    finishingStagesNavigationWrapper: {
        height: 64,
        padding: 16,
        overflow: 'hidden',
    },
    finishingStagesNavigationTitle: {
        padding: `0px 14px`,
        textAlign: 'left',
        fontWeight: 600,
        margin: `auto 0px`,
        fontSize: 16,
        color: 'gray',
    },
    finishingStagesContainer: {
        height: '170px',
    },
    finishingStageRowWrapper: {
        padding: `10px 14px`,
        textAlign: 'left',
        cursor: 'pointer',
    },
    finishingStageRowWrapperSelected: {
        padding: `10px 14px`,
        textAlign: 'left',
        backgroundColor: '#E8F3E8',
        cursor: 'pointer',
    },
    finishingStageTitleRowWrapper: {
        borderTop: '1px solid #e0e0e0',
        textAlign: 'left',
    },
    stageMenuItem: {
        padding: `2px 16px`,
    },
}));

interface PoiStageProps {
    showMultiView: boolean;
    setShowMultiView: (value: boolean) => void;
}

const PoiTitleAndStage = () => {
    const localClasses = useStyles();

    return (
        <>
            <Grid
                item
                container
                direction={'row'}
                className={localClasses.finishingStagesNavigationWrapper}
                spacing={1}
            >
                <Grid item xs className={localClasses.finishingStagesNavigationTitle}>
                    Fix POI
                </Grid>
            </Grid>
            <Grid item className={localClasses.finishingStagesContainer}>
                <div role={'button'} className={localClasses.finishingStageRowWrapperSelected}>
                    <Text variant={'body2'} medium color={'PRIMARY_FOREGROUND'}>
                        Insertion Direction
                    </Text>
                </div>
            </Grid>
        </>
    );
};

const PoiStage: React.FC<PoiStageProps> = ({ showMultiView, setShowMultiView }) => {
    const localClasses = useStyles();
    const {
        manager: insertionAxisManager,
        insertionAxisIncrementDeg,
        setInsertionAxisIncrementDeg,
        insertionAxisIncrementRad,
    } = useInsertionAxisState();
    const { manager } = useSceneState();

    React.useEffect(() => {
        insertionAxisManager.insertionAxisIncrementRad = insertionAxisIncrementRad;
    }, [insertionAxisIncrementRad, insertionAxisManager]);

    return (
        <Grid item container spacing={1} className={localClasses.divider} style={{ padding: '0px 12px' }}>
            <Grid item color={'gray'} className={localClasses.finishingStageTitleRowWrapper}>
                Insertion Direction Tools
            </Grid>
            <Grid item xs>
                <Button
                    onClick={() => insertionAxisManager.setInsertionAxisFromCamera()}
                    variant={'secondary'}
                    fullWidth
                >
                    Set from current view
                </Button>
            </Grid>
            <Grid item>
                <InsertionAxisAdjusterMenu
                    onClick={direction => insertionAxisManager.adjustInsertionAxis(direction)}
                    onHover={direction =>
                        manager.onInsertionAxisAdjusterHover(
                            insertionAxisManager.computeInsertionAxisAdjustAxis(direction),
                        )
                    }
                    incrementAngleDeg={insertionAxisIncrementDeg}
                    setIncrementAngleDeg={setInsertionAxisIncrementDeg}
                />
            </Grid>
            <Grid item className={localClasses.stageMenuItem}>
                <SimpleToggle
                    label={'Show proximal multiview'}
                    checked={showMultiView}
                    onChange={setShowMultiView}
                    SwitchProps={{
                        classes: {
                            root: localClasses.switchRoot,
                        },
                    }}
                    FormControlLabelProps={{
                        classes: {
                            label: localClasses.switchLabel,
                        },
                    }}
                    blur={true}
                />
            </Grid>
        </Grid>
    );
};

export const FinishingPoiStage: React.VFC = () => {
    const { value: enableFinishingCommitInvalidIntaglio } = useFeatureFlag('enableFinishingCommitInvalidIntaglio');

    const { manager, toggleInsertionAxisAdjustingEnabled, setShowMultiView, showMultiView } = useInsertionAxisState();
    const sceneState = useSceneState();
    const order = useOrder();
    const { enqueueSnackbar } = useSnackbar();
    const { manager: opManager } = useOperationsState();

    const onComplete = (errorMessage: string | null): void => {
        errorMessage && console.warn(errorMessage);

        if (errorMessage && !enableFinishingCommitInvalidIntaglio) {
            enqueueSnackbar(errorMessage, {
                variant: 'error',
            });
        } else {
            toggleInsertionAxisAdjustingEnabled();
        }
    };

    const onCommit = () => {
        manager.commitInsertionAxis(order.id, onComplete);
    };

    const onCancel = () => {
        opManager.cancelInsertionAxis();
        toggleInsertionAxisAdjustingEnabled();
        sceneState.manager.toggleScanUndercutEnabled(false);
        Array.from(sceneState.appearance.restoratives.keys()).map(toothNumber => {
            sceneState.manager.setRestorativeTransparency(toothNumber, false);
        });
        setShowMultiView(false);
    };

    return (
        <Grid container direction={'column'}>
            <PoiTitleAndStage />
            <Grid item xs style={{ padding: '10px 12px' }}>
                <Button variant={'primary'} onClick={onCommit} fullWidth>
                    Save & return to finishing
                </Button>
            </Grid>
            <Grid item xs style={{ padding: '10px 12px' }}>
                <Button variant={'secondary'} onClick={onCancel} fullWidth>
                    Cancel & return to finishing
                </Button>
            </Grid>
            <PoiStage showMultiView={showMultiView} setShowMultiView={setShowMultiView} />
        </Grid>
    );
};
