import type { LabsGqlOrder, LabsGqlSubmitScanReviewMutationVariables } from '@orthly/graphql-operations';
import { useSubmitScanReviewMutation } from '@orthly/graphql-react';
import { QuickForm, SimpleSelect, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, Text, Collapse, Grid } from '@orthly/ui-primitives';
import { OrderTaskSidebarOverlay, FlagScanForm } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

interface ReasonsDisplayProps {
    reasons: string[];
}

const ReasonsDisplay: React.FC<ReasonsDisplayProps> = ({ reasons, children }) => {
    if (reasons.length === 0) {
        return null;
    }
    return (
        <Grid container>
            {children}
            {reasons.map((reason, index) => (
                <Grid key={index} container>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        • {reason}
                    </Text>
                </Grid>
            ))}
        </Grid>
    );
};

interface OrderScanReviewAlertProps {
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
}

interface SubmitOrderScanReviewProps extends OrderScanReviewAlertProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    reasons: string[];
    scan_review_task_notes?: string | null;
    initialResponse?: 'approve' | 'flag';
}

type Vars = LabsGqlSubmitScanReviewMutationVariables;

export const ScanRejectionReasons = [
    'Bite scan confirmation',
    'Dr approval needed on margin',
    'File download error',
    'Implant: Incorrect scan body',
    'Implant specs confirmation',
    'Insufficient Clearance',
    'Opposing arch missing or insufficient',
    'Order specification confirmation (shade, material or tooth number)',
    'Path of insertion issue',
    'Scan error (insufficient images/quality)',
    'Tissue over margin',
    'Other',
];

interface ScanReviewTaskSummaryProps {
    reasons: string[];
    notes?: string | null;
}

const ScanReviewTaskSummary: React.FC<ScanReviewTaskSummaryProps> = props => {
    return (
        <Grid container style={{ borderBottom: `1px solid ${FlossPalette.GRAY}`, paddingBottom: 16 }}>
            <ReasonsDisplay reasons={props.reasons}>
                <Grid container>
                    <Text variant={'body2'} medium>
                        Scan review requested for these reasons:{' '}
                    </Text>
                </Grid>
            </ReasonsDisplay>
            {props.notes && (
                <ReasonsDisplay reasons={props.notes.split('\n')}>
                    <Grid container>
                        <Text variant={'body2'} medium>
                            Request notes:{' '}
                        </Text>
                    </Grid>
                </ReasonsDisplay>
            )}
        </Grid>
    );
};

export const SubmitOrderScanReview: React.FC<SubmitOrderScanReviewProps> = props => {
    const { order, refetchOrder, setOpen, open } = props;
    const [submissionType, setSubmissionType] = React.useState<'approve' | 'flag' | undefined>(props.initialResponse);
    const [submitMtn] = useSubmitScanReviewMutation();
    const mtnSubmitter = (vars: Omit<Vars, 'id'>) => submitMtn({ variables: { ...vars, id: order.id } });
    const { submit, submitting } = useChangeSubmissionFn<any, [Omit<Vars, 'id'>]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => [`Scan ${submissionType === 'approve' ? 'Approved' : 'Flagged'}`, {}],
        onSuccess: async () => {
            await refetchOrder();
            setSubmissionType(undefined);
            setOpen(false);
        },
    });

    return (
        <OrderTaskSidebarOverlay
            open={open}
            setOpen={setOpen}
            title={submissionType ? `${_.startCase(submissionType)} scan` : 'Submit scan review'}
            blocked={submitting}
        >
            <Grid container direction={'column'} style={{ padding: '8px 16px' }}>
                <ScanReviewTaskSummary reasons={props.reasons} notes={props.scan_review_task_notes} />
                <Grid container style={{ padding: '12px 0' }}>
                    <SimpleSelect
                        options={[
                            { value: 'approve', label: 'Approve' },
                            { value: 'flag', label: 'Flag' },
                        ]}
                        value={submissionType ?? ''}
                        onChange={value => {
                            !!value && setSubmissionType(value === 'approve' ? 'approve' : 'flag');
                        }}
                        label={'Review response'}
                    />
                </Grid>
                <Collapse in={submissionType === 'flag'} style={{ width: '100%' }}>
                    {submissionType === 'flag' && <FlagScanForm {...{ order, submit }} />}
                </Collapse>
                <Collapse in={submissionType === 'approve'} style={{ width: '100%' }}>
                    {submissionType === 'approve' && (
                        <QuickForm<{ approval_notes?: string }>
                            fields={{
                                approval_notes: {
                                    type: 'text',
                                    optional: true,
                                    fieldProps: { multiline: true, minRows: 3, maxRows: 100 },
                                },
                            }}
                            initialValues={{}}
                            onSubmit={async result => {
                                await submit({ approval_notes: result.approval_notes ?? null, rejection: null });
                            }}
                            submitButtonProps={{ children: 'Approve scan' }}
                        />
                    )}
                </Collapse>
            </Grid>
        </OrderTaskSidebarOverlay>
    );
};
