import type { ButtonProps } from '@orthly/ui';
import { RootActionDialog } from '@orthly/ui';
import { FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

interface SettingsBaseModalProps {
    content: React.ReactNode;
    loading: boolean;
    title: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    subtitle?: string;
    CustomButton?: React.FC<ButtonProps>;
    hideButton?: boolean;
}

export const SettingsBaseModal: React.FC<SettingsBaseModalProps> = props => {
    return (
        <RootActionDialog
            showCloseButton
            closeIconButtonProps={{
                style: { height: 'fit-content' },
            }}
            closeIconProps={{ style: { color: FlossPalette.BLACK } }}
            titleTextProps={{ variant: 'h4', 'data-test': 'settings-base-modal-title' }}
            subtitleTextProps={{ 'data-test': 'settings-base-modal-subtitle' }}
            titleProps={{ style: { borderBottom: 'none' } }}
            contentProps={{
                style: { borderTop: 'none' },
                'data-test': 'settings-base-modal-content-wrapper',
            }}
            buttonText={''}
            {...props}
        />
    );
};
