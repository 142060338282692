import { ArchiveAndRestore } from './ArchiveAndRestore';
import { cleanStringSpaces } from './ImplantFieldUtils';
import { ScanbodyDisplay } from '@orthly/dentin';
import type { LabsGqlScanbodyFragment } from '@orthly/graphql-operations';
import {
    useGetImplantTypesQuery,
    useGetScanbodiesQuery,
    useCreateScanbodyMutation,
    useUpdateScanbodyMutation,
    useDeleteScanbodyMutation,
    useRestoreScanbodyMutation,
} from '@orthly/graphql-react';
import MUITable from '@orthly/mui-table';
import { selectFirstTruthyKey } from '@orthly/runtime-utils';
import { ScanbodyUtils } from '@orthly/shared-types';
import {
    ActionCard,
    QuickForm,
    LoadBlocker,
    RootActionDialog,
    useChangeSubmissionFn,
    apolloErrorMessage,
} from '@orthly/ui';
import { stylesFactory, Button, Dialog, DialogContent, Grid } from '@orthly/ui-primitives';
import { useCopyToClipboard } from '@orthly/veneer';
import constate from 'constate';
import _ from 'lodash';
import React from 'react';

const [ScanbodiesProvider, useScanbodies] = constate(() =>
    useGetScanbodiesQuery({
        variables: { withDeleted: true },
    }),
);

const useScanbodyImageFileInfoStyles = stylesFactory(() => ({
    dialog: {
        padding: '24px',
    },
}));

const ScanbodyImageFileInfo: React.VFC<{ scanbody: LabsGqlScanbodyFragment }> = ({ scanbody }) => {
    const classes = useScanbodyImageFileInfoStyles();
    const copyToClipboard = useCopyToClipboard();
    const [previewOpen, setPreviewOpen] = React.useState(false);

    const filename = ScanbodyUtils.getScanbodyImageFilename(scanbody.name, scanbody.manufacturer);

    return (
        <>
            <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)}>
                <DialogContent className={classes.dialog}>
                    <ScanbodyDisplay
                        implantManufacturer={'Manufacturer'}
                        implantSystem={'Sys.'}
                        implantConnection={'Conn.'}
                        scanbodyManufacturer={scanbody.manufacturer}
                        scanbodyName={scanbody.name}
                        requestedDate={new Date()}
                    />
                </DialogContent>
            </Dialog>
            <Grid container item>
                <Button fullWidth variant={'primary'} startIcon={'Visibility'} onClick={() => setPreviewOpen(true)}>
                    Preview scan body
                </Button>
            </Grid>
            <Grid container item>
                <Button
                    fullWidth
                    variant={'primary'}
                    startIcon={'CopyLinkIcon'}
                    onClick={() =>
                        copyToClipboard({
                            text: filename,
                            successMessage: 'Filename copied to clipboard!',
                            errorMessage: 'Failed to copy to clipboard',
                        })
                    }
                >
                    Copy image filename
                </Button>
            </Grid>
        </>
    );
};

interface ScanbodyRowProps {
    data: LabsGqlScanbodyFragment;
}

const ScanbodyRow: React.FC<ScanbodyRowProps> = ({ data: scanbody }) => {
    const { refetch: refetchScanbodies, loading: isFetching } = useScanbodies();
    const {
        data,
        error: fetchError,
        loading: isFetchingImplantTypes,
    } = useGetImplantTypesQuery({ variables: { withDeleted: false } });

    const [updateScanbody, { error: updateError, loading: isUpdating }] = useUpdateScanbodyMutation();
    const [deleteScanbody, { error: deleteError, loading: isDeleting }] = useDeleteScanbodyMutation();
    const [restoreScanbody, { error: restoreError, loading: isRestoring }] = useRestoreScanbodyMutation();

    const dispatchAction = async (promise: PromiseLike<any> | void) => {
        await promise;
        await refetchScanbodies();
    };

    const loading = isFetching || isFetchingImplantTypes || isUpdating || isDeleting || isRestoring;
    const error = updateError || deleteError || restoreError || fetchError;

    return (
        <Grid container style={{ padding: 16 }}>
            {error && (
                <ActionCard
                    variant={'alert'}
                    title={`Failed to ${
                        selectFirstTruthyKey({
                            update: updateError,
                            delete: deleteError,
                            restore: restoreError,
                            fetch: fetchError,
                        }) || 'do something with'
                    } scan body.`}
                    subtitle={apolloErrorMessage(error)}
                />
            )}
            <Grid container direction={'row'} alignItems={'flex-start'} spacing={4}>
                <Grid container item xs={9}>
                    <LoadBlocker blocking={(!data && !error) || isUpdating}>
                        {data?.getImplantTypes.length === 0 ? (
                            <ActionCard
                                variant={'alert'}
                                title={'There are no implant types yet'}
                                subtitle={'This is a fatal error. Please contact @engineers on slack.'}
                            />
                        ) : (
                            <QuickForm<{
                                name: string;
                                manufacturer: string;
                                sku: string;
                            }>
                                disabled={!data}
                                readOnly={!data}
                                fields={{
                                    name: { type: 'text' },
                                    manufacturer: { type: 'text' },
                                    sku: { type: 'text' },
                                }}
                                initialValues={scanbody}
                                onSubmit={({ name, manufacturer, sku }) =>
                                    dispatchAction(
                                        updateScanbody({
                                            variables: {
                                                data: {
                                                    name,
                                                    manufacturer,
                                                    sku,
                                                    id: scanbody.id,
                                                },
                                            },
                                        }),
                                    )
                                }
                            />
                        )}
                    </LoadBlocker>
                </Grid>

                <Grid container item xs={3}>
                    <Grid container direction={'column'} spacing={1}>
                        <Grid item>
                            <ArchiveAndRestore
                                objectName={'scan body'}
                                isArchived={scanbody.is_archived}
                                disabled={loading}
                                hidePrompt={scanbody.numberOfLinkedImplants === 0}
                                promptText={`There are currently ${
                                    scanbody.numberOfLinkedImplants ?? 0
                                } implant types linked to this scan body.`}
                                onRestore={() =>
                                    dispatchAction(
                                        restoreScanbody({
                                            variables: {
                                                data: {
                                                    id: scanbody.id,
                                                },
                                            },
                                        }),
                                    )
                                }
                                onDelete={() =>
                                    dispatchAction(
                                        deleteScanbody({
                                            variables: {
                                                data: {
                                                    id: scanbody.id,
                                                },
                                            },
                                        }),
                                    )
                                }
                            />
                        </Grid>
                        <ScanbodyImageFileInfo scanbody={scanbody} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export const scanbodiesToManufacturers = (
    scanbodies: LabsGqlScanbodyFragment[],
): { value: string; label: string }[] => {
    return _(scanbodies)
        .map(s => s.manufacturer)
        .uniq()
        .sort()
        .map(option => ({
            label: option,
            value: option,
        }))
        .value();
};

const ScanbodiesTableContent: React.FC = () => {
    const { error: scanbodiesError, data, refetch: refetchScanbodies } = useScanbodies();
    const [isCreateScanbodyOpen, setCreateScanbodyOpen] = React.useState(false);
    const [rawCreateScanbody] = useCreateScanbodyMutation();
    const { submit: createScanbody, submitting: creatingScanbody } = useChangeSubmissionFn<any, any>(
        data => rawCreateScanbody({ variables: { data } }),
        {
            closeOnComplete: true,
            successMessage: () => ['Scan body successfully created!', {}],
            onSuccess: async () => {
                setCreateScanbodyOpen(false);
                await refetchScanbodies();
            },
        },
    );

    const manufacturers = React.useMemo(() => scanbodiesToManufacturers(data?.getScanbodies ?? []), [data]);
    const error = scanbodiesError;

    return (
        <Grid container style={{ padding: 16 }}>
            {error && (
                <ActionCard
                    variant={'alert'}
                    title={'Failed to retrieve scan bodies'}
                    subtitle={'Please try again later'}
                />
            )}
            <MUITable<LabsGqlScanbodyFragment>
                title={'Scan bodies'}
                data={data?.getScanbodies ?? []}
                loading={!data && !error}
                DetailPanel={ScanbodyRow}
                rowOptions={{ rowHover: true }}
                displayOptions={{
                    elevation: 0,
                    sort: true,
                    filter: true,
                }}
                actions={{
                    global: [
                        {
                            icon: 'refresh',
                            position: 'toolbar',
                            tooltip: 'Reload scan bodies',
                            onClick: () => refetchScanbodies(),
                        },
                        {
                            icon: 'add',
                            position: 'toolbar',
                            tooltip: 'Create scan body',
                            onClick: () => setCreateScanbodyOpen(true),
                        },
                    ],
                }}
                eventHooks={{
                    onRowClick: (row, actions) => actions.toggleDetailPanel(row),
                }}
                columns={[
                    { name: 'Name', render: 'name' },
                    { name: 'Manufacturer', render: 'manufacturer' },
                    { name: 'SKU', render: 'sku' },
                    { name: 'Number of implants', render: 'numberOfLinkedImplants', filter: false },
                    {
                        name: 'Archived',
                        render: 'is_archived',
                        type: 'boolean',
                        filterOptions: { defaultValues: ['false'], exact: false, type: 'dropdown' },
                    },
                ]}
            />
            <RootActionDialog
                title={'Create Scan body'}
                open={isCreateScanbodyOpen}
                setOpen={setCreateScanbodyOpen}
                loading={creatingScanbody}
                hideButton={true}
                content={
                    <QuickForm<
                        Omit<
                            LabsGqlScanbodyFragment,
                            | 'is_archived'
                            | 'created_at'
                            | 'updated_at'
                            | 'id'
                            | 'implantToScanbodies'
                            | 'numberOfLinkedImplants'
                        >
                    >
                        fields={{
                            name: { type: 'text' },
                            manufacturer: {
                                type: 'autocomplete',
                                options: manufacturers,
                                freeSolo: true,
                                cleanValue: cleanStringSpaces,
                            },
                            sku: { type: 'text' },
                        }}
                        initialValues={{ name: '', manufacturer: '', sku: '' }}
                        onSubmit={createScanbody}
                    />
                }
            />
        </Grid>
    );
};

export const ScanbodiesRoot: React.FC = () => {
    return (
        <ScanbodiesProvider>
            <ScanbodiesTableContent />
        </ScanbodiesProvider>
    );
};
