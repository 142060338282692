import { SimpleDialog } from '../SimpleDialog';
import { SimpleInput } from '../SimpleForm/SimpleInput';
import { FormControlLabel, Radio, RadioGroupPrimitive, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

interface SaveViewModalContentProps {
    onClose: () => void;
    editedTitle: string;
    viewTitle: string;
    onSave: () => void;
    setEditedTitle: (title: string) => void;
    isSaved: boolean;
    newPublicValue: boolean;
    viewIsPublic: boolean;
    setNewPublicValue: (isPublic: boolean) => void;
}

export const SaveCustomViewModal: React.FC<SaveViewModalContentProps> = props => {
    const {
        onClose,
        editedTitle,
        viewTitle,
        onSave,
        setEditedTitle,
        isSaved,
        newPublicValue,
        viewIsPublic,
        setNewPublicValue,
    } = props;
    return (
        <SimpleDialog
            open={true}
            onClose={onClose}
            title={isSaved ? 'Update saved search' : 'Name your saved search'}
            actions={
                <>
                    <Button variant={'secondary'} onClick={onClose} style={{ marginRight: 8 }}>
                        Cancel
                    </Button>
                    <Button
                        disabled={
                            (editedTitle.length === 0 || editedTitle === viewTitle) && viewIsPublic === newPublicValue
                        }
                        variant={'primary'}
                        onClick={() => onSave()}
                    >
                        OK
                    </Button>
                </>
            }
        >
            <SimpleInput
                TextFieldProps={{
                    autoFocus: true,
                    // select the text when its focused
                    onFocus: e => e.currentTarget.setSelectionRange(0, editedTitle.length, 'backward'),
                    onKeyDown: event =>
                        event.key === 'Enter' && editedTitle.length > 3 && viewTitle !== editedTitle && onSave(),
                }}
                fullWidth
                value={editedTitle}
                onChange={v => setEditedTitle(v ?? '')}
                label={'Name'}
            />
            <Text variant={'body1'} style={{ marginTop: 16 }}>
                Who should it be visible for?
            </Text>
            <RadioGroupPrimitive
                row
                name={'visibility'}
                value={newPublicValue ? 'true' : 'false'}
                onChange={(_e, val) => setNewPublicValue(val === 'true')}
            >
                <FormControlLabel
                    value={'false'}
                    control={<Radio color={'secondary'} style={{ marginLeft: 0 }} />}
                    label={'Just me'}
                />
                <FormControlLabel value={'true'} control={<Radio color={'secondary'} />} label={'Everyone'} />
            </RadioGroupPrimitive>
        </SimpleDialog>
    );
};
