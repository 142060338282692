import React from 'react';

/**
 * delays changes across a callback, only propagating new values on the trailing edge of delay
 *
 * this technique is often used to bridge between a
 * - text input field where keyboard input needs to be reflected immediately and a
 * - poorly architected redux store where changes cause widespread re-rendering
 *
 * @param debouncedValue upstream value, may be out of date
 * @param setDebouncedValue upstream value setter function, may be a cpu-expensive
 * @param delay the time to wait before propagating updates upstream
 * @returns value most up to date value, suitable to use as input field's value
 * @returns setValue efficient value setter function, suitable to use in an input field's onChange listener
 *
 * @example
 * const EditComponent = () => {
 *     const upstreamValue = useInefficientReduxStoreSelector (s => s.value)
 *     const expensivelySetUpstreamValue = useInefficientReduxStoreAction(`SET_VALUE`)
 *
 *     const [value, setValue] = useDebouncedValue(upstreamValue, expensivelySetUpstreamValue, 200)
 *
 *     return (
 *         <input value={value} onChange={ev => setValue(ev.target.value)}/>
 *     )
 * }
 */
export const useDebouncedState = <T extends unknown>(
    debouncedValue: T,
    setDebouncedValue: (value: T) => unknown,
    delay: number,
): [value: T, setValue: (value: T) => unknown] => {
    const [value, setValue] = React.useState(debouncedValue);

    // if the upstream value changes independently, update downstream accordingly
    React.useEffect(() => {
        setValue(debouncedValue);
    }, [debouncedValue, setValue]);

    // if the downstream value changes, queue an update for the upstream value to occur after delay
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            if (debouncedValue !== value) {
                setDebouncedValue(value);
            }
        }, delay);

        return () => {
            clearTimeout(timeout);
        };
    }, [value, debouncedValue, setDebouncedValue, delay]);

    return [value, setValue];
};
