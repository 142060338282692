import type { WithClassesProp } from '../../util/floss.types';
import { formatDataTestTitle } from '../../util/format-data-test-title';
import { useMergedMuiClasses } from '../../util/mergeMuiClasses';
import { stylesFactory } from '../../util/stylesFactory';
import { ToolbarContainer } from '../Containers/ToolbarContainer';
import { SidebarContainer } from '../Sidebar/SidebarContainer';
import type {
    SubNavigationEntry,
    SubNavigationCollapseEntry,
    SubNavigationWithSubtitleEntry,
} from './SubNavigation.types';
import { SubNavigationGroup } from './SubNavigationGroup';
import { SubNavigationGroupCollapse } from './SubNavigationGroupCollapse';
import { List, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

export type SubNavigationClassKey = 'root' | 'list' | 'bottomContainer' | 'subtitle';

const useStyles = stylesFactory<{}, SubNavigationClassKey>(() => ({
    root: { padding: 0, flexWrap: 'nowrap' },
    list: { width: '100%', overflow: 'scroll' },
    bottomContainer: { position: 'absolute', bottom: 0, padding: 24 },
    subtitle: { padding: `8px 24px` },
}));

export type SubNavigationSectionsProp<V extends string = string> = (
    | SubNavigationEntry<V>[]
    | SubNavigationCollapseEntry<V>
    | SubNavigationWithSubtitleEntry<V>
)[];

interface SubNavigationProps<V extends string = string> extends WithClassesProp<SubNavigationClassKey> {
    title: string;
    subtitle?: string;
    titleAction?: React.ReactNode;
    value: V;
    onChange: (value: V) => void;
    sections: SubNavigationSectionsProp<V>;
    bottomContent?: React.ReactNode;
    collapseOnMobile?: boolean;
}

export function SubNavigation<V extends string = string>(props: SubNavigationProps<V>): React.ReactElement | null {
    const { title, subtitle, value, onChange, sections, bottomContent, titleAction = null } = props;
    const classes = useMergedMuiClasses<SubNavigationClassKey>(useStyles(), props.classes);
    const bottomContentRef = React.useRef<HTMLDivElement | null>(null);

    return (
        <SidebarContainer position={'left'} className={classes.root}>
            <ToolbarContainer style={{ justifyContent: 'space-between' }}>
                <Text
                    style={{ lineHeight: 1 }}
                    variant={'h5'}
                    data-test={`sub-navigation-${formatDataTestTitle(title)}`}
                >
                    {title}
                </Text>
                {titleAction}
            </ToolbarContainer>
            <List
                className={classes.list}
                style={{ paddingBottom: bottomContentRef?.current ? bottomContentRef.current.clientHeight : 0 }}
            >
                {subtitle && (
                    <Grid className={classes.subtitle}>
                        <Text variant={'caption'} color={'DARK_GRAY'} style={{ fontWeight: 600 }}>
                            {subtitle}
                        </Text>
                    </Grid>
                )}
                {sections.map((section, idx) =>
                    Array.isArray(section) || 'subtitle' in section ? (
                        <SubNavigationGroup<V>
                            key={idx}
                            value={value}
                            onChange={onChange}
                            items={'subtitle' in section ? section.entries : section}
                            isFirstGroup={idx === 0}
                            subtitle={'subtitle' in section ? section.subtitle : ''}
                            collapseOnMobile
                        />
                    ) : (
                        <SubNavigationGroupCollapse<V>
                            key={idx}
                            label={section.label}
                            entries={section.entries}
                            value={value}
                            onChange={onChange}
                            isFirstGroup={idx === 0}
                        />
                    ),
                )}
            </List>
            {bottomContent !== undefined && (
                <Grid container className={classes.bottomContainer} ref={bottomContentRef}>
                    {bottomContent}
                </Grid>
            )}
        </SidebarContainer>
    );
}
