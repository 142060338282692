import type { OrderDetailSidebarTabPieces } from '../../OrderDetail.types';
import { useOrderDetailContext } from '../../state/OrderDetailProvider.graphql';
import { useChatMessages, useTimelineChatItems } from './OrderDetailChat.hooks';
import { getChatMessageVisibilityForWrite } from '@orthly/chat';
import { TimelineItemsDocument, ListChatMessagesDocument } from '@orthly/graphql-react';
import { LabsGqlStaffRoleWithAny, LabsGqlChatEntityTypes } from '@orthly/graphql-schema';
import { IOrganizationType } from '@orthly/retainer-common';
import { useSession } from '@orthly/session-client';
import { FilterIcon, LoadBlocker, SimpleCheckbox } from '@orthly/ui';
import { Grid, IconButton, Menu, MenuItem, Tooltip, Icon } from '@orthly/ui-primitives';
import {
    OrderDetailChatAddAttachment,
    DandyChatWithConversation,
    useUpdateNotifier,
    UpdateNotifierSignal,
    DandyChatFileDrop,
    useOrderChatAttachmentFileControls,
    OrderDetailChatAddScreenshot,
} from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

enum TimelineFilterEnum {
    All = 'all',
    Events = 'events',
    Chat = 'chat',
}

function getMessageVisibility(senderOrgType: IOrganizationType, recipientOrgType: IOrganizationType) {
    switch (recipientOrgType) {
        case IOrganizationType.lab:
            return getChatMessageVisibilityForWrite<LabsGqlStaffRoleWithAny>(senderOrgType, [
                LabsGqlStaffRoleWithAny.LabAny,
            ]);
        case IOrganizationType.practice:
            return getChatMessageVisibilityForWrite<LabsGqlStaffRoleWithAny>(senderOrgType, [
                LabsGqlStaffRoleWithAny.PracticeAny,
            ]);
        default:
            return getChatMessageVisibilityForWrite<LabsGqlStaffRoleWithAny>(senderOrgType);
    }
}

function visibilityToSimpleConvo(visibility: IOrganizationType[]) {
    if (visibility.includes(IOrganizationType.lab)) {
        return IOrganizationType.lab;
    }
    if (visibility.includes(IOrganizationType.practice)) {
        return IOrganizationType.practice;
    }
    return IOrganizationType.internal;
}

interface ChatHeaderProps {
    view: TimelineFilterEnum;
    setView: (val: TimelineFilterEnum) => void;
    loading: boolean;
    onClickRefresh: () => Promise<any>;
}

const ChatHeader: React.FC<ChatHeaderProps> = props => {
    const { view, setView, loading, onClickRefresh } = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const onClickFilter = (view: TimelineFilterEnum) => () => {
        setView(view);
        setAnchorEl(null);
    };
    const [refreshing, setRefreshing] = React.useState<boolean>(false);
    const refresh = React.useCallback(() => {
        setRefreshing(true);
        onClickRefresh()
            .catch(console.error)
            .finally(() => {
                setRefreshing(false);
            });
    }, [onClickRefresh]);
    return (
        <Grid item container style={{ flexDirection: 'row', justifyContent: 'flex-end' }} xs={2} wrap={'nowrap'}>
            <Grid item style={{ width: 'fit-content' }}>
                <IconButton size={'small'} onClick={e => setAnchorEl(e.currentTarget)}>
                    <Tooltip title={'Filter Timeline'}>
                        <FilterIcon />
                    </Tooltip>
                </IconButton>
                <Menu
                    onClose={() => setAnchorEl(null)}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    keepMounted
                >
                    {Object.values(TimelineFilterEnum).map(filter => (
                        <MenuItem key={filter} selected={view === filter} onClick={onClickFilter(filter)}>
                            {_.startCase(filter)}
                        </MenuItem>
                    ))}
                </Menu>
            </Grid>
            <LoadBlocker blocking={loading || refreshing} ContainerProps={{ style: { width: 'auto' } }}>
                <IconButton size={'small'} onClick={refresh} disabled={loading || refreshing}>
                    <Tooltip title={'Refresh Timeline'}>
                        <Icon icon={'RefreshIcon'} />
                    </Tooltip>
                </IconButton>
            </LoadBlocker>
        </Grid>
    );
};

export function useOrderChatSidebarParts(): OrderDetailSidebarTabPieces {
    const { order, loading, refetch, id: orderId } = useOrderDetailContext();
    const session = useSession();
    const senderOrgType: IOrganizationType = session?.organization_type ?? 'internal';
    const staffId = session?.id ?? '';
    const [view, setView] = React.useState<TimelineFilterEnum>(TimelineFilterEnum.All);
    const timelineQuery = useTimelineChatItems(orderId, order?.updated_at);
    const {
        data: messages,
        refetch: refetchChat,
        addChatFn: { submit, submitting },
    } = useChatMessages({ orderId, order, staffId });
    const [shouldSendNotification, setShouldSendNotification] = React.useState<boolean>(false);

    const onAddChat = React.useCallback(
        async (text: string, role: IOrganizationType) => {
            if (
                role === IOrganizationType.practice &&
                !window.confirm('Are you sure you want to send a portal chat instead of unified chat?')
            ) {
                return;
            }
            await submit({
                text,
                entity_type: LabsGqlChatEntityTypes.Order,
                entity_id: orderId,
                should_send_notification: shouldSendNotification && role === IOrganizationType.practice,
                visible_to_roles: getMessageVisibility(senderOrgType, role),
                attachment_urls: [],
            });
        },
        [orderId, senderOrgType, submit, shouldSendNotification],
    );
    const chatProps = React.useMemo(
        () => ({
            messages: view !== TimelineFilterEnum.Events ? messages : [],
            events: view !== TimelineFilterEnum.Chat ? timelineQuery.chatTimelineItems : [],
        }),
        [messages, timelineQuery.chatTimelineItems, view],
    );
    const initialConvo = visibilityToSimpleConvo(messages[messages.length - 1]?.attachmentVisibility ?? []);
    const chatCount = messages.length ?? 0;
    const { setAttachmentDialogOpen, attachmentDialogOpen, pastedFiles, setPastedFiles } =
        useOrderChatAttachmentFileControls();

    const {
        attachmentDialogOpen: screenshotDialogOpen,
        setAttachmentDialogOpen: setScreenshotDialogOpen,
        pastedFiles: screenshotPastedFiles,
    } = useOrderChatAttachmentFileControls();

    useUpdateNotifier(
        UpdateNotifierSignal.onUpdate('ChatMessage')
            .via('LabOrder', orderId)
            .refetch([TimelineItemsDocument, ListChatMessagesDocument]),
    );

    return {
        name: 'Chat',
        badgeCount: chatCount,
        headerExtras: (
            <ChatHeader
                view={view}
                setView={setView}
                loading={loading || timelineQuery.loading}
                onClickRefresh={() => Promise.all([timelineQuery.refetch(), refetch(), refetchChat()])}
            />
        ),
        sidebarBody: (
            <DandyChatFileDrop onFilesDropped={setPastedFiles} disabled={attachmentDialogOpen}>
                <DandyChatWithConversation<IOrganizationType>
                    chatProps={{
                        ...chatProps,
                        submitting,
                        currentStaffId: staffId,
                        highlight_since: null,
                        lab_id: order?.manufacturer_id ?? '',
                        practice_id: order?.partner_id ?? '',
                        onFilePaste: setPastedFiles,
                        orderId: order?.id ?? null,
                    }}
                    buttonProps={{
                        convoKeysEnum: IOrganizationType,
                        ExpandedChatAction: ({ currentConvo }) => {
                            if (currentConvo !== IOrganizationType.practice) {
                                return null;
                            }

                            return (
                                <>
                                    <SimpleCheckbox
                                        checked={shouldSendNotification}
                                        setChecked={() => setShouldSendNotification(!shouldSendNotification)}
                                        label={'Notify practice'}
                                    />
                                </>
                            );
                        },
                    }}
                    onAddChat={onAddChat}
                    initialConvo={initialConvo ?? IOrganizationType.internal}
                    chatActions={
                        order && !order?.refabrication_order_id ? (
                            <div>
                                <OrderDetailChatAddScreenshot
                                    orderId={orderId}
                                    onAttached={refetchChat}
                                    initialFiles={screenshotPastedFiles}
                                    controls={{ open: screenshotDialogOpen, setOpen: setScreenshotDialogOpen }}
                                    senderOrgType={senderOrgType}
                                />
                                <OrderDetailChatAddAttachment
                                    orderId={orderId}
                                    onAttached={refetchChat}
                                    initialFiles={pastedFiles}
                                    controls={{ open: attachmentDialogOpen, setOpen: setAttachmentDialogOpen }}
                                    senderOrgType={senderOrgType}
                                />
                            </div>
                        ) : undefined
                    }
                />
            </DandyChatFileDrop>
        ),
    };
}
