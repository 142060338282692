import type { IBaseItemData } from '../../types';
import { getHeaderForLabSlipItem } from '../../utils/shared.utils';
import { BaseItem } from './BaseItem';
import type { ISleepApneaItem } from '@orthly/items';
import React from 'react';

/**
 * Renders a "SleepApnea" order item to display on
 * the Printable Slip
 * @param props The order item to render
 * @returns Rendered order item details for the order "SleepApnea" item
 */
export const SleepApneaItem: React.VFC<{ item: ISleepApneaItem }> = ({ item }) => {
    return <BaseItem itemData={mapSleepApneaItemToPrintableSlipData(item)} />;
};

/**
 * Pull data needed for printable slip display into a standard data structure
 * @param item ISleepApneaItem order item to display
 * @returns Structured data for SleepApnea item display
 */
function mapSleepApneaItemToPrintableSlipData(item: ISleepApneaItem): IBaseItemData {
    return {
        headerLeft: getHeaderForLabSlipItem(item),
        itemFields: [
            {
                key: 'Material',
                value: item.sleep_apnea_type,
            },
        ],
    };
}
