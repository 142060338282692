import { HIDE_POPUP_MODAL_KEY } from '../VisualConstants.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction } from '../state/GuidedWaxupState';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { RootActionDialog } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    Text,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    CheckboxPrimitive,
    FormControlLabel,
    Grid,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory<{ isMobile: boolean }>(() => ({
    bodyText: {
        marginBottom: 40,
    },
    bottomRowContainer: {
        justifyContent: 'space-between',
        padding: '8px 0px 8px 0px',
        flexDirection: ({ isMobile }) => (isMobile ? 'column-reverse' : 'row'),
    },
    checkbox: {
        borderRadius: 8,
    },
    buttonsContainer: {
        display: 'flex',
        gap: '8px',
        flexDirection: ({ isMobile }) => (isMobile ? 'column-reverse' : 'row'),
    },
    button: {
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        width: '100%',
    },
    mobileCheckbox: {
        marginTop: 8,
        width: '100%',
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        backgroundColor: FlossPalette.TAN,
        borderRadius: 8,
    },
}));

export const SkipToGeneralReviewModal: React.VFC = () => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const classes = useStyles({ isMobile });
    const skipToGeneralView = useGuidedWaxupAction('SKIP_TO_GENERAL_VIEW');
    const { isSkipToGeneralViewModalOpen, setIsSkipToGeneralViewModalOpen, setSelectedTab } = useGuidedWaxupContext();
    const [checked, setChecked] = React.useState<boolean>(false);
    return (
        <RootActionDialog
            loading={false}
            open={isSkipToGeneralViewModalOpen}
            setOpen={setIsSkipToGeneralViewModalOpen}
            title={'Are you sure you want to skip the recommended guided review steps?'}
            CustomButton={() => null}
            content={
                <Grid container>
                    <Grid item className={classes.bodyText}>
                        <Text variant={'body2'}>
                            Guided review is a way for the designer to receive direction from you.
                        </Text>
                    </Grid>
                    <Grid item container className={classes.bottomRowContainer}>
                        <Grid item className={isMobile ? classes.mobileCheckbox : undefined}>
                            <FormControlLabel
                                control={
                                    <CheckboxPrimitive
                                        color={'secondary'}
                                        checked={checked}
                                        onChange={() => {
                                            setChecked(!checked);
                                        }}
                                        className={classes.checkbox}
                                    />
                                }
                                label={
                                    <Text variant={'body2'} medium={isMobile}>
                                        Don't show this again
                                    </Text>
                                }
                            />
                        </Grid>
                        <Grid item className={classes.buttonsContainer}>
                            <Grid item>
                                <Button
                                    variant={'secondary-gray'}
                                    className={classes.button}
                                    onClick={() => {
                                        if (checked) {
                                            localStorage.setItem(HIDE_POPUP_MODAL_KEY, 'true');
                                        }
                                        setIsSkipToGeneralViewModalOpen(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant={'primary'}
                                    className={classes.button}
                                    onClick={() => {
                                        if (checked) {
                                            localStorage.setItem(HIDE_POPUP_MODAL_KEY, 'true');
                                        }
                                        setIsSkipToGeneralViewModalOpen(false);
                                        skipToGeneralView({});
                                        setSelectedTab(LabsGqlGuidedWaxupPresetType.GeneralView);
                                    }}
                                >
                                    Yes, Skip to Submit Review
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        />
    );
};
