import { statusToStringMap } from '../ModelViewerFooterBannerHelper';
import type { LabsGqlDesignOrderDoctorReviewStatus } from '@orthly/graphql-schema';
import type { TextColor } from '@orthly/ui-primitives';
import { Text } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';

export const DesignVersionTitle: React.VFC<{
    selectedDesignRevisionAlreadyReviewed: boolean;
    selectedDesignRevisionStatus?: LabsGqlDesignOrderDoctorReviewStatus;
    selectedDesignRevisionCreatedAtDate?: string;
    color?: TextColor;
}> = ({
    selectedDesignRevisionAlreadyReviewed,
    selectedDesignRevisionStatus,
    selectedDesignRevisionCreatedAtDate,
    color,
}) => {
    const formattedReviewDate = selectedDesignRevisionCreatedAtDate
        ? dayjs(selectedDesignRevisionCreatedAtDate).format('MMM D, h:mma')
        : '';
    const formattedStatus = selectedDesignRevisionStatus && statusToStringMap[selectedDesignRevisionStatus];
    return (
        <Text variant={'body2'} color={color}>
            <strong>{selectedDesignRevisionAlreadyReviewed ? 'Previous Version: ' : 'Current Design: '}</strong>
            {selectedDesignRevisionAlreadyReviewed ? `${formattedStatus} ${formattedReviewDate}` : 'Pending Review'}
        </Text>
    );
};
