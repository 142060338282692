import { ItemEditorV2Utils } from '../../ItemEditorV2';
import type { ICustomFieldSubmission, ICartItemV2DTOWithId } from '@orthly/items';
import { ItemDataFieldUtils, ItemMetafieldV2Utils } from '@orthly/items';
import _ from 'lodash';

/** Copied from Scanner's `enamel-rpc-types`, CaseSetupItemUtils */
function findFirstMetafieldEntry(items: ICartItemV2DTOWithId[], field_id: string): ICustomFieldSubmission | undefined {
    for (const item of items) {
        const matchingField = item.preference_fields.find(pref => pref.field_id === field_id);
        if (matchingField) {
            return matchingField;
        }
    }
    return undefined;
}

export function getGroupPreferences(group: ICartItemV2DTOWithId[]): { key: string; value: string }[] {
    const metafields = group
        .flatMap(item => ItemMetafieldV2Utils.getMetafieldsForItem(item))
        .filter(
            field =>
                !ItemDataFieldUtils.allReplacedMetafieldIds.includes(field.id) &&
                !ItemEditorV2Utils.IGNORED_METAFIELD_IDS.includes(field.id),
        );
    const results: { key: string; value: string }[] = _.compact(
        metafields.map(field => {
            const value = findFirstMetafieldEntry(group, field.id)?.value;
            // if they have no selection, we return undefined
            // because the results of this hook will be overridden by an incomplete warning
            if (!value) {
                return undefined;
            }

            const getValueLabel = () => {
                if (field.type === 'boolean') {
                    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                    // eslint-disable-next-line no-nested-ternary
                    return _.isBoolean(value) ? (value ? 'Yes' : 'No') : undefined;
                }
                return field.options?.find(opt => opt.value === value)?.label;
            };
            const valueLabel = !_.isNil(value) ? getValueLabel() : undefined;
            if (!valueLabel) {
                return undefined;
            }

            return { key: field.name, value: valueLabel };
        }),
    );

    return results;
}
