import { useInboxState } from '../../screens/Inbox/state/Inbox.selectors';
import { LabsGqlFilterComparator } from '@orthly/graphql-schema';
import type { SimpleSelectProps } from '@orthly/ui';
import { FlossPalette, IconButton, CloseIcon } from '@orthly/ui-primitives';
import React from 'react';

export const DateComparators: LabsGqlFilterComparator[] = [
    LabsGqlFilterComparator.Gte,
    LabsGqlFilterComparator.Gt,
    LabsGqlFilterComparator.Lte,
    LabsGqlFilterComparator.Lt,
    LabsGqlFilterComparator.Equals,
    LabsGqlFilterComparator.NotEquals,
];

export function useSelectDisplayProps(): Pick<SimpleSelectProps, 'value' | 'options'> {
    const tasksFilter = useInboxState(s => s.view?.tasksFilter);
    const filters = [];

    if (tasksFilter?.type && tasksFilter.type.length > 0) {
        filters.push('Type');
    }
    if (tasksFilter?.assigned_user_ids && tasksFilter.assigned_user_ids.length > 0) {
        filters.push('Assignees');
    }
    if (tasksFilter?.created_at?.comparison_value && tasksFilter?.created_at?.comparator) {
        filters.push('Created At');
    }
    if (tasksFilter?.completed_at?.comparison_value && tasksFilter?.completed_at?.comparator) {
        filters.push('Completed At');
    }
    if (typeof tasksFilter?.completed === 'boolean') {
        filters.push('Completed');
    }
    if (tasksFilter?.assigned) {
        filters.push('Assigned');
    }
    if (tasksFilter?.completed_by_ids) {
        filters.push('Completed By');
    }
    if (typeof tasksFilter?.design_review_required === 'boolean') {
        filters.push('Review Type');
    }
    const title = filters.join(', ');
    return { value: title, options: [{ value: title }] };
}

export const ClearFilterButton: React.FC<{ className?: string; onClear: () => void }> = props => {
    const { onClear, className } = props;
    return (
        <IconButton
            className={className}
            // the styles from the className for the mui select icon specifies cursor events none
            style={{ pointerEvents: 'all' }}
            onClick={e => {
                e.stopPropagation();
                onClear();
            }}
            size={'small'}
        >
            <CloseIcon style={{ color: FlossPalette.STAR_GRASS }} />
        </IconButton>
    );
};
