import { useOpsInboxAction } from '../../screens/Inbox/state/Inbox.actions';
import { useInboxState } from '../../screens/Inbox/state/Inbox.selectors';
import { useInboxTasks } from '../../screens/Inbox/state/InboxTasksProvider';
import type { SimpleMenuPropsItem } from '@orthly/ui';
import { SimpleMenu } from '@orthly/ui';
import { FlossPalette, stylesFactory, Text, CheckboxPrimitive as Checkbox, Grid, Tooltip } from '@orthly/ui-primitives';
import { AssignTasksBulkButton } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        borderLeft: `1px solid ${FlossPalette.DARK_TAN}`,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        width: '10%',
    },
}));

const BulkSelectCheckbox: React.FC = () => {
    const { tasks } = useInboxTasks();
    const setSelectedTaskIds = useOpsInboxAction('SET_SELECTED_TASK_IDS');
    const selectedTaskIds = useInboxState(s => s.selectedTaskIds);
    const incompleteTaskIds = React.useMemo(() => tasks.flatMap(t => (t.closeout ? [] : [t.id])), [tasks]);
    const checked = _.difference(selectedTaskIds, incompleteTaskIds).length === 0 && selectedTaskIds.length > 0;
    const menuItems = React.useMemo<SimpleMenuPropsItem[]>(() => {
        const assignees = _.compact(tasks.map(t => t.assignee));
        const generateMenuItem = (label: string, taskIds: string[]): SimpleMenuPropsItem => ({
            label: `${label} (${taskIds.length})`,
            onClick: setClosed => {
                setSelectedTaskIds(taskIds);
                setClosed();
            },
        });
        const assigneeMenuItems = _.uniqBy(assignees, a => a.assigned_user.id).map<SimpleMenuPropsItem>(a => {
            const taskIdsForAssignee = tasks.flatMap<string>(t =>
                t.assignee?.assigned_user.id === a.assigned_user.id ? [t.id] : [],
            );
            return generateMenuItem(
                `Assigned to ${a.assigned_user.first_name} ${a.assigned_user.last_name}`,
                taskIdsForAssignee,
            );
        });
        if (assigneeMenuItems.length === 0) {
            return [];
        }
        const unassignedTaskIds = _.compact(tasks.map(t => (!t.assignee && !t.closeout ? t.id : undefined)));
        // no need for special selection params, all tasks assigned to same user
        if (assigneeMenuItems.length === 1 && unassignedTaskIds.length === 0) {
            return [];
        }
        const unassignedMenuItem: SimpleMenuPropsItem | null =
            unassignedTaskIds.length === 0 ? null : generateMenuItem(`All unassigned`, unassignedTaskIds);
        return [
            generateMenuItem(`Select all`, incompleteTaskIds),
            ...(unassignedMenuItem ? [unassignedMenuItem] : []),
            ...assigneeMenuItems,
        ];
    }, [incompleteTaskIds, setSelectedTaskIds, tasks]);
    return (
        <>
            <SimpleMenu
                ButtonComponent={({ onClick: menuOnClick }) => (
                    <Checkbox
                        color={'secondary'}
                        style={{ margin: 5 }}
                        disabled={incompleteTaskIds.length === 0}
                        checked={checked}
                        onChange={(event, isChecked) => {
                            if (!isChecked) {
                                return setSelectedTaskIds([]);
                            }
                            if (menuItems.length === 0) {
                                return setSelectedTaskIds(incompleteTaskIds);
                            }
                            menuOnClick(event);
                        }}
                    />
                )}
                items={menuItems}
            />
        </>
    );
};

export const InboxListBulkActions: React.FC = () => {
    const classes = useStyles();
    const { tasks, refetch } = useInboxTasks();
    const selectedTaskIds = useInboxState(s => s.selectedTaskIds);
    const setSelectedTaskIds = useOpsInboxAction('SET_SELECTED_TASK_IDS');
    const incompleteTaskIds = React.useMemo(() => tasks.flatMap(t => (t.closeout ? [] : [t.id])), [tasks]);
    return (
        <Tooltip arrow title={'No active tasks in list'} disableHoverListener={incompleteTaskIds.length > 0}>
            <Grid container className={classes.root} alignContent={'center'}>
                <Grid container alignItems={'center'} justifyContent={'center'}>
                    <AssignTasksBulkButton
                        refetch={refetch}
                        tasks={tasks.filter(t => selectedTaskIds.includes(t.id))}
                        setSelectedTaskIds={setSelectedTaskIds}
                    />
                    <BulkSelectCheckbox />
                </Grid>
                <Grid container justifyContent={'center'}>
                    <Text variant={'caption'} color={'DARK_GRAY'}>
                        {selectedTaskIds.length}/{incompleteTaskIds.length} selected
                    </Text>
                </Grid>
            </Grid>
        </Tooltip>
    );
};
