import { useFeatureFlag } from '../components';
import { pusherClient } from './usePusher';
import type { ApolloRefetchUtil } from '@orthly/graphql-react';
import { useApolloRefetch } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import type { UpdateNotifierEntityType, UpdateNotifierInternalMessage } from '@orthly/shared-types';
import { getUpdateNotifierInternalChannelName, UpdateNotifierInternalChannelType } from '@orthly/shared-types';
// eslint-disable-next-line no-restricted-imports
import type { DocumentNode } from 'graphql';
import React from 'react';

type RefetchQuery =
    | DocumentNode
    | {
          query: DocumentNode;
          variables: Record<string, unknown>;
      };

function isQueryDocumentOnly(query: RefetchQuery): query is DocumentNode {
    return !(query as any).variables;
}

const log = (msg: string, ...args: any[]) => console.log(`[UpdateNotifier] ${msg}`, ...args);

class UpdateNotifierSignalInternal {
    constructor(
        public readonly getChannelName: (session: { user: { id: string } }) => string,
        private readonly shouldProcessMessage: (message: UpdateNotifierInternalMessage) => boolean,
        private readonly queries: RefetchQuery[],
        private readonly hashKeys: any[],
    ) {}

    dispatch(refetch: ApolloRefetchUtil, message: UpdateNotifierInternalMessage) {
        // The client will subscribe to the channel attached to this signal, but it is the signal's responsibility
        // to perform message filtering. For example, a `ChatMessage` update event on the `LabOrder` channel should not
        // trigger refetching the order itself.
        if (!this.shouldProcessMessage(message)) {
            log(`Dropping UpdateSignal`, message);
            return;
        }

        log(`Processing UpdateSignal`, message);
        for (const query of this.queries) {
            if (isQueryDocumentOnly(query)) {
                refetch.query(query);
            } else {
                refetch.query(query.query, query.variables);
            }
        }
    }

    isEqual(signal: UpdateNotifierSignalInternal) {
        // Don't want to use lodash.isEqual here, because query objects from Apollo are actually
        // full ASTs, and since they are globally defined, it is enough to check if they are referentially
        // equal rather than having to check for deep equality.

        if (this.hashKeys.length !== signal.hashKeys.length) {
            return false;
        }

        for (const [idx, leftElm] of this.hashKeys.entries()) {
            // The lengths match, so the indices will always match
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            if (signal.hashKeys[idx]! !== leftElm) {
                return false;
            }
        }

        return true;
    }
}

interface UpdateNotifierSignalBuilder {
    via(entity: UpdateNotifierEntityType, id: string): Omit<UpdateNotifierSignalBuilder, 'via'>;
    refetch(queries: RefetchQuery[]): UpdateNotifierSignalInternal;
}

export const UpdateNotifierSignal = {
    onUpdate(entityName: UpdateNotifierEntityType, entityId?: string): UpdateNotifierSignalBuilder {
        return {
            via(parentEntity: UpdateNotifierEntityType, parentId: string) {
                const getChannelName = () =>
                    getUpdateNotifierInternalChannelName({
                        channelType: UpdateNotifierInternalChannelType.Entity,
                        typeName: parentEntity,
                        id: parentId,
                    });
                const shouldProcessMessage = (message: UpdateNotifierInternalMessage) => {
                    return message.typeName === entityName && (!entityId || entityId === message.id);
                };

                return {
                    refetch: (queries: RefetchQuery[]) =>
                        new UpdateNotifierSignalInternal(getChannelName, shouldProcessMessage, queries, [
                            entityName,
                            entityId,
                            parentEntity,
                            parentId,
                            ...queries,
                        ]),
                };
            },

            refetch(queries: RefetchQuery[]) {
                if (!entityId) {
                    return new UpdateNotifierSignalInternal(
                        session =>
                            getUpdateNotifierInternalChannelName({
                                channelType: UpdateNotifierInternalChannelType.User,
                                userId: session.user.id,
                            }),
                        message => message.typeName === entityName,
                        queries,
                        [entityName, ...queries],
                    );
                }

                return new UpdateNotifierSignalInternal(
                    () =>
                        getUpdateNotifierInternalChannelName({
                            channelType: UpdateNotifierInternalChannelType.Entity,
                            typeName: entityName,
                            id: entityId,
                        }),
                    message => message.typeName === entityName && entityId === message.id,
                    queries,
                    [entityName, entityId, ...queries],
                );
            },
        };
    },
};

export function useUpdateNotifier(signal: UpdateNotifierSignalInternal) {
    const refetch = useApolloRefetch();
    const session = useSession();

    const { value: isEnabled } = useFeatureFlag('enableUpdateNotifierSubscriber');
    React.useEffect(() => {
        log(`${isEnabled ? 'Enabling' : 'Disabling'} update notifier`);
    }, [isEnabled]);

    // Pusher logs quite a bit of info, such as channel events and incoming messages
    // We want to be able to control this via LD, so that we can turn it on to debug issues
    const { value: enablePusherBrowserDebugLogs } = useFeatureFlag('enablePusherBrowserDebugLogs');
    React.useEffect(() => {
        pusherClient.setLoggingEnabled(process.env.NODE_ENV === 'test' || !!enablePusherBrowserDebugLogs);
    }, [enablePusherBrowserDebugLogs]);

    // We use a stale ref as a copy of the given signal, so we can optimize channel subscriptions.
    // On unnecessary re-renders, the consuming component will rebuild the signal object, but the semantics
    // of which channel to listen to, and which messages to consume will not have changed. The signals are able
    // to identify if a second signal that is referentially different contains the same semantics, and therefore
    // we can act as a smart 'useMemo()'.
    const [activeSignal, setActiveSignal] = React.useState(signal);
    React.useEffect(() => {
        if (!activeSignal.isEqual(signal)) {
            setActiveSignal(signal);
        }
    }, [activeSignal, signal]);

    React.useEffect(() => {
        if (!session || !isEnabled) {
            return;
        }

        const chan = pusherClient.subscribe(
            activeSignal.getChannelName(session),
            async (message: UpdateNotifierInternalMessage) => activeSignal.dispatch(refetch, message),
        );
        return () => chan.unsubscribe();
    }, [refetch, session, activeSignal, isEnabled]);
}
