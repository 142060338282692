import { OrderDesignPrepTaskAlert } from './DesignSubmission/OrderDesignPrepTaskAlert';
import { OrderInjectionMoldDesignTaskAlert } from './DesignSubmission/OrderInjectionMoldDesignTaskAlert';
import { OrderInternalDesignTaskAlert } from './DesignSubmission/OrderInternalDesignTaskAlert.graphql';
import { OrderModelDesignTaskAlert } from './DesignSubmission/OrderModelDesignTaskAlert';
import { OrderAutomateReviewTaskAlert } from './OrderAutomateReviewTaskAlert';
import { OrderDesignReviewTaskAlert } from './OrderDesignReviewTaskAlert.graphql';
import { OrderExternalDesignVerificationTaskAlert } from './OrderExternalDesignVerificationTaskAlert';
import { OrderScanReviewAlert } from './OrderScanReviewAlert';
import { useQuery } from '@apollo/client';
import type {
    FragmentType,
    OrderDesignPreviewDesign_FragmentFragmentDoc,
    VeneerOrderDesignAnalyticsDesign_FragmentFragmentDoc,
} from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import React from 'react';

const AdminOrderActiveTaskActionDesignRevision_Query = graphql(`
    query AdminOrderActiveTaskActionDesignRevision_Query($orderId: String!) {
        getLatestDesignOrderDesignRevision(labOrderId: $orderId) {
            ...AdminOrderInternalDesignTaskAlertDesignSource_Fragment
            ...AdminOrderDesignSubmissionCommonInternalDesignPath_Fragment
        }
    }
`);

interface OrderActiveTaskActionProps {
    order: LabsGqlOrder;
    refetchOrder: () => Promise<unknown>;
    variant: 'sidebar' | 'detail_block';
    designFragment:
        | (FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc> &
              FragmentType<typeof VeneerOrderDesignAnalyticsDesign_FragmentFragmentDoc>)
        | undefined
        | null;
}

export const OrderActiveTaskAction: React.FC<OrderActiveTaskActionProps> = ({
    order,
    refetchOrder,
    variant,
    designFragment,
}) => {
    const activeTask = order.fulfillment_workflow.active_task;

    const { data: designRevisionData } = useQuery(AdminOrderActiveTaskActionDesignRevision_Query, {
        variables: {
            orderId: order.id,
        },
        skip: !activeTask,
    });

    const designRevision = designRevisionData?.getLatestDesignOrderDesignRevision ?? undefined;

    if (!activeTask) {
        return null;
    }
    switch (activeTask.type) {
        case LabsGqlWorkflowTaskType.InternalDesign:
            return (
                <OrderInternalDesignTaskAlert
                    order={order}
                    refetchOrder={refetchOrder}
                    variant={variant}
                    designRevision={designRevision}
                />
            );
        case LabsGqlWorkflowTaskType.InjectionMoldDesign:
            return (
                <OrderInjectionMoldDesignTaskAlert
                    order={order}
                    refetchOrder={refetchOrder}
                    variant={variant}
                    designRevision={designRevision}
                />
            );
        case LabsGqlWorkflowTaskType.ModelDesign:
            return (
                <OrderModelDesignTaskAlert
                    order={order}
                    refetchOrder={refetchOrder}
                    variant={variant}
                    designRevision={designRevision}
                />
            );
        case LabsGqlWorkflowTaskType.ExternalDesignVerification:
            return (
                <OrderExternalDesignVerificationTaskAlert order={order} refetchOrder={refetchOrder} variant={variant} />
            );
        case LabsGqlWorkflowTaskType.DesignPrep:
            return (
                <OrderDesignPrepTaskAlert
                    order={order}
                    refetchOrder={refetchOrder}
                    variant={variant}
                    designRevision={designRevision}
                />
            );
        case LabsGqlWorkflowTaskType.DesignReview:
        case LabsGqlWorkflowTaskType.DesignReview2:
            return (
                <OrderDesignReviewTaskAlert
                    order={order}
                    refetchOrder={refetchOrder}
                    variant={variant}
                    designFragment={designFragment}
                />
            );
        case LabsGqlWorkflowTaskType.ScanReview:
            return <OrderScanReviewAlert order={order} refetchOrder={refetchOrder} variant={variant} />;
        case LabsGqlWorkflowTaskType.AutomateReview:
            return <OrderAutomateReviewTaskAlert order={order} refetchOrder={refetchOrder} variant={variant} />;
        default:
            return null;
    }
};
