import type { PartnerBillingOverviewData } from '../../types';
import type { SourceSplitOption } from './usePartnerPaymentSplitSourceOptions.graphql';
import type { PaymentSplitEntityVars } from './useSubmitPaymentSplitConfig';
import { useAddressesForPartner, useCurrentDoctorPreferencesQuery } from '@orthly/graphql-react';
import type { FieldDefSelect, FieldsDefProp } from '@orthly/ui';
import { QuickForm } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

type EntitySplitType = 'location' | 'doctor';
export type GenericEntitySplitOption = { id: string; label: string };

function useEntitySplitFormInitialValues(
    splitType: EntitySplitType,
    currentConfig: PartnerBillingOverviewData['usage_payment_source_config'],
) {
    return React.useMemo<Record<string, string>>(() => {
        if (splitType === 'location' && currentConfig?.__typename === 'PaymentSplitConfigLocation') {
            const addressIdSourceId = currentConfig.splits.map<[string, string]>(s => [
                s.delivery_address_id,
                s.source_id,
            ]);
            return _.fromPairs(addressIdSourceId);
        }
        if (splitType === 'doctor' && currentConfig?.__typename === 'PaymentSplitConfigDoctor') {
            const doctorIdSourceId = currentConfig.splits.map<[string, string]>(s => [
                s.doctor_preferences_id,
                s.source_id,
            ]);
            return _.fromPairs(doctorIdSourceId);
        }
        return {};
    }, [currentConfig, splitType]);
}

function useEntityOptions(splitType: EntitySplitType, partner_id: string): GenericEntitySplitOption[] {
    const { addresses } = useAddressesForPartner(partner_id);
    const doctorsQuery = useCurrentDoctorPreferencesQuery({ variables: { partner_id }, fetchPolicy: 'cache-first' });
    return React.useMemo<GenericEntitySplitOption[]>(() => {
        const doctors = doctorsQuery.data?.preferences ?? [];
        if (splitType === 'doctor') {
            return doctors.map(entity => ({ id: entity.id, label: entity.name }));
        }
        return addresses.map(entity => ({ id: entity.id, label: [entity.street_one, entity.street_two].join(', ') }));
    }, [addresses, doctorsQuery.data, splitType]);
}

function useEntitySplitFormFields(entities: GenericEntitySplitOption[], sourceOptions: SourceSplitOption[]) {
    return React.useMemo<FieldsDefProp<{ [entityId: string]: string }>>(() => {
        const options = sourceOptions.map(({ label, id }) => ({ label, value: id }));
        const fieldCommon = { options, type: 'select' as const };
        const pairs = entities.map<[string, FieldDefSelect]>(entity => [
            entity.id,
            { ...fieldCommon, label: entity.label },
        ]);
        return _.fromPairs(pairs);
    }, [entities, sourceOptions]);
}

interface SplitPaymentsByEntityFormProps {
    practiceId: string;
    sourceOptions: SourceSplitOption[];
    splitType: EntitySplitType;
    onSubmit: (vars: PaymentSplitEntityVars) => Promise<unknown>;
    config: PartnerBillingOverviewData['usage_payment_source_config'];
}

export const PaymentSplitEntityForm: React.FC<SplitPaymentsByEntityFormProps> = ({
    onSubmit,
    practiceId,
    sourceOptions,
    splitType,
    config,
}) => {
    const entities = useEntityOptions(splitType, practiceId);
    const formFields = useEntitySplitFormFields(entities, sourceOptions);
    const initialValues = useEntitySplitFormInitialValues(splitType, config);
    return (
        <QuickForm<{ [entityId: string]: string }>
            dirtySubmitOnly={true}
            disabled={entities.length === 0}
            fields={formFields}
            resetOnInitialValueChange={true}
            onSubmit={async result => {
                const splits = _.entries(result).map(([entity_id, source_id]) => ({ source_id, entity_id }));
                await onSubmit({ splits, type: splitType });
            }}
            initialValues={{ ...initialValues }}
        />
    );
};
