import { useSceneState } from '../AppState.hooks';
import type { IconToggleButtonProps, IconName } from '@orthly/ui-primitives';
import {
    stylesFactory,
    FlossPalette,
    List,
    ListItem,
    ListItemText,
    Text,
    Icon,
    IconToggleButton,
} from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    menuItem: {
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    },
    insetLabel: {
        paddingLeft: 16,
    },
    iconButtonInner: {
        height: 16,
        width: 16,
    },
    iconButton: {
        backgroundColor: FlossPalette.TAN,
        borderRadius: 10,
        height: 20,
        width: 20,
        margin: `0px 4px`,
        padding: 0,
        color: FlossPalette.BLACK,
        '&.active': {
            backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
            color: FlossPalette.PRIMARY_FOREGROUND,
        },
        '@media (hover: none)': {
            '&:hover': {
                // Match the non-hover border.
                border: '1px solid transparent',
            },
        },
    },
    colorizeIconButton: {
        '& svg': {
            filter: 'saturate(0)',
            opacity: 0.5,
        },

        '&.active svg': {
            filter: 'none',
            opacity: 1,
        },
    },
}));

interface SectionHeaderProps {
    label: string;
    first?: boolean;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ label }) => {
    const classes = useStyles();

    return (
        <ListItem key={label} className={classes.menuItem}>
            <Text variant={'body1'}>{label}</Text>
        </ListItem>
    );
};

interface ToggleButtonProps extends IconToggleButtonProps {
    iconName: IconName;
    viewBox: string;
}

const ToggleButton: React.VFC<ToggleButtonProps> = ({ iconName, viewBox, ...rest }) => {
    const classes = useStyles();

    return (
        <IconToggleButton {...rest} className={classes.iconButton}>
            <Icon icon={iconName} className={classes.iconButtonInner} viewBox={viewBox} />
        </IconToggleButton>
    );
};

interface ModelControllerProps {
    label: string;
    key?: string;
    visible: boolean;
    toggleVisible: () => void;
    transparent: boolean;
    toggleTransparent: () => void;
    colorize?: boolean;
    toggleColorize?: () => void;
}

const ModelController: React.FC<ModelControllerProps> = ({
    label,
    key,
    visible,
    toggleVisible,
    transparent,
    toggleTransparent,
    colorize,
    toggleColorize,
}) => {
    const classes = useStyles();

    const visibilityTooltip = visible ? 'Hide' : 'Show';
    const transparencyTooltip = transparent ? 'Set opaque' : 'Set transparent';
    const colorizeTooltip = colorize ? 'Disable color' : 'Enable color';

    return (
        <ListItem key={key ?? label} className={classes.menuItem}>
            <ListItemText>
                <Text className={classes.insetLabel} variant={'body2'}>
                    {label}
                </Text>
            </ListItemText>
            {colorize !== undefined && toggleColorize !== undefined && (
                <IconToggleButton
                    className={clsx(classes.iconButton, classes.colorizeIconButton)}
                    active={colorize}
                    onClick={toggleColorize}
                    tooltip={colorizeTooltip}
                >
                    <Icon icon={'ModelTextureIcon'} className={classes.iconButtonInner} viewBox={'0 0 24 24'} />
                </IconToggleButton>
            )}
            <ToggleButton
                iconName={'RestorativesTransparentIcon'}
                active={transparent}
                onClick={toggleTransparent}
                tooltip={transparencyTooltip}
                viewBox={'0 0 24 24'}
            />
            <ToggleButton
                iconName={'EyeStrikethroughIcon'}
                active={!visible}
                onClick={toggleVisible}
                tooltip={visibilityTooltip}
                viewBox={'0 0 16 16'}
            />
        </ListItem>
    );
};

export const SceneAppearanceMenu: React.FC = () => {
    const { appearance, manager } = useSceneState();

    const { upperJaw, lowerJaw, prePrepUpperJaw, prePrepLowerJaw } = appearance.scans;

    return (
        <List dense>
            <SectionHeader label={'Scans'} first />
            <ModelController
                label={'Upper jaw'}
                visible={upperJaw.visible}
                toggleVisible={() => manager.setJawVisibility('upperJaw')}
                transparent={upperJaw.transparent}
                toggleTransparent={() => manager.setJawTransparency('upperJaw')}
                colorize={upperJaw?.colorize ?? false}
                toggleColorize={() => manager.setJawColorize('upperJaw')}
            />
            <ModelController
                label={'Lower jaw'}
                visible={lowerJaw?.visible ?? false}
                toggleVisible={() => manager.setJawVisibility('lowerJaw')}
                transparent={lowerJaw.transparent}
                toggleTransparent={() => manager.setJawTransparency('lowerJaw')}
                colorize={lowerJaw?.colorize ?? false}
                toggleColorize={() => manager.setJawColorize('lowerJaw')}
            />
            {manager.getScanMeshes()?.prePrepUpperJaw && (
                <ModelController
                    label={'Upper pre-op'}
                    visible={prePrepUpperJaw?.visible ?? false}
                    toggleVisible={() => manager.setJawVisibility('prePrepUpperJaw')}
                    transparent={prePrepUpperJaw?.transparent ?? false}
                    toggleTransparent={() => manager.setJawTransparency('prePrepUpperJaw')}
                    colorize={prePrepUpperJaw?.colorize ?? false}
                    toggleColorize={() => manager.setJawColorize('prePrepUpperJaw')}
                />
            )}
            {manager.getScanMeshes()?.prePrepLowerJaw && (
                <ModelController
                    label={'Lower pre-op'}
                    visible={prePrepLowerJaw?.visible ?? false}
                    toggleVisible={() => manager.setJawVisibility('prePrepLowerJaw')}
                    transparent={prePrepLowerJaw?.transparent ?? false}
                    toggleTransparent={() => manager.setJawTransparency('prePrepLowerJaw')}
                    colorize={prePrepLowerJaw?.colorize ?? false}
                    toggleColorize={() => manager.setJawColorize('prePrepLowerJaw')}
                />
            )}
            <SectionHeader label={'Restoratives'} />
            {Array.from(appearance.restoratives.entries()).map(([toothNumber, modelAppearance]) => (
                <ModelController
                    key={toothNumber.toString()}
                    label={`Crown ${toothNumber}`}
                    visible={modelAppearance.visible}
                    toggleVisible={() => manager.setRestorativeVisibility(toothNumber)}
                    transparent={modelAppearance.transparent}
                    toggleTransparent={() => manager.setRestorativeTransparency(toothNumber)}
                />
            ))}
        </List>
    );
};
