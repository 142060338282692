import type { LabsGqlAttachExternalAlignerFulfillmentMutationVariables } from '@orthly/graphql-operations';
import { useAttachExternalAlignerFulfillmentMutation, useGetOrgQuery } from '@orthly/graphql-react';
import { useChangeSubmissionFn, RootActionDialog, QuickForm } from '@orthly/ui';
import React from 'react';

interface MarkExternalAlignerFulfillmentCreatedProps {
    orderId: string;
    refetch: () => Promise<any>;
    disabled: boolean;
    labId: string;
}
type Vars = LabsGqlAttachExternalAlignerFulfillmentMutationVariables['data'];

export const MarkExternalAlignerFulfillmentCreated: React.FC<MarkExternalAlignerFulfillmentCreatedProps> = ({
    orderId,
    refetch,
    disabled,
    labId,
}) => {
    const [submitMtn] = useAttachExternalAlignerFulfillmentMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        successMessage: () => [`External aligner fulfillment saved!`, {}],
        onSuccess: async () => {
            await refetch();
        },
    });

    const labName = useGetOrgQuery({ variables: { id: labId } }).data?.getOrganization.name;

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={`Mark aligner case submitted to ${labName ?? 'manufacturer'}`}
            buttonText={'Submit to manufacturer'}
            buttonProps={{ disabled }}
            content={
                <QuickForm<Omit<Vars, 'order_id'>>
                    fields={{
                        provider_case_id: { type: 'text', label: 'External case ID' },
                        expected_arrival_date: { type: 'date', fieldProps: { disablePast: true } },
                    }}
                    initialValues={{}}
                    onSubmit={async result => {
                        await submit({ ...result, order_id: orderId });
                    }}
                />
            }
        />
    );
};
