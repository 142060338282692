import { ButtonWithProgress } from './ButtonWithProgress';
import { useApolloClient } from '@apollo/client';
import type { DesignTrainingOrderFragment } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import { useOrder } from '@orthly/graphql-react';
import type { LabsGqlFinishingInBrowserPayload } from '@orthly/graphql-schema';
import { Button, type ButtonProps } from '@orthly/ui-primitives';
import { useDesignOrderRevision, DesignFinishingRoot } from '@orthly/veneer';
import React from 'react';

const SubmitAutomateReviewForTrainingMutation = graphql(`
    mutation SubmitAutomateReviewForTraining($command: SubmitAutomateReviewCommand!, $trainingDesignId: ID!) {
        submitAutomateReviewForTraining(command: $command, trainingDesignId: $trainingDesignId)
    }
`);
const SubmitFinishingInBrowserForTrainingMutation = graphql(`
    mutation SubmitFinishingInBrowserForTraining($payload: FinishingInBrowserPayload!, $trainingDesignId: ID!) {
        submitFinishingInBrowserForTraining(payload: $payload, trainingDesignId: $trainingDesignId)
    }
`);

function useSubmitCallbacks(orderId: string, trainingDesignId: string) {
    const client = useApolloClient();

    const submitReviewDecision = async (args: { approved: boolean; reviewDurationMs: number }) => {
        await client.mutate({
            mutation: SubmitAutomateReviewForTrainingMutation,
            variables: {
                command: {
                    order_id: orderId,
                    approved: args.approved,
                    review_duration_ms: args.reviewDurationMs,
                },
                trainingDesignId,
            },
        });
    };
    const submitFinishedDesign = async (args: { payload: LabsGqlFinishingInBrowserPayload }) => {
        await client.mutate({
            mutation: SubmitFinishingInBrowserForTrainingMutation,
            variables: { payload: args.payload, trainingDesignId },
        });
    };

    return {
        submitReviewDecision,
        submitFinishedDesign,
    };
}

export const DesignTrainingStartFinishingButton: React.VFC<{
    trainingOrder: DesignTrainingOrderFragment;
    onComplete: () => void;
    /** Button children. Usually just the button text. */
    children?: React.ReactNode;
    /** Whether to prefetch order and design revision data or wait until the user clicks the button. Default is `false`. */
    prefetch?: boolean;
    /** The button component. Default is `Button`. */
    ButtonComponent?: React.ComponentType<ButtonProps>;
    /** The button variant. Default is `primary`. */
    buttonVariant?: ButtonProps['variant'];
}> = props => {
    const {
        trainingOrder,
        onComplete,
        children,
        prefetch = false,
        ButtonComponent = Button,
        buttonVariant = 'primary',
    } = props;
    const [open, setOpen] = React.useState<boolean>(false);
    // Skip queries when not prefetching and the user hasn't clicked the button to start.
    const skip = !prefetch && !open;

    let { order: labOrderData } = useOrder(trainingOrder.orderId, { skip });
    if (labOrderData) {
        // TODO: This is pretty hacky. Maybe we can explicitly tell the finishing app where to start instead.
        labOrderData = {
            ...labOrderData,
            fulfillment_workflow: { ...labOrderData.fulfillment_workflow, active_task: null },
            patient: { ...labOrderData.patient, first_name: `Training #${trainingOrder.orderNumber}`, last_name: '' },
        };
    }
    const { data: designRevisionData } = useDesignOrderRevision(trainingOrder.designRevisionId ?? '', { skip });
    const design = designRevisionData?.getDesignOrderDesignRevisionById;

    const submitCallbacks = useSubmitCallbacks(trainingOrder.orderId, trainingOrder.id);

    return (
        <>
            <ButtonWithProgress
                ButtonComponent={ButtonComponent}
                variant={buttonVariant}
                loading={open && (!labOrderData || !design)}
                onClick={() => {
                    setOpen(true);
                }}
            >
                {children}
            </ButtonWithProgress>
            {labOrderData && design && (
                <DesignFinishingRoot
                    order={labOrderData}
                    design={design}
                    open={open}
                    callbacks={{
                        ...submitCallbacks,
                        setWindowOpen: setOpen,
                        onCancel: () => {},
                        onComplete,
                    }}
                    initiallyOpen={false}
                    isTrainingOrder={true}
                />
            )}
        </>
    );
};
