import { DesignSidebarSection } from './DesignDetailSidebarSection';
import type { FragmentType, VeneerOrderDesignAnalyticsDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import { OrderDesignAnalytics } from '@orthly/veneer';
import React from 'react';

interface DesignAnalyticsSectionProps {
    orderId: string;
    designFragment: FragmentType<typeof VeneerOrderDesignAnalyticsDesign_FragmentFragmentDoc>;
}

export const DesignAnalyticsSection: React.FC<DesignAnalyticsSectionProps> = ({ orderId, designFragment }) => {
    return (
        <DesignSidebarSection title={'Design Analytics'}>
            <OrderDesignAnalytics designFragment={designFragment} orderId={orderId} variant={'sidebar'} />
        </DesignSidebarSection>
    );
};
