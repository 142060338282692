import { getFeedbackOrRefabItemsFromOrder } from '../../FeedbackAndRefab';
import { RefabBodyContainer } from '../components/RefabBodyContainer';
import { RefabReasonCodesContainer } from '../components/RefabItemReasonCodesScreen';
import {
    useRefabFlowAction,
    useRefabFlowSelector,
    useSelectedReasonCodesForCurrentItem,
} from '../state/refab-flow-state';
import { useRefabFlowContext } from './refab-flow-context';
import { LabsGqlFormDisplay } from '@orthly/graphql-schema';
import { LoadBlocker } from '@orthly/ui';
import { Text, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const ItemReasonCodesScreen: React.VFC = () => {
    const { order, useReasonCodesForOrderItemQuery } = useRefabFlowContext();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const selectedItemIds = useRefabFlowSelector(s => s.selectedItemIds);
    const itemId = useRefabFlowSelector(s => s.screenItemId);
    const screenItem = getFeedbackOrRefabItemsFromOrder(order).find(item => item.id === itemId);
    const itemIndex = selectedItemIds.findIndex(id => id === itemId);
    const patchState = useRefabFlowAction('PATCH_STATE');
    const { data, loading } = useReasonCodesForOrderItemQuery({
        variables: { order_id: order?.id ?? '', item_id: itemId, form_display: LabsGqlFormDisplay.Refab },
    });

    // Sort reason codes so that all the 'Other' options are always at the end.
    const itemReasonCodes = _.sortBy(data?.reasonCodesForOrderItem ?? [], rc => rc.title.toLowerCase() === 'other');
    const selectedReasonCodes = useSelectedReasonCodesForCurrentItem();

    React.useEffect(() => {
        // We expect to get a valid itemIndex, but if we don't, we defensively redirect to 'selection'
        if (itemIndex === -1) {
            patchState({ screen: 'selection' });
        }
    }, [itemIndex, patchState, order]);

    const areAnyCodesAvailable = (data?.reasonCodesForOrderItem.length ?? 0) > 0;
    const areAnyCodesSet = Object.keys(selectedReasonCodes ?? {}).length > 0;
    const areAnyOtherCodesIncomplete = Object.keys(selectedReasonCodes).some(
        rc => selectedReasonCodes[rc]?.title === 'Other' && !selectedReasonCodes[rc]?.otherNotes,
    );

    return (
        <RefabBodyContainer
            title={
                <Text variant={isMobile ? 'h5' : 'h3'} medium>
                    What was wrong with {screenItem?.fullDisplayName}?
                </Text>
            }
            disableNext={areAnyCodesAvailable && (!areAnyCodesSet || areAnyOtherCodesIncomplete)}
        >
            <LoadBlocker blocking={loading}>
                <RefabReasonCodesContainer reasonCodes={itemReasonCodes} />
            </LoadBlocker>
        </RefabBodyContainer>
    );
};
