import { ItemEditorV2DialogField } from '../../components/ItemEditorV2DialogField';
import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { ItemCrownMaterialSelect } from '@orthly/dentin';
import { CartItemV2Utils, LabOrderItemSKUType, ItemMaterialFieldV2Utils } from '@orthly/items';
import React from 'react';

export const isItemEditorV2CrownMaterialItem = CartItemV2Utils.itemIsType([
    LabOrderItemSKUType.Crown,
    LabOrderItemSKUType.Inlay,
    LabOrderItemSKUType.Veneer,
    LabOrderItemSKUType.Bridge,
    LabOrderItemSKUType.Implant,
    LabOrderItemSKUType.ImplantBridge,
]);

export const ItemEditorV2CrownMaterialField: React.VFC<ItemEditorV2BaseProps> = ({ item, updateItem, showError }) => {
    const value = ItemMaterialFieldV2Utils.getSingleMaterial(item, 'crown');
    const materialLabel = CartItemV2Utils.itemTypeHasImplant(item) ? 'Crown Material' : 'Material';

    return (
        <ItemEditorV2DialogField
            fieldLabel={materialLabel}
            fieldValue={value}
            modalTitle={`Select ${materialLabel}`}
            modalContent={
                <ItemCrownMaterialSelect
                    material={value}
                    onChange={material => updateItem({ name: 'material', payload: material })}
                    item={item}
                    unns={CartItemV2Utils.getUniqueUNNs(item)}
                />
            }
            errorText={showError && !value ? 'This field is required' : undefined}
        />
    );
};
