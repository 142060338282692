import type { LabsGqlSetPartnerScannersMutationVariables } from '@orthly/graphql-operations';
import {
    useAllScannersWithSearchQuery,
    useGetScannersByPracticeQuery,
    useSetPartnerScannersMutation,
} from '@orthly/graphql-react';
import { useChangeSubmissionFn, LoadBlocker, useDebouncedValue } from '@orthly/ui';
import {
    Button,
    Text,
    stylesFactory,
    CircularProgress,
    IconButton,
    Grid,
    TextField,
    DeleteIcon,
    Autocomplete,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type SetPartnerScannersVars = LabsGqlSetPartnerScannersMutationVariables;

const useStyles = stylesFactory(() => ({
    deleteButton: {
        // Don't show a border on hover, but take up the space so layout doesn't judder.
        '&:hover': { border: '1px solid rgba(0, 0, 0, 0)' },
        padding: 0,
        paddingLeft: 8,
    },
}));

const ScannerSelect: React.VFC<{ currentScanners: string[]; onSelect: (scannerId: string) => void }> = ({
    currentScanners,
    onSelect,
}) => {
    const [open, setOpen] = React.useState(false);
    const [internalSearch, setInternalSearch] = React.useState('');
    const search = useDebouncedValue(internalSearch, 500);
    const { data, loading } = useAllScannersWithSearchQuery({
        variables: { search: search },
        skip: !search,
    });

    const currentScannersSet = new Set(currentScanners);
    const options = data?.getAllScannersWithSearch.scanners.map(s => s.id).filter(s => !currentScannersSet.has(s));

    return (
        <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={options || []}
            loading={loading}
            filterOptions={x => x}
            onChange={(_evt, newValue) => {
                onSelect(newValue as string);
            }}
            onInputChange={(_, newInputValue) => {
                setInternalSearch(newInputValue);
            }}
            renderInput={params => (
                <TextField
                    autoFocus
                    variant={'standard'}
                    style={{ width: 400 }}
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color={'inherit'} size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};

export interface ScannerSelectionPanelProps {
    practiceId: string;
}

export const ScannerSelectionPanel: React.FC<ScannerSelectionPanelProps> = props => {
    const [currentScanners, setCurrentScanners] = React.useState<string[]>([]);
    const {
        data,
        error,
        refetch,
        loading: queryLoading,
    } = useGetScannersByPracticeQuery({
        variables: { practiceId: props.practiceId },
        onCompleted: ({ getScannersByPractice }) => {
            setCurrentScanners(getScannersByPractice.map(cs => cs.id));
        },
    });
    const [submitMtn] = useSetPartnerScannersMutation();
    const { submit, submitting: loading } = useChangeSubmissionFn<any, [SetPartnerScannersVars]>(
        (variables: SetPartnerScannersVars) => submitMtn({ variables }),
        {
            closeOnComplete: true,
            successMessage: () => ['Practice scanners updated', {}],
        },
    );
    const [isEditing, setIsEditing] = React.useState(false);

    const styles = useStyles();
    const originalScannerIds = new Set(data?.getScannersByPractice.map(cs => cs.id) || []);

    if (error || !data) {
        return <div />;
    }
    const scannersHaveChanged = !_.isEqual(currentScanners, originalScannerIds);
    return (
        <LoadBlocker blocking={loading || queryLoading}>
            <Grid container spacing={1}>
                {currentScanners.map(scannerId => (
                    <Grid key={scannerId} item container alignItems={'center'} xs={12}>
                        <Text
                            variant={'body2'}
                            style={{ fontFamily: 'monospace' }}
                            color={originalScannerIds.has(scannerId) ? undefined : 'GREEN'}
                        >
                            {scannerId}
                        </Text>
                        <IconButton
                            className={styles.deleteButton}
                            onClick={() => setCurrentScanners(scanners => scanners.filter(id => id !== scannerId))}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                ))}
                <Grid item container justifyContent={'space-between'} style={{ marginTop: 8 }}>
                    {!isEditing ? (
                        <Button variant={'ghost'} onClick={() => setIsEditing(true)}>
                            + Add scanner
                        </Button>
                    ) : (
                        <ScannerSelect
                            currentScanners={currentScanners}
                            onSelect={scannerId => {
                                setCurrentScanners(scanners => [...scanners, scannerId]);
                                setIsEditing(false);
                            }}
                        />
                    )}

                    <Button
                        variant={'primary'}
                        disabled={!scannersHaveChanged}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={async () => {
                            try {
                                await submit({
                                    scanner_ids: currentScanners,
                                    partner_id: props.practiceId,
                                });
                                await refetch();
                            } catch (err: any) {
                                console.error(err);
                            }
                        }}
                    >
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </LoadBlocker>
    );
};
