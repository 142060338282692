import { useOperationsState } from '../../AppState.hooks';
import { ToolbarButton } from './ToolbarButton';
import { ToolbarContainer } from './ToolbarContainer';
import { Icon } from '@orthly/ui';
import { stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    button: {
        width: 48,
    },
}));

export const UndoRedoToolbar: React.VFC = () => {
    const classes = useStyles();

    const { manager: opManager, hasUndo, hasRedo } = useOperationsState();

    const undoTooltip = hasUndo ? 'Undo' : 'Nothing to undo';
    const redoTooltip = hasRedo ? 'Redo' : 'Nothing to redo';

    return (
        <ToolbarContainer>
            <ToolbarButton
                className={classes.button}
                disabled={!hasUndo}
                onClick={() => opManager.undoOperation()}
                tooltip={undoTooltip}
            >
                <Icon icon={'UndoIcon'} />
            </ToolbarButton>
            <ToolbarButton
                className={classes.button}
                disabled={!hasRedo}
                onClick={() => opManager.redoOperation()}
                tooltip={redoTooltip}
            >
                <Icon icon={'RedoIcon'} />
            </ToolbarButton>
        </ToolbarContainer>
    );
};
