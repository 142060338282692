import type { ItemEditorV2BaseProps } from '../../../types/ItemEditorV2BaseProps';
import { useAutoSetItemDataFieldDefaultValue } from './ItemEditorV2ItemDataField.util';
import { CheckoutTextField } from '@orthly/dentin';
import type { ItemDataFieldId, LabOrderItemSKUType, StringFreeformItemDataField } from '@orthly/items';
import { ItemDataFieldUtils } from '@orthly/items';
import React from 'react';

interface ItemEditorV2StringFreeformItemDataFieldProps<ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>
    extends ItemEditorV2BaseProps<SKU> {
    field: StringFreeformItemDataField<ID, SKU>;
}

export const ItemEditorV2StringFreeformItemDataField = <ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>({
    field,
    item,
    updateItem,
    showError,
}: ItemEditorV2StringFreeformItemDataFieldProps<ID, SKU>): React.ReactElement => {
    const value = field.getValue(item) ?? undefined;

    const applyUpdate = (val: string | undefined) => {
        updateItem(field.getUpdatePayload(val ?? null, item));
    };

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    return (
        <CheckoutTextField
            label={field.label}
            value={value}
            onChange={val => applyUpdate(val)}
            // we handle validation && error display ourselves
            required={false}
            error={
                showError && ItemDataFieldUtils.isItemDataFieldRequired(field, item) && !field.isComplete(item)
                    ? 'This field is required'
                    : undefined
            }
        />
    );
};
