import type { LabsGqlListPracticeNamesQuery } from '@orthly/graphql-operations';
import type { ListPracticeNamesQueryResult } from '@orthly/graphql-react';
import { useListPracticeNamesQuery } from '@orthly/graphql-react';
import constate from 'constate';

export type PartnerNameFragment = LabsGqlListPracticeNamesQuery['listPracticeNames'][number];

function usePartners(): ListPracticeNamesQueryResult {
    return useListPracticeNamesQuery({
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });
}
const [PartnerDataProv, usePartnerQ] = constate(usePartners);

// exported separately b/c the typings for constate dont resolve correctly
export const PartnerDataProvider = PartnerDataProv;

export const usePartnersData: () => ListPracticeNamesQueryResult = usePartnerQ;
