import type { UseVirtualizedListOrdersRes } from '../../hooks';
import type {
    ListItemPropsCompare,
    OrdersVirtualListProps,
    OrdersVirtualizedListItemProps,
    OrdersVirtualizedListData,
} from '../OrderListItem/OrderListItemUtils.types';
import type {
    WorkflowTasksVirtualizedListItemProps,
    WorkflowTasksVirtualizedListData,
} from './OrdersVirtualList.types';
import type { LabsGqlWorkflowTaskFragment } from '@orthly/graphql-operations';
import _ from 'lodash';
import React from 'react';
import type { FixedSizeListProps } from 'react-window';
import { FixedSizeList as List } from 'react-window';

const OVERSCAN_COUNT = 10;

function orderFromProps({ index, data: { orders, startIndex } }: ListItemPropsCompare) {
    return orders[index - startIndex];
}

function orderListItemPropsAreEqual(prev: ListItemPropsCompare, next: ListItemPropsCompare): boolean {
    // if the top level list props have changed we need to re-render
    if (!_.isEqual(prev.style, next.style) || prev.index !== next.index) {
        return false;
    }
    // if the component or callback have changed we need to re-render
    if (
        prev.data.orderToListItemContent !== next.data.orderToListItemContent ||
        prev.data.ListItem !== next.data.ListItem
    ) {
        return false;
    }
    const prevOrder = orderFromProps(prev);
    const nextOrder = orderFromProps(next);
    // for the order itself we only need to ensure its the same id and version (via updated_at)
    return prevOrder?.id === nextOrder?.id && prevOrder?.updated_at === nextOrder?.updated_at;
}

const OrdersVirtualizedListItem = React.memo<OrdersVirtualizedListItemProps<unknown>>(props => {
    const order = orderFromProps(props);
    const { ListItem, orderToListItemContent } = props.data;
    return (
        <div style={props.style}>
            <ListItem listItemContent={order ? orderToListItemContent(order) : undefined} />
        </div>
    );
}, orderListItemPropsAreEqual);

export function useInitialScrollOffset(startIndex: number, listItemHeight: number): number {
    // use a ref to make this stable
    const initialScrollOffsetRef = React.useRef(Math.max(startIndex * listItemHeight - listItemHeight, 0));
    return initialScrollOffsetRef.current;
}

type OrdersVirtualizedListProps<ListItemContent> = Omit<FixedSizeListProps, 'itemData'> & {
    itemData: OrdersVirtualizedListData<ListItemContent>;
};
const OrdersVirtualizedList = List as React.ComponentType<OrdersVirtualizedListProps<any>>;

export const OrdersVirtualList = <ListItemContent extends unknown>(props: OrdersVirtualListProps<ListItemContent>) => {
    const { onItemsRendered, orders, listHeight, ids, startIndex, listItemHeight, orderToListItemContent, style } =
        props;
    const initialScrollOffset = useInitialScrollOffset(startIndex, listItemHeight);
    const itemKeyCb = React.useCallback((index: number) => ids[index] ?? index, [ids]);
    return (
        <OrdersVirtualizedList
            itemKey={itemKeyCb}
            overscanCount={OVERSCAN_COUNT}
            height={listHeight}
            itemCount={ids.length}
            initialScrollOffset={initialScrollOffset}
            itemData={{ startIndex, orders, orderToListItemContent, ListItem: props.ListItem }}
            width={'100%'}
            itemSize={props.listItemHeight}
            onItemsRendered={onItemsRendered}
            style={style}
        >
            {OrdersVirtualizedListItem}
        </OrdersVirtualizedList>
    );
};

function taskFromProps(props: WorkflowTasksVirtualizedListItemProps<unknown>): LabsGqlWorkflowTaskFragment | undefined {
    return props.data.tasks[props.index];
}

function taskListItemPropsAreEqual(
    prev: WorkflowTasksVirtualizedListItemProps<unknown>,
    next: WorkflowTasksVirtualizedListItemProps<unknown>,
): boolean {
    return orderListItemPropsAreEqual(prev, next) && _.isEqual(taskFromProps(prev), taskFromProps(next));
}

const WorkflowTasksVirtualizedListItem = React.memo<WorkflowTasksVirtualizedListItemProps<any>>(props => {
    const { orderToListItemContent, ListItem } = props.data;
    const order = orderFromProps(props);
    const task = taskFromProps(props);
    if (!task) {
        return null;
    }
    return (
        <div style={props.style}>
            <ListItem task={task} listItemContent={order ? orderToListItemContent(order) : undefined} />
        </div>
    );
}, taskListItemPropsAreEqual);

type WorkflowTasksVirtualizedListProps<ListItemContent> = Omit<FixedSizeListProps, 'itemData'> & {
    itemData: WorkflowTasksVirtualizedListData<ListItemContent>;
};
const WorkflowTasksVirtualizedList = List as React.ComponentType<WorkflowTasksVirtualizedListProps<any>>;

interface WorkflowTasksVirtualListProps<ListItemContent> extends UseVirtualizedListOrdersRes<ListItemContent> {
    listItemHeight: number;
    ListItem: WorkflowTasksVirtualizedListData<ListItemContent>['ListItem'];
    tasks: LabsGqlWorkflowTaskFragment[];
}

export const WorkflowTasksVirtualList = <ListItemContent extends unknown>(
    props: WorkflowTasksVirtualListProps<ListItemContent>,
) => {
    const { onItemsRendered, orders, listHeight, startIndex, ListItem, listItemHeight, tasks, orderToListItemContent } =
        props;
    const initialScrollOffset = useInitialScrollOffset(startIndex, listItemHeight);
    const itemKeyCb = React.useCallback((index: number) => tasks[index]?.id ?? index, [tasks]);
    return (
        <WorkflowTasksVirtualizedList
            itemKey={itemKeyCb}
            overscanCount={OVERSCAN_COUNT}
            height={listHeight}
            itemCount={tasks.length}
            initialScrollOffset={initialScrollOffset}
            itemData={{ startIndex, orders, orderToListItemContent, ListItem, tasks }}
            width={'100%'}
            itemSize={props.listItemHeight}
            onItemsRendered={onItemsRendered}
        >
            {WorkflowTasksVirtualizedListItem}
        </WorkflowTasksVirtualizedList>
    );
};
