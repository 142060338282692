import { ItemEditorV2DialogField } from '../../components/ItemEditorV2DialogField';
import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { CartItemSimpleShadePicker, getHiddenShadeProps } from '@orthly/dentin';
import { CartItemV2Utils } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

export const ItemEditorV2ShadeField: React.VFC<ItemEditorV2BaseProps> = ({ item, updateItem, showError }) => {
    const hasBaseShadeValue = item.shades.some(shade => shade.name === 'base' && shade.value.length > 0);
    const { hiddenShades, hiddenShadeOpts } = getHiddenShadeProps(item.sku);

    return (
        <ItemEditorV2DialogField
            fieldLabel={'Shade'}
            fieldValue={item.shades
                .filter(shade => shade.name !== 'tissue')
                .map(shade => `${_.startCase(shade.name)}: ${shade.value}`)
                .join(', ')}
            modalTitle={`Select Shade`}
            modalContent={
                <>
                    <CartItemSimpleShadePicker
                        item={item}
                        updateItem={updateItem}
                        unns={CartItemV2Utils.getUniqueUNNs(item)}
                        requiredShadeTypes={['base']}
                        hiddenShades={hiddenShades}
                        hiddenShadeOpts={hiddenShadeOpts}
                    />
                </>
            }
            errorText={showError && !hasBaseShadeValue ? `Base shade is required` : undefined}
            isOkButtonDisabled={!hasBaseShadeValue}
        />
    );
};
