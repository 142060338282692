import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlStripePaymentMethod } from '@orthly/graphql-schema';
import React from 'react';

export type SourceSplitOption = { id: string; label: string; is_default: boolean };

const ListPaymentSources_Query = graphql(`
    query GetLabsPaymentMethods($partnerId: String) {
        getLabsPaymentMethods(partnerId: $partnerId) {
            id
            brand
            last4
            is_default
            method
            name
        }
    }
`);

export function usePartnerPaymentSplitSourceOptions(partner_id: string, skipQuery: boolean = false) {
    const { data: sourcesRaw, loading: sourcesLoading } = useQuery(ListPaymentSources_Query, {
        variables: { partnerId: partner_id },
        skip: skipQuery,
    });

    const getLabel = (s: LabsGqlStripePaymentMethod): string => {
        let label = '';
        if (s.method === 'bank_account') {
            label = `ACH (${s.name}) ending in ${s.last4}`;
            // Example: `ACH (STRIPE TEST BANK) ending in 1234`
        } else {
            // Implicit s.method === 'card'
            label = `${s.brand.toUpperCase()} ending in ${s.last4}`;
            // Example: `VISA ending in 4321`
        }

        if (s.is_default) {
            label += ' (Default)';
        }

        return label;
    };

    const sourceOptions = React.useMemo<SourceSplitOption[]>(() => {
        const sources = sourcesRaw?.getLabsPaymentMethods ?? [];
        return sources.map<SourceSplitOption>(s => ({
            id: s.id,
            label: getLabel(s),
            is_default: s.is_default,
        }));
    }, [sourcesRaw]);
    return { sourcesLoading, sourceOptions };
}
