import { OrderFilesMenu } from '../OrderButtons/OrderFilesMenu';
import type { TaskButtonProps } from './TaskButton';
import { useAnchor, TaskButtonBase } from './TaskButton';
import { DownloadIcon } from '@orthly/ui';
import { FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

export const DownloadFilesButton: React.FC<TaskButtonProps> = ({ task, setOpen, buttonProps }) => {
    const [anchor, setAnchor] = useAnchor(setOpen);
    return (
        <>
            <TaskButtonBase tooltip={`Download files`} onClick={ev => setAnchor(ev.currentTarget)} {...buttonProps}>
                <DownloadIcon style={{ color: FlossPalette.STAR_GRASS }} />
            </TaskButtonBase>
            <OrderFilesMenu orderId={task.lab_order_id} anchorEl={anchor} onClose={() => setAnchor(null)} />
        </>
    );
};
