import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import type { LabsGqlLabPricePartialDentureArchUnitType } from '@orthly/graphql-schema';
import { LabsGqlLabPriceRuleType, LabsGqlOrderItemPartialDentureCoverageType } from '@orthly/graphql-schema';
import { objectEntries } from '@orthly/runtime-utils';
import { QuickForm } from '@orthly/ui';

const partialDentureArchUnitLabel: Record<LabsGqlLabPricePartialDentureArchUnitType, string> = {
    TryIn: 'TryIn',
    Final: 'Final (no Try-In, straight to finish)',
    FinalAfterTryIn: 'Final (after Try-In or Wax Rim)',
};

export const PartialDentureArchForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.PartialDentureArch>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.PartialDentureArch>({
                unit_type: {
                    type: 'select',
                    label: 'Unit Type',
                    options: Object.values(partialDentureArchUnitLabel),
                },
                coverage_type: {
                    type: 'select',
                    label: 'Coverage Type',
                    options: Object.values(LabsGqlOrderItemPartialDentureCoverageType),
                },
                material: {
                    type: 'select',
                    label: 'Material',
                    options: ['Chrome-Cobalt', 'Titanium', 'Valplast/TCS', 'Duraflex', 'Acetal', 'Acrylic'],
                },
                tooth_count_min: {
                    type: 'number',
                    label: 'Tooth Count Minimum',
                    optional: true,
                },
                tooth_count_max: {
                    type: 'number',
                    label: 'Tooth Count Maximum',
                    optional: true,
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          unit_type:
                              price.rule.__typename === 'LabPricePartialDentureArchRuleDTO'
                                  ? partialDentureArchUnitLabel[price.rule.partial_unit_type]
                                  : null,
                          coverage_type:
                              price.rule.__typename === 'LabPricePartialDentureArchRuleDTO'
                                  ? price.rule.coverage_type
                                  : null,
                          material:
                              price.rule.__typename === 'LabPricePartialDentureArchRuleDTO'
                                  ? price.rule.material
                                  : null,
                          tooth_count_min:
                              price.rule.__typename === 'LabPricePartialDentureArchRuleDTO'
                                  ? price.rule.tooth_count_range.min
                                  : null,
                          tooth_count_max:
                              price.rule.__typename === 'LabPricePartialDentureArchRuleDTO'
                                  ? price.rule.tooth_count_range.max
                                  : null,
                      }
                    : {}
            }
            onSubmit={async result => {
                const unitTypeEntry =
                    objectEntries(partialDentureArchUnitLabel).find(([_, v]) => v === result.unit_type) ?? [];
                await submit({
                    name: result.name,
                    rule: {
                        type: LabsGqlLabPriceRuleType.PartialDentureArch,
                        partial_unit_type: unitTypeEntry[0] ?? null,
                        coverage_type: result.coverage_type || null,
                        material: result.material || null,
                        tooth_count_range: { min: result.tooth_count_min || null, max: result.tooth_count_max || null },
                    },
                });
            }}
            disabled={disabled}
        />
    );
};
