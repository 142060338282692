import { AdminLabsInvoicingLineItemsPane } from './AdminLabsEditInvoicingLineItems/AdminLabsEditInvoicingLineItemsPane.graphql';
import type { AdminLabsEditInvoicingLineItemsActionProps } from './pricing-history/types';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useHasCapability } from '@orthly/session-client';
import { RootActionDialog } from '@orthly/ui';
import { Button, Text, Tooltip } from '@orthly/ui-primitives';
import type { ReactNode } from 'react';
import React from 'react';

interface MaybeTooltipProps {
    content: ReactNode;
    show: boolean;
}

const MaybeDisabledTooltip: React.FC<MaybeTooltipProps> = ({ content, show, children }) => {
    return show ? (
        <Tooltip
            title={content}
            slotProps={{
                popper: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 16],
                            },
                        },
                    ],
                },
            }}
            arrow
        >
            {/* https://mui.com/material-ui/react-tooltip/#disabled-elements */}
            {/* By default disabled elements like <button> do not trigger user interactions so a Tooltip will not activate on
             normal events like hover. To accommodate disabled elements, add a simple wrapper element, such as a span. */}
            <span style={{ cursor: 'not-allowed' }}>{children}</span>
        </Tooltip>
    ) : (
        <>{children}</>
    );
};

const GetOrderHasPendingPayment_Query = graphql(`
    query getOrderHasPendingPayment($orderId: String!) {
        hasPendingPayment: getOrderHasPendingPayment(orderId: $orderId)
    }
`);

export const AdminLabsEditInvoicingLineItemsAction: React.FC<AdminLabsEditInvoicingLineItemsActionProps> = props => {
    const [open, setOpen] = React.useState<boolean>(false);

    const { data: queryData, loading } = useQuery(GetOrderHasPendingPayment_Query, {
        variables: { orderId: props.order.id },
        skip: typeof props.hasPendingPaymentOnInvoice !== 'undefined',
    });

    const canView = useHasCapability('order', 'order.ledger.view');
    const shouldDisable = props.hasPendingPaymentOnInvoice || loading || queryData?.hasPendingPayment || false;
    const canEdit = useHasCapability('order', 'order.ledger.edit');

    if (!canView) {
        return null;
    }

    return (
        <RootActionDialog
            loading={false}
            open={open}
            setOpen={setOpen}
            dialogProps={{ maxWidth: 'sm' }}
            title={'Order Ledger'}
            content={
                <AdminLabsInvoicingLineItemsPane
                    {...props}
                    canEdit={canEdit && !shouldDisable}
                    onSubmit={() => setOpen(false)}
                />
            }
            style={{ padding: 0 }}
            buttonText={'Ledger'}
            CustomButton={() => (
                <MaybeDisabledTooltip
                    content={
                        <Text variant={'body2'} color={'WARNING_HIGHLIGHT'}>
                            Order is associated with invoice(s) which have pending payments. No adjustments can be made
                            until the payments are no longer pending.
                        </Text>
                    }
                    show={shouldDisable}
                >
                    <Button
                        variant={'ghost'}
                        startIcon={props.shouldShowLedgerIcon ? 'LocalAtm' : undefined}
                        onClick={() => setOpen(true)}
                    >
                        Ledger
                    </Button>
                </MaybeDisabledTooltip>
            )}
        />
    );
};
