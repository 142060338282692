import type { PracticeDetailsProps } from '../../PalatePracticeRoot.types';
import { CONTAINER_WIDTH } from '../../usePalateStyles';
import { PracticeInfo } from './PracticeInfo';
import { PracticeProfiles } from './PracticeProfiles';
import { PreviouslyOrderedSKUs } from './PreviouslyOrderedSKUs';
import { RefabRates } from './RefabRates';
import { Grid } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

export const PalatePracticeInfo: React.FC<PracticeDetailsProps> = props => {
    const { practiceId, practiceDetails } = props;
    const { value: showPracticeRefabRatesInPalate } = useFeatureFlag('showPracticeRefabRatesInPalate');

    return (
        <Grid container style={{ overflow: 'auto', height: '100%' }}>
            <Grid
                container
                id={'palate-practice-info'}
                style={{ padding: '0 32px', width: CONTAINER_WIDTH, display: 'flex', flexDirection: 'column' }}
            >
                <PracticeInfo practiceDetails={practiceDetails} practiceId={practiceId} />
                {showPracticeRefabRatesInPalate && <RefabRates practiceId={practiceId} />}
                <PreviouslyOrderedSKUs practiceId={practiceId} />
                <PracticeProfiles practiceId={practiceId} />
            </Grid>
        </Grid>
    );
};
