import type { StepConnectorProps } from '@orthly/ui-primitives';
import { FlossPalette, stylesFactory, StepConnector } from '@orthly/ui-primitives';
import clsx from 'classnames';
import React from 'react';

function activeLineBgColor(hasIssue: boolean): string {
    const baseColor = hasIssue ? FlossPalette.ATTENTION : FlossPalette.GRAY;
    return `linear-gradient(90deg,${baseColor} 0%,${baseColor} 49.99%,${FlossPalette.DARK_TAN} 50%,${FlossPalette.DARK_TAN} 100%)`;
}

const useStyles = stylesFactory(
    () => ({
        root: { width: '100%' },
        line: { height: 2, border: 0, backgroundColor: FlossPalette.DARK_TAN, borderRadius: 1 },
        activeLine: { backgroundColor: 'unset', backgroundImage: activeLineBgColor(false) },
        activeLineIssue: { backgroundColor: 'unset', backgroundImage: activeLineBgColor(true) },
        completedLine: { backgroundColor: FlossPalette.GRAY },
        completedLineIssue: { backgroundColor: FlossPalette.ATTENTION },
    }),
    { name: 'TrackerConnector' },
);

// Fun change: they no longer expose the active and completed props, despite the code being unchanged.
// Before: https://github.com/mui/material-ui/blob/v4.8.3/packages/material-ui/src/StepConnector/StepConnector.js
// After: https://github.com/mui/material-ui/blob/v4.12.4/packages/material-ui/src/StepConnector/StepConnector.js

type OrderListItemTrackerConnectorProps = StepConnectorProps & {
    hasIssue: boolean;
    active: boolean;
    completed: boolean;
};

// Utility so the hasIssue props is not passed to the base StepConnector
export const TrackerConnector: React.FC<OrderListItemTrackerConnectorProps> = ({ hasIssue, ...stepConnectorProps }) => {
    const classes = useStyles();
    const { active, completed } = stepConnectorProps;
    return (
        <StepConnector
            {...stepConnectorProps}
            classes={{
                root: classes.root,
                line: clsx(classes.line, {
                    [classes.activeLine]: !hasIssue && active,
                    [classes.activeLineIssue]: hasIssue && active,
                    [classes.completedLine]: !hasIssue && completed,
                    [classes.completedLineIssue]: hasIssue && completed,
                }),
            }}
        />
    );
};
