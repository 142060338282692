import type {
    LabsGqlDisplayRuleFragment,
    LabsGqlAddRefabReasonRuleMutationVariables,
    LabsGqlRefabReasonFragment,
} from '@orthly/graphql-operations';
import { useListDisplayRuleOptsQuery, useAddRefabReasonRuleMutation } from '@orthly/graphql-react';
import type { LabsGqlCustomFieldDisplayRuleInput } from '@orthly/graphql-schema';
import {
    LabsGqlDisplayRuleOrderProperty,
    LabsGqlDisplayRuleTypeEnum,
    LabsGqlOrderItemLinkRelationship,
} from '@orthly/graphql-schema';
import {
    LoadBlocker,
    RootActionDialog,
    SimpleInput,
    SimpleMultiSelect,
    SimpleSelect,
    useChangeSubmissionFn,
} from '@orthly/ui';
import { FlossPalette, createStyles, makeStyles, Button, Chip, Grid, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        contentText: {
            paddingRight: 5,
            width: 'auto',
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: '1px 2px',
        },
        chipOr: {
            margin: 0,
            background: 'none',
        },
        chipOrLabel: {
            padding: 0,
        },
    }),
);

type DisplayRuleProperty = 'unit_type' | 'material_type' | 'link_relationship' | 'teeth';

const FormControlProps = { style: { width: 'auto', padding: '5px 5px 5px 0' }, variant: 'standard' as const };
const SelectProps = { displayEmpty: true };

interface RefabRuleFormFieldProps {
    reasonName: string;
    rule: LabsGqlCustomFieldDisplayRuleInput;
    rule_type: LabsGqlDisplayRuleTypeEnum.ReturnRules | LabsGqlDisplayRuleTypeEnum.DisplayRules;
    setRule: (value: LabsGqlCustomFieldDisplayRuleInput) => void;
    existingReasons: LabsGqlRefabReasonFragment[];
}

export const RuleFormField: React.FC<RefabRuleFormFieldProps> = props => {
    const classes = useStyles();
    const { rule, setRule, rule_type } = props;
    const { data: displayOpts, loading: displayOptsLoading } = useListDisplayRuleOptsQuery({
        fetchPolicy: 'cache-first',
    });
    const [optionsTouched, setOptionsTouched] = React.useState<boolean>(false);
    const rootOptions = React.useMemo<Record<DisplayRuleProperty, string[]>>(() => {
        return {
            unit_type: displayOpts?.listDisplayRuleOptions.unit_types ?? [],
            material_type: displayOpts?.listDisplayRuleOptions.material_types ?? [],
            link_relationship: Object.values(LabsGqlOrderItemLinkRelationship),
            teeth: _.range(1, 33).map(i => `${i}`),
        };
    }, [displayOpts]);
    const existingRules = React.useMemo(() => {
        const allRulesById = props.existingReasons.flatMap(reason =>
            reason[rule_type].map(rule => ({ ...rule, reasonName: reason.name })),
        );
        type RuleWithReasonName = LabsGqlDisplayRuleFragment & { reasonName: string };
        const groupedById = _.groupBy<RuleWithReasonName>(allRulesById, t => t.id);
        type RuleWithLabel = LabsGqlDisplayRuleFragment & { label: string };
        return Object.values(groupedById).flatMap<RuleWithLabel>(rules => {
            const firstRule = rules[0];
            if (!firstRule) {
                return [];
            }
            const { reasonName, ...rule } = firstRule;
            const reasonNames = _.uniq(rules.map(r => r.reasonName)).join(', ');
            return { ...rule, label: `${firstRule.name} (in reasons ${reasonNames})` };
        });
    }, [props.existingReasons, rule_type]);
    const evalResults =
        props.rule_type === LabsGqlDisplayRuleTypeEnum.DisplayRules ? ['HIDE', 'SHOW'] : ["DON'T RETURN", 'RETURN'];
    return (
        <LoadBlocker blocking={displayOptsLoading && !displayOpts}>
            <Grid container alignItems={'center'} style={{ paddingBottom: 10 }}>
                <SimpleSelect
                    options={existingRules.map(({ label, id }) => ({ label, value: id }))}
                    onChange={value => {
                        const matchingRule = !value ? undefined : existingRules.find(r => r.id === value);
                        if (matchingRule) {
                            setRule({
                                applicable_values: matchingRule.applicable_values,
                                property: matchingRule.property,
                                display_if_passes: matchingRule.display_if_passes,
                                name: matchingRule.name,
                            });
                        }
                    }}
                    label={'Copy Existing Rule?'}
                />
            </Grid>
            <Grid container alignItems={'center'}>
                <SimpleInput
                    onChange={value => setRule({ ...rule, name: value ?? '' })}
                    value={rule.name}
                    label={'Rule Name'}
                    fullWidth
                />
            </Grid>
            <Grid container alignItems={'center'}>
                <Text variant={'body1'} className={classes.contentText}>
                    IF the item's
                </Text>
                <SimpleSelect
                    onChange={value => {
                        if (value !== rule.property && value) {
                            setRule({ ...rule, property: value as any, applicable_values: [] });
                        }
                    }}
                    label={''}
                    options={[
                        { value: 'material_type', label: 'MATERIAL TYPE' },
                        { value: 'unit_type', label: 'UNIT TYPE' },
                        { value: 'link_relationship', label: 'IMPLANT RELATIONSHIP' },
                        { value: 'teeth', label: 'TEETH' },
                    ]}
                    value={rule.property}
                    SelectProps={{ ...SelectProps, style: { fontWeight: 500 }, variant: 'standard' }}
                    FormControlProps={FormControlProps}
                />
            </Grid>
            <Grid container alignItems={'center'}>
                <Text variant={'body1'} className={classes.contentText}>
                    IS
                </Text>
                <SimpleMultiSelect
                    onChange={value => setRule({ ...rule, applicable_values: value ?? [] })}
                    placeholder={_.startCase(rule.property)}
                    label={''}
                    options={rootOptions[rule.property].map(value => ({ value, label: value }))}
                    value={rule.applicable_values}
                    helperText={
                        rule.applicable_values.length <= 0 && optionsTouched
                            ? `Must select at least one${
                                  rule.property === 'teeth'
                                      ? `; Reference: Anterior (6-11, 22-27), Posterior (1-5, 12-16, 17-21, 28-32)`
                                      : ``
                              }`
                            : undefined
                    }
                    SelectProps={{
                        ...SelectProps,
                        onOpen: () => setOptionsTouched(true),
                        renderValue: val => {
                            const selected = val as string[];
                            if (selected.length === 0) {
                                return <em>{rule.property ? _.startCase(rule.property) : '...'}</em>;
                            }
                            const lastItemIdx = selected.length - 1;
                            return (
                                <div className={classes.chips}>
                                    {selected.map((value, idx) => (
                                        <>
                                            <Chip key={value} label={value} className={classes.chip} />
                                            {idx !== lastItemIdx && (
                                                <Chip
                                                    key={`${value}-OR`}
                                                    label={'OR'}
                                                    className={classes.chipOr}
                                                    classes={{ label: classes.chipOrLabel }}
                                                />
                                            )}
                                        </>
                                    ))}
                                </div>
                            );
                        },
                        variant: 'standard',
                    }}
                    FormControlProps={{
                        ...FormControlProps,
                        error: rule.applicable_values.length <= 0 && optionsTouched,
                    }}
                />
            </Grid>
            <Grid container alignItems={'center'}>
                <Text variant={'body1'} className={classes.contentText}>
                    THEN
                </Text>
                <SimpleSelect
                    onChange={value => setRule({ ...rule, display_if_passes: value !== 'HIDE' })}
                    label={''}
                    options={[
                        { value: 'SHOW', label: evalResults[1] },
                        { value: 'HIDE', label: evalResults[0] },
                    ]}
                    value={rule.display_if_passes ? 'SHOW' : 'HIDE'}
                    SelectProps={{
                        ...SelectProps,
                        renderValue: value => {
                            const result = value === 'SHOW' ? evalResults[1] : evalResults[0];
                            if (typeof value === 'string') {
                                return (
                                    <span
                                        style={{
                                            color: value === 'SHOW' ? FlossPalette.GREEN : FlossPalette.ATTENTION,
                                        }}
                                    >
                                        {result}
                                    </span>
                                );
                            }
                            return null;
                        },
                        variant: 'standard',
                    }}
                    FormControlProps={FormControlProps}
                />
                {rule_type === LabsGqlDisplayRuleTypeEnum.DisplayRules && (
                    <Text variant={'body1'} className={classes.contentText}>
                        <b>{props.reasonName}</b>
                    </Text>
                )}
                {rule_type === LabsGqlDisplayRuleTypeEnum.ReturnRules && (
                    <Text variant={'body1'} className={classes.contentText}>
                        item(s) when <b>{props.reasonName}</b> is selected
                    </Text>
                )}
            </Grid>
        </LoadBlocker>
    );
};

interface AddRefabRuleContentProps {
    submit: (data: LabsGqlCustomFieldDisplayRuleInput) => Promise<void>;
    submitting: boolean;
    material_types: Array<string>;
    unit_types: Array<string>;
    existingReasons: LabsGqlRefabReasonFragment[];
    rule_type: LabsGqlDisplayRuleTypeEnum.DisplayRules | LabsGqlDisplayRuleTypeEnum.ReturnRules;
    reasonName: string;
    createButtonText: string;
}

export const AddRefabRuleContent: React.FC<AddRefabRuleContentProps> = props => {
    const { rule_type, reasonName } = props;
    const [rule, setRule] = React.useState<LabsGqlCustomFieldDisplayRuleInput>({
        name: '',
        applicable_values: [],
        display_if_passes: true,
        property: LabsGqlDisplayRuleOrderProperty.MaterialType,
    });
    return (
        <LoadBlocker blocking={props.submitting}>
            <RuleFormField
                rule={rule}
                setRule={setRule}
                existingReasons={props.existingReasons}
                rule_type={rule_type}
                reasonName={reasonName}
            />
            <Grid container>
                <Button
                    fullWidth
                    disabled={rule.applicable_values.length <= 0 || !rule.name || props.submitting}
                    onClick={async () => {
                        if (rule.applicable_values.length > 0 && rule.name) {
                            await props.submit(rule);
                        }
                    }}
                    variant={'contained'}
                >
                    {props.createButtonText}
                </Button>
            </Grid>
        </LoadBlocker>
    );
};

interface AddRefabRuleProps {
    reason: LabsGqlRefabReasonFragment;
    rule_type: LabsGqlDisplayRuleTypeEnum.DisplayRules | LabsGqlDisplayRuleTypeEnum.ReturnRules;
    existingReasons: LabsGqlRefabReasonFragment[];
}

type Vars = LabsGqlAddRefabReasonRuleMutationVariables['data'];

export const AddRefabReasonRule: React.FC<AddRefabRuleProps> = props => {
    const [submitMtn] = useAddRefabReasonRuleMutation();
    const { data: displayOpts, loading } = useListDisplayRuleOptsQuery({ fetchPolicy: 'cache-first' });
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const ruleVerb = props.rule_type === LabsGqlDisplayRuleTypeEnum.DisplayRules ? 'Display' : 'Return';
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => [`Refab Reason ${ruleVerb} rule added!`, {}],
    });
    return (
        <RootActionDialog
            open={open}
            loading={submitting || loading}
            title={`Add ${ruleVerb} Rule to Reason ${props.reason.name}`}
            setOpen={setOpen}
            buttonText={`Add ${ruleVerb} Rule`}
            buttonProps={{ fullWidth: true }}
            content={
                <AddRefabRuleContent
                    createButtonText={`Create ${ruleVerb} Rule`}
                    existingReasons={props.existingReasons}
                    material_types={displayOpts?.listDisplayRuleOptions.material_types || []}
                    unit_types={displayOpts?.listDisplayRuleOptions.unit_types || []}
                    submitting={submitting}
                    reasonName={props.reason.name}
                    rule_type={props.rule_type}
                    submit={async data => {
                        const firstOption = data.applicable_values[0];
                        if (!firstOption) {
                            return;
                        }
                        const command: Vars = {
                            reason_id: props.reason.id,
                            rule_type: props.rule_type,
                            rule: {
                                applicable_values: data.applicable_values,
                                property: data.property,
                                name: data.name,
                                display_if_passes: data.display_if_passes,
                            },
                        };
                        await submit(command);
                    }}
                />
            }
        />
    );
};
