import { AnalyticsClient } from '../../utils/analyticsClient';
import type { PracticeTab } from './PalatePracticeRoot.types';
import { useSession } from '@orthly/session-client';
import React from 'react';

export function useTrackPalateDuringCall(palateOpened: boolean, practiceId?: string) {
    const userId = useSession()?.user_id;
    return React.useCallback(() => {
        if (palateOpened) {
            AnalyticsClient.track('Palate - App Opened', {
                practiceId,
                userId,
                context: 'call',
            });
        } else {
            AnalyticsClient.track('Palate - App Closed', {
                userId,
                practiceId,
                context: 'call',
                reason: 'timeout',
            });
        }
    }, [palateOpened, userId, practiceId]);
}

export function useTrackTimeOnPalateTabChange(openTime: number, tab?: PracticeTab, practiceId?: string) {
    const userId = useSession()?.user_id;
    return React.useCallback(
        (closeTime: number) => {
            // openTime/closeTime are in milliseconds, so to calculate timeElapsed,
            // get the difference and divide by 1000 to convert to seconds
            AnalyticsClient.track('Palate - Tab Changed', {
                userId,
                tab,
                practiceId,
                timeElapsedSeconds: (closeTime - openTime) / 1000,
            });
        },
        [userId, openTime, tab, practiceId],
    );
}

export function useTrackTimeOnPalateClose(openTime: number, practiceId?: string) {
    const userId = useSession()?.user_id;
    return React.useCallback(
        (closeTime: number) => {
            // openTime/closeTime are in milliseconds, so to calculate timeElapsed,
            // get the difference and divide by 1000 to convert to seconds
            AnalyticsClient.track('Palate - App Closed', {
                userId,
                practiceId,
                timeElapsedSeconds: (closeTime - openTime) / 1000,
            });
        },
        [userId, openTime, practiceId],
    );
}

export function useTrackLinkClick(practiceId: string) {
    const userId = useSession()?.user_id;
    return React.useCallback(
        (url: string) => {
            AnalyticsClient.track('Palate - Link Clicked', { url, practiceId, userId });
        },
        [practiceId, userId],
    );
}

export function useTrackImpersonateOpen(practiceId: string, impersonateUrl: string) {
    const userId = useSession()?.user_id;
    return React.useCallback(() => {
        AnalyticsClient.track('Palate - Practice Impersonate Opened', { practiceId, impersonateUrl, userId });
    }, [practiceId, impersonateUrl, userId]);
}

export function useTrackEmptyResult(search_term?: string) {
    const userId = useSession()?.user_id;
    return React.useCallback(() => {
        AnalyticsClient.track('Palate - No Practices Found', { userId, search: search_term || '' });
    }, [userId, search_term]);
}

export function useTrackNonEmptyResult(search_term?: string) {
    const userId = useSession()?.user_id;
    return React.useCallback(() => {
        AnalyticsClient.track('Palate - Practices Found', { userId, search: search_term || '' });
    }, [userId, search_term]);
}

export function usePracticeInfoRender(practiceId: string) {
    const userId = useSession()?.user_id;
    return React.useEffect(() => {
        AnalyticsClient.track('Palate - Practice Info Rendered', { userId, practiceId });
    }, [userId, practiceId]);
}
