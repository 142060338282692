export enum ManualInvoiceItemValidationError {
    UNKNOWN_PRACTICE_ID = 'Unknown Practice ID',
    INVALID_ITEM_CATEGORY = 'Invalid Item Category',
    INVALID_ITEM_DESCRIPTION = 'Invalid Item Description',
    INVALID_ITEM_AMOUNT = 'Invalid Item Amount',
}

export interface ManualInvoiceItemCsvRow {
    practice_id: string;
    item_category: string;
    item_description: string;
    price_dollars: number;
}

export function isStringInEnum(inputString: string, enumObject: any): boolean {
    return Object.values(enumObject).includes(inputString);
}

export const defaultValidationErrors: Record<ManualInvoiceItemValidationError, number[]> = {
    [ManualInvoiceItemValidationError.UNKNOWN_PRACTICE_ID]: [],
    [ManualInvoiceItemValidationError.INVALID_ITEM_CATEGORY]: [],
    [ManualInvoiceItemValidationError.INVALID_ITEM_DESCRIPTION]: [],
    [ManualInvoiceItemValidationError.INVALID_ITEM_AMOUNT]: [],
};

export function doesMatrixRowContainValidationError(
    rowIndex: number,
    validationErrors: Record<ManualInvoiceItemValidationError, number[]>,
) {
    // incrementing row index by 1 because we don't want the zero-indexed value
    return Object.values(validationErrors)
        .flat()
        .includes(rowIndex + 1);
}
