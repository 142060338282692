import { DesignSidebarSection } from './DesignDetailSidebarSection';
import { LabsGqlTaggableEntityType } from '@orthly/graphql-schema';
import { TagsAutocomplete } from '@orthly/veneer';
import React from 'react';

export const DesignDetailSidebarTags: React.VFC<{ orderId: string }> = ({ orderId }) => (
    <DesignSidebarSection title={'Order tags'}>
        <TagsAutocomplete
            rootStyle={{ height: '12px', padding: 'none', margin: 'none', display: 'inline' }}
            entityId={orderId}
            entityType={LabsGqlTaggableEntityType.Order}
        />
    </DesignSidebarSection>
);
