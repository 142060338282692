import type { Invoice, InvoiceRow } from '../InvoicesTable/InvoiceTable.types';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlStripeInvoiceStatus } from '@orthly/graphql-schema';
import { QuickForm, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import React from 'react';

interface VoidInvoiceProps {
    invoice: InvoiceRow<Invoice>;
    refetch: () => Promise<unknown>;
}

const VoidInvoice_Mutation = graphql(`
    mutation VoidInvoice($data: VoidInvoiceInput!) {
        voidInvoice(data: $data) {
            id
        }
    }
`);

export const VoidInvoiceAction: React.FC<VoidInvoiceProps> = ({ invoice, refetch }) => {
    const [open, setOpen] = React.useState(false);
    const voidInvoiceMtn = useMutation(VoidInvoice_Mutation);
    const { submit, submitting } = useRootActionCommand(voidInvoiceMtn, {
        onSuccess: async () => {
            await refetch();
            setOpen(false);
        },
        successMessage: 'Invoice voided!',
    });

    if (invoice.status !== LabsGqlStripeInvoiceStatus.Open || invoice.amount_paid > 0) {
        return null;
    }
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Void Invoice'}
            content={
                <QuickForm<{ reason: string }>
                    fields={{
                        reason: { type: 'text', label: 'Reason for voiding' },
                    }}
                    initialValues={{}}
                    onSubmit={async result => {
                        await submit({ data: { invoiceId: invoice.id, reason: result.reason } });
                    }}
                />
            }
            buttonText={'Void Invoice'}
        />
    );
};
