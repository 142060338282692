import type { LabsGqlImplantTypeLiteFragment } from '@orthly/graphql-operations';
import type { SimpleAutocompleteOption, SimpleAutocompleteProps } from '@orthly/ui';
import { LoadBlocker, SimpleAutocomplete, ActionCard } from '@orthly/ui';
import { FlossPalette, Grid, Icon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export type ImplantMetaFieldName = 'manufacturer' | 'system' | 'connection';

// Common props for all the autocomplete fields for implant properties
const commonAutocompleteProps: Partial<SimpleAutocompleteProps> = {
    freeSolo: false,
    AutocompleteProps: { style: { width: '100%' } },
    variant: 'standard',
    TextFieldProps: { InputProps: { style: { fontWeight: 500 } } },
};

interface ImplantSystemMeta {
    connection: string | null;
    manufacturer: string | null;
    system: string | null;
}

function useFilteredImplantSystems(
    implantSystems: ImplantSystemMeta[],
    currentValues: ImplantSystemMeta,
): ImplantSystemMeta[] {
    return React.useMemo(() => {
        const { manufacturer, system, connection } = currentValues;
        // don't filter on system if manufacturer not selected
        if (!manufacturer) {
            return implantSystems;
        }
        return implantSystems.filter(
            i =>
                i.manufacturer === manufacturer &&
                (!system || i.system?.toLowerCase() === system.toLowerCase()) &&
                (!connection || i.connection?.toLowerCase() === connection.toLowerCase()),
        );
    }, [implantSystems, currentValues]);
}

function useImplantAutocompleteFieldOptions(db: ImplantSystemMeta[], property: ImplantMetaFieldName) {
    return React.useMemo<SimpleAutocompleteOption[]>(() => {
        const uniqValues = _.uniq(_.compact(db.map<string | null>(d => d[property])));
        return _.sortBy(uniqValues, _.startCase).map(value => ({ value, label: value }));
    }, [db, property]);
}

interface ImplantSystemAutocompleteRowProps {
    linkMeta: ImplantSystemMeta;
    manufacturers: SimpleAutocompleteOption[];
    systems: SimpleAutocompleteOption[];
    connections: SimpleAutocompleteOption[];
    updateField: (fieldName: ImplantMetaFieldName, value: string | null) => void;
    showIncompleteError?: boolean;
    // used for surgical guides as they don't specify connection
    hideConnection?: boolean;
    decorativeArrow?: boolean;
    mostFrequentlyUsedManufacturers?: string[];
}

export const ImplantSystemAutocompleteRow: React.VFC<ImplantSystemAutocompleteRowProps> = props => {
    const {
        linkMeta,
        manufacturers,
        systems,
        connections,
        updateField,
        showIncompleteError,
        hideConnection,
        decorativeArrow,
        mostFrequentlyUsedManufacturers,
    } = props;
    const onFieldChange = (fieldName: ImplantMetaFieldName, value: string | null) => {
        switch (fieldName) {
            case 'manufacturer':
                updateField('manufacturer', value);
                updateField('system', null);
                updateField('connection', null);
                return;
            case 'system':
                updateField('system', value);
                updateField('connection', null);
                return;
            case 'connection':
                updateField('connection', value);
                return;
        }
    };

    const frequentGroupLabel = `Frequently used manufacturers (${mostFrequentlyUsedManufacturers?.length})`;
    const allGroupLabel = `All (${manufacturers.length - (mostFrequentlyUsedManufacturers?.length ?? 0)})`;

    return (
        <>
            <Grid item style={{ flexGrow: 1 }}>
                <SimpleAutocomplete
                    initialInputValue={linkMeta.manufacturer}
                    label={'Manufacturer'}
                    options={
                        !mostFrequentlyUsedManufacturers
                            ? manufacturers
                            : _.orderBy(
                                  manufacturers,
                                  m => mostFrequentlyUsedManufacturers?.includes(m.label ?? ''),
                                  'desc',
                              )
                    }
                    onInputChange={val => onFieldChange('manufacturer', val)}
                    onChange={val => onFieldChange('manufacturer', val)}
                    error={showIncompleteError && !linkMeta.manufacturer ? 'Manufacturer is required' : undefined}
                    {...commonAutocompleteProps}
                    AutocompleteProps={{
                        ...commonAutocompleteProps.AutocompleteProps,
                        ...(mostFrequentlyUsedManufacturers?.length
                            ? {
                                  groupBy: option =>
                                      mostFrequentlyUsedManufacturers?.includes(option.value)
                                          ? frequentGroupLabel
                                          : allGroupLabel,
                              }
                            : {}),
                    }}
                />
            </Grid>
            {decorativeArrow && (
                <Grid item>
                    <Icon icon={'ArrowForwardIcon'} style={{ color: FlossPalette.DARK_TAN }} />
                </Grid>
            )}
            <Grid item style={{ flexGrow: 1 }}>
                <SimpleAutocomplete
                    {...commonAutocompleteProps}
                    // uses manufacturer so React creates a fresh component instance when manufacturer changes
                    key={linkMeta.manufacturer || undefined}
                    initialInputValue={linkMeta.system}
                    label={'System'}
                    options={systems}
                    onInputChange={val => onFieldChange('system', val)}
                    error={showIncompleteError && !linkMeta.system ? 'System is required' : undefined}
                />
            </Grid>
            {!hideConnection && (
                <>
                    {decorativeArrow && (
                        <Grid item>
                            <Icon icon={'ArrowForwardIcon'} style={{ color: FlossPalette.DARK_TAN }} />
                        </Grid>
                    )}
                    <Grid item style={{ flexGrow: 1 }}>
                        <SimpleAutocomplete
                            {...commonAutocompleteProps}
                            // uses system so React creates a fresh component instance when system changes
                            key={linkMeta.system || undefined}
                            initialInputValue={linkMeta.connection}
                            // labeled updated from 'Connection Size'
                            label={'Platform Size'}
                            options={connections}
                            onInputChange={val => onFieldChange('connection', val)}
                            error={
                                showIncompleteError && !linkMeta.connection ? 'Platform size is required' : undefined
                            }
                        />
                    </Grid>
                </>
            )}
        </>
    );
};

export interface ImplantSystemInputProps {
    implantSystems: LabsGqlImplantTypeLiteFragment[];
    LinkRelationshipField?: React.ReactNode;
    linkMeta: ImplantSystemMeta;
    updateField: (fieldName: ImplantMetaFieldName, value: string | null) => void;
    // used for surgical guides as they don't specify connection
    hideConnection?: boolean;
    decorativeArrow?: boolean;
    vertical?: boolean;
    loadingError?: boolean;
    showIncompleteError?: boolean;
    mostFrequentlyUsedManufacturers?: string[];
}

export const ImplantSystemInput: React.VFC<ImplantSystemInputProps> = props => {
    const {
        implantSystems,
        LinkRelationshipField,
        linkMeta,
        updateField,
        hideConnection,
        decorativeArrow,
        vertical,
        loadingError,
        showIncompleteError,
        mostFrequentlyUsedManufacturers,
    } = props;
    const { systems, manufacturers, connections } = useImplantSystemRows(linkMeta, implantSystems);

    return (
        <LoadBlocker blocking={!implantSystems.length}>
            <Grid
                container
                direction={vertical ? 'column' : 'row'}
                spacing={2}
                style={{ position: 'relative', flexWrap: 'wrap' }}
                alignItems={decorativeArrow ? 'center' : undefined}
            >
                {LinkRelationshipField && (
                    <Grid container item xs={12}>
                        {LinkRelationshipField}
                    </Grid>
                )}
                {loadingError && (
                    <Grid container item>
                        <ActionCard
                            variant={'alert'}
                            title={'Failed to load implant options.'}
                            subtitle={'Please try reloading the page.'}
                        />
                    </Grid>
                )}
                <ImplantSystemAutocompleteRow
                    linkMeta={linkMeta}
                    manufacturers={manufacturers}
                    mostFrequentlyUsedManufacturers={mostFrequentlyUsedManufacturers}
                    systems={systems}
                    connections={connections}
                    updateField={updateField}
                    showIncompleteError={showIncompleteError}
                    hideConnection={hideConnection}
                    decorativeArrow={decorativeArrow}
                />
            </Grid>
        </LoadBlocker>
    );
};

export function useImplantSystemRows(
    linkMeta: ImplantSystemMeta,
    implantSystems: Omit<LabsGqlImplantTypeLiteFragment, '__typename'>[],
) {
    const filteredImplantSystems = useFilteredImplantSystems(implantSystems, linkMeta);
    // we dont pass the filtered implant db for manufacturer because that's the first field to fill out
    const manufacturers = useImplantAutocompleteFieldOptions(implantSystems, 'manufacturer');
    const systems = useImplantAutocompleteFieldOptions(filteredImplantSystems, 'system');
    // platform_type is the column in the database, but 3shape refers to it as connection so we do too
    const connections = useImplantAutocompleteFieldOptions(filteredImplantSystems, 'connection');

    return { manufacturers, systems, connections };
}
