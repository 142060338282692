import type { AdminState } from '../../../redux/redux.types';
import type {
    EditSavedSearchPayload,
    FavoriteSavedSearchPayload,
    LoadSavedSearchPayload,
    MutateSavedSearchPayload,
    SaveViewProps,
    VisibilitySavedSearchResult,
} from '../../../redux/utils/SavedViewActionUtils';
import { SavedViewActionUtils } from '../../../redux/utils/SavedViewActionUtils';
import type { InboxCustomView, InboxState, OpsInboxScreen, OpsInboxSortKey } from './Inbox.types';
import { DEFAULT_SORT } from './InboxConstants';
import { cleanTasksFilter } from './InboxStateUtils';
import type {
    LabsGqlCreateSavedInboxSearchMutation,
    LabsGqlCreateSavedInboxSearchMutationVariables,
    LabsGqlEditSavedInboxSearchMutation as EditSavedInboxSearchMutation,
    LabsGqlEditSavedInboxSearchMutationVariables as EditSavedInboxSearchVariables,
    LabsGqlSavedSearchFragment,
} from '@orthly/graphql-operations';
import { CreateSavedInboxSearchDocument, EditSavedInboxSearchDocument } from '@orthly/graphql-react';
import type { LabsGqlFilterCriteriaSubmissionInput, LabsGqlListWorkflowTasksFilter } from '@orthly/graphql-schema';
import { LabsGqlCustomSearchType, LabsGqlSavedSearchVisibility } from '@orthly/graphql-schema';
import { createAsyncAction, createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';
import { RetainerSessionManager } from '@orthly/session-client';

const PREFIX = 'inbox';

interface FavoriteSavedSearchResult {
    searchId: string;
    view?: InboxCustomView;
}

interface CreateSavedSearchProps {
    payload: EditSavedSearchPayload;
    inboxState: InboxState;
}

interface EditSavedSearchProps extends CreateSavedSearchProps {
    activeSearch: boolean;
    existingSavedSearch: InboxCustomView;
}

function getCustomView(rawSearch: LabsGqlSavedSearchFragment): InboxCustomView {
    const { id, name, search, visibility } = rawSearch;
    const session = RetainerSessionManager.sessionFromToken(RetainerSessionManager.tokenFromLocalStorage());
    return {
        id,
        visibility,
        title: name,
        ordersCriteria: search.order_criteria ?? undefined,
        tasksFilter: search.__typename === 'InboxCustomSearch' ? search.task_filter ?? undefined : undefined,
        search: rawSearch.search.search ?? undefined,
        createdByUser: session?.user.id ? session.user.id === rawSearch.created_by : undefined,
        createdByUsername: `${rawSearch.created_by_user.first_name} ${rawSearch.created_by_user.last_name}`,
        sort:
            rawSearch.search.__typename === 'InboxCustomSearch' && rawSearch.search.task_sort
                ? { key: rawSearch.search.task_sort.field, asc: rawSearch.search.task_sort.isAsc }
                : DEFAULT_SORT,
        // + 1 for user who created search
        favoritedCount: rawSearch.favorited_by.length + 1,
    };
}

async function editSavedSearch(props: EditSavedSearchProps): Promise<SaveViewProps> {
    const { payload, activeSearch, inboxState, existingSavedSearch } = props;
    const result = await payload.client.mutate<EditSavedInboxSearchMutation, EditSavedInboxSearchVariables>({
        mutation: EditSavedInboxSearchDocument,
        variables: {
            data: {
                search_id: payload.searchId,
                visibility:
                    payload.visibility ??
                    (activeSearch ? inboxState?.view?.visibility : existingSavedSearch.visibility),
                search: activeSearch
                    ? {
                          type: LabsGqlCustomSearchType.Inbox,
                          search: inboxState?.view?.search,
                          order_criteria: SavedViewActionUtils.cleanOrderFilter(inboxState?.view?.ordersCriteria),
                          task_filter: cleanTasksFilter(inboxState?.view?.tasksFilter),
                          sort: inboxState.view
                              ? { field: inboxState?.view?.sort.key, isAsc: inboxState?.view?.sort.asc }
                              : undefined,
                      }
                    : undefined,
                name: payload.title,
            },
        },
    });
    if (!result.data) {
        console.log(result.errors);
        throw new Error('No data returned from mutation');
    }
    const { id, name: title, visibility } = result.data.editSavedInboxSearch;
    return { id, title, visibility };
}

async function createSavedSearch(props: CreateSavedSearchProps): Promise<SaveViewProps> {
    const { payload, inboxState } = props;
    const result = await payload.client.mutate<
        LabsGqlCreateSavedInboxSearchMutation,
        LabsGqlCreateSavedInboxSearchMutationVariables
    >({
        mutation: CreateSavedInboxSearchDocument,
        variables: {
            data: {
                visibility: payload.visibility ?? LabsGqlSavedSearchVisibility.Private,
                search: {
                    type: LabsGqlCustomSearchType.Inbox,
                    search: inboxState?.view?.search,
                    task_filter: cleanTasksFilter(inboxState?.view?.tasksFilter),
                    order_criteria: SavedViewActionUtils.cleanOrderFilter(inboxState?.view?.ordersCriteria),
                    sort: inboxState.view
                        ? { field: inboxState?.view?.sort.key, isAsc: inboxState?.view?.sort.asc }
                        : undefined,
                },
                name: payload.title,
            },
        },
    });
    if (!result.data) {
        console.log(result.errors);
        throw new Error('No data returned from mutation');
    }
    const { id, name: title, visibility } = result.data.createSavedInboxSearch;
    return { id, title, visibility };
}

export const OpsInboxActions = {
    SET_SCREEN: createSyncAction<OpsInboxScreen | string, [OpsInboxScreen | string]>(`${PREFIX}/SET_SCREEN`),
    SET_SEARCH: createSyncAction<string | undefined, [string | undefined]>(`${PREFIX}/SET_SEARCH`),
    SEARCH_ON_BLUR: createSyncAction<undefined, []>(`${PREFIX}/SEARCH_ON_BLUR`),
    SET_ORDER_CRITERIA: createSyncAction<LabsGqlFilterCriteriaSubmissionInput[]>(`${PREFIX}/SET_ORDER_CRITERIA`),
    CLEAR_ORDER_CRITERIA: createSyncAction<undefined, []>(`${PREFIX}/CLEAR_ORDER_CRITERIA`),
    SET_TASK_FILTER: createSyncAction<LabsGqlListWorkflowTasksFilter>(`${PREFIX}/SET_TASK_FILTER`),
    CLEAR_TASK_FILTER: createSyncAction<undefined, []>(`${PREFIX}/CLEAR_TASK_FILTER`),
    SORT_KEY_CLICKED: createSyncAction<OpsInboxSortKey, [OpsInboxSortKey]>(`${PREFIX}/SORT_KEY_CLICKED`),
    TOGGLE_SORT_DIR: createSyncAction<undefined, []>(`${PREFIX}/TOGGLE_SORT_DIR`),
    SAVE_VIEW: createAsyncAction<SaveViewProps, AdminState, [EditSavedSearchPayload]>(
        `${PREFIX}/SAVE_VIEW`,
        async (thunkParams, payload) => {
            const inboxState = thunkParams.getState().inbox;
            const existingSavedSearch = inboxState.savedViews[payload.searchId];
            if (existingSavedSearch) {
                const activeSearch = inboxState.view?.id === payload.searchId;
                return {
                    payload: await editSavedSearch({ payload, activeSearch, existingSavedSearch, inboxState }),
                };
            }
            return { payload: await createSavedSearch({ payload, inboxState }) };
        },
    ),
    SET_EDITING_VIEW_ID: createSyncAction<string | undefined, [string | undefined]>(`${PREFIX}/SET_EDITING_VIEW_ID`),
    DELETE_VIEW: createAsyncAction<string, AdminState, [MutateSavedSearchPayload]>(
        `${PREFIX}/DELETE_VIEW`,
        async (_thunkParams, { client, searchId }) => {
            await SavedViewActionUtils.deleteView(client, searchId);
            return { payload: searchId };
        },
    ),
    COPY_VIEW: createSyncAction<InboxCustomView, [InboxCustomView]>(`${PREFIX}/COPY_VIEW`),
    TOGGLE_FAVORITE_VIEW: createAsyncAction<FavoriteSavedSearchResult, AdminState, [FavoriteSavedSearchPayload]>(
        `${PREFIX}/TOGGLE_FAVORITE_VIEW`,
        async (_thunkParams, { client, searchId, favorited }) => {
            const savedSearch = await SavedViewActionUtils.toggleFavoritedView(client, favorited, searchId);
            return { payload: { searchId, view: savedSearch ? getCustomView(savedSearch) : undefined } };
        },
    ),
    LOAD_VIEWS: createAsyncAction<InboxCustomView[], AdminState, [LoadSavedSearchPayload]>(
        `${PREFIX}/LOAD_VIEWS`,
        async (_thunkParams, { client }) => {
            const views = await SavedViewActionUtils.loadViews(client, LabsGqlCustomSearchType.Inbox);
            return { payload: views.map(search => getCustomView(search)) ?? [] };
        },
    ),
    TOGGLE_VIEW_VISIBILITY: createAsyncAction<VisibilitySavedSearchResult, AdminState, [MutateSavedSearchPayload]>(
        `${PREFIX}/TOGGLE_VIEW_VISIBILITY`,
        async (thunkParams, { client, searchId }) => {
            const editedView = thunkParams.getState().inbox.savedViews[searchId];
            const newVisibility = await SavedViewActionUtils.toggleViewVisibility(client, searchId, editedView);
            return { payload: { searchId, newVisibility } };
        },
    ),
    SAVE_ACTIVE_VIEW_AS_NEW: createSyncAction<undefined, []>(`${PREFIX}/SAVE_ACTIVE_VIEW_AS_NEW`),
    SET_SELECTED_TASK_IDS: createSyncAction<string[], [string[]]>(`${PREFIX}/SET_SELECTED_TASK_IDS`),
    TOGGLE_TASK_SELECTED: createSyncAction<string, [string]>(`${PREFIX}/TOGGLE_TASK_SELECTED`),
};

export const useOpsInboxAction = generateUseActionHook<typeof OpsInboxActions>(OpsInboxActions);
