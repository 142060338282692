import type { AdminState } from '../../../redux/redux.types';
import { SavedViewActionUtils } from '../../../redux/utils/SavedViewActionUtils';
import type { OrdersOverviewState, OrdersOverviewScreen } from './OrdersOverview.types';
import { OrdersOverviewDefaultSort } from './OrdersOverviewConstants';
import type { LabsGqlOrderEntriesByListViewQueryVariables } from '@orthly/graphql-operations';
import type { LabsGqlFilterCriteriaSubmissionInput } from '@orthly/graphql-schema';
import {
    LabsGqlFilterComparator as FilterComparator,
    LabsGqlLabOrderStatus,
    LabsGqlLabOrderStatus as OrderStatus,
    LabsGqlWorkflowTaskType,
} from '@orthly/graphql-schema';
import { generateUseSubstateSelector } from '@orthly/redux-async-actions';
import type { ConstEnum } from '@orthly/runtime-utils';
import { OrderFilterIdEnum } from '@orthly/shared-types';

export const useOrdersOverviewState = generateUseSubstateSelector<AdminState, 'ordersOverview'>('ordersOverview');

const currentOrderStatusSelector =
    (fallback: OrderStatus | 'all' = OrderStatus.New) =>
    (state: OrdersOverviewState): OrderStatus | 'all' | 'waxups' => {
        switch (state.screen) {
            case 'labCases':
            case 'returns':
            case 'scans':
            case 'custom':
                return fallback;
            default:
                return state.screen;
        }
    };

export const OrdersOverviewScreenEnum: ConstEnum<OrdersOverviewScreen> = {
    [OrderStatus.Shipped]: OrderStatus.Shipped,
    [OrderStatus.New]: OrderStatus.New,
    [OrderStatus.NeedsRefabrication]: OrderStatus.NeedsRefabrication,
    [OrderStatus.NeedsReview]: OrderStatus.NeedsReview,
    [OrderStatus.OnHold]: OrderStatus.OnHold,
    [OrderStatus.Delivered]: OrderStatus.Delivered,
    [OrderStatus.Cancelled]: OrderStatus.Cancelled,
    [OrderStatus.Fabrication]: OrderStatus.Fabrication,
    all: 'all',
    labCases: 'labCases',
    returns: 'returns',
    scans: 'scans',
    waxups: 'waxups',
};

export function isOrdersOverviewScreen(screen: OrdersOverviewScreen | string): screen is OrdersOverviewScreen {
    return OrdersOverviewScreenEnum.hasOwnProperty(screen);
}

export type OrdersListFilterVars = Pick<
    LabsGqlOrderEntriesByListViewQueryVariables,
    'search' | 'filter' | 'sort' | 'criteria'
>;

const waxupCriteria: LabsGqlFilterCriteriaSubmissionInput[] = [
    {
        filter_id: OrderFilterIdEnum.active_task_type,
        comparator: FilterComparator.Equals,
        comparison_value: LabsGqlWorkflowTaskType.WaxupReview,
    },
];

const criteriaForStatus = (status: OrderStatus | 'all' | 'waxups'): LabsGqlFilterCriteriaSubmissionInput[] =>
    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    status === 'all'
        ? []
        : status === 'waxups'
          ? waxupCriteria
          : [{ filter_id: OrderFilterIdEnum.status, comparator: FilterComparator.Equals, comparison_value: status }];

function ordersListQueryVarsSelector(state: OrdersOverviewState): OrdersListFilterVars {
    if (state.screen === 'custom') {
        return {
            criteria: SavedViewActionUtils.cleanOrderFilter(state.view?.criteria) ?? null,
            search: state.view?.search ?? null,
            sort: state.view?.sort ?? null,
            filter: null,
        };
    }
    const status = currentOrderStatusSelector(LabsGqlLabOrderStatus.New)(state);
    return { filter: {}, search: null, sort: OrdersOverviewDefaultSort, criteria: criteriaForStatus(status) };
}

export function useOrdersListQueryVars(): OrdersListFilterVars {
    return useOrdersOverviewState(ordersListQueryVarsSelector);
}

export function isOrdersSearchViewActive(state: OrdersOverviewState): boolean {
    return state.screen === 'custom' && !!state.view.search;
}

// Whether or not to skip the search query due to short input
function isOrdersSearchQueryDisabled(state: OrdersOverviewState): boolean {
    if (isOrdersSearchViewActive(state)) {
        return !!state.view?.search && state.view.search.length < 3;
    }
    return false;
}

export function useOrdersSearchQueryDisabled(): boolean {
    return useOrdersOverviewState(isOrdersSearchQueryDisabled);
}
