import type { ProductionPlanDebuggerViewFragment } from '@orthly/graphql-inline-react';
import { SimpleTable } from '@orthly/ui';
import { differenceInBusinessDays } from 'date-fns';
import React from 'react';

function toTableRows(rows: { name: string; value: React.ReactNode }[]) {
    return rows.map(r => ({ ...r, titleCellStyle: { width: 300 }, valueCellStyle: { fontWeight: 'bold' } }));
}

export const PlansSummary: React.VFC<{ result: ProductionPlanDebuggerViewFragment }> = ({ result }) => {
    const createdDate = new Date(result.orderCreated);
    const dueDate = result.committedDateInput ? new Date(result.committedDateInput) : null;
    const earliestArrivalDate = result.earliestArrivalDate ? new Date(result.earliestArrivalDate) : null;

    return (
        <>
            <SimpleTable
                title={'Lab Order'}
                titleInPaper
                PaperProps={{ style: { marginBottom: 24 } }}
                rows={toTableRows([
                    { name: 'Created', value: createdDate.toLocaleString() },
                    { name: 'Committed Date', value: dueDate?.toLocaleDateString() },
                    { name: 'Working Days', value: dueDate ? differenceInBusinessDays(dueDate, createdDate) : null },
                ])}
            />
            <SimpleTable
                titleInPaper
                title={'Plan'}
                PaperProps={{ style: { marginBottom: 24 } }}
                rows={toTableRows([
                    { name: 'Plans generated', value: result.totalPlansConsidered },
                    { name: 'Earliest Arrival', value: earliestArrivalDate?.toLocaleDateString() },
                    {
                        name: 'Working Days',
                        value: earliestArrivalDate ? differenceInBusinessDays(earliestArrivalDate, createdDate) : null,
                    },
                ])}
            />
        </>
    );
};
