import { IOrganizationType } from '@orthly/retainer-common';
import { DandyDIcon, LabChatIcon, PracticeChatIcon } from '@orthly/ui';
import { FlossPalette, makeStyles, createStyles, Avatar, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        avatarLab: {
            background: FlossPalette.BLACK,
            borderRadius: 8,
            color: FlossPalette.WHITE,
            width: 24,
            height: 24,
        },
        avatarPractice: {
            background: FlossPalette.STAR_GRASS,
            borderRadius: 8,
            color: FlossPalette.WHITE,
            width: 24,
            height: 24,
        },
        avatarPSR: {
            background: FlossPalette.WHITE,
            borderRadius: 8,
            color: FlossPalette.BLACK,
            width: 24,
            height: 24,
        },
        internalIcon: {
            width: 16,
            height: 16,
        },
    }),
);

export const DandyChatAvatar: React.FC<{ senderRole?: string; username: string }> = props => {
    const classes = useStyles();
    const { senderRole, username } = props;
    const { className, component } = React.useMemo(() => {
        if (!senderRole) {
            return {
                className: classes.avatarLab,
                component: <DandyDIcon className={classes.internalIcon} viewBox={'0 0 16 16'} />,
            };
        }
        switch (senderRole) {
            case IOrganizationType.practice:
                return {
                    className: classes.avatarPractice,
                    component: <PracticeChatIcon className={classes.internalIcon} viewBox={'0 0 16 16'} />,
                };
            case IOrganizationType.lab:
                return {
                    className: classes.avatarLab,
                    component: <LabChatIcon className={classes.internalIcon} viewBox={'0 0 16 16'} />,
                };
            case IOrganizationType.internal:
            default:
                return {
                    className: classes.avatarPSR,
                    component: <Text style={{ color: FlossPalette.BLACK, fontWeight: 600 }}>{username.charAt(0)}</Text>,
                };
        }
    }, [senderRole, classes, username]);
    return (
        <Avatar className={className} variant={'rounded'}>
            {component}
        </Avatar>
    );
};
