import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import { LabsGqlLabPriceRuleType, LabsGqlOrderItemSleepApneaType } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';
import { startCase } from 'lodash';

export const SleepApneaForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.SleepApnea>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.SleepApnea>({
                sleep_apnea_type: {
                    type: 'select',
                    label: 'Device Type',
                    options: Object.values(LabsGqlOrderItemSleepApneaType).map(value => ({
                        value,
                        label: startCase(value),
                    })),
                },
                discluding_ramp: {
                    type: 'boolean',
                    label: 'Includes Anterior Discluding Ramp?',
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          discluding_ramp:
                              price.rule.__typename === 'LabPriceSleepApneaRuleDTO'
                                  ? !!price.rule.discluding_ramp
                                  : null,
                          sleep_apnea_type:
                              price.rule.__typename === 'LabPriceSleepApneaRuleDTO'
                                  ? price.rule.sleep_apnea_type
                                  : null,
                      }
                    : {}
            }
            onSubmit={async result => {
                await submit({
                    name: result.name,
                    rule: {
                        type: LabsGqlLabPriceRuleType.SleepApnea,
                        sleep_apnea_type: result.sleep_apnea_type || null,
                        discluding_ramp: !!result.discluding_ramp,
                    },
                });
            }}
            disabled={disabled}
        />
    );
};
