import { getPossibleAbutmentPartValues } from '../../../AbutmentPartsCalculator/AbutmentPartInput';
import type { AbutmentPartFilters } from '../../../AbutmentPartsCalculator/AbutmentPartSelector';
import { AbutmentPartsCalculatorResults } from '../../../AbutmentPartsCalculator/AbutmentPartsCalculatorResults';
import {
    formatAbutmentPartsSpecsText,
    fromPriorityNumberToIsPreferred,
} from '../../../AbutmentPartsCalculator/helpers';
import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { useListAbutmentPartByImplantAndScanbodyQuery } from '@orthly/graphql-react';
import { CartItemV2Utils } from '@orthly/items';
import type { LabOrderItemSKUType } from '@orthly/items';
import { SimpleSelect, SimpleAutocomplete } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

enum NonPreferredReasons {
    TALLER_ABUTMENT_NEEDED = 'TALLER_ABUTMENT_NEEDED',
    LARGER_BLANK_NEEDED = 'LARGER_BLANK_NEEDED',
    BACKORDER = 'BACKORDER',
    NO_PREFERRED_OPTION_AVAILABLE = 'NO_PREFERRED_OPTION_AVAILABLE',
    OTHER = 'OTHER',
}

const NonPreferredReasonsLabelMap: { [K in NonPreferredReasons]: string } = {
    [NonPreferredReasons.TALLER_ABUTMENT_NEEDED]: 'Taller abutment/gingival height needed',
    [NonPreferredReasons.LARGER_BLANK_NEEDED]: 'Larger blank needed',
    [NonPreferredReasons.BACKORDER]: 'Backorder',
    [NonPreferredReasons.NO_PREFERRED_OPTION_AVAILABLE]: 'No preferred option available',
    [NonPreferredReasons.OTHER]: 'Other',
};

const useAbutmentPartsData = (implantToScanbodyId: string | null) => {
    const { data: abutmentPartsData } = useListAbutmentPartByImplantAndScanbodyQuery({
        variables: { implantToScanbodyId: implantToScanbodyId ?? '' },
        skip: !implantToScanbodyId,
    });

    return implantToScanbodyId ? abutmentPartsData?.listAbutmentPartByImplantAndScanbody ?? [] : [];
};

interface ItemEditorV2AbutmentPartFieldsProps
    extends Omit<ItemEditorV2BaseProps<LabOrderItemSKUType.Implant | LabOrderItemSKUType.ImplantBridge>, 'showError'> {
    implantToScanbodyId: string | null;
}

// eslint-disable-next-line max-lines-per-function
export const ItemEditorV2AbutmentPartFields: React.VFC<ItemEditorV2AbutmentPartFieldsProps> = ({
    item,
    updateItem,
    implantToScanbodyId,
}) => {
    const implantMetadata = CartItemV2Utils.getImplantMetadata(item);

    const [abutmentPartFilters, setAbutmentPartFilters] = React.useState<AbutmentPartFilters>({
        showPreferred: true,
    });

    const abutmentPartsData = useAbutmentPartsData(implantToScanbodyId);

    const isInitialized = React.useRef<boolean>(false);

    // if we have a part id already set, look for the part in the abutment data and set the filters
    React.useEffect(() => {
        if (!isInitialized.current) {
            if (implantMetadata?.part_id) {
                const abutmentPartToImplantAndScanbody = abutmentPartsData.find(
                    part => part.abutment_part.id === implantMetadata.part_id,
                );
                if (abutmentPartToImplantAndScanbody) {
                    setAbutmentPartFilters({
                        showPreferred: fromPriorityNumberToIsPreferred(abutmentPartToImplantAndScanbody.priority),
                        manufacturer: abutmentPartToImplantAndScanbody.abutment_part.manufacturer,
                        material: abutmentPartToImplantAndScanbody.abutment_part.material,
                        engagement: abutmentPartToImplantAndScanbody.abutment_part.engagement,
                        specs: formatAbutmentPartsSpecsText(abutmentPartToImplantAndScanbody.abutment_part),
                    });
                    isInitialized.current = true;
                } else if (abutmentPartsData.length > 0) {
                    // if we have a scanbody id but can't find it in the abutment part data (after loading), clear it
                    updateItem({ name: 'implant_metadata', payload: { part_id: null } });
                    isInitialized.current = true;
                }
                // in this case, with no matching abutment part relationship and the parts haven't loaded, we intentionally do not
                // set isInitialized to true so that we run through this hook again once they do load
            } else {
                isInitialized.current = true;
            }
        }
    }, [abutmentPartsData, abutmentPartFilters, implantMetadata?.part_id, updateItem]);

    // if the implantToScanbodyId changes from something -> null or something -> something else, reset the filters
    // we do not consider null -> something because that will happen on initial load if a scanbody_id was already set
    // *after* we've already set our initial filters (thus overwriting them back to nothing)
    const previousImplantToScanbodyId = React.useRef<string | null>(implantToScanbodyId);
    React.useEffect(() => {
        if (previousImplantToScanbodyId.current !== implantToScanbodyId) {
            if (previousImplantToScanbodyId.current) {
                setAbutmentPartFilters({ showPreferred: true });
            }
            previousImplantToScanbodyId.current = implantToScanbodyId;
        }
    }, [implantToScanbodyId]);

    const selectedAbutmentPartToImplantScanbody = React.useMemo(
        () =>
            abutmentPartsData.find(
                part =>
                    abutmentPartFilters.engagement === part.abutment_part.engagement &&
                    abutmentPartFilters.material === part.abutment_part.material &&
                    abutmentPartFilters.manufacturer === part.abutment_part.manufacturer &&
                    abutmentPartFilters.specs === formatAbutmentPartsSpecsText(part.abutment_part) &&
                    abutmentPartFilters.showPreferred === fromPriorityNumberToIsPreferred(part.priority),
            ),
        [abutmentPartFilters, abutmentPartsData],
    );

    // if we have a selected part, update the item with the part id and mfg
    // otherwise, clear any part id or mfg that may have been set
    React.useEffect(() => {
        if (selectedAbutmentPartToImplantScanbody?.abutment_part) {
            if (implantMetadata?.part_id !== selectedAbutmentPartToImplantScanbody.abutment_part.id) {
                updateItem({
                    name: 'implant_metadata',
                    payload: { part_id: selectedAbutmentPartToImplantScanbody.abutment_part.id },
                });
            }
            if (
                implantMetadata?.abutment_part_manufacturer !==
                selectedAbutmentPartToImplantScanbody.abutment_part.manufacturer
            ) {
                updateItem({
                    name: 'implant_metadata',
                    payload: {
                        abutment_part_manufacturer: selectedAbutmentPartToImplantScanbody.abutment_part.manufacturer,
                    },
                });
            }
        } else if (isInitialized.current) {
            if (implantMetadata?.part_id) {
                updateItem({ name: 'implant_metadata', payload: { part_id: null } });
            }
            if (implantMetadata?.abutment_part_manufacturer) {
                updateItem({ name: 'implant_metadata', payload: { abutment_part_manufacturer: null } });
            }
        }
    }, [
        selectedAbutmentPartToImplantScanbody?.abutment_part,
        implantMetadata?.part_id,
        implantMetadata?.abutment_part_manufacturer,
        updateItem,
    ]);

    return (
        <>
            <Grid item xs={12} md={4}>
                <SimpleSelect
                    label={'Preferred or Non-Preferred'}
                    value={abutmentPartFilters.showPreferred ? 'preferred' : 'non_preferred'}
                    options={[
                        {
                            value: 'preferred',
                            label: 'Preferred',
                        },
                        {
                            value: 'non_preferred',
                            label: 'Non-Preferred',
                        },
                    ]}
                    onChange={val => {
                        setAbutmentPartFilters({ showPreferred: val === 'preferred' });
                        updateItem({ name: 'implant_metadata', payload: { non_preferred_part_reason: null } });
                    }}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <SimpleAutocomplete
                    label={'Abutment Manufacturer'}
                    key={`${abutmentPartFilters.showPreferred}_${abutmentPartFilters.manufacturer}`}
                    variant={'standard'}
                    AutocompleteProps={{ value: abutmentPartFilters.manufacturer }}
                    options={getPossibleAbutmentPartValues(abutmentPartsData, abutmentPartFilters, 'manufacturer')}
                    onChange={val =>
                        setAbutmentPartFilters(filters => ({
                            ...filters,
                            manufacturer: val ?? undefined,
                            material: undefined,
                            engagement: undefined,
                            specs: undefined,
                        }))
                    }
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <SimpleAutocomplete
                    label={'Abutment Material'}
                    key={`${abutmentPartFilters.manufacturer}_${abutmentPartFilters.material}}`}
                    variant={'standard'}
                    AutocompleteProps={{ value: abutmentPartFilters.material }}
                    options={getPossibleAbutmentPartValues(abutmentPartsData, abutmentPartFilters, 'material')}
                    onChange={val =>
                        setAbutmentPartFilters(filters => ({
                            ...filters,
                            material: val ?? undefined,
                            engagement: undefined,
                            specs: undefined,
                        }))
                    }
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <SimpleAutocomplete
                    label={'Abutment Connection'}
                    key={`${abutmentPartFilters.material}_${abutmentPartFilters.engagement}}`}
                    variant={'standard'}
                    AutocompleteProps={{ value: abutmentPartFilters.engagement }}
                    options={getPossibleAbutmentPartValues(abutmentPartsData, abutmentPartFilters, 'engagement')}
                    onChange={val =>
                        setAbutmentPartFilters(filters => ({
                            ...filters,
                            engagement: val ?? undefined,
                            specs: undefined,
                        }))
                    }
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <SimpleAutocomplete
                    label={'Part Specs'}
                    key={`${abutmentPartFilters.engagement}_${abutmentPartFilters.specs}}`}
                    variant={'standard'}
                    AutocompleteProps={{ value: abutmentPartFilters.specs }}
                    options={getPossibleAbutmentPartValues(abutmentPartsData, abutmentPartFilters, 'specs')}
                    onChange={val =>
                        setAbutmentPartFilters(filters => ({
                            ...filters,
                            specs: val ?? undefined,
                        }))
                    }
                />
            </Grid>
            {!abutmentPartFilters.showPreferred && (
                <Grid item xs={3}>
                    <SimpleSelect
                        label={'Reason for Non-Preferred'}
                        value={implantMetadata?.non_preferred_part_reason ?? undefined}
                        options={Object.values(NonPreferredReasons).map(option => ({
                            value: option,
                            label: NonPreferredReasonsLabelMap[option],
                        }))}
                        onChange={val =>
                            updateItem({ name: 'implant_metadata', payload: { non_preferred_part_reason: val } })
                        }
                    />
                </Grid>
            )}
            {selectedAbutmentPartToImplantScanbody && (
                <Grid item xs={12}>
                    <AbutmentPartsCalculatorResults
                        abutmentPartToImplantAndScanbody={selectedAbutmentPartToImplantScanbody}
                    />
                </Grid>
            )}
        </>
    );
};
