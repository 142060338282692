import occlusal_anatomy_cusp_mimic_ACCEPTABLE from '../../../../assets/images/design-qc/occlusal_anatomy/occlusal_anatomy_cusp_mimic_ACCEPTABLE.png';
import occlusal_anatomy_cusp_mimic_BAD from '../../../../assets/images/design-qc/occlusal_anatomy/occlusal_anatomy_cusp_mimic_BAD.png';
import occlusal_anatomy_cusp_mimic_GOOD from '../../../../assets/images/design-qc/occlusal_anatomy/occlusal_anatomy_cusp_mimic_GOOD.png';
import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { DEFAULT_SCAN_APPEARANCE, DEFAULT_RESTORATIVE_APPEARANCE } from '@orthly/dentin';

export const occlusalAnatomyRubric: DesignQcRubricCategoryEntry = {
    category: 'occlusal_anatomy',
    subcategories: [
        {
            category: 'occlusal_anatomy',
            name: 'Primary & Secondary Grooves',
            grading: {
                type: 'tertiary',
                bad: 'Library grooves dramatically modified/eliminated',
                acceptable: '"Know it when you see it"<br>OR<br>Escalate to Senior',
                ideal: 'Library grooves remain untouched/minimally adjusted',
            },
            description: `FYI - This is not currently well-defined in SOP and our philosophy. Only watch for non-adherence to special anatomy requests<br><br>Check for adherence to library and how much/little designer changes<br><br>"You know it when you see it"`,
            selectable_bad_reasons: ['Occlusal grooves are eliminated', 'Occlusal grooves are overly modified'],
            edge_case:
                'Minimum thickness does not allow for ideal anatomy. Even then, anatomy should not be completely washed.',
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
        },
        {
            category: 'occlusal_anatomy',
            name: 'Ridges',
            grading: {
                type: 'binary',
                bad: 'Limits excursive movements',
                ideal: 'Does not limit excursive movements',
            },
            description: `Must not limit the excursive movements<br><Br>Either you have inappropriate collisions, or you don't`,
            selectable_bad_reasons: ['Occlusal anatomy is limiting excursive movements'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
        },
        {
            category: 'occlusal_anatomy',
            name: 'Cusp Shape / Length',
            grading: {
                type: 'tertiary',
                bad: 'Cusp shape & length of restoration does not mimic adjacent natural dentition',
                acceptable:
                    'Cusp shape & length of restoration is close-ish to adjacent natural dentition<br><br>"Know it when you see it"<br>OR<br>Escalate to Senior',
                ideal: 'Cusp shape & length of restoration mimics adjacent natural dentition',
            },
            examples: {
                bad: [occlusal_anatomy_cusp_mimic_BAD],
                acceptable: [occlusal_anatomy_cusp_mimic_ACCEPTABLE],
                ideal: [occlusal_anatomy_cusp_mimic_GOOD],
            },
            description: `Cusps shape and length should mimic adjacent and/or contralateral<br><br>"You know it when you see it"`,
            selectable_bad_reasons: [
                'Cusp shape does not mimic adjacent teeth',
                'Cusp length does not mimic adjacent teeth',
            ],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
        },
    ],
    design_standard: {
        googleEmbedStub: `2PACX-1vRv1pe6CAuV55bImMOMXJsGfdGhOsQT_OLXj9I00Rl2GqCZIs09DTZji59t8qnl9q1ScpnRe2E8Jvjt`,
    },
};
