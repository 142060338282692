import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import { LabsGqlLabPriceRuleType, LabsGqlOrderItemTmjType } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';
import { startCase } from 'lodash';
import React from 'react';

export const TMJForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    const [selectedTMJType, setSelectedTMJType] = React.useState<string | undefined>(undefined);
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.Tmj>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.Tmj>({
                tmj_type: {
                    type: 'select',
                    label: 'Type',
                    options: Object.values(LabsGqlOrderItemTmjType).map(value => ({ value, label: startCase(value) })),
                },
                includes_wiring: {
                    type: 'boolean',
                    label: 'Includes Wire Running Through?',
                    hidden: selectedTMJType !== LabsGqlOrderItemTmjType.AnteriorDeprogrammer,
                },
            })}
            onChange={formValues => {
                if (formValues.tmj_type && formValues.tmj_type !== selectedTMJType) {
                    setSelectedTMJType(formValues.tmj_type);
                }
            }}
            initialValues={
                price
                    ? {
                          name: price.name,
                          tmj_type: price.rule.__typename === 'LabPriceTMJRuleDTO' ? price.rule.tmj_type : null,
                          includes_wiring:
                              price.rule.__typename === 'LabPriceTMJRuleDTO' ? price.rule.includes_wiring : null,
                      }
                    : {}
            }
            onSubmit={async result => {
                const rule = {
                    type: LabsGqlLabPriceRuleType.Tmj,
                    tmj_type: result.tmj_type || null,
                    includes_wiring:
                        result.tmj_type === LabsGqlOrderItemTmjType.AnteriorDeprogrammer
                            ? result.includes_wiring
                            : null,
                };
                await submit({
                    name: result.name,
                    rule,
                });
            }}
            disabled={disabled}
        />
    );
};
