import { UsersActions } from './Users.actions';
import type { UsersFormState, UsersState } from './Users.types';
import type { LabsGqlUserDtoFragment } from '@orthly/graphql-operations';
import { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import { DANDY_INTERNAL_ID } from '@orthly/retainer-common';
import type { Action } from 'redux-actions';
import { handleActions } from 'redux-actions';

const UsersInitialFormState: UsersFormState = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: undefined,
    mobile_phone_number: undefined,
    password: {
        value: undefined,
        visible: false,
    },
    roles: [],
    rolesLoaded: true,
};

export const UsersInitialState: UsersState = {
    form: UsersInitialFormState,
    open: false,
    edited: false,
    view: {
        screen: 'all',
        search: undefined,
        orgId: undefined,
    },
};

// Builds the current state given some user
// This will populate all of the needed state vars to the best of its ability, using default values when there isn't a clear answer.
export function userToFormState(user?: LabsGqlUserDtoFragment): UsersFormState {
    if (!user) {
        return { ...UsersInitialFormState, password: { value: undefined, visible: true } };
    }

    const { first_name, last_name, email, phone_number, mobile_phone_number } = user;

    return {
        first_name,
        last_name,
        email,
        phone_number: phone_number ?? undefined,
        mobile_phone_number: mobile_phone_number ?? undefined,
        password: {
            value: undefined,
            visible: false,
        },
        roles: [],
        rolesLoaded: !!user, // If it's a new user, we already have all of their roles loaded by definition
    };
}

export function isUserCommonFieldsUpdated(user: LabsGqlUserDtoFragment, form: UsersFormState) {
    // Check the common field values to see if any have changed.
    const checkFields: (keyof UsersFormState & keyof LabsGqlUserDtoFragment)[] = [
        'first_name',
        'last_name',
        'email',
        'phone_number',
        'mobile_phone_number',
    ];

    // We have to do ?? undefined to avoid null vs undefined problems.
    return !!checkFields.find(field => (user[field] ?? undefined) !== (form[field] ?? undefined));
}

function isUserEdited(state: UsersState) {
    const { user, form } = state;

    // No form being edited, so no state change.
    if (!form) {
        return false;
    }

    // This is a new user, and thus is always being edited.
    if (!user) {
        return true;
    }

    if (isUserCommonFieldsUpdated(user, form)) {
        return true;
    }

    // And now we finally look to see if the password is set
    // Password are considered savable if the length is >= 7.
    return !!form.password.value && form.password.value.length >= 7 && form.password.visible;
}

// Reducer specifically for the form object.
// This is where almost all of the heavy lifting of this redux lives.
const formReducer = handleActions<UsersFormState, any>(
    {
        ...UsersActions.UPDATE_COMMON_FIELDS.reducer<UsersFormState>((state, action) => ({
            ...state,
            [action.payload.property]: action.payload.value,
        })),
        ...UsersActions.ADD_ROLE.reducer<UsersFormState>((state, _action) => ({
            ...state,
            roles: [...state.roles, { organization_id: undefined, type: undefined, roles: [], editing: true }],
        })),
        ...UsersActions.UPDATE_ROLE.reducer<UsersFormState>((state, action) => ({
            ...state,
            roles: state.roles.map((role, idx) => {
                if (idx !== action.payload.idx) {
                    return role;
                }

                if (action.payload.property === 'type') {
                    // If the new role is internal, we autocomplete some data to cover the most common use case.
                    return action.payload.value === LabsGqlOrganizationType.Internal
                        ? {
                              type: LabsGqlOrganizationType.Internal,
                              organization_id: DANDY_INTERNAL_ID,
                              roles: ['internal__employee'],
                              editing: true,
                          }
                        : {
                              type: action.payload.value as LabsGqlOrganizationType,
                              organization_id: undefined,
                              roles: [],
                              editing: true,
                          };
                }

                return {
                    ...role,
                    [action.payload.property]: action.payload.value,
                };
            }),
        })),
        ...UsersActions.DELETE_ROLE.reducer<UsersFormState>((state, action) => ({
            ...state,
            roles: state.roles.map((role, idx) => (idx === action.payload.idx ? { ...role, deactivated: true } : role)),
        })),
        ...UsersActions.TOGGLE_PASSWORD.reducer<UsersFormState>((state, _action) => ({
            ...state,
            password: {
                value: undefined,
                visible: !state.password.visible,
            },
        })),
        ...UsersActions.UPDATE_PASSWORD.reducer<UsersFormState>((state, action) => ({
            ...state,
            password: {
                value: action.payload.password,
                visible: true,
            },
        })),
        ...UsersActions.ROLES_LOADED.reducer<UsersFormState>((state, action) => ({
            ...state,
            rolesLoaded: true,
            roles: action.payload.roles.map(role => ({
                organization_id: role.organization_id,
                type: role.organization_type,
                member_id: role.id,
                roles: role.roles,
                editing: false,
                deactivated: !!role.deactivated_at,
            })),
        })),
    },
    UsersInitialFormState,
);

function reduceForm(state: UsersState, action: Action<any>): UsersState {
    const newState: UsersState = {
        ...state,
        form: state.form ? formReducer(state.form, action) : undefined,
    };
    return {
        ...newState,
        edited: isUserEdited(newState),
    };
}

export const usersReducer = handleActions<UsersState, any>(
    {
        ...Object.values(UsersActions).reduce(
            (state, action) => ({
                ...state,
                ...(action.reducer as any)(reduceForm),
            }),
            {},
        ),
        ...UsersActions.START_EDITING.reducer<UsersState>((state, action) => {
            const updatedForm = userToFormState(action.payload.user);
            return {
                ...state,
                user: action.payload.user,
                form: { ...updatedForm, roles: state.form?.roles ?? [] },
                open: true,
                edited: false,
            };
        }),
        ...UsersActions.STOP_EDITING.reducer<UsersState>((state, _action) => {
            return { ...UsersInitialState, open: false, view: state.view };
        }),
        ...UsersActions.USER_SAVED.reducer<UsersState>((state, action) => {
            const updatedForm = userToFormState(action.payload.user);
            return {
                ...state,
                user: action.payload.user,
                form: { ...updatedForm, roles: state.form?.roles ?? [] },
                open: true,
                edited: false,
            };
        }),
        ...UsersActions.SCREEN_SET.reducer<UsersState>((state, action) => ({
            ...state,
            view: {
                ...state.view,
                screen: action.payload.screen,
            },
        })),
        ...UsersActions.SET_SEARCH.reducer<UsersState>((state, action) => ({
            ...state,
            view: {
                ...state.view,
                search: action.payload.search,
                orgId: action.payload.orgId,
            },
        })),
        ...UsersActions.ROLE_SAVED.reducer<UsersState>((state, action) => ({
            ...state,
            form: state.form
                ? {
                      ...state.form,
                      // Replace the role that used this organization w/ the new role directly from the live result
                      roles: state.form.roles.map(role => {
                          if (role.organization_id !== action.payload.org.id) {
                              return role;
                          }

                          const staffMember = action.payload.org.staff.find(staff => staff.user_id === state.user?.id);

                          return {
                              ...role,
                              roles: staffMember?.roles ?? [],
                              member_id: staffMember?.id,
                              deactivated: !!staffMember?.deactivated_at,
                          };
                      }),
                  }
                : undefined,
        })),
    },
    UsersInitialState,
);
