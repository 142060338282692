import { useOrderDetailContext } from '../../state/OrderDetailProvider.graphql';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { QuickForm, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

const AdminOrderDetailToolbarProductionOrderOptOut_Mutation = graphql(`
    mutation AdminOrderDetailToolbarProductionOrderOptOut_Mutation($data: OptOutProductionOrderCommand!) {
        optOutProductionOrder(data: $data) {
            id
        }
    }
`);

export const OrderDetailToolbarProductionOrder: React.VFC = () => {
    const { order, refetch } = useOrderDetailContext();

    const optOutMutation = useMutation(AdminOrderDetailToolbarProductionOrderOptOut_Mutation);
    const { submit, submitting, open, setOpen } = useRootActionCommand(optOutMutation, {
        onSuccess: () => {
            refetch().catch(console.error);
        },
        successMessage: 'Opted out of production order',
    });

    if (!order?.uses_production_order) {
        return null;
    }

    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            loading={submitting}
            title={'Opt Out of Production Order System'}
            CustomButton={({ onClick }) => (
                <Button variant={'ghost'} onClick={onClick} style={{ marginRight: 8 }}>
                    Using Production Order
                </Button>
            )}
            content={
                <QuickForm<{ reason: string }>
                    fields={{
                        reason: {
                            type: 'text',
                            label: 'Please describe why this order needed to be opted out',
                            fieldProps: {
                                multiline: true,
                                minRows: 3,
                            },
                        },
                    }}
                    initialValues={{}}
                    submitButtonTitle={'Confirm Opt Out'}
                    onSubmit={data => submit({ data: { order_id: order.id, reason: data.reason } })}
                />
            }
        />
    );
};
