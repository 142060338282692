import { ItemEditorV2DialogField } from '../../components/ItemEditorV2DialogField';
import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { ItemAbutmentMaterialSelect, useAutoResetAbutmentMaterial } from '@orthly/dentin';
import type { LabOrderItemSKUType } from '@orthly/items';
import { ItemMaterialFieldV2Utils } from '@orthly/items';
import { SimpleSelect } from '@orthly/ui';
import React from 'react';

const ItemEditorV2DialogAbutmentMaterialField: React.VFC<
    ItemEditorV2BaseProps<LabOrderItemSKUType.Implant | LabOrderItemSKUType.ImplantBridge>
> = ({ item, updateItem, showError }) => {
    const value = ItemMaterialFieldV2Utils.getSingleMaterial(item, 'abutment');

    const onChange = React.useCallback(val => updateItem({ name: 'abutment_material', payload: val }), [updateItem]);

    useAutoResetAbutmentMaterial({
        item,
        value,
        onChange,
        useDefaultValue: false,
    });

    return (
        <ItemEditorV2DialogField
            fieldLabel={'Abutment Material'}
            fieldValue={value}
            modalTitle={'Select Abutment Material'}
            modalContent={<ItemAbutmentMaterialSelect item={item} value={value} onChange={onChange} />}
            // the only way to get the tooltips to display properly after mount is to force a deep re-render,
            // which apparently only happens when the provided value changes, so we just set it to itself again on open
            onOpen={() => onChange(value)}
            errorText={showError && !value ? 'This field is required' : undefined}
        />
    );
};

const ItemEditorV2SimpleAbutmentMaterialField: React.VFC<
    ItemEditorV2BaseProps<LabOrderItemSKUType.Implant | LabOrderItemSKUType.ImplantBridge>
> = ({ item, updateItem, showError }) => {
    const value = ItemMaterialFieldV2Utils.getSingleMaterial(item, 'abutment');

    return (
        <SimpleSelect
            label={'Abutment Material'}
            value={value}
            options={[
                'Dandy Recommended',
                'Custom Titanium Abutment',
                'Ti-Base',
                'Custom Zirconia Hybrid Abutment',
            ].map(value => ({ value, label: value }))}
            onChange={val => updateItem({ name: 'abutment_material', payload: val })}
            errorText={showError && !value ? 'This field is required' : undefined}
        />
    );
};

interface ItemEditorV2AbutmentMaterialFieldProps
    extends ItemEditorV2BaseProps<LabOrderItemSKUType.Implant | LabOrderItemSKUType.ImplantBridge> {
    isInternal: boolean;
}

export const ItemEditorV2AbutmentMaterialField: React.VFC<ItemEditorV2AbutmentMaterialFieldProps> = ({
    isInternal,
    ...props
}) => {
    // For internal users we show a simple field with all available options since they may have specific circumstances
    // where they need to break the rules that we apply to practices. Otherwise, we provide the same experience as in checkout.
    return isInternal ? (
        <ItemEditorV2SimpleAbutmentMaterialField {...props} />
    ) : (
        <ItemEditorV2DialogAbutmentMaterialField {...props} />
    );
};
