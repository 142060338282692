import { RouterTabs } from '../../components/RouterTabs';
import { RouterUtils } from '../../utils/router/RouterUtils';
import { OrganizationDetailsRoot } from './OrganizationDetails/OrganizationDetailsRoot';
import { OrganizationsTable } from './OrganizationsTable';
import { PracticeDetailsRoot } from './PracticeDetails/PracticeDetailsRoot.graphql';
import { PracticesTable } from './PracticesTable';
import { ScanbodyOrdersTable } from './ScanbodyOrdersTable';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

const OrganizationsRoot: React.FC = () => {
    const basePath = `${RouterUtils.pathForScreen('accounts')}/organizations`;
    return (
        <Switch>
            <Route path={`${basePath}/:organizationId`} component={OrganizationDetailsRoot} />
            <Route path={`${basePath}`}>
                <OrganizationsTable />
            </Route>
            <Route>
                <Redirect to={basePath} />
            </Route>
        </Switch>
    );
};

const PracticesRoot: React.FC = () => {
    const basePath = `${RouterUtils.pathForScreen('accounts')}/practices`;
    return (
        <Switch>
            <Route path={`${basePath}/:practiceId`} component={PracticeDetailsRoot} />
            <Route path={`${basePath}`}>
                <PracticesTable />
            </Route>
            <Route>
                <Redirect to={basePath} />
            </Route>
        </Switch>
    );
};

export const AccountsRoot: React.VFC = () => {
    return (
        <RouterTabs
            defaultPath={'practices'}
            items={[
                { path: 'practices', label: 'Practices', Component: PracticesRoot },
                { path: 'organizations', label: 'Organizations', Component: OrganizationsRoot },
                { path: 'scanbodies', label: 'Scan body Orders', Component: ScanbodyOrdersTable },
            ]}
        />
    );
};
