import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import {
    LabsGqlDenturesFabricationMethod,
    LabsGqlDenturesSingleFulfillmentArchType,
    LabsGqlDenturesTeethQuality,
    LabsGqlLabPriceRuleType,
} from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';

export const DentureArchForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.DentureArch>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.DentureArch>({
                arch_type: {
                    type: 'select',
                    label: 'Arch Type',
                    options: Object.values(LabsGqlDenturesSingleFulfillmentArchType),
                },
                fabrication_method: {
                    type: 'select',
                    label: 'Fabrication Method',
                    options: Object.values(LabsGqlDenturesFabricationMethod),
                },
                teeth_quality: {
                    type: 'select',
                    label: 'Teeth Quality',
                    options: Object.values(LabsGqlDenturesTeethQuality),
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          arch_type:
                              price.rule.__typename === 'LabPriceDentureArchRuleDTO' ? price.rule.arch_type : null,
                          fabrication_method:
                              price.rule.__typename === 'LabPriceDentureArchRuleDTO'
                                  ? price.rule.fabrication_method
                                  : null,
                          teeth_quality:
                              price.rule.__typename === 'LabPriceDentureArchRuleDTO' ? price.rule.teeth_quality : null,
                      }
                    : {}
            }
            onSubmit={async result => {
                await submit({
                    name: result.name,
                    rule: {
                        type: LabsGqlLabPriceRuleType.DentureArch,
                        arch_type: result.arch_type || null,
                        fabrication_method: result.fabrication_method || null,
                        teeth_quality: result.teeth_quality || null,
                    },
                });
            }}
            disabled={disabled}
        />
    );
};
