import { useQCItemConfigs } from '../../../../../Fields/useQCItemConfigs';
import type { OrderDetailSidebarTabPieces } from '../../OrderDetail.types';
import { useOrderDetailContext } from '../../state/OrderDetailProvider.graphql';
import type { LabsGqlAddManualQualityControlItemMutationVariables } from '@orthly/graphql-operations';
import { useAddManualQualityControlItemMutation } from '@orthly/graphql-react';
import { getFullStoragePath, MfgStorageConfigs } from '@orthly/shared-types';
import type { CustomQFComponentProps, ButtonProps } from '@orthly/ui';
import {
    LoadBlocker,
    useChangeSubmissionFn,
    RootActionDialog,
    SimpleSelect,
    QuickForm,
    OrthlyBrowserConfig,
} from '@orthly/ui';
import {
    stylesFactory,
    Button,
    Text,
    FlossPalette,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Grid,
    IconButton,
    ListItemIcon,
    Tooltip,
    DeleteIcon,
    Icon,
} from '@orthly/ui-primitives';
import type { QCItemChecklistProps } from '@orthly/veneer';
import {
    useQCItemChecklistProps,
    QCItemChecklist,
    useFirebasePreviewMulti,
    getQFUploadMultiFileFieldDef,
} from '@orthly/veneer';
import React from 'react';
import { z } from 'zod';

export const OrderDetailQCListHeader: React.FC<QCItemChecklistProps> = ({ loading, refetch }) => {
    return (
        <LoadBlocker blocking={loading} ContainerProps={{ style: { width: 'auto' } }}>
            <IconButton size={'small'} onClick={() => refetch?.()} disabled={loading}>
                <Tooltip title={'Refresh QC Items'}>
                    <Icon icon={'RefreshIcon'} />
                </Tooltip>
            </IconButton>
        </LoadBlocker>
    );
};

type AddManualItemVars = LabsGqlAddManualQualityControlItemMutationVariables['data'];

export function useAddManualQualityControlItem(onSuccess?: () => Promise<unknown>) {
    const [submitMtn] = useAddManualQualityControlItemMutation();
    const mtnSubmitter = (data: AddManualItemVars) => submitMtn({ variables: { data } });
    return useChangeSubmissionFn<any, [AddManualItemVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['QC Item Added!', {}],
        onSuccess: async () => await onSuccess?.(),
    });
}

type AddQcItemFormVars = Omit<AddManualItemVars, 'orderId' | 'attachments'> & {
    existingAttachments?: string[];
    newAttachments?: string[];
};

const useStyles = stylesFactory(() => ({
    previewImg: {
        display: 'flex',
        flexDirection: 'column',
        width: 'auto',
        maxWidth: '100%',
        maxHeight: 60,
        height: 'auto',
        padding: '5px 10px',
    },
    itemIcon: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        maxWidth: 56,
    },
}));

export const ExistingImagesList: React.VFC<{
    images: string[];
    remove: (index: number) => unknown;
}> = ({ images, remove }) => {
    const classes = useStyles();
    const sources = React.useMemo(
        () => images.map((source, i) => ({ source, name: `Existing image #${i + 1}` })),
        [images],
    );
    const { result } = useFirebasePreviewMulti(sources);
    return (
        <>
            <Text variant={`h6`}>Existing Attachments</Text>
            <List dense style={{ width: `100%` }}>
                {result?.map(({ source, name }, i) => (
                    <ListItem dense={true} button key={source}>
                        <ListItemIcon className={classes.itemIcon}>
                            <img alt={`${name}`} src={source} className={classes.previewImg} />
                        </ListItemIcon>
                        <ListItemText
                            style={{ overflow: 'hidden' }}
                            primary={name}
                            primaryTypographyProps={{
                                style: { textOverflow: 'ellipsis', wordBreak: 'break-all' },
                            }}
                        />
                        <ListItemSecondaryAction>
                            <IconButton edge={'end'} aria-label={'delete'} onClick={() => remove(i)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
        </>
    );
};

const ExistingImagesListField: React.VFC<CustomQFComponentProps> = ({ field, form }) => (
    <ExistingImagesList
        images={field.value ?? []}
        remove={index =>
            form.setFieldValue(
                field.name,
                (field.value ?? []).filter((_: unknown, i: number) => i !== index),
            )
        }
    />
);

export const AddQcItemDialog: React.VFC<{
    orderId: string;
    refetch: () => Promise<unknown>;
    CustomButton?: React.FC<ButtonProps>;
    open: boolean;
    setOpen: (open: boolean) => void;
    fromNote?: { text: string; attachments: string[] };
}> = ({ orderId, refetch, CustomButton, open, setOpen, fromNote }) => {
    const { submit, submitting } = useAddManualQualityControlItem(() => refetch());
    const { configs, loading } = useQCItemConfigs({ includeArchived: false, skip: !open });
    const [configId, setConfigId] = React.useState<string | undefined>(undefined);
    const config = React.useMemo(() => configs.find(c => c.id === configId), [configId, configs]);
    const configOptions = React.useMemo(() => configs.map(c => ({ value: c.id, label: c.title })), [configs]);

    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, MfgStorageConfigs.qcItemsConfig);

    return (
        <RootActionDialog
            title={'Add Quality Control Item'}
            loading={submitting}
            open={open}
            setOpen={setOpen}
            content={
                <>
                    <Grid
                        style={{
                            paddingBottom: '10px',
                            borderBottom: `1px solid ${FlossPalette.GRAY}`,
                            marginBottom: '10px',
                        }}
                    >
                        <LoadBlocker blocking={loading}>
                            <SimpleSelect
                                options={configOptions}
                                onChange={configId => {
                                    setConfigId(configId);
                                }}
                                label={'Use an existing QC config as a template...'}
                                value={configId}
                            />
                        </LoadBlocker>
                    </Grid>
                    <QuickForm<AddQcItemFormVars>
                        resetOnInitialValueChange={true}
                        fields={{
                            title: {
                                type: 'text',
                                label: 'Title',
                                optional: true,
                            },
                            description: {
                                type: 'text',
                                label: 'Description',
                                optional: false,
                            },
                            existingAttachments: {
                                type: `custom`,
                                validation: z.array(z.string()),
                                component: ExistingImagesListField,
                            },
                            newAttachments: getQFUploadMultiFileFieldDef({
                                optional: true,
                                storagePathConfig: storagePathConfig,
                            }),
                        }}
                        initialValues={{
                            title: config?.title ?? ``,
                            description: config?.description ?? fromNote?.text ?? ``,
                            existingAttachments: [...(config?.attachments ?? []), ...(fromNote?.attachments ?? [])],
                            newAttachments: [],
                        }}
                        onSubmit={async ({ existingAttachments = [], newAttachments = [], ...result }) => {
                            await submit({
                                ...result,
                                orderId,
                                attachments: [...existingAttachments, ...newAttachments],
                            });
                            setConfigId(undefined);
                            setOpen(false);
                        }}
                    />
                </>
            }
            buttonText={``}
            {...{ CustomButton }}
        />
    );
};

export const OrderDetailsQCList: React.VFC<QCItemChecklistProps> = props => {
    const { orderId, refetch } = props;
    const [qcOpen, setQcOpen] = React.useState(false);
    return (
        <Grid
            container
            wrap={'nowrap'}
            direction={'column'}
            justifyContent={'space-between'}
            style={{ height: '100%' }}
        >
            <Grid
                container
                direction={'column'}
                wrap={'nowrap'}
                style={{ paddingRight: '16px', height: '100%', overflowY: 'scroll' }}
            >
                <QCItemChecklist {...props} />
            </Grid>
            <AddQcItemDialog
                {...{ orderId, refetch, open: qcOpen, setOpen: setQcOpen }}
                CustomButton={({ onClick }) => (
                    <Button fullWidth variant={`primary`} {...{ onClick }}>
                        Add Quality Control Item
                    </Button>
                )}
            />
        </Grid>
    );
};

const NULL_FUNCTION = () => null;

export function useOrderQcSidebarParts(): OrderDetailSidebarTabPieces {
    const { id: orderId } = useOrderDetailContext();

    const qcProps = useQCItemChecklistProps(orderId);
    const qcItemCount = qcProps.items.filter(i => !i.complete).length;

    // undefined means the add qc item box is closed
    const [qcFromNote, setQcFromNote] = React.useState<{ text: string; attachments: string[] } | undefined>();
    const closeQcBox = React.useCallback(() => setQcFromNote(undefined), []);

    return {
        name: 'QC',
        badgeCount: qcItemCount,
        headerExtras: <OrderDetailQCListHeader {...qcProps} />,
        sidebarBody: (
            <>
                <OrderDetailsQCList {...qcProps} />
                <AddQcItemDialog
                    orderId={orderId}
                    refetch={qcProps.refetch}
                    fromNote={qcFromNote}
                    open={!!qcFromNote}
                    setOpen={closeQcBox}
                    CustomButton={NULL_FUNCTION}
                />
            </>
        ),
    };
}
