import { DevModal } from './DevModal';
import { OrthlyBrowserConfig, useOpenDevModal } from '@orthly/ui';
import { FlossPalette, styled } from '@orthly/ui-primitives';
import React from 'react';
import ReactDOM from 'react-dom';

export type AppContainerProps = {
    disableUpdateNotification?: boolean;
    schema?: any;
};

const NonProdBanner = styled('div')({
    position: `fixed`,
    left: `0px`,
    cursor: 'pointer',
    bottom: `0px`,
    right: `0px`,
    padding: `0 10px`,
    color: FlossPalette.WHITE,
    font: `500 12px/16px Bagoss Standard, Verdana, sans-serif`,
    backgroundColor: FlossPalette.ATTENTION,
    zIndex: Number.MAX_SAFE_INTEGER,
    '@media print': {
        display: 'none',
    },
});

export const AppContainer: React.FC<AppContainerProps> = ({ children }) => {
    const [open, setOpen] = useOpenDevModal();

    // process.env.REACT_APP_FREE_SOLO_MODE disables the safety banner we use to avoid mixing up local and production
    // for those who prefer to live life on the edge
    if (OrthlyBrowserConfig.isProduction || process.env.REACT_APP_LOCAL_PROD || process.env.REACT_APP_FREE_SOLO_MODE) {
        return (
            <>
                {children}
                <DevModal />
            </>
        );
    }

    return (
        <>
            {children}
            {ReactDOM.createPortal(
                <NonProdBanner id={'non-production-environment-banner'} onClick={() => setOpen(!open)}>
                    NON PRODUCTION ENVIRONMENT
                </NonProdBanner>,
                document.body,
            )}
            <DevModal />
        </>
    );
};
