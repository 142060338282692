/* eslint-disable max-lines */
import { useInsertionAxisState, useOperationsState, useSculptingState } from './AppState.hooks';
import type {
    CrossSectionAppState,
    DeformState,
    ISceneAppearanceManager,
    IViewManager,
    MainViewCameraControlsRef,
    SceneAppearanceState,
    SculptingState,
    InsertionAxisManager,
} from '@orthly/dentin';
import {
    DEFAULT_FINISHING_HOT_KEY_CONFIG,
    getRadiusChangeMultiple,
    sliderSteppingDecimalRounding,
    useRegisterHotKeys,
} from '@orthly/dentin';
import { HeatMapType } from '@orthly/forceps';
import React from 'react';

enum HotKeyCategory {
    FinishingApp = 'Finishing App',
    CameraView = 'Camera View',
    Tools = 'Tools',
}

export function useSetupMainViewKeyboardControls(
    manager: ISceneAppearanceManager,
    crossSection: CrossSectionAppState,
    sceneState: SceneAppearanceState,
    sculptingState: SculptingState,
    deformState: DeformState,
    controlsRef: MainViewCameraControlsRef,
    insertionAxisManager: InsertionAxisManager,
) {
    useSetupMainControls(manager, crossSection);
    useSetupHeatmapsKeyboardControls(manager, sceneState);
    useSetupScanAppearanceKeyboardControls(manager);
    useSetupUndoRedoKeyboardControls();
    useSetupSculptingBrushesKeyboardControls();
    useSetupInsertionAxisControls(insertionAxisManager);

    useRegisterMouseWheelControls(sculptingState, deformState, controlsRef);
}

function useSetupMainControls(manager: ISceneAppearanceManager, crossSection: CrossSectionAppState) {
    // Register hotkeys for the main controls
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleCrossSectionActive,
        description: `Toggle cross section`,
        category: HotKeyCategory.FinishingApp,
        action: () => {
            crossSection.appActive.toggle(false);
        },
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleCollisions,
        description: `Toggle collisions`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.setCollisionsVisibility(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleCollisionsWithCurtains,
        description: `Toggle collisions curtains`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.setCollisionsCurtainsVisibility(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleCurtains,
        description: `Toggle curtains`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.setCurtainsVisibility(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleInsertionAxis,
        description: `Toggle insertion axis`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.setInsertionPathsVisibility(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleMargin,
        description: `Toggle margins`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.setMarginLinesVisibility(),
    });
}

function useSetupHeatmapsKeyboardControls(manager: ISceneAppearanceManager, sceneState: SceneAppearanceState) {
    // Register hotkeys for heatmaps
    const proximalHeatmapActive = sceneState.appearance.activeHeatMap === HeatMapType.Proximal;
    const occlusalHeatmapActive = sceneState.appearance.activeHeatMap === HeatMapType.Occlusal;
    const thicknessHeatmapActive = sceneState.appearance.activeHeatMap === HeatMapType.Thickness;
    const undercutHeatmapActive = sceneState.appearance.activeHeatMap === HeatMapType.Undercut;

    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleThicknessHeatmap,
        description: `Toggle thickness heatmap`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.setActiveHeatMap(thicknessHeatmapActive ? undefined : HeatMapType.Thickness),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleOcclusalHeatmap,
        description: `Toggle occlusal heatmap`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.setActiveHeatMap(occlusalHeatmapActive ? undefined : HeatMapType.Occlusal),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleProximalHeatmap,
        description: `Toggle proximal heatmap`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.setActiveHeatMap(proximalHeatmapActive ? undefined : HeatMapType.Proximal),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleRestorativeUndercutHeatmap,
        description: `Toggle restorative undercut heatmap`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.setActiveHeatMap(undercutHeatmapActive ? undefined : HeatMapType.Undercut),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleSculptMask,
        description: `Toggle sculpt mask`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.toggleIsMaskVisible(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleScanUndercutHeatmap,
        description: `Toggle scan undercut`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.toggleScanUndercutEnabled(),
    });
}

function useSetupScanAppearanceKeyboardControls(manager: ISceneAppearanceManager) {
    // Register hotkeys for scan and restoratives appearance
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.togglePreprep,
        description: `Toggle pre prep scans`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.togglePrePrepScanVisibility(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.togglePrep,
        description: `Toggle scans`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.togglePrepScanVisibility(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleAntagonist,
        description: `Toggle antagonist scans`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.toggleAntagonistScanVisibility(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleRestorative,
        description: `Toggle restoratives`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.toggleRestorativesVisibility(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.toggleTextureOnScans,
        description: `Toggle textures on scans`,
        category: HotKeyCategory.FinishingApp,
        action: () => manager.toggleScansColorize(),
    });
}

export function useSetupCameraViewKeyboardControls(viewManager: IViewManager): void {
    // standard directions
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.zoomToFront,
        description: 'Front view',
        category: 'Camera View',
        action: () => viewManager.setFrontCameraView(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.zoomToLeft,
        description: 'Left view',
        category: 'Camera View',
        action: () => viewManager.setLeftCameraView(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.zoomToRight,
        description: 'Right view',
        category: 'Camera View',
        action: () => viewManager.setRightCameraView(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.zoomToTop,
        description: 'Top view',
        category: HotKeyCategory.CameraView,
        action: () => viewManager.setTopCameraView(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.zoomToBottom,
        description: 'Bottom view',
        category: HotKeyCategory.CameraView,
        action: () => viewManager.setBottomCameraView(),
    });

    // special directions
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.zoomToMesial,
        description: 'Mesial view',
        category: HotKeyCategory.CameraView,
        action: () => viewManager.setMesialCameraView(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.zoomToDistal,
        description: 'Distal view',
        category: HotKeyCategory.CameraView,
        action: () => viewManager.setDistalCameraView(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.zoomToFacial,
        description: 'Facial view',
        category: HotKeyCategory.CameraView,
        action: () => viewManager.setFacialCameraView(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.zoomToLingual,
        description: 'Lingual view',
        category: HotKeyCategory.CameraView,
        action: () => viewManager.setLingualCameraView(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.zoomToPOI,
        description: 'POI view',
        category: HotKeyCategory.CameraView,
        action: () => viewManager.setInsertionCameraView(),
    });
}

function useSetupUndoRedoKeyboardControls() {
    const { manager: opManager } = useOperationsState();
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.undoAction,
        description: 'undo',
        category: HotKeyCategory.Tools,
        action: () => opManager.undoOperation(),
    });
    useRegisterHotKeys({
        key: DEFAULT_FINISHING_HOT_KEY_CONFIG.redoAction,
        description: 'redo',
        category: HotKeyCategory.Tools,
        action: () => opManager.redoOperation(),
    });
}

// Registers hotkeys that change the sculpting brush type
function useSetupSculptingBrushesKeyboardControls() {
    const { sculptingEnabled, enableAddBrush, enableSubtractBrush, enableSmoothBrush } = useSculptingState();

    // We don't use `useRegisterHotKeys` here because it doesn't handle the '+' and '*' keys.
    React.useEffect(() => {
        if (!sculptingEnabled) {
            return;
        }

        const onKeyDown = (evt: KeyboardEvent) => {
            if (evt.key === DEFAULT_FINISHING_HOT_KEY_CONFIG.sculptAdd) {
                enableAddBrush();
            }
            if (evt.key === DEFAULT_FINISHING_HOT_KEY_CONFIG.sculptSubtract) {
                enableSubtractBrush();
            }
            if (evt.key === DEFAULT_FINISHING_HOT_KEY_CONFIG.sculptSmooth) {
                enableSmoothBrush();
            }
        };

        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [sculptingEnabled, enableAddBrush, enableSubtractBrush, enableSmoothBrush]);
}

function useSetupInsertionAxisControls(insertionAxisManager: InsertionAxisManager) {
    const { insertionAxisAdjustingEnabled } = useInsertionAxisState();

    useRegisterHotKeys({
        key: 'ctrl+V',
        description: `Set insertion axis from camera`,
        category: HotKeyCategory.FinishingApp,
        action: () => {
            insertionAxisManager.setInsertionAxisFromCamera();
        },
        disabled: !insertionAxisAdjustingEnabled,
    });
}

// Registers mousewheel controls for changing sculpting or deform brush settings
// Modified from PiB useRegisterMouseWheelControls
function useRegisterMouseWheelControls(
    sculptingState: SculptingState,
    deformState: DeformState,
    controlsRef: MainViewCameraControlsRef,
): void {
    const onKeyDown = React.useCallback(
        (evt: KeyboardEvent) => {
            // Shift or Ctrl indicate that we are about to wheel
            if ((evt.ctrlKey || evt.shiftKey) && controlsRef.current) {
                controlsRef.current.enabled = false;
            }
        },
        [controlsRef],
    );

    const onKeyUp = React.useCallback(
        (evt: KeyboardEvent) => {
            const wasModifierReleased = ['Shift', 'Control'].includes(evt.key);
            const isModifierPressed = evt.ctrlKey || evt.shiftKey;

            if (wasModifierReleased && !isModifierPressed && controlsRef.current) {
                controlsRef.current.enabled = true;
            }
        },
        [controlsRef],
    );

    React.useEffect(() => {
        const sculptingEnabled = sculptingState.sculptingEnabled;
        const deformEnabled = deformState.deformEnabled;

        if (!(sculptingEnabled || deformEnabled)) {
            return;
        }

        const brushSettings = sculptingEnabled ? sculptingState.brushSettings : deformState.brushSettings;
        const setBrushRadius = sculptingEnabled ? sculptingState.setBrushRadius : deformState.setBrushRadius;
        const setBrushIntensity = sculptingEnabled ? sculptingState.setBrushIntensity : deformState.setBrushIntensity;

        const manageWheel = (evt: WheelEvent) => {
            if (!evt.ctrlKey && !evt.shiftKey) {
                return;
            }
            // Make sure we are not zooming or scrolling the page
            evt.preventDefault();
            evt.stopPropagation();

            if (controlsRef.current) {
                controlsRef.current.enabled = false;
            }

            /*
             *This is a NB/gotcha hopefully future readers find helpful
             * onMousewheel we get values (on the order of 10-200) large integer deltaX values
             *       ...unless ctrl is held, we get floats of smaller orders of magnitude in deltaY
             * onTrackPad we get deltaY values that we expect with the fingers going up/dn on the pad
             *
             * Follow up: When scrolling the mouse wheel without holding down any keys, I observed `deltaY` values in
             * steps of roughly 4.0 (`deltaX` was zero). When I held the Shift key, I observed `deltaX` values in steps
             * of 40 (`deltaY` was zero).
             * */

            const isFloat = Math.abs(evt.deltaX % 1) > 0 || Math.abs(evt.deltaY % 1) > 0;
            const isTrackpad = Math.abs(evt.deltaY) > 0 && !isFloat;
            const xFactor = evt.deltaX > 0 ? -1 : 1;
            const yFactor = evt.deltaY > 0 ? -1 : 1;

            const factor = isFloat || isTrackpad ? yFactor : xFactor;

            if (evt.shiftKey) {
                // get the min/max and step from current settings
                const multiple = getRadiusChangeMultiple(evt, 4);
                const newRadius = sliderSteppingDecimalRounding(
                    brushSettings.radius,
                    multiple * factor * brushSettings.ranges.radiusStep,
                    brushSettings.ranges.radiusMin,
                    brushSettings.ranges.radiusMax,
                    brushSettings.ranges.radiusStep,
                );
                setBrushRadius(newRadius);
            }

            if (evt.ctrlKey && sculptingEnabled) {
                // get the min/max and step from current settings
                const newIntensity = sliderSteppingDecimalRounding(
                    brushSettings.intensity,
                    0.5 * factor * brushSettings.ranges.intensityStep,
                    brushSettings.ranges.intensityMin,
                    brushSettings.ranges.intensityMax,
                    brushSettings.ranges.intensityStep,
                );
                setBrushIntensity(newIntensity);
            }
        };
        document.addEventListener('keydown', onKeyDown);
        document.addEventListener('keyup', onKeyUp);
        document.addEventListener('wheel', manageWheel, { passive: false });

        return () => {
            document.removeEventListener('wheel', manageWheel);
            document.removeEventListener('keydown', onKeyDown);
            document.removeEventListener('keyup', onKeyUp);
        };
    }, [
        sculptingState.sculptingEnabled,
        deformState.deformEnabled,
        sculptingState.brushSettings,
        deformState.brushSettings,
        deformState.setBrushIntensity,
        deformState.setBrushRadius,
        sculptingState.setBrushIntensity,
        sculptingState.setBrushRadius,
        controlsRef,
        onKeyUp,
        onKeyDown,
    ]);
}
