import type { OrderDesignSubmissionCoreProps } from './DesignSubmission/OrderDesignSubmissionCommon.graphql';
import { CondensedActionCard, DesignFinishingStartBanner } from '@orthly/veneer';
import React from 'react';

type OrderAutomateReviewTaskAlertProps = Omit<OrderDesignSubmissionCoreProps, 'designRevision'>;

export const OrderAutomateReviewTaskAlert: React.FC<OrderAutomateReviewTaskAlertProps> = ({
    order,
    variant,
    refetchOrder,
}) => {
    const activeTask = order.fulfillment_workflow.active_task;
    if (activeTask?.type !== 'AutomateReview') {
        return null;
    }

    const { assignee } = activeTask;
    const assignedUser = assignee
        ? `${assignee.assigned_user.first_name} ${assignee.assigned_user.last_name}`
        : 'Unassigned';

    return (
        <>
            <DesignFinishingStartBanner orderId={order.id} variant={'review'} />
            <CondensedActionCard
                title={'Automate Review'}
                assignee={assignedUser}
                task={activeTask}
                refetch={refetchOrder}
                variant={variant}
            />
        </>
    );
};
