import { Menu, MenuItem } from '@orthly/ui-primitives';
import React from 'react';

interface CustomViewMenuProps {
    isSaved: boolean;
    isEdited: boolean;
    isPublic: boolean;
    onSave: () => void;
    onEdit: () => void;
    onCopy: () => void;
    onDelete: () => void;
    onToggleFavorite: () => void;
    onToggleVisibility: () => void;
    onReset: () => void;
    anchorEl: HTMLElement | null;
    setAnchorEl: (elem: HTMLElement | null) => void;
    isCreatedByUser: boolean;
}

interface MenuItemProps {
    label: string;
    onClick: () => void;
}

function useMenuItems(props: CustomViewMenuProps): MenuItemProps[] {
    const { isSaved, onSave, isEdited, onEdit, onCopy, onDelete, onReset, onToggleFavorite, isCreatedByUser } = props;

    return React.useMemo(() => {
        if (!isSaved) {
            return [
                {
                    label: 'Save',
                    onClick: () => onSave(),
                },
            ];
        }
        const menuItems = [];
        if (isCreatedByUser) {
            isEdited &&
                menuItems.push({
                    label: 'Save',
                    onClick: onSave,
                });
            !isEdited &&
                menuItems.push({
                    label: 'Edit',
                    onClick: onEdit,
                });
            menuItems.push({
                label: `Make ${props.isPublic ? 'private' : 'public'}`,
                onClick: props.onToggleVisibility,
            });
        }
        if (isEdited) {
            menuItems.push({
                label: 'Reset',
                onClick: onReset,
            });
            menuItems.push({
                label: 'Save as...',
                onClick: onCopy,
            });
        } else {
            menuItems.push({
                label: 'Copy',
                onClick: onCopy,
            });
            menuItems.push({
                label: isCreatedByUser ? 'Delete' : 'Unfavorite',
                onClick: isCreatedByUser ? onDelete : onToggleFavorite,
            });
        }
        return menuItems;
    }, [
        isCreatedByUser,
        isEdited,
        isSaved,
        onCopy,
        onDelete,
        onEdit,
        onReset,
        onSave,
        onToggleFavorite,
        props.isPublic,
        props.onToggleVisibility,
    ]);
}

export const CustomViewMenu: React.FC<CustomViewMenuProps> = props => {
    const { anchorEl, setAnchorEl } = props;
    const menuItems = useMenuItems(props);
    return (
        <Menu
            onClose={() => setAnchorEl(null)}
            onBackdropClick={e => {
                e.stopPropagation();
            }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            keepMounted
        >
            {menuItems.map(val => (
                <MenuItem
                    key={val.label}
                    onClick={e => {
                        val.onClick();
                        e.stopPropagation();
                        setAnchorEl(null);
                    }}
                >
                    {val.label}
                </MenuItem>
            ))}
        </Menu>
    );
};
