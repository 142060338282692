import type { ButtonProps } from '@orthly/ui-primitives';
import { styled, CircularProgress, FlossPalette, Button } from '@orthly/ui-primitives';
import React from 'react';

const Wrapper = styled('div')({
    position: 'relative',
});

const Progress = styled(CircularProgress)({
    color: FlossPalette.GREEN,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
});

/** A button that shows a progress indicator when `loading` is true. */
export const ButtonWithProgress: React.VFC<
    ButtonProps & { loading: boolean; ButtonComponent?: React.ComponentType<ButtonProps> }
> = props => {
    const { loading, ButtonComponent = Button, ...buttonProps } = props;
    return (
        <Wrapper>
            <ButtonComponent {...buttonProps} disabled={props.disabled || props.loading} />
            {loading && <Progress size={24} />}
        </Wrapper>
    );
};
