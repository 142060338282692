import type { ManualInvoiceItemEditProps } from './ManualInvoiceItem.utils';
import { useManualInvoiceStyles } from './ManualInvoiceItem.utils';
import { useDeleteRegisteredInvoiceItemMutation } from '@orthly/graphql-react';
import { LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import { Button, Text, Grid, InputAdornment, TextField } from '@orthly/ui-primitives';
import React from 'react';

export const ManualInvoiceItemDelete: React.VFC<
    ManualInvoiceItemEditProps & { setOpenManualChargeDelete: (openManualChargeDelete: boolean) => void }
> = ({ invoiceItem, setOpenManualChargeEdit, setOpenManualChargeDelete, refetch }) => {
    const classes = useManualInvoiceStyles();
    const [mtnSubmitter] = useDeleteRegisteredInvoiceItemMutation();
    const { submit: deleteItem, submitting: deletingItem } = useChangeSubmissionFn(mtnSubmitter, {
        onSuccess: async () => {
            await refetch();
            setOpenManualChargeDelete(false);
            setOpenManualChargeEdit(false);
        },
        successMessage: () => ['Successfully deleted invoice item', {}],
    });
    return (
        <LoadBlocker blocking={deletingItem}>
            <Grid container spacing={2} className={classes.contentBody}>
                <Grid item xs={6}>
                    <Text variant={'body2'} medium>
                        Category
                    </Text>
                    <TextField
                        variant={'standard'}
                        label={''}
                        value={invoiceItem?.subcategory}
                        disabled={true}
                        fullWidth
                        InputProps={{ className: classes.textInput }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Text variant={'body2'} medium>
                        Amount
                    </Text>
                    <TextField
                        variant={'standard'}
                        value={`${(invoiceItem?.amount_cents ?? 0) / 100}`}
                        label={''}
                        disabled={true}
                        fullWidth
                        InputProps={{
                            className: classes.textInput,
                            startAdornment: (
                                <InputAdornment position={'start'}>
                                    <Text variant={'body2'} color={'GRAY'}>
                                        $
                                    </Text>
                                </InputAdornment>
                            ),
                            inputProps: { className: classes.amountInput },
                        }}
                    />
                </Grid>
                <Grid item container>
                    <Text variant={'body2'} medium>
                        Description
                    </Text>
                    <TextField
                        variant={'standard'}
                        InputProps={{ className: classes.textInput }}
                        label={''}
                        disabled={true}
                        fullWidth
                        value={invoiceItem?.description ?? ''}
                    />
                </Grid>
            </Grid>
            <Grid container justifyContent={'flex-end'}>
                <Grid item>
                    <Button
                        variant={'secondary'}
                        className={classes.cancelButton}
                        onClick={() => {
                            setOpenManualChargeDelete(false);
                            setOpenManualChargeEdit(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={'alert-secondary'}
                        onClick={() => {
                            void deleteItem({
                                variables: { data: { id: invoiceItem?.recurring_item_id ?? '' } },
                            });
                        }}
                    >
                        Delete invoice item
                    </Button>
                </Grid>
            </Grid>
        </LoadBlocker>
    );
};
