import { SimpleExpansionPanel } from '../../../../components/SimpleExpansionPanel';
import {
    ExpansionPanelStyle,
    ExpansionPanelSummaryStyle,
    ExpansionPanelDetailsStyle,
} from '../BillingDetailPanel.graphql';
import {
    FullWidthContainer,
    TableActionsContainer,
    StyledInput,
    TableHeaderRow,
    TableBodyRow,
    ActionButtonsContainer,
    StyledButton,
} from './styled-components';
import type { MUIDataObj, MUITableColumn } from '@orthly/mui-table';
import { Table } from '@orthly/ui';
import { DropdownIcon, FlossPalette, Icon, TableCell, TableSortLabel, Text } from '@orthly/ui-primitives';
import React from 'react';

interface BillingDetailsTableAction<T extends MUIDataObj> {
    name: string;
    label: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, rows: T[]) => void;
    CustomRender?: () => React.ReactNode;
}

interface BillingDetailsTableProps<T extends MUIDataObj> {
    rows: T[];
    columns: MUITableColumn<T>[];
    title: string;
    search?: boolean;
    searchPlaceholder?: string;
    handleSort: (rowA: T, rowB: T, sortDirection: string) => number;
    handleFilter: (row: T, searchTerm: string) => void;
    actions?: BillingDetailsTableAction<T>[];
}

export function BillingDetailsTable<T extends MUIDataObj>({
    rows,
    columns,
    title,
    search = false,
    searchPlaceholder,
    handleSort,
    handleFilter,
    actions,
}: BillingDetailsTableProps<T>) {
    const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('desc');
    const toggleSortDirection = () => {
        setSortDirection(curr => (curr === 'asc' ? 'desc' : 'asc'));
    };

    const [searchTerm, setSearchTerm] = React.useState('');

    const sortedAndFilteredRows = rows
        .filter(r => {
            if (!search || !searchTerm || !handleFilter) {
                return true;
            }

            return handleFilter(r, searchTerm);
        })
        .sort((a, b) => {
            if (!handleSort) {
                return 0;
            }

            return handleSort(a, b, sortDirection);
        });

    return (
        <SimpleExpansionPanel
            title={
                <Text variant={'body1'} medium>
                    {title}
                </Text>
            }
            ExpansionPanelProps={{ style: ExpansionPanelStyle, variant: 'outlined' }}
            ExpansionPanelSummaryProps={{ style: ExpansionPanelSummaryStyle }}
            ExpansionPanelDetailsProps={{ style: ExpansionPanelDetailsStyle }}
        >
            <FullWidthContainer>
                <TableActionsContainer>
                    {search && (
                        <StyledInput
                            startAdornment={
                                <Icon icon={'SearchIcon'} style={{ color: FlossPalette.GRAY }} fontSize={'small'} />
                            }
                            placeholder={searchPlaceholder}
                            value={searchTerm}
                            onChange={e => setSearchTerm(e.target.value)}
                        />
                    )}
                    {actions?.length && (
                        <ActionButtonsContainer>
                            {actions.map(action => {
                                return action.CustomRender ? (
                                    action.CustomRender()
                                ) : (
                                    <StyledButton
                                        key={action.name}
                                        variant={'ghost'}
                                        onClick={e => action.onClick?.(e, sortedAndFilteredRows)}
                                    >
                                        {action.label}
                                    </StyledButton>
                                );
                            })}
                        </ActionButtonsContainer>
                    )}
                </TableActionsContainer>
                <Table>
                    <Table.Header>
                        <TableHeaderRow>
                            {columns.map(column => (
                                <TableCell key={column.name} style={{ width: column.width ?? 'auto', ...column.style }}>
                                    <Text variant={'caption'} color={'GRAY'}>
                                        {column.name}
                                    </Text>
                                    {column.sort && (
                                        <TableSortLabel
                                            active={true}
                                            direction={sortDirection}
                                            IconComponent={DropdownIcon}
                                            onClick={toggleSortDirection}
                                        />
                                    )}
                                </TableCell>
                            ))}
                        </TableHeaderRow>
                    </Table.Header>
                    <Table.Body>
                        {sortedAndFilteredRows.map(row => (
                            <TableBodyRow key={row.id}>
                                {columns.map(column => {
                                    const value = typeof column.render === 'function' ? column.render(row) : null;
                                    return (
                                        <TableCell key={column.name} style={column.style}>
                                            {value}
                                        </TableCell>
                                    );
                                })}
                            </TableBodyRow>
                        ))}
                    </Table.Body>
                </Table>
            </FullWidthContainer>
        </SimpleExpansionPanel>
    );
}
