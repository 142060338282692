import { usePracticeOrderCountsPalate } from '../state/usePracticeOrderCountsPalate.graphql';
import { LoadBlocker } from '@orthly/ui';
import { Text, Box } from '@orthly/ui-primitives';
import React from 'react';

export const PracticeOverview: React.FC<{ practiceId: string }> = props => {
    const { counts, loading } = usePracticeOrderCountsPalate(props.practiceId);
    const inFlightOrdersCount = counts?.InFlightOrders ?? 'Loading...';

    return (
        <LoadBlocker blocking={loading} loader={<React.Fragment />}>
            <Box sx={{ display: 'block', width: '100%', paddingTop: '8px' }}>
                <Text color={'DARK_GRAY'} variant={'caption'}>
                    In-flight orders
                </Text>
                <Box sx={{ width: '100%' }}>
                    <Text variant={'caption'}>
                        <b>{inFlightOrdersCount}</b>&nbsp;{counts ? `(${counts.TotalOrders} total)` : ''}
                    </Text>
                </Box>
            </Box>
        </LoadBlocker>
    );
};
