import { labSlipFields } from './fields.util';
import type {
    LabsGqlOrderLabSlipPreviewFragment,
    LabsGqlManufacturerProfileFragment,
    LabsGqlLabOrderForLabSlipFragment,
} from '@orthly/graphql-operations';
import type { TeethShade } from '@orthly/items';
import { isTeethShade, sortLightestToDarkest } from '@orthly/items';
import _ from 'lodash';

export const QR_FIELDS = [
    'patientFirst',
    'patientLast',
    'shade',
    'orderNumber',
    'dueDate',
    'shipDate',
    'doctorName',
] as const;
export type QRField = (typeof QR_FIELDS)[number];
export type QRFieldEntryData = Record<QRField, string>;
export type QRFieldPlacementConfig = Record<QRField, number[]>;

export const createEntryQRCodeString = (
    fieldData: QRFieldEntryData,
    fieldPlacement: QRFieldPlacementConfig,
): string => {
    const fieldMappings: Map<number, string> = new Map();

    for (const field of QR_FIELDS) {
        for (const placement of fieldPlacement[field]) {
            fieldMappings.set(placement, fieldData[field]);
        }
    }

    const maxIndex = _.max(Array.from(fieldMappings.keys())) || 0;
    // We get a range 0...maxIndex and insert a field if available, followed by a tab character if it isn't the last field
    return _.range(maxIndex + 1)
        .map(i => fieldMappings.get(i) ?? '')
        .join('\t');
};

export const DEFAULT_MT_QR_ENTRY_FIELD_PLACEMENTS: QRFieldPlacementConfig = {
    patientFirst: [0],
    patientLast: [1],
    shade: [2],
    orderNumber: [7],
    dueDate: [27],
    shipDate: [29],
    doctorName: [],
};

const magicTouchLabsFieldPlacement: { [manufacturer_id: string]: QRFieldPlacementConfig } = {
    '3dea9795-652b-43d7-ae7a-ac8c66e91a4e': DEFAULT_MT_QR_ENTRY_FIELD_PLACEMENTS, // ZIRCON
    '79c787a4-43bb-4bb9-b5e4-936bd217ed46': {
        patientFirst: [0],
        patientLast: [1],
        shade: [2],
        orderNumber: [7],
        dueDate: [25],
        shipDate: [26],
        doctorName: [],
    }, // GPS
    'eac5d29f-3229-4402-a383-b8908162e268': {
        patientFirst: [0],
        patientLast: [1],
        shade: [2],
        orderNumber: [7],
        dueDate: [26],
        shipDate: [27],
        doctorName: [],
    }, // GPS - North Carolina
    '3208fc84-ba80-4e46-9201-76df1e74b852': {
        patientFirst: [],
        patientLast: [],
        shade: [],
        orderNumber: [1],
        dueDate: [],
        shipDate: [],
        doctorName: [],
    }, // Lehi
    '1f592451-aa0d-44a8-a878-2ee673c76814': {
        patientFirst: [],
        patientLast: [],
        shade: [],
        orderNumber: [1],
        dueDate: [],
        shipDate: [],
        doctorName: [],
    }, // Dallas
    'b6b72696-bda3-4e21-9a47-15aebadf6183': {
        patientFirst: [1],
        patientLast: [2],
        shade: [3],
        orderNumber: [0, 8],
        dueDate: [28],
        shipDate: [30],
        doctorName: [9],
    }, // BIOTEC
    '93662ee6-a387-49a6-bd38-82a3f7211cda': DEFAULT_MT_QR_ENTRY_FIELD_PLACEMENTS, // PRUDENTAL
    '82b38430-91bf-4e9d-8ace-c2867991012d': DEFAULT_MT_QR_ENTRY_FIELD_PLACEMENTS, // MAROTTA
    'df863955-a0f3-4468-b46b-39a7df603a9d': DEFAULT_MT_QR_ENTRY_FIELD_PLACEMENTS, // AURORA
    'c4a8fce5-6fcf-4000-8a9a-4625efd597e7': DEFAULT_MT_QR_ENTRY_FIELD_PLACEMENTS, // DERBY
    'e4b5b8ff-b7c6-4d5b-b2e3-735ea0dfa520': DEFAULT_MT_QR_ENTRY_FIELD_PLACEMENTS, // RELIABLE
    'd7b7129b-e6b6-4333-80af-b5eaa6115b2a': DEFAULT_MT_QR_ENTRY_FIELD_PLACEMENTS, // BURBANK
    '34091abc-d562-4cb3-ab3d-bda2f2313320': {
        patientFirst: [1],
        patientLast: [2],
        shade: [3],
        orderNumber: [7],
        dueDate: [27],
        shipDate: [29],
        doctorName: [9],
    }, // SPECTRUM
    'c2548c1e-deaa-4e3b-a64a-c63fc5a78931': DEFAULT_MT_QR_ENTRY_FIELD_PLACEMENTS, // TALOS
};

const getTeethShades = (order: LabsGqlOrderLabSlipPreviewFragment | LabsGqlLabOrderForLabSlipFragment): TeethShade[] =>
    _.compact(order.items_v2.flatMap(item => item.shades))
        .map(s => s.value)
        .filter(isTeethShade);

export const getOrderEntryQRCode = (
    order: LabsGqlOrderLabSlipPreviewFragment | LabsGqlLabOrderForLabSlipFragment | undefined,
    manufacturerProfile: LabsGqlManufacturerProfileFragment | undefined,
): string | undefined => {
    if (order && manufacturerProfile?.id) {
        const fieldPlacement =
            manufacturerProfile.id in magicTouchLabsFieldPlacement
                ? magicTouchLabsFieldPlacement[manufacturerProfile.id]
                : DEFAULT_MT_QR_ENTRY_FIELD_PLACEMENTS;

        if (fieldPlacement) {
            const { shipDate, dueDate } = labSlipFields(order);

            // This selects the "lightest" shade within an order, and does not
            // send all the shades within the order if there are many. This is
            // likely not the ideal behavior but works with the facts that most
            // orders are for single teeth, and when there are multiple, they
            // tend towards all being the same. In the event that they are not
            // the same, the lighter ones tend to be anterior, which the
            // patient cares more about. We're leaving the existing behavior
            // through the refactor because we don't know how a change to
            // provide more info would affect existing labs
            const shade = _.first(sortLightestToDarkest(getTeethShades(order))) ?? 'N/A';

            const data: QRFieldEntryData = {
                shade,
                patientFirst: _.upperCase(order.patient.first_name),
                patientLast: _.upperCase(order.patient.last_name),
                orderNumber: order.order_number,
                dueDate: dueDate.format('MMDDYYYY'),
                shipDate: shipDate.format('MMDDYYYY'),
                doctorName: order.doctor_name,
            };

            return createEntryQRCodeString(data, fieldPlacement);
        }
    }
};
