import { YAxis } from '../../../utils';
import * as THREE from 'three';
import type { LineMaterial } from 'three-stdlib';

const DEFAULT_INSERTION_AXIS_DIRECTION = YAxis;
const DEFAULT_INSERTION_AXIS_ORIGIN = new THREE.Vector3(0, 0, 0);
const DEFAULT_INSERTION_AXIS_LENGTH = 30;
const DEFAULT_INSERTION_AXIS_COLOR = '#6dadc3';
const DEFAULT_INSERTION_AXIS_HEAD_LENGTH = 5;

export class ScanReviewUndercutToolLiveObjectsProvider {
    readonly group: THREE.Group;
    private readonly arrowHelper: THREE.ArrowHelper;
    private readonly arrowHelperMaterial: LineMaterial;

    constructor(insertionAxis: THREE.Vector3) {
        this.group = new THREE.Group();
        this.group.renderOrder = 1000;
        this.group.frustumCulled = false;

        this.arrowHelper = new THREE.ArrowHelper(
            DEFAULT_INSERTION_AXIS_DIRECTION,
            DEFAULT_INSERTION_AXIS_ORIGIN,
            DEFAULT_INSERTION_AXIS_LENGTH,
            DEFAULT_INSERTION_AXIS_COLOR,
            DEFAULT_INSERTION_AXIS_HEAD_LENGTH,
        );
        this.arrowHelperMaterial = this.arrowHelper.line.material as LineMaterial;
        this.group.add(this.arrowHelper);
        this.setInsertionAxisDirection(insertionAxis);
    }

    setInsertionAxisVisible(visible: boolean) {
        this.arrowHelper.visible = visible;
    }

    setInsertionAxisHighlighted(highlighted: boolean) {
        highlighted ? (this.arrowHelperMaterial.linewidth = 2.5) : (this.arrowHelperMaterial.linewidth = 0.5);
    }

    setInsertionAxisPosition(position: THREE.Vector3) {
        this.arrowHelper.position.copy(position);
    }

    setInsertionAxisDirection(direction: THREE.Vector3) {
        this.arrowHelper.setDirection(direction.clone());
    }

    setInsertionAxisLength(length: number) {
        this.arrowHelper.setLength(length);
    }
}
