import type { FirebasePreviewFileMulti } from '../../../hooks';
import { useGetScanExport } from '../graphql/useGetScanExport.graphql';
import { usePatient } from '../graphql/usePatient.graphql';
import { useSharePatientScan } from '../graphql/useSharePatientScan.graphql';
import { getPatientPdf } from '../utils/getPatientPdf';
import { getScanSnapshots } from '../utils/getScanSnapshots';
import { ShareScansWithModalSuccessContent } from './ShareScansWithModalSuccessContent';
import { ShareScansWithPatientModalAdditionalContent } from './ShareScansWithPatientModalAdditionalContent';
import { ShareScansWithPatientModalFooter } from './ShareScansWithPatientModalFooter';
import { ShareScansWithPatientModalMessage } from './ShareScansWithPatientModalMessage';
import { ShareScansWithPatientModalShareMethod } from './ShareScansWithPatientModalShareMethod';
import { useShareScansEmailValidation } from './useShareScansEmailValidation';
import { useShareScansPhoneNumberValidation } from './useShareScansPhoneNumberValidation';
import type { ScanSnapshotGeometries } from '@orthly/dentin';
import { UserUtil } from '@orthly/retainer-common';
import { useSession } from '@orthly/session-client';
import { LoadBlocker } from '@orthly/ui';
import { Box } from '@orthly/ui-primitives';
import React from 'react';

interface ShareWithPatientModalContentProps {
    setOpen: (open: boolean) => void;
    organizationName: string | null;
    organizationPhone: string | null;
    scanExportId: string | null;
    patientId: string | null;
    annotationUrls: Array<string>;
    annotationImages: FirebasePreviewFileMulti[] | undefined;
    onSuccessScreen: boolean;
    setOnSuccessScreen: (value: boolean) => void;
    geometries?: ScanSnapshotGeometries; // only required for share with pdf
    message?: string | null; // only required when re-sharing
    organizationId?: string; // only required when re-sharing
    staffMemberId?: string; // only required when re-sharing
    patientEmail?: string | null; // only required when re-sharing
    patientPhoneNumber?: string | null; // only required when re-sharing
    onShareComplete?: () => void;
}

const shareType = (
    linkOrPrintout: string,
    patientEmail: string | undefined,
    patientMobilePhone: string | undefined,
) => {
    if (linkOrPrintout === 'printout') {
        return 'PDF';
    }
    if (patientEmail) {
        return 'Email';
    }
    if (patientMobilePhone) {
        return 'SMS';
    }
};

const disableSubmitForInvalidEmailOrPhone = (
    linkOrPrintout: string,
    patientEmail: string,
    emailError: string | undefined,
    patientMobilePhone: string | undefined,
    phoneNumberError: string | undefined,
) => {
    return Boolean(
        (linkOrPrintout === 'smsOrEmail' && !patientEmail && !patientMobilePhone) ||
            (patientEmail && emailError) ||
            (patientMobilePhone && phoneNumberError) ||
            patientMobilePhone === '+1',
    );
};

export const ShareScansWithPatientModalContent: React.FC<ShareWithPatientModalContentProps> = ({
    setOpen,
    scanExportId,
    annotationImages,
    annotationUrls,
    patientId,
    organizationName,
    organizationPhone,
    geometries,
    message: messageFromProps,
    organizationId,
    staffMemberId,
    patientEmail: patientEmailFromProps,
    patientPhoneNumber: patientPhoneNumberFromProps,
    onShareComplete,
    onSuccessScreen,
    setOnSuccessScreen,
}) => {
    const session = useSession();

    const [error, setError] = React.useState('');
    const [shared, setShared] = React.useState(false);
    const [message, setMessage] = React.useState(messageFromProps);
    const [includeAnnotations, setIncludeAnnotations] = React.useState(false);
    const [linkOrPrintout, setLinkOrPrintout] = React.useState(
        patientEmailFromProps || patientPhoneNumberFromProps ? 'smsOrEmail' : '',
    );
    const [patientMobilePhone, setPatientMobilePhone] = React.useState<string | undefined>(
        patientPhoneNumberFromProps ?? undefined,
    );
    const [patientEmail, setPatientEmail] = React.useState<string | undefined>(patientEmailFromProps ?? undefined);

    const practiceName = organizationName ?? '';
    const practiceContactPhone = organizationPhone ?? '';

    const onSuccess = () => {
        if (linkOrPrintout === 'printout') {
            setOpen(false);
            onShareComplete && onShareComplete();
            return;
        }

        onShareComplete && onShareComplete();
        setOnSuccessScreen(true);
    };
    const onFailure = (error: string | undefined) => {
        error && setError(error);
    };

    const { patient, loading: loadingPatient } = usePatient(patientId);
    const { createdAt: appointmentDate, loading: loadingScanExport } = useGetScanExport(scanExportId);
    const { submit: sharePatientScan, submitting: submitSharePatientScan } = useSharePatientScan({
        onSuccess,
        onFailure,
    });

    const { emailError } = useShareScansEmailValidation(patientEmail ?? '');
    const { phoneNumberError } = useShareScansPhoneNumberValidation(patientMobilePhone ?? '');
    const invalidEmailOrPhone = disableSubmitForInvalidEmailOrPhone(
        linkOrPrintout,
        patientEmail ?? '',
        emailError,
        patientMobilePhone,
        phoneNumberError,
    );

    const shareButtonDisabled =
        submitSharePatientScan || !linkOrPrintout || loadingPatient || loadingScanExport || invalidEmailOrPhone;

    React.useEffect(() => {
        if (shared && scanExportId && patientId) {
            const onShare = async () => {
                const result = await sharePatientScan(
                    includeAnnotations ? annotationUrls : null,
                    message,
                    practiceName,
                    practiceContactPhone,
                    patientEmail,
                    patientId,
                    patientMobilePhone,
                    scanExportId,
                    organizationId ?? null,
                    staffMemberId ?? null,
                );

                if (linkOrPrintout !== 'printout') {
                    setOnSuccessScreen(true);
                    return;
                }

                if (result.data?.sharePatientScan && geometries) {
                    const snapshots = getScanSnapshots(geometries);

                    const pdf = await getPatientPdf({
                        practiceName,
                        practiceContactPhone: practiceContactPhone
                            ? UserUtil.prettyPhoneNumber(practiceContactPhone)
                            : '',
                        doctorName: `${session?.user?.first_name} ${session?.user?.last_name}`,
                        portalLink: result.data?.sharePatientScan,
                        patientName: `${patient?.first_name.charAt(0)}. ${patient?.last_name.charAt(0)}.`,
                        appointmentDate: appointmentDate ?? '',
                        annotationImages,
                        snapshots,
                        message: message ?? '',
                    });

                    pdf.open();
                }
            };
            onShare().catch(console.error);
        }
        // We only want to run this effect when we've clicked the Share button or if the upload has finished.
        // User interactions (typing, radio button selections) should not trigger the hook.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scanExportId, shared, patientId]);

    if (onSuccessScreen) {
        return <ShareScansWithModalSuccessContent />;
    }

    return (
        <LoadBlocker blocking={submitSharePatientScan || loadingPatient || loadingScanExport}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', marginTop: '40px', width: '100%' }}>
                <ShareScansWithPatientModalMessage message={message} setMessage={setMessage} />
                <ShareScansWithPatientModalAdditionalContent
                    includeAnnotations={includeAnnotations}
                    setIncludeAnnotations={setIncludeAnnotations}
                    imagePreviews={annotationImages}
                />
                <ShareScansWithPatientModalShareMethod
                    linkOrPrintout={linkOrPrintout}
                    setLinkOrPrintout={setLinkOrPrintout}
                    patientMobilePhone={patientMobilePhone}
                    patientEmail={patientEmail}
                    setPatientEmail={setPatientEmail}
                    setPatientMobilePhone={setPatientMobilePhone}
                    disablePdf={!geometries}
                />
            </Box>
            <ShareScansWithPatientModalFooter
                error={error}
                setShared={setShared}
                setOpen={setOpen}
                linkOrPrintout={linkOrPrintout}
                shareButtonDisabled={shareButtonDisabled}
                shareType={shareType(linkOrPrintout, patientEmail, patientMobilePhone) ?? ''}
            />
        </LoadBlocker>
    );
};
