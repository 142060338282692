import type { RouterTabItem } from '../../components/RouterTabs';
import { RouterTabs } from '../../components/RouterTabs';
import { RouterUtils } from '../../utils/router/RouterUtils';
import { BillingDetailsRoot } from './BillingDetails/BillingDetailsRoot.graphql';
import { BillingCreditCategoriesRoot } from './CreditCategories/BillingCreditCategoriesRoot.graphql';
import { RefundCreditCategoriesRoot } from './RefundCategories/RefundCategoriesRoot.graphql';
import ImportBillingCredits from './actions/ImportBillingCredits/ImportBillingCredits';
import { ManualInvoiceBulkUploadModal } from './actions/ManualInvoiceBulkUpload/ManualInvoiceBulkUploadModal';
import { ZeroOutModelPricesBulkRoot } from './actions/ZeroOutModelPricesBulkRoot';
import { BulkSetWillChargeCCFeeRoot } from './actions/bulk-set-will-charge-cc-processing-fee/BulkSetWillChargeCCFeeRoot';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import { MUITable } from '@orthly/mui-table';
import { useHasCapability } from '@orthly/session-client';
import { LoadBlockerLoader } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

export const ListOrgSummaries_Query = graphql(`
    query ListOrganizationSummaries($filter: ListOrgsFilter) {
        listOrganizationSummaries(filter: $filter) {
            name
            id
        }
    }
`);

const AdminBillingTableRoot: React.FC = () => {
    const {
        data: orgSummaries,
        loading,
        refetch,
    } = useQuery(ListOrgSummaries_Query, {
        variables: { filter: { type: LabsGqlOrganizationType.Practice } },
    });
    const partnerData = orgSummaries?.listOrganizationSummaries ?? [];
    const basePath = `${RouterUtils.pathForScreen('billing')}/overview`;
    const history = useHistory();

    return (
        <>
            <Grid container style={{ position: 'relative' }}>
                <LoadBlockerLoader blocking={loading} loader={'linear'} />
            </Grid>

            <MUITable
                title={'Billing Overview'}
                eventHooks={{ onRowClick: row => history.push(`${basePath}/${row.id}`) }}
                columns={[
                    { name: 'id', hidden: true, render: 'id' },
                    { name: 'Partner', render: 'name' },
                ]}
                actions={{
                    global: [{ icon: 'refresh', position: 'toolbar', onClick: refetch, disabled: loading }],
                }}
                toolbarOptions={{
                    CustomRight: ManualInvoiceBulkUploadModal,
                }}
                data={partnerData}
                paginationOptions={{ rowsPerPageOptions: [25], initialRowsPerPage: 25 }}
                displayOptions={{ fixedSearch: true, viewColumns: true }}
            />
        </>
    );
};

const BillingRoot: React.FC = () => {
    const basePath = `${RouterUtils.pathForScreen('billing')}/overview`;
    return (
        <Switch>
            <Route path={`${basePath}/:practiceId`} component={BillingDetailsRoot} />
            <Route path={`${basePath}`}>
                <AdminBillingTableRoot />
            </Route>
            <Route>
                <Redirect to={basePath} />
            </Route>
        </Switch>
    );
};

export const AdminBillingRoot: React.FC = () => {
    const canToggleCCFee = useHasCapability('billing', 'billing.toggle_will_charge_cc_fee');
    const setCCFeeItem: RouterTabItem[] = canToggleCCFee
        ? [
              {
                  path: 'card-processing-fees',
                  Component: BulkSetWillChargeCCFeeRoot,
                  label: 'Card Processing Fees',
              },
          ]
        : [];

    return (
        <RouterTabs
            items={[
                { path: 'overview', Component: BillingRoot },
                ...setCCFeeItem,
                { path: 'import-credits', Component: ImportBillingCredits, label: 'Credit Import' },
                { path: 'price-adjustments', Component: ZeroOutModelPricesBulkRoot, label: 'Zero out models' },
                { path: 'credit-categories', Component: BillingCreditCategoriesRoot, label: 'Credit Category Config' },
                { path: 'refund-categories', Component: RefundCreditCategoriesRoot, label: 'Refund Category Config' },
            ]}
        />
    );
};
