import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import { LabsGqlLabPriceRuleType, LabsGqlOrderItemPartialDentureCoverageType } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';
import React from 'react';

export const PartialDenturePracticeFinalForm: React.FC<LabPriceFormProps> = ({ price, submit, disabled }) => {
    function _visibleFieldsByMaterial(material: string) {
        return new Map([
            ['tooth_count', material === 'Acrylic'],
            ['coverage_type', material !== 'Acrylic'],
            ['practice_partial_clasp_type', ['Chrome-Cobalt', 'Titanium'].includes(material)],
        ]);
    }

    const initialVisibleFields =
        price?.rule.__typename === 'LabPricePracticePartialDentureFinalRuleDTO'
            ? _visibleFieldsByMaterial(price.rule.material)
            : _visibleFieldsByMaterial('');

    const [selectedMaterial, setSelectedMaterial] = React.useState<string | undefined>(undefined);
    const [visibleFields, setVisibleFields] = React.useState<Map<string, boolean>>(initialVisibleFields);

    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.PracticePartialDentureFinal>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.PracticePartialDentureFinal>({
                material: {
                    type: 'select',
                    label: 'Material',
                    options: ['Chrome-Cobalt', 'Titanium', 'Valplast/TCS', 'Duraflex', 'Acetal', 'Acrylic'],
                },
                coverage_type: {
                    type: 'select',
                    label: 'Coverage Type',
                    options: Object.values(LabsGqlOrderItemPartialDentureCoverageType),
                    optional: visibleFields.get('coverage_type') === false,
                    hidden: visibleFields.get('coverage_type') === false, // show only for non-Acrylic
                },
                tooth_count_min: {
                    type: 'number',
                    label: 'Tooth Count Minimum',
                    optional: visibleFields.get('tooth_count') === false,
                    hidden: visibleFields.get('tooth_count') === false,
                },
                tooth_count_max: {
                    type: 'number',
                    label: 'Tooth Count Maximum',
                    optional: visibleFields.get('tooth_count') === false,
                    hidden: visibleFields.get('tooth_count') === false,
                },
                practice_partial_clasp_type: {
                    type: 'select',
                    label: 'Clasp Type',
                    options: ['Metal', 'Flexible'],
                    optional: visibleFields.get('practice_partial_clasp_type') === false,
                    hidden: visibleFields.get('practice_partial_clasp_type') === false, // show only for non-Acrylic
                },
                default_practice_price: {
                    type: 'number',
                    label: 'Default Price',
                    optional: true,
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          material:
                              price.rule.__typename === 'LabPricePracticePartialDentureFinalRuleDTO'
                                  ? price.rule.material
                                  : undefined,
                          coverage_type:
                              price.rule.__typename === 'LabPricePracticePartialDentureFinalRuleDTO'
                                  ? price.rule.optional_coverage_type
                                  : null,
                          tooth_count_min:
                              price.rule.__typename === 'LabPricePracticePartialDentureFinalRuleDTO'
                                  ? price.rule.optional_tooth_count_range?.min
                                  : null,
                          tooth_count_max:
                              price.rule.__typename === 'LabPricePracticePartialDentureFinalRuleDTO'
                                  ? price.rule.optional_tooth_count_range?.max
                                  : null,
                          practice_partial_clasp_type:
                              price.rule.__typename === 'LabPricePracticePartialDentureFinalRuleDTO'
                                  ? price.rule.practice_partial_clasp_type
                                  : null,
                          default_practice_price:
                              typeof price.default_practice_price_cents === 'number'
                                  ? price.default_practice_price_cents / 100
                                  : null,
                      }
                    : {}
            }
            onChange={formValues => {
                const { material } = formValues;
                if (material && material !== selectedMaterial) {
                    setSelectedMaterial(material);
                    setVisibleFields(_visibleFieldsByMaterial(material));
                }
            }}
            onSubmit={async result => {
                const rule = {
                    type: LabsGqlLabPriceRuleType.PracticePartialDentureFinal,
                    material: result.material,
                    coverage_type: result.coverage_type || null,
                    tooth_count_range: { min: result.tooth_count_min || null, max: result.tooth_count_max || null },
                    practice_partial_clasp_type: result.practice_partial_clasp_type || null,
                };

                // clear out non-visible fields
                if (visibleFields.get('coverage_type') === false) {
                    rule.coverage_type = null;
                }
                if (visibleFields.get('practice_partial_clasp_type') === false) {
                    rule.practice_partial_clasp_type = null;
                }
                if (visibleFields.get('tooth_count') === false) {
                    rule.tooth_count_range = { min: null, max: null };
                }

                const default_practice_price_cents =
                    typeof result.default_practice_price === 'number' ? result.default_practice_price * 100 : null;

                await submit({
                    name: result.name,
                    rule,
                    default_practice_price_cents,
                });
            }}
            disabled={disabled}
        />
    );
};
