import { ListAbutmentPartsProvider } from '../../../context';
import type { IOrderItemV2DTO } from '@orthly/items';
import { CartItemV2Utils, ItemMaterialFieldV2Utils } from '@orthly/items';
import { getMaterialDetails } from '@orthly/shared-types';
import { Text, Divider, Grid, Tooltip, InfoIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const OrderSidebarItemContent: React.VFC<{ item: IOrderItemV2DTO; divider?: boolean }> = props => {
    const { item } = props;
    const firstTooth = CartItemV2Utils.getUniqueUNNs(item)[0];
    const materialDetails = firstTooth
        ? getMaterialDetails({ material: ItemMaterialFieldV2Utils.getSingleMaterial(item) ?? '', unn: firstTooth })
        : undefined;

    return (
        <>
            <Grid item xs={4}>
                <Text variant={'body2'} style={{ fontWeight: 500 }}>
                    {CartItemV2Utils.getFullDisplayName(item, true)}
                </Text>
            </Grid>
            <Grid item container xs={4} justifyContent={'flex-end'} alignContent={'center'}>
                <Text variant={'caption'}>{CartItemV2Utils.getItemDisplayMaterial(item) ?? ''}</Text>
            </Grid>
            <Grid item container xs={4} justifyContent={'flex-end'} alignContent={'center'}>
                {materialDetails && (
                    <>
                        <Text variant={'caption'}>{materialDetails.thickness} mm</Text>
                        <Tooltip title={materialDetails.description}>
                            <InfoIcon style={{ fontSize: 16, marginLeft: 2 }} color={'action'} />
                        </Tooltip>
                    </>
                )}
            </Grid>
            {props.divider && <Divider style={{ width: '100%', margin: '4px 0' }} />}
        </>
    );
};

/**
 * Sidebar section for items in the Ops portal design viewer
 */
export const ItemsSection: React.VFC<{ items: IOrderItemV2DTO[] }> = props => {
    const sortedItems = _.sortBy(props.items, i => {
        const unns = CartItemV2Utils.getUniqueUNNs(i);
        return Math.min(...(unns.length > 0 ? unns : [Number.POSITIVE_INFINITY]));
    });
    return (
        <ListAbutmentPartsProvider>
            <Grid container style={{ marginTop: 0 }}>
                {sortedItems.map((item, idx) => (
                    <OrderSidebarItemContent item={item} divider={idx < props.items.length - 1} key={item.id} />
                ))}
            </Grid>
        </ListAbutmentPartsProvider>
    );
};
