import { useFeatureFlag } from '../../../Providers/LaunchDarkly';
import { useInsertionAxisState, useSceneState } from '../../AppState.hooks';
import { SculptToLimitMenu } from '../SculptToLimitMenu';
import { SculptingMenu } from '../SculptingMenu';
import { Button, Grid } from '@orthly/ui-primitives';
import React from 'react';

export const IPContactsStage: React.VFC = () => {
    const { toggleInsertionAxisAdjustingEnabled, setShowMultiView } = useInsertionAxisState();
    const { value: enableFinishingInsertionAxisAdjustment } = useFeatureFlag('enableFinishingInsertionAxisAdjustment');
    const { manager, appearance } = useSceneState();

    return (
        <Grid item container spacing={1} style={{ padding: '0px 12px' }}>
            <Grid item container>
                {enableFinishingInsertionAxisAdjustment && (
                    <Grid item xs={12} style={{ padding: '0px 12px' }}>
                        <Button
                            variant={'secondary'}
                            onClick={() => {
                                toggleInsertionAxisAdjustingEnabled();
                                manager.toggleScanUndercutEnabled(true);
                                Array.from(appearance.restoratives.keys()).map(toothNumber => {
                                    manager.setRestorativeTransparency(toothNumber, true);
                                });
                                setShowMultiView(true);
                            }}
                            fullWidth
                        >
                            Insertion Direction
                        </Button>
                    </Grid>
                )}
                <SculptingMenu showSculptToAdjacent showSculptToCurtains />
                <SculptToLimitMenu />
            </Grid>
        </Grid>
    );
};
