import type { IMergedItem } from '../types';
import { isMergedItem } from '../types';
import * as DentureItemUtils from './dentureItem.util';
import { getPartialsPrefValues } from './partialsItem.util';
import { QUANTITY_FIELDS } from './removableItem.util';
import type { IOrderItemV2DTO, IRemovableItem, ITMJItem } from '@orthly/items';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';

/**
 * Returns the left header for the given item.
 * @param {IOrderItemV2DTO | IMergedItem} item Item to map to header
 * @returns {string} Header to render
 */
export function getHeaderForLabSlipItem(item: IOrderItemV2DTO | IMergedItem): string {
    if (!isMergedItem(item)) {
        switch (item.sku) {
            case LabOrderItemSKUType.Denture:
                return DentureItemUtils.getDentureDescription(item);
            case LabOrderItemSKUType.Partial: {
                const isNew = getPartialsPrefValues(item).partialIsNew === 'true';
                return `Partial Denture ${item.unit.unns[0] ?? ''}${isNew ? ' (Replacement)' : ''}`;
            }
            case LabOrderItemSKUType.Removeable:
            case LabOrderItemSKUType.TMJ:
                return formatRemovableHeader(item);
            default:
                return CartItemV2Utils.getFullDisplayName(item, true);
        }
    }
    return `${CartItemV2Utils.getDisplayName(item.defaultItem)} ${CartItemV2Utils.getUnnsDisplay(item.groupedUnns)}`;
}

function formatRemovableHeader(item: IRemovableItem | ITMJItem): string {
    const header = CartItemV2Utils.getDisplayName(item);
    const quantity = item.preference_fields.find(pref => QUANTITY_FIELDS.includes(pref.field_id))?.value;
    const parsedQuantity = parseInt(`${quantity}`);
    if (!parsedQuantity || isNaN(parsedQuantity)) {
        return header;
    }
    return `${parsedQuantity}x ${Format.pluralizeNoun(header, parsedQuantity)}`;
}
