import type { CanvasObject } from '../Objects/CanvasObject';
import type { Point } from '../Util';
import type { ActionEntry, CanvasTool } from './CanvasTool';

// Handles most of the logic for creating objects from a pointer-down ->
// pointer-moved -> pointer-up sequence. Subclasses need only implement
// the `objectForPoints` method.
export abstract class CreationTool implements CanvasTool {
    private isPointerDown = false;
    private points: Point[] = [];
    private cursor: string;
    private selectObjectWhenDone: boolean;

    constructor(cursor: string, selectObjectWhenDone: boolean = true) {
        this.cursor = cursor;
        this.selectObjectWhenDone = selectObjectWhenDone;
    }

    // Returns the CanvasObject for the given pointer locations. `start` is the start point,
    // `end` is the end point, and `all` is an array of all the points the pointer moved through,
    // including the start and end.
    abstract objectForPoints(start: Point, end: Point, all: Point[]): CanvasObject | null;

    handlePointerDown(point: Point) {
        this.isPointerDown = true;
        this.points = [point];
        return {};
    }

    handlePointerMoved(point: Point) {
        this.points.push(point);
    }

    handlePointerUp(_point: Point, event: PointerEvent): ActionEntry | null {
        this.isPointerDown = false;
        const currentObject = this.currentObject();
        if (currentObject) {
            return {
                resetToPointer: this.selectObjectWhenDone && !event.shiftKey,
                do: stack => stack.push(currentObject),
                undo: stack => stack.pop(),
            };
        }
        return null;
    }

    cursorAtPoint(): string {
        return this.cursor;
    }

    private currentObject(): CanvasObject | null {
        const startPoint = this.points[0];
        if (!startPoint) {
            return null;
        }
        const endPoint = this.points[this.points.length - 1] ?? startPoint;
        return this.objectForPoints(startPoint, endPoint, this.points);
    }

    draw(ctx: CanvasRenderingContext2D): void {
        if (this.isPointerDown) {
            this.currentObject()?.draw(ctx);
        }
    }
}
