import type { LabsGqlDesignStaffFocusAreaDtoFragment } from '@orthly/graphql-operations';

export type FocusArea = Pick<LabsGqlDesignStaffFocusAreaDtoFragment, 'capability' | 'level' | 'task_type'>;

export function focusAreaExistsInList(focusArea: FocusArea, list: FocusArea[]): FocusArea | null {
    return (
        list.find(
            fa =>
                fa.capability === focusArea.capability &&
                fa.level === focusArea.level &&
                fa.task_type === focusArea.task_type,
        ) ?? null
    );
}
