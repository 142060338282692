import { useStageState } from '../AppState.hooks';
import { MainView } from './MainView';
import { ReviewView } from './ReviewView';
import { stylesFactory, FlossPalette, Box } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        height: '100%',
        flex: 'auto',
        // This is required to get the canvas element to shrink properly. It seems like it may be due to some bad
        // interaction with the ModelViewerCanvas component.
        overflow: 'hidden',
        backgroundColor: FlossPalette.WHITE,
    },
}));

export const CentralArea: React.VFC = () => {
    const classes = useStyles();

    const { isReviewComplete } = useStageState();

    return <Box className={classes.root}>{isReviewComplete ? <MainView /> : <ReviewView />}</Box>;
};
