import { PresetReviewCheckboxControl } from '../GeneralReviewStructuredRejectionNotes/components/PresetReviewControls/PresetReviewCheckboxControl';
import { PresetReviewControlsTitle } from '../GeneralReviewStructuredRejectionNotes/components/PresetReviewControls/PresetReviewControlsTitle';
import { MobileRejectionNotesContainer } from '../MobileRejectionNotesContainer';
import { UnfinishedPresetsMessage } from '../UnfinishedPresetsMessage';
import { StructuredRejectionNotesAddendum } from './StructuredRejectionNotesAddendum';
import { getFragmentData, OrderDesignPreviewDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import type { FragmentType } from '@orthly/graphql-inline-react';
import type { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { Box, FlossPalette } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';

/**
 * The mobile-only modal for generating a read-only view of the rejection notes from a previous version.
 * It accepts a design fragment and extracts all the needed context from that fragment.
 *
 * You can find the figma here: https://www.figma.com/design/C3hnlaLEorlXlJUZZ07b7t/Feature%3A-DDP-Improvements-2024?node-id=6945-157940&node-type=frame&m=dev
 *
 */
export const PreviousStructuredRejectionNotesMobile: React.VFC<{
    onClose: () => any;
    onBack: () => any;
    onCancel?: () => any;
    designFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
}> = ({ onBack, onCancel, onClose, designFragment }) => {
    const designFragmentData = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, designFragment);
    const annotations = designFragmentData?.doctor_review?.annotations || [];

    const reviewedAt = designFragmentData?.doctor_review?.created_at;
    const formattedReviewDate = reviewedAt ? dayjs(reviewedAt).format('MMM D') : '';
    return (
        <MobileRejectionNotesContainer
            isOpen={true}
            closeLabel={'Return'}
            onBack={onBack}
            onCancel={onCancel}
            onClose={onClose}
            closeLabelVariant={'secondary'}
            title={`Previous Rejection Notes`}
            subtitle={`Rejected ${formattedReviewDate}`}
            footerPreface={<UnfinishedPresetsMessage />}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    '& > *': {
                        paddingBottom: '16px',
                        '&:not(:last-child)': {
                            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
                        },
                    },
                    '&  .MuiInput-input.Mui-disabled': {
                        background: 'inherit',
                    },
                    '&  .MuiInputBase-formControl.Mui-disabled:hover': {
                        border: `1px solid ${FlossPalette.DARK_TAN}`,
                    },
                }}
            >
                {annotations.map(preset => {
                    const presetType = preset?.preset_type;
                    return (
                        <Box key={presetType} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <PresetReviewControlsTitle presetType={presetType as LabsGqlGuidedWaxupPresetType} />
                            <PresetReviewCheckboxControl
                                areControlsDisabled={true}
                                structured_notes={preset?.structured_notes?.map(({ category }) => category)}
                                presetType={presetType as LabsGqlGuidedWaxupPresetType}
                            />
                            {preset.notes && (
                                <Box sx={{ marginBottom: '16px' }}>
                                    <StructuredRejectionNotesAddendum
                                        disabled={true}
                                        notes={preset.notes}
                                        isComparisonModeAvailable={false}
                                        presetType={presetType as LabsGqlGuidedWaxupPresetType}
                                        mostRecentlyRejectedDesignFragment={designFragment}
                                    />
                                </Box>
                            )}
                        </Box>
                    );
                })}
            </Box>
        </MobileRejectionNotesContainer>
    );
};
