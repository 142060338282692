import type { ItemInfo, Decision } from '../orderEditMode';
import { OrderEditMode } from '@orthly/shared-types';

/**
 * newScan checks for a new scan on the order, returning an editable
 * decision if one is found and null otherwise.
 */
export function newScan(item: ItemInfo): Decision {
    return {
        editMode: item.newScan ? OrderEditMode.OrderEdit : null,
        debugInfo: {
            rule: 'newScan',
            newScan: item.newScan,
        },
    };
}
