import { RouterTabs } from '../../components/RouterTabs';
import { RouterUtils } from '../../utils/router/RouterUtils';
import { ExternalOrgsTable } from './components/ExternalOrgsTable';
import { ManufacturersDetailView } from './components/ManufacturersDetailView';
import { ManufacturersTable } from './components/ManufacturersTable';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

const LabAccountsRoot: React.FC = () => {
    const basePath = `${RouterUtils.pathForScreen('labs')}/accounts`;
    return (
        <Switch>
            <Route path={`${basePath}/:manufacturerId`}>
                <ManufacturersDetailView />
            </Route>
            <Route path={`${basePath}`}>
                <ManufacturersTable />
            </Route>
            <Route>
                <Redirect to={basePath} />
            </Route>
        </Switch>
    );
};

const ExternalOrgAccountsRoot: React.FC = () => {
    const basePath = `${RouterUtils.pathForScreen('labs')}/external-accounts`;
    return (
        <Switch>
            <Route path={`${basePath}`}>
                <ExternalOrgsTable />
            </Route>
            <Route>
                <Redirect to={basePath} />
            </Route>
        </Switch>
    );
};

export const ManufacturersRoot: React.FC = () => {
    return (
        <RouterTabs
            items={[
                { path: 'accounts', label: 'Labs', Component: LabAccountsRoot },
                { path: 'external-accounts', label: 'External Orgs', Component: ExternalOrgAccountsRoot },
            ]}
        />
    );
};
