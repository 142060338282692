import { useSplitsDispatch } from './Splits.context';
import type { SplitsDelta } from './Splits.types';
import type {
    LabsGqlLabOrderForListViewFragment,
    LabsGqlLabOrderFragment,
    LabsGqlOrganizationDtoFragment,
} from '@orthly/graphql-operations';
import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'splits';

export type OpenManagerPayload = {
    order: LabsGqlLabOrderFragment;
    splitOrders: ReadonlyArray<LabsGqlLabOrderForListViewFragment>;
    manufacturers: ReadonlyArray<LabsGqlOrganizationDtoFragment>;
};

export const SplitsActions = {
    OPEN_MANAGER: createSyncAction<OpenManagerPayload, [OpenManagerPayload]>(`${PREFIX}/OPEN_MANAGER`),
    APPLY_DELTA: createSyncAction<SplitsDelta, [SplitsDelta]>(`${PREFIX}/APPLY_DELTA`),
    UNDO_DELTA: createSyncAction<void, []>(`${PREFIX}/UNDO_DELTA`),
    RESET_DELTAS: createSyncAction<void, []>(`${PREFIX}/RESET_DELTAS`),
    CLOSE_MANAGER: createSyncAction<void, []>(`${PREFIX}/CLOSE_MANAGER`),
};

export const useSplitsAction = generateUseActionHook<typeof SplitsActions>(SplitsActions, useSplitsDispatch);
