import { RootActionDialog } from '@orthly/ui';
import { Box, Icon, Text } from '@orthly/ui-primitives';
import React from 'react';

interface FormatStatusProps {
    status: string;
}

const bouncedContent = () => {
    return (
        <>
            <Text color={'DARK_GRAY'} medium>
                Why Emails Bounce
            </Text>
            <Text color={'DARK_GRAY'}>
                We noticed that an email we sent to you bounced. This can happen for several reasons:
            </Text>
            <ul>
                <li>
                    <Text color={'DARK_GRAY'}>
                        <b>Invalid Email Address:</b> Double-check that your email address was entered correctly.
                    </Text>
                </li>
                <li>
                    <Text color={'DARK_GRAY'}>
                        <b> Full Mailbox:</b> Clear some space in your inbox to ensure you can receive emails.
                    </Text>
                </li>
                <li>
                    <Text color={'DARK_GRAY'}>
                        <b>Email Server Issues:</b> Your email provider may have temporarily rejected the message.
                    </Text>
                </li>
            </ul>
            <Text color={'DARK_GRAY'} medium>
                What You Can Do:
            </Text>
            <ul>
                <li>Confirm your email address is correct in your account settings.</li>
                <li>Check with your email provider to resolve inbox or server issues.</li>
                <li>Add our email address to your safe sender list to avoid future issues.</li>
            </ul>
        </>
    );
};

const spamContent = () => {
    return (
        <>
            <Text color={'DARK_GRAY'} medium>
                Why Emails Are Marked as Spam
            </Text>
            <Text color={'DARK_GRAY'}>It seems that an email from us was flagged as spam. This may happen if:</Text>
            <ul>
                <li>
                    <Text color={'DARK_GRAY'}>
                        <b>Email Content Looks Suspicious:</b> Spam filters flagged our message.
                    </Text>
                </li>
                <li>
                    <Text color={'DARK_GRAY'}>
                        <b>Unintended Manual Action:</b> You might have accidentally marked the email as spam.
                    </Text>
                </li>
                <li>
                    <Text color={'DARK_GRAY'}>
                        <b>Engagement Issues:</b> If our emails remain unopened, spam filters may step in.
                    </Text>
                </li>
            </ul>
            <Text color={'DARK_GRAY'} medium>
                What You Can Do:
            </Text>
            <ul>
                <li>Check your spam or junk folder and mark our emails as "Not Spam."</li>
                <li>Add us to your safe sender or whitelist to ensure delivery.</li>
                <li>
                    If this was accidental, no worries — you can still access your account or notifications as usual.
                </li>
            </ul>
        </>
    );
};

export const FormatStatus: React.FC<FormatStatusProps> = ({ status }) => {
    const [bouncedOpen, setBouncedOpen] = React.useState<boolean>(false);
    const [spamOpen, setSpamOpen] = React.useState<boolean>(false);
    if (status === 'Bounced') {
        return (
            <>
                <Box
                    sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
                    onClick={() => {
                        setBouncedOpen(true);
                    }}
                >
                    <Text variant={'body2'} color={'SECONDARY_FOREGROUND'}>
                        Bounced
                    </Text>
                    <Icon icon={'InfoIcon'} />
                </Box>
                <RootActionDialog
                    loading={false}
                    title={'Status: Bounced'}
                    hideButton
                    setOpen={setBouncedOpen}
                    open={bouncedOpen}
                    content={bouncedContent()}
                    showCloseButton
                />
            </>
        );
    }
    if (status === 'Marked as Spam') {
        return (
            <>
                <Box
                    sx={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }}
                    onClick={() => {
                        setSpamOpen(true);
                    }}
                >
                    <Text variant={'body2'} color={'SECONDARY_FOREGROUND'}>
                        Marked as Spam
                    </Text>
                    <Icon icon={'InfoIcon'} />
                </Box>
                <RootActionDialog
                    loading={false}
                    title={'Status: Marked as Spam'}
                    hideButton
                    setOpen={setSpamOpen}
                    open={spamOpen}
                    content={spamContent()}
                    showCloseButton
                />
            </>
        );
    }
    return <Text variant={'body2'}>{status}</Text>;
};
