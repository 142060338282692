import type { RestorativeModel, ScanGeometries } from './FinishingApp.types';
import type { ScanKey } from './SceneAppearanceManager.types';
import type { IntaglioSettingsData } from '@orthly/forceps';
import { ToothUtils } from '@orthly/items';
import type { ToothNumber } from '@orthly/items';
import _ from 'lodash';

export type CaseMetadataRestorativeModel = Pick<RestorativeModel, 'toothNumber' | 'intaglioSettings'>;

type IntaglioSettingsRecord = Partial<Record<ToothNumber, Readonly<IntaglioSettingsData>>>;

export class CaseMetadata {
    public readonly restorationJaw: 'none' | 'both' | ScanKey;
    public readonly hasPrePrepScans: boolean;
    public readonly intaglioSettings: Readonly<IntaglioSettingsRecord>;

    constructor(restorativeModels: CaseMetadataRestorativeModel[], scans: ScanGeometries) {
        const restorativeTeeth = _.map(restorativeModels, 'toothNumber');

        const hasUpper = restorativeTeeth.some(ToothUtils.toothIsUpper);
        const hasLower = restorativeTeeth.some(ToothUtils.toothIsLower);

        if (hasUpper && hasLower) {
            this.restorationJaw = 'both';
        } else if (hasUpper) {
            this.restorationJaw = 'upper';
        } else if (hasLower) {
            this.restorationJaw = 'lower';
        } else {
            this.restorationJaw = 'none';
        }

        this.hasPrePrepScans = !!scans.prePrepLowerJaw || !!scans.prePrepUpperJaw;

        this.intaglioSettings = restorativeModels.reduce(
            (acc, el) => ({
                ...acc,
                [el.toothNumber]: { ...el.intaglioSettings },
            }),
            {},
        );
    }
}
