import type { ApolloClient } from '@apollo/client';
import type {
    LabsGqlLogThreeshapePluginOperationMutation,
    LabsGqlLogThreeshapePluginOperationMutationVariables,
} from '@orthly/graphql-operations';
import type { LabsGqlListMyDesignTasksQuery, LabsGqlListMyDesignTasksQueryVariables } from '@orthly/graphql-operations';
import { LogThreeshapePluginOperationDocument, ListMyDesignTasksDocument } from '@orthly/graphql-react';
import type { LabsGqlThreeshapePluginOperationTypeInput } from '@orthly/graphql-schema';
import { LabsGqlDesignTaskType, LabsGqlWorkflowTaskSortField, LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { DesignTaskType } from '@orthly/shared-types';
import React from 'react';

export async function getSubdirectoryForTask(
    rootDir: FileSystemDirectoryHandle,
    taskType: DesignTaskType,
): Promise<FileSystemDirectoryHandle> {
    switch (taskType) {
        case DesignTaskType.InternalDesign:
            return await rootDir.getDirectoryHandle('design', { create: true });
        case DesignTaskType.DesignPrep:
            return await rootDir.getDirectoryHandle('prep', { create: true });
        default:
            throw new Error(`Unknown task type ${taskType}. Unable to get subdirectory`);
    }
}

/**
 *
 * A custom hook that runs a callback at the specified minimum delay.
 * If the callback takes longer than this to run, it will be called again immediately after execution.
 * This hook is useful in components where you want to use a recursive setTimeout,
 * but don't want to reset the timeout every time the component re-renders.
 * Based on the useInterval implementation described here: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 *
 * @param {() => Promise<any>} asyncCallback The callback to run
 * @param {number} minDelayMs The minimum delay between callback invocations.
 *
 */
export function useRecursiveTimeout(asyncCallback: () => Promise<any>, minDelayMs: number) {
    const savedCallback = React.useRef<() => Promise<any>>(asyncCallback);

    // Remember the latest callback
    React.useEffect(() => {
        savedCallback.current = asyncCallback;
    }, [asyncCallback]);

    React.useEffect(() => {
        async function recursiveCallback() {
            const startTime = new Date();
            try {
                await savedCallback.current();
            } catch (e) {
                // we'll catch and log the error but still queue up the callback again
                console.error(e);
            }
            const endTime = new Date();
            const timeElapsed = endTime.getTime() - startTime.getTime();
            if (timeElapsed > minDelayMs) {
                setTimeout(recursiveCallback, 0);
            } else {
                setTimeout(recursiveCallback, minDelayMs - timeElapsed);
            }
        }
        const id = setTimeout(recursiveCallback, minDelayMs);
        return () => {
            clearInterval(id);
        };
    }, [minDelayMs]);
}

function getGqlDesignTaskType(designTaskType: DesignTaskType): LabsGqlDesignTaskType {
    switch (designTaskType) {
        case DesignTaskType.DesignPrep:
            return LabsGqlDesignTaskType.DesignPrep;
        case DesignTaskType.InternalDesign:
            return LabsGqlDesignTaskType.InternalDesign;
        default:
            throw new Error('Invalid design task type for plugin');
    }
}

export async function logThreeshapePluginOperation(
    client: ApolloClient<any>,
    orderId: string,
    taskType: DesignTaskType,
    operationType: LabsGqlThreeshapePluginOperationTypeInput,
    error?: Error,
) {
    await client.mutate<
        LabsGqlLogThreeshapePluginOperationMutation,
        LabsGqlLogThreeshapePluginOperationMutationVariables
    >({
        mutation: LogThreeshapePluginOperationDocument,
        variables: {
            operation: {
                designOrderId: orderId,
                taskType: getGqlDesignTaskType(taskType),
                operation: operationType,
                error: error?.toString() ?? null,
            },
        },
    });
}

export async function getAssignedDesignTasks(
    client: ApolloClient<any>,
): Promise<{ orderId: string; taskType: DesignTaskType }[]> {
    const assignedDesignTasksResult = await client.query<
        LabsGqlListMyDesignTasksQuery,
        LabsGqlListMyDesignTasksQueryVariables
    >({
        query: ListMyDesignTasksDocument,
        variables: {
            sort: { field: LabsGqlWorkflowTaskSortField.OrderDueDate, isAsc: false },
            offset: 0,
            limit: 20,
        },
    });

    return assignedDesignTasksResult.data.tasks
        .map(t => ({ orderId: t.lab_order_id, taskType: getDesignTaskIfValid(t.type) }))
        .filter((t): t is { orderId: string; taskType: DesignTaskType } => !!t.taskType);
}

/**
 * Returns a design task if the task is a supported design task, otherwise returns null
 * @param {LabsGqlWorkflowTaskType | undefined} activeTask
 * @returns {DesignTaskType.DesignPrep | DesignTaskType.InternalDesign | null}
 */
function getDesignTaskIfValid(activeTask: LabsGqlWorkflowTaskType | undefined) {
    switch (activeTask) {
        case LabsGqlWorkflowTaskType.InternalDesign:
            return DesignTaskType.InternalDesign;
        case LabsGqlWorkflowTaskType.DesignPrep:
            return DesignTaskType.DesignPrep;
        default:
            return null;
    }
}
