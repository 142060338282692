import type { SubmitterParams } from '../../utils';
import { handleSuccess } from '../../utils';
import {
    useDisableCapacityRuleMutation,
    useEnableCapacityRuleMutation,
    useCreateCapacityRuleMutation,
    useUpdateCapacityRuleMutation,
} from '@orthly/graphql-react';
import type {
    LabsGqlDisableCapacityRuleCommand,
    LabsGqlEnableCapacityRuleCommand,
    LabsGqlCreateCapacityRuleCommand,
    LabsGqlUpdateCapacityRuleCommand,
} from '@orthly/graphql-schema';
import { useChangeSubmissionFn } from '@orthly/ui';

export function useDisableCapacityRuleSubmitter(params: SubmitterParams) {
    const [submitMtn] = useDisableCapacityRuleMutation();
    const mtnSubmitter = (data: LabsGqlDisableCapacityRuleCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        successMessage: () => ['Capacity Rule disabled!', {}],
        onSuccess: () => handleSuccess(params),
    });
    return { submit, submitting };
}

export function useEnableCapacityRuleSubmitter(params: SubmitterParams) {
    const [submitMtn] = useEnableCapacityRuleMutation();
    const mtnSubmitter = (data: LabsGqlEnableCapacityRuleCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        successMessage: () => ['Capacity Rule enabled!', {}],
        onSuccess: () => handleSuccess(params),
    });
    return { submit, submitting };
}

export function useCreateCapacityRuleSubmitter(params: SubmitterParams) {
    const [submitMtn] = useCreateCapacityRuleMutation();
    const mtnSubmitter = (data: LabsGqlCreateCapacityRuleCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        successMessage: () => ['Capacity Rule created!', {}],
        onSuccess: () => handleSuccess(params),
    });
    return { submit, submitting };
}

export function useUpdateCapacityRuleSubmitter(params: SubmitterParams) {
    const [submitMtn] = useUpdateCapacityRuleMutation();
    const mtnSubmitter = (data: LabsGqlUpdateCapacityRuleCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        successMessage: () => ['Capacity Rule updated!', {}],
        onSuccess: () => handleSuccess(params),
    });
    return { submit, submitting };
}
