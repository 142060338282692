import { HIT_TEST_MARGIN } from '../Util';
import type { Handle } from './CanvasObject';
import { CanvasObject } from './CanvasObject';

const HANDLE_RADIUS = 5;

export class HandleObject extends CanvasObject {
    readonly handle: Handle;

    constructor(handle: Handle) {
        super();
        this.handle = handle;
    }

    draw(ctx: CanvasRenderingContext2D, hitTestColor?: string | undefined): void {
        const radius = HANDLE_RADIUS + (hitTestColor ? HIT_TEST_MARGIN : 0);
        ctx.fillStyle = hitTestColor ?? 'rgb(25, 114, 204)';
        ctx.strokeStyle = 'white';
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.arc(this.handle.center[0], this.handle.center[1], radius, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fill();
        if (!hitTestColor) {
            ctx.stroke();
        }
    }

    handles() {
        return [];
    }

    grabHandle(): Handle {
        return this.handle;
    }
}
