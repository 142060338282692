import { ComparisonModeEvent } from '../../../hooks/useComparisonModeTracking';
import type { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { LabsGqlGuidedWaxupPresetStatus } from '@orthly/graphql-schema';
import { Button, Icon, styled } from '@orthly/ui-primitives';
import React from 'react';

const AnnotationButtonStyled = styled(Button)({
    height: '32px',
    gap: '8px',
});

interface AnnotationButtonProps {
    selectedPreset: LabsGqlGuidedWaxupPresetType;
    setPresetStatus: (payload: {
        presetName: LabsGqlGuidedWaxupPresetType;
        status?: LabsGqlGuidedWaxupPresetStatus | undefined;
    }) => unknown;
    toggleComparisonMode: () => void;
    trackComparisonMode: (event: ComparisonModeEvent) => void;
}

export const AnnotationButton: React.VFC<AnnotationButtonProps> = ({
    selectedPreset,
    setPresetStatus,
    toggleComparisonMode,
    trackComparisonMode,
}) => {
    return (
        <AnnotationButtonStyled
            id={'annotate-design-button'}
            onClick={() => {
                setPresetStatus({
                    presetName: selectedPreset,
                    status: LabsGqlGuidedWaxupPresetStatus.Rejected,
                });
                toggleComparisonMode();
                trackComparisonMode(ComparisonModeEvent.annotate);
            }}
            variant={'secondary'}
        >
            Annotate design
            <Icon icon={'DrawIcon'} />
        </AnnotationButtonStyled>
    );
};
