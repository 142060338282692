import { stylesFactory, FlossPalette, FlossPaletteUtils, Grid } from '@orthly/ui-primitives';
import clsx from 'clsx';
import type { MouseEventHandler } from 'react';
import React from 'react';

export const ORDER_DETAIL_LIST_ITEM_HEIGHT = 96;
export const ORDER_DETAIL_LIST_ITEM_CLASSNAME = 'order-detail-sidebar-item';

const useStyles = stylesFactory(() => ({
    root: {
        padding: '12px 16px',
        height: ORDER_DETAIL_LIST_ITEM_HEIGHT,
        borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
        overflow: 'hidden',
    },
    selected: {
        background: FlossPaletteUtils.toRgba('STAR_GRASS', 0.15),
    },
    clickable: {
        cursor: 'pointer',
        '&:hover': { background: FlossPalette.DARK_TAN },
    },
}));

interface OrderDetailSidebarItemLayoutProps {
    className?: string;
    selected: boolean;
    onClick?: MouseEventHandler;
}

export const OrderDetailSidebarItemLayout: React.FC<OrderDetailSidebarItemLayoutProps> = props => {
    const classes = useStyles();
    const { selected, onClick, className } = props;
    return (
        <Grid
            container
            className={clsx(className, classes.root, ORDER_DETAIL_LIST_ITEM_CLASSNAME, {
                [classes.selected]: selected,
                [classes.clickable]: !!onClick,
            })}
            style={{ opacity: selected ? 1 : undefined }}
            onClick={onClick}
            onAuxClick={onClick}
        >
            {props.children}
        </Grid>
    );
};

// Not using material ui styles for performance

export const LOADING_STYLE: React.CSSProperties = {
    width: '100%',
    transform: 'unset',
    height: 16,
    backgroundColor: FlossPalette.DARK_TAN,
};

export const OrderDetailSidebarItemLoading: React.FC = () => {
    return (
        <OrderDetailSidebarItemLayout selected={false}>
            <Grid container wrap={'nowrap'} alignItems={'center'}>
                <div style={{ ...LOADING_STYLE, width: 24, marginRight: 4, height: 24 }} />
                <div style={{ ...LOADING_STYLE, height: 24 }} />
            </Grid>
            <div style={{ ...LOADING_STYLE, height: 12 }} />
            <div style={{ ...LOADING_STYLE, height: 12 }} />
        </OrderDetailSidebarItemLayout>
    );
};
