import { DandyMouthDisplay } from './DandyMouthDisplay';
import type { ToothNumber } from '@orthly/items';
import { AllLowerToothNumbers, AllUpperToothNumbers } from '@orthly/items';
import { FlossPalette } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface DandyMouthToothSelectorProps {
    selected: ToothNumber[];
    disabled?: ToothNumber[];
    outlined?: ToothNumber[];
    onChange: (values: ToothNumber[]) => void;
    upperEnabled?: boolean;
    lowerEnabled?: boolean;
    verticalLayout?: boolean;
    animate?: boolean;
    archLabels?: boolean;
}

export const DandyMouthToothSelector: React.FC<DandyMouthToothSelectorProps> = ({
    selected,
    disabled = [],
    outlined = [],
    onChange,
    upperEnabled = true,
    lowerEnabled = true,
    verticalLayout,
    animate = true,
    archLabels,
}) => {
    const items = React.useMemo(
        () => [
            ...selected.map(tooth => ({
                number: tooth,
                highlighted: true,
                activeColor: FlossPalette.GREEN,
                toothStrokeColor: outlined.includes(tooth) ? FlossPalette.BLACK : undefined,
                toothStrokeWidth: outlined.includes(tooth) ? 2 : undefined,
            })),
            ..._.difference(outlined, selected).map(tooth => ({
                number: tooth,
                highlighted: false,
                toothStrokeColor: FlossPalette.BLACK,
                toothStrokeWidth: 2,
                unselected: true,
            })),
            ...disabled.map(tooth => ({
                number: tooth,
                highlighted: true,
                activeColor: FlossPalette.DARK_TAN,
                disableClick: true,
            })),
            ...(!upperEnabled
                ? AllUpperToothNumbers.map(tooth => ({
                      number: tooth,
                      highlighted: false,
                      activeColor: FlossPalette.TAN,
                      disableClick: true,
                  }))
                : []),
            ...(!lowerEnabled
                ? AllLowerToothNumbers.map(tooth => ({
                      number: tooth,
                      highlighted: false,
                      activeColor: FlossPalette.TAN,
                      disableClick: true,
                  }))
                : []),
        ],
        [selected, disabled, outlined, upperEnabled, lowerEnabled],
    );
    const onToothClick = React.useCallback(
        (tooth: ToothNumber) => {
            if (selected.includes(tooth)) {
                onChange(selected.filter(t => t !== tooth));
            } else {
                onChange([...selected, tooth]);
            }
        },
        [selected, onChange],
    );

    return (
        <DandyMouthDisplay
            animate={animate}
            fullMouth={{
                upperVisible: true,
                upperHighlighted: true,
                upperActiveColor: upperEnabled ? FlossPalette.DARK_TAN : FlossPalette.TAN,
                lowerVisible: true,
                lowerHighlighted: true,
                lowerActiveColor: lowerEnabled ? FlossPalette.DARK_TAN : FlossPalette.TAN,
            }}
            items={items}
            bridges={[]}
            verticalLayout={!!verticalLayout}
            onToothClick={onToothClick}
            archLabels={!!archLabels}
        />
    );
};
