import type { OpsScreen } from '../../redux/ui/ui.types';
import { RouterUtils } from '../../utils/router/RouterUtils';
import { type SessionTestFn, SessionGuardUtils, SessionGuard } from '@orthly/session-client';
import React from 'react';
import { Link } from 'react-router-dom';

const ScreenSessionGuards: { [K in OpsScreen]?: SessionTestFn } = {
    billing: SessionGuardUtils.hasCapability('billing', 'billing.list'),
    pricing: SessionGuardUtils.hasCapability('pricing', 'pricing.list'),
    automations: SessionGuardUtils.hasCapability('automation', 'automation.list'),
    config: SessionGuardUtils.hasCapability('master_config', 'master_config.view'),
    labs: SessionGuardUtils.hasCapability('lab_management', 'lab_management.labs.list'),
    routing: SessionGuardUtils.hasCapability('lab_management', 'lab_management.routing.list'),

    // We allow all users except designers to view the accounts/practices and support tabs (exceptions: if you are an admin or developer).
    accounts: SessionGuardUtils.hasCapability('practice', 'practice.list'),

    // We allow all users except designers to view the accounts and support tabs (exceptions: if you are an admin or developer or design manager).
    users: SessionGuardUtils.hasCapability('user', 'user.list'),

    training: SessionGuardUtils.hasCapability('design_training', 'design_training.train'),
};

// Given a screen and children, will only render if the children if the user is allowed to access the provided screen.
// NOTE: you are able to pick arbitrary { screen, children } pairs -- you can guard literally anything based on any screen.
export const OpsScreenSessionGuard: React.FC<{ screen: OpsScreen; children: React.ReactNode }> = props => {
    const testFn = ScreenSessionGuards[props.screen];
    if (testFn) {
        return (
            <SessionGuard fallback={null} test={testFn}>
                {props.children}
            </SessionGuard>
        );
    }
    return <>{props.children}</>;
};

export const OpsScreenSessionGuardedNavigationLink =
    (screen: OpsScreen): React.FC =>
    props => (
        <OpsScreenSessionGuard screen={screen}>
            <Link style={{ textDecoration: 'none', color: 'inherit' }} to={RouterUtils.pathForScreen(screen)}>
                {props.children}
            </Link>
        </OpsScreenSessionGuard>
    );
