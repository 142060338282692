import OpenInNewWindow from '@orthly/dentin/assets/images/OpenInNewWindow.png';
import {
    Text,
    FlossPalette,
    stylesFactory,
    Dialog,
    Grid,
    IconButton,
    Slide,
    Tooltip,
    CloseIcon,
    WarningIcon,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    paper: {
        position: 'absolute',
        bottom: 0,
    },
    dialogContainer: {
        '& .MuiDialog-container .MuiDialog-paper': {
            margin: 8,
        },
    },
}));

interface OpenIncognitoDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    practiceUrl: string;
    title: string;
}

const Transition = React.forwardRef((props: { children: React.ReactElement }, ref) => {
    return <Slide direction={'up'} {...props} ref={ref} timeout={{ enter: 300, exit: 300 }} />;
});

export const OpenIncognitoDialog: React.FC<OpenIncognitoDialogProps> = props => {
    const classes = useStyles();
    const { open, setOpen, practiceUrl, title } = props;
    const [rightClickHint, setRightClickHint] = React.useState(false);

    const onClick = () => {
        setOpen(false);
        setRightClickHint(false);
    };

    return (
        <Dialog
            className={classes.dialogContainer}
            classes={{ paper: classes.paper }}
            open={open}
            onClose={onClick}
            PaperProps={{ style: { borderRadius: 8 } }}
            TransitionComponent={Transition as any}
        >
            <Grid container>
                <Grid
                    container
                    justifyContent={'space-between'}
                    wrap={'nowrap'}
                    alignItems={'center'}
                    style={{ padding: '16px 16px 0 16px' }}
                >
                    <Text variant={'body2'} style={{ color: FlossPalette.GRAY, paddingLeft: 16 }}>
                        {title}
                    </Text>
                    <Tooltip title={'Close'}>
                        <IconButton onClick={onClick} size={'small'}>
                            <CloseIcon style={{ color: FlossPalette.GRAY, fontSize: 16 }} />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Text variant={'body2'} style={{ padding: '0 32px' }}>
                    Right-click{' '}
                    <a
                        style={{ color: FlossPalette.STAR_GRASS, textDecoration: 'none', fontWeight: 600 }}
                        href={practiceUrl}
                        onClick={evt => {
                            evt.preventDefault();
                            setRightClickHint(true);
                        }}
                    >
                        this link
                    </a>{' '}
                    and select the “Open in incognito” option.
                </Text>
                {rightClickHint && (
                    <Grid container style={{ padding: '8px 32px 0 32px' }}>
                        <WarningIcon style={{ color: FlossPalette.ATTENTION, fontSize: 16 }} />
                        <Text variant={'caption'} medium style={{ color: FlossPalette.ATTENTION, marginLeft: 8 }}>
                            Please right-click the link above and open in incognito
                        </Text>
                    </Grid>
                )}
                <Grid
                    container
                    style={{ backgroundColor: FlossPalette.TAN, padding: '16px 24px 0 24px', marginTop: 16 }}
                >
                    <Grid item container style={{ padding: '0 10px 4px 10px' }}>
                        <Text variant={'caption'} medium style={{ color: FlossPalette.GRAY }}>
                            How to impersonate
                        </Text>
                        <Text variant={'caption'} style={{ color: FlossPalette.GRAY }}>
                            Select “Open link in incognito window” after right-clicking the link above, as shown in this
                            example:
                        </Text>
                    </Grid>
                    <Grid item container justifyContent={'center'}>
                        <img src={OpenInNewWindow} alt={'Open link in incognito window'} style={{ maxWidth: '100%' }} />
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};
