import type { OrderDetailBlockProps } from './OrderDetailBlock';
import { OrderDetailBlock } from './OrderDetailBlock';
import { OrthlyErrorBoundary } from '@orthly/ui';
import { stylesFactory, FlossPalette, Collapse, IconButton, ExpandMoreIcon } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    titleRow: { minHeight: 0, cursor: 'pointer' },
    icon: { color: FlossPalette.GRAY, transition: theme.transitions.create('transform') },
    iconOpen: { transform: 'rotate(180deg)' },
}));

interface OrderDetailAccordionBlockProps extends Omit<OrderDetailBlockProps, 'fitHeightToContent' | 'titleRowProps'> {
    defaultOpen?: boolean;
    isLoading?: boolean;
    onOpen?: () => void;
}

// To avoid paying to render the contents of a closed accordion, we don't render the children until it opens.
// This may defer the cost from pageload to onclick, when it's relatively cheap. Or, better yet, prevents
// the cost from ever being paid at all if the accordion is never opened, which is typically the common case.
// Once the accordian has been opened the first time, we keep it loaded. We do this for 3 reasons:
//   1. the accordian animates closed nicely. If we removed the children as soon as the accordian's state=closed,
//      the contents (and height) of the accordian would drop to 0 instantaniously, and it would snap shut instead
//      of gracefully rolling up.
//   2. We preserve any state inside the children. If we removed the children on close, we would knock out the
//      state of any children by removing and recreating them each time the collapse is closed and re-opened.
//   3. We only pay the rendering cost of the children once. If we removed and re-added the children, we would have
//      to pay their render cost every time we re-open the accordian.
type AccordionState = 'closed-unloaded' | 'open' | 'closed-loaded';

export const OrderDetailAccordionBlock: React.FC<OrderDetailAccordionBlockProps> = props => {
    const classes = useStyles();
    const { children, actions, defaultOpen, isLoading, onOpen: onOpenProp, ...blockProps } = props;
    const [state, setState] = React.useState<AccordionState>(defaultOpen ? 'open' : 'closed-unloaded');
    const accordionOnClick = React.useCallback(() => {
        setState(s => {
            const newValue = s === 'open' ? 'closed-loaded' : 'open';
            if (newValue === 'open' && onOpenProp) {
                onOpenProp();
            }
            return newValue;
        });
    }, [onOpenProp]);
    const open = state === 'open';

    React.useEffect(() => {
        if (!isLoading) {
            if (defaultOpen) {
                setState('open');
            } else {
                setState('closed-loaded');
            }
        }
    }, [isLoading, defaultOpen]);
    return (
        <OrderDetailBlock
            {...blockProps}
            fitHeightToContent
            classes={{ ...props.classes, titleRow: `${classes.titleRow} ${props.classes?.titleRow ?? ''}` }}
            titleRowProps={{ onClick: accordionOnClick }}
            actions={
                <>
                    {actions}
                    <IconButton size={'small'} onClick={accordionOnClick}>
                        <ExpandMoreIcon className={cx(classes.icon, open && classes.iconOpen)} />
                    </IconButton>
                </>
            }
        >
            <Collapse in={open} style={{ width: '100%' }}>
                <OrthlyErrorBoundary
                    FallbackComponent={() => <p>{props.title} failed to load. Please try again later.</p>}
                >
                    {!isLoading ? children : null}
                </OrthlyErrorBoundary>
            </Collapse>
        </OrderDetailBlock>
    );
};
