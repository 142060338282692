/* eslint-disable max-lines */
import { DisabledFieldOverlay } from '../LegacyCheckoutField/DisabledFieldOverlay';
import type { ShadeFieldAutocompleteCustomPopoverProps, ShadeFieldAutocompleteProps } from './ShadeFieldAutocomplete';
import { ShadeFieldAutocomplete, ShadeName } from './ShadeFieldAutocomplete';
import { ToothShadeDisplay } from './ToothShadeDisplay';
import { ToothUtils, CartItemV2Utils, isTeethShade, isValidShadeType, LabOrderItemSKUType } from '@orthly/items';
import type { ValidShadeType, TeethShade, ICartItemV2DTO, ICartItemV2Update, IOrderItemShade } from '@orthly/items';
import { SearchIcon, WarningIcon } from '@orthly/ui';
import type { GridSize } from '@orthly/ui-primitives';
import {
    Button,
    FlossPalette,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    IconButton,
    InputAdornment,
    Text,
    Collapse,
    AddIcon,
    Icon,
    useScreenIsMobile,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type ItemShade = {
    name: ValidShadeType;
    value?: TeethShade;
};

export interface ShadeOption {
    name: ValidShadeType;
    optional: boolean;
    visible: boolean;
    shadesToExclude: ShadeName[];
    isOther?: boolean;
}

interface ShadeOptionSectionProps extends Omit<ShadeFieldAutocompleteProps, 'isBaseShadeType'> {
    shadeOption: ShadeOption;
    onClickAdd: () => void;
    onClickDelete: () => void;
    shadesToExclude: ShadeName[];
    isRequired?: boolean;
}

// Determines specific shades & particular shade options to hide for a given sku

export const getHiddenShadeProps = (sku: LabOrderItemSKUType, enableStumpIfRelevant = true) => {
    const partialOrDentureSku = sku === LabOrderItemSKUType.Partial || sku === LabOrderItemSKUType.Denture;

    const getHiddenShades = (
        enableStumpIfRelevant: boolean,
        partialOrDentureSku: boolean,
        sku: LabOrderItemSKUType,
    ): ValidShadeType[] => {
        // Dentures and Partials only accept a base shade (and tissue, but that's set in it's own step).
        if (partialOrDentureSku) {
            return ['gingival', 'incisal', 'stump', 'tissue'];
        }
        // We only care about the stump shade during the Order Review portion of chairside submission, unless it's an implant.
        // Implants should never ask for stump shades because it is not applicable.
        const isStumpDisabled =
            !enableStumpIfRelevant || sku === LabOrderItemSKUType.Implant || sku === LabOrderItemSKUType.ImplantBridge;

        return _.compact(['tissue', isStumpDisabled ? 'stump' : undefined]);
    };
    // We currently don't offer Vita Master shades for the Denture or Partial base shade
    const hiddenShadeOpts: Pick<ShadeOption, 'name' | 'shadesToExclude'> | undefined = partialOrDentureSku
        ? { name: 'base', shadesToExclude: [ShadeName.master] }
        : undefined;

    return { hiddenShades: getHiddenShades(enableStumpIfRelevant, partialOrDentureSku, sku), hiddenShadeOpts };
};

const ShadeOptionSection: React.FC<ShadeOptionSectionProps> = props => {
    const { shadeOption, onClickAdd, onClickDelete, ...autocompleteProps } = props;
    const { name, optional, visible } = shadeOption;
    const title = `${_.capitalize(name)} shade${optional ? ' (optional)' : ''}`;
    return (
        <Grid container justifyContent={'flex-start'}>
            <Grid container item xs={1} />
            <Grid container item xs={4} alignItems={'center'}>
                <Text style={{ width: '100%', color: optional ? FlossPalette.GRAY : FlossPalette.BLACK }}>{title}</Text>
            </Grid>
            <Grid container item xs={6}>
                <Collapse in={!visible} style={{ width: '100%' }}>
                    <IconButton onClick={() => onClickAdd()}>
                        <AddIcon />
                    </IconButton>
                </Collapse>
                <Collapse in={visible} style={{ width: '100%' }} unmountOnExit mountOnEnter>
                    <Grid container>
                        <Grid container item xs={8}>
                            <ShadeFieldAutocomplete
                                {...autocompleteProps}
                                isBaseShadeType={shadeOption.name === 'base'}
                            />
                        </Grid>
                        {/* We render this with visibility: hidden if its not optional so the height of the row stays consistent */}
                        <IconButton
                            style={{
                                width: '3.3rem',
                                marginLeft: '8px',
                                marginBottom: '5px', // Adding margin bottom so icon is aligned vertically with text
                                visibility: !optional ? 'hidden' : 'visible',
                            }}
                            onClick={() => onClickDelete()}
                        >
                            <Icon icon={'DeleteOutline'} />
                        </IconButton>
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    );
};

const standardShades = [ShadeName.classical, ShadeName.master, ShadeName.bleached];
const initialShades: ShadeOption[] = [
    { name: 'gingival', optional: true, visible: false, shadesToExclude: [ShadeName.nd, ShadeName.tissue] },
    { name: 'base', optional: false, visible: true, shadesToExclude: [ShadeName.nd, ShadeName.tissue] },
    { name: 'incisal', optional: true, visible: false, shadesToExclude: [ShadeName.nd, ShadeName.tissue] },
    { name: 'stump', optional: true, visible: false, shadesToExclude: [...standardShades, ShadeName.tissue] },
    { name: 'tissue', optional: true, visible: true, shadesToExclude: [...standardShades, ShadeName.nd] },
];

function initialShadeOpts(itemShades: ItemShade[], isUpperTooth: boolean): ShadeOption[] {
    const opts = initialShades.map(shadeOption => {
        const savedValue = itemShades.find(s => s.name === shadeOption.name)?.value;
        return { ...shadeOption, visible: shadeOption.visible || !!savedValue };
    });
    return isUpperTooth ? opts : opts.reverse();
}

export interface ShadePickerProps {
    unns: number[];
    onShadeChange: (shadeName: ValidShadeType, value?: TeethShade) => void;
    shades: ItemShade[];
    disabled?: boolean;
    // When provided, these shades will be excluded from this picker
    // Otherwise, we show all shades
    hiddenShades?: ValidShadeType[];
    CustomPopover?: React.ComponentType<ShadeFieldAutocompleteCustomPopoverProps>;
    originalShades?: ItemShade[];
}

export const ShadePicker: React.FC<ShadePickerProps> = props => {
    const { unns, onShadeChange, disabled = false, hiddenShades, shades, CustomPopover } = props;

    const isUpperTooth = ToothUtils.checkGroupMembership('Upper', unns);
    const [shadeOptions, setShadeOptions] = React.useState<ShadeOption[]>(
        // filter out tissue shades unless includeTissue is set to true
        initialShadeOpts(shades, isUpperTooth).filter(opt => !hiddenShades?.includes(opt.name)),
    );

    const findCurrentShade = React.useCallback(
        (name: ValidShadeType) => shades.find(s => s.name === name)?.value,
        [shades],
    );
    const setShade = React.useCallback(
        (shadeName: ValidShadeType, value: TeethShade | undefined = undefined) => {
            const currentShade = findCurrentShade(shadeName);
            if (value !== currentShade) {
                onShadeChange(shadeName, value);
            }
        },
        [findCurrentShade, onShadeChange],
    );

    const isAnteriorTooth = ToothUtils.checkGroupMembership('Anterior', unns);
    const warnAboutStump =
        !hiddenShades?.includes('stump') && isAnteriorTooth && findCurrentShade('base') && !findCurrentShade('stump');

    const getOptionSectionProps = React.useCallback(
        (option: ShadeOption): ShadeOptionSectionProps => {
            const toggleShadeVisible = (visible: boolean) => {
                setShadeOptions(oldOpts => oldOpts.map(o => (o.name === option.name ? { ...o, visible } : o)));
            };
            return {
                shadeOption: { ...option, visible: disabled ? false : option.visible },
                currentShade: findCurrentShade(option.name),
                onClickAdd: () => toggleShadeVisible(true),
                onClickDelete: () => {
                    toggleShadeVisible(false);
                    setShade(option.name);
                },
                setShade: value => setShade(option.name, value),
                shadesToExclude: option.shadesToExclude,
            };
        },
        [disabled, findCurrentShade, setShade],
    );

    return (
        <>
            <Grid container direction={'row'} alignItems={'flex-start'}>
                <Grid container item xs={3} justifyContent={'center'}>
                    <ToothShadeDisplay
                        base={findCurrentShade('base')}
                        gingival={findCurrentShade('gingival')}
                        incisal={findCurrentShade('incisal')}
                        rotate={!isUpperTooth}
                        svgStyle={{ height: '11rem', width: '12rem' }}
                    />
                    <Grid container direction={'row'} style={{ marginTop: '0.5rem' }}>
                        <Grid container item justifyContent={'center'}>
                            <Text style={{ color: FlossPalette.GRAY, fontSize: '0.8rem', textAlign: 'center' }}>
                                Colors are approximate, for display purposes only
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    item
                    xs={8}
                    direction={'column'}
                    alignItems={'flex-start'}
                    justifyContent={'space-around'}
                >
                    {shadeOptions.map(opt => (
                        <ShadeOptionSection
                            key={opt.name}
                            {...getOptionSectionProps(opt)}
                            CustomPopover={CustomPopover}
                        />
                    ))}
                    {warnAboutStump && (
                        <Grid container justifyContent={'flex-start'}>
                            <Grid container item xs={1} />
                            <Grid container item xs={8}>
                                <Text style={{ color: FlossPalette.ATTENTION }}>
                                    <WarningIcon
                                        style={{ color: FlossPalette.ATTENTION, marginRight: '4px', float: `left` }}
                                    />
                                    For anterior cases, we recommend that you add a stump shade.
                                </Text>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <DisabledFieldOverlay disabled={disabled} />
        </>
    );
};

// Typescript is weird so we can't say `shade is ItemShade`, but this gets the job done
// we need to define value as not optional here to get the typeguard to work with the input

export const isItemShadeValid = (shade: IOrderItemShade): shade is { name: ValidShadeType; value: TeethShade } => {
    return isValidShadeType(shade.name) && isTeethShade(shade.value);
};

interface CartItemShadePickerProps extends Omit<ShadePickerProps, 'unns' | 'shades' | 'onShadeChange'> {
    item: ICartItemV2DTO;
    updateItem: (update: ICartItemV2Update) => void;
}

export const CartItemShadePicker: React.FC<CartItemShadePickerProps> = props => {
    const { item, updateItem, ...shadePickerProps } = props;
    const unns = CartItemV2Utils.getUniqueUNNs(item);

    const onShadeChange = React.useCallback(
        (shadeName: ValidShadeType, value?: TeethShade) => {
            updateItem({
                name: 'shades',
                payload: item.shades
                    .filter(shade => shade.name !== shadeName)
                    .concat(value ? [{ value, name: shadeName }] : []),
            });
        },
        [item, updateItem],
    );

    return (
        <ShadePicker
            unns={unns}
            shades={item.shades.filter(isItemShadeValid)}
            onShadeChange={onShadeChange}
            {...shadePickerProps}
        />
    );
};

// Need base shade to be 1st in our list for the SimpleShadePicker UIs

export const organizedInitialShades: ShadeOption[] = [
    { name: 'base', optional: false, visible: true, shadesToExclude: [ShadeName.nd, ShadeName.tissue] },
    ...initialShades.filter(shade => shade.name !== 'base'),
];

function initialSimpleShadeOpts(itemShades: ItemShade[], isAnteriorTooth: boolean): ShadeOption[] {
    // For posterior teeth, we only show base shade initially
    const possibleShades = isAnteriorTooth
        ? organizedInitialShades.map(shade => ({ ...shade, visible: true }))
        : organizedInitialShades.map(shade => (shade.name !== 'base' ? { ...shade, visible: false } : shade));
    const opts = possibleShades.map(shadeOption => {
        const savedValue = itemShades.find(s => s.name === shadeOption.name)?.value;
        return { ...shadeOption, visible: shadeOption.visible || !!savedValue };
    });
    return opts;
}

export interface DynamicShadeOptionProps {
    ShadeOptionSectionProps: ShadeOptionSectionProps;
    isAnterior: boolean;
    disableAutoFocusShade: boolean;
    shadeContainerLength: GridSize;
}

export const DynamicShadeOption: React.VFC<DynamicShadeOptionProps> = props => {
    const { shadeOption, onClickAdd, onClickDelete, originalShade, isRequired, ...autocompleteProps } =
        props.ShadeOptionSectionProps;
    const { name, optional, visible } = shadeOption;
    const isMobile = useScreenIsMobileOrVerticalTablet();
    if (!visible) {
        return null;
    }
    const { disableAutoFocusShade, shadeContainerLength, isAnterior } = props;
    const title = `${_.capitalize(name)} shade`;

    return (
        <Grid item container direction={'column'} xs={isMobile ? 12 : shadeContainerLength}>
            <Grid item>
                <Collapse in={visible} style={{ width: '100%' }} unmountOnExit mountOnEnter>
                    <Grid container>
                        <Grid item xs={12}>
                            <div style={{ display: `flex`, justifyContent: 'space-between', alignItems: `center` }}>
                                <Text variant={'h6'}>
                                    {title}

                                    {!isAnterior && (
                                        <IconButton
                                            style={{
                                                width: '3.3rem',
                                                marginLeft: 12,
                                                marginBottom: 5, // Adding margin bottom so icon is aligned vertically with text
                                                visibility: !optional ? 'hidden' : 'visible',
                                                color: FlossPalette.PRIMARY_FOREGROUND,
                                            }}
                                            onClick={() => onClickDelete()}
                                            data-test={'shade-delete-button'}
                                        >
                                            <Icon icon={'DeleteOutline'} />
                                        </IconButton>
                                    )}
                                </Text>
                                {isRequired && (
                                    <Text variant={'body1'} style={{ color: FlossPalette.ATTENTION }}>
                                        Required
                                    </Text>
                                )}
                            </div>
                            <ShadeFieldAutocomplete
                                {...autocompleteProps}
                                originalShade={originalShade}
                                isBaseShadeType={shadeOption.name === 'base'}
                                disableAutoFocusShade={disableAutoFocusShade}
                                endAdornment={
                                    <InputAdornment position={'end'}>
                                        <SearchIcon color={'action'} style={{ height: 16, marginTop: 6 }} />
                                    </InputAdornment>
                                }
                            />
                            {autocompleteProps.showShadeChange && (
                                <Text
                                    variant={'caption'}
                                    style={{
                                        color: FlossPalette.GRAY,
                                        fontWeight: 400,
                                        marginTop: `8px`,
                                        display: `inline-block`,
                                    }}
                                >
                                    {originalShade ? `Original value of item: ${originalShade}` : ''}
                                </Text>
                            )}
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    );
};

export interface SimpleShadePickerProps extends ShadePickerProps {
    // by default we disable autofocus for all shades except base shade
    disableAutoFocusShade?: boolean;
    hiddenShadeOpts?: Pick<ShadeOption, 'name' | 'shadesToExclude'>;
    originalShades?: ItemShade[];
    requiredShadeTypes?: ValidShadeType[];
}

export const SimpleShadePicker: React.VFC<SimpleShadePickerProps> = props => {
    const {
        unns,
        onShadeChange,
        disabled = false,
        hiddenShades,
        shades,
        disableAutoFocusShade,
        hiddenShadeOpts,
        originalShades,
        requiredShadeTypes,
    } = props;

    const isMobile = useScreenIsMobile();

    const isAnteriorTooth = ToothUtils.checkGroupMembership('Anterior', unns);
    // filter out hidden shade types (eg. not showing tissue shades)
    const filteredShades = initialSimpleShadeOpts(shades, isAnteriorTooth).filter(
        (opt: ShadeOption) => !hiddenShades?.includes(opt.name),
    );
    // filter out shade options we don't offer for a given shade type (eg. no vita masters for dentures)
    const filteredOptions = filteredShades.map((opt: ShadeOption) => {
        if (hiddenShadeOpts?.name === opt.name) {
            return { ...opt, shadesToExclude: opt.shadesToExclude.concat(hiddenShadeOpts.shadesToExclude) };
        }
        return opt;
    });
    const [shadeOptions, setShadeOptions] = React.useState<ShadeOption[]>(filteredOptions);

    const findCurrentShade = (name: ValidShadeType) => shades.find(s => s.name === name)?.value;
    const findOriginalShade = (name: ValidShadeType) => (originalShades ?? []).find(s => s.name === name)?.value;
    const setShade = (shadeName: ValidShadeType, value: TeethShade | undefined = undefined) => {
        const currentShade = findCurrentShade(shadeName);
        if (value !== currentShade) {
            onShadeChange(shadeName, value);
        }
    };
    const warnAboutStump =
        !hiddenShades?.includes('stump') && isAnteriorTooth && findCurrentShade('base') && !findCurrentShade('stump');

    const getOptionSectionProps = (option: ShadeOption): ShadeOptionSectionProps => {
        const toggleShadeVisible = (visible: boolean) => {
            setShadeOptions(oldOpts => oldOpts.map(o => (o.name === option.name ? { ...o, visible } : o)));
        };
        return {
            shadeOption: { ...option, visible: disabled ? false : option.visible },
            currentShade: findCurrentShade(option.name),
            onClickAdd: () => {
                toggleShadeVisible(true);

                if (originalShades) {
                    setShade(option.name, findOriginalShade(option.name));
                }
            },
            onClickDelete: () => {
                toggleShadeVisible(false);
                setShade(option.name);
            },
            setShade: value => setShade(option.name, value),
            shadesToExclude: option.shadesToExclude,
            showShadeChange: !!originalShades,
            originalShade: findOriginalShade(option.name),
            isRequired: requiredShadeTypes?.includes(option.name),
        };
    };

    return (
        <Grid container style={{ maxWidth: 760 }}>
            <Grid container direction={'row'} spacing={3}>
                {shadeOptions.map((opt: ShadeOption) => (
                    <DynamicShadeOption
                        key={opt.name}
                        isAnterior={isAnteriorTooth}
                        ShadeOptionSectionProps={{ ...getOptionSectionProps(opt) }}
                        disableAutoFocusShade={disableAutoFocusShade ?? opt.name !== 'base'}
                        shadeContainerLength={
                            shadeOptions.filter(shade => shade.name !== 'base' && shade.visible).length === 0 ? 12 : 6
                        }
                    />
                ))}

                <Grid container item direction={'column'}>
                    <Collapse in={shadeOptions.some(shade => !shade.visible)}>
                        <Grid item style={{ marginBottom: 16 }}>
                            <Text variant={'h6'}>Need to add more?</Text>
                        </Grid>
                    </Collapse>
                    <Grid container item direction={isMobile ? 'column' : 'row'} spacing={1}>
                        {shadeOptions
                            .filter(shade => shade.name !== 'base')
                            .map(
                                (opt: ShadeOption) =>
                                    !opt.visible && (
                                        <Grid item xs key={opt.name}>
                                            <Collapse in={!opt.visible}>
                                                <Button
                                                    variant={'secondary'}
                                                    onClick={() => getOptionSectionProps(opt).onClickAdd()}
                                                    style={{ width: '100%' }}
                                                    data-test={'shade-add-button'}
                                                >
                                                    Add {_.capitalize(opt.name)}
                                                    <AddIcon style={{ marginLeft: 4 }} />
                                                </Button>
                                            </Collapse>
                                        </Grid>
                                    ),
                            )}
                    </Grid>
                </Grid>
            </Grid>
            {warnAboutStump && (
                <Grid container justifyContent={'flex-start'}>
                    <Grid container item xs={1} />
                    <Grid container item xs={8}>
                        <Text variant={'body2'} style={{ color: FlossPalette.ATTENTION }}>
                            <WarningIcon style={{ color: FlossPalette.ATTENTION, marginRight: '4px', float: `left` }} />
                            For anterior cases, we recommend that you add a stump shade.
                        </Text>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

// This differs from the regular CartItemShadePicker (no visual shade indications, just inputs)
export interface CartItemSimpleShadePickerProps
    extends CartItemShadePickerProps,
        Pick<SimpleShadePickerProps, 'hiddenShadeOpts' | 'unns' | 'requiredShadeTypes'> {}

export const CartItemSimpleShadePicker: React.VFC<CartItemSimpleShadePickerProps> = props => {
    const { item, updateItem, hiddenShadeOpts, unns, requiredShadeTypes, ...shadePickerProps } = props;

    const onShadeChange = React.useCallback(
        (shadeName: ValidShadeType, value?: TeethShade) => {
            updateItem({
                name: 'shades',
                payload: item.shades
                    .filter(shade => shade.name !== shadeName)
                    .concat(value ? [{ value, name: shadeName }] : []),
            });
        },
        [item, updateItem],
    );

    return (
        <SimpleShadePicker
            unns={unns}
            shades={item.shades.filter(isItemShadeValid)}
            onShadeChange={onShadeChange}
            {...shadePickerProps}
            hiddenShadeOpts={hiddenShadeOpts}
            requiredShadeTypes={requiredShadeTypes}
        />
    );
};
