import { RouterUtils } from '../../../utils/router/RouterUtils';
import { CreateLabsManufacturerAction } from './actions/CreateManufacturerAction';
import type { LabsGqlOrganizationDtoFragment, LabsGqlListOrgsQuery } from '@orthly/graphql-operations';
import { useListLabOrgs } from '@orthly/graphql-react';
import MUITable from '@orthly/mui-table';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const basePath = `${RouterUtils.pathForScreen('labs')}/accounts`;

type ManufacturersTableRow = LabsGqlOrganizationDtoFragment;

const defaultQuery: LabsGqlListOrgsQuery = { listOrganizations: [], __typename: 'Query' };

export const ManufacturersTable: React.VFC = () => {
    const { data: manufacturerData = defaultQuery, refetch, loading } = useListLabOrgs({ fetchPolicy: 'cache-first' });
    const history = useHistory();
    const CustomRightComponent = React.useCallback(
        () => (
            <Grid container justifyContent={'flex-end'} style={{ paddingTop: 10 }}>
                <CreateLabsManufacturerAction onComplete={refetch} />
            </Grid>
        ),
        [refetch],
    );
    return (
        <Grid container style={{ width: '100%' }}>
            <MUITable<ManufacturersTableRow>
                loading={loading}
                title={'Lab Manufacturers'}
                data={manufacturerData.listOrganizations}
                rowOptions={{ rowHover: true }}
                displayOptions={{ fixedSearch: true, fixedHeader: true, responsive: 'scroll' }}
                eventHooks={{ onRowClick: row => history.push(`${basePath}/${row.id}`) }}
                columns={[
                    { name: 'id', hidden: true, render: 'id' },
                    { name: 'Lab', render: 'name', bodyCellWrapStyle: { fontWeight: 'bold' } },
                    {
                        name: 'Primary Contact',
                        render: row =>
                            `${row.primary_contact?.first_name ?? 'Not'} ${row.primary_contact?.last_name ?? 'Set'}`,
                    },
                    { name: 'Email', render: r => r.primary_contact?.email },
                    { name: 'Phone', render: r => r.primary_contact?.phone_number },
                    {
                        name: 'Notification Prefs',
                        render: row =>
                            [
                                `Email: ${row.lab_notification_preferences?.emails?.join(', ') ?? '(none)'}`,
                                `Phone: ${row.lab_notification_preferences?.phone_number || '(none)'}`,
                                `Time Zone: ${row.lab_notification_preferences?.time_zone || '(none)'}`,
                            ].join('\n'),
                    },
                ]}
                actions={{
                    global: [{ position: 'toolbar', onClick: () => refetch(), icon: 'refresh', tooltip: 'Refresh' }],
                }}
                toolbarOptions={{ CustomRight: CustomRightComponent }}
            />
        </Grid>
    );
};
