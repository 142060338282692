import { ListAbutmentParts } from './ListAbutmentParts';
import type { AbutmentPartManagementData } from './types';
import { RootActionDialog } from '@orthly/ui';
import React from 'react';

interface AbutmentPartToImplantScanbodyContainerProps {
    abutmentManagerData: AbutmentPartManagementData;
}

export const AbutmentPartToImplantScanbodyContainer: React.FC<AbutmentPartToImplantScanbodyContainerProps> = ({
    abutmentManagerData,
}) => {
    const [isAbutmentManagementModalOpen, setIsAbutmentManagementModalOpen] = React.useState(false);

    if (!abutmentManagerData.implantTypeId || !abutmentManagerData.implantToScanbodyId) {
        return null;
    }

    return (
        <RootActionDialog
            title={'Manage abutment parts for implant/scanbody'}
            open={isAbutmentManagementModalOpen}
            setOpen={setIsAbutmentManagementModalOpen}
            loading={false}
            PaperProps={{ style: { alignSelf: 'flex-start' } }}
            buttonText={'Manage abutment parts'}
            buttonProps={{ style: { width: `auto` } }}
            showCloseButton={true}
            maxWidth={'lg'}
            // I disabled the backdrop click as we allow users to delete from the table and I want to
            // reduce the times they'll X out of the modal when trying to remove an item from the list
            disableBackdropClick
            content={<ListAbutmentParts abutmentManagerData={abutmentManagerData} />}
        />
    );
};
