import { QCColorLegend } from '../../ColorRamp';
import { UndercutHighlight, useHeatmapHighlight, MouseLabel } from '../../ModelViewer';
import { useScanReviewCompleteViewAppContext } from '../ScanReviewCompleteView.hooks';
import { ScanReviewPanel } from '../ScanReviewPanel';
import { useScanReviewUndercutViewContext } from './ScanReviewUndercutView.hooks';
import { HeatMapType } from '@orthly/forceps';
import { Box } from '@orthly/ui-primitives';
import React from 'react';

export const ScanReviewUndercutView: React.FC = props => {
    const { appearance, appearanceManager } = useScanReviewCompleteViewAppContext();
    const { viewManager, toolController } = useScanReviewUndercutViewContext();
    const reversedInsertionAxis = React.useMemo(() => {
        return toolController?.activeJawData.insertionAxis?.clone()?.negate();
    }, [toolController]);

    const { updateTextRef, setHeatmapHighlight: setUndercutHighlight } = useHeatmapHighlight();

    return (
        <Box sx={{ minWidth: 0, minHeight: 0, display: 'flex', flex: 'auto' }}>
            <ScanReviewPanel viewManager={viewManager} key={'undercut-view'}>
                {props.children}
                {toolController && reversedInsertionAxis && (
                    <UndercutHighlight
                        targetModel={{ mesh: toolController.activeJawData.scanMesh }}
                        shadowingMeshes={[toolController.activeJawData.scanMesh]}
                        insertionAxis={reversedInsertionAxis}
                        onHighlight={setUndercutHighlight}
                        showOnlyEscapeDistance
                    />
                )}
            </ScanReviewPanel>
            {toolController && (
                <Box sx={{ position: 'absolute', top: 86, right: 16, zIndex: 21 }}>
                    <QCColorLegend
                        heatMapType={HeatMapType.Undercut}
                        dynamicHeatmaps={true}
                        heatMapRange={appearance.undercutHeatmapRange}
                        setHeatMapRange={range => appearanceManager.setUndercutHeatmapRange(range)}
                        heatMapStep={0.05}
                    />
                </Box>
            )}
            <MouseLabel forceUpdateRef={updateTextRef} />
        </Box>
    );
};
