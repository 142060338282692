import { useAdminUiAction } from '../../redux/ui/ui.actions';
import { useAdminUiSelector } from '../../redux/ui/ui.state';
import type { PartnerNameFragment } from '../../utils/usePartners';
import { usePartnersData } from '../../utils/usePartners';
import type { LabsGqlOrganizationDtoFragment } from '@orthly/graphql-operations';
import { useGetImpersonationTokenLazyQuery, useListLabOrgs } from '@orthly/graphql-react';
import { useHasCapability } from '@orthly/session-client';
import type { NavigationEntryBottom, SimpleAutocompleteOption } from '@orthly/ui';
import { CopyLinkIcon } from '@orthly/ui';
import {
    FlossPalette,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Text,
    Typography,
    CloseIcon,
    Icon,
} from '@orthly/ui-primitives';
import { HotkeysManager, SpotlightSearch, SpotlightSearchItem, useCopyToClipboard } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

type ImpersonateData = {
    organization_id: string;
    token?: string;
    practiceUrl?: string;
    labPortalUrl?: string | null;
};

interface ImpersonateOpt extends SimpleAutocompleteOption {
    type: 'Lab' | 'Practice';
    label: string;
    subtitle?: string;
}

interface ImpersonatePreviewBoxProps {
    impersonateLink: string;
    impersonateLabPortalLink?: string | null;
    onReset: () => void;
    userName: string;
}

export const ImpersonatePreviewBox: React.FC<ImpersonatePreviewBoxProps> = props => {
    const { impersonateLink, userName, impersonateLabPortalLink } = props;
    const copyToClipboard = useCopyToClipboard();
    const onClickCopy = React.useCallback(() => {
        copyToClipboard({
            text: impersonateLink,
            successMessage: 'Impersonate link copied to clipboard!',
            errorMessage: 'Failed to copy link :(',
        });
    }, [copyToClipboard, impersonateLink]);
    const onLabPortalLinkClickCopy = React.useCallback(() => {
        copyToClipboard({
            text: impersonateLabPortalLink ?? 'no-link-available',
            successMessage: 'Impersonate link copied to clipboard!',
            errorMessage: 'Failed to copy link :(',
        });
    }, [copyToClipboard, impersonateLabPortalLink]);
    const [rightClickHint, setRightClickHint] = React.useState(false);
    const [rightClickLabPortalHint, setRightClickLabPortalHint] = React.useState(false);
    return (
        <Grid container style={{ padding: 12 }}>
            <Grid
                container
                justifyContent={'space-between'}
                wrap={'nowrap'}
                alignItems={'center'}
                style={{ paddingBottom: 8 }}
            >
                <Text variant={'h6'}>Impersonate {userName}</Text>
                <Tooltip title={'Reset'}>
                    <IconButton onClick={() => props.onReset()} size={'small'}>
                        <CloseIcon style={{ color: FlossPalette.ATTENTION }} />
                    </IconButton>
                </Tooltip>
            </Grid>
            {!impersonateLabPortalLink && (
                <>
                    <TextField
                        variant={'standard'}
                        fullWidth
                        label={'Impersonate Practice Portal Link'}
                        value={impersonateLink}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position={'end'} style={{ margin: 0, paddingRight: 8 }}>
                                    <Tooltip title={'Copy Link'}>
                                        <IconButton onClick={onClickCopy} size={'small'}>
                                            <CopyLinkIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Grid container direction={'column'} alignItems={'center'} style={{ paddingTop: 8 }}>
                        <p>
                            Right-click{' '}
                            <a
                                href={impersonateLink}
                                onClick={evt => {
                                    evt.preventDefault();
                                    setRightClickHint(true);
                                }}
                            >
                                this link
                            </a>
                            , and select 'Open in {window.navigator.userAgent.match(/Chrome/) ? 'Incognito' : 'Private'}{' '}
                            Window'.
                        </p>
                        {rightClickHint && (
                            <p>
                                <strong>Please try right-clicking instead of left-clicking.</strong>
                            </p>
                        )}
                    </Grid>
                </>
            )}
            {impersonateLabPortalLink && (
                <>
                    <TextField
                        variant={'standard'}
                        fullWidth
                        label={'Impersonate Lab Portal Link'}
                        value={impersonateLabPortalLink}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position={'end'} style={{ margin: 0, paddingRight: 8 }}>
                                    <Tooltip title={'Copy Link'}>
                                        <IconButton onClick={onLabPortalLinkClickCopy} size={'small'}>
                                            <CopyLinkIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Grid container direction={'column'} alignItems={'center'} style={{ paddingTop: 8 }}>
                        <p>
                            Right-click{' '}
                            <a
                                href={impersonateLabPortalLink}
                                onClick={evt => {
                                    evt.preventDefault();
                                    setRightClickLabPortalHint(true);
                                }}
                            >
                                this link
                            </a>
                            , and select 'Open in {window.navigator.userAgent.match(/Chrome/) ? 'Incognito' : 'Private'}{' '}
                            Window'.
                        </p>
                        {rightClickLabPortalHint && (
                            <p>
                                <strong>Please try right-clicking instead of left-clicking.</strong>
                            </p>
                        )}
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export function failsSearch(
    id: string,
    title: string,
    subtitle: string | undefined,
    search: string | undefined,
): boolean {
    return (
        !!search &&
        !title.toLowerCase().includes(search.toLowerCase()) &&
        !(subtitle ?? '').toLowerCase().includes(search) &&
        id !== search
    );
}

function useLabImpersonateOpts(search?: string, selectedOrganizationId?: string) {
    const { data: rawManufacturers, loading } = useListLabOrgs({
        fetchPolicy: 'cache-first',
    });
    const options = React.useMemo(() => {
        const manufacturers: LabsGqlOrganizationDtoFragment[] = rawManufacturers?.listOrganizations ?? [];
        return manufacturers.flatMap<ImpersonateOpt>(m => {
            if (!m.primary_contact) {
                return [];
            }
            const label = m.name ?? `${m.primary_contact.first_name} ${m.primary_contact.last_name}`;
            const subtitle = m.primary_contact.email;
            return failsSearch(m.id, label, subtitle, search) && selectedOrganizationId !== m.id
                ? []
                : { subtitle, label, value: m.id, type: 'Lab' };
        });
    }, [rawManufacturers, search, selectedOrganizationId]);
    return { options, loading };
}

function usePartnerImpersonateOptions(search?: string, selectedOrganizationId?: string) {
    const { data: rawPartners, loading } = usePartnersData();
    const options = React.useMemo(() => {
        const partners: PartnerNameFragment[] = rawPartners?.listPracticeNames || [];
        return partners.flatMap<ImpersonateOpt>(partner => {
            const label = partner.name;
            const subtitle = partner.primary_contact_email ?? undefined;
            return failsSearch(partner.id, label, subtitle, search) && selectedOrganizationId !== partner.id
                ? []
                : {
                      subtitle,
                      label,
                      value: partner.id,
                      type: 'Practice',
                  };
        });
    }, [rawPartners, search, selectedOrganizationId]);
    return { options, loading };
}

function useImpersonateOptions() {
    const [search, setSearch] = React.useState<string | undefined>();
    const [impersonateData, setImpersonateData] = React.useState<ImpersonateData | undefined>();
    const [triggerQuery] = useGetImpersonationTokenLazyQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        onCompleted: data => {
            const organization_id = impersonateData?.organization_id;
            if (organization_id && data) {
                setImpersonateData({
                    organization_id,
                    token: data.getImpersonationToken.token,
                    practiceUrl: data.getImpersonationToken.practiceUrl,
                    labPortalUrl: data.getImpersonationToken.labPortalUrl ?? null,
                });
            }
        },
    });
    const onClickRow = React.useCallback(
        (organization_id: string) => {
            setImpersonateData({ organization_id });
            triggerQuery({ variables: { organization_id, user_id: null, expires_in_seconds: null } });
        },
        [triggerQuery],
    );
    const { options: labUserOptions, loading: loadingManufacturers } = useLabImpersonateOpts(
        search,
        impersonateData?.organization_id,
    );
    const { options: practiceUserOptions, loading: loadingPartners } = usePartnerImpersonateOptions(
        search,
        impersonateData?.organization_id,
    );
    const userOptions = React.useMemo(
        () => _.sortBy([...labUserOptions, ...practiceUserOptions], o => o.label),
        [labUserOptions, practiceUserOptions],
    );
    return {
        impersonateData,
        search,
        setSearch,
        onClickRow,
        userOptions,
        onReset: () => {
            setSearch(undefined);
            setImpersonateData(undefined);
        },
        loading: loadingManufacturers || loadingPartners,
    };
}

const ImpersonateAutocompleteItem = (listProps: React.HTMLAttributes<HTMLLIElement>, props: ImpersonateOpt) => {
    const { subtitle, type, value, label } = props;
    const searchItem = (
        <SpotlightSearchItem
            key={value}
            title={label}
            details={
                subtitle ? (
                    <Typography color={'textSecondary'} variant={'body2'}>
                        {subtitle}
                    </Typography>
                ) : null
            }
            titleRight={
                <Typography color={'textSecondary'} style={{ fontSize: 16 }}>
                    {type}
                </Typography>
            }
        />
    );
    return <li {...listProps}>{searchItem}</li>;
};

const GlobalImpersonateContent: React.FC = () => {
    const { userOptions, search, setSearch, loading, onClickRow, impersonateData, onReset } = useImpersonateOptions();
    const setOpen = useAdminUiAction('SET_IMPERSONATE_OPEN');
    const open = useAdminUiSelector(s => s.activeSpotlight === 'impersonate');
    const impersonatingUserName = React.useMemo(() => {
        return !impersonateData ? undefined : userOptions.find(u => u.value === impersonateData.organization_id)?.label;
    }, [impersonateData, userOptions]);
    return (
        <SpotlightSearch<ImpersonateOpt>
            onSelectOption={(value: string) => onClickRow(value)}
            open={open}
            placeholder={'Impersonate a lab or practice'}
            loading={loading}
            onClose={() => setOpen(false)}
            setSearch={setSearch}
            search={search}
            options={userOptions}
            renderOption={ImpersonateAutocompleteItem}
            hotkey={'impersonate'}
            AutocompleteOverride={
                !impersonateData ? undefined : (
                    <ImpersonatePreviewBox
                        userName={impersonatingUserName ?? ''}
                        onReset={onReset}
                        impersonateLink={impersonateData?.practiceUrl ?? ''}
                        impersonateLabPortalLink={impersonateData?.labPortalUrl}
                    />
                )
            }
        />
    );
};

export const GlobalImpersonate: React.FC = () => {
    const open = useAdminUiSelector(s => s.activeSpotlight === 'impersonate');
    return open ? <GlobalImpersonateContent /> : null;
};

const NavContainer: React.FC = props => {
    const canImpersonateUsers = useHasCapability('user', 'user.impersonate');

    if (!canImpersonateUsers) {
        return null;
    }

    return (
        <Tooltip
            title={<span style={{ fontSize: 16 }}>{HotkeysManager.tooltipText('impersonate')}</span>}
            placement={'right'}
            arrow
        >
            <div>{props.children}</div>
        </Tooltip>
    );
};

export function useGlobalImpersonateNavItem(): NavigationEntryBottom {
    const setOpen = useAdminUiAction('SET_IMPERSONATE_OPEN');
    const open = useAdminUiSelector(s => s.activeSpotlight === 'impersonate');

    const canImpersonateUsers = useHasCapability('user', 'user.impersonate');

    const toggleOpen = React.useCallback(() => {
        if (!canImpersonateUsers) {
            return;
        }

        setOpen(!open);
    }, [open, setOpen, canImpersonateUsers]);
    useHotkeys(HotkeysManager.keyCombo('impersonate'), toggleOpen, [toggleOpen]);
    return {
        title: 'Impersonate',
        active: open,
        Icon: props => <Icon icon={'SupervisorAccount'} {...props} />,
        onClick: () => setOpen(true),
        Container: NavContainer,
    };
}
