import { AddOrderItemV2Button } from '../ManageOrderItemsV2/AddOrderItemV2Button.graphql';
import { DeleteOrderItemV2Button } from '../ManageOrderItemsV2/DeleteOrderItemV2Button.graphql';
import { EditOrderItemV2Button } from '../ManageOrderItemsV2/EditOrderItemV2Button.graphql';
import { ManageSplitsDialog, ManageSplitsProvider, useSplitsManager } from '../ManageSplits/ManageSplitsDialog';
import type { AdminOrderDetailItemsLabSalesOrder_FragmentFragment, FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { isArrayMin1 } from '@orthly/runtime-utils';
import { useHasCapability } from '@orthly/session-client';
import { Button } from '@orthly/ui-primitives';
import type { OrderDetailItemsTableItemActionProps } from '@orthly/veneer';
import {
    getOrderItemsFromFragment,
    OrderDetailItemsAndPreferencesSection,
    OrderEditActionButton,
    OrderEditActionDialog,
} from '@orthly/veneer';
import React from 'react';

const AdminOrderDetailItemsLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailItemsLabSalesOrder_Fragment on LabSalesOrderDTO {
        id
        doctor_preferences_id
        items_v2 {
            ...OrderItemV2DTO
        }
        items_full_display_info_with_common {
            ...VeneerOrderDetailItemsAndPreferencesLabSalesOrderItemFullDisplayInfoWithCommon_Fragment
        }
        action_eligibility {
            can_add_items
            can_delete_items
            can_update_items
        }
    }
`);

interface OrderDetailItemsItemActionProps extends OrderDetailItemsTableItemActionProps {
    salesOrder: AdminOrderDetailItemsLabSalesOrder_FragmentFragment;
    refetch: () => Promise<unknown>;
}

const OrderDetailItemsItemAction: React.VFC<OrderDetailItemsItemActionProps> = ({ salesOrder, refetch, items }) => {
    if (!isArrayMin1(items)) {
        return null;
    }

    return (
        <>
            {salesOrder.action_eligibility.can_update_items && (
                <EditOrderItemV2Button
                    orderId={salesOrder.id}
                    doctorPreferencesId={salesOrder.doctor_preferences_id}
                    itemOptions={items}
                    allItems={getOrderItemsFromFragment(salesOrder.items_v2)}
                    refetch={refetch}
                />
            )}
            {salesOrder.action_eligibility.can_delete_items && (
                <DeleteOrderItemV2Button orderId={salesOrder.id} items={items} refetch={refetch} />
            )}
        </>
    );
};

// wrapper to inject the sales order into the action component
const useOrderDetailItemsItemAction = (
    salesOrder: AdminOrderDetailItemsLabSalesOrder_FragmentFragment,
    refetch: () => Promise<unknown>,
): React.ComponentType<OrderDetailItemsTableItemActionProps> => {
    return React.useCallback(
        (props: OrderDetailItemsTableItemActionProps) => (
            <OrderDetailItemsItemAction salesOrder={salesOrder} refetch={refetch} {...props} />
        ),
        [salesOrder, refetch],
    );
};

interface OrderDetailItemsContentProps {
    salesOrder: FragmentType<typeof AdminOrderDetailItemsLabSalesOrder_Fragment>;
    refetch: () => Promise<unknown>;
    /** @deprecated Remove once order edit is moved to a dedicated page or change when updated to only depend on id. */
    orderEditAction: React.ReactNode;
    manageSplitsAction: React.ReactNode;
}

/**
 * Temporary component that does not rely on lab order directly.
 * Helps control what belongs to lab order and what does not and faciliate our transition off.
 *
 * Therefore, we inject all actions that rely on the lab order as a temporary stopgap
 * with a more sustainable design intended for the future.
 */
const OrderDetailItemsContent: React.VFC<OrderDetailItemsContentProps> = ({
    salesOrder: salesOrderProp,
    refetch,
    orderEditAction,
    manageSplitsAction,
}) => {
    const salesOrder = getFragmentData(AdminOrderDetailItemsLabSalesOrder_Fragment, salesOrderProp);

    const ItemAction = useOrderDetailItemsItemAction(salesOrder, refetch);

    return (
        <OrderDetailItemsAndPreferencesSection
            fullDisplayInfoWithCommon={salesOrder.items_full_display_info_with_common}
            itemsBlockActions={
                <>
                    {orderEditAction}
                    {salesOrder.action_eligibility.can_add_items && (
                        <AddOrderItemV2Button
                            orderId={salesOrder.id}
                            refetchOrder={refetch}
                            doctorPreferencesId={salesOrder.doctor_preferences_id}
                        />
                    )}
                    {manageSplitsAction}
                </>
            }
            ItemAction={ItemAction}
        />
    );
};

interface OrderDetailItemsOrderEditProps {
    labOrder: LabsGqlOrder;
    openOrder: (orderId: string, e?: React.MouseEvent) => void;
}

const OrderDetailItemsOrderEdit: React.VFC<OrderDetailItemsOrderEditProps> = ({ labOrder, openOrder }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <OrderEditActionDialog
                order={labOrder}
                isInternal={true}
                openOrder={openOrder}
                isEditOpen={open}
                setEditOpen={setOpen}
            />
            <OrderEditActionButton order={labOrder} isInternal={true} setEditOpen={setOpen} buttonLabel={'Modify Rx'} />
        </>
    );
};

interface OrderDetailItemsManageSplitsActionProps {
    labOrder: LabsGqlOrder;
}

const OrderDetailItemsManageSplitsAction: React.VFC<OrderDetailItemsManageSplitsActionProps> = ({ labOrder }) => {
    const splitManagerDisallowedStatuses = [
        LabsGqlLabOrderStatus.Delivered,
        LabsGqlLabOrderStatus.Shipped,
        LabsGqlLabOrderStatus.Cancelled,
        LabsGqlLabOrderStatus.NeedsRefabrication,
    ];

    const canEditSplits = useHasCapability('order', 'order.splits.edit');
    const manageSplitsDisabled = splitManagerDisallowedStatuses.includes(labOrder.status) || !canEditSplits;

    const manageSplits = useSplitsManager(labOrder);

    if (manageSplitsDisabled) {
        return null;
    }

    return (
        <>
            <Button onClick={() => manageSplits()} variant={'ghost'} startIcon={'SplitIcon'}>
                Manage Splits
            </Button>
            <ManageSplitsDialog />
        </>
    );
};

interface OrderDetailItemsProps {
    salesOrder: FragmentType<typeof AdminOrderDetailItemsLabSalesOrder_Fragment>;
    refetch: () => Promise<unknown>;
    labOrder: LabsGqlOrder;
    openOrder: (orderId: string, e?: React.MouseEvent) => void;
}

export const OrderDetailItems: React.VFC<OrderDetailItemsProps> = ({ salesOrder, refetch, labOrder, openOrder }) => {
    return (
        <OrderDetailItemsContent
            salesOrder={salesOrder}
            refetch={refetch}
            orderEditAction={<OrderDetailItemsOrderEdit labOrder={labOrder} openOrder={openOrder} />}
            manageSplitsAction={
                <ManageSplitsProvider>
                    <OrderDetailItemsManageSplitsAction labOrder={labOrder} />
                </ManageSplitsProvider>
            }
        />
    );
};
