import './index.css';
import { App } from './screens/App/App';
import { OpsRootProvider } from './screens/App/OpsRootProvider';
import './utils/dayjs-plugins';
import '@orthly/pintura/pintura.css';
import { OrthlyBrowserConfig, OrthlyFrontendApps, setupOrthlyBrowser } from '@orthly/ui';
import '@orthly/ui/assets/fonts/BagossStandard.css';
import '@orthly/ui/assets/fonts/Inter.css';
import 'core-js/features/string/replace-all';
import React from 'react';
import ReactDOM from 'react-dom';

const config = new OrthlyBrowserConfig(OrthlyFrontendApps.admin);
setupOrthlyBrowser(config);

// Strict mode only runs in development, see docs here: https://reactjs.org/docs/strict-mode.html
ReactDOM.render(
    <React.StrictMode>
        <OpsRootProvider config={config}>
            <App />
        </OpsRootProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
if (!process.env.BUNDLED_WITHOUT_WEBPACK && (module as any).hot) {
    (module as any).hot.accept();
}

if (process.env.SERVE_ESBUILD) {
    new EventSource('/esbuild').addEventListener('change', () => location.reload());
}
