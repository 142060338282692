import type { Column, Row } from '../types';
import type { ColumnSortState, ColumnFilterState, ColumnFilterMod } from './TableStateContext';
import { createSyncAction } from '@orthly/redux-async-actions';
import type { Dispatch } from 'react';

export class TableStateActions {
    static toggleViewColumn = createSyncAction<string, [string]>('toggleViewColumn');
    static setHiddenColumns = createSyncAction<string[], [string[]]>('setHiddenColumns');
    static searchTextUpdate = createSyncAction<string | null, [string | null]>('searchTextUpdate');
    static toggleSearchVisible = createSyncAction<undefined, []>('toggleSearchVisible');
    static updateFilterValues = createSyncAction<ColumnFilterState, [string, string[], ColumnFilterMod?]>(
        'onFilterUpdate',
        (columnName, filterValues, mod: ColumnFilterMod = '=') => ({ columnName, filterValues, mod }),
    );
    static onFilterReset = createSyncAction<undefined, []>('onFilterReset');
    static toggleRowSelected = createSyncAction<Row<any>, [Row<any>]>('toggleRowSelected');
    static toggleDetailPanel = createSyncAction<Row<any>, [Row<any>]>('toggleDetailPanel');
    static handleAllSelect = createSyncAction<string[], [string[]]>('handleAllSelect');
    static toggleSort = createSyncAction<string, [string]>('toggleSort');
    static setSortColumn = createSyncAction<ColumnSortState | undefined, [ColumnSortState | undefined]>(
        'setSortColumn',
    );
    static changePage = createSyncAction<number, [number]>('changePage');
    static changeRowsPerPage = createSyncAction<number, [number]>('changeRowsPerPage');
    static onCellClick = createSyncAction<{ row: Row<any>; column: Column<any> }, [Row<any>, Column<any>]>(
        'onCellClick',
        (row, column) => ({ row, column }),
    );
    constructor(private dispatch: Dispatch<any>) {}
    private createInstanceAction =
        <K extends keyof ActionsClass = keyof ActionsClass>(action: K) =>
        (...args: Parameters<ActionsClass[K]>) => {
            const actionCreator = TableStateActions[action];
            this.dispatch(actionCreator(...(args as never[])) as any);
        };
    toggleViewColumn = this.createInstanceAction('toggleViewColumn');
    setHiddenColumns = this.createInstanceAction('setHiddenColumns');
    searchTextUpdate = this.createInstanceAction('searchTextUpdate');
    toggleSearchVisible = this.createInstanceAction('toggleSearchVisible');
    updateFilterValues = this.createInstanceAction('updateFilterValues');
    onFilterReset = this.createInstanceAction('onFilterReset');
    toggleRowSelected = this.createInstanceAction('toggleRowSelected');
    toggleDetailPanel = this.createInstanceAction('toggleDetailPanel');
    handleAllSelect = this.createInstanceAction('handleAllSelect');
    changePage = this.createInstanceAction('changePage');
    changeRowsPerPage = this.createInstanceAction('changeRowsPerPage');
    onCellClick = this.createInstanceAction('onCellClick');
}

export type ActionsClass = Omit<typeof TableStateActions, 'prototype'>;
