import { useAutomationBuilderAction } from '../../../state/AutomationBuilder.actions';
import { useAutomationBuilderSelector } from '../../../state/AutomationBuilder.context';
import type { ActionConfigValue } from '../../../state/AutomationBuilder.types';
import { AutomationStepContainer } from '../components/AutomationStepContainer';
import { AutomationActionConfigField } from './AutomationActionConfigField';
import type { FetchResult } from '@apollo/client';
import { useSearchAutocomplete } from '@orthly/dentin';
import type {
    LabsGqlAutomationActionFragment,
    LabsGqlTestOrderAutomationMutation,
    LabsGqlTestOrderAutomationResultFragment,
} from '@orthly/graphql-operations';
import {
    useListAutomationActionsQuery,
    useTestOrderAutomationMutation,
    useOrderSearchResults,
} from '@orthly/graphql-react';
import type { LabsGqlAutomationFieldSubmissionInput } from '@orthly/graphql-schema';
import { SimpleMenu, RootActionDialog, useChangeSubmissionFn, SimpleAutocomplete } from '@orthly/ui';
import { FlossPalette, Button, Grid, IconButton, Tooltip, Text, CancelIcon, Icon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const AutomationActionTester: React.FC<AutomationActionNodeProps> = props => {
    type Result = FetchResult<LabsGqlTestOrderAutomationMutation>;
    const { action } = props;
    const [selectedOrderId, setSelectedOrderId] = React.useState<string | undefined>(undefined);
    const { search, loading, options, onInputChange, onSelectOption, onBlurOrFocus, noOptionsText } =
        useSearchAutocomplete(setSelectedOrderId, useOrderSearchResults);
    const [submitMtn] = useTestOrderAutomationMutation();
    const completedActionFields = React.useMemo(() => {
        return _.compact(
            action.fields.map<LabsGqlAutomationFieldSubmissionInput | undefined>(f =>
                f.value === undefined || f.value === null ? undefined : { value: f.value, field_id: f.id },
            ),
        );
    }, [action.fields]);
    const mtnSubmitter = async () => {
        if (!selectedOrderId) {
            return null;
        }
        return submitMtn({
            variables: { orderId: selectedOrderId, config: { fields: completedActionFields, action_id: action.id } },
        });
    };
    const [result, setResult] = React.useState<LabsGqlTestOrderAutomationResultFragment | null>(null);
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<Result | null, []>(mtnSubmitter, {
        successMessage: () => ['Automation test completed!', {}],
        onSuccess: async res => {
            console.log(res);
            setResult(res?.data?.testOrderAutomation ?? null);
        },
    });

    if (!action.test_available) {
        return null;
    }
    return (
        <RootActionDialog
            showCloseButton
            title={`Test ${action.name} action`}
            setOpen={openNew => {
                if (!openNew) {
                    setResult(null);
                    setSelectedOrderId(undefined);
                }
                setOpen(openNew);
            }}
            open={open}
            loading={submitting}
            buttonText={'Test action'}
            buttonProps={{ disabled: action.fields.filter(f => !!f.error).length > 0, style: { width: 'auto' } }}
            content={
                <Grid container>
                    <Grid container>
                        <SimpleAutocomplete
                            label={'Select test order'}
                            options={options}
                            variant={'standard'}
                            initialInputValue={search ?? null}
                            onInputChange={onInputChange}
                            onChange={value => {
                                onSelectOption(value);
                                !value && setSelectedOrderId(undefined);
                            }}
                            AutocompleteProps={{ loading, noOptionsText, style: { width: '100%' } }}
                            TextFieldProps={{ onBlur: onBlurOrFocus, onFocus: onBlurOrFocus }}
                        />
                    </Grid>
                    <Grid container justifyContent={'flex-end'} style={{ paddingTop: 8 }}>
                        <Tooltip
                            title={!selectedOrderId ? 'Select an order to test' : ''}
                            disableHoverListener={!!selectedOrderId}
                        >
                            <Grid container style={{ width: 'auto' }}>
                                <Button
                                    variant={'contained'}
                                    disabled={submitting || !selectedOrderId}
                                    onClick={() => submit()}
                                >
                                    Send test
                                </Button>
                            </Grid>
                        </Tooltip>
                    </Grid>
                    {!!result && (
                        <Grid container wrap={'nowrap'}>
                            <Grid container style={{ width: 'auto', paddingRight: 8 }}>
                                {result.success ? (
                                    <Icon icon={'CheckCircleIcon'} style={{ color: FlossPalette.STAR_GRASS }} />
                                ) : (
                                    <Icon icon={'CancelOutlined'} style={{ color: FlossPalette.ATTENTION }} />
                                )}
                            </Grid>
                            <Grid container>
                                <Text>{result.summary}</Text>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            }
        />
    );
};

interface AutomationActionNodeProps {
    action: ActionConfigValue;
}

const AutomationActionNode: React.FC<AutomationActionNodeProps> = props => {
    const { action } = props;
    const deleteAction = useAutomationBuilderAction('DELETE_ACTION');
    return (
        <AutomationStepContainer
            disableDefaultExpanded={action.fields.length === 0}
            startIcon={
                <Grid container style={{ width: 'auto', padding: 12, paddingLeft: 0 }}>
                    <Tooltip title={'Remove'}>
                        <IconButton
                            size={'small'}
                            aria-label={'remove-action'}
                            onClick={e => {
                                e.stopPropagation();
                                deleteAction({ id: action.id });
                            }}
                        >
                            <CancelIcon color={'error'} />
                        </IconButton>
                    </Tooltip>
                </Grid>
            }
            title={`Action: ${action.name}`}
            subtitle={action.description ?? undefined}
        >
            <Grid container>
                {action.fields.length === 0 && (
                    <Grid container>
                        <Text variant={'h6'} style={{ fontSize: 16 }}>
                            No Configuration Needed
                        </Text>
                    </Grid>
                )}
                {action.fields.map(f => (
                    <AutomationActionConfigField key={f.id} field={f} action={action} />
                ))}
            </Grid>
            <Grid container justifyContent={'flex-end'}>
                <AutomationActionTester action={action} />
            </Grid>
        </AutomationStepContainer>
    );
};

interface AddActionButtonProps {
    availableActions: LabsGqlAutomationActionFragment[];
}

const AddActionButton: React.FC<AddActionButtonProps> = props => {
    const { availableActions } = props;
    const addAction = useAutomationBuilderAction('ADD_ACTION');
    return (
        <Grid container justifyContent={'center'} style={{ paddingBottom: 24 }}>
            <SimpleMenu
                ButtonComponent={({ onClick }) => (
                    <Button onClick={onClick} variant={'contained'} startIcon={'PlusIcon'}>
                        Add action
                    </Button>
                )}
                items={availableActions.map(action => ({
                    onClick: setClosed => {
                        addAction(action);
                        setClosed();
                    },
                    label: action.name,
                }))}
            />
        </Grid>
    );
};

export const AutomationActionsStep: React.FC = () => {
    const query = useListAutomationActionsQuery();
    const actions = useAutomationBuilderSelector(s => s.form.actions);
    const availableActions = React.useMemo(() => {
        if (!query.data) {
            return [];
        }
        const usedActionIds = actions.map(a => a.id);
        return query.data.actions.filter(a => !usedActionIds.includes(a.id));
    }, [actions, query.data]);
    return (
        <Grid container>
            {actions.map(a => (
                <AutomationActionNode action={a} key={a.id} />
            ))}
            {availableActions.length > 0 && <AddActionButton availableActions={availableActions} />}
        </Grid>
    );
};
