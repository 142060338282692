import { FlossPalette, styled, Text, Box, Grid, DialogContent, ChevronRight } from '@orthly/ui-primitives';

export const StyledTitle = styled(Text)({
    marginBottom: 24,
});

export const StyledSubTitle = styled(Text)({
    marginBottom: 24,
});

export const StyledBoxButton = styled(Box)({
    backgroundColor: FlossPalette.TAN,
    width: 232,
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    borderRadius: 10,
    padding: 16,
    cursor: 'pointer',
    '&:hover': {
        background: FlossPalette.GREEN,
        color: FlossPalette.WHITE,
    },
    '&:not(:first-child)': {
        marginLeft: 20,
    },
});

export const StyledButtonsContainer = styled(Grid)({
    marginBottom: 24,
});

export const StyledDialogContentBox = styled(DialogContent)({
    padding: 40,
});

export const StyledButtonTitleRow = styled(Grid)({
    justifyContent: 'space-between',
});

export const StyledButtonTitleIcon = styled(ChevronRight)({
    color: FlossPalette.PRIMARY_FOREGROUND,
});
