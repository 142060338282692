import { TOOLBAR_DESKTOP_HEIGHT } from '@orthly/ui';
import { styled, FlossPalette } from '@orthly/ui-primitives';

export const DesignTrainingHeaderContainer = styled('div')({
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    height: TOOLBAR_DESKTOP_HEIGHT,
    paddingLeft: 24,
    borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
    gap: 16,
});
