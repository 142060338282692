import bl_embrasure_BAD from '../../../../assets/images/design-qc/emergence_profile/bl_embrasure_BAD.jpg';
import bl_embrasure_GOOD from '../../../../assets/images/design-qc/emergence_profile/bl_embrasure_GOOD.jpg';
import emergence_profile_emergence_profile_ACCEPTABLE from '../../../../assets/images/design-qc/emergence_profile/emergence_profile_emergence_profile_ACCEPTABLE.png';
import emergence_profile_emergence_profile_BAD from '../../../../assets/images/design-qc/emergence_profile/emergence_profile_emergence_profile_BAD.png';
import emergence_profile_emergence_profile_GOOD from '../../../../assets/images/design-qc/emergence_profile/emergence_profile_emergence_profile_GOOD.png';
import emergence_profile_occlusal_embrasure_ACCEPTABLE from '../../../../assets/images/design-qc/emergence_profile/emergence_profile_occlusal_embrasure_ACCEPTABLE.png';
import emergence_profile_occlusal_embrasure_BAD from '../../../../assets/images/design-qc/emergence_profile/emergence_profile_occlusal_embrasure_BAD.png';
import emergence_profile_occlusal_embrasure_GOOD from '../../../../assets/images/design-qc/emergence_profile/emergence_profile_occlusal_embrasure_GOOD.png';
import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { DEFAULT_SCAN_APPEARANCE, DEFAULT_RESTORATIVE_APPEARANCE } from '@orthly/dentin';

export const abutmentEmergenceProfileAndEmbrasuresRubric: DesignQcRubricCategoryEntry = {
    category: 'abutment_emergence_profile_and_embrasures',
    subcategories: [
        {
            category: 'abutment_emergence_profile_and_embrasures',
            name: 'Facial / Lingual Emergence Profile',
            grading: {
                type: 'tertiary',
                bad: 'Straight or concave emergence',
                acceptable: '"Know it when you see it"<br>OR<br>Escalate to Senior',
                ideal: 'EP of restoration matches adjacent natural EP',
            },
            examples: {
                bad: [emergence_profile_emergence_profile_BAD],
                acceptable: [emergence_profile_emergence_profile_ACCEPTABLE],
                ideal: [emergence_profile_emergence_profile_GOOD],
            },
            description:
                'Slightly convex emergence from margin line to end of cervical 1/3rd<br><br>Do not impinge tissue beyond 0.1mm<br><br>Adjacent natural EP defines ideal EP contours for restoration<br><br>"You know it when you see it"',
            selectable_bad_reasons: ['[SURFACE] emergence profile does not appropriately match adjacent'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasBridge,
        },
        {
            category: 'abutment_emergence_profile_and_embrasures',
            name: 'M/D Gingival Embrasures',
            grading: {
                type: 'tertiary',
                bad: 'Straight or concave emergence',
                acceptable: '"Know it when you see it"<br>OR<br>Escalate to Senior',
                ideal: 'EP of restoration matches adjacent natural EP',
            },
            description:
                'Slighly convex contour from margin line to bottom of IP contact<br><br>Should never be straight or concave.<br><br>Do not impinge tissue beyond 0.15mm.<br><br>Adjacent natural EP defines ideal EP contours for restoration - Do not copy unnatural areas (eg: filings)<br><br>"You know it when you see it"',
            edge_case: 'Margin line position requires convex design<br>Tissue impingement',
            selectable_bad_reasons: ['[SURFACE] emergence profile does not appropriately match adjacent'],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasBridge,
        },
        {
            category: 'abutment_emergence_profile_and_embrasures',
            name: 'B/L Embrasures',
            grading: {
                type: 'tertiary',
                bad: 'Straight or concave embrasure',
                acceptable: '"Know it when you see it"<br>OR<br>Escalate to Senior',
                ideal: 'EP of restoration matches adjacent natural EP',
            },
            examples: {
                bad: [bl_embrasure_BAD],
                acceptable: [bl_embrasure_GOOD],
                ideal: [bl_embrasure_GOOD],
            },
            description:
                'Contour from prominence to IP contact surface<br><br>V-Shaped with adjacent teeth have rounded contacts with no wrapping of the contact. With flat prepped adjacent contact the embrasure should have a smaller v shape after making contact as broad as possible.<br><br>Adjacent natural embrasure defines ideal contours for embrasures of the restoration - Do not copy rotated dentition<br><br>"You know it when you see it"',
            edge_case: 'Wrapping is ok if chase ortho rotation (need to define by how much)',
            selectable_bad_reasons: ['[SURFACE] emergence profile does not appropriately match adjacent'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasBridge,
        },
        {
            category: 'abutment_emergence_profile_and_embrasures',
            name: 'Occlusal Embrasures',
            grading: {
                type: 'tertiary',
                bad: 'Straight or concave embrasure',
                acceptable: '"Know it when you see it"<br>OR<br>Escalate to Senior',
                ideal: 'Embrasures of restoration matches adjacent natural embrasures',
            },
            examples: {
                bad: [emergence_profile_occlusal_embrasure_BAD],
                acceptable: [emergence_profile_occlusal_embrasure_ACCEPTABLE],
                ideal: [emergence_profile_occlusal_embrasure_GOOD],
            },
            description:
                'Contour from internal edge of the occlusal table to IP contact<br><br>V-shaped with rounded marginal ridges<br><br>Adjacent natural embrasure defines ideal contours for embrasures of the restoration - Do not copy rotated dentition and/or unnatural areas<br><br>"You know it when you see it"',
            selectable_bad_reasons: ['[SURFACE] emergence profile does not appropriately match adjacent'],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasBridge,
        },
    ],
};
