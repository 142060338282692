import type { IBaseItemData } from '../../types';
import { getPartialsPrefValues } from '../../utils/partialsItem.util';
import { getHeaderForLabSlipItem } from '../../utils/shared.utils';
import { BaseItem } from './BaseItem';
import { PrintableSlipItemDetail } from './PrintableSlipItemDetail';
import { DandyMouthDisplay } from '@orthly/dentin';
import type { IPartialDentureItem } from '@orthly/items';
import { ToothUtils } from '@orthly/items';
import { FlossPalette } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

/**
 * Renders a "Partial Denture" order item to display on
 * the Printable Slip
 * @param props The order item to render
 * @returns Rendered order item details for the order "Partial Denture" item
 */
export const PartialsItem: React.VFC<{ item: IPartialDentureItem }> = ({ item }) => {
    return <BaseItem itemData={mapPartialsItemToPrintableSlipData(item)} />;
};

/**
 * Pull data needed for printable slip display into a standard data structure
 * @param item IPartialDentureItem order item to display
 * @returns Structured data for Partials item display
 */
function mapPartialsItemToPrintableSlipData(item: IPartialDentureItem): IBaseItemData {
    const prefValues = getPartialsPrefValues(item);

    const manufacturing = prefValues.mfgStandard ?? prefValues.mfgAcrylic;
    const material = prefValues.metalMaterial ?? prefValues.acrylicMaterial ?? item.unit.material;
    const tissueShade = prefValues.metalTissueShade ?? prefValues.acrylicTissueShade;
    const baseShade = item.shades.find(shade => shade.name === 'base');

    const digitalExtraction = _.compact(
        prefValues.digitalExtraction?.toLowerCase()?.split(',')?.map(ToothUtils.toToothNumber),
    );
    return {
        allowPageBreaks: true,
        headerLeft: getHeaderForLabSlipItem(item),
        itemFields: _.compact([
            { key: 'Material', value: material },
            { key: 'Manufacturing (F/A)', value: manufacturing },
            // temporary until we no longer have items that set "All Missing"
            // TODO: clean up when all in flight orders are completed
            {
                key: 'Teeth Replaced',
                value:
                    // eslint-disable-next-line no-nested-ternary
                    prefValues.teethSelection === 'All Missing'
                        ? 'All Missing'
                        : item.unit.unns.length
                          ? item.unit.unns.join(',')
                          : 'Unknown',
            },
            {
                customComponent: (
                    <PrintableSlipItemDetail
                        title={'' /* This is being used to keep the same spacing, so the title is just empty */}
                    >
                        <DandyMouthDisplay
                            items={item.unit.unns.map(unn => ({
                                number: unn,
                                highlighted: true,
                                activeColor: FlossPalette.BLACK,
                            }))}
                            bridges={[]}
                            animate={false}
                            verticalLayout={false}
                        />
                    </PrintableSlipItemDetail>
                ),
            },

            digitalExtraction.length === 0
                ? { key: 'Digital Extraction', value: 'no' }
                : {
                      customComponent: (
                          <PrintableSlipItemDetail title={'Digital Extraction'}>
                              <DandyMouthDisplay
                                  items={digitalExtraction.map(unn => ({
                                      number: unn,
                                      highlighted: true,
                                      activeColor: FlossPalette.BLACK,
                                  }))}
                                  bridges={[]}
                                  animate={false}
                                  verticalLayout={false}
                              />
                          </PrintableSlipItemDetail>
                      ),
                  },

            { key: 'Coverage Type', value: prefValues.coverage },
            { key: 'Tooth Shade', value: baseShade?.value },
            { key: 'Tissue Shade', value: tissueShade },

            !!prefValues.claspType && {
                key: 'Clasp Type',
                value: prefValues.claspType,
            },
            !!prefValues.claspColor && {
                key: 'Clasp Color',
                value: prefValues.claspColor,
            },
            !!prefValues.claspLocations && {
                key: 'Clasp Locations',
                value: prefValues.claspLocations,
            },
            !!prefValues.acetalClaspShade && {
                key: 'Acetal Clasp Shade',
                value: prefValues.acetalClaspShade,
            },
        ]),
    };
}
