import {
    MarginEditingPalette,
    lineShaderMaterial,
    type LineType,
    createPreviewLine,
    type IndexedSphere,
    updateCircle,
    getMarginTessellatedPoints,
    updateLine,
    updateIndexedSpheres,
    drawLine,
    findRightIndexForANewPoint,
} from '../..//ModelViewer/Margin.util';
import { updateRaycaster } from '../../ModelViewer';
import { drawCircle } from '../../ModelViewer/utils3d/interaction.util';
import type { ScanReviewEditedMarginLine } from './ScanReviewMarginMarkingToolTypes';
import { ScanReviewMarginLineConnection } from './ScanReviewMarginMarkingToolTypes';
import _ from 'lodash';
import * as THREE from 'three';
import type { MeshBVH, HitPointInfo } from 'three-mesh-bvh';

const TESSELLATION_RESOLUTION = 200;

export class ScanReviewMarginMarkingToolLiveObjectsProvider {
    readonly spheres: IndexedSphere[] = [];
    readonly group: THREE.Group;

    private readonly rayCaster: THREE.Raycaster = new THREE.Raycaster();
    private readonly sphereGeometry = new THREE.SphereBufferGeometry(0.125, 32, 32);
    private readonly sphereMaterial = new THREE.MeshBasicMaterial({ color: MarginEditingPalette.CONTROL_POINT_COLOR });
    private readonly activeMaterial = new THREE.MeshBasicMaterial({
        color: MarginEditingPalette.CONTROL_POINT_COLOR_ACTIVE,
    });
    private readonly line: THREE.Line;
    private readonly lineMaterial = lineShaderMaterial({
        color: new THREE.Color(MarginEditingPalette.EDIT_LINE_COLOR),
        xray: false,
    });
    private readonly effectCircle: THREE.Line = drawCircle({ lineWidth: 8 });
    private readonly previewCircle: THREE.Line = drawCircle({ color: MarginEditingPalette.CURSOR_CIRCLE_COLOR });
    private readonly previewLine: LineType = createPreviewLine();

    private _activeHighlight: IndexedSphere | undefined;
    private _marginLineIntersection: THREE.Vector3 | undefined;
    private _geometryIntersection: THREE.Vector3 | undefined;

    constructor(
        private readonly indexBVH: MeshBVH,
        private readonly editor: ScanReviewMarginLineEditor,
    ) {
        this.group = new THREE.Group();
        this.group.renderOrder = 1000;
        this.group.frustumCulled = false;
        this.previewLine.frustumCulled = false;

        this.group.add(this.effectCircle);
        this.group.add(this.previewCircle);
        this.group.add(this.previewLine);

        const lineTesselationPoints = this.getRefinedPoints(
            this.editor.currentEditedMarginLine.controlPoints,
            this.editor.currentEditedMarginLine.marginClosed,
        );
        this.line = drawLine(lineTesselationPoints, this.lineMaterial);
        this.line.frustumCulled = false;
        this.group.add(this.line);
    }

    get activeHighlight(): IndexedSphere | undefined {
        return this._activeHighlight;
    }

    get marginLineIntersection(): THREE.Vector3 | undefined {
        return this._marginLineIntersection;
    }

    get geometryIntersection(): THREE.Vector3 | undefined {
        return this._geometryIntersection;
    }

    update(canvas: HTMLCanvasElement, camera: THREE.Camera, evt: MouseEvent) {
        this._activeHighlight = undefined;
        this._marginLineIntersection = undefined;
        this._geometryIntersection = undefined;

        updateRaycaster(this.rayCaster, canvas, camera, evt);
        this.pickActiveHighlight();
        this.pickMarginLine();
        this.pickGeometry();
    }

    private pickActiveHighlight() {
        const sphereIntersects = this.rayCaster.intersectObjects(this.spheres, false);
        this._activeHighlight = sphereIntersects[0]?.object as IndexedSphere | undefined;
    }

    private pickMarginLine() {
        if (!this.rayCaster.params || !this.rayCaster.params.Line) {
            return;
        }
        this.rayCaster.params.Line.threshold = 0.1;
        const lineIntersects = this.rayCaster.intersectObject(this.line, false);
        if (lineIntersects.length > 0) {
            const point = lineIntersects[0]?.point;
            this._marginLineIntersection = point;
        }
    }

    private pickGeometry() {
        this._geometryIntersection = this.indexBVH.raycastFirst(this.rayCaster.ray, THREE.FrontSide)?.point;
    }

    setMarginLineVisibility(visible: boolean) {
        this.line.visible = visible;
    }

    setPreviewLineVisibility(visible: boolean) {
        this.previewLine.visible = visible;
    }

    setEffectCircleVisibility(visible: boolean) {
        this.effectCircle.visible = visible;
    }

    setPreviewCircleVisibility(visible: boolean) {
        this.previewCircle.visible = visible;
    }

    getRefinedPoints(controlPoints: THREE.Vector3[], marginClosed: boolean): THREE.Vector3[] {
        const lineTesselationResult = getMarginTessellatedPoints(
            controlPoints,
            marginClosed,
            this.indexBVH,
            TESSELLATION_RESOLUTION,
        );
        return lineTesselationResult.points;
    }

    snapToGeometry(position: THREE.Vector3) {
        const target = {} as HitPointInfo;
        this.indexBVH.closestPointToPoint(position, target, 0, 3);
        return target.point;
    }

    updateActiveHighlightPreviewCircle(position: THREE.Vector3, cameraRotation: THREE.Euler) {
        updateCircle(this.previewCircle, {
            pos: position,
            rot: cameraRotation,
            effectDistance: 0.5,
        });
    }

    updatePreviewCircle(position: THREE.Vector3, cameraRotation: THREE.Euler) {
        updateCircle(this.previewCircle, {
            pos: position,
            rot: cameraRotation,
            effectDistance: 0.75,
        });
    }

    updateEffectCircle(effectDistance: number, position?: THREE.Vector3, cameraRotation?: THREE.Euler) {
        updateCircle(this.effectCircle, {
            effectDistance: effectDistance,
            pos: position,
            rot: cameraRotation,
        });
    }

    updateInsertMarginPointPreviewCircle(position: THREE.Vector3, cameraRotation: THREE.Euler) {
        updateCircle(this.previewCircle, {
            pos: position,
            rot: cameraRotation,
            effectDistance: 0.4,
        });
    }

    updatePreviewLine(position: THREE.Vector3, drawFromEnd: boolean): void {
        // get the last 3 or first 3 control points
        const previewControlPoints = drawFromEnd
            ? this.editor.currentEditedMarginLine.controlPoints.slice(-3)
            : this.editor.currentEditedMarginLine.controlPoints.slice(0, 3);

        if (drawFromEnd) {
            // add to end
            previewControlPoints.push(position);
        } else {
            // add to beginning
            previewControlPoints.unshift(position);
        }
        // now that we have 4 nodes, update the catmulRom curve
        const tessResult = this.getRefinedPoints(
            previewControlPoints,
            this.editor.currentEditedMarginLine.marginClosed,
        );
        updateLine(this.previewLine, tessResult, true);
    }

    updateMarginLine(editedMarginLine: ScanReviewEditedMarginLine): void {
        const tesselationResult = this.getRefinedPoints(editedMarginLine.controlPoints, editedMarginLine.marginClosed);
        updateLine(this.line, tesselationResult);
        updateIndexedSpheres(
            this.spheres,
            editedMarginLine.controlPoints,
            this.sphereGeometry,
            this.sphereMaterial,
            this.group,
        );
        this.line.geometry.computeBoundingSphere();
    }

    updateCompletionLine(): void {
        const controlPoints = this.editor.currentEditedMarginLine.controlPoints;
        const totalPoints = controlPoints.length;
        const previewControlPoints = _.compact([
            controlPoints[totalPoints - 2],
            controlPoints[totalPoints - 1],
            controlPoints[0],
            controlPoints[1],
        ]);
        const tessResult = this.getRefinedPoints(previewControlPoints, false);
        updateLine(this.previewLine, tessResult, true);
    }

    updateActiveHighlight(): void {
        for (const sphere of this.spheres) {
            sphere.material = this.sphereMaterial;
        }
        if (this._activeHighlight) {
            this._activeHighlight.material = this.activeMaterial;
        }
    }
}

export const MARGIN_LINE_CONNECTION_EPSILON = 3.5;
export const MARGIN_LINE_CLOSE_EPSILON = 5.0;
export const MARGIN_LINE_CONTROL_POINT_EPSILON = 0.0001;

export class ScanReviewMarginLineEditor {
    private readonly falloffMap: Map<number, number> = new Map<number, number>();
    private history: ScanReviewEditedMarginLine[];

    constructor(marginLine: ScanReviewEditedMarginLine) {
        this.history = [marginLine];
    }

    get currentEditedMarginLine(): ScanReviewEditedMarginLine {
        const marginLine = this.history.at(-1);
        if (!marginLine) {
            throw new Error('History stack was empty');
        }
        return marginLine;
    }

    get historyDepth() {
        return this.history.length;
    }

    getFalloffValue(index: number) {
        return this.falloffMap.get(index);
    }

    addControlPoint(position: THREE.Vector3) {
        if (this.findSimilarControlPoint(position)) {
            return;
        }

        const bestConnection = this.findBestConnection(position);
        if (bestConnection === ScanReviewMarginLineConnection.None) {
            return;
        }

        this.pushHistory();
        if (bestConnection === ScanReviewMarginLineConnection.Start) {
            this.currentEditedMarginLine.controlPoints.unshift(position);
        } else {
            this.currentEditedMarginLine.controlPoints.push(position);
        }
        return this.currentEditedMarginLine;
    }

    private findSimilarControlPoint(position: THREE.Vector3) {
        return this.currentEditedMarginLine.controlPoints.filter(
            cp => cp.distanceTo(position) < MARGIN_LINE_CONTROL_POINT_EPSILON,
        )[0];
    }

    insertControlPoint(insertionPoint: THREE.Vector3) {
        if (this.currentEditedMarginLine.controlPoints.length < 2) {
            return;
        }

        const correctIndex =
            this.currentEditedMarginLine.controlPoints.length === 2
                ? 1
                : findRightIndexForANewPoint(this.currentEditedMarginLine.controlPoints, insertionPoint);

        this.pushHistory();
        this.currentEditedMarginLine.controlPoints.splice(correctIndex, 0, insertionPoint);
        return this.currentEditedMarginLine;
    }

    deleteControlPoint(index: number) {
        this.pushHistory();
        this.currentEditedMarginLine.controlPoints.splice(index, 1);
        return this.currentEditedMarginLine;
    }

    closeLine() {
        if (!this.canCloseLine()) {
            return;
        }
        this.pushHistory();
        this.currentEditedMarginLine.marginClosed = true;
        return this.currentEditedMarginLine;
    }

    canCloseLine() {
        if (this.currentEditedMarginLine.marginClosed || this.currentEditedMarginLine.controlPoints.length < 4) {
            return false;
        }
        const p0 = this.currentEditedMarginLine.controlPoints[0];
        const pn = this.currentEditedMarginLine.controlPoints.at(-1);
        if (!pn || !p0) {
            return false;
        }
        return p0.distanceTo(pn) <= MARGIN_LINE_CLOSE_EPSILON;
    }

    findBestConnection(pos: THREE.Vector3): ScanReviewMarginLineConnection {
        if (this.currentEditedMarginLine.marginClosed) {
            return ScanReviewMarginLineConnection.None;
        }

        if (this.currentEditedMarginLine.controlPoints.length < 2) {
            return ScanReviewMarginLineConnection.Start;
        }

        const distanceToStart = this.currentEditedMarginLine.controlPoints[0]?.distanceTo(pos) ?? Infinity;
        const distanceToEnd = this.currentEditedMarginLine.controlPoints.at(-1)?.distanceTo(pos) ?? Infinity;

        if (distanceToStart < distanceToEnd && distanceToStart < MARGIN_LINE_CONNECTION_EPSILON) {
            return ScanReviewMarginLineConnection.Start;
        }

        if (distanceToEnd < distanceToStart && distanceToEnd < MARGIN_LINE_CONNECTION_EPSILON) {
            return ScanReviewMarginLineConnection.End;
        }

        return ScanReviewMarginLineConnection.None;
    }

    *affectedNeighbors() {
        for (const [neighborIndex, fallOffValue] of this.falloffMap) {
            yield { neighborIndex, fallOffValue };
        }
    }

    updateFalloffMap(selectedPoint: number, numberOfNeighbors: number, effectDistance: number) {
        const currentMarginLine = this.history.at(-1);
        if (!currentMarginLine) {
            return;
        }

        const { controlPoints, marginClosed } = currentMarginLine;
        const selectedPosition = controlPoints[selectedPoint];
        if (!selectedPosition) {
            return;
        }

        this.falloffMap.clear();
        const directions: number[] = [1, -1];
        // go forward and backward
        directions.forEach(direction => {
            // iterate to a maximum of numberOfNeighbors away from the selectedSpehre
            for (let i = 1; i < numberOfNeighbors; i++) {
                const rawIndex = selectedPoint + direction * i;

                if ((rawIndex >= controlPoints.length || rawIndex < 0) && !marginClosed) {
                    // don't wrap around if the loop is not closed
                    break;
                }
                // annoyed that -1 % 90 does not give 89
                const neighborIndex = rawIndex > 0 ? rawIndex % controlPoints.length : controlPoints.length + rawIndex;

                const neighborPoint = controlPoints[neighborIndex];
                if (neighborPoint) {
                    const d: number = neighborPoint.distanceTo(selectedPosition);
                    if (d > effectDistance) {
                        break;
                    }
                    // gives values from 0 to 1
                    this.falloffMap.set(neighborIndex, 1 - d / effectDistance);
                }
            }
        });
    }

    pushHistory() {
        this.history.push(this.currentEditedMarginLine.clone());
    }

    undo() {
        if (this.history.length === 1) {
            return;
        }
        this.history.pop();
    }

    resetHistory() {
        this.history.length = 1;
    }

    clearCurrentLine() {
        this.pushHistory();
        this.currentEditedMarginLine.clear();
        return this.currentEditedMarginLine;
    }
}
