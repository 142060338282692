import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { DEFAULT_SCAN_APPEARANCE, DEFAULT_RESTORATIVE_APPEARANCE } from '@orthly/dentin';

export const connectorsRubric: DesignQcRubricCategoryEntry = {
    category: 'connectors',
    subcategories: [
        {
            category: 'connectors',
            name: 'Minimum width',
            grading: {
                type: 'binary',
                bad: 'Violates Rule of 27',
                ideal: 'Does not Violate Rule of 27',
            },
            description: `Rule of 27<br>(Height(^2)) * Width`,
            selectable_bad_reasons: ['Violates Rule of 27'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasBridge,
        },
    ],
};
