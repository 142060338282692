import type { CustomQFComponentProps } from '@orthly/ui';
import { SimpleAutocomplete } from '@orthly/ui';
import React from 'react';

interface CategoryAutocompleteProps extends CustomQFComponentProps {
    suggestions: { value: string; label: string }[];
}

export const CategoryAutocomplete: React.FC<CategoryAutocompleteProps> = props => {
    const { field, form, suggestions } = props;
    return (
        <SimpleAutocomplete
            freeSolo
            label={`Select a Category or Create New`}
            options={suggestions}
            initialInputValue={field.value ?? ''}
            onInputChange={value => {
                form.setFieldValue(field.name, value);
            }}
            onChange={value => {
                form.setFieldValue(field.name, value);
            }}
            AutocompleteProps={{ style: { minWidth: '100%' } }}
            error={form.errors[field.name]}
        />
    );
};
