import { ItemEditorV2SectionWrapper } from '../../components/ItemEditorV2SectionWrapper';
import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { ItemEditorV2SurgicalGuideDesiredSizeField } from './ItemEditorV2SurgicalGuideDesiredSizeField';
import { ItemEditorV2SurgicalGuideDrillKitField } from './ItemEditorV2SurgicalGuideDrillKitField';
import { ItemEditorV2SurgicalGuideImplantSystemFields } from './ItemEditorV2SurgicalGuideImplantSystemFields';
import type { LabsGqlImplantTypeLiteFragment } from '@orthly/graphql-operations';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface ItemEditorV2SurgicalGuideImplantMetadataSectionProps extends ItemEditorV2BaseProps {
    implantSystems: LabsGqlImplantTypeLiteFragment[];
}

export const ItemEditorV2SurgicalGuideImplantMetadataSection: React.VFC<
    ItemEditorV2SurgicalGuideImplantMetadataSectionProps
> = ({ item, updateItem, showError, implantSystems }) => {
    if (!CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.SurgicalGuide)) {
        return null;
    }

    return (
        <ItemEditorV2SectionWrapper title={'Implant Info'}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <ItemEditorV2SurgicalGuideImplantSystemFields
                        item={item}
                        updateItem={updateItem}
                        showError={showError}
                        implantSystems={implantSystems}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ItemEditorV2SurgicalGuideDrillKitField item={item} updateItem={updateItem} showError={showError} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ItemEditorV2SurgicalGuideDesiredSizeField item={item} updateItem={updateItem} />
                </Grid>
            </Grid>
        </ItemEditorV2SectionWrapper>
    );
};
