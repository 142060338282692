import type { LabsGqlAbutmentPartToImplantAndScanbodyFragment } from '@orthly/graphql-operations';
import { FlossPalette, Text, Grid, Icon } from '@orthly/ui-primitives';
import React from 'react';

interface AbutmentPartsCalculatorResultsProps {
    abutmentPartToImplantAndScanbody: LabsGqlAbutmentPartToImplantAndScanbodyFragment;
}

const FieldResult = ({ label, value, style }: { label: string; value: string | null; style?: React.CSSProperties }) => (
    <Grid container direction={'row'} style={{ ...style }}>
        <Text variant={'body2'} style={{ width: '250px' }}>
            {label}
        </Text>
        <Text variant={'body2'} medium>
            {value ?? ''}
        </Text>
    </Grid>
);

export const AbutmentPartsCalculatorResults: React.VFC<AbutmentPartsCalculatorResultsProps> = ({
    abutmentPartToImplantAndScanbody,
}) => (
    <div style={{ backgroundColor: FlossPalette.PRIMARY_BACKGROUND, padding: 16, marginTop: 16, width: '100%' }}>
        <Grid container direction={'row'} alignItems={'center'} style={{ marginBottom: `16px` }}>
            <Icon
                icon={'DoneIcon'}
                style={{ color: FlossPalette.PRIMARY_FOREGROUND, fontSize: `24px`, marginRight: `8px` }}
            />
            <Text variant={'h6'}>Found a match!</Text>
        </Grid>
        <FieldResult
            label={'3Shape Category'}
            value={abutmentPartToImplantAndScanbody.abutment_part.three_shape_category}
        />
        <FieldResult
            label={'3Shape System'}
            value={abutmentPartToImplantAndScanbody.abutment_part.three_shape_system}
        />
        <FieldResult label={'3Shape Kit'} value={abutmentPartToImplantAndScanbody.abutment_part.three_shape_kit} />
        <FieldResult
            label={'3Shape Material'}
            value={abutmentPartToImplantAndScanbody.abutment_part.three_shape_material}
        />
        <FieldResult
            label={'3Shape Manufacturer'}
            value={abutmentPartToImplantAndScanbody.abutment_part.three_shape_manufacturer}
        />
        <FieldResult
            label={'3Shape Manufacturing Process'}
            value={abutmentPartToImplantAndScanbody.abutment_part.three_shape_manufacturing_process}
        />
        <FieldResult
            style={{ marginTop: 16 }}
            label={'Abutment SKU'}
            value={abutmentPartToImplantAndScanbody.abutment_part.sku}
        />
        <FieldResult label={'Analog SKU'} value={abutmentPartToImplantAndScanbody.abutment_part.analog_sku} />
        <FieldResult label={'Screw SKU'} value={abutmentPartToImplantAndScanbody.abutment_part.screw_sku} />
    </div>
);
