/* eslint-disable no-nested-ternary */
import { NavigationTitleBoundary } from '../../components/NavigationTitleBoundary';
import { useOpsSidebarVisible } from '../../redux/ui/ui.selectors';
import { RouterUtils } from '../../utils/router/RouterUtils';
import { useCurrentOrderDetailId } from '../../utils/router/useOpenOrderDetail';
import { useSavedSearches } from '../../utils/useSavedSearches';
import { OrderDetailRoot } from '../Orders/screens/OrderDetail/OrderDetailRoot.graphql';
import { InboxDetailSidebar } from './components/InboxDetailSidebar/InboxDetailSidebar';
import { InboxListView } from './components/InboxListView/InboxListView';
import { InboxSubNavigation } from './components/InboxSubNavigation';
import { InboxTasksProvider, useInboxTasks } from './state/InboxTasksProvider';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, Redirect, Switch } from 'react-router-dom';

const basePath = RouterUtils.pathForScreen('tasks');

const OrderDetailRoute: React.FC<RouteComponentProps<{ orderId: string }>> = props => {
    const { params } = props.match;
    const { refetch } = useInboxTasks();
    return <OrderDetailRoot idOrOrderNumber={params.orderId} additionalRefetch={refetch} />;
};

export const InboxRoot: React.VFC = () => {
    const onDetailPage = !!useCurrentOrderDetailId();
    const sidebarOpen = useOpsSidebarVisible();
    useSavedSearches('inbox');
    return (
        <NavigationTitleBoundary title={'Tasks'}>
            <InboxTasksProvider>
                {!onDetailPage ? <InboxSubNavigation /> : sidebarOpen ? <InboxDetailSidebar /> : null}
                <Switch>
                    <Route path={`${basePath}/:orderId`} component={OrderDetailRoute} />

                    <Route path={basePath}>
                        <InboxListView />
                    </Route>

                    <Redirect to={basePath} />
                </Switch>
            </InboxTasksProvider>
        </NavigationTitleBoundary>
    );
};
