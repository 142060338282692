import { AskDoctorOption } from './AskDoctorOption';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { SortableList } from '@orthly/ui';
import { Grid, Button } from '@orthly/ui-primitives';
import type { AskDoctorV2ViewableOption } from '@orthly/veneer';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export type AskDoctorOptionListManagerProps = {
    options: AskDoctorV2ViewableOption[];
    setOptions: React.Dispatch<React.SetStateAction<AskDoctorV2ViewableOption[]>>;
    order: LabsGqlLabOrderFragment;
    errorMessages?: Record<string, string>;
};

export const AskDoctorOptionListManager: React.VFC<AskDoctorOptionListManagerProps> = ({
    options,
    setOptions,
    order,
    errorMessages,
}) => {
    const handleAddOption = React.useCallback(() => {
        setOptions(prevOptions => [
            ...prevOptions,
            {
                id: uuidv4(),
                label: '',
                type: null,
            },
        ]);
    }, [setOptions]);

    const handleDelete = React.useCallback(
        (optionIdToRemove: string) => {
            setOptions(prevOptions => prevOptions.filter(option => option.id !== optionIdToRemove));
        },
        [setOptions],
    );

    return (
        <Grid item container direction={'column'}>
            <Grid item>
                <Button onClick={() => handleAddOption()} variant={'primary'} endIcon={'PlusIcon'}>
                    Add an option
                </Button>
            </Grid>
            {options.length > 0 && (
                <SortableList
                    items={options}
                    renderItem={item => {
                        const index = options.findIndex(o => o.id === item.id);
                        const errorText = errorMessages?.[item.id] ?? null;

                        return (
                            <SortableList.Item id={item.id}>
                                <AskDoctorOption
                                    order={order}
                                    option={item}
                                    optionIndex={index}
                                    key={item.id}
                                    handleDelete={() => handleDelete(item.id)}
                                    updateOption={updatedOption => {
                                        setOptions(prevOptions =>
                                            prevOptions.map(option =>
                                                option.id === updatedOption.id ? updatedOption : option,
                                            ),
                                        );
                                    }}
                                    errorText={errorText}
                                />
                            </SortableList.Item>
                        );
                    }}
                    onChange={setOptions}
                />
            )}
        </Grid>
    );
};
