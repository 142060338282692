import { useDeformState } from '../AppState.hooks';
import { SliderRow } from './SliderRow';
import { FlossPalette, stylesFactory, Grid, Icon, Text } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    icon: {
        width: 16,
        height: 16,
        placeSelf: 'center',
        marginRight: 8,
    },
    primaryIcon: {
        color: FlossPalette.BLACK,
    },
    bannerWrapper: {
        padding: '8px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    primaryBannerWrapper: {
        fontSize: '8 !important',
    },
}));

export const DeformMenu: React.VFC = () => {
    const classes = useStyles();

    const { deformEnabled, brushSettings, setBrushRadius } = useDeformState();

    const { radius } = brushSettings;
    const { radiusMin, radiusMax, radiusStep } = brushSettings.ranges;

    return (
        <Grid item container direction={'column'} wrap={'nowrap'}>
            {deformEnabled && (
                <Grid item container spacing={1} style={{ padding: '0px 12px' }}>
                    <Grid item color={'gray'}>
                        Morphing
                    </Grid>
                    <Grid item xs={12}>
                        <SliderRow
                            label={'Radius'}
                            value={radius}
                            onChange={setBrushRadius}
                            min={radiusMin}
                            max={radiusMax}
                            step={radiusStep}
                        />
                    </Grid>
                    <Grid item color={'gray'}>
                        Click and hold anywhere on the restoration to start morphing.
                    </Grid>
                    <Grid item color={'gray'}>
                        <div className={clsx(classes.bannerWrapper, classes.primaryBannerWrapper)}>
                            <Icon icon={'ExclamationIcon'} className={clsx(classes.icon, classes.primaryIcon)} />
                            <Text variant={'body2'} color={'BLACK'}>
                                Select multiple dots using shift + click to move them as a group.
                            </Text>
                        </div>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
