import { ALIGNER_ARCH_CHOICE_METAFIELD_ID } from '@orthly/items';

// aligner preference fields that should be ignored as they're handled by dedicated properties or not relevant to lab slip
export const ALIGNER_PREFERENCE_FIELDS = [
    ALIGNER_ARCH_CHOICE_METAFIELD_ID,
    'aligner-treatment-area-order-item-meta',
    'aligner-plan-order-item-meta',
    'aligner-bite-concern-improve-overjet-order-item-meta',
    'aligner-bite-concern-improve-overbite-order-item-meta',
    'aligner-bite-concern-improve-midline-order-item-meta',
    'aligner-crowding-resolution-expansion-order-item-meta',
    'aligner-crowding-resolution-proclination-order-item-meta',
    'aligner-crowding-resolution-ipr-order-item-meta',
    'aligner-movement-restricted-teeth-order-item-meta',
    'aligner-attachment-restricted-teeth-order-item-meta',
    'aligner-extraction-teeth-order-item-meta',
    'aligner-interproximal-space-sizes-order-item-meta',
    'aligner-proposed-step-limit-order-item-meta',
    'aligner-trays-count',
];
