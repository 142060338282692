import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { ItemEditorV2BooleanItemDataField } from './fields/ItemEditorV2BooleanItemDataField';
import { ItemEditorV2DateItemDataField } from './fields/ItemEditorV2DateItemDataField';
import { ItemEditorV2EnumSelectItemDataField } from './fields/ItemEditorV2EnumSelectItemDataField';
import { ItemEditorV2EnumSelectMultiItemDataField } from './fields/ItemEditorV2EnumSelectMultiItemDataField';
import { ItemEditorV2NumberSelectItemDataField } from './fields/ItemEditorV2NumberSelectItemDataField';
import { ItemEditorV2StringFreeformItemDataField } from './fields/ItemEditorV2StringFreeformItemDataField';
import { ItemEditorV2StringSelectItemDataField } from './fields/ItemEditorV2StringSelectItemDataField';
import { ItemEditorV2ToothSelectMultiItemDataField } from './fields/ItemEditorV2ToothSelectMultiItemDataField';
import type { ItemDataField, ItemDataFieldId, LabOrderItemSKUType } from '@orthly/items';
import React from 'react';

interface ItemEditorV2ItemDataFieldProps<ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>
    extends ItemEditorV2BaseProps<SKU> {
    field: ItemDataField<ID, SKU>;
}

export const ItemEditorV2ItemDataField = <ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>({
    field,
    item,
    updateItem,
    showError,
}: ItemEditorV2ItemDataFieldProps<ID, SKU>): React.ReactElement => {
    switch (field.type) {
        case 'enum_select':
            return (
                <ItemEditorV2EnumSelectItemDataField
                    field={field}
                    item={item}
                    updateItem={updateItem}
                    showError={showError}
                />
            );
        case 'enum_select_multi':
            return (
                <ItemEditorV2EnumSelectMultiItemDataField
                    field={field}
                    item={item}
                    updateItem={updateItem}
                    showError={showError}
                />
            );
        case 'string_select':
            return (
                <ItemEditorV2StringSelectItemDataField
                    field={field}
                    item={item}
                    updateItem={updateItem}
                    showError={showError}
                />
            );
        case 'number_select':
            return (
                <ItemEditorV2NumberSelectItemDataField
                    field={field}
                    item={item}
                    updateItem={updateItem}
                    showError={showError}
                />
            );
        case 'tooth_select_multi':
            return (
                <ItemEditorV2ToothSelectMultiItemDataField
                    field={field}
                    item={item}
                    updateItem={updateItem}
                    showError={showError}
                />
            );
        case 'boolean':
            return (
                <ItemEditorV2BooleanItemDataField
                    field={field}
                    item={item}
                    updateItem={updateItem}
                    showError={showError}
                />
            );
        case 'date':
            return (
                <ItemEditorV2DateItemDataField
                    field={field}
                    item={item}
                    updateItem={updateItem}
                    showError={showError}
                />
            );
        case 'string_freeform':
            return (
                <ItemEditorV2StringFreeformItemDataField
                    field={field}
                    item={item}
                    updateItem={updateItem}
                    showError={showError}
                />
            );
    }
};
