import { useAdminSelector } from '../redux';
import { OpsInboxActions } from '../screens/Inbox/state/Inbox.actions';
import { OrdersOverviewActions, useOrdersOverviewAction } from '../screens/Orders/state/OrdersOverview.actions';
import { useGetSavedSearchById } from './useGetSavedSearchById.graphql';
import { useApolloClient } from '@apollo/client';
import { LabsGqlSavedSearchVisibility } from '@orthly/graphql-schema';
import { useAsyncIsLoading } from '@orthly/redux-async-actions';
import { useSession } from '@orthly/session-client';
import { QueryString } from '@orthly/ui';
import moment from 'moment-timezone';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

function getAction(type: 'inbox' | 'orders') {
    switch (type) {
        case 'inbox':
            return OpsInboxActions.LOAD_VIEWS;
        case 'orders':
            return OrdersOverviewActions.LOAD_VIEWS;
    }
}

export function useSavedSearches(type: 'inbox' | 'orders') {
    const client = useApolloClient();
    const dispatch = useDispatch();
    const action = getAction(type);
    const isLoading = useAsyncIsLoading(action.NAME);
    const lastLoadInitial = useAdminSelector(s => s.async.succeeded[action.NAME]);
    const [lastLoadDate, setLastLoadDate] = React.useState<string | undefined>(lastLoadInitial);
    const loadViews = React.useCallback(() => {
        setLastLoadDate(new Date().toJSON());
        dispatch(action({ client }));
    }, [action, client, dispatch]);
    React.useEffect(() => {
        if (isLoading) {
            return;
        }
        // refresh views every 10 minutes for users who don't reload site
        if (!lastLoadDate || moment(lastLoadDate).diff(moment(), 'minutes') > 10) {
            loadViews();
        }
    }, [loadViews, lastLoadDate, isLoading]);
}

export function useLoadSaveSearchFromUrl() {
    const history = useHistory();
    const savedSearchUuid = QueryString.parse(history.location.search)?.savedSearch;
    const setScreen = useOrdersOverviewAction('SET_SCREEN');
    const toggleFavorite = useOrdersOverviewAction('TOGGLE_FAVORITE_VIEW');
    const client = useApolloClient();
    const session = useSession();
    const { data: savedSearch } = useGetSavedSearchById(savedSearchUuid);

    // if the search is created by the user, set the screen
    if (savedSearchUuid && session?.user_id === savedSearch?.created_by) {
        setScreen(savedSearchUuid);
    }

    // search is not created by the user
    if (session?.user_id !== savedSearch?.created_by) {
        // only redirect to and save the search if it's public
        if (savedSearchUuid && savedSearch?.visibility === LabsGqlSavedSearchVisibility.Public) {
            toggleFavorite({ client, searchId: savedSearchUuid, favorited: true });
            setScreen(savedSearchUuid);
        }

        // if the search is private redirect to the orders page
        if (savedSearch?.visibility === LabsGqlSavedSearchVisibility.Private) {
            window.alert("This is a private search of another user. You can't save it.");
            history.push({
                pathname: '/orders',
                search: undefined,
            });
            setScreen('all');
        }
    }
}
