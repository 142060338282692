import { InboxCardIcon } from '../../InboxCard';
import {
    FlossPalette,
    stylesFactory,
    Text,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    InfoIcon,
    Icon,
} from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    confirmation: {
        paddingTop: 88,
        paddingLeft: 135,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: 44,
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center',
            paddingTop: 56,
            paddingLeft: 0,
            marginBottom: 0,
        },
    },
    infoIcon: {
        borderRadius: 16,
        height: 32,
        width: 32,
        marginRight: 16,
        marginTop: 3,
        [theme.breakpoints.down('lg')]: {
            marginTop: 0,
        },
    },
    calendarIcon: {
        backgroundColor: FlossPalette.GRAY,
        borderRadius: 16,
        color: FlossPalette.WHITE,
        marginRight: 16,
        marginTop: 7,
        [theme.breakpoints.down('lg')]: {
            marginTop: 0,
        },
    },
}));

export const RefabConfirmationScreen: React.VFC<{
    requiresReturn: boolean;
    estimatedDate: string;
}> = ({ requiresReturn, estimatedDate }) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const formattedEstimatedDate = moment(estimatedDate).format('MMMM Do');
    return (
        <Grid container direction={'row'} className={classes.confirmation}>
            {requiresReturn ? (
                <>
                    <Grid item>
                        <InfoIcon className={classes.infoIcon} />
                    </Grid>
                    <Grid item>
                        <Grid container direction={'column'}>
                            <Grid item style={{ marginTop: isMobile ? 4 : 0 }}>
                                <Text variant={isMobile ? 'h6' : 'h4'} medium>
                                    What's next?
                                </Text>
                            </Grid>
                            <Grid item style={{ marginTop: 16 }}>
                                <Text variant={'body2'}>1. Print your shipping label</Text>
                                <Text variant={'body2'}>2. Securely pack and return your item to Dandy</Text>
                                <Text variant={'body2'}>3. Dandy will process your refabrication request</Text>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item>
                        <InboxCardIcon
                            variant={'info'}
                            IconComponent={props => <Icon icon={'Event'} {...props} />}
                            style={{
                                backgroundColor: FlossPalette.GRAY,
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Grid container direction={'column'}>
                            <Grid item>
                                <Text variant={isMobile ? 'h6' : 'h4'} medium>
                                    Estimated Delivery
                                </Text>
                            </Grid>
                            <Grid item style={{ marginTop: 16 }}>
                                <Text variant={isMobile ? 'h5' : 'h3'} medium color={'PRIMARY_FOREGROUND'}>
                                    {formattedEstimatedDate}
                                </Text>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};
