import { getQFUploadMultiFileFieldDef } from '../components/FirebaseUpload/QFUploadMultiFileField';
import type { MetafieldInputs } from './OrderDesign/DesignReviewMetafields';
import {
    DesignReviewMetafields,
    metafieldInputsAreValid,
    metafieldInputsToMetafieldSubmissions,
    useReviewFields,
} from './OrderDesign/DesignReviewMetafields';
import type { LabsGqlSubmitScanReviewMutationVariables, LabsGqlOrder } from '@orthly/graphql-operations';
import { useSubmitScanReviewMutation } from '@orthly/graphql-react';
import type { LabsGqlOrderScanReviewRejectionInput } from '@orthly/graphql-schema';
import { LabsGqlDesignReviewFieldType, LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import type { FieldDefText, FieldsDefProp } from '@orthly/ui';
import { OrthlyBrowserConfig, QuickForm, useRootActionCommand, RootActionDialog } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

export const ScanRejectionReasons = [
    'Bite scan confirmation',
    'Dr approval needed on margin',
    'File download error',
    'Implant: Incorrect scan body',
    'Implant specs confirmation',
    'Insufficient Clearance',
    'Opposing arch missing or insufficient',
    'Order specification confirmation (shade, material or tooth number)',
    'Path of insertion issue',
    'Scan error (insufficient images/quality)',
    'Tissue over margin',
    'Other',
];

const SEVERITY_SCORES = ['1 (least severe)', '2', '3', '4', '5 (most severe)'];

type RejectionFormScema = Omit<LabsGqlOrderScanReviewRejectionInput, 'category'> & {
    severity_score: string;
    categories: string[];
};

function useRejectionFormSchema(orderId: string) {
    return React.useMemo<FieldsDefProp<RejectionFormScema>>(() => {
        const multilineFieldProps: FieldDefText['fieldProps'] = { multiline: true, minRows: 3, maxRows: 100 };
        const storagePathConfig = getFullStoragePath(
            OrthlyBrowserConfig.env,
            OrderingStorageConfigs.scanRejections,
            orderId,
        );
        return {
            categories: { type: 'multiselect', options: ScanRejectionReasons, disableSortOptions: true },
            internal_notes: {
                type: 'text',
                helperText: 'For internal use in resolving the issue',
                fieldProps: multilineFieldProps,
            },
            severity_score: {
                type: 'select',
                options: SEVERITY_SCORES.map((score, idx) => ({ label: score, value: (idx + 1).toString() })),
            },
            notes_for_doctor: { type: 'text', fieldProps: multilineFieldProps, optional: true },
            file_urls: getQFUploadMultiFileFieldDef({
                optional: true,
                storagePathConfig: storagePathConfig,
                label: 'Issue screenshots',
            }),
        };
    }, [orderId]);
}

export const FlagScanForm: React.VFC<{
    order: LabsGqlOrder;
    submit: (args: LabsGqlSubmitScanReviewMutationVariables) => Promise<void>;
}> = ({ order, submit }) => {
    const rejectionFormSchema = useRejectionFormSchema(order.id);

    const [metafieldInputs, setMetafieldInputs] = React.useState<MetafieldInputs>({});

    const { reviewFields, loading } = useReviewFields(order, LabsGqlDesignReviewFieldType.FlagScan);
    const isValid = React.useMemo(
        () => metafieldInputsAreValid(metafieldInputs, reviewFields),
        [metafieldInputs, reviewFields],
    );

    return (
        <>
            <DesignReviewMetafields {...{ metafieldInputs, reviewFields, setMetafieldInputs }} />
            <QuickForm<RejectionFormScema>
                fields={rejectionFormSchema}
                initialValues={{}}
                disabled={loading || !isValid}
                onSubmit={async result => {
                    const metafields = metafieldInputsToMetafieldSubmissions(metafieldInputs, reviewFields);
                    await submit({
                        id: order.id,
                        rejection: {
                            ...result,
                            metafields,
                            category: result.categories.join(', '),
                            severity_score: parseInt(result.severity_score),
                        },
                        approval_notes: null,
                    });
                }}
                submitButtonProps={{ children: 'Flag scan' }}
            />
        </>
    );
};

interface FlagScanActionProps {
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
}

export const FlagScanAction: React.FC<FlagScanActionProps> = ({ order, refetchOrder }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const { submit, submitting } = useRootActionCommand(useSubmitScanReviewMutation(), {
        successMessage: 'Scan Flagged',
        onSuccess: async () => {
            await refetchOrder();
            setOpen(false);
        },
    });

    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            title={`Flag scan`}
            loading={submitting}
            content={
                <Grid container direction={'column'} style={{ padding: '8px 16px' }}>
                    {open && <FlagScanForm order={order} submit={submit} />}
                </Grid>
            }
            buttonText={`Flag scan`}
            buttonProps={{
                variant: 'ghost',
                startIcon: 'WarningIcon',
                disabled: order.fulfillment_workflow.active_task?.type === LabsGqlWorkflowTaskType.ResolveScanRejection,
            }}
        />
    );
};
