import type { LabsGqlCreateDoctorRequestCommand } from '@orthly/graphql-schema';
import {
    LabsGqlDoctorRequestOptionType,
    LabsGqlDoctorRequestInitiatingFlow,
    OrderItemV2InputUtils,
} from '@orthly/graphql-schema';
import { DoctorRequestOptionDefinitions, DoctorRequestOptionType } from '@orthly/shared-types';
import type { AskDoctorV2ViewableOption } from '@orthly/veneer';
import _ from 'lodash';
import { z } from 'zod';

const DoctorRequestSchema: z.Schema<LabsGqlCreateDoctorRequestCommand> = z.object({
    order_id: z.string().uuid(),
    question_text: z.string().min(1),
    initiating_flow: z.nativeEnum(LabsGqlDoctorRequestInitiatingFlow),
    photo_attachment_urls: z.array(z.string()),
    options: z
        .array(
            z.object({
                label: z.string().min(1),
                type: z.nativeEnum(LabsGqlDoctorRequestOptionType),
                modify_order_config: z.any().optional(),
            }),
        )
        .min(1),
});

const MAP_OPTION_TYPES = {
    [LabsGqlDoctorRequestOptionType.AddReductionCoping]: DoctorRequestOptionType.AddReductionCoping,
    [LabsGqlDoctorRequestOptionType.ManualResolution]: DoctorRequestOptionType.ManualResolution,
    [LabsGqlDoctorRequestOptionType.ModifyOrder]: DoctorRequestOptionType.ModifyOrder,
    [LabsGqlDoctorRequestOptionType.NeedsAdditionalScans]: DoctorRequestOptionType.NeedsAdditionalScans,
    [LabsGqlDoctorRequestOptionType.Other]: DoctorRequestOptionType.Other,
    [LabsGqlDoctorRequestOptionType.ProceedAsIs]: DoctorRequestOptionType.ProceedAsIs,
};

/**
 * We use a different type for the data that is managed in the UI vs the one we then submit
 * This allows us to have some fields being optional in the UI while required in the endpoint
 * which is nice as we let the UI slowly build the object that we submit at the end of the flow
 */

type AskDoctorUIDataToValidate = {
    initiating_flow: LabsGqlDoctorRequestInitiatingFlow;
    order_id: string;
    photo_attachment_urls: string[];
    question_text: string;
    options: AskDoctorV2ViewableOption[];
};

export type AskDoctorErrorState = {
    questionError: string | null;
    noOptionsError: string | null;
    optionErrors: Record<string, string>;
};

const getErrorMessageForOption = (option: AskDoctorV2ViewableOption): string | null => {
    if (!option.type) {
        return 'Please select an option in the dropdown above.';
    }

    if (!option.label || option.label === '') {
        return 'Please include a description for this option in the textfield above.';
    }

    if (option.type === LabsGqlDoctorRequestOptionType.ModifyOrder && !option.modify_order_config?.mode) {
        return 'Please click on Configure to set up the changes you want to suggest to the doctor.';
    }

    return null;
};

const hasValidErrorState = (errorState: AskDoctorErrorState): boolean => {
    return (
        errorState.noOptionsError === null && errorState.questionError === null && _.isEmpty(errorState.optionErrors)
    );
};

export const validateData = (
    data: AskDoctorUIDataToValidate,
): {
    isDataValid: boolean;
    errorState: AskDoctorErrorState;
    validatedData: LabsGqlCreateDoctorRequestCommand | null;
} => {
    const errorState: AskDoctorErrorState = { questionError: null, noOptionsError: null, optionErrors: {} };

    // We don't use Zod schema errors because of 2 main reasons: It's not possible to generate errors on a property based on another property
    // which we need for the Modify Order configuration errors, and we also do not want to display all errors at once for each option.
    // For instance, if an option does not have a type or a label, we just want to tell the user to select an option type, since the label wouldn't even be shown at this point.
    if (!data.question_text || data.question_text.length === 0) {
        errorState.questionError = 'Please add a question for the doctor to answer.';
    }

    if (data.options.length === 0) {
        errorState.noOptionsError = 'Please add an option for the doctor to answer the question.';
    }

    for (const option of data.options) {
        const optionErrorMessage = getErrorMessageForOption(option);

        if (optionErrorMessage) {
            errorState.optionErrors = { ...errorState.optionErrors, [option.id]: optionErrorMessage };
        }
    }

    // We use this parse to properly format the back-end data and safely use `LabsGqlCreateDoctorRequestCommand` later without casting.
    const result = DoctorRequestSchema.safeParse({
        ...data,
        options: data.options.map(option => ({
            label: option.label,
            type: option.type,
            modify_order_config:
                option.type === LabsGqlDoctorRequestOptionType.ModifyOrder
                    ? {
                          mode: option.modify_order_config?.mode,
                          // We only format the items by Sku at validation time since it's a lot easier to manipulate an array of order items v2
                          // throughout our various UI tools
                          items_v2_by_sku:
                              OrderItemV2InputUtils.getOrderItemV2InputBySKU(
                                  option.modify_order_config?.items_v2 ?? [],
                              ) ?? {},
                      }
                    : undefined,
        })),
    });

    const isDataValid = result.success && hasValidErrorState(errorState);

    return {
        isDataValid,
        errorState,
        validatedData: isDataValid ? result.data : null,
    };
};

export const getOptionTypeLabel = (optionType: LabsGqlDoctorRequestOptionType | null): string => {
    if (optionType === null) {
        return '';
    }

    return DoctorRequestOptionDefinitions[MAP_OPTION_TYPES[optionType]].label;
};

export const getOpsFieldLabel = (optionType: LabsGqlDoctorRequestOptionType | null): string => {
    const defaultFieldLabel = 'Text displayed to the doctor for this option';

    if (optionType === null) {
        return defaultFieldLabel;
    }

    return DoctorRequestOptionDefinitions[MAP_OPTION_TYPES[optionType]].opsFieldLabel ?? defaultFieldLabel;
};

export const getDefaultDoctorLabel = (optionType: LabsGqlDoctorRequestOptionType | null): string => {
    if (optionType === null) {
        return '';
    }

    return DoctorRequestOptionDefinitions[MAP_OPTION_TYPES[optionType]]?.defaultDoctorLabel ?? '';
};
