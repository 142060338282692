import { ItemEditorV2DialogField } from '../../components/ItemEditorV2DialogField';
import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { DandyMouthToothSelector } from '@orthly/dentin';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const isItemEditorV2SingleToothItem = CartItemV2Utils.itemIsType([
    LabOrderItemSKUType.Crown,
    LabOrderItemSKUType.Inlay,
    LabOrderItemSKUType.Veneer,
    LabOrderItemSKUType.Implant,
]);

export const ItemEditorV2SingleToothField: React.VFC<
    Omit<
        ItemEditorV2BaseProps<
            | LabOrderItemSKUType.Crown
            | LabOrderItemSKUType.Inlay
            | LabOrderItemSKUType.Veneer
            | LabOrderItemSKUType.Implant
        >,
        'showError'
    >
> = ({ item, updateItem }) => {
    return (
        <ItemEditorV2DialogField
            fieldLabel={'Tooth'}
            fieldValue={`${item.unit.unn}`}
            modalTitle={'Select Tooth'}
            modalContent={
                <Grid container justifyContent={'center'}>
                    <Grid item xs={12} sm={6}>
                        <DandyMouthToothSelector
                            selected={[item.unit.unn]}
                            onChange={unns => {
                                const newUnn = _.first(_.pull(unns, item.unit.unn));
                                if (newUnn) {
                                    updateItem({ name: 'single_unn', payload: newUnn });
                                }
                            }}
                            animate={false}
                            verticalLayout={true}
                            archLabels={true}
                        />
                    </Grid>
                </Grid>
            }
        />
    );
};
