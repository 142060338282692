import type { MainViewCameraControlsRef } from '../ModelViewer/ModelViewerTHREETypes';
import { updateCameraFromPose } from '../ModelViewer/utils3d/camera-controls.util';
import type { ToothViewDirections } from '../ModelViewer/utils3d/presetCameraView.utils';
import { generateGenericCameraRotationForTooth } from '../ModelViewer/utils3d/presetCameraView.utils';
import type { MountingMatrix } from '@orthly/forceps';
import type { ToothNumber } from '@orthly/items';
import * as THREE from 'three';

const DEFAULT_MOUNTING_MATRIX: MountingMatrix = {
    x: new THREE.Vector3(1, 0, 0),
    y: new THREE.Vector3(0, 1, 0),
    z: new THREE.Vector3(0, 0, 1),
    origin: new THREE.Vector3(0, 0, 0),
};

/**
 * Sets the camera to look at the specified tooth from the specified view.
 */
export function setToothView(
    controlsRef: MainViewCameraControlsRef,
    toothNumber: ToothNumber,
    toothCenter: THREE.Vector3,
    insertionAxis: THREE.Vector3 | undefined,
    viewType: ToothViewDirections,
): void {
    /**
     * We use the default mounting matrix here, i.e. we assume that jaw models' occlusal frame is aligned with the
     * global frame. This is true for cases prepped by Dandy Prep, but not true for other cases.
     */
    const rotationMatrix = generateGenericCameraRotationForTooth(
        toothNumber,
        DEFAULT_MOUNTING_MATRIX,
        insertionAxis,
        viewType,
    );

    const camOrientation = new THREE.Quaternion().setFromRotationMatrix(rotationMatrix);
    // Camera location
    const locVector = toothCenter.clone().add(new THREE.Vector3(0, 0, 100).applyQuaternion(camOrientation));

    updateCameraFromPose(controlsRef, { targetVector: toothCenter, locVector, rotMatrix: rotationMatrix });
}
