import { AbutmentPartTable } from './AbutmentPartTable';
import { AbutmentPartProvider } from './utils';
import React from 'react';

export const AbutmentPartRoot: React.FC = () => {
    return (
        <AbutmentPartProvider>
            <AbutmentPartTable />
        </AbutmentPartProvider>
    );
};
