import { RootActionDialog } from '@orthly/ui';
import React from 'react';

export interface RuleTableDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    form: React.ReactNode;
    title: string;
}

export const RuleTableDialog: React.VFC<RuleTableDialogProps> = props => {
    const { open, setOpen, form, title } = props;
    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            dialogProps={{ maxWidth: 'lg', fullWidth: true, PaperProps: { style: { minWidth: 350 } } }}
            contentProps={{ style: { padding: 20, borderTop: 'none' } }}
            title={title}
            loading={false}
            content={form}
            hideButton={true}
        />
    );
};
