import { useDisplayOptionsContext } from '../contexts/TableOptionsContext';
import { useMuiTableSelector } from '../state';

export function useDetailPanelOpen(rowId: string) {
    const toggledDetailPanelRows = useMuiTableSelector(s => s.detailPanelRows);
    const rowIsToggled = toggledDetailPanelRows.includes(rowId);
    const { detailPanelDefaultOpen: rowIsClosedWhenToggled } = useDisplayOptionsContext();
    // if the default is open, a row being toggled would mean its closed
    return rowIsClosedWhenToggled ? !rowIsToggled : rowIsToggled;
}
