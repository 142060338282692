import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useSoftSmileAssociatePlanWithCaseMutation } from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import { Button, FlossPalette, Grid, Typography, TextField } from '@orthly/ui-primitives';
import { OrderDetailAccordionBlock } from '@orthly/veneer';
import { useSnackbar } from 'notistack';
import React from 'react';

interface SoftSmileAssociationBlockProps {
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
}

export const SoftSmileAssociationBlock: React.FC<SoftSmileAssociationBlockProps> = ({ order, refetchOrder }) => {
    const [path, setPath] = React.useState<string>('');
    const [version, setVersion] = React.useState<string>('');
    const [raw_associate_plan] = useSoftSmileAssociatePlanWithCaseMutation();
    const invalidState = (version !== '' && Number.isNaN(parseInt(version))) || parseInt(version) < 0;
    const { enqueueSnackbar } = useSnackbar();
    const { submit: associate_plan } = useChangeSubmissionFn(raw_associate_plan, {
        onSuccess: () => {
            setPath('');
            setVersion('');
            void refetchOrder();
            enqueueSnackbar('Successfully linked treatment plan to case.', {
                variant: 'success',
            });
        },
    });

    if (!order) {
        return null;
    }

    return (
        <OrderDetailAccordionBlock variant={'full'} title={'SoftSmile Testing Tools'}>
            <Typography variant={'subtitle2'} color={'textSecondary'} style={{ padding: '5px' }}>
                Link Existing Treatment Plan to Current SoftSmile Case
            </Typography>
            <form
                onSubmit={evt => {
                    evt.preventDefault();
                }}
                style={{
                    width: '100%',
                    background: `${FlossPalette.WHITE}`,
                    display: 'flex',
                    alignItems: 'center',
                    padding: 10,
                    borderRadius: 10,
                    marginTop: 10,
                }}
            >
                <Grid container>
                    <Grid item xs={10}>
                        <TextField
                            variant={'standard'}
                            value={path}
                            onChange={event => setPath(event.target.value)}
                            label={`Treatment Plan Path ('orders/aligners/<planId>')`}
                            style={{ background: `${FlossPalette.WHITE}`, borderRight: 'solid 1px rgba(0,0,0,.1)' }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            variant={'standard'}
                            value={version}
                            onChange={event => setVersion(event.target.value)}
                            label={'Version #'}
                            style={{ background: `${FlossPalette.WHITE}` }}
                            error={invalidState}
                        />
                    </Grid>
                </Grid>
                <Button
                    variant={'primary'}
                    size={'small'}
                    style={{ margin: 5, borderRadius: '3px' }}
                    onClick={() => {
                        void associate_plan({
                            variables: { orderId: order.id, versionId: parseInt(version), pathPrefix: path },
                        });
                    }}
                    disabled={path === '' || version === '' || invalidState}
                >
                    Link Plan
                </Button>
            </form>
        </OrderDetailAccordionBlock>
    );
};
