import type { OrderDetailSidebarTabPieces } from '../../OrderDetail.types';
import { LabtracDetailsSidebar } from '@orthly/veneer';
import React from 'react';

export const LabtracDetailsSidebarContainer: React.FC = () => {
    // NOT YET IMPLEMENTED
    // TODO: Update this to display internal procedure data https://meetdandy.atlassian.net/browse/EPDSCM-1936
    return <LabtracDetailsSidebar dataLoading={false} labtracId={''} procedureData={[]} />;
};

export function useLabtracDetailsSidebarParts(): OrderDetailSidebarTabPieces {
    return {
        name: 'Labtrac',
        sidebarBody: <LabtracDetailsSidebarContainer />,
    };
}
