import { PausedPracticeEditorModal } from './PausedPracticeEditorModal.graphql';
import { Grid, Button, stylesFactory } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    pauseButton: {
        height: 24,
    },
    pauseRoot: {
        padding: 8,
    },
}));

interface PausedPracticePauseButtonProps {
    practiceId: string;
    refetch: () => Promise<unknown>;
}

export const PausedPracticePauseButton: React.VFC<PausedPracticePauseButtonProps> = ({ practiceId, refetch }) => {
    const classes = useStyles();

    const [isPauseModalOpen, setIsPauseModalOpen] = React.useState<boolean>(false);

    const { value: isEnabled } = useFeatureFlag('allowedToPausePracticesFromPlacingOrders');

    if (!isEnabled) {
        return null;
    }

    return (
        <Grid container direction={'row'} className={classes.pauseRoot}>
            <PausedPracticeEditorModal
                open={isPauseModalOpen}
                practiceId={practiceId}
                setOpen={setIsPauseModalOpen}
                currentReasons={[]}
                refetch={refetch}
            />
            <Grid item xs />
            <Grid item>
                <Button variant={'ghost'} onClick={() => setIsPauseModalOpen(true)} className={classes.pauseButton}>
                    Pause practice from placing orders
                </Button>
            </Grid>
        </Grid>
    );
};
