import { RuleTableDialog } from '../../../../components/RuleTable/RuleTableDialog';
import { RoutingRuleUtils } from '../../components/RoutingRule.util';
import { TargetDistributionRuleForm } from './TargetDistributionRuleForm';
import { TargetDistributionRuleRowActions } from './TargetDistributionsRuleRowActions';
import type { LabsGqlTargetDistributionRuleFragment } from '@orthly/graphql-operations';
import {
    useListLabOrgs,
    useTargetDistributionRulesQuery,
    useCreateTargetDistributionRuleMutation,
} from '@orthly/graphql-react';
import { MUITable } from '@orthly/mui-table';
import { ActionCard, LoadBlocker, useRootActionCommand } from '@orthly/ui';
import { Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

/**
 * Page-level component for displaying, creating, and editing Target Distribution Rules in routing.
 */
export const TargetDistributionRuleList: React.VFC = () => {
    const {
        data: targetDistributionRuleData,
        error: errorRules,
        loading: loadingRules,
        refetch,
    } = useTargetDistributionRulesQuery({
        fetchPolicy: 'cache-and-network',
    });
    const targetDistributionRules = targetDistributionRuleData?.routingTargetDistributionRules ?? [];
    const [openAddDialog, setOpenAddDialog] = React.useState(false);

    const closeAndRefetch = () => {
        void refetch();
        setOpenAddDialog(false);
    };
    const { submit: submitTargetDistributionRule, submitting: submittingTargetDistributionRule } = useRootActionCommand(
        useCreateTargetDistributionRuleMutation(),
        {
            onSuccess: closeAndRefetch,
        },
    );

    const { data: labOrgs, loading: labOrgsLoading, error: labOrgsError } = useListLabOrgs();
    const labsToOrganization: Record<string, string> = (labOrgs?.listOrganizations ?? []).reduce((mapping, labOrg) => {
        return { ...mapping, [labOrg.id]: labOrg.name };
    }, {});
    return (
        <Grid container>
            {labOrgsError && (
                <ActionCard
                    title={`Error loading lab names`}
                    variant={'alert'}
                    subtitle={
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            {labOrgsError.message}
                        </Text>
                    }
                />
            )}
            {labOrgsLoading && (
                <ActionCard
                    title={`Loading lab names`}
                    variant={'message'}
                    subtitle={'Without the lab names loaded, lab Ids will be used in the Target Distribution column'}
                />
            )}
            {errorRules && (
                <ActionCard
                    title={`Error loading distribution rules`}
                    variant={'alert'}
                    subtitle={
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            {errorRules}
                        </Text>
                    }
                />
            )}
            <MUITable<LabsGqlTargetDistributionRuleFragment>
                title={'Lab Target Distribution Rules'}
                data={targetDistributionRules}
                loading={loadingRules}
                actions={{
                    global: [{ icon: 'add', position: 'toolbar', onClick: () => setOpenAddDialog(true) }],
                }}
                displayOptions={{ filter: true, sort: true, filterValues: true }}
                columns={[
                    {
                        title: 'Priority',
                        name: 'priority',
                        field: 'priority',
                        render: row => <>{row.priority === -1 ? 'Global' : row.priority}</>,
                        type: 'numeric',
                    },
                    ...RoutingRuleUtils.commonColumns,
                    {
                        title: 'Target Distribution',
                        name: 'targetDistribution',
                        field: 'target_distribution_items',
                        render: row => {
                            return row.routing_target_distribution_items.map((item, key) => {
                                return (
                                    <p key={`${item.manufacturer_id}_${key}`}>
                                        {labsToOrganization[item.manufacturer_id] ?? item.manufacturer_id}:{' '}
                                        {item.weight}
                                    </p>
                                );
                            });
                        },
                    },
                    {
                        title: 'Disabled?',
                        name: 'actions',
                        field: 'disabled',
                        render: row => <TargetDistributionRuleRowActions row={row} refetch={refetch} />,
                        filter: true,
                        sort: false,
                        customFilterFn: (values, row) => values.includes(`${row.disabled}`),
                        type: 'boolean',
                        filterOptions: {
                            defaultValues: ['false'],
                        },
                    },
                ]}
            />
            <RuleTableDialog
                open={openAddDialog}
                setOpen={setOpenAddDialog}
                form={
                    <LoadBlocker blocking={submittingTargetDistributionRule}>
                        <TargetDistributionRuleForm
                            onSubmit={values => {
                                void submitTargetDistributionRule({ data: values });
                            }}
                        />
                    </LoadBlocker>
                }
                title={'Add Target Distribution Rule'}
            />
        </Grid>
    );
};
