import { useFeatureFlag } from '../../../Providers/LaunchDarkly';
import type { VeneerUseOrderRevisionItemsDesign_Fragment } from '../../OrderDesignActions.graphql';
import type { FragmentType } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderItemV2 } from '@orthly/graphql-schema';
import { LabsGqlOrderItemSkuType } from '@orthly/graphql-schema';
import { useScreenIsXs } from '@orthly/ui-primitives';
import React from 'react';

const ENABLED_SKUS: LabsGqlOrderItemSkuType[] = [
    LabsGqlOrderItemSkuType.Bridge,
    LabsGqlOrderItemSkuType.Crown,
    LabsGqlOrderItemSkuType.Implant,
    LabsGqlOrderItemSkuType.ImplantBridge,
    LabsGqlOrderItemSkuType.Model,
    LabsGqlOrderItemSkuType.Waxup,
];

export const useComparisonMode = ({
    designRevisionFragments,
    items,
}: {
    designRevisionFragments: FragmentType<typeof VeneerUseOrderRevisionItemsDesign_Fragment>[];
    items: LabsGqlLabOrderItemV2[];
}) => {
    const isSmall = useScreenIsXs();
    const [isComparisonModeActive, setComparisonModeActive] = React.useState(false);
    const { value: enableDDPComparison = false } = useFeatureFlag('enableDDPComparison');

    const isComparisonModeAvailable = React.useMemo(() => {
        if (!enableDDPComparison) {
            return false;
        }

        if (isSmall) {
            return false;
        }

        if (designRevisionFragments.length <= 1) {
            return false;
        }

        return items.every(item => ENABLED_SKUS.includes(item.sku));
    }, [designRevisionFragments.length, enableDDPComparison, isSmall, items]);

    const toggleComparisonMode = React.useCallback(() => {
        if (isComparisonModeAvailable) {
            setComparisonModeActive(prev => !prev);
        }
    }, [isComparisonModeAvailable]);

    const isComparisonSelectorAvailable = React.useMemo(
        () => designRevisionFragments.length >= 3 && isComparisonModeActive,
        [designRevisionFragments.length, isComparisonModeActive],
    );

    React.useEffect(() => {
        if (!isComparisonModeAvailable) {
            setComparisonModeActive(false);
        }
    }, [isComparisonModeAvailable]);

    return {
        isComparisonModeActive,
        isComparisonModeAvailable,
        isComparisonSelectorAvailable,
        toggleComparisonMode,
    };
};
