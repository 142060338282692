import { useInsertionAxisState, useOperationsState } from '../AppState.hooks';
import { EditItemSelect } from './EditItemSelect';
import { FinishingStagesMenu } from './FinishingStagesMenu';
import { SceneAppearanceMenu } from './SceneAppearanceMenu';
import { FinishingPoiStage } from './Stages/FinishingPoiStage';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

export const EditSidebarContents: React.VFC = () => {
    const { itemToothNumbers } = useOperationsState();
    const { insertionAxisAdjustingEnabled } = useInsertionAxisState();

    return (
        <Grid item container direction={'column'} wrap={'nowrap'}>
            <Grid item>
                <SceneAppearanceMenu />
            </Grid>
            {itemToothNumbers.length > 1 && (
                <Grid item>
                    <EditItemSelect />
                </Grid>
            )}
            <Grid item>{insertionAxisAdjustingEnabled ? <FinishingPoiStage /> : <FinishingStagesMenu />}</Grid>
        </Grid>
    );
};
