import { useFirebaseStorage } from '../../context/firebase.context';
import { getFirebaseDownloadUrl } from '../../hooks/useFirebaseDownload';
import type { FirebasePreviewFileMulti } from '../../hooks/useFirebaseDownload';
import { loadModelFromBuffer } from '../DesignViewer/OrderDesignPreview.util';
import type { FullJawPayloadView, ModelPayload, SingletonModelPayloadView } from '@orthly/dentin';
import { ModelPayloadViewKind } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import axios from 'axios';
import _ from 'lodash';
import React from 'react';
import { useAsyncCallback } from 'react-async-hook';

type CommonScanMetadata = { isUpper: boolean; step: number };
type FirebasePreviewFileMultiWithMetadata = FirebasePreviewFileMulti & CommonScanMetadata;
type ModelPayloadWithMetadata = ModelPayload & CommonScanMetadata;

const VeneerUseTreatmentPlanViewsStepToPayload_Fragment = graphql(`
    fragment VeneerUseTreatmentPlanViewsStepToPayload_Fragment on DesignOrderAlignerTreatmentPlanStepDTO {
        legacy_assets {
            lower_path
            upper_path
        }
        step
    }
`);

const stepToPayload = (
    stepFragment: FragmentType<typeof VeneerUseTreatmentPlanViewsStepToPayload_Fragment>,
    which: 'upper' | 'lower',
): FirebasePreviewFileMultiWithMetadata | undefined => {
    const step = getFragmentData(VeneerUseTreatmentPlanViewsStepToPayload_Fragment, stepFragment);
    const isUpper = which === 'upper';

    const path = isUpper ? step.legacy_assets.upper_path : step.legacy_assets.lower_path;

    if (!path) {
        return undefined;
    }

    return {
        isUpper,
        step: step.step,
        source: path,
        name: `Step ${step.step}, ${isUpper ? 'Upper' : 'Lower'}`,
    };
};

// Finds the most recent arch preceding this scan to use for this one.
const fixMissingArches = (
    flattenedSteps: FirebasePreviewFileMultiWithMetadata[],
    step?: FirebasePreviewFileMultiWithMetadata,
): FirebasePreviewFileMultiWithMetadata[] => {
    if (!step) {
        return [];
    }

    const needsUpper = !step.isUpper;
    // Grab the last step that contains each arch
    const missingArch = _.maxBy(
        flattenedSteps.filter(candidate => candidate.isUpper === needsUpper && candidate.step < step.step),
        candidate => candidate.step,
    );

    return _.compact([
        step,
        missingArch
            ? { ...missingArch, step: step.step, name: `Step ${step.step}, ${needsUpper ? 'Upper' : 'Lower'}` }
            : undefined,
    ]);
};

const VeneerUseTreatmentPlanViews_Fragment = graphql(`
    fragment VeneerUseTreatmentPlanViews_Fragment on DesignOrderAlignerTreatmentPlanDTO {
        version_number_internal
        version_number_practice
        steps {
            legacy_assets {
                lower_path
                upper_path
            }
            ...VeneerUseTreatmentPlanViewsStepToPayload_Fragment
        }
    }
`);

export function useTreatmentPlanViews(
    treatmentPlanFragment: FragmentType<typeof VeneerUseTreatmentPlanViews_Fragment>,
) {
    const treatmentPlan = getFragmentData(VeneerUseTreatmentPlanViews_Fragment, treatmentPlanFragment);
    const steps = React.useMemo(() => {
        // This is a 2d array, where each element is an array of 1 or 2 scans for the given step.
        // If a step only has a lower, or only has an upper, we only have one element
        // After we compute these steps, we can fill in any missing scans that we need.
        const previewSteps = treatmentPlan.steps.map(step =>
            _.compact([stepToPayload(step, 'upper'), stepToPayload(step, 'lower')]),
        );
        const flattenedSteps = _.flatten(previewSteps);

        // and now we backfill any missing arches
        // We skip index 0 because it's a redundant scan.
        return _.flatten(
            previewSteps.map(steps => (steps.length === 2 ? steps : fixMissingArches(flattenedSteps, steps[0]))),
        );
    }, [treatmentPlan]);

    const [previous, setPrevious] = React.useState<FirebasePreviewFileMultiWithMetadata[]>([]);
    const firebase = useFirebaseStorage();

    const cb = useAsyncCallback(async (paths: FirebasePreviewFileMultiWithMetadata[]) => {
        // Generate download links via firebase, this will be downloaded in the second pass.
        const scanUrls = await Promise.all(
            paths.map(async path => ({
                name: path.name,
                source: await getFirebaseDownloadUrl(firebase, path.source),
                isUpper: path.isUpper,
                step: path.step,
            })),
        );

        // And now we can actually download the scan's contents, and do post-processing.
        const payloads = await Promise.all(
            scanUrls.map<Promise<ModelPayloadWithMetadata | undefined>>(async scan => {
                try {
                    const res = await axios.get<ArrayBuffer>(scan.source, { responseType: 'arraybuffer' });
                    const model = await loadModelFromBuffer({ buffer: res.data, filePath: scan.source });

                    return {
                        model,
                        path: scan.name,
                        name: scan.name,
                        type: LabsGqlOrderDesignScanType.Cad,
                        isUpper: scan.isUpper,
                        step: scan.step,
                    };
                } catch (_err: any) {
                    return undefined;
                }
            }),
        );

        // And now we reduce down to our individual modelviews
        const steps = _.groupBy(payloads, payload => payload?.step);

        return _.compact(
            Object.entries(steps).map<FullJawPayloadView | SingletonModelPayloadView | undefined>(
                ([stepNumber, payloads]) => {
                    const upper = payloads.find(payload => payload?.isUpper);
                    const lower = payloads.find(payload => !payload?.isUpper);

                    if (!stepNumber) {
                        return undefined;
                    }

                    const upperOrLower = upper ?? lower;
                    if (upperOrLower && (!lower || !upper)) {
                        return {
                            kind: ModelPayloadViewKind.SingletonPayloadView,
                            payload: upperOrLower,
                        };
                    }

                    if (!lower || !upper) {
                        return undefined;
                    }

                    return {
                        upper,
                        lower,
                        kind: ModelPayloadViewKind.FullJaw,
                        step: _.parseInt(stepNumber),
                    };
                },
            ),
        );
    });

    React.useEffect(() => {
        if (!_.isEqual(steps, previous)) {
            setPrevious(steps);
            void cb.execute(steps).then();
        }
    }, [cb, treatmentPlan, previous, steps]);

    return cb;
}
