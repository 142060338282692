import type { MUITableProps } from './components/MUITableRoot';
import { MUITableRoot as MUITable } from './components/MUITableRoot';
import type { Column as OGColumn, MaterialTableProps } from './material-table-types';
import type { Column, MUIDataObj } from './types';
import React from 'react';

function columnsAdapter<R extends MUIDataObj>(ogColumn: OGColumn<R>, colIndex: number): Column<R> {
    const field = ogColumn.field as string;
    return {
        render:
            ogColumn.render || !field
                ? row => {
                      if (ogColumn.render) {
                          return ogColumn.render(row, 'row');
                      }
                      return null;
                  }
                : field,
        hidden: ogColumn.hidden,
        name: typeof ogColumn.title === 'string' ? ogColumn.title : (ogColumn.field as string) || colIndex.toString(),
        download: !!ogColumn.export,
        filter: ogColumn.filtering !== false,
        filterOptions: {
            exact: !!ogColumn.lookup,
            defaultOpts: ogColumn.lookup ? Object.keys(ogColumn.lookup) : undefined,
            sortFilterList: true,
            type: !!ogColumn.lookup ? 'multiselect' : 'search',
        },
        sort: ogColumn.sorting,
        title: ogColumn.title,
        type: ogColumn.type,
    };
}

interface LimitedOgProps<R extends MUIDataObj> extends MaterialTableProps<R> {
    title: string;
}

export function materialTablePropsAdapter<R extends MUIDataObj>(
    ogProps: LimitedOgProps<R>,
    newProps?: Partial<MUITableProps<R>>,
): MUITableProps<R> {
    return {
        columns: ogProps.columns.map(columnsAdapter),
        title: ogProps.title || '',
        data: ogProps.data as R[],
        loading: ogProps.isLoading,
        ...newProps,
        displayOptions: {
            elevation: 0,
            download: ogProps.options?.exportButton,
            filter: ogProps.options?.filtering,
            filterValues: true,
            viewColumns: ogProps.options?.columnsButton,
            search: ogProps.options?.search,
            fixedSearch: true,
            ...newProps?.displayOptions,
        },
        rowOptions: {
            selectable: ogProps.options?.selection,
            setRowStyle: ogProps.options?.rowStyle
                ? (row, index) => {
                      if (typeof ogProps.options?.rowStyle === 'function') {
                          return ogProps.options?.rowStyle(row, index, 0);
                      }
                      return ogProps.options?.rowStyle;
                  }
                : undefined,
            ...newProps?.rowOptions,
        },
        paginationOptions: {
            disable: false,
            ...newProps?.paginationOptions,
        },
    };
}

interface MaterialTableAdapterProps<R extends MUIDataObj> extends LimitedOgProps<R> {
    newProps?: Partial<MUITableProps<R>>;
}

export function MaterialTableAdapter<R extends MUIDataObj>(props: MaterialTableAdapterProps<R>) {
    const { newProps, ...ogProps } = props;
    const calculatedProps = React.useMemo(() => {
        return materialTablePropsAdapter(ogProps, newProps);
    }, [newProps, ogProps]);
    return <MUITable {...calculatedProps} />;
}
