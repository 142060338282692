import type { DesignQcRubricCategory, DesignQcRubricSubCategoryEntry } from '../configs/DesignQc.config.types';
import { useDesignQcDispatch } from './DesignQc.context';
import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'design-qc';

type SetRubricNavigationPayload = {
    category: DesignQcRubricCategory;

    // If entry is not provided, we will find the first incomplete step
    entry: DesignQcRubricSubCategoryEntry | null;

    currentTime: number;
    autoScroll: boolean;
};

type MarkSubcategoryAcceptablePayload = {
    which: 'ideal' | 'acceptable';

    currentTime: number;
    note: string;
};

type ToggleMarkSubcategoryBadPayWorkflowPayload = {
    isEnabled: boolean;
};

type ToggleMarkSubcategoryAcceptableWorkflowPayload = {
    isEnabled: boolean;
};

type MarkSubcategoryBadPayload = {
    reasons: string[];

    affectedUnns: number[];
    currentTime: number;
    note: string;
};

type AddPendingMarkupPayload = {
    url: string;
};

type DeletePendingMarkupPayload = {
    url: string;
};

export const DesignQcActions = {
    SET_RUBRIC_NAVIGATION: createSyncAction<SetRubricNavigationPayload, [SetRubricNavigationPayload]>(
        `${PREFIX}/SET_RUBRIC_NAVIGATION`,
    ),
    MARK_SUBCATEGORY_ACCEPTABLE: createSyncAction<MarkSubcategoryAcceptablePayload, [MarkSubcategoryAcceptablePayload]>(
        `${PREFIX}/MARK_SUBCATEGORY_ACCEPTABLE`,
    ),
    TOGGLE_MARK_SUBCATEGORY_BAD_WORKFLOW: createSyncAction<
        ToggleMarkSubcategoryBadPayWorkflowPayload,
        [ToggleMarkSubcategoryBadPayWorkflowPayload]
    >(`${PREFIX}/TOGGLE_MARK_SUBCATEGORY_BAD_WORKFLOW`),
    TOGGLE_MARK_SUBCATEGORY_ACCEPTABLE_WORKFLOW: createSyncAction<
        ToggleMarkSubcategoryAcceptableWorkflowPayload,
        [ToggleMarkSubcategoryAcceptableWorkflowPayload]
    >(`${PREFIX}/TOGGLE_MARK_SUBCATEGORY_ACCEPTABLE_WORKFLOW`),
    MARK_SUBCATEGORY_BAD: createSyncAction<MarkSubcategoryBadPayload, [MarkSubcategoryBadPayload]>(
        `${PREFIX}/MARK_SUBCATEGORY_BAD`,
    ),
    ADD_PENDING_MARKUP_URL: createSyncAction<AddPendingMarkupPayload, [AddPendingMarkupPayload]>(
        `${PREFIX}/ADD_PENDING_MARKUP_URL`,
    ),
    DELETE_PENDING_MARKUP_URL: createSyncAction<DeletePendingMarkupPayload, [DeletePendingMarkupPayload]>(
        `${PREFIX}/DELETE_PENDING_MARKUP_URL`,
    ),
};

export const useDesignQcAction = generateUseActionHook<typeof DesignQcActions>(DesignQcActions, useDesignQcDispatch);
