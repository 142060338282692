import { scanbodiesToManufacturers } from '../../Fields/ImplantScanbody/ScanbodiesRoot';
import type { LabsGqlScanbodyFragment } from '@orthly/graphql-operations';
import { useLinkScanbodyRequestFulfillmentMutation, useGetScanbodiesQuery } from '@orthly/graphql-react';
import type { LabsGqlLinkScanbodyRequestFulfillmentCommand } from '@orthly/graphql-schema';
import { LabsGqlShippingCarriersEnum } from '@orthly/graphql-schema';
import { ShippingUtils } from '@orthly/shared-types';
import { useChangeSubmissionFn, RootActionDialog, QuickForm } from '@orthly/ui';
import { Text, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const scanbodyToOption = (prioritized: boolean) => {
    return ({ id, manufacturer, name }: LabsGqlScanbodyFragment, index: number) => {
        return {
            value: id,
            label: prioritized ? `#${index + 1} ${manufacturer} ${name}` : `${manufacturer} ${name}`,
        };
    };
};

interface LinkScanbodyRequestFulfillmentFormData {
    fulfillment_order_id?: string;
    shipment_tracking_number?: string;
    shipment_carrier?: LabsGqlShippingCarriersEnum;
    linked_scanbody_id?: string;
    unlinked_scanbody_id?: string;
    new_scanbody?: {
        name?: string;
        manufacturer?: string;
        sku?: string;
    };
    is_authentic?: boolean;
}

interface LinkScanbodyRequestFulfillmentFormProps {
    scanbody_request_id: string;
    linked_scanbodies: LabsGqlScanbodyFragment[];
    submit: (data: LabsGqlLinkScanbodyRequestFulfillmentCommand) => void;
    unknownImplantType: boolean;
    fulfillment_order_id?: string;
}

// this is used as a work around because quick form forces select fields' values to be strings
const NULL_PLACEHOLDER = `__NULL_PLACEHOLDER__`;

const LinkScanbodyRequestFulfillmentForm: React.VFC<LinkScanbodyRequestFulfillmentFormProps> = props => {
    const { submit, scanbody_request_id, linked_scanbodies, unknownImplantType, fulfillment_order_id } = props;
    // fetch all our possible scanbody options, and manufacturers in case we add a new scanbody
    const { data: all_scanbodies_data } = useGetScanbodiesQuery({ variables: { withDeleted: true } });
    const all_scanbodies = React.useMemo(() => all_scanbodies_data?.getScanbodies ?? [], [all_scanbodies_data]);
    const scanbody_manufacturers = React.useMemo(() => scanbodiesToManufacturers(all_scanbodies), [all_scanbodies]);
    const unlinked_scanbodies = React.useMemo(
        () => _.differenceBy(all_scanbodies, linked_scanbodies, s => s.id),
        [all_scanbodies, linked_scanbodies],
    );
    const [form_data, set_form_data] = React.useState<LinkScanbodyRequestFulfillmentFormData>({
        fulfillment_order_id: fulfillment_order_id ?? '',
        shipment_tracking_number: ``,
        shipment_carrier: undefined,
        linked_scanbody_id: ``,
        new_scanbody: {},
    });
    const show_unlinked_scanbodies = form_data.linked_scanbody_id === NULL_PLACEHOLDER;
    const show_create_new_scanbody =
        (show_unlinked_scanbodies || unknownImplantType) && form_data.unlinked_scanbody_id === NULL_PLACEHOLDER;
    return (
        <QuickForm<LinkScanbodyRequestFulfillmentFormData>
            fields={{
                fulfillment_order_id: { label: `Fulfillment Order ID`, type: `text` },
                shipment_tracking_number: { label: `Shipment Tracking Number`, type: `text` },
                shipment_carrier: {
                    label: `Shipment Carrier`,
                    type: `select`,
                    options: Object.entries(LabsGqlShippingCarriersEnum).map(([label, value]) => ({
                        value,
                        label: ShippingUtils.getCarrierDisplayText(label),
                    })),
                },
                linked_scanbody_id: {
                    type: `select`,
                    options: [
                        ...linked_scanbodies.map(scanbodyToOption(true)),
                        { value: NULL_PLACEHOLDER, label: `(link a different scan body)` },
                    ],
                    optional: unknownImplantType,
                    hidden: unknownImplantType,
                },
                unlinked_scanbody_id: {
                    type: `select`,
                    options: [
                        ...unlinked_scanbodies.map(scanbodyToOption(false)),
                        { value: NULL_PLACEHOLDER, label: `(add a new scan body)` },
                    ],
                    optional: !(show_unlinked_scanbodies || unknownImplantType),
                    hidden: !(show_unlinked_scanbodies || unknownImplantType),
                },
                new_scanbody: {
                    type: `nested`,
                    fields: {
                        name: { type: `text`, optional: !show_create_new_scanbody },
                        manufacturer: {
                            type: `autocomplete`,
                            options: scanbody_manufacturers,
                            freeSolo: true,
                            optional: !show_create_new_scanbody,
                        },
                        sku: { type: `text`, optional: !show_create_new_scanbody },
                    },
                    hidden: !show_create_new_scanbody,
                    optional: !show_create_new_scanbody,
                },
                is_authentic: {
                    type: `boolean`,
                    optional: !show_unlinked_scanbodies && !show_create_new_scanbody,
                    hidden: (!show_unlinked_scanbodies && !show_create_new_scanbody) || unknownImplantType,
                },
            }}
            initialValues={form_data}
            onChange={set_form_data}
            onSubmit={data => {
                const {
                    fulfillment_order_id = ``,
                    shipment_tracking_number = ``,
                    shipment_carrier = null,
                    is_authentic = false,
                    linked_scanbody_id = NULL_PLACEHOLDER,
                    unlinked_scanbody_id = NULL_PLACEHOLDER,
                    new_scanbody,
                } = data;
                const vars = { scanbody_request_id, fulfillment_order_id, shipment_tracking_number, shipment_carrier };
                // case 1 simply fulfilling with a pre-linked scanbody
                if (!!linked_scanbody_id && linked_scanbody_id !== NULL_PLACEHOLDER) {
                    const scanbody_id = linked_scanbody_id;
                    return submit({ ...vars, linked_scanbody: { scanbody_id } });
                }
                // case 2 fulfilling with an existing scanbody that is not linked
                if (!!unlinked_scanbody_id && unlinked_scanbody_id !== NULL_PLACEHOLDER) {
                    const scanbody_id = unlinked_scanbody_id;
                    return submit({ ...vars, unlinked_scanbody: { scanbody_id, is_authentic } });
                }
                // case 3 fulfilling with a new scanbody that needs to be linked
                if (new_scanbody) {
                    const { name = ``, manufacturer = ``, sku = `` } = new_scanbody;
                    return submit({ ...vars, new_scanbody: { name, manufacturer, sku, is_authentic } });
                }
            }}
        />
    );
};

interface AddFulfillmentIDDialogProps {
    open: boolean;
    setOpen: (val: boolean) => void;
    scanbody_order_id: string;
    linked_scanbodies: LabsGqlScanbodyFragment[];
    unknownImplantType: boolean;
    fulfillment_order_id?: string;
    refetch: () => void;
}

export const LinkScanbodyRequestFulfillmentAction: React.VFC<AddFulfillmentIDDialogProps> = props => {
    const { open, setOpen, scanbody_order_id, fulfillment_order_id, linked_scanbodies, refetch, unknownImplantType } =
        props;
    const [rawSubmit] = useLinkScanbodyRequestFulfillmentMutation();
    const { submit, submitting } = useChangeSubmissionFn(rawSubmit, {
        closeOnComplete: true,
        onSuccess: () => {
            setOpen(false);
            refetch();
        },
        successMessage: () => ['Order marked as fulfilled!', {}],
    });
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Fulfillment Order ID'}
            CustomButton={() => null}
            content={
                <>
                    <LinkScanbodyRequestFulfillmentForm
                        submit={data => submit({ variables: { data } })}
                        linked_scanbodies={linked_scanbodies}
                        unknownImplantType={unknownImplantType}
                        scanbody_request_id={scanbody_order_id}
                        fulfillment_order_id={fulfillment_order_id}
                    />
                    <Grid style={{ textAlign: 'center', marginTop: 8 }}>
                        <Text variant={'caption'} color={'DARK_GRAY'}>
                            An email will be automatically sent to the practice containing shipping information.
                        </Text>
                    </Grid>
                </>
            }
        />
    );
};
