import { usePartnersData } from '../../../../utils/usePartners';
import { ManualInvoiceBulkUploader } from './ManualInvoiceBulkUploader';
import { ManualInvoiceDialogSubtitle } from './ManualInvoiceDialogSubtitle';
import type { ManualInvoiceItemCsvRow } from './utils';
import { RootActionDialog } from '@orthly/ui';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    dialogButton: {
        width: 'fit-content',
        padding: '8px 8px 8px 16px',
    },
    dialogContent: {
        padding: '0px 40px 40px 40px',
        borderTop: 'none',
        maxHeight: 500,
        overflow: 'hidden',
    },
}));

export const ManualInvoiceBulkUploadModal: React.VFC = () => {
    const classes = useStyles();
    // We need to query the partners data again to conform to the ComponentType<{}> type requiremnt for
    // the custom toolbar in the AdminBillingTableRoot
    const { data: partnerNames } = usePartnersData();
    const partnerData = partnerNames?.listPracticeNames ?? [];
    const [openBulkImport, setOpenBulkImport] = React.useState<boolean>(false);
    const [inputRows, setInputRows] = React.useState<ManualInvoiceItemCsvRow[]>([]);
    const invoiceItemsHaveBeenUploaded = inputRows.length > 0;
    return (
        <Grid container justifyContent={'flex-end'}>
            <RootActionDialog
                title={invoiceItemsHaveBeenUploaded ? 'Confirm manual invoice items' : 'Upload manual invoice items'}
                subtitle={<ManualInvoiceDialogSubtitle invoiceItemsHaveBeenUploaded={invoiceItemsHaveBeenUploaded} />}
                showCloseButton
                onClose={() => {
                    setInputRows([]);
                    setOpenBulkImport(false);
                }}
                buttonText={'Upload manual invoice items'}
                buttonProps={{
                    endIcon: 'AddIcon',
                    className: classes.dialogButton,
                }}
                loading={false}
                open={openBulkImport}
                maxWidth={invoiceItemsHaveBeenUploaded ? 'md' : 'sm'}
                contentProps={{
                    className: classes.dialogContent,
                }}
                setOpen={setOpenBulkImport}
                content={
                    <ManualInvoiceBulkUploader
                        partnerData={partnerData}
                        invoiceItemsHaveBeenUploaded={invoiceItemsHaveBeenUploaded}
                        inputRows={inputRows}
                        setInputRows={setInputRows}
                        setOpenBulkImport={setOpenBulkImport}
                    />
                }
            />
        </Grid>
    );
};
