import type { MenuItemProps } from './OrderDetailToolbarFiles.types';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LoadBlockerLoader, useRootActionCommand } from '@orthly/ui';
import { MenuItem } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';

const SkipAutomateReviewTaskMutation = graphql(`
    mutation SkipAutomateReviewTask($order_id: String!) {
        skipAutomateReviewTask(order_id: $order_id)
    }
`);

export const SkipAutomateReviewTaskMenuItem: React.FC<MenuItemProps> = props => {
    const { order } = props;
    const { submit, submitting } = useRootActionCommand(useMutation(SkipAutomateReviewTaskMutation), {
        successMessage: 'Successfully skipped Automate Review task!',
        onSuccess: () => {
            void props.refetchOrder();
            props.onClose();
        },
    });

    const { value: enableSkipAutomateReviewTask } = useFeatureFlag('enableSkipAutomateReviewTask');

    if (!enableSkipAutomateReviewTask) {
        return null;
    }

    return (
        <>
            <MenuItem
                disabled={submitting}
                onClick={() => {
                    void submit({ order_id: order.id });
                }}
            >
                Skip Automate Review Task
            </MenuItem>
            <LoadBlockerLoader blocking={submitting} />
        </>
    );
};
