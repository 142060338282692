import { useAlignerPlansDetails } from '../../Aligners/AlignerPlans';
import { aligner_treatment_plan_version_title } from '../AlignerTreatmentPlanTitle.graphql';
import {
    ApproveAlignerFabricationAcknowledging,
    ApproveAlignerFabricationApproving,
    ApproveAlignerFabricationConfirming,
} from './ApproveAlignerFabricationScreens.graphql';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { useApproveAlignerFabricationMutation } from '@orthly/graphql-react';
import type { LabsGqlAlignerPlan } from '@orthly/graphql-schema';
import { RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import type { ButtonProps } from '@orthly/ui-primitives';
import React from 'react';

type RequestRevisionState = {
    open: boolean;
    dandy_plan?: LabsGqlAlignerPlan;
    screen: `APPROVING` | `CONFIRMING` | `ACKNOWLEDGING`;
};

type RequestRevisionAction =
    | { type: `SET_OPEN`; open: boolean }
    | { type: `SET_PLAN`; dandy_plan: LabsGqlAlignerPlan }
    | { type: `APPROVE` }
    | { type: `BACK` }
    | { type: `SUCCESS` };

const VeneerApproveAlignerFabrication_Fragment = graphql(`
    fragment VeneerApproveAlignerFabrication_Fragment on DesignOrderAlignerTreatmentPlanDTO {
        created_at
        version_number_practice
        ...VeneerAlignerTreatmentPlanTitleVersionSelector_Fragment
        ...VeneerAlignerFabricationScreens_Fragment
    }
`);

export const ApproveAlignerFabrication: React.VFC<{
    patient_name: string;
    order_id: string;
    treatment_plan_fragment: FragmentType<typeof VeneerApproveAlignerFabrication_Fragment>;
    refetch: () => Promise<unknown>;
    button_props?: Partial<ButtonProps>;
    is_refinement: boolean;
}> = ({ patient_name, order_id, treatment_plan_fragment, refetch, button_props, is_refinement }) => {
    const treatment_plan = getFragmentData(VeneerApproveAlignerFabrication_Fragment, treatment_plan_fragment);

    const plans_details = useAlignerPlansDetails();
    const [{ open, dandy_plan, screen }, dispatch] = React.useReducer(
        (state: RequestRevisionState, action: RequestRevisionAction): RequestRevisionState => {
            switch (action.type) {
                case `SET_OPEN`: {
                    const { open } = action;
                    return open
                        ? { open: true, dandy_plan: undefined, screen: `APPROVING` }
                        : { ...state, open: false };
                }
                case `SET_PLAN`: {
                    const { dandy_plan } = action;
                    return { ...state, dandy_plan };
                }
                case `APPROVE`: {
                    return { ...state, screen: `CONFIRMING` };
                }
                case `BACK`: {
                    return { ...state, screen: `APPROVING` };
                }
                case `SUCCESS`: {
                    return { ...state, screen: `ACKNOWLEDGING` };
                }
            }
        },
        { open: false, dandy_plan: undefined, screen: `APPROVING` },
    );
    const [raw_approve] = useApproveAlignerFabricationMutation();
    const { submit: approve, submitting: approving } = useChangeSubmissionFn(raw_approve, {
        successMessage: () => [`Aligner fabrication approved!`, {}],
        onSuccess: async () => {
            dispatch({ type: `SUCCESS` });
        },
    });
    const from_date = treatment_plan.created_at;
    const version = aligner_treatment_plan_version_title(treatment_plan);
    const vSuffix = `V${treatment_plan.version_number_practice}`;
    const buttonText = (is_refinement ? `Approve plan ${vSuffix}` : `Select pricing & approve plan ${vSuffix}`).trim();
    return (
        <RootActionDialog
            loading={approving}
            open={open}
            setOpen={async open => {
                // this is a hack abusing root action dialog
                // normally i'd return a stock button separately but this one is custom
                // and i don't want to write all the button styles twice
                if (is_refinement) {
                    await approve({ variables: { order_id, from_date, dandy_plan: null } });
                    await refetch();
                } else {
                    dispatch({ open, type: `SET_OPEN` });
                }
            }}
            title={<></>}
            titleProps={{ style: { position: `absolute`, border: 0, background: `none` } }}
            showCloseButton={screen !== `ACKNOWLEDGING`}
            buttonText={buttonText}
            buttonProps={{
                endIcon: 'ChevronRight',
                fullWidth: true,
                variant: `secondary`,
                ...button_props,
            }}
            contentProps={{ style: { padding: 40, border: 0 } }}
            dialogProps={{ maxWidth: screen === `ACKNOWLEDGING` ? `sm` : `md` }}
            content={
                // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                // eslint-disable-next-line no-nested-ternary
                screen === `APPROVING` || !dandy_plan ? (
                    <ApproveAlignerFabricationApproving
                        treatment_plan_fragment={treatment_plan}
                        patient_name={patient_name}
                        version={version}
                        approve={() => dispatch({ type: `APPROVE` })}
                        close={() => dispatch({ type: `SET_OPEN`, open: false })}
                        plans_details={plans_details}
                        selected_plan={dandy_plan}
                        select_plan={dandy_plan => dispatch({ dandy_plan, type: `SET_PLAN` })}
                    />
                ) : screen === `CONFIRMING` ? (
                    <ApproveAlignerFabricationConfirming
                        treatment_plan_fragment={treatment_plan}
                        patient_name={patient_name}
                        version={version}
                        plan_details={plans_details[dandy_plan]}
                        confirm={() => approve({ variables: { order_id, from_date, dandy_plan } })}
                        back={() => dispatch({ type: `BACK` })}
                    />
                ) : (
                    <ApproveAlignerFabricationAcknowledging
                        close={async () => {
                            await refetch();
                            dispatch({ type: `SET_OPEN`, open: false });
                        }}
                    />
                )
            }
        />
    );
};
