import { useRejectTreatmentPlanMutation } from '@orthly/graphql-react';
import { RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import type { ButtonProps } from '@orthly/ui-primitives';
import { Button, FlossPalette, Text, Grid, Link, TextField } from '@orthly/ui-primitives';
import React from 'react';

type RequestRevisionState = {
    open: boolean;
    feedback: string;
    screen: `REQUESTING` | `CONFIRMING` | `ACKNOWLEDGING`;
};

type RequestRevisionAction =
    | { type: `SET_OPEN`; open: boolean }
    | { type: `SET_FEEDBACK`; feedback: string }
    | { type: `SUBMIT` }
    | { type: `BACK` }
    | { type: `SUCCESS` };

export const RequestAlignerRevision: React.FC<{
    order_id: string;
    refetch: () => Promise<unknown>;
    button_props?: Partial<ButtonProps>;
}> = ({ order_id, refetch, button_props }) => {
    const [{ open, feedback, screen }, dispatch] = React.useReducer(
        (state: RequestRevisionState, action: RequestRevisionAction): RequestRevisionState => {
            switch (action.type) {
                case `SET_OPEN`: {
                    const { open } = action;
                    return open ? { open: true, feedback: ``, screen: `REQUESTING` } : { ...state, open: false };
                }
                case `SET_FEEDBACK`: {
                    const { feedback } = action;
                    return { ...state, feedback };
                }
                case `SUBMIT`: {
                    return { ...state, screen: `CONFIRMING` };
                }
                case `BACK`: {
                    return { ...state, screen: `REQUESTING` };
                }
                case `SUCCESS`: {
                    return { ...state, screen: `ACKNOWLEDGING` };
                }
            }
        },
        { open: false, feedback: ``, screen: `REQUESTING` },
    );
    const [raw_reject] = useRejectTreatmentPlanMutation();
    const { submit: reject, submitting: rejecting } = useChangeSubmissionFn(raw_reject, {
        successMessage: () => [`Aligner revision requested!`, {}],
        onSuccess: async () => {
            dispatch({ type: `SUCCESS` });
        },
    });
    return (
        <RootActionDialog
            loading={rejecting}
            open={open}
            setOpen={open => dispatch({ open, type: `SET_OPEN` })}
            title={<></>}
            titleProps={{ style: { display: `none` } }}
            showCloseButton={screen !== `ACKNOWLEDGING`}
            buttonText={`Request Revision`}
            buttonProps={{
                endIcon: 'ChevronRight',
                fullWidth: true,
                variant: `secondary`,
                ...button_props,
            }}
            dialogProps={{ maxWidth: `sm` }}
            content={
                {
                    REQUESTING: (
                        <Grid item container direction={`column`} spacing={4}>
                            <Grid item>
                                <Text variant={`h4`}>Treatment plan revision request</Text>
                            </Grid>
                            <Grid item>
                                <Text variant={`body2`}>
                                    What changes would you like to make to this treatment plan?
                                </Text>
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant={'standard'}
                                    fullWidth
                                    multiline
                                    minRows={5}
                                    value={feedback}
                                    onChange={ev => dispatch({ type: `SET_FEEDBACK`, feedback: ev.target.value })}
                                    placeholder={`Type your revision requests here`}
                                    autoFocus
                                />
                            </Grid>
                            <Grid item container justifyContent={`flex-end`} spacing={2}>
                                <Grid item>
                                    <Button
                                        onClick={() => dispatch({ type: `SET_OPEN`, open: false })}
                                        variant={`secondary`}
                                    >
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button onClick={() => dispatch({ type: `SUBMIT` })} variant={`primary`}>
                                        Submit revision request
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    ),
                    CONFIRMING: (
                        <Grid item container direction={`column`} spacing={4}>
                            <Grid item>
                                <Text variant={`h4`}>Confirm revision request</Text>
                            </Grid>
                            <Grid item>
                                <Text variant={`body2`}>
                                    Please confirm you'd like to continue with the following revision request:
                                </Text>
                            </Grid>
                            <Grid item>
                                <Text
                                    variant={`body2`}
                                    style={{ borderLeft: `2px solid ${FlossPalette.DARK_TAN}`, paddingLeft: 8 }}
                                >
                                    "{feedback}"
                                </Text>
                            </Grid>
                            <Grid item container justifyContent={`flex-end`} spacing={2}>
                                <Grid item>
                                    <Button onClick={() => dispatch({ type: `BACK` })} variant={`secondary`}>
                                        Back
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        onClick={() => reject({ variables: { feedback, order_id } })}
                                        variant={`primary`}
                                    >
                                        Confirm revision request
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    ),
                    ACKNOWLEDGING: (
                        <Grid item container direction={`column`} spacing={4}>
                            <Grid item>
                                <Text variant={`h4`}>We received your treatment plan revision request!</Text>
                            </Grid>
                            <Grid item container direction={`column`} spacing={2}>
                                <Grid item>
                                    <Text variant={`body2`}>
                                        A revised treatment plan will be available within 2 business days.
                                    </Text>
                                </Grid>
                                <Grid item>
                                    <Text variant={`body2`}>
                                        In the meantime, set up your{' '}
                                        <Link
                                            href={`/profile`}
                                            style={{
                                                color: FlossPalette.BURGUNDY,
                                                fontWeight: `bold`,
                                                cursor: `pointer`,
                                            }}
                                        >
                                            notification preferences
                                        </Link>{' '}
                                        to be notified as soon as your revised plan is available.
                                    </Text>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Button
                                    fullWidth
                                    onClick={async () => {
                                        await refetch();
                                        dispatch({ type: `SET_OPEN`, open: false });
                                    }}
                                    variant={`primary`}
                                >
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    ),
                }[screen]
            }
        />
    );
};
