import type { LabsGqlOrder, LabsGqlOrderTimelineItemFragment } from '@orthly/graphql-operations';
import { useTimelineItemsQuery } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus, LabsGqlOrderTimelineEventNameEnum } from '@orthly/graphql-schema';
import { UuidUtils } from '@orthly/runtime-utils';
import { ActionCard } from '@orthly/ui';
import { Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface OnHoldActionCardProps {
    order: LabsGqlOrder;
}

function useOrderCancellationReasons(orderId: string, skip: boolean): string[] {
    const { data } = useTimelineItemsQuery({
        skip: !UuidUtils.isUUID(orderId) || skip,
        variables: { entityId: orderId, entityType: 'order' },
        fetchPolicy: 'cache-first',
    });
    const items = data?.timelineItems ?? [];
    return (
        items
            .filter((i): i is LabsGqlOrderTimelineItemFragment => i.__typename === 'OrderTimelineItem')
            .find(item => item.orderEvent === LabsGqlOrderTimelineEventNameEnum.OrderCancelled)?.subtitle ?? []
    );
}

export const OrderCancelledActionCard: React.FC<OnHoldActionCardProps> = props => {
    const { order } = props;
    const reasons = useOrderCancellationReasons(order.id, order.status !== LabsGqlLabOrderStatus.Cancelled);
    if (order.status !== LabsGqlLabOrderStatus.Cancelled) {
        return null;
    }
    return (
        <ActionCard
            variant={'alert'}
            title={'This order was canceled'}
            subtitle={
                <Grid container>
                    {reasons.map((reason, index) => (
                        <Grid key={index} container>
                            <Text variant={'body2'} color={'DARK_GRAY'}>
                                {reason}
                            </Text>
                        </Grid>
                    ))}
                </Grid>
            }
        />
    );
};
