import { useDisplayOptionsContext, useTranslationOptionsContext } from '../../contexts/TableOptionsContext';
import { useMuiTableSelector } from '../../state';
import { useTableStateAction } from '../../state/action-hooks';
import type { Column } from '../../types';
import type { Theme } from '@orthly/ui-primitives';
import { createStyles, makeStyles, TableCell, TableSortLabel, Tooltip, Icon } from '@orthly/ui-primitives';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            root: {
                '&:hover .MuiTableSortLabel-icon': {
                    color: theme.palette.action,
                    opacity: 0.8,
                },
            } as any,
            fixedHeader: {
                position: 'sticky' as 'sticky',
                top: '0px',
                left: '0px',
                zIndex: 100,
                backgroundColor: theme.palette.background.paper,
                border: 'none',
                '&::after': {
                    content: `""`,
                    zIndex: 400,
                    position: 'absolute' as 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                },
            },
            tooltip: {
                cursor: 'pointer',
            },
            mypopper: {
                '&[data-x-out-of-boundaries]': {
                    display: 'none',
                },
            },
            data: {
                display: 'inline-block',
            },
            sortAction: {
                display: 'inline-block',
                verticalAlign: 'top',
                cursor: 'pointer',
                paddingLeft: '4px',
                height: '10px',
            },
            sortActive: {
                color: theme.palette.text.primary,
            },
            toolButton: {
                outline: 'none',
                cursor: 'pointer',
                display: 'inline-flex' as 'inline-flex',
                height: 'auto',
            },
        }),
    { name: 'MUITableHeadCell' },
);

export interface MUITableHeadCellProps {
    column: Column<any>;
    index: number;
    children: any;
}

export const MUITableDetailPanelHeadCell: React.FC = () => {
    const classes = useStyles();
    const displayOpts = useDisplayOptionsContext();
    const cellClass = classNames({
        [classes.root]: true,
        [classes.fixedHeader]: displayOpts.fixedHeader,
    });
    return <TableCell className={cellClass} scope={'col'} />;
};

export const MUITableHeadCell = (props: MUITableHeadCellProps) => {
    const classes = useStyles();
    const { children, column } = props;
    const sortColumn = useMuiTableSelector(s => s.sortColumn);
    const toggleSort = useTableStateAction('toggleSort');
    const displayOpts = useDisplayOptionsContext();
    const translations = useTranslationOptionsContext();
    const handleSortClick = () => toggleSort(column.name);

    const sort = column.sort !== false && displayOpts.sort !== false;
    const sortActive = sort && sortColumn?.columnName === column.name;
    const direction: 'asc' | 'desc' | undefined = sortColumn?.asc ? 'asc' : 'desc';

    const cellClass = classNames({
        [classes.root]: true,
        [classes.fixedHeader]: displayOpts.fixedHeader,
    });

    return (
        <TableCell
            className={cellClass}
            scope={'col'}
            sortDirection={sortActive && direction}
            data-column={column.name}
        >
            {sort ? (
                <span
                    role={'button'}
                    onKeyUp={handleSortClick}
                    onClick={handleSortClick}
                    className={classes.toolButton}
                    tabIndex={0}
                >
                    <div
                        className={classNames({
                            [classes.data]: true,
                            [classes.sortActive]: sortActive,
                        })}
                    >
                        {children}
                    </div>
                    <Tooltip
                        title={translations.body.toolTip || 'Table Header'}
                        placement={'bottom-end'}
                        classes={{
                            tooltip: classes.tooltip,
                            popper: classes.mypopper,
                        }}
                        enterDelay={300}
                    >
                        <div className={classes.sortAction}>
                            <TableSortLabel
                                className={'sort-label'}
                                active={sortActive}
                                direction={sortActive ? direction : undefined}
                                IconComponent={props => <Icon icon={'ArrowDropDown'} {...props} />}
                            />
                        </div>
                    </Tooltip>
                </span>
            ) : (
                children
            )}
        </TableCell>
    );
};
