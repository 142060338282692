import { AnalyticsClient } from '../../../../../../utils/analyticsClient';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LoadBlocker, useRootActionCommand } from '@orthly/ui';
import { Button, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

interface ReRunManufacturingFileProcessingButtonProps {
    orderId: string;
    onSubmit?: (id: string) => void;
}

export const BulkRunManufacturingFileProcessingMutation = graphql(`
    mutation BulkRunManufacturingFileProcessing($order_ids: [String!]!) {
        queueManufacturingFileProcessing(labOrderIds: $order_ids)
    }
`);

export const ReRunManufacturingFileProcessingButton: React.FC<ReRunManufacturingFileProcessingButtonProps> = props => {
    const { orderId, onSubmit } = props;

    const rerunManufacturingFileProcessing = useMutation(BulkRunManufacturingFileProcessingMutation);
    const { submit, submitting } = useRootActionCommand(rerunManufacturingFileProcessing, {
        successMessage: 'Manufacturing file processing scheduled:  Check back soon',
    });

    return (
        <Tooltip title={'Rerun Manufacturing File Processing'}>
            <Button
                onClick={() => {
                    AnalyticsClient.track('All - Portal - Order Files Menu Used', {
                        $groups: { order: orderId },
                        action: 'rerun_manufacturing_file_processing',
                    });
                    // Intentional so you can find the temporal revision ID which is not
                    // easily accessible from any front end page.
                    console.log(`Scheduling manufacturing-file-processing-${orderId}`);
                    void submit({
                        order_ids: [orderId],
                    });
                    onSubmit?.(orderId);
                }}
                variant={'primary'}
                disabled={submitting}
            >
                <LoadBlocker blocking={submitting}>Generate Files</LoadBlocker>
            </Button>
        </Tooltip>
    );
};
