import { OrderList } from './OrderList';
import { useInFlightOrders } from './queryHooks';
import React from 'react';

interface InFlightOrdersProps {
    practiceId: string;
}

export const InFlightOrdersView: React.FC<InFlightOrdersProps> = props => {
    const { practiceId } = props;
    const { ids: orderIds, loading } = useInFlightOrders({ practiceId });

    return <OrderList loading={loading} orderIds={orderIds} />;
};
