import { OrderDetailSidebarLayout } from '../../../../components/OrderDetailSidebar/OrderDetailSidebarLayout';
import type { OrderDetailSidebarTabPieces } from './OrderDetail.types';
import { useRegisterHotKeys } from '@orthly/dentin';
import { TabWithBadge } from '@orthly/ui';
import { Tab, Tabs, Grid } from '@orthly/ui-primitives';
import React from 'react';

const RegisterHotkey: React.FC<{
    hotKey: string;
    description: string;
    category: string;
    action: () => void;
}> = ({ hotKey, description, category, action }) => {
    useRegisterHotKeys({ description, category, action, key: hotKey });
    return null;
};

const OrderDetailPageSidebarTab: React.FC<{
    hotKey: string;
    registerHotKey?: boolean;
    name: string;
    badgeCount?: number;
    tabIndex: number;
    setActiveTabIndex: (tabIndex: number) => void;
}> = ({ hotKey, registerHotKey, name, badgeCount, tabIndex, setActiveTabIndex }) => {
    const switchToTab = React.useCallback(() => setActiveTabIndex(tabIndex), [setActiveTabIndex, tabIndex]);
    return (
        <>
            {registerHotKey && (
                <RegisterHotkey
                    hotKey={hotKey}
                    description={`Switch to ${name} tab`}
                    category={'Tabs'}
                    action={switchToTab}
                />
            )}
            <Tab value={tabIndex} onClick={switchToTab} label={<TabWithBadge label={name} count={badgeCount} />} />
        </>
    );
};

export const OrderDetailPageSidebar: React.FC<{
    tabs: OrderDetailSidebarTabPieces[];
    registerTabHotKeys?: boolean;
    keepOffscreenTabsMounted?: boolean;
}> = props => {
    const { tabs, registerTabHotKeys } = props;

    const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);
    const targetActiveSidebar: OrderDetailSidebarTabPieces | undefined = tabs[activeTabIndex] ?? tabs[0];

    if (!targetActiveSidebar) {
        return null;
    }
    const activeSidebar = targetActiveSidebar;

    const body = props.keepOffscreenTabsMounted ? (
        // if we want to keep all tabs mounted, put the offscreen tabs in hidden divs. To keep it easy, they'll all stay
        // wrapped in a div, which means the active tab will have `display: contents` to not mess up css layout.
        <>
            {tabs.map((tab, i) => (
                <div key={i} style={{ display: tab === activeSidebar ? 'contents' : 'none' }}>
                    {tab.sidebarBody}
                </div>
            ))}
        </>
    ) : (
        // if we're not keeping offscreen tabs mounted, the body is just the body of the active tab
        activeSidebar.sidebarBody
    );

    return (
        <OrderDetailSidebarLayout
            resizable
            position={'right'}
            toolbarContent={
                <Grid container alignItems={'center'} justifyContent={'space-between'} direction={'row'}>
                    <Tabs indicatorColor={'secondary'} textColor={'inherit'} value={activeTabIndex}>
                        {tabs.map((tabPieces, i) => (
                            <OrderDetailPageSidebarTab
                                key={tabPieces.name}
                                hotKey={String(3 + i)}
                                registerHotKey={registerTabHotKeys}
                                tabIndex={i}
                                setActiveTabIndex={setActiveTabIndex}
                                name={tabPieces.name}
                                badgeCount={tabPieces.badgeCount}
                            />
                        ))}
                    </Tabs>
                    {activeSidebar.headerExtras}
                </Grid>
            }
        >
            {body}
        </OrderDetailSidebarLayout>
    );
};
