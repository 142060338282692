import { useMutation } from '@apollo/client';
import { LabsUtilsBase } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { useAddressesForPartner } from '@orthly/graphql-react';
import { useHasCapability } from '@orthly/session-client';
import { QuickForm, RootActionDialog, useRootActionCommand, Icon } from '@orthly/ui';
import { IconButton } from '@orthly/ui-primitives';
import React from 'react';

const AdminChangDeliveryAddressActionLabSalesOrder_Fragment = graphql(`
    fragment AdminChangDeliveryAddressActionLabSalesOrder_Fragment on LabSalesOrderDTO {
        id
        practice_id
        delivery_address_id
        action_eligibility {
            can_change_delivery_address
        }
    }
`);

const AdminChangeDeliveryAddressActionChangeLabSalesOrderDeliveryAddress_Mutation = graphql(`
    mutation AdminChangeDeliveryAddressActionChangeLabSalesOrderDeliveryAddress_Mutation(
        $args: LabSalesOrderChangeDeliveryAddressInput!
    ) {
        changeLabSalesOrderDeliveryAddress(args: $args) {
            id
        }
    }
`);

interface ChangeDeliveryAddressActionProps {
    salesOrder: FragmentType<typeof AdminChangDeliveryAddressActionLabSalesOrder_Fragment>;
    refetchSalesOrder: () => void;
}

export const ChangeDeliveryAddressAction: React.VFC<ChangeDeliveryAddressActionProps> = ({
    salesOrder: salesOrderProp,
    refetchSalesOrder,
}) => {
    const salesOrder = getFragmentData(AdminChangDeliveryAddressActionLabSalesOrder_Fragment, salesOrderProp);

    const [open, setOpen] = React.useState<boolean>(false);

    const { addresses } = useAddressesForPartner(salesOrder.practice_id);

    const changeDeliveryAddressMutation = useMutation(
        AdminChangeDeliveryAddressActionChangeLabSalesOrderDeliveryAddress_Mutation,
    );
    const { submit, submitting } = useRootActionCommand(changeDeliveryAddressMutation, {
        onSuccess: () => {
            refetchSalesOrder();
            setOpen(false);
        },
        successMessage: 'Delivery Address Updated!',
    });

    const hasCapability = useHasCapability('order', 'order.configuration.delivery_address.edit');

    if (!salesOrder.action_eligibility.can_change_delivery_address || !hasCapability) {
        return null;
    }

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Update Order Delivery Address'}
            showCloseButton={true}
            content={
                <QuickForm<{ delivery_address_id: string }>
                    fields={{
                        delivery_address_id: {
                            type: 'select',
                            options: addresses.map(a => ({ value: a.id, label: LabsUtilsBase.addressToString(a) })),
                        },
                    }}
                    initialValues={{
                        delivery_address_id: salesOrder.delivery_address_id,
                    }}
                    onSubmit={result => {
                        void submit({
                            args: {
                                sales_order_id: salesOrder.id,
                                delivery_address_id: result.delivery_address_id,
                            },
                        });
                    }}
                />
            }
            style={{ padding: 0 }}
            buttonText={'Update Address'}
            buttonProps={{ style: { height: '100%' } }}
            CustomButton={({ onClick }) => (
                <IconButton onClick={onClick} style={{ padding: 0 }}>
                    <Icon icon={'PencilOutlinedIcon'} />
                </IconButton>
            )}
        />
    );
};
