import { AnalyticsClient } from '../../../../../../utils/analyticsClient';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { LoadBlocker } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import { useFirebaseFileDownload } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

export const AdminOrderDesignToolbarFileDownloadDesign_Fragment = graphql(`
    fragment AdminOrderDesignToolbarFileDownloadDesign_Fragment on DesignOrderDesignRevisionDTO {
        id
        source_file_zip_path
        design_source
    }
`);

interface OrderDesignToolbarFileDownloadProps {
    order: LabsGqlLabOrderFragment;
    selectedDesignFragment: FragmentType<typeof AdminOrderDesignToolbarFileDownloadDesign_Fragment>;
    isDesignMostRecent: boolean;
}

export const OrderDesignToolbarFileDownload: React.FC<OrderDesignToolbarFileDownloadProps> = ({
    order,
    selectedDesignFragment,
    isDesignMostRecent,
}) => {
    const selectedDesign = getFragmentData(AdminOrderDesignToolbarFileDownloadDesign_Fragment, selectedDesignFragment);

    const filename =
        _.last(selectedDesign.source_file_zip_path.split('/')) ||
        `${order.scan_export?.patient_first_name} ${order.scan_export?.patient_last_name}.zip`;

    const downloader = useFirebaseFileDownload(selectedDesign.source_file_zip_path, filename);

    return (
        <LoadBlocker
            blocking={downloader.loading}
            ContainerProps={{ style: { justifyContent: 'flex-end', width: 'fit-content' } }}
        >
            <Button
                onClick={() => {
                    AnalyticsClient.track('All - Portal - Design Revision Downloaded', {
                        $groups: { order: order.id },
                        displayLocation: 'design_tab',
                        isCurrentDesign: isDesignMostRecent,
                    });
                    void downloader.execute();
                }}
                variant={`ghost`}
                startIcon={'DownloadIcon'}
                style={{ marginRight: 8 }}
            >
                {isDesignMostRecent ? 'Download' : 'Download Old Design'}
            </Button>
        </LoadBlocker>
    );
};
