import { ToolbarButton } from '../BottomToolbar/ToolbarButton';
import type { ToolbarButtonProps } from '../BottomToolbar/ToolbarButton';
import { Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const PaddedText = styled(Text)(() => ({
    padding: '0px 12px',
}));

interface LabeledButtonProps extends Omit<ToolbarButtonProps, 'tooltip'> {
    label: string;
    tooltip?: string;
}

export const LabeledButton: React.VFC<LabeledButtonProps> = ({ label, tooltip, ...buttonProps }) => {
    return (
        <ToolbarButton tooltip={tooltip ?? label} {...buttonProps}>
            <PaddedText variant={'body2'}>{label}</PaddedText>
        </ToolbarButton>
    );
};
