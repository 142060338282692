import type { ColumnFilterMod } from '../../../state/TableStateContext';
import { ColumnFilterModOptions } from '../../../state/TableStateContext';
import { useTableStateAction } from '../../../state/action-hooks';
import type { RenderFilterProp } from './MUITableFilter';
import { useFilterClasses } from './useFilterClasses';
import { SimpleSelect, SimpleDatePicker } from '@orthly/ui';
import { Grid, InputLabel } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

export const MUITableDateFilter: React.FC<RenderFilterProp> = props => {
    const classes = useFilterClasses();
    const { column, currentValues, filterMod } = props;
    const onFilterUpdate = useTableStateAction('updateFilterValues');
    const dateValue = React.useMemo(() => {
        const selectedValue = currentValues[0];
        if (typeof selectedValue !== 'string' || !moment(selectedValue).isValid()) {
            return null;
        }
        return moment(selectedValue).toDate();
    }, [currentValues]);

    return (
        <Grid container className={classes.selectRoot}>
            <Grid container>
                <InputLabel htmlFor={column.name}>{column.title || column.name}</InputLabel>
            </Grid>
            <Grid container item xs={3}>
                <SimpleSelect
                    FormControlProps={{ style: { backgroundColor: 'transparent' }, variant: 'standard' }}
                    variant={'standard'}
                    onChange={value => {
                        const newMod = value as ColumnFilterMod | undefined;
                        if (newMod && newMod !== filterMod) {
                            onFilterUpdate(column.name, currentValues, newMod);
                        }
                    }}
                    options={ColumnFilterModOptions}
                    label={''}
                    value={filterMod ?? ''}
                />
            </Grid>
            <Grid container item xs={9} style={{ paddingLeft: 8 }}>
                <SimpleDatePicker
                    label={typeof column.title === 'string' ? column.title : column.name}
                    value={dateValue}
                    onChange={date => {
                        if (!date) {
                            return onFilterUpdate(column.name, [], filterMod);
                        }
                        const dateMmt = moment(date);
                        if (!dateMmt.isValid()) {
                            return;
                        }
                        onFilterUpdate(column.name, [dateMmt.format('YYYY-MM-DD')], filterMod);
                    }}
                    fullWidth={true}
                />
            </Grid>
        </Grid>
    );
};

MUITableDateFilter.displayName = 'MUITableDateFilter';
