import { Text, FlossPalette, IconButton, styled } from '@orthly/ui-primitives';
import { Link } from 'react-router-dom';

export const Layout = styled('div')({
    display: 'flex',
    height: '100%',
    width: '520px',
    flexDirection: 'column',
    backgroundColor: FlossPalette.WHITE,
    padding: '32px',
    position: 'relative',
});

export const PricingHistoryItemsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

export const HeaderContainer = styled('div')({
    gap: '8px',
    marginBottom: '24px',
});

export const CloseButton = styled(IconButton)({
    position: 'absolute',
    top: '16px',
    right: '16px',
});

export const Row = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
});

export const IndentedRow = styled(Row)({
    paddingLeft: '16px',
});

export const ItemContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    paddingTop: '16px',
});

export const ItemDetailsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginBottom: '16px',
});

export const PricingLink = styled(Link)({
    color: FlossPalette.BLACK,
});

export const AutomationLink = styled(Link)({
    display: 'inline-flex',
    color: FlossPalette.SECONDARY_FOREGROUND,
});

export const PriceConfigContainer = styled('div')({
    backgroundColor: FlossPalette.TAN,
    padding: '8px',
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    marginBottom: '16px',
});

export const Label = styled(Text)({
    maxWidth: '40%',
    textAlign: 'left',
});

export const Value = styled(Text)({
    maxWidth: '60%',
    textAlign: 'right',
});

export const ItemContainer = styled('div')({
    borderLeft: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    paddingLeft: '16px',
});

export const DateContaienr = styled('div')({
    paddingBottom: '8px',
    marginTop: '16px',
});

export const Divider = styled('div')({
    borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
});
