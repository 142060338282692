import type { IPrintableSlipStickerProps } from '../../../../../PrintableSlip/components/shared/PrintableSlipSticker';
import { PrintableSlipSticker } from '../../../../../PrintableSlip/components/shared/PrintableSlipSticker';
import { getOrderPuckType } from './LabSlipPuckType';
import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import { LabsGqlRushRequestStatus } from '@orthly/graphql-schema';
import { TruckIconOutlined, RefabCircleIcon } from '@orthly/ui';
import { CircleIcon } from '@orthly/ui-primitives';
import format from 'date-fns/format';
import React from 'react';

interface ILabSlipOrderStickersProps {
    order: LabsGqlLabOrderForLabSlipFragment;
}
/**
 * Get the information from an order needed to create a list
 * of order-level "stickers" to render to the Order Lab Slip
 * @param alerts The order alerts for which to generate stickers
 * @returns List of sticker properties
 */
function getOrderStickers(order: LabsGqlLabOrderForLabSlipFragment): IPrintableSlipStickerProps[] {
    const banners = [];
    if (order.manufacturer_sla.rush_request?.status === LabsGqlRushRequestStatus.Accepted) {
        const shipByDate = new Date(order.manufacturer_sla.ship_by_date);
        const displayDateString = format(shipByDate, 'MM/dd');
        banners.push({
            solid: true,
            icon: <TruckIconOutlined />,
            text: `Rush Order - Ship By ${displayDateString}`,
        });
    }
    if (order.original_order_id) {
        banners.push({
            solid: true,
            icon: <RefabCircleIcon />,
            text: `${order.is_multi_refab ? `2nd+ Refab` : `Refab`}${
                order.notes_for_refabrication ? ' - See Notes' : ''
            }`,
        });
    }
    const orderStickerType = getOrderPuckType(order);
    if (orderStickerType) {
        banners.push({
            solid: true,
            icon: <CircleIcon />,
            text: orderStickerType,
        });
    }
    return banners;
}

/**
 * Render the list of order-level alert/tag stickers on the Order Lab Slip
 * for a given  order
 * @param props
 * @returns The rendered set of alert/tag stickers for the order
 */
export const LabSlipOrderStickers: React.VFC<ILabSlipOrderStickersProps> = props => {
    const { order } = props;
    const stickers: IPrintableSlipStickerProps[] = getOrderStickers(order);
    return (
        <>
            {stickers.map((sticker, idx) => (
                <PrintableSlipSticker key={idx} solid={sticker.solid} icon={sticker.icon} text={sticker.text} />
            ))}
        </>
    );
};
