import bl_embrasure_BAD from '../../../../assets/images/design-qc/emergence_profile/bl_embrasure_BAD.jpg';
import bl_embrasure_GOOD from '../../../../assets/images/design-qc/emergence_profile/bl_embrasure_GOOD.jpg';
import emergence_profile_emergence_profile_ACCEPTABLE from '../../../../assets/images/design-qc/emergence_profile/emergence_profile_emergence_profile_ACCEPTABLE.png';
import emergence_profile_emergence_profile_BAD from '../../../../assets/images/design-qc/emergence_profile/emergence_profile_emergence_profile_BAD.png';
import emergence_profile_emergence_profile_GOOD from '../../../../assets/images/design-qc/emergence_profile/emergence_profile_emergence_profile_GOOD.png';
import emergence_profile_occlusal_embrasure_ACCEPTABLE from '../../../../assets/images/design-qc/emergence_profile/emergence_profile_occlusal_embrasure_ACCEPTABLE.png';
import emergence_profile_occlusal_embrasure_BAD from '../../../../assets/images/design-qc/emergence_profile/emergence_profile_occlusal_embrasure_BAD.png';
import emergence_profile_occlusal_embrasure_GOOD from '../../../../assets/images/design-qc/emergence_profile/emergence_profile_occlusal_embrasure_GOOD.png';
import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { DEFAULT_SCAN_APPEARANCE, DEFAULT_RESTORATIVE_APPEARANCE } from '@orthly/dentin';

export const ponticEmergenceProfileAndEmbrasuresRubric: DesignQcRubricCategoryEntry = {
    category: 'pontic_emergence_profile_and_embrasures',
    subcategories: [
        {
            category: 'pontic_emergence_profile_and_embrasures',
            name: 'Facial / Lingual Emergence Profile',
            grading: {
                type: 'tertiary',
                bad: 'Straight or concave emergence',
                acceptable: '"Know it when you see it"<br>OR<br>Escalate to Senior',
                ideal: 'Adjacent abutment EP defines ideal EP contours for pontic',
            },
            examples: {
                bad: [emergence_profile_emergence_profile_BAD],
                acceptable: [emergence_profile_emergence_profile_ACCEPTABLE],
                ideal: [emergence_profile_emergence_profile_GOOD],
            },
            description: `Slightly convex emergence from gingival apex to end of cervical 1/3rd - Pontic style takes precedence over mimicking adjacent
                <br><br>Adjacent abutment EP defines ideal EP contours for pontic
                <br><br>TODO -  ""You know it when you see it"" - Know the extremes`,
            selectable_bad_reasons: ['[SURFACE] emergence profile does not appropriately match adjacent'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasBridge,
        },
        {
            category: 'pontic_emergence_profile_and_embrasures',
            name: 'M/D Gingival Embrasures',
            grading: {
                type: 'tertiary',
                bad: 'Straight or concave emergence',
                acceptable: '"Know it when you see it"<br>OR<br>Escalate to Senior',
                ideal: 'Adjacent abutment emburasure defines ideal embrasure contours for pontic',
            },
            description: `Buccal aspect and lingual aspect should look different.
                <br><br>Buccal aspect - slightly convex contour from pontic intaglio to bottom of connector - Should look like natural dentition
                <br>Lingal aspect -  slightly convex contour from pontic intaglio to bottom of connector - Should be more closed compared to natural dentition
                <br><br>Buccal should look like naturally emerging tooth, lingual should be more sealed
                <br><br>TODO -  ""You know it when you see it""`,
            edge_case: 'Finishing line position requires convex design<br>Tissue impingement',
            selectable_bad_reasons: ['[SURFACE] emergence profile does not appropriately match adjacent'],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasBridge,
        },
        {
            category: 'pontic_emergence_profile_and_embrasures',
            name: 'B/L Embrasures',
            grading: {
                type: 'tertiary',
                bad: 'Straight or concave embrasure',
                acceptable: '"Know it when you see it"<br>OR<br>Escalate to Senior',
                ideal: 'Embrasures of pontic matches adjacent abutment embrasures',
            },
            examples: {
                bad: [bl_embrasure_BAD],
                acceptable: [bl_embrasure_GOOD],
                ideal: [bl_embrasure_GOOD],
            },
            description: `Contour from prominence to conntector.
                <br>Buccal embrasures should mimick abutment embrasures - V-Shaped
                <br>Lingual embrasures can be smoothed out for connector strength
                <br><br>Adjacent abutment embrasure defines ideal contours for embrasures of the pontic
                <br><br>TODO -  ""You know it when you see it""`,
            edge_case: 'Wrapping is ok if chase ortho rotation (need to define by how much)',
            selectable_bad_reasons: ['[SURFACE] emergence profile does not appropriately match adjacent'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasBridge,
        },
        {
            category: 'pontic_emergence_profile_and_embrasures',
            name: 'Occlusal Embrasures',
            grading: {
                type: 'tertiary',
                bad: 'Straight or concave embrasure',
                acceptable: '"Know it when you see it"<br>OR<br>Escalate to Senior',
                ideal: 'Embrasures of pontic matches adjacent abutment embrasures',
            },
            examples: {
                bad: [emergence_profile_occlusal_embrasure_BAD],
                acceptable: [emergence_profile_occlusal_embrasure_ACCEPTABLE],
                ideal: [emergence_profile_occlusal_embrasure_GOOD],
            },
            description:
                'Contour from internal edge of the occlusal table to IP contact<br><br>V-shaped with rounded marginal ridges<br><br>Adjacent natural embrasure defines ideal contours for embrasures of the restoration - Do not copy rotated dentition and/or unnatural areas<br><br>"You know it when you see it"',
            selectable_bad_reasons: ['[SURFACE] emergence profile does not appropriately match adjacent'],
            presets: {
                view_direction_preset: {
                    type: 'tooth_view',
                    view: 'buccal',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
            when: flags => flags.hasBridge,
        },
    ],
};
