import type { BrushParams } from '../Objects/BrushPathObject';
import { BrushPathObject } from '../Objects/BrushPathObject';
import type { CanvasObject } from '../Objects/CanvasObject';
import type { Point } from '../Util';
import { CreationTool } from './CreationTool';

const MIN_CURSOR_SIZE = 21;
const MAX_BRUSH_SIZE_FOR_CROSSHAIR = 5;

function crosshairLines(color: string, strokeWidth?: number): string {
    const crossHairExtent = 9;
    const crossHairInner = 6;
    const strokeWidthParam = strokeWidth !== undefined ? `stroke-width="${strokeWidth}"` : ``;
    const lineCapParam = `stroke-linecap="square"`;

    return (
        `<line x1="-${crossHairExtent}" y1="0" x2="-${crossHairInner}" y2="0" stroke="${color}" ${strokeWidthParam} ${lineCapParam}/>` +
        `<line x1="${crossHairInner}" y1="0" x2="${crossHairExtent}" y2="0" stroke="${color}" ${strokeWidthParam} ${lineCapParam}/>` +
        `<line x1="0" y1="-${crossHairExtent}" x2="0" y2="-${crossHairInner}" stroke="${color}" ${strokeWidthParam} ${lineCapParam}/>` +
        `<line x1="0" y1="${crossHairInner}" x2="0" y2="${crossHairExtent}" stroke="${color}" ${strokeWidthParam} ${lineCapParam}/>`
    );
}

// Returns a data URL for the cursor for the given brush params. The cursor is a dot
// whose size and color matches the brush. If the brush size is sufficiently small,
// the cursor includes crosshairs to enhance its visiblity.
function makeCursor(params: BrushParams): string {
    const crosshair = crosshairLines('white', 3) + crosshairLines('black', 1);
    const cursorSize = Math.max(MIN_CURSOR_SIZE, params.size + (1 - (params.size % 2)));
    const center = cursorSize / 2;
    return (
        // eslint-disable-next-line prefer-template
        `url('data:image/svg+xml;utf8,` +
        `<svg width="${cursorSize}" height="${cursorSize}" viewBox="${-center} ${-center} ${cursorSize} ${cursorSize}" xmlns="http://www.w3.org/2000/svg">` +
        `<circle cx="0" cy="0" r="${params.size / 2}" style="fill:${encodeURIComponent(params.color)}" />` +
        (params.size <= MAX_BRUSH_SIZE_FOR_CROSSHAIR ? crosshair : ``) +
        `</svg>') ${center} ${center}, auto`
    );
}

export class BrushCreationTool extends CreationTool {
    private brushParams: BrushParams;

    constructor(params: BrushParams) {
        super(makeCursor(params), false);

        this.brushParams = params;
    }

    objectForPoints(_start: Point, _end: Point, all: Point[]): CanvasObject | null {
        return new BrushPathObject(this.brushParams, all);
    }
}
