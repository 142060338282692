import { cleanStringSpaces } from '../ImplantFieldUtils';
import type { LabsGqlAbutmentPartFragment } from '@orthly/graphql-operations';
import { useListAbutmentPartsQuery } from '@orthly/graphql-react';
import { LabsGqlAbutmentPartEngagement, LabsGqlAbutmentPartMaterial } from '@orthly/graphql-schema';
import type { FieldsDefProp } from '@orthly/ui';
import constate from 'constate';
import _ from 'lodash';
import { z } from 'zod';

export type EditableAbutmentPartFields = Omit<
    LabsGqlAbutmentPartFragment,
    // Will add screw_sku in the next PR.
    'is_archived' | 'created_at' | 'updated_at' | 'id'
>;

export const [AbutmentPartProvider, useAbutmentParts] = constate(() =>
    useListAbutmentPartsQuery({
        variables: { withArchived: true },
    }),
);

export const abutmentPartsFormFields = (
    dataToAutocomplete: FieldsWithAutoCompleteDataSet,
): FieldsDefProp<EditableAbutmentPartFields> => {
    return {
        sku: { type: 'text', validation: z.string().max(200) },
        manufacturer: {
            type: 'autocomplete',
            options: dataToAutocomplete.manufacturers,
            freeSolo: true,
            cleanValue: cleanStringSpaces,
            validation: z.string().max(200),
        },
        material: {
            type: 'select',
            options: Object.keys(LabsGqlAbutmentPartMaterial),
        },
        engagement: {
            type: 'select',
            options: Object.keys(LabsGqlAbutmentPartEngagement),
        },
        three_shape_material: {
            type: 'autocomplete',
            options: dataToAutocomplete.three_shape_material,
            freeSolo: true,
            cleanValue: cleanStringSpaces,
            validation: z.string().max(200),
        },
        three_shape_category: {
            type: 'autocomplete',
            options: dataToAutocomplete.three_shape_category,
            freeSolo: true,
            cleanValue: cleanStringSpaces,
            validation: z.string().max(200),
        },
        three_shape_manufacturer: {
            type: 'autocomplete',
            options: dataToAutocomplete.three_shape_manufacturer,
            freeSolo: true,
            cleanValue: cleanStringSpaces,
            validation: z.string().max(200),
        },
        three_shape_manufacturing_process: {
            type: 'autocomplete',
            options: dataToAutocomplete.three_shape_manufacturing_process,
            freeSolo: true,
            cleanValue: cleanStringSpaces,
            validation: z.string().max(200),
        },
        three_shape_system: {
            type: 'autocomplete',
            options: dataToAutocomplete.three_shape_system,
            freeSolo: true,
            cleanValue: cleanStringSpaces,
            validation: z.string().max(200),
        },
        three_shape_kit: {
            type: 'autocomplete',
            options: dataToAutocomplete.three_shape_kit,
            freeSolo: true,
            cleanValue: cleanStringSpaces,
            validation: z.string().max(200),
        },
        gingival_height_mm: { type: 'number', optional: true, label: 'Gingival Height (mm)' },
        top_cap_height_mm: { type: 'number', optional: true, label: 'Top Cap Height (mm)' },
        diameter_mm: { type: 'number', optional: true, label: 'Diameter (mm)' },
        analog_sku: { type: 'text', validation: z.string().max(200) },
        screw_sku: { type: 'text', optional: true },
    };
};

type AutoCompleteField = {
    label: string;
    value: string;
};
export interface FieldsWithAutoCompleteDataSet {
    manufacturers: AutoCompleteField[];
    three_shape_category: AutoCompleteField[];
    three_shape_material: AutoCompleteField[];
    three_shape_manufacturer: AutoCompleteField[];
    three_shape_manufacturing_process: AutoCompleteField[];
    three_shape_system: AutoCompleteField[];
    three_shape_kit: AutoCompleteField[];
}

const getValuesForAbutmentPartsField = (
    listAbutmentParts: LabsGqlAbutmentPartFragment[],
    field:
        | 'manufacturer'
        | 'three_shape_category'
        | 'three_shape_material'
        | 'three_shape_manufacturer'
        | 'three_shape_manufacturing_process'
        | 'three_shape_system'
        | 'three_shape_kit',
): AutoCompleteField[] =>
    _(listAbutmentParts)
        .map(s => s[field] ?? '')
        .uniq()
        .filter(s => s !== '')
        .sort()
        .value()
        .map(v => ({ value: v, label: v }));

export const getDataToAutoComplete = (abutmentParts: LabsGqlAbutmentPartFragment[]): FieldsWithAutoCompleteDataSet => ({
    manufacturers: getValuesForAbutmentPartsField(abutmentParts, 'manufacturer'),
    three_shape_category: getValuesForAbutmentPartsField(abutmentParts, 'three_shape_category'),
    three_shape_material: getValuesForAbutmentPartsField(abutmentParts, 'three_shape_material'),
    three_shape_manufacturer: getValuesForAbutmentPartsField(abutmentParts, 'three_shape_manufacturer'),
    three_shape_manufacturing_process: getValuesForAbutmentPartsField(
        abutmentParts,
        'three_shape_manufacturing_process',
    ),
    three_shape_system: getValuesForAbutmentPartsField(abutmentParts, 'three_shape_system'),
    three_shape_kit: getValuesForAbutmentPartsField(abutmentParts, 'three_shape_kit'),
});
