import type { TranslationOptions } from '../types';

export const DEFAULT_TRANSLATIONS: TranslationOptions = {
    body: {
        noMatch: 'Sorry, no matching records found',
        toolTip: 'Sort',
        summary: 'SUMMARY',
    },
    pagination: {
        next: 'Next Page',
        previous: 'Previous Page',
        first: 'First Page',
        last: 'Last Page',
        rowsPerPage: 'Rows per page:',
        displayRows: 'of',
    },
    toolbar: {
        search: 'Search',
        downloadCsv: 'Download CSV',
        print: 'Print',
        viewColumns: 'View Columns',
        filterTable: 'Filter Table',
    },
    filter: {
        all: 'All',
        title: 'FILTERS',
        reset: 'RESET',
    },
    viewColumns: {
        title: 'Show Columns',
        titleAria: 'Show/Hide Table Columns',
    },
    selectedRows: {
        text: 'row(s) selected',
        delete: 'Delete',
        deleteAria: 'Delete Selected Rows',
    },
};
