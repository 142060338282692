import { RouterTabs } from '../../components/RouterTabs';
import { DesignAutoAssignmentBulkEditRosterView } from './DesignAutoAssignmentBulkEditRoster.graphql';
import { DesignAutoAssignmentDesignersView } from './DesignAutoAssignmentDesignersView';
import React from 'react';

export const DesignAutoAssignmentRoot: React.FC = () => {
    return (
        <RouterTabs
            defaultPath={'designers'}
            items={[
                { path: 'designers', label: 'Designers', Component: DesignAutoAssignmentDesignersView },
                {
                    path: 'edit-roster',
                    label: 'Roster management',
                    Component: DesignAutoAssignmentBulkEditRosterView,
                },
            ]}
        />
    );
};
