import type { RestorativeAlignmentCheckResult } from '@orthly/dentin';

export type RestorativesValidity = Omit<RestorativeAlignmentCheckResult, 'toothNumber'> & {
    allOkay: boolean;
};

export function parseRestorativesValidity(
    restorativesValidity: RestorativeAlignmentCheckResult[] | undefined,
): RestorativesValidity {
    if (!restorativesValidity) {
        // If we get falsy `restorativesValidity`, it indicates that an error occurred during the check. Out of an
        // abundance of caution, assume that the checks would have failed.
        return {
            allOkay: false,
            marginDistanceToRestorativeOkay: false,
            marginDistanceToPrepScanOkay: false,
            intaglioIntersectionOkay: false,
        };
    }

    const marginDistanceToRestorativeOkay = restorativesValidity.every(el => el.marginDistanceToRestorativeOkay);
    const marginDistanceToPrepScanOkay = restorativesValidity.every(el => el.marginDistanceToPrepScanOkay);
    const intaglioIntersectionOkay = restorativesValidity.every(el => el.intaglioIntersectionOkay);

    return {
        allOkay: marginDistanceToRestorativeOkay && marginDistanceToPrepScanOkay && intaglioIntersectionOkay,
        marginDistanceToRestorativeOkay,
        marginDistanceToPrepScanOkay,
        intaglioIntersectionOkay,
    };
}
