import type { ItemInfo, Rule, Decision } from '../orderEditMode';
import { OrderEditMode } from '@orthly/shared-types';

/**
 * editedPreference checks for the edited item pref matching any of its
 * specified field types.  If it finds a match it returns EditOrder as
 * the most permissible possible value, otherwise it returns null to
 * skip
 */
export function editedPreference(...prefs: string[]): Rule {
    return function (item: ItemInfo): Decision {
        const debugInfo = {
            rule: 'editedPreference',
            expected: prefs,
            received: item.editedPref,
        };

        if (item.editedPref && prefs.includes(item.editedPref)) {
            return {
                editMode: OrderEditMode.OrderEdit,
                debugInfo,
            };
        }

        return {
            editMode: null,
            debugInfo,
        };
    };
}
