import { useFirebaseFileDownload } from '../../hooks/useFirebaseFileDownload.graphql';
import type { UserRole } from '../../util/userRole';
import { AlignerTreatmentPlan } from '../AlignerViewer';
import { OrderDetailBlock, OrderDetailFactRow, OrderDetailImagesBlock } from '../OrderDetails';
import { OrderDetailAlignerRetainersBlock } from './OrderDetailAlignerRetainersBlock.graphql';
import { OrderDetailAlignerCaseBlock } from './OrderDetailAlignersCaseBlock';
import { OrderDetailAlignersIprBlock } from './OrderDetailAlignersIprBlock.graphql';
import { useQuery } from '@apollo/client';
import { getAlignerPlanFromGql } from '@orthly/dentin';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlAlignerCaseFragment, LabsGqlLabOrderFragment, LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlDesignOrderAlignerTreatmentPlanDoctorReviewStatus } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { ALIGNER_PLANS_DETAIL, UserRoleUtil } from '@orthly/shared-types';
import { DownloadIcon, LoadBlocker } from '@orthly/ui';
import { IconButton, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const DownloadPatientPhotos: React.VFC<{
    photo_zip_path: string;
    patient_name: string;
}> = ({ photo_zip_path, patient_name }) => {
    const download = useFirebaseFileDownload(photo_zip_path, `${patient_name} Photos.zip`);
    return (
        <LoadBlocker blocking={download.loading} ContainerProps={{ style: { display: `inline-block`, width: `auto` } }}>
            <IconButton onClick={() => download.execute()} style={{ padding: 0 }}>
                <DownloadIcon />
            </IconButton>
        </LoadBlocker>
    );
};

const VeneerOrderDetailAligners_Query = graphql(`
    query VeneerOrderDetailAligners_Query($lab_order_id: String!) {
        getAllDesignOrderAlignerTreatmentPlans(labOrderId: $lab_order_id) {
            is_latest_doctor_treatment_plan
            version_number_practice
            created_at

            steps_count {
                overall
            }

            doctor_review {
                status
            }

            ...VeneerAlignerTreatmentPlanTreatmentPlan_Fragment
            ...VeneerOrderDetailAlignersIprBlock_Fragment
        }
    }
`);

function useAlignerTreatmentPlanSelection(orderId: string) {
    const { data: treatmentPlansData, refetch } = useQuery(VeneerOrderDetailAligners_Query, {
        variables: {
            lab_order_id: orderId,
        },
        fetchPolicy: 'no-cache',
    });

    const filteredTreatmentPlans = (treatmentPlansData?.getAllDesignOrderAlignerTreatmentPlans ?? []).filter(
        tp => !!tp.version_number_practice,
    );
    const all_treatment_plans = _.sortBy(filteredTreatmentPlans, tp => (tp ? -new Date(tp.created_at) : -Infinity));

    const approvedTreatmentPlanIndex = all_treatment_plans.findIndex(
        tp => tp.doctor_review?.status === LabsGqlDesignOrderAlignerTreatmentPlanDoctorReviewStatus.Approved,
    );

    const preferredTreatmentPlanIndex =
        approvedTreatmentPlanIndex >= 0
            ? approvedTreatmentPlanIndex
            : all_treatment_plans.findIndex(tp => tp.is_latest_doctor_treatment_plan);

    const [selected_treatment_plan_index, set_selected_treatment_plan_index] =
        React.useState(preferredTreatmentPlanIndex);

    React.useEffect(() => {
        if (selected_treatment_plan_index === -1 && preferredTreatmentPlanIndex !== -1) {
            set_selected_treatment_plan_index(preferredTreatmentPlanIndex);
        }
    }, [selected_treatment_plan_index, set_selected_treatment_plan_index, preferredTreatmentPlanIndex]);

    return {
        all_treatment_plans,
        selected_treatment_plan_index,
        set_selected_treatment_plan_index,
        current_treatment_plan: all_treatment_plans[preferredTreatmentPlanIndex],
        refetch,
    };
}

export interface OrderDetailAlignersSoftSmilePlanVersionsBlockProps {
    refetch: () => Promise<unknown>;
    order: Pick<LabsGqlOrder, 'id' | 'aligner_case' | 'fulfillment_workflow' | 'scan_export'>;
}

export const OrderDetailAligners: React.FC<{
    lab_order: LabsGqlLabOrderFragment;
    aligner_case: LabsGqlAlignerCaseFragment;
    refetch: () => Promise<unknown>;
    actions?: React.ReactNode;
    user_role: UserRole;
    disablePhotoDownload?: boolean;
    SoftSmilePlanVersionsBlock?: React.VFC<OrderDetailAlignersSoftSmilePlanVersionsBlockProps>;
}> = ({
    lab_order,
    aligner_case,
    refetch: refetchOrder,
    actions,
    user_role,
    disablePhotoDownload,
    SoftSmilePlanVersionsBlock,
}) => {
    const { patient } = lab_order;
    const patient_name = `${patient.first_name} ${patient.last_name}`;
    const is_internal = UserRoleUtil.is_internal(user_role);
    const {
        all_treatment_plans,
        selected_treatment_plan_index,
        set_selected_treatment_plan_index,
        current_treatment_plan,
        refetch: refetchPlans,
    } = useAlignerTreatmentPlanSelection(lab_order.id);
    const selected_treatment_plan = all_treatment_plans[selected_treatment_plan_index] ?? null;

    const refetchAll = async () => await Promise.all([refetchOrder(), refetchPlans()]);

    const { estimate, photo_zip_path, photos: raw_patient_photos } = aligner_case;
    const { __typename, ...patient_photos } = raw_patient_photos;

    const image_sources = React.useMemo(() => {
        return Object.entries(patient_photos).map(([name, source]) => ({
            source: source ?? '',
            title: _.startCase(name),
        }));
    }, [patient_photos]);
    const estimate_extras = _.compact([
        estimate.needsIpr ? 'IPR' : null,
        estimate.needsAttachments ? 'Attachments' : null,
    ]);
    const [raw_selected_step_index, set_selected_step_index] = React.useState(0);
    const selected_step_index = Math.min(
        raw_selected_step_index,
        selected_treatment_plan?.version_number_practice ? selected_treatment_plan.steps_count.overall - 1 : 0,
    );

    const shouldShowRetainersBlock = !UserRoleUtil.is_lab(user_role);

    return (
        <>
            <Grid container direction={'column'} wrap={'nowrap'}>
                {/* This block is provided when rendering in the admin portal only. */}
                {SoftSmilePlanVersionsBlock && <SoftSmilePlanVersionsBlock refetch={refetchAll} order={lab_order} />}
                <AlignerTreatmentPlan
                    lab_order={lab_order}
                    aligner_case={aligner_case}
                    user_role={user_role}
                    refetch={refetchAll}
                    selected_step_index={selected_step_index}
                    set_selected_step_index={set_selected_step_index}
                    all_treatment_plan_fragments={all_treatment_plans}
                    selected_treatment_plan_index={selected_treatment_plan_index}
                    set_selected_treatment_plan_index={set_selected_treatment_plan_index}
                />
                {shouldShowRetainersBlock &&
                    current_treatment_plan?.doctor_review?.status ===
                        LabsGqlDesignOrderAlignerTreatmentPlanDoctorReviewStatus.Approved && (
                        <OrderDetailAlignerRetainersBlock lab_order={lab_order} aligner_case={aligner_case} />
                    )}
                {!!selected_treatment_plan?.version_number_practice && (
                    <OrderDetailAlignersIprBlock
                        treatment_plan_fragment={selected_treatment_plan}
                        selected_step_index={selected_step_index}
                    />
                )}
                <OrderDetailAlignerCaseBlock
                    lab_order={lab_order}
                    aligner_case={aligner_case}
                    is_psr={is_internal}
                    actions={actions}
                />
                <OrderDetailBlock variant={'full'} title={'Estimate'}>
                    <OrderDetailFactRow
                        layoutLeft={6}
                        layoutRight={6}
                        title={'Suggested Plan'}
                        value={ALIGNER_PLANS_DETAIL[getAlignerPlanFromGql(estimate)].displayName}
                    />
                    {estimate.doctor_proposed_step_limit && (
                        <OrderDetailFactRow
                            layoutLeft={6}
                            layoutRight={6}
                            title={'Proposed Step Limit'}
                            value={Format.pluralize('step', estimate.doctor_proposed_step_limit)}
                        />
                    )}
                    {estimate_extras.length > 0 && (
                        <OrderDetailFactRow
                            layoutLeft={6}
                            layoutRight={6}
                            title={'Extras'}
                            value={estimate_extras.join(', ')}
                        />
                    )}
                </OrderDetailBlock>
            </Grid>
            <OrderDetailImagesBlock
                label={is_internal ? 'Aligner case photos' : 'Patient photos'}
                sources={image_sources}
                refetchOrder={refetchOrder}
                actions={
                    is_internal && !!photo_zip_path && <DownloadPatientPhotos {...{ photo_zip_path, patient_name }} />
                }
                disablePhotoDownload={disablePhotoDownload}
                orderId={lab_order.id}
            />
        </>
    );
};
