import { NavArea } from './NavArea';
import type { NavAreaProps } from './NavArea';
import type { SlideProps } from '@orthly/ui-primitives';
import { Text, Slide, styled, useScreenIsSm } from '@orthly/ui-primitives';
import React from 'react';

const TitleAndContentsWrapper = styled('div')<{ isSmScreen: boolean; wrapperWidth: string }>(
    ({ isSmScreen, wrapperWidth }) => ({
        display: 'flex',
        flexDirection: 'column',
        overflow: 'scroll',
        height: '100%',
        width: wrapperWidth,
        margin: '0 auto',
        gridGap: 16,
        padding: isSmScreen ? '16px 24px' : 48,
    }),
);
const ContentWrapper = styled('div')({
    height: '100%',
});

export type FullScreenWorkflowContainerProps = {
    children: React.ReactNode;
    title: React.ReactNode;
    animationDirection?: SlideProps['direction'];
    triggerAnimation?: boolean;
} & NavAreaProps;

const slideAnimationAction = (
    direction: SlideProps['direction'],
    containerId: string,
    setSlideDirection: (direction: SlideProps['direction']) => void,
    setSlideIn: (value: boolean) => void,
) => {
    const oppDirection = direction === 'down' ? 'up' : 'down';
    setSlideDirection(direction);
    setSlideIn(false);

    setTimeout(() => {
        const mainEl = document.getElementById(containerId);
        setSlideDirection(oppDirection);
        setSlideIn(true);
        mainEl?.scrollIntoView();
    }, 300);
};

export const FullScreenWorkflowContainer: React.VFC<FullScreenWorkflowContainerProps> = props => {
    const { children, title, onBack, onNext, animationDirection, triggerAnimation, disableNextForNoChanges } = props;

    const [slideIn, setSlideIn] = React.useState(true);
    const [slideDirection, setSlideDirection] = React.useState<SlideProps['direction']>('down');

    const isSmScreen = useScreenIsSm();

    const wrapperWidth = isSmScreen ? '100%' : '80%';

    const showNavArea = !!onNext || !!onBack;
    const containerId = 'order-edit-body-container';

    React.useEffect(() => {
        if (animationDirection) {
            slideAnimationAction(animationDirection, containerId, setSlideDirection, setSlideIn);
        }
    }, [containerId, animationDirection, triggerAnimation]);

    return (
        <>
            <Slide in={slideIn} direction={slideDirection}>
                <TitleAndContentsWrapper
                    isSmScreen={isSmScreen}
                    wrapperWidth={wrapperWidth}
                    style={{
                        //this needs to go here doesn't work in class
                        scrollMargin: 1000,
                        overflowX: 'hidden',
                    }}
                >
                    <Text variant={'h4'}>{title}</Text>
                    <ContentWrapper>{children}</ContentWrapper>
                </TitleAndContentsWrapper>
            </Slide>
            {showNavArea && (
                <NavArea
                    {...props}
                    onNavigateClick={(direction: SlideProps['direction']) =>
                        slideAnimationAction(direction, containerId, setSlideDirection, setSlideIn)
                    }
                    wrapperWidth={wrapperWidth}
                    disableNextForNoChanges={disableNextForNoChanges}
                />
            )}
        </>
    );
};
