import { useOrderDetailContext } from '../../state/OrderDetailProvider.graphql';
import { FlossPalette } from '@orthly/ui-primitives';
import { OrderDetailAccordionBlock } from '@orthly/veneer';
import React from 'react';
import ReactJson from 'react-json-view';

export const OrderDetailDevTools: React.FC = () => {
    const { order } = useOrderDetailContext();
    return (
        <OrderDetailAccordionBlock variant={'full'} title={'Dev Tools'}>
            <ReactJson
                sortKeys
                theme={'bright:inverted'}
                name={'LabOrder'}
                collapsed={1}
                src={order ?? {}}
                enableClipboard={true}
                displayDataTypes={false}
                style={{ fontSize: 14, backgroundColor: FlossPalette.TAN, overflow: 'scroll' }}
            />
        </OrderDetailAccordionBlock>
    );
};
