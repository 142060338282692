import type { CanvasObject, Handle } from '../Objects/CanvasObject';
import { HandleObject } from '../Objects/HandleObject';
import type { Point } from '../Util';
import { pointEquals, vectorDifference, vectorSum } from '../Util';
import type { CanvasTool } from './CanvasTool';

class HandleManipulationTool {
    private startPoint?: Point;
    private handle: Handle;
    private offset: Point = [0, 0];

    constructor(handle: Handle) {
        this.handle = handle;
    }

    cursor(): string {
        return this.handle.cursor;
    }

    begin(point: Point): void {
        this.startPoint = point;
        this.offset = vectorDifference(this.handle.center, point);
    }

    onMove(point: Point) {
        this.handle.actionForMove(vectorSum(point, this.offset))();
    }

    end(point: Point) {
        const { startPoint } = this;
        if (startPoint && !pointEquals(startPoint, point)) {
            return {
                do: this.handle.actionForMove(vectorSum(point, this.offset)),
                undo: this.handle.actionForMove(vectorSum(startPoint, this.offset)),
            };
        }
        return undefined;
    }
}

export class PointerTool implements CanvasTool {
    private subtool?: HandleManipulationTool;
    private hitTest: (point: Point) => CanvasObject | null;

    constructor(hitTest: (point: Point) => CanvasObject | null) {
        this.hitTest = hitTest;
    }

    cursorAtPoint(point: Point): string {
        if (this.subtool) {
            return this.subtool.cursor();
        } else {
            const hitObject = this.hitTest(point);
            if (hitObject && hitObject instanceof HandleObject) {
                return hitObject.handle.cursor;
            } else if (hitObject) {
                return 'grab';
            }
        }
        return 'default';
    }

    handlePointerDown(point: Point) {
        const hitObject = this.hitTest(point);
        this.subtool = hitObject ? new HandleManipulationTool(hitObject.grabHandle()) : undefined;
        this.subtool?.begin(point);

        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
        // eslint-disable-next-line no-nested-ternary
        const selectedObject = hitObject ? (hitObject instanceof HandleObject ? undefined : hitObject) : null;
        return { selectedObject, cursor: this.subtool?.cursor() ?? 'default' };
    }

    handlePointerMoved(point: Point) {
        this.subtool?.onMove(point);
    }

    handlePointerUp(point: Point) {
        if (this.subtool) {
            const actionEntry = this.subtool.end(point);
            this.subtool = undefined;
            if (actionEntry) {
                return actionEntry;
            }
        }
        return null;
    }

    draw(_ctx: CanvasRenderingContext2D): void {}
}
