import { useGetImpersonationTokenLazyQuery } from '@orthly/graphql-react';
import { apolloErrorMessage } from '@orthly/ui';
import { useSnackbar } from 'notistack';

export const useGetImpersonationToken = (onComplete: (token: string) => void) => {
    const { enqueueSnackbar } = useSnackbar();

    return useGetImpersonationTokenLazyQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        onError: err => {
            enqueueSnackbar(apolloErrorMessage(err), {
                variant: 'error',
            });
        },
        onCompleted: data => {
            const impersonationToken = data?.getImpersonationToken.token;
            if (impersonationToken) {
                onComplete(impersonationToken);
            }
        },
    });
};
