import type { UnsavedFilterCriteria } from '../../../components/OrderFilterBuilder/OrderFilterBuilder.types';
import {
    OrderFilterBuilderRoot,
    isCompletedFilterCriteria,
} from '../../../components/OrderFilterBuilder/OrderFilterBuilderRoot';
import { SimpleExpansionPanel } from '../../../components/SimpleExpansionPanel';
import type { LabsGqlDoctorPreferencesFragment, LabsGqlDoctorLabSlipAlertFragment } from '@orthly/graphql-operations';
import { useListOrderFiltersQuery, useSaveDoctorLabSlipAlertsMutation } from '@orthly/graphql-react';
import type { LabsGqlDoctorLabSlipAlertInput } from '@orthly/graphql-schema';
import { LabsGqlDoctorAlertLocation, LabsGqlFilterCombinator, LabsGqlLabSlipAlertType } from '@orthly/graphql-schema';
import type { ButtonProps } from '@orthly/ui';
import {
    SimpleCheckbox,
    useChangeSubmissionFn,
    RootActionDialog,
    TrashIcon,
    SimpleInput,
    SimpleMultiSelect,
} from '@orthly/ui';
import { Button, Text, FlossPalette, Collapse, Grid, IconButton } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface EditDoctorLabSlipAlertsProps {
    doctor: LabsGqlDoctorPreferencesFragment;
    refetchPreferences: () => Promise<any>;
    customButton?: React.FC<ButtonProps>;
}

type AlertFilter = {
    criteria: UnsavedFilterCriteria[];
    combinator: LabsGqlFilterCombinator;
};

type FormAlert = Omit<LabsGqlDoctorLabSlipAlertInput, 'type' | 'filter'> & {
    isTask: boolean;
    filter?: AlertFilter | null;
};

interface DoctorLabSlipAlertFormProps {
    alert: FormAlert;
    onAlertChange: (alert: FormAlert) => void;
    onRemove: () => void;
}

const DoctorLabSlipAlertForm: React.FC<DoctorLabSlipAlertFormProps> = props => {
    const { alert, onAlertChange } = props;
    const { filter } = alert;
    const filtersQuery = useListOrderFiltersQuery();

    return (
        <SimpleExpansionPanel
            ExpansionPanelProps={{ variant: 'outlined' }}
            title={
                <Grid container justifyContent={'space-between'} alignItems={'center'}>
                    <Text variant={'body1'}>{alert.text ? `"${alert.text}"` : 'Unsaved alert'}</Text>
                    <IconButton onClick={props.onRemove}>
                        <TrashIcon />
                    </IconButton>
                </Grid>
            }
        >
            <Grid container>
                <Grid container alignItems={'center'}>
                    <Grid container item xs={8}>
                        <SimpleInput
                            fullWidth
                            label={'Title'}
                            value={alert.text}
                            onChange={v => onAlertChange({ ...alert, text: v ?? '' })}
                        />
                    </Grid>
                    <Grid container item xs={4}>
                        <SimpleCheckbox
                            checked={alert.isTask}
                            setChecked={checked => onAlertChange({ ...alert, isTask: checked })}
                            label={'Show as checkbox?'}
                        />
                    </Grid>
                    <Grid container item xs={4}>
                        <SimpleMultiSelect
                            options={Object.values(LabsGqlDoctorAlertLocation).map(value => ({
                                value,
                                label: _.startCase(value),
                            }))}
                            onChange={selected =>
                                onAlertChange({
                                    ...alert,
                                    visibility: (selected ?? []) as LabsGqlDoctorAlertLocation[],
                                })
                            }
                            value={alert.visibility ?? [LabsGqlDoctorAlertLocation.LabSlip]}
                            label={'Visibility'}
                        />
                    </Grid>
                </Grid>
                <SimpleCheckbox
                    checked={!!filter}
                    setChecked={checked =>
                        onAlertChange({
                            ...alert,
                            filter: !checked ? null : { combinator: LabsGqlFilterCombinator.And, criteria: [] },
                        })
                    }
                    label={'Filter orders?'}
                />
                <Collapse in={!!filter} style={{ width: '100%' }}>
                    <Grid container style={{ padding: '12px 0' }}>
                        {!!filter && (
                            <OrderFilterBuilderRoot
                                combinator={filter.combinator}
                                setCombinator={combinator =>
                                    onAlertChange({
                                        ...alert,
                                        filter: { combinator, criteria: filter?.criteria ?? [] },
                                    })
                                }
                                criterias={filter.criteria}
                                setCriterias={criteria =>
                                    onAlertChange({
                                        ...alert,
                                        filter: {
                                            criteria,
                                            combinator: filter?.combinator ?? LabsGqlFilterCombinator.And,
                                        },
                                    })
                                }
                                filtersQuery={filtersQuery}
                                changed={true}
                                setChanged={_changed => {}}
                            />
                        )}
                    </Grid>
                </Collapse>
            </Grid>
        </SimpleExpansionPanel>
    );
};

function savedAlertsToForm(alerts: LabsGqlDoctorLabSlipAlertFragment[]): FormAlert[] {
    return alerts.map<FormAlert>(({ id, text, filter, type, visibility }) => ({
        id,
        text,
        visibility,
        filter: filter && {
            ...filter,
            criteria: filter.criteria.map(c => _.omit(c, '__typename') as Omit<UnsavedFilterCriteria, '__typename'>),
        },
        isTask: type === LabsGqlLabSlipAlertType.Checkbox,
    }));
}

interface FormProps extends EditDoctorLabSlipAlertsProps {
    setOpen: (open: boolean) => void;
}

const EditDoctorLabSlipAlertsForm: React.FC<FormProps> = props => {
    const { doctor, setOpen } = props;
    const [submitMtn] = useSaveDoctorLabSlipAlertsMutation();
    const [alerts, setAlerts] = React.useState<FormAlert[]>(savedAlertsToForm(doctor.lab_slip_alerts));
    const { submit, submitting } = useChangeSubmissionFn<any, [LabsGqlDoctorLabSlipAlertInput[]]>(
        alerts => submitMtn({ variables: { alerts, doctor_id: doctor.id } }),
        {
            closeOnComplete: true,
            successMessage: () => ['Lab slip alerts saved!', {}],
            onSuccess: async () => {
                await props.refetchPreferences();
                setOpen(false);
            },
        },
    );
    const [changed, setChanged] = React.useState<boolean>(false);
    const onReset = React.useCallback(() => {
        setAlerts(savedAlertsToForm(doctor.lab_slip_alerts));
    }, [doctor.lab_slip_alerts]);
    const onChangeAlert = (alertIndex: number) => (alert: FormAlert) => {
        !changed && setChanged(true);
        setAlerts(alerts => alerts.map((a, idx) => (idx === alertIndex ? alert : a)));
    };
    const onRemove = (alertIndex: number) => () => {
        !changed && setChanged(true);
        setAlerts(alerts => alerts.filter((_a, idx) => idx !== alertIndex));
    };
    const onSubmit = React.useCallback(() => {
        return submit(
            alerts.map<LabsGqlDoctorLabSlipAlertInput>(a => {
                const completedCriteria = (a.filter?.criteria ?? []).filter(isCompletedFilterCriteria);
                const filter =
                    completedCriteria.length === 0 || !a.filter?.combinator
                        ? null
                        : { combinator: a.filter.combinator, criteria: completedCriteria };
                return {
                    filter,
                    id: a.id,
                    text: a.text,
                    type: a.isTask ? LabsGqlLabSlipAlertType.Checkbox : LabsGqlLabSlipAlertType.Warning,
                    visibility: a.visibility,
                };
            }),
        );
    }, [alerts, submit]);
    return (
        <Grid container>
            <Grid container>
                {alerts.map((a, idx) => (
                    <DoctorLabSlipAlertForm
                        key={a.id}
                        alert={a}
                        onAlertChange={onChangeAlert(idx)}
                        onRemove={onRemove(idx)}
                    />
                ))}
            </Grid>
            <Grid container justifyContent={'space-between'} style={{ padding: '12px 0' }}>
                <Button
                    variant={'ghost'}
                    onClick={() => {
                        !changed && setChanged(true);
                        setAlerts(a => [
                            ...a,
                            { isTask: false, text: '', visibility: [LabsGqlDoctorAlertLocation.LabSlip] },
                        ]);
                    }}
                >
                    Add Alert
                </Button>
                <Grid container style={{ width: 'auto' }}>
                    <Button
                        style={{ marginRight: 8, color: FlossPalette.BLACK }}
                        variant={'text'}
                        disabled={!changed}
                        onClick={onReset}
                    >
                        Reset
                    </Button>
                    <Button variant={'primary'} onClick={() => onSubmit()} disabled={!changed || submitting}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export const EditDoctorLabSlipAlerts: React.FC<EditDoctorLabSlipAlertsProps> = props => {
    const { doctor, customButton } = props;
    const [open, setOpen] = React.useState<boolean>(false);
    return (
        <RootActionDialog
            loading={false}
            open={open}
            setOpen={setOpen}
            title={`Edit lab slip alerts for ${doctor.name}`}
            dialogProps={{ maxWidth: 'lg' }}
            content={<EditDoctorLabSlipAlertsForm {...props} setOpen={setOpen} />}
            buttonText={`Edit Lab Slip Alerts (${doctor.lab_slip_alerts.length} current)`}
            CustomButton={customButton}
        />
    );
};
