import type { InboxState } from '../../screens/Inbox/state/Inbox.types';
import type { OrdersOverviewState, OrdersOverviewCustomView } from '../../screens/Orders/state/OrdersOverview.types';
import type { CustomView } from './SavedView.types';
import type { ApolloClient, useApolloClient } from '@apollo/client';
import type {
    LabsGqlEditSavedOrderSearchMutation,
    LabsGqlEditSavedOrderSearchMutationVariables,
    LabsGqlGetSavedSearchesQuery,
    LabsGqlGetSavedSearchesQueryVariables,
    LabsGqlSavedSearchFragment,
    LabsGqlFavoriteSavedSearchMutation as FavoriteSavedSearchMutation,
    LabsGqlFavoriteSavedSearchMutationVariables as FavoriteSavedSearchVariables,
    LabsGqlUnfavoriteSavedSearchMutation,
    LabsGqlUnfavoriteSavedSearchMutationVariables,
    LabsGqlDeleteSavedSearchMutation,
    LabsGqlDeleteSavedSearchMutationVariables,
} from '@orthly/graphql-operations';
import {
    EditSavedOrderSearchDocument,
    GetSavedSearchesDocument,
    FavoriteSavedSearchDocument,
    UnfavoriteSavedSearchDocument,
    DeleteSavedSearchDocument,
} from '@orthly/graphql-react';
import type { LabsGqlCustomSearchType, LabsGqlFilterCriteriaSubmissionInput } from '@orthly/graphql-schema';
import { LabsGqlSavedSearchVisibility } from '@orthly/graphql-schema';

export type SaveViewPayload = { id: string; title: string };

export interface ApolloThunkArgs {
    client: ReturnType<typeof useApolloClient>;
}

export interface MutateSavedSearchPayload extends ApolloThunkArgs {
    searchId: string;
}

export interface LoadSavedSearchPayload extends ApolloThunkArgs {}

export interface EditSavedSearchPayload extends MutateSavedSearchPayload {
    title: string;
    visibility?: LabsGqlSavedSearchVisibility;
}

export interface FavoriteSavedSearchPayload extends MutateSavedSearchPayload {
    favorited: boolean;
}

export interface CreateOrderSavedSearchProps {
    payload: EditSavedSearchPayload;
    ordersOverviewState: OrdersOverviewState;
}

export interface EditOrderSavedSearchProps extends CreateOrderSavedSearchProps {
    activeSearch: boolean;
    existingSavedSearch: OrdersOverviewCustomView;
}

export interface CreateInboxSavedSearchProps {
    payload: EditSavedSearchPayload;
    inboxState: InboxState;
}

export interface FavoriteSavedSearchResult {
    searchId: string;
    view?: OrdersOverviewCustomView;
}

export interface VisibilitySavedSearchResult {
    searchId: string;
    newVisibility: LabsGqlSavedSearchVisibility;
}

export interface SaveViewProps {
    title: string;
    id: string;
    visibility: LabsGqlSavedSearchVisibility;
}

export class SavedViewActionUtils {
    static async deleteView(client: ApolloClient<any>, searchId: string): Promise<void> {
        await client.mutate<LabsGqlDeleteSavedSearchMutation, LabsGqlDeleteSavedSearchMutationVariables>({
            mutation: DeleteSavedSearchDocument,
            variables: { searchId },
        });
    }

    static async toggleFavoritedView(
        client: ApolloClient<any>,
        favorited: boolean,
        searchId: string,
    ): Promise<LabsGqlSavedSearchFragment | undefined> {
        const variables = { data: { search_id: searchId } };
        if (favorited) {
            const result = await client.mutate<FavoriteSavedSearchMutation, FavoriteSavedSearchVariables>({
                variables,
                mutation: FavoriteSavedSearchDocument,
            });
            return result.data?.favoriteSavedSearch;
        }
        await client.mutate<LabsGqlUnfavoriteSavedSearchMutation, LabsGqlUnfavoriteSavedSearchMutationVariables>({
            variables,
            mutation: UnfavoriteSavedSearchDocument,
        });
    }

    static async loadViews(
        client: ApolloClient<any>,
        type: LabsGqlCustomSearchType,
    ): Promise<LabsGqlSavedSearchFragment[]> {
        const result = await client.query<LabsGqlGetSavedSearchesQuery, LabsGqlGetSavedSearchesQueryVariables>({
            query: GetSavedSearchesDocument,
            variables: { type, limit: null, unique: null },
        });
        return result?.data.getSavedSearches ?? [];
    }

    static async toggleViewVisibility(
        client: ApolloClient<any>,
        searchId: string,
        view?: CustomView,
    ): Promise<LabsGqlSavedSearchVisibility> {
        const newVisibility =
            view?.visibility === LabsGqlSavedSearchVisibility.Private
                ? LabsGqlSavedSearchVisibility.Public
                : LabsGqlSavedSearchVisibility.Private;
        await client.mutate<LabsGqlEditSavedOrderSearchMutation, LabsGqlEditSavedOrderSearchMutationVariables>({
            mutation: EditSavedOrderSearchDocument,
            variables: { data: { search_id: searchId, visibility: newVisibility } },
        });
        return newVisibility;
    }

    // Used to remove __typename from order filter for type compatibility
    static cleanOrderFilter(filters?: LabsGqlFilterCriteriaSubmissionInput[]) {
        return filters?.map(filter => ({
            filter_id: filter.filter_id,
            comparator: filter.comparator,
            list_combinator: filter.list_combinator,
            comparison_value: filter.comparison_value,
        }));
    }
}
