import { tryParseJson } from '../../screens/Routing/utils';
import { LabsGqlLabOrderItemConditionType, LabsGqlLabOrderConditionType } from '@orthly/graphql-schema';
import { FlossPalette, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import ReactJson from 'react-json-view';

export type RuleConditionType = 'item' | 'order';
export const RuleConditionPreview: React.FC<{ ruleType: RuleConditionType; rawJSON: string }> = ({
    ruleType,
    rawJSON,
}) => {
    const { parsed, conditionTypes } = React.useMemo(() => {
        const parsed = tryParseJson(rawJSON) as any;
        if (!parsed) {
            return { parsed, conditionTypes: [] };
        }
        const validKeys =
            ruleType === 'item'
                ? Object.values(LabsGqlLabOrderItemConditionType)
                : Object.values(LabsGqlLabOrderConditionType);
        const conditionTypes = _.intersection(validKeys, Object.keys(parsed));
        return { parsed, conditionTypes };
    }, [rawJSON, ruleType]);

    if (!parsed) {
        return (
            <Text variant={'caption'}>
                Matches<b style={{ color: FlossPalette.BURGUNDY }}>ALL</b> {ruleType === 'item' ? 'items' : 'orders'}
            </Text>
        );
    }

    const conditionType = conditionTypes[0];
    if (!conditionType || conditionTypes.length > 1) {
        console.error(`ERROR: invalid condition data for rule: ${rawJSON}`);
        return <>(invalid condition)</>;
    }

    return (
        <ReactJson
            name={conditionType}
            src={typeof parsed[conditionType] === 'object' ? parsed[conditionType] : parsed}
            collapsed={true}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
        />
    );
};
