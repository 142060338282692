import type { FetchResult } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type { SharePatientScanMutation } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

const SharePatientScan_Mutation = graphql(`
    mutation SharePatientScan(
        $annotation_urls: [String!]
        $message: String
        $practice_name: String
        $practice_contact_phone: String
        $patient_email: String
        $patient_id: String!
        $patient_mobile_phone: String
        $scan_export_id: String!
        $organization_id: String
        $staff_member_id: String
    ) {
        sharePatientScan(
            annotation_urls: $annotation_urls
            message: $message
            practice_name: $practice_name
            practice_contact_phone: $practice_contact_phone
            patient_email: $patient_email
            patient_id: $patient_id
            patient_mobile_phone: $patient_mobile_phone
            scan_export_id: $scan_export_id
            organization_id: $organization_id
            staff_member_id: $staff_member_id
        )
    }
`);

export const useSharePatientScan = ({
    onSuccess,
    onFailure,
}: {
    onSuccess?: () => void;
    onFailure?: (message?: string) => void;
}) => {
    type Result = FetchResult<SharePatientScanMutation>;
    const [submitMtn, { error }] = useMutation(SharePatientScan_Mutation);
    const { submit: sharePatientScan, ...rest } = useChangeSubmissionFn<Result, any>(
        variables => submitMtn({ variables }),
        {
            disableDefaultErrorMessage: true,
            onSuccess: () => {
                onSuccess?.();
            },
            onError: e => {
                onFailure?.(e?.message);
            },
        },
    );

    const submit = React.useCallback(
        async (
            annotation_urls,
            message,
            practice_name,
            practice_contact_phone,
            patient_email,
            patient_id,
            patient_mobile_phone,
            scan_export_id,
            organization_id,
            staff_member_id,
        ) =>
            sharePatientScan({
                annotation_urls,
                message,
                practice_name,
                practice_contact_phone,
                patient_email,
                patient_id,
                patient_mobile_phone,
                scan_export_id,
                organization_id,
                staff_member_id,
            }),
        [sharePatientScan],
    );

    return {
        error: error?.message,
        submit,
        ...rest,
    };
};
