import bl_contour_lateral_height_ACCEPTABLE from '../../../../assets/images/design-qc/bl_contour/bl_contour_lateral_height_ACCEPTABLE.png';
import bl_contour_lateral_height_BAD from '../../../../assets/images/design-qc/bl_contour/bl_contour_lateral_height_ACCEPTABLE.png';
import bl_contour_lateral_height_GOOD from '../../../../assets/images/design-qc/bl_contour/bl_contour_lateral_height_GOOD.png';
import bl_contour_transitional_contour_ACCEPTABLE from '../../../../assets/images/design-qc/bl_contour/bl_contour_transitional_contour_ACCEPTABLE.jpg';
import bl_contour_transitional_contour_BAD from '../../../../assets/images/design-qc/bl_contour/bl_contour_transitional_contour_BAD.jpg';
import bl_contour_vertical_height_ACCEPTABLE from '../../../../assets/images/design-qc/bl_contour/bl_contour_vertical_height_ACCEPTABLE.png';
import bl_contour_vertical_height_BAD from '../../../../assets/images/design-qc/bl_contour/bl_contour_vertical_height_ACCEPTABLE.png';
import bl_contour_vertical_height_GOOD from '../../../../assets/images/design-qc/bl_contour/bl_contour_vertical_height_GOOD.png';
import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { DEFAULT_SCAN_APPEARANCE, DEFAULT_RESTORATIVE_APPEARANCE } from '@orthly/dentin';

export const buccalLingualContourRubric: DesignQcRubricCategoryEntry = {
    category: 'bl_contour',
    subcategories: [
        {
            category: 'bl_contour',
            name: 'Height of contour vertical placement',
            grading: {
                type: 'tertiary',
                bad: 'Beyond +/- 10%',
                acceptable: 'Within +/- 10%',
                ideal: 'As close to perfect as situation permits (100%)',
            },
            examples: {
                bad: [bl_contour_lateral_height_BAD],
                acceptable: [bl_contour_lateral_height_ACCEPTABLE],
                ideal: [bl_contour_lateral_height_GOOD],
            },
            description: 'Vertical position of the height of contour, relative to adjacent teeth',
            selectable_bad_reasons: ['Height of contour is too high', 'Height of contour is too low'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
        },
        {
            category: 'bl_contour',
            name: 'Height of contour lateral placement',
            grading: {
                type: 'tertiary',
                bad: 'Beyond +/- 10%',
                acceptable: 'Within +/- 10%',
                ideal: 'As close to perfect as situation permits (100%)',
            },
            examples: {
                bad: [bl_contour_vertical_height_BAD],
                acceptable: [bl_contour_vertical_height_ACCEPTABLE],
                ideal: [bl_contour_vertical_height_GOOD],
            },
            description: 'Lateral placement of the height of contour, relative to adjacent teeth',
            selectable_bad_reasons: ['Height of contour is too far out', 'Height of contour is too far in'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
        },
        {
            category: 'bl_contour',
            name: 'Transitional Contour',
            grading: {
                type: 'tertiary',
                bad: 'Beyond +/- 10%',
                acceptable: 'Within +/- 10%',
                ideal: 'As close to perfect as situation permits (100%)',
            },
            examples: {
                bad: [bl_contour_transitional_contour_BAD],
                acceptable: [bl_contour_transitional_contour_ACCEPTABLE],
                ideal: [bl_contour_transitional_contour_ACCEPTABLE],
            },
            description: 'How closely the transitions to the peak mimic adjacent anatomy',
            selectable_bad_reasons: ['Contour does not approriately mimic adjacent contour'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
        },
    ],
    design_standard: {
        googleEmbedStub: `2PACX-1vSXrYDagIv6Lu-LtUhE8YGFKGso56t-9fsd9W2CKYh67QhZUM-3Vn5YdSTu_JQxNh_6UzRYknpAM1Np`,
    },
};
