import { OrderActiveTaskAction } from '../OrderDetailAlerts/OrderActiveTaskAction.graphql';
import type { AdminLabsOrderActionProps } from '../actions/order-action-types';
import { DesignTaskDetailTracker } from '@orthly/dentin';
import { Divider } from '@orthly/ui-primitives';
import { useDesignTaskTimeRemainingText } from '@orthly/veneer';
import React from 'react';

export const DesignTrackingBlock: React.VFC<AdminLabsOrderActionProps> = props => {
    const { order, refetchOrder } = props;
    const { active_task } = order.fulfillment_workflow;
    const remainingTimeText = useDesignTaskTimeRemainingText(active_task ?? undefined);
    if (!active_task) {
        return null;
    }
    return (
        <>
            <div style={{ padding: '12px 0 0' }}>
                <DesignTaskDetailTracker order={order} activeTask={active_task} remainingTimeText={remainingTimeText} />
            </div>
            <Divider style={{ margin: '8px 0', width: '100%' }} />
            <OrderActiveTaskAction
                order={order}
                refetchOrder={refetchOrder}
                variant={'detail_block'}
                designFragment={null}
            />
        </>
    );
};
