import type { ToolbarActionProps, ToolbarActionDefinition } from './ToolbarActionDefinition';
import type { LabsGqlMarkFulfillmentShippedMutationVariables } from '@orthly/graphql-operations';
import { useMarkFulfillmentShippedMutation } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus, LabsGqlShippingCarriersEnum } from '@orthly/graphql-schema';
import { QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import moment from 'moment-timezone';
import React from 'react';

type Vars = LabsGqlMarkFulfillmentShippedMutationVariables['data'];

const MarkShippedAction: React.FC<ToolbarActionProps> = props => {
    const { order, setOpen, open } = props;
    const [submitMtn] = useMarkFulfillmentShippedMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Order marked shipped', {}],
        onSuccess: async () => {
            await props.refetchOrder();
            setOpen(false);
        },
    });
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Mark Shipped'}
            content={
                <QuickForm<Omit<Vars, 'orderId'>>
                    fields={{
                        carrier: {
                            type: 'select',
                            options: Object.entries(LabsGqlShippingCarriersEnum).map(([label, value]) => ({
                                label,
                                value,
                            })),
                            label: 'Shipping Carrier',
                        },
                        ship_date: {
                            type: 'date',
                            helperText: 'Date you shipped the order',
                            fieldProps: { minDate: moment(order.created_at).toDate() },
                        },
                        tracking_number: { type: 'text' },
                    }}
                    initialValues={{ ship_date: moment().toJSON(), carrier: LabsGqlShippingCarriersEnum.Ups }}
                    onSubmit={async (result, actions) => {
                        await submit({
                            ...result,
                            orderId: props.order.id,
                            ship_date: moment(result.ship_date).toJSON(),
                        });
                        actions.setSubmitting(false);
                    }}
                />
            }
            style={{ padding: 0 }}
            buttonText={'Mark Shipped'}
            buttonProps={{
                style: { height: '100%' },
            }}
            CustomButton={props.CustomButton}
        />
    );
};

export const MarkShippedToolbarAction: ToolbarActionDefinition = {
    label: 'Mark shipped',
    Component: MarkShippedAction,
    allowedStatuses: [LabsGqlLabOrderStatus.Fabrication],
    capabilityRequirement: 'order.fulfillment.mark_shipped',
};
