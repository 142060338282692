import { useTrackImpersonateOpen } from '../../PalateMetrics';
import { OpenIncognitoDialog } from '../../components/OpenIncognitoDialog';
import { usePalateStyles } from '../../usePalateStyles';
import { MultiProductImageWrapper, calculateSKU } from '@orthly/dentin';
import type { LabsGqlScanFileFragment } from '@orthly/graphql-operations';
import { isValidProductUnitType } from '@orthly/items';
import type { ProductUnitType } from '@orthly/items';
import { Text, Grid } from '@orthly/ui-primitives';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

interface IncompleteOrdersProps {
    scans: LabsGqlScanFileFragment[];
    practiceUrl: string;
    practiceId: string;
}

interface IncompleteOrderDetailsProps {
    id: string;
    patientName: string;
    date: string;
    products: ProductUnitType[];
    lastOrder: boolean;
    practiceUrl: string;
    practiceId: string;
}

const IncompleteOrderDetails: React.VFC<IncompleteOrderDetailsProps> = props => {
    const classes = usePalateStyles();
    const { id, patientName, date, products, lastOrder, practiceUrl, practiceId } = props;
    const [open, setOpen] = React.useState(false);
    const impersonateUrl = practiceUrl.replace('?', `/lab/submit?scanId=${id}&`);
    const trackImpersonateOpen = useTrackImpersonateOpen(practiceId, impersonateUrl);

    return (
        <>
            <OpenIncognitoDialog
                open={open}
                setOpen={setOpen}
                practiceUrl={impersonateUrl}
                title={'Open this incomplete order'}
            />
            <Grid
                container
                wrap={'nowrap'}
                className={clsx(classes.order, !lastOrder && classes.orderBorder)}
                alignItems={'center'}
                onClick={() => {
                    trackImpersonateOpen();
                    setOpen(true);
                }}
            >
                <Grid item style={{ paddingRight: 8 }}>
                    <MultiProductImageWrapper products={products} direction={'column'} maxNumOrbs={3} />
                </Grid>
                <Grid item style={{ marginTop: -4 }}>
                    <Text variant={'caption'} medium>{`${patientName}'s ${(
                        products[0] ?? 'Other'
                    ).toLowerCase()}`}</Text>
                    <Grid container>
                        <Text variant={'caption'} color={'DARK_GRAY'}>
                            Since {moment(date).format('DD MMM YYYY')}
                        </Text>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export const IncompleteOrders: React.VFC<IncompleteOrdersProps> = props => {
    const classes = usePalateStyles();
    const { scans, practiceUrl, practiceId } = props;

    return (
        <Grid container>
            {scans.length > 0 && (
                <Grid container>
                    <Text className={classes.sectionHeader} variant={'body2'} medium>
                        Incomplete orders
                    </Text>
                    {_.orderBy(scans, s => new Date(s.created_at).valueOf(), 'desc').map((scan, index) => (
                        <IncompleteOrderDetails
                            key={index}
                            id={scan.id}
                            patientName={`${scan.patient_first_name} ${scan.patient_last_name}`}
                            date={scan.created_at}
                            products={_.uniq(
                                scan.restorations.map(r => {
                                    const sku = calculateSKU(r);
                                    return isValidProductUnitType(sku) ? sku : 'Unknown';
                                }),
                            )}
                            lastOrder={index === scans.length - 1}
                            practiceUrl={practiceUrl}
                            practiceId={practiceId}
                        />
                    ))}
                </Grid>
            )}
        </Grid>
    );
};
