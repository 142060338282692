import { FlossPalette, makeStyles, createStyles, Grid, IconButton, Text, Icon } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        backButton: {
            color: FlossPalette.STAR_GRASS,
            marginTop: 4,
        },
        grayText: {
            color: FlossPalette.GRAY,
        },
        header: {
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            alignItems: 'center',
            marginBottom: 16,
        },
    }),
);

interface EditOrderSlaLayoutProps {
    title: string;
    onBack: () => void;
    children: React.ReactNode;
}

export const EditOrderSlaLayout: React.FC<EditOrderSlaLayoutProps> = props => {
    const { onBack, title } = props;
    const classes = useStyles();
    return (
        <Grid container spacing={2}>
            <div className={classes.header}>
                <Text variant={'h4'} medium style={{ width: '100%' }}>
                    {title}
                </Text>
                <IconButton className={classes.backButton} onClick={() => onBack()}>
                    <Icon icon={'XIcon'} />
                </IconButton>
            </div>
            {props.children}
        </Grid>
    );
};
