import { DesignerEntryContent } from '../../../../../DesignAutoAssignment/AutoAssignmentDesignerEntry';
import { OrderAutoAssignmentDebuggerInfoDisplay } from '../../../../../DesignAutoAssignment/OrderAutoAssignmentDebuggerInfoDisplay';
import type { LabsGqlLabOrderFragment, LabsGqlDesignStaffWithUserFragment } from '@orthly/graphql-operations';
import { WorkflowTaskTypeToName } from '@orthly/graphql-operations';
import { useListFilteredDesignersQuery } from '@orthly/graphql-react';
import type { LabsGqlDesignerFilters } from '@orthly/graphql-schema';
import { LabsGqlDesignStaffStatus, LabsGqlWorkflowTaskType, LabsGqlDesignStaffTaskType } from '@orthly/graphql-schema';
import { ActionCard, SimpleSelect } from '@orthly/ui';
import {
    Button,
    Text,
    Collapse,
    IconButton,
    TextField,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Icon,
} from '@orthly/ui-primitives';
import { OrderDetailAccordionBlock, DesignerStatusIndicator } from '@orthly/veneer';
import React from 'react';

const AutoAssignmentDesignerEntry: React.FC<{
    orderId: string;
    designStaffWithUser: LabsGqlDesignStaffWithUserFragment;
    orderActiveTask?: LabsGqlWorkflowTaskType;
}> = ({ orderId, designStaffWithUser, orderActiveTask }) => {
    const [isOpen, setOpen] = React.useState(false);

    return (
        <div style={{ padding: 10 }}>
            <Text
                variant={'body2'}
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                onClick={() => setOpen(!isOpen)}
            >
                <span style={{ display: 'flex', alignItems: 'center' }}>
                    <DesignerStatusIndicator status={designStaffWithUser.designStaff.status} />
                    <span style={{ paddingBottom: 2 }}>
                        {[designStaffWithUser.first_name, designStaffWithUser.last_name]
                            .map(w => w.trim())
                            .filter(w => !!w)
                            .join(' ')}{' '}
                        ({designStaffWithUser.email})
                    </span>
                </span>
                <Icon icon={isOpen ? 'KeyboardArrowUpIcon' : 'KeyboardArrowDownIcon'} />
            </Text>
            <Collapse in={isOpen}>
                <div style={{ padding: 10 }}>
                    <div style={{ marginBottom: 10 }}>
                        <Text variant={'h6'} color={'BLACK'}>
                            {designStaffWithUser.first_name} {designStaffWithUser.last_name}
                        </Text>
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            {designStaffWithUser.email}
                        </Text>
                    </div>

                    <OrderAutoAssignmentDebuggerInfoDisplay
                        designStaffWithUser={designStaffWithUser}
                        orderId={orderId}
                        orderActiveTask={orderActiveTask}
                        isOpen={isOpen}
                    />
                    <DesignerEntryContent isOpen={isOpen} designStaff={designStaffWithUser.designStaff} />
                </div>
            </Collapse>
        </div>
    );
};

const DesignerSearchBar: React.FC<{
    value: LabsGqlDesignerFilters;
    onChange(value: LabsGqlDesignerFilters): void;
    isLoading: boolean;
    orderId: string;
    taskType?: LabsGqlWorkflowTaskType;
}> = ({
    value: defaultValue,
    onChange: updateValue,
    isLoading,
    orderId,
    taskType = LabsGqlWorkflowTaskType.InternalDesign,
}) => {
    const [searchFilters, setSearchFilters] = React.useState(defaultValue);
    React.useEffect(() => {
        setSearchFilters(defaultValue);
    }, [defaultValue]);
    // If the stage isn't something relevant, act like it's the normal design phase.
    const cleanTaskType = (LabsGqlDesignStaffTaskType as any)[taskType]
        ? taskType
        : LabsGqlWorkflowTaskType.InternalDesign;
    const taskTypeName = WorkflowTaskTypeToName[cleanTaskType];

    return (
        <form
            onSubmit={evt => {
                evt.preventDefault();
                if (isLoading) {
                    return;
                }
                updateValue(searchFilters);
            }}
            style={{
                width: '100%',
                background: '#fff',
                display: 'flex',
                alignItems: 'center',
                padding: 10,
                borderRadius: 10,
                marginTop: 10,
            }}
        >
            <div style={{ flex: '1 1 0' }}>
                <TextField
                    variant={'standard'}
                    onChange={evt => setSearchFilters({ ...searchFilters, search: evt.target.value ?? '', page: 0 })}
                    value={searchFilters.search}
                    style={{ background: '#fff', borderRight: 'solid 1px rgba(0,0,0,.1)' }}
                    fullWidth
                    label={'Search for designer'}
                    disabled={isLoading}
                />
            </div>
            <div style={{ flex: '1 1 0' }}>
                <SimpleSelect
                    value={searchFilters.order_id ? 'match-capabilities' : 'any-capabilities'}
                    options={[
                        {
                            label: `Has ${taskTypeName} Capabilities`,
                            value: 'match-capabilities',
                        },
                        { label: `Any ${taskTypeName} Capabilities`, value: 'any-capabilities' },
                    ]}
                    onChange={capabilityMatch =>
                        setSearchFilters({
                            ...searchFilters,
                            order_id: capabilityMatch === 'match-capabilities' ? orderId : null,
                            page: 0,
                        })
                    }
                    label={'Designer Capabilities'}
                />
            </div>
            <div style={{ flex: '1 1 0' }}>
                <SimpleSelect
                    value={searchFilters.status ?? 'no-status'}
                    options={[
                        { label: '(any status)', value: 'no-status' },
                        ...Object.values(LabsGqlDesignStaffStatus)
                            .filter(status => status !== LabsGqlDesignStaffStatus.Offboarded)
                            .map(status => ({ label: status, value: status })),
                    ]}
                    onChange={status =>
                        setSearchFilters({
                            ...searchFilters,
                            status: (LabsGqlDesignStaffStatus as any)[String(status)] ?? undefined,
                            page: 0,
                        })
                    }
                    label={'Filter by status'}
                />
            </div>
            <Button
                variant={'primary'}
                size={'small'}
                style={{ marginLeft: 10 }}
                onClick={() => updateValue(searchFilters)}
                disabled={isLoading || searchFilters === defaultValue}
            >
                Search
            </Button>
        </form>
    );
};

interface OrderAutoAssignmentDebuggerBlockProps {
    order: LabsGqlLabOrderFragment;
}

const OrderAutoAssignmentDebuggerBlockInternal: React.FC<OrderAutoAssignmentDebuggerBlockProps> = ({ order }) => {
    const [searchFilter, setSearchFilter] = React.useState<LabsGqlDesignerFilters>({
        search: '',
        status: LabsGqlDesignStaffStatus.Active,
        order_id: order.id,
        page: 0,
        per_page: 10,
    });
    const { data, error, loading } = useListFilteredDesignersQuery({ variables: { data: searchFilter } });

    return (
        <div>
            <DesignerSearchBar
                value={searchFilter}
                onChange={setSearchFilter}
                isLoading={loading}
                orderId={order.id}
                taskType={order.fulfillment_workflow.active_task?.type}
            />
            {error && (
                <ActionCard
                    style={{ marginTop: 10 }}
                    title={`Failed to load designers.`}
                    subtitle={String(error)}
                    variant={'alert'}
                />
            )}
            {data && (
                <div style={{ marginTop: 20, background: '#fff', borderRadius: 10 }}>
                    {data.listFilteredDesigners.designers.length === 0 && (
                        <Text variant={'body2'} style={{ padding: 10, width: '100%', textAlign: 'center' }}>
                            Your search returned zero results.
                        </Text>
                    )}
                    {data.listFilteredDesigners.designers.map(designer => (
                        <AutoAssignmentDesignerEntry
                            key={designer.email}
                            designStaffWithUser={designer}
                            orderActiveTask={order.fulfillment_workflow.active_task?.type}
                            orderId={order.id}
                        />
                    ))}
                </div>
            )}
            <div
                style={{
                    marginTop: 10,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <IconButton
                    size={'small'}
                    style={{ minWidth: 0 }}
                    disabled={searchFilter.page === 0}
                    onClick={() => setSearchFilter(filters => ({ ...filters, page: filters.page - 1 }))}
                >
                    <KeyboardArrowLeft />
                </IconButton>
                <Text variant={'body2'} style={{ margin: '0 16px' }}>
                    Page {searchFilter.page + 1}
                </Text>
                <IconButton
                    size={'small'}
                    style={{ minWidth: 0 }}
                    disabled={!data?.listFilteredDesigners.hasNextPage}
                    onClick={() => setSearchFilter(filters => ({ ...filters, page: filters.page + 1 }))}
                >
                    <KeyboardArrowRight />
                </IconButton>
            </div>
        </div>
    );
};

export const OrderAutoAssignmentDebuggerBlock: React.FC<OrderAutoAssignmentDebuggerBlockProps> = ({ order }) => {
    return (
        <OrderDetailAccordionBlock title={`Autoassignment Debugger`} variant={'full'}>
            <OrderAutoAssignmentDebuggerBlockInternal order={order} />
        </OrderDetailAccordionBlock>
    );
};
