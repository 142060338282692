import React from 'react';

export function useChangeEffect(fn: React.EffectCallback, deps: React.DependencyList | undefined) {
    const isFirstRender = React.useRef(true);

    React.useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {
            fn();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
