import { RefabBodyContainer } from '../components/RefabBodyContainer';
import { useRefabFlowSelector } from '../state/refab-flow-state';
import { getUniqueAdditionalInfoRequest } from '../utils';
import { useRefabFlowContext } from './refab-flow-context';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { LoadBlocker } from '@orthly/ui';
import { Text, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

export const SupplementalScreenStepOne: React.VFC = () => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const itemwiseReasonCodes = useRefabFlowSelector(s => s.itemwiseReasonCodes);
    const { order, submitting, useGenerateUploaderConfig, SupplementalInfoStepOne, isOps } = useRefabFlowContext();
    const additionalInfoRequests = getUniqueAdditionalInfoRequest(itemwiseReasonCodes);

    const { qrCode, textUploadLink } = useGenerateUploaderConfig();
    const [isUploading, setIsUploading] = React.useState(false);

    React.useEffect(() => {
        BrowserAnalyticsClientFactory.Instance?.track(
            `${isOps ? 'Ops' : 'Practice'} - Refab Flow - Supplementary Steps Entered`,
            {
                $groups: { order: order?.id ?? 'unknown' },
            },
        );
    }, [order, isOps]);

    return (
        <RefabBodyContainer
            title={<Text variant={isMobile ? 'h5' : 'h3'}>Anything else you want to share?</Text>}
            nextTitle={isUploading ? 'Uploading...' : 'Next'}
            disableNext={isUploading}
        >
            <LoadBlocker blocking={submitting}>
                <SupplementalInfoStepOne
                    order={order}
                    additionalInfoRequests={additionalInfoRequests}
                    qrCode={qrCode}
                    textUploadLink={textUploadLink}
                    onUploadingChange={setIsUploading}
                />
            </LoadBlocker>
        </RefabBodyContainer>
    );
};
