import type { FragmentType } from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import { FlossPalette, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const BannerWrapper = styled('div')({
    backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
    width: '100%',
    padding: 8,
});

const AdminFakePracticeBannerOrganization_Fragment = graphql(`
    fragment AdminFakePracticeBannerOrganization_Fragment on OrganizationDTO {
        is_fake_organization
    }
`);

export const FakePracticeBanner: React.VFC<{
    organizationFragment?: FragmentType<typeof AdminFakePracticeBannerOrganization_Fragment>;
}> = ({ organizationFragment }) => {
    const organization = getFragmentData(AdminFakePracticeBannerOrganization_Fragment, organizationFragment);
    const isFakeOrganization = organization?.is_fake_organization;

    if (!isFakeOrganization) {
        return null;
    }

    return (
        <BannerWrapper>
            <Text variant={'body2'} color={'PRIMARY_FOREGROUND'} medium>
                This is a Dandy-managed practice. Please do not pause it or attempt to collect payment.
            </Text>
        </BannerWrapper>
    );
};
