import type { ItemEditorV2BaseProps } from '../../../types/ItemEditorV2BaseProps';
import { useAutoSetItemDataFieldDefaultValue } from './ItemEditorV2ItemDataField.util';
import type { EnumSelectItemDataField, ItemDataFieldId, LabOrderItemSKUType } from '@orthly/items';
import { ItemDataFieldUtils } from '@orthly/items';
import { belongsToEnum } from '@orthly/runtime-utils';
import { SimpleSelect } from '@orthly/ui';
import React from 'react';

interface ItemEditorV2EnumSelectItemDataFieldProps<
    ID extends ItemDataFieldId,
    SKU extends LabOrderItemSKUType,
    Enum extends string,
> extends ItemEditorV2BaseProps<SKU> {
    field: EnumSelectItemDataField<ID, SKU, Enum>;
}

export const ItemEditorV2EnumSelectItemDataField = <
    ID extends ItemDataFieldId,
    SKU extends LabOrderItemSKUType,
    Enum extends string,
>({
    field,
    item,
    updateItem,
    showError,
}: ItemEditorV2EnumSelectItemDataFieldProps<ID, SKU, Enum>): React.ReactElement => {
    const value = field.getValue(item) ?? undefined;

    React.useEffect(() => {
        const options = field.getOptions(item);

        if (value && !options.some(option => option.value === value)) {
            updateItem(field.getUpdatePayload(null, item));
        }
    }, [item, value, field, updateItem]);

    const applyUpdate = (val: string | undefined) => {
        // belongs to enum should always be true, but we check for type reasons
        if (val === undefined || belongsToEnum(val, field.enum)) {
            updateItem(field.getUpdatePayload(val ?? null, item));
        }
    };

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    return (
        <SimpleSelect
            label={field.label}
            value={value}
            options={[...field.getOptions(item)]}
            onChange={val => applyUpdate(val)}
            errorText={
                showError && ItemDataFieldUtils.isItemDataFieldRequired(field, item) && !field.isComplete(item)
                    ? 'This field is required'
                    : undefined
            }
        />
    );
};
