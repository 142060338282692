import type { LabsGqlActionCapabilityDtoFragment } from '@orthly/graphql-operations';
import {
    useCreateActionCapabilityMutation,
    useGetActionCapabilitiesQuery,
    useUpdateActionCapabilityMutation,
} from '@orthly/graphql-react';
import MUITable from '@orthly/mui-table';
import { LoadBlocker, QuickForm, RootActionDialog, TrashIcon, useRootActionCommand } from '@orthly/ui';
import { Grid, IconButton, Tooltip, EditIcon, Icon } from '@orthly/ui-primitives';
import React from 'react';

interface ActionCapabilityEditDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    refetch: () => void;
    capability: LabsGqlActionCapabilityDtoFragment | null;
}

interface ActionCapabilityEditDialogFormData {
    name: string;
}

const ActionCapabilityEditDialog: React.FC<ActionCapabilityEditDialogProps> = props => {
    const { capability, refetch } = props;

    const closeAndRefetch = () => {
        refetch();
        props.setOpen(false);
    };

    const { submit: submitCreate, submitting: submittingCreate } = useRootActionCommand(
        useCreateActionCapabilityMutation(),
        {
            successMessage: `Action capability created!`,
            onSuccess: closeAndRefetch,
        },
    );
    const { submit: submitUpdate, submitting: submittingUpdate } = useRootActionCommand(
        useUpdateActionCapabilityMutation(),
        {
            successMessage: `Action capability updated!`,
            onSuccess: closeAndRefetch,
        },
    );

    return (
        <RootActionDialog
            title={`${capability ? 'Edit' : 'Create'} Action Capability`}
            open={props.open}
            setOpen={props.setOpen}
            loading={submittingCreate || submittingUpdate}
            CustomButton={() => null}
            content={
                <QuickForm<ActionCapabilityEditDialogFormData>
                    fields={{
                        name: { type: 'text' },
                    }}
                    initialValues={{ name: '', ...capability }}
                    submitButtonTitle={capability ? 'Save' : 'Create'}
                    onSubmit={async formResult => {
                        const { name } = formResult;
                        if (capability) {
                            await submitUpdate({
                                data: { name, capability_id: capability.id, is_archived: false },
                            });
                        } else {
                            await submitCreate({
                                data: { name },
                            });
                        }
                    }}
                />
            }
        />
    );
};

interface ActionCapabilitiesListRowActionProps {
    capability: LabsGqlActionCapabilityDtoFragment;
    onEdit: () => void;
    onArchiveChange: () => void;
}

export const ActionCapabilitiesListRowAction: React.VFC<ActionCapabilitiesListRowActionProps> = props => {
    const { capability, onEdit, onArchiveChange } = props;

    const { submit: submitArchive, submitting: submittingArchive } = useRootActionCommand(
        useUpdateActionCapabilityMutation(),
        {
            successMessage: `Action capability ${capability.is_archived ? 'restored' : 'archived'}!`,
            onSuccess: () => onArchiveChange(),
        },
    );

    return (
        <>
            <div style={{ display: 'flex' }}>
                <Tooltip title={`Edit`}>
                    <IconButton onClick={onEdit}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={`Delete`}>
                    <LoadBlocker blocking={submittingArchive} loader={`circular`}>
                        <IconButton
                            onClick={() => {
                                void submitArchive({
                                    data: {
                                        capability_id: capability.id,
                                        name: capability.name,
                                        is_archived: !capability.is_archived,
                                    },
                                });
                            }}
                        >
                            {capability.is_archived ? <Icon icon={'Restore'} /> : <TrashIcon />}
                        </IconButton>
                    </LoadBlocker>
                </Tooltip>
            </div>
        </>
    );
};

export const ActionCapabilitiesList: React.FC = () => {
    const { data, refetch } = useGetActionCapabilitiesQuery();
    const actionCapabilities = React.useMemo(() => data?.getActionCapabilities ?? [], [data]);
    const [isEditing, setIsEditing] = React.useState(false);
    const [editTarget, setEditTarget] = React.useState<LabsGqlActionCapabilityDtoFragment | null>(null);

    const editCapability = (action: LabsGqlActionCapabilityDtoFragment | null) => {
        setEditTarget(action);
        setIsEditing(true);
    };

    return (
        <Grid container>
            <MUITable<LabsGqlActionCapabilityDtoFragment>
                title={''}
                data={actionCapabilities}
                displayOptions={{
                    fixedSearch: true,
                    elevation: 0,
                    viewColumns: true,
                    filter: true,
                    sort: true,
                }}
                actions={{
                    global: [
                        { icon: `refresh`, position: `toolbar`, onClick: () => refetch()?.catch(console.error) },
                        { icon: `add`, position: `toolbar`, onClick: () => editCapability(null), tooltip: `Create` },
                    ],
                }}
                columns={[
                    { name: `Capability`, render: 'name', filter: false },
                    {
                        name: 'Archived',
                        hidden: true,
                        render: 'is_archived',
                        type: 'boolean',
                        filterOptions: { defaultValues: ['false'], exact: false, type: 'dropdown' },
                    },
                    {
                        name: '',
                        render: capability => (
                            <ActionCapabilitiesListRowAction
                                capability={capability}
                                onEdit={() => editCapability(capability)}
                                onArchiveChange={refetch}
                            />
                        ),
                    },
                ]}
            />
            <ActionCapabilityEditDialog
                open={isEditing}
                setOpen={setIsEditing}
                capability={editTarget}
                refetch={refetch}
            />
        </Grid>
    );
};
