import { useAutomationBuilderSelector } from './AutomationBuilder.context';
import type { LabsGqlFilterCriteriaSubmissionInput, LabsGqlFilterSubmissionInput } from '@orthly/graphql-schema';

export function useCompletedAutomationFilter(): LabsGqlFilterSubmissionInput | undefined {
    return useAutomationBuilderSelector<LabsGqlFilterSubmissionInput | undefined>(({ form }) => {
        const completeCriterias = (form.filter_criteria ?? []).filter(
            (f): f is LabsGqlFilterCriteriaSubmissionInput => {
                return !!f.comparator && !!f.filter_id;
            },
        );
        if (completeCriterias.length !== (form.filter_criteria ?? []).length) {
            return undefined;
        }
        return { criteria: completeCriterias, combinator: form.filter_combinator };
    });
}
