/* eslint-disable no-nested-ternary */
import { ModelAssetCacheProvider } from '../DesignViewer/ModelAssetCacheContext';
import { OrderDetailBlock } from '../OrderDetails';
import { ClickToLoadDesignViewerLiteWrapper } from './ClickToLoadDesignViewerLiteWrapper';
import { GuidedWaxupBlock } from './GuidedWaxups/GuidedWaxupBlock.graphql';
import { OrderDesignTopBar } from './OrderDesignActions.graphql';
import { OrderDesignPreviewPlaceholder } from './OrderDesignPreviewPlaceholder';
import { WaxupSidebar, WaxupSidebarHeader } from './OrderWaxupSidebar.graphql';
import { WaxupSidebarControls } from './OrderWaxupSidebarControls';
import type { OrderWaxupProps } from './Waxup.util.graphql';
import { useCurrentWaxup } from './Waxup.util.graphql';
import { useShouldShowGuidedWaxupFlow } from './Waxup.util.graphql';
import type { CurrentWaxup } from './Waxups.types';
import { LabsGqlLabOrderWaxupReviewStatus } from '@orthly/graphql-schema';
import { FlossPalette, useScreenIsMobileOrVerticalTablet, Grid } from '@orthly/ui-primitives';
import React from 'react';

const HEIGHT_MODEL_VIEWER = '500px';

interface OrderWaxupActionsProps extends OrderWaxupProps {
    currentWaxup: CurrentWaxup;
}

const OrderWaxupActions: React.VFC<OrderWaxupActionsProps> = props => {
    const { order, userRole, refetchOrder, currentWaxup } = props;

    return (
        <OrderDesignTopBar
            order={order}
            userRole={userRole}
            refetch={refetchOrder}
            designRevisionFragments={currentWaxup.slimDesignFragments}
            selectedDesignRevisionId={currentWaxup.selectedRevisionId}
            setSelectedDesignRevisionId={currentWaxup.setSelectedRevisionId}
            selectedDesignFragment={currentWaxup?.currentDesignFragment ?? undefined}
        />
    );
};

const OrderWaxupInner: React.FC<OrderWaxupProps> = props => {
    const { order, refetchOrder, userRole, clickToLoad } = props;

    const currentWaxup = useCurrentWaxup(order);
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const enableNewGuidedWaxupFlow = useShouldShowGuidedWaxupFlow(order);
    const showGuidedWaxups = enableNewGuidedWaxupFlow && userRole === 'practitioner';
    if (!currentWaxup) {
        return null;
    }

    const showWaxupSidebarAndHeader =
        !showGuidedWaxups || order.waxup_status !== LabsGqlLabOrderWaxupReviewStatus.ReadyForReview;

    const hangTight =
        order.waxup_status === LabsGqlLabOrderWaxupReviewStatus.AwaitingDesign &&
        !currentWaxup.slimDesignFragments.length;

    if (isMobile) {
        return (
            <OrderDetailBlock
                key={props.order.id}
                title={showGuidedWaxups ? 'Design Preview' : 'Digital Waxup'}
                titleRowProps={{
                    style: {
                        marginBottom: `16px`,
                        alignItems: `center`,
                    },
                }}
                variant={'full'}
                actions={
                    showWaxupSidebarAndHeader && (
                        <OrderWaxupActions
                            currentWaxup={currentWaxup}
                            order={order}
                            userRole={userRole}
                            refetchOrder={refetchOrder}
                        />
                    )
                }
            >
                <Grid container>
                    <Grid
                        container
                        key={currentWaxup.selectedRevisionId}
                        style={{ flexDirection: `column`, alignItems: 'space-between', gap: '16px' }}
                    >
                        <Grid item>
                            {showWaxupSidebarAndHeader && (
                                <WaxupSidebarHeader
                                    order={order}
                                    currentWaxup={currentWaxup}
                                    refetchOrder={refetchOrder}
                                    userRole={userRole}
                                />
                            )}
                        </Grid>
                        <Grid item style={{ flex: 1 }}>
                            {hangTight ? (
                                <OrderDesignPreviewPlaceholder isError={false} />
                            ) : !showGuidedWaxups ? (
                                <ClickToLoadDesignViewerLiteWrapper
                                    designFragment={currentWaxup.currentDesignFragment}
                                    order={order}
                                    refetchDesign={currentWaxup.refetch}
                                    clickToLoad={clickToLoad}
                                    clickToLoadType={`design preview`}
                                    wrapperStyle={{ height: HEIGHT_MODEL_VIEWER }}
                                />
                            ) : (
                                <GuidedWaxupBlock order={order} currentWaxup={currentWaxup} />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {!showGuidedWaxups && (
                            <WaxupSidebarControls
                                order={order}
                                currentWaxup={currentWaxup}
                                refetchOrder={refetchOrder}
                                userRole={userRole}
                                rootStyle={{
                                    paddingTop: '16px',
                                    marginTop: '16px',
                                    borderTop: `2px solid ${FlossPalette.DARK_TAN}`,
                                }}
                            />
                        )}
                    </Grid>
                </Grid>
            </OrderDetailBlock>
        );
    }

    return (
        <>
            <OrderDetailBlock
                key={props.order.id}
                title={'Design Preview'}
                variant={'full'}
                titleRowProps={{
                    style: {
                        alignItems: `center`,
                    },
                }}
                actions={
                    showWaxupSidebarAndHeader && (
                        <OrderWaxupActions
                            currentWaxup={currentWaxup}
                            order={order}
                            userRole={userRole}
                            refetchOrder={refetchOrder}
                        />
                    )
                }
            >
                <Grid container key={currentWaxup.selectedRevisionId} spacing={2} justifyContent={'center'}>
                    {showWaxupSidebarAndHeader && (
                        <Grid item xs={4}>
                            <WaxupSidebar
                                order={order}
                                currentWaxup={currentWaxup}
                                refetchOrder={refetchOrder}
                                userRole={userRole}
                            />
                        </Grid>
                    )}
                    <Grid item xs={8} style={{ flex: 1 }}>
                        {hangTight ? (
                            <OrderDesignPreviewPlaceholder isError={false} />
                        ) : !showGuidedWaxups ? (
                            <ClickToLoadDesignViewerLiteWrapper
                                designFragment={currentWaxup.currentDesignFragment}
                                order={order}
                                refetchDesign={currentWaxup.refetch}
                                clickToLoad={clickToLoad}
                                clickToLoadType={`design preview`}
                                wrapperStyle={{ height: HEIGHT_MODEL_VIEWER }}
                            />
                        ) : (
                            <GuidedWaxupBlock order={order} currentWaxup={currentWaxup} />
                        )}
                    </Grid>
                </Grid>
            </OrderDetailBlock>
        </>
    );
};

export const OrderWaxup: React.FC<OrderWaxupProps> = props => {
    return (
        <ModelAssetCacheProvider>
            <OrderWaxupInner {...props} />
        </ModelAssetCacheProvider>
    );
};
