import type { LabsGqlDoctorRequestOptionModifyOrderMode } from '@orthly/graphql-schema';
import type { LabsGqlDoctorRequestOptionType } from '@orthly/graphql-schema';
import type { ICartItemV2DTO } from '@orthly/items';
import {
    DandyRecommendedIcon,
    FlossPalette,
    Text,
    stylesFactory,
    Grid,
    RadioPrimitive as Radio,
} from '@orthly/ui-primitives';
import React from 'react';

const SELECTED_BACKGROUND_COLOR = FlossPalette.PRIMARY_BACKGROUND;
const REGULAR_BACKGROUND_COLOR = FlossPalette.TAN;
const BORDER_SELECTED_STYLE = `2px solid ${FlossPalette.PRIMARY_FOREGROUND}`;
const BORDER_REGULAR_STYLE = `2px solid ${FlossPalette.DARK_TAN}`;

const useStyles = stylesFactory(() => ({
    selectedRecommendedOptionWrapper: {
        backgroundColor: SELECTED_BACKGROUND_COLOR,
        borderBottom: BORDER_SELECTED_STYLE,
        borderLeft: BORDER_SELECTED_STYLE,
        borderRight: BORDER_SELECTED_STYLE,
        borderRadius: `0 0 16px 16px`,
    },
    recommendedOptionWrapper: {
        backgroundColor: REGULAR_BACKGROUND_COLOR,
        borderBottom: BORDER_REGULAR_STYLE,
        borderLeft: BORDER_REGULAR_STYLE,
        borderRight: BORDER_REGULAR_STYLE,
        borderRadius: `0 0 16px 16px`,
    },
    selectedOptionWrapper: {
        backgroundColor: SELECTED_BACKGROUND_COLOR,
        border: BORDER_SELECTED_STYLE,
        borderRadius: `16px`,
    },
    optionWrapper: {
        backgroundColor: REGULAR_BACKGROUND_COLOR,
        border: BORDER_REGULAR_STYLE,
        borderRadius: `16px`,
    },
    optionTextWrapper: {
        padding: `2px`,
    },
    outerOptionWrapper: {
        marginTop: `8px`,
        cursor: 'pointer',
    },
    recommendedHeader: {
        flexGrow: 1,
        backgroundColor: FlossPalette.STAR_GRASS,
        color: FlossPalette.WHITE,
        fontSize: 12,
        padding: '2px 16px 2px 24px',
        width: '100%',
        borderRadius: '16px 16px 0 0',
        alignItems: 'center',
    },
    recommendedIconWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    recommendedText: {
        paddingLeft: 8,
    },
}));

export type AskDoctorV2ViewableOption = {
    id: string;
    label: string;
    type: LabsGqlDoctorRequestOptionType | null;
    modify_order_config?: { items_v2: ICartItemV2DTO[]; mode?: LabsGqlDoctorRequestOptionModifyOrderMode };
};

export type AskDoctorOptionsProps = {
    options: AskDoctorV2ViewableOption[];
    onOptionSelected: (selectedOption: AskDoctorV2ViewableOption) => void;
    selectedOptionId: string | undefined;
};

export const AskDoctorOptions: React.VFC<AskDoctorOptionsProps> = ({ options, onOptionSelected, selectedOptionId }) => {
    const classes = useStyles();

    const getOptionClassName = ({ isRecommended, isSelected }: { isRecommended: boolean; isSelected: boolean }) => {
        if (isRecommended) {
            return isSelected ? classes.selectedRecommendedOptionWrapper : classes.recommendedOptionWrapper;
        }

        return isSelected ? classes.selectedOptionWrapper : classes.optionWrapper;
    };

    return (
        <Grid item container direction={'column'} spacing={1}>
            <Grid item>
                <Text variant={'body1'} medium>
                    Please select how you would like to proceed:
                </Text>
            </Grid>

            <Grid container direction={'column'}>
                {options.map((option, index) => {
                    const isRecommended = index === 0;
                    const isSelected = option.id === selectedOptionId;

                    return (
                        <Grid
                            key={`ask-doctor-option-${option.id}`}
                            container
                            item
                            onClick={() => {
                                onOptionSelected(option);
                            }}
                            className={classes.outerOptionWrapper}
                        >
                            {isRecommended ? (
                                <Grid container item direction={'row'} className={classes.recommendedHeader}>
                                    <Grid item className={classes.recommendedIconWrapper}>
                                        <DandyRecommendedIcon />
                                    </Grid>
                                    <Grid item>
                                        <Text
                                            variant={'caption'}
                                            color={'WHITE'}
                                            medium
                                            className={classes.recommendedText}
                                        >
                                            Recommended
                                        </Text>
                                    </Grid>
                                </Grid>
                            ) : null}
                            <Grid
                                container
                                direction={'row'}
                                alignItems={'center'}
                                wrap={'nowrap'}
                                className={getOptionClassName({ isRecommended, isSelected })}
                            >
                                <Grid item>
                                    <Radio color={'secondary'} checked={isSelected} />
                                </Grid>
                                <Grid item className={classes.optionTextWrapper}>
                                    <Text variant={'body2'} medium>
                                        {option.label}
                                    </Text>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};
