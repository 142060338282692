import { RefabBodyContainer } from '../components/RefabBodyContainer';
import { Text, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

export const AttachExistingScans: React.VFC = () => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    return (
        <RefabBodyContainer
            title={
                <>
                    <Text variant={isMobile ? 'h5' : 'h3'} medium>
                        We recommend rescanning the patient via Chairside or selecting an existing scan
                    </Text>
                    <Text variant={isMobile ? 'body2' : 'h4'} color={'LIGHT_GRAY'} style={{ opacity: 0.5 }}>
                        If you skip this step the refab order will be fabricated with the existing scan. Either scan the
                        patient through a scan on Chairside or select from an existing scan below. Learn more.
                    </Text>
                </>
            }
        >
            Attach existing scans here
        </RefabBodyContainer>
    );
};
