import { PRINTABLE_SLIP_FADED_TEXT_COLOR } from '../../styles/colors';
import { FlossPalette, stylesFactory, Text, Box, Grid, createStyles } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() =>
    createStyles({
        printableSlipContainer: {
            background: FlossPalette.WHITE,
            width: '75%',
            padding: '0 1rem',
            margin: 'auto',
            '@media print': {
                width: '100%',
                pageBreakAfter: 'left',
                colorAdjust: 'exact',
            },
        },
        printableSlipDualColumn: {
            '@media print': {
                columnCount: 2,
                columnGap: '1rem',
                columnFill: 'auto',
            },
        },
        fadedText: {
            color: `${PRINTABLE_SLIP_FADED_TEXT_COLOR} !important`,
            fontSize: '0.5rem !important',
        },
        printFooter: {
            display: 'none',
            '@media print': {
                marginTop: `0.5rem`,
                display: 'flex',
                position: 'relative',
                bottom: '-.2rem',
            },
        },
    }),
);

interface IPrintablePageProps {
    orderNumber: string;
    children?: React.ReactNode;
    singleColumn?: boolean;
}

/**
 * Retrieves renders Printable Page with order number in footer
 * @param singleColumn {boolean} Render page as single column layout (defaults to 2 columns)
 * @param orderNumber {string} order number for the page being rendered
 * @param children Component to render within printable slip
 * @returns Rendered Printable Page with footer
 */
export const PrintableSlipPage: React.VFC<IPrintablePageProps> = ({ orderNumber, children, singleColumn }) => {
    const classes = useStyles();
    return (
        <div className={classes.printableSlipContainer}>
            <div className={!singleColumn ? classes.printableSlipDualColumn : undefined}>
                <Box component={'div'}>{children}</Box>
                <Grid container justifyContent={'center'} className={classes.printFooter}>
                    <Text variant={'h6'}>----------</Text>
                    <Text variant={'h6'} className={classes.fadedText}>{`(Dandy #${orderNumber})`}</Text>
                    <Text variant={'h6'}>----------</Text>
                </Grid>
            </div>
        </div>
    );
};
