import { OrderList } from './OrderList';
import { useCompleteOrders } from './queryHooks';
import React from 'react';

interface CompleteOrdersProps {
    practiceId: string;
}

export const CompleteOrdersView: React.FC<CompleteOrdersProps> = props => {
    const { ids: orderIds, loading } = useCompleteOrders({ practiceId: props.practiceId });

    return <OrderList loading={loading} orderIds={orderIds} />;
};
