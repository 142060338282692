import { OrderDetailRow } from '../OrderDetails';
import type {
    FragmentType,
    VeneerOrderDesignAnalyticsCaseModelElementRowCaseModelElement_FragmentFragment,
} from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlDesignFileDmeListResult } from '@orthly/graphql-schema';
import _ from 'lodash';
import React from 'react';

export const VeneerOrderDesignAnalyticsCaseModelElementRowCaseModelElementFragment = graphql(`
    fragment VeneerOrderDesignAnalyticsCaseModelElementRowCaseModelElement_Fragment on DesignRevisionCaseFileModelElement {
        cache_material_name
        items_description
        model_height
        model_volume
        color_id
    }
`);

function getDmeElementString(
    element: VeneerOrderDesignAnalyticsCaseModelElementRowCaseModelElement_FragmentFragment,
    dmeAnalytics?: LabsGqlDesignFileDmeListResult,
): string | undefined {
    const firstCommaIndex = element.cache_material_name.indexOf(',');
    const cleanedMaterialName =
        firstCommaIndex > 0 ? element.cache_material_name.substring(0, firstCommaIndex) : element.cache_material_name;
    const matchingDmes = dmeAnalytics?.found_dmes.filter(dme => dme.provides_materials.includes(cleanedMaterialName));

    if (!matchingDmes?.length) {
        return undefined;
    }

    const dmeString = matchingDmes
        .map(dme => `${dme.dme_name} v${dme.dme_version}${dme.dme_version !== dme.latest_version ? ' (OUTDATED)' : ''}`)
        .join(', ');
    return `DMEs: ${dmeString}`;
}

export const OrderDesignAnalyticsCaseModelElementRowGraphql: React.VFC<{
    elementFragment: FragmentType<typeof VeneerOrderDesignAnalyticsCaseModelElementRowCaseModelElementFragment>;
    variant?: 'sidebar' | 'standard';
    dmeAnalytics?: LabsGqlDesignFileDmeListResult;
}> = ({ elementFragment, variant, dmeAnalytics }) => {
    const element = getFragmentData(
        VeneerOrderDesignAnalyticsCaseModelElementRowCaseModelElementFragment,
        elementFragment,
    );
    const elementDmeString = getDmeElementString(element, dmeAnalytics);

    return (
        <OrderDetailRow
            variant={variant ?? 'standard'}
            name={_.compact([
                element.items_description,
                element.cache_material_name,
                element.color_id && !element.color_id.includes('NULL') ? element.color_id : undefined,
            ]).join(', ')}
            value={_.compact([
                element.model_height ? `Height: ${element.model_height}` : undefined,
                element.model_volume ? `Volume: ${element.model_volume}` : undefined,
                elementDmeString,
            ]).join(', ')}
        />
    );
};
