import type { StaffRoleWithAny } from '../role';
import { getOrganizationTypeFromStaffRoleWithAny } from '../role';
import type { IOrganizationType } from '@orthly/retainer-common';
import { DANDY_INTERNAL_ID } from '@orthly/retainer-common';

/**
 * Objects relating to chat-message visibility may or may not have `manufacturer_id` and/or `partner_id` values.
 * When these values are present and truthy, the lab and/or practice (respectively) will be able to view the
 * relevant chat message.  This interface captures those two values so that we do not have to import more complex
 * objects into `retainer-common` somehow.
 */
export interface ChatMessageVisibilityOrganizationIds {
    manufacturer_id?: string;
    partner_id?: string;
}

/**
 * Legacy chat-message events have visibility information in `visible_to_org_ids` properties.  We've changed to
 * `visible_to_roles`, but we need to support the legacy and new ways of specifying permissions in case we need to
 * read older chat messages.  {@link getChatVisibilityOrgTypes} accepts an object with these properties to determine
 * which organizations can view a message.
 *
 * The type parameter `TRole` is in case we need `visible_to_roles` to have a GQL type, and TypeScript can't figure out
 * that the type is equivalent to {@link StaffRoleWithAny}.  In that case, specify this type parameter to make the
 * compiler happier.
 */
export interface ChatMessageVisibilityProperties<TRole extends StaffRoleWithAny = StaffRoleWithAny> {
    /**
     * The legacy way to determine visibility.  Contains relevant organization IDs for older chat messages.  Otherwise,
     * an empty array.
     */
    visible_to_org_ids: string[];
    /**
     * Contains role identifiers corresponding to internal viewers, labs, and practices, depending on who has permission
     * to see the chat messages.  If TypeScript complains about the return type not being compatible with some GQL type,
     * try specifying `TRole` explicitly where applicable.
     */
    visible_to_roles: TRole[];
}

const creatorOrgTypesWithExternalVisibility: ReadonlySet<IOrganizationType> = new Set<IOrganizationType>([
    'external',
    'internal',
    'practice',
]);

/**
 * Returns an array to use as the `visible_to_roles` property in a chat message creation or visibility-update event.
 * The result will contain `internal__any`, plus `lab__any` and/or `practice__any` if the lab and/or practice should be
 * able to view the message.  That array will also contain `external__any` if the `createdByOrgType` parameter is
 * `'external'`, `'internal'`, or `'practice'`.
 *
 * The type parameter `TRole` is in case we need `visible_to_roles` to have a GQL type, and TypeScript can't figure out
 * that the type is equivalent to {@link StaffRoleWithAny}.  In that case, specify this type parameter to make the
 * compiler happier.  Otherwise, the default should work.
 *
 * The return value could really be a `ReadonlySet<TRole>`, but nearly every consumer would convert to an array.
 *
 * @param createdByOrgType The organization type that created, or is creating, the message (not who is _modifying_
 * the message)
 * @param additionalRoles A collection of roles at add to the visibility output (probably just `lab__any` and/or
 * `practice__any`).
 * @returns An array suitable to use for `visible_to_roles` in chat-message event objects.
 */
export function getChatMessageVisibilityForWrite<TRole extends StaffRoleWithAny = StaffRoleWithAny>(
    createdByOrgType: IOrganizationType,
    additionalRoles: Iterable<TRole> = [],
): TRole[] {
    const visibleToRoles: StaffRoleWithAny[] = ['internal__any'];

    if (creatorOrgTypesWithExternalVisibility.has(createdByOrgType)) {
        visibleToRoles.push('external__any');
    }

    visibleToRoles.push(...additionalRoles);

    return [...new Set(visibleToRoles)] as TRole[];
}

/**
 * Get the set of organization types that can view a message, given some information about the message and the order
 * to which the message applies.  NOTE: this isn't "forever" code (so whoever's running `git blame` in about 2028,
 * you have my apologies and gratitude) because 1) the roles are finer-grained than organization level, and if we ever
 * _use_ that feature, this function will imply overbroad permissions; and 2) we should deprecate `visible_to_org_ids`
 * anyway.
 *
 * @param visible_to_org_ids The organization IDs that can see a message, if we're still using those.
 * @param visible_to_roles The staff roles (with `__any`) that can see the chat message, if we're using them.  Ignores
 * `'admin'` for now, since it's ambiguous, and we're not using it for chat.
 * @param manufacturer_id The lab ID.  Used if we get permissions from `visible_to_org_ids`.  Ignored otherwise.
 * @param partner_id The practice ID.  Used if we get permissions from `visible_to_org_ids`.  Ignored otherwise.
 * @returns The set of {@link IOrganizationType} values whose members might be able to view the message.
 */
export function getChatVisibilityOrgTypes<TRole extends StaffRoleWithAny = StaffRoleWithAny>(
    { visible_to_org_ids = [], visible_to_roles = [] }: Partial<ChatMessageVisibilityProperties<TRole>>,
    { manufacturer_id, partner_id }: ChatMessageVisibilityOrganizationIds,
): ReadonlySet<IOrganizationType> {
    const result = new Set<IOrganizationType>();
    if (visible_to_roles.length > 0) {
        // We do not use visible_to_org_ids in this code path, on purpose.  We want to
        // deprecate visible_to_org_ids, eventually.
        for (const staffRoleWithAny of visible_to_roles) {
            // Ignore admin because 1) we shouldn't have it (yet) and 2) the function doesn't support it
            if (staffRoleWithAny !== 'admin') {
                result.add(getOrganizationTypeFromStaffRoleWithAny(staffRoleWithAny));
            }
        }
    } else {
        for (const orgId of visible_to_org_ids) {
            // Because the three IDs are not necessarily distinct, we can add multiple org types for one orgId.
            // We probably won't, but nothing forbids it right now.
            if (orgId === DANDY_INTERNAL_ID) {
                result.add('internal');
            }
            if (orgId === manufacturer_id) {
                result.add('lab');
            }
            if (orgId === partner_id) {
                result.add('practice');
            }
        }
    }
    return result;
}
