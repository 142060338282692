import { LabsGqlFilterCombinator as Combinator } from '@orthly/graphql-schema';
import { ToggleButtonGroup, ToggleButton } from '@orthly/ui-primitives';
import React from 'react';

interface CombinatorSwitchProps {
    combinator: Combinator;
    setCombinator: (combinator: Combinator) => void;
}

export const OrderFilterCombinatorSwitch: React.FC<CombinatorSwitchProps> = ({ setCombinator, combinator }) => {
    return (
        <ToggleButtonGroup
            style={{ paddingBottom: 16 }}
            value={combinator}
            exclusive
            size={'small'}
            onChange={(_event, value) => {
                setCombinator(value === Combinator.And ? Combinator.And : Combinator.Or);
            }}
        >
            <ToggleButton style={{ height: 24 }} value={Combinator.And}>
                And
            </ToggleButton>
            <ToggleButton style={{ height: 24 }} value={Combinator.Or}>
                Or
            </ToggleButton>
        </ToggleButtonGroup>
    );
};
