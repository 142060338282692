import { useMobileDialogStyles } from '../VisualConstants.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { DoctorImages } from './DoctorImages';
import { PracticeFullScreenDialog } from '@orthly/dentin';
import { ChevronLeft } from '@orthly/ui';
import { Button, FlossPalette, Text, Grid, Slide } from '@orthly/ui-primitives';
import React from 'react';

export const MobileDoctorImagesDialog: React.VFC = () => {
    const classes = useMobileDialogStyles();
    const { setIsMobileDoctorImagesDialogOpen } = useGuidedWaxupContext();

    return (
        <PracticeFullScreenDialog dialogPaperStyle={{ backgroundColor: FlossPalette.TAN }}>
            <Slide in={true} direction={'left'}>
                <Grid container direction={'column'}>
                    <Grid item className={classes.dialog}>
                        <Grid item container className={classes.dialogContentContainer}>
                            <Grid item container className={classes.goBackContainer}>
                                <Button
                                    variant={'secondary'}
                                    className={classes.goBackButton}
                                    onClick={() => {
                                        setIsMobileDoctorImagesDialogOpen(false);
                                    }}
                                >
                                    {<ChevronLeft />}
                                </Button>
                            </Grid>
                            <Grid item className={classes.title}>
                                <Text variant={'body2'} medium>
                                    Images
                                </Text>
                            </Grid>
                        </Grid>
                        <DoctorImages />
                    </Grid>
                </Grid>
            </Slide>
        </PracticeFullScreenDialog>
    );
};
