import { FlossPalette, stylesFactory, Dialog } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    dialogRoot: {},
    contentWrap: {
        position: 'relative',
        backgroundColor: FlossPalette.WHITE,
        height: 'auto',
        overflow: 'hidden',
        minHeight: 'calc(100vh - 64px)',
        [theme.breakpoints.down('md')]: {
            minHeight: 'unset',
            height: '100%',
            flexDirection: 'column',
            overflow: 'scroll',
        },
    },
    dialogPaper: {
        backgroundColor: 'transparent',
        minHeight: '100vh',
        [theme.breakpoints.down('md')]: {
            height: '100vh',
            minHeight: 'unset',
        },
    },
    backdrop: {
        backgroundColor: FlossPalette.WHITE,
        transition: 'none !important',
    },
    dialogContainer: {
        alignItems: 'stretch',
        flexDirection: 'column',
        flexWrap: 'nowrap',
    },
}));

interface PracticeFullScreenDialogProps {
    open?: boolean;
    dialogPaperStyle?: React.CSSProperties;
}

export const PracticeFullScreenDialog: React.FC<PracticeFullScreenDialogProps> = props => {
    const classes = useStyles();
    return (
        <Dialog
            open={props.open ?? true}
            fullScreen
            BackdropProps={{ className: classes.backdrop }}
            classes={{ container: classes.dialogContainer, root: classes.dialogRoot }}
            disableEnforceFocus={true}
            PaperProps={{ elevation: 0, className: classes.dialogPaper, style: props.dialogPaperStyle }}
        >
            {props.children}
        </Dialog>
    );
};
