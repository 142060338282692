import { PrintTable } from '../../../../../../components/PrintTable';
import { useQuery } from '@apollo/client';
import type {
    AdminOrderDetailReviewBlockLabSalesOrder_FragmentFragment,
    AdminOrderDetailReviewBlockReviewSubmission_FragmentFragment,
    FragmentType,
} from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { Chip, Grid, Text } from '@orthly/ui-primitives';
import { OrderDetailBlock } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const AdminOrderDetailReviewBlockLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailReviewBlockLabSalesOrder_Fragment on LabSalesOrderDTO {
        id
        items_display_info {
            item_id
            full_display_name
        }
    }
`);

const AdminOrderDetailReviewBlockReviewSubmission_Fragment = graphql(`
    fragment AdminOrderDetailReviewBlockReviewSubmission_Fragment on IReviewSubmission {
        doctor_is_happy
        items {
            item_id
            star_rating
            notes
            chairside_adjustment_minutes
            tags {
                title
                category
                review_tag_id
                other_notes
            }
        }
    }
`);

const AdminOrderDetailReviewBlockGetReviewSubmission_Query = graphql(`
    query GetReviewSubmission($order_id: String!) {
        getReview(lab_order_id: $order_id) {
            ...AdminOrderDetailReviewBlockReviewSubmission_Fragment
        }
    }
`);

type SalesOrder = AdminOrderDetailReviewBlockLabSalesOrder_FragmentFragment;
type ReviewSubmission = AdminOrderDetailReviewBlockReviewSubmission_FragmentFragment;
type Tag = ReviewSubmission['items'][number]['tags'][number];

interface OrderDetailReviewSubmissionTagCellProps {
    tags: Tag[];
}

const OrderDetailReviewSubmissionTagCell: React.VFC<OrderDetailReviewSubmissionTagCellProps> = ({ tags }) => (
    <Grid container>
        {tags.map((tag, idx) => (
            <div key={`chip-${tag.review_tag_id ?? idx}`} style={{ padding: 2 }}>
                <Chip
                    label={!tag.other_notes ? `${tag.category}: ${tag.title}` : `${tag.category} - ${tag.other_notes}`}
                />
                <br />
            </div>
        ))}
    </Grid>
);

interface OrderDetailReviewSubmissionProps {
    salesOrder: SalesOrder;
    reviewSubmission: ReviewSubmission;
}

const OrderDetailReviewSubmission: React.VFC<OrderDetailReviewSubmissionProps> = ({
    salesOrder: salesOrderProp,
    reviewSubmission,
}) => {
    const itemDisplayInfo = _.keyBy(salesOrderProp.items_display_info, i => i.item_id);
    const hasStarRating = reviewSubmission.items.some(item => item.star_rating !== null);

    const rows = _.compact([
        !hasStarRating && typeof reviewSubmission.doctor_is_happy === 'boolean'
            ? {
                  _: `Are you happy with the\nquality of the order?`,
                  star_rating: null,
                  feedback: reviewSubmission.doctor_is_happy ? `Yes! Keep it up` : `No. You can do better`,
                  chairside_adjustment_minutes: null,
                  notes: null,
              }
            : null,
        ...reviewSubmission.items.map((reviewItem, index) => ({
            _: itemDisplayInfo[reviewItem.item_id]?.full_display_name ?? `Item #${index + 1}`,
            star_rating: reviewItem.star_rating,
            feedback: <OrderDetailReviewSubmissionTagCell tags={reviewItem.tags} />,
            chairside_adjustment_minutes: reviewItem.chairside_adjustment_minutes,
            notes: reviewItem.notes,
        })),
    ]);

    if (!rows.length) {
        <Text variant={'body2'}>Feedback exists for this order but is not viewable in portal (legacy)</Text>;
    }

    return <PrintTable rows={rows} />;
};

interface OrderDetailReviewBlockProps {
    salesOrder: FragmentType<typeof AdminOrderDetailReviewBlockLabSalesOrder_Fragment>;
}

export const OrderDetailReviewBlock: React.VFC<OrderDetailReviewBlockProps> = ({ salesOrder: salesOrderProp }) => {
    const salesOrder = getFragmentData(AdminOrderDetailReviewBlockLabSalesOrder_Fragment, salesOrderProp);

    const { data: reviewSubmissionData } = useQuery(AdminOrderDetailReviewBlockGetReviewSubmission_Query, {
        variables: { order_id: salesOrder.id },
    });

    const reviewSubmission = getFragmentData(
        AdminOrderDetailReviewBlockReviewSubmission_Fragment,
        reviewSubmissionData?.getReview,
    );

    if (!reviewSubmission) {
        return <OrderDetailBlock title={'Feedback not provided'} variant={'full'} />;
    }

    return (
        <OrderDetailBlock title={'Feedback'} variant={'full'}>
            <OrderDetailReviewSubmission salesOrder={salesOrder} reviewSubmission={reviewSubmission} />
        </OrderDetailBlock>
    );
};
