import { RefabFaultSelector } from './RefabFaultSelector';
import type {
    RefabOption,
    RefabReasonReturnMap,
    RefabInput,
    RefabInputWithCategory,
    RefabReasonSelectorProps,
} from './RefabReasons.types';
import type { LabsGqlRefabReasonOptionFragment } from '@orthly/graphql-operations';
import { useRefabReasonsForOrderItemsQuery } from '@orthly/graphql-react';
import type { LabsGqlOrderRefabReasonInput, LabsGqlRefabFault } from '@orthly/graphql-schema';
import type { PartialObjectDeep } from '@orthly/ui';
import { LoadBlocker, SimpleMultiSelect } from '@orthly/ui';
import { CheckboxPrimitive, Grid, IconButton, Text, CloseIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const defaultRefabReasons: RefabReasonReturnMap = {
    Other: false,
};

function refabReasonsToReturnMap(reasons: LabsGqlRefabReasonOptionFragment[]) {
    return _.fromPairs(reasons.map(r => [r.id, r.requires_return]));
}

export const isValidOrderRefabReason = (
    partialReason: PartialObjectDeep<LabsGqlRefabReasonOptionFragment | LabsGqlOrderRefabReasonInput>,
): partialReason is LabsGqlOrderRefabReasonInput => !!partialReason.name;

type UseRefabReasonOptionsRes = {
    reasons: RefabOption[];
    loading: boolean;
    requiresReturn: boolean;
};

export function useRefabReasonOptions(order_id: string, selectedReasons: RefabInput[]): UseRefabReasonOptionsRes {
    const { data, loading } = useRefabReasonsForOrderItemsQuery({ variables: { order_id } });
    return React.useMemo<UseRefabReasonOptionsRes>(() => {
        // If we have finished loading and no refab reasons apply to this order, use the default
        // refab reasons. This is a safe guard to prevent blocking progress in the app when refab
        // reasons must be selected to proceed.
        const reasonsReturnMap: RefabReasonReturnMap =
            !loading && data?.refabReasonsForOrderItems.length === 0
                ? defaultRefabReasons
                : refabReasonsToReturnMap(data?.refabReasonsForOrderItems || []);
        // The order only requires a return if every current, selected refab reason requires a return.
        const requiresReturn = _.every(
            selectedReasons.filter(
                (r): r is LabsGqlOrderRefabReasonInput & { refab_reason_id: string } => !!r.refab_reason_id,
            ),
            r => reasonsReturnMap[r.refab_reason_id],
        );
        const reasons = data?.refabReasonsForOrderItems ?? [];
        return { loading, requiresReturn, reasons };
    }, [data, loading, selectedReasons]);
}

interface ConfirmSelectionButtonProps {
    onClick: () => void;
}

const ConfirmSelectionButton: React.FC<ConfirmSelectionButtonProps> = props => {
    const { onClick } = props;
    return (
        <IconButton
            onClick={e => {
                e.stopPropagation();
                onClick();
            }}
            style={{ position: 'absolute', right: 8, top: 2, padding: '0 8px', minWidth: 0 }}
        >
            <CloseIcon />
        </IconButton>
    );
};

function optionToInput(selected: RefabOption, selectedFault?: LabsGqlRefabFault): RefabInput {
    const { name, id } = selected;
    return {
        name,
        fault_override: selectedFault,
        refab_reason_id: id,
    };
}

const withCategory = (input: RefabInput, allReasons: RefabOption[]): RefabInputWithCategory => {
    const idMatch = allReasons.find(r => r.id === input.refab_reason_id);
    const nameMatch = allReasons.find(r => r.name === input.name);
    const category = idMatch?.category ?? nameMatch?.category ?? 'Other';
    return { ...input, category };
};

export interface RefabReasonCategory {
    category: string | null;
    categoryLabel: string;
    reasonOptions: RefabOption[];
    initialExpanded: boolean;
}

function categoryIsInternalOnly(options: RefabOption[]): boolean {
    return _.every(options, r => r.internal_only);
}

function getCategoryLabel(label: string, options: RefabOption[]): string {
    if (categoryIsInternalOnly(options)) {
        return `${label} (Internal Only)`;
    }

    return label;
}

function getReasonsByCategory(selectedReasons: RefabInput[], allReasons: RefabOption[]): RefabReasonCategory[] {
    const getDefaultLabel = (category?: string | null) => category ?? 'Other';
    const selectedCategories = new Set(
        selectedReasons.map(s => withCategory(s, allReasons)).map(s => getDefaultLabel(s.category)),
    );
    const allReasonsByCategoryName = _.groupBy(allReasons, r => getDefaultLabel(r.category));

    const asList = Object.entries(allReasonsByCategoryName).map(([name, options]) => ({
        categoryLabel: getCategoryLabel(name, options),
        category: name,
        reasonOptions: options,
        initialExpanded: selectedCategories.has(name),
    }));

    return _.orderBy(asList, [e => e.categoryLabel === 'Other', e => e.categoryLabel.toLowerCase()]);
}

export const RefabReasonSelector: React.FC<RefabReasonSelectorProps> = props => {
    const { loading, possibleReasons, selected, setSelected, allowFaultOverride, headerText } = props;
    const [open, setOpen] = React.useState<string>();
    const reasonsByCategory = getReasonsByCategory(selected, possibleReasons);
    const [expanded, setExpanded] = React.useState<string[]>(
        _.compact(
            reasonsByCategory.map(({ categoryLabel, initialExpanded }) =>
                initialExpanded ? categoryLabel : undefined,
            ),
        ),
    );

    // Want to be able to do category filtering without affecting the selected inputs
    const selectedWithCategories = selected.map(s => withCategory(s, possibleReasons));
    const setSelectedWithCategories = React.useCallback(
        (selected: RefabInputWithCategory[]) => {
            setSelected(selected.map(({ category, ...toSave }) => ({ ...toSave })));
        },
        [setSelected],
    );

    return (
        <LoadBlocker blocking={loading}>
            <Grid container item direction={'column'}>
                <Text variant={'h6'}>{headerText || 'Why do you need a refabrication?'}</Text>
                {reasonsByCategory.map(({ category, categoryLabel, reasonOptions }) => {
                    // Since orders could have arbitrary strings on them as reasons prior to conversion,
                    // it is possible they have outdated reasons on them when loaded
                    const orphanedOptions = selected
                        .filter(s => !possibleReasons.map(p => p.name).includes(s.name))
                        .map(s => ({ value: s.name, label: `${s.name} (Deprecated)`, disabled: true }));
                    const selectedInCategory = selectedWithCategories.filter(s => s.category === category);

                    const persistCategoryResult = (result: string[] | undefined) => {
                        const otherCategories = selectedWithCategories.filter(s => s.category !== category);
                        const preExistingThisCategory = selectedInCategory.filter(s => result?.includes(s.name));
                        if (!result || result.length === 0) {
                            setSelectedWithCategories(otherCategories);
                            setExpanded(prev => prev.filter(c => c !== categoryLabel));
                        } else {
                            const newlySelected = result.filter(
                                r => !preExistingThisCategory.map(p => p.name).includes(r),
                            );
                            const newInputs = _.compact(
                                newlySelected.map(name => reasonOptions.find(r => r.name === name)),
                            ).map(o => optionToInput(o));
                            setSelectedWithCategories([...otherCategories, ...preExistingThisCategory, ...newInputs]);
                        }
                    };

                    const categoryIsSelected = expanded.includes(categoryLabel);

                    return (
                        <Grid container style={{ alignItems: 'center', marginBottom: '12px' }} key={category}>
                            <Grid item xs={2} md={1}>
                                <CheckboxPrimitive
                                    color={'secondary'}
                                    checked={categoryIsSelected}
                                    onClick={() => {
                                        if (categoryIsSelected) {
                                            setSelectedWithCategories(
                                                selectedWithCategories.filter(s => s.category !== category),
                                            );
                                            setExpanded(prev => prev.filter(c => c !== categoryLabel));
                                        } else {
                                            setExpanded(prev => prev.concat([categoryLabel]));
                                            if (reasonOptions.length === 1 && reasonOptions[0]) {
                                                setSelectedWithCategories([
                                                    ...selected,
                                                    optionToInput(reasonOptions[0]),
                                                ]);
                                            } else {
                                                setOpen(categoryLabel);
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                            {/* Fun fact (blame Ricardo): 170px is the minimum length required to accommodate "Interproximal Contact" in our standard font */}
                            <Grid item xs={10} md={5}>
                                <Text style={{ minWidth: '170px' }}>{categoryLabel}</Text>
                            </Grid>
                            {categoryIsSelected && (
                                <Grid item xs={12} md={6}>
                                    <SimpleMultiSelect
                                        SelectProps={{
                                            open: open === categoryLabel,
                                            onOpen: () => setOpen(categoryLabel),
                                            onClose: () => setOpen(undefined),
                                            MenuProps: {
                                                MenuListProps: {
                                                    style: {
                                                        maxHeight: 256,
                                                        overflowY: 'auto',
                                                        paddingTop: '32px',
                                                    },
                                                    subheader: (
                                                        <ConfirmSelectionButton onClick={() => setOpen(undefined)} />
                                                    ),
                                                },
                                                anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
                                            },
                                            variant: 'standard',
                                        }}
                                        label={`${categoryLabel} issues`}
                                        options={reasonOptions
                                            .map(r => ({ value: r.name, label: r.label }))
                                            .concat(orphanedOptions)}
                                        value={selectedInCategory.map(s => s.name)}
                                        onChange={result => {
                                            persistCategoryResult(result);
                                        }}
                                    />
                                </Grid>
                            )}
                            {categoryIsSelected && selectedInCategory.length > 0 && allowFaultOverride && (
                                <RefabFaultSelector
                                    {...props}
                                    selectedInCategory={selectedInCategory}
                                    setSelected={setSelectedWithCategories}
                                />
                            )}
                        </Grid>
                    );
                })}
            </Grid>
        </LoadBlocker>
    );
};
