import type { StaffMemberSession } from '@orthly/session-client';
import { useSession } from '@orthly/session-client';
import { RootActionDialog, useOpenDevModal } from '@orthly/ui';
import { Text } from '@orthly/ui-primitives';
import React from 'react';

const DevModalContent = React.lazy(() => import('./DevModalContent'));

const InternalOnlyWarning = () => (
    <>
        <Text variant={'h4'}>Not Authorized</Text>
        <Text variant={'body1'}>
            This is a feature used for internal debugging. If you think you should have access, please reach out to
            support.
        </Text>
    </>
);

const isInternalUser = (session?: StaffMemberSession) => {
    if (!session) {
        return false;
    }
    if (session.organization_type === 'internal') {
        return true;
    }
    if (session.isImpersonation) {
        return true;
    }
    if (session.user.email.endsWith('@orthly.com')) {
        return true;
    }
    if (session.user.email.endsWith('@meetdandy.com')) {
        return true;
    }
    if (session.user.email.endsWith('@meetdandy.dev')) {
        return true;
    }
    if (window.location.hostname === 'localhost') {
        return true;
    }
    return false;
};

export const DevModal: React.VFC = () => {
    const [open, setOpen] = useOpenDevModal();
    const session = useSession();
    const isAuthorized = isInternalUser(session);

    return (
        <>
            {open && (
                <RootActionDialog
                    loading={false}
                    open={open}
                    setOpen={setOpen}
                    hideButton
                    title={''}
                    content={
                        <React.Suspense fallback={<div>Loading</div>}>
                            {isAuthorized ? <DevModalContent /> : <InternalOnlyWarning />}
                        </React.Suspense>
                    }
                    showCloseButton
                />
            )}
        </>
    );
};
