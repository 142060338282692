/* eslint-disable max-lines,no-nested-ternary */
import type { AutomationListItemData } from '../../../Automations/screens/AutomationsList/AutomationsListFilterToolbar';
import { ChannelFilterOption } from '../../components/FilterComponents';
import { usePalateState } from '../../state/Palate.reducer';
import { LINK_CONTAINER_HEIGHT } from '../../usePalateStyles';
import {
    SalesforceEscalationDetails,
    PracticeTimelineItemDetails,
    ReviewSubmissionDetails,
    PracticeAutomationDetails,
} from './PalatePracticeInteractions';
import type {
    ReviewOrder,
    PracticeTimelineArgs,
    PalatePracticeInteraction,
    PalatePracticeTimelineSectionProps,
    PalatePracticeTimelineProps,
} from './PalatePracticeTimeline.types';
import type {
    LabsGqlPracticeTimelineItemFragment,
    LabsGqlLabOrderFragment,
    LabsGqlReviewSubmissionFragment,
    LabsGqlUserDtoFragment,
    LabsGqlOrderAutomationListElementFragment,
} from '@orthly/graphql-operations';
import {
    useGetPracticeInteractionsQuery,
    useTimelineItemsQuery,
    useGetReviewsQuery,
    useTagsQuery,
    useListOrderAutomationsQuery,
    useListRetainerUsersQuery,
    useOrdersByIds,
} from '@orthly/graphql-react';
import { LabsGqlFilterComparator, LabsGqlTaggableEntityType } from '@orthly/graphql-schema';
import { LoadBlocker } from '@orthly/ui';
import { FlossPalette, Text, Grid, KeyboardArrowDownIcon } from '@orthly/ui-primitives';
import { ChatStickyProvider, useChatStickyParent, useChatStickyChild, DandyChatStickyDate } from '@orthly/veneer';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

function scrollRefToBottom(ref?: HTMLDivElement | null, scrollSmooth: boolean = false) {
    if (!!ref) {
        ref.scrollTo({
            top: ref.scrollHeight - ref.clientHeight,
            behavior: scrollSmooth ? 'smooth' : 'auto',
        });
    }
}

function useScrollToBottomEffectWithPages(timelineLength: number) {
    const chatScrollRef = React.useRef<HTMLDivElement>(null);

    function scrollBottom() {
        const out = chatScrollRef.current;
        if (!out) {
            return;
        }
        const isScrolledToBottom = out.scrollHeight - out.clientHeight <= out.scrollTop + 1;
        if (!isScrolledToBottom) {
            scrollRefToBottom(out);
        }
    }

    // Scroll to bottom when the number of timeline items changes or if the input is expanded/closed
    React.useEffect(scrollBottom, [timelineLength]);
    return { chatScrollRef, scrollBottom };
}

function getPracticeAutomations(
    automations: LabsGqlOrderAutomationListElementFragment[],
    practiceId: string,
    practiceTags: string[],
    users: LabsGqlUserDtoFragment[],
) {
    const usersById = _.keyBy(users, u => u.id);
    const automationsRaw: LabsGqlOrderAutomationListElementFragment[] = automations ?? [];
    return automationsRaw
        .filter(automation => {
            return automation.filter.criteria.find(criteria => {
                const isPracticeMatch =
                    criteria.filter_id === 'by_partner' &&
                    criteria.comparator === LabsGqlFilterComparator.Equals &&
                    criteria.comparison_value === practiceId;
                const isTagMatch =
                    criteria.filter_id === 'practice_tags' &&
                    criteria.comparator === LabsGqlFilterComparator.Equals &&
                    _.isArray(criteria.comparison_value) &&
                    _.intersection(criteria.comparison_value, practiceTags).length > 0;
                return isPracticeMatch || isTagMatch;
            });
        })
        .map<AutomationListItemData>(a => ({
            __typename: a.__typename,
            id: a.id,
            name: a.name,
            description: a.description,
            created_at: a.created_at,
            updated_at: a.updated_at,
            triggered_by: a.triggered_by,
            created_by_user_id: a.created_by_user_id,
            archived: a.archived,
            filter: a.filter,
            folder: a.folder,
            actions: [],
            creator: usersById[a.created_by_user_id] ?? null,
        }));
}

function getReviewOrders(orders: LabsGqlLabOrderFragment[], reviews: LabsGqlReviewSubmissionFragment[]) {
    return reviews.reduce<ReviewOrder[]>((prev, review) => {
        const order = orders.find(o => o.id === review.lab_order_id);
        if (!!order) {
            prev.push({ order, review, __typename: 'ReviewOrder' });
        }
        return prev;
    }, []);
}

function usePracticeTimeline(args: PracticeTimelineArgs) {
    const { opsInteractions, practiceAutomations, interactions, reviewOrders, channelFilter } = args;
    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    return React.useMemo(() => {
        const timeline: {
            [key: string]: PalatePracticeInteraction[];
        } = {};

        const showAllInteractions = !channelFilter || channelFilter.length === 0;
        const showSalesforceInteractions = channelFilter?.includes(ChannelFilterOption.salesforce);
        const showPracticeInteractions = channelFilter?.includes(ChannelFilterOption.practice);
        const showAutomations = channelFilter?.includes(ChannelFilterOption.automations);
        const showReviews = channelFilter?.includes(ChannelFilterOption.reviews);

        const timelineInteractions: PalatePracticeInteraction[] = showAllInteractions
            ? [...opsInteractions, ...practiceAutomations, ...reviewOrders]
            : [
                  ...(showPracticeInteractions ? opsInteractions : []),
                  ...(showAutomations ? practiceAutomations : []),
                  ...(showReviews ? reviewOrders : []),
              ];

        const groupedInteractions = _.groupBy(timelineInteractions, i =>
            moment(getInteractionDateTime(i) ?? '').format('MMMM Do YYYY'),
        );

        // add grouped interactions to timeline
        _.assignWith(timeline, groupedInteractions, (timelineObjectValue, interactionObjectValue) => {
            // defined only if there are multiple interaction groups under the same date
            return _.isUndefined(timelineObjectValue)
                ? interactionObjectValue
                : timelineObjectValue.concat(interactionObjectValue);
        });

        const conversationDates = Object.keys(timeline);
        const lastConversationDate = conversationDates.pop();

        if (showAllInteractions || showSalesforceInteractions) {
            interactions.forEach(escalation => {
                if (escalation.CreatedDate) {
                    const escalationDate = moment(escalation.CreatedDate).format('MMMM Do YYYY');
                    // if escalationDate already exists in timeline, add escalation to the list of interactions on
                    // escalationDate and then sort that list by date desc
                    if (timeline[escalationDate]) {
                        timeline[escalationDate]?.push(escalation);
                        timeline[escalationDate]?.sort((interactionA, interactionB) => {
                            const dateA = getInteractionDateTime(interactionA);
                            const dateB = getInteractionDateTime(interactionB);
                            return dateA && dateB && moment(dateB).isAfter(moment(dateA)) ? 1 : -1;
                        });
                    } else if (
                        (!showAllInteractions && showSalesforceInteractions) ||
                        moment(escalationDate, 'MMMM Do YYYY').isAfter(moment(lastConversationDate, 'MMMM Do YYYY'))
                    ) {
                        // if escalationDate is not in the timeline,
                        // only add it to the timeline if it's after the last conversation date
                        timeline[escalationDate] = [escalation];
                    }
                } else if (lastConversationDate) {
                    // if for some reason escalation doesn't have CreatedDate, just add it to the end of the timeline
                    timeline[lastConversationDate]?.push(escalation);
                }
            });
        }
        return timeline;
    }, [reviewOrders, practiceAutomations, opsInteractions, interactions, channelFilter]);
}

function getInteractionDateTime(interaction: PalatePracticeInteraction) {
    if (interaction.__typename === 'SalesforceCase') {
        return interaction.CreatedDate;
    }
    if (interaction.__typename === 'PracticeTimelineItem') {
        return interaction.date;
    }
    if (interaction.__typename === 'OrderAutomation') {
        return interaction.created_at;
    }
    if (interaction.__typename === 'ReviewOrder') {
        return interaction.review.created_at;
    }
}

const ScrollToBottomButton: React.VFC<{ current?: HTMLDivElement | null }> = ({ current }) => (
    <Grid
        container
        alignItems={'center'}
        justifyContent={'center'}
        style={{
            position: 'absolute',
            bottom: 8,
            left: 'calc(50% - 16px)',
            width: 32,
            height: 32,
            backgroundColor: FlossPalette.TAN,
            borderRadius: 16,
            border: '1px solid white',
            cursor: 'pointer',
        }}
        onClick={() => scrollRefToBottom(current, true)}
    >
        <KeyboardArrowDownIcon style={{ color: FlossPalette.STAR_GRASS }} />
    </Grid>
);

export const PalatePracticeTimelineSection: React.FC<PalatePracticeTimelineSectionProps> = props => {
    const { date, interactions, practiceId } = props;
    const today = moment().format('MMMM Do YYYY');
    const yesterday = moment().subtract(1, 'days').format('MMMM Do YYYY');
    const ref = React.useRef<HTMLDivElement | null>(null);
    useChatStickyChild(ref, moment(date, 'MMMM Do YYYY').toDate());

    return (
        <Grid container ref={ref}>
            <Grid
                container
                style={{ backgroundColor: FlossPalette.TAN, borderTop: `1px solid ${FlossPalette.DARK_TAN}` }}
            >
                <Text variant={'caption'} medium style={{ color: FlossPalette.GRAY, padding: '4px 32px' }}>
                    {date === today ? 'Today' : date === yesterday ? 'Yesterday' : date}
                </Text>
            </Grid>
            {interactions?.map((interaction, index) =>
                // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                // eslint-disable-next-line no-nested-ternary
                interaction.__typename === 'SalesforceCase' ? (
                    <SalesforceEscalationDetails
                        key={interaction.Id}
                        escalation={interaction}
                        practiceId={practiceId}
                    />
                ) : // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                // eslint-disable-next-line no-nested-ternary
                interaction.__typename === 'PracticeTimelineItem' ? (
                    <PracticeTimelineItemDetails key={index} item={interaction} practiceId={practiceId} />
                ) : // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                // eslint-disable-next-line no-nested-ternary
                interaction.__typename === 'OrderAutomation' ? (
                    <PracticeAutomationDetails key={index} automation={interaction} practiceId={practiceId} />
                ) : interaction.__typename === 'ReviewOrder' ? (
                    <ReviewSubmissionDetails key={index} reviewOrder={interaction} practiceId={practiceId} />
                ) : null,
            )}
        </Grid>
    );
};

function useReviewedOrders(practiceId: string) {
    const { data: reviewsData, loading: reviewsLoading } = useGetReviewsQuery({
        fetchPolicy: 'cache-first',
        variables: { partner_id: practiceId },
    });
    const orderIds = _.compact(reviewsData?.getReviews?.map(review => review.lab_order_id) ?? []);
    const { orders, loading: ordersLoading } = useOrdersByIds(orderIds, {
        fetchPolicy: 'cache-first',
        skip: !orderIds.length,
    });
    const reviewOrders = getReviewOrders(_.compact(orders), reviewsData?.getReviews ?? []);
    return { reviewOrders, loading: reviewsLoading || ordersLoading };
}

const PalatePracticeTimelineBase: React.FC<PalatePracticeTimelineProps> = props => {
    const { practiceId, tabContainerHeight } = props;
    const timelineFilters = usePalateState(s => s.timelineFilters);
    const channelFilter = timelineFilters?.find(filter => filter.filter_id === 'by_channel')?.comparison_value;
    const staffFilter = timelineFilters?.find(filter => filter.filter_id === 'by_staff')?.comparison_value;

    const { data: practiceData, loading: practiceLoading } = useGetPracticeInteractionsQuery({
        fetchPolicy: 'cache-first',
        variables: { practice_id: practiceId, customer_names: staffFilter ?? [] },
    });
    const { data: timelineData, loading: timelineLoading } = useTimelineItemsQuery({
        fetchPolicy: 'cache-first',
        variables: { entityId: practiceId, entityType: 'practice' },
    });
    const { data: tagsData, loading: tagsLoading } = useTagsQuery({
        fetchPolicy: 'cache-first',
        variables: { id: null, entity_id: practiceId, entity_type: LabsGqlTaggableEntityType.Practice },
    });
    const { data: automationData, loading: automationsLoading } = useListOrderAutomationsQuery({
        fetchPolicy: 'cache-first',
    });
    const creatingUserIds = React.useMemo(() => {
        return _.uniq(automationData?.listOrderAutomations.map(a => a.created_by_user_id) ?? []);
    }, [automationData]);
    const { data: usersData, loading: usersLoading } = useListRetainerUsersQuery({
        nextFetchPolicy: 'cache-first',
        fetchPolicy: 'cache-first',
        skip: creatingUserIds.length === 0,
        variables: { filter: { ids: creatingUserIds } },
    });
    const practiceAutomations = getPracticeAutomations(
        automationData?.listOrderAutomations ?? [],
        practiceId,
        (tagsData?.tags ?? []).map(tag => tag.id),
        usersData?.listUsers ?? [],
    );
    const opsInteractions = (timelineData?.timelineItems ?? []).filter(
        (i): i is LabsGqlPracticeTimelineItemFragment => i.__typename === 'PracticeTimelineItem',
    );
    const { reviewOrders, loading: reviewsLoading } = useReviewedOrders(practiceId);
    const interactions = practiceData?.getPracticeInteractions;

    const practiceTimeline = usePracticeTimeline({
        opsInteractions,
        practiceAutomations,
        reviewOrders,
        channelFilter,
        interactions: interactions?.sf_escalations ?? [],
    });

    // sort dates in ascending order
    const sortedTimelineKeys = Object.keys(practiceTimeline).sort((dateA, dateB) =>
        moment(dateB, 'MMMM Do YYYY').isBefore(moment(dateA, 'MMMM Do YYYY')) ? 1 : -1,
    );

    const numberOfElements = Object.keys(practiceTimeline).reduce((prev, key) => {
        return prev + (practiceTimeline[key]?.length ?? 0);
    }, 0);

    const { chatScrollRef } = useScrollToBottomEffectWithPages(numberOfElements);
    const visible_dates = useChatStickyParent(chatScrollRef);
    const stickyDate = React.useMemo(() => _.minBy(visible_dates, d => d.valueOf()), [visible_dates]);
    const loading =
        practiceLoading || timelineLoading || reviewsLoading || usersLoading || automationsLoading || tagsLoading;

    const showScrollToBottomButton =
        chatScrollRef?.current &&
        chatScrollRef.current.scrollHeight - chatScrollRef.current.scrollTop > chatScrollRef.current.clientHeight + 100;

    return (
        <LoadBlocker blocking={loading}>
            <Grid container>
                {chatScrollRef?.current?.scrollTop !== 0 && stickyDate && (
                    <DandyChatStickyDate date={stickyDate} style={{ padding: '2px 8px', top: 8, left: 32 }} />
                )}
                {showScrollToBottomButton && <ScrollToBottomButton current={chatScrollRef?.current} />}
                {/* must specify height for sticky component */}
                <Grid
                    container
                    ref={chatScrollRef}
                    style={{
                        height: `calc(100vh - ${tabContainerHeight + LINK_CONTAINER_HEIGHT}px)`,
                        overflow: 'scroll',
                        flexDirection: 'column',
                        flexWrap: 'nowrap',
                    }}
                >
                    {sortedTimelineKeys.length > 0 ? (
                        <>
                            {sortedTimelineKeys.map((date, idx) => (
                                <PalatePracticeTimelineSection
                                    key={idx}
                                    date={date}
                                    interactions={practiceTimeline[date]}
                                    practiceId={practiceId}
                                />
                            ))}
                        </>
                    ) : (
                        <Grid container style={{ padding: 16 }}>
                            {!loading && 'No timeline data found'}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </LoadBlocker>
    );
};

export const PalatePracticeTimeline: React.FC<PalatePracticeTimelineProps> = props => {
    return (
        <ChatStickyProvider>
            <PalatePracticeTimelineBase {...props} />
        </ChatStickyProvider>
    );
};
