import { OrderDetailBlock } from '../OrderDetails';
import type { ToothToLastStepMap } from './AlignersIPR.util';
import { AlignersIPRUtils } from './AlignersIPR.util';
import type {
    OrderDetailAlignersIprBlockIprStepRow_FragmentFragment,
    FragmentType,
} from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import {
    FlossPalette,
    stylesFactory,
    Text,
    Collapse,
    Grid,
    TablePrimitive as Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const NUM_TEETH = 32;

const IPR_TOOTH_NUMBERS = _.concat(_.range(1, NUM_TEETH / 2 + 1), _.reverse(_.range(NUM_TEETH / 2 + 1, NUM_TEETH + 1)));

const useStyles = stylesFactory(theme => ({
    titleCell: {
        padding: '0 6px',
        color: FlossPalette.GRAY,
        textAlign: 'right',
        fontWeight: 500,
    },
    iprCell: {
        padding: 0,
        textAlign: 'right',
    },
    iprHeader: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    iprText: { marginRight: '-10px' },
    '@global': {
        body: {
            '@media print': {
                maxHeight: '100vh !important',
                overflow: 'hidden',
                position: 'fixed',
            },
        },
    },
    root: {
        '@media print': {
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 10000,
            backgroundColor: FlossPalette.WHITE,
        },
    },
    actions: {
        '@media print': {
            display: 'none',
        },
    },
    printButton: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    iprTableWrapper: {
        position: 'relative',
    },
    stepsLabel: {
        position: 'absolute',
        left: -20,
        bottom: '45%',
        transform: 'rotate( -90deg)',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
            left: 0,
        },
    },
}));

const blackBorderStyle = `1px solid ${FlossPalette.BLACK}`;
const grayBorderStyle = `1px solid ${FlossPalette.DARK_TAN}`;

const OrderDetailAlignersIprBlockIprStepRow_Fragment = graphql(`
    fragment OrderDetailAlignersIprBlockIprStepRow_Fragment on DesignOrderAlignerTreatmentPlanStepDTO {
        step
        interproximal_reductions {
            reduction_in_mm
            tooth
        }
    }
`);

interface IprStepRowProps {
    step: OrderDetailAlignersIprBlockIprStepRow_FragmentFragment;
    toothNumbers: number[];
    toothToLastStepMap: ToothToLastStepMap;
    isCurrentStep: boolean;
}

const IprStepRow: React.VFC<IprStepRowProps> = props => {
    const { step, toothNumbers, toothToLastStepMap, isCurrentStep } = props;
    const classes = useStyles();
    return (
        <TableRow style={{ backgroundColor: isCurrentStep ? FlossPalette.DARK_TAN : undefined }}>
            <TableCell className={classes.titleCell}>{step.step}</TableCell>
            {toothNumbers.map(t => {
                const lastStep = toothToLastStepMap[`${t}`] ?? 0;
                const stepIprs = step.interproximal_reductions.filter(
                    ipr => t === _.toNumber(AlignersIPRUtils.getGridNumberFromIPRTooth(ipr.tooth) ?? '0'),
                );
                const reductionSum = stepIprs.reduce((sum, ipr) => sum + (ipr.reduction_in_mm ?? 0), 0);

                return (
                    <TableCell
                        key={`${step.step}_${t}`}
                        style={{
                            borderLeft: t === NUM_TEETH ? blackBorderStyle : undefined,
                            borderRight: step.step < lastStep && stepIprs.length === 0 ? grayBorderStyle : undefined,
                        }}
                        className={classes.iprCell}
                    >
                        <Text variant={'caption'} className={classes.iprText} color={'ATTENTION'}>
                            {reductionSum > 0 ? reductionSum : ''}
                        </Text>
                    </TableCell>
                );
            })}
        </TableRow>
    );
};

const AlignersIprTable: React.VFC<{
    stepFragments: FragmentType<typeof OrderDetailAlignersIprBlockIprStepRow_Fragment>[];
    currentIndex: number;
}> = props => {
    const steps = getFragmentData(OrderDetailAlignersIprBlockIprStepRow_Fragment, props.stepFragments);
    const classes = useStyles();
    const toothToLastStepMap = AlignersIPRUtils.getToothToLastStepMap(steps);
    return (
        <Grid container className={classes.iprTableWrapper}>
            <Text variant={'body2'} color={'DARK_GRAY'} className={classes.stepsLabel}>
                Steps
            </Text>
            <Table size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        {IPR_TOOTH_NUMBERS.map(t => (
                            <TableCell
                                key={t}
                                className={classes.titleCell}
                                style={{
                                    borderLeft: t === NUM_TEETH ? blackBorderStyle : undefined,
                                    textAlign: 'center',
                                }}
                            >
                                {t}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {steps.map(step => (
                        <IprStepRow
                            step={step}
                            isCurrentStep={step.step === props.currentIndex}
                            key={step.step}
                            toothNumbers={IPR_TOOTH_NUMBERS}
                            toothToLastStepMap={toothToLastStepMap}
                        />
                    ))}
                </TableBody>
            </Table>
        </Grid>
    );
};

const AlignersIprGrid: React.VFC<{
    stepFragments: FragmentType<typeof OrderDetailAlignersIprBlockIprStepRow_Fragment>[];
    open: boolean;
    selected_step_index: number;
}> = props => {
    const { stepFragments, open, selected_step_index } = props;
    const classes = useStyles();

    return (
        <div style={{ padding: '16px 0 16px 8px' }}>
            <Grid container>
                <Grid item xs={6}>
                    <Text className={classes.iprHeader} variant={'body2'} color={'DARK_GRAY'}>
                        Upper
                    </Text>
                </Grid>
                <Grid item xs={6}>
                    <Text className={classes.iprHeader} variant={'body2'} color={'DARK_GRAY'}>
                        Lower
                    </Text>
                </Grid>
            </Grid>
            <Collapse in={open}>
                <AlignersIprTable stepFragments={stepFragments} currentIndex={selected_step_index} />
            </Collapse>
            <Collapse in={!open}>
                <AlignersIprTable
                    stepFragments={stepFragments.slice(selected_step_index, selected_step_index + 1)}
                    currentIndex={selected_step_index}
                />
            </Collapse>
        </div>
    );
};

const IprBlockActions: React.VFC<{ set_open: (val: boolean) => void; open: boolean }> = props => {
    const classes = useStyles();
    return (
        <Grid container className={classes.actions}>
            <Button
                variant={'ghost'}
                onClick={() => props.set_open(!props.open)}
                endIcon={props.open ? 'KeyboardArrowDownIcon' : 'KeyboardArrowUpIcon'}
            >
                Collapse
            </Button>
            <Button
                className={classes.printButton}
                variant={'ghost'}
                onClick={() => window.print()}
                startIcon={'PrintIcon'}
            >
                Print
            </Button>
        </Grid>
    );
};

const VeneerOrderDetailAlignersIprBlock_Fragment = graphql(`
    fragment VeneerOrderDetailAlignersIprBlock_Fragment on DesignOrderAlignerTreatmentPlanDTO {
        steps {
            ...OrderDetailAlignersIprBlockIprStepRow_Fragment
        }
    }
`);

export const OrderDetailAlignersIprBlock: React.VFC<{
    treatment_plan_fragment: FragmentType<typeof VeneerOrderDetailAlignersIprBlock_Fragment>;
    selected_step_index: number;
}> = ({ treatment_plan_fragment, selected_step_index }) => {
    const [open, set_open] = React.useState(true);
    const classes = useStyles();

    const { steps } = getFragmentData(VeneerOrderDetailAlignersIprBlock_Fragment, treatment_plan_fragment);

    return (
        <Grid container item xs={12} className={classes.root}>
            <OrderDetailBlock
                variant={'full'}
                title={'IPR'}
                actions={<IprBlockActions set_open={set_open} open={open} />}
                contentStyle={{ overflow: 'scroll' }}
            >
                <AlignersIprGrid stepFragments={steps} open={open} selected_step_index={selected_step_index} />
            </OrderDetailBlock>
        </Grid>
    );
};
