import { useStageState, useRestorativesValidity } from '../AppState.hooks';
import { parseRestorativesValidity } from '../AppState.utils';
import { useFinishingCallbacks, useIsTrainingOrder, useOrder } from '../OrderState.hooks';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { useSnackbar } from 'notistack';
import { useAsyncCallback } from 'react-async-hook';

export function useSubmitAutomateReview(enableFinishingEditing: boolean) {
    const { id: orderId } = useOrder();
    const { startTimeMs, setIsReviewComplete } = useStageState();
    const { submitReviewDecision, closeWindow, onComplete } = useFinishingCallbacks();
    const restorativesValidity = parseRestorativesValidity(useRestorativesValidity());
    const isTraining = useIsTrainingOrder();
    const { enqueueSnackbar } = useSnackbar();

    const exitFinishing = () => {
        closeWindow(true);
        onComplete();
    };

    const { loading: approveLoading, execute: approve } = useAsyncCallback(async () => {
        await submitReviewDecision({ approved: true, reviewDurationMs: Date.now() - startTimeMs });
        enqueueSnackbar('Design approved for manufacturing!');
        exitFinishing();
    });

    const approveAutomateDesign = () => {
        const confirmed = confirm(
            isTraining
                ? 'Approve the current design?'
                : 'This order will now be sent to the lab for manufacturing. Are you sure you want to continue?',
        );
        if (confirmed) {
            void approve();
        }
    };

    const { loading: rejectLoading, execute: rejectAutomateDesign } = useAsyncCallback(async () => {
        await submitReviewDecision({ approved: false, reviewDurationMs: Date.now() - startTimeMs });

        BrowserAnalyticsClientFactory.Instance?.track('Ops - Portal - Design Finishing - Automate Design Rejected', {
            $groups: { order: orderId },
            marginDistanceToRestorativeOkay: restorativesValidity.marginDistanceToRestorativeOkay,
            marginDistanceToPrepScanOkay: restorativesValidity.marginDistanceToPrepScanOkay,
            intaglioIntersectionOkay: restorativesValidity.intaglioIntersectionOkay,
        });

        enqueueSnackbar('Design rejection recorded');

        enableFinishingEditing ? setIsReviewComplete(true) : exitFinishing();
    });

    return {
        approveAutomateDesign,
        rejectAutomateDesign,
        loading: approveLoading || rejectLoading,
    };
}
