import { useFirebaseMultiFileUpload } from './FirebaseUpload';
import { uploadFilesToGCS } from './FirebaseUpload/FileUploader.utils';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { getChatMessageVisibilityForWrite } from '@orthly/chat';
import { useCreateChatMessageMutation } from '@orthly/graphql-react';
import type { LabsGqlCreateChatMessageCommand } from '@orthly/graphql-schema';
import { LabsGqlChatEntityTypes } from '@orthly/graphql-schema';
import { getEditorDefaults } from '@orthly/pintura';
import { PinturaEditor } from '@orthly/react-pintura';
import type { IOrganizationType } from '@orthly/retainer-common';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import { OrthlyBrowserConfig, RootActionDialog, useChangeSubmissionFn, QuickForm } from '@orthly/ui';
import { FlossPalette, Grid, IconButton, Tooltip, Icon } from '@orthly/ui-primitives';
import React from 'react';

type FormFields = Pick<LabsGqlCreateChatMessageCommand, 'text' | 'visible_to_roles'>;

interface ChatAddScreenshotFormContentProps {
    file: File;
    onSubmit: (fields: FormFields & { files: File[] }) => Promise<any>;
    closeModal: () => void;
}

const Utils = {
    dataURLToFile: (dataURL: string, fileName: string, mimeType: string) => {
        return fetch(dataURL)
            .then(function (res: any) {
                return res.arrayBuffer();
            })
            .then(function (buf: any) {
                return new File([buf], fileName, { type: mimeType });
            });
    },
    getScreenshotFile: async (stream: MediaStream) => {
        const canvas = document.createElement('canvas');
        const video = document.createElement('video');
        video.srcObject = stream;

        await video.play();

        // Draw one video frame to canvas.
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d')?.drawImage(video, 0, 0);

        // If you dont replace you will get a DOM 18 exception.
        const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        // Stop Chrome screen-sharing
        stream.getTracks()[0]?.stop();
        return Utils.dataURLToFile(image, 'screenshot.png', 'image/png');
    },
};

const ChatAddScreenshotFormContent: React.FC<ChatAddScreenshotFormContentProps> = props => {
    const { file, onSubmit, closeModal } = props;
    const ref = React.useRef<PinturaEditor>(null);
    // get default properties
    const editorConfig = getEditorDefaults();
    return (
        <Grid
            container
            style={{
                height: '75vh',
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            <div style={{ flexBasis: '75%' }}>
                <PinturaEditor
                    {...editorConfig}
                    ref={ref}
                    onClose={closeModal}
                    cropEnableButtonRotateRight={false}
                    cropEnableButtonFlipVertical={false}
                    cropEnableButtonFlipHorizontal={false}
                    cropEnableButtonRotateLeft={false}
                    cropEnableRotationInput={false}
                    cropEnableZoomInput={false}
                    enableCanvasAlpha={false}
                    enableButtonClose={true}
                    enableButtonExport={false}
                    enableNavigateHistory={true}
                    enableToolbar={true}
                    enableUtils={true}
                    enableDropImage={false}
                    enablePasteImage={false}
                    utils={['crop', 'annotate']}
                    src={file}
                />
            </div>
            <div style={{ flexBasis: '25%' }}>
                <QuickForm<FormFields>
                    disabled={false}
                    fields={{
                        text: {
                            type: 'text',
                            label: 'Message',
                            fieldProps: {
                                placeholder: 'Add message',
                                multiline: true,
                                minRows: 3,
                                maxRows: 50,
                            },
                        },
                        visible_to_roles: {
                            label: 'Visible to',
                            type: 'multiselect',
                            optional: true,
                            options: [
                                { label: 'Practice', value: 'practice__all' },
                                { label: 'Lab', value: 'lab__all' },
                            ],
                        },
                    }}
                    submitButtonTitle={'Add Screenshot'}
                    initialValues={{ text: '', visible_to_roles: [] }}
                    onSubmit={async result => {
                        if (!ref.current) {
                            return;
                        }
                        const imageWriter = await ref.current.editor.processImage();
                        await onSubmit({
                            files: [imageWriter.dest],
                            text: result.text,
                            visible_to_roles: result.visible_to_roles,
                        });
                    }}
                />
            </div>
        </Grid>
    );
};

interface OrderDetailChatAddScreenshotProps {
    orderId: string;
    onAttached: () => any;
    senderOrgType: IOrganizationType;
    // files that were pasted into chat or dropped onto the sidebar
    initialFiles?: File[];
    controls?: { open: boolean; setOpen: (open: boolean) => void };
}

export const OrderDetailChatAddScreenshot: React.VFC<OrderDetailChatAddScreenshotProps> = ({
    orderId,
    senderOrgType,
    onAttached,
    controls,
}) => {
    const [screenshot, setScreenshot] = React.useState<File | undefined>();
    const [submitMtn] = useCreateChatMessageMutation();
    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, OrderingStorageConfigs.attachments, orderId);
    const [uploadFn] = useFirebaseMultiFileUpload(storagePathConfig, []);
    const submitHandler = React.useCallback(
        async ({ files, ...fields }: FormFields & { files: File[] }) => {
            const uploadedFiles = await uploadFilesToGCS({ uploadFn, filesToSubmit: files });
            return submitMtn({
                variables: {
                    data: {
                        attachment_urls: uploadedFiles.map(f => f.uploadedPath),
                        text: fields.text,
                        entity_type: LabsGqlChatEntityTypes.Order,
                        entity_id: orderId,
                        visible_to_roles: getChatMessageVisibilityForWrite(
                            senderOrgType,
                            fields.visible_to_roles ?? [],
                        ),
                    },
                },
            });
        },
        [orderId, submitMtn, uploadFn, senderOrgType],
    );
    const { submit, submitting, ...action } = useChangeSubmissionFn(submitHandler, {
        successMessage: () => ['Screenshot added!'],
        onSuccess: () => {
            onAttached();
            BrowserAnalyticsClientFactory.Instance?.track('Ops - Order Chat - Screenshot Uploaded', {
                $groups: {
                    order: orderId,
                },
            });
            controls?.setOpen(false);
        },
    });
    const { open, setOpen } = React.useMemo(
        () => controls ?? { open: action.open, setOpen: action.setOpen },
        [action.open, action.setOpen, controls],
    );

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Add Screenshot to Order'}
            maxWidth={'xl'}
            // we only mount the form when open becomes true; it makes syncing the initial file state simpler
            content={
                open && screenshot ? (
                    <ChatAddScreenshotFormContent
                        file={screenshot}
                        onSubmit={submit}
                        closeModal={() => setOpen(false)}
                    />
                ) : null
            }
            buttonProps={{ style: { display: 'none' } }}
            buttonText={'Add Screenshot'}
            CustomButton={() => (
                <IconButton
                    size={'small'}
                    color={'inherit'}
                    onClick={async e => {
                        e.stopPropagation();
                        const mediaStream = await navigator.mediaDevices.getDisplayMedia({});
                        const file = await Utils.getScreenshotFile(mediaStream);
                        setScreenshot(file);
                        setOpen(true);
                    }}
                    style={{ color: FlossPalette.STAR_GRASS, marginRight: 4 }}
                >
                    <Tooltip title={'Attach Screenshot'} arrow>
                        <Icon icon={'ScreenShareIcon'} style={{ color: FlossPalette.STAR_GRASS }} />
                    </Tooltip>
                </IconButton>
            )}
        />
    );
};
