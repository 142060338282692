import { Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

interface AutomationBuilderFieldLayoutProps {
    title: string | React.ReactNode;
    secondary?: boolean;
    fullWidth?: boolean;
    children: React.ReactNode;
}

export const AutomationBuilderFieldLayout: React.FC<AutomationBuilderFieldLayoutProps> = props => {
    const { title, secondary, fullWidth } = props;
    return (
        <Grid container style={{ padding: '12px 0' }} alignItems={'flex-start'}>
            <Grid item container md={2} alignItems={'center'}>
                {typeof title === 'string' ? (
                    <Text
                        style={{ fontWeight: secondary ? 'normal' : undefined, fontSize: 16, paddingTop: 8 }}
                        variant={'h6'}
                    >
                        {title}
                    </Text>
                ) : (
                    title
                )}
            </Grid>
            <Grid item container md={fullWidth ? 9 : 7} alignItems={'center'}>
                {props.children}
            </Grid>
        </Grid>
    );
};
