import type { ScanMeshes, ScanModels, ScansRecord } from './FinishingApp.types';
import { createCollisionsCurtainsMaterial, createCollisionsMaterial, createScanMaterial } from './Materials.utils';
import type { IPartialSceneAppearanceManager } from './SceneAppearanceManager.types';
import { scanKeys, type PartialSceneAppearanceSetter, type Range } from './SceneAppearanceManager.types';
import { createCollisionsMesh, createCurtainsNominalMaterial, heatmapName } from './SceneAppearanceManager.utils';
import type { ISceneMaterialManager, ScanMaterialOptions } from './SceneMaterialManager.types';
import { HeatMapType, unionSpheres } from '@orthly/forceps';
import type { ToothNumber } from '@orthly/items';
import _ from 'lodash';
import * as THREE from 'three';

type MinimalScanMaterialOptions = Pick<ScanMaterialOptions, 'useColors' | 'transparent'>;

const DEFAULT_SCAN_MATERIAL_OPTIONS: Readonly<MinimalScanMaterialOptions> = {
    useColors: false,
    transparent: true,
};

// Implements common functionality for managing the appearance of a scene in a review panel
export class PartialSceneAppearanceManagerBase implements IPartialSceneAppearanceManager {
    scene: THREE.Scene = new THREE.Scene();

    protected readonly scans: ScanMeshes | null = null;
    private restoratives: Map<ToothNumber, THREE.Mesh<THREE.BufferGeometry, THREE.Material>> = new Map();
    protected curtains: Map<ToothNumber, THREE.Mesh<THREE.BufferGeometry, THREE.Material>> = new Map();
    private collisions: THREE.Mesh[] = [];
    private insertionPaths: Map<ToothNumber, THREE.ArrowHelper> = new Map();
    private useScanColors: boolean;

    constructor(
        private setAppearance: PartialSceneAppearanceSetter,
        private materialManager: ISceneMaterialManager,
        restoratives: Map<ToothNumber, THREE.BufferGeometry>,
        private scanModels: ScanModels | null,
        collisions: THREE.Mesh[],
        insertionPaths: Map<ToothNumber, THREE.ArrowHelper> = new Map(),
        scanAppearanceOptions: Partial<MinimalScanMaterialOptions> = {},
        curtains?: Map<ToothNumber, THREE.BufferGeometry>,
    ) {
        const scanMaterialOptions = {
            ...DEFAULT_SCAN_MATERIAL_OPTIONS,
            ...scanAppearanceOptions,
        };
        this.useScanColors = scanMaterialOptions.useColors;

        this.materialManager.activeHeatMap = undefined;
        restoratives.forEach((geometry, toothNumber) => {
            const mesh = new THREE.Mesh(geometry, this.materialManager.getRestorativeMaterial(toothNumber));
            this.restoratives.set(toothNumber, mesh);
            this.scene.add(mesh);
        });

        curtains?.forEach((geometry, toothNumber) => {
            const mesh = new THREE.Mesh(geometry, createCurtainsNominalMaterial());
            mesh.visible = false;
            this.curtains.set(toothNumber, mesh);
            this.scene.add(mesh);
        });

        if (scanModels) {
            // Pre-prep scans should always have color and be opaque.
            const createPrePrepMaterial = (colorMap?: THREE.Texture) => createScanMaterial(false, colorMap);
            this.scans = {
                prePrepUpperJaw: scanModels.prePrepUpperJaw?.toMesh(createPrePrepMaterial),
                prePrepLowerJaw: scanModels.prePrepLowerJaw?.toMesh(createPrePrepMaterial),
                upperJaw: scanModels.upperJaw.toMesh(colorMap =>
                    this.materialManager.getScanMaterial('upper', { ...scanMaterialOptions, colorMap }),
                ),
                lowerJaw: scanModels.lowerJaw.toMesh(colorMap =>
                    this.materialManager.getScanMaterial('lower', { ...scanMaterialOptions, colorMap }),
                ),
            } as const;

            _.values(this.scans).forEach(el => el && this.scene.add(el));

            // hide pre-prep scans initially
            if (this.scans.prePrepUpperJaw) {
                this.scans.prePrepUpperJaw.visible = false;
            }
            if (this.scans.prePrepLowerJaw) {
                this.scans.prePrepLowerJaw.visible = false;
            }
        }

        const collisionsMaterial = createCollisionsMaterial();
        const curtainCollisionsMaterial = createCollisionsCurtainsMaterial();
        curtainCollisionsMaterial.visible = false;

        collisions.forEach(el =>
            this.addCollisionsMesh(el.geometry as THREE.BufferGeometry, collisionsMaterial, curtainCollisionsMaterial),
        );

        for (const [toothNumber, arrowMesh] of insertionPaths.entries()) {
            const clone = arrowMesh.clone();
            clone.visible = false;
            this.insertionPaths.set(toothNumber, clone);
            this.scene.add(clone);
        }

        this.updateAppearanceState();
    }

    getRestorativeMeshes(): ReadonlyMap<ToothNumber, THREE.Mesh<THREE.BufferGeometry, THREE.Material>> {
        return this.restoratives;
    }

    getScanMeshes(): ScanMeshes | null {
        return this.scans;
    }

    toggleCollisionsVisibility(): void {
        const nextVisible = !this.areCollisionsVisible;

        this.collisions.forEach(el => {
            el.visible = nextVisible;
        });

        this.updateAppearanceState();
    }

    toggleCollisionsCurtainsVisibility(): void {
        const nextVisible = !this.areCollisionsCurtainsVisible;

        this.collisions.forEach(el => {
            const materials = el.material as THREE.MeshBasicMaterial[];
            const curtainCollisionsMaterial = materials[1];
            if (curtainCollisionsMaterial) {
                curtainCollisionsMaterial.visible = nextVisible;
            }
        });

        this.updateAppearanceState();
    }

    protected toggleRestorativesVisibilityImpl(): void {
        const nextVisible = !this.areRestorativesVisible;
        this.restoratives.forEach(mesh => {
            mesh.visible = nextVisible;
        });

        this.updateAppearanceState();
    }

    setHeatmapRange(range: Range): void {
        this.materialManager.heatmapRange = range;

        this.updateAppearanceState();
    }

    getBoundingSphereForVisible(): THREE.Sphere {
        const sphere = new THREE.Sphere();

        [..._.values(this.scans), ...this.restoratives.values()].forEach(el => {
            // PartialSceneAppearanceManagerBase child classes do not currently expose a way to hide meshes, but we
            // check visibility in order to future-proof.
            if (el?.visible) {
                el.geometry.computeBoundingSphere();
                unionSpheres(sphere, el.geometry.boundingSphere);
            }
        });

        return sphere;
    }

    getRestorativeBoundingSphere(toothNumber: ToothNumber): THREE.Sphere {
        const geometry = this.restoratives.get(toothNumber)?.geometry;
        if (!geometry) {
            throw new Error(`No restorative with tooth number ${toothNumber}.`);
        }

        geometry.computeBoundingSphere();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return geometry.boundingSphere!.clone();
    }

    protected toggleRestorativeTransparency(enabled?: boolean): void {
        this.materialManager.setAllRestorativesTransparency(enabled);
    }

    protected toggleScanUndercut(enabled?: boolean): void {
        this.materialManager.showScanUndercut = enabled ?? !this.materialManager.showScanUndercut;
        scanKeys.forEach(key => {
            const scan = this.scans?.[`${key}Jaw`];
            if (!scan) {
                return;
            }
            const material = this.materialManager.getScanMaterial(key, {
                useColors: this.useScanColors,
                transparent: false,
                colorMap: this.scanModels?.[`${key}Jaw`].colorMap,
            });
            if (!material) {
                return;
            }
            scan.material = material;
        });
        this.updateAppearanceState();
    }

    protected toggleHeatMap(heatMapType: HeatMapType, enabled?: boolean): void {
        const nextEnabled = typeof enabled === 'boolean' ? enabled : !this.isHeatmapEnabled(heatMapType);
        this.toggleRestorativeTransparency(false);
        this.setActiveHeatMap(nextEnabled ? heatMapType : undefined);
    }

    protected setScansVisibility(visibility: Partial<ScansRecord<boolean | 'toggle'>>): void {
        let update = false;
        for (const name in visibility) {
            const key = name as keyof ScansRecord;
            const mesh = this.scans?.[key];
            if (mesh && mesh.visible !== visibility[key]) {
                const val = visibility[key];
                if (typeof val === 'boolean') {
                    mesh.visible = val;
                    update = true;
                } else if (val === 'toggle') {
                    mesh.visible = !mesh.visible;
                    update = true;
                }
            }
        }
        if (!update) {
            return;
        }
        this.updateAppearanceState();
    }

    protected toggleInsertionPathsVisibilityImpl(visible?: boolean): void {
        const nextVisible = typeof visible === 'boolean' ? visible : !this.areInsertionPathsVisible;

        this.insertionPaths.forEach(el => {
            el.visible = nextVisible;
        });

        this.updateAppearanceState();
    }

    protected updateAppearanceState(): void {
        this.setAppearance({
            collisionsVisible: this.areCollisionsVisible,
            collisionsCurtainsVisible: this.areCollisionsCurtainsVisible,
            curtainsVisible: this.areCurtainsVisible,
            insertionPathsVisible: this.areInsertionPathsVisible,
            thicknessHeatmapEnabled: this.isHeatmapEnabled(HeatMapType.Thickness),
            proximalHeatmapEnabled: this.isHeatmapEnabled(HeatMapType.Proximal),
            occlusalHeatmapEnabled: this.isHeatmapEnabled(HeatMapType.Occlusal),
            undercutHeatmapEnabled: this.isHeatmapEnabled(HeatMapType.Undercut),
            scanUndercutEnabled: this.materialManager.showScanUndercut,
            heatmapRange: this.materialManager.heatmapRange,
            antagonistScansVisible:
                (!!this.scans?.lowerJaw?.visible || !!this.scans?.prePrepLowerJaw?.visible) &&
                (!!this.scans?.upperJaw?.visible || !!this.scans?.prePrepUpperJaw?.visible),
            prePrepScansVisible: !!this.scans?.prePrepUpperJaw?.visible || !!this.scans?.prePrepLowerJaw?.visible,
            restorativesVisible: this.areRestorativesVisible,
        });
    }

    private setActiveHeatMap(heatMap?: HeatMapType): void {
        this.materialManager.activeHeatMap = heatMap;
        this.restoratives.forEach((mesh, toothNumber) => {
            const material = this.materialManager.getRestorativeMaterial(toothNumber);
            if (material) {
                mesh.material = material;
            } else {
                console.warn(
                    `Could not find material for ${
                        heatMap ? heatmapName[heatMap] : 'no'
                    } heatmap @ tooth ${toothNumber}.`,
                );
            }
        });

        this.updateAppearanceState();
    }

    private isHeatmapEnabled(heatmapType: HeatMapType): boolean {
        return this.materialManager.activeHeatMap === heatmapType;
    }

    private get areCollisionsVisible(): boolean {
        // Collisions are shown or hidden in tandem, so we only need to check one.
        return this.collisions[0]?.visible ?? false;
    }

    private get areRestorativesVisible(): boolean {
        return Array.from(this.restoratives.values()).some(mesh => mesh.visible);
    }

    private get areCollisionsCurtainsVisible(): boolean {
        const firstCollisionsObject = this.collisions[0];
        if (firstCollisionsObject) {
            const materials = firstCollisionsObject.material as THREE.MeshBasicMaterial[];
            const curtainCollisionsMaterial = materials[1];
            if (curtainCollisionsMaterial) {
                return curtainCollisionsMaterial.visible;
            }
        }
        return false;
    }
    protected get areCurtainsVisible(): boolean {
        return this.curtains.values().next().value?.visible ?? false;
    }

    private get areInsertionPathsVisible(): boolean {
        return this.insertionPaths.values().next().value?.visible ?? false;
    }

    private addCollisionsMesh(
        geometry: THREE.BufferGeometry,
        collisionsMaterial: THREE.Material,
        curtainCollisionsMaterial: THREE.Material,
    ): void {
        const mesh = createCollisionsMesh(geometry, collisionsMaterial, curtainCollisionsMaterial);
        mesh.visible = false;
        this.collisions.push(mesh);
        this.scene.add(mesh);
    }
}
