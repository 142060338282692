import { useSceneState, useOperationsState, useCaseMetadata } from '../../AppState.hooks';
import { useInitiallyOpen } from '../../OrderState.hooks';
import { useSetupReviewKeyboardControls } from '../../ReviewControls.hooks';
import { ReviewPanel } from './ReviewPanel';
import { useReviewApps } from '@orthly/dentin';
import type { DesignFinishingReviewType } from '@orthly/dentin';
import { FlossPalette, stylesFactory, Box } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        height: '100%',
        flex: 'auto',
        // This is required to get the element to shrink properly. It seems like it may be some interaction with the
        // ModelViewerCanvas component.
        overflow: 'hidden',
        padding: 8,
        backgroundColor: FlossPalette.DARK_TAN,
    },
    grid: {
        display: 'grid',
        gridTemplateRows: '1fr 1fr',
        gridTemplateColumns: '1fr 1fr',
        gap: 8,
    },
}));

export const ReviewView: React.VFC = () => {
    const classes = useStyles();

    const [expandedReviewType, setExpandedReviewType] = React.useState<DesignFinishingReviewType | null>(null);

    const caseMetadata = useCaseMetadata();
    const sceneState = useSceneState();
    const operationsState = useOperationsState();
    const initiallyOpen = useInitiallyOpen();

    const apps = useReviewApps(caseMetadata, sceneState.manager, operationsState.manager, {
        accountForTransition: !initiallyOpen,
    });

    useSetupReviewKeyboardControls(apps, expandedReviewType);

    // NB: We give keys to the ReviewPanel components to ensure that the first component is re-mounted when switching
    // between the quadrant and expanded view, and when switching between review types with the review panel expanded.
    // The camera view setting logic depends on this.

    return expandedReviewType ? (
        <Box className={classes.root}>
            <ReviewPanel
                key={`${expandedReviewType}-expanded`}
                expanded
                type={expandedReviewType}
                setType={setExpandedReviewType}
                app={apps[expandedReviewType]}
            />
        </Box>
    ) : (
        <Box className={clsx(classes.root, classes.grid)}>
            <ReviewPanel
                key={'occlusion'}
                sx={{ gridColumn: '1', gridRow: '1' }}
                type={'occlusion'}
                setType={setExpandedReviewType}
                app={apps.occlusion}
            />
            <ReviewPanel
                key={'insertion'}
                sx={{ gridColumn: '2', gridRow: '1' }}
                type={'insertion'}
                setType={setExpandedReviewType}
                app={apps.insertion}
            />
            <ReviewPanel
                key={'mesial'}
                sx={{ gridColumn: '1', gridRow: '2' }}
                type={'mesial'}
                setType={setExpandedReviewType}
                app={apps.mesial}
            />
            <ReviewPanel
                key={'distal'}
                sx={{ gridColumn: '2', gridRow: '2' }}
                type={'distal'}
                setType={setExpandedReviewType}
                app={apps.distal}
            />
        </Box>
    );
};
