import type { LabsGqlWorkflowTaskFragment } from '@orthly/graphql-operations';
import type { IconButtonProps } from '@orthly/ui-primitives';
import { IconButton, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

export const useAnchor = (setOpen?: (open: boolean) => void) => {
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
    return [
        anchor,
        React.useCallback(
            (anchor: HTMLElement | null) => {
                setAnchor(anchor);
                setOpen && setOpen(!!anchor);
            },
            [setAnchor, setOpen],
        ),
    ] as [HTMLElement | null, (anchor: HTMLElement | null) => void];
};

export interface TaskButtonProps {
    task: LabsGqlWorkflowTaskFragment;
    refetch: () => Promise<any>;
    setOpen?: (open: boolean) => void;
    buttonProps?: { className?: string };
}

export const TaskButtonBase: React.FC<IconButtonProps & { tooltip: string }> = ({ tooltip, ...props }) => (
    <Tooltip title={tooltip}>
        <span>
            <IconButton size={`small`} {...props} />
        </span>
    </Tooltip>
);
