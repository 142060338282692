import type { LabsGqlOrganizationDtoFragment } from '@orthly/graphql-operations';
import type { LabsGqlCapacityStationDto } from '@orthly/graphql-schema';
import { LabsGqlCapacityLimitUnitType } from '@orthly/graphql-schema';
import type { FieldsDefProp } from '@orthly/ui';
import { LoadBlocker, QuickForm } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

export type CapacityStationFormFields = Pick<LabsGqlCapacityStationDto, 'name' | 'organization_id' | 'unit_type'>;

const INITIAL_VALUES: CapacityStationFormFields = {
    name: ``,
    organization_id: ``,
    unit_type: LabsGqlCapacityLimitUnitType.Legacy,
};

export const CapacityStationForm: React.VFC<{
    loading: boolean;
    labs: LabsGqlOrganizationDtoFragment[];
    labsLoading: boolean;
    onSubmit: (result: CapacityStationFormFields) => void;
}> = ({ loading, onSubmit, labs, labsLoading }) => {
    const formFields: FieldsDefProp<CapacityStationFormFields> = {
        name: {
            label: 'Name',
            type: 'text',
        },
        organization_id: {
            label: 'Organization',
            type: 'select',
            options: _.sortBy(labs, m => m.name.trim().toLowerCase()).map(({ id, name }) => ({
                value: id,
                label: name,
            })),
        },
        unit_type: {
            label: 'Unit type',
            type: 'select',
            options: Object.values(LabsGqlCapacityLimitUnitType),
        },
    };

    return (
        <LoadBlocker blocking={loading || labsLoading}>
            <QuickForm<CapacityStationFormFields>
                initialValues={INITIAL_VALUES}
                fields={formFields}
                onSubmit={onSubmit}
            />
        </LoadBlocker>
    );
};
