import { levelToDisplayString, getLabelForTaskType, capabilityCardStyles } from '../../../../utils/Capabilities';
import { useUsersSelector } from '../../state/Users.context';
import { EditUserFocusAreasCard } from '../FocusAreas/EditUserFocusAreasCard';
import { CapabilityCardTitle } from './CapabilityCardTitle';
import { DesignPrepCapabilitiesDialog } from './CapabilityDialogs/DesignPrepCapabilitiesDialog.graphql';
import { DesignReviewCapabilitiesDialog } from './CapabilityDialogs/DesignReviewCapabilitiesDialog.graphql';
import { InternalDesignCapabilitiesDialog } from './CapabilityDialogs/InternalDesignCapabilitiesDialog.graphql';
import type {
    LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment,
    LabsGqlDesignStaffDtoFragment,
} from '@orthly/graphql-operations';
import { useOffboardDesignStaffMutation, useReOnboardDesignStaffMutation } from '@orthly/graphql-react';
import { LabsGqlDesignStaffStatus, LabsGqlDesignTaskType } from '@orthly/graphql-schema';
import type { OrderDesignCapabilityLevel } from '@orthly/shared-types';
import {
    DesignPrepCapabilityTypeToName,
    InternalDesignCapabilityTypeToName,
    DesignReviewCapabilityTypeToName,
} from '@orthly/shared-types';
import { useRootActionCommand } from '@orthly/ui';
import { FlossPalette, Button, Text, Grid, Container, makeStyles, createStyles } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type TaskType = 'DesignPrep' | 'DesignReview' | 'InternalDesign';

const useStyles = makeStyles(() =>
    createStyles({
        capabilitiesList: {
            marginTop: 0,
            paddingLeft: 0,
        },
        capabilitesListItem: {
            listStyleType: 'none',
        },
        capabilitiesListItemText: {
            marginTop: 10,
        },
    }),
);

function CapabilitiesBlock<K extends TaskType>(props: {
    title: string;
    capabilities: LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment[K];
    typeToName: Record<string, string>;
    children: React.ReactNode;
}): React.ReactElement {
    const { title, capabilities, typeToName, children } = props;
    const classes = useStyles();

    const formattedCapabilities = _.compact(
        Object.entries(capabilities).map(
            ([capabilityType, level]) =>
                level &&
                typeToName[capabilityType] &&
                `${typeToName[capabilityType]}: ${levelToDisplayString(level as OrderDesignCapabilityLevel)}`,
        ),
    );

    return (
        <Container style={{ ...capabilityCardStyles, backgroundColor: FlossPalette.TAN }}>
            <CapabilityCardTitle
                title={title}
                subtitleText={`Sets auto-assigned ${title} cases`}
                editButtonText={'Edit'}
                children={children}
            />
            <Text variant={'body2'} style={{ marginTop: 12 }}>
                {formattedCapabilities.length ? '' : 'No capabilities configured'}
            </Text>

            <ul className={classes.capabilitiesList}>
                {formattedCapabilities.map(formattedString => (
                    <li key={formattedString} className={classes.capabilitesListItem}>
                        <Text variant={'body2'} className={classes.capabilitiesListItemText}>
                            {formattedString}
                        </Text>
                    </li>
                ))}
            </ul>
        </Container>
    );
}

export const EditUserDesignCapabilities: React.FC<{
    designerStaff: LabsGqlDesignStaffDtoFragment;
}> = ({ designerStaff }) => {
    const { submit: offboard, submitting: offboarding } = useRootActionCommand(useOffboardDesignStaffMutation(), {
        successMessage: 'Design Staff Offboarded',
    });
    const { submit: reOnboard, submitting: reOnboarding } = useRootActionCommand(useReOnboardDesignStaffMutation(), {
        successMessage: 'Design Staff Onboarded',
    });
    const user = useUsersSelector(s => s.user);
    const designPrepCapabilities = designerStaff.task_capabilities.DesignPrep;
    const internalDesignCapabilities = designerStaff.task_capabilities.InternalDesign;
    const designReviewCapabilities = designerStaff.task_capabilities.DesignReview;
    const allCapabilities = designerStaff.task_capabilities;

    return (
        <Grid container direction={'column'}>
            <Grid item style={{ paddingTop: 8, paddingBottom: 12 }}>
                <Text variant={'body1'} medium>
                    Design levels & capabilities
                </Text>
                <Text variant={'body2'}>Determines which case types are assigned during rostered hours</Text>
            </Grid>
            {user && (
                <>
                    <Grid container spacing={3} item direction={'row'}>
                        <Grid item xs>
                            {/* DesignPrep capabilities */}
                            <CapabilitiesBlock
                                title={getLabelForTaskType(LabsGqlDesignTaskType.DesignPrep)}
                                capabilities={designPrepCapabilities}
                                typeToName={DesignPrepCapabilityTypeToName}
                            >
                                <DesignPrepCapabilitiesDialog userId={user.id} capabilities={designPrepCapabilities} />
                            </CapabilitiesBlock>
                        </Grid>
                        <Grid item xs>
                            {/* InternalDesign capabilities */}
                            <CapabilitiesBlock
                                title={getLabelForTaskType(LabsGqlDesignTaskType.InternalDesign)}
                                capabilities={internalDesignCapabilities}
                                typeToName={InternalDesignCapabilityTypeToName}
                            >
                                <InternalDesignCapabilitiesDialog
                                    user_id={user.id}
                                    capabilities={internalDesignCapabilities}
                                />
                            </CapabilitiesBlock>
                        </Grid>
                        <Grid item xs>
                            {/* DesignReview capabilities */}
                            <CapabilitiesBlock
                                title={getLabelForTaskType(LabsGqlDesignTaskType.DesignReview)}
                                capabilities={designReviewCapabilities}
                                typeToName={DesignReviewCapabilityTypeToName}
                            >
                                <DesignReviewCapabilitiesDialog
                                    user_id={user.id}
                                    capabilities={designReviewCapabilities}
                                />
                            </CapabilitiesBlock>
                        </Grid>

                        <Grid item xs>
                            <EditUserFocusAreasCard capabilities={allCapabilities} designerId={designerStaff.id} />
                        </Grid>
                        <Grid item style={{ paddingTop: 32 }} />
                    </Grid>
                    <Grid item style={{ paddingTop: 32 }}>
                        {designerStaff.status === LabsGqlDesignStaffStatus.Offboarded ? (
                            <Button
                                startIcon={'RedoIcon'}
                                onClick={() => {
                                    if (window.confirm('Are you sure you want to re-onboard this user?')) {
                                        void reOnboard({ data: { user_id: user.id } });
                                    }
                                }}
                                variant={'secondary'}
                                disabled={reOnboarding}
                                fullWidth={false}
                            >
                                Re-onboard Designer
                            </Button>
                        ) : (
                            <Button
                                startIcon={'CancelIcon'}
                                onClick={() => {
                                    if (window.confirm('Are you sure you want to offboard this user?')) {
                                        void offboard({ data: { user_id: user.id } });
                                    }
                                }}
                                variant={'alert-secondary'}
                                disabled={offboarding}
                                fullWidth={false}
                            >
                                Offboard Designer
                            </Button>
                        )}
                    </Grid>
                </>
            )}
        </Grid>
    );
};
