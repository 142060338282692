import { JsonDevTools } from '../../../components/JsonDevTools';
import { useBillingDetailsContext } from '../BillingDetails/providers/BillingDetailsProvider.graphql';
import { usePartnerPaymentSplitSourceOptions } from '../actions/EditPaymentSplitConfig/usePartnerPaymentSplitSourceOptions.graphql';
import { RefundInvoiceChargeAction } from '../actions/RefundInvoiceChargeAction.graphql';
import { VoidInvoiceAction } from '../actions/VoidInvoiceAction.graphql';
import { InvoiceItemsTable } from './InvoiceItemsTable';
import { InvoicePaymentLog } from './InvoicePaymentLog';
import type { InvoiceItem } from './InvoiceTable.types';
import { type Invoice, type InvoiceRow } from './InvoiceTable.types';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LoadBlocker } from '@orthly/ui';
import { styled } from '@orthly/ui-primitives';
import React from 'react';

const Container = styled('div')({
    padding: 10,
    width: 'calc(100vw - 72px)',
    display: 'grid',
    gridTemplateColumns: '7fr 5fr',
});

const PaymentsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
});

const RefundContainer = styled('div')({
    height: 'fit-content',
});

const LoadInvoiceItems_Query = graphql(`
    query ListInvoiceItemsForInvoice($invoiceId: String!) {
        listInvoiceItemsForInvoice(invoiceId: $invoiceId) {
            amount_cents
            category
            created_at
            description
            id
            invoice_id
            item_type
            order_id
            updated_at
            used_credit_id
            subcategory
            recurring_item_id
            used_credit_attribution_key
            used_credit_attribution_description
        }
    }
`);

export const InvoiceTableDetailPanel: React.FC<{ data: InvoiceRow<Invoice> }> = ({ data: invoice }) => {
    const { refetchBillingDetails } = useBillingDetailsContext();
    const {
        data: { listInvoiceItemsForInvoice = [] } = {},
        loading,
        refetch: refetchItems,
    } = useQuery<{
        listInvoiceItemsForInvoice: InvoiceItem[];
    }>(LoadInvoiceItems_Query, {
        variables: { invoiceId: invoice.id },
    });

    const rawItems = listInvoiceItemsForInvoice;

    const items = React.useMemo(() => {
        return (rawItems ?? []).map<InvoiceItem>(item => ({
            ...item,
            created_at: item.created_at,
            recurring_item_id: item.recurring_item_id,
            __typename: 'InvoiceItem',
        }));
    }, [rawItems]);

    const loadSources = invoice.payment_errors_count > 0 || invoice.payments_count > 0;
    const { sourceOptions } = usePartnerPaymentSplitSourceOptions(invoice.partner_id, !loadSources);
    const hasPendingPaymentOnInvoice = invoice.payments.filter(p => p.status === 'pending').length > 0;

    return (
        <LoadBlocker blocking={loading}>
            <Container>
                <div>
                    <InvoiceItemsTable
                        title={`Items - ${invoice.type} ${invoice.month_formatted} (${invoice.partner_name})`}
                        invoice={invoice}
                        items={items}
                        refetchItems={refetchItems}
                        hasPendingPaymentOnInvoice={hasPendingPaymentOnInvoice}
                    />
                </div>

                <PaymentsContainer>
                    <RefundContainer>
                        <RefundInvoiceChargeAction invoice={invoice} refetch={refetchBillingDetails} />
                        <VoidInvoiceAction invoice={invoice} refetch={refetchBillingDetails} />
                    </RefundContainer>
                    <JsonDevTools src={invoice} dataType={'Invoice'} />
                    <InvoicePaymentLog invoice={invoice} sourceOptions={sourceOptions} displayEmpty={true} />
                </PaymentsContainer>
            </Container>
        </LoadBlocker>
    );
};
