import { DesignQaResultsDesignDisplay } from './DesignQaResultsDesign.graphql';
import { DesignQaResultsSidebar } from './DesignQaResultsSidebar';
import { useQuery } from '@apollo/client';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import type { LabsGqlUserDtoFragment } from '@orthly/graphql-operations';
import { useListRetainerUsersQuery, useOrder } from '@orthly/graphql-react';
import { SimpleSelect } from '@orthly/ui';
import { stylesFactory, CircularProgress, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';

const AdminDesignQaResultsInternal_Fragment = graphql(`
    fragment AdminDesignQaResultsInternal_Fragment on DesignQaEvaluationDTO {
        id

        design_revision {
            source_file_zip_path
            label
            created_at
            ...VeneerOrderDesignAnalyticsDesign_Fragment
            ...OrderDesignPreviewDesign_Fragment
        }
        ...AdminDesignQaResultsDesignDisplayEvaluation_Fragment
    }
`);

const AdminDesignQaResults_Query = graphql(`
    query AdminDesignQaResults_Query($orderId: String!) {
        getDesignQaEvaluationsByOrderId(order_id: $orderId) {
            assigned_user_id

            ...AdminDesignQaResultsInternal_Fragment
        }
    }
`);

const useStyles = stylesFactory(() => ({
    designVersionWrapper: {
        padding: 8,
    },
}));

function getDesignLabel(label: string, createdAt: string) {
    const datePart = moment(createdAt).format(`MM/DD/YYYY hh:mma`);
    return `${label} ${datePart}`;
}

interface DesignQaResultsInternalProps {
    order: LabsGqlLabOrderFragment;
    evaluationFragments: FragmentType<typeof AdminDesignQaResultsInternal_Fragment>[];
    users: LabsGqlUserDtoFragment[];
}

const DesignQaResultsInternal: React.VFC<DesignQaResultsInternalProps> = ({ order, evaluationFragments, users }) => {
    const classes = useStyles();
    const evaluations = getFragmentData(AdminDesignQaResultsInternal_Fragment, evaluationFragments);

    const uniqueDesigns = React.useMemo(() => {
        return _.uniqBy(_.compact(evaluations.map(e => e.design_revision)), design => design?.source_file_zip_path);
    }, [evaluations]);

    const [selectedDesignPath, setSelectedDesignPath] = React.useState<string | undefined>(
        uniqueDesigns[0]?.source_file_zip_path,
    );
    const selectedDesign = uniqueDesigns.find(d => d.source_file_zip_path === selectedDesignPath);

    if (!order || uniqueDesigns.length === 0) {
        return <strong>An error has occurred</strong>;
    }

    return (
        <Grid container direction={'row'}>
            <Grid item xs style={{ overflowX: 'scroll' }}>
                <Grid className={classes.designVersionWrapper}>
                    <SimpleSelect
                        label={'Design Version'}
                        value={selectedDesignPath}
                        onChange={setSelectedDesignPath}
                        options={uniqueDesigns.map(design => ({
                            value: design.source_file_zip_path,
                            label: getDesignLabel(design.label, design.created_at),
                        }))}
                    />
                </Grid>
                <Grid container>
                    {selectedDesign && (
                        <DesignQaResultsDesignDisplay
                            order={order}
                            designFragment={selectedDesign}
                            evaluationFragments={evaluations.filter(
                                e => e.design_revision?.source_file_zip_path === selectedDesign.source_file_zip_path,
                            )}
                            users={users}
                        />
                    )}
                </Grid>
            </Grid>
            <DesignQaResultsSidebar order={order} designFragment={selectedDesign ?? undefined} />
        </Grid>
    );
};

export const DesignQaResults: React.VFC<RouteComponentProps<{ orderId: string }>> = props => {
    const { params } = props.match;

    const { order, loading: orderLoading } = useOrder(params.orderId);

    const { data, loading: designsLoading } = useQuery(AdminDesignQaResults_Query, {
        variables: {
            orderId: params.orderId,
        },
    });

    const allUserIds = _.uniq(_.compact((data?.getDesignQaEvaluationsByOrderId ?? []).map(e => e.assigned_user_id)));
    const { data: usersData } = useListRetainerUsersQuery({
        nextFetchPolicy: 'cache-first',
        fetchPolicy: 'cache-first',
        skip: allUserIds.length === 0,
        variables: { filter: { ids: allUserIds } },
    });

    const results = data?.getDesignQaEvaluationsByOrderId ?? [];

    if (orderLoading || designsLoading || !order) {
        return <CircularProgress />;
    }

    return <DesignQaResultsInternal order={order} evaluationFragments={results} users={usersData?.listUsers ?? []} />;
};
