import occlusal_cusp_fossa_distances_ACCEPTABLE from '../../../../assets/images/design-qc/occlusal/occlusal_cusp_fossa_distances_ACCEPTABLE.png';
import occlusal_cusp_fossa_distances_BAD from '../../../../assets/images/design-qc/occlusal/occlusal_cusp_fossa_distances_BAD.png';
import occlusal_cusp_fossa_distances_GOOD from '../../../../assets/images/design-qc/occlusal/occlusal_cusp_fossa_distances_GOOD.png';
import occlusal_cusp_fossa_locations_ACCEPTABLE from '../../../../assets/images/design-qc/occlusal/occlusal_cusp_fossa_locations_ACCEPTABLE.png';
import occlusal_cusp_fossa_locations_BAD from '../../../../assets/images/design-qc/occlusal/occlusal_cusp_fossa_locations_BAD.png';
import occlusal_cusp_fossa_locations_GOOD from '../../../../assets/images/design-qc/occlusal/occlusal_cusp_fossa_locations_GOOD.png';
import occlusal_minimum_thickness_BAD from '../../../../assets/images/design-qc/occlusal/occlusal_minimum_thickness_BAD.png';
import occlusal_minimum_thickness_GOOD from '../../../../assets/images/design-qc/occlusal/occlusal_minimum_thickness_GOOD.png';
import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { DEFAULT_SCAN_APPEARANCE, DEFAULT_RESTORATIVE_APPEARANCE } from '@orthly/dentin';
import { HeatMapType } from '@orthly/forceps';

export const occlusionRubric: DesignQcRubricCategoryEntry = {
    category: 'occlusion',
    subcategories: [
        {
            category: 'occlusion',
            name: 'Minimum thickness violations',
            grading: {
                type: 'binary',
                bad: 'Does not meet minimum material thickness',
                ideal: 'Meets minimum material thickness',
            },
            examples: {
                bad: [occlusal_minimum_thickness_BAD],
                ideal: [occlusal_minimum_thickness_GOOD],
            },
            description: 'Does this meet the minimum thickness requirement for the material?',
            selectable_bad_reasons: ['Does not meet minimum material thickness'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
                qc_settings: {
                    activeHeatMap: HeatMapType.Thickness,
                },
            },
        },
        {
            category: 'occlusion',
            name: 'Cusp & Fossa locations',
            grading: {
                type: 'tertiary',
                bad: 'Beyond 0.25 mm of "perfect" location',
                acceptable: 'Between 0.1mm and 0.25mm of "perfect" location',
                ideal: 'Within 0.1mm of "perfect" location',
            },
            examples: {
                bad: [occlusal_cusp_fossa_locations_BAD],
                acceptable: [occlusal_cusp_fossa_locations_ACCEPTABLE],
                ideal: [occlusal_cusp_fossa_locations_GOOD],
            },
            description: 'Are all cusps/fossas placed in the correct position where possible?',
            edge_case:
                'Orthodontically challenged opposing<br>Smaller teeth need different measurements. Team in general hates the idea of measuring this - for good reason.',
            selectable_bad_reasons: ['Beyond 0.25 mm of "perfect" location'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'FRONT',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
            },
        },
        {
            category: 'occlusion',
            name: 'Opposing Cusp <> Fossa distances',
            grading: {
                type: 'tertiary',
                bad: '50% or less of contact points within doctor preference',
                acceptable: 'Majority (75%) of contact points within doctor preferences',
                ideal: 'All possible contact points within doctor preference',
            },
            examples: {
                bad: [occlusal_cusp_fossa_distances_BAD],
                acceptable: [occlusal_cusp_fossa_distances_ACCEPTABLE],
                ideal: [occlusal_cusp_fossa_distances_GOOD],
            },
            description:
                'Difference between actual cusp/fossa distance and doctor preference<br><br>AKA Occlusal Contact Tightness',
            selectable_bad_reasons: ['Occlusal Contact Tightness does not match doctor preference'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: true,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                },
                qc_settings: {
                    activeHeatMap: HeatMapType.Occlusal,
                },
            },
        },
    ],
    design_standard: {
        googleEmbedStub: `2PACX-1vRity07zUL45Plrd935wygkb2ByPVvrntfywRMe0QwSIdAEqfPMsle0-4qPxd5GOXHQ-oIYxnnKSHF3`,
    },
};
