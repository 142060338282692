import { MUITableUtils } from '../contexts/MUITableUtils';
import { useTableData } from '../contexts/TableDataContext';
import { useTableStateContext } from '../state/TableStateContext';
import type { MUITableMetaRow } from '../types';
import FileSaver from 'file-saver';
import type json2csv from 'json2csv';
import { parse } from 'json2csv';

function downloadBlob(data: Blob, fileName: string) {
    FileSaver.saveAs(data, fileName);
}

type CSVRowData = { [k: string]: any };

export function downloadAsCsv(csvRows: CSVRowData[], exportTableName: string, options?: json2csv.Options<CSVRowData>) {
    const file = new Blob([parse(csvRows, options ?? {})], { type: 'text/plain;charset=utf-8' });
    downloadBlob(file, `${exportTableName} ${new Date().toJSON()}.csv`);
}

const downloadTableCsv = (rows: MUITableMetaRow[], exportTableName: string) => {
    const csvRows = rows.map(row => {
        return row.cells.reduce((parsedRow, cell) => {
            if (cell.column.download === false) {
                return parsedRow;
            }
            return { ...parsedRow, [cell.column.name]: cell.exportValue };
        }, {} as CSVRowData);
    });
    downloadAsCsv(csvRows, exportTableName);
};

export function useDownloadTableCsv() {
    const tableState = useTableStateContext().state;
    const { allRows, columns } = useTableData();
    return (exportName: string) => {
        const rows = MUITableUtils.filterRows(
            allRows,
            {
                searchText: tableState.searchText,
                searchOpen: tableState.searchOpen,
                columnFilters: tableState.columnFilters,
            },
            columns,
        );
        downloadTableCsv(rows, exportName);
    };
}
