import type { ItemInfo, Rule, Decision } from '../orderEditMode';
import { OrderEditMode } from '@orthly/shared-types';

/**
 * all checks that all of its subordinate rules return an OrderEdit
 * result for the given item.  One or more nulls will result in a null
 * result, and one or more CancelAndResubmit results will result in an
 * overall CancelAndResubmit
 */
export function all(...rules: Rule[]): Rule {
    return function (item: ItemInfo): Decision {
        const results = rules.map(r => r(item));
        const debugInfo = {
            rule: 'all',
            results: results,
        };

        if (results.some(r => r.editMode === OrderEditMode.CancelAndResubmit)) {
            return {
                editMode: OrderEditMode.CancelAndResubmit,
                debugInfo,
            };
        }

        if (results.some(r => r.editMode === null)) {
            return {
                editMode: null,
                debugInfo,
            };
        }

        return {
            editMode: OrderEditMode.OrderEdit,
            debugInfo,
        };
    };
}
