import { LabsGqlDesignOrderNoteCategory } from '@orthly/graphql-schema';

export const isMarginalRidgeOptionDisabled = (
    option: LabsGqlDesignOrderNoteCategory,
    selectionsContain: (options: LabsGqlDesignOrderNoteCategory[]) => boolean,
): boolean => {
    switch (option) {
        case LabsGqlDesignOrderNoteCategory.MesialMakeMarginalRidgeHigher:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.MesialMakeMarginalRidgeLower]);

        case LabsGqlDesignOrderNoteCategory.MesialMakeMarginalRidgeLower:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.MesialMakeMarginalRidgeHigher]);

        case LabsGqlDesignOrderNoteCategory.DistalMakeMarginalRidgeHigher:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.DistalMakeMarginalRidgeLower]);

        case LabsGqlDesignOrderNoteCategory.DistalMakeMarginalRidgeLower:
            return selectionsContain([LabsGqlDesignOrderNoteCategory.DistalMakeMarginalRidgeHigher]);

        default:
            return false;
    }
};
