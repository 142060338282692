/* eslint-disable max-lines */
import type { TaskButtonProps } from './TaskButton';
import { TaskButtonBase, useAnchor } from './TaskButton';
import { useApolloClient } from '@apollo/client';
import type {
    LabsGqlAssignFulfillmentTaskMutationVariables,
    LabsGqlWorkflowTaskFragment,
} from '@orthly/graphql-operations';
import {
    useAssignFulfillmentTaskMutation,
    useListPsrUsers,
    useListMatchingDesignersForOrderQuery,
    useListExternalUsers,
    AssignFulfillmentTaskDocument,
} from '@orthly/graphql-react';
import type { IOrganizationType } from '@orthly/retainer-common';
import { keyBy } from '@orthly/runtime-utils';
import { useSession, useHasCapability } from '@orthly/session-client';
import { LoadBlocker, SimpleInput, useChangeSubmissionFn, RootActionDialog, apolloErrorMessage } from '@orthly/ui';
import {
    FlossPalette,
    Button,
    Avatar,
    Divider,
    Grid,
    InputAdornment,
    MenuItem,
    Popover,
    Text,
    CheckIcon,
    PersonIcon,
    SearchIcon,
} from '@orthly/ui-primitives';
import _, { compact, uniq, uniqBy } from 'lodash';
import React from 'react';
import { createLocalStorageStateHook } from 'use-local-storage-state';

export interface UserPartial {
    id?: string;
    first_name: string | null;
    last_name: string | null;
}
interface UserPartialWithOrganization extends UserPartial {
    organization_type: IOrganizationType;
}

const useRecentlyAssignedUsers = createLocalStorageStateHook<string[]>(`most-recently-assigned-users`, []);

export const AssignmentAvatar: React.FC<{ user: UserPartial | null; disabled?: boolean }> = ({ user, disabled }) => {
    const color = disabled ? FlossPalette.GRAY : FlossPalette.STAR_GRASS;
    return user ? (
        <Avatar style={{ backgroundColor: color, width: 24, height: 24 }}>
            <Text style={{ fontSize: `0.75em`, color: FlossPalette.WHITE, fontWeight: 600 }}>
                {(user.first_name ?? `-`).charAt(0)}
                {(user.last_name ?? ``).charAt(0)}
            </Text>
        </Avatar>
    ) : (
        <PersonIcon style={{ color }} />
    );
};

function assignTaskSubmenu(items: JSX.Element[], description: string): JSX.Element {
    return (
        <>
            <MenuItem disabled style={{ height: 44 }}>
                {description}
            </MenuItem>
            {items}
        </>
    );
}

interface AssignmentMenuItemsProps {
    anchor: HTMLElement | null;
    onClose: () => void;
    assigned_user: UserPartial | null;
    assignTo: (user: UserPartial | null) => Promise<any>;
    // for bulk assignment
    clearAssignmentItem?: React.ReactNode;
    order_id?: string;
}

const AssignmentMenu: React.FC<AssignmentMenuItemsProps> = props => {
    const { anchor, onClose, assigned_user, assignTo, clearAssignmentItem, order_id } = props;
    const { data: internalUsers, loading: loadingInternalUsers } = useListPsrUsers({
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });
    const { data: externalUsers, loading: loadingExternalUsers } = useListExternalUsers({
        fetchPolicy: 'cache-first',
    });
    const users = React.useMemo<UserPartialWithOrganization[]>(
        () =>
            uniqBy(
                [
                    ...(internalUsers?.listUsers || []).map(user => ({
                        ...user,
                        organization_type: 'internal' as const,
                    })),
                    ...(externalUsers?.listUsers || []).map(user => ({
                        ...user,
                        organization_type: 'external' as const,
                    })),
                ],
                user => user.id,
            ),
        [externalUsers, internalUsers],
    );
    const loadingUsers = loadingInternalUsers || loadingExternalUsers;

    const { data: matchingDesignersRaw } = useListMatchingDesignersForOrderQuery({
        variables: { order_id: order_id || '' },
        skip: !order_id || !anchor,
    });
    const session = useSession();
    const myself = React.useMemo(() => {
        return session?.user ? { ...session.user, organization_type: session.organization_type } : undefined;
    }, [session?.organization_type, session?.user]);
    const last_user_id = assigned_user?.id ?? null;
    const usersById = keyBy<UserPartialWithOrganization>(users, user => user.id);
    const matchingDesignerIds = (matchingDesignersRaw?.listMatchingDesignersForOrder ?? []).map(user => user.id);
    const matchingDesigners = _.compact<UserPartialWithOrganization>(
        matchingDesignerIds.map(userId => usersById[userId]),
    );
    const [search, setSearch] = React.useState<string | undefined>(``);
    const [recentIds, setRecentIds] = useRecentlyAssignedUsers();
    const recentUsers = compact<UserPartialWithOrganization>(
        recentIds.map(recentId => users.find(({ id }) => id === recentId)),
    );

    const menuItemForUser = (isMyself: boolean) => (user: UserPartialWithOrganization) => (
        <MenuItem
            key={user.id}
            onClick={async () => {
                await assignTo((user.id !== last_user_id && user) || null);
                const { id } = user;
                if (id && user.id !== myself?.id) {
                    setRecentIds(recentIds => uniq([id, ...recentIds]).slice(0, 3));
                }
            }}
        >
            <Grid container style={{ height: 32 }} alignItems={`center`}>
                <Grid item>
                    <AssignmentAvatar user={user} />
                </Grid>
                <Grid item style={{ flexGrow: 1, paddingLeft: 8 }}>
                    {isMyself ? `Myself` : `${user.first_name} ${user.last_name}`}
                    {user.organization_type === 'external' ? ' (External)' : ''}
                </Grid>
                {user.id === last_user_id && <CheckIcon style={{ color: FlossPalette.STAR_GRASS }} />}
            </Grid>
        </MenuItem>
    );

    const assignedUserWithOrg = assigned_user?.id ? usersById[assigned_user.id] : null;
    const currentAssigneeItem = assignedUserWithOrg && menuItemForUser(false)(assignedUserWithOrg);
    const assignToMeItem = myself && assigned_user?.id !== myself.id && menuItemForUser(true)(myself);
    const matchItems = users
        .filter(
            ({ first_name, last_name }) =>
                `${first_name} ${last_name}`.toLowerCase().indexOf((search ?? ``).toLowerCase()) !== -1,
        )
        .map(menuItemForUser(false));
    const recentItems = assignTaskSubmenu(
        recentUsers.filter(u => !assigned_user || u.id !== assigned_user.id).map(menuItemForUser(false)),
        'Recent assignees',
    );

    const matchingDesignerItems = assignTaskSubmenu(
        matchingDesigners.filter(u => !assigned_user || u.id !== assigned_user.id).map(menuItemForUser(false)),
        'Matching designers',
    );

    return (
        <Popover
            open={!!anchor}
            onClose={onClose}
            anchorEl={anchor}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
            <SimpleInput
                fullWidth
                label={`Search users`}
                placeholder={'Type to search...'}
                onChange={setSearch}
                value={search}
                TextFieldProps={{
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position={'end'} style={{ paddingRight: 16, paddingBottom: 4 }}>
                                <SearchIcon color={'action'} />
                            </InputAdornment>
                        ),
                        disableUnderline: true,
                    },
                }}
            />
            {!search && currentAssigneeItem}
            {!search && clearAssignmentItem}
            {!search && assignToMeItem}
            {!search && <Divider />}
            {!search && recentItems}
            {!search && <Divider />}
            {!search && matchingDesignerItems}
            {search && loadingUsers && <MenuItem>Loading...</MenuItem>}
            {search && !loadingUsers && matchItems}
        </Popover>
    );
};

type TaskErr = { ok: false; id: string; message: string };
type TaskErrResult = { user_id: string | null; errors: TaskErr[] };
interface AssignTasksBulkButtonProps extends Omit<TaskButtonProps, 'task' | 'setOpen'> {
    tasks: LabsGqlWorkflowTaskFragment[];
    setSelectedTaskIds: (ids: string[]) => void;
}

export const AssignTasksBulkButton: React.FC<AssignTasksBulkButtonProps> = props => {
    const { tasks, refetch, buttonProps, setSelectedTaskIds } = props;
    const [failedTasks, setFailedTasks] = React.useState<TaskErrResult | undefined>(undefined);
    const client = useApolloClient();
    const assignBulk = React.useCallback(
        async (user_id: string | null, override: boolean): Promise<string | undefined> => {
            const operationsRaw = tasks.map<LabsGqlAssignFulfillmentTaskMutationVariables | undefined>(task => {
                if ((task.assignee?.assigned_user?.id ?? null) === user_id || task.closeout) {
                    return undefined;
                }
                const last_user_id = task.assignee?.assigned_user.id ?? null;
                const data = {
                    last_assigned_user_id: last_user_id,
                    assigned_user_id: user_id,
                    order_id: task.lab_order_id,
                    task_id: task.id,
                    override_validation: override,
                };
                return { data };
            });
            const neededOperations = _.compact(operationsRaw);
            const baseActionVerb = user_id ? 'Assign' : 'Unassign';
            if (
                neededOperations.length === 0 ||
                !window.confirm(`${baseActionVerb} ${neededOperations.length} tasks?`)
            ) {
                return undefined;
            }
            type Result = { ok: true } | TaskErr;
            const result = await Promise.all(
                neededOperations.map(async (variables): Promise<Result> => {
                    return client
                        .mutate({ variables, mutation: AssignFulfillmentTaskDocument })
                        .then((): Result => ({ ok: true }))
                        .catch((e): Result => {
                            return {
                                ok: false,
                                id: variables.data.task_id,
                                message: apolloErrorMessage(e, ''),
                            };
                        });
                }),
            );
            const errors = result.filter((r): r is TaskErr => !r.ok);
            setSelectedTaskIds(errors.map(err => err.id));
            if (errors.length > 0) {
                setFailedTasks({ user_id, errors });
                return undefined;
            }
            return `${baseActionVerb}ed ${result.length - errors.length} tasks.`;
        },
        [tasks, client, setSelectedTaskIds],
    );
    const [anchor, setAnchor] = useAnchor();
    type Res = string | undefined; // just needed this for formatter to not break linter
    type Params = [string | null, boolean];
    const { submit: assignTask, submitting: assigningTask } = useChangeSubmissionFn<Res, Params>(assignBulk, {
        successMessage: res => [res, {}],
        onError: () => refetch(),
        onSuccess: async () => {
            await refetch();
            setAnchor(null);
        },
    });
    const { soleAssignedUser, taskAssignees } = React.useMemo(() => {
        const taskAssignees = tasks.flatMap(t => (t.assignee ? [t.assignee.assigned_user] : []));
        const uniqAssignedUsers = _.uniqBy(taskAssignees, a => a.id);
        const soleAssignedUser =
            uniqAssignedUsers.length === 1 && taskAssignees.length === tasks.length ? taskAssignees[0] ?? null : null;
        return { soleAssignedUser, taskAssignees };
    }, [tasks]);
    return (
        <Grid item onClick={ev => ev.stopPropagation()}>
            <RootActionDialog
                loading={assigningTask}
                open={!!failedTasks}
                setOpen={() => setFailedTasks(undefined)}
                title={`Some tasks failed`}
                hideButton={true}
                content={
                    <Grid container direction={'column'} style={{ gap: '8px' }}>
                        <Text variant={'body2'}>
                            {failedTasks?.errors?.length} tasks failed to assign. Click below to retry, and override
                            safety checks.
                        </Text>
                        <Grid style={{ overflowY: 'auto' }}>
                            {failedTasks?.errors.map(taskInfo => {
                                const task = tasks.find(task => task.id === taskInfo.id);
                                if (!task) {
                                    return null;
                                }

                                return (
                                    <Grid
                                        key={task.id}
                                        container
                                        direction={'column'}
                                        style={{ borderBottom: '1px solid black' }}
                                    >
                                        <Text variant={'body1'}>
                                            <a
                                                target={'_blank'}
                                                rel={'noreferrer'}
                                                href={`/orders/${task.lab_order_id}`}
                                            >
                                                {task.type}
                                            </a>
                                        </Text>
                                        <Text variant={'caption'}>{taskInfo.message}</Text>
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Grid container>
                            <Button
                                variant={'primary'}
                                onClick={async () => {
                                    await assignTask(failedTasks?.user_id ?? null, true);
                                    setFailedTasks(undefined);
                                }}
                            >
                                Retry Failed Tasks
                            </Button>
                        </Grid>
                    </Grid>
                }
            />
            <TaskButtonBase
                tooltip={`${taskAssignees.length > 0 ? 'Re-assign' : 'Assign'} ${tasks.length} tasks`}
                onClick={ev => setAnchor(ev.currentTarget)}
                disabled={tasks.length === 0}
                {...buttonProps}
            >
                <LoadBlocker blocking={assigningTask}>
                    <AssignmentAvatar user={soleAssignedUser} disabled={tasks.length === 0} />
                </LoadBlocker>
            </TaskButtonBase>
            <AssignmentMenu
                anchor={anchor}
                onClose={() => setAnchor(null)}
                assigned_user={soleAssignedUser}
                assignTo={user => assignTask(user?.id ?? null, false)}
                clearAssignmentItem={
                    taskAssignees.length === 0 || soleAssignedUser ? undefined : (
                        <MenuItem
                            key={'clear-assignments'}
                            style={{ borderBottom: `1px solid ${FlossPalette.DARK_TAN}` }}
                            onClick={() => assignTask(null, false)}
                        >
                            Clear Assignment{tasks.length > 1 ? 's' : ''}
                        </MenuItem>
                    )
                }
            />
        </Grid>
    );
};

interface UserSelectButtonProps {
    assignedUser?: UserPartial;
    setAssignedUser: (user: UserPartial | null) => Promise<void>;
    setOpen?: (open: boolean) => void;
    buttonProps?: { className?: string };
    order_id?: string;
    disabled?: boolean;
}

export const UserSelectButton: React.FC<UserSelectButtonProps> = props => {
    const { assignedUser, setAssignedUser, setOpen, buttonProps, order_id, disabled } = props;

    const [anchor, setAnchor] = useAnchor(setOpen);
    return (
        <Grid item onClick={ev => ev.stopPropagation()}>
            <TaskButtonBase
                tooltip={
                    assignedUser ? `Assigned to ${assignedUser.first_name} ${assignedUser.last_name}` : `Not assigned`
                }
                onClick={ev => {
                    if (!disabled) {
                        setAnchor(ev.currentTarget);
                    }
                }}
                {...buttonProps}
            >
                <AssignmentAvatar user={assignedUser ?? null} />
            </TaskButtonBase>

            {/*
            NOTE: in some pages of the app (e.g. the Tasks page), UserSelectButton is rendered a large number of times.
            If the assignment menu is unconditionally rendered, those pages become much much slower, because suddenly
            the assignment menu is preparing and filtering through 30 instances of the entire list of all employees at Dandy,
            even when its not visible to the user. In the future, AssignmentMenu probably should be optimized, but it is much easier
            and probably just as effective to simply only render/run the component when the user is actually interacting
            with it.
             */}
            {anchor && (
                <AssignmentMenu
                    anchor={anchor}
                    onClose={() => setAnchor(null)}
                    assigned_user={assignedUser ?? null}
                    assignTo={setAssignedUser}
                    order_id={order_id}
                />
            )}
        </Grid>
    );
};

export const AssignTaskButton: React.FC<TaskButtonProps> = props => {
    const { task, refetch, setOpen, buttonProps } = props;
    const { id: task_id, lab_order_id: order_id, assignee } = task;
    const [override, setOverride] = React.useState<string | null>(null);
    const [rawAssignTask] = useAssignFulfillmentTaskMutation();

    const canChangeAssignee = useHasCapability('order', 'order.fulfillment.assign_task');

    const { submit: assignTask } = useChangeSubmissionFn(rawAssignTask, {
        successMessage: () => [`Task assigned`, {}],
        errorMessage: error => [
            `${apolloErrorMessage(error)}. Retry to override safety checks`,
            { autoHideDuration: 5_000 },
        ],
        onError: () => refetch(),
        onSuccess: () => refetch(),
    });
    const assignTo = React.useCallback(
        async (user_id: string | null) => {
            const data = {
                order_id,
                task_id,
                assigned_user_id: user_id,
                last_assigned_user_id: assignee?.assigned_user.id ?? null,
                override_validation: !!user_id && override === user_id,
            };
            setOverride(user_id);
            await assignTask({ variables: { data } });
        },
        [assignTask, assignee, order_id, task_id, override],
    );
    return (
        <UserSelectButton
            assignedUser={task.assignee?.assigned_user}
            setAssignedUser={user => assignTo(user?.id ?? null)}
            setOpen={setOpen}
            buttonProps={buttonProps}
            order_id={order_id}
            disabled={!canChangeAssignee}
        />
    );
};
