import type { CheckboxItem, ItemDisplayAndPriceInfo } from './CreditOrRefund.types';
import { useCreditOrRefundFormContext } from './providers/CreditOrRefundFormProvider.graphql';
import { DandySearchInput } from '@orthly/dentin';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlInvoiceAdjustmentAttributionType } from '@orthly/graphql-schema';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import { Checkbox, FlossPalette, FormControl, FormControlLabel, FormGroup, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const StyledFormControlLabel = styled(FormControlLabel)<{ indented: boolean }>(({ indented }) => ({
    marginLeft: '-14px',
    paddingLeft: indented ? '36px' : '0px',
    '.MuiTypography-root': {
        marginLeft: '0px',
        width: '100%',
    },
}));

const Stacked = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
});

const LabelContainer = styled('div')({
    display: 'flex',
    color: FlossPalette.GRAY,
});

const NoWrapText = styled(Text)({
    textWrap: 'nowrap',
});

const EndText = styled(Text)({
    textAlign: 'end',
});

export const OrderItemsSelector: React.FC<{ order: LabsGqlOrder }> = ({ order }) => {
    const { dispatchFormStateAction, existingCredit } = useCreditOrRefundFormContext();
    const [search, setSearch] = React.useState('');
    const lowerCaseSearch = search.toLowerCase();

    const orderPatientName = `${order.patient.first_name[0]}. ${order.patient.last_name}`;
    const items = OrderItemV2Utils.parseItems(order.items_v2);
    const displayItems = items.map<ItemDisplayAndPriceInfo>(item => {
        const { full_display_name, material } = CartItemV2Utils.getItemDisplayInfo(item);
        const label = `${full_display_name} - ${material}`;
        return {
            label,
            value: item.id,
            price: item.pricing.override_amount_due_cents ?? item.pricing.amount_due_cents ?? 0,
        };
    });

    const doesExistingCreditHaveOrderAttribution =
        existingCredit && existingCredit.attribution?.type === LabsGqlInvoiceAdjustmentAttributionType.Order;

    const checkboxItems: CheckboxItem[] = displayItems.map(item => {
        if (existingCredit?.attribution) {
            const shouldCheckItem =
                existingCredit.attribution.__typename === 'OrderItemAdjustmentAttribution' &&
                existingCredit.attribution.order_item_id === item.value;

            if (doesExistingCreditHaveOrderAttribution) {
                return { ...item, checked: true, disabled: true };
            } else {
                return {
                    ...item,
                    checked: shouldCheckItem,
                    disabled: true,
                };
            }
        }
        return { ...item, checked: false, disabled: item.price === 0 };
    });

    const [checkboxes, setCheckboxes] = React.useState<CheckboxItem[]>(checkboxItems);
    const hasCheckedItems = checkboxes
        .filter(checkbox => (existingCredit?.attribution ? true : !checkbox.disabled))
        .some(item => item.checked);
    const hasUncheckedItems = checkboxes
        .filter(checkbox => (existingCredit?.attribution ? true : !checkbox.disabled))
        .some(item => !item.checked);

    const handleCheckboxChange = (value: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;

        setCheckboxes(prevCheckboxes => {
            const newCheckboxes = prevCheckboxes.map(checkbox => {
                const updateCheckbox = checkbox.value === value && !checkbox.disabled;
                return updateCheckbox ? { ...checkbox, checked: isChecked } : checkbox;
            });

            const selectedItemsAmountCents = newCheckboxes.reduce(
                (acc, curr) => (curr.checked ? acc + curr.price : acc),
                0,
            );
            dispatchFormStateAction({
                type: 'SET_SELECTED_ITEMS',
                amountDollars: selectedItemsAmountCents > 0 ? `${selectedItemsAmountCents / 100}` : '',
                selectedItems: newCheckboxes.filter(newCheckbox => newCheckbox.checked && !newCheckbox.disabled),
            });

            return newCheckboxes;
        });
    };

    const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        checkboxes.forEach(checkbox => handleCheckboxChange(checkbox.value)(event));
    };

    const filteredSearchItems =
        checkboxes.filter(checkbox => {
            if (lowerCaseSearch === '') {
                return true;
            }

            return lowerCaseSearch
                .split(' ')
                .every(
                    term =>
                        checkbox.label.toLowerCase().includes(term) || checkbox.value.toLowerCase().startsWith(term),
                );
        }) ?? [];

    return (
        <Stacked>
            <Text variant={'body2'} medium>
                Apply to Order(s) and/or Item(s)
            </Text>
            <DandySearchInput
                search={search}
                onInputChange={value => setSearch(value)}
                onInputFocus={() => {}}
                inputStyle={{ width: '100%' }}
                onInputClear={() => setSearch('')}
                placeholder={'Search for items'}
            />
            <FormControl>
                <FormGroup>
                    <StyledFormControlLabel
                        indented={false}
                        control={
                            <Checkbox
                                color={'secondary'}
                                indeterminate={hasCheckedItems && hasUncheckedItems}
                                disabled={!!existingCredit?.attribution}
                                checked={hasCheckedItems}
                                onChange={handleSelectAllChange}
                            />
                        }
                        label={
                            <LabelContainer>
                                <Text variant={'body2'} color={!!existingCredit?.attribution ? 'GRAY' : 'BLACK'}>
                                    {orderPatientName}
                                </Text>
                                <EndText variant={'caption'} color={!!existingCredit?.attribution ? 'GRAY' : 'BLACK'}>
                                    {Format.currency(order.dentist_amount_due_cents ?? 0)}
                                </EndText>
                            </LabelContainer>
                        }
                    />
                    {filteredSearchItems.map(checkbox => (
                        <StyledFormControlLabel
                            key={checkbox.value}
                            indented={true}
                            control={
                                <Checkbox
                                    color={'secondary'}
                                    checked={checkbox.checked}
                                    disabled={checkbox.disabled}
                                    onChange={handleCheckboxChange(checkbox.value)}
                                />
                            }
                            label={
                                <LabelContainer>
                                    <NoWrapText variant={'body2'} color={checkbox.disabled ? 'GRAY' : 'BLACK'}>
                                        {checkbox.label}
                                    </NoWrapText>
                                    <EndText variant={'caption'} color={checkbox.disabled ? 'GRAY' : 'BLACK'}>
                                        {Format.currency(checkbox.price)}
                                    </EndText>
                                </LabelContainer>
                            }
                        />
                    ))}
                </FormGroup>
            </FormControl>
        </Stacked>
    );
};
