import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import { LabsGqlLabPriceRuleType, LabsGqlOrderItemPartialDentureClaspType } from '@orthly/graphql-schema';
import { QuickForm } from '@orthly/ui';

export const PartialDentureClaspForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.PartialDentureClasp>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.PartialDentureClasp>({
                clasp_type: {
                    type: 'select',
                    label: 'Clasp Type',
                    options: Object.values(LabsGqlOrderItemPartialDentureClaspType),
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          clasp_type:
                              price.rule.__typename === 'LabPricePartialDentureClaspRuleDTO'
                                  ? price.rule.clasp_type
                                  : null,
                      }
                    : {}
            }
            onSubmit={async result => {
                await submit({
                    name: result.name,
                    rule: { type: LabsGqlLabPriceRuleType.PartialDentureClasp, clasp_type: result.clasp_type || null },
                });
            }}
            disabled={disabled}
        />
    );
};
