import { PatientViewsToolbar } from '../BottomToolbar/PatientViewsToolbar';
import { ToothViewsToolbar } from '../BottomToolbar/ToothViewsToolbar';
import type { ViewControlButtonsProps } from './ViewControlButtons';
import { ViewControlButtons } from './ViewControlButtons';
import type { IViewManager } from '@orthly/dentin';
import { StackX } from '@orthly/ui';
import type { BoxPropsBase } from '@orthly/ui-primitives';
import { stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    toolbar: {
        margin: '0px 8px',
    },
}));

interface ExpandedViewToolbarsProps extends BoxPropsBase, Omit<ViewControlButtonsProps, 'connected'> {
    viewManager: IViewManager;
}

export const ExpandedViewToolbars: React.VFC<ExpandedViewToolbarsProps> = ({
    viewManager,
    resetCamera,
    viewExpanded,
    expandView,
    minimizeView,
    ...boxProps
}) => {
    const classes = useStyles();

    return (
        // @ts-expect-error: StackX has an incompatible "ref" prop.
        <StackX {...boxProps}>
            <ViewControlButtons
                connected
                connectedClassName={classes.toolbar}
                resetCamera={resetCamera}
                viewExpanded={viewExpanded}
                expandView={expandView}
                minimizeView={minimizeView}
            />
            <PatientViewsToolbar className={classes.toolbar} viewManager={viewManager} />
            <ToothViewsToolbar className={classes.toolbar} viewManager={viewManager} />
        </StackX>
    );
};
