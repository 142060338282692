import { PrintTable } from '../../../components/PrintTable';
import type { PartnerImportCsvRow, PricesRow } from '../PricingRoot.types';
import { useUpdatedPricingQuery } from '../hooks/useUpdatedPricingQuery.graphql';
import { PriceImportItem, cellValToPriceCents, useRowsFromCsv } from '../useCsvImport';
import { useMutation } from '@apollo/client';
import { graphql, type ListPricesWithPracticeCountsQuery } from '@orthly/graphql-inline-react';
import type {
    LabsGqlBulkUploadPartnerPriceUpsert,
    LabsGqlBulkUploadPartnerPriceUpsertEntry,
} from '@orthly/graphql-schema';
import { LoadBlocker, useRootActionCommand } from '@orthly/ui';
import { Button, Collapse, Grid, Text } from '@orthly/ui-primitives';
import { SimpleDropzone } from '@orthly/veneer';
import React from 'react';

type PricingPartnerImportUploadProps = {
    refetchAfterImport: (rows: PartnerImportCsvRow[]) => void;
    fetchedPrices: Record<string, Partial<PricesRow>>;
    setSortColumnName: React.Dispatch<React.SetStateAction<'' | 'isLoaded'>>;
};

function csvRowToUpsert(
    row: PartnerImportCsvRow,
    prices: ListPricesWithPracticeCountsQuery['listPricesWithPracticeCounts'],
): LabsGqlBulkUploadPartnerPriceUpsert {
    const entries = Object.entries(row).reduce<LabsGqlBulkUploadPartnerPriceUpsertEntry[]>(
        (result, [cellHeader, cellValue]) => {
            const matchingPrice = prices.find(p => p.name === cellHeader);
            if (!matchingPrice) {
                return result;
            }
            const price_cents = cellValToPriceCents(cellValue);
            return [
                ...result,
                { price_cents: typeof price_cents === 'number' ? price_cents : null, price_name: cellHeader },
            ];
        },
        [],
    );
    return { partner_id: row.partner_id, upserts: entries };
}

function useStyledCsvRows(inputRows?: PartnerImportCsvRow[], fetchedPrices?: Record<string, Partial<PricesRow>>) {
    const { prices } = useUpdatedPricingQuery();

    return React.useMemo(() => {
        if (!inputRows) {
            return [];
        }
        return inputRows.map(row => {
            const { partner_id, ...rest } = row;
            return Object.entries(rest).reduce<{ [k: string]: React.ReactNode }>((styledRow, [header, cell]) => {
                const matchingPrice = prices.find(p => p.name === header);
                const importPrice = cellValToPriceCents(cell);
                if (!matchingPrice) {
                    return styledRow;
                }
                const currentPrice = fetchedPrices?.[partner_id]?.[header];
                if (typeof currentPrice !== 'string') {
                    return styledRow;
                }
                return {
                    ...styledRow,
                    [header]: (
                        <PriceImportItem
                            importPriceCents={importPrice}
                            currentPriceCents={cellValToPriceCents(currentPrice)}
                        />
                    ),
                };
            }, rest);
        });
    }, [inputRows, prices, fetchedPrices]);
}

const BulkUpsertPartnerPrices_Mutation = graphql(`
    mutation BulkUpsertPrices($upserts: [BulkUploadPartnerPriceUpsert!]!) {
        bulkUpsertPrices(upserts: $upserts) {
            errors
        }
    }
`);

export const PricingPartnerImportUpload: React.FC<PricingPartnerImportUploadProps> = ({
    refetchAfterImport,
    fetchedPrices,
    setSortColumnName,
}) => {
    const { prices, pricesLoading } = useUpdatedPricingQuery();
    const bulkUpsertPartnerPrices = useMutation(BulkUpsertPartnerPrices_Mutation);
    const { submit, submitting } = useRootActionCommand(bulkUpsertPartnerPrices, {
        // can't rely on the error state from the mutation since the upsert is dispatched to a worker
        // and the errors response is always an empty array. so, just toast a success message
        successMessage: 'Prices imported successfully',
    });
    const [inputRows, setInputRows] = React.useState<PartnerImportCsvRow[] | undefined>();

    const onDropAccepted = useRowsFromCsv<PartnerImportCsvRow>({
        checkColumns: columns => {
            if (columns[0] !== 'partner_id') {
                window.alert('First column must be `partner_id`');
                return false;
            }

            return true;
        },

        onValid: (rows: PartnerImportCsvRow[]) => {
            setInputRows(rows.filter(row => typeof row.partner_id === 'string' && row.partner_id !== ''));
            setSortColumnName('isLoaded');
        },
    });

    async function onSubmit() {
        if (!inputRows || submitting) {
            return;
        }
        const upserts = inputRows.map(r => csvRowToUpsert(r, prices));
        await submit({ upserts });
        // can't do a refetch here since the bulk upsert is enqueued to a worker (i.e. we don't know
        // when these updates will be available to fetch in the DB).
        // we also don't have a valid error state to show to the user, so for now just
        // refresh the stateful UI data with the upserts
        refetchAfterImport(inputRows);
        setInputRows(undefined);
    }

    const styledInputRows = useStyledCsvRows(inputRows, fetchedPrices);

    return (
        <LoadBlocker blocking={submitting || pricesLoading}>
            <Grid container style={{ padding: '10px 30px', maxHeight: 60, display: inputRows ? 'none' : undefined }}>
                <SimpleDropzone
                    preUploadText={'Import Prices With CSV (click or drop file)'}
                    wrapperStyle={{ minHeight: 40, padding: 0 }}
                    options={{ onDropAccepted, multiple: false }}
                />
            </Grid>
            <Collapse in={!!inputRows} style={{ width: '100%' }}>
                <Grid container alignItems={'center'} wrap={'nowrap'} style={{ padding: 20 }}>
                    <Text variant={'h6'} style={{ width: '100%' }}>
                        From Import
                    </Text>
                    <Grid container justifyContent={'flex-end'}>
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                onSubmit().catch(console.error);
                            }}
                            style={{ marginLeft: 4 }}
                            startIcon={'CloudUploadIcon'}
                        >
                            Upload Import
                        </Button>
                        <Button
                            variant={'contained'}
                            onClick={() => setInputRows(undefined)}
                            style={{ marginLeft: 4, color: 'red' }}
                            startIcon={'Backspace'}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <PrintTable rows={styledInputRows} disableHeaderFormatting />
            </Collapse>
        </LoadBlocker>
    );
};
