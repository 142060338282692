import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';

const GetScanExport_Query = graphql(`
    query GetScanExport($scan_export_id: String!) {
        scan_file(scanId: $scan_export_id) {
            created_at
        }
    }
`);

export const useGetScanExport = (scanExportId: string | null) => {
    const { data, loading } = useQuery(GetScanExport_Query, {
        variables: { scan_export_id: scanExportId ?? '' },
        skip: !scanExportId,
    });

    return { createdAt: data?.scan_file?.created_at, loading };
};
