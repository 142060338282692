import type { SubmitterParams } from '../../utils';
import { handleSuccess } from '../../utils';
import { DesignRuleForm } from './DesignRuleForm';
import { useCreateDesignRuleMutation } from '@orthly/graphql-react';
import type { LabsGqlCreateDesignRuleCommand } from '@orthly/graphql-schema';
import { useChangeSubmissionFn } from '@orthly/ui';
import { Text, Dialog, DialogContent, DialogTitle } from '@orthly/ui-primitives';
import React from 'react';

function useCreateDesignRuleSubmitter(params: SubmitterParams) {
    const [submitMtn] = useCreateDesignRuleMutation();
    const mtnSubmitter = (data: LabsGqlCreateDesignRuleCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        successMessage: () => ['Design Rule created!', {}],
        onSuccess: () => handleSuccess(params),
    });
    return { submit, submitting };
}

export const CreateDesignRuleDialog: React.FC<{
    open: boolean;
    close: () => void;
    refetch: () => Promise<unknown>;
}> = ({ refetch, close, open }) => {
    const { submit, submitting } = useCreateDesignRuleSubmitter({
        onComplete: async () => {
            await refetch();
            close();
        },
    });
    return (
        <Dialog open={open} onClose={() => close()} maxWidth={'md'} fullWidth PaperProps={{ style: { minWidth: 350 } }}>
            <DialogTitle>
                <Text variant={'h6'} component={'span'}>
                    Add Design Rule
                </Text>
            </DialogTitle>
            <DialogContent style={{ padding: 20, borderTop: 'none' }} dividers>
                <DesignRuleForm
                    loading={submitting}
                    onSubmit={values => {
                        void submit({ ...values });
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};
