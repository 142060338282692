import { useFirebasePreviewMulti } from '../../hooks/useFirebaseDownload';
import { useFilesFromOnPasteEvent } from '../../util';
import { DandyLightbox, isImagePath } from '../DandyLightbox/DandyLightbox';
import { TimelineEvent } from '../Timeline/TimelineEvent';
import { ChatStickyProvider, useChatStickyParent } from './ChatSticky.provider';
import type { MixedTimelineData, DandyChatMessage, TimelineEventProps } from './DandyChat.types';
import { DandyChatMessageItem } from './DandyChatMessage';
import { format_timeline_date } from './formatTimelineDate';
import { IOrganizationType } from '@orthly/retainer-common';
import type { WithClassesProp } from '@orthly/ui';
import { LoadBlocker, SidebarWidthMobile, useMergedMuiClasses } from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    InputBase,
    TextareaAutosize,
    Button,
    Grid,
    Text,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import 'react-image-lightbox/style.css';

export type DandyChatClassKey =
    | 'rootWrapper'
    | 'scrollWrapper'
    | 'chatMessageContainer'
    | 'inputContainer'
    | 'inputRoot'
    | 'timelineItemWrapper'
    | 'lightbox'
    | 'chatInput';

export const useChatStyles = stylesFactory<{}, DandyChatClassKey>(theme => ({
    rootWrapper: {
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
        flexWrap: 'nowrap !important' as any,
    },
    scrollWrapper: {
        flexDirection: 'column',
        overflow: 'auto',
        justifyContent: 'flex-start',
        height: '100%',
    },
    chatMessageContainer: {
        overflowY: 'scroll',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
    },
    timelineItemWrapper: {
        paddingLeft: 10,
        paddingTop: 8,
        paddingRight: 8,
        width: '100%',
    },
    inputContainer: {
        alignSelf: 'flex-start',
        flexWrap: 'nowrap',
        background: '#fff',
        borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
    },
    inputRoot: {
        alignItems: `flex-end`,
        '& textarea': {
            padding: '5px 0 !important',
            backgroundColor: FlossPalette.WHITE,
            width: `100%`,
            border: `none`,
            outline: `none`,
            font: `inherit`,
            resize: `none`,
            lineHeight: `20px`,
        },
    },
    lightbox: {
        '& .ril__navButtonPrev, .ril__navButtonNext': {
            backgroundColor: FlossPalette.STAR_GRASS,
            borderRadius: 2,
            marginRight: 8,
            marginLeft: 8,
        },
    },
    chatInput: {
        [theme.breakpoints.down('lg')]: {
            marginLeft: SidebarWidthMobile,
        },
    },
}));

function useScrollToBottomEffect(timelineLength: number, expandedInput: boolean) {
    const chatScrollRef = React.useRef<HTMLDivElement | null>(null);

    function scrollBottom() {
        const out = chatScrollRef.current;
        if (!out) {
            return;
        }
        const isScrolledToBottom = out.scrollHeight - out.clientHeight <= out.scrollTop + 1;
        if (!isScrolledToBottom) {
            out.scrollTop = out.scrollHeight - out.clientHeight;
        }
    }

    // Scroll to bottom when the number of timeline items changes or if the input is expanded/closed
    React.useEffect(scrollBottom, [timelineLength, expandedInput]);
    return { chatScrollRef, scrollBottom };
}

function useMixedTimeline(messages: DandyChatMessage[], events: TimelineEventProps[] = []): MixedTimelineData[] {
    return React.useMemo(() => {
        const eventItems = events.map<MixedTimelineData>(event => ({ data: event, date: event.date, type: 'event' }));
        const chats = messages.map<MixedTimelineData>(chat => ({ type: 'message', data: chat, date: chat.date }));
        return _.sortBy([...eventItems, ...chats], i => i.date.valueOf());
    }, [events, messages]);
}

export interface DandyChatInputProps {
    newMessageText: string;
    setNewMessageText: (text: string) => void;
    currentRecipient?: string;
    placeholder?: string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    inputStartAdornment?: React.ReactNode;
    expanded?: boolean;
    onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onFilePaste?: (files: File[]) => void;
}

export const DandyChatInput: React.FC<DandyChatInputProps> = props => {
    const {
        newMessageText,
        setNewMessageText,
        currentRecipient,
        onKeyDown,
        onFocus,
        inputStartAdornment,
        expanded,
        onFilePaste,
    } = props;
    const recipientText = currentRecipient !== 'psr' ? currentRecipient : 'internal';
    const placeholder = props.placeholder ?? `Chat${currentRecipient ? ` with ${recipientText}` : ''}`;
    const classes = useChatStyles();
    const onPaste = useFilesFromOnPasteEvent(onFilePaste);

    return (
        <InputBase
            fullWidth
            startAdornment={inputStartAdornment}
            {...{ onKeyDown, onFocus }}
            onChange={ev => setNewMessageText(ev.target.value)}
            onPaste={onPaste}
            value={newMessageText}
            autoFocus={expanded}
            placeholder={placeholder}
            classes={{ root: classes.inputRoot }}
            inputProps={{ minRows: expanded ? 2 : 1 }}
            inputComponent={TextareaAutosize}
            style={{ padding: `8px 0`, backgroundColor: FlossPalette.WHITE }}
        />
    );
};

export interface DandyChatProps extends WithClassesProp<DandyChatClassKey> {
    currentStaffId: string;
    messages: DandyChatMessage[];
    highlight_since: Date | null;
    events?: TimelineEventProps[];
    onAddChat?: (text: string) => Promise<void>;
    submitting: boolean;
    scrollWrapStyle?: React.CSSProperties;
    inputStartAdornment?: React.ReactNode;
    expandedInput?: boolean;
    practice_id: string;
    lab_id: string;
    inputPlaceholder?: string;
    chatActions?: React.ReactNode;
    onFilePaste?: (files: File[]) => void;
    orderId: string | null;
}

interface DandyChatBaseProps extends DandyChatProps {
    enableStickyDate?: boolean;
    newMessageText: string;
    setNewMessageText: (text: string) => void;
}

interface DandyChatLightboxProps {
    timelineItems: MixedTimelineData[];
    setSelectedAttachmentPreview: (attachment: string | undefined) => void;
    selectedAttachmentPreview?: string;
    orderId: string | null;
}

// Prepares a lightbox for the chat
// Handles image loading, image selection changes, etc.
// When selectedAttachmentPreview is defined, we will load in a lightbox with that image selected.
export const DandyChatLightbox: React.FC<DandyChatLightboxProps> = props => {
    const { timelineItems, setSelectedAttachmentPreview, selectedAttachmentPreview } = props;
    const attachments = React.useMemo(() => {
        return timelineItems
            .flatMap(item => item.data.attachments ?? [])
            .map(({ url }) => url)
            .filter(url => isImagePath(url))
            .map(url => ({ source: url, name: url }));
    }, [timelineItems]);

    // Loads firebase URLs since we currently just have Firebase paths in the attachments list.
    const { result: previews, loading } = useFirebasePreviewMulti(attachments);
    return (
        <DandyLightbox
            setSelectedAttachmentPreview={setSelectedAttachmentPreview}
            loading={loading}
            previews={previews}
            selectedAttachmentPreview={selectedAttachmentPreview}
            onPhotoViewedAnalytics={(source, name) => {
                return {
                    name: 'All - Portal - Photo Lightbox Viewed',
                    data: {
                        $groups: { order: props.orderId ?? undefined },
                        displayLocation: 'order_timeline',
                        photoSource: source,
                        photoName: name,
                    },
                };
            }}
        />
    );
};

export const DandyChatStickyDate: React.VFC<{ date: Date; style?: React.CSSProperties }> = ({ date, style }) => (
    <div
        style={{
            zIndex: 99,
            padding: `0 3px`,
            position: `absolute`,
            top: 7,
            left: 6,
            backgroundColor: FlossPalette.WHITE,
            border: `1px solid ${FlossPalette.GRAY}`,
            borderRadius: `16px`,
            ...style,
        }}
    >
        <Text style={{ fontSize: 12, fontWeight: 500, color: FlossPalette.GRAY }}>{format_timeline_date(date)}</Text>
    </div>
);

const DandyChatBaseInner: React.FC<DandyChatBaseProps> = props => {
    const classes = useMergedMuiClasses<DandyChatClassKey>(useChatStyles(), props.classes);
    const {
        submitting,
        currentStaffId,
        newMessageText,
        setNewMessageText,
        inputStartAdornment,
        onAddChat,
        expandedInput,
        events,
        messages,
        highlight_since,
        enableStickyDate,
        lab_id,
        practice_id,
        inputPlaceholder,
        chatActions,
        orderId,
    } = props;
    const isMobileNav = useScreenIsMobileOrVerticalTablet();
    const mixedTimeline = useMixedTimeline(messages, events);
    const [pendingChat, setPendingChat] = React.useState<DandyChatMessage | undefined>();

    // We set to undefined when no image is currently selected.
    const [selectedAttachmentPreview, setSelectedAttachmentPreview] = React.useState<string | undefined>(undefined);

    const { chatScrollRef } = useScrollToBottomEffect(mixedTimeline.length, expandedInput ?? false);
    const onSubmit = React.useCallback(async () => {
        if (newMessageText.trim() && !submitting) {
            onAddChat &&
                setPendingChat({
                    staffId: currentStaffId,
                    username: 'You',
                    text: newMessageText,
                    date: new Date(),
                    deleted_at: null,
                    senderRole: IOrganizationType.practice,
                });
            setNewMessageText('');
            await onAddChat?.(newMessageText);
            setPendingChat(undefined);
        }
    }, [newMessageText, submitting, onAddChat, currentStaffId, setNewMessageText]);
    const onKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            if (event.key === 'Enter' && !event.shiftKey && newMessageText?.length > 1) {
                event.preventDefault();
                event.stopPropagation();
                onSubmit().catch(console.error);
            }
        },
        [newMessageText, onSubmit],
    );
    const pendingChatItem: MixedTimelineData | undefined = pendingChat
        ? { type: 'message', date: pendingChat.date, data: pendingChat }
        : undefined;
    const timelineWithPending: MixedTimelineData[] = [...mixedTimeline, ...(pendingChatItem ? [pendingChatItem] : [])];
    const visible_dates = useChatStickyParent(chatScrollRef);
    const date = React.useMemo(() => _.minBy(visible_dates, d => d.valueOf()), [visible_dates]);
    return (
        <>
            {selectedAttachmentPreview && (
                <DandyChatLightbox
                    timelineItems={mixedTimeline}
                    selectedAttachmentPreview={selectedAttachmentPreview}
                    setSelectedAttachmentPreview={setSelectedAttachmentPreview}
                    orderId={orderId}
                />
            )}
            <Grid
                container
                className={`${classes.rootWrapper} dandy-rpa-timeline`}
                style={{ background: FlossPalette.TAN }}
            >
                {date && enableStickyDate && <DandyChatStickyDate date={date} />}
                <Grid container className={classes.scrollWrapper} style={{ ...props.scrollWrapStyle }}>
                    <Grid container className={classes.chatMessageContainer} ref={chatScrollRef}>
                        {timelineWithPending.map((item, idx) => {
                            const previousItem = mixedTimeline[idx - 1];
                            const backgroundColor =
                                highlight_since && highlight_since < new Date(moment(item.data.date).unix() * 1000)
                                    ? FlossPalette.ATTENTION_BG
                                    : undefined;
                            const key = `${item.date.valueOf()}-${idx}`;
                            if (item.type === 'message') {
                                return (
                                    <div
                                        key={key}
                                        style={{ backgroundColor }}
                                        className={`${classes.timelineItemWrapper} dandy-rpa-timeline-item`}
                                    >
                                        <DandyChatMessageItem
                                            setSelectedAttachmentPreview={setSelectedAttachmentPreview}
                                            currentStaffId={currentStaffId}
                                            previousItem={previousItem}
                                            key={`${item.data.staffId}_${item.data.date.valueOf()}`}
                                            item={item.data}
                                            lab_id={lab_id}
                                            practice_id={practice_id}
                                        />
                                    </div>
                                );
                            }
                            return (
                                <div
                                    style={{ backgroundColor }}
                                    key={key}
                                    className={`${classes.timelineItemWrapper} dandy-rpa-timeline-item`}
                                >
                                    <TimelineEvent
                                        setSelectedAttachmentPreview={setSelectedAttachmentPreview}
                                        previousDate={previousItem?.date}
                                        {...item.data}
                                    />
                                </div>
                            );
                        })}
                    </Grid>
                </Grid>
                {typeof props.onAddChat !== 'undefined' && (
                    <LoadBlocker blocking={submitting} ContainerProps={{ classes: { root: classes.inputContainer } }}>
                        <Grid
                            container
                            className={!isMobileNav ? classes.chatInput : undefined}
                            style={{ paddingLeft: 8 }}
                        >
                            <DandyChatInput
                                onFilePaste={props.onFilePaste}
                                newMessageText={newMessageText}
                                setNewMessageText={setNewMessageText}
                                inputStartAdornment={inputStartAdornment}
                                onKeyDown={onKeyDown}
                                placeholder={inputPlaceholder}
                            />
                        </Grid>
                        {chatActions && (
                            <Grid item style={{ alignSelf: 'center' }}>
                                {chatActions}
                            </Grid>
                        )}
                        <Grid container style={{ width: 'auto', padding: 4 }}>
                            <Button
                                fullWidth
                                variant={'contained'}
                                style={{ borderRadius: 3, alignSelf: `flex-end` }}
                                disabled={submitting || !newMessageText}
                                onClick={async () => {
                                    await onSubmit();
                                }}
                            >
                                Send
                            </Button>
                        </Grid>
                    </LoadBlocker>
                )}
                {props.children}
            </Grid>
        </>
    );
};

export const DandyChatBase: React.FC<DandyChatBaseProps> = props => (
    <ChatStickyProvider>
        <DandyChatBaseInner {...props} />
    </ChatStickyProvider>
);

export const DandyChat: React.FC<DandyChatProps> = props => {
    const [newMessageText, setNewMessageText] = React.useState<string>('');
    return <DandyChatBase {...props} setNewMessageText={setNewMessageText} newMessageText={newMessageText} />;
};
