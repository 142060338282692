import { DesignerStatusIcon } from './DesignerStatusIcon';
import { DesignerStatusIndicatorConfig } from './DesignerStatusIndicator';
import type {
    LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment,
    LabsGqlDesignStaffFocusAreaDtoFragment,
} from '@orthly/graphql-operations';
import { WorkflowTaskTypeToName } from '@orthly/graphql-operations';
import { useUpdateDesignStaffStatusMutation } from '@orthly/graphql-react';
import { LabsGqlDesignStaffStatus as DesignerStatus, LabsGqlDesignStaffTaskType } from '@orthly/graphql-schema';
import { objectEntries } from '@orthly/runtime-utils';
import { useRootActionCommand, QuickForm, LoadBlocker } from '@orthly/ui';
import {
    FlossPalette,
    Dialog,
    DialogContent,
    Grid,
    Collapse,
    Text,
    makeStyles,
    createStyles,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { z } from 'zod';

const AWAY_LENGTH_OPTIONS = ['30', '45', '60', '90'];

const useStyles = makeStyles(() =>
    createStyles({
        optionContainer: {
            alignItems: 'center',
            padding: `4px 8px 4px 8px`,
            cursor: 'pointer',
        },
        optionStatusBadge: {
            height: 12,
            width: 12,
            borderRadius: 3,
            margin: '12px 12px 12px 0px',
        },
        optionTextContainer: {
            alignItems: 'center',
            justifyContent: 'center',
        },
        currentStatusContainer: {
            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
            paddingBottom: 8,
        },
        currentStatusTextContainer: {
            alignItems: 'center',
            justifyContent: 'center',
        },
        dialogTitle: {
            marginBottom: 16,
        },
    }),
);

interface DesignStatusDialog {
    open: boolean;
    setOpen: (open: boolean) => void;
    onClose: () => void;
    user_id: string;
    currentStatus: DesignerStatus;
    assignableTaskTypes: LabsGqlDesignStaffTaskType[];
    designerCapabilities: LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment;
    initials: string;
    badgeColor: string;
    focusAreas: LabsGqlDesignStaffFocusAreaDtoFragment[];
}

const isLabsGqlDesignStaffTaskType = (taskType: any): taskType is LabsGqlDesignStaffTaskType =>
    !!(LabsGqlDesignStaffTaskType as any)[taskType];

function useOptions(currentStatus: DesignerStatus): DesignerStatus[] {
    switch (currentStatus) {
        case DesignerStatus.Active:
            return [DesignerStatus.Paused, DesignerStatus.Offline, DesignerStatus.Away];
        case DesignerStatus.Draining:
        case DesignerStatus.Paused:
        case DesignerStatus.Away:
            return [DesignerStatus.Active, DesignerStatus.Offline];
        case DesignerStatus.Inactive:
        case DesignerStatus.Offline:
            return [DesignerStatus.Active];
        default:
            return [];
    }
}

function getFocusAreaTaskTypes(focusAreas: LabsGqlDesignStaffFocusAreaDtoFragment[]): LabsGqlDesignStaffTaskType[] {
    return _.compact([...new Set(focusAreas.map(fa => LabsGqlDesignStaffTaskType[fa.task_type]))]);
}

const StatusDialogOption: React.VFC<{
    optionStatus: DesignerStatus;
    color: string;
    tagline: string;
    onClick: any;
}> = props => {
    const { optionStatus, color, tagline, onClick } = props;
    const styles = useStyles();

    return (
        <Grid container direction={'row'} className={styles.optionContainer} onClick={onClick}>
            <Grid item className={styles.optionStatusBadge} style={{ backgroundColor: color }} />
            <Grid direction={'column'} item className={styles.optionTextContainer}>
                <Text variant={'body2'}>Set to {optionStatus}</Text>
                <Text variant={'caption'} color={'GRAY'}>
                    {tagline}
                </Text>
            </Grid>
        </Grid>
    );
};

const DesignerSetStatusDialogContent: React.FC<DesignStatusDialog> = props => {
    const {
        setOpen,
        onClose,
        user_id,
        currentStatus,
        designerCapabilities,
        assignableTaskTypes: defaultAssignableTaskTypes,
        initials,
        badgeColor,
        focusAreas,
    } = props;
    const styles = useStyles();

    const { submit, submitting } = useRootActionCommand(useUpdateDesignStaffStatusMutation(), {
        successMessage: `Your status has been updated.`,
        onSuccess: async () => {
            setOpen(false);
            onClose();
        },
    });

    const designerTasks = React.useMemo<LabsGqlDesignStaffTaskType[]>(() => {
        const tasksFromCapabilities = objectEntries(designerCapabilities).flatMap(([taskType, capabilities]) => {
            if (
                isLabsGqlDesignStaffTaskType(taskType) &&
                Object.values(_.omit(capabilities ?? {}, ['__typename'])).some(value => !!value)
            ) {
                return [taskType];
            }
            return [];
        });

        return _.compact([
            ...tasksFromCapabilities,
            designerCapabilities.InternalDesign.model ? LabsGqlDesignStaffTaskType.ModelDesign : undefined,
        ]);
    }, [designerCapabilities]);

    const updateDesignStaffStatus = React.useCallback(
        (
            status: DesignerStatus,
            assignable_task_types?: LabsGqlDesignStaffTaskType[],
            away_length_minutes?: number,
        ) => {
            void submit({ data: { user_id, status, assignable_task_types, away_length_minutes } });
        },
        [submit, user_id],
    );

    const [showTaskTypeList, setShowTaskTypeList] = React.useState(false);
    const [showAwayLengthOptions, setShowAwayLengthOptions] = React.useState(false);

    const statusOptions = useOptions(currentStatus);

    const handleStatusOptionSelection = (status: DesignerStatus) => {
        if (status === DesignerStatus.Active) {
            if (focusAreas.length) {
                const assignable_task_types = getFocusAreaTaskTypes(focusAreas);
                updateDesignStaffStatus(status, assignable_task_types);
            } else {
                setShowTaskTypeList(true);
            }
        } else if (status === DesignerStatus.Away) {
            setShowAwayLengthOptions(true);
        } else {
            updateDesignStaffStatus(status);
        }
    };

    return (
        <>
            <Collapse in={!showTaskTypeList && !showAwayLengthOptions}>
                <LoadBlocker blocking={submitting} />
                <Grid container direction={'row'} className={styles.currentStatusContainer}>
                    <Grid item>
                        <DesignerStatusIcon initials={initials} badgeColor={badgeColor} />
                    </Grid>
                    <Grid direction={'column'} item className={styles.currentStatusTextContainer}>
                        <Text variant={'body2'}>{currentStatus}</Text>
                        <Text variant={'caption'} color={'GRAY'}>
                            {DesignerStatusIndicatorConfig[currentStatus].title}
                        </Text>
                    </Grid>
                </Grid>
                {statusOptions.map(status => (
                    <StatusDialogOption
                        key={status}
                        optionStatus={status}
                        color={DesignerStatusIndicatorConfig[status].color}
                        tagline={DesignerStatusIndicatorConfig[status].tagline}
                        onClick={() => handleStatusOptionSelection(status)}
                    />
                ))}
            </Collapse>
            <Collapse in={showTaskTypeList}>
                <Text variant={'body1'} bold className={styles.dialogTitle}>
                    Select the types of tasks that you would like to work on:
                </Text>
                <QuickForm<{ assignable_task_types: LabsGqlDesignStaffTaskType[] }>
                    disabled={submitting}
                    fields={{
                        assignable_task_types: {
                            type: 'multiselect',
                            options: [...Object.values(LabsGqlDesignStaffTaskType)]
                                .filter(taskType => designerTasks.includes(taskType))
                                .map(taskType => ({
                                    value: taskType,
                                    label: WorkflowTaskTypeToName[taskType],
                                })),
                            validation: z.array(z.nativeEnum(LabsGqlDesignStaffTaskType)).min(1),
                        },
                    }}
                    initialValues={{
                        assignable_task_types: defaultAssignableTaskTypes.filter(taskType =>
                            designerTasks.includes(taskType),
                        ),
                    }}
                    onSubmit={({ assignable_task_types }) =>
                        updateDesignStaffStatus(DesignerStatus.Active, assignable_task_types)
                    }
                    submitButtonProps={{
                        children: 'Go active',
                    }}
                />
            </Collapse>

            <Collapse in={showAwayLengthOptions}>
                <Text variant={'body1'} bold className={styles.dialogTitle}>
                    How long will you be Away?
                </Text>
                <QuickForm<{ away_length_minutes: string }>
                    fields={{
                        away_length_minutes: {
                            type: 'select',
                            options: AWAY_LENGTH_OPTIONS.map(num => ({
                                value: num,
                                label: `${num} minutes`,
                            })),
                            validation: z.string().min(1),
                        },
                    }}
                    initialValues={{
                        away_length_minutes: undefined,
                    }}
                    onSubmit={({ away_length_minutes }) => {
                        updateDesignStaffStatus(DesignerStatus.Away, undefined, parseFloat(away_length_minutes));
                    }}
                    submitButtonProps={{
                        children: 'Go Away',
                    }}
                />
            </Collapse>
        </>
    );
};

export const DesignerSetStatusDialog: React.FC<DesignStatusDialog> = props => {
    const { open, setOpen } = props;

    return (
        <Dialog
            open={open}
            onClose={(e: Event) => {
                e.stopPropagation();
                setOpen(false);
            }}
            PaperProps={{ style: { borderRadius: 10 } }}
        >
            <DialogContent style={{ padding: 20, borderTop: 'none' }}>
                {open && <DesignerSetStatusDialogContent {...props} />}
            </DialogContent>
        </Dialog>
    );
};
