import { DandyMouthInterproximalSpaceSelector } from '@orthly/dentin';
import type { LabsGqlPendingAlignerCaseInterproximalSpaceSizeInput } from '@orthly/graphql-schema';
import type { InterproximalSpaceSize } from '@orthly/items';
import type { CustomQFComponentProps } from '@orthly/ui';
import { Text, Divider, Grid } from '@orthly/ui-primitives';
import React from 'react';
import { z } from 'zod';

export const validateSpacing = z.array(z.object({}));

export const UpdateSpacing: React.FC<CustomQFComponentProps<{ label: string }>> = props => {
    const { field, form, label } = props;
    const { name, value }: { name: string; value: LabsGqlPendingAlignerCaseInterproximalSpaceSizeInput[] } = field;

    return (
        <Grid container>
            <Text variant={'h6'}>{label}</Text>
            <Divider />
            <DandyMouthInterproximalSpaceSelector
                value={value as InterproximalSpaceSize[]}
                onChange={value => form.setFieldValue(name, value)}
            />
        </Grid>
    );
};
