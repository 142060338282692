import type { AdminLabsOrderActionProps } from '../order-action-types';
import { DesignConfigDropdown } from './DesignConfigDropdown';
import { useConfigureWaxupMutation } from '@orthly/graphql-react';
import { LoadBlocker, useChangeSubmissionFn, RootActionDialog } from '@orthly/ui';
import {
    styled,
    Text,
    RadioGroupPrimitive as RadioGroup,
    Radio,
    FormControlLabel,
    Button,
    Box,
} from '@orthly/ui-primitives';
import { useFeatureFlag, useIsDesignActionEditable } from '@orthly/veneer';
import React from 'react';

const ActionRowDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '24px',
});

const RightButton = styled(Button)({
    marginLeft: 'auto',
});

const DialogOption: React.FC<{
    caption: string;
    label: string;
    value: boolean;
    onChange: (value: boolean) => void;
}> = ({ caption, label, onChange, value }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <FormControlLabel
                value={value}
                onChange={() => onChange(value)}
                control={<Radio sx={{ margin: 0 }} />}
                label={
                    <Text variant={'body2'} color={'BLACK'}>
                        {label}
                    </Text>
                }
            />
            <Text variant={'caption'} color={'GRAY'}>
                {caption}
            </Text>
        </Box>
    );
};

const DialogContent: React.FC<Pick<DialogProps, 'onSubmit' | 'setOpen'>> = ({ onSubmit, setOpen }) => {
    const [doctorRequested, setDoctorRequested] = React.useState(false);

    return (
        <>
            <RadioGroup value={doctorRequested} sx={{ gap: '24px', marginTop: '24px' }}>
                <DialogOption
                    caption={'Internal recommendation or requesting feedback from doctor'}
                    onChange={setDoctorRequested}
                    label={'Dandy request'}
                    value={false}
                />
                <DialogOption
                    caption={'Doctor has requested to add DDP to order'}
                    onChange={setDoctorRequested}
                    label={'Doctor request'}
                    value={true}
                />
            </RadioGroup>
            <ActionRowDiv>
                <Button variant={'secondary'} onClick={setOpen.bind(null, false)}>
                    Go Back
                </Button>
                <RightButton variant={'primary'} onClick={() => onSubmit(doctorRequested)}>
                    Save & Add DDP
                </RightButton>
            </ActionRowDiv>
        </>
    );
};

type DialogProps = {
    onSubmit: (doctorRequested: boolean) => void;
    setOpen: (open: boolean) => void;
    open: boolean;
};
const ConfirmDialog: React.FC<DialogProps> = props => {
    const { open, setOpen } = props;

    return (
        <RootActionDialog
            title={'Who requested this design preview?'}
            setOpen={setOpen}
            content={<DialogContent {...props} />}
            open={open}
            dialogProps={{ maxWidth: 'sm' }}
            onClose={setOpen.bind(null, false)}
            showCloseButton
            loading={false}
            hideButton
        />
    );
};

export const EditOrderWaxupRequirement: React.FC<AdminLabsOrderActionProps> = props => {
    const { order, refetchOrder } = props;
    const { fulfillment_workflow } = order;
    const { waxup_required } = fulfillment_workflow.configuration;
    const { value: collectDoctorRequestedDDP } = useFeatureFlag('collectDoctorRequestedDDP');
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const [submitMtn] = useConfigureWaxupMutation();
    const { submit, submitting } = useChangeSubmissionFn<any, [boolean | null]>(
        (doctorRequested: boolean | null) =>
            submitMtn({
                variables: {
                    order_id: order.id,
                    waxup_required: !waxup_required,
                    doctor_requested: doctorRequested,
                },
            }),
        {
            successMessage: () => [`Design Preview ${waxup_required ? 'removed' : 'added'}`, {}],
            onSuccess: async () => {
                await refetchOrder();
            },
        },
    );
    const currentValue = { label: waxup_required ? 'Yes' : 'No', value: waxup_required };
    const isEditable = useIsDesignActionEditable(order, { allowAfterLabAccept: true });

    async function onChange(newValue: boolean): Promise<boolean> {
        if (newValue && !currentValue.value && collectDoctorRequestedDDP) {
            setShowConfirmation(true);
            return true;
        }

        if (
            newValue !== currentValue.value &&
            window.confirm(`${waxup_required ? 'Remove design preview from' : 'Add design preview to'} order?`)
        ) {
            await submit(null);
            return true;
        }

        return false;
    }

    function submitConfirmDialog(doctorRequested: boolean) {
        void submit(doctorRequested);
        setShowConfirmation(false);
    }

    return (
        <div>
            <LoadBlocker blocking={submitting}>
                <DesignConfigDropdown<boolean>
                    options={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                    ]}
                    selected={currentValue}
                    submit={onChange}
                    disabled={!isEditable}
                />
            </LoadBlocker>
            <ConfirmDialog open={showConfirmation} setOpen={setShowConfirmation} onSubmit={submitConfirmDialog} />
        </div>
    );
};
