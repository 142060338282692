import { ImplantSystemInput } from '@orthly/dentin';
import type { LabsGqlImplantTypeLiteFragment } from '@orthly/graphql-operations';
import { useGetImplantTypesLiteQuery } from '@orthly/graphql-react';
import React from 'react';

interface ImplantSelectorProps {
    setSelectedImplant: (implant: LabsGqlImplantTypeLiteFragment | null) => void;
}

/**
 * Component used to select an implant with dropdowns for the manufacturer, system and connection.
 *
 * The setSelectedImplant callback will be called with the implant from the db if one exists for
 * the values selected in the dropdowns.
 */
export const ImplantSelector: React.VFC<ImplantSelectorProps> = ({ setSelectedImplant }) => {
    const { data, error } = useGetImplantTypesLiteQuery({
        variables: { withDeleted: false },
    });

    const [dataImplant, setDataImplant] = React.useState({ system: null, manufacturer: null, connection: null });

    const handleUpdateField = React.useCallback(
        (fieldName: string, fieldValue: string | null) => {
            setDataImplant(prevValue => ({
                ...prevValue,
                [fieldName]: fieldValue,
            }));

            // If a field has changed, we reset the previously selected implant, then in the next effect
            // we'll look for a match for the new values that were selected.
            setSelectedImplant(null);
        },
        [setSelectedImplant],
    );

    React.useEffect(() => {
        if (dataImplant.connection && dataImplant.manufacturer && dataImplant.system) {
            const existingImplant = data?.getImplantTypesLite.find(
                implant =>
                    implant.connection === dataImplant.connection &&
                    implant.manufacturer === dataImplant.manufacturer &&
                    implant.system === dataImplant.system,
            );

            existingImplant && setSelectedImplant(existingImplant);
        }
    }, [dataImplant, data, setSelectedImplant]);

    return (
        <ImplantSystemInput
            updateField={handleUpdateField}
            linkMeta={dataImplant}
            implantSystems={data?.getImplantTypesLite ?? []}
            loadingError={!!error}
        />
    );
};
