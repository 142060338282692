import { useSculptingState } from '../AppState.hooks';
import { SliderRow } from './SliderRow';
import { Button, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface SculptBrushButtonProps {
    label: string;
    active: boolean;
    onClick: () => void;
}

const SculptBrushButton: React.VFC<SculptBrushButtonProps> = ({ label, active, onClick: _onClick }) => {
    const onClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        _onClick();

        // If the target retains focus, our hotkeys will be inoperable until the user clicks away because the input
        // element will be capturing all key events. We need to do this in the `onClick` handler rather than the
        // `onChange` handler because the element is given focus on a click even if the value does not change (i.e.
        // clicking the currently selected radio button).
        (evt.target as any)?.blur?.();
    };

    return (
        <Button variant={active ? 'primary' : 'secondary'} onClick={onClick} fullWidth>
            {label}
        </Button>
    );
};

interface SculptingMenuProps {
    showSculptToOpposing?: boolean;
    showSculptToAdjacent?: boolean;
    showSculptToCurtains?: boolean;
}

export const SculptingMenu: React.VFC<SculptingMenuProps> = ({
    showSculptToOpposing,
    showSculptToAdjacent,
    showSculptToCurtains,
}) => {
    const {
        sculptingEnabled,
        isAddBrushEnabled,
        enableAddBrush,
        isSubtractBrushEnabled,
        enableSubtractBrush,
        isSmoothBrushEnabled,
        enableSmoothBrush,
        isOpposingBrushEnabled,
        enableOpposingBrush,
        isProximalBrushEnabled,
        enableProximalBrush,
        isCurtainsBrushEnabled,
        enableCurtainsBrush,
        brushSettings,
        setBrushRadius,
        setBrushIntensity,
    } = useSculptingState();

    const { radius, intensity } = brushSettings;
    const { radiusMin, radiusMax, radiusStep, intensityMin, intensityMax, intensityStep } = brushSettings.ranges;

    return (
        <Grid item container direction={'column'} wrap={'nowrap'}>
            {sculptingEnabled && (
                <Grid item container spacing={1} style={{ padding: '0px 12px' }}>
                    <Grid item color={'gray'}>
                        Sculpting Brushes
                    </Grid>
                    <Grid item xs={6}>
                        <SculptBrushButton label={'Add'} active={isAddBrushEnabled} onClick={enableAddBrush} />
                    </Grid>
                    <Grid item xs={6}>
                        <SculptBrushButton
                            label={'Subtract'}
                            active={isSubtractBrushEnabled}
                            onClick={enableSubtractBrush}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SculptBrushButton label={'Smooth'} active={isSmoothBrushEnabled} onClick={enableSmoothBrush} />
                    </Grid>
                    {showSculptToOpposing && (
                        <Grid item xs={6}>
                            <SculptBrushButton
                                label={'Opposing'}
                                active={isOpposingBrushEnabled}
                                onClick={enableOpposingBrush}
                            />
                        </Grid>
                    )}
                    {showSculptToAdjacent && (
                        <Grid item xs={6}>
                            <SculptBrushButton
                                label={'Adjacent'}
                                active={isProximalBrushEnabled}
                                onClick={enableProximalBrush}
                            />
                        </Grid>
                    )}
                    {showSculptToCurtains && (
                        <Grid item xs={6}>
                            <SculptBrushButton
                                label={'Curtains'}
                                active={isCurtainsBrushEnabled}
                                onClick={enableCurtainsBrush}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <SliderRow
                            label={'Radius'}
                            value={radius}
                            onChange={setBrushRadius}
                            min={radiusMin}
                            max={radiusMax}
                            step={radiusStep}
                        />
                    </Grid>
                    {!isOpposingBrushEnabled && !isProximalBrushEnabled && !isCurtainsBrushEnabled && (
                        <Grid item xs={12}>
                            <SliderRow
                                label={'Strength'}
                                value={intensity}
                                onChange={setBrushIntensity}
                                min={intensityMin}
                                max={intensityMax}
                                step={intensityStep}
                            />
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    );
};
