import { SimpleExpansionPanel } from './SimpleExpansionPanel';
import { FlossPalette, Grid } from '@orthly/ui-primitives';
import React from 'react';
import ReactJson from 'react-json-view';

interface JsonDevToolsProps {
    src: object;
    dataType: string;
    title?: React.ReactNode | string;
    containerStyle?: React.CSSProperties;
    defaultExpanded?: boolean;
    expansionPanelStyle?: React.CSSProperties;
    expansionPanelDetailsStyle?: React.CSSProperties;
    expansionPanelSummaryStyle?: React.CSSProperties;
}

// Simple component for displaying relevant json data
export const JsonDevTools: React.FC<JsonDevToolsProps> = props => {
    const {
        src,
        dataType,
        title,
        containerStyle,
        expansionPanelStyle,
        expansionPanelDetailsStyle,
        expansionPanelSummaryStyle,
    } = props;
    return (
        <Grid container style={{ padding: '10px 0', ...containerStyle }}>
            <SimpleExpansionPanel
                title={title ?? 'Dev Tools'}
                ExpansionPanelProps={{
                    variant: 'outlined',
                    defaultExpanded: props.defaultExpanded,
                    style: { height: 'fit-content', ...expansionPanelStyle },
                }}
                ExpansionPanelSummaryProps={{ style: expansionPanelSummaryStyle }}
                ExpansionPanelDetailsProps={{
                    style: { background: FlossPalette.BLACK, ...expansionPanelDetailsStyle },
                }}
            >
                <ReactJson
                    sortKeys
                    theme={'google'}
                    name={dataType}
                    collapsed={1}
                    src={src}
                    enableClipboard={true}
                    displayDataTypes={false}
                    style={{ fontSize: 14, backgroundColor: FlossPalette.BLACK, overflow: 'scroll' }}
                />
            </SimpleExpansionPanel>
        </Grid>
    );
};
