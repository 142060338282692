import type { SubmitterParams } from '../../utils';
import { handleSuccess } from '../../utils';
import {
    useCreateBiasRuleMutation,
    useUpdateBiasRuleMutation,
    useDisableBiasRuleMutation,
    useEnableBiasRuleMutation,
} from '@orthly/graphql-react';
import type {
    LabsGqlCreateBiasRuleCommand,
    LabsGqlDisableBiasRuleCommand,
    LabsGqlUpdateBiasRuleCommand,
    LabsGqlEnableBiasRuleCommand,
} from '@orthly/graphql-schema';
import { useChangeSubmissionFn } from '@orthly/ui';

export function useCreateBiasRuleSubmitter(params: SubmitterParams) {
    const [submitMtn] = useCreateBiasRuleMutation();
    const mtnSubmitter = (data: LabsGqlCreateBiasRuleCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        successMessage: () => ['Bias Rule created!', {}],
        onSuccess: () => handleSuccess(params),
    });
    return { submit, submitting };
}

export function useUpdateBiasRuleSubmitter(params: SubmitterParams) {
    const [submitMtn] = useUpdateBiasRuleMutation();
    const mtnSubmitter = (data: LabsGqlUpdateBiasRuleCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        successMessage: () => ['Bias Rule updated!', {}],
        onSuccess: () => handleSuccess(params),
    });
    return { submit, submitting };
}

export function useDisableBiasRuleSubmitter(params: SubmitterParams) {
    const [submitMtn] = useDisableBiasRuleMutation();
    const mtnSubmitter = (data: LabsGqlDisableBiasRuleCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        successMessage: () => ['Bias Rule disabled!', {}],
        onSuccess: () => handleSuccess(params),
    });
    return { submit, submitting };
}

export function useEnableBiasRuleSubmitter(params: SubmitterParams) {
    const [submitMtn] = useEnableBiasRuleMutation();
    const mtnSubmitter = (data: LabsGqlEnableBiasRuleCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        successMessage: () => ['Bias Rule enabled!', {}],
        onSuccess: () => handleSuccess(params),
    });
    return { submit, submitting };
}
