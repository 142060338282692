import { stylesFactory, FlossPalette, Divider, Grid, Tooltip, InfoIcon, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    sectionRoot: {
        padding: '8px 16px',
        width: '100%',
    },
    rootAlert: {
        padding: '20px 16px',
        backgroundColor: FlossPalette.ATTENTION_BG,
    },
}));

export interface DesignSidebarSectionProps {
    title?: string;
    subtitle?: string;
    tooltipText?: string;
    style?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    subtitleStyle?: React.CSSProperties;
    hideDivider?: boolean;
}

export const DesignSidebarSection: React.FC<DesignSidebarSectionProps> = props => {
    const classes = useStyles();
    return (
        <Grid style={{ width: '100%' }}>
            <Grid style={props.style} className={classes.sectionRoot}>
                {props.title && (
                    <Text variant={'body2'} color={'DARK_GRAY'} style={{ ...props.titleStyle, fontWeight: 500 }}>
                        {props.title}
                        {props.tooltipText && (
                            <Tooltip title={props.tooltipText}>
                                <InfoIcon style={{ fontSize: 16, marginLeft: 4 }} />
                            </Tooltip>
                        )}
                    </Text>
                )}
                {props.subtitle && (
                    <Text variant={'caption'} color={'DARK_GRAY'} style={{ ...props.subtitleStyle, fontWeight: 400 }}>
                        {props.subtitle}
                    </Text>
                )}
                {props.children}
            </Grid>
            {props.hideDivider !== false && <Divider style={{ width: '100%' }} />}
        </Grid>
    );
};
