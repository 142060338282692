import { CreateExternalOrgAction } from './actions/CreateExternalOrgAction';
import type { LabsGqlOrganizationDtoFragment, LabsGqlListOrgsQuery } from '@orthly/graphql-operations';
import { useListOrgsQuery } from '@orthly/graphql-react';
import { LabsGqlOrganizationType } from '@orthly/graphql-schema';
import MUITable from '@orthly/mui-table';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

type ExternalOrgsTableRow = LabsGqlOrganizationDtoFragment;

const defaultQuery: LabsGqlListOrgsQuery = { listOrganizations: [], __typename: 'Query' };

export const ExternalOrgsTable: React.VFC = () => {
    const {
        data: externalOrgData = defaultQuery,
        refetch,
        loading,
    } = useListOrgsQuery({
        variables: { filter: { type: LabsGqlOrganizationType.External } },
        fetchPolicy: 'cache-first',
    });
    const CustomRightComponent = React.useCallback(
        () => (
            <Grid container justifyContent={'flex-end'} style={{ paddingTop: 10 }}>
                <CreateExternalOrgAction onComplete={refetch} />
            </Grid>
        ),
        [refetch],
    );
    return (
        <Grid container style={{ width: '100%' }}>
            <MUITable<ExternalOrgsTableRow>
                loading={loading}
                title={'External Organizations'}
                data={externalOrgData.listOrganizations}
                rowOptions={{ rowHover: true }}
                displayOptions={{ fixedSearch: true, fixedHeader: true, responsive: 'scroll' }}
                columns={[
                    { name: 'id', hidden: true, render: 'id' },
                    { name: 'Organization', render: 'name', bodyCellWrapStyle: { fontWeight: 'bold' } },
                    {
                        name: 'Primary Contact',
                        render: row =>
                            `${row.primary_contact?.first_name ?? 'Not'} ${row.primary_contact?.last_name ?? 'Set'}`,
                    },
                    { name: 'Email', render: r => r.primary_contact?.email },
                    { name: 'Phone', render: r => r.primary_contact?.phone_number },
                ]}
                actions={{
                    global: [{ position: 'toolbar', onClick: () => refetch(), icon: 'refresh', tooltip: 'Refresh' }],
                }}
                toolbarOptions={{ CustomRight: CustomRightComponent }}
            />
        </Grid>
    );
};
