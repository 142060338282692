import { ItemEditorV2SectionWrapper } from '../../components/ItemEditorV2SectionWrapper';
import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { ItemEditorV2ItemDataField } from './ItemEditorV2ItemDataField';
import { ItemDataFieldUtils } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

export const ItemEditorV2DataFieldsSection: React.VFC<ItemEditorV2BaseProps> = ({ item, updateItem, showError }) => {
    const dataFields = ItemDataFieldUtils.getItemDataFields(item);

    if (dataFields.length === 0) {
        return null;
    }

    return (
        <ItemEditorV2SectionWrapper title={'Item Details'}>
            <Grid container spacing={2}>
                {dataFields.map(field => (
                    <Grid item xs={12} md={6} key={field.id}>
                        <ItemEditorV2ItemDataField
                            field={field}
                            item={item}
                            updateItem={updateItem}
                            showError={showError}
                        />
                    </Grid>
                ))}
            </Grid>
        </ItemEditorV2SectionWrapper>
    );
};
