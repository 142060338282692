import { PalateRecentReviews } from './PalateRecentReviews';
import { CompleteOrdersView } from './views/CompleteOrders';
import { InFlightOrdersView } from './views/InFlightOrders';
import { IncompleteOrdersView } from './views/IncompleteOrders';
import { Divider, FormControl, InputLabel, MenuItem, Select, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface PalatePracticeOrdersProps {
    practiceId: string;
    practiceUrl: string;
}

enum Views {
    InFlight = 'In-Flight Orders',
    Incomplete = 'Incomplete Orders',
    Complete = 'Complete Orders',
}

const PracticeOrdersViewDisplay: React.FC<{
    selection: Views;
    practiceId: string;
    practiceUrl: string;
}> = props => {
    const { selection, practiceId, practiceUrl } = props;
    switch (selection) {
        case Views.InFlight:
            return <InFlightOrdersView practiceId={practiceId} />;
        case Views.Incomplete:
            return <IncompleteOrdersView practiceId={practiceId} practiceUrl={practiceUrl} />;
        case Views.Complete:
            return <CompleteOrdersView practiceId={practiceId} />;
        default:
            return null;
    }
};

export const PalatePracticeOrdersViews: React.FC<PalatePracticeOrdersProps> = props => {
    const { practiceId, practiceUrl } = props;
    const [selectedView, setSelectedView] = React.useState(Views.InFlight);

    return (
        <Grid container direction={'column'} wrap={'nowrap'} style={{ height: '100%' }}>
            <PalateRecentReviews practiceId={practiceId} />
            <Divider style={{ marginTop: '16px' }} />
            <FormControl size={'small'} style={{ margin: '16px 32px' }} variant={'standard'}>
                <InputLabel>View</InputLabel>
                <Select
                    onChange={event => setSelectedView(event.target.value as Views)}
                    value={selectedView}
                    variant={'standard'}
                >
                    {Object.values(Views).map(view => (
                        <MenuItem key={view} value={view}>
                            {view}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Grid item style={{ minHeight: 0 }}>
                <PracticeOrdersViewDisplay selection={selectedView} practiceId={practiceId} practiceUrl={practiceUrl} />
            </Grid>
        </Grid>
    );
};
