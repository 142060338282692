import { TimelineDate, TimelineEventDivider } from '../Timeline/TimelineEvent';
import { ChangeAttachmentVisibilityDialogButton } from './ChangeAttachmentVisibilityDialogButton';
import { useChatStickyChild } from './ChatSticky.provider';
import type { MixedTimelineData, DandyChatMessage } from './DandyChat.types';
import { DandyChatAttachment } from './DandyChatAttachment';
import { DandyChatAvatar } from './DandyChatAvatar';
import type { LabsGqlTimelineAttachment, LabsGqlStaffRoleWithAny } from '@orthly/graphql-schema';
import { IOrganizationType } from '@orthly/retainer-common';
import { ReadIcon, SmallTrashCanIcon } from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import { FlossPalette, createStyles, makeStyles, Tooltip, Grid, IconButton, Typography } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

type StylesProps = {
    isDeleted?: boolean;
    senderRole?: IOrganizationType;
    internalChat?: boolean;
    isDeletable?: boolean;
    fromCurrentUser?: boolean;
    newTimelineItem?: boolean;
};

const backgroundColorForChatBubble = (
    fromCurrentUser: boolean,
    internalChat: boolean,
    senderRole: IOrganizationType | undefined,
) => {
    if (internalChat) {
        if (senderRole === IOrganizationType.internal) {
            return FlossPalette.LIGHT_YELLOW;
        }

        if (senderRole === IOrganizationType.external && !fromCurrentUser) {
            return FlossPalette.SECONDARY_BACKGROUND;
        }
    }

    return fromCurrentUser ? FlossPalette.WHITE : FlossPalette.DARK_TAN;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles<string, StylesProps>({
        topLevelGrid: ({ newTimelineItem }) => ({
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: newTimelineItem ? 8 : 0,
            paddingBottom: 8,
            color: FlossPalette.BLACK,
        }),
        chatBubble: ({ fromCurrentUser, internalChat, senderRole, isDeletable }) => ({
            padding: '8px 16px',
            borderRadius: 24,
            marginLeft: isDeletable ? 12 : 32,
            backgroundColor: backgroundColorForChatBubble(!!fromCurrentUser, !!internalChat, senderRole),
            borderTopLeftRadius: fromCurrentUser ? undefined : 3,
            borderTopRightRadius: !fromCurrentUser ? undefined : 3,
            position: 'relative',
        }),
        deleteIcon: {
            padding: 0,
            marginLeft: 4,
            '&:hover': {
                color: FlossPalette.STAR_GRASS,
            },
            color: FlossPalette.GRAY,
        },
        chatPre: {
            whiteSpace: 'pre-wrap',
            margin: 0,
            fontFamily: theme.typography.body1.fontFamily,
            overflowWrap: 'anywhere',
        },
        chatText: ({ isDeleted }) => ({
            textDecoration: isDeleted ? 'line-through' : undefined,
        }),
        timelineDateText: {
            textTransform: 'none',
            padding: '0 5px',
            color: FlossPalette.GRAY,
            fontWeight: 'bold',
            fontFamily: 'Inter Regular, Verdana, sans-serif',
            fontSize: 12,
            letterSpacing: 1.5,
        },
        personIconNotCurrent: {
            color: FlossPalette.WHITE,
            background: FlossPalette.BLACK,
        },
    }),
);

interface DandyChatMessageRowProps {
    fromCurrentUser: boolean;
    text: string;
    date: Date;
    senderRole: IOrganizationType;
    attachments?: LabsGqlTimelineAttachment[];
    internalChat?: boolean;
    onDeleteChat?: () => Promise<void>;
    onToggleQualityControl?: () => Promise<void>;
    deleted_at?: Date | null;
    newTimelineItem?: boolean;
    onChangeAttachmentVisibility?: (visible_to_roles: LabsGqlStaffRoleWithAny[]) => Promise<void>;
    attachmentVisibility?: string[];
    readByAllRecipients?: boolean;
    setSelectedAttachmentPreview?: (attachment: string | undefined) => void;
    practice_id: string;
    lab_id: string;
}

export const DandyChatMessageRow: React.VFC<DandyChatMessageRowProps> = props => {
    const {
        text,
        attachments,
        fromCurrentUser,
        deleted_at,
        onDeleteChat,
        internalChat,
        newTimelineItem,
        onChangeAttachmentVisibility,
        attachmentVisibility,
        readByAllRecipients,
        setSelectedAttachmentPreview,
        senderRole,
        date,
        practice_id,
        lab_id,
    } = props;
    const classes = useStyles({
        senderRole,
        fromCurrentUser,
        internalChat,
        newTimelineItem,
        isDeleted: !!deleted_at,
        isDeletable: !!onDeleteChat,
    });
    const chatColor = props.deleted_at ? 'textSecondary' : 'inherit';
    const ref = React.useRef<HTMLDivElement | null>(null);
    useChatStickyChild(ref, date);
    return (
        <Grid container className={classes.topLevelGrid} justifyContent={'flex-start'} wrap={'nowrap'} ref={ref}>
            {onDeleteChat && (
                <Tooltip title={`Delete message`}>
                    <IconButton
                        className={classes.deleteIcon}
                        onClick={() => {
                            if (onDeleteChat && window.confirm('Are you sure you want to delete the message?')) {
                                void onDeleteChat();
                            }
                        }}
                    >
                        <SmallTrashCanIcon style={{ fontSize: `0.75em` }} color={`inherit`} />
                    </IconButton>
                </Tooltip>
            )}
            <Grid item container className={classes.chatBubble}>
                <Grid item style={{ flexGrow: 1, flexBasis: 0 }}>
                    <Typography className={classes.chatText} component={'div'} variant={'body1'} color={chatColor}>
                        <pre className={classes.chatPre}>{text}</pre>
                    </Typography>
                    {!!attachments &&
                        attachments.map(({ url, description }) => (
                            <DandyChatAttachment
                                setSelectedAttachmentPreview={setSelectedAttachmentPreview}
                                attachment_url={url}
                                description={description}
                                fromCurrentUser={fromCurrentUser}
                                key={url}
                            />
                        ))}
                    {!!attachments && !!onChangeAttachmentVisibility && (
                        <ChangeAttachmentVisibilityDialogButton
                            onChangeAttachmentVisibility={onChangeAttachmentVisibility}
                            attachmentVisibility={attachmentVisibility}
                            senderRole={senderRole}
                            practice_id={practice_id}
                            lab_id={lab_id}
                        />
                    )}
                </Grid>
                {readByAllRecipients !== undefined && (
                    <ReadIcon
                        style={{
                            fontSize: 16,
                            margin: 4,
                            color: readByAllRecipients ? FlossPalette.BURGUNDY : FlossPalette.DARK_TAN,
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

interface DandyChatMessageHeaderProps {
    username: string;
    date: Date;
    recipient_tag?: string;
    senderRole?: IOrganizationType;
}

const DandyChatMessageHeader: React.FC<DandyChatMessageHeaderProps> = props => {
    const { username, recipient_tag, date, senderRole } = props;
    const ref = React.useRef<HTMLDivElement | null>(null);
    useChatStickyChild(ref, date);
    const displayDate = moment(date).format('h:mmA');
    const tooltipDate = moment(date).format('dddd, MMM D, YYYY h:mmA');

    return (
        <Grid container ref={ref}>
            <Grid container justifyContent={'flex-start'} alignItems={'center'} direction={'row'}>
                <Grid item container xs={8} justifyContent={'flex-start'} direction={'row'}>
                    <DandyChatAvatar senderRole={senderRole} username={username} />
                    <Typography
                        color={'inherit'}
                        style={{
                            fontSize: 14,
                            margin: '0 8px',
                            fontWeight: 500,
                            color: FlossPalette.BLACK,
                        }}
                    >
                        {username}
                    </Typography>
                    <Typography
                        color={'inherit'}
                        style={{
                            fontSize: 14,
                            fontWeight: 500,
                            color: FlossPalette.GRAY,
                        }}
                    >
                        {recipient_tag}
                    </Typography>
                </Grid>
                <Grid item container xs={4} justifyContent={'flex-end'}>
                    <Tooltip title={tooltipDate} placement={'left'}>
                        <Typography variant={'caption'} color={'textSecondary'} style={{ whiteSpace: 'nowrap' }}>
                            {displayDate}
                        </Typography>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
};

interface DandyChatMessageItemProps {
    previousItem?: MixedTimelineData;
    item: DandyChatMessage;
    currentStaffId: string;
    setSelectedAttachmentPreview?: (attachment: string | undefined) => void;
    practice_id: string;
    lab_id: string;
}

export const DandyChatMessageItem: React.FC<DandyChatMessageItemProps> = props => {
    const { item: currentChat, previousItem, currentStaffId, practice_id, lab_id } = props;
    const previousMessage = previousItem?.type === 'message' ? previousItem.data : null;
    const { senderRole, username, recipient_tag } = currentChat;
    const fromCurrentUser = currentChat.staffId === currentStaffId;
    const currentMessageDate = moment(currentChat.date).format('MM/DD/YY');
    const previousMessageDate = previousItem ? moment(previousItem.date).format('MM/DD/YY') : undefined;
    const previousSenderRepeat = previousMessage && previousMessage.staffId === currentChat.staffId;
    const previousVisibilityRepeat =
        previousMessage &&
        previousMessage.attachmentVisibility?.sort().join(',') === currentChat.attachmentVisibility?.sort().join(',');
    return (
        <React.Fragment>
            {previousItem && (!previousSenderRepeat || !previousVisibilityRepeat) && <TimelineEventDivider />}
            {(!previousMessageDate || currentMessageDate !== previousMessageDate) && (
                <TimelineDate date={currentChat.date} />
            )}
            {(!previousMessage || !previousSenderRepeat || !previousVisibilityRepeat) && (
                <DandyChatMessageHeader
                    senderRole={senderRole}
                    username={username}
                    recipient_tag={recipient_tag}
                    date={currentChat.date}
                />
            )}
            <DandyChatMessageRow
                date={currentChat.date}
                text={currentChat.text}
                senderRole={currentChat.senderRole}
                attachments={currentChat.attachments}
                fromCurrentUser={fromCurrentUser}
                onDeleteChat={currentChat.onDeleteChat}
                deleted_at={currentChat.deleted_at}
                internalChat={currentChat.isInternal}
                newTimelineItem={!previousVisibilityRepeat || !previousSenderRepeat}
                onChangeAttachmentVisibility={currentChat.onChangeAttachmentVisibility}
                onToggleQualityControl={currentChat.onToggleQualityControl}
                attachmentVisibility={currentChat.attachmentVisibility}
                readByAllRecipients={currentChat.readByAllRecipients}
                setSelectedAttachmentPreview={props.setSelectedAttachmentPreview}
                practice_id={practice_id}
                lab_id={lab_id}
            />
        </React.Fragment>
    );
};
