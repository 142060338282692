import { OrderListItemCellText } from './OrderListItemCellText';
import { OrderListItemStatusTracker } from './OrderListItemStatusTracker';
import type { DesignTaskListItemTrackerProps } from '@orthly/dentin';
import { DesignTaskListItemTracker } from '@orthly/dentin';
import type { OrderOpsListItemContentProps } from '@orthly/veneer';
import React from 'react';

export const OrderListItemPartnerCell: React.VFC<{ partner_name?: string; doctor_name: string }> = props => {
    const { doctor_name, partner_name } = props;
    return (
        <>
            <OrderListItemCellText variant={'title'} text={doctor_name} />
            {partner_name && <OrderListItemCellText variant={'subtitle'} text={partner_name} />}
        </>
    );
};

export const OrderListItemItemsLabCell: React.VFC<{ manufacturer_name?: string; products: string }> = props => {
    const { manufacturer_name, products } = props;
    return (
        <>
            <OrderListItemCellText variant={'title'} text={products} tooltipText={products.split(', ').join('\n')} />
            {manufacturer_name && <OrderListItemCellText variant={'subtitle'} text={manufacturer_name} />}
        </>
    );
};

export type OrderListItemTrackerCellProps = Pick<OrderOpsListItemContentProps, 'trackerProps' | 'order'> & {
    actions?: React.ReactNode;
};

export const OrderListItemTrackerCell: React.VFC<OrderListItemTrackerCellProps> = props => {
    const { trackerProps, actions } = props;
    return (
        <>
            <OrderListItemStatusTracker {...trackerProps} />
            {actions}
        </>
    );
};

interface DesignTaskListItemTrackerCellProps extends DesignTaskListItemTrackerProps {
    actions: React.ReactNode;
}

export const DesignTaskListItemTrackerCell: React.VFC<DesignTaskListItemTrackerCellProps> = ({
    actions,
    ...trackerProps
}) => {
    return (
        <>
            <DesignTaskListItemTracker {...trackerProps} containerStyle={{ paddingTop: '4px' }} />
            {actions}
        </>
    );
};
