import {
    GUIDED_WAXUP_COMMON_STYLES,
    GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT,
    GUIDED_WAXUP_TOOLBAR_HEIGHT_DESKTOP,
    TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_COLLAPSED,
    TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_EXPANDED,
} from '../VisualConstants.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { DoctorImages } from './DoctorImages';
import { ChevronLeft, ChevronRight, SidebarContainer } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, Tab, Tabs, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

const SIDEBAR_WIDTH = 300;

const useStyles = stylesFactory<{ isSidebarExpanded?: boolean }>(() => ({
    root: {
        padding: 0,
        flexWrap: 'nowrap',
        height: '100%',
        borderLeft: 'none',
        width: 'auto',
    },
    toolbar: {
        padding: '0 8px',
        marginBottom: 10,
        display: ({ isSidebarExpanded }) => (isSidebarExpanded ? 'inherit' : 'none'),
    },
    expandContainer: {
        position: 'fixed',
        top: GUIDED_WAXUP_TOOLBAR_HEIGHT_DESKTOP + 15,
        right: ({ isSidebarExpanded }) => (isSidebarExpanded ? SIDEBAR_WIDTH - 15 : 25),
        width: 24,
        height: 40,
        borderRadius: 12,
        backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
        marginLeft: -15,
        cursor: 'pointer',
    },
    expandIcon: {
        color: FlossPalette.WHITE,
        position: 'absolute',
        top: 7,
        left: 0,
    },
    timelineAndImagesSidebarContainer: {
        position: 'fixed',
        width: ({ isSidebarExpanded }) =>
            isSidebarExpanded
                ? TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_EXPANDED
                : TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_COLLAPSED,
        top: GUIDED_WAXUP_TOOLBAR_HEIGHT_DESKTOP,
        right: 0,
        height: GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT,
        ...GUIDED_WAXUP_COMMON_STYLES,
    },
    bodyContainer: {
        display: ({ isSidebarExpanded }) => (isSidebarExpanded ? 'inherit' : 'none'),
        overflow: 'auto',
    },
}));

interface SidebarTab {
    name: string;
    body: React.ReactNode;
}

export const GuidedWaxupTimelineAndImagesSidebar: React.VFC = () => {
    const { order, isSidebarExpanded, setIsSidebarExpanded, OrderChatWrapper } = useGuidedWaxupContext();
    const classes = useStyles({ isSidebarExpanded });

    const timelineTab: SidebarTab = {
        name: 'Timeline',
        body: OrderChatWrapper && <OrderChatWrapper order={order} hideFooter={true} />,
    };
    const imagesTab: SidebarTab = { name: 'Images', body: <DoctorImages /> };

    // If OrderChatWrapper isn't provided, then there isn't data to render for the timeline, and we exclude
    // it as an option
    const tabs: SidebarTab[] = OrderChatWrapper ? [timelineTab, imagesTab] : [imagesTab];

    const [activeTabIndex, setActiveTabIndex] = React.useState<number>(0);

    return (
        <Grid item className={classes.timelineAndImagesSidebarContainer}>
            <SidebarContainer position={'right'} className={classes.root}>
                <Tooltip title={`${isSidebarExpanded ? 'Hide' : 'Expand'} Timeline & Photos`} placement={'left'}>
                    <div
                        className={classes.expandContainer}
                        onClick={() => setIsSidebarExpanded(isSidebarExpanded => !isSidebarExpanded)}
                    >
                        {isSidebarExpanded ? (
                            <ChevronRight className={classes.expandIcon} />
                        ) : (
                            <ChevronLeft className={classes.expandIcon} />
                        )}
                    </div>
                </Tooltip>
                <Tabs
                    indicatorColor={'secondary'}
                    textColor={'inherit'}
                    value={activeTabIndex}
                    variant={'fullWidth'}
                    centered
                    className={classes.toolbar}
                >
                    {tabs.map((tabPiece, i) => (
                        <Tab
                            key={tabPiece?.name}
                            value={i}
                            onClick={() => setActiveTabIndex(i)}
                            label={tabPiece?.name}
                        />
                    ))}
                </Tabs>
                <Grid container className={classes.bodyContainer}>
                    {tabs[activeTabIndex]?.body}
                </Grid>
            </SidebarContainer>
        </Grid>
    );
};
