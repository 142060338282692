import type { FileUploadAnalyticsMetadata } from '../FirebaseUpload';
import { SimpleDropzone, useOrderFileUpload } from '../FirebaseUpload';
import { FileList } from '../PhotoUpload';
import { PracticeFullScreenDialog } from '@orthly/dentin';
import { useOrder } from '@orthly/graphql-react';
import { NavigationLogoDandy } from '@orthly/ui';
import { FlossPalette, Text, stylesFactory, Grid, ChevronLeft, Button } from '@orthly/ui-primitives';
import React from 'react';
import { useParams } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    root: {
        padding: '32px 16px 0 16px',
        height: '85vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: FlossPalette.WHITE,
        position: 'absolute',
        zIndex: 2,
    },
    logo: {
        width: 128,
    },
}));

export interface MobileRefabFlowFilesUploaderParams {
    orderId: string;
    itemId?: string;
}

const MobileRefabFlowFilesUploader: React.VFC<MobileRefabFlowFilesUploaderParams> = props => {
    const classes = useStyles();
    const { orderId, itemId } = props;
    const [isFinished, setIsFinished] = React.useState(false);
    const analyticsMetadata: FileUploadAnalyticsMetadata = {
        productName: 'order refab files',
        device: 'mobile',
    };

    const { onDropAccepted, onRemoveFile, files } = useOrderFileUpload({
        orderId,
        itemId,
        analyticsMetadata,
        forRefab: true,
    });
    const hasFiles = !!files.length;
    return (
        <PracticeFullScreenDialog dialogPaperStyle={{ padding: 20 }}>
            <NavigationLogoDandy className={classes.logo} />
            <Text variant={'h4'} style={{ marginTop: 24 }}>
                {isFinished ? 'Files successfully uploaded to the refab' : 'Upload refab files'}
            </Text>
            {isFinished ? (
                <>
                    <Text variant={'body2'} color={'GRAY'} style={{ margin: '20px 0' }}>
                        You can now close this window and complete the refab using your laptop.
                    </Text>
                    <Button variant={'secondary'} fullWidth onClick={() => setIsFinished(false)}>
                        <ChevronLeft /> I need to add more photos
                    </Button>
                </>
            ) : (
                <>
                    {hasFiles && (
                        <div
                            style={{
                                border: `1px solid ${FlossPalette.DARK_TAN}`,
                                borderRadius: 8,
                                margin: '20px 0',
                                padding: 10,
                            }}
                        >
                            <Grid container justifyContent={'space-between'} style={{ marginBottom: 16 }}>
                                <Text variant={'body2'} bold>
                                    Your files
                                </Text>
                                <Text variant={'body2'} color={'GRAY'}>
                                    Tap to view or remove
                                </Text>
                            </Grid>
                            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                            <FileList files={files} onRemove={onRemoveFile} maxHeight={180} />
                        </div>
                    )}
                    <Grid container direction={'row'} style={{ gap: 8, marginTop: 20 }}>
                        <SimpleDropzone
                            options={{
                                multiple: true,
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onDropAccepted,
                            }}
                            wrapperStyle={{ border: 0, padding: 0, minHeight: 'auto', width: 'auto', flexGrow: 1 }}
                            dropzoneContent={
                                <Button endIcon={'AddIcon'} variant={hasFiles ? 'secondary' : 'primary'} fullWidth>
                                    Add files
                                </Button>
                            }
                        />
                        {hasFiles && (
                            <Button
                                endIcon={'CheckIcon'}
                                variant={'primary'}
                                style={{ flexGrow: 1 }}
                                onClick={() => setIsFinished(true)}
                            >
                                Finish
                            </Button>
                        )}
                    </Grid>
                </>
            )}
        </PracticeFullScreenDialog>
    );
};

export const MobileRefabFlowFilesUploaderPage: React.VFC = () => {
    const classes = useStyles();
    const { orderId, itemId } = useParams<{ orderId: string; itemId: string }>();
    const { order } = useOrder(orderId);

    if (!order) {
        return null;
    }

    return (
        <Grid container direction={'column'} wrap={'nowrap'} className={classes.root}>
            <MobileRefabFlowFilesUploader orderId={order.id} itemId={itemId} />
        </Grid>
    );
};
