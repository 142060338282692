import type { LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment } from '@orthly/graphql-operations';
import type {
    LabsGqlDesignPrepCapabilitiesDto,
    LabsGqlInternalDesignCapabilitiesDto,
    LabsGqlOrderDesignCapabilityLevel,
} from '@orthly/graphql-schema';
import { LabsGqlDesignTaskType, LabsGqlOrderDesignCapabilityType } from '@orthly/graphql-schema';
import { type OrderDesignCapabilityLevel } from '@orthly/shared-types';
import { FlossPalette } from '@orthly/ui-primitives';
import _ from 'lodash';

export function levelToDisplayString(level: LabsGqlOrderDesignCapabilityLevel | OrderDesignCapabilityLevel): string {
    if (level === 'other') {
        return 'Other';
    }
    // All other levels are like 'level1' with a single-digit level number at the 5th position.
    return `Level ${level[5]}`;
}

export function getLabelForTaskType(task: LabsGqlDesignTaskType | null) {
    switch (task) {
        case LabsGqlDesignTaskType.DesignPrep:
            return 'Prep';
        case LabsGqlDesignTaskType.InternalDesign:
            return 'Design';
        case LabsGqlDesignTaskType.DesignReview:
            return 'Review';
        case LabsGqlDesignTaskType.ModelDesign:
            return 'Model';
        case LabsGqlDesignTaskType.AutomateReview:
            return 'Automate Review';
        case null:
            return 'None';
        default:
            return 'Other';
    }
}

export function getShorthandLabelForCapability(
    capability: LabsGqlOrderDesignCapabilityType,
    level: LabsGqlOrderDesignCapabilityLevel,
    shouldUseSimplifiedLabels: boolean,
) {
    if (capability === LabsGqlOrderDesignCapabilityType.Other || level === 'other') {
        return 'Other';
    }

    // All C&B Model design tasks are currently given a P1 requirement. This means a focus on P1 Model Design
    // is effectively a focus on all C&B. This can be updated when Model Design Capabilities are broken out to
    // follow the pattern prep, design and review follow. Similarly, all AutomateReview tasks will have only a P1
    // requirement.
    if (
        shouldUseSimplifiedLabels &&
        (capability === LabsGqlOrderDesignCapabilityType.PosteriorCrownAndBridge ||
            capability === LabsGqlOrderDesignCapabilityType.Implant)
    ) {
        return 'All';
    }

    return `${capability.toUpperCase()[0]}` + `${level[5]}`;
}

function getCapabilitiesForModelDesign(
    capabilities: LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment,
): Pick<LabsGqlDesignPrepCapabilitiesDto, 'posterior_crown_and_bridge'> &
    Pick<LabsGqlInternalDesignCapabilitiesDto, 'implant'> {
    // Currently the Model Design Capabilities designers can focus on are implant (all levels) and
    // C&B (controlled by P1 requirement). This can be replaced when Model Design capabilities are broken out
    // to follow the pattern prep, design and review currently follow
    const modelCapabilities: Pick<LabsGqlDesignPrepCapabilitiesDto, 'posterior_crown_and_bridge'> &
        Pick<LabsGqlInternalDesignCapabilitiesDto, 'implant'> = {
        posterior_crown_and_bridge: capabilities.DesignPrep.posterior_crown_and_bridge,
        implant: capabilities.InternalDesign.implant,
    };

    return modelCapabilities;
}

export function getCapabilitiesForTask(
    capabilities: LabsGqlDesignStaffAllTaskCapabilitiesDtoFragment,
    task: LabsGqlDesignTaskType,
) {
    switch (task) {
        case LabsGqlDesignTaskType.DesignPrep:
            return _.omit(capabilities.DesignPrep, '__typename');
        case LabsGqlDesignTaskType.InternalDesign:
            return _.omit(capabilities.InternalDesign, '__typename');
        case LabsGqlDesignTaskType.DesignReview:
            return _.omit(capabilities.DesignReview, '__typename');
        case LabsGqlDesignTaskType.ModelDesign:
            return getCapabilitiesForModelDesign(capabilities);
        default:
            return _.omit(capabilities.InternalDesign, '__typename');
    }
}

export function getLabelForCapability(
    capability: LabsGqlOrderDesignCapabilityType,
    shouldUseSimplifiedLabels: boolean,
) {
    switch (capability) {
        case LabsGqlOrderDesignCapabilityType.AnteriorCrownAndBridge:
            return 'Anterior C&B';
        case LabsGqlOrderDesignCapabilityType.PosteriorCrownAndBridge:
            // All C&B Model Design Tasks are currently given P1 requirements. Similarly, all AutomateReview tasks will
            // have only P1 requirements. Translated here to represent that to the user.
            return shouldUseSimplifiedLabels ? 'Crown & Bridge' : 'Posterior C&B';
        case LabsGqlOrderDesignCapabilityType.Implant:
            return 'Implant';
        default:
            return 'Other';
    }
}

export const capabilityCardStyles = {
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    borderRadius: 16,
    height: '100%',
};
