import type { LabPriceFormProps, LabPriceFormState } from './Utils/ProductCatalogForms.types';
import { intoBasePriceFieldDefs } from './Utils/ProductCatalogForms.utils';
import { useListDisplayRuleOptsQuery } from '@orthly/graphql-react';
import { LabsGqlLabPriceRuleType } from '@orthly/graphql-schema';
import { OrderItemArch } from '@orthly/items';
import { QuickForm } from '@orthly/ui';

export const RemovableForm: React.FC<LabPriceFormProps> = ({ submit, price, disabled }) => {
    const { data: displayOpts } = useListDisplayRuleOptsQuery({ fetchPolicy: 'cache-first' });
    return (
        <QuickForm<LabPriceFormState<LabsGqlLabPriceRuleType.Removable>>
            fields={intoBasePriceFieldDefs<LabsGqlLabPriceRuleType.Removable>({
                unit_type: {
                    type: 'select',
                    label: 'Unit Type',
                    options: ['Night Guard', 'Retainer', 'Bleaching Tray', 'Sportsguard'],
                },
                material: {
                    type: 'select',
                    label: 'Material',
                    options: displayOpts?.listDisplayRuleOptions.material_types ?? [],
                },
                arch: {
                    type: 'select',
                    label: 'Arch',
                    options: Object.values(OrderItemArch),
                },
            })}
            initialValues={
                price
                    ? {
                          name: price.name,
                          unit_type: price.rule.__typename === 'LabPriceRemovableRuleDTO' ? price.rule.unit_type : null,
                          material: price.rule.__typename === 'LabPriceRemovableRuleDTO' ? price.rule.material : null,
                          arch: price.rule.__typename === 'LabPriceRemovableRuleDTO' ? price.rule.arch : null,
                      }
                    : {}
            }
            onSubmit={async result => {
                await submit({
                    name: result.name,
                    rule: {
                        type: LabsGqlLabPriceRuleType.Removable,
                        unit_type: result.unit_type || null,
                        material: result.material || null,
                        arch: result.arch || null,
                    },
                });
            }}
            disabled={disabled}
        />
    );
};
