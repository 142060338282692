import { ItemSelect } from '../../FeedbackAndRefab';
import type { RefabFlowLabOrder } from '../state/refab-flow-types';
import { isUnitTypeEligibleForRefab } from '../utils';
import { OrderItemV2Utils } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

interface RefabItemSelectProps {
    order?: RefabFlowLabOrder;
    onSelection: (selectedItemIds: string[]) => void;
    selectedItemIds: string[];
}

export const RefabItemSelectContainer: React.VFC<RefabItemSelectProps> = ({ order, onSelection, selectedItemIds }) => {
    if (!order) {
        return null;
    }
    const items = OrderItemV2Utils.parseItems(order.items_v2);
    const eligibleItemsForRefab = items.filter(item => isUnitTypeEligibleForRefab(item));

    return (
        <ItemSelect
            items={eligibleItemsForRefab}
            selectedItems={eligibleItemsForRefab.filter(i => selectedItemIds.includes(i?.id))}
            setItemSelected={(item, isSelected) => {
                if (isSelected) {
                    onSelection(_.uniq([...selectedItemIds, item.id]));
                } else {
                    onSelection(selectedItemIds.filter(id => id !== item.id));
                }
            }}
        />
    );
};
