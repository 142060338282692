import type { LabsGqlStaffRoleWithAny } from '@orthly/graphql-schema';
import { useChangeSubmissionFn } from '@orthly/ui';

export function useChangeAttachmentVisibility(
    onChangeAttachmentVisibility: (visible_to_roles: LabsGqlStaffRoleWithAny[]) => Promise<void>,
) {
    return useChangeSubmissionFn<any, [LabsGqlStaffRoleWithAny[]]>(onChangeAttachmentVisibility, {
        closeOnComplete: true,
        successMessage: () => ['Note Visibility Changed!', {}],
        errorMessage: result => [result.message, {}], // TODO(nour): change to Apollo once migrated to veneer
    });
}
