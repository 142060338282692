import { AnalyticsClient } from '../../../../../../../utils/analyticsClient';
import { DoctorDesignTaskAlerts } from '../DoctorDesignTaskAlerts';
import type { OrderDesignSubmissionCoreProps } from './OrderDesignSubmissionCommon.graphql';
import { OrderDesignSubmissionCommon } from './OrderDesignSubmissionCommon.graphql';
import type { LabsGqlCompleteDesignPrepTaskMutationVariables } from '@orthly/graphql-operations';
import { useCompleteDesignPrepTaskMutation, useConfigureDesignerTypeMutation } from '@orthly/graphql-react';
import { LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import { DesignPrepStartPrepBanner } from '@orthly/veneer';
import { ModelAssetCacheProvider } from '@orthly/veneer';
import React from 'react';

type Vars = LabsGqlCompleteDesignPrepTaskMutationVariables['data'];

export const OrderDesignPrepTaskAlert: React.FC<OrderDesignSubmissionCoreProps> = ({
    order,
    variant,
    refetchOrder,
    designRevision,
}) => {
    // Mutations for completing the task
    const [submitCompleteTaskMtn] = useCompleteDesignPrepTaskMutation();
    const completeTaskMtnSubmitter = (data: Vars) => submitCompleteTaskMtn({ variables: { data } });
    const { submit: submitCompleteTask, submitting: submittingCompleteTask } = useChangeSubmissionFn<any, [Vars]>(
        completeTaskMtnSubmitter,
        {
            closeOnComplete: true,
            successMessage: () => [`Design task completed!`, {}],
            onSuccess: refetchOrder,
        },
    );

    const [submitReconfigureMtn] = useConfigureDesignerTypeMutation();
    const { submit: submitReconfigureTask, submitting: submittingReconfigureTask } = useChangeSubmissionFn<any, []>(
        () =>
            submitReconfigureMtn({
                variables: {
                    internal_design_required: order.fulfillment_workflow.configuration.internal_design_required,
                    id: order.id,
                    internal_design_prep_required: false,
                    separate_model_design_step: null,
                    automate_review_required: null,
                },
            }),
        {
            successMessage: () => [`Design prep disabled`, {}],
            onSuccess: async () => {
                await refetchOrder();
                AnalyticsClient.track(`Ops - Portal - Design Prep Task Completed`, {
                    $groups: {
                        order: order.id,
                    },
                });
            },
        },
    );

    const CardSubtitle = React.useCallback(() => {
        return <DoctorDesignTaskAlerts lab_slip_alerts={order.lab_slip_alerts} />;
    }, [order.lab_slip_alerts]);

    const CardTopAction = React.useCallback(() => {
        return (
            <LoadBlocker blocking={submittingReconfigureTask} ContainerProps={{ style: { width: 'fit-content' } }}>
                <Button onClick={() => submitReconfigureTask()} variant={'ghost'} startIcon={'CubeIcon'}>
                    Remove
                </Button>
            </LoadBlocker>
        );
    }, [submittingReconfigureTask, submitReconfigureTask]);

    return (
        <>
            <ModelAssetCacheProvider>
                <DesignPrepStartPrepBanner order={order} refetch={refetchOrder} />
            </ModelAssetCacheProvider>

            <OrderDesignSubmissionCommon
                taskType={LabsGqlWorkflowTaskType.DesignPrep}
                onSubmit={async ({ design_file_path, notes, metafields }) => {
                    if (!design_file_path) {
                        return;
                    }

                    await submitCompleteTask({
                        notes,
                        metafields,
                        prepped_design_file_path: design_file_path,
                        order_id: order.id,
                    });
                }}
                submitting={submittingCompleteTask}
                CardTitle={'Design Prep'}
                CardTopAction={CardTopAction}
                CardSubtitle={CardSubtitle}
                order={order}
                refetchOrder={refetchOrder}
                variant={variant}
                designRevision={designRevision}
            />
        </>
    );
};
