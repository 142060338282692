import type {
    StartEditingPayload,
    UpdateCommonFieldsPayload,
    DeleteRolePayload,
    UpdateRolePayload,
    UpdatePasswordPayload,
    UserSavedPayload,
    RoleSavedPayload,
    RolesLoadedPayload,
    ScreenSetPayload,
    SetSearchPayload,
} from './Users.types';
import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'users';

export const UsersActions = {
    START_EDITING: createSyncAction<StartEditingPayload, [StartEditingPayload]>(`${PREFIX}/START_EDITING`),
    UPDATE_COMMON_FIELDS: createSyncAction<UpdateCommonFieldsPayload, [UpdateCommonFieldsPayload]>(
        `${PREFIX}/UPDATE_COMMON_FIELDS`,
    ),
    ADD_ROLE: createSyncAction<undefined>(`${PREFIX}/ADD_ROLE`),
    DELETE_ROLE: createSyncAction<DeleteRolePayload, [DeleteRolePayload]>(`${PREFIX}/DELETE_ROLE`),
    UPDATE_ROLE: createSyncAction<UpdateRolePayload, [UpdateRolePayload]>(`${PREFIX}/UPDATE_ROLE`),
    TOGGLE_PASSWORD: createSyncAction<undefined>(`${PREFIX}/TOGGLE_PASSWORD`),
    UPDATE_PASSWORD: createSyncAction<UpdatePasswordPayload, [UpdatePasswordPayload]>(`${PREFIX}/UPDATE_PASSWORD`),
    STOP_EDITING: createSyncAction<undefined>(`${PREFIX}/STOP_EDITING`),
    USER_SAVED: createSyncAction<UserSavedPayload, [UserSavedPayload]>(`${PREFIX}/USER_SAVED`),
    ROLE_SAVED: createSyncAction<RoleSavedPayload, [RoleSavedPayload]>(`${PREFIX}/ROLE_SAVED`),
    ROLES_LOADED: createSyncAction<RolesLoadedPayload, [RolesLoadedPayload]>(`${PREFIX}/ROLES_LOADED`),
    SCREEN_SET: createSyncAction<ScreenSetPayload, [ScreenSetPayload]>(`${PREFIX}/SCREEN_SET`),
    SET_SEARCH: createSyncAction<SetSearchPayload, [SetSearchPayload]>(`${PREFIX}/SET_SEARCH`),
};

export const useUsersAction = generateUseActionHook<typeof UsersActions>(UsersActions);
