import BadgeSrcAttention from '../../../assets/images/tracker/Badge/Attention.png';
import BadgeSrcDarkGray from '../../../assets/images/tracker/Badge/DarkGray.png';
import BadgeSrcGray from '../../../assets/images/tracker/Badge/Gray.png';
import CancelSrcAttention from '../../../assets/images/tracker/Cancel/Attention.png';
import CancelSrcDarkGray from '../../../assets/images/tracker/Cancel/DarkGray.png';
import CancelSrcGray from '../../../assets/images/tracker/Cancel/Gray.png';
import CheckSrcAttention from '../../../assets/images/tracker/Check/Attention.png';
import CheckSrcDarkGray from '../../../assets/images/tracker/Check/DarkGray.png';
import CheckSrcGray from '../../../assets/images/tracker/Check/Gray.png';
import ClockSrcAttention from '../../../assets/images/tracker/Clock/Attention.png';
import ClockSrcDarkGray from '../../../assets/images/tracker/Clock/DarkGray.png';
import ClockSrcGray from '../../../assets/images/tracker/Clock/Gray.png';
import CubeSrcAttention from '../../../assets/images/tracker/Cube/Attention.png';
import CubeSrcDarkGray from '../../../assets/images/tracker/Cube/DarkGray.png';
import CubeSrcGray from '../../../assets/images/tracker/Cube/Gray.png';
import OrderSrcAttention from '../../../assets/images/tracker/Order/Attention.png';
import OrderSrcDarkGray from '../../../assets/images/tracker/Order/DarkGray.png';
import OrderSrcGray from '../../../assets/images/tracker/Order/Gray.png';
import PersonSrcAttention from '../../../assets/images/tracker/Person/Attention.png';
import PersonSrcDarkGray from '../../../assets/images/tracker/Person/DarkGray.png';
import PersonSrcGray from '../../../assets/images/tracker/Person/Gray.png';
import RefabSrcAttention from '../../../assets/images/tracker/Refab/Attention.png';
import RefabSrcDarkGray from '../../../assets/images/tracker/Refab/DarkGray.png';
import RefabSrcGray from '../../../assets/images/tracker/Refab/Gray.png';
import ScanSrcAttention from '../../../assets/images/tracker/Scan/Attention.png';
import ScanSrcDarkGray from '../../../assets/images/tracker/Scan/DarkGray.png';
import ScanSrcGray from '../../../assets/images/tracker/Scan/Gray.png';
import StopSrcAttention from '../../../assets/images/tracker/Stop/Attention.png';
import StopSrcDarkGray from '../../../assets/images/tracker/Stop/DarkGray.png';
import StopSrcGray from '../../../assets/images/tracker/Stop/Gray.png';
import ThumbsUpSrcAttention from '../../../assets/images/tracker/ThumbsUp/Attention.png';
import ThumbsUpSrcDarkGray from '../../../assets/images/tracker/ThumbsUp/DarkGray.png';
import ThumbsUpSrcGray from '../../../assets/images/tracker/ThumbsUp/Gray.png';
import ToolSrcAttention from '../../../assets/images/tracker/Tool/Attention.png';
import ToolSrcDarkGray from '../../../assets/images/tracker/Tool/DarkGray.png';
import ToolSrcGray from '../../../assets/images/tracker/Tool/Gray.png';
import TruckSrcAttention from '../../../assets/images/tracker/Truck/Attention.png';
import TruckSrcDarkGray from '../../../assets/images/tracker/Truck/DarkGray.png';
import TruckSrcGray from '../../../assets/images/tracker/Truck/Gray.png';
import WarningSrcAttention from '../../../assets/images/tracker/Warning/Attention.png';
import WarningSrcGray from '../../../assets/images/tracker/Warning/Gray.png';
import { Avatar } from '@orthly/ui-primitives';
import React from 'react';

export type TrackerIconVariant =
    | 'Scan'
    | 'Badge'
    | 'Cancel'
    | 'Check'
    | 'Person'
    | 'Order'
    | 'Refab'
    | 'Stop'
    | 'Tool'
    | 'Truck'
    | 'Warning'
    | 'Cube'
    | 'Clock'
    | 'ThumbsUp';
export type TrackerIconColor = 'GRAY' | 'DARK_GRAY' | 'ATTENTION';

type IconSrcMap = { [K in TrackerIconVariant]-?: { [C in TrackerIconColor]-?: string } };

const ICON_SRC_MAP: IconSrcMap = {
    Scan: { ATTENTION: ScanSrcAttention, DARK_GRAY: ScanSrcDarkGray, GRAY: ScanSrcGray },
    Badge: { ATTENTION: BadgeSrcAttention, DARK_GRAY: BadgeSrcDarkGray, GRAY: BadgeSrcGray },
    Cancel: { ATTENTION: CancelSrcAttention, DARK_GRAY: CancelSrcDarkGray, GRAY: CancelSrcGray },
    Check: { ATTENTION: CheckSrcAttention, DARK_GRAY: CheckSrcDarkGray, GRAY: CheckSrcGray },
    Order: { ATTENTION: OrderSrcAttention, DARK_GRAY: OrderSrcDarkGray, GRAY: OrderSrcGray },
    Person: { ATTENTION: PersonSrcAttention, DARK_GRAY: PersonSrcDarkGray, GRAY: PersonSrcGray },
    Refab: { ATTENTION: RefabSrcAttention, DARK_GRAY: RefabSrcDarkGray, GRAY: RefabSrcGray },
    Stop: { ATTENTION: StopSrcAttention, DARK_GRAY: StopSrcDarkGray, GRAY: StopSrcGray },
    Tool: { ATTENTION: ToolSrcAttention, DARK_GRAY: ToolSrcDarkGray, GRAY: ToolSrcGray },
    Truck: { ATTENTION: TruckSrcAttention, DARK_GRAY: TruckSrcDarkGray, GRAY: TruckSrcGray },
    Warning: { ATTENTION: WarningSrcAttention, DARK_GRAY: WarningSrcAttention, GRAY: WarningSrcGray },
    ThumbsUp: { ATTENTION: ThumbsUpSrcAttention, DARK_GRAY: ThumbsUpSrcDarkGray, GRAY: ThumbsUpSrcGray },
    Clock: { ATTENTION: ClockSrcAttention, DARK_GRAY: ClockSrcDarkGray, GRAY: ClockSrcGray },
    Cube: { ATTENTION: CubeSrcAttention, DARK_GRAY: CubeSrcDarkGray, GRAY: CubeSrcGray },
};

interface TrackerIconProps {
    variant: TrackerIconVariant;
    color: TrackerIconColor;
    className: string;
}

export const TrackerIcon: React.FC<TrackerIconProps> = props => {
    const { variant, color, className } = props;
    return <Avatar className={className} src={ICON_SRC_MAP[variant][color]} />;
};
