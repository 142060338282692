import { SubmitOrderScanReview } from '../actions/SubmitOrderScanReview';
import { DoctorDesignTaskAlerts } from './DoctorDesignTaskAlerts';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import type { ActionCardButtonPropsBase } from '@orthly/ui';
import { ScanIcon } from '@orthly/ui';
import { Text, Grid } from '@orthly/ui-primitives';
import { CondensedActionCard, type CondensedActionCardVariant } from '@orthly/veneer';
import React from 'react';

interface ReasonsDisplayProps {
    reasons: string[];
}

const ReasonsDisplay: React.FC<ReasonsDisplayProps> = ({ reasons, children }) => {
    return (
        <Grid container>
            {children}
            {reasons.map((reason, index) => (
                <Grid key={index} container>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        • {reason}
                    </Text>
                </Grid>
            ))}
        </Grid>
    );
};

interface OrderScanReviewAlertProps {
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
    variant: CondensedActionCardVariant;
}

export const OrderScanReviewAlert: React.FC<OrderScanReviewAlertProps> = props => {
    const { order } = props;
    const [open, setOpen] = React.useState<boolean>(false);
    const activeTask = order.fulfillment_workflow.active_task;
    const isSidebarElement = props.variant === 'sidebar';
    const primaryAction = React.useMemo<ActionCardButtonPropsBase>(() => {
        return {
            endIcon: 'CheckIcon',
            text: isSidebarElement ? 'Review' : 'Review scan',
            onClick: () => setOpen(true),
        };
    }, [isSidebarElement, setOpen]);
    if (activeTask?.__typename !== 'ScanReviewWorkflowTask') {
        return null;
    }
    const reasons: string[] = activeTask.configuration.scan_review_reasons ?? [];
    const assignedUser = activeTask?.assignee
        ? `${activeTask.assignee.assigned_user.first_name} ${activeTask?.assignee.assigned_user.last_name}`
        : 'Unassigned';

    return (
        <>
            <CondensedActionCard
                title={'Scan Review'}
                assignee={assignedUser}
                primaryAction={primaryAction}
                IconComponent={ScanIcon}
                refetch={props.refetchOrder}
                task={activeTask}
                variant={props.variant}
                subtitle={
                    <>
                        <ReasonsDisplay reasons={reasons}>
                            {activeTask.configuration.scan_review_request_notes && (
                                <>
                                    <Grid container>
                                        <Text variant={'body2'} color={'DARK_GRAY'} style={{ whiteSpace: 'pre-line' }}>
                                            <b>Notes:</b>
                                            <br />
                                            {activeTask.configuration.scan_review_request_notes}
                                        </Text>
                                    </Grid>
                                    <Grid container>
                                        <Text variant={'body2'} color={'DARK_GRAY'}>
                                            <b>Reasons: </b>
                                        </Text>
                                    </Grid>
                                </>
                            )}
                        </ReasonsDisplay>

                        <DoctorDesignTaskAlerts lab_slip_alerts={order.lab_slip_alerts} />
                    </>
                }
            />
            <SubmitOrderScanReview
                open={open}
                reasons={reasons}
                setOpen={setOpen}
                scan_review_task_notes={activeTask.configuration.scan_review_request_notes}
                {...props}
            />
        </>
    );
};
