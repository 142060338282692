import type { DesignViewerLiteWrapperProps } from './DesignViewerLiteWrapper';
import { DesignViewerLiteWrapper } from './DesignViewerLiteWrapper';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { Grid, Button, Text } from '@orthly/ui-primitives';
import { useScreenIsMobile } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface ClickToLoadChildrenProps {
    clickToLoad?: boolean;
    clickToLoadType?: 'design' | 'design preview';
    children: React.ReactNode;
    orderId: string;
}

const ClickToLoadChildren: React.FC<ClickToLoadChildrenProps> = props => {
    const { clickToLoad, clickToLoadType, children, orderId } = props;
    const [clicked, setClicked] = React.useState<boolean>(false);
    const isMobile = useScreenIsMobile();

    if (clickToLoad && !clicked) {
        return (
            <Grid
                container
                style={{
                    flexDirection: 'column',
                    justifyContent: isMobile ? 'end' : 'center',
                    alignItems: 'center',
                    marginLeft: isMobile ? 0 : 40,
                }}
            >
                <Text
                    style={{
                        color: 'gray',
                        marginBottom: '1em',
                        maxWidth: isMobile ? '80%' : 250,
                        textAlign: 'center',
                    }}
                >
                    Press the button below to load a 3D model of the {clickToLoadType ?? 'design'}
                </Text>
                <Button
                    onClick={() => {
                        setClicked(true);

                        BrowserAnalyticsClientFactory.Instance?.track(
                            'All - Portal - Design Click To Load Button Clicked',
                            {
                                $groups: { order: orderId },
                                variant: clickToLoadType,
                            },
                        );
                    }}
                    variant={'primary'}
                    style={{ minWidth: isMobile ? '80%' : 250 }}
                >
                    Display {clickToLoadType ? _.startCase(clickToLoadType) : 'Design'}
                </Button>
            </Grid>
        );
    }

    return <>{children}</>;
};

interface ClickToLoadDesignViewerLiteWrapperProps extends DesignViewerLiteWrapperProps {
    // loading is quite expensive in CPU, especially for large models. Without clickToLoad=true,
    // the user pays for it whether they care about the waxup or not
    clickToLoad?: boolean;
    clickToLoadType?: 'design' | 'design preview';
    wrapperStyle?: React.CSSProperties;
}

export const ClickToLoadDesignViewerLiteWrapper: React.FC<ClickToLoadDesignViewerLiteWrapperProps> = ({
    clickToLoad,
    clickToLoadType,
    wrapperStyle,
    ...orderDesignPreviewProps
}) => (
    <ClickToLoadChildren
        clickToLoadType={clickToLoadType}
        clickToLoad={clickToLoad}
        orderId={orderDesignPreviewProps.order.id}
    >
        <DesignViewerLiteWrapper
            order={orderDesignPreviewProps.order}
            refetchDesign={orderDesignPreviewProps.refetchDesign}
            designFragment={orderDesignPreviewProps.designFragment}
            style={wrapperStyle}
        />
    </ClickToLoadChildren>
);
