import type { ToothNumber } from '@orthly/items';
import type * as THREE from 'three';

export enum ScanReviewMarginMarkingToolActiveState {
    Drawing = 'drawing',
    Transforming = 'transforming',
    Orbiting = 'orbiting',
}

export enum ScanReviewMarginLineConnection {
    Start = 'start',
    End = 'end',
    None = 'none',
}

export class ScanReviewEditedMarginLine {
    constructor(
        public readonly toothNumber: ToothNumber,
        public readonly controlPoints: THREE.Vector3[],
        public marginClosed: boolean,
    ) {}

    clear() {
        this.controlPoints.length = 0;
        this.marginClosed = false;
    }

    clone() {
        return new ScanReviewEditedMarginLine(
            this.toothNumber,
            this.controlPoints.map((controlPoint: THREE.Vector3) => controlPoint.clone()),
            this.marginClosed,
        );
    }
}
