import { SortableItemContext } from './SortableItemContext';
import { IconButton, Icon } from '@orthly/ui-primitives';
import type { PropsWithChildren } from 'react';
import React from 'react';

interface Props {
    customHandle?: React.ReactElement;
    // Can pass a class name to override styling if needed.
    className?: string;
}

export function DragHandle({ customHandle, className }: PropsWithChildren<Props>) {
    const { attributes, listeners, ref } = React.useContext(SortableItemContext);
    const optionalClass = className ? { className } : {};

    return (
        <div {...attributes} {...listeners} {...optionalClass} ref={ref}>
            {customHandle ? (
                customHandle
            ) : (
                <IconButton size={'small'}>
                    <Icon icon={'DragHandle'} />
                </IconButton>
            )}
        </div>
    );
}
