import type { FetchResult } from '@apollo/client';
import type { LabsGqlRefreshScanImagesMutation } from '@orthly/graphql-operations';
import { useRefreshScanImagesMutation } from '@orthly/graphql-react';
import { LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import { Button, IconButton, Tooltip, Icon } from '@orthly/ui-primitives';
import React from 'react';

interface RefreshScanImagesProps {
    scan: { id: string; file_url: string | null };
    onComplete: () => any;
    useIconButton?: boolean;
}

type MutationRes = FetchResult<LabsGqlRefreshScanImagesMutation>;

export const RefreshScanImages: React.FC<RefreshScanImagesProps> = props => {
    const { scan } = props;
    const [submitMtn] = useRefreshScanImagesMutation({ variables: { processedScanId: scan.id } });
    const { submit, submitting } = useChangeSubmissionFn<MutationRes>(() => submitMtn(), {
        closeOnComplete: true,
        successMessage: () => ['Scan images refreshed', {}],
        onSuccess: async () => {
            props.onComplete?.();
        },
    });
    // can only refresh images for processed scans
    if (!scan.file_url) {
        return null;
    }
    const containerStyle: React.CSSProperties = props.useIconButton ? { width: 'auto' } : { padding: '10px 0' };
    return (
        <LoadBlocker blocking={submitting} ContainerProps={{ style: containerStyle }}>
            {props.useIconButton ? (
                <IconButton onClick={() => submit()}>
                    <Tooltip title={'Refresh Scan Images'}>
                        <Icon icon={'ImageSearch'} />
                    </Tooltip>
                </IconButton>
            ) : (
                <Button onClick={() => submit()} variant={'contained'} fullWidth style={{ whiteSpace: 'pre-line' }}>
                    Refresh Scan Images
                </Button>
            )}
        </LoadBlocker>
    );
};
