import { getAdminReduxMiddleware } from '../../../redux/admin-analytics.middleware';
import { automationBuilderReducer } from './AutomationBuilder.state';
import type { AutomationBuilderState } from './AutomationBuilder.types';
import { compose, applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

const isDev = process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGING === 'true';
const composeEnhancers =
    (isDev &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: false,
            traceLimit: 5,
            name: 'AutomationBuilder',
        })) ||
    compose;

export const AutomationBuilderStore = createStore<AutomationBuilderState, any, any, any>(
    automationBuilderReducer,
    undefined,
    composeEnhancers(applyMiddleware(...getAdminReduxMiddleware([thunk]))),
);
