import type { FormItemValues, FormValues, SchemaFromOrderPricesArgs } from './AdminLabsEditInvoicingLineItems.types';
import { OrderPriceCalculatedPrice } from './OrderPriceCalculatedPrice';
import type { LabsGqlOrderPriceDtoFragment, LabsGqlOrderPriceUnitInfoDtoFragment } from '@orthly/graphql-operations';
import type { LabsGqlUpdateOrderPriceEntryCommand } from '@orthly/graphql-schema';
import { CurrencyUtils } from '@orthly/runtime-utils';
import _ from 'lodash';

export const generateLabelFromUnits = (units: LabsGqlOrderPriceUnitInfoDtoFragment[]): string => {
    let labelString = '';
    units.forEach((unit, idx) => {
        if (idx > 0) {
            labelString += ', ';
        }

        labelString += `${unit.unit_type} (Tooth ${unit.unn ?? (unit.unns ?? []).join(', ')}${
            unit.material ? `, ${unit.material}` : ``
        })`;
    });
    return labelString;
};

const getOrderPriceUnitFirstTooth = (orderPrice: LabsGqlOrderPriceDtoFragment) => {
    return orderPrice.units
        .map(({ unn, unns = [] }) => {
            if (unns && unns.length > 0) {
                return [...unns].sort((a, b) => a - b);
            }
            return unn;
        })
        .flat()[0];
};

const sortAndGroupOrderPrices = (
    ops: LabsGqlOrderPriceDtoFragment[],
): Record<string, LabsGqlOrderPriceDtoFragment[]> => {
    // unns are first sorted by the tooth number, then grouped together by the price_id
    const sorted = _.sortBy(ops, [getOrderPriceUnitFirstTooth]);
    const grouped = Object.values(_.groupBy(sorted, 'price_id')).flat();

    // lastly, group by item_id, such that in the end we have a record of
    // item_id -> order_price[]
    // where the items are sorted by tooth number AND still grouped by their shared prices
    // (e.g., if there are two Crown Emax Full Contour items, we want to group them together)
    return _.groupBy(grouped, 'item_id');
};

export const quickFormInitialValueFromOrderPrices = (orderPrice: LabsGqlOrderPriceDtoFragment) => {
    const { id, total_price_cents, override_total_price_cents } = orderPrice;
    // the `total_price_cents` is the price that was calculated by pricingV2, so should always
    // exist. `override_total_price_cents` is the price that was manually overridden by the user
    const price = override_total_price_cents ?? total_price_cents;

    return {
        [`price_${id}`]: `${(price as any) / 100 || `0.00`}`,
    };
};

export const quickFormInitialValuesFromOrderPrices = (orderPrices: LabsGqlOrderPriceDtoFragment[]) => {
    const orderPricesGroupedByItemId = sortAndGroupOrderPrices(orderPrices);

    return Object.assign(
        {},
        ...Object.entries(orderPricesGroupedByItemId).map(([itemId, ops]) => ({
            [`${itemId}`]: {
                ...Object.assign({}, ...ops.map(quickFormInitialValueFromOrderPrices)),
            },
        })),
    );
};

const sumOrderPriceUnits = (orderPrices: LabsGqlOrderPriceDtoFragment[]): number => {
    return _.sumBy(orderPrices, op => op.override_total_price_cents ?? op.total_price_cents);
};

const orderPricesEqual = (
    a: LabsGqlOrderPriceDtoFragment[] | undefined,
    b: LabsGqlOrderPriceDtoFragment[] | undefined,
) => {
    if (!a || !b) {
        return false;
    }
    const totalA = sumOrderPriceUnits(a);
    const totalB = sumOrderPriceUnits(b);
    return totalA === totalB;
};

export const orderPriceDecreased = (
    oldPrice: LabsGqlOrderPriceDtoFragment[] | undefined,
    newPrice: LabsGqlOrderPriceDtoFragment[] | undefined,
) => {
    if (!oldPrice || !newPrice) {
        return true;
    }
    const oldTotal = sumOrderPriceUnits(oldPrice);
    const newTotal = sumOrderPriceUnits(newPrice);
    return oldTotal > newTotal;
};

export const quickFormFieldFromOrderPrices = (orderPrice: LabsGqlOrderPriceDtoFragment) => {
    const { id, units, total_price_cents } = orderPrice;
    const label = generateLabelFromUnits(units);

    return {
        [`price_${id}`]: {
            type: 'currency',
            precision: 2,
            fieldProps: { textAlign: 'left' },
            label,
            beforeContent: <OrderPriceCalculatedPrice price={total_price_cents} />,
        },
    };
};

export const quickFormSchemaFromOrderPrices = (args: SchemaFromOrderPricesArgs) => {
    const { orderPrices, formOrderPrices } = args;
    const orderPricesGroupedByItemId = sortAndGroupOrderPrices(orderPrices);

    return Object.assign(
        {},
        ...Object.entries(orderPricesGroupedByItemId).map(([itemId, ops], idx) => {
            const formOrderPricesForItem = formOrderPrices.filter(({ item_id }) => item_id === itemId);

            return {
                [`${itemId}`]: {
                    type: 'nested',
                    label: `Item #${idx + 1}`,
                    fields: {
                        ...Object.assign({}, ...ops.map(quickFormFieldFromOrderPrices)),
                        reason: {
                            type: 'text',
                            label: 'Override Reason',
                            hidden: orderPricesEqual(ops, formOrderPricesForItem),
                            optional: !orderPriceDecreased(ops, formOrderPricesForItem),
                        },
                    },
                },
            };
        }),
    );
};

export const orderPricesFromQuickformValues = (
    result: FormValues,
    dbPrices: LabsGqlOrderPriceDtoFragment[],
): LabsGqlOrderPriceDtoFragment[] => {
    // the `dbPrices` represent the db values, and the `result` represents the state of the form
    return [...dbPrices].map(dbPrice => {
        const { id: priceId, item_id, total_price_cents, override_total_price_cents } = dbPrice;
        const resultsForItem = result[item_id];
        if (!resultsForItem) {
            return { ...dbPrice };
        }

        const rawUpdatedPrice = resultsForItem[`price_${priceId}`];
        const updatedPrice = CurrencyUtils.dollarStringToCents(rawUpdatedPrice);

        let overrideTotalPrice: number | null = override_total_price_cents;
        let reason: string | null = null;

        // if the value in the db has a price override, the price has changed if the input value
        // differs from the stored override value, NOT if it differs from the price calculated by
        // pricingV2. this allows us to support a user changing the price BACK to the V2 calculated
        // price
        const dbPriceHasOverride = override_total_price_cents !== null;
        const dbPriceForComparison = dbPriceHasOverride ? override_total_price_cents : total_price_cents;

        if (rawUpdatedPrice && updatedPrice !== dbPriceForComparison) {
            overrideTotalPrice = updatedPrice;
            reason = resultsForItem.reason ?? null;
        }

        return {
            ...dbPrice,
            override_total_price_cents: overrideTotalPrice,
            override_total_price_cents_reason: reason,
        };
    });
};

export const totalFromOrderPrices = (items: FormItemValues[]) => {
    const total = _.sumBy(items, item => {
        return _.sumBy(_.values(_.omit(item, 'reason')), (unit: string) => parseFloat(unit.replace(',', '')));
    });
    // handle floating point precision error
    return Math.round(100 * total) / 100;
};

export const orderPricesForBulkUpdate = (
    newValues: LabsGqlOrderPriceDtoFragment[],
    rawValues: LabsGqlOrderPriceDtoFragment[],
): LabsGqlUpdateOrderPriceEntryCommand[] => {
    return newValues
        .filter(op => {
            const rawValue = rawValues.find(({ id }) => id === op.id);
            if (!rawValue) {
                return false;
            }

            // `onChange` calculates new prices via `OrderPricesFromQuickformValues`, which
            // stores the new price against the `override_total_price_cents` field. if a price
            // does not have the override field set, it has not been updated and should not
            // be patched
            return !!op.override_total_price_cents && !orderPricesEqual([op], [rawValue]);
        })
        .map<LabsGqlUpdateOrderPriceEntryCommand>(update => ({
            order_price_id: update.id,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            override_total_price_cents: update.override_total_price_cents!,
            override_total_price_cents_reason: update.override_total_price_cents_reason ?? null,
        }));
};
