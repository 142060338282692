import { isAnteriorContourOptionDisabled } from './presetRules/anteriorContour';
import { isContactDesignOptionDisabled } from './presetRules/contactDesign';
import { isFacialAnatomyOptionDisabled } from './presetRules/facialAnatomy';
import { isMarginOptionDisabled } from './presetRules/margin';
import { isMarginalRidgeOptionDisabled } from './presetRules/marginalRidge';
import { isOcclusalAnatomyOptionDisabled } from './presetRules/occlusalAnatomy';
import { isPosteriorContourOptionDisabled } from './presetRules/posteriorContour';
import { isToothDesignOptionDisabled } from './presetRules/toothDesign';
import type { LabsGqlDesignOrderNoteCategory } from '@orthly/graphql-schema';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';

export const isOptionDisabled = (
    presetType: LabsGqlGuidedWaxupPresetType,
    option: LabsGqlDesignOrderNoteCategory,
    selections: LabsGqlDesignOrderNoteCategory[],
): boolean => {
    const selectionsContain = (options: LabsGqlDesignOrderNoteCategory[]) => options.some(o => selections.includes(o));

    switch (presetType) {
        case LabsGqlGuidedWaxupPresetType.ToothDesign:
            return isToothDesignOptionDisabled(option, selectionsContain);

        case LabsGqlGuidedWaxupPresetType.MarginView:
            return isMarginOptionDisabled(option, selectionsContain);

        case LabsGqlGuidedWaxupPresetType.AnteriorContour:
            return isAnteriorContourOptionDisabled(option, selectionsContain);

        case LabsGqlGuidedWaxupPresetType.PosteriorContour:
            return isPosteriorContourOptionDisabled(option, selectionsContain);

        case LabsGqlGuidedWaxupPresetType.ContactDesign:
            return isContactDesignOptionDisabled(option, selectionsContain);

        case LabsGqlGuidedWaxupPresetType.MarginalRidge:
            return isMarginalRidgeOptionDisabled(option, selectionsContain);

        case LabsGqlGuidedWaxupPresetType.OcclusalAnatomy:
            return isOcclusalAnatomyOptionDisabled(option, selectionsContain);

        case LabsGqlGuidedWaxupPresetType.FacialAnatomy:
            return isFacialAnatomyOptionDisabled(option, selectionsContain);

        default:
            // these preset types don't use structured notes
            presetType satisfies LabsGqlGuidedWaxupPresetType.GeneralView | LabsGqlGuidedWaxupPresetType.ContourView;

            return false;
    }
};
