import { useMutation } from '@apollo/client';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { useHasCapability } from '@orthly/session-client';
import { QuickForm, RootActionDialog, useRootActionCommand } from '@orthly/ui';
import { Icon, IconButton } from '@orthly/ui-primitives';
import { useDoctorsForPartner } from '@orthly/veneer';
import React from 'react';

const AdminChangeDoctorActionLabSalesOrder_Fragment = graphql(`
    fragment AdminChangeDoctorActionLabSalesOrder_Fragment on LabSalesOrderDTO {
        id
        practice_id
        doctor_preferences_id
        action_eligibility {
            can_change_doctor
        }
    }
`);

const AdminChangeDoctorActionChangeLabSalesOrderDoctorPreferences_Mutation = graphql(`
    mutation AdminChangeDoctorActionChangeLabSalesOrderDoctorPreferences_Mutation(
        $args: LabSalesOrderChangeDoctorPreferencesInput!
    ) {
        changeLabSalesOrderDoctorPreferences(args: $args) {
            id
        }
    }
`);

interface ChangeDoctorActionProps {
    salesOrder: FragmentType<typeof AdminChangeDoctorActionLabSalesOrder_Fragment>;
    refetchSalesOrder: () => void;
}

export const ChangeDoctorAction: React.VFC<ChangeDoctorActionProps> = ({
    salesOrder: salesOrderProp,
    refetchSalesOrder,
}) => {
    const salesOrder = getFragmentData(AdminChangeDoctorActionLabSalesOrder_Fragment, salesOrderProp);

    const [open, setOpen] = React.useState<boolean>(false);

    const { preferences } = useDoctorsForPartner(salesOrder.practice_id, false);

    const changeDoctorMutation = useMutation(AdminChangeDoctorActionChangeLabSalesOrderDoctorPreferences_Mutation);
    const { submit, submitting } = useRootActionCommand(changeDoctorMutation, {
        onSuccess: () => {
            refetchSalesOrder();
            setOpen(false);
        },
        successMessage: 'Doctor Updated!',
    });

    const hasCapability = useHasCapability('order', 'order.configuration.doctor.edit');

    if (!salesOrder.action_eligibility.can_change_doctor || !hasCapability || preferences.length <= 1) {
        return null;
    }

    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Change Doctor'}
            content={
                <QuickForm<{ doctor_preferences_id: string }>
                    fields={{
                        doctor_preferences_id: {
                            type: 'select',
                            fieldProps: {
                                variant: 'standard',
                                placeholder: 'Select a Doctor',
                            },
                            options: preferences ? preferences.map(p => ({ label: p.name, value: p.id })) : [],
                        },
                    }}
                    initialValues={{ doctor_preferences_id: salesOrder.doctor_preferences_id }}
                    onSubmit={result => {
                        void submit({
                            args: {
                                sales_order_id: salesOrder.id,
                                doctor_preferences_id: result.doctor_preferences_id,
                            },
                        });
                    }}
                />
            }
            style={{ padding: 0 }}
            buttonText={'Change Doctor'}
            CustomButton={({ onClick }) => (
                <IconButton onClick={onClick} style={{ padding: 0 }}>
                    <Icon icon={'PencilOutlinedIcon'} />
                </IconButton>
            )}
        />
    );
};
