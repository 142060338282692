import { useToolbarOptionsContext } from '../../contexts/TableOptionsContext';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createStyles, makeStyles, Grid } from '@orthly/ui-primitives';
import classnames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';

const useStyles = makeStyles(
    () =>
        createStyles({
            datePickerWrapper: {
                display: 'flex',
                justifyContent: 'flex-end',
            },
            datePicker: {
                margin: '0 10px',
            },
        }),
    { name: 'MUITableDatePicker' },
);

export const MUITableDatePicker = () => {
    const classes = useStyles();
    const { startDate, endDate, showDates, handleDateChange, startLabel, endLabel } = useToolbarOptionsContext();
    if (showDates && !handleDateChange) {
        console.error('showDates=true provided to MUITableDatePicker but no handler provided');
        return null;
    }
    if (!showDates) {
        return null;
    }
    return (
        <Grid container direction={'column'} wrap={'wrap'}>
            <Grid container item xs={12} justifyContent={'flex-end'} wrap={'wrap'}>
                {showDates && handleDateChange && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            className={classnames(classes.datePicker, 'muiTableToolbar-datePicker-start')}
                            label={startLabel ? startLabel : 'Start Date'}
                            value={dayjs(startDate ?? null)}
                            onChange={dayDate => handleDateChange(true)(dayDate?.toDate())}
                            disableFuture={true}
                            maxDate={dayjs(endDate)}
                        />
                        <DesktopDatePicker
                            className={classnames(classes.datePicker, 'muiTableToolbar-datePicker-end')}
                            label={endLabel ? endLabel : 'End Date'}
                            value={dayjs(endDate ?? null)}
                            onChange={dayDate => handleDateChange(false)(dayDate?.toDate())}
                            minDate={dayjs(startDate)}
                            disableFuture={true}
                        />
                    </LocalizationProvider>
                )}
            </Grid>
        </Grid>
    );
};
