import { AssignTaskButton } from './TaskButtons/AssignTaskButton';
import type { LabsGqlWorkflowTaskFragment } from '@orthly/graphql-operations';
import type { ActionCardButtonPropsBase } from '@orthly/ui';
import { ActionCardButtonPrimary, ActionCardButtonSecondary } from '@orthly/ui';
import type { SvgIconProps } from '@orthly/ui-primitives';
import { FlossPalette, Text, stylesFactory, Divider, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    topActionsRoot: {
        width: 'auto',
        paddingRight: 4,
        flexGrow: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    buttonsRoot: {
        flexGrow: 1,
        justifyContent: 'center',
        alignContent: 'flex-end',
    },
    condensedIcon: {
        color: FlossPalette.GRAY,
        width: 16,
        height: 16,
    },
}));

export type CondensedActionCardVariant = 'sidebar' | 'detail_block';

export interface CondensedActionCardProps {
    title: string | React.ReactNode;
    assignee: string;
    IconComponent?: React.ComponentType<SvgIconProps>;
    primaryAction?: ActionCardButtonPropsBase;
    secondaryAction?: ActionCardButtonPropsBase;
    topAction?: React.ReactNode;
    subtitle?: React.ReactNode;
    task: LabsGqlWorkflowTaskFragment;
    refetch: () => Promise<any>;
    variant: CondensedActionCardVariant;
}

export const CondensedActionCard: React.FC<CondensedActionCardProps> = props => {
    const { title, secondaryAction, primaryAction, topAction, IconComponent, task, refetch, variant } = props;
    const isSidebarElement = variant === 'sidebar';
    const classes = useStyles();

    return (
        <Grid container style={{ flexGrow: 1 }} direction={'column'}>
            <Grid
                container
                style={{ padding: isSidebarElement ? '8px 16px 16px' : undefined, flexGrow: 1 }}
                direction={'column'}
            >
                <Grid container alignItems={'center'} style={{ flexWrap: 'nowrap', marginBottom: 4 }}>
                    {IconComponent && isSidebarElement && (
                        <Grid container alignItems={'center'} style={{ width: 'fit-content', marginRight: 4 }}>
                            <IconComponent className={classes.condensedIcon} />
                        </Grid>
                    )}
                    <Text variant={isSidebarElement ? 'body2' : 'body1'} style={{ fontWeight: 500 }}>
                        {title}
                    </Text>
                    <Grid container className={classes.topActionsRoot}>
                        {topAction}
                        <AssignTaskButton task={task} refetch={refetch} />
                        {task.assignee?.assigned_user.first_name}
                    </Grid>
                </Grid>
                {props.subtitle ?? null}
                {/* Buttons */}
                <Grid container item justifyContent={'space-between'} style={{ marginTop: 24 }}>
                    {primaryAction && (
                        <Grid item>
                            <ActionCardButtonPrimary {...primaryAction} cardVariant={variant} />
                        </Grid>
                    )}
                    {secondaryAction && (
                        <Grid item>{<ActionCardButtonSecondary {...secondaryAction} cardVariant={variant} />}</Grid>
                    )}
                </Grid>
            </Grid>
            {isSidebarElement && <Divider style={{ width: '100%' }} />}
        </Grid>
    );
};
