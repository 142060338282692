import * as ItemUtils from '../../../../../../components/PrintableSlip/utils/item.util';
import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import { LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';

export enum PuckTypeEnum {
    AZ = '(AZ) AiZir 25 mm Puck',
    HS = '(HS) Diamond HS Puck',
    ML = '(ML) Diamond ML Puck',
}

const { itemIsType, parseItems, getSingleToothUnits } = OrderItemV2Utils;
const { Crown, Bridge, Implant, Inlay } = LabOrderItemSKUType;

// Puck type requirement per this document
// https://docs.google.com/document/d/1flOHjLcA1Axr5DI3GQW1f3ZP78SzOU8Cb5XCWF5UXCw
export const getOrderPuckType = (order: LabsGqlLabOrderForLabSlipFragment): PuckTypeEnum | null => {
    const items = parseItems(order.items_v2);
    const typeSet = new Set<PuckTypeEnum>();
    for (const item of items) {
        const units = getSingleToothUnits(item);
        const puckHeight = ItemUtils.getPuckHeight(item);
        if (units.length === 0 || !puckHeight) {
            continue;
        }
        const isPFZ = units[0]?.material === 'Porcelain Fused Zirconia';
        const isAZ = puckHeight === 25 && (isPFZ || itemIsType(item, [Crown, Bridge, Inlay, Implant]));
        const isHeightBT12And20 = 12 <= puckHeight && puckHeight <= 20;
        const isHS = isHeightBT12And20 && ((itemIsType(item, Bridge) && units.length >= 4) || isPFZ);
        const isML =
            isHeightBT12And20 &&
            !isPFZ &&
            (itemIsType(item, [Inlay, Crown, Implant]) || (itemIsType(item, Bridge) && units.length <= 3));
        if (isAZ) {
            typeSet.add(PuckTypeEnum.AZ);
        }
        if (isHS) {
            typeSet.add(PuckTypeEnum.HS);
        }
        if (isML) {
            typeSet.add(PuckTypeEnum.ML);
        }
    }
    for (const priority of [PuckTypeEnum.AZ, PuckTypeEnum.HS, PuckTypeEnum.ML]) {
        if (typeSet.has(priority)) {
            return priority;
        }
    }
    return null;
};
