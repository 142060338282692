import type { LabsGqlPrepInBrowserSessionTimingData as SessionTimingData } from '@orthly/graphql-schema';

/**
 * Keeps track of the time spent in the editing phase of Dandy Finishing.
 *
 * We do not track loading times because it is expected that the user completed the Dandy Verified Design review before
 * advancing to editing, and the asset loading would have happened in the review phase.
 */
export class SessionTimer {
    static readonly IDLE_TIMEOUT_MS = 30 * 1000;

    private startTimeMs: number | null = null;
    private submitTimeMs: number | null = null;

    private idleTimeoutId: number | null = null;
    private onUserActivity: () => void;

    private lastCommitedTimeMs: number = 0;
    private isIdle: boolean = false;
    private idleDurationMs: number = 0;
    private activeDurationMs: number = 0;

    constructor() {
        // We use an arrow function here to avoid having to bind it when adding it as an event listener.
        this.onUserActivity = () => {
            this.commitTime();
            this.isIdle = false;

            this.setIdleTimeout();
        };
    }

    /**
     * Starts the session timer.
     */
    start(): void {
        if (this.startTimeMs !== null) {
            throw new Error('SessionTimer already started');
        }

        this.startTimeMs = Date.now();
        this.lastCommitedTimeMs = this.startTimeMs;

        this.setIdleTimeout();
        window.addEventListener('click', this.onUserActivity);
    }

    /**
     * Stops idle tracking and records the beginning of the asset submission phase
     */
    handleSubmit(): void {
        if (this.startTimeMs === null) {
            throw new Error('SessionTimer not started');
        }

        if (this.submitTimeMs !== null) {
            return;
        }

        window.removeEventListener('click', this.onUserActivity);
        this.clearIdleTimeout();

        this.submitTimeMs = this.commitTime();
    }

    /**
     * Stops the session timer
     * @returns The session timing results
     */
    stop(): SessionTimingData {
        if (this.startTimeMs === null || this.submitTimeMs === null) {
            throw new Error('Submission start not recorded');
        }

        return {
            start_time_ms: this.startTimeMs,
            submit_time_ms: this.submitTimeMs,
            asset_upload_time_ms: Date.now(),
            sections: [
                {
                    section: 'idle',
                    duration_ms: this.idleDurationMs,
                },
                {
                    section: 'anatomy_design_step',
                    duration_ms: this.activeDurationMs,
                },
            ],
        };
    }

    private commitTime(isIdle?: boolean): number {
        const commitedTimeMs = Date.now();
        const durationMs = commitedTimeMs - this.lastCommitedTimeMs;

        if (isIdle ?? this.isIdle) {
            this.idleDurationMs += durationMs;
        } else {
            this.activeDurationMs += durationMs;
        }

        this.lastCommitedTimeMs = commitedTimeMs;

        return commitedTimeMs;
    }

    private setIdleTimeout(): void {
        this.clearIdleTimeout();

        const onUserIdle = () => {
            this.commitTime(true);
            this.isIdle = true;
        };

        this.idleTimeoutId = window.setTimeout(onUserIdle, SessionTimer.IDLE_TIMEOUT_MS);
    }

    private clearIdleTimeout(): void {
        if (this.idleTimeoutId !== null) {
            window.clearTimeout(this.idleTimeoutId);
            this.idleTimeoutId = null;
        }
    }
}
