import { useTableData } from '../../contexts/TableDataContext';
import { useDisplayOptionsContext, useRowOptionsContext } from '../../contexts/TableOptionsContext';
import { useMuiTableSelector } from '../../state';
import { useTableStateAction } from '../../state/action-hooks';
import type { Row } from '../../types';
import type { Theme } from '@orthly/ui-primitives';
import { createStyles, makeStyles, CheckboxPrimitive as Checkbox, TableCell, Icon } from '@orthly/ui-primitives';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            root: {
                [theme.breakpoints.down('md')]: {
                    display: 'none',
                },
            },
            fixedHeader: {
                position: 'sticky' as 'sticky',
                top: '0px',
                left: '0px',
                zIndex: 100,
                backgroundColor: theme.palette.background.paper,
                border: 'none',
                '&::after': {
                    content: `""`,
                    zIndex: 400,
                    position: 'absolute' as 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    borderBottom: `1px solid ${theme.palette.divider}`,
                },
            },
            icon: {
                cursor: 'pointer',
                transition: 'transform 0.25s',
            },
            expanded: {
                transform: 'rotate(90deg)',
            },
            hide: {
                visibility: 'hidden',
            },
            headerCell: {
                backgroundColor: theme.palette.background.paper,
            },
            checkboxRoot: {
                '&.Mui-checked': {
                    color: theme.palette.primary.main,
                },
            },
            checked: {},
            disabled: {},
        }),
    { name: 'MUIDataTableSelectCell' },
);

interface MUITableSelectCellBaseProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    type: 'body' | 'header' | 'fixedHeader';
    testId?: string;
    style?: React.CSSProperties;
}

export const MUITableSelectCellBase: React.FC<MUITableSelectCellBaseProps> = props => {
    const classes = useStyles();
    const { type, onChange, checked, testId } = props;
    const cellClass = classNames({
        [classes.root]: true,
        [classes.fixedHeader]: type === 'fixedHeader',
        [classes.headerCell]: type === 'fixedHeader' || type === 'header',
    });
    return (
        <TableCell className={cellClass} padding={'checkbox'} style={props.style}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                    color={'secondary'}
                    checkedIcon={<Icon icon={'CheckBoxSharp'} />}
                    icon={<Icon icon={'CheckBoxOutlineBlankSharp'} />}
                    data-testid={testId}
                    classes={{
                        root: classes.checkboxRoot,
                        checked: classes.checked,
                        disabled: classes.disabled,
                    }}
                    checked={checked}
                    onChange={(_event, checkedNew) => onChange(checkedNew)}
                />
            </div>
        </TableCell>
    );
};

export interface MUITableSelectCellProps {
    checked: boolean;
    isHeaderCell: boolean;
    row?: Row<any>;
    testId?: string;
}

export const MUITableSelectCell = (props: MUITableSelectCellProps) => {
    const { isHeaderCell, checked, row, testId } = props;
    const toggleRowSelected = useTableStateAction('toggleRowSelected');
    const handleAllSelect = useTableStateAction('handleAllSelect');
    const displayOpts = useDisplayOptionsContext();
    const rowOpts = useRowOptionsContext();
    const selectedRows = useMuiTableSelector(s => s.selectedRows);
    const { displayRows } = useTableData();
    const onChange = () => {
        if (isHeaderCell) {
            return handleAllSelect(selectedRows.length === 0 ? displayRows.map(r => r.row.id) : []);
        }
        if (row) {
            toggleRowSelected(row);
        }
    };
    if (!rowOpts.selectable) {
        return null;
    }
    return (
        <MUITableSelectCellBase
            checked={checked}
            onChange={() => onChange()}
            // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
            // eslint-disable-next-line no-nested-ternary
            type={displayOpts.fixedHeader && isHeaderCell ? 'fixedHeader' : isHeaderCell ? 'header' : 'body'}
            testId={testId}
        />
    );
};
