import { useCaseMetadata } from '../../AppState.hooks';
import { ToolsToolbar } from '../ToolsToolbar';
import type { ToolsToolbarProps } from '../ToolsToolbar/Types';
import { ExpandedViewToolbars } from './ExpandedViewToolbars';
import { TYPE_TO_TITLE } from './ReviewPanel.utils';
import { ReviewTypeToolbar } from './ReviewTypeToolbar';
import { ViewControlButtons } from './ViewControlButtons';
import { SceneViewer, ModelViewerControls } from '@orthly/dentin';
import type { DesignFinishingReviewApp, DesignFinishingReviewType } from '@orthly/dentin';
import { StackX } from '@orthly/ui';
import type { BoxProps } from '@orthly/ui-primitives';
import { FlossPalette, stylesFactory, Box, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        height: '100%',
        flex: 'auto',
        // This is required to get the element to shrink properly. It seems like it may be some interaction with the
        // ModelViewerCanvas component.
        overflow: 'hidden',
        transform: 'translate(0, 0)',
        borderRadius: 8,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        backgroundColor: FlossPalette.WHITE,
    },
    canvasRoot: {
        height: '100%',
        flex: 'auto',
        // This is required to get the element to shrink properly. It seems like it may be some interaction with the
        // ModelViewerCanvas component.
        overflow: 'hidden',
        // This is a hack to get the bottom toolbar in MainView to be positioned with respect to that root element.
        // Otherwise, the toolbar is centered with respect to the whole screen due to nuances in how the containing
        // block is selected.
        transform: 'translate(0, 0)',
    },
    title: {
        position: 'absolute',
        top: 16,
        left: 16,
        zIndex: 10,
    },
    reviewTypeToolbar: {
        position: 'absolute',
        top: 8,
        left: 8,
        zIndex: 10,
    },
    viewControlButtons: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: 48,
        paddingLeft: 12,
    },
    bottomToolbars: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: 48,
        width: '100%',
        justifyContent: 'center',
    },
}));

type ReviewPanelProps = BoxProps & {
    type: DesignFinishingReviewType;
    setType: React.Dispatch<React.SetStateAction<DesignFinishingReviewType | null>>;
    expanded?: boolean;
    app: DesignFinishingReviewApp;
};

export const ReviewPanel: React.VFC<ReviewPanelProps> = ({ type, setType, expanded, app, ...boxProps }) => {
    const classes = useStyles();

    const caseMetaData = useCaseMetadata();

    const tools: ToolsToolbarProps['tools'] = {
        collisions: {
            onClick: () => app.sceneState.manager.toggleCollisionsVisibility(),
            active: app.sceneState.appearance.collisionsVisible,
        },
        collisionsCurtains: app.sceneState.appearance.collisionsVisible
            ? {
                  onClick: () => app.sceneState.manager.toggleCollisionsCurtainsVisibility(),
                  active: app.sceneState.appearance.collisionsCurtainsVisible,
              }
            : undefined,
        curtains: app.sceneState.manager.toggleCurtainsVisibility
            ? {
                  onClick: () => app.sceneState.manager.toggleCurtainsVisibility?.(),
                  active: app.sceneState.appearance.curtainsVisible,
              }
            : undefined,
        crossSection: {
            onClick: () => app.crossSection.appActive.toggle(false),
            active: app.crossSection.appActive.open,
        },
        insertionPaths: app.sceneState.manager.toggleInsertionPathsVisibility
            ? {
                  onClick: () => app.sceneState.manager.toggleInsertionPathsVisibility?.(),
                  active: app.sceneState.appearance.insertionPathsVisible,
              }
            : undefined,
        thicknessHeatmap: app.sceneState.manager.toggleThicknessHeatmapEnabled
            ? {
                  onClick: () => app.sceneState.manager.toggleThicknessHeatmapEnabled?.(),
                  active: app.sceneState.appearance.thicknessHeatmapEnabled,
              }
            : undefined,
        proximalHeatmap: app.sceneState.manager.toggleProximalHeatmapEnabled
            ? {
                  onClick: () => app.sceneState.manager.toggleProximalHeatmapEnabled?.(),
                  active: app.sceneState.appearance.proximalHeatmapEnabled,
              }
            : undefined,
        occlusalHeatmap: app.sceneState.manager.toggleOcclusalHeatmapEnabled
            ? {
                  onClick: () => app.sceneState.manager.toggleOcclusalHeatmapEnabled?.(),
                  active: app.sceneState.appearance.occlusalHeatmapEnabled,
              }
            : undefined,
        undercutHeatmap: app.sceneState.manager.toggleUndercutHeatmapEnabled
            ? {
                  onClick: () => app.sceneState.manager.toggleUndercutHeatmapEnabled?.(),
                  active: app.sceneState.appearance.undercutHeatmapEnabled,
              }
            : undefined,
        prePrepScansVisibility: app.sceneState.manager.togglePrePrepScanVisibility
            ? {
                  onClick: () => app.sceneState.manager.togglePrePrepScanVisibility?.(),
                  active: !!app.sceneState.appearance.prePrepScansVisible,
                  disabled: !caseMetaData.hasPrePrepScans,
              }
            : undefined,
        antagonistScansVisibility:
            app.sceneState.manager.toggleAntagonistScanVisibility && caseMetaData.restorationJaw !== 'both'
                ? {
                      onClick: () => app.sceneState.manager.toggleAntagonistScanVisibility?.(),
                      active: !!app.sceneState.appearance.antagonistScansVisible,
                  }
                : undefined,
        scanUndercut: app.sceneState.manager.toggleScanUndercutEnabled
            ? {
                  onClick: () => app.sceneState.manager.toggleScanUndercutEnabled?.(),
                  active: app.sceneState.appearance.scanUndercutEnabled,
              }
            : undefined,
        restorativesVisibility: app.sceneState.manager.toggleRestorativesVisibility
            ? {
                  onClick: () => app.sceneState.manager.toggleRestorativesVisibility?.(),
                  active: app.sceneState.appearance.restorativesVisible,
              }
            : undefined,
    };

    const title = TYPE_TO_TITLE[type];

    const expandView = React.useCallback(() => {
        setType(type);
    }, [type, setType]);

    const minimizeView = React.useCallback(() => {
        setType(null);
    }, [setType]);

    return (
        // @ts-expect-error - We have to override BoxProps
        <StackX className={classes.root} {...boxProps}>
            <Box className={classes.canvasRoot}>
                {expanded ? (
                    <ReviewTypeToolbar
                        className={classes.reviewTypeToolbar}
                        currentType={type}
                        setCurrentType={setType}
                    />
                ) : (
                    <Text className={classes.title} variant={'caption'} medium>
                        {title}
                    </Text>
                )}
                <SceneViewer scene={app.sceneState.manager.scene}>
                    <ModelViewerControls ref={app.initCameraControls} use3ShapeViewerControls={true} />
                    {app.canvasChildren}
                </SceneViewer>
                {app.canvasSiblings}
                {expanded ? (
                    <ExpandedViewToolbars
                        className={classes.bottomToolbars}
                        viewManager={app.viewManager}
                        resetCamera={app.resetCamera}
                        viewExpanded={expanded}
                        expandView={expandView}
                        minimizeView={minimizeView}
                    />
                ) : (
                    <StackX className={classes.viewControlButtons}>
                        <ViewControlButtons
                            resetCamera={app.resetCamera}
                            viewExpanded={expanded}
                            expandView={expandView}
                            minimizeView={minimizeView}
                        />
                    </StackX>
                )}
            </Box>
            <ToolsToolbar tools={tools} />
        </StackX>
    );
};
