import { InvoicesDetailPanel } from '../InvoicesTable/InvoicesDetailPanel.graphql';
import { LegacyPartnerCreditsTable } from '../LegacyPartnerCreditsTable.graphql';
import { PracticeContractV2Table } from '../PracticeContracts/PracticeContractV2Table.graphql';
import type { BillingDetailsProps } from '../types';
import { OrganizationOverview, OVERVIEW_CONTAINER_GAP, OVERVIEW_CONTAINER_WIDTH } from './OrganizationOverview';
import { useBillingDetailsContext } from './providers/BillingDetailsProvider.graphql';
import { ArrowEastIcon, ArrowWestIcon, Box, IconButton, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const CARD_WIDTH = OVERVIEW_CONTAINER_WIDTH + OVERVIEW_CONTAINER_GAP;

const OverviewContainer = styled('div', { shouldForwardProp: p => p !== 'idx' })<{ idx: number }>(({ idx }) => ({
    width: '100%',
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'row',
    marginBottom: '16px',
    transition: 'transform 0.5s ease-in-out',
    transform: `translateX(-${idx * CARD_WIDTH}px)`,
}));

const PaginationContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    marginBottom: '16px',
});

export const ExpansionPanelStyle: React.CSSProperties = {
    borderRadius: '16px',
    padding: '24px',
};

export const ExpansionPanelSummaryStyle: React.CSSProperties = {
    height: '24px',
    minHeight: '24px',
    padding: '0px',
};

export const ExpansionPanelDetailsStyle: React.CSSProperties = {
    marginTop: '24px',
    padding: '0px',
};

const TitleText = styled(Text)({
    marginBottom: '24px',
});

export const BillingDetailPanel: React.FC<BillingDetailsProps> = ({ practiceId }) => {
    const {
        associatedOrganizations,
        primaryPracticeForContract,
        practice,
        practiceLoading,
        contractsLoading,
        doesNotHaveActiveContract,
    } = useBillingDetailsContext();

    // make sure the primary is first in the carousel
    const allOrganizations = associatedOrganizations.sort((a, b) => {
        if (a.id === primaryPracticeForContract) {
            return -1;
        }
        if (b.id === primaryPracticeForContract) {
            return 1;
        }
        return 0;
    });

    const [currentIndex, setCurrentIndex] = React.useState(0);

    const handleNextClick = () => {
        setCurrentIndex((currentIndex + 1) % associatedOrganizations.length);
    };

    const handlePrevClick = () => {
        setCurrentIndex((currentIndex - 1 + associatedOrganizations.length) % associatedOrganizations.length);
    };

    const practiceOrContractsLoading = practiceLoading || contractsLoading;

    return (
        <>
            <div>
                <TitleText variant={'h5'}>{practice?.name} Overview</TitleText>
                {practiceOrContractsLoading && 'Loading...'}
                {doesNotHaveActiveContract && 'No active contracts found for practice.'}
                {primaryPracticeForContract === practiceId &&
                    !practiceOrContractsLoading &&
                    !!associatedOrganizations?.length && (
                        <TitleText variant={'body1'}>Locations ({associatedOrganizations?.length})</TitleText>
                    )}
            </div>
            <OverviewContainer idx={currentIndex}>
                {allOrganizations.map(org => (
                    <OrganizationOverview key={org.id} organization={org} />
                ))}
            </OverviewContainer>
            <PaginationContainer>
                {associatedOrganizations?.length > 1 && (
                    <>
                        <IconButton size={'small'} onClick={handlePrevClick}>
                            <ArrowWestIcon />
                        </IconButton>
                        <IconButton size={'small'} onClick={handleNextClick}>
                            <ArrowEastIcon />
                        </IconButton>
                    </>
                )}
            </PaginationContainer>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <PracticeContractV2Table practiceId={practiceId} />
                <LegacyPartnerCreditsTable practiceId={practiceId} />
                {/* TODO: EPDB-953 invoices */}
                <InvoicesDetailPanel practiceId={practiceId} />
            </Box>
        </>
    );
};
