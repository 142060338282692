import { BooleanIcon } from '../../../components/BooleanIcon';
import { PrintTable } from '../../../components/PrintTable';
import type { SourceSplitOption } from '../actions/EditPaymentSplitConfig/usePartnerPaymentSplitSourceOptions.graphql';
import type { InvoicePayment } from './InvoiceTable.types';
import { type Invoice, type InvoiceRow } from './InvoiceTable.types';
import { Format } from '@orthly/runtime-utils';
import { Icon } from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

interface InvoicePaymentErrorsProps {
    invoice: InvoiceRow<Invoice>;
    sourceOptions: SourceSplitOption[];
    style?: React.CSSProperties;
    displayEmpty?: boolean;
}

function getPaymentSourceType(row: Omit<InvoicePayment, '__typename'>, sourceOptions: SourceSplitOption[]): string {
    const source = sourceOptions.find(_.matches({ id: row.stripe_payment_source_id }));
    if (source) {
        return source.label;
    } else if (isManualCheckPayment(row.stripe_charge_id)) {
        return 'Manual Check';
    } else if (isManualACHPayment(row.stripe_charge_id)) {
        return 'Manual ACH';
    }
    return 'Unknown';
}

function isManualCheckPayment(chargeId?: string | null): boolean {
    if (!chargeId) {
        return false;
    }
    return chargeId.startsWith('ch_MANUAL_CHECK');
}

function isManualACHPayment(chargeId?: string | null): boolean {
    if (!chargeId) {
        return false;
    }
    return chargeId.startsWith('ch_ach') && chargeId.endsWith('_xxxxxxxxxxxxxx');
}

export const InvoicePaymentLog: React.FC<InvoicePaymentErrorsProps> = ({
    invoice,
    sourceOptions,
    displayEmpty,
    style,
}) => {
    const rows = React.useMemo(() => {
        const mergedLogs = invoice.payments;
        const rawLogs = _.sortBy(mergedLogs, r => new Date(r.created_at).valueOf());
        return rawLogs.flatMap(row => {
            const allocationIds = new Set(
                row.allocations
                    .filter(allocation => allocation.invoice_id === invoice.id)
                    .map(allocation => allocation.id),
            );
            const refunds = row.refunds.filter(refund => allocationIds.has(refund.payment_allocation_id ?? ''));
            const refundTotal = _.sumBy(refunds, r => r.amount_cents) ?? 0;
            const baseRow = {
                success:
                    row.status === 'pending' ? (
                        <Icon icon={'HourglassEmpty'} />
                    ) : (
                        <BooleanIcon val={!row.error_summary} />
                    ),
                date: moment(row.created_at).format('MM/DD/YY h:mm a'),
                source: getPaymentSourceType(row, sourceOptions),
                amount: Format.currency(row.amount_cents + refundTotal),
                error: row.error_summary,
            };
            const refundRows = refunds.map(refund => ({
                success: <Icon icon={'RestorePage'} />,
                date: moment(refund.created_at).format('MM/DD/YY h:mm a'),
                source: `REFUND to ${baseRow.source}`,
                amount: `-${Format.currency(refund.amount_cents)}`,
            }));
            return [baseRow, ...refundRows];
        });
    }, [invoice, sourceOptions]);
    return <PrintTable title={'Payments Log'} rootStyle={style} rows={rows} displayEmpty={displayEmpty} />;
};
