import { PrintTable } from '../../components/PrintTable';
import { SimpleExpansionPanel } from '../../components/SimpleExpansionPanel';
import { EditPriceName, EditPriceSalesforceMapping } from './EditPriceName.graphql';
import { PartnerConfigTable } from './PartnerConfigTable';
import { AddPriceApplicationRule, RemovePriceApplicationRule, UpsertPartnerPriceConfig } from './PricingForms.graphql';
import type { ConfigRow } from './PricingRoot.types';
import { type PricingOverviewTableRow } from './PricingRoot.types';
import { useUpdatedPricingQuery } from './hooks/useUpdatedPricingQuery.graphql';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useDeletePriceMutation } from '@orthly/graphql-react';
import { LoadBlocker } from '@orthly/ui';
import { Button, styled } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

interface PricingDetailPanelProps {
    data: PricingOverviewTableRow;
}

const DeletePriceBtn: React.FC<PricingDetailPanelProps> = ({ data }) => {
    const { value: disableContractEdits = false } = useFeatureFlag('disableContractEdits');

    // refetch all prices on delete
    const { refetchPrices } = useUpdatedPricingQuery();
    const { name, id, partner_prices_count } = data;
    const [trigger, mtnState] = useDeletePriceMutation({ variables: { priceId: id } });
    const onClick = React.useCallback(async () => {
        const partnerCount = `There are currently ${partner_prices_count} partners with a configured price`;
        if (!mtnState.loading && window.confirm(`Delete price ${name}? ${partnerCount}`)) {
            await trigger();
            await refetchPrices();
        }
    }, [mtnState.loading, name, partner_prices_count, refetchPrices, trigger]);

    return (
        <LoadBlocker blocking={mtnState.loading}>
            <Button
                startIcon={'DeleteIcon'}
                onClick={onClick}
                variant={'alert'}
                fullWidth
                disabled={disableContractEdits}
            >
                Delete Price
            </Button>
        </LoadBlocker>
    );
};

const Container = styled('div')({
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    padding: '20px',
    maxWidth: 'calc(100vw - 73px)',
});

const ActionsContainer = styled('div')({
    paddingLeft: '20px',
});

const GetPriceWithPracticeDetails_Query = graphql(`
    query GetPriceWithPracticeDetails($priceId: String) {
        listPrices(priceId: $priceId) {
            id
            partner_prices {
                partner_id
                price_cents
                from_preset
            }
        }
    }
`);

export const PricingDetailPanel: React.FC<PricingDetailPanelProps> = ({ data }) => {
    const { value: disableContractEdits = false } = useFeatureFlag('disableContractEdits');
    const {
        data: { listPrices: priceDetailsRaw = [] } = {},
        loading,
        refetch,
    } = useQuery(GetPriceWithPracticeDetails_Query, {
        variables: { priceId: data.id },
    });
    const { getPracticeNameById } = useUpdatedPricingQuery();

    const partnerPricesWithNames = React.useMemo(() => {
        return (priceDetailsRaw[0]?.partner_prices ?? []).map<ConfigRow>(p => ({
            ...p,
            name: getPracticeNameById(p.partner_id),
        }));
    }, [priceDetailsRaw, getPracticeNameById]);

    const priceWithPracticePriceDetails = priceDetailsRaw[0];

    return (
        <LoadBlocker blocking={loading}>
            <Container>
                <div>
                    <SimpleExpansionPanel title={'Partner Configs'}>
                        <PartnerConfigTable
                            rows={partnerPricesWithNames}
                            refetch={refetch}
                            priceId={data.id}
                            priceName={data.name}
                        />
                    </SimpleExpansionPanel>
                    <PrintTable
                        rootStyle={{ margin: '10px 0' }}
                        title={'Applies If'}
                        rows={data.application_rules.map(p => ({
                            unit_type: p.unit_type,
                            material: p.material_type || 'ANY',
                            remove: (
                                <RemovePriceApplicationRule
                                    data={{
                                        material_type: p.material_type,
                                        unit_type: p.unit_type,
                                        price_id: data.id,
                                    }}
                                />
                            ),
                        }))}
                    />
                </div>
                <ActionsContainer>
                    <div>
                        <EditPriceName price={data} />
                    </div>
                    <div>
                        <EditPriceSalesforceMapping price={data} />
                    </div>
                    {priceWithPracticePriceDetails && !disableContractEdits && (
                        <div>
                            <UpsertPartnerPriceConfig price={priceWithPracticePriceDetails} refetch={refetch} />
                        </div>
                    )}
                    <div>{!disableContractEdits && <AddPriceApplicationRule priceId={data.id} />}</div>
                    <div>
                        <DeletePriceBtn data={data} />
                    </div>
                </ActionsContainer>
            </Container>
        </LoadBlocker>
    );
};
