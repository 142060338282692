import type { ItemEditorV2BaseProps } from '../../../types/ItemEditorV2BaseProps';
import { useAutoSetItemDataFieldDefaultValue } from './ItemEditorV2ItemDataField.util';
import type { NumberSelectItemDataField, ItemDataFieldId, LabOrderItemSKUType } from '@orthly/items';
import { ItemDataFieldUtils } from '@orthly/items';
import { SimpleSelect } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

interface ItemEditorV2NumberSelectItemDataFieldProps<ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>
    extends ItemEditorV2BaseProps<SKU> {
    field: NumberSelectItemDataField<ID, SKU>;
}

export const ItemEditorV2NumberSelectItemDataField = <ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>({
    field,
    item,
    updateItem,
    showError,
}: ItemEditorV2NumberSelectItemDataFieldProps<ID, SKU>): React.ReactElement => {
    const value = field.getValue(item);

    const applyUpdate = (val: string | undefined) => {
        const numberVal = val ? _.toNumber(val) : null;
        updateItem(field.getUpdatePayload(numberVal, item));
    };

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    return (
        <SimpleSelect
            label={field.label}
            value={!_.isNil(value) ? `${value}` : undefined}
            options={field.getOptions(item).map(o => ({ ...o, value: `${o.value}` }))}
            onChange={val => applyUpdate(val)}
            errorText={
                showError && ItemDataFieldUtils.isItemDataFieldRequired(field, item) && !field.isComplete(item)
                    ? 'This field is required'
                    : undefined
            }
        />
    );
};
