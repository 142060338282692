import type { ToolState, UndoState } from './CanvasController';
import { CanvasController } from './CanvasController';
import { CANVAS_SCALE } from './Util';
import React from 'react';

type BoundCanvasProps = {
    controller: CanvasController;
} & React.CanvasHTMLAttributes<HTMLCanvasElement>;

const TOUCH_EVENTS = ['touchstart', 'touchmove', 'touchend', 'touchcancel'] as const;

function BoundCanvas(props: BoundCanvasProps): React.ReactElement<HTMLCanvasElement> {
    const { controller } = props;
    const canvasRef = React.useRef<HTMLCanvasElement>(null);

    React.useEffect(() => {
        const canvas = canvasRef.current;

        const preventDefault = (evt: TouchEvent) => evt.preventDefault();

        if (canvas) {
            controller.setCanvas(canvas);

            /**
             * Using these event listeners to prevent weird scrolling issues on mobile with the canvas.
             * Followed the advice from: https://stackoverflow.com/questions/49047414/disable-scroll-swipe-action-for-html-canvas-drawing-on-ios?rq=1
             *
             * This allows mobile users to properly use the canvas as expected instead of trying to scroll the page
             * when drawing a line for instance.
             */
            TOUCH_EVENTS.map(name => canvas.addEventListener(name, preventDefault));
        }

        return () => {
            TOUCH_EVENTS.map(name => canvas?.removeEventListener(name, preventDefault));
        };
    }, [controller]);

    return <canvas {...props} ref={canvasRef} tabIndex={1} />;
}

function useToolState(controller: CanvasController): ToolState {
    const [toolState, setToolState] = React.useState<ToolState>(controller.getToolState());
    React.useEffect(() => {
        controller.onToolStateChange = setToolState;
    }, [controller, setToolState]);
    return toolState;
}

function useUndoState(controller: CanvasController): UndoState {
    const [undoState, setUndoState] = React.useState<UndoState>(controller.undoState());
    React.useEffect(() => {
        controller.onUndoStateChange = setUndoState;
    }, [controller, setUndoState]);
    return undoState;
}

/**
 * Hook for using a canvas that supports annotation via a set of drawing tools.
 *
 * @param width The canvas width
 * @param height The canvas height
 * @param initialToolState The initial tool state for the canvas.
 * @param backgroundImageUrl If set, the canvas draws the specified image in its background
 * @param backgroundColor If set, the canvas uses the specified background color. Otherwise, white.
 * @param canvasProps Extra properties for the canvas element.
 */
export function useDrawingCanvas(args: {
    width: number;
    height: number;
    initialToolState?: ToolState;
    backgroundImageUrl?: string;
    backgroundColor?: string;
    canvasProps?: React.CanvasHTMLAttributes<HTMLCanvasElement>;
    backgroundImageSizeCallback: (width: number, height: number) => void;
}) {
    const {
        canvasProps,
        width,
        height,
        initialToolState,
        backgroundImageUrl,
        backgroundColor,
        backgroundImageSizeCallback,
    } = args;
    const controllerRef = React.useRef(new CanvasController(initialToolState));
    const controller = controllerRef.current;
    const toolState = useToolState(controller);
    const undoState = useUndoState(controller);

    React.useEffect(() => {
        if (backgroundImageUrl) {
            controller.setBackgroundImage(backgroundImageUrl, backgroundImageSizeCallback);
        }
    }, [controller, backgroundImageUrl, backgroundImageSizeCallback]);

    React.useEffect(() => {
        if (backgroundColor) {
            controller.setBackgroundColor(backgroundColor);
        }
    }, [controller, backgroundColor]);

    const functions = React.useMemo(
        () => ({
            setToolState: controller.setToolState.bind(controller),
            undo: controller.undo.bind(controller),
            redo: controller.redo.bind(controller),
            takeSnapshot: controller.toBlob.bind(controller),
            clear: controller.clear.bind(controller),
            commitTextObject: controller.commitTextObject.bind(controller),
        }),
        [controller],
    );

    return {
        toolState,
        undoState,
        canvas: (
            <BoundCanvas
                {...canvasProps}
                width={width * CANVAS_SCALE}
                height={height * CANVAS_SCALE}
                style={{ ...canvasProps?.style, height, width, outline: 'none' }}
                controller={controller}
            />
        ),
        ...functions,
    };
}
