import type { ChoiceData } from '../../../utils/useTicketActionHooks';
import { SelectActiveStatus } from './ticket_filters/SelectActiveStatus';
import { SelectIDs } from './ticket_filters/SelectIDs';
import { SelectOpenStatus } from './ticket_filters/SelectOpenStatus';
import type { LabsGqlTicketsFilters } from '@orthly/graphql-schema';
import { ToolbarContainer, LoadBlocker } from '@orthly/ui';
import React from 'react';

export type FiltersInputProps = {
    filters: LabsGqlTicketsFilters;
    setFilters: (filters: LabsGqlTicketsFilters) => void;
    choiceData?: ChoiceData;
};

const toolbarContainer = { padding: '0px', display: 'flex', flexFlow: 'column nowrap' };
const toolbarSelectorContainer = {
    padding: '0px',
    display: 'flex',
    alignItems: 'flex-start',
    flexFlow: 'row nowrap',
    width: '100%',
};

export const TicketFiltersInput: React.FC<FiltersInputProps> = props => {
    return (
        <ToolbarContainer sticky style={toolbarContainer}>
            <LoadBlocker loader={'linear'} blocking={typeof props.choiceData === 'undefined'} />
            <div style={toolbarSelectorContainer}>
                <SelectOpenStatus
                    isOpen={props.filters.is_open ?? undefined}
                    setIsOpen={is_open => props.setFilters({ ...props.filters, is_open })}
                />
                <SelectActiveStatus
                    activeStatus={props.filters.active_action_is_workable_now ?? undefined}
                    setActiveStatus={active_action_is_workable_now =>
                        props.setFilters({ ...props.filters, active_action_is_workable_now })
                    }
                />
                <SelectIDs
                    label={'Assigned User'}
                    options={props.choiceData?.users}
                    selectIDs={assigned_user_ids => props.setFilters({ ...props.filters, assigned_user_ids })}
                    selectedIDs={props.filters.assigned_user_ids ?? undefined}
                />
                <SelectIDs
                    label={'Order Tags'}
                    options={props.choiceData?.tags}
                    selectIDs={order_tag_ids => props.setFilters({ ...props.filters, order_tag_ids })}
                    selectedIDs={props.filters.order_tag_ids ?? undefined}
                />
                <SelectIDs
                    label={'Action Type'}
                    options={props.choiceData?.actionTypes}
                    selectIDs={action_type_ids => props.setFilters({ ...props.filters, action_type_ids })}
                    selectedIDs={props.filters.action_type_ids ?? undefined}
                />
                <SelectIDs
                    label={'Practice'}
                    options={props.choiceData?.practices}
                    selectIDs={practice_ids => props.setFilters({ ...props.filters, practice_ids })}
                    selectedIDs={props.filters.practice_ids ?? undefined}
                />
                <SelectIDs
                    label={'Manufacturer'}
                    options={props.choiceData?.manufacturers}
                    selectIDs={manufacturer_ids => props.setFilters({ ...props.filters, manufacturer_ids })}
                    selectedIDs={props.filters.manufacturer_ids ?? undefined}
                />
            </div>
        </ToolbarContainer>
    );
};
