import type { FullScreenWorkflowContainerProps } from '../../FullScreenWorkflowContainer/FullScreenWorkflowContainer';
import { FullScreenWorkflowContainer } from '../../FullScreenWorkflowContainer/FullScreenWorkflowContainer';
import { RefabFlowOrderDetails } from '../../RefabFlowOrderDetails';
import { useRefabFlowContext } from '../screens/refab-flow-context';
import { useRefabOnBack, useRefabOnNext } from '../utils';
import { RootActionDialog } from '@orthly/ui';
import { Text, useScreenIsMobile, styled } from '@orthly/ui-primitives';
import React from 'react';

const TitleContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
});

interface RefabBodyContainerProps extends FullScreenWorkflowContainerProps {
    hideOrderDetails?: boolean;
}

export const RefabBodyContainer: React.VFC<RefabBodyContainerProps> = props => {
    const { order, doctorPreferences } = useRefabFlowContext();
    const { children, title, hideOrderDetails = false, ...restProps } = props;
    const isMobile = useScreenIsMobile();
    const [isOrderDetailsOpen, setIsOrderDetailsOpen] = React.useState(false);
    const onBack = useRefabOnBack();
    const onNext = useRefabOnNext();

    if (!order) {
        return null;
    }
    return (
        <FullScreenWorkflowContainer
            title={
                <TitleContainer>
                    <Text variant={isMobile ? 'h5' : 'h3'} medium>
                        {title}
                    </Text>
                    {!hideOrderDetails && (
                        <RootActionDialog
                            loading={false}
                            open={isOrderDetailsOpen}
                            setOpen={setIsOrderDetailsOpen}
                            title={'Order Summary'}
                            content={
                                <RefabFlowOrderDetails
                                    order={order}
                                    doctorCustomFieldPreferences={doctorPreferences?.custom_field_preferences ?? []}
                                />
                            }
                            showCloseButton
                            onClose={() => setIsOrderDetailsOpen(false)}
                            buttonText={'View order details'}
                            buttonProps={{ fullWidth: false }}
                        />
                    )}
                </TitleContainer>
            }
            onBack={onBack}
            onNext={onNext}
            {...restProps}
        >
            {children}
        </FullScreenWorkflowContainer>
    );
};
