import { RefabBodyContainer } from '../components/RefabBodyContainer';
import { RequestShadeChanges } from '../components/RequestShadeChanges';
import { useRefabFlowSelector } from '../state/refab-flow-state';
import { useGetDisplayStringOfItemWithShadeUpdates } from '../utils';
import { Text, useScreenIsMobileOrVerticalTablet, Grid } from '@orthly/ui-primitives';
import React from 'react';

export const ItemShadeChanges: React.VFC = () => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const itemId = useRefabFlowSelector(s => s.screenItemId);
    const [hasValidShades, setHasValidShades] = React.useState(false);
    const itemNamesDisplayString = useGetDisplayStringOfItemWithShadeUpdates();

    return (
        <RefabBodyContainer
            title={
                <>
                    <Text variant={'body1'} color={'GRAY'} medium>
                        You noted that{' '}
                        <Text variant={'body1'} color={'SECONDARY_FOREGROUND'} medium style={{ display: 'inline ' }}>
                            {itemNamesDisplayString}'s shades are incorrect
                        </Text>
                    </Text>
                    <Text variant={isMobile ? 'h5' : 'h3'} medium style={{ marginTop: '8px' }}>
                        Review shade selections
                    </Text>
                </>
            }
            disableNext={!hasValidShades}
        >
            <Grid>
                {/* We set the key as we want to make sure the component re-renders when we switch from one item to the other. */}
                <RequestShadeChanges key={itemId} setHasValidShades={setHasValidShades} />

                {!hasValidShades && (
                    <Text variant={'body2'} style={{ marginTop: '32px' }}>
                        One or more required field(s) are missing
                    </Text>
                )}
            </Grid>
        </RefabBodyContainer>
    );
};
