import { useDelayDateSlaPreview, isDelayedOrderCustomerNotificationNeeded } from './AddOrderDelayForm.utils';
import { EditOrderSlaLayout } from './EditOrderSlaLayout';
import { useQuery } from '@apollo/client';
import { isBusinessDay } from '@orthly/date-fns-business-days';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment, LabsGqlOrderSlaFragment } from '@orthly/graphql-operations';
import { useModifyOrderSlaMutation } from '@orthly/graphql-react';
import type { LabsGqlModifyOrderSlaCommand } from '@orthly/graphql-schema';
import { LabsGqlCategoryKind, LabsGqlOrderSlaModificationType } from '@orthly/graphql-schema';
import { useChangeSubmissionFn, SimpleDatePicker, SimpleInput, SimpleSelect } from '@orthly/ui';
import { Button, Text, stylesFactory, Tooltip, Icon, FlossPalette } from '@orthly/ui-primitives';
import moment from 'moment-timezone';
import React from 'react';

const useStyles = stylesFactory(() => ({
    inputGrid: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr auto 1fr',
        gridGap: 8,
        alignItems: 'center',
        marginBottom: 16,
    },
    summaryText: {
        display: 'grid',
        gridTemplateColumns: 'auto 1fr',
    },
    saveButton: {
        marginTop: 16,
        float: 'right',
    },
    pickerInput: {
        width: '100%',
        marginBottom: 16,
    },
}));

function formatDate(date: Date | string): string {
    return moment(date).format('MM/DD/YY');
}

const AdminOrderSlaReconfigurationFormSlaCategories_Query = graphql(`
    query AdminOrderSlaReconfigurationFormSlaCategories_Query($kind: CategoryKind!) {
        listCategories(kind: $kind) {
            id
            label
            requires_further_info
            visible_to_internal
            visible_to_lab

            subcategories {
                id
                label
                requires_further_info
            }
        }
    }
`);

function useCategorySelection(modificationType: LabsGqlOrderSlaModificationType) {
    const [selectedCategoryId, setSelectedCategoryId] = React.useState<string | null>(null);
    const [selectedSubCategoryId, setSelectedSubCategoryId] = React.useState<string | null>(null);
    const [furtherInfo, setFurtherInfo] = React.useState<string | null>(null);

    const { data: categoriesData } = useQuery(AdminOrderSlaReconfigurationFormSlaCategories_Query, {
        variables: {
            kind: LabsGqlCategoryKind.OrderSlaAdjustment,
        },
    });

    const categories = (categoriesData?.listCategories ?? []).filter(category => {
        if (modificationType === LabsGqlOrderSlaModificationType.ManufacturerDelay) {
            return category.visible_to_lab;
        }

        // We never seem to do this anymore
        if (modificationType === LabsGqlOrderSlaModificationType.PracticeReconfiguration) {
            return false;
        }

        return true;
    });

    const selectedCategory = categories.find(category => category.id === selectedCategoryId);
    const selectedSubCategory = selectedCategory?.subcategories?.find(
        subCategory => subCategory.id === selectedSubCategoryId,
    );

    return {
        categories,
        selectedCategory,
        selectedSubCategory,
        furtherInfo,
        setFurtherInfo,
        setSelectedSubCategoryId,
        setSelectedCategoryId: (id: string | null) => {
            setSelectedCategoryId(id);
            setSelectedSubCategoryId(null);
            setFurtherInfo(null);
        },
    };
}

type SlaStageKey = keyof Omit<NonNullable<LabsGqlOrderSlaFragment['stages']>, '__typename'>;

const SlaStageKeyLabelMap: Record<SlaStageKey, string | null> = {
    ProcessingStart: null,
    ReadyForDesign: null,
    Design: 'Design Due By',
    WaxupReview: null,
    ReadyForFabrication: 'Ship By',
    Fabrication: 'Ship By',
    ShipToDandy: null,
    ShipToPractice: null,
    Buffer: null,
};

interface OrderSlaVersionedReconfigurationFormProps {
    labOrderFragment: Pick<LabsGqlLabOrderFragment, 'id' | 'manufacturer_sla' | 'practice_dates' | 'status'>;
    modificationType: LabsGqlOrderSlaModificationType;
    submit: (command: LabsGqlModifyOrderSlaCommand) => Promise<unknown>;
    submitting: boolean;
}

// eslint-disable-next-line max-lines-per-function
const OrderSlaV3ModificationForm: React.VFC<OrderSlaVersionedReconfigurationFormProps> = ({
    labOrderFragment,
    modificationType,
    submit,
    submitting,
}) => {
    const classes = useStyles();
    const { manufacturer_sla: sla, practice_dates } = labOrderFragment;

    const isInFabrication = sla.current_stage === 'Fabrication' || sla.current_stage === 'ReadyForFabrication';
    const currentStage = labOrderFragment.manufacturer_sla.current_stage
        ? labOrderFragment.manufacturer_sla.stages?.[labOrderFragment.manufacturer_sla.current_stage as SlaStageKey]
        : null;
    const startingDate = currentStage?.eta ?? sla.due_date;
    const dateBeingChangedLabel = currentStage?.eta
        ? SlaStageKeyLabelMap[sla.current_stage as SlaStageKey] ?? 'Stage Due By'
        : 'Due Date';

    const { selectedDate, setSelectedDate, updatedSLA, isInvalidDate } = useDelayDateSlaPreview(
        labOrderFragment,
        startingDate,
    );

    const {
        categories,
        selectedCategory,
        selectedSubCategory,
        furtherInfo,
        setFurtherInfo,
        setSelectedSubCategoryId,
        setSelectedCategoryId,
    } = useCategorySelection(modificationType);

    const notificationNeeded = isDelayedOrderCustomerNotificationNeeded(
        sla.original_deadline ?? sla.internal_committed_due_date,
        practice_dates.estimated_delivery_date,
        updatedSLA?.estimated_delivery_date ?? null,
    );

    const requiresFurtherInfo = selectedCategory?.requires_further_info || selectedSubCategory?.requires_further_info;

    const areFormFieldsIncomplete =
        !selectedDate ||
        !selectedCategory ||
        (selectedCategory.subcategories?.length && !selectedSubCategory) ||
        (requiresFurtherInfo && !furtherInfo);
    const isSavingDisabled = areFormFieldsIncomplete || isInvalidDate;

    const isDelayedFromCurrentEta =
        updatedSLA?.estimated_delivery_date &&
        moment(updatedSLA.estimated_delivery_date).isAfter(practice_dates.estimated_delivery_date, 'day');

    return (
        <div>
            <div className={classes.inputGrid}>
                {/* Date selection row titles */}
                <Text variant={'body2'} medium>
                    Current {dateBeingChangedLabel}
                </Text>
                <span />
                <Text variant={'body2'} medium>
                    Delay {dateBeingChangedLabel}
                </Text>

                {/* Actual Date Input Row */}
                <SimpleDatePicker
                    disabled={true}
                    fullWidth={true}
                    label={`Current ${dateBeingChangedLabel}`}
                    value={new Date(startingDate)}
                    onChange={() => {
                        /* no-op */
                    }}
                />
                <Icon icon={'ChevronRight'} width={24} color={'disabled'} />
                <SimpleDatePicker
                    fullWidth={true}
                    minDate={moment(labOrderFragment.manufacturer_sla.start_date).toDate()}
                    shouldDisableDate={dateParam => {
                        const date = dateParam.toDate();
                        // Non-business days cannot be a due date for anything.
                        if (!date || !isBusinessDay(date)) {
                            return true;
                        }

                        // You can't make something retroactive due before today, it doesn't make sense.
                        if (moment(date).isBefore(new Date(), 'day')) {
                            return true;
                        }

                        if (!isInFabrication && moment(date).isBefore(startingDate, 'day')) {
                            return true;
                        }

                        return false;
                    }}
                    label={`New ${dateBeingChangedLabel}`}
                    value={selectedDate}
                    onChange={date => date && setSelectedDate(date)}
                />

                {/* ETAs */}
                <Text variant={'body2'} color={'GRAY'}>
                    Current practice ETA: {formatDate(practice_dates.estimated_delivery_date)}
                </Text>
                <span />
                <Text variant={'body2'} color={'GRAY'}>
                    {/* Delayed staged are red, stages shifted to be due sooner are green */}
                    New practice ETA:{' '}
                    <span
                        style={{
                            color: isDelayedFromCurrentEta ? FlossPalette.ATTENTION : FlossPalette.PRIMARY_FOREGROUND,
                        }}
                    >
                        {updatedSLA ? formatDate(updatedSLA.estimated_delivery_date) : '-'}
                    </span>
                </Text>
            </div>

            <Text variant={'body2'} medium>
                Issue
            </Text>
            <SimpleSelect
                label={'Issue'}
                options={categories.map(category => ({ value: category.id, label: category.label }))}
                value={selectedCategory?.id}
                onChange={id => setSelectedCategoryId(id ?? null)}
                SelectProps={{
                    className: classes.pickerInput,
                    variant: 'standard',
                }}
            />

            {/* Sub-reason row */}
            {selectedCategory?.subcategories?.length && (
                <>
                    <Text variant={'body2'} medium>
                        Reason
                    </Text>
                    <SimpleSelect
                        label={'Reason'}
                        options={selectedCategory.subcategories.map(subCategory => ({
                            value: subCategory.id,
                            label: subCategory.label,
                        }))}
                        value={selectedSubCategory?.id}
                        onChange={id => setSelectedSubCategoryId(id ?? null)}
                        SelectProps={{
                            className: classes.pickerInput,
                            variant: 'standard',
                        }}
                    />
                </>
            )}

            {/* Further Info row */}
            {requiresFurtherInfo && (
                <>
                    <Text variant={'body2'} medium>
                        Further Info
                    </Text>
                    <SimpleInput
                        label={'Further Info'}
                        value={furtherInfo ?? ''}
                        onChange={value => {
                            setFurtherInfo(value ?? null);
                        }}
                        TextFieldProps={{
                            error: !furtherInfo,
                            InputProps: {
                                className: classes.pickerInput,
                            },
                        }}
                        fullWidth
                    />
                </>
            )}
            {notificationNeeded && (
                <div className={classes.summaryText}>
                    <Icon icon={'WarningIcon'} style={{ color: FlossPalette.ATTENTION }} />
                    <Text variant={'body2'} medium color={'ATTENTION'}>
                        The practice will be notified. The new ETA is later than the committed ETA.
                    </Text>
                </div>
            )}
            <div className={classes.saveButton}>
                <Tooltip
                    title={
                        areFormFieldsIncomplete
                            ? `You must complete all fields to submit.`
                            : `You must enter a valid date that does not push the ETA before the committed due date.`
                    }
                    disableHoverListener={!isSavingDisabled}
                >
                    <div>
                        <Button
                            variant={'primary'}
                            disabled={isSavingDisabled || submitting}
                            onClick={() =>
                                submit({
                                    order_id: labOrderFragment.id,
                                    modification_type: LabsGqlOrderSlaModificationType.InternalDelay,
                                    notify_practice: notificationNeeded,
                                    reason: {
                                        category_id: selectedCategory?.id ?? '',
                                        further_info: furtherInfo,
                                        subcategory_id: selectedSubCategory?.id,
                                    },
                                    stage_due_date: selectedDate.toISOString(),
                                })
                            }
                        >
                            Save
                        </Button>
                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

interface ReconfigurationFormProps {
    labOrderFragment: Pick<LabsGqlLabOrderFragment, 'id' | 'manufacturer_sla' | 'practice_dates' | 'status'>;
    onSubmit: () => unknown;
    onBack: () => unknown;
    modificationType: LabsGqlOrderSlaModificationType;
}

export const AddOrderDelayForm: React.VFC<ReconfigurationFormProps> = props => {
    const { labOrderFragment, onSubmit, onBack, modificationType } = props;

    const [rawSubmit] = useModifyOrderSlaMutation();
    const { submit, submitting } = useChangeSubmissionFn(
        (command: LabsGqlModifyOrderSlaCommand) => rawSubmit({ variables: { command } }),
        {
            closeOnComplete: true,
            successMessage: () => [`SLA updated`, {}],
            onSuccess: onSubmit,
        },
    );

    return (
        <EditOrderSlaLayout title={`Delaying Order`} onBack={onBack}>
            <OrderSlaV3ModificationForm
                labOrderFragment={labOrderFragment}
                modificationType={modificationType}
                submit={submit}
                submitting={submitting}
            />
        </EditOrderSlaLayout>
    );
};
