import type { LabsGqlUserDtoFragment } from '@orthly/graphql-operations';
import { useListRetainerUsersQuery } from '@orthly/graphql-react';
import React from 'react';

export function useUserById(id: string | undefined | null) {
    const { data } = useListRetainerUsersQuery({
        nextFetchPolicy: 'cache-first',
        fetchPolicy: 'cache-first',
        skip: !id,
        variables: { filter: { ids: [id ?? ''] } },
    });

    return data?.listUsers[0];
}

export function useUsersById(ids: string[]) {
    const { data } = useListRetainerUsersQuery({
        nextFetchPolicy: 'cache-first',
        fetchPolicy: 'cache-first',
        skip: ids.length === 0,
        variables: { filter: { ids } },
    });

    return React.useMemo(
        () =>
            data?.listUsers.reduce<Record<string, LabsGqlUserDtoFragment>>(
                (map, user) => ({ ...map, [user.id]: user }),
                {},
            ) ?? {},
        [data],
    );
}

export function getUserDisplayName(user?: LabsGqlUserDtoFragment) {
    return user ? `${user.first_name} ${user.last_name}` : '...';
}
