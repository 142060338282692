import type { ScanReviewShadeMatchingPicker } from './ScanReviewShadeMatchingTypes';
import { FlossPalette } from '@orthly/ui-primitives';
import { useThree } from '@react-three/fiber';
import React from 'react';
import * as THREE from 'three';

export interface ScanReviewShadeMatchingToolProps {
    picker: ScanReviewShadeMatchingPicker;
    maxRadiusMm: number;
    onShadePicked: (color: [number, number, number]) => void;
}

export enum ScanReviewShadeMatchingToolPickingState {
    Uninitialized = 'Uninitialized',
    Picking = 'Picking',
    NotPicking = 'NotPicking',
}

export const ScanReviewShadeMatchingTool: React.VFC<ScanReviewShadeMatchingToolProps> = ({
    picker,
    maxRadiusMm,
    onShadePicked,
}) => {
    const { gl } = useThree();
    const canvas = gl.domElement;

    //This is a hack. There seems to be a problem with ModelViewerCanvas where the currentCanvas is null
    //When setting a ref
    picker.viewManager.canvasRef.current = canvas;

    const { sphereGeometry, sphereMaterial } = React.useMemo(() => {
        return {
            sphereGeometry: new THREE.SphereBufferGeometry(maxRadiusMm, 32, 32),
            sphereMaterial: new THREE.MeshBasicMaterial({ color: FlossPalette.STAR_GRASS }),
        };
    }, [maxRadiusMm]);

    const sphere = React.useMemo(() => {
        return new THREE.Mesh(sphereGeometry, sphereMaterial);
    }, [sphereGeometry, sphereMaterial]);

    const mouseState = React.useRef<ScanReviewShadeMatchingToolPickingState>(
        ScanReviewShadeMatchingToolPickingState.Uninitialized,
    );

    React.useEffect(() => {
        const updateLiveVisualObjects = (spherePosition: THREE.Vector3) => {
            sphere.position.copy(spherePosition);
        };

        const pickShadeFromMesh = () => {
            const shade = picker.pickShadeFromVertexColors(maxRadiusMm);
            return shade;
        };

        const enablePick = () => {
            mouseState.current = ScanReviewShadeMatchingToolPickingState.Picking;
            sphere.visible = true;
            if (picker.viewManager.cameraControlsRef.current) {
                picker.viewManager.cameraControlsRef.current.enabled = false;
            }
        };

        const disablePick = () => {
            if (mouseState.current !== ScanReviewShadeMatchingToolPickingState.Uninitialized) {
                mouseState.current = ScanReviewShadeMatchingToolPickingState.NotPicking;
            }
            if (picker.viewManager.cameraControlsRef.current) {
                picker.viewManager.cameraControlsRef.current.enabled = true;
            }
        };

        const pointerMove = (evt: MouseEvent) => {
            picker.respondToMouseEvent(evt);
            const shade = pickShadeFromMesh();
            if (shade && mouseState.current === ScanReviewShadeMatchingToolPickingState.Picking) {
                onShadePicked(shade.color);
                updateLiveVisualObjects(shade.center);
            }
        };

        const pointerDown = (evt: MouseEvent) => {
            if (evt.button !== 0) {
                return;
            }

            const shade = pickShadeFromMesh();
            if (shade) {
                enablePick();
                onShadePicked(shade.color);
                updateLiveVisualObjects(shade.center);
            } else {
                disablePick();
            }
        };

        const pointerUp = (evt: MouseEvent) => {
            if (evt.button !== 0) {
                return;
            }
            disablePick();
        };

        canvas.addEventListener('pointermove', pointerMove);
        canvas.addEventListener('pointerdown', pointerDown);
        canvas.addEventListener('pointerup', pointerUp);

        return () => {
            canvas.removeEventListener('pointermove', pointerMove);
            canvas.removeEventListener('pointerdown', pointerDown);
            canvas.removeEventListener('pointerup', pointerUp);
        };
    }, [picker, canvas, maxRadiusMm, onShadePicked, sphere]);
    return (
        <group visible={mouseState.current !== ScanReviewShadeMatchingToolPickingState.Uninitialized}>
            <primitive object={sphere} />
        </group>
    );
};
