import { useGuidedQcAnalytics } from './DesignQcCommon';
import { useDesignQcAction } from './state/DesignQc.actions';
import { useDesignQcPropSelector } from './state/DesignQc.context';
import { getAllUnns } from './state/DesignQc.selectors';
import { useRegisterHotKeys } from '@orthly/dentin';
import { SimpleTextField } from '@orthly/ui';
import { FlossPalette, Text, Button, Grid } from '@orthly/ui-primitives';
import React from 'react';

export const DesignQcMarkSubcategoryGoodSidebar: React.VFC<{}> = () => {
    const { rubricNavigation, order } = useDesignQcPropSelector(['rubricNavigation', 'order', 'results']);

    const [note, setNote] = React.useState('');

    const entry = rubricNavigation?.entry;

    const markAcceptableAction = useDesignQcAction('MARK_SUBCATEGORY_ACCEPTABLE');
    const toggleAcceptableWorkflowAction = useDesignQcAction('TOGGLE_MARK_SUBCATEGORY_ACCEPTABLE_WORKFLOW');
    const trackSubcategoryScored = useGuidedQcAnalytics('All - Portal - Guided QC - Subcategory Scored');

    const markAcceptable = React.useCallback(() => {
        trackSubcategoryScored({ verdict: 'acceptable', unns: getAllUnns({ order }) });
        markAcceptableAction({ which: 'acceptable', currentTime: Date.now(), note });
    }, [markAcceptableAction, order, note, trackSubcategoryScored]);

    useRegisterHotKeys({
        key: 'Shift+Enter',
        description: 'Submit Reason',
        category: 'Guided QC',
        action: markAcceptable,
    });

    // Should never happen, but for type safety
    if (!entry) {
        return null;
    }

    return (
        <Grid
            item
            container
            direction={'column'}
            style={{
                width: 336,
                overflow: 'auto',
                borderLeft: `1px solid ${FlossPalette.STROKE_LIGHT}`,
                padding: `48px 24px`,
            }}
        >
            <Grid item spacing={1}>
                <Text variant={'h4'} medium>
                    Mark subcategory as acceptable
                </Text>
            </Grid>
            <Grid item style={{ marginTop: 32 }}>
                <Text variant={'body2'} color={'GRAY'}>
                    Note (optional)
                </Text>
                <SimpleTextField
                    TextFieldProps={{ multiline: true }}
                    label={'Add a note'}
                    value={note}
                    onChange={value => setNote(value)}
                />
            </Grid>
            {/* Spacer */}
            <div style={{ flexGrow: 1 }} />
            <Grid item style={{ marginTop: 8 }}>
                <Button
                    variant={'secondary'}
                    onClick={() => {
                        toggleAcceptableWorkflowAction({ isEnabled: false });
                    }}
                    fullWidth
                >
                    Cancel
                </Button>
            </Grid>
            <Grid item style={{ marginTop: 8 }}>
                <Button variant={'primary'} onClick={markAcceptable} fullWidth>
                    Submit (Shift+Enter)
                </Button>
            </Grid>
        </Grid>
    );
};
