import { RefabBodyContainer } from '../components/RefabBodyContainer';
import { useRefabFlowSelector } from '../state/refab-flow-state';
import { getItemsToReturn, getUniqueAdditionalInfoRequest } from '../utils';
import { useRefabFlowContext } from './refab-flow-context';
import { LoadBlocker } from '@orthly/ui';
import { Text, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

export const SupplementalScreenStepTwo: React.VFC = () => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const itemwiseReasonCodes = useRefabFlowSelector(s => s.itemwiseReasonCodes);
    const [hasAckedReturnPolicy, setHasAckedReturnPolicy] = React.useState(false);
    const { order, refetch: refetchOrder, submitting, SupplementalInfoStepTwo } = useRefabFlowContext();
    const additionalInfoRequests = getUniqueAdditionalInfoRequest(itemwiseReasonCodes);

    const itemsToReturn = getItemsToReturn(order, itemwiseReasonCodes);
    const hasPassedReturnValidation = itemsToReturn.length > 0 ? hasAckedReturnPolicy : true;
    const isNextDisabled = !hasPassedReturnValidation;

    return (
        <RefabBodyContainer
            title={<Text variant={isMobile ? 'h5' : 'h3'}>Please review your design</Text>}
            disableNext={isNextDisabled}
            nextTitle={'Next'}
        >
            <LoadBlocker blocking={submitting}>
                <SupplementalInfoStepTwo
                    order={order}
                    hasAckedReturnPolicy={hasAckedReturnPolicy}
                    setHasAckedReturnPolicy={setHasAckedReturnPolicy}
                    itemsToReturn={itemsToReturn}
                    additionalInfoRequests={additionalInfoRequests}
                    refetchOrder={refetchOrder}
                />
            </LoadBlocker>
        </RefabBodyContainer>
    );
};
