import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { Grid } from '@orthly/ui-primitives';
import { OrderListItemFlags, getOrderListItemFlagProps } from '@orthly/veneer';

// TODO: remove dependence on legacy flags

interface OrderDetailSummaryFlagsProps {
    labOrder: LabsGqlLabOrderFragment;
}

export const OrderDetailSummaryFlags: React.VFC<OrderDetailSummaryFlagsProps> = ({ labOrder }) => {
    const { flags } = getOrderListItemFlagProps(labOrder);
    const displayFlags = flags.filter(f => f !== 'Refab');

    if (!displayFlags.length) {
        return null;
    }

    return (
        <Grid container style={{ paddingTop: 8 }}>
            <OrderListItemFlags flags={displayFlags} />
        </Grid>
    );
};
