import type { QcHeatmapRange } from '../ColorRamp';
import type { MainViewCameraControlsRef, ICameraControls } from '../ModelViewer';
import type { ScanReviewCompositeScene } from './ScanReviewSceneTypes';
import type React from 'react';
import type * as THREE from 'three';

export const INITIAL_SCAN_REVIEW_BITE_ANALYSIS_HEATMAP_RANGE: QcHeatmapRange = { min: -0.1, max: 1.0 };
export const INITIAL_SCAN_REVIEW_UNDERCUT_HEATMAP_RANGE: QcHeatmapRange = { min: 0.0, max: 0.1 };

export type ScanReviewViewState = Record<ScanReviewPanelType, ScanReviewPanelCameraState | null>;

export enum ScanReviewViewType {
    Single = 'single',
    SideBySide = 'side-by-side',
    Complete = 'complete',
}

export enum ScanReviewPanelType {
    Upper = 'upper',
    Lower = 'lower',
    Left = 'left',
    Right = 'right',
    Front = 'front',
    Isolated = Front,
}

export enum ScanReviewDisplayType {
    Scan = 'scan',
    StoneModel = 'stone-model',
    BiteAnalysis = 'bite-analysis',
    ScanUndercut = 'undercut',
    StoneModelUndercut = 'stone-undercut',
}

export interface ScanReviewViewJawDisplayState {
    upper: boolean;
    lower: boolean;
}

export enum ScanReviewMode {
    Review = 'review',
    MarginMarking = 'margin-marking',
    Undercut = 'undercut',
}

export class ScanReviewPanelCameraState {
    constructor(
        public position: THREE.Vector3,
        public rotation: THREE.Euler,
        public up: THREE.Vector3,
        public zoom: number,
        public target: THREE.Vector3,
    ) {}
}

export interface ScanReviewThreeViewProvider {
    scene: THREE.Scene;
}

export class ScanReviewViewManager {
    constructor(
        public canvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
        public cameraRef: React.MutableRefObject<THREE.OrthographicCamera | null>,
        public cameraControlsRef: MainViewCameraControlsRef,
        public scene: ScanReviewThreeViewProvider,
        public panelType: ScanReviewPanelType,
        public viewState: ScanReviewViewState,
    ) {}

    updateViewState(viewState: ScanReviewPanelCameraState) {
        if (!this.cameraControlsRef.current) {
            return;
        }

        const controls = this.cameraControlsRef.current;

        console.log('Updating view state from test.', this.panelType);

        controls.reset();
        controls.object.position.copy(viewState.position);
        controls.object.rotation.copy(viewState.rotation);
        controls.object.up.copy(viewState.up);
        controls.object.zoom = viewState.zoom;
        controls.target.copy(viewState.target);
        controls.object.updateMatrixWorld(true);
        controls.object.updateProjectionMatrix();
        controls.update();

        this.viewState[this.panelType] = viewState;
        console.log('Done Updating view state from test.', this.panelType);
    }

    updateViewStateFromControls() {
        if (!this.cameraControlsRef.current) {
            return;
        }
        const currentCameraState = new ScanReviewPanelCameraState(
            this.cameraControlsRef.current.object.position.clone(),
            this.cameraControlsRef.current.object.rotation.clone(),
            this.cameraControlsRef.current.object.up.clone(),
            this.cameraControlsRef.current.object.zoom,
            this.cameraControlsRef.current.target.clone(),
        );
        this.viewState[this.panelType] = currentCameraState;
    }

    initializeControls(controls: ICameraControls) {
        if (!controls) {
            return;
        }

        this.cameraControlsRef.current = controls;
        const panelViewState = this.viewState[this.panelType];
        if (panelViewState !== null) {
            controls.reset();
            controls.object.position.copy(panelViewState.position);
            controls.object.rotation.copy(panelViewState.rotation);
            controls.object.up.copy(panelViewState.up);
            controls.object.zoom = panelViewState.zoom;
            controls.target.copy(panelViewState.target);
            controls.object.updateMatrixWorld(true);
            controls.object.updateProjectionMatrix();
            controls.update();
        }
    }

    get canvas() {
        return this.canvasRef.current;
    }
    get camera() {
        return this.cameraRef.current;
    }
    get controls() {
        return this.cameraControlsRef.current;
    }
}

export class ScanReviewCompositeViewManager {
    constructor(
        private readonly upperView: ScanReviewViewManager,
        private readonly lowerView: ScanReviewViewManager,
        private readonly leftView: ScanReviewViewManager,
        private readonly rightView: ScanReviewViewManager,
        private readonly frontView: ScanReviewViewManager,
    ) {}

    getViewManagerForPanelType(panelType: ScanReviewPanelType): ScanReviewViewManager {
        switch (panelType) {
            case ScanReviewPanelType.Upper: {
                return this.upperView;
            }
            case ScanReviewPanelType.Lower: {
                return this.lowerView;
            }
            case ScanReviewPanelType.Left: {
                return this.leftView;
            }
            case ScanReviewPanelType.Right: {
                return this.rightView;
            }
            case ScanReviewPanelType.Front: {
                return this.frontView;
            }
        }
    }
}

export interface ScanReviewSceneAppearance {
    mode: ScanReviewMode;
    displayType: ScanReviewDisplayType;
    viewType: ScanReviewViewType;
    heatmapRange: QcHeatmapRange;
    undercutHeatmapRange: QcHeatmapRange;
    isScanOrScanUndercutDisplay: boolean;
    isStoneModelOrStoneModelUndercutDisplay: boolean;
    upperJawVisible: boolean;
    lowerJawVisible: boolean;
    lowerJawInScene: boolean;
    upperJawInScene: boolean;
}

export class ScanReviewAppearanceManager {
    private _mode: ScanReviewMode;
    private _displayType: ScanReviewDisplayType;
    private _viewType: ScanReviewViewType;
    private _heatmapRange: QcHeatmapRange;
    private _undercutHeatmapRange: QcHeatmapRange;
    private _upperJawVisible: boolean;
    private _lowerJawVisible: boolean;

    constructor(
        private readonly scene: ScanReviewCompositeScene,
        private readonly setAppearance: (appearance: ScanReviewSceneAppearance) => void,
        readonly currentAppearance: ScanReviewSceneAppearance,
    ) {
        this._mode = currentAppearance.mode;
        this._displayType = currentAppearance.displayType;
        this._viewType = currentAppearance.viewType;
        this._heatmapRange = currentAppearance.heatmapRange;
        this._undercutHeatmapRange = currentAppearance.undercutHeatmapRange;
        this._upperJawVisible = currentAppearance.upperJawVisible;
        this._lowerJawVisible = currentAppearance.lowerJawVisible;
    }

    private updateAppearanceState(): void {
        this.setAppearance({
            mode: this._mode,
            displayType: this._displayType,
            viewType: this._viewType,
            heatmapRange: this._heatmapRange,
            undercutHeatmapRange: this._undercutHeatmapRange,
            upperJawVisible: this._upperJawVisible,
            lowerJawVisible: this._lowerJawVisible,
            isScanOrScanUndercutDisplay:
                this._displayType === ScanReviewDisplayType.Scan ||
                this._displayType === ScanReviewDisplayType.ScanUndercut,
            isStoneModelOrStoneModelUndercutDisplay:
                this._displayType === ScanReviewDisplayType.StoneModel ||
                this._displayType === ScanReviewDisplayType.StoneModelUndercut,
            upperJawInScene: this.scene.upperJawInScene,
            lowerJawInScene: this.scene.lowerJawInScene,
        });
    }

    setHeatmapRange(newHeatmapRange: QcHeatmapRange) {
        this._heatmapRange = newHeatmapRange;
        this.scene.updateHeatmapRange(newHeatmapRange);
        this.updateAppearanceState();
    }

    setUndercutHeatmapRange(newHeatmapRange: QcHeatmapRange) {
        this._undercutHeatmapRange = newHeatmapRange;
        this.scene.updateUndercutHeatmapRange(newHeatmapRange);
        this.updateAppearanceState();
    }

    setViewType(newViewType: ScanReviewViewType) {
        switch (newViewType) {
            case ScanReviewViewType.Complete:
            case ScanReviewViewType.SideBySide: {
                if (this._displayType === ScanReviewDisplayType.ScanUndercut) {
                    this.setDisplayType(ScanReviewDisplayType.Scan);
                } else if (this._displayType === ScanReviewDisplayType.StoneModelUndercut) {
                    this.setDisplayType(ScanReviewDisplayType.StoneModel);
                }
                this.setMode(ScanReviewMode.Review);
                this._viewType = newViewType;
                break;
            }
            case ScanReviewViewType.Single: {
                this._viewType = newViewType;
                break;
            }
        }
        this.updateAppearanceState();
    }

    setMode(newMode: ScanReviewMode) {
        switch (newMode) {
            case ScanReviewMode.Review: {
                if (this._displayType === ScanReviewDisplayType.ScanUndercut) {
                    this.scene.setDisplayType(ScanReviewDisplayType.Scan, this._heatmapRange);
                    this._displayType = ScanReviewDisplayType.Scan;
                } else if (this._displayType === ScanReviewDisplayType.StoneModelUndercut) {
                    this.scene.setDisplayType(ScanReviewDisplayType.StoneModel, this._heatmapRange);
                    this._displayType = ScanReviewDisplayType.StoneModel;
                }
                break;
            }
            case ScanReviewMode.MarginMarking: {
                this.scene.setDisplayType(ScanReviewDisplayType.Scan, this._heatmapRange);
                this._displayType = ScanReviewDisplayType.Scan;
                break;
            }
            case ScanReviewMode.Undercut: {
                if (this._displayType === ScanReviewDisplayType.StoneModel) {
                    this.scene.setDisplayType(ScanReviewDisplayType.StoneModelUndercut, this._heatmapRange);
                    this._displayType = ScanReviewDisplayType.StoneModelUndercut;
                } else {
                    this.scene.setDisplayType(ScanReviewDisplayType.ScanUndercut, this._heatmapRange);
                    this._displayType = ScanReviewDisplayType.ScanUndercut;
                }

                if (this._lowerJawVisible && this.scene.lowerJawInScene) {
                    this.scene.setUpperJawVisibility(false);
                    this._upperJawVisible = false;
                } else if (this._upperJawVisible && this.scene.upperJawInScene) {
                    this.scene.setLowerJawVisibility(false);
                    this._lowerJawVisible = false;
                }
                break;
            }
        }
        this._mode = newMode;
        this.updateAppearanceState();
    }

    setDisplayType(newDisplayType: ScanReviewDisplayType) {
        switch (newDisplayType) {
            case ScanReviewDisplayType.Scan: {
                this.switchToScanDisplay();
                break;
            }
            case ScanReviewDisplayType.StoneModel: {
                this.switchToStoneModelDisplay();
                break;
            }
            case ScanReviewDisplayType.BiteAnalysis: {
                this.switchToBiteAnalyisDisplay();
                break;
            }
        }
        this.updateAppearanceState();
    }

    private switchToScanDisplay() {
        switch (this._mode) {
            case ScanReviewMode.Review:
            case ScanReviewMode.MarginMarking: {
                this.scene.setDisplayType(ScanReviewDisplayType.Scan, this._heatmapRange);
                this._displayType = ScanReviewDisplayType.Scan;
                break;
            }
            case ScanReviewMode.Undercut: {
                this.scene.setDisplayType(ScanReviewDisplayType.ScanUndercut, this._heatmapRange);
                this._displayType = ScanReviewDisplayType.ScanUndercut;
                break;
            }
        }
    }

    private switchToStoneModelDisplay() {
        switch (this._mode) {
            case ScanReviewMode.Review: {
                this.scene.setDisplayType(ScanReviewDisplayType.StoneModel, this._heatmapRange);
                this._displayType = ScanReviewDisplayType.StoneModel;
                break;
            }
            case ScanReviewMode.MarginMarking: {
                this.scene.setDisplayType(ScanReviewDisplayType.StoneModel, this._heatmapRange);
                this._displayType = ScanReviewDisplayType.StoneModel;
                this._mode = ScanReviewMode.Review;
                break;
            }
            case ScanReviewMode.Undercut: {
                this.scene.setDisplayType(ScanReviewDisplayType.StoneModelUndercut, this._heatmapRange);
                this._displayType = ScanReviewDisplayType.StoneModelUndercut;
                break;
            }
        }
    }

    private switchToBiteAnalyisDisplay() {
        switch (this._mode) {
            case ScanReviewMode.Review: {
                this.scene.setDisplayType(ScanReviewDisplayType.BiteAnalysis, this._heatmapRange);
                this._displayType = ScanReviewDisplayType.BiteAnalysis;
                break;
            }
            case ScanReviewMode.MarginMarking:
            case ScanReviewMode.Undercut: {
                this.scene.setDisplayType(ScanReviewDisplayType.BiteAnalysis, this._heatmapRange);
                this._displayType = ScanReviewDisplayType.BiteAnalysis;
                this._mode = ScanReviewMode.Review;
                break;
            }
        }
    }

    setUpperJawVisible(visible: boolean) {
        this.scene.setUpperJawVisibility(visible);
        this._upperJawVisible = visible;
        if (this._mode === ScanReviewMode.MarginMarking || this._mode === ScanReviewMode.Undercut) {
            this.scene.setLowerJawVisibility(!visible);
            this._lowerJawVisible = !visible;
        }
        this.updateAppearanceState();
    }

    setLowerJawVisible(visible: boolean) {
        const originalLowerJaw = this._lowerJawVisible;
        const originalUpperJaw = this._upperJawVisible;

        this.scene.setLowerJawVisibility(visible);
        this._lowerJawVisible = visible;
        if (this._mode === ScanReviewMode.MarginMarking || this._mode === ScanReviewMode.Undercut) {
            this.scene.setUpperJawVisibility(!visible);
            this._upperJawVisible = !visible;
        }

        //Need a more general solution to this.
        if (originalLowerJaw !== this._lowerJawVisible || originalUpperJaw !== this._upperJawVisible) {
            this.updateAppearanceState();
        }
    }
}
