import type { ScanbodyTrie, ImplantToScanbodyFilters } from './ImplantToScanbodySelector';
import { SimpleAutocomplete } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface ImplantToScanbodyInputProps {
    value: ImplantToScanbodyFilters;
    onFieldUpdate: (field: keyof ImplantToScanbodyFilters, value: string | null) => void;
    scanbodyTrie: ScanbodyTrie;
}

export const ImplantToScanbodyInput: React.VFC<ImplantToScanbodyInputProps> = ({
    value: { manufacturer, name, isAuthentic },
    onFieldUpdate,
    scanbodyTrie,
}) => (
    <Grid container spacing={1} alignItems={'center'}>
        <Grid container item xs={3}>
            <SimpleAutocomplete
                AutocompleteProps={{ style: { width: '100%' } }}
                label={'Is Authentic?'}
                options={['Authentic', 'Generic']}
                key={'authenticity'}
                onChange={val => onFieldUpdate('isAuthentic', val)}
            />
        </Grid>
        <Grid container item xs={3}>
            <SimpleAutocomplete
                AutocompleteProps={{ style: { width: '100%' } }}
                label={'Scan body Manufacturer'}
                options={_.keys(scanbodyTrie)}
                // uses isAuthentic field so React creates a fresh component instance when is authentic changes
                // otherwise, the values in this auto complete don't refresh and show obsolete values
                key={`authenticity_${isAuthentic}`}
                onChange={val => onFieldUpdate('manufacturer', val)}
            />
        </Grid>
        <Grid container item xs={3}>
            <SimpleAutocomplete
                AutocompleteProps={{ style: { width: '100%' } }}
                label={'Scan body Name'}
                options={_.keys(scanbodyTrie[manufacturer ?? ''])}
                // uses manufacturer field so React creates a fresh component instance when manufacturer changes
                // otherwise, the values in this auto complete don't refresh and show obsolete values
                key={manufacturer}
                onChange={val => onFieldUpdate('name', val)}
            />
        </Grid>
        <Grid container item xs={3}>
            <SimpleAutocomplete
                AutocompleteProps={{ style: { width: '100%' } }}
                label={'Scan body SKU'}
                options={scanbodyTrie[manufacturer ?? '']?.[name ?? '']?.map(s => s.sku) ?? []}
                // uses name field so React creates a fresh component instance when name changes
                // otherwise, the values in this auto complete don't refresh and show obsolete values
                key={name}
                onChange={val => onFieldUpdate('sku', val)}
            />
        </Grid>
    </Grid>
);
