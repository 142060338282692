import { PausedPracticeEditorModal } from './PausedPracticeEditorModal.graphql';
import type { LabsGqlOrderingPausedPracticeReason } from '@orthly/graphql-schema';
import { Button, stylesFactory } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    unpauseButton: {
        height: 24,
    },
}));

interface PausedPracticePauseButtonProps {
    practiceId: string;
    refetch: () => Promise<unknown>;
    reasons: LabsGqlOrderingPausedPracticeReason[];
}

export const PausedPracticeUnpauseButton: React.VFC<PausedPracticePauseButtonProps> = ({
    practiceId,
    refetch,
    reasons,
}) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState<boolean>(false);

    const { value: isEnabled } = useFeatureFlag('allowedToPausePracticesFromPlacingOrders');

    if (!isEnabled) {
        return null;
    }

    return (
        <>
            <PausedPracticeEditorModal
                open={open}
                setOpen={setOpen}
                practiceId={practiceId}
                refetch={refetch}
                currentReasons={reasons}
            />
            <Button variant={'ghost'} onClick={() => setOpen(true)} className={classes.unpauseButton}>
                Unpause ordering
            </Button>
        </>
    );
};
