import { useDesignTaskStyles } from './OrderDesignTaskPane.util';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { SimpleMultiSelect } from '@orthly/ui';
import { Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

const posteriorCrownPrepFeedbackOptionsList: string[] = [
    'Prep was acceptable',
    'Incorrect 3Shape order form',
    'Incorrect files uploaded',
    'Incorrect scans used or imported incorrectly',
    'Missed screenshots or notes',
    'Missed scan errors / unrecognized stitching errors',
    "Didn't send to Scan Review for a valid prep reason",
    'Wrong DME',
    'Failed to redo prep steps for a refab where prep was incorrectly done for the original',
    'Refine upper jaw scan / Refine lower jaw scan steps skipped',
    'Occlusal plane set incorrectly',
    'Path of Insertion set incorrectly',
    'Scans trimmed incorrectly',
    'TOM unsculpted',
    'Undersculpted TOM',
    'Oversculpted TOM',
    'Margins marked incorrectly',
    'Bites incorrectly set',
    'Unnecessarily sculpted parts of the scan other than the margin',
    'Failed to sculpt obvious artifacts or indentations on proximal wall',
    'Did not close the prep undercut / die interface failure',
    'Used “Align to bite” causing floating margins or incorrect bites',
    'Annotations marked incorrectly',
];

const implantPrepFeedbackOptionsList: string[] = [
    'Prep was acceptable',
    'Incorrect material',
    'Incorrect parts',
    'Incorrect scans used or scans misaligned',
    'Bite is incorrect',
    'Contact discrepancies not identified',
    'Annotations placed incorrectly',
    'Insertion guide needed and not added',
    'Incorrect model settings',
    'Incorrect file name',
    'Dandy Recommended abutment not updated in Rx',
    'Incorrect info/screenshots',
    'Incorrect scan body selected',
];

function usePrepFeedbackOptionsList(order: Pick<LabsGqlLabOrderFragment, 'items_v2'>) {
    return React.useMemo(() => {
        const hasImplant = order.items_v2.some(item => item.sku === 'Implant');

        if (hasImplant) {
            return implantPrepFeedbackOptionsList.map(s => ({ value: s, label: s }));
        }

        return posteriorCrownPrepFeedbackOptionsList.map(s => ({ value: s, label: s }));
    }, [order.items_v2]);
}

export function useDoesTaskRequirePrepFeedback(order: Pick<LabsGqlLabOrderFragment, 'fulfillment_workflow'>): boolean {
    // We only ask for prep feedback if this is an Internal Design task and the order previously required prep.
    // We don't ask for the feedback in revisions because the prep will have already been reviewed.
    return (
        order.fulfillment_workflow.active_task?.__typename === 'InternalDesignWorkflowTask' &&
        order.fulfillment_workflow.configuration.internal_design_prep_required &&
        !order.fulfillment_workflow.active_task.configuration.is_revision
    );
}

interface OrderDesignTaskPanePrepFeedbackProps {
    order: Pick<LabsGqlLabOrderFragment, 'fulfillment_workflow' | 'items_v2'>;
    feedback: string[];
    setFeedback: (feedback: string[]) => void;
}

export const OrderDesignTaskPanePrepFeedback: React.VFC<OrderDesignTaskPanePrepFeedbackProps> = ({
    order,
    feedback,
    setFeedback,
}) => {
    const classes = useDesignTaskStyles();
    const isEnabled = useDoesTaskRequirePrepFeedback(order);

    const options = usePrepFeedbackOptionsList(order);

    if (!isEnabled) {
        return null;
    }

    return (
        <Grid container item direction={'column'}>
            <Text variant={'body2'} className={classes.fieldLabel}>
                Prep Stage Feedback *
            </Text>
            <SimpleMultiSelect
                options={options}
                onChange={value => setFeedback(value ?? [])}
                value={feedback}
                label={'Prep Stage Feedback'}
            />
        </Grid>
    );
};
