import { OpsScreenEnum } from '../../../redux/ui/ui.types';
import type { FetchResult } from '@apollo/client';
import type {
    LabsGqlOrderPriceAdjustmentBulkJobStatusFragment,
    LabsGqlBulkPriceAdjustmentOrderResultFragment,
    LabsGqlQueueZeroOutModelsBulkPricingJobMutationVariables,
    LabsGqlQueueZeroOutModelsBulkPricingJobMutation,
} from '@orthly/graphql-operations';
import {
    useListZeroOutModelsBulkPricingJobsQuery,
    useQueueZeroOutModelsBulkPricingJobMutation,
} from '@orthly/graphql-react';
import { MUITable } from '@orthly/mui-table';
import { UuidUtils } from '@orthly/runtime-utils';
import { useChangeSubmissionFn, RootActionDialog, apolloErrorMessage, SimpleInput } from '@orthly/ui';
import { Button, Grid } from '@orthly/ui-primitives';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

type JobDataTableRow = LabsGqlOrderPriceAdjustmentBulkJobStatusFragment;

const OrderPriceAdjustmentsDetailPanel: React.VFC<{ data: JobDataTableRow }> = ({ data: jobData }) => {
    type SingleOrderResult = LabsGqlBulkPriceAdjustmentOrderResultFragment & {
        id: string;
        type: 'success' | 'missing prices' | 'error';
    };
    const results = React.useMemo<SingleOrderResult[]>(() => {
        return [
            ...jobData.errors.map<SingleOrderResult>(e => ({ ...e, id: e.order_id, type: 'error' })),
            ...jobData.orders_missing_pricing.map<SingleOrderResult>(e => ({
                ...e,
                id: e.order_id,
                type: 'missing prices',
            })),
            ...jobData.successes.map<SingleOrderResult>(e => ({ ...e, id: e.order_id, type: 'success' })),
        ];
    }, [jobData.errors, jobData.orders_missing_pricing, jobData.successes]);
    return (
        <Grid container style={{ maxWidth: 'calc(100vw - 64px)' }}>
            <MUITable<SingleOrderResult>
                title={'Results'}
                columns={[
                    { name: 'Order ID', render: 'order_id', filter: false },
                    {
                        name: 'Link',
                        filter: false,
                        render: r => <RouterLink to={`/${OpsScreenEnum.orders}/${r.id}`}>View order</RouterLink>,
                    },
                    { name: 'Type', render: 'type', filterOptions: { type: 'checkbox' } },
                    { name: 'Message?', render: 'message', filter: false },
                ]}
                data={results}
                displayOptions={{ fixedSearch: true, viewColumns: true, filter: true, download: true }}
            />
        </Grid>
    );
};

const SchedulePriceAdjustmentJobAction: React.VFC<{ refetch: () => Promise<any> }> = props => {
    type Vars = LabsGqlQueueZeroOutModelsBulkPricingJobMutationVariables;
    type Result = FetchResult<LabsGqlQueueZeroOutModelsBulkPricingJobMutation>;
    const { refetch } = props;
    const [orderIdsInput, setOrderIdsInput] = React.useState<string | undefined>();
    const [submitMtn] = useQueueZeroOutModelsBulkPricingJobMutation();
    const mtnSubmitter = (variables: Vars) => submitMtn({ variables });
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<Result, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Job scheduled!', {}],
        errorMessage: e => [apolloErrorMessage(e), {}],
        onSuccess: async () => {
            setOrderIdsInput(undefined);
            await refetch().catch(console.error);
        },
    });
    const orderIdsParsed = React.useMemo<string[]>(() => {
        return (orderIdsInput ?? '').split('\n').filter(id => UuidUtils.isUUID(id));
    }, [orderIdsInput]);
    return (
        <Grid container justifyContent={'flex-end'}>
            <RootActionDialog
                loading={submitting}
                open={open}
                setOpen={setOpen}
                title={'Schedule Zero-Out Model Prices Job'}
                buttonText={'Schedule Job'}
                buttonProps={{ style: { maxWidth: 200 } }}
                showCloseButton
                content={
                    <Grid container>
                        <SimpleInput
                            onChange={setOrderIdsInput}
                            value={orderIdsInput}
                            label={'Paste CSV of order IDs here'}
                            fullWidth
                            placeholder={'One ID per line (copy/paste from sheets works)'}
                            TextFieldProps={{ multiline: true, rows: 4 }}
                        />
                        <Grid container style={{ paddingTop: 16 }}>
                            <Button
                                fullWidth
                                variant={'primary'}
                                disabled={orderIdsParsed.length === 0}
                                onClick={() => {
                                    void submit({ orderIds: orderIdsParsed });
                                }}
                            >
                                Queue job for {orderIdsParsed.length} orders
                            </Button>
                        </Grid>
                    </Grid>
                }
            />
        </Grid>
    );
};

/**
 * This allows ops users to schedule a job to bulk set model item prices to $0 for a set of order ids.
 * They do this before invoices are generated each month, there's a Looker dashboard with conditions that change
 * dynamically for which orders are included so it didn't make sense to try to fix root cause at this point.
 *
 * @example See demo: https://www.loom.com/share/9efb964bd46e42c6b6db10f6e19bacb3
 */
export const ZeroOutModelPricesBulkRoot: React.VFC = () => {
    const { data, refetch, loading } = useListZeroOutModelsBulkPricingJobsQuery();
    const CustomRightToolbar = React.useCallback(
        () => <SchedulePriceAdjustmentJobAction refetch={refetch} />,
        [refetch],
    );
    return (
        <MUITable<JobDataTableRow>
            loading={loading}
            title={'Jobs - Zero Model Prices'}
            DetailPanel={OrderPriceAdjustmentsDetailPanel}
            columns={[
                { name: 'Job ID', render: r => r.id, field: 'id' },
                { name: 'Status', render: r => r.status, field: 'status' },
                {
                    name: 'Created at',
                    render: r => r.created_at,
                    field: 'created_at',
                    type: 'datetime',
                    defaultSort: 'desc',
                },
                { name: 'Completed at', render: r => r.completed_at, field: 'completed_at', type: 'datetime' },
                { name: '# Orders', render: r => r.order_ids.length, field: 'order_ids.length', type: 'numeric' },
            ]}
            actions={{
                global: [{ icon: 'refresh', position: 'toolbar', onClick: refetch, disabled: loading }],
            }}
            toolbarOptions={{ CustomRight: CustomRightToolbar }}
            data={data?.listZeroOutModelsBulkPricingJobs ?? []}
            paginationOptions={{ rowsPerPageOptions: [25], initialRowsPerPage: 25 }}
            displayOptions={{ fixedSearch: true, viewColumns: true }}
        />
    );
};
