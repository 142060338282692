import { FakePracticeBanner } from '../../../../components/FakePracticeBanner.graphql';
import { PausedPracticeBanner } from '../../../../components/PausedPractices/PausedPracticeBanner.graphql';
import { CollectPaymentMethodModal } from './CollectPaymentMethodModal';
import { PaymentMethods } from './PaymentMethods';
import type { PracticePaymentMethodsQueryQuery } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { useHasCapability } from '@orthly/session-client';
import { LoadBlocker } from '@orthly/ui';
import { FlossPalette, Text, Button, styled } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

const ContentContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 30,
});

const TitleContainer = styled('div')({
    display: 'flex',
    marginTop: 30,
    alignItems: 'center',
});

const LinkButtonContainer = styled('div')({
    marginLeft: 20,
});

const PaymentMethodContainer = styled('div')({
    marginTop: 30,
});

const StyledText = styled(Text)({
    cursor: 'pointer',
    display: 'inline',
});

const OptedForFeesContainer = styled('div')({
    display: 'flex',
    marginTop: 30,
    justifyContent: 'space-between',
    width: '25%',
    borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
});

const PartnerBillingChangelogEntryFragment = graphql(`
    fragment PartnerBillingChangelogEntryFragment on PartnerBillingChangelogEntryBase {
        __typename
        actor_name
        actor_role
        event_timestamp
    }
`);

const PaymentSourceFragment = graphql(`
    fragment PaymentSourceFragment on StripePaymentMethod {
        id
        brand
        last4
        is_default
        method
        funding
    }
`);

type PracticePaymentMethodsProps = {
    practiceId: string;
    loading: boolean;
    practicePaymentMethodsInfoQueryData?: PracticePaymentMethodsQueryQuery;
};

export const PracticePaymentMethods: React.FC<PracticePaymentMethodsProps> = ({
    practiceId,
    loading,
    practicePaymentMethodsInfoQueryData,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const hasCapability = useHasCapability('user', 'user.collect_payment_method');

    const partnerBillingChangelogEntryData = getFragmentData(
        PartnerBillingChangelogEntryFragment,
        practicePaymentMethodsInfoQueryData?.partnerBillingChangelog,
    );

    const paymentMethods = getFragmentData(PaymentSourceFragment, practicePaymentMethodsInfoQueryData?.paymentMethods);

    const practiceHasPaymentMethods = paymentMethods && paymentMethods.length;

    const practiceWillBeChargedCCFee =
        practicePaymentMethodsInfoQueryData?.partnerBillingState[0]?.will_be_charged_cc_fee;

    const org_legal_name = practicePaymentMethodsInfoQueryData?.getOrganization.legal_name;

    const willBeChargedCCFeeChangeEvent = partnerBillingChangelogEntryData?.find(
        change => change.__typename === 'PartnerBillingWillBeChargedCCFeeChangelogEntry',
    );

    const actorName = willBeChargedCCFeeChangeEvent?.actor_name;
    const fallbackActor = willBeChargedCCFeeChangeEvent?.actor_role === 'service' ? 'Automatic Service' : 'Unknown';

    return (
        <LoadBlocker blocking={loading}>
            <FakePracticeBanner organizationFragment={practicePaymentMethodsInfoQueryData?.getOrganization} />
            <PausedPracticeBanner practiceId={practiceId} />
            <ContentContainer>
                <TitleContainer>
                    <CollectPaymentMethodModal
                        open={open}
                        setOpen={setOpen}
                        practiceId={practiceId}
                        practiceName={org_legal_name}
                    />
                    <div>
                        <Text variant={'h5'} medium>
                            {org_legal_name} - Payment Methods
                        </Text>
                    </div>
                    {hasCapability && (
                        <LinkButtonContainer>
                            <Button variant={'primary'} onClick={() => setOpen(true)}>
                                Collect Payment Method
                            </Button>
                        </LinkButtonContainer>
                    )}
                </TitleContainer>
                <PaymentMethodContainer>
                    {practiceHasPaymentMethods ? (
                        <PaymentMethods loadedMethods={paymentMethods ?? []} />
                    ) : (
                        <Text variant={'body2'} color={'GRAY'}>
                            No payment methods on file.{' '}
                            {hasCapability && (
                                <StyledText
                                    variant={'body2'}
                                    medium
                                    color={'PRIMARY_FOREGROUND'}
                                    onClick={() => setOpen(true)}
                                >
                                    Collect Payment Method
                                </StyledText>
                            )}
                        </Text>
                    )}
                </PaymentMethodContainer>
                <OptedForFeesContainer>
                    <Text variant={'caption'} medium>
                        CC Fee Enabled
                    </Text>
                    <Text variant={'caption'} color={'GRAY'}>
                        {practiceWillBeChargedCCFee
                            ? `Yes by ${actorName ?? fallbackActor} on ${moment(
                                  willBeChargedCCFeeChangeEvent?.event_timestamp,
                              ).format(`MM/DD/YY, h:mm a`)}`
                            : 'No'}
                    </Text>
                </OptedForFeesContainer>
            </ContentContainer>
        </LoadBlocker>
    );
};
