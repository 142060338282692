import { FlossPalette, Grid, InputAdornment, IosSwitch, Slider, Text, TextField, styled } from '@orthly/ui-primitives';
import React from 'react';

interface SwitchWithSliderProps {
    checked: boolean;
    onClick: () => void;
    label: string;
    hint?: string;
    value: number | undefined;
    onChange?: (newValue: number) => void;
    showSlider?: boolean;
}

const HintContainer = styled('div')({
    maxWidth: 200,
    lineHeight: 0.75,
});

const StyledSlider = styled(Slider)(({ theme }) => ({
    color: FlossPalette.STAR_GRASS,
    height: 3,
    padding: '13px 0',
    marginTop: 8,
    '& .MuiSlider-thumb': {
        height: 27,
        width: 27,
        backgroundColor: FlossPalette.STAR_GRASS,
        border: '1px solid white',
        '&:hover': {
            boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
        },
    },
    '& .MuiSlider-track': {
        height: 3,
    },
    '& .MuiSlider-rail': {
        color: theme.palette.mode === 'dark' ? FlossPalette.TAN : FlossPalette.DARK_TAN,
        opacity: theme.palette.mode === 'dark' ? undefined : 1,
        height: 3,
    },
}));

export const SwitchWithSlider: React.VFC<SwitchWithSliderProps> = ({
    checked,
    onClick,
    label,
    hint,
    value,
    onChange,
    showSlider = true,
}) => {
    const handleSliderChange = (_: any, value: number | number[]) => {
        onChange?.(value as number);
    };
    const percentValue = (value ?? 0).toFixed(0);
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10) || 0;
        onChange && onChange(Math.max(0, Math.min(value, 100)));
    };
    return (
        <Grid container alignItems={'top'} wrap={'wrap'}>
            <Grid item xs={'auto'} style={{ paddingTop: 0 }}>
                <IosSwitch checked={checked} onClick={onClick} />
            </Grid>
            <Grid item style={{ padding: '0.6rem' }}>
                <Text
                    variant={'caption'}
                    bold
                    color={checked ? 'BLACK' : 'STAR_GRASS'}
                    style={{ paddingRight: '1rem' }}
                >
                    {label}
                </Text>
                <br />
                <HintContainer>
                    <Text variant={'caption'} color={'GRAY'}>
                        {hint}
                    </Text>
                </HintContainer>
                {checked && showSlider && (
                    <Grid
                        container
                        item
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        style={{ minWidth: 240 }}
                    >
                        <Grid item xs={8}>
                            <StyledSlider
                                max={100}
                                min={0}
                                value={value}
                                aria-label={'Disabled slider'}
                                onChange={handleSliderChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                style={{ marginLeft: 4 }}
                                variant={'standard'}
                                value={percentValue}
                                InputProps={{
                                    inputProps: {
                                        sx: { padding: '8px 0 8px 4px !important', width: 32 },
                                        'aria-label': 'Opacity',
                                    },
                                    endAdornment: (
                                        <InputAdornment
                                            position={'end'}
                                            style={{ marginLeft: 0, marginRight: 4, padding: 0 }}
                                        >
                                            %
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true,
                                }}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
