import { OrderListItem } from '../../../../../components/OrderListItem/OrderListItem';
import { usePartnerAndLabNames } from '../../../../../utils/usePartnerAndLabNames';
import { useCompletedAutomationFilter } from '../../../state/AutomationBuilder.selectors';
import { useOrders, useOrdersMatchingAutomationFilterCountQuery } from '@orthly/graphql-react';
import type { LabsGqlFilterSubmissionInput } from '@orthly/graphql-schema';
import { LabsGqlLabOrderSortKey, LabsGqlFilterCombinator } from '@orthly/graphql-schema';
import { LoadBlocker } from '@orthly/ui';
import { Button, Box, Dialog, DialogContent, Grid, List, Tooltip, Typography } from '@orthly/ui-primitives';
import { useOrderToOpsListItemContent, OrderListNoResultsItem } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const EMPTY_FILTER_SUBMISSION: LabsGqlFilterSubmissionInput = {
    criteria: [],
    combinator: LabsGqlFilterCombinator.And,
};

const AutomationOrdersPreviewDialog: React.VFC = () => {
    const filter = useCompletedAutomationFilter();
    const [open, setOpen] = React.useState(false);
    const { orders, loading } = useOrders(
        {
            criteria: filter?.criteria ?? [],
            limit: 50,
            sort: { key: LabsGqlLabOrderSortKey.CreatedAt, asc: false },
            combinator: filter?.combinator,
        },
        { skip: !filter || filter.criteria.length === 0 || !open },
    );
    const MIN_AGE_DAYS_FOR_COUNT: number = 30;
    const { data: countData, loading: countLoading } = useOrdersMatchingAutomationFilterCountQuery({
        variables: { automationFilter: filter ?? EMPTY_FILTER_SUBMISSION, minAgeDays: MIN_AGE_DAYS_FOR_COUNT },
    });
    const count = countData?.ordersMatchingAutomationFilterCount;
    const { labNamesById, partnerNamesById } = usePartnerAndLabNames();
    const toListItemContent = useOrderToOpsListItemContent(labNamesById, partnerNamesById);
    const disabledMessage = !filter || filter.criteria.length === 0 ? 'Add filter criteria to preview' : undefined;
    const countMessage = React.useMemo(() => {
        if (countLoading) {
            return <div>Loading affected orders count...</div>;
        } else if (_.isNil(count)) {
            /* this happens frequently during editing and we don't want to overload with error color, that should be reserved for exceptional cases */
            return <div>Filter is invalid. Please update before creating automation.</div>;
        } else if (count === -1) {
            return (
                <div>
                    <Typography color={'error'}>
                        Filter requires runtime data and no estimate can be generated. Please verify the correctness
                        before creating automation!
                    </Typography>
                </div>
            );
        } else {
            return (
                <div>
                    <Typography color={(count ?? 0) >= 100 ? 'error' : 'inherit'}>
                        This filter <em>currently</em> affects an estimated <strong>{count}</strong> orders created in
                        the last {MIN_AGE_DAYS_FOR_COUNT} days.
                    </Typography>
                </div>
            );
        }
    }, [countLoading, count]);
    return (
        <>
            <Grid container item justifyContent={'center'} xs={5}>
                <Tooltip title={disabledMessage ?? ''} arrow>
                    {/* Need a div here to have the tooltip show on a disabled button */}
                    <div>
                        <LoadBlocker blocking={loading} ContainerProps={{ style: { width: 'auto' } }}>
                            <Button onClick={() => setOpen(true)} disabled={!!disabledMessage} variant={'primary'}>
                                Preview Filter (Across All Orders)
                            </Button>
                        </LoadBlocker>
                    </div>
                </Tooltip>
            </Grid>
            <Grid container item justifyContent={'center'}>
                <Box my={2}>{countMessage}</Box>
            </Grid>
            <Dialog
                open={open && !loading}
                transitionDuration={{ exit: 0 }}
                onClose={() => setOpen(false)}
                maxWidth={'lg'}
                fullWidth
            >
                <DialogContent dividers={true} style={{ minHeight: '150px' }}>
                    <List>
                        {orders.map(order => (
                            <OrderListItem
                                key={order.id}
                                // `useOrders` doesn't return `aligner_case` in its results.
                                // This may cause a slight UI defect, but nothing critical.
                                listItemContent={toListItemContent({ aligner_case: null, ...order })}
                            />
                        ))}
                        {!orders.length && <OrderListNoResultsItem message={'No orders found for this filter'} />}
                    </List>
                </DialogContent>
            </Dialog>
        </>
    );
};

export const OrderFilterResultsPreview: React.FC = () => {
    return (
        <Grid container alignItems={'center'} spacing={1} direction={'column'}>
            <AutomationOrdersPreviewDialog />
        </Grid>
    );
};
