import type { LabsGqlLabSlipAlertFragment } from '@orthly/graphql-operations';
import { LabsGqlDoctorAlertLocation } from '@orthly/graphql-schema';
import { Text } from '@orthly/ui-primitives';
import React from 'react';

function designTaskLabSlipAlerts(alerts: LabsGqlLabSlipAlertFragment[]): LabsGqlLabSlipAlertFragment[] {
    return alerts.filter(alert => !!alert.visibility?.includes(LabsGqlDoctorAlertLocation.DesignTask));
}

export const DoctorDesignTaskAlerts: React.FC<{ lab_slip_alerts: LabsGqlLabSlipAlertFragment[] }> = props => {
    const alerts = designTaskLabSlipAlerts(props.lab_slip_alerts);

    if (alerts.length === 0) {
        return null;
    }

    return (
        <div>
            <Text variant={'body2'} color={'DARK_GRAY'}>
                Design task alerts:
            </Text>
            {alerts.map(alert => (
                <Text variant={'body2'} color={'BLACK'} key={alert.text}>
                    {alert.text}
                </Text>
            ))}
        </div>
    );
};
