import React from 'react';

/**
 * Delays changes to `value`, only propagating new values on the trailing edge of `delay`.
 * Debounced value is initialized as `value`.
 *
 * @param value The value to debounce.
 * @param delayMillis The debounce delay, in milliseconds.
 * @returns debouncedValue stores a ref to the scrollable container<br/>
 *
 * @example
 * const debouncedValue = useDebouncedValue(value, 100)
 */
export const useDebouncedValue = <T extends any>(value: T, delayMillis: number): T => {
    const [debouncedValue, setDebouncedValue] = React.useState(value);

    React.useEffect(
        () => {
            // update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delayMillis);

            // cancel the timeout if value changes (also on delay change or unmount)
            // this is how we prevent debounced value from updating if value is changed...
            // ...within the delay period, timeout gets cleared and restarted
            return () => {
                clearTimeout(handler);
            };
        },

        [value, delayMillis], // only re-call effect if value or delay changes
    );

    return debouncedValue;
};
