import { useOrdersOverviewAction } from '../../screens/Orders/state/OrdersOverview.actions';
import { useOrdersOverviewState } from '../../screens/Orders/state/OrdersOverview.selectors';
import { ORDER_DETAIL_LIST_ITEM_CLASSNAME } from './OrderDetailSidebarItemLayout';
import type { RequireChildrenProps } from '@orthly/ui';
import { LoadBlockerLoader, ResizeHandleIcon, SidebarContainer, ToolbarContainer } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';
import { ResizableBox } from 'react-resizable';

const SIDEBAR_WIDTH = 300;

const useStyles = stylesFactory(() => ({
    root: { padding: 0, flexWrap: 'nowrap', maxHeight: '100vh', '&::-webkit-scrollbar': { display: 'none' } },
    rootFixed: { width: SIDEBAR_WIDTH, minWidth: SIDEBAR_WIDTH },
    rootResizable: { width: 'inherit' },
    toolbar: { padding: '0 8px', alignItems: 'center', alignContent: 'center' },
    contentRoot: {
        height: '100vh',
        overflow: 'auto',
        alignContent: 'flex-start',
        position: 'relative',
        [`& .${ORDER_DETAIL_LIST_ITEM_CLASSNAME}`]: { opacity: 0.5 },
        [`&:hover .${ORDER_DETAIL_LIST_ITEM_CLASSNAME}`]: { opacity: 1 },
    },
}));

const useResizeStyles = stylesFactory(theme => ({
    icon: {
        color: FlossPalette.DARK_TAN,
        position: 'absolute',
        top: 20,
        left: 4,
        cursor: 'grab',
        [theme.breakpoints.down('lg')]: { top: 16 },
    },
    barLeft: { position: 'absolute', left: -4, width: 10, bottom: 0, top: 0, cursor: 'ew-resize', zIndex: 1100 },
    overlay: { width: '100vw', position: 'fixed', left: 0, bottom: 0, top: 0, right: 0, zIndex: 1200 },
    container: { display: 'flex', position: 'relative', flex: 'none' },
}));

const ResizeContainer: React.FC<RequireChildrenProps> = props => {
    const classes = useResizeStyles();
    const [dragging, setDragging] = React.useState<'arrow' | 'icon' | undefined>(undefined);
    const saveTimelineWidthPct = useOrdersOverviewAction('SAVE_TIMELINE_WIDTH_PCT');
    const maxWidth = Math.max(window.innerWidth * (1 / 3), SIDEBAR_WIDTH);
    const timelineSidebarWidthPct = useOrdersOverviewState(s => s.timelineSidebarWidthPct);
    const initialWidth = timelineSidebarWidthPct
        ? Math.min(timelineSidebarWidthPct * window.innerWidth, maxWidth)
        : SIDEBAR_WIDTH;
    const cursor = dragging === 'arrow' ? 'ew-resize' : 'grabbing';
    return (
        <ResizableBox
            handleSize={[24, 24]}
            className={classes.container}
            onResizeStart={() => setDragging(current => current ?? 'arrow')}
            onResizeStop={(_e, { size }) => {
                setDragging(undefined);
                const newPctWidth = size.width / window.innerWidth;
                newPctWidth !== timelineSidebarWidthPct && saveTimelineWidthPct(newPctWidth);
            }}
            handle={
                <Grid container className={classes.barLeft}>
                    <ResizeHandleIcon className={classes.icon} onMouseDown={() => setDragging('icon')} />
                    {dragging && <Grid container className={classes.overlay} style={{ cursor }} />}
                </Grid>
            }
            height={window.innerHeight}
            width={initialWidth}
            minConstraints={[SIDEBAR_WIDTH, window.innerHeight]}
            maxConstraints={[maxWidth, window.innerHeight]}
            axis={'x'}
            resizeHandles={['nw']}
        >
            {props.children}
        </ResizableBox>
    );
};

interface OrderDetailSidebarLayoutProps extends RequireChildrenProps {
    position: 'left' | 'right';
    toolbarContent: React.ReactNode;
    loading?: boolean;
    resizable?: boolean;
}

export const OrderDetailSidebarLayout: React.FC<OrderDetailSidebarLayoutProps> = props => {
    const classes = useStyles();
    const Container = props.resizable ? ResizeContainer : React.Fragment;
    return (
        <Container>
            <SidebarContainer
                position={props.position}
                className={clsx(classes.root, props.resizable ? classes.rootResizable : classes.rootFixed)}
            >
                <ToolbarContainer className={classes.toolbar}>{props.toolbarContent}</ToolbarContainer>
                <Grid container className={classes.contentRoot}>
                    <LoadBlockerLoader loader={'linear'} blocking={!!props.loading} />
                    {props.children}
                </Grid>
            </SidebarContainer>
        </Container>
    );
};
