import { useCreditOrRefundFormContext } from './providers/CreditOrRefundFormProvider.graphql';
import { Format } from '@orthly/runtime-utils';
import { Button, FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const Footer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '120px',
    padding: '16px 32px',
    gap: '10px',
    borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
});

const TotalCreditAmountContainer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
});

const ActionButtonContainer = styled('div')({
    display: 'flex',
    gap: '16px',
});

export const CreditOrRefundFooter: React.FC = () => {
    const { formState, setOpen, order, submit, disableSubmit, viewOnly, existingCredit } =
        useCreditOrRefundFormContext();

    const numberOfItemsWithAmountDue = order?.items_v2.filter(item => {
        const itemPrice = item.pricing.override_amount_due_cents ?? item.pricing.amount_due_cents ?? 0;
        return itemPrice > 0;
    }).length;

    const existingCreditHasOrderAttribution =
        existingCredit?.attribution && existingCredit.attribution?.__typename === 'OrderAdjustmentAttribution';

    const newAttributedOrderCreditText =
        formState.selectedItems.length === numberOfItemsWithAmountDue
            ? 'Order'
            : `${Format.pluralize('Item', formState.selectedItems.length)}`;
    // Since individual credits are created for each order_item attribution created from this modal, if we are viewing
    // an existing credit, then the attribution is either the entire order or just a single item
    const existingAttributedOrderCreditText = existingCreditHasOrderAttribution ? 'Order' : '1 Item';

    return (
        <Footer>
            <TotalCreditAmountContainer>
                <Text variant={'body2'} color={'DARK_GRAY'} medium>
                    {order
                        ? `Total ${formState.action === 'credit' ? 'Credits' : 'Refund'} Applied on ${viewOnly ? existingAttributedOrderCreditText : newAttributedOrderCreditText}`
                        : `Total ${formState.action === 'credit' ? 'Credit' : 'Refund'} Applied`}
                </Text>
                <Text variant={'body2'} color={'PRIMARY_FOREGROUND'} medium>
                    {Format.currency(parseFloat(formState.amountDollars) || 0, 'dollars')}
                </Text>
            </TotalCreditAmountContainer>
            <ActionButtonContainer>
                <Button variant={'secondary-gray'} fullWidth onClick={() => setOpen(false)}>
                    Close Panel
                </Button>
                {!viewOnly && !formState.submitted && (
                    <Button variant={'primary'} fullWidth disabled={disableSubmit} onClick={submit}>
                        {formState.action === 'credit' ? 'Apply Credit' : 'Apply Refund'}
                    </Button>
                )}
            </ActionButtonContainer>
        </Footer>
    );
};
