import { DandyMouthLinearDisplay } from './DandyMouthLinearDisplay';
import type { InterproximalSpace, InterproximalSpaceSize } from '@orthly/items';
import { AllUpperInterproximalSpaces, AllLowerInterproximalSpaces } from '@orthly/items';
import { stylesFactory, FlossPalette, AddIcon } from '@orthly/ui-primitives';
import cx from 'classnames';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    inputWrapper: {
        height: 32,
        width: 32,
        backgroundColor: FlossPalette.WHITE,
        overflow: 'hidden',
        border: `1px solid ${FlossPalette.DARK_TAN}`,
    },
    inputWrapperDisabled: {
        opacity: 0.4,
    },
    plusWrapper: {
        height: '100%',
        width: '100%',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: FlossPalette.GRAY,
        '&:hover': {
            backgroundColor: FlossPalette.STAR_GRASS_HOVER,
        },
    },
    plusWrapperDisabled: {
        cursor: 'default',
        '&:hover': {
            backgroundColor: FlossPalette.WHITE,
        },
    },
    input: {
        border: 'none',
        height: '100%',
        width: '100%',
        textAlign: 'center',
        fontFamily: 'Bagoss Standard, Verdana, sans-serif',
        '-moz-appearance': 'textfield',
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '&:focus': {
            outline: 'none',
        },
    },
}));

interface InterproximalSpaceSelectorContextProps {
    value: InterproximalSpaceSize[] | null;
    onChange: (val: InterproximalSpaceSize[]) => void;
    upperEnabled?: boolean;
    lowerEnabled?: boolean;
}

const InterproximalSpaceSelectorContext = React.createContext<InterproximalSpaceSelectorContextProps>({
    value: null,
    onChange: () => {},
    upperEnabled: true,
    lowerEnabled: true,
});

interface InterproximalSpaceInputProps {
    space: InterproximalSpace;
}

const InterproximalSpaceInput: React.VFC<InterproximalSpaceInputProps> = ({ space }) => {
    const classes = useStyles();
    const { value, onChange, upperEnabled, lowerEnabled } = React.useContext(InterproximalSpaceSelectorContext);
    const isEnabled = React.useMemo(() => {
        return (
            (upperEnabled && AllUpperInterproximalSpaces.includes(space)) ||
            (lowerEnabled && AllLowerInterproximalSpaces.includes(space))
        );
    }, [space, upperEnabled, lowerEnabled]);

    const findFn = (v: InterproximalSpaceSize) => _.isEqual(v.space, space);

    const spaceValue = (value && value.find(findFn)) ?? null;

    const updateSpace = (size: string) => {
        const newSize = parseFloat(size);
        if (value && spaceValue) {
            const newValue = [...value];
            newValue[newValue.findIndex(findFn)] = { space, size: newSize };
            onChange(newValue);
        } else {
            onChange([...(value || []), { space, size: newSize }]);
        }
    };

    return (
        <div className={cx(classes.inputWrapper, { [classes.inputWrapperDisabled]: !isEnabled })}>
            {spaceValue && isEnabled ? (
                <input
                    onChange={event => updateSpace(event.target.value)}
                    defaultValue={`${spaceValue.size}`}
                    className={classes.input}
                    type={'number'}
                    inputMode={'numeric'}
                    min={'0'}
                    step={'0.1'}
                    autoFocus
                />
            ) : (
                <div
                    className={cx(classes.plusWrapper, { [classes.plusWrapperDisabled]: !isEnabled })}
                    onClick={() => isEnabled && updateSpace('0')}
                >
                    <AddIcon color={'inherit'} />
                </div>
            )}
        </div>
    );
};

interface DandyMouthInterproximalSpaceSelectorProps extends InterproximalSpaceSelectorContextProps {
    verticalLayout?: boolean;
}

export const DandyMouthInterproximalSpaceSelector: React.VFC<DandyMouthInterproximalSpaceSelectorProps> = ({
    value,
    onChange,
    upperEnabled = true,
    lowerEnabled = true,
    verticalLayout = false,
}) => {
    const interproximalSpaceItems = React.useMemo(() => {
        if (value) {
            return _.compact(
                value.map(({ space, size }) => (size > 0 ? { space, activeColor: FlossPalette.STAR_GRASS } : null)),
            );
        }
    }, [value]);

    return (
        <InterproximalSpaceSelectorContext.Provider value={{ value, onChange, upperEnabled, lowerEnabled }}>
            <DandyMouthLinearDisplay
                verticalLayout={verticalLayout}
                interproximalSpaceItems={interproximalSpaceItems}
                InterproximalSpaceAction={InterproximalSpaceInput}
            />
        </InterproximalSpaceSelectorContext.Provider>
    );
};
