import { DESIGN_FILE_VALIDATION_ERROR_TO_HUMAN_STRING_MAP } from '../../util';
import { useFeatureFlag } from '../Providers/LaunchDarkly';
import { useApolloClient } from '@apollo/client';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlDesignOrderRevisionValidationResultStatus } from '@orthly/graphql-schema';
import React from 'react';

const VeneerOrderDesignTaskValidation_Query = graphql(`
    query VeneerOrderDesignTaskValidationQuery($orderId: String!, $designFilePath: String!) {
        validateDesignOrderRevisionUpload(labOrderId: $orderId, designFilePath: $designFilePath) {
            validation_status
            old_design_validation_errors
            case_file_invalid_differences
        }
    }
`);

export type DesignSubmissionValidationResult =
    | { status: 'success' }
    | {
          status: 'failed';
          errorLines: string[];
          canBypass: boolean;
      };

export function useValidateDesign(orderId: string, task?: { type: string; id: string }) {
    const client = useApolloClient();

    const [result, setResult] = React.useState<DesignSubmissionValidationResult | undefined>(undefined);
    const [validationLoading, setValidationLoading] = React.useState<boolean>(false);

    const { value: isValidationBypassable } = useFeatureFlag('designFileValidationAllowBypassingValidation');
    const validateDesign = React.useCallback(
        async (filePath: string): Promise<DesignSubmissionValidationResult> => {
            try {
                setValidationLoading(true);
                const { data } = await client.query({
                    query: VeneerOrderDesignTaskValidation_Query,
                    variables: {
                        orderId,
                        designFilePath: filePath,
                    },
                });

                const { case_file_invalid_differences, old_design_validation_errors, validation_status } =
                    data.validateDesignOrderRevisionUpload;

                const allValidationErrors = [
                    ...case_file_invalid_differences,
                    ...old_design_validation_errors.map(
                        err => DESIGN_FILE_VALIDATION_ERROR_TO_HUMAN_STRING_MAP[err] ?? err,
                    ),
                ];

                BrowserAnalyticsClientFactory.Instance?.track('Ops - Portal - Design Validation Ran', {
                    $groups: {
                        order: orderId,
                    },
                    validationErrors: allValidationErrors,
                    succeeded: validation_status === LabsGqlDesignOrderRevisionValidationResultStatus.Passed,
                    taskType: task?.type,
                    taskId: task?.id,
                });

                return validation_status === LabsGqlDesignOrderRevisionValidationResultStatus.Passed
                    ? {
                          status: 'success',
                      }
                    : {
                          status: 'failed',
                          errorLines: allValidationErrors,
                          canBypass: !!isValidationBypassable,
                      };
            } finally {
                setValidationLoading(false);
            }
        },
        [client, orderId, isValidationBypassable, setValidationLoading, task],
    );

    /*
     * Checks if a provided design file is valid for this order.
     * If it is, returns true.
     * If it's not, returns false and stores the validation issues in the `result` state variable.
     * If run a subsequent time after an original failure, will return true if and only if result.canBypass is true.
     */
    const checkDesignValid = React.useCallback(
        async (filePath: string) => {
            // We will only end up querying if validation hasn't yet ran.
            // If they rerun, we will avoid re-querying, and if they re-upload the design, the validation error will have been cleared.
            if (!result) {
                const newResult = await validateDesign(filePath);
                setResult(newResult);

                return newResult.status === 'success';
            }

            // This is the 2nd+ run, but they're not allowed to bypass the failure -> die.
            if (result.status === 'failed' && !result.canBypass) {
                return false;
            }

            // They are allowed to bypass, so let's confirm with them that they're really sure before we just allow it.
            if (result.status === 'failed') {
                const confirmedOverride = window.confirm(
                    'Are you sure you want to bypass the automated validation, and assert your design is for the correct patient and follows the RX SOPs?',
                );
                return confirmedOverride;
            }

            return true;
        },
        [result, validateDesign, setResult],
    );

    return {
        result,
        validationLoading,
        checkDesignValid,
        reset: () => setResult(undefined),
    };
}
