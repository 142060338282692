import { useFirebasePreview } from '../../hooks';
import { isBlobURL } from '../../util';
import { PROFILE_PHOTO_COLOR_TO_HEX } from './DoctorProfilePhotoColors';
import type { LabsGqlDoctorProfilePhotoColor } from '@orthly/graphql-schema';
import { LabsGqlDoctorProfilePhotoType } from '@orthly/graphql-schema';
import { OrderFormatUtils, getInitials } from '@orthly/shared-types';
import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    doctorProfilePhoto: {
        width: 64,
        aspectRatio: '1',
        borderRadius: '25%',
        fontSize: 20,
        color: FlossPalette.WHITE,
        overflow: 'hidden',
        backgroundSize: 'cover',
        userSelect: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

interface DoctorProfilePhotoProps {
    type: LabsGqlDoctorProfilePhotoType;
    source: string;
    color: LabsGqlDoctorProfilePhotoColor;
    selected?: boolean;
    onClick?: (type_source: Pick<DoctorProfilePhotoProps, 'type' | 'source'>) => void;
    style?: React.CSSProperties;
    className?: string;
    sourceCanbeBlob?: boolean;
}

export const DoctorProfilePhotoBackground: React.FC<DoctorProfilePhotoProps> = props => {
    const { type, source, color, selected, onClick, style, className } = props;
    const classes = useStyles();
    return (
        <div
            className={clsx(classes.doctorProfilePhoto, className)}
            style={{
                cursor: onClick ? 'pointer' : undefined,
                backgroundColor:
                    type === LabsGqlDoctorProfilePhotoType.Uploaded ? 'none' : PROFILE_PHOTO_COLOR_TO_HEX[color],
                boxShadow: selected
                    ? `0 0 0 2px ${FlossPalette.WHITE}, 0 0 0 4px ${PROFILE_PHOTO_COLOR_TO_HEX[color]}`
                    : undefined,
                textTransform: type === LabsGqlDoctorProfilePhotoType.None ? 'uppercase' : undefined,
                ...style,
            }}
            onClick={() => onClick?.({ type, source })}
        >
            {props.children}
        </div>
    );
};

const FirebaseBackground: typeof DoctorProfilePhotoBackground = props => {
    const { result } = useFirebasePreview(props.source);
    return (
        <DoctorProfilePhotoBackground
            {...props}
            style={{ backgroundImage: result && `url("${result}")`, ...props.style }}
        />
    );
};

export const DoctorProfilePhotoContent: React.FC<DoctorProfilePhotoProps> = props => {
    const { type, source, sourceCanbeBlob } = props;
    if (type === LabsGqlDoctorProfilePhotoType.None) {
        const initials = getInitials(OrderFormatUtils.getNonPrefixedDoctorName(source));
        return <DoctorProfilePhotoBackground {...props}>{initials}</DoctorProfilePhotoBackground>;
    }
    if (type === LabsGqlDoctorProfilePhotoType.Avatar) {
        return (
            <DoctorProfilePhotoBackground {...props} style={{ backgroundImage: `url("${source}")`, ...props.style }} />
        );
    }
    if (type === LabsGqlDoctorProfilePhotoType.Uploaded) {
        if (sourceCanbeBlob && isBlobURL(source)) {
            return (
                <DoctorProfilePhotoBackground
                    {...props}
                    style={{ backgroundImage: `url("${source}")`, ...props.style }}
                />
            );
        }
        return <FirebaseBackground {...props} />;
    }
    return null;
};
