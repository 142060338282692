import bite_alignment_contact_depth_BAD from '../../../../assets/images/design-qc/bite/bite_alignment_contact_depth_BAD.png';
import bite_alignment_contact_depth_GOOD from '../../../../assets/images/design-qc/bite/bite_alignment_contact_depth_GOOD.png';
import tom_sculpting_acceptable_bad from '../../../../assets/images/design-qc/tom_sculpting/tom_sculpting_ACCEPTABLE_BAD.png';
import type { DesignQcRubricCategoryEntry } from './DesignQc.config.types';
import { DEFAULT_SCAN_APPEARANCE, DEFAULT_RESTORATIVE_APPEARANCE } from '@orthly/dentin';

export const tomSculptingRubric: DesignQcRubricCategoryEntry = {
    category: 'tom_sculpting',
    subcategories: [
        {
            category: 'tom_sculpting',
            name: 'Sculpted where needed',
            grading: {
                type: 'binary',
                bad: '<100% of TOM Sculpted, OR areas that were not TOM were sculpted.',
                ideal: '100% of TOM sculpted',
            },
            description: 'Are all areas of TOM sculpted? Did they sculpt areas that were NOT TOM?',
            selectable_bad_reasons: ['Some TOM still present', 'Areas were sculpted that were not TOM'],
            examples: {
                bad: [tom_sculpting_acceptable_bad],
                ideal: [tom_sculpting_acceptable_bad],
            },
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                        colorize: false,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    order_scans_raw_prep: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                },
            },
        },
        {
            category: 'tom_sculpting',
            name: 'Correctly Sculpted',
            grading: {
                type: 'binary',
                bad: 'Incorrectly Sculpted',
                ideal: 'Correctly Sculpted',
            },
            examples: {
                bad: [bite_alignment_contact_depth_BAD],
                ideal: [bite_alignment_contact_depth_GOOD],
            },
            description: `Where TOM is present, is it appropriately sculpted?<br><br>Mistakes to look for include fully carving in a shoulder/ledge that does not exist`,
            selectable_bad_reasons: ['TOM is incorrectly sculpted'],
            presets: {
                view_direction_preset: {
                    type: 'axis_view',
                    view: 'prep_from_opposing',
                },
                payload_presets: {
                    restoratives_cad: {
                        ...DEFAULT_RESTORATIVE_APPEARANCE,
                        visible: false,
                    },
                    preparation_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                        colorize: false,
                    },
                    opposing_jaw: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    printed_models: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: false,
                    },
                    order_scans_raw_prep: {
                        ...DEFAULT_SCAN_APPEARANCE,
                        visible: true,
                    },
                },
            },
        },
    ],
    design_standard: {
        googleEmbedStub: `2PACX-1vSE1jdLgOZ1iHasl2qARDcKoF3CQbWJvbD9dhf6kQ62Sou8EcfzFu4gv3uiJkqP7v_UkhoB6zrMS0Ug`,
    },
};
