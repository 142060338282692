import { useFeatureFlag } from '../../Providers/LaunchDarkly';
import {
    useSceneState,
    useCrossSection,
    useCameraControlsRef,
    useCanvasChildren,
    useCanvasSiblings,
    useCanvasRef,
    useDeformState,
    useSculptingState,
    useInsertionAxisState,
} from '../AppState.hooks';
import { useSetupMainViewKeyboardControls } from '../MainViewControls.hooks';
import { BottomToolbar } from './BottomToolbar';
import { ToolsToolbar } from './ToolsToolbar';
import type { ToolsToolbarProps } from './ToolsToolbar/Types';
import type { ICameraControls } from '@orthly/dentin';
import { SceneViewer, ModelViewerControls } from '@orthly/dentin';
import { HeatMapType } from '@orthly/forceps';
import { StackX } from '@orthly/ui';
import { stylesFactory, Box } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        height: '100%',
        flex: 'auto',
        // This is required to get the element to shrink properly. It seems like it may be some interaction with the
        // ModelViewerCanvas component.
        overflow: 'hidden',
    },
    canvasRoot: {
        height: '100%',
        flex: 'auto',
        // This is required to get the element to shrink properly. It seems like it may be some interaction with the
        // ModelViewerCanvas component.
        overflow: 'hidden',
        // This is a hack to get the bottom toolbar in MainView to be positioned with respect to that root element.
        // Otherwise, the toolbar is centered with respect to the whole screen due to nuances in how the containing
        // block is selected.
        transform: 'translate(0, 0)',
    },
    bottomToolbar: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: 56,
        width: '100%',
    },
}));

export const MainView: React.VFC = () => {
    const { value: enableFinishingCurtainsHeatmap } = useFeatureFlag('enableFinishingCurtainsHeatmap');
    const { value: enableCementGapHeatmap } = useFeatureFlag('enableCementGapHeatmap');

    const classes = useStyles();

    const sceneState = useSceneState();
    const crossSection = useCrossSection();
    const canvasChildren = useCanvasChildren();
    const canvasSiblings = useCanvasSiblings();
    const cameraControlsRef = useCameraControlsRef();
    const canvasRef = useCanvasRef();
    const deformState = useDeformState();
    const sculptingState = useSculptingState();
    const { manager } = useInsertionAxisState();

    const curtainsHeatmapActive = sceneState.appearance.curtainsHeatMapEnabled;
    const proximalHeatmapActive =
        sceneState.appearance.activeHeatMap === HeatMapType.Proximal && !curtainsHeatmapActive;
    const occlusalHeatmapActive = sceneState.appearance.activeHeatMap === HeatMapType.Occlusal;
    const thicknessHeatmapActive = sceneState.appearance.activeHeatMap === HeatMapType.Thickness;
    const undercutHeatmapActive = sceneState.appearance.activeHeatMap === HeatMapType.Undercut;
    const cementGapHeatmapActive = sceneState.appearance.activeHeatMap === HeatMapType.CementGap;

    const tools: ToolsToolbarProps['tools'] = {
        collisions: {
            onClick: () => sceneState.manager.setCollisionsVisibility(),
            active: sceneState.appearance.collisionsVisible,
        },
        collisionsCurtains: sceneState.appearance.collisionsVisible
            ? {
                  onClick: () => sceneState.manager.setCollisionsCurtainsVisibility(),
                  active: sceneState.appearance.collisionsCurtainsVisible,
              }
            : undefined,
        curtains: {
            onClick: () => sceneState.manager.setCurtainsVisibility(),
            active: sceneState.appearance.curtainsVisible,
        },
        margin: {
            onClick: () => sceneState.manager.setMarginLinesVisibility(),
            active: sceneState.appearance.marginLinesVisible,
        },
        insertionPaths: {
            onClick: () => sceneState.manager.setInsertionPathsVisibility(),
            active: sceneState.appearance.insertionPathsVisible,
        },
        crossSection: {
            onClick: () => crossSection.appActive.toggle(false),
            active: crossSection.appActive.open,
        },
        proximalHeatmap: {
            onClick: () =>
                sceneState.manager.setActiveHeatMap(proximalHeatmapActive ? undefined : HeatMapType.Proximal),
            active: proximalHeatmapActive,
        },
        curtainsHeatmap: enableFinishingCurtainsHeatmap
            ? {
                  onClick: () => sceneState.manager.setCurtainsHeatMapEnabled(curtainsHeatmapActive ? false : true),
                  active: curtainsHeatmapActive,
              }
            : undefined,
        occlusalHeatmap: {
            onClick: () =>
                sceneState.manager.setActiveHeatMap(occlusalHeatmapActive ? undefined : HeatMapType.Occlusal),
            active: occlusalHeatmapActive,
        },
        thicknessHeatmap: {
            onClick: () =>
                sceneState.manager.setActiveHeatMap(thicknessHeatmapActive ? undefined : HeatMapType.Thickness),
            active: thicknessHeatmapActive,
        },
        undercutHeatmap: {
            onClick: () =>
                sceneState.manager.setActiveHeatMap(undercutHeatmapActive ? undefined : HeatMapType.Undercut),
            active: undercutHeatmapActive,
        },
        scanUndercut: {
            onClick: () => sceneState.manager.toggleScanUndercutEnabled?.(),
            active: sceneState.appearance.scanUndercutHeatmapEnabled,
        },
        cementGapHeatmap: enableCementGapHeatmap
            ? {
                  onClick: () =>
                      sceneState.manager.setActiveHeatMap(cementGapHeatmapActive ? undefined : HeatMapType.CementGap),
                  active: cementGapHeatmapActive,
              }
            : undefined,
        sculptMask: {
            onClick: () => sceneState.manager.toggleIsMaskVisible(),
            active: sceneState.appearance.isMaskVisible,
        },
    };

    const initCameraControls = React.useCallback(
        (cameraControls: ICameraControls | null) => {
            cameraControlsRef.current = cameraControls;
            // setting this to true will re-render the deform tools and get the new camera controls
            deformState.setCameraControlsEnabled(true);
        },
        [cameraControlsRef, deformState],
    );

    useSetupMainViewKeyboardControls(
        sceneState.manager,
        crossSection,
        sceneState,
        sculptingState,
        deformState,
        cameraControlsRef,
        manager,
    );

    return (
        <StackX className={classes.root}>
            <Box className={classes.canvasRoot}>
                {canvasSiblings}
                <SceneViewer scene={sceneState.manager.scene} canvasRef={canvasRef}>
                    <ModelViewerControls ref={initCameraControls} use3ShapeViewerControls={true} />
                    {canvasChildren}
                </SceneViewer>
                <BottomToolbar className={classes.bottomToolbar} />
            </Box>
            <ToolsToolbar tools={tools} />
        </StackX>
    );
};
