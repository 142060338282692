import { CheckoutButtonSelect, ProductUnitTypeImages } from '@orthly/dentin';
import { ExtraCartItemV2DisplayUtils } from '@orthly/dentin';
import {
    CartItemV2Utils,
    CartItemV2UpdateUtils,
    CartItemV2CreateUtils,
    ExtraCartItemV2Utils,
    AllProductUnitTypes,
    AllExtraItemOptions,
    isValidProductUnitType,
} from '@orthly/items';
import type { ICartItemV2DTO, ProductUnitType, ExtraCartItemV2UnitType } from '@orthly/items';
import { RootActionDialog } from '@orthly/ui';
import { Button, FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import { ItemEditorV2, ItemEditorV2Utils } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    button: {
        minHeight: '90px',
        margin: '10px !important',
        borderLeftColor: FlossPalette.DARK_TAN,
        borderRightColor: FlossPalette.DARK_TAN,
        maxWidth: 'calc(20% - 20px)',
        [theme.breakpoints.down('md')]: {
            maxWidth: 'unset',
            margin: '0 !important',
        },
    },
    buttonSelected: {
        '& + $button': {
            borderLeftColor: FlossPalette.DARK_TAN,
        },
    },
    buttonGroup: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    imgWrap: {
        flexGrow: 1,
        padding: '3rem 10px',
    },
}));

const useButtonGroupStyles = stylesFactory(() => ({
    groupedOutlinedHorizontal: {
        '&:not(:last-child)': {
            borderRightColor: FlossPalette.DARK_TAN,
        },
    },
}));

const excludedProductUnitTypes: readonly ProductUnitType[] = ['WellnessScan', 'Aligners', 'Onlay', 'Unknown'];

const productUnitTypeOptions = AllProductUnitTypes.filter(u => !excludedProductUnitTypes.includes(u)).map(unitType => ({
    value: unitType,
    label: _.startCase(unitType),
    image_url: ProductUnitTypeImages.Large[unitType],
}));
const extraOptions = AllExtraItemOptions.filter(e => !(AllProductUnitTypes as string[]).includes(e.unit_type)).map(
    extra => ({
        label: extra.label,
        value: extra.unit_type,
        image_url: ExtraCartItemV2DisplayUtils.getImageForUnitType(extra.unit_type),
    }),
);
const productSelectOptions = [...productUnitTypeOptions, ...extraOptions];

interface AddOrderItemV2ProductSelectProps {
    onSelect: (unitType: ProductUnitType | ExtraCartItemV2UnitType) => void;
}

const AddOrderItemV2ProductSelect: React.VFC<AddOrderItemV2ProductSelectProps> = ({ onSelect }) => {
    const classes = useStyles();
    const btnGroupClasses = useButtonGroupStyles();

    return (
        <CheckoutButtonSelect
            classes={classes}
            buttonGroupClasses={btnGroupClasses}
            label={'What type of item do you want to add?'}
            mode={'single'}
            options={productSelectOptions}
            onChange={val => val && onSelect(val as ProductUnitType | ExtraCartItemV2UnitType)}
        />
    );
};

interface AddOrderItemV2DialogProps {
    doctorPreferencesId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    onSubmit: (item: ICartItemV2DTO) => Promise<any>;
    submitting?: boolean;
}

export const AddOrderItemV2Dialog: React.VFC<AddOrderItemV2DialogProps> = ({
    doctorPreferencesId,
    open,
    setOpen,
    onSubmit,
    submitting,
}) => {
    const [item, setItem] = React.useState<ICartItemV2DTO | null>(null);
    const [showError, setShowError] = React.useState<boolean>(false);

    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            hideButton={true}
            // reset on close
            onClose={() => {
                setItem(null);
                setShowError(false);
            }}
            loading={!!submitting}
            maxWidth={'lg'}
            title={`Add New ${item ? CartItemV2Utils.getDisplayName(item) : 'Item'}`}
            content={
                item ? (
                    <ItemEditorV2
                        item={item}
                        updateItem={update => setItem(i => i && CartItemV2UpdateUtils.updateItem(i, update))}
                        isInternal={true}
                        doctorPreferencesId={doctorPreferencesId}
                        showError={showError}
                    />
                ) : (
                    <AddOrderItemV2ProductSelect
                        onSelect={unitType => {
                            const newItem = isValidProductUnitType(unitType)
                                ? CartItemV2CreateUtils.buildNewItem(unitType, 1)
                                : ExtraCartItemV2Utils.itemFromUnitType(unitType);
                            if (newItem) {
                                setItem(newItem);
                            }
                        }}
                    />
                )
            }
            actions={
                item && (
                    <Button
                        variant={'primary'}
                        disabled={submitting}
                        onClick={() => {
                            if (!ItemEditorV2Utils.isItemComplete(item, true)) {
                                setShowError(true);
                                return;
                            }
                            const cleanedItem = ItemEditorV2Utils.cleanItem(item);

                            void onSubmit(cleanedItem).then(() => {
                                // reset state after submission
                                setOpen(false);
                                setItem(null);
                                setShowError(false);
                            });
                        }}
                    >
                        Save
                    </Button>
                )
            }
        />
    );
};
