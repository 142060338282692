import { useBulkSetWillChargeCCFeeContext } from './providers/BulkSetWillChargeCCFeeProvider.graphql';
import { downloadAsCsv } from '@orthly/mui-table';
import { styled, Button, FlossPalette, Icon, Text } from '@orthly/ui-primitives';
import { SimpleDropzone } from '@orthly/veneer';
import React from 'react';

const ActionsContainer = styled('div')({
    width: '100%',
    justifyContent: 'flex-end',
    display: 'flex',
});

const UploadTextContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
});

const StyledIcon = styled(Icon)({
    transform: 'rotate(180deg)',
    color: FlossPalette.PRIMARY_FOREGROUND,
    marginLeft: '8px',
});

const DownloadButton = styled(Button)({
    marginLeft: '16px',
});

export const BulkSetWillChargeCCFeeActions: React.FC = () => {
    const { onDropAccepted, loading } = useBulkSetWillChargeCCFeeContext();

    return (
        <ActionsContainer>
            <SimpleDropzone
                dropzoneContent={
                    <UploadTextContainer>
                        <Text variant={'body2'} medium color={'PRIMARY_FOREGROUND'}>
                            Upload CSV
                        </Text>
                        <StyledIcon icon={'DownloadIcon'} />
                    </UploadTextContainer>
                }
                wrapperStyle={{
                    minHeight: 24,
                    width: '112px',
                    padding: 0,
                    backgroundColor: 'transparent',
                    border: 'none',
                    cursor: loading ? 'not-allowed' : 'pointer',
                }}
                options={{ onDropAccepted, multiple: false, disabled: loading }}
            />

            <DownloadButton
                variant={'ghost'}
                endIcon={'DownloadIcon'}
                onClick={() => {
                    downloadAsCsv(
                        [{ partner_id: '', will_be_charged_cc_fee: '' }],
                        'Set_Will_Charge_CC_Processing_Fee',
                        { eol: '\n' },
                    );
                }}
            >
                Download CSV Template
            </DownloadButton>
        </ActionsContainer>
    );
};
