import { itemsToMap } from '../orderEditState';
import { blacklistedExtras } from '../pages/types';
import type { ItemInfo } from './orderEditMode';
import { getOrderEditMode } from './orderEditMode';
import type { Decision } from './orderEditMode';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { ItemDataFieldUtils, LabOrderItemSKUType, CartItemV2Utils, ExtraCartItemV2Utils } from '@orthly/items';
import type {
    ICustomFieldSubmission,
    IOrderItemShade,
    ICartItemImplantMetadata,
    ItemDataField,
    ICartItemV2DTOWithId,
} from '@orthly/items';
import { OrderEditMode } from '@orthly/shared-types';
import { isEmpty, map, keyBy, isEqual, every, forEach, isNil } from 'lodash';

const isNullBlankOrUndefined = (o: unknown) => typeof o === 'undefined' || o === null || o === '';
const isObject = (value: unknown): value is Record<string, unknown> => typeof value === 'object' && value !== null;
const objectDifference = <O extends Record<string, unknown>>(a: O, b: O): Record<string, unknown> => {
    const result: Record<string, unknown> = {};
    for (const key of Object.keys(a)) {
        const aValue = a[key];
        const bValue = b[key];
        if (!isEqual(aValue, bValue) && (!isNullBlankOrUndefined(aValue) || !isNullBlankOrUndefined(bValue))) {
            if (isObject(aValue) && isObject(bValue)) {
                const diff = objectDifference(aValue, bValue);
                if (!isEqual(diff, {})) {
                    result[key] = diff;
                }
            } else {
                result[key] = aValue;
            }
        }
    }
    return result;
};
const arrayDifference = <T>(changedArray: T[], originalArray: T[]): T[] => {
    return [...changedArray.filter(item => !originalArray.some(originalItem => isEqual(originalItem, item)))];
};
const itemsToMapPref = (items: ICustomFieldSubmission[]) => {
    return keyBy(items, item => item.field_id);
};
const itemsToMapShade = (items: IOrderItemShade[]) => {
    return keyBy(items, item => item.name);
};
type ItemRecord = Record<string, ICartItemV2DTOWithId>;

const diffTooth = (changedItems: ItemRecord, originalItems: ItemRecord) => {
    const singleToothDiff: number[][] = [];
    forEach(originalItems, item => {
        const changedItem = changedItems[item.id];
        if (changedItem) {
            const originalTooth = CartItemV2Utils.getUniqueUNNs(item);
            const changedTooth = CartItemV2Utils.getUniqueUNNs(changedItem);
            const tooth = arrayDifference(changedTooth, originalTooth);
            !isEmpty(tooth) && singleToothDiff.push(tooth);
        }
    });
    return singleToothDiff;
};

const diffArch = (changedItems: ItemRecord, originalItems: ItemRecord) => {
    const archDiff: string[] = [];
    forEach(originalItems, item => {
        const changedItem = changedItems[item.id];
        if (
            changedItem &&
            CartItemV2Utils.isArchItem(item) &&
            CartItemV2Utils.isArchItem(changedItem) &&
            changedItem.unit.arch &&
            item.unit.arch !== changedItem.unit.arch
        ) {
            archDiff.push(changedItem.unit.arch);
        }
    });
    return archDiff;
};
const diffShade = (changedItems: ItemRecord, originalItems: ItemRecord) => {
    const shadeDiff: Record<string, unknown>[] = [];
    forEach(originalItems, item => {
        const changedItem = changedItems[item.id];
        if (changedItem) {
            const originalShades = itemsToMapShade(item.shades);
            const changedItemsShade = itemsToMapShade(changedItem.shades);
            const shade = objectDifference(changedItemsShade, originalShades);
            if (!isEmpty(shade)) {
                shadeDiff.push(shade);
            }
        }
    });
    return shadeDiff;
};

const diffMat = (
    changedItems: Record<string, ICartItemV2DTOWithId>,
    originalItems: Record<string, ICartItemV2DTOWithId>,
) => {
    const material: string[][] = [];
    forEach(originalItems, item => {
        const changedItem = changedItems[item.id];
        if (changedItem) {
            const originalMat = CartItemV2Utils.getAllMaterials(item);
            const changedMat = CartItemV2Utils.getAllMaterials(changedItem);
            const mat = arrayDifference(changedMat, originalMat);
            !isEmpty(mat) && material.push(mat);
        }
    });
    return material;
};
const diffPref = (changedItems: ItemRecord, originalItems: ItemRecord) => {
    const diffPref: Record<string, unknown>[] = [];

    map(originalItems, item => {
        const changedItem = changedItems[item.id];
        if (changedItem) {
            const originalPrefFields = itemsToMapPref(
                item.preference_fields.filter(
                    pref => !ItemDataFieldUtils.allReplacedMetafieldIds.includes(pref.field_id),
                ),
            );
            const changedItemsPref = itemsToMapPref(
                changedItem.preference_fields.filter(
                    pref => !ItemDataFieldUtils.allReplacedMetafieldIds.includes(pref.field_id),
                ),
            );
            const pref = objectDifference(changedItemsPref, originalPrefFields);
            !isEmpty(pref) && diffPref.push(pref);
        }
    });

    return diffPref;
};

type ItemDataFieldValue = ReturnType<ItemDataField['getValue']>;

// considers arrays with no values to be equal to null for comparisons
// considers null and undefined to be equal
const isDataFieldValueEqual = (a: ItemDataFieldValue, b: ItemDataFieldValue): boolean => {
    const getValue = (value: ItemDataFieldValue) => {
        if (isNil(value)) {
            return null;
        }
        if (Array.isArray(value) && !value.length) {
            return null;
        }
        return value;
    };

    const aValue = getValue(a);
    const bValue = getValue(b);

    return isEqual(aValue, bValue);
};

const diffItemDataFields = (changedItems: ItemRecord, originalItems: ItemRecord) => {
    const diffedDataFields: { field_id: string; value: ItemDataFieldValue }[] = [];
    map(originalItems, item => {
        const changedItem = changedItems[item.id];
        if (changedItem && item.sku === changedItem.sku) {
            ItemDataFieldUtils.getItemDataFields(item).forEach(field => {
                const originalValue = field.getValue(item);
                const changedValue = field.getValue(changedItem);
                if (!isDataFieldValueEqual(originalValue, changedValue)) {
                    diffedDataFields.push({ field_id: field.id, value: changedValue });
                }
            });
        }
    });
    return diffedDataFields;
};
const diffItemNotes = (changedItems: ItemRecord, originalItems: ItemRecord) => {
    const notesDiff: unknown[] = [];
    forEach(originalItems, item => {
        const changedItem = changedItems[item.id];
        if (changedItem && item.item_notes !== changedItem.item_notes) {
            notesDiff.push(changedItem.item_notes);
        }
    });
    return notesDiff;
};

const implantArray = [LabOrderItemSKUType.Implant, LabOrderItemSKUType.ImplantBridge] as const;
const diffImplantMetaData = (changedItems: ItemRecord, originalItems: ItemRecord) => {
    const implantMetaDiff: ICartItemImplantMetadata[][] = [];
    forEach(originalItems, item => {
        const changedItem = changedItems[item.id];
        if (
            changedItem &&
            CartItemV2Utils.itemIsType(item, implantArray) &&
            CartItemV2Utils.itemIsType(changedItem, implantArray)
        ) {
            const originalImplantMeta = CartItemV2Utils.getAllImplantMetadata(item);
            const changedImplantMeta = CartItemV2Utils.getAllImplantMetadata(changedItem);
            const diff = arrayDifference(originalImplantMeta, changedImplantMeta);
            !isEmpty(diff) && implantMetaDiff.push(diff);
        }
    });
    return implantMetaDiff;
};

const surgicalGuideImplantArray = [LabOrderItemSKUType.SurgicalGuide] as const;
const diffSurgicalGuideImplantMetadata = (changedItems: ItemRecord, originalItems: ItemRecord) => {
    const surgicalGuidImplantMetaDiff: Record<string, unknown>[] = [];
    forEach(originalItems, item => {
        const changedItem = changedItems[item.id];
        if (
            changedItem &&
            CartItemV2Utils.itemIsType(item, surgicalGuideImplantArray) &&
            CartItemV2Utils.itemIsType(changedItem, surgicalGuideImplantArray)
        ) {
            const originalImplantMeta = item.unit.implant_metadata;
            const changedImplantMeta = item.unit.implant_metadata;
            // @ts-expect-error
            const diff = objectDifference(originalImplantMeta, changedImplantMeta);
            !isEmpty(diff) && surgicalGuidImplantMetaDiff.push(diff);
        }
    });
    return surgicalGuidImplantMetaDiff;
};

type OrderEditModeObject = {
    arch?: string | null;
    tooth?: string | null;
    shades?: (string | null)[];
    material?: string | null;
    prefs?: (string | null)[];
    dataFields?: (string | null)[];
    itemNotes?: string | null;
    implantMetadata?: string | null;
    surgicalGuideImplantMetadata?: string | null;
};
// this lets us default to c/r for the order edit button for safety,
// if we get all order edit back we can safely proceed with the order edit
const isOrderEdit = (orderEditMode: OrderEditModeObject): boolean => {
    if (orderEditMode) {
        return every(orderEditMode, item => {
            if (Array.isArray(item)) {
                return item.every(subItem => {
                    return !subItem || subItem === OrderEditMode.OrderEdit;
                });
            }
            return !item || item === OrderEditMode.OrderEdit;
        });
    }
    return false;
};
const getEditMode = (
    order: LabsGqlOrder,
    diff: unknown[],
    editedField: ItemInfo['editedField'],
    editedPref?: string,
    editedShade?: string,
): Decision | null => {
    return !isEmpty(diff) ? getOrderEditMode({ order, editedField, editedPref, editedShade }) : null;
};

const orderEditModeNew = (isOrderEdit: boolean | undefined) => {
    return isOrderEdit ? OrderEditMode.OrderEdit : OrderEditMode.CancelAndResubmit;
};

const getItemDiffs = (
    keyedChangedItems: Record<string, ICartItemV2DTOWithId>,
    originalItems: Record<string, ICartItemV2DTOWithId>,
) => {
    return {
        arch: diffArch(keyedChangedItems, originalItems),
        tooth: diffTooth(keyedChangedItems, originalItems),
        shades: diffShade(keyedChangedItems, originalItems),
        material: diffMat(keyedChangedItems, originalItems),
        prefs: diffPref(keyedChangedItems, originalItems),
        dataFields: diffItemDataFields(keyedChangedItems, originalItems),
        itemNotes: diffItemNotes(keyedChangedItems, originalItems),
        implantMetadata: diffImplantMetaData(keyedChangedItems, originalItems),
        surgicalGuideImplantMetadata: diffSurgicalGuideImplantMetadata(keyedChangedItems, originalItems),
    };
};
export const itemEditSummary = (
    order: LabsGqlOrder,
    changedItems: ICartItemV2DTOWithId[],
    originalItems: Record<string, ICartItemV2DTOWithId>,
) => {
    const keyedChangedItems = itemsToMap(changedItems);
    const orderInfo = {
        order_id: order.id,
        order_status: order.status,
        active_task: order.fulfillment_workflow.active_task?.type,
        new_order_edit_mode: OrderEditMode.OrderEdit,
        assignee: order.fulfillment_workflow.active_task?.assignee,
    };
    if (isEmpty(originalItems) || isEmpty(keyedChangedItems)) {
        return orderInfo;
    }
    const diffs = getItemDiffs(keyedChangedItems, originalItems);
    const arch = getEditMode(order, diffs.arch, 'arch');
    const tooth = getEditMode(order, diffs.tooth, 'unn');
    const shades = diffs.shades
        .flatMap(item => Object.keys(item))
        .map(shade => getEditMode(order, diffs.shades, 'shades', undefined, shade));
    const material = getEditMode(order, diffs.material, 'material');
    const prefs = diffs.prefs
        .flatMap((item: Record<string, unknown>) => Object.keys(item))
        .map(pref => getEditMode(order, diffs.prefs, 'preference_fields', pref));
    const dataFields = diffs.dataFields
        .map(item => item.field_id)
        .map(pref => getEditMode(order, diffs.dataFields, 'preference_fields', pref));
    const itemNotes = getEditMode(order, diffs.itemNotes, 'item_notes');
    const implantMetadata = getEditMode(order, diffs.implantMetadata, 'implant_metadata');
    const surgicalGuideImplantMetadata = getEditMode(order, diffs.implantMetadata, 'implant_metadata');
    const orderEditMode: OrderEditModeObject = {
        arch: arch?.editMode,
        tooth: tooth?.editMode,
        shades: shades.map(s => s?.editMode || null),
        material: material?.editMode,
        prefs: prefs.map(p => p?.editMode || null),
        dataFields: dataFields.map(f => f?.editMode || null),
        itemNotes: itemNotes?.editMode,
        implantMetadata: implantMetadata?.editMode,
        surgicalGuideImplantMetadata: surgicalGuideImplantMetadata?.editMode,
    };
    const debugGranulatedOrderEdit = {
        arch: arch?.debugInfo,
        tooth: tooth?.debugInfo,
        shades: shades.map(s => s?.debugInfo),
        material: material?.debugInfo,
        prefs: prefs.map(p => p?.debugInfo),
        dataFields: dataFields.map(f => f?.debugInfo),
        itemNotes: itemNotes?.debugInfo,
        implantMetadata: implantMetadata?.debugInfo,
        surgicalGuideImplantMetadata: surgicalGuideImplantMetadata?.debugInfo,
    };
    return {
        ...orderInfo,
        item_diffs: diffs,
        debug_granulated_order_edit: debugGranulatedOrderEdit,
        granular_order_edit_mode: orderEditMode,
        new_order_edit_mode: orderEditModeNew(isOrderEdit(orderEditMode)),
    };
};

const currentItemChanged = (
    changedItems: ICartItemV2DTOWithId[],
    originalItems: Record<string, ICartItemV2DTOWithId>,
    currentItemId: string,
) => {
    const currentItem = changedItems.find(item => item.id === currentItemId);
    if (!currentItem) {
        return false;
    }
    const originalItem = originalItems[currentItemId];
    if (!originalItem) {
        // no original implies that current item was freshly added
        return true;
    }
    const diffs = getItemDiffs({ [currentItemId]: currentItem }, { [currentItemId]: originalItem });
    return Object.values(diffs).flat().length > 0;
};

export const disableNextForNoChanges = (
    isLastPage: boolean,
    isItemSelectionScreen: boolean,
    changedItems: ICartItemV2DTOWithId[],
    originalItems: Record<string, ICartItemV2DTOWithId>,
    currentItemId?: string,
) => {
    if (isItemSelectionScreen || isLastPage || !currentItemId) {
        return false;
    }
    // disable next if current item has not changed
    return !currentItemChanged(changedItems, originalItems, currentItemId);
};

// Used for the C/R add-items flow
export const getFilteredExtraOptions = (orderItems: ICartItemV2DTOWithId[]) => {
    const blacklistedOptions = [...blacklistedExtras, ...orderItems.map(CartItemV2Utils.getProductUnitType)];
    return ExtraCartItemV2Utils.getAddToCartOptions(orderItems).filter(option => {
        return !blacklistedOptions.includes(option.unit_type);
    });
};
export const getCurrentExtras = (orderItems: ICartItemV2DTOWithId[], addedExtraIds: string[]) => {
    const extras = orderItems.filter(item => addedExtraIds.includes(item.id));
    return extras.map(item => CartItemV2Utils.getPrimaryUnitType(item));
};
