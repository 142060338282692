import '../../index.css';
import { persistor, history, store } from '../../redux/store';
import { OpsScreens } from '../../redux/ui/ui.types';
import { AdminGqlProvider } from '../../utils/AdminGqlProvider';
import { AdminLogin, AdminLoginRoute } from '../Auth/AdminLogin';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { RetainerSessionProvider } from '@orthly/session-client';
import { OrthlyEnvironment } from '@orthly/shared-types';
import {
    OrthlyBrowserConfig,
    OrthlyErrorBoundary,
    OrthlyFrontendApps,
    StyledSnackbarProvider,
    LoadBlocker,
    OrthlyConfigProvider,
    ReloadStalePages,
} from '@orthly/ui';
import { DandyThemeProvider, Grid } from '@orthly/ui-primitives';
import {
    ConnectedFirebaseProvider,
    LaunchDarklyProvider,
    InfraUpdateTools,
    useLaunchDarklyUserContext,
    OutageBanner,
    DandyStatsigProvider,
} from '@orthly/veneer';
import { useSnackbar } from 'notistack';
import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { PersistGate } from 'redux-persist/integration/react';

const LoadingFallback: React.FC = () => {
    return (
        <LoadBlocker blocking={true} loader={'dandy'}>
            <Grid container style={{ height: '100vh', width: '100vw' }} />
        </LoadBlocker>
    );
};

const AdminLaunchDarklySessionProvider: React.FC = props => {
    const ldContext = useLaunchDarklyUserContext();
    return (
        <LaunchDarklyProvider config={new OrthlyBrowserConfig(OrthlyFrontendApps.admin)} context={ldContext}>
            {props.children}
        </LaunchDarklyProvider>
    );
};

const AdminSession: React.FC = props => {
    const { enqueueSnackbar } = useSnackbar();
    return (
        <RetainerSessionProvider
            // required to enable password resetting
            allowImpersonate
            allowedOrgTypes={['internal']}
            TokenExchangeLoadingFallback={LoadingFallback}
            showNotification={(msg, isError) => {
                enqueueSnackbar(msg, { variant: isError ? 'error' : undefined });
            }}
        >
            <DandyStatsigProvider fallback={LoadingFallback}>
                <ConnectedFirebaseProvider
                    appName={OrthlyFrontendApps.admin}
                    envOverride={process.env.REACT_APP_LOCAL_PROD ? OrthlyEnvironment.production : undefined}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Provider store={store}>
                            <Router history={history}>
                                <CompatRouter>
                                    <ReloadStalePages safeToReloadRoutes={Array.from(OpsScreens)} maxAgeInDays={2}>
                                        <PersistGate loading={<LoadingFallback />} persistor={persistor}>
                                            <AdminLaunchDarklySessionProvider>
                                                <InfraUpdateTools />
                                                <Switch>
                                                    <Route path={AdminLoginRoute} component={AdminLogin} />
                                                    <Route path={'*'}>{props.children}</Route>
                                                </Switch>
                                                <OutageBanner audience={'ops'} />
                                            </AdminLaunchDarklySessionProvider>
                                        </PersistGate>
                                    </ReloadStalePages>
                                </CompatRouter>
                            </Router>
                        </Provider>
                    </LocalizationProvider>
                </ConnectedFirebaseProvider>
            </DandyStatsigProvider>
        </RetainerSessionProvider>
    );
};

export const OpsRootProvider: React.FC<{ config: OrthlyBrowserConfig }> = props => {
    return (
        <DandyThemeProvider>
            <OrthlyErrorBoundary componentName={'RootProvider'}>
                <HelmetProvider>
                    <Helmet>
                        <title>Dandy Ops</title>
                    </Helmet>
                    <OrthlyConfigProvider config={props.config}>
                        <StyledSnackbarProvider
                            maxSnack={2}
                            autoHideDuration={5000}
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                            <AdminGqlProvider>
                                <AdminSession>{props.children}</AdminSession>
                            </AdminGqlProvider>
                        </StyledSnackbarProvider>
                    </OrthlyConfigProvider>
                </HelmetProvider>
            </OrthlyErrorBoundary>
        </DandyThemeProvider>
    );
};
