import type { ToolbarActionProps } from '../OrderDetailToolbar/OrderDetailToolbarActions/ToolbarActionDefinition';
import type { LabsGqlUpdateOrderHoldMutationVariables } from '@orthly/graphql-operations';
import { useUpdateOrderHoldMutation } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { HoldUtils } from '@orthly/shared-types';
import { useChangeSubmissionFn, RootActionDialog } from '@orthly/ui';
import { EditHoldForm } from '@orthly/veneer';

type Vars = LabsGqlUpdateOrderHoldMutationVariables['data'];
export const UpdateOrderHold: React.FC<ToolbarActionProps> = props => {
    const { order, open, setOpen } = props;

    const [submitMtn] = useUpdateOrderHoldMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });

    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Order hold updated!', {}],
        onSuccess: async () => {
            await props.refetchOrder();
            setOpen(false);
        },
    });

    if (order.status !== LabsGqlLabOrderStatus.OnHold) {
        return null;
    }

    return (
        <RootActionDialog
            title={'Edit Hold'}
            loading={submitting}
            open={open}
            setOpen={setOpen}
            content={
                <EditHoldForm
                    can_show_practice={true}
                    show_only_lab_visible_categories={false}
                    initialValue={HoldUtils.structuredHoldFromHoldDetails(
                        order.hold_details,
                        !!order.show_hold_status_to_practice,
                    )}
                    onSubmit={async hold => {
                        await submit({
                            order_id: order.id,
                            hold_details: HoldUtils.deconstructHold(hold),
                            reason: await HoldUtils.describeHold(hold),
                            show_hold_status_to_practice: hold.show_to_practice,
                        });
                    }}
                    labOrder={order}
                />
            }
            buttonText={'Save Changes'}
            CustomButton={props.CustomButton}
        />
    );
};
