import { useAutomationBuilderAction } from '../../../state/AutomationBuilder.actions';
import { useAutomationBuilderSelector } from '../../../state/AutomationBuilder.context';
import { AutomationStepContainer } from '../components/AutomationStepContainer';
import { OrderFilterCriteriaItem } from './OrderFilterCriteriaItem';
import { OrderFilterResultsPreview } from './OrderFilterResultsPreview';
import { useListOrderFiltersQuery } from '@orthly/graphql-react';
import { Grid, Button } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const AddFilterButton: React.FC = () => {
    const addFilter = useAutomationBuilderAction('ADD_ORDER_FILTER');
    const combinator = useAutomationBuilderSelector(s => s.form.filter_combinator);
    return (
        <Grid container>
            <Button variant={'secondary'} startIcon={'PlusIcon'} onClick={() => addFilter()}>
                {_.startCase(combinator)}
            </Button>
        </Grid>
    );
};

const AutomationFilterFieldsRoot: React.FC = () => {
    const filtersQuery = useListOrderFiltersQuery();
    const filters = filtersQuery.data?.rules ?? [];
    const criteriaValues = useAutomationBuilderSelector(s => s.form.filter_criteria);
    return (
        <Grid container>
            {criteriaValues.map((criteria, idx) => (
                <OrderFilterCriteriaItem key={idx} idx={idx} criteria={criteria} filterDefinitions={filters} />
            ))}
            <AddFilterButton />
        </Grid>
    );
};

export const AutomationFilterStepRoot: React.FC = () => {
    return (
        <AutomationStepContainer title={'Filter'}>
            <AutomationFilterFieldsRoot />
            <OrderFilterResultsPreview />
        </AutomationStepContainer>
    );
};
