import type { MarginLinesMap } from './Types';
import type { ModelPayloadItem } from '@orthly/dentin';
import { getQCUndercutsCurtainId, isQCUndercutsCurtainsItem } from '@orthly/dentin';
import { getMarginPoints } from '@orthly/forceps';
import { ToothUtils } from '@orthly/items';
import type { MarginLine } from '@orthly/shared-types';
import _ from 'lodash';

export function parseMarginLines(marginLines: MarginLine[]): MarginLinesMap {
    const marginsMap: MarginLinesMap = new Map();

    for (const margin of marginLines) {
        const tooth = margin.tooth;
        if (!ToothUtils.isToothNumber(tooth)) {
            console.warn(`Invalid tooth number: ${tooth}`);
            continue;
        }

        marginsMap.set(tooth, getMarginPoints(margin));
    }

    return marginsMap;
}

export function generateCurtainsRecord(items: ModelPayloadItem[]): Record<string, ModelPayloadItem> {
    const curtainsMapByID: Record<string, ModelPayloadItem> = {};
    items.filter(isQCUndercutsCurtainsItem).forEach(curtain => {
        const curtainID = getQCUndercutsCurtainId(curtain);
        if (!curtainID) {
            return;
        }
        curtainsMapByID[curtainID] = curtain;
    });
    return curtainsMapByID;
}

export function isPrePrepAssetPath(filePath: string) {
    return filePath.includes('PrePrep');
}

export function isRawAssetPath(filePath: string) {
    return filePath.includes('Raw ');
}

export function isModelBuilderAssetPath(filePath: string) {
    return filePath.includes('MB ');
}

export function getPreferredScanType(unFiltered: string[], prePrep: boolean = false): string | undefined {
    const filtered = unFiltered.filter(prePrep ? isPrePrepAssetPath : _.negate(isPrePrepAssetPath));

    // Return first occurrence of path in the following lists in order of preference:
    // 1. MB (model builder) from filtered
    // 2. non-raw scans from filtered
    // 3. first occurrence from filtered
    // 4. first non-raw from unFiltered
    // 5. first occurrence from unFiltered if non-pre-prep is requested
    return (
        filtered.find(isModelBuilderAssetPath) ||
        filtered.find(_.negate(isRawAssetPath)) ||
        filtered[0] ||
        unFiltered.find(_.negate(isRawAssetPath)) ||
        (prePrep ? undefined : unFiltered[0])
    );
}
