import { useOrdersOverviewAction } from '../../screens/Orders/state/OrdersOverview.actions';
import { useOrdersOverviewState } from '../../screens/Orders/state/OrdersOverview.selectors';
import { useCurrentOrdersSort } from '../../screens/Orders/state/OrdersStateUtils';
import type { OrdersToolbarPopperContentProps } from './OrdersToolbarPopper';
import { OrdersToolbarPopper } from './OrdersToolbarPopper';
import { LabsGqlLabOrderSortKey } from '@orthly/graphql-schema';
import type { RequireChildrenProps } from '@orthly/ui';
import { SimpleSelect, SortIcon } from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    Grid,
    List,
    ListItemButton,
    ListItemText,
    ListSubheader,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useItemStyles = stylesFactory(() => ({
    root: {
        width: '100%',
        margin: '8px 0',
        padding: 4,
        justifyContent: 'space-between',
        display: 'flex',
    },
    text: { padding: 0 },
}));

const SortKeyTitles: { [K in LabsGqlLabOrderSortKey]?: string } = {
    [LabsGqlLabOrderSortKey.CreatedAt]: 'Placed on',
    [LabsGqlLabOrderSortKey.UpdatedAt]: 'Last update',
    [LabsGqlLabOrderSortKey.DueDate]: 'Due date (Lab)',
    [LabsGqlLabOrderSortKey.DeliveryDate]: 'Delivered on',
    [LabsGqlLabOrderSortKey.PatientFirstName]: 'First name',
    [LabsGqlLabOrderSortKey.PatientLastName]: 'Last name',
    [LabsGqlLabOrderSortKey.AssignmentFabricationDays]: 'Fabrication days',
    [LabsGqlLabOrderSortKey.SplitOrderCount]: 'Split count',
    [LabsGqlLabOrderSortKey.NeedsRefabricationDate]: 'Needs refab date',
    [LabsGqlLabOrderSortKey.ShipDate]: 'Shipped on',
    [LabsGqlLabOrderSortKey.FabricationStartDate]: 'Fabrication start',
    [LabsGqlLabOrderSortKey.DentistAmountDueCents]: 'Dentist price',
    [LabsGqlLabOrderSortKey.TicketDueAt]: `Ticket action due`,
};

interface OrdersListSortItemProps {
    sortKey: LabsGqlLabOrderSortKey | 'Relevance';
    onClick: () => void;
}

const OrdersListSortItem: React.FC<OrdersListSortItemProps> = props => {
    const classes = useItemStyles();
    const { sortKey, onClick } = props;
    const currentSort = useCurrentOrdersSort();
    return (
        <ListItemButton onClick={onClick} className={classes.root}>
            <ListItemText
                primary={sortKey === 'Relevance' ? 'Search Relevance' : SortKeyTitles[sortKey] ?? _.startCase(sortKey)}
                primaryTypographyProps={{ variant: 'body2' }}
            />
            {currentSort.key === sortKey && (
                <SortIcon
                    onClick={onClick}
                    style={{ cursor: 'pointer' }}
                    highlightedArrow={currentSort.asc ? 'upper' : 'lower'}
                />
            )}
        </ListItemButton>
    );
};

interface SortKeysListProps extends RequireChildrenProps {
    subheader: string;
}

const SortKeysList: React.FC<SortKeysListProps> = props => {
    return (
        <Grid container style={{ maxWidth: '49%' }}>
            <List style={{ width: '100%' }}>
                <ListSubheader style={{ lineHeight: 'normal', padding: '0 4px' }}>{props.subheader}</ListSubheader>
                {props.children}
            </List>
        </Grid>
    );
};

const NonDateKeys: LabsGqlLabOrderSortKey[] = [
    LabsGqlLabOrderSortKey.PatientFirstName,
    LabsGqlLabOrderSortKey.PatientLastName,
    LabsGqlLabOrderSortKey.SplitOrderCount,
    LabsGqlLabOrderSortKey.DentistAmountDueCents,
    LabsGqlLabOrderSortKey.AssignmentFabricationDays,
];

const DateKeys: LabsGqlLabOrderSortKey[] = [
    LabsGqlLabOrderSortKey.CreatedAt,
    LabsGqlLabOrderSortKey.UpdatedAt,
    LabsGqlLabOrderSortKey.FabricationStartDate,
    LabsGqlLabOrderSortKey.ShipDate,
    LabsGqlLabOrderSortKey.DueDate,
    LabsGqlLabOrderSortKey.NeedsRefabricationDate,
    LabsGqlLabOrderSortKey.TicketDueAt,
];

const SortPopoverContent: React.FC<OrdersToolbarPopperContentProps> = props => {
    const { setOpen } = props;
    const onSortKeyClicked = useOrdersOverviewAction('SORT_KEY_CLICKED');
    const search = useOrdersOverviewState(s => s.view?.search);
    const onSelect = (key?: LabsGqlLabOrderSortKey) => () => {
        onSortKeyClicked(key);
        setOpen(false);
    };

    return (
        <Grid container wrap={'nowrap'} justifyContent={'space-between'}>
            <SortKeysList subheader={'Order'}>
                {NonDateKeys.map(key => (
                    <OrdersListSortItem key={key} sortKey={key} onClick={onSelect(key)} />
                ))}
                {search && <OrdersListSortItem key={'relevance'} sortKey={'Relevance'} onClick={onSelect()} />}
            </SortKeysList>
            <SortKeysList subheader={'Dates'}>
                {DateKeys.map(key => (
                    <OrdersListSortItem key={key} sortKey={key} onClick={onSelect(key)} />
                ))}
            </SortKeysList>
        </Grid>
    );
};

export const OrdersListSort: React.FC = () => {
    const toggleDir = useOrdersOverviewAction('TOGGLE_SORT_DIR');
    const sort = useCurrentOrdersSort();

    const [open, setOpen] = React.useState<boolean>(false);
    const currentSortLabel = !sort.key ? 'Relevance' : SortKeyTitles[sort.key] ?? _.startCase(sort.key);

    return (
        <OrdersToolbarPopper PopoverContent={SortPopoverContent} open={open} setOpen={setOpen}>
            <SimpleSelect
                // just used for display
                value={currentSortLabel}
                options={[{ value: currentSortLabel }]}
                onChange={() => {}}
                label={'Sort By'}
                FormControlProps={{ onClick: _e => setOpen(true), variant: 'standard' }}
                InputLabelProps={{ style: { backgroundColor: 'transparent' } }}
                SelectProps={{
                    disabled: true,
                    style: {
                        backgroundColor: 'transparent',
                        color: FlossPalette.BLACK,
                        cursor: 'pointer',
                        borderRadius: 0,
                        border: 'none',
                        boxShadow: 'none',
                    },
                    IconComponent: () => null,
                    SelectDisplayProps: { style: { cursor: 'pointer' } },
                    variant: 'standard',
                }}
            />

            <SortIcon
                onClick={() => toggleDir()}
                className={`MuiSelect-icon`}
                style={{ cursor: 'pointer', zIndex: 2, background: '#fff', pointerEvents: 'auto' }}
                highlightedArrow={sort.asc ? 'upper' : 'lower'}
            />
        </OrdersToolbarPopper>
    );
};
