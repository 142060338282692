import type { LabsGqlActiveInternalDesignTaskFragment } from '@orthly/graphql-operations';
import { OrderDetailFactRow } from '@orthly/veneer';
import React from 'react';

interface OrderDetailSummaryDesignCapabilityRequirementsProps {
    activeInternalDesignTask: LabsGqlActiveInternalDesignTaskFragment | null;
}

export const OrderDetailSummaryDesignCapabilityRequirements: React.VFC<
    OrderDetailSummaryDesignCapabilityRequirementsProps
> = ({ activeInternalDesignTask }) => {
    // Replace space with non-breaking space within the requirement name so that the requirement wraps as a unit.
    const value = activeInternalDesignTask?.requirements.map(r => r.name.replaceAll(' ', ' ')).join(', ');

    if (!value) {
        return null;
    }

    return <OrderDetailFactRow title={'Design Levels'} value={value} />;
};
