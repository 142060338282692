import { RefabBodyContainer } from '../components/RefabBodyContainer';
import { RefabItemSelectContainer } from '../components/RefabItemSelectScreen';
import { useRefabFlowAction, useRefabFlowSelector } from '../state/refab-flow-state';
import { useRefabFlowContext } from './refab-flow-context';
import { Text, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const ItemSelectionScreen: React.VFC = () => {
    const { order } = useRefabFlowContext();
    const selectedItemIds = useRefabFlowSelector(s => s.selectedItemIds);
    const patchState = useRefabFlowAction('PATCH_STATE');
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const history = useHistory();

    const onSelection = (selectedItemIds: string[]) => {
        patchState({
            selectedItemIds,
        });
    };

    React.useEffect(() => {
        if (order?.items_v2.length === 1) {
            const itemId = order.items_v2[0]?.id ?? '';
            patchState({ selectedItemIds: [itemId], screen: 'reasonCodes', screenItemId: itemId });
        }
    }, [order, patchState]);

    return (
        <RefabBodyContainer
            title={
                <>
                    <Text variant={isMobile ? 'body1' : 'h4'} color={'LIGHT_GRAY'} style={{ opacity: 0.5 }}>
                        Sorry to hear that
                    </Text>
                    <Text variant={isMobile ? 'h5' : 'h3'} medium>
                        What do you want to refabricate?
                    </Text>
                </>
            }
            onBack={() => history.goBack()}
            disableNext={!selectedItemIds.length}
        >
            <RefabItemSelectContainer order={order} onSelection={onSelection} selectedItemIds={selectedItemIds} />
        </RefabBodyContainer>
    );
};
