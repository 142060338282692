import type { CaseMetadata } from './CaseMetadata';
import type { ScanModels } from './FinishingApp.types';
import { PartialSceneAppearanceManagerBase } from './PartialSceneAppearanceManagerBase';
import type {
    IPartialSceneAppearanceManager,
    PartialSceneAppearanceSetter,
    ScanKey,
} from './SceneAppearanceManager.types';
import type { ISceneMaterialManager } from './SceneMaterialManager.types';
import { HeatMapType } from '@orthly/forceps';
import type { ToothNumber } from '@orthly/items';
import type * as THREE from 'three';

// Manages the scene of the contours and occlusion review panel
export class OcclusalSceneAppearanceManager
    extends PartialSceneAppearanceManagerBase
    implements IPartialSceneAppearanceManager
{
    private showAntagonistJaw = false;
    private showPrePrep = false;
    private showScan = true;
    private readonly antagonistJaw: ScanKey | 'none';

    constructor(
        setAppearance: PartialSceneAppearanceSetter,
        restoratives: Map<ToothNumber, THREE.BufferGeometry>,
        scanModels: ScanModels,
        collisions: THREE.Mesh[],
        materialManager: ISceneMaterialManager,
        readonly restorationJaw: CaseMetadata['restorationJaw'],
    ) {
        super(setAppearance, materialManager, restoratives, scanModels, collisions, new Map());
        this.antagonistJaw = 'none';
        if (restorationJaw === 'upper') {
            this.antagonistJaw = 'lower';
        } else if (restorationJaw === 'lower') {
            this.antagonistJaw = 'upper';
        }
        this.toggleThicknessHeatmapEnabled(true);
        this.applyScanVisibilityState();
    }

    togglePrepScanVisibility(): void {
        this.showScan = !this.showScan;
        this.applyScanVisibilityState();
    }

    togglePrePrepScanVisibility(): void {
        this.showPrePrep = !this.showPrePrep;
        this.applyScanVisibilityState();
    }

    toggleAntagonistScanVisibility(): void {
        this.showAntagonistJaw = !this.showAntagonistJaw;
        this.applyScanVisibilityState();
    }

    private applyScanVisibilityState() {
        if (this.antagonistJaw !== 'none') {
            const upperAnt = this.antagonistJaw === 'upper';
            const lowerAnt = !upperAnt;

            this.setScansVisibility({
                prePrepUpperJaw: (!upperAnt || this.showAntagonistJaw) && (this.showPrePrep || !this.scans?.upperJaw),
                prePrepLowerJaw: (upperAnt || this.showAntagonistJaw) && (this.showPrePrep || !this.scans?.lowerJaw),
                upperJaw:
                    ((!upperAnt && this.showScan) || (upperAnt && this.showAntagonistJaw)) &&
                    (!this.showPrePrep || !this.scans?.prePrepUpperJaw),
                lowerJaw:
                    ((!lowerAnt && this.showScan) || (lowerAnt && this.showAntagonistJaw)) &&
                    (!this.showPrePrep || !this.scans?.prePrepLowerJaw),
            });
        } else {
            this.setScansVisibility({
                prePrepUpperJaw: this.showPrePrep || !this.scans?.upperJaw,
                prePrepLowerJaw: this.showPrePrep || !this.scans?.lowerJaw,
                upperJaw: !this.showPrePrep || !this.scans?.prePrepUpperJaw,
                lowerJaw: !this.showPrePrep || !this.scans?.prePrepLowerJaw,
            });
        }
    }

    toggleThicknessHeatmapEnabled(enabled?: boolean): void {
        this.toggleHeatMap(HeatMapType.Thickness, enabled);
    }

    toggleOcclusalHeatmapEnabled(enabled?: boolean): void {
        this.toggleHeatMap(HeatMapType.Occlusal, enabled);
    }
}
