import { useAutomationBuilderAction } from '../../../state/AutomationBuilder.actions';
import { useAutomationBuilderSelector } from '../../../state/AutomationBuilder.context';
import type { AutomationFilterCriteria } from '../../../state/AutomationBuilder.types';
import { AutomationBuilderFieldLayout } from '../components/AutomationBuilderFieldLayout';
import { ListEntityCombinatorField } from './criteria-fields/ListEntityCombinatorField';
import { OrderFilterCombinatorField } from './criteria-fields/OrderFilterCombinatorField';
import {
    OrderFilterComparatorField,
    comparatorsAvailableForFilter,
} from './criteria-fields/OrderFilterComparatorField';
import { OrderFilterComparisonValueField } from './criteria-fields/OrderFilterComparisonValueField';
import { OrderFilterRuleField } from './criteria-fields/OrderFilterRuleField';
import type { LabsGqlOrderFilterRuleFragment } from '@orthly/graphql-operations';
import type { LabsGqlFilterCriteriaSubmissionInput } from '@orthly/graphql-schema';
import { LabsGqlFilterComparator, LabsGqlFilterListCombinator } from '@orthly/graphql-schema';
import { Grid, IconButton, CancelIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface OrderFilterCriteriaFieldProps {
    filterDefinitions: LabsGqlOrderFilterRuleFragment[];
    criteria: AutomationFilterCriteria;
    idx: number;
}

function useOnFilterCriteriaChange(props: OrderFilterCriteriaFieldProps) {
    const { filterDefinitions, idx, criteria } = props;
    const updateFilter = useAutomationBuilderAction('UPDATE_ORDER_FILTER');
    return React.useCallback(
        (newValue: Partial<LabsGqlFilterCriteriaSubmissionInput>) => {
            if (newValue.filter_id === criteria.filter_id || newValue.filter_id === undefined) {
                return updateFilter({ idx, value: { ...criteria, ...newValue } });
            }
            // Filter definition has changed
            const newSelectedFilter = filterDefinitions.find(f => f.id === newValue.filter_id);
            const availableComparators = comparatorsAvailableForFilter(newSelectedFilter);
            const comparator =
                criteria.comparator && availableComparators.includes(criteria.comparator)
                    ? criteria.comparator
                    : availableComparators[0];
            const list_combinator = !newSelectedFilter?.list_entity_name
                ? null
                : criteria.list_combinator ?? LabsGqlFilterListCombinator.All;
            return updateFilter({
                idx,
                value: { ...criteria, ...newValue, list_combinator, comparator, comparison_value: null },
            });
        },
        [criteria, filterDefinitions, idx, updateFilter],
    );
}

export const OrderFilterCriteriaItem: React.FC<OrderFilterCriteriaFieldProps> = props => {
    const onChange = useOnFilterCriteriaChange(props);
    const { filterDefinitions, idx, criteria } = props;
    const deleteFilter = useAutomationBuilderAction('DELETE_ORDER_FILTER');
    const combinator = useAutomationBuilderSelector(s => s.form.filter_combinator);
    const selectedFilter = React.useMemo(
        () => filterDefinitions.find(f => f.id === criteria.filter_id),
        [criteria.filter_id, filterDefinitions],
    );
    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const title = idx === 0 ? 'If ' : idx === 1 ? <OrderFilterCombinatorField /> : _.startCase(combinator);
    return (
        <AutomationBuilderFieldLayout secondary={idx !== 0} title={title} fullWidth>
            <Grid container spacing={2} alignItems={'center'} wrap={'nowrap'}>
                <ListEntityCombinatorField
                    criteria={criteria}
                    onChange={onChange}
                    filterListEntityName={selectedFilter?.list_entity_name ?? undefined}
                />
                <Grid container item xs={3} alignItems={'center'}>
                    <OrderFilterRuleField currentCriteria={criteria} onChange={onChange} rules={filterDefinitions} />
                </Grid>
                <Grid container item xs={2} alignItems={'center'}>
                    <OrderFilterComparatorField
                        comparator={criteria.comparator}
                        onChange={onChange}
                        selectedFilter={selectedFilter}
                    />
                </Grid>
                <Grid container item xs={!selectedFilter?.list_entity_name ? 6 : 4} alignItems={'center'}>
                    <OrderFilterComparisonValueField
                        comparator={criteria.comparator ?? LabsGqlFilterComparator.Disabled}
                        value={criteria.comparison_value}
                        selectedFilter={selectedFilter}
                        onChange={comparison_value => onChange({ comparison_value })}
                    />
                </Grid>
                <Grid container item xs={1} alignItems={'center'}>
                    <IconButton size={'small'} onClick={() => deleteFilter(idx)}>
                        <CancelIcon color={'error'} />
                    </IconButton>
                </Grid>
            </Grid>
        </AutomationBuilderFieldLayout>
    );
};
