import { FlossSlider } from '../../DesignPrep/SharedUi/FlossSlider';
import { Grid, Text, TextField as TextFieldImpl } from '@orthly/ui-primitives';
import React from 'react';
import type { NumericFormatProps } from 'react-number-format';
import { NumericFormat } from 'react-number-format';

interface CustomProps {
    onChange: (event: { target: { value: string } }) => void;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>((props, ref) => {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            decimalScale={3}
        />
    );
});

const NonnegativeNumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>((props, ref) => {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            allowNegative={false}
            decimalScale={3}
        />
    );
});

interface TextFieldProps {
    value: number;
    onChange: (value: number) => void;
    min: number;
    max: number;
}

const TextField: React.VFC<TextFieldProps> = ({ value, onChange: _onChange, min, max }) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(event.target.value);
        const clampedValue = Math.min(max, Math.max(min, value));
        _onChange(clampedValue);
    };

    const allowNegative = min < 0;

    return (
        <TextFieldImpl
            variant={'standard'}
            margin={'none'}
            value={value}
            onChange={onChange}
            InputProps={{
                inputComponent: (allowNegative ? NumericFormatCustom : NonnegativeNumericFormatCustom) as any,
                style: {
                    padding: 2,
                },
            }}
            inputProps={{
                style: {
                    marginTop: -8,
                    marginLeft: -8,
                },
            }}
        />
    );
};

interface SliderRowProps {
    label: string;
    value: number;
    onChange: (val: number) => void;
    min: number;
    max: number;
    step: number;
}

export const SliderRow: React.VFC<SliderRowProps> = ({ label, value, onChange, min, max, step }) => {
    return (
        <Grid container direction={'row'} wrap={'nowrap'} alignItems={'center'} spacing={2}>
            <Grid item xs={3}>
                <Text variant={'caption'} color={'GRAY'}>
                    {label}
                </Text>
            </Grid>
            <Grid item xs={5}>
                <FlossSlider value={value} onChange={onChange} min={min} max={max} step={step} />
            </Grid>
            <Grid item xs={4}>
                <TextField value={value} onChange={onChange} min={min} max={max} />
            </Grid>
        </Grid>
    );
};
