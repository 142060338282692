import { useColorRamps } from '../ColorRamp/ColorRamp.hooks';
import { useCrossSectionState, useCrossSectionApp } from '../CrossSection';
import { useHeatmapHighlight } from '../ModelViewer';
import type { MainViewCameraControlsRef } from '../ModelViewer/ModelViewerTHREETypes';
import { useModelAppearance, useMarginLines } from './Adapter.hooks';
import { useCanvasChildren, useCanvasSiblingsState } from './CanvasChildren.utils';
import { useCaseMetadata } from './CaseMetadata.hooks';
import { useDeform } from './Deform.hooks';
import type {
    ScanGeometries,
    EditingMode,
    FinishingApp,
    MarginLinesMap,
    RestorativeModel,
    ScanModels,
    FinishingAppOptions,
} from './FinishingApp.types';
import { useInsertionAxis } from './InsertionAxis.hooks';
import { useOperations } from './Operations.hooks';
import { usePrepareModels } from './PrepareModels.hooks';
import { useCheckRestorativesValidity } from './RestorativeValidity.hooks';
import { useSceneAppearanceManager } from './SceneAppearanceManager.hooks';
import { useSculpting } from './Sculpting.hooks';
import { useViewManager } from './ViewManager.hooks';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import React from 'react';

function scanModelsToGeometries(models: ScanModels): ScanGeometries {
    return {
        upperJaw: models.upperJaw.geometry,
        lowerJaw: models.lowerJaw.geometry,
        prePrepUpperJaw: models.prePrepUpperJaw?.geometry,
        prePrepLowerJaw: models.prePrepLowerJaw?.geometry,
    };
}

/**
 * Encompasses the business logic for the Dandy Finishing tool, including Dandy Verified Design.
 */
export function useFinishingApp(
    orderId: string,
    restorativeModelsInput: ArrayMin1<RestorativeModel>,
    scanModels: ScanModels,
    cameraControlsRef: MainViewCameraControlsRef,
    canvasRef: React.MutableRefObject<HTMLCanvasElement | null>,
    marginLines: MarginLinesMap,
    options: FinishingAppOptions,
): FinishingApp {
    const [mode, setMode] = React.useState<EditingMode | undefined>(undefined);

    // Flag to indicate whether a long-running operation is in progress.
    const [loading, setLoading] = React.useState<boolean>(false);

    const scanGeometries = React.useMemo(() => scanModelsToGeometries(scanModels), [scanModels]);

    const { updateTextRef, setHeatmapHighlight } = useHeatmapHighlight();

    // Keep this hook call at the top of the app. It calculates the BVH for each geometry, which potentially reorders
    // the faces.
    const { restorativeModels, collisions, insertionDepthGenerator } = usePrepareModels(
        restorativeModelsInput,
        scanGeometries,
    );

    const restorativesValidity = useCheckRestorativesValidity(restorativeModels, scanGeometries, marginLines);

    const caseMetadata = useCaseMetadata(restorativeModels, scanGeometries);

    const operationsState = useOperations(restorativeModels, scanGeometries);

    const colorRamps = useColorRamps();

    const sceneState = useSceneAppearanceManager(
        scanModels,
        restorativeModels,
        marginLines,
        collisions,
        insertionDepthGenerator,
        caseMetadata,
        colorRamps,
        operationsState.manager,
        options.enableTubeMarginLine,
    );

    const viewManager = useViewManager(cameraControlsRef, operationsState.manager, sceneState.manager);

    const crossSectionState = useCrossSectionState();
    const crossSectionApp = useCrossSectionApp(cameraControlsRef, crossSectionState);

    // NB: `modelAppearance` and `marginLinesArray` are generated in order to interface with the cross section tool. Do
    // not use them as inputs for newly written Finishing-specific components. Instead, use the variables of Finishing-
    // specific types.
    const { appearance, setAppearance } = useModelAppearance(restorativeModels, scanGeometries, sceneState.appearance);

    // updateCrossSection is also used specifically for the cross section tool (when the geometry changes, the cross section will update)
    const updateCrossSection = React.useCallback(() => {
        setAppearance(current => ({
            ...current,
        }));
    }, [setAppearance]);

    React.useEffect(() => {
        operationsState.manager.registerGeometryChangedCallback(updateCrossSection);
    }, [operationsState.manager, updateCrossSection]);

    const marginLinesArray = useMarginLines(marginLines);

    const sculptingState = useSculpting(
        orderId,
        mode,
        setMode,
        operationsState.manager,
        cameraControlsRef,
        canvasRef,
        sceneState.manager.getSculptingGroup(),
        updateCrossSection,
        sceneState.manager.getCurtains(operationsState.manager.editToothNumber),
    );

    const deformState = useDeform(
        orderId,
        mode,
        setMode,
        operationsState.manager,
        cameraControlsRef,
        canvasRef,
        sceneState.manager.getSculptingGroup(),
        sceneState.appearance,
        updateCrossSection,
        sceneState.manager.getCurtains(operationsState.manager.editToothNumber),
    );

    const canvasChildren = useCanvasChildren(
        cameraControlsRef,
        crossSectionState,
        crossSectionApp,
        appearance,
        marginLinesArray,
        setHeatmapHighlight,
        sceneState.appearance,
        operationsState.manager,
        sceneState.manager,
    );

    const insertionAxisState = useInsertionAxis(
        mode,
        setMode,
        cameraControlsRef,
        operationsState.manager,
        caseMetadata,
        marginLines,
        setLoading,
        options.enableFinishingCommitInvalidIntaglio,
    );

    const canvasSiblings = useCanvasSiblingsState(
        crossSectionState,
        crossSectionApp,
        sceneState,
        updateTextRef,
        operationsState.manager,
        marginLines,
        insertionAxisState,
    );
    return {
        loading,
        caseMetadata,
        restorativesValidity,
        sculptingState,
        deformState,
        sceneState,
        operationsState,
        viewManager,
        crossSection: crossSectionState,
        canvasChildren,
        canvasSiblings,
        insertionAxisState,
    };
}
