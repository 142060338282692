import type { ToolbarActionDefinition, ToolbarActionProps } from './ToolbarActionDefinition';
import type {
    LabsGqlLinkOrderToRefabricationMutationVariables,
    LabsGqlOrder,
    LabsGqlLabOrderForRefabLinkFragment,
} from '@orthly/graphql-operations';
import {
    DEFAULT_ORDER_QUERY,
    useLinkOrderToRefabricationMutation,
    useOrdersForRefabLinkQuery,
} from '@orthly/graphql-react';
import type { LabsGqlOrderRefabReasonInput } from '@orthly/graphql-schema';
import { LabsGqlFilterComparator as Comparator, LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { levenshtein } from '@orthly/runtime-utils';
import { OrderFilterIdEnum } from '@orthly/shared-types';
import { LoadBlocker, QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { isValidOrderRefabReason, RefabReasonSelector, useRefabReasonOptions } from '@orthly/veneer';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { z } from 'zod';

type Vars = LabsGqlLinkOrderToRefabricationMutationVariables['data'];

const linkToRefabVisible = (order: LabsGqlOrder): boolean => !!order.delivery_date && !order.refabrication_order_id;

function patientNameDistance(
    a: Pick<LabsGqlLabOrderForRefabLinkFragment, 'patient'>,
    b: Pick<LabsGqlLabOrderForRefabLinkFragment, 'patient'>,
): number {
    return levenshtein(
        `${a.patient.first_name} ${a.patient.last_name}`.toLowerCase(),
        `${b.patient.first_name} ${b.patient.last_name}`.toLowerCase(),
    );
}

const LinkToRefabricationAction: React.FC<ToolbarActionProps> = props => {
    const { order, open, setOpen, refetchOrder } = props;
    const { data, loading: ordersLoading } = useOrdersForRefabLinkQuery({
        variables: {
            ...DEFAULT_ORDER_QUERY,
            criteria: [
                {
                    filter_id: OrderFilterIdEnum.by_partner,
                    comparison_value: order.partner_id,
                    comparator: Comparator.Equals,
                },
                {
                    filter_id: OrderFilterIdEnum.status,
                    comparison_value: [
                        LabsGqlLabOrderStatus.New,
                        LabsGqlLabOrderStatus.NeedsReview,
                        LabsGqlLabOrderStatus.OnHold,
                        LabsGqlLabOrderStatus.Fabrication,
                    ],
                    comparator: Comparator.OneOf,
                },
                {
                    filter_id: OrderFilterIdEnum.created_at,
                    comparison_value: order.delivery_date,
                    comparator: Comparator.Gt,
                },
            ],
        },
    });
    const orders = data?.ordersByListView ?? [];
    const sortedOrders = _(orders)
        .filter(o => patientNameDistance(o, order) <= 5 && o.id !== order.id)
        .sortBy(orders, o => patientNameDistance(o, order))
        .value();

    const [submitMtn] = useLinkOrderToRefabricationMutation();
    const [selectedReasons, setSelectedReasons] = React.useState<LabsGqlOrderRefabReasonInput[]>([]);
    const { reasons, loading: reasonsLoading } = useRefabReasonOptions(order.id, selectedReasons);
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Order linked to re-fabrication', {}],
        onSuccess: async _result => {
            await refetchOrder();
            setOpen(false);
        },
    });

    if (!linkToRefabVisible(order)) {
        return null;
    }
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Link to Refabrication Order'}
            showCloseButton={true}
            content={
                <LoadBlocker blocking={reasonsLoading || ordersLoading}>
                    <QuickForm<Vars>
                        fields={{
                            original_order_id: {
                                type: 'text',
                                hidden: true,
                            },
                            refab_order_id: {
                                label: 'Refabrication Order',
                                type: 'autocomplete',
                                options: sortedOrders.map(o => ({
                                    value: o.id,
                                    label: `${o.patient.first_name} ${o.patient.last_name} (${_.startCase(
                                        o.status,
                                    )}, placed on ${moment(o.created_at).format('MMM DD YYYY')})`,
                                })),
                            },
                            refab_reasons: {
                                type: 'custom',
                                optional: true,
                                validation: z.array(z.any()),
                                component: ({ form, field }) => (
                                    <RefabReasonSelector
                                        loading={false}
                                        possibleReasons={reasons}
                                        selected={field.value ?? []}
                                        setSelected={selected => form.setFieldValue(field.name, selected)}
                                        allowFaultOverride={true}
                                    />
                                ),
                            },
                            practitioner_notes: {
                                type: 'text',
                                fieldProps: { multiline: true, rows: 3 },
                            },
                        }}
                        initialValues={{
                            original_order_id: order.id,
                        }}
                        onChange={result => {
                            if (result.refab_reasons) {
                                const validatedReasons = _.compact(result.refab_reasons).filter(
                                    isValidOrderRefabReason,
                                );
                                setSelectedReasons(validatedReasons);
                            }
                        }}
                        onSubmit={async (result, actions) => {
                            await submit({ ...result });
                            actions.setSubmitting(false);
                        }}
                    />
                </LoadBlocker>
            }
            style={{
                display: 'inline',
                flexBasis: 0,
            }}
            buttonText={'Link to Refabrication Order'}
            CustomButton={props.CustomButton}
        />
    );
};

export const LinkOrderToRefabToolbarAction: ToolbarActionDefinition = {
    label: 'Link to refab',
    Component: LinkToRefabricationAction,
    isVisible: linkToRefabVisible,
    capabilityRequirement: 'order.refabrication.link_order_to_refab',
};
