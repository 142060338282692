import { OrderToolbar } from '@orthly/dentin';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { LabsGqlDoctorRequestOptionModifyOrderMode } from '@orthly/graphql-schema';
import type { ICartItemV2DTO } from '@orthly/items';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { Dialog } from '@orthly/ui-primitives';
import { OrderEditMode } from '@orthly/veneer';
import { ItemEditorV2Utils, OrderEditRoot } from '@orthly/veneer';
import React from 'react';

export type AskDoctorChangeRxConfigurationDialogProps = {
    order: LabsGqlLabOrderFragment;
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    onSubmit: (items_v2: ICartItemV2DTO[], mode: LabsGqlDoctorRequestOptionModifyOrderMode) => void;
};

const orderEditModeMap = {
    [OrderEditMode.CancelAndResubmit]: LabsGqlDoctorRequestOptionModifyOrderMode.CancelAndResubmit,
    [OrderEditMode.OrderEdit]: LabsGqlDoctorRequestOptionModifyOrderMode.Update,
    [OrderEditMode.Disabled]: null,
};

export const AskDoctorChangeRxConfigurationDialog: React.VFC<AskDoctorChangeRxConfigurationDialogProps> = ({
    isOpen,
    setOpen,
    onSubmit,
    order,
}) => {
    const [managedItems, setManagedItems] = React.useState(OrderItemV2Utils.parseItems(order.items_v2));

    return (
        <Dialog open={isOpen} fullScreen>
            <OrderToolbar
                order={order}
                onClose={() => setOpen(false)}
                customTitle={`Change Rx Configuration`}
                hideProductImage
            />
            <OrderEditRoot
                onClose={() => setOpen(false)}
                order={order}
                orderItemsOverrides={managedItems}
                customStrings={{
                    reviewTitle: 'Review the proposed modifications',
                    submitText: 'Save Proposed RX Recommendation',
                }}
                onSubmit={(submittedItems, mode) => {
                    const cleanedItems = submittedItems.map(ItemEditorV2Utils.cleanItem);
                    const modeToSubmit = orderEditModeMap[mode];

                    if (modeToSubmit) {
                        setManagedItems(submittedItems.filter(CartItemV2Utils.isOrderItem));
                        onSubmit(cleanedItems, modeToSubmit);
                        setOpen(false);
                    }
                }}
            />
        </Dialog>
    );
};
