import { stylesFactory } from '../../util/stylesFactory';
import { Tooltip, Icon, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    tooltipContainer: {
        display: `flex`,
        alignItems: `center`,
        justifyContent: `flex-start`,
    },
    tooltipIconContainer: {
        marginLeft: `8px`,
    },
    tooltipTextContainer: {
        display: `flex`,
        flexDirection: `column`,
        textAlign: `center`,
        alignItems: 'center',
        justifyContent: `center`,
        alignContent: `center`,
    },
}));

interface TextWithTooltipProps {
    text: string;
    tooltipTitle: string;
    tooltipSubTitle: string;
}
export const TextWithTooltip: React.VFC<TextWithTooltipProps> = ({ text, tooltipTitle, tooltipSubTitle }) => {
    const classes = useStyles();

    return (
        <Text color={'DARK_GRAY'} variant={'body2'} className={classes.tooltipContainer}>
            {text}
            <Tooltip
                className={classes.tooltipIconContainer}
                title={
                    <div className={classes.tooltipTextContainer}>
                        <Text variant={'body2'} medium color={'WHITE'}>
                            {tooltipTitle}
                        </Text>
                        <Text variant={'body2'} color={'WHITE'}>
                            {tooltipSubTitle}
                        </Text>
                    </div>
                }
            >
                <Icon icon={'InfoIcon'} fontSize={'small'} />
            </Tooltip>
        </Text>
    );
};
