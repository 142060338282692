import { NavigationCollapsedWidth, NavigationExpandedWidth } from '../../../util/constants';
import type { WithClassesProp } from '../../../util/floss.types';
import { useMergedMuiClasses } from '../../../util/mergeMuiClasses';
import { stylesFactory } from '../../../util/stylesFactory';
import type { NavigationEntryBottom, NavigationEntryCollapse } from './Navigation.types';
import type { NavigationEntry } from './NavigationItem';
import { NavigationItem } from './NavigationItem';
import { NavigationItemCollapse } from './NavigationItemCollapse';
import { NavigationLogo } from './NavigationLogo';
import { Grid, Drawer, List, FlossPalette } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

export type NavigationClassKey = 'drawer' | 'paper' | 'paperFull' | 'paperCollapsed' | 'listContainer' | 'listBottom';

const useStyles = stylesFactory<{ collapsed: boolean; isMobile: boolean }, NavigationClassKey>(theme => ({
    drawer: props => ({
        zIndex: `${theme.zIndex.appBar} !important` as any,
        width: props.collapsed ? NavigationCollapsedWidth : NavigationExpandedWidth,
        height: '100vh',
        transition: theme.transitions.create('width', { easing: theme.transitions.easing.easeInOut }),
        position: props.isMobile ? 'fixed' : undefined,
    }),
    paper: {
        flex: 1,
        background: FlossPalette.TAN,
        borderRight: `1px solid ${FlossPalette.DARK_TAN}`,
        transition: theme.transitions.create('width', { easing: theme.transitions.easing.easeInOut }),
        overflow: 'hidden',
    },
    paperFull: {
        width: NavigationExpandedWidth,
    },
    paperCollapsed: {
        width: NavigationCollapsedWidth,
    },
    listContainer: {
        flexDirection: 'column',
        overflow: 'auto',
        flex: 1,
        flexWrap: 'nowrap',
        paddingBottom: 16,
    },
    listBottom: {
        marginTop: 'auto',
        paddingTop: 0,
    },
}));

const isCollapseEntry = (item: NavigationEntryBottom): item is NavigationEntryCollapse => {
    return item.hasOwnProperty('items');
};

export interface NavigationTemporaryModeProps {
    // whether or not we are currently in mobile mode
    active: boolean;
    open: boolean;
    onClose?: () => void;
}

export interface NavigationProps extends WithClassesProp<NavigationClassKey> {
    collapsible: boolean;
    initialCollapsed?: boolean;
    fixedItems?: NavigationEntry[];
    mainItems?: NavigationEntry[];
    bottomItems?: NavigationEntryBottom[];
    TemporaryMode?: NavigationTemporaryModeProps;
}

const TemporaryModeDefaults: NavigationTemporaryModeProps = { active: false, onClose: undefined, open: false };

export const Navigation: React.FC<NavigationProps> = props => {
    const { fixedItems, mainItems, bottomItems, collapsible, initialCollapsed } = props;
    const { active: isMobile, onClose, open } = props.TemporaryMode ?? TemporaryModeDefaults;
    const [collapsed, setCollapsed] = React.useState<boolean>(collapsible && initialCollapsed === true);
    const classes = useMergedMuiClasses<NavigationClassKey>(useStyles({ collapsed, isMobile }), props.classes);
    return (
        <Drawer
            open={isMobile ? open : true}
            onClose={onClose}
            variant={isMobile ? 'temporary' : 'persistent'}
            classes={{
                root: classes.drawer,
                paper: clsx(classes.paper, { [classes.paperFull]: !collapsed, [classes.paperCollapsed]: collapsed }),
            }}
            // when variant=permanent, disablePortal is passed to the html, causing a warning. This little hack prevents that.
            {...(isMobile ? { disablePortal: true } : undefined)}
        >
            <NavigationLogo
                collapsed={collapsed}
                onToggleCollapsed={collapsible ? () => setCollapsed(c => !c) : undefined}
            />
            <List sx={{ padding: 0 }}>
                {fixedItems?.map((item, idx) => <NavigationItem key={idx} collapsed={collapsed} {...item} />)}
            </List>
            <Grid container className={classes.listContainer}>
                <List>
                    {mainItems?.map((item, idx) => <NavigationItem key={idx} collapsed={collapsed} {...item} />)}
                </List>
                {props.children}
                {bottomItems && (
                    <List className={classes.listBottom}>
                        {bottomItems.map((item, idx) => {
                            if (isCollapseEntry(item)) {
                                return <NavigationItemCollapse key={idx} collapsed={collapsed} {...item} />;
                            }
                            return <NavigationItem key={idx} collapsed={collapsed} {...item} />;
                        })}
                    </List>
                )}
            </Grid>
        </Drawer>
    );
};
