import type { LabsGqlScanFileForOrderFragment, LabsGqlScanFileFragment } from '@orthly/graphql-operations';
import { LoadBlocker } from '@orthly/ui';
import { makeStyles, Button, ButtonGroup } from '@orthly/ui-primitives';
import { useFirebaseFileDownload } from '@orthly/veneer';
import React from 'react';

// TODO: Make a storybook story
const useStyles = makeStyles({
    // really annoying i had to toss important on all these due to material ui's default styles overriding
    button: {
        padding: '2px 5px !important',
        minWidth: 'unset !important',
        minHeight: 'unset !important',
    },
    openButton: {
        '& span': {
            whiteSpace: 'nowrap',
        },
    },
});

type ScanFileDownloadLinkProps = {
    scan_export: LabsGqlScanFileFragment | LabsGqlScanFileForOrderFragment;
};

export const ScanFileDownloadLink: React.FC<ScanFileDownloadLinkProps> = props => {
    const classes = useStyles();
    const triggerStlDownload = useFirebaseFileDownload(
        props.scan_export.stl_url || '',
        `${props.scan_export.patient_first_name} ${props.scan_export.patient_last_name}.stl`,
    );
    const trigger3oxzDownload = useFirebaseFileDownload(
        props.scan_export.file_url || '',
        `${props.scan_export.patient_first_name} ${props.scan_export.patient_last_name}.3oxz`,
    );
    return (
        <LoadBlocker blocking={triggerStlDownload.loading || trigger3oxzDownload.loading}>
            <ButtonGroup>
                <Button
                    variant={'contained'}
                    classes={{ root: classes.button }}
                    disabled={!props.scan_export.stl_url}
                    onClick={() => triggerStlDownload.execute()}
                >
                    STL
                </Button>
                <Button
                    variant={'contained'}
                    classes={{ root: classes.button }}
                    disabled={!props.scan_export.file_url}
                    onClick={() => trigger3oxzDownload.execute()}
                >
                    Raw 3oxz
                </Button>
            </ButtonGroup>
        </LoadBlocker>
    );
};
