import { OpsInboxActions, useOpsInboxAction } from '../state/Inbox.actions';
import { OpsInboxScreenLabels, useInboxState } from '../state/Inbox.selectors';
import type { OpsInboxScreen } from '../state/Inbox.types';
import { useInboxTasks } from '../state/InboxTasksProvider';
import { useActiveInboxViewIsEdited, useInboxCustomViewSidebarEntries } from './InboxCustomViewNavItem';
import { SaveInboxViewModal } from './SavedViews/InboxSavedViews';
import { useApolloClient } from '@apollo/client';
import { useWorkflowTasksCountQuery, WorkflowTasksCountDocument } from '@orthly/graphql-react';
import { LabsGqlCustomSearchType, LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { useAsyncIsLoading } from '@orthly/redux-async-actions';
import type { SubNavigationEntry, SubNavigationSectionsProp } from '@orthly/ui';
import { SubNavigation } from '@orthly/ui';
import { stylesFactory, IconButton, RefreshIcon } from '@orthly/ui-primitives';
import { SavedViewsAutocomplete, useUpdateNotifier, UpdateNotifierSignal } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    list: { width: '100%', bottom: 80, top: 64, position: 'absolute', overflow: 'auto' },
    bottomContainer: { position: 'absolute', bottom: 16, padding: 16 },
    text: { flex: 1, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' },
}));

const ScreenSubNavSections: OpsInboxScreen[][] = [
    ['AssignedToMe', 'CompletedByMe', 'AssignedToOthers', 'AllUnassigned', 'WaitingOnPractice'],
    [
        LabsGqlWorkflowTaskType.ScanReview,
        LabsGqlWorkflowTaskType.DesignPrep,
        LabsGqlWorkflowTaskType.AutomateReview,
        LabsGqlWorkflowTaskType.InternalDesign,
        LabsGqlWorkflowTaskType.DesignReview,
        LabsGqlWorkflowTaskType.DesignReview2,
        LabsGqlWorkflowTaskType.ExternalDesignVerification,
    ],
    [
        LabsGqlWorkflowTaskType.ReviewOrder,
        LabsGqlWorkflowTaskType.ResolveHold,
        LabsGqlWorkflowTaskType.ResolveScanRejection,
    ],
];

const AlignersInboxScreens: OpsInboxScreen[] = [
    LabsGqlWorkflowTaskType.CreateTreatmentPlanRequest,
    LabsGqlWorkflowTaskType.SetTreatmentPlanRequestUrl,
    LabsGqlWorkflowTaskType.ConvertTreatmentPlanRequest,
    LabsGqlWorkflowTaskType.FinalizeTreatmentPlanRequest,
    LabsGqlWorkflowTaskType.ApproveAlignerFabrication,
    LabsGqlWorkflowTaskType.CreateExternalAlignerFulfillment,
];

export const InboxSubNavigation: React.FC = () => {
    const classes = useStyles();
    const setScreen = useOpsInboxAction('SET_SCREEN');
    const toggleFavorite = useOpsInboxAction('TOGGLE_FAVORITE_VIEW');
    const client = useApolloClient();
    const screen = useInboxState(s => (s.screen === 'Custom' ? s.view?.id : s.screen));
    const activeViewEdited = useActiveInboxViewIsEdited();

    const onScreenChange = React.useCallback(
        (newScreen: OpsInboxScreen | string) => {
            if (screen !== newScreen && (!activeViewEdited || window.confirm(`Abandon changes to current view?`))) {
                setScreen(newScreen);
            }
        },
        [activeViewEdited, setScreen, screen],
    );
    const { refetch, loading: tasksLoading } = useInboxTasks();
    const { data } = useWorkflowTasksCountQuery({
        // refresh every five minutes
        pollInterval: 5 * 60 * 1000,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    });
    useUpdateNotifier(UpdateNotifierSignal.onUpdate('WorkflowTask').refetch([WorkflowTasksCountDocument]));

    const taskCounts = data?.orderWorkflowTasksCount;
    const customViewEntries = useInboxCustomViewSidebarEntries();
    const sections = React.useMemo<SubNavigationSectionsProp>(() => {
        const screenToSection = (screen: OpsInboxScreen): SubNavigationEntry => ({
            value: screen,
            // Completed tasks are expensive to compute counts of, so we will just return 0 automatically
            count: screen === 'CompletedByMe' ? 0 : taskCounts?.[screen],
            label: OpsInboxScreenLabels[screen],
        });

        return [
            ...ScreenSubNavSections.map(screens => screens.map(screenToSection)),
            { label: 'Aligners', entries: AlignersInboxScreens.map(screenToSection) },
            customViewEntries,
        ];
    }, [taskCounts, customViewEntries]);
    const viewsLoading = useAsyncIsLoading(OpsInboxActions.LOAD_VIEWS.NAME);
    const loadViews = useOpsInboxAction('LOAD_VIEWS');

    return (
        <>
            <SubNavigation
                title={'Tasks'}
                titleAction={
                    <IconButton
                        onClick={async () => {
                            await refetch();
                            await loadViews({ client });
                        }}
                        disabled={tasksLoading || viewsLoading}
                        size={'small'}
                    >
                        <RefreshIcon />
                    </IconButton>
                }
                onChange={onScreenChange}
                value={screen}
                sections={sections}
                classes={{ list: classes.list, bottomContainer: classes.bottomContainer }}
                bottomContent={
                    <SavedViewsAutocomplete
                        onSelect={async value => await toggleFavorite({ client, searchId: value, favorited: true })}
                        type={LabsGqlCustomSearchType.Inbox}
                    />
                }
            />
            <SaveInboxViewModal />
        </>
    );
};
