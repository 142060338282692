import { useFirebaseStorage } from '../../context/firebase.context';
import { getFileNameWithExtension } from '../../util/file-utils';
import type { BulkDownloadState, DownloadRecord, FileRow } from './BulkDownloadDialog.types';
import { BulkDownloaderUtils } from './BulkDownloadUtils';
import { Assert } from '@orthly/runtime-utils';
import type { Reducer } from 'react';
import React from 'react';

type BulkDownloadActions =
    | { type: 'PROGRESS_FILE'; path: string; progress: number }
    | { type: 'DOWNLOAD_FILE_END'; path: string; filename: string }
    | { type: 'END_FILE'; path: string }
    | { type: 'ERROR_FILE'; path: string; error: string }
    | { type: 'REMOVE_DONE_FILES' };

function stateReducer(state: BulkDownloadState, action: BulkDownloadActions): BulkDownloadState {
    switch (action.type) {
        case 'PROGRESS_FILE':
            return {
                ...state,
                files: state.files.map(file =>
                    action.path === file.path
                        ? {
                              ...file,
                              status: 'downloading',
                              progress: action.progress,
                              error: null,
                          }
                        : file,
                ),
            };

        case 'DOWNLOAD_FILE_END':
            return {
                ...state,
                files: state.files.map(file =>
                    action.path === file.path
                        ? {
                              ...file,
                              status: 'downloaded',
                          }
                        : file,
                ),
            };

        case 'END_FILE':
            return {
                ...state,
                files: state.files.map(file => {
                    if (action.path === file.path) {
                        return {
                            ...file,
                            status: 'success',
                            error: null,
                        };
                    }
                    return file;
                }),
            };

        case 'ERROR_FILE':
            return {
                ...state,
                files: state.files.map(file =>
                    action.path === file.path
                        ? {
                              ...file,
                              status: 'error',
                              error: action.error,
                          }
                        : file,
                ),
            };

        case 'REMOVE_DONE_FILES':
            return {
                ...state,
                files: state.files.filter(file => file.status !== 'success'),
            };

        default:
            Assert.unreachable(action);
            return state;
    }
}

function trackBulkDownloaderEvent(_name: string, _data: Record<string, string | number | boolean>) {}

interface BulkDownloadActionCreators {
    startDownload(): Promise<void>;
    retryDownload(): Promise<void>;
}

export function useBulkDownloadState({
    files,
    onDownloadStart,
}: {
    files: DownloadRecord[];
    onDownloadStart: () => void;
}): [BulkDownloadState, BulkDownloadActionCreators] {
    const firebaseStorage = useFirebaseStorage();
    const [state, dispatch] = React.useReducer<Reducer<BulkDownloadState, BulkDownloadActions>>(stateReducer, {
        files: files.map(file => ({
            id: file.path,
            source: file.source,
            path: file.path,
            name: file.name,
            status: 'idle',
            error: null,
            downloadAttempts: 0,
        })),
    });

    // Downloads a given file, and tracks the entire process by dispatching events against the reducer.
    const downloadFileFromFirebase = React.useCallback(
        async (file: FileRow): Promise<{ blob: Blob; file: FileRow } | null> => {
            try {
                const blob = await BulkDownloaderUtils.downloadFileAsBlob(firebaseStorage, file, percentCompleted => {
                    dispatch({ path: file.path, type: 'PROGRESS_FILE', progress: percentCompleted });
                });
                trackBulkDownloaderEvent(`SUCCESSFUL_DOWNLOAD`, {
                    file: file.path,
                    downloadAttempts: file.downloadAttempts,
                });
                dispatch({
                    path: file.path,
                    filename: getFileNameWithExtension(file.name, file.path),
                    type: 'DOWNLOAD_FILE_END',
                });
                return { blob, file };
            } catch {
                trackBulkDownloaderEvent(`FAILED_DOWNLOAD`, {
                    file: file.path,
                    downloadAttempts: file.downloadAttempts,
                });
                dispatch({
                    type: 'ERROR_FILE',
                    path: file.path,
                    error: 'Failed to download',
                });
                return null;
            }
        },
        [firebaseStorage, dispatch],
    );

    const downloadFiles = React.useCallback(
        async (files: FileRow[]) => {
            const saveQueue = await Promise.all(
                files.map(file => {
                    return downloadFileFromFirebase(file);
                }),
            );

            const downloadedFiles = new Set<string>();
            for (const entry of saveQueue) {
                if (entry) {
                    const { blob, file } = entry;
                    if (!downloadedFiles.has(file.path)) {
                        // deduplicate downloads by path
                        downloadedFiles.add(file.path);
                        BulkDownloaderUtils.saveBlobToDisk(
                            blob,
                            file.source === 'firebase' ? getFileNameWithExtension(file.name, file.path) : file.name,
                        );
                        dispatch({
                            path: file.path,
                            type: 'END_FILE',
                        });

                        // Delay the next save, because otherwise Chrome will get overwhelmed by
                        // the number of download requests
                        await new Promise(resolve => setTimeout(resolve, 200));
                    }
                }
            }
        },
        [downloadFileFromFirebase],
    );

    // Starts the download for all files
    const startDownload = React.useCallback(async () => {
        onDownloadStart();
        await downloadFiles(state.files);
    }, [state.files, downloadFiles, onDownloadStart]);

    // Cleans out the successful files, then restarts downloads for all
    // failed downloads
    const retryDownload = React.useCallback(async () => {
        dispatch({ type: 'REMOVE_DONE_FILES' });
        await downloadFiles(state.files.filter(file => file.status === 'error'));
    }, [state.files, downloadFiles]);

    return [state, { startDownload, retryDownload }];
}
