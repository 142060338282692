import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { ImplantSystemInput } from '@orthly/dentin';
import type { LabsGqlImplantTypeLiteFragment } from '@orthly/graphql-operations';
import { CartItemV2Utils } from '@orthly/items';
import type { LabOrderItemSKUType } from '@orthly/items';
import React from 'react';

// duplicated from checkout
type UpdateImplantMetaFieldName = 'manufacturer' | 'system' | 'connection';
type CartItemImplantFieldName = 'manufacturer' | 'system' | 'connection_size';
function convertFieldName(name: UpdateImplantMetaFieldName): CartItemImplantFieldName {
    return name === 'connection' ? 'connection_size' : name;
}

interface ItemEditorV2ImplantSystemFieldsProps
    extends ItemEditorV2BaseProps<LabOrderItemSKUType.Implant | LabOrderItemSKUType.ImplantBridge> {
    implantSystems: LabsGqlImplantTypeLiteFragment[];
}

export const ItemEditorV2ImplantSystemFields: React.VFC<ItemEditorV2ImplantSystemFieldsProps> = ({
    item,
    updateItem,
    showError,
    implantSystems,
}) => {
    const implantMetadata = CartItemV2Utils.getImplantMetadata(item);

    return (
        <ImplantSystemInput
            implantSystems={implantSystems}
            linkMeta={{
                manufacturer: implantMetadata?.manufacturer ?? null,
                system: implantMetadata?.system ?? null,
                connection: implantMetadata?.connection_size ?? null,
            }}
            updateField={(fieldName, value) => {
                const propName = convertFieldName(fieldName);
                updateItem({ name: 'implant_metadata', payload: { [propName]: value } });
            }}
            showIncompleteError={showError}
        />
    );
};
