import type { IconName } from '@orthly/ui-primitives';
import { boldTitleFont, Button, FlossPalette, Icon, styled, useScreenIsLgOrUp } from '@orthly/ui-primitives';
import React from 'react';

const StyledButton = styled(Button)<{ checked: boolean }>(({ checked, theme }) => ({
    width: 'max-content',
    display: 'inline-flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    fontFamily: boldTitleFont,
    fontSize: '14px',
    minHeight: '48px',
    height: 'unset',
    lineHeight: '16px',
    '&&, &&:active, &&:focus, &&:hover': {
        backgroundColor: checked ? FlossPalette.PRIMARY_BACKGROUND : FlossPalette.TAN,
        color: checked ? FlossPalette.PRIMARY_PRESSED : FlossPalette.PRIMARY_FOREGROUND,
    },
    '&&:active svg': {
        color: checked ? FlossPalette.PRIMARY_PRESSED : FlossPalette.PRIMARY_FOREGROUND,
    },
    [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        gap: 0,
        fontSize: '10px',
        padding: '8px',
    },
}));

export const ToggleButton: React.VFC<
    {
        checked: boolean;
        compactLabel?: string;
        icon: IconName;
        label: string;
        onClick: () => void;
    } & React.HTMLAttributes<HTMLButtonElement>
> = ({ checked, icon, onClick, label, compactLabel = label, ...rest }) => {
    const isLarge = useScreenIsLgOrUp();

    return (
        <StyledButton checked={checked} variant={'secondary'} size={'small'} onClick={onClick} {...rest}>
            <Icon icon={icon} />
            {isLarge ? label : compactLabel}
        </StyledButton>
    );
};
