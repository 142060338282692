/* eslint-disable no-nested-ternary */
import { useActionTypes } from '../../../../../../utils/useTicketActionHooks';
import { getUserDisplayName, useUserById, useUsersById } from '../../../../../../utils/useUsers';
import { AppendActionDialog } from './AppendActionDialog';
import { CloseActionDialog } from './CloseActionDialog';
import { useOrderTicketsRefetch } from './OrderTickets.utils';
import { useClaimTicketActionMutation, useReleaseTicketActionMutation } from '@orthly/graphql-react';
import type { LabsGqlTicketActionDto, LabsGqlTicketDto } from '@orthly/graphql-schema';
import { LabsGqlActionStatus } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { useRootActionCommand } from '@orthly/ui';
import {
    AddIcon,
    Box,
    CheckIcon,
    CircularProgress,
    createStyles,
    FlossPalette,
    makeStyles,
    Menu,
    MenuItem,
    MoreVertIcon,
    PlayCircleFilledIcon,
    Tooltip,
} from '@orthly/ui-primitives';
import { AssignmentAvatar } from '@orthly/veneer';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        actionBlockWrap: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',

            '&:not(:first-child)': {
                marginTop: 8,
            },

            '& .dnd-orderAction': {
                flex: 1,
                display: 'flex',
                alignItems: 'center',
            },

            '& .dnd-orderAction-statusCanceled *': {
                textDecorationLine: 'line-through',
            },

            '& .dnd-orderAction-check-inactive': {
                marginLeft: 4,
                marginRight: 12,
                width: 18,
                height: 18,
                borderColor: FlossPalette.DARK_TAN,
                backgroundColor: FlossPalette.DARK_TAN,
                textAlign: 'center',
            },

            '& .dnd-orderAction-check-active': {
                marginLeft: 4,
                marginRight: 12,
                width: 18,
                height: 18,
                borderWidth: 2,
                borderStyle: 'solid',
                borderColor: FlossPalette.GRAY,
                cursor: 'pointer',
                '&:hover ': {
                    backgroundColor: FlossPalette.DARK_TAN,
                },
            },

            '& .dnd-orderAction-actionType': {
                fontWeight: 500,
            },

            '& .dnd-orderAction-detail': {
                color: FlossPalette.GRAY,
            },

            '& .dnd-orderAction-actions': {
                marginLeft: 'auto',
                display: 'flex',
                alignItems: 'center',

                '& > .dnd-orderAction-actionable': {
                    cursor: 'pointer',
                    width: 24,

                    '&:hover': {
                        opacity: 0.8,
                    },
                },
            },

            '& .dnd-orderAction-detailBlock': {
                marginTop: 4,
                marginLeft: 32,
                fontSize: 12,
                whiteSpace: 'pre-wrap',
            },

            '& .dnd-orderAction-detailBlock label': {
                fontWeight: 'bold',
            },
        },
    }),
);

const useClaimAction = (ticket: LabsGqlTicketDto, action: LabsGqlTicketActionDto) => {
    const refetchTickets = useOrderTicketsRefetch(ticket.order_id);
    const { submit, submitting } = useRootActionCommand(useClaimTicketActionMutation(), {
        successMessage: `Action claimed!`,
        onSuccess: () => {
            refetchTickets();
        },
    });
    return { claim: () => submit({ data: { ticket_id: ticket.id, action_id: action.id } }), claiming: submitting };
};

const useReleaseAction = (ticket: LabsGqlTicketDto, action: LabsGqlTicketActionDto) => {
    const refetchTickets = useOrderTicketsRefetch(ticket.order_id);
    const { submit, submitting } = useRootActionCommand(useReleaseTicketActionMutation(), {
        successMessage: `Action released!`,
        onSuccess: () => {
            refetchTickets();
        },
    });
    return { release: () => submit({ data: { ticket_id: ticket.id, action_id: action.id } }), releasing: submitting };
};

export const AssigneeButton: React.FC<{
    ticket: LabsGqlTicketDto;
    action: LabsGqlTicketActionDto;
    active: boolean;
}> = ({ ticket, action, active }) => {
    const assignedUser = useUserById(action.assigned_user_id);
    const { claim, claiming } = useClaimAction(ticket, action);

    return (
        <>
            {claiming ? (
                <CircularProgress />
            ) : (
                <>
                    {assignedUser && (
                        <Tooltip title={`${assignedUser.first_name} ${assignedUser.last_name}`}>
                            <Box>
                                <AssignmentAvatar user={assignedUser} />
                            </Box>
                        </Tooltip>
                    )}
                    {!action.assigned_user_id && active && (
                        <Tooltip title={active && 'Claim'}>
                            <PlayCircleFilledIcon
                                htmlColor={FlossPalette.STAR_GRASS}
                                className={'dnd-orderAction-actionable'}
                                onClick={() => claim()}
                            />
                        </Tooltip>
                    )}
                </>
            )}
        </>
    );
};

const ActionDetailBlock: React.FC<{ action: LabsGqlTicketActionDto; ticket: LabsGqlTicketDto }> = ({
    action,
    ticket,
}) => {
    const userIds = React.useMemo(
        () => _.uniq(_.compact([action.assigned_user_id, action.closed_by_user_id, action.created_by_user_id])),
        [action],
    );
    const users = useUsersById(userIds);
    const createdByAutomation: boolean = !!(
        action.id === ticket.actions[0]?.id && ticket.created_by_automation_event_id
    );

    return (
        <div className={'dnd-orderAction-detailBlock'}>
            <div>
                <label>Created:</label> {moment(action.created_at).format('M/D/YY h:mma')} by{' '}
                {getUserDisplayName(users[action.created_by_user_id])}{' '}
                {createdByAutomation ? '(Created by Automation)' : null}
            </div>
            {action.activate_at && (
                <div>
                    <label>Activates:</label> {moment(action.activate_at).format('M/D/YY h:mma')}
                </div>
            )}
            {action.due_at && (
                <div>
                    <label>Due:</label> {moment(action.due_at).format('M/D/YY h:mma')}
                </div>
            )}
            {action.assigned_user_id && (
                <div>
                    <label>Assignee:</label> {getUserDisplayName(users[action.assigned_user_id])}
                </div>
            )}
            {action.closed_at && action.closed_by_user_id && (
                <div>
                    <label>Closed:</label> {moment(action.closed_at).format('M/D/YY h:mma')} by{' '}
                    {getUserDisplayName(users[action.closed_by_user_id])}
                </div>
            )}
            {action.opening_notes && (
                <div>
                    <label>Notes:</label> {action.opening_notes}
                </div>
            )}
            {action.closing_notes && (
                <div>
                    <label>Closing Notes:</label> {action.closing_notes}
                </div>
            )}
        </div>
    );
};

interface ActionBlockProps {
    ticket: LabsGqlTicketDto;
    action: LabsGqlTicketActionDto;
    active: boolean;
    subtitle?: React.ReactNode;
    onSubtitleClick?: () => void;
    onClick?: () => void;
    showDetail?: boolean;
}
export const ActionBlock: React.FC<ActionBlockProps> = props => {
    const { ticket, action, active, subtitle, onSubtitleClick, onClick, showDetail } = props;
    const styles = useStyles();
    const session = useSession();
    const { resolveActionTypeName, resolveActionDisplayCategory } = useActionTypes();
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const { claim, claiming } = useClaimAction(ticket, action);
    const { release, releasing } = useReleaseAction(ticket, action);
    const [addAction, setAddAction] = React.useState(false);
    const [closeIntent, setCloseIntent] = React.useState<'complete' | 'cancel' | null>(null);

    return (
        <div className={styles.actionBlockWrap}>
            <div className={`dnd-orderAction dnd-orderAction-status${action.status}`}>
                {/* Completion check */}
                {active ? (
                    <Tooltip title={'Complete action'}>
                        <div
                            role={'button'}
                            aria-label={'Complete action'}
                            className={'dnd-orderAction-check-active'}
                            onClick={() => setCloseIntent('complete')}
                        />
                    </Tooltip>
                ) : (
                    <div className={'dnd-orderAction-check-inactive'}>
                        {action.status === LabsGqlActionStatus.Completed && (
                            <CheckIcon style={{ width: 12, height: 12 }} />
                        )}
                    </div>
                )}

                {/* Action type */}
                <Tooltip title={showDetail ? '' : action.opening_notes ?? ''} style={{ flex: 1 }}>
                    <div style={{ cursor: onClick ? 'pointer' : undefined }} onClick={onClick}>
                        <div className={'dnd-orderAction-actionType'}>
                            {action.followup_parent_id && 'Follow-up: '}
                            {showDetail && `${resolveActionDisplayCategory(action.type_id)} > `}
                            {resolveActionTypeName(action.type_id)}
                        </div>
                        {!showDetail && (
                            <div className={'dnd-orderAction-detail'}>
                                {action.due_at
                                    ? `Due ${moment(action.due_at).format('M/D/YY h:mma')}`
                                    : action.activate_at
                                      ? `${getActivationTitle(action.activate_at)} ${moment(action.activate_at).format(
                                            'M/D/YY h:mma',
                                        )}`
                                      : `Created ${moment(action.created_at).format('M/D/YY h:mma')}`}{' '}
                                &bull; {resolveActionDisplayCategory(action.type_id) ?? '...'}
                            </div>
                        )}
                        {subtitle && (
                            <div
                                className={'dnd-orderAction-detail'}
                                onClick={onSubtitleClick}
                                style={{ cursor: onSubtitleClick ? 'pointer' : undefined }}
                            >
                                {subtitle}
                            </div>
                        )}
                    </div>
                </Tooltip>

                {/* Actions */}
                <div className={'dnd-orderAction-actions'}>
                    <AssigneeButton ticket={ticket} action={action} active={active} />

                    {active && (
                        <>
                            <Tooltip title={'Add action to ticket'}>
                                <AddIcon
                                    htmlColor={FlossPalette.GRAY}
                                    fontSize={'small'}
                                    className={'dnd-orderAction-actionable'}
                                    onClick={() => setAddAction(true)}
                                />
                            </Tooltip>
                            {claiming || releasing ? (
                                <CircularProgress />
                            ) : (
                                <MoreVertIcon
                                    htmlColor={FlossPalette.GRAY}
                                    onClick={e => setAnchorEl(e.currentTarget)}
                                    className={'dnd-orderAction-actionable'}
                                />
                            )}
                            <Menu
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                anchorEl={anchorEl}
                                open={!!anchorEl}
                                onClose={() => setAnchorEl(null)}
                            >
                                {active &&
                                    action.assigned_user_id &&
                                    !action.followup_parent_id &&
                                    (action.assigned_user_id === session?.user_id ? (
                                        <MenuItem
                                            onClick={() => {
                                                setAnchorEl(null);
                                                if (window.confirm('Are you sure you want to release this action?')) {
                                                    void release();
                                                }
                                            }}
                                        >
                                            Release
                                        </MenuItem>
                                    ) : (
                                        <MenuItem
                                            onClick={() => {
                                                setAnchorEl(null);
                                                if (window.confirm('Are you sure you want to take over this action?')) {
                                                    void claim();
                                                }
                                            }}
                                        >
                                            Claim
                                        </MenuItem>
                                    ))}
                                {/* <MenuItem onClick={() => {}}>Edit note</MenuItem> */}
                                <MenuItem
                                    onClick={() => {
                                        setAnchorEl(null);
                                        setCloseIntent('cancel');
                                    }}
                                >
                                    Cancel
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </div>
            </div>
            {addAction && <AppendActionDialog ticket={ticket} onClose={() => setAddAction(false)} />}
            {closeIntent && (
                <CloseActionDialog
                    ticket={ticket}
                    action={action}
                    intent={closeIntent}
                    onClose={() => setCloseIntent(null)}
                />
            )}
            {showDetail && <ActionDetailBlock action={action} ticket={ticket} />}
        </div>
    );
};

function getActivationTitle(date: string) {
    const isPast = date < new Date().toISOString();
    return isPast ? `Activated` : `Activates`;
}
