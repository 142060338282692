import type { LabsGqlGetSavedSearchesQueryVariables } from '@orthly/graphql-operations';
import { LabsGqlCustomSearchType } from '@orthly/graphql-schema';

export const DANDY_SEARCH_WIDTH = 268;

export const FETCH_SAVED_ORDER_SEARCH_ARGS: LabsGqlGetSavedSearchesQueryVariables = {
    type: LabsGqlCustomSearchType.Order,
    limit: 5,
    unique: true,
};
