import { stylesFactory } from '../../util';
import { DragHandle, SortableItem, SortableOverlay } from './components';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import type { Active, UniqueIdentifier } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import React from 'react';
import type { ReactNode } from 'react';

const useStyles = stylesFactory(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        padding: 0,
        listStyle: 'none',
    },
}));

interface BaseItem {
    id: UniqueIdentifier;
}

interface Props<T extends BaseItem> {
    items: T[];
    onChange(items: T[]): void;
    renderItem(item: T): ReactNode;
}

/**
 * This component was built following the base template provided by dnd-kit for the Sortable object
 * Docs: https://docs.dndkit.com/presets/sortable
 * Examples: https://codesandbox.io/examples/package/@dnd-kit/sortable
 */
export function SortableList<T extends BaseItem>({ items, onChange, renderItem }: Props<T>) {
    const classes = useStyles();
    const [active, setActive] = React.useState<Active | null>(null);
    const activeItem = React.useMemo(() => items.find(item => item.id === active?.id), [active, items]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    return (
        <DndContext
            sensors={sensors}
            onDragStart={({ active }) => {
                setActive(active);
            }}
            onDragEnd={({ active, over }) => {
                if (over && active.id !== over?.id) {
                    const activeIndex = items.findIndex(({ id }) => id === active.id);
                    const overIndex = items.findIndex(({ id }) => id === over.id);

                    onChange(arrayMove(items, activeIndex, overIndex));
                }
                setActive(null);
            }}
            onDragCancel={() => {
                setActive(null);
            }}
        >
            <SortableContext items={items}>
                <ul className={classes.container} role={'application'}>
                    {items.map(item => (
                        <React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
                    ))}
                </ul>
            </SortableContext>
            <SortableOverlay>{activeItem ? renderItem(activeItem) : null}</SortableOverlay>
        </DndContext>
    );
}

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
