import type {
    LabsGqlDeliveryAddressFragment,
    LabsGqlValidateDeliveryAddressQueryVariables,
} from '@orthly/graphql-operations';
import type { ValidateDeliveryAddressLazyQueryHookResult } from '@orthly/graphql-react';
import { useValidateDeliveryAddressLazyQuery } from '@orthly/graphql-react';
import { useDebouncedValue, WarningIcon, SimpleCheckbox } from '@orthly/ui';
import { FlossPalette, Button, Collapse, Grid, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type Vars = LabsGqlValidateDeliveryAddressQueryVariables['data'];

function addressIsComplete(input: Partial<Vars>): input is Vars {
    return !!input.street_one && !!input.state && !!input.city && !!input.postal_code && !!input.country;
}

type UseValidationQueryResult = {
    error?: string;
    address?: LabsGqlDeliveryAddressFragment;
    fix?: Partial<Vars>;
    fixMessages?: string[];
};

function useValidationQueryResult(
    inputVars: Partial<Vars>,
    validationQuery: ValidateDeliveryAddressLazyQueryHookResult[1],
) {
    return React.useMemo<UseValidationQueryResult>(() => {
        if (!validationQuery.data || !addressIsComplete(inputVars)) {
            return { error: undefined, address: undefined, fix: undefined };
        }
        if (validationQuery.data.validateDeliveryAddress.__typename === 'DeliveryAddressValidationError') {
            return { error: validationQuery.data.validateDeliveryAddress.error, address: undefined, fix: undefined };
        }
        const validatedAddress = validationQuery.data.validateDeliveryAddress.validated_address;
        const cleanedCity = _.startCase(_.toLower(validatedAddress.city));
        const fixes = _.compact([
            validatedAddress.city.toLowerCase() !== inputVars.city?.toLowerCase() ? `City: ${cleanedCity}` : null,
            validatedAddress.state !== inputVars.state ? `State: ${validatedAddress.state}` : null,
            validatedAddress.postal_code !== inputVars.postal_code
                ? `Postal Code: ${validatedAddress.postal_code}`
                : null,
        ]);
        if (fixes.length > 0) {
            return {
                fixMessages: fixes,
                error: `Address is valid with the following fixes: `,
                fix: { city: cleanedCity, state: validatedAddress.state, postal_code: validatedAddress.postal_code },
            };
        }

        return {
            error: undefined,
            fix: undefined,
            address: validationQuery.data.validateDeliveryAddress.validated_address,
        };
    }, [inputVars, validationQuery.data]);
}

export interface ValidateDeliveryAddressQuery {
    fixMessages?: string[];
    fix?: Partial<Vars>;
    validated_address?: LabsGqlDeliveryAddressFragment;
    error?: string;
    loading: boolean;
}

export function useValidateDeliveryAddressQuery(input: Partial<Vars>): ValidateDeliveryAddressQuery {
    const abortController = React.useRef<AbortController | null>(null);
    const [triggerQuery, validationQuery] = useValidateDeliveryAddressLazyQuery({ fetchPolicy: 'network-only' });
    const inputVars = useDebouncedValue(input, 50);
    const queryNeedsFetch = addressIsComplete(inputVars) && !_.isEqual(inputVars, validationQuery.variables?.data);
    // This Hook lets us abort earlier queries that are launched when the inputVars are changed,
    // By passing it to the Apollo lazy query we will only query when the inputVars stop changing.
    React.useEffect(() => {
        if (queryNeedsFetch && addressIsComplete(inputVars)) {
            const currentAbortController = abortController.current;
            if (currentAbortController) {
                currentAbortController.abort();
            }
            const controller = new window.AbortController();
            abortController.current = controller;
            triggerQuery({
                variables: { data: inputVars },
                // abort controller allows us to abort the earlier query
                context: { fetchOptions: { signal: controller.signal } },
            });
        }
    }, [inputVars, queryNeedsFetch, triggerQuery, validationQuery, validationQuery.variables]);
    const { error, address, fix, fixMessages } = useValidationQueryResult(inputVars, validationQuery);
    return {
        error,
        fix,
        fixMessages,
        validated_address: address,
        loading: validationQuery.loading,
    };
}

interface DeliveryAddressValidationProps {
    validationQuery: ValidateDeliveryAddressQuery;
    ignoreError: boolean;
    setIgnoreError: (checked: boolean) => void;
    onFix: () => void;
}

export const DeliveryAddressValidation: React.FC<DeliveryAddressValidationProps> = props => {
    const { validationQuery, ignoreError, setIgnoreError, onFix } = props;
    return (
        <Collapse in={!!validationQuery.error} style={{ width: '100%' }}>
            <Grid container>
                <Grid
                    container
                    style={{ background: FlossPalette.ATTENTION_BG, color: FlossPalette.GRAY, padding: 8 }}
                    wrap={`nowrap`}
                >
                    <WarningIcon style={{ margin: 8, color: FlossPalette.ATTENTION }} />
                    <Grid container item style={{ padding: 8 }}>
                        <Text>{!!validationQuery.error ? `${validationQuery.error}` : ``}</Text>
                        {validationQuery.fixMessages && (
                            <ul style={{ width: `100%`, margin: `10px 0` }}>
                                {validationQuery.fixMessages?.map(f => <li key={f}>{f}</li>)}
                            </ul>
                        )}
                        {validationQuery.fix && (
                            <Button variant={`contained`} onClick={onFix}>
                                Fix Fields
                            </Button>
                        )}
                        {validationQuery.error && (
                            <SimpleCheckbox
                                CheckboxProps={{ style: { color: FlossPalette.GRAY }, color: 'secondary' }}
                                checked={ignoreError ?? false}
                                setChecked={setIgnoreError}
                                label={
                                    validationQuery.error.includes('PO box')
                                        ? `Confirm address is not a PO box`
                                        : `Confirm address is correct`
                                }
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Collapse>
    );
};
