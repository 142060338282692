import type { WithClassesProp } from '../../../util/floss.types';
import { ToolbarContainer } from '../../Containers/ToolbarContainer';
import { useOpenDevModal } from '../../DevModalHelpers';
import { NavigationLogoD, NavigationLogoDandy } from './NavigationLogoSvgs';
import { makeStyles } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

export type NavigationLogoClassKey = 'root' | 'logo' | 'logoFull' | 'logoCollapsed' | 'logoHidden';

const useStyles = makeStyles(() => ({
    root: {
        justifyContent: undefined,
        padding: '0 24px',
    },
    logo: {
        objectFit: 'contain',
        display: 'block',
        maxHeight: '100%',
        maxWidth: '100%',
        position: 'absolute',
    },
    collapsible: {
        cursor: 'pointer',
    },
    collapsed: {
        justifyContent: 'center',
    },
    fadeIn: {
        visibility: 'visible',
        transition: 'visibility 0s linear 0s, opacity 200ms',
        opacity: 1,
    },
    fadeOut: {
        visibility: 'hidden',
        transition: 'visibility 0s linear 200ms, opacity 200ms',
        opacity: 0,
    },
    logoFull: {
        maxHeight: '100%',
        width: 128,
        height: 24,
        left: 0,
    },
    logoCollapsed: { width: 24, height: 24, marginRight: 4 },
    logoHidden: { opacity: 0 },
}));

export interface NavigationLogoProps extends WithClassesProp<NavigationLogoClassKey> {
    collapsed: boolean;
    onToggleCollapsed?: () => void;
}

export const NavigationLogo: React.FC<NavigationLogoProps> = ({
    collapsed,
    classes: _classesParam,
    onToggleCollapsed,
}) => {
    const [__openDevModal, setOpenDevModal] = useOpenDevModal();
    const classes = useStyles();
    const handleNavigationClick = (event: React.MouseEvent<SVGSVGElement>) => {
        if (event.shiftKey) {
            setOpenDevModal(true);
            return;
        }

        onToggleCollapsed?.();
    };

    return (
        <ToolbarContainer className={clsx(classes.root, collapsed && classes.collapsed)}>
            <NavigationLogoD
                aria-label={'Dandy Logo (collapsed)'}
                role={'button'}
                onClick={handleNavigationClick}
                className={clsx(classes.logo, classes.logoCollapsed, collapsed ? classes.fadeIn : classes.fadeOut)}
            />
            <NavigationLogoDandy
                aria-label={'Dandy Logo (open)'}
                role={'button'}
                onClick={handleNavigationClick}
                className={clsx(classes.logo, classes.logoFull, collapsed && classes.logoHidden)}
            />
        </ToolbarContainer>
    );
};
