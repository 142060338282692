/* eslint-disable max-lines */
import { useFirebasePreviewMulti } from '../../hooks';
import { DandyLightbox } from '../DandyLightbox';
import { DesignQcRubricCategoryToLabelMap, BadgeWithCounts, useGuidedQcAnalytics } from './DesignQcCommon';
import type { DesignQcRubricCategory } from './configs/DesignQc.config.types';
import { useDesignQcPropSelector } from './state/DesignQc.context';
import type { DesignQcStateCompletedRubricEntry } from './state/DesignQc.state';
import { useCompleteDesignReviewTaskMutation, useSubmitDesignQaEvaluationMutation } from '@orthly/graphql-react';
import type {
    LabsGqlCompleteDesignReviewTaskCommand,
    LabsGqlDesignReviewGuidedQcResultEntryInput,
    LabsGqlSubmitDesignQaEvaluationCommand,
} from '@orthly/graphql-schema';
import { LabsGqlGuidedQcResultEntryScoreVerdict } from '@orthly/graphql-schema';
import { RootActionDialog, useChangeSubmissionFn, DandyRecommendedIcon } from '@orthly/ui';
import {
    FlossPalette,
    Text,
    stylesFactory,
    CheckboxPrimitive,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Tab,
    Tabs,
    ExpandMoreIcon,
    Button,
} from '@orthly/ui-primitives';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';

type FinalReviewTab = 'bad' | 'acceptable' | 'all';

const useStyles = stylesFactory(() => ({
    categoryRoot: {
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        borderRadius: `8px !important`,
    },
    categoryRowRoot: {
        minHeight: 96,
        padding: `8px 0px`,
    },
    categoryRowName: {
        width: 268,
        margin: 'auto 0px',
    },
    categoryRowResult: {
        width: 72,
        textAlign: 'right',
        margin: 'auto 0px',
    },
    categoryRowMarkups: {
        margin: 'auto 0px',
    },
    finalSubmissionRoot: {
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        cursor: 'pointer',
        borderRadius: 16,
    },
    finalSubmissionDisabled: {
        opacity: 0.5,
        cursor: 'unset',
    },
    finalSubmissionContent: {
        padding: 16,
    },
    finalSubmissionRecommendedBadge: {
        height: 24,
        padding: `0px 16px 0px 24px`,
        backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
        color: FlossPalette.WHITE,
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
    },
    finalSubmissionRecommendedBadgeIcon: {
        paddingTop: 4,
        marginRight: 8,
    },
    finalSubmissionRecommendedBadgeText: {
        paddingTop: 1,
    },
    finalReviewModalCategoryOuterWrapper: {
        maxHeight: 550,
        overflowY: 'scroll',
    },
    finalReviewModalTab: {
        height: 56,
        padding: 16,
    },
    photo: {
        height: 80,
        width: 80,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        borderRadius: 8,
        cursor: 'pointer',
    },
}));

const DesignQcFinalReviewModalCategoryGroupRow: React.VFC<{
    entry: DesignQcStateCompletedRubricEntry;
    showBottomBorder: boolean;
}> = ({ entry, showBottomBorder }) => {
    const classes = useStyles();

    const { order } = useDesignQcPropSelector(['order']);

    const photoPaths = React.useMemo(() => {
        return entry.result.type === 'bad' ? entry.result.markupUrls : [];
    }, [entry]);

    const { result: previews, loading } = useFirebasePreviewMulti(photoPaths.map(url => ({ source: url, name: url })));
    const [selectedAttachmentPreview, setSelectedAttachmentPreview] = React.useState<string | undefined>(undefined);
    const firstPhoto = previews?.[0];

    return (
        <Grid
            item
            container
            spacing={1}
            direction={'row'}
            className={classes.categoryRowRoot}
            style={{
                minHeight: 96,
                padding: `8px 0px`,
                borderBottom: showBottomBorder ? undefined : `1px solid ${FlossPalette.STROKE_LIGHT}`,
            }}
        >
            {selectedAttachmentPreview && (
                <DandyLightbox
                    setSelectedAttachmentPreview={setSelectedAttachmentPreview}
                    loading={loading}
                    previews={previews}
                    selectedAttachmentPreview={selectedAttachmentPreview}
                    onPhotoViewedAnalytics={(source, name) => {
                        return {
                            name: 'All - Portal - Photo Lightbox Viewed',
                            data: {
                                $groups: { order: order.id },
                                displayLocation: 'guided_design_qc',
                                photoSource: source,
                                photoName: name,
                            },
                        };
                    }}
                />
            )}
            <Grid item className={classes.categoryRowName}>
                {entry.name}
            </Grid>
            <Grid item className={classes.categoryRowResult}>
                <Text
                    variant={'body2'}
                    medium
                    color={entry.result.type === 'bad' ? 'SECONDARY_FOREGROUND' : 'PRIMARY_FOREGROUND'}
                >
                    {entry.result.type === 'bad' ? 'Not Ideal' : 'Ideal'}
                </Text>
            </Grid>
            <Grid
                item
                xs
                className={classes.categoryRowMarkups}
                onClick={() => setSelectedAttachmentPreview(firstPhoto?.name)}
            >
                {firstPhoto && <img src={firstPhoto.source} className={classes.photo} />}
            </Grid>
        </Grid>
    );
};

const DesignQcFinalReviewModalCategoryGroup: React.VFC<{
    category: DesignQcRubricCategory;
    entries: DesignQcStateCompletedRubricEntry[];
}> = ({ category, entries }) => {
    const classes = useStyles();
    const numIdeal = entries.filter(e => e.result.type !== 'bad').length;

    return (
        <Accordion className={classes.categoryRoot} variant={'outlined'}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container direction={'row'}>
                    <Grid item xs>
                        <Text variant={'h6'} medium>
                            {DesignQcRubricCategoryToLabelMap[category]}
                        </Text>
                    </Grid>
                    <Grid item>
                        <Text
                            variant={'body2'}
                            medium
                            color={numIdeal === entries.length ? 'PRIMARY_FOREGROUND' : 'SECONDARY_FOREGROUND'}
                        >
                            {numIdeal} / {entries.length} ideal
                        </Text>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container direction={'column'}>
                    {entries.map((entry, idx) => (
                        <DesignQcFinalReviewModalCategoryGroupRow
                            entry={entry}
                            showBottomBorder={idx === entries.length - 1}
                            key={`${entry.category}_${entry.name}`}
                        />
                    ))}
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

interface DesignQcFinalReviewSubmissionModalOptionProps {
    title: string;
    description: string | React.ReactNode;
    isSelected: boolean;
    onSelect: () => void;
    recommended?: boolean;
    disabled?: boolean;
}

const DesignQcFinalReviewSubmissionModalOption: React.VFC<DesignQcFinalReviewSubmissionModalOptionProps> = ({
    title,
    description,
    isSelected,
    onSelect,
    recommended,
    disabled,
}) => {
    const classes = useStyles();
    return (
        <Grid
            container
            direction={'column'}
            className={clsx(classes.finalSubmissionRoot, disabled ? classes.finalSubmissionDisabled : undefined)}
            onClick={() => {
                if (!disabled) {
                    onSelect();
                }
            }}
        >
            {recommended && (
                <Grid container item direction={'row'} className={classes.finalSubmissionRecommendedBadge}>
                    <Grid item className={classes.finalSubmissionRecommendedBadgeIcon}>
                        <DandyRecommendedIcon />
                    </Grid>
                    <Grid item xs className={classes.finalSubmissionRecommendedBadgeText}>
                        <Text variant={'caption'} color={'WHITE'}>
                            Recommended
                        </Text>
                    </Grid>
                </Grid>
            )}
            <Grid container item direction={'row'} className={classes.finalSubmissionContent}>
                <Grid item xs>
                    <Text variant={'body1'} medium color={'BLACK'}>
                        {title}
                    </Text>
                    <Text variant={'body2'} style={{ marginTop: 4 }} color={'DARK_GRAY'}>
                        {description}
                    </Text>
                </Grid>
                <Grid item style={{ marginLeft: 16 }}>
                    <CheckboxPrimitive color={'secondary'} checked={isSelected} disabled={disabled} />
                </Grid>
            </Grid>
        </Grid>
    );
};

interface DesignQcOrQaFinalReviewSubmissionModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    closeGuidedQcAndRefetch: () => Promise<void>;
}

const DesignQcFinalReviewSubmissionModal: React.VFC<DesignQcOrQaFinalReviewSubmissionModalProps> = ({
    open,
    setOpen,
    closeGuidedQcAndRefetch,
}) => {
    const { results, order, timings, current_user_id, has_design_previously_been_rejected } = useDesignQcPropSelector([
        'results',
        'order',
        'timings',
        'current_user_id',
        'has_design_previously_been_rejected',
    ]);
    const numSubcategoriesBad = results.filter(r => r.result.type === 'bad').length;

    const [submitMtn] = useCompleteDesignReviewTaskMutation();
    const [selection, setSelection] = React.useState<'approve' | 'reject' | 'reject_manual_edit' | undefined>(
        undefined,
    );

    const mtnSubmitter = (data: LabsGqlCompleteDesignReviewTaskCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [LabsGqlCompleteDesignReviewTaskCommand]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => [`Design review submitted`, {}],
        onSuccess: async () => {
            await closeGuidedQcAndRefetch();
        },
    });

    const trackSubmission = useGuidedQcAnalytics('All - Portal - Guided QC - Result Submitted');
    const handleSubmit = React.useCallback(() => {
        if (!selection) {
            return;
        }

        trackSubmission({
            decision: selection,
            badSubcategories: results.filter(entry => entry.result.type === 'bad').length,
            goodSubcategories: results.filter(entry => entry.result.type !== 'bad').length,
        });
        void submit({
            rejection:
                selection === 'reject' || selection === 'reject_manual_edit'
                    ? {
                          reasons: [],
                          notes: '',
                          next_assigned_user_id: selection === 'reject_manual_edit' ? current_user_id : undefined,
                          skip_next_review: false,
                      }
                    : null,
            order_id: order.id,
            file_urls: [],
            guided_qc_results: results.map<LabsGqlDesignReviewGuidedQcResultEntryInput>(entry => ({
                category: entry.category,
                name: entry.name,
                reasons: entry.result.type === 'bad' ? entry.result.reasons : undefined,
                verdict:
                    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                    // eslint-disable-next-line no-nested-ternary
                    entry.result.type === 'bad'
                        ? LabsGqlGuidedQcResultEntryScoreVerdict.Bad
                        : entry.result.type === 'acceptable'
                          ? LabsGqlGuidedQcResultEntryScoreVerdict.Acceptable
                          : LabsGqlGuidedQcResultEntryScoreVerdict.Ideal,
                markup_paths: entry.result.type === 'bad' ? entry.result.markupUrls : undefined,
                duration_ms: timings.find(t => t.category === entry.category && t.name === entry.name)?.durationMs ?? 0,
                affected_unns: entry.result.affectedUnns,
                note: entry.result.note,
            })),
        });
    }, [selection, results, current_user_id, order.id, timings, submit, trackSubmission]);

    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            title={'Choose Next Step'}
            loading={false}
            content={
                <Grid container direction={'column'}>
                    <Grid item>
                        <DesignQcFinalReviewSubmissionModalOption
                            title={'Approve designs'}
                            description={'Designs will be sent off to fabrication.'}
                            isSelected={selection === 'approve'}
                            onSelect={() => setSelection('approve')}
                            recommended={numSubcategoriesBad === 0}
                            disabled={numSubcategoriesBad > 0}
                        />
                    </Grid>
                    <Grid item style={{ marginTop: 24 }}>
                        <DesignQcFinalReviewSubmissionModalOption
                            title={'Send back to designer'}
                            description={
                                <>
                                    Designs will be sent back to the designer for modifications.
                                    <br />
                                    Subcategories marked as bad: {numSubcategoriesBad}
                                </>
                            }
                            isSelected={selection === 'reject'}
                            onSelect={() => setSelection('reject')}
                            recommended={numSubcategoriesBad > 0 && !has_design_previously_been_rejected}
                        />
                    </Grid>
                    <Grid item style={{ marginTop: 24 }}>
                        <DesignQcFinalReviewSubmissionModalOption
                            title={`Assign to yourself`}
                            description={'You can edit designs in 3Shape and fix it yourself.'}
                            isSelected={selection === 'reject_manual_edit'}
                            onSelect={() => setSelection('reject_manual_edit')}
                            recommended={numSubcategoriesBad > 0 && has_design_previously_been_rejected}
                        />
                    </Grid>
                    <Grid item container direction={'row'} style={{ marginTop: 32 }}>
                        <Grid item>
                            <Button variant={'secondary'} onClick={() => setOpen(false)}>
                                Go back
                            </Button>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                            <Button variant={'primary'} disabled={!selection || submitting} onClick={handleSubmit}>
                                Finish up
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
            CustomButton={() => null}
        />
    );
};

const DesignQaFinalReviewSubmissionModal: React.VFC<DesignQcOrQaFinalReviewSubmissionModalProps> = ({
    open,
    setOpen,
    closeGuidedQcAndRefetch,
}) => {
    const { results, timings, designQaEvaluationId } = useDesignQcPropSelector([
        'results',
        'timings',
        'designQaEvaluationId',
    ]);
    const numSubcategoriesBad = results.filter(r => r.result.type === 'bad').length;

    const [submitMtn] = useSubmitDesignQaEvaluationMutation();
    const [selection, setSelection] = React.useState<'approve' | 'reject' | undefined>(undefined);

    const mtnSubmitter = (data: LabsGqlSubmitDesignQaEvaluationCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [LabsGqlSubmitDesignQaEvaluationCommand]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => [`Design QA review submitted`, {}],
        onSuccess: async () => {
            await closeGuidedQcAndRefetch();
        },
    });

    const trackSubmission = useGuidedQcAnalytics('All - Portal - Guided QA - Result Submitted');
    const handleSubmit = React.useCallback(() => {
        if (!selection || !designQaEvaluationId) {
            return;
        }

        trackSubmission({
            decision: selection,
            badSubcategories: results.filter(entry => entry.result.type === 'bad').length,
            goodSubcategories: results.filter(entry => entry.result.type !== 'bad').length,
        });
        void submit({
            evaluation_id: designQaEvaluationId,
            is_passing: selection === 'approve',
            qc_results: results.map<LabsGqlDesignReviewGuidedQcResultEntryInput>(entry => ({
                category: entry.category,
                name: entry.name,
                reasons: entry.result.type === 'bad' ? entry.result.reasons : undefined,
                verdict:
                    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                    // eslint-disable-next-line no-nested-ternary
                    entry.result.type === 'bad'
                        ? LabsGqlGuidedQcResultEntryScoreVerdict.Bad
                        : entry.result.type === 'acceptable'
                          ? LabsGqlGuidedQcResultEntryScoreVerdict.Acceptable
                          : LabsGqlGuidedQcResultEntryScoreVerdict.Ideal,
                markup_paths: entry.result.type === 'bad' ? entry.result.markupUrls : undefined,
                duration_ms: timings.find(t => t.category === entry.category && t.name === entry.name)?.durationMs ?? 0,
                affected_unns: entry.result.affectedUnns,
                note: entry.result.note,
            })),
        });
    }, [selection, results, designQaEvaluationId, timings, submit, trackSubmission]);

    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            title={'Choose Next Step'}
            loading={false}
            content={
                <Grid container direction={'column'}>
                    <Grid item>
                        <DesignQcFinalReviewSubmissionModalOption
                            title={'QA Pass'}
                            description={'This design passes QA and is acceptable by our rubric.'}
                            isSelected={selection === 'approve'}
                            onSelect={() => setSelection('approve')}
                            recommended={numSubcategoriesBad === 0}
                            disabled={numSubcategoriesBad > 0}
                        />
                    </Grid>
                    <Grid item style={{ marginTop: 24 }}>
                        <DesignQcFinalReviewSubmissionModalOption
                            title={'QA Failure'}
                            description={
                                <>
                                    This design does not conform to our rubric and should not have been sent to the
                                    customer.
                                    <br />
                                    Subcategories marked as bad: {numSubcategoriesBad}
                                </>
                            }
                            isSelected={selection === 'reject'}
                            onSelect={() => setSelection('reject')}
                            recommended={numSubcategoriesBad > 0}
                        />
                    </Grid>
                    <Grid item container direction={'row'} style={{ marginTop: 32 }}>
                        <Grid item>
                            <Button variant={'secondary'} onClick={() => setOpen(false)}>
                                Go back
                            </Button>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                            <Button variant={'primary'} disabled={!selection || submitting} onClick={handleSubmit}>
                                Finish up
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
            CustomButton={() => null}
        />
    );
};

const DesignQcOrQaFinalReviewSubmissionModal: React.VFC<DesignQcOrQaFinalReviewSubmissionModalProps> = props => {
    const { designQaEvaluationId } = useDesignQcPropSelector(['designQaEvaluationId']);

    return !!designQaEvaluationId ? (
        <DesignQaFinalReviewSubmissionModal {...props} />
    ) : (
        <DesignQcFinalReviewSubmissionModal {...props} />
    );
};

interface DesignQcFinalReviewModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    closeGuidedQcAndRefetch: () => Promise<void>;
}

export const DesignQcFinalReviewModal: React.VFC<DesignQcFinalReviewModalProps> = ({
    open,
    setOpen,
    closeGuidedQcAndRefetch,
}) => {
    const classes = useStyles();
    const { results } = useDesignQcPropSelector(['results']);
    const [tab, setTab] = React.useState<FinalReviewTab>('all');
    const [submitOpen, setSubmitOpen] = React.useState<boolean>(false);

    const resultsByTab = React.useMemo<Record<FinalReviewTab, DesignQcStateCompletedRubricEntry[]>>(() => {
        const groupedResults = _.groupBy(results, r => r.result.type);

        return {
            all: results,
            bad: groupedResults.bad ?? [],
            acceptable: [...(groupedResults.acceptable ?? []), ...(groupedResults.ideal ?? [])],
        };
    }, [results]);

    const selectedResultsByCategory = React.useMemo(() => {
        return _.groupBy(resultsByTab[tab], r => r.category);
    }, [tab, resultsByTab]);

    return (
        <RootActionDialog
            style={{ height: 780 }}
            open={open}
            setOpen={setOpen}
            title={'Review & Submit'}
            loading={false}
            CustomButton={() => null}
            showCloseButton
            content={
                <Grid container direction={'column'} spacing={1}>
                    <DesignQcOrQaFinalReviewSubmissionModal
                        open={submitOpen}
                        setOpen={setSubmitOpen}
                        closeGuidedQcAndRefetch={closeGuidedQcAndRefetch}
                    />
                    <Grid item>
                        <Tabs
                            indicatorColor={'secondary'}
                            textColor={'inherit'}
                            value={tab}
                            onChange={(_, value) => setTab(value)}
                        >
                            <Tab
                                className={classes.finalReviewModalTab}
                                value={'all'}
                                label={<BadgeWithCounts label={'All'} total={resultsByTab.all.length} />}
                            />
                            <Tab
                                className={classes.finalReviewModalTab}
                                value={'acceptable'}
                                label={<BadgeWithCounts label={'Acceptable'} total={resultsByTab.acceptable.length} />}
                            />
                            <Tab
                                className={classes.finalReviewModalTab}
                                value={'bad'}
                                label={<BadgeWithCounts label={'Bad'} total={resultsByTab.bad.length} />}
                            />
                        </Tabs>
                    </Grid>

                    <Grid item className={classes.finalReviewModalCategoryOuterWrapper}>
                        <Grid container direction={'column'} spacing={1} style={{ height: '100%' }}>
                            {Object.entries(selectedResultsByCategory).map(([category, entries]) => (
                                <Grid item key={category}>
                                    <DesignQcFinalReviewModalCategoryGroup
                                        category={category as DesignQcRubricCategory}
                                        entries={entries}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid item container direction={'row'}>
                        <Grid item>
                            <Button variant={'secondary'} onClick={() => setOpen(false)}>
                                Go back
                            </Button>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                            <Button variant={'primary'} onClick={() => setSubmitOpen(true)} endIcon={'CheckIcon'}>
                                Submit review
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
        />
    );
};
