import { UNSAVED_VIEW_ID } from '../../../../redux/utils/SavedViewActionConstants';
import { useOrdersOverviewAction } from '../../state/OrdersOverview.actions';
import { useOrdersOverviewState } from '../../state/OrdersOverview.selectors';
import type { OrdersOverviewCustomView } from '../../state/OrdersOverview.types';
import { useApolloClient } from '@apollo/client';
import { LabsGqlSavedSearchVisibility } from '@orthly/graphql-schema';
import { SaveCustomViewModal } from '@orthly/ui';
import { useSnackbar } from 'notistack';
import React from 'react';

const SaveOrdersViewModalContent: React.FC<{ view: OrdersOverviewCustomView }> = props => {
    const { view } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [title, setTitle] = React.useState<string>(view.title);
    const [isPublic, setPublic] = React.useState<boolean>(view.visibility === LabsGqlSavedSearchVisibility.Public);
    const viewIsPublic = view.visibility === LabsGqlSavedSearchVisibility.Public;
    const client = useApolloClient();
    const saveView = useOrdersOverviewAction('SAVE_VIEW');
    const setEditingViewId = useOrdersOverviewAction('SET_EDITING_VIEW_ID');
    const onClose = React.useCallback(() => setEditingViewId(undefined), [setEditingViewId]);
    const onSave = React.useCallback(() => {
        saveView({
            title,
            client,
            searchId: view.id,
            visibility: isPublic ? LabsGqlSavedSearchVisibility.Public : LabsGqlSavedSearchVisibility.Private,
        });
        enqueueSnackbar(`View "${title}" saved!`, {
            variant: 'success',
            autoHideDuration: 2000,
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        });
        onClose();
    }, [saveView, title, view.id, enqueueSnackbar, onClose, client, isPublic]);
    return (
        <SaveCustomViewModal
            viewTitle={view.title}
            editedTitle={title}
            setEditedTitle={setTitle}
            onClose={onClose}
            onSave={onSave}
            newPublicValue={isPublic}
            setNewPublicValue={setPublic}
            isSaved={view.id !== UNSAVED_VIEW_ID}
            viewIsPublic={viewIsPublic}
        />
    );
};

export const SaveOrdersViewModal: React.FC = () => {
    const view = useOrdersOverviewState(s => {
        if (!s.editingViewId) {
            return undefined;
        }
        if (s.view?.id === s.editingViewId) {
            return s.view;
        }
        return s.savedViews[s.editingViewId];
    });
    return view ? <SaveOrdersViewModalContent view={view} /> : null;
};
