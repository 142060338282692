import { trackGuidedWaxupButtonClick } from '../../DesignViewerLiteDetachedContainer';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { ToggleButton } from './ToggleButton';
import React from 'react';

export const useColorToggleButton = () => {
    const { appearanceFilters, toggleAppearanceFilters } = useGuidedWaxupContext();

    const onClick = React.useCallback(() => {
        trackGuidedWaxupButtonClick('Toggle Color');
        toggleAppearanceFilters(['showColor']);
    }, [toggleAppearanceFilters]);

    return { checked: appearanceFilters.showColor, onClick };
};

export const ColorToggleButton: React.VFC = () => {
    const { checked, onClick } = useColorToggleButton();

    return <ToggleButton checked={checked} icon={'ModelTextureIcon'} onClick={onClick} label={'Color'} />;
};
