import { BooleanIcon } from '../../../components/BooleanIcon';
import { PrintTable } from '../../../components/PrintTable';
import { useBillingDetailsContext } from '../BillingDetails/providers/BillingDetailsProvider.graphql';
import type { SourceSplitOption } from '../actions/EditPaymentSplitConfig/usePartnerPaymentSplitSourceOptions.graphql';
import { usePartnerPaymentSplitSourceOptions } from '../actions/EditPaymentSplitConfig/usePartnerPaymentSplitSourceOptions.graphql';
import type { PartnerBillingInfo } from '../usePartnerBilling';
import { InvoicePaymentLog } from './InvoicePaymentLog';
import type { Invoice, InvoiceRow } from './InvoiceTable.types';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useListPartnerBillingQuery } from '@orthly/graphql-react';
import { LabsGqlStripeInvoiceStatus } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { DefaultValidationMap, QuickForm, RootActionDialog, SimpleSelect, useRootActionCommand } from '@orthly/ui';
import { Button, Grid, Icon, IconButton, InputAdornment, Menu, MenuItem, Tooltip } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

const PayInvoiceModalDetails: React.FC<{
    invoice: InvoiceRow<Invoice>;
    sourceOptions: SourceSplitOption[];
}> = ({ invoice, sourceOptions }) => {
    const detailsRow = React.useMemo(() => {
        const dueDate = moment.utc(invoice.due_date);
        return {
            invoice: `${invoice.month_formatted} ${invoice.type}`,
            partner: invoice.partner_name,
            due_date: dueDate.format('MM/DD/YYYY'),
            overdue: <BooleanIcon val={dueDate.isBefore(moment())} />,
            amountRemaining: Format.currency(invoice.amount_remaining),
        };
    }, [invoice.amount_remaining, invoice.due_date, invoice.month_formatted, invoice.partner_name, invoice.type]);
    return (
        <>
            <PrintTable rootStyle={{ marginBottom: 16 }} rows={[detailsRow]} />
            <InvoicePaymentLog sourceOptions={sourceOptions} invoice={invoice} style={{ marginBottom: 16 }} />
        </>
    );
};

interface ImportFormFields {
    payment_amount_dollars: number;
    charge_date: string;
    charge_id: string;
    payment_source_id: string;
}

interface PaymentDialogProps {
    invoice: InvoiceRow<Invoice>;
    open: boolean;
    setOpen: (open: boolean) => void;
    partner: Pick<PartnerBillingInfo, 'id' | 'usage_payment_source_config'>;
}

const PayInvoiceWithManualStripePayment_Mutation = graphql(`
    mutation PayInvoiceWithManualStripePayment($data: PayInvoiceWithManualStripePaymentInput!) {
        payInvoiceWithManualStripePayment(data: $data) {
            id
        }
    }
`);

const ImportManualInvoicePaymentDialog: React.FC<PaymentDialogProps> = props => {
    const { invoice, open, setOpen } = props;

    const { refetchBillingDetails } = useBillingDetailsContext();

    const manualPaymentMtn = useMutation(PayInvoiceWithManualStripePayment_Mutation);
    const { submit: manualPaymentFn } = useRootActionCommand(manualPaymentMtn, {
        successMessage: 'Payment Imported!',
        onSuccess: async () => {
            await refetchBillingDetails();
            setOpen(false);
        },
    });

    const { sourceOptions, sourcesLoading } = usePartnerPaymentSplitSourceOptions(invoice.partner_id, !open);

    const handleSubmit = (formFields: ImportFormFields) => {
        const amountCents = Math.round(formFields.payment_amount_dollars * 100);
        const createdAt = new Date(formFields.charge_date).toJSON();
        return manualPaymentFn({
            data: {
                amountCents,
                createdAt,
                invoiceId: invoice.id,
                partnerId: invoice.partner_id,
                paymentSourceId: formFields.payment_source_id,
                stripeChargeId: formFields.charge_id,
            },
        });
    };

    return (
        <RootActionDialog
            loading={sourcesLoading}
            open={open}
            setOpen={setOpen}
            title={'Import Manual Stripe Payment'}
            content={
                <Grid container>
                    <PayInvoiceModalDetails invoice={invoice} sourceOptions={sourceOptions} />
                    <QuickForm<ImportFormFields>
                        fields={{
                            payment_amount_dollars: {
                                type: 'number',
                                fieldProps: {
                                    InputProps: {
                                        startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
                                    },
                                },
                                validation: DefaultValidationMap.number.max(invoice.amount_remaining / 100),
                            },
                            charge_date: {
                                type: 'date',
                                fieldProps: { disableFuture: true },
                            },
                            charge_id: {
                                type: 'text',
                                label: 'Stripe Charge ID',
                                validation: DefaultValidationMap.text
                                    .length(27, 'Stripe charge ids must be 27 chars long')
                                    .regex(/^(ch_|py_).*$/, 'Stripe charge ids must start with ch_ or py_'),
                            },
                            payment_source_id: { type: 'text', label: 'Stripe Source ID' },
                        }}
                        onSubmit={async result => await handleSubmit(result)}
                        initialValues={{ payment_amount_dollars: invoice.amount_remaining / 100 }}
                    />
                </Grid>
            }
            buttonText={'Import Manual Payment'}
            CustomButton={() => null}
        />
    );
};

const PayInvoiceWithPaymentSource_Mutation = graphql(`
    mutation PayInvoiceWithPaymentSource($data: PayInvoiceWithSourceInput!) {
        payInvoiceWithPaymentSource(data: $data) {
            id
        }
    }
`);

const PayInvoiceWithConfig_Mutation = graphql(`
    mutation PayInvoiceWithConfig($data: PayInvoiceWithConfigInput!) {
        payInvoiceWithConfig(data: $data) {
            id
        }
    }
`);

const PayInvoiceDirectlyDialog: React.FC<PaymentDialogProps> = ({ invoice, open, setOpen, partner }) => {
    const { sourceOptions, sourcesLoading } = usePartnerPaymentSplitSourceOptions(invoice.partner_id, !open);
    const { refetchBillingDetails } = useBillingDetailsContext();

    const { usage_payment_source_config } = partner;
    const splitConfig = usage_payment_source_config;
    const [sourceId, setSourceId] = React.useState<string | undefined>(
        undefined, // Just set to initial undefined because our sourceOptions may not have loaded yet
    );

    React.useEffect(() => {
        // When we finish loading sources...
        if (!sourcesLoading) {
            // Set our default selected option
            setSourceId(splitConfig ? undefined : sourceOptions.find(s => s.is_default)?.id);
        }
    }, [splitConfig, sourceOptions, sourcesLoading]);

    const payInvoiceWithSourceMtn = useMutation(PayInvoiceWithPaymentSource_Mutation);
    const { submit: payInvoiceWithSource, submitting: payingInvoiceWithSource } = useRootActionCommand(
        payInvoiceWithSourceMtn,
        {
            successMessage: 'Invoice paid!',
            onSuccess: async () => {
                await refetchBillingDetails();
                setOpen(false);
            },
        },
    );
    const payInvoiceWithDefaultConfigMtn = useMutation(PayInvoiceWithConfig_Mutation);
    const { submit: payInvoiceWithDefaultConfig, submitting: payingInvoiceWithConfig } = useRootActionCommand(
        payInvoiceWithDefaultConfigMtn,
        {
            successMessage: 'Invoice paid!',
            onSuccess: async () => {
                await refetchBillingDetails();
                setOpen(false);
            },
        },
    );

    const handleSubmit = () => {
        if (sourceId) {
            return payInvoiceWithSource({
                data: { invoiceId: invoice.id, organizationId: partner.id, sourceId },
            });
        }

        return payInvoiceWithDefaultConfig({
            data: { invoiceId: invoice.id, organizationId: partner.id },
        });
    };

    const submitting = React.useMemo(() => {
        return sourceId ? payingInvoiceWithSource : payingInvoiceWithConfig;
    }, [sourceId, payingInvoiceWithSource, payingInvoiceWithConfig]);

    const buttonText = React.useMemo(() => {
        const amtRemainingFmt = Format.currency(invoice.amount_remaining);
        const selectedSource = !sourceId ? undefined : sourceOptions.find(s => s.id === sourceId)?.label;
        if (selectedSource) {
            return `Pay ${amtRemainingFmt} using ${selectedSource}`;
        }
        if (splitConfig) {
            return `Pay ${amtRemainingFmt} using partner's ${splitConfig.type} payment split`;
        }
        return 'Please select a source';
    }, [invoice.amount_remaining, sourceId, sourceOptions, splitConfig]);

    const buttonDisabled = (!splitConfig && !sourceId) || submitting;
    return (
        <RootActionDialog
            loading={sourcesLoading || submitting}
            open={open}
            setOpen={setOpen}
            title={`Pay ${invoice.partner_name}'s Invoice [${invoice.month_formatted} ${invoice.type}]`}
            content={
                <Grid container>
                    <PayInvoiceModalDetails invoice={invoice} sourceOptions={sourceOptions} />
                    <Grid container style={{ marginBottom: 8 }}>
                        <SimpleSelect
                            label={'Select Source'}
                            options={sourceOptions.map(o => ({ value: o.id, label: o.label }))}
                            placeholder={splitConfig ? `Use ${splitConfig.type} payment split` : undefined}
                            onChange={setSourceId}
                            value={sourceId}
                        />
                    </Grid>
                    <Grid container>
                        <Button
                            variant={'contained'}
                            fullWidth
                            disabled={buttonDisabled}
                            onClick={() => handleSubmit().catch(console.error)}
                        >
                            {buttonText}
                        </Button>
                    </Grid>
                </Grid>
            }
            buttonText={''}
            CustomButton={() => null}
        />
    );
};

export const PayInvoiceModal: React.FC<{
    invoice: InvoiceRow<Invoice>;
}> = ({ invoice }) => {
    const isHidden = invoice.status !== LabsGqlStripeInvoiceStatus.Open || invoice.amount_remaining === 0;

    const [openDialog, setOpenDialog] = React.useState<'pay' | 'import'>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const { data: partnerData } = useListPartnerBillingQuery({
        variables: { limit: 1, offset: 0, partner_ids: [invoice.partner_id] },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        skip: isHidden || !openDialog,
    });
    const partner = partnerData?.listPartnerBillingAccounts[0];

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (option: 'pay' | 'import') => () => {
        setAnchorEl(null);
        setOpenDialog(option);
    };

    if (isHidden) {
        return null;
    }

    return (
        <Grid container>
            <IconButton onClick={handleClick}>
                <Tooltip title={'Pay Invoice'}>
                    <Icon icon={'Money'} />
                </Tooltip>
            </IconButton>
            <Menu
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={handleClose('pay')}>Pay Invoice (Click for options)</MenuItem>
                <MenuItem onClick={handleClose('import')}>Import Manual Stripe Charge</MenuItem>
            </Menu>
            {openDialog === 'pay' && partner && (
                <PayInvoiceDirectlyDialog
                    partner={partner}
                    invoice={invoice}
                    open={true}
                    setOpen={() => setOpenDialog(undefined)}
                />
            )}
            {openDialog === 'import' && partner && (
                <ImportManualInvoicePaymentDialog
                    partner={partner}
                    invoice={invoice}
                    open={true}
                    setOpen={() => setOpenDialog(undefined)}
                />
            )}
        </Grid>
    );
};
