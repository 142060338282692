import { useAcceptDcmFiles } from './AcceptFilesFromBrowser.hooks';
import { File3dDropLoader } from './File3dDropLoader';
import { initializeScanReviewViewStateFromDcm } from './ScanReviewLandingPage.utils';
import {
    type ScanReviewDcmFile,
    extractScanReviewDcmFileData,
    loadScanReviewDcmFileData,
    ScanReviewPanelType,
    type ScanReviewRecordFactory,
    ScanReviewShadeMatchingViewAppProvider,
    ScanReviewShadeMatchingView,
    useChairsideShadeMatchingOnImageLoadCallback,
} from '@orthly/dentin';
import { Jaw } from '@orthly/shared-types';
import { StackY, styled, StackX } from '@orthly/ui';
import { FlossPalette, ToggleButton, Box, ToggleButtonGroup } from '@orthly/ui-primitives';
import React from 'react';

const JawToggleButton = styled(ToggleButton)({
    border: 'none',
    marginLeft: 8,
    marginRight: 8,
    borderRadius: '0.25em',
    fontSize: 16,
    height: 32,
});

export const ScanReviewShadeMatchingLandingPage: React.VFC = () => {
    const [dcms, setDcms] = React.useState<ScanReviewDcmFile[]>([]);
    const { onDcmDrop } = useAcceptDcmFiles(setDcms);

    const [upperJawFactory, setUpperJawFactory] = React.useState<ScanReviewRecordFactory | null>(null);
    const [lowerJawFactory, setLowerJawFactory] = React.useState<ScanReviewRecordFactory | null>(null);

    const viewState = React.useMemo(() => {
        return {
            [ScanReviewPanelType.Upper]: null,
            [ScanReviewPanelType.Lower]: null,
            [ScanReviewPanelType.Left]: null,
            [ScanReviewPanelType.Right]: null,
            [ScanReviewPanelType.Front]: null,
        };
    }, []);

    const [shadeColor, setShadeColor] = React.useState<string>('rgb(255, 255, 255)');

    // After the DCM is loaded the texture data is converted in memory to an HTML image element. This is an
    // async function that accepts a callback that will be invoked once the image loading is complete. The callback
    // returned by this hook returns a factory function that is customized to construct scan records/meshes with
    // the correct materials and attributes needed for shade matching.
    const onScanImageLoadCallback = useChairsideShadeMatchingOnImageLoadCallback();

    React.useEffect(() => {
        const upperJawData = extractScanReviewDcmFileData(Jaw.UPPER, dcms);
        const lowerJawData = extractScanReviewDcmFileData(Jaw.LOWER, dcms);
        initializeScanReviewViewStateFromDcm(upperJawData, lowerJawData, viewState);
        if (upperJawData) {
            loadScanReviewDcmFileData(Jaw.UPPER, upperJawData, onScanImageLoadCallback, setUpperJawFactory);
        }
        if (lowerJawData) {
            loadScanReviewDcmFileData(Jaw.LOWER, lowerJawData, onScanImageLoadCallback, setLowerJawFactory);
        }
    }, [dcms, onScanImageLoadCallback, viewState]);

    const [jawType, setJawType] = React.useState<Jaw | null>(null);
    React.useEffect(() => {
        if (upperJawFactory) {
            setJawType(Jaw.UPPER);
            return;
        } else if (lowerJawFactory) {
            setJawType(Jaw.LOWER);
            return;
        }
        setJawType(null);
    }, [upperJawFactory, lowerJawFactory]);

    return (
        <StackY
            sx={{
                flex: 'auto',
                overflow: 'hidden',
                height: '100vh',
                backgroundColor: FlossPalette.WHITE,
            }}
        >
            <File3dDropLoader scan={'Upper Jaw'} onDcmDrop={(files, event) => onDcmDrop(Jaw.UPPER, files, event)} />
            <File3dDropLoader scan={'Lower Jaw'} onDcmDrop={(files, event) => onDcmDrop(Jaw.LOWER, files, event)} />
            <ScanReviewShadeMatchingViewAppProvider
                upperJawFactory={upperJawFactory}
                lowerJawFactory={lowerJawFactory}
                viewState={viewState}
                onShadePicked={shade => setShadeColor(`rgb(${shade[0]},${shade[1]},${shade[2]})`)}
            >
                <ScanReviewShadeMatchingView jawType={jawType} maxRadiusMm={1.6} />
                <StackX>
                    <Box
                        sx={{
                            position: 'absolute',
                            zIndex: 21,
                            top: 86,
                            right: 16,
                            width: 80,
                            height: 80,
                            borderRadius: 2,
                            border: `1px solid ${FlossPalette.BLACK}`,
                            backgroundColor: shadeColor,
                        }}
                    />
                    <ToggleButtonGroup
                        disabled={!jawType || !upperJawFactory || !lowerJawFactory}
                        size={'small'}
                        value={jawType}
                        exclusive
                        onChange={(_evt, newValue) => {
                            if (!newValue) {
                                return;
                            }
                            const newJawType = newValue as Jaw;
                            setJawType(newJawType);
                        }}
                        aria-label={'Platform'}
                    >
                        <JawToggleButton value={Jaw.UPPER}>Upper</JawToggleButton>
                        <JawToggleButton value={Jaw.LOWER}>Lower</JawToggleButton>
                    </ToggleButtonGroup>
                </StackX>
            </ScanReviewShadeMatchingViewAppProvider>
        </StackY>
    );
};
