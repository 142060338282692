import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import { AllSurgicalGuideDrillKits } from '@orthly/items';
import type { LabOrderItemSKUType } from '@orthly/items';
import { SimpleAutocomplete } from '@orthly/ui';
import React from 'react';

export const ItemEditorV2SurgicalGuideDrillKitField: React.VFC<
    ItemEditorV2BaseProps<LabOrderItemSKUType.SurgicalGuide>
> = ({ item, updateItem, showError }) => {
    return (
        <SimpleAutocomplete
            label={'Drill Kit'}
            options={AllSurgicalGuideDrillKits.map(kit => ({
                value: kit,
                label: kit,
            }))}
            onChange={val =>
                updateItem({
                    name: 'surgical_guide_implant_metadata',
                    payload: { drill_kit: val ?? null },
                })
            }
            AutocompleteProps={{
                value: item.unit.implant_metadata.drill_kit,
            }}
            error={showError && !item.unit.implant_metadata.drill_kit ? 'This field is required' : undefined}
        />
    );
};
