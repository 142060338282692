import FaviconDandy from '@orthly/ui/assets/logos/favicon.png';

function createFaviconLink(id: string, rel: string, href: string): HTMLLinkElement {
    const link = document.createElement('link');
    link.id = id;
    link.rel = rel;
    link.href = href;

    return link;
}

function swapFavicons(faviconToRemove: string, faviconToAdd: HTMLLinkElement) {
    const oldLink = document.getElementById(faviconToRemove);
    if (oldLink) {
        document.head.removeChild(oldLink);
        document.head.appendChild(faviconToAdd);
    }
}

export function showBrowserTabAlert(alertText: string, audio: HTMLAudioElement | null, alertIcon: string) {
    void audio?.play()?.catch(error => {
        console.error('Failed to play audio', error);
    });

    const oldTitle = document.title;
    document.title = alertText;
    swapFavicons('dandy-favicon', createFaviconLink('bell-favicon', 'shortcut icon', alertIcon));

    setTimeout(() => {
        document.title = oldTitle;
        swapFavicons('bell-favicon', createFaviconLink('dandy-favicon', 'shortcut icon', FaviconDandy));
    }, 10_000);
}
