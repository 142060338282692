import TriosClick from '../../../assets/images/TriosClick.svg';
import { OrderDetailBlock } from '../OrderDetails';
import { useQuery } from '@apollo/client';
import AlignerRetainerImage from '@orthly/dentin/assets/images/skus/Aligner_Retainer.svg';
import type {
    GetAlignerRetainerSummaryForOrderQuery as RetainerSummaryQuery,
    GetAlignerRetainerSummaryForOrderQueryVariables as RetainerSummaryVariables,
} from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import type {
    LabsGqlAlignerCaseFragment,
    LabsGqlLabOrderFragment,
    LabsGqlOrder,
    LabsGqlLabOrderSingleFulfillmentFragment,
    LabsGqlAlignerRetainerFragment,
} from '@orthly/graphql-operations';
import type { LabsGqlAlignerPlan } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { getFreeRetainersIncludedWithPlan, getRetainerPriceInCentsV2 } from '@orthly/shared-types';
import {
    FlossPalette,
    stylesFactory,
    Text,
    useScreenIsMobileOrVerticalTablet,
    Button,
    Collapse,
    Dialog,
    Divider,
    Grid,
    CloseIcon,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const useStyles = stylesFactory(() => ({
    retainerImage: {
        position: 'relative',
        height: 160,
        '&>img': {
            position: 'absolute',
            right: 0,
            bottom: -36,
            height: 'calc(100% + 64px)',
        },
    },
    retainerList: {
        borderLeft: `1px solid ${FlossPalette.DARK_TAN}`,
        rowGap: '8px',
        paddingLeft: 20,
    },
    retainerListItem: {
        backgroundColor: FlossPalette.WHITE,
        borderRadius: 8,
        padding: '8px 16px',
        columnGap: '16px',
    },
    compactList: {
        margin: 0,
        paddingLeft: 24,
        listStyleType: 'decimal',
        '&>li::marker': {
            fontSize: '1rem',
        },
    },
}));

const RetainerImage: React.VFC = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.retainerImage}>
            <img alt={'Dandy Retainers'} src={AlignerRetainerImage} />
        </Grid>
    );
};

const RetainerListItem: React.VFC<{
    retainer: LabsGqlAlignerRetainerFragment;
    fulfillment: LabsGqlLabOrderSingleFulfillmentFragment;
}> = ({ retainer, fulfillment }) => {
    const classes = useStyles();
    const { priceInCents, quantity } = retainer;
    return (
        <Grid
            container
            direction={'row'}
            wrap={'nowrap'}
            justifyContent={'space-between'}
            className={classes.retainerListItem}
        >
            <Text variant={'body2'}>
                {moment(fulfillment.created_at).format('MMM Do')}, {Format.pluralize('set', quantity)}
            </Text>
            <Text variant={'body2'} color={'DARK_GRAY'}>
                {_.startCase(fulfillment.status)}
            </Text>
            <Text variant={'body2'} color={'DARK_GRAY'}>
                {priceInCents === 0 ? 'Free' : Format.currency(priceInCents, 'cents', false)}
            </Text>
        </Grid>
    );
};

const RetainerList: React.VFC<{
    lab_order: LabsGqlLabOrderFragment;
    aligner_case: LabsGqlAlignerCaseFragment;
}> = ({ lab_order, aligner_case }) => {
    const { fulfillment } = lab_order;
    const classes = useStyles();
    return (
        <Grid container direction={'column'} wrap={'nowrap'} className={classes.retainerList}>
            <Text variant={'body2'} color={'DARK_GRAY'} style={{ height: 32 }}>
                Inventory
            </Text>
            {aligner_case.retainers.current && (
                <RetainerListItem retainer={aligner_case.retainers.current} fulfillment={fulfillment.current} />
            )}
            {aligner_case.retainers.historical.map((retainer, index) => {
                const historical_fulfillment = fulfillment.history[index];
                return (
                    historical_fulfillment && (
                        <RetainerListItem retainer={retainer} fulfillment={historical_fulfillment} key={index} />
                    )
                );
            })}
        </Grid>
    );
};

const enum RetainerMethodDialogVariant {
    selection = 'selection',
    newScan = 'newScan',
}

const RetainerMethodSelectionDialogContent: React.VFC<{
    setMethodDialogVariant(methodDialogVariant: RetainerMethodDialogVariant): void;
    order: LabsGqlOrder;
}> = ({ setMethodDialogVariant, order }) => {
    return (
        <>
            <Text variant={'h4'}>How do you want to start?</Text>
            <Grid container direction={'row'} wrap={'nowrap'} style={{ marginTop: 40, columnGap: 40 }}>
                <Grid container direction={'column'} wrap={'nowrap'}>
                    <Grid
                        container
                        direction={'row'}
                        wrap={'nowrap'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Text variant={'h6'}>New scan</Text>
                        <Text
                            variant={'body2'}
                            color={'WHITE'}
                            display={'inline'}
                            style={{
                                backgroundColor: FlossPalette.BURGUNDY,
                                fontSize: 12,
                                padding: '0 8px',
                                borderRadius: 2,
                            }}
                        >
                            RECOMMENDED
                        </Text>
                    </Grid>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        Captures the exact anatomy of the patient, having a lower rate of fit problems.
                    </Text>
                    <Button
                        variant={'primary'}
                        endIcon={'ChevronRight'}
                        style={{ marginTop: 24 }}
                        onClick={() => setMethodDialogVariant(RetainerMethodDialogVariant.newScan)}
                    >
                        Start from scan
                    </Button>
                </Grid>
                <Divider
                    orientation={'vertical'}
                    /* should use the `flexItem` prop here once we get the newer mui */
                    style={{ height: 'auto' }}
                />
                <Grid container direction={'column'} wrap={'nowrap'}>
                    <Text variant={'h6'}>Last step from the plan</Text>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        We’ll use the last treatment plan step to design the retainer.
                    </Text>
                    <Button
                        variant={'primary'}
                        endIcon={'ChevronRight'}
                        style={{ marginTop: 24 }}
                        onClick={() => {
                            const url = new URL('/lab/submit', window.location.origin);
                            url.searchParams.set('scanId', order.scan_export_id);
                            url.searchParams.set('scanReused', 'true');
                            url.searchParams.set('stage', 'retainer');
                            window.open(url.href, '_blank');
                        }}
                    >
                        Start from last step
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

const RetainerMethodNewScanDialogContent: React.VFC<{
    setMethodDialogVariant(methodDialogVariant: RetainerMethodDialogVariant): void;
    setMethodDialogOpen(methodDialogOpen: boolean): void;
}> = ({ setMethodDialogVariant, setMethodDialogOpen }) => {
    const classes = useStyles();
    return (
        <>
            <Button
                variant={'ghost'}
                startIcon={'ChevronLeft'}
                style={{ alignSelf: 'flex-start' }}
                onClick={() => setMethodDialogVariant(RetainerMethodDialogVariant.selection)}
            >
                Back
            </Button>
            <Text variant={'h4'}>Ordering retainers from a scan</Text>
            <Grid container direction={'row'} wrap={'nowrap'} style={{ marginTop: 40, columnGap: 20 }}>
                <Grid container direction={'column'} wrap={'nowrap'} justifyContent={'space-between'}>
                    <ol className={classes.compactList}>
                        <li>
                            <Text variant={'body2'}>
                                If applicable, place any restorations or fixed lingual retainers
                            </Text>
                        </li>
                        <li>
                            <Text variant={'body2'}>In Chairside, perform a Diagnostic Scan.</Text>
                        </li>
                        <li>
                            <Text variant={'body2'}>Upload these scans to existing Aligners order.</Text>
                        </li>
                    </ol>
                    <Button variant={'secondary'} onClick={() => setMethodDialogOpen(false)}>
                        Close
                    </Button>
                </Grid>
                <img
                    src={TriosClick}
                    alt={'Click the Trios icon on your desktop'}
                    style={{
                        width: '100%',
                        height: 200,
                        backgroundColor: FlossPalette.BLACK,
                        borderRadius: 16,
                        objectFit: 'scale-down',
                        objectPosition: 'calc(50% + 6px) 50%',
                    }}
                />
            </Grid>
        </>
    );
};

const RetainerMethodDialog: React.VFC<{
    methodDialogOpen: boolean;
    setMethodDialogOpen(methodDialogOpen: boolean): void;
    order: LabsGqlOrder;
}> = props => {
    const { methodDialogOpen, setMethodDialogOpen, order } = props;
    const [methodDialogVariant, setMethodDialogVariant] = React.useState<RetainerMethodDialogVariant>(
        RetainerMethodDialogVariant.selection,
    );
    return (
        <Dialog open={methodDialogOpen}>
            <Button
                variant={'secondary'}
                style={{
                    margin: '8px 8px 0 0',
                    padding: 0,
                    width: 32,
                    height: 32,
                    minWidth: 'auto',
                    alignSelf: 'flex-end',
                }}
                onClick={() => setMethodDialogOpen(false)}
            >
                <CloseIcon style={{ color: FlossPalette.BLACK }} />
            </Button>
            <Grid style={{ margin: 40, marginTop: 0 }}>
                <Collapse in={methodDialogVariant === RetainerMethodDialogVariant.selection}>
                    <RetainerMethodSelectionDialogContent
                        setMethodDialogVariant={setMethodDialogVariant}
                        order={order}
                    />
                </Collapse>
                <Collapse in={methodDialogVariant === RetainerMethodDialogVariant.newScan}>
                    <RetainerMethodNewScanDialogContent
                        setMethodDialogVariant={setMethodDialogVariant}
                        setMethodDialogOpen={setMethodDialogOpen}
                    />
                </Collapse>
            </Grid>
        </Dialog>
    );
};

export const VeneerAlignerRetainersSummary_Fragment = graphql(`
    fragment AlignerCaseRetainersSummary on AlignerCaseRetainersSummaryDTO {
        __typename
        freeSetsRemaining
        totalSetsOrdered
    }
`);

export const VeneerAlignerRetainersSummary_Query = graphql(`
    query GetAlignerRetainerSummaryForOrder($order_id: String!) {
        getAlignerRetainerSummaryForOrder(order_id: $order_id) {
            ...AlignerCaseRetainersSummary
        }
    }
`);

function getFreeSetsIncludedCopy(selectedPlan: LabsGqlAlignerPlan | null, freeSetsRemaining: number) {
    const freeSetsIncluded = selectedPlan ? getFreeRetainersIncludedWithPlan(`${selectedPlan}`) : 0;
    if (!selectedPlan || freeSetsIncluded === 0 || freeSetsRemaining === 0) {
        return null;
    }
    const planName = _.startCase(selectedPlan);
    const setsPlural = Format.pluralize('set', freeSetsRemaining);
    if (freeSetsRemaining < freeSetsIncluded) {
        return `You have ${setsPlural} of free retainers remaining on your plan (${planName})`;
    }
    return `Your plan (${planName}) includes ${setsPlural} of free retainers.`;
}

function getOrderMoreCopy(totalSetsOrdered: number, extraSetPriceInCents: number): string {
    const parts: string[] = [];
    if (totalSetsOrdered > 0) {
        parts.push(`You've ordered ${Format.pluralize('set', totalSetsOrdered)} for this patient so far.`);
    }
    if (extraSetPriceInCents > 0) {
        parts.push(`An extra set costs ${Format.currency(extraSetPriceInCents, 'cents', false)}.`);
    }
    if (totalSetsOrdered > 0) {
        parts.push('Do you need to order more?');
    }
    return parts.join(' ');
}

export const OrderDetailAlignerRetainersBlock: React.VFC<{
    lab_order: LabsGqlLabOrderFragment;
    aligner_case: LabsGqlAlignerCaseFragment;
}> = ({ lab_order, aligner_case }) => {
    const { data } = useQuery<RetainerSummaryQuery, RetainerSummaryVariables>(VeneerAlignerRetainersSummary_Query, {
        variables: { order_id: lab_order.id },
    });
    const summary = getFragmentData(VeneerAlignerRetainersSummary_Fragment, data?.getAlignerRetainerSummaryForOrder);
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const [methodDialogOpen, setMethodDialogOpen] = React.useState(false);
    const totalSetsOrdered = summary?.totalSetsOrdered ?? 0;
    const freeSetsRemaining = summary?.freeSetsRemaining ?? 0;
    const extraSetPriceInCents = getRetainerPriceInCentsV2(`${aligner_case.aligner_arch}`, 1, freeSetsRemaining);

    return (
        <OrderDetailBlock
            variant={'full'}
            contentStyle={{ flexDirection: isMobile ? 'column' : 'row', flexWrap: 'nowrap', gap: 8 }}
            innerRootStyle={{ overflow: 'visible' }}
        >
            <Grid container direction={'column'} wrap={'nowrap'}>
                <Text variant={'body2'} color={'DARK_GRAY'} medium style={{ height: 32 }}>
                    Retainers
                </Text>
                <Text variant={'h6'}>
                    {totalSetsOrdered > 0
                        ? 'Need to order more retainers?'
                        : `It might be time to order retainers for ${lab_order.patient.first_name}`}
                </Text>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    {getFreeSetsIncludedCopy(aligner_case.dandy_plan, freeSetsRemaining)}
                </Text>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    {getOrderMoreCopy(totalSetsOrdered, extraSetPriceInCents)}
                </Text>
                <div style={{ flexGrow: 1, minHeight: 16 }} />
                <Button
                    variant={'primary'}
                    endIcon={'CartIcon'}
                    style={{ alignSelf: 'flex-start' }}
                    onClick={() => setMethodDialogOpen(true)}
                >
                    Order retainers
                </Button>
            </Grid>
            {/** display inventory if the old retainer system mechanism is set on the order  */}
            {aligner_case.retainers.current && <RetainerList lab_order={lab_order} aligner_case={aligner_case} />}
            {!aligner_case.retainers.current && !isMobile && <RetainerImage />}
            <RetainerMethodDialog
                methodDialogOpen={methodDialogOpen}
                setMethodDialogOpen={setMethodDialogOpen}
                order={lab_order}
            />
        </OrderDetailBlock>
    );
};
