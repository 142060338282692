import { RouterUtils } from '../../utils/router/RouterUtils';
import type { RefabOrReturnSubmitData, RefabOrReturnSubmitResult, TextUploadLinkResult } from '@orthly/dentin';
import { ATTACH_REFAB_FILES_PATH, OrderToolbar, PracticeFullScreenDialog, RefabFlowWrapper } from '@orthly/dentin';
import {
    useCreateReturnForRefabMutation,
    useGetDoctorPreferencesByIdQuery,
    useMarkNeedsRefabricationMutation,
    useOrder,
    useReasonCodesForOrderItemQuery,
    useSendRefabFilesUploadLinkMutation,
} from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import type { ImpersonatedQRCodeLinkResult } from '@orthly/veneer';
import {
    MobileRefabFlowFilesUploaderPage,
    RefabFileUploader,
    RefabSupplementalInfoStepOne,
    RefabSupplementalInfoStepTwoGraphql,
    useImpersonatedQrCodeLink,
    useFeatureFlag,
} from '@orthly/veneer';
import React from 'react';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';

const basePath = RouterUtils.pathForScreen('refab_flowops');

const RefabFlowOpsForOrder: React.VFC = () => {
    const { orderId: order_id } = useParams<{ orderId: string }>();
    const { value: enableAttachExistingScansInRefab } = useFeatureFlag('enableAttachExistingScansInRefab');
    const { order, loading: loadingOrder, refetch } = useOrder(order_id);
    const { order: refabricatedOrder } = useOrder(order?.refabrication_order_id ?? '', {
        skip: !order?.refabrication_order_id,
    });
    const { data: doctorPreferences } = useGetDoctorPreferencesByIdQuery({
        variables: { doctor_id: order?.doctor_preferences_id ?? '' },
        skip: !order?.doctor_preferences_id,
    });
    const history = useHistory();
    React.useEffect(() => {
        if (!loadingOrder && !order) {
            history.replace('/');
        }
    }, [loadingOrder, order, history]);

    const [submitFullRefabMtn] = useMarkNeedsRefabricationMutation();
    const [submitReturnMtn] = useCreateReturnForRefabMutation();
    const mtnSubmitter = ({ command, needsReturn }: RefabOrReturnSubmitData) => {
        if (needsReturn) {
            return submitReturnMtn({
                variables: {
                    data: command,
                },
            });
        }
        return submitFullRefabMtn({
            variables: {
                data: command,
            },
        });
    };
    const { submit, submitting } = useChangeSubmissionFn<RefabOrReturnSubmitResult, [RefabOrReturnSubmitData]>(
        mtnSubmitter,
        {
            successMessage: () => ['Refab created!'],
        },
    );

    function getMobileURL(additionalPath?: string) {
        const rootUrl = `${basePath}/${order_id}/${ATTACH_REFAB_FILES_PATH}`;
        const fullUrl = additionalPath ? `${rootUrl}/${additionalPath}` : rootUrl;

        const mobileURL = new URL(fullUrl, window.location.origin);
        return mobileURL.toString();
    }

    function useTextUploadLink(uploadLink: string): TextUploadLinkResult {
        const [sendTextMtn] = useSendRefabFilesUploadLinkMutation();
        const textSubmitter = async (phoneNumber: string) => {
            await sendTextMtn({
                variables: {
                    data: {
                        upload_link: uploadLink,
                        doctor_name: order?.doctor_name,
                        practice_id: order?.partner_id ?? '',
                        phone_number: phoneNumber,
                    },
                },
            });
        };
        const { submit: sendText, submitting } = useChangeSubmissionFn<void, [string]>(textSubmitter, {
            successMessage: () => ['Check your phone for the upload link!', {}],
        });

        return {
            sendText,
            submitting,
            placeholderPhoneNumber: '',
        };
    }

    const useGenerateUploaderConfig = (
        additionalPath?: string,
    ): { qrCode: ImpersonatedQRCodeLinkResult; textUploadLink: TextUploadLinkResult } => {
        const mobileURL = getMobileURL(additionalPath);
        const qrCode = useImpersonatedQrCodeLink(mobileURL);
        const textUploadLink = useTextUploadLink(mobileURL);

        return {
            qrCode,
            textUploadLink,
        };
    };

    return (
        <PracticeFullScreenDialog dialogPaperStyle={{ overflowY: 'auto', overflowX: 'clip' }}>
            <OrderToolbar order={order} onClose={() => history.goBack()} />
            <RefabFlowWrapper
                order={order}
                refabricatedOrder={refabricatedOrder}
                loadingOrder={loadingOrder}
                doctorPreferences={doctorPreferences?.preferences}
                refetch={refetch}
                submit={submit}
                submitting={submitting}
                enableAttachExistingScans={!!enableAttachExistingScansInRefab}
                openChairsidePdf={undefined}
                // these screens relies on veneer code (which can't be imported into dentin)
                // we use dependency injection to provide it.
                SupplementalInfoStepOne={RefabSupplementalInfoStepOne}
                SupplementalInfoStepTwo={RefabSupplementalInfoStepTwoGraphql}
                isOps={true}
                // this uploader relies on veneer code (which can't be imported into dentin)
                // we use dependency injection to provide it.
                RefabFileUploader={RefabFileUploader}
                useGenerateUploaderConfig={useGenerateUploaderConfig}
                useReasonCodesForOrderItemQuery={useReasonCodesForOrderItemQuery}
            />
        </PracticeFullScreenDialog>
    );
};

export const RefabFlowOpsRoot: React.VFC = () => {
    return (
        <Switch>
            <Route
                path={`${basePath}/:orderId/${ATTACH_REFAB_FILES_PATH}/items/:itemId`}
                component={MobileRefabFlowFilesUploaderPage}
            />
            <Route
                path={`${basePath}/:orderId/${ATTACH_REFAB_FILES_PATH}`}
                component={MobileRefabFlowFilesUploaderPage}
            />
            <Route path={`${basePath}/:orderId`} component={RefabFlowOpsForOrder} />
            <Redirect to={'/'} />
        </Switch>
    );
};
