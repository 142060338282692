import { useGuidedWaxupTabs, usePresetTabClick, type PresetTabs } from '../GuidedWaxup.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupSelector } from '../state/GuidedWaxupState';
import { FlossPalette, Text, Box } from '@orthly/ui-primitives';
import React from 'react';

const PresetTab: React.FC<{ tab: PresetTabs }> = ({ tab }) => {
    const tabRef = React.useRef<HTMLDivElement>(null);
    const presetTabClick = usePresetTabClick();
    const { selectedTab } = useGuidedWaxupContext();
    const isSelected = selectedTab === tab.value;

    React.useLayoutEffect(() => {
        if (isSelected) {
            tabRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
            });
        }
    }, [isSelected]);

    return (
        <Box
            onClick={() => presetTabClick(tab)}
            key={tab.value}
            ref={tabRef}
            sx={{
                borderBottom: isSelected ? `2px solid ${FlossPalette.PRIMARY_FOREGROUND}` : 'none',
                cursor: 'pointer',
            }}
        >
            <Text variant={'body2'} medium color={isSelected ? 'PRIMARY_FOREGROUND' : 'GRAY'} noWrap>
                {tab.label}
            </Text>
        </Box>
    );
};

export const PresetTabTopbar: React.VFC = () => {
    const presets = useGuidedWaxupSelector(s => s.presets);
    const tabs = useGuidedWaxupTabs(presets);

    return (
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-between', gap: '1ch' }}>
                {tabs.map(tab => (
                    <PresetTab tab={tab} key={tab.value} />
                ))}
            </Box>
        </Box>
    );
};
