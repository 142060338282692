import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const PAGE_LOADED_AT = new Date().getTime();
const ONE_DAY = 24 * 60 * 60 * 1000;

export function shouldReloadPage(
    search: string,
    hash: string,
    safeToReloadRoutes: string[],
    stale: boolean,
    pathname: string,
) {
    const sanitizedPathname = pathname.replace('/', '');
    return search === '' && hash === '' && safeToReloadRoutes.includes(sanitizedPathname) && stale;
}

export function useReloadStalePages(safeToReloadRoutes: string[], maxAgeInDays: number) {
    const history = useHistory();
    const { pathname, search, hash } = useLocation();

    const currentTime = new Date();
    const stale = currentTime.getTime() - PAGE_LOADED_AT > maxAgeInDays * ONE_DAY;

    const shouldReload = shouldReloadPage(search, hash, safeToReloadRoutes, stale, pathname);

    React.useEffect(() => {
        if (shouldReload) {
            history.go(0);
        }
    }, [shouldReload, history]);

    return shouldReload;
}

export type ReloadStalePagesProps = {
    safeToReloadRoutes: string[];
    maxAgeInDays?: number;
};

/**
 * This component forces a page reload if the page is stale.
 */
export const ReloadStalePages: React.FC<ReloadStalePagesProps> = ({ children, safeToReloadRoutes, maxAgeInDays }) => {
    const sanitizedSafeRoutes = safeToReloadRoutes.map(route => route.replace('/', ''));
    const stale = useReloadStalePages(sanitizedSafeRoutes, maxAgeInDays || 3);
    if (stale) {
        return null;
    }
    return <>{children}</>;
};
