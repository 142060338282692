import { useSculptingState } from '../AppState.hooks';
import { SliderRow } from './SliderRow';
import { SimpleCheckbox } from '@orthly/ui';
import { Button, Grid, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    switchRoot: {
        margin: '15px 0px 10px 2px',
        width: 45,
    },
    switchLabel: {
        margin: '15px 0px 10px 8px',
        fontSize: 14,
    },
    checkboxLabel: {
        margin: 0,
        fontSize: 14,
    },
    panel: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        padding: '0px 0px',
        marginTop: '8px',
    },
    tinyButton: {
        height: 32,
        minWidth: `0px !important`,
        padding: `2px 5px !important`,
        margin: `10px 4px !important`,
    },
}));

interface SculptBrushButtonProps {
    label: string;
    active: boolean;
    onClick: () => void;
}

const LimitDistanceButton: React.VFC<SculptBrushButtonProps> = ({ label, active, onClick: _onClick }) => {
    const classes = useStyles();

    const onClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        _onClick();
        (evt.target as any)?.blur?.();
    };

    return (
        <Button
            variant={active ? 'primary' : 'secondary'}
            className={classes.tinyButton}
            onClick={onClick}
            size={'small'}
            fullWidth={false}
        >
            {label}
        </Button>
    );
};

export const SculptToLimitMenu: React.VFC = () => {
    const classes = useStyles();

    const params = useGetMenuParams();
    if (!params) {
        return null;
    }

    const {
        presetValues,
        currentIntensity,
        minIntensity,
        maxIntensity,
        stepIntensity,
        setIntensity,
        raiseVerticesEnabled,
        toggleRaiseVerticesEnabled,
    } = params;

    return (
        <Grid item container className={classes.panel}>
            <Grid item xs={12}>
                <SliderRow
                    label={'Distance'}
                    value={currentIntensity}
                    onChange={setIntensity}
                    min={minIntensity}
                    max={maxIntensity}
                    step={stepIntensity}
                />
            </Grid>
            {presetValues.map(value => {
                const label = value.toFixed(2);
                return (
                    <Grid key={label} item xs={3}>
                        <LimitDistanceButton
                            label={label}
                            active={currentIntensity === value}
                            onClick={() => setIntensity(value)}
                        />
                    </Grid>
                );
            })}
            {toggleRaiseVerticesEnabled && (
                <Grid item xs={12}>
                    <SimpleCheckbox
                        label={'Add material'}
                        checked={raiseVerticesEnabled ?? false}
                        setChecked={toggleRaiseVerticesEnabled}
                        FormControlLabelProps={{
                            classes: {
                                label: classes.checkboxLabel,
                            },
                        }}
                        blur
                    />
                </Grid>
            )}
        </Grid>
    );
};

const OCCLUSAL_BRUSH_PRESETS = [0.1, 0.25, 0.35, 0.4] as const;
const PROXIMAL_BRUSH_PRESETS = [-0.07, -0.05, -0.04, -0.03] as const;
const CURTAINS_BRUSH_PRESETS = [-0.05, -0.04, -0.03, 0.02] as const;

interface MenuParams {
    presetValues: readonly number[];
    currentIntensity: number;
    minIntensity: number;
    maxIntensity: number;
    stepIntensity: number;
    setIntensity: (intensity: number) => void;
    raiseVerticesEnabled?: boolean;
    toggleRaiseVerticesEnabled?: () => void;
}

function useGetMenuParams(): MenuParams | undefined {
    const {
        sculptingEnabled,
        brushSettings,
        isProximalBrushEnabled,
        setBrushProximalIntensity,
        toggleProximalRaiseVerticesEnabled,
        isCurtainsBrushEnabled,
        setBrushCurtainsIntensity,
        isOpposingBrushEnabled,
        setBrushOpposingIntensity,
    } = useSculptingState();

    if (!sculptingEnabled) {
        return undefined;
    }

    if (isProximalBrushEnabled) {
        return {
            presetValues: PROXIMAL_BRUSH_PRESETS,
            currentIntensity: brushSettings.proximalLimitMm,
            minIntensity: brushSettings.ranges.proxTargetMin,
            maxIntensity: brushSettings.ranges.proxTargetMax,
            stepIntensity: brushSettings.ranges.proxTargetStep,
            setIntensity: setBrushProximalIntensity,
            raiseVerticesEnabled: brushSettings.isProximalRaiseVerticesEnabled,
            toggleRaiseVerticesEnabled: toggleProximalRaiseVerticesEnabled,
        };
    }

    if (isCurtainsBrushEnabled) {
        return {
            presetValues: CURTAINS_BRUSH_PRESETS,
            currentIntensity: brushSettings.curtainsLimitMm,
            minIntensity: brushSettings.ranges.curtainsTargetMin,
            maxIntensity: brushSettings.ranges.curtainsTargetMax,
            stepIntensity: brushSettings.ranges.curtainsTargetStep,
            setIntensity: setBrushCurtainsIntensity,
            raiseVerticesEnabled: brushSettings.isProximalRaiseVerticesEnabled,
            toggleRaiseVerticesEnabled: toggleProximalRaiseVerticesEnabled,
        };
    }

    if (isOpposingBrushEnabled) {
        return {
            presetValues: OCCLUSAL_BRUSH_PRESETS,
            currentIntensity: brushSettings.occlusalLimitMm,
            minIntensity: brushSettings.ranges.occlusalTargetMin,
            maxIntensity: brushSettings.ranges.occlusalTargetMax,
            stepIntensity: brushSettings.ranges.occlusalTargetStep,
            setIntensity: setBrushOpposingIntensity,
        };
    }
}
