import { CategoryConfig } from '../CategoryConfig/CategoryConfig';
import type { BillingCreditCategories } from './BillingCreditCategoriesQuery.graphql';
import { GetAllBillingCreditCategories_Query } from './BillingCreditCategoriesQuery.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { SessionGuardUtils } from '@orthly/session-client';
import React from 'react';

const CreateBillingCreditCategory_Mutation = graphql(`
    mutation CreateBillingCreditCategory($name: String!) {
        createBillingCreditCategory(data: { name: $name }) {
            id
            updated_at
            created_at
            name
            archived
        }
    }
`);

const UpdateBillingCreditCategory_Mutation = graphql(`
    mutation UpdateBillingCreditCategory($data: UpdateBillingCreditCategoryCommand!) {
        updateBillingCreditCategory(data: $data) {
            id
            archived
            name
        }
    }
`);

const canEditCategories = SessionGuardUtils.hasCapability('billing', 'billing.edit_credit_categories');

export const BillingCreditCategoriesRoot: React.VFC = () => {
    const {
        data: { getAllBillingCreditCategories } = {},
        loading,
        refetch,
    } = useQuery<{
        getAllBillingCreditCategories: BillingCreditCategories;
    }>(GetAllBillingCreditCategories_Query, {
        variables: { include_archived: true },
    });
    const create = useMutation(CreateBillingCreditCategory_Mutation);
    const update = useMutation(UpdateBillingCreditCategory_Mutation);

    return (
        <CategoryConfig
            categoryName={'Credit'}
            canEdit={canEditCategories}
            data={getAllBillingCreditCategories || []}
            loading={loading}
            refetch={refetch}
            create={create}
            update={update}
        />
    );
};
