import type { LabsGqlScanExportItemFragment, LabsGqlScanExportItemForOrderFragment } from '@orthly/graphql-operations';
import { LabsGqlOrderItemSkuType, LabsGqlOrderItemLinkType } from '@orthly/graphql-schema';

// Only the fields used by the function are selected here to keep this compatible with Chairside
export type CalculateSkuItemType =
    | Pick<LabsGqlScanExportItemFragment, 'link_type' | 'teeth'>
    | Pick<LabsGqlScanExportItemForOrderFragment, 'LinkTypeClass' | 'TypeIDs'>;

export function calculateSKU(item: CalculateSkuItemType): LabsGqlOrderItemSkuType {
    if ('link_type' in item && item.link_type) {
        return calculateLinkTypeSKU(item.link_type);
    }
    if ('LinkTypeClass' in item && item.LinkTypeClass) {
        return calculateLinkTypeSKU(item.LinkTypeClass);
    }
    if ('teeth' in item && item.teeth[0]?.unit_type) {
        return calculateUnitTypeSKU(item.teeth[0].unit_type);
    }
    if ('TypeIDs' in item && item.TypeIDs[0]?.unit_type) {
        return calculateUnitTypeSKU(item.TypeIDs[0].unit_type);
    }
    return LabsGqlOrderItemSkuType.Other;
}

function calculateLinkTypeSKU(linkType: string): LabsGqlOrderItemSkuType {
    switch (linkType) {
        case 'Connector':
        case LabsGqlOrderItemLinkType.Bridge:
            return LabsGqlOrderItemSkuType.Bridge;
        case LabsGqlOrderItemLinkType.Implant:
            return LabsGqlOrderItemSkuType.Implant;
        case LabsGqlOrderItemLinkType.ImplantBridge:
            return LabsGqlOrderItemSkuType.ImplantBridge;
        default:
            return LabsGqlOrderItemSkuType.Other;
    }
}

function calculateUnitTypeSKU(unitType: string): LabsGqlOrderItemSkuType {
    switch (unitType) {
        // Crown unit types
        case 'Crown':
        case 'CrownPontic':
            return LabsGqlOrderItemSkuType.Crown;
        // Implant unit types
        case 'Abutment':
        case 'AbutmentScrewRetainedCrown':
            return LabsGqlOrderItemSkuType.Implant;
        // Aligner unit types
        case 'Aligner':
        case 'Aligners':
            return LabsGqlOrderItemSkuType.Aligners;
        // Denture unit types
        case 'Denture':
        case 'FullDenture':
            return LabsGqlOrderItemSkuType.Denture;
        // Inlay unit types
        case 'Inlay':
        case 'Onlay':
            return LabsGqlOrderItemSkuType.Inlay;
        case 'Veneer':
            return LabsGqlOrderItemSkuType.Veneer;
        // Partial Denture unit types
        case 'Clasp':
        case 'Partial':
        case 'Partial Denture':
            return LabsGqlOrderItemSkuType.Partial;
        // Other unit types
        case 'SurgicalGuide':
        case 'Splints':
        case 'ImplantPlanning':
            return LabsGqlOrderItemSkuType.Other;
        default:
            return LabsGqlOrderItemSkuType.Other;
    }
}
