import type { LabsGqlActionTypeDtoFragment } from '@orthly/graphql-operations';
import {
    useGetActionCapabilitiesQuery,
    useGetActionCategoriesQuery,
    useGetActionTypesQuery,
    useResolveDefaultActionTimesQuery,
    useTicketListFilterDropdownDataQuery,
} from '@orthly/graphql-react';
import type { LabsGqlActionTypeWithCategory } from '@orthly/graphql-schema';
import type { ApolloError } from '@orthly/ui';
import React from 'react';

type ActionTypeInfo = Pick<LabsGqlActionTypeWithCategory, 'subcategory_name' | 'category_name' | 'name'>;

export type ChoiceData = {
    users: Map<string, { display: string; first_name: string; last_name: string }>;
    practices: Map<string, { display: string }>;
    manufacturers: Map<string, { display: string }>;
    actionTypes: Map<string, { display: string } & ActionTypeInfo>;
    tags: Map<string, { display: string }>;
};

export const useTicketFilterDropdownData = (): {
    data?: ChoiceData;
    loading: boolean;
    error?: ApolloError;
} => {
    const { data: rawData, loading, error } = useTicketListFilterDropdownDataQuery();
    const data = React.useMemo(() => {
        if (rawData) {
            const users: [string, { display: string; first_name: string; last_name: string }][] = rawData.listUsers.map(
                user => [user.id, { ...user, display: `${user.first_name} ${user.last_name} (${user.email})` }],
            );

            const actionTypes: [string, { display: string } & ActionTypeInfo][] =
                rawData.actionTypesWithCategoryInfo.map(actionType => [
                    actionType.id,
                    {
                        ...actionType,
                        display: `${actionType.category_name} > ${actionType.subcategory_name} > ${actionType.name}`,
                    },
                ]);
            const practices: [string, { display: string }][] = rawData.listOrganizations
                .filter(({ type }) => type === 'practice')
                .map(org => [org.id, { display: org.name }]);

            const manufacturers: [string, { display: string }][] = rawData.listOrganizations
                .filter(({ type }) => type === 'lab')
                .map(org => [org.id, { display: org.name }]);

            const tags: [string, { display: string }][] = rawData.tags.map(tag => [tag.id, { display: tag.name }]);

            return {
                actionTypes: new Map(actionTypes),
                manufacturers: new Map(manufacturers),
                practices: new Map(practices),
                tags: new Map(tags),
                users: new Map(users),
            };
        }
    }, [rawData]);

    return { data, loading, error };
};

export function useActionCategories(parent_id?: string) {
    const { data } = useGetActionCategoriesQuery();

    const categories = React.useMemo(
        () =>
            data?.getActionCategories
                .filter(category => category.parent_id === null && !category.is_archived)
                .map(category => ({
                    value: category.id,
                    label: category.name,
                })) ?? [],
        [data],
    );

    const subcategories = React.useMemo(
        () =>
            data?.getActionCategories
                .filter(category => category.parent_id && category.parent_id === parent_id && !category.is_archived)
                .map(category => ({
                    value: category.id,
                    label: category.name,
                })) ?? [],
        [data, parent_id],
    );

    const resolveCategoryDisplayName = (subcategory_id: string) => {
        const subcategory = data?.getActionCategories.find(category => category.id === subcategory_id);
        const category = data?.getActionCategories.find(category => category.id === subcategory?.parent_id);
        return category && subcategory ? `${category.name} > ${subcategory.name}` : null;
    };

    const resolveSubcategoryParentId = (subcategory_id: string) => {
        return data?.getActionCategories.find(category => category.id === subcategory_id)?.parent_id;
    };

    return { categories, subcategories, resolveSubcategoryParentId, resolveCategoryDisplayName };
}

export function useActionCapabilities() {
    const { data } = useGetActionCapabilitiesQuery();
    const capabilities = React.useMemo(() => data?.getActionCapabilities ?? [], [data]);
    const capabilityNamesById = React.useMemo(
        () => Object.fromEntries(capabilities.map(capability => [capability.id, capability.name])),
        [capabilities],
    );

    return { capabilityNamesById };
}

export function useActionTypes(category_id?: string) {
    const { data } = useGetActionTypesQuery();
    const { resolveCategoryDisplayName } = useActionCategories();
    const rawActionTypes = React.useMemo(() => data?.getActionTypes ?? [], [data]);
    const filteredActionTypes = React.useMemo(
        () => rawActionTypes.filter(actionType => category_id === undefined || actionType.category_id === category_id),
        [category_id, rawActionTypes],
    );
    const actionTypeSelectList = React.useMemo(
        () =>
            filteredActionTypes
                .filter(actionType => !actionType.is_archived)
                .map(actionType => ({
                    value: actionType.id,
                    label:
                        category_id === undefined
                            ? `${resolveCategoryDisplayName(actionType.category_id)} > ${actionType.name}`
                            : actionType.name,
                    subtitle: actionType.description ?? undefined,
                })),
        [category_id, resolveCategoryDisplayName, filteredActionTypes],
    );

    const actionTypesById = React.useMemo(
        () =>
            rawActionTypes.reduce<Record<string, LabsGqlActionTypeDtoFragment>>(
                (map, actionType) => ({ ...map, [actionType.id]: actionType }),
                {},
            ),
        [rawActionTypes],
    );

    const resolveActionTypeName = (type_id: string) => {
        return actionTypesById[type_id]?.name;
    };

    const resolveActionDisplayCategory = (type_id: string) => {
        const actionType = actionTypesById[type_id];
        return actionType ? resolveCategoryDisplayName(actionType.category_id) : undefined;
    };

    return { actionTypesById, actionTypeSelectList, resolveActionTypeName, resolveActionDisplayCategory };
}

export function useActionTypeDefaultDates(order_id: string, type_id: string) {
    const { data } = useResolveDefaultActionTimesQuery({
        variables: { order_id: order_id ?? '', type_id: type_id ?? '' },
        skip: !order_id || !type_id,
    });

    return React.useMemo(
        () => ({
            activate_at: data?.resolveDefaultActionTimes.activate_at
                ? new Date(data.resolveDefaultActionTimes.activate_at)
                : null,
            due_at: data?.resolveDefaultActionTimes.due_at ? new Date(data.resolveDefaultActionTimes.due_at) : null,
        }),
        [data],
    );
}
