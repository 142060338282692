import { DEFUALT_VALUE_TEXT } from '../../types';
import { Text, Grid, makeStyles } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = makeStyles({
    sectionContent: {
        marginTop: '0.01rem',
        marginBottom: '0.1rem',
        marginLeft: `0.3rem`,
        lineHeight: '0.5',
        '& > .MuiGrid-item': {
            paddingTop: '0.01px',
            paddingBottom: '0.01px',
        },
    },
    sectionTitle: {
        fontSize: '0.9rem !important',
        fontWeight: 'bold',
    },
    sectionValue: {
        fontSize: `0.9rem !important`,
        fontWeight: 'normal',
        marginTop: '0.01rem',
        marginBottom: '0.01rem',
        '& > .MuiGrid-item': {
            paddingTop: '0.01px',
            paddingBottom: '0.01px',
        },
    },
});

const formatFieldValue = (value?: string | number | boolean | null) => {
    const formattedValue = _.isNil(value) ? DEFUALT_VALUE_TEXT : value;
    if (formattedValue === true) {
        return 'Yes';
    }
    if (formattedValue === false) {
        return 'No';
    }
    return formattedValue;
};

interface PrintableSlipItemDetailProps {
    title: string;
    value?: string | number | boolean | null;
}

/**
 * Renders the information about a field on an order item
 * to be displayed on the Printable Slip
 * @param props The title of the field and value/nested rendering of the value
 * @returns Rendered order item details for Printable Slip
 */
export const PrintableSlipItemDetail: React.FC<PrintableSlipItemDetailProps> = props => {
    const { title, value, children } = props;
    const formattedValue = formatFieldValue(value);
    const classes = useStyles();
    return (
        <Grid container className={classes.sectionContent}>
            <Grid container>
                <Grid item xs={4}>
                    <Text variant={'h6'} className={classes.sectionTitle}>
                        {title}
                    </Text>
                </Grid>
                <Grid item xs={8}>
                    {children ? (
                        children
                    ) : (
                        <Text variant={'h6'} className={classes.sectionValue}>
                            {formattedValue}
                        </Text>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
