import { OrderDesignToolbarFileUpload } from '../../screens/Orders/screens/OrderDetail/components/OrderDesignToolbar/OrderDesignToolbarFileUpload';
import { OrderActiveTaskAction } from '../../screens/Orders/screens/OrderDetail/components/OrderDetailAlerts/OrderActiveTaskAction.graphql';
import { OrderDetailAlerts } from '../../screens/Orders/screens/OrderDetail/components/OrderDetailAlerts/OrderDetailAlerts';
import { usePartnerAndLabNames } from '../../utils/usePartnerAndLabNames';
import { DesignAnalyticsSection } from './DesignDetailSidebarAnalytics';
import { DesignItemsSection } from './DesignDetailSidebarItems';
import { DesignPreferencesSection } from './DesignDetailSidebarPreferences';
import { DesignSidebarSection } from './DesignDetailSidebarSection';
import { DesignDetailSidebarTags } from './DesignDetailSidebarTags';
import { DesignRefabNotesSection, DesignDoctorNotesSection } from './DesignNotesSection';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { SimpleCopyToClipboard } from '@orthly/dentin';
import type { FragmentType, VeneerOrderDesignAnalyticsDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import { getFragmentData } from '@orthly/graphql-inline-react';
import { OrderDesignPreviewDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { stylesFactory, FlossPalette, Grid, Skeleton, Text } from '@orthly/ui-primitives';
import { OrderListItemFlags, getOrderListItemFlagProps } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    sectionRoot: {
        padding: '8px 16px',
        width: '100%',
    },
    rootAlert: {
        padding: '20px 16px',
        backgroundColor: FlossPalette.ATTENTION_BG,
    },
}));

const DesignDetailHeader: React.FC<{ order?: LabsGqlOrder }> = props => {
    const { order } = props;
    const { labNamesById } = usePartnerAndLabNames();
    const { flags, review_flag_reasons } = getOrderListItemFlagProps(order);
    return (
        <DesignSidebarSection>
            {order && (
                <SimpleCopyToClipboard
                    onClick={() =>
                        BrowserAnalyticsClientFactory.Instance?.track('Order Number Copied', {
                            $groups: { order: order.id },
                        })
                    }
                    tooltip={'Copy order ID'}
                    size={'small'}
                    value={order.order_number}
                >{`Order ID: #${order.order_number}`}</SimpleCopyToClipboard>
            )}
            <Grid container>
                <Grid item xs={7}>
                    <Text variant={'h6'} style={{ lineHeight: 1 }}>
                        {!order && <Skeleton animation={'wave'} variant={'text'} style={{ width: 250 }} />}
                        {order?.patient.first_name} {order?.patient.last_name}
                    </Text>
                </Grid>
                {order && (
                    <Grid item container xs={5} justifyContent={'flex-end'} alignContent={'center'}>
                        <Text variant={'caption'} color={'DARK_GRAY'} style={{ fontWeight: 500 }}>
                            {labNamesById[order?.manufacturer_id]}
                        </Text>
                    </Grid>
                )}
            </Grid>
            <Grid container style={{ paddingTop: 8, flexWrap: 'nowrap' }}>
                {order && <OrderListItemFlags small flags={flags} review_flag_reasons={review_flag_reasons} />}{' '}
            </Grid>
        </DesignSidebarSection>
    );
};

interface DesignErrorSectionProps {
    designFragment: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    order?: LabsGqlOrder;
}

const DesignErrorSection: React.FC<DesignErrorSectionProps> = ({ designFragment }) => {
    const classes = useStyles();

    const design = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, designFragment);
    const errors = design?.validation_data?.conversion_errors;

    if (!errors || errors.length === 0) {
        return null;
    }
    return (
        <Grid container className={classes.rootAlert}>
            <Grid item>
                <Text variant={'h6'} style={{ lineHeight: 1, paddingBottom: 16 }}>
                    {"The design file couldn't be processed"}
                </Text>
            </Grid>
            {errors.map(error => (
                <Grid key={error} container style={{ paddingBottom: 16, flexWrap: 'nowrap' }}>
                    <Text variant={'body2'} style={{ lineHeight: 1 }}>
                        {error}
                    </Text>
                </Grid>
            ))}
            <OrderDesignToolbarFileUpload />
        </Grid>
    );
};

const DesignLabSlipAlertsSection: React.FC<{ order: LabsGqlOrder }> = props => {
    if (!props.order.lab_slip_alerts || props.order.lab_slip_alerts?.length === 0) {
        return null;
    }
    return (
        <DesignSidebarSection title={'Lab Slip Alerts'}>
            <Grid container style={{ marginTop: 4 }}>
                {props.order.lab_slip_alerts.map(alert => (
                    <Text key={alert.text} variant={'caption'} style={{ marginBottom: 8 }}>
                        {alert.text}
                    </Text>
                ))}
            </Grid>
        </DesignSidebarSection>
    );
};

export const DesignDetailSidebar: React.VFC<{
    order?: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
    designFragment?: FragmentType<typeof VeneerOrderDesignAnalyticsDesign_FragmentFragmentDoc> &
        FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
}> = props => {
    const { order, refetchOrder, designFragment } = props;

    if (!order) {
        return null;
    }
    return (
        <Grid container>
            <DesignDetailHeader order={order} />
            {designFragment && <DesignErrorSection order={order} designFragment={designFragment} />}
            <OrderDetailAlerts order={order} refetchOrder={refetchOrder} />
            <OrderActiveTaskAction
                order={order}
                refetchOrder={refetchOrder}
                variant={'sidebar'}
                designFragment={designFragment}
            />
            <DesignItemsSection order={order} />
            <DesignPreferencesSection order={order} />
            <DesignDetailSidebarTags orderId={order.id} />
            {designFragment && <DesignAnalyticsSection orderId={order.id} designFragment={designFragment} />}
            <DesignRefabNotesSection order={order} />
            <DesignDoctorNotesSection order={order} />
            <DesignLabSlipAlertsSection order={order} />
        </Grid>
    );
};
