import type { MainViewCameraControlsRef } from '../ModelViewer';
import type { ICameraControls } from '../ModelViewer/utils3d/TrackballControls/CameraControls.types';
import { isOrthographicCamera } from '@orthly/forceps';

/**
 * Implements several behaviors of review panel cameras:
 *
 * - When a review panel is first mounted, the camera is initialized to a default view.
 * - Preserves the camera pose associated with a review type while that review type does not have an associated canvas
 * - Preserves the canvas contents size with respect to the canvas size when the panel is expanded or minimized
 *
 * @param cameraControlsRef Ref in which we keep track of the camera controls associated with a review type
 * @param nextCameraControls The camera controls object attached to the canvas
 * @param setInitialCameraView Sets the initial camera view of a review panel
 */
export function transferCameraProperties(
    cameraControlsRef: MainViewCameraControlsRef,
    nextCameraControls: ICameraControls | null,
    setInitialCameraView: () => void,
    accountForTransition: boolean,
): void {
    if (!nextCameraControls) {
        // Do not clear the ref, even though the canvas has unmounted. We use the ref to store the camera view of a
        // review panel.
        return;
    }

    if (!cameraControlsRef.current) {
        // The review panel has just been mounted for the first time, i.e. the Dandy Finishing UI was just opened.

        cameraControlsRef.current = nextCameraControls;
        // `cameraControlsRef` must be populated before calling `setInitialCameraView` as that function relies on it.
        setInitialCameraView();

        // The ReviewPanels are currently mounted within a Popover, which has a transition effect. At the time that
        // `setInitialCameraView` is called above, the canvas has not yet reached its final size, which causes the
        // camera to be zoomed out more than if `setInitialCameraView` were called after the canvas has reached its
        // final size. To work around this, we simply zoom the camera in some more on initialization.
        if (accountForTransition) {
            cameraControlsRef.current.object.zoom *= 1.8;
            cameraControlsRef.current.object.updateProjectionMatrix();
        }

        return;
    }

    const prevCamera = cameraControlsRef.current.object;
    const nextCamera = nextCameraControls.object;
    if (!(isOrthographicCamera(prevCamera) && isOrthographicCamera(nextCamera))) {
        throw new Error('Expected orthographic cameras.');
    }

    if (prevCamera === nextCamera) {
        return;
    }

    // We use the previous camera to initialize the next camera, preserving the camera pose and potentially adjusting
    // the zoom level to account for the change in canvas size.

    nextCamera.position.copy(prevCamera.position);
    nextCamera.quaternion.copy(prevCamera.quaternion);
    nextCamera.up.copy(prevCamera.up);
    nextCamera.updateMatrixWorld(true);

    const widthRatio = (nextCamera.right - nextCamera.left) / (prevCamera.right - prevCamera.left);
    const heightRatio = (nextCamera.top - nextCamera.bottom) / (prevCamera.top - prevCamera.bottom);

    if (widthRatio > 1 && heightRatio > 1) {
        // The panel was expanded. Zoom in so that the contents of the canvas take up roughly the same percent area of
        // the canvas as before.
        const minRatio = Math.min(widthRatio, heightRatio);
        nextCamera.zoom = prevCamera.zoom * minRatio;
    } else if (widthRatio < 1 && heightRatio < 1) {
        // The panel was minimized. Zoom out so that the contents of the canvas take up roughly the same percent area of
        // the canvas as before.
        const maxRatio = Math.max(widthRatio, heightRatio);
        nextCamera.zoom = prevCamera.zoom * maxRatio;
    } else {
        nextCamera.zoom = prevCamera.zoom;
    }

    nextCamera.updateProjectionMatrix();

    nextCameraControls.target.copy(cameraControlsRef.current.target);
    nextCameraControls.update();

    cameraControlsRef.current = nextCameraControls;
}
