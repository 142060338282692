import type { SubmitterParams } from '../../utils';
import { handleSuccess } from '../../utils';
import { DesignRuleForm } from './DesignRuleForm';
import type { LabsGqlDesignRuleFragment } from '@orthly/graphql-operations';
import { useUpdateDesignRuleMutation } from '@orthly/graphql-react';
import type { LabsGqlUpdateDesignRuleCommand } from '@orthly/graphql-schema';
import { useChangeSubmissionFn } from '@orthly/ui';
import { Text, Dialog, DialogContent, DialogTitle } from '@orthly/ui-primitives';
import React from 'react';

function useUpdateDesignRuleSubmitter(params: SubmitterParams) {
    const [submitMtn] = useUpdateDesignRuleMutation();
    const mtnSubmitter = (data: LabsGqlUpdateDesignRuleCommand) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        successMessage: () => ['Design Rule updated!', {}],
        onSuccess: () => handleSuccess(params),
    });
    return { submit, submitting };
}

export const UpdateDesignRuleDialog: React.FC<{
    rule: LabsGqlDesignRuleFragment;
    open: boolean;
    close: () => void;
    refetch: () => Promise<any>;
}> = ({ refetch, close, open, rule }) => {
    const { submit, submitting } = useUpdateDesignRuleSubmitter({
        onComplete: async () => {
            await refetch();
            close();
        },
    });
    return (
        <Dialog open={open} onClose={() => close()} maxWidth={'md'} fullWidth PaperProps={{ style: { minWidth: 350 } }}>
            <DialogTitle>
                <Text variant={'h6'} component={'span'}>
                    Edit Design Rule
                </Text>
            </DialogTitle>
            <DialogContent style={{ padding: 20, borderTop: 'none' }} dividers>
                <DesignRuleForm
                    loading={submitting}
                    initialValues={{ ...rule, condition_type: rule.unit_limit !== null ? 'unit' : 'order' }}
                    onSubmit={values => {
                        const { id: rule_id } = rule;
                        void submit({ ...values, rule_id });
                    }}
                />
            </DialogContent>
        </Dialog>
    );
};
