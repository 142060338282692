import { useDisplayOptionsContext } from '../../contexts/TableOptionsContext';
import { useTableStateAction } from '../../state/action-hooks';
import type { Column, MUIDataObj, MUITableMetaRow } from '../../types/index';
import type { Theme } from '@orthly/ui-primitives';
import { TableCell, createStyles, makeStyles } from '@orthly/ui-primitives';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            root: {
                whiteSpace: 'pre',
            },
            cellHide: {
                display: 'none',
            },
            cellStacked: {
                [theme.breakpoints.down('md')]: {
                    display: 'inline-block',
                    backgroundColor: theme.palette.background.paper,
                    fontSize: '16px',
                    height: '24px',
                    width: 'calc(50% - 80px)',
                    whiteSpace: 'nowrap' as 'nowrap',
                },
            },
            responsiveStacked: {
                [theme.breakpoints.down('md')]: {
                    display: 'inline-block',
                    fontSize: '16px',
                    width: 'calc(50% - 80px)',
                    whiteSpace: 'nowrap' as 'nowrap',
                    height: '24px',
                },
            },
        }),
    { name: 'MUITableBodyCell' },
);

interface MUITableBodyCellProps<R extends MUIDataObj = MUIDataObj> {
    column: Column<R>;
    row: MUITableMetaRow<R>;
    rowIndex: number;
    colSpan: number;
    children?: React.ReactNode;
}

interface BodyCellBaseProps {
    colSpan: number;
    children: React.ReactNode;
    onClick?: () => void;
    style?: React.CSSProperties;
    dataColumnName?: string;
}

export const BodyCellBase: React.FC<BodyCellBaseProps> = props => {
    const classes = useStyles();
    const displayOpts = useDisplayOptionsContext();
    return (
        <TableCell
            onClick={props.onClick}
            colSpan={props.colSpan}
            className={classNames({
                [classes.root]: true,
                [classes.responsiveStacked]: displayOpts.responsive === 'stacked',
            })}
            style={props.style}
            data-column={props.dataColumnName}
        >
            {props.children}
        </TableCell>
    );
};

export const MUITableBodyCell = <R extends MUIDataObj = MUIDataObj>(props: MUITableBodyCellProps<R>) => {
    const { column, colSpan, row } = props;
    const onCellClick = useTableStateAction<'onCellClick'>('onCellClick');
    const handleClick = () => {
        !column.disableOnCellClick && onCellClick(row.row, column);
    };
    const { bodyCellWrapStyle } = column;
    const wrapStyle = React.useMemo<React.CSSProperties | undefined>(() => {
        return typeof bodyCellWrapStyle === 'function' ? bodyCellWrapStyle(row.row) : bodyCellWrapStyle;
    }, [bodyCellWrapStyle, row.row]);

    return (
        <BodyCellBase onClick={handleClick} colSpan={colSpan} dataColumnName={column.name} style={column.style ?? {}}>
            {wrapStyle ? (
                <span style={wrapStyle}>{row.cellForColumn(column).renderValue}</span>
            ) : (
                <>{row.cellForColumn(column).renderValue}</>
            )}
        </BodyCellBase>
    );
};
