import { RouterTabs } from '../../../components/RouterTabs';
import { OrganizationsTable } from '../OrganizationsTable';
import { PracticesTable } from '../PracticesTable';
import { OrganizationSettings } from './OrganizationSettings';
import { OrganizationUsersTable } from './OrganizationUsersTable';
import { useGetOrganizationWithDescendantsQuery } from '@orthly/graphql-react';
import { LoadBlocker } from '@orthly/ui';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';

export const OrganizationDetailsRoot: React.FC<RouteComponentProps<{ organizationId: string }>> = props => {
    const organizationId = props.match.params.organizationId;
    const { data, loading, refetch } = useGetOrganizationWithDescendantsQuery({ variables: { id: organizationId } });
    return (
        <LoadBlocker blocking={loading}>
            <RouterTabs
                defaultPath={'practices'}
                items={[
                    {
                        path: 'practices',
                        label: 'Practices',
                        Component: () => (
                            <PracticesTable
                                parentOrg={data?.getOrganizationWithDescendants}
                                refetchParentOrg={async () => {
                                    await refetch();
                                }}
                            />
                        ),
                    },
                    {
                        path: 'sub-organizations',
                        label: 'Sub-organizations',
                        Component: () => (
                            <OrganizationsTable
                                parentOrg={data?.getOrganizationWithDescendants}
                                refetchParentOrg={async () => {
                                    await refetch();
                                }}
                            />
                        ),
                    },
                    {
                        path: 'users',
                        label: 'Users',
                        Component: () => <OrganizationUsersTable organizationId={organizationId} />,
                    },
                    {
                        path: 'settings',
                        label: 'Settings',
                        Component: () => <OrganizationSettings organizationId={organizationId} />,
                    },
                ]}
            />
        </LoadBlocker>
    );
};
