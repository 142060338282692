import { useUpdateUserPasswordMutation } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import type { QuickFormCustomRenderProps, FieldsDefProp, FieldDefText } from '@orthly/ui';
import { LoginSubmitButton, LoadBlocker, QuickForm, useChangeSubmissionFn } from '@orthly/ui';
import { Text, Grid } from '@orthly/ui-primitives';
import React from 'react';
import { z } from 'zod';

type NewPasswordFields = { confirm_password: string; password: string };

const FormLayout: React.FC<QuickFormCustomRenderProps<NewPasswordFields>> = props => {
    const session = useSession();
    const password = props.formikProps.values.password || '';
    const passwordConfirmation = props.formikProps.values.confirm_password || '';
    const confirmationError = props.formikProps.errors.confirm_password;
    React.useEffect(() => {
        if (
            password?.length > 0 &&
            passwordConfirmation?.length > 0 &&
            password !== passwordConfirmation &&
            !confirmationError
        ) {
            props.formikProps.setFieldError('confirm_password', 'Must match password');
            return;
        }
        if (confirmationError && password === passwordConfirmation) {
            props.formikProps.setFieldError('confirm_password', '');
        }
    }, [confirmationError, password, passwordConfirmation, props.formikProps]);

    const submitDisabled = password !== passwordConfirmation || props.submitting || !props.formikProps.isValid;
    return (
        <form
            onSubmit={event => {
                event.preventDefault();
                props.triggerSubmit();
            }}
            style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', width: '100%' }}
        >
            {/* this is recommended by google, see https://goo.gl/9p2vKq */}
            <input
                readOnly
                style={{ display: 'none' }}
                hidden
                value={session?.user.email ?? ''}
                type={'email'}
                name={'username'}
                autoComplete={'username'}
            />
            <LoadBlocker blocking={props.submitting}>{props.fieldList}</LoadBlocker>
            <LoginSubmitButton text={'Change password'} disabled={submitDisabled} fullWidth />
        </form>
    );
};

const passwordFieldBase: FieldDefText = {
    label: 'New Password',
    fieldProps: {
        variant: 'standard',
        style: { marginBottom: 24, position: 'relative' },
        FormHelperTextProps: { style: { position: 'absolute' } },
        type: 'password',
        autoComplete: 'new-password',
    },
    type: 'text',
    validation: z.string({ invalid_type_error: 'Must be text' }).min(7),
};

const formFields: FieldsDefProp<NewPasswordFields> = {
    password: passwordFieldBase,
    confirm_password: { ...passwordFieldBase, label: 'Confirm New Password' },
};

interface SetNewPasswordProps {
    onPasswordReset: () => void;
}

export const SetNewPassword: React.VFC<SetNewPasswordProps> = ({ onPasswordReset }) => {
    // the reset link uses an impersonation token to pre-auth the user
    const user_id = useSession()?.user_id;
    const [submitMtn] = useUpdateUserPasswordMutation();
    const onSubmit = React.useCallback(
        (password_raw: string) => {
            if (!user_id) {
                throw new Error('Reset link invalid');
            }
            return submitMtn({ variables: { data: { user_id, password_raw } } });
        },
        [user_id, submitMtn],
    );

    const { submit: setNewPassword, submitting } = useChangeSubmissionFn<any, [string]>(onSubmit, {
        successMessage: () => ['Successfully reset password!', {}],
        onSuccess: onPasswordReset,
    });

    return (
        <>
            <Grid container>
                <Text variant={'h6'} color={'BLACK'}>
                    Create new password
                </Text>
                <ul style={{ paddingInlineStart: '20px', margin: 0, paddingTop: 20 }}>
                    <li style={{ marginBottom: 5 }}>Must contain at least 7 characters</li>
                    <li style={{ marginBottom: 5 }}>Does not match or significantly contain your username</li>
                </ul>
            </Grid>
            <QuickForm<NewPasswordFields>
                fields={formFields}
                disabled={submitting}
                onSubmit={fields => setNewPassword(fields.password)}
                initialValues={{ password: '', confirm_password: '' }}
                CustomLayout={FormLayout}
            />
        </>
    );
};
