import type { ScanReviewMarginMarkingToolController } from './ScanReviewMarginMarkingToolController';
import { useThree } from '@react-three/fiber';
import React from 'react';

export const ScanReviewMarginMarkingTool: React.VFC<{
    toolController: ScanReviewMarginMarkingToolController;
}> = ({ toolController }) => {
    const { gl } = useThree();
    const canvas = gl.domElement;

    React.useEffect(() => {
        const handlePointerDown = toolController.handlePointerDown.bind(toolController);
        const handlePointerMove = toolController.handlePointerMove.bind(toolController);
        const handlePointerUp = toolController.handlePointerUp.bind(toolController);
        const handleKeyDown = toolController.handleKeyDown.bind(toolController);
        const handleKeyUp = toolController.handleKeyUp.bind(toolController);

        canvas?.addEventListener('pointerdown', handlePointerDown);
        canvas?.addEventListener('pointermove', handlePointerMove);
        canvas?.addEventListener('pointerup', handlePointerUp);
        canvas?.addEventListener('pointerleave', handlePointerUp);
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            canvas?.removeEventListener('pointerdown', handlePointerDown);
            canvas?.removeEventListener('pointermove', handlePointerMove);
            canvas?.removeEventListener('pointerup', handlePointerUp);
            canvas?.removeEventListener('pointerleave', handlePointerUp);
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [canvas, toolController]);

    return <primitive object={toolController.group} />;
};
