import { useGuidedWaxupContext } from '../GuidedWaxups/state/GuidedWaxupContext';
import type { QcHeatmapRange } from '@orthly/dentin';
import { QCColorLegend } from '@orthly/dentin';
import { FlossPalette, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

export const useQCColorLegend = () => {
    const { setBothAppearances } = useGuidedWaxupContext();

    const onChange = React.useCallback(
        (heatMapRange: QcHeatmapRange) => {
            setBothAppearances(current => ({ ...current, heatMapRange }));
        },
        [setBothAppearances],
    );

    return { onChange };
};

export const QCColorLegendContainer: React.VFC<{ isHeatmapView: boolean }> = ({ isHeatmapView }) => {
    const isMobileOrTablet = useScreenIsMobileOrVerticalTablet();
    const { primaryAppearance } = useGuidedWaxupContext();
    const { onChange } = useQCColorLegend();

    if (!isMobileOrTablet || !isHeatmapView) {
        return null;
    }

    return (
        <QCColorLegend
            dynamicHeatmaps
            heatMapType={primaryAppearance.activeHeatMap}
            heatMapRange={primaryAppearance.heatMapRange}
            horizontal
            setHeatMapRange={onChange}
            hideZoom
            sx={{
                boxShadow: 'none',
                backgroundColor: FlossPalette.TAN,
                border: `1px solid ${FlossPalette.DARK_TAN}`,
                margin: 0,
            }}
        />
    );
};
