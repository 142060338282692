import React from 'react';

/**
 * Return functions to manage boolean state around displaying a printable item (lab slip, pan label, etc)
 * @returns Printable open state, and functions to open/close
 */
export function usePrintableState() {
    const [printableIsOpen, setPrintableIsOpen] = React.useState<boolean>(false);
    return {
        printableIsOpen: printableIsOpen,
        openPrintable: () => setPrintableIsOpen(true),
        closePrintable: () => setPrintableIsOpen(false),
    };
}
