import type { CanvasObject } from '../Objects/CanvasObject';
import type { LineParams } from '../Objects/LineObject';
import { LineObject } from '../Objects/LineObject';
import type { Point } from '../Util';
import { pointEquals } from '../Util';
import { CreationTool } from './CreationTool';

export class LineCreationTool extends CreationTool {
    private params: LineParams;

    constructor(params: LineParams) {
        super('crosshair');
        this.params = params;
    }

    objectForPoints(start: Point, end: Point): CanvasObject | null {
        if (pointEquals(start, end)) {
            return null;
        }
        return new LineObject(this.params, start, end);
    }
}
