import { AnalyticsClient } from '../../../../../../utils/analyticsClient';
import { OrderDetailSummaryImpersonationButton } from './OrderDetailSummaryImpersonationButton';
import { SimpleCopyToClipboard } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { useHasCapability } from '@orthly/session-client';
import { OrderDetailBlock } from '@orthly/veneer';
import React from 'react';

const AdminOrderDetailSummaryBlockLabSalesOrder_Fragment = graphql(`
    fragment AdminOrderDetailSummaryBlockLabSalesOrder_Fragment on LabSalesOrderDTO {
        id
        order_number
        practice_id
    }
`);

interface OrderDetailSummaryBlockProps {
    salesOrder: FragmentType<typeof AdminOrderDetailSummaryBlockLabSalesOrder_Fragment>;
}

export const OrderDetailSummaryBlock: React.FC<OrderDetailSummaryBlockProps> = ({
    children,
    salesOrder: salesOrderProp,
}) => {
    const salesOrder = getFragmentData(AdminOrderDetailSummaryBlockLabSalesOrder_Fragment, salesOrderProp);

    const canImpersonate = useHasCapability('user', 'user.impersonate');

    return (
        <OrderDetailBlock
            title={
                <SimpleCopyToClipboard
                    onClick={() =>
                        AnalyticsClient.track('Order Number Copied', {
                            $groups: { order: salesOrder.id },
                        })
                    }
                    tooltip={'Copy order ID'}
                    value={salesOrder.order_number}
                >{`Order ID: #${salesOrder.order_number}`}</SimpleCopyToClipboard>
            }
            variant={'full'}
            actions={
                canImpersonate ? (
                    <OrderDetailSummaryImpersonationButton
                        orderId={salesOrder.id}
                        practiceId={salesOrder.practice_id}
                    />
                ) : undefined
            }
        >
            {children}
        </OrderDetailBlock>
    );
};
