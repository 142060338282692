import { VisibilityToggleIcon } from '../GuidedWaxup.util';
import { GUIDED_WAXUP_COMMON_STYLES } from '../VisualConstants.util';
import { FlossPalette, Grid, Icon, Text, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    modelVisibility: {
        padding: '15px 15px 15px 30px',
        ...GUIDED_WAXUP_COMMON_STYLES,
        borderRight: 'none',
        borderTop: 'none',
        cursor: 'pointer',
    },
    iconTextContainer: {
        display: 'inline-flex',
    },
    toggleText: {
        marginLeft: 10,
    },
    desktopBodyContainer: {
        justifyContent: 'space-between',
    },
    eyeIconOn: {
        color: FlossPalette.PRIMARY_FOREGROUND,
    },
    eyeIconOff: {
        color: FlossPalette.GRAY,
    },
}));

const EyeIcon: React.VFC<{ toggled: boolean }> = ({ toggled }) => {
    const classes = useStyles();
    return toggled ? (
        <Icon icon={'VisibilityOutlinedIcon'} className={classes.eyeIconOn} />
    ) : (
        <Icon icon={'VisibilityOffOutlinedIcon'} className={classes.eyeIconOff} />
    );
};

export const VisibilityToggleDesktop: React.VFC<{
    toggleLabel: string;
    toggleAction: (checked: boolean) => void;
    toggled: boolean;
}> = ({ toggleLabel, toggleAction, toggled }) => {
    const classes = useStyles();
    return (
        <Grid
            role={'button'}
            aria-pressed={toggled}
            item
            className={classes.modelVisibility}
            onClick={() => {
                toggleAction(!toggled);
            }}
        >
            <Grid item container className={classes.desktopBodyContainer}>
                <Grid item className={classes.iconTextContainer}>
                    <VisibilityToggleIcon toggleName={toggleLabel} toggled={toggled} />
                    <Text
                        variant={'body2'}
                        medium
                        color={toggled ? 'PRIMARY_FOREGROUND' : 'GRAY'}
                        className={classes.toggleText}
                    >
                        {toggleLabel}
                    </Text>
                </Grid>
                <Grid item>
                    <EyeIcon toggled={toggled} />
                </Grid>
            </Grid>
        </Grid>
    );
};
