import { getAdminReduxMiddleware } from '../../../../../../../redux/admin-analytics.middleware';
import { splitsReducer } from './Splits.state';
import type { SplitManagerState } from './Splits.types';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

const isDev = process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGING === 'true';
const composeEnhancers =
    (isDev &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: false,
            traceLimit: 5,
            name: 'Splits',
        })) ||
    compose;

export const SplitsStore = createStore<SplitManagerState, any, any, any>(
    splitsReducer,
    { open: false },
    composeEnhancers(applyMiddleware(...getAdminReduxMiddleware([thunk]))),
);
