import { useAdminUiAction } from '../../../../../../redux/ui/ui.actions';
import { AnalyticsClient } from '../../../../../../utils/analyticsClient';
import { SubmitOrderScanReview } from '../actions/SubmitOrderScanReview';
import { DesignFileValidationErrorsCard } from './DesignFileValidationOverrides';
import { DoctorDesignTaskAlerts } from './DoctorDesignTaskAlerts';
import type {
    FragmentType,
    OrderDesignPreviewDesign_FragmentFragmentDoc,
    VeneerOrderDesignAnalyticsDesign_FragmentFragmentDoc,
} from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useConfigureDesignReviewMutation } from '@orthly/graphql-react';
import type { ActionCardButtonPropsBase } from '@orthly/ui';
import { WarningIcon, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, Text } from '@orthly/ui-primitives';
import type { CondensedActionCardVariant } from '@orthly/veneer';
import { CondensedActionCard, CompleteDesignReviewTaskModal } from '@orthly/veneer';
import React from 'react';

interface OrderDesignReviewTaskAlertProps {
    order: LabsGqlOrder;
    refetchOrder: () => Promise<any>;
    variant: CondensedActionCardVariant;
    // If provided null, will fetch the latest design for the order.
    // If provided undefined or a design fragment, will utilize what was provided.
    designFragment?:
        | (FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc> &
              FragmentType<typeof VeneerOrderDesignAnalyticsDesign_FragmentFragmentDoc>)
        | null;
}

function useSkipReview(orderId: string, onSuccess: () => void) {
    const [submitMtn] = useConfigureDesignReviewMutation({
        variables: { order_id: orderId, design_review_required: false, external_design_review_blocking: false },
    });
    const { submit, submitting } = useChangeSubmissionFn(() => submitMtn(), {
        successMessage: () => [`Design review skipped`, {}],
        onSuccess,
    });
    return { submit, submitting };
}

export const OrderDesignReviewTaskAlert: React.VFC<OrderDesignReviewTaskAlertProps> = props => {
    const { order, refetchOrder } = props;

    const [scanReviewOpen, setScanReviewOpen] = React.useState<boolean>(false);
    const [designReviewTaskOpen, setDesignReviewTaskOpenInner] = React.useState<boolean>(false);
    const setSidebarOpen = useAdminUiAction('SET_SIDEBAR_OPEN');

    const { submit: skipReview, submitting: skipReviewSubmitting } = useSkipReview(order.id, () => {
        void refetchOrder();
    });

    const setDesignReviewTaskOpen = React.useCallback(
        (open: boolean) => {
            // Visually, this modal by itself cover the design portal's controls (on the left-hand
            // side of the screen). We prevent this behavior by opening the portal sidebar
            // with `setSidebarOpen` when the modal is opened, which will then both shrink
            // the design portal to the right and also make the tab controls available to the user.
            if (open) {
                setSidebarOpen(open);
            }

            setDesignReviewTaskOpenInner(open);
        },
        [setSidebarOpen, setDesignReviewTaskOpenInner],
    );

    const isSidebarElement = props.variant === 'sidebar';
    const primaryAction = React.useMemo<ActionCardButtonPropsBase>(() => {
        return {
            endIcon: 'CheckIcon',
            text: isSidebarElement ? 'Review' : 'Review design',
            onClick: () => {
                AnalyticsClient.track(`Button Clicked`, {
                    AssetType: 'button',
                    AssetName: 'Design QC Review',
                    AssetVersion: '1.0',
                    AssetCTAText: isSidebarElement ? 'Review' : 'Review design',
                });
                setDesignReviewTaskOpen(true);
            },
        };
    }, [isSidebarElement, setDesignReviewTaskOpen]);

    const secondaryAction = {
        text: 'Skip design review',
        onClick: () => {
            if (window.confirm(`Skip design review?`)) {
                void skipReview();
            }
        },
        disabled: skipReviewSubmitting,
    };

    const task = order.fulfillment_workflow.active_task;
    if (task?.__typename !== 'DesignReviewWorkflowTask') {
        return null;
    }
    const { assignee } = task;
    const assignedUser = assignee
        ? `${assignee.assigned_user.first_name} ${assignee.assigned_user.last_name}`
        : 'Unassigned';
    const internalDesignNotes = task.configuration.internal_design_notes;
    const designFileValidationErrors = order.latest_design_file_validation_errors;

    return (
        <>
            <CondensedActionCard
                title={task.type === 'DesignReview' ? 'Design Review' : 'Double QC'}
                assignee={assignedUser}
                primaryAction={primaryAction}
                secondaryAction={secondaryAction}
                IconComponent={WarningIcon}
                refetch={refetchOrder}
                variant={props.variant}
                task={task}
                subtitle={
                    <>
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            {`Design completed by ${
                                order.fulfillment_workflow.configuration.internal_design_required ? 'internal' : 'lab'
                            } designer`}
                        </Text>
                        {internalDesignNotes && (
                            <Text variant={'body2'} color={'DARK_GRAY'}>
                                Internal design notes:{' '}
                                <span style={{ color: FlossPalette.BLACK }}>{internalDesignNotes}</span>
                            </Text>
                        )}
                        {designFileValidationErrors?.length > 0 && (
                            <DesignFileValidationErrorsCard validationErrors={designFileValidationErrors} />
                        )}
                        <DoctorDesignTaskAlerts lab_slip_alerts={order.lab_slip_alerts} />
                    </>
                }
            />
            {designReviewTaskOpen && (
                <CompleteDesignReviewTaskModal
                    order={props.order}
                    refetchOrder={props.refetchOrder}
                    setOpen={setDesignReviewTaskOpen}
                    open={designReviewTaskOpen}
                />
            )}
            <SubmitOrderScanReview
                initialResponse={'flag'}
                open={scanReviewOpen}
                reasons={[]}
                setOpen={setScanReviewOpen}
                {...props}
            />
        </>
    );
};
