import type { IssueCreditOrRefundButtonProps } from './CreditOrRefund.types';
import { CreditOrRefundWrapper } from './CreditOrRefundWrapper';
import { useHasCapability } from '@orthly/session-client';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

export const IssueCreditOrRefundButton: React.VFC<IssueCreditOrRefundButtonProps> = ({
    organizationId,
    order,
    invoiceItem,
    startIcon,
    existingCreditId,
    refetchItems,
    refetchCredits,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const canIssueCredits = useHasCapability('billing', 'billing.issue_credits');

    if (!canIssueCredits) {
        return null;
    }

    return (
        <>
            <CreditOrRefundWrapper
                open={open}
                setOpen={setOpen}
                organizationId={organizationId}
                order={order}
                invoiceItem={invoiceItem}
                existingCreditId={existingCreditId}
                refetchItems={refetchItems}
                refetchCredits={refetchCredits}
            />
            <Button onClick={() => setOpen(true)} variant={'ghost'} startIcon={startIcon}>
                {existingCreditId ? 'View Credit' : 'Issue Credit'}
            </Button>
        </>
    );
};
