import { useCrossSectionState, useCrossSectionApp } from '../CrossSection';
import type { MainViewCameraControlsRef } from '../ModelViewer';
import { useHeatmapHighlight } from '../ModelViewer/HeatmapHighlight.hooks';
import type { ICameraControls } from '../ModelViewer/utils3d/TrackballControls/CameraControls.types';
import type { CaseMetadata } from './CaseMetadata';
import { MergedProximalSceneAppearanceManager } from './MergedProximalSceneAppearanceManager';
import type { IOperationsManager } from './OperationsManager.types';
import type { IProximalSceneAppearanceManager } from './ProximalSceneAppearanceManager';
import type { ReviewApp, ReviewType } from './Review.types';
import { useModelAppearance } from './ReviewAdapter.hooks';
import { transferCameraProperties } from './ReviewCamera.utils';
import { useCanvasChildren, createCanvasSiblings } from './ReviewCanvasChildren.hooks';
import type {
    ISceneAppearanceManager,
    PartialSceneAppearance,
    PartialSceneAppearanceState,
    IPartialSceneAppearanceManager,
} from './SceneAppearanceManager.types';
import { ViewManager } from './ViewManager';
import React from 'react';

interface ReviewAppOptions {
    // If true, attempt to account for the transition effect of the parent component when initializing the camera(s)
    accountForTransition: boolean;
}

/**
 * Encapsulates the state of each of the four review panels
 */
export function useReviewApps(
    caseMetadata: CaseMetadata,
    sceneManager: ISceneAppearanceManager,
    opManager: IOperationsManager,
    options: ReviewAppOptions,
): Record<ReviewType, ReviewApp> {
    const occlusionAppearanceState = useSceneAppearanceState('occlusion', caseMetadata, sceneManager);
    const insertionAppearanceState = useSceneAppearanceState('insertion', caseMetadata, sceneManager);
    const mesialAppearanceState = useSceneAppearanceState('mesial', caseMetadata, sceneManager);
    const distalAppearanceState = useSceneAppearanceState('distal', caseMetadata, sceneManager);

    // Insert merged scene appearance managers into the proximal review panels in order to synchronize the appearances
    // of those two scenes.

    const baseMesialSceneManager = mesialAppearanceState.manager as IProximalSceneAppearanceManager;
    const baseDistalSceneManager = distalAppearanceState.manager as IProximalSceneAppearanceManager;

    mesialAppearanceState.manager = React.useMemo(() => {
        return new MergedProximalSceneAppearanceManager(baseMesialSceneManager, baseDistalSceneManager);
    }, [baseMesialSceneManager, baseDistalSceneManager]);

    distalAppearanceState.manager = React.useMemo(() => {
        return new MergedProximalSceneAppearanceManager(baseDistalSceneManager, baseMesialSceneManager);
    }, [baseDistalSceneManager, baseMesialSceneManager]);

    return {
        occlusion: useReviewApp('occlusion', occlusionAppearanceState, opManager, options),
        insertion: useReviewApp('insertion', insertionAppearanceState, opManager, options),
        mesial: useReviewApp('mesial', mesialAppearanceState, opManager, options),
        distal: useReviewApp('distal', distalAppearanceState, opManager, options),
    };
}

function useSceneAppearanceState(
    type: ReviewType,
    caseMetadata: CaseMetadata,
    sceneManager: ISceneAppearanceManager,
): PartialSceneAppearanceState {
    const [sceneAppearance, setSceneAppearance] = React.useState<PartialSceneAppearance>({
        collisionsVisible: false,
        collisionsCurtainsVisible: false,
        curtainsVisible: false,
        insertionPathsVisible: false,
        thicknessHeatmapEnabled: false,
        occlusalHeatmapEnabled: false,
        proximalHeatmapEnabled: false,
        undercutHeatmapEnabled: false,
        scanUndercutEnabled: false,
        heatmapRange: {
            min: 0,
            max: 1,
        },
        prePrepScansVisible: false,
        antagonistScansVisible: false,
        restorativesVisible: false,
    });

    const partialSceneManager = React.useMemo<IPartialSceneAppearanceManager>(() => {
        switch (type) {
            case 'occlusion':
                return sceneManager.createOcclusalSceneManager(setSceneAppearance, caseMetadata.restorationJaw);
            case 'insertion':
                return sceneManager.createInsertionSceneManager(setSceneAppearance);
            default:
                return sceneManager.createProximalSceneManager(setSceneAppearance);
        }
    }, [type, sceneManager, caseMetadata]);

    return {
        appearance: sceneAppearance,
        manager: partialSceneManager,
    };
}

// Encapsulates the state of a review panel
function useReviewApp(
    type: ReviewType,
    sceneAppearanceState: PartialSceneAppearanceState,
    opManager: IOperationsManager,
    options: ReviewAppOptions,
): ReviewApp {
    const { appearance: sceneAppearance, manager: sceneManager } = sceneAppearanceState;
    const { accountForTransition } = options;

    const cameraControlsRef: MainViewCameraControlsRef = React.useRef(null);

    const viewManager = React.useMemo(
        () => new ViewManager(cameraControlsRef, opManager, sceneManager),
        [cameraControlsRef, opManager, sceneManager],
    );

    const resetCamera = React.useCallback(() => {
        switch (type) {
            case 'occlusion':
                viewManager.setFacialCameraView();
                break;
            case 'insertion':
                viewManager.setInsertionCameraView();
                break;
            case 'mesial':
                viewManager.setMesialCameraView();
                break;
            case 'distal':
                viewManager.setDistalCameraView();
                break;
        }
    }, [viewManager, type]);

    const initCameraControls = React.useCallback(
        (cameraControls: ICameraControls | null) => {
            transferCameraProperties(cameraControlsRef, cameraControls, resetCamera, accountForTransition);
        },
        [resetCamera, accountForTransition],
    );

    const { updateTextRef, setHeatmapHighlight } = useHeatmapHighlight();

    // NB: `modelAppearance` is generated in order to interface with the cross section tool. Do not use it as inputs for
    // newly written Finishing-specific components. Instead, use the variables of Finishing-specific types.
    const modelAppearance = useModelAppearance(opManager.editToothNumber, sceneManager, sceneAppearance);

    const crossSectionState = useCrossSectionState();
    const crossSectionApp = useCrossSectionApp(cameraControlsRef, crossSectionState);

    const canvasChildren = useCanvasChildren(
        cameraControlsRef,
        crossSectionState,
        crossSectionApp,
        modelAppearance,
        setHeatmapHighlight,
        sceneAppearance,
        opManager,
        sceneManager,
    );
    const canvasSiblings = createCanvasSiblings(
        crossSectionState,
        crossSectionApp,
        sceneAppearance,
        sceneManager,
        updateTextRef,
    );

    return {
        initCameraControls,
        resetCamera,
        sceneState: sceneAppearanceState,
        viewManager,
        crossSection: crossSectionState,
        canvasChildren,
        canvasSiblings,
    };
}
