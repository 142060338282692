import {
    isGuidedPreset,
    useNextTabNavigation,
    usePrevTabNavigation,
    useReviewHasStructuredNotes,
    useShouldDisableTabs,
} from '../GuidedWaxup.util';
import { GUIDED_WAXUP_MOBILE_NAV_HEIGHT, HIDE_POPUP_MODAL_KEY, NAV_BUTTON_STYLES } from '../VisualConstants.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction, useGuidedWaxupSelector, useWaxupIsRejected } from '../state/GuidedWaxupState';
import { GuidedWaxupScheduleLiveCall } from './LiveDesignReview/GuidedWaxupScheduleLiveCall.graphql';
import { UnfinishedPresetsMessage } from './UnfinishedPresetsMessage';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import {
    Box,
    Button,
    FlossPalette,
    Grid,
    Text,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    styled,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    navAreaDesktop: {
        marginBottom: 20,
        paddingLeft: 24,
    },
    navAreaMobile: {
        display: 'flex',
        width: '100%',
        height: GUIDED_WAXUP_MOBILE_NAV_HEIGHT,
        borderTop: `2px solid ${FlossPalette.STROKE_LIGHT}`,
        backgroundColor: FlossPalette.WHITE,
        // setting position and zIndex so elements are hidden behind nav area when scrolling
        position: 'fixed',
        zIndex: 1,
        alignItems: 'center',
        justifyContent: 'space-evenly',
        // using dynamic viewport units to account for address bar on mobile browsers
        top: `calc(100dvh - ${GUIDED_WAXUP_MOBILE_NAV_HEIGHT}px)`,
        padding: '15px',
        gap: '15px',
    },
}));

const GuidedWaxupLiveReviewButtonWrapper = styled('div')({
    marginLeft: 'auto',
});

const trackNavButtonClick = (buttonText: string) => {
    BrowserAnalyticsClientFactory.Instance?.track('Button Clicked', {
        AssetName: `Guided Waxup Navigation Button - ${buttonText}`,
        AssetType: 'button',
        AssetVersion: '',
        AssetCTAText: buttonText,
    });
};

interface NavAreaProps {
    submit?: () => Promise<unknown>;
}

export const GuidedWaxupNavArea: React.VFC<NavAreaProps> = ({ submit }) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const {
        enableStructuredDesignReviewNotes,
        onBack,
        selectedTab,
        setSelectedTab,
        selectedDesignRevisionAlreadyReviewed,
        setIsSkipToGeneralViewModalOpen,
        setIsMobileRejectionNoteOpen,
        revisionId,
        internalEvaluation,
        unfinishedPresets,
        selectedDesignRevisionId,
        order,
    } = useGuidedWaxupContext();
    const skipToGeneralView = useGuidedWaxupAction('SKIP_TO_GENERAL_VIEW');
    const waxupRejected = useWaxupIsRejected();
    const navigateToNextTab = useNextTabNavigation();
    const navigateToPrevTab = usePrevTabNavigation();
    const guidedPreset = isGuidedPreset(selectedTab);
    const disableNav = useShouldDisableTabs();
    const buttonIsSkipToSubmit = guidedPreset && !selectedDesignRevisionAlreadyReviewed;
    const isStructuredNotesNavButton = enableStructuredDesignReviewNotes && guidedPreset;
    const showPrimaryButton = !selectedDesignRevisionAlreadyReviewed || guidedPreset;
    const showSecondaryButton = buttonIsSkipToSubmit || !guidedPreset;
    const presets = useGuidedWaxupSelector(s => s.presets);
    const disableSubmit = !guidedPreset && unfinishedPresets.length > 0;
    const reviewHasStructuredNotes = useReviewHasStructuredNotes();

    const primaryBtnText = React.useMemo(() => {
        if (guidedPreset) {
            return 'Continue';
        }

        const buttonText = waxupRejected ? 'Reject Design' : 'Approve Design';
        return internalEvaluation ? `${buttonText} (Not recommended. Internal Evaluation)` : buttonText;
    }, [guidedPreset, internalEvaluation, waxupRejected]);

    const isFirstTab = React.useMemo(() => selectedTab === Object.keys(presets)[0], [presets, selectedTab]);

    const secondaryBtnText = React.useMemo(() => {
        if (isStructuredNotesNavButton) {
            return isFirstTab ? 'Cancel' : 'Previous';
        }

        if (buttonIsSkipToSubmit) {
            return 'Skip to Submit Review';
        }

        if (selectedDesignRevisionAlreadyReviewed && reviewHasStructuredNotes) {
            return 'View Rejection Note';
        }

        if (enableStructuredDesignReviewNotes) {
            return waxupRejected ? 'Modify Rejection Note' : 'Add Rejection Note';
        }

        const generalRejectionNote = presets[selectedTab]?.notes?.trim();
        return selectedDesignRevisionAlreadyReviewed || revisionId !== undefined || generalRejectionNote
            ? 'View Rejection Note'
            : 'Add Rejection Note';
    }, [
        buttonIsSkipToSubmit,
        isFirstTab,
        isStructuredNotesNavButton,
        selectedDesignRevisionAlreadyReviewed,
        reviewHasStructuredNotes,
        enableStructuredDesignReviewNotes,
        revisionId,
        presets,
        selectedTab,
        waxupRejected,
    ]);

    React.useEffect(() => {
        if (internalEvaluation) {
            skipToGeneralView({});
            setSelectedTab(LabsGqlGuidedWaxupPresetType.GeneralView);
        }
    }, [internalEvaluation, setSelectedTab, skipToGeneralView]);

    const navigateOrSubmitClick = () => {
        trackNavButtonClick(primaryBtnText);
        if (!guidedPreset) {
            void submit?.();
        } else {
            navigateToNextTab();
        }
    };

    const handleSecondaryClick = React.useCallback(() => {
        if (isStructuredNotesNavButton) {
            trackNavButtonClick(secondaryBtnText);
            if (isFirstTab) {
                onBack();
            } else {
                navigateToPrevTab();
            }
        } else if (buttonIsSkipToSubmit) {
            trackNavButtonClick('Skip to Submit Review');
            if (localStorage.getItem(HIDE_POPUP_MODAL_KEY) === 'true') {
                skipToGeneralView({});
                setSelectedTab(LabsGqlGuidedWaxupPresetType.GeneralView);
            } else {
                setIsSkipToGeneralViewModalOpen(true);
            }
        } else if (isMobile) {
            setIsMobileRejectionNoteOpen(true);
        }
    }, [
        buttonIsSkipToSubmit,
        isFirstTab,
        isMobile,
        isStructuredNotesNavButton,
        navigateToPrevTab,
        onBack,
        secondaryBtnText,
        setIsMobileRejectionNoteOpen,
        setIsSkipToGeneralViewModalOpen,
        setSelectedTab,
        skipToGeneralView,
    ]);

    if (isMobile) {
        return (
            <Box className={classes.navAreaMobile}>
                {showSecondaryButton && (
                    <Button
                        variant={
                            buttonIsSkipToSubmit || isStructuredNotesNavButton ? 'secondary-gray' : 'alert-secondary'
                        }
                        style={NAV_BUTTON_STYLES}
                        disabled={disableNav && !(enableStructuredDesignReviewNotes && isFirstTab)}
                        onClick={handleSecondaryClick}
                    >
                        {secondaryBtnText}
                    </Button>
                )}
                {showPrimaryButton && (
                    <Button
                        variant={'primary'}
                        style={NAV_BUTTON_STYLES}
                        disabled={disableNav || disableSubmit}
                        onClick={navigateOrSubmitClick}
                    >
                        {primaryBtnText}
                    </Button>
                )}
            </Box>
        );
    }

    return (
        <Grid item container direction={'column'}>
            {disableNav && !enableStructuredDesignReviewNotes && (
                <Grid item className={classes.navAreaDesktop} sx={{ paddingLeft: '10px' }}>
                    <Text variant={'body2'}>One or more required field(s) are missing</Text>
                </Grid>
            )}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                }}
                className={classes.navAreaDesktop}
            >
                {(isStructuredNotesNavButton || buttonIsSkipToSubmit) && (
                    <Button
                        variant={'secondary-gray'}
                        disabled={disableNav && !(enableStructuredDesignReviewNotes && isFirstTab)}
                        onClick={handleSecondaryClick}
                        sx={{ minWidth: '190px' }}
                    >
                        {secondaryBtnText}
                    </Button>
                )}
                <Button
                    variant={'primary'}
                    disabled={disableNav || disableSubmit}
                    onClick={navigateOrSubmitClick}
                    sx={{ minWidth: '190px' }}
                >
                    {primaryBtnText}
                </Button>
                {selectedDesignRevisionId && (
                    <GuidedWaxupLiveReviewButtonWrapper>
                        <GuidedWaxupScheduleLiveCall orderId={order.id} revisionId={selectedDesignRevisionId} />
                    </GuidedWaxupLiveReviewButtonWrapper>
                )}
                <UnfinishedPresetsMessage />
            </Box>
        </Grid>
    );
};
