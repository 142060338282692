import { isItemShadeValid, SimpleShadePicker, getHiddenShadeProps } from '../../../components/ToothShade';
import { useRefabFlowContext } from '../screens/refab-flow-context';
import { useRefabFlowAction, useRefabFlowSelector } from '../state/refab-flow-state';
import {
    areShadesOfAllItemsTheSame,
    useGetDisplayStringOfItemWithShadeUpdates,
    useGetShadesOfSelectedItems,
} from '../utils';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { IOrderItemShade, IOrderItemV2DTO, TeethShade, ValidShadeType } from '@orthly/items';
import { CartItemV2Utils, LabOrderItemSKUType, OrderItemV2Utils, isTeethShade } from '@orthly/items';
import { RootActionDialog } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    Text,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    FormControlLabel,
    Grid,
    Switch,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory<{ isMobile: boolean }>(() => ({
    container: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    separator: {
        display: 'flex',
        alignSelf: 'stretch',
        width: `1px`,
        backgroundColor: FlossPalette.DARK_TAN,
        marginLeft: 100,
        marginRight: 100,
    },
    modalButtonsContainer: { display: `flex`, justifyContent: `flex-end`, gap: `12px`, marginTop: `40px` },
    toggleContainer: {
        background: FlossPalette.TAN,
        border: `1px solid ${FlossPalette.DARK_TAN}`,
        borderRadius: `8px`,
        display: `flex`,
        padding: `8px`,
        marginBottom: `24px`,
        maxWidth: ({ isMobile }) => (isMobile ? `100%` : `328px`),
    },
}));

const useGetItemV2 = (itemId: string): IOrderItemV2DTO | undefined => {
    const { order } = useRefabFlowContext();
    return OrderItemV2Utils.parseItems(order?.items_v2 ?? []).find(i => i.id === itemId);
};

const useShadesToDisplay = (): IOrderItemShade[] => {
    const itemId = useRefabFlowSelector(s => s.screenItemId);
    const shadeUpdates = useRefabFlowSelector(s => s.shadeUpdatesByItemId);
    const item = useGetItemV2(itemId);

    if (!item) {
        return [];
    }

    const updatedShades = shadeUpdates[item.id]?.changeRequests ?? [];
    const untouchedShades = item.shades.filter(shade => {
        return !updatedShades.find(s => s.shadeName === shade.name);
    });

    const mergedShades = [...untouchedShades, ...updatedShades.map(s => ({ name: s.shadeName, value: s.newValue }))];

    // Finally we filter out the shades that have been set to undefined (ie: user clicked on trash for that shade)
    // as we won't display them in the SimpleShadePicker
    return mergedShades.filter(s => s.value !== undefined) as IOrderItemShade[];
};

const MODAL_TEXTS = {
    switchToTrue: {
        title: 'Use the same shades for all items?',
        subtitle: `By changing this setting, you'll lose your selections and will be able to select the same shades for all items`,
    },
    switchToFalse: {
        title: 'Stop using the same shades for each item?',
        subtitle: `By changing this setting, you'll lose your selections and will be able to select different shades for each item`,
    },
};

export const RequestShadeChanges: React.VFC<{
    setHasValidShades: (isValid: boolean) => void;
}> = ({ setHasValidShades }) => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const classes = useStyles({ isMobile });
    const { order, isOps } = useRefabFlowContext();
    const itemId = useRefabFlowSelector(s => s.screenItemId);
    const shadeUpdatesState = useRefabFlowSelector(s => s.shadeUpdatesByItemId);
    const isSettingShadesForAllItems = useRefabFlowSelector(s => s.isSettingShadesForAllItems);
    const shadesToDisplay = useShadesToDisplay();
    const setIsSettingShadesForAllItemsAction = useRefabFlowAction('SET_IS_SETTING_SHADES_FOR_ALL_ITEMS');
    const setChangeRequests = useRefabFlowAction('SET_CHANGE_REQUESTS');
    const shadesOfSelectedItems = useGetShadesOfSelectedItems();
    const setIsSettingShadesForAllItems = React.useCallback(
        (isSettingShadesForAllItems: boolean) => {
            setIsSettingShadesForAllItemsAction({ isSettingShadesForAllItems });
        },
        [setIsSettingShadesForAllItemsAction],
    );
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState(false);
    const hasMultipleShadeUpdates = shadesOfSelectedItems.length > 1;

    const itemNamesDisplayString = useGetDisplayStringOfItemWithShadeUpdates();

    React.useEffect(() => {
        if (isSettingShadesForAllItems === undefined) {
            setIsSettingShadesForAllItems(areShadesOfAllItemsTheSame(shadesOfSelectedItems));
        }
    }, [shadesOfSelectedItems, isSettingShadesForAllItems, setIsSettingShadesForAllItems]);

    const item = useGetItemV2(itemId);
    const { hiddenShades, hiddenShadeOpts } = getHiddenShadeProps(item?.sku ?? LabOrderItemSKUType.Unknown);

    const updateShadeValue = (shadeName: ValidShadeType, newValue: TeethShade | undefined) => {
        const initialOrderShadeValue = item?.shades.find(s => s.name === shadeName)?.value;
        const otherChangeRequests =
            shadeUpdatesState[itemId]?.changeRequests.filter(cr => cr.shadeName !== shadeName) ?? [];

        if (newValue === initialOrderShadeValue) {
            // If we're back to original value, we remove the change request (and only retain the other change requests)
            setChangeRequests({ shadeChangeRequests: otherChangeRequests });
        } else {
            setChangeRequests({
                shadeChangeRequests: [
                    ...otherChangeRequests,
                    {
                        shadeName,
                        newValue,
                        previousValue: initialOrderShadeValue,
                    },
                ],
            });
        }
    };

    React.useEffect(() => {
        const hasValidShadeValue = isTeethShade(shadesToDisplay.find(s => s.name === 'base')?.value ?? '');

        setHasValidShades(hasValidShadeValue);
    }, [shadesToDisplay, setHasValidShades]);

    const titleModal = isSettingShadesForAllItems ? MODAL_TEXTS.switchToFalse.title : MODAL_TEXTS.switchToTrue.title;
    const subtitleModal = isSettingShadesForAllItems
        ? MODAL_TEXTS.switchToFalse.subtitle
        : MODAL_TEXTS.switchToTrue.subtitle;

    return (
        <>
            <Grid direction={isMobile ? 'column-reverse' : 'row'} className={classes.container}>
                <Grid direction={'column'}>
                    <Text variant={'body1'} style={{ marginBottom: 24 }}>
                        Review your previously selected shades. Modify the shade if needed or keep as is.
                    </Text>
                    <SimpleShadePicker
                        unns={item ? CartItemV2Utils.getUniqueUNNs(item) : []}
                        onShadeChange={updateShadeValue}
                        shades={shadesToDisplay.filter(isItemShadeValid)}
                        disableAutoFocusShade={true}
                        hiddenShades={hiddenShades}
                        hiddenShadeOpts={hiddenShadeOpts}
                        originalShades={item?.shades.filter(isItemShadeValid) ?? []}
                        requiredShadeTypes={['base']}
                    />
                </Grid>
                {hasMultipleShadeUpdates && !isMobile && <div className={classes.separator} />}
                {hasMultipleShadeUpdates && (
                    <Grid direction={'column'} className={classes.toggleContainer}>
                        <FormControlLabel
                            control={
                                <Switch
                                    key={`switch-${isSettingShadesForAllItems}`}
                                    checked={isSettingShadesForAllItems}
                                    onChange={() => {
                                        setIsConfirmationModalOpen(true);
                                    }}
                                    color={'secondary'}
                                />
                            }
                            label={'Use the same shades for all items'}
                        />
                        <Text variant={'body2'} style={{ paddingLeft: `88px`, marginTop: `8px` }} color={'GRAY'}>
                            Your selection will apply to {itemNamesDisplayString}.
                        </Text>
                    </Grid>
                )}
            </Grid>
            <RootActionDialog
                loading={false}
                open={isConfirmationModalOpen}
                setOpen={setIsConfirmationModalOpen}
                title={titleModal}
                content={
                    <div>
                        <Text variant={'body1'}>{subtitleModal}</Text>

                        <div className={classes.modalButtonsContainer}>
                            <Button variant={'secondary'} onClick={() => setIsConfirmationModalOpen(false)}>
                                Cancel
                            </Button>
                            <Button
                                variant={'primary'}
                                onClick={() => {
                                    BrowserAnalyticsClientFactory.Instance?.track(
                                        `${
                                            isOps ? `Ops` : `Practice`
                                        } - Refab Flow - Switched Setting Shade On All Items`,
                                        {
                                            $groups: { order: order?.id ?? 'unknown' },
                                            switchTo: !isSettingShadesForAllItems,
                                        },
                                    );
                                    setIsConfirmationModalOpen(false);
                                    setIsSettingShadesForAllItems(!isSettingShadesForAllItems);
                                }}
                            >
                                Yes, change it
                            </Button>
                        </div>
                    </div>
                }
                showCloseButton
                onClose={() => setIsConfirmationModalOpen(false)}
                hideButton={true}
            />
        </>
    );
};
