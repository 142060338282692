import type { ItemInfo, Decision } from '../orderEditMode';
import { OrderEditMode } from '@orthly/shared-types';

/**
 * labDoesDesign checks if the lab is doing design. If so, it returns OrderEdit as most permissible value,
 * otherwise it returns null to skip
 */
export function labDoesDesign(item: ItemInfo): Decision {
    const isLabDesign = !item.order.fulfillment_workflow.configuration.internal_design_required;
    return {
        editMode: isLabDesign ? OrderEditMode.OrderEdit : null,
        debugInfo: { rule: 'labDoesDesign', isLabDesign },
    };
}
