import type {
    AdminLabsInvoicingLineItemsPaneProps,
    BulkOrderPriceUpdateVars,
    LedgerRow,
    OrderItemPriceFormProps,
    OrderPriceFormProps,
    OverrideAmtVars,
    PriceHierarchy,
} from './AdminLabsEditInvoicingLineItems.types';
import {
    REFUND_CONFIRMATION,
    convertGetOrderLedgerQueryToTableData,
    itemsPricesFromOrder,
    itemsPricesFromQuickformValues,
    quickFormInitialValuesFromOrder,
    quickFormSchemaFromOrder,
    staticColumns,
    totalFromItemPrices,
} from './AdminLabsEditInvoicingLineItems.utils';
import {
    orderPricesForBulkUpdate,
    orderPricesFromQuickformValues,
    quickFormInitialValuesFromOrderPrices,
    quickFormSchemaFromOrderPrices,
    totalFromOrderPrices,
} from './AdminLabsEditInvoicingOrderPrices.utils';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrderPriceDtoFragment } from '@orthly/graphql-operations';
import {
    useBulkUpdateOrderPricesMutation,
    useGetOrderPricesByOrderId,
    useOverrideDentistAmountDueMutation,
} from '@orthly/graphql-react';
import { LoadBlocker, QuickForm, useChangeSubmissionFn } from '@orthly/ui';
import { Button, FlossPalette } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';
import MaterialTable, { MTableCell } from 'material-table';
import type { OptionsObject } from 'notistack';
import React from 'react';

const CenteredBoldText: React.FC<{ children: React.ReactNode }> = props => {
    return (
        <div
            style={{
                marginTop: '10px',
                textAlign: 'center',
                fontSize: '18px',
                fontWeight: 'bold',
            }}
        >
            {props.children}
        </div>
    );
};

const BalanceSection = (props: { amount_owed: number; amount_paid: number; amount_credited: number }) => {
    return (
        <CenteredBoldText>
            <div>Total Amount Owed: ${props.amount_owed}</div>
            <div>Amount Paid: ${props.amount_paid}</div>
            <div style={{ color: FlossPalette.PRIMARY_FOREGROUND }}>Amount Credited: ${props.amount_credited}</div>
            <div>Net Amount Due: ${props.amount_owed - props.amount_paid - props.amount_credited}</div>
        </CenteredBoldText>
    );
};

const OrderItemPriceForm: React.FC<OrderItemPriceFormProps> = ({
    order,
    refetchOrder,
    onSubmit,
    refetchLedger,
    showingPreferences,
    setNewAmountDue,
    data,
    canEdit,
}) => {
    const orderItemsPrices = itemsPricesFromOrder(order);
    const [itemsPrices, setItemsPrices] = React.useState<PriceHierarchy[]>(orderItemsPrices);

    const [submitMtn] = useOverrideDentistAmountDueMutation();
    const mtnSubmitter = (data: OverrideAmtVars) => submitMtn({ variables: { ...data } });

    const onSuccess = React.useCallback(async () => {
        await Promise.all([refetchLedger(), refetchOrder()]);
        onSubmit();
    }, [refetchLedger, refetchOrder, onSubmit]);

    const changeConfig = {
        onSuccess,
        closeOnComplete: true,
        successMessage: () => ['Order balance updated!', {}] as [string, OptionsObject | undefined],
    };

    const overrideFn = useChangeSubmissionFn<any, [OverrideAmtVars]>(mtnSubmitter, changeConfig);

    return (
        <QuickForm<any>
            disabled={!canEdit}
            fields={quickFormSchemaFromOrder({
                order,
                orderItemsPrices,
                showingPreferences,
                formItemsPrices: itemsPrices,
            })}
            submitButtonProps={{
                children: 'Change Amount Due',
            }}
            onChange={result => {
                const newItemsPrices = itemsPricesFromQuickformValues(result);
                setItemsPrices(newItemsPrices);
                const newTotal = totalFromItemPrices(_.values(result));
                setNewAmountDue(newTotal);
            }}
            initialValues={quickFormInitialValuesFromOrder(order)}
            onSubmit={async result => {
                const amount_paid = _.sumBy(data, d => (d.invoice !== 'Pending' ? d.amount : 0));
                const items_prices = itemsPricesFromQuickformValues(result);
                const new_total = totalFromItemPrices(_.values(result));

                // PSR seems to be attempting to issue a refund, confirm they mean it
                // eslint-disable-next-line no-restricted-globals
                if (new_total < amount_paid && !confirm(REFUND_CONFIRMATION)) {
                    return;
                }
                return overrideFn.submit({
                    data: {
                        items_prices,
                        orderId: order.id,
                    },
                });
            }}
        />
    );
};

const OrderPricesForm: React.FC<OrderPriceFormProps> = ({
    orderPrices,
    onSubmit,
    refetch,
    refetchOrder,
    setNewAmountDue,
    canEdit,
}) => {
    const [formOrderPrices, setFormOrderPrices] = React.useState<LabsGqlOrderPriceDtoFragment[]>(orderPrices);

    const [submitMtn] = useBulkUpdateOrderPricesMutation();
    const mtnSubmitter = (data: BulkOrderPriceUpdateVars) => submitMtn({ variables: { ...data } });

    const onSuccess = async () => {
        await Promise.all([refetch(), refetchOrder()]);
        onSubmit();
    };

    const changeConfig = {
        onSuccess,
        closeOnComplete: true,
        successMessage: () => ['Order prices updated!', {}] as [string, OptionsObject | undefined],
    };

    const overrideFn = useChangeSubmissionFn<any, [BulkOrderPriceUpdateVars]>(mtnSubmitter, changeConfig);

    return (
        <QuickForm<any>
            disabled={!canEdit}
            fields={quickFormSchemaFromOrderPrices({
                orderPrices,
                formOrderPrices,
            })}
            submitButtonProps={{
                children: 'Change Amount Due',
            }}
            onChange={(result, _props) => {
                const newOrderPrices = orderPricesFromQuickformValues(result, orderPrices);
                setFormOrderPrices(newOrderPrices);
                const newTotal = totalFromOrderPrices(_.values(result));
                setNewAmountDue(newTotal);
            }}
            initialValues={quickFormInitialValuesFromOrderPrices(orderPrices)}
            resetOnInitialValueChange={true}
            onSubmit={async result => {
                const newOrderPrices = orderPricesFromQuickformValues(result, orderPrices);

                // only update order prices that have changed
                const updates = orderPricesForBulkUpdate(newOrderPrices, orderPrices);
                return overrideFn.submit({ updates });
            }}
        />
    );
};

const GetOrderLedger_Query = graphql(`
    query GetOrderLedger($orderId: String!) {
        getOrderLedger(orderId: $orderId) {
            billedItems {
                id
                order_id
                category
                amount_cents
                invoice_id
                description
                created_at
            }
            pendingItem {
                amount_cents
                description
                created_at
            }
            paidInvoiceIds
            credits {
                id
                created_at
                deleted_at
                description
                amount_issued_cents
                attribution {
                    ... on InvoiceAdjustmentAttributionBase {
                        type
                    }
                    ... on OrderAdjustmentAttribution {
                        order_id
                    }
                    ... on OrderItemAdjustmentAttribution {
                        order_id
                        order_item_id
                    }
                    ... on InvoiceItemAdjustmentAttribution {
                        invoice_id
                        invoice_item_id
                    }
                }
                allocations {
                    amountCents
                    invoiceId
                }
                categoryName
            }
        }
    }
`);

export const AdminLabsInvoicingLineItemsPane: React.FC<AdminLabsInvoicingLineItemsPaneProps> = props => {
    const { value: enableOrderPriceStorage } = useFeatureFlag('enableOrderPriceStorage');
    const { order, canEdit } = props;
    const {
        prices: orderPrices,
        loading: orderPricesLoading,
        refetch: refetchOrderPrices,
    } = useGetOrderPricesByOrderId(order.id);

    const preferencesHavePrices = _.some(
        props.order.line_items.flatMap(li => li.preference_fields.map(p => p.price_cents)),
    );
    // Show preferences by default only if any of them have a price
    const [showingPreferences, setShowingPreferences] = React.useState<boolean>(preferencesHavePrices);
    const [newAmountDue, setNewAmountDue] = React.useState<number | undefined>(undefined);

    // Updated invoicing system data
    const {
        data: rawUpdatedLedgerData,
        loading: loadingLedger,
        refetch: refetchLedger,
    } = useQuery(GetOrderLedger_Query, {
        variables: { orderId: order.id },
    });
    const ledgerRows = React.useMemo(() => {
        return rawUpdatedLedgerData ? convertGetOrderLedgerQueryToTableData(rawUpdatedLedgerData.getOrderLedger) : [];
    }, [rawUpdatedLedgerData]);

    const shouldUseOrderPriceForm = enableOrderPriceStorage && !orderPricesLoading && orderPrices.length > 0;

    const creditAmount = React.useMemo(() => {
        return rawUpdatedLedgerData
            ? _.sum(
                  rawUpdatedLedgerData.getOrderLedger.credits
                      .filter(c => !c.deleted_at)
                      .flatMap(c => c.allocations.map(a => a.amountCents)),
              )
            : 0;
    }, [rawUpdatedLedgerData]);

    return (
        <LoadBlocker blocking={loadingLedger && (enableOrderPriceStorage ? orderPricesLoading : false)}>
            <div className={'material-table-notitle-container'}>
                <MaterialTable<LedgerRow>
                    title={'Order Ledger'}
                    style={{}}
                    columns={[
                        ...staticColumns,
                        {
                            title: 'Amount',
                            field: 'amount',
                            type: 'currency',
                            cellStyle: { width: '95px', fontWeight: 'bold' },
                        },
                    ]}
                    data={ledgerRows}
                    components={{
                        Toolbar: () => <div />,
                        Cell: props => <MTableCell {...props} style={{ paddingTop: 0, paddingBottom: 0 }} />,
                    }}
                    options={{
                        searchFieldStyle: {
                            minHeight: '0px',
                            height: '0px',
                        },
                        showFirstLastPageButtons: false,
                        grouping: false,
                        paging: false,
                        showTitle: false,
                        showSelectAllCheckbox: false,
                        search: false,
                        defaultExpanded: true,
                        rowStyle(data: LedgerRow) {
                            if (data.isDeleted) {
                                return {
                                    textDecoration: 'line-through',
                                };
                            }
                            return {};
                        },
                    }}
                />
                <BalanceSection
                    amount_owed={(order.dentist_amount_due_cents || 0) / 100}
                    amount_paid={ledgerRows.reduce((sum, a) => (a.paid ? sum + a.amount : sum), 0)}
                    amount_credited={creditAmount / 100}
                />
                {!enableOrderPriceStorage && !preferencesHavePrices && (
                    <CenteredBoldText>
                        <Button variant={'contained'} onClick={() => setShowingPreferences(!showingPreferences)}>
                            {showingPreferences ? 'Hide Preference Prices' : 'Show Preference Prices'}
                        </Button>
                    </CenteredBoldText>
                )}
                {shouldUseOrderPriceForm ? (
                    <OrderPricesForm
                        onSubmit={props.onSubmit}
                        refetch={refetchOrderPrices}
                        refetchOrder={props.refetchOrder}
                        setNewAmountDue={setNewAmountDue}
                        orderPrices={orderPrices}
                        canEdit={canEdit}
                    />
                ) : (
                    <OrderItemPriceForm
                        {...props}
                        refetchLedger={refetchLedger}
                        showingPreferences={showingPreferences}
                        setNewAmountDue={setNewAmountDue}
                        data={ledgerRows}
                        canEdit={canEdit}
                    />
                )}
                {newAmountDue !== undefined && (
                    <CenteredBoldText>
                        <span>{`Changing total amount due to: $${newAmountDue}`}</span>
                    </CenteredBoldText>
                )}
            </div>
        </LoadBlocker>
    );
};
