import { PrintableSlipSectionDivider } from '../../../PrintableSlip/components/shared/PrintableSlipSectionDivider';
import { PrintableSlipSticker } from '../../../PrintableSlip/components/shared/PrintableSlipSticker';
import { NOTE_TO_DR_PREFIX } from '../../../PrintableSlip/utils/notes.util';
import type { LabsGqlLabSlipAlertFragment } from '@orthly/graphql-operations';
import { ToothInFrameIcon } from '@orthly/ui';
import { stylesFactory, Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    subSection: {
        marginBottom: '0.5rem',
        marginTop: '0.5rem',
    },
    subSectionTitle: {
        fontSize: '1rem !important',
        fontWeight: 'bold',
    },
    item: {
        fontSize: '0.75rem !important',
        marginTop: '0.25rem',
    },
}));

/**
 * UnboxingSlip section for displaying notes to the doctor
 * @param notesToDoctor The "NOTES TO DR:" information stored as alerts on an Order
 * @returns AdjustmentNotes section
 */
export const AdjustmentNotes: React.VFC<{ notesToDoctor: LabsGqlLabSlipAlertFragment[] }> = ({ notesToDoctor }) => {
    const classes = useStyles();
    if (notesToDoctor.length === 0) {
        return null;
    }
    return (
        <>
            <Grid container className={classes.subSection}>
                <Grid container spacing={1}>
                    <PrintableSlipSticker icon={ToothInFrameIcon} solid text={'May Require Chairside Adjustments'} />
                </Grid>
                <Grid container>
                    {notesToDoctor.map((alert, idx) => {
                        return (
                            <Grid container key={idx} spacing={1} wrap={'nowrap'}>
                                <Grid item>
                                    <Text variant={'body1'} className={classes.item}>
                                        {alert.text.trim().replace(NOTE_TO_DR_PREFIX, '')}
                                    </Text>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
            <PrintableSlipSectionDivider />
        </>
    );
};
