import { useBillingDetailsContext } from '../BillingDetails/providers/BillingDetailsProvider.graphql';
import { InvoiceAdjustmentHistoryButton } from '../actions/pricing-history/InvoiceAdjustmentHistoryModal';
import type { BillingDetailsProps } from '../types';
import { type Invoice, type InvoiceRow } from './InvoiceTable.types';
import { InvoiceTableDetailPanel } from './InvoiceTableDetailPanel.graphql';
import { PartialPaymentAction } from './PartialPaymentAction.graphql';
import { PayInvoiceModal } from './PayInvoiceModal.graphql';
import { LabsGqlStripeInvoiceStatus } from '@orthly/graphql-schema';
import type { ColumnFilterOpts, MUITableColumn } from '@orthly/mui-table';
import { MUITable } from '@orthly/mui-table';
import { LoadBlockerLoader } from '@orthly/ui';
import { Box, Grid } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import { compact, startCase } from 'lodash';
import moment from 'moment';
import React from 'react';

const booleanFilterOptions: ColumnFilterOpts = { defaultOpts: ['true', 'false'], type: 'multiselect' };

const InvoiceRootColumns = (
    hasUnpaid: boolean,
    enablePricingChangeHistory?: boolean,
): MUITableColumn<InvoiceRow<Invoice>>[] => [
    { name: 'ID', render: 'id', hidden: true },
    { name: 'Customer ID', render: 'stripe_customer_id', hidden: true },
    { name: 'Month', render: 'month_formatted', filterOptions: { type: 'multiselect' } },
    { name: 'Invoice Number', render: 'invoice_number' },
    {
        name: 'Type',
        render: 'type',
        filterOptions: { exact: true, defaultOpts: ['Prepayment', 'Orders'], type: 'multiselect' },
    },
    { name: 'Partner ID', render: 'partner_id', hidden: true },
    { name: 'Partner', render: 'partner_name', filterOptions: { exact: true, type: 'multiselect' } },
    { name: 'Autocharge Enabled', render: 'autocharge_enabled', type: 'boolean', filterOptions: booleanFilterOptions },
    {
        name: 'Status',
        render: r => startCase(r.status),
        field: 'status',
        filterOptions: {
            defaultValues: hasUnpaid ? ['open'] : undefined,
            defaultOpts: Object.values(LabsGqlStripeInvoiceStatus),
            exact: true,
            type: 'multiselect',
        },
    },
    { name: 'Overdue', render: 'overdue', type: 'boolean', filterOptions: booleanFilterOptions },
    { name: 'Created', render: 'created_at', type: 'date', defaultSort: 'desc' },
    { name: 'Period Start', render: 'period_start', type: 'date' },
    { name: 'Period End', render: 'period_end', type: 'date' },
    { name: 'Due Date', render: 'due_date', type: 'date', defaultSort: 'desc' },
    { name: 'Initial Invoice Total', render: 'total', type: 'currency' },
    { name: 'Applied Stripe Balance', render: 'applied_balance', type: 'currency' },
    { name: 'Amount Due', render: 'amount_due', type: 'currency' },
    { name: 'Amount Paid', render: 'amount_paid', type: 'currency' },
    { name: 'Amount Remaining', render: 'amount_remaining', type: 'currency' },
    { name: 'Email Sent', render: 'summary_email_sent', type: 'boolean', filterOptions: booleanFilterOptions },
    {
        name: 'Payment Errors',
        render: r => r.payment_errors_count || null,
        field: 'payment_errors_count',
    },
    {
        name: 'Actions',
        render: r => {
            if (r.status !== LabsGqlStripeInvoiceStatus.Open) {
                return enablePricingChangeHistory ? <InvoiceAdjustmentHistoryButton invoiceId={r.id} /> : null;
            }

            const amountPaidAndPending = r.amount_paid + r.pending_payments_cents;
            if (amountPaidAndPending >= r.amount_due) {
                return enablePricingChangeHistory ? <InvoiceAdjustmentHistoryButton invoiceId={r.id} /> : null;
            }

            return (
                <Box sx={{ display: 'flex' }}>
                    <PayInvoiceModal invoice={r} />
                    {enablePricingChangeHistory ? <InvoiceAdjustmentHistoryButton invoiceId={r.id} /> : null}
                </Box>
            );
        },
    },
];

export const InvoicesDetailPanel: React.FC<BillingDetailsProps> = ({ practiceId }) => {
    const {
        invoices: rawInvoices,
        invoicesLoading: loading,
        refetchBillingDetails,
        getOrganizationFromId,
    } = useBillingDetailsContext();
    const { value: enablePricingChangeHistory } = useFeatureFlag('enablePricingChangeHistory');

    const invoices: InvoiceRow<Invoice>[] = React.useMemo(() => {
        if (!rawInvoices) {
            return [];
        }

        return compact(
            rawInvoices.map(invoice => {
                const organization = getOrganizationFromId(invoice.organization_id);

                if (!organization) {
                    return undefined;
                }

                return {
                    ...invoice,
                    partner_id: invoice.organization_id,
                    month_formatted: moment.utc(invoice.period_start).format('MMM YYYY'),
                    partner_name: organization.name,
                    overdue: invoice.status === 'open' && moment.utc(invoice.due_date).isBefore(moment()),
                    autocharge_enabled: organization.autocharge_enabled,
                    stripe_customer_id: organization.stripe_customer_id,

                    // ensure these display as the right date for local time
                    period_start: moment.utc(invoice.period_start).set({ hour: 12 }).toISOString(),
                    period_end: moment(invoice.period_end).set({ hour: 12 }).toISOString(),
                    type: 'Orders',
                    payments_count: invoice.payments.length,
                    payment_errors_count: invoice.payments.filter(p => !!p.error_summary).length,
                    pending_payments_cents: invoice.payments
                        .filter(p => p.status === 'pending')
                        .reduce((acc, p) => acc + p.amount_cents, 0),
                };
            }),
        );
    }, [rawInvoices, getOrganizationFromId]);

    const hasUnpaidInvoice = invoices.some(i => i.status === LabsGqlStripeInvoiceStatus.Open);

    return (
        <>
            <Grid container style={{ position: 'relative' }}>
                <LoadBlockerLoader blocking={loading} loader={'linear'} />
            </Grid>

            <MUITable<InvoiceRow<Invoice>>
                title={'Invoices'}
                DetailPanel={InvoiceTableDetailPanel}
                displayOptions={{ fixedSearch: true, download: true, viewColumns: true }}
                actions={{
                    global: [
                        { icon: 'refresh', onClick: refetchBillingDetails, disabled: loading, position: 'toolbar' },
                    ],
                }}
                toolbarOptions={{
                    CustomRight: () => (
                        <PartialPaymentAction organizationId={practiceId} refetch={refetchBillingDetails} />
                    ),
                }}
                data={invoices}
                columns={InvoiceRootColumns(hasUnpaidInvoice, enablePricingChangeHistory)}
            />
        </>
    );
};
