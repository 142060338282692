import { useFirebasePreviewMulti } from '../../hooks/useFirebaseDownload';
import type { RootActionDialogProps } from '@orthly/ui';
import { RootActionDialog } from '@orthly/ui';
import { FlossPalette, Grid } from '@orthly/ui-primitives';
import React from 'react';

export type AttachmentPreviewProps = Pick<
    RootActionDialogProps,
    'open' | 'setOpen' | 'title' | 'buttonText' | 'buttonProps' | 'buttonColor' | 'buttonTextColor' | 'CustomButton'
> & {
    sources: { source: string; name: string }[];
};

export const AttachmentPreview = (props: AttachmentPreviewProps) => {
    const { sources, ...dialogProps } = props;
    const { result, loading } = useFirebasePreviewMulti(sources);
    if (sources.length === 0) {
        return null;
    }
    return (
        <RootActionDialog
            showCloseButton
            loading={loading}
            dialogProps={{ maxWidth: 'xl' }}
            contentProps={{ style: { backgroundColor: FlossPalette.GRAY } }}
            content={
                <Grid container justifyContent={'space-between'}>
                    {sources.map((r, idx) => {
                        const imgSrc = result?.[idx]?.source;
                        return <img alt={r.name} key={r.name} src={imgSrc} style={{ width: '100%' }} />;
                    })}
                </Grid>
            }
            {...dialogProps}
        />
    );
};
