import { StageRow } from './StageRow';
import { StatusHeadline } from './StatusHeadline';
import { getRowData } from './utils/getRowData';
import { useQuery } from '@apollo/client';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { LoadBlocker } from '@orthly/ui';
import {
    FlossPalette,
    TablePrimitive as Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    stylesFactory,
} from '@orthly/ui-primitives';
import moment from 'moment-timezone';
import React from 'react';

const { ATTENTION_FOREGROUND, DARK_TAN, GRAY } = FlossPalette;

const notInProgressStatuses = new Set<LabsGqlLabOrderStatus>([
    LabsGqlLabOrderStatus.Cancelled,
    LabsGqlLabOrderStatus.Delivered,
    LabsGqlLabOrderStatus.NeedsRefabrication,
]);

const useStyles = stylesFactory(theme => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: theme.spacing(1),
        paddingTop: '1em',
        '& table': {
            marginTop: '1em',
        },
    },
    stageMetaRow: {
        backgroundColor: DARK_TAN,
        '& th, & td': {
            ...theme.typography.body1,
            color: GRAY,
            // Copied from sizeSmall
            padding: '6px 24px 6px 16px',
            '&:last-child': {
                paddingRight: '16px',
            },
        },
        '& th:last-of-type': {
            // Deliberately not applied to <td> because "Upcoming stages" is just one cell for the whole row.
            textAlign: 'right' as const,
        },
    },
    headline: {
        ...theme.typography.body1,
        fontWeight: 'bold' as const,
        color: GRAY,
    },
    errorBlock: {
        ...theme.typography.body1,
        color: ATTENTION_FOREGROUND,
    },
}));

export const TatProgressTrackingBlock_Fragment = graphql(`
    fragment TatProgressTrackingBlock_Fragment on SLAStageStatus {
        stage_name
        sla_days
        actual_start_time
        actual_end_time
        overridden_projected_end_time
    }
`);

export const TatProgressTrackingBlock_Query = graphql(`
    query TatProgressTrackingBlockQuery($order_id: ID!) {
        getOrderSlaStageStatuses(order_id: $order_id) {
            ...TatProgressTrackingBlock_Fragment
        }
    }
`);

/**
 * The equivalent of `apps/admin/src/screens/Orders/screens/actions/order-action-types/AdminLabsOrderActionProps`,
 * if that type could be imported here.
 */
export interface TatProgressTrackingBlockProps {
    order: LabsGqlOrder;
}

export const TatProgressTrackingBlock: React.VFC<TatProgressTrackingBlockProps> = ({ order }) => {
    const { data, error, loading } = useQuery(TatProgressTrackingBlock_Query, {
        variables: { order_id: order.id },
    });

    const classes = useStyles();

    if (error) {
        return <div className={classes.errorBlock}>Sorry, there was an error.</div>;
    }

    const userTimeZone = moment.tz.guess();
    const rowData = data
        ? getRowData(
              order,
              getFragmentData(TatProgressTrackingBlock_Fragment, data.getOrderSlaStageStatuses),
              userTimeZone,
          )
        : [];

    const completeAndOngoingStages = rowData.filter(row => row.stageStatus !== 'upcoming');
    const ongoingStage = completeAndOngoingStages.find(row => row.stageStatus === 'ongoing');
    const upcomingStages = rowData.filter(row => row.stageStatus === 'upcoming');
    const orderShipByDate = moment.tz(order.manufacturer_sla.ship_by_date, userTimeZone);
    const isInProgress = !notInProgressStatuses.has(order.status);

    let lastStatusHeadline: React.ReactNode = null;
    switch (order.status) {
        case LabsGqlLabOrderStatus.Cancelled:
            lastStatusHeadline = (
                <StatusHeadline
                    textPart={'Canceled on'}
                    datePart={order.cancel_date}
                    useTime={'none'}
                    colorful={false}
                />
            );
            break;
        case LabsGqlLabOrderStatus.Delivered:
            lastStatusHeadline = (
                <StatusHeadline
                    textPart={'Delivered on'}
                    datePart={order.delivery_date}
                    useTime={'full'}
                    colorful={true}
                    carrier={order.carrier}
                    trackingNumber={order.tracking_number}
                />
            );
            break;
        case LabsGqlLabOrderStatus.Shipped:
            lastStatusHeadline = (
                <StatusHeadline
                    textPart={'Shipped on'}
                    datePart={order.ship_date}
                    useTime={'none'}
                    colorful={true}
                    carrier={order.carrier}
                    trackingNumber={order.tracking_number}
                />
            );
            break;
        case LabsGqlLabOrderStatus.NeedsRefabrication:
            if (order.delivery_date) {
                lastStatusHeadline = (
                    <StatusHeadline
                        textPart={'Delivered on'}
                        datePart={order.delivery_date}
                        useTime={'full'}
                        colorful={false}
                        carrier={order.carrier}
                        trackingNumber={order.tracking_number}
                    />
                );
            }
            break;
        default:
            lastStatusHeadline = (
                <StatusHeadline
                    textPart={'Shipping deadline'}
                    datePart={orderShipByDate}
                    colorful={false}
                    useTime={'none'}
                />
            );
            break;
    }

    return (
        <LoadBlocker blocking={loading}>
            <div className={classes.root}>
                <div className={classes.headline}>
                    <span>Order status</span>
                </div>
                {ongoingStage && isInProgress && order.status !== LabsGqlLabOrderStatus.Shipped && (
                    <StatusHeadline textPart={`${ongoingStage.stage} deadline`} datePart={ongoingStage.end} />
                )}
                {lastStatusHeadline}
                {/* TODO EPDSCM-725: warning icons, buffer status */}
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.stageMetaRow}>
                                <TableCell>Stage</TableCell>
                                <TableCell>TAT</TableCell>
                                <TableCell>Start</TableCell>
                                <TableCell>End</TableCell>
                                <TableCell>Total&nbsp;time (breach)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {completeAndOngoingStages.map(row => (
                                <StageRow key={row.reactKey} orderIsInProgress={isInProgress} {...row} />
                            ))}
                            {upcomingStages.length > 0 && isInProgress && (
                                <TableRow className={classes.stageMetaRow}>
                                    <TableCell colSpan={5}>Upcoming stages</TableCell>
                                </TableRow>
                            )}
                            {upcomingStages.map(row => (
                                <StageRow key={row.reactKey} orderIsInProgress={isInProgress} {...row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </LoadBlocker>
    );
};
