import { TrackerConnector } from './TrackerConnector';
import type { TrackerIconVariant } from './TrackerIcon';
import { TrackerIcon } from './TrackerIcon';
import { FlossPalette, stylesFactory, Grid, Text } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

const useStyles = stylesFactory<{ isLast: boolean }>(
    () => ({
        stepIconLabelWrap: props => ({
            justifyContent: props.isLast ? 'end' : 'center',
            maxWidth: 18,
            position: 'relative',
            height: 18,
            '&:last-of-type': { justifyContent: 'flex-end' },
            '&:first-of-type': { justifyContent: 'flex-start' },
        }),
        icon: { color: FlossPalette.DARK_TAN, height: 16, width: 16 },
        iconActiveOrDone: { color: FlossPalette.GRAY },
        iconActiveOrDoneIssue: { color: FlossPalette.ATTENTION },
        stepIconLabel: { whiteSpace: 'nowrap', textAlign: 'center', color: FlossPalette.GRAY },
        stepIconLabelIssue: { color: FlossPalette.ATTENTION },
        designTaskLabel: {
            fontWeight: 500,
            fontSize: 12,
        },
        designTaskSubtitle: {
            fontSize: 12,
        },
    }),
    { name: 'TrackerStep' },
);

export interface TrackerStepDefinition {
    title: string;
    complete: boolean;
    active: boolean;
    icon: TrackerIconVariant;
    subtitle?: string;
    secondarySubtitle?: string;
}

export interface TrackerStepProps extends TrackerStepDefinition {
    idx: number;
    hasIssue: boolean;
    isLast: boolean;
}

export const TrackerStep: React.FC<TrackerStepProps> = props => {
    const { icon, active, title, complete, hasIssue, isLast } = props;
    const classes = useStyles({ isLast });
    const activeOrComplete = active || complete;
    return (
        <>
            <Grid container className={classes.stepIconLabelWrap}>
                <TrackerIcon
                    variant={icon}
                    className={cx(
                        classes.icon,
                        activeOrComplete && classes.iconActiveOrDone,
                        activeOrComplete && hasIssue && classes.iconActiveOrDoneIssue,
                    )}
                    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                    // eslint-disable-next-line no-nested-ternary
                    color={activeOrComplete ? (hasIssue ? 'ATTENTION' : 'DARK_GRAY') : 'GRAY'}
                />
                <Text
                    className={cx(classes.stepIconLabel, hasIssue && !isLast && classes.stepIconLabelIssue)}
                    variant={'caption'}
                    style={{ fontWeight: isLast ? 500 : undefined }}
                    data-test={'tracker-step'}
                >
                    {title}
                </Text>
            </Grid>
            {!isLast && <TrackerConnector hasIssue={hasIssue} active={active} completed={complete} />}
        </>
    );
};
