import { useFirebasePreviewMulti } from '../../../../hooks';
import { DandyLightbox } from '../../../DandyLightbox';
import { orderDetailImageExists, useOrderDetailImages } from '../../../OrderDetails';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { ExpandIcon, LoadBlocker } from '@orthly/ui';
import { FlossPalette, Text, stylesFactory, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const PREVIEW_SIZE = 250;
const useStyles = stylesFactory(() => ({
    photoPreviewContainer: {
        flexDirection: 'column',
        alignContent: 'center',
        marginBottom: 20,
    },
    imageContentContainer: {
        alignContent: 'center',
        backgroundColor: FlossPalette.TAN,
        paddingTop: 20,
    },
    header: {
        marginBottom: 20,
    },
    titleAndIconContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    image: {
        backgroundSize: `cover`,
        width: PREVIEW_SIZE,
        height: PREVIEW_SIZE,
        cursor: `pointer`,
        borderRadius: 18,
        boxShadow: `0 0 3px rgba(0,0,0,0.16)`,
        marginBottom: 5,
        display: `inline-flex`,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
}));

const DoctorPhotoPreview: React.VFC<{ name: string; onSelect?: () => unknown; imgSrc?: string; loading?: boolean }> = ({
    imgSrc,
    onSelect,
    name,
    loading,
}) => {
    const classes = useStyles();
    return (
        <>
            <Grid item>
                <LoadBlocker blocking={!!loading}>
                    <img src={imgSrc} className={classes.image} alt={name} onClick={onSelect} />
                </LoadBlocker>
            </Grid>
            <Grid item className={classes.titleAndIconContainer}>
                <Text variant={'body2'}>{name}</Text>
                <ExpandIcon style={{ color: FlossPalette.PRIMARY_FOREGROUND }} onClick={onSelect} />
            </Grid>
        </>
    );
};

export const DoctorImages: React.VFC = () => {
    const classes = useStyles();
    const { order } = useGuidedWaxupContext();
    const sources = useOrderDetailImages(order, false);
    const [selected, setSelected] = React.useState<string | undefined>();

    const [sourcesWithExistingImage, sourcesWithMissingImage] = _.partition(sources, orderDetailImageExists);
    // we set the source to be the name so that we can associate the returned source (authenticated url) with the original source (path within bucket)
    const { result, loading } = useFirebasePreviewMulti(
        sourcesWithExistingImage.map(({ source }) => ({ source, name: source })),
        true,
    );

    const noImagesToShow = sourcesWithExistingImage.length === 0 && sourcesWithMissingImage.length === 0;

    return (
        <>
            <Grid container className={classes.imageContentContainer}>
                <Grid container className={classes.photoPreviewContainer}>
                    <Grid item>
                        <Text
                            variant={'body2'}
                            medium
                            color={noImagesToShow ? 'GRAY' : 'BLACK'}
                            className={classes.header}
                        >
                            {noImagesToShow ? 'No doctor photos to display' : 'Doctor photos'}
                        </Text>
                    </Grid>
                    {sourcesWithExistingImage.map(source => {
                        const imgSrc = result?.find(res => res.name === source.source)?.source ?? '';
                        return (
                            <DoctorPhotoPreview
                                key={source.source}
                                name={source.title}
                                onSelect={() => setSelected(source.source)}
                                imgSrc={imgSrc}
                                loading={loading}
                            />
                        );
                    })}
                    {sourcesWithMissingImage.map((source, i) => {
                        return <DoctorPhotoPreview key={i} name={source.title} imgSrc={undefined} />;
                    })}
                </Grid>
            </Grid>
            <DandyLightbox
                setSelectedAttachmentPreview={setSelected}
                previews={result}
                selectedAttachmentPreview={selected}
                disablePhotoDownload={true}
                onPhotoViewedAnalytics={(source, name) => {
                    return {
                        name: 'All - Portal - Photo Lightbox Viewed',
                        data: {
                            $groups: { order: order.id },
                            displayLocation: 'guided_waxup_doctor_photos',
                            photoSource: source,
                            photoName: name,
                        },
                    };
                }}
            />
        </>
    );
};
