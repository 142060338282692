/* eslint-disable max-lines */
import { MarginLineMesh } from '../DesignEditing/MarginLineMesh';
import type { LineType } from '../ModelViewer/Margin.util';
import { drawLineTube, getMarginTessellatedPoints } from '../ModelViewer/Margin.util';
import { MarginMeshTubeMaterial } from '../ModelViewer/MarginMeshTubeMaterial';
import type { CaseMetadata } from './CaseMetadata';
import { DirectionIndicator } from './DirectionIndicator';
import type {
    CollisionsMap,
    MarginLinesMap,
    RestorativeModel,
    ScanMeshes,
    ScanModels,
    ScansRecord,
} from './FinishingApp.types';
import { InsertionSceneAppearanceManager } from './InsertionSceneAppearanceManager';
import {
    createCollisionsMaterial,
    createCollisionsCurtainsMaterial,
    createScanMaterial,
    isColoredMaterial,
} from './Materials.utils';
import { OcclusalSceneAppearanceManager } from './OcclusalSceneAppearanceManager';
import { ProximalSceneAppearanceManager } from './ProximalSceneAppearanceManager';
import type {
    ISceneAppearanceManager,
    SceneAppearance,
    Range,
    IPartialSceneAppearanceManager,
    PartialSceneAppearance,
    ScanKey,
    MinimalOperationsManager,
} from './SceneAppearanceManager.types';
import { createCollisionsMesh, createCurtainsNominalMaterial, heatmapName } from './SceneAppearanceManager.utils';
import type { ISceneMaterialManager } from './SceneMaterialManager.types';
import { ensureMeshIndex, getInsertionAxisFromOrientation, unionSpheres, HeatMapType } from '@orthly/forceps';
import type { ToothNumber } from '@orthly/items';
import { ToothUtils } from '@orthly/items';
import _ from 'lodash';
import * as THREE from 'three';

// Creates a scene from the design data and provides an interface for changing the scene appearance
export class SceneAppearanceManager implements ISceneAppearanceManager {
    scene: THREE.Scene = new THREE.Scene();

    private scanMeshes: ScanMeshes;
    private restoratives: Map<ToothNumber, THREE.Mesh<THREE.BufferGeometry, THREE.Material>> = new Map();
    private curtains: Map<ToothNumber, THREE.Mesh<THREE.BufferGeometry>> = new Map();

    private marginLines: Map<ToothNumber, LineType> = new Map();
    private collisions: Map<ToothNumber, THREE.Mesh> = new Map();
    private insertionPaths: Map<ToothNumber, THREE.ArrowHelper> = new Map();
    private directionIndicators: Map<ToothNumber, DirectionIndicator> = new Map();
    private sculptingGroup = new THREE.Group();

    private readonly antagonistJaw?: ScanKey;

    constructor(
        private setAppearance: (appearance: SceneAppearance) => void,
        private materialManager: ISceneMaterialManager,
        private operationsManager: MinimalOperationsManager,
        private scanModels: ScanModels,
        restorativeModels: RestorativeModel[],
        marginLines: MarginLinesMap,
        collisions: CollisionsMap,
        restorationJaw: CaseMetadata['restorationJaw'],
        enableTubeMarginLine: boolean,
    ) {
        if (restorationJaw === 'upper') {
            this.antagonistJaw = 'lower';
        } else if (restorationJaw === 'lower') {
            this.antagonistJaw = 'upper';
        }

        this.scanMeshes = {
            upperJaw: new THREE.Mesh(
                scanModels.upperJaw.geometry,
                createScanMaterial(false, scanModels.upperJaw.colorMap),
            ),
            lowerJaw: new THREE.Mesh(
                scanModels.lowerJaw.geometry,
                createScanMaterial(false, scanModels.lowerJaw.colorMap),
            ),
            prePrepUpperJaw:
                scanModels.prePrepUpperJaw &&
                new THREE.Mesh(
                    scanModels.prePrepUpperJaw.geometry,
                    createScanMaterial(false, scanModels.prePrepUpperJaw.colorMap),
                ),
            prePrepLowerJaw:
                scanModels.prePrepLowerJaw &&
                new THREE.Mesh(
                    scanModels.prePrepLowerJaw.geometry,
                    createScanMaterial(false, scanModels.prePrepLowerJaw.colorMap),
                ),
        } as const;

        this.scene.add(this.scanMeshes.upperJaw);
        this.scene.add(this.scanMeshes.lowerJaw);
        if (this.scanMeshes.prePrepUpperJaw) {
            this.scene.add(this.scanMeshes.prePrepUpperJaw);
            this.scanMeshes.prePrepUpperJaw.visible = false;
        }
        if (this.scanMeshes.prePrepLowerJaw) {
            this.scene.add(this.scanMeshes.prePrepLowerJaw);
            this.scanMeshes.prePrepLowerJaw.visible = false;
        }

        const collisionsMaterial = createCollisionsMaterial();
        const collisionsCurtainMaterial = createCollisionsCurtainsMaterial();
        collisionsCurtainMaterial.visible = false;

        const hiddenCollisionsMaterial = collisionsMaterial.clone();
        hiddenCollisionsMaterial.visible = false;

        restorativeModels.forEach(model => {
            const restorativeMesh = new THREE.Mesh(
                model.geometry,
                this.materialManager.getRestorativeMaterial(model.toothNumber),
            );
            this.restoratives.set(model.toothNumber, restorativeMesh);
            this.scene.add(restorativeMesh);

            if (model.curtainGeometry) {
                const curtainMesh = new THREE.Mesh(model.curtainGeometry, createCurtainsNominalMaterial());
                curtainMesh.name = `curtain-${model.toothNumber}`;
                curtainMesh.visible = false;
                this.curtains.set(model.toothNumber, curtainMesh);
                this.scene.add(curtainMesh);
            }

            this.addMarginLine(model.toothNumber, marginLines, enableTubeMarginLine);
            this.addCollisions(model.toothNumber, collisions, collisionsMaterial, collisionsCurtainMaterial);
            this.addInsertionPath(model.toothNumber, model.insertionAxis, model.geometry);

            const origin = model.geometry.boundingSphere?.center.clone() || new THREE.Vector3(0, 0, 0);
            const directionIndicator = new DirectionIndicator({ radius: 8, origin, visible: false });
            this.directionIndicators.set(model.toothNumber, directionIndicator);
            this.scene.add(directionIndicator);
        });

        this.scene.add(this.sculptingGroup);

        operationsManager.registerInsertionAxisChangedCallback(this.handleInsertionPathChange.bind(this));

        this.updateAppearanceState();
    }

    setJawVisibility(name: keyof ScansRecord<any>, visible?: boolean): void {
        const mesh = this.scanMeshes[name];
        if (!mesh) {
            return;
        }

        mesh.visible = visible ?? !mesh.visible;

        this.updateAppearanceState();
    }

    setJawTransparency(name: keyof ScansRecord<any>, transparent?: boolean): void {
        this.materialManager.showScanUndercut = false;
        const mesh = this.scanMeshes[name];
        if (!mesh) {
            return;
        }
        const key = name === 'upperJaw' || name === 'prePrepUpperJaw' ? 'upper' : 'lower';
        const nextTransparent = transparent ?? !mesh.material.transparent;
        const useColors = isColoredMaterial(mesh.material);

        const material = this.materialManager.getScanMaterial(key, {
            useColors,
            transparent: nextTransparent,
            colorMap: this.scanModels[name]?.colorMap,
        });
        if (!material) {
            return;
        }
        mesh.material = material;
        this.updateAppearanceState();
    }

    setJawColorize(name: keyof ScansRecord<any>, colorize?: boolean): void {
        const mesh = this.scanMeshes[name];
        if (!mesh) {
            return;
        }
        const key = name === 'upperJaw' || name === 'prePrepUpperJaw' ? 'upper' : 'lower';
        const nextColorized = colorize ?? !isColoredMaterial(mesh.material);

        const material = this.materialManager.getScanMaterial(key, {
            useColors: nextColorized,
            transparent: mesh.material.transparent,
            colorMap: this.scanModels[name]?.colorMap,
        });
        if (!material) {
            return;
        }
        mesh.material = material;
        this.updateAppearanceState();
    }

    setShowSculptMask(showSculptMask: boolean): void {
        if (showSculptMask) {
            this.setActiveHeatMap(undefined);
            this.materialManager.setAllRestorativesTransparency(false);
        }
        this.materialManager.setShowSculptMask(showSculptMask);
        this.updateAppearanceState();
    }

    toggleIsMaskVisible() {
        this.setShowSculptMask(!this.materialManager.showSculptMask);
    }

    disableMaskVisible() {
        this.setShowSculptMask(false);
    }

    toggleScanUndercutEnabled(scanUnderCutEnabled?: boolean) {
        this.materialManager.showScanUndercut = scanUnderCutEnabled ?? !this.materialManager.showScanUndercut;

        if (this.materialManager.showScanUndercut) {
            this.setActiveHeatMap(undefined);
        }

        let hasUpper = false;
        let hasLower = false;
        this.restoratives.forEach((_mesh, toothNumber) => {
            if (ToothUtils.isUpper([toothNumber])) {
                hasUpper = true;
            }
            if (ToothUtils.isLower([toothNumber])) {
                hasLower = true;
            }
        });

        if (hasUpper) {
            this.setJawColorize('upperJaw', isColoredMaterial(this.scanMeshes.upperJaw.material));
        }
        if (hasLower) {
            this.setJawColorize('lowerJaw', isColoredMaterial(this.scanMeshes.lowerJaw.material));
        }

        this.updateAppearanceState();
    }

    setRestorativeVisibility(toothNumber: ToothNumber, visible?: boolean): void {
        const restorative = this.restoratives.get(toothNumber);
        if (!restorative) {
            console.warn(`No restorative with tooth number ${toothNumber}.`);
            return;
        }

        restorative.visible = visible ?? !restorative.visible;

        this.updateAppearanceState();
    }

    setRestorativeTransparency(toothNumber: ToothNumber, transparent?: boolean): void {
        const restorative = this.restoratives.get(toothNumber);
        if (!restorative) {
            console.warn(`No restorative with tooth number ${toothNumber}.`);
            return;
        }

        const nextTransparent = transparent ?? !restorative.material.transparent;
        if (nextTransparent) {
            this.setActiveHeatMap(undefined);
            this.materialManager.setShowSculptMask(false);
        }

        this.materialManager.setRestorativeTransparency(toothNumber, nextTransparent);

        this.updateAppearanceState();
    }

    setMarginLinesVisibility(visible?: boolean): void {
        const nextVisible = typeof visible === 'boolean' ? visible : !this.areMarginLinesVisible;

        for (const lineMesh of this.marginLines.values()) {
            lineMesh.visible = nextVisible;
        }

        this.updateAppearanceState();
    }

    setCollisionsVisibility(visible?: boolean): void {
        const nextVisible = typeof visible === 'boolean' ? visible : !this.areCollisionsVisible;

        for (const collisionMesh of this.collisions.values()) {
            collisionMesh.visible = nextVisible;
        }

        this.updateAppearanceState();
    }

    togglePrePrepScanVisibility(visible?: boolean): void {
        if (this.scanMeshes.prePrepUpperJaw) {
            this.scanMeshes.prePrepUpperJaw.visible = visible ?? !this.scanMeshes.prePrepUpperJaw?.visible;
        }
        if (this.scanMeshes.prePrepLowerJaw) {
            this.scanMeshes.prePrepLowerJaw.visible = visible ?? !this.scanMeshes.prePrepLowerJaw?.visible;
        }
        this.updateAppearanceState();
    }

    togglePrepScanVisibility(visible?: boolean): void {
        if (this.antagonistJaw) {
            const upperAnt = this.antagonistJaw === 'upper';
            this.setJawVisibility(upperAnt ? 'lowerJaw' : 'upperJaw', visible);
        }
    }

    toggleAntagonistScanVisibility(visible?: boolean): void {
        if (this.antagonistJaw) {
            const upperAnt = this.antagonistJaw === 'upper';
            this.setJawVisibility(upperAnt ? 'upperJaw' : 'lowerJaw', visible);
        }
    }

    toggleRestorativesVisibility(): void {
        const nextVisible = !this.areRestorativesVisible;
        this.restoratives.forEach(mesh => {
            mesh.visible = nextVisible;
        });

        this.updateAppearanceState();
    }

    toggleScansColorize(colorize?: boolean): void {
        const isMeshColorized = isColoredMaterial(this.scanMeshes.upperJaw.material);

        this.setJawColorize('upperJaw', colorize ?? !isMeshColorized);
        this.setJawColorize('lowerJaw', colorize ?? !isMeshColorized);
    }

    private get areRestorativesVisible(): boolean {
        return Array.from(this.restoratives.values()).some(mesh => mesh.visible);
    }

    setCollisionsCurtainsVisibility(visible?: boolean): void {
        const nextVisible = typeof visible === 'boolean' ? visible : !this.areCollisionsCurtainsVisible;

        for (const collisionMesh of this.collisions.values()) {
            const materials = collisionMesh.material as THREE.MeshBasicMaterial[];
            const curtainCollisionsMaterial = materials[1];
            if (curtainCollisionsMaterial) {
                curtainCollisionsMaterial.visible = nextVisible;
            }
        }

        this.updateAppearanceState();
    }

    setCurtainsVisibility(visible?: boolean): void {
        const nextVisible = typeof visible === 'boolean' ? visible : !this.areCurtainsVisible;

        this.curtains.forEach(el => {
            el.visible = nextVisible;
        });

        this.updateAppearanceState();
    }

    setInsertionPathsVisibility(visible?: boolean): void {
        const nextVisible = typeof visible === 'boolean' ? visible : !this.areInsertionPathsVisible;

        for (const arrow of this.insertionPaths.values()) {
            arrow.visible = nextVisible;
        }

        this.updateAppearanceState();
    }

    setActiveHeatMap(heatMap?: HeatMapType): void {
        this.setActiveHeatMapImpl(heatMap);
    }

    setCurtainsHeatMapEnabled(enabled: boolean): void {
        this.setActiveHeatMapImpl(enabled ? HeatMapType.Proximal : undefined, enabled);
    }

    setHeatmapRange(range: Range): void {
        this.materialManager.heatmapRange = range;

        this.updateAppearanceState();
    }

    getSculptingGroup(): THREE.Group {
        return this.sculptingGroup;
    }

    getBoundingSphereForVisible(): THREE.Sphere {
        const sphere = new THREE.Sphere();

        _.compact([this.scanMeshes.upperJaw, this.scanMeshes.lowerJaw, ...this.restoratives.values()]).forEach(el => {
            if (el.visible) {
                el.geometry.computeBoundingSphere();
                unionSpheres(sphere, el.geometry.boundingSphere);
            }
        });

        return sphere;
    }

    getRestorativeBoundingSphere(toothNumber: ToothNumber): THREE.Sphere {
        const geometry = this.restoratives.get(toothNumber)?.geometry;
        if (!geometry) {
            throw new Error(`No restorative with tooth number ${toothNumber}.`);
        }

        geometry.computeBoundingSphere();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return geometry.boundingSphere!.clone();
    }

    getRestorativeMeshes(): ReadonlyMap<ToothNumber, THREE.Mesh<THREE.BufferGeometry, THREE.Material>> {
        return this.restoratives;
    }

    getScanMeshes(): ScanMeshes | null {
        return this.scanMeshes;
    }

    createOcclusalSceneManager(
        setAppearance: (appearance: PartialSceneAppearance) => void,
        restorationJaw: CaseMetadata['restorationJaw'],
    ): IPartialSceneAppearanceManager {
        const restorativeGeometries = new Map(
            Array.from(this.restoratives.entries()).map(([key, mesh]) => [key, mesh.geometry.clone()]),
        );

        const clonedModels = {
            upperJaw: this.scanModels.upperJaw.clone(),
            lowerJaw: this.scanModels.lowerJaw.clone(),
            prePrepUpperJaw: this.scanModels.prePrepUpperJaw?.clone(),
            prePrepLowerJaw: this.scanModels.prePrepLowerJaw?.clone(),
        } as const;

        return new OcclusalSceneAppearanceManager(
            setAppearance,
            restorativeGeometries,
            clonedModels,
            Array.from(this.collisions.values()),
            this.materialManager.clone(),
            restorationJaw,
        );
    }

    createInsertionSceneManager(
        setAppearance: (appearance: PartialSceneAppearance) => void,
    ): IPartialSceneAppearanceManager {
        const restorativeGeometries = new Map(
            Array.from(this.restoratives.entries()).map(([key, mesh]) => [key, mesh.geometry.clone()]),
        );
        const curtainsGeometries = new Map(
            Array.from(this.curtains.entries(), ([key, mesh]) => [key, mesh.geometry.clone()]),
        );

        const clonedModels = {
            upperJaw: this.scanModels.upperJaw.clone(),
            lowerJaw: this.scanModels.lowerJaw.clone(),
        } as const;

        return new InsertionSceneAppearanceManager(
            setAppearance,
            restorativeGeometries,
            curtainsGeometries,
            clonedModels,
            Array.from(this.collisions.values()),
            this.materialManager.clone(),
            this.insertionPaths,
        );
    }

    getCurtains(toothNumber: ToothNumber): THREE.BufferGeometry | undefined {
        const curtains = this.curtains.get(toothNumber)?.geometry;
        return curtains;
    }

    createProximalSceneManager(
        setAppearance: (appearance: PartialSceneAppearance) => void,
    ): IPartialSceneAppearanceManager {
        const restorativeGeometries = new Map(
            Array.from(this.restoratives.entries()).map(([key, mesh]) => [key, mesh.geometry.clone()]),
        );
        const curtainsGeometries = new Map(
            Array.from(this.curtains.entries(), ([key, mesh]) => [key, mesh.geometry.clone()]),
        );
        const clonedModels = {
            upperJaw: this.scanModels.upperJaw.clone(),
            lowerJaw: this.scanModels.lowerJaw.clone(),
        } as const;
        return new ProximalSceneAppearanceManager(
            setAppearance,
            restorativeGeometries,
            curtainsGeometries,
            clonedModels,
            Array.from(this.collisions.values()),
            this.materialManager.clone(),
        );
    }

    onInsertionAxisAdjusterHover(rotationAxis: THREE.Vector3 | undefined) {
        const toothNumber = this.operationsManager.editToothNumber;
        const poiDirectionIndicator = this.directionIndicators.get(toothNumber);
        if (!poiDirectionIndicator) {
            return;
        }

        if (rotationAxis) {
            const orientation = this.operationsManager.editInsertionOrientation;
            const rotatedOrientation = orientation
                .clone()
                .multiply(new THREE.Quaternion().setFromEuler(new THREE.Euler(0, 0, Math.PI)));
            poiDirectionIndicator.setAxis(rotationAxis, rotatedOrientation);
            poiDirectionIndicator.visible = true;
        } else {
            poiDirectionIndicator.visible = false;
        }
    }

    private updateAppearanceState(): void {
        this.setAppearance({
            scans: _.mapValues(this.scanMeshes, mesh => ({
                visible: mesh?.visible ?? false,
                transparent: mesh?.material.transparent ?? false,
                colorize: mesh ? isColoredMaterial(mesh.material) : false,
            })),
            restoratives: new Map(
                Array.from(this.restoratives.entries()).map(([id, mesh]) => [
                    id,
                    { visible: mesh.visible, transparent: mesh.material.transparent },
                ]),
            ),
            marginLinesVisible: this.areMarginLinesVisible,
            collisionsVisible: this.areCollisionsVisible,
            collisionsCurtainsVisible: this.areCollisionsCurtainsVisible,
            curtainsVisible: this.areCurtainsVisible,
            insertionPathsVisible: this.areInsertionPathsVisible,
            activeHeatMap: this.activeHeatMap,
            curtainsHeatMapEnabled: this.materialManager.curtainsHeatMapEnabled,
            heatmapRange: this.materialManager.heatmapRange,
            isMaskVisible: this.materialManager.showSculptMask,
            scanUndercutHeatmapEnabled: this.materialManager.showScanUndercut,
        });
    }

    private addMarginLine(toothNumber: ToothNumber, marginLines: MarginLinesMap, enableTubeMarginLine: boolean): void {
        const marginPoints = marginLines.get(toothNumber);
        if (!marginPoints) {
            console.warn(`Missing margin line for tooth number ${toothNumber}.`);
            return;
        }

        const scanMesh = ToothUtils.toothIsUpper(toothNumber) ? this.scanMeshes.upperJaw : this.scanMeshes.lowerJaw;

        if (!scanMesh) {
            console.warn(`No Scan mesh associated with tooth number ${toothNumber}.`);
            return;
        }

        const boundsTree = ensureMeshIndex(scanMesh.geometry);
        const tessResult = getMarginTessellatedPoints(marginPoints, true, boundsTree);
        const tessellatedPoints = tessResult.points;

        const marginLine = enableTubeMarginLine
            ? drawLineTube(tessellatedPoints, new MarginMeshTubeMaterial(0xff0000), true)
            : new MarginLineMesh(tessellatedPoints);
        marginLine.name = `margin-${toothNumber}`;
        marginLine.visible = false;

        this.marginLines.set(toothNumber, marginLine);
        this.scene.add(marginLine);
    }

    private addCollisions(
        toothNumber: ToothNumber,
        collisions: CollisionsMap,
        collisionsMaterial: THREE.Material,
        collisionsCurtainMaterial: THREE.Material,
    ): void {
        const collisionsGeometry = collisions.get(toothNumber);
        if (!collisionsGeometry) {
            console.warn(`Missing collisions for tooth number ${toothNumber}.`);
            return;
        }

        const collisionsMesh = createCollisionsMesh(collisionsGeometry, collisionsMaterial, collisionsCurtainMaterial);
        collisionsMesh.name = `collisions-${toothNumber}`;
        collisionsMesh.visible = false;

        this.collisions.set(toothNumber, collisionsMesh);
        this.scene.add(collisionsMesh);
    }

    private addInsertionPath(
        toothNumber: ToothNumber,
        insertionPath: THREE.Vector3,
        geometry: THREE.BufferGeometry,
        initiallyVisible: boolean = false,
    ) {
        geometry.computeBoundingSphere();
        const sphere = geometry.boundingSphere;
        if (!sphere) {
            console.warn(`Bounding sphere missing for tooth ${toothNumber}.`);
            return;
        }

        const arrowLength = 10;

        const dir = insertionPath.clone();
        const origin = sphere.center.clone();
        const arrow = new THREE.ArrowHelper(dir, origin, arrowLength, '#6dadc3');
        arrow.name = `insertion-path-${toothNumber}`;
        arrow.visible = initiallyVisible;

        this.insertionPaths.set(toothNumber, arrow);
        this.scene.add(arrow);
    }

    private removeInsertionPath(toothNumber: ToothNumber): boolean {
        const arrow = this.insertionPaths.get(toothNumber);
        if (!arrow) {
            throw new Error(`No insertion path for tooth number ${toothNumber}.`);
        }

        this.insertionPaths.delete(toothNumber);
        this.scene.remove(arrow);

        return arrow.visible;
    }

    private handleInsertionPathChange(insertionOrientation: THREE.Quaternion): void {
        const toothNumber = this.operationsManager.editToothNumber;

        const restorativeGeometry = this.restoratives.get(toothNumber)?.geometry;
        if (!restorativeGeometry) {
            return;
        }

        const wasVisible = this.removeInsertionPath(toothNumber);
        const insertionAxis = getInsertionAxisFromOrientation(insertionOrientation);
        this.addInsertionPath(toothNumber, insertionAxis, restorativeGeometry, wasVisible);
    }

    private setActiveHeatMapImpl(heatMap: HeatMapType | undefined, showCurtainsDistance: boolean = false): void {
        if (heatMap !== undefined) {
            this.disableMaskVisible();
            this.materialManager.setAllRestorativesTransparency(false);
            this.toggleScanUndercutEnabled(false);
        }

        this.materialManager.activeHeatMap = heatMap;
        this.materialManager.showCurtainsDistance = showCurtainsDistance;

        this.restoratives.forEach((mesh, toothNumber) => {
            const material = this.materialManager.getRestorativeMaterial(toothNumber);
            if (material) {
                mesh.material = material;
            } else {
                console.warn(
                    `Could not find material for ${
                        heatMap ? heatmapName[heatMap] : 'no'
                    } heatmap @ tooth ${toothNumber}.`,
                );
            }
        });

        this.updateAppearanceState();
    }

    private get areMarginLinesVisible(): boolean {
        // Margins are shown or hidden in tandem, so we only need to check one.
        return this.marginLines.values().next().value?.visible ?? false;
    }

    private get areCollisionsVisible(): boolean {
        // Collisions are shown or hidden in tandem, so we only need to check one.
        return this.collisions.values().next().value?.visible ?? false;
    }

    private get areCollisionsCurtainsVisible(): boolean {
        const firstCollisionsObject = this.collisions.values().next().value;
        if (firstCollisionsObject) {
            const materials = firstCollisionsObject.material as THREE.MeshBasicMaterial[];
            const curtainCollisionsMaterial = materials[1];
            if (curtainCollisionsMaterial) {
                return curtainCollisionsMaterial.visible;
            }
        }
        return false;
    }

    private get areCurtainsVisible(): boolean {
        return this.curtains.values().next().value?.visible ?? false;
    }

    private get areInsertionPathsVisible(): boolean {
        return Array.from(this.insertionPaths.values()).some(arrow => arrow.visible);
    }

    private get activeHeatMap(): HeatMapType | undefined {
        return this.materialManager.activeHeatMap;
    }
}
