import { OrthlyBrowserConfig } from '@orthly/ui';
import _ from 'lodash';

export class OrderDownloadFilesUtils {
    static getSanitizedScanUrl({
        orderId,
        retainerToken,
        scanExportId,
    }: {
        orderId: string;
        retainerToken: string;
        scanExportId?: string;
    }) {
        const baseUrl = `${OrthlyBrowserConfig.zipstreamUrl}/sanitized-scan?orderId=${orderId}&authToken=${retainerToken}`;

        if (scanExportId) {
            return `${baseUrl}&scanExportId=${scanExportId}`;
        }

        return baseUrl;
    }

    static getDesignByVersion({
        orderId,
        version,
        retainerToken,
        designBaseName,
    }: {
        orderId: string;
        version: '2020' | '2021' | null;
        retainerToken: string;
        designBaseName?: string;
    }) {
        const queryParams = _.compact([
            `orderId=${orderId}`,
            `authToken=${retainerToken}`,
            !!version ? `version=${version}` : undefined,
            !!designBaseName ? `designBaseName=${designBaseName}` : undefined,
        ]).join('&');
        return `${OrthlyBrowserConfig.zipstreamUrl}/order-design?${queryParams}`;
    }

    // This hits an endpoint which generates the .stl (crown) and .pts (margin)
    // files needed to mill the crown and it does so in an optimal orientation
    static getDandyCamFilesFromPortalDesign({
        orderId,
        portalDesignId,
        retainerToken,
        oriented,
    }: {
        orderId: string;
        portalDesignId: string;
        retainerToken: string;
        oriented: boolean;
    }) {
        return `${OrthlyBrowserConfig.zipstreamUrl}/dandy-cam-files?orderId=${orderId}&designId=${portalDesignId}&authToken=${retainerToken}&oriented=${oriented}`;
    }
}
