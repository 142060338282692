import { RuleTableRowActions } from '../../../../components/RuleTable/RuleTableRowActions';
import { TargetDistributionRuleForm } from './TargetDistributionRuleForm';
import type { LabsGqlTargetDistributionRuleFragment } from '@orthly/graphql-operations';
import {
    useEnableTargetDistributionRuleMutation,
    useUpdateTargetDistributionRuleMutation,
    useDisableTargetDistributionRuleMutation,
} from '@orthly/graphql-react';
import { LoadBlocker, useRootActionCommand } from '@orthly/ui';
import React from 'react';

type TargetDistributionRuleRowActionsProps = {
    row: LabsGqlTargetDistributionRuleFragment;
    refetch: () => Promise<any>;
};

/**
 * Container for the 'Actions' column on the Target Distributions page.
 * The actions include a switch for enabling/disabling the rule and an edit button, which opens up a form for editing
 * the rule.
 */
export const TargetDistributionRuleRowActions = ({ row, refetch }: TargetDistributionRuleRowActionsProps) => {
    const { submit: disableRule, submitting: submittingDisable } = useRootActionCommand(
        useDisableTargetDistributionRuleMutation(),
        {
            successMessage: `Target distribution rule disabled`,
            onSuccess: async () => {
                await refetch();
            },
        },
    );
    const { submit: enableRule, submitting: submittingEnable } = useRootActionCommand(
        useEnableTargetDistributionRuleMutation(),
        {
            successMessage: `Target distribution rule enabled`,
            onSuccess: async () => {
                await refetch();
            },
        },
    );
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const { submit: submitUpdate, submitting: submittingUpdate } = useRootActionCommand(
        useUpdateTargetDistributionRuleMutation(),
        {
            successMessage: `Target distribution rule updated`,
            onSuccess: async () => {
                await refetch();
                setDialogOpen(false);
            },
        },
    );
    return (
        <RuleTableRowActions
            RuleTableRowEnableProps={{
                disabled: row.disabled,
                onClick: () =>
                    row.disabled
                        ? enableRule({ data: { rule_id: row.id } })
                        : disableRule({ data: { rule_id: row.id } }),
                submitting: submittingEnable || submittingDisable,
            }}
            RuleTableDialogProps={{
                form: (
                    <LoadBlocker blocking={submittingUpdate}>
                        <TargetDistributionRuleForm
                            initialValues={row}
                            onSubmit={({ name, condition_json, routing_target_distribution_items, priority }) =>
                                submitUpdate({
                                    data: {
                                        name,
                                        condition_json,
                                        routing_target_distribution_items,
                                        priority,
                                        rule_id: row.id,
                                    },
                                })
                            }
                        />
                    </LoadBlocker>
                ),
                open: dialogOpen,
                setOpen: setDialogOpen,
                title: 'Edit Target Distribution Rule',
            }}
        />
    );
};
