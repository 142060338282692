import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { getWorkflowTaskName } from '@orthly/graphql-operations';
import { useTaskAssignmentTimelineQuery } from '@orthly/graphql-react';
import { ActionCard, PlusIcon, LoadBlocker, SimpleTable } from '@orthly/ui';
import { FlossPalette, stylesFactory, Text, Button, Collapse, Grid } from '@orthly/ui-primitives';
import { OrderDetailAccordionBlock } from '@orthly/veneer';
import clsx from 'clsx';
import moment from 'moment';
import React from 'react';

const useStyles = stylesFactory(() => ({
    listItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        background: 'none',
        border: 'none',
        textAlign: 'left',
        padding: '5px 0',
        cursor: 'pointer',
    },
    listItemDisabled: {
        cursor: 'not-allowed',
    },
    badge: {
        padding: 5,
        borderRadius: 10,
        marginLeft: 16,
    },
    dateBadge: {
        background: FlossPalette.STAR_GRASS,
        color: FlossPalette.WHITE,
    },
    noModBadge: {
        background: FlossPalette.GRAY,
        color: FlossPalette.WHITE,
    },
}));

function getAssignmentReasonLabel(reason: string | null) {
    if (!reason) {
        return '(Unknown)';
    }
    return reason
        .replace(/^(.*:)/, '')
        .replace(/[A-Z]/g, match => ` ${match}`)
        .trim();
}

interface OrderTaskTimelineBlockProps {
    order: LabsGqlLabOrderFragment;
}

// eslint-disable-next-line max-lines-per-function
const OrderTaskTimelineBlockInternal: React.FC<OrderTaskTimelineBlockProps> = ({ order }) => {
    const { data, refetch, error, loading } = useTaskAssignmentTimelineQuery({
        variables: {
            order_id: order.id,
        },
    });
    const [showUnassignments, setShowUnassignments] = React.useState(true);
    const [selectedRow, setSelectedRow] = React.useState(-1);
    const classes = useStyles();
    React.useEffect(() => {
        void refetch();
    }, [order.updated_at, refetch]);

    return (
        <LoadBlocker blocking={loading}>
            {!data && !error && <p>Loading ...</p>}
            {error && (
                <ActionCard
                    style={{ marginTop: 16 }}
                    variant={'alert'}
                    title={'Failed to load task history.'}
                    subtitle={String(error)}
                />
            )}
            <Button
                size={'small'}
                variant={'primary'}
                disabled={loading}
                onClick={() => refetch()}
                style={{ margin: '16px 0' }}
            >
                Reload changes
            </Button>
            <Button
                size={'small'}
                variant={'secondary'}
                disabled={loading}
                onClick={() => setShowUnassignments(!showUnassignments)}
                style={{ margin: '16px 0 16px 16px' }}
            >
                {showUnassignments ? 'Hide unassignments' : 'Show unassignments'}
            </Button>
            {data && (
                <Grid container direction={'column'}>
                    {data.taskAssignmentTimeline.map((entry, index) => {
                        const assignedAt = moment(entry.assigned_at);
                        const expiration = entry.auto_assignment_expiration
                            ? moment(entry.auto_assignment_expiration)
                            : null;

                        if (!showUnassignments && !entry.assignee_name) {
                            return null;
                        }
                        return (
                            <div key={index}>
                                <button
                                    className={clsx(classes.listItem)}
                                    onClick={() => setSelectedRow(current => (current === index ? -1 : index))}
                                >
                                    <span style={{ display: 'flex' }}>
                                        <PlusIcon style={{ marginRight: 16 }} />
                                        <Text variant={'body2'}>
                                            {getWorkflowTaskName(entry.task_type)}{' '}
                                            {/* eslint-disable-next-line no-nested-ternary */}
                                            {showUnassignments
                                                ? // eslint-disable-next-line no-nested-ternary
                                                  entry.event_name === 'FulfillmentTaskAssigned'
                                                    ? entry.assignee_name
                                                        ? ' - assigned'
                                                        : ' - unassigned'
                                                    : ' - task timed out'
                                                : ''}
                                        </Text>
                                        {entry.auto_assigned && (
                                            <span className={clsx(classes.badge, classes.noModBadge)}>
                                                Auto Assigned
                                            </span>
                                        )}
                                    </span>

                                    <span className={clsx(classes.badge, classes.dateBadge)}>
                                        {new Date(entry.assigned_at).toLocaleString()}
                                    </span>
                                </button>
                                <Collapse in={selectedRow === index}>
                                    <div style={{ padding: 16 }}>
                                        <SimpleTable
                                            elevation={0}
                                            rows={[
                                                ...(entry.assignee_name
                                                    ? [
                                                          {
                                                              name: 'Assignee',
                                                              value: entry.assignee_name,
                                                          },
                                                      ]
                                                    : []),
                                                {
                                                    name: 'Assigned by',
                                                    value: entry.assigned_by,
                                                },
                                                {
                                                    name: 'Assigned at',
                                                    value:
                                                        assignedAt.format('MM/DD/YYYY h:mm a z') +
                                                        (assignedAt.isSame(new Date(), 'day')
                                                            ? ` (${assignedAt.fromNow()})`
                                                            : ''),
                                                },
                                                ...(expiration
                                                    ? [
                                                          {
                                                              name: expiration.isBefore(new Date())
                                                                  ? 'Expired at'
                                                                  : 'Expires at',
                                                              value:
                                                                  expiration.format('MM/DD/YYYY h:mm a z') +
                                                                  (expiration.isSame(new Date(), 'day')
                                                                      ? ` (${expiration.fromNow()})`
                                                                      : ''),
                                                          },
                                                      ]
                                                    : []),
                                                {
                                                    name: 'Assignment delay',
                                                    value: assignedAt.diff(entry.created_at, 'minute'),
                                                },
                                                {
                                                    name: 'Event ID',
                                                    value: String(entry.event_id),
                                                },
                                                {
                                                    name: 'Assignment reason',
                                                    value: getAssignmentReasonLabel(entry.assignment_reason),
                                                },
                                            ]}
                                        />
                                    </div>
                                </Collapse>
                            </div>
                        );
                    })}
                </Grid>
            )}
        </LoadBlocker>
    );
};

export const OrderTaskTimelineBlock: React.FC<OrderTaskTimelineBlockProps> = ({ order }) => {
    return (
        <OrderDetailAccordionBlock title={`Task assignment history`} variant={'full'}>
            <OrderTaskTimelineBlockInternal order={order} />
        </OrderDetailAccordionBlock>
    );
};
