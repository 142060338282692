import { RouterUtils } from '../../utils/router/RouterUtils';
import { DesignQaEvaluationList } from './DesignQaList';
import { useDesignQaSidebar } from './DesignQaSidebar.graphql';
import { DesignQaResults } from './Results/DesignQaResults.graphql';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useGetDesignQaEvaluationByIdForResultsQuery } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import type { DesignQcRubricCategoryEntry } from '@orthly/veneer';
import {
    DesignQcRoot,
    useFeatureFlag,
    occlusalOpposingCuspFossaRubric,
    marginLinePlacementRubric,
    marginalRidgesVerticalPositionRubric,
    ipContactsAdjacentUndercutRubric,
    generateFilteredGuidedQCRubric,
    allBiteRubric,
    undercutsAndInterproximalContactsRubric,
    gigivalEmbrasuresRubric,
    cuspAndFossaLocationsRubric,
    allAestheticsRubric,
} from '@orthly/veneer';
import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Switch, Route, useHistory } from 'react-router-dom';

const AdminDesignQaEvaluationViewer_Query = graphql(`
    query AdminDesignQaEvaluationViewer_Query($evaluationId: String!) {
        getDesignQaEvaluationById(id: $evaluationId) {
            design_revision {
                ...OrderDesignPreviewDesign_Fragment
                ...VeneerOrderDesignAnalyticsDesign_Fragment
            }
        }
    }
`);

function getSpecializedRubric(specializedQARubrics: string[] | undefined): DesignQcRubricCategoryEntry[] | undefined {
    if (!specializedQARubrics || specializedQARubrics.length === 0) {
        return undefined;
    }
    const rubrics = [];

    for (const rubric of specializedQARubrics) {
        switch (rubric) {
            case 'occlusalOpposingCuspFossa':
                rubrics.push(occlusalOpposingCuspFossaRubric);
                break;
            case 'marginLinePlacementRubric':
                rubrics.push(marginLinePlacementRubric);
                break;
            case 'ipContactsAdjacentUndercut':
                rubrics.push(ipContactsAdjacentUndercutRubric);
                break;
            case 'marginalRidgesVerticalPosition':
                rubrics.push(marginalRidgesVerticalPositionRubric);
                break;
            case 'allBiteRubric':
                rubrics.push(allBiteRubric);
                break;
            case 'undercutsAndInterproximalContactsRubric':
                rubrics.push(undercutsAndInterproximalContactsRubric);
                break;
            case 'gigivalEmbrasuresRubric':
                rubrics.push(gigivalEmbrasuresRubric);
                break;
            case 'cuspAndFossaLocationsRubric':
                rubrics.push(cuspAndFossaLocationsRubric);
                break;
            case 'aestheticsRubric':
                rubrics.push(allAestheticsRubric);
                break;
            default:
                break;
        }
    }

    if (rubrics.length === 0) {
        return undefined;
    }
    return generateFilteredGuidedQCRubric(rubrics);
}

const DesignQaEvaluationViewer: React.VFC<RouteComponentProps<{ id: string }>> = props => {
    const { params } = props.match;

    const history = useHistory();
    const session = useSession();

    // TODO: convert this to use just one total query
    // We currently are performing multiple queries because it's unclear how to handle items_v2 and similar fragments from the order yet.
    // This will be fixed up as those utilities get ported to accept the new data types.
    const { data: designRevisionRelatedData } = useQuery(AdminDesignQaEvaluationViewer_Query, {
        variables: {
            evaluationId: params.id,
        },
        fetchPolicy: 'no-cache',
    });

    const { data: getEvaluationData } = useGetDesignQaEvaluationByIdForResultsQuery({
        variables: {
            id: params.id,
        },
        fetchPolicy: 'no-cache',
    });
    const { value: specializedQARubrics } = useFeatureFlag('specializedQARubrics');
    const evaluationOrder = getEvaluationData?.getDesignQaEvaluationById?.order;
    const designFragment = designRevisionRelatedData?.getDesignQaEvaluationById.design_revision;

    const designQcSidebarEntries = useDesignQaSidebar(evaluationOrder ?? undefined, designFragment ?? undefined);

    if (!evaluationOrder || !designFragment) {
        return null;
    }

    return (
        <DesignQcRoot
            order={evaluationOrder}
            designFragment={designFragment}
            sidebarEntries={designQcSidebarEntries}
            refetch={async () => {}}
            currentUserId={session?.user?.id}
            hasDesignBeenRejectedPreviously={false}
            setOpen={open => {
                if (!open) {
                    history.push(RouterUtils.pathForScreen('design_qa'));
                }
            }}
            open={true}
            designQaEvaluationId={params.id}
            rubricOverride={getSpecializedRubric(specializedQARubrics)}
        />
    );
};

export const DesignQaRoot: React.VFC = () => {
    return (
        <Switch>
            <Route path={`${RouterUtils.pathForScreen('design_qa')}`} component={DesignQaEvaluationList} exact />

            <Route
                path={`${RouterUtils.pathForScreen('design_qa')}/results/:orderId`}
                component={DesignQaResults}
                exact
            />

            <Route path={`${RouterUtils.pathForScreen('design_qa')}/:id`} component={DesignQaEvaluationViewer} />
        </Switch>
    );
};
