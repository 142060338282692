import type { PaymentSourceFragmentFragment } from '@orthly/graphql-inline-react';
import { AMEXIcon, AchIcon, DiscoverIcon, JCBIcon, MastercardIcon, VisaIcon } from '@orthly/ui';
import { Text, Grid, CreditCardIcon } from '@orthly/ui-primitives';
import { BankCardToNameMap, usePaymentMethodStyles } from '@orthly/veneer';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';

interface PaymentMethodsProps {
    loadedMethods: readonly PaymentSourceFragmentFragment[];
}

const PrimaryTag: React.VFC = () => {
    const classes = usePaymentMethodStyles({});

    return <div className={classes.primaryTag}>Primary method</div>;
};

interface PaymentMethodProps {
    source: PaymentSourceFragmentFragment;
}

const PaymentMethod: React.VFC<PaymentMethodProps> = ({ source }) => {
    const { method, brand, last4, is_default } = source;
    const classes = usePaymentMethodStyles({ is_default });
    const isBank = method === 'bank_account';

    const bankCardIcon = () => {
        switch (_.startCase(brand)) {
            case BankCardToNameMap.american_express:
                return <AMEXIcon />;
            case BankCardToNameMap.discover:
                return <DiscoverIcon />;
            case BankCardToNameMap.jcb:
                return <JCBIcon />;
            case BankCardToNameMap.mastercard:
                return <MastercardIcon />;
            case BankCardToNameMap.visa:
                return <VisaIcon />;
            default:
                return <CreditCardIcon />;
        }
    };

    return (
        <Grid container direction={'column'} className={clsx(classes.root)} style={{ width: 300 }}>
            <Grid container>
                <Grid item className={classes.paymentMethodIcon}>
                    {isBank ? <AchIcon /> : bankCardIcon()}
                </Grid>
                <Grid item>
                    <Text variant={'caption'} color={'DARK_GRAY'} medium>
                        {isBank ? 'ACH Transfer' : _.startCase(brand)}
                    </Text>
                    <Grid item>
                        <Text variant={'body2'}>{isBank ? `Acct. #: ****${last4}` : `**** **** **** ${last4}`}</Text>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.footerContainer}>
                {is_default && <PrimaryTag />}
            </Grid>
        </Grid>
    );
};

export const PaymentMethods: React.FC<PaymentMethodsProps> = props => {
    const { loadedMethods } = props;
    const methods = React.useMemo(() => loadedMethods ?? [], [loadedMethods]);
    // we want the method with is_default set to true to be first, so reverse the order
    // since sortBy sorts in ascending (false first) order
    const defaultMethodFirst = _.sortBy(methods, m => m.is_default).reverse();

    return (
        <Grid container spacing={2}>
            {defaultMethodFirst.map(method => (
                <Grid item key={method.id}>
                    <PaymentMethod key={method.id} source={method} />
                </Grid>
            ))}
        </Grid>
    );
};
